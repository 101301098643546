import { AutocompleteItems, DropdownIcon, Link, StringHelper, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, tenantModelStore, useTheme } from "../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../index";

export function GciTenantItem() {
    const gciTenantModels = tenantModelStore.useGetGciTenants();
    const { gciTenantModel, tenantModel } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const orderedGciTenantModels =
        useMemo(
            () =>
                _(gciTenantModels).
                    filter(
                        gciTenantModel =>
                            gciTenantModel.status === Contract.GciTenantModelStatus.Valid ||
                            gciTenantModel.status === Contract.GciTenantModelStatus.ServiceAccountUserDelegationOptionalAuthorizationScopesMismatch).
                    orderBy(gciTenantModel => StringHelper.getSortValue(gciTenantModel.configuration.name)).
                    value(),
            [gciTenantModels]);

    const [selectedGciTenantModel, setSelectedGciTenantModel] = useState<Contract.GciTenantModel | undefined>(gciTenantModel);

    const { setLoaded, setValid } = useOrderedWizardContext();
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.gciTenantItem",
            () => ({
                fields: {
                    gciTenant: {
                        empty: "No Google Workspaces",
                        error: {
                            required: "You must choose a Google Workspace"
                        },
                        subtitle: {
                            gciTenantsPageLink: "click here",
                            text: "Select the Google Workspace linked to the project you want to add. If the Workspace doesn’t appear in the dropdown, {{gciTenantsPageLink}} to add it before you proceed to add you project."
                        },
                        title: "Google Workspace"
                    }
                }
            }));

    const [gciTenantModelValidationController, gciTenantModelValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(selectedGciTenantModel)) {
                    return localization.fields.gciTenant.error.required();
                }

                return undefined;
            },
            [selectedGciTenantModel]);

    useEffect(
        () => {
            if (selectedGciTenantModel !== gciTenantModel) {
                setAddOrEditContext(
                    addContext => ({
                        ...addContext,
                        gciTenantModel: selectedGciTenantModel,
                        name: selectedGciTenantModel?.configuration.name,
                        organizationRawShortId: selectedGciTenantModel?.state.gcp?.organizationRawShortId
                    }));
            }

            setValid(gciTenantModelValidationController.isValid());
        },
        [selectedGciTenantModel]);

    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Typography>
                {localization.fields.gciTenant.subtitle.text({
                    gciTenantsPageLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsGciRelativeUrl(true)}>
                            {localization.fields.gciTenant.subtitle.gciTenantsPageLink()}
                        </Link>
                })}
            </Typography>
            <Box>
                <AutocompleteItems
                    disableClearable={true}
                    disabled={!_.isNil(tenantModel)}
                    fullWidth={true}
                    getOptionLabel={gciTenantModel => gciTenantModel.configuration.name}
                    noOptionsText={localization.fields.gciTenant.empty()}
                    options={orderedGciTenantModels}
                    popupIcon={<DropdownIcon sx={{ color: theme.palette.text.hint }}/>}
                    renderInput={
                        params => (
                            <TextField
                                {...params}
                                label={localization.fields.gciTenant.title()}
                                variant="outlined"/>)}
                    value={selectedGciTenantModel}
                    onChange={(_event, gciTenantModel) => setSelectedGciTenantModel(gciTenantModel)}>
                    {gciTenantModel =>
                        <Stack>
                            <Typography>
                                {gciTenantModel.configuration.name}
                            </Typography>
                            <Typography variant="subtitle1">
                                {gciTenantModel.configuration.rawId}
                            </Typography>
                        </Stack>}
                </AutocompleteItems>
                {!_.isNil(gciTenantModelValidationMessage) &&
                    <FormHelperText error={true}>{gciTenantModelValidationMessage}</FormHelperText>}
            </Box>
        </Stack>);
}