import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsClusterRiskContext } from "../contexts";

export function useAwsRdsClusterMinorVersionUpgradeDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsClusterMinorVersionUpgradeDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsClusterModel;
    const cluster = clusterModel.entity as Contract.AwsRdsCluster;

    const getAwsRdsClusterRiskContext = useGetAwsRdsClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsClusterMinorVersionUpgradeDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} automatic minor version upgrade is not enabled",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Additional configuration** section and select the **Enable auto minor version upgrade** checkbox",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsClusterUrl(cluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAwsRdsClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.storageSize,
                clusterRiskContext.instances,
                clusterRiskContext.highestDataSensitivity,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        }
    );
}