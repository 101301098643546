import { NumberFormatter, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, EntityIcon, TypeHelper, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../common";

type EntityPublicDataItemProps = {
    entityTypeName: string;
    pageFirstItem: boolean;
    pageLastItem: boolean;
    securityStats: Contract.SecurityStats;
};

export function EntityPublicDataItem({ entityTypeName, pageFirstItem, pageLastItem, securityStats }: EntityPublicDataItemProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.entityPublicDataAndSecretsContentPages.entityPublicDataItem",
            () => ({
                riskSeverityCount: "{{count | NumberFormatter.humanize}} ({{percent | NumberFormatter.percentage}})",
                title: "{{translatedEntityCount}} Public {{translatedEntityTypeName}}"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            style={{
                border: theme.border.primary,
                borderRadius:
                    pageLastItem
                        ? theme.spacing(0, 0, 0.75, 0.75)
                        : pageFirstItem
                            ? theme.spacing(0.75, 0.75, 0, 0)
                            : undefined,
                borderTop:
                    pageFirstItem
                        ? theme.border.primary
                        : "none",
                padding: theme.spacing(2)
            }}>
            <EntityIcon
                badge="publicAccess"
                entityTypeName={entityTypeName}
                sx={{ fontSize: "30px" }}/>
            <Box style={{ flex: 1 }}>
                <Typography>
                    {localization.title({
                        translatedEntityCount:
                            <Typography
                                component="span"
                                variant="h4">
                                {NumberFormatter.humanize(securityStats.count)}
                            </Typography>,
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                { count: securityStats.count })
                    })}
                </Typography>
            </Box>
            {_(securityStats.unsecuredSeverityToCountMap).
                toPairs().
                filter(([_severity, severityCount]) => severityCount !== 0).
                orderBy(
                    ([severity, _severityCount]) => TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity),
                    "desc").
                map(
                    ([severity, severityCount]) =>
                        <Stack
                            alignItems="center"
                            direction="row"
                            key={severity}>
                            <Box
                                sx={{
                                    backgroundColor: theme.palette.severity(severity as Contract.Severity),
                                    height: theme.px(11),
                                    marginRight: theme.spacing(0.5),
                                    width: theme.px(3)
                                }}/>
                            <Typography>
                                {localization.riskSeverityCount({
                                    count: severityCount,
                                    percent: severityCount / securityStats.count
                                })}
                            </Typography>
                        </Stack>).
                value()}
        </Stack>);
}