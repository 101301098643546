import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.apiServers.useKubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} does not have RBAC enabled for access management",
                resolutionSection: {
                    step1: "Edit the API server manifest file (typically located in **/etc/kubernetes/manifests/kube-apiserver.yaml**)",
                    step2: "Ensure the **--authorization-mode** parameter includes **RBAC** (e.g., **--authorization-mode=RBAC,...**)",
                    step3: "Restart the API server to apply the changes"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}