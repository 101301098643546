import { useLocalization } from "@infrastructure";
import { Contract } from "../controllers";

export function useRiskSubStatusTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useRiskSubStatusTranslator",
            () => ({
                [Contract.TypeNames.RiskSubStatus]: {
                    [Contract.RiskSubStatus.ClosedFalsePositive]: "False positive",
                    [Contract.RiskSubStatus.ClosedFixed]: "Fixed",
                    [Contract.RiskSubStatus.ClosedManually]: "Manually closed",
                    [Contract.RiskSubStatus.ClosedResourceDeleted]: "Resource deleted",
                    [Contract.RiskSubStatus.ClosedSelfResolved]: "Self resolved",
                    [Contract.RiskSubStatus.IgnoredByDesign]: "By design",
                    [Contract.RiskSubStatus.IgnoredException]: "Exception",
                    [Contract.RiskSubStatus.IgnoredFalsePositive]: "False positive",
                    [Contract.RiskSubStatus.OpenAnomaly]: "Anomaly",
                    [Contract.RiskSubStatus.OpenExistingResource]: "Existing resource",
                    [Contract.RiskSubStatus.OpenNewResource]: "New resource",
                    [Contract.RiskSubStatus.OpenNewRisk]: "New finding",
                    [Contract.RiskSubStatus.OpenReopened]: "Reopened",
                    [Contract.RiskSubStatus.OpenUnignored]: "Unignored"
                }
            }));
    return (riskSubStatus: Contract.RiskSubStatus) =>
        localization[Contract.TypeNames.RiskSubStatus][riskSubStatus]();
}