import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonEncryptedResourceInfoElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements } from "..";
import { Contract, CustomerConsoleAppUrlHelper, entityModelStore, InfoItem } from "../../../../../../../../../../../common";
import { useAzureContainerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator, useAzureContainerServiceManagedClusterStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources, KubernetesClusterResourcesInfoCard } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";
import { useCommonKubernetesClusterInfoElements } from "../../useCommonKubernetesClusterInfoElements";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";
import { ManagedClusterNetworkingInfoCard } from "./components";

export function useAzureContainerServiceManagedClusterDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const cluster = resourceGroupResourceModel.entity as Contract.AzureContainerServiceManagedCluster;
    const clusterModel = resourceGroupResourceModel as Contract.AzureContainerServiceManagedClusterModel;
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonKubernetesClusterInfoElements = useCommonKubernetesClusterInfoElements(_.as<Contract.IKubernetesClusterModel>(resourceGroupResourceModel));
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const diskEncryptionSetModel = entityModelStore.useGet(cluster.diskEncryptionSetId) as Contract.AzureComputeDiskEncryptionSetModel;

    const containerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator = useAzureContainerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator();
    const containerServiceManagedClusterStatusTranslator = useAzureContainerServiceManagedClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureContainerServiceManagedClusterDefinition",
            () => ({
                empty: "No cluster parameters",
                info: {
                    agentPoolIds: "Node Pools",
                    applicationGatewayId: "Application Gateway",
                    authentication: {
                        kubernetesUser: {
                            false: "Disabled",
                            title: "Kubernetes Local Accounts",
                            true: "Enabled"
                        }
                    },
                    authenticationAndAuthorizationMode: "Authentication and Authorization",
                    authorization: {
                        kubernetesAdministratorClusterRoleAadDirectoryGroupIds: "Cluster Admins",
                        kubernetesRoleBinding: {
                            false: "Disabled",
                            title: "Kubernetes RBAC Enabled",
                            true: "Enabled"
                        }
                    },
                    diskEncryptionSetVaultIdReference: "Encryption At Rest Key Vault",
                    diskEncryptionSetVaultKeyIdReference: "Encryption At Rest Key",
                    identity: "Identity",
                    network: {
                        virtualNetworkId: "Virtual Network"
                    },
                    nodePools: {
                        count: "Nodes count",
                        enableAutoscaling: {
                            false: "Manual",
                            title: "Node pool type",
                            true: "Auto scaling"
                        },
                        image: "Node image",
                        name: "Node pool",
                        powerState: "Power state",
                        title: "Node Pools",
                        virtualMachineScaleSets: "Virtual Machine Scale Sets"
                    },
                    status: "Status",
                    vaultKeyIdReferences: "Secrets Encryption",
                    version: "Kubernetes Version",
                    virtualNodeEnabled: {
                        false: "Disabled",
                        title: "Virtual Node Pools",
                        true: "Enabled"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments",
                    resources: "Resources"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesClusterResourcesInfoCard clusterId={clusterModel.entity.id}/>,
                localization.tabs.resources(),
                "resources"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={
                            _.filter(
                                _.concat(
                                    cluster.systemManagedIdentityServicePrincipalId,
                                    cluster.servicePrincipalId,
                                    clusterModel.userManagedIdentityServicePrincipalIds)) as string[]}
                        entityTypeName={Contract.TypeNames.AzureManagedIdentityManagedIdentity}
                        key="identity"
                        location="all"
                        title={localization.info.identity()}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.status()}
                        value={containerServiceManagedClusterStatusTranslator(cluster.status)}/>,
                    <InfoItem
                        key="version"
                        location="all"
                        title={localization.info.version()}
                        value={cluster.version}/>,
                    commonKubernetesClusterInfoElements.createApiConnectivityStatusInfoItemElement(CustomerConsoleAppUrlHelper.getDocsOnboardAzureAksClusterRelativeUrl()),
                    <InfoItem
                        key="authenticationAndAuthorizationMode"
                        title={localization.info.authenticationAndAuthorizationMode()}
                        value={containerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator(clusterModel.authenticationAndAuthorizationMode)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cluster.authorization.kubernetesAdministratorClusterRoleAadDirectoryGroupIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                        key="kubernetesAdministratorClusterRoleAadDirectoryGroupIds"
                        title={
                            clusterModel.authenticationAndAuthorizationMode === Contract.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode.IdentityAndKubernetesRoleBinding
                                ? localization.info.authorization.kubernetesAdministratorClusterRoleAadDirectoryGroupIds()
                                : undefined}/>,
                    <InfoItem
                        key="authorizationKubernetesRoleBinding"
                        title={localization.info.authorization.kubernetesRoleBinding.title()}
                        value={
                            cluster.authorization.kubernetesRoleBinding
                                ? localization.info.authorization.kubernetesRoleBinding.true()
                                : localization.info.authorization.kubernetesRoleBinding.false()}/>,
                    <InfoItem
                        key="authenticationKubernetesUser"
                        title={localization.info.authentication.kubernetesUser.title()}
                        value={
                            cluster.authentication.kubernetesUser
                                ? localization.info.authentication.kubernetesUser.true()
                                : localization.info.authentication.kubernetesUser.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cluster.applicationGatewayId}
                        entityTypeName={Contract.TypeNames.AzureNetworkApplicationGateway}
                        key="applicationGatewayId"
                        title={localization.info.applicationGatewayId()}/>,
                    <InfoItem
                        key="virtualNodeEnabled"
                        title={localization.info.virtualNodeEnabled.title()}
                        value={
                            cluster.virtualNodeEnabled
                                ? localization.info.virtualNodeEnabled.true()
                                : localization.info.virtualNodeEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cluster.agentPoolIds}
                        entityTypeName={Contract.TypeNames.AzureContainerServiceManagedClusterAgentPool}
                        key="agentPoolIds"
                        title={localization.info.agentPoolIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={diskEncryptionSetModel?.vaultIdReference}
                        entityTypeName={Contract.TypeNames.AzureKeyVaultVault}
                        key="diskEncryptionSetVaultIdReference"
                        title={localization.info.diskEncryptionSetVaultIdReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={diskEncryptionSetModel?.vaultKeyIdReference}
                        entityTypeName={Contract.TypeNames.AzureKeyVaultVaultKey}
                        key="diskEncryptionSetVaultKeyIdReference"
                        title={localization.info.diskEncryptionSetVaultKeyIdReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cluster.network?.virtualNetworkId}
                        entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}
                        key="networkVirtualNetworkId"
                        location="miniGlance"
                        title={localization.info.network.virtualNetworkId()}/>
                ]}
                options={options?.infoOptions}>
                <ManagedClusterNetworkingInfoCard managedCluster={cluster}/>
            </Info>
    });
}