import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsResourceSnapshotTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsRdsNeptuneClusterSnapshotDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const snapshotModel = resourceModel as Contract.AwsRdsNeptuneClusterSnapshotModel;
    const snapshot = snapshotModel.entity as Contract.AwsRdsNeptuneClusterSnapshot;
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const snapshotTypeTranslator = useAwsResourceSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsRdsNeptuneClusterSnapshotDefinition",
            () => ({
                info: {
                    items: {
                        status: "Status",
                        storageSize: "Size",
                        type: "Snapshot Type"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={snapshotTypeTranslator(snapshot.type)}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={snapshot.rawStatus}/>,
                    <InfoItem
                        key="storageSize"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(snapshot.storageSize)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={snapshot.clusterId}
                        entityTypeName={Contract.TypeNames.AwsRdsNeptuneCluster}
                        key="clusterId"/>,
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}