import { DataTableColumn, DataTableColumnRenderProps, useExecuteOperation, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, PermissionManagementController } from "../../../../../../../../../../../../common";
import { TableColumnId } from "../../../../IdentityProviderTenantTable";
import { TenantDefinition } from "../../useDefinition";
import { PermissionsCell } from "./components";

export function useAadDefinition(): TenantDefinition {
    const [{ partitionTypeToApplicationRawIdMap }] =
        useExecuteOperation(
            useAadDefinition,
            PermissionManagementController.getAadTenantOnboardingInfo);

    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.identityProviderTenantTable.hooks.useDefinition.hooks.useAadDefinition",
            () => ({
                columns: {
                    permissions: "Permissions",
                    rawId: "ID"
                }
            }));

    return new TenantDefinition([
        <DataTableColumn
            id={TableColumnId.AadRawId}
            itemProperty={(item: Contract.AadTenantModel) => item.configuration.rawId}
            key={TableColumnId.AadRawId}
            title={localization.columns.rawId()}/>,
        <DataTableColumn
            id={TableColumnId.AadPermissions}
            key={TableColumnId.AadPermissions}
            render={
                ({ item }: DataTableColumnRenderProps<Contract.AadTenantModel>) =>
                    <PermissionsCell
                        item={item}
                        partitionTypeToApplicationRawIdMap={partitionTypeToApplicationRawIdMap}/>}
            title={localization.columns.permissions()}/>
    ]);
}