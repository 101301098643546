﻿import { EmptyMessageText, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { RiskContentProps } from "../../../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, usePrincipalModelAccess, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../../../../common";
import { useGcpIamServiceAccountDisabledTranslator } from "../../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useOpenRiskedEntityRisksStep } from "../../../../../../../useOpenRiskedEntityRisksStep";
import { useResourceGeneralInformationStep } from "../../../../../../../useResourceGeneralInformationStep";
import { EntityExternalConsoleLink } from "../../../../../../components";
import { RoleBindingTable } from "../../../components";
import { getSeverityValue, useGetServiceAccountSeverity } from "../../useGetServiceAccountSeverity";

export function useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountDefinition(riskModel: Contract.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskModel) {
    const serviceAccountModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.GcpIamServiceAccountModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountDefinition",
            () => ({
                description: "Service account {{serviceAccountId}} has {{roleIds}} assigned at the project level or above",
                sections: {
                    resolution: {
                        step1: "Before deleting the role bindings, assign the service account role bindings to specific service accounts",
                        step2: "{{entityExternalConsoleLink}} and filter on **Principal:{{serviceAccountMail}}**",
                        step3: "Under **Permissions** delete the role bindings to {{roleIds}}"
                    },
                    roleBindingIds: {
                        title: "Role Bindings",
                        unknown: "Learning"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            roleIds:
                <InlineEntities
                    entityIdsOrModels={riskModel.roleIds}
                    entityTypeName={Contract.TypeNames.GcpIamRole}
                    variant="itemAndTypeOrItemCountAndType"/>,
            serviceAccountId:
                <Entity
                    entityIdOrModel={serviceAccountModel}
                    variant="text"/>
        }),
        () =>
            _.concat(
                localization.sections.resolution.step1(),
                _(riskModel.risk.orderedResourceManagerResourceIds).
                    flatMap(
                        resourceManagerResourceId => [
                            localization.sections.resolution.step2({
                                entityExternalConsoleLink:
                                    <EntityExternalConsoleLink
                                        entityId={resourceManagerResourceId}
                                        page={Contract.GcpConsolePage.Permissions}/>,
                                serviceAccountMail: riskModel.risk.principalMail
                            }),
                            localization.sections.resolution.step3({
                                roleIds:
                                    <InlineEntities
                                        entityIdsOrModels={riskModel.risk.resourceManagerResourceIdToRoleIdsMap[resourceManagerResourceId]}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })
                        ]).
                    value()),
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <RoleBindingTable
                        csvExportFilePrefixes={[localization.sections.roleBindingIds.title()]}
                        risk={riskModel.risk}
                        unknownMessageText={new EmptyMessageText(localization.sections.roleBindingIds.unknown())}/>,
                    localization.sections.roleBindingIds.title())
            ]
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const risk = riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk;
    const serviceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamServiceAccountModel;
    const serviceAccountModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(serviceAccountModel.id);
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(serviceAccountModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(serviceAccountModel);
    const [severity, severityServiceAccountIds] = useGetServiceAccountSeverity(risk);
    const iamServiceAccountDisabledTranslator = useGcpIamServiceAccountDisabledTranslator();
    const riskSeverityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountDefinition.contextSection",
            () => ({
                disabled: "The service account is {{disabled}}",
                originatorResourceIds: "The service account is assigned to {{originatorResourceIds}}",
                serviceAccountIds: {
                    withHighSeverityServiceAccountIds: "The service account can impersonate {{serviceAccountIds}} in project {{tenantId}}, {{highSeverityServiceAccountIds}} with {{severity}} severity permissions",
                    withoutHighSeverityServiceAccountIds: "The service account can impersonate {{serviceAccountIds}} in project {{tenantId}}, none with high severity permissions"
                },
                signInActivityTime: {
                    everExact: "The service account was last active {{signInActivityTime | TimeFormatter.humanizePastDuration}}",
                    everOlderThan: "The service account was last active more than {{signInActivityTime | TimeFormatter.humanizePastDuration}}",
                    never: "The service account was not active since it was created"
                }
            }));


    return (
        <Steps>
            {_.filter([
                resourceGeneralInformationStep,
                risk.signInActivity!.timeType === Contract.ActivityTimeType.Unknown
                    ? undefined
                    : _.isNil(risk.signInActivity!.time)
                        ? localization.signInActivityTime.never()
                        : risk.signInActivity!.timeType === Contract.ActivityTimeType.Exact
                            ? localization.signInActivityTime.everExact({ signInActivityTime: risk.signInActivity!.time })
                            : localization.signInActivityTime.everOlderThan({ signInActivityTime: risk.signInActivity!.time }),
                localization.disabled({ disabled: iamServiceAccountDisabledTranslator(risk.identityDisabled!) }),
                _.isEmpty(serviceAccountModelAccess.originatorEntityIds)
                    ? undefined
                    : localization.originatorResourceIds({
                        originatorResourceIds:
                            <InlineEntities
                                entityIdsOrModels={serviceAccountModelAccess.originatorEntityIds}
                                entityTypeName={Contract.TypeNames.GcpScopeResource}
                                variant="itemAndTypeOrItemCountAndType"/>
                    }),
                (getSeverityValue(severity) >= getSeverityValue(Contract.Severity.High)
                    ? localization.serviceAccountIds.withHighSeverityServiceAccountIds
                    : localization.serviceAccountIds.withoutHighSeverityServiceAccountIds)({
                    highSeverityServiceAccountIds:
                        <InlineEntities
                            entityIdsOrModels={severityServiceAccountIds}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            variant="itemCountAndType"/>,
                    serviceAccountIds:
                        <InlineEntities
                            entityIdsOrModels={_.keys(risk.serviceAccountIdToSeverityMap)}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            variant="itemCountAndType"/>,
                    severity:
                        _.isNil(severity)
                            ? undefined
                            : severityTranslator(severity!, "text"),
                    tenantId:
                        <Entity
                            entityIdOrModel={risk.tenantId}
                            variant="text"/>
                }),
                openRiskedEntityRisksStep,
                riskSeverityReasonTranslator(
                    risk.severity,
                    risk.severityReason!)
            ])}
        </Steps>);
}