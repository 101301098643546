﻿import { CheckboxField, Dropdown, DropdownActions, DropdownIcon, useLocalization } from "@infrastructure";
import { List, ListItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Contract, PrincipalRiskCategoryHelper, usePrincipalTypeTranslator, useTheme } from "../../../../../../../../..";

type PrincipalRiskCategoryMultiSelectProps = {
    onSelectedPrincipalRiskCategoriesChanged: (selectedPrincipalRiskCategories: Contract.PrincipalRiskCategory[]) => void;
    principalRiskCategories: Contract.PrincipalRiskCategory[];
    selectedPrincipalRiskCategories: Contract.PrincipalRiskCategory[];
};

export function PrincipalRiskCategoryMultiSelect({ onSelectedPrincipalRiskCategoriesChanged, principalRiskCategories, selectedPrincipalRiskCategories }: PrincipalRiskCategoryMultiSelectProps) {
    const actionsRef = useRef<DropdownActions>();
    const [open, setOpen] = useState(false);
    const principalTypeTranslator = usePrincipalTypeTranslator();

    const principalRiskCategoryToPrincipalTypeMap =
        useMemo(
            () =>
                _(principalRiskCategories).
                    keyBy(principalRiskCategory => principalRiskCategory).
                    mapValues(principalRiskCategory => PrincipalRiskCategoryHelper.getPrincipalType(principalRiskCategory)).
                    value(),
            [principalRiskCategories]);

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetExcessivePermissionPrincipalTrendsDefinition.principalRiskCategoryMultiSelect",
            () => ({
                placeholder: "Identity Type",
                selection: "{{selectionCount | NumberFormatter.humanize}} identity types"
            }));

    const theme = useTheme();
    return (
        <Dropdown
            actionsRef={actionsRef}
            className="principalRiskCategoryMultiSelect"
            justify="right"
            open={open}
            popoverElement={
                <List
                    dense={true}
                    sx={{ padding: theme.spacing(0.25) }}>
                    {_.map(
                        principalRiskCategories,
                        principalRiskCategory =>
                            <ListItem
                                dense={true}
                                key={principalRiskCategory}>
                                <CheckboxField
                                    checked={_.includes(selectedPrincipalRiskCategories, principalRiskCategory)}
                                    onChange={
                                        (_event, checked) =>
                                            onSelectedPrincipalRiskCategoriesChanged(
                                                checked
                                                    ? _.concat(selectedPrincipalRiskCategories, principalRiskCategory)
                                                    : _.without(selectedPrincipalRiskCategories, principalRiskCategory))}>
                                    <Typography>
                                        {principalTypeTranslator(principalRiskCategoryToPrincipalTypeMap[principalRiskCategory])}
                                    </Typography>
                                </CheckboxField>
                            </ListItem>)}
                </List>}
            popoverElementContainerSx={{ padding: 0 }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                sx={{
                    "&:hover": {
                        border: theme.border.hoverFocus
                    },
                    border:
                        open
                            ? theme.border.hoverFocus
                            : theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(0.5, 0.5, 0.5, 1.5)
                }}>
                <Typography
                    noWrap={true}
                    sx={{ fontSize: "inherit" }}>
                    {_.isEmpty(selectedPrincipalRiskCategories)
                        ? localization.placeholder()
                        : localization.selection({ selectionCount: _.size(selectedPrincipalRiskCategories) })}
                </Typography>
                <DropdownIcon sx={{ fontSize: "18px" }}/>
            </Stack>
        </Dropdown>);
}