﻿import { UnexpectedError } from "@infrastructure";
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../controllers";
import { useTheme } from "../themes";

type CodeServerIconProps =
    SvgIconProps & {
        serverType: Contract.CodeTenantType;
    };

export function CodeServerIcon({ serverType, ...props }: CodeServerIconProps) {
    const theme = useTheme();
    switch (serverType) {
        case Contract.CodeTenantType.GitHub:
            return (
                <SvgIcon {...props}>
                    <path
                        d="M1 12.2995C1 6.0589 5.92481 1 12 1C16.0111 1 19.5208 3.20531 21.4426 6.5H18.015C18.3417 4.63479 17.9602 4.52087 17.7831 4.22977C17.1288 3.99205 15.5074 4.84113 14.6217 5.43964C13.1773 5.00768 10.1246 5.05019 8.97997 5.55133C6.86782 4.00589 5.75016 4.24262 5.75016 4.24262C5.75016 4.24262 5.02607 5.56517 5.55771 7.50007C4.86248 8.40499 4.34479 9.04551 4.34479 10.7427C4.34479 11.699 4.45497 12.555 4.70323 13.3023L4.76241 13.4698L4.9616 13.9374L5.04676 14.1024C5.84206 15.5747 7.37252 16.491 9.95425 16.7441C9.58571 17.0297 9.14307 17.5724 9.08197 18.1991C8.61864 18.5055 7.68718 18.6063 6.9626 18.3731C5.94743 18.0454 5.55916 15.9924 4.03928 16.2849C3.71019 16.3482 3.77562 16.5701 4.05997 16.7579C4.5257 17.0643 4.95871 17.4474 5.2955 18.2633C5.5529 18.89 6.09706 20.0089 7.8142 20.0089C8.20218 20.0118 8.58982 19.9842 8.97371 19.9264C8.97371 19.9264 8.98719 21.5252 8.98719 22.147C8.98719 22.5512 8.68359 22.7919 8.42042 22.9871C4.10279 21.4615 1 17.2531 1 12.2995Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        clipRule="evenodd"
                        d="M13.9998 8.33342C12.8953 8.33342 11.9998 9.22885 11.9998 10.3334V21.0001C11.9998 22.1047 12.8953 23.0001 13.9998 23.0001H20.9998C22.1044 23.0001 22.9998 22.1046 22.9998 21.0001V10.3334C22.9998 9.22885 22.1044 8.33342 20.9998 8.33342H13.9998ZM15.6666 10.1674C15.211 10.1674 14.8416 10.5368 14.8416 10.9924C14.8416 11.448 15.211 11.8174 15.6666 11.8174H19.3333C19.7889 11.8174 20.1583 11.448 20.1583 10.9924C20.1583 10.5368 19.7889 10.1674 19.3333 10.1674H15.6666ZM14.8416 14.2007C14.8416 13.745 15.211 13.3757 15.6666 13.3757H19.3333C19.7889 13.3757 20.1583 13.745 20.1583 14.2007C20.1583 14.6563 19.7889 15.0257 19.3333 15.0257H15.6666C15.211 15.0257 14.8416 14.6563 14.8416 14.2007ZM17.5 20.7085C18.0063 20.7085 18.4167 20.2981 18.4167 19.7918C18.4167 19.2856 18.0063 18.8752 17.5 18.8752C16.9937 18.8752 16.5833 19.2856 16.5833 19.7918C16.5833 20.2981 16.9937 20.7085 17.5 20.7085Z"
                        fill={theme.palette.text.primary}
                        fillRule="evenodd"/>
                </SvgIcon>);
        case Contract.CodeTenantType.GitLab:
            return (
                <SvgIcon {...props}>
                    <path
                        clipRule="evenodd"
                        d="M12.6686 22.6019C12.4978 22.3073 12.4 21.9651 12.4 21.6001V10.6001C12.4 9.49553 13.2954 8.6001 14.4 8.6001H21.4C21.6118 8.6001 21.816 8.63303 22.0076 8.69406L19.388 1.51536C19.3272 1.35621 19.2205 1.22105 19.0828 1.12883C18.9451 1.03662 18.7834 0.991984 18.6204 1.00118C18.4573 1.01038 18.3011 1.07296 18.1736 1.18013C18.0462 1.2873 17.9538 1.43369 17.9096 1.59877L15.911 8.02434H7.81789L5.81928 1.59877C5.77406 1.43455 5.68127 1.28919 5.55381 1.18287C5.42634 1.07656 5.27052 1.01456 5.10798 1.00549C4.94544 0.996422 4.78424 1.04073 4.64677 1.13226C4.50929 1.2238 4.40237 1.35801 4.34083 1.51628L1.38219 9.62661L1.35164 9.70819C0.925665 10.8775 0.873235 12.1606 1.20226 13.3639C1.53128 14.5673 2.22391 15.6256 3.1757 16.3794L3.18617 16.3886L3.21236 16.4087L7.71578 19.9533L9.95004 21.727L11.3063 22.805C11.4656 22.9315 11.6599 23 11.8596 23C12.0594 23 12.2537 22.9315 12.413 22.805L12.6686 22.6019Z"
                        fill="#E24329"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M12.4 16.2461L11.8682 16.6685L12.4 17.0907V16.2461Z"
                        fill="#FC6D26"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M12.4 17.0923L11.8653 16.668L7.71533 19.9532L9.94959 21.7268L11.3067 22.8048C11.466 22.9313 11.6603 22.9998 11.8601 22.9998C12.0598 22.9998 12.2541 22.9313 12.4134 22.8048L12.6686 22.6021C12.4978 22.3075 12.4 21.9654 12.4 21.6003V17.0923Z"
                        fill="#FCA326"
                        fillRule="evenodd"/>
                    <path
                        d="M5.36108 11.506C4.18278 10.5789 2.82368 9.93719 1.38131 9.62695L1.35164 9.70853C0.925665 10.8778 0.873235 12.1609 1.20226 13.3643C1.53128 14.5676 2.22391 15.626 3.1757 16.3798L3.18617 16.3889L3.21236 16.4091L7.71578 19.9537L11.857 16.6685L5.36021 11.506H5.36108Z"
                        fill="#FC6D26"/>
                    <path
                        clipRule="evenodd"
                        d="M15 9.19922C13.8954 9.19922 13 10.0947 13 11.1992V21.8659C13 22.9705 13.8954 23.8659 15 23.8659H22C23.1046 23.8659 24 22.9705 24 21.8659V11.1992C24 10.0946 23.1046 9.19922 22 9.19922H15ZM16.6668 11.0332C16.2112 11.0332 15.8418 11.4026 15.8418 11.8582C15.8418 12.3138 16.2112 12.6832 16.6668 12.6832H20.3335C20.7891 12.6832 21.1585 12.3138 21.1585 11.8582C21.1585 11.4026 20.7891 11.0332 20.3335 11.0332H16.6668ZM15.8418 15.0665C15.8418 14.6108 16.2112 14.2415 16.6668 14.2415H20.3335C20.7891 14.2415 21.1585 14.6108 21.1585 15.0665C21.1585 15.5221 20.7891 15.8915 20.3335 15.8915H16.6668C16.2112 15.8915 15.8418 15.5221 15.8418 15.0665ZM18.5002 21.5743C19.0064 21.5743 19.4168 21.1639 19.4168 20.6576C19.4168 20.1514 19.0064 19.741 18.5002 19.741C17.9939 19.741 17.5835 20.1514 17.5835 20.6576C17.5835 21.1639 17.9939 21.5743 18.5002 21.5743Z"
                        fill="#FC6D26"
                        fillRule="evenodd"/>
                </SvgIcon>);
        default:
            throw new UnexpectedError("serverType", serverType);
    }
}