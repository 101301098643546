import { Optional } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, RiskPoliciesType, RiskPolicyItem, scopeNodeModelStore } from "../../../../../common";
import { useRiskPoliciesItemConfiguration } from "./useRiskPoliciesItemConfiguration";

export function useGetGeneralInheritToggleInheritedValues(
    riskPoliciesType: RiskPoliciesType,
    riskPolicyConfiguration: Contract.RiskPolicyConfiguration,
    riskPolicyItem: RiskPolicyItem,
    getAdditivePropertyNameToValueMap?: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => _.Dictionary<any[]>,
    getOverridablePropertyNameToValueMap?: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => _.Dictionary<any>) {
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const { inheritDisabled, parentRiskPolicyModels, scopeIdToRiskPolicyModelMap } =
        useRiskPoliciesItemConfiguration(
            riskPoliciesType,
            riskPolicyItem,
            riskPolicyConfiguration.scopeId);

    return useMemo(
        () => {
            let additivePropertyNameToDataMap = {} as _.Dictionary<any>;
            let overridablePropertyNameToDataMap = {} as _.Dictionary<any>;

            if (!_.isNil(getAdditivePropertyNameToValueMap)) {
                const parentsPropertyNameToValuesMap =
                    inheritDisabled
                        ? {}
                        : _(parentRiskPolicyModels).
                            map(riskPolicyModel => getAdditivePropertyNameToValueMap(riskPolicyModel.riskPolicyConfiguration)).
                            aggregate(
                                (additivePropertyValue, otherAdditivePropertyValue) =>
                                    _(additivePropertyValue).
                                        concat(otherAdditivePropertyValue).
                                        filter().
                                        uniq().
                                        value()).
                            value();

                additivePropertyNameToDataMap =
                    _.mapValues(
                        getAdditivePropertyNameToValueMap(riskPolicyConfiguration),
                        (propertyValue, propertyName) => ({
                            inheritedValues: parentsPropertyNameToValuesMap[propertyName],
                            values: propertyValue
                        }));
            }

            if (!_.isNil(getOverridablePropertyNameToValueMap)) {
                overridablePropertyNameToDataMap =
                    _.mapValues(
                        getOverridablePropertyNameToValueMap(riskPolicyConfiguration),
                        propertyValue => ({
                            inheritedScopeId: undefined as Optional<string>,
                            inheritedValue: undefined as Optional<any>,
                            value: propertyValue
                        }));

                if (!inheritDisabled) {
                    const parentScopeIds = scopeNodeMap[riskPolicyConfiguration.scopeId].parentScopeIds;
                    for (const parentScopeId of parentScopeIds) {
                        if (_.every(overridablePropertyNameToDataMap, propertyData => !_.isNil(propertyData.value ?? propertyData.inheritedValue))) {
                            break;
                        }

                        const parentPropertyNameToValueMap = getOverridablePropertyNameToValueMap(scopeIdToRiskPolicyModelMap[parentScopeId].riskPolicyConfiguration);
                        overridablePropertyNameToDataMap =
                            _.mapValues(
                                overridablePropertyNameToDataMap,
                                (propertyData, propertyName) =>
                                    _.isNil(propertyData.value ?? propertyData.inheritedValue) &&
                                    !_.isNil(parentPropertyNameToValueMap[propertyName])
                                        ? {
                                            ...propertyData,
                                            inheritedScopeId: parentScopeId,
                                            inheritedValue: parentPropertyNameToValueMap[propertyName]
                                        }
                                        : propertyData);
                    }
                }
            }

            return {
                additivePropertyNameToDataMap,
                inherited: _.isNil(riskPolicyConfiguration.enabled),
                overridablePropertyNameToDataMap
            };
        },
        [inheritDisabled, parentRiskPolicyModels, riskPolicyConfiguration, scopeIdToRiskPolicyModelMap, scopeNodeMap]);
}