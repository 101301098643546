import _ from "lodash";
import { Contract } from "../../../../../../../common";

export function resourceTagsAreEqual(resourceTagPatterns: Contract.ResourceTagPattern[], resourceTagPatternItems: Contract.ResourceTagPattern[]) {
    if (resourceTagPatterns.length != resourceTagPatternItems.length) {
        return false;
    }

    const resourceTagPatternIdSet =
        new Set(
            _.map(
                resourceTagPatterns,
                resourceTagPattern => `${resourceTagPattern.keyPattern}_${resourceTagPattern.valuePattern}_${resourceTagPattern.caseSensitive}`));
    const resourceTagPatternItemIdSet =
        new Set(
            _.map(
                resourceTagPatternItems,
                resourceTagPatternItem => `${resourceTagPatternItem.keyPattern}_${resourceTagPatternItem.valuePattern}_${resourceTagPatternItem.caseSensitive}`));
    return _.every(
        [...resourceTagPatternIdSet],
        (resourceTagPatternId: string) => resourceTagPatternItemIdSet.has(resourceTagPatternId));
}