import { InlineItems, Link, Loading, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { InstanceTable } from "../../components";
import { ContextSection } from "./components";

export function useAwsEc2InstanceUserDataSecretExistsRiskClusterDefinition(riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel) {
    const clusterModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEksClusterModel;
    const cluster = (clusterModel.entity) as Contract.AwsEksCluster;

    const instanceModels = entityModelStore.useGet(riskModel.risk.aggregatedEntityIds) as Contract.AwsEc2InstanceModel[];
    const karpenterNodeClassNames =
        useMemo(
            () =>
                _(instanceModels).
                    map(instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).karpenterNodeClassName).
                    filter().
                    uniq().
                    value(),
            [riskModel]);

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskClusterDefinition",
            () => ({
                description: "{{cluster}} runs EC2 instances that are exposing secrets in clear text vis user data",
                sections: {
                    details: "EC2 Instances",
                    resolution: {
                        step1: {
                            karpenter: {
                                false: "The {{translatedClusterTypeName}} is spinning up and down EC2 instances. Please update the cluster’s configuration to ensure the instances are launched with the correct configuration. Migrate your secrets which are stored in the **User Data** to {{secretsManagerLink}}.",
                                karpenterNodeClasses: [
                                    "1 Karpenter EC2 Node Class",
                                    "{{count | NumberFormatter.humanize}} Karpenter EC2 Node Classes"
                                ],
                                link: "Secrets Manager",
                                true: "The {{translatedClusterTypeName}} is using **Karpenter** to spin up and down EC2 instances. Migrate your secrets which are stored in the **User Data** to {{secretsManagerLink}}. Update the template on {{karpenterNodeClasses}} to run the instance with the new secured template."
                            }
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Eks,
                AwsConsoleUrlBuilder.getEksClusterUrl(cluster)),
            _.isEmpty(karpenterNodeClassNames)
                ? localization.sections.resolution.step1.karpenter.false({
                    secretsManagerLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={riskModel.secretsManagerDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step1.karpenter.link()}
                        </Link>,
                    translatedClusterTypeName:
                        entityTypeNameTranslator(
                            cluster.typeName,
                            { variant: "text" })
                })
                : localization.sections.resolution.step1.karpenter.true({
                    karpenterNodeClasses:
                        <InlineItems
                            items={karpenterNodeClassNames}
                            namePluralizer={localization.sections.resolution.step1.karpenter.karpenterNodeClasses}
                            variant="itemCountAndType"/>,
                    secretsManagerLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={riskModel.secretsManagerDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step1.karpenter.link()}
                        </Link>,
                    translatedClusterTypeName:
                        entityTypeNameTranslator(
                            cluster.typeName,
                            { variant: "text" })
                })
        ],
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections:
                _<RiskDefinitionSection>([]).
                    concatIf(
                        !_.isEmpty(riskModel.risk.aggregatedEntityIds),
                        () =>
                            new RiskDefinitionSection(
                                <Loading>
                                    <InstanceTable
                                        instanceIds={riskModel.risk.aggregatedEntityIds}
                                        riskModel={riskModel}/>
                                </Loading>,
                                localization.sections.details()
                            )).
                    value()
        });
}