import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, EntityController, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { AwsElbClassicLoadBalancerListenersTable } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonNetworkingInfoItemElements } from "./useAwsCommonNetworkingInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsElbClassicLoadBalancerDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const classicLoadBalancer = resourceModel.entity as Contract.AwsElbClassicLoadBalancer;
    const classicLoadBalancerModel = resourceModel as Contract.AwsElbClassicLoadBalancerModel;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const [{ distributionIds }] =
        useExecuteOperation(
            [useAwsElbClassicLoadBalancerDefinition, classicLoadBalancerModel.id],
            async () => await EntityController.getAwsDistributionIds(new Contract.EntityControllerGetAwsDistributionIdsRequest(classicLoadBalancerModel.id)));
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            networkInterfaceIds: classicLoadBalancer.networkInterfaceIds,
            privateIpAddresses: classicLoadBalancer.privateIpAddresses,
            publicIpAddresses: classicLoadBalancer.publicIpAddresses,
            resourceModel
        });

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsElbClassicLoadBalancerDefinition",
            () => ({
                info: {
                    cards: {
                        listeners: "Listeners"
                    },
                    items: {
                        accessLogsEnabled: {
                            false: "Disabled",
                            title: "Access Logs",
                            true: "Enabled"
                        },
                        autoScalingGroups: "Auto Scaling Groups",
                        availabilityZones: "Availability Zones",
                        cloudFrontDistributions: "CloudFront Distributions",
                        dnsName: "DNS Name",
                        dnsZoneIds: "DNS Zones",
                        external: {
                            false: "Off",
                            title: "Internet Facing",
                            true: "On"
                        },
                        vpc: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                classicLoadBalancer.availabilityZones,
                                availabilityZone => availabilityZone.name)}
                        key="availabilityZones"
                        title={localization.info.items.availabilityZones()}/>,
                    <InfoItem
                        key="external"
                        title={localization.info.items.external.title()}
                        value={
                            classicLoadBalancer.external
                                ? localization.info.items.external.true()
                                : localization.info.items.external.false()}/>,
                    <InfoItem
                        key="dnsName"
                        title={localization.info.items.dnsName()}
                        value={classicLoadBalancer.dnsName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={classicLoadBalancerModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <InfoItem
                        key="accessLogsEnabled"
                        title={localization.info.items.accessLogsEnabled.title()}
                        value={
                            classicLoadBalancer.accessLogsEnabled
                                ? localization.info.items.accessLogsEnabled.true()
                                : localization.info.items.accessLogsEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={classicLoadBalancerModel.autoScalingGroupIds}
                        entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                        key="autoScalingGroups"
                        title={localization.info.items.autoScalingGroups()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={distributionIds}
                        entityTypeName={Contract.TypeNames.AwsCloudFrontDistribution}
                        key="cloudFrontDistributions"
                        title={localization.info.items.cloudFrontDistributions()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={classicLoadBalancer.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getPrivateIpAddresses()}
                    {commonNetworkCardInfoItems.getPublicIpAddresses()}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
                <InfoCard title={localization.info.cards.listeners()}>
                    <AwsElbClassicLoadBalancerListenersTable
                        instanceIds={classicLoadBalancer.instanceIds}
                        loadBalancerListenerIds={classicLoadBalancerModel.listenerIds}/>
                </InfoCard>
            </Info>
    });
}