import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAzureWebApplicationRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useAzureWebApplicationTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureWebApplicationHttpsOnlyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureWebApplicationHttpsOnlyDisabledRisk;
    const applicationModel = entityModelStore.useGet(risk.entityId) as Contract.AzureWebApplicationModel;
    const application = applicationModel.entity as Contract.AzureWebApplication;

    const getAzureWebApplicationRiskContext = useGetAzureWebApplicationRiskContext();

    const webApplicationTypeTranslator = useAzureWebApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureWebApplicationHttpsOnlyDisabledRiskDefinition",
            () => ({
                description: "{{applicationType}} {{application}} is not enforcing SSL connections",
                sections: {
                    resolution: {
                        step1: "Before enforcing HTTPS, verify that clients use only HTTPS to access the web app",
                        step2: {
                            function: "az functionapp update --resource-group {{resourceGroup}} --name {{name}} --set httpsOnly=true",
                            title: "Run the following command from Azure CLI: {{command}}",
                            web: "az webapp update --resource-group {{resourceGroup}} --name {{name}} --set httpsOnly=true"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            application:
                <Entity
                    entityIdOrModel={applicationModel}
                    variant="text"/>,
            applicationType: webApplicationTypeTranslator(application.type)
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.CertificatesReact}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.title({
                command:
                    (application.type === Contract.AzureWebApplicationType.Web
                        ? localization.sections.resolution.step2.web
                        : localization.sections.resolution.step2.function)({
                        name:
                            <Entity
                                entityIdOrModel={application.id}
                                linkOptions={{ disabled: true }}
                                variant="text"/>,
                        resourceGroup:
                            <Entity
                                entityIdOrModel={application.resourceGroupId}
                                linkOptions={{ disabled: true }}
                                variant="text"/>
                    })
            })],
        riskModel,
        () => {
            const applicationRiskContext = getAzureWebApplicationRiskContext(applicationModel);
            return [
                applicationRiskContext.generalInformation,
                applicationRiskContext.sensitive,
                applicationRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}