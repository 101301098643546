import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingSecurityListStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingSecurityListStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingSecurityListStatus]: {
                    [Contract.OciNetworkingSecurityListStatus.Available]: "Available",
                    [Contract.OciNetworkingSecurityListStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingSecurityListStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingSecurityListStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciNetworkingSecurityListStatus) =>
        localization[Contract.TypeNames.OciNetworkingSecurityListStatus][status]();
}