﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SwitzerlandIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5288)">
                <rect
                    fill="white"
                    height="16"
                    rx="2"
                    width="24"
                    x="1"
                    y="4"/>
                <path
                    d="M1 4.00012H25V20.0001H1V4.00012Z"
                    fill="#DA291C"/>
                <path
                    d="M11.3931 6.63196H14.6067V10.3896H18.3558V13.6105H14.6067V17.3681H11.3931V13.6105H7.64392V10.3896H11.3931V6.63196Z"
                    fill="white"/>
            </g>
            <rect
                height="16"
                rx="2"
                stroke="#A8B7D4"
                strokeLinejoin="round"
                strokeWidth="0.0541275"
                width="24"
                x="1"
                y="4"/>
            <defs>
                <clipPath id="clip0_60203_5288">
                    <rect
                        fill="white"
                        height="16"
                        rx="2"
                        width="24"
                        x="1"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}