import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class PingIdentityTenantsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.PingIdentityTenantConfiguration) {
    }
}

export const [usePingIdentityTenantsContext, useSetPingIdentityTenantsContext, usePingIdentityTenantsContextProvider] = makeContextProvider<PingIdentityTenantsContext>();

export function PingIdentityTenants() {
    const [context, setContext, ContextProvider] = usePingIdentityTenantsContextProvider(() => new PingIdentityTenantsContext(false));

    return (
        <ContextProvider>
            {context.addOrEditOpen != false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={() => setContext(new PingIdentityTenantsContext(false))}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}