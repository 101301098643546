import { makeContextProvider, useLocalization, Wizard, WizardItem } from "@infrastructure";
import React from "react";
import { cloudRiskPolicyItemsOperationStore, Contract, ScopeHelper, scopeNodeModelStore } from "../../../../../../../../../../../../common";
import { RiskPolicyItem, RiskPolicyTypeItem, RiskPolicyTypeItemCategory, TenantTypeItem } from "./components";

export class AddContext {
    public riskPolicyConfigurationTypeName?: string;
    public riskPolicyTypeItemCategory?: RiskPolicyTypeItemCategory;

    constructor(
        public scopeId: string,
        public tenantType?: Contract.TenantType) {
    }
}

export const [useAddContext, useSetAddContext, useAddContextProvider] = makeContextProvider<AddContext>();

type AddProps = {
    onFinish: () => void;
    scopeId: string;
};

export function Add({ onFinish, scopeId }: AddProps) {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);

    const [, , ContextProvider] =
        useAddContextProvider(
            () =>
                new AddContext(
                    scopeId,
                    ScopeHelper.getTenantType(scopeNodeModel)
                ));

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useDefinition.hooks.cloud.hooks.useCloudRiskPoliciesCustomDefinition.add",
            () => ({
                items: {
                    riskPolicy: {
                        subtitle: "Choose the name, description, severity and details of the policy.",
                        title: "Add Template Details"
                    },
                    riskPolicyType: {
                        subtitle: "Choose the template that best describes the policy you would like to enforce.",
                        title: "Choose Policy Template"
                    },
                    tenantType: {
                        subtitle: "Select the Cloud provider you would like to enforce the policy on.",
                        title: "Choose Cloud Provider"
                    }
                },
                title: "Custom Policy"
            }));

    return (
        <ContextProvider>
            <Wizard
                startItemIndex={0}
                titleOptions={{ text: localization.title() }}
                onFinished={
                    async (canceled: boolean) => {
                        onFinish();
                        if (!canceled) {
                            await cloudRiskPolicyItemsOperationStore.notifyAll();
                        }
                    }}>
                {scopeNodeModel.configuration.typeName === Contract.TypeNames.CustomerConfiguration &&
                    <WizardItem
                        deferredLoading={true}
                        disablePadding={true}
                        subtitle={localization.items.tenantType.subtitle()}
                        title={localization.items.tenantType.title()}>
                        <TenantTypeItem/>
                    </WizardItem>}
                <WizardItem
                    deferredLoading={true}
                    disablePadding={true}
                    subtitle={localization.items.riskPolicyType.subtitle()}
                    title={localization.items.riskPolicyType.title()}>
                    <RiskPolicyTypeItem/>
                </WizardItem>
                <WizardItem
                    subtitle={localization.items.riskPolicy.subtitle()}
                    title={localization.items.riskPolicy.title()}>
                    <RiskPolicyItem/>
                </WizardItem>
            </Wizard>
        </ContextProvider>);
}