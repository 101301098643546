import { NotValidIcon, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../common";

export type S3PublicAccessPolicySectionProps = {
    bucketPublicAccessPolicy: Contract.AwsS3PublicAccessPolicy;
    tenantPublicAccessPolicy: Contract.AwsS3PublicAccessPolicy;
};

export function S3PublicAccessPolicySection({ bucketPublicAccessPolicy, tenantPublicAccessPolicy }: S3PublicAccessPolicySectionProps) {
    const theme = useTheme();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.s3PublicAccessPolicySection",
            () => ({
                fields: {
                    blockAnyAcls: "**Block any ACLs (IgnorePublicAcls)**",
                    blockAnyPolicy: "**Block any policy (RestrictPublicBuckets)**",
                    blockNewAcls: "**Block new ACLs (BlockPublicAcls)**",
                    blockNewPolicy: "**Block new policy (BlockPublicPolicy)**",
                    disabled: "Not enabled",
                    enabled: "Enabled",
                    enabledInherited: "Enabled (inherited from account settings)"
                }
            }));
    return (
        <Stack>
            <Typography>
                {localization.fields.blockNewAcls()}
            </Typography>
            {tenantPublicAccessPolicy.blockNewAcls
                ? localization.fields.enabledInherited()
                : bucketPublicAccessPolicy.blockNewAcls
                    ? localization.fields.enabled()
                    : <Typography
                        sx={{
                            color: theme.palette.error.main,
                            display: "unset",
                            fontWeight: 600
                        }}>
                        {localization.fields.disabled()}
                        <NotValidIcon
                            sx={{
                                color: theme.palette.error.main,
                                display: "unset",
                                marginBottom: theme.spacing(0.25),
                                padding: theme.spacing(0.25),
                                verticalAlign: "middle"
                            }}/>
                    </Typography>}
            <Typography sx={{ marginTop: theme.spacing(3) }}>
                {localization.fields.blockAnyAcls()}
            </Typography>
            {tenantPublicAccessPolicy.blockAnyAcls
                ? localization.fields.enabledInherited()
                : bucketPublicAccessPolicy.blockAnyAcls
                    ? localization.fields.enabled()
                    : <Typography
                        sx={{
                            color: theme.palette.error.main,
                            display: "unset",
                            fontWeight: 600
                        }}>
                        {localization.fields.disabled()}
                        <NotValidIcon
                            sx={{
                                color: theme.palette.error.main,
                                display: "unset",
                                marginBottom: theme.spacing(0.25),
                                padding: theme.spacing(0.25),
                                verticalAlign: "middle"
                            }}/>
                    </Typography>}
            <Typography sx={{ marginTop: theme.spacing(3) }}>
                {localization.fields.blockNewPolicy()}
            </Typography>
            {tenantPublicAccessPolicy.blockNewPolicy
                ? localization.fields.enabledInherited()
                : bucketPublicAccessPolicy.blockNewPolicy
                    ? localization.fields.enabled()
                    : <Typography
                        sx={{
                            color: theme.palette.error.main,
                            display: "unset",
                            fontWeight: 600
                        }}>
                        {localization.fields.disabled()}
                        <NotValidIcon
                            sx={{
                                color: theme.palette.error.main,
                                display: "unset",
                                marginBottom: theme.spacing(0.25),
                                padding: theme.spacing(0.25),
                                verticalAlign: "middle"
                            }}/>
                    </Typography>}
            <Typography sx={{ marginTop: theme.spacing(3) }}>
                {localization.fields.blockAnyPolicy()}
            </Typography>
            {tenantPublicAccessPolicy.blockAnyPolicy
                ? localization.fields.enabledInherited()
                : bucketPublicAccessPolicy.blockAnyPolicy
                    ? localization.fields.enabled()
                    : <Typography
                        sx={{
                            color: theme.palette.error.main,
                            display: "unset",
                            fontWeight: 600
                        }}>
                        {localization.fields.disabled()}
                        <NotValidIcon
                            sx={{
                                color: theme.palette.error.main,
                                display: "unset",
                                marginBottom: theme.spacing(0.25),
                                padding: theme.spacing(0.25),
                                verticalAlign: "middle"
                            }}/>
                    </Typography>}
        </Stack>);
}