import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetOciObjectStorageBucketRiskContext } from "../contexts";

export function useOciObjectStorageBucketObjectVersioningDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciObjectStorageBucketObjectVersioningDisabledRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.OciObjectStorageBucketModel;

    const getOciObjectStorageBucketRiskContext = useGetOciObjectStorageBucketRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciObjectStorageBucketObjectVersioningDisabledRiskDefinition",
            () => ({
                description: "{{bucket}} object versioning is not enabled",
                sections: {
                    resolution: {
                        step1: "Under **Features**, click **Edit** next to **Object Versioning**",
                        step2: "Note that object versioning cannot be disabled once enabled. Versioning can only be suspended",
                        step3: "Click **Enable Versioning**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.ObjectStorage,
                bucketModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const bucketRiskContext = getOciObjectStorageBucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.sensitive,
                bucketRiskContext.accessLevel,
                bucketRiskContext.encryption,
                bucketRiskContext.storageSize,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}