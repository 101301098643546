import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MaliciousFilesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M0.553199 12.1069C3.42907 10.6707 7.51831 9.80151 12 9.80151C16.4816 9.80151 20.5709 10.6707 23.4468 12.1069C23.9409 12.3536 24.1414 12.9542 23.8946 13.4483C23.6479 13.9424 23.0473 14.1429 22.5532 13.8962C20.0237 12.6329 16.2589 11.8015 12 11.8015C7.74104 11.8015 3.97628 12.6329 1.44675 13.8962C0.952653 14.1429 0.352078 13.9424 0.10533 13.4483C-0.141418 12.9542 0.0590996 12.3536 0.553199 12.1069Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19.5241 3.72987C19.524 3.72971 19.5241 3.73004 19.5241 3.72987L20.6878 11.1962C20.7728 11.7419 20.3994 12.2532 19.8537 12.3382C19.308 12.4233 18.7967 12.0499 18.7116 11.5042L17.5478 4.03738C17.4156 3.18595 16.5069 2.75492 15.7933 3.14929L15.1177 3.52281C15.1176 3.52288 15.1178 3.52274 15.1177 3.52281C14.1636 4.05132 13.0904 4.32881 11.9997 4.32881C10.909 4.32881 9.83618 4.05153 8.88207 3.52302C8.88195 3.52295 8.8822 3.52309 8.88207 3.52302L8.20661 3.14959C8.20655 3.14956 8.20668 3.14963 8.20661 3.14959C7.49254 2.75567 6.58377 3.18703 6.45164 4.03699L5.28777 11.5042C5.20272 12.0499 4.6914 12.4233 4.1457 12.3382C3.6 12.2532 3.22658 11.7419 3.31163 11.1962L4.47537 3.72976C4.47535 3.72989 4.47539 3.72963 4.47537 3.72976C4.81645 1.53745 7.21641 0.318735 9.173 1.39856L9.85013 1.77292C10.5077 2.13724 11.2479 2.32881 11.9997 2.32881C12.7515 2.32881 13.4909 2.13767 14.1485 1.77335L14.8258 1.39892C16.7829 0.317165 19.183 1.53682 19.5241 3.72987Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M4.92456 17.2303V17.2624C4.92456 18.6638 6.06064 19.7999 7.46206 19.7999H7.90325C8.60246 19.7999 9.22171 19.4576 9.60256 18.9314L4.92456 17.2303ZM4.01693 14.7721C3.48596 14.5791 2.92456 14.9723 2.92456 15.5373V17.2624C2.92456 19.7684 4.95607 21.7999 7.46206 21.7999H7.90325C10.1656 21.7999 11.9996 19.9659 11.9996 17.7036C11.9996 17.6864 11.9888 17.671 11.9726 17.6651L4.01693 14.7721Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19.0745 17.2303V17.2624C19.0745 18.6638 17.9384 19.7999 16.537 19.7999H16.0958C15.3966 19.7999 14.7773 19.4576 14.3965 18.9314L19.0745 17.2303ZM19.9821 14.7721C20.5131 14.5791 21.0745 14.9723 21.0745 15.5373V17.2624C21.0745 19.7684 19.043 21.7999 16.537 21.7999H16.0958C13.8334 21.7999 11.9995 19.9659 11.9995 17.7036C11.9995 17.6864 12.0102 17.671 12.0264 17.6651L19.9821 14.7721Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}