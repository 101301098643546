import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function FilterIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M4 6.00073C3.44772 6.00073 3 6.44845 3 7.00073C3 7.55302 3.44772 8.00073 4 8.00073H20C20.5523 8.00073 21 7.55302 21 7.00073C21 6.44845 20.5523 6.00073 20 6.00073H4ZM10 17.0007C10 17.553 10.4477 18.0007 11 18.0007H13C13.5523 18.0007 14 17.553 14 17.0007C14 16.4484 13.5523 16.0007 13 16.0007H11C10.4477 16.0007 10 16.4484 10 17.0007ZM18 12.0007C18 12.553 17.5523 13.0007 17 13.0007H7C6.44772 13.0007 6 12.553 6 12.0007C6 11.4484 6.44772 11.0007 7 11.0007H17C17.5523 11.0007 18 11.4484 18 12.0007Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}