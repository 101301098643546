import { ItemWithValidation, MultiSelect, useChangeEffect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract } from "../../../controllers";
import { scopeSystemEntityModelStore } from "../../../stores";
import { ScopeHelper } from "../../../utilities";
import { DeliveryProps } from "../Delivery";

export function Datadog({ delivery, disabled, onChange, scopeId }: DeliveryProps) {
    const dataDogModels = scopeSystemEntityModelStore.useGetDatadog();
    const [selectedOrganizationIds, setSelectedOrganizationIds] =
        useState(
            _.map(
                (delivery.destinations ?? []) as Contract.DatadogDeliveryOrganization[],
                ({ organizationId }) => organizationId));
    const datadogOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            dataDogModels);
    const datadogOrganizationModelMap =
        useMemo(
            () =>
                _.keyBy(
                    datadogOrganizationModels,
                    datadogOrganizationModel => datadogOrganizationModel.configuration.id),
            [datadogOrganizationModels]);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.DatadogDelivery),
                destinations:
                    _.map(
                        selectedOrganizationIds,
                        organizationId => ({
                            organizationId,
                            typeName: Contract.TypeNames.DatadogDeliveryOrganization
                        })) as Contract.DatadogDeliveryOrganization[],
                valid: !_.isEmpty(selectedOrganizationIds)
            } as ItemWithValidation<Contract.DatadogDelivery>),
        [selectedOrganizationIds],
        500);

    const localization =
        useLocalization(
            "common.delivery.datadog",
            () => ({
                organizations: "{{organizationsCount | NumberFormatter.humanize}} Datadog organizations",
                title: "Organizations"
            }));

    return (
        <MultiSelect
            disabled={disabled}
            fullWidth={true}
            items={_.keys(datadogOrganizationModelMap)}
            placeholder={localization.title()}
            selectedItems={selectedOrganizationIds}
            selectedItemsTranslator={
                selectedItems =>
                    selectedItems.length === 1
                        ? (datadogOrganizationModelMap[selectedItems[0]].configuration as Contract.DatadogOrganizationConfiguration).name
                        : localization.organizations({ organizationsCount: selectedItems.length })}
            onSelectedItemsChanged={setSelectedOrganizationIds}>
            {organizationId => (datadogOrganizationModelMap[organizationId].configuration as Contract.DatadogOrganizationConfiguration).name}
        </MultiSelect>);
}