import { Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function UserAccessTokenItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
        },
        []);
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oktaTenants.addOrEdit.userAccessTokenItem",
            () => ({
                steps: {
                    step1: "Login to the Admin console again as the new user and click on **Security -> API**",
                    step2: "Go to **Tokens** tab and click **Create Token**, choose a name and click **Create Token**",
                    step3: "Copy the generated **Token Value**"
                }
            }));
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
        </Steps>);
}