import { NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonComputeDiskResourceInfoItemElements, useAzureDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, entityModelStore, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../components";

export function useAzureComputeDiskDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonComputeDiskResourceInfoItemElements = useAzureCommonComputeDiskResourceInfoItemElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const disk = resourceGroupResourceModel.entity as Contract.AzureComputeDisk;
    const diskModel = resourceGroupResourceModel as Contract.AzureComputeDiskModel;
    const diskResourceModels = entityModelStore.useGet(diskModel.diskResourceIds);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureComputeDiskDefinition",
            () => ({
                info: {
                    items: {
                        diskResourceIds: "Snapshots",
                        role: {
                            title: "Disk Role",
                            [Contract.TypeNames.AzureComputeResourceDiskRole]: {
                                [Contract.AzureComputeResourceDiskRole.Data]: "Data",
                                [Contract.AzureComputeResourceDiskRole.OperatingSystem]: "OS"
                            }
                        },
                        size: "Size",
                        storageAccountSku: {
                            title: "SKU",
                            [Contract.TypeNames.AzureComputeDiskStorageAccountSku]: {
                                [Contract.AzureComputeDiskStorageAccountSku.PremiumLrs]: "Premium LRS",
                                [Contract.AzureComputeDiskStorageAccountSku.PremiumV2Lrs]: "Premium V2 LRS",
                                [Contract.AzureComputeDiskStorageAccountSku.PremiumZrs]: "Premium ZRS",
                                [Contract.AzureComputeDiskStorageAccountSku.StandardLrs]: "Standard LRS",
                                [Contract.AzureComputeDiskStorageAccountSku.StandardSsdLrs]: "Standard SSD LRS",
                                [Contract.AzureComputeDiskStorageAccountSku.StandardSsdZrs]: "Standard SSD ZRS",
                                [Contract.AzureComputeDiskStorageAccountSku.UltraSsdLrs]: "Ultra SSD LRS"
                            }
                        },
                        systemManagedIdentityId: "Managed Identity",
                        virtualMachineId: "Virtual Machine"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="size"
                        title={localization.info.items.size()}
                        value={
                            _.isNil(disk.size)
                                ? undefined
                                : NumberFormatter.storage(disk.size)}/>,
                    <InfoItem
                        key="role"
                        title={localization.info.items.role.title()}
                        value={localization.info.items.role[Contract.TypeNames.AzureComputeResourceDiskRole][diskModel.role]()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={diskModel.systemManagedIdentityId}
                        entityTypeName={Contract.TypeNames.AzureManagedIdentitySystemManagedIdentity}
                        key="systemManagedIdentityId"
                        title={localization.info.items.systemManagedIdentityId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={diskModel.virtualMachineId}
                        entityTypeName={Contract.TypeNames.AzureComputeVirtualMachine}
                        key="virtualMachineId"
                        title={localization.info.items.virtualMachineId()}/>,
                    <InfoItem
                        key="storageAccountSku"
                        title={localization.info.items.storageAccountSku.title()}
                        value={localization.info.items.storageAccountSku[Contract.TypeNames.AzureComputeDiskStorageAccountSku][disk.storageAccountSku]()}/>,
                    commonComputeDiskResourceInfoItemElements.diskEncryptionSetVaultIdReference,
                    commonComputeDiskResourceInfoItemElements.diskEncryptionSetVaultKeyIdReference,
                    commonComputeDiskResourceInfoItemElements.encryptionType,
                    commonComputeDiskResourceInfoItemElements.networkAccessPolicyType,
                    <EntitiesInfoItem
                        entityIdsOrModels={diskModel?.diskResourceIds}
                        entityTypeName={
                            _.isEmpty(diskModel.diskResourceIds)
                                ? Contract.TypeNames.AzureComputeDiskResource
                                : _(diskResourceModels).
                                    groupBy(diskResourceModel => diskResourceModel.typeName).
                                    size() > 1
                                    ? Contract.TypeNames.AzureComputeDiskResource
                                    : diskResourceModels[0].entity.typeName}
                        key="diskResourceIds"
                        title={localization.info.items.diskResourceIds()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}