import { Tooltip } from "@infrastructure";
import { Box, Stack, SxProps } from "@mui/material";
import React, { ReactNode } from "react";
import { useTheme } from "..";

type SeverityLabelProps = {
    backgroundColor?: string;
    borderColor: string;
    disabled?: boolean;
    item?: ReactNode;
    sx?: SxProps;
    tooltipTitle?: string;
};

export function SeverityLabel({ backgroundColor, borderColor, disabled, item, sx, tooltipTitle }: SeverityLabelProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={0.5}
            sx={{
                alignItems: "center",
                color:
                    disabled
                        ? theme.palette.text.disabled
                        : undefined,
                overflow: "hidden"
            }}>
            <Tooltip titleOrGetTitle={tooltipTitle}>
                <Box
                    sx={{
                        alignItems: "center",
                        backgroundColor,
                        border: "solid 1px",
                        borderColor,
                        borderRadius: theme.spacing(0.75),
                        height: theme.spacing(2.75),
                        overflow: "hidden",
                        padding: theme.spacing(0, 0.75),
                        ...sx,
                        ...(disabled && {
                            backgroundColor: theme.palette.opacity(theme.palette.severity(undefined), 0.15),
                            border: theme.border.primary,
                            borderColor: theme.palette.borders.primary,
                            opacity: 0.5
                        })
                    }}>
                    {item}
                </Box>
            </Tooltip>
        </Stack>);
}