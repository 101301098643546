import { NumberFormatter } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Cell, Label, Pie as RechartsPie, PieChart, Sector, SectorProps } from "recharts";
import { useTheme } from "../../../../../../common";
import { MultilineText } from "./components";

export type DistributionChartProps = {
    emptyTitle?: string;
    items: DistributionChartItem[];
    newReport?: boolean;
    title?: string;
    total?: number;
    totalFormatter?: (total: number) => string;
};

export class DistributionChartItem {
    constructor(
        public color: string,
        public value: number,
        public options?: DistributionChartItemOptions) {
    }
}

type DistributionChartItemOptions = {
    info?: string;
    legendElement?: ReactNode;
    url?: string;
};

export function DistributionChart({ emptyTitle, items, newReport = false, title, total, totalFormatter }: DistributionChartProps) {
    const theme = useTheme();
    const [barInnerSize, barOuterSize, chartSize] =
        newReport
            ? [93, 100, 200]
            : [75, 80, 160];
    const radius = barOuterSize - barInnerSize;
    return (
        <PieChart
            height={chartSize}
            width={chartSize}>
            <defs>
                <filter
                    height="200%"
                    id="Pie_shadow"
                    width="200%"
                    x="-50%"
                    y="-50%">
                    <feDropShadow
                        dx="2"
                        dy="2"
                        floodColor="rgba(0, 0, 0, 0.25)"
                        stdDeviation="5"/>
                </filter>
            </defs>
            <RechartsPie
                activeShape={
                    ({ innerRadius, outerRadius, ...props }: SectorProps) =>
                        <Sector
                            {...props}
                            filter="url(#Pie_shadow)"
                            innerRadius={(innerRadius ?? radius) - 2}
                            outerRadius={(outerRadius ?? radius) + 2}/>}
                cornerRadius={
                    newReport
                        ? undefined
                        : 8}
                cx="50%"
                cy="50%"
                data={items}
                dataKey={({ value }: DistributionChartItem) => value}
                endAngle={90}
                innerRadius={barInnerSize}
                isAnimationActive={false}
                minAngle={5}
                outerRadius={barOuterSize}
                paddingAngle={
                    items.length > 1
                        ? 3
                        : undefined}
                startAngle={450}
                strokeWidth={0}>
                {total === 0 && !_.isNil(emptyTitle) &&
                    <Label
                        content={MultilineText}
                        dy={-30}
                        position="center"
                        style={{
                            fontSize: "14px",
                            fontWeight: 500
                        }}
                        value={emptyTitle}/>}
                {!_.isNil(total) && (total !== 0 || _.isNil(emptyTitle)) &&
                    <Label
                        dy={
                            _.isNil(title)
                                ? undefined
                                : -10}
                        fill={theme.palette.text.primary}
                        offset={0}
                        position="center"
                        style={{
                            fontSize: "30px",
                            textAnchor: "middle"
                        }}
                        value={(totalFormatter ?? NumberFormatter.humanize)(total)}/>}
                {!_.isNil(total) && !_.isNil(title) && (total !== 0 || _.isNil(emptyTitle)) &&
                    <Label
                        content={MultilineText}
                        dy={0}
                        fill={theme.palette.text.primary}
                        position="center"
                        value={title}/>}
                {_.map(
                    items,
                    (item, itemIndex) =>
                        <Cell
                            fill={item.color}
                            key={itemIndex}
                            style={{
                                cursor:
                                    _.isNil(item.options?.url)
                                        ? undefined
                                        : "pointer"
                            }}/>)}
            </RechartsPie>
        </PieChart>);
}