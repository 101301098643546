import { useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { Contract, TimeRangeHelper, useTheme } from "../../../../../../../../../../../common";

export function Title() {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.complianceContentPages.item.title",
            () => ({
                complianceSection: "Compliance",
                summary: "Summary",
                [Contract.TypeNames.TimeFrame]: "{{ time }} days"
            }));

    const theme = useTheme();
    return (
        <Grid
            alignItems="center"
            container={true}
            justifyContent="space-between"
            style={{
                backgroundColor: theme.palette.background.paper,
                border: theme.border.primary,
                borderBottom: theme.border.primary,
                borderRadius: theme.spacing(0.75, 0.75, 0, 0),
                height: theme.spacing(5),
                paddingLeft: theme.spacing(2),
                width: "100%"
            }}>
            <Grid
                item={true}
                xs={true}>
                <Typography
                    style={{
                        fontSize: "13px",
                        fontWeight: 600
                    }}>
                    {localization.complianceSection()}
                </Typography>
            </Grid>
            <Grid
                item={true}
                xs={true}>
                <Typography
                    style={{
                        fontSize: "13px",
                        fontWeight: 600
                    }}>
                    {localization.summary()}
                </Typography>
            </Grid>
            <Grid
                item={true}
                sx={{ width: theme.spacing(12) }}>
                <Typography
                    style={{
                        fontSize: "13px",
                        fontWeight: 600
                    }}>
                    {localization[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Short) })}
                </Typography>
            </Grid>
            <Grid
                item={true}
                sx={{ width: theme.spacing(12) }}>
                <Typography
                    style={{
                        fontSize: "13px",
                        fontWeight: 600
                    }}>
                    {localization[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Medium) })}
                </Typography>
            </Grid>
            <Grid
                item={true}
                sx={{ width: theme.spacing(10) }}>
                <Typography
                    style={{
                        fontSize: "13px",
                        fontWeight: 600
                    }}>
                    {localization[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Long) })}
                </Typography>
            </Grid>
        </Grid>);
}