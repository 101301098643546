import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function Ec2ImageIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M9.5 1L16 3.72728V7.81819C16 8.56146 15.8924 9.2947 15.6919 9.9996L12.7463 9.99948C13.4063 8.72712 13.1783 7.14524 12.0567 6.08637C10.6483 4.75682 8.35889 4.75682 6.95055 6.08637C5.54222 7.41591 5.54222 9.57728 6.95055 10.9068C7.78212 11.6918 8.9182 12.0163 9.99943 11.876L10.0003 15.8673C9.83568 15.9169 9.66885 15.9612 9.5 16C5.85803 15.1604 3.12655 11.7618 3.00428 8.07579L3 7.81819V3.72728L9.5 1ZM9.5 6.45455C10.6966 6.45455 11.6667 7.37033 11.6667 8.5C11.6667 9.62968 10.6966 10.5455 9.5 10.5455C8.30338 10.5455 7.33333 9.62968 7.33333 8.5C7.33333 7.37033 8.30338 6.45455 9.5 6.45455Z"/>
            <path
                clipRule="evenodd"
                d="M12.9435 12C12.4511 12 12 12.4167 12 13.0113V20.677C12 21.2716 12.451 21.6884 12.9435 21.6885C12.9434 21.6885 12.9435 21.6885 12.9435 21.6885H20.3506C20.8429 21.6885 21.294 21.2719 21.294 20.6772V13.0113C21.294 12.4166 20.8429 12 20.3507 12H12.9435ZM12.9435 22.6885H20.3506C21.4273 22.6885 22.294 21.7915 22.294 20.6772V13.0113C22.294 11.897 21.4273 11 20.3507 11H12.9435C11.8668 11 11 11.897 11 13.0113V20.677C11 21.7914 11.8668 22.6884 12.9435 22.6885Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M20.2315 12.752H13.0566V16.2519H20.2315V12.752ZM15.3067 13.3281H13.604V15.6724H15.3067V13.3281ZM17.4931 13.3281H15.7904V15.6724H17.4931V13.3281ZM19.6838 13.3281H17.9811V15.6724H19.6838V13.3281Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M20.2315 17.4375H13.0566V20.9374H20.2315V17.4375ZM15.3067 18.0156H13.604V20.3599H15.3067V18.0156ZM17.4931 18.0156H15.7904V20.3599H17.4931V18.0156ZM19.6838 18.0156H17.9811V20.3599H19.6838V18.0156Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}