import React, { Fragment } from "react";
import { ApiIcon, DataAnalysisIcon, EntitiesIcon, PermissionManagementIcon, WorkloadAnalysisIcon } from "../../../../../../common";
import { PrincipalAccessIcon } from "../../../../../../common/icons/PrincipalAccessIcon";
import { NetworkIcon } from "../../../../../../common/icons/RiskPolicyCategoryIcon/icons";
import { CodeIcon } from "../../../../../../tenants";
import { ConfigurationView } from "../../utilities";
import { AuthenticationIcon, AutomationsIcon, IntegrationsIcon, LicensingIcon, PrincipalsIcon } from "./icons";

type ViewIconProps = {
    view: ConfigurationView;
};

export function ViewIcon({ view }: ViewIconProps) {
    switch (view) {
        case ConfigurationView.Access:
            return <PrincipalAccessIcon/>;
        case ConfigurationView.ApiKeys:
            return <ApiIcon/>;
        case ConfigurationView.Authentication:
            return <AuthenticationIcon/>;
        case ConfigurationView.Automations:
            return <AutomationsIcon/>;
        case ConfigurationView.Code:
            return <CodeIcon/>;
        case ConfigurationView.DataAnalysis:
            return <DataAnalysisIcon/>;
        case ConfigurationView.Entities:
            return <EntitiesIcon/>;
        case ConfigurationView.Integrations:
            return <IntegrationsIcon/>;
        case ConfigurationView.Licensing:
            return <LicensingIcon/>;
        case ConfigurationView.NetworkInternalSubnets:
            return <NetworkIcon/>;
        case ConfigurationView.PermissionManagement:
            return <PermissionManagementIcon/>;
        case ConfigurationView.Principals:
            return <PrincipalsIcon/>;
        case ConfigurationView.WorkloadAnalysis:
            return <WorkloadAnalysisIcon/>;
        default:
            return <Fragment/>;
    }
}