import { useLocalization } from "@infrastructure";
import React from "react";
import { EntitySnapshotDefinition } from "../..";
import { Contract } from "../../../../../../../../../../../../../common";
import { PolicyTable } from "../../../components";

export function useAwsIamPrincipalPolicyDefinition(entitySnapshot: Contract.EntitySnapshot) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.snapshots.snapshot.hooks.useDefinition.hooks.useAwsIamPrincipalPolicyDefinition",
            () => ({
                description: "The following contains the policy at the specified time, after the specified changes were made."
            }));
    return new EntitySnapshotDefinition(
        localization.description(),
        [
            <PolicyTable
                entitySnapshot={entitySnapshot}
                key="policyTable"
                policySnapshotIds={[entitySnapshot.id]}/>
        ]);
}