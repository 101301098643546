﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpContainerClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpContainerClusterLegacyComputeMetadataServerEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpContainerClusterLegacyComputeMetadataServerEnabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.GcpContainerClusterModel;

    const getGcpContainerClusterRiskContext = useGetGcpContainerClusterRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpContainerClusterLegacyComputeMetadataServerEnabledRiskDefinition",
            () => ({
                contextItems: {
                    nodePools: "The cluster has {{nodePools}} with the legacy Compute Engine instance metadata API enabled"
                },
                description: "{{cluster}} has node pools in which the legacy Compute Engine instance metadata APIs are Enabled",
                resolutionSection: {
                    step1: "Navigate to the **NODES** tab",
                    step2: "Click **ADD NODE POOL**",
                    step3: "Enter relevant node pool details",
                    step4: "In the **Metadata** tab, under **GCE instance metadata**, ensure that the key is set to **disable-legacy-endpoints** and the value is set to **true**",
                    step5: "Click **CREATE**",
                    step6: "Migrate workloads from any existing, non-conforming Node pools to the new Node pool, and then delete the non-conforming Node pools",
                    step7: "Note: Any workloads using the legacy GCE metadata endpoint will no longer be able to retrieve metadata from the endpoint. Use Workload Identity instead"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={`${risk.entityId}-${Contract.GcpConsolePage.Resource}`}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3(),
            localization.resolutionSection.step4(),
            localization.resolutionSection.step5(),
            localization.resolutionSection.step6(),
            localization.resolutionSection.step7()
        ],
        riskModel,
        () => {
            const containerClusterRiskContext = getGcpContainerClusterRiskContext(clusterModel);
            return [
                containerClusterRiskContext.generalInformation,
                containerClusterRiskContext.sensitive,
                containerClusterRiskContext.vpc,
                new RiskDefinitionContextItem(
                    localization.contextItems.nodePools({
                        nodePools:
                            <InlineEntities
                                entityIdsOrModels={risk.nodePoolIds}
                                entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                                variant="itemCountAndType"/>
                    })),
                containerClusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}