import { ItemWithValidation, MultiSelect, useChangeEffect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract } from "../../../controllers";
import { scopeSystemEntityModelStore } from "../../../stores";
import { ScopeHelper } from "../../../utilities";
import { DeliveryProps } from "../Delivery";

export function Sqs({ delivery, disabled, onChange, scopeId }: DeliveryProps) {
    const sqsModels = scopeSystemEntityModelStore.useGetSqs();
    const [selectedItems, setSelectedItems] =
        useState(
            _.map(
                (delivery.destinations ?? []) as Contract.SqsDeliveryQueue[],
                ({ queueId }) => queueId));
    const sqsQueueModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            sqsModels);
    const sqsQueueModelMap =
        useMemo(
            () => _.keyBy(
                sqsQueueModels,
                sqsQueueModel => sqsQueueModel.configuration.id),
            [sqsQueueModels]);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.SqsDelivery),
                destinations:
                    _.map(
                        selectedItems,
                        queueId => ({
                            queueId,
                            typeName: Contract.TypeNames.SqsDeliveryQueue
                        })) as Contract.SqsDeliveryQueue[],
                valid: !_.isEmpty(selectedItems)
            } as ItemWithValidation<Contract.SqsDelivery>),
        [selectedItems],
        500);

    const localization =
        useLocalization(
            "common.delivery.sqs",
            () => ({
                queues: "{{queueCount | NumberFormatter.humanize}} SQS queues",
                title: "Queues"
            }));

    return (
        <MultiSelect
            disabled={disabled}
            fullWidth={true}
            items={_.keys(sqsQueueModelMap)}
            placeholder={localization.title()}
            selectedItems={selectedItems}
            selectedItemsTranslator={
                selectedItems =>
                    selectedItems.length === 1
                        ? (sqsQueueModelMap[selectedItems[0]].configuration as Contract.SqsQueueConfiguration).name
                        : localization.queues({ queueCount: selectedItems.length })}
            onSelectedItemsChanged={setSelectedItems}>
            {item => (sqsQueueModelMap[item].configuration as Contract.SqsQueueConfiguration).name}
        </MultiSelect>);
}