import { DialogMenuItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, usePrincipalModelAccess } from "../../../../../../../../../../common";
import { GenerateGcpIdentityNonexcessiveRoleDialog } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, GciIdentityActivityInfoItem, Info, PrincipalSecurityStatsStat } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { GciDirectoryPrincipalGcpRoleBindings, GcpIamServiceAccountOriginatorResourcesInfoItem } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpIamPartialServiceAccountDefinition(entityModel: Contract.GcpEntityModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(entityModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(entityModel as Contract.GcpResourceModel);
    const partialServiceAccount = entityModel.entity as Contract.GcpIamPartialServiceAccount;
    const partialServiceAccountModel = entityModel as Contract.GcpIamPartialServiceAccountModel;
    const partialServiceAccountModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(partialServiceAccountModel.id);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamPartialServiceAccountDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        mail: "Mail",
                        typeName: "Type",
                        uniqueId: "Unique ID"
                    }
                },
                tabs: {
                    principalRoleBindings: "Bindings"
                },
                topbar: {
                    menu: {
                        generate: "Generate Custom Role"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GciDirectoryPrincipalGcpRoleBindings
                    csvExportFilePrefix={localization.tabs.principalRoleBindings()}
                    gcpRoleBindingIds={partialServiceAccountModel.roleBindingIds}
                    principalId={partialServiceAccount.id}/>,
                localization.tabs.principalRoleBindings(),
                "principalRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="mail"
                        location="all"
                        title={localization.info.items.mail()}
                        value={partialServiceAccount.mail}/>,
                    <InfoItem
                        key="uniqueId"
                        location="all"
                        title={localization.info.items.uniqueId()}
                        value={partialServiceAccount.uniqueId}/>,
                    <GcpIamServiceAccountOriginatorResourcesInfoItem
                        key="originatorResourceIds"
                        location="all"
                        originatorResourceIds={partialServiceAccountModelAccess.originatorEntityIds}/>,
                    <GciIdentityActivityInfoItem
                        identityModel={partialServiceAccountModel}
                        key="activityTime"/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={partialServiceAccountModel.groupIds}
                    entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                _.isNil(partialServiceAccountModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={partialServiceAccountModel.analyzing?.reason}
                        analyzingTenants={
                            _.isNil(partialServiceAccountModel.analyzing?.tenantIds)
                                ? undefined
                                : {
                                    ids: partialServiceAccountModel.analyzing!.tenantIds,
                                    typeName: Contract.TypeNames.GcpTenantEntity
                                }}
                        securityStats={partialServiceAccountModelAccess!.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.GcpIamPartialServiceAccount}/>
            ]),
        topbarMenuItems:
            _.isNil(partialServiceAccountModel.systemDeletionTime)
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateGcpIdentityNonexcessiveRoleDialog
                                identityModel={partialServiceAccountModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}