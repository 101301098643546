import { Button } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { AddIcon, DataTableAction, DataTableColumn, DataTableColumnRenderProps, DataTableSortType, Loading, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, CustomRiskPolicyItem, LicensingHelper, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem, ScopeHelper, scopeNodeModelStore, StorageHelper, TenantHelper, tenantModelStore, useComplianceTranslator, useDeliveryTranslator, useEntityTypeNameTranslator, useFileNameScopeTranslator, UserHelper, useScopeNameTranslator, useSelectedScopeId, useTenantTypeTranslator, useTheme } from "../../../../../../../../../../common";
import { ExclusionHelper } from "../../../../../../../Risks/utilities";
import { CustomerSideViewItemType } from "../../../../../../../SideView";
import { ActionsCell, AnalyzedEntityCount, FailedEntitiesCell, useSetRiskPoliciesContext } from "../../../../../../components";
import { RiskPolicyHelper } from "../../../../../../utilities";
import { PolicyTableDefinition } from "../../../../useDefinition";
import { CommonTableColumnId, useCommonRiskPolicyItemTableItems } from "../../../useCommonRiskPolicyItemTableItems";
import { Add } from "./components";

export function useCloudRiskPoliciesCustomDefinition(scopeId: string): PolicyTableDefinition {
    const setRiskPoliciesContext = useSetRiskPoliciesContext();
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const scopeTenantType = ScopeHelper.getTenantType(scopeNodeModel);
    const filteredActiveTenants = tenantModelStore.useGetFilteredActiveTenants(TenantHelper.CustomRisksTenantTypes);
    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const commonRiskPolicyItemTableItems =
        useCommonRiskPolicyItemTableItems(
            scopeId,
            RiskPoliciesType.Cloud,
            RiskPoliciesView.Custom);
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(undefined, true);
    const { selectedScopeId } = useSelectedScopeId();

    const customRisksEnabled =
        useMemo(
            () => {
                if (_.isEmpty(filteredActiveTenants) || ScopeHelper.isProjectScope(scopeNodeModel)) {
                    return false;
                }

                if (!_.isNil(scopeTenantType)) {
                    return _.includes(TenantHelper.CustomRisksTenantTypes, scopeTenantType);
                }

                const activeTenantTypes =
                    _.map(
                        activeTenantModels,
                        activeTenantModel => activeTenantModel.tenantType);
                return !_(activeTenantTypes).
                    concat().
                    intersection(TenantHelper.CustomRisksTenantTypes).
                    isEmpty();
            },
            [scopeTenantType]);

    const complianceTranslator = useComplianceTranslator();
    const deliveryTranslator = useDeliveryTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const fileNameScopeTranslator = useFileNameScopeTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useDefinition.hooks.cloud.hooks.useCloudRiskPoliciesCustomDefinition",
            () => ({
                actions: {
                    add: "Add Custom Policy",
                    csvExport: {
                        fileName: "Cloud_Custom_Policies_{{translatedScopeName}}",
                        result: {
                            failure: "Failed ({{riskCount | NumberFormatter.humanize}} findings on {{failedTenantCount | NumberFormatter.humanize}} accounts)",
                            success: "Success"
                        }
                    },
                    customPoliciesFlatView: "Flat View"
                },
                columns: {
                    analyzedEntityCount: {
                        count: "{{entityCount | NumberFormatter.humanize}}",
                        title: "Scanned Resources"
                    },
                    exclusions: {
                        entities: "{{count | NumberFormatter.humanize}} Excluded {{translatedEntityTypeName}}",
                        patterns: "{{count | NumberFormatter.humanize}} Excluded {{translatedEntityTypeName}} by pattern",
                        title: "Excluded Resources"
                    },
                    failedEntityCount: "Failed Resources",
                    scope: "Scope",
                    severity: {
                        empty: "Dynamic",
                        title: "Severity"
                    },
                    status: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    }
                }
            }));

    const getCsvItem =
        (item: RiskPolicyItem) => {
            const [exclusionEntityTypeNameToCountMap, exclusionEntityTypeNameToPropertyPatternCountMap] =
                ExclusionHelper.getExclusionEntityTypeNameToCountMap(
                    [],
                    (item as CustomRiskPolicyItem).riskPolicyModel,
                    item.riskPolicyConfigurationTypeName,
                    []);

            return {
                /* eslint-disable sort-keys-fix/sort-keys-fix */
                Cloud:
                    _(item.tenantTypes).
                        map(tenantType => tenantTypeTranslator(tenantType)).
                        join("\n"),
                Name: (item as CustomRiskPolicyItem).riskPolicyConfiguration.name,
                Description: (item as CustomRiskPolicyItem).riskPolicyConfiguration.description,
                Severity: (item as CustomRiskPolicyItem).riskPolicyConfiguration.severity,
                Compliance:
                    !LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) ||
                        _.isNil(item.customComplianceIds)
                        ? undefined
                        : _.isEmpty(item.customComplianceIds)
                            ? "-"
                            : _(item.customComplianceIds).
                                map(complianceIdOrSectionType => complianceTranslator(complianceIdOrSectionType).title).
                                join("\n"),
                Status:
                    item.enabled
                        ? localization.columns.status.true()
                        : localization.columns.status.false(),
                Result:
                    _.isNil(item.result)
                        ? "-"
                        : item.success
                            ? localization.actions.csvExport.result.success()
                            : localization.actions.csvExport.result.failure({
                                failedTenantCount: item.result.failedTenantCount,
                                riskCount: item.result.riskCount
                            }),
                Exclusions:
                    _<string>([]).
                        concat(
                            _(exclusionEntityTypeNameToCountMap).
                                pickBy(exclusionEntityCount => exclusionEntityCount > 0).
                                map(
                                    (exclusionEntityCount, entityTypeName) =>
                                        localization.columns.exclusions.entities({
                                            count: exclusionEntityCount,
                                            translatedEntityTypeName:
                                                entityTypeNameTranslator(
                                                    entityTypeName,
                                                    {
                                                        count: exclusionEntityCount,
                                                        includeServiceName: true,
                                                        variant: "text"
                                                    })
                                        })).
                                value(),
                            _(exclusionEntityTypeNameToPropertyPatternCountMap).
                                pickBy(exclusionEntityPropertyPatternCount => exclusionEntityPropertyPatternCount > 0).
                                map(
                                    (exclusionEntityPropertyPatternCount, entityTypeName) =>
                                        localization.columns.exclusions.patterns({
                                            count: exclusionEntityPropertyPatternCount,
                                            translatedEntityTypeName:
                                                entityTypeNameTranslator(
                                                    entityTypeName,
                                                    {
                                                        count: exclusionEntityPropertyPatternCount,
                                                        includeServiceName: true,
                                                        variant: "text"
                                                    })
                                        })).
                                value()).
                        join("\n"),
                Findings:
                    !item.success
                        ? CustomerConsoleAppUrlHelper.getRisksUrl(
                            Contract.RisksView.Open,
                            {
                                policyConfigurationTypeNameOrIds: [(item as CustomRiskPolicyItem).riskPolicyConfiguration.id]
                            })
                        : "-",
                Actions:
                    _.isEmpty(item.deliveries)
                        ? "-"
                        : _(item.deliveries).
                            map(({ delivery }) => deliveryTranslator(delivery.typeName as Contract.DeliveryDerivedTypeNames, "title").text).
                            join("\n")
                /* eslint-enable sort-keys-fix/sort-keys-fix */
            };
        };

    function getScopeId(itemScopeId: string) {
        return _.includes(
            scopeNodeMap[selectedScopeId].childScopeIds,
            itemScopeId)
            ? itemScopeId
            : selectedScopeId;
    }

    const theme = useTheme();
    return useMemo(
        () =>
            new PolicyTableDefinition(
                columnIdToItemValuesMap =>
                    _<ReactNode>([]).
                        concat(
                            _.map(
                                [
                                    CommonTableColumnId.TenantsType,
                                    CommonTableColumnId.Name
                                ],
                                tableColumnId => commonRiskPolicyItemTableItems[tableColumnId].getColumn(columnIdToItemValuesMap[tableColumnId])),
                            <DataTableColumn
                                id={TableColumnId.Scope}
                                itemProperty={item => scopeNameTranslator(item.scopeId, { path: true })}
                                key={TableColumnId.Scope}
                                title={localization.columns.scope()}/>,
                            commonRiskPolicyItemTableItems.severity.getColumn()).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                            commonRiskPolicyItemTableItems.compliances.getColumn(columnIdToItemValuesMap[CommonTableColumnId.Compliances])).
                        concat([
                            commonRiskPolicyItemTableItems.status.getColumn(),
                            <DataTableColumn
                                id={TableColumnId.AnalyzedEntityCount}
                                key={TableColumnId.AnalyzedEntityCount}
                                render={
                                    ({ item }: DataTableColumnRenderProps<CustomRiskPolicyItem>) =>
                                        <Loading container="cell">
                                            <AnalyzedEntityCount
                                                item={item}
                                                scopeId={scopeId}/>
                                        </Loading>}
                                sortOptions={{ enabled: false }}
                                title={localization.columns.analyzedEntityCount.title()}/>,
                            <DataTableColumn
                                id={TableColumnId.FailedEntityCount}
                                key={TableColumnId.FailedEntityCount}
                                render={
                                    ({ item }: DataTableColumnRenderProps<CustomRiskPolicyItem>) =>
                                        <Loading container="cell">
                                            <FailedEntitiesCell item={item}/>
                                        </Loading>}
                                sortOptions={{ type: DataTableSortType.Numeric }}
                                title={localization.columns.failedEntityCount()}/>,
                            commonRiskPolicyItemTableItems.excludedEntityIds.getColumn(
                                undefined,
                                { selectorOptions: { default: false } }),
                            <DataTableColumn
                                disableAction={true}
                                headerSx={{ minWidth: theme.spacing(5) }}
                                id={TableColumnId.Actions}
                                key={TableColumnId.Actions}
                                orderable={false}
                                render={
                                    ({ item }: DataTableColumnRenderProps<CustomRiskPolicyItem>) =>
                                        <ActionsCell
                                            item={item}
                                            riskPoliciesType={RiskPoliciesType.Cloud}
                                            scopeId={item.scopeId}/>}
                                resizable={false}
                                selectorOptions={{ disabled: true }}
                                sortOptions={{ enabled: false }}/>
                        ]).
                        value(),
                {
                    ...commonRiskPolicyItemTableItems.compliances.getValueMap(),
                    [TableColumnId.FailedEntityCount]:
                        item =>
                            item.riskPolicyTypeMetadata.temporal || !item.enabled || _.isNil(item.result.failedEntityCount)
                                ? -1
                                : item.result.failedEntityCount,
                    ...commonRiskPolicyItemTableItems.name.getValueMap(),
                    ...commonRiskPolicyItemTableItems.severity.getValueMap(),
                    ...commonRiskPolicyItemTableItems.status.getValueMap(),
                    ...commonRiskPolicyItemTableItems.tenantsType.getValueMap(),
                    [TableColumnId.Scope]: {
                        getFilterValue: item => (item as CustomRiskPolicyItem).scopeId,
                        getSortValue: item => scopeNameTranslator((item as CustomRiskPolicyItem).scopeId, { path: true })
                    }
                },
                item =>
                    RiskPolicyHelper.getSideViewItemId(
                        item.riskPolicyConfigurationTypeNameOrId,
                        getScopeId((item as CustomRiskPolicyItem).scopeId),
                        RiskPoliciesType.Cloud),
                {
                    hashRouteTemplate: `${CustomerSideViewItemType.RiskPolicies}/{itemId}`
                },
                {
                    columnOrder: StorageHelper.customerRiskPoliciesTableColumnOrder(RiskPoliciesView.Custom),
                    columnSelector: StorageHelper.customerRiskPoliciesTableColumnSelector(RiskPoliciesView.Custom),
                    flatView: StorageHelper.customerRiskPoliciesTableFlatView(RiskPoliciesView.Custom),
                    tableFilters: StorageHelper.customerRiskPoliciesTableFilters(RiskPoliciesView.Custom)
                },
                <Add
                    scopeId={scopeId}
                    onFinish={
                        () => {
                            setRiskPoliciesContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }));
                        }}/>,
                {
                    csvExportFilePrefixes: [localization.actions.csvExport.fileName({ translatedScopeName: fileNameScopeTranslator(scopeId) })],
                    getCsvItem
                },
                _<ReactNode>([]).
                    concatIf(
                        UserHelper.hasScopePermissions(scopeId, Contract.IdentityPermission.SecurityWrite),
                        <DataTableAction>
                            <Button
                                disabled={!customRisksEnabled}
                                size="small"
                                startIcon={<AddIcon/>}
                                onClick={
                                    () =>
                                        setRiskPoliciesContext(
                                            context => ({
                                                ...context,
                                                addOrEditOpen: true
                                            }))}>
                                {localization.actions.add()}
                            </Button>
                        </DataTableAction>).
                    value(),
                {
                    defaultSortColumnIds: [CommonTableColumnId.TenantsType, CommonTableColumnId.Name],
                    rowOptions: {
                        getUrl:
                            item =>
                                CustomerConsoleAppUrlHelper.getRiskPoliciesProfileHashUrl(
                                    RiskPoliciesType.Cloud,
                                    item.riskPolicyConfigurationTypeNameOrId,
                                    getScopeId((item as CustomRiskPolicyItem).scopeId))
                    }
                }
            ),
        [commonRiskPolicyItemTableItems, scopeId]);
}

enum TableColumnId {
    Actions = "actions",
    AnalyzedEntityCount = "analyzedEntityCount",
    FailedEntityCount = "failedEntityCount",
    Scope = "scope"
}