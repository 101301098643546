﻿import { ActionMenuItem, Dialog, makeContextProvider, Optional, useLocalization, useRemoveQueryParameters } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, memo, useCallback, useMemo, useState } from "react";
import { useScopesContext, useSetScopesContext } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, ScopeNavigationView, scopeNodeModelStore, TenantHelper, TenantsTenantType } from "../../../../../../common";
import { AddOrEditFolder, ScopeTooltip, Table } from "../../components";
import { TenantsDefinition, useAddOrEditDefinition } from "../../hooks";

export class CloudProviderTenantsContext {
    public organizationTenant?: boolean;

    constructor(public definition: TenantsDefinition) {
    }
}

export const [, useSetCloudProviderTenantsContext, useCloudProviderTenantsContextProvider] = makeContextProvider<CloudProviderTenantsContext>();


const CloudProviderTenantsMemo = memo(CloudProviderTenants);
export { CloudProviderTenantsMemo as CloudProviderTenants };

function CloudProviderTenants() {
    const { addOrEditOpen, addOrEditStepIndex, definition, view } = useScopesContext();
    const addOrEditDefinition = useAddOrEditDefinition(view);
    const tenantType = view as Contract.TenantType;
    const setScopesContext = useSetScopesContext();
    const scopeNodeModels = scopeNodeModelStore.useGetAll();

    const tenantTypes =
        useMemo(
            () => [tenantType],
            [tenantType]);
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(tenantTypes);

    const localization =
        useLocalization(
            "views.customer.scopes.cloudProviderTenants.core",
            () => ({
                actions: {
                    addFolder: {
                        disabledTooltip: "Can't add folder in organization which is configured to automatically onboard new {{scopeType}}",
                        title: "Add folder"
                    },
                    addTenant: {
                        disabledTooltip: "Can't add {{scopeType}} in organization which is configured to automatically onboard new {{scopeType}}"
                    }
                },
                [Contract.TypeNames.TenantType]: {
                    [Contract.TenantType.Aws]: "account",
                    [Contract.TenantType.Azure]: "subscription",
                    [Contract.TenantType.Ci]: "registry",
                    [Contract.TenantType.Code]: "repository",
                    [Contract.TenantType.Gcp]: "project",
                    [Contract.TenantType.Oci]: "compartment",
                    [Contract.TenantType.Op]: "account"
                }
            }));

    const tenantTypeRootFolderScopeNodeModel =
        useMemo(
            () =>
                _.find(
                    scopeNodeModels,
                    scopeNodeModel =>
                        ScopeHelper.isRootFolder(scopeNodeModel) &&
                        (scopeNodeModel.configuration as Contract.FolderConfiguration).tenantType === tenantType)!,
            [scopeNodeModels, tenantType]);

    const removeQueryParameters = useRemoveQueryParameters();
    const [context, , ContextProvider] =
        useCloudProviderTenantsContextProvider(
            () => {
                removeQueryParameters("addOrEdit");
                removeQueryParameters("addOrEditStepIndex");
                return new CloudProviderTenantsContext(definition);
            });

    const [parentFolderId, setParentFolderId] = useState<string>(tenantTypeRootFolderScopeNodeModel.configuration.id);
    const addDisabled =
        useMemo(
            () => {
                const cloudProviderTenantFolderConfiguration = scopeNodeMap[parentFolderId]?.scopeNodeModel.configuration as Optional<Contract.CloudProviderTenantFolderConfiguration>;
                return cloudProviderTenantFolderConfiguration?.managed === true;
            },
            [parentFolderId, scopeNodeMap]);

    const addOrEditTenantOpen =
        addOrEditOpen === "scope" ||
        context.organizationTenant ||
        (addOrEditOpen as Contract.ScopeNodeModel).type === Contract.ScopeType.CloudProviderTenant;
    const onEditClick =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: scopeNodeModel
                    }));
            },
            [setScopesContext]);

    const onScopeChanged =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => {
                setParentFolderId(scopeNodeModel.configuration.id);
            },
            []);

    const onAddOrEditClick =
        useCallback(
            () => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: "scope"
                    }));
            },
            []);

    const onCloseAddOrEdit =
        useCallback(
            () => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: false
                    }));
            },
            []);

    const menuItems =
        useMemo(
            () =>
                _<ActionMenuItem>([]).
                    concatIf(
                        !_.isNil(addOrEditDefinition.additionalActionItems),
                        () => addOrEditDefinition.additionalActionItems!(onAddOrEditClick)).
                    concat(
                        new ActionMenuItem(
                            () =>
                                setScopesContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: "folder"
                                    })),
                            localization.actions.addFolder.title(),
                            {
                                disabled: addDisabled,
                                tooltip:
                                    addDisabled
                                        ? localization.actions.addFolder.disabledTooltip({ scopeType: localization[Contract.TypeNames.TenantType][tenantType as TenantsTenantType]() })
                                        : undefined
                            })).
                    concatIf(
                        !addOrEditDefinition.addDisabled,
                        () =>
                            new ActionMenuItem(
                                () =>
                                    setScopesContext(
                                        context => ({
                                            ...context,
                                            addOrEditOpen: "scope"
                                        })),
                                addOrEditDefinition.addTitle!,
                                {
                                    disabled: addDisabled,
                                    tooltip:
                                        addDisabled
                                            ? localization.actions.addTenant.disabledTooltip({ scopeType: localization[Contract.TypeNames.TenantType][tenantType as TenantsTenantType]() })
                                            : undefined
                                })).
                    value(),
            [addOrEditDefinition, localization, setScopesContext]);

    const cloudProviderTenantTypes =
        useMemo(
            () => TenantHelper.CloudProviderTenantTypes,
            []);

    return (
        <ContextProvider>
            {addOrEditOpen !== false && (
                <Fragment>
                    {addOrEditTenantOpen && !_.isNil(addOrEditDefinition.element)
                        ? <Dialog
                            size={addOrEditDefinition.dialogSize ?? "large"}
                            variant="editor"
                            onClose={onCloseAddOrEdit}>
                            <addOrEditDefinition.element
                                organizationTenant={context.organizationTenant === true}
                                parentFolderId={parentFolderId}
                                scopeNodeModel={
                                    addOrEditOpen === "scope"
                                        ? undefined
                                        : addOrEditOpen as Contract.ScopeNodeModel}
                                startItemIndex={
                                    _.isNil(addOrEditStepIndex)
                                        ? undefined
                                        : Number(addOrEditStepIndex)}
                                onClose={onCloseAddOrEdit}/>
                        </Dialog>
                        : <Dialog
                            variant="editor"
                            onClose={onCloseAddOrEdit}>
                            <AddOrEditFolder
                                folderScopeNodeModel={
                                    addOrEditOpen === "folder"
                                        ? undefined
                                        : addOrEditOpen as Contract.ScopeNodeModel}
                                parentFolderId={parentFolderId}
                                onSave={onCloseAddOrEdit}/>
                        </Dialog>}
                </Fragment>)}
            {_.isNil(addOrEditDefinition.welcome)
                ? <ScopeNavigationView
                    getItemTooltip={scopeNode => <ScopeTooltip scopeNode={scopeNode}/>}
                    hideNonPermittedScopes={true}
                    identityPermissions={[Contract.IdentityPermission.SecurityAdministrationRead]}
                    layout="view"
                    rootFolderId={tenantTypeRootFolderScopeNodeModel.configuration.id}
                    search={false}
                    templatePath={`${CustomerConsoleAppUrlHelper.getScopesRelativeUrl(view)}/{scopeId}`}
                    tenantTypes={cloudProviderTenantTypes}
                    variant="folders"
                    onScopeChanged={onScopeChanged}>
                    {scopeNodeModel =>
                        <Table
                            key={scopeNodeModel.configuration.id}
                            menuItems={menuItems}
                            onEditClick={onEditClick}/>}
                </ScopeNavigationView>
                : addOrEditDefinition.welcome}
        </ContextProvider>);
}