import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useGcpTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpTenantModelStatus]: {
                    [Contract.GcpTenantModelStatus.AccessDenied]: "Access Denied",
                    [Contract.GcpTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.GcpTenantModelStatus.GciTenantIssue]: "Google workspace is not connected",
                    [Contract.GcpTenantModelStatus.ServiceAccountDataAnalysisPermissionsMismatch]: "Missing data protection permissions",
                    [Contract.GcpTenantModelStatus.ServiceAccountMandatoryOrganizationPermissionsMismatch]: "Missing permissions at the organization level",
                    [Contract.GcpTenantModelStatus.ServiceAccountMandatoryPermissionsMismatch]: "Missing permissions",
                    [Contract.GcpTenantModelStatus.ServiceAccountOptionalRoleMismatch]: "Missing permissions",
                    [Contract.GcpTenantModelStatus.ServiceAccountTenantBillingNotEnabled]: "Some features are disabled",
                    [Contract.GcpTenantModelStatus.ServiceAccountTenantServiceApiHostNamesMismatch]: "Some features are disabled",
                    [Contract.GcpTenantModelStatus.ServiceAccountUnauthorized]: "Failed to connect",
                    [Contract.GcpTenantModelStatus.ServiceAccountWorkloadAnalysisPermissionsMismatch]: "Missing workload protection permissions",
                    [Contract.GcpTenantModelStatus.TenantDeletePending]: "Project is pending deletion",
                    [Contract.GcpTenantModelStatus.TenantNotExist]: "Project is deleted",
                    [Contract.GcpTenantModelStatus.TenantSuspended]: "Project is suspended",
                    [Contract.GcpTenantModelStatus.Valid]: "Connected",
                    [Contract.GcpTenantModelStatus.ValidSinkExclusionFilterMismatch]: "The logging sink exclude filter does not match the provided filter",
                    [Contract.GcpTenantModelStatus.ValidSinkInclusionFilterMismatch]: "The logging sink filter does not match the provided filter",
                    [Contract.GcpTenantModelStatus.ValidSinkNotConfigured]: "Subscription is not configured",
                    [Contract.GcpTenantModelStatus.ValidSinkMultipleSinks]: "There are multiple sinks writing to the same topic",
                    [Contract.GcpTenantModelStatus.ValidSinkNotExist]: "Logging sink not found",
                    [Contract.GcpTenantModelStatus.ValidSinkOrganizationIncludeChildrenNotEnabled]: "The logging sink that redirects traffic to the subscription should be created in the organization scope and have includeChildren set to true",
                    [Contract.GcpTenantModelStatus.ValidSinkSubscriptionExpirationTimeFrameExists]: "Subscription expiration period should be set to never expire",
                    [Contract.GcpTenantModelStatus.ValidSinkSubscriptionFilterExists]: "Subscription should not contain a filter",
                    [Contract.GcpTenantModelStatus.ValidSinkSubscriptionMessageDeliveryTypePush]: "Subscription delivery type should be Pull",
                    [Contract.GcpTenantModelStatus.ValidSinkSubscriptionMessageRetentionDuration]: "Subscription message retention should be at least 3 days",
                    [Contract.GcpTenantModelStatus.ValidSinkSubscriptionNotExist]: "Subscription not found",
                    [Contract.GcpTenantModelStatus.ValidSinkSubscriptionRawIdNotValid]: "Invalid subscription name",
                    [Contract.GcpTenantModelStatus.ValidSinkSubscriptionServiceAccountUnauthorized]: "Subscription does not contain permissions for service account with role Pub/Sub Subscriber",
                    [Contract.GcpTenantModelStatus.ValidSinkSubscriptionTopicNotConnected]: "Subscription is not connected to a topic",
                    [Contract.GcpTenantModelStatus.ValidWithIssues]: "Connected with issues"
                }
            }));
    return (status: Contract.GcpTenantModelStatus) => localization[Contract.TypeNames.GcpTenantModelStatus][status]();
}