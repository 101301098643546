import { DataTableColumn, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useGciCommonDirectoryPrincipalDefinition } from "./useGciCommonDirectoryPrincipalDefinition";

export function useGciTenantEntityDefinition(definitionData: DefinitionData) {
    const commonDirectoryPrincipalDefinition = useGciCommonDirectoryPrincipalDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gci.useGciTenantEntityDefinition",
            () => ({
                columns: {
                    primaryDomainName: "Primary Domain Name",
                    rawId: "Customer ID"
                }
            }));

    return new EntityTableDefinition(
        [
            commonDirectoryPrincipalDefinition.columns.tenantColumn,
            commonDirectoryPrincipalDefinition.columns.creationTime,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciTenantEntityModel) => ({
                            [localization.columns.rawId()]: (item.entity as Contract.GciTenantEntity).tenantId
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GciTenantEntityRawId)}
                                placeholder={localization.columns.rawId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciTenantEntityRawId}
                itemProperty={(item: Contract.GciTenantEntityModel) => (item.entity as Contract.GciTenantEntity).tenantId}
                key={Contract.EntityModelProperty.GciTenantEntityRawId}
                title={localization.columns.rawId()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciTenantEntityModel) => ({
                            [localization.columns.primaryDomainName()]: (item.entity as Contract.GciTenantEntity).primaryDomainName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GciTenantEntityPrimaryDomainName)}
                                placeholder={localization.columns.primaryDomainName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciTenantEntityPrimaryDomainName}
                itemProperty={(item: Contract.GciTenantEntityModel) => (item.entity as Contract.GciTenantEntity).primaryDomainName}
                key={Contract.EntityModelProperty.GciTenantEntityPrimaryDomainName}
                title={localization.columns.primaryDomainName()}/>,
            commonDirectoryPrincipalDefinition.columns.attributesColumn,
            commonDirectoryPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGciTenantEntityRequest(
                new Contract.EntityControllerGetEntityModelPageGciTenantEntityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GciTenantEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciTenantEntityPrimaryDomainName]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciTenantEntityRawId])),
                limit,
                skip,
                requestSort));
}