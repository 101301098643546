﻿import { map, Optional } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../common";
import { PermissionAssignmentEligibilityData } from "../../../utilities";

export const awsSsoPermissionSetNamePrefixMaxLength = 19;

export class AwsSsoPermissionSetAssignmentEligibilityData extends PermissionAssignmentEligibilityData {
    private _autoGeneratePermissionSetNamePrefix: boolean;
    private _permissionSetNamePrefix: string;
    public permissions: AwsSsoPermissionSetAssignmentEligibilityDataPermissions;

    public constructor(
        permissionEligibilityModel?: Optional<Contract.PermissionEligibilityModel>,
        scopeId?: string) {
        super(
            permissionEligibilityModel,
            scopeId);

        const ssoPermissionSetPermissionEligibilityModel = permissionEligibilityModel as Contract.AwsSsoPermissionSetAssignmentEligibilityModel;

        this._autoGeneratePermissionSetNamePrefix = _.isNil(permissionEligibilityModel);
        this.permissions =
            _.isNil(ssoPermissionSetPermissionEligibilityModel)
                ? new AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions([], undefined)
                : map<string, AwsSsoPermissionSetAssignmentEligibilityDataPermissions>(
                    ssoPermissionSetPermissionEligibilityModel.configuration.permissions.typeName,
                    {
                        [Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions]: () => {
                            const ssoPermissionSetPermissionEligibilityConfigurationPolicyPermissions = ssoPermissionSetPermissionEligibilityModel.configuration.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions;
                            const ssoPermissionSetPermissionEligibilityModelPolicyPermissions = ssoPermissionSetPermissionEligibilityModel.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPolicyPermissions;
                            return new AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions(
                                ssoPermissionSetPermissionEligibilityModelPolicyPermissions.existingAwsManagedPolicyIds,
                                ssoPermissionSetPermissionEligibilityConfigurationPolicyPermissions.inlinePolicyDocument?.raw);
                        },
                        [Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions]: () => {
                            const ssoPermissionSetPermissionEligibilityModelPermissionSetPermissions = ssoPermissionSetPermissionEligibilityModel.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions;

                            return new AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions(
                                ssoPermissionSetPermissionEligibilityModelPermissionSetPermissions.permissionSetExists
                                    ? ssoPermissionSetPermissionEligibilityModelPermissionSetPermissions.permissionSetIdReference
                                    : undefined);
                        }
                    });
        this._permissionSetNamePrefix = ssoPermissionSetPermissionEligibilityModel?.configuration.permissionSetNamePrefix ?? "";
    }

    public get autoGeneratePermissionSetNamePrefix() {
        return this._autoGeneratePermissionSetNamePrefix;
    }

    public get permissionSetNamePrefix() {
        return this._permissionSetNamePrefix;
    }

    public setPermissionSetNamePrefix(permissionSetNamePrefix: string) {
        if (this._permissionSetNamePrefix != permissionSetNamePrefix) {
            this._permissionSetNamePrefix = permissionSetNamePrefix;
            this._autoGeneratePermissionSetNamePrefix = false;
        }
    }

    protected UpdateNameRelatedData() {
        super.UpdateNameRelatedData();
        if (this._autoGeneratePermissionSetNamePrefix) {
            this._permissionSetNamePrefix =
                this.name.
                    trim().
                    replaceAll(/[^A-Za-z0-9+=,.@_\-]/g, "-").
                    substring(0, awsSsoPermissionSetNamePrefixMaxLength);
        }
    }

    protected UpdatePrincipalTenantRelatedData() {
        super.UpdatePrincipalTenantRelatedData();
        this.permissions.UpdatePrincipalTenantRelatedData();
    }
}

export abstract class AwsSsoPermissionSetAssignmentEligibilityDataPermissions {
    public UpdatePrincipalTenantRelatedData() {
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions extends AwsSsoPermissionSetAssignmentEligibilityDataPermissions {
    constructor(
        public awsManagedPolicyIds: string[],
        public inlinePolicyDocumentJson: Optional<string>) {
        super();
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions extends AwsSsoPermissionSetAssignmentEligibilityDataPermissions {
    constructor(public permissionSetId: Optional<string>) {
        super();
    }

    public UpdatePrincipalTenantRelatedData() {
        super.UpdatePrincipalTenantRelatedData();
        this.permissionSetId = undefined;
    }
}