import { Action0, GroupedSearchList, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../themes";
import { PendoHelper } from "../../../../../utilities";

type PendoOnboardingGuidesProps = {
    onClick: Action0;
};

export function PendoOnboardingGuides({ onClick }: PendoOnboardingGuidesProps) {
    const localization =
        useLocalization(
            "common.topbar.aboutDropdown.pendoOnboardingGuides",
            () => ({
                steps: "{{count}}/{{total}}"
            }));

    const theme = useTheme();
    return (
        <GroupedSearchList
            getGroupItemSearchText={item => item.name}
            groupItemRenderer={
                item =>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={3}
                        sx={{
                            "&:hover": {
                                backgroundColor: theme.palette.action.hover
                            },
                            borderRadius: theme.spacing(0.75),
                            color: theme.palette.text.secondary,
                            cursor: "pointer",
                            height: "100%",
                            marginLeft: theme.px(5),
                            padding: theme.spacing(1.25)
                        }}
                        onClick={
                            () => {
                                window.pendo.showGuideById(item.id);
                                onClick();
                            }}>
                        <Typography
                            noWrap={true}
                            sx={{ maxWidth: theme.spacing(30) }}>
                            {item.name}
                        </Typography>
                        <Stack
                            alignItems="center"
                            direction="row">
                            {item.steps === item.seenSteps
                                ? <Check
                                    sx={{
                                        color: theme.palette.success.main,
                                        fontSize: "18px"
                                    }}/>
                                : <Typography>
                                    {localization.steps({
                                        count: item.seenSteps,
                                        total: item.steps
                                    })}
                                </Typography>}
                        </Stack>
                    </Stack>}
            groups={PendoHelper.guides}/>);
}