﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../controllers";
import { customEntityAttributeDefinitionModelStore } from "../stores";
import { TypeHelper } from "../utilities";
import { useBuiltInEntityAttributeTypeNameTranslator } from "./useBuiltInEntityAttributeTypeNameTranslator";

export function useGetEntityAttributesCsvItem() {
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const builtInEntityAttributeTypeNameTranslator = useBuiltInEntityAttributeTypeNameTranslator();
    const localization =
        useLocalization(
            "common.hooks.useGetEntityAttributesCsvItem",
            () => ({
                columns: {
                    attributes: "Labels"
                }
            }));

    return (entityAttributes: Contract.EntityAttribute[], itemName?: string) => {
        const customEntityAttributeDefinitionModelMap =
            _.keyBy(
                customEntityAttributeDefinitionModels,
                customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id);
        return ({
            [itemName ?? localization.columns.attributes()]:
                _(entityAttributes).
                    filter(
                        entityAttribute =>
                            !(TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.CustomEntityAttribute) &&
                                _.isNil(customEntityAttributeDefinitionModelMap[(entityAttribute as Contract.CustomEntityAttribute).definitionId]))).
                    map(
                        attribute =>
                            TypeHelper.extendOrImplement(attribute.typeName, Contract.TypeNames.CustomEntityAttribute)
                                ? customEntityAttributeDefinitionModelMap[(attribute as Contract.CustomEntityAttribute).definitionId].configuration.name
                                : builtInEntityAttributeTypeNameTranslator(attribute.typeName)).
                    join("\n")
        });
    };
}