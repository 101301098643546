import _ from "lodash";
import { scopeNodeModelStore } from "../stores";
import { ScopeHelper } from "../utilities";
import { useSelectedScopeId } from "./useSelectedScopeId";

export function useProjectScopeSelected() {
    const scopeNodeMap =
        scopeNodeModelStore.useGetScopeNodeMap(
            undefined,
            true);
    const { selectedScopeId } = useSelectedScopeId();

    return !_.isNil(scopeNodeMap[selectedScopeId]) &&
        ScopeHelper.isProjectScope(scopeNodeMap[selectedScopeId].scopeNodeModel);
}