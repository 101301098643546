import { map } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpResourceManagerOrganizationDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => GcpConsoleUrlBuilder.GetResourceManagerOrganizationPermissionsUrl
            });

    return new EntityExternalConsoleLinkDefinition(getUrl((entityModel.entity as Contract.GcpResourceManagerOrganization).rawShortId));
}