import { Box, List, ListItemButton, Popper, Stack, styled, Typography } from "@mui/material";
import _ from "lodash";
import React, { ComponentType, Fragment, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { Shadows, useRoute, useSetApplicationContext } from "@infrastructure";
import { Contract, ScopeHelper, scopeNodeModelStore, tenantModelStore, useSelectedScopeId, useTheme } from "../../../..";
import { useSidebarContext } from "../..";
import { SidebarItem } from "..";

export type SidebarNavigationItemProps = {
    disabled?: boolean;
    icon: ComponentType<any>;
    subItems?: SidebarNavigationItemSubItem[];
    tenantTypes?: Contract.TenantType[];
    title: string;
    view: string;
};

export class SidebarNavigationItemSubItem {
    constructor(
        public icon: JSX.Element,
        public title: string,
        public view: string,
        public enabled: boolean = true) {
    }
}

export function SidebarNavigationItem({ disabled: itemDisabled = false, icon: Icon, subItems, tenantTypes, title, view }: SidebarNavigationItemProps) {
    const { reloadView, rootRelativeUrl, setViewRoute, viewRoute } = useSidebarContext();
    const setApplicationContext = useSetApplicationContext();
    const activeScopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const filteredActiveTenantTypes = tenantModelStore.useGetFilteredActiveTenantTypes();
    const { view: currentView } = viewRoute;
    const { subView } = useRoute(`${rootRelativeUrl}/${view}/{subView}`);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const [showSubItems, setShowSubItems] = useState<boolean>(false);
    const [iteratingSubItemIndex, setIteratingSubItemIndex] = useState<number>(0);
    const { registerCloseSubItems, triggerCloseSubItems } = useSidebarContext();
    const { selectedScopeId } = useSelectedScopeId();
    const enabledSubItems =
        useMemo(
            () =>
                _.filter(
                    subItems,
                    subItem => subItem.enabled),
            [subItems]);

    useEffect(
        () => {
            const unregister =
                registerCloseSubItems(
                    element => {
                        setShowSubItems(false);
                        setAnchorElement(
                            anchorElement =>
                                anchorElement === element
                                    ? anchorElement
                                    : null);
                    });

            return () => unregister();
        },
        []);

    const disabledPermitted =
        useMemo(
            () => {
                if (_.isNil(tenantTypes) ||
                    _.isNil(activeScopeNodeMap[selectedScopeId]) ||
                    selectedScopeId === ScopeHelper.customerId) {
                    return false;
                }

                return _(tenantTypes).
                    intersection(filteredActiveTenantTypes).
                    isEmpty();
            },
            [activeScopeNodeMap, selectedScopeId, tenantTypes]);

    const selectedSubItem =
        useMemo(
            () =>
                _.isNil(subView)
                    ? undefined
                    : _.find(subItems, subItem => subItem.view === subView),
            [subItems, subView]);

    const disabled = itemDisabled || disabledPermitted;
    useEffect(
        () => {
            if (currentView === view) {
                setApplicationContext(
                    context => ({
                        ...context,
                        permittedContent:
                            !disabled &&
                            (_.isNil(selectedSubItem) ||
                            !_.isNil(selectedSubItem) && selectedSubItem.enabled)
                    }));
            }
        },
        [currentView, disabled, view, selectedSubItem?.enabled]);

    const openSubItemsTimeoutRef = useRef<NodeJS.Timeout>();
    const closeSubItemsTimeoutRef = useRef<NodeJS.Timeout>();

    function openSubItems(event: MouseEvent) {
        const element = event.currentTarget;
        clearCloseSubItemsTimeoutRef();
        openSubItemsTimeoutRef.current =
            setTimeout(
                () => {
                    setAnchorElement(element as HTMLElement);
                    triggerCloseSubItems(element as HTMLElement);
                    setShowSubItems(true);
                    openSubItemsTimeoutRef.current = undefined;
                },
                100);
    }

    function clearOpenSubItemsTimeoutRef() {
        if (!_.isNil(openSubItemsTimeoutRef.current)) {
            clearTimeout(openSubItemsTimeoutRef.current);
        }
    }

    function closeSubItems() {
        closeSubItemsTimeoutRef.current =
            setTimeout(
                () => {
                    triggerCloseSubItems(null);
                    setShowSubItems(false);
                    closeSubItemsTimeoutRef.current = undefined;
                },
                100);
    }

    function clearCloseSubItemsTimeoutRef() {
        if (!_.isNil(closeSubItemsTimeoutRef.current)) {
            clearTimeout(closeSubItemsTimeoutRef.current);
        }
    }

    const hasSubItems = !_.isNil(subItems) && _.size(subItems) > 1;
    const href = `${rootRelativeUrl}/${view}`;

    const theme = useTheme();
    return (
        <Fragment>
            <ListItem
                className={getSidebarNavigationItemClassName(view)}
                component="a"
                disabled={disabled}
                disableGutters={true}
                disableTouchRipple={true}
                href={
                    hasSubItems
                        ? `${href}/${_.head(subItems)!.view}`
                        : href}
                selected={currentView === view}
                sx={{
                    "&.Mui-selected": {
                        background: theme.palette.sidebar.selected
                    },
                    "&.Mui-selected:hover": {
                        background: theme.palette.sidebar.selected
                    },
                    "&:hover": {
                        background: theme.palette.sidebar.hover
                    },
                    background:
                        showSubItems && hasSubItems
                            ? theme.palette.sidebar.hover
                            : undefined,
                    borderRadius: theme.spacing(0.75),
                    marginLeft: theme.spacing(0.75),
                    marginRight: theme.spacing(0.75),
                    opacity:
                        disabled
                            ? 0.38
                            : undefined,
                    padding: 0,
                    paddingLeft: theme.spacing(0.5)
                }}
                onClick={
                    (event: MouseEvent) => {
                        if (_.isNil(subItems)) {
                            setViewRoute(view);
                            reloadView();
                        } else if (!_.isEmpty(enabledSubItems)) {
                            setViewRoute(`${view}/${enabledSubItems[iteratingSubItemIndex].view}`);
                            reloadView();
                            setIteratingSubItemIndex(
                                iteratingSubItemIndex < enabledSubItems.length - 1
                                    ? iteratingSubItemIndex + 1
                                    : 0);
                        }
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                onMouseEnter={openSubItems}
                onMouseLeave={
                    () => {
                        clearOpenSubItemsTimeoutRef();
                        setIteratingSubItemIndex(0);
                    }}>
                <SidebarItem
                    icon={<Icon sx={{ fontSize: "20px" }}/>}>
                    <Typography
                        noWrap={true}
                        sx={{ fontSize: "14px" }}>
                        {title}
                    </Typography>
                </SidebarItem>
            </ListItem>
            {hasSubItems &&
                <Popper
                    anchorEl={anchorElement}
                    modifiers={[
                        {
                            enabled: true,
                            name: "preventOverflow",
                            options: {
                                tether: true
                            }
                        },
                        {
                            enabled: true,
                            name: "offset",
                            options: {
                                offset: [0, 6]
                            }
                        }
                    ]}
                    open={showSubItems && !_.isNil(anchorElement)}
                    placement="right-start"
                    sx={{ zIndex: theme.zIndex.modal }}>
                    <Stack onMouseLeave={closeSubItems}>
                        <Box
                            sx={{
                                background: theme.palette.background.paper,
                                border: theme.border.primary,
                                borderRadius: theme.spacing(0.75),
                                boxShadow: theme.shadows[Shadows.Card],
                                minWidth: 240,
                                overflow: "hidden",
                                padding: theme.spacing(0.5)
                            }}>
                            <List
                                dense={true}
                                disablePadding={true}>
                                {_.map(
                                    subItems,
                                    (subItem, subItemIndex) =>
                                        <SubItem
                                            isDefault={subItemIndex === 0}
                                            key={subItemIndex}
                                            selected={selectedSubItem?.view === subItem.view}
                                            subItem={subItem}
                                            view={view}/>)}
                            </List>
                        </Box>
                    </Stack>
                </Popper>}
        </Fragment>);
}

export function getSidebarNavigationItemClassName(view: string) {
    return `sidebar-navigationItem-${view}`;
}

type SubItemProps = {
    isDefault: boolean;
    selected: boolean;
    subItem: SidebarNavigationItemSubItem;
    view: string;
};

function SubItem({ isDefault, selected, subItem, view }: SubItemProps) {
    const { rootRelativeUrl, setViewRoute } = useSidebarContext();
    const { match: subViewMatch, subView } = useRoute(`${rootRelativeUrl}/${view}/{subView}`);
    const { reloadView } = useSidebarContext();
    const theme = useTheme();
    return (
        <ListItem
            component="a"
            disabled={!subItem.enabled}
            disableGutters={true}
            disableTouchRipple={true}
            href={`${rootRelativeUrl}/${view}/${subItem.view}`}
            selected={selected || isDefault && subViewMatch && _.isNil(subView)}
            sx={{
                borderRadius: theme.spacing(0.75),
                fontSize: "12px",
                padding: theme.spacing(1.25)
            }}
            onClick={
                (event: MouseEvent) => {
                    setViewRoute(`${view}/${subItem.view}`);
                    reloadView();

                    event.preventDefault();
                    event.stopPropagation();
                }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1.5}>
                <Box sx={{ fontSize: "16px" }}>
                    {subItem.icon}
                </Box>
                <Typography sx={{ fontSize: "12px" }}>
                    {subItem.title}
                </Typography>
            </Stack>
        </ListItem>);
}

const ListItem = styled(ListItemButton)(({ theme }) => ({
    "&.Mui-selected": {
        "&:hover": {
            backgroundColor: theme.palette.action.selected
        },
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.text.primary
    },
    "&:hover": {
        backgroundColor: theme.palette.action.hover
    },
    backgroundColor: "unset"
})) as typeof ListItemButton;