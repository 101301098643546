﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useGcpIamRoleBindingPermissionUsageTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpIamRoleBindingPermissionUsageTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpIamRoleBindingModelPermissionUsageType]: {
                    [Contract.GcpIamRoleBindingModelPermissionUsageType.PartiallyUsed]: "Partially used",
                    [Contract.GcpIamRoleBindingModelPermissionUsageType.Unknown]: "Unknown",
                    [Contract.GcpIamRoleBindingModelPermissionUsageType.Unused]: "Unused",
                    [Contract.GcpIamRoleBindingModelPermissionUsageType.Used]: "Used"
                }
            }));

    return (permissionUsageType: Contract.GcpIamRoleBindingModelPermissionUsageType) => localization[Contract.TypeNames.GcpIamRoleBindingModelPermissionUsageType][permissionUsageType]();
}