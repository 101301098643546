import { Action0, InlineItems, InlineItemsVariant, ItemsPopoverActions, Link, useExecuteOperation } from "@infrastructure";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RiskController, RiskHelper, Severity, Tenant, TypeHelper, useRiskPolicyTitleTranslator, useTenantNameTranslator, useTheme } from "../../common";

type InlineRisksProps = {
    namePluralizer?: (count: number) => string;
    riskIdsOrModels: Contract.RiskModel[] | string[];
    variant: InlineItemsVariant;
};

export function InlineRisks({ namePluralizer, riskIdsOrModels, variant }: InlineRisksProps) {
    const itemsPopoverActionsRef = useRef<ItemsPopoverActions>(null);
    return (
        <InlineItems
            items={riskIdsOrModels}
            itemsPopover={
                () =>
                    <Risks
                        riskIdsOrModels={riskIdsOrModels}
                        onClick={() => itemsPopoverActionsRef.current?.close()}/>}
            itemsPopoverActionsRef={itemsPopoverActionsRef}
            namePluralizer={namePluralizer}
            variant={variant}/>);
}

type RisksProps = {
    onClick: Action0;
    riskIdsOrModels: Contract.RiskModel[] | string[];
};

export function Risks({ onClick, riskIdsOrModels }: RisksProps) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const [riskModels] =
        useExecuteOperation(
            Risks,
            async () => {
                if (_.isString(riskIdsOrModels[0])) {
                    const { riskModels } = await RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(riskIdsOrModels as string[]));
                    return riskModels;
                } else {
                    return riskIdsOrModels as Contract.RiskModel[];
                }
            });
    const orderedRiskModels =
        useMemo(
            () =>
                _(riskModels).
                    orderBy(
                        [
                            riskModel => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskModel.risk.severity),
                            riskModel => riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(riskModel.risk)),
                            riskModel => tenantNameTranslator(riskModel.risk.tenantId)
                        ],
                        [
                            "desc",
                            "asc",
                            "asc"
                        ]).
                    value(),
            [riskModels]);

    const theme = useTheme();
    return (
        <List
            disablePadding={true}
            sx={{
                display: "unset",
                maxHeight: theme.spacing(60)
            }}>
            {_.map(
                orderedRiskModels,
                orderedRiskModel =>
                    <ListItem key={orderedRiskModel.risk.id}>
                        <Risk
                            riskModel={orderedRiskModel}
                            onClick={onClick}/>
                    </ListItem>)}
        </List>);
}

type RiskProps = {
    onClick: Action0;
    riskModel: Contract.RiskModel;
};

function Risk({ onClick, riskModel }: RiskProps) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const theme = useTheme();
    return (
        <Link
            sx={{ width: "100%" }}
            urlOrGetUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(riskModel.risk.id)}
            variant="text"
            onClick={onClick}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}>
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                        flex: 1,
                        overflow: "hidden"
                    }}>
                    <Typography noWrap={true}>
                        {riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(riskModel.risk))}
                    </Typography>
                    <Box sx={{ padding: theme.spacing(0, 0.5) }}>
                        <Separator/>
                    </Box>
                    <Typography noWrap={true}>
                        <Tenant
                            tenantId={riskModel.risk.tenantId}
                            variant="text"/>
                    </Typography>
                </Stack>
                <Severity severity={riskModel.risk.severity}/>
            </Stack>
        </Link>);
}

function Separator() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                borderLeft: `1px solid ${theme.palette.text.secondary}`,
                height: "18px"
            }}/>);
}