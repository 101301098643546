import { DataTableColumn, DataTableSortType, EmptyMessageText, InlineItems, Link, NoneIcon, optionalTableCell, TextValuesFilter, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, ItemTable, useTheme, vulnerabilityModelStore } from "../../../../../../../../../common";
import { VulnerabilityReferenceAuthority } from "./components";
import { useVulnerabilityReferenceAuthorityTranslator } from "./hooks";

type SourcesProps = {
    vulnerabilityRawId: string;
};

export function Sources({ vulnerabilityRawId }: SourcesProps) {
    const vulnerabilityModel = vulnerabilityModelStore.useGet(vulnerabilityRawId);

    const vulnerabilityReferenceAuthorityTranslator = useVulnerabilityReferenceAuthorityTranslator();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.sources",
            () => ({
                columns: {
                    authority: "Authority",
                    link: "Link",
                    tagDatas: "Details"
                },
                empty: "No Items",
                title: "Sources"
            }));

    const theme = useTheme();
    return (
        <ItemTable
            columnIdToGetItemValueMap={
                {
                    [SourcesTableColumnId.Authority]:
                        item =>
                            _.isNil(item.authority)
                                ? undefined
                                : vulnerabilityReferenceAuthorityTranslator(item.authority),
                    [SourcesTableColumnId.PublicationDate]: item => item.publicationDate,
                    [SourcesTableColumnId.TagDatas]: {
                        getFilterValue: item => item.tagDatas,
                        getSortValue: item => _.size(item.tagDatas)
                    },
                    [SourcesTableColumnId.Url]: item => item.url
                }}
            defaultSortColumnIdOrIds={[SourcesTableColumnId.Authority, SourcesTableColumnId.Url]}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getItemId={item => String(item?.url)}
            items={vulnerabilityModel.vulnerability.references}
            showEmptyTable={true}
            sx={{ padding: theme.spacing(0, 2, 0) }}
            variant="view">
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TextValuesFilter
                                    emptyValue={true}
                                    placeholder={localization.columns.authority()}
                                    values={columnIdToItemValuesMap[SourcesTableColumnId.Authority]}/>
                        }
                    }}
                    id={SourcesTableColumnId.Authority}
                    key={SourcesTableColumnId.Authority}
                    render={optionalTableCell<Contract.VulnerabilityReference>(item => <VulnerabilityReferenceAuthority authority={item.authority}/>)}
                    title={localization.columns.authority()}/>,
                <DataTableColumn
                    id={SourcesTableColumnId.Url}
                    key={SourcesTableColumnId.Url}
                    render={
                        ({ item }: { item: Contract.VulnerabilityReference }) =>
                            <Stack
                                sx={{
                                    overflow: "hidden",
                                    width: "100%"
                                }}>
                                <Link
                                    urlOrGetUrl={item.url}
                                    variant="external"/>
                            </Stack>}
                    title={localization.columns.link()}/>,
                <DataTableColumn
                    id={SourcesTableColumnId.TagDatas}
                    key={SourcesTableColumnId.TagDatas}
                    render={({ item }: { item: Contract.VulnerabilityReference }) => <VulnerabilityReferenceTagData tagDatas={item.tagDatas}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.tagDatas()}/>]}
        </ItemTable>);
}

enum SourcesTableColumnId {
    Authority = "authority",
    PublicationDate = "publicationDate",
    TagDatas = "tagDatas",
    Url = "url"
}

type VulnerabilityReferenceTagDataProps = {
    tagDatas: Contract.VulnerabilityReferenceTagData[];
};

function VulnerabilityReferenceTagData({ tagDatas }: VulnerabilityReferenceTagDataProps) {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.sources.vulnerabilityReferenceTagData",
            () => ({
                title: "{{tag}}: {{parameter}}",
                [Contract.TypeNames.VulnerabilityReferenceTag]: {
                    [Contract.VulnerabilityReferenceTag.AptGroup]: "APT group",
                    [Contract.VulnerabilityReferenceTag.CategorizationCloud]: "Categorization: Cloud",
                    [Contract.VulnerabilityReferenceTag.CategorizationIdentity]: "Categorization: Identity",
                    [Contract.VulnerabilityReferenceTag.CategorizationOt]: "Categorization: OT",
                    [Contract.VulnerabilityReferenceTag.Exploit]: "Exploit",
                    [Contract.VulnerabilityReferenceTag.ExploitChain]: "Exploit chain",
                    [Contract.VulnerabilityReferenceTag.ExploitDisclosed]: "Exploit: Disclosed",
                    [Contract.VulnerabilityReferenceTag.ExploitFunctional]: "Exploit: Functional",
                    [Contract.VulnerabilityReferenceTag.ExploitHigh]: "Exploit: High",
                    [Contract.VulnerabilityReferenceTag.ExploitPoc]: "Exploit: POC",
                    [Contract.VulnerabilityReferenceTag.ExploitedInTheWild]: "Exploited in the wild",
                    [Contract.VulnerabilityReferenceTag.InitialCompromiseVector]: "Initial compromise vector",
                    [Contract.VulnerabilityReferenceTag.MalwareName]: "Malware",
                    [Contract.VulnerabilityReferenceTag.NamedVulnerability]: "Vulnerability name",
                    [Contract.VulnerabilityReferenceTag.PersistentlyExploited]: "Persistently exploited",
                    [Contract.VulnerabilityReferenceTag.PostCompromiseVector]: "Post compromise vector",
                    [Contract.VulnerabilityReferenceTag.RansomwareGroup]: "Ransomware group",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityBeingMonitored]: "Vulnerability being monitored",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityOfConcern]: "Vulnerability of concern",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityOfInterest]: "Vulnerability of interest",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeArbitraryFileRead]: "Vulnerability type: Arbitrary file read",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeArbitraryFileWrite]: "Vulnerability type: Arbitrary file write",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeAuthenticationBypass]: "Vulnerability type: Authentication bypass",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeBufferOverflow]: "Vulnerability type: Buffer overflow",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeCodeInjection]: "Vulnerability type: Code injection",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeCommandExecution]: "Vulnerability type: Command execution",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeCommandInjection]: "Vulnerability type: Command injection",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeCrossSiteRequestForgery]: "Vulnerability type: Cross site request forgery",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeCrossSiteScripting]: "Vulnerability type: Cross site scripting",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeDenialOfService]: "Vulnerability type: Denial of service",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeDeserializationOfUntrustedData]: "Vulnerability type: Deserialization of untrusted data",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeDirectoryTraversal]: "Vulnerability type: Directory traversal",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeHardcodedCredentials]: "Vulnerability type: Hardcoded credentials",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeHeapBasedBufferOverflow]: "Vulnerability type: Heap based buffer overflow",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeImproperAuthentication]: "Vulnerability type: Improper authentication",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeImproperInputValidation]: "Vulnerability type: Improper input validation",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeInformationDisclosure]: "Vulnerability type: Information disclosure",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeMissingAuthentication]: "Vulnerability type: Missing authentication",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeOther]: "Vulnerability type: Other",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeOutOfBoundsRead]: "Vulnerability type: Out of bounds read",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeOutOfBoundsWrite]: "Vulnerability type: Out of bounds write",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypePrivilegeEscalation]: "Vulnerability type: Privilege escalation",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeRaceCondition]: "Vulnerability type: Race condition",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeRemoteCodeExecution]: "Vulnerability type: Remote code execution",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeSecurityFeatureBypass]: "Vulnerability type: Security feature bypass",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeServerSideRequestForgery]: "Vulnerability type: Server side request forgery",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeSqlInjection]: "Vulnerability type: SQL injection",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeUnrestrictedUploadOfFile]: "Vulnerability type: Unrestricted upload of file",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeUseAfterFree]: "Vulnerability type: Use after free",
                    [Contract.VulnerabilityReferenceTag.VulnerabilityTypeXmlExternalEntity]: "Vulnerability type: XML external entity",
                    [Contract.VulnerabilityReferenceTag.WeaponizationApt]: "Weaponization: APT",
                    [Contract.VulnerabilityReferenceTag.WeaponizationMalware]: "Weaponization: Malware",
                    [Contract.VulnerabilityReferenceTag.WeaponizationOther]: "Weaponization: Other",
                    [Contract.VulnerabilityReferenceTag.WeaponizationRansomware]: "Weaponization: Ransomware",
                    [Contract.VulnerabilityReferenceTag.Wormable]: "Wormable",
                    [Contract.VulnerabilityReferenceTag.ZeroDay]: "Zero Day"
                }
            }));

    const translatedTagDatas =
        useMemo(
            () =>
                _(tagDatas).
                    map(
                        ({ parameter, tag }) =>
                            _.isNil(parameter)
                                ? localization[Contract.TypeNames.VulnerabilityReferenceTag][tag]()
                                : localization.title({
                                    parameter,
                                    tag: localization[Contract.TypeNames.VulnerabilityReferenceTag][tag]()
                                })).
                    sortBy().
                    value(),
            [tagDatas]);

    return (
        _.isEmpty(translatedTagDatas)
            ? <NoneIcon/>
            : <InlineItems
                items={translatedTagDatas}
                variant="itemPlusItemCount"/>);
}