import { DataTableColumn, DataTableSortType, DeferredFilter, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsIamServerCertificateDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const serverCertificateModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsIamServerCertificateModelFilters>;

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsIamServerCertificateDefinition",
            () => ({
                columns: {
                    creationTime: "Upload Time",
                    expirationTime: "Expiration Time",
                    uniqueId: "ID"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.getCreationTimeColumn(localization.columns.creationTime()),
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamServerCertificateModel) => ({
                            [localization.columns.expirationTime()]: TimeFormatter.iso8601String((item.entity as Contract.AwsIamServerCertificate).expirationTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={serverCertificateModelFiltersPromise}
                                title={localization.columns.expirationTime()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.expirationTimeRange.emptyValue}
                                        placeholder={localization.columns.expirationTime()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.expirationTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamServerCertificateExpirationTime}
                itemProperty={(item: Contract.AwsIamServerCertificateModel) => TimeFormatter.monthDayAndYear((item.entity as Contract.AwsIamServerCertificate).expirationTime)}
                key={Contract.EntityModelProperty.AwsIamServerCertificateExpirationTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.expirationTime()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamServerCertificateModel) => ({
                            [localization.columns.uniqueId()]: (item.entity as Contract.AwsIamServerCertificate).uniqueId
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsIamServerCertificateUniqueId)}
                                placeholder={localization.columns.uniqueId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamServerCertificateUniqueId}
                itemProperty={item => (item.entity as Contract.AwsIamServerCertificate).uniqueId}
                key={Contract.EntityModelProperty.AwsIamServerCertificateUniqueId}
                title={localization.columns.uniqueId()}/>,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsIamServerCertificateRequest(
                new Contract.EntityControllerGetEntityModelPageAwsIamServerCertificateRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsIamServerCertificateExpirationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamServerCertificateUniqueId])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}