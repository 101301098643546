import { InlineItems, NoneIcon } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";
import { Vendor } from "../../../../Vendor";

export function CommonVendor({ item }: UdmObjectPropertyItemProps) {
    return _.isEmpty(item)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : <InlineItems
            items={item}
            variant="itemPlusItemCount">
            {vendorType => <Vendor vendorType={vendorType}/>}
        </InlineItems>;
}