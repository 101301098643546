import { ApiError, Link, Message, MultiSelect, useLocalization } from "@infrastructure";
import { CircularProgress, FormControl, InputLabel, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, PermissionManagementHelper, ScopeHelper, scopeSystemEntityModelStore, SlackIcon, useScopeNavigationViewContext, useTheme } from "../../../../../../../common";
import { IntegrationInformation } from "../components";

export function Slack() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const slackWorkspaceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetSlack());
    const [enabledSlackWorkspaceIds, validSlackWorkspaceModelMap] =
        useMemo(
            () => {
                const validSlackWorkspaceModelMap =
                    _(slackWorkspaceModels).
                        filter(slackWorkspaceModel => (slackWorkspaceModel.state as Contract.SlackWorkspaceState).issue != Contract.SlackWorkspaceStateIssue.OptionalPermissionsNotExist).
                        keyBy(slackWorkspaceModel => slackWorkspaceModel.configuration.id).
                        value();
                const enabledSlackWorkspaceIds =
                    _(slackWorkspaceModels).
                        filter(slackWorkspaceModel => (slackWorkspaceModel.configuration as Contract.SlackWorkspaceConfiguration).permissionManagementEnabled).
                        map(slackWorkspaceModel => slackWorkspaceModel.configuration.id).
                        value();
                return [
                    enabledSlackWorkspaceIds,
                    validSlackWorkspaceModelMap];
            },
            [slackWorkspaceModels]);

    const [selectedSlackWorkspaceIds, setSelectedSlackWorkspaceIds] =
        useState(
            _.isEmpty(enabledSlackWorkspaceIds)
                ? _.keys(validSlackWorkspaceModelMap)
                : enabledSlackWorkspaceIds);

    const [slackEnabled, setSlackEnabled] = useState(PermissionManagementHelper.slackEnabled);

    const [updateSlackEnabledError, setUpdateSlackEnabledError] = useState(false);
    const [updateSlackEnabledExecuting, setUpdateSlackEnabledExecuting] = useState(false);

    const [updateSlackWorkspacesPermissionManagementEnabledError, setUpdateSlackWorkspacesPermissionManagementEnabledError] = useState<Contract.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError | true>();
    const [updateSlackWorkspacesPermissionManagementEnabledExecuting, setUpdateSlackWorkspacesPermissionManagementEnabledExecuting] = useState(false);

    const executing =
        useMemo(
            () => updateSlackEnabledExecuting || updateSlackWorkspacesPermissionManagementEnabledExecuting,
            [updateSlackEnabledExecuting, updateSlackWorkspacesPermissionManagementEnabledExecuting]);

    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.slack",
            () => ({
                description: {
                    items: {
                        item1: "Users can request access via Slack commands",
                        item2: "Approval requests are sent via private Slack message to all approvers",
                        item3: "Send approval requests to specific Slack channels",
                        item4: "Requests can be approved natively within Slack"
                    }
                },
                slackEnabled: {
                    empty: {
                        link: "connect your Slack workspace",
                        title: "To enable Slack for JIT, first {{link}} with Tenable Cloud Security, or, if you already have a workspace configured, update it to grant Tenable Cloud Security the required permissions."
                    },
                    error: "Failed to change slack status",
                    unlicensed: "JIT feature is not enabled"
                },
                title: "Slack integration",
                workspaces: {
                    disabled: "You must select at least one workspace",
                    [Contract.TypeNames.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError]: {
                        [Contract.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError.PermissionManagementSlackNotEnabled]: "Slack-JIT is not enabled",
                        [Contract.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError.ValidPermissionManagementEnabledWorkspaceNotExists]: "At least one workspace must be selected"
                    },
                    error: "Failed to update workspaces",
                    placeholder: "Workspaces",
                    selected: "{{workspaceCount | NumberFormatter.humanize}} selected",
                    title: "Select workspaces:"
                }
            }));

    async function configurationControllerUpdateSlackWorkspacesPermissionManagementEnabled() {
        setUpdateSlackWorkspacesPermissionManagementEnabledError(undefined);

        try {
            await ConfigurationController.updateSlackWorkspacesPermissionManagementEnabled(new Contract.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledRequest(selectedSlackWorkspaceIds));
            await scopeSystemEntityModelStore.notify();
            return true;
        } catch (error) {
            setUpdateSlackWorkspacesPermissionManagementEnabledError(
                error instanceof ApiError && error.statusCode === 400
                    ? error.error as Contract.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError
                    : true);
            return false;
        }
    }

    async function updateSlackEnabled() {
        setUpdateSlackEnabledExecuting(true);
        setUpdateSlackEnabledError(false);

        const toggleSlackEnabled = !slackEnabled;
        try {
            await ConfigurationController.updatePermissionManagementSlackEnabled(new Contract.ConfigurationControllerUpdatePermissionManagementSlackEnabledRequest(toggleSlackEnabled));
            setSlackEnabled(toggleSlackEnabled);
            await PermissionManagementHelper.initialize();
            if (toggleSlackEnabled && _.isEmpty(enabledSlackWorkspaceIds)) {
                await configurationControllerUpdateSlackWorkspacesPermissionManagementEnabled();
            }
        } catch (error) {
            setUpdateSlackEnabledError(true);
        }

        setUpdateSlackEnabledExecuting(false);
    }

    async function updateSlackWorkspacesPermissionManagementEnabled() {
        setUpdateSlackWorkspacesPermissionManagementEnabledExecuting(true);

        const success = await configurationControllerUpdateSlackWorkspacesPermissionManagementEnabled();

        setUpdateSlackWorkspacesPermissionManagementEnabledExecuting(false);

        return success;
    }

    const theme = useTheme();
    return (
        <IntegrationInformation
            emptyTitle={
                _.isEmpty(validSlackWorkspaceModelMap)
                    ? localization.slackEnabled.empty.title({
                        link:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.GetConfigurationIntegrationsSlackRelativeUrl()}
                                variant="external">
                                {localization.slackEnabled.empty.link()}
                            </Link>
                    })
                    : undefined}
            icon={<SlackIcon sx={{ fontSize: "36px" }}/>}
            items={[
                localization.description.items.item1(),
                localization.description.items.item2(),
                localization.description.items.item3(),
                localization.description.items.item4()]}
            title={localization.title()}
            toggleOptions={{
                checked: slackEnabled,
                disabled:
                    executing ||
                    !slackEnabled &&
                    _(validSlackWorkspaceModelMap).
                        filter(validSlackWorkspace => _.isNil((validSlackWorkspace.state as Contract.SlackWorkspaceState).issue)).
                        isEmpty(),
                errorMessage:
                    updateSlackEnabledError
                        ? localization.slackEnabled.error()
                        : undefined,
                executing: updateSlackEnabledExecuting,
                onClick: updateSlackEnabled
            }}>
            {slackEnabled && (
                <Stack spacing={2}>
                    <Typography>
                        {localization.workspaces.title()}
                    </Typography>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <FormControl fullWidth={true}>
                            <InputLabel
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    padding: theme.spacing(0, 1)
                                }}>
                                {localization.workspaces.placeholder()}
                            </InputLabel>
                            <MultiSelect
                                disabled={executing}
                                fieldOptions={{ dense: true }}
                                fullWidth={true}
                                items={_.keys(validSlackWorkspaceModelMap)}
                                mandatoryItems={
                                    selectedSlackWorkspaceIds.length === 1
                                        ? selectedSlackWorkspaceIds
                                        : undefined}
                                mandatoryItemsTooltipText={localization.workspaces.disabled()}
                                selectedItems={selectedSlackWorkspaceIds}
                                selectedItemsTranslator={selectedItems => localization.workspaces.selected({ workspaceCount: selectedItems.length })}
                                onClose={
                                    async () => {
                                        if (_.isEqual(
                                            enabledSlackWorkspaceIds,
                                            selectedSlackWorkspaceIds)) {
                                            return;
                                        }
                                        const success = await updateSlackWorkspacesPermissionManagementEnabled();
                                        if (!success) {
                                            setSelectedSlackWorkspaceIds(enabledSlackWorkspaceIds);
                                        }
                                    }}
                                onSelectedItemsChanged={selectedItems => setSelectedSlackWorkspaceIds(selectedItems)}>
                                {workspaceId => (validSlackWorkspaceModelMap[workspaceId].configuration as Contract.SlackWorkspaceConfiguration).name}
                            </MultiSelect>
                        </FormControl>
                        {updateSlackWorkspacesPermissionManagementEnabledExecuting &&
                            <CircularProgress size="18px"/>}
                        {!_.isNil(updateSlackWorkspacesPermissionManagementEnabledError) &&
                            <Message
                                level="error"
                                title={
                                    updateSlackWorkspacesPermissionManagementEnabledError === true
                                        ? localization.workspaces.error()
                                        : localization.workspaces[Contract.TypeNames.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError][updateSlackWorkspacesPermissionManagementEnabledError]()}
                                variant="minimal"/>}
                    </Stack>
                </Stack>)}
        </IntegrationInformation>);
}