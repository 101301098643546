import { DataTableColumn, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonIamResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useOciIamDomainLicenseTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciIamDomainDefinition(definitionData: DefinitionData) {
    const commonIamResourceDefinition = useOciCommonIamResourceDefinition(definitionData);

    const domainLicenseTypeTranslator = useOciIamDomainLicenseTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciIamDomainDefinition",
            () => ({
                columns: {
                    defaultDomain: {
                        false: "Secondary",
                        title: "Type",
                        true: "Default"
                    },
                    licenseType: "License Type"
                }
            }));

    function translateDefaultDomain(domainModel: Contract.OciIamDomainModel) {
        return (domainModel.entity as Contract.OciIamDomain).defaultDomain
            ? localization.columns.defaultDomain.true()
            : localization.columns.defaultDomain.false();
    }

    return new EntityTableDefinition(
        [
            commonIamResourceDefinition.columns.tenantColumn,
            commonIamResourceDefinition.columns.creationTimeColumn,
            commonIamResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciIamDomainModel) => ({
                            [localization.columns.licenseType()]: domainLicenseTypeTranslator((item.entity as Contract.OciIamDomain).licenseType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciIamDomainLicenseType}
                                enumTypeTranslator={domainLicenseTypeTranslator}
                                placeholder={localization.columns.licenseType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciIamDomainLicenseType}
                itemProperty={(item: Contract.EntityModel) => domainLicenseTypeTranslator((item.entity as Contract.OciIamDomain).licenseType)}
                key={Contract.EntityModelProperty.OciIamDomainLicenseType}
                title={localization.columns.licenseType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciIamDomainModel) => ({
                            [localization.columns.defaultDomain.title()]: translateDefaultDomain(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.defaultDomain.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.defaultDomain.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.defaultDomain.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciIamDomainDefaultDomain}
                itemProperty={translateDefaultDomain}
                key={Contract.EntityModelProperty.OciIamDomainDefaultDomain}
                title={localization.columns.defaultDomain.title()}/>,
            commonIamResourceDefinition.columns.enabledColumn,
            commonIamResourceDefinition.columns.descriptionColumn,
            commonIamResourceDefinition.columns.regionColumn,
            commonIamResourceDefinition.columns.regionLocationColumn,
            commonIamResourceDefinition.columns.tagsColumn,
            commonIamResourceDefinition.columns.attributesColumn,
            commonIamResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonIamResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonIamResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciIamDomainRequest(
                new Contract.EntityControllerGetEntityModelPageOciIamDomainRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamResourceDescription]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamResourceEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamDomainDefaultDomain]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamDomainLicenseType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}