﻿import { formatQueryParameters, getUrlQueryParameters, Loading, makeContextProvider, map, useExecuteOperation, useLocalization, useRoute } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useEffect, useMemo } from "react";
import { ConfigurationController, GeographyHelper, LogoTextIcon, StorageHelper, UrlHelper } from "../../common";
import { Regions } from "./components";

class GeographySelectorContext {
    constructor(public open: () => void) {
    }
}

export const [useGeographySelectorContext, , useGeographySelectorContextProvider] = makeContextProvider<GeographySelectorContext>();

type GeographySelectorProps = {
    children: () => ReactNode;
};

type GeographySelectorQueryParameters = {
    reset?: string;
};

export function GeographySelector({ children }: GeographySelectorProps) {
    useExecuteOperation(
        GeographySelector,
        GeographyHelper.initialize);

    const { RedirectPath, redirectUrl } = UrlHelper.getRedirectUrlQueryParameters();

    const mode =
        useMemo(
            () => {
                if (GeographyHelper.globalConsoleAppUrl != `${window.location.origin}${window.location.pathname}`) {
                    return "continue";
                }

                const { reset } = getUrlQueryParameters<GeographySelectorQueryParameters>();
                if (reset) {
                    StorageHelper.geographySelectedConsoleAppUrl.removeValue();
                }

                const selectedGeographyConsoleAppUrl = StorageHelper.geographySelectedConsoleAppUrl.getValue();
                if (!_.isNil(selectedGeographyConsoleAppUrl)) {
                    const url =
                        redirectUrl === "/customer/docs" && RedirectPath?.startsWith("/docs")
                            ? `${redirectUrl}${RedirectPath.replace("/docs", "")}`
                            : redirectUrl;
                    window.location.assign(
                        _.isNil(url)
                            ? selectedGeographyConsoleAppUrl
                            : new URL(UrlHelper.sanitize(url), selectedGeographyConsoleAppUrl));
                    return "loading";
                }

                return "selectGeography";
            },
            []);

    const [, , GeographySelectorContextProvider] =
        useGeographySelectorContextProvider(
            () =>
                new GeographySelectorContext(
                    () => {
                        const globalConsoleAppUrlReset = `${GeographyHelper.globalConsoleAppUrl}?${formatQueryParameters<GeographySelectorQueryParameters>({ reset: "true" })}`;
                        window.location.assign(
                            _.isNil(redirectUrl)
                                ? globalConsoleAppUrlReset
                                : `${globalConsoleAppUrlReset}&${UrlHelper.formatRedirectUrlQueryParameters(redirectUrl)}`);
                    }));

    const { view } = useRoute("/{view}");

    if (view == GeographySelectorView.GitHubOnboarding) {
        return <GitHubOnboarding/>;
    }

    return (
        <GeographySelectorContextProvider>
            {map(
                mode,
                {
                    "continue": () => children(),
                    "loading": () => <Loading/>,
                    "selectGeography": () => <Core consoleAppGeographyTypeToUrlMap={GeographyHelper.consoleAppGeographyTypeToUrlMap}/>
                })}
        </GeographySelectorContextProvider>);
}

export type GeographySelectorGitHubOnboardingState = {
    deploymentName: string;
    serverId?: string;
};

export enum GeographySelectorView {
    GitHubOnboarding = "gitHubOnboarding"
}

type CoreProps = {
    consoleAppGeographyTypeToUrlMap: Dictionary<string>;
};

function Core({ consoleAppGeographyTypeToUrlMap }: CoreProps) {
    const localization =
        useLocalization(
            "views.geographySelector.core",
            () => ({
                message: "To log in to Tenable Cloud Security, select your organization's region:",
                title: "Tenable Cloud Security - Select Region"
            }));

    useEffect(
        () => {
            document.title = localization.title();
        },
        []);

    return (
        <Stack
            justifyContent="center"
            spacing={16}
            sx={{ flex: 1 }}>
            <Stack
                alignItems="center"
                spacing={8}>
                <Stack
                    alignItems="center"
                    spacing={3}>
                    <LogoTextIcon
                        sx={{
                            height: "37px",
                            width: "323px"
                        }}/>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 450
                        }}>
                        {localization.message()}
                    </Typography>
                </Stack>
                <Regions consoleAppGeographyTypeToUrlMap={consoleAppGeographyTypeToUrlMap}/>
            </Stack>
        </Stack>);
}

type GitHubOnboardingQueryParameters = {
    code?: string;
    state: string;
};

function GitHubOnboarding() {
    useEffect(
        () => {
            const { code, state: rawState } = getUrlQueryParameters<GitHubOnboardingQueryParameters>();
            const state = JSON.parse(rawState) as GeographySelectorGitHubOnboardingState;

            window.location.assign(
                UrlHelper.getUrl(
                    new URL(GeographyHelper.deploymentNameToConsoleUrlMap[state.deploymentName]),
                    ConfigurationController.processGitHubOrganizationCallbackUrl(code, state.serverId)));
        },
        []);
    return <Loading/>;
}