﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useKubernetesAdmissionControllerRiskPolicyResultTranslator() {
    const localization =
        useLocalization(
            "views.customer.kubernetes.hooks.useKubernetesAdmissionControllerRiskPolicyResultTranslator",
            () => ({
                [Contract.TypeNames.KubernetesAdmissionControllerRiskPolicyResult]: {
                    [Contract.KubernetesAdmissionControllerRiskPolicyResult.Alert]: "Alerted",
                    [Contract.KubernetesAdmissionControllerRiskPolicyResult.Deny]: "Denied",
                    [Contract.KubernetesAdmissionControllerRiskPolicyResult.Pass]: "Passed"
                }
            }));
    return (riskPolicyResult: Contract.KubernetesAdmissionControllerRiskPolicyResult) =>
        localization[Contract.TypeNames.KubernetesAdmissionControllerRiskPolicyResult][riskPolicyResult]();
}