import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsElastiCacheReplicationGroupRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRisk;
    const replicationGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElastiCacheReplicationGroupModel;

    const getAwsElastiCacheReplicationGroupRiskContext = useGetAwsElastiCacheReplicationGroupRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskDefinition",
            () => ({
                description: "{{replicationGroup}}  is not enforcing transport encryption over HTTPS",
                sections: {
                    resolution: {
                        step1: "Click **Modify**",
                        step2: "Under the **Security** section, enable the **Encryption in transit** option",
                        step3: "When encryption in transit is enabled, in order for you to move from Preferred to Required transit encryption mode, you need to migrate all clients to use TLS connections before you can move to Required. Then, modify your cluster to use the Required mode to only allow encrypted configurations.",
                        step4: "Click **Preview changes**",
                        step5: "Click **Modify**",
                        step6: "Before setting transit encryption mode to Required, make sure you migrated all clients to use TLS connections before to avoid downtime.",
                        step7: "Click **Modify** to edit the same settings",
                        step8: "Set **Transit encryption mode** to **Required**",
                        step9: "Click **Preview changes**",
                        step10: "Click **Modify**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            replicationGroup:
                <Entity
                    entityIdOrModel={replicationGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ElastiCache,
                AwsConsoleUrlBuilder.getElastiCacheReplicationGroupUrl(replicationGroupModel.entity as Contract.AwsElastiCacheReplicationGroup)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8(),
            localization.sections.resolution.step9(),
            localization.sections.resolution.step10()
        ],
        riskModel,
        () => {
            const replicationGroupContextItems = getAwsElastiCacheReplicationGroupRiskContext(replicationGroupModel);
            return [
                replicationGroupContextItems.generalInformation,
                replicationGroupContextItems.transitEncryptionMode,
                replicationGroupContextItems.sensitive,
                replicationGroupContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}