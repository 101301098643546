import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { useGetOciIamUserAuthTokenRiskContext } from "../contexts";

export function useOciIamUserAuthTokenNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciIamUserAuthTokenNotRotatedRisk;
    const userAuthTokenModel = entityModelStore.useGet(risk.entityId) as Contract.OciIamUserAuthTokenModel;

    const getOciIamUserAuthTokenRiskContext = useGetOciIamUserAuthTokenRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciIamUserAuthTokenNotRotatedRiskDefinition",
            () => ({
                description: "{{userAuthToken}} was not rotated for more than {{userAuthTokenRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                sections: {
                    resolution: {
                        step1: "Regenerating user auth tokens may break existing client connectivity, as the client will try to connect with older user auth tokens. Therefore, it is better to create a new user auth token and delete the old one after you migrate your application to use the new user auth token.",
                        step2: "Click **Generate token** and then **Generate token**",
                        step3: "Before deleting the old user auth token, migrate your applications (if any) to use the newly created user auth token",
                        step4: "In the **Auth tokens** page, select the 3-dot menu next to the old user auth token and click **Delete**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (localization.description({
            userAuthToken:
                <Entity
                    entityIdOrModel={userAuthTokenModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            userAuthTokenRotationTimeFrame: risk.credentialRotationTimeFrame
        })),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.User,
                userAuthTokenModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const keyRiskContext = getOciIamUserAuthTokenRiskContext(userAuthTokenModel);
            return [
                keyRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.credentialRotationTimeFrame),
                keyRiskContext.creationTime,
                keyRiskContext.belongsTo,
                keyRiskContext.sensitive
            ];
        });
}