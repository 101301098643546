import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { GciConsoleUrlBuilder } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGciDirectoryUserRiskContext } from "../contexts";

export function useGciDirectoryUserInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GciDirectoryUserInactiveRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.GciDirectoryUserModel;

    const getGciDirectoryUserRiskContext = useGetGciDirectoryUserRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gci.hooks.compliance.useGciDirectoryUserInactiveRiskDefinition",
            () => ({
                description: "{{user}} is inactive and should be suspended or removed",
                sections: {
                    context: {
                        [Contract.TypeNames.GciDirectoryUserInactiveRiskModelInfoType]: {
                            [Contract.GciDirectoryUserInactiveRiskModelInfoType.SignInNever]: "The user was not active since it was created",
                            [Contract.GciDirectoryUserInactiveRiskModelInfoType.SignInTime]: "The user was last active {{signInTime | TimeFormatter.humanizePastDuration}}",
                            [Contract.GciDirectoryUserInactiveRiskModelInfoType.SignInUnknown]: "The user was not seen active"
                        }
                    },
                    resolution: {
                        step1: {
                            link: "Details page",
                            text: "In Google Admin Portal, open the {{detailsLink}} of {{user}}"
                        },
                        step2: {
                            [Contract.TypeNames.GciDirectoryUserInactiveRiskResolutionType]: {
                                [Contract.GciDirectoryUserInactiveRiskResolutionType.Delete]: "click **DELETE USER** on the left menu and then click **DELETE USER**",
                                [Contract.GciDirectoryUserInactiveRiskResolutionType.DeleteDisable]: "To delete the user, click **DELETE USER** on the left menu and then click **DELETE USER**"
                            }
                        },
                        step3: "To suspend the user, click on **SUSPEND USER** on the left menu and then click **SUSPEND**",
                        title: {
                            [Contract.TypeNames.GciDirectoryUserInactiveRiskResolutionType]: {
                                [Contract.GciDirectoryUserInactiveRiskResolutionType.Delete]: "Delete {{user}}",
                                [Contract.GciDirectoryUserInactiveRiskResolutionType.DeleteDisable]: "Delete/suspend {{user}}"
                            }
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            new Step(
                localization.sections.resolution.title[Contract.TypeNames.GciDirectoryUserInactiveRiskResolutionType][risk.resolutionType]({
                    user:
                        <Entity
                            entityIdOrModel={userModel}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>
                }),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {_.filter([
                                localization.sections.resolution.step1.text({
                                    detailsLink:
                                        <Link
                                            urlOrGetUrl={GciConsoleUrlBuilder.GetUserUrl((userModel.entity as Contract.GciDirectoryUser).uniqueId)}
                                            variant="external">
                                            {localization.sections.resolution.step1.link()}
                                        </Link>,
                                    user:
                                        <Entity
                                            entityIdOrModel={userModel}
                                            linkOptions={{ disabled: true }}
                                            variant="typeText"/>
                                }),
                                localization.sections.resolution.step2[Contract.TypeNames.GciDirectoryUserInactiveRiskResolutionType][risk.resolutionType](),
                                risk.resolutionType === Contract.GciDirectoryUserInactiveRiskResolutionType.DeleteDisable
                                    ? localization.sections.resolution.step3()
                                    : undefined
                            ])}
                        </Steps>
                }
            )
        ],
        riskModel,
        () => {
            const directoryUserRiskContext = getGciDirectoryUserRiskContext(userModel);
            return [
                directoryUserRiskContext.generalInformation,
                new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.GciDirectoryUserInactiveRiskModelInfoType][(riskModel as Contract.GciDirectoryUserInactiveRiskModel).infoType]({ signInTime: risk.authenticationActivity?.time })),
                directoryUserRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}