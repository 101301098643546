import { Action0, Dropdown, DropdownActions, Loading, Optional, useActions, useChangeEffect, useLocalization, useWindowEventEffect, WidthCounter } from "@infrastructure";
import { Box, Breakpoint, Grid2, Stack, SxProps, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, Ref, useCallback, useRef, useState } from "react";
import { useTheme } from "..";

type TopItemsProps = {
    actionsRef?: Ref<Optional<TopItemsActions>>;
    additionalItem?: TopItemsAdditionalItem;
    breakpointToTopCount?: Dictionary<number>;
    children: (item: any) => ReactNode;
    dropdownRenderer?: (item: any) => ReactNode;
    getItemId?: (item: any) => string;
    items: any[];
    itemSizeForLimitCalculations?: number;
    orderVariant?: "leftToRight" | "rightToLeft";
    variant?: TopItemsVariants;
    widthCounterSx?: SxProps;
};

type TopItemsAdditionalItem = {
    element: ReactNode;
    width: number;
};

type TopItemsData = {
    otherItems: any[];
    topItems: any[];
    topItemsCount: Optional<number>;
};

export type TopItemsActions = {
    close: Action0;
};

export type TopItemsVariants = "breakpoints" | "dynamic" | "wrap";

export function TopItems({ actionsRef, additionalItem, breakpointToTopCount = { "xs": 3 }, children: renderItem, dropdownRenderer, getItemId = undefined, items, itemSizeForLimitCalculations = 0, orderVariant = "rightToLeft", variant = "breakpoints", widthCounterSx }: TopItemsProps) {
    const dropdownActionsRef = useRef<DropdownActions>();
    getItemId = getItemId ?? (item => item);
    const [{ otherItems, topItems, topItemsCount }, setItems] =
        useState<TopItemsData>({
            otherItems: [],
            topItems: [],
            topItemsCount: undefined
        });
    const reducedWidth =
        (_.size(otherItems) > 0
            ? 33
            : 0) +
        (additionalItem?.width ?? 0);

    useActions<TopItemsActions>(
        actionsRef,
        {
            close() {
                dropdownActionsRef.current?.close();
            }
        });

    const onCountChange =
        useCallback(
            (topDynamicItemsCount: Optional<number>) => {
                const topItemsCount =
                    variant === "breakpoints"
                        ? topBreakpointsCount
                        : Math.max(topDynamicItemsCount ?? 1, 1);
                const [otherItems, topItems] =
                    variant === "wrap"
                        ? [[], items]
                        : [_.drop(items, topItemsCount), _.take(items, topItemsCount)];
                setItems({
                    otherItems,
                    topItems,
                    topItemsCount
                });
            },
            [variant, items]);
    useChangeEffect(
        () => onCountChange(topItemsCount),
        [items]);

    const theme = useTheme();
    function getTopCount() {
        if (variant === "breakpoints") {
            const breakpoints = _.reverse([...theme.breakpoints.keys]);
            for (const breakpoint of breakpoints) {
                if (!_.isNil(breakpointToTopCount[breakpoint]) &&
                    window.innerWidth >= theme.breakpoints.get(breakpoint as Breakpoint)) {
                    return breakpointToTopCount[breakpoint];
                }
            }
        }

        return undefined;
    }

    const [topBreakpointsCount, setTopBreakpointsCount] =
        useState<number | undefined>(
            variant === "breakpoints"
                ? getTopCount()
                : undefined);

    useWindowEventEffect(
        "resize",
        () => {
            if (variant === "breakpoints") {
                const nextTopBreakpointCount = getTopCount();

                if (topBreakpointsCount !== nextTopBreakpointCount) {
                    setTopBreakpointsCount(nextTopBreakpointCount);
                }
            }
        });

    const localization =
        useLocalization(
            "common.topItems",
            () => ({
                additionalItems: "+{{otherItemCount | NumberFormatter.humanize}}"
            }));

    const [hover, setHover] = useState(false);
    return (
        <WidthCounter
            reducedWidth={reducedWidth}
            sx={widthCounterSx}
            width={itemSizeForLimitCalculations}
            onCountChange={onCountChange}>
            {() => (
                <Stack
                    alignItems="center"
                    className="topItems"
                    direction={
                        orderVariant === "rightToLeft"
                            ? "row-reverse"
                            : "row"}
                    spacing={1}>
                    <Loading
                        container="cell"
                        loading={_.isNil(topItemsCount)}>
                        <List
                            getItemId={getItemId!}
                            items={topItems}
                            renderItem={renderItem}
                            variant={orderVariant}
                            wrap={variant === "wrap"}/>
                        {(!_.isEmpty(otherItems) || !_.isNil(additionalItem?.element)) &&
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                {!_.isEmpty(otherItems) &&
                                    <Box>
                                        <Dropdown
                                            actionsRef={dropdownActionsRef}
                                            popoverElement={
                                                <Loading container="popup">
                                                    <List
                                                        getItemId={getItemId!}
                                                        items={otherItems}
                                                        renderItem={dropdownRenderer ?? renderItem}
                                                        variant="dropdown"
                                                        wrap={false}/>
                                                </Loading>}
                                            popoverElementContainerSx={{ padding: 0 }}>
                                            {open =>
                                                <Typography
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        border:
                                                            hover || open
                                                                ? theme.border.hoverFocus
                                                                : theme.border.primary,
                                                        borderRadius: theme.spacing(0.75),
                                                        fontWeight: 500,
                                                        padding: theme.spacing(0, 0.5)
                                                    }}
                                                    onMouseEnter={() => setHover(true)}
                                                    onMouseLeave={() => setHover(false)}>
                                                    {localization.additionalItems({ otherItemCount: otherItems.length })}
                                                </Typography>}
                                        </Dropdown>
                                    </Box>}
                                {additionalItem?.element}
                            </Stack>}
                    </Loading>
                </Stack>)}
        </WidthCounter>);
}

type ListProps = {
    getItemId: (item: any) => string;
    items: any[];
    renderItem: (item: any) => ReactNode;
    variant: "dropdown" | "leftToRight" | "rightToLeft";
    wrap: boolean;
};

function List({ getItemId, items, renderItem, variant, wrap }: ListProps) {
    const theme = useTheme();
    return (
        <Grid2
            container={true}
            spacing={1}
            {...(variant === "dropdown"
                ? {
                    direction: "column",
                    sx: {
                        padding: theme.spacing(1)
                    }
                }
                : {
                    alignItems: "center",
                    direction:
                        variant === "rightToLeft"
                            ? "row-reverse"
                            : "row",
                    wrap:
                        wrap
                            ? "wrap"
                            : "nowrap"
                })}>
            {_.map(
                items,
                item =>
                    <Grid2
                        key={getItemId(item)}
                        sx={{
                            width:
                                variant === "dropdown"
                                    ? "fit-content"
                                    : undefined
                        }}>
                        {renderItem(item)}
                    </Grid2>)}
        </Grid2>);
}