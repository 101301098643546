import { browserHistory, hashRouteFreezeContext, RouteType, useRouteContext } from "@infrastructure";
import _ from "lodash";
import qs from "qs";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppError, browserHashHistory, UnexpectedError } from "../utilities";

export function useQueryParameters<TQueryParameters>(): TQueryParameters {
    const { type } = useRouteContext();
    const [query, setQuery] =
        useState(
            () =>
                type === RouteType.Url
                    ? getUrlQueryParameters<TQueryParameters>()
                    : getHashQueryParameters<TQueryParameters>());
    useEffect(
        () => {
            const unregister =
                type === RouteType.Url
                    ? browserHistory.listen(
                        () => {
                            const queryParams = getUrlQueryParameters<TQueryParameters>();
                            if (!_.isEqual(query, queryParams)) {
                                setQuery(queryParams);
                            }
                        })
                    : browserHashHistory.listen(
                        () => {
                            const queryParams = getHashQueryParameters<TQueryParameters>();
                            if (!_.isEqual(query, queryParams)) {
                                setQuery(queryParams);
                            }
                        });

            return () => {
                unregister();
            };
        },
        [type, query]);

    const freeze = useContext(hashRouteFreezeContext);
    const [previousQuery, setPreviousQuery] = useState(query);
    if (!freeze &&
        !_.isEqual(previousQuery, query)) {
        setPreviousQuery(query);
    }

    return freeze
        ? previousQuery
        : query;
}

export function useClearQueryParameters() {
    const { type } = useRouteContext();
    return useMemo(
        () => {
            switch (type) {
                case RouteType.Hash:
                    return clearHashQueryParameters;
                case RouteType.Url:
                    return clearUrlQueryParameters;
                default:
                    throw new UnexpectedError("type", type);
            }
        },
        [type]);
}

export function clearHashQueryParameters() {
    browserHashHistory.replace({
        search: ""
    });
}

export function clearUrlQueryParameters() {
    browserHistory.replace({
        hash: window.location.hash,
        search: ""
    });
}

export function formatQueryParameters<TQueryParameters>(parameters: TQueryParameters): string {
    return qs.stringify(parameters);
}

export function getHashQueryParameters<TQueryParameters>(): TQueryParameters {
    return qs.parse(
        browserHashHistory.location.search,
        {
            ignoreQueryPrefix: true
        }) as any;
}

export function getUrlQueryParameters<TQueryParameters>(): TQueryParameters {
    return qs.parse(
        window.location.search,
        {
            ignoreQueryPrefix: true
        }) as any;
}

export function useRemoveQueryParameters() {
    const { type } = useRouteContext();
    return useMemo(
        () => {
            switch (type) {
                case RouteType.Hash:
                    return removeHashQueryParameters;
                case RouteType.Url:
                    return removeUrlQueryParameters;
                default:
                    throw new UnexpectedError("type", type);
            }
        },
        [type]);
}

export function removeUrlQueryParameters(key: string) {
    const parametersString =
        formatQueryParameters(
            _.omit(
                getUrlQueryParameters(),
                key));
    browserHistory.replace({
        hash: window.location.hash,
        search: parametersString
    });
}

export function removeHashQueryParameters(key: string) {
    const parametersString =
        formatQueryParameters(
            _.omit(
                getHashQueryParameters(),
                key));
    browserHashHistory.replace({
        search: parametersString
    });
}

export function useSetQueryParameters() {
    const { type } = useRouteContext();
    return useMemo(
        () => {
            switch (type) {
                case RouteType.Hash:
                    return setHashQueryParameters;
                case RouteType.Url:
                    return setUrlQueryParameters;
                default:
                    throw new UnexpectedError("type", type);
            }
        },
        [type]);
}

type SetUrlQueryParametersOptions = {
    appendBrowserHistory?: boolean;
    ignoreUrlMaxLength?: boolean;
};

export function setHashQueryParameters<TQueryParameters>(parameters: TQueryParameters, options?: SetUrlQueryParametersOptions) {
    options =
        _.merge(
            {
                appendBrowserHistory: true,
                ignoreUrlMaxLength: false
            },
            options);

    const parametersString =
        formatQueryParameters({
            ...getHashQueryParameters<any>(),
            ...parameters
        });

    const urlLength = window.location.origin.length + window.location.pathname.length + window.location.search.length + window.location.hash.length + parametersString.length;
    if (urlLength < MAX_URL_LENGTH) {
        if (options.appendBrowserHistory) {
            browserHashHistory.push({ search: parametersString });
        } else {
            browserHashHistory.replace({ search: parametersString });
        }
    } else if (!options.ignoreUrlMaxLength) {
        throw new AppError("URL length exceeds max length");
    }
}

export function setUrlQueryParameters<TQueryParameters>(parameters: TQueryParameters, options?: SetUrlQueryParametersOptions) {
    options =
        _.merge(
            {
                appendBrowserHistory: true,
                ignoreUrlMaxLength: false
            },
            options);

    const parametersString =
        formatQueryParameters({
            ...getUrlQueryParameters<any>(),
            ...parameters
        });

    const urlLength = window.location.origin.length + window.location.pathname.length + parametersString.length;
    if (urlLength < MAX_URL_LENGTH) {
        const newUrl = {
            hash: window.location.hash,
            pathname: window.location.pathname,
            search: parametersString
        };
        if (options.appendBrowserHistory) {
            browserHistory.push(newUrl);
        } else {
            browserHistory.replace(newUrl);
        }
    } else if (!options.ignoreUrlMaxLength) {
        throw new AppError("URL length exceeds max length");
    }
}

const MAX_URL_LENGTH = 2048;