import { ItemSelector, map, Message, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Radio, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, useTheme } from "../../../..";
import { NetworkAccessScope } from "../../../../../views/Customer/components/Entities/components";
import { InlineSourceIpAddressSelectionData } from "../InlineSourceIpAddressSelection";
import { Subnets } from "./components/Subnets";

type SourceIpAddressSelectorProps = {
    onSelectionChanged: (selection?: SourceIpAddressSelectorSelection) => void;
    selection?: SourceIpAddressSelectorSelection;
};

export function SourceIpAddressSelector({ onSelectionChanged, selection }: SourceIpAddressSelectorProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.sourceIpAddressSelector",
            () => ({
                fields: {
                    anyIpV4AddressSubnet: {
                        title: "Any IPv4 address"
                    },
                    exactSubnets: {
                        helpText: "Only addresses that exactly match one of the security group's inbound rules will trigger the policy. For example, if you enter 1.2.3.4/32, and the inbound rule has a range of 0.0.0.0/0 (all IPv4 addresses), the policy conditions will NOT be met.",
                        title: "By IPv4 address ranges (exact match)"
                    },
                    inboundAccessScope: {
                        placeholder: "Exposure",
                        title: "By exposure"
                    },
                    overlapSubnets: {
                        helpText: "Any address that is included in one of the security group's inbound rules will trigger the policy. For example, if you enter 1.2.3.4/32, and the inbound rule has a range of 0.0.0.0/0 (all IPv4 addresses), the policy conditions will be met.",
                        title: "By IPv4 address ranges (inclusive)"
                    }
                }
            }));

    const [anyIpV4AddressSubnetSelection, setAnyIpV4AddressSubnetSelection] = useState(() => new SourceIpAddressSelectorAnyIpV4AddressSubnetSelection());
    const [exactSubnetSelection, setExactSubnetSelection] =
        useState(
            () =>
                new SourceIpAddressSelectorExactSubnetSelection(
                    selection instanceof SourceIpAddressSelectorExactSubnetSelection
                        ? selection.subnetItems
                        : []));
    const [inboundAccessScopeSelection, setInboundAccessScopeSelection] =
        useState(
            () =>
                new SourceIpAddressSelectorInboundAccessScopeSelection(
                    selection instanceof SourceIpAddressSelectorInboundAccessScopeSelection
                        ? selection.inboundAccessScope
                        : undefined));
    const [overlapSubnetSelection, setOverlapSubnetSelection] =
        useState(
            () =>
                new SourceIpAddressSelectorOverlapSubnetSelection(
                    selection instanceof SourceIpAddressSelectorOverlapSubnetSelection
                        ? selection.subnetItems
                        : []));
    const [type, setType] =
        useState(
            _.isNil(selection)
                ? undefined
                : selection instanceof SourceIpAddressSelectorAnyIpV4AddressSubnetSelection
                    ? SourceIpAddressSelectorType.AnyIpV4AddressSubnet
                    : selection instanceof SourceIpAddressSelectorInboundAccessScopeSelection
                        ? SourceIpAddressSelectorType.Exposure
                        : selection instanceof SourceIpAddressSelectorExactSubnetSelection
                            ? SourceIpAddressSelectorType.ExactSubnets
                            : SourceIpAddressSelectorType.OverlapSubnets);

    useChangeEffect(
        () => {
            onSelectionChanged(
                map<string, SourceIpAddressSelectorSelection | undefined>(
                    type,
                    {
                        [SourceIpAddressSelectorType.AnyIpV4AddressSubnet]: () => anyIpV4AddressSubnetSelection,
                        [SourceIpAddressSelectorType.ExactSubnets]: () => exactSubnetSelection,
                        [SourceIpAddressSelectorType.Exposure]: () => inboundAccessScopeSelection,
                        [SourceIpAddressSelectorType.OverlapSubnets]: () => overlapSubnetSelection
                    },
                    () => undefined));
        },
        [anyIpV4AddressSubnetSelection, exactSubnetSelection, inboundAccessScopeSelection, overlapSubnetSelection, type]);

    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ maxWidth: theme.spacing(50) }}>
                <Radio
                    checked={type === SourceIpAddressSelectorType.AnyIpV4AddressSubnet}
                    size="small"
                    onChange={() => {
                        setType(SourceIpAddressSelectorType.AnyIpV4AddressSubnet);
                        setAnyIpV4AddressSubnetSelection(new SourceIpAddressSelectorAnyIpV4AddressSubnetSelection());
                    }}/>
                {localization.fields.anyIpV4AddressSubnet.title()}
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ maxWidth: theme.spacing(50) }}>
                <Radio
                    checked={type === SourceIpAddressSelectorType.Exposure}
                    size="small"
                    onChange={() => setType(SourceIpAddressSelectorType.Exposure)}/>
                {localization.fields.inboundAccessScope.title()}
                <Box sx={{ flex: 1 }}>
                    <ItemSelector
                        disabled={type !== SourceIpAddressSelectorType.Exposure}
                        fieldSx={{ maxHeight: theme.spacing(6) }}
                        fullWidth={true}
                        items={[
                            Contract.NetworkAccessScope.All,
                            Contract.NetworkAccessScope.Wide
                        ]}
                        placeholder={localization.fields.inboundAccessScope.placeholder()}
                        selectedItem={inboundAccessScopeSelection?.inboundAccessScope}
                        sorted={false}
                        onSelectedItemChanged={inboundAccessScope => setInboundAccessScopeSelection(new SourceIpAddressSelectorInboundAccessScopeSelection(inboundAccessScope))}>
                        {item => <NetworkAccessScope accessScope={item} copyToClipboard={false}/>}
                    </ItemSelector>
                </Box>
            </Stack>
            <Stack justifyContent="flex-start">
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Radio
                        checked={type === SourceIpAddressSelectorType.OverlapSubnets}
                        size="small"
                        onChange={() => setType(SourceIpAddressSelectorType.OverlapSubnets)}/>
                    {localization.fields.overlapSubnets.title()}
                    <Message
                        level="info"
                        title={
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                {localization.fields.overlapSubnets.helpText()}
                            </Typography>}
                        variant="minimal"/>
                </Stack>
                <Box sx={{ paddingLeft: theme.spacing(2) }}>
                    <Subnets
                        disabled={type !== SourceIpAddressSelectorType.OverlapSubnets}
                        existingSubnetItems={overlapSubnetSelection.subnetItems}
                        onSubnetItemsChanged={subnetItems => setOverlapSubnetSelection(new SourceIpAddressSelectorOverlapSubnetSelection(subnetItems))}/>
                </Box>
            </Stack>
            <Stack justifyContent="flex-start">
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Radio
                        checked={type === SourceIpAddressSelectorType.ExactSubnets}
                        size="small"
                        onChange={() => setType(SourceIpAddressSelectorType.ExactSubnets)}/>
                    {localization.fields.exactSubnets.title()}
                    <Message
                        level="info"
                        title={
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                {localization.fields.exactSubnets.helpText()}
                            </Typography>}
                        variant="minimal"/>
                </Stack>
                <Box sx={{ marginLeft: theme.spacing(2) }}>
                    <Subnets
                        disabled={type !== SourceIpAddressSelectorType.ExactSubnets}
                        existingSubnetItems={exactSubnetSelection.subnetItems}
                        onSubnetItemsChanged={subnetItems => setExactSubnetSelection(new SourceIpAddressSelectorExactSubnetSelection(subnetItems))}/>
                </Box>
            </Stack>
        </Stack>);
}

enum SourceIpAddressSelectorType {
    AnyIpV4AddressSubnet = "anyIpV4AddressSubnet",
    ExactSubnets = "exactSubnets",
    Exposure = "exposure",
    OverlapSubnets = "overlapSubnets"
}

export class SourceIpAddressSelectorSelection {
    public getInlineSelectionData(): InlineSourceIpAddressSelectionData {
        return {};
    }

    public valid() {
        return true;
    }
}

export class SourceIpAddressSelectorAnyIpV4AddressSubnetSelection extends SourceIpAddressSelectorSelection {
    constructor() {
        super();
    }

    public getInlineSelectionData(): InlineSourceIpAddressSelectionData {
        return { anyIpV4AddressSubnet: true };
    }

    public valid() {
        return true;
    }
}

export class SourceIpAddressSelectorInboundAccessScopeSelection extends SourceIpAddressSelectorSelection {
    constructor(public inboundAccessScope?: Contract.NetworkAccessScope) {
        super();
    }


    public getInlineSelectionData(): InlineSourceIpAddressSelectionData {
        return { inboundAccessScope: this.inboundAccessScope };
    }

    public valid() {
        return !_.isNil(this.inboundAccessScope);
    }
}

export class SourceIpAddressSelectorSubnetSelection extends SourceIpAddressSelectorSelection {
    constructor(public subnetItems: SubnetItem[]) {
        super();
    }

    public getSubnets() {
        return _(this.subnetItems).
            filter(subnetItem => subnetItem.valid).
            map(subnetItem => subnetItem.subnet).
            value();
    }

    public static getSubnetItems(subnets: string[]) {
        return _.map(
            subnets,
            subnet =>
                new SubnetItem(
                    true,
                    subnet));
    }

    public valid() {
        return (
            !_.isEmpty(this.subnetItems) &&
            _.every(
                this.subnetItems,
                subnetItem => subnetItem.valid));
    }
}

export class SourceIpAddressSelectorExactSubnetSelection extends SourceIpAddressSelectorSubnetSelection {
    constructor(public subnetItems: SubnetItem[]) {
        super(subnetItems);
    }

    public static fromSubnets(subnets: string[]) {
        return new SourceIpAddressSelectorExactSubnetSelection(this.getSubnetItems(subnets));
    }

    public getInlineSelectionData(): InlineSourceIpAddressSelectionData {
        return { exactSubnets: this.getSubnets() };
    }
}

export class SourceIpAddressSelectorOverlapSubnetSelection extends SourceIpAddressSelectorSubnetSelection {
    constructor(public subnetItems: SubnetItem[]) {
        super(subnetItems);
    }

    public static fromSubnets(subnets: string[]) {
        return new SourceIpAddressSelectorOverlapSubnetSelection(this.getSubnetItems(subnets));
    }

    public getInlineSelectionData(): InlineSourceIpAddressSelectionData {
        return { overlapSubnets: this.getSubnets() };
    }
}

export class SubnetItem {
    private static _idCounter = 1;
    public id: number;

    constructor(
        public valid: boolean,
        public subnet: string,
        id?: number) {
        this.id = id ?? SubnetItem._idCounter++;
    }
}