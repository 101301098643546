import { useMemo } from "react";
import { usePingIdentityDefaultDirectoryEntityDefinition, usePingIdentityDirectoryGroupDefinition, usePingIdentityDirectoryUserDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function usePingIdentityDirectoryEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.PingIdentityDirectoryGroup:
                        return usePingIdentityDirectoryGroupDefinition;
                    case Contract.TypeNames.PingIdentityDirectoryUser:
                        return usePingIdentityDirectoryUserDefinition;
                    default:
                        return usePingIdentityDefaultDirectoryEntityDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}