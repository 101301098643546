import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, map, optionalTableCell, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, EntityFilter, entityModelStore, ItemTable } from "../../../../../../../../../common";
import { useAadDirectoryDirectoryRoleAssignmentResourceScopeInfoTranslator } from "../../../../../hooks";

type AadDirectoryDirectoryRoleAssignmentResourcesProps = {
    csvExportFilePrefixes: string[];
    directoryRoleAssignmentResourceIds: string[];
    principalId?: string;
    variant: "principalAndScopeAndRoleManagementPolicyAssignment" | "roleAndScopeAndPrincipal" | "roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment";
};

export function AadDirectoryDirectoryRoleAssignmentResources({ csvExportFilePrefixes, directoryRoleAssignmentResourceIds, principalId, variant }: AadDirectoryDirectoryRoleAssignmentResourcesProps) {
    const directoryRoleAssignmentResourceModels = entityModelStore.useGet(directoryRoleAssignmentResourceIds);
    const directoryRoleAssignmentResourceRelatedEntityModels =
        entityModelStore.useGet(
            _(directoryRoleAssignmentResourceModels).
                flatMap(
                    directoryRoleAssignmentResourceModel => {
                        const directoryRoleAssignmentResource = _.as<Contract.AadDirectoryDirectoryRoleAssignmentResource>(directoryRoleAssignmentResourceModel.entity);
                        return [
                            directoryRoleAssignmentResourceModel.typeName === Contract.TypeNames.AadDirectoryDirectoryRoleEligibilityScheduleModel
                                ? _.as<Contract.AadDirectoryDirectoryRoleEligibilityScheduleModel>(directoryRoleAssignmentResourceModel).directoryRoleManagementPolicyAssignmentId
                                : undefined,
                            directoryRoleAssignmentResource.principalId,
                            directoryRoleAssignmentResource.roleDefinitionId
                        ];
                    }).
                filter().
                as<string>().
                value());
    const directoryRoleAssignmentResourceScopeInfoTranslator = useAadDirectoryDirectoryRoleAssignmentResourceScopeInfoTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.aadDirectoryDirectoryRoleAssignmentResources",
            () => ({
                columns: {
                    principalId: {
                        this: "This Principal",
                        title: "Principal"
                    },
                    roleDefinitionId: "Role",
                    roleManagementPolicyAssignment: "PIM Role Settings",
                    scopeInfo: "Scope",
                    severePermission: {
                        false: "No",
                        title: "Privileged",
                        true: "Yes"
                    },
                    status: {
                        [Contract.AadDirectoryDirectoryRoleAssignmentResourceStatus.Active]: "Active",
                        [Contract.AadDirectoryDirectoryRoleAssignmentResourceStatus.Eligible]: "Eligible",
                        title: "Status"
                    }
                },
                empty: "No Assignments"
            }));

    const [directoryRoleAssignmentResourceRelatedEntityModelMap, directoryRoleAssignmentResources, directoryRoleAssignmentResourceDirectoryRoleManagementPolicyAssignmentExists, directoryRoleEligibilityScheduleIdToRoleManagementPolicyAssignmentModelMap] =
        useMemo(
            () => {
                const directoryRoleAssignmentResources =
                    _.map(
                        directoryRoleAssignmentResourceModels,
                        directoryRoleAssignmentResourceModel => directoryRoleAssignmentResourceModel.entity as Contract.AadDirectoryDirectoryRoleAssignmentResource);

                const directoryRoleAssignmentResourceRelatedEntityModelMap =
                    _.keyBy(
                        directoryRoleAssignmentResourceRelatedEntityModels,
                        directoryRoleAssignmentResourceRelatedEntityModel => directoryRoleAssignmentResourceRelatedEntityModel.id);

                const directoryRoleAssignmentResourceDirectoryRoleManagementPolicyAssignmentExists =
                    _.some(
                        directoryRoleAssignmentResourceModels,
                        roleAssignmentResourceModel =>
                            roleAssignmentResourceModel.typeName === Contract.TypeNames.AadDirectoryDirectoryRoleEligibilityScheduleModel &&
                            !_.isNil((roleAssignmentResourceModel as Contract.AadDirectoryDirectoryRoleEligibilityScheduleModel).directoryRoleManagementPolicyAssignmentId));

                const directoryRoleEligibilityScheduleIdToRoleManagementPolicyAssignmentModelMap =
                    _(directoryRoleAssignmentResourceModels).
                        filter(directoryRoleAssignmentResourceModel => directoryRoleAssignmentResourceModel.typeName === Contract.TypeNames.AadDirectoryDirectoryRoleEligibilityScheduleModel).
                        keyBy(directoryRoleEligibilityScheduleModel => directoryRoleEligibilityScheduleModel.id).
                        mapValues(
                            directoryRoleEligibilityScheduleModel => {
                                const directoryRoleManagementPolicyAssignmentId = _.as<Contract.AadDirectoryDirectoryRoleEligibilityScheduleModel>(directoryRoleEligibilityScheduleModel).directoryRoleManagementPolicyAssignmentId;
                                return _.isNil(directoryRoleManagementPolicyAssignmentId)
                                    ? undefined
                                    : directoryRoleAssignmentResourceRelatedEntityModelMap[directoryRoleManagementPolicyAssignmentId];
                            }).
                        value();

                return [directoryRoleAssignmentResourceRelatedEntityModelMap, directoryRoleAssignmentResources, directoryRoleAssignmentResourceDirectoryRoleManagementPolicyAssignmentExists, directoryRoleEligibilityScheduleIdToRoleManagementPolicyAssignmentModelMap];
            },
            [directoryRoleAssignmentResourceRelatedEntityModels, directoryRoleAssignmentResourceModels]);

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [AadDirectoryDirectoryRoleAssignmentResourcesColumnId.DirectoryRoleDefinitionId]: {
                    getFilterValue: directoryRoleAssignmentResource => directoryRoleAssignmentResource.roleDefinitionId,
                    getSortValue: directoryRoleAssignmentResource => directoryRoleAssignmentResourceRelatedEntityModelMap[directoryRoleAssignmentResource.roleDefinitionId].entity.displayName
                },
                [AadDirectoryDirectoryRoleAssignmentResourcesColumnId.DirectoryRoleManagementPolicyAssignment]: directoryRoleAssignmentResource => directoryRoleEligibilityScheduleIdToRoleManagementPolicyAssignmentModelMap[directoryRoleAssignmentResource.id]?.entity.displayName,
                [AadDirectoryDirectoryRoleAssignmentResourcesColumnId.PrincipalId]: {
                    getFilterValue: directoryRoleAssignmentResource => directoryRoleAssignmentResource.principalId,
                    getSortValue:
                        directoryRoleAssignmentResource =>
                            directoryRoleAssignmentResource.principalId === principalId
                                ? ""
                                : directoryRoleAssignmentResourceRelatedEntityModelMap[directoryRoleAssignmentResource.principalId].entity.displayName
                },
                [AadDirectoryDirectoryRoleAssignmentResourcesColumnId.ScopeInfo]: directoryRoleAssignmentResource => directoryRoleAssignmentResourceScopeInfoTranslator(directoryRoleAssignmentResource.scopeInfo),
                [AadDirectoryDirectoryRoleAssignmentResourcesColumnId.SeverePermission]: directoryRoleAssignmentResource =>
                    (directoryRoleAssignmentResourceRelatedEntityModelMap[directoryRoleAssignmentResource.roleDefinitionId] as Contract.AadDirectoryDirectoryRoleDefinitionModel).severePermission
                        ? localization.columns.severePermission.true()
                        : localization.columns.severePermission.false(),
                [AadDirectoryDirectoryRoleAssignmentResourcesColumnId.Status]: directoryRoleAssignmentResource => directoryRoleAssignmentResource.status
            }}
            csvExportFilePrefixes={csvExportFilePrefixes}
            defaultSortColumnIdOrIds={
                variant === "roleAndScopeAndPrincipal" || variant === "roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment"
                    ? AadDirectoryDirectoryRoleAssignmentResourcesColumnId.DirectoryRoleDefinitionId
                    : AadDirectoryDirectoryRoleAssignmentResourcesColumnId.PrincipalId}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => {
                    const directoryRoleManagementPolicyAssignment =
                        !_.isNil(directoryRoleEligibilityScheduleIdToRoleManagementPolicyAssignmentModelMap[item.id])
                            ? directoryRoleEligibilityScheduleIdToRoleManagementPolicyAssignmentModelMap[item.id]!.entity.displayReference
                            : "-";
                    const severePermission =
                        (directoryRoleAssignmentResourceRelatedEntityModelMap[item.roleDefinitionId] as Contract.AadDirectoryDirectoryRoleDefinitionModel).severePermission
                            ? localization.columns.severePermission.true()
                            : localization.columns.severePermission.false();
                    return map<string, any>(
                        variant,
                        {
                            "principalAndScopeAndRoleManagementPolicyAssignment": () => ({
                                /* eslint-disable sort-keys-fix/sort-keys-fix */
                                "Principal": directoryRoleAssignmentResourceRelatedEntityModelMap[item.principalId].entity.displayReference,
                                "Scope": directoryRoleAssignmentResourceScopeInfoTranslator(item.scopeInfo),
                                "Status": localization.columns.status[item.status](),
                                "PIM Role Settings": directoryRoleManagementPolicyAssignment,
                                "Privileged": severePermission
                                /* eslint-enable sort-keys-fix/sort-keys-fix */
                            }),
                            "roleAndScopeAndPrincipal": () => ({
                                /* eslint-disable sort-keys-fix/sort-keys-fix */
                                "Role": directoryRoleAssignmentResourceRelatedEntityModelMap[item.roleDefinitionId].entity.displayReference,
                                "Scope": directoryRoleAssignmentResourceScopeInfoTranslator(item.scopeInfo),
                                "Principal": directoryRoleAssignmentResourceRelatedEntityModelMap[item.principalId].entity.displayReference,
                                "Status": localization.columns.status[item.status](),
                                "Privileged": severePermission
                                /* eslint-enable sort-keys-fix/sort-keys-fix */
                            }),
                            "roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment": () => ({
                                /* eslint-disable sort-keys-fix/sort-keys-fix */
                                "Role": directoryRoleAssignmentResourceRelatedEntityModelMap[item.roleDefinitionId].entity.displayReference,
                                "Scope": directoryRoleAssignmentResourceScopeInfoTranslator(item.scopeInfo),
                                "Principal": directoryRoleAssignmentResourceRelatedEntityModelMap[item.principalId].entity.displayReference,
                                "Status": localization.columns.status[item.status](),
                                "PIM Role Settings": directoryRoleManagementPolicyAssignment,
                                "Privileged": severePermission
                                /* eslint-enable sort-keys-fix/sort-keys-fix */
                            })
                        });
                }}
            getItemId={item => item.id}
            items={directoryRoleAssignmentResources}>
            {columnIdToItemValuesMap => {
                const principalColumnElement =
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[AadDirectoryDirectoryRoleAssignmentResourcesColumnId.PrincipalId]}
                                        placeholder={localization.columns.principalId.title()}/>
                            }
                        }}
                        id={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.PrincipalId}
                        key={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.PrincipalId}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryDirectoryRoleAssignmentResource>) =>
                                item.principalId === principalId
                                    ? <Typography noWrap={true}>{localization.columns.principalId.this()}</Typography>
                                    : <EntitiesCell
                                        entityIdsOrModels={item.principalId}
                                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                        entityVariant="iconTextTypeTenant"/>}
                        title={localization.columns.principalId.title()}/>;
                const roleColumnElement =
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[AadDirectoryDirectoryRoleAssignmentResourcesColumnId.DirectoryRoleDefinitionId]}
                                        placeholder={localization.columns.roleDefinitionId()}/>
                            }
                        }}
                        id={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.DirectoryRoleDefinitionId}
                        key={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.DirectoryRoleDefinitionId}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryDirectoryRoleAssignmentResource>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.roleDefinitionId}
                                    entityTypeName={Contract.TypeNames.AadDirectoryDirectoryRoleDefinition}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.columns.roleDefinitionId()}/>;
                const scopeInfoColumnElement =
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.scopeInfo()}
                                        values={columnIdToItemValuesMap[AadDirectoryDirectoryRoleAssignmentResourcesColumnId.ScopeInfo]}/>
                            }
                        }}
                        id={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.ScopeInfo}
                        itemProperty={(item: Contract.AadDirectoryDirectoryRoleAssignmentResource) => directoryRoleAssignmentResourceScopeInfoTranslator(item.scopeInfo)}
                        key={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.ScopeInfo}
                        title={localization.columns.scopeInfo()}/>;
                const statusColumnElement =
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter placeholder={localization.columns.status.title()}>
                                        {_.map(
                                            columnIdToItemValuesMap[AadDirectoryDirectoryRoleAssignmentResourcesColumnId.Status],
                                            status =>
                                                <ValuesFilterItem
                                                    key={status}
                                                    title={localization.columns.status[status as Contract.AadDirectoryDirectoryRoleAssignmentResourceStatus]()}
                                                    value={status}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.Status}
                        itemProperty={(item: Contract.AadDirectoryDirectoryRoleAssignmentResource) => localization.columns.status[item.status]()}
                        key={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.Status}
                        title={localization.columns.status.title()}/>;
                const roleManagementAssignmentPolicyElement =
                    directoryRoleAssignmentResourceDirectoryRoleManagementPolicyAssignmentExists
                        ? <DataTableColumn
                            id={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.DirectoryRoleManagementPolicyAssignment}
                            render={
                                optionalTableCell<Contract.AadDirectoryDirectoryRoleAssignmentResourceModel>(
                                    item =>
                                        item.typeName === Contract.TypeNames.AadDirectoryDirectoryRoleEligibilitySchedule
                                            ? <EntitiesCell
                                                entityIdsOrModels={directoryRoleEligibilityScheduleIdToRoleManagementPolicyAssignmentModelMap[item.id]}
                                                entityTypeName={Contract.TypeNames.AadDirectoryDirectoryRoleManagementPolicyAssignment}
                                                entityVariant="iconTextTypeTenant"/>
                                            : undefined
                                )}
                            title={localization.columns.roleManagementPolicyAssignment()}/>
                        : undefined;
                const severePermissionColumnElement =
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.severePermission.title()}
                                        values={columnIdToItemValuesMap[AadDirectoryDirectoryRoleAssignmentResourcesColumnId.SeverePermission]}/>
                            }
                        }}
                        id={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.SeverePermission}
                        itemProperty={(item: Contract.AadDirectoryDirectoryRoleAssignmentResource) =>
                            (directoryRoleAssignmentResourceRelatedEntityModelMap[item.roleDefinitionId] as Contract.AadDirectoryDirectoryRoleDefinitionModel).severePermission
                                ? localization.columns.severePermission.true()
                                : localization.columns.severePermission.false()
                        }
                        key={AadDirectoryDirectoryRoleAssignmentResourcesColumnId.SeverePermission}
                        title={localization.columns.severePermission.title()}/>;

                return [
                    ...map(
                        variant,
                        {
                            "principalAndScopeAndRoleManagementPolicyAssignment": () => [
                                principalColumnElement,
                                scopeInfoColumnElement,
                                roleManagementAssignmentPolicyElement
                            ],
                            "roleAndScopeAndPrincipal": () => [
                                roleColumnElement,
                                scopeInfoColumnElement,
                                principalColumnElement
                            ],
                            "roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment": () => [
                                roleColumnElement,
                                scopeInfoColumnElement,
                                principalColumnElement,
                                roleManagementAssignmentPolicyElement
                            ]
                        }),
                    statusColumnElement,
                    severePermissionColumnElement
                ];
            }}
        </ItemTable>);
}

enum AadDirectoryDirectoryRoleAssignmentResourcesColumnId {
    DirectoryRoleDefinitionId = "roleDefinitionId",
    DirectoryRoleManagementPolicyAssignment = "directoryRoleManagementPolicyAssignment",
    PrincipalId = "principalId",
    ScopeInfo = "scopeInfo",
    SeverePermission = "severePermission",
    Status = "status"
}