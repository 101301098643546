
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PermissionManagementIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M16.0002 22.6999C15.0002 22.6999 14.2002 22.6999 13.3002 22.6999C11.8002 22.6999 10.6002 21.4999 10.4002 19.9999C10.4002 19.3999 10.2002 18.6999 10.2002 17.8999C10.2002 17.0999 10.2002 16.3999 10.4002 15.7999C10.6002 14.2999 11.8002 13.1999 13.3002 13.0999C14.2002 13.0999 15.1002 13.0999 16.0002 13.0999C16.9002 13.0999 17.7002 13.0999 18.6002 13.0999C20.1002 13.0999 21.3002 14.2999 21.5002 15.7999C21.5002 16.3999 21.7002 17.0999 21.7002 17.8999C21.7002 18.6999 21.7002 19.3999 21.5002 19.9999C21.3002 21.4999 20.1002 22.5999 18.6002 22.6999C17.8002 22.6999 16.9002 22.6999 15.9002 22.6999H16.0002ZM16.1002 14.9999C15.2002 14.9999 14.3002 14.9999 13.5002 14.9999C13.0002 14.9999 12.4002 15.3999 12.3002 15.8999C12.3002 16.3999 12.2002 17.0999 12.2002 17.6999C12.2002 18.2999 12.2002 18.9999 12.3002 19.4999C12.3002 19.9999 12.8002 20.6562 13.3002 20.6562C14.9002 20.6562 16.8002 20.6562 18.4002 20.6562C18.9002 20.6562 19.8002 20.1999 19.9002 19.6999C19.9002 19.1999 20.0002 18.4999 20.0002 17.8999C20.0002 17.2999 20.0002 16.5999 19.9002 16.0999C19.9002 15.5999 19.1002 14.9999 18.6002 14.9999C17.8002 14.9999 16.9002 14.9999 16.1002 14.9999Z"/>
            <path d="M18.7002 14.9999C18.1002 14.9999 17.7002 14.5999 17.7002 13.9999V12.4999C17.7002 11.5999 17.0002 10.7999 16.0002 10.7999C15.0002 10.7999 14.3002 11.4999 14.3002 12.4999V13.9999C14.3002 14.5999 13.9002 14.9999 13.3002 14.9999C12.7002 14.9999 12.3002 14.5999 12.3002 13.9999V12.4999C12.3002 10.4999 13.9002 8.79993 16.0002 8.79993C18.1002 8.79993 19.7002 10.3999 19.7002 12.4999V13.9999C19.7002 14.5999 19.3002 14.9999 18.7002 14.9999Z"/>
            <path
                clipRule="evenodd"
                d="M10.2111 1.77642C11.3373 1.21336 12.6627 1.21336 13.7889 1.77642L19.7889 4.77642C21.144 5.45399 22 6.83904 22 8.35413V8.90002C22 9.45231 21.5523 9.90003 21 9.90003C20.4477 9.90003 20 9.45231 20 8.90002V8.35413C20 7.59658 19.572 6.90406 18.8944 6.56527L12.8944 3.56527L13.3416 2.67084L12.8944 3.56527C12.3314 3.28374 11.6686 3.28374 11.1056 3.56527L5.10557 6.56527C4.42801 6.90406 4 7.59658 4 8.35413V11.9C4 14.9286 5.84079 18.324 8.41616 20.5374C8.83501 20.8974 8.88274 21.5288 8.52276 21.9476C8.16278 22.3665 7.53141 22.4142 7.11257 22.0542C4.21084 19.5603 2 15.6485 2 11.9V8.35413C2 6.83904 2.85601 5.45399 4.21115 4.77642L10.2111 1.77642Z"
                fillRule="evenodd"/>

        </SvgIcon>);
}