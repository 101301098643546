import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureKeyVaultObjectRiskContext } from "./useGetAzureKeyVaultObjectRiskContext";

export function useGetAzureKeyVaultKeyRiskContext() {
    return useMemo(
        () => useAzureKeyVaultKeyRiskContext,
        []);
}

function useAzureKeyVaultKeyRiskContext(keyModel: Contract.AzureKeyVaultVaultKeyModel) {
    const key = keyModel.entity as Contract.AzureKeyVaultVaultKey;
    const objectRiskContext = useGetAzureKeyVaultObjectRiskContext()(keyModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureKeyVaultKeyRiskContext",
            () => ({
                expirationDisabledVersions: {
                    title: "The {{translatedKeyTypeName}} has **{{expirationDisabledVersions}}** with no expiration date set",
                    version: [
                        "1 enabled version",
                        "{{count | NumberFormatter.humanize}} enabled versions"
                    ]
                },
                rotationDisabled: "The {{translatedKeyTypeName}} automatic rotation is not enabled",
                rotationPolicy: "The {{translatedKeyTypeName}} has a configured rotation policy with no expiration date set",
                rotationTime: {
                    empty: "The {{translatedKeyTypeName}} rotation period is not set",
                    text: "The {{translatedKeyTypeName}}'s rotation period is currently set to {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                }
            }));
    const translatedKeyTypeName =
        entityTypeNameTranslator(
            key.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...objectRiskContext,
        expirationDisabledVersions:
            new RiskDefinitionContextItem(
                localization.expirationDisabledVersions.title({
                    expirationDisabledVersions:
                        <InlineItems
                            items={
                                _.orderBy(
                                    key.expirationDisabledVersionNames,
                                    versionName => versionName === key.name,
                                    "desc")}
                            namePluralizer={localization.expirationDisabledVersions.version}
                            variant="itemCountAndType"/>,
                    translatedKeyTypeName
                })),
        rotationDisabled:
            key.rotationPolicy?.rotationEnabled
                ? undefined
                : new RiskDefinitionContextItem(localization.rotationDisabled({ translatedKeyTypeName })),
        rotationPolicy:
            key.rotationPolicy?.rotationEnabled &&
            _.isNil(key.rotationPolicy.keyExpirationTime)
                ? new RiskDefinitionContextItem(localization.rotationPolicy({ translatedKeyTypeName }))
                : undefined,
        rotationTime:
            new RiskDefinitionContextItem(
                _.isNil(key.rotationPolicy?.rotationTimeFrame)
                    ? localization.rotationTime.empty({ translatedKeyTypeName })
                    : localization.rotationTime.text({
                        rotationTimeFrame: key.rotationPolicy?.rotationTimeFrame,
                        translatedKeyTypeName
                    }))
    };
}