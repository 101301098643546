import { Link, useLocalization, useOrderedWizardContext, VerticalTabView } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { CustomerConsoleAppUrlHelper, useTheme } from "../../../../../../../../../../../../../../../../../common";
import { SyncOptionType, useAddOrEditProjectContext, useSetAddOrEditProjectContext } from "../../../AddOrEditProject";
import { IssueTypeItem } from "./IssueTypeItem";
import { StatusesMapTable } from "./StatusesMapTable";

type CustomViewProps = {
    issueTypeToSyncOptionTypeMap: _.Dictionary<SyncOptionType>;
    onIssueTypeSyncOptionChange: (selectedIssueType: string, syncOption: SyncOptionType) => void;
};

export function CustomView({ issueTypeToSyncOptionTypeMap, onIssueTypeSyncOptionChange }: CustomViewProps) {
    const { issueTypeNameToRiskStatusToJiraStatusErrorMap, issueTypeNameToRiskStatusToJiraStatusMap, issueTypeNameToStatusesMap } = useAddOrEditProjectContext();
    const setAddOrEditProjectContext = useSetAddOrEditProjectContext();
    const { executing } = useOrderedWizardContext();

    const sortedIssueTypeNames =
        useMemo(
            () =>
                _(issueTypeNameToRiskStatusToJiraStatusMap).
                    keys().
                    sortBy(
                        issueTypeName => [
                            _.isNil(issueTypeNameToRiskStatusToJiraStatusErrorMap?.[issueTypeName]),
                            issueTypeName]).
                    value(),
            [issueTypeNameToRiskStatusToJiraStatusMap, issueTypeNameToRiskStatusToJiraStatusErrorMap]);

    const [selectedIssueType, setSelectedIssueType] = useState<string>(_.first(sortedIssueTypeNames)!);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEditProject.syncIssueStatusesItem.customView",
            () => ({
                issueTypeTitle: "Jira Issue Type",
                learnMore: "Learn more",
                permissionNote: "This feature requires additional permissions and configuration in Jira. {{learnMoreLink}}"
            }));

    const theme = useTheme();
    return (
        <Stack>
            <Box
                sx={{
                    backgroundColor: theme.palette.input.filledBackground,
                    borderRadius: 2,
                    display: "flex",
                    marginBottom: theme.spacing(2),
                    padding: theme.spacing(1.5)
                }}>
                <Typography
                    sx={{
                        alignItems: "center",
                        display: "flex"
                    }}>
                    {localization.permissionNote({
                        learnMoreLink:
                            <Link
                                sx={{ marginLeft: theme.spacing(1) }}
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsTicketingIntegrationAllowJiraIssueTransitions()}
                                variant="external">
                                {localization.learnMore()}
                            </Link>
                    })}
                </Typography>
            </Box>
            <Box
                sx={{
                    border: theme.border.primary,
                    borderRadius: 2,
                    height: theme.spacing(50)
                }}>
                <VerticalTabView
                    collapseEnabled={false}
                    items={
                        _.map(
                            sortedIssueTypeNames,
                            issueTypeName => ({
                                title:
                                    <IssueTypeItem
                                        issueTypeName={issueTypeName}
                                        syncOptionType={issueTypeToSyncOptionTypeMap[issueTypeName]}/>,
                                view: issueTypeName
                            }))
                    }
                    selectedView={selectedIssueType}
                    tabsProps={{
                        scrollButtons: false,
                        visibleScrollbar: true
                    }}
                    tabsSx={{
                        borderRight: "none",
                        maxWidth: theme.spacing(19),
                        paddingBottom: theme.spacing(2)
                    }}
                    tabsTitle={
                        <Box
                            sx={{
                                borderBottom: theme.border.primary,
                                fontWeight: 600,
                                margin: theme.spacing(1),
                                padding: theme.spacing(1, 2),
                                width: "90%"
                            }}>
                            {localization.issueTypeTitle()}
                        </Box>}
                    onSelectedViewChanged={selectedView => setSelectedIssueType(selectedView)}>
                    <StatusesMapTable
                        disabled={executing}
                        issueTypeDeleted={_.isNil(issueTypeNameToStatusesMap[selectedIssueType])}
                        key={selectedIssueType}
                        riskStatusToJiraStatusErrorMap={issueTypeNameToRiskStatusToJiraStatusErrorMap?.[selectedIssueType]}
                        riskStatusToJiraStatusMap={issueTypeNameToRiskStatusToJiraStatusMap![selectedIssueType]}
                        selectedSyncOption={issueTypeToSyncOptionTypeMap[selectedIssueType]}
                        statusesMap={issueTypeNameToStatusesMap[selectedIssueType]}
                        onStatusChange={
                            (findingStatus, jiraStatus) =>
                                setAddOrEditProjectContext(
                                    context => ({
                                        ...context,
                                        issueTypeNameToRiskStatusToJiraStatusErrorMap:
                                            _.omitBy({
                                                ...context.issueTypeNameToRiskStatusToJiraStatusErrorMap,
                                                [selectedIssueType]: _.omit(context.issueTypeNameToRiskStatusToJiraStatusErrorMap?.[selectedIssueType], findingStatus)
                                            },
                                            _.isEmpty),
                                        issueTypeNameToRiskStatusToJiraStatusMap: {
                                            ...context.issueTypeNameToRiskStatusToJiraStatusMap,
                                            [selectedIssueType]: {
                                                ...(context.issueTypeNameToRiskStatusToJiraStatusMap?.[selectedIssueType] || {}),
                                                [findingStatus]: jiraStatus
                                            }
                                        }
                                    }))}
                        onSyncOptionTypeChange={syncOption => onIssueTypeSyncOptionChange(selectedIssueType, syncOption)}/>
                </VerticalTabView>
            </Box>
        </Stack>);
}