import { Optional, TimeHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../../../common";
import { AzureAccessPrincipalRiskDefinition } from "../useDefinition";

export function useAadDirectoryApplicationServicePrincipalDefinition(riskModel: Contract.AzureAccessPrincipalRiskModel) {
    const applicationServicePrincipalModel = entityModelStore.useGet(riskModel.risk.entityId);
    const applicationServicePrincipal = applicationServicePrincipalModel.entity as Contract.AadDirectoryApplicationServicePrincipal;
    const applicationModel = entityModelStore.useGet(riskModel.risk.servicePrincipalApplicationId) as Optional<Contract.AadDirectoryApplicationModel>;
    const applicationCertificateModels = entityModelStore.useGet(applicationModel?.certificateIds);
    const applicationSecretModels = entityModelStore.useGet(applicationModel?.secretIds);

    const applicationActiveCertificateModels =
        useMemo(
            () =>
                _.filter(
                    applicationCertificateModels,
                    applicationCertificateModel =>
                        !applicationCertificateModel.unknown &&
                        TimeHelper.isAfter(
                            (applicationCertificateModel.entity as Contract.AadDirectoryApplicationCertificate).expirationTime,
                            TimeHelper.utcNow())),
            [applicationCertificateModels]);
    const applicationActiveSecretModels =
        useMemo(
            () =>
                _.filter(
                    applicationSecretModels,
                    applicationSecretModel =>
                        !applicationSecretModel.unknown &&
                        TimeHelper.isAfter(
                            (applicationSecretModel.entity as Contract.AadDirectoryApplicationSecret).expirationTime,
                            TimeHelper.utcNow())),
            [applicationSecretModels]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.hooks.useDefinition.hooks.useAadDirectoryApplicationServicePrincipalDefinition",
            () => ({
                applicationActiveCertificates: "Application has valid certificates which, if compromised could allow an attacker to access sensitive resources",
                applicationActiveSecrets: "Application has valid secrets which, if compromised could allow an attacker to access sensitive resources",
                applicationNoneActiveSecretsAndCertificates: "Application doesn't have valid secrets or certificates, making it less likely to be compromised",
                disabled: "Application is disabled",
                microsoftApp: "This is a Microsoft Application",
                vendor: "Application is used by a 3rd party vendor"
            }));
    return new AzureAccessPrincipalRiskDefinition([
        !riskModel.risk.identityEnabled
            ? localization.disabled()
            : undefined,
        applicationServicePrincipal.type !== Contract.AadDirectoryServicePrincipalType.MicrosoftFirstParty
            ? undefined
            : localization.microsoftApp(),
        !applicationServicePrincipal.vendor
            ? undefined
            : localization.vendor(),
        _.some(applicationActiveCertificateModels)
            ? localization.applicationActiveCertificates()
            : undefined,
        _.some(applicationActiveSecretModels)
            ? localization.applicationActiveSecrets()
            : undefined,
        !applicationServicePrincipal.vendor &&
        _.isEmpty(applicationActiveCertificateModels) &&
        _.isEmpty(applicationActiveSecretModels)
            ? localization.applicationNoneActiveSecretsAndCertificates()
            : undefined
    ]);
}