import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function JoinIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path d="M9.56283 7.2491C8.58068 8.57764 8 10.221 8 12C8 13.779 8.58068 15.4224 9.56283 16.7509C10.2157 16.5363 10.8092 16.1908 11.3124 15.7455C10.4911 14.719 10 13.4168 10 12C10 10.5832 10.4911 9.281 11.3124 8.2545C10.8092 7.80921 10.2157 7.46374 9.56283 7.2491Z"/>
            <path d="M14.8118 6.11764C15.1959 6.04048 15.5932 6 16 6C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18C15.5932 18 15.1959 17.9595 14.8118 17.8824C14.2994 18.4752 13.7108 19.0005 13.0614 19.443C13.9711 19.8025 14.9625 20 16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C14.9625 4 13.9711 4.19751 13.0613 4.55698C13.7108 4.99948 14.2994 5.52476 14.8118 6.11764Z"/>
            <path
                clipRule="evenodd"
                d="M8 18C11.3137 18 14 15.3137 14 12C14 8.68629 11.3137 6 8 6C4.68629 6 2 8.68629 2 12C2 15.3137 4.68629 18 8 18ZM16 12C16 16.4183 12.4183 20 8 20C3.58172 20 0 16.4183 0 12C0 7.58172 3.58172 4 8 4C12.4183 4 16 7.58172 16 12Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}