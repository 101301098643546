import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsCommonRdsClusterResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, FeatureHelper } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";

export function useAwsRdsNeptuneClusterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const clusterModel = resourceModel as Contract.AwsRdsNeptuneClusterModel;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonNetworkCardInfoItems = useAwsCommonNetworkingInfoItemElements({ resourceModel });
    const commonRdsClusterResourceInfoElements =
        useAwsCommonRdsClusterResourceInfoElements(
            clusterModel,
            Contract.TypeNames.AwsRdsNeptuneDatabaseInstance,
            Contract.TypeNames.AwsRdsNeptuneGlobalCluster,
            Contract.TypeNames.AwsRdsNeptuneClusterSnapshot);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonRdsClusterResourceInfoElements.status,
                    commonRdsClusterResourceInfoElements.engineVersion,
                    commonRdsClusterResourceInfoElements.storageSize,
                    commonRdsClusterResourceInfoElements.instances,
                    commonRdsClusterResourceInfoElements.snapshots,
                    commonRdsClusterResourceInfoElements.globalCluster,
                    ...commonEncryptedResourceInfoElements,
                    commonRdsClusterResourceInfoElements.backupRetentionTimeFrame,
                    commonRdsClusterResourceInfoElements.cloudWatchLoggingEnabled,
                    commonRdsClusterResourceInfoElements.cloudWatchLogTypes,
                    commonRdsClusterResourceInfoElements.deleteProtection,
                    commonRdsClusterResourceInfoElements.availabilityZones
                ]}
                options={options?.infoOptions}>
                {
                    // Migration: 1.161 | Delete condition & keep false flow
                    !FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerEntityModelsCompleted)
                        ? undefined
                        : <NetworkingInfoCard>
                            {commonNetworkCardInfoItems.inboundAccessType}
                            {commonNetworkCardInfoItems.inboundExternalAccessScope}
                            {commonNetworkCardInfoItems.getVpcs()}
                            {commonNetworkCardInfoItems.getSubnets()}
                            {commonNetworkCardInfoItems.getSecurityGroups()}
                        </NetworkingInfoCard>}
            </Info>
    });
}