import { DataTableFilterExternalOptions, makeContextProvider, SelectionActions, selectionActionsHeight, SelectionActionsItemPermissions, useLocalization, useUncaptureValue } from "@infrastructure";
import { SxProps } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TableActions } from "..";
import { Contract, GroupedTable, GroupedTableActions, RiskTypeGroups, useTheme } from "../../../../../../../../common";
import { useIdentityPermissionsTranslator } from "../../../../../../hooks";
import { useSideViewState } from "../../../../../SideView";
import { useRiskController } from "../../../../hooks";
import { RequestData, useItemsContextProvider, useRiskTypeDefinition } from "../../hooks";
import { useRiskContext } from "../../Items";
import { Item } from "./components";
import { useDefinition } from "./hooks";

type GroupedProps = {
    filterExternalOptions: DataTableFilterExternalOptions;
    filtersInitialized: boolean;
    filtersMap: Dictionary<any>;
    groupingType: RiskTypeGroups;
    onRiskGroupCountChanged?: (riskGroupCount?: number) => void;
    onRiskModelCountChanged?: (riskModelCount?: number) => void;
    requestData: RequestData;
    riskStatus: Contract.RiskStatus;
    sx?: SxProps;
    view: Contract.RisksView;
};

export class GroupedContext {
    constructor(public itemIdToSelectionMap: Dictionary<GroupedSelection>) {
    }
}

export const [useGroupedContext, , useGroupedContextProvider] = makeContextProvider<GroupedContext>();

export function Grouped({ filterExternalOptions, filtersInitialized, filtersMap, groupingType, onRiskGroupCountChanged, onRiskModelCountChanged, requestData, riskStatus, sx, view }: GroupedProps) {
    const { riskType } = useRiskContext();
    const riskTypeDefinition = useRiskTypeDefinition(riskType, view);
    const sideViewState = useSideViewState();
    const itemIdToTableActionsMapRef = useRef<Dictionary<TableActions>>({});
    const groupedTableActionsRef = useRef<GroupedTableActions>();
    const [itemIdToLoadedRiskModelsMap, setItemIdToLoadedRiskModelsMap] = useState<Dictionary<Contract.RiskModel[]>>({});
    const [itemIdToSelectionMap, setItemIdToSelectionMap] = useState<Dictionary<GroupedSelection>>({});
    const itemIdsSetRef = useRef<Set<string>>();

    const [{ requestFilters, riskIdToScopeIdsMapRef }, , ItemsContextProvider] =
        useItemsContextProvider(
            filtersMap,
            filtersInitialized,
            status => uncaptureReloadRiskModels(reloadRiskModels => reloadRiskModels(status)),
            requestData,
            view);

    const definition = useDefinition(groupingType, riskType);
    const { getRiskGroups } = useRiskController(riskType);

    const fetchItems =
        useCallback(
            async () => {
                const { riskGroups, riskModelCount } = await getRiskGroups(requestFilters!, definition.groupRequestType);
                const items =
                    _.map<Contract.RiskControllerGetRiskGroupsResponseRiskGroup, GroupedItem>(
                        riskGroups,
                        riskGroup => ({
                            id: definition.riskGroupToId(riskGroup),
                            riskGroup
                        }));
                onRiskModelCountChanged?.(riskModelCount);
                onRiskGroupCountChanged?.(items.length);

                itemIdsSetRef.current = new Set(_.map(items, item => item.id));

                return items;
            },
            [definition.groupRequestType, requestFilters]);

    useEffect(
        () => {
            if (!_.isNil(requestFilters)) {
                setItemIdToLoadedRiskModelsMap({});
                setItemIdToSelectionMap({});
                itemIdToTableActionsMapRef.current = {};
                groupedTableActionsRef.current!.reset();
            }
        },
        [requestFilters]);

    async function reloadRiskModels(status?: Contract.RiskStatus) {
        if (!_.isNil(status) && status === riskStatus) {
            await groupedTableActionsRef.current!.reloadGroups();
            const itemIdSet = itemIdsSetRef.current!;

            setItemIdToLoadedRiskModelsMap(
                itemIdToLoadedRiskModelsMap =>
                    _.pickBy(
                        itemIdToLoadedRiskModelsMap,
                        (_, itemId) => itemIdSet.has(itemId)));
            setItemIdToSelectionMap(
                itemIdToSelectionMap =>
                    _.pickBy(
                        itemIdToSelectionMap,
                        (_, itemId) => itemIdSet.has(itemId)));

            itemIdToTableActionsMapRef.current =
                _.pickBy(
                    itemIdToTableActionsMapRef.current,
                    (_, itemId) => itemIdSet.has(itemId));
        }

        await Promise.all(
            _(itemIdToTableActionsMapRef.current).
                values().
                map(tableActions => tableActions.reloadRiskModels()).
                value());
    }

    const uncaptureReloadRiskModels = useUncaptureValue(reloadRiskModels);

    const [, , GroupedContextProvider] =
        useGroupedContextProvider(
            () => new GroupedContext(itemIdToSelectionMap),
            [itemIdToSelectionMap]);

    const identityPermissionsTranslator = useIdentityPermissionsTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped",
            () => ({
                empty: {
                    filter: "No Matching Findings",
                    noFilter: "No Findings"
                }
            }));


    const onSelectionChanged =
        useCallback(
            (itemId: string, selectedRiskIds: string[], selectionPermissionsIntersection?: SelectionActionsItemPermissions) => {
                setItemIdToSelectionMap(
                    itemIdToSelectionMap =>
                        _.isEmpty(selectedRiskIds)
                            ? _.omit(itemIdToSelectionMap, itemId)
                            : {
                                ...itemIdToSelectionMap,
                                [itemId]: {
                                    permissions: selectionPermissionsIntersection,
                                    riskIds: selectedRiskIds
                                }
                            });
            },
            []);

    const onLoadedRiskModelsChanged =
        useCallback(
            (loadedRiskModels: Contract.RiskModel[], itemId: string) => {
                setItemIdToLoadedRiskModelsMap(
                    itemIdToLoadedRiskModelsMap =>
                        _.isEmpty(loadedRiskModels)
                            ? _.omit(itemIdToLoadedRiskModelsMap, itemId)
                            : {
                                ...itemIdToLoadedRiskModelsMap,
                                [itemId]: loadedRiskModels
                            });
            },
            []);

    const [selectedLoadedRiskModels, selectionPermissions, selectedRiskIdToScopeIdsMap] =
        useMemo(
            () => {
                const allPermissions =
                    _(itemIdToSelectionMap).
                        flatMap(riskSelection => riskSelection.permissions?.all ?? []).
                        uniq().
                        value();
                const commonPermissions =
                    _(itemIdToSelectionMap).
                        map(riskSelection => riskSelection.permissions?.common).
                        reduce(
                            (aggregatedCommonPermissions, commonPermissions) =>
                                _.intersection(
                                    aggregatedCommonPermissions,
                                    commonPermissions)) ?? [];
                const selectionPermissions =
                    new SelectionActionsItemPermissions(
                        allPermissions,
                        commonPermissions);
                const selectedRiskIds =
                    _.flatMap(
                        itemIdToSelectionMap,
                        riskSelection => riskSelection.riskIds);
                const selectedLoadedRiskModels =
                    _(itemIdToLoadedRiskModelsMap).
                        values().
                        flatMap().
                        filter(loadedRiskModel => _.includes(selectedRiskIds, loadedRiskModel.risk.id)).
                        value();
                const selectedRiskIdToScopeIdsMap =
                    _(selectedRiskIds).
                        keyBy(selectedRiskId => selectedRiskId).
                        mapValues(selectedRiskId => riskIdToScopeIdsMapRef.current[selectedRiskId]).
                        value();

                return [selectedLoadedRiskModels, selectionPermissions, selectedRiskIdToScopeIdsMap];
            },
            [itemIdToLoadedRiskModelsMap, itemIdToSelectionMap, riskIdToScopeIdsMapRef]);

    const clearSelection =
        useCallback(
            () => {
                _.each(
                    itemIdToTableActionsMapRef.current,
                    itemTableActions => itemTableActions.setSelectedItemIds([]));
                setItemIdToSelectionMap({});
            },
            []);

    const selectionActionsElements =
        useMemo(
            () =>
                riskTypeDefinition.selectionActionElements?.(
                    {
                        clearSelection,
                        getSelectedRiskIdToScopeIdsMap: () => selectedRiskIdToScopeIdsMap,
                        reloadRiskModels
                    }),
            [clearSelection, reloadRiskModels, selectedRiskIdToScopeIdsMap]);

    const theme = useTheme();
    return (
        <ItemsContextProvider>
            <GroupedContextProvider>
                <GroupedTable
                    actionsRef={groupedTableActionsRef}
                    columns={definition.columns}
                    fetchItems={fetchItems}
                    filterExternalOptions={filterExternalOptions}
                    getItemId={(item: GroupedItem) => item.id}
                    infiniteScrollOptions={{
                        emptyText:
                            _.isEmpty(filtersMap)
                                ? localization.empty.noFilter()
                                : localization.empty.filter(),
                        marginBottom:
                            _.isEmpty(selectedRiskIdToScopeIdsMap)
                                ? undefined
                                : theme.px(selectionActionsHeight)
                    }}
                    sort={definition.defaultSort}
                    sx={sx}>
                    {(item: GroupedItem) =>
                        <Item
                            item={item}
                            riskStatus={riskStatus}
                            tableActionsRef={tableActions => itemIdToTableActionsMapRef.current[item.id] = tableActions!}
                            onClose={() => groupedTableActionsRef.current!.setItemExpanded(item.id, false)}
                            onLoadedRiskModelsChanged={onLoadedRiskModelsChanged}
                            onSelectionChanged={onSelectionChanged}/>}
                </GroupedTable>
                <SelectionActions
                    itemIds={_.keys(selectedRiskIdToScopeIdsMap)}
                    itemPermissions={selectionPermissions}
                    loadedItems={selectedLoadedRiskModels}
                    permissionsTranslator={permissions => identityPermissionsTranslator(permissions as Contract.IdentityPermission[])}
                    visible={!sideViewState.open || sideViewState.inside}
                    onClear={clearSelection}>
                    {selectionActionsElements}
                </SelectionActions>
            </GroupedContextProvider>
        </ItemsContextProvider>);
}

interface GroupedSelection {
    permissions?: SelectionActionsItemPermissions;
    riskIds: string[];
}

export interface GroupedItem {
    id: string;
    riskGroup: Contract.RiskControllerGetRiskGroupsResponseRiskGroup;
}

export enum GroupedTableColumnId {
    Compliances = "compliances",
    Repositories = "repositories",
    Repository = "repository",
    RiskCount = "riskCount",
    RiskPolicies = "riskPolicies",
    RiskPolicyCategory = "riskPolicyCategory",
    RiskPolicyConfigurationTypeName = "riskPolicyConfigurationTypeName",
    Severity = "severity",
    Tenant = "tenant",
    Tenants = "tenants"
}