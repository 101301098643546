﻿import { Store } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { ConfigurationController, Contract, TypeHelper } from "..";

export class ScopeSystemEntityModelStore extends Store<Contract.ScopeSystemEntityModel, never, never> {
    public useGetAuditEventAutomationRule =
        () => this.useGetTypeName(Contract.TypeNames.AuditEventAutomationRuleConfiguration);
    public useGetAwsOrganization =
        () => this.useGetTypeName(Contract.TypeNames.AwsOrganizationConfiguration);
    public useGetAzureOrganization =
        () => this.useGetTypeName(Contract.TypeNames.AzureOrganizationConfiguration);
    public useGetCiIntegration =
        (ciTenantType?: Contract.CiTenantType) =>
            _.filter(
                this.useGetTypeName(Contract.TypeNames.CiIntegrationConfiguration),
                scopeEntityModel =>
                    _.isNil(ciTenantType) ||
                    (scopeEntityModel.configuration as Contract.CiIntegrationConfiguration).type === ciTenantType) as Contract.CiIntegrationModel[];
    public useGetCloudProviderTenantOrganization =
        () => this.useGetTypeName(Contract.TypeNames.CloudProviderTenantOrganizationConfiguration);
    public useGetCodeIntegration =
        () => this.useGetTypeName(Contract.TypeNames.CodeIntegrationConfiguration);
    public useGetCodeOrganization =
        (codeTenantType?: Contract.CodeTenantType) =>
            _.filter(
                this.useGetTypeName(Contract.TypeNames.CodeOrganizationConfiguration),
                scopeEntityModel =>
                    _.isNil(codeTenantType) ||
                    (scopeEntityModel.configuration as Contract.CodeOrganizationConfiguration).type === codeTenantType) as Contract.CodeOrganizationModel[];
    public useGetCodePipeline =
        (codePipelineType?: Contract.CodePipelineType) =>
            _.filter(
                this.useGetTypeName(Contract.TypeNames.CodePipelineConfiguration),
                scopeEntityModel =>
                    _.isNil(codePipelineType) ||
                    (scopeEntityModel.configuration as Contract.CodePipelineConfiguration).type === codePipelineType) as Contract.CodePipelineModel[];
    public useGetCodeServer =
        (codeTenantType?: Contract.CodeTenantType) =>
            _.filter(
                this.useGetTypeName(Contract.TypeNames.CodeServerConfiguration),
                scopeEntityModel =>
                    _.isNil(codeTenantType) ||
                    (scopeEntityModel.configuration as Contract.CodeServerConfiguration).codeTenantType === codeTenantType) as Contract.CodeServerModel[];
    public useGetCustomCompliance =
        () => this.useGetTypeName(Contract.TypeNames.CustomComplianceConfiguration);
    public useGetDatadog =
        () => this.useGetTypeName(Contract.TypeNames.DatadogOrganizationConfiguration);
    public useGetEndpointConnector =
        () => this.useGetTypeName(Contract.TypeNames.EndpointConnectorConfiguration);
    public useGetEntityPropertyDefinition =
        () => this.useGetTypeName(Contract.TypeNames.EntityPropertyDefinitionConfiguration);
    public useGetGcpOrganization =
        () => this.useGetTypeName(Contract.TypeNames.GcpOrganizationConfiguration);
    public useGetJira =
        () => this.useGetTypeName(Contract.TypeNames.JiraInstanceConfiguration);
    public useGetKubernetesClusterConnector =
        () => this.useGetTypeName(Contract.TypeNames.KubernetesClusterConnectorConfiguration);
    public useGetOciOrganization =
        () => this.useGetTypeName(Contract.TypeNames.OciOrganizationConfiguration) as Contract.OciOrganizationModel[];
    public useGetOrganization =
        () => this.useGetTypeName(Contract.TypeNames.CloudProviderTenantOrganizationConfiguration);
    public useGetPermissionEligibility =
        () => this.useGetTypeName(Contract.TypeNames.PermissionEligibilityConfiguration) as Contract.PermissionEligibilityModel[];
    public useGetQRadar =
        () => this.useGetTypeName(Contract.TypeNames.QRadarServerConfiguration);
    public useGetReportSchedule =
        () => this.useGetTypeName(Contract.TypeNames.ReportScheduleConfiguration);
    public useGetRiskAutomationRule =
        () => this.useGetTypeName(Contract.TypeNames.RiskAutomationRuleConfiguration);
    public useGetServiceNow =
        () => this.useGetTypeName(Contract.TypeNames.ServiceNowInstanceConfiguration);
    public useGetSlack =
        () => this.useGetTypeName(Contract.TypeNames.SlackWorkspaceConfiguration) as Contract.SlackWorkspaceModel[];
    public useGetSplunk =
        () => this.useGetTypeName(Contract.TypeNames.SplunkEventCollectorConfiguration);
    public useGetSqs =
        () => this.useGetTypeName(Contract.TypeNames.SqsQueueConfiguration);
    public useGetTeams =
        () => this.useGetTypeName(Contract.TypeNames.TeamsOrganizationConfiguration);
    public useGetWebhook =
        () => this.useGetTypeName(Contract.TypeNames.WebhookEndpointConfiguration);

    private useGetTypeName =
        (typeName: string) => {
            const scopeSystemEntityModels = this.useGetAll();
            return useMemo(
                () =>
                    _.filter(
                        scopeSystemEntityModels,
                        scopeSystemEntityModel =>
                            TypeHelper.extendOrImplement(scopeSystemEntityModel.configuration.typeName, typeName)),
                [scopeSystemEntityModels, typeName]);
        };
}

export const scopeSystemEntityModelStore =
    new ScopeSystemEntityModelStore(
        scopeSystemEntityModel => scopeSystemEntityModel.configuration.id,
        {
            get:
                async ids => {
                    const { scopeSystemEntityModels } = await ConfigurationController.getScopeSystemEntityModels(new Contract.ConfigurationControllerGetScopeSystemEntityModelsRequest(ids));
                    return scopeSystemEntityModels;
                },
            getAll:
                async () => {
                    const { scopeSystemEntityModels } = await ConfigurationController.getScopeSystemEntityModels(new Contract.ConfigurationControllerGetScopeSystemEntityModelsRequest(undefined));
                    return scopeSystemEntityModels;
                }
        });