﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstanceRiskContext } from "../../../contexts";

export function useGcpComputeInstanceSshSerialPortEnabledRiskInstanceDefinition(riskModel: Contract.GcpComputeInstanceSshSerialPortEnabledRiskModel) {
    const instanceModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.GcpComputeInstanceModel;

    const getGcpComputeInstanceRiskContext = useGetGcpComputeInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceSshSerialPortEnabledRiskDefinition.hooks.useGcpComputeInstanceSshSerialPortEnabledRiskInstanceDefinition",
            () => ({
                description: "{{instance}} has serial port access enabled",
                sections: {
                    resolution: {
                        step1: "Click the **Edit** button",
                        step2: "Delete the metadata entry **serial-port-enable**",
                        step3: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const instanceContextItems = getGcpComputeInstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.status,
                instanceContextItems.vpcsAndSubnets,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        });
}