import { Shadows } from "@infrastructure";
import { Box, useTheme } from "@mui/material";
import React, { ReactNode } from "react";

type SelectionCardProps = {
    children: ReactNode;
    disabled?: boolean;
    height: number;
    selected: boolean;
    width: number;
};

export function SelectionCard({ children, disabled = false, height, selected, width }: SelectionCardProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                "&:hover": {
                    boxShadow: theme.important(Shadows.Card)
                },
                background: theme.palette.background.paper,
                boxShadow: theme.shadows[Shadows.Card],
                cursor: "pointer",
                height: theme.px(height),
                padding: theme.spacing(2),
                width: theme.px(width),
                ...(disabled && {
                    cursor: "default",
                    opacity: 0.5
                }),
                ...(!disabled &&
                    selected && {
                    border: "solid 1px #D0D6E1",
                    boxShadow: theme.shadows[Shadows.Card],
                    height: theme.px(height + 4),
                    outline: `solid 4px ${theme.palette.background.paper}`,
                    width: theme.px(width + 4)
                })
            }}>
            {children}
        </Box>);
}