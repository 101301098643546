import { Action0 } from "@infrastructure";
import * as d3 from "d3";
import { graphlib, layout as dagreLayout } from "dagre";
import _ from "lodash";
import { DirectedGraphModel, DirectedGraphModelColumn, DirectedGraphModelEdge, DirectedGraphModelNode } from ".";

export class DirectedGraphLayout {
    public readonly columns: DirectedGraphLayoutColumn[] = [];
    public readonly edges: DirectedGraphLayoutEdge[] = [];
    public readonly nodes: DirectedGraphLayoutNode[] = [];
    public readonly nodeSpacing = 25;

    constructor(
        public model: DirectedGraphModel,
        spacing: number,
        render: Action0) {
        const dagreGraph =
            new graphlib.Graph({
                compound: true,
                directed: true,
                multigraph: true
            });
        dagreGraph.setGraph({
            nodesep: this.nodeSpacing * spacing,
            rankdir: "LR",
            ranksep: this.nodeSpacing
        });
        dagreGraph.setDefaultEdgeLabel(() => ({}));

        _(model.nodes).
            map(modelNode => modelNode.columnId).
            uniq().
            each(columnId => dagreGraph.setNode(columnId, {}));

        _.each(
            model.edges,
            modelEdge => {
                dagreGraph.setEdge(modelEdge.sourceNodeId, modelEdge.destinationNodeId);
                this.edges.push(new DirectedGraphLayoutEdge(modelEdge));
            });

        _.each(
            model.nodes,
            modelNode => {
                let verticalAlignmentHeight = modelNode.contentSize.height;
                let verticalAlignmentOffset = 0;
                const modelEdges =
                    _.concat(
                        model.nodeIdToDirectionEdgesMap[modelNode.id].destinationEdges,
                        model.nodeIdToDirectionEdgesMap[modelNode.id].sourceEdges);
                if (!_.isEmpty(modelEdges)) {
                    let bottomAnchorPointY = -Infinity;
                    let topAnchorPointY = Infinity;
                    _.each(
                        modelEdges,
                        modelEdge => {
                            const anchorPoint = modelNode.getEdgeAnchorPoint(modelEdge);
                            bottomAnchorPointY = Math.max(bottomAnchorPointY, anchorPoint.y);
                            topAnchorPointY = Math.min(topAnchorPointY, anchorPoint.y);
                        });

                    const deltaBottom = modelNode.contentSize.height - bottomAnchorPointY;
                    const deltaTop = topAnchorPointY;
                    verticalAlignmentHeight = modelNode.contentSize.height + Math.abs(deltaTop - deltaBottom);
                    verticalAlignmentOffset = (verticalAlignmentHeight - modelNode.contentSize.height) / 2;
                }

                dagreGraph.setParent(
                    modelNode.id,
                    modelNode.columnId);
                dagreGraph.setNode(
                    modelNode.id,
                    {
                        height: verticalAlignmentHeight,
                        width: modelNode.contentSize.width
                    });

                this.nodes.push(
                    new DirectedGraphLayoutNode(
                        modelNode,
                        verticalAlignmentOffset));
            });

        dagreLayout(dagreGraph);

        const nodeMap =
            _.keyBy(
                this.nodes,
                node => node.modelNode.id);

        let topGraphY = Infinity;
        _(dagreGraph.nodes()).
            map(dagreGraphNodeId => nodeMap[dagreGraphNodeId]).
            filter().
            each(
                node => {
                    const dagreGraphNode = dagreGraph.node(node.modelNode.id);
                    node.fx = dagreGraphNode.x - node.modelNode.contentSize.width / 2;
                    node.fy = dagreGraphNode.y - node.modelNode.contentSize.height / 2 + node.verticalOffset;

                    topGraphY = Math.min(topGraphY, node.fy);
                });
        _.each(
            this.nodes,
            node =>
                node.fy! +=
                    _.isEmpty(model.columns)
                        ? 2 * this.nodeSpacing - topGraphY
                        : 4 * this.nodeSpacing - topGraphY);

        this.columns =
            _(this.nodes).
                filter(node => !_.isNil(model.columnMap[node.modelNode.columnId])).
                groupBy(node => node.modelNode.columnId).
                map(
                    (columnNodes, columnId) =>
                        new DirectedGraphLayoutColumn(
                            model.columnMap[columnId],
                            _(columnNodes).
                                map(node => node.modelNode.contentSize.width).
                                max()!,
                            _(columnNodes).
                                map(node => node.fx).
                                min()!)).
                value();

        const nodeIdToSourceEdgesMap =
            _(this.edges).
                groupBy(edge => edge.modelEdge.sourceNodeId).
                mapValues(
                    nodeSourceEdges =>
                        _.orderBy(
                            nodeSourceEdges,
                            [
                                nodeSourceEdge => nodeMap[nodeSourceEdge.modelEdge.destinationNodeId].fy,
                                nodeSourceEdge => nodeMap[nodeSourceEdge.modelEdge.destinationNodeId].modelNode.getEdgeAnchorPoint(nodeSourceEdge.modelEdge).y
                            ])).
                value();

        d3.
            forceSimulation(this.nodes).
            force("link", d3.forceLink(this.edges).id(node => (node as DirectedGraphLayoutNode).modelNode.id)).
            stop().
            tick();

        d3.timeout(
            () => {
                _.each(
                    this.edges,
                    edge => {
                        const sourceNodeSourceEdges = nodeIdToSourceEdgesMap[edge.modelEdge.sourceNodeId];
                        const sourceNodeSourceEdgeIndex = _.indexOf(sourceNodeSourceEdges, edge);
                        const sourceNodeSourceEdgeSpacing = Math.min(this.nodeSpacing / sourceNodeSourceEdges.length, 5);

                        const sourceNodeAnchorPoint = nodeMap[edge.modelEdge.sourceNodeId].modelNode.getEdgeAnchorPoint(edge.modelEdge);
                        const destinationNodeAnchorPoint = nodeMap[edge.modelEdge.destinationNodeId].modelNode.getEdgeAnchorPoint(edge.modelEdge);

                        const sourceX = edge.source.fx + sourceNodeAnchorPoint.x;
                        const sourceY = edge.source.fy + sourceNodeAnchorPoint.y - (sourceNodeSourceEdges.length - 1) * sourceNodeSourceEdgeSpacing / 2 + sourceNodeSourceEdgeIndex * sourceNodeSourceEdgeSpacing;
                        const destinationX = edge.target.fx + destinationNodeAnchorPoint.x;
                        const destinationY = edge.target.fy + destinationNodeAnchorPoint.y;
                        const centerX = sourceX + (destinationX - sourceX) / 2;
                        const centerY = sourceY + (destinationY - sourceY) / 2;

                        edge.svgPath = `M${sourceX},${sourceY}Q${sourceX + (destinationX - sourceX) * 0.4},${sourceY},${centerX},${centerY}T${destinationX},${destinationY}`;
                        edge.centerX = centerX;
                        edge.centerY = centerY;
                    });

                render();
            });
    }

    public getDimensions =
        () => {
            const horizontalMaxNode = _.maxBy(this.nodes, node => node.fy)!;
            const horizontalMinNode = _.minBy(this.nodes, node => node.fy)!;
            const verticalMaxNode = _.maxBy(this.nodes, node => node.fx)!;
            const verticalMinNode = _.minBy(this.nodes, node => node.fx)!;
            return ({
                height:
                    Math.abs(
                        horizontalMaxNode.fy! + horizontalMaxNode.modelNode.contentSize.height / 2 -
                        (horizontalMinNode.fy! - horizontalMinNode.modelNode.contentSize.height / 2)) +
                    this.nodeSpacing * 2,
                width:
                    Math.abs(
                        verticalMaxNode.fx! + verticalMaxNode.modelNode.contentSize.width / 2 -
                        (verticalMinNode.fx! - verticalMinNode.modelNode.contentSize.width / 2)) +
                    this.nodeSpacing * 2
            });
        };
}

export class DirectedGraphLayoutColumn {
    constructor(
        public modelColumn: DirectedGraphModelColumn,
        public width: number,
        public x: number) {
    }
}

export class DirectedGraphLayoutEdge {
    public centerX?: number;
    public centerY?: number;
    public svgPath?: string;
    public source: any;
    public target: any;

    constructor(
        public modelEdge: DirectedGraphModelEdge) {
        this.source = modelEdge.sourceNodeId;
        this.target = modelEdge.destinationNodeId;
    }
}

export class DirectedGraphLayoutNode {
    public fx?: number;
    public fy?: number;
    public x?: number;
    public y?: number;

    constructor(
        public modelNode: DirectedGraphModelNode,
        public verticalOffset: number = 0) {
    }
}