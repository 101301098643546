﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAadDirectoryEntityRiskContext } from "../contexts";

export function useAadSeverePermissionPrincipalRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AadSeverePermissionPrincipalRisk;
    const principalModel = entityModelStore.useGet(risk.entityId) as Contract.AadDirectoryPrincipalModel;

    const directoryRoleAssignmentModels = entityModelStore.useGet(risk.severeDirectoryPermissionDirectoryRoleAssignmentResourceIds) as Contract.AadDirectoryDirectoryRoleAssignmentModel[];
    const directoryRoleDefinitionModels =
        entityModelStore.useGet(
            _.map(
                directoryRoleAssignmentModels,
                directoryRoleAssignmentModel => (directoryRoleAssignmentModel.entity as Contract.AadDirectoryDirectoryRoleAssignment).roleDefinitionId)) as Contract.AadDirectoryDirectoryRoleAssignmentModel[];

    const getAadDirectoryEntityRiskContext = useGetAadDirectoryEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.access.useAadSeverePermissionPrincipalRiskDefinition",
            () => ({
                contextItems: {
                    severeDirectoryPermissionDirectoryRoleAssignments: "New privileged permissions granted through {{severeDirectoryPermissionDirectoryRoleAssignments}}",
                    severeDirectoryPermissionRoleDefinitions: "{{severeDirectoryPermissionDirectoryRoleDefinitions}} which are classified as privileged was assigned on {{severeDirectoryPermissionDirectoryPermissionStartTime | TimeFormatter.shortDate}}"
                },
                description: {
                    [Contract.TypeNames.AadDirectoryGroup]:
                        [
                            "{{groupIdentities}} was recently assigned roles (via {{principal}}) which are classified as privileged",
                            "{{groupIdentities}} were recently assigned roles (via {{principal}}) which are classified as privileged"
                        ],
                    [Contract.TypeNames.AadDirectoryIdentity]: "{{principal}} was recently assigned roles which are classified as privileged"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        principalModel.entity.typeName === Contract.TypeNames.AadDirectoryGroup
            ? localization.description[Contract.TypeNames.AadDirectoryGroup](
                risk.groupIdentityIds.length,
                {
                    groupIdentities:
                        <InlineEntities
                            entityIdsOrModels={risk.groupIdentityIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                            entityVariant="iconTextTypeTenant"
                            variant="itemCountAndType"/>,
                    principal:
                        <Entity
                            entityIdOrModel={principalModel}
                            variant="typeText"/>
                })
            : localization.description[Contract.TypeNames.AadDirectoryIdentity]({
                principal:
                    <Entity
                        entityIdOrModel={principalModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [],
        riskModel,
        () => {
            const directoryEntityRiskContext = getAadDirectoryEntityRiskContext(principalModel);
            return [
                directoryEntityRiskContext.generalInformation,
                new RiskDefinitionContextItem(
                    localization.contextItems.severeDirectoryPermissionRoleDefinitions({
                        severeDirectoryPermissionDirectoryPermissionStartTime: risk.severeDirectoryPermissionStartTime,
                        severeDirectoryPermissionDirectoryRoleDefinitions:
                            <InlineEntities
                                entityIdsOrModels={directoryRoleDefinitionModels}
                                entityTypeName={Contract.TypeNames.AadDirectoryDirectoryRoleDefinition}
                                variant="itemCountAndType"/>
                    })),
                new RiskDefinitionContextItem(
                    localization.contextItems.severeDirectoryPermissionDirectoryRoleAssignments({
                        severeDirectoryPermissionDirectoryRoleAssignments:
                            <InlineEntities
                                entityIdsOrModels={directoryRoleAssignmentModels}
                                entityTypeName={Contract.TypeNames.AadDirectoryDirectoryRoleAssignment}
                                variant="itemCountAndType"/>
                    }))
            ];
        },
        {
            sections: []
        });
}