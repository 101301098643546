import { Action1, Filter, Filters, FiltersActions, Optional, TextValuesFilter, useActions, useChangeEffect, ValueFilter, ValuesFilter, ValuesFilterItem, ValuesFilterSelection } from "@infrastructure";
import { Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Ref, useMemo, useRef, useState } from "react";
import { Contract, EntityFilter, NetworkScopeFormatter, StorageHelper, useTheme } from "../../..";
import { NetworkView, useNetworkContext } from "..";

type ToolbarProps = {
    actionsRef: Ref<Optional<ToolbarActions>>;
    baseUrl: string;
    initialFilterMap?: Dictionary<any>;
    networkGraph: Contract.NetworkGraph;
    onFiltersChanged: Action1<Dictionary<any>>;
    view: NetworkView;
};

export type ToolbarActions = {
    filter: (filterId: ToolbarFilterId, type: "by" | "except", values: any[]) => void;
};

export function Toolbar({ actionsRef, initialFilterMap, networkGraph, onFiltersChanged }: ToolbarProps) {
    const { definition, tenantType } = useNetworkContext();
    const [filterMap, setFilterMap] = useState<Dictionary<any>>(initialFilterMap ?? {});
    useChangeEffect(
        () => {
            onFiltersChanged(filterMap);
        },
        [filterMap]);

    const filterToValuesMap =
        useMemo(
            () => ({
                [ToolbarFilterId.InterfaceSecurityGroupIds]:
                    _(networkGraph.securityPerimeters).
                        filter(securityPerimeter => securityPerimeter.type == Contract.NetworkGraphSecurityPerimeterType.InterfaceSecurityGroup).
                        map(securityGroup => securityGroup.securityPerimeterResourceId).
                        uniq().
                        value(),
                [ToolbarFilterId.FirewallPolicyAndVpcIds]:
                    _(networkGraph.securityPerimeters).
                        filter(
                            securityPerimeter =>
                                securityPerimeter.type == Contract.NetworkGraphSecurityPerimeterType.FirewallPolicy ||
                                securityPerimeter.type == Contract.NetworkGraphSecurityPerimeterType.Vpc).
                        map(securityGroup => securityGroup.securityPerimeterResourceId).
                        uniq().
                        value(),
                [ToolbarFilterId.SourceEntityIds]:
                    _(networkGraph.sourceGroups).
                        flatMap(source => source.entityIds ?? []).
                        uniq().
                        value(),
                [ToolbarFilterId.SourceSubnets]:
                    _(networkGraph.sourceGroups).
                        flatMap(source => source.subnets ?? []).
                        uniq().
                        value(),
                [ToolbarFilterId.DestinationScopeFilterIds]:
                    _(networkGraph.destinationGroups).
                        flatMap(destinationGroup => destinationGroup.scopes ?? []).
                        uniqBy(scope => scope.filterId).
                        value()
            }),
            []);

    function getExisingFilter(filterId: ToolbarFilterId) {
        switch (filterId) {
            case ToolbarFilterId.DestinationScopeFilterIds:
                return _.map(
                    filterToValuesMap[ToolbarFilterId.DestinationScopeFilterIds] as Contract.NetworkGraphDestinationGroupScope[],
                    scope => scope.filterId);
            default:
                return filterToValuesMap[filterId] as string[];
        }
    }

    const filtersActionsRef = useRef<FiltersActions>();
    useActions(
        actionsRef,
        {
            filter:
                (filterId, type, values) =>
                    filtersActionsRef.current?.set(
                        filterId,
                        existingFilter =>
                            new ValuesFilterSelection(
                                type == "by"
                                    ? false
                                    : existingFilter?.emptyValue ?? false,
                                type === "by"
                                    ? values
                                    : _.difference(
                                        _.isEmpty(existingFilter)
                                            ? getExisingFilter(filterId)
                                            : existingFilter.values,
                                        values)))
        });

    const theme = useTheme();
    return (
        <Stack
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={1.5}
            sx={{ paddingBottom: theme.spacing(1.5) }}>
            <Filters
                actionsRef={filtersActionsRef}
                filterQueryParameterName="filterMap"
                initialFilterMap={initialFilterMap}
                visibilityStorageItem={StorageHelper.customerNetworkToolbarFilters(tenantType)}
                onFilterChanged={setFilterMap}>
                <Filter
                    default={true}
                    id={ToolbarToggleFilterId.WideRangeSourceSubnet}
                    key={ToolbarToggleFilterId.WideRangeSourceSubnet}
                    title={definition.toolbarToggleFilterIdTranslator(ToolbarToggleFilterId.WideRangeSourceSubnet)}>
                    <ValueFilter
                        items={[
                            {
                                title: definition.toolbarToggleFilterIdTranslator(ToolbarToggleFilterId.WideRangeSourceSubnet),
                                value: true
                            }
                        ]}
                        placeholder={definition.toolbarToggleFilterIdTranslator(ToolbarToggleFilterId.WideRangeSourceSubnet)}/>
                </Filter>
                <Filter
                    id={ToolbarFilterId.SourceSubnets}
                    title={definition.toolbarFilterIdTranslator(ToolbarFilterId.SourceSubnets)}>
                    <TextValuesFilter
                        placeholder={definition.toolbarFilterIdTranslator(ToolbarFilterId.SourceSubnets)}
                        values={filterToValuesMap[ToolbarFilterId.SourceSubnets]}/>
                </Filter>
                {_(
                    [
                        ToolbarFilterId.SourceEntityIds,
                        ToolbarFilterId.FirewallPolicyAndVpcIds,
                        ToolbarFilterId.InterfaceSecurityGroupIds
                    ]).
                    filter(filterId => !_.isEmpty(filterToValuesMap[filterId])).
                    map(
                        filterId =>
                            <Filter
                                id={filterId}
                                key={filterId}
                                title={definition.toolbarFilterIdTranslator(filterId)}>
                                <EntityFilter
                                    emptyValue={filterId === ToolbarFilterId.InterfaceSecurityGroupIds}
                                    entityIdsOrSearchableReferences={filterToValuesMap[filterId] as string[]}
                                    placeholder={definition.toolbarFilterIdTranslator(filterId)}/>
                            </Filter>).
                    value()}
                <Filter
                    id={ToolbarFilterId.DestinationScopeFilterIds}
                    title={definition.toolbarFilterIdTranslator(ToolbarFilterId.DestinationScopeFilterIds)}>
                    <ValuesFilter placeholder={definition.toolbarFilterIdTranslator(ToolbarFilterId.DestinationScopeFilterIds)}>
                        {_.map(
                            filterToValuesMap[ToolbarFilterId.DestinationScopeFilterIds],
                            destinationGroupScope =>
                                <ValuesFilterItem
                                    key={destinationGroupScope.filterId}
                                    title={
                                        NetworkScopeFormatter.networkScope(
                                            destinationGroupScope.destinationPortRange,
                                            destinationGroupScope.protocolRange)}
                                    value={destinationGroupScope.filterId}/>)}
                    </ValuesFilter>
                </Filter>
            </Filters>
        </Stack>);
}

export enum ToolbarFilterId {
    DestinationScopeFilterIds = "destinationScopeFilterIds",
    FirewallPolicyAndVpcIds = "firewallPolicyAndVpcIds",
    InterfaceSecurityGroupIds = "interfaceSecurityGroupIds",
    SourceEntityIds = "sourceEntityIds",
    SourceSubnets = "sourceSubnets"
}

export enum ToolbarToggleFilterId {
    WideRangeSourceSubnet = "wideRangeSourceSubnet"
}