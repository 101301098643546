import { useLocalization } from "@infrastructure";
import { Contract } from "../../..";

export function useOciResourceSearchGenericResourceTypeNameLocalization() {
    return useLocalization(
        "common.hooks.useEntityTypeNameTranslator.hooks.useOciResourceSearchGenericResourceTypeNameLocalization",
         () => ({
             typeNames: {
                    [Contract.TypeNames.OciAccessPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "access policy",
                                "access policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Access Policy",
                                "Access Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAdmKnowledgeBaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "adm knowledge base",
                                "adm knowledge bases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Adm Knowledge Base",
                                "Adm Knowledge Bases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAdmRemediationRecipeGenericResource]: {
                         text: {
                             withServiceName: [
                                "adm remediation recipe",
                                "adm remediation recipes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Adm Remediation Recipe",
                                "Adm Remediation Recipes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAdmRemediationRunGenericResource]: {
                         text: {
                             withServiceName: [
                                "adm remediation run",
                                "adm remediation runs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Adm Remediation Run",
                                "Adm Remediation Runs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAdmVulnerabilityAuditGenericResource]: {
                         text: {
                             withServiceName: [
                                "adm vulnerability audit",
                                "adm vulnerability audits"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Adm Vulnerability Audit",
                                "Adm Vulnerability Audits"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiAnomalyDetectionDataAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai anomaly detection data asset",
                                "ai anomaly detection data assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Anomaly Detection Data Asset",
                                "Ai Anomaly Detection Data Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiAnomalyDetectionModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai anomaly detection model",
                                "ai anomaly detection models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Anomaly Detection Model",
                                "Ai Anomaly Detection Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiAnomalyDetectionProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai anomaly detection project",
                                "ai anomaly detection projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Anomaly Detection Project",
                                "Ai Anomaly Detection Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiAnomalyDetectionPvtEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai anomaly detection pvt endpoint",
                                "ai anomaly detection pvt endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Anomaly Detection Pvt Endpoint",
                                "Ai Anomaly Detection Pvt Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiDocumentModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai document model",
                                "ai document models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Document Model",
                                "Ai Document Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiDocumentProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai document project",
                                "ai document projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Document Project",
                                "Ai Document Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiForecastDataAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai forecast data asset",
                                "ai forecast data assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Forecast Data Asset",
                                "Ai Forecast Data Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiForecastForecastGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai forecast forecast",
                                "ai forecast forecasts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Forecast Forecast",
                                "Ai Forecast Forecasts"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiForecastProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai forecast project",
                                "ai forecast projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Forecast Project",
                                "Ai Forecast Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiLanguageEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai language endpoint",
                                "ai language endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Language Endpoint",
                                "Ai Language Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiLanguageModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai language model",
                                "ai language models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Language Model",
                                "Ai Language Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiLanguageProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai language project",
                                "ai language projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Language Project",
                                "Ai Language Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiModelDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai model deployment",
                                "ai model deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Model Deployment",
                                "Ai Model Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai model",
                                "ai models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Model",
                                "Ai Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai project",
                                "ai projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Project",
                                "Ai Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiVisionModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai vision model",
                                "ai vision models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Vision Model",
                                "Ai Vision Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAiVisionProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "ai vision project",
                                "ai vision projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ai Vision Project",
                                "Ai Vision Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAlarmGenericResource]: {
                         text: {
                             withServiceName: [
                                "alarm",
                                "alarms"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Alarm",
                                "Alarms"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAnalyticsInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "analytics instance",
                                "analytics instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Analytics Instance",
                                "Analytics Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApiDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "api deployment",
                                "api deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Deployment",
                                "Api Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApiGatewayApiGenericResource]: {
                         text: {
                             withServiceName: [
                                "api gateway api",
                                "api gateway apis"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Gateway Api",
                                "Api Gateway Apis"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApiGatewayCertificateGenericResource]: {
                         text: {
                             withServiceName: [
                                "api gateway certificate",
                                "api gateway certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Gateway Certificate",
                                "Api Gateway Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApiGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "api gateway",
                                "api gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Gateway",
                                "Api Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApiGatewaySdkGenericResource]: {
                         text: {
                             withServiceName: [
                                "api gateway sdk",
                                "api gateway sdks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Gateway Sdk",
                                "Api Gateway Sdks"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApiGatewaySubscriberGenericResource]: {
                         text: {
                             withServiceName: [
                                "api gateway subscriber",
                                "api gateway subscribers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Gateway Subscriber",
                                "Api Gateway Subscribers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApiGatewayUsagePlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "api gateway usage plan",
                                "api gateway usage plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Gateway Usage Plan",
                                "Api Gateway Usage Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApmDomainGenericResource]: {
                         text: {
                             withServiceName: [
                                "apm domain",
                                "apm domains"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Apm Domain",
                                "Apm Domains"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAppGenericResource]: {
                         text: {
                             withServiceName: [
                                "app",
                                "apps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "App",
                                "Apps"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "application",
                                "applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application",
                                "Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciApplicationVipGenericResource]: {
                         text: {
                             withServiceName: [
                                "application vip",
                                "application vips"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Vip",
                                "Application Vips"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciArcsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "arcs saas environment",
                                "arcs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Arcs Saas Environment",
                                "Arcs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAtatGenericResource]: {
                         text: {
                             withServiceName: [
                                "atat",
                                "atats"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "ATAT",
                                "ATATs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutoScalingConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "auto scaling configuration",
                                "auto scaling configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Auto Scaling Configuration",
                                "Auto Scaling Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutonomousContainerDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous container database",
                                "autonomous container databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Container Database",
                                "Autonomous Container Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutonomousDataSecurityInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous data security instance",
                                "autonomous data security instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Data Security Instance",
                                "Autonomous Data Security Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutonomousDatabaseBackupGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous database backup",
                                "autonomous database backups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Database Backup",
                                "Autonomous Database Backups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutonomousDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous database",
                                "autonomous databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Database",
                                "Autonomous Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutonomousDatabaseSoftwareImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous database software image",
                                "autonomous database software images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Database Software Image",
                                "Autonomous Database Software Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutonomousExadataInfrastructureGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous exadata infrastructure",
                                "autonomous exadata infrastructures"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Exadata Infrastructure",
                                "Autonomous Exadata Infrastructures"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutonomousVirtualMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous virtual machine",
                                "autonomous virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Virtual Machine",
                                "Autonomous Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciAutonomousVmClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous vm cluster",
                                "autonomous vm clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Vm Cluster",
                                "Autonomous Vm Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBackupDestinationGenericResource]: {
                         text: {
                             withServiceName: [
                                "backup destination",
                                "backup destinations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Backup Destination",
                                "Backup Destinations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBastionGenericResource]: {
                         text: {
                             withServiceName: [
                                "bastion",
                                "bastions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bastion",
                                "Bastions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBigDataServiceApiKeyGenericResource]: {
                         text: {
                             withServiceName: [
                                "big data service api key",
                                "big data service api keys"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Big Data Service Api Key",
                                "Big Data Service Api Keys"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBigDataServiceAutoscaleGenericResource]: {
                         text: {
                             withServiceName: [
                                "big data service autoscale",
                                "big data service autoscales"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Big Data Service Autoscale",
                                "Big Data Service Autoscales"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBigDataServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "big data service",
                                "big data services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Big Data Service",
                                "Big Data Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBigDataServiceLakeConfigGenericResource]: {
                         text: {
                             withServiceName: [
                                "big data service lake config",
                                "big data service lake configs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Big Data Service Lake Config",
                                "Big Data Service Lake Configs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBigDataServiceMetastoreConfigGenericResource]: {
                         text: {
                             withServiceName: [
                                "big data service metastore config",
                                "big data service metastore configs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Big Data Service Metastore Config",
                                "Big Data Service Metastore Configs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBlockchainPlatformGenericResource]: {
                         text: {
                             withServiceName: [
                                "blockchain platform",
                                "blockchain platforms"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Blockchain Platform",
                                "Blockchain Platforms"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBootVolumeBackupGenericResource]: {
                         text: {
                             withServiceName: [
                                "boot volume backup",
                                "boot volume backups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Boot Volume Backup",
                                "Boot Volume Backups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBootVolumeGenericResource]: {
                         text: {
                             withServiceName: [
                                "boot volume",
                                "boot volumes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Boot Volume",
                                "Boot Volumes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBootVolumeReplicaGenericResource]: {
                         text: {
                             withServiceName: [
                                "boot volume replica",
                                "boot volume replicas"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Boot Volume Replica",
                                "Boot Volume Replicas"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBucketGenericResource]: {
                         text: {
                             withServiceName: [
                                "bucket",
                                "buckets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bucket",
                                "Buckets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciBudgetGenericResource]: {
                         text: {
                             withServiceName: [
                                "budget",
                                "budgets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Budget",
                                "Budgets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciByoipRangeGenericResource]: {
                         text: {
                             withServiceName: [
                                "byoip range",
                                "byoip ranges"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Byoip Range",
                                "Byoip Ranges"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCaBundleAssociationGenericResource]: {
                         text: {
                             withServiceName: [
                                "ca bundle association",
                                "ca bundle associations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ca Bundle Association",
                                "Ca Bundle Associations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCaBundleGenericResource]: {
                         text: {
                             withServiceName: [
                                "ca bundle",
                                "ca bundles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ca Bundle",
                                "Ca Bundles"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCccInfrastructureGenericResource]: {
                         text: {
                             withServiceName: [
                                "ccc infrastructure",
                                "ccc infrastructures"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ccc Infrastructure",
                                "Ccc Infrastructures"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCccUpgradeScheduleGenericResource]: {
                         text: {
                             withServiceName: [
                                "ccc upgrade schedule",
                                "ccc upgrade schedules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ccc Upgrade Schedule",
                                "Ccc Upgrade Schedules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCertificateAssociationGenericResource]: {
                         text: {
                             withServiceName: [
                                "certificate association",
                                "certificate associations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Certificate Association",
                                "Certificate Associations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCertificateAuthorityAssociationGenericResource]: {
                         text: {
                             withServiceName: [
                                "certificate authority association",
                                "certificate authority associations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Certificate Authority Association",
                                "Certificate Authority Associations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCertificateAuthorityGenericResource]: {
                         text: {
                             withServiceName: [
                                "certificate authority",
                                "certificate authorities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Certificate Authority",
                                "Certificate Authorities"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCertificateGenericResource]: {
                         text: {
                             withServiceName: [
                                "certificate",
                                "certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Certificate",
                                "Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCimDemoSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "cim demo saas environment",
                                "cim demo saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cim Demo Saas Environment",
                                "Cim Demo Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudAutonomousVmClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud autonomous vm cluster",
                                "cloud autonomous vm clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Autonomous Vm Cluster",
                                "Cloud Autonomous Vm Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudExadataInfrastructureGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud exadata infrastructure",
                                "cloud exadata infrastructures"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Exadata Infrastructure",
                                "Cloud Exadata Infrastructures"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudGuardAdhocQueryGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud guard adhoc query",
                                "cloud guard adhoc queries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Guard Adhoc Query",
                                "Cloud Guard Adhoc Queries"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudGuardDataSourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud guard data source",
                                "cloud guard data sources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Guard Data Source",
                                "Cloud Guard Data Sources"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudGuardDetectorRecipeGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud guard detector recipe",
                                "cloud guard detector recipes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Guard Detector Recipe",
                                "Cloud Guard Detector Recipes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudGuardManagedListGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud guard managed list",
                                "cloud guard managed lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Guard Managed List",
                                "Cloud Guard Managed Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudGuardResponderRecipeGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud guard responder recipe",
                                "cloud guard responder recipes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Guard Responder Recipe",
                                "Cloud Guard Responder Recipes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudGuardSavedQueryGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud guard saved query",
                                "cloud guard saved queries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Guard Saved Query",
                                "Cloud Guard Saved Queries"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudGuardTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud guard target",
                                "cloud guard targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Guard Target",
                                "Cloud Guard Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCloudVmClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud vm cluster",
                                "cloud vm clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Vm Cluster",
                                "Cloud Vm Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciClusterNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster network",
                                "cluster networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster Network",
                                "Cluster Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciClusterPlacementGroupDevGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster placement group dev",
                                "cluster placement group devs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster Placement Group Dev",
                                "Cluster Placement Group Devs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciClusterPlacementGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster placement group",
                                "cluster placement groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster Placement Group",
                                "Cluster Placement Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciClustersClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "clusters cluster",
                                "clusters clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Clusters Cluster",
                                "Clusters Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCommerceCloudSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "commerce cloud saas environment",
                                "commerce cloud saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Commerce Cloud Saas Environment",
                                "Commerce Cloud Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCompartmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "compartment",
                                "compartments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Compartment",
                                "Compartments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciComputeCapacityReservationGenericResource]: {
                         text: {
                             withServiceName: [
                                "compute capacity reservation",
                                "compute capacity reservations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Compute Capacity Reservation",
                                "Compute Capacity Reservations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciConnectHarnesGenericResource]: {
                         text: {
                             withServiceName: [
                                "connect harness",
                                "connect harnesses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connect Harness",
                                "Connect Harnesses"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciConsoleConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "console connection",
                                "console connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Console Connection",
                                "Console Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciConsoleDashboardGenericResource]: {
                         text: {
                             withServiceName: [
                                "console dashboard",
                                "console dashboards"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Console Dashboard",
                                "Console Dashboards"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciConsoleDashboardGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "console dashboard group",
                                "console dashboard groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Console Dashboard Group",
                                "Console Dashboard Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciConsoleHistoryGenericResource]: {
                         text: {
                             withServiceName: [
                                "console history",
                                "console histories"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Console History",
                                "Console Histories"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciConsoleResourceCollectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "console resource collection",
                                "console resource collections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Console Resource Collection",
                                "Console Resource Collections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciContainerGenericResource]: {
                         text: {
                             withServiceName: [
                                "container",
                                "containers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container",
                                "Containers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciContainerImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "container image",
                                "container images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container Image",
                                "Container Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciContainerInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "container instance",
                                "container instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container Instance",
                                "Container Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciContainerRepoGenericResource]: {
                         text: {
                             withServiceName: [
                                "container repo",
                                "container repos"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container Repo",
                                "Container Repos"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciContinuousQueryGenericResource]: {
                         text: {
                             withServiceName: [
                                "continuous query",
                                "continuous queries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Continuous Query",
                                "Continuous Queries"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCpeGenericResource]: {
                         text: {
                             withServiceName: [
                                "cpe",
                                "cpes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cpe",
                                "Cpes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCpqSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "cpq saas environment",
                                "cpq saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cpq Saas Environment",
                                "Cpq Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCrossConnectGenericResource]: {
                         text: {
                             withServiceName: [
                                "cross connect",
                                "cross connects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cross Connect",
                                "Cross Connects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCrossConnectGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "cross connect group",
                                "cross connect groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cross Connect Group",
                                "Cross Connect Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCustomerDnsZoneGenericResource]: {
                         text: {
                             withServiceName: [
                                "customer dns zone",
                                "customer dns zones"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Customer Dns Zone",
                                "Customer Dns Zones"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCxOpaSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "cx opa saas environment",
                                "cx opa saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cx Opa Saas Environment",
                                "Cx Opa Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciCxUnitySaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "cx unity saas environment",
                                "cx unity saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cx Unity Saas Environment",
                                "Cx Unity Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataCatalogGenericResource]: {
                         text: {
                             withServiceName: [
                                "data catalog",
                                "data catalogs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Catalog",
                                "Data Catalogs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataCatalogMetastoreGenericResource]: {
                         text: {
                             withServiceName: [
                                "data catalog metastore",
                                "data catalog metastores"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Catalog Metastore",
                                "Data Catalog Metastores"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataCatalogPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "data catalog private endpoint",
                                "data catalog private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Catalog Private Endpoint",
                                "Data Catalog Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataFlowApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "data flow application",
                                "data flow applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Flow Application",
                                "Data Flow Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataFlowClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "data flow cluster",
                                "data flow clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Flow Cluster",
                                "Data Flow Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataFlowPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "data flow pool",
                                "data flow pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Flow Pool",
                                "Data Flow Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataFlowRunGenericResource]: {
                         text: {
                             withServiceName: [
                                "data flow run",
                                "data flow runs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Flow Run",
                                "Data Flow Runs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataFlowSqlEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "data flow sql endpoint",
                                "data flow sql endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Flow Sql Endpoint",
                                "Data Flow Sql Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataLabelingDatasetGenericResource]: {
                         text: {
                             withServiceName: [
                                "data labeling dataset",
                                "data labeling datasets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Labeling Dataset",
                                "Data Labeling Datasets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "data saas environment",
                                "data saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Saas Environment",
                                "Data Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeAlertPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe alert policy",
                                "data safe alert policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Alert Policy",
                                "Data Safe Alert Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeArchiveRetrievalGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe archive retrieval",
                                "data safe archive retrievals"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Archive Retrieval",
                                "Data Safe Archive Retrievals"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeAuditPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe audit policy",
                                "data safe audit policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Audit Policy",
                                "Data Safe Audit Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeAuditProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe audit profile",
                                "data safe audit profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Audit Profile",
                                "Data Safe Audit Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeAuditTrailGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe audit trail",
                                "data safe audit trails"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Audit Trail",
                                "Data Safe Audit Trails"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeDatabaseSecurityConfigGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe database security config",
                                "data safe database security configs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Database Security Config",
                                "Data Safe Database Security Configs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeDiscoveryJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe discovery job",
                                "data safe discovery jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Discovery Job",
                                "Data Safe Discovery Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeLibraryMaskingFormatGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe library masking format",
                                "data safe library masking formats"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Library Masking Format",
                                "Data Safe Library Masking Formats"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeMaskPolicyHealthReportGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe mask policy health report",
                                "data safe mask policy health reports"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Mask Policy Health Report",
                                "Data Safe Mask Policy Health Reports"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeMaskingPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe masking policy",
                                "data safe masking policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Masking Policy",
                                "Data Safe Masking Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeMaskingReportGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe masking report",
                                "data safe masking reports"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Masking Report",
                                "Data Safe Masking Reports"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeOnpremConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe onprem connector",
                                "data safe onprem connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Onprem Connector",
                                "Data Safe Onprem Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafePrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe private endpoint",
                                "data safe private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Private Endpoint",
                                "Data Safe Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeReportDefinitionGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe report definition",
                                "data safe report definitions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Report Definition",
                                "Data Safe Report Definitions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeReportGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe report",
                                "data safe reports"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Report",
                                "Data Safe Reports"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSdmMaskingPolicyDifferenceGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe sdm masking policy difference",
                                "data safe sdm masking policy differences"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Sdm Masking Policy Difference",
                                "Data Safe Sdm Masking Policy Differences"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSecurityAssessmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe security assessment",
                                "data safe security assessments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Security Assessment",
                                "Data Safe Security Assessments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSecurityPolicyDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe security policy deployment",
                                "data safe security policy deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Security Policy Deployment",
                                "Data Safe Security Policy Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSecurityPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe security policy",
                                "data safe security policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Security Policy",
                                "Data Safe Security Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSensitiveDataModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe sensitive data model",
                                "data safe sensitive data models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Sensitive Data Model",
                                "Data Safe Sensitive Data Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSensitiveTypeGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe sensitive type",
                                "data safe sensitive types"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Sensitive Type",
                                "Data Safe Sensitive Types"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSensitiveTypesExportGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe sensitive types export",
                                "data safe sensitive types exports"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Sensitive Types Export",
                                "Data Safe Sensitive Types Exports"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSqlCollectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe sql collection",
                                "data safe sql collections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Sql Collection",
                                "Data Safe Sql Collections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSqlFirewallAllowedSqlGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe sql firewall allowed sql",
                                "data safe sql firewall allowed sqls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Sql Firewall Allowed Sql",
                                "Data Safe Sql Firewall Allowed Sqls"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSqlFirewallPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe sql firewall policy",
                                "data safe sql firewall policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Sql Firewall Policy",
                                "Data Safe Sql Firewall Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeSqlFirewallViolationGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe sql firewall violation",
                                "data safe sql firewall violations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Sql Firewall Violation",
                                "Data Safe Sql Firewall Violations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeTargetAlertPolicyAssociationGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe target alert policy association",
                                "data safe target alert policy associations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Target Alert Policy Association",
                                "Data Safe Target Alert Policy Associations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeTargetDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe target database",
                                "data safe target databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe Target Database",
                                "Data Safe Target Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSafeUserAssessmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "data safe user assessment",
                                "data safe user assessments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Safe User Assessment",
                                "Data Safe User Assessments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataScienceJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science job",
                                "data science jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Job",
                                "Data Science Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataScienceJobRunGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science job run",
                                "data science job runs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Job Run",
                                "Data Science Job Runs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataScienceModelDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science model deployment",
                                "data science model deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Model Deployment",
                                "Data Science Model Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataScienceModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science model",
                                "data science models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Model",
                                "Data Science Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataScienceModelVersionSetGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science model version set",
                                "data science model version sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Model Version Set",
                                "Data Science Model Version Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataScienceNotebookSessionGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science notebook session",
                                "data science notebook sessions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Notebook Session",
                                "Data Science Notebook Sessions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSciencePipelineGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science pipeline",
                                "data science pipelines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Pipeline",
                                "Data Science Pipelines"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSciencePipelineRunGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science pipeline run",
                                "data science pipeline runs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Pipeline Run",
                                "Data Science Pipeline Runs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataSciencePrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science private endpoint",
                                "data science private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Private Endpoint",
                                "Data Science Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataScienceProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science project",
                                "data science projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Project",
                                "Data Science Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataScienceScheduleGenericResource]: {
                         text: {
                             withServiceName: [
                                "data science schedule",
                                "data science schedules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Science Schedule",
                                "Data Science Schedules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataTransferApplianceExportJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "data transfer appliance export job",
                                "data transfer appliance export jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Transfer Appliance Export Job",
                                "Data Transfer Appliance Export Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataTransferApplianceGenericResource]: {
                         text: {
                             withServiceName: [
                                "data transfer appliance",
                                "data transfer appliances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Transfer Appliance",
                                "Data Transfer Appliances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataTransferDeviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "data transfer device",
                                "data transfer devices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Transfer Device",
                                "Data Transfer Devices"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataTransferJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "data transfer job",
                                "data transfer jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Transfer Job",
                                "Data Transfer Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDataTransferPackageGenericResource]: {
                         text: {
                             withServiceName: [
                                "data transfer package",
                                "data transfer packages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Transfer Package",
                                "Data Transfer Packages"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "database",
                                "databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database",
                                "Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDatabaseSoftwareImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "database software image",
                                "database software images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database Software Image",
                                "Database Software Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDatabaseToolsConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "database tools connection",
                                "database tools connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database Tools Connection",
                                "Database Tools Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDatabaseToolsPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "database tools private endpoint",
                                "database tools private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database Tools Private Endpoint",
                                "Database Tools Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbCloudSchedulingPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "db cloud scheduling policy",
                                "db cloud scheduling policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Cloud Scheduling Policy",
                                "Db Cloud Scheduling Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbCloudSchedulingWindowGenericResource]: {
                         text: {
                             withServiceName: [
                                "db cloud scheduling window",
                                "db cloud scheduling windows"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Cloud Scheduling Window",
                                "Db Cloud Scheduling Windows"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbHomeGenericResource]: {
                         text: {
                             withServiceName: [
                                "db home",
                                "db homes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Home",
                                "Db Homes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbKeyStoreGenericResource]: {
                         text: {
                             withServiceName: [
                                "db key store",
                                "db key stores"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Key Store",
                                "Db Key Stores"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbNfsStorageGenericResource]: {
                         text: {
                             withServiceName: [
                                "db nfs storage",
                                "db nfs storages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Nfs Storage",
                                "Db Nfs Storages"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbNodeConsoleConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "db node console connection",
                                "db node console connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Node Console Connection",
                                "Db Node Console Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbNodeConsoleHistoryGenericResource]: {
                         text: {
                             withServiceName: [
                                "db node console history",
                                "db node console histories"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Node Console History",
                                "Db Node Console Histories"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbNodeGenericResource]: {
                         text: {
                             withServiceName: [
                                "db node",
                                "db nodes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Node",
                                "Db Nodes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "db server",
                                "db servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db Server",
                                "Db Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbSystemGenericResource]: {
                         text: {
                             withServiceName: [
                                "db system",
                                "db systems"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Db System",
                                "Db Systems"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalAsmGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external asm",
                                "dbmgmt external asms"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Asm",
                                "Dbmgmt External Asms"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalAsmInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external asm instance",
                                "dbmgmt external asm instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Asm Instance",
                                "Dbmgmt External Asm Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external cluster",
                                "dbmgmt external clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Cluster",
                                "Dbmgmt External Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalClusterInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external cluster instance",
                                "dbmgmt external cluster instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Cluster Instance",
                                "Dbmgmt External Cluster Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalDbHomeGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external db home",
                                "dbmgmt external db homes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Db Home",
                                "Dbmgmt External Db Homes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalDbNodeGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external db node",
                                "dbmgmt external db nodes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Db Node",
                                "Dbmgmt External Db Nodes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalDbSystemConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external db system connector",
                                "dbmgmt external db system connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Db System Connector",
                                "Dbmgmt External Db System Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalDbSystemGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external db system",
                                "dbmgmt external db systems"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Db System",
                                "Dbmgmt External Db Systems"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalExadataInfrastructureGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external exadata infrastructure",
                                "dbmgmt external exadata infrastructures"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Exadata Infrastructure",
                                "Dbmgmt External Exadata Infrastructures"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalExadataStorageConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external exadata storage connector",
                                "dbmgmt external exadata storage connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Exadata Storage Connector",
                                "Dbmgmt External Exadata Storage Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalExadataStorageGridGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external exadata storage grid",
                                "dbmgmt external exadata storage grids"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Exadata Storage Grid",
                                "Dbmgmt External Exadata Storage Grids"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalExadataStorageServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external exadata storage server",
                                "dbmgmt external exadata storage servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Exadata Storage Server",
                                "Dbmgmt External Exadata Storage Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtExternalListenerGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt external listener",
                                "dbmgmt external listeners"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt External Listener",
                                "Dbmgmt External Listeners"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt job",
                                "dbmgmt jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt Job",
                                "Dbmgmt Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtManagedDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt managed database",
                                "dbmgmt managed databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt Managed Database",
                                "Dbmgmt Managed Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtManagedDatabaseGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt managed database group",
                                "dbmgmt managed database groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt Managed Database Group",
                                "Dbmgmt Managed Database Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtNamedCredentialGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt named credential",
                                "dbmgmt named credentials"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt Named Credential",
                                "Dbmgmt Named Credentials"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDbmgmtPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "dbmgmt private endpoint",
                                "dbmgmt private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dbmgmt Private Endpoint",
                                "Dbmgmt Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDcmsEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "dcms endpoint",
                                "dcms endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DCMS Endpoint",
                                "DCMS Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDcmsRegistryGenericResource]: {
                         text: {
                             withServiceName: [
                                "dcms registry",
                                "dcms registries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DCMS Registry",
                                "DCMS Registries"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDedicatedVmHostGenericResource]: {
                         text: {
                             withServiceName: [
                                "dedicated vm host",
                                "dedicated vm hosts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dedicated Vm Host",
                                "Dedicated Vm Hosts"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDelegationManagementDelegatedResourceAccessRequestGenericResource]: {
                         text: {
                             withServiceName: [
                                "delegation management delegated resource access request",
                                "delegation management delegated resource access requests"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Delegation Management Delegated Resource Access Request",
                                "Delegation Management Delegated Resource Access Requests"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDelegationManagementDelegationControlGenericResource]: {
                         text: {
                             withServiceName: [
                                "delegation management delegation control",
                                "delegation management delegation controls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Delegation Management Delegation Control",
                                "Delegation Management Delegation Controls"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDelegationManagementDelegationSubscriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "delegation management delegation subscription",
                                "delegation management delegation subscriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Delegation Management Delegation Subscription",
                                "Delegation Management Delegation Subscriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDesktopPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "desktop pool",
                                "desktop pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Desktop Pool",
                                "Desktop Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsBuildPipelineGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops build pipeline",
                                "dev ops build pipelines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Build Pipeline",
                                "Dev Ops Build Pipelines"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsBuildPipelineStageGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops build pipeline stage",
                                "dev ops build pipeline stages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Build Pipeline Stage",
                                "Dev Ops Build Pipeline Stages"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsBuildRunGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops build run",
                                "dev ops build runs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Build Run",
                                "Dev Ops Build Runs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops connection",
                                "dev ops connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Connection",
                                "Dev Ops Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsDeployArtifactGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops deploy artifact",
                                "dev ops deploy artifacts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Deploy Artifact",
                                "Dev Ops Deploy Artifacts"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsDeployEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops deploy environment",
                                "dev ops deploy environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Deploy Environment",
                                "Dev Ops Deploy Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsDeployPipelineGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops deploy pipeline",
                                "dev ops deploy pipelines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Deploy Pipeline",
                                "Dev Ops Deploy Pipelines"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsDeployStageGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops deploy stage",
                                "dev ops deploy stages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Deploy Stage",
                                "Dev Ops Deploy Stages"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops deployment",
                                "dev ops deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Deployment",
                                "Dev Ops Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops project",
                                "dev ops projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Project",
                                "Dev Ops Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsRepositoryGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops repository",
                                "dev ops repositories"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Repository",
                                "Dev Ops Repositories"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDevOpsTriggerGenericResource]: {
                         text: {
                             withServiceName: [
                                "dev ops trigger",
                                "dev ops triggers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dev Ops Trigger",
                                "Dev Ops Triggers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDhcpOptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "dhcp option",
                                "dhcp options"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DHCP Option",
                                "DHCP Options"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDisWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "dis workspace",
                                "dis workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DIS Workspace",
                                "DIS Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDnsPolicyAttachmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns policy attachment",
                                "dns policy attachments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns Policy Attachment",
                                "Dns Policy Attachments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDnsPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns policy",
                                "dns policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns Policy",
                                "Dns Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDnsResolverGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns resolver",
                                "dns resolvers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns Resolver",
                                "Dns Resolvers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDnsTsigKeyGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns tsig key",
                                "dns tsig keys"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns Tsig Key",
                                "Dns Tsig Keys"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDnsViewGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns view",
                                "dns views"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns View",
                                "Dns Views"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDrPlanExecutionGenericResource]: {
                         text: {
                             withServiceName: [
                                "dr plan execution",
                                "dr plan executions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dr Plan Execution",
                                "Dr Plan Executions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDrPlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "dr plan",
                                "dr plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dr Plan",
                                "Dr Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDrProtectionGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "dr protection group",
                                "dr protection groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dr Protection Group",
                                "Dr Protection Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDrgAttachmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "drg attachment",
                                "drg attachments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Drg Attachment",
                                "Drg Attachments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDrgGenericResource]: {
                         text: {
                             withServiceName: [
                                "drg",
                                "drgs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Drg",
                                "Drgs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDrgRouteDistributionGenericResource]: {
                         text: {
                             withServiceName: [
                                "drg route distribution",
                                "drg route distributions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Drg Route Distribution",
                                "Drg Route Distributions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDrgRouteTableGenericResource]: {
                         text: {
                             withServiceName: [
                                "drg route table",
                                "drg route tables"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Drg Route Table",
                                "Drg Route Tables"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciDynamicResourceGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "dynamic resource group",
                                "dynamic resource groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dynamic Resource Group",
                                "Dynamic Resource Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciEdmcsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "edmcs saas environment",
                                "edmcs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edmcs Saas Environment",
                                "Edmcs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciEkmsPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "ekms private endpoint",
                                "ekms private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ekms Private Endpoint",
                                "Ekms Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciEmailDkimGenericResource]: {
                         text: {
                             withServiceName: [
                                "email dkim",
                                "email dkims"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Email Dkim",
                                "Email Dkims"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciEmailDomainGenericResource]: {
                         text: {
                             withServiceName: [
                                "email domain",
                                "email domains"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Email Domain",
                                "Email Domains"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciEmailReturnPathGenericResource]: {
                         text: {
                             withServiceName: [
                                "email return path",
                                "email return paths"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Email Return Path",
                                "Email Return Paths"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciEmailSenderGenericResource]: {
                         text: {
                             withServiceName: [
                                "email sender",
                                "email senders"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Email Sender",
                                "Email Senders"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciEprcsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "eprcs saas environment",
                                "eprcs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Eprcs Saas Environment",
                                "Eprcs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciEventRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "event rule",
                                "event rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Event Rule",
                                "Event Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciExadataInfrastructureGenericResource]: {
                         text: {
                             withServiceName: [
                                "exadata infrastructure",
                                "exadata infrastructures"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Exadata Infrastructure",
                                "Exadata Infrastructures"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciExadbVmClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "exadb vm cluster",
                                "exadb vm clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Exadb Vm Cluster",
                                "Exadb Vm Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciExascaleDbStorageVaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "exascale db storage vault",
                                "exascale db storage vaults"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Exascale Db Storage Vault",
                                "Exascale Db Storage Vaults"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciExportGenericResource]: {
                         text: {
                             withServiceName: [
                                "export",
                                "exports"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Export",
                                "Exports"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciExternalContainerDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "external container database",
                                "external container databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "External Container Database",
                                "External Container Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciExternalDatabaseConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "external database connector",
                                "external database connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "External Database Connector",
                                "External Database Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciExternalNonContainerDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "external non container database",
                                "external non container databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "External Non Container Database",
                                "External Non Container Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciExternalPluggableDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "external pluggable database",
                                "external pluggable databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "External Pluggable Database",
                                "External Pluggable Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFamscompliancepolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "famscompliancepolicy",
                                "famscompliancepolicies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Famscompliancepolicy",
                                "Famscompliancepolicies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFamsfleetGenericResource]: {
                         text: {
                             withServiceName: [
                                "famsfleet",
                                "famsfleets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Famsfleet",
                                "Famsfleets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFamsmaintenancewindowGenericResource]: {
                         text: {
                             withServiceName: [
                                "famsmaintenancewindow",
                                "famsmaintenancewindows"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Famsmaintenancewindow",
                                "Famsmaintenancewindows"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFamspatchGenericResource]: {
                         text: {
                             withServiceName: [
                                "famspatch",
                                "famspatches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Famspatch",
                                "Famspatches"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFamsplatformconfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "famsplatformconfiguration",
                                "famsplatformconfigurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Famsplatformconfiguration",
                                "Famsplatformconfigurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFamsrunbookGenericResource]: {
                         text: {
                             withServiceName: [
                                "famsrunbook",
                                "famsrunbooks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Famsrunbook",
                                "Famsrunbooks"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFamsschedulerdefinitionGenericResource]: {
                         text: {
                             withServiceName: [
                                "famsschedulerdefinition",
                                "famsschedulerdefinitions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Famsschedulerdefinition",
                                "Famsschedulerdefinitions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFawServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "faw service",
                                "faw services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Faw Service",
                                "Faw Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFileSystemGenericResource]: {
                         text: {
                             withServiceName: [
                                "file system",
                                "file systems"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "File System",
                                "File Systems"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFilesystemSnapshotPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "filesystem snapshot policy",
                                "filesystem snapshot policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Filesystem Snapshot Policy",
                                "Filesystem Snapshot Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsGbuFccmamlcsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "fs gbu fccmamlcs saas environment",
                                "fs gbu fccmamlcs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fs Gbu Fccmamlcs Saas Environment",
                                "Fs Gbu Fccmamlcs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsGbuObcsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "fs gbu obcs saas environment",
                                "fs gbu obcs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fs Gbu Obcs Saas Environment",
                                "Fs Gbu Obcs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsgbuascsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsgbuascs saas environment",
                                "fsgbuascs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsgbuascs Saas Environment",
                                "Fsgbuascs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsgbuccaSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsgbucca saas environment",
                                "fsgbucca saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsgbucca Saas Environment",
                                "Fsgbucca Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsgbuerfSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsgbuerf saas environment",
                                "fsgbuerf saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsgbuerf Saas Environment",
                                "Fsgbuerf Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsgbuinsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsgbuins saas environment",
                                "fsgbuins saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsgbuins Saas Environment",
                                "Fsgbuins Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsgbupbsmSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsgbupbsm saas environment",
                                "fsgbupbsm saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsgbupbsm Saas Environment",
                                "Fsgbupbsm Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFssReplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "fss replication",
                                "fss replications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fss Replication",
                                "Fss Replications"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFssReplicationTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "fss replication target",
                                "fss replication targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fss Replication Target",
                                "Fss Replication Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsuActionGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsu action",
                                "fsu actions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsu Action",
                                "Fsu Actions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsuCollectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsu collection",
                                "fsu collections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsu Collection",
                                "Fsu Collections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsuCycleGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsu cycle",
                                "fsu cycles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsu Cycle",
                                "Fsu Cycles"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsuDiscoveryGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsu discovery",
                                "fsu discoveries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsu Discovery",
                                "Fsu Discoveries"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFsuJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "fsu job",
                                "fsu jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fsu Job",
                                "Fsu Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFunctionsApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "functions application",
                                "functions applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Functions Application",
                                "Functions Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFunctionsFunctionGenericResource]: {
                         text: {
                             withServiceName: [
                                "function",
                                "functions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Function",
                                "Functions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFusionEnvironmentFamilyGenericResource]: {
                         text: {
                             withServiceName: [
                                "fusion environment family",
                                "fusion environment families"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fusion Environment Family",
                                "Fusion Environment Families"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciFusionEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "fusion environment",
                                "fusion environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fusion Environment",
                                "Fusion Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGenAiAgentDevelopmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "gen ai agent development",
                                "gen ai agent developments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gen Ai Agent Development",
                                "Gen Ai Agent Developments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGenerativeAiDedicatedAiClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "generative ai dedicated ai cluster",
                                "generative ai dedicated ai clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Generative Ai Dedicated Ai Cluster",
                                "Generative Ai Dedicated Ai Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGenerativeAiEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "generative ai endpoint",
                                "generative ai endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Generative Ai Endpoint",
                                "Generative Ai Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGenerativeAiModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "generative ai model",
                                "generative ai models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Generative Ai Model",
                                "Generative Ai Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGoldenGateConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "golden gate connection",
                                "golden gate connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Golden Gate Connection",
                                "Golden Gate Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGoldenGateDatabaseRegistrationGenericResource]: {
                         text: {
                             withServiceName: [
                                "golden gate database registration",
                                "golden gate database registrations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Golden Gate Database Registration",
                                "Golden Gate Database Registrations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGoldenGateDeploymentBackupGenericResource]: {
                         text: {
                             withServiceName: [
                                "golden gate deployment backup",
                                "golden gate deployment backups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Golden Gate Deployment Backup",
                                "Golden Gate Deployment Backups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGoldenGateDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "golden gate deployment",
                                "golden gate deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Golden Gate Deployment",
                                "Golden Gate Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "group",
                                "groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Group",
                                "Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciHttpRedirectGenericResource]: {
                         text: {
                             withServiceName: [
                                "http redirect",
                                "http redirects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Http Redirect",
                                "Http Redirects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciIdentityProviderGenericResource]: {
                         text: {
                             withServiceName: [
                                "identity provider",
                                "identity providers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Identity Provider",
                                "Identity Providers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "image",
                                "images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Image",
                                "Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciIngressGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "ingress gateway",
                                "ingress gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ingress Gateway",
                                "Ingress Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciIngressGatewayRouteTableGenericResource]: {
                         text: {
                             withServiceName: [
                                "ingress gateway route table",
                                "ingress gateway route tables"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ingress Gateway Route Table",
                                "Ingress Gateway Route Tables"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciInstanceConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance configuration",
                                "instance configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance Configuration",
                                "Instance Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance",
                                "instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance",
                                "Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciInstancePoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance pool",
                                "instance pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance Pool",
                                "Instance Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciIntegrationInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "integration instance",
                                "integration instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Integration Instance",
                                "Integration Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciInternetGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "internet gateway",
                                "internet gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Internet Gateway",
                                "Internet Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciInventoryAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "inventory asset",
                                "inventory assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Inventory Asset",
                                "Inventory Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciIpSecConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip sec connection",
                                "ip sec connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IP Sec Connection",
                                "IP Sec Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciIpv6GenericResource]: {
                         text: {
                             withServiceName: [
                                "ipv 6",
                                "ipv 6s"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ipv 6",
                                "Ipv 6s"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciJmsFleetGenericResource]: {
                         text: {
                             withServiceName: [
                                "jms fleet",
                                "jms fleets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Jms Fleet",
                                "Jms Fleets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciKafkaClusterConfigGenericResource]: {
                         text: {
                             withServiceName: [
                                "kafka cluster config",
                                "kafka cluster configs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kafka Cluster Config",
                                "Kafka Cluster Configs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciKafkaClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "kafka cluster",
                                "kafka clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kafka Cluster",
                                "Kafka Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciKeyGenericResource]: {
                         text: {
                             withServiceName: [
                                "key",
                                "keys"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Key",
                                "Keys"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciKmsHsmClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "kms hsm cluster",
                                "kms hsm clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kms Hsm Cluster",
                                "Kms Hsm Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciKmsHsmPartitionGenericResource]: {
                         text: {
                             withServiceName: [
                                "kms hsm partition",
                                "kms hsm partitions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kms Hsm Partition",
                                "Kms Hsm Partitions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLicenseManagerLicenseRecordGenericResource]: {
                         text: {
                             withServiceName: [
                                "license manager license record",
                                "license manager license records"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "License Manager License Record",
                                "License Manager License Records"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLicenseManagerProductLicenseGenericResource]: {
                         text: {
                             withServiceName: [
                                "license manager product license",
                                "license manager product licenses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "License Manager Product License",
                                "License Manager Product Licenses"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLoadBalancerGenericResource]: {
                         text: {
                             withServiceName: [
                                "load balancer",
                                "load balancers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Load Balancer",
                                "Load Balancers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLocalPeeringGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "local peering gateway",
                                "local peering gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Local Peering Gateway",
                                "Local Peering Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLogAnalyticsEntityGenericResource]: {
                         text: {
                             withServiceName: [
                                "log analytics entity",
                                "log analytics entities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Log Analytics Entity",
                                "Log Analytics Entities"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLogAsyncSearchGenericResource]: {
                         text: {
                             withServiceName: [
                                "log async search",
                                "log async searches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Log Async Search",
                                "Log Async Searches"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLogDataModelGenericResource]: {
                         text: {
                             withServiceName: [
                                "log data model",
                                "log data models"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Log Data Model",
                                "Log Data Models"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLogFireSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "log fire saas environment",
                                "log fire saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Log Fire Saas Environment",
                                "Log Fire Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLogGenericResource]: {
                         text: {
                             withServiceName: [
                                "log",
                                "logs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Log",
                                "Logs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLogGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "log group",
                                "log groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Log Group",
                                "Log Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLogRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "log rule",
                                "log rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Log Rule",
                                "Log Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciLogSavedSearchGenericResource]: {
                         text: {
                             withServiceName: [
                                "log saved search",
                                "log saved searches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Log Saved Search",
                                "Log Saved Searches"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciManagementAgentGenericResource]: {
                         text: {
                             withServiceName: [
                                "management agent",
                                "management agents"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Management Agent",
                                "Management Agents"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciManagementAgentInstallKeyGenericResource]: {
                         text: {
                             withServiceName: [
                                "management agent install key",
                                "management agent install keys"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Management Agent Install Key",
                                "Management Agent Install Keys"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMaxymiserSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "maxymiser saas environment",
                                "maxymiser saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Maxymiser Saas Environment",
                                "Maxymiser Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMediaAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "media asset",
                                "media assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Media Asset",
                                "Media Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMediaWorkflowConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "media workflow configuration",
                                "media workflow configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Media Workflow Configuration",
                                "Media Workflow Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMediaWorkflowGenericResource]: {
                         text: {
                             withServiceName: [
                                "media workflow",
                                "media workflows"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Media Workflow",
                                "Media Workflows"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMediaWorkflowJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "media workflow job",
                                "media workflow jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Media Workflow Job",
                                "Media Workflow Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMeshGenericResource]: {
                         text: {
                             withServiceName: [
                                "mesh",
                                "meshes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mesh",
                                "Meshes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMigrationGenericResource]: {
                         text: {
                             withServiceName: [
                                "migration",
                                "migrations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migration",
                                "Migrations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMigrationPlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "migration plan",
                                "migration plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migration Plan",
                                "Migration Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMktPubArtifactGenericResource]: {
                         text: {
                             withServiceName: [
                                "mkt pub artifact",
                                "mkt pub artifacts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mkt Pub Artifact",
                                "Mkt Pub Artifacts"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMktPubListingGenericResource]: {
                         text: {
                             withServiceName: [
                                "mkt pub listing",
                                "mkt pub listings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mkt Pub Listing",
                                "Mkt Pub Listings"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMktPubListingRevisionGenericResource]: {
                         text: {
                             withServiceName: [
                                "mkt pub listing revision",
                                "mkt pub listing revisions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mkt Pub Listing Revision",
                                "Mkt Pub Listing Revisions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMktPubTermGenericResource]: {
                         text: {
                             withServiceName: [
                                "mkt pub term",
                                "mkt pub terms"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mkt Pub Term",
                                "Mkt Pub Terms"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMktPubTermVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "mkt pub term version",
                                "mkt pub term versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mkt Pub Term Version",
                                "Mkt Pub Term Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMockCimImSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "mock cim im saas environment",
                                "mock cim im saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mock Cim Im Saas Environment",
                                "Mock Cim Im Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciMountTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "mount target",
                                "mount targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mount Target",
                                "Mount Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciNatGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "nat gateway",
                                "nat gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Nat Gateway",
                                "Nat Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciNetworkFirewallGenericResource]: {
                         text: {
                             withServiceName: [
                                "network firewall",
                                "network firewalls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Firewall",
                                "Network Firewalls"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciNetworkFirewallPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "network firewall policy",
                                "network firewall policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Firewall Policy",
                                "Network Firewall Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciNetworkSecurityGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "network security group",
                                "network security groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Security Group",
                                "Network Security Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciNoSqlTableGenericResource]: {
                         text: {
                             withServiceName: [
                                "no sql table",
                                "no sql tables"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "No SQL Table",
                                "No SQL Tables"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbAgentDependencyGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb agent dependency",
                                "ocb agent dependencies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Agent Dependency",
                                "Ocb Agent Dependencies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbAgentGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb agent",
                                "ocb agents"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Agent",
                                "Ocb Agents"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbAssetSourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb asset source",
                                "ocb asset sources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Asset Source",
                                "Ocb Asset Sources"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbAwsEbsAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb aws ebs asset",
                                "ocb aws ebs assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Aws Ebs Asset",
                                "Ocb Aws Ebs Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbAwsEcTwoAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb aws ec two asset",
                                "ocb aws ec two assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Aws Ec Two Asset",
                                "Ocb Aws Ec Two Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbDiscoveryScheduleGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb discovery schedule",
                                "ocb discovery schedules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Discovery Schedule",
                                "Ocb Discovery Schedules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb environment",
                                "ocb environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Environment",
                                "Ocb Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbInventoryGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb inventory",
                                "ocb inventories"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Inventory",
                                "Ocb Inventories"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbInventoryRelationGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb inventory relation",
                                "ocb inventory relations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Inventory Relation",
                                "Ocb Inventory Relations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbOracleDbAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb oracle db asset",
                                "ocb oracle db assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Oracle Db Asset",
                                "Ocb Oracle Db Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbVmAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb vm asset",
                                "ocb vm assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Vm Asset",
                                "Ocb Vm Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOcbVmwareVmAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "ocb vmware vm asset",
                                "ocb vmware vm assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ocb Vmware Vm Asset",
                                "Ocb Vmware Vm Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOccAvailabilityCatalogGenericResource]: {
                         text: {
                             withServiceName: [
                                "occ availability catalog",
                                "occ availability catalogs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Occ Availability Catalog",
                                "Occ Availability Catalogs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOccCapacityRequestGenericResource]: {
                         text: {
                             withServiceName: [
                                "occ capacity request",
                                "occ capacity requests"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Occ Capacity Request",
                                "Occ Capacity Requests"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOceInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "oce instance",
                                "oce instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oce Instance",
                                "Oce Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOdaInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "oda instance",
                                "oda instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oda Instance",
                                "Oda Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOdaPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "oda private endpoint",
                                "oda private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oda Private Endpoint",
                                "Oda Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOdmsAgentGenericResource]: {
                         text: {
                             withServiceName: [
                                "odms agent",
                                "odms agents"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Odms Agent",
                                "Odms Agents"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOdmsConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "odms connection",
                                "odms connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Odms Connection",
                                "Odms Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOdmsJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "odms job",
                                "odms jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Odms Job",
                                "Odms Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOdmsMigrationGenericResource]: {
                         text: {
                             withServiceName: [
                                "odms migration",
                                "odms migrations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Odms Migration",
                                "Odms Migrations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOneoffPatchGenericResource]: {
                         text: {
                             withServiceName: [
                                "oneoff patch",
                                "oneoff patches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oneoff Patch",
                                "Oneoff Patches"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOnsPhoneApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "ons phone application",
                                "ons phone applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ons Phone Application",
                                "Ons Phone Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOnsPhoneNumberGenericResource]: {
                         text: {
                             withServiceName: [
                                "ons phone number",
                                "ons phone numbers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ons Phone Number",
                                "Ons Phone Numbers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOnsSubscriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "ons subscription",
                                "ons subscriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ons Subscription",
                                "Ons Subscriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOnsTopicGenericResource]: {
                         text: {
                             withServiceName: [
                                "ons topic",
                                "ons topics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ons Topic",
                                "Ons Topics"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOpctlOperatorControlAssignmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "opctl operator control assignment",
                                "opctl operator control assignments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Opctl Operator Control Assignment",
                                "Opctl Operator Control Assignments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOpctlOperatorControlGenericResource]: {
                         text: {
                             withServiceName: [
                                "opctl operator control",
                                "opctl operator controls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Opctl Operator Control",
                                "Opctl Operator Controls"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOpsiDatabaseInsightGenericResource]: {
                         text: {
                             withServiceName: [
                                "opsi database insight",
                                "opsi database insights"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Opsi Database Insight",
                                "Opsi Database Insights"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOracleDbAzureBlobContainerGenericResource]: {
                         text: {
                             withServiceName: [
                                "oracle db azure blob container",
                                "oracle db azure blob containers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oracle Db Azure Blob Container",
                                "Oracle Db Azure Blob Containers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOracleDbAzureBlobMountGenericResource]: {
                         text: {
                             withServiceName: [
                                "oracle db azure blob mount",
                                "oracle db azure blob mounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oracle Db Azure Blob Mount",
                                "Oracle Db Azure Blob Mounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOracleDbAzureConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "oracle db azure connector",
                                "oracle db azure connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oracle Db Azure Connector",
                                "Oracle Db Azure Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOracleDbAzureVaultAssociationGenericResource]: {
                         text: {
                             withServiceName: [
                                "oracle db azure vault association",
                                "oracle db azure vault associations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oracle Db Azure Vault Association",
                                "Oracle Db Azure Vault Associations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOracleDbAzureVaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "oracle db azure vault",
                                "oracle db azure vaults"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oracle Db Azure Vault",
                                "Oracle Db Azure Vaults"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOrganizationsGovernanceRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "organizations governance rule",
                                "organizations governance rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Organizations Governance Rule",
                                "Organizations Governance Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOrmConfigSourceProviderGenericResource]: {
                         text: {
                             withServiceName: [
                                "orm config source provider",
                                "orm config source providers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Orm Config Source Provider",
                                "Orm Config Source Providers"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOrmJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "orm job",
                                "orm jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Orm Job",
                                "Orm Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOrmPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "orm private endpoint",
                                "orm private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Orm Private Endpoint",
                                "Orm Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOrmStackGenericResource]: {
                         text: {
                             withServiceName: [
                                "orm stack",
                                "orm stacks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Orm Stack",
                                "Orm Stacks"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOrmTemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "orm template",
                                "orm templates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Orm Template",
                                "Orm Templates"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsdPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "osd private endpoint",
                                "osd private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osd Private Endpoint",
                                "Osd Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsdShardedDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "osd sharded database",
                                "osd sharded databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osd Sharded Database",
                                "Osd Sharded Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsfpcsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "osfpcs saas environment",
                                "osfpcs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osfpcs Saas Environment",
                                "Osfpcs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmhLifecycleEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "osmh lifecycle environment",
                                "osmh lifecycle environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osmh Lifecycle Environment",
                                "Osmh Lifecycle Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmhLifecycleStageGenericResource]: {
                         text: {
                             withServiceName: [
                                "osmh lifecycle stage",
                                "osmh lifecycle stages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osmh Lifecycle Stage",
                                "Osmh Lifecycle Stages"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmhManagedInstanceGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "osmh managed instance group",
                                "osmh managed instance groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osmh Managed Instance Group",
                                "Osmh Managed Instance Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmhManagementStationGenericResource]: {
                         text: {
                             withServiceName: [
                                "osmh management station",
                                "osmh management stations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osmh Management Station",
                                "Osmh Management Stations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmhProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "osmh profile",
                                "osmh profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osmh Profile",
                                "Osmh Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmhScheduledJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "osmh scheduled job",
                                "osmh scheduled jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osmh Scheduled Job",
                                "Osmh Scheduled Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmhSoftwareSourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "osmh software source",
                                "osmh software sources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osmh Software Source",
                                "Osmh Software Sources"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmsManagedInstanceGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "osms managed instance group",
                                "osms managed instance groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osms Managed Instance Group",
                                "Osms Managed Instance Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmsScheduledJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "osms scheduled job",
                                "osms scheduled jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osms Scheduled Job",
                                "Osms Scheduled Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOsmsSoftwareSourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "osms software source",
                                "osms software sources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Osms Software Source",
                                "Osms Software Sources"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOtmgtmSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "otmgtm saas environment",
                                "otmgtm saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Otmgtm Saas Environment",
                                "Otmgtm Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciOutboundConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "outbound connector",
                                "outbound connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Outbound Connector",
                                "Outbound Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPathAnalyzerTestGenericResource]: {
                         text: {
                             withServiceName: [
                                "path analyzer test",
                                "path analyzer tests"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Path Analyzer Test",
                                "Path Analyzer Tests"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPcmcsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "pcmcs saas environment",
                                "pcmcs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Pcmcs Saas Environment",
                                "Pcmcs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPlanningSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "planning saas environment",
                                "planning saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Planning Saas Environment",
                                "Planning Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPluggableDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "pluggable database",
                                "pluggable databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Pluggable Database",
                                "Pluggable Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "policy",
                                "policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Policy",
                                "Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPostgresqlBackupGenericResource]: {
                         text: {
                             withServiceName: [
                                "postgresql backup",
                                "postgresql backups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Postgresql Backup",
                                "Postgresql Backups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPostgresqlConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "postgresql configuration",
                                "postgresql configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Postgresql Configuration",
                                "Postgresql Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPostgresqlDbSystemGenericResource]: {
                         text: {
                             withServiceName: [
                                "postgresql db system",
                                "postgresql db systems"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Postgresql Db System",
                                "Postgresql Db Systems"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPriceListGenericResource]: {
                         text: {
                             withServiceName: [
                                "price list",
                                "price lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Price List",
                                "Price Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPriceListItemGenericResource]: {
                         text: {
                             withServiceName: [
                                "price list item",
                                "price list items"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Price List Item",
                                "Price List Items"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPricingRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "pricing rule",
                                "pricing rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Pricing Rule",
                                "Pricing Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPrivateIpGenericResource]: {
                         text: {
                             withServiceName: [
                                "private ip",
                                "private ips"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Ip",
                                "Private Ips"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciProcessAutomationInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "process automation instance",
                                "process automation instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Process Automation Instance",
                                "Process Automation Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciProductGenericResource]: {
                         text: {
                             withServiceName: [
                                "product",
                                "products"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Product",
                                "Products"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciProtectedDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "protected database",
                                "protected databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Protected Database",
                                "Protected Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciProtectionPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "protection policy",
                                "protection policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Protection Policy",
                                "Protection Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPublicIpGenericResource]: {
                         text: {
                             withServiceName: [
                                "public ip",
                                "public ips"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Public Ip",
                                "Public Ips"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciPublicIpPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "public ip pool",
                                "public ip pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Public Ip Pool",
                                "Public Ip Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciQueryServiceProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "query service project",
                                "query service projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Query Service Project",
                                "Query Service Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciQueueConsumerGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "queue consumer group",
                                "queue consumer groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Queue Consumer Group",
                                "Queue Consumer Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciQueueGenericResource]: {
                         text: {
                             withServiceName: [
                                "queue",
                                "queues"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Queue",
                                "Queues"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciQuotumGenericResource]: {
                         text: {
                             withServiceName: [
                                "quotum",
                                "quota"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Quotum",
                                "Quota"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRcmsAppConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "rcms app configuration",
                                "rcms app configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rcms App Configuration",
                                "Rcms App Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRecoveryServiceSubnetGenericResource]: {
                         text: {
                             withServiceName: [
                                "recovery service subnet",
                                "recovery service subnets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Recovery Service Subnet",
                                "Recovery Service Subnets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRecoverySystemGenericResource]: {
                         text: {
                             withServiceName: [
                                "recovery system",
                                "recovery systems"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Recovery System",
                                "Recovery Systems"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRecoverySystemNetworkInterfaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "recovery system network interface",
                                "recovery system network interfaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Recovery System Network Interface",
                                "Recovery System Network Interfaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRecoverySystemPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "recovery system policy",
                                "recovery system policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Recovery System Policy",
                                "Recovery System Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRedisClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "redis cluster",
                                "redis clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Redis Cluster",
                                "Redis Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRemotePeeringConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "remote peering connection",
                                "remote peering connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Remote Peering Connection",
                                "Remote Peering Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciReplicationPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "replication policy",
                                "replication policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Replication Policy",
                                "Replication Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciReplicationScheduleGenericResource]: {
                         text: {
                             withServiceName: [
                                "replication schedule",
                                "replication schedules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Replication Schedule",
                                "Replication Schedules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciResourceScheduleGenericResource]: {
                         text: {
                             withServiceName: [
                                "resource schedule",
                                "resource schedules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Resource Schedule",
                                "Resource Schedules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRgbuCecsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "rgbu cecs saas environment",
                                "rgbu cecs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rgbu Cecs Saas Environment",
                                "Rgbu Cecs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRouteTableGenericResource]: {
                         text: {
                             withServiceName: [
                                "route table",
                                "route tables"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Route Table",
                                "Route Tables"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRoverClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "rover cluster",
                                "rover clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rover Cluster",
                                "Rover Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciRoverNodeGenericResource]: {
                         text: {
                             withServiceName: [
                                "rover node",
                                "rover nodes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rover Node",
                                "Rover Nodes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciSecurityAttributeNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "security attribute namespace",
                                "security attribute namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Security Attribute Namespace",
                                "Security Attribute Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciSecurityListGenericResource]: {
                         text: {
                             withServiceName: [
                                "security list",
                                "security lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Security List",
                                "Security Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciSecurityZonesSecurityPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "security zones security policy",
                                "security zones security policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Security Zones Security Policy",
                                "Security Zones Security Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciSecurityZonesSecurityRecipeGenericResource]: {
                         text: {
                             withServiceName: [
                                "security zones security recipe",
                                "security zones security recipes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Security Zones Security Recipe",
                                "Security Zones Security Recipes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciSecurityZonesSecurityZoneGenericResource]: {
                         text: {
                             withServiceName: [
                                "security zones security zone",
                                "security zones security zones"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Security Zones Security Zone",
                                "Security Zones Security Zones"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciServiceConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "service connector",
                                "service connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Connector",
                                "Service Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciServiceGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "service gateway",
                                "service gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Gateway",
                                "Service Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciShareGenericResource]: {
                         text: {
                             withServiceName: [
                                "share",
                                "shares"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Share",
                                "Shares"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciStackMonitoringResourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "stack monitoring resource",
                                "stack monitoring resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stack Monitoring Resource",
                                "Stack Monitoring Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciStatusServiceResourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "status service resource",
                                "status service resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Status Service Resource",
                                "Status Service Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciStorageGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage gateway",
                                "storage gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Gateway",
                                "Storage Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciStreamCdnConfigGenericResource]: {
                         text: {
                             withServiceName: [
                                "stream cdn config",
                                "stream cdn configs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stream Cdn Config",
                                "Stream Cdn Configs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciStreamDistributionChannelGenericResource]: {
                         text: {
                             withServiceName: [
                                "stream distribution channel",
                                "stream distribution channels"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stream Distribution Channel",
                                "Stream Distribution Channels"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciStreamGenericResource]: {
                         text: {
                             withServiceName: [
                                "stream",
                                "streams"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stream",
                                "Streams"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciStreamPackagingConfigGenericResource]: {
                         text: {
                             withServiceName: [
                                "stream packaging config",
                                "stream packaging configs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stream Packaging Config",
                                "Stream Packaging Configs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciSubnetGenericResource]: {
                         text: {
                             withServiceName: [
                                "subnet",
                                "subnets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Subnet",
                                "Subnets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciSubscriptionPricingConfigGenericResource]: {
                         text: {
                             withServiceName: [
                                "subscription pricing config",
                                "subscription pricing configs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Subscription Pricing Config",
                                "Subscription Pricing Configs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciTagDefaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "tag default",
                                "tag defaults"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Tag Default",
                                "Tag Defaults"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciTagNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "tag namespace",
                                "tag namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Tag Namespace",
                                "Tag Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciUGbuwacsSaasEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "u gbuwacs saas environment",
                                "u gbuwacs saas environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "U Gbuwacs Saas Environment",
                                "U Gbuwacs Saas Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciUnifiedAgentConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "unified agent configuration",
                                "unified agent configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Unified Agent Configuration",
                                "Unified Agent Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciUserGenericResource]: {
                         text: {
                             withServiceName: [
                                "user",
                                "users"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "User",
                                "Users"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "vault",
                                "vaults"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vault",
                                "Vaults"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVaultSecretGenericResource]: {
                         text: {
                             withServiceName: [
                                "vault secret",
                                "vault secrets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vault Secret",
                                "Vault Secrets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVbsInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "vbs instance",
                                "vbs instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vbs Instance",
                                "Vbs Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVcnGenericResource]: {
                         text: {
                             withServiceName: [
                                "vcn",
                                "vcns"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vcn",
                                "Vcns"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVirtualCircuitGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual circuit",
                                "virtual circuits"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Circuit",
                                "Virtual Circuits"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVirtualDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual deployment",
                                "virtual deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Deployment",
                                "Virtual Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVirtualServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual service",
                                "virtual services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Service",
                                "Virtual Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVirtualServiceRouteTableGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual service route table",
                                "virtual service route tables"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Service Route Table",
                                "Virtual Service Route Tables"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVisualBuilderInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "visual builder instance",
                                "visual builder instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Visual Builder Instance",
                                "Visual Builder Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "vlan",
                                "vlans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vlan",
                                "Vlans"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVmClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "vm cluster",
                                "vm clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vm Cluster",
                                "Vm Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVmClusterNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "vm cluster network",
                                "vm cluster networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vm Cluster Network",
                                "Vm Cluster Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVmwareBillingLinkGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware billing link",
                                "vmware billing links"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vmware Billing Link",
                                "Vmware Billing Links"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVmwareClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware cluster",
                                "vmware clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vmware Cluster",
                                "Vmware Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVmwareDatastoreClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware datastore cluster",
                                "vmware datastore clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vmware Datastore Cluster",
                                "Vmware Datastore Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVmwareDatastoreGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware datastore",
                                "vmware datastores"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vmware Datastore",
                                "Vmware Datastores"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVmwareEsxiHostGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware esxi host",
                                "vmware esxi hosts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vmware Esxi Host",
                                "Vmware Esxi Hosts"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVmwareSddcGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware sddc",
                                "vmware sddcs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vmware Sddc",
                                "Vmware Sddcs"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVnicGenericResource]: {
                         text: {
                             withServiceName: [
                                "vnic",
                                "vnics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vnic",
                                "Vnics"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVolumeBackupGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume backup",
                                "volume backups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume Backup",
                                "Volume Backups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVolumeBackupPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume backup policy",
                                "volume backup policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume Backup Policy",
                                "Volume Backup Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVolumeGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume",
                                "volumes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume",
                                "Volumes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVolumeGroupBackupGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume group backup",
                                "volume group backups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume Group Backup",
                                "Volume Group Backups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVolumeGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume group",
                                "volume groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume Group",
                                "Volume Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVolumeGroupReplicaGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume group replica",
                                "volume group replicas"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume Group Replica",
                                "Volume Group Replicas"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVolumeReplicaGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume replica",
                                "volume replicas"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume Replica",
                                "Volume Replicas"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVssContainerScanRecipeGenericResource]: {
                         text: {
                             withServiceName: [
                                "vss container scan recipe",
                                "vss container scan recipes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vss Container Scan Recipe",
                                "Vss Container Scan Recipes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVssContainerScanTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "vss container scan target",
                                "vss container scan targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vss Container Scan Target",
                                "Vss Container Scan Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVssHostScanRecipeGenericResource]: {
                         text: {
                             withServiceName: [
                                "vss host scan recipe",
                                "vss host scan recipes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vss Host Scan Recipe",
                                "Vss Host Scan Recipes"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciVssHostScanTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "vss host scan target",
                                "vss host scan targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vss Host Scan Target",
                                "Vss Host Scan Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWaasAddressListGenericResource]: {
                         text: {
                             withServiceName: [
                                "waas address list",
                                "waas address lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Waas Address List",
                                "Waas Address Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWaasCertificateGenericResource]: {
                         text: {
                             withServiceName: [
                                "waas certificate",
                                "waas certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Waas Certificate",
                                "Waas Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWaasCustomProtectionRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "waas custom protection rule",
                                "waas custom protection rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Waas Custom Protection Rule",
                                "Waas Custom Protection Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWaasPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "waas policy",
                                "waas policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Waas Policy",
                                "Waas Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWebAppAccelerationGenericResource]: {
                         text: {
                             withServiceName: [
                                "web app acceleration",
                                "web app accelerations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web App Acceleration",
                                "Web App Accelerations"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWebAppAccelerationPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "web app acceleration policy",
                                "web app acceleration policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web App Acceleration Policy",
                                "Web App Acceleration Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWebAppFirewallGenericResource]: {
                         text: {
                             withServiceName: [
                                "web app firewall",
                                "web app firewalls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web App Firewall",
                                "Web App Firewalls"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWebAppFirewallNetworkAddressListGenericResource]: {
                         text: {
                             withServiceName: [
                                "web app firewall network address list",
                                "web app firewall network address lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web App Firewall Network Address List",
                                "Web App Firewall Network Address Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWebAppFirewallPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "web app firewall policy",
                                "web app firewall policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web App Firewall Policy",
                                "Web App Firewall Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciWlmsWlsDomainGenericResource]: {
                         text: {
                             withServiceName: [
                                "wlms wls domain",
                                "wlms wls domains"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Wlms Wls Domain",
                                "Wlms Wls Domains"
                            ]
                         }
                    },
                    [Contract.TypeNames.OciZprPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "zpr policy",
                                "zpr policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Zpr Policy",
                                "Zpr Policies"
                            ]
                         }
                    }
                }
            }
        ));
}