import { Loading, Optional, PagedDropdownPage, useDebouncedEffect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { Contract, UdmController } from "../../../../../../../controllers";
import { ObjectTypeMetadataModelHelper } from "../../../../../../../utilities";
import { UdmObjectPropertyItem } from "../../../../UdmObjectPropertyItem";
import { UdmObjectPropertyFilterProps } from "../UdmObjectPropertyFilter";
import { Filter } from "./Filter";
import { ListFilterPopover } from "./ListFilterPopover";

type ListFilterProps =
    UdmObjectPropertyFilterProps & {
        filterItems?: any[];
        operators?: Contract.UdmQueryRuleOperator[];
    };

export function ListFilter({ objectTypeName, onClearClicked, onFilterChange, operators, propertyId, rule }: ListFilterProps) {
    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.udmObjectPropertyFilter.listFilter",
            () => ({
                empty: "No Items",
                itemCount: "{{itemCount | NumberFormatter.humanize}} options",
                or: "or"
            }));

    const [values, setValues] =
        useState<string[]>(
            _.isEmpty(rule.values)
                ? []
                : rule.values);
    const [operator, setOperator] = useState<Contract.UdmQueryRuleOperator>(rule.operator ?? Contract.UdmQueryRuleOperator.Equals);
    const objectProperty =
        useMemo(
            () =>
                _.find(
                    ObjectTypeMetadataModelHelper.get(objectTypeName)?.udmProperties,
                    property => property.id === rule.propertyId),
            [objectTypeName, rule.propertyId]);

    useDebouncedEffect(
        () => {
            if (operator && !_.isNil(values)) {
                onFilterChange({
                    operator,
                    value: values
                });
            }
        },
        500,
        [operator, values]);

    const selectionViewValue =
        !_.isEmpty(values)
            ? _.size(values) > 3
                ? <Typography sx={{ fontWeight: 500 }}>
                    {localization.itemCount({ itemCount: values.length })}
                </Typography>
                : <Stack
                    direction="row"
                    spacing={1}>
                    {_.map(
                        values,
                        (value, index) =>
                            <Typography
                                key={index}
                                noWrap={true}>
                                <Loading container="cell">
                                    <UdmObjectPropertyItem
                                        filter={true}
                                        item={value}
                                        objectId={value}
                                        objectProperty={objectProperty!}
                                        objectTypeName={objectTypeName}/>
                                </Loading>
                            </Typography>)}
                </Stack>
            : <Fragment/>;

    async function fetchItems(searchText: Optional<string>, skip: number) {
        const { hasMore, values } =
            await UdmController.getPropertyValuePage(
                new Contract.UdmControllerGetPropertyValuePageRequest(
                    30,
                    objectTypeName,
                    propertyId,
                    searchText || undefined,
                    skip));

        return new PagedDropdownPage(hasMore, values);
    }

    return (
        <Filter
            emptyValue={true}
            initialOperator={operator}
            operators={operators}
            propertyId={propertyId}
            ruleId={rule.id!}
            selectionViewValue={selectionViewValue}
            values={values}
            onClearClicked={onClearClicked}
            onOperatorChange={setOperator}>
            <ListFilterPopover
                emptyText={localization.empty()}
                getItemPage={fetchItems}
                objectProperty={objectProperty}
                objectTypeName={objectTypeName}
                setValues={setValues}
                values={values}/>
        </Filter>);
}