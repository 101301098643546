﻿import { NotValidIcon, SuccessIcon, Tooltip, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../common";

type StatusCellProps = {
    connected: boolean;
};

export function StatusCell({ connected }: StatusCellProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useEndpointConnectorItems.endpointConnectors.table.statusCell",
            () => ({
                connected: {
                    title: "Connected"
                },
                disconnected: {
                    title: "Disconnected",
                    tooltip: "Run the provided helm command on the designated Kubernetes cluster"
                }
            }));

    const theme = useTheme();
    return <Tooltip titleOrGetTitle={
        connected
            ? undefined
            : localization.disconnected.tooltip()}>
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            {connected
                ? <SuccessIcon
                    sx={{
                        color: theme.palette.success.main,
                        fontSize: "18px"
                    }}/>
                : <NotValidIcon
                    sx={{
                        color: theme.palette.warning.main,
                        fontSize: "18px"
                    }}/>}
            <Typography>
                {connected
                    ? localization.connected.title()
                    : localization.disconnected.title()}
            </Typography>
        </Stack>
    </Tooltip>;
}