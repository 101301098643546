import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntityContainersIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M9 15H5V19H9V15ZM5 13C3.89543 13 3 13.8954 3 15V19C3 20.1046 3.89543 21 5 21H9C10.1046 21 11 20.1046 11 19V15C11 13.8954 10.1046 13 9 13H5Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19 15H15V19H19V15ZM15 13C13.8954 13 13 13.8954 13 15V19C13 20.1046 13.8954 21 15 21H19C20.1046 21 21 20.1046 21 19V15C21 13.8954 20.1046 13 19 13H15Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M9 5H5V9H9V5ZM5 3C3.89543 3 3 3.89543 3 5V9C3 10.1046 3.89543 11 5 11H9C10.1046 11 11 10.1046 11 9V5C11 3.89543 10.1046 3 9 3H5Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19.1962 5.73205L15.7321 3.73205L13.7321 7.19615L17.1962 9.19615L19.1962 5.73205ZM16.7321 2C15.7755 1.44772 14.5523 1.77547 14 2.73205L12 6.19615C11.4477 7.15274 11.7755 8.37592 12.7321 8.9282L16.1962 10.9282C17.1527 11.4805 18.3759 11.1527 18.9282 10.1962L20.9282 6.73205C21.4805 5.77547 21.1527 4.55228 20.1962 4L16.7321 2Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}