import { getItemWithId, ItemWithId, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../controllers";
import { ObjectTypeMetadataModelHelper, TypeHelper } from "../../../utilities";

export class UdmQueryHelper {
    public static addRuleToRuleGroup(ruleGroup: Contract.UdmQueryRuleGroup, rule: Contract.UdmQueryRuleBase): Contract.UdmQueryRuleGroup {
        let updatedRules: Contract.UdmQueryRuleBase[];
        if (TypeHelper.extendOrImplement(rule.typeName, Contract.TypeNames.UdmQueryRuleGroup)) {
            updatedRules = [
                ...ruleGroup.rules,
                rule
            ];
        } else {
            const targetRuleGroup =
                _.find(
                    ruleGroup.rules,
                    ruleGroupRule => ruleGroupRule.typeName === Contract.TypeNames.UdmQueryRuleGroup) as Contract.UdmQueryRuleGroup;
            if (_.isNil(targetRuleGroup)) {
                updatedRules = [
                    ...ruleGroup.rules,
                    {
                        ...UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup),
                        rules: [rule]
                    } as Contract.UdmQueryRuleGroup
                ];
            } else {
                updatedRules =
                    _.map(
                        ruleGroup.rules,
                        ruleGroupRule =>
                            ruleGroupRule === targetRuleGroup
                                ? {
                                    ...targetRuleGroup,
                                    rules: [
                                        ...targetRuleGroup.rules,
                                        rule
                                    ]
                                }
                                : ruleGroupRule);
            }
        }

        return {
            ...ruleGroup,
            rules: updatedRules
        };
    }

    public static createQuery(): any {
        return new Contract.UdmQuery(
            undefined,
            {
                propertyIds: [],
                sort: undefined
            },
            UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup),
            "");
    }

    public static createQueryJoin(property?: Contract.UdmObjectProperty): Contract.UdmQueryJoin {
        return new Contract.UdmQueryJoin(
            undefined,
            {
                propertyIds:
                    _.isNil(property)
                        ? []
                        : _(ObjectTypeMetadataModelHelper.get(property.relation!.objectTypeName!).udmProperties!).
                            filter(property => !_.isNil(property?.options.priorityIndex)).
                            map(property => property.id).
                            value(),
                sort: undefined
            },
            UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup),
            property?.id ?? "" as any);
    }

    public static createEmptyRuleGroup(typeName: string) {
        return getItemWithId(
            typeName === Contract.TypeNames.UdmQueryRelationRuleGroup
                ? new Contract.UdmQueryRelationRuleGroup(
                    Contract.TypeNames.UdmQueryRelationRuleGroup,
                    "",
                    false,
                    Contract.UdmQueryRuleGroupOperator.And,
                    [],
                    "" as any)
                : new Contract.UdmQueryRuleGroup(
                    Contract.TypeNames.UdmQueryRuleGroup,
                    "",
                    false,
                    Contract.UdmQueryRuleGroupOperator.And,
                    []));
    }

    public static createRule(objectProperty: Contract.UdmObjectProperty) {
        function createOperatorQueryRule(operator: Contract.UdmQueryRuleOperator) {
            return getItemWithId(
                new Contract.UdmQueryRule(
                    Contract.TypeNames.UdmQueryRule,
                    operator,
                    objectProperty.id,
                    []));
        }

        switch (objectProperty.dataType) {
            case Contract.UdmObjectPropertyDataType.CommonActivity:
            case Contract.UdmObjectPropertyDataType.CommonEntityAttributes:
            case Contract.UdmObjectPropertyDataType.CommonPermissionCategories:
            case Contract.UdmObjectPropertyDataType.CommonSeverity:
            case Contract.UdmObjectPropertyDataType.CommonTenantType:
            case Contract.UdmObjectPropertyDataType.CommonSelfId:
            case Contract.UdmObjectPropertyDataType.CommonVendor:
            case Contract.UdmObjectPropertyDataType.CommonVulnerabilities:
            case Contract.UdmObjectPropertyDataType.InfraEnum:
            case Contract.UdmObjectPropertyDataType.InfraPortRange:
            case Contract.UdmObjectPropertyDataType.InfraString:
            case Contract.UdmObjectPropertyDataType.InfraUrl:
                return createOperatorQueryRule(Contract.UdmQueryRuleOperator.In);
            case Contract.UdmObjectPropertyDataType.CommonEntityTags:
            case Contract.UdmObjectPropertyDataType.InfraIpAddress:
                return createOperatorQueryRule(Contract.UdmQueryRuleOperator.Like);
            case Contract.UdmObjectPropertyDataType.CommonId:
                return {
                    ...UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRelationRuleGroup),
                    relationPropertyId: objectProperty.id
                };
            case Contract.UdmObjectPropertyDataType.InfraBoolean:
            case Contract.UdmObjectPropertyDataType.InfraByteSize:
            case Contract.UdmObjectPropertyDataType.InfraInteger:
            case Contract.UdmObjectPropertyDataType.InfraTimeSpan:
                return createOperatorQueryRule(Contract.UdmQueryRuleOperator.Equals);
            case Contract.UdmObjectPropertyDataType.InfraDate:
            case Contract.UdmObjectPropertyDataType.InfraDateTime:
                return createOperatorQueryRule(Contract.UdmQueryRuleOperator.Gte);
            default:
                throw new UnexpectedError("createQueryRule", objectProperty.dataType);
        }
    }

    public static isQueryJoin(query: Contract.UdmQueryBase) {
        return !_.isNil((query as Contract.UdmQueryJoin).propertyId);
    }

    public static getRuleGroupVariant(ruleGroup: Contract.UdmQueryRuleGroup) {
        if (_.isEmpty(ruleGroup.rules)) {
            return "line";
        }

        if (_(ruleGroup.rules).
            filter(rule => TypeHelper.extendOrImplement(rule.typeName, Contract.TypeNames.UdmQueryRuleGroup)).
            size() > 1) {
            return "multiline";
        }

        return _.some(
            ruleGroup.rules,
            rule => rule.typeName === Contract.TypeNames.UdmQueryRelationRuleGroup)
            ? "multiline"
            : "line";
    }

    public static removeRuleFromRuleGroup(ruleGroup: Contract.UdmQueryRuleGroup, rule: ItemWithId<Contract.UdmQueryRuleBase>): Contract.UdmQueryRuleGroup {
        return {
            ...ruleGroup,
            rules:
                _.filter(
                    ruleGroup.rules,
                    ruleGroupRule => (ruleGroupRule as ItemWithId<Contract.UdmQueryRuleBase>).id !== rule.id)
        };
    }

    public static replaceRuleInRuleGroup(ruleGroup: Contract.UdmQueryRuleGroup, rule: ItemWithId<Contract.UdmQueryRuleBase>): Contract.UdmQueryRuleGroup {
        return {
            ...ruleGroup,
            rules:
                _.map(
                    ruleGroup.rules,
                    ruleGroupRule =>
                        (ruleGroupRule as ItemWithId<Contract.UdmQueryRuleBase>).id === rule.id
                            ? rule
                            : ruleGroupRule)
        };
    }
}