import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useAwsResourceSnapshotTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsRdsNeptuneClusterSnapshotDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const snapshotTypeTranslator = useAwsResourceSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsRdsNeptuneClusterSnapshotDefinition",
            () => ({
                columns: {
                    storageSize: "Size",
                    type: "Snapshot Type"
                }
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.AwsRdsNeptuneCluster,
            {
                includeServiceName: true,
                variant: "title"
            });

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRdsNeptuneClusterSnapshotModel) => ({
                            [localization.columns.type()]: snapshotTypeTranslator((item.entity as Contract.AwsRdsNeptuneClusterSnapshot).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsResourceSnapshotType}
                                enumTypeTranslator={snapshotTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRdsNeptuneClusterSnapshotType}
                itemProperty={(item: Contract.EntityModel) => snapshotTypeTranslator((item.entity as Contract.AwsRdsNeptuneClusterSnapshot).type)}
                key={Contract.EntityModelProperty.AwsRdsNeptuneClusterSnapshotType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRdsNeptuneClusterSnapshotModel) => ({
                            [localization.columns.storageSize()]: NumberFormatter.storage((item.entity as Contract.AwsRdsNeptuneClusterSnapshot).storageSize)
                        })
                }}
                id={Contract.EntityModelProperty.AwsRdsSnapshotResourceStorageSize}
                itemProperty={(item: Contract.AwsResourceModel) => NumberFormatter.storage((item.entity as Contract.AwsRdsNeptuneClusterSnapshot).storageSize)}
                key={Contract.EntityModelProperty.AwsRdsSnapshotResourceStorageSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.storageSize()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsRdsNeptuneClusterSnapshotModel>(
                        Contract.TypeNames.AwsRdsNeptuneCluster,
                        item => [(item.entity as Contract.AwsRdsNeptuneClusterSnapshot).clusterId],
                        translatedClusterTypeName)}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsRdsNeptuneClusterSnapshotCluster)}
                                placeholder={translatedClusterTypeName}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRdsNeptuneClusterSnapshotCluster}
                key={Contract.EntityModelProperty.AwsRdsNeptuneClusterSnapshotCluster}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsRdsNeptuneClusterSnapshotModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AwsRdsNeptuneClusterSnapshot).clusterId}
                            entityTypeName={Contract.TypeNames.AwsRdsNeptuneCluster}
                            entityVariant="iconText"/>}
                title={translatedClusterTypeName}/>,
            commonAccessResourceDefinition.columns.accessLevelColumn,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsRdsNeptuneClusterSnapshotRequest(
                new Contract.EntityControllerGetEntityModelPageAwsRdsNeptuneClusterSnapshotRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsNeptuneClusterSnapshotCluster]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsNeptuneClusterSnapshotType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}