import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortDirection, DataTableSortType, Dialog, EmptyMessageText, makeContextProvider, map, optionalTableCell, StringHelper, TextValuesFilter, TimeFormatter, TimeHelper, useChangeEffect, useExecuteOperation, useLocalization, useUncaptureValue } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Fragment, useMemo, useRef, useState } from "react";
import { ConfigurationController, Contract, IdentityRoleFilter, scopeNodeModelStore, StorageHelper, useIdentityRoleTranslator, useScopeNameTranslator, useScopeNavigationViewContext } from "../../../../../../common";
import { ActionsCell, Add } from "./components";

class ApiKeysContext {
    constructor(
        public addOpen: boolean,
        public apiKeyDatas: Contract.ConfigurationControllerGetApiKeysResponseApiKeyData[],
        public executeGetApiKeys: Function0<Promise<void>>) {
    }
}

export const [useApiKeysContext, useSetApiKeysContext, useApiKeysContextProvider] = makeContextProvider<ApiKeysContext>();

export function ApiKeys() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [childScopeApiKeysEnabled, setChildScopeApiKeysEnabled] = useState(!StringHelper.isFalse(StorageHelper.customerConfigurationApiKeysFlatView.getValue()));
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const scopeParentScopeIds = new Set(scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds);

    const uncaptureChildScopeApiKeysEnabled = useUncaptureValue(childScopeApiKeysEnabled);
    const [{ apiKeyDatas }, executeGetApiKeys] =
        useExecuteOperation(
            [ApiKeys, scopeNodeModel.configuration.id],
            () =>
                uncaptureChildScopeApiKeysEnabled(
                    uncapturedChildScopeApiKeysEnabled =>
                        ConfigurationController.getApiKeys(
                            new Contract.ConfigurationControllerGetApiKeysRequest(
                                uncapturedChildScopeApiKeysEnabled,
                                scopeNodeModel.configuration.id))));

    const dataTableActionsRef = useRef<DataTableActions>();
    const [context, setContext, ContextProvider] =
        useApiKeysContextProvider(
            () =>
                new ApiKeysContext(
                    false,
                    apiKeyDatas,
                    executeGetApiKeys));

    useChangeEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    apiKeyDatas
                }));

            dataTableActionsRef.current!.reset({ refreshFilters: true });
        },
        [apiKeyDatas]);

    useChangeEffect(
        () => executeGetApiKeys(),
        [childScopeApiKeysEnabled]);

    const names =
        useMemo(
            () =>
                _(apiKeyDatas).
                    map(apiKeyData => apiKeyData.apiKey.name).
                    uniq().
                    value(),
            [apiKeyDatas]);

    const identityRoleTranslator = useIdentityRoleTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.apiKeys",
            () => ({
                actions: {
                    add: "Add Token",
                    childScopeApiKeysEnabled: "Flat View"
                },
                columns: {
                    creationTime: "Creation Time",
                    name: "Name",
                    role: "Role",
                    scope: "Scope",
                    usageTime: "Usage Time"
                },
                empty: {
                    withFilter: "No matching token",
                    withoutFilter: "No tokens"
                }
            }));
    return (
        <ContextProvider>
            {context.addOpen &&
                <Dialog
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOpen: false
                                }))}>
                    <Add/>
                </Dialog>}
            <DataTable
                actionsRef={dataTableActionsRef}
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
                }}
                fetchItems={
                    (filterMap, sort) =>
                        _(apiKeyDatas).
                            filter(
                                apiKeyData => {
                                    if (!_.isNil(filterMap[ApiKeysColumnId.Name]) &&
                                        !_.includes(filterMap[ApiKeysColumnId.Name].values, apiKeyData.apiKey.name)) {
                                        return false;
                                    }

                                    if (!_.isNil(filterMap[ApiKeysColumnId.Role]) &&
                                        !_.includes(filterMap[ApiKeysColumnId.Role].values, apiKeyData.role)) {
                                        return false;
                                    }

                                    return true;
                                }).
                            orderBy(
                                [
                                    apiKeyData =>
                                        map<string, number | string | undefined>(
                                            sort?.columnId ?? ApiKeysColumnId.Scope,
                                            {
                                                [ApiKeysColumnId.CreationTime]: () => TimeHelper.getSortable(apiKeyData.apiKey.systemCreationTime),
                                                [ApiKeysColumnId.Name]: () => StringHelper.getSortValue(apiKeyData.apiKey.name),
                                                [ApiKeysColumnId.Scope]: () => scopeNameTranslator(apiKeyData.scopeId, { path: true }),
                                                [ApiKeysColumnId.Role]: () => StringHelper.getSortValue(identityRoleTranslator(apiKeyData.role)),
                                                [ApiKeysColumnId.UsageTime]: () => TimeHelper.getSortable(apiKeyData.state.usageTime)
                                            }),
                                    apiKeyData => StringHelper.getSortValue(apiKeyData.apiKey.name)
                                ],
                                [
                                    sort?.direction === DataTableSortDirection.Descending
                                        ? "desc"
                                        : "asc",
                                    "asc"
                                ]).
                            value()}
                filtersOptions={{
                    persist: {
                        visibilityStorageItem: StorageHelper.customerConfigurationApiKeysFilters
                    }
                }}
                getItemId={(item: Contract.ConfigurationControllerGetApiKeysResponseApiKeyData) => item.apiKey.id + item.scopeId}>
                <DataTableAction>
                    <CheckButton
                        checked={childScopeApiKeysEnabled}
                        title={localization.actions.childScopeApiKeysEnabled()}
                        onCheckedChanged={
                            checked => {
                                StorageHelper.customerConfigurationApiKeysFlatView.setValue(checked);
                                setChildScopeApiKeysEnabled(checked);
                            }}/>
                </DataTableAction>
                <DataTableAction>
                    <Button
                        size="small"
                        startIcon={<AddIcon sx={{ fontSize: "18px" }}/>}
                        onClick={
                            () =>
                                setContext(
                                    context => ({
                                        ...context,
                                        addOpen: true
                                    }))}>
                        {localization.actions.add()}
                    </Button>
                </DataTableAction>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.name()}
                                    values={names}/>
                        }
                    }}
                    id={ApiKeysColumnId.Name}
                    itemProperty={(item: Contract.ConfigurationControllerGetApiKeysResponseApiKeyData) => item.apiKey.name}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element: <IdentityRoleFilter placeholder={localization.columns.role()}/>
                        }
                    }}
                    id={ApiKeysColumnId.Role}
                    itemProperty={(item: Contract.ConfigurationControllerGetApiKeysResponseApiKeyData) => identityRoleTranslator(item.role)}
                    title={localization.columns.role()}/>
                <DataTableColumn
                    id={ApiKeysColumnId.CreationTime}
                    itemProperty={(item: Contract.ConfigurationControllerGetApiKeysResponseApiKeyData) => TimeFormatter.shortDateTime(item.apiKey.systemCreationTime)}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>
                <DataTableColumn
                    id={ApiKeysColumnId.UsageTime}
                    render={
                        optionalTableCell<Contract.ConfigurationControllerGetApiKeysResponseApiKeyData>(
                            item =>
                                _.isNil(item.state.usageTime)
                                    ? undefined
                                    : TimeFormatter.shortDateTime(item.state.usageTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.usageTime()}/>
                <DataTableColumn
                    id={ApiKeysColumnId.Scope}
                    itemProperty={(item: Contract.ConfigurationControllerGetApiKeysResponseApiKeyData) => scopeNameTranslator(item.scopeId, { path: true })}
                    title={localization.columns.scope()}/>
                <DataTableColumn
                    id={ApiKeysColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ConfigurationControllerGetApiKeysResponseApiKeyData>) =>
                            scopeParentScopeIds.has(item.scopeId)
                                ? <Fragment/>
                                : <ActionsCell apiKeyData={item}/>}/>
            </DataTable>
        </ContextProvider>);
}

enum ApiKeysColumnId {
    Actions = "actions",
    CreationTime = "creationTime",
    Name = "name",
    Role = "role",
    Scope = "scope",
    UsageTime = "usageTime"
}