import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useServiceNowContext, useSetServiceNowContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { FinishItem, InstanceItem, RoleItem, UserItem } from "./components";

export class AddOrEditContext {
    public instanceName: string;
    public instanceUrl: string;
    public userPassword?: string;
    public instanceUserName: string;

    constructor(public instanceModel?: Contract.ScopeSystemEntityModel) {
        this.instanceName = (instanceModel?.configuration as Contract.ServiceNowInstanceConfiguration)?.name ?? "";
        this.instanceUrl = (instanceModel?.configuration as Contract.ServiceNowInstanceConfiguration)?.url ?? "";
        this.userPassword =
            _.isNil(instanceModel)
                ? ""
                : undefined;
        this.instanceUserName = (instanceModel?.configuration as Contract.ServiceNowInstanceConfiguration)?.userName ?? "";
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useServiceNowContext();
    const setServiceNowContext = useSetServiceNowContext();
    const [{ instanceModel }, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.serviceNow.addOrEdit",
            () => ({
                steps: {
                    instance: "Activate Instance",
                    role: "Assign Role",
                    user: "Create ServiceNow User"
                },
                title: {
                    add: "Add ServiceNow Instance",
                    edit: "Edit ServiceNow Instance"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(instanceModel)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(instanceModel)
                            ? 0
                            : 2}
                    onClose={
                        () =>
                            setServiceNowContext(
                                serviceNowContext => ({
                                    ...serviceNowContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.user()}>
                        <UserItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.role()}>
                        <RoleItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.instance()}>
                        <InstanceItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}