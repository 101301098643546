import { Action0, ClearIcon, DropdownIcon, Tooltip, useLocalization } from "@infrastructure";
import { Box, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, ReactNode } from "react";

export type ItemSelectorFieldProps = {
    dense?: boolean;
    disabled?: boolean;
    disablePopover?: boolean;
    emptyValue?: string;
    error?: boolean;
    icon?: ReactElement;
    onSelectionCleared?: Action0;
    open?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    selection?: ReactNode;
    selectionCount?: number;
    sx?: SxProps;
    variant?: ItemSelectorFieldPropsVariant;
};

export type ItemSelectorFieldPropsVariant = "outlined" | "underline";

export function ItemSelectorField({ dense = false, disabled = false, disablePopover = false, emptyValue, error, icon, onSelectionCleared, open = false, placeholder, readOnly = false, selection, selectionCount, sx, variant = "outlined" }: ItemSelectorFieldProps) {
    const localization =
        useLocalization(
            "infrastructure.inputs.itemSelectorField",
            () => ({
                selection: "{{selectionCount | NumberFormatter.humanize}} selected"
            }));
    const theme = useTheme();
    const hasSelection =
        !_.isNil(selection) ||
        !_.isNil(selectionCount) && selectionCount > 0;
    return (
        <Stack
            alignItems="center"
            direction="row"
            sx={{
                "&:hover": {
                    borderBottom:
                        !disabled && !readOnly
                            ? theme.border.hoverFocus
                            : undefined
                },
                backgroundColor:
                    disabled || readOnly
                        ? theme.palette.input.disabledBackground
                        : theme.palette.background.paper,
                borderBottom:
                    error
                        ? theme.border.error
                        : open
                            ? theme.border.hoverFocus
                            : theme.border.primary,
                ...(variant === "outlined" && {
                    "&:hover": {
                        border:
                            !disabled && !readOnly
                                ? theme.border.hoverFocus
                                : undefined
                    },
                    border:
                        error
                            ? theme.border.error
                            : open
                                ? theme.border.hoverFocus
                                : theme.border.primary,
                    borderRadius:
                        dense
                            ? theme.spacing(0.75)
                            : theme.spacing(1)
                }),
                color: theme.palette.text.secondary,
                flex: 1,
                maxHeight: theme.spacing(6.25),
                overflow: "hidden",
                width: "100%",
                ...(hasSelection && {
                    color: theme.palette.text.primary,
                    fontWeight: 500
                }),
                ...(disabled && {
                    color: theme.palette.text.secondary
                }),
                ...((disabled || readOnly) && {
                    cursor: "default"
                }),
                ...sx
            }}>
            <Stack
                alignItems="center"
                direction="row"
                sx={{
                    "&:hover": {
                        borderRight:
                            !_.isNil(onSelectionCleared) &&
                            hasSelection &&
                            !readOnly
                                ? theme.border.hoverFocus
                                : "none"
                    },
                    borderRight:
                        !_.isNil(onSelectionCleared) &&
                        hasSelection &&
                        !readOnly
                            ? open
                                ? theme.border.hoverFocus
                                : theme.border.primary
                            : "none",
                    flex: 1,
                    overflow: "hidden",
                    padding:
                        variant === "underline"
                            ? theme.spacing(0.5, 1)
                            : dense
                                ? theme.spacing(0.5, 0.5, 0.5, 1.5)
                                : theme.spacing(2)
                }}>
                <Tooltip
                    disabled={disablePopover || !hasSelection || readOnly}
                    sx={{ overflow: "hidden" }}
                    titleOrGetTitle={placeholder}>
                    <Typography
                        noWrap={true}
                        sx={{
                            fontSize: "inherit",
                            width: "100%"
                        }}>
                        {hasSelection
                            ? !_.isNil(selectionCount)
                                ? localization.selection({ selectionCount })
                                : selection
                            : emptyValue ?? placeholder}
                    </Typography>
                </Tooltip>
                <Box
                    sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        marginLeft: theme.spacing(0.5),
                        ...(disabled && {
                            color: theme.palette.text.disabled
                        })
                    }}>
                    {!readOnly && (
                        icon ?? <DropdownIcon sx={{ fontSize: "18px" }}/>)}
                </Box>
            </Stack>
            {!_.isNil(onSelectionCleared) &&
                hasSelection &&
                !disabled &&
                !readOnly &&
                <ClearIcon
                    sx={{
                        fontSize: "18px",
                        padding: theme.spacing(0, 2)
                    }}
                    onClick={
                        event => {
                            onSelectionCleared();
                            event.preventDefault();
                            event.stopPropagation();
                        }}/>}
        </Stack>);
}