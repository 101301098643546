﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsResourceSnapshotTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsRdsSnapshotRiskContext() {
    return useMemo(
        () => useAwsRdsSnapshotRiskContext,
        []);
}

function useAwsRdsSnapshotRiskContext(snapshotModel: Contract.AwsRdsSnapshotModel) {
    const snapshot = snapshotModel.entity as Contract.AwsRdsSnapshot;
    const sourceResourceModel = entityModelStore.useGet(snapshot.sourceResourceId);
    const resourceRiskContext = useGetAwsResourceRiskContext()(snapshotModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const snapshotTypeTranslator = useAwsResourceSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsRdsSnapshotRiskContext",
            () => ({
                sourceResource: "The {{translatedSnapshotTypeName}} is associated with {{sourceResource}}",
                storageSize: "The {{translatedSnapshotTypeName}}'s size is {{storageSize | NumberFormatter.storage}}",
                type: "The {{translatedSnapshotTypeName}}'s type is **{{type}}**"
            }));
    const translatedSnapshotTypeName =
        entityTypeNameTranslator(
            snapshot.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        sourceResource:
            _.isNil(sourceResourceModel)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sourceResource({
                        sourceResource:
                            <Entity
                                entityIdOrModel={sourceResourceModel}
                                variant="text"/>,
                        translatedSnapshotTypeName
                    })),
        storageSize:
            _.isNil(snapshot.sourceResourceId)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.storageSize({
                        storageSize: snapshot.storageSize,
                        translatedSnapshotTypeName
                    })),
        type:
            _.isNil(snapshot.sourceResourceId)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.type({
                        translatedSnapshotTypeName,
                        type: snapshotTypeTranslator(snapshot.type)
                    }))
    };
}