import { ActionButton, SearchTextField, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo, useState } from "react";
import { StorageHelper, useTheme } from "../../../../../..";
import { Categories, CategoryItems } from "./components";

type ObjectSelectorProps = {
    disableDetails?: boolean;
    items: ObjectSelectorItem[];
    onSelectedItemIdChanged: (selectedItemId: string) => void;
    selectedItemId?: string;
};

export class ObjectSelectorItem {
    constructor(
        public id: string,
        public searchText: string,
        public title: ReactNode | string,
        public options?: ObjectSelectorItemOptions) {
    }
}

type ObjectSelectorItemOptions = {
    description?: ReactNode;
    disabled?: boolean;
    icon?: ReactNode;
    initialExpanded?: boolean;
    items?: ObjectSelectorItem[];
    path?: string;
    tooltip?: ReactNode;
};

export function ObjectSelector({ disableDetails = true, items, onSelectedItemIdChanged, selectedItemId }: ObjectSelectorProps) {
    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.objectSelector",
            () => ({
                details: {
                    hidden: "Show details",
                    visible: "Hide details"
                },
                empty: "No items",
                placeholder: "Search users, roles & resources"
            }));

    const [searchText, setSearchText] = useState<string>();
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>();
    useChangeEffect(
        () => {
            setDebouncedSearchText(
                searchText?.
                    toLowerCase().
                    trim());
        },
        [searchText],
        300);

    const filteredItems =
        useMemo(
            () => {
                function filterItems(items: (ObjectSelectorItem)[]): (ObjectSelectorItem)[] {
                    return _(items).
                        map(
                            item => {
                                if (StringHelper.search(item.searchText, debouncedSearchText)) {
                                    return new ObjectSelectorItem(
                                        item.id,
                                        item.searchText,
                                        item.title,
                                        item.options);
                                }

                                const filteredItems = filterItems(item.options?.items || []);
                                if (!_.isEmpty(filteredItems)) {
                                    return new ObjectSelectorItem(
                                        item.id,
                                        item.searchText,
                                        item.title,
                                        {
                                            ...item.options,
                                            items: filteredItems as ObjectSelectorItem[]
                                        });
                                }

                                return undefined;
                            }).
                        filter(item => !_.isNil(item)).
                        value() as (ObjectSelectorItem)[];
                }

                return _.isEmpty(debouncedSearchText)
                    ? items
                    : filterItems(items);

            },
            [items, debouncedSearchText]);

    const [detailsVisible, setDetailsVisible] = useState(() => StringHelper.isTrue(StorageHelper.customerExploreFilterDetailsVisible.getValue() ?? true));
    const [hoveredCategoryItemId, setHoveredCategoryItemId] = useState<string>();
    const [hoveredItem, setHoverItem] = useState<ObjectSelectorItem>();
    const theme = useTheme();
    return (
        <Stack
            sx={{
                height: theme.spacing(60),
                overflow: "hidden",
                width: "100%"
            }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ padding: theme.spacing(1) }}>
                <SearchTextField
                    placeholder={localization.placeholder()}
                    searchText={searchText}
                    onSearchTextChanged={searchText => setSearchText(searchText ?? "")}/>
                {!disableDetails &&
                    <ActionButton
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: "24px"
                        }}
                        tooltip={
                            detailsVisible
                                ? localization.details.visible()
                                : localization.details.hidden()}
                        onClick={
                            event => {
                                StorageHelper.customerExploreFilterDetailsVisible.setValue(!detailsVisible);
                                setDetailsVisible(!detailsVisible);
                                event?.stopPropagation();
                            }}>
                        <HelpCenterOutlinedIcon/>
                    </ActionButton>}
            </Stack>
            {_.isEmpty(filteredItems)
                ? <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        flex: 1,
                        height: "100%"
                    }}>
                    <Typography>
                        {localization.empty()}
                    </Typography>
                </Stack>
                : <Stack
                    direction="row"
                    sx={{
                        borderTop: theme.border.primary,
                        height: "100%",
                        overflow: "hidden"
                    }}>
                    <Stack sx={{ width: theme.spacing(22.5) }}>
                        <Box sx={{ overflow: "auto" }}>
                            <Categories
                                hoveredItemId={hoveredCategoryItemId}
                                items={filteredItems}
                                onClick={
                                    (objectTypeItemId: string) => {
                                        setSearchText("");
                                        setTimeout(
                                            () => {
                                                const section = document.querySelector(`#${objectTypeItemId}`);
                                                section?.scrollIntoView({ behavior: "smooth", block: "start" });
                                            });
                                    }}/>
                        </Box>
                    </Stack>
                    <Stack
                        sx={{
                            borderLeft: theme.border.primary,
                            flex: 1,
                            height: "100%",
                            overflow: "hidden",
                            width: theme.spacing(40)
                        }}>
                        <Box sx={{ overflow: "hidden auto" }}>
                            <Stack
                                gap={0.5}
                                sx={{ height: "100%" }}>
                                {_.map(
                                    filteredItems,
                                    filteredItem =>
                                        <Stack
                                            id={filteredItem.id}
                                            key={filteredItem.id}
                                            onMouseEnter={() => setHoveredCategoryItemId?.(filteredItem.id)}>
                                            <CategoryItems
                                                item={filteredItem}
                                                selectedItemId={selectedItemId}
                                                onHovered={setHoverItem}
                                                onSelectedItemIdChanged={onSelectedItemIdChanged}/>
                                        </Stack>)}
                            </Stack>
                        </Box>
                    </Stack>
                    {!disableDetails &&
                        detailsVisible &&
                        <Stack
                            sx={{
                                borderLeft: theme.border.primary,
                                flex: 1,
                                height: "100%",
                                overflow: "hidden",
                                width: theme.spacing(40)
                            }}>
                            <Box
                                sx={{
                                    marginRight: theme.px(1),
                                    overflow: "auto"
                                }}>
                                {!_.isNil(hoveredItem) &&
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            height: "100%",
                                            padding: theme.spacing(2, 2, 1.5, 3)
                                        }}>
                                        {hoveredItem.title}
                                        {hoveredItem.options?.description}
                                    </Stack>}
                            </Box>
                        </Stack>}
                </Stack>}
        </Stack>);
}