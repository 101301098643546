﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, optionalTableCell, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, EntityAttributeFilter, EntityAttributesCell, EntityFilter, entityModelStore, EntityTypeFilter, EntityTypeMetadataModelHelper, InlineEntities, InlineResourceTags, Region, ResourceTagHelper, TypeHelper, useTableDefinition } from "../../../../../../../../../common";
import { Table } from "../components";
import { useCommonCustomSectionsAndDescriptionDefinition } from "./useCommonCustomSectionsAndDescriptionDefinition";

type ResourceCodeResourceNotExistsRiskViolationTableProps = {
    entityTypeName: string;
    getResourceTags: (item: Contract.Entity) => Contract.ResourceTag[];
    riskModel: Contract.RiskModel;
};

export function useResourceCodeResourceNotExistsRiskDefinition({ entityTypeName, getResourceTags, riskModel }: ResourceCodeResourceNotExistsRiskViolationTableProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceCodeResourceNotExistsRiskDefinition",
            () => ({
                violations: {
                    many: "{{resources}} are not managed as code",
                    single: "{{resources}} is not managed as code"
                }
            }));

    return useCommonCustomSectionsAndDescriptionDefinition(
        (riskModel.risk.riskedEntityIds.length === 1
            ? localization.violations.single
            : localization.violations.many)({
            resources:
                <InlineEntities
                    entityIdsOrModels={riskModel.risk.riskedEntityIds}
                    entityTypeName={entityTypeName}
                    variant="itemAndTypeOrItemCountAndType"/>
        }),
        riskModel,
        "violations",
        <ViolationTable
            getResourceTags={getResourceTags}
            risk={riskModel.risk}/>);
}

type ViolationTableProps = {
    getResourceTags: (item: Contract.Entity) => Contract.ResourceTag[];
    risk: Contract.Risk;
};

function ViolationTable({ getResourceTags, risk }: ViolationTableProps) {
    const codeResourceNotExistsResourceModels = entityModelStore.useGet(risk.riskedEntityIds);

    const items =
        useMemo(
            () => {
                const codeResourceNotExistsResourceModelMap =
                    _.keyBy(
                        codeResourceNotExistsResourceModels,
                        codeResourceNotExistsResourceModel => codeResourceNotExistsResourceModel.id);

                return _.map(
                    risk.items,
                    codeResourceNotExistsResourceItem =>
                        new ViolationTableItem(
                            codeResourceNotExistsResourceItem as Contract.RiskItem,
                            codeResourceNotExistsResourceModelMap[codeResourceNotExistsResourceItem.entityId]));
            },
            [risk]);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Attributes]: {
                    getFilterValue:
                        item =>
                            _.map(
                                item.codeResourceNotExistsResourceModel.attributes.attributes,
                                entityAttribute =>
                                    TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.CustomEntityAttribute)
                                        ? (entityAttribute as Contract.CustomEntityAttribute).definitionId
                                        : entityAttribute.typeName),
                    getSortValue: item => item.codeResourceNotExistsResourceModel.attributes.attributes.length
                },
                [ViolationTableColumnId.Region]: {
                    getFilterValue: item => item.codeResourceNotExistsResourceModel.entity.regionId,
                    getSortValue: item => item.codeResourceNotExistsResourceModel.entity.regionId ?? ""
                },
                [ViolationTableColumnId.Resource]: {
                    getFilterValue: item => item.riskItem.entityId,
                    getSortValue: item => item.codeResourceNotExistsResourceModel.entity.displayName.toLowerCase()
                },
                [ViolationTableColumnId.Tags]: {
                    getFilterValue:
                        item =>
                            _.map(
                                ResourceTagHelper.getDatas(getResourceTags(item.codeResourceNotExistsResourceModel.entity)),
                                resourceTagData => resourceTagData.id),
                    getSortValue: item => getResourceTags(item.codeResourceNotExistsResourceModel.entity).length
                },
                [ViolationTableColumnId.TypeName]: {
                    getFilterValue: item => EntityTypeMetadataModelHelper.get(item.codeResourceNotExistsResourceModel.entity.typeName).entitiesViewName,
                    getSortValue: item => EntityTypeMetadataModelHelper.get(item.codeResourceNotExistsResourceModel.entity.typeName).entitiesViewName
                }
            },
            ViolationTableColumnId.Resource);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceCodeResourceNotExistsRiskDefinition.violationTable",
            () => ({
                columns: {
                    attributes: "Labels",
                    region: "Region",
                    resource: "Resource",
                    tags: "Tags",
                    typeName: "Resource Type"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.codeResourceNotExistsResourceModel.id}
            sortEnabled={true}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Resource]}
                                placeholder={localization.columns.resource()}/>
                    }
                }}
                id={ViolationTableColumnId.Resource}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.codeResourceNotExistsResourceModel}
                            variant="iconTextTypeTenant"/>}
                title={localization.columns.resource()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityTypeFilter
                                entityTypeNames={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.TypeName]}
                                placeholder={localization.columns.typeName()}/>
                    }
                }}
                id={ViolationTableColumnId.TypeName}
                title={localization.columns.typeName()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.region()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Region],
                                    regionId =>
                                        <ValuesFilterItem
                                            key={regionId}
                                            title={regionId}
                                            value={regionId}>
                                            {() =>
                                                <Typography noWrap={true}>
                                                    <Region
                                                        regionId={regionId}
                                                        variant="iconText"/>
                                                </Typography>}
                                        </ValuesFilterItem>)}
                            </ValuesFilter>
                    }
                }}
                id={ViolationTableColumnId.Region}
                render={
                    optionalTableCell<ViolationTableItem>(
                        ({ codeResourceNotExistsResourceModel }) =>
                            <Region regionId={codeResourceNotExistsResourceModel.entity.regionId}/>)}
                title={localization.columns.region()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.tags()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Tags],
                                    (tagId: string) =>
                                        <ValuesFilterItem
                                            key={tagId}
                                            title={ResourceTagHelper.getDisplayName(tagId)}
                                            value={tagId}/>)}
                            </ValuesFilter>
                    }
                }}
                id={ViolationTableColumnId.Tags}
                render={
                    optionalTableCell<ViolationTableItem>(
                        ({ codeResourceNotExistsResourceModel }) =>
                            _.isEmpty(getResourceTags(codeResourceNotExistsResourceModel.entity))
                                ? undefined
                                : <InlineResourceTags tags={getResourceTags(codeResourceNotExistsResourceModel.entity)}/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.tags()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityAttributeFilter
                                emptyValue={true}
                                entityAttributeValues={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Attributes]}
                                placeholder={localization.columns.attributes()}/>
                    }
                }}
                id={ViolationTableColumnId.Attributes}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntityAttributesCell
                            entityAttributes={item.codeResourceNotExistsResourceModel.attributes.attributes}
                            entityTypeName={item.codeResourceNotExistsResourceModel.entity.typeName}/>}
                title={localization.columns.attributes()}/>
        </Table>);
}

class ViolationTableItem {
    constructor(
        public riskItem: Contract.RiskItem,
        public codeResourceNotExistsResourceModel: Contract.EntityModel) {
    }
}

enum ViolationTableColumnId {
    Attributes = "attributes",
    Region = "region",
    Resource = "resource",
    Tags = "tags",
    TypeName = "typeName"
}