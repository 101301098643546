﻿import { Action0, ApiError, CopyToClipboardActionButton, DataTable, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSearchOptions, Dialog, EmptyMessageText, FormLayout, Message, PasswordTextField, StringHelper, useLocalization } from "@infrastructure";
import { Button, CircularProgress, InputAdornment, Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AdministrationController, Contract, useTheme } from "../../../../../../../common";

type CognitoIdentityProviderProps = {
    onClose: Action0;
};

export function CognitoIdentityProvider({ onClose }: CognitoIdentityProviderProps) {
    const localization =
        useLocalization(
            "views.customer.administration.authentication.cognitoIdentityProvider",
            () => ({
                columns: {
                    userMail: "Mail"
                },
                empty: "No users",
                search: "Search",
                title: "User/Password Configuration"
            }));

    const searchFilterId = "searchText";
    return (
        <Dialog
            size="small"
            variant="editor"
            onClose={() => onClose()}>
            <FormLayout titleOptions={{ text: localization.title() }}>
                <DataTable
                    emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                    fetchItems={
                        async (filterMap, _sort) => {
                            const getAuthenticationUsersResponse = await AdministrationController.getAuthenticationUsers();
                            const userMails =
                                _(getAuthenticationUsersResponse.mails).
                                    filter(userMail => StringHelper.search(userMail, filterMap[searchFilterId])).
                                    value();
                            return new DataTableFetchItemsResult(
                                { count: userMails.length },
                                userMails,
                                true);
                        }
                    }
                    getItemId={(item: string) => item}
                    searchOptions={
                        new DataTableSearchOptions(
                            searchFilterId,
                            localization.search())}
                    sortOptions={{ enabled: false }}>
                    <DataTableColumn
                        id="userMail"
                        itemProperty={(item: string) => item}
                        title={localization.columns.userMail()}/>
                    <DataTableColumn
                        id="password"
                        render={
                            ({ item: userMail }: DataTableColumnRenderProps<string>) =>
                                <ResetPasswordActionsCell userMail={userMail}/>}/>
                </DataTable>
            </FormLayout>
        </Dialog>);
}

type ResetPasswordActionsCellProps = {
    userMail: string;
};

function ResetPasswordActionsCell({ userMail }: ResetPasswordActionsCellProps) {
    const [newUserPassword, setNewUserPassword] = useState<string | undefined>();
    const [resetUserPasswordExecuting, setResetUserPasswordExecuting] = useState(false);
    const [resetUserPasswordError, setResetUserPasswordError] = useState<Contract.AdministrationControllerResetCognitoUserPasswordError | true>();

    const localization =
        useLocalization(
            "views.customer.administration.authentication.cognitoIdentityProvider.resetPasswordActionsCell",
            () => ({
                error: {
                    general: "User password reset failed",
                    [Contract.TypeNames.AdministrationControllerResetCognitoUserPasswordError]: {
                        [Contract.AdministrationControllerResetCognitoUserPasswordError.UserMailInvalid]: "User not found in customer identity pool"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1}>
            {resetUserPasswordExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {resetUserPasswordError &&
                (<Message
                    level="error"
                    title={
                        resetUserPasswordError === true
                            ? localization.error.general()
                            : localization.error[Contract.TypeNames.AdministrationControllerResetCognitoUserPasswordError][resetUserPasswordError]()}
                    variant="minimal"/>)}
            {!_.isNil(newUserPassword) &&
                <PasswordTextField
                    password={newUserPassword}
                    size="small"
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment
                                    position="end">
                                    <CopyToClipboardActionButton getValue={() => newUserPassword}/>
                                </InputAdornment>,
                            readOnly: true,
                            sx: { height: theme.spacing(4) }
                        }
                    }}
                    sx={{
                        paddingLeft: theme.spacing(2),
                        width: theme.spacing(20)
                    }}
                    variant="outlined">
                </PasswordTextField>}
            <Button
                disabled={false}
                size="small"
                sx={{ height: theme.spacing(4) }}
                variant="outlined"
                onClick={
                    async () => {
                        setNewUserPassword(undefined);
                        setResetUserPasswordError(undefined);
                        setResetUserPasswordExecuting(true);

                        try {
                            const newUserPasswordResponse = await AdministrationController.resetCognitoUserPassword(new Contract.AdministrationControllerResetCognitoUserPasswordRequest(userMail));
                            setNewUserPassword(newUserPasswordResponse.password);
                        } catch (error) {
                            setResetUserPasswordError(
                                error instanceof ApiError && error.statusCode === 400
                                    ? error.error as Contract.AdministrationControllerResetCognitoUserPasswordError
                                    : true);
                        }

                        setResetUserPasswordExecuting(false);
                    }}>
                {"Reset Password"}
            </Button>
        </Stack>);
}