import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColoredAzureIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M9.00075 3.00001H14.3272L8.79782 20.3862C8.74099 20.5649 8.6328 20.7201 8.48847 20.8301C8.34415 20.9401 8.17095 20.9992 7.99328 20.9993H3.84801C3.71339 20.9993 3.5807 20.9653 3.46092 20.9001C3.34114 20.8349 3.23771 20.7403 3.15918 20.6243C3.08066 20.5083 3.0293 20.374 3.00936 20.2327C2.98941 20.0915 3.00146 19.9472 3.04449 19.8118L8.196 3.61307C8.2528 3.43434 8.36101 3.27903 8.50538 3.16901C8.64975 3.05898 8.82301 3.00002 9.00075 3.00001Z"
                fill="url(#paint0_linear_57152_3243)"/>
            <path
                d="M16.7411 14.6617H8.29467C8.21615 14.6616 8.13941 14.6866 8.07447 14.7334C8.00953 14.7803 7.95939 14.8468 7.93058 14.9243C7.90177 15.0019 7.89563 15.0868 7.91295 15.1681C7.93028 15.2494 7.97027 15.3232 8.02772 15.38L13.4552 20.7561C13.6132 20.9125 13.8213 20.9995 14.0374 20.9994H18.82L16.7411 14.6617Z"
                fill="#0078D4"/>
            <path
                d="M9.00075 3.00001C8.82107 2.99927 8.64626 3.05981 8.50114 3.17226C8.35603 3.2847 8.2486 3.44344 8.19454 3.62529L3.05121 19.7973C3.00528 19.9332 2.99086 20.0788 3.00917 20.2218C3.02748 20.3648 3.07798 20.5011 3.1564 20.619C3.23482 20.7369 3.33885 20.8331 3.45969 20.8993C3.58054 20.9656 3.71463 21 3.85064 20.9996H8.10289C8.26126 20.9695 8.40929 20.8954 8.53184 20.7848C8.6544 20.6742 8.74709 20.5311 8.80045 20.37L9.82613 17.162L13.4899 20.7885C13.6434 20.9233 13.836 20.9978 14.0352 20.9996H18.8001L16.7103 14.6618L10.6182 14.6633L14.3468 3.00028L9.00075 3.00001Z"
                fill="url(#paint1_linear_57152_3243)"/>
            <path
                d="M15.8037 3.61248C15.747 3.43403 15.639 3.27898 15.4948 3.16915C15.3507 3.05932 15.1777 3.00026 15.0002 3.00028H9.06401C9.24146 3.00029 9.41443 3.05936 9.55858 3.16919C9.70273 3.27901 9.81078 3.43405 9.86754 3.61248L15.0192 19.8118C15.0623 19.9472 15.0744 20.0916 15.0545 20.2329C15.0345 20.3742 14.9832 20.5085 14.9047 20.6246C14.8261 20.7407 14.7227 20.8352 14.6029 20.9005C14.4831 20.9657 14.3504 20.9998 14.2157 20.9998H20.1521C20.2868 20.9997 20.4195 20.9657 20.5392 20.9004C20.659 20.8352 20.7624 20.7406 20.8409 20.6245C20.9194 20.5084 20.9708 20.3742 20.9907 20.2328C21.0106 20.0915 20.9985 19.9472 20.9555 19.8118L15.8037 3.61248Z"
                fill="url(#paint2_linear_57152_3243)"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_57152_3243"
                    x1="15.6207"
                    x2="11.4634"
                    y1="4.33405"
                    y2="22.7242">
                    <stop stopColor="#114A8B"/>
                    <stop
                        offset="1"
                        stopColor="#0669BC"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_57152_3243"
                    x1="14.0162"
                    x2="12.5808"
                    y1="12.4162"
                    y2="12.9372">
                    <stop stopOpacity="0.3"/>
                    <stop
                        offset="0.071"
                        stopOpacity="0.2"/>
                    <stop
                        offset="0.321"
                        stopOpacity="0.1"/>
                    <stop
                        offset="0.623"
                        stopOpacity="0.05"/>
                    <stop
                        offset="1"
                        stopOpacity="0"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint2_linear_57152_3243"
                    x1="11.9661"
                    x2="18.6997"
                    y1="3.82848"
                    y2="20.7328">
                    <stop stopColor="#3CCBF4"/>
                    <stop
                        offset="1"
                        stopColor="#2892DF"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}