import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, NumberFormatter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureNetworkPrivateDnsZoneDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureNetworkPrivateDnsZoneDefinition",
            () => ({
                columns: {
                    recordSetCount: "Record Set Count",
                    virtualNetworkIds: "Virtual Networks"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkPrivateDnsZoneModel) => ({
                            [localization.columns.recordSetCount()]: NumberFormatter.humanize(item.recordSetCount)
                        })
                }}
                id={Contract.EntityModelProperty.AzureNetworkPrivateDnsZoneRecordSetCount}
                itemProperty={(item: Contract.AzureNetworkPrivateDnsZoneModel) => item.recordSetCount}
                key={Contract.EntityModelProperty.AzureNetworkPrivateDnsZoneRecordSetCount}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.recordSetCount()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkPrivateDnsZoneModel>(
                        Contract.TypeNames.AzureNetworkVirtualNetwork,
                        item => (item as Contract.AzureNetworkPrivateDnsZoneModel).virtualNetworkIds,
                        localization.columns.virtualNetworkIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkPrivateDnsZoneVirtualNetworks)}
                                placeholder={localization.columns.virtualNetworkIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkPrivateDnsZoneVirtualNetworks}
                key={Contract.EntityModelProperty.AzureNetworkPrivateDnsZoneVirtualNetworks}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkPrivateDnsZoneModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.virtualNetworkIds}
                            entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}
                            entityVariant="iconTextTypeTenant"/>}
                title={localization.columns.virtualNetworkIds()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureNetworkPrivateDnsZoneRequest(
                new Contract.EntityControllerGetEntityModelPageAzureNetworkPrivateDnsZoneRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkPrivateDnsZoneVirtualNetworks])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}