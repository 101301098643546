import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskServiceNowIncidentAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskServiceNowIncidentAuditEventDefinition",
            () => ({
                serviceNowIncidentUrl: "Link to incident"
            }));

    const riskServiceNowIncidentAuditEvent = context.auditEventModel.auditEvent as Contract.RiskServiceNowIncidentAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            riskAuditEventDetailsItems.riskPolicyTypeItem,
            riskAuditEventDetailsItems.riskIdItem,
            riskAuditEventDetailsItems.riskDeletedItem,
            riskAuditEventDetailsItems.riskedEntitiesItem,
            riskAuditEventDetailsItems.riskLinkItem,
            _.isNil(riskServiceNowIncidentAuditEvent.serviceNowIncidentUrl)
                ? undefined
                : new AuditEventDefinitionDetailItem(
                    localization.serviceNowIncidentUrl(),
                    riskServiceNowIncidentAuditEvent.serviceNowIncidentUrl,
                    <Link
                        urlOrGetUrl={riskServiceNowIncidentAuditEvent.serviceNowIncidentUrl}
                        variant="external"/>),
            riskAuditEventDetailsItems.errorMessageItem
        ]) as AuditEventDefinitionDetailItem[]);
}