import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../..";
import { useAadDeletePrincipalGroupMembershipChangeDefinition } from "./hooks";

export function useAadDefinition(change: Contract.AadChange, entityLinkDisabled?: boolean) {
    const useDefinition =
        useMemo(
            () => {
                switch (change.typeName) {
                    case Contract.TypeNames.AadDeletePrincipalGroupMembershipChange:
                        return useAadDeletePrincipalGroupMembershipChangeDefinition;
                    default:
                        throw new UnexpectedError("change.typeName", change.typeName);
                }
            },
            []);

    return useDefinition(change, entityLinkDisabled);
}