import { useChangeEffect, useLocalization } from "@infrastructure";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, RiskPoliciesType, UserHelper, useTheme } from "../../../../../../../../common";
import { useRiskPoliciesItemConfiguration } from "../../../../hooks";
import { useConfigurationContext, useSetConfigurationContext } from "../../Configuration";
import { ApiGatewayRoutePattern } from "./ApiGatewayRoutePattern";

type ApiGatewayRouteExclusionProps = {
    riskPoliciesType: RiskPoliciesType;
    title: string;
};

export function ApiGatewayRouteExclusion({ riskPoliciesType, title }: ApiGatewayRouteExclusionProps) {
    const { item, riskPolicyConfiguration, scopeId } = useConfigurationContext();
    const { inheritDisabled, scopeIdToRiskPolicyModelMap } = useRiskPoliciesItemConfiguration(riskPoliciesType, item, scopeId);
    const setConfigurationContext = useSetConfigurationContext();

    const [inheritedApiGatewayRoutePatterns, apiGatewayRoutePatternKeyToScopeIdsMap] =
        useMemo(
            () => {
                const apiGatewayRouteExclusionPatternDatas =
                    _(scopeIdToRiskPolicyModelMap as _.Dictionary<Contract.RiskPolicyModel>).
                        flatMap(
                            (riskPolicyModel, scopeId) =>
                                _((riskPolicyModel.riskPolicyConfiguration as Contract.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration).apiGatewayRouteExclusionRoutePatterns).
                                    map(apiGatewayRouteExclusionPattern => ({ apiGatewayRouteExclusionPattern, scopeId })).
                                    value()).
                        filter().
                        value();
                const inheritedApiGatewayRoutePatterns =
                    _(apiGatewayRouteExclusionPatternDatas).
                        map(apiGatewayRouteExclusionPatternData => apiGatewayRouteExclusionPatternData.apiGatewayRouteExclusionPattern).
                        value();
                const apiGatewayRoutePatternKeyToScopeIdsMap =
                    _(apiGatewayRouteExclusionPatternDatas).
                        groupBy(apiGatewayRouteExclusionPatternData => getExclusionApiGatewayRoutePatternKey(apiGatewayRouteExclusionPatternData.apiGatewayRouteExclusionPattern)).
                        mapValues(
                            apiGatewayRoutePatternDatas =>
                                _(apiGatewayRoutePatternDatas).
                                    map(apiGatewayRoutePatternData => apiGatewayRoutePatternData.scopeId).
                                    value()).
                        value();
                return [inheritedApiGatewayRoutePatterns, apiGatewayRoutePatternKeyToScopeIdsMap];
            },
            [scopeIdToRiskPolicyModelMap]);

    const userSecurityWrite =
        useMemo(
            () =>
                UserHelper.hasScopePermissions(
                    riskPolicyConfiguration.scopeId,
                    Contract.IdentityPermission.SecurityWrite),
            [riskPolicyConfiguration]);
    const [exclusionApiGatewayRoutePatternItems, setExclusionApiGatewayRoutePatternItems] =
        useState(
            () =>
                _((riskPolicyConfiguration as Contract.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration).apiGatewayRouteExclusionRoutePatterns ?? []).
                    concatIf(
                        !inheritDisabled,
                        inheritedApiGatewayRoutePatterns).
                    map(
                        exclusionApiGatewayRoutePattern =>
                            new ExclusionApiGatewayRoutePatternItem(
                                exclusionApiGatewayRoutePattern,
                                false)).
                    orderBy(exclusionApiGatewayRoutePatternItem => _.isEmpty(apiGatewayRoutePatternKeyToScopeIdsMap[exclusionApiGatewayRoutePatternItem.key])).
                    concatIf(
                        userSecurityWrite,
                        getEmptyExclusionApiGatewayRoutePatternItem()).
                    value());

    const exclusionApiGatewayRoutePatternItemKeyToDuplicateMap =
        useMemo(
            () =>
                _(exclusionApiGatewayRoutePatternItems).
                    keyBy(exclusionApiGatewayRoutePatternItem => exclusionApiGatewayRoutePatternItem.key).
                    mapValues(exclusionApiGatewayRoutePatternItem => isItemDuplicate(exclusionApiGatewayRoutePatternItems, exclusionApiGatewayRoutePatternItem.key)).
                    value(),
            [exclusionApiGatewayRoutePatternItems]);

    function isItemDuplicate(items: ExclusionApiGatewayRoutePatternItem[], key: string): boolean {
        return _(items).
            filter(item => item.key === key).
            size() > 1;
    }

    useChangeEffect(
        () => {
            const newItems =
                _([...exclusionApiGatewayRoutePatternItems]).
                    slice(0, -1).
                    filter(exclusionApiGatewayPatternPatternItem => _.isEmpty(apiGatewayRoutePatternKeyToScopeIdsMap[exclusionApiGatewayPatternPatternItem.key])).
                    value();

            const valid =
                _.every(
                    newItems,
                    item => item.isValid()) &&
                _(exclusionApiGatewayRoutePatternItems).
                    uniqBy(item => item.key).
                    size() === _.size(exclusionApiGatewayRoutePatternItems);

            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    additionalTabIdToSectionMap: {
                        ...configurationContext.additionalTabIdToSectionMap,
                        [title]: { valid }
                    },
                    dirty: true,
                    riskPolicyConfiguration: {
                        ...configurationContext.riskPolicyConfiguration,
                        apiGatewayRouteExclusionRoutePatterns:
                            _.map(
                                newItems,
                                exclusionApiGatewayRoutePatternItem => exclusionApiGatewayRoutePatternItem.exclusionApiGatewayRoutePattern)
                    }
                }));
        },
        [exclusionApiGatewayRoutePatternItems]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.apiGatewayRouteExclusion.apiGatewayRouteExclusion",
            () => ({
                pathPatternInfo: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character.",
                subtitle: "Define API Gateway resource paths and methods as public by design to reflect intended configurations. Enter a resource path pattern and HTTP method (e.g., Get). If a detected path and method match, Tenable classifies them as public by design and closes related findings. For example, enter \"/health\" with Get to mark this combination as intentionally public.",
                title: "Exclude by API Gateway Path Pattern"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                padding: theme.spacing(2, 2, 2, 3)
            }}>
            <Stack spacing={1}>
                <Typography variant="h4">
                    {localization.title()}
                </Typography>
                <Typography sx={{ maxWidth: "55%" }}>
                    {localization.subtitle()}
                </Typography>
            </Stack>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.pathPatternInfo()}
            </Typography>
            <Box
                sx={{
                    flex: 1,
                    overflowY: "auto"
                }}>
                <List disablePadding={true}>
                    {_(exclusionApiGatewayRoutePatternItems).
                        map(
                            (exclusionApiGatewayRoutePatternItem, index) =>
                                <ListItem
                                    key={exclusionApiGatewayRoutePatternItem.id}
                                    sx={{ paddingLeft: 0 }}>
                                    <ApiGatewayRoutePattern
                                        duplicate={exclusionApiGatewayRoutePatternItemKeyToDuplicateMap[exclusionApiGatewayRoutePatternItem.key]}
                                        exclusionApiGatewayRoutePattern={exclusionApiGatewayRoutePatternItem.exclusionApiGatewayRoutePattern}
                                        last={_.size(exclusionApiGatewayRoutePatternItems) === index + 1}
                                        readOnly={!userSecurityWrite}
                                        scopeIds={
                                            exclusionApiGatewayRoutePatternItem.dirty || inheritDisabled
                                                ? undefined
                                                : apiGatewayRoutePatternKeyToScopeIdsMap[exclusionApiGatewayRoutePatternItem.key]}
                                        valid={exclusionApiGatewayRoutePatternItem.isValid()}
                                        onDelete={
                                            () => {
                                                const items =
                                                    _.without(
                                                        exclusionApiGatewayRoutePatternItems,
                                                        exclusionApiGatewayRoutePatternItem);
                                                setExclusionApiGatewayRoutePatternItems(items);
                                            }}
                                        onExclusionApiGatewayRoutePatternChanged={
                                            exclusionApiGatewayRoutePattern => {
                                                const newItems = _.cloneDeep(exclusionApiGatewayRoutePatternItems);
                                                const itemIndex =
                                                    _.findIndex(
                                                        newItems,
                                                        currentItem => currentItem.id === exclusionApiGatewayRoutePatternItem.id);
                                                const newItem =
                                                    new ExclusionApiGatewayRoutePatternItem(
                                                        exclusionApiGatewayRoutePattern,
                                                        true,
                                                        exclusionApiGatewayRoutePatternItem.id);
                                                newItems.splice(itemIndex, 1, newItem);
                                                setExclusionApiGatewayRoutePatternItems(
                                                    _(newItems).
                                                        concatIf(
                                                            _.size(exclusionApiGatewayRoutePatternItems) === index + 1,
                                                            getEmptyExclusionApiGatewayRoutePatternItem()).
                                                        value());
                                            }}/>
                                </ListItem>).
                        value()}
                </List>
            </Box>
        </Stack>);
}

function getEmptyExclusionApiGatewayRoutePatternItem() {
    return new ExclusionApiGatewayRoutePatternItem(
        new Contract.AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern(
            false,
            "",
            [],
            ""),
        true,
        undefined);
}

function getExclusionApiGatewayRoutePatternKey(exclusionApiGatewayRoutePattern: Contract.AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern): string {
    return `${exclusionApiGatewayRoutePattern.pathPattern}-${_.join(exclusionApiGatewayRoutePattern.methods, ",")}`;
}
export class ExclusionApiGatewayRoutePatternItem {
    private static _idCounter = 0;
    public dirty: boolean;
    public id: number;
    public key: string;

    constructor(
        public exclusionApiGatewayRoutePattern: Contract.AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern,
        dirty?: boolean,
        id?: number) {
        this.dirty = dirty ?? false;
        this.id = id ?? ExclusionApiGatewayRoutePatternItem._idCounter++;
        this.key = getExclusionApiGatewayRoutePatternKey(exclusionApiGatewayRoutePattern);
    }

    public isValid(): boolean {
        return !_.isEmpty(this.exclusionApiGatewayRoutePattern.pathPattern) &&
            !_.isEmpty(this.exclusionApiGatewayRoutePattern.methods);
    }
}