import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RemediationIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_59326_922)">
                <path d="M14.6001 15.9H13.8001C12.3001 15.9 10.9001 15.3 9.8001 14.2C8.7001 13.1 8.1001 11.7 8.1001 10.2V9.8C8.1001 5.8 8.1001 3.2 9.7001 1.6C11.3001 0 12.2001 0 17.8001 0H18.5001C18.8001 0 19.1001 0 19.4001 0.3C19.7001 0.5 19.9001 0.7 20.0001 1C20.1001 1.3 20.2001 1.6 20.0001 2C20.0001 2.3 19.8001 2.6 19.5001 2.8L16.3001 6C16.2001 6.1 16.1001 6.2 16.1001 6.3C16.1001 6.4 16.1001 6.6 16.1001 6.7C16.1001 6.8 16.1001 7 16.1001 7.1C16.1001 7.2 16.2001 7.3 16.3001 7.4C16.3001 7.4 16.5001 7.6 16.6001 7.6C16.7001 7.6 16.9001 7.6 17.0001 7.6C17.1001 7.6 17.3001 7.6 17.4001 7.6C17.5001 7.6 17.6001 7.5 17.7001 7.4L20.9001 4.2C21.1001 4 21.4001 3.8 21.7001 3.7C22.0001 3.6 22.4001 3.7 22.7001 3.7C23.0001 3.8 23.3001 4 23.4001 4.3C23.6001 4.6 23.7001 4.9 23.7001 5.2V5.9C23.7001 11.5 23.7001 12.4 22.1001 14C20.5001 15.6 18.0001 15.6 14.3001 15.6L14.6001 15.9ZM17.5001 2C12.4001 2 12.1001 2 11.1001 3C10.1001 4 10.1001 6.3 10.1001 9.8V10.2C10.1001 11.2 10.5001 12.1 11.2001 12.8C11.9001 13.5 12.8001 13.9 13.8001 13.9H14.2001C17.7001 13.9 20.0001 13.9 21.0001 12.9C22.0001 11.9 22.0001 11.7 22.0001 6.3L19.4001 8.9C19.1001 9.2 18.8001 9.4 18.4001 9.6C18.0001 9.8 17.6001 9.8 17.2001 9.8C16.8001 9.8 16.4001 9.8 16.0001 9.6C15.6001 9.4 15.3001 9.2 15.0001 8.9C14.7001 8.6 14.5001 8.3 14.3001 7.9C14.1001 7.5 14.1001 7.1 14.1001 6.7C14.1001 6.3 14.1001 5.9 14.3001 5.5C14.5001 5.1 14.7001 4.8 15.0001 4.5L17.6001 1.9C17.6001 1.9 17.5001 1.9 17.4001 1.9L17.5001 2Z"/>
                <path d="M4.3 24C3.2 24 2.1 23.5 1.3 22.7C0.5 21.9 0.1 20.8 0 19.7C0 18.6 0.4 17.5 1.2 16.7L8.4 9.49995C8.8 9.09995 9.4 9.09995 9.8 9.49995C10.2 9.89995 10.2 10.5 9.8 10.9L2.6 18.1C2.2 18.5 2 19.1 2 19.7C2 20.3 2.2 20.9 2.7 21.3C3.1 21.7 3.7 22 4.3 22C4.9 22 5.5 21.8 5.9 21.4L13 14.3C13.4 13.9 14 13.9 14.4 14.3C14.8 14.7 14.8 15.3 14.4 15.7L7.2 22.9C6.4 23.7 5.3 24.1 4.2 24.1L4.3 24ZM4.4 20.6C3.8 20.6 3.4 20.2 3.4 19.6C3.4 19 3.8 18.6 4.4 18.6C5 18.6 5.4 19 5.4 19.6C5.4 20.2 5 20.6 4.4 20.6Z"/>
            </g>
            <defs>
                <clipPath id="clip0_59326_922">
                    <rect fill="white" height="24" width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}