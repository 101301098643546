﻿import { StringHelper } from "@infrastructure";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { Contract } from "../controllers";
import { customEntityAttributeDefinitionModelStore } from "../stores";
import { useTheme } from "../themes";
import { useBuiltInEntityAttributeTypeNameTranslator } from "./useBuiltInEntityAttributeTypeNameTranslator";

export function useGetEntityAttributesItemDefinition() {
    const builtInEntityAttributeTypeNameTranslator = useBuiltInEntityAttributeTypeNameTranslator();
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const customEntityAttributeDefinitionModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customEntityAttributeDefinitionModels,
                    customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id),
            [customEntityAttributeDefinitionModels]);

    const theme = useTheme();
    return useCallback(
        (builtInEntityAttributeTypeNames: string[], customEntityAttributeDefinitionIds: string[]) =>
            _.concat(
                _(customEntityAttributeDefinitionIds).
                    map(
                        customEntityAttributeDefinitionId =>
                            new EntityAttributeItemDefinition(
                                theme.palette.entityAttribute.custom[customEntityAttributeDefinitionModelMap[customEntityAttributeDefinitionId].configuration!.colorIndex],
                                customEntityAttributeDefinitionModelMap[customEntityAttributeDefinitionId].configuration.name,
                                "custom",
                                customEntityAttributeDefinitionModelMap[customEntityAttributeDefinitionId].configuration)).
                    orderBy(entityAttributeItemDefinition => entityAttributeItemDefinition.name).
                    value(),
                _(builtInEntityAttributeTypeNames).
                    groupBy(builtInEntityAttributeTypeName => builtInEntityAttributeTypeNameTranslator(builtInEntityAttributeTypeName)).
                    map((builtInEntityAttributeTypeNames, translatedBuiltInEntityAttributeTypeName) =>
                        new EntityAttributeItemDefinition(
                            theme.palette.entityAttribute.builtIn(
                                _(builtInEntityAttributeTypeNames!).
                                    orderBy(typeName => StringHelper.getSortValue(typeName)).
                                    head()!),
                            translatedBuiltInEntityAttributeTypeName,
                            "builtIn",
                            undefined,
                            builtInEntityAttributeTypeNames)).
                    value()),
        [builtInEntityAttributeTypeNameTranslator, customEntityAttributeDefinitionModelMap]);
}

export class EntityAttributeItemDefinition {
    constructor(
        public color: string,
        public name: string,
        public type: "builtIn" | "custom",
        public customEntityAttributeDefinitionConfiguration?: Contract.CustomEntityAttributeDefinitionConfiguration,
        public typeNames?: string[]) {
    }
}