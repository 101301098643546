import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpDnsManagedZoneDnsSecSigningKeyAlgorithmTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpDnsManagedZoneDnsSecSigningKeyAlgorithmTranslator",
            () => ({
                [Contract.TypeNames.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm]: {
                    [Contract.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm.EcdsaP256Sha256]: "ECDSAP256SHA256",
                    [Contract.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm.EcdsaP384Sha384]: "ECDSAP384SHA384",
                    [Contract.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm.RsaSha1]: "RSASHA1",
                    [Contract.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm.RsaSha256]: "RSASHA256",
                    [Contract.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm.RsaSha512]: "RSASHA512"
                }
            }));
    return (signingKeyAlgorithm: Contract.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm) =>
        localization[Contract.TypeNames.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm][signingKeyAlgorithm]();
}