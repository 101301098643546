import { NoneIcon, NumberFormatter, useExecuteOperation } from "@infrastructure";
import { Typography } from "@mui/material";
import React, { memo } from "react";
import { Contract, RiskController, RiskPolicyItem } from "../../../../../common";

const AnalyzedEntityCountMemo = memo(AnalyzedEntityCount);
export { AnalyzedEntityCountMemo as AnalyzedEntityCount };

type AnalyzedEntityCountProps = {
    item: RiskPolicyItem;
    scopeId: string;
};

function AnalyzedEntityCount({ item, scopeId }: AnalyzedEntityCountProps) {
    return item.riskPolicyTypeMetadata.temporal || !item.enabled
        ? <NoneIcon/>
        : <Core
            item={item}
            scopeId={scopeId}/>;
}

function Core({ item, scopeId }: AnalyzedEntityCountProps) {
    const [riskPolicyConfigurationAnalyzedEntityCount] =
        useExecuteOperation(
            [Core, `${scopeId}/${item.riskPolicyConfigurationTypeNameOrId}`],
            async () => {
                const { riskPolicyDatas } =
                    await RiskController.getRiskPolicyAnalyzedEntityCount(
                        new Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequest(
                            [
                                new Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequestRiskPolicyData(
                                    Contract.RiskPolicyTypeMetadataAnalysisGroupType.AllEntities,
                                    item.riskPolicyConfigurationTypeNameOrId)
                            ],
                            scopeId));

                return riskPolicyDatas[0].analyzedEntityCount;
            },
            {
                persistenceStrategy: "always"
            });

    return (
        <Typography
            noWrap={true}
            sx={{ whiteSpace: "nowrap" }}>
            {NumberFormatter.humanize(riskPolicyConfigurationAnalyzedEntityCount ?? 0)}
        </Typography>);
}