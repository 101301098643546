import { InlineItems, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsLambdaFunctionConfigurationRiskContext } from "../contexts";

export function useAwsLambdaFunctionConfigurationDeprecatedRuntimeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsLambdaFunctionConfigurationDeprecatedRuntimeRisk;
    const functionConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsLambdaFunctionConfigurationModel;
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;

    const getAwsLambdaFunctionConfigurationRiskContext = useGetAwsLambdaFunctionConfigurationRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsLambdaFunctionConfigurationDeprecatedRuntimeRiskDefinition",
            () => ({
                contextItems: {
                    deprecatedRuntime: {
                        runtimes: [
                            "1 deprecated runtime",
                            "{{count | NumberFormatter.humanize}} deprecated runtimes"
                        ],
                        text: [
                            "The Lambda function has **{{versions}}** that is using **{{runtimes}}**",
                            "The Lambda function has **{{versions}}** that are using **{{runtimes}}**"
                        ],
                        versions: [
                            "1 version",
                            "{{count | NumberFormatter.humanize}} versions"
                        ]
                    }
                },
                description: "{{functionConfiguration}} is using deprecated runtime",
                sections: {
                    resolution: {
                        step1: "Older versions are immutable and cannot be modified. To remediate the issues, you need to publish a new version, update all aliases and dependent services to reference the newly created version, and then delete the outdated versions.",
                        step2: "In the **Runtime settings** section, click **Edit**",
                        step3: "In the **Runtime** dropdown, select the latest supported version of the runtime environment.",
                        step4: "Click **Save**",
                        step5: {
                            step1: "Click **Actions** and then **Publish new version**",
                            step2: "Fill in the **Version description** and click **Publish**",
                            step3: "Update all aliases and dependent services that reference older outdated versions, whether by ARN or specific version",
                            step4: "Before deleting the outdated versions, make sure your environment is properly configured and running",
                            step5: "Delete the outdated versions",
                            title: "Publish the new Lambda function version and delete older versions"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            functionConfiguration:
                <Entity
                    entityIdOrModel={functionConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Lambda,
                AwsConsoleUrlBuilder.getLambdaFunctionConfigurationUrl(functionConfiguration)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            new Step(
                localization.sections.resolution.step5.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step5.step1()}
                            {localization.sections.resolution.step5.step2()}
                            {localization.sections.resolution.step5.step3()}
                            {localization.sections.resolution.step5.step4()}
                            {localization.sections.resolution.step5.step5()}
                        </Steps>
                }
            )
        ],
        riskModel,
        () => {
            const functionConfigurationRiskContext = getAwsLambdaFunctionConfigurationRiskContext(functionConfigurationModel);

            return [
                functionConfigurationRiskContext.generalInformation,
                functionConfigurationRiskContext.accessLevel,
                new RiskDefinitionContextItem(
                    localization.contextItems.deprecatedRuntime.text(
                        risk.deprecatedRuntimeVersions.length,
                        {
                            runtimes:
                                <InlineItems
                                    items={risk.deprecatedRuntimes}
                                    namePluralizer={localization.contextItems.deprecatedRuntime.runtimes}
                                    variant="itemCountAndType"/>,
                            versions:
                                <InlineItems
                                    items={risk.deprecatedRuntimeVersions}
                                    namePluralizer={localization.contextItems.deprecatedRuntime.versions}
                                    variant="itemCountAndType"/>
                        })),
                functionConfigurationRiskContext.sensitive,
                functionConfigurationRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}