﻿import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { IconText, useLocalization } from "@infrastructure";
import { Contract, PrincipalIcon, PrincipalRiskCategoryHelper, usePrincipalTypeTranslator, useTheme } from "../../../../../../../../../..";
import { CustomGrid } from "../../../../components";
import { Ranking, RankingItem } from "./components";

type PrincipalRiskCategoryTenantRankingProps = {
    orderedPrincipalRiskCategories: Contract.PrincipalRiskCategory[];
    principalRiskCategoryToTenantIdToOpenRiskSeverityToPrincipalTimeFrameSummaryMap: Dictionary<Dictionary<Dictionary<Contract.AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessPrincipalTimeFrameSummary>>>;
    severities: Contract.Severity[];
    timeFrame: Contract.TimeFrame;
    title: string;
};

const maxItemCount = 4;

export function PrincipalRiskCategoryTenantRanking({ orderedPrincipalRiskCategories, principalRiskCategoryToTenantIdToOpenRiskSeverityToPrincipalTimeFrameSummaryMap, severities, timeFrame, title }: PrincipalRiskCategoryTenantRankingProps) {
    const principalTypeTranslator = usePrincipalTypeTranslator();
    const [selectedAccountsType, setSelectedAccountsType] = useState(Accounts.Bottom);

    const principalRiskCategoryToPrincipalTypeMap =
        useMemo(
            () =>
                _(orderedPrincipalRiskCategories).
                    keyBy(principalRiskCategory => principalRiskCategory).
                    mapValues(principalRiskCategory => PrincipalRiskCategoryHelper.getPrincipalType(principalRiskCategory)).
                    value(),
            [orderedPrincipalRiskCategories]);

    const principalRiskCategoryToDataMap =
        useMemo(
            () =>
                _(principalRiskCategoryToTenantIdToOpenRiskSeverityToPrincipalTimeFrameSummaryMap).
                    pickBy((_tenantIdToOpenRiskSeverityToPrincipalTimeFrameSummaryMap, principalRiskCategory) =>
                        _.includes(
                            orderedPrincipalRiskCategories,
                            principalRiskCategory as Contract.PrincipalRiskCategory)).
                    mapValues(
                        tenantIdToOpenRiskSeverityToPrincipalTimeFrameSummaryMap => {
                            const rankingItems =
                                _(tenantIdToOpenRiskSeverityToPrincipalTimeFrameSummaryMap).
                                    map(
                                        (openRiskSeverityToPrincipalTimeFrameSummaryMap, tenantId) => {
                                            const filteredSeverityPrincipalTimeFrameSummaryList =
                                                _(openRiskSeverityToPrincipalTimeFrameSummaryMap).
                                                    pickBy(
                                                        (_principalTimeFrameSummary, openRiskSeverity) =>
                                                            _.includes(
                                                                severities,
                                                                openRiskSeverity)).
                                                    values().
                                                    value();
                                            const endTimePrincipalCount =
                                                _.sumBy(
                                                    filteredSeverityPrincipalTimeFrameSummaryList,
                                                    principalTimeFrameSummary => principalTimeFrameSummary.endTimeCount);
                                            const startTimePrincipalCount =
                                                _.sumBy(
                                                    filteredSeverityPrincipalTimeFrameSummaryList,
                                                    principalTimeFrameSummary => principalTimeFrameSummary.startTimeCount);
                                            const delta = endTimePrincipalCount - startTimePrincipalCount;

                                            return new RankingItem(
                                                delta,
                                                endTimePrincipalCount,
                                                tenantId);
                                        }).
                                    value();

                            return {
                                bottomRankingItems: _(rankingItems).
                                    filter(rankingItem => rankingItem.delta > 0).
                                    orderBy(
                                        rankingItem => rankingItem.delta,
                                        "desc").
                                    take(maxItemCount).
                                    value(),
                                topRankingItems: _(rankingItems).
                                    filter(rankingItem => rankingItem.delta < 0).
                                    orderBy(
                                        rankingItem => rankingItem.delta,
                                        "asc").
                                    take(maxItemCount).
                                    value()
                            };
                        }).
                    value(),
            [principalRiskCategoryToTenantIdToOpenRiskSeverityToPrincipalTimeFrameSummaryMap, orderedPrincipalRiskCategories, severities]);

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetExcessivePermissionPrincipalTrendsDefinition.principalRiskCategoryTenantRanking",
            () => ({
                bottom: "Bottom",
                top: "Top"
            }));

    const theme = useTheme();
    return (
        <Stack
            className="principalRiskCategoryTenantRanking"
            sx={{ minHeight: theme.spacing(30) }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ paddingBottom: theme.spacing(1) }}>
                <Typography
                    noWrap={true}
                    sx={{ flex: 1 }}
                    variant="h4">
                    {title}
                </Typography>
                <ToggleButtonGroup
                    exclusive={true}
                    value={selectedAccountsType}
                    onChange={
                        (_event, type) =>
                            !_.isNil(type) &&
                            setSelectedAccountsType(type)}>
                    <ToggleButton
                        className="topButton"
                        value={Accounts.Top}>
                        <Typography>
                            {localization.top()}
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        className="bottomButton"
                        value={Accounts.Bottom}>
                        <Typography>
                            {localization.bottom()}
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <CustomGrid
                minItemWidth={200}
                sx={{ margin: 0 }}>
                {_.map(
                    orderedPrincipalRiskCategories,
                    principalRiskCategory => {
                        const principalType = principalRiskCategoryToPrincipalTypeMap[principalRiskCategory];
                        return (
                            <Stack
                                className={`item ${principalTypeTranslator(principalType).replaceAll(" ", "")}`}
                                key={principalType}
                                sx={{
                                    border: theme.border.primary,
                                    borderRadius: theme.spacing(0.75),
                                    minHeight: theme.spacing(25),
                                    minWidth: theme.spacing(25)
                                }}>
                                <IconText
                                    containerSx={{
                                        borderBottom: theme.border.primary,
                                        minWidth: "100%",
                                        padding: theme.spacing(1, 2)
                                    }}
                                    icon={
                                        <PrincipalIcon
                                            principalType={principalType}
                                            sx={{ fontSize: "16px" }}/>}
                                    text={
                                        <Typography
                                            noWrap={true}
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 600
                                            }}>
                                            {principalTypeTranslator(principalType)}
                                        </Typography>}/>
                                <Ranking
                                    items={
                                        selectedAccountsType === Accounts.Top
                                            ? principalRiskCategoryToDataMap[principalRiskCategory]?.topRankingItems
                                            : principalRiskCategoryToDataMap[principalRiskCategory]?.bottomRankingItems}
                                    principalType={principalType}
                                    severities={severities}
                                    timeFrame={timeFrame}
                                    variant={selectedAccountsType}/>
                            </Stack>);
                    })}
            </CustomGrid>
        </Stack>);
}

enum Accounts {
    Bottom = "bottom",
    Top = "top"
}