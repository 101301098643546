﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useOpCommonKubernetesNamespaceResourceInfoItemElements, useOpDefaultResourceInfoItemElements } from "..";
import { Contract, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { KubernetesRoleBindings, KubernetesYamlInfoCard } from "../../components";

export function useOpKubernetesServiceAccountDefinition(resourceModel: Contract.OpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useOpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOpDefaultResourceInfoItemElements(resourceModel);
    const serviceAccountModel = resourceModel as Contract.OpKubernetesServiceAccountModel;
    const serviceAccount = resourceModel.entity as Contract.OpKubernetesServiceAccount;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpKubernetesServiceAccountDefinition",
            () => ({
                info: {
                    containerImageRepositoryPullSecretNames: "Image Pull Secrets",
                    mountableSecretNames: "Mountable Secrets",
                    originatorWorkloadResourceIds: "Assigned To",
                    roleBindings: "Role Bindings"
                },
                tabs: {
                    roleBindings: "Role Bindings",
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <KubernetesRoleBindings principalOrRoleModel={serviceAccountModel}/>,
                localization.tabs.roleBindings(),
                "roleBindings"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={serviceAccount.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.tagsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={serviceAccountModel.originatorWorkloadResourceIds}
                        entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                        key="originatorWorkloadResourceIds"
                        title={localization.info.originatorWorkloadResourceIds()}/>,
                    <VerticalTopItemsInfoItem
                        items={serviceAccount.data.containerImageRepositoryPullSecretNames}
                        key="containerImageRepositoryPullSecretNames"
                        title={localization.info.containerImageRepositoryPullSecretNames()}/>,
                    <VerticalTopItemsInfoItem
                        items={serviceAccount.data.mountableSecretNames}
                        key="mountableSecretNames"
                        title={localization.info.mountableSecretNames()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}