import { Action0, ActionButton, EventHandlerRegister, makeContextProvider, MenuIcon, MouseClickAreaScope, useEvent } from "@infrastructure";
import { Box, List, Stack } from "@mui/material";
import React, { ReactNode, useMemo, useState } from "react";
import { sidebarItemSizeClose, sidebarItemSizeOpen } from ".";
import { SetViewRoute, StorageHelper, useTheme, ViewRoute } from "../..";

class SidebarContext {
    constructor(
        public open: boolean,
        public registerCloseSubItems: EventHandlerRegister<(element: HTMLElement | null) => void>,
        public reloadView: Action0,
        public rootRelativeUrl: string,
        public setViewRoute: SetViewRoute<string>,
        public triggerCloseSubItems: (element: HTMLElement | null) => void,
        public viewRoute: ViewRoute<string>) {
    }
}

export const [useSidebarContext, , useSidebarContextProvider] = makeContextProvider<SidebarContext>();

type SidebarProps = {
    bottomItems?: ReactNode;
    items: ReactNode;
    reloadView: Action0;
    rootRelativeUrl: string;
    setViewRoute: SetViewRoute<string>;
    viewRoute: ViewRoute<string>;
};

export function Sidebar({ bottomItems, items, reloadView, rootRelativeUrl, setViewRoute, viewRoute }: SidebarProps) {
    const [open, setOpen] = useState(!(StorageHelper.customerSidebarOpen.getValue() === "false"));
    const [registerCloseSubItems, triggerCloseSubItems] = useEvent<(element: HTMLElement | null) => void>();
    const [, , ContextProvider] =
        useSidebarContextProvider(
            () =>
                new SidebarContext(
                    open,
                    registerCloseSubItems,
                    reloadView,
                    rootRelativeUrl,
                    setViewRoute,
                    triggerCloseSubItems,
                    viewRoute),
            [open, viewRoute]);

    const theme = useTheme();
    const classes =
        useMemo(
            () => ({
                closed: {
                    overflowX: "hidden",
                    transition:
                        theme.transitions.create(
                            "width",
                            {
                                duration: theme.transitions.duration.leavingScreen,
                                easing: theme.transitions.easing.sharp
                            }),
                    width: sidebarItemSizeClose
                },
                opened: {
                    overflowX: "hidden",
                    transition:
                        theme.transitions.create(
                            "width",
                            {
                                duration: theme.transitions.duration.enteringScreen,
                                easing: theme.transitions.easing.sharp
                            }),
                    width: sidebarItemSizeOpen
                }
            }),
            [theme]);
    return (
        <ContextProvider>
            <Stack>
                <MouseClickAreaScope disabled={true}>
                    <Box
                        sx={{
                            fontSize: "20px",
                            padding: theme.spacing(0, 0.75)
                        }}>
                        <ActionButton
                            sx={{
                                "&:hover": {
                                    background: theme.palette.sidebar.hover
                                },
                                borderRadius: theme.spacing(0.75),
                                height: theme.px(42),
                                width: theme.px(42)
                            }}
                            variant="icon"
                            onClick={
                                () => {
                                    StorageHelper.customerSidebarOpen.setValue(!open);
                                    setOpen(!open);
                                }}>
                            <MenuIcon/>
                        </ActionButton>
                    </Box>
                </MouseClickAreaScope>
                <Box
                    sx={{
                        height: "fit-content",
                        overflow: "auto"
                    }}
                    onMouseLeave={() => triggerCloseSubItems(null)}>
                    <List
                        disablePadding={true}
                        sx={{
                            fontSize: "14px",
                            height: "100%",
                            position: "unset",
                            ...(open
                                ? classes.opened
                                : classes.closed)
                        }}>
                        {items}
                    </List>
                </Box>
                {bottomItems &&
                    <Box
                        sx={{
                            marginTop: "auto",
                            paddingBottom: theme.spacing(0.75)
                        }}
                        onMouseLeave={() => triggerCloseSubItems(null)}>
                        <List
                            disablePadding={true}
                            sx={{
                                fontSize: "14px",
                                position: "unset",
                                ...(open
                                    ? classes.opened
                                    : classes.closed)
                            }}>
                            {bottomItems}
                        </List>
                    </Box>}
            </Stack>
        </ContextProvider>);
}