import { Stack, Typography } from "@mui/material";
import React from "react";
import { useAwsResourceAccessLevelTranslator } from "../../";
import { Contract } from "../../../../common";
import { AwsResourceAccessLevelIcon } from "./icons";

type AwsResourceAccessLevelProps = {
    accessLevel: Contract.AwsResourceAccessLevel;
};

export function AwsResourceAccessLevel({ accessLevel }: AwsResourceAccessLevelProps) {
    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <AwsResourceAccessLevelIcon
                accessLevel={accessLevel}
                sx={{ fontSize: "18px" }}/>
            <Typography noWrap={true}>
                {resourceAccessLevelTranslator(accessLevel)}
            </Typography>
        </Stack>);
}