import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useKubernetesClusterPlatformTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useKubernetesClusterPlatformTranslator",
            () => ({
                [Contract.TypeNames.KubernetesClusterPlatform]: {
                    [Contract.KubernetesClusterPlatform.Kubernetes]: "Kubernetes",
                    [Contract.KubernetesClusterPlatform.OpenShift]: "OpenShift"
                }
            }));
    return (platform: Contract.KubernetesClusterPlatform) =>
        localization[Contract.TypeNames.KubernetesClusterPlatform][platform]();
}