﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useAutomationRuleAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAutomationRuleAuditEventDefinition",
            () => ({
                ruleDescription: "Description",
                ruleName: "Name"
            }));
    const automationRuleAuditEvent = context.auditEventModel.auditEvent as Contract.AutomationRuleAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.ruleName(),
                    automationRuleAuditEvent.name)).
            concatIf(
                !_.isNil(automationRuleAuditEvent.description),
                new AuditEventDefinitionDetailItem(
                    localization.ruleDescription(),
                    automationRuleAuditEvent.description!)).
            value());
}