import { DataTableColumnRenderProps } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, RiskHelper, useRiskPolicyTitleTranslator } from "../../../../../../../../../common";

export function TitleCell({ item }: DataTableColumnRenderProps<Contract.RiskModel>) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    return (
        <Typography noWrap={true}>
            {riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(item.risk))}
        </Typography>);
}