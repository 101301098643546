import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { GcpComputeFirewallRulesTable } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpComputeVpcRiskContext } from "../contexts";

export function useGcpComputeVpcUnrestrictedInboundAccessRiskDefinition(riskModel: Contract.RiskModel) {
    const computeVpcUnrestrictedInboundAccessRiskModel = riskModel as Contract.GcpComputeVpcUnrestrictedInboundAccessRiskModel;
    const vpcModel = entityModelStore.useGet(computeVpcUnrestrictedInboundAccessRiskModel.risk.entityId) as Contract.GcpComputeVpcModel;

    const getGcpComputeVpcRiskContext = useGetGcpComputeVpcRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeVpcUnrestrictedInboundAccessRiskDefinition",
            () => ({
                description: "{{vpc}} firewall rules allow inbound {{protocolName}} traffic from any IP address",
                sections: {
                    firewallRuleIds: "Firewall Rules",
                    resolution: {
                        step1: "For the relevant inbound rules, edit the address range to restrict it only to the IP Addresses that require access or delete it if the access is not required"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            protocolName: computeVpcUnrestrictedInboundAccessRiskModel.protocolName,
            vpc:
                <Entity
                    entityIdOrModel={vpcModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={computeVpcUnrestrictedInboundAccessRiskModel.risk.entityId}
                key={computeVpcUnrestrictedInboundAccessRiskModel.risk.entityId}
                page={Contract.GcpConsolePage.VpcFirewallRules}/>,
            localization.sections.resolution.step1()
        ],
        computeVpcUnrestrictedInboundAccessRiskModel,
        () => {
            const vpcRiskContext = getGcpComputeVpcRiskContext(vpcModel);
            return [
                vpcRiskContext.generalInformation,
                vpcRiskContext.sensitive,
                vpcRiskContext.subnets,
                vpcRiskContext.instances,
                vpcRiskContext.sensitiveInstances,
                vpcRiskContext.sensitiveSubnets,
                vpcRiskContext.severityPermissionActionInstances,
                vpcRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <GcpComputeFirewallRulesTable
                        firewallRuleIds={_.keys(computeVpcUnrestrictedInboundAccessRiskModel.risk.firewallRuleIdToUnrestrictedInboundAccessMap)}
                        getHighlightColor={
                            (firewallRule, opacity) =>
                                computeVpcUnrestrictedInboundAccessRiskModel.risk.firewallRuleIdToUnrestrictedInboundAccessMap[firewallRule.id]
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        : theme.palette.severity(riskModel.risk.severity)
                                    : undefined}/>,
                    localization.sections.firewallRuleIds())
            ]
        });
}