import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../..";

export function useAzureDeleteRoleAssignmentClassicAdministratorChangeDefinition(change: Contract.AzureChange, entityLinkDisabled?: boolean) {
    const deleteRoleAssignmentClassicAdministratorChange = change as Contract.AzureDeleteRoleAssignmentClassicAdministratorChange;
    const roleAssignmentClassicAdministrator = entityModelStore.useGet(deleteRoleAssignmentClassicAdministratorChange.resourceId).entity as Contract.AzureAuthorizationRoleAssignmentResource;
    const roleAssignmentClassicAdministratorScopeEntity = entityModelStore.useGet(deleteRoleAssignmentClassicAdministratorChange.scopeEntityId)?.entity as Contract.AzureEntity;
    const roleAssignmentClassicAdministratorPrincipalModel = entityModelStore.useGet(roleAssignmentClassicAdministrator.principalReference.id) as Contract.AadDirectoryPrincipalModel;

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAzureDefinition.hooks.useAzureDeleteRoleAssignmentClassicAdministratorChangeDefinition",
            () => ({
                title: "Remove {{principal}} as Classic administrator for {{scopeEntity}}"
            }));

    return {
        title:
            localization.title({
                principal:
                    <Entity
                        entityIdOrModel={roleAssignmentClassicAdministratorPrincipalModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>,
                scopeEntity:
                    <Entity
                        entityIdOrModel={roleAssignmentClassicAdministratorScopeEntity.id}
                        entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}