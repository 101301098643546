﻿import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../../common";
import { GenerateAzureIdentityNonexcessiveRoleDefinitionDialog } from "../../../../../../../../../../../../../../tenants";

type GenerateNonexcessiveRoleDefinitionProps = {
    excessivePermissionEvaluationStartDate?: string;
    principalModel: Contract.IPrincipalModel;
    scopeEntityId: string;
};

export function GenerateNonexcessiveRoleDefinition({ excessivePermissionEvaluationStartDate, principalModel, scopeEntityId }: GenerateNonexcessiveRoleDefinitionProps) {
    const [open, setOpen] = useState(false);
    const scopeEntityModel = entityModelStore.useGet(scopeEntityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureExcessivePermissionPrincipalRiskDefinition.generateNonexcessiveRoleDefinition",
            () => ({
                linkText: "custom role"
            }));

    return (
        <Fragment>
            {open &&
                <GenerateAzureIdentityNonexcessiveRoleDefinitionDialog
                    identityModel={principalModel}
                    selectionOptions={{
                        containerResourceIds: [scopeEntityId],
                        containerResourceTypeName: scopeEntityModel.entity.typeName,
                        excessivePermissionEvaluationStartDate
                    }}
                    onClose={() => setOpen(false)}/>}
            <Typography
                component="span"
                sx={{
                    cursor: "pointer",
                    textDecoration: "underline"
                }}
                onClick={() => setOpen(true)}>
                {localization.linkText()}
            </Typography>
        </Fragment>);
}