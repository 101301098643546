﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsS3BucketObjectOwnershipTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsS3BucketObjectOwnershipTranslator",
            () => ({
                [Contract.TypeNames.AwsS3BucketObjectOwnership]: {
                    [Contract.AwsS3BucketObjectOwnership.BucketOwnerEnforced]: "Bucket Owner Enforced",
                    [Contract.AwsS3BucketObjectOwnership.BucketOwnerPreferred]: "Bucket Owner Preferred",
                    [Contract.AwsS3BucketObjectOwnership.ObjectWriter]: "Object Writer"
                }
            }));
    return (objectOwnership: Contract.AwsS3BucketObjectOwnership) =>
        localization[Contract.TypeNames.AwsS3BucketObjectOwnership][objectOwnership]();
}