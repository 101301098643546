import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, NumberFormatter, PagedValuesFilter, TimeSpanFormatter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAwsCommonCloudWatchLoggingResourceDefinition } from "./useAwsCommonCloudWatchLoggingResourceDefinition";

export function useAwsCommonRdsDatabaseInstanceResourceDefinition(
    clusterTypeName: string,
    definitionData: DefinitionData) {
    const commonCloudWatchLoggingResourceDefinition = useAwsCommonCloudWatchLoggingResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonRdsDatabaseInstanceResourceDefinition",
            () => ({
                columns: {
                    backupRetentionTimeFrame: "Automatic Backup Retention Period",
                    cloudWatchLogging: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "CloudWatch Logging"
                    },
                    deleteProtection: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Delete Protection"
                    },
                    endpoint: "Endpoint",
                    engineVersion: "Engine Version",
                    instanceRawType: "Instance Class",
                    minorVersionUpgradeEnabled: {
                        false: "Disabled",
                        title: "Auto Minor Version Upgrade",
                        true: "Enabled"
                    },
                    status: "Status",
                    storageSize: "Size"
                }
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            clusterTypeName,
            {
                includeServiceName: true,
                variant: "title"
            });

    function getMinorVersionUpgradeEnabledTranslation(instanceResourceModel: Contract.AwsRdsDatabaseInstanceResourceModel) {
        return (instanceResourceModel.entity as Contract.AwsRdsDatabaseInstanceResource).minorVersionUpgradeEnabled
            ? localization.columns.minorVersionUpgradeEnabled.true()
            : localization.columns.minorVersionUpgradeEnabled.false();
    }

    return {
        columns: {
            ...commonCloudWatchLoggingResourceDefinition.columns,
            backupRetentionTimeFrameColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsDatabaseInstanceResourceModel) => ({
                                [localization.columns.backupRetentionTimeFrame()]: TimeSpanFormatter.day((item.entity as Contract.AwsRdsDatabaseInstanceResource).backupRetentionTimeFrame)
                            })
                    }}
                    id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceBackupRetentionTimeFrame}
                    itemProperty={(item: Contract.AwsRdsDatabaseInstanceResourceModel) => TimeSpanFormatter.day((item.entity as Contract.AwsRdsDatabaseInstanceResource).backupRetentionTimeFrame)}
                    key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceBackupRetentionTimeFrame}
                    selectorOptions={{ default: false }}
                    sortOptions={{
                        type: DataTableSortType.Numeric
                    }}
                    title={localization.columns.backupRetentionTimeFrame()}/>,
            clusterColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsRdsDatabaseInstanceResourceModel>(
                            translatedClusterTypeName,
                            item => [(item.entity as Contract.AwsRdsDatabaseInstanceResource).clusterId],
                            translatedClusterTypeName)}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceCluster)}
                                    placeholder={translatedClusterTypeName}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceCluster}
                    key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceCluster}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsRdsDatabaseInstanceResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AwsRdsDatabaseInstanceResource).clusterId}
                                entityTypeName={clusterTypeName}
                                entityVariant="iconText"/>}
                    title={translatedClusterTypeName}/>,
            deleteProtectionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsDatabaseInstanceResourceModel) => ({
                                [localization.columns.deleteProtection.title()]:
                                    (item.entity as Contract.AwsRdsDatabaseInstanceResource).deleteProtection
                                        ? localization.columns.deleteProtection.enabled()
                                        : localization.columns.deleteProtection.disabled()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.deleteProtection.title()}>
                                    <ValuesFilterItem
                                        key="false"
                                        title={localization.columns.deleteProtection.disabled()}
                                        value={false}/>
                                    <ValuesFilterItem
                                        key="true"
                                        title={localization.columns.deleteProtection.enabled()}
                                        value={true}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceDeleteProtection}
                    itemProperty={
                        (item: Contract.AwsRdsDatabaseInstanceResourceModel) =>
                            (item.entity as Contract.AwsRdsDatabaseInstanceResource).deleteProtection
                                ? localization.columns.deleteProtection.enabled()
                                : localization.columns.deleteProtection.disabled()}
                    key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceDeleteProtection}
                    selectorOptions={{ default: false }}
                    title={localization.columns.deleteProtection.title()}/>,
            endpointColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsDatabaseInstanceResourceModel) => ({
                                [localization.columns.endpoint()]: (item.entity as Contract.AwsRdsDatabaseInstanceResource).endpoint
                            })
                    }}
                    id="endpoint"
                    key="endpoint"/>,
            engineVersionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsDatabaseInstanceResourceModel) => ({
                                [localization.columns.engineVersion()]: (item.entity as Contract.AwsRdsDatabaseInstanceResource).engineVersion
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceEngineVersion)}
                                    placeholder={localization.columns.engineVersion()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceEngineVersion}
                    itemProperty={(item: Contract.AwsRdsDatabaseInstanceResourceModel) => (item.entity as Contract.AwsRdsDatabaseInstanceResource).engineVersion}
                    key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceEngineVersion}
                    selectorOptions={{ default: false }}
                    title={localization.columns.engineVersion()}/>,
            instanceRawTypeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsDatabaseInstanceResourceModel) => ({
                                [localization.columns.instanceRawType()]: (item.entity as Contract.AwsRdsDatabaseInstanceResource).instanceRawType ?? ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceRawType)}
                                    placeholder={localization.columns.instanceRawType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceRawType}
                    itemProperty={(item: Contract.AwsResourceModel) => (item.entity as Contract.AwsRdsDatabaseInstanceResource).instanceRawType}
                    key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceRawType}
                    selectorOptions={{ default: false }}
                    title={localization.columns.instanceRawType()}/>,
            minorVersionUpgradeEnabledColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsDatabaseInstanceResourceModel) => ({
                                [localization.columns.minorVersionUpgradeEnabled.title()]: getMinorVersionUpgradeEnabledTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.minorVersionUpgradeEnabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.minorVersionUpgradeEnabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.minorVersionUpgradeEnabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceMinorVersionUpgradeEnabled}
                    itemProperty={getMinorVersionUpgradeEnabledTranslation}
                    key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceMinorVersionUpgradeEnabled}
                    selectorOptions={{ default: false }}
                    title={localization.columns.minorVersionUpgradeEnabled.title()}/>,
            statusColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsDatabaseInstanceResourceModel) => ({
                                [localization.columns.status()]: (item.entity as Contract.AwsRdsDatabaseInstanceResource).status
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceStatus)}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceStatus}
                    itemProperty={(item: Contract.AwsRdsDatabaseInstanceResourceModel) => (item.entity as Contract.AwsRdsDatabaseInstanceResource).status}
                    key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceStatus}
                    title={localization.columns.status()}/>,
            storageSizeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsDatabaseInstanceResourceModel) => ({
                                [localization.columns.storageSize()]: NumberFormatter.storage((item.entity as Contract.AwsRdsDatabaseInstanceResource).storageSize)
                            })
                    }}
                    id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceStorageSize}
                    itemProperty={(item: Contract.AwsResourceModel) => NumberFormatter.storage((item.entity as Contract.AwsRdsDatabaseInstanceResource).storageSize)}
                    key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceStorageSize}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.storageSize()}/>
        }
    };
}