import { UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { RiskPoliciesType } from "../../../../../../../common";
import { useItems } from "../../../hooks";

export function useItem(type: RiskPoliciesType, scopeId: string, riskPolicyConfigurationTypeNameOrId: string) {
    const items =
        useItems(
            type,
            false,
            scopeId);

    const item =
        useMemo(
            () =>
                _.find(
                    items,
                    item => item.riskPolicyConfigurationTypeNameOrId === riskPolicyConfigurationTypeNameOrId),
            [items, riskPolicyConfigurationTypeNameOrId]);

    if (_.isNil(item)) {
        throw new UnexpectedError("useItem.item");
    }

    return item;
}