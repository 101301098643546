import { useTheme } from "@mui/material";
import _ from "lodash";
import React, { Children, Fragment, ReactNode } from "react";
import { DirectedGraphModelNodeContentSize, DirectedGraphModelNodeEdgeAnchorPoint, getDirectedGraphEdgeAppearanceProps, useDirectedGraphNodeContext } from ".";

export type HorizontalLinkedNodeContentProps = {
    children: ReactNode;
    childrenLeftAnchorPoint: DirectedGraphModelNodeEdgeAnchorPoint;
    childrenRightAnchorPoint: DirectedGraphModelNodeEdgeAnchorPoint;
    childrenSize: DirectedGraphModelNodeContentSize;
};

export function HorizontalLinkedNodeContent({ children, childrenLeftAnchorPoint, childrenRightAnchorPoint, childrenSize }: HorizontalLinkedNodeContentProps) {
    const { modelNode } = useDirectedGraphNodeContext();
    const theme = useTheme();
    const childElements = Children.toArray(children);
    const { width } = getHorizontalLinkedNodeContentSize(childElements.length, childrenSize);
    return (
        <Fragment>
            <line
                stroke={
                    modelNode.options.appearance === "highlighted"
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary}
                x1={childrenRightAnchorPoint.x}
                x2={width - childrenSize.width + childrenLeftAnchorPoint.x}
                y1={childrenRightAnchorPoint.y}
                y2={childrenLeftAnchorPoint.y}
                {...getDirectedGraphEdgeAppearanceProps(modelNode.options.appearance)}/>
            {_.map(
                childElements,
                (childElement, childElementIndex) =>
                    <g
                        key={childElementIndex}
                        transform={`translate(${childElementIndex * (childrenSize.width + linkedNodesSpacing)}, 0)`}>
                        {childElement}
                    </g>)}
        </Fragment>);
}

const linkedNodesSpacing = 20;

export const getHorizontalLinkedNodeContentAnchorPoint =
    (anchorPlacement: "left" | "right",
        childrenCount: number,
        childrenLeftAnchorPoint: DirectedGraphModelNodeEdgeAnchorPoint,
        childrenRightAnchorPoint: DirectedGraphModelNodeEdgeAnchorPoint,
        childrenSize: DirectedGraphModelNodeContentSize) => {
        if (anchorPlacement === "left") {
            return childrenLeftAnchorPoint;
        } else {
            const size = getHorizontalLinkedNodeContentSize(childrenCount, childrenSize);
            return ({
                x: childrenRightAnchorPoint.x + size.width - childrenSize.width,
                y: childrenRightAnchorPoint.y
            });
        }
    };

export const getHorizontalLinkedNodeContentSize =
    (childrenCount: number, childrenSize: DirectedGraphModelNodeContentSize) => ({
        height: childrenSize.height,
        width: childrenCount * childrenSize.width + linkedNodesSpacing * (childrenCount - 1)
    });