﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { VulnerabilitiesCell } from "../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem } from "../../../../components";

export function useAwsCommonKubernetesWorkloadResourceInfoItemElements(workloadResourceModel: Contract.AwsKubernetesWorkloadResourceModel) {
    const workloadResource = workloadResourceModel.entity as Contract.AwsKubernetesWorkloadResourceBase;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonKubernetesWorkloadResourceInfoItemElements",
            () => ({
                awsRoleIds: [
                    "AWS Role",
                    "AWS Roles"
                ],
                containerImages: "Container Images",
                containerImageVulnerabilities: "Vulnerabilities",
                controllerResourceReference: "Owner",
                fargateProfileId: "Fargate Profile",
                nodeGroupIds: "Node Groups",
                serviceAccountIdReference: "Service Account"
            }));

    return {
        awsRoleInfoItemElement:
            _.isEmpty(workloadResourceModel.awsRoleIds)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.awsRoleIds}
                    entityTypeName={Contract.TypeNames.AwsIamRole}
                    key="awsRoleIds"
                    title={localization.awsRoleIds(_.size(workloadResourceModel.awsRoleIds))}/>,
        containerImagesInfoItemElement:
            _.isEmpty(workloadResourceModel.containerImageIdReferences)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.containerImageIdReferences}
                    entityTypeName={Contract.TypeNames.IContainerImage}
                    key="containerImages"
                    title={localization.containerImages()}/>,
        containerImageVulnerabilitiesInfoItemElement:
            _.isEmpty(workloadResourceModel.containerImageVulnerabilities)
                ? undefined
                : <InfoItem
                    key="containerImageVulnerabilities"
                    title={localization.containerImageVulnerabilities()}
                    value={
                        <VulnerabilitiesCell
                            containerImageScanStatus={workloadResourceModel.containerImageScanStatus}
                            variant="initialAndCount"
                            vulnerabilities={workloadResourceModel.containerImageVulnerabilities}/>}/>,
        controllerResourceInfoItemElement:
            _.isNil(workloadResourceModel.controllerResourceReference)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.controllerResourceReference.idReference}
                    entityTypeName={Contract.TypeNames.AwsKubernetesResource}
                    key="controllerResourceReference"
                    location="all"
                    title={localization.controllerResourceReference()}/>,
        fargateProfileInfoItemElement:
            _.isNil(workloadResourceModel.fargateProfileId)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.fargateProfileId}
                    entityTypeName={Contract.TypeNames.AwsEksFargateProfile}
                    key="fargateProfileIds"
                    title={localization.fargateProfileId()}/>,
        nodeGroupsInfoItemElement:
            _.isEmpty(workloadResourceModel.nodeGroupIds)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.nodeGroupIds}
                    entityTypeName={Contract.TypeNames.AwsEksClusterNodeGroup}
                    key="nodeGroupIds"
                    title={localization.nodeGroupIds()}/>,
        serviceAccountInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={workloadResource.data.podTemplateSpec.podSpec.serviceAccountReference.idReference}
                entityTypeName={Contract.TypeNames.AwsKubernetesServiceAccount}
                key="serviceAccountIdReference"
                title={localization.serviceAccountIdReference()}/>
    };
}