import { DataTableColumn, DataTableSortType, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceDefinition, useAwsCommonEncryptedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2LaunchTemplateDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2LaunchTemplateDefinition",
            () => ({
                columns: {
                    resources: {
                        autoScalingGroups: "Auto Scaling Groups",
                        instances: "Instances",
                        title: "Resources"
                    },
                    vpcIds: "VPC"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2LaunchTemplateModel>(
                            Contract.TypeNames.AwsAutoScalingAutoScalingGroup,
                            item =>
                                _(item.revisionIdToDataMap).
                                    values().
                                    flatMap(revisionData => revisionData.autoScalingGroupIds).
                                    concat(item.deletedRevisionAutoScalingGroupIds).
                                    uniq().
                                    value(),
                            localization.columns.resources.autoScalingGroups())}
                    id="autoScalingGroupIds"
                    key="autoScalingGroupIds"/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2LaunchTemplateModel>(
                            Contract.TypeNames.AwsEc2Instance,
                            item =>
                                _(item.revisionIdToDataMap).
                                    values().
                                    flatMap(revisionData => revisionData.instanceIds).
                                    concat(item.deletedRevisionInstanceIds).
                                    uniq().
                                    value(),
                            localization.columns.resources.instances())}
                    id="instances"
                    key="instances"/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2LaunchTemplateResources)}
                                    placeholder={localization.columns.resources.title()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2LaunchTemplateResources}
                    key={Contract.EntityModelProperty.AwsEc2LaunchTemplateResources}
                    render={
                        optionalTableCell<Contract.AwsEc2LaunchTemplateModel>(
                            item => {
                                const resourceIds =
                                    _(item.revisionIdToDataMap).
                                        values().
                                        flatMap(
                                            revisionData =>
                                                _.concat(
                                                    revisionData.autoScalingGroupIds,
                                                    revisionData.instanceIds)).
                                        concat(item.deletedRevisionAutoScalingGroupIds).
                                        concat(item.deletedRevisionInstanceIds).
                                        uniq().
                                        value();
                                return _.isEmpty(resourceIds)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={resourceIds}
                                        entityTypeName={Contract.TypeNames.AwsResource}
                                        entityVariant="iconText"/>;
                            })}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.resources.title()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2LaunchTemplateModel>(
                            Contract.TypeNames.AwsEc2Vpc,
                            item =>
                                _((item.entity as Contract.AwsEc2LaunchTemplate).revisionMap).
                                    values().
                                    flatMap(revision => revision.vpcIds).
                                    uniq().
                                    value(),
                            localization.columns.vpcIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2LaunchTemplateVpcs)}
                                    placeholder={localization.columns.vpcIds()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2LaunchTemplateVpcs}
                    key={Contract.EntityModelProperty.AwsEc2LaunchTemplateVpcs}
                    render={
                        optionalTableCell<Contract.AwsEc2LaunchTemplateModel>(
                            item => {
                                const vpcIds =
                                    _((item.entity as Contract.AwsEc2LaunchTemplate).revisionMap).
                                        values().
                                        flatMap(revision => revision.vpcIds).
                                        uniq().
                                        value();
                                return _.isEmpty(vpcIds)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={vpcIds}
                                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                                        entityVariant="iconText"/>;
                            })}
                    title={localization.columns.vpcIds()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2LaunchTemplateRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2LaunchTemplateRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2LaunchTemplateResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2LaunchTemplateVpcs])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}