import { UnexpectedError, useLocalization, Wizard } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore } from "../..";
import { useEntityTypeNameTranslator } from "../../hooks";
import { useDefinition } from "./hooks";
import { useRiskResolutionAutomationContextProvider } from "./utilities";

export type RiskResolutionAutomationProps = {
    changeModels: Contract.ChangeModel[];
    onFinished: (canceled: boolean) => void;
    riskModelMap: Dictionary<Contract.RiskModel>;
};

export function RiskResolutionAutomation({ changeModels, onFinished, riskModelMap }: RiskResolutionAutomationProps) {
    const riskedEntityId =
        useMemo(
            () => {
                const riskedEntityIds =
                    _(riskModelMap).
                        map(riskModel => (riskModel.risk as Contract.EntityRisk).entityId).
                        uniq().
                        value();
                if (_.size(riskedEntityIds) !== 1) {
                    throw new UnexpectedError("riskedEntityIds", _.join(riskedEntityIds, ","));
                }

                return riskedEntityIds[0];
            },
            []);

    const riskedEntityModel = entityModelStore.useGet(riskedEntityId);

    const definition = useDefinition(changeModels, riskedEntityModel);

    const [, , ContextProvider] = useRiskResolutionAutomationContextProvider(() => definition.createContext());

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.riskResolutionAutomation",
            () => ({
                title: "**Automatic Remediation** for {{translatedEntityTypeName}} **{{entityDisplayName}}**"
            }));

    return (
        <ContextProvider>
            <Wizard
                titleOptions={{
                    text:
                        localization.title({
                            entityDisplayName: riskedEntityModel.entity.displayName,
                            translatedEntityTypeName:
                                entityTypeNameTranslator(
                                    riskedEntityModel.entity.typeName,
                                    {
                                        includeServiceName: false,
                                        variant: "title"
                                    })
                        })
                }}
                onFinished={onFinished}>
                {definition.wizardItems}
            </Wizard>
        </ContextProvider>);
}