﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useSystemKubernetesClusterApiConnectivityStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.kubernetes.hooks.useSystemKubernetesClusterApiConnectivityStatusTranslator",
            () => ({
                [Contract.TypeNames.SystemKubernetesClusterApiConnectivityStatus]: {
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorApplicationIpAddressNotConfigured]: "No network access",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAuthenticationFailure]: "Missing permissions",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAzureAuthenticationCustomRoleNotConfigured]: "Connected with issues",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAzureAuthenticationIdentityDisabled]: "Missing permissions",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorClusterNotRunning]: "Inactive cluster",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorConnectionFailure]: "Connection failed",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionNotConnected]: "No network access",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionNotExist]: "No network access",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionUnstable]: "Connection lost",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.Pending]: "Pending",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.Success]: "Connected"
                }
            }));
    return (status: Contract.SystemKubernetesClusterApiConnectivityStatus) =>
        localization[Contract.TypeNames.SystemKubernetesClusterApiConnectivityStatus][status]();
}