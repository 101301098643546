import { Action0, Tooltip } from "@infrastructure";
import { Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../themes";

type BadgeProps = {
    onClick?: Action0;
    sx?: SxProps;
    text: string;
    tooltip?: string;
};

export function Badge({ onClick, sx = {}, text, tooltip }: BadgeProps) {
    const theme = useTheme();
    return (
        <Tooltip
            disableInteractive={true}
            titleOrGetTitle={tooltip}>
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    background: theme.palette.action.hover,
                    borderRadius: theme.spacing(0.75),
                    color: theme.palette.text.secondary,
                    height: 28,
                    padding: theme.spacing(0.5, 1),
                    ...(!_.isNil(onClick) && {
                        cursor: "pointer"
                    }),
                    ...sx
                }}
                onClick={() => onClick?.()}>
                <Typography noWrap={true}>
                    {text}
                </Typography>
            </Stack>
        </Tooltip>);
}