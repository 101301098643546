import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, Optional, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, IntRangeHelper, NetworkScopeFormatter, NetworkScopeHelper, useEntityTypeNameTranslator } from "../../../common";

type GcpComputeFirewallRulesTableProps = {
    firewallRuleIds: string[];
    getHighlightColor?: (firewallRule: Contract.GcpComputeFirewallRule, opacity?: number) => Optional<string>;
    sx?: SxProps;
};

export function GcpComputeFirewallRulesTable({ firewallRuleIds, getHighlightColor, sx }: GcpComputeFirewallRulesTableProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const firewallRuleModels = entityModelStore.useGet(firewallRuleIds);
    const localization =
        useLocalization(
            "tenants.gcp.gcpComputeFirewallRulesTable",
            () => ({
                columns: {
                    action: {
                        title: "Action",
                        [Contract.TypeNames.GcpComputeFirewallRuleAction]: {
                            [Contract.GcpComputeFirewallRuleAction.Allow]: "Allow",
                            [Contract.GcpComputeFirewallRuleAction.Deny]: "Deny"
                        }
                    },
                    direction: {
                        title: "Type",
                        [Contract.TypeNames.GcpComputeFirewallRuleDirection]: {
                            [Contract.GcpComputeFirewallRuleDirection.Outbound]: "Egress",
                            [Contract.GcpComputeFirewallRuleDirection.Inbound]: "Ingress"
                        }
                    },
                    filters: "Filters",
                    name: "Name",
                    priority: "Priority",
                    scopes: "Protocol / Ports",
                    target: {
                        empty: "Apply to all",
                        title: "Targets"
                    }
                },
                empty: "No {{translatedEntityTypeName}}"
            }));

    return (
        <DataTable
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty({
                            translatedEntityTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpComputeFirewallRule,
                                    { count: 0 })
                        }))
            }}
            fetchItems={
                () =>
                    _.orderBy(
                        firewallRuleModels,
                        firewallRuleModel => (firewallRuleModel.entity as Contract.GcpComputeFirewallRule).priority)}
            getItemId={(item: Contract.GcpComputeFirewallRule) => item.id}
            rowOptions={{
                getHighlightColor: (item: Contract.GcpComputeFirewallRule) => getHighlightColor?.(item),
                getSx:
                    (item: Contract.GcpComputeFirewallRule) => ({
                        backgroundColor: getHighlightColor?.(item, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            sx={sx}
            variant="card">
            <DataTableColumn
                id={FirewallRuleColumnId.Name}
                itemProperty={(firewallRuleModel: Contract.GcpComputeFirewallRuleModel) => (firewallRuleModel.entity as Contract.GcpComputeFirewallRule).name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={FirewallRuleColumnId.Direction}
                itemProperty={(firewallRuleModel: Contract.GcpComputeFirewallRuleModel) => localization.columns.direction[Contract.TypeNames.GcpComputeFirewallRuleDirection][(firewallRuleModel.entity as Contract.GcpComputeFirewallRule).direction]()}
                title={localization.columns.direction.title()}/>
            <DataTableColumn
                id={FirewallRuleColumnId.Target}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeFirewallRuleModel>) => {
                        const firewallRule = item.entity as Contract.GcpComputeFirewallRule;
                        const targets =
                            _.concat(
                                firewallRule.target.networkTags,
                                item.targetServiceAccountIdReferences);
                        return firewallRule.target.any
                            ? <Typography noWrap={true}> {localization.columns.target.empty()} </Typography>
                            : <InlineItems
                                items={targets}
                                variant="itemPlusItemCount">
                                {target =>
                                    _.includes(item.targetServiceAccountIdReferences, target)
                                        ? <Entity
                                            entityIdOrModel={target}
                                            variant="iconTextTenant"/>
                                        : <Typography noWrap={true}>
                                            {target}
                                        </Typography>}
                            </InlineItems>;
                    }}
                title={localization.columns.target.title()}/>
            <DataTableColumn
                id={FirewallRuleColumnId.Filters}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeFirewallRuleModel>) => {
                        const firewallRule = item.entity as Contract.GcpComputeFirewallRule;
                        return (
                            <InlineItems
                                items={_<string>([]).
                                    concatIf(
                                        firewallRule.direction === Contract.GcpComputeFirewallRuleDirection.Inbound,
                                        () => [
                                            ...firewallRule.filter.sourceSubnets,
                                            ...firewallRule.filter.inboundVpc?.networkTags ?? [],
                                            ...item.filterInboundVpcServiceAccountIdReferences
                                        ]).
                                    concatIf(
                                        firewallRule.direction === Contract.GcpComputeFirewallRuleDirection.Outbound,
                                        () => firewallRule.filter.destinationSubnets).
                                    value()}
                                variant="itemPlusItemCount">
                                {filter =>
                                    _.includes(item.filterInboundVpcServiceAccountIdReferences, filter)
                                        ? <Entity
                                            entityIdOrModel={filter}
                                            variant="iconTextTenant"/>
                                        : <Typography noWrap={true}>
                                            {filter}
                                        </Typography>}
                            </InlineItems>);
                    }}
                title={localization.columns.filters()}/>
            <DataTableColumn
                id={FirewallRuleColumnId.Scopes}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeFirewallRuleModel>) =>
                        <InlineItems
                            items={
                                _.map(
                                    (item.entity as Contract.GcpComputeFirewallRule).scopes,
                                    ({ destinationPortRanges, protocolRange }) => {
                                        const formattedPortRanges =
                                            _.map(
                                                destinationPortRanges,
                                                destinationPortRange =>
                                                    NetworkScopeHelper.isAll(destinationPortRange)
                                                        ? "ALL"
                                                        : IntRangeHelper.format(destinationPortRange));
                                        const protocolRanges = NetworkScopeFormatter.protocolRange(protocolRange, Contract.TenantType.Gcp);
                                        return _.isEmpty(destinationPortRanges)
                                            ? protocolRanges
                                            : `${protocolRanges}: ${_.join(formattedPortRanges, ", ")}`;
                                    })}
                            variant="itemPlusItemCount"/>}
                title={localization.columns.scopes()}/>
            <DataTableColumn
                id={FirewallRuleColumnId.Action}
                itemProperty={(firewallRuleModel: Contract.GcpComputeFirewallRuleModel) => localization.columns.action[Contract.TypeNames.GcpComputeFirewallRuleAction][(firewallRuleModel.entity as Contract.GcpComputeFirewallRule).action]()}
                title={localization.columns.action.title()}/>
            <DataTableColumn
                id={FirewallRuleColumnId.Priority}
                itemProperty={(firewallRuleModel: Contract.GcpComputeFirewallRuleModel) => (firewallRuleModel.entity as Contract.GcpComputeFirewallRule).priority}
                title={localization.columns.priority()}/>
        </DataTable>);
}

enum FirewallRuleColumnId {
    Action = "action",
    Direction = "direction",
    Filters = "filters",
    Name = "name",
    Priority = "priority",
    Scopes = "scopes",
    Target = "target"
}