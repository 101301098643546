import { NoneIcon, Optional } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useNetworkInboundAccessTypeTranslator } from "../../views/Customer/components/Entities/hooks";
import { Contract } from "../controllers";

type NetworkInboundAccessTypeCellProps = {
    networkInboundAccessType: Optional<Contract.NetworkInboundAccessType>;
    variant?: "default" | "risk";
};

export function NetworkInboundAccessTypeCell({ networkInboundAccessType, variant = "default" }: NetworkInboundAccessTypeCellProps) {
    const networkInboundAccessTypeTranslator = useNetworkInboundAccessTypeTranslator();
    return (
        _.isNil(networkInboundAccessType) ||
        networkInboundAccessType === Contract.NetworkInboundAccessType.Internal
            ? <NoneIcon
                sx={{
                    fontSize:
                        variant === "risk"
                            ? "24px"
                            : "18px"
                }}/>
            : <Typography noWrap={true}>
                {networkInboundAccessTypeTranslator(networkInboundAccessType)}
            </Typography>);
}