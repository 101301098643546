﻿import { Grid } from "@mui/material";
import React from "react";
import { RiskPolicyItem } from "../../../../../../../../common";
import { GroupedItem } from "../../../../../../../Customer/components";
import { Data, Title } from "./components";

type ItemProps = {
    groupedItem: GroupedItem;
    pageFirstItem: boolean;
    pageLastItem: boolean;
    scopeId: string;
    tableItem: RiskPolicyItem;
    tableItemIndex: number;
};

export function Item({ groupedItem, pageFirstItem, pageLastItem, scopeId, tableItem, tableItemIndex }: ItemProps) {
    return (
        <Grid
            container={true}
            direction="column">
            {(pageFirstItem || tableItemIndex === 0) &&
                <Title
                    categoryTitle={tableItemIndex === 0}
                    groupedItem={groupedItem}/>}
            <Data
                borderBottom={
                    pageLastItem ||
                    tableItemIndex === groupedItem.tableItems.length - 1}
                scopeId={scopeId}
                tableItem={tableItem}/>
        </Grid>);
}