import { DataTableColumn, DataTableColumnRenderProps, Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { AwsResourceAccessLevel, AwsResourceAccessLevelFilter, useAwsResourceAccessLevelTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";

export type useAwsCommonAccessResourceDefinitionOptions = {
    accessLevel?: {
        title?: string;
        warningMessage?: string;
    };
};

export function useAwsCommonAccessResourceDefinition(definitionData: DefinitionData, options?: useAwsCommonAccessResourceDefinitionOptions) {
    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonAccessResourceDefinition",
            () => ({
                columns: {
                    accessLevel: {
                        title: "Access",
                        unknown: "Unknown"
                    }
                }
            }));

    const getAccessLevelColumn =
        (title?: string, warningMessage?: string) => {
            title = title ?? localization.columns.accessLevel.title();
            return <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IAwsAccessResourceModel) => ({
                            [title!]:
                                _.isNil(item.accessLevel)
                                    ? ""
                                    : resourceAccessLevelTranslator(item.accessLevel)
                        })
                }}
                filterOptions={{
                    itemOrItems: { element: <AwsResourceAccessLevelFilter placeholder={title}/> }
                }}
                id={Contract.EntityModelProperty.AwsResourceAccessLevel}
                key={Contract.EntityModelProperty.AwsResourceAccessLevel}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.IAwsAccessResourceModel>) =>
                        _.isNil(item.accessLevel)
                            ? <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <Typography noWrap={true}>
                                    {localization.columns.accessLevel.unknown()}
                                </Typography>
                                {!_.isNil(warningMessage) &&
                                    <Message
                                        level="infoWarning"
                                        title={warningMessage}
                                        variant="minimal"/>}
                            </Stack>
                            : <AwsResourceAccessLevel accessLevel={item.accessLevel}/>}
                title={title}/>;
        };

    return {
        columns: {
            accessLevelColumn: getAccessLevelColumn(
                options?.accessLevel?.title,
                options?.accessLevel?.warningMessage)
        }
    };
}