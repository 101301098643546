import { Optional, useLocalization, useRoute, useSetRoute, VerticalTabView } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ProfileLayout, ProfileTopbar, Severity, VulnerabilitiesIcon, vulnerabilityModelStore } from "../../../../../../../common";
import { CategoryView } from "./CategoryView";
import { Overview, RiskImpact, Sources, Timeline } from "./components";
import { CategoryIcon } from "./icons";
import { ElementClass } from "./Profile.element";

type ProfileProps = {
    rawId: string;
};

export function Profile({ rawId }: ProfileProps) {
    const vulnerabilityModel = vulnerabilityModelStore.useGet(rawId) as Optional<Contract.VulnerabilityModel>;
    const { category } = useRoute(`${CustomerConsoleAppUrlHelper.getVulnerabilityHashUrl(rawId)}/{category}`);
    const setRoute = useSetRoute();

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile",
            () => ({
                category: {
                    [CategoryView.Timeline]: "Timeline",
                    [CategoryView.Overview]: "Overview",
                    [CategoryView.RiskImpact]: "Risk Impact",
                    [CategoryView.Sources]: "Sources"
                },
                tenableVulnerabilityIntelligenceLink: {
                    title: "Tenable Vulnerability Intelligence",
                    tooltip: "View more information based on Tenable's world-class research expertise (requires a VM license)"
                },
                titleWithName: "{{rawId}} - {{name}}"
            }));

    const categoryToViewMap =
        useMemo(
            () => ({
                [CategoryView.Overview]: <Overview rawId={rawId}/>,
                [CategoryView.RiskImpact]: <RiskImpact rawId={rawId}/>,
                [CategoryView.Sources]: <Sources vulnerabilityRawId={rawId}/>,
                [CategoryView.Timeline]: <Timeline rawId={rawId}/>
            }),
            [rawId]);

    const orderedCategories =
        useMemo(
            () =>
                _<CategoryView>([]).
                    concat([
                        CategoryView.Overview,
                        CategoryView.RiskImpact]).
                    concatIf(
                        !_.isNil(vulnerabilityModel),
                        [
                            CategoryView.Sources,
                            CategoryView.Timeline
                        ]).
                    value(),
            []);

    const currentCategory =
        useMemo(
            () =>
                _.includes(orderedCategories, category)
                    ? category as CategoryView
                    : _.first(orderedCategories)!,
            [category, orderedCategories]);

    const infoChildren =
        useMemo(
            () =>
                _.isNil(vulnerabilityModel)
                    ? []
                    : [<Severity
                        key="severity"
                        severity={vulnerabilityModel.severity}/>],
            [vulnerabilityModel]);

    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<VulnerabilitiesIcon/>}
                    infoChildren={infoChildren}
                    title={
                        _.isEmpty(vulnerabilityModel?.vulnerability.name)
                            ? rawId
                            : localization.titleWithName({
                                name: vulnerabilityModel?.vulnerability.name,
                                rawId
                            })}/>}>
            <VerticalTabView
                items={
                    _.map(
                        orderedCategories,
                        category => ({
                            icon: <CategoryIcon view={category}/>,
                            title: localization.category[category](),
                            view: category
                        }))}
                selectedView={currentCategory}
                tabsSx={{ height: "100%" }}
                onSelectedViewChanged={
                    (view: CategoryView) => {
                        setRoute(`${CustomerConsoleAppUrlHelper.getVulnerabilityHashUrl(rawId)}/${view}`);
                    }}>
                {!_.isNil(categoryToViewMap[currentCategory]) &&
                    <Stack
                        className={ElementClass.categoryContainer}
                        sx={{
                            height: "100%",
                            overflow: "hidden auto",
                            width: "100%"
                        }}>
                        {categoryToViewMap[currentCategory]}
                    </Stack>}
            </VerticalTabView>
        </ProfileLayout>);
}