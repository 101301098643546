﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useCodeTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.code.hooks.useCodeTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.CodeTenantModelStatus]: {
                    [Contract.CodeTenantModelStatus.CodeOrganizationIssue]: "Repository integration is not connected",
                    [Contract.CodeTenantModelStatus.CodeServerIssue]: "Server integration is not connected",
                    [Contract.CodeTenantModelStatus.Deleting]: "Deleting...",
                    [Contract.CodeTenantModelStatus.IacNotExist]: "Connected without IaC",
                    [Contract.CodeTenantModelStatus.TenantEmpty]: "Repository is empty",
                    [Contract.CodeTenantModelStatus.TenantNotFound]: "Repository not found",
                    [Contract.CodeTenantModelStatus.TenantSuspended]: "Repository was archived or suspended",
                    [Contract.CodeTenantModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.CodeTenantModelStatus) => localization[Contract.TypeNames.CodeTenantModelStatus][status]();
}