import React from "react";
import { useLocalization } from "@infrastructure";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.apiServers.useKubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} NamespaceLifecycle admission control plugin is not enabled",
                resolutionSection: {
                    step1: "Edit the API server manifest file (typically located in **/etc/kubernetes/manifests/kube-apiserver.yaml**)",
                    step2: "Ensure that the **NamespaceLifecycle** admission control plugin is enabled by modifying the API server’s configuration file or startup arguments to include **--enable-admission-plugins=NamespaceLifecycle**",
                    step3: "Restart the API server to apply the changes"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3()
        ],
        riskModel,
        () => {
            const clusterContextItems = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterContextItems.generalInformation,
                clusterContextItems.virtualNetwork,
                clusterContextItems.sensitive,
                clusterContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}