import { InlineItems, ResultIcon } from "@infrastructure";
import { List, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";

type ResultProps = {
    message?: string | string[];
    success: boolean;
    variant?: "inlineItems" | "list";
};

export function Result({ message, success, variant = "list" }: ResultProps) {
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <ResultIcon
                success={success}
                sx={{ fontSize: "18px" }}/>
            {!_.isNil(message) &&
                (variant === "list"
                    ? <List>
                        {_.map(
                            _.isString(message)
                                ? [message]
                                : message,
                            (message, messageIndex) =>
                                <Typography key={messageIndex}>
                                    {message}
                                </Typography>)}
                    </List>
                    : <InlineItems
                        items={message}
                        variant="itemPlusItemCount"/>)}
        </Stack>);
}