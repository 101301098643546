﻿import { ApiError, CheckboxField, ItemSelector, Optional, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { FolderEnabled } from "../../../../../../../../../components";
import { useAzureDevOpsOrganizationManagerErrorTranslator } from "../hooks";

export function ChooseOrganizationItem() {
    const setAddOrEditContext = useSetAddOrEditContext();
    const { aadTenantRawId, oAuthAccessToken, organizationModel, updatedFolderEnabled, updatedSyncEnabled } = useAddOrEditContext();
    const { executing, setError, setValid } = useOrderedWizardContext();

    const azureDevOpsOrganizationModels = scopeSystemEntityModelStore.useGetCodeOrganization(Contract.CodeTenantType.AzureDevOps);
    const existingOrganizationNames =
        useMemo(
            () =>
                _.map(
                    azureDevOpsOrganizationModels,
                    azureDevOpsOrganizationModel => azureDevOpsOrganizationModel.configuration.name),
            [azureDevOpsOrganizationModels]);

    const [{ organizationNames, organizationNamesError }] =
        useExecuteOperation(
            ChooseOrganizationItem,
            async () => {
                if (!_.isNil(organizationModel)) {
                    return { organizationNames: [] as string[] };
                }

                try {
                    const { organizationNames } =
                        await ConfigurationController.getAzureDevOpsOrganizationOnboardingOrganizationsInfo(
                            new Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoRequest(
                                aadTenantRawId!,
                                oAuthAccessToken!));
                    return { organizationNames };
                } catch (organizationNamesError) {
                    return {
                        organizationNames: [] as string[],
                        organizationNamesError
                    };
                }
            });
    const azureDevOpsOrganizationConfiguration = organizationModel?.configuration as Optional<Contract.AzureDevOpsOrganizationConfiguration>;

    const [folderEnabled, setFolderEnabled] = useState(updatedFolderEnabled ?? azureDevOpsOrganizationConfiguration?.folderEnabled ?? true);
    const [organizationName, setOrganizationName] =
        useState(
            azureDevOpsOrganizationConfiguration?.name ??
            _(organizationNames).
                filter(name => !existingOrganizationNames.includes(name)).
                first());
    const [syncEnabled, setSyncEnabled] = useState(updatedSyncEnabled ?? azureDevOpsOrganizationConfiguration?.syncEnabled ?? true);

    const azureDevOpsOrganizationManagerErrorTranslator = useAzureDevOpsOrganizationManagerErrorTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.azureDevOps.addOrEditAzureDevOps.chooseOrganizationItem",
            () => ({
                actions: {
                    info: {
                        error: {
                            general: "Failed to access Microsoft Entra ID tenant",
                            noOrganizations: "No Azure DevOps organizations found on the authenticated Microsoft Entra ID tenant"
                        }
                    }
                },
                fields: {
                    organization: {
                        details: "Select the Azure DevOps organization you want to add:",
                        title: "Organization name"
                    }
                },
                folderEnabled: {
                    info: "Mirror your organization's structure as folders within Tenable, preserving its hierarchy and keeping the view synchronized.",
                    title: "Automatically update folder structure"
                },
                syncEnabled: "Sync Cloud to Code"
            }));

    useEffect(
        () => {
            if (!_.isNil(organizationNamesError)) {
                setError(
                    organizationNamesError instanceof ApiError && organizationNamesError.statusCode === 400
                        ? azureDevOpsOrganizationManagerErrorTranslator(organizationNamesError.error as Contract.AzureDevOpsOrganizationManagerError)
                        : localization.actions.info.error.general());
            }

            if (_.isNil(azureDevOpsOrganizationConfiguration) &&
                organizationNames.length === 0) {
                setError(localization.actions.info.error.noOrganizations());
            }
        },
        [azureDevOpsOrganizationConfiguration, organizationNames, organizationNamesError, setError]);

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedFolderEnabled: folderEnabled,
                    updatedSyncEnabled: syncEnabled
                }));
        },
        [syncEnabled, folderEnabled]);

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedOrganizationName: organizationName
                }));

            setValid(!!organizationName);
        },
        [organizationName]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{
                maxWidth: theme.spacing(60),
                paddingBottom: theme.spacing(3)
            }}>
            <Typography>
                {localization.fields.organization.details()}
            </Typography>
            <ItemSelector
                disabled={
                    executing ||
                    !_.isNil(azureDevOpsOrganizationConfiguration) ||
                    organizationNames.length === 0}
                fullWidth={true}
                getItemDisabled={item => existingOrganizationNames.includes(item)}
                items={
                    !_.isNil(azureDevOpsOrganizationConfiguration)
                        ? [azureDevOpsOrganizationConfiguration.name]
                        : organizationNames ?? []}
                placeholder={localization.fields.organization.title()}
                selectedItem={organizationName}
                sorted={true}
                onSelectedItemChanged={setOrganizationName}/>
            <CheckboxField
                checked={syncEnabled}
                onChange={() => setSyncEnabled(!syncEnabled)}>
                {localization.syncEnabled()}
            </CheckboxField>
            <FolderEnabled
                folderEnabled={folderEnabled}
                info={localization.folderEnabled.info()}
                setFolderEnabled={setFolderEnabled}
                title={localization.folderEnabled.title()}/>
        </Stack>);
}