import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureKeyVaultKeyRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureKeyVaultVaultKeyRotationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultKeyRotationDisabledRisk;
    const vaultKeyModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultKeyModel;
    const vaultKey = vaultKeyModel.entity as Contract.AzureKeyVaultVaultKey;

    const getAzureKeyVaultVaultKeyRiskContext = useGetAzureKeyVaultKeyRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultKeyRotationDisabledRiskDefinition",
            () => ({
                description: "{{vaultKey}} automatic rotation is not enabled",
                sections: {
                    resolution: {
                        step1: "Click on **Rotation policy**",
                        step2: "Set **Enable auto rotation** to **Enabled**",
                        step3: "Set an appropriate **Rotation option** and **Rotation time**. Tenable recommends a rotation time of every 2 years or less",
                        step4: "Key rotation generates a new key version of an existing key with new key material. Target services should use versionless key uri to automatically refresh to the latest version of the key. Ensure that your data encryption solution stores versioned key uri with data to point to the same key material for decrypt/unwrap as was used for encrypt/wrap operations to avoid disruption to your services",
                        step5: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vaultKey:
                <Entity
                    entityIdOrModel={vaultKeyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={vaultKey.vaultId}
                key={vaultKey.vaultId}
                page={Contract.AzureConsoleEntityPage.Keys}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const vaultKeyContext = getAzureKeyVaultVaultKeyRiskContext(vaultKeyModel);
            return [
                vaultKeyContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.keyRotationTimeFrame),
                _.isNil(vaultKeyContext.rotationDisabled)
                    ? vaultKeyContext.rotationTime
                    : vaultKeyContext.rotationDisabled,
                vaultKeyContext.sensitive,
                vaultKeyContext.keyVault,
                vaultKeyContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        }
    );
}