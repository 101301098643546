import { StorageItem, UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelperCategoryView, RiskType } from "../../../../../../../../common";
import { RiskDefinitionSectionCategory } from "../../../../hooks/useDefinition/utilities";
import { RiskView } from "../../../../utilities";
import { useCloudDefinition, useCodeDefinition } from "./hooks";

export function useProfileDefinition(riskType: RiskType, riskModel: Contract.RiskModel): RiskProfileDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskType) {
                    case RiskType.Cloud:
                        return useCloudDefinition;
                    case RiskType.Code:
                        return useCodeDefinition;
                    default:
                        throw new UnexpectedError("riskType", riskType);
                }
            },
            [riskType]);

    return useDefinition(riskModel);
}

export type RiskProfileDefinition = {
    cardData: RiskProfileDefinitionCardData;
    storageData: RiskProfileDefinitionStorageData;
    urlHelper: RiskProfileDefinitionUrlHelper;
};

type RiskProfileDefinitionCardData = {
    infoElements: ReactNode[];
};

type RiskProfileDefinitionStorageData = {
    openTab: StorageItem;
};

type RiskProfileDefinitionUrlHelper = {
    getHashUrl: (riskId: string, categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) => string;
    getProfileUrl: (riskId: string, categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) => string;
    getRelativeUrl: (riskId: string, categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) => string;
};