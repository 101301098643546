﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonComputeLoadBalancerResourceInfoItemElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useGcpComputeSslPolicyFeatureProfileTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpComputeSslPolicyDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const sslPolicyModel = scopeResourceModel as Contract.GcpComputeSslPolicyModel;
    const commonComputeLoadBalancerResourceInfoItemElements = useGcpCommonComputeLoadBalancerResourceInfoItemElements(sslPolicyModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const sslPolicy = sslPolicyModel.entity as Contract.GcpComputeSslPolicy;

    const computeSslPolicyFeatureProfileTranslator = useGcpComputeSslPolicyFeatureProfileTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeSslPolicyDefinition",
            () => ({
                info: {
                    items: {
                        customFeatures: "Custom Features",
                        description: "Description",
                        featureProfile: "Profile",
                        targetProxyIds: "Target Proxies",
                        tlsMinVersion: "Minimum TLS version"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={
                            _.isNil(sslPolicy.description)
                                ? undefined
                                : sslPolicy.description}/>,
                    <InfoItem
                        key="tlsMinVersion"
                        title={localization.info.items.tlsMinVersion()}
                        value={sslPolicy.tlsMinVersion}/>,
                    <InfoItem
                        key="featureProfile"
                        title={localization.info.items.featureProfile()}
                        value={computeSslPolicyFeatureProfileTranslator(sslPolicy.featureProfile)}/>,
                    <VerticalTopItemsInfoItem
                        items={sslPolicy?.customFeatures}
                        key="customFeatures"
                        title={localization.info.items.customFeatures()}/>,
                    commonComputeLoadBalancerResourceInfoItemElements.loadBalancerIdsInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={sslPolicyModel.targetProxyIds}
                        entityTypeName={Contract.TypeNames.GcpComputeTargetProxy}
                        key="targetProxyIds"
                        title={localization.info.items.targetProxyIds()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}