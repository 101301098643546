import { GuidParser, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, tenantModelStore, useTheme } from "../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../index";

export function TenantItem() {
    const { aadTenantModel, rawShortId: initialRawShortId, tenantModel } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const tenantModels = tenantModelStore.useGetPermittedAzureTenants(aadTenantModel!.configuration.id);

    const [rawShortId, setRawShortId] = useState(initialRawShortId ?? "");

    const { setLoaded, setValid } = useOrderedWizardContext();
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.addOrEdit.tenantItem",
            () => ({
                fields: {
                    rawShortId: {
                        error: {
                            exists: "Subscription ID already exists",
                            format: "Subscription ID must be a valid GUID",
                            required: "Subscription ID cannot be empty"
                        },
                        title: "Subscription ID"
                    }
                }
            }));

    const [rawShortIdValidationController, rawShortIdValidationMessage] =
        useInputValidation(
            () => {
                const validationRawShortIdValue = rawShortId.trim();
                if (_.isEmpty(validationRawShortIdValue)) {
                    return localization.fields.rawShortId.error.required();
                }
                if (!GuidParser.validate(validationRawShortIdValue)) {
                    return localization.fields.rawShortId.error.format();
                }
                if (_.some(
                    tenantModels,
                    tenantModel => (tenantModel.configuration as Contract.AzureTenantConfiguration).rawShortId === validationRawShortIdValue)) {
                    return localization.fields.rawShortId.error.exists();
                }

                return undefined;
            },
            [rawShortId]);

    useEffect(
        () => {
            if (!_.isNil(tenantModel)) {
                return;
            }

            setAddOrEditContext(
                addContext => ({
                    ...addContext,
                    rawShortId: rawShortId.trim()
                }));

            setValid(rawShortIdValidationController.isValid());
        },
        [rawShortId]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={!_.isNil(tenantModel)}
                    label={localization.fields.rawShortId.title()}
                    value={rawShortId}
                    variant="outlined"
                    onChange={event => setRawShortId(event.target.value)}/>
                {!_.isNil(rawShortIdValidationMessage) && (
                    <FormHelperText error={true}>{rawShortIdValidationMessage}</FormHelperText>)}
            </FormControl>
        </Stack>);
}