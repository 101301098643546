﻿import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntityController, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpBigQueryDatasetRiskContext() {
    return useMemo(
        () => useGcpBigQueryDatasetRiskContext,
        []);
}

function useGcpBigQueryDatasetRiskContext(datasetModel: Contract.GcpBigQueryDatasetModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(datasetModel);
    const dataset = datasetModel.entity as Contract.GcpBigQueryDataset;
    const [{ tableIds }] =
        useExecuteOperation(
            [useGcpBigQueryDatasetRiskContext, datasetModel.id],
            () => EntityController.getGcpBigQueryDatasetTableIds(new Contract.EntityControllerGetGcpBigQueryDatasetTableIdsRequest(datasetModel.id)));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpBigQueryDatasetRiskContext",
            () => ({
                tables: "The {{translatedDatasetTypeName}} contains {{tables}}"
            }));
    const translatedDatasetTypeName =
        entityTypeNameTranslator(
            dataset.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...entityRiskContext,
        tables:
            !_.isEmpty(tableIds)
                ? new RiskDefinitionContextItem(
                    localization.tables({
                        tables:
                            <InlineEntities
                                entityIdsOrModels={tableIds}
                                entityTypeName={Contract.TypeNames.GcpBigQueryDatasetTable}
                                variant="itemCountAndType"/>,
                        translatedDatasetTypeName
                    }))
                : undefined
    };
}