import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Change, Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { ChangeHelper } from "../../../../utilities";
import { ContextSection } from "./components";

export function useAwsIamUserUnusedAccessKeysEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const userUnusedAccessKeysEnabledRiskModel = riskModel as Contract.AwsIamUserUnusedAccessKeysEnabledRiskModel;
    const userModel = entityModelStore.useGet(userUnusedAccessKeysEnabledRiskModel.risk.entityId) as Contract.AwsIamUserModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserUnusedAccessKeysEnabledRiskDefinition",
            () => ({
                description: "{{user}} has access keys that were unused for more than {{unusedAccessKeyTimeFrame | TimeSpanFormatter.humanizeDays}}"
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            unusedAccessKeyTimeFrame: userUnusedAccessKeysEnabledRiskModel.risk.accessKeyUsageTimeFrame,
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            _.map(
                ChangeHelper.getChanges(userUnusedAccessKeysEnabledRiskModel.risk.resolutionChanges!),
                change => <Change change={change}/>),
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    risk={userUnusedAccessKeysEnabledRiskModel.risk}
                    unusedAccessKeys={userUnusedAccessKeysEnabledRiskModel.risk.unusedAccessKeys}
                    userModel={userModel}/>
        });
}