﻿import { NumberFormatter, Optional, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useCallback } from "react";
import { ComplianceHelper, Contract, TypeHelper, useComplianceTranslator, useFileNameScopeTranslator, useGetAnalysisGroupTypeToRiskPolicyIdentifierToCountMap, useGetComplianceScopeId, useRiskPolicyTranslator } from "..";

export function useComplianceExportCsvData(scopeId: string, sectionData: Contract.ComplianceSectionData) {
    const complianceTranslator = useComplianceTranslator();
    const fileNameScopeTranslator = useFileNameScopeTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "common.hooks.useComplianceExportCsvData",
            () => ({
                export: {
                    fileName: "{{translatedComplianceStandardTitle}}_{{translatedScopeName}}"
                }
            }));

    const getCompliancePolicyItemDatas =
        (complianceSectionData: Contract.ComplianceSectionData): ComplianceRiskPolicyDataItem[] => {
            if (_.some(complianceSectionData.sectionDatas)) {
                return _.flatMap(
                    complianceSectionData.sectionDatas,
                    getCompliancePolicyItemDatas);
            } else {
                return _.map(
                    complianceSectionData.riskPolicyDatas,
                    complianceRiskPolicyData => ({
                        complianceIdentifier: complianceSectionData.identifier,
                        complianceRiskPolicyData,
                        policyName: riskPolicyTranslator(complianceRiskPolicyData.riskPolicyIdentifier).title
                    }));
            }
        };

    const getDataItems =
        useCallback(
            () =>
                _.orderBy(
                    getCompliancePolicyItemDatas(sectionData),
                    [
                        compliancePolicyItemData =>
                            sectionData.custom
                                ? StringHelper.getSortValue(complianceTranslator(compliancePolicyItemData.complianceIdentifier).title)
                                : TypeHelper.getEnumValue(
                                    Contract.TypeNames.BuiltInComplianceSectionType,
                                    compliancePolicyItemData.complianceIdentifier as Contract.BuiltInComplianceSectionType),
                        compliancePolicyItemData => StringHelper.getSortValue(compliancePolicyItemData.policyName)
                    ]),
            [sectionData]);

    const getComplianceScopeId = useGetComplianceScopeId();
    const getAnalysisGroupTypeToRiskPolicyIdentifierToCountMap =
        useGetAnalysisGroupTypeToRiskPolicyIdentifierToCountMap(
            () =>
                _(getDataItems()).
                    filter(dataItem => !_.isNil(dataItem.complianceRiskPolicyData)).
                    map(dataItem => dataItem.complianceRiskPolicyData!).
                    value(),
            getComplianceScopeId(
                sectionData.identifier,
                scopeId));

    async function getCsvItems() {
        const analysisGroupTypeToRiskPolicyIdentifierToCountMap = await getAnalysisGroupTypeToRiskPolicyIdentifierToCountMap();
        const complianceRiskPolicyIdentifierToExcludedEntityIdsMap =
            await ComplianceHelper.getRiskPolicyIdentifierToExcludedEntityIdsMap(
                _(getDataItems()).
                    filter(dataItem => !_.isNil(dataItem.complianceRiskPolicyData)).
                    map(dataItem => dataItem.complianceRiskPolicyData!).
                    value(),
                scopeId);
        return _.map(
            getDataItems(),
            dataItem => {
                const [securedPercentage] =
                    _.isNil(dataItem.complianceRiskPolicyData)
                        ? [0]
                        : ComplianceHelper.getDataPercentages(dataItem.complianceRiskPolicyData);
                return {
                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                    Requirement: complianceTranslator(dataItem.complianceIdentifier).title,
                    Policy: dataItem.policyName,
                    Failed:
                        dataItem.complianceRiskPolicyData?.enabled
                            ? dataItem.complianceRiskPolicyData.failedEntityCount
                            : "",
                    Assessed:
                        dataItem.complianceRiskPolicyData?.enabled
                            ? analysisGroupTypeToRiskPolicyIdentifierToCountMap[dataItem.complianceRiskPolicyData.analysisGroupType][dataItem.complianceRiskPolicyData.riskPolicyIdentifier]
                            : "",
                    Excluded:
                        dataItem.complianceRiskPolicyData?.enabled
                            ? complianceRiskPolicyIdentifierToExcludedEntityIdsMap[dataItem.complianceRiskPolicyData.riskPolicyIdentifier].length
                            : "",
                    ["Summary (passed)"]:
                        dataItem.complianceRiskPolicyData?.enabled &&
                        dataItem.complianceRiskPolicyData.hasResults
                            ? NumberFormatter.percentage(securedPercentage)
                            : ""
                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                };
            });
    }

    return {
        fileNameOptions: {
            default: true,
            filtered: false,
            prefix:
                localization.export.fileName({
                    translatedComplianceStandardTitle: complianceTranslator(sectionData.identifier).title,
                    translatedScopeName: fileNameScopeTranslator(scopeId)
                })
        },
        getCsvItems
    };
}

type ComplianceRiskPolicyDataItem = {
    complianceIdentifier: string;
    complianceRiskPolicyData: Optional<Contract.ComplianceRiskPolicyData>;
    policyName: Optional<string>;
};