import { DataTableSortDirection, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { CodeTenant, Contract, GroupedTableColumnRenderProps } from "../../../../../../../../../../../../../common";
import { RisksItemsFilterId } from "../../../../../../../../Filters";
import { GroupedItem, GroupedTableColumnId } from "../../../../../Grouped";
import { GroupedDefinition } from "../../../useDefinition";
import { useCommonGroupedRiskTenantColumns } from "../../useCommonGroupedRiskTenantColumns";

export function useRiskCodeTenantDefinition() {
    const commonColumns = useCommonGroupedRiskTenantColumns();

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.hooks.useCodeDefinition.hooks.useRiskCodeTenantDefinition",
            () => ({
                repository: "Repository"
            }));

    return useMemo(
        (): GroupedDefinition => ({
            columns: [
                {
                    element:
                        ({ item }: GroupedTableColumnRenderProps<GroupedItem>) =>
                            <CodeTenant
                                iconSx={{ fontSize: "18px" }}
                                tenantId={(item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup).tenantId}
                                textSx={{ fontWeight: 600 }}/>,
                    filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.TenantId],
                    id: GroupedTableColumnId.Repository,
                    minWidth: 280,
                    title: localization.repository(),
                    width: 3
                },
                commonColumns.riskCount,
                commonColumns.riskPolicies,
                commonColumns.severity
            ],
            defaultSort: {
                columnId: commonColumns.riskCount.id,
                direction: DataTableSortDirection.Descending
            },
            groupRequestType: Contract.RiskControllerGetRiskGroupsRequestType.Tenant,
            riskGroupToId: riskGroup => (riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup).tenantId
        }),
        [commonColumns]);
}