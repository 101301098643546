import { alpha, Box, IconButton, Stack, styled, SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { BackIcon, NextIcon, Shadows, Sx } from "../assets";

type SlideItemsProps = {
    buttonSx?: SxProps;
    children: (itemChunk: any[]) => ReactNode;
    chunkSize: number;
    items: any[];
    sx?: SxProps;
};

export function SlideItems({ buttonSx, children, chunkSize, items, sx }: SlideItemsProps) {
    const itemChunks =
        useMemo(
            () =>
                _.chunk(
                    items,
                    chunkSize),
            [items, chunkSize]);

    const [slideTabIndex, setSlideTabIndex] = useState(0);
    useEffect(
        () => {
            setSlideTabIndex(
                slideTabIndex =>
                    Math.min(
                        slideTabIndex,
                        itemChunks.length - 1));
        },
        [itemChunks]);
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={0}
            sx={{
                height: "100%",
                position: "relative",
                ...sx
            }}>
            {itemChunks.length > 1 && (
                <Box
                    sx={
                        Sx.combine(
                            {
                                left: theme.px(-10),
                                position: "absolute",
                                top: "calc(50% - 24px)"
                            },
                            buttonSx)}>
                    <SlideButton onClick={() => setSlideTabIndex((slideTabIndex + itemChunks.length - 1) % itemChunks.length)}>
                        <BackIcon/>
                    </SlideButton>
                </Box>)}
            {children(itemChunks[slideTabIndex])}
            {itemChunks.length > 1 && (
                <Box
                    sx={
                        Sx.combine(
                            {
                                position: "absolute",
                                right: theme.px(-10),
                                top: "calc(50% - 24px)"
                            },
                            buttonSx)}>
                    <SlideButton onClick={() => setSlideTabIndex((slideTabIndex + 1) % itemChunks.length)}>
                        <NextIcon/>
                    </SlideButton>
                </Box>)}
        </Stack>);
}

const SlideButton =
    styled(IconButton)(
        ({ theme }) => ({
            "&:hover": {
                backgroundColor: theme.palette.background.paper,
                border: theme.border.hoverFocus,
                fill: theme.palette.text.primary
            },
            backgroundColor: theme.palette.background.paper,
            border: theme.border.primary,
            boxShadow: theme.shadows[Shadows.Tooltip],
            fill: alpha(theme.palette.text.primary, 0.7),
            fontSize: "18px",
            height: theme.spacing(5),
            padding: theme.spacing(1),
            width: theme.spacing(5)
        }));