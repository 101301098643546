import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore, RegionModelHelper } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpNotebooksInstanceRiskContext } from "../contexts";

export function useGcpNotebooksInstanceVirtualTpmNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpNotebooksInstanceVirtualTpmNotEnabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpNotebooksInstanceModel;
    const instance = instanceModel.entity as Contract.GcpNotebooksInstance;

    const getGcpNotebooksInstanceRiskContext = useGetGcpNotebooksInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpNotebooksInstanceVirtualTpmNotEnabledRiskDefinition",
            () => ({
                description: "{{instance}} vTPM is not enabled",
                sections: {
                    resolution: {
                        step1: "Click the **Stop** button to stop your instance",
                        step2: "Enabling vTPM is not supported via the GCP console. Run the following command from the GCP CLI: gcloud workbench instances update {{instance}} --location={{location}} --shielded-vtpm true --format=\"yaml(gceSetup.shieldedInstanceConfig.enableVtpm)\"",
                        step3: "Start your instance"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({
                instance: instance.name,
                location: RegionModelHelper.get(instance.regionId!).systemName
            }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const instanceRiskContext = getGcpNotebooksInstanceRiskContext(instanceModel);
            return [
                instanceRiskContext.generalInformation,
                instanceRiskContext.sensitive,
                instanceRiskContext.status,
                instanceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}