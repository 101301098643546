﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function FranceIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M22 4H16V20H22C23.1046 20 24 19.1046 24 18V6C24 4.89543 23.1046 4 22 4Z"
                fill="#CE1126"/>
            <path
                d="M16 4.00012H8V19.9639H16V4.00012Z"
                fill="white"/>
            <path
                d="M8 4.00012H2C0.895431 4.00012 0 4.89555 0 6.00012V17.9639C0 19.0685 0.89543 19.9639 2 19.9639H8V4.00012Z"
                fill="#002654"/>
            <path
                clipRule="evenodd"
                d="M16 5H8V4H16V5Z"
                fill="#BAC5D3"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M16 20H8V19H16V20Z"
                fill="#BAC5D3"
                fillRule="evenodd"/>
        </SvgIcon>);
}