import _ from "lodash";
import { useMemo } from "react";
import { Contract, EntityPropertyHelper, RiskType, tenantModelStore } from "../../../../../../../../../../../../../../../../../common";
import { useTableCommonCloudColumns } from "../../../../../../../../../../../hooks";
import { TableDefinition } from "../../../useDefinition";
import { useCommonColumns } from "../../useCommonColumns";

export function useRiskTenantDefinition(riskGroup: Contract.RiskControllerGetRiskGroupsResponseRiskGroup): TableDefinition {
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();
    const tenantId = (riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup).tenantId;
    const tenantModel = tenantModelStore.useGet(tenantId);

    return useMemo(
        () => ({
            columns:
                _.filter([
                    commonColumns.statusUpdateTimeColumn,
                    commonColumns.severityColumn,
                    commonColumns.riskPolicyIdColumn,
                    commonCloudColumns.descriptionColumn,
                    commonColumns.tenantIdColumn,
                    commonColumns.riskedEntityIdColumn(
                        undefined,
                        undefined,
                        Contract.TypeNames.IResource),
                    EntityPropertyHelper.isResourceOwnerConfigured([tenantModel])
                        ? commonColumns.resourceOwnerColumn
                        : undefined,
                    EntityPropertyHelper.isResourceEnvironmentConfigured([tenantModel])
                        ? commonColumns.resourceEnvironmentColumn
                        : undefined,
                    commonColumns.statusColumn(true),
                    commonColumns.subStatusColumn(true),
                    commonColumns.ignoreExpirationDateColumn,
                    commonColumns.openStatusUpdateTimeColumn,
                    commonColumns.systemCreationTimeColumn,
                    commonColumns.compliancesColumn,
                    commonColumns.riskedEntityAttributesColumn,
                    commonColumns.actionsCell(RiskType.Cloud)
                ]),
            getRiskIdToScopeIdentityPermissionsMapRequest:
                requestFilters =>
                    new Contract.RiskControllerGetScopeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                        requestFilters,
                        tenantId),
            getRiskModelPageRequest:
                (limit, requestFilters, skip, sort) =>
                    new Contract.RiskControllerGetTenantGroupCloudRiskModelPageRequest(
                        requestFilters,
                        limit,
                        skip,
                        sort,
                        tenantId)
        }),
        [commonColumns, commonCloudColumns, tenantId, tenantModel]);
}