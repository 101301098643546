import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useAadTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.AadTenantAuditEvent;
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAadTenantAuditEventDefinition",
            () => ({
                applicationId: "Application ID",
                applicationSecret: "Application secret",
                propertyChanges: "Updated properties",
                tenantName: "Tenant name",
                tenantPrimaryDomainName: "Primary domain name",
                tenantRawId: "Tenant ID"
            }));
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantRawId(),
                    tenantAuditEvent.tenantRawId),
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName),
                new AuditEventDefinitionDetailItem(
                    localization.tenantPrimaryDomainName(),
                    tenantAuditEvent.tenantPrimaryDomainName)).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.AadTenantUpdateAuditEvent,
                () => {
                    const tenantUpdateAuditEvent = tenantAuditEvent as Contract.AadTenantUpdateAuditEvent;
                    const properties =
                        _.filter([
                            tenantUpdateAuditEvent.applicationIdChanged
                                ? localization.applicationId()
                                : undefined,
                            tenantUpdateAuditEvent.applicationSecretChanged
                                ? localization.applicationSecret()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}