import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GcpFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_61567_10022)">
                <path
                    d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H3.08299C3.35167 16.2342 4.25461 14.6877 5.52496 13.5692C5.93636 12.4952 6.55242 11.4829 7.32967 10.6256L7.33834 10.6161C10.8094 6.827 16.8848 6.40828 20.779 9.82906C21.6995 10.6207 22.4525 11.5729 23 12.638V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                    fill="#BAC5D3"/>
                <path
                    d="M17.4707 13.5891L19.0354 12.1834L19.1393 11.5917C16.2885 9.26214 11.7563 9.5266 9.18557 12.1428C8.47147 12.8692 7.94224 13.7752 7.65978 14.7112L8.21995 14.6399L11.3493 14.1765L11.5905 13.9548C12.9822 12.5812 15.3363 12.3965 16.9431 13.5649L17.4707 13.5891Z"
                    fill="#EA4335"/>
                <path
                    d="M21.2647 14.6688C20.9053 13.479 20.1666 12.409 19.1398 11.5907L16.9436 13.5639C17.8711 14.2446 18.3987 15.2697 18.3765 16.3454V16.6954C19.4556 16.6954 20.3308 17.4817 20.3308 18.4512C20.3308 19.4206 19.4556 20.1877 18.3765 20.1877H14.4624L14.0784 20.5626V22.6691L14.4624 22.9999H18.3765C21.1838 23.0198 23.4776 21.0103 23.4998 18.4882C23.5133 16.9592 22.6739 15.5256 21.2647 14.6688Z"
                    fill="#4285F4"/>
                <path
                    d="M10.5538 23L14.4624 22.9999L14.4631 20.1885H10.5538C10.2769 20.1885 10.0039 20.1343 9.75241 20.0309L9.19779 20.1835L7.62201 21.5892L7.48475 22.0675C8.36864 22.6671 9.44693 23.0042 10.5538 23Z"
                    fill="#34A853"/>
                <path
                    d="M10.5535 13.88C7.74552 13.8949 5.48343 15.9522 5.50009 18.475C5.50961 19.8836 6.24195 21.2095 7.48448 22.0685L9.75241 20.0309C8.76855 19.6317 8.33107 18.5919 8.7754 17.708C9.21972 16.824 10.3773 16.4312 11.3612 16.8304C11.7944 17.0065 12.1419 17.318 12.3379 17.708L14.6056 15.6706C13.6407 14.5372 12.1412 13.875 10.5535 13.88Z"
                    fill="#FBBC05"/>
            </g>
            <defs>
                <clipPath id="clip0_61567_10022">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}