import { Action0, ActionMenuItem, UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { BuiltInRiskPolicyItem, RiskPoliciesType } from "../../../../../../../../../../common";
import { useCloudDefinition, useCodeDefinition, useKubernetesAdmissionControllerDefinition } from "./hooks";

export function useDefinition(item: BuiltInRiskPolicyItem, onClick: Action0, scopeId: string, type: RiskPoliciesType): BuiltInActionsCellDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (type) {
                    case RiskPoliciesType.Cloud:
                        return useCloudDefinition;
                    case RiskPoliciesType.Code:
                        return useCodeDefinition;
                    case RiskPoliciesType.KubernetesAdmissionController:
                        return useKubernetesAdmissionControllerDefinition;
                    default:
                        throw new UnexpectedError("useDefinition.type", type);
                }
            },
            []);

    return useDefinition(item, onClick, scopeId);
}

export interface BuiltInActionsCellDefinition {
    actionMenuItems: ActionMenuItem[];
}