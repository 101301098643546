﻿import { InlineItems, map, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InlineEntities, InlinePermissionActions, InlineResourceTags, NetworkScopeFormatter } from "../../../../../../../../../../../../../../common";
import { useInstanceVulnerabilityStep } from "./useInstanceVulnerabilityStep";

export function useInformationSteps(riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel, resourceDisplayName: string) {
    const instanceVulnerabilityStep = useInstanceVulnerabilityStep(riskModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useInformationSteps",
            () => ({
                [Contract.TypeNames.AwsInboundExternalEc2InstanceRiskInfoType]: {
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.AutoScalingGroups]: "The {{resourceDisplayName}} is part of {{autoScalingGroups}}",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.GroupTags]: [
                        "{{instances}} is grouped by {{tags}}",
                        "{{instances}} are grouped by {{tags}}"
                    ],
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceHighSeverityRolePermissions]: [
                        "{{instances}} is associated with {{highSeverityPermissionActions}} with **high** severity",
                        "{{instances}} are associated with {{highSeverityPermissionActions}} with **high** severity"
                    ],
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceNotExists]: "is not associated with any instance that is exposing a secret",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.Instances]: "The {{resourceDisplayName}} is associated with {{instances}}",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.NotSensitive]: "The {{resourceDisplayName}} is not marked as sensitive",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.RunningInstances]: [
                        "{{instances}} has state of **running**",
                        "{{instances}} have state of **running**"
                    ],
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.RunningInstanceNotExists]: "All EC2 instances are not running",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.Sensitive]: "The {{resourceDisplayName}} is marked as sensitive",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.SensitiveAutoScalingGroups]: [
                        "{{autoScalingGroups}} is marked as sensitive",
                        "{{autoScalingGroups}} are marked as sensitive"
                    ],
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.SensitiveInstances]: [
                        "{{instances}} is marked as sensitive",
                        "{{instances}} are marked as sensitive"
                    ]
                },
                inboundExternalAccess: [
                    "{{instances}} is directly accessible via {{securityGroups}} through {{networkScopes}}",
                    "{{instances}} are directly accessible via {{securityGroups}} through {{networkScopes}}"
                ],
                ports: [
                    "1 port",
                    "{{count | NumberFormatter.humanize}} ports"
                ],
                severityVulnerabilities: {
                    critical: [
                        "1 critical vulnerability",
                        "{{count | NumberFormatter.humanize}} critical vulnerabilities"
                    ],
                    high: [
                        "1 high vulnerability",
                        "{{count | NumberFormatter.humanize}} high vulnerabilities"
                    ]
                },
                vulnerabilities: {
                    text: "The EC2 instances contains {{vulnerabilities}}",
                    vulnerabilities: [
                        " 1 vulnerability",
                        " {{count | NumberFormatter.humanize}} vulnerabilities"
                    ]
                }
            }));
    return _.filter([
        riskModel.risk.infos.find(info => info.type == Contract.AwsInboundExternalEc2InstanceRiskInfoType.Instances)
            ? localization[Contract.TypeNames.AwsInboundExternalEc2InstanceRiskInfoType][Contract.AwsInboundExternalEc2InstanceRiskInfoType.Instances](
                {
                    instances:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            variant="itemCountAndType"/>,
                    resourceDisplayName
                })
            : undefined,
        instanceVulnerabilityStep,
        _.isEmpty(riskModel.risk.instanceIdToDataMap)
            ? undefined
            : localization.inboundExternalAccess(
                _.size(riskModel.risk.instanceIdToDataMap),
                {
                    instances:
                        <InlineEntities
                            entityIdsOrModels={_.keys(riskModel.risk.instanceIdToDataMap)}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            variant="itemCountAndType"/>,
                    networkScopes:
                        <InlineItems
                            items={
                                _(riskModel.risk.instanceIdToDataMap).
                                    flatMap(instanceData => instanceData.publicAccess.destinationNetworkScopes).
                                    map(networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope)).
                                    uniq().
                                    sort().
                                    value()}
                            namePluralizer={localization.ports}
                            variant="itemCountAndType"/>,
                    resourceDisplayName,
                    securityGroups:
                        <InlineEntities
                            entityIdsOrModels={
                                _(riskModel.risk.instanceIdToDataMap).
                                    flatMap(instanceData => instanceData.publicAccess.securityGroupIds).
                                    uniq().
                                    value()}
                            entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                            variant="itemCountAndType"/>
                }),
        ..._.map(
            riskModel.risk.infos.filter(info => info.type != Contract.AwsInboundExternalEc2InstanceRiskInfoType.Instances),
            info => {
                const infoLocalization = localization[Contract.TypeNames.AwsInboundExternalEc2InstanceRiskInfoType];
                return map(
                    info.type,
                    {
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.AutoScalingGroups]: () =>
                            infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.AutoScalingGroups](
                                {
                                    autoScalingGroups:
                                        <InlineEntities
                                            entityIdsOrModels={(info as Contract.AwsInboundExternalEc2InstanceRiskAutoScalingGroupsInfo).autoScalingGroupIds}
                                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                            variant="itemCountAndType"/>,
                                    resourceDisplayName
                                }),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.GroupTags]: () =>
                            infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.GroupTags](
                                riskModel.risk.aggregatedEntityIds.length,
                                {
                                    instances:
                                        <InlineEntities
                                            entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                            variant="itemCountAndType"/>,
                                    tags:
                                        <InlineResourceTags
                                            tags={(info as Contract.AwsInboundExternalEc2InstanceRiskGroupTagsInfo).groupTags}
                                            textVariant="text"
                                            variant="itemCountAndType"/>
                                }),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceHighSeverityRolePermissions]: () =>
                            infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceHighSeverityRolePermissions](
                                (info as Contract.AwsInboundExternalEc2InstanceRiskHighSeverityInstanceRolePermissionsInfo).instanceIds.length,
                                {
                                    highSeverityPermissionActions:
                                        <InlinePermissionActions
                                            permissionActions={(info as Contract.AwsInboundExternalEc2InstanceRiskHighSeverityInstanceRolePermissionsInfo).permissionActions}
                                            variant="itemCountAndType"/>,
                                    instances:
                                        <InlineEntities
                                            entityIdsOrModels={(info as Contract.AwsInboundExternalEc2InstanceRiskHighSeverityInstanceRolePermissionsInfo).instanceIds}
                                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                            variant="itemCountAndType"/>
                                }),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceNotExists]: () =>
                            infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceNotExists]({ resourceDisplayName }),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.NotSensitive]:
                            () =>
                                infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.NotSensitive]({
                                    resourceDisplayName
                                }),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.RunningInstances]:
                            () =>
                                infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.RunningInstances](
                                    (info as Contract.AwsInboundExternalEc2InstanceRiskRunningInstancesInfo).instanceIds.length,
                                    {
                                        instances:
                                            <InlineEntities
                                                entityIdsOrModels={(info as Contract.AwsInboundExternalEc2InstanceRiskRunningInstancesInfo).instanceIds}
                                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                                variant="itemCountAndType"/>,
                                        resourceDisplayName
                                    }),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.RunningInstanceNotExists]:
                            () => infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.RunningInstanceNotExists](),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.Sensitive]:
                            () => infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.Sensitive]({ resourceDisplayName }),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.SensitiveAutoScalingGroups]: () =>
                            infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.SensitiveAutoScalingGroups](
                                (info as Contract.AwsInboundExternalEc2InstanceRiskSensitiveEntitiesInfo).entityIds.length,
                                {
                                    autoScalingGroups:
                                        <InlineEntities
                                            entityIdsOrModels={(info as Contract.AwsInboundExternalEc2InstanceRiskSensitiveEntitiesInfo).entityIds}
                                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                            variant="itemCountAndType"/>
                                }),
                        [Contract.AwsInboundExternalEc2InstanceRiskInfoType.SensitiveInstances]:
                            () =>
                                infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.SensitiveInstances](
                                    (info as Contract.AwsInboundExternalEc2InstanceRiskSensitiveEntitiesInfo).entityIds.length,
                                    {
                                        instances:
                                            <InlineEntities
                                                entityIdsOrModels={(info as Contract.AwsInboundExternalEc2InstanceRiskSensitiveEntitiesInfo).entityIds}
                                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                                variant="itemCountAndType"/>
                                    })
                    });
            })
    ]);
}