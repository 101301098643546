import { ActionButton, ActionMenuItem, AnalyticsEventActionType, CsvExportButton, DataTableColumnMultiSelect, DataTableColumnMultiSelectActions, DataTableFilterExternalOptions, DataTableSort, DataTableSortDirection, Dialog, DropdownIcon, FiltersActions, Loading, makeContextProvider, map, Menu, MenuItem, Optional, SeparatorMenuItem, setUrlRoute, StorageItem, TimeHelper, useChangeEffect, useGetExportFileName, useLayoutChangeEffect, useLocalization, useRoute, useTrackAnalytics, useTrackAnalyticsEvent, VerticalFillGrid } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Contract, ExportReportHelper, ReportsIcon, RiskType, RiskTypeGroups, scopeNodeModelStore, TypeHelper, UserHelper, useTheme } from "../../../../../../common";
import { Filters, FiltersHelper } from "../Filters";
import { Report } from "../Report";
import { Grouped, TableActions, TableColumnId, Tabs, Ungrouped } from "./components";
import { RequestData, useRiskTypeDefinition, useViewTranslator } from "./hooks";

type ItemsProps = {
    baseUrl: string;
    filtersVisibilityStorageItem?: StorageItem;
    relatedEntityModel?: Contract.EntityModel;
    riskType: RiskType;
    variant: "entityProfile" | "view";
};

export class RiskContext {
    constructor(public riskType: RiskType) {
    }
}

export const [useRiskContext, , useRiskContextProvider] = makeContextProvider<RiskContext>();

export function Items({ baseUrl, filtersVisibilityStorageItem, relatedEntityModel, riskType, variant }: ItemsProps) {
    const filtersActionsRef = useRef<FiltersActions>();
    const { groupingType: routeGroupingType, view } = useRoute(`${baseUrl}/{view}/{groupingType}`);
    const [reportDialogOpen, setReportDialogOpen] = useState(false);

    const [, , RisksContextProvider] =
        useRiskContextProvider(
            () => new RiskContext(riskType),
            [riskType]);

    const risksView =
        useMemo(
            () =>
                TypeHelper.tryParseEnum<Contract.RisksView>(
                    Contract.TypeNames.RisksView,
                    _(Contract.RisksView).
                        values().
                        find(risksViewValue => risksViewValue.toLowerCase() === view?.toLowerCase())) ?? Contract.RisksView.Open,
            [view]);
    const risksViewRef = useRef(risksView);

    const definition =
        useRiskTypeDefinition(
            riskType,
            risksView);

    const groupingType =
        useMemo(
            (): RiskTypeGroups => {
                if (routeGroupingType) {
                    return routeGroupingType as RiskTypeGroups;
                }

                const storageValue = definition.storageOptions.groupBy.getValue();
                const hasStorageValue =
                    !_.isNil(storageValue) &&
                    _(RiskTypeGroups).
                        values().
                        includes(storageValue as RiskTypeGroups);

                return _.isNil(relatedEntityModel) && risksView === Contract.RisksView.Open
                    ? hasStorageValue
                        ? storageValue as RiskTypeGroups
                        : RiskTypeGroups.RiskPolicyType
                    : RiskTypeGroups.Risk;
            },
            [routeGroupingType, risksView, relatedEntityModel, definition]);

    useEffect(
        () => {
            if (_.isNil(relatedEntityModel) && risksView === Contract.RisksView.Open) {
                definition.storageOptions.groupBy.setValue(groupingType);
            }
        },
        [groupingType, relatedEntityModel, risksView]);

    const riskStatus =
        useMemo(
            () =>
                map(
                    risksView,
                    {
                        [Contract.RisksView.Closed]: () => Contract.RiskStatus.Closed,
                        [Contract.RisksView.Ignored]: () => Contract.RiskStatus.Ignored,
                        [Contract.RisksView.Open]: () => Contract.RiskStatus.Open
                    }),
            [risksView]);

    const viewTranslator = useViewTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.items",
            () => ({
                actions: {
                    csvExport: {
                        fileNameEntityPrefix: "Findings_{{view}}_{{entityDisplayName}}",
                        fileNamePrefix: "Findings_{{view}}"
                    },
                    reportRedirect: {
                        tooltip: "Create Scheduled Report"
                    }
                },
                groups: {
                    count: [
                        " in 1 group",
                        " in {{count | NumberFormatter.humanize}} groups"
                    ],
                    item: {
                        [RiskTypeGroups.RiskPolicyType]: "Policy",
                        [RiskTypeGroups.Tenant]: "Account",
                        [RiskTypeGroups.Repository]: "Repository",
                        [RiskTypeGroups.Risk]: "Ungroup"
                    },
                    placeholder: {
                        [RiskTypeGroups.RiskPolicyType]: "Group By **Policy**",
                        [RiskTypeGroups.Tenant]: "Group By **Account**",
                        [RiskTypeGroups.Repository]: "Group By **Repository**",
                        [RiskTypeGroups.Risk]: "Group By"
                    }
                },
                itemCount: [
                    "1 result{{riskGroupCount}}",
                    "{{count | NumberFormatter.humanize}} items{{riskGroupCount}}"
                ]
            }));

    const [filtersMap, setFiltersMap] = useState<Dictionary<any>>({});
    const [visibleFilters, setVisibleFilters] = useState<string[]>([]);

    const getRequestFilters =
        useCallback(
            (filterMap: Dictionary<any>) =>
                definition.toRequestRiskFiltersFromFilterMap(
                    filterMap,
                    riskStatus,
                    filtersActionsRef.current?.getTime(),
                    relatedEntityModel?.id),
            [filtersActionsRef, relatedEntityModel, riskStatus]);

    const getRequestSort =
        useCallback(
            (sort: Optional<DataTableSort>) =>
                _.isNil(sort)
                    ? undefined
                    : new Contract.RiskControllerRiskModelSort(
                        sort.direction === DataTableSortDirection.Ascending
                            ? Contract.SortDirection.Ascending
                            : Contract.SortDirection.Descending,
                        map(
                            sort.columnId,
                            {
                                [TableColumnId.IgnoreExpirationDate]: () => Contract.RiskControllerRequestSortRiskModelProperty.IgnoreExpirationDate,
                                [TableColumnId.OpenStatusUpdateTime]: () => Contract.RiskControllerRequestSortRiskModelProperty.OpenStatusUpdateTime,
                                [TableColumnId.StatusUpdateTime]: () => Contract.RiskControllerRequestSortRiskModelProperty.StatusUpdateTime,
                                [TableColumnId.SystemCreationTime]: () => Contract.RiskControllerRequestSortRiskModelProperty.SystemCreationTime
                            })),
            []);

    const requestData =
        useMemo(
            (): RequestData => ({
                getFilters: (filterMap: Dictionary<any>) => getRequestFilters(filterMap),
                getFiltersTime: () => filtersActionsRef.current?.getTime(),
                getSort: (sort?: DataTableSort) => getRequestSort(sort)
            }),
            [getRequestFilters, filtersActionsRef, getRequestSort]);

    const [filtersInitialized, setFiltersInitialized] = useState(false);
    const sortRef = useRef<DataTableSort>();

    const getRiskReportRequestDefinition =
        useCallback(
            () =>
                definition.toRiskReportRequestDefinitionFromFilters(
                    !_.isEmpty(filtersMap),
                    getRequestFilters(filtersMap),
                    riskStatus,
                    getRequestSort(sortRef.current),
                    TimeHelper.timeZoneId()),
            [filtersMap, riskStatus, sortRef]);

    const getGroupingTypeItems =
        useCallback(
            () => {
                function createActionMenuItem(itemsGroupingType: RiskTypeGroups): MenuItem {
                    return new ActionMenuItem(
                        () => {
                            if (groupingType === itemsGroupingType) {
                                return;
                            }

                            setUrlRoute(
                                `${baseUrl}/${risksView}/${itemsGroupingType}`,
                                undefined,
                                { preserveSearchString: true });
                        },
                        localization.groups.item[itemsGroupingType](),
                        { selected: groupingType === itemsGroupingType });
                }

                return _(definition.groups).
                    map(group => createActionMenuItem(group)).
                    concatIf(
                        groupingType !== RiskTypeGroups.Risk,
                        () => new SeparatorMenuItem(),
                        () => createActionMenuItem(RiskTypeGroups.Risk)).
                    value();
            },
            [groupingType, localization, baseUrl, risksView]);

    const [groupingMenuItems, setGroupingMenuItems] = useState(() => getGroupingTypeItems());
    useChangeEffect(
        () => {
            setGroupingMenuItems(getGroupingTypeItems());
            if (groupingType === RiskTypeGroups.Risk) {
                setRiskGroupCount(undefined);
            }
        },
        [groupingType, risksView],
        250);

    const [riskGroupCount, setRiskGroupCount] = useState<number>();
    const [riskModelCount, setRiskModelCount] = useState<number>();
    useLayoutChangeEffect(
        () => {
            if (filtersInitialized) {
                setRiskModelCount(undefined);
                setRiskGroupCount(undefined);
                sortRef.current = undefined;
            }
        },
        [filtersMap, filtersInitialized, groupingType]);

    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(undefined, true);
    const permittedScopeNodeMap =
            _.pickBy(
                scopeNodeMap,
                (_, scopeId) => UserHelper.hasScopePermissions(scopeId, Contract.IdentityPermission.SecurityWrite));
    const permittedScopeNodes =
            _(permittedScopeNodeMap).
                values().
                filter(
                    permittedScopeNode =>
                        _.isNil(permittedScopeNode.parentScopeNode) ||
                        _.isNil(permittedScopeNodeMap[permittedScopeNode.parentScopeNode.scopeNodeModel.configuration.id])).
                value();

    useTrackAnalyticsEvent(
        AnalyticsEventActionType.PageContentView,
        {
            "Risks Grouping Type": localization.groups.placeholder[groupingType](),
            "Risks View Type": viewTranslator(risksView ?? Contract.RisksView.Open)
        },
        [groupingType, localization, risksView, viewTranslator]);

    const filterExternalOptions =
        useMemo(
            (): DataTableFilterExternalOptions => ({
                actionsRef: filtersActionsRef,
                active: _.keys(filtersMap),
                visible: visibleFilters
            }),
            [filtersActionsRef, visibleFilters, filtersMap]);

    const tableActionsRef = useRef<TableActions>();
    const columnMultiSelectActionRef = useRef<DataTableColumnMultiSelectActions>();
    useEffect(
        () => {
            setTimeout(() => columnMultiSelectActionRef.current?.reset());
        },
        [risksView]);
    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);

    const getReportData =
        useCallback(
            () => {
                const riskReportRequestDefinition = getRiskReportRequestDefinition();
                return {
                    additionalColumnResourceTagKeys: riskReportRequestDefinition.additionalColumnResourceTagKeys,
                    filtersMap:
                        _.isNil(riskReportRequestDefinition?.filters)
                            ? undefined
                            : FiltersHelper.toFilterMapFromRequestCloudFilters(riskReportRequestDefinition.filters as Contract.RiskControllerCloudRiskModelFilters),
                    reportType:
                        riskType === RiskType.Cloud
                            ? Contract.ReportType.Risks
                            : Contract.ReportType.CodeRisks,
                    riskType,
                    status: riskReportRequestDefinition.status
                };
            },
            [riskType, getRiskReportRequestDefinition]);

    const trackAnalytics = useTrackAnalytics();
    const theme = useTheme();
    return (
        <RisksContextProvider>
            {reportDialogOpen &&
                <Dialog
                    variant="editor"
                    onClose={() => setReportDialogOpen(false)}>
                    <Report
                        getReportData={getReportData}
                        setReportDialogOpen={setReportDialogOpen}/>
                </Dialog>}
            <VerticalFillGrid>
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "flex-start",
                        justifyContent: "center",
                        padding:
                            variant === "entityProfile"
                                ? theme.spacing(0, 0, 1.5)
                                : theme.spacing(2),
                        width: "100%"
                    }}>
                    <Filters
                        actionsRef={filtersActionsRef}
                        initialFilterMap={filtersMap}
                        queryParameterName="filterMap"
                        relatedEntityId={relatedEntityModel?.id}
                        risksView={risksViewRef.current}
                        riskType={riskType}
                        visibilityStorageItem={filtersVisibilityStorageItem}
                        onFilterChanged={setFiltersMap}
                        onInitialized={() => setFiltersInitialized(true)}
                        onVisibleFiltersChanged={setVisibleFilters}/>
                    <Stack
                        alignItems="center"
                        direction="row">
                        {!_.isNil(riskModelCount) &&
                            <Typography
                                color={theme.palette.text.secondary}
                                sx={{
                                    alignSelf: "center",
                                    margin: theme.spacing(0, 2)
                                }}>
                                {localization.itemCount(
                                    riskModelCount,
                                    {
                                        riskGroupCount:
                                            !_.isNil(riskGroupCount)
                                                ? localization.groups.count(riskGroupCount)
                                                : ""
                                    })}
                            </Typography>}
                        <Box sx={{ marginRight: theme.spacing(2) }}>
                            <Menu
                                itemsOrGetItems={groupingMenuItems}
                                itemSx={{ minWidth: theme.spacing(16) }}
                                variant="bottomCenter">
                                <Stack
                                    direction="row"
                                    sx={{ color: theme.palette.text.primary }}>
                                    <Typography noWrap={true}>
                                        {localization.groups.placeholder[groupingType]()}
                                    </Typography>
                                    <DropdownIcon
                                        sx={{
                                            fontSize: "18px",
                                            marginLeft: theme.spacing(0.5)
                                        }}/>
                                </Stack>
                            </Menu>
                        </Box>
                        <Tabs
                            baseUrl={baseUrl}
                            selectedGroupType={groupingType}
                            selectedView={risksView}
                            onChange={
                                newView => {
                                    setFiltersMap({});
                                    risksViewRef.current = newView;
                                }}/>
                        <Box sx={{ marginLeft: theme.spacing(1) }}>
                            {!_.isEmpty(permittedScopeNodes) &&
                            _.isNil(relatedEntityModel) &&
                                <ActionButton
                                    size="medium"
                                    tooltip={localization.actions.reportRedirect.tooltip()}
                                    variant="outlined"
                                    onClick={
                                        () => {
                                            trackAnalytics(
                                                AnalyticsEventActionType.ScheduleReportButtonClick,
                                                { "Type": "Risks" });
                                            setReportDialogOpen(true);
                                        }}>
                                    <ReportsIcon/>
                                </ActionButton>}
                        </Box>
                        <Box sx={{ marginLeft: theme.spacing(1) }}>
                            <CsvExportButton
                                fileNameOptions={{
                                    filtered: !_.isEmpty(filtersMap),
                                    prefix:
                                        _.isNil(relatedEntityModel)
                                            ? localization.actions.csvExport.fileNamePrefix({ view: viewTranslator(risksView) })
                                            : localization.actions.csvExport.fileNameEntityPrefix({
                                                entityDisplayName: relatedEntityModel?.entity.displayName,
                                                view: viewTranslator(risksView)
                                            })
                                }}
                                itemCount={riskModelCount}
                                showLimitMessage={false}
                                onClick={
                                    async fileNameOptions => {
                                        const riskReportRequestDefinition = getRiskReportRequestDefinition();
                                        await ExportReportHelper.downloadRemote({
                                            ...riskReportRequestDefinition,
                                            name: getExportFileName(fileNameOptions)
                                        });
                                    }}/>
                        </Box>
                        {groupingType === RiskTypeGroups.Risk && !_.isNil(tableActionsRef.current) &&
                            <Box sx={{ marginLeft: theme.spacing(1) }}>
                                <DataTableColumnMultiSelect
                                    actionsRef={columnMultiSelectActionRef}
                                    dataTableActionsRef={tableActionsRef.current.getDataTableActionRef()}/>
                            </Box>}
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        height: "100%",
                        width: "100%"
                    }}>
                    <Loading>
                        {groupingType === RiskTypeGroups.Risk
                            ? <Ungrouped
                                columnMultiSelectActionRef={columnMultiSelectActionRef}
                                filterExternalOptions={filterExternalOptions}
                                filtersInitialized={filtersInitialized}
                                filtersMap={filtersMap}
                                requestData={requestData}
                                tableActionsRef={tableActionsRef}
                                view={risksView}
                                onRiskModelCountChanged={riskModelCount => setRiskModelCount(riskModelCount)}
                                onSortChanged={(sort?: DataTableSort) => sortRef.current = sort}/>
                            : <Grouped
                                filterExternalOptions={filterExternalOptions}
                                filtersInitialized={filtersInitialized}
                                filtersMap={filtersMap}
                                groupingType={groupingType}
                                key={`${risksView}-${groupingType}`}
                                requestData={requestData}
                                riskStatus={riskStatus}
                                sx={{
                                    ...(variant === "view" && {
                                        minWidth: "unset"
                                    })
                                }}
                                view={risksView}
                                onRiskGroupCountChanged={riskGroupCount => setRiskGroupCount(riskGroupCount)}
                                onRiskModelCountChanged={riskModelCount => setRiskModelCount(riskModelCount)}/>}
                    </Loading>
                </Stack>
            </VerticalFillGrid>
        </RisksContextProvider>);
}