import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React from "react";

export function BackIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon
            sx={{
                width: theme.spacing(1.5),
                ...props?.sx
            }}
            viewBox="0 0 12 24"
            {...props}>
            <path
                clipRule="evenodd"
                d="M9.96556 18.1114C10.4563 18.5969 10.4606 19.3884 9.97504 19.8792C9.48952 20.3699 8.69806 20.3741 8.20729 19.8886L0.222601 12L8.2073 4.11141C8.69806 3.62588 9.48951 3.63013 9.97504 4.12089C10.4606 4.61166 10.4562 5.40325 9.96541 5.88878L3.7778 12L9.96556 18.1114Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}