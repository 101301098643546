import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useGetPermissionAssignmentEligibilityUpdateAuditEventCommonChanges } from ".";
import { Contract } from "../../../..";

export function useGetAzureRoleAssignmentEligibilityUpdateAuditEventChanges() {
    const getPermissionAssignmentEligibilityUpdateAuditEventCommonChanges = useGetPermissionAssignmentEligibilityUpdateAuditEventCommonChanges();

    const localization =
        useLocalization(
            "common.hooks.usePermissionEligibilityUpdateAuditEventChangesTranslator.hooks.useGetPermissionAssignmentEligibilityUpdateAuditEventChanges.useGetAzureRoleAssignmentEligibilityUpdateAuditEventChanges",
            () => ({
                roleDefinitions: "Permissions"
            }));
    return (permissionEligibilityUpdateAuditEventPermissionEligibilityChanges: Contract.PermissionEligibilityUpdateAuditEventAzureRoleAssignmentEligibilityChanges) => {
        const permissionAssignmentEligibilityUpdateAuditEventCommonChanges = getPermissionAssignmentEligibilityUpdateAuditEventCommonChanges(permissionEligibilityUpdateAuditEventPermissionEligibilityChanges);
        return _.filter([
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.name,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.principalTenantId,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.granteePrincipals,
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.roleDefinitionsChanged
                ? localization.roleDefinitions()
                : undefined!,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.permissionRequestScopes,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.expirationTimeFrame,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.approval,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.reasonRequired
        ]);
    };
}