﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../common";

export function useRiskPolicyConfigurationInactivityTimeFrameStep(inactivityTimeFrame: string) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyConfigurationInactivityTimeFrameStep",
            () => ({
                documentationLink: "Learn more",
                text: "This finding was created based on a {{inactivityTimeFrame | TimeSpanFormatter.day}} inactivity period. {{documentationLink}}."
            }));

    return localization.text({
        documentationLink:
            <Link
                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                variant="external">
                {localization.documentationLink()}
            </Link>,
        inactivityTimeFrame
    });
}