﻿import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useRegionLocationTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useRegionLocationTranslator",
            () => ({
                [Contract.TypeNames.RegionLocation]: {
                    [Contract.RegionLocation.Africa]: "Africa",
                    [Contract.RegionLocation.Asia]: "Asia",
                    [Contract.RegionLocation.AsiaPacific]: "Asia Pacific",
                    [Contract.RegionLocation.Australia]: "Australia",
                    [Contract.RegionLocation.Bahrain]: "Bahrain",
                    [Contract.RegionLocation.Bangladesh]: "Bangladesh",
                    [Contract.RegionLocation.Belgium]: "Belgium",
                    [Contract.RegionLocation.Brazil]: "Brazil",
                    [Contract.RegionLocation.Canada]: "Canada",
                    [Contract.RegionLocation.Chile]: "Chile",
                    [Contract.RegionLocation.China]: "China",
                    [Contract.RegionLocation.Colombia]: "Colombia",
                    [Contract.RegionLocation.Europe]: "Europe",
                    [Contract.RegionLocation.Finland]: "Finland",
                    [Contract.RegionLocation.France]: "France",
                    [Contract.RegionLocation.Germany]: "Germany",
                    [Contract.RegionLocation.Global]: "Global",
                    [Contract.RegionLocation.India]: "India",
                    [Contract.RegionLocation.Indonesia]: "Indonesia",
                    [Contract.RegionLocation.Ireland]: "Ireland",
                    [Contract.RegionLocation.Israel]: "Israel",
                    [Contract.RegionLocation.Italy]: "Italy",
                    [Contract.RegionLocation.Japan]: "Japan",
                    [Contract.RegionLocation.Malaysia]: "Malaysia",
                    [Contract.RegionLocation.Mexico]: "Mexico",
                    [Contract.RegionLocation.Netherlands]: "Netherlands",
                    [Contract.RegionLocation.NewZealand]: "New Zealand",
                    [Contract.RegionLocation.NorthAmerica]: "North America",
                    [Contract.RegionLocation.Norway]: "Norway",
                    [Contract.RegionLocation.Oman]: "Oman",
                    [Contract.RegionLocation.Poland]: "Poland",
                    [Contract.RegionLocation.Qatar]: "Qatar",
                    [Contract.RegionLocation.SaudiArabia]: "Saudi Arabia",
                    [Contract.RegionLocation.Serbia]: "Serbia",
                    [Contract.RegionLocation.Singapore]: "Singapore",
                    [Contract.RegionLocation.SouthAfrica]: "South Africa",
                    [Contract.RegionLocation.SouthKorea]: "South Korea",
                    [Contract.RegionLocation.Spain]: "Spain",
                    [Contract.RegionLocation.Sweden]: "Sweden",
                    [Contract.RegionLocation.Switzerland]: "Switzerland",
                    [Contract.RegionLocation.Thailand]: "Thailand",
                    [Contract.RegionLocation.UnitedArabEmirates]: "United Arab Emirates",
                    [Contract.RegionLocation.UnitedKingdom]: "United Kingdom",
                    [Contract.RegionLocation.UnitedStates]: "United States",
                    [Contract.RegionLocation.UnitedStatesGov]: "United States Gov"
                }
            }));

    return (regionLocation: Contract.RegionLocation) =>
        localization[Contract.TypeNames.RegionLocation][regionLocation]();
}