import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, entityModelStore, tenantModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationPasswordRequirementContextItem } from "../../../useGetRiskPolicyConfigurationPasswordRequirementContextItem";
import { useGetAwsTenantEntityRiskContext } from "../contexts";

export function useAwsIamPasswordLengthPolicyRiskDefinition(riskModel: Contract.RiskModel) {
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const risk = riskModel.risk as Contract.AwsIamPasswordLengthPolicyRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AwsTenantEntityModel;
    const tenantEntity = tenantEntityModel.entity as Contract.AwsTenantEntity;

    const getAwsTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();
    const getRiskPolicyConfigurationPasswordRequirementContextItem = useGetRiskPolicyConfigurationPasswordRequirementContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamPasswordLengthPolicyRiskDefinition",
            () => ({
                contextItems: {
                    passwordPolicyMinLength: {
                        entity: {
                            empty: "The {{translatedTenantEntityTypeName}}'s password length policy is not set",
                            text: "The {{translatedTenantEntityTypeName}}'s password length policy is currently set to {{passwordPolicyMinLength}} characters long"
                        },
                        riskPolicyConfiguration: "required minimum password length of **{{value}} characters**"
                    }
                },
                description: "Password length policy should enforce minimum length of at least {{passwordPolicyMinLength}} characters long",
                sections: {
                    resolution: {
                        step1: "In the left navigation pane, select **Account Settings**",
                        step2: "In the **Password policy** section, click **Edit**",
                        step3: "Set the minimum length to at least {{passwordPolicyMinLength}} characters",
                        step4: "Click **Save Changes**"
                    }
                }
            }));
    const translatedTenantEntityTypeName =
        entityTypeNameTranslator(
            tenantEntityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({ passwordPolicyMinLength: risk.passwordPolicyMinLength }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamUrl(tenantConfiguration.partitionType)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3({ passwordPolicyMinLength: risk.passwordPolicyMinLength }),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getAwsTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                getRiskPolicyConfigurationPasswordRequirementContextItem(localization.contextItems.passwordPolicyMinLength.riskPolicyConfiguration({ value: risk.passwordPolicyMinLength })),
                new RiskDefinitionContextItem(
                    _.isNil(tenantEntity.iam?.passwordPolicy?.minLength)
                        ? localization.contextItems.passwordPolicyMinLength.entity.empty({ translatedTenantEntityTypeName })
                        : localization.contextItems.passwordPolicyMinLength.entity.text({
                            passwordPolicyMinLength: tenantEntity.iam!.passwordPolicy!.minLength,
                            translatedTenantEntityTypeName
                        })),
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.accountStatus,
                tenantEntityRiskContext.path,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}