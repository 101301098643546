import { ApiError, defined, ItemSelector, PasswordTextField, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "../..";
import { Contract, TenantController, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../../common";
import { usePingIdentityClientRegionTranslator } from "../../../../../../../../../../../../../../../../tenants";
import { InstructionsImageUrl } from "./images";

export function TenantItem() {
    const tenantModels = tenantModelStore.useGetPermittedPingIdentityTenants();
    const { tenantClientId, tenantClientSecret, tenantConfiguration, tenantName, tenantRawId, tenantRegion } = useAddOrEditContext();
    const [clientId, setClientId] = useState(tenantClientId);
    const [clientSecret, setClientSecret] = useState(tenantClientSecret);
    const [name, setName] = useState(tenantName);
    const [rawId, setRawId] = useState(tenantRawId);
    const [region, setRegion] = useState(tenantRegion);

    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
            setLoaded();
        },
        []);

    const pingIdentityClientRegionTranslator = usePingIdentityClientRegionTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.pingIdentityTenants.addOrEdit.tenantItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add Ping Identity environment",
                            edit: "Failed to save Ping Identity environment",
                            [Contract.TypeNames.TenantControllerInsertPingIdentityTenantError]: {
                                [Contract.TenantControllerInsertPingIdentityTenantError.AuthenticationFailed]: "Authentication failure",
                                [Contract.TenantControllerInsertPingIdentityTenantError.MissingPermissions]: "Insufficient permissions",
                                [Contract.TenantControllerInsertPingIdentityTenantError.TenantExists]: "Environment already exists"
                            },
                            [Contract.TypeNames.TenantControllerUpdatePingIdentityTenantError]: {
                                [Contract.TenantControllerUpdatePingIdentityTenantError.AuthenticationFailed]: "Authentication failure",
                                [Contract.TenantControllerUpdatePingIdentityTenantError.MissingPermissions]: "Insufficient permissions"
                            }
                        }
                    }
                },
                fields: {
                    clientId: {
                        error: {
                            required: "Client ID cannot be empty"
                        },
                        title: "Client ID"
                    },
                    clientSecret: {
                        error: {
                            required: "Client secret cannot be empty"
                        },
                        title: "Client Secret"
                    },
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    rawId: {
                        error: {
                            required: "Environment ID cannot be empty"
                        },
                        title: "Environment ID"
                    },
                    region: {
                        error: {
                            required: "Region cannot be empty"
                        },
                        placeholder: "Region"
                    }
                }
            }
            ));

    useNextEffect(
        async () => {
            try {
                let updatedTenantModel: Contract.PingIdentityTenantModel;
                if (!_.isNil(tenantConfiguration)) {
                    const { tenantModel } =
                        await TenantController.updatePingIdentityTenant(
                            new Contract.TenantControllerUpdatePingIdentityTenantRequest(
                                clientId.trim(),
                                clientSecret?.trim(),
                                tenantConfiguration.id,
                                name.trim()));
                    updatedTenantModel = tenantModel;
                } else {
                    const { tenantModel } =
                        await TenantController.insertPingIdentityTenant(
                            new Contract.TenantControllerInsertPingIdentityTenantRequest(
                                clientId.trim(),
                                defined(clientSecret?.trim()),
                                name.trim(),
                                rawId,
                                defined(region)));
                    updatedTenantModel = tenantModel;
                }

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        updatedTenantModel
                    }));
            } catch (error) {
                return (
                    error instanceof ApiError && error.statusCode === 400
                        ? _.isNil(tenantConfiguration)
                            ? localization.actions.save.error[Contract.TypeNames.TenantControllerInsertPingIdentityTenantError][error.error as Contract.TenantControllerInsertPingIdentityTenantError]()
                            : localization.actions.save.error[Contract.TypeNames.TenantControllerUpdatePingIdentityTenantError][error.error as Contract.TenantControllerUpdatePingIdentityTenantError]()
                        : _.isNil(tenantConfiguration)
                            ? localization.actions.save.error.add()
                            : localization.actions.save.error.edit());
            }
        },
        [clientId, clientSecret, name, rawId, region]);

    const existingTenantConfigurations =
        useMemo(
            () =>
                _(tenantModels).
                    filter(tenantModel => tenantModel.configuration.id !== tenantConfiguration?.id).
                    map(tenantModel => tenantModel.configuration as Contract.PingIdentityTenantConfiguration).
                    value(),
            [tenantModels]);

    const [clientIdValidationController, clientIdValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(tenantConfiguration) &&
                    _.isNil(clientId)) {
                    return undefined;
                }

                const validationClientId = clientId?.trim();
                if (_.isEmpty(validationClientId)) {
                    return localization.fields.clientId.error.required();
                }

                return undefined;
            },
            [clientId]);

    const [clientSecretValidationController, clientSecretValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(tenantConfiguration) &&
                    _.isNil(clientSecret)) {
                    return undefined;
                }

                const validationClientSecret = clientSecret?.trim();
                if (_.isEmpty(validationClientSecret)) {
                    return localization.fields.clientSecret.error.required();
                }

                return undefined;
            },
            [clientSecret]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }
                if (_.some(
                    existingTenantConfigurations,
                    existingTenantConfiguration => existingTenantConfiguration.name === validationName)) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [rawIdValidationController, rawIdValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(tenantConfiguration) &&
                    _.isNil(rawId)) {
                    return undefined;
                }

                const validationRawId = rawId?.trim();
                if (_.isEmpty(validationRawId)) {
                    return localization.fields.rawId.error.required();
                }

                return undefined;
            },
            [rawId]);


    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantClientId: clientId.trim(),
                    tenantClientSecret: clientSecret?.trim(),
                    tenantName: name.trim(),
                    tenantRawId: rawId.trim(),
                    tenantRegion: region
                }));

            setValid(
                clientIdValidationController.isValid() &&
                clientSecretValidationController.isValid() &&
                nameValidationController.isValid() &&
                rawIdValidationController.isValid() &&
                !_.isNil(region));
        },
        [clientId, clientSecret, name, rawId, region]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.name.title()}
                    value={name}
                    variant="outlined"
                    onChange={event => setName(event.target.value)}/>
                {!_.isNil(nameValidationMessage) &&
                    <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.rawId.title()}
                    slotProps={{
                        input: {
                            readOnly: !_.isNil(tenantConfiguration)
                        }
                    }}
                    value={rawId}
                    variant="outlined"
                    onChange={event => setRawId(event.target.value)}/>
                {!_.isNil(rawIdValidationMessage) &&
                    <FormHelperText error={true}>{rawIdValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.clientId.title()}
                    value={clientId}
                    variant="outlined"
                    onChange={event => setClientId(event.target.value)}/>
                {!_.isNil(clientIdValidationMessage) &&
                    <FormHelperText error={true}>{clientIdValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <PasswordTextField
                    disabled={executing}
                    label={localization.fields.clientSecret.title()}
                    password={clientSecret}
                    variant="outlined"
                    onPasswordChanged={clientSecret => setClientSecret(clientSecret)}/>
                {!_.isNil(clientSecretValidationMessage) &&
                    <FormHelperText error={true}>{clientSecretValidationMessage}</FormHelperText>}
            </FormControl>
            <ItemSelector
                disabled={!_.isNil(tenantConfiguration)}
                fullWidth={true}
                items={[
                    Contract.PingIdentityClientRegion.Us,
                    Contract.PingIdentityClientRegion.Eu,
                    Contract.PingIdentityClientRegion.Ca,
                    Contract.PingIdentityClientRegion.Ap]}
                placeholder={localization.fields.region.placeholder()}
                selectedItem={region}
                sorted={false}
                onSelectedItemChanged={region => setRegion(region as Contract.PingIdentityClientRegion)}>
                {(region: Contract.PingIdentityClientRegion) => pingIdentityClientRegionTranslator(region)}
            </ItemSelector>
        </Stack>);
}