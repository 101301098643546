﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useGcpComputeVpcPeeringConnectionSupportedIpAddressFamiliesTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpComputeVpcPeeringConnectionSupportedIpAddressFamiliesTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies]: {
                    [Contract.GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies.IpV4]: "IP Version 4",
                    [Contract.GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies.IpV4AndIpV6]: "IP Version 4 & 6"
                }
            }));
    return (ipAddressFamilies: Contract.GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies) =>
        localization[Contract.TypeNames.GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies][ipAddressFamilies]();
}