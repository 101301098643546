﻿import { Optional, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../common";
import { ScopesView, ScopesViewType } from "../../utilities";
import { useAwsDefinition, useAzureDefinition, useCiDefinition, useCodeDefinition, useGcpDefinition, useOciDefinition, useOpDefinition, useProjectsDefinition } from "./hooks";

export function useDefinition(view: ScopesViewType): TenantsDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case ScopesView.Aws:
                        return useAwsDefinition;
                    case ScopesView.Azure:
                        return useAzureDefinition;
                    case ScopesView.Ci:
                        return useCiDefinition;
                    case ScopesView.Code:
                        return useCodeDefinition;
                    case ScopesView.Gcp:
                        return useGcpDefinition;
                    case ScopesView.Oci:
                        return useOciDefinition;
                    case ScopesView.Op:
                        return useOpDefinition;
                    case ScopesView.Project:
                        return useProjectsDefinition;
                    default:
                        throw new UnexpectedError("view", view);
                }
            },
            [view]);

    return useDefinition();
}

export type TenantsDefinition = {
    deleteFolderDisabled?: boolean;
    deleteTenantDisabled?: boolean;
    editTenantDisabled?: boolean;
    filter: (filterMap: _.Dictionary<any>, scopeNodeModel: Contract.ScopeNodeModel) => boolean;
    filterItems?: (scopeNodeModels: Contract.ScopeNodeModel[]) => Contract.ScopeNodeModel[];
    getColumns: (scopeNodeModels: Contract.ScopeNodeModel[]) => ReactNode[];
    getDeleteScopeDisabledMessage?: (selection: boolean, scopeNodeModel: Contract.ScopeNodeModel) => Optional<string>;
    getTenantAdditionalInfoItems?: (scopeNodeModel: Contract.ScopeNodeModel) => TenantAdditionalInfoItem[];
    sort: (columnId: string, scopeNodeModel: Contract.ScopeNodeModel) => any;
    title: string;
};

export class TenantAdditionalInfoItem {
    constructor(
        public title: string,
        public valueOrValues: string | string[],
        public element?: ReactNode) {
    }
}