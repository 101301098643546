import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpDefaultResourceInfoItemElements } from ".";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useGcpComputeVpcPeeringConnectionStatusTranslator, useGcpComputeVpcPeeringConnectionSupportedIpAddressFamiliesTranslator } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpComputeVpcPeeringConnectionDefinition(resourceModel: Contract.GcpResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(resourceModel);
    const vpcPeeringConnection = resourceModel.entity as Contract.GcpComputeVpcPeeringConnection;
    const vpcPeeringConnectionModel = resourceModel as Contract.GcpComputeVpcPeeringConnectionModel;

    const computeVpcPeeringConnectionStatusTranslator = useGcpComputeVpcPeeringConnectionStatusTranslator();
    const computeVpcPeeringConnectionSupportedIpAddressFamiliesTranslator = useGcpComputeVpcPeeringConnectionSupportedIpAddressFamiliesTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeVpcPeeringConnectionDefinition",
            () => ({
                info: {
                    items: {
                        accepterSubnetIds: "Associated Subnets",
                        accepterVpcIdReference: "Accepter VPC",
                        accepterVpcSubnetSubnets: "Accepter CIDRs",
                        exportCustomRoutes: {
                            false: "Disabled",
                            title: "Export custom routes",
                            true: "Enabled"
                        },
                        exportPublicSubnetRoutes: {
                            false: "Disabled",
                            title: "Export subnet routes with public IP",
                            true: "Enabled"
                        },
                        importCustomRoutes: {
                            false: "Disabled",
                            title: "Import custom routes",
                            true: "Enabled"
                        },
                        importPublicSubnetRoutes: {
                            false: "Disabled",
                            title: "Import subnet routes with public IP",
                            true: "Enabled"
                        },
                        requesterVpcId: "Requester VPC",
                        requesterVpcSubnetSubnets: "Requester CIDRs",
                        respectiveVpcConnection: "Associated VPC Peering Connection",
                        status: "Status",
                        supportedIpAddressFamilies: "IP stack type"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={computeVpcPeeringConnectionStatusTranslator(vpcPeeringConnection.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel.requesterVpcId}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="requesterVpcId"
                        title={localization.info.items.requesterVpcId()}/>,
                    <VerticalTopItemsInfoItem
                        items={vpcPeeringConnectionModel.requesterVpcSubnetSubnets}
                        key="requesterVpcSubnetSubnets"
                        title={localization.info.items.requesterVpcSubnetSubnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel.accepterVpcIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="accepterVpcIdReference"
                        title={localization.info.items.accepterVpcIdReference()}/>,
                    <VerticalTopItemsInfoItem
                        items={vpcPeeringConnectionModel.accepterVpcSubnetSubnets}
                        key="accepterVpcSubnetSubnets"
                        title={localization.info.items.accepterVpcSubnetSubnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel.respectiveVpcPeeringConnectionId}
                        entityTypeName={Contract.TypeNames.GcpComputeVpcPeeringConnection}
                        key="respectiveVpcConnection"
                        title={localization.info.items.respectiveVpcConnection()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel.accepterSubnetIds}
                        entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                        key="accepterSubnetIds"
                        title={localization.info.items.accepterSubnetIds()}/>,
                    <InfoItem
                        key="supportedIpAddressFamilies"
                        title={localization.info.items.supportedIpAddressFamilies()}
                        value={computeVpcPeeringConnectionSupportedIpAddressFamiliesTranslator(vpcPeeringConnection.supportedIpAddressFamilies)}/>,
                    <InfoItem
                        key="importCustomRoutes"
                        title={localization.info.items.importCustomRoutes.title()}
                        value={
                            vpcPeeringConnection.importCustomRoutes
                                ? localization.info.items.importCustomRoutes.true()
                                : localization.info.items.importCustomRoutes.false()}/>,
                    <InfoItem
                        key="exportCustomRoutes"
                        title={localization.info.items.exportCustomRoutes.title()}
                        value={
                            vpcPeeringConnection.exportCustomRoutes
                                ? localization.info.items.exportCustomRoutes.true()
                                : localization.info.items.exportCustomRoutes.false()}/>,
                    <InfoItem
                        key="importPublicSubnetRoutes"
                        title={localization.info.items.importPublicSubnetRoutes.title()}
                        value={
                            vpcPeeringConnection.importPublicSubnetRoutes
                                ? localization.info.items.importPublicSubnetRoutes.true()
                                : localization.info.items.importPublicSubnetRoutes.false()}/>,
                    <InfoItem
                        key="exportSubnetRoutes"
                        title={localization.info.items.exportPublicSubnetRoutes.title()}
                        value={
                            vpcPeeringConnection.exportPublicSubnetRoutes
                                ? localization.info.items.exportPublicSubnetRoutes.true()
                                : localization.info.items.exportPublicSubnetRoutes.false()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}