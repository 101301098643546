import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureNetworkNetworkSecurityGroupRiskContext() {
    return useMemo(
        () => useAzureNetworkNetworkSecurityGroupRiskContext,
        []);
}

function useAzureNetworkNetworkSecurityGroupRiskContext(networkSecurityGroupModel: Contract.AzureNetworkNetworkSecurityGroupModel) {
    const networkSecurityGroup = networkSecurityGroupModel.entity as Contract.AzureNetworkNetworkSecurityGroup;
    const resourceRiskContext = useGetAzureResourceRiskContext()(networkSecurityGroupModel);
    const networkedResourceModels =
        entityModelStore.useGet(
            _(networkSecurityGroupModel.networkedResourceTypeNameToIdsMap).
                values().
                flatten().
                value());
    const sensitiveNetworkedResourceModels =
        useMemo(
            () =>
                _.filter(
                    networkedResourceModels,
                    networkedResourceModel =>
                        networkedResourceModel.entityConfiguration?.sensitive === true),
            [networkSecurityGroupModel]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureNetworkNetworkSecurityGroupRiskContext",
            () => ({
                attachedResources: {
                    attached: "The {{translatedSecurityGroupTypeName}} is attached to {{resources}}",
                    notAttachedResources: "The {{translatedSecurityGroupTypeName}} is not attached to any resources",
                    notAttachedSubnets: "The {{translatedSecurityGroupTypeName}} is not attached to any subnet"
                },
                sensitiveNetworkedResources: [
                    "The {{translatedSecurityGroupTypeName}} is attached to {{sensitiveNetworkedResources}} that is marked as sensitive",
                    "The {{translatedSecurityGroupTypeName}} is attached to {{sensitiveNetworkedResources}} that are marked as sensitive"
                ]
            }));
    const translatedSecurityGroupTypeName =
        entityTypeNameTranslator(
            networkSecurityGroup.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        networkedResources:
            new RiskDefinitionContextItem(
                (_.isEmpty(networkedResourceModels)
                    ? localization.attachedResources.notAttachedResources
                    : localization.attachedResources.attached)({
                    resources:
                        <InlineEntities
                            entityIdsOrModels={networkedResourceModels}
                            entityTypeName={Contract.TypeNames.AzureResourceGroupResource}
                            variant="itemCountAndType"/>,
                    translatedSecurityGroupTypeName
                })),
        sensitiveNetworkedResources:
            _.isEmpty(sensitiveNetworkedResourceModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sensitiveNetworkedResources(
                        _.size(sensitiveNetworkedResourceModels),
                        {
                            sensitiveNetworkedResources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveNetworkedResourceModels}
                                    entityTypeName={Contract.TypeNames.AzureResourceGroupResource}
                                    variant="itemOrItemCountAndType"/>,
                            translatedSecurityGroupTypeName
                        })),
        subnets:
            new RiskDefinitionContextItem(
                (_.isEmpty(networkSecurityGroupModel.subnetIds)
                    ? localization.attachedResources.notAttachedSubnets
                    : localization.attachedResources.attached)({
                    resources:
                        <InlineEntities
                            entityIdsOrModels={networkSecurityGroupModel.subnetIds}
                            entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}
                            variant="itemCountAndType"/>,
                    translatedSecurityGroupTypeName
                }))
    };
}