﻿import { DataTable, DataTableColumn, EmptyMessageText, map, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Contract, Entity } from "../../../common";
import { useAwsApiGatewayApiRouteMethodTranslator } from "../hooks";

type AwsApiGatewayV2ApiRoutesTableProps = {
    apiModel: Contract.AwsApiGatewayV2ApiModel;
    getHighlightColor?: (route: Contract.AwsApiGatewayV2ApiRoute, opacity?: number) => Optional<string>;

};

export function AwsApiGatewayV2ApiRoutesTable({ apiModel, getHighlightColor }: AwsApiGatewayV2ApiRoutesTableProps) {
    const api = apiModel.entity as Contract.AwsApiGatewayV2Api;

    const apiGatewayApiMethodTranslator = useAwsApiGatewayApiRouteMethodTranslator();
    const localization =
        useLocalization(
            "tenants.aws.awsApiGatewayV2ApiRoutesTable",
            () => ({
                columns: {
                    apiKey: {
                        false: "No",
                        title: "API key required",
                        true: "Yes"
                    },
                    authorizationType: {
                        iam: "IAM",
                        title: "Authorization"
                    },
                    integration: {
                        mock: "Mock",
                        title: "Target"
                    },
                    method: "Method",
                    path: "Resource"
                },
                empty: "No resources"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                () =>
                    _.orderBy(
                        api.routes,
                        [
                            route => !_.isNil(getHighlightColor?.(route)),
                            route => route.path
                        ],
                        [
                            "desc",
                            "desc"
                        ])}
            getItemId={(item: Contract.AwsApiGatewayV2ApiRoute) => item.path}
            rowOptions={{
                getHighlightColor: (route: Contract.AwsApiGatewayV2ApiRoute) => getHighlightColor?.(route),
                getSx:
                    (route: Contract.AwsApiGatewayV2ApiRoute) => ({
                        backgroundColor: getHighlightColor?.(route, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            {_.filter([
                <DataTableColumn
                    id={TableColumnId.Path}
                    itemProperty={(route: Contract.AwsApiGatewayV2ApiRoute) => route.path}
                    key={TableColumnId.Path}
                    title={localization.columns.path()}/>,
                api.type === Contract.AwsApiGatewayApiType.Http
                    ? <DataTableColumn
                        id={TableColumnId.Method}
                        key={TableColumnId.Method}
                        render={
                            optionalTableCell<Contract.AwsApiGatewayV2ApiRoute>(
                                route =>
                                    _.isNil(route.method)
                                        ? undefined
                                        : apiGatewayApiMethodTranslator(route.method))}
                        title={localization.columns.method()}/>
                    : undefined,
                <DataTableColumn
                    id={TableColumnId.AuthorizationType}
                    key={TableColumnId.AuthorizationType}
                    render={
                        optionalTableCell<Contract.AwsApiGatewayV2ApiRoute>(
                            route =>
                                map<Contract.AwsApiGatewayV2ApiAuthorizationType, ReactNode | string | undefined>(
                                    route.authorizationType,
                                    {
                                        [Contract.AwsApiGatewayV2ApiAuthorizationType.Jwt]:
                                            () => api.authorizerMap[route.authorizerId!].jwtIssuerUrl!,
                                        [Contract.AwsApiGatewayV2ApiAuthorizationType.Iam]:
                                            () => localization.columns.authorizationType.iam(),
                                        [Contract.AwsApiGatewayV2ApiAuthorizationType.Lambda]:
                                            () =>
                                                <Entity
                                                    entityIdOrModel={apiModel.resourceArnToReferenceMap[api.authorizerMap[route.authorizerId!].functionConfigurationArn!]}
                                                    variant="text"/>,
                                        [Contract.AwsApiGatewayV2ApiAuthorizationType.None]: () => undefined
                                    },
                                    () => undefined
                                ))}
                    title={localization.columns.authorizationType.title()}/>,
                api.type === Contract.AwsApiGatewayApiType.Http
                    ? undefined
                    : <DataTableColumn
                        id={TableColumnId.ApiKey}
                        itemProperty={
                            (route: Contract.AwsApiGatewayV2ApiRoute) =>
                                route.apiKeyRequired
                                    ? localization.columns.apiKey.true()
                                    : localization.columns.apiKey.false()}
                        key={TableColumnId.ApiKey}
                        title={localization.columns.apiKey.title()}/>,
                <DataTableColumn
                    id={TableColumnId.IntegrationType}
                    key={TableColumnId.IntegrationType}
                    render={
                        optionalTableCell<Contract.AwsApiGatewayV2ApiRoute>(
                            route =>
                                _.isNil(route.integrationId)
                                    ? undefined
                                    : map<Contract.AwsApiGatewayV2ApiRouteIntegrationType, ReactNode | string | undefined>(
                                        api.integrationMap[route.integrationId].type,
                                        {
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.AppConfig]:
                                                () =>
                                                    <Entity
                                                        entityIdOrModel={apiModel.resourceArnToReferenceMap[api.integrationMap[route.integrationId!].resourceArn!]}
                                                        variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.Elb]:
                                                () =>
                                                    <Entity
                                                        entityIdOrModel={apiModel.resourceArnToReferenceMap[api.integrationMap[route.integrationId!].resourceArn!]}
                                                        variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.EventBridge]:
                                                () =>
                                                    <Entity
                                                        entityIdOrModel={api.integrationMap[route.integrationId!].serviceId!}
                                                        variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.Http]:
                                                () => api.integrationMap[route.integrationId!].rawUrl!,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.Kinesis]:
                                                () =>
                                                    <Entity
                                                        entityIdOrModel={apiModel.resourceArnToReferenceMap[api.integrationMap[route.integrationId!].resourceArn!]}
                                                        variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.Lambda]:
                                                () =>
                                                    <Entity
                                                        entityIdOrModel={apiModel.resourceArnToReferenceMap[api.integrationMap[route.integrationId!].resourceArn!]}
                                                        variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.Mock]:
                                                () => localization.columns.integration.mock(),
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.Service]:
                                                () =>
                                                    _.isNil(api.integrationMap[route.integrationId!].serviceId)
                                                        ? api.integrationMap[route.integrationId!].resourceArn
                                                        : <Entity
                                                            entityIdOrModel={api.integrationMap[route.integrationId!].serviceId!}
                                                            variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.ServiceDiscovery]:
                                                () =>
                                                    <Entity
                                                        entityIdOrModel={apiModel.resourceArnToReferenceMap[api.integrationMap[route.integrationId!].resourceArn!]}
                                                        variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.States]:
                                                () =>
                                                    <Entity
                                                        entityIdOrModel={apiModel.resourceArnToReferenceMap[api.integrationMap[route.integrationId!].resourceArn!]}
                                                        variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.Sqs]:
                                                () =>
                                                    <Entity
                                                        entityIdOrModel={apiModel.resourceUrlToIdToMap[api.integrationMap[route.integrationId!].rawUrl!]}
                                                        variant="text"/>,
                                            [Contract.AwsApiGatewayV2ApiRouteIntegrationType.Vpc]:
                                                () => api.integrationMap[route.integrationId!].rawUrl!
                                        },
                                        () => undefined)
                        )}
                    title={localization.columns.integration.title()}/>
            ])}
        </DataTable>);
}

enum TableColumnId {
    ApiKey = "apiKey",
    AuthorizationType = "authorizationType",
    IntegrationType = "integrationType",
    Method = "method",
    Path = "path"
}