import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntityComputeIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M19 19V5H5V19H19ZM20 21C20.5523 21 21 20.5523 21 20V4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H20Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M20 6C20 5.44772 20.4477 5 21 5H23C23.5523 5 24 5.44772 24 6C24 6.55228 23.5523 7 23 7H21C20.4477 7 20 6.55228 20 6Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M6 4C5.44772 4 5 3.55228 5 3V1C5 0.447715 5.44772 2.41411e-08 6 0C6.55228 -2.41411e-08 7 0.447715 7 1V3C7 3.55228 6.55228 4 6 4Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M6 24C5.44772 24 5 23.5523 5 23V21C5 20.4477 5.44772 20 6 20C6.55228 20 7 20.4477 7 21V23C7 23.5523 6.55228 24 6 24Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M0 6C0 5.44772 0.447715 5 1 5H3C3.55228 5 4 5.44772 4 6C4 6.55228 3.55228 7 3 7H1C0.447715 7 0 6.55228 0 6Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M20 10C20 9.44772 20.4477 9 21 9H23C23.5523 9 24 9.44772 24 10C24 10.5523 23.5523 11 23 11H21C20.4477 11 20 10.5523 20 10Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M10 4C9.44772 4 9 3.55228 9 3V1C9 0.447715 9.44772 2.41411e-08 10 0C10.5523 -2.41411e-08 11 0.447715 11 1V3C11 3.55228 10.5523 4 10 4Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M10 24C9.44772 24 9 23.5523 9 23V21C9 20.4477 9.44772 20 10 20C10.5523 20 11 20.4477 11 21V23C11 23.5523 10.5523 24 10 24Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M0 10C0 9.44772 0.447715 9 1 9H3C3.55228 9 4 9.44772 4 10C4 10.5523 3.55228 11 3 11H1C0.447715 11 0 10.5523 0 10Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M20 14C20 13.4477 20.4477 13 21 13H23C23.5523 13 24 13.4477 24 14C24 14.5523 23.5523 15 23 15H21C20.4477 15 20 14.5523 20 14Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M14 4C13.4477 4 13 3.55228 13 3V1C13 0.447715 13.4477 2.41411e-08 14 0C14.5523 -2.41411e-08 15 0.447715 15 1V3C15 3.55228 14.5523 4 14 4Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M14 24C13.4477 24 13 23.5523 13 23V21C13 20.4477 13.4477 20 14 20C14.5523 20 15 20.4477 15 21V23C15 23.5523 14.5523 24 14 24Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M0 14C0 13.4477 0.447715 13 1 13H3C3.55228 13 4 13.4477 4 14C4 14.5523 3.55228 15 3 15H1C0.447715 15 0 14.5523 0 14Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M20 18C20 17.4477 20.4477 17 21 17H23C23.5523 17 24 17.4477 24 18C24 18.5523 23.5523 19 23 19H21C20.4477 19 20 18.5523 20 18Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M18 4C17.4477 4 17 3.55228 17 3V1C17 0.447715 17.4477 2.41411e-08 18 0C18.5523 -2.41411e-08 19 0.447715 19 1V3C19 3.55228 18.5523 4 18 4Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M18 24C17.4477 24 17 23.5523 17 23V21C17 20.4477 17.4477 20 18 20C18.5523 20 19 20.4477 19 21V23C19 23.5523 18.5523 24 18 24Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M0 18C0 17.4477 0.447715 17 1 17H3C3.55228 17 4 17.4477 4 18C4 18.5523 3.55228 19 3 19H1C0.447715 19 0 18.5523 0 18Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}