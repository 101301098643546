import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, optionalTableCell, useLocalization, useLocalizeList } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InfoCard, useEntityTypeNameTranslator } from "../../../../../../../../../common";

type AwsEc2VpcEndpointSubnetsInfoCardProps = {
    subnetIdToNetworkInterfaceId: Dictionary<string>;
};

export function AwsEc2VpcEndpointSubnetsInfoCard({ subnetIdToNetworkInterfaceId }: AwsEc2VpcEndpointSubnetsInfoCardProps) {
    const subnetModels = entityModelStore.useGet(_.keys(subnetIdToNetworkInterfaceId));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.awsEc2VpcEndpointSubnetsInfoCard",
            () => ({
                columns: {
                    cidr: "CIDR",
                    networkInterface: "Network Interface",
                    subnet: "Subnet ID"
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Subnets"
            }));

    const items =
        useMemo(
            () =>
                _(subnetModels).
                    as<Contract.AwsEc2SubnetModel>().
                    map(
                        subnetModel =>
                            new AwsEc2VpcEndpointSubnetTableItem(
                                subnetIdToNetworkInterfaceId[subnetModel.id],
                                subnetModel)).
                    value(),
            [subnetModels]);
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsEc2Subnet,
                                        { count: 0 })
                            }))
                }}
                fetchItems={() => items}
                getItemId={(item: AwsEc2VpcEndpointSubnetTableItem) => item.subnetModel.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={SubnetColumnId.Subnet}
                    render={
                        ({ item }: DataTableColumnRenderProps<AwsEc2VpcEndpointSubnetTableItem>) =>
                            <Entity
                                entityIdOrModel={item.subnetModel}
                                variant="iconTextTenant"/>}
                    title={localization.columns.subnet()}/>
                <DataTableColumn
                    id={SubnetColumnId.Cidr}
                    render={optionalTableCell<AwsEc2VpcEndpointSubnetTableItem>(item => localizeList((item.subnetModel.entity as Contract.AwsEc2Subnet).subnets))}
                    title={localization.columns.cidr()}/>
                <DataTableColumn
                    id={SubnetColumnId.NetworkInterface}
                    render={
                        ({ item }: DataTableColumnRenderProps<AwsEc2VpcEndpointSubnetTableItem>) =>
                            <Entity
                                entityIdOrModel={item.networkInterfaceId}
                                variant="iconTextTenant"/>}
                    title={localization.columns.networkInterface()}/>
            </DataTable>
        </InfoCard>);
}

class AwsEc2VpcEndpointSubnetTableItem {
    constructor(
        public networkInterfaceId: string,
        public subnetModel: Contract.AwsEc2SubnetModel) {
    }
}

enum SubnetColumnId {
    Cidr = "cidr",
    NetworkInterface = "networkInterface",
    Subnet = "subnet"
}