﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { DefinitionData } from "../../../Table";

export function useCommonKubernetesIamResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonKubernetesIamResourceDefinition",
            () => ({
                columns: {
                    principalIdReferences: "Principals",
                    roleBindingIds: "Role Bindings"
                }
            }));

    return {
        columns: {
            getPrincipalIdReferencesColumn:
                (principalProperty: Contract.EntityModelProperty) =>
                    <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.IKubernetesRoleModel | Contract.IKubernetesRoleBindingModel>(
                                Contract.TypeNames.IKubernetesPrincipal,
                                item => item.principalIdReferences,
                                localization.columns.principalIdReferences())}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                principalProperty)}
                                        placeholder={localization.columns.principalIdReferences()}/>
                            }
                        }}
                        id={principalProperty}
                        key={principalProperty}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.IKubernetesRoleModel | Contract.IKubernetesRoleBindingModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.principalIdReferences}
                                    entityTypeName={Contract.TypeNames.IKubernetesPrincipal}
                                    entityVariant="iconText"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.principalIdReferences()}/>,
            getRoleBindingIdsColumn:
                (roleBindingsProperty: Contract.EntityModelProperty) =>
                    <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.IKubernetesPrincipalModel | Contract.IKubernetesRoleModel>(
                                Contract.TypeNames.IKubernetesRoleBinding,
                                item => item.roleBindingIds,
                                localization.columns.roleBindingIds())}
                        id={roleBindingsProperty}
                        key={roleBindingsProperty}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.IKubernetesPrincipalModel | Contract.IKubernetesRoleModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.roleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesRoleBinding}
                                    entityVariant="iconText"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.roleBindingIds()}/>,
            getRoleIdReferenceColumn:
                (roleTypeName: string) => {
                    const title =
                        entityTypeNameTranslator(
                            roleTypeName,
                            {
                                includeServiceName: false
                            });
                    return (
                        <DataTableColumn
                            exportOptions={
                                entitiesExportOptions<Contract.IKubernetesRoleBindingModel>(
                                    roleTypeName,
                                    item => [item.roleIdReference],
                                    title)}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <PagedEntityFilter
                                            getEntityIdPage={
                                                ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                                    definitionData.entityTypeEntitiesViewName,
                                                    Contract.EntityModelProperty.KubernetesRoleBindingRole)}
                                            placeholder={title}/>
                                }
                            }}
                            id={Contract.EntityModelProperty.KubernetesRoleBindingRole}
                            key={Contract.EntityModelProperty.KubernetesRoleBindingRole}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.IKubernetesRoleBindingModel>) =>
                                    <EntitiesCell
                                        entityIdsOrModels={item.roleIdReference}
                                        entityTypeName={roleTypeName}
                                        entityVariant="iconText"/>}
                            title={title}/>);
                }
        }
    };
}