import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsCloudFormationStackDriftStatusTranslator, useAwsCloudFormationStackStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsCloudFormationStackDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const cloudFormationStackDriftStatusTranslator = useAwsCloudFormationStackDriftStatusTranslator();
    const cloudFormationStackStatusTranslator = useAwsCloudFormationStackStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCloudFormationStackDefinition",
            () => ({
                columns: {
                    driftStatus: "Drift Status",
                    resources: "Resources",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsCloudFormationStackModel) => ({
                                [localization.columns.status()]: cloudFormationStackStatusTranslator((item.entity as Contract.AwsCloudFormationStack).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsCloudFormationStackStatus}
                                    enumTypeTranslator={cloudFormationStackStatusTranslator}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudFormationStackStatus}
                    itemProperty={(item: Contract.AwsCloudFormationStackModel) => cloudFormationStackStatusTranslator((item.entity as Contract.AwsCloudFormationStack).status)}
                    key={Contract.EntityModelProperty.AwsCloudFormationStackStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsCloudFormationStackModel) => ({
                                [localization.columns.driftStatus()]: cloudFormationStackDriftStatusTranslator((item.entity as Contract.AwsCloudFormationStack).driftStatus)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsCloudFormationStackDriftStatus}
                                    enumTypeTranslator={cloudFormationStackDriftStatusTranslator}
                                    placeholder={localization.columns.driftStatus()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudFormationStackDriftStatus}
                    itemProperty={(item: Contract.AwsCloudFormationStackModel) => cloudFormationStackDriftStatusTranslator((item.entity as Contract.AwsCloudFormationStack).driftStatus)}
                    key={Contract.EntityModelProperty.AwsCloudFormationStackDriftStatus}
                    title={localization.columns.driftStatus()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsCloudFormationStackModel>(
                            Contract.TypeNames.AwsResource,
                            item => item.resourceIdReferences,
                            localization.columns.resources())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsCloudFormationStackResources)}
                                    placeholder={localization.columns.resources()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudFormationStackResources}
                    key={Contract.EntityModelProperty.AwsCloudFormationStackResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsCloudFormationStackModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.resourceIdReferences}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                entityVariant="iconText"/>}
                    title={localization.columns.resources()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsCloudFormationStackRequest(
                new Contract.EntityControllerGetEntityModelPageAwsCloudFormationStackRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudFormationStackDriftStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudFormationStackResources]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudFormationStackStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}