import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function PublicAccessBadgeIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <circle
                cx="6"
                cy="6"
                fill={theme.palette.background.paper}
                r="6"/>
            <path d="M5.995 1C3.235 1 1 3.24 1 6C1 8.76 3.235 11 5.995 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 5.995 1ZM9.46 4H7.985C7.825 3.375 7.595 2.775 7.295 2.22C8.215 2.535 8.98 3.175 9.46 4ZM6 2.02C6.415 2.62 6.74 3.285 6.955 4H5.045C5.26 3.285 5.585 2.62 6 2.02ZM2.13 7C2.05 6.68 2 6.345 2 6C2 5.655 2.05 5.32 2.13 5H3.82C3.78 5.33 3.75 5.66 3.75 6C3.75 6.34 3.78 6.67 3.82 7H2.13ZM2.54 8H4.015C4.175 8.625 4.405 9.225 4.705 9.78C3.785 9.465 3.02 8.83 2.54 8ZM4.015 4H2.54C3.02 3.17 3.785 2.535 4.705 2.22C4.405 2.775 4.175 3.375 4.015 4ZM6 9.98C5.585 9.38 5.26 8.715 5.045 8H6.955C6.74 8.715 6.415 9.38 6 9.98ZM7.17 7H4.83C4.785 6.67 4.75 6.34 4.75 6C4.75 5.66 4.785 5.325 4.83 5H7.17C7.215 5.325 7.25 5.66 7.25 6C7.25 6.34 7.215 6.67 7.17 7ZM7.295 9.78C7.595 9.225 7.825 8.625 7.985 8H9.46C8.98 8.825 8.215 9.465 7.295 9.78ZM8.18 7C8.22 6.67 8.25 6.34 8.25 6C8.25 5.66 8.22 5.33 8.18 5H9.87C9.95 5.32 10 5.655 10 6C10 6.345 9.95 6.68 9.87 7H8.18Z"/>
        </SvgIcon>);
}