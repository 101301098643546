import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TenantEntityParentPathCell, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData, ExportData } from "../../../../Table";

export function useGcpTenantEntityDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpTenantEntityDefinition",
            () => ({
                columns: {
                    ownerReferences: "Owners",
                    parentPath: "Path",
                    rawShortNameId: "ID",
                    rawShortNumberId: "Number"
                }
            }));
    return new EntityTableDefinition(
        [
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpTenantEntityModel) => ({
                            [localization.columns.rawShortNameId()]: (item.entity as Contract.GcpTenantEntity).rawShortNameId
                        })
                }}
                id={Contract.EntityModelProperty.GcpTenantEntityRawShortNameId}
                itemProperty={(item: Contract.GcpTenantEntityModel) => (item.entity as Contract.GcpTenantEntity).rawShortNameId}
                key={Contract.EntityModelProperty.GcpTenantEntityRawShortNameId}
                title={localization.columns.rawShortNameId()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpTenantEntityModel) => ({
                            [localization.columns.rawShortNumberId()]: (item.entity as Contract.GcpTenantEntity).rawShortNumberId
                        })
                }}
                id={Contract.EntityModelProperty.GcpTenantEntityRawShortNumberId}
                itemProperty={(item: Contract.GcpTenantEntityModel) => (item.entity as Contract.GcpTenantEntity).rawShortNumberId}
                key={Contract.EntityModelProperty.GcpTenantEntityRawShortNumberId}
                title={localization.columns.rawShortNumberId()}/>,
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpTenantEntityModel>(
                        Contract.TypeNames.IGciPrincipal,
                        item => item.ownerReferences,
                        localization.columns.ownerReferences())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpTenantEntityOwners)}
                                placeholder={localization.columns.ownerReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpTenantEntityOwners}
                key={Contract.EntityModelProperty.GcpTenantEntityOwners}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpTenantEntityModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.ownerReferences}
                            entityTypeName={Contract.TypeNames.IGciPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.ownerReferences()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.GcpTenantEntityModel) => [(item.entity as Contract.GcpTenantEntity).parentResourceId],
                    getItem:
                        (item: Contract.GcpTenantEntityModel, exportData: ExportData) => ({
                            [localization.columns.parentPath()]:
                                _.isNil((item.entity as Contract.GcpTenantEntity).parentResourceId) ||
                                _.isNil(exportData.relatedEntityModelMap[(item.entity as Contract.GcpTenantEntity).parentResourceId!]?.consoleUrl)
                                    ? item.parentEntityPath
                                    : `${item.parentEntityPath} (${exportData.relatedEntityModelMap[(item.entity as Contract.GcpTenantEntity).parentResourceId!].consoleUrl})`
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpTenantEntityParentPath)}
                                placeholder={localization.columns.parentPath()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpTenantEntityParentPath}
                key={Contract.EntityModelProperty.GcpTenantEntityParentPath}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpTenantEntityModel>) =>
                        <TenantEntityParentPathCell
                            parentEntityId={(item.entity as Contract.GcpTenantEntity).parentResourceId}
                            parentEntityPath={item.parentEntityPath}/>}
                title={localization.columns.parentPath()}/>,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpTenantEntityRequest(
                new Contract.EntityControllerGetEntityModelPageGcpTenantEntityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpTenantEntityOwners]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpTenantEntityParentPath])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}