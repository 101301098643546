import { Store } from "@infrastructure";
import { Contract, VulnerabilityController } from "..";

export const vulnerabilityModelStore =
    new Store<Contract.VulnerabilityModel, never, never>(
        vulnerabilityModel => vulnerabilityModel.vulnerability.rawId,
        {
            get:
                async rawIds => {
                    const { vulnerabilityModels } = await VulnerabilityController.getVulnerabilityModels(new Contract.VulnerabilityControllerGetVulnerabilityModelsRequest(rawIds));
                    return vulnerabilityModels;
                }
        });