﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";

export function useGciCommonDirectoryPartialPrincipalInfoItemElements(entityModel: Contract.GciEntityModel) {
    const partialPrincipal = entityModel.entity as Contract.GciDirectoryPartialPrincipal;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciCommonDirectoryPartialPrincipalInfoItemElements",
            () => ({
                info: {
                    mail: "Mail",
                    uniqueId: "Unique ID"
                }
            }));

    return {
        mailInfoItemElement:
            <InfoItem
                key="mail"
                location="all"
                title={localization.info.mail()}
                value={partialPrincipal.mail}/>,
        uniqueIdInfoItemElement:
            <InfoItem
                key="uniqueId"
                location="all"
                title={localization.info.uniqueId()}
                value={
                    _.isNil(partialPrincipal?.uniqueId)
                        ? undefined
                        : partialPrincipal.uniqueId}/>
    };
}