import { useMemo } from "react";
import { useGcpApiKeysKeyDefinition, useGcpAppEngineApplicationDefinition, useGcpAppEngineApplicationServiceDefinition, useGcpArtifactRegistryDefinition, useGcpBigQueryDatasetDefinition, useGcpBigQueryDatasetTableDefinition, useGcpCloudBuildBuildTriggerDefinition, useGcpCloudRunServiceDefinition, useGcpComputeBackendServiceDefinition, useGcpComputeDiskDefinition, useGcpComputeFirewallPolicyDefinition, useGcpComputeForwardingRuleDefinition, useGcpComputeImageDefinition, useGcpComputeInstanceDefinition, useGcpComputeInstanceGroupDefinition, useGcpComputeInstanceTemplateDefinition, useGcpComputeSnapshotDefinition, useGcpComputeSslPolicyDefinition, useGcpComputeSubnetDefinition, useGcpComputeTargetPoolDefinition, useGcpComputeTargetProxyDefinition, useGcpComputeUrlMapDefinition, useGcpComputeVpcDefinition, useGcpContainerClusterDefinition, useGcpContainerClusterNodePoolDefinition, useGcpDefaultScopeResourceDefinition, useGcpDnsManagedZoneDefinition, useGcpFunctionsFunctionDefinition, useGcpIamServiceAccountDefinition, useGcpIamWorkforcePoolDefinition, useGcpIamWorkloadIdentityPoolDefinition, useGcpKmsKeyRingDefinition, useGcpKmsKeyRingKeyDefinition, useGcpLoggingLogBucketDefinition, useGcpLoggingLogSinkDefinition, useGcpNotebooksInstanceDefinition, useGcpPubSubSubscriptionDefinition, useGcpPubSubTopicDefinition, useGcpSecretManagerSecretDefinition, useGcpSpannerInstanceDatabaseDefinition, useGcpSpannerInstanceDefinition, useGcpSqlInstanceDefinition, useGcpStorageBucketDefinition, useGcpTenantEntityDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpScopeResourceDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.GcpApiKeysKey:
                        return useGcpApiKeysKeyDefinition;
                    case Contract.TypeNames.GcpAppEngineApplication:
                        return useGcpAppEngineApplicationDefinition;
                    case Contract.TypeNames.GcpAppEngineApplicationService:
                        return useGcpAppEngineApplicationServiceDefinition;
                    case Contract.TypeNames.GcpArtifactRegistry:
                        return useGcpArtifactRegistryDefinition;
                    case Contract.TypeNames.GcpBigQueryDataset:
                        return useGcpBigQueryDatasetDefinition;
                    case Contract.TypeNames.GcpBigQueryDatasetTable:
                        return useGcpBigQueryDatasetTableDefinition;
                    case Contract.TypeNames.GcpCloudBuildBuildTrigger:
                        return useGcpCloudBuildBuildTriggerDefinition;
                    case Contract.TypeNames.GcpCloudRunService:
                        return useGcpCloudRunServiceDefinition;
                    case Contract.TypeNames.GcpComputeBackendService:
                        return useGcpComputeBackendServiceDefinition;
                    case Contract.TypeNames.GcpComputeDisk:
                        return useGcpComputeDiskDefinition;
                    case Contract.TypeNames.GcpComputeFirewallPolicy:
                        return useGcpComputeFirewallPolicyDefinition;
                    case Contract.TypeNames.GcpComputeForwardingRule:
                        return useGcpComputeForwardingRuleDefinition;
                    case Contract.TypeNames.GcpComputeImage:
                        return useGcpComputeImageDefinition;
                    case Contract.TypeNames.GcpComputeInstance:
                        return useGcpComputeInstanceDefinition;
                    case Contract.TypeNames.GcpComputeInstanceGroup:
                        return useGcpComputeInstanceGroupDefinition;
                    case Contract.TypeNames.GcpComputeInstanceTemplate:
                        return useGcpComputeInstanceTemplateDefinition;
                    case Contract.TypeNames.GcpComputeSnapshot:
                        return useGcpComputeSnapshotDefinition;
                    case Contract.TypeNames.GcpComputeSslPolicy:
                        return useGcpComputeSslPolicyDefinition;
                    case Contract.TypeNames.GcpComputeSubnet:
                        return useGcpComputeSubnetDefinition;
                    case Contract.TypeNames.GcpComputeTargetPool:
                        return useGcpComputeTargetPoolDefinition;
                    case Contract.TypeNames.GcpComputeTargetProxy:
                        return useGcpComputeTargetProxyDefinition;
                    case Contract.TypeNames.GcpComputeUrlMap:
                        return useGcpComputeUrlMapDefinition;
                    case Contract.TypeNames.GcpComputeVpc:
                        return useGcpComputeVpcDefinition;
                    case Contract.TypeNames.GcpContainerCluster:
                        return useGcpContainerClusterDefinition;
                    case Contract.TypeNames.GcpContainerClusterNodePool:
                        return useGcpContainerClusterNodePoolDefinition;
                    case Contract.TypeNames.GcpDnsManagedZone:
                        return useGcpDnsManagedZoneDefinition;
                    case Contract.TypeNames.GcpFunctionsFunction:
                        return useGcpFunctionsFunctionDefinition;
                    case Contract.TypeNames.GcpIamServiceAccount:
                        return useGcpIamServiceAccountDefinition;
                    case Contract.TypeNames.GcpIamWorkforcePool:
                        return useGcpIamWorkforcePoolDefinition;
                    case Contract.TypeNames.GcpIamWorkloadIdentityPool:
                        return useGcpIamWorkloadIdentityPoolDefinition;
                    case Contract.TypeNames.GcpKmsKeyRing:
                        return useGcpKmsKeyRingDefinition;
                    case Contract.TypeNames.GcpKmsKeyRingKey:
                        return useGcpKmsKeyRingKeyDefinition;
                    case Contract.TypeNames.GcpLoggingLogBucket:
                        return useGcpLoggingLogBucketDefinition;
                    case Contract.TypeNames.GcpLoggingLogSink:
                        return useGcpLoggingLogSinkDefinition;
                    case Contract.TypeNames.GcpNotebooksInstance:
                        return useGcpNotebooksInstanceDefinition;
                    case Contract.TypeNames.GcpPubSubSubscription:
                        return useGcpPubSubSubscriptionDefinition;
                    case Contract.TypeNames.GcpPubSubTopic:
                        return useGcpPubSubTopicDefinition;
                    case Contract.TypeNames.GcpSecretManagerSecret:
                        return useGcpSecretManagerSecretDefinition;
                    case Contract.TypeNames.GcpSqlInstance:
                        return useGcpSqlInstanceDefinition;
                    case Contract.TypeNames.GcpStorageBucket:
                        return useGcpStorageBucketDefinition;
                    case Contract.TypeNames.GcpSpannerInstance:
                        return useGcpSpannerInstanceDefinition;
                    case Contract.TypeNames.GcpSpannerInstanceDatabase:
                        return useGcpSpannerInstanceDatabaseDefinition;
                    case Contract.TypeNames.GcpTenantEntity:
                        return useGcpTenantEntityDefinition;
                    default:
                        return useGcpDefaultScopeResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}