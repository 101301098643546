﻿import { DataTableColumn, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useOciCommonIamCredentialDefinition } from "./useOciCommonIamCredentialDefinition";

export function useOciIamUserSecretKeyDefinition(definitionData: DefinitionData) {
    const commonIamCredentialDefinition = useOciCommonIamCredentialDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciIamUserSecretKeyDefinition",
            () => ({
                columns: {
                    accessKey: "Access Key"
                }
            }));

    return new EntityTableDefinition(
        [
            commonIamCredentialDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciIamUserSecretKeyModel) => ({
                            [localization.columns.accessKey()]: (item.entity as Contract.OciIamUserSecretKey).accessKey
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciIamUserSecretKeyAccessKey)}
                                placeholder={localization.columns.accessKey()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciIamUserSecretKeyAccessKey}
                key={Contract.EntityModelProperty.OciIamUserSecretKeyAccessKey}
                render={optionalTableCell<Contract.OciIamUserSecretKeyModel>(item => (item.entity as Contract.OciIamUserSecretKey).accessKey)}
                title={localization.columns.accessKey()}/>,
            commonIamCredentialDefinition.columns.creationTimeColumn,
            commonIamCredentialDefinition.columns.creatorCsvColumn,
            commonIamCredentialDefinition.columns.userColumn,
            commonIamCredentialDefinition.columns.attributesColumn,
            commonIamCredentialDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonIamCredentialDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonIamCredentialDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciIamUserSecretKeyRequest(
                new Contract.EntityControllerGetEntityModelPageOciIamUserSecretKeyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    undefined,
                    undefined,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamCredentialUser]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamUserSecretKeyAccessKey])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}