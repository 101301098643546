import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { CopyToClipboardActionButton } from ".";

export type InlineCopyToClipboardTextProps = {
    text: string;
};

export function InlineCopyToClipboardText({ text }: InlineCopyToClipboardTextProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                alignItems: "center",
                borderRadius: theme.spacing(0.75),
                display: "inline-flex",
                maxHeight: theme.spacing(2),
                padding: theme.spacing(0, 0, 0, 0.5)
            }}>
            <Typography
                noWrap={true}
                sx={{
                    fontWeight: 600,
                    maxWidth: theme.spacing(40)
                }}>
                {text}
            </Typography>
            <CopyToClipboardActionButton getValue={() => text}/>
        </Stack>);
}