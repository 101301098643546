import { Action0, ActionMenuItem, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import { ConfigurationController, Contract, riskPolicyModelStore, scopeSystemEntityModelStore, useTheme } from "../../../../../../../common";

type ActionsCellProps = {
    automationRuleConfiguration: Contract.AutomationRuleConfiguration;
    onEdit: Action0;
};

export function ActionsCell({ automationRuleConfiguration, onEdit }: ActionsCellProps) {
    const [deleteRuleExecuting, setDeleteRuleExecuting] = useState(false);
    const [deleteRuleError, setDeleteRuleError] = useState(false);

    async function deleteRule() {
        setDeleteRuleExecuting(true);
        setDeleteRuleError(false);

        try {
            await ConfigurationController.deleteAutomationRule(new Contract.ConfigurationControllerDeleteAutomationRuleRequest(automationRuleConfiguration.id));
            await scopeSystemEntityModelStore.notifyDeleted(automationRuleConfiguration.id);
            await riskPolicyModelStore.notify();
        } catch {
            setDeleteRuleError(true);
        }

        setDeleteRuleExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.automations.actionsCell",
            () => ({
                actions: {
                    delete: {
                        confirm: "Are you sure you want to remove {{name}}?",
                        error: "Failed to delete {{name}}",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteRuleExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteRuleError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error({ name: automationRuleConfiguration.name })}/>)}
            <Menu
                itemsOrGetItems={[
                    new ActionMenuItem(
                        () => onEdit(),
                        localization.actions.edit(),
                        {
                            icon: <EditIcon/>
                        }),
                    new ActionMenuItem(
                        () => deleteRule(),
                        localization.actions.delete.title(),
                        {
                            confirmOptions: {
                                message: localization.actions.delete.confirm({ name: automationRuleConfiguration.name })
                            },
                            icon: <DeleteIcon/>
                        })
                ]}/>
        </Stack>);
}