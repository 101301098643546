import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DoiTIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2092)">
                <path
                    d="M11.5313 3.04689H12.4219L12.9375 3.23439L13.3594 3.56251L13.6875 3.93751L13.875 4.31251L13.9688 4.73439V7.92189H17.5781L18.2344 8.06251L18.75 8.39064L19.0781 8.76564L19.3594 9.32814L19.4063 9.51564V10.3125L19.2188 10.8281L18.8438 11.3438L18.4219 11.6719L17.9063 11.8594L17.5781 11.9063H13.9688L14.0156 15.1875L14.1563 15.8438L14.5313 16.5938L15.0469 17.1563L15.6563 17.5313L16.1719 17.7188L16.6406 17.8125H17.25L18 17.625L18.5625 17.3438L19.0781 16.9219L19.4531 16.4063L19.6875 15.9375L19.8281 15.4688L19.9219 14.4844L20.1563 13.875L20.4844 13.4531L20.8125 13.1719L21.3281 12.9375L21.5625 12.8906H22.2188L22.8281 13.0781L23.2031 13.3594L23.4375 13.5938L23.7656 14.0156L24 14.5313V15.9844L23.6719 17.1094L23.3906 17.8125L23.0625 18.4688L22.6875 19.0313L22.1719 19.6406L21.9844 19.875H21.8906V19.9688L21.375 20.3906L20.8594 20.7656L20.2031 21.1406L19.4531 21.4688L18.7031 21.7031L17.9531 21.8438L17.5313 21.8906H16.4531L15.5156 21.75L14.5781 21.4688L13.7344 21.0938L13.0313 20.6719L12.375 20.1563L12.0938 19.875L11.9531 19.8281L11.8594 20.0156L11.25 20.5313L10.5938 20.9531L9.84376 21.3281L9.18751 21.5625L8.48439 21.75L7.54689 21.8906H6.46876L5.53126 21.75L4.59376 21.4688L3.75001 21.0938L3.00001 20.625L2.48439 20.2031L2.01564 19.7813L1.59376 19.3125L1.07814 18.5625L0.750013 18L0.468763 17.2969L0.281263 16.7344L0.0937634 15.75L0.0468884 9.93751L0.0937634 9.42189L0.281263 9.00001L0.562513 8.57814L0.984388 8.20314L1.35939 8.01564L1.78126 7.92189H2.29689L2.85939 8.06251L3.37501 8.39064L3.75001 8.81251L3.98439 9.32814L4.03126 9.51564L4.07814 10.6875V14.1094L4.12501 15.4688L4.35939 16.125L4.68751 16.6875L5.20314 17.2031L5.85939 17.5781L6.42189 17.7656L6.75001 17.8125H7.31251L8.06251 17.625L8.62501 17.3438L9.04689 17.0156L9.42189 16.5938L9.70314 16.125L9.89064 15.5156L9.93751 15.2344L9.98439 4.64064L10.1719 4.12501L10.5 3.65626L10.875 3.32814L11.3438 3.09376L11.5313 3.04689Z"
                    fill="#F63465"/>
                <path
                    d="M1.82813 3L2.48438 3.04688L2.85938 3.1875L3.375 3.51562L3.75 4.03125L3.9375 4.45312L3.98438 4.64062V5.4375L3.79688 5.95312L3.51563 6.375L3.09375 6.75L2.67188 6.9375L2.25 7.03125H1.64063L1.03125 6.84375L0.5625 6.51562L0.234375 6.04688L0.046875 5.625L0 5.39062V4.73438L0.1875 4.125L0.46875 3.70312L0.796875 3.375L1.35938 3.09375L1.82813 3Z"
                    fill="#F63465"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2092">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}