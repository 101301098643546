import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingLocalPeeringGatewayStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingLocalPeeringGatewayStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingLocalPeeringGatewayStatus]: {
                    [Contract.OciNetworkingLocalPeeringGatewayStatus.Available]: "Available",
                    [Contract.OciNetworkingLocalPeeringGatewayStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingLocalPeeringGatewayStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingLocalPeeringGatewayStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciNetworkingLocalPeeringGatewayStatus) =>
        localization[Contract.TypeNames.OciNetworkingLocalPeeringGatewayStatus][status]();
}