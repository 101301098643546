import React, { useMemo } from "react";
import { KubernetesAdmissionControllerRiskPolicyItem, RiskPoliciesType } from "../../../../../../../../../../../common";
import { useRiskPoliciesItemConfiguration } from "../../../../../../../hooks";
import { RiskPolicyConfigurationDefinition } from "../../../useDefinition";
import { useCommonRiskPolicyDefinition } from "../../useCommonRiskPolicyDefinition";
import { Effect, Warning } from "./components";

export function useKubernetesAdmissionControllerRiskPolicyBuiltInDefinition(item: KubernetesAdmissionControllerRiskPolicyItem, scopeId: string): RiskPolicyConfigurationDefinition {
    const { riskPolicyConfiguration } = useRiskPoliciesItemConfiguration(RiskPoliciesType.KubernetesAdmissionController, item, scopeId);
    const additionalTabs = useCommonRiskPolicyDefinition(riskPolicyConfiguration, RiskPoliciesType.KubernetesAdmissionController);

    return useMemo(
        () => ({
            configurationCategoryTabToViewItemMap: additionalTabs,
            generalAdditionalItems: [
                <Effect key="effect"/>,
                <Warning key="warning"/>
            ]
        }),
        [additionalTabs, riskPolicyConfiguration]);
}