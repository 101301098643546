import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEc2SnapshotRiskContext, useGetAwsEc2VolumeRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useAwsEc2SnapshotPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2SnapshotPublicAccessExistsRisk;
    const snapshotModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2SnapshotModel;
    const snapshot = snapshotModel.entity as Contract.AwsEc2Snapshot;
    const sourceVolumeModel = entityModelStore.useGet(snapshot.sourceVolumeId) as Optional<Contract.AwsEc2VolumeModel>;

    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

    const getAwsEc2SnapshotRiskContext = useGetAwsEc2SnapshotRiskContext();
    const getAwsEc2VolumeRiskContext = useGetAwsEc2VolumeRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotPublicAccessExistsRiskDefinition",
            () => ({
                description: "EBS snapshot {{publicAccessSnapshot}} is publicly accessible via snapshot permissions",
                sections: {
                    resolution: {
                        step1: "Disabling public access might break identities using the snapshot. Before changing the snapshots permissions, verify that identities using the snapshot can access it without public access",
                        step2: "Change the access to **Private** and optionally add explicit AWS account numbers to share the snapshot with",
                        step3: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                publicAccessSnapshot:
                    <Entity
                        entityIdOrModel={snapshotModel}
                        variant="text"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                snapshotModel.unknown
                    ? AwsConsoleUrlBuilder.getEc2Url(tenantConfiguration.partitionType)
                    : AwsConsoleUrlBuilder.getEc2SnapshotModifyPermissionsUrl(snapshot)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const snapshotRiskContext = getAwsEc2SnapshotRiskContext(snapshotModel);

            return _<Optional<RiskDefinitionContextItem>>([]).
                concat(
                    snapshotRiskContext.generalInformation,
                    snapshotRiskContext.sensitive,
                    snapshotRiskContext.snapshotAssociation,
                    snapshotRiskContext.sourceVolumeId).
                concatIf(
                    !_.isNil(sourceVolumeModel),
                    () => {
                        const volumeRiskContext = getAwsEc2VolumeRiskContext(sourceVolumeModel!);
                        return [
                            volumeRiskContext.sensitive
                        ];
                    }).
                concat(snapshotRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)).
                value();
        });
}