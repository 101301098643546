import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionContextItemImpact, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetVirtualMachinesWorkloadAnalysisRiskContext, useGetVirtualMachineWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetVirtualMachineOperatingSystemResolutionSteps } from "./useGetVirtualMachineOperatingSystemResolutionSteps";

export function useVirtualMachineOperatingSystemEndOfLifeRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const virtualMachineOperatingSystemEndOfLifeRisk = riskModel.risk as Contract.VirtualMachineOperatingSystemEndOfLifeRisk;
    const virtualMachineModels = _.as<Contract.IVirtualMachineModel[]>(entityModelStore.useGet(virtualMachineOperatingSystemEndOfLifeRisk.virtualMachineIds));

    const operatingSystemReleaseNotesUrl =
        _(virtualMachineModels).
            map(virtualMachineModel => virtualMachineModel.operatingSystemReleaseNotesUrl).
            filter().
            first();

    const getVirtualMachineOperatingSystemResolutionSteps =
        useGetVirtualMachineOperatingSystemResolutionSteps(
            virtualMachineOperatingSystemEndOfLifeRisk,
            operatingSystemReleaseNotesUrl);

    const operatingSystemExtendedSupport =
        _(virtualMachineModels).
            map(virtualMachineModel => virtualMachineModel.workloadAnalysisOperatingSystemExtendedSupport).
            filter().
            first();

    const {
        operatingSystemDisplayName,
        operatingSystemEndOfLifeDate
    } = virtualMachineOperatingSystemEndOfLifeRisk;

    const getVirtualMachineImageWorkloadAnalysisRiskContext = useGetVirtualMachinesWorkloadAnalysisRiskContext(virtualMachineOperatingSystemEndOfLifeRisk);
    const getVirtualMachineWorkloadAnalysisRiskContext = useGetVirtualMachineWorkloadAnalysisRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useVirtualMachineOperatingSystemEndOfLifeRiskDefinition",
            () => ({
                description: {
                    extendedSupport: {
                        many: "{{virtualMachines}} in {{riskedEntity}} are running operating system {{operatingSystem}}, which is End-of-Life as of {{ operatingSystemEndOfLifeDate | TimeFormatter.shortDate}} (based on extended support date)",
                        single: "{{riskedEntity}} is running operating system {{operatingSystem}}, which is End-of-Life as of {{ operatingSystemEndOfLifeDate | TimeFormatter.shortDate}} (based on extended support date)"
                    },
                    nonExtendedSupport: {
                        many: "{{virtualMachines}} in {{riskedEntity}} are running operating system {{operatingSystem}}, which is End-of-Life as of {{ operatingSystemEndOfLifeDate | TimeFormatter.shortDate}} (based on end of security updates support date)",
                        single: "{{riskedEntity}} is running operating system {{operatingSystem}}, which is End-of-Life as of {{ operatingSystemEndOfLifeDate | TimeFormatter.shortDate}} (based on end of security updates support date)"
                    }
                },
                sections: {
                    vulnerabilities: "Vulnerabilities"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (operatingSystemExtendedSupport
            ? virtualMachineOperatingSystemEndOfLifeRisk.singleVirtualMachine
                ? localization.description.extendedSupport.single
                : localization.description.extendedSupport.many
            : virtualMachineOperatingSystemEndOfLifeRisk.singleVirtualMachine
                ? localization.description.nonExtendedSupport.single
                : localization.description.nonExtendedSupport.many)({
            operatingSystem: operatingSystemDisplayName,
            operatingSystemEndOfLifeDate,
            riskedEntity:
                <Entity
                    entityIdOrModel={virtualMachineOperatingSystemEndOfLifeRisk.entityId}
                    variant="text"/>,
            virtualMachines:
                <InlineEntities
                    entityIdsOrModels={virtualMachineOperatingSystemEndOfLifeRisk.virtualMachineIds}
                    entityTypeName={Contract.TypeNames.IVirtualMachine}
                    variant="itemOrItemCountAndType"/>
        }),
        getVirtualMachineOperatingSystemResolutionSteps,
        riskModel,
        () => {
            const virtualMachineWorkloadAnalysisRiskContext = getVirtualMachineWorkloadAnalysisRiskContext(virtualMachineOperatingSystemEndOfLifeRisk);
            const virtualMachineImageWorkloadAnalysisRiskContext = getVirtualMachineImageWorkloadAnalysisRiskContext();
            return [
                virtualMachineWorkloadAnalysisRiskContext.generalInformation,
                virtualMachineWorkloadAnalysisRiskContext.virtualMachines,
                virtualMachineWorkloadAnalysisRiskContext.operatingSystem,
                ...virtualMachineOperatingSystemEndOfLifeRisk.packageScope === Contract.WorkloadResourceOperatingSystemRiskPackageScope.All
                    ? []
                    : [
                        virtualMachineWorkloadAnalysisRiskContext.getVulnerabilities(
                            true,
                            true),
                        virtualMachineWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.Critical),
                        virtualMachineWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.High)
                    ],
                virtualMachineImageWorkloadAnalysisRiskContext.sensitive?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachineImageWorkloadAnalysisRiskContext.inboundExternalAccessScope?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachineImageWorkloadAnalysisRiskContext.identityPermissionActionSeverity?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachineImageWorkloadAnalysisRiskContext.stopped?.withImpact(RiskDefinitionContextItemImpact.SeverityDecreased),
                virtualMachineWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(virtualMachineOperatingSystemEndOfLifeRisk.id)
            ];
        },

        {
            sections:
                [
                    new RiskDefinitionSection(
                        <Vulnerabilities
                            entityIds={virtualMachineOperatingSystemEndOfLifeRisk.virtualMachineIds}
                            severityFilterValues={[Contract.Severity.Critical, Contract.Severity.High, Contract.Severity.Medium]}
                            variant="risk"/>,
                        localization.sections.vulnerabilities(),
                        {
                            expandable: true
                        })
                ]
        });
}