import { Action0, Action1, formatQueryParameters, getChildElements, makeContextProvider, Optional, StorageItem, TimeHelper, useChangeEffect } from "@infrastructure";
import { Box, Grid2, SxProps } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Children, ReactNode, Ref, useEffect, useMemo } from "react";
import { useActions, useQueryParameters, useSetQueryParameters } from "../../hooks";
import { AddFilter, Filter, FilterConnector } from "./components";

type FiltersProps = {
    actionsRef?: Ref<Optional<FiltersActions>>;
    children: ReactNode;
    filterQueryParameterName?: string;
    initialFilterMap?: Dictionary<any>;
    onFilterChanged: Action1<Dictionary<any>>;
    onInitialized?: Action0;
    onVisibleFiltersChanged?: Action1<string[]>;
    sx?: SxProps;
    visibilityStorageItem?: StorageItem;
};

export type FilterOption = {
    id: string;
    title?: string;
};

export type FiltersContext = {
    activeFilter?: string;
    filterMap: Dictionary<any>;
    filterOptions?: FilterOption[];
    time: string;
    visibleFilters: string[];
};

export type FiltersActions = {
    add: (id: string) => void;
    getTime: () => string;
    remove: (id: string) => void;
    set: (id: string, updateFilter: (existingFilter: any) => any) => void;
};

export const [useFiltersContext, useSetFiltersContext, useFiltersContextProvider] = makeContextProvider<FiltersContext>();

export function Filters({ actionsRef, children, filterQueryParameterName, initialFilterMap = {}, onFilterChanged, onInitialized, onVisibleFiltersChanged, sx, visibilityStorageItem }: FiltersProps) {
    const queryParameters = useQueryParameters<Dictionary<Optional<string>>>();
    const setQueryParameters = useSetQueryParameters();
    const filterElements =
        useMemo(
            () => getChildElements(Children.toArray(children), Filter),
            [children]);
    const filterOptions =
        useMemo(
            () =>
                _.map(
                    filterElements,
                    ({ props: { id, title } }) => ({ id, title })),
            [filterElements]);
    const queryFilterMap =
        useMemo(
            () =>
                filterQueryParameterName && queryParameters?.[filterQueryParameterName]
                    ? normalizeFilterMap(JSON.parse(queryParameters[filterQueryParameterName]!))
                    : {},
            [filterQueryParameterName, queryParameters]);
    const [context, setContext, ContextProvider] =
        useFiltersContextProvider(
            () => {
                let filterMap = initialFilterMap;
                if (!_.isEmpty(queryFilterMap)) {
                    filterMap = queryFilterMap;
                } else if (!_.isEmpty(initialFilterMap) && filterQueryParameterName) {
                    setQueryParameters<Dictionary<Optional<string>>>(
                        {
                            [filterQueryParameterName]: JSON.stringify(filterMap)
                        },
                        {
                            appendBrowserHistory: false,
                            ignoreUrlMaxLength: true
                        });
                }

                const cachedActiveFilters = visibilityStorageItem?.getValue();
                const visibleFilters =
                    _(filterMap).
                        keys().
                        concatIf(
                            !_.isNil(cachedActiveFilters),
                            () => JSON.parse(cachedActiveFilters!) as string[]).
                        concat(
                            _(filterElements).
                                filter(filter => !!filter.props.default).
                                map(filter => filter.props.id).
                                value()).
                        uniq().
                        value();
                const sortedVisibleFilters =
                    _(filterElements).
                        map(filter => filter.props.id).
                        intersection(visibleFilters).
                        value();
                return {
                    filterMap,
                    filterOptions,
                    time:
                        TimeHelper.
                            utcNow().
                            toISOString(),
                    visibleFilters: sortedVisibleFilters
                };
            },
            []);

    const { activeFilter, filterMap, visibleFilters } = context;

    useEffect(
        () => {
            if (!_.isEqual(filterMap, initialFilterMap)) {
                onFilterChanged(filterMap);
            }

            onInitialized?.();
        },
        []);

    useChangeEffect(
        () => {
            setContext(
                context => {
                    if (!_.isEqualByProperties(context.filterMap, queryFilterMap)) {
                        return {
                            ...context,
                            filterMap: queryFilterMap
                        };
                    }

                    return context;
                });
        },
        [queryFilterMap]);

    useChangeEffect(
        () => {
            if (!_.isNil(filterQueryParameterName)) {
                const filterMapQueryParams = formatQueryParameters(filterMap);
                setQueryParameters<Dictionary<Optional<string>>>(
                    {
                        [filterQueryParameterName]:
                            _.isEmpty(filterMapQueryParams)
                                ? undefined
                                : JSON.stringify(filterMap)
                    },
                    { ignoreUrlMaxLength: true });
            }

            onFilterChanged(filterMap);
            setContext(
                context =>
                    ({
                        ...context,
                        time:
                        TimeHelper.
                            utcNow().
                            toISOString()
                    }));
        },
        [filterMap],
        500);

    useEffect(
        () => onVisibleFiltersChanged?.(visibleFilters),
        [visibleFilters]);

    useEffect(
        () => {
            visibilityStorageItem?.setValue(JSON.stringify(visibleFilters));
        },
        [visibilityStorageItem, visibleFilters]);

    useActions(
        actionsRef,
        {
            add(id: string) {
                setContext(
                    context => ({
                        ...context,
                        activeFilter: id,
                        visibleFilters: _.uniq([...context.visibleFilters, id])
                    }));
            },
            getTime() {
                return context.time;
            },
            remove(id: string) {
                setContext(
                    context => ({
                        ...context,
                        activeFilter: undefined,
                        filterMap:
                            _.has(context.filterMap, id)
                                ? _.omit(context.filterMap, id)
                                : context.filterMap,
                        visibleFilters: _.without(context.visibleFilters, id)
                    }));
            },
            set(id: string, updateFilter: (existingFilter: any) => any) {
                setContext(
                    context =>
                        ({
                            ...context,
                            filterMap: normalizeFilterMap({
                                ...filterMap,
                                [id]: updateFilter(filterMap[id])
                            }),
                            visibleFilters: _.uniq([...context.visibleFilters, id])
                        }));
            }
        });

    useEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    filterOptions
                }));
        },
        [filterOptions]);

    const activeFilterElements =
        useMemo(
            () =>
                _(visibleFilters).
                    map(filter => filterElements.find(f => f.props.id === filter)!).
                    filter(filter => !_.isUndefined(filter)).
                    value(),
            [filterElements, visibleFilters]);
    return (
        <ContextProvider>
            <Box sx={sx}>
                <Grid2
                    alignItems="center"
                    container={true}
                    spacing={1}>
                    {activeFilterElements.map(
                        filterElement =>
                            <Grid2 key={filterElement.props.id}>
                                <FilterConnector
                                    filter={filterMap[filterElement.props.id]}
                                    id={filterElement.props.id}
                                    open={activeFilter === filterElement.props.id}
                                    showByDefault={filterElement.props.default}>
                                    {filterElement.props.children}
                                </FilterConnector>
                            </Grid2>)}
                    <AddFilter/>
                </Grid2>
            </Box>
        </ContextProvider>);
}

export function normalizeFilterMap(filterMap: Dictionary<any>) {
    const normalizedFilterMap: Dictionary<any> = {};
    _.each(
        filterMap,
        (filter, id) => {
            if (!_.isNil(filter)) {
                normalizedFilterMap[id] = filter;
            }
        });
    return normalizedFilterMap;
}

export function formatFilterQueryParameters(
    queryParameterName: string,
    filterMap?: Dictionary<any>) {
    return _.some(
        filterMap,
        filter => !_.isNil(filter))
        ? formatQueryParameters<Dictionary<Optional<string>>>({
            [queryParameterName]: JSON.stringify(filterMap)
        })
        : undefined;
}