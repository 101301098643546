﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsCloudFrontDistributionStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsCloudFrontDistributionRiskContext() {
    return useMemo(
        () => useAwsCloudFrontDistributionRiskContext,
        []);
}

function useAwsCloudFrontDistributionRiskContext(distributionModel: Contract.AwsCloudFrontDistributionModel) {
    const distribution = distributionModel.entity as Contract.AwsCloudFrontDistribution;
    const resourceRiskContext = useGetAwsResourceRiskContext()(distributionModel);
    const originModels =
        entityModelStore.useGet(
            _(distributionModel.originIdToResourceIdReferenceMap).
                values().
                uniq().
                value());
    const sensitiveOriginModels =
        _.filter(
            originModels,
            originModel => originModel.entityConfiguration?.sensitive === true);

    const cloudFrontDistributionStatusTranslator = useAwsCloudFrontDistributionStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsCloudFrontDistributionRiskContext",
            () => ({
                originResources: {
                    pluralizer: [
                        "1 Origin Resource",
                        "{{count | NumberFormatter.humanize}} origins resources"],
                    title: "The {{translatedDistributionTypeName}} gets its content from **{{originResources}}**"
                },
                sensitiveOrigin: [
                    "The {{translatedDistributionTypeName}} is redirecting requests to {{sensitiveOriginModels}}, which marked as sensitive",
                    "The {{translatedDistributionTypeName}} is redirecting requests to {{sensitiveOriginModels}} that are marked as sensitive"
                ],
                status: "The {{translatedDistributionTypeName}} status is {{status}}"
            }));

    const translatedDistributionTypeName =
        entityTypeNameTranslator(
            distribution.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        originResources:
            _.isEmpty(originModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.originResources.title({
                        originResources:
                            <InlineEntities
                                entityIdsOrModels={originModels}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                namePluralizer={localization.originResources.pluralizer}
                                variant="itemAndTypeOrItemCountAndType"/>,
                        translatedDistributionTypeName
                    })),
        sensitiveOrigin:
            _.isEmpty(sensitiveOriginModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sensitiveOrigin(
                        sensitiveOriginModels.length,
                        {
                            sensitiveOriginModels:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveOriginModels}
                                    entityTypeName={Contract.TypeNames.AwsResource}
                                    namePluralizer={localization.originResources.pluralizer}
                                    variant="itemAndTypeOrItemCountAndType"/>,
                            translatedDistributionTypeName
                        })),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: cloudFrontDistributionStatusTranslator(distribution.status),
                    translatedDistributionTypeName
                }))
    };
}