﻿import { ApiError, Link, useInputValidation, useLocalization, useSetWizardContext, useWizardContext } from "@infrastructure";
import { FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CodeTenantLink, Contract, RiskController, useRiskPolicyTranslator, useTheme } from "../../../../../../../../../../../../../../common";
import { CodeBranchIcon, CodeConsoleUrlHelper } from "../../../../../../../../../../../../../../tenants";
import { useSetCodeResolutionContext } from "../CodeResolutionContext";

export type PullRequestItemProps = {
    codeTenantModel: Contract.CodeTenantModel;
    entityDisplayName: string;
    riskId: string;
    riskPolicyConfigurationTypeName: string;
};

export function PullRequestItem({ codeTenantModel, entityDisplayName, riskId, riskPolicyConfigurationTypeName }: PullRequestItemProps) {
    const { setValid, useNextEffect } = useWizardContext();
    const setWizardContext = useSetWizardContext();
    const setCodeResolutionContext = useSetCodeResolutionContext();
    const riskPolicyTranslator = useRiskPolicyTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.profile.core.resolutionCategory.resolveCode.codeResolution.pullRequestItem",
            () => ({
                actions: {
                    create: {
                        error: {
                            general: "Failed to create pull request",
                            [Contract.TypeNames.RiskControllerResolveRiskCodeAutomationError]: {
                                [Contract.RiskControllerResolveRiskCodeAutomationError.PullRequestMaxSizeExceeded]: "Pull request too large",
                                [Contract.RiskControllerResolveRiskCodeAutomationError.PullRequestMissingPermissions]: "Missing pull request permissions",
                                [Contract.RiskControllerResolveRiskCodeAutomationError.PullRequestSourceCommitNotFound]: "Commit not found"
                            }
                        },
                        title: "Create"
                    }
                },
                codeRepositoryPath: "{{codeOrganization}} / {{codeRepository}}",
                fields: {
                    commitMessage: {
                        error: {
                            empty: "Commit message shouldn't be empty",
                            length: "Commit message should be up to 70 characters"
                        },
                        placeholder: "Tenable | {{riskPolicyTitle}} | {{entityDisplayName}}",
                        title: "Commit Message"
                    },
                    description: "Description",
                    destination: "Destination"
                },
                title: "Enter the pull request details"
            }));

    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    nextTitle: localization.actions.create.title()
                }));
        },
        []);

    const [commitMessage, setCommitMessage] = useState("");
    const [description, setDescription] = useState("");

    const commitMessageMaxLength = 70;
    const [commitMessageValidationController, commitMessageValidationMessage] =
        useInputValidation(
            () => {
                const validationCommitMessage = commitMessage.trim();
                if (_.isEmpty(validationCommitMessage)) {
                    return localization.fields.commitMessage.error.empty();
                }

                if (validationCommitMessage.length > commitMessageMaxLength) {
                    return localization.fields.commitMessage.error.length();
                }
            },
            [commitMessage]);


    useEffect(
        () => {
            setCommitMessage(
                _.truncate(
                    localization.fields.commitMessage.placeholder(
                        {
                            entityDisplayName,
                            riskPolicyTitle: riskPolicyTranslator(riskPolicyConfigurationTypeName).title
                        }),
                    { length: commitMessageMaxLength }));
            setDescription(
                riskPolicyTranslator(
                    riskPolicyConfigurationTypeName,
                    "text").
                    description!);
        },
        [localization]);

    useNextEffect(
        async () => {
            try {
                const { pullRequestUrl } =
                    await RiskController.resolveRiskCodeAutomation(
                        new Contract.RiskControllerResolveRiskCodeAutomationRequest(
                            commitMessage,
                            description,
                            riskId));

                setCodeResolutionContext(
                    codeResolutionContext => ({
                        ...codeResolutionContext,
                        pullRequestUrl
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? localization.actions.create.error[Contract.TypeNames.RiskControllerResolveRiskCodeAutomationError][error.error as Contract.RiskControllerResolveRiskCodeAutomationError]()
                    : localization.actions.create.error.general();
            }
        },
        [commitMessage, description]);

    useEffect(
        () => {
            setValid(commitMessageValidationController.isValid());
        },
        [commitMessage]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Typography variant="h3">
                {localization.title()}
            </Typography>
            <TextField
                label={localization.fields.commitMessage.title()}
                value={commitMessage}
                variant="outlined"
                onChange={event => setCommitMessage(event.target.value)}/>
            {!_.isNil(commitMessageValidationMessage) && (
                <FormHelperText error={true}>{commitMessageValidationMessage}</FormHelperText>)}
            <TextField
                label={localization.fields.description()}
                multiline={true}
                value={description}
                variant="outlined"
                onChange={event => setDescription(event.target.value)}/>
            <Stack spacing={2}>
                <Typography variant="h3">
                    {localization.fields.destination()}
                </Typography>
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                        flex: 1,
                        overflow: "hidden",
                        paddingLeft: theme.spacing(1)
                    }}>
                    <CodeBranchIcon/>
                    <Typography
                        sx={{ paddingLeft: theme.spacing(1) }}
                        variant="h5">
                        {localization.codeRepositoryPath({ codeOrganization:
                                <Link
                                    urlOrGetUrl={
                                        CodeConsoleUrlHelper.getCodeOrganizationUrl(
                                            codeTenantModel.configuration.codeTenantType,
                                            codeTenantModel.organizationName,
                                            codeTenantModel.serverEndpoint)}
                                    variant="external">
                                    {codeTenantModel.organizationName}
                                </Link>,
                        codeRepository: <CodeTenantLink tenantConfiguration={codeTenantModel.configuration}/> })}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>);
}