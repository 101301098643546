import { CheckboxField, ItemSelector, Link, StringHelper, useExecuteOperation, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, RadioField, ScopeHelper, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function ServerInfoItem() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const serverModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetQRadar());

    const setAddOrEditContext = useSetAddOrEditContext();
    const { serverEndpointConnectorId, serverHostName, serverModel, serverName, serverPort, serverServerCertificateValidationEnabled } = useAddOrEditContext();

    const [endpointConnectorAvailableTunnelSessionClientIds] =
        useExecuteOperation(
            ServerInfoItem,
            async () => {
                const { endpointConnectorTunnelSessionClientIds } = await ConfigurationController.getEndpointConnectorTunnelSessionClientIds();
                const { endpointConnectorIdToIntegrationNameMap } = await ConfigurationController.getEndpointConnectorIdToIntegrationNameMap();
                return _.difference(
                    endpointConnectorTunnelSessionClientIds,
                    _.keys(endpointConnectorIdToIntegrationNameMap));
            });
    const endpointConnectorModels = scopeSystemEntityModelStore.useGetEndpointConnector();
    const endpointConnectorConfigurations =
        useMemo(
            () =>
                _(endpointConnectorModels).
                    filter(
                        endpointConnectorModel =>
                            endpointConnectorModel.configuration.id == serverEndpointConnectorId ||
                            endpointConnectorAvailableTunnelSessionClientIds.includes(endpointConnectorModel.configuration.id)).
                    map(endpointConnectorModel => (endpointConnectorModel.configuration as Contract.EndpointConnectorConfiguration)).
                    value(),
            [endpointConnectorModels]);
    const updatedEndpointConnectorConfiguration =
        useMemo(
            () =>
                _.find(
                    endpointConnectorConfigurations,
                    endpointConnectorConfiguration => endpointConnectorConfiguration.id === serverEndpointConnectorId)
            , [endpointConnectorConfigurations, serverEndpointConnectorId]);

    const [endpointConnectorEnabled, setEndpointConnectorEnabled] = useState(!_.isNil(serverEndpointConnectorId));
    const [hostName, setHostName] = useState(serverHostName ?? "");
    const [name, setName] = useState(serverName);
    const [port, setPort] = useState(serverPort);
    const [selectedEndpointConnectorConfiguration, setSelectedEndpointConnectorConfiguration] = useState(updatedEndpointConnectorConfiguration ?? _.first(endpointConnectorConfigurations));
    const [serverCertificateValidationEnabled, setServerCertificateValidationEnabled] = useState(serverServerCertificateValidationEnabled);
    const setOrderedWizardContext = useSetOrderedWizardContext();

    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: undefined
                }));
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.qRadar.addOrEdit.serverInfoItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add",
                            edit: "Failed to save"
                        }
                    }
                },
                fields: {
                    endpoint: {
                        errorExists: "Server endpoint already exists",
                        hostName: {
                            errorRequired: "Server hostname cannot be empty",
                            title: "Hostname or IP address"
                        },
                        port: {
                            errorRequired: "Server port cannot be empty",
                            title: "QRadar TLS port"
                        }
                    },
                    endpointConnectorUsed: {
                        description: {
                            link: "click here",
                            title: "Select how Tenable connects to your server. If your server is network-isolated, use a Tenable Endpoint Connector to establish connection. If the connector option is disabled, {{addConnectorWizardLink}} to add one."
                        },
                        no: "Endpoint (internet-facing)",
                        yes: "Endpoint Connector (network-isolated)"
                    },
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    serverCertificateValidationEnabled: "Accept any server SSL certificates"
                }
            }));

    const [existingServerModels, existingEndpointsParts] =
        useMemo(
            () => {
                const existingServerModels =
                    _.filter(
                        serverModels,
                        otherServerModel =>
                            otherServerModel.configuration.id !== serverModel?.configuration.id &&
                            otherServerModel.configuration.scopeId == scopeNodeModel.configuration.id);
                const existingEndpointsParts =
                    _.map(
                        existingServerModels,
                        existingServerModel =>
                            _.split(
                                (existingServerModel.configuration as Contract.QRadarServerConfiguration).endpoint,
                                ":"));
                return [
                    existingServerModels,
                    existingEndpointsParts
                ];
            },
            [serverModels]);

    const [hostNameValidationController, hostNameValidationMessage] =
        useInputValidation(
            () => {
                const validationHostName = hostName.trim();
                if (_.isEmpty(validationHostName)) {
                    return localization.fields.endpoint.hostName.errorRequired();
                }

                return undefined;
            },
            [hostName]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName =
                    name.
                        trim().
                        toLowerCase();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }
                if (_.some(
                    existingServerModels,
                    existingServerModel => StringHelper.compare((existingServerModel.configuration as Contract.QRadarServerConfiguration).name, validationName))) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [portValidationController, portValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNaN(port)) {
                    return localization.fields.endpoint.port.errorRequired();
                }

                if (!_.isEmpty(hostName) &&
                    _(existingEndpointsParts).
                        some(
                            existingEndpointParts =>
                                StringHelper.compare(
                                    existingEndpointParts[0],
                                    hostName) &&
                                _.parseInt(existingEndpointParts[1]) === port)) {
                    return localization.fields.endpoint.errorExists();
                }

                return undefined;
            },
            [hostName, port]);

    useNextEffect(
        async () => {
            try {
                const { scopeSystemEntityModel } =
                    _.isNil(serverModel)
                        ? await ConfigurationController.insertQRadarServer(
                            new Contract.ConfigurationControllerInsertQRadarServerRequest(
                                endpointConnectorEnabled
                                    ? selectedEndpointConnectorConfiguration?.id
                                    : undefined,
                                endpointConnectorEnabled
                                    ? undefined
                                    : hostName,
                                name,
                                endpointConnectorEnabled
                                    ? undefined
                                    : port,
                                scopeNodeModel.configuration.id,
                                serverCertificateValidationEnabled))
                        : await ConfigurationController.updateQRadarServer(
                            new Contract.ConfigurationControllerUpdateQRadarServerRequest(
                                endpointConnectorEnabled
                                    ? selectedEndpointConnectorConfiguration?.id
                                    : undefined,
                                endpointConnectorEnabled
                                    ? undefined
                                    : hostName,
                                serverModel!.configuration.id,
                                name,
                                endpointConnectorEnabled
                                    ? undefined
                                    : port,
                                serverCertificateValidationEnabled));

                await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            } catch {
                return _.isNil(serverModel)
                    ? localization.actions.save.error.add()
                    : localization.actions.save.error.edit();
            }
        },
        [endpointConnectorEnabled, hostName, name, port, selectedEndpointConnectorConfiguration, serverCertificateValidationEnabled]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    serverEndpointConnectorId:
                        endpointConnectorEnabled
                            ? selectedEndpointConnectorConfiguration?.id
                            : undefined,
                    serverHostName:
                        endpointConnectorEnabled
                            ? undefined
                            : hostName.trim(),
                    serverName: name.trim(),
                    serverPort:
                        endpointConnectorEnabled
                            ? undefined
                            : port,
                    serverServerCertificateValidationEnabled: serverCertificateValidationEnabled
                }));

            setValid(
                ((endpointConnectorEnabled && !!selectedEndpointConnectorConfiguration) ||
                    (!endpointConnectorEnabled && hostNameValidationController.isValid() && portValidationController.isValid())) &&
                nameValidationController.isValid());
        },
        [endpointConnectorEnabled, hostName, name, port, selectedEndpointConnectorConfiguration, serverCertificateValidationEnabled]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(70) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.name.title()}
                    value={name}
                    variant="outlined"
                    onChange={event => setName(event.target.value)}/>
                {!_.isNil(nameValidationMessage) && (
                    <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
            </FormControl>
            <Stack
                spacing={2}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(2)
                }}>
                <Typography>
                    {localization.fields.endpointConnectorUsed.description.title({
                        addConnectorWizardLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsEndpointConnectorRelativeUrl(true)}
                                variant="external">
                                {localization.fields.endpointConnectorUsed.description.link()}
                            </Link>
                    })}
                </Typography>
                <RadioField
                    checked={!endpointConnectorEnabled}
                    title={localization.fields.endpointConnectorUsed.no()}
                    onSelected={() => setEndpointConnectorEnabled(false)}>
                    <Stack
                        spacing={2}
                        sx={{ maxWidth: theme.spacing(70) }}>
                        <FormControl
                            fullWidth={true}
                            variant="standard">
                            <TextField
                                disabled={executing}
                                label={localization.fields.endpoint.hostName.title()}
                                value={hostName}
                                variant="outlined"
                                onChange={event => setHostName(event.target.value)}/>
                            {!_.isNil(hostNameValidationMessage) && (
                                <FormHelperText error={true}>{hostNameValidationMessage}</FormHelperText>)}
                        </FormControl>
                        <FormControl
                            fullWidth={true}
                            variant="standard">
                            <TextField
                                disabled={executing}
                                label={localization.fields.endpoint.port.title()}
                                type="number"
                                value={port}
                                variant="outlined"
                                onChange={event => setPort(_.parseInt(event.target.value))}/>
                            {!_.isNil(portValidationMessage) && (
                                <FormHelperText error={true}>{portValidationMessage}</FormHelperText>)}
                        </FormControl>
                    </Stack>
                </RadioField>
                <RadioField
                    checked={endpointConnectorEnabled}
                    indent={false}
                    title={localization.fields.endpointConnectorUsed.yes()}
                    onSelected={() => setEndpointConnectorEnabled(true)}>
                    <ItemSelector
                        disabled={executing || !_.size(endpointConnectorConfigurations)}
                        fullWidth={true}
                        getItemText={(endpointConnectorConfiguration: Contract.EndpointConnectorConfiguration) => endpointConnectorConfiguration.name}
                        items={endpointConnectorConfigurations}
                        selectedItem={selectedEndpointConnectorConfiguration}
                        sorted={true}
                        onSelectedItemChanged={setSelectedEndpointConnectorConfiguration}>
                        {(endpointConnectorConfiguration: Contract.EndpointConnectorConfiguration) => endpointConnectorConfiguration.name}
                    </ItemSelector>
                </RadioField>
            </Stack>
            <CheckboxField
                checked={!serverCertificateValidationEnabled}
                disabled={executing}
                onChange={() => setServerCertificateValidationEnabled(!serverCertificateValidationEnabled)}>
                {localization.fields.serverCertificateValidationEnabled()}
            </CheckboxField>
        </Stack>);
}