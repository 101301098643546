import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EmptyValueIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M16.573 4.24554C13.1217 2.20719 8.60188 2.67069 5.63653 5.63604C2.67118 8.60139 2.20768 13.1212 4.24603 16.5725L5.72099 15.0975C4.43038 12.4817 4.87363 9.22736 7.05074 7.05025C9.22785 4.87315 12.4822 4.4299 15.098 5.72051L16.573 4.24554Z"/>
            <path d="M7.42801 19.7545C10.8793 21.7928 15.3991 21.3293 18.3644 18.364C21.3298 15.3986 21.7933 10.8788 19.7549 7.42752L18.28 8.90249C19.5706 11.5183 19.1273 14.7726 16.9502 16.9497C14.7731 19.1269 11.5187 19.5701 8.90297 18.2795L7.42801 19.7545Z"/>
            <path
                clipRule="evenodd"
                d="M4.15136 19.7077C3.72179 19.2781 3.72179 18.5816 4.15136 18.1521L18.1521 4.15135C18.5817 3.72178 19.2781 3.72178 19.7077 4.15135C20.1373 4.58093 20.1373 5.27741 19.7077 5.70699L5.707 19.7077C5.27742 20.1373 4.58094 20.1373 4.15136 19.7077Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}