﻿import { Optional } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, TypeHelper } from "../../../../../../../../../../../../../../common";

export function useGetServiceAccountSeverity(risk: Contract.GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRisk): [
    Optional<Contract.Severity>,
    string[]
] {
    return useMemo(
        () => {
            const serviceAccountSeverity =
                _(risk.serviceAccountIdToSeverityMap).
                    values().
                    filter().
                    maxBy(getSeverityValue);
            const severityServiceAccountIds =
                _(risk.serviceAccountIdToSeverityMap).
                    pickBy(severity => severity === serviceAccountSeverity).
                    keys().
                    value();
            return [serviceAccountSeverity, severityServiceAccountIds];
        },
        [risk.serviceAccountIdToSeverityMap]);
}

export function getSeverityValue(severity?: Contract.Severity) {
    return _.isNil(severity)
        ? -1
        : TypeHelper.getEnumValue(
            Contract.TypeNames.Severity,
            severity);
}