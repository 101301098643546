import { UnexpectedError } from "@infrastructure";
import { useCallback } from "react";
import { Contract } from "../../../../../../../../../../common";
import { CustomerLicenceObjectTranslationInfo } from "../../useGetCustomerLicenseObjectInfo";
import { useGetVersion2AwsTranslator, useGetVersion2AzureTranslator, useGetVersion2GcpTranslator, useGetVersion2OciTranslator, useGetVersion2OpTranslator } from "./hooks";

export function useGetVersion2Translator() {
    const getVersion2AwsData = useGetVersion2AwsTranslator();
    const getVersion2AzureData = useGetVersion2AzureTranslator();
    const getVersion2GcpData = useGetVersion2GcpTranslator();
    const getVersion2OciData = useGetVersion2OciTranslator();
    const getVersion2OpData = useGetVersion2OpTranslator();
    const getVersionData =
        useCallback(
            (tenantType: Contract.TenantType) => {
                switch (tenantType) {
                    case Contract.TenantType.Aws:
                        return getVersion2AwsData;
                    case Contract.TenantType.Azure:
                        return getVersion2AzureData;
                    case Contract.TenantType.Gcp:
                        return getVersion2GcpData;
                    case Contract.TenantType.Oci:
                        return getVersion2OciData;
                    case Contract.TenantType.Op:
                        return getVersion2OpData;
                    default:
                        throw new UnexpectedError("tenantType", tenantType);
                }
            },
            [getVersion2AwsData, getVersion2AzureData, getVersion2GcpData, getVersion2OciData, getVersion2OpData]);

    return (objectType: Contract.CustomerLicenseObjectType, tenantType: Contract.TenantType, snapshotDate?: string) => {
        const versionData = getVersionData(tenantType);

        return versionData(objectType, snapshotDate);
    };
}

export type CustomerLicenseObjectTypeTranslator = (objectType: Contract.CustomerLicenseObjectType, snapshotDate?: string) => CustomerLicenceObjectTranslationInfo;