import { DataTableColumn, optionalTableCell, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, InlineEntities, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpCommonEncryptedResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCommonEncryptedResourceDefinition",
            () => ({
                columns: {
                    encryptionExists: {
                        false: "Disabled",
                        title: "Encryption",
                        true: "Enabled"
                    },
                    kmsEncryptionKeyReferences: "Encryption Key"
                }
            }));

    function getEncryptionExistsTranslation(encryptedResourceModel: Contract.GcpScopeResourceModel) {
        return (_.as<Contract.IGcpEncryptedResource>(encryptedResourceModel.entity)).encryptionExists
            ? localization.columns.encryptionExists.true()
            : localization.columns.encryptionExists.false();
    }

    return {
        columns: {
            encryptionExistsColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GcpScopeResourceModel) => ({
                                [localization.columns.encryptionExists.title()]: getEncryptionExistsTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.encryptionExists.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.encryptionExists.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.encryptionExists.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists}
                    itemProperty={getEncryptionExistsTranslation}
                    key={Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists}
                    title={localization.columns.encryptionExists.title()}/>,
            encryptionKeyColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.GcpScopeResourceModel>(
                            Contract.TypeNames.GcpKmsKeyRingKey,
                            item =>
                                _(_.as<Contract.IGcpEncryptedResourceModel>(item).kmsEncryptionKeyReferences).
                                    map(kmsEncryptionKeyReference => kmsEncryptionKeyReference.idReference).
                                    value(),
                            localization.columns.kmsEncryptionKeyReferences())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys)}
                                    placeholder={localization.columns.kmsEncryptionKeyReferences()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys}
                    key={Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys}
                    render={
                        optionalTableCell<Contract.IGcpEncryptedResourceModel>(
                            item =>
                                _.isEmpty(item.kmsEncryptionKeyReferences)
                                    ? undefined
                                    : <InlineEntities
                                        entityIdsOrModels={
                                            _.map(
                                                item.kmsEncryptionKeyReferences,
                                                kmsEncryptionKeyReference => kmsEncryptionKeyReference.idReference)}
                                        entityTypeName={Contract.TypeNames.GcpKmsKeyRingKey}
                                        entityVariant="iconText"
                                        variant="itemPlusItemCount"/>)}
                    title={localization.columns.kmsEncryptionKeyReferences()}/>
        }
    };
}