import { Steps, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../..";
import { Contract, useSeverityTranslator } from "../../../../../../../../../../../../../../common";
import { useResourceGeneralInformationStep } from "../../../../../useResourceGeneralInformationStep";
import { useRiskPolicyConfigurationInactivityTimeFrameStep } from "../../../../../useRiskPolicyConfigurationInactivityTimeFrameStep";

type ContextSectionProps = {
    risk: Contract.AwsIamUserUnusedPasswordEnabledRisk;
    userModel: Contract.AwsIamUserModel;
};

export function ContextSection({ risk, userModel }: ContextSectionProps) {
    const userPermissionActionSeverity = risk.userPermissionActionSeverity;
    const userPassword = (userModel.entity as Contract.AwsIamUser).password!;

    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(userModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(userModel);
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserUnusedPasswordEnabledRiskDefinition.contextSection",
            () => ({
                userMfaEnabled: {
                    disabled: "The user has no MFA enabled",
                    enabled: "The user has MFA enabled"
                },
                userPassword: {
                    lastUsed: "last used on **{{lastUsedDate}}**",
                    neverUsed: "**never used**",
                    text: "The user has an **enabled** password which was last updated on {{lastUpdatedDate}} and was {{lastUsed}}"
                },
                userPermissionActionSeverity: {
                    any: "The user has {{userPermissionActionSeverity}} severity permissions",
                    none: "The user has no permissions"
                }
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                useRiskPolicyConfigurationInactivityTimeFrameStep(risk.passwordUsageTimeFrame),
                localization.userPassword.text({
                    lastUpdatedDate: TimeFormatter.mediumDate(userPassword.creationTime),
                    lastUsed:
                        _.isNil(userPassword.usageTime)
                            ? localization.userPassword.neverUsed()
                            : localization.userPassword.lastUsed({
                                lastUsedDate: TimeFormatter.mediumDate(userPassword.usageTime)
                            })
                }),
                risk.userMfaEnabled
                    ? localization.userMfaEnabled.enabled()
                    : localization.userMfaEnabled.disabled(),
                _.isNil(userPermissionActionSeverity)
                    ? localization.userPermissionActionSeverity.none()
                    : localization.userPermissionActionSeverity.any({
                        userPermissionActionSeverity: severityTranslator(userPermissionActionSeverity, "text")
                    }),
                openRiskedEntityRisksStep
            ])}
        </Steps>);
}