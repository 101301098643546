import { Action0 } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React, { RefObject } from "react";
import { EntityIcon, EntityLinkOptions } from "../../..";
import { EntityDefinition } from "../hooks";
import { Link, LinkProps } from "./Link";

type IconProps = SvgIconProps & Pick<LinkProps, "urlOrGetUrl"> & {
    blurred: boolean;
    definition: EntityDefinition;
    linkOptions?: EntityLinkOptions;
    linkRef?: RefObject<HTMLAnchorElement>;
    onClick?: Action0;
    onMouseEnter?: Action0;
    onMouseLeave?: Action0;
};

export function Icon({ blurred, definition, linkOptions, linkRef, onClick, onMouseEnter, onMouseLeave, sx, urlOrGetUrl, ...props }: IconProps) {
    return (
        <Link
            linkOptions={linkOptions}
            ref={linkRef}
            urlOrGetUrl={urlOrGetUrl}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <EntityIcon
                {...props}
                badge={definition.badge}
                blurred={blurred}
                entityTypeName={definition.typeName}
                sensitive={definition.sensitive}
                sx={sx}
                variant={definition.iconVariant}/>
        </Link>);
}