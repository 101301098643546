﻿import { UnexpectedError } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, CustomerConsoleAppUrlHelperCategoryView, RiskPolicyTypeMetadataHelper, RiskTypeMetadataModelHelper, TypeHelper } from "..";
import { RiskDefinitionSectionCategory } from "../../views/Customer/components/Risks/hooks/useDefinition/utilities";
import { RiskView } from "../../views/Customer/components/Risks/utilities";

export enum RiskType {
    Cloud = "Cloud",
    Code = "Code"
}

export class RiskHelper {
    public static getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(risk: Contract.Risk) {
        const riskPolicyConfigurationTypeName = RiskTypeMetadataModelHelper.get(risk.typeName).policyConfigurationTypeName;
        return RiskPolicyTypeMetadataHelper.get(riskPolicyConfigurationTypeName).category === Contract.RiskPolicyCategory.Custom
            ? risk.policyId
            : riskPolicyConfigurationTypeName;
    }

    public static getRiskProfileRelativeUrlByRiskType(
        risk: Contract.Risk,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        switch (RiskHelper.getRiskType(risk)) {
            case RiskType.Cloud:
                return CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(risk.id, categoryView);
            case RiskType.Code:
                return CustomerConsoleAppUrlHelper.getCodeRiskProfileRelativeUrl(risk.id, categoryView);
            default:
                throw new UnexpectedError("risk.typeName", risk.typeName);
        }
    }

    public static getRiskProfileUrlByRiskType(
        risk: Contract.Risk,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        switch (RiskHelper.getRiskType(risk)) {
            case RiskType.Cloud:
                return CustomerConsoleAppUrlHelper.getRiskProfileUrl(risk.id, categoryView);
            case RiskType.Code:
                return CustomerConsoleAppUrlHelper.getCodeRiskProfileUrl(risk.id, categoryView);
            default:
                throw new UnexpectedError("risk.typeName", risk.typeName);
        }
    }

    public static getRisksUrlByRiskType(riskType?: RiskType) {
        switch (riskType) {
            case RiskType.Cloud:
                return CustomerConsoleAppUrlHelper.getRisksUrl(Contract.RisksView.Open);
            case RiskType.Code:
                return CustomerConsoleAppUrlHelper.getCodeRisksUrl();
            default:
                throw new UnexpectedError("riskType", riskType);
        }
    }

    public static getRiskType(risk: Contract.Risk) {
        return TypeHelper.extendOrImplement(risk.typeName, Contract.TypeNames.CloudRisk)
            ? RiskType.Cloud
            : RiskType.Code;
    }
}