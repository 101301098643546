﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureComputeDiskResourceNetworkAccessPolicyTypeTranslator() {

    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureComputeDiskResourceNetworkAccessPolicyTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureComputeDiskResourceNetworkAccessPolicyType]: {
                    [Contract.AzureComputeDiskResourceNetworkAccessPolicyType.AllowAll]: "Public",
                    [Contract.AzureComputeDiskResourceNetworkAccessPolicyType.AllowPrivate]: "Private",
                    [Contract.AzureComputeDiskResourceNetworkAccessPolicyType.DenyAll]: "Deny All"
                }

            }));
    return (type: Contract.AzureComputeDiskResourceNetworkAccessPolicyType) =>
        localization[Contract.TypeNames.AzureComputeDiskResourceNetworkAccessPolicyType][type]();
}