import { SettingsOutlined as ConfigurationIcon } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useState } from "react";
import { ActionButton, ActionMenuItem, ColumnsIcon, Dropdown, Menu, TextViewer, useChangeEffect, useLocalization } from "@infrastructure";
import { Contract, ObjectIdentifierData, useSetUdmObjectTableContext, useTheme } from "../../..";
import { ColumnSelector } from "../components";

type ActionsProps = {
    itemsCount?: number;
    itemsCountLoading: boolean;
    objectIdentifierToDataMap: Dictionary<ObjectIdentifierData>;
    onSelectedPropertyIdsChanged: (objectIdentifier: string, propertyIds: Contract.UdmObjectPropertyId[]) => void;
    query: Contract.UdmQuery;
};

export function Actions({ itemsCount, itemsCountLoading, objectIdentifierToDataMap, onSelectedPropertyIdsChanged, query }: ActionsProps) {
    const [compact, setCompact] = useState(true);
    const [queryString, setQueryString] = useState(() => JSON.stringify(query, undefined, 2));
    const setUdmObjectTableContext = useSetUdmObjectTableContext();
    useChangeEffect(
        () =>
            setUdmObjectTableContext(
                context => ({
                    ...context,
                    compact
                })),
        [compact]);

    const localization =
        useLocalization(
            "common.udmObjectTable.actions",
            () => ({
                action: {
                    comfortable: "Comfortable",
                    compact: "Compact",
                    tooltip: "Table Options"
                },
                columnPicker: {
                    tooltip: "Column Picker"
                },
                itemCount: [
                    "1 result",
                    "{{count | NumberFormatter.humanize}} items"
                ],
                jsonButton: "Show Json"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Box>
                <Typography
                    sx={{
                        alignSelf: "center",
                        color: theme.palette.text.secondary,
                        minWidth: "max-content",
                        paddingRight: theme.spacing(1)
                    }}>
                    {itemsCountLoading
                        ? <Skeleton
                            animation="wave"
                            sx={{ width: theme.spacing(6) }}/>
                        : !_.isNil(itemsCount) && localization.itemCount(itemsCount)}
                </Typography>
            </Box>
            <Dropdown
                disabled={_.isEmpty(objectIdentifierToDataMap)}
                popoverElement={
                    () =>
                        <ColumnSelector
                            objectIdentifierToDataMap={objectIdentifierToDataMap}
                            onSelectedPropertyIdsChanged={onSelectedPropertyIdsChanged}/>}
                popoverElementContainerSx={{ padding: 0 }}>
                <ActionButton
                    size="medium"
                    tooltip={localization.columnPicker.tooltip()}
                    variant="outlined">
                    <ColumnsIcon/>
                </ActionButton>
            </Dropdown>
            <Menu
                itemsOrGetItems={[
                    new ActionMenuItem(
                        () => setCompact(true),
                        localization.action.compact(),
                        {
                            icon:
                                <CheckIcon
                                    sx={{
                                        visibility:
                                            compact
                                                ? "visible"
                                                : "hidden"
                                    }}/>
                        }),
                    new ActionMenuItem(
                        () => setCompact(false),
                        localization.action.comfortable(),
                        {
                            icon:
                                <CheckIcon
                                    sx={{
                                        visibility:
                                            compact
                                                ? "hidden"
                                                : "visible"
                                    }}/>
                        })
                ]}
                variant="bottomLeft">
                <ActionButton
                    size="medium"
                    tooltip={localization.action.tooltip()}
                    variant="outlined">
                    <ConfigurationIcon/>
                </ActionButton>
            </Menu>
            <Dropdown
                popoverElement={
                    <TextViewer
                        copyToClipboard={true}
                        format="json"
                        height="500px"
                        text={queryString}
                        width="500px"/>}
                onOpen={() => setQueryString(JSON.stringify(query, undefined, 2))}>
                <ActionButton
                    size="medium"
                    tooltip={localization.jsonButton()}
                    variant="outlined">
                    <DataObjectIcon/>
                </ActionButton>
            </Dropdown>
        </Stack>);
}