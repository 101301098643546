import { Box, List, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, memo } from "react";
import { useTheme } from "../../../../../../../..";
import { ObjectSelectorItem } from "../../ObjectSelector";
import { Item } from "./components";

const CategoryItemsMemo = memo(CategoryItems);
export { CategoryItemsMemo as CategoryItems };

type CategoryItemsProps = {
    item: ObjectSelectorItem;
    onHovered?: (item: ObjectSelectorItem) => void;
    onSelectedItemIdChanged: (selectedItemId: string) => void;
    selectedItemId?: string;
};

function CategoryItems({ item, onHovered, onSelectedItemIdChanged, selectedItemId }: CategoryItemsProps) {
    const theme = useTheme();
    return (
        <Fragment>
            <Box sx={{ padding: theme.spacing(0.75, 2) }}>
                {_.isString(item.title)
                    ? <Typography
                        noWrap={true}
                        sx={{
                            color: theme.palette.text.secondary,
                            lineHeight: 1
                        }}>
                        {item.title}
                    </Typography>
                    : item.title}
            </Box>
            <List disablePadding={true}>
                {_.map(
                    item.options?.items,
                    item =>
                        <Item
                            item={item}
                            key={item.id}
                            selected={selectedItemId === item.id}
                            onHovered={() => onHovered?.(item)}
                            onSelected={() => onSelectedItemIdChanged(item.id)}/>)}
            </List>
        </Fragment>);
}