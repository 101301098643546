import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../../../common";

export function useSqsQueueIssueTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.sqs.hooks.useSqsQueueIssueTranslator",
            () => ({
                noIssue: "Connected",
                [Contract.TypeNames.SqsQueueStateIssue]: {
                    [Contract.SqsQueueStateIssue.AssumeRoleFailed]: "Failed to assume role",
                    [Contract.SqsQueueStateIssue.InvalidUrl]: "Invalid URL",
                    [Contract.SqsQueueStateIssue.MissingKmsPermissions]: "The role has no kms:GenerateDataKey permission for the queue",
                    [Contract.SqsQueueStateIssue.MissingQueuePermissions]: "The role has no SQS:SendMessage permission for the queue",
                    [Contract.SqsQueueStateIssue.MissingRole]: "No role was found for the queue's account. Please create one manually and enter the ARN."
                }
            }));
    return (queueIssue?: Contract.SqsQueueStateIssue) =>
        _.isNil(queueIssue)
            ? localization.noIssue()
            : localization[Contract.TypeNames.SqsQueueStateIssue][queueIssue]();
}