﻿import { map, UnexpectedError, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { OperatorSelector } from "../../../../../../OperatorSelector";
import { PagedEntityPropertyValueSelector } from "../../../../../../PagedEntityPropertyValueSelector";
import { useCustomRiskPolicyContext } from "../../../../../CustomRiskPolicy";
import { useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";
import { useEntityMatchConditionCollectionOperatorTranslator } from "../hooks";

type PropertiesProps = {
    entityTypeName: string;
    identifier: Contract.EntityPropertyIdentifier;
    includeServiceName: boolean;
};

export function Properties({ entityTypeName, identifier, includeServiceName }: PropertiesProps) {
    const { scopeId, tenantTypes } = useCustomRiskPolicyContext();
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[identifier.raw] as Contract.EntityPropertyMatchCondition<string>;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const operatorTranslator = useEntityMatchConditionCollectionOperatorTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.properties",
            () => ({
                text: {
                    part1: "*capitalize*{{translatedEntityTypeName}}** matching",
                    part2: "of {{identifierName}} selection"
                }
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.text.part1({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <OperatorSelector
                operators={_.values(Contract.EntityMatchConditionCollectionOperator)}
                operatorTranslator={operatorTranslator}
                selectedOperator={condition.operator}
                onSelectedOperatorChanged={
                    (operator: Contract.EntityMatchConditionCollectionOperator) =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [identifier.raw]:
                                        map(
                                            identifier.valueType,
                                            {
                                                [Contract.EntityPropertyValueType.PrincipalReference]: () =>
                                                    new Contract.EntityPrincipalReferencePropertyMatchCondition(
                                                        identifier,
                                                        operator,
                                                        condition.values),
                                                [Contract.EntityPropertyValueType.String]: () =>
                                                    new Contract.EntityStringPropertyMatchCondition(
                                                        identifier,
                                                        operator,
                                                        condition.values)
                                            },
                                            () => {
                                                throw new UnexpectedError("identifier.valueType", identifier.valueType);
                                            })
                                }
                            }))}/>
            <Typography>
                {localization.text.part2({ identifierName: identifier.name })}
            </Typography>
            <PagedEntityPropertyValueSelector
                entityTypeName={entityTypeName}
                identifier={identifier}
                scopeId={scopeId}
                selectedValues={condition.values}
                tenantTypes={tenantTypes}
                onSelectedValuesChanged={
                    values =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [identifier.raw]:
                                        map(
                                            identifier.valueType,
                                            {
                                                [Contract.EntityPropertyValueType.PrincipalReference]: () =>
                                                    new Contract.EntityPrincipalReferencePropertyMatchCondition(
                                                        identifier,
                                                        condition.operator,
                                                        values),
                                                [Contract.EntityPropertyValueType.String]: () =>
                                                    new Contract.EntityStringPropertyMatchCondition(
                                                        identifier,
                                                        condition.operator,
                                                        values)
                                            },
                                            () => {
                                                throw new UnexpectedError("identifier.valueType", identifier.valueType);
                                            })
                                }
                            }))}/>
        </Stack>);
}