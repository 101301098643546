import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MenuIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <rect
                height="2"
                rx="1"
                width="18"
                x="3"
                y="6"/>
            <rect
                height="2"
                rx="1"
                width="18"
                x="3"
                y="11"/>
            <rect
                height="2"
                rx="1"
                width="18"
                x="3"
                y="16"/>
        </SvgIcon>);
}