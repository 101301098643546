import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../common";
import { AwsEc2NetworkAclRulesTable } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsEc2SubnetsInfoCard } from "../../components";

export function useAwsEc2NetworkAclDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const networkAcl = resourceModel.entity as Contract.AwsEc2NetworkAcl;
    const networkAclModel = resourceModel as Contract.AwsEc2NetworkAclModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2NetworkAclDefinition",
            () => ({
                info: {
                    cards: {
                        inboundRules: "Inbound Rules",
                        outboundRules: "Outbound Rules"
                    },
                    items: {
                        defaultNetworkAcl: {
                            false: "No",
                            title: "Main Network ACL",
                            true: "Yes"
                        },
                        vpcId: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkAcl.vpcId}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpcId"
                        title={localization.info.items.vpcId()}/>,
                    <InfoItem
                        key="defaultNetworkAcl"
                        title={localization.info.items.defaultNetworkAcl.title()}
                        value={
                            networkAcl.defaultNetworkAcl
                                ? localization.info.items.defaultNetworkAcl.true()
                                : localization.info.items.defaultNetworkAcl.false()}/>
                ]}
                options={options?.infoOptions}>
                <AwsEc2SubnetsInfoCard subnetIds={networkAclModel.subnetIds}/>
                <InfoCard title={localization.info.cards.inboundRules()}>
                    <AwsEc2NetworkAclRulesTable
                        inbound={true}
                        rules={networkAcl.inboundRules}/>
                </InfoCard>
                <InfoCard title={localization.info.cards.outboundRules()}>
                    <AwsEc2NetworkAclRulesTable
                        inbound={false}
                        rules={networkAcl.outboundRules}/>
                </InfoCard>
            </Info>
    });
}