import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonIamPrincipalInfoItemElements, useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciIamDynamicResourceGroupDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const commonIamPrincipalInfoItemElements = useOciCommonIamPrincipalInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const dynamicResourceGroupModel = resourceModel as Contract.OciIamDynamicResourceGroupModel;
    const dynamicResourceGroup = dynamicResourceGroupModel.entity as Contract.OciIamDynamicResourceGroup;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciIamDynamicResourceGroupDefinition",
            () => ({
                info: {
                    items: {
                        membershipRule: "Matching Rule"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonIamPrincipalInfoItemElements.domainIdInfoItemElement,
                    commonIamPrincipalInfoItemElements.descriptionInfoItemElement,
                    <InfoItem
                        key="membershipRule"
                        title={localization.info.items.membershipRule()}
                        value={dynamicResourceGroup.membershipRule}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}