import { DataTableColumnRenderProps, Link, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, StatusCell as BaseStatusCell, useTheme } from "../../../../../../../../../common";
import { useAzureTenantModelStatusTranslator } from "../../../../../../../../../tenants";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.AzureTenantModel>) {
    const azureTenantStatusTranslator = useAzureTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.statusCell",
            () => ({
                [Contract.AzureTenantModelStatus.AadTenantMismatch]: {
                    aadTenantsPageLink: "Onboard the new tenant now",
                    title: "Microsoft Entra ID tenant {{aadTenantId}} is no longer associated with your subscription. The subscription is now associated with a different tenant. {{aadTenantsPageLink}}."
                },
                [Contract.TypeNames.AzureTenantModelIssue]: {
                    [Contract.AzureTenantModelIssue.TerraformStateStorageAccountBlobContainerAccessDenied]: "Missing permissions to read Terraform state files from blob containers in the following storage accounts - {{terraformStateStorageAccountNames}}"
                },
                servicePrincipalDataAnalysisAssignedRoleDefinitionMismatch: "Data protection permissions not granted",
                servicePrincipalMissingAssignedRoleDefinitionNames: "The following role definitions are missing:",
                servicePrincipalWorkloadAnalysisAssignedRoleDefinitionMismatch: "Workload protection permissions not granted"
            }));

    const theme = useTheme();
    return (
        <BaseStatusCell
            statusSeverity={item.statusSeverity}
            title={azureTenantStatusTranslator(item.status)}
            tooltip={
                (() => {
                    switch (item.status) {
                        case Contract.AzureTenantModelStatus.AccessDenied:
                            return (
                                <Typography
                                    key={item.state.monitoring.analysisAccessDeniedErrorMessage}
                                    sx={{ whiteSpace: "pre-wrap" }}>
                                    {item.state.monitoring.analysisAccessDeniedErrorMessage}
                                </Typography>);
                        case Contract.AzureTenantModelStatus.AadTenantMismatch:
                            return localization[Contract.AzureTenantModelStatus.AadTenantMismatch].title({
                                aadTenantId: item.configuration.aadTenantId,
                                aadTenantsPageLink:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsAadRelativeUrl()}
                                        variant="external">
                                        {localization[Contract.AzureTenantModelStatus.AadTenantMismatch].aadTenantsPageLink()}
                                    </Link>
                            });
                        case Contract.AzureTenantModelStatus.ServicePrincipalDataAnalysisAssignedRoleDefinitionMismatch:
                            return (
                                <Stack>
                                    <Typography sx={{ paddingBottom: theme.spacing(2) }}>
                                        {localization.servicePrincipalDataAnalysisAssignedRoleDefinitionMismatch()}
                                    </Typography>
                                </Stack>);
                        case Contract.AzureTenantModelStatus.ServicePrincipalMandatoryAssignedRoleDefinitionMismatch:
                        case Contract.AzureTenantModelStatus.ServicePrincipalOptionalAssignedRoleDefinitionMismatch:
                            return (
                                <Stack>
                                    <Typography sx={{ paddingBottom: theme.spacing(2) }}>
                                        {localization.servicePrincipalMissingAssignedRoleDefinitionNames()}
                                    </Typography>
                                    {_.map(
                                        item.state.monitoring.servicePrincipalMissingAssignedRoleDefinitionNames,
                                        servicePrincipalMissingAssignedRoleDefinitionName =>
                                            <Typography key={servicePrincipalMissingAssignedRoleDefinitionName}>
                                                {servicePrincipalMissingAssignedRoleDefinitionName}
                                            </Typography>)}
                                </Stack>);
                        case Contract.AzureTenantModelStatus.ServicePrincipalWorkloadAnalysisAssignedRoleDefinitionMismatch:
                            return (
                                <Stack>
                                    <Typography sx={{ paddingBottom: theme.spacing(2) }}>
                                        {localization.servicePrincipalWorkloadAnalysisAssignedRoleDefinitionMismatch()}
                                    </Typography>
                                </Stack>);
                        case Contract.AzureTenantModelStatus.ValidWithIssues:
                            return (
                                <Steps variant="bullets">
                                    {_.map(
                                        item.issues,
                                        issue =>
                                            <Typography>
                                                {localization[Contract.TypeNames.AzureTenantModelIssue][issue]({
                                                    terraformStateStorageAccountNames:
                                                        _(item.state.monitoring.terraformStateStorageAccountNameToAccessDeniedBlobContainerNamesMap).
                                                            keys().
                                                            join(", ")
                                                })}
                                            </Typography>)}
                                </Steps>);
                        default:
                            return undefined;
                    }
                })()}/>);
}