import { InlineItems, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../common";

type GcpApiKeysKeyRestrictionsClientRawIdentifiersProps = {
    keyRestrictions: Contract.GcpApiKeysKeyRestrictions;
};

export function GcpApiKeysKeyRestrictionsClientRawIdentifiers({ keyRestrictions }: GcpApiKeysKeyRestrictionsClientRawIdentifiersProps) {
    const localization =
        useLocalization(
            "tenants.gcp.gcpApiKeysKeyRestrictionsClientRawIdentifiers",
            () => ({
                clientRawIdentifiers: {
                    [Contract.TypeNames.GcpApiKeysKeyRestrictionsClientType]: {
                        [Contract.GcpApiKeysKeyRestrictionsClientType.AndroidApps]: [
                            "1 Android app",
                            "{{count | NumberFormatter.humanize}} Android apps"
                        ],
                        [Contract.GcpApiKeysKeyRestrictionsClientType.HttpReferrers]: [
                            "1 HTTP referrer",
                            "{{count | NumberFormatter.humanize}} HTTP referrers"
                        ],
                        [Contract.GcpApiKeysKeyRestrictionsClientType.IosApps]: [
                            "1 iOS app",
                            "{{count | NumberFormatter.humanize}} iOS apps"
                        ],
                        [Contract.GcpApiKeysKeyRestrictionsClientType.IpAddresses]: [
                            "1 IP address",
                            "{{count | NumberFormatter.humanize}} IP addresses"
                        ]
                    },
                    title: "Application Restrictions"
                }
            }));

    return (
        <InlineItems
            items={keyRestrictions.clientRawIdentifiers}
            namePluralizer={localization.clientRawIdentifiers[Contract.TypeNames.GcpApiKeysKeyRestrictionsClientType][keyRestrictions.clientType as Exclude<Contract.GcpApiKeysKeyRestrictionsClientType, Contract.GcpApiKeysKeyRestrictionsClientType.None>]}
            variant="itemCountAndType"/>);
}