﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsOrganizationsAccountStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsOrganizationsAccountStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsOrganizationsAccountStatus]: {
                    [Contract.AwsOrganizationsAccountStatus.Active]: "Active",
                    [Contract.AwsOrganizationsAccountStatus.Pending]: "Pending",
                    [Contract.AwsOrganizationsAccountStatus.Suspended]: "Suspended"
                }
            }));
    return (status: Contract.AwsOrganizationsAccountStatus) =>
        localization[Contract.TypeNames.AwsOrganizationsAccountStatus][status]();
}