import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../utilities";

export function useGetNetworkAccessResourceRiskContext() {
    return useMemo(
        () => useNetworkAccessResourceRiskContext,
        []);
}

function useNetworkAccessResourceRiskContext(entityModel: Contract.EntityModel) {
    const entityNetwork = entityModel.entityNetwork as Optional<Contract.NetworkAccessResourceStateNetwork>;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetNetworkAccessResourceRiskContext",
            () => ({
                inboundExternalAccessScope: {
                    [Contract.TypeNames.NetworkAccessScope]: {
                        [Contract.NetworkAccessScope.All]: "The {{translatedEntityTypeName}} is accessible from any IP address",
                        [Contract.NetworkAccessScope.None]: "The {{translatedEntityTypeName}} is not exposed to the internet",
                        [Contract.NetworkAccessScope.Restricted]: "The {{translatedEntityTypeName}} is accessible from specific IP addresses",
                        [Contract.NetworkAccessScope.Wide]: "The {{translatedEntityTypeName}} is accessible from a wide range of public IP addresses"
                    }
                }
            }));
    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const accessScope = entityNetwork?.inboundExternalAccessScope;
    return {
        inboundExternalAccessScope:
            _.isNil(accessScope)
                ? undefined
                : new RiskDefinitionContextItem(localization.inboundExternalAccessScope[Contract.TypeNames.NetworkAccessScope][accessScope]({ translatedEntityTypeName }))
    };
}