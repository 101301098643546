import { Link, Step, Steps, TextSnippet, TextSnippetItem, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { ConfigurationController, ConsoleAppUrlHelper, Contract, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { addOrViewContext } from "../AddOrView";

export function ConnectItem() {
    const { endpointConnectorModel } = addOrViewContext();
    const endpointConnectorConfiguration = (endpointConnectorModel!.configuration as Contract.EndpointConnectorConfiguration);
    const endpointConnectorEndpointParts = endpointConnectorConfiguration.endpoint.split(":");
    const [{ apiKeyToken }] =
        useExecuteOperation(
            ConnectItem,
            () => ConfigurationController.getEndpointConnectorApiKeyToken(new Contract.ConfigurationControllerGetEndpointConnectorApiKeyTokenRequest(endpointConnectorConfiguration.id)));

    const { setLoaded, useNextEffect } = useOrderedWizardContext();
    useEffect(setLoaded, []);

    useNextEffect(
        async () => {
            await scopeSystemEntityModelStore.notify(endpointConnectorModel);
            return undefined;
        },
        [endpointConnectorModel]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useEndpointConnectorItems.endpointConnectors.addOrView.connectItem",
            () => ({
                steps: {
                    step1: {
                        links: {
                            documentation: "documentation"
                        },
                        title: "Install Helm as a package manager for Kubernetes. Please refer to Helm’s {{documentationLink}} to get started."
                    },
                    step2: "Configure kubectl to connect to your Kubernetes cluster.",
                    step3: "Run the following command to add the Tenable repo:",
                    step4: "To connect and send data to Tenable, run the following command. This command installs the endpoint connector chart on the Kubernetes cluster."
                }
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Steps variant="plainNumbers">
                {localization.steps.step1.title({
                    documentationLink:
                        <Link
                            urlOrGetUrl="https://helm.sh/docs/"
                            variant="external">
                            {localization.steps.step1.links.documentation()}
                        </Link>
                })}
                {localization.steps.step2()}
                {new Step(
                    localization.steps.step3(),
                    {
                        contentElement:
                            <TextSnippet
                                height={theme.spacing(5)}
                                items={[
                                    new TextSnippetItem(
                                        "helm repo add tenable https://charts.tenable.com",
                                        "")
                                ]}/>
                    })}
                {new Step(
                    localization.steps.step4(),
                    {
                        contentElement:
                            <TextSnippet
                                height={theme.spacing(15)}
                                items={[
                                    new TextSnippetItem(
                                        `helm install tenable-cloud-security-endpoint-connector-${endpointConnectorConfiguration.id.split("-")[0]} tenable/cloud-security-endpoint-connector -n tenable-cloud-security-endpoint-connector-${endpointConnectorConfiguration.id.split("-")[0]} --create-namespace --set apiKeyToken=${apiKeyToken} --set apiUrl=${ConsoleAppUrlHelper.url} --set serviceEndpoint=${endpointConnectorEndpointParts[0]}:${endpointConnectorEndpointParts[1]} --set serviceId=${endpointConnectorConfiguration.id}`,
                                        "")
                                ]}/>
                    })}
            </Steps>
        </Stack>);
}