import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DataIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M5.29209 4.58052C5.01022 4.83042 5 4.97447 5 5C5 5.02553 5.01022 5.16958 5.29209 5.41948C5.57279 5.66834 6.03602 5.93815 6.69427 6.18499C8.0034 6.67591 9.87903 7 12 7C14.121 7 15.9966 6.67591 17.3057 6.18499C17.964 5.93815 18.4272 5.66834 18.7079 5.41948C18.9898 5.16958 19 5.02553 19 5C19 4.97447 18.9898 4.83042 18.7079 4.58052C18.4272 4.33166 17.964 4.06185 17.3057 3.81501C15.9966 3.32409 14.121 3 12 3C9.87903 3 8.0034 3.32409 6.69427 3.81501C6.03602 4.06185 5.57279 4.33166 5.29209 4.58052ZM5.99202 1.94235C7.57833 1.34749 9.70269 1 12 1C14.2973 1 16.4217 1.34749 18.008 1.94235C18.7974 2.2384 19.5058 2.61504 20.0347 3.08401C20.5625 3.55194 21 4.19711 21 5C21 5.80289 20.5625 6.44806 20.0347 6.91599C19.5058 7.38496 18.7974 7.7616 18.008 8.05765C16.4217 8.65251 14.2973 9 12 9C9.70269 9 7.57833 8.65251 5.99202 8.05765C5.20256 7.7616 4.49422 7.38496 3.96527 6.91599C3.43749 6.44806 3 5.80289 3 5C3 4.19711 3.43749 3.55194 3.96527 3.08401C4.49422 2.61504 5.20256 2.2384 5.99202 1.94235Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M6.04191 10.5556C6.20013 10.0265 6.75733 9.72576 7.28647 9.88397C7.83976 10.0494 8.46578 10.1897 9.14981 10.2933C9.69586 10.3761 10.0715 10.8858 9.98872 11.4319C9.90598 11.9779 9.39625 12.3535 8.85019 12.2708C8.08222 12.1544 7.36424 11.9947 6.71353 11.8001C6.18439 11.6419 5.8837 11.0847 6.04191 10.5556ZM4 11.0001C4.55228 11.0001 5 11.4478 5 12.0001C5 12.0256 5.01024 12.1697 5.29211 12.4196C5.57283 12.6685 6.03608 12.9383 6.69436 13.1851C8.00352 13.676 9.87916 14.0001 12 14.0001C14.1208 14.0001 15.9965 13.676 17.3056 13.1851C17.9639 12.9383 18.4272 12.6685 18.7079 12.4196C18.9898 12.1697 19 12.0256 19 12.0001C19 11.4478 19.4477 11.0001 20 11.0001C20.5523 11.0001 21 11.4478 21 12.0001C21 12.803 20.5625 13.4482 20.0347 13.9161C19.5057 14.3851 18.7973 14.7617 18.0079 15.0578C16.4215 15.6526 14.2972 16.0001 12 16.0001C9.70284 16.0001 7.57848 15.6526 5.99214 15.0578C5.20267 14.7617 4.4943 14.3851 3.96533 13.9161C3.43751 13.4482 3 12.803 3 12.0001C3 11.4478 3.44772 11.0001 4 11.0001ZM6.04191 17.5556C6.20013 17.0265 6.75733 16.7258 7.28647 16.884C7.83976 17.0494 8.46579 17.1897 9.14981 17.2933C9.69586 17.3761 10.0715 17.8858 9.98872 18.4319C9.90598 18.9779 9.39625 19.3535 8.85019 19.2708C8.08222 19.1544 7.36424 18.9947 6.71353 18.8001C6.18439 18.6419 5.8837 18.0847 6.04191 17.5556Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M4 4C4.55228 4 5 4.44772 5 5V19C5 19.0256 5.01024 19.1696 5.29211 19.4195C5.57283 19.6684 6.03608 19.9382 6.69436 20.185C8.00352 20.6759 9.87916 21 12 21C14.1208 21 15.9965 20.6759 17.3056 20.185C17.9639 19.9382 18.4272 19.6684 18.7079 19.4195C18.9898 19.1696 19 19.0256 19 19V5C19 4.44772 19.4477 4 20 4C20.5523 4 21 4.44772 21 5V19C21 19.8029 20.5625 20.4481 20.0347 20.9161C19.5057 21.385 18.7973 21.7617 18.0079 22.0577C16.4215 22.6526 14.2972 23 12 23C9.70284 23 7.57848 22.6526 5.99214 22.0577C5.20267 21.7617 4.4943 21.385 3.96533 20.9161C3.43751 20.4481 3 19.8029 3 19V5C3 4.44772 3.44772 4 4 4Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}