import _, { Dictionary } from "lodash";
import { useOpCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonVirtualMachineDiskResourceDefinition } from "../useCommonVirtualMachineDiskResourceDefinition";
import { useCommonVirtualMachineResourceDefinition } from "../useCommonVirtualMachineResourceDefinition";

export function useOpVirtualMachineDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOpCommonResourceDefinition(definitionData);
    const commonVirtualMachineDiskResourceDefinition = useCommonVirtualMachineDiskResourceDefinition(definitionData);
    const commonVirtualMachineResourceDefinition = useCommonVirtualMachineResourceDefinition();


    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonVirtualMachineDiskResourceDefinition.columns.operatingSystemColumn,
                commonVirtualMachineDiskResourceDefinition.columns.operatingSystemTypeColumn,
                commonVirtualMachineDiskResourceDefinition.columns.vulnerabilitiesColumn,
                commonVirtualMachineDiskResourceDefinition.columns.workloadAnalysisStatusColumn,
                commonVirtualMachineDiskResourceDefinition.columns.scanTimeColumn,
                commonVirtualMachineResourceDefinition.columns.scanSource,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOpVirtualMachineRequest(
                new Contract.EntityControllerGetEntityModelPageOpVirtualMachineRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(undefined),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemDisplayName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemType]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.VirtualMachineScanTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineScanStatus])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineKubernetesClusterSensor]))));
}