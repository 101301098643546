﻿import { Link, useLocalization, useLocalizeAttributes } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../controllers";
import { scopeSystemEntityModelStore } from "../stores";

export function useJiraIssueTranslator() {
    const instanceModels = scopeSystemEntityModelStore.useGetJira();
    const localizeAttributes = useLocalizeAttributes();
    const localization =
        useLocalization(
            "common.hooks.useJiraIssueTranslator",
            () => ({
                assignee: "Assignee: **{{userDisplayName}}** ",
                id: "Id: **{{rawId}}**",
                issueLink: "Link",
                multipleRisks : "Unified issue",
                project: "Project: **{{projectName}}**",
                typeName: "Issue Type: **{{typeName}}**"
            }));
    return (issue: Contract.JiraIssue, variant: "id" | "link") => {
        const instanceModel =
            _.find(
                instanceModels,
                instanceModel => instanceModel.configuration.id === issue.instanceId)!;
        const projectName =
            _.isNil(instanceModel)
                ? undefined
                : (instanceModel.state as Contract.JiraInstanceState).projectRawIdToProjectMap[issue.projectRawId]?.project.name;
        return localizeAttributes(
            variant === "id"
                ? localization.id({ rawId: issue.rawId })
                : undefined,
            _.isNil(projectName)
                ? undefined
                : localization.project({ projectName }),
            issue.issueTypeName === ""
                ? undefined
                : localization.typeName({ typeName: issue.issueTypeName }),
            _.isNil(issue.user)
                ? undefined
                : localization.assignee({ userDisplayName: issue.user!.displayName }),
            variant === "link"
                ? <Link
                    sx={{ fontWeight: 700 }}
                    urlOrGetUrl={issue.url}
                    variant="external">
                    {localization.issueLink()}
                </Link>
                : undefined,
            issue.multipleRisks
                ? localization.multipleRisks()
                : undefined);
    };
}