﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel: Contract.GcpScopeResourceModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonServiceAccountOriginatorScopeResourceInfoElements",
            () => ({
                serviceAccount: "Service Account"
            }));

    return {
        serviceAccount:
            <EntitiesInfoItem
                entityIdsOrModels={_.as<Contract.IGcpServiceAccountOriginatorScopeResourceModel>(scopeResourceModel).serviceAccountIdReference}
                entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                key="serviceAccount"
                location="all"
                title={localization.serviceAccount()}/>
    };
}