import { Optional, PagedDropdownPage, useChangeEffect, useDebouncedEffect, useLocalization, useLocalizeList } from "@infrastructure";
import { TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { Contract, UdmController } from "../../../../../../../controllers";
import { ObjectTypeMetadataModelHelper } from "../../../../../../../utilities";
import { UdmObjectPropertyFilterProps } from "../UdmObjectPropertyFilter";
import { Filter } from "./Filter";
import { ListFilterPopover } from "./ListFilterPopover";

export function InfraString({ objectTypeName, onClearClicked, onFilterChange, propertyId, rule }: UdmObjectPropertyFilterProps) {
    const [operator, setOperator] = useState<Contract.UdmQueryRuleOperator>(rule.operator ?? Contract.UdmQueryRuleOperator.StringStartsWith);
    const [valueOrValues, setValueOrValues] =
        useState<string | string[]>(
            _.isEmpty(rule.values)
                ? undefined
                : operator === Contract.UdmQueryRuleOperator.In
                    ? rule.values
                    : rule.values[0]);

    useDebouncedEffect(
        () => {
            if (operator && !_.isNil(valueOrValues)) {
                onFilterChange({
                    operator,
                    value:
                        _.isArray(valueOrValues)
                            ? valueOrValues
                            : [valueOrValues]
                });
            }
        },
        500,
        [operator, valueOrValues]);

    const objectProperty =
        useMemo(
            () =>
                _.find(
                    ObjectTypeMetadataModelHelper.get(objectTypeName)?.udmProperties,
                    property => property.id === rule.propertyId),
            [objectTypeName, rule.propertyId]);

    useChangeEffect(
        () => {
            if (operator === Contract.UdmQueryRuleOperator.In && _.isString(valueOrValues)) {
                setValueOrValues([]);
            } else if (operator !== Contract.UdmQueryRuleOperator.In && !_.isString(valueOrValues)) {
                setValueOrValues("");
            }
        },
        [operator, valueOrValues]);

    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.udmObjectPropertyFilter.infraString",
            () => ({
                empty: "No items",
                options: "{{count}} options",
                search: "Search"
            }));

    async function fetchItems(searchText: Optional<string>, skip: number) {
        const { hasMore, values } = 
            await UdmController.getPropertyValuePage(
                new Contract.UdmControllerGetPropertyValuePageRequest(
                    30,
                    objectTypeName,
                    propertyId,
                    searchText || undefined,
                    skip));

        return new PagedDropdownPage(
            hasMore,
            values);
    }

    return (
        <Filter
            initialOperator={operator}
            operators={operators}
            propertyId={propertyId}
            ruleId={rule.id!}
            selectionViewValue={
                _.isEmpty(valueOrValues)
                    ? <Fragment/>
                    : <Typography noWrap={true}>
                        {_.isArray(valueOrValues)
                            ? _.size(valueOrValues) > 3
                                ? localization.options({ count: _.size(valueOrValues) })
                                : localizeList(valueOrValues, false)
                            : valueOrValues}
                    </Typography>}
            values={valueOrValues}
            onClearClicked={onClearClicked}
            onOperatorChange={setOperator}>
            {operator === Contract.UdmQueryRuleOperator.In
                ? <ListFilterPopover
                    emptyText={localization.empty()}
                    getItemPage={fetchItems}
                    objectProperty={objectProperty}
                    objectTypeName={objectTypeName}
                    setValues={setValueOrValues as React.Dispatch<React.SetStateAction<string[]>>}
                    values={valueOrValues as string[]}/>
                : <TextField
                    label={localization.search()}
                    size="small"
                    value={valueOrValues}
                    variant="outlined"
                    onChange={event => setValueOrValues(event.target.value ?? "")}/>}
        </Filter>);
}

const operators = [
    Contract.UdmQueryRuleOperator.StringStartsWith,
    Contract.UdmQueryRuleOperator.StringContains,
    Contract.UdmQueryRuleOperator.Equals,
    Contract.UdmQueryRuleOperator.StringEndsWith,
    Contract.UdmQueryRuleOperator.In
];