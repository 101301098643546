import { Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, Link, useLocalization } from "@infrastructure";
import { BuiltInRiskPolicyItem, Contract, CustomerConsoleAppUrlHelper, KubernetesAdmissionControllerRiskPolicyItem, LicensingHelper, RiskController, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem, StorageHelper, useComplianceTranslator, useDeliveryTranslator, useFileNameScopeTranslator, useRiskPolicyTranslator, useScopeNameTranslator, useTheme } from "../../../../../../../../../common";
import { useGetRiskPolicyItemBuiltInComplianceTypes } from "../../../../../../Compliance/hooks";
import { CustomerSideViewItemType } from "../../../../../../SideView";
import { ActionsCell, useRiskPoliciesContext } from "../../../../../components";
import { RiskPolicyHelper } from "../../../../../utilities";
import { useKubernetesAdmissionControllerRiskPolicyEffectTranslator } from "../../../../useKubernetesAdmissionControllerRiskPolicyEffectTranslator";
import { PolicyTableDefinition } from "../../../useDefinition";
import { CommonTableColumnId, useCommonRiskPolicyItemTableItems } from "../../useCommonRiskPolicyItemTableItems";

export function useKubernetesAdmissionControllerRiskPoliciesBuiltInDefinition(scopeId: string): PolicyTableDefinition {
    const { scopeNodeMap } = useRiskPoliciesContext();
    const scopeNode = scopeNodeMap[scopeId];
    const commonRiskPolicyItemTableItems =
        useCommonRiskPolicyItemTableItems(
            scopeId,
            RiskPoliciesType.KubernetesAdmissionController);
    const getRiskPolicyItemBuiltInComplianceTypes = useGetRiskPolicyItemBuiltInComplianceTypes();

    const complianceTranslator = useComplianceTranslator();
    const deliveryTranslator = useDeliveryTranslator();
    const fileNameScopeTranslator = useFileNameScopeTranslator();
    const riskPolicyEffectTranslator = useKubernetesAdmissionControllerRiskPolicyEffectTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useDefinition.hooks.kubernetesAdmissionController.hooks.useKubernetesAdmissionControllerRiskPoliciesBuiltInDefinition",
            () => ({
                columns: {
                    effect: "Effect",
                    lastViolationEventTime: {
                        empty: "-",
                        time: "{{lastViolationEventTime | TimeFormatter.humanizeDuration}} ago",
                        title: "Violation Time"
                    },
                    status: {
                        false: "Disabled",
                        inheritedFalse: "Disabled (Inherited)",
                        inheritedTrue: "Enabled (Inherited)",
                        title: "Status",
                        true: "Enabled"
                    }
                },
                csvExportFileName: "Kubernetes_Admission_Controller_Built_In_Policies_{{translatedScopeName}}"
            }));

    const getCsvItemPage =
        (items: RiskPolicyItem[]) =>
            async () => {
                const { riskPolicyConfigurationTypeNameToDataMap: builtInRiskPolicyConfigurationTypeNameToDataMap } =
                    await RiskController.getBuiltInRiskPolicyModels(
                        new Contract.RiskControllerGetBuiltInKubernetesAdmissionControllerRiskPolicyModelsRequest(
                            _.map(
                                items,
                                item => item.riskPolicyConfigurationTypeName),
                            scopeId));
                const riskPolicyConfigurationTypeNameToScopeIdToParentRiskPolicyModelMap =
                    _.mapValues(
                        builtInRiskPolicyConfigurationTypeNameToDataMap,
                        builtInRiskPolicyData =>
                            _.keyBy(
                                builtInRiskPolicyData.parentRiskPolicyModels,
                                parentRiskPolicyModel => parentRiskPolicyModel.riskPolicyConfiguration.scopeId));

                function getEnabledInheritedScopeName(riskPolicyConfigurationTypeName: string) {
                    for (const parentScopeId of scopeNode.parentScopeIds) {
                        if (!_.isNil(riskPolicyConfigurationTypeNameToScopeIdToParentRiskPolicyModelMap[riskPolicyConfigurationTypeName][parentScopeId].riskPolicyConfiguration.enabled)) {
                            return scopeNameTranslator(parentScopeId);
                        }
                    }

                    return undefined;
                }

                return _(items as KubernetesAdmissionControllerRiskPolicyItem[]).
                    map(
                        item => {
                            const complianceIdOrSectionTypes =
                                LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                                    ? _(getRiskPolicyItemBuiltInComplianceTypes(item)).
                                        as<string>().
                                        concat(item.customComplianceIds).
                                        value()
                                    : undefined;
                            return {
                                /* eslint-disable sort-keys-fix/sort-keys-fix */
                                Name: riskPolicyTranslator(item.riskPolicyConfigurationTypeName).title,
                                Description:
                                riskPolicyTranslator(
                                    item.riskPolicyConfigurationTypeName,
                                    "text").
                                    description,
                                Compliance:
                                    !LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise) ||
                                    _.isNil(complianceIdOrSectionTypes)
                                        ? undefined
                                        : _.isEmpty(complianceIdOrSectionTypes)
                                            ? "-"
                                            : _(complianceIdOrSectionTypes).
                                                map(complianceIdOrSectionType => complianceTranslator(complianceIdOrSectionType).title).
                                                join("\n"),
                                Effect: riskPolicyEffectTranslator(item.effect),
                                Status:
                                    item.enabled
                                        ? item.enabledInherited
                                            ? localization.columns.status.inheritedTrue({
                                                scope: getEnabledInheritedScopeName(item.riskPolicyConfigurationTypeName)
                                            })
                                            : localization.columns.status.true()
                                        : item.enabledInherited
                                            ? localization.columns.status.inheritedFalse({
                                                scope: getEnabledInheritedScopeName(item.riskPolicyConfigurationTypeName)
                                            })
                                            : localization.columns.status.false(),
                                "Last Violation Event Time":
                                    _.isNil(item.lastViolationEventTime)
                                        ? localization.columns.lastViolationEventTime.empty()
                                        : localization.columns.lastViolationEventTime.time({ lastViolationEventTime: item.lastViolationEventTime }),
                                Actions:
                                    _.isEmpty(item.deliveries)
                                        ? "-"
                                        : _(item.deliveries).
                                            map(({ delivery }) => deliveryTranslator(delivery.typeName as Contract.DeliveryDerivedTypeNames, "title").text).
                                            join("\n")
                                /* eslint-enable sort-keys-fix/sort-keys-fix */
                            };
                        }).
                    orderBy([
                        csvItem => csvItem.Name
                    ]).
                    value();
            };

    const theme = useTheme();
    return useMemo(
        () =>
            new PolicyTableDefinition(
                columnIdToItemValuesMap =>
                    _<ReactNode>([]).
                        concat(commonRiskPolicyItemTableItems[CommonTableColumnId.Name].getColumn(columnIdToItemValuesMap[CommonTableColumnId.Name])).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                            commonRiskPolicyItemTableItems.compliances.getColumn(columnIdToItemValuesMap[CommonTableColumnId.Compliances])).
                        concat([
                            <DataTableColumn
                                cellSx={{ minWidth: theme.spacing(40) }}
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <EnumValuesFilter
                                                enumType={Contract.KubernetesAdmissionControllerRiskPolicyEffect}
                                                enumTypeTranslator={riskPolicyEffectTranslator}
                                                placeholder={localization.columns.effect()}/>
                                    }
                                }}
                                id={TableColumnId.Effect}
                                key={TableColumnId.Effect}
                                render={
                                    ({ item }: DataTableColumnRenderProps<KubernetesAdmissionControllerRiskPolicyItem>) =>
                                        <Typography noWrap={true}>
                                            {riskPolicyEffectTranslator(item.effect)}
                                        </Typography>}
                                title={localization.columns.effect()}/>,
                            commonRiskPolicyItemTableItems.status.getColumn(),
                            <DataTableColumn
                                cellSx={{ minWidth: theme.spacing(40) }}
                                id={TableColumnId.LastViolationEventTime}
                                key={TableColumnId.LastViolationEventTime}
                                render={
                                    ({ item }: DataTableColumnRenderProps<KubernetesAdmissionControllerRiskPolicyItem>) =>
                                        <Link
                                            disabled={_.isNil(item.lastViolationEventTime)}
                                            urlOrGetUrl={
                                                CustomerConsoleAppUrlHelper.getKubernetesAdmissionControllerEventRelativeUrl(
                                                    undefined,
                                                    item.riskPolicyConfigurationTypeName)}>
                                            {_.isNil(item.lastViolationEventTime)
                                                ? localization.columns.lastViolationEventTime.empty()
                                                : localization.columns.lastViolationEventTime.time({ lastViolationEventTime: item.lastViolationEventTime })}
                                        </Link>}
                                sortOptions={{ type: DataTableSortType.Date }}
                                title={localization.columns.lastViolationEventTime.title()}/>,
                            <DataTableColumn
                                disableAction={true}
                                headerSx={{ minWidth: theme.spacing(5) }}
                                id={TableColumnId.Actions}
                                key={TableColumnId.Actions}
                                orderable={false}
                                render={
                                    ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                        <ActionsCell
                                            item={item}
                                            riskPoliciesType={RiskPoliciesType.KubernetesAdmissionController}
                                            scopeId={scopeId}/>}
                                resizable={false}
                                selectorOptions={{ disabled: true }}
                                sortOptions={{ enabled: false }}/>
                        ]).
                        value(),
                {
                    [TableColumnId.Effect]: item => riskPolicyEffectTranslator((item as KubernetesAdmissionControllerRiskPolicyItem).effect),
                    [TableColumnId.LastViolationEventTime]: item => (item as KubernetesAdmissionControllerRiskPolicyItem).lastViolationEventTime,
                    ...commonRiskPolicyItemTableItems.compliances.getValueMap(),
                    ...commonRiskPolicyItemTableItems.name.getValueMap(),
                    ...commonRiskPolicyItemTableItems.status.getValueMap(),
                    ...commonRiskPolicyItemTableItems.tenantsType.getValueMap()
                },
                item => RiskPolicyHelper.getSideViewItemId(item.riskPolicyConfigurationTypeName, scopeId, RiskPoliciesType.KubernetesAdmissionController),
                {
                    hashRouteTemplate: `${CustomerSideViewItemType.RiskPolicies}/{itemId}`
                },
                {
                    columnOrder: StorageHelper.customerKubernetesAdmissionControllerRiskPoliciesTableColumnOrder(RiskPoliciesView.BuiltIn),
                    columnSelector: StorageHelper.customerKubernetesAdmissionControllerRiskPoliciesTableColumnSelector(RiskPoliciesView.BuiltIn),
                    tableFilters: StorageHelper.customerKubernetesAdmissionControllerRiskPoliciesTableFilters(RiskPoliciesView.BuiltIn)
                },
                undefined,
                {
                    getCsvItemPage,
                    prefix: localization.csvExportFileName({ translatedScopeName: fileNameScopeTranslator(scopeId) })
                },
                undefined,
                {
                    defaultSortColumnIds: [CommonTableColumnId.Name],
                    rowOptions: {
                        getUrl: (item: any) => CustomerConsoleAppUrlHelper.getRiskPoliciesProfileHashUrl(RiskPoliciesType.KubernetesAdmissionController, item.riskPolicyConfigurationTypeName, scopeId)
                    }
                }),
        [commonRiskPolicyItemTableItems, scopeId]);
}

export enum TableColumnId {
    Actions = "actions",
    Effect = "effect",
    LastViolationEventTime = "lastViolationEventTime"
}