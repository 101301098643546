import { Message, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Contract, CustomRiskPolicy, riskPolicyModelStore } from "../../../../../../../../../common";
import { RiskDefinition, RiskDefinitionSection } from "../../../utilities";

export function useCommonCustomSectionsAndDescriptionDefinition(
    description: string,
    riskModel: Contract.RiskModel,
    variant: "events" | "violations",
    variantSectionElement: ReactNode): RiskDefinition {
    const riskPolicyModel = riskPolicyModelStore.useGet(riskModel.risk.policyId);
    const riskPolicyConfiguration = riskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useCommonCustomSectionsAndDescriptionDefinition",
            () => ({
                description: {
                    events: "{{description}}",
                    violations: "{{description}} which does not comply with the defined custom policy"
                },
                sections: {
                    details: {
                        description: "**Policy description:** {{policyDescription}}",
                        name: "**Policy name:** {{policyName}}",
                        template: "**Policy template:** {{policyTemplate}}",
                        title: "Details",
                        violations: "**Violations:** {{violations}}",
                        warning: "The template was recently updated. The displayed violations might not match the current template and will be updated shortly."
                    },
                    events: "Events",
                    limitedResults: "Limited to first {{limitSize}} results",
                    violations: "Violations"
                }
            }));

    return useMemo(
        () => ({
            description: localization.description[variant]({ description }),
            sections: [
                new RiskDefinitionSection(
                    <Stack spacing={1}>
                        {riskModel.risk.systemUpdateTime < riskPolicyConfiguration.updateTime &&
                            <Message
                                level="infoWarning"
                                title={localization.sections.details.warning()}/>}
                        <Steps>
                            {_<string>([]).
                                concat(localization.sections.details.name({ policyName: riskPolicyConfiguration.name })).
                                concatIf(
                                    !_.isNil(riskPolicyConfiguration.description),
                                    () => localization.sections.details.description({ policyDescription: riskPolicyConfiguration.description })).
                                concat(
                                    localization.sections.details.template({
                                        policyTemplate:
                                            <CustomRiskPolicy
                                                riskPolicyConfiguration={riskPolicyConfiguration}
                                                riskPolicyConfigurationTypeName={riskPolicyConfiguration.typeName}
                                                scopeId={riskPolicyConfiguration.scopeId}/>
                                    })).
                                concatIf(
                                    variant === "violations",
                                    () =>
                                        localization.sections.details.violations({
                                            violations:
                                                <Typography
                                                    component="span"
                                                    sx={{
                                                        "&:first-letter": {
                                                            textTransform: "capitalize"
                                                        },
                                                        display: "inline-block"
                                                    }}>
                                                    {description}
                                                </Typography>
                                        })).
                                value()}
                        </Steps>
                    </Stack>,
                    localization.sections.details.title()),
                new RiskDefinitionSection(
                    variantSectionElement,
                    localization.sections[variant](),
                    {
                        actionsElement:
                            variant === "violations" &&
                            riskModel.risk.limitedResults &&
                            <Message
                                level="info"
                                title={localization.sections.limitedResults({ limitSize: riskModel.risk.items.length })}
                                variant="minimal"/>
                    }
                )
            ]
        }),
        [riskModel, variantSectionElement, description, variant, riskPolicyConfiguration]);
}