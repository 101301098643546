import { DialogMenuItem, Link, NumberFormatter, StringHelper, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements, useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "..";
import { Contract, EntityController, entityModelStore, InfoCard, InfoItem, useTenantNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { AwsActionHelper, useAwsCloudTrailTrailEventSelectorTypeTranslator } from "../../../../../../../../../../tenants";
import { useAwsS3BucketObjectOwnershipTranslator, useAwsS3BucketServerSideEncryptionTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsIamPolicyDocument } from "../../components";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../useDefaultDataAnalysisResourceInfoItemElements";

export function useAwsS3BucketDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const bucket = resourceModel.entity as Contract.AwsS3Bucket;
    const bucketModel = resourceModel as Contract.AwsS3BucketModel;
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            bucketModel,
            dataAnalysis => dataAnalysis.objectStoreScanEnabled);
    const tenantEntityPublicAccessPolicy = (entityModelStore.useGet(bucket.tenantId).entity as Contract.AwsTenantEntity).s3!.publicAccessPolicy;
    const [cloudFrontDistributionIds] =
        useExecuteOperation(
            [useAwsS3BucketDefinition, bucketModel.id],
            async () => {
                const getAwsDistributionIdsResponse =
                    await EntityController.getAwsDistributionIds(new Contract.EntityControllerGetAwsDistributionIdsRequest(bucketModel.id));
                const getAwsLoggingBucketDistributionIdsResponse =
                    await EntityController.getAwsLoggingBucketDistributionIds(new Contract.EntityControllerGetAwsLoggingBucketDistributionIdsAsyncRequest(bucketModel.id));
                return _(getAwsDistributionIdsResponse.distributionIds).
                    concat(getAwsLoggingBucketDistributionIdsResponse.distributionIds).
                    uniq().
                    value();
            });

    const cloudTrailTrailEventSelectorTypeTranslator = useAwsCloudTrailTrailEventSelectorTypeTranslator();
    const s3BucketObjectOwnershipTranslator = useAwsS3BucketObjectOwnershipTranslator();
    const s3BucketServerSideEncryptionTypeTranslator = useAwsS3BucketServerSideEncryptionTypeTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsS3BucketDefinition",
            () => ({
                info: {
                    cards: {
                        accessControlList: {
                            disabled: "ACLs are disabled, and the bucket owner automatically owns and has full control over every object in the bucket",
                            fields: {
                                logDeliveryGroupAccess: {
                                    false: "Not enabled",
                                    title: "S3 Log Delivery Group",
                                    true: "Enabled"
                                },
                                otherTenantAccessAccounts: {
                                    disabled: "Not enabled",
                                    title: "Access for other AWS accounts"
                                },
                                publicAccessPermissionActions: {
                                    disabled: "Not enabled",
                                    title: "Public Access"
                                },
                                tenantAccess: {
                                    false: "Not enabled",
                                    title: "Access for bucket owner",
                                    true: "Enabled"
                                }
                            },
                            title: "ACL"
                        },
                        blockPublicAccess: {
                            fields: {
                                blockAnyAcls: "Block any ACLs",
                                blockAnyPolicy: "Block any policy",
                                blockNewAcls: "Block new ACLs",
                                blockNewPolicy: "Block new policy",
                                disabled: "Not enabled",
                                enabled: "Enabled",
                                enabledInherited: "Enabled (inherited from account settings)"
                            },
                            title: "Block Public Access"
                        }

                    },
                    items: {
                        cloudFrontDistributions: "CloudFront Distributions",
                        dnsZoneIds: "DNS Zones",
                        eventEnabled: {
                            false: "Not enabled",
                            title: "Server Access Logging",
                            true: "Enabled"
                        },
                        objectCount: "Object Count",
                        objectEventSelectorType: "Object-Level Logging",
                        objectLifecycleConfigurationEnabled: {
                            false: "Disabled",
                            title: "Object Lifecycle Configurations",
                            true: "Enabled",
                            unknown: "Unknown"
                        },
                        objectLockEnabled: {
                            false: "Not enabled",
                            title: "Object Lock",
                            true: "Enabled"
                        },
                        objectMfaDeleteEnabled: {
                            false: "Not enabled",
                            title: "Object MFA Delete",
                            true: "Enabled"
                        },
                        objectOwnership: "Object Ownership",
                        objectVersioningEnabled: {
                            false: "Not enabled",
                            title: "Object Versioning",
                            true: "Enabled"
                        },
                        serverSideEncryptionKeyId: "Encryption Key",
                        serverSideEncryptionType: "Server-Side Encryption",
                        storageSize: "Size",
                        url: "URL",
                        website: {
                            false: "Disabled",
                            title: "Static Website Hosting",
                            true: "Enabled"
                        }
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument policyDocument={bucket.policyDocument}/>,
                localization.tabs.policyDocument(),
                "policyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="storageSize"
                        location="all"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(bucket.storageSize)}/>,
                    <InfoItem
                        key="objectCount"
                        title={localization.info.items.objectCount()}
                        value={NumberFormatter.humanize(bucket.objectCount)}/>,
                    <InfoItem
                        key="url"
                        location="all"
                        title={localization.info.items.url()}
                        value={
                            _.isNil(bucket.url)
                                ? undefined
                                : <Link
                                    urlOrGetUrl={bucket.url}
                                    variant="external">
                                    {bucket.url}
                                </Link>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={bucketModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <InfoItem
                        key="serverSideEncryptionType"
                        title={localization.info.items.serverSideEncryptionType()}
                        value={s3BucketServerSideEncryptionTypeTranslator(bucket.serverSideEncryptionType)}/>,
                    <InfoItem
                        key="eventEnabled"
                        title={localization.info.items.eventEnabled.title()}
                        value={
                            bucket.eventEnabled
                                ? localization.info.items.eventEnabled.true()
                                : localization.info.items.eventEnabled.false()}/>,
                    <InfoItem
                        key="objectEventSelectorType"
                        title={localization.info.items.objectEventSelectorType()}
                        value={cloudTrailTrailEventSelectorTypeTranslator(bucketModel.objectEventSelectorType)}/>,
                    <InfoItem
                        key="objectLifecycleConfigurationEnabled"
                        title={localization.info.items.objectLifecycleConfigurationEnabled.title()}
                        value={
                            _.isNil(bucket.objectLifecycleConfigurationEnabled)
                                ? localization.info.items.objectLifecycleConfigurationEnabled.unknown()
                                : bucket.objectLifecycleConfigurationEnabled
                                    ? localization.info.items.objectLifecycleConfigurationEnabled.true()
                                    : localization.info.items.objectLifecycleConfigurationEnabled.false()}/>,
                    <InfoItem
                        key="objectVersioningEnabled"
                        title={localization.info.items.objectVersioningEnabled.title()}
                        value={
                            bucket.objectVersioningEnabled
                                ? localization.info.items.objectVersioningEnabled.true()
                                : localization.info.items.objectVersioningEnabled.false()}/>,
                    <InfoItem
                        key="objectMfaDeleteEnabled"
                        title={localization.info.items.objectMfaDeleteEnabled.title()}
                        value={
                            bucket.objectMfaDeleteEnabled
                                ? localization.info.items.objectMfaDeleteEnabled.true()
                                : localization.info.items.objectMfaDeleteEnabled.false()}/>,
                    <InfoItem
                        key="objectLockEnabled"
                        title={localization.info.items.objectLockEnabled.title()}
                        value={
                            bucket.objectLockEnabled
                                ? localization.info.items.objectLockEnabled.true()
                                : localization.info.items.objectLockEnabled.false()}/>,
                    <InfoItem
                        key="objectOwnership"
                        location="all"
                        title={localization.info.items.objectOwnership()}
                        value={s3BucketObjectOwnershipTranslator(bucket.objectOwnership)}/>,
                    <InfoItem
                        key="website"
                        title={localization.info.items.website.title()}
                        value={
                            bucket.websiteEnabled
                                ? localization.info.items.website.true()
                                : localization.info.items.website.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cloudFrontDistributionIds}
                        entityTypeName={Contract.TypeNames.AwsCloudFrontDistribution}
                        key="cloudFrontDistributions"
                        title={localization.info.items.cloudFrontDistributions()}/>,
                    ...defaultDataAnalysisResourceInfoItemElements
                ]}
                options={options?.infoOptions}>
                <InfoCard
                    columns={true}
                    title={localization.info.cards.accessControlList.title()}>
                    {bucket.objectOwnership === Contract.AwsS3BucketObjectOwnership.BucketOwnerEnforced
                        ? localization.info.cards.accessControlList.disabled()
                        : [
                            <InfoItem
                                key="tenantAccessTitle"
                                title={localization.info.cards.accessControlList.fields.tenantAccess.title()}
                                value={
                                    bucket.accessControlList.tenantAccess
                                        ? localization.info.cards.accessControlList.fields.tenantAccess.true()
                                        : localization.info.cards.accessControlList.fields.tenantAccess.false()}/>,
                            <VerticalTopItemsInfoItem
                                emptyValue={localization.info.cards.accessControlList.fields.otherTenantAccessAccounts.disabled()}
                                items={
                                    _(bucketModel.accessControlListTenantCanonicalIdToIdMap).
                                        toPairs().
                                        orderBy(
                                            [
                                                ([, tenantId]) => !_.isNil(tenantId),
                                                ([canonicalId, tenantId]) =>
                                                    !_.isNil(tenantId)
                                                        ? tenantNameTranslator(tenantId)
                                                        : canonicalId
                                            ],
                                            [
                                                "desc",
                                                "asc"
                                            ]).
                                        map(
                                            ([canonicalId, tenantId]) =>
                                                !_.isNil(tenantId)
                                                    ? tenantNameTranslator(tenantId, { includeRawId: true })
                                                    : canonicalId
                                        ).
                                        value()}
                                key="otherTenantAccessAccountsTitle"
                                title={localization.info.cards.accessControlList.fields.otherTenantAccessAccounts.title()}/>,
                            <VerticalTopItemsInfoItem
                                emptyValue={localization.info.cards.accessControlList.fields.publicAccessPermissionActions.disabled()}
                                getSortValue={publicAccessPermissionAction => StringHelper.getSortValue(publicAccessPermissionAction)!}
                                items={
                                    _.map(
                                        bucket.accessControlList.publicAccessPermissionActions,
                                        publicAccessPermissionAction => AwsActionHelper.getName(publicAccessPermissionAction))}
                                key="publicAccessPermissionActionsTitle"
                                title={localization.info.cards.accessControlList.fields.publicAccessPermissionActions.title()}/>,
                            <InfoItem
                                key="logDeliveryGroupAccessTitle"
                                title={localization.info.cards.accessControlList.fields.logDeliveryGroupAccess.title()}
                                value={
                                    bucket.accessControlList.logDeliveryGroupAccess
                                        ? localization.info.cards.accessControlList.fields.logDeliveryGroupAccess.true()
                                        : localization.info.cards.accessControlList.fields.logDeliveryGroupAccess.false()}/>
                        ]}
                </InfoCard>
                <InfoCard
                    columns={true}
                    title={localization.info.cards.blockPublicAccess.title()}>
                    <InfoItem
                        title={localization.info.cards.blockPublicAccess.fields.blockNewAcls()}
                        value={
                            tenantEntityPublicAccessPolicy.blockNewAcls
                                ? localization.info.cards.blockPublicAccess.fields.enabledInherited()
                                : bucket.publicAccessPolicy.blockNewAcls
                                    ? localization.info.cards.blockPublicAccess.fields.enabled()
                                    : localization.info.cards.blockPublicAccess.fields.disabled()}/>
                    <InfoItem
                        title={localization.info.cards.blockPublicAccess.fields.blockAnyAcls()}
                        value={
                            tenantEntityPublicAccessPolicy.blockAnyAcls
                                ? localization.info.cards.blockPublicAccess.fields.enabledInherited()
                                : bucket.publicAccessPolicy.blockAnyAcls
                                    ? localization.info.cards.blockPublicAccess.fields.enabled()
                                    : localization.info.cards.blockPublicAccess.fields.disabled()}/>
                    <InfoItem
                        title={localization.info.cards.blockPublicAccess.fields.blockNewPolicy()}
                        value={
                            tenantEntityPublicAccessPolicy.blockNewPolicy
                                ? localization.info.cards.blockPublicAccess.fields.enabledInherited()
                                : bucket.publicAccessPolicy.blockNewPolicy
                                    ? localization.info.cards.blockPublicAccess.fields.enabled()
                                    : localization.info.cards.blockPublicAccess.fields.disabled()}/>
                    <InfoItem
                        title={localization.info.cards.blockPublicAccess.fields.blockAnyPolicy()}
                        value={
                            tenantEntityPublicAccessPolicy.blockAnyPolicy
                                ? localization.info.cards.blockPublicAccess.fields.enabledInherited()
                                : bucket.publicAccessPolicy.blockAnyPolicy
                                    ? localization.info.cards.blockPublicAccess.fields.enabled()
                                    : localization.info.cards.blockPublicAccess.fields.disabled()}/>
                </InfoCard>
            </Info>,
        topbarMenuItems: _.filter([getDataAnalysisResourcePriorityScanRequestDialogMenuItem()]) as DialogMenuItem[]
    });
}