import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, TimeSpanFormatter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useAzureCommonResourceDefinition } from "./useAzureCommonResourceDefinition";

export function useAzureAuthorizationRoleManagementPolicyAssignmentDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAzureCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureAuthorizationRoleManagementPolicyAssignmentDefinition",
            () => ({
                columns: {
                    approvalRequired: {
                        false: "No",
                        title: "Requires Approval",
                        true: "Yes"
                    },
                    approverPrincipalIds: "Approvers",
                    maxDuration: "Maximum Duration",
                    mfaEnabled: {
                        false: "Not enabled",
                        title: "MFA",
                        true: "Enabled"
                    },
                    roleDefinitionId: "Role",
                    scopeResourceId: "Scope"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.azureTenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel) => ({
                            [localization.columns.mfaEnabled.title()]:
                                item.mfaEnabled
                                    ? localization.columns.mfaEnabled.true()
                                    : localization.columns.mfaEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.mfaEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.mfaEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.mfaEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentMfaEnabled}
                itemProperty={(item: Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel) =>
                    item.mfaEnabled
                        ? localization.columns.mfaEnabled.true()
                        : localization.columns.mfaEnabled.false()}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentMfaEnabled}
                sortOptions={{ enabled: false }}
                title={localization.columns.mfaEnabled.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel>(
                        Contract.TypeNames.AzureAuthorizationRoleDefinition,
                        item => [item.roleDefinitionId],
                        localization.columns.roleDefinitionId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentRoleDefinition)}
                                placeholder={localization.columns.roleDefinitionId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentRoleDefinition}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentRoleDefinition}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleDefinitionId}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                            entityVariant="iconText"/>}
                title={localization.columns.roleDefinitionId()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel) => ({
                            [localization.columns.maxDuration()]: TimeSpanFormatter.dayHourMinuteSecond((item.entity as Contract.AzureAuthorizationRoleManagementPolicyAssignment).activation.maxDuration)
                        })
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentMaxDuration}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        TimeSpanFormatter.dayHourMinuteSecond((item.entity as Contract.AzureAuthorizationRoleManagementPolicyAssignment).activation.maxDuration)
                }
                key={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentMaxDuration}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.maxDuration()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel>(
                        Contract.TypeNames.AzureScopeResource,
                        item => [item.scopeResourceId],
                        localization.columns.scopeResourceId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentScopeResource)}
                                placeholder={localization.columns.scopeResourceId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentScopeResource}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentScopeResource}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.scopeResourceId}
                            entityTypeName={Contract.TypeNames.AzureScopeResource}
                            entityVariant="iconText"/>}
                title={localization.columns.scopeResourceId()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel) => ({
                            [localization.columns.approvalRequired.title()]:
                                (item.entity as Contract.AzureAuthorizationRoleManagementPolicyAssignment).activation.approvalRequired
                                    ? localization.columns.approvalRequired.true()
                                    : localization.columns.approvalRequired.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.approvalRequired.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.approvalRequired.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.approvalRequired.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentApprovalRequired}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.AzureAuthorizationRoleManagementPolicyAssignment).activation.approvalRequired
                            ? localization.columns.approvalRequired.true()
                            : localization.columns.approvalRequired.false()}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentApprovalRequired}
                title={localization.columns.approvalRequired.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel>(
                        Contract.TypeNames.AadDirectoryPrincipal,
                        item => (item.entity as Contract.AzureAuthorizationRoleManagementPolicyAssignment).activation.approverPrincipalIds,
                        localization.columns.approverPrincipalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentApproverPrincipals)}
                                placeholder={localization.columns.approverPrincipalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentApproverPrincipals}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentApproverPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureAuthorizationRoleManagementPolicyAssignment).activation.approverPrincipalIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.approverPrincipalIds()}/>,
            commonResourceDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureAuthorizationRoleManagementPolicyAssignmentRequest(
                new Contract.EntityControllerGetEntityModelPageAzureAuthorizationRoleManagementPolicyAssignmentRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentApprovalRequired]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentApproverPrincipals]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentMfaEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentRoleDefinition]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleManagementPolicyAssignmentScopeResource])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(undefined)));
}