import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../..";
import { TypeHelper } from "../../../../utilities";
import { useAadDefinition, useAwsDefinition, useAzureDefinition, useGciDefinition, useGcpDefinition } from "./hooks";

export function useDefinition(change: Contract.Change, entityLinkDisabled?: boolean) {
    const useDefinition =
        useMemo(
            () => {
                if (TypeHelper.extendOrImplement(change.typeName, Contract.TypeNames.AadChange)) {
                    return useAadDefinition;
                } else if (TypeHelper.extendOrImplement(change.typeName, Contract.TypeNames.AwsChange)) {
                    return useAwsDefinition;
                } else if (TypeHelper.extendOrImplement(change.typeName, Contract.TypeNames.AzureChange)) {
                    return useAzureDefinition;
                } else if (TypeHelper.extendOrImplement(change.typeName, Contract.TypeNames.GciChange)) {
                    return useGciDefinition;
                } else if (TypeHelper.extendOrImplement(change.typeName, Contract.TypeNames.GcpChange)) {
                    return useGcpDefinition;
                } else {
                    throw new UnexpectedError(change.typeName);
                }
            },
            []);

    return useDefinition(change, entityLinkDisabled);
}