import { Typography } from "@mui/material";
import React from "react";
import { AwsUpsertPolicyChangeInfo } from ".";
import { useTheme } from "../../../../..";
import { Entity } from "../../../../Entity";
import { useRiskResolutionAutomationContext } from "../../../utilities";

type AwsUpsertResourcePolicyChangeProps = {
    updatedRawDocument: string;
    updateRawDocument: (rawDocument: string) => void;
};

export function AwsUpsertResourcePolicyChangeInfo({ updatedRawDocument, updateRawDocument }: AwsUpsertResourcePolicyChangeProps) {
    const { riskedEntityModel } = useRiskResolutionAutomationContext();
    const theme = useTheme();
    return (
        <AwsUpsertPolicyChangeInfo
            titleElement={
                <Typography
                    sx={{
                        borderBottom: theme.border.alternate,
                        padding: theme.spacing(2)
                    }}
                    variant="h2">
                    <Entity
                        entityIdOrModel={riskedEntityModel}
                        linkOptions={{ disabled: true }}
                        sxOptions={{
                            icon: { fontSize: "30px" },
                            subtitle: { color: theme.palette.text.primary },
                            title: {
                                fontSize: "inherit",
                                fontWeight: "inherit"
                            }
                        }}
                        variant="iconTextTenant"/>
                </Typography>}
            updatedRawDocument={updatedRawDocument}
            updateRawDocument={updateRawDocument}/>);
}