﻿import { NumberFormatter, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpBigQueryDatasetTableDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const bigQueryDatasetTableModel = scopeResourceModel as Contract.GcpBigQueryDatasetTableModel;
    const bigQueryDatasetTable = bigQueryDatasetTableModel.entity as Contract.GcpBigQueryDatasetTable;
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpBigQueryDatasetTableDefinition",
            () => ({
                info: {
                    items: {
                        datasetId: "BigQuery Dataset",
                        expirationTime: {
                            empty: "Never",
                            title: "Expiration"
                        },
                        size: "Size"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={bigQueryDatasetTableModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    <InfoItem
                        key="size"
                        location="all"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(bigQueryDatasetTable.size)}/>,
                    <InfoItem
                        emptyValue={localization.info.items.expirationTime.empty()}
                        key="expirationTime"
                        title={localization.info.items.expirationTime.title()}
                        value={
                            _.isNil(bigQueryDatasetTable.expirationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(bigQueryDatasetTable.expirationTime)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={bigQueryDatasetTable.datasetId}
                        entityTypeName={Contract.TypeNames.GcpBigQueryDataset}
                        key="datasetId"
                        title={localization.info.items.datasetId()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}