import { Action0, Action1, DirectedGraphModelNode, Optional, UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { FunctionComponent, MutableRefObject, ReactNode, Ref, useMemo } from "react";
import { AccessView } from "../..";
import { Contract } from "../../../../controllers";
import { useAwsDefinition, useAzureDefinition, useGcpDefinition } from "./hooks";

export function useDefinition(entityTypeName: string, tenantType: Contract.TenantType) {
    const useDefinition =
        useMemo(
            () => {
                switch (tenantType) {
                    case Contract.TenantType.Aws:
                        return useAwsDefinition;
                    case Contract.TenantType.Azure:
                        return useAzureDefinition;
                    case Contract.TenantType.Gcp:
                        return useGcpDefinition;
                    default:
                        throw new UnexpectedError("tenantType", tenantType);
                }
            },
            []);

    return useDefinition(entityTypeName);
}

export class AccessDefinition {
    constructor(
        public csvExportFileNamePrefix: string,
        public entitiesPermissionActions: AccessDefinitionEntitiesPermissionActions,
        public getAccessGraph: AccessDefinitionGetAccessGraph,
        public getAccessListPermissionActions: AccessDefinitionGetAccessListPermissionActions,
        public getAccessReportRequestDefinition: AccessDefinitionGetAccessReportRequestDefinition,
        public getFilters: AccessDefinitionGetFilters,
        public graphInitialDirection: Contract.EntityAccessDirection,
        public graphPermissionPath: AccessDefinitionGraphPermissionPath,
        public toolbarComponent: FunctionComponent<AccessDefinitionToolbarProps>,
        public getDirectedGraphGroupNodeTooltip?: (entityModel: Contract.EntityModel) => Optional<string>) {
    }
}

type AccessDefinitionEntitiesPermissionActions = {
    entityTypeNames: AccessDefinitionEntitiesPermissionActionsEntityTypeNames;
    getPermissionActionName: (permissionAction: string, serviceName: string) => string;
    getServiceName: (entityModel: Contract.EntityModel) => string;
    permitterComponent: FunctionComponent<AccessDefinitionEntitiesPermissionActionsPermittersProps>;
};

export type AccessDefinitionEntitiesPermissionActionsPermittersProps = {
    actionData: Contract.AccessListPermissionActionData;
    entityTypeName: string;
};

export type AccessDefinitionEntitiesPermissionActionsEntityTypeNames = {
    destinationEntityTypeName: string;
    serviceTypeName: string;
    sourceEntityTypeName: string;
};

export type AccessDefinitionGetAccessGraph = (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope) => Promise<Contract.EntityControllerGetAccessGraphResponse>;

export type AccessDefinitionGetAccessListPermissionActions = (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope, selection: Contract.EntityControllerGetAccessListPermissionActionsRequestSelection, selectedPermissionPaths: Contract.AccessGraphPermissionPath[]) => Promise<Contract.EntityControllerGetAccessListPermissionActionsResponse>;

export type AccessDefinitionGetAccessReportRequestDefinition = (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope) => Contract.ReportControllerAccessReportRequestDefinition;

export type AccessDefinitionGetFilters = (filterMap: Dictionary<any>, tenantId?: string) => Contract.EntityControllerGetAccessDataRequestFilters;

type AccessDefinitionGraphPermissionPath = {
    getDirectedGraphModelNode: (permissionPath: Contract.AccessGraphPermissionPath, toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>, onClick: Action0) => DirectedGraphModelNode;
    getEntityPermissionPath: (entityId: string, permissionPaths: Contract.AccessGraphPermissionPath[]) => Contract.AccessGraphPermissionPath | undefined;
};

export type AccessDefinitionToolbarProps = {
    accessGraph: Optional<Contract.AccessGraph>;
    actionsRef: Ref<Optional<AccessDefinitionToolbarActions>>;
    baseUrl?: string;
    csvExportButtonElement?: ReactNode;
    entityModel: Contract.EntityModel;
    graphDirection?: Contract.EntityAccessDirection;
    onFiltersChanged: Action1<Dictionary<any>>;
    view?: AccessView;
};

export type AccessDefinitionToolbarActions = {
    filter: (filterId: ToolbarFilterId, type: "by" | "except", values: any[]) => void;
};

export enum ToolbarFilterId {
    AccessLevels = "accessLevels",
    DestinationEntityIds = "destinationEntityIds",
    DestinationResourceBuiltInEntityAttributeTypeNames = "destinationResourceBuiltInEntityAttributeTypeNames",
    DestinationResourceTenantIds = "destinationResourceTenantIds",
    GroupIds = "groupIds",
    IdentityIds = "identityIds",
    OriginatorEntityIds = "originatorEntityIds",
    OriginatorEntityTypeNames = "originatorEntityTypeNames",
    PermissionActionRiskCategories = "permissionActionRiskCategories",
    PermissionActions = "permissionActions",
    PermissionActionSeverity = "permissionActionSeverity",
    PermitterIds = "permitterIds"
}