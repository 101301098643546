﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NorwayIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g filter="url(#filter0_dd_60203_5257)">
                <g clipPath="url(#clip0_60203_5257)">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        x="2"
                        y="4"/>
                    <path
                        d="M26 4.00012H2V19.9639H26V4.00012Z"
                        fill="#BA0C2F"/>
                    <path
                        d="M2 11.982H26H2ZM10.7273 4.00012V19.9639V4.00012Z"
                        fill="#000008"/>
                    <path
                        clipRule="evenodd"
                        d="M8.54545 9.8002V4.00012H12.9091V9.8002H26V14.1638H12.9091V19.9639H8.54545V14.1638H2V9.8002H8.54545Z"
                        fill="white"
                        fillRule="evenodd"/>
                    <path
                        d="M2 11.982H26H2ZM10.7273 4.00012V19.9639V4.00012Z"
                        fill="#00205B"/>
                    <path
                        clipRule="evenodd"
                        d="M9.63636 10.8911V4.00012H11.8182V10.8911H26V13.0729H11.8182V19.9639H9.63636V13.0729H2V10.8911H9.63636Z"
                        fill="#00205B"
                        fillRule="evenodd"/>
                </g>
            </g>
            <defs>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="18.5701"
                    id="filter0_dd_60203_5257"
                    width="26.6063"
                    x="0.696833"
                    y="3.56561">
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"/>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="0.868778"/>
                    <feGaussianBlur stdDeviation="0.651584"/>
                    <feComposite
                        in2="hardAlpha"
                        operator="out"/>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.08 0"/>
                    <feBlend
                        in2="BackgroundImageFix"
                        mode="normal"
                        result="effect1_dropShadow_60203_5257"/>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset/>
                    <feGaussianBlur
                        stdDeviation="0.0542986"/>
                    <feComposite
                        in2="hardAlpha"
                        operator="out"/>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.32 0"/>
                    <feBlend
                        in2="effect1_dropShadow_60203_5257"
                        mode="normal"
                        result="effect2_dropShadow_60203_5257"/>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_60203_5257"
                        mode="normal"
                        result="shape"/>
                </filter>
                <clipPath id="clip0_60203_5257">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        x="2"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}