//@ts-nocheck"
import Mustache from "mustache";
import { AwsConsoleHelper } from ".";
import { AwsResourceArnParser, Contract, RegionModelHelper } from "../../../common";

export class AwsConsoleUrlBuilder {
    private static readonly _acmCertificatesUrlTemplate = "https://{{domainName}}/acm/home?region={{regionSystemName}}#/certificates/list";
    private static readonly _acmCertificateUrlTemplate = "https://{{domainName}}/acm/home?region={{regionSystemName}}#/certificates/{{rawId}}";
    private static readonly _acmUrlTemplate = "https://{{domainName}}/acm/";
    private static readonly _aossCollectionUrlTemplate = "https://{{domainName}}/aos/home?region={{regionSystemName}}#opensearch/collections/{{name}}";
    private static readonly _apiGatewayUrlTemplate = "https://{{domainName}}/apigateway/";
    private static readonly _apiGatewayV1ApiResourcesUrlTemplate = "https://{{domainName}}/apigateway/main/apis/{{rawId}}/resources?api={{rawId}}&region={{regionSystemName}}";
    private static readonly _apiGatewayV1ApiStagesUrlTemplate = "https://{{domainName}}/apigateway/main/apis/{{rawId}}/stages?api={{rawId}}&region={{regionSystemName}}";
    private static readonly _apiGatewayV2ApiUrlTemplate = "https://{{domainName}}/apigateway/main/api-detail?api={{rawId}}&region={{regionSystemName}}";
    private static readonly _athenaWorkgroupUrlTemplate = "https://{{domainName}}/athena/home?region={{regionSystemName}}#/workgroups/details/{{name}}";
    private static readonly _autoScalingGroupLaunchConfigurationUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#LaunchConfigurations";
    private static readonly _autoScalingGroupUrlTemplate = "https://{{domainName}}/ec2autoscaling/home?region={{regionSystemName}}#/details/{{name}}?view=details";
    private static readonly _autoScalingUrlTemplate = "https://{{domainName}}/ec2autoscaling/";
    private static readonly _batchJobDefinitionUrlTemplate = "https://{{domainName}}/batch/home?region={{regionSystemName}}#job-definition/detail/{{latestRevisionArn}}";
    private static readonly _batchUrlTemplate = "https://{{domainName}}/batch/";
    private static readonly _bedrockAgentUrlTemplate = "https://{{domainName}}/bedrock/home?region={{regionSystemName}}#/agents/{{rawId}}";
    private static readonly _bedrockCustomModelUrlTemplate = "https://{{domainName}}/bedrock/home?region={{regionSystemName}}#/custom-models/item?arn={{arn}}";
    private static readonly _bedrockUrlTemplate = "https://{{domainName}}/bedrock/";
    private static readonly _cloudFormationStackUrlTemplate = "https://{{domainName}}/cloudformation/home?region={{regionSystemName}}#/stacks/stackinfo?stackId={{rawId}}";
    private static readonly _cloudFormationUrlTemplate = "https://{{domainName}}/cloudformation/";
    private static readonly _cloudFrontDistributionUrlTemplate = "https://{{domainName}}/cloudfront/home#/distributions/{{rawId}}";
    private static readonly _cloudFrontUrlTemplate = "https://{{domainName}}/cloudfront/";
    private static readonly _cloudTrailTrailUrlTemplate = "https://{{domainName}}/cloudtrail/home?region={{regionSystemName}}#/trails/{{arn}}";
    private static readonly _cloudTrailUrlTemplate = "https://{{domainName}}/cloudtrail/";
    private static readonly _cloudWatchMetadataNoTokenMetricUrlTemplate = "https://{{domainName}}/cloudwatch/home?region={{regionSystemName}}#metricsV2:graph=~(metrics~(~(~'AWS*2fEC2~'MetadataNoToken~'InstanceId~'{{instanceRawIdParameters}}))~view~'timeSeries~stacked~false~region~'{{regionSystemName}}~start~'-PT720H~end~'P0D~stat~'Average~period~300);query=~'*7bAWS*2fEC2*2cInstanceId*7d*20metadata";
    private static readonly _cloudWatchUrlTemplate = "https://{{domainName}}/cloudwatch/";
    private static readonly _configUrlTemplate = "https://{{domainName}}/config/";
    private static readonly _docDbElasticClusterSnapshotUrlTemplate = "https://{{domainName}}/docdb/home?region={{regionSystemName}}#elastic-snapshot-details/{{arn}}";
    private static readonly _docDbElasticClusterUrlTemplate = "https://{{domainName}}/docdb/home?region={{regionSystemName}}#elastic-cluster-details/{{arn}}";
    private static readonly _docDbElasticUrlTemplate = "https://{{domainName}}/docdb/";
    private static readonly _dynamoDbTableUrlTemplate = "https://{{domainName}}/dynamodbv2/home?region={{regionSystemName}}#table?name={{name}}&tab=overview";
    private static readonly _dynamoDbUrlTemplate = "https://{{domainName}}/dynamodbv2/";
    private static readonly _ec2ElasticIpUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#ElasticIpDetails:AllocationId={{rawId}}";
    private static readonly _ec2ImageEditPermissionsUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#EditImagePermissions:imageId={{rawId}}";
    private static readonly _ec2ImageUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#ImageDetails:imageId={{rawId}}";
    private static readonly _ec2InstanceUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#InstanceDetails:instanceId={{rawId}}";
    private static readonly _ec2InternetGatewayUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#InternetGateway:internetGatewayId={{rawId}}";
    private static readonly _ec2LaunchTemplateUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#LaunchTemplateDetails:launchTemplateId={{rawId}}";
    private static readonly _ec2NatGatewayUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#NatGatewayDetails:natGatewayId={{rawId}}";
    private static readonly _ec2NetworkAclUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#NetworkAclDetails:networkAclId={{rawId}}";
    private static readonly _ec2NetworkInterfaceUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#NetworkInterface:networkInterfaceId={{rawId}}";
    private static readonly _ec2RouteTableUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#RouteTableDetails:RouteTableId={{rawId}}";
    private static readonly _ec2SecurityGroupsSecurityGroupUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#SecurityGroup:groupId={{rawId}}";
    private static readonly _ec2SecurityGroupsUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#SecurityGroups";
    private static readonly _ec2SnapshotModifyPermissionsUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#ModifyPermissions:snapshotId={{rawId}}";
    private static readonly _ec2SnapshotUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#SnapshotDetails:snapshotId={{rawId}}";
    private static readonly _ec2SubnetsVpcUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#Subnets:vpcId={{vpc}}";
    private static readonly _ec2SubnetUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#SubnetDetails:subnetId={{rawId}}";
    private static readonly _ec2UrlTemplate = "https://{{domainName}}/ec2/";
    private static readonly _ec2VolumeUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#VolumeDetails:volumeId={{rawId}}";
    private static readonly _ec2VpcBlockPublicAccessPolicyUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#Settings:tab=blockPublicAccess";
    private static readonly _ec2VpcEndpointUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#EndpointDetails:vpcEndpointId={{rawId}}";
    private static readonly _ec2VpcPeeringConnectionUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#PeeringConnectionDetails:VpcPeeringConnectionId={{rawId}}";
    private static readonly _ec2VpcsUrlTemplate = "https://{{domainName}}/vpcconsole/";
    private static readonly _ec2VpcUrlTemplate = "https://{{domainName}}/vpcconsole/home?region={{regionSystemName}}#VpcDetails:VpcId={{rawId}}";
    private static readonly _ecrRepositoryEditPermissionsUrlTemplate = "https://{{domainName}}/ecr/repositories/private/{{tenantRawId}}/{{name}}/_/permissions/edit-perms/?region={{regionSystemName}}";
    private static readonly _ecrRepositoryUrlTemplate = "https://{{domainName}}/ecr/repositories/private/{{tenantRawId}}/{{name}}?region={{regionSystemName}}";
    private static readonly _ecrUrlTemplate = "https://{{domainName}}/ecr/";
    private static readonly _ecsServiceUrlTemplate = "https://{{domainName}}/ecs/v2/clusters/{{clusterName}}/services/{{name}}?region={{regionSystemName}}";
    private static readonly _ecsTaskDefinitionUrlTemplate = "https://{{domainName}}/ecs/v2/task-Definitions/{{name}}?region={{regionSystemName}}";
    private static readonly _ecsUrlTemplate = "https://{{domainName}}/ecs/";
    private static readonly _efsFileSystemUrlTemplate = "https://{{domainName}}/efs/home?region={{regionSystemName}}#/file-systems/{{rawId}}";
    private static readonly _efsUrlTemplate = "https://{{domainName}}/efs/";
    private static readonly _eksClusterEditEndpointAccessUrlTemplate = "https://{{domainName}}/eks/home?region={{regionSystemName}}#/clusters/{{name}}/manage-endpoint-access";
    private static readonly _eksClusterNodeGroupUrlTemplate = "https://{{domainName}}/eks/home?region={{regionSystemName}}#/clusters/{{clusterName}}/nodegroups/{{name}}";
    private static readonly _eksClusterUrlTemplate = "https://{{domainName}}/eks/home?region={{regionSystemName}}#/clusters/{{name}}";
    private static readonly _eksUrlTemplate = "https://{{domainName}}/eks/";
    private static readonly _elastiCacheBackupsUrlTemplate = "https://{{domainName}}/elasticache/home?region={{regionSystemName}}#/snapshots";
    private static readonly _elastiCacheReplicationGroupUrlTemplate = "https://{{domainName}}/elasticache/home?region={{regionSystemName}}#/redis/{{name}}";
    private static readonly _elastiCacheServerlessCacheUrlTemplate = "https://{{domainName}}/elasticache/home?region={{regionSystemName}}#/redis/serverless/{{name}}";
    private static readonly _elastiCacheUrlTemplate = "https://{{domainName}}/elasticache/";
    private static readonly _elasticBeanstalkEnvironmentUrlTemplate = "https://{{domainName}}/elasticbeanstalk/home?region={{regionSystemName}}#/environment/dashboard?environmentId={{rawId}}";
    private static readonly _elasticBeanstalkUrlTemplate = "https://{{domainName}}/elasticbeanstalk/";
    private static readonly _elbClassicLoadBalancerUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#LoadBalancer:loadBalancerArn={{name}}";
    private static readonly _elbModernLoadBalancerUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#LoadBalancer:loadBalancerArn={{arn}}";
    private static readonly _elbTargetGroupsUrlTemplate = "https://{{domainName}}/ec2/home?region={{regionSystemName}}#TargetGroups:";
    private static readonly _emrClusterUrlTemplate = "https://{{domainName}}/emr/home?region={{regionSystemName}}#/clusterDetails/{{rawId}}";
    private static readonly _emrSecurityConfigurationUrlTemplate = "https://{{domainName}}/emr/home?region={{regionSystemName}}#/securityConfigs";
    private static readonly _emrUrlTemplate = "https://{{domainName}}/emr/";
    private static readonly _esDomainEditSecurityConfigUrlTemplate = "https://{{domainName}}/aos/home?region={{regionSystemName}}#opensearch/domains/{{name}}/editSecurityConfig";
    private static readonly _esDomainUrlTemplate = "https://{{domainName}}/aos/home?region={{regionSystemName}}#opensearch/domains/{{name}}";
    private static readonly _esUrlTemplate = "https://{{domainName}}/aos/";
    private static readonly _firehoseDeliveryStreamUrlTemplate = "https://{{domainName}}/firehose/home?region={{regionSystemName}}#/details/{{name}}";
    private static readonly _firehoseUrlTemplate = "https://{{domainName}}/firehose/";
    private static readonly _iamGroupUrlTemplate = "https://{{domainName}}/iamv2/home#/groups/details/{{name}}?section=users";
    private static readonly _iamManagedPolicyUrlTemplate = "https://{{domainName}}/iamv2/home#/policies/details/{{arn}}?view=json";
    private static readonly _iamRoleTrustRelationshipsUrlTemplate = "https://{{domainName}}/iamv2/home#/roles/details/{{name}}?section=trust_relationships";
    private static readonly _iamRoleUrlTemplate = "https://{{domainName}}/iamv2/home#/roles/details/{{name}}?section=permissions";
    private static readonly _iamUrlTemplate = "https://{{domainName}}/iamv2/";
    private static readonly _iamUserGroupMembershipUrlTemplate = "https://{{domainName}}/iamv2/home?#/users/details/{{name}}?section=groups";
    private static readonly _iamUserSecurityCredentialsUrlTemplate = "https://{{domainName}}/iamv2/home#/users/details/{{name}}?section=security_credentials";
    private static readonly _iamUserUrlTemplate = "https://{{domainName}}/iamv2/home#/users/details/{{name}}";
    private static readonly _kinesisStreamUrlTemplate = "https://{{domainName}}/kinesis/home?region={{regionSystemName}}#/streams/details/{{name}}/details";
    private static readonly _kinesisUrlTemplate = "https://{{domainName}}/kinesis/";
    private static readonly _kmsKeyUrlTemplate = "https://{{domainName}}/kms/home?region={{regionSystemName}}#/kms/keys/{{rawId}}";
    private static readonly _kmsUrlTemplate = "https://{{domainName}}/kms/";
    private static readonly _lambdaFunctionConfigurationEditEnvironmentVariablesUrlTemplate = "https://{{domainName}}/lambda/home?region={{regionSystemName}}#/functions/{{name}}/edit/environment-variables?tab=configure";
    private static readonly _lambdaFunctionConfigurationUrlTemplate = "https://{{domainName}}/lambda/home?region={{regionSystemName}}#/functions/{{name}}?tab=code";
    private static readonly _lambdaUrlTemplate = "https://{{domainName}}/lambda/";
    private static readonly _organizationsAccountUrlTemplate = "https://{{domainName}}/organizations/v2/home/accounts/{{tenantRawId}}";
    private static readonly _organizationsOrganizationUnitUrlTemplate = "https://{{domainName}}/organizations/v2/home/organizational-units/{{rawId}}";
    private static readonly _organizationsResourceControlPolicyUrlTemplate = "https://{{domainName}}/organizations/v2/home/policies/resource-control-policy/{{rawId}}";
    private static readonly _organizationsRootUrlTemplate = "https://{{domainName}}/organizations/v2/home/root";
    private static readonly _organizationsServiceControlPolicyUrlTemplate = "https://{{domainName}}/iamv2/home#/organizations/ServiceControlPolicies/{{rawId}}/details";
    private static readonly _organizationsUrlTemplate = "https://{{domainName}}/organizations/";
    private static readonly _rdsAuroraGlobalClusterUrlTemplate = "https://{{domainName}}/rds/home?#database:id={{rawId}};is-cluster=false;is-global-cluster=true;tab=configuration";
    private static readonly _rdsClusterUrlTemplate = "https://{{domainName}}/rds/home?region={{regionSystemName}}#database:id={{rawId}};is-cluster=true";
    private static readonly _rdsDatabaseInstanceSnapshotUrlTemplate = "https://{{domainName}}/rds/home?region={{regionSystemName}}#db-snapshot:id={{rawId}}";
    private static readonly _rdsDatabaseInstanceUrlTemplate = "https://{{domainName}}/rds/home?region={{regionSystemName}}#database:id={{rawId}};is-cluster=false";
    private static readonly _rdsDocDbClusterSnapshotShareUrlTemplate = "https://{{domainName}}/docdb/home?region={{regionSystemName}}#snapshot-share/{{rawId}}";
    private static readonly _rdsDocDbClusterSnapshotUrlTemplate = "https://{{domainName}}/docdb/home?region={{regionSystemName}}#snapshot-details/{{rawId}}";
    private static readonly _rdsDocDbClusterUrlTemplate = "https://{{domainName}}/docdb/home?region={{regionSystemName}}#cluster-details/{{rawId}}";
    private static readonly _rdsDocDbDatabaseInstanceUrlTemplate = "https://{{domainName}}/docdb/home?region={{regionSystemName}}#instance-details/{{rawId}}";
    private static readonly _rdsDocDbGlobalClusterUrlTemplate = "https://{{domainName}}/docdb/home?region={{regionSystemName}}#global-cluster-details/{{rawId}}";
    private static readonly _rdsDocDbUrlTemplate = "https://{{domainName}}/docdb/";
    private static readonly _rdsNeptuneClusterSnapshotShareUrlTemplate = "https://{{domainName}}/neptune/home?region={{regionSystemName}}#snapshot-share:id={{rawId}}";
    private static readonly _rdsNeptuneClusterSnapshotUrlTemplate = "https://{{domainName}}/neptune/home?region={{regionSystemName}}#snapshot-details:id={{rawId}}";
    private static readonly _rdsNeptuneClusterUrlTemplate = "https://{{domainName}}/neptune/home?region={{regionSystemName}}#database-details:id={{rawId}};resource-type=cluster";
    private static readonly _rdsNeptuneDatabaseInstanceUrlTemplate = "https://{{domainName}}/neptune/home?region={{regionSystemName}}#database-details:id={{rawId}};resource-type=db";
    private static readonly _rdsNeptuneGlobalClusterUrlTemplate = "https://{{domainName}}/neptune/home?region={{regionSystemName}}#database-details:id={{rawId}};resource-type=global-cluster";
    private static readonly _rdsNeptuneUrlTemplate = "https://{{domainName}}/neptune/";
    private static readonly _rdsSnapshotShareUrlTemplate = "https://{{domainName}}/rds/home?region={{regionSystemName}}#share-snapshot:id={{rawId}}";
    private static readonly _rdsSnapshotsUrlTemplate = "https://{{domainName}}/rds/home?region={{regionSystemName}}#snapshots-list:";
    private static readonly _rdsSnapshotUrlTemplate = "https://{{domainName}}/rds/home?region={{regionSystemName}}#db-snapshot:id={{rawId}}";
    private static readonly _rdsUrlTemplate = "https://{{domainName}}/rds/";
    private static readonly _redshiftClusterUrlTemplate = "https://{{domainName}}/redshiftv2/home?region={{regionSystemName}}#cluster-details?cluster={{name}}";
    private static readonly _redshiftNamespaceUrlTemplate = "https://{{domainName}}/redshiftv2/home?region={{regionSystemName}}#serverless-namespace?namespace={{name}}";
    private static readonly _redshiftUrlTemplate = "https://{{domainName}}/redshiftv2/";
    private static readonly _resourceGroupsTagEditorUrlTemplate = "https://{{domainName}}/resource-groups/tag-editor/find-resources";
    private static readonly _resourceGroupsUrlTemplate = "https://{{domainName}}/resource-groups/";
    private static readonly _route53HostedZoneUrlTemplate = "https://{{domainName}}/route53/v2/hostedzones#ListRecordSets/{{rawId}}";
    private static readonly _route53UrlTemplate = "https://{{domainName}}/route53/";
    private static readonly _s3BucketEditBlockPublicAccessUrlTemplate = "https://{{domainName}}/s3/bucket/{{name}}/property/bpa/edit?region={{regionSystemName}}";
    private static readonly _s3BucketEditLoggingUrlTemplate = "https://{{domainName}}/s3/bucket/{{name}}/property/logging/edit?region={{regionSystemName}}";
    private static readonly _s3BucketEditPermissionsUrlTemplate = "https://{{domainName}}/s3/buckets/{{name}}?region={{regionSystemName}}&tab=permissions";
    private static readonly _s3BucketObjectUrlTemplate = "https://{{domainName}}/s3/buckets/{{name}}?region={{regionSystemName}}&prefix={{objectName}}";
    private static readonly _s3BucketUrlTemplate = "https://{{domainName}}/s3/buckets/{{name}}?region={{regionSystemName}}";
    private static readonly _s3UrlTemplate = "https://{{domainName}}/s3/";
    private static readonly _sageMakerNotebookInstanceUrlTemplate = "https://{{domainName}}/sagemaker/home?region={{regionSystemName}}#/notebook-instances/{{name}}";
    private static readonly _sageMakerUrlTemplate = "https://{{domainName}}/sagemaker/";
    private static readonly _secretsManagerSecretUrlTemplate = "https://{{domainName}}/secretsmanager/secret?region={{regionSystemName}}&name={{name}}";
    private static readonly _secretsManagerUrlTemplate = "https://{{domainName}}/secretsmanager/";
    private static readonly _securityHubUrlTemplate = "https://{{domainName}}/securityhub/";
    private static readonly _snsTopicEditUrlTemplate = "https://{{domainName}}/sns/v3/home?region={{regionSystemName}}#/edit-topic/{{arn}}";
    private static readonly _snsTopicUrlTemplate = "https://{{domainName}}/sns/v3/home?region={{regionSystemName}}#/topic/{{arn}}";
    private static readonly _snsUrlTemplate = "https://{{domainName}}/sns/";
    private static readonly _sqsQueueEditUrlTemplate = "https://{{domainName}}/sqs/home?region={{regionSystemName}}#/queues/{{urlEncodedUrl}}/edit";
    private static readonly _sqsQueueUrlTemplate = "https://{{domainName}}/sqs/home?region={{regionSystemName}}#/queues/{{urlEncodedUrl}}";
    private static readonly _sqsUrlTemplate = "https://{{domainName}}/sqs/";
    private static readonly _ssmParameterUrlTemplate = "https://{{domainName}}/systems-manager/parameters/{{name}}/description?region={{regionSystemName}}&tab=Table#";
    private static readonly _ssmUrlTemplate = "https://{{domainName}}/systems-manager/";
    private static readonly _ssoGroupUrlTemplate = "https://{{domainName}}/singlesignon/identity/home?region={{regionSystemName}}#!/groups/groupDetails/{{rawId}}";
    private static readonly _ssoIamUrlTemplate = "https://{{domainName}}/singlesignon/identity/home?region={{regionSystemName}}#!/";
    private static readonly _ssoPermissionSetUrlTemplate = "https://{{domainName}}/iamv2/home?region={{regionSystemName}}#/organization/permission-sets/details/{{rawId}}";
    private static readonly _ssoUrlTemplate = "https://{{domainName}}/singlesignon/";
    private static readonly _ssoUserUrlTemplate = "https://{{domainName}}/singlesignon/identity/home?region={{regionSystemName}}#!/users/userDetails/userId={{id}}&directoryId=&realm=/profile";
    private static readonly _timestreamDatabaseTableUrlTemplate = "https://{{domainName}}/timestream/home?region={{regionSystemName}}#/databases/{{databaseName}}/tables/{{name}}";
    private static readonly _timestreamDatabaseUrlTemplate = "https://{{domainName}}/timestream/home?region={{regionSystemName}}#/databases/{{name}}";
    private static readonly _wafUrlTemplate = "https://{{domainName}}/wafv2/";
    private static readonly _wafWebAclUrlTemplate = "https://{{domainName}}/wafv2/homev2/web-acl/{{name}}/{{rawId}}/overview?region={{regionSystemName}}";

    public static getAcmCertificatesUrl(certificate: Contract.AwsAcmCertificate) {
        return Mustache.render(
            AwsConsoleUrlBuilder._acmCertificatesUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(certificate.arn!)),
                "regionSystemName": RegionModelHelper.get(certificate.regionId!).systemName
            });
    }

    public static getAcmCertificateUrl(certificate: Contract.AwsAcmCertificate) {
        return Mustache.render(
            AwsConsoleUrlBuilder._acmCertificateUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(certificate.arn!)),
                "regionSystemName": RegionModelHelper.get(certificate.regionId!).systemName,
                "rawId": certificate.rawId
            });
    }

    public static getAcmUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._acmUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getAossCollectionUrl(aossCollection: Contract.AwsAossCollection) {
        return Mustache.render(
            AwsConsoleUrlBuilder._aossCollectionUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(aossCollection.arn!)),
                "regionSystemName": RegionModelHelper.get(aossCollection.regionId!).systemName,
                "name": aossCollection.name
            });
    }

    public static getApiGatewayV1ApiResourcesUrl(v1Api: Contract.AwsApiGatewayV1Api) {
        return Mustache.render(
            AwsConsoleUrlBuilder._apiGatewayV1ApiResourcesUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(v1Api.arn!)),
                "rawId": v1Api.rawId,
                "regionSystemName": RegionModelHelper.get(v1Api.regionId!).systemName
            });
    }

    public static getApiGatewayV1ApiStagesUrl(v1Api: Contract.AwsApiGatewayV1Api) {
        return Mustache.render(
            AwsConsoleUrlBuilder._apiGatewayV1ApiStagesUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(v1Api.arn!)),
                "rawId": v1Api.rawId,
                "regionSystemName": RegionModelHelper.get(v1Api.regionId!).systemName
            });
    }

    public static getApiGatewayV2ApiUrl(v2Api: Contract.AwsApiGatewayV2Api) {
        return Mustache.render(
            AwsConsoleUrlBuilder._apiGatewayV2ApiUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(v2Api.arn!)),
                "rawId": v2Api.rawId,
                "regionSystemName": RegionModelHelper.get(v2Api.regionId!).systemName
            });
    }

    public static getAthenaWorkgroupUrl(workgroup: Contract.AwsAthenaWorkgroup) {
        return Mustache.render(
            AwsConsoleUrlBuilder._athenaWorkgroupUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(workgroup.arn!)),
                "regionSystemName": RegionModelHelper.get(workgroup.regionId!).systemName,
                "name": workgroup.name
            });
    }

    public static getAutoScalingGroupLaunchConfigurationUrl(launchConfiguration: Contract.AwsAutoScalingLaunchConfiguration) {
        return Mustache.render(
            AwsConsoleUrlBuilder._autoScalingGroupLaunchConfigurationUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(launchConfiguration.arn!)),
                "regionSystemName": RegionModelHelper.get(launchConfiguration.regionId!).systemName
            });
    }

    public static getAutoScalingGroupUrl(autoScalingGroup: Contract.AwsAutoScalingAutoScalingGroup) {
        return Mustache.render(
            AwsConsoleUrlBuilder._autoScalingGroupUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(autoScalingGroup.arn!)),
                "regionSystemName": RegionModelHelper.get(autoScalingGroup.regionId!).systemName,
                "name": autoScalingGroup.name
            });
    }

    public static getBatchJobDefinitionUrl(batchJobDefinition: Contract.AwsBatchJobDefinition) {
        return Mustache.render(
            AwsConsoleUrlBuilder._batchJobDefinitionUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(batchJobDefinition.arn!)),
                "regionSystemName": RegionModelHelper.get(batchJobDefinition.regionId!).systemName,
                "latestRevisionArn": batchJobDefinition.latestRevisionArn
            });
    }

    public static getBedrockAgentUrl(agent: Contract.AwsBedrockAgent) {
        return Mustache.render(
            AwsConsoleUrlBuilder._bedrockAgentUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(agent.arn!)),
                "regionSystemName": RegionModelHelper.get(agent.regionId!).systemName,
                "rawId": agent.rawId
            });
    }

    public static getBedrockCustomModelUrl(customModel: Contract.AwsBedrockCustomModel) {
        return Mustache.render(
            AwsConsoleUrlBuilder._bedrockCustomModelUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(customModel.arn!)),
                "regionSystemName": RegionModelHelper.get(customModel.regionId!).systemName,
                "arn": customModel.arn
            });
    }

    public static getCloudFormationStackUrl(stack: Contract.AwsCloudFormationStack) {
        return Mustache.render(
            AwsConsoleUrlBuilder._cloudFormationStackUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(stack.arn!)),
                "regionSystemName": RegionModelHelper.get(stack.regionId!).systemName,
                "rawId": stack.rawId
            });
    }

    public static getCloudFrontDistributionUrl(distribution: Contract.AwsCloudFrontDistribution) {
        return Mustache.render(
            AwsConsoleUrlBuilder._cloudFrontDistributionUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(distribution.arn!)),
                "rawId": distribution.rawId
            });
    }

    public static getCloudFrontUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._cloudFrontUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getCloudTrailTrailUrl(cloudTrail: Contract.AwsCloudTrailTrail) {
        return Mustache.render(
            AwsConsoleUrlBuilder._cloudTrailTrailUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(cloudTrail.arn!)),
                "regionSystemName": RegionModelHelper.get(cloudTrail.regionId!).systemName,
                "arn": cloudTrail.arn
            });
    }

    public static getCloudTrailUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._cloudTrailUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getCloudWatchMetadataNoTokenMetricUrl(

        instanceRawIdParameters: string,
        partitionType: Contract.AwsPartitionType,
        regionSystemName: string) {
        return Mustache.render(
            AwsConsoleUrlBuilder._cloudWatchMetadataNoTokenMetricUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType),
                "regionSystemName": regionSystemName,
                "instanceRawIdParameters": instanceRawIdParameters
            });
    }

    public static getCloudWatchUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._cloudWatchUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getConfigUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._configUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getDocDbElasticClusterSnapshotUrl(snapshot: Contract.AwsDocDbElasticClusterSnapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._docDbElasticClusterSnapshotUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "arn": snapshot.arn
            });
    }

    public static getDocDbElasticClusterUrl(cluster: Contract.AwsDocDbElasticCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._docDbElasticClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(cluster.arn!)),
                "regionSystemName": RegionModelHelper.get(cluster.regionId!).systemName,
                "arn": cluster.arn
            });
    }

    public static getDynamoDbTableUrl(dynamoDbTable: Contract.AwsDynamoDbTable) {
        return Mustache.render(
            AwsConsoleUrlBuilder._dynamoDbTableUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(dynamoDbTable.arn!)),
                "regionSystemName": RegionModelHelper.get(dynamoDbTable.regionId!).systemName,
                "name": dynamoDbTable.name
            });
    }

    public static getEc2ElasticIpUrl(elasticIp: Contract.AwsEc2ElasticIp) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2ElasticIpUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(elasticIp.arn!)),
                "regionSystemName": RegionModelHelper.get(elasticIp.regionId!).systemName,
                "rawId": elasticIp.rawId
            });
    }

    public static getEc2ImageEditPermissionsUrl(image: Contract.AwsEc2Image) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2ImageEditPermissionsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(image.arn!)),
                "regionSystemName": RegionModelHelper.get(image.regionId!).systemName,
                "rawId": image.rawId
            });
    }

    public static getEc2ImageUrl(ec2Image: Contract.AwsEc2Image) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2ImageUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(ec2Image.arn!)),
                "regionSystemName": RegionModelHelper.get(ec2Image.regionId!).systemName,
                "rawId": ec2Image.rawId
            });
    }

    public static getEc2InstanceUrl(ec2Instance: Contract.AwsEc2Instance) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2InstanceUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(ec2Instance.arn!)),
                "regionSystemName": RegionModelHelper.get(ec2Instance.regionId!).systemName,
                "rawId": ec2Instance.rawId
            });
    }

    public static getEc2InternetGatewayUrl(internetGateway: Contract.AwsEc2InternetGateway) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2InternetGatewayUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(internetGateway.arn!)),
                "regionSystemName": RegionModelHelper.get(internetGateway.regionId!).systemName,
                "rawId": internetGateway.rawId
            });
    }

    public static getEc2LaunchTemplateUrl(ec2LaunchTemplate: Contract.AwsEc2LaunchTemplate) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2LaunchTemplateUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(ec2LaunchTemplate.arn!)),
                "regionSystemName": RegionModelHelper.get(ec2LaunchTemplate.regionId!).systemName,
                "rawId": ec2LaunchTemplate.rawId
            });
    }

    public static getEc2NatGatewayUrl(natGateway: Contract.AwsEc2NatGateway) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2NatGatewayUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(natGateway.arn!)),
                "regionSystemName": RegionModelHelper.get(natGateway.regionId!).systemName,
                "rawId": natGateway.rawId
            });
    }

    public static getEc2NetworkAclUrl(networkAcl: Contract.AwsEc2NetworkAcl) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2NetworkAclUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(networkAcl.arn!)),
                "regionSystemName": RegionModelHelper.get(networkAcl.regionId!).systemName,
                "rawId": networkAcl.rawId
            });
    }

    public static getEc2NetworkInterfaceUrl(networkInterface: Contract.AwsEc2NetworkInterface) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2NetworkInterfaceUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(networkInterface.arn!)),
                "regionSystemName": RegionModelHelper.get(networkInterface.regionId!).systemName,
                "rawId": networkInterface.rawId
            });
    }

    public static getEc2RouteTableUrl(routeTable: Contract.AwsEc2RouteTable) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2RouteTableUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(routeTable.arn!)),
                "regionSystemName": RegionModelHelper.get(routeTable.regionId!).systemName,
                "rawId": routeTable.rawId
            });
    }

    public static getEc2SecurityGroupsSecurityGroupUrl(securityGroup: Contract.AwsEc2SecurityGroup) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2SecurityGroupsSecurityGroupUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(securityGroup.arn!)),
                "regionSystemName": RegionModelHelper.get(securityGroup.regionId!).systemName,
                "rawId": securityGroup.rawId
            });
    }

    public static getEc2SecurityGroupsUrl(

        resource: Contract.AwsResource,
        partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2SecurityGroupsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType),
                "regionSystemName": RegionModelHelper.get(resource.regionId!).systemName
            });
    }

    public static getEc2SnapshotModifyPermissionsUrl(snapshot: Contract.AwsEc2Snapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2SnapshotModifyPermissionsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getEc2SnapshotUrl(snapshot: Contract.AwsEc2Snapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2SnapshotUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getEc2SubnetsVpcUrl(

        resource: Contract.AwsResource,
        vpc: string) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2SubnetsVpcUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(resource.arn!)),
                "regionSystemName": RegionModelHelper.get(resource.regionId!).systemName,
                "vpc": vpc
            });
    }

    public static getEc2SubnetUrl(subnet: Contract.AwsEc2Subnet) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2SubnetUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(subnet.arn!)),
                "regionSystemName": RegionModelHelper.get(subnet.regionId!).systemName,
                "rawId": subnet.rawId
            });
    }

    public static getEc2Url(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2UrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getEc2VolumeUrl(ec2Volume: Contract.AwsEc2Volume) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2VolumeUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(ec2Volume.arn!)),
                "regionSystemName": RegionModelHelper.get(ec2Volume.regionId!).systemName,
                "rawId": ec2Volume.rawId
            });
    }

    public static getEc2VpcBlockPublicAccessPolicyUrl(

        partitionType: Contract.AwsPartitionType,
        regionSystemName: string) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2VpcBlockPublicAccessPolicyUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType),
                "regionSystemName": regionSystemName
            });
    }

    public static getEc2VpcEndpointUrl(vpcEndpoint: Contract.AwsEc2VpcEndpoint) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2VpcEndpointUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(vpcEndpoint.arn!)),
                "regionSystemName": RegionModelHelper.get(vpcEndpoint.regionId!).systemName,
                "rawId": vpcEndpoint.rawId
            });
    }

    public static getEc2VpcPeeringConnectionUrl(vpcPeeringConnection: Contract.AwsEc2VpcPeeringConnection) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2VpcPeeringConnectionUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(vpcPeeringConnection.arn!)),
                "regionSystemName": RegionModelHelper.get(vpcPeeringConnection.regionId!).systemName,
                "rawId": vpcPeeringConnection.rawId
            });
    }

    public static getEc2VpcUrl(vpc: Contract.AwsEc2Vpc) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ec2VpcUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(vpc.arn!)),
                "regionSystemName": RegionModelHelper.get(vpc.regionId!).systemName,
                "rawId": vpc.rawId
            });
    }

    public static getEcrRepositoryEditPermissionsUrl(

        repository: Contract.AwsEcrRepository,
        tenantRawId: string) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ecrRepositoryEditPermissionsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(repository.arn!)),
                "tenantRawId": tenantRawId,
                "name": repository.name,
                "regionSystemName": RegionModelHelper.get(repository.regionId!).systemName
            });
    }

    public static getEcrRepositoryUrl(

        repository: Contract.AwsEcrRepository,
        tenantRawId: string) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ecrRepositoryUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(repository.arn!)),
                "tenantRawId": tenantRawId,
                "name": repository.name,
                "regionSystemName": RegionModelHelper.get(repository.regionId!).systemName
            });
    }

    public static getEcrUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ecrUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getEcsServiceUrl(ecsService: Contract.AwsEcsService) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ecsServiceUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(ecsService.arn!)),
                "clusterName": ecsService.clusterName,
                "name": ecsService.name,
                "regionSystemName": RegionModelHelper.get(ecsService.regionId!).systemName
            });
    }

    public static getEcsTaskDefinitionUrl(taskDefinition: Contract.AwsEcsTaskDefinition) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ecsTaskDefinitionUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(taskDefinition.arn!)),
                "name": taskDefinition.name,
                "regionSystemName": RegionModelHelper.get(taskDefinition.regionId!).systemName
            });
    }

    public static getEcsUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ecsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getEfsFileSystemUrl(fileSystem: Contract.AwsElasticFileSystemFileSystem) {
        return Mustache.render(
            AwsConsoleUrlBuilder._efsFileSystemUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(fileSystem.arn!)),
                "regionSystemName": RegionModelHelper.get(fileSystem.regionId!).systemName,
                "rawId": fileSystem.rawId
            });
    }

    public static getEksClusterEditEndpointAccessUrl(eksCluster: Contract.AwsEksCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._eksClusterEditEndpointAccessUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(eksCluster.arn!)),
                "regionSystemName": RegionModelHelper.get(eksCluster.regionId!).systemName,
                "name": eksCluster.name
            });
    }

    public static getEksClusterNodeGroupUrl(eksClusterNodeGroup: Contract.AwsEksClusterNodeGroup) {
        return Mustache.render(
            AwsConsoleUrlBuilder._eksClusterNodeGroupUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(eksClusterNodeGroup.arn!)),
                "regionSystemName": RegionModelHelper.get(eksClusterNodeGroup.regionId!).systemName,
                "clusterName": eksClusterNodeGroup.clusterName,
                "name": eksClusterNodeGroup.name
            });
    }

    public static getEksClusterUrl(eksCluster: Contract.AwsEksCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._eksClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(eksCluster.arn!)),
                "regionSystemName": RegionModelHelper.get(eksCluster.regionId!).systemName,
                "name": eksCluster.name
            });
    }

    public static getElastiCacheBackupsUrl(elastiCache: Contract.AwsElastiCacheCache) {
        return Mustache.render(
            AwsConsoleUrlBuilder._elastiCacheBackupsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(elastiCache.arn!)),
                "regionSystemName": RegionModelHelper.get(elastiCache.regionId!).systemName
            });
    }

    public static getElastiCacheReplicationGroupUrl(replicationGroup: Contract.AwsElastiCacheReplicationGroup) {
        return Mustache.render(
            AwsConsoleUrlBuilder._elastiCacheReplicationGroupUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(replicationGroup.arn!)),
                "regionSystemName": RegionModelHelper.get(replicationGroup.regionId!).systemName,
                "name": replicationGroup.name
            });
    }

    public static getElastiCacheServerlessCacheUrl(serverlessCache: Contract.AwsElastiCacheServerlessCache) {
        return Mustache.render(
            AwsConsoleUrlBuilder._elastiCacheServerlessCacheUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(serverlessCache.arn!)),
                "regionSystemName": RegionModelHelper.get(serverlessCache.regionId!).systemName,
                "name": serverlessCache.name
            });
    }

    public static getElasticBeanstalkEnvironmentUrl(elasticBeanstalkEnvironment: Contract.AwsElasticBeanstalkEnvironment) {
        return Mustache.render(
            AwsConsoleUrlBuilder._elasticBeanstalkEnvironmentUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(elasticBeanstalkEnvironment.arn!)),
                "regionSystemName": RegionModelHelper.get(elasticBeanstalkEnvironment.regionId!).systemName,
                "rawId": elasticBeanstalkEnvironment.rawId
            });
    }

    public static getElbClassicLoadBalancerUrl(loadBalancer: Contract.AwsElbLoadBalancer) {
        return Mustache.render(
            AwsConsoleUrlBuilder._elbClassicLoadBalancerUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(loadBalancer.arn!)),
                "regionSystemName": RegionModelHelper.get(loadBalancer.regionId!).systemName,
                "name": loadBalancer.name
            });
    }

    public static getElbModernLoadBalancerUrl(loadBalancer: Contract.AwsElbLoadBalancer) {
        return Mustache.render(
            AwsConsoleUrlBuilder._elbModernLoadBalancerUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(loadBalancer.arn!)),
                "regionSystemName": RegionModelHelper.get(loadBalancer.regionId!).systemName,
                "arn": loadBalancer.arn
            });
    }

    public static getElbTargetGroupsUrl(loadBalancer: Contract.AwsElbLoadBalancer) {
        return Mustache.render(
            AwsConsoleUrlBuilder._elbTargetGroupsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(loadBalancer.arn!)),
                "regionSystemName": RegionModelHelper.get(loadBalancer.regionId!).systemName
            });
    }

    public static getEmrClusterUrl(emrCluster: Contract.AwsEmrCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._emrClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(emrCluster.arn!)),
                "regionSystemName": RegionModelHelper.get(emrCluster.regionId!).systemName,
                "rawId": emrCluster.rawId
            });
    }

    public static getEmrSecurityConfigurationUrl(

        resource: Contract.AwsResource,
        partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._emrSecurityConfigurationUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType),
                "regionSystemName": RegionModelHelper.get(resource.regionId!).systemName
            });
    }

    public static getEsDomainEditSecurityConfigUrl(domain: Contract.AwsEsDomain) {
        return Mustache.render(
            AwsConsoleUrlBuilder._esDomainEditSecurityConfigUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(domain.arn!)),
                "regionSystemName": RegionModelHelper.get(domain.regionId!).systemName,
                "name": domain.name
            });
    }

    public static getEsDomainUrl(esDomain: Contract.AwsEsDomain) {
        return Mustache.render(
            AwsConsoleUrlBuilder._esDomainUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(esDomain.arn!)),
                "regionSystemName": RegionModelHelper.get(esDomain.regionId!).systemName,
                "name": esDomain.name
            });
    }

    public static getEsUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._esUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getFirehoseDeliveryStreamUrl(kinesisStream: Contract.AwsFirehoseDeliveryStream) {
        return Mustache.render(
            AwsConsoleUrlBuilder._firehoseDeliveryStreamUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(kinesisStream.arn!)),
                "regionSystemName": RegionModelHelper.get(kinesisStream.regionId!).systemName,
                "name": kinesisStream.name
            });
    }

    public static getFirehoseUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._firehoseUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getIamGroupUrl(group: Contract.AwsIamGroup) {
        return Mustache.render(
            AwsConsoleUrlBuilder._iamGroupUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(group.arn!)),
                "name": group.name
            });
    }

    public static getIamManagedPolicyUrl(policy: Contract.AwsIamManagedPolicy) {
        return Mustache.render(
            AwsConsoleUrlBuilder._iamManagedPolicyUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(policy.arn!)),
                "arn": policy.arn
            });
    }

    public static getIamRoleTrustRelationshipsUrl(role: Contract.AwsIamRole) {
        return Mustache.render(
            AwsConsoleUrlBuilder._iamRoleTrustRelationshipsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(role.arn!)),
                "name": role.name
            });
    }

    public static getIamRoleUrl(role: Contract.AwsIamRole) {
        return Mustache.render(
            AwsConsoleUrlBuilder._iamRoleUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(role.arn!)),
                "name": role.name
            });
    }

    public static getIamUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._iamUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getIamUserGroupMembershipUrl(user: Contract.AwsIamUser) {
        return Mustache.render(
            AwsConsoleUrlBuilder._iamUserGroupMembershipUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(user.arn!)),
                "name": user.name
            });
    }

    public static getIamUserSecurityCredentialsUrl(user: Contract.AwsIamUser) {
        return Mustache.render(
            AwsConsoleUrlBuilder._iamUserSecurityCredentialsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(user.arn!)),
                "name": user.name
            });
    }

    public static getIamUserUrl(user: Contract.AwsIamUser) {
        return Mustache.render(
            AwsConsoleUrlBuilder._iamUserUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(user.arn!)),
                "name": user.name
            });
    }

    public static getKinesisStreamUrl(kinesisStream: Contract.AwsKinesisStream) {
        return Mustache.render(
            AwsConsoleUrlBuilder._kinesisStreamUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(kinesisStream.arn!)),
                "regionSystemName": RegionModelHelper.get(kinesisStream.regionId!).systemName,
                "name": kinesisStream.name
            });
    }

    public static getKinesisUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._kinesisUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getKmsKeyUrl(kmsKey: Contract.AwsKmsKey) {
        return Mustache.render(
            AwsConsoleUrlBuilder._kmsKeyUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(kmsKey.arn!)),
                "regionSystemName": RegionModelHelper.get(kmsKey.regionId!).systemName,
                "rawId": kmsKey.rawId
            });
    }

    public static getKmsUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._kmsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getLambdaFunctionConfigurationEditEnvironmentVariablesUrl(functionConfiguration: Contract.AwsLambdaFunctionConfiguration) {
        return Mustache.render(
            AwsConsoleUrlBuilder._lambdaFunctionConfigurationEditEnvironmentVariablesUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(functionConfiguration.arn!)),
                "regionSystemName": RegionModelHelper.get(functionConfiguration.regionId!).systemName,
                "name": functionConfiguration.name
            });
    }

    public static getLambdaFunctionConfigurationUrl(functionConfiguration: Contract.AwsLambdaFunctionConfiguration) {
        return Mustache.render(
            AwsConsoleUrlBuilder._lambdaFunctionConfigurationUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(functionConfiguration.arn!)),
                "regionSystemName": RegionModelHelper.get(functionConfiguration.regionId!).systemName,
                "name": functionConfiguration.name
            });
    }

    public static getLambdaUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._lambdaUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getOrganizationsAccountUrl(

        partitionType: Contract.AwsPartitionType,
        tenantRawId: string) {
        return Mustache.render(
            AwsConsoleUrlBuilder._organizationsAccountUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType),
                "tenantRawId": tenantRawId
            });
    }

    public static getOrganizationsOrganizationUnitUrl(organizationalUnit: Contract.AwsOrganizationsOrganizationalUnit) {
        return Mustache.render(
            AwsConsoleUrlBuilder._organizationsOrganizationUnitUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(organizationalUnit.arn!)),
                "rawId": organizationalUnit.rawId
            });
    }

    public static getOrganizationsResourceControlPolicyUrl(policy: Contract.AwsOrganizationsPolicy) {
        return Mustache.render(
            AwsConsoleUrlBuilder._organizationsResourceControlPolicyUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(policy.arn!)),
                "rawId": policy.rawId
            });
    }

    public static getOrganizationsRootUrl(root: Contract.AwsOrganizationsRoot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._organizationsRootUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(root.arn!))
            });
    }

    public static getOrganizationsServiceControlPolicyUrl(policy: Contract.AwsOrganizationsPolicy) {
        return Mustache.render(
            AwsConsoleUrlBuilder._organizationsServiceControlPolicyUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(policy.arn!)),
                "rawId": policy.rawId
            });
    }

    public static getRdsAuroraGlobalClusterUrl(auroraGlobalCluster: Contract.AwsRdsAuroraGlobalCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsAuroraGlobalClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(auroraGlobalCluster.arn!)),
                "rawId": auroraGlobalCluster.rawId
            });
    }

    public static getRdsClusterUrl(cluster: Contract.AwsRdsCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(cluster.arn!)),
                "regionSystemName": RegionModelHelper.get(cluster.regionId!).systemName,
                "rawId": cluster.rawId
            });
    }

    public static getRdsDatabaseInstanceSnapshotUrl(snapshot: Contract.AwsRdsDatabaseInstanceSnapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsDatabaseInstanceSnapshotUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getRdsDatabaseInstanceUrl(databaseInstance: Contract.AwsRdsDatabaseInstance) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsDatabaseInstanceUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(databaseInstance.arn!)),
                "regionSystemName": RegionModelHelper.get(databaseInstance.regionId!).systemName,
                "rawId": databaseInstance.rawId
            });
    }

    public static getRdsDocDbClusterSnapshotShareUrl(snapshot: Contract.AwsRdsDocDbClusterSnapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsDocDbClusterSnapshotShareUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getRdsDocDbClusterSnapshotUrl(snapshot: Contract.AwsRdsDocDbClusterSnapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsDocDbClusterSnapshotUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getRdsDocDbClusterUrl(cluster: Contract.AwsRdsDocDbCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsDocDbClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(cluster.arn!)),
                "regionSystemName": RegionModelHelper.get(cluster.regionId!).systemName,
                "rawId": cluster.rawId
            });
    }

    public static getRdsDocDbDatabaseInstanceUrl(instance: Contract.AwsRdsDocDbDatabaseInstance) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsDocDbDatabaseInstanceUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(instance.arn!)),
                "regionSystemName": RegionModelHelper.get(instance.regionId!).systemName,
                "rawId": instance.rawId
            });
    }

    public static getRdsDocDbGlobalClusterUrl(globalCluster: Contract.AwsRdsDocDbGlobalCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsDocDbGlobalClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(globalCluster.arn!)),
                "regionSystemName": RegionModelHelper.get(globalCluster.regionId!).systemName,
                "rawId": globalCluster.rawId
            });
    }

    public static getRdsNeptuneClusterSnapshotShareUrl(snapshot: Contract.AwsRdsNeptuneClusterSnapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsNeptuneClusterSnapshotShareUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getRdsNeptuneClusterSnapshotUrl(snapshot: Contract.AwsRdsNeptuneClusterSnapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsNeptuneClusterSnapshotUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getRdsNeptuneClusterUrl(cluster: Contract.AwsRdsNeptuneCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsNeptuneClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(cluster.arn!)),
                "regionSystemName": RegionModelHelper.get(cluster.regionId!).systemName,
                "rawId": cluster.rawId
            });
    }

    public static getRdsNeptuneDatabaseInstanceUrl(instance: Contract.AwsRdsNeptuneDatabaseInstance) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsNeptuneDatabaseInstanceUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(instance.arn!)),
                "regionSystemName": RegionModelHelper.get(instance.regionId!).systemName,
                "rawId": instance.rawId
            });
    }

    public static getRdsNeptuneGlobalClusterUrl(globalCluster: Contract.AwsRdsNeptuneGlobalCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsNeptuneGlobalClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(globalCluster.arn!)),
                "regionSystemName": RegionModelHelper.get(globalCluster.regionId!).systemName,
                "rawId": globalCluster.rawId
            });
    }

    public static getRdsSnapshotShareUrl(snapshot: Contract.AwsRdsSnapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsSnapshotShareUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getRdsSnapshotsUrl(resource: Contract.AwsResource) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsSnapshotsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(resource.arn!)),
                "regionSystemName": RegionModelHelper.get(resource.regionId!).systemName
            });
    }

    public static getRdsSnapshotUrl(snapshot: Contract.AwsRdsSnapshot) {
        return Mustache.render(
            AwsConsoleUrlBuilder._rdsSnapshotUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(snapshot.arn!)),
                "regionSystemName": RegionModelHelper.get(snapshot.regionId!).systemName,
                "rawId": snapshot.rawId
            });
    }

    public static getRedshiftClusterUrl(cluster: Contract.AwsRedshiftCluster) {
        return Mustache.render(
            AwsConsoleUrlBuilder._redshiftClusterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(cluster.arn!)),
                "regionSystemName": RegionModelHelper.get(cluster.regionId!).systemName,
                "name": cluster.name
            });
    }

    public static getRedshiftNamespaceUrl(namespace: Contract.AwsRedshiftNamespace) {
        return Mustache.render(
            AwsConsoleUrlBuilder._redshiftNamespaceUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(namespace.arn!)),
                "regionSystemName": RegionModelHelper.get(namespace.regionId!).systemName,
                "name": namespace.name
            });
    }

    public static getResourceGroupsTagEditorUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._resourceGroupsTagEditorUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getResourceGroupsUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._resourceGroupsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getRoute53HostedZoneUrl(route53HostedZone: Contract.AwsRoute53HostedZone) {
        return Mustache.render(
            AwsConsoleUrlBuilder._route53HostedZoneUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(route53HostedZone.arn!)),
                "rawId": route53HostedZone.rawId
            });
    }

    public static getS3BucketEditBlockPublicAccessUrl(bucket: Contract.AwsS3Bucket) {
        return Mustache.render(
            AwsConsoleUrlBuilder._s3BucketEditBlockPublicAccessUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(bucket.arn!)),
                "name": bucket.name,
                "regionSystemName": RegionModelHelper.get(bucket.regionId!).systemName
            });
    }

    public static getS3BucketEditLoggingUrl(bucket: Contract.AwsS3Bucket) {
        return Mustache.render(
            AwsConsoleUrlBuilder._s3BucketEditLoggingUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(bucket.arn!)),
                "name": bucket.name,
                "regionSystemName": RegionModelHelper.get(bucket.regionId!).systemName
            });
    }

    public static getS3BucketEditPermissionsUrl(bucket: Contract.AwsS3Bucket) {
        return Mustache.render(
            AwsConsoleUrlBuilder._s3BucketEditPermissionsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(bucket.arn!)),
                "name": bucket.name,
                "regionSystemName": RegionModelHelper.get(bucket.regionId!).systemName
            });
    }

    public static getS3BucketObjectUrl(

        bucket: Contract.AwsS3Bucket,
        objectName: string) {
        return Mustache.render(
            AwsConsoleUrlBuilder._s3BucketObjectUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(bucket.arn!)),
                "name": bucket.name,
                "regionSystemName": RegionModelHelper.get(bucket.regionId!).systemName,
                "objectName": objectName
            });
    }

    public static getS3BucketUrl(bucket: Contract.AwsS3Bucket) {
        return Mustache.render(
            AwsConsoleUrlBuilder._s3BucketUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(bucket.arn!)),
                "name": bucket.name,
                "regionSystemName": RegionModelHelper.get(bucket.regionId!).systemName
            });
    }

    public static getS3Url(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._s3UrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getSageMakerNotebookInstanceUrl(notebookInstance: Contract.AwsSageMakerNotebookInstance) {
        return Mustache.render(
            AwsConsoleUrlBuilder._sageMakerNotebookInstanceUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(notebookInstance.arn!)),
                "regionSystemName": RegionModelHelper.get(notebookInstance.regionId!).systemName,
                "name": notebookInstance.name
            });
    }

    public static getSecretsManagerSecretUrl(secret: Contract.AwsSecretsManagerSecret) {
        return Mustache.render(
            AwsConsoleUrlBuilder._secretsManagerSecretUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(secret.arn!)),
                "regionSystemName": RegionModelHelper.get(secret.regionId!).systemName,
                "name": secret.name
            });
    }

    public static getSecretsManagerUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._secretsManagerUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getSecurityHubUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._securityHubUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getSnsTopicEditUrl(topic: Contract.AwsSnsTopic) {
        return Mustache.render(
            AwsConsoleUrlBuilder._snsTopicEditUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(topic.arn!)),
                "regionSystemName": RegionModelHelper.get(topic.regionId!).systemName,
                "arn": topic.arn
            });
    }

    public static getSnsTopicUrl(topic: Contract.AwsSnsTopic) {
        return Mustache.render(
            AwsConsoleUrlBuilder._snsTopicUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(topic.arn!)),
                "regionSystemName": RegionModelHelper.get(topic.regionId!).systemName,
                "arn": topic.arn
            });
    }

    public static getSnsUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._snsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getSqsQueueEditUrl(queue: Contract.AwsSqsQueue) {
        return Mustache.render(
            AwsConsoleUrlBuilder._sqsQueueEditUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(queue.arn!)),
                "regionSystemName": RegionModelHelper.get(queue.regionId!).systemName,
                "urlEncodedUrl": queue.urlEncodedUrl
            });
    }

    public static getSqsQueueUrl(queue: Contract.AwsSqsQueue) {
        return Mustache.render(
            AwsConsoleUrlBuilder._sqsQueueUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(queue.arn!)),
                "regionSystemName": RegionModelHelper.get(queue.regionId!).systemName,
                "urlEncodedUrl": queue.urlEncodedUrl
            });
    }

    public static getSqsUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._sqsUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getSsmParameterUrl(parameter: Contract.AwsSsmParameter) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ssmParameterUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(parameter.arn!)),
                "name": parameter.name,
                "regionSystemName": RegionModelHelper.get(parameter.regionId!).systemName
            });
    }

    public static getSsmUrl(partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ssmUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType)
            });
    }

    public static getSsoGroupUrl(

        group: Contract.AwsSsoGroup,
        partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ssoGroupUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType),
                "regionSystemName": RegionModelHelper.get(group.regionId!).systemName,
                "rawId": group.rawId
            });
    }

    public static getSsoPermissionSetUrl(permissionSet: Contract.AwsSsoPermissionSet) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ssoPermissionSetUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(permissionSet.arn!)),
                "regionSystemName": RegionModelHelper.get(permissionSet.regionId!).systemName,
                "rawId": permissionSet.rawId
            });
    }

    public static getSsoUserUrl(

        user: Contract.AwsSsoUser,
        partitionType: Contract.AwsPartitionType) {
        return Mustache.render(
            AwsConsoleUrlBuilder._ssoUserUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(partitionType),
                "regionSystemName": RegionModelHelper.get(user.regionId!).systemName,
                "id": user.id
            });
    }

    public static getTimestreamDatabaseTableUrl(

        table: Contract.AwsTimestreamDatabaseTable,
        databaseName: string) {
        return Mustache.render(
            AwsConsoleUrlBuilder._timestreamDatabaseTableUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(table.arn!)),
                "regionSystemName": RegionModelHelper.get(table.regionId!).systemName,
                "databaseName": databaseName,
                "name": table.name
            });
    }

    public static getTimestreamDatabaseUrl(database: Contract.AwsTimestreamDatabase) {
        return Mustache.render(
            AwsConsoleUrlBuilder._timestreamDatabaseUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(database.arn!)),
                "regionSystemName": RegionModelHelper.get(database.regionId!).systemName,
                "name": database.name
            });
    }

    public static getWafWebAclUrl(webAcl: Contract.AwsWafWebAcl) {
        return Mustache.render(
            AwsConsoleUrlBuilder._wafWebAclUrlTemplate,
            {
                "domainName": AwsConsoleHelper.getDomainName(AwsResourceArnParser.getPartitionType(webAcl.arn!)),
                "name": webAcl.name,
                "rawId": webAcl.rawId,
                "regionSystemName": RegionModelHelper.get(webAcl.regionId!).systemName
            });
    }
}