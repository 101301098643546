﻿import { IconText, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, InfoCard, InfoItem, TenantIcon, useTheme } from "../../../../../../../../../common";

type InfoProps = {
    risk: Contract.CodeIacRisk;
};

export function Info({ risk }: InfoProps) {
    const localization =
        useLocalization(
            "views.customer.code.scans.scanRisk.info",
            () => ({
                resourceName: "Resource"
            }));

    const theme = useTheme();
    return (
        <InfoCard columns={true}>
            <InfoItem
                title={localization.resourceName()}
                value={
                    <IconText
                        icon={
                            <TenantIcon
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontSize: "18px"
                                }}
                                tenantType={
                                    risk.cloudTenantType
                                        ? risk.cloudTenantType
                                        : Contract.TenantType.Code}/>}
                        text={
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <Typography
                                    noWrap={true}
                                    sx={{ width: "fit-content" }}>
                                    {risk.resourceName}
                                </Typography>
                                <Typography
                                    noWrap={true}
                                    sx={{ lineHeight: 1.5 }}
                                    variant="subtitle1">
                                    {risk.resourceTypeSystemName}
                                </Typography>
                            </Stack>}/>}/>
        </InfoCard>);
}