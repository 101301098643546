import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DarkModeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M10.2148 1.39056C10.4541 1.70187 10.4891 2.12443 10.3042 2.47085C9.54134 3.90041 9.25816 5.53732 9.49645 7.14009C9.73474 8.74285 10.4819 10.2266 11.6277 11.3724C12.7735 12.5181 14.2572 13.2653 15.86 13.5036C17.4627 13.7419 19.0996 13.4587 20.5292 12.6958C20.8756 12.5109 21.2982 12.5459 21.6095 12.7852C21.9208 13.0246 22.0632 13.4239 21.9736 13.8062C20.8731 18.5021 16.6598 22 11.627 22C8.80854 22 6.10553 20.8804 4.11258 18.8875C2.11963 16.8945 1 14.1915 1 11.373C1 6.34021 4.49794 2.12698 9.19382 1.02642C9.57612 0.936823 9.97548 1.07926 10.2148 1.39056ZM7.7057 3.68671C4.91219 5.11479 3 8.02144 3 11.373C3 13.6611 3.90891 15.8554 5.52679 17.4733C7.14467 19.0911 9.33898 20 11.627 20C14.9786 20 17.8853 18.0878 19.3133 15.2943C18.0961 15.602 16.8227 15.6687 15.5658 15.4818C13.5389 15.1805 11.6625 14.2356 10.2135 12.7866C8.76445 11.3376 7.81955 9.46115 7.51819 7.43421C7.33133 6.17738 7.39803 4.90394 7.7057 3.68671Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}