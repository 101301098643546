﻿import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonEncryptedResourceInfoElements, useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciBlockStorageBlockVolumeStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useOciBlockStorageBlockVolumeDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useOciCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const blockVolumeModel = resourceModel as Contract.OciBlockStorageBlockVolumeModel;
    const blockVolume = blockVolumeModel.entity as Contract.OciBlockStorageBlockVolume;

    const blockStorageBlockVolumeStatusTranslator = useOciBlockStorageBlockVolumeStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciBlockStorageBlockVolumeDefinition",
            () => ({
                info: {
                    items: {
                        availabilityDomain: "Availability Domain",
                        size: "Size",
                        source: "Source",
                        status: "Status"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={blockStorageBlockVolumeStatusTranslator(blockVolume.status)}/>,
                    <InfoItem
                        key="availabilityDomain"
                        location="all"
                        title={localization.info.items.availabilityDomain()}
                        value={blockVolume.availabilityDomain}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={blockVolumeModel.sourceResourceIdReference}
                        entityTypeName={Contract.TypeNames.OciResource}
                        key="source"
                        title={localization.info.items.source()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={blockVolumeModel.instanceIdReferences}
                        entityTypeName={Contract.TypeNames.OciComputeInstance}
                        key="instances"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciComputeInstance,
                                { count: 0 })}/>,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.encryptionVaultKeyIdReference,
                    <InfoItem
                        key="size"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(blockVolume.size)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}