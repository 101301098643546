import { DataTableColumn, useExecuteOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ItemSelectionHelper, RiskController, RiskType, useRiskPolicyTitleTranslator } from "../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../useCommonCloudRiskPolicyTableDefinition";
import { RiskPolicyNameCell } from "./components";

export function useCustomDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const [{ filters }] =
        useExecuteOperation(
            [useCustomDefinition, riskPolicyConfigurationTypeName],
            () =>
                RiskController.getRiskPolicyTypeGroupFilters(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest(
                        requestFilters!,
                        riskPolicyConfigurationTypeName)));

    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useCustomDefinition",
            () => ({
                columns: {
                    riskPolicyName: "Policy"
                }
            }));

    const customRiskPolicyGroupFilters = filters as Contract.CustomRiskPolicyGroupFilters;
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();
    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: customRiskPolicyGroupFilters.riskPolicyIdItems.emptyValue }}
                                groupItemTitle={true}
                                placeholder={localization.columns.riskPolicyName()}>
                                {_.map(
                                    customRiskPolicyGroupFilters.riskPolicyIdItems.items,
                                    riskPolicyId =>
                                        <ValuesFilterItem
                                            key={riskPolicyId}
                                            title={riskPolicyTitleTranslator(riskPolicyId)}
                                            value={riskPolicyId}/>)}
                            </ValuesFilter>
                        }
                    }}
                    id={TableColumnId.CustomPolicyName}
                    key={TableColumnId.CustomPolicyName}
                    render={RiskPolicyNameCell}
                    selectorOptions={{ disabled: true }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.riskPolicyName()}/>,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerCustomRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.CustomPolicyName])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}