import { Message, SuccessIcon } from "@infrastructure";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";

type WizardFinishItemProps = {
    title: string;
    warningMessage?: WizardFinishItemWarningMessage;
};

type WizardFinishItemWarningMessage = {
    description: string;
    title: string;
};

export function WizardFinishItem({ title, warningMessage }: WizardFinishItemProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ height: "100%" }}>
            <SuccessIcon
                sx={{
                    color:
                        _.isNil(warningMessage)
                            ? theme.palette.success.main
                            : theme.palette.warning.main,
                    fontSize: "60px"
                }}/>
            <Typography
                align="center"
                sx={{
                    fontWeight: 400,
                    whiteSpace: "pre-wrap"
                }}
                variant="h2">
                {title}
            </Typography>
            {!_.isNil(warningMessage) &&
                <Box sx={{ marginTop: theme.spacing(4) }}>
                    <Message
                        description={warningMessage.description}
                        level="infoWarning"
                        title={warningMessage.title}
                        variant="contained"/>
                </Box>}
        </Stack>);
}