﻿import { DataTableColumn, ItemSelector, optionalTableCell, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../../common";
import { AwsEc2DeviceTable } from "../../../../../../../../../../../../../../../../tenants";


type RevisionsDeviceTableProps = {
    launchTemplateModel: Contract.AwsEc2LaunchTemplateModel;
    risk: Contract.AwsEc2SnapshotEncryptionDisabledRisk;
};

export function RevisionsDeviceTable({ launchTemplateModel, risk }: RevisionsDeviceTableProps) {
    const riskData = risk.data as Contract.AwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateData;
    const launchTemplate = launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate;
    const instanceModels = entityModelStore.useGet(riskData.instanceIds);
    const snapshotModels = entityModelStore.useGet(risk.aggregatedEntityIds);
    const volumeModels = entityModelStore.useGet(riskData.volumeIds);

    const [selectedRevisionId, setSelectedRevisionId] =
        useState(
            () =>
                _(_.isEmpty(risk.aggregatedEntityIds)
                    ? launchTemplateModel.revisionIdToDataMap
                    : riskData.launchTemplateRevisionIdToVolumeIdsMap).
                    keys().
                    maxBy(Number)!);
    const instanceRawIds =
        useMemo(
            () =>
                _.map(
                    instanceModels,
                    instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).rawId),
            [risk]);
    const [deviceNameToVolumeModelsMap, deviceNameToVolumeSnapshotModelsMap] =
        useMemo(
            () => {
                const deviceNameToVolumeModelsMap =
                    _(launchTemplate.revisionMap[selectedRevisionId].devices).
                        keyBy(device => device.name).
                        mapValues(
                            device =>
                                _.filter(
                                    volumeModels,
                                    volumeModel =>
                                        !_.isEmpty(
                                            _(instanceRawIds).
                                                intersection(_.keys((volumeModel.entity as Contract.AwsEc2Volume).instanceRawIdToDeviceNameMap)).
                                                filter(instanceRawId => (volumeModel.entity as Contract.AwsEc2Volume).instanceRawIdToDeviceNameMap[instanceRawId] === device.name).
                                                value()))).
                        value();
                const deviceNameToVolumeSnapshotModels =
                    _(deviceNameToVolumeModelsMap).
                        mapValues(
                            volumeModels => {
                                const volumeIds =
                                    _.map(
                                        volumeModels,
                                        volumeModel => volumeModel.id);
                                return _.filter(
                                    snapshotModels,
                                    snapshotModel =>
                                        !snapshotModel.unknown &&
                                        volumeIds.includes((snapshotModel.entity as Contract.AwsEc2Snapshot).sourceVolumeId!));
                            }).
                        value();

                return [deviceNameToVolumeModelsMap, deviceNameToVolumeSnapshotModels];
            },
            [selectedRevisionId]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotEncryptionDisabledRiskDefinition.hooks.useAwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateDefinition.revisionsDeviceTable",
            () => ({
                deviceTable: {
                    snapshots: "Snapshots",
                    title: "Block Device Mappings",
                    volumes: "Volumes"
                },
                selectedRevision: {
                    default: "{{revisionId}} (Default)",
                    latest: "Latest : {{revisionId}}"
                },
                title: "Version ({{revisionCount | NumberFormatter.humanize}})"
            }));
    return (
        <Stack
            spacing={4}
            sx={{ overflow: "hidden auto" }}>
            <Box
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <ItemSelector
                    dense={true}
                    fullWidth={true}
                    items={
                        _(_.isEmpty(risk.aggregatedEntityIds)
                            ? launchTemplateModel.revisionIdToDataMap
                            : riskData.launchTemplateRevisionIdToVolumeIdsMap).
                            keys().
                            orderBy(
                                revisionId => Number(revisionId),
                                "desc").
                            value()}
                    selectedItem={selectedRevisionId}
                    sorted={false}
                    onSelectedItemChanged={revisionId => setSelectedRevisionId(revisionId)}>
                    {revisionId =>
                        Number(revisionId) === launchTemplate.defaultRevisionId
                            ? localization.selectedRevision.default({ revisionId })
                            : Number(revisionId) === launchTemplate.latestRevisionId
                                ? localization.selectedRevision.latest({ revisionId })
                                : revisionId}
                </ItemSelector>
            </Box>
            <Stack
                spacing={1}
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <Typography
                    sx={{ width: "100%" }}
                    variant="h5">
                    {localization.deviceTable.title()}
                </Typography>
                <Box sx={{ width: "100%" }}>
                    {_.isEmpty(risk.aggregatedEntityIds)
                        ? <AwsEc2DeviceTable
                            devices={
                                _.filter(
                                    launchTemplate.revisionMap[selectedRevisionId].devices,
                                    device => device.encryptionEnabled === false)}
                            snapshotRawIdToIdMap={launchTemplateModel.snapshotRawIdToIdMap}/>
                        : <AwsEc2DeviceTable
                            additionalColumns={[
                                <DataTableColumn
                                    id={localization.deviceTable.volumes()}
                                    key={localization.deviceTable.volumes()}
                                    render={
                                        optionalTableCell<Contract.AwsEc2Device>(
                                            item =>
                                                _.isEmpty(deviceNameToVolumeModelsMap![item.name])
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={deviceNameToVolumeModelsMap![item.name]}
                                                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                                        variant="itemOrItemCountAndType"/>)}
                                    title={localization.deviceTable.volumes()}/>,
                                <DataTableColumn
                                    id={localization.deviceTable.snapshots()}
                                    key={localization.deviceTable.snapshots()}
                                    render={
                                        optionalTableCell<Contract.AwsEc2Device>(
                                            item =>
                                                _.isEmpty(deviceNameToVolumeSnapshotModelsMap![item.name])
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={deviceNameToVolumeSnapshotModelsMap![item.name]}
                                                        entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                                        variant="itemOrItemCountAndType"/>)}
                                    title={localization.deviceTable.snapshots()}/>
                            ]}
                            devices={launchTemplate.revisionMap[selectedRevisionId].devices}/>}
                </Box>
            </Stack>
        </Stack>);
}