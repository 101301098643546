import { Link, SearchTextField, StringHelper, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { CustomerConsoleAppUrlHelper, EntityAttributeItem, EntityAttributeItemDefinition, useGetEntityAttributesItemDefinition, useTheme } from "..";

type EntityAttributeDefinitionMultiSelectProps = {
    builtInEntityAttributeTypeNames?: string[];
    customEntityAttributeDefinitionIds?: string[];
    onToggleBuiltInEntityAttributeItem?: (builtInEntityAttributeTypeNames: string[]) => Promise<void>;
    onToggleCustomEntityAttributeItem?: (customEntityAttributeDefinitionId: string) => Promise<void>;
    selectedBuiltInEntityAttributeTypeNames?: string[];
    selectedCustomEntityAttributeDefinitionIds?: string[];
    title?: boolean;
};

export function EntityAttributeDefinitionList({ builtInEntityAttributeTypeNames = [], customEntityAttributeDefinitionIds = [], onToggleBuiltInEntityAttributeItem = undefined, onToggleCustomEntityAttributeItem = undefined, selectedBuiltInEntityAttributeTypeNames = [], selectedCustomEntityAttributeDefinitionIds = [], title }: EntityAttributeDefinitionMultiSelectProps) {
    const [searchText, setSearchText] = useState<string>();
    const getEntityAttributesItemDefinition = useGetEntityAttributesItemDefinition();
    const filteredEntityAttributesItemDefinitions =
        useMemo(
            () =>
                _.filter(
                    getEntityAttributesItemDefinition(builtInEntityAttributeTypeNames, customEntityAttributeDefinitionIds),
                    entityAttributesItemDefinition => StringHelper.search(entityAttributesItemDefinition.name, searchText)),
            [builtInEntityAttributeTypeNames, customEntityAttributeDefinitionIds, searchText]);

    const localization =
        useLocalization(
            "common.entityAttributeDefinitionList",
            () => ({
                empty: {
                    links: {
                        customEntityAttributeDefinition: "Settings/Labels"
                    },
                    withFilter: "No matching labels",
                    withoutFilter: "To manually label this resource, go to {{customEntityAttributeDefinitionLink}} and create manual labels"
                },
                search: "Search labels...",
                title: "Labels"
            }));

    const onToggle =
        useCallback(
            (entityAttributeItemDefinition: EntityAttributeItemDefinition) =>
                entityAttributeItemDefinition.type == "builtIn"
                    ? onToggleBuiltInEntityAttributeItem!(entityAttributeItemDefinition.typeNames!)
                    : onToggleCustomEntityAttributeItem!(entityAttributeItemDefinition.customEntityAttributeDefinitionConfiguration!.id!),
            [onToggleCustomEntityAttributeItem, onToggleBuiltInEntityAttributeItem]);

    const theme = useTheme();
    return (
        <Stack>
            {title &&
                <Typography
                    sx={{
                        borderBottom: theme.border.primary,
                        padding: theme.spacing(1, 2)
                    }}
                    variant="h5">
                    {localization.title()}
                </Typography>}
            {customEntityAttributeDefinitionIds.length > 10 &&
                <Box sx={{ padding: theme.spacing(1, 1, 0) }}>
                    <SearchTextField
                        placeholder={localization.search()}
                        searchText={searchText}
                        onSearchTextChanged={searchText => setSearchText(searchText)}/>
                </Box>}
            <Box
                sx={{
                    flex: 1,
                    padding: 0
                }}>
                <Box
                    sx={{
                        maxHeight: theme.spacing(40),
                        overflow: "hidden auto",
                        padding: theme.spacing(0.5),
                        width: "100%"
                    }}>
                    {_.isEmpty(filteredEntityAttributesItemDefinitions)
                        ? _.isEmpty(searchText)
                            ? <Typography sx={{ padding: theme.spacing(2) }}>
                                {localization.empty.withoutFilter({
                                    customEntityAttributeDefinitionLink:
                                        <Link
                                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationCustomEntityAttributeDefinitionsRelativeUrl()}>
                                            {localization.empty.links.customEntityAttributeDefinition()}
                                        </Link>
                                })}
                            </Typography>
                            : <Typography
                                align="center"
                                noWrap={true}
                                sx={{ padding: theme.spacing(2) }}>
                                {localization.empty.withFilter()}
                            </Typography>
                        : <Stack>
                            {_.map(
                                filteredEntityAttributesItemDefinitions,
                                (filteredEntityAttributesItemDefinition, filteredEntityAttributesItemDefinitionIndex) =>
                                    filteredEntityAttributesItemDefinition.type === "custom"
                                        ? <EntityAttributeItem
                                            definition={filteredEntityAttributesItemDefinition}
                                            key={filteredEntityAttributesItemDefinitionIndex}
                                            selected={
                                                _.includes(
                                                    selectedCustomEntityAttributeDefinitionIds,
                                                    filteredEntityAttributesItemDefinition.customEntityAttributeDefinitionConfiguration!.id)}
                                            onToggle={onToggle}/>
                                        : <EntityAttributeItem
                                            definition={filteredEntityAttributesItemDefinition}
                                            key={filteredEntityAttributesItemDefinitionIndex}
                                            selected={
                                                !_(selectedBuiltInEntityAttributeTypeNames).
                                                    intersection(filteredEntityAttributesItemDefinition.typeNames).
                                                    isEmpty()}
                                            onToggle={onToggle}/>)}
                        </Stack>}
                </Box>
            </Box>
        </Stack>);
}