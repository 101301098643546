import { AnalyticsEventActionType, useFilterConnectorContext, useSetFilterConnectorContext, ValuesFilterSelection } from "@infrastructure";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { ScopeMultiSelect } from ".";
import { scopeNodeModelStore } from "../stores";

type ScopeFilterProps = {
    placeholder: string;
    scopeIds: string[];
};

export function ScopeFilter({ placeholder, scopeIds }: ScopeFilterProps) {
    const { filter: scopeIdSelection, open } = useFilterConnectorContext() as {
        filter?: ValuesFilterSelection<string>;
        open: boolean;
    };
    const setFilterConnectorContext = useSetFilterConnectorContext();

    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(undefined, true);
    const scopeTreeScopeIds =
        useMemo(
            () =>
                _(scopeIds).
                    flatMap(scopeId => scopeNodeMap[scopeId].parentScopeIds).
                    concat(scopeIds).
                    uniq().
                    value(),
            [scopeIds, scopeNodeMap]);

    const onClose =
        useCallback(
            () =>
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        open: false
                    })),
            []);

    return (
        <ScopeMultiSelect
            analyticsOptions={{
                onClose: {
                    actionType: AnalyticsEventActionType.FilterValueSet,
                    propertyNameToValueMap: {
                        "Filter Name": placeholder,
                        "Filter Type": "ScopeFilter",
                        "Selected Values Count": scopeIdSelection?.values.length ?? 0
                    }
                },
                onOpen: {
                    actionType: AnalyticsEventActionType.FilterValueOpen,
                    propertyNameToValueMap: {
                        "Filter Name": placeholder,
                        "Filter Type": "ScopeFilter"
                    }
                }
            }}
            disabled={_.isEmpty(scopeTreeScopeIds)}
            open={!_.isEmpty(scopeTreeScopeIds) && open}
            placeholder={placeholder}
            scopeIds={scopeTreeScopeIds}
            selectedScopeIds={scopeIdSelection?.values ?? []}
            variant="filter"
            onClose={onClose}
            onSelectedScopeIdsChanged={
                selectedScopeIds =>
                    setFilterConnectorContext(
                        context =>
                            (
                                {
                                    ...context,
                                    filter:
                                        _.isEmpty(selectedScopeIds)
                                            ? undefined
                                            : new ValuesFilterSelection(
                                                false,
                                                selectedScopeIds)
                                }))}/>);
}