import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsKinesisStreamRiskContext() {
    return useMemo(
        () => useAwsKinesisStreamRiskContext,
        []);
}

function useAwsKinesisStreamRiskContext(streamModel: Contract.AwsKinesisStreamModel) {
    return useGetAwsResourceRiskContext()(streamModel);}