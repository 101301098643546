﻿import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, InlineItems, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, RiskType } from "../../../../../../../../../../../../../../../../../../../../../common";
import { useAwsLambdaFunctionConfigurationAccessTypeTranslator } from "../../../../../../../../../../../../../../../../../../../../../tenants";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../useCommonCloudRiskPolicyTableDefinition";

export function useAwsLambdaFunctionConfigurationPublicAccessExistsRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();
    const { requestFilters } = useItemsContext();

    const lambdaFunctionConfigurationAccessTypeTranslator = useAwsLambdaFunctionConfigurationAccessTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useComputeDefinition.hooks.useAwsLambdaFunctionConfigurationPublicAccessExistsRiskDefinition",
            () => ({
                columns: {
                    accessTypes: "Accessible Via",
                    triggers: "Triggers"
                }
            }));

    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonColumns.riskPolicyIdColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <EnumValuesFilter
                                enumType={Contract.AwsLambdaFunctionConfigurationModelAccessType}
                                enumTypeTranslator={lambdaFunctionConfigurationAccessTypeTranslator}
                                placeholder={localization.columns.accessTypes()}/>
                        }
                    }}
                    id={TableColumnId.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPublicAccessTypes}
                    key={TableColumnId.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPublicAccessTypes}
                    render={
                        optionalTableCell<Contract.AwsLambdaFunctionConfigurationPublicAccessExistsRiskModel>(
                            (item: Contract.AwsLambdaFunctionConfigurationPublicAccessExistsRiskModel) =>
                                _.isEmpty(item.risk.publicAccessTypes)
                                    ? undefined
                                    : <InlineItems
                                        items={
                                            _(item.risk.publicAccessTypes).
                                                map(lambdaFunctionConfigurationAccessTypeTranslator).
                                                sort().
                                                value()}
                                        variant="itemPlusItemCount"/>)}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.accessTypes()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedRiskFilterEntityItem(
                                        requestFilters as Contract.RiskControllerCloudRiskModelFilters,
                                        Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty.AwsLambdaFunctionConfigurationPublicAccessExistsRiskTriggerResources,
                                        riskPolicyConfigurationTypeName)}
                                placeholder={localization.columns.triggers()}/>
                        }
                    }}
                    id={TableColumnId.AwsLambdaFunctionConfigurationPublicAccessExistsRiskTriggerResources}
                    key={TableColumnId.AwsLambdaFunctionConfigurationPublicAccessExistsRiskTriggerResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsLambdaFunctionConfigurationPublicAccessExistsRiskModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.risk.triggerResourceIdReferences}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                entityVariant="iconText"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.triggers()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerAwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPublicAccessTypes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.AwsLambdaFunctionConfigurationPublicAccessExistsRiskTriggerResources])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}