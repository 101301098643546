import { IconText } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ProfileTopbarCommitInfoItem, Severity, StorageHelper, TenantIcon, tenantModelStore, useTheme } from "../../../../../../../../../common";
import { RiskProfileDefinition } from "../useProfileDefinition";
import { useCommonInfo } from "./useCommonInfo";

export function useCodeDefinition(riskModel: Contract.RiskModel): RiskProfileDefinition {
    const codeRiskModel = riskModel as Contract.CodeRiskModel;
    const commonInfo = useCommonInfo(riskModel);
    const tenantConfiguration = tenantModelStore.useGet(codeRiskModel.tenantId).configuration as Contract.CodeTenantConfiguration;

    const theme = useTheme();
    return useMemo(
        () => ({
            cardData: {
                infoElements:
                    _.filter([
                        <Severity
                            key="Severity"
                            severity={riskModel.risk.severity}
                            sx={{ fontSize: "18px" }}/>,
                        <IconText
                            icon={
                                <TenantIcon
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        fontSize: "18px"
                                    }}
                                    tenantType={
                                        _.isNil(codeRiskModel.cloudTenantType)
                                            ? Contract.TenantType.Code
                                            : codeRiskModel.cloudTenantType}/>}
                            key="Resource"
                            text={
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <Typography
                                        noWrap={true}
                                        sx={{ width: "fit-content" }}>
                                        {codeRiskModel.risk.resourceName}
                                    </Typography>
                                    <Typography
                                        noWrap={true}
                                        sx={{ lineHeight: 1.5 }}
                                        variant="subtitle1">
                                        {codeRiskModel.risk.resourceTypeSystemName}
                                    </Typography>
                                </Stack>}/>,
                        <ProfileTopbarCommitInfoItem
                            codeTenantType={tenantConfiguration.codeTenantType}
                            hash={codeRiskModel.risk.firstScanCommit.hash}
                            key="commit"
                            url={tenantConfiguration.url}/>,
                        commonInfo.openStatusUpdateTime,
                        commonInfo.statusUpdateTime,
                        commonInfo.status
                    ])
            },
            storageData: {
                openTab: StorageHelper.customerCodeRisksProfileTabsOpen
            },
            urlHelper: {
                getHashUrl: CustomerConsoleAppUrlHelper.getCodeRiskProfileHashUrl,
                getProfileUrl: CustomerConsoleAppUrlHelper.getCodeRiskProfileUrl,
                getRelativeUrl: CustomerConsoleAppUrlHelper.getCodeRiskProfileRelativeUrl
            }
        }),
        [CustomerConsoleAppUrlHelper, StorageHelper, codeRiskModel, commonInfo]);
}