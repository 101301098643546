﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { OperatorSelector } from "../../../../../../OperatorSelector";
import { PagedResourceTagSelector } from "../../../../../../PagedResourceTagSelector";
import { useCustomRiskPolicyContext } from "../../../../../CustomRiskPolicy";
import { EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";
import { useEntityMatchConditionCollectionOperatorTranslator } from "../hooks";

type TagsProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
};

export function Tags({ disabled, entityTypeName, includeServiceName }: TagsProps) {
    const { scopeId, tenantTypes } = useCustomRiskPolicyContext();
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.Tags] as Contract.EntityTagMatchCondition;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const operatorTranslator = useEntityMatchConditionCollectionOperatorTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.tags",
            () => ({
                placeholder: "Tags",
                text: {
                    part1: "*capitalize*{{translatedEntityTypeName}}** with",
                    part2: "of these tags"
                }
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.text.part1({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <OperatorSelector
                operators={_.values(Contract.EntityMatchConditionCollectionOperator)}
                operatorTranslator={operatorTranslator}
                selectedOperator={condition.operator}
                onSelectedOperatorChanged={
                    (operator: Contract.EntityMatchConditionCollectionOperator) =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.Tags]:
                                        new Contract.EntityTagMatchCondition(
                                            operator,
                                            condition.tags)
                                }
                            }))
                }/>
            <Typography>
                {localization.text.part2()}
            </Typography>
            <PagedResourceTagSelector
                disabled={disabled}
                entityTypeName={entityTypeName}
                multiSelect={true}
                placeholder={localization.placeholder()}
                scopeId={scopeId}
                selectedTags={condition.tags}
                tenantTypes={tenantTypes}
                onSelectedTagsChanged={
                    tags =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.Tags]:
                                        new Contract.EntityTagMatchCondition(
                                            condition.operator,
                                            tags)
                                }
                            }))}/>
        </Stack>);
}