import { Dictionary } from "lodash";
import { ConfigurationController, Contract } from "..";

export class FeatureHelper {
    private static featureNameToEnabledMap: Dictionary<boolean> = {};

    public static async initialize() {
        const { featureNameToEnabledMap } = await ConfigurationController.getFeatures();
        FeatureHelper.featureNameToEnabledMap = featureNameToEnabledMap;
    }

    public static enabled(featureName: Contract.FeatureName) {
        return FeatureHelper.featureNameToEnabledMap[featureName];
    }
}