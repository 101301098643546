import { Function0 } from "lodash";
import { EffectCallback, useRef, useState } from "react";
import { useDebouncedEffect } from ".";

export function useChangeEffect(
    effect: EffectCallback | Function0<Promise<void>>,
    dependencies?: any[],
    debounceTimeSpan?: number) {
    const firstEffectRef = useRef(true);
    const [, setError] = useState();
    useDebouncedEffect(
        () => {
            if (firstEffectRef.current) {
                firstEffectRef.current = false;
                return;
            }

            const result = effect();
            if (result instanceof Promise) {
                result.catch(error => setError(() => { throw error; }));
            } else {
                return result;
            }
        },
        debounceTimeSpan,
        dependencies);
}