﻿import { DataTableColumnRenderProps, TimeFormatter, TimeHelper, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../common";

export function TimeCell({ item }: DataTableColumnRenderProps<Contract.EventModel>) {
    const localization =
        useLocalization(
            "views.customer.events.timeCell",
            () => ({
                range: "{{startTime | TimeFormatter.shortTime}} - {{endTime | TimeFormatter.shortTime}} ({{aggregatedEventCount | NumberFormatter.humanize}} occurrences)"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography noWrap={true}>
                {TimeFormatter.mediumDate(item.event.time)}
            </Typography>
            <Typography noWrap={true}>
                {_.isNil(item.event.aggregatedEventCount) ||
                    item.event.aggregatedEventCount === 1
                    ? TimeFormatter.longTime(item.event.time)
                    : localization.range({
                        aggregatedEventCount: item.event.aggregatedEventCount,
                        endTime: TimeHelper.addTime(item.event.time, 1, "minutes"),
                        startTime: item.event.time
                    })}
            </Typography>
        </Stack>);
}