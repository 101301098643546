import { useLocalization } from "@infrastructure";
import React from "react";
import { CodeServerIcon, Contract, scopeSystemEntityModelStore } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { GitHubServer, GitLabServer } from "./components";

export function useCodeServerItems(): IntegrationItem[] {
    const gitHubServerModels = scopeSystemEntityModelStore.useGetCodeServer(Contract.CodeTenantType.GitHub);
    const gitLabServerModels = scopeSystemEntityModelStore.useGetCodeServer(Contract.CodeTenantType.GitLab);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems",
            () => ({
                description: "{{count | NumberFormatter.humanize}} configured",
                [Contract.TypeNames.IntegrationType]: {
                    [Contract.IntegrationType.GitHubServer]: "GitHub Enterprise Server",
                    [Contract.IntegrationType.GitLabServer]: "GitLab Self-Managed Server"
                }
            }));

    return [
        new IntegrationItem(
            IntegrationItemCategory.CodeServers,
            gitHubServerModels,
            [localization.description({ count: gitHubServerModels.length })],
            <CodeServerIcon serverType={Contract.CodeTenantType.GitHub}/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.GitHubServer](),
            Contract.IntegrationType.GitHubServer,
            <GitHubServer/>
        ),
        new IntegrationItem(
            IntegrationItemCategory.CodeServers,
            gitLabServerModels,
            [localization.description({ count: gitLabServerModels.length })],
            <CodeServerIcon serverType={Contract.CodeTenantType.GitLab}/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.GitLabServer](),
            Contract.IntegrationType.GitLabServer,
            <GitLabServer/>
        )
    ];
}

export type CodeServerQueryParameters = {
    addOrEditOpen?: string;
};