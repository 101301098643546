import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class OktaTenantsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.OktaTenantConfiguration) {
    }
}

export const [useOktaTenantsContext, useSetOktaTenantsContext, useOktaTenantsContextProvider] = makeContextProvider<OktaTenantsContext>();

export function OktaTenants() {
    const [context, setContext, ContextProvider] = useOktaTenantsContextProvider(() => new OktaTenantsContext(false));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={() => setContext(new OktaTenantsContext(false))}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}