﻿import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

type InfoBoxProps = {
    children?: ReactNode;
    iconElement: ReactElement;
    title: string;
};

export function InfoBox({ children, iconElement, title }: InfoBoxProps) {
    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                padding: theme.spacing(1),
                width: "100%"
            }}>
            <Stack
                direction="row"
                spacing={1}
                sx={{ padding: theme.spacing(1, 1, 0, 1) }}>
                {iconElement}
                <Typography sx={{ fontWeight: 600 }}>
                    {title}
                </Typography>
            </Stack>
            <Box sx={{ paddingLeft: theme.spacing(4) }}>
                {children}
            </Box>
        </Stack>);
}