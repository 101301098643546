import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { EntitySelector, EntitySelectorSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsIdentityActivityRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const { scopeId } = useCustomRiskPolicyContext();
    const [identitiesValid, setIdentitiesValid] = useState(false);

    const [identitySelectorSelection, setIdentitySelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                undefined,
                undefined,
                undefined,
                riskPolicyConfiguration?.identityBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.identityCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.identityIds,
                undefined,
                undefined,
                riskPolicyConfiguration?.identityTags,
                undefined));
    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityTags } = identitySelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAwsIdentityActivityRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityTags);
                }
        });

    useEffect(
        () => {
            const identitiesValid = identitySelectorSelection?.valid() ?? false;
            setIdentitiesValid(identitiesValid);
            onValidChange(identitiesValid);
        },
        [identitySelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsIdentityActivityRiskPolicy.edit",
            () => ({
                prompt: "Select the required identities"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        identities:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.AwsIamIdentity}
                                selection={identitySelectorSelection}/>
                    })}
                </Typography>
                <Stack>
                    <Accordion>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AwsIamIdentity,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {identitiesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.AdministratorPrincipalAttribute,
                                    Contract.TypeNames.BehaviorRiskIdentityAttribute,
                                    Contract.TypeNames.CredentialsDisabledUserAttribute,
                                    Contract.TypeNames.InactiveIdentityAttribute,
                                    Contract.TypeNames.MfaDisabledUserAttribute,
                                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.AwsAccessKeyEnabledUserAttribute,
                                    Contract.TypeNames.RelatedPublicComputeAttribute,
                                    Contract.TypeNames.VendorServiceIdentityAttribute,
                                    Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute,
                                    Contract.TypeNames.CodeResourceAttribute
                                ]}
                                entityTypeName={Contract.TypeNames.AwsIamIdentity}
                                properties={["tags", "attributes"]}
                                scopeId={scopeId}
                                selection={identitySelectorSelection}
                                onSelectionChanged={setIdentitySelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                </Stack>
            </Stack>
        </Stack>);
}