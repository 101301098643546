import { InlineItems, Message, Steps, StringHelper, useInputValidation, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AwsResourceArnParser, RadioGroup, useTheme } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";

export type TrailBucketProps = {
    executing: boolean;
    onValidChanged: (valid: boolean) => void;
};

export function TrailBucket({ executing, onValidChanged }: TrailBucketProps) {
    const { tenantModel, tenantTrailBucket } = useAddOrEditContext();

    const [trailBucketName, setTrailBucketName] = useState(tenantTrailBucket?.name);
    const [trailEncryptionKeyArn, setTrailEncryptionKeyArn] = useState(tenantTrailBucket?.encryptionKeyArn ?? "");
    const [trailEncryptionKeyArnEnabled, setTrailEncryptionKeyArnEnabled] = useState(!_.isNil(tenantTrailBucket?.encryptionKeyArn));

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.permissionsItem.trailBucket",
            () => ({
                encryptionKeyArn: {
                    error: {
                        format: "KMS key ARN is not in the correct format",
                        required: "KMS key ARN cannot be empty"
                    },
                    title: "KMS key ARN"
                },
                encryptionKeyArnEnabled: {
                    false: "No",
                    title: "Is the trail bucket encrypted with AWS KMS?",
                    true: "Yes"
                },
                name: {
                    error: {
                        format: "Bucket name is not in a correct S3 bucket name format",
                        length: "Bucket name must be between 3 and 255 characters",
                        required: "Bucket name cannot be empty"
                    },
                    title: "Bucket name"
                },
                trailBucketNameToEncryptionKeyArnsMap: {
                    bucketWithKeys: "{{trailBucketName}}: {{trailBucketEncryptionKeyArns}}",
                    encryptionKeyNamePluralizer: [
                        "1 encryption key",
                        "{{count | NumberFormatter.humanize}} encryption keys"
                    ],
                    title: "We have identified the following buckets that contain CloudTrail logs associated with your onboarded accounts"
                }
            }));

    const [trailBucketNameValidationController, trailBucketNameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = trailBucketName?.trim();
                if (_.isNil(validationName) || _.isEmpty(validationName)) {
                    return _.isEmpty(tenantModel?.state.eventAnalysis.trailBucketNameToEncryptionKeyArnsMap)
                        ? localization.name.error.required()
                        : undefined;
                } else if (validationName.length < 3 || validationName.length > 255) {
                    return localization.name.error.length();
                } else if (!/[a-zA-Z\d\.\-_]/.test(validationName)) {
                    return localization.name.error.format();
                }

                return undefined;
            },
            [trailBucketName]);

    const [cloudTrailEncryptionKeyArnValidationController, cloudTrailEncryptionKeyArnValidationMessage] =
        useInputValidation(
            () => {
                const validationEncryptionKeyArn = trailEncryptionKeyArn?.trim();
                if (_.isNil(validationEncryptionKeyArn) || _.isEmpty(validationEncryptionKeyArn)) {
                    return localization.encryptionKeyArn.error.required();
                } else if (!AwsResourceArnParser.validateKmsKey(validationEncryptionKeyArn)) {
                    return localization.encryptionKeyArn.error.format();
                }

                return undefined;
            },
            [trailEncryptionKeyArn]);

    const setAddOrEditContext = useSetAddOrEditContext();
    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantTrailBucket:
                        _.isNil(trailBucketName)
                            ? undefined
                            : {
                                encryptionKeyArn:
                                    trailEncryptionKeyArnEnabled
                                        ? trailEncryptionKeyArn
                                        : undefined,
                                name: trailBucketName
                            }
                }));

            onValidChanged(
                (!trailEncryptionKeyArnEnabled || cloudTrailEncryptionKeyArnValidationController.isValid()) &&
                trailBucketNameValidationController.isValid());
        },
        [trailBucketName, trailEncryptionKeyArn, trailEncryptionKeyArnEnabled]);

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ paddingLeft: theme.spacing(5) }}>
            <Stack spacing={1}>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={executing}
                        label={localization.name.title()}
                        value={trailBucketName}
                        variant="outlined"
                        onChange={event => setTrailBucketName(event.target.value)}/>
                    {!_.isNil(trailBucketNameValidationMessage) &&
                        <FormHelperText error={true}>{trailBucketNameValidationMessage}</FormHelperText>}
                </FormControl>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Typography variant="h5">
                        {localization.encryptionKeyArnEnabled.title()}
                    </Typography>
                    <RadioGroup
                        items={[
                            {
                                label: localization.encryptionKeyArnEnabled.true(),
                                value: true
                            },
                            {
                                label: localization.encryptionKeyArnEnabled.false(),
                                value: false
                            }
                        ]}
                        selectedValue={trailEncryptionKeyArnEnabled}
                        onChange={value => setTrailEncryptionKeyArnEnabled(StringHelper.isTrue(value))}/>
                </Stack>
                {trailEncryptionKeyArnEnabled && (
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            label={localization.encryptionKeyArn.title()}
                            value={trailEncryptionKeyArn}
                            variant="outlined"
                            onChange={event => setTrailEncryptionKeyArn(event.target.value)}/>
                        {!_.isNil(cloudTrailEncryptionKeyArnValidationMessage) && (
                            <FormHelperText error={true}>{cloudTrailEncryptionKeyArnValidationMessage}</FormHelperText>)}
                    </FormControl>)}
            </Stack>
            {!_.isEmpty(tenantModel?.state.eventAnalysis.trailBucketNameToEncryptionKeyArnsMap) &&
                <Stack
                    spacing={1}
                    sx={{
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(1)
                    }}>
                    <Message
                        level="info"
                        title={localization.trailBucketNameToEncryptionKeyArnsMap.title()}/>
                    <Steps sx={{ paddingLeft: theme.spacing(2) }}>
                        {_.map(
                            tenantModel!.state.eventAnalysis.trailBucketNameToEncryptionKeyArnsMap,
                            (trailBucketEncryptionKeyArns, trailBucketName) =>
                                _.isEmpty(trailBucketEncryptionKeyArns)
                                    ? trailBucketName
                                    : localization.trailBucketNameToEncryptionKeyArnsMap.bucketWithKeys({
                                        trailBucketEncryptionKeyArns:
                                            <InlineItems
                                                items={trailBucketEncryptionKeyArns}
                                                namePluralizer={localization.trailBucketNameToEncryptionKeyArnsMap.encryptionKeyNamePluralizer}
                                                variant="itemCountAndType"/>,
                                        trailBucketName
                                    }))}
                    </Steps>
                </Stack>}
        </Stack>);
}