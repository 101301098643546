﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CodeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M15.2983 0.0455431C15.8254 0.210276 16.1192 0.771153 15.9545 1.2983L9.28781 22.6316C9.12308 23.1588 8.5622 23.4526 8.03506 23.2878C7.50791 23.1231 7.21412 22.5622 7.37885 22.0351L14.0455 0.701748C14.2103 0.174603 14.7711 -0.11919 15.2983 0.0455431ZM5.70519 5.62434C6.09677 6.0138 6.09848 6.64696 5.70902 7.03854L3.25605 9.50485C2.72095 10.0434 2.39136 10.378 2.18349 10.652C1.99887 10.8953 1.99977 10.9781 1.99999 10.9983C1.99999 10.999 2 10.9995 2 11C2 11.0005 1.99999 11.0011 1.99999 11.0017C1.99977 11.0219 1.99887 11.1048 2.18349 11.3481C2.39136 11.6221 2.72059 11.9563 3.25569 12.4948L5.70902 14.9615C6.09848 15.3531 6.09677 15.9862 5.70519 16.3757C5.31361 16.7652 4.68044 16.7635 4.29098 16.3719L1.79125 13.8585C1.31447 13.3787 0.888358 12.95 0.590174 12.557C0.264836 12.1281 0 11.6271 0 11C0 10.3729 0.264836 9.8719 0.590174 9.44309C0.888357 9.05008 1.31446 8.6213 1.79125 8.14153C1.80654 8.12614 1.82189 8.1107 1.83728 8.0952L4.29098 5.62817C4.68044 5.23659 5.31361 5.23487 5.70519 5.62434ZM17.6281 5.62434C18.0197 5.23487 18.6529 5.23659 19.0424 5.62817L21.5421 8.14154C22.0189 8.62131 22.445 9.05008 22.7432 9.44309C23.0685 9.8719 23.3333 10.3729 23.3333 11C23.3333 11.6271 23.0685 12.1281 22.7432 12.557C22.445 12.95 22.0189 13.3787 21.5421 13.8585L19.0424 16.3719C18.6529 16.7635 18.0197 16.7652 17.6281 16.3757C17.2366 15.9862 17.2348 15.3531 17.6243 14.9615L20.0773 12.4952C20.0772 12.4953 20.0774 12.4951 20.0773 12.4952C20.6124 11.9566 20.942 11.6221 21.1498 11.3481C21.3345 11.1048 21.3336 11.0219 21.3333 11.0017C21.3333 11.0011 21.3333 11.0005 21.3333 11C21.3333 10.9995 21.3333 10.999 21.3333 10.9983C21.3336 10.9781 21.3345 10.8953 21.1498 10.652C20.942 10.378 20.6127 10.0438 20.0776 9.50521C20.0775 9.50509 20.0778 9.50533 20.0776 9.50521L17.6243 7.03854C17.2348 6.64696 17.2366 6.0138 17.6281 5.62434Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}