import { Box, Button, CircularProgress, Divider, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { EditIcon, Expand, KeyCodes, MailIcon, MailParser, MouseHelper, PasswordIcon, setUrlQueryParameters, useChangeEffect, useLocalization } from "@infrastructure";
import { SignInQueryParameters } from "../..";
import { AuthenticationController, Contract } from "../../../../controllers";
import { LogoIcon } from "../../../../icons";
import { useTheme } from "../../../../themes";
import { AccessHelper, ConsoleApiUrlHelper, UrlHelper } from "../../../../utilities";
import { GoogleIcon, MicrosoftIcon } from "../../icons";
import { ElementId } from "./Authentication.element";

type AuthenticationProps = {
    consoleAppType: Contract.ConsoleAppType;
    disabled?: boolean;
    enableTenable: boolean;
};

export function Authentication({ consoleAppType, disabled, enableTenable }: AuthenticationProps) {
    const userMailInputElementRef = useRef<HTMLInputElement>();
    const [cognitoIdentityProvider, setCognitoIdentityProvider] = useState(false);
    const [userMail, setUserMail] = useState<string>();
    const [userPassword, setUserPassword] = useState<string>("");
    const [authenticationExecuting, setAuthenticationExecuting] = useState(false);
    const [authenticationError, setAuthenticationError] = useState<AuthenticationError>();
    const authenticate =
        async () => {
            if (_.isEmpty(userMail) ||
                (cognitoIdentityProvider && _.isEmpty(userPassword))) {
                return;
            }

            setAuthenticationExecuting(true);
            setAuthenticationError(undefined);

            let redirectingToIdentityProvider = false;
            try {
                if (cognitoIdentityProvider) {
                    const { redirectUrl, result } =
                        await AuthenticationController.signInCognitoUser(
                            new Contract.AuthenticationControllerSignInCognitoUserRequest(
                                UrlHelper.getRedirectUrlQueryParameters().redirectUrl,
                                userMail!,
                                userPassword));
                    switch (result) {
                        case Contract.AuthenticationControllerSignInCognitoUserResponseResult.CustomerMaintenance:
                        case Contract.AuthenticationControllerSignInCognitoUserResponseResult.FailureUnauthorized:
                            window.location.assign(redirectUrl!);
                            break;
                        case Contract.AuthenticationControllerSignInCognitoUserResponseResult.Success:
                            window.location.assign(redirectUrl!);
                            redirectingToIdentityProvider = true;
                            break;
                        case Contract.AuthenticationControllerSignInCognitoUserResponseResult.SuccessChangePasswordRequired:
                            setUrlQueryParameters<SignInQueryParameters>({
                                changePasswordRecovery: false,
                                changePasswordUserMail: userMail!
                            });
                            break;
                        default:
                            setAuthenticationError(AuthenticationError.InvalidUserCredentials);
                    }
                } else {
                    if (!MailParser.validate(userMail!)) {
                        setAuthenticationError(AuthenticationError.InvalidUserMailFormat);
                        return;
                    }

                    const { aadIdentityProviderEnabled, cognitoIdentityProviderEnabled, gciIdentityProviderEnabled, samlIdentityProviderIssuerId } =
                        await AuthenticationController.getUserIdentityProviders(
                            new Contract.AuthenticationControllerGetUserIdentityProvidersRequest(userMail!));
                    if (!_.isNil(samlIdentityProviderIssuerId)) {
                        window.location.assign(
                            ConsoleApiUrlHelper.getSignInSamlUserRelativeUrl(
                                consoleAppType,
                                samlIdentityProviderIssuerId));
                        redirectingToIdentityProvider = true;
                    } else if (cognitoIdentityProviderEnabled) {
                        setCognitoIdentityProvider(true);
                    } else if (aadIdentityProviderEnabled) {
                        window.location.assign(
                            ConsoleApiUrlHelper.getSignInAadUserRelativeUrl(
                                consoleAppType,
                                userMail));
                        redirectingToIdentityProvider = true;
                    } else if (gciIdentityProviderEnabled) {
                        window.location.assign(
                            ConsoleApiUrlHelper.getSignInGciUserRelativeUrl(
                                consoleAppType,
                                userMail));
                        redirectingToIdentityProvider = true;
                    } else {
                        userMailInputElementRef.current?.focus();
                        setAuthenticationError(AuthenticationError.NoIdentityProviders);
                    }
                }
            } catch {
                setAuthenticationError(
                    cognitoIdentityProvider
                        ? AuthenticationError.InvalidUserCredentials
                        : AuthenticationError.NoIdentityProviders);
            } finally {
                if (!redirectingToIdentityProvider) {
                    setAuthenticationExecuting(false);
                }
            }
        };

    const recoverPassword =
        async () => {
            if (!cognitoIdentityProvider) {
                return;
            }

            if (!MailParser.validate(userMail!)) {
                setAuthenticationError(AuthenticationError.InvalidUserMailFormat);
            }

            setAuthenticationExecuting(true);
            setAuthenticationError(undefined);

            try {
                const { success } = await AuthenticationController.startRecoverCognitoUserPassword(new Contract.AuthenticationControllerStartRecoverCognitoUserPasswordRequest(userMail!));
                if (success) {
                    setUrlQueryParameters<SignInQueryParameters>({
                        changePasswordRecovery: true,
                        changePasswordUserMail: userMail!
                    });
                } else {
                    setAuthenticationError(AuthenticationError.PasswordRecoveryFailed);
                }
            } catch {
                setAuthenticationError(AuthenticationError.PasswordRecoveryFailed);
            } finally {
                setAuthenticationExecuting(false);
            }
        };

    useEffect(
        () => AccessHelper.resetDashboardStorageItems(),
        []);

    useChangeEffect(
        () => {
            setAuthenticationError(undefined);
        },
        [userMail, userPassword]);

    const localization =
        useLocalization(
            "common.signIn.authentication",
            () => ({
                actions: {
                    next: "Next",
                    recovery: "Forgot password?",
                    signIn: "Sign in"
                },
                fields: {
                    error: {
                        [AuthenticationError.InvalidUserCredentials]: "Incorrect user credentials",
                        [AuthenticationError.InvalidUserMailFormat]: "Please enter a valid mail address",
                        [AuthenticationError.NoIdentityProviders]: "Couldn't find your mail address",
                        [AuthenticationError.PasswordRecoveryFailed]: "Failed to start password recovery"
                    },
                    mail: {
                        title: "Mail Address"
                    },
                    password: {
                        title: "Enter password"
                    }
                },
                oidc: {
                    aad: {
                        actions: {
                            signIn: "Sign in with Microsoft"
                        }
                    },
                    gci: {
                        actions: {
                            signIn: "Sign in with Google"
                        }
                    },
                    title: "or"
                },
                tenable: {
                    signIn: "Sign in with Tenable One"
                },
                title: {
                    commonConsoleApp: "Tenable Cloud Security",
                    userConsoleApp: "JIT Portal"
                }
            }));

    const userConsoleApp = consoleAppType === Contract.ConsoleAppType.User;

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            spacing={5}
            sx={{ width: theme.spacing(65) }}>
            <Stack
                alignItems="center"
                spacing={1}>
                <Typography
                    sx={{
                        fontSize: "30px",
                        fontWeight: 600
                    }}>
                    {localization.title.commonConsoleApp()}
                </Typography>
                {userConsoleApp && (
                    <Typography sx={{ fontSize: "30px" }}>
                        {localization.title.userConsoleApp()}
                    </Typography>)}
            </Stack>
            <Stack
                spacing={5}
                sx={{ width: theme.spacing(40) }}>
                <Stack spacing={0}>
                    {cognitoIdentityProvider
                        ? <TextField
                            fullWidth={true}
                            name="email"
                            slotProps={{
                                input: {
                                    disableUnderline: true,
                                    endAdornment:
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                color: theme.important(theme.palette.text.primary),
                                                cursor: "pointer",
                                                fontSize: "18px"
                                            }}
                                            onClick={() => setCognitoIdentityProvider(false)}>
                                            <EditIcon/>
                                        </InputAdornment>,
                                    readOnly: true,
                                    startAdornment:
                                        <InputAdornment
                                            position="start"
                                            sx={{
                                                color: theme.important(theme.palette.text.primary),
                                                fontSize: "24px"
                                            }}>
                                            <MailIcon/>
                                        </InputAdornment>
                                }
                            }}
                            sx={{
                                ".MuiInput-input:read-only": {
                                    color: theme.palette.text.primary
                                }
                            }}
                            type="email"
                            value={userMail}
                            variant="standard"/>
                        : <TextField
                            autoComplete="on"
                            autoFocus={true}
                            disabled={authenticationExecuting || disabled}
                            fullWidth={true}
                            id={ElementId.userMailField}
                            inputRef={userMailInputElementRef}
                            name="email"
                            placeholder={localization.fields.mail.title()}
                            slotProps={{
                                input: {
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <MailIcon sx={{ fontSize: "18px" }}/>
                                        </InputAdornment>
                                }
                            }}
                            type="email"
                            value={userMail ?? ""}
                            variant="outlined"
                            onChange={event => setUserMail(event.target.value)}
                            onKeyPress={
                                event => {
                                    if (event.charCode === KeyCodes.Enter) {
                                        authenticate();
                                    }
                                }}/>}
                    <Expand
                        expanded={cognitoIdentityProvider}
                        timeout={{ enter: 250, exit: 250 }}>
                        <TextField
                            disabled={authenticationExecuting || disabled}
                            fullWidth={true}
                            id={ElementId.userPasswordField}
                            placeholder={localization.fields.password.title()}
                            slotProps={{
                                input: {
                                    startAdornment:
                                        <InputAdornment
                                            position="start"
                                            sx={{ fontSize: "24px" }}>
                                            <PasswordIcon/>
                                        </InputAdornment>
                                }
                            }}
                            sx={{ marginTop: theme.spacing(3) }}
                            type="password"
                            value={userPassword}
                            variant="outlined"
                            onChange={event => setUserPassword(event.target.value)}
                            onKeyPress={
                                event => {
                                    if (event.charCode === KeyCodes.Enter) {
                                        authenticate();
                                    }
                                }}/>
                    </Expand>
                    {cognitoIdentityProvider && (
                        <Typography
                            sx={{
                                "&:hover": {
                                    textDecorationLine: "underline"
                                },
                                cursor: "pointer",
                                fontSize: "10px",
                                marginLeft: theme.spacing(0.5)
                            }}
                            onClick={recoverPassword}>
                            {localization.actions.recovery()}
                        </Typography>)}
                    {authenticationError && (
                        <FormHelperText error={true}>
                            {localization.fields.error[authenticationError]()}
                        </FormHelperText>)}
                    <Button
                        color="primary"
                        disabled={
                            _.isEmpty(userMail) ||
                            authenticationExecuting ||
                            (cognitoIdentityProvider && _.isEmpty(userPassword) ||
                                disabled)}
                        fullWidth={true}
                        id={ElementId.submitButton}
                        size="large"
                        sx={{
                            height: theme.spacing(6.25),
                            marginTop: theme.spacing(2.5)
                        }}
                        type="submit"
                        onClick={() => authenticate()}>
                        {cognitoIdentityProvider
                            ? localization.actions.signIn()
                            : localization.actions.next()}
                    </Button>
                </Stack>
                <Stack
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="center">
                    <Divider
                        flexItem={true}
                        sx={{
                            alignSelf: "center",
                            flexGrow: 1
                        }}/>
                    <Typography
                        align="center"
                        sx={{ mx: 2 }}
                        variant="h4">
                        {localization.oidc.title()}
                    </Typography>
                    <Divider
                        flexItem={true}
                        sx={{
                            alignSelf: "center",
                            flexGrow: 1
                        }}/>
                </Stack>
                <Stack spacing={2}>
                    {_<AuthenticationIdentityProviderInfo>([]).
                        concatIf(
                            enableTenable,
                            {
                                icon: <LogoIcon/>,
                                title: localization.tenable.signIn(),
                                url: UrlHelper.tenableSignInUrl
                            }).
                        concat(
                            {
                                icon: <MicrosoftIcon/>,
                                title: localization.oidc.aad.actions.signIn(),
                                url: ConsoleApiUrlHelper.getSignInAadUserRelativeUrl(consoleAppType)
                            },
                            {
                                icon: <GoogleIcon/>,
                                title: localization.oidc.gci.actions.signIn(),
                                url: ConsoleApiUrlHelper.getSignInGciUserRelativeUrl(consoleAppType)
                            }).
                        map(
                            ({ icon, title, url }) =>
                                <Button
                                    color="primary"
                                    disabled={authenticationExecuting || disabled}
                                    key={url}
                                    size="large"
                                    startIcon={icon}
                                    sx={{
                                        ".MuiButton-startIcon": {
                                            marginRight: theme.spacing(1)
                                        },
                                        display: "flex",
                                        fontWeight: 400,
                                        height: theme.spacing(6.25),
                                        justifyContent: "flex-start",
                                        paddingLeft: theme.spacing(2.75)
                                    }}
                                    type="submit"
                                    variant="outlined"
                                    onClick={
                                        event => {
                                            setAuthenticationExecuting(true);
                                            if (MouseHelper.isAlterActionClick(event)) {
                                                url += "&localEnvironment=true";
                                            }
                                            window.location.assign(url);
                                        }}>
                                    {title}
                                </Button>).
                        value()}
                    <Box sx={{ position: "relative" }}>
                        {authenticationExecuting && (
                            <CircularProgress
                                size={theme.spacing(5)}
                                sx={{
                                    left: `calc(50% - ${theme.spacing(2.5)})`,
                                    marginTop: theme.spacing(2),
                                    position: "absolute"
                                }}/>)}
                    </Box>
                </Stack>
            </Stack>
        </Stack>);
}

enum AuthenticationError {
    InvalidUserCredentials = "invalidUserCredentials",
    InvalidUserMailFormat = "invalidUserMailFormat",
    NoIdentityProviders = "noIdentityProviders",
    PasswordRecoveryFailed = "passwordRecoveryFailed"
}

type AuthenticationIdentityProviderInfo = {
    icon: ReactNode;
    title: string;
    url: string;
};