import { useMemo } from "react";
import { useGcpAssetGenericResourceDefinition, useGcpComputeVpcPeeringConnectionDefinition, useGcpDefaultResourceDefinition, useGcpIamRoleDefinition, useGcpKubernetesResourceDefinition, useGcpScopeResourceDefinition } from ".";
import { Contract, TypeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpResourceDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.GcpComputeVpcPeeringConnection:
                        return useGcpComputeVpcPeeringConnectionDefinition;
                    case Contract.TypeNames.GcpIamRole:
                        return useGcpIamRoleDefinition;
                    default:
                        if (TypeHelper.extendOrImplement(definitionData.entityTypeEntitiesViewName, Contract.TypeNames.GcpAssetGenericResource)) {
                            return useGcpAssetGenericResourceDefinition;
                        }
                        if (TypeHelper.extendOrImplement(definitionData.entityTypeEntitiesViewName, Contract.TypeNames.GcpKubernetesResource)) {
                            return useGcpKubernetesResourceDefinition;
                        }
                        if (TypeHelper.extendOrImplement(definitionData.entityTypeEntitiesViewName, Contract.TypeNames.GcpScopeResource)) {
                            return useGcpScopeResourceDefinition;
                        }

                        return useGcpDefaultResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}