import { AnalyticsEventActionType, DataTableColumnRenderProps, formatFilterQueryParameters, Link, useTrackAnalytics, ValuesFilterSelection } from "@infrastructure";
import { Stack, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef } from "react";
import { usePrincipalsContext } from "../../../../../..";
import { Contract, CustomerConsoleAppUrlHelper, ToolbarFilterId, TopItems, TopItemsActions, TypeHelper, useActionRiskCategoryTranslator, useTheme } from "../../../../../../../../../../../../common";
import { ProfileCategory } from "../../../../../../../../../Entities/components/Profile/hooks";
import { AccessView } from "../../../../../../../../hooks";
import { ActionRiskCategoryIcon } from "./components";

export function ActionRiskCategoriesCell({ item }: DataTableColumnRenderProps<Contract.IPrincipalModel>) {
    const { view } = usePrincipalsContext();
    const actionRiskCategoryTranslator = useActionRiskCategoryTranslator();
    const categoryItems =
        useMemo(
            (): CategoryItem[] => {
                const actionRiskCategoryToSeverityMap =
                    view === AccessView.PrincipalExcessivePermissions
                        ? item.access!.permissions!.excessivePermissions!.actionRiskCategoryToSeverityMap
                        : item.access!.permissions!.permissions.actionRiskCategoryToSeverityMap;
                return _(actionRiskCategoryToSeverityMap).
                    map(
                        (actionRiskSeverity, actionRiskCategory) => ({
                            actionRiskCategory: actionRiskCategory as Contract.ActionRiskCategory,
                            actionRiskSeverity,
                            ...actionRiskCategoryTranslator(actionRiskCategory as Contract.ActionRiskCategory)
                        })).
                    orderBy(
                        [
                            ({ actionRiskSeverity }) => TypeHelper.getEnumValue(Contract.TypeNames.Severity, actionRiskSeverity),
                            ({ initials }) => initials.toUpperCase()
                        ],
                        [
                            "desc",
                            "asc"
                        ]).
                    value();
            },
            []);

    function getCategoryItemUrl(categoryItem: CategoryItem) {
        const queryParams =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [ToolbarFilterId.PermissionActionSeverity]:
                        new ValuesFilterSelection(
                            false,
                            [categoryItem.actionRiskSeverity]),
                    [ToolbarFilterId.PermissionActionRiskCategories]:
                        new ValuesFilterSelection(
                            false,
                            [categoryItem.actionRiskCategory])
                });

        return `${CustomerConsoleAppUrlHelper.getEntityProfileHashUrl(item.entity.id, { category: ProfileCategory.Iam })}?${queryParams}`;
    }

    const topItemsActionsRef = useRef<TopItemsActions>();
    const trackAnalytics = useTrackAnalytics();
    const theme = useTheme();
    return (
        <TopItems
            actionsRef={topItemsActionsRef}
            getItemId={(item: CategoryItem) => item.actionRiskCategory}
            items={categoryItems}
            itemSizeForLimitCalculations={56}
            orderVariant="leftToRight"
            variant="dynamic">
            {categoryItem =>
                <Tooltip title={categoryItem.title}>
                    <Stack>
                        <Link
                            disabled={item.unknown}
                            sx={{
                                textDecoration: "none"
                            }}
                            urlOrGetUrl={getCategoryItemUrl(categoryItem)}
                            onClick={
                                () => {
                                    trackAnalytics(
                                        AnalyticsEventActionType.AccessPermissionCategoryClick,
                                        { "Category": categoryItem.title });
                                    topItemsActionsRef.current?.close();
                                }}>
                            <Stack
                                direction="row"
                                spacing={0.5}
                                sx={{
                                    alignItems: "center",
                                    border: theme.border.primary,
                                    borderColor: theme.palette.severity(categoryItem.actionRiskSeverity),
                                    borderRadius: theme.spacing(0.75),
                                    height: theme.spacing(2.75),
                                    justifyContent: "center",
                                    width: theme.spacing(5.5)
                                }}>
                                <ActionRiskCategoryIcon
                                    category={categoryItem.actionRiskCategory}
                                    sx={{ fontSize: "14px" }}/>
                                <Typography
                                    sx={{
                                        lineHeight: "inherit",
                                        wordBreak: "normal"
                                    }}>
                                    {categoryItem.initials}
                                </Typography>
                            </Stack>
                        </Link>
                    </Stack>
                </Tooltip>}
        </TopItems>);
}

type CategoryItem = {
    actionRiskCategory: Contract.ActionRiskCategory;
    actionRiskSeverity: Contract.Severity;
    initials: string;
    title: string;
};