import { NumberFormatter, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ComplianceHelper, Contract, SecuredBar, useTheme } from "../../..";

type EnabledBarProps = {
    data: Contract.ComplianceData;
    dense: boolean;
    timeFrame?: Contract.TimeFrame;
};

export function EnabledBar({ data, dense, timeFrame }: EnabledBarProps) {
    const [securedPercentage, securedPercentageDelta] =
        useMemo(
            () => {
                const securedPercentage =
                    _.isNil(data?.stats)
                        ? 0
                        : _.round(data.stats?.securedPercentage, 2);
                const securedPercentageDelta = ComplianceHelper.getDataSecuredPercentageDelta(data, timeFrame);
                return [securedPercentage, securedPercentageDelta];
            },
            [data, timeFrame]);

    const localization =
        useLocalization(
            "common.complianceSecuredBar.enabledBar",
            () => ({
                securedCount: "Passed {{count | NumberFormatter.percentage}}",
                unsecuredCount: "{{translatedSeverity}} {{count | NumberFormatter.percentage}}",
                waitingResults: "In progress..."
            }));

    const theme = useTheme();
    return (
        data.hasResults
            ? <Stack
                alignItems="center"
                direction="row">
                <Box
                    sx={{
                        flex:
                            dense
                                ? undefined
                                : 1,
                        width:
                            dense
                                ? theme.spacing(15)
                                : undefined
                    }}>
                    <SecuredBar
                        count={data.stats!.count}
                        height="narrow"
                        securedCount={data.stats!.securedCount}/>
                </Box>
                <Stack
                    sx={{
                        paddingTop:
                            !_.isNil(securedPercentageDelta) && securedPercentageDelta !== 0
                                ? theme.spacing(1.5)
                                : undefined,
                        width:
                            theme.spacing(
                                dense
                                    ? 5
                                    : 6)
                    }}>
                    <Typography
                        align="right"
                        variant="h5">
                        {NumberFormatter.percentage(securedPercentage)}
                    </Typography>
                    {!_.isNil(securedPercentageDelta) &&
                        securedPercentageDelta !== 0 &&
                        <Typography
                            align="right"
                            sx={{
                                color:
                                    securedPercentageDelta > 0
                                        ? theme.palette.success.main
                                        : theme.palette.error.main,
                                fontSize: "11px",
                                fontWeight: 700
                            }}>
                            {NumberFormatter.signedPercentage(securedPercentageDelta)}
                        </Typography>}
                </Stack>
            </Stack>
            : <Typography>
                {localization.waitingResults()}
            </Typography>);
}