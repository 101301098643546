import { Grid2 } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useMemo } from "react";
import { StringFormatter, useLocalization } from "@infrastructure";
import { Contract, LogoIcon, StorageHelper, UrlHelper, useTheme } from "../../../common";
import { AeIcon, AuIcon, BrIcon, CaIcon, EuIcon, InIcon, JpIcon, KrIcon, SgIcon, UkIcon, UsGovIcon, UsIcon } from "../icons";
import { RegionItem } from "./RegionItem";

type CoreProps = {
    consoleAppGeographyTypeToUrlMap: Dictionary<string>;
};

export function Regions({ consoleAppGeographyTypeToUrlMap }: CoreProps) {
    const { RedirectPath, redirectUrl } = UrlHelper.getRedirectUrlQueryParameters();

    const orderedGeographyTypes =
        useMemo(
            () => {
                const orderedGeographyTypes = [
                    Contract.GeographyType.Us,
                    Contract.GeographyType.Eu,
                    Contract.GeographyType.Au,
                    Contract.GeographyType.Br,
                    Contract.GeographyType.Ca,
                    Contract.GeographyType.In,
                    Contract.GeographyType.Jp,
                    Contract.GeographyType.Sg,
                    Contract.GeographyType.Kr,
                    Contract.GeographyType.Ae,
                    Contract.GeographyType.Uk,
                    Contract.GeographyType.UsGov
                ];

                return _(consoleAppGeographyTypeToUrlMap).
                    keys().
                    orderBy([
                        geographyType => !_.includes(orderedGeographyTypes, geographyType),
                        geographyType => _.indexOf(orderedGeographyTypes, geographyType)
                    ]).
                    as<Contract.GeographyType>().
                    value();
            },
            []);
    const localization =
        useLocalization(
            "views.geographySelector.regions",
            () => ({
                [Contract.GeographyType.Ae]: "United Arab Emirates",
                [Contract.GeographyType.Af]: "Africa",
                [Contract.GeographyType.Ap]: "Asia Pacific",
                [Contract.GeographyType.Au]: "Australia",
                [Contract.GeographyType.Br]: "Brazil",
                [Contract.GeographyType.Ca]: "Canada",
                [Contract.GeographyType.Cn]: "China",
                [Contract.GeographyType.Eu]: "Europe",
                [Contract.GeographyType.EuIsoE]: "EU Isolated E",
                [Contract.GeographyType.Il]: "Israel",
                [Contract.GeographyType.In]: "India",
                [Contract.GeographyType.Jp]: "Japan",
                [Contract.GeographyType.Kr]: "South Korea",
                [Contract.GeographyType.Me]: "Middle East",
                [Contract.GeographyType.Sa]: "South America",
                [Contract.GeographyType.Sg]: "Singapore",
                [Contract.GeographyType.Uk]: "United Kingdom",
                [Contract.GeographyType.Us]: "United States",
                [Contract.GeographyType.UsGov]: "US Gov Cloud",
                [Contract.GeographyType.UsIsoA]: "US Isolated A",
                [Contract.GeographyType.UsIsoB]: "US Isolated B",
                [Contract.GeographyType.UsIsoF]: "US Isolated F",
                tenableOneLink: "Go to Tenable One"
            }));

    const onClick = useCallback(
        (geographyType: Contract.GeographyType) =>
            () => {
                const consoleAppUrl = consoleAppGeographyTypeToUrlMap[geographyType];
                StorageHelper.geographySelectedConsoleAppUrl.setValue(consoleAppUrl);
                const url =
                    redirectUrl === "/customer/docs" && RedirectPath?.startsWith("/docs")
                        ? `${redirectUrl}${RedirectPath.replace("/docs", "")}`
                        : redirectUrl;
                window.location.assign(
                    _.isNil(url)
                        ? consoleAppUrl
                        : new URL(UrlHelper.sanitize(url), consoleAppUrl));
            },
        [consoleAppGeographyTypeToUrlMap, redirectUrl]);

    const theme = useTheme();
    return (
        <Grid2
            columns={6}
            container={true}
            justifyContent="center"
            spacing={3}
            sx={{
                maxWidth: theme.spacing(136),
                width: "100%"
            }}>
            {_.map(
                orderedGeographyTypes,
                geographyType =>
                    <RegionItem
                        displayName={localization[geographyType]()}
                        icon={
                            <Icon
                                displayName={localization[geographyType]()}
                                geographyType={geographyType}/>}
                        key={geographyType}
                        onClick={onClick(geographyType)}/>)}
            <RegionItem
                displayName={localization.tenableOneLink()}
                icon={
                    <LogoIcon
                        sx={{
                            color: "white",
                            fontSize: "39px"
                        }}/>}
                sx={{
                    background: theme.palette.primary.main,
                    color: "white",
                    padding: theme.spacing(0, 3.5)
                }}
                onClick={() => window.location.href = UrlHelper.tenableSignInUrl}/>
        </Grid2>);
}

type IconProps = {
    displayName: string;
    geographyType: Contract.GeographyType;
};

function Icon({ displayName, geographyType }: IconProps) {
    const theme = useTheme();
    switch (geographyType) {
        case Contract.GeographyType.Ae:
            return <AeIcon/>;
        case Contract.GeographyType.Au:
            return <AuIcon/>;
        case Contract.GeographyType.Br:
            return <BrIcon/>;
        case Contract.GeographyType.Ca:
            return <CaIcon/>;
        case Contract.GeographyType.Eu:
            return <EuIcon/>;
        case Contract.GeographyType.In:
            return <InIcon/>;
        case Contract.GeographyType.Jp:
            return <JpIcon/>;
        case Contract.GeographyType.Kr:
            return <KrIcon/>;
        case Contract.GeographyType.Sg:
            return <SgIcon/>;
        case Contract.GeographyType.Uk:
            return <UkIcon/>;
        case Contract.GeographyType.Us:
            return <UsIcon/>;
        case Contract.GeographyType.UsGov:
            return <UsGovIcon/>;
    }

    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <g>
                <circle
                    cx="50%"
                    cy="50%"
                    fill="blue"
                    r={25}>
                </circle>
                <text
                    dominantBaseline="central"
                    fill={theme.palette.common.white}
                    fontSize={20}
                    textAnchor="middle"
                    x="50%"
                    y="50%">
                    {StringFormatter.initials(displayName)}
                </text>
            </g>
        </svg>);
}