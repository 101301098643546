import { IpAddressHelper, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAzureSqlServerAuthenticationTypeTranslator, useAzureSqlServerStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureSqlServerRiskContext() {
    return useMemo(
        () => useAzureSqlServerRiskContext,
        []);
}

function useAzureSqlServerRiskContext(serverModel: Contract.AzureSqlServerModel) {
    const server = serverModel.entity as Contract.AzureSqlServer;
    const resourceRiskContext = useGetAzureResourceRiskContext()(serverModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(serverModel);
    const serverNetwork = serverModel.entityNetwork as Optional<Contract.AzureDatabaseResourceStateNetwork>;

    const databaseModels = entityModelStore.useGet(server.databaseIds);
    const totalSize =
        _(databaseModels).
            map(databaseModel => (databaseModel.entity as Contract.AzureSqlServerDatabase).storageSize ?? 0).
            sum();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const sqlServerAuthenticationTypeTranslator = useAzureSqlServerAuthenticationTypeTranslator();
    const sqlServerStatusTranslator = useAzureSqlServerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureSqlServerRiskContext",
            () => ({
                allowAnyAzureInternalIpAddress: "**Allow Azure services and resources to access this server** is enabled allowing unrestricted network inbound access to your server from all Azure resources, including resources from **any** subscription",
                authenticationType: "The {{translatedServerTypeName}} is using **{{authenticationType}}** authentication method",
                ipAddressRange: "IP address range {{startIpAddress}}-{{endIpAddress}} is open, allowing access to {{ipCount | NumberFormatter.unit}} IP addresses",
                status: "The {{translatedServerTypeName}}'s status is **{{status}}**",
                storageSize: "The {{translatedServerTypeName}}'s total size is {{storageSize | NumberFormatter.storage}}"
            }));
    const translatedServerTypeName =
        entityTypeNameTranslator(
            server.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        allowAnyAzureInternalIpAddress:
            server.allowAnyAzureInternalIpAddress
                ? new RiskDefinitionContextItem(localization.allowAnyAzureInternalIpAddress())
                : undefined,
        authenticationType:
            new RiskDefinitionContextItem(
                localization.authenticationType({
                    authenticationType: sqlServerAuthenticationTypeTranslator(server.authenticationType),
                    translatedServerTypeName
                })),
        firewallRuleRiskContext:
            _.isNil(serverNetwork)
                ? []
                : _.map(
                    serverNetwork.wideRangeFirewallIpAddressRangeRules,
                    firewallRule =>
                        new RiskDefinitionContextItem(
                            localization.ipAddressRange({
                                endIpAddress: firewallRule.endIpAddress,
                                ipCount:
                                    IpAddressHelper.getIpAddressCount(
                                        firewallRule.startIpAddress,
                                        firewallRule.endIpAddress),
                                startIpAddress: firewallRule.startIpAddress
                            }))),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: sqlServerStatusTranslator(server.status),
                    translatedServerTypeName
                })),
        storageSize:
            new RiskDefinitionContextItem(
                localization.storageSize({
                    storageSize: totalSize,
                    translatedServerTypeName
                }))
    };
}