import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { InfoItem, RiskPoliciesType, RiskPolicyItem, RiskPolicyTypeMetadataHelper, useRiskPolicyTranslator } from "../../../../../../../../../../../common";
import { useGetRiskPolicyItemBuiltInComplianceTypes } from "../../../../../../../../Compliance/hooks";
import { CodeExclusions } from "../../../../../../CodeExclusions";
import { RiskPolicyInfoItems } from "../../../useRiskPolicyInfoItems";
import { useCommonCodeRiskPolicyInfoItems } from "./useCommonCodeRiskPolicyInfoItems";

export function useCodeRiskPolicyBuiltInInfoItems(scopeId: string, item: RiskPolicyItem): RiskPolicyInfoItems {
    const commonCodeRiskPolicyInfoItems = useCommonCodeRiskPolicyInfoItems(item, scopeId);
    const getRiskPolicyItemBuiltInComplianceTypes = useGetRiskPolicyItemBuiltInComplianceTypes();

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const { description, title } =
        useMemo(
            () =>
                riskPolicyTranslator(item.riskPolicyConfigurationTypeNameOrId),
            [item.riskPolicyConfigurationTypeNameOrId]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.profile.hooks.useRiskPolicyInfoItems.hooks.code.hooks.useCodeRiskPolicyBuiltInInfoItems",
            () => ({
                configuration: "Configuration",
                excludedPattern: "Excluded Pattern",
                subtitle: "IaC Policy"
            }));

    return useMemo(
        () => ({
            additionalInfoCard: [
                { items: [ commonCodeRiskPolicyInfoItems.cloudPolicy ] },
                { items: [ commonCodeRiskPolicyInfoItems.getCompliances(getRiskPolicyItemBuiltInComplianceTypes(item)) ] },
                {
                    items: [
                        commonCodeRiskPolicyInfoItems.status,
                        <InfoItem
                            key={localization.excludedPattern()}
                            title={localization.excludedPattern()}
                            value={
                                <CodeExclusions
                                    riskPoliciesType={RiskPoliciesType.Code}
                                    riskPolicyConfigurationTypeNameOrId={item.riskPolicyConfigurationTypeName}
                                    scopeId={scopeId}/>}/>
                    ],
                    title: localization.configuration()
                }
            ],
            description: commonCodeRiskPolicyInfoItems.getDescription(description),
            general: [
                commonCodeRiskPolicyInfoItems.getTenantId(RiskPolicyTypeMetadataHelper.getCodeRiskCloudTenantTypes(item.riskPolicyConfigurationTypeName)),
                commonCodeRiskPolicyInfoItems.category,
                commonCodeRiskPolicyInfoItems.getRisks()
            ],
            topbar: {
                subtitle: localization.subtitle(),
                title
            }
        }),
        [scopeId, item]);
}