import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { OciConsoleUrlBuilder, useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetOciComputeInstanceRiskContext } from "../../../contexts";

export function useOciComputeInstanceSecureBootNotEnabledRiskInstanceDefinition(riskModel: Contract.OciComputeInstanceSecureBootNotEnabledRiskModel) {
    const risk = riskModel.risk as Contract.OciComputeInstanceSecureBootNotEnabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.OciComputeInstanceModel;

    const getOciComputeInstanceRiskContext = useGetOciComputeInstanceRiskContext();

    const tenantModel = tenantModelStore.useGet(instanceModel.entity.tenantId);
    const ociTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.OciTenantConfiguration).ocid);
    const ociTenantConfiguration = ociTenantModel.configuration as Contract.OciTenantConfiguration;

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciComputeInstanceSecureBootNotEnabledRiskDefinition.hooks.useOciComputeInstanceSecureBootNotEnabledRiskInstanceDefinition",
            () => ({
                description: "{{instance}} secure boot is not enabled",
                sections: {
                    resolution: {
                        step1: "Enabling secure boot on an existing instance is not supported. To resolve this issue, you must delete the current instance and create a new one with secure boot enabled.",
                        step2: "Click **Create instance**",
                        step3: {
                            link: "OCI documentation",
                            text: "Make sure the selected instance shape and image support secure boot. For more information, see {{instanceShieldedInstanceSupportedShapesAndImagesDocumentationLink}}"
                        },
                        step4: "In the **Security** section, click **Edit**",
                        step5: "Select the **Shielded instance** option and then select the **Secure Boot** option",
                        step6: "Click **Create**",
                        step7: "Delete the insecure {{instance}}"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            instanceModel.unknown
                ? []
                : [
                    consoleSignInStepTranslator(
                        Contract.OciConsoleView.Compute,
                        OciConsoleUrlBuilder.GetComputeInstancesUrl(
                            ociTenantConfiguration.realmId,
                            RegionModelHelper.get(instanceModel.entity.regionId!).systemName!)),
                    localization.sections.resolution.step1(),
                    localization.sections.resolution.step2(),
                    localization.sections.resolution.step3.text({
                        instanceShieldedInstanceSupportedShapesAndImagesDocumentationLink:
                            <Link
                                urlOrGetUrl={riskModel.instanceShieldedInstanceSupportedShapesAndImagesDocumentationUrl}
                                variant="external">
                                {localization.sections.resolution.step3.link()}
                            </Link>
                    }),
                    localization.sections.resolution.step4(),
                    localization.sections.resolution.step5(),
                    localization.sections.resolution.step6(),
                    localization.sections.resolution.step7({
                        instance:
                            <Entity
                                entityIdOrModel={instanceModel}
                                entityTypeNameTranslatorOptions={{ variant: "title" }}
                                variant="typeText"/>
                    })
                ],
        riskModel,
        () => {
            const instanceContextItems = getOciComputeInstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.status,
                instanceContextItems.sensitive,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}