import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React, { useMemo } from "react";
import { Contract } from "../../../../..";
import { InlineEntityMultiSelection, InlineEntitySelection } from "../../";

type ViewProps = {
    riskPolicyConfiguration?: Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    const [principalExcludeMatchConditions, principalMatchConditions, scopeResourceExcludeMatchConditions, scopeResourceMatchConditions] =
        useMemo(
            () => [
                riskPolicyConfiguration?.excludePrincipalMatchConditions ?? [],
                riskPolicyConfiguration?.principalMatchConditions ?? [],
                riskPolicyConfiguration?.excludeScopeResourceMatchConditions ?? [],
                riskPolicyConfiguration?.scopeResourceMatchConditions ?? []
            ],
            [riskPolicyConfiguration]);
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                principals:
                    <InlineEntityMultiSelection
                        conditions={principalMatchConditions}
                        entityLinkOptions={{ disabled: true }}
                        entityTypeName={Contract.TypeNames.IGciPrincipal}
                        excludeConditions={principalExcludeMatchConditions}
                        sx={sx}/>,
                roles:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.GcpIamRole}
                        selection={{ entityIds: riskPolicyConfiguration?.roles.ids }}
                        sx={sx}/>,
                scopeResources:
                    <InlineEntityMultiSelection
                        conditions={scopeResourceMatchConditions}
                        entityLinkOptions={{ disabled: true }}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        excludeConditions={scopeResourceExcludeMatchConditions}
                        sx={sx}/>
            })}
        </Typography>);
}