import { Grid } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../common";
import { Data, Title } from "./components";

type ItemProps = {
    complianceSectionData: Contract.ComplianceSectionData;
    complianceSectionTitle: string;
    itemIndex: number;
    pageFirstItem: boolean;
    pageLastItem: boolean;
};

export function Item({ complianceSectionData, complianceSectionTitle, itemIndex, pageFirstItem, pageLastItem }: ItemProps) {
    const theme = useTheme();
    return (
        <Grid
            container={true}
            style={{
                paddingTop:
                    itemIndex === 0
                        ? theme.spacing(4)
                        : undefined
            }}>
            {(itemIndex === 0 || pageFirstItem) &&
                <Title/>}
            <Data
                borderBottom={pageLastItem}
                complianceSectionData={complianceSectionData}
                complianceSectionTitle={complianceSectionTitle}/>
        </Grid>);
}