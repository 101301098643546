import { AnalyticsContext, AnalyticsEventActionType, Loading, makeContextProvider, map, useQueryParameters, useRoute, useTrackAnalyticsEvent } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, scopeNodeModelStore, TenantHelper, useLayoutOptions } from "../../../../common";
import { CiTenants, CloudProviderTenants, CodeTenants, Projects } from "./components";
import { TenantsDefinition, useDefinition } from "./hooks";
import { ScopesView, ScopesViewType } from "./utilities";

export class ScopesContext {
    constructor(
        public addOrEditOpen: false | "folder" | "scope" | Contract.ScopeNodeModel = false,
        public definition: TenantsDefinition,
        public view: ScopesViewType,
        public addOrEditStepIndex?: number) {
    }
}

export type EditScopeQueryParameters = {
    addOrEdit?: string;
    addOrEditStepIndex?: number;
};
export const [useScopesContext, useSetScopesContext, useScopesContextProvider] = makeContextProvider<ScopesContext>();

export function Scopes() {
    const { view } = useRoute(`${CustomerConsoleAppUrlHelper.getScopesRelativeUrl()}/{view}`) as any as { view: ScopesViewType };

    return (
        <AnalyticsContext context={view}>
            <Loading key={view}>
                <ScopeView view={view}/>
            </Loading>
        </AnalyticsContext>);
}

type TenantViewProps = {
    view: ScopesViewType;
};

export function ScopeView({ view }: TenantViewProps) {
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const definition = useDefinition(view);
    useLayoutOptions({ view: { title: definition.title } });
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const { addOrEdit, addOrEditStepIndex } = useQueryParameters<EditScopeQueryParameters>();
    const [, , ContextProvider] =
        useScopesContextProvider(
            () =>
                new ScopesContext(
                    addOrEdit == "scope"
                        ? addOrEdit
                        : (_.find(
                            scopeNodeModels,
                            scopeNodeModel => scopeNodeModel.configuration.id == addOrEdit) ??
                            false),
                    definition,
                    view,
                    addOrEditStepIndex),
            [view]);

    return (
        <ContextProvider>
            {_.includes(TenantHelper.CloudProviderTenantTypes, view)
                ? <CloudProviderTenants/>
                : map(
                    view,
                    {
                        [ScopesView.Ci]: () => <CiTenants/>,
                        [ScopesView.Code]: () => <CodeTenants/>,
                        [ScopesView.Project]: () => <Projects/>
                    })}
        </ContextProvider>);
}