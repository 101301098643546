﻿import { useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { Contract, useLayoutOptions } from "../../../../../common";
import { EntitiesTable } from "../../Entities";

export function Entities() {
    const localization =
        useLocalization(
            "views.customer.dataAnalysis.entities",
            () => ({
                title: "Data Inventory"
            }));
    useLayoutOptions({ view: { title: localization.title() } });

    return (
        <Box sx={{ height: "100%" }}>
            <EntitiesTable
                hideEntityPropertyColumns={true}
                tenantTypes={[
                    Contract.TenantType.Aws,
                    Contract.TenantType.Azure,
                    Contract.TenantType.Gcp
                ]}
                typeName={Contract.TypeNames.IDataAnalysisResource}/>
        </Box>);
}