import _, { Dictionary } from "lodash";
import { map, StringHelper } from "@infrastructure";
import { TenantHelper, UserHelper } from ".";
import { Contract } from "../controllers";
import { ScopeNode, scopeNodeModelStore } from "../stores";
import { TypeHelper } from "./typeHelper";

export class ScopeHelper {
    public static customerDisplayName = "Organization";

    public static get customerId() {
        return UserHelper.customer!.id;
    }

    public static getParentScopeSystemEntityModelsIntersection<TValue extends Contract.ScopeSystemEntityModel>(scopeIds: string[], scopeSystemEntityModels: TValue[]): TValue[] {
        return _.intersectionBy(
            ...this.getParentScopeSystemEntityModels(scopeIds, scopeSystemEntityModels),
            scopeSystemEntityModel => scopeSystemEntityModel.configuration.id);
    }

    public static getParentScopeSystemEntityModelsUnion<TValue extends Contract.ScopeSystemEntityModel>(scopeIds: string[], scopeSystemEntityModels: TValue[]): TValue[] {
        return _.uniqBy(
            _.flatMap(this.getParentScopeSystemEntityModels(scopeIds, scopeSystemEntityModels)),
            scopeSystemEntityModel => scopeSystemEntityModel.configuration.id);
    }

    public static getParentScopeSystemEntityModels<TValue extends Contract.ScopeSystemEntityModel>(scopeIds: string[], scopeSystemEntityModels: TValue[]): TValue[][] {
        const scopeNodeMap =
            scopeNodeModelStore.useGetActiveScopeNodeMap(
                undefined,
                true);
        return _(scopeIds).
            filter(
                scopeId =>
                    UserHelper.hasScopePermissions(
                        scopeId,
                        Contract.IdentityPermission.PermissionManagementAdministrationRead,
                        Contract.IdentityPermission.PermissionManagementPermissionRequest,
                        Contract.IdentityPermission.SecurityRead)).
            map(
                scopeId =>
                    _.filter(
                        scopeSystemEntityModels,
                        scopeSystemEntityModel =>
                            _.includes(
                                _.concat(scopeId, scopeNodeMap[scopeId].parentScopeIds),
                                scopeSystemEntityModel.configuration.scopeId))).
            value();
    }

    public static getScopeActiveTenantIds(scopeIds: string[]): string[] {
        const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
        return (
            _(scopeIds).
                flatMap(scopeId => scopeNodeMap[scopeId].tenantIds).
                uniq().
                value());
    }

    public static getScopePath(scopeNodeModel: Contract.ScopeNodeModel, scopeNodeMap: _.Dictionary<ScopeNode>): string | undefined {
        function getScopePath(scopeId: string): string | undefined {
            const scopeNodeModel = scopeNodeMap[scopeId].scopeNodeModel;

            if (ScopeHelper.isRootFolder(scopeNodeModel)) {
                return undefined;
            }

            const parentScopePath = getScopePath(ScopeHelper.tryGetParentScopeId(scopeNodeModel)!);
            return (
                _.isNil(parentScopePath)
                    ? scopeNodeModel.configuration.name
                    : `${parentScopePath} \\ ${scopeNodeModel.configuration.name}`);
        }

        return getScopePath(ScopeHelper.tryGetParentScopeId(scopeNodeModel)!);
    }

    public static getScopesParentScopeSystemEntityModelsIntersection<TValue extends Contract.ScopeSystemEntityModel>(
        scopeIdsList: string[][],
        scopeSystemEntityModels: TValue[]): TValue[] {
        const scopeNodeMap =
            scopeNodeModelStore.useGetActiveScopeNodeMap(
                undefined,
                true);
        const commonScopeIds =
            new Set(
                _(scopeIdsList).
                    map(
                        scopeIds =>
                            _(scopeIds).
                                filter(
                                    scopeId =>
                                        UserHelper.hasScopePermissions(
                                            scopeId,
                                            Contract.IdentityPermission.PermissionManagementAdministrationRead,
                                            Contract.IdentityPermission.PermissionManagementPermissionRequest,
                                            Contract.IdentityPermission.SecurityRead)).
                                flatMap(scopeId => _.concat(scopeId, scopeNodeMap[scopeId].parentScopeIds)).
                                value()).
                    reduce((commonScopeIds, scopeIds) => _.intersection(commonScopeIds, scopeIds)) ?? []);
        return _.filter(
            scopeSystemEntityModels,
            scopeSystemEntityModel => commonScopeIds.has(scopeSystemEntityModel.scopeId));
    }

    public static getScopeTree(scopeNodeMap: Dictionary<ScopeNode>, scopeNodeModelIds: string[], includeProject = false, includeEmptyFolders = false) {
        const scopeTreeNodeMap: Dictionary<ScopeNode> = {};

        function createNode(scopeNode: ScopeNode, parentScopeNode?: ScopeNode) {
            const scopeTreeNode =
                new ScopeNode(
                    parentScopeNode,
                    scopeNode.scopeNodeModel);
            scopeTreeNode.scopeNodes =
                _(scopeNode.scopeNodes).
                    map(scopeNode => createNode(scopeNode, scopeTreeNode)).
                    filter().
                    as<ScopeNode>().
                    value();
            if (ScopeHelper.isFolder(scopeNode.scopeNodeModel) &&
                (includeEmptyFolders && _.isEmpty(scopeTreeNode.scopeNodes) && ScopeHelper.isRootFolder(scopeTreeNode.scopeNodeModel) ||
                    (!includeEmptyFolders && _.isEmpty(scopeTreeNode.scopeNodes))) ||
                ((ScopeHelper.isTenant(scopeTreeNode.scopeNodeModel) ||
                        (includeProject &&
                            ScopeHelper.isProject(scopeTreeNode.scopeNodeModel))) &&
                    !_.includes(scopeNodeModelIds, scopeTreeNode.scopeNodeModel.configuration.id))) {
                return undefined;
            }

            scopeTreeNodeMap[scopeTreeNode.scopeNodeModel.configuration.id] = scopeTreeNode;
            return scopeTreeNode;
        }

        createNode(scopeNodeMap[ScopeHelper.customerId]);

        return scopeTreeNodeMap;
    }

    public static getTenantType(scopeNodeModel: Contract.ScopeNodeModel) {
        return map(
            scopeNodeModel.type,
            {
                [Contract.ScopeType.CiTenant]: () => (scopeNodeModel.configuration as Contract.CiTenantConfiguration).type,
                [Contract.ScopeType.CloudProviderTenant]: () => (scopeNodeModel.configuration as Contract.CloudProviderTenantConfiguration).type,
                [Contract.ScopeType.CodeTenant]: () => (scopeNodeModel.configuration as Contract.CodeTenantConfiguration).type,
                [Contract.ScopeType.Customer]: () => undefined,
                [Contract.ScopeType.Folder]: () => (scopeNodeModel.configuration as Contract.FolderConfiguration).tenantType,
                [Contract.ScopeType.IdentityProviderTenant]: () => (scopeNodeModel.configuration as Contract.IdentityProviderTenantConfiguration).type,
                [Contract.ScopeType.ProjectFolder]: () => undefined,
                [Contract.ScopeType.Project]: () => undefined
            });
    }

    public static isCloudProviderTenantsScope(scopeNodeModel: Contract.ScopeNodeModel) {
        const tenantType = ScopeHelper.getTenantType(scopeNodeModel);
        return (
            !_.isNil(tenantType) &&
            TenantHelper.isCloudProviderTenantType(tenantType));
    }

    public static isCloudProviderTenant(scopeNodeModel: Contract.ScopeNodeModel) {
        return TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.CloudProviderTenantConfiguration);
    }

    public static isCodeTenant(scopeNodeModel: Contract.ScopeNodeModel) {
        return TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.CodeTenantConfiguration);
    }

    public static isCodeFolder(scopeNodeModel: Contract.ScopeNodeModel) {
        return scopeNodeModel.configuration.typeName === Contract.TypeNames.CodeFolderConfiguration;
    }

    public static isCiTenant(scopeNodeModel: Contract.ScopeNodeModel) {
        return TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.CiTenantConfiguration);
    }

    public static isIdentityProviderTenantsScope(scopeNodeModel: Contract.ScopeNodeModel) {
        const tenantType = ScopeHelper.getTenantType(scopeNodeModel);
        return (
            !_.isNil(tenantType) &&
            TenantHelper.isIdentityProviderTenantType(tenantType));
    }

    public static isFolder(scopeNodeModel: Contract.ScopeNodeModel) {
        return TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.FolderConfiguration);
    }

    public static isPermissionManagementTenantsScope(scopeNodeModel: Contract.ScopeNodeModel) {
        if (scopeNodeModel.configuration.typeName === Contract.TypeNames.CustomerConfiguration) {
            return true;
        } else if (TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.IProjectScopeConfiguration)) {
            return false;
        } else {
            return TenantHelper.isPermissionManagementTenantType(ScopeHelper.getTenantType(scopeNodeModel)!);
        }
    }

    public static isRootFolder(scopeNodeModel: Contract.ScopeNodeModel) {
        return ScopeHelper.tryGetParentScopeId(scopeNodeModel) === ScopeHelper.customerId;
    }

    public static isTenant(scopeNodeModel: Contract.ScopeNodeModel) {
        return TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.TenantConfiguration);
    }

    public static isProject(scopeNodeModel: Contract.ScopeNodeModel) {
        return TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.ProjectConfiguration);
    }

    public static isProjectFolder(scopeNodeModel: Contract.ScopeNodeModel) {
        return TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.ProjectFolderConfiguration);
    }

    public static isProjectScope(scopeNodeModel: Contract.ScopeNodeModel) {
        return this.isProjectFolder(scopeNodeModel) || this.isProject(scopeNodeModel);
    }

    public static tryGetFirstPermittedScopeId(
        scope: ScopeNode,
        identityPermissions: [Contract.IdentityPermission, ...Contract.IdentityPermission[]] = [Contract.IdentityPermission.SecurityRead]): string | undefined {
        if (UserHelper.hasScopePermissions(scope.scopeNodeModel.configuration.id, ...identityPermissions)) {
            return scope.scopeNodeModel.configuration.id;
        }
        const scopeNodes =
            _.orderBy(
                scope.scopeNodes,
                [
                    scopeNode => !ScopeHelper.isFolder(scopeNode.scopeNodeModel),
                    scopeNode => StringHelper.getSortValue(scopeNode.scopeNodeModel.configuration.name)
                ]);
        for (const scopeNode of scopeNodes) {
            const childFirstPermittedScope = ScopeHelper.tryGetFirstPermittedScopeId(scopeNode, identityPermissions);
            if (childFirstPermittedScope) {
                return childFirstPermittedScope;
            }
        }
        return undefined;
    }

    public static tryGetParentScopeId(scopeNodeModel: Contract.ScopeNodeModel) {
        return TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.IChildScopeConfiguration)
            ? (scopeNodeModel.configuration as Contract.IChildScopeConfiguration).parentScopeId
            : undefined;
    }
}