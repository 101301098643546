﻿import { useLocalization } from "@infrastructure";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";
import { useCommonKubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPolicyConfigurationDefinition } from "./useCommonKubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPolicyConfigurationDefinition";

export function useKubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfigurationDefinition(): RiskPolicyConfigurationDefinitionData {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useKubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfigurationDefinition",
            () => ({
                description: "Disallowed repositories:"
            }));

    return useCommonKubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPolicyConfigurationDefinition(localization.description());
}