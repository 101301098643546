﻿import { InlineItems, Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper, Entity, InlineEntities, RiskTypeMetadataModelHelper, useProjectScopeSelected } from "../../../../../../../../../common";

export function useResourceSecretExistsSteps() {
    const projectScopeSelected = useProjectScopeSelected();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceSecretExistsSteps",
            () => ({
                excludedSecrets: "{{secrets}} is being excluded. Manage exclusions by editing the {{policyConfigurationLink}}",
                riskPolicyConfigurationLink: "policy configuration",
                secretExists: [
                    "*capitalize*{{entities}}** is exposing {{secrets}}",
                    "{{entities}} are exposing {{secrets}}"
                ],
                secretExistsAggregatingEntity: "The {{resourceDisplayName}} is exposing {{secrets}}",
                secretExistsAggregatingEntityAndEntities: "{{entities}} and the {{resourceDisplayName}} are exposing {{secrets}}",
                secrets: [
                    "1 secret",
                    "{{count | NumberFormatter.humanize}} secrets"
                ]
            }));

    return ({
        getExcludedSecretsStep:
            (excludedSecrets: string[], riskTypeName: string, scopeId: string) =>
                !_.isEmpty(excludedSecrets)
                    ? localization.excludedSecrets({
                        policyConfigurationLink:
                            <Link
                                urlOrGetUrl={
                                    projectScopeSelected
                                        ? undefined
                                        : CustomerConsoleAppUrlHelper.getCloudRiskPoliciesProfileHashUrl(
                                            RiskTypeMetadataModelHelper.get(riskTypeName).policyConfigurationTypeName,
                                            scopeId)}>
                                {localization.riskPolicyConfigurationLink()}
                            </Link>,
                        secrets:
                            <InlineItems
                                items={excludedSecrets}
                                namePluralizer={localization.secrets}
                                variant="itemCountAndType"/>
                    })
                    : undefined,
        getSecretExistsAggregatingEntityAndEntitiesStep:
            (entityIds: string | string[], entityTypeName: string, resourceDisplayName: string, secrets: string[]) =>
                localization.secretExistsAggregatingEntityAndEntities({
                    entities:
                        _.isString(entityIds)
                            ? <Entity
                                entityIdOrModel={entityIds}
                                variant="typeText"/>
                            : <InlineEntities
                                entityIdsOrModels={entityIds}
                                entityTypeName={entityTypeName}
                                variant={"itemCountAndType"}/>,
                    resourceDisplayName,
                    secrets:
                        <InlineItems
                            items={secrets}
                            namePluralizer={localization.secrets}
                            variant="itemCountAndType"/>
                }),
        getSecretExistsAggregatingEntityStep:
            (resourceDisplayName: string, secrets: string[]) =>
                localization.secretExistsAggregatingEntity({
                    resourceDisplayName,
                    secrets:
                        <InlineItems
                            items={secrets}
                            namePluralizer={localization.secrets}
                            variant="itemCountAndType"/>
                }),
        getSecretExistsStep:
            (entityIds: string | string[], entityTypeName: string, secrets: string[]) =>
                localization.secretExists(
                    _.concat(entityIds).length,
                    {
                        entities:
                            _.isString(entityIds)
                                ? <Entity
                                    entityIdOrModel={entityIds}
                                    variant="typeText"/>
                                : <InlineEntities
                                    entityIdsOrModels={entityIds}
                                    entityTypeName={entityTypeName}
                                    variant={"itemCountAndType"}/>,
                        secrets:
                            <InlineItems
                                items={secrets}
                                namePluralizer={localization.secrets}
                                variant="itemCountAndType"/>
                    })
    });
}