import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useAzureCommonComputeVirtualMachineResourceInfoItemElements(virtualMachineResourceModel: Contract.AzureComputeVirtualMachineResourceModel) {
    const virtualMachineResource = virtualMachineResourceModel.entity as Contract.AzureComputeVirtualMachineResource;
    const image = entityModelStore.useGet(virtualMachineResourceModel.imageId);
    const imageInfo = (image?.entity as Contract.AzureComputeImage)?.info ?? virtualMachineResource.specification?.imageInfo;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonComputeVirtualMachineResourceInfoItemElements",
            () => ({
                info: {
                    items: {
                        applicationGatewayIds: "Application Gateways",
                        imageInfo: {
                            text: "{{offer}} {{sku}}",
                            title: "Image"
                        },
                        loadBalancerIds: "Load Balancers",
                        rawId: "ID",
                        rawType: "Size"
                    }
                }
            }));
    return {
        applicationGatewaysInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={virtualMachineResourceModel.applicationGatewayIds}
                entityTypeName={Contract.TypeNames.AzureNetworkApplicationGateway}
                key={Contract.TypeNames.AzureNetworkApplicationGateway}/>,
        imageInfoInfoItemElement:
            <VerticalTopItemsInfoItem
                items={
                    _.filter([
                        virtualMachineResource?.specification?.operatingSystemType,
                        _.isNil(imageInfo)
                            ? undefined
                            : localization.info.items.imageInfo.text({
                                offer: imageInfo.offer,
                                sku: imageInfo.sku
                            })])}
                key="imageInfo"
                title={localization.info.items.imageInfo.title()}/>,
        loadBalancersInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={virtualMachineResourceModel.loadBalancerIds}
                entityTypeName={Contract.TypeNames.AzureNetworkLoadBalancer}
                key={Contract.TypeNames.AzureNetworkLoadBalancer}/>,
        rawIdInfoItemElement:
            <InfoItem
                key="rawId"
                title={localization.info.items.rawId()}
                value={virtualMachineResource.rawId}/>,
        rawTypeInfoItemElement:
            <InfoItem
                key="rawType"
                title={localization.info.items.rawType()}
                value={virtualMachineResource?.specification?.rawType}/>
    };
}