import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureMySqlServerRiskContext } from "../contexts";

export function useAzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureMySqlFlexibleServerModel;

    const getAzureMySqlFlexibleServerRiskContext = useGetAzureMySqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskDefinition",
            () => ({
                description: "{{server}} parameter audit_log_events does not meet minimum requirements",
                sections: {
                    resolution: {
                        step1: "Set the **audit_log_enabled** parameter to **ON**",
                        step2: "Set **audit_log_events** parameter to have at least the **CONNECTION** option checked",
                        step3: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={`${risk.entityId}-${Contract.AzureConsoleEntityPage.ActiveDirectoryAdmins}`}
                page={Contract.AzureConsoleEntityPage.ServerConfigurations}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureMySqlFlexibleServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}