import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsS3BucketRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { PolicySection, S3BucketAccessControlListSection, S3PublicAccessPolicySection as S3PublicAccessPolicySection } from "../../components";

export function useAwsS3BucketBlockPublicAccessDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsS3BucketBlockPublicAccessDisabledRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.AwsS3BucketModel;
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;
    const tenantEntityPublicAccessPolicy = (entityModelStore.useGet(bucket.tenantId).entity as Contract.AwsTenantEntity).s3!.publicAccessPolicy;

    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsS3BucketBlockPublicAccessDisabledRiskDefinition",
            () => ({
                description: "{{bucket}} does not actively block public access",
                sections: {
                    accessControlList: "ACL",
                    policyDocument: "Policy",
                    publicAccessPolicy: "Block Public Access",
                    resolution: {
                        step1: "Since the bucket has public access, block public access might break identities access to the bucket. Verify that only identities from the same account access the bucket before applying the change",
                        step2: "Check **Block all public access** and click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                bucket:
                    <Entity
                        entityIdOrModel={bucketModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.S3,
                AwsConsoleUrlBuilder.getS3BucketEditBlockPublicAccessUrl(bucket)),
            bucketModel.accessLevel === Contract.AwsResourceAccessLevel.Public
                ? localization.sections.resolution.step1()
                : undefined!,
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.storageSize,
                bucketRiskContext.encryption,
                bucketRiskContext.sensitive,
                bucketRiskContext.accessLevel,
                bucketRiskContext.highestDataSensitivity,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSectionGroup(
                    [
                        new RiskDefinitionSection(
                            <S3PublicAccessPolicySection
                                bucketPublicAccessPolicy={bucket.publicAccessPolicy}
                                tenantPublicAccessPolicy={tenantEntityPublicAccessPolicy}/>,
                            localization.sections.publicAccessPolicy()),
                        new RiskDefinitionSection(
                            <S3BucketAccessControlListSection accessControlList={bucket.accessControlList}/>,
                            localization.sections.accessControlList()),
                        new RiskDefinitionSection(
                            <PolicySection policyDocument={bucket.policyDocument}/>,
                            localization.sections.policyDocument(),
                            {
                                expandable: true
                            })
                    ])
            ]
        });
}