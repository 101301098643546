﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity } from "../../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useRemediationSteps } from "../useRemediationSteps";
import { ContextSection } from "./components";

export function useAwsInboundExternalEc2InstanceRiskLaunchConfigurationDefinition(riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel) {
    const remediationSteps = useRemediationSteps(riskModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useAwsInboundExternalEc2InstanceRiskLaunchConfigurationDefinition",
            () => ({
                description: "{{launchConfiguration}} launched EC2 instances that are directly accessible from a wide range of public IP addresses"
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchConfiguration:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => remediationSteps,
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>
        });
}