﻿import { DataTable, DataTableColumn, EmptyMessageText, InlineTexts, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard } from "../../../../../../../../../common";

type AwsWafWebAclRulesInfoCardProps = {
    rules: Contract.AwsWafWebAclRuleBase[];
};

export function AwsWafWebAclRulesInfoCard({ rules }: AwsWafWebAclRulesInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.awsWafWebAclRulesInfoCard",
            () => ({
                columns: {
                    groupName: "Group Name",
                    groupRules: "Rules",
                    name: "Name",
                    priority: "Priority"
                },
                empty: "No rules",
                title: "Rules Table"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => rules}
                getItemId={(item: Contract.AwsWafWebAclRuleBase) => item.name}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={WebAclRuleColumnId.Name}
                    itemProperty={(item: Contract.AwsWafWebAclRuleBase) => item.name}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={WebAclRuleColumnId.Priority}
                    itemProperty={(item: Contract.AwsWafWebAclRuleBase) => item.priority}
                    title={localization.columns.priority()}/>
                <DataTableColumn
                    id={WebAclRuleColumnId.GroupName}
                    render={
                        optionalTableCell<Contract.AwsWafWebAclRuleBase>(
                            item =>
                                item.typeName === Contract.TypeNames.AwsWafWebAclRuleGroup
                                    ? (item as Contract.AwsWafWebAclRuleGroup).groupName
                                    : undefined)}
                    title={localization.columns.groupName()}/>
                <DataTableColumn
                    id={WebAclRuleColumnId.GroupRules}
                    render={
                        optionalTableCell<Contract.AwsWafWebAclRuleBase>(
                            item =>
                                item.typeName === Contract.TypeNames.AwsWafWebAclRuleGroup
                                    ? <InlineTexts
                                        texts={
                                            _.map(
                                                (item as Contract.AwsWafWebAclRuleGroup).rules,
                                                rule => rule.name)}
                                        variant="itemPlusItemCount"/>
                                    : undefined)}
                    title={localization.columns.groupRules()}/>
            </DataTable>
        </InfoCard>);
}

enum WebAclRuleColumnId {
    GroupName = "groupName",
    GroupRules = "groupRules",
    Name = "name",
    Priority = "priority"

}