import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ItemWithValidation, useLocalization } from "@infrastructure";
import { Contract, Deliveries, ScopeHelper, scopeSystemEntityModelStore, slackWorkspaceChannelOperationStore, UserHelper } from "../../../../../../../common";

type DeliveriesItemProps = {
    createItem: (typeName: string) => void;
    deliveries: ItemWithValidation<Contract.DeliveryRequest>[];
    onChange: (deliveries: ItemWithValidation<Contract.DeliveryRequest[]>) => void;
    scopeId: string;
    variant: "auditEvent" | "risk";
};

export function DeliveriesItem({ createItem, deliveries, onChange, scopeId, variant }: DeliveriesItemProps) {
    const workspaceIdToChannelRawIdToNameMap = slackWorkspaceChannelOperationStore.useGet();
    const slackModels = scopeSystemEntityModelStore.useGetSlack();
    const teamsModels = scopeSystemEntityModelStore.useGetTeams();
    const jiraModels = scopeSystemEntityModelStore.useGetJira();
    const serviceNowModels = scopeSystemEntityModelStore.useGetServiceNow();
    const qRadarModels = scopeSystemEntityModelStore.useGetQRadar();
    const dataDogModels = scopeSystemEntityModelStore.useGetDatadog();
    const webhooksModels = scopeSystemEntityModelStore.useGetWebhook();
    const sqsModels = scopeSystemEntityModelStore.useGetSqs();
    const splunkModels = scopeSystemEntityModelStore.useGetSplunk();
    const slackWorkspaceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            slackModels);
    const teamsOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            teamsModels);
    const jiraInstanceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            jiraModels);
    const serviceNowInstanceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            serviceNowModels);
    const qRadarServerModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            qRadarModels);
    const datadogOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            dataDogModels);
    const webhookEndpointModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            webhooksModels);
    const sqsQueueModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            sqsModels);
    const splunkEventCollectorModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            splunkModels);
    const jiraEnabled =
        useMemo(
            () =>
                !_(jiraInstanceModels).
                    flatMap(jiraInstanceModel => (jiraInstanceModel.configuration as Contract.JiraInstanceConfiguration).projects).
                    isEmpty(),
            [jiraInstanceModels]);

    const typeNames =
        useMemo(
            () =>
                UserHelper.hasScopePermissions(scopeId, Contract.IdentityPermission.SecurityAdministrationRead)
                    ? _<Contract.DeliveryDerivedTypeNames>([]).
                        concatIf(
                            variant === "risk",
                            Contract.DeliveryDerivedTypeNames.MailDelivery).
                        concatIf(
                            variant === "risk" &&
                            !_.some(
                                deliveries,
                                ({ delivery }) => delivery.typeName === Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery),
                            Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery).
                        concatIf(
                            !_(slackWorkspaceModels).
                                flatMap(slackWorkspaceModel => _.keys(workspaceIdToChannelRawIdToNameMap[slackWorkspaceModel.id])).
                                isEmpty(),
                            Contract.DeliveryDerivedTypeNames.SlackDelivery).
                        concatIf(
                            !_(teamsOrganizationModels).
                                flatMap(teamsOrganizationModel => (teamsOrganizationModel.state as Contract.TeamsOrganizationState).teamRawIdToChannelReferencesMap).
                                isEmpty(),
                            Contract.DeliveryDerivedTypeNames.TeamsDelivery).
                        concatIf(
                            jiraEnabled &&
                            variant === "risk" &&
                            !_.some(
                                deliveries,
                                ({ delivery }) => delivery.typeName === Contract.DeliveryDerivedTypeNames.JiraDelivery),
                            Contract.DeliveryDerivedTypeNames.JiraDelivery).
                        concatIf(
                            !_.isEmpty(serviceNowInstanceModels) &&
                            variant === "risk" &&
                            !_.some(
                                deliveries,
                                ({ delivery }) => delivery.typeName === Contract.DeliveryDerivedTypeNames.ServiceNowDelivery),
                            Contract.DeliveryDerivedTypeNames.ServiceNowDelivery).
                        concatIf(
                            !_.isEmpty(qRadarServerModels),
                            Contract.DeliveryDerivedTypeNames.QRadarDelivery).
                        concatIf(
                            !_.isEmpty(datadogOrganizationModels),
                            Contract.DeliveryDerivedTypeNames.DatadogDelivery).
                        concatIf(
                            !_.isEmpty(sqsQueueModels),
                            Contract.DeliveryDerivedTypeNames.SqsDelivery).
                        concatIf(
                            !_.isEmpty(splunkEventCollectorModels),
                            Contract.DeliveryDerivedTypeNames.SplunkDelivery).
                        concatIf(
                            !_.isEmpty(webhookEndpointModels),
                            Contract.DeliveryDerivedTypeNames.WebhookDelivery).
                        value()
                    : [],
            [datadogOrganizationModels, deliveries, scopeId, serviceNowInstanceModels, slackWorkspaceModels, splunkEventCollectorModels, sqsQueueModels, teamsOrganizationModels, jiraEnabled, variant, qRadarServerModels, webhookEndpointModels]);

    const localization =
        useLocalization(
            "views.customer.configuration.automations.deliveriesItem",
            () => ({
                delivery: "Delivery"
            }));

    return (
        <Stack spacing={2}>
            <Typography
                variant="h4">
                {localization.delivery()}
            </Typography>
            <Deliveries
                createItem={createItem}
                deliveries={deliveries}
                disabled={false}
                scopeId={scopeId}
                typeNames={typeNames}
                onChange={onChange}/>
        </Stack>);
}