import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { useAwsResourceAccessLevelTranslator } from "../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";

export function useGetAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterContext() {
    return useMemo(
        () => useAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterContext,
        []);
}

function useAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterContext(riskModel: Contract.AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskModel) {
    const snapshotModels = entityModelStore.useGet(riskModel.risk.aggregatedEntityIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskDefinition.hooks.useGetAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterContext",
            () => ({
                networkAccess: {
                    all: "All the {{translatedSnapshotTypeName}} have **{{accessLevel}}** access",
                    some: "{{accessLevelSnapshots}} have **{{accessLevel}}** access"
                },
                snapshots: {
                    automatic: "The {{translatedClusterTypeName}} has automatic backup enabled generating {{snapshotIds}} without KMS encryption",
                    awsBackup: "The {{translatedClusterTypeName}} has aws backup enabled generating {{snapshotIds}} without KMS encryption",
                    empty: "The {{translatedClusterTypeName}} has no Neptune cluster snapshots taken from this cluster",
                    manual: "The {{translatedClusterTypeName}} has {{snapshotIds}} manually created without KMS encryption"
                }
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.AwsRdsNeptuneCluster,
            {
                includeServiceName: false,
                variant: "text"
            });

    const [accessLevelSnapshotModels, snapshotTypeToSnapshotModelsMap] =
        useMemo(
            () => {
                const accessLevelSnapshotModels =
                    _.filter(
                        snapshotModels,
                        snapshotModel => (snapshotModel as Contract.AwsRdsNeptuneClusterSnapshotModel).accessLevel === riskModel.risk.accessLevel);
                const snapshotTypeToSnapshotModelsMap =
                    _.groupBy(
                        snapshotModels,
                        snapshotModel => (snapshotModel.entity as Contract.AwsRdsNeptuneClusterSnapshot).type);
                return [accessLevelSnapshotModels, snapshotTypeToSnapshotModelsMap];
            },
            [riskModel]);

    return {
        automatic:
            _.isEmpty(snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.Automatic])
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.snapshots.automatic({
                        snapshotIds:
                            <InlineEntities
                                entityIdsOrModels={snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.Automatic]}
                                entityTypeName={Contract.TypeNames.AwsRdsNeptuneClusterSnapshot}
                                variant="itemCountAndType"/>,
                        translatedClusterTypeName
                    })),
        awsBackup:
            _.isEmpty(snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.AwsBackup])
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.snapshots.awsBackup({
                        snapshotIds:
                            <InlineEntities
                                entityIdsOrModels={snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.AwsBackup]}
                                entityTypeName={Contract.TypeNames.AwsRdsNeptuneClusterSnapshot}
                                variant="itemCountAndType"/>,
                        translatedClusterTypeName
                    })),
        internal:
            _.isEmpty(riskModel.risk.aggregatedEntityIds) ||
            _.isNil(riskModel.risk.accessLevel) ||
            riskModel.risk.accessLevel === Contract.AwsResourceAccessLevel.Internal
                ? undefined
                : new RiskDefinitionContextItem(
                    (accessLevelSnapshotModels.length === riskModel.risk.aggregatedEntityIds.length)
                        ? localization.networkAccess.all({
                            accessLevel: resourceAccessLevelTranslator(riskModel.risk.accessLevel),
                            translatedSnapshotTypeName:
                                entityTypeNameTranslator(
                                    accessLevelSnapshotModels[0].entity.typeName,
                                    {
                                        count: 0,
                                        includeServiceName: false,
                                        variant: "text"
                                    })
                        })
                        : localization.networkAccess.some({
                            accessLevel: resourceAccessLevelTranslator(riskModel.risk.accessLevel),
                            accessLevelSnapshots:
                                <InlineEntities
                                    entityIdsOrModels={accessLevelSnapshotModels}
                                    entityTypeName={Contract.TypeNames.AwsRdsNeptuneClusterSnapshot}
                                    variant="itemCountAndType"/>,
                            translatedClusterTypeName
                        })),
        manual:
            _.isEmpty(snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.Manual])
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.snapshots.manual({
                        snapshotIds:
                            <InlineEntities
                                entityIdsOrModels={snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.Manual]}
                                entityTypeName={Contract.TypeNames.AwsRdsNeptuneClusterSnapshot}
                                variant="itemCountAndType"/>,
                        translatedClusterTypeName
                    })),
        snapshotIdsEmpty:
            _.isEmpty(riskModel.risk.aggregatedEntityIds)
                ? new RiskDefinitionContextItem(
                    localization.snapshots.empty({ translatedClusterTypeName }))
                : undefined
    };
}