import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, TypeMetadataHelper } from "../../../../../../../common";

export function useReportScheduleCadenceTranslator() {
    const localization =
        useLocalization(
            "views.customer.reports.schedule.hooks.useReportScheduleCadenceTranslator",
            () => ({
                [Contract.TypeNames.ReportScheduleCadenceInterval]: {
                    [Contract.ReportScheduleCadenceInterval.Daily]: "Daily at {{hour | TimeFormatter.hour}}",
                    [Contract.ReportScheduleCadenceInterval.Monthly]: "Monthly on the {{monthDay | TimeFormatter.humanizeMonthDay}}",
                    [Contract.ReportScheduleCadenceInterval.Weekly]: "Weekly on {{weekDay | TimeFormatter.humanizeWeekDay}}"
                }
            }));
    return (cadence: Contract.ReportScheduleCadence) =>
        localization[Contract.TypeNames.ReportScheduleCadenceInterval][cadence.interval]({
            hour: cadence.hour,
            monthDay: cadence.monthDay,
            weekDay:
                _.isNil(cadence.weekDay)
                    ? undefined
                    : TypeMetadataHelper.getTypeMetadata(Contract.TypeNames.DayOfWeek).nameToValueMap![cadence.weekDay]
        });
}