import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsRdsSnapshotEncryptionDisabledRiskResolutionSteps, useGetAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext } from ".";
import { useGetAwsRdsClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsRdsSnapshotEncryptionDisabledRiskClusterDefinition(riskModel: Contract.AwsRdsSnapshotEncryptionDisabledRiskModel) {
    const risk = riskModel.risk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsClusterModel;

    const getAwsRdsClusterRiskContext = useGetAwsRdsClusterRiskContext();
    const getAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext = useGetAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsSnapshotEncryptionDisabledRiskDefinition.hooks.useAwsRdsSnapshotEncryptionDisabledRiskClusterDefinition",
            () => ({
                description: {
                    many: "{{cluster}} has {{snapshots}} that are not configured with KMS encryption",
                    none: "{{cluster}} is not encrypted using KMS",
                    single: "{{cluster}} has {{snapshots}} that is not configured with KMS encryption"
                }
            }));

    const resolutionSteps = useAwsRdsSnapshotEncryptionDisabledRiskResolutionSteps(clusterModel);

    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.none
            : risk.aggregatedEntityIds.length === 1
                ? localization.description.single
                : localization.description.many)({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            snapshots:
                <InlineEntities
                    entityIdsOrModels={risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.AwsRdsSnapshot}
                    variant="itemCountAndType"/>
        }),
        () => resolutionSteps,
        riskModel,
        () => {
            const clusterContextItems = getAwsRdsClusterRiskContext(clusterModel);
            const snapshotEncryptionDisabledRiskSourceResourceContextItems = getAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext(riskModel);
            return [
                clusterContextItems.generalInformation,
                snapshotEncryptionDisabledRiskSourceResourceContextItems.awsBackup,
                snapshotEncryptionDisabledRiskSourceResourceContextItems.manual,
                snapshotEncryptionDisabledRiskSourceResourceContextItems.automatic,
                clusterContextItems.engine,
                clusterContextItems.storageSize,
                snapshotEncryptionDisabledRiskSourceResourceContextItems.internal,
                snapshotEncryptionDisabledRiskSourceResourceContextItems.snapshotIdsEmpty,
                clusterContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}