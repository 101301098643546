import { DataTableColumnRenderProps, Message, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntityAttributeDefinitions } from "../../../../../../../../../../../common";

export function EntityAttributeCell({ item }: DataTableColumnRenderProps<Contract.RiskAutomationRuleConfiguration>) {
    const localization =
        useLocalization(
            "views.customer.configuration.automations.riskAutomations.table.entityAttributeCell",
            () => ({
                empty: "This rule has been configured to trigger automations on findings with specified labels that have been deleted, so no automation will be triggered. Edit the rule to configure new labels."
            }));
    return !_.isNil(item.entityAttributeValues) && _.isEmpty(item.entityAttributeValues)
        ? <Message
            level="error"
            title={localization.empty()}
            variant="minimal"/>
        : <EntityAttributeDefinitions
            breakpointToTopCount={{ "xs": 1 }}
            builtInEntityAttributeTypeNames={item.builtInEntityAttributeTypeNames ?? []}
            customEntityAttributeDefinitionIds={item.customEntityAttributeDefinitionIds ?? []}/>;
}