import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../..";
import { InlineEntities } from "../../../../../..";
import { AccessDefinitionEntitiesPermissionActionsPermittersProps } from "../../..";

export function Permitters({ actionData }: AccessDefinitionEntitiesPermissionActionsPermittersProps) {
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useGcpDefinition.permitters",
            () => ({
                permitters: "Granted through {{permitterEntities}}"
            }));
    const { permissionPathIdentifiers } = actionData as Contract.GcpAccessListPermissionActionData;
    return (
        <Typography>
            {localization.permitters({
                permitterEntities:
                    <InlineEntities
                        entityIdsOrModels={
                            _(permissionPathIdentifiers).
                                map(permissionPathIdentifier => permissionPathIdentifier.roleId).
                                uniq().
                                value()}
                        entityTypeName={Contract.TypeNames.GcpIamRole}
                        variant="itemOrItemCountAndType"/>
            })}
        </Typography>);
}