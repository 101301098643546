﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useAwsDefaultResourceInfoItemElements } from "..";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { AwsCloudFrontDistributionBehaviorsTable, AwsCloudFrontDistributionOriginsTable } from "../../../../../../../../../../tenants";
import { useAwsCloudFrontDistributionStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useAwsCloudFrontDistributionDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const distribution = resourceModel.entity as Contract.AwsCloudFrontDistribution;
    const distributionModel = resourceModel as Contract.AwsCloudFrontDistributionModel;

    const cloudFrontDistributionStatusTranslator = useAwsCloudFrontDistributionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCloudFrontDistributionDefinition",
            () => ({
                info: {
                    cards: {
                        cacheBehaviors: "Behaviors",
                        origins: "Origins"
                    },
                    items: {
                        alternativeDomainNames: "Alternative Domain Names",
                        certificate: "Custom SSL Certificate",
                        defaultRootObjectName: "Default Root Object",
                        domainName: "Domain Name",
                        loggingBucket: "S3 Bucket",
                        loggingEnabled: {
                            false: "Off",
                            title: "Logging",
                            true: "On"
                        },
                        origins: "Origins",
                        status: "Status",
                        updateTime: "Update Time",
                        webAcl: "WAF web ACL"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="updateTime"
                        title={localization.info.items.updateTime()}
                        value={TimeFormatter.profileFormatDateTime(distribution.updateTime)}/>,
                    <InfoItem
                        key="domainName"
                        title={localization.info.items.domainName()}
                        value={distribution.domainName}/>,
                    <VerticalTopItemsInfoItem
                        items={distribution.alternativeDomainNames}
                        key="alternativeDomainNames"
                        title={localization.info.items.alternativeDomainNames()}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={cloudFrontDistributionStatusTranslator(distribution.status)}/>,
                    <InfoItem
                        key="defaultRootObjectName"
                        title={localization.info.items.defaultRootObjectName()}
                        value={distribution.defaultRootObjectName}/>,
                    <InfoItem
                        key="loggingEnabled"
                        title={localization.info.items.loggingEnabled.title()}
                        value={
                            distribution.loggingEnabled
                                ? localization.info.items.loggingEnabled.true()
                                : localization.info.items.loggingEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={distributionModel.loggingBucketIdReference}
                        entityTypeName={Contract.TypeNames.AwsS3Bucket}
                        key="loggingBucket"
                        title={localization.info.items.loggingBucket()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={_.head(distribution.certificateIds)}
                        entityTypeName={Contract.TypeNames.AwsResource}
                        key="certificate"
                        title={localization.info.items.certificate()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={distributionModel.webAclId}
                        entityTypeName={Contract.TypeNames.AwsWafGlobalWebAcl}
                        key="wafWebAcl"
                        title={localization.info.items.webAcl()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={
                            _(distributionModel.originIdToResourceIdReferenceMap).
                                values().
                                uniq().
                                value()}
                        entityTypeName={Contract.TypeNames.AwsResource}
                        key="origins"
                        location="miniGlance"
                        title={localization.info.items.origins()}/>
                ]}
                options={options?.infoOptions}>
                <InfoCard title={localization.info.cards.origins()}>
                    <AwsCloudFrontDistributionOriginsTable
                        originIdToResourceIdReferenceMap={distributionModel.originIdToResourceIdReferenceMap}
                        origins={distribution.origins}/>
                </InfoCard>
                <InfoCard title={localization.info.cards.cacheBehaviors()}>
                    <AwsCloudFrontDistributionBehaviorsTable
                        behaviors={distribution.behaviors}
                        originIdToResourceIdReferenceMap={distributionModel.originIdToResourceIdReferenceMap}/>
                </InfoCard>
            </Info>
    });
}