﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstanceRiskContext } from "../../../contexts";

export function useGcpComputeInstanceIpForwardingEnabledRiskInstanceDefinition(riskModel: Contract.GcpComputeInstanceIpForwardingEnabledRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceIpForwardingEnabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeInstanceModel;
    const instance = instanceModel.entity as Contract.GcpComputeInstance;
    const tenantModel =
        tenantModelStore.useGet(
            instanceModel.unknown
                ? undefined
                : instanceModel.tenantId);

    const getGcpComputeInstanceRiskContext = useGetGcpComputeInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceIpForwardingEnabledRiskDefinition.hooks.useGcpComputeInstanceIpForwardingEnabledRiskInstanceDefinition",
            () => ({
                description: "{{instance}} has IP forwarding enabled",
                sections: {
                    resolution: {
                        step1: "Use the instances export command to export the instance properties: gcloud compute instances export {{instanceName}} --project {{tenantRawShortNameId}} --zone {{location}} --destination=<FILE_PATH>",
                        step2: "Use a text editor and change the **canIpForward** value to **False**",
                        step3: "Use the instances update-from-file command to update the instance: gcloud compute instances update-from-file {{instanceName}} --project {{tenantRawShortNameId}} --zone {{location}} --source=<FILE_PATH>"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1({
                instanceName: instance.name,
                location: RegionModelHelper.get(instance.regionId!).systemName,
                tenantRawShortNameId: (tenantModel!.configuration as Contract.GcpTenantConfiguration).rawShortNameId
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3({
                instanceName: instance.name,
                location: RegionModelHelper.get(instance.regionId!).systemName,
                tenantRawShortNameId: (tenantModel!.configuration as Contract.GcpTenantConfiguration).rawShortNameId
            })
        ],
        riskModel,
        () => {
            const instanceContextItems = getGcpComputeInstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.status,
                instanceContextItems.vpcsAndSubnets,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}