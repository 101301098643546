﻿import React, { useEffect, useRef } from "react";
import ResizeObserver from "react-resize-observer";

type ResizerProps = {
    onSize?: (rect: DOMRect) => void;
};

export function Resizer({ onSize }: ResizerProps) {
    const resizeObserverRef = useRef<any>(null);
    const resizerMountedRef = useRef(false);
    useEffect(
        () => {
            resizerMountedRef.current = true;
            onSize?.(resizeObserverRef.current!._lastRect);
        },
        []);

    return (
        <ResizeObserver
            ref={resizeObserverRef}
            onResize={
                rect => {
                    if (resizerMountedRef.current) {
                        const domRect = new DOMRect(rect.x, rect.y, rect.width, rect.height);
                        onSize?.(domRect);
                    }
                }}/>);
}