import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useAwsElastiCacheReplicationGroupTransitEncryptionModeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsElastiCacheReplicationGroupRiskContext() {
    return useMemo(
        () => useAwsElastiCacheReplicationGroupContext,
        []);
}

function useAwsElastiCacheReplicationGroupContext(elastiCacheReplicationGroupModel: Contract.AwsElastiCacheReplicationGroupModel) {
    const replicationGroup = elastiCacheReplicationGroupModel.entity as Contract.AwsElastiCacheReplicationGroup;
    const resourceContextItems = useGetAwsResourceRiskContext()(elastiCacheReplicationGroupModel);

    const elastiCacheReplicationGroupTransitEncryptionModeTranslator = useAwsElastiCacheReplicationGroupTransitEncryptionModeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsElastiCacheReplicationGroupRiskContext",
            () => ({
                automaticBackups: {
                    false: "Disabled",
                    title: "The automatic backup is **{{automaticBackups}}**",
                    true: "Enabled"
                },
                backupRetentionTimeFrame: "The automatic backup retention period is currently set to **{{backupRetentionTimeFrame}}**",
                transitEncryptionMode: {
                    preferred: "Preferred",
                    required: "Required",
                    title: "The encryption mode is set to **{{transitEncryptionMode}}**"
                }
            }));

    return {
        ...resourceContextItems,
        automaticBackups:
            new RiskDefinitionContextItem(
                localization.automaticBackups.title({
                    automaticBackups:
                        replicationGroup.automaticBackups
                            ? localization.automaticBackups.true()
                            : localization.automaticBackups.false()
                })),
        backupRetentionTimeFrame:
            new RiskDefinitionContextItem(
                localization.backupRetentionTimeFrame({ backupRetentionTimeFrame: TimeSpanFormatter.day(replicationGroup.backupRetentionTimeFrame) })),
        transitEncryptionMode:
            _.isNil(replicationGroup.transitEncryptionMode)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.transitEncryptionMode.title({
                        transitEncryptionMode:
                            _.isNil(replicationGroup.transitEncryptionMode)
                                ? undefined
                                : elastiCacheReplicationGroupTransitEncryptionModeTranslator(replicationGroup.transitEncryptionMode)
                    }))
    };
}