import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureKeyVaultObjectRiskContext() {
    return useMemo(
        () => useAzureKeyVaultObjectRiskContext,
        []);
}

function useAzureKeyVaultObjectRiskContext(objectModel: Contract.AzureKeyVaultVaultObjectModel) {
    const object = objectModel.entity as Contract.AzureKeyVaultVaultObject;
    const resourceRiskContext = useGetAzureResourceRiskContext()(objectModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureKeyVaultObjectRiskContext",
            () => ({
                keyVault: "The {{translatedObjectTypeName}} is linked to key vault {{keyVault}}"
            }));

    return {
        ...resourceRiskContext,
        keyVault:
            new RiskDefinitionContextItem(
                localization.keyVault({
                    keyVault:
                        <Entity
                            entityIdOrModel={object.vaultId}
                            variant="text"/>,
                    translatedObjectTypeName:
                        entityTypeNameTranslator(
                            object.typeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                }))
    };
}