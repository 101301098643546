import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { map, useLocalization } from "@infrastructure";
import { useTheme } from "../../../../../../../../../../../../../../../../common";
import { CloudFormationCli, CloudFormationConsole } from "./components";

export function RoleItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit.roleItem",
            () => ({
                onboardingType: {
                    [OnboardingType.CloudFormationCli]: "CloudFormation (AWS CLI)",
                    [OnboardingType.CloudFormationConsole]: "CloudFormation (AWS Console)"
                }
            }));

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(OnboardingType.CloudFormationConsole);
    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={selectedOnboardingType}
                variant="standard"
                onChange={(_, value) => setSelectedOnboardingType(value)}>
                {_.map(
                    [
                        OnboardingType.CloudFormationConsole,
                        OnboardingType.CloudFormationCli
                    ],
                    onboardingType =>
                        <Tab
                            key={onboardingType}
                            label={localization.onboardingType[onboardingType]()}
                            sx={{ padding: theme.spacing(1, 2) }}
                            value={onboardingType}/>)}
            </Tabs>
            <Stack
                key={selectedOnboardingType}
                sx={{ overflow: "hidden auto" }}>
                <Box sx={{ maxWidth: theme.spacing(80) }}>
                    {map(
                        selectedOnboardingType,
                        {
                            [OnboardingType.CloudFormationCli]: () => <CloudFormationCli/>,
                            [OnboardingType.CloudFormationConsole]: () => <CloudFormationConsole/>
                        })}
                </Box>
            </Stack>
        </Stack>);
}

enum OnboardingType {
    CloudFormationCli = "cloudFormationCli",
    CloudFormationConsole = "cloudFormationConsole"
}