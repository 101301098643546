import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, EntityFilter, entityModelStore, ItemTable } from "../../../../../../../../../common";

type ApplicationRoleAssignmentsProps = {
    applicationRoleDefinitions: Contract.AadDirectoryApplicationRoleDefinition[];
    assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap: Dictionary<string[]>;
    csvExportFilePrefixes: string[];
};

export function AadDirectoryServicePrincipalApplicationRoleAssignments({ applicationRoleDefinitions, assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap, csvExportFilePrefixes }: ApplicationRoleAssignmentsProps) {
    const principalModels = entityModelStore.useGet(_.keys(assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap));
    const [applicationRoleDefinitionMap, items, principalModelMap] =
        useMemo(
            () => {
                const applicationRoleDefinitionMap =
                    _.keyBy(
                        applicationRoleDefinitions,
                        applicationRoleDefinition => applicationRoleDefinition.rawId);
                const applicationRoleDefinitionRawIds = _.keys(applicationRoleDefinitionMap);

                const items =
                    _(assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap).
                        map(
                            (assignedApplicationRoleDefinitionsRawIds, assignedPrincipalId) =>
                                new ApplicationRoleAssignmentsInfoCardItem(
                                    _.intersection(
                                        assignedApplicationRoleDefinitionsRawIds,
                                        applicationRoleDefinitionRawIds),
                                    assignedPrincipalId)).
                        filter(item => !_.isEmpty(item.assignedApplicationRoleDefinitionRawIds)).
                        value();

                const principalModelMap =
                    _.keyBy(
                        principalModels,
                        principalModel => principalModel.entity.id);

                return [applicationRoleDefinitionMap, items, principalModelMap];
            },
            [assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap, principalModels]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.aadDirectoryServicePrincipalApplicationRoleAssignments",
            () => ({
                columns: {
                    principal: "Principal",
                    roles: "Roles"
                },
                empty: "No Users or Groups"
            }));

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedPrincipalId]: {
                    getFilterValue: item => item.assignedPrincipalId,
                    getSortValue: item => principalModelMap[item.assignedPrincipalId].entity.displayName
                },
                [ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedApplicationRoleDefinitionRawIds]: item => item.assignedApplicationRoleDefinitionRawIds
            }}
            csvExportFilePrefixes={csvExportFilePrefixes}
            defaultSortColumnIdOrIds={ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedPrincipalId}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => ({
                    "Principal": principalModelMap[item.assignedPrincipalId].entity.displayReference,
                    "Roles":
                        _(item.assignedApplicationRoleDefinitionRawIds).
                            map(assignedApplicationRoleDefinitionRawId => applicationRoleDefinitionMap[assignedApplicationRoleDefinitionRawId].name).
                            join("\n")
                })}
            getItemId={item => item.assignedPrincipalId}
            items={items}>
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedPrincipalId]}
                                    placeholder={localization.columns.principal()}/>
                        }
                    }}
                    id={ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedPrincipalId}
                    key={ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedPrincipalId}
                    render={
                        ({ item }: DataTableColumnRenderProps<ApplicationRoleAssignmentsInfoCardItem>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.assignedPrincipalId}
                                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                inlineEntitiesVariant="itemPlusItemCount"/>}
                    title={localization.columns.principal()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter placeholder={localization.columns.roles()}>
                                    {_.map(
                                        columnIdToItemValuesMap[ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedApplicationRoleDefinitionRawIds],
                                        assignedApplicationRoleDefinitionRawId =>
                                            <ValuesFilterItem
                                                key={assignedApplicationRoleDefinitionRawId}
                                                title={applicationRoleDefinitionMap[assignedApplicationRoleDefinitionRawId].name}
                                                value={assignedApplicationRoleDefinitionRawId}/>)}
                                </ValuesFilter>
                        }
                    }}
                    id={ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedApplicationRoleDefinitionRawIds}
                    key={ApplicationRoleAssignmentsInfoCardTableColumnId.AssignedApplicationRoleDefinitionRawIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<ApplicationRoleAssignmentsInfoCardItem>) =>
                            <InlineItems
                                items={
                                    _.map(
                                        item.assignedApplicationRoleDefinitionRawIds,
                                        assignedApplicationRoleDefinitionRawId => applicationRoleDefinitionMap[assignedApplicationRoleDefinitionRawId].name)}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.roles()}/>
            ]}
        </ItemTable>);
}

class ApplicationRoleAssignmentsInfoCardItem {
    constructor(
        public assignedApplicationRoleDefinitionRawIds: string[],
        public assignedPrincipalId: string) {
    }
}

enum ApplicationRoleAssignmentsInfoCardTableColumnId {
    AssignedApplicationRoleDefinitionRawIds = "assignedApplicationRoleDefinitionRawIds",
    AssignedPrincipalId = "assignedPrincipalId"
}