import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useSeverityTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useSeverityTranslator",
            () => ({
                [Contract.TypeNames.Severity]: {
                    initial: {
                        dynamic: "D",
                        [Contract.Severity.Critical]: "C",
                        [Contract.Severity.High]: "H",
                        [Contract.Severity.Information]: "I",
                        [Contract.Severity.Low]: "L",
                        [Contract.Severity.Medium]: "M"
                    },
                    text: {
                        dynamic: "dynamic",
                        [Contract.Severity.Critical]: "critical",
                        [Contract.Severity.High]: "high",
                        [Contract.Severity.Information]: "informational",
                        [Contract.Severity.Low]: "low",
                        [Contract.Severity.Medium]: "medium"
                    },
                    title: {
                        dynamic: "Dynamic",
                        [Contract.Severity.Critical]: "Critical",
                        [Contract.Severity.High]: "High",
                        [Contract.Severity.Information]: "Informational",
                        [Contract.Severity.Low]: "Low",
                        [Contract.Severity.Medium]: "Medium"
                    }
                }
            }));
    return (severity?: Contract.Severity, variant: "text" | "title" | "initial" = "title") =>
        localization[Contract.TypeNames.Severity][variant][severity ?? "dynamic"]();
}