import { useMemo } from "react";
import { useAwsDefaultKubernetesNamespaceResourceDefinition, useAwsDefaultKubernetesResourceDefinition, useAwsKubernetesClusterRoleBindingDefinition, useAwsKubernetesClusterRoleDefinition, useAwsKubernetesGroupDefinition, useAwsKubernetesIngressDefinition, useAwsKubernetesNamespaceDefinition, useAwsKubernetesNamespaceRoleBindingDefinition, useAwsKubernetesNamespaceRoleDefinition, useAwsKubernetesServiceAccountDefinition, useAwsKubernetesServiceDefinition, useAwsKubernetesUserDefinition, useAwsKubernetesWorkloadResourceDefinition } from ".";
import { Contract, TypeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsKubernetesResourceDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.AwsKubernetesClusterRole:
                        return useAwsKubernetesClusterRoleDefinition;
                    case Contract.TypeNames.AwsKubernetesClusterRoleBinding:
                        return useAwsKubernetesClusterRoleBindingDefinition;
                    case Contract.TypeNames.AwsKubernetesGroup:
                        return useAwsKubernetesGroupDefinition;
                    case Contract.TypeNames.AwsKubernetesIngress:
                        return useAwsKubernetesIngressDefinition;
                    case Contract.TypeNames.AwsKubernetesNamespace:
                        return useAwsKubernetesNamespaceDefinition;
                    case Contract.TypeNames.AwsKubernetesNamespaceRole:
                        return useAwsKubernetesNamespaceRoleDefinition;
                    case Contract.TypeNames.AwsKubernetesNamespaceRoleBinding:
                        return useAwsKubernetesNamespaceRoleBindingDefinition;
                    case Contract.TypeNames.AwsKubernetesService:
                        return useAwsKubernetesServiceDefinition;
                    case Contract.TypeNames.AwsKubernetesServiceAccount:
                        return useAwsKubernetesServiceAccountDefinition;
                    case Contract.TypeNames.AwsKubernetesUser:
                        return useAwsKubernetesUserDefinition;
                    case Contract.TypeNames.AwsKubernetesWorkloadResourceBase:
                        return useAwsKubernetesWorkloadResourceDefinition;
                    default:
                        return TypeHelper.extendOrImplement(definitionData.entityTypeEntitiesViewName, Contract.TypeNames.AwsKubernetesNamespaceResourceBase)
                            ? useAwsDefaultKubernetesNamespaceResourceDefinition
                            : useAwsDefaultKubernetesResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}