import { DataTableColumn, DataTableColumnRenderProps, useExecuteOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ActionHelper, Contract, ElasticsearchItemPageHelper, EntitiesCell, EntityAttributes, InlinePermissionActions, ItemSelectionHelper, PagedEntityFilter, RiskController, RiskType } from "../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../useCommonCloudRiskPolicyTableDefinition";

export function useAzureBehaviorDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const [{ filters }] =
        useExecuteOperation(
            [useAzureBehaviorDefinition, riskPolicyConfigurationTypeName],
            () =>
                RiskController.getRiskPolicyTypeGroupFilters(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest(
                        requestFilters!,
                        riskPolicyConfigurationTypeName)));

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useBehaviorDefinition.hooks.useAzureBehaviorDefinition",
            () => ({
                columns: {
                    eventActions: "Actions",
                    resourceDisplayNames: "Affected Resources",
                    riskedEntityAttributes: "Labels"
                }
            }));

    const azureBehaviorIdentityRiskPolicyGroupFilters = filters as Contract.AzureBehaviorIdentityRiskPolicyGroupFilters;
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();
    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: azureBehaviorIdentityRiskPolicyGroupFilters.eventActionItems.emptyValue }}
                                groupItemTitle={true}
                                placeholder={localization.columns.eventActions()}>
                                {_.map(
                                    azureBehaviorIdentityRiskPolicyGroupFilters.eventActionItems.items,
                                    eventAction =>
                                        <ValuesFilterItem
                                            key={eventAction}
                                            title={ActionHelper.getRawValue(eventAction)}
                                            value={eventAction}/>)}
                            </ValuesFilter>
                        }
                    }}
                    id={TableColumnId.AzureBehaviorIdentityRiskEventActions}
                    key={TableColumnId.AzureBehaviorIdentityRiskEventActions}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureBehaviorIdentityRiskModel>) =>
                            <Typography noWrap={true}>
                                <InlinePermissionActions
                                    permissionActions={item.risk.eventActions}
                                    variant="itemOrItemCountAndType"/>
                            </Typography>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.eventActions()}/>,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedRiskFilterEntityItem(
                                        requestFilters as Contract.RiskControllerCloudRiskModelFilters,
                                        Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty.AzureBehaviorIdentityRiskResources,
                                        riskPolicyConfigurationTypeName)}
                                placeholder={localization.columns.resourceDisplayNames()}/>
                        }
                    }}
                    id={TableColumnId.AzureBehaviorIdentityRiskResourceIds}
                    key={TableColumnId.AzureBehaviorIdentityRiskResourceIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureBehaviorIdentityRiskModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.resourceIdReferences}
                                entityTypeName={Contract.TypeNames.AzureResource}
                                entityVariant="iconText"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.resourceDisplayNames()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                <DataTableColumn
                    disableAction={true}
                    id={TableColumnId.RiskedEntityAttributes}
                    key={TableColumnId.RiskedEntityAttributes}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                            <EntityAttributes
                                breakpointToTopCount={{ "xs": 1 }}
                                entityAttributes={item.entityAttributes}
                                entityCount={item.risk.riskedEntityIds.length}
                                entityTypeName={item.riskedEntityTypeName}
                                orderVariant="leftToRight"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.riskedEntityAttributes()}/>,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerAzureBehaviorIdentityRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AzureBehaviorIdentityRiskEventActions]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.AzureBehaviorIdentityRiskResourceIds])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}