import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, Optional, optionalTableCell, PagedValuesFilter, TimeSpanFormatter, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { GcpRoleBindingHelper } from "../../../../../../../../../../tenants";
import { useGcpIamWorkforcePoolProviderTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpIamWorkforcePoolDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const workforcePoolProviderTypeTranslator = useGcpIamWorkforcePoolProviderTypeTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpIamWorkforcePoolDefinition",
            () => ({
                columns: {
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    identityContainedRoleBindingIds: {
                        helpText: "These role bindings are \"covered\" by another role binding at a higher scope level or one that is assigned via group membership.",
                        title: "Covered GCP Role Bindings"
                    },
                    identityRoleBindingIds: "GCP Role Bindings",
                    providerTypes: "Provider Types",
                    rawShortId: "ID",
                    roleIds: "GCP Roles",
                    sessionDuration: "Session Duration"
                }
            }));

    return new EntityTableDefinition(
        [
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamWorkforcePoolModel) => ({
                            [localization.columns.rawShortId()]: item.rawShortId
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamWorkforcePoolRawShortId)}
                                placeholder={localization.columns.rawShortId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamWorkforcePoolRawShortId}
                key={Contract.EntityModelProperty.GcpIamWorkforcePoolRawShortId}
                render={optionalTableCell<Contract.GcpIamWorkforcePoolModel>(item => item.rawShortId)}
                title={localization.columns.rawShortId()}/>,
            commonScopeResourceDefinition.columns.scopeColumn,
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamWorkforcePoolModel) => ({
                            [localization.columns.enabled.title()]:
                                (item.entity as Contract.GcpIamWorkforcePool).enabled
                                    ? localization.columns.enabled.true()
                                    : localization.columns.enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.enabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamWorkforcePoolEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.GcpIamWorkforcePool).enabled
                            ? localization.columns.enabled.true()
                            : localization.columns.enabled.false()}
                key={Contract.EntityModelProperty.GcpIamWorkforcePoolEnabled}
                title={localization.columns.enabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamWorkforcePoolModel) => ({
                            [localization.columns.providerTypes()]:
                                _.map(
                                    item.workforcePoolProviderTypes,
                                    workforcePoolProviderTypeTranslator).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.GcpIamWorkforcePoolProviderType}
                                enumTypeTranslator={workforcePoolProviderTypeTranslator}
                                placeholder={localization.columns.providerTypes()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamWorkforcePoolProviderType}
                key={Contract.EntityModelProperty.GcpIamWorkforcePoolProviderType}
                render={
                    optionalTableCell<Contract.GcpIamWorkforcePoolModel>(
                        (item: Contract.GcpIamWorkforcePoolModel) =>
                            <InlineItems
                                items={
                                    _.map(
                                        item.workforcePoolProviderTypes,
                                        workforcePoolProviderTypeTranslator)}
                                variant="itemPlusItemCount"/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.providerTypes()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamWorkforcePoolModel) => ({
                            [localization.columns.sessionDuration()]: TimeSpanFormatter.dayHourMinuteSecond((item.entity as Contract.GcpIamWorkforcePool).sessionDuration)
                        })
                }}
                id={Contract.EntityModelProperty.GcpIamWorkforcePoolSessionDuration}
                itemProperty={
                    (item: Contract.GcpIamWorkforcePoolModel) => {
                        const sessionDuration = (item.entity as Contract.GcpIamWorkforcePool).sessionDuration;
                        return !_.isNil(sessionDuration)
                            ? TimeSpanFormatter.dayHourMinuteSecond(sessionDuration)
                            : undefined;
                    }}
                key={Contract.EntityModelProperty.GcpIamWorkforcePoolSessionDuration}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.sessionDuration()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamWorkforcePoolModel>(
                        Contract.TypeNames.GcpIamRoleBinding,
                        item => item.identityRoleBindingIds,
                        localization.columns.identityRoleBindingIds(),
                        { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                }
                id={Contract.EntityModelProperty.GcpIamWorkforcePoolRoleBindings}
                key={Contract.EntityModelProperty.GcpIamWorkforcePoolRoleBindings}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamWorkforcePoolModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.identityRoleBindingIds}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.identityRoleBindingIds()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamWorkforcePoolRoles)}
                                placeholder={localization.columns.roleIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamWorkforcePoolRoles}
                key={Contract.EntityModelProperty.GcpIamWorkforcePoolRoles}
                title={localization.columns.roleIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamWorkforcePoolModel>(
                        Contract.TypeNames.GcpIamRoleBinding,
                        item => item.identityContainedRoleBindingIds,
                        localization.columns.identityContainedRoleBindingIds.title(),
                        { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.identityContainedRoleBindingIds.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamWorkforcePoolContainedRoleBindings}
                key={Contract.EntityModelProperty.GcpIamWorkforcePoolContainedRoleBindings}
                message={localization.columns.identityContainedRoleBindingIds.helpText()}
                messageLevel="info"
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamWorkforcePoolModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.identityContainedRoleBindingIds}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.identityContainedRoleBindingIds.title()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpIamWorkforcePoolRequest(
                new Contract.EntityControllerGetEntityModelPageGcpIamWorkforcePoolRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    filterMap[Contract.EntityModelProperty.GcpIamWorkforcePoolContainedRoleBindings] as Optional<boolean>,
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamWorkforcePoolEnabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamWorkforcePoolProviderType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamWorkforcePoolRawShortId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamWorkforcePoolRoles])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}