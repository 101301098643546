import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useSqsQueueAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useSqsQueueAuditEventDefinition",
            () => ({
                propertyChanges: "Updated",
                queueName: "Name",
                queueRoleArn: {
                    default: "queue's account role ARN",
                    title: "Role ARN"
                },
                queueUrl: "URL"
            }));

    const sqsQueueAuditEvent = context.auditEventModel.auditEvent as Contract.SqsQueueAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.queueUrl(),
                    sqsQueueAuditEvent.queueUrl),
                new AuditEventDefinitionDetailItem(
                    localization.queueName(),
                    sqsQueueAuditEvent.queueName),
                new AuditEventDefinitionDetailItem(
                    localization.queueRoleArn.title(),
                    sqsQueueAuditEvent.queueRoleArn ?? localization.queueRoleArn.default())).
            concatIf(
                sqsQueueAuditEvent.typeName === Contract.TypeNames.SqsQueueUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (sqsQueueAuditEvent as Contract.SqsQueueUpdateAuditEvent).queueUrlChanged
                                ? localization.queueUrl()
                                : undefined,
                            (sqsQueueAuditEvent as Contract.SqsQueueUpdateAuditEvent).queueNameChanged
                                ? localization.queueName()
                                : undefined,
                            (sqsQueueAuditEvent as Contract.SqsQueueUpdateAuditEvent).queueRoleArnChanged
                                ? localization.queueRoleArn.title()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}