
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InfrastructureModificationIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M14.3 15.6H13.5C12.1 15.6 10.8 15.1 9.8 14.1C8.8 13.1 8.3 11.8 8.3 10.4V10C8.3 6.40002 8.3 3.90002 9.8 2.40002C11.3 0.900025 12.1 0.900024 17.2 0.900024H17.8C18.1 0.900024 18.4 0.900024 18.7 1.20002C19 1.40002 19.2 1.60002 19.3 1.90002C19.4 2.20002 19.5 2.50002 19.3 2.80002C19.3 3.10002 19.1 3.40002 18.9 3.60002L16 6.50002C16 6.50002 15.8 6.70002 15.8 6.80002C15.8 6.90002 15.8 7.00002 15.8 7.10002C15.8 7.20002 15.8 7.30002 15.8 7.40002C15.8 7.50002 15.9 7.60002 16 7.70002C16 7.70002 16.2 7.80002 16.3 7.90002C16.4 7.90002 16.5 7.90002 16.6 7.90002C16.7 7.90002 16.8 7.90002 16.9 7.90002C17 7.90002 17.1 7.80002 17.2 7.70002L20.1 4.80002C20.3 4.60002 20.6 4.40002 20.9 4.40002C21.2 4.40002 21.5 4.40002 21.8 4.40002C22.1 4.50002 22.3 4.70002 22.5 5.00002C22.7 5.30002 22.8 5.60002 22.8 5.90002V6.50002C22.8 11.7 22.8 12.5 21.3 14C19.8 15.4 17.6 15.5 14.1 15.5L14.3 15.6ZM16.9 3.00002C12.3 3.00002 12.1 3.00002 11.2 3.90002C10.3 4.80002 10.3 7.00002 10.3 10V10.4C10.3 11.3 10.6 12.1 11.2 12.7C11.8 13.3 12.6 13.6 13.5 13.6H13.9C17 13.6 19.1 13.6 20 12.7C20.9 11.8 20.9 11.7 20.9 7.00002L18.7 9.20002C18.4 9.50002 18.1 9.70002 17.8 9.80002C17.4 9.90003 17.1 10 16.7 10C16.3 10 15.9 10 15.6 9.80002C15.2 9.70002 14.9 9.40002 14.7 9.20002C14.5 9.00002 14.2 8.60002 14.1 8.30002C14 7.90002 13.9 7.60002 13.9 7.20002C13.9 6.80002 13.9 6.40002 14.1 6.10002C14.2 5.70002 14.5 5.40002 14.7 5.20002L16.9 3.00002Z"/>
            <path d="M5 23C4 23 3 22.6 2.2 21.8C1.5 21.1 1 20.1 1 19C1 18 1.4 17 2.1 16.2L8.6 9.70002C9 9.30002 9.6 9.30002 10 9.70002C10.4 10.1 10.4 10.7 10 11.1L3.5 17.6C3.2 18 3 18.5 3 19C3 19.5 3.2 20 3.6 20.4C4 20.8 4.5 21 5 21C5.5 21 6 20.8 6.4 20.4L12.9 13.9C13.3 13.5 13.9 13.5 14.3 13.9C14.7 14.3 14.7 14.9 14.3 15.3L7.8 21.8C7 22.5 6.1 22.9 5 22.9V23ZM5.2 19.9C4.6 19.9 4.2 19.5 4.2 18.9C4.2 18.3 4.6 17.9 5.2 17.9C5.8 17.9 6.2 18.3 6.2 18.9C6.2 19.5 5.8 19.9 5.2 19.9Z"/>
        </SvgIcon>);
}