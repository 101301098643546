import { Action0, CopyToClipboardActionButton, IconText, Link, Optional, useLocalization } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, memo, useEffect } from "react";
import { Contract, CustomerConsoleAppUrlHelper, InfoCard, InfoItem, Severity, useTheme, VulnerabilitiesIcon, vulnerabilityModelStore } from "../..";
import { ScoreCards, ThreatAndImpactFactorsInfoCard } from "./components";

export enum VulnerabilityEventType {
    CISADueDate = "CISADueDate",
    CISAKnownExploit = "CISAKnownExploit",
    DiscoveryDate = "DiscoveryDate",
    EmergingThreat = "EmergingThreat",
    ExploitedInTheWild = "ExploitedInTheWild",
    FirstProofOfConcept = "FirstProofOfConcept",
    FirstTenableCoverage = "FirstTenableCoverage",
    FunctionalExploit = "FunctionalExploit",
    Malware = "Malware",
    Mention = "Mention",
    NVDPublished = "NVDPublished",
    Ransomware = "Ransomware"
}

export enum VulnerabilityScoreSourceType {
    Cvss = "cvss",
    Epss = "epss",
    Vpr = "vpr"
}

const VulnerabilityMemo = memo(Vulnerability);
export { VulnerabilityMemo as Vulnerability };

type VulnerabilityProps = {
    onMount?: Action0;
    rawId: string;
    variant?: "full" | "minimal";
};

function Vulnerability({ onMount, rawId, variant = "minimal" }: VulnerabilityProps) {
    const vulnerabilityModel = vulnerabilityModelStore.useGet(rawId) as Optional<Contract.VulnerabilityModel>;

    useEffect(
        () => {
            onMount?.();
        },
        []);

    const localization =
        useLocalization(
            "common.vulnerability",
            () => ({
                displayName: "{{rawId}} - {{name}}",
                informationUnavailable: "Detailed information about this vulnerability is currently unavailable. Please try again later.",
                sections: {
                    threatFactors: {
                        title: "Threat Factors",
                        tooltip: "Learn more"
                    }
                }
            }));


    const minimalVariant = variant === "minimal";
    const theme = useTheme();
    return (
        <Stack
            sx={{
                maxHeight:
                    minimalVariant
                        ? 500
                        : undefined,
                overflow: "hidden",
                paddingRight: 0
            }}>
            {minimalVariant &&
                <Fragment>
                    <Stack
                        gap={theme.spacing(1)}
                        sx={{ padding: theme.spacing(1.75) }}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between">
                            <IconText
                                icon={
                                    <VulnerabilitiesIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "24px"
                                        }}/>}
                                text={
                                    <Typography
                                        noWrap={true}
                                        variant="h3">
                                        {_.isEmpty(vulnerabilityModel?.vulnerability.name)
                                            ? rawId
                                            : localization.displayName({
                                                name: vulnerabilityModel?.vulnerability.name,
                                                rawId
                                            })}
                                    </Typography>}/>
                            <CopyToClipboardActionButton
                                getValue={() => rawId}
                                size="normal"/>
                        </Stack>
                        {!_.isNil(vulnerabilityModel) &&
                            <Severity severity={vulnerabilityModel.severity}/>}
                    </Stack>
                    <Divider flexItem={true}/>
                </Fragment>}
            {_.isNil(vulnerabilityModel) &&
                <InfoCard
                    containerSx={{
                        flex: 1,
                        overflow: "hidden auto",
                        padding:
                            minimalVariant
                                ? theme.spacing(2)
                                : theme.spacing(2, 3, 2, 3)
                    }}>
                    <InfoItem
                        containerSx={{ maxWidth: "700px" }}
                        value={
                            <Typography
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%"
                                }}>
                                {localization.informationUnavailable()}
                            </Typography>}/>
                </InfoCard>}
            {!_.isNil(vulnerabilityModel) &&
                <Stack sx={{ overflow: "hidden auto" }}>
                    {_.every(
                        [
                            vulnerabilityModel.vulnerability.vprScore,
                            vulnerabilityModel.vulnerability.cvssScore,
                            vulnerabilityModel.vulnerability.epssScore
                        ],
                        _.isNil)
                        ? <Fragment/>
                        : <InfoCard
                            columnContainerSx={{ paddingRight: 0 }}
                            containerSx={{
                                padding:
                                    minimalVariant
                                        ? theme.spacing(2, 2, 0, 2)
                                        : theme.spacing(2, 0, 0, 2)
                            }}
                            itemContainerSx={{ paddingBottom: 0 }}>
                            <ScoreCards
                                variant={variant}
                                vulnerability={vulnerabilityModel.vulnerability}/>
                        </InfoCard>}
                    {_.isNil(vulnerabilityModel.vulnerability.description)
                        ? <Fragment/>
                        : <Fragment>
                            <InfoCard
                                containerSx={{ padding: theme.spacing(2, 2, 0, 2) }}
                                titleSx={{
                                    fontSize:
                                        minimalVariant
                                            ? "12px"
                                            : "14px",
                                    fontWeight:
                                        minimalVariant
                                            ? 600
                                            : 500,
                                    margin: theme.spacing(0)
                                }}>
                                <InfoItem
                                    containerSx={{
                                        maxWidth: "700px",
                                        paddingBottom: theme.spacing(1)
                                    }}
                                    value={
                                        <Typography
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%"
                                            }}>
                                            {vulnerabilityModel.vulnerability.description}
                                        </Typography>}/>
                            </InfoCard>
                            <Box
                                sx={{
                                    ...(!minimalVariant && {
                                        padding: theme.spacing(0, 2)
                                    })
                                }}>
                                <Divider/>
                            </Box>
                        </Fragment>}
                    <InfoCard
                        containerSx={{
                            gap: theme.spacing(1),
                            padding:
                                minimalVariant
                                    ? theme.spacing(1.5, 2, 1)
                                    : theme.spacing(2, 2, 1, 2)
                        }}
                        itemContainerSx={{ paddingBottom: 0 }}
                        title={localization.sections.threatFactors.title()}
                        titleHelp={
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsVulnerabilityThreatAndImpactFactorsUrl()}
                                variant="external">
                                {localization.sections.threatFactors.tooltip()}
                            </Link>}
                        titleSx={{
                            fontSize:
                                minimalVariant
                                    ? "12px"
                                    : "14px",
                            fontWeight:
                                minimalVariant
                                    ? 600
                                    : 500,
                            margin: theme.spacing(0)
                        }}>
                        <ThreatAndImpactFactorsInfoCard
                            variant={variant}
                            vulnerability={vulnerabilityModel.vulnerability}/>
                    </InfoCard>
                </Stack>}
        </Stack>);
}