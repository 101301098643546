import React from "react";
import { Contract, useDataSensitivityTranslator } from "../../index";
import { OutlineLabelButton } from "../OutlineLabelButton";
import { DataSensitivityIcon } from "./icons";

type DataSensitivityCellProps = {
    active?: boolean;
    count?: number;
    countLoading?: boolean;
    disabled?: boolean;
    onClick?: (category: Contract.DataSensitivity) => void;
    sensitivity: Contract.DataSensitivity;
};

export function DataSensitivity({ active, count, countLoading, disabled, onClick, sensitivity }: DataSensitivityCellProps) {
    const dataSensitivityTranslator = useDataSensitivityTranslator();
    return (
        <OutlineLabelButton
            active={active}
            className={`dataSensitivityButton ${sensitivity}`}
            count={count}
            countLoading={countLoading}
            disabled={disabled}
            icon={
                <DataSensitivityIcon
                    sensitivity={sensitivity}
                    sx={{
                        opacity:
                            disabled
                                ? 0.5
                                : 1
                    }}/>}
            label={dataSensitivityTranslator(sensitivity)}
            onClick={onClick && (_ => onClick?.(sensitivity))}/>);
}