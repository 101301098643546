import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useNetworkInboundAccessTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useNetworkInboundAccessTypeTranslator",
            () => ({
                [Contract.TypeNames.NetworkInboundAccessType]: {
                    [Contract.NetworkInboundAccessType.ExternalDirect]: "Internet (direct)",
                    [Contract.NetworkInboundAccessType.ExternalIndirect]: "Internet (indirect)"
                }
            }));
    return (inboundAccessType: Exclude<Contract.NetworkInboundAccessType, Contract.NetworkInboundAccessType.Internal>) =>
        localization[Contract.TypeNames.NetworkInboundAccessType][inboundAccessType]();
}