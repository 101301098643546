import { Dictionary } from "lodash";
import { Contract } from "../../../common";

export class AwsConsoleHelper {
    private static _partitionTypeToDomainNameMap: Dictionary<string>;

    public static initialize(partitionTypeToDomainNameMap: Dictionary<string>) {
        AwsConsoleHelper._partitionTypeToDomainNameMap = partitionTypeToDomainNameMap;
    }

    public static getDomainName(partitionType: Contract.AwsPartitionType) {
        return AwsConsoleHelper._partitionTypeToDomainNameMap[partitionType];
    }
}