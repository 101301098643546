import { InlineItems, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../controllers";

type InlinePackagesProps = {
    packageNames: string[];
    packageScope: Contract.WorkloadResourceOperatingSystemRiskPackageScope;
};

export function InlineWorkloadAnalysisPackages({ packageNames, packageScope }: InlinePackagesProps) {
    const localization =
        useLocalization(
            "common.inlineWorkloadAnalysisPackages",
            () => ({
                [Contract.TypeNames.WorkloadResourceOperatingSystemRiskPackageScope]: {
                    [Contract.WorkloadResourceOperatingSystemRiskPackageScope.All]: [
                        "1 software package",
                        "{{count | NumberFormatter.humanize}} software packages"
                    ],
                    [Contract.WorkloadResourceOperatingSystemRiskPackageScope.OperatingSystem]: [
                        "1 OS package",
                        "{{count | NumberFormatter.humanize}} OS packages"
                    ]
                }
            }));
    return (
        <InlineItems
            items={packageNames}
            namePluralizer={localization[Contract.TypeNames.WorkloadResourceOperatingSystemRiskPackageScope][packageScope]}
            variant="itemCountAndType"/>);
}