import { Link, Steps, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, useTheme } from "../../../../../../../../../../../../../../../../../../../../common";

export function WorkspaceItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useTerraformCloudRunTask.workspaceItem",
            () => ({
                description: "Follow these steps to associate the Run Task created in the previous step with a workspace:",
                note: {
                    link: "product documentation",
                    text: "Once the scan is triggered, you should start seeing scan results under **IaC**. By default, the Run Task will fail if an issue with severity “high” or above is detected. Please refer to the {{docsLink}} for additional information."
                },
                steps: {
                    step1: "Click **Workspaces**, and then navigate to the workspace you want to protect.",
                    step2: "Click **Settings**.",
                    step3: "Click **Run Tasks**.",
                    step4: "Under **Available Run Tasks**, click on the plus (**+**) sign next to the run task you created for Tenable Cloud Security to add it to the workspace.",
                    step5: {
                        step1: "Under **Run stage**, select **Post-plan**.",
                        step2: "Under **Enforcement Level**, select **Advisory** or **Mandatory**.",
                        step3: "Click on **Create.**",
                        title: "Associate the run task by following these steps:"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: theme.spacing(58) }}>
            <Typography>
                {localization.description()}
            </Typography>
            <Box>
                <Steps variant="letters">
                    {localization.steps.step1()}
                    {localization.steps.step2()}
                    {localization.steps.step3()}
                    {localization.steps.step4()}
                    <Stack>
                        <Typography>
                            {localization.steps.step5.title()}
                        </Typography>
                        <Box sx={{ paddingLeft: theme.spacing(1) }}>
                            <Steps variant="plainNumbers">
                                {localization.steps.step5.step1()}
                                {localization.steps.step5.step2()}
                                {localization.steps.step5.step3()}
                            </Steps>
                        </Box>
                    </Stack>
                </Steps>
                <Typography>
                    {localization.note.text({
                        docsLink:
                            <Link
                                sx={{ textDecorationLine: "underline" }}
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsConnectYourCICDPipelineAddTerraformCloudRunTaskRelativeUrl()}
                                variant="external">
                                {localization.note.link()}
                            </Link>
                    })}
                </Typography>
            </Box>
        </Stack>);
}