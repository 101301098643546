import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceDefinition, useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpComputeDiskStatusTranslator, useGcpComputeDiskTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpComputeDiskDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useGcpCommonEncryptedResourceDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const computeDiskStatusTranslator = useGcpComputeDiskStatusTranslator();
    const computeDiskTypeTranslator = useGcpComputeDiskTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeDiskDefinition",
            () => ({
                columns: {
                    instanceIdReferences: "VM Instances",
                    size: "Size",
                    sourceIdReference: "Source",
                    status: "Status",
                    type: "Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeDiskModel) => ({
                            [localization.columns.status()]: computeDiskStatusTranslator((item.entity as Contract.GcpComputeDisk).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeDiskStatus}
                                enumTypeTranslator={computeDiskStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeDiskStatus}
                itemProperty={(item: Contract.EntityModel) => computeDiskStatusTranslator((item.entity as Contract.GcpComputeDisk).status)}
                key={Contract.EntityModelProperty.GcpComputeDiskStatus}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeDiskModel) => ({
                            [localization.columns.type()]: computeDiskTypeTranslator((item.entity as Contract.GcpComputeDisk).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeDiskType}
                                enumTypeTranslator={computeDiskTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeDiskType}
                itemProperty={(item: Contract.EntityModel) => computeDiskTypeTranslator((item.entity as Contract.GcpComputeDisk).type)}
                key={Contract.EntityModelProperty.GcpComputeDiskType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeDiskModel) => ({
                            [localization.columns.sourceIdReference()]: item.sourceIdReference
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeDiskSource)}
                                placeholder={localization.columns.sourceIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeDiskSource}
                key={Contract.EntityModelProperty.GcpComputeDiskSource}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeDiskModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.sourceIdReference}
                            entityTypeName={Contract.TypeNames.GcpEntity}
                            entityVariant="iconText"/>}
                title={localization.columns.sourceIdReference()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeDiskModel) => ({
                            [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.GcpComputeDisk).size)
                        })
                }}
                id={Contract.EntityModelProperty.GcpComputeDiskSize}
                itemProperty={(item: Contract.GcpComputeDiskModel) => NumberFormatter.storage((item.entity as Contract.GcpComputeDisk).size)}
                key={Contract.EntityModelProperty.GcpComputeDiskSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeDiskModel>(
                        Contract.TypeNames.GcpComputeInstance,
                        item => item.instanceIdReferences,
                        localization.columns.instanceIdReferences())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeDiskInstances)}
                                placeholder={localization.columns.instanceIdReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeDiskInstances}
                key={Contract.EntityModelProperty.GcpComputeDiskInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeDiskModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIdReferences}
                            entityTypeName={Contract.TypeNames.GcpComputeInstance}
                            entityVariant="iconText"/>}
                title={localization.columns.instanceIdReferences()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionKeyColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeDiskRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeDiskRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeDiskInstances]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeDiskSource]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeDiskStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeDiskType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys]))));
}