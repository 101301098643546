import { Action1, ItemsSearchLayout, useLocalization } from "@infrastructure";
import React, { ReactNode } from "react";
import { Contract, useEntityTypeNameTranslator } from "../..";
import { List } from "./components/List";

type EntityListProps = {
    children?: (entityId: string) => ReactNode;
    entityIdOrModels: string[] | Contract.EntityModel[];
    entityTypeName: string;
    onSelectionChanged?: Action1<string>;
    selectedEntityId?: string;
    title?: string;
    variant?: "card" | "dialog";
};

export function EntityList({ children: renderEntityStats, entityIdOrModels, entityTypeName, onSelectionChanged, selectedEntityId, title, variant = "card" }: EntityListProps) {
    const localization =
        useLocalization(
            "common.entityList",
            () => ({
                actions: {
                    search: "Search {{translatedEntityTypeName}}"
                },
                title: "{{translatedEntityTypeName}} ({{entityCount | NumberFormatter.humanize}})"
            }));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <ItemsSearchLayout
            searchPlaceholder={
                localization.actions.search({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            { count: 0 })
                })}
            title={
                title ??
                localization.title({
                    entityCount: entityIdOrModels.length,
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            { count: 0 })
                })}
            variant={variant}>
            {searchText =>
                <List
                    entityIdsOrModels={entityIdOrModels}
                    entityTypeName={entityTypeName!}
                    renderEntityStats={renderEntityStats}
                    searchText={searchText}
                    selectedEntityId={selectedEntityId}
                    onSelectionChanged={onSelectionChanged}/>}
        </ItemsSearchLayout>);
}