import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../themes";

export function GitHubSecurityAdvisoriesIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M22 12.2723C22 6.599 17.5229 2 12 2C6.4771 2 2 6.599 2 12.2723C2 16.7756 4.82072 20.6013 8.74583 21.9883C8.98509 21.8108 9.26108 21.592 9.26108 21.2245C9.26108 20.6593 9.24883 19.2058 9.24883 19.2058C8.89983 19.2583 8.54744 19.2834 8.19473 19.2809C6.63369 19.2809 6.139 18.2636 5.905 17.6939C5.59883 16.9522 5.20518 16.6039 4.78179 16.3254C4.52329 16.1546 4.46381 15.9529 4.76298 15.8954C6.14469 15.6294 6.49766 17.4958 7.42055 17.7937C8.07925 18.0058 8.92604 17.9141 9.34724 17.6355C9.40279 17.0658 9.80519 16.5725 10.1402 16.3128C7.7932 16.0828 6.40187 15.2498 5.67887 13.9113L5.60145 13.7612L5.42037 13.3362L5.36657 13.1839C5.14088 12.5046 5.04072 11.7264 5.04072 10.857C5.04072 9.3141 5.51135 8.73181 6.14338 7.90915C5.66006 6.15015 6.31833 4.94784 6.31833 4.94784C6.31833 4.94784 7.33438 4.73262 9.25452 6.13757C10.2951 5.68199 13.0703 5.64335 14.3833 6.03603C15.1886 5.49194 16.6626 4.72004 17.2574 4.93615C17.4184 5.20079 17.7652 5.97313 17.4682 7.66878C17.6699 8.0408 18.7192 8.83336 18.7231 11.0731C18.7065 11.8994 18.6221 12.5971 18.4659 13.187L18.3784 13.4885C18.3784 13.4885 18.329 13.6309 18.2748 13.7666L18.2109 13.9163C17.5146 15.4785 16.0865 16.0616 13.7789 16.3043C14.5268 16.785 14.7411 17.3884 14.7411 19.0212C14.7411 20.6539 14.7201 20.8732 14.7249 21.2488C14.7293 21.5808 14.9874 21.8194 15.22 22C19.164 20.6225 22 16.7877 22 12.2723Z"
                fill={theme.palette.text.primary}/>
        </SvgIcon>);
}