import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell } from "../../../common";
import { useOciLoadBalancingLoadBalancerSslProtocolTranslator } from "../../../views/Customer/components/Entities/hooks";

type OciLoadBalancingLoadBalancerBackendsTableProps = {
    backendIpAddressToInstanceIdMap: Dictionary<string>;
    backendSets: Contract.OciLoadBalancingLoadBalancerBackendSet[];
};

export function OciLoadBalancingLoadBalancerBackendsTable({ backendIpAddressToInstanceIdMap, backendSets }: OciLoadBalancingLoadBalancerBackendsTableProps) {
    const sslProtocolTranslator = useOciLoadBalancingLoadBalancerSslProtocolTranslator();
    const localization =
        useLocalization(
            "tenants.oci.ociLoadBalancingLoadBalancerBackendsTable",
            () => ({
                columns: {
                    backendSetName: "Backend Set Name",
                    cipherSuiteName: "Cipher Suite",
                    ipAddress: "IP Address",
                    name: "Name",
                    port: {
                        any: "Any",
                        title: "Port"
                    },
                    sslProtocols: "SSL Protocols"
                },
                empty: "No Backends"
            }));

    const items =
        useMemo(
            () =>
                _.flatMap(
                    backendSets,
                    backendSet =>
                        _.map(
                            backendSet.backends,
                            backend =>
                                new OciLoadBalancingLoadBalancerBackendsTableItem(
                                    backendSet.name,
                                    backendSet.sslConfiguration?.cipherSuiteName,
                                    backendIpAddressToInstanceIdMap[backend.ipAddress],
                                    backend.ipAddress,
                                    backend.port,
                                    backendSet.sslConfiguration?.sslProtocols))),
            [backendSets]);

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => items}
            getItemId={(item: OciLoadBalancingLoadBalancerBackendsTableItem) => item.ipAddress}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerBackendsTableColumnId.Name}
                render={
                    ({ item }: DataTableColumnRenderProps<OciLoadBalancingLoadBalancerBackendsTableItem>) =>
                        _.isNil(item.instanceId)
                            ? <Typography noWrap={true}> {item.ipAddress} </Typography>
                            : <EntitiesCell
                                entityIdsOrModels={item.instanceId}
                                entityTypeName={Contract.TypeNames.OciComputeInstance}
                                entityVariant="iconText"/>}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerBackendsTableColumnId.IpAddress}
                itemProperty={(item: OciLoadBalancingLoadBalancerBackendsTableItem) => item.ipAddress}
                title={localization.columns.ipAddress()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerBackendsTableColumnId.Port}
                itemProperty={(item: OciLoadBalancingLoadBalancerBackendsTableItem) =>
                    item.port == 0
                        ? localization.columns.port.any()
                        : item.port}
                title={localization.columns.port.title()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerBackendsTableColumnId.BackendSetName}
                itemProperty={(item: OciLoadBalancingLoadBalancerBackendsTableItem) => item.backendSetName}
                title={localization.columns.backendSetName()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerBackendsTableColumnId.SslProtocols}
                render={
                    optionalTableCell<OciLoadBalancingLoadBalancerBackendsTableItem>(
                        (item: OciLoadBalancingLoadBalancerBackendsTableItem) =>
                            _.isNil(item.sslProtocols)
                                ? undefined
                                : <InlineItems
                                    items={
                                        item.sslProtocols.
                                            map(sslProtocolTranslator).
                                            sort()}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.sslProtocols()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerBackendsTableColumnId.CipherSuiteName}
                render={
                    optionalTableCell<OciLoadBalancingLoadBalancerBackendsTableItem>(
                        (item: OciLoadBalancingLoadBalancerBackendsTableItem) =>
                            _.isNil(item.cipherSuiteName)
                                ? undefined
                                : item.cipherSuiteName)}
                title={localization.columns.cipherSuiteName()}/>
        </DataTable>);
}

class OciLoadBalancingLoadBalancerBackendsTableItem {
    constructor(
        public backendSetName: string,
        public cipherSuiteName: Optional<string>,
        public instanceId: Optional<string>,
        public ipAddress: string,
        public port: number,
        public sslProtocols: Optional<Contract.OciLoadBalancingLoadBalancerSslProtocol[]>) {
    }
}

enum OciLoadBalancingLoadBalancerBackendsTableColumnId {
    BackendSetName = "backendSetName",
    CipherSuiteName = "cipherSuiteName",
    IpAddress = "ipAddress",
    Name = "name",
    Port = "port",
    SslProtocols = "sslProtocols"
}