﻿import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { AzureConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";

export function useAzureEntityDefinition(entityModel: Contract.EntityModel, page: Contract.AzureConsoleEntityPage) {
    const tenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);
    const aadTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.AzureTenantConfiguration).aadTenantId);
    const aadTenantConfiguration = aadTenantModel.configuration as Contract.AadTenantConfiguration;

    const getUrl =
        map(
            page,
            {
                [Contract.AzureConsoleEntityPage.AccessControl]: () => AzureConsoleUrlBuilder.GetEntityAccessControlUrl,
                [Contract.AzureConsoleEntityPage.AccessKeys]: () => AzureConsoleUrlBuilder.GetEntityAccessKeysUrl,
                [Contract.AzureConsoleEntityPage.ActiveDirectoryAdmin]: () => AzureConsoleUrlBuilder.GetEntityActiveDirectoryAdminUrl,
                [Contract.AzureConsoleEntityPage.ActiveDirectoryAdmins]: () => AzureConsoleUrlBuilder.GetEntityActiveDirectoryAdminsUrl,
                [Contract.AzureConsoleEntityPage.ActivityLog]: () => AzureConsoleUrlBuilder.GetResourceActivityLogUrl,
                [Contract.AzureConsoleEntityPage.Authentication]: () => AzureConsoleUrlBuilder.GetEntityAuthenticationUrl,
                [Contract.AzureConsoleEntityPage.Certificates]: () => AzureConsoleUrlBuilder.GetEntityCertificatesUrl,
                [Contract.AzureConsoleEntityPage.CertificatesReact]: () => AzureConsoleUrlBuilder.GetEntityCertificatesReactUrl,
                [Contract.AzureConsoleEntityPage.ConnectionSecurity]: () => AzureConsoleUrlBuilder.GetEntityConnectionSecurityUrl,
                [Contract.AzureConsoleEntityPage.DataEncryption]: () => AzureConsoleUrlBuilder.GetEntityDataEncryptionUrl,
                [Contract.AzureConsoleEntityPage.Diagnostics]: () => AzureConsoleUrlBuilder.GetEntityDiagnosticsUrl,
                [Contract.AzureConsoleEntityPage.Disks]: () => AzureConsoleUrlBuilder.GetEntityDisksUrl,
                [Contract.AzureConsoleEntityPage.Firewall]: () => AzureConsoleUrlBuilder.GetEntityFirewallUrl,
                [Contract.AzureConsoleEntityPage.InboundRules]: () => AzureConsoleUrlBuilder.GetEntityInboundRulesUrl,
                [Contract.AzureConsoleEntityPage.Network]: () => AzureConsoleUrlBuilder.GetEntityNetworkUrl,
                [Contract.AzureConsoleEntityPage.Networking]: () => AzureConsoleUrlBuilder.GetEntityNetworkingUrl,
                [Contract.AzureConsoleEntityPage.NetworkingHub]: () => AzureConsoleUrlBuilder.GetEntityNetworkingHubUrl,
                [Contract.AzureConsoleEntityPage.NetworkSettings]: () => AzureConsoleUrlBuilder.GetEntityNetworkSettingsUrl,
                [Contract.AzureConsoleEntityPage.Overview]: () => AzureConsoleUrlBuilder.GetEntityOverviewUrl,
                [Contract.AzureConsoleEntityPage.RoleAssignments]: () => AzureConsoleUrlBuilder.GetEntityRoleAssignmentsUrl,
                [Contract.AzureConsoleEntityPage.ServerAuditing]: () => AzureConsoleUrlBuilder.GetEntityServerAuditingUrl,
                [Contract.AzureConsoleEntityPage.ServerConfigurations]: () => AzureConsoleUrlBuilder.GetEntityServerConfigurationsUrl,
                [Contract.AzureConsoleEntityPage.ServerTransparentDataEncryption]: () => AzureConsoleUrlBuilder.GetServerTransportDataEncryptionUrl,
                [Contract.AzureConsoleEntityPage.VirtualNetworkSubnets]: () => AzureConsoleUrlBuilder.GetEntityVirtualNetworkSubnetsUrl
            });

    return {
        url:
            getUrl(
                aadTenantConfiguration.primaryDomainName,
                aadTenantConfiguration.partitionType,
                (entityModel.entity as Contract.AzureScopeResource).path!)
    };
}