import { Stack } from "@mui/material";
import React, { ReactNode } from "react";
import { RiskPoliciesType, useTheme } from "../../../../../../../../common";
import { useRiskPoliciesItemConfiguration } from "../../../../hooks";
import { useConfigurationContext } from "../../Configuration";
import { EnabledRadioGroup, InheritToggle } from "./components";

type GeneralProps = {
    additionalConfigurationElements: ReactNode[];
    riskPoliciesType: RiskPoliciesType;
};

export function General({ additionalConfigurationElements, riskPoliciesType }: GeneralProps) {
    const { item, scopeId } = useConfigurationContext();
    const { generalInheritToggleEnabled, inheritDisabled } = useRiskPoliciesItemConfiguration(riskPoliciesType, item, scopeId);

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                overflow: "auto",
                padding: theme.spacing(2, 2, 2, 3)
            }}>
            {!inheritDisabled &&
                generalInheritToggleEnabled &&
                <InheritToggle/>}
            <EnabledRadioGroup riskPoliciesType={riskPoliciesType}/>
            {...additionalConfigurationElements}
        </Stack>);
}