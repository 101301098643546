import { useLocalization } from "@infrastructure";
import { Stack, Switch, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AdministrationController, Contract, tenantModelStore, useScopeNavigationViewContext } from "../../../../../common";

export function TenantConfiguration() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const tenantModel = tenantModelStore.useGet(scopeNodeModel.configuration.id);
    const [orchestrationTypeToEnabledMap, setOrchestrationTypeToEnabledMap] = useState(tenantModel.configuration.orchestrationTypeToEnabledMap);

    async function toggleTenantEnabled(orchestrationType: Contract.OrchestrationType) {
        const { tenantModel: updatedTenantModel } =
            await AdministrationController.configureTenant(
                new Contract.AdministrationControllerConfigureTenantRequest(
                    !orchestrationTypeToEnabledMap[orchestrationType],
                    tenantModel.configuration.id,
                    orchestrationType));
        setOrchestrationTypeToEnabledMap(updatedTenantModel.configuration.orchestrationTypeToEnabledMap);
        tenantModelStore.notify(updatedTenantModel);
    }

    const localization =
        useLocalization(
            "views.customer.administration.tenantConfiguration",
            () => ({
                enabled: "{{orchestrationType}} Enabled"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="start"
            spacing={3}
            sx={{
                padding: theme.spacing(2),
                width: theme.spacing(60)
            }}>
            {_.map(
                orchestrationTypeToEnabledMap,
                (enabled, orchestrationType) =>
                    <Stack
                        alignItems="center"
                        direction="row"
                        key={orchestrationType}
                        sx={{ width: "100%" }}>
                        <Typography
                            sx={{
                                flex: 1,
                                fontWeight: 500
                            }}
                            variant="h4">
                            {localization.enabled({ orchestrationType })}
                        </Typography>
                        <Switch
                            checked={enabled}
                            color="secondary"
                            size="small"
                            onClick={_ => toggleTenantEnabled(orchestrationType as Contract.OrchestrationType)}/>
                    </Stack>
            )}
        </Stack>);
}