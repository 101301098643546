﻿import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, RiskController } from "../../../../../../../controllers";
import { SummaryItem, SummaryItemItem } from "../../../../../components";
import { useRiskResolutionAutomationContext } from "../../../../../utilities";
import { GcpRiskResolutionAutomationContext } from "../useGcpDefinition";

export function GcpSummaryItem() {
    const {
        changeModels,
        gcpAccessToken,
        isChangeResolvable,
        someChangeableSomeNotChangeable,
        storedGcpAccessToken,
        updatedChangeMap
    } = useRiskResolutionAutomationContext() as GcpRiskResolutionAutomationContext;

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useGcpDefinition.gcpSummaryItem",
            () => ({
                confirm: "Are you sure you want to continue and apply these changes in your GCP environment?",
                updateStatus: "Update models"
            }));

    const items =
        useMemo(
            () =>
                _(changeModels).
                    filter(changeModel => isChangeResolvable(changeModel)).
                    filter(changeModel =>
                        someChangeableSomeNotChangeable &&
                        _.isNil(gcpAccessToken) &&
                        !storedGcpAccessToken
                            ? changeModel.changeable
                            : true).
                    map(
                        changeModel =>
                            new GcpSummaryItemItem(
                                updatedChangeMap[changeModel.change.id] ?? changeModel.change,
                                changeModel.changeDatas,
                                gcpAccessToken,
                                storedGcpAccessToken) as SummaryItemItem).
                    value(),
            []);

    return (
        <SummaryItem
            confirm={localization.confirm()}
            items={items}/>);
}

export class GcpSummaryItemItem extends SummaryItemItem {
    constructor(
        change: Contract.Change,
        changeDatas: Contract.ChangeModelChangeData[],
        private gcpAccessToken: Optional<string>,
        private storedGcpAccessToken: boolean) {
        super(
            change,
            changeDatas);
    }

    protected async executeCore(): Promise<boolean> {
        this.resolveRiskAutomationResponse =
            await RiskController.resolveRiskAutomation(
                new Contract.RiskControllerResolveGcpRiskAutomationRequest(
                    this.change,
                    this.riskId,
                    this.gcpAccessToken,
                    this.storedGcpAccessToken));
        return this.resolveRiskAutomationResponse.success;
    }
}