import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../../../../../../../common";

export function useJiraInstanceStateProjectIssueTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.list.instance.hooks.useJiraInstanceStateProjectIssueTranslator",
            () => ({
                success: "Connected",
                [Contract.TypeNames.JiraInstanceStateProjectIssue]: {
                    [Contract.JiraInstanceStateProjectIssue.InvalidConfiguration]: "Misconfigured",
                    [Contract.JiraInstanceStateProjectIssue.InvalidId]: "Project not found",
                    [Contract.JiraInstanceStateProjectIssue.MissingPermissions]: "Missing permissions",
                    [Contract.JiraInstanceStateProjectIssue.MissingProductPermissions]: "Missing product permissions",
                    [Contract.JiraInstanceStateProjectIssue.UnsupportedProduct]: "Unsupported project product",
                    [Contract.JiraInstanceStateProjectIssue.UnsupportedProject]: "Unsupported project type"
                }
            }));
    return (issue: Optional<Contract.JiraInstanceStateProjectIssue>) =>
        _.isNil(issue)
            ? localization.success()
            : issue === Contract.JiraInstanceStateProjectIssue.InstanceError
                ? undefined
                : localization[Contract.TypeNames.JiraInstanceStateProjectIssue][issue]();
}