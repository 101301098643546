﻿import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, AwsEc2DeviceTable, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { LaunchConfigurationContextSection } from "./components";

export function useAwsEc2SnapshotEncryptionDisabledRiskLaunchConfigurationDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2SnapshotEncryptionDisabledRisk;
    const riskData = risk.data as Contract.AwsEc2SnapshotEncryptionDisabledRiskAutoScalingLaunchConfigurationData;
    const instanceModels = entityModelStore.useGet(riskData.instanceIds);
    const launchConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsAutoScalingLaunchConfigurationModel;
    const launchConfiguration = launchConfigurationModel.entity as Contract.AwsAutoScalingLaunchConfiguration;
    const snapshotModels = entityModelStore.useGet(risk.aggregatedEntityIds);
    const volumeModels = entityModelStore.useGet(riskData.volumeIds);

    const unencryptedVolumeModels =
        _.filter(
            volumeModels,
            volumeModel => _.isEmpty((volumeModel as Contract.AwsEc2VolumeModel).kmsEncryptionKeyIdReferences));

    const deviceNameToVolumeModelsMap =
        useMemo(
            () => {
                const instanceRawIds =
                    _.map(
                        instanceModels,
                        instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).rawId);

                return _(launchConfiguration.devices).
                    keyBy(device => device.name).
                    mapValues(
                        device =>
                            _.filter(
                                volumeModels,
                                volumeModel => {
                                    const volume = volumeModel.entity as Contract.AwsEc2Volume;
                                    return volume.sourceSnapshotRawId === device.snapshotRawId &&
                                        !_.isEmpty(
                                            _(instanceRawIds).
                                                intersection(_.keys(volume.instanceRawIdToDeviceNameMap)).
                                                filter(instanceRawId => volume.instanceRawIdToDeviceNameMap[instanceRawId] === device.name).
                                                value());
                                })).
                    value();
            },
            [launchConfiguration, volumeModels]);
    const deviceNameToVolumeSnapshotModelsMap =
        _(deviceNameToVolumeModelsMap).
            mapValues(
                volumeModels => {
                    const volumeIds =
                        _.map(
                            volumeModels,
                            volumeModel => volumeModel.id);
                    return _.filter(
                        snapshotModels!,
                        snapshotModel =>
                            !snapshotModel.unknown &&
                            volumeIds.includes((snapshotModel.entity as Contract.AwsEc2Snapshot).sourceVolumeId!));
                }).
            value();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotEncryptionDisabledRiskDefinition.hooks.useAwsEc2SnapshotEncryptionDisabledRiskLaunchConfigurationDefinition",
            () => ({
                description: {
                    none: "{{launchConfiguration}} has unencrypted device mapping configuration",
                    snapshot: "{{launchConfiguration}} was used to launch {{autoScalingGroupIds}} with {{volumeIds}} with {{snapshotIds}}"
                },
                deviceTable: {
                    snapshots: "Snapshots",
                    volumes: "Volumes"
                },
                sections: {
                    resolution: {
                        step1: "Select the launch configuration and choose **Actions, Copy launch configuration**.",
                        step2: "Replace the existing image with an image that contains encrypted block device mappings",
                        step3: "Click **Create launch configuration**",
                        step4: "Before replacing the launch configuration, verify that all identities used to launch the instances have decrypt permissions",
                        step5: "Update auto scaling groups to use the new secured launch configuration",
                        step6: "Click on **Delete launch configuration** to delete the unsecured version",
                        step7: "Wait until all unencrypted instances are relaunched with the encrypted launch configuration version",
                        step8: "For each unencrypted snapshot, select it and click **Actions** on top and then **Delete**"
                    },
                    title: "Launch Configuration"
                },
                snapshots: [
                    "1 unencrypted snapshot",
                    "{{count | NumberFormatter.humanize}} unencrypted snapshots"
                ],
                volumes: [
                    "1 unencrypted volume",
                    "{{count | NumberFormatter.humanize}} unencrypted volumes"
                ]
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.none
            : localization.description.snapshot)({
            autoScalingGroupIds:
                <InlineEntities
                    entityIdsOrModels={riskData.autoScalingGroupIds}
                    entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                    variant="itemCountAndType"/>,
            launchConfiguration:
                <Entity
                    entityIdOrModel={launchConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            snapshotIds:
                <InlineEntities
                    entityIdsOrModels={risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                    namePluralizer={localization.snapshots}
                    variant="itemCountAndType"/>,
            volumeIds:
                _.isEmpty(unencryptedVolumeModels)
                    ? <InlineEntities
                        entityIdsOrModels={volumeModels!}
                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                        variant="itemCountAndType"/>
                    : <InlineEntities
                        entityIdsOrModels={unencryptedVolumeModels}
                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                        namePluralizer={localization.volumes}
                        variant="itemCountAndType"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getAutoScalingGroupLaunchConfigurationUrl(launchConfiguration)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <LaunchConfigurationContextSection
                    launchConfigurationModel={launchConfigurationModel}
                    risk={risk}/>,
            sections: [
                new RiskDefinitionSection(
                    _.isEmpty(risk.aggregatedEntityIds)
                        ? <AwsEc2DeviceTable
                            devices={
                                _.filter(
                                    launchConfiguration.devices,
                                    device => device.encryptionEnabled === false)}
                            snapshotRawIdToIdMap={launchConfigurationModel.snapshotRawIdToIdMap}/>
                        : <AwsEc2DeviceTable
                            additionalColumns={[
                                <DataTableColumn
                                    id={localization.deviceTable.volumes()}
                                    key={localization.deviceTable.volumes()}
                                    render={
                                        optionalTableCell<Contract.AwsEc2Device>(
                                            item =>
                                                _.isEmpty(deviceNameToVolumeModelsMap[item.name])
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={deviceNameToVolumeModelsMap[item.name]}
                                                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                                        variant="itemOrItemCountAndType"/>)}
                                    title={localization.deviceTable.volumes()}/>,
                                <DataTableColumn
                                    id={localization.deviceTable.snapshots()}
                                    key={localization.deviceTable.snapshots()}
                                    render={
                                        optionalTableCell<Contract.AwsEc2Device>(
                                            item =>
                                                _.isEmpty(deviceNameToVolumeSnapshotModelsMap[item.name])
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={deviceNameToVolumeSnapshotModelsMap[item.name]}
                                                        entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                                        variant="itemOrItemCountAndType"/>)}
                                    title={localization.deviceTable.snapshots()}/>
                            ]}
                            devices={launchConfiguration.devices}/>,
                    localization.sections.title())
            ]
        });
}