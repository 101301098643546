import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function FivetranIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2082)">
                <circle
                    cx="12"
                    cy="12"
                    fill="#0073FF"
                    r="12"/>
                <path
                    d="M13.9167 12.2945H15.3125C15.4375 12.2945 15.5208 12.2074 15.5208 12.0768C15.5208 12.055 15.5208 12.0114 15.5 11.9897L13.1042 5.13065C13.0625 5.06532 13 5 12.9167 5H11.5208C11.3958 5 11.3125 5.0871 11.3125 5.21775C11.3125 5.23952 11.3125 5.2613 11.3333 5.28307L13.7292 12.1421C13.75 12.2292 13.8333 12.2945 13.9167 12.2945Z"
                    fill="white"/>
                <path
                    d="M13.6667 19.5455H15.0625C15.1875 19.5455 15.2708 19.4584 15.2708 19.3277C15.2708 19.3059 15.2708 19.2842 15.25 19.2624L10.3125 5.15242C10.2708 5.06532 10.1875 5 10.1042 5H8.70833C8.58333 5 8.5 5.0871 8.5 5.21775C8.5 5.23952 8.5 5.2613 8.52083 5.28307L13.4792 19.393C13.5 19.5019 13.5833 19.5455 13.6667 19.5455Z"
                    fill="white"/>
                <path
                    d="M10.9167 19.5455H12.3125C12.4167 19.5455 12.5208 19.4584 12.5208 19.3495C12.5208 19.3277 12.5208 19.2842 12.5 19.2624L10.0833 12.4251C10.0625 12.3381 9.97917 12.2945 9.89583 12.2945H8.5C8.39583 12.2945 8.3125 12.4034 8.3125 12.5122C8.3125 12.534 8.3125 12.5558 8.33333 12.5776L10.7292 19.4366C10.75 19.5019 10.8333 19.5455 10.9167 19.5455Z"
                    fill="white"/>
                <path
                    d="M14.0833 5.30484L15.2083 8.50572C15.2292 8.59281 15.3125 8.63636 15.3958 8.63636H16.7917C16.9167 8.63636 17 8.54927 17 8.41862C17 8.39684 17 8.37507 16.9792 8.35329L15.8542 5.15242C15.8333 5.06532 15.75 5 15.6667 5H14.2708C14.1458 5 14.0625 5.0871 14.0625 5.21775C14.0625 5.23952 14.0625 5.28307 14.0833 5.30484Z"
                    fill="white"/>
                <path
                    d="M8.33333 19.5455H9.72917C9.85417 19.5455 9.9375 19.4584 9.9375 19.3277C9.9375 19.3059 9.9375 19.2842 9.91667 19.2624L8.79167 16.0397C8.77083 15.9526 8.6875 15.9091 8.60417 15.9091H7.20833C7.08333 15.9091 7 15.9962 7 16.1268C7 16.1486 7 16.1704 7.02083 16.1922L8.14583 19.4148C8.16667 19.5019 8.25 19.5455 8.33333 19.5455Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2082">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}