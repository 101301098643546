import { Optional, useDirectedGraphNodeContext, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { MutableRefObject } from "react";
import { Contract, Entity, useEntityNodeContentSubtitleTranslator, useEntityTypeNameTranslator } from "../../../../../../..";
import { AccessDefinitionToolbarActions } from "../../..";
import { entityModelStore } from "../../../../../../../stores";
import { PermitterDefinitionNodeContent } from "../../../components";

type PermissionPathNodeContentProps = {
    permissionPath: Contract.GcpAccessGraphPermissionPath;
    toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>;
};

export function PermissionPathNodeContent({ permissionPath, toolbarActionsRef }: PermissionPathNodeContentProps) {
    const { modelNode } = useDirectedGraphNodeContext();
    const roleModel = entityModelStore.useGet(permissionPath.identifier.roleId);
    const scopeResourceModel = entityModelStore.useGet(permissionPath.identifier.scopeResourceId);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const entityNodeSubtitleTranslator = useEntityNodeContentSubtitleTranslator();
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useGcpDefinition.permissionPathNodeContent",
            () => ({
                title: "{{role}} | {{scopeResourceDisplayName}} ({{scopeResourceTypeName}})"
            }));

    return (
        <PermitterDefinitionNodeContent
            icon={
                <Entity
                    blurred={modelNode.options.appearance === "normal"}
                    entityIdOrModel={roleModel}
                    glanceOptions={{ disabled: true }}
                    linkOptions={{ disabled: true }}
                    variant="icon"/>}
            options={
                _.isNil(scopeResourceModel)
                    ? undefined
                    : {
                        subtitle: entityNodeSubtitleTranslator(roleModel),
                        title:
                            localization.title({
                                role:
                                    <Entity
                                        entityIdOrModel={roleModel}
                                        linkOptions={{
                                            disabled: true,
                                            disabledHighlight: false
                                        }}
                                        variant="text"/>,
                                scopeResourceDisplayName: scopeResourceModel.entity.displayName,
                                scopeResourceTypeName: entityTypeNameTranslator(scopeResourceModel.entity.typeName)
                            })
                    }}
            permitterEntityId={permissionPath.identifier.roleId}
            toolbarActionsRef={toolbarActionsRef}
            variant="large"
            onViewText={entityModel => (entityModel.entity as Contract.GcpIamRole).rawRole}/>);
}