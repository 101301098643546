﻿import { Link, useLocalization } from "@infrastructure";
import React, { Fragment } from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useDefinition } from "./hooks";

type EntityExternalConsoleLinkProps = {
    entityId: string;
    page: Contract.GcpConsolePage;
};

export function EntityExternalConsoleLink({ entityId, page }: EntityExternalConsoleLinkProps) {
    const entityModel = entityModelStore.useGet(entityId);
    const definition = useDefinition(entityModel, page);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.entityExternalConsoleLink",
            () => ({
                consolePage: {
                    linkText: "{{name}} page",
                    name: {
                        [Contract.GcpConsolePage.ApiKeys]: "API Keys",
                        [Contract.GcpConsolePage.ApiLibrary]: "API Library",
                        [Contract.GcpConsolePage.AuditLogs]: "Audit Logs",
                        [Contract.GcpConsolePage.Backups]: "Backups",
                        [Contract.GcpConsolePage.Connections]: "Connections",
                        [Contract.GcpConsolePage.Edit]: "Edit",
                        [Contract.GcpConsolePage.Firewalls]: "Firewalls",
                        [Contract.GcpConsolePage.Metadata]: "Metadata",
                        [Contract.GcpConsolePage.Keys]: "Keys",
                        [Contract.GcpConsolePage.LogMetrics]: "Log-based Metrics",
                        [Contract.GcpConsolePage.Permissions]: "Permissions",
                        [Contract.GcpConsolePage.Resource]: "resource",
                        [Contract.GcpConsolePage.Revisions]: "Revisions",
                        [Contract.GcpConsolePage.Security]: "Security",
                        [Contract.GcpConsolePage.Versions]: "Versions",
                        [Contract.GcpConsolePage.Variables]: "Variables",
                        [Contract.GcpConsolePage.VpcFirewallRules]: "Firewalls",
                        [Contract.GcpConsolePage.VpcSubnets]: "Subnets"
                    }
                },
                description: "In GCP Portal, open the {{entityExternalConsoleLink}} of {{translatedEntityTypeName}} {{entityId}}"
            }));
    return (
        <Fragment>
            {localization.description({
                entityExternalConsoleLink:
                    <Link
                        urlOrGetUrl={definition.url}
                        variant="external">
                        {localization.consolePage.linkText({ name: localization.consolePage.name[page]() })}
                    </Link>,
                entityId:
                    <Entity
                        entityIdOrModel={entityModel.entity.id}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="text"/>,
                translatedEntityTypeName:
                    entityTypeNameTranslator(
                        entityModel.entity.typeName,
                        { variant: "text" })
            })}
        </Fragment>);
}