﻿import { ApiError, DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSearchOptions, Optional, StringHelper, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, RadioField, TenantIcon } from "../../../../../../../../../../../../../../../common";
import { useAzureDevOpsOrganizationManagerErrorTranslator } from "../hooks";

export function TenantsItem() {
    const { aadTenantRawId, oAuthAccessToken, organizationModel, tenantPaths, updatedFolderEnabled, updatedOrganizationName, updatedSyncEnabled } = useAddOrEditContext();

    const azureDevOpsOrganizationConfiguration = organizationModel?.configuration as Optional<Contract.AzureDevOpsOrganizationConfiguration>;
    const setAddOrEditContext = useSetAddOrEditContext();
    const { setLoaded, useNextEffect } = useOrderedWizardContext();

    useEffect(
        () => {
            setLoaded();
        },
        []);

    const azureDevOpsOrganizationManagerErrorTranslator = useAzureDevOpsOrganizationManagerErrorTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.azureDevOps.addOrEditAzureDevOps.tenantsItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            general: "Failed to add organization {{organizationName}}"
                        }
                    }
                },
                columns: {
                    name: "Name"
                },
                errors: {
                    emptyTenants: "Select at least one repository"
                },
                search: "Search",
                tenantManagement: {
                    disabled: "Choose specific repositories",
                    enabled: "Add all existing and future repositories (in permitted projects)"
                }
            }));

    const [selectedTenantPaths, setSelectedTenantPaths] = useState(azureDevOpsOrganizationConfiguration?.memberSelection?.rawIds ?? tenantPaths!);
    const [allTenants, setAllTenants] = useState(_.isEmpty(azureDevOpsOrganizationConfiguration?.memberSelection?.rawIds));

    useNextEffect(
        async () => {
            if (!allTenants && _.isEmpty(selectedTenantPaths)) {
                return localization.errors.emptyTenants();
            }

            if (_.isNil(azureDevOpsOrganizationConfiguration)) {
                try {
                    const { folderScopeNodeModel, organizationModel } =
                        await ConfigurationController.insertAzureDevOpsOrganization(
                            new Contract.ConfigurationControllerInsertAzureDevOpsOrganizationRequest(
                                aadTenantRawId!,
                                oAuthAccessToken!,
                                updatedFolderEnabled!,
                                allTenants
                                    ? undefined
                                    : {
                                        rawIds: selectedTenantPaths,
                                        type: Contract.OrganizationMemberSelectionType.Include
                                    },
                                updatedOrganizationName!,
                                updatedSyncEnabled!));
                    setAddOrEditContext(
                        context => ({
                            ...context,
                            organizationModel,
                            updatedFolderScopeNodeModel: folderScopeNodeModel
                        }));
                } catch (error) {
                    return error instanceof ApiError && error.statusCode === 400
                        ? azureDevOpsOrganizationManagerErrorTranslator(error.error as Contract.AzureDevOpsOrganizationManagerError)
                        : localization.actions.save.error.general({ organizationName: updatedOrganizationName });
                }
            } else {
                const { organizationModel } =
                    await ConfigurationController.updateAzureDevOpsOrganization(
                        new Contract.ConfigurationControllerUpdateAzureDevOpsOrganizationRequest(
                            updatedFolderEnabled!,
                            allTenants
                                ? undefined
                                : {
                                    rawIds: selectedTenantPaths,
                                    type: Contract.OrganizationMemberSelectionType.Include
                                },
                            azureDevOpsOrganizationConfiguration.id,
                            updatedSyncEnabled!));
                setAddOrEditContext(
                    context => ({
                        ...context,
                        organizationModel
                    }));
            }
        },
        [selectedTenantPaths, allTenants]);

    const searchFilterId = "name";
    const dataTableActionsRef = useRef<DataTableActions>();
    useEffect(
        () => {
            dataTableActionsRef.current!.setSelectedItemIds(selectedTenantPaths);
        },
        []);

    return (
        <Stack spacing={2}>
            <RadioField
                checked={allTenants}
                title={localization.tenantManagement.enabled()}
                onSelected={() => setAllTenants(true)}/>
            <RadioField
                checked={!allTenants}
                indent={false}
                title={localization.tenantManagement.disabled()}
                onSelected={() => setAllTenants(false)}>
                <DataTable
                    actionsRef={dataTableActionsRef}
                    fetchItems={
                        filterMap =>
                            _(tenantPaths).
                                filter(tenantPath => StringHelper.search(tenantPath, filterMap[searchFilterId])).
                                orderBy(tenantPath => StringHelper.getSortValue(tenantPath)).
                                value()}
                    getItemId={item => item}
                    searchOptions={
                        new DataTableSearchOptions(
                            searchFilterId,
                            localization.search())}
                    selectionOptions={{
                        onChanged: setSelectedTenantPaths,
                        persistence: true
                    }}
                    sortOptions={{ enabled: false }}>
                    <DataTableColumn
                        id="name"
                        render={
                            ({ item }: DataTableColumnRenderProps<string>) =>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <TenantIcon
                                        data={{ codeTenantType: Contract.CodeTenantType.AzureDevOps }}
                                        sx={{ fontSize: "18px" }}
                                        tenantType={Contract.TenantType.Code}/>
                                    <Typography>
                                        {item}
                                    </Typography>
                                </Stack>}
                        title={localization.columns.name()}/>
                </DataTable>
            </RadioField>
        </Stack>);
}