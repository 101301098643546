import { Filter, Filters, FiltersActions, GraphIcon, InboundIcon, ListIcon, OutboundIcon, TextValuesFilter, useActions, useChangeEffect, useLocalization, useSetRoute, ValuesFilter, ValuesFilterItem, ValuesFilterSelection } from "@infrastructure";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { AccessView, ActionRiskCategoryFilter, Contract, EntityAttributeFilter, EntityFilter, EntityTypeMetadataModelHelper, PermissionActionExcessivenessFilter, SeverityFilter, StorageHelper, TypeHelper, useEntityTypeNameTranslator, useTheme } from "../../../../../../..";
import { AccessDefinitionToolbarProps, ToolbarFilterId } from "../../..";
import { AccessLevelFilter } from "../../../../../../AccessLevelFilter";

export function Toolbar({ accessGraph, actionsRef, baseUrl, csvExportButtonElement, entityModel, graphDirection, onFiltersChanged, view }: AccessDefinitionToolbarProps) {
    const entityTypeMetadataModel = EntityTypeMetadataModelHelper.get(entityModel.entity.typeName);
    const [filterMap, setFilterMap] = useState<Dictionary<any>>({});
    useChangeEffect(
        () => onFiltersChanged({ ...filterMap }),
        [filterMap]);

    const { filterToValuesMap } =
        useMemo(
            () => ({
                accessVariant: accessGraph?.variant,
                filterToValuesMap: {
                    [ToolbarFilterId.DestinationEntityIds]:
                        _(accessGraph?.destinationEntityGroups).
                            flatMap(destinationEntityGroup => destinationEntityGroup.entityIds).
                            value(),
                    [ToolbarFilterId.DestinationResourceTenantIds]: accessGraph?.destinationResourceTenantIds,
                    [ToolbarFilterId.IdentityIds]:
                        _.flatMap(
                            accessGraph?.identityGroups,
                            identityGroup => identityGroup.identityIds),
                    [ToolbarFilterId.GroupIds]:
                        _(accessGraph?.groups).
                            map(group => group.groupId).
                            uniq().
                            value(),
                    [ToolbarFilterId.OriginatorEntityIds]:
                        _.flatMap(
                            accessGraph?.originatorEntityGroups,
                            originatorEntityGroup => originatorEntityGroup.originatorEntityIds),
                    [ToolbarFilterId.OriginatorEntityTypeNames]:
                        _(accessGraph?.originatorEntityGroups).
                            flatMap(originatorEntityGroup => originatorEntityGroup.entityTypeNames).
                            uniq().
                            value(),
                    [ToolbarFilterId.PermissionActions]: accessGraph?.permissionActions,
                    [ToolbarFilterId.PermitterIds]:
                        _(accessGraph?.permissionPaths).
                            map(permissionPath => (permissionPath as Contract.GcpAccessGraphPermissionPath).identifier.roleId).
                            uniq().
                            value()
                } as Dictionary<string[]>
            }),
            []);

    const filtersActionsRef = useRef<FiltersActions>();
    useActions(
        actionsRef,
        {
            filter:
                (filterId, type, values) => {
                    filtersActionsRef.current!.set(
                        filterId,
                        existingFilter =>
                            new ValuesFilterSelection(
                                type == "by"
                                    ? false
                                    : existingFilter?.emptyValue ?? false,
                                type === "by"
                                    ? values
                                    : _.difference(
                                        _.isEmpty(existingFilter)
                                            ? filterToValuesMap[filterId]
                                            : existingFilter.values,
                                        values)));
                }
        });

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useGcpDefinition.toolbar",
            () => ({
                filters: {
                    [GcpToolbarFilterId.PermissionActionExcessiveness]: "Usage",
                    [ToolbarFilterId.AccessLevels]: "Access Level",
                    [ToolbarFilterId.DestinationEntityIds]: "Resources",
                    [ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames]: "Resource Labels",
                    [ToolbarFilterId.DestinationResourceTenantIds]: "Projects",
                    [ToolbarFilterId.GroupIds]: "Groups",
                    [ToolbarFilterId.IdentityIds]: "Identities",
                    [ToolbarFilterId.OriginatorEntityIds]: "Originators",
                    [ToolbarFilterId.OriginatorEntityTypeNames]: "Originators Type",
                    [ToolbarFilterId.PermissionActionRiskCategories]: "Permission Categories",
                    [ToolbarFilterId.PermissionActions]: "Permissions",
                    [ToolbarFilterId.PermissionActionSeverity]: "Severity",
                    [ToolbarFilterId.PermitterIds]: "Roles"
                }
            }));

    const setRoute = useSetRoute();
    const theme = useTheme();
    return (
        <Stack
            alignItems="flex-start"
            direction="row"
            spacing={1.5}
            sx={{ paddingBottom: theme.spacing(1) }}>
            <Filters
                actionsRef={filtersActionsRef}
                filterQueryParameterName="filterMap"
                sx={{ flex: 1 }}
                visibilityStorageItem={StorageHelper.customerAccessToolbarFilters(entityTypeMetadataModel.tenantType, graphDirection)}
                onFilterChanged={setFilterMap}>
                <Filter
                    default={true}
                    id={GcpToolbarFilterId.PermissionActionExcessiveness}
                    title={localization.filters[GcpToolbarFilterId.PermissionActionExcessiveness]()}>
                    <PermissionActionExcessivenessFilter/>
                </Filter>
                {!_.isEmpty(filterToValuesMap[ToolbarFilterId.OriginatorEntityTypeNames]) &&
                    <Filter
                        id={ToolbarFilterId.OriginatorEntityTypeNames}
                        title={localization.filters[ToolbarFilterId.OriginatorEntityTypeNames]()}>
                        <ValuesFilter placeholder={localization.filters[ToolbarFilterId.OriginatorEntityTypeNames]()}>
                            {_.map(
                                filterToValuesMap[ToolbarFilterId.OriginatorEntityTypeNames],
                                originatorEntityTypeName =>
                                    <ValuesFilterItem
                                        key={originatorEntityTypeName}
                                        title={entityTypeNameTranslator(originatorEntityTypeName)}
                                        value={originatorEntityTypeName}/>)}
                        </ValuesFilter>
                    </Filter>}
                {_(
                    [
                        ToolbarFilterId.OriginatorEntityIds,
                        ToolbarFilterId.IdentityIds,
                        ToolbarFilterId.GroupIds,
                        ToolbarFilterId.PermitterIds
                    ]).
                    filter(filterId => !_.isEmpty(filterToValuesMap[filterId])).
                    map(
                        filterId =>
                            <Filter
                                id={filterId}
                                key={filterId}
                                title={localization.filters[filterId]()}>
                                <EntityFilter
                                    entityIdsOrSearchableReferences={filterToValuesMap[filterId]}
                                    placeholder={localization.filters[filterId]()}/>
                            </Filter>).
                    value()}
                <Filter
                    default={true}
                    id={ToolbarFilterId.AccessLevels}
                    key={ToolbarFilterId.AccessLevels}
                    title={localization.filters[ToolbarFilterId.AccessLevels]()}>
                    <AccessLevelFilter placeholder={localization.filters[ToolbarFilterId.AccessLevels]()}/>
                </Filter>
                {!_.isEmpty(filterToValuesMap[ToolbarFilterId.DestinationEntityIds]) && (
                    <Filter
                        default={true}
                        id={ToolbarFilterId.DestinationEntityIds}
                        key={ToolbarFilterId.DestinationEntityIds}
                        title={localization.filters[ToolbarFilterId.DestinationEntityIds]()}>
                        <EntityFilter
                            entityIdsOrSearchableReferences={filterToValuesMap[ToolbarFilterId.DestinationEntityIds]}
                            placeholder={localization.filters[ToolbarFilterId.DestinationEntityIds]()}/>
                    </Filter>)}
                {!_.isEmpty(filterToValuesMap[ToolbarFilterId.DestinationResourceTenantIds]) && (
                    <Filter
                        default={true}
                        id={ToolbarFilterId.DestinationResourceTenantIds}
                        key={ToolbarFilterId.DestinationResourceTenantIds}
                        title={localization.filters[ToolbarFilterId.DestinationResourceTenantIds]()}>
                        <EntityFilter
                            entityIdsOrSearchableReferences={filterToValuesMap[ToolbarFilterId.DestinationResourceTenantIds]}
                            placeholder={localization.filters[ToolbarFilterId.DestinationResourceTenantIds]()}/>
                    </Filter>)}
                <Filter
                    id={ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames}
                    title={localization.filters[ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames]()}>
                    <EntityAttributeFilter
                        emptyValue={true}
                        entityAttributeValues={TypeHelper.getSealedAssignableTypeNames(Contract.TypeNames.BuiltInEntityAttribute)}
                        placeholder={localization.filters[ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames]()}/>
                </Filter>
                <Filter
                    default={true}
                    id={ToolbarFilterId.PermissionActions}
                    title={localization.filters[ToolbarFilterId.PermissionActions]()}>
                    <TextValuesFilter
                        placeholder={localization.filters[ToolbarFilterId.PermissionActions]()}
                        values={filterToValuesMap[ToolbarFilterId.PermissionActions]}/>
                </Filter>
                <Filter
                    default={true}
                    id={ToolbarFilterId.PermissionActionSeverity}
                    title={localization.filters[ToolbarFilterId.PermissionActionSeverity]()}>
                    <SeverityFilter
                        emptyValueOptions={{ enabled: false }}
                        information={false}
                        placeholder={localization.filters[ToolbarFilterId.PermissionActionSeverity]()}/>
                </Filter>
                <Filter
                    default={true}
                    id={ToolbarFilterId.PermissionActionRiskCategories}
                    title={localization.filters[ToolbarFilterId.PermissionActionRiskCategories]()}>
                    <ActionRiskCategoryFilter placeholder={localization.filters[ToolbarFilterId.PermissionActionRiskCategories]()}/>
                </Filter>
            </Filters>
            {entityTypeMetadataModel.gcpInboundAccessGraph &&
                entityTypeMetadataModel.gcpOutboundAccessGraph &&
                <ToggleButtonGroup
                    exclusive={true}
                    value={graphDirection}
                    onChange={(event, direction) => !_.isNil(direction) && setRoute(`${baseUrl}/${view}/${direction}`)}>
                    <ToggleButton value={Contract.EntityAccessDirection.Outbound}>
                        <OutboundIcon/>
                    </ToggleButton>
                    <ToggleButton value={Contract.EntityAccessDirection.Inbound}>
                        <InboundIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>}
            <ToggleButtonGroup
                exclusive={true}
                value={view}
                onChange={
                    (event, view) =>
                        !_.isNil(view) &&
                        setRoute(
                            `${baseUrl}/${view}/${graphDirection}`,
                            undefined,
                            { preserveSearchString: true })}>
                <ToggleButton value={AccessView.Graph}>
                    <GraphIcon/>
                </ToggleButton>
                <ToggleButton value={AccessView.List}>
                    <ListIcon/>
                </ToggleButton>
            </ToggleButtonGroup>
            {!_.isNil(accessGraph) && csvExportButtonElement}
        </Stack>);
}

export enum GcpToolbarFilterId {
    PermissionActionExcessiveness = "permissionActionExcessiveness"
}