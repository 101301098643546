import { DeleteIcon, SubnetHelper, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Box, FormControl, FormHelperText, IconButton, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useTheme } from "../../../../../../../themes";
import { SubnetItem } from "../../../SourceIpAddressSelector";

type ItemProps = {
    disabled?: boolean;
    onChange: (subnetItem: SubnetItem) => void;
    onDelete?: () => void;
    subnetItem: SubnetItem;
};

export function Item({ disabled = false, onChange, onDelete, subnetItem }: ItemProps) {
    const [subnet, setSubnet] = useState(subnetItem.subnet);

    const localization =
        useLocalization(
            "common.customRiskPolicy.sourceIpAddressSelector.subnets.item",
            () => ({
                error: {
                    format: "Must be a valid IP address (CIDR)",
                    required: "IP address range cannot be empty"
                },
                placeholder: "Range"
            }));

    const [subnetValidationController, subnetValidationMessage] =
        useInputValidation(
            () => {
                if (_.isEmpty(subnet)) {
                    return localization.error.required();
                }

                if (!SubnetHelper.isValid(subnet!)) {
                    return localization.error.format();
                }

                return undefined;
            },
            [subnet]);

    useChangeEffect(
        () => {
            onChange(
                new SubnetItem(
                    subnetValidationController.isValid(),
                    subnet,
                    subnetItem.id));
        },
        [subnet]);

    const theme = useTheme();

    return (
        <Stack sx={{ width: "100%" }}>
            <Stack
                alignItems="center"
                direction="row">
                <Box sx={{ flex: 1 }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={disabled}
                            label={localization.placeholder()}
                            value={subnet ?? ""}
                            variant="outlined"
                            onChange={event => setSubnet(event.target.value)}/>
                    </FormControl>
                </Box>
                <Box sx={{ width: theme.spacing(4) }}>
                    {!_.isNil(onDelete) && (
                        <IconButton
                            size="large"
                            onClick={() => onDelete()}>
                            <DeleteIcon/>
                        </IconButton>)}
                </Box>
            </Stack>
            {(!_.isNil(subnetValidationMessage) && (
                <FormHelperText error={true}>{subnetValidationMessage}</FormHelperText>))}
        </Stack>);
}