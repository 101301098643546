import { DataTableColumnRenderProps, NoneIcon, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, usePermissionEligibilityUpdateAuditEventChangesTranslator } from "../../../../../../../../common";
import { AwsInlinePolicyDocumentDiff } from "./components";

export function DetailsCell({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityAuditEventModel>) {
    const permissionEligibilityAuditEvent = item.auditEvent as Contract.PermissionEligibilityAuditEvent;
    const localization =
        useLocalization(
            "views.user.auditEvents.permissionEligibilityAuditEvents.detailsCell",
            () => ({
                propertyChanges: "Updated: {{propertyChanges}}"
            }));
    const permissionEligibilityUpdateAuditEventChangesTranslator = usePermissionEligibilityUpdateAuditEventChangesTranslator();

    if (permissionEligibilityAuditEvent.typeName !== Contract.TypeNames.PermissionEligibilityUpdateAuditEvent) {
        return <NoneIcon sx={{ fontSize: "18px" }}/>;
    }

    const permissionEligibilityUpdateAuditEvent = permissionEligibilityAuditEvent as Contract.PermissionEligibilityUpdateAuditEvent;

    let awsInlinePolicyDocumentChanged = false;
    if (permissionEligibilityUpdateAuditEvent.permissionEligibilityChanges.typeName === Contract.TypeNames.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges) {
        const awsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange = (permissionEligibilityUpdateAuditEvent.permissionEligibilityChanges as Contract.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges).permissionsChange;
        if (!_.isNil(awsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange) &&
            awsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange.typeName === Contract.TypeNames.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPolicyPermissionsChange) {
            awsInlinePolicyDocumentChanged = (awsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange as Contract.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPolicyPermissionsChange).inlinePolicyDocumentChanged;
        }
    }

    const propertyChanges = permissionEligibilityUpdateAuditEventChangesTranslator(permissionEligibilityUpdateAuditEvent);
    return _.isNil(propertyChanges) && !awsInlinePolicyDocumentChanged
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : <Stack>
            {!_.isNil(propertyChanges) &&
                <Typography>
                    {localization.propertyChanges({ propertyChanges })}
                </Typography>}
            {awsInlinePolicyDocumentChanged &&
                <AwsInlinePolicyDocumentDiff
                    existingRawInlinePolicyDocument={(item.auditEvent.permissionEligibilityData as Contract.PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData).existingInlinePolicyDocument?.raw}
                    rawInlinePolicyDocument={(item.auditEvent.permissionEligibilityData as Contract.PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData).inlinePolicyDocument?.raw}/>}
        </Stack>;
}