import { Contract } from "../../../../../../../../../../common";
import { useGciCommonEntityInfoItemElements } from "./useGciCommonEntityInfoItemElements";

export function useGciDefaultEntityInfoItemElements(entityModel: Contract.GciEntityModel) {
    const commonEntityInfoElements = useGciCommonEntityInfoItemElements(entityModel);
    return [
        commonEntityInfoElements.typeNameInfoElement,
        commonEntityInfoElements.tenantInfoItemElement,
        commonEntityInfoElements.syncTimeInfoItemElement
    ];
}