﻿import _, { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import { useAwsCommonKubernetesNamespaceResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesWorkloadResourceDefinition } from "../useCommonKubernetesWorkloadResourceDefinition";

export function useAwsKubernetesWorkloadResourceDefinition(definitionData: DefinitionData) {
    const commonKubernetesWorkloadResourceDefinition = useCommonKubernetesWorkloadResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const { columns, initialFilterMap } = useAwsCommonKubernetesNamespaceResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsKubernetesWorkloadResourceDefinition",
            () => ({
                columns: {
                    awsRoleIds: "AWS Role"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            columns.clusterColumn,
            columns.tenantColumn,
            commonKubernetesWorkloadResourceDefinition.columns.typeColumn,
            columns.creationTimeColumn,
            columns.namespaceColumn,
            commonKubernetesWorkloadResourceDefinition.columns.containerImagesColumn,
            commonKubernetesWorkloadResourceDefinition.columns.containerImageVulnerabilitiesColumn,
            commonKubernetesWorkloadResourceDefinition.columns.serviceAccountColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsKubernetesWorkloadResourceModel>(
                        Contract.TypeNames.AwsIamRole,
                        item => item.awsRoleIds,
                        localization.columns.awsRoleIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsKubernetesWorkloadResourceAwsRoles)}
                                placeholder={localization.columns.awsRoleIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsKubernetesWorkloadResourceAwsRoles}
                key={Contract.EntityModelProperty.AwsKubernetesWorkloadResourceAwsRoles}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsKubernetesWorkloadResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.awsRoleIds}
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.awsRoleIds()}/>,
            columns.tagsColumn,
            columns.attributesColumn,
            columns.openRiskedEntityRisksCsvColumn,
            columns.openRelatedEntityRiskHighestSeverityColumn,
            columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsKubernetesWorkloadResourceRequest(
                new Contract.EntityControllerGetEntityModelPageAwsKubernetesWorkloadResourceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsKubernetesWorkloadResourceAwsRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImages]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadContainerImageVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceControllerResource]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceServiceAccount]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceTypeName])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesResourceCluster])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesNamespaceResourceNamespace]))),
        {
            initialFilterMap
        });
}