import { Action0, NodeContentContextMenuItem, Optional, useDirectedGraphNodeContext, useLocalization } from "@infrastructure";
import React, { MutableRefObject } from "react";
import { Contract, EntityNodeContent, getEntityNodeContentAnchorPoint, getEntityNodeContentSize, useAccessContext } from "../../../../..";
import { AccessDefinitionToolbarActions, ToolbarFilterId } from "../../../hooks";

type EntityGroupNodeContentProps = {
    destinationEntityGroup: Contract.AccessGraphDestinationEntityGroup;
    onViewPermissions: Action0;
    toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>;
};

export function EntityGroupNodeContent({ destinationEntityGroup, onViewPermissions, toolbarActionsRef }: EntityGroupNodeContentProps) {
    const localization =
        useLocalization(
            "common.access.graph.entityGroupNodeContent",
            () => ({
                menu: {
                    permissions: "View permissions"
                }
            }));
    const { modelNode } = useDirectedGraphNodeContext();
    const { entityId, variant } = useAccessContext();
    return (
        <EntityNodeContent
            contextMenuItems={
                modelNode.options.appearance === "normal"
                    ? undefined
                    : [
                        new NodeContentContextMenuItem(
                            onViewPermissions,
                            localization.menu.permissions())
                    ]}
            entityIds={destinationEntityGroup.entityIds}
            entityProfileMenuItem={
                destinationEntityGroup.entityIds[0] !== entityId ||
                variant !== "entity"}
            entityTypeName={destinationEntityGroup.entityTypeEntitiesViewName}
            titlePosition="right"
            variant="large"
            onFilter={
                type =>
                    toolbarActionsRef.current!.filter(
                        ToolbarFilterId.DestinationEntityIds,
                        type,
                        destinationEntityGroup.entityIds)}/>);
}

export const getEntityGroupNodeContentAnchorPoint =
    () => getEntityNodeContentAnchorPoint("left", "right", "large");

export const getEntityGroupNodeContentSize =
    () => getEntityNodeContentSize("right", "large");