import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Change, Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { ChangeHelper } from "../../../../utilities";
import { ContextSection } from "./components";

export function useAwsIamUserUnusedPasswordEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const userUnusedPasswordEnabledRiskModel = riskModel as Contract.AwsIamUserUnusedPasswordEnabledRiskModel;
    const userModel = entityModelStore.useGet(userUnusedPasswordEnabledRiskModel.risk.entityId) as Contract.AwsIamUserModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserUnusedPasswordEnabledRiskDefinition",
            () => ({
                description: "{{user}} has a password that was unused for more than {{unusedPasswordTimeFrame | TimeSpanFormatter.humanizeDays}}"
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            unusedPasswordTimeFrame: userUnusedPasswordEnabledRiskModel.risk.passwordUsageTimeFrame,
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            _.map(
                ChangeHelper.getChanges(userUnusedPasswordEnabledRiskModel.risk.resolutionChanges!),
                change => <Change change={change}/>),
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    risk={userUnusedPasswordEnabledRiskModel.risk}
                    userModel={userModel}/>
        });
}