import { Store } from "@infrastructure";
import { Contract, DataController } from "..";

export const dataClassifierModelStore =
    new Store<Contract.DataClassifierModel, never, never>(
        dataClassifierModel => dataClassifierModel.id,
        {
            getAll:
                async () => {
                    const { dataClassifierModels } = await DataController.getDataClassifierModels();
                    return dataClassifierModels;
                }
        });