﻿import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, EntityFilter } from "../../../../../../../../../../common";
import { GranteeUserPermissionRequestsViewType, useGranteeUserPermissionRequestsContext } from "../../../../../../GranteeUserPermissionRequests";
import { TableColumnId } from "../../../../Table";
import { TableDefinition } from "../../useDefinition";
import { useCommonColumns } from "../useCommonColumns";
import { ActionsCell } from "./components";

export function useHistoryDefinition(): TableDefinition {
    const { getFiltersResponse } = useGranteeUserPermissionRequestsContext();
    const commonColumns = useCommonColumns(GranteeUserPermissionRequestsViewType.History);

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.table.hooks.useDefinition.hooks.useHistoryDefinition.useHistoryDefinition",
            () => ({
                actions: {
                    add: "Request Access"
                },
                columns: {
                    approverUserIdReferences: "Approvers",
                    closeReason: "Status"
                },
                filters: {
                    approverUserSearchableReference: "Approvers",
                    closeReasonAndStatus: {
                        title: "Status"
                    }
                }
            }));

    return useMemo(
        () =>
            new TableDefinition(
                () =>
                    [
                        commonColumns.systemCreationTime,
                        commonColumns.permissionEligibility,
                        commonColumns.permissionAssignmentRequestTenantIds,
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <EntityFilter
                                            emptyValue={getFiltersResponse.approverUserSearchableReferenceItems.emptyValue}
                                            entityIdsOrSearchableReferences={getFiltersResponse.approverUserSearchableReferenceItems.items}
                                            placeholder={localization.filters.approverUserSearchableReference()}/>
                                }
                            }}
                            id={TableColumnId.ApproverUserSearchableReference}
                            key={TableColumnId.ApproverUserSearchableReference}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) =>
                                    <EntitiesCell
                                        entityIdsOrModels={
                                            _(item.levelToApproverUserIdReferenceMap).
                                                values().
                                                uniq().
                                                value()}
                                        entityLinkOptions={{ disabled: true }}
                                        entityTypeName={Contract.TypeNames.IPermissionManagementUser}
                                        entityVariant="iconText"/>}
                            sortOptions={{ enabled: false }}
                            title={localization.columns.approverUserIdReferences()}/>,
                        commonColumns.status,
                        commonColumns.expirationTimeFrame,
                        commonColumns.reason,
                        <DataTableColumn
                            id={TableColumnId.Actions}
                            key={TableColumnId.Actions}
                            render={ActionsCell}
                            selectorOptions={{ disabled: true }}/>
                    ],
                [Contract.PermissionRequestStatus.Closed]),
        [commonColumns, getFiltersResponse, localization]);
}