﻿import { useInputValidation, useLocalization } from "@infrastructure";
import { Box, FormHelperText } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, ElasticsearchItemPageHelper, EntityController, entityModelStore, PagedEntitySelector, PermissionManagementHelper } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../../../AddOrEdit";
import { AwsSsoPermissionSetAssignmentEligibilityData, AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions } from "../../../utilities";
import { PermissionsProps } from "../Permissions";

export function PermissionSetPermissions({ disabled, onChange, onValidChange }: PermissionsProps) {
    const { permissionEligibilityData } = useAddOrEditContext();
    const { permissions, principalTenantId } = permissionEligibilityData as AwsSsoPermissionSetAssignmentEligibilityData;
    const permissionSetPermissions =
        permissions instanceof AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions
            ? permissions as AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions
            : undefined;

    const [permissionSetId, setPermissionSetId] = useState(permissionSetPermissions?.permissionSetId);

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.hooks.useAwsDefinition.permissions.permissionSetPermissions",
            () => ({
                permissionSet: {
                    error: {
                        inlinePolicyLength: "The permission set's inline policy cannot be larger than {{inlinePolicyDocumentMaxLength | NumberFormatter.humanize}} characters",
                        notSupported: "The permission set has permissions boundary or customer managed policies which are not supported"
                    },
                    placeholder: "Permission set"
                }
            }));


    const permissionSetModel = entityModelStore.useGet(permissionSetId);
    const [permissionSetIdValidationController, permissionSetIdValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(permissionSetModel)) {
                    return undefined;
                }

                if (PermissionManagementHelper.getAwsIamPolicyDocumentCharacterCount((permissionSetModel.entity as Contract.AwsSsoPermissionSet).inlinePolicyDocument) > PermissionManagementHelper.awsInlinePolicyDocumentMaxLength) {
                    return localization.permissionSet.error.inlinePolicyLength({ inlinePolicyDocumentMaxLength: PermissionManagementHelper.awsInlinePolicyDocumentMaxLength });
                }

                if ((permissionSetModel.entity as Contract.AwsSsoPermissionSet).customerManagedPolicyExists ||
                    (permissionSetModel.entity as Contract.AwsSsoPermissionSet).permissionsBoundaryExists) {
                    return localization.permissionSet.error.notSupported();
                }

                return undefined;
            },
            [permissionSetModel]);

    useEffect(
        () => {
            onChange(new AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions(permissionSetId));
        },
        [permissionSetId]);

    useEffect(
        () => {
            onValidChange(
                !_.isNil(permissionSetId) &&
                permissionSetIdValidationController.isValid());
        },
        [permissionSetId, permissionSetModel]);

    return (
        <Box>
            <PagedEntitySelector
                disabled={disabled}
                fullWidth={true}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntitySelector(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    new Contract.EntityControllerSearchEntityModelsPermissionManagementAwsSsoPermissionSetRequest(
                                        false,
                                        15,
                                        itemNextPageSearchCursor,
                                        undefined,
                                        searchText,
                                        principalTenantId!));
                            return entityModelPage;
                        })}
                placeholder={localization.permissionSet.placeholder()}
                selectedEntityId={permissionSetId}
                onSelectedEntityIdChanged={setPermissionSetId}/>
            {!_.isNil(permissionSetIdValidationMessage) && (
                <FormHelperText error={true}>
                    {permissionSetIdValidationMessage}
                </FormHelperText>)}
        </Box>);
}