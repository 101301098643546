﻿import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, EntitiesCell, entityModelStore, InfoCard, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type VirtualMachineScaleSetsInfoCardProps = {
    virtualMachineScaleSetIds: string[];
};

export function VirtualMachineScaleSetsInfoCard({ virtualMachineScaleSetIds }: VirtualMachineScaleSetsInfoCardProps) {
    const virtualMachineScaleSetModels = entityModelStore.useGet(virtualMachineScaleSetIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureContainerServiceManagedClusterAgentPoolDefinition.virtualMachineScaleSetsInfoCard",
            () => ({
                empty: "No {{translatedEntityTypeName}}"
            }));
    return (
        <InfoCard
            title={
                entityTypeNameTranslator(
                    Contract.TypeNames.AzureComputeVirtualMachineScaleSet,
                    { count: 0 })}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AzureComputeVirtualMachineScaleSet,
                                        { count: 0 })
                            }))
                }}
                fetchItems={() => virtualMachineScaleSetModels}
                getItemId={(item: Contract.AzureComputeVirtualMachineScaleSetModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id="virtualMachineScaleSet"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineScaleSetModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.id}
                                entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSet}
                                entityVariant="iconTextTenant"/>}
                    title={entityTypeNameTranslator(Contract.TypeNames.AzureComputeVirtualMachineScaleSet)}/>
                <DataTableColumn
                    id="virtualMachineScaleSetVirtualMachineIds"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineScaleSetModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.virtualMachineIds}
                                entityTypeName={Contract.TypeNames.AzureComputeVirtualMachine}
                                entityVariant="iconTextTenant"/>}
                    title={
                        entityTypeNameTranslator(
                            Contract.TypeNames.AzureComputeVirtualMachine,
                            { count: 0 })}/>
            </DataTable>
        </InfoCard>);
}