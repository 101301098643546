import { DataTableColumnRenderProps, map } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, StatusCell as BaseStatusCell } from "../../../../../../../../../../../../../../../common";
import { useOktaTenantModelStatusTranslator } from "../../../hooks";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.OktaTenantModel>) {
    const oktaTenantModelStatusTranslator = useOktaTenantModelStatusTranslator();
    return (
        <BaseStatusCell
            statusSeverity={item.statusSeverity}
            title={oktaTenantModelStatusTranslator(item.status)}
            tooltip={
                map(
                    item.status,
                    {
                        [Contract.OktaTenantModelStatus.AccessDenied]: () =>
                            <Typography
                                key={item.state.monitoring.analysisAccessDeniedErrorMessage}
                                sx={{ whiteSpace: "pre-wrap" }}>
                                {item.state.monitoring.analysisAccessDeniedErrorMessage}
                            </Typography>
                    },
                    () => undefined)}/>);
}