import { AppError, map } from "@infrastructure";
import _ from "lodash";
import path from "path";
import { CustomerConsoleAppUrlHelper, TeamsConsoleAppUrlHelper, UserConsoleAppUrlHelper } from ".";
import { Contract } from "../controllers";

export class ConsoleAppUrlHelper {
    private static readonly urlPath = "/";

    public static url: URL;

    public static initialize(url: URL) {
        ConsoleAppUrlHelper.url = url;
        if (ConsoleAppUrlHelper.url.pathname !== ConsoleAppUrlHelper.urlPath) {
            throw new AppError("Console app URL path mismatch");
        }
    }

    public static getConsoleAppRelativeUrl(relativeUrl: string): string {
        relativeUrl = _.trim(relativeUrl, "/");
        const consoleAppRelativeUrlStartingIndex = relativeUrl.indexOf("/");
        return consoleAppRelativeUrlStartingIndex === -1
            ? "/"
            : `/${relativeUrl.substring(consoleAppRelativeUrlStartingIndex + 1)}`;
    }

    public static getCustomersRelativeUrl(consoleAppType: Contract.ConsoleAppType): string {
        return map(
            consoleAppType,
            {
                [Contract.ConsoleAppType.Customer]: () => CustomerConsoleAppUrlHelper.getCustomersRelativeUrl(),
                [Contract.ConsoleAppType.User]: () => UserConsoleAppUrlHelper.getCustomersRelativeUrl()
            });
    }

    public static getRelativeUrl(consoleAppType: Contract.ConsoleAppType, consoleAppRelativeUrl: string): string {
        return map(
            consoleAppType,
            {
                [Contract.ConsoleAppType.Customer]: () => CustomerConsoleAppUrlHelper.getRelativeUrl(consoleAppRelativeUrl),
                [Contract.ConsoleAppType.User]: () => UserConsoleAppUrlHelper.getRelativeUrl(consoleAppRelativeUrl)
            });
    }

    public static getResourcesQRadarErmeticExtensionRelativeUrl(): string {
        return path.join(
            ConsoleAppUrlHelper.urlPath,
            "resources",
            "QRadarErmeticExtension.zip");
    }

    public static getSignInErrorRelativeUrl(consoleAppType: Contract.ConsoleAppType): string {
        return map(
            consoleAppType,
            {
                [Contract.ConsoleAppType.Customer]: () => CustomerConsoleAppUrlHelper.getSignInErrorRelativeUrl(),
                [Contract.ConsoleAppType.User]: () => UserConsoleAppUrlHelper.getSignInErrorRelativeUrl()
            });
    }

    public static getSignInRelativeUrl(consoleAppType: Contract.ConsoleAppType, redirectUrl?: string): string {
        return map(
            consoleAppType,
            {
                [Contract.ConsoleAppType.Customer]: () => CustomerConsoleAppUrlHelper.getSignInRelativeUrl(redirectUrl),
                [Contract.ConsoleAppType.User]: () => UserConsoleAppUrlHelper.getSignInRelativeUrl(redirectUrl)
            });
    }

    public static getUnauthorizedPageRelativeUrl(consoleAppType: Contract.ConsoleAppType, redirectUrl?: string): string {
        return map(
            consoleAppType,
            {
                [Contract.ConsoleAppType.Customer]: () => CustomerConsoleAppUrlHelper.getSignInRelativeUrl(redirectUrl),
                [Contract.ConsoleAppType.Teams]: () => TeamsConsoleAppUrlHelper.getSignInErrorRelativeUrl(Contract.AuthenticationControllerTeamsSignInError.FailureUnauthorized),
                [Contract.ConsoleAppType.User]: () => UserConsoleAppUrlHelper.getSignInRelativeUrl(redirectUrl)
            });
    }

    public static isRelativeUrl(consoleAppType: Contract.ConsoleAppType, relativeUrl: string): boolean {
        return map(
            consoleAppType,
            {
                [Contract.ConsoleAppType.Customer]: () => CustomerConsoleAppUrlHelper.isRelativeUrl(relativeUrl),
                [Contract.ConsoleAppType.User]: () => UserConsoleAppUrlHelper.isRelativeUrl(relativeUrl)
            });
    }
}