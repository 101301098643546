import { Link, Step, Steps, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../index";

export function Manual() {
    const { aadTenantModel, rawShortId, tenantModel, tenantPermissionTypes, tenantTerraformStateStorageAccountBlobContainerEnabled } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.addOrEdit.roleDefinitionsItem.manual",
            () => ({
                dataAnalysisRoleDefinitionName: "Tenable Data Scanning",
                defaultAadApplicationName: "Tenable Cloud Security Connector",
                steps: {
                    step1: {
                        link: "Azure portal",
                        title: {
                            add: "Login to {{link}}, and choose the **Subscription** you want to add.",
                            edit: "Login to {{link}}, and choose the **Subscription** you want to edit."
                        }
                    },
                    step2: {
                        add: "Create a custom role called **{{dataAnalysisRoleDefinitionRoleName}}** and add the following permissions to it:",
                        edit: "Verify a custom role called **{{dataAnalysisRoleDefinitionRoleName}}** exists and contains the following permissions:"
                    },
                    step3: {
                        add: "Assign roles to the **{{aadApplicationName}}** app by repeating the steps below for each of the following roles:",
                        edit: "Verify the following roles are assigned to **{{aadApplicationName}}** by following the steps below:"
                    },
                    step4: "Navigate to **Access Control (IAM)** and click on **Add -> Add role assignment**",
                    step5: "Search for and select a role from the list above and click **Next**",
                    step6: "Click **+ Select members**",
                    step7: "Search for and select **{{aadApplicationName}}** and then click **Select**",
                    step8: "Click **Next** and then click **Review + assign** to finish. Repeat these steps for all additional roles.",
                    step9: "In the Azure portal, navigate to storage account **{{storageAccountName}}**",
                    step10: "Navigate to **Containers** (under **Data storage**) and then click on **{{blobContainerName}}**",
                    step11: "Navigate to **Access Control (IAM)**, and then click **Add -> Add role assignment**",
                    step12: "Search for and select the **Storage Blob Data Reader** role from the list in the wizard and then click **Next**",
                    step13: "Click **+ Select members**",
                    step14: "Search for and select **{{aadApplicationName}}** and then click **Select**",
                    step15: "Click **Next** and then click **Review + assign** to finish"
                }
            }));

    const localizationVariant =
        _.isNil(tenantModel)
            ? "add"
            : "edit";
    const aadApplicationName = aadTenantModel!.configuration.application?.name ?? localization.defaultAadApplicationName();
    const dataAnalysisRoleDefinitionRoleName = `${localization.dataAnalysisRoleDefinitionName()} (${rawShortId ?? "[subscription ID]"})`;
    return (
        <Steps variant="letters">
            {_.filter([localization.steps.step1.title[localizationVariant]({
                link:
                    <Link
                        urlOrGetUrl="https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBlade"
                        variant="external">
                        {localization.steps.step1.link()}
                    </Link>
            }),
            _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis)
                ? new Step(
                    localization.steps.step2[localizationVariant]({ dataAnalysisRoleDefinitionRoleName }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {_<string>([
                                    "Microsoft.Sql/servers/databases/write",
                                    "Microsoft.Storage/storageAccounts/privateEndpointConnections/write"
                                ]).
                                    map(
                                        action =>
                                            new Step(
                                                <Typography sx={{ fontWeight: 600 }}>
                                                    {action}
                                                </Typography>)).
                                    value()}
                            </Steps>
                    })
                : null,
            new Step(
                localization.steps.step3[localizationVariant]({ aadApplicationName }),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {_<string>([]).
                                concat([
                                    "Reader",
                                    "Key Vault Reader",
                                    "Azure Kubernetes Service Cluster User Role",
                                    "Azure Kubernetes Service RBAC Reader"
                                ]).
                                concatIf(
                                    _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                                    "Disk Snapshot Contributor (Virtual Machine Scanning)").
                                concatIf(
                                    _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.PermissionManagement),
                                    "User Access Administrator (JIT)").
                                concatIf(
                                    _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.Write),
                                    "Owner (Remediation)").
                                concatIf(
                                    _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                                    "Storage Blob Data Reader",
                                    dataAnalysisRoleDefinitionRoleName).
                                map(
                                    roleDefinitionName =>
                                        new Step(
                                            <Typography sx={{ fontWeight: 600 }}>
                                                {roleDefinitionName}
                                            </Typography>)).
                                value()}
                        </Steps>
                }),
            localization.steps.step4(),
            localization.steps.step5(),
            localization.steps.step6(),
            localization.steps.step7({ aadApplicationName }),
            localization.steps.step8(),
            ...tenantTerraformStateStorageAccountBlobContainerEnabled
                ? _.flatMap(
                    tenantModel?.state.codeAnalysis.terraformStateStorageAccountNameToBlobContainerNamesMap,
                    (blobContainerNames, storageAccountName) =>
                        _.flatMap(
                            blobContainerNames,
                            blobContainerName => [
                                localization.steps.step9({ storageAccountName }),
                                localization.steps.step10({ blobContainerName }),
                                localization.steps.step11(),
                                localization.steps.step12(),
                                localization.steps.step13(),
                                localization.steps.step14({ aadApplicationName }),
                                localization.steps.step15()
                            ]))
                : []
            ])}
        </Steps>);
}