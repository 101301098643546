﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function IndiaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <rect
                fill="white"
                height="16.0724"
                rx="2"
                width="24"
                y="4"/>
            <path
                d="M0 4.00183H24V9.3231H0V4.00183Z"
                fill="#FF9933"/>
            <path
                d="M0 9.32312H24V14.6444H0V9.32312Z"
                fill="white"/>
            <path
                clipRule="evenodd"
                d="M1 9.32312V14.6444H0V9.32312H1ZM23 14.6444V9.32312H24V14.6444H23Z"
                fill="#BAC5D3"
                fillRule="evenodd"/>
            <path
                d="M0 14.6445H24V19.9658H0V14.6445Z"
                fill="#128807"/>
            <path
                d="M12.0826 14.0325C13.2183 14.0325 14.139 13.1118 14.139 11.9761C14.139 10.8404 13.2183 9.91968 12.0826 9.91968C10.9469 9.91968 10.0262 10.8404 10.0262 11.9761C10.0262 13.1118 10.9469 14.0325 12.0826 14.0325Z"
                fill="#000088"/>
            <path
                d="M12.0825 13.7753C13.0762 13.7753 13.8818 12.9697 13.8818 11.9759C13.8818 10.9821 13.0762 10.1765 12.0825 10.1765C11.0887 10.1765 10.2831 10.9821 10.2831 11.9759C10.2831 12.9697 11.0887 13.7753 12.0825 13.7753Z"
                fill="white"/>
            <path
                d="M12.0823 12.3361C12.281 12.3361 12.4422 12.175 12.4422 11.9762C12.4422 11.7775 12.281 11.6163 12.0823 11.6163C11.8835 11.6163 11.7224 11.7775 11.7224 11.9762C11.7224 12.175 11.8835 12.3361 12.0823 12.3361Z"
                fill="#000088"/>
            <path
                d="M13.8551 12.3012C13.9058 12.3078 13.9523 12.2722 13.959 12.2215C13.9656 12.1708 13.93 12.1243 13.8793 12.1177C13.8286 12.111 13.7821 12.1467 13.7755 12.1973C13.7688 12.248 13.8045 12.2945 13.8551 12.3012Z"
                fill="#000088"/>
            <path
                d="M12.0834 13.7732L12.1451 12.6936L12.0834 12.1794L12.0217 12.6936L12.0834 13.7732Z"
                fill="#000088"/>
            <path
                d="M13.7076 12.7513C13.7538 12.7698 13.8079 12.7458 13.8285 12.6976C13.8491 12.6494 13.8284 12.5953 13.7823 12.5767C13.7361 12.5582 13.682 12.5822 13.6614 12.6304C13.6407 12.6786 13.6615 12.7327 13.7076 12.7513Z"
                fill="#000088"/>
            <path
                d="M11.6129 13.7144L11.9535 12.6839L12.0347 12.1719L11.8437 12.6551L11.6129 13.7144Z"
                fill="#000088"/>
            <path
                d="M13.4517 13.1443C13.4912 13.1743 13.5493 13.165 13.5815 13.1234C13.6137 13.0818 13.6078 13.0237 13.5683 12.9936C13.5289 12.9636 13.4708 12.9729 13.4386 13.0145C13.4064 13.0561 13.4123 13.1142 13.4517 13.1443Z"
                fill="#000088"/>
            <path
                d="M11.1803 13.5337L11.7746 12.625L11.9834 12.1496L11.674 12.5672L11.1803 13.5337Z"
                fill="#000088"/>
            <path
                d="M13.1025 13.4602C13.1326 13.4998 13.1907 13.506 13.2322 13.474C13.2737 13.4419 13.2829 13.3838 13.2528 13.3442C13.2226 13.3046 13.1645 13.2984 13.123 13.3305C13.0815 13.3625 13.0723 13.4206 13.1025 13.4602Z"
                fill="#000088"/>
            <path
                d="M10.8082 13.2485L11.616 12.524L11.9393 12.1175L11.5329 12.4408L10.8082 13.2485Z"
                fill="#000088"/>
            <path
                d="M12.6883 13.6731C12.709 13.7215 12.7631 13.7458 12.8092 13.7274C12.8552 13.709 12.8758 13.6549 12.855 13.6065C12.8343 13.5581 12.7802 13.5338 12.7341 13.5522C12.6881 13.5706 12.6676 13.6248 12.6883 13.6731Z"
                fill="#000088"/>
            <path
                d="M10.5276 12.8736L11.4927 12.395L11.9036 12.0834L11.4268 12.2815L10.5276 12.8736Z"
                fill="#000088"/>
            <path
                d="M12.2282 13.7707C12.236 13.8226 12.2825 13.8602 12.332 13.8547C12.3815 13.8492 12.4153 13.8028 12.4075 13.7509C12.3997 13.699 12.3532 13.6614 12.3037 13.6669C12.2542 13.6724 12.2204 13.7189 12.2282 13.7707Z"
                fill="#000088"/>
            <path
                d="M10.3472 12.4377L11.4055 12.2299L11.8834 12.0354L11.3702 12.1005L10.3472 12.4377Z"
                fill="#000088"/>
            <path
                d="M11.754 13.7459C11.7486 13.7954 11.7864 13.8419 11.8383 13.8497C11.8903 13.8574 11.9367 13.8236 11.9421 13.774C11.9475 13.7244 11.9098 13.6779 11.8578 13.6701C11.8059 13.6624 11.7594 13.6963 11.754 13.7459Z"
                fill="#000088"/>
            <path
                d="M10.286 11.9611L11.3651 12.0281L11.8764 11.9907L11.3617 11.9343L10.286 11.9611Z"
                fill="#000088"/>
            <path
                d="M11.3091 13.6027C11.2902 13.6495 11.3135 13.7036 11.3613 13.7236C11.4091 13.7436 11.4633 13.7218 11.4822 13.6751C11.5012 13.6283 11.4779 13.5741 11.4301 13.5541C11.3823 13.5342 11.3281 13.5559 11.3091 13.6027Z"
                fill="#000088"/>
            <path
                d="M10.3458 11.5087L11.3749 11.8484L11.8871 11.9252L11.405 11.7332L10.3458 11.5087Z"
                fill="#000088"/>
            <path
                d="M10.9167 13.3488C10.8845 13.3904 10.8904 13.4485 10.9298 13.4785C10.9693 13.5086 11.0274 13.4992 11.0596 13.4576C11.0918 13.4159 11.0859 13.3579 11.0465 13.3278C11.0071 13.2978 10.949 13.3072 10.9167 13.3488Z"
                fill="#000088"/>
            <path
                d="M10.5279 11.0798L11.4271 11.6719L11.9039 11.87L11.493 11.5583L10.5279 11.0798Z"
                fill="#000088"/>
            <path
                d="M10.6003 12.999C10.5586 13.0312 10.5492 13.0893 10.5792 13.1288C10.6092 13.1683 10.6673 13.1742 10.7089 13.142C10.7506 13.1098 10.76 13.0517 10.73 13.0122C10.7 12.9728 10.6419 12.9668 10.6003 12.999Z"
                fill="#000088"/>
            <path
                d="M10.8124 10.7056L11.5263 11.5118L11.9343 11.8277L11.6186 11.4197L10.8124 10.7056Z"
                fill="#000088"/>
            <path
                d="M10.3867 12.58C10.3386 12.6004 10.3148 12.6545 10.3335 12.7009C10.3522 12.7473 10.4063 12.7685 10.4544 12.7481C10.5025 12.7278 10.5263 12.6737 10.5076 12.6272C10.4889 12.5808 10.4348 12.5597 10.3867 12.58Z"
                fill="#000088"/>
            <path
                d="M11.186 10.4195L11.6666 11.3848L11.9779 11.7969L11.7783 11.3203L11.186 10.4195Z"
                fill="#000088"/>
            <path
                d="M10.2875 12.1201C10.2359 12.1276 10.1988 12.174 10.2046 12.2239C10.2104 12.2738 10.2569 12.3083 10.3084 12.3008C10.36 12.2934 10.3971 12.2469 10.3913 12.197C10.3855 12.1471 10.339 12.1127 10.2875 12.1201Z"
                fill="#000088"/>
            <path
                d="M11.6197 10.2393L11.8307 11.2977L12.0247 11.7766L11.9574 11.2636L11.6197 10.2393Z"
                fill="#000088"/>
            <path
                d="M10.314 11.6483C10.2646 11.6427 10.2181 11.6801 10.2101 11.7319C10.2022 11.7836 10.2357 11.8301 10.2851 11.8357C10.3345 11.8413 10.381 11.8039 10.3889 11.7521C10.3969 11.7004 10.3633 11.6539 10.314 11.6483Z"
                fill="#000088"/>
            <path
                d="M12.1001 10.1798L12.0325 11.2589L12.0664 11.7707L12.1221 11.2561L12.1001 10.1798Z"
                fill="#000088"/>
            <path
                d="M10.4587 11.2003C10.4126 11.1817 10.3585 11.2057 10.3378 11.2538C10.3172 11.302 10.3378 11.3561 10.3839 11.3747C10.4301 11.3934 10.4842 11.3694 10.5049 11.3212C10.5255 11.2731 10.5049 11.219 10.4587 11.2003Z"
                fill="#000088"/>
            <path
                d="M12.5537 10.2374L12.213 11.2678L12.1316 11.7798L12.3225 11.2968L12.5537 10.2374Z"
                fill="#000088"/>
            <path
                d="M10.7155 10.8062C10.6761 10.7761 10.618 10.7854 10.5857 10.827C10.5535 10.8686 10.5593 10.9267 10.5987 10.9568C10.6381 10.9869 10.6962 10.9775 10.7284 10.9359C10.7607 10.8943 10.7549 10.8363 10.7155 10.8062Z"
                fill="#000088"/>
            <path
                d="M12.9869 10.4172L12.3925 11.3259L12.1835 11.8013L12.4929 11.3837L12.9869 10.4172Z"
                fill="#000088"/>
            <path
                d="M11.0635 10.4915C11.0335 10.452 10.9754 10.446 10.9337 10.4781C10.8921 10.5102 10.8826 10.5683 10.9126 10.6079C10.9426 10.6474 11.0006 10.6534 11.0423 10.6213C11.084 10.5891 11.0935 10.5311 11.0635 10.4915Z"
                fill="#000088"/>
            <path
                d="M13.3575 10.7032L12.5496 11.4282L12.2257 11.8346L12.6319 11.511L13.3575 10.7032Z"
                fill="#000088"/>
            <path
                d="M11.4775 10.279C11.4567 10.2306 11.4026 10.2063 11.3566 10.2247C11.3105 10.2431 11.29 10.2972 11.3107 10.3456C11.3315 10.394 11.3856 10.4183 11.4316 10.3999C11.4777 10.3815 11.4982 10.3274 11.4775 10.279Z"
                fill="#000088"/>
            <path
                d="M13.6382 11.0791L12.6731 11.5576L12.2622 11.8693L12.739 11.6711L13.6382 11.0791Z"
                fill="#000088"/>
            <path
                d="M11.939 10.182C11.9312 10.1302 11.8847 10.0926 11.8352 10.098C11.7856 10.1035 11.7519 10.15 11.7597 10.2019C11.7675 10.2537 11.814 10.2913 11.8635 10.2859C11.913 10.2804 11.9468 10.2339 11.939 10.182Z"
                fill="#000088"/>
            <path
                d="M13.82 11.5151L12.7617 11.7229L12.2838 11.9173L12.7969 11.8523L13.82 11.5151Z"
                fill="#000088"/>
            <path
                d="M12.4113 10.2045C12.4165 10.1551 12.3786 10.1086 12.3265 10.1007C12.2744 10.0928 12.2279 10.1264 12.2227 10.1758C12.2174 10.2252 12.2554 10.2717 12.3074 10.2796C12.3595 10.2876 12.406 10.2539 12.4113 10.2045Z"
                fill="#000088"/>
            <path
                d="M13.8787 11.991L12.7998 11.9234L12.2887 11.9579L12.8035 12.0137L13.8787 11.991Z"
                fill="#000088"/>
            <path
                d="M12.8562 10.3491C12.875 10.3025 12.8513 10.2484 12.8034 10.2282C12.7554 10.208 12.7012 10.2294 12.6824 10.276C12.6637 10.3226 12.6873 10.3767 12.7353 10.3969C12.7833 10.4171 12.8375 10.3957 12.8562 10.3491Z"
                fill="#000088"/>
            <path
                d="M13.8194 12.4431L12.7897 12.103L12.2776 12.0248L12.7602 12.2165L13.8194 12.4431Z"
                fill="#000088"/>
            <path
                d="M13.2509 10.6064C13.2814 10.5664 13.2729 10.5083 13.2317 10.4766C13.1906 10.445 13.1325 10.4518 13.102 10.4919C13.0715 10.5319 13.08 10.59 13.1211 10.6216C13.1623 10.6532 13.2203 10.6464 13.2509 10.6064Z"
                fill="#000088"/>
            <path
                d="M13.6396 12.8773L12.733 12.2835L12.2573 12.0774L12.6734 12.3873L13.6396 12.8773Z"
                fill="#000088"/>
            <path
                d="M13.5661 10.9562C13.6061 10.9256 13.6128 10.8675 13.5811 10.8264C13.5494 10.7853 13.4913 10.7768 13.4513 10.8074C13.4114 10.838 13.4047 10.8961 13.4363 10.9372C13.468 10.9782 13.5261 10.9867 13.5661 10.9562Z"
                fill="#000088"/>
            <path
                d="M13.3547 13.2497L12.632 12.4423L12.2253 12.1205L12.5473 12.5272L13.3547 13.2497Z"
                fill="#000088"/>
            <path
                d="M13.7791 11.375C13.8257 11.356 13.8473 11.3018 13.8272 11.2541C13.8071 11.2064 13.7529 11.1832 13.7063 11.2022C13.6596 11.2213 13.6381 11.2754 13.6582 11.3231C13.6783 11.3708 13.7324 11.3941 13.7791 11.375Z"
                fill="#000088"/>
            <path
                d="M12.9812 13.5355L12.4913 12.5693L12.1813 12.1535L12.3874 12.6293L12.9812 13.5355Z"
                fill="#000088"/>
            <path
                d="M13.8774 11.8364C13.9275 11.8303 13.9623 11.7838 13.9551 11.7326C13.9479 11.6814 13.9014 11.6449 13.8513 11.6511C13.8011 11.6572 13.7663 11.7037 13.7736 11.7549C13.7808 11.8061 13.8273 11.8426 13.8774 11.8364Z"
                fill="#000088"/>
            <path
                d="M12.5478 13.7167L12.3229 12.6577L12.131 12.1759L12.2081 12.6882L12.5478 13.7167Z"
                fill="#000088"/>
        </SvgIcon>);
}