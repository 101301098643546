import { useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { InsertTrustedFilesDialog } from "../../../../../../../../WorkloadAnalysis";

type InsertTrustedFilesProps = {
    contentSha256Strings: string[];
    tenantId: string;
};

export function InsertTrustedFiles({ contentSha256Strings, tenantId }: InsertTrustedFilesProps) {
    const [insertTrustedFilesDialog, setInsertTrustedFilesDialog] = useState(false);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.insertTrustedFiles",
            () => ({
                button: [
                    "mark it as trusted",
                    "mark them as trusted"
                ],
                text: [
                    "If you believe this file is benign, you can {{markFilesAsTrustedButton}}. This will cause the finding to be closed.",
                    "If you believe these files are benign, you can {{markFilesAsTrustedButton}}. This will cause the finding to be closed."
                ]
            }));

    return (
        <Box>
            {insertTrustedFilesDialog &&
                <InsertTrustedFilesDialog
                    contentSha256Strings={contentSha256Strings}
                    tenantIds={[tenantId]}
                    onClose={() => setInsertTrustedFilesDialog(false)}/>}
            {localization.text(
                contentSha256Strings.length,
                {
                    markFilesAsTrustedButton:
                        <Typography
                            component="span"
                            sx={{
                                cursor: "pointer",
                                textDecoration: "underline"
                            }}
                            onClick={() => setInsertTrustedFilesDialog(true)}>
                            {localization.button(contentSha256Strings.length)}
                        </Typography>
                })}
        </Box>);
}