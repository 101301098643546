import { CopyToClipboard } from "@infrastructure";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import React, { useState } from "react";
import { CopyToClipboardIcon } from "../icons";

type CopyToClipboardTextProps = {
    copyValue?: string;
    sx?: SxProps;
    text: string;
    title?: string;
};

export function CopyToClipboardText({ copyValue, sx, text, title }: CopyToClipboardTextProps) {
    const [hover, setHover] = useState(false);
    return (
        <CopyToClipboard
            getValue={() => copyValue ?? text}
            title={title}>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    alignItems: "center",
                    cursor: "pointer"
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <Typography
                    noWrap={true}
                    sx={sx}>
                    {text}
                </Typography>
                <Box
                    sx={{
                        visibility:
                            hover
                                ? "visible"
                                : "hidden"
                    }}>
                    <CopyToClipboardIcon/>
                </Box>
            </Stack>
        </CopyToClipboard>);
}