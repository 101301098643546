﻿import { DataTableColumn, DataTableSortType, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, InlineEntities, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsCloudFrontDistributionStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsCloudFrontDistributionDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const cloudFrontDistributionStatusTranslator = useAwsCloudFrontDistributionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCloudFrontDistributionDefinition",
            () => ({
                columns: {
                    certificate: "Custom SSL Certificate",
                    origins: "Origins Resources",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsCloudFrontDistributionModel) => ({
                                [localization.columns.status()]: cloudFrontDistributionStatusTranslator((item.entity as Contract.AwsCloudFrontDistribution).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsCloudFrontDistributionStatus}
                                    enumTypeTranslator={(status: Contract.AwsCloudFrontDistributionStatus) => cloudFrontDistributionStatusTranslator(status)}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudFrontDistributionStatus}
                    itemProperty={(item: Contract.AwsCloudFrontDistributionModel) => cloudFrontDistributionStatusTranslator((item.entity as Contract.AwsCloudFrontDistribution).status)}
                    key={Contract.EntityModelProperty.AwsCloudFrontDistributionStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsCloudFrontDistributionModel) => ({
                                [localization.columns.origins()]:
                                    _(item.originIdToResourceIdReferenceMap).
                                        values().
                                        uniq().
                                        value().
                                        join("\n")
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsCloudFrontDistributionOrigins)}
                                    placeholder={localization.columns.origins()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudFrontDistributionOrigins}
                    key={Contract.EntityModelProperty.AwsCloudFrontDistributionOrigins}
                    render={
                        optionalTableCell<Contract.AwsCloudFrontDistributionModel>(
                            item =>
                                _.isEmpty(item.originIdToResourceIdReferenceMap)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={
                                            _(item.originIdToResourceIdReferenceMap).
                                                values().
                                                uniq().
                                                value()}
                                        entityTypeName={Contract.TypeNames.AwsResource}
                                        entityVariant="iconText"/>
                        )}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.origins()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsCloudFrontDistributionModel>(
                            Contract.TypeNames.AwsResource,
                            item => [(item as Contract.AwsCloudFrontDistributionModel).certificateIdReference],
                            localization.columns.certificate())
                    }
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsCloudFrontDistributionCertificate)}
                                    placeholder={localization.columns.certificate()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudFrontDistributionCertificate}
                    key={Contract.EntityModelProperty.AwsCloudFrontDistributionCertificate}
                    render={
                        optionalTableCell<Contract.AwsCloudFrontDistributionModel>(
                            item =>
                                (
                                    item.certificateIdReference == undefined
                                        ? undefined
                                        : <InlineEntities
                                            entityIdsOrModels={item.certificateIdReference}
                                            entityTypeName={Contract.TypeNames.AwsResource}
                                            entityVariant="iconText"
                                            variant="itemPlusItemCount"/>))}
                    selectorOptions={{ default: false }}
                    title={localization.columns.certificate()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsCloudFrontDistributionRequest(
                new Contract.EntityControllerGetEntityModelPageAwsCloudFrontDistributionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudFrontDistributionCertificate]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudFrontDistributionOrigins]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudFrontDistributionStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}