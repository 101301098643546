import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ListIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_10_3262)">
                <path d="M3 12C3 12.5523 3.44772 13 4 13C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11C3.44772 11 3 11.4477 3 12ZM3 16C3 16.5523 3.44772 17 4 17C4.55228 17 5 16.5523 5 16C5 15.4477 4.55228 15 4 15C3.44772 15 3 15.4477 3 16ZM3 8C3 8.55228 3.44772 9 4 9C4.55228 9 5 8.55228 5 8C5 7.44772 4.55228 7 4 7C3.44772 7 3 7.44772 3 8ZM7 12C7 12.5523 7.44772 13 8 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H8C7.44772 11 7 11.4477 7 12ZM7 16C7 16.5523 7.44772 17 8 17H20C20.5523 17 21 16.5523 21 16C21 15.4477 20.5523 15 20 15H8C7.44772 15 7 15.4477 7 16ZM8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9H20C20.5523 9 21 8.55228 21 8C21 7.44772 20.5523 7 20 7H8Z"/>
            </g>
            <defs>
                <clipPath id="clip0_10_3262">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}