import { Tooltip, useLocalization, useSetRoute } from "@infrastructure";
import { ToggleButton, ToggleButtonGroup, ToggleButtonProps } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, RiskStatusIcon, RiskTypeGroups } from "../../../../../../../common";

type TabsProps = {
    baseUrl: string;
    onChange: (newView: Contract.RisksView) => void;
    selectedGroupType: RiskTypeGroups;
    selectedView: Contract.RisksView;
};

export function Tabs({ baseUrl, onChange, selectedGroupType, selectedView }: TabsProps) {
    const localization =
        useLocalization(
            "views.customer.risks.items.tabs",
            () => ({
                tooltip: {
                    [Contract.RisksView.Closed]: "Closed",
                    [Contract.RisksView.Ignored]: "Ignored",
                    [Contract.RisksView.Open]: "Open"
                }
            }));
    const setRoute = useSetRoute();
    return (
        <ToggleButtonGroup
            exclusive={true}
            value={selectedView}
            onChange={
                (_event, view) => {
                    if (!_.isNil(view)) {
                        onChange(view);
                        setRoute(`${baseUrl}/${view}/${selectedGroupType}`);
                    }
                }}>
            <TooltipToggleButton
                title={localization.tooltip[Contract.RisksView.Open]()}
                value={Contract.RisksView.Open}>
                <RiskStatusIcon status={Contract.RiskStatus.Open}/>
            </TooltipToggleButton>
            <TooltipToggleButton
                title={localization.tooltip[Contract.RisksView.Ignored]()}
                value={Contract.RisksView.Ignored}>
                <RiskStatusIcon status={Contract.RiskStatus.Ignored}/>
            </TooltipToggleButton>
            <TooltipToggleButton
                title={localization.tooltip[Contract.RisksView.Closed]()}
                value={Contract.RisksView.Closed}>
                <RiskStatusIcon status={Contract.RiskStatus.Closed}/>
            </TooltipToggleButton>
        </ToggleButtonGroup>);
}

function TooltipToggleButton({ children, title, ...props }: ToggleButtonProps) {
    return (
        <Tooltip titleOrGetTitle={title}>
            <ToggleButton {...props}>
                {children}
            </ToggleButton>
        </Tooltip>);
}