﻿import { Optional, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Events } from "../../../../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, InlineIpAddresses, InlinePermissionActions, SeveritySquare, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../../../../common";

export function useGcpCommonBehaviorIdentitySectionElements(
    riskModel: Contract.GcpBehaviorIdentityRiskModel,
    riskInfoTranslator?: (info: Contract.GcpBehaviorIdentityRiskModelInfo) => Optional<string>) {
    const risk = riskModel.risk as Contract.GcpBehaviorIdentityRisk;

    return {
        context:
            <ContextSection
                riskInfoTranslator={riskInfoTranslator}
                riskModel={riskModel}/>,
        events: <Events riskId={risk.id}/>
    };
}

type GcpCommonBehaviorIdentityRiskContentProps = {
    riskInfoTranslator?: (info: Contract.GcpBehaviorIdentityRiskModelInfo) => Optional<string>;
    riskModel: Contract.GcpBehaviorIdentityRiskModel;
};

function ContextSection({ riskInfoTranslator, riskModel }: GcpCommonBehaviorIdentityRiskContentProps) {
    const behaviorIdentityRiskModel = riskModel as Contract.GcpBehaviorIdentityRiskModel;
    const identityModel = entityModelStore.useGet(behaviorIdentityRiskModel.risk.identityId) as Contract.EntityModel;
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.behavior.useGcpCommonBehaviorIdentitySectionsElements.infoSection",
            () => ({
                severity: {
                    title: "This finding has {{severitySquare}}**{{severity}}** severity since:",
                    [Contract.TypeNames.GcpBehaviorIdentityRiskSeverityInfo]: {
                        [Contract.GcpBehaviorIdentityRiskSeverityInfo.Default]: "All changes apply on a resource-level only, and none of the affected resources or granted permissions are sensitive",
                        [Contract.GcpBehaviorIdentityRiskSeverityInfo.SensitiveAction]: "Some of the granted permissions are sensitive",
                        [Contract.GcpBehaviorIdentityRiskSeverityInfo.SensitiveScopeResource]: "At least one of the affected resources is sensitive",
                        [Contract.GcpBehaviorIdentityRiskSeverityInfo.SensitiveWideScopeChildScopeResource]: "At least one of the resources in the project is sensitive",
                        [Contract.GcpBehaviorIdentityRiskSeverityInfo.SensitiveWideScopeScopeResource]: "This project is sensitive",
                        [Contract.GcpBehaviorIdentityRiskSeverityInfo.WideScope]: "Some of the changes apply on the entire project"
                    }
                },
                [Contract.TypeNames.GcpBehaviorIdentityRiskModelInfo]: {
                    [Contract.GcpBehaviorIdentityRiskModelInfo.EventActions]: "Actions: {{eventActions}}",
                    [Contract.GcpBehaviorIdentityRiskModelInfo.IpAddresses]: "Source IP addresses: {{ipAddresses}}",
                    [Contract.GcpBehaviorIdentityRiskModelInfo.PreviousBehaviorDateEver]: {
                        [Contract.TypeNames.GcpPermissionManagementRisk]: "{{translatedIdentityTypeName}} {{identity}} was not observed modifying similar permissions since: {{previousBehaviorDate | TimeFormatter.longDate}}"
                    },
                    [Contract.GcpBehaviorIdentityRiskModelInfo.PreviousBehaviorDateNever]: {
                        [Contract.TypeNames.GcpPermissionManagementRisk]: "{{translatedIdentityTypeName}} {{identity}} was not observed modifying similar permissions before"
                    },
                    [Contract.GcpBehaviorIdentityRiskModelInfo.Resources]: "Affected resources: {{resources}}"
                }
            }));
    const createInfoProps =
        () => ({
            eventActions:
                <InlinePermissionActions
                    permissionActions={behaviorIdentityRiskModel.risk.eventActions}
                    variant="itemOrItemCountAndType"/>,
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    variant="text"/>,
            ipAddresses: <InlineIpAddresses ipAddresses={behaviorIdentityRiskModel.risk.ipAddresses}/>,
            previousBehaviorDate: behaviorIdentityRiskModel.risk.previousBehaviorDate,
            resources:
                <InlineEntities
                    entityIdsOrModels={behaviorIdentityRiskModel.resourceIdReferences}
                    entityTypeName={Contract.TypeNames.GcpResource}
                    variant="itemAndTypeOrItemCountAndType"/>,
            translatedIdentityTypeName:
                entityTypeNameTranslator(
                    identityModel.entity.typeName,
                    {
                        includeServiceName: false
                    })
        });
    return (
        <Steps>
            {_<(React.ReactNode | Step)>([]).
                concat(
                    _.map(
                        behaviorIdentityRiskModel.infos,
                        info => {
                            const translatedRiskInfo = riskInfoTranslator?.(info);
                            if (!_.isNil(translatedRiskInfo)) {
                                return translatedRiskInfo;
                            }

                            switch (info) {
                                case Contract.GcpBehaviorIdentityRiskModelInfo.PreviousBehaviorDateEver:
                                case Contract.GcpBehaviorIdentityRiskModelInfo.PreviousBehaviorDateNever:
                                    return localization[Contract.TypeNames.GcpBehaviorIdentityRiskModelInfo][info][(behaviorIdentityRiskModel.risk.typeName as BehaviorRiskTypeNames)](createInfoProps());
                                default:
                                    return localization[Contract.TypeNames.GcpBehaviorIdentityRiskModelInfo].translate(info, createInfoProps());
                            }
                        })).
                concat(
                    new Step(
                        localization.severity.title({
                            severity:
                                severityTranslator(riskModel.risk.severity, "text"),
                            severitySquare:
                                <SeveritySquare severity={riskModel.risk.severity}/>
                        }),
                        {
                            contentElement:
                                <Steps variant="bullets">
                                    {_.map(
                                        riskModel.risk.severityInfos,
                                        severityInfo => localization.severity[Contract.TypeNames.GcpBehaviorIdentityRiskSeverityInfo][severityInfo]())}
                                </Steps>
                        })).
                value()}
        </Steps>);
}

type BehaviorRiskTypeNames = Contract.TypeNames.GcpPermissionManagementRisk;