import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function KentikIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2638)">
                <path
                    d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
                    fill="#E05420"/>
                <path
                    d="M17.5667 11.3047C17.2696 11.0407 16.9065 10.8756 16.5104 10.8756C16.1143 10.8756 15.7182 11.0076 15.4541 11.3047C15.1571 11.6018 14.992 11.9649 15.025 12.394C15.025 12.7901 15.1571 13.1862 15.4541 13.4503C15.7512 13.7144 16.1143 13.8794 16.5104 13.8464C16.9065 13.8464 17.2696 13.7144 17.5667 13.4503C17.8638 13.1862 18.0288 12.7901 17.9958 12.394C18.0288 11.9649 17.8638 11.5688 17.5667 11.3047Z"
                    fill="white"/>
                <path
                    d="M12.2193 12.361L17.3687 5.49514C17.1376 5.3631 16.9065 5.23106 16.6425 5.16505C16.3454 5.06602 16.0483 5 15.7512 5C15.3221 5 14.926 5.06602 14.5299 5.23106C14.1668 5.42912 13.8367 5.69319 13.5726 6.05629L8.88534 12.361L13.5726 18.6657C13.8367 18.9958 14.1668 19.2929 14.5299 19.491C14.926 19.656 15.3221 19.755 15.7512 19.722C16.0483 19.722 16.3454 19.689 16.6425 19.59C16.9065 19.524 17.1376 19.3919 17.3687 19.2599L12.2193 12.361Z"
                    fill="white"/>
                <path
                    d="M12.4833 5.49514C12.2523 5.3631 12.0212 5.23106 11.7571 5.16505C11.4931 5.06602 11.163 5 10.8659 5C10.4368 5 10.0407 5.06602 9.64455 5.23106C9.28145 5.42912 8.95136 5.69319 8.68728 6.05629L4 12.361L8.68728 18.6657C8.95136 18.9958 9.28145 19.2929 9.64455 19.491C10.0407 19.656 10.4368 19.755 10.8659 19.722C11.163 19.722 11.46 19.689 11.7571 19.59C12.0212 19.524 12.2523 19.3919 12.4833 19.2599L7.33391 12.361L12.4833 5.49514Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2638">
                    <rect
                        fill="white"
                        height="24"
                        width="23.9962"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}