import { Contract } from "../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../hooks";
import { EntityDefinition } from "../useDefinition";

export function useAzureAuthorizationRoleManagementPolicyAssignmentDefinition(entityModel: Contract.EntityModel) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const roleManagementPolicyAssignmentModel = entityModel as Contract.AzureAuthorizationRoleManagementPolicyAssignmentModel;

    return new EntityDefinition(
        entityModel,
        {
            displayName:
                roleManagementPolicyAssignmentModel.unknown
                    ? `${roleManagementPolicyAssignmentModel.entity.displayName}`
                    : `${roleManagementPolicyAssignmentModel.entity.displayName} (${entityTypeNameTranslator(roleManagementPolicyAssignmentModel.scopeResourceTypeName)})`
        });
}