import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { ActionsCell, IntegrationsCell, NameCell, ScopesCell, useRiskAutomationsContext } from "../../..";
import { Contract, Severity, StorageHelper, TypeHelper, useScopeNameTranslator, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { useSetRiskAutomationsContext } from "../../RiskAutomations";
import { EntityAttributeCell, RiskPoliciesCell } from "./components";

type TableProps = {
    ruleModels: Contract.ScopeSystemEntityModel[];
};

export function Table({ ruleModels }: TableProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setRiskAutomationsContext = useSetRiskAutomationsContext();
    const { childScopeEnabled } = useRiskAutomationsContext();

    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.automations.riskAutomations.table",
            () => ({
                actions: {
                    add: "Add Rule",
                    childScopeEnabled: "Flat View"
                },
                columns: {
                    entityAttributes: "Labels",
                    integrations: "Integrations",
                    riskPolicies: "Policies",
                    rule: "Rule",
                    scope: "Scope",
                    scopes: "Targets",
                    severity: "Severity"
                }
            }));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [ruleModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{ resizable: true }}
            fetchItems={
                () =>
                    _(ruleModels).
                        map(ruleModel => (ruleModel.configuration as Contract.RiskAutomationRuleConfiguration)).
                        orderBy(item => StringHelper.getSortValue(item.name)).
                        value()}
            getItemId={item => item.id}
            sortOptions={{ enabled: false }}>
            <DataTableAction>
                <CheckButton
                    checked={childScopeEnabled}
                    title={localization.actions.childScopeEnabled()}
                    onCheckedChanged={
                        checked => {
                            StorageHelper.customerConfigurationAutomationsRiskFlatView.setValue(checked);
                            setRiskAutomationsContext(
                                context => ({
                                    ...context,
                                    childScopeEnabled: checked
                                }));
                        }}/>
            </DataTableAction>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={
                        () =>
                            setRiskAutomationsContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: true
                                }))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Rule}
                render={NameCell}
                title={localization.columns.rule()}/>
            <DataTableColumn
                id={TableColumnId.Scope}
                itemProperty={(item: Contract.RiskAutomationRuleConfiguration) => scopeNameTranslator(item.scopeId, { path: true })}
                title={localization.columns.scope()}/>
            {!TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.TenantConfiguration) && (
                <DataTableColumn
                    id={TableColumnId.Tenants}
                    render={ScopesCell}
                    title={localization.columns.scopes()}/>)}
            <DataTableColumn
                id={TableColumnId.RiskPolicies}
                render={RiskPoliciesCell}
                title={localization.columns.riskPolicies()}/>
            <DataTableColumn
                id={TableColumnId.Severity}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.RiskAutomationRuleConfiguration>) =>
                        <Severity severity={item.minSeverity}/>}
                title={localization.columns.severity()}/>
            <DataTableColumn
                id={TableColumnId.EntityAttributes}
                render={EntityAttributeCell}
                title={localization.columns.entityAttributes()}/>
            <DataTableColumn
                id={TableColumnId.Integrations}
                render={IntegrationsCell}
                title={localization.columns.integrations()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.RiskAutomationRuleConfiguration>) =>
                        <ActionsCell
                            automationRuleConfiguration={item}
                            onEdit={
                                () =>
                                    setRiskAutomationsContext(
                                        automationContext => ({
                                            ...automationContext,
                                            addOrEditOpen: item
                                        }))}/>}
                resizable={false}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    EntityAttributes = "entityAttributes",
    Integrations = "integrations",
    RiskPolicies = "riskPolicies",
    Rule = "rule",
    Scope = "scope",
    Severity = "severity",
    Tenants = "tenants"
}