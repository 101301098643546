import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useCiDefaultEntityInfoItemElements } from "./useCiDefaultEntityInfoItemElements";

export function useCiContainerImageRepositoryDefinition(entityModel: Contract.CiEntityModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(entityModel);
    const defaultTenantInfoItemElements = useCiDefaultEntityInfoItemElements(entityModel);
    const repository = entityModel.entity as Contract.CiContainerImageRepository;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.ci.useCiContainerImageRepositoryDefinition",
            () => ({
                info: {
                    items: {
                        containerImageCount: "Image Count",
                        rawUrl: "URI"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultTenantInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawUri"
                        location="all"
                        title={localization.info.items.rawUrl()}
                        value={repository.rawUrl}/>,
                    <InfoItem
                        key="artifactCount"
                        location="all"
                        title={localization.info.items.containerImageCount()}
                        value={repository.containerImageCount}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}