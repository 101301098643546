﻿import { ActionMenuItem, useLocalization, useSetRoute } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, scopeNodeModelStore } from "../../../../../../../../common";
import { AddOrEditDefinition } from "../../useAddOrEditDefinition";
import { Welcome } from "./components";

export function useOciAddOrEditDefinition(): AddOrEditDefinition {
    const tenantTypes =
            useMemo(
                () => [Contract.TenantType.Oci],
                []);
    const activeScopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(tenantTypes);
    const setRoute = useSetRoute();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOciDefinition.useOciAddOrEditDefinition",
            () => ({
                addOrganization: "Add tenancy"
            }));

    return useMemo(
        () => ({
            addDisabled: true,
            additionalActionItems:
                () => [
                    new ActionMenuItem(
                        () => setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsOciRelativeUrl(true)),
                        localization.addOrganization())
                ],
            welcome:
                _(activeScopeNodeMap).
                    filter(
                        scopeNode =>
                            !ScopeHelper.isRootFolder(scopeNode.scopeNodeModel) &&
                            scopeNode.scopeNodeModel.configuration.id !== ScopeHelper.customerId).
                    isEmpty()
                    ? <Welcome/>
                    : undefined
        }),
        [activeScopeNodeMap, localization]);
}