import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useOpCommonKubernetesResourceInfoItemElements } from "./useOpCommonKubernetesResourceInfoItemElements";

export function useOpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel: Contract.OpResourceModel) {
    const commonResourceInfoElements = useOpCommonKubernetesResourceInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.OpKubernetesResource<Contract.KubernetesNamespaceResourceData>;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpCommonKubernetesNamespaceResourceInfoItemElements",
            () => ({
                namespace: "Namespace"
            }));

    return {
        ...commonResourceInfoElements,
        namespaceInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={resource.data.namespaceId}
                entityTypeName={Contract.TypeNames.GcpKubernetesNamespace}
                key="namespaceId"
                location="all"
                title={localization.namespace()}/>
    };
}