import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { ConfirmButton, DeleteIcon, useLocalization, useSelectionActionsContext } from "@infrastructure";
import { ConfigurationController, Contract, FolderController, InlineScopes, ScopeHelper, ScopeNode, scopeNodeModelStore, TenantController, tenantModelStore, UserHelper, useSelectedScopeId } from "../../../../../../../common";
import { useScopesContext } from "../../../Scopes";

type DeleteSelectionActionProps = {
    resetSelectedItemIds: () => void;
    scopeNodeMap: Dictionary<ScopeNode>;
    scopeTypeName?: string;
};

export function DeleteSelectionAction({ resetSelectedItemIds, scopeNodeMap, scopeTypeName }: DeleteSelectionActionProps) {
    const { definition } = useScopesContext();
    const { actionEnded, actionExecuting, actionStarted, itemIds } = useSelectionActionsContext();
    const { selectedScopeId } = useSelectedScopeId();

    const localization =
        useLocalization(
            "views.customer.scopes.table.deleteSelectionAction",
            () => ({
                error: "Failed to delete",
                errorMessage: {
                    accounts: "accounts",
                    itemHasNoPermissions: "There are missing permissions for some of the selected folders/accounts.",
                    itemsIncludeSelectedScope: "One or more folders/accounts can't be deleted because they are currently selected in the scope dropdown. Choose a different scope and try again.",
                    notEmptyFolderExists: "Some of the selected folders contain {{scopeTypeName}} in their sub folders. Please delete those {{scopeTypeName}} before you can delete the entire folder.",
                    title: "OK"
                },
                message: "Are you sure you want to delete {{scopes}}?",
                title: "Delete"
            }));

    const [deleteScopeDisabledMessages, itemsDeleted, itemHasNoPermissions, notEmptyFolderExists, itemsIncludeSelectedScope] =
        useMemo(
            () => {
                const itemsDeleted =
                    _.every(
                        itemIds,
                        itemId => scopeNodeMap[itemId]?.scopeNodeModel.configuration.systemDeleted);

                const itemHasNoPermissions =
                    _.some(
                        itemIds,
                        itemId =>
                            !UserHelper.hasScopePermissions(
                                itemId,
                                Contract.IdentityPermission.SecurityAdministrationRead));

                const deleteScopeDisabledMessages =
                    _(itemIds).
                        filter(itemId => !!scopeNodeMap[itemId]?.scopeNodeModel).
                        map(itemId => definition.getDeleteScopeDisabledMessage?.(true, scopeNodeMap[itemId].scopeNodeModel)).
                        filter().
                        value();

                const notEmptyFolderExists =
                    !_(itemIds).
                        map(itemId => scopeNodeMap[itemId]).
                        filter(
                            scopeNode =>
                                !_.isNil(scopeNode) &&
                                ScopeHelper.isFolder(scopeNode.scopeNodeModel)).
                        flatMap(folderNode => folderNode.childScopeIds).
                        isEmpty();

                const itemsIncludeSelectedScope = _.some(itemIds, itemId => selectedScopeId === itemId);

                return [deleteScopeDisabledMessages, itemsDeleted, itemHasNoPermissions, notEmptyFolderExists, itemsIncludeSelectedScope];
            },
            [itemIds, scopeNodeMap, selectedScopeId]);

    async function deleteScopes() {
        actionStarted(false);

        try {
            const [folderIds, scopeIds] =
                _.partition(
                    itemIds,
                    itemId => ScopeHelper.isFolder(scopeNodeMap[itemId].scopeNodeModel));
            const [projectScopeIds, tenantIds] =
                _.partition(
                    scopeIds,
                    itemId => ScopeHelper.isProject(scopeNodeMap[itemId].scopeNodeModel));
            if (!_.isEmpty(folderIds) || !_.isEmpty(projectScopeIds)) {
                for (const folderId of folderIds) {
                    await FolderController.deleteFolder(new Contract.FolderControllerDeleteFolderRequest(folderId));
                }

                for (const projectScopeId of projectScopeIds) {
                    await ConfigurationController.deleteProject(new Contract.ConfigurationControllerDeleteProjectRequest(projectScopeId));
                }

                await scopeNodeModelStore.notifyDeleted(
                    _(projectScopeIds).
                        concat(folderIds).
                        flatMap(scopeId => scopeNodeMap[scopeId].scopeIds).
                        value());
            }

            if (!_.isEmpty(tenantIds)) {
                for (const tenantId of tenantIds) {
                    await TenantController.deleteTenant(new Contract.TenantControllerDeleteTenantRequest(tenantId));
                }

                await tenantModelStore.notify(tenantIds);
            }

            actionEnded();
            resetSelectedItemIds();
        } catch {
            actionEnded(localization.error());
        }
    }

    const deleteDisabledTooltip =
        useMemo(
            () => {
                if (!_.isEmpty(deleteScopeDisabledMessages)) {
                    return deleteScopeDisabledMessages[0];
                } else if (notEmptyFolderExists) {
                    return localization.errorMessage.notEmptyFolderExists({
                        scopeTypeName: scopeTypeName ?? localization.errorMessage.accounts()
                    });
                } else if (itemHasNoPermissions) {
                    return localization.errorMessage.itemHasNoPermissions();
                } else if (itemsIncludeSelectedScope) {
                    return localization.errorMessage.itemsIncludeSelectedScope();
                }

                return undefined;
            },
            [deleteScopeDisabledMessages, notEmptyFolderExists, itemHasNoPermissions, itemsIncludeSelectedScope]);

    return (
        <ConfirmButton
            disabled={
                itemsDeleted ||
                actionExecuting ||
                !_.isEmpty(deleteScopeDisabledMessages) ||
                itemHasNoPermissions ||
                notEmptyFolderExists ||
                itemsIncludeSelectedScope}
            icon={<DeleteIcon/>}
            message={
                localization.message({
                    scopes:
                        <InlineScopes
                            scopeIds={itemIds}
                            variant="itemOrItemCountAndType"/>
                })}
            tooltip={deleteDisabledTooltip}
            variant="text"
            onClick={() => deleteScopes()}>
            {localization.title()}
        </ConfirmButton>);
}