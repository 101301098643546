import { Link, Message, Step, Steps, UnexpectedError, useLocalization } from "@infrastructure";
import { Divider, Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { RiskContentProps } from "../../../../..";
import { Change, Contract, Entity, entityModelStore, InlineEntities, InlineGroupIdentities, MultiLineEllipsis, SeveritySquare, TypeHelper, useEntityTypeNameTranslator, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../common";
import { GciConsoleUrlBuilder } from "../../../../../../../../../../../../../tenants";
import { RiskView } from "../../../../../../../../../utilities";
import { ChangeStep, makeAccessResolutionSectionDefinitionComponent, RiskDefinitionSection, RiskDefinitionSectionCategory } from "../../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../../components";
import { useGcpCommonAccessPrincipalRiskDefinition } from "../useGcpCommonAccessPrincipalRiskDefinition";
import { GenerateNonexcessiveRole, ReplaceRoleBindingsChangeDiff, ResolutionCategoryViewLink, RoleBindingTable } from "./components";

export function useGcpExcessivePermissionPrincipalRiskDefinition(riskModel: Contract.RiskModel) {
    const excessivePermissionPrincipalRiskModel = riskModel as Contract.GcpExcessivePermissionPrincipalRiskModel;
    const principalModel = entityModelStore.useGet(excessivePermissionPrincipalRiskModel.risk.entityId) as Contract.EntityModel;
    const tenantEntityModel = entityModelStore.useGet(excessivePermissionPrincipalRiskModel.risk.tenantId) as Contract.EntityModel;

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpExcessivePermissionPrincipalRiskDefinition",
            () => ({
                description: "{{principal}} has {{severitySquare}}**{{excessivePermissionActionSeverity}}** severity permissions that can be removed",
                sections: {
                    roleBindingTable: {
                        helpText: "This table displays {{principal}}’s bindings on project {{tenantId}} only. {{principal}} may also have bindings through groups and permissions on other projects.",
                        title: "Direct bindings of {{principal}} on project {{tenantId}}"
                    }
                }
            }));

    return useGcpCommonAccessPrincipalRiskDefinition(
        <ContextSection riskModel={riskModel}/>,
        localization.description({
            excessivePermissionActionSeverity: severityTranslator(excessivePermissionPrincipalRiskModel.risk.excessivePermissionActionSeverity, "text"),
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            severitySquare:
                <SeveritySquare severity={excessivePermissionPrincipalRiskModel.risk.excessivePermissionActionSeverity}/>
        }),
        riskModel,
        undefined,
        {
            resolutionSectionDefinitionComponent,
            sections: [
                _.isEmpty(excessivePermissionPrincipalRiskModel.risk.roleBindingIdToDataMap)
                    ? undefined!
                    : new RiskDefinitionSection(
                        <RoleBindingTable
                            csvExportFilePrefixes={[localization.sections.roleBindingTable.title({
                                principal: principalModel.entity.displayName,
                                tenantId: tenantEntityModel.entity.displayName
                            })]}
                            risk={excessivePermissionPrincipalRiskModel.risk}/>,
                        localization.sections.roleBindingTable.title({
                            principal:
                                <Entity
                                    entityIdOrModel={principalModel}
                                    variant="text"/>,
                            tenantId:
                                <Entity
                                    entityIdOrModel={excessivePermissionPrincipalRiskModel.risk.tenantId}
                                    linkOptions={{ disabled: true }}
                                    variant="typeText"/>

                        }),
                        {
                            actionsElement:
                                <Message
                                    level="info"
                                    title={
                                        localization.sections.roleBindingTable.helpText({
                                            principal:
                                                <Entity
                                                    entityIdOrModel={principalModel}
                                                    variant="text"/>,
                                            tenantId:
                                                <Entity
                                                    entityIdOrModel={excessivePermissionPrincipalRiskModel.risk.tenantId}
                                                    linkOptions={{ disabled: true }}
                                                    variant="text"/>
                                        })}
                                    variant="minimal"/>
                        }
                    ),
                _.some(
                    excessivePermissionPrincipalRiskModel.risk.excessiveScopeRoleBindingDatas,
                    excessiveScopeRoleBindingData => excessiveScopeRoleBindingData.resolution === Contract.GcpExcessivePermissionPrincipalRiskRoleBindingResolution.Replace)
                    ? new RiskDefinitionSection(
                        <CustomResolutionSection riskModel={riskModel}/>,
                        "",
                        {
                            categoryView: {
                                category: RiskDefinitionSectionCategory.Resolution,
                                view:
                                    excessivePermissionPrincipalRiskModel.risk.excessiveScopeRoleBindingDatas.length > 1
                                        ? RiskView.ResolutionGcpCustomRoles
                                        : RiskView.ResolutionGcpCustomRole
                            }
                        })
                    : undefined!
            ]
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const excessivePermissionPrincipalRiskModel = riskModel as Contract.GcpExcessivePermissionPrincipalRiskModel;
    const principalModel = entityModelStore.useGet(excessivePermissionPrincipalRiskModel.risk.entityId) as Contract.EntityModel;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const riskSeverityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpExcessivePermissionPrincipalRiskDefinition.contextSection",
            () => ({
                [Contract.TypeNames.GcpExcessivePermissionPrincipalRiskModelInfo]: {
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.CreationTime]: "*capitalize*{{translatedPrincipalTypeName}}** {{principal}} was created {{principalCreationTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.ExcessiveDirectMembershipGroupIds]: "The {{translatedPrincipalTypeName}} is a member of {{excessiveDirectMembershipGroupIds}} granting it {{excessiveDirectMembershipGroupsPermissionActionSeveritySquare}}**{{excessiveDirectMembershipGroupsPermissionActionSeverity}}** severity permissions that can be removed based on the usage in the last {{excessivePermissionEvaluationStartDate | TimeFormatter.humanizeDuration}}",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.ExcessiveRoleBindingIds]: {
                        [Contract.TypeNames.GciDirectoryGroup]: "The {{translatedPrincipalTypeName}} has {{excessiveRoleBindingIds}} granting its {{groupIdentityIds}} {{excessiveRoleBindingsPermissionActionSeveritySquare}}**{{excessiveRoleBindingsPermissionActionSeverity}}** severity permissions that can be removed based on the usage in the last {{excessivePermissionEvaluationStartDate | TimeFormatter.humanizeDuration}}",
                        [Contract.TypeNames.IGciPrincipal]: "The {{translatedPrincipalTypeName}} has {{excessiveRoleBindingIds}} granting it {{excessiveRoleBindingsPermissionActionSeveritySquare}}**{{excessiveRoleBindingsPermissionActionSeverity}}** severity permissions that can be removed based on the usage in the last {{excessivePermissionEvaluationStartDate | TimeFormatter.humanizeDuration}}"
                    },
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.ExternalPrincipal]: "The {{translatedPrincipalTypeName}} is external to the organization",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.GroupMembers]: "The {{translatedPrincipalTypeName}} has {{groupIdentityIds}}",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.ServiceAccountOriginatorResources]: "The service account is linked to {{originatorResourceIds}}",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.ServiceAccountOriginatorResourcesPublicAccessAll]: "The service account is linked to {{originatorResourceIds}}, all are public compute resources",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.ServiceAccountOriginatorResourcesPublicAccessSingle]: "The service account is linked to {{originatorResourceIds}}, {{publicAccessOriginatorResourceIds}} is a public compute resource",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.ServiceAccountOriginatorResourcesPublicAccessSome]: "The service account is linked to {{originatorResourceIds}}, {{publicAccessOriginatorResourceIds}} are public compute resources",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.SignInNever]: "The {{translatedPrincipalTypeName}} was not active since it was created",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.SignInTime]: "The {{translatedPrincipalTypeName}} was last active {{signInActivityTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.SignInTimeMissingCreationTime]: "*capitalize*{{translatedPrincipalTypeName}}** {{principal}} was last active {{signInActivityTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.SignInTimeNotExact]: "The {{translatedPrincipalTypeName}} was last active more than {{signInActivityTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.SignInTimeNotExactMissingCreationTime]: "*capitalize*{{translatedPrincipalTypeName}}** {{principal}} was last active more than {{signInActivityTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.SignInUnknown]: "The {{translatedPrincipalTypeName}} was not seen active",
                    [Contract.GcpExcessivePermissionPrincipalRiskModelInfo.UserMfaNotEnabled]: "The user has no MFA enabled"
                }
            }));
    const publicAccessOriginatorResourceIds =
        _(excessivePermissionPrincipalRiskModel.risk.serviceAccountOriginatorResourceIdToPublicMap).
            pickBy((publicAccess, _) => publicAccess).
            keys().
            value();

    const createInfoProps =
        () => ({
            excessiveDirectMembershipGroupIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupIds}
                    entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                    variant="itemCountAndType"/>,
            excessiveDirectMembershipGroupsPermissionActionSeverity:
                _.isNil(excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupsPermissionActionSeverity)
                    ? undefined
                    : severityTranslator(excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupsPermissionActionSeverity!, "text"),
            excessiveDirectMembershipGroupsPermissionActionSeveritySquare:
                _.isNil(excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupsPermissionActionSeverity)
                    ? undefined
                    : <SeveritySquare severity={excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupsPermissionActionSeverity!}/>,
            excessivePermissionEvaluationStartDate: excessivePermissionPrincipalRiskModel.risk.excessivePermissionEvaluationStartDate,
            excessiveRoleBindingIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPrincipalRiskModel.risk.excessiveRoleBindingIds}
                    entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                    variant="itemCountAndType"/>,
            excessiveRoleBindingsPermissionActionSeverity:
                _.isNil(excessivePermissionPrincipalRiskModel.risk.excessiveRoleBindingsPermissionActionSeverity)
                    ? undefined
                    : severityTranslator(excessivePermissionPrincipalRiskModel.risk.excessiveRoleBindingsPermissionActionSeverity!, "text"),
            excessiveRoleBindingsPermissionActionSeveritySquare:
                _.isNil(excessivePermissionPrincipalRiskModel.risk.excessiveRoleBindingsPermissionActionSeverity)
                    ? undefined
                    : <SeveritySquare severity={excessivePermissionPrincipalRiskModel.risk.excessiveRoleBindingsPermissionActionSeverity!}/>,
            groupIdentityIds:
                <InlineGroupIdentities
                    identityIds={excessivePermissionPrincipalRiskModel.risk.groupIdentityIds}
                    identityTypeName={Contract.TypeNames.IGciIdentity}/>,
            originatorResourceIds:
                <InlineEntities
                    entityIdsOrModels={_.keys(excessivePermissionPrincipalRiskModel.risk.serviceAccountOriginatorResourceIdToPublicMap)}
                    entityTypeName={Contract.TypeNames.GcpScopeResource}
                    variant="itemCountAndType"/>,
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    variant="text"/>,
            principalCreationTime: excessivePermissionPrincipalRiskModel.principalCreationTime,
            publicAccessOriginatorResourceIds:
                <InlineEntities
                    entityIdsOrModels={publicAccessOriginatorResourceIds}
                    entityTypeName={Contract.TypeNames.GcpScopeResource}
                    entityVariant="iconTextTypeTenant"
                    variant="itemAndTypeOrItemCountAndType"/>,
            signInActivityTime: excessivePermissionPrincipalRiskModel.risk.signInActivity?.time,
            translatedPrincipalTypeName:
                entityTypeNameTranslator(
                    principalModel.entity.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    })
        });

    return (
        <Steps>
            {_((riskModel as Contract.GcpExcessivePermissionPrincipalRiskModel).infos).
                map(info => {
                    if (info === Contract.GcpExcessivePermissionPrincipalRiskModelInfo.ExcessiveRoleBindingIds) {
                        const principalType =
                            principalModel.entity.typeName === Contract.TypeNames.GciDirectoryGroup
                                ? Contract.TypeNames.GciDirectoryGroup
                                : Contract.TypeNames.IGciPrincipal;
                        return localization[Contract.TypeNames.GcpExcessivePermissionPrincipalRiskModelInfo][info][principalType](createInfoProps());
                    } else {
                        return localization[Contract.TypeNames.GcpExcessivePermissionPrincipalRiskModelInfo][info](createInfoProps());
                    }
                }).
                concatIf(
                    !_.isNil(excessivePermissionPrincipalRiskModel.risk.severityReason),
                    () => riskSeverityReasonTranslator(
                        excessivePermissionPrincipalRiskModel.risk.severity,
                        excessivePermissionPrincipalRiskModel.risk.severityReason!,
                        { excessivePermissionActionSeverity: severityTranslator(excessivePermissionPrincipalRiskModel.risk.excessivePermissionActionSeverity, "text") })).
                value()}
        </Steps>);
}

const resolutionSectionDefinitionComponent =
    makeAccessResolutionSectionDefinitionComponent(
        riskModel => {
            const excessivePermissionPrincipalRisk = riskModel.risk as Contract.GcpExcessivePermissionPrincipalRisk;
            const principalModel = entityModelStore.useGet(excessivePermissionPrincipalRisk.entityId);

            const entityModels =
                entityModelStore.useGet(
                    _.flatMap(
                        excessivePermissionPrincipalRisk.resolutionChanges,
                        resolutionChange => resolutionChange.entityIds));
            const entityModelMap =
                _.keyBy(
                    entityModels,
                    entityModel => entityModel.id);

            const entityTypeNameTranslator = useEntityTypeNameTranslator();
            const localization =
                useLocalization(
                    "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpExcessivePermissionPrincipalRiskDefinition.resolutionSection",
                    () => ({
                        steps: {
                            createRoleBinding: "Add a role binding with {{principal}} and {{resolutionRoleId}}",
                            deleteRoleBinding: {
                                text: {
                                    removeAll: "Select the principal and click on **REMOVE ACCESS** at the top",
                                    removeOne: "Remove the role binding with principal **{{principalMail}}** and {{role}}"
                                },
                                title: "Remove {{principal}}‘s IAM bindings assigned to {{scopeResourceId}} {{scopeResourceTranslatedTypeName}}:"
                            },
                            entityExternalConsoleLink: "{{entityExternalConsoleLink}} and filter on **Principal:{{principalMail}}**",
                            permissionActionGroups: {
                                many: {
                                    step1: {
                                        link: "Group memberships page",
                                        text: "In Google Admin Portal, open the {{groupsLink}} of {{principal}}"
                                    },
                                    title: "Remove {{principal}}’s group memberships"
                                },
                                one: {
                                    step1: {
                                        link: "Members page",
                                        text: "In Google Admin Portal, open the {{groupMembersLink}} of group {{groupId}}"
                                    },
                                    step2: "Remove {{translatedPrincipalTypeName}} **{{principalMail}}** from the members list"
                                }
                            },
                            replaceRoleBinding: "Replace {{roleIds}} with {{resolutionRoleIds}} assigned to {{scopeResourceTranslatedTypeName}} {{scopeResourceId}}:"
                        }
                    }));

            const createPrincipalResolutionProps =
                () => ({
                    principal:
                        <Entity
                            entityIdOrModel={principalModel}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>,
                    principalMail: excessivePermissionPrincipalRisk.principalMail,
                    translatedPrincipalTypeName: entityTypeNameTranslator(
                        principalModel.entity.typeName,
                        {
                            includeServiceName: false,
                            variant: "text"
                        })
                });
            const createScopeResolutionProps =
                (scopeResourceId: string) => ({
                    scopeResourceId:
                        <Entity
                            entityIdOrModel={scopeResourceId}
                            linkOptions={{ disabled: true }}
                            variant="text"/>,
                    scopeResourceTranslatedTypeName:
                        entityTypeNameTranslator(
                            entityModelMap[scopeResourceId].entity.typeName,
                            {
                                variant: "text"
                            })
                });
            const createResolutionRoleResolutionProps =
                (resolutionRoleId: string) => ({
                    resolutionRoleId:
                        <Entity
                            entityIdOrModel={resolutionRoleId}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>
                });
            const createResolutionRolesResolutionProps =
                (resolutionRoleIds: string[]) => ({
                    resolutionRoleIds:
                        <InlineEntities
                            entityIdsOrModels={resolutionRoleIds}
                            entityLinkOptions={{ disabled: true }}
                            entityTypeName={Contract.TypeNames.GcpIamRole}
                            variant="itemAndTypeOrItemCountAndType"/>
                });
            const createDeleteRoleBindingResolutionProps =
                (roleBindingId: string) => ({
                    role:
                        <Entity
                            entityIdOrModel={(entityModelMap[roleBindingId].entity as Contract.GcpIamRoleBinding).roleId}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>
                });
            const createDeleteRoleBindingsResolutionProps =
                (deleteRoleBindingChanges: Contract.GcpDeleteRoleBindingChange[]) => ({
                    roleIds:
                        <InlineEntities
                            entityGlanceOptions={{ disabled: true }}
                            entityIdsOrModels={
                                _.map(
                                    deleteRoleBindingChanges,
                                    deleteRoleBindingChange => (entityModelMap[deleteRoleBindingChange.resourceId].entity as Contract.GcpIamRoleBinding).roleId)}
                            entityLinkOptions={{ disabled: true }}
                            entityTypeName={Contract.TypeNames.GcpIamRole}
                            variant="itemAndTypeOrItemCountAndType"/>
                });

            function getGciDeletePrincipalGroupMembershipChangeStep(gciDeletePrincipalGroupMembershipChange: Contract.GciDeletePrincipalGroupMembershipChange) {
                const groupModel = entityModelMap[gciDeletePrincipalGroupMembershipChange.groupId] as Contract.GciDirectoryGroupModel;
                const group = groupModel.entity as Contract.GciDirectoryGroup;

                return new ChangeStep(
                    gciDeletePrincipalGroupMembershipChange,
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {[
                                    localization.steps.permissionActionGroups.one.step1.text({
                                        groupId:
                                            <Entity
                                                entityIdOrModel={gciDeletePrincipalGroupMembershipChange.groupId}
                                                linkOptions={{ disabled: true }}
                                                variant="text"/>,
                                        groupMembersLink:
                                            <Link
                                                urlOrGetUrl={GciConsoleUrlBuilder.GetGroupMembersUrl(group.uniqueId)}
                                                variant="external">
                                                {localization.steps.permissionActionGroups.one.step1.link()}
                                            </Link>
                                    }),
                                    localization.steps.permissionActionGroups.one.step2({
                                        ...createPrincipalResolutionProps()
                                    })
                                ]}
                            </Steps>
                    });
            }

            function getGciDeletePrincipalGroupMembershipsChangeStep(changeSet: Contract.ChangeSet) {
                const deletePrincipalGroupMembershipChanges = changeSet.changes as Contract.GciDeletePrincipalGroupMembershipChange[];
                return new Step(
                    localization.steps.permissionActionGroups.many.title({
                        principal:
                            <Entity
                                entityIdOrModel={principalModel}
                                glanceOptions={{ disabled: true }}
                                linkOptions={{ disabled: true }}
                                variant="text"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {[
                                    localization.steps.permissionActionGroups.many.step1.text({
                                        ...createPrincipalResolutionProps(),
                                        groupsLink:
                                            principalModel.unknown
                                                ? localization.steps.permissionActionGroups.many.step1.link()
                                                : <Link
                                                    urlOrGetUrl={GciConsoleUrlBuilder.GetUserGroupsUrl((principalModel.entity as Contract.GciDirectoryUser).uniqueId)}
                                                    variant="external">
                                                    {localization.steps.permissionActionGroups.many.step1.link()}
                                                </Link>
                                    }),
                                    ..._.map(
                                        deletePrincipalGroupMembershipChanges,
                                        deletePrincipalGroupMembershipChange =>
                                            <Change
                                                change={deletePrincipalGroupMembershipChange}
                                                key={deletePrincipalGroupMembershipChange.id}/>)
                                ]}
                            </Steps>
                    });
            }

            function getRoleBindingChangeStep(
                title: ReactNode,
                translatedGcpConsoleExternalLink: string,
                actionElement?: ReactNode,
                createRoleBindingChanges?: Contract.GcpCreateRoleBindingChange[],
                deleteRoleBindingChanges?: Contract.GcpDeleteRoleBindingChange[]) {
                return new Step(
                    title,
                    {
                        actionsElement:
                            _.isNil(actionElement)
                                ? undefined
                                : <Stack
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="flex-end"
                                    sx={{ width: "100%" }}>
                                    {actionElement}
                                </Stack>,
                        contentElement:
                            <Steps variant="plainNumbers">
                                {_<ChangeStep | ReactNode | string>([]).
                                    concat(translatedGcpConsoleExternalLink).
                                    concatIf(
                                        !_.isNil(createRoleBindingChanges),
                                        () =>
                                            _.map(
                                                createRoleBindingChanges,
                                                createRoleBindingChange =>
                                                    new ChangeStep(
                                                        createRoleBindingChange,
                                                        {
                                                            title:
                                                                localization.steps.createRoleBinding({
                                                                    ...createPrincipalResolutionProps(),
                                                                    ...createResolutionRoleResolutionProps(createRoleBindingChange.resourceId)
                                                                })
                                                        }))
                                    ).
                                    concatIf(
                                        !_.isNil(deleteRoleBindingChanges),
                                        () =>
                                            _.map(
                                                deleteRoleBindingChanges,
                                                deleteRoleBindingChange =>
                                                    new ChangeStep(
                                                        deleteRoleBindingChange,
                                                        {
                                                            title:
                                                                localization.steps.deleteRoleBinding.text.removeOne({
                                                                    ...createPrincipalResolutionProps(),
                                                                    ...createDeleteRoleBindingResolutionProps(deleteRoleBindingChange.resourceId)
                                                                })
                                                        }))).
                                    value()}
                            </Steps>
                    });
            }

            function getDeleteRoleBindingChangeStep(changeSet: Contract.ChangeSet) {
                const deleteRoleBindingChanges = changeSet.changes as Contract.GcpDeleteRoleBindingChange[];
                const scopeResourceId = deleteRoleBindingChanges[0].scopeResourceId;
                return getRoleBindingChangeStep(
                    localization.steps.deleteRoleBinding.title({
                        ...createPrincipalResolutionProps(),
                        ...createScopeResolutionProps(scopeResourceId)
                    }),
                    localization.steps.entityExternalConsoleLink({
                        entityExternalConsoleLink:
                            <EntityExternalConsoleLink
                                entityId={scopeResourceId}
                                page={Contract.GcpConsolePage.Permissions}/>,
                        principalMail: excessivePermissionPrincipalRisk.principalMail
                    }),
                    undefined,
                    undefined,
                    deleteRoleBindingChanges);
            }

            function getDeleteScopeResourcePrincipalRoleBindingsChangeStep(deleteScopeResourcePrincipalRoleBindingsChange: Contract.GcpDeleteScopeResourcePrincipalRoleBindingsChange) {
                const scopeResourceId = deleteScopeResourcePrincipalRoleBindingsChange.resourceId;

                return new ChangeStep(
                    deleteScopeResourcePrincipalRoleBindingsChange,
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {[
                                    localization.steps.entityExternalConsoleLink({
                                        entityExternalConsoleLink:
                                            <EntityExternalConsoleLink
                                                entityId={scopeResourceId}
                                                page={Contract.GcpConsolePage.Permissions}/>,
                                        principalMail: excessivePermissionPrincipalRisk.principalMail
                                    }),
                                    localization.steps.deleteRoleBinding.text.removeAll()
                                ]}
                            </Steps>,
                        title:
                            localization.steps.deleteRoleBinding.title({
                                ...createPrincipalResolutionProps(),
                                ...createScopeResolutionProps(scopeResourceId)
                            })

                    });
            }

            function getReplaceRoleBindingChangeStep(changeSet: Contract.ChangeSet) {
                const createRoleBindingChanges =
                    _(changeSet.changes).
                        filter(
                            change =>
                                TypeHelper.extendOrImplement(
                                    change.typeName,
                                    Contract.TypeNames.GcpCreateRoleBindingChange)).
                        as<Contract.GcpCreateRoleBindingChange>().
                        value();
                const deleteRoleBindingChanges =
                    _(changeSet.changes).
                        filter(
                            change =>
                                TypeHelper.extendOrImplement(
                                    change.typeName,
                                    Contract.TypeNames.GcpDeleteRoleBindingChange)).
                        as<Contract.GcpDeleteRoleBindingChange>().
                        value();

                const resolutionRoleIds =
                    _.map(
                        createRoleBindingChanges,
                        createRoleBindingChange => createRoleBindingChange.resourceId);
                return getRoleBindingChangeStep(
                    localization.steps.replaceRoleBinding({
                        ...(createScopeResolutionProps(createRoleBindingChanges[0].scopeResourceId)),
                        ...(createResolutionRolesResolutionProps(resolutionRoleIds)),
                        ...(createDeleteRoleBindingsResolutionProps(deleteRoleBindingChanges))
                    }),
                    localization.steps.entityExternalConsoleLink({
                        entityExternalConsoleLink:
                            <EntityExternalConsoleLink
                                entityId={createRoleBindingChanges[0].scopeResourceId}
                                page={Contract.GcpConsolePage.Permissions}/>,
                        principalMail: excessivePermissionPrincipalRisk.principalMail
                    }),
                    <ReplaceRoleBindingsChangeDiff
                        existingRoleIds={
                            _.map(
                                deleteRoleBindingChanges,
                                deleteRoleBindingChange => (entityModelMap[deleteRoleBindingChange.resourceId].entity as Contract.GcpIamRoleBinding).roleId)}
                        newRoleIds={resolutionRoleIds}/>,
                    createRoleBindingChanges,
                    deleteRoleBindingChanges);
            }

            return _.map(
                excessivePermissionPrincipalRisk.resolutionChanges,
                resolutionChange => {
                    if (resolutionChange.typeName === Contract.TypeNames.ChangeSet) {
                        const changeSet = resolutionChange as Contract.ChangeSet;
                        const changeSetChangeTypeName = changeSet.changes[0].typeName;
                        switch (changeSetChangeTypeName) {
                            case Contract.TypeNames.GciDeletePrincipalGroupMembershipChange:
                                return getGciDeletePrincipalGroupMembershipsChangeStep(changeSet);
                            case Contract.TypeNames.GcpDeleteRoleBindingChange:
                                return getDeleteRoleBindingChangeStep(changeSet);
                            case Contract.TypeNames.GcpCreateRoleBindingChange:
                                return getReplaceRoleBindingChangeStep(changeSet);
                            default:
                                throw new UnexpectedError("changeSetChangeTypeName", changeSetChangeTypeName);
                        }
                    } else {
                        const change = resolutionChange as Contract.Change;
                        switch (change.typeName) {
                            case Contract.TypeNames.GciDeletePrincipalGroupMembershipChange:
                                return getGciDeletePrincipalGroupMembershipChangeStep(change as Contract.GciDeletePrincipalGroupMembershipChange);
                            case Contract.TypeNames.GcpDeleteScopeResourcePrincipalRoleBindingsChange:
                                return getDeleteScopeResourcePrincipalRoleBindingsChangeStep(change as Contract.GcpDeleteScopeResourcePrincipalRoleBindingsChange);
                            default:
                                throw new UnexpectedError("change.typeName", change.typeName);
                        }
                    }
                });
        });

function CustomResolutionSection({ riskModel }: RiskContentProps) {
    const excessivePermissionPrincipalRisk = riskModel.risk as Contract.GcpExcessivePermissionPrincipalRisk;
    const principalModel = _.as<Contract.IPrincipalModel>(entityModelStore.useGet(excessivePermissionPrincipalRisk.entityId));
    entityModelStore.useGet(
        _.flatMap(
            excessivePermissionPrincipalRisk.excessiveScopeRoleBindingDatas,
            excessiveScopeRoleBindingData => _.concat(excessiveScopeRoleBindingData.ids, excessiveScopeRoleBindingData.scopeEntityId)));
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpExcessivePermissionPrincipalRiskDefinition.customResolutionSection",
            () => ({
                steps: {
                    [Contract.TypeNames.GcpExcessivePermissionPrincipalRiskRoleBindingResolution]: {
                        [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingResolution.Delete]: "Remove {{roleBindingIds}} at scope of {{scopeEntityId}} (for more detailed instructions, see {{riskResolutionCategoryViewLink}} Remediation tab)",
                        [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingResolution.Replace]: "Replace {{roleBindingIds}} at scope of {{scopeEntityId}} (has excessive permissions) with a {{generationLink}}"
                    }
                },
                subtitle: "These remediation steps only include bindings at the project level or above.\nTo remediate bindings at the resource level, see the {{riskResolutionCategoryViewLink}} Remediation tab."
            }));

    const steps =
        useMemo(
            () =>
                _.map(
                    excessivePermissionPrincipalRisk.excessiveScopeRoleBindingDatas,
                    excessiveScopeRoleBindingData =>
                        new Step(
                            localization.steps[Contract.TypeNames.GcpExcessivePermissionPrincipalRiskRoleBindingResolution][excessiveScopeRoleBindingData.resolution]({
                                generationLink:
                                    excessiveScopeRoleBindingData.resolution === Contract.GcpExcessivePermissionPrincipalRiskRoleBindingResolution.Delete
                                        ? undefined
                                        : <GenerateNonexcessiveRole
                                            excessivePermissionEvaluationStartDate={excessivePermissionPrincipalRisk.excessivePermissionEvaluationStartDate}
                                            principalModel={principalModel}
                                            scopeEntityId={excessiveScopeRoleBindingData.scopeEntityId}/>,
                                riskResolutionCategoryViewLink: <ResolutionCategoryViewLink riskModel={riskModel}/>,
                                roleBindingIds:
                                    <InlineEntities
                                        entityIdsOrModels={excessiveScopeRoleBindingData.ids}
                                        entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                                        variant="itemCountAndType"/>,
                                scopeEntityId:
                                    <Entity
                                        entityIdOrModel={excessiveScopeRoleBindingData.scopeEntityId}
                                        linkOptions={{ disabled: true }}
                                        variant="typeText"/>
                            }))),
            [excessivePermissionPrincipalRisk, principalModel]);

    return (
        <Stack spacing={1.5}>
            <MultiLineEllipsis maxLines={2}>
                {localization.subtitle({
                    riskResolutionCategoryViewLink: <ResolutionCategoryViewLink riskModel={riskModel}/>
                })}
            </MultiLineEllipsis>
            <Divider variant="fullWidth"/>
            <Steps variant="plainNumbers">
                {steps}
            </Steps>
        </Stack>);
}