import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { Link, UnexpectedError, useLocalization } from "@infrastructure";
import { Contract, riskPolicyModelStore, useRiskPolicyTitleTranslator } from "..";

export function useRiskPolicyTranslator() {
    const customRiskPolicyModels = riskPolicyModelStore.useGetCustom();
    const customRiskPolicyModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customRiskPolicyModels,
                    customRiskPolicyModel => customRiskPolicyModel.riskPolicyConfiguration.id),
            [customRiskPolicyModels]);
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const localization =
        useLocalization(
            "common.hooks.useRiskPolicyTranslator",
            () => ({
                links: {
                    awsCloudFrontDistributionAlternativeDomainNamesAndHttpsLink: "Using alternate domain names and HTTPS",
                    awsEc2InstanceMetadataServiceVersionLink: "How to retrieve instance metadata",
                    awsEc2VolumeEncryptionLink: "Amazon EBS encryption",
                    awsEc2VpcEndpointUnrestrictedServiceAccessDataPerimeterLink: "Building a Data Perimeter on AWS",
                    awsEksClusterEndpointAccessControlLink: "Amazon EKS cluster endpoint access control",
                    awsElbLoadBalancerRedirectActionsLink: "Redirect Actions",
                    awsLambdaFunctionConfigurationRuntimeLink: "AWS documentation",
                    awsRotateAccessKeysLink: "How to Rotate Access Keys for IAM Users",
                    awsRoute53HostedZoneEnableSigningLink: "AWS documentation",
                    awsS3BucketHttpsOnlyDisabledLink: "Security best practices for Amazon S3",
                    awsS3BucketObjectLifecycleConfigurationLink: "Lifecycle configurations",
                    awsS3BucketObjectMfaDeleteLink: "Configuring MFA delete",
                    awsServiceManagementTasksLink: "Service management tasks",
                    azureAppServiceKeyVaultReferencesLink: "Azure App Service Key Vault references",
                    azureContainerServiceApiServerAuthorizedIpRangesLink: "API Server Authorized IP Ranges",
                    azureDocumentDbFirewallLink: "Configure an IP firewall for your Azure Cosmos DB account",
                    azureDocumentDbRbacLink: "Azure role-based access control in Azure Cosmos DB",
                    azureKeyVaultConfigureFirewallsAndVirtualNetworksLink: "Configure Azure Key Vault firewalls and virtual networks",
                    azureKeyVaultRbacMigrationLink: "Migrate to Azure role-based access control",
                    azureKeyVaultRenewCertificateLink: "Renew your Azure Key Vault certificates",
                    azureLogicWorkflowSecureParametersDefinitionLink: "Secure access and data in Azure Logic Apps",
                    azureMySqlFirewallRulesLink: "Azure MySQL firewall rules",
                    azureSqlFirewallRulesLink: "Azure SQL firewall rules",
                    azureStorageAccountFirewallRulesLink: "Configure Azure Storage firewalls and virtual networks",
                    azureVirtualMachineSecureSecretsLink: "How to securely deal with secrets in Azure VM Applications",
                    azureWebApplicationAccessRulesLink: "Set up Azure App Service access restrictions",
                    azureWebApplicationAuthenticationLink: "Authentication and authorization",
                    changeDefaultInactivityPeriodLink: "change the default inactivity period",
                    changeDefaultIncludeUnusedRootUserLink: "change the default setting",
                    changeDefaultPasswordPolicyMinLengthLink: "change the default minimum length",
                    changeDefaultPasswordPolicyPreventReusePreviousPasswordCountLink: "change the default reuse count",
                    changeDefaultRetentionPeriodLink: "change the default retention period",
                    changeDefaultRotationPeriodLink: "change the default rotation period",
                    gcpArtifactRegistryProtectArtifactsLink: "Protecting artifacts",
                    gcpCloudRunServiceSecretsLink: "Use secrets",
                    gcpComputeInstanceIpForwardingEnabledLink: "Using routes",
                    gcpComputeSshIamEnabledLink: "About OS Login",
                    gcpComputeSshSerialPortEnabledLink: "Troubleshooting using the serial console",
                    gcpComputeSslPolicyLink: "SSL policies overview",
                    gcpContainerClusterControlPlaneAccessAuthorizationLink: "Authorize access to the control plane",
                    gcpContainerLeastPrivilegeServiceAccountsLink: "Use least privilege Google service accounts",
                    gcpLoggingPricingLink: "pricing",
                    gcpSecretManagerSecretCreateAndAccessLink: "Creating and accessing secrets",
                    gcpSecretManagerSecretRotationLink: "Rotation of secrets",
                    gcpSqlInstanceMysqlLoadClientFileLink: "Security considerations for LOAD DATA LOCAL",
                    gcpStorageBucketUniformAccessControlLink: "Uniform bucket-level access",
                    gcpUnusedTenantRecommendationLink: "GCP project recommendations",
                    iamSettingsLink: "Learn more",
                    kubernetesPodSecurityStandardsHostNamespacesLink: "Kubernetes documentation",
                    kubernetesPodSecurityStandardsPrivilegedLink: "Kubernetes documentation",
                    kubernetesReleasesLink: "Kubernetes release page",
                    ociComputeInstanceMetadataServiceVersion2SupportedImagesLink: "certain platform images",
                    ociComputeInstanceShieldedInstanceSupportedShapesAndImagesLink: "OCI documentation",
                    ociComputeInstanceVolumeEncryptionInTransitSupportedShapesAndImagesLink: "OCI documentation",
                    openshiftReleasesLink: "OpenShift release page"
                },
                riskPolicyConfigurationTypeName: {
                    [Contract.TypeNames.AadDirectoryApplicationCertificateNotRotatedRiskPolicyConfiguration]: "App registration certificates should be rotated periodically to minimize the risk of a compromise. Tenable recommends a timeframe of at least once a year.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration]: "App registration secrets should be rotated periodically to minimize the risk of a compromise. Tenable recommends a timeframe of every 180 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AadDirectoryApplicationServicePrincipalInactiveRiskPolicyConfiguration]: "Monitor Microsoft Entra ID applications that are inactive. By disabling or deleting inactive applications, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates inactivity can be modified through the IAM Settings. {{iamSettingsLink}}",
                    [Contract.TypeNames.AadDirectoryGroupInactiveRiskPolicyConfiguration]: "Monitor Microsoft Entra ID group identities that are inactive. By deleting inactive groups, you can mitigate the risk of unauthorized access, data breaches, and other security threats. Tenable generates inactivity recommendations based on the usage during a learning period, which is influenced by the type of group members (user and service identities). You can change this learning period via the IAM settings page. {{iamSettingsLink}}",
                    [Contract.TypeNames.AadDirectoryUserInactiveRiskPolicyConfiguration]: "Monitor Microsoft Entra ID users that are inactive. By disabling or deleting inactive users, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates inactivity can be modified through the IAM Settings. {{iamSettingsLink}}",
                    [Contract.TypeNames.AadSeverePermissionPrincipalRiskPolicyConfiguration]: "Monitor Microsoft Entra ID identities that were granted privileged roles in Microsoft Entra ID.",
                    [Contract.TypeNames.AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskPolicyConfiguration]: "It is recommended that only an administrator be allowed to register custom-developed applications. This ensures that the application undergoes a formal security review and approval process prior to exposing Microsoft Entra ID data.",
                    [Contract.TypeNames.AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskPolicyConfiguration]: "It is recommended that only an administrator be allowed to create new tenants. This prevents users from creating new Microsoft Entra ID or Azure AD B2C tenants and ensures that only authorized users are able to do so.",
                    [Contract.TypeNames.AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskPolicyConfiguration]: "It is recommended to limit the access of guest users to prevent guest accounts from performing certain directory tasks, such as enumerating users, groups, or other directory resources, and from being assigned administrative roles.\nThe recommended option is the most restrictive: 'Guest user access is restricted to their own directory object'.",
                    [Contract.TypeNames.AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskPolicyConfiguration]: "It is recommended to restrict invitations to users with specific administrator roles to ensure that only authorized accounts have access to cloud resources.\nThis helps maintain ‘Need to Know’ permissions and prevents inadvertent data access.\nBy default, the Guest invite restrictions setting is configured to ‘Anyone in the organization can invite guest users, including guests and non-admins’.\nThis may pose a security risk because it allows any user to invite guests and non-admins to the tenant.",
                    [Contract.TypeNames.AwsAccessAnalyzerRegionAnalyzerNotExistRiskPolicyConfiguration]: "It is recommended that IAM Access Analyzer be enabled for all regions. IAM Access Analyzer helps you identify the resources in your organization and accounts, such as Amazon S3 buckets or IAM roles, that are shared with an external entity. This lets you identify unintended access to your resources and data.\n\n**Note: Tenable CS will only scan the active regions configured in your account.**",
                    [Contract.TypeNames.AwsApiGatewayApiWebAclNotExistsRiskPolicyConfiguration]: "At least one stage of your API Gateway API should be associated with AWS WAF web ACL.\nDoing so protects web applications and APIs from threats, and enables you to control how traffic reaches your applications.\nThis policy is only applicable to REST APIs.",
                    [Contract.TypeNames.AwsAcmCertificateKeyMinLengthRiskPolicyConfiguration]: "ACM certificates should use a minimum 2048-bit RSA key to adhere to security best practices and protect against brute-force cryptographic attacks.",
                    [Contract.TypeNames.AwsAthenaWorkgroupEncryptionDisabledRiskPolicyConfiguration]: "Athena workgroup should have encryption at rest enabled to ensure query results are protected.",
                    [Contract.TypeNames.AwsAthenaWorkgroupLoggingDisabledRiskPolicyConfiguration]: "Athena workgroup logging should be enabled so that you can audit usage metrics using Amazon CloudWatch. Audit logs track and monitor system activities. They provide a record of events that can help you detect security breaches, investigate incidents, and comply with regulations. ",
                    [Contract.TypeNames.AwsCloudFormationStackSecretExistsRiskPolicyConfiguration]: "CloudFormation stacks should store sensitive data like passwords and access tokens using Secrets Manager secrets and not in clear text via parameters and outputs.",
                    [Contract.TypeNames.AwsCloudFrontDistributionDefaultSslCertificateRiskPolicyConfiguration]: "It is recommended to use a custom SSL certificate instead of the default certificate. This will allow your users to access content using alternative domain names, ensuring secure communication over HTTPS with a personalized certificate.",
                    [Contract.TypeNames.AwsCloudFrontDistributionHttpsOnlyDisabledRiskPolicyConfiguration]: "CloudFront should require HTTPS communication between viewers and distributions.\nIf HTTPS isn’t enforced by design, you can exclude the relevant resource and Tenable Cloud Security will not alert on it anymore.\n\n**Note:**\nIf you’re using your own domain name in CloudFront (for example, https://www.example.com) and you want your viewers to use HTTPS, refer to the relevant AWS documentation about {{awsCloudFrontDistributionAlternativeDomainNamesAndHttpsLink}}.",
                    [Contract.TypeNames.AwsCloudFrontDistributionInsecureTlsProtocolRiskPolicyConfiguration]: "CloudFront distributions should use a secure TLS/SSL protocol when establishing HTTPS connections to your origins.\nTenable recommends using version TLSv1.2.\nEnsuring that your distributions are not using an insecure protocol can help protect your content and users from potential security vulnerabilities and ensure that your cloud environment is secure.\nIf TLSv1.2 isn’t enforced by design, you can exclude resources from the policy, and Tenable Cloud Security won't alert on them anymore.\n\n**Note:**\nIf your origin is an Amazon S3 bucket that’s configured as a website endpoint, you can’t configure CloudFront to use HTTPS with your origin because Amazon S3 doesn't support HTTPS for website endpoints.",
                    [Contract.TypeNames.AwsCloudFrontDistributionLoggingDisabledRiskPolicyConfiguration]: "CloudFront distribution logging should be enabled so that you can audit each request made for your web content and see who accesses your data, and how often, in case of a breach.",
                    [Contract.TypeNames.AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskPolicyConfiguration]: "HTTPS should be used for communication between CloudFront distributions and their origins.\nIf HTTPS isn’t enforced by design, you can exclude the relevant resource and Tenable Cloud Security will not alert on it anymore.\n\n**Note:**\nIf your origin is an Amazon S3 bucket that’s configured as a website endpoint, you can’t configure CloudFront to use HTTPS with your origin because Amazon S3 doesn't support HTTPS for website endpoints.",
                    [Contract.TypeNames.AwsCloudFrontDistributionRootObjectNotExistsRiskPolicyConfiguration]: "CloudFront distribution should have a default root object specified to avoid exposing the contents of your web distribution.",
                    [Contract.TypeNames.AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskPolicyConfiguration]: "It is recommended to enable origin access control (OAC) to permit access to the content in the bucket only through the specified CloudFront distribution, and to prohibit direct access from the bucket or another distribution. Although CloudFront supports origin access identity (OAI), OAC offers additional functionality. Distributions using OAI can migrate to OAC.\n\n**Note: S3 bucket origins set as website endpoints don’t support OAC and won’t be evaluated.**",
                    [Contract.TypeNames.AwsCloudFrontDistributionWebAclNotExistsRiskPolicyConfiguration]: "CloudFront distributions should be associated with AWS WAF web ACL, which protects web applications and APIs from threats, and enables you to control how traffic reaches your applications.",
                    [Contract.TypeNames.AwsCloudTrailTrailBucketEventDisabledRiskPolicyConfiguration]: "CloudTrail S3 bucket logging should be enabled in order to audit API calls to the bucket.",
                    [Contract.TypeNames.AwsCloudTrailTrailBucketPublicAccessEnabledRiskPolicyConfiguration]: "CloudTrail S3 bucket policy or Access Control List (ACL) should allow access only to specific identities and accounts.\n\n**Note: in order to evaluate the policy, Tenable Cloud Security must have access to the AWS account of the target S3 bucket.**",
                    [Contract.TypeNames.AwsCloudTrailTrailDigestDisabledRiskPolicyConfiguration]: "CloudTrail S3 bucket log file validation should be enabled to provide integrity checks for logs.",
                    [Contract.TypeNames.AwsCloudTrailTrailEncryptionDisabledRiskPolicyConfiguration]: "CloudTrail S3 buckets should have encryption at rest enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.AwsCloudTrailTrailLogGroupDisabledRiskPolicyConfiguration]: "CloudTrail should be configured to send logs to CloudWatch Logs in order to facilitate real-time and historic activity logging based on user, API, resource, and IP address, and to provide an opportunity to establish alarms and notifications for anomalous or sensitivity account activity.",
                    [Contract.TypeNames.AwsCloudTrailTrailMultiRegionNotExistRiskPolicyConfiguration]: "At least one trail must be a multi-region trail that captures read and write management events.",
                    [Contract.TypeNames.AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskPolicyConfiguration]: "It is recommended to enable CloudTrail data events for all S3 buckets. Logging S3 bucket object-level read events should be enabled for auditing and monitoring purposes.",
                    [Contract.TypeNames.AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskPolicyConfiguration]: "It is recommended to enable CloudTrail data events for all S3 buckets. Logging S3 bucket object-level write events should be enabled for auditing and monitoring purposes.",
                    [Contract.TypeNames.AwsCloudWatchAlarmCloudTrailChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmConfigChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2VpcChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmIamPolicyChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmRootUsageNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskPolicyConfiguration]: "Real-time monitoring of suspicious API calls can be achieved by directing multi regional CloudTrail logs to CloudWatch log group, creating metric filters and alarms based on SNS topics.",
                    [Contract.TypeNames.AwsConfigRegionConfigurationRecorderNotExistRiskPolicyConfiguration]: "It is recommended that AWS config be enabled for all regions. AWS config enables security analysis, resource change tracking, and compliance auditing.\n\n**Note: Tenable CS will only scan the active regions configured in your account.**",
                    [Contract.TypeNames.AwsDataLeakageRiskPolicyConfiguration]: "An identity that accessed data while it has no history of accessing this data before. This can be a result of an attacker gaining this identity credentials and acting on its behalf.",
                    [Contract.TypeNames.AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "It is recommended to increase the default backup retention period to restore your systems to a point in time and minimize downtime and data loss. Backups help you recover more quickly from a security incident and strengthen the resilience of your systems. Tenable recommends a retention period of at least 7 days.\n\n**Note: You can change the default retention period for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsDynamoDbTableDeleteProtectionDisabledRiskPolicyConfiguration]: "It is recommended to enable the deletion protection as an additional layer of protection against accidental database deletion or deletion by an unauthorized user. Resources can't be deleted while deletion protection is enabled. You must first disable deletion protection before deleting a resource.",
                    [Contract.TypeNames.AwsDynamoDbTableEncryptionDisabledRiskPolicyConfiguration]: "DynamoDB tables should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsEc2ImagePublicAccessExistsRiskPolicyConfiguration]: "EC2 images launch permissions should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsEc2InstanceInstanceProfileNotExistRiskPolicyConfiguration]: "It is recommended to attach the instance to a role which has an appropriate permissions for the required access rather than encoding AWS keys into AWS API calls. Amazon EC2 uses an instance profile as a container for an IAM role.",
                    [Contract.TypeNames.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskPolicyConfiguration]: "EC2 instance metadata response hop limit should be set to 1. This setting limits the number of hops a packet can take, preventing it from being routed outside the EC2 instance, which enhances security against external attackers. If an EC2 instance is misconfigured as a network device, a hop limit of 1 ensures that the metadata response stays within the instance.\n\n**Note: In a container environment, if the hop limit is 1, the IMDSv2 response does not return because going to the container is considered an additional network hop. To avoid the process of falling back to IMDSv1 and the resultant delay, in a container environment we recommend that you set the hop limit to 2.**",
                    [Contract.TypeNames.AwsEc2InstanceMetadataServiceVersionRiskPolicyConfiguration]: "EC2 instances obtain IAM roles credentials through the local Instance Metadata Service (IMDS).\nBoth IMDSv1 and IMDSv2 are available and enabled by default, but IMDSv2 should be enforced since it protects against known attacks on misconfigured-open website application firewalls, open reverse proxies, unpatched SSRF vulnerabilities and open layer-3 firewalls.\n\nIMDSv2 ensures that credentials do not travel outside the EC2 instance by setting a hop limit (TTL).\nIn a container environment, we recommend that you set the hop limit to 2.\nFor more information see: {{awsEc2InstanceMetadataServiceVersionLink}}\n\nTo enforce the use of IMDSv2 on all new instances, you should use an IAM policy and SCPs. By using an IAM policy, you can prevent IAM users from launching new instances, unless they require IMDSv2 on the new instance.",
                    [Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration]: "EC2 instances should store sensitive data like passwords and access tokens using Secrets Manager secrets and not in clear text via user data.",
                    [Contract.TypeNames.AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskPolicyConfiguration]: "Load balancers should use TLS protocols that enforce v1.2 and above, since communication between the load balancer and its clients is vulnerable to interception. If you want to support HTTP on your load balancer, make sure all HTTP requests are redirected to HTTPS. For more information, see {{awsElbLoadBalancerRedirectActionsLink}}. Findings will only be generated for TLS, HTTP and HTTPS listeners that do not enforce TLS 1.2 or higher.",
                    [Contract.TypeNames.AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskPolicyConfiguration]: "Network ACLs rules should restrict network inbound access (excluding web ports) to specific IP addresses.",
                    [Contract.TypeNames.AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskPolicyConfiguration]: "Security groups rules should restrict network inbound access (excluding web ports) to specific IP addresses.",
                    [Contract.TypeNames.AwsEc2SecurityGroupUnusedRiskPolicyConfiguration]: "It is recommended to delete security groups that have been unused for an extended period of time to prevent attaching resources by mistake, which can lead to unintentional network exposure. Tenable recommends a timeframe of 30 days.\n\n**Note: You can {{changeDefaultInactivityPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsEc2SnapshotEncryptionDisabledRiskPolicyConfiguration]: "EBS snapshots should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsEc2SnapshotPublicAccessExistsRiskPolicyConfiguration]: "EBS snapshots permissions should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsEc2VolumeEncryptionDisabledRiskPolicyConfiguration]: "EBS volumes should have encryption at rest enabled using Key Management Service (KMS).\nNew EBS volumes can also be encrypted by default for the entire region, for more information see: {{awsEc2VolumeEncryptionLink}}",
                    [Contract.TypeNames.AwsEc2VpcDefaultSecurityGroupRuleExistsRiskPolicyConfiguration]: "If you don't specify a security group when you launch an EC2 instance, the instance is automatically assigned to the default security group of the VPC.\nDefault security groups should restrict all traffic and you should avoid using them and use least privileged security groups instead.",
                    [Contract.TypeNames.AwsEc2VpcEndpointUnrestrictedServiceAccessRiskPolicyConfiguration]: "To establish a secure data perimeter, VPC endpoint policy should allow access only to trusted identities and resources.\nThis is done by using conditions such as aws:PrincipalOrgId and aws:ResourceOrgId.\nFor more information, refer to AWS documentation about {{awsEc2VpcEndpointUnrestrictedServiceAccessDataPerimeterLink}}\n\n**Note: in order to evaluate the policy, the AWS account must be a part of an organization**",
                    [Contract.TypeNames.AwsEc2VpcRejectFlowLogNotExistRiskPolicyConfiguration]: "VPC Flow Logs records the network traffic passing through the VPC and should be enabled on rejected traffic for security audit and management purposes.",
                    [Contract.TypeNames.AwsEcrRepositoryPublicAccessExistsRiskPolicyConfiguration]: "ECR repositories resource policy should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "Ensure that your ECS task definitions store sensitive data using Secrets Manager secrets, and not environment variables, to reduce the risk of exposing data. Even when a resource is not active, it still can be accessed by any identity with read-metadata-only permissions.",
                    [Contract.TypeNames.AwsEksClusterInboundExternalWideRangeRiskPolicyConfiguration]: "The Kubernetes API lets you query and manipulate the state of API objects in Kubernetes.\nThe EKS cluster's Kubernetes API should restrict inbound internet access to specific IP addresses via its configuration of authorized networks.\nRules that allow inbound internet access for an IP range of more than 65,535 addresses are considered wide.\nFor more information refer to the relevant AWS documentation: {{awsEksClusterEndpointAccessControlLink}}.",
                    [Contract.TypeNames.AwsEksClusterInstanceInboundExternalRiskPolicyConfiguration]: "Cluster nodes should be private, and should allow access only to private IP addresses. Disabling public IP addresses on cluster nodes restricts access to internal networks only, forcing attackers to obtain local network access before attempting to compromise the underlying Kubernetes hosts.",
                    [Contract.TypeNames.AwsEksClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "Application-layer secrets encryption provides extra security for sensitive data like user-defined secrets and secrets needed for cluster operation (e.g., service account keys), all stored in etcd.\nThis allows you to use a self-managed AWS KMS key to encrypt data at the application level, countering potential attackers who breach etcd access.",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskPolicyConfiguration]: "It is recommended to enable automatic failover to ensure the primary node automatically fails over to a read replica. This failover and replica promotion ensure that you can resume writing to the new primary after the promotion is complete, reducing overall downtime in case of failure.",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskPolicyConfiguration]: "It is recommended to enable automatic minor version upgrade to automatically receive minor engine upgrades during the specified maintenance window. This allows clusters to get new features, bug fixes, and security patches that are backward compatible with existing applications.",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyConfiguration]: "It is recommended to enable automatic backups with a sufficient snapshot retention period to protect against data loss or damage. You can use the backup to restore a cluster or seed a new cluster. The backup consists of the cluster's metadata, along with all the data in the cluster.\nTenable recommends a snapshot retention period of at least 1 day.\n\n**Note: You can {{changeDefaultRetentionPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskPolicyConfiguration]: "It is recommended to use custom subnet groups that provide more restrictive control over the subnets where the cluster resides and the networking configurations inherited from those subnets. By default, AWS creates a default subnet group for ElastiCache clusters if none exists, but relying on it can lead to broader accessibility and less precise control over network security and compliance.",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupEncryptionDisabledRiskPolicyConfiguration]: "Redis OSS cluster caches should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskPolicyConfiguration]: "Redis OSS cluster caches should have transport encryption enabled to enforce secure data transfer using SSL.\n\n**Note:In-transit encryption is supported on Redis OSS Cache running Redis OSS versions 3.2.6, 4.0.10 and later.**",
                    [Contract.TypeNames.AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyConfiguration]: "Elastic Beanstalk should be configured to send logs to CloudWatch Logs in order to facilitate real-time and historic activity logging based on user, API, resource, and IP address, and to provide an opportunity to establish alarms and notifications for anomalous or sensitive account activity. CloudWatch logs should be configured for a sufficient period of time. Tenable recommends a retention period of at least 7 days.\nNote: You can change the default retention period for this policy to comply with internal standards.",
                    [Contract.TypeNames.AwsElasticFileSystemFileSystemEncryptionDisabledRiskPolicyConfiguration]: "EFS file system should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsEsDomainCloudWatchLoggingDisabledRiskPolicyConfiguration]: "OpenSearch domain should be configured to send logs to CloudWatch Logs in order to facilitate real-time and historic activity logging based on user, API, resource, and IP address, and to provide an opportunity to establish alarms and notifications for anomalous or sensitive account activity.",
                    [Contract.TypeNames.AwsEsDomainEncryptionDisabledRiskPolicyConfiguration]: "OpenSearch domains should have encryption at rest enabled using Key Management Service (KMS), since it may contain sensitive information.",
                    [Contract.TypeNames.AwsEsDomainNodeCommunicationEncryptionDisabledRiskPolicyConfiguration]: "Node-to-node encryption enables TLS 1.2 encryption for all communications within the domain in order to prevent man-in-the-middle attacks.\nThis policy is only applicable to domains with either OpenSearch, or Elasticsearch version 6.0 or above",
                    [Contract.TypeNames.AwsEsDomainPublicAccessExistsRiskPolicyConfiguration]: "OpenSearch domain resource policy should allow access only to specific identities and accounts, since it may contain sensitive information.\nReview your domains and restrict access in order to minimize the security risk.",
                    [Contract.TypeNames.AwsExcessivePermissionBucketRiskPolicyConfiguration]: "S3 bucket permissions that were granted via bucket policy, and were not used are considered overprivileged. This access can be granted internally (same account), to identities outside of the bucket (cross account), or publicly.\nTenable analyzes the policy to compare identities being granted access with the identities that are using those permissions, and then generates least privilege recommendations based on the usage during a learning period (for both user and service identities). You can change this learning period via the IAM settings page. {{iamSettingsLink}}\nFor example, if the bucket exposes permissions publicly, but is only being used internally, Tenable will suggest to limit access to internal identities only.",
                    [Contract.TypeNames.AwsExcessivePermissionGroupRiskPolicyConfiguration]: "Monitor IAM group privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}",
                    [Contract.TypeNames.AwsExcessivePermissionPermissionSetRiskPolicyConfiguration]: "Monitor SSO permission set privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}",
                    [Contract.TypeNames.AwsExcessivePermissionRepositoryRiskPolicyConfiguration]: "It's possible to expose images stored in an ECR repository to the public, or to identities outside of the account, using a repository policy.\nIt's also possible to grant internal (same account) access solely through a repository policy (and not through an identity).\nTenable analyzes the policy to compare identities being granted access with the identities that are using those permissions, and then generates least privilege recommendations based on the usage during a learning period (for both user and service identities). You can change this learning period via the IAM settings page. {{iamSettingsLink}}\nFor example, if the repository exposes permissions to the public but is only being used internally, Tenable will suggest to limit access to internal identities only.",
                    [Contract.TypeNames.AwsExcessivePermissionRoleGroupRiskPolicyConfiguration]: "Monitor IAM organization role privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}\nThis policy relates to a role that is identically deployed across multiple accounts.",
                    [Contract.TypeNames.AwsExcessivePermissionRoleRiskPolicyConfiguration]: "Monitor IAM role privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}",
                    [Contract.TypeNames.AwsExcessivePermissionSecretRiskPolicyConfiguration]: "It's possible to expose secrets stored in AWS Secrets Manager to the public, or to identities outside of the account, using a resource policy.\nIt's also possible to grant internal (same account) access solely through a secret policy (and not through an identity).\nTenable analyzes the policy to compare identities being granted access with the identities that are using those permissions, and then generates least privilege recommendations based on the usage during a learning period (for both user and service identities). You can change this learning period via the IAM settings page. {{iamSettingsLink}}\nFor example, if the secret policy exposes permissions to the public but is only being used internally, Tenable will suggest to limit access to internal identities only.",
                    [Contract.TypeNames.AwsExcessivePermissionUserRiskPolicyConfiguration]: "Monitor IAM user privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}",
                    [Contract.TypeNames.AwsFirehoseDeliveryStreamEncryptionDisabledRiskPolicyConfiguration]: "Data Firehose streams should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsIamGroupInactiveRiskPolicyConfiguration]: "Monitor inactive IAM groups. By deleting an inactive group, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates inactivity can be modified through the IAM Settings. {{iamSettingsLink}}",
                    [Contract.TypeNames.AwsIamPasswordLengthPolicyRiskPolicyConfiguration]: "IAM password policy should be at least 14 characters long to significantly reduce the risk of password-guessing and brute-force attacks. Tenable recommends to enforce a minimum length of at least 14 characters long. \n\n**Note: You can {{changeDefaultPasswordPolicyMinLengthLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsIamPasswordReusePolicyRiskPolicyConfiguration]: "IAM password policy should not allow reusing passwords to significantly reduce the risk of password-guessing and brute-force attacks. Tenable recommends to prevent the reuse of 24 previous passwords . \n\n**Note: You can {{changeDefaultPasswordPolicyPreventReusePreviousPasswordCountLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskPolicyConfiguration]: "IAM users, groups, and roles should be granted the minimal set of permissions in order to follow the Principle of Least Privilege. An IAM policy that provides full administrative permissions is a policy that contains the following statement: \"Effect\": \"Allow\", \"Action\": \"*\", \"Resource\": \"*\".",
                    [Contract.TypeNames.AwsIamRoleInactiveRiskPolicyConfiguration]: "Monitor IAM roles that are inactive. By deleting inactive roles, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates inactivity can be modified through the IAM Settings. {{iamSettingsLink}}",
                    [Contract.TypeNames.AwsIamRolePublicAccessExistsRiskPolicyConfiguration]: "IAM role trust policy defines which principals can assume the role and get temporary credentials in order to use its permissions, it should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyConfiguration]: "External ID is a condition that you can use in an IAM role trust policy to designate who can assume the role, it should be used to protect you from potential impersonators who can use the same vendor product to access your account.",
                    [Contract.TypeNames.AwsIamRootUserAccessKeyEnabledRiskPolicyConfiguration]: "Access keys are used to sign programmatic requests to AWS and should not be created or enabled for the root user.\nThe root user should be used only when required to perform account and {{awsServiceManagementTasksLink}}.",
                    [Contract.TypeNames.AwsIamRootUserActivityRiskPolicyConfiguration]: "The root user should be used only when required to perform account and {{awsServiceManagementTasksLink}}.",
                    [Contract.TypeNames.AwsIamRootUserMfaDisabledRiskPolicyConfiguration]: "Multi-Factor Authentication (MFA) should be enabled.\n\n**Note: Findings will not be generated if the root user was created as part of an organization and remains unused. Findings will be generated once the user is used. You can {{changeDefaultIncludeUnusedRootUserLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsIamServerCertificateExpiredRiskPolicyConfiguration]: "Removing expired SSL/TLS certificates eliminates the risk that an invalid certificate will be deployed accidentally to a resource such as AWS Elastic Load Balancer (ELB), which can damage the credibility of the application/website behind the ELB. As a best practice, it is recommended to delete expired certificates.",
                    [Contract.TypeNames.AwsIamSupportRoleNotExistRiskPolicyConfiguration]: "IAM role with the appropriate policy assigned should be set to allow authorized users to manage incidents with AWS Support.",
                    [Contract.TypeNames.AwsIamUserAttachedPolicyRiskPolicyConfiguration]: "IAM users should be assigned with group membership, rather than having a direct IAM policy attached. Assigning IAM policy only through groups unifies permissions management to a single, flexible layer consistent with organizational functional roles. By unifying permissions management, the likelihood of excessive permissions is reduced.",
                    [Contract.TypeNames.AwsIamUserInactiveRiskPolicyConfiguration]: "Monitor IAM users that are inactive. By deleting inactive users, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates inactivity can be modified through the IAM Settings. {{iamSettingsLink}}",
                    [Contract.TypeNames.AwsIamUserMfaDisabledRiskPolicyConfiguration]: "Multi-Factor Authentication (MFA) should be enabled for all IAM users with console access.",
                    [Contract.TypeNames.AwsIamUserMultipleEnabledAccessKeyRiskPolicyConfiguration]: "IAM users should have only one active access key. Access keys are used to sign programmatic requests to AWS. In order to reduce the possibility of attackers gaining access to sensitive resources, you should deactivate or remove redundant keys.",
                    [Contract.TypeNames.AwsIamUserUnrotatedAccessKeyRiskPolicyConfiguration]: "Access keys are used to sign programmatic requests to AWS and should be rotated periodically to reduce the risk if a user is compromised. Tenable recommends a timeframe of at least every 90 days. For more information see: {{awsRotateAccessKeysLink}}.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration]: "It is recommended to remove or deactivate all access keys that have been unused for an extended period of time to reduce the window of opportunity for attackers to gain access to sensitive resources. Tenable recommends a timeframe of 45 days.\n\n**Note: You can {{changeDefaultInactivityPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsIamUserUnusedPasswordEnabledRiskPolicyConfiguration]: "It is recommended to remove passwords that have been unused for an extended period of time to reduce the window of opportunity for attackers to gain access to sensitive resources. Tenable recommends a timeframe of 45 days.\n\n**Note: You can {{changeDefaultInactivityPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsInboundExternalEc2InstanceRiskPolicyConfiguration]: "EC2 instances should be accessible directly only by specific IP addresses.\nAn EC2 instance is considered public if it has a public IP address, Security Group, and NACL rules allowing network inbound access to an external IP range of more than 65,535 addresses, and is part of a public subnet.",
                    [Contract.TypeNames.AwsInboundExternalElbLoadBalancerRiskPolicyConfiguration]: "Internet-facing load balancers (ELB) are vulnerable to breaches and should not be accessible from a wide range of public IP addresses.\nIf the ELB is public by design, you can exclude the relevant resource and Tenable Cloud Security will not alert on it anymore.",
                    [Contract.TypeNames.AwsInboundExternalRdsDatabaseInstanceRiskPolicyConfiguration]: "RDS instances should be accessible only from the specific IP addresses that needs access to it.\nReview your public RDS instances and restrict access in order to minimize the security risk.\nRDS instance is considered public **directly** if:\n1. The instance belongs to a subnet that has a route to the internet gateway\n2. \"Publicly Accessible\" is set to Yes\n3. Security group rules allow network inbound access from external IP ranges\n4. NACL rules allow network inbound access from external IP ranges\n5. The external IP address ranges allowed by the security group and NACL contain more than 65,535 addresses\n\nRDS instance is considered public **indirectly** if:\n1. A load balancer is publicly exposed to a wide range\n2. There's a route from the load balancer to the RDS instance\n3. The external IP address ranges allowed by the route is larger than 65,535 addresses",
                    [Contract.TypeNames.AwsInboundExternalRedshiftClusterRiskPolicyConfiguration]: "Redshift clusters should be accessible only from the specific IP addresses that needs access to it.\nReview your public Redshift clusters and restrict access in order to minimize the security risk.\nRedshift cluster is considered public **directly** if:\n1. The cluster belongs to a subnet that has a route to the internet gateway\n2. \"Publicly Accessible\" is set to Yes\n3. Security group rules allow network inbound access from external IP ranges\n4. NACL rules allow network inbound access from external IP ranges\n5. The external IP address ranges allowed by the security group and NACL contain more than 65,535 addresses\n\nRedshift cluster is considered public **indirectly** if:\n1. A load balancer is publicly exposed to a wide range\n2. There's a route from the load balancer to the Redshift cluster\n3. The external IP address ranges allowed by the route is larger than 65,535 addresses",
                    [Contract.TypeNames.AwsKinesisStreamEncryptionDisabledRiskPolicyConfiguration]: "Kinesis data streams should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsKmsKeyPublicAccessExistsRiskPolicyConfiguration]: "KMS key resource policy should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsKmsKeyRotationDisabledRiskPolicyConfiguration]: "Automatic rotation should be enabled for KMS customer managed keys in order to reduce the risk of a compromised key. Tenable recommends a timeframe of every 365 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskPolicyConfiguration]: "It is recommended to use a supported runtime with available security updates to prevent the Lambda function from being compromised due to unpatched vulnerabilities.\nLambda runtimes consist of an operating system, programming language, and software libraries as components, each receiving maintenance and security updates. When a runtime component stops receiving security updates, Lambda deprecates the runtime.\nFor more information, see {{awsLambdaFunctionConfigurationRuntimeLink}}.",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "Lambda functions should store sensitive data in AWS Secrets Manager secrets and not environment variables.",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskPolicyConfiguration]: "It is recommended to deploy the Lambda function across multiple AZs to ensure high availability within your architecture.\n**Note: Only Lambda functions that are associated with VPC will be evaluated.**",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration]: "Lambda functions should allow access and invocation only to specific identities.\nNote: You can edit the policy to exclude API Gateway paths by marking them as intentionally public. Tenable will classify them as public by design and close related findings.",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationVpcNotExistsRiskPolicyConfiguration]: "It is recommended to deploy a Lambda function in a virtual private cloud (VPC) to strengthen security and control over network access to private resources.",
                    [Contract.TypeNames.AwsNetworkAccessManagementRiskPolicyConfiguration]: "An identity that is modifying network access rules while it has no history of performing similar actions. This is a common technique used by attackers to gain a foothold in the environment.",
                    [Contract.TypeNames.AwsPermissionManagementRiskPolicyConfiguration]: "An identity that is modifying permissions while it has no history of performing similar actions. This is a common technique used by attackers to gain a foothold in the environment.",
                    [Contract.TypeNames.AwsPrivilegeEscalationRiskPolicyConfiguration]: "An identity that has escalated its privileges in a way that was not observed before. This is a common technique used by attackers to get more permissions in the environment, commonly known as lateral movement.",
                    [Contract.TypeNames.AwsRdsClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "It is recommended to increase the default backup retention period to restore your systems to a point in time and minimize downtime and data loss. Backups help you recover more quickly from a security incident and strengthen the resilience of your systems. Tenable recommends a retention period of at least 7 days.\n\n**Note: You can change the default retention period for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsRdsClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "RDS Cluster should be configured to send logs to CloudWatch Logs in order to facilitate real-time and historic activity logging based on user, API, resource, and IP address, and to provide an opportunity to establish alarms and notifications for anomalous or sensitive account activity.",
                    [Contract.TypeNames.AwsRdsClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "Enabling deletion protection is recommended as an additional protection against accidental database deletion or deletion by an unauthorized user. Resources can't be deleted while deletion protection is enabled. You must first disable deletion protection before deleting a resource.",
                    [Contract.TypeNames.AwsRdsClusterMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "Ensure that your RDS cluster is configured to automatically receive minor engine upgrades during the specified maintenance window.\nThis allows clusters to get new features, bug fixes, and security patches that are backward-compatible with existing applications.\nTo configure this setting, enable the Auto minor version upgrade flag. Note that DB engine upgrades require downtime.",
                    [Contract.TypeNames.AwsRdsClusterStorageEncryptionDisabledRiskPolicyConfiguration]: "RDS clusters should have storage encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyConfiguration]: "It is recommended to increase the default backup retention period to restore your systems to a point in time and minimize downtime and data loss. Backups help you recover more quickly from a security incident and strengthen the resilience of your systems. Tenable recommends a retention period of at least 7 days.\n\n**Note: You can change the default retention period for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskPolicyConfiguration]: "RDS Instance should be configured to send logs to CloudWatch Logs in order to facilitate real-time and historic activity logging based on user, API, resource, and IP address, and to provide an opportunity to establish alarms and notifications for anomalous or sensitive account activity.",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskPolicyConfiguration]: "Enabling deletion protection is recommended as an additional protection against accidental database deletion or deletion by an unauthorized user. Resources can't be deleted while deletion protection is enabled. You must first disable deletion protection before deleting a resource.",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "Ensure that your RDS DB instances are configured to automatically receive minor engine upgrades during the specified maintenance window.\nThis allows instances to get new features, bug fixes, and security patches that are backward-compatible with existing applications.\nTo configure this setting, enable the Auto minor version upgrade flag.\nNote that DB engine upgrades require downtime.",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskPolicyConfiguration]: "RDS instances should have storage encryption at rest enabled using Key Management Service (KMS).\n\n**Note: RDS instances with an engine type of SQL Server Express Edition or specific DB instance class types will not be evaluated since encryption is not supported for them.**",
                    [Contract.TypeNames.AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "DocumentDB Cluster should be configured to send logs to CloudWatch Logs in order to facilitate real-time and historic activity logging based on user, API, resource, and IP address, and to provide an opportunity to establish alarms and notifications for anomalous or sensitive account activity.",
                    [Contract.TypeNames.AwsRdsDocDbClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "Enabling deletion protection is recommended as an additional protection against accidental database deletion or deletion by an unauthorized user. Resources can't be deleted while deletion protection is enabled. You must first disable deletion protection before deleting a resource.\n\n**Note: DocumentDB clusters with a cluster type of Elastic will not be evaluated since they do not support delete protection.**",
                    [Contract.TypeNames.AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskPolicyConfiguration]: "DocumentDB cluster snapshots should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskPolicyConfiguration]: "DocumentDB cluster snapshots permissions should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "It is recommended to increase the default backup retention period to restore your systems to a point in time and minimize downtime and data loss. Backups help you recover more quickly from a security incident and strengthen the resilience of your systems. Tenable recommends a retention period of at least 7 days.\n\n**Note: You can change the default retention period for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "Neptune DB Cluster should be configured to send logs to CloudWatch Logs in order to facilitate real-time and historic activity logging based on user, API, resource, and IP address, and to provide an opportunity to establish alarms and notifications for anomalous or sensitive account activity.",
                    [Contract.TypeNames.AwsRdsNeptuneClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "Enabling deletion protection is recommended as an additional protection against accidental database deletion or deletion by an unauthorized user. Resources can't be deleted while deletion protection is enabled. You must first disable deletion protection before deleting a resource.",
                    [Contract.TypeNames.AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskPolicyConfiguration]: "Neptune cluster snapshots should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskPolicyConfiguration]: "Neptune cluster snapshots permissions should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "Ensure that your Neptune DB instances are configured to automatically receive minor engine upgrades during the specified maintenance window.\nThis allows instances to get new features, bug fixes, and security patches that are backward-compatible with existing applications.\nTo configure this setting, enable the Auto minor version upgrade flag.\nNote that DB engine upgrades require downtime.",
                    [Contract.TypeNames.AwsRdsSnapshotEncryptionDisabledRiskPolicyConfiguration]: "RDS snapshots should have encryption at rest enabled using Key Management Service (KMS).\n\n**Note: RDS snapshots with an engine type of SQL Server Express Edition or specific DB instance class types will not be evaluated since encryption is not supported for them.**",
                    [Contract.TypeNames.AwsRdsSnapshotPublicAccessExistsRiskPolicyConfiguration]: "RDS snapshots permissions should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsReconnaissanceRiskPolicyConfiguration]: "An identity that is collecting information about the environment while it has no history of performing similar actions. This is a common technique used by attackers to identify who has access to what and identify sensitive data.",
                    [Contract.TypeNames.AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "It is recommended to increase the default backup retention period to restore your systems to a point in time and minimize downtime and data loss. Backups help you recover more quickly from a security incident and strengthen the resilience of your systems. Tenable recommends a retention period of at least 7 days.\n\n**Note: You can change the default retention period for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AwsRedshiftClusterEncryptionDisabledRiskPolicyConfiguration]: "Redshift clusters should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsResourceTagSecretExistsRiskPolicyConfiguration]: "AWS resources should store sensitive data using Secrets Manager secrets and not tags.",
                    [Contract.TypeNames.AwsRoute53HostedZoneSigningDisabledRiskPolicyConfiguration]: "Route 53 public hosted zones should use Domain Name System Security Extensions (DNSSEC) signing to protect your domains against spoofing and cache poisoning attacks. By default, DNSSEC signing is not enabled for Route 53 hosted zones. For more information see: {{awsRoute53HostedZoneEnableSigningLink}}.",
                    [Contract.TypeNames.AwsS3BucketBlockPublicAccessDisabledRiskPolicyConfiguration]: "S3 Block Public Access at rest is a feature that is meant to prevent granting public access to your S3 buckets and objects by mistake, either through bucket policies or bucket ACLs.\n\nS3 Block Public Access provides four settings:\n1. BlockPublicAcls - trying to add an ACL that allows public access will fail.\n2. IgnorePublicAcls - blocks public access defined by current ACL configuration.\n3. BlockPublicPolicy - trying to add a bucket policy that allows public access will fail.\n4. RestrictPublicBuckets - blocks public and cross account access to buckets with public bucket policies.\n\nTenable Cloud Security verifies that all 4 of those settings are enabled and alerts otherwise.",
                    [Contract.TypeNames.AwsS3BucketEncryptionDisabledRiskPolicyConfiguration]: "S3 buckets should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsS3BucketHttpsOnlyDisabledRiskPolicyConfiguration]: "You can use secure data transfer through HTTPS to help prevent potential man-in-the-middle attacks.\nBucket policy should allow only encrypted connections over HTTPS using the aws:SecureTransport condition.\nFor more information see: {{awsS3BucketHttpsOnlyDisabledLink}}",
                    [Contract.TypeNames.AwsS3BucketObjectMfaDeleteDisabledRiskPolicyConfiguration]: "For S3 buckets with object versioning enabled, you can add another layer of security by enabling Multi-Factor Authentication (MFA) delete. MFA delete enforces two forms of authentication in any request to delete a version or change the versioning state of the bucket.\nMFA delete provides added security if security credentials are compromised and can help prevent accidental bucket deletions.\nFor more information see: {{awsS3BucketObjectMfaDeleteLink}}\n\n**Note: You cannot use MFA delete with {{awsS3BucketObjectLifecycleConfigurationLink}}**.",
                    [Contract.TypeNames.AwsS3BucketPublicAccessExistsRiskPolicyConfiguration]: "S3 bucket policy or Access Control List (ACL) should allow access only to specific identities and accounts.\nIf the bucket is public by design, you can exclude it and Tenable Cloud Security will not alert on it anymore.",
                    [Contract.TypeNames.AwsSageMakerNotebookInstanceDefaultEncryptionRiskPolicyConfiguration]: "SageMaker Notebook instance buckets should have encryption at rest enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.AwsSageMakerNotebookInstanceRootAccessEnabledRiskPolicyConfiguration]: "It is recommended to disable root access on the SageMaker Notebook instance. Root access means having administrator privileges, users with root access can access and edit all files on the instance, including system-critical files. Removing root access prevents notebook users from deleting system-level software, installing new software, and modifying essential environment components.\n\nNote: Lifecycle configurations need root access to be able to set up a notebook instance. Because of this, lifecycle configurations associated with a notebook instance always run with root access even if you disable root access for users.",
                    [Contract.TypeNames.AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskPolicyConfiguration]: "It is recommended to disable direct internet access on the SageMaker Notebook instance to block any public outbound connections.",
                    [Contract.TypeNames.AwsSecretsManagerSecretPublicAccessExistsRiskPolicyConfiguration]: "Secrets Manager secrets resource policy should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsSecretsManagerSecretRotationDisabledRiskPolicyConfiguration]: "Rotation is the process of periodically updating a secret. When you rotate a secret, you update the credentials in both the secret and the database or service.\nIn Secrets Manager, you can set up automatic rotation for your secrets. Applications that retrieve the secret from Secrets Manager automatically get the new credentials after rotation.\nSecrets Manager secrets automatic rotation should be enabled in order to reduce the risk of a compromised secret.",
                    [Contract.TypeNames.AwsSecurityHubRegionHubDisabledRiskPolicyConfiguration]: "It is recommended that AWS security hub be enabled for all regions. AWS Security Hub provides you with a comprehensive view of your security state in AWS and helps you check your environment against security industry standards and best practices.\n\n**Note: Tenable CS will only scan the active regions configured in your account.**",
                    [Contract.TypeNames.AwsSeverePermissionPrincipalRiskPolicyConfiguration]: "Monitor IAM identities that were granted admin privileges.",
                    [Contract.TypeNames.AwsSnsTopicEncryptionDisabledRiskPolicyConfiguration]: "SNS topics should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsSnsTopicHttpsOnlyDisabledRiskPolicyConfiguration]: "SNS topics should have transport encryption enabled to enforce secure data transfer using SSL.",
                    [Contract.TypeNames.AwsSnsTopicPublicAccessExistsRiskPolicyConfiguration]: "SNS topics resource policy should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsSqsQueueEncryptionDisabledRiskPolicyConfiguration]: "SQS queues should have encryption at rest enabled using Key Management Service (KMS).",
                    [Contract.TypeNames.AwsSqsQueuePublicAccessExistsRiskPolicyConfiguration]: "SQS queues resource policy should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AwsSsmParameterSecretExistsRiskPolicyConfiguration]: "Systems Manager parameters should store sensitive data using the SecureString parameter type that has an encrypted parameter value.\nTenable Cloud Security evaluates the parameter name and alerts if the name implies the parameter stores a secret value.",
                    [Contract.TypeNames.AwsSsoPermissionSetUnusedRiskPolicyConfiguration]: "Monitor SSO permission sets that are unused. By deleting unused permission sets, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates unused can be modified through the IAM Settings. {{iamSettingsLink}}",
                    [Contract.TypeNames.AwsTenantEntityCreationRiskPolicyConfiguration]: "Alert on new accounts created in your AWS organization.\n\n**Note: This policy only applies if Tenable Cloud Security monitors the entire AWS organization.**",
                    [Contract.TypeNames.AzureApplicationAppSettingsSecretExistsRiskPolicyConfiguration]: "App Service function, web and API applications should not store secrets in plain text app settings.\nInstead, you should use secrets from Azure Key Vault as values of app settings or connection strings.\nFor more information see: {{azureAppServiceKeyVaultReferencesLink}}",
                    [Contract.TypeNames.AzureBotServiceBotServiceInboundExternalWideRangeRiskPolicyConfiguration]: "Bot services should restrict inbound network access and invocation to specific IP addresses",
                    [Contract.TypeNames.AzureBotServiceBotServiceSharedKeyAccessEnabledRiskPolicyConfiguration]: "Requests to the Bot service can be authorized with either Microsoft Entra ID role-based access control (RBAC) or by using access keys.\nMicrosoft Entra ID provides superior security and ease of use over access keys, and is recommended by Microsoft.\nTo require clients to use Microsoft Entra ID to authorize requests, you can deny requests to the bot service by disabling local authentication.",
                    [Contract.TypeNames.AzureCognitiveServicesAccountInboundExternalWideRangeRiskPolicyConfiguration]: "Azure AI services should restrict inbound network access and invocation to specific IP addresses.",
                    [Contract.TypeNames.AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskPolicyConfiguration]: "Requests to the Azure AI service can be authorized with either Microsoft Entra ID role-based access control (RBAC) or by using access keys.\nMicrosoft Entra ID provides superior security and ease of use over access keys, and is recommended by Microsoft.\nTo require clients to use Microsoft Entra ID to authorize requests, you can deny requests to the AI service by disabling local authentication",
                    [Contract.TypeNames.AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskPolicyConfiguration]: "Azure AI services data loss prevention capabilities allow customers to configure the list of outbound URLs their Azure AI services resources are allowed to access. This creates another level of control for customers to prevent data loss.\n\n**Note: Due to Azure support, this policy evaluates specific Azure AI services**",
                    [Contract.TypeNames.AzureComputeVirtualMachineUnmanagedDiskExistsRiskPolicyConfiguration]: "Azure managed disks should be used since they provide less access time, enhanced manageability and higher availability.",
                    [Contract.TypeNames.AzureComputeVirtualMachineUserDataSecretExistsRiskPolicyConfiguration]: "Virtual Machine instances should store sensitive data like passwords and access tokens using Key Vault secrets and not in clear text via startup script.\n\nFor more information see: {{azureVirtualMachineSecureSecretsLink}}",
                    [Contract.TypeNames.AzureContainerRegistryRegistryAccessKeyEnabledRiskPolicyConfiguration]: "Each container registry includes an admin user account, which is disabled by default. If enabled, this admin user has full access to all the resources in the registry, which makes it a high-value target for attackers.\nInstead of using the admin user account, it is recommended to create a separate user account with the necessary permissions to perform the required tasks.\n\n**Note: To evaluate the policy, Tenable Cloud Security must have network access to the target container registry.**",
                    [Contract.TypeNames.AzureContainerRegistryRegistryInboundExternalWideRangeRiskPolicyConfiguration]: "Container registries should restrict inbound network access to specific IP addresses.",
                    [Contract.TypeNames.AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskPolicyConfiguration]: "Azure Kubernetes Service (AKS) can be configured to use Microsoft Entra ID for user authentication.\nIn this configuration, you sign in to an AKS cluster using an Microsoft Entra ID authentication token.\nOnce authenticated, you can use the built-in Kubernetes role-based access control (RBAC) to manage access to namespaces and cluster resources.\nThis feature frees you from having to separately manage user identities and credentials for Kubernetes.",
                    [Contract.TypeNames.AzureContainerServiceManagedClusterInboundExternalWideRangeRiskPolicyConfiguration]: "The Kubernetes API lets you query and manipulate the state of API objects in Kubernetes.\nThe AKS cluster's Kubernetes API should restrict inbound internet access to specific IP addresses via its configuration of authorized networks.\nRules that allow inbound internet access for an IP range of more than 65,535 addresses are considered wide.\nFor more information refer to the relevant AKS documentation: {{azureContainerServiceApiServerAuthorizedIpRangesLink}}.",
                    [Contract.TypeNames.AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "Kubernetes Secrets should have encryption at rest enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskPolicyConfiguration]: "Cluster nodes should be private, and should allow access only to private IP addresses. Disabling public IP addresses on cluster nodes restricts access to only internal networks, forcing attackers to obtain local network access before attempting to compromise the underlying Kubernetes hosts.",
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskPolicyConfiguration]: "Cosmos DB accounts should prevent management access using account keys to improve security and governance.\nWhen this feature is enabled, management operations can only be made using proper Microsoft Entra ID credentials.\nFor more information see: {{azureDocumentDbRbacLink}}",
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskPolicyConfiguration]: "It is recommended to use Azure private endpoints to access CosmosDB accounts, allowing clients and services to securely access data located over a network via an encrypted Private Link.",
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountWideRangeInboundRiskPolicyConfiguration]: "CosmosDB account network configuration should restrict access to specific IP addresses and virtual networks.\nUnrestricted firewall rules are rules that allow network inbound access from an IP range of more than 65,535 addresses.\nIf 'Accept connections from within public Azure datacenters' is enabled your CosmosDB account will allow network inbound access from all Azure resources, including resources not in your subscription.\nFor more information see: {{azureDocumentDbFirewallLink}}",
                    [Contract.TypeNames.AzureEventHubNamespaceInboundExternalWideRangeRiskPolicyConfiguration]: "Event Hubs namespaces should restrict inbound network access to specific IP addresses.",
                    [Contract.TypeNames.AzureExcessivePermissionApplicationServicePrincipalRiskPolicyConfiguration]: "Monitor Microsoft Entra ID application privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}",
                    [Contract.TypeNames.AzureExcessivePermissionGroupRiskPolicyConfiguration]: "Monitor Microsoft Entra ID group privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}",
                    [Contract.TypeNames.AzureExcessivePermissionManagedIdentityServicePrincipalRiskPolicyConfiguration]: "Monitor managed identity privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}",
                    [Contract.TypeNames.AzureExcessivePermissionUserRiskPolicyConfiguration]: "Monitor Microsoft Entra ID user privileges that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}",
                    [Contract.TypeNames.AzureGuestUserAdministratorRiskPolicyConfiguration]: "When possible, Microsoft Entra ID external or guest users should not be granted administrative roles, since they are not managed by your organization and could potentially be overlooked.",
                    [Contract.TypeNames.AzureInboundExternalComputeVirtualMachineRiskPolicyConfiguration]: "Virtual machines should be accessible directly only by specific IP addresses.\nPublic virtual machine has a public IP address and the network security groups associated with the machine contains inbound rules allowing access to an external IP range of more than 65,535 addresses.",
                    [Contract.TypeNames.AzureInboundExternalComputeVirtualMachineScaleSetRiskPolicyConfiguration]: "Virtual machine scale sets should be accessible directly only by specific IP addresses.\nPublic virtual machine scale set has at least one instance that has a public IP address and the network security groups associated with the instance contains inbound rules allowing access to an external IP range of more than 65,535 addresses.",
                    [Contract.TypeNames.AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskPolicyConfiguration]: "Activity log blob containers should have encryption at rest enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskPolicyConfiguration]: "Activity log blob container access level should allow access only to specific identities and accounts.",
                    [Contract.TypeNames.AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskPolicyConfiguration]: "Administrative Activity log activities record of all management API calls and are kept by default for only 90 days.\nActivity log diagnostic settings should be configured and retained for security audit and management purposes.",
                    [Contract.TypeNames.AzureKeyVaultVaultCertificateNotRotatedRiskPolicyConfiguration]: "Key Vault certificates should be rotated periodically to minimize the risk of a compromise. Tenable recommends a timeframe of at least once a year. For more information see: {{azureKeyVaultRenewCertificateLink}}.\n\n**Note:\n1. To evaluate the policy, Tenable must have access to the target key vaults via vault access policies or role-based access control permissions mode.\n2. You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AzureKeyVaultVaultEventDisabledRiskPolicyConfiguration]: "Enable AuditEvent logging for key vault instances to ensure interactions with key vaults are logged and available.",
                    [Contract.TypeNames.AzureKeyVaultVaultKeyExpirationDisabledRiskPolicyConfiguration]: "Ensure that keys in your Key Vault have an expiration date set to follow security best practices.\nAzure Key Vault does not automatically enforce the expiration of keys, meaning that they can still be retrieved even after the expiration date has passed.\nThe responsibility to manage the usage of keys lies with the application developers or administrators.\n\n**Note: To evaluate the policy, Tenable Cloud Security must have access to the target key vaults via vault access policies or role-based access control permissions mode.**",
                    [Contract.TypeNames.AzureKeyVaultVaultKeyNotRotatedRiskPolicyConfiguration]: "Key Vault keys should be rotated periodically to minimize the risk of a compromise. Tenable recommends a timeframe of every 2 years or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AzureKeyVaultVaultKeyRotationDisabledRiskPolicyConfiguration]: "Automatic key rotation should be enabled to remove the need for manual administration when keys expire at intervals determined by your organization's policy. Your organization should determine its own key expiration policy. Tenable recommends a timeframe of every 2 years or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AzureKeyVaultVaultPrivateEndpointNotExistsRiskPolicyConfiguration]: "It is recommended to use Azure private endpoints to access Key vaults, allowing clients and services to securely access data located over a network.",
                    [Contract.TypeNames.AzureKeyVaultVaultRbacDisabledRiskPolicyConfiguration]: "The vault access policy model is an existing authorization system built in Key Vault to provide access to keys, secrets and certificates.\nAzure role-based access control (Azure RBAC) is an authorization system built on Azure Resource Manager that provides fine-grained access management of Azure resources.\nAzure RBAC key benefits over vault access policies: \n1. Provides a unified access control model for Azure resources by using the same API across Azure services.\n2. Centralized access management for administrators - manage all Azure resources in one view.\n3. Integrated with Privileged Identity Management for time-based access control.\n4. Deny assignments - ability to exclude security principals at a particular scope.\n\nFor more information see: {{azureKeyVaultRbacMigrationLink}}",
                    [Contract.TypeNames.AzureKeyVaultVaultSecretExpirationDisabledRiskPolicyConfiguration]: "Key Vault secrets should have expiration date set to ensure they cannot be used without periodic rotation.\n\n**Note: in order to evaluate the policy, Tenable Cloud Security must have access to the target key vaults via vault access policies or role-based access control permissions mode.**",
                    [Contract.TypeNames.AzureKeyVaultVaultSoftDeleteDisabledRiskPolicyConfiguration]: "Ensure that your key vault is recoverable to prevent the immediate loss of data/security functions supported by key vault objects in case of accidental deletion (for example, authentication, validation, verification, non-repudiation, etc.).\nTo make your key vault recoverable, enable the \"Soft Delete\" and \"Do Not Purge\" functions.",
                    [Contract.TypeNames.AzureKeyVaultVaultWideRangeInboundRiskPolicyConfiguration]: "By default, when you create a new key vault, the firewall is disabled. The key vault still restricts to secrets, keys, and certificates stored in key vault by requiring Microsoft Entra ID authentication and access policy permissions.\nFor greater security, firewall rules should restrict access to specific IP addresses and virtual networks.\nPublic firewall rules are rules that allow network inbound access from an IP range of more than 65,535 addresses.\n\nFor more information see: {{azureKeyVaultConfigureFirewallsAndVirtualNetworksLink}}",
                    [Contract.TypeNames.AzureLogicWorkflowInboundExternalWideRangeRiskPolicyConfiguration]: "Logic apps should restrict inbound network access and invocation to specific IP addresses.",
                    [Contract.TypeNames.AzureLogicWorkflowSecretExistsRiskPolicyConfiguration]: "The Logic app workflow should not store sensitive data in clear text via string parameters or action inputs.\nMigrate your secrets and passwords to a secured key management system like Azure Key Vault, and update your workflow accordingly.\nFor more information, refer to the relevant Azure documentation: {{azureLogicWorkflowSecureParametersDefinitionLink}}.\n\n**Note:** \n1. Since workflow versions cannot be deleted, you need to rotate all your secrets\n2. For logic app resources, Tenable Cloud Security scans **HTTP** and **Get Secret (key vault)** actions",
                    [Contract.TypeNames.AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "It is recommended to enable Machine Learning workspace logging to collect important information about successful and failed requests. This information can be used to monitor individual requests and to diagnose issues with the service.",
                    [Contract.TypeNames.AzureMachineLearningWorkspaceInboundExternalWideRangeRiskPolicyConfiguration]: "Machine Learning workspaces should restrict inbound network access and invocation to specific IP addresses.",
                    [Contract.TypeNames.AzureManagedIdentityManagedIdentityInactiveRiskPolicyConfiguration]: "Monitor Microsoft Entra ID managed identities that are inactive. By deleting inactive managed identities, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates inactivity can be modified through the IAM Settings. {{iamSettingsLink}}",
                    [Contract.TypeNames.AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "Microsoft Entra ID-only authentication should be enabled for MySQL database servers.\nThis authentication method provides superior security and ease of use over MySQL authentication, and is recommended as a security best practice.\nThis policy is only applicable to Flexible Servers.",
                    [Contract.TypeNames.AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskPolicyConfiguration]: "MySQL database server parameter audit_log_events helps MySQL Database to log items such as successful and failed connection attempts to the server. Log data can be used to identify, troubleshoot, and repair configuration errors and suboptimal performance.",
                    [Contract.TypeNames.AzureMySqlFlexibleServerAuditLogDisabledRiskPolicyConfiguration]: "MySQL database server parameter audit_log_enabled helps MySQL Database log items such as connection attempts to the server, DDL/DML access, and more. Log data can be used to identify, troubleshoot, and repair configuration errors and suboptimal performance.",
                    [Contract.TypeNames.AzureMySqlFlexibleServerInsecureTlsProtocolRiskPolicyConfiguration]: "MySQL database server parameter tls_version provides a new layer of security by connecting database servers to client applications using Transport Layer Security (TLS). Enforcing TLS connections between database server and client applications helps protect against \"man in the middle\" attacks by encrypting the data stream between the server and application.",
                    [Contract.TypeNames.AzureMySqlServerHttpsOnlyDisabledRiskPolicyConfiguration]: "MySQL database servers should have transport encryption enabled to enforce secure data transfer using SSL.",
                    [Contract.TypeNames.AzureMySqlServerWideRangeInboundRiskPolicyConfiguration]: "MySQL database servers network configuration should restrict access to specific IP addresses and virtual networks.\nUnrestricted firewall rules are rules that allow network inbound access from an IP range of more than 65,535 addresses.\nIf 'Allow access to Azure services' is enabled your database will allow unrestricted network inbound access from all Azure resources, including resources from **any** subscription.\nFor more information see: {{azureMySqlFirewallRulesLink}}",
                    [Contract.TypeNames.AzureNetworkAccessManagementRiskPolicyConfiguration]: "An identity that is modifying network access rules while it has no history of performing similar actions. This is a common technique used by attackers to gain a foothold in the environment.",
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskPolicyConfiguration]: "Network security group rules using service tags should implement an additional layer of security.\n**This vulnerability was discovered by the Tenable Research team.**\nTenable Research discovered a vulnerability in Azure that allows an attacker to bypass firewall rules that are based on Azure service tags by forging requests from trusted services.\nIf you rely on such firewall rules for security, you are potentially at risk from this vulnerability.\nYou should take immediate action to mitigate the issue by ensuring you are protected via robust layers of authentication and authorization.",
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "Network security groups rules should restrict RDP access to specific IP addresses.",
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "Network security groups rules should restrict SSH access to specific IP addresses.",
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskPolicyConfiguration]: "Network security groups rules should restrict UDP access to specific IP addresses.",
                    [Contract.TypeNames.AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskPolicyConfiguration]: "You should attach network security groups to subnets to filter network traffic.\nFailure to do so can result in unrestricted network traffic when virtual machines are launched inside the subnet.\n\n**Note: You cannot attach a network security group on a gateway subnet.**",
                    [Contract.TypeNames.AzurePermissionManagementRiskPolicyConfiguration]: "An identity that is modifying permissions while it has no history of performing similar actions. This is a common technique used by attackers to gain a foothold in the environment.",
                    [Contract.TypeNames.AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "Microsoft Entra ID-only authentication should be enabled for PostgreSQL database servers.\nThis authentication method provides superior security and ease of use over PostgreSQL authentication, and is recommended as a security best practice.\nThis policy is only applicable to Flexible Servers.",
                    [Contract.TypeNames.AzurePostgreSqlServerConnectionThrottlingDisabledRiskPolicyConfiguration]: "PostgreSQL database server parameter connection_throttling enables temporary connection throttling per IP for too many invalid password login failures.",
                    [Contract.TypeNames.AzurePostgreSqlServerHttpsOnlyDisabledRiskPolicyConfiguration]: "PostgreSQL database servers should have transport encryption enabled to enforce secure data transfer using SSL.",
                    [Contract.TypeNames.AzurePostgreSqlServerLogCheckpointsDisabledRiskPolicyConfiguration]: "PostgreSQL database server parameter log_checkpoints causes checkpoints and restart points to be logged in the server log.\nSome statistics are included in the log messages, including the number of buffers written and the time spent writing them, which can help identify, troubleshoot, and repair errors and performance issues.",
                    [Contract.TypeNames.AzurePostgreSqlServerLogConnectionsDisabledRiskPolicyConfiguration]: "PostgreSQL database server parameter log_connections causes each attempted connection to the server to be logged, as well as successful completion of client authentication, which can help identify, troubleshoot, and repair errors and performance issues.",
                    [Contract.TypeNames.AzurePostgreSqlServerLogDisconnectionsDisabledRiskPolicyConfiguration]: "PostgreSQL database server parameter log_disconnections logs session termination, and includes the duration of the session, which can help identify, troubleshoot, and repair errors and performance issues.",
                    [Contract.TypeNames.AzurePostgreSqlServerLogRetentionRiskPolicyConfiguration]: "PostgreSQL database server parameter log_retention_days sets how many days a log file is saved for.\nThe parameter should be set to at least 3 days in order to help identify, troubleshoot, and repair errors and performance issues.",
                    [Contract.TypeNames.AzurePostgreSqlServerWideRangeInboundRiskPolicyConfiguration]: "PostgreSQL database servers network configuration should restrict access to specific IP addresses and virtual networks.\nUnrestricted firewall rules allow network inbound access for an IP range of more than 65,535 addresses.\nIf 'Allow access to Azure services' is enabled your database will allow unrestricted network inbound access from all Azure resources, including resources from **any** subscription.",
                    [Contract.TypeNames.AzureResourceTagSecretExistsRiskPolicyConfiguration]: "Azure resources should store sensitive data using Key Vault secrets and not tags.",
                    [Contract.TypeNames.AzureSeverePermissionPrincipalRiskPolicyConfiguration]: "Monitor Microsoft Entra ID identities that were granted admin privileges.",
                    [Contract.TypeNames.AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskPolicyConfiguration]: "To enable Microsoft Entra ID authentication for your server, set an admin. Microsoft Entra ID authentication allows you to connect to Microsoft Azure SQL Database and SQL Data Warehouse using Microsoft Entra ID identities. This centralizes identity and permission management for database users and other Microsoft services.",
                    [Contract.TypeNames.AzureSqlServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "Microsoft Entra ID-only authentication should be enabled for SQL servers.\nThis authentication method provides superior security and ease of use over SQL authentication, and is recommended as a security best practice.",
                    [Contract.TypeNames.AzureSqlServerDatabaseEncryptionDisabledRiskPolicyConfiguration]: "SQL databases should have encryption at rest enabled.",
                    [Contract.TypeNames.AzureSqlServerDefaultEncryptionRiskPolicyConfiguration]: "SQL server should have transparent data encryption enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.AzureSqlServerEventDisabledRiskPolicyConfiguration]: "SQL server auditing tracks database events and should be enabled for security audit and management purposes.",
                    [Contract.TypeNames.AzureSqlServerEventRetentionTimeFrameRiskPolicyConfiguration]: "SQL Server audit retention should be configured for a sufficient period of time to track anomalies and suspicious activity. Tenable recommends a retention period of at least 90 days. This policy applies only to SQL Servers where the audit logs are stored in a designated storage account.\n\n**Note: You can {{changeDefaultRetentionPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.AzureSqlServerSecurityAlertPolicyDisabledRiskPolicyConfiguration]: "It is recommended to enable Microsoft Defender. Microsoft Defender for SQL enables security analysis, classifying sensitive data, surfacing and mitigating potential database vulnerabilities, and detecting anomalous activities that could indicate a threat to your database.",
                    [Contract.TypeNames.AzureSqlServerWideRangeInboundRiskPolicyConfiguration]: "SQL servers network configuration should restrict access to specific IP addresses and virtual networks.\nUnrestricted firewall rules are rules that allow network inbound access from an IP range of more than 65,535 addresses.\nIf 'Allow Azure services and resources to access this server' is enabled your database will allow unrestricted network inbound access from all Azure resources, including resources from **any** subscription.\nFor more information see: {{azureSqlFirewallRulesLink}}",
                    [Contract.TypeNames.AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskPolicyConfiguration]: "It is recommended to enable Azure trusted services access to storage account to help Azure services work as intended. Some Azure services that interact with storage accounts operate from networks that can't be granted access through network rules. To help this type of service work as intended, allow the set of trusted Azure services to bypass the network rules.",
                    [Contract.TypeNames.AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskPolicyConfiguration]: "Storage Account blob containers access level should allow access only to specific identities and accounts.\nThere are two public access levels:\n\n**Container** - anonymous identities can enumerate and read the blobs inside the container.\n**Blob** - anonymous identities cannot enumerate but they can read the blobs inside the container if they know the blobs names.\n\nIf no anonymous access is needed on any container in the storage account, it’s recommended to set allowBlobPublicAccess false at the account level, which forbids any container to accept anonymous access in the future.",
                    [Contract.TypeNames.AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "It is recommended to enable storage account blob service logging to collect important information about successful and failed requests to a storage service. This information can be used to monitor individual requests and to diagnose issues with a storage service.\n\n**Note: Logging isn’t available for certain storage account types, which will not be evaluated.**",
                    [Contract.TypeNames.AzureStorageStorageAccountDefaultEncryptionRiskPolicyConfiguration]: "Storage accounts should have encryption at rest enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.AzureStorageStorageAccountHttpsOnlyDisabledRiskPolicyConfiguration]: "Storage accounts should have transport encryption enabled to enforce secure data transfer.",
                    [Contract.TypeNames.AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskPolicyConfiguration]: "Infrastructure encryption should be enabled to protect against potential compromises of encryption algorithms or keys. Azure Storage automatically encrypts all data at the network level. For higher assurance, enable infrastructure-level encryption.\n\n**Note: Enabling both default encryption and infrastructure encryption may impact read and write speeds due to the additional resource overhead required for the secondary encryption.**",
                    [Contract.TypeNames.AzureStorageStorageAccountInsecureTlsProtocolRiskPolicyConfiguration]: "Storage accounts should use TLS protocols that enforce v1.2 and above, since communication between the storage account and its clients is vulnerable to interception.",
                    [Contract.TypeNames.AzureStorageStorageAccountPrivateEndpointNotExistsRiskPolicyConfiguration]: "It is recommended to use Azure private endpoints to access storage accounts, allowing clients and services to securely access data located over a network via an encrypted Private Link",
                    [Contract.TypeNames.AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "It is recommended to enable storage account queue service logging to collect important information about successful and failed requests to a storage service. This information can be used to monitor individual requests and to diagnose issues with a storage service.\n\n**Note: Logging isn’t available for certain storage account types, which will not be evaluated.**",
                    [Contract.TypeNames.AzureStorageStorageAccountSharedKeyAccessEnabledRiskPolicyConfiguration]: "Requests to storage accounts can be authorized with either role-based access control (RBAC) or by using Shared Key access keys.\nMicrosoft Entra ID provides superior security and ease of use over Shared Key, and is recommended by Microsoft.\nTo require clients to use Microsoft Entra ID to authorize requests, you can deny requests to the storage account that are authorized with Shared Key.",
                    [Contract.TypeNames.AzureStorageStorageAccountSoftDeleteDisabledRiskPolicyConfiguration]: "It is recommended that both Azure containers with attached blob storage and standalone containers with blob storage be made recoverable by enabling the soft delete configuration.",
                    [Contract.TypeNames.AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "It is recommended to enable storage account table service logging to collect important information about successful and failed requests to a storage service. This information can be used to monitor individual requests and to diagnose issues with a storage service.\n\n**Note: Logging isn’t available for certain storage account types, which will not be evaluated.**",
                    [Contract.TypeNames.AzureStorageStorageAccountWideRangeInboundRiskPolicyConfiguration]: "By default, when you create a new storage account, the network is open to the internet.\nThe storage account still restricts access to blob containers, file shares, tables and queues by requiring Microsoft Entra ID authentication and access policy permissions.\nFor greater security, firewall rules should restrict access to specific IP addresses and virtual networks.\nThe firewall rules should not allow access from an IP range of more than 65,535 addresses.\nFor more information see: {{azureStorageAccountFirewallRulesLink}}",
                    [Contract.TypeNames.AzureTenantEntityCreationRiskPolicyConfiguration]: "Alert on new subscriptions created in your Azure management group.\n\n**Note: this policy only applies if Tenable Cloud Security monitors an entire Azure management group.**",
                    [Contract.TypeNames.AzureWebApplicationHttpsOnlyDisabledRiskPolicyConfiguration]: "App Services should have HTTPS only enabled to enforce secure data transfer using SSL.",
                    [Contract.TypeNames.AzureWebApplicationInboundExternalWideRangeRiskPolicyConfiguration]: "App Service function, web and API applications should not allow unauthenticated access.\nInstead, you should authenticate users via built-in Azure authentication mechanisms, or restrict network access to the function.\nSee: {{azureWebApplicationAuthenticationLink}} for more information.\n\n**Note:** If the App Service application is public by design, or implements a custom authentication mechanism, you can exclude it from the policy and Tenable Cloud Security will not alert on it anymore.",
                    [Contract.TypeNames.AzureWebApplicationInsecureTlsProtocolRiskPolicyConfiguration]: "App Services should enforce clients to communicate using TLS v1.2 or above.",
                    [Contract.TypeNames.AzureWebApplicationScmInboundExternalWideRangeRiskPolicyConfiguration]: "Kudu is a web-based Git repository manager that provides source control management (SCM) for deploying and managing your Azure App Service. The SCM site acts as a management interface for these services.\nApp Service function, web and API applications should not allow wide network inbound internet access to the SCM site. Instead, you should limit the access only to specific IP addresses, or deny all access if you are not using the SCM site at all.\nNote that the SCM site also requires Microsoft Entra ID authentication, in addition to the network access.\nFor more information see: {{azureWebApplicationAccessRulesLink}}",
                    [Contract.TypeNames.CodeTerraformDnsRecordSetExistsRiskPolicyConfiguration]: "Usage of DNS provider data blocks in Terraform triggers DNS requests, which can result in data exfiltration through DNS tunneling. This method can transmit sensitive data to attacker-controlled servers, posing a significant security risk, and potentially leading to severe data breaches.",
                    [Contract.TypeNames.CodeTerraformExternalExistsRiskPolicyConfiguration]: "Utilizing the external data block in Terraform configurations allows external programs, such as Python or bash scripts, to execute on the machine running Terraform. This configuration poses a significant risk, as it can be exploited to exfiltrate sensitive data like EC2 credentials, create a reverse shell, or carry out other malicious activities targeting the system. The potential for local code execution makes this a security concern.",
                    [Contract.TypeNames.ContainerImageMaliciousFileRiskPolicyConfiguration]: "Malware, short for Malicious Software, is important due to its potential to compromise the security and functionality of computer systems, networks and devices. It encompasses a range of harmful software, including viruses, worms, Trojans, ransomware, spyware and more. Malware can lead to data breaches, financial losses, identity theft, and disrupt normal operations. Its significance lies in the fact that it poses a serious threat to both individual uses and organizations, compromising sensitive information, privacy, and the integrity of cloud infrastructure. We recommend urgently remediating this issue.",
                    [Contract.TypeNames.ContainerImageOperatingSystemEndOfLifeRiskPolicyConfiguration]: "Running containers with vulnerabilities exposes your cloud environment to the risk of being exploited by an attacker.\nAn attacker can exploit a vulnerability to steal or manipulate sensitive data, or take control over various assets in your cloud environment.\nAn operating system that is EOL receives no security updates, bug fixes, or patches. That means the workload security is compromised.\nTenable strongly recommends that you update the operating system on this container to a newer version, and then deploy the image to all relevant workloads.",
                    [Contract.TypeNames.ContainerImageOperatingSystemUnpatchedRiskPolicyConfiguration]: "Running containers with vulnerabilities exposes your cloud environment to the risk of being exploited by an attacker.\nAn attacker can exploit a vulnerability to steal or manipulate sensitive data, or take control over various assets in your cloud environment.\nThe operating system on this workload contains a large number of vulnerable software packages, which means that the workload security is compromised.\nTenable strongly recommends that you update the operating system to a newer version in which most of these vulnerabilities have been addressed.",
                    [Contract.TypeNames.ContainerImageRepositoryVulnerabilityRiskPolicyConfiguration]: "Running containers with vulnerabilities exposes your cloud environment to the risk of being exploited by an attacker.\nAn attacker can exploit a vulnerability to steal or manipulate sensitive data, or take control over various assets in your cloud environment.\nTenable strongly recommends that you update the software packages on this container by upgrading them to versions in which these vulnerabilities have been addressed, and then deploy the image to all relevant workloads.",
                    [Contract.TypeNames.ContainerImageVulnerabilityRiskPolicyConfiguration]: "Running containers with vulnerabilities exposes your cloud environment to the risk of being exploited by an attacker.\nAn attacker can exploit a vulnerability to steal or manipulate sensitive data, or take control over various assets in your cloud environment.\nTenable strongly recommends that you update the software packages on this container by upgrading them to versions in which these vulnerabilities have been addressed, and then deploy the image to all relevant workloads.",
                    [Contract.TypeNames.GciDirectoryGroupInactiveRiskPolicyConfiguration]: "Monitor Google Workspace group identities that are inactive. By suspending or deleting inactive groups, you can mitigate the risk of unauthorized access, data breaches, and other security threats. Tenable generates inactivity recommendations based on the usage during a learning period, which is influenced by the type of group members (user and service identities). You can change this learning period via the IAM settings page. {{iamSettingsLink}}\nFindings are created only for non-external groups.",
                    [Contract.TypeNames.GciDirectoryUserInactiveRiskPolicyConfiguration]: "Monitor Google Workspace users that are inactive. By suspending or deleting inactive users, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates inactivity can be modified through the IAM Settings. {{iamSettingsLink}}\nFindings are created only for non-external users.",
                    [Contract.TypeNames.GcpApiKeysKeyClientRestrictionNotExistsRiskPolicyConfiguration]: "It is recommended to restrict API key usage to trusted hosts, HTTP referrers, and apps to reduce the impact that a compromised key can have on your applications.",
                    [Contract.TypeNames.GcpApiKeysKeyExistsRiskPolicyConfiguration]: "To avoid the security risk in using API keys, it is recommended to use standard authentication flow instead. API Keys should only be used for services in cases where other authentication methods are unavailable.",
                    [Contract.TypeNames.GcpApiKeysKeyNotRotatedRiskPolicyConfiguration]: "API keys should only be used for services in cases where other authentication methods are unavailable. If keys are in use, they should be rotated regularly. Tenable recommends a timeframe of every 90 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.GcpApiKeysKeyServiceRestrictionNotExistsRiskPolicyConfiguration]: "It is recommended to restrict API key usage to only those APIs required by the application. API restrictions specify the enabled APIs that this key can call.",
                    [Contract.TypeNames.GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "App Engine services should store sensitive data as Secret Manager secrets and not environment variables.\nFor more information see: {{gcpSecretManagerSecretCreateAndAccessLink}}.",
                    [Contract.TypeNames.GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskPolicyConfiguration]: "App Engine Services should enforce secure data transfer using HTTPS.",
                    [Contract.TypeNames.GcpArtifactRegistryPublicAccessExistsRiskPolicyConfiguration]: "Artifact registries stores artifacts and build dependencies for your GCP applications. An artifact registry may contain sensitive artifacts that shouldn't be publicly accessible. Although registries are private by default, they can be made publicly accessible via the `allUsers` or `allAuthenticatedUsers` IAM permissions.\nFor more information, refer to Google Cloud documentation about {{gcpArtifactRegistryProtectArtifactsLink}}",
                    [Contract.TypeNames.GcpBigQueryDatasetEncryptionDisabledRiskPolicyConfiguration]: "BigQuery datasets should have default encryption at rest enabled using customer-managed encryption keys (CMEK).",
                    [Contract.TypeNames.GcpBigQueryDatasetPublicAccessExistsRiskPolicyConfiguration]: "BigQuery datasets should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpBigQueryDatasetTableEncryptionDisabledRiskPolicyConfiguration]: "BigQuery datasets and tables should have encryption at rest enabled using customer-managed encryption keys (CMEK).",
                    [Contract.TypeNames.GcpCloudBuildBuildTriggerDefaultServiceAccountRiskPolicyConfiguration]: "The default service account has permissions that are excessively broad for many use cases, granting access to resources like Cloud Storage, Artifact Registry or Container Registry.\n**This vulnerability was discovered by the Tenable Research team.**\nThe Tenable Research team discovered a vulnerability in GCP that allows an attacker to leverage the high privileges of the default service account in other GCP services that are created when a Cloud Function is created or updated.\nThe team discovered that when a GCP user creates or updates a Cloud Function, a multi-step backend process is triggered. This process, among other things, attaches a default Cloud Build service account to the Cloud Build instance created as part of the function’s deployment. An attacker who gains access to create or update a Cloud Function could exploit this deployment process to escalate privileges to the default Cloud Build service account.",
                    [Contract.TypeNames.GcpCloudRunServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "Cloud Run services should store sensitive data like passwords and access tokens using Secret Manager secrets and not in clear text via environment variables.\nFor more information see: {{gcpCloudRunServiceSecretsLink}}",
                    [Contract.TypeNames.GcpCloudRunServicePublicAccessExistsRiskPolicyConfiguration]: "Cloud Run services should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpComputeBackendServiceLoggingNotEnabledRiskPolicyConfiguration]: "Load balancer backend service logging should be enabled so that you can monitor performance, diagnose issues and identify potential security threats.",
                    [Contract.TypeNames.GcpComputeImagePublicAccessExistsRiskPolicyConfiguration]: "Compute Engine images should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskPolicyConfiguration]: "Project wide SSH keys are stored in project metadata and can be used to login into all the VM instances within the project.\nIt is recommended to use Instance specific SSH keys which can limit the attack surface if the project wide SSH keys are compromised.\nIf OS Login is enabled, SSH keys in instance metadata are ignored, and therefore blocking project-wide SSH keys is not necessary.",
                    [Contract.TypeNames.GcpComputeInstanceDefaultServiceAccountRiskPolicyConfiguration]: "The default Compute Engine service account has the Editor basic role on the project, which contains permissions to create and delete resources for most Google Cloud services.\nInstead of using the default service account, VM instances should adopt the principle of least privilege by creating a new service account and assigning it only the permissions needed.",
                    [Contract.TypeNames.GcpComputeInstanceIpForwardingEnabledRiskPolicyConfiguration]: "By default, Google Cloud performs strict source and destination checking for packets so that a VM cannot forward a packet originated by another VM.\nIf IP forwarding is enabled, it allows an instance to send and receive packets with non-matching destination or source IPs. Except for advanced packet routing scenarios, IP forwarding should be disabled to prevent data loss and information disclosure.\n\n**Note: instances created by GKE are excluded from evaluation because they need to have IP forwarding enabled, see: {{gcpComputeInstanceIpForwardingEnabledLink}} for more information**",
                    [Contract.TypeNames.GcpComputeInstanceMemoryEncryptionNotEnabledRiskPolicyConfiguration]: "Confidential Computing ensures that sensitive data remains encrypted even during processing by compute instances.\nEnsure this feature is enabled to safeguard against potential data breaches or unauthorized access.\n\n**Note: Only supported machine types will be evaluated**",
                    [Contract.TypeNames.GcpComputeInstanceShieldedNotEnabledRiskPolicyConfiguration]: "Shielded VMs are virtual machines (VMs) on GCP that are hardened by a set of security controls that help defend against advanced threats and ensure that the boot loader and firmware on your VMs are signed and untampered.\nIt is recommended to launch Compute instances with Shielded VM enabled.",
                    [Contract.TypeNames.GcpComputeInstanceSshSerialPortEnabledRiskPolicyConfiguration]: "Serial port access can help you troubleshoot instances that are not properly starting up or are not accessible.\nIf you enable it on an instance, clients can attempt to connect to that instance from any IP address without any IP based access restrictions.\nFor more information see: {{gcpComputeSshSerialPortEnabledLink}}",
                    [Contract.TypeNames.GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration]: "VM instances should store sensitive data like passwords and access tokens using Secret Manager secrets and not in clear text via startup script.\nFor more information see: {{gcpSecretManagerSecretCreateAndAccessLink}}",
                    [Contract.TypeNames.GcpComputeLoadBalancerInsecureSslPolicyRiskPolicyConfiguration]: "Load balancers should use SSL policies that enforce TLS protocols v1.2 and above with strong cipher suites.\nFor more information see: {{gcpComputeSslPolicyLink}}",
                    [Contract.TypeNames.GcpComputeProjectSshIamNotEnabledRiskPolicyConfiguration]: "OS Login simplifies SSH access management by linking your Linux user account to your Google identity.\nAdministrators can easily manage access to VM instances at either an instance or project level by setting IAM permissions, or even enforce at the organization level using organization policy.\nFor more information see: {{gcpComputeSshIamEnabledLink}}",
                    [Contract.TypeNames.GcpComputeVpcFlowLogsNotEnabledRiskPolicyConfiguration]: "It's recommended to enable Flow Logs for every business-critical VPC subnet, and ensure proper configuration.\nFlow Logs is a feature that lets you capture information about the IP traffic going to and from network interfaces in subnets.\nOnce enabled, you can view and retrieve its data in Stackdriver Logging.\nFlow logs should be configured as follows:\nAggregation Interval = 5 seconds\nInclude metadata = Enabled\nSample rate = 100%",
                    [Contract.TypeNames.GcpComputeVpcLegacyVpcRiskPolicyConfiguration]: "A project should not have a legacy network configured.\nLegacy networks have a single network IPv4 prefix range and a single gateway IP address for the whole network.\nThe network is global in scope and spans all cloud regions.\nLegacy networks can have an impact for high network traffic projects and are subject to a single point of contention or failure.",
                    [Contract.TypeNames.GcpComputeVpcUnrestrictedIcmpInboundAccessRiskPolicyConfiguration]: "VPC firewall rules let you allow or deny connections to or from your virtual machine instances based on a configuration that you specify.\nFor protocols such as ICMP you should allow network inbound access only to specific IP address ranges as required.",
                    [Contract.TypeNames.GcpComputeVpcUnrestrictedRdpInboundAccessRiskPolicyConfiguration]: "VPC firewall rules let you allow or deny connections to or from your virtual machine instances based on a configuration that you specify.\nFor administration ports such as RDP you should allow network inbound access only to specific IP address ranges as required.",
                    [Contract.TypeNames.GcpComputeVpcUnrestrictedSshInboundAccessRiskPolicyConfiguration]: "VPC firewall rules let you allow or deny connections to or from your virtual machine instances based on a configuration that you specify.\nFor administration ports such as SSH you should allow network inbound access only to specific IP address ranges as required.",
                    [Contract.TypeNames.GcpContainerClusterAttributeBasedAuthorizationEnabledRiskPolicyConfiguration]: "Legacy authorization, also known as Attribute-Based Access Control (ABAC), has been superseded by Role-Based Access Control (RBAC), and is not under active development. RBAC is the recommended way to manage permissions in Kubernetes.",
                    [Contract.TypeNames.GcpContainerClusterDefaultServiceAccountRiskPolicyConfiguration]: "The default Compute Engine service account has the Editor basic role on the project, which contains permissions to create and delete resources for most Google Cloud services.\nInstead of using the default service account, Kubernetes clusters should use the workload identity feature or to adopt the principle of least privilege by creating a new service account and assigning it only the permissions needed.\nGKE requires the service account to have, at minimum, the permissions granted by the Logs Writer, Monitoring Metric Writer and Monitoring Viewer predefined IAM roles.\n\nFor more information see: {{gcpContainerLeastPrivilegeServiceAccountsLink}}",
                    [Contract.TypeNames.GcpContainerClusterInboundExternalWideRangeRiskPolicyConfiguration]: "The Kubernetes API lets you query and manipulate the state of API objects in Kubernetes.\nThe GKE cluster's Kubernetes API should restrict inbound internet access to specific IP addresses via its configuration of authorized networks.\nIf authorized networks is disabled, any public IP address can access the Kubernetes API without any restrictions.\nWide rules allow network inbound access for an IP range of more than 65,535 addresses.\nIf 'Allow access through Google Cloud public IP addresses' is enabled the Kubernetes API will accept network inbound internet access from all GCP resources, including virtual machines from **any** project.\n\nFor more information see: {{gcpContainerClusterControlPlaneAccessAuthorizationLink}}",
                    [Contract.TypeNames.GcpContainerClusterInstanceInboundExternalRiskPolicyConfiguration]: "Cluster nodes should be private, and should allow access only to private IP addresses.  Disabling public IP addresses on cluster nodes restricts access to only internal networks, forcing attackers to obtain local network access before attempting to compromise the underlying Kubernetes hosts.",
                    [Contract.TypeNames.GcpContainerClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "Application-layer secrets encryption provides extra security for sensitive data like user-defined secrets and secrets needed for cluster operation (e.g., service account keys), all stored in etcd.\nThis allows you to use a self-managed Cloud KMS key to encrypt data at the application level, countering potential attackers who breach etcd access.",
                    [Contract.TypeNames.GcpContainerClusterLegacyComputeMetadataServerEnabledRiskPolicyConfiguration]: "The legacy GCE metadata endpoint allows simple HTTP requests to be made, returning sensitive information. To prevent the enumeration of metadata endpoints and data exfiltration, the legacy metadata endpoint must be disabled.",
                    [Contract.TypeNames.GcpContainerClusterWorkloadIdentityDisabledRiskPolicyConfiguration]: "Every node stores its metadata on a metadata server.\nSome of this metadata, such as kubelet credentials and the VM instance identity token, is sensitive, and should not be exposed to a Kubernetes workload.\nEnabling the GKE metadata server prevents pods that are not running on the host network from accessing this metadata, and facilitates Workload Identity.",
                    [Contract.TypeNames.GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskPolicyConfiguration]: "DNS managed zones DNSSEC should use strong key-signing algorithms to protect your domains against spoofing and cache poisoning attacks.",
                    [Contract.TypeNames.GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskPolicyConfiguration]: "DNS managed zones DNSSEC should use strong zone-signing algorithms to protect your domains against spoofing and cache poisoning attacks.",
                    [Contract.TypeNames.GcpDnsManagedZoneDnsSecNotEnabledRiskPolicyConfiguration]: "DNS public managed zones should use Domain Name System Security Extensions (DNSSEC) signing to protect your domains against spoofing and cache poisoning attacks. By default, DNSSEC signing is not enabled.",
                    [Contract.TypeNames.GcpExcessivePermissionGroupRiskPolicyConfiguration]: "Remove privileges of Google Workspace groups that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}\nFindings are created only for groups with permissions granted directly or through group membership.",
                    [Contract.TypeNames.GcpExcessivePermissionServiceAccountRiskPolicyConfiguration]: "Remove privileges of service accounts that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}\nFindings are created only for service accounts with permissions granted directly or through group membership.",
                    [Contract.TypeNames.GcpExcessivePermissionUserRiskPolicyConfiguration]: "Remove privileges of Google Workspace users that were unused for more than the duration defined in the identity learning period. {{iamSettingsLink}}\nFindings are created only for users with permissions granted directly or through group membership.",
                    [Contract.TypeNames.GcpFunctionsFunctionBuildDefaultServiceAccountRiskPolicyConfiguration]: "The default service account has permissions that are excessively broad for many use cases, granting access to resources like Cloud Storage, Artifact Registry or Container Registry.\n**This vulnerability was discovered by the Tenable Research team.** The Tenable Research team discovered a vulnerability in GCP that allows an attacker to leverage the high privileges of the default service account in other GCP services that are created when a Cloud Function is created or updated.\nThe team discovered that when a GCP user creates or updates a Cloud Function, a multi-step backend process is triggered. This process, among other things, attaches a default Cloud Build service account to the Cloud Build instance created as part of the function’s deployment. An attacker who gains access to create or update a Cloud Function could exploit this deployment process to escalate privileges to the default Cloud Build service account.",
                    [Contract.TypeNames.GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "Cloud functions should store sensitive data as Secret Manager secrets and not environment variables.\nFor more information see: {{gcpSecretManagerSecretCreateAndAccessLink}}",
                    [Contract.TypeNames.GcpFunctionsFunctionPublicAccessExistsRiskPolicyConfiguration]: "Cloud functions should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpIamServiceAccountInactiveRiskPolicyConfiguration]: "Monitor GCP service accounts that are inactive. By disabling or deleting inactive service accounts, you can mitigate the risk of unauthorized access, data breaches, and other security threats. The learning period that evaluates inactivity can be modified through the IAM Settings. {{iamSettingsLink}}\nFindings are created only for non-external service accounts.",
                    [Contract.TypeNames.GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyConfiguration]: "Service accounts should use only GCP managed keys that cannot be downloaded and are rotated automatically. If your service accounts require user managed keys, they should be rotated periodically. Tenable recommends a timeframe of every 90 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.GcpIamServiceAccountUserManagedKeyExistsRiskPolicyConfiguration]: "Service accounts should use only GCP managed keys that cannot be downloaded and are rotated automatically.",
                    [Contract.TypeNames.GcpInboundExternalComputeInstanceRiskPolicyConfiguration]: "VM instances should only be directly accessible by specific IP addresses. A VM instance is considered public if it has a public IP address and inbound VPC firewall rules allowing access to an external IP range of more than 65,535 addresses.",
                    [Contract.TypeNames.GcpKmsKeyRingKeyPublicAccessExistsRiskPolicyConfiguration]: "Cloud KMS keys should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyConfiguration]: "Cloud KMS keys should have rotation set for a sufficient period of time. Tenable recommends a timeframe of every 90 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.GcpKmsKeyRingPublicAccessExistsRiskPolicyConfiguration]: "Cloud KMS key rings should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpLoggingAuditLogTenantDefaultNotEnabledRiskPolicyConfiguration]: "It is recommended that audit logs are configured for the project to track by default all admin activities and read, write access to user data.\nTenable Cloud Security evaluates if all audit logs operations are enabled by default for the project on all the services without exemptions, and alerts otherwise.\nThe configuration can also be inherited from the folder or organization level and apply to all projects in the hierarchy.\n\n**Note: enabling the logs might result in your Cloud Project being charged for the additional logs usage, see {{gcpLoggingPricingLink}} for more information.**",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskPolicyConfiguration]: "Monitor audit configuration changes to ensure that all project activities can be audited at any point in time.",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskPolicyConfiguration]: "Monitor changes to cloud SQL instance configurations to potentially reduce the time needed to detect and correct SQL server misconfigurations.",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskPolicyConfiguration]: "Monitor changes to VPC network firewall rules to gain insight into network access changes and potentially reduce the time it takes to detect suspicious activity.",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskPolicyConfiguration]: "Monitor changes to VPC network routes to help ensure that all VPC traffic flows through an expected path.",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskPolicyConfiguration]: "Monitor changes to VPC networks to help ensure that VPC traffic flow isn't negatively impacted.",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskPolicyConfiguration]: "Monitor changes to cloud storage IAM permissions to potentially reduce the time needed to detect and correct permissions on sensitive cloud storage buckets and objects inside the bucket.",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskPolicyConfiguration]: "Monitor changes to custom IAM role creation, deletion, and updating activities to help identify overprivileged roles at an early stage.",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskPolicyConfiguration]: "Monitor all role/owner assignments to prevent unnecessary assignments to users/service-accounts, and to avoid misuses of projects and resources.",
                    [Contract.TypeNames.GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskPolicyConfiguration]: "It is recommended to enable integrity monitoring on the Vertex AI Workbench instance to automatically check and monitor the runtime boot integrity of your shielded notebook instances using Google Cloud Monitoring.",
                    [Contract.TypeNames.GcpNotebooksInstanceDefaultEncryptionRiskPolicyConfiguration]: "Vertex AI Workbench Notebook instance disk should have encryption at rest enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.GcpNotebooksInstanceRootAccessEnabledRiskPolicyConfiguration]: "It is recommended to disable root access on the Vertex AI Workbench instance. Root access means having administrator privileges. Removing root access minimizes the risk of unauthorized modifications, and enhances security by preventing potential misuse or exploitation of superuser privileges.",
                    [Contract.TypeNames.GcpNotebooksInstanceSecureBootNotEnabledRiskPolicyConfiguration]: "It is recommended to enable secure boot on the Vertex AI Workbench instance. Secure Boot ensures that only software signed by trusted authorities can run during startup, protecting against boot-level malware and unauthorized system changes.",
                    [Contract.TypeNames.GcpNotebooksInstanceVirtualTpmNotEnabledRiskPolicyConfiguration]: "It is recommended to enable vTPM on the Vertex AI Workbench instance. vTPM provides hardware-based encryption, secure boot, and trusted storage for cryptographic keys, helping to meet compliance requirements and protect sensitive data from unauthorized access and tampering.",
                    [Contract.TypeNames.GcpPermissionManagementRiskPolicyConfiguration]: "An identity that is modifying permissions while it has no history of performing similar actions. This is a common technique used by attackers to gain a foothold in the environment.",
                    [Contract.TypeNames.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPolicyConfiguration]: "Service accounts should not have * Admin, Owner or Editor roles on the project level or above. Instead, in order to implement least privilege best practices, permissions should be assigned to a service account based on the business needs.",
                    [Contract.TypeNames.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration]: "Service accounts should not be assigned the **iam.serviceAccounts.getAccessToken, iam.serviceAccounts.actAs or iam.serviceAccounts.signJWT** permissions that allow impersonation and can lead to privileges escalation, at the project level or above.\nInstead, in order to implement least privilege best practices, these permissions should be assigned to a service account for a specific service account based on the business needs.",
                    [Contract.TypeNames.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration]: "Google workspace users should not be assigned the **iam.serviceAccounts.getAccessToken, iam.serviceAccounts.actAs or iam.serviceAccounts.signJWT** permissions that allow impersonation and can lead to privileges escalation, at the project level or above.\nInstead, in order to implement least privilege best practices, these permissions should be assigned to a user for a specific service account based on the business needs.",
                    [Contract.TypeNames.GcpPubSubSubscriptionPublicAccessExistsRiskPolicyConfiguration]: "Pub/Sub subscriptions should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpPubSubTopicPublicAccessExistsRiskPolicyConfiguration]: "Pub/Sub topics should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpResourceTagSecretExistsRiskPolicyConfiguration]: "GCP resources should store sensitive data using Secret Manager secrets and not labels.\nFor more information see: {{gcpSecretManagerSecretCreateAndAccessLink}}",
                    [Contract.TypeNames.GcpSecretManagerSecretRotationTimeFrameRiskPolicyConfiguration]: "Secret Manager secrets should have rotation enabled to limit the impact in the case of a leaked secret. Tenable recommends a timeframe of at least every 90 days.\nFor more information see: {{gcpSecretManagerSecretRotationLink}}.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.GcpSeverePermissionPrincipalRiskPolicyConfiguration]: "Monitor Google identities that were granted admin privileges in a project.",
                    [Contract.TypeNames.GcpSqlInstanceBackupDisabledRiskPolicyConfiguration]: "Backups provide a way to restore a Cloud SQL instance to recover lost data or recover from a problem with that instance. Automated backups need to be set for any instance that contains data that should be protected from loss or damage. This recommendation is applicable for SQL Server, PostgreSql, MySql generation 1 and MySql generation 2 instances.",
                    [Contract.TypeNames.GcpSqlInstanceHttpsOnlyDisabledRiskPolicyConfiguration]: "Cloud SQL instances should have transport encryption enabled to enforce secure data transfer using SSL.",
                    [Contract.TypeNames.GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskPolicyConfiguration]: "Cloud SQL MySQL Instance **'skip_show_database'** prevents users from listing the database names if they do not have the SHOW DATABASES privilege.\nBy default listing the database names is permitted to all users, but displays the names of only those databases for which the user has any privilege.",
                    [Contract.TypeNames.GcpSqlInstanceMySqlLoadClientFileEnabledRiskPolicyConfiguration]: "Cloud SQL MySQL Instance 'local_infile' flag controls the capability for LOAD DATA statements. Depending on the setting, the instance refuses or permits local data loading. If enabled, it can be used by attackers when combined with a SQL injection vulnerability.\nFor more information see: {{gcpSqlInstanceMysqlLoadClientFileLink}}.",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlAuditDisabledRiskPolicyConfiguration]: "PostgreSQL instance flag ‘cloudsql.enable_pgaudit’ enables database auditing in PostgreSQL through the open-source pgAudit extension. Set this flag to **On** and configure the ’pgaudit.log’ flag to define the scope of operations recommended by your organization. Doing so provides detailed session and object logging to comply with government, financial, & ISO standards, and provides auditing capabilities to mitigate threats by monitoring security events on the instance.",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL instance flag 'log_connections' causes each attempted connection to the server to be logged, as well as successful completion of client authentication, which can help identify, troubleshoot, and repair errors and performance issues.",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL instance flag 'log_hostname' logs the connecting hostname in addition to the IP address, which can help identify, troubleshoot, and repair errors and performance issues.\n\n**Note: In order for the hostname logging to take effect, the log_connections flag must be enabled also.**",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL instance flag 'log_disconnections' logs session termination, and includes the duration of the session, which can help identify, troubleshoot, and repair errors and performance issues.",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogErrorVerbosityRiskPolicyConfiguration]: "Cloud SQL PostgreSQL instance flag ‘log_error_verbosity’ defines the verbosity/details of messages logged. The flag should be set to DEFAULT or stricter for better auditing and troubleshooting.",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogMinLevelRiskPolicyConfiguration]: "Cloud SQL PostgreSQL instance flag 'log_min_statement' defines the minimum message severity level that is considered an error. The flag should be set to **Warning** or stricter to classify errors correctly for better auditing and troubleshooting.",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL instance flag 'log_min_duration_statement' defines the minimum execution time in milliseconds of statements that will be logged. The flag should be set to -1 or deleted in order to disable this feature, since logged statements can include sensitive information.",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskPolicyConfiguration]: "Cloud SQL PostgreSQL instance flag 'log_min_error_statement' defines the minimum message severity level that is considered as error. The flag should be set to Error or stricter in order to classify errors correctly for better auditing and troubleshooting.",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlStatementLogDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL instance flag ‘log_statement’ controls which SQL statements are logged. The value **ddl** logs all data definition statements. The value **mod** logs all ddl statements, plus data-modifying statements. The flag should be set to **ddl**, **mod** or **all** for better auditing and troubleshooting.",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskPolicyConfiguration]: "Cloud SQL instance flag 'contained database authentication' enables contained databases on the instance of SQL Server Database Engine. A contained database includes all database settings and metadata required to define the database. Set this flag to **Off** to prevent users from connecting to the database without authenticating a login at the Database Engine level.",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskPolicyConfiguration]: "Cloud SQL instance flag 'cross db ownership chaining' allows users of databases access to other databases besides the one they are currently using. If enabled, it can lead to unintended access to sensitive data.",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskPolicyConfiguration]: "Cloud SQL instance flag 'remote access' allows remote execution of stored procedures. The flag is enabled by default and should be disabled since it can be abused to launch a Denial-of-Service (DoS) attack on the instance.",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskPolicyConfiguration]: "Cloud SQL instance flag '3625' limits the amount of information returned to users who are not members of the sysadmin fixed server role, by masking sensitive parameters of some error messages. This can help prevent disclosure of sensitive information.",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerScriptExecutionEnabledRiskPolicyConfiguration]: "Cloud SQL instance flag 'external scripts enabled' allows the execution of scripts with certain remote language extensions. If enabled, it can be used by attackers to run remote execution attacks using malicious scripts.",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskPolicyConfiguration]: "Cloud SQL instance flag ‘user connections’ defines the maximum number of simultaneous user connections that are allowed on an instance of SQL Server. The default is 0, which means that the maximum (32,767) user connections are allowed. However, if there is a number defined here that limits connections, any new requests will be dropped, potentially causing lost data or outages for those using the database. It is recommended to limit the number of simultaneous user connections according to your organization’s needs to avoid lost data or outages.",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerUserOptionsEnabledRiskPolicyConfiguration]: "Cloud SQL instance flag ‘user options’ specifies global defaults for all users. It is recommended to delete this flag so that users can’t override these defaults by using the SET statement.",
                    [Contract.TypeNames.GcpSqlInstanceWideRangeInboundRiskPolicyConfiguration]: "Cloud SQL instances network configuration should restrict access to specific authorized networks. Unrestricted authorized networks allow inbound access from an IP range of more than 65,535 addresses.",
                    [Contract.TypeNames.GcpStorageBucketLockedRetentionPolicyNotEnabledRiskPolicyConfiguration]: "Enabling retention policies on log buckets protects logs stored in cloud storage buckets from being overwritten or accidentally deleted.\nIt's recommended to set up retention policies and configure Bucket Lock on all storage buckets used as log sinks.\n\n**Note: This policy applies only to buckets which are configured as a sink destination and where object versioning is not enabled.**",
                    [Contract.TypeNames.GcpStorageBucketPublicAccessExistsRiskPolicyConfiguration]: "Cloud Storage buckets should only be accessible to specific principals.",
                    [Contract.TypeNames.GcpStorageBucketUniformAccessControlDisabledRiskPolicyConfiguration]: "Cloud Storage offers two systems for granting users permission to access your buckets and objects: Cloud Identity and Access Management (Cloud IAM) and Access Control Lists (ACLs).\nEnabling uniform bucket-level access guarantees that if a Storage bucket is not publicly accessible, no object in the bucket is publicly accessible either.\nIt is recommended to use uniform bucket-level access to unify and simplify how you grant access to your Cloud Storage resources.\nFor more information see: {{gcpStorageBucketUniformAccessControlLink}}",
                    [Contract.TypeNames.GcpTenantEntityCloudAssetServiceNotEnabledRiskPolicyConfiguration]: "The GCP resources and IAM policies captured by GCP Cloud Asset Inventory enables security analysis, resource change tracking, and compliance auditing.\nIt is recommended that GCP Cloud Asset Inventory be enabled for all GCP projects.",
                    [Contract.TypeNames.GcpTenantEntityCreationRiskPolicyConfiguration]: "Alert on new projects created in your GCP organization.\n\n**Note: this policy only applies if Tenable Cloud Security monitors the entire GCP organization.**",
                    [Contract.TypeNames.GcpTenantEntityUnusedRiskPolicyConfiguration]: "Alert on projects that have low usage for an extended period of time. Tenable recommends a timeframe of 30 days for projects, and 180 days for OAuth tokens. The usage is based on API activity, network activity, service usage and more.\nRemoving low usage projects can provide the following impact and benefits to your organization:\n1. Reduction in security risks\n2. Reduction in unnecessary spending.\nFor more information see: {{gcpUnusedTenantRecommendationLink}}.\n\n**Note: You can {{changeDefaultInactivityPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.KubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration]: "Requires resources from the *italic*required labels** list to contain specified labels, with values from the *italic*required labels** list.\nConfigure the resource types and required labels in the **Parameters** tab.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration]: "Requires containers to only have capabilities from the *italic*allowed capabilities** list, and to drop all capabilities from the *italic*capabilities to drop** list.\nConfigure both lists in the **Parameters** tab.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfiguration]: "Requires container images to match the pattern of an allowed repository.\nConfigure the *italic*allowed repositories** pattern list in the **Parameters** tab.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerImageDigestNotExistsRiskPolicyConfiguration]: "Requires container images to have a digest.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfiguration]: "Requires container images to not match the pattern of a disallowed repository.\nConfigure the *italic*disallowed repositories** pattern list in the **Parameters** tab.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration]: "Requires containers to have the **allowPrivilegeEscalation** field set as **false**.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerPrivilegedRiskPolicyConfiguration]: "Requires containers to not have the **privileged** field set as **true**.\nFor more information, see {{kubernetesPodSecurityStandardsPrivilegedLink}}.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration]: "Requires containers to be non-root by defining either the **runAsNonRoot** field as **true**, or the **runAsUser** as any value other than **0**.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration]: "Requires containers to not have the **hostIPC** field set as **true**.\nFor more information, see {{kubernetesPodSecurityStandardsHostNamespacesLink}}.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration]: "Requires containers to not have the **hostNetwork** field set as **true**.\nFor more information, see {{kubernetesPodSecurityStandardsHostNamespacesLink}}.",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration]: "Requires containers to not have the **hostPID** field set as **true**.\nFor more information, see {{kubernetesPodSecurityStandardsHostNamespacesLink}}.",
                    [Contract.TypeNames.KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskPolicyConfiguration]: "The **AlwaysAdmit** plugin permits all API server requests without validation or filtering, bypassing critical security checks.\nThis allows potentially harmful or unauthorized actions to be processed without restriction.\nIt is recommended to disable the plugin and enable more restrictive admission control plugins that validate requests before they are processed.",
                    [Contract.TypeNames.KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskPolicyConfiguration]: "The API server can be configured with the \"AlwaysAllow\" authorization mode, which permits all requests without evaluation.\nThis mode is highly insecure and should never be used on a production cluster, as it poses a significant security risk.\nInstead, use a more secure authorization mode, such as RBAC.",
                    [Contract.TypeNames.KubernetesClusterApiServerBasicAuthenticationEnabledRiskPolicyConfiguration]: "Basic authentication uses plaintext credentials for authentication.\nCurrently, the basic authentication credentials last indefinitely, and the password cannot be changed without restarting the API server.\nThe basic authentication is currently supported for compatibility and should not be used.",
                    [Contract.TypeNames.KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "**etcd** is a highly available key-value store for Kubernetes' REST API objects, which are sensitive and require protection through client authentication.\nThe API server must identify itself to **etcd** using an SSL Certificate Authority file.",
                    [Contract.TypeNames.KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskPolicyConfiguration]: "**etcd** is a highly-available key value store used by Kubernetes deployments for persistent storage of all of its REST API objects.\nThese objects are sensitive in nature and should be protected by client authentication.\nThis requires the API server to identify itself to the **etcd** server using a SSL Certificate Authority file.",
                    [Contract.TypeNames.KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "**etcd** is a highly available key-value store used by Kubernetes for persistent storage of REST API objects.\nThese objects are sensitive and require protection through secure communication.\nTo ensure confidentiality and integrity, the API server must authenticate itself to the **etcd** server using a client certificate and key, enabling encrypted communication.",
                    [Contract.TypeNames.KubernetesClusterApiServerInsecureListenerEnabledRiskPolicyConfiguration]: "When you bind the API server to an insecure address, anyone who can connect to it over the insecure port gains unauthenticated and unencrypted access to your master node.\nThe API server doesn't do any authentication checking for insecure binds, and traffic to the Insecure API port is not encrypted, allowing attackers to potentially read sensitive data in transit.\nThe secure port is used to serve https with authentication and authorization.\nIf you disable it, no https traffic is served and all traffic is served unencrypted.",
                    [Contract.TypeNames.KubernetesClusterApiServerInsecureTlsProtocolRiskPolicyConfiguration]: "TLS ciphers have a number of known vulnerabilities and weaknesses, which can reduce the protection provided by them.\nBy default, Kubernetes supports several TLS cipher suites, including some with security concerns, weakening the protection provided.",
                    [Contract.TypeNames.KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskPolicyConfiguration]: "Connections from the API server to the kubelet are used to fetch pod logs, attach to running pods via kubectl, and utilize the kubelet's port-forwarding functionality.These connections terminate at the kubelet's HTTPS endpoint.\nBy default, the API server does not verify the kubelet's serving certificate, leaving the connection vulnerable to man-in-the-middle attacks and unsafe for use over untrusted or public networks.\nTo secure these connections, configure the API server to verify the kubelet's certificate authority.",
                    [Contract.TypeNames.KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "By default, the API server does not authenticate itself to the kubelet's HTTPS endpoints, causing requests from the API server to be treated as anonymous.\nTo mitigate this, you should configure certificate-based authentication to ensure the API server properly authenticates itself when submitting requests to kubelets.",
                    [Contract.TypeNames.KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "The **NamespaceLifecycle** admission control plugin ensures that objects cannot be created in non-existent namespaces, and that namespaces undergoing termination are not used to create new objects.\nIt is recommended to enable this plugin to ensure the integrity of the namespace termination process and the availability of newer objects.",
                    [Contract.TypeNames.KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "The NodeRestriction admission control plugin ensures kubelets are limited to modifying only their own Node API object and Pod API objects bound to their node.\nThis helps enforce proper access controls, preventing kubelets from affecting resources on other nodes.",
                    [Contract.TypeNames.KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskPolicyConfiguration]: "Role-Based Access Control (RBAC) provides fine-grained control over the actions that various entities can perform on specific resources within the cluster.\nTo enhance security, it is recommended to enable and use the RBAC authorization mode.",
                    [Contract.TypeNames.KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "The **SecurityContextConstraint** (SCC) admission control plugin restricts pod creation to those that meet security requirements.\nIt rejects pods that do not comply with defined constraints, enforcing secure configurations and limiting risky actions.\nEnabling this plugin is critical to enforcing security best practices.",
                    [Contract.TypeNames.KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "A service account is a type of non-human account that provides a distinct identity in a Kubernetes cluster.\nThis identity is useful in situations like authenticating to the API server or implementing identity-based security policies.\nThis admission controller implements automation for **serviceAccounts**.\nTenable recommends enabling this admission controller plugin to be able to use the Kubernetes **serviceAccounts** objects.",
                    [Contract.TypeNames.KubernetesClusterApiServerServiceAccountLookupDisabledRiskPolicyConfiguration]: "If **--service-account-lookup** is not enabled, the API server only verifies that the authentication token is valid but does not check whether the associated service account exists in etcd.\nThis allows the use of a service account token even after the corresponding service account is deleted, creating a time-of-check-to-time-of-use security vulnerability.",
                    [Contract.TypeNames.KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskPolicyConfiguration]: "By default, if no **--service-account-key-file** is specified, the API server uses the private key from the TLS serving certificate to verify service account tokens.\nTo allow for key rotation and improve security, a separate public/private key pair should be used specifically for signing service account tokens.\nTherefore, the public key should be provided to the API server using the **--service-account-key-file** option.",
                    [Contract.TypeNames.KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskPolicyConfiguration]: "The token-based authentication utilizes static tokens to authenticate requests to the API server.\nThe tokens are stored in clear-text in a file on the API server, and cannot be revoked or rotated without restarting the API server.\nThis means that it's not recommended to use static token-based authentication.",
                    [Contract.TypeNames.KubernetesClusterApiServerTlsCertificateNotExistRiskPolicyConfiguration]: "API server communication contains sensitive parameters that should remain encrypted in transit. Configure the API server to serve only HTTPS traffic.",
                    [Contract.TypeNames.KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskPolicyConfiguration]: "The system:anonymous user, system:unauthenticated group, and system:authenticated group are publicly accessible, and therefore shouldn't be granted any elevated permissions.",
                    [Contract.TypeNames.KubernetesClusterClusterAdminRoleBindingExistsRiskPolicyConfiguration]: "The cluster-admin RBAC role provides wide-ranging powers over the environment, and should only be used when absolutely necessary.",
                    [Contract.TypeNames.KubernetesClusterControllerManagerInsecureListenerIpAddressRiskPolicyConfiguration]: "The Kubernetes Controller Manager API service, which runs on port 10257/TCP by default, provides health and metrics information but lacks authentication and encryption.\nTo minimize the cluster's attack surface, restrict the Controller Manager to local communication by binding it to the loopback address.",
                    [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskPolicyConfiguration]: "The Kubernetes Controller Manager creates a dedicated service account for each controller in the kube-system namespace, generating credentials and API clients for them.\nWhen **--use-service-account-credentials** is not set, all control loops share the same credentials, which may grant excessive permissions.\nTo enforce least privilege access, set **--use-service-account-credentials=true** so each control loop runs with its own service account, restricting permissions according to RBAC policies.",
                    [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskPolicyConfiguration]: "The Kubernetes Controller Manager signs service account tokens using a public/private key pair.\nIf a private key file is not set, token signing may rely on an automatically generated key, which can limit key rotation and security controls.\nTo ensure secure authentication and allow key rotation, specify a private key file using **--service-account-private-key-file**.",
                    [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskPolicyConfiguration]: "Processes running within pods must verify the API server’s certificate to establish secure connections. Without a specified **--root-ca-file**, pods may lack the trusted root certificate needed to validate the API server’s identity, making them vulnerable to man-in-the-middle attacks. To ensure secure communication, configure the Kubernetes Controller Manager with **--root-ca-file** to inject the trusted root CA bundle into pods.",
                    [Contract.TypeNames.KubernetesClusterDefaultNamespaceUsedRiskPolicyConfiguration]: "Kubernetes provides a default namespace for clusters, where objects are placed if no namespace is specified for them. Placing objects in this default namespace makes it more difficult to apply RBAC and other controls. Instead, you should segregate resources in a cluster by creating alternate namespaces, making it easier to manage resources.",
                    [Contract.TypeNames.KubernetesClusterEndOfLifeRiskPolicyConfiguration]: "When a cluster version reaches EOL, it is no longer actively supported or maintained. It is recommended to update the version before it becomes EOL.\nFor more information about release history and EOL dates, see the {{kubernetesReleasesLink}}.\n\nNote: This finding relates to the EOL date defined by Kubernetes, and not that of your cloud provider.",
                    [Contract.TypeNames.KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "etcd is a highly-available key value store used by Kubernetes deployments for persistent storage of all of its REST API objects.\nWithout client certificate authentication, unauthorized clients may access or modify this data.\nTo protect etcd from unauthorized access, enable client authentication using valid TLS certificates.",
                    [Contract.TypeNames.KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "etcd is a highly-available key value store used by Kubernetes deployments for persistent storage of all of its REST API objects.\nWhen peer-client certificate authentication is disabled, etcd cluster members are not required to authenticate, increasing the risk of unauthorized access.\nTo secure etcd, enable peer-client certificate authentication to ensure only trusted peers can communicate within the cluster.",
                    [Contract.TypeNames.KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration]: "etcd is a highly-available key value store used by Kubernetes deployments for persistent storage of all of its REST API objects.\nWhen peer auto TLS is enabled, etcd automatically generates self-signed certificates for peer authentication, which are less secure than manually issued certificates.\nTo protect etcd from unauthorized access, disable peer auto TLS and configure valid TLS certificates for secure peer-to-peer communication.",
                    [Contract.TypeNames.KubernetesClusterEtcdPeerTlsCertificateNotExistRiskPolicyConfiguration]: "etcd is a highly-available key value store used by Kubernetes deployments for persistent storage of all of its REST API objects.\nWithout peer certificate files, communication between etcd cluster members may be unencrypted, exposing data to interception.\nTo secure etcd, configure valid TLS certificates for peer-to-peer communication.",
                    [Contract.TypeNames.KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration]: "etcd is a highly-available key value store used by Kubernetes deployments for persistent storage of all of its REST API objects.\nWhen auto TLS is enabled, etcd automatically generates self-signed certificates for client connections, which are not as secure as manually managed certificates.\nTo protect etcd from unauthorized access, disable auto TLS and enable client authentication using valid, manually-issued certificates.",
                    [Contract.TypeNames.KubernetesClusterEtcdTlsCertificateNotExistRiskPolicyConfiguration]: "etcd is a highly-available key value store used by Kubernetes deployments for persistent storage of all of its REST API objects.\nWithout specified certificate files, communication with etcd may be unencrypted, exposing data to interception.\nTo protect sensitive data, configure etcd to use valid TLS certificates for secure, encrypted communication.",
                    [Contract.TypeNames.KubernetesClusterIdentityProviderAuthenticationDisabledRiskPolicyConfiguration]: "The ability to revoke credentials if they are compromised or no longer required is a key control with any authentication mechanism.\nKubernetes client certificate authentication does not allow this due to a lack of support for certificate revocation.",
                    [Contract.TypeNames.KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskPolicyConfiguration]: "When the --anonymous-auth flag is enabled (set to True), requests that are not rejected by other configured authentication methods are treated as anonymous requests.\nThese requests are then served by the Kubelet server. You should rely on authentication to authorize access and disallow anonymous requests.",
                    [Contract.TypeNames.KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskPolicyConfiguration]: "Kubelets can be configured to allow all authenticated requests (even anonymous ones) without needing explicit authorization checks from the apiserver (--authorization-mode argument is set to AlwaysAllow).\nYou should restrict this behavior and only allow explicitly authorized requests.",
                    [Contract.TypeNames.KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "By default, the API server does not verify the kubelet’s serving certificate, which makes the connection subject to man-in-the-middle attacks, and unsafe to run over untrusted and/or public networks.\nEnabling kubelet certificate authentication ensures that the API server can authenticate the kubelet before submitting any requests.",
                    [Contract.TypeNames.KubernetesClusterKubeletClientCertificateRotationDisabledRiskPolicyConfiguration]: "The --rotate-certificates setting prompts kubelet to automatically generate new CSRs as its current client certificates expire.\nThis periodic rotation prevents downtime caused by expired certificates, ensuring availability in the CIA security triad.\n\nNote: This advice is relevant if kubelets obtain certificates from the API server. If certificates are sourced externally (e.g., Vault), manual rotation is required.",
                    [Contract.TypeNames.KubernetesClusterKubeletHostnameOverrideUsedRiskPolicyConfiguration]: "Overriding hostnames may disrupt TLS communication between the kubelet and apiserver, and make it difficult to associate logs with specific nodes and process them for security analytics.\nIt is recommended to configure kubelet nodes with resolvable FQDNs and refrain from using the --hostname-override argument.",
                    [Contract.TypeNames.KubernetesClusterKubeletInsecureTlsProtocolRiskPolicyConfiguration]: "TLS ciphers have a number of known vulnerabilities and weaknesses, which can reduce the protection they provide. By default, Kubernetes supports several TLS cipher suites, including some with security concerns, weakening the protection provided.",
                    [Contract.TypeNames.KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskPolicyConfiguration]: "Security-relevant information should be captured. Adjust the --eventRecordQPS flag on the Kubelet to control the rate of event gathering.\nSetting this too low could result in relevant events not being logged, but setting it to 0 (unlimited) could result in a denial of service on the kubelet.",
                    [Contract.TypeNames.KubernetesClusterKubeletManageIpTablesDisabledRiskPolicyConfiguration]: "Kubelets can automatically manage the required changes to iptables based on how you choose your networking options for the pods. It is recommended to let kubelets manage the changes to iptables.\nThis ensures that the iptables configuration remains in sync with pods networking configuration. Manually configuring iptables with dynamic pod network configuration changes might hamper the communication between pods/containers, and to the outside world.",
                    [Contract.TypeNames.KubernetesClusterKubeletReadOnlyPortEnabledRiskPolicyConfiguration]: "The Kubelet process provides a read-only API in addition to the main Kubelet API.\nUnauthenticated access is provided to this read-only API which could possibly retrieve potentially sensitive information about the cluster.",
                    [Contract.TypeNames.KubernetesClusterKubeletServerCertificateRotationDisabledRiskPolicyConfiguration]: "RotateKubeletServerCertificate causes the kubelet to both request a serving certificate after bootstrapping its client credentials, and rotate the certificate as its existing credentials expire.\nThis periodic rotation prevents downtime caused by expired certificates, ensuring availability in the CIA security triad.\n\nNote: This advice is relevant if kubelets obtain certificates from the API server. If certificates are sourced externally (e.g., Vault), manual rotation is required.",
                    [Contract.TypeNames.KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskPolicyConfiguration]: "Setting idle timeouts ensures that you are protected against Denial-of-Service attacks, inactive connections and running out of ephemeral ports.\n\nNote: By default, --streaming-connection-idle-timeout is set to 4 hours which might be too high for your environment.\nSetting this as appropriate would additionally ensure that such streaming connections are timed out after serving legitimate use cases.",
                    [Contract.TypeNames.KubernetesClusterKubeletTlsCertificateNotExistRiskPolicyConfiguration]: "The Kubernetes API server connects to the kubelet to fetch pod logs, attach to running pods, and enable port forwarding.\nThese connections terminate at the kubelet’s HTTPS endpoint.\nToֿ ensure secure communication, configure the kubelet with a valid TLS certificate and private key.",
                    [Contract.TypeNames.KubernetesClusterNetworkPolicyUnsupportedRiskPolicyConfiguration]: "There are a variety of CNI plugins available for Kubernetes.\nIf the CNI in use does not support Network Policies, it may not be possible to effectively restrict traffic in the cluster.",
                    [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskPolicyConfiguration]: "You should restrict the worker node configuration files' permissions and set their ownership to maintain the integrity of the files.\nThe files should be writable by only the administrators on the system, and they should be owned by root:root.",
                    [Contract.TypeNames.KubernetesClusterPlatformEndOfLifeRiskPolicyConfiguration]: "When a cluster version reaches EOL, it is no longer actively supported or maintained. It is recommended to update the version before it becomes EOL.\nFor more information about release history and EOL dates, see the {{openshiftReleasesLink}}.",
                    [Contract.TypeNames.KubernetesClusterResourceEncryptionDisabledRiskPolicyConfiguration]: "When **etcd** encryption is used, it is important to ensure that the appropriate set of encryption providers is used.\nCurrently, the **aescbc**, **kms** and **secretbox** are likely suitable options.",
                    [Contract.TypeNames.KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskPolicyConfiguration]: "Profiling allows for the identification of specific performance bottlenecks.\nIt generates a significant amount of program data that could potentially be exploited to uncover system and program details.\nIf you are not experiencing any bottlenecks and do not need the profiler for troubleshooting purposes, it is recommended to turn it off to reduce the potential attack surface.",
                    [Contract.TypeNames.KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskPolicyConfiguration]: "To ensure that rights granted to applications can be more easily audited and reviewed, the default service account should not be used.\nInstead, wherever a Kubernetes workload requires specific access to the Kubernetes API server, you should create user-managed service accounts.",
                    [Contract.TypeNames.KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskPolicyConfiguration]: "The Pod Security Standards define three different policies to broadly cover the security spectrum.\nThese policies are cumulative and range from highly-permissive to highly-restrictive.\nThe Privileged policy is purposely-open and entirely unrestricted.\nThe Baseline policy is aimed at ease of adoption for common containerized workloads while preventing known privilege escalations.\nThe Restricted policy is aimed at enforcing current Pod hardening best practices, at the expense of some compatibility.\nTenable recommends having a Restricted policy in place to ensure that overprivileged pods are not provisioned in your Kubernetes environment.\n\n**Note: kube-system namespace will not be evaluated**",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskPolicyConfiguration]: "Containers run with a default set of capabilities, and are parts of the rights generally granted on a Linux system to the root user.\nSince applications running in containers generally don't need these capabilities, they should be minimized to follow the principle of least privilege.\n\n**Note: Workloads part of kube-system namespace will not be evaluated**",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskPolicyConfiguration]: "Running a container in a privileged mode enables the container to access the host’s resources and kernel capabilities.\nSince an attacker may gain access to privileged containers, it is not recommended to run privileged containers.\nTenable Cloud Security evaluates Kubernetes workloads and alerts on every container spec that has the securityContext.privileged flag set to true.\n\n**Note: Workloads part of kube-system namespace will not be evaluated**",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration]: "A container running with the allowPrivilegeEscalation flag set to true may have processes that can gain more privileges than their parent.\nAttackers may gain access to a container and escalate its privileges.\nTenable Cloud Security evaluates Kubernetes workloads and alerts on every container spec that has the securityContext.allowPrivilegeEscalation flag set to true.\n\n**Note: Workloads part of kube-system namespace will not be evaluated**",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration]: "Containers which run as the root user have an escalated likelihood of container breakout.\nIdeally, all containers should run as a defined non-UID 0 user.\nThere should be at least one Pod Security Standard policy defined which restricts root user access.\n\n**Note: Workloads part of kube-system namespace will not be evaluated**",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration]: "A container running in the host's IPC namespace can use IPC to interact with processes outside the container.\nThere should be at least one Pod Security Standard policy defined which does not permit containers to share the host IPC namespace.\n\n**Note: Workloads part of kube-system namespace will not be evaluated**",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration]: "A container running in the host's PID namespace can inspect processes running outside the container.\nIf the container also has access to ptrace capabilities this can be used to escalate privileges outside of the container.\nThere should be at least one Pod Security Standard policy defined which does not permit containers to share the hostPID namespace.\n\n**Note: Workloads part of kube-system namespace will not be evaluated**",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration]: "A container running in the host's network namespace could access the local loopback device, and could access network traffic to and from other pods.\nThere should be at least one Pod Security Standard policy defined which does not permit containers to share the host network namespace.\n\n**Note: Workloads part of kube-system namespace will not be evaluated**",
                    [Contract.TypeNames.KubernetesWorkloadResourceContainerPrivilegedRiskPolicyConfiguration]: "Running a container in a privileged mode enables the container to access the host’s resources and kernel capabilities.\nSince an attacker may gain access to privileged containers, it is not recommended to run privileged containers.",
                    [Contract.TypeNames.KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskPolicyConfiguration]: "Kubernetes supports mounting secrets as data volumes or as environment variables.\nMinimize the use of environment variable secrets.\nIt is reasonably common for application code to log out its environment (particularly in the event of an error).\nThis will include any secret values passed in as environment variables, so secrets can easily be exposed to any user or entity who has access to the logs.",
                    [Contract.TypeNames.OciBlockStorageBlockVolumeDefaultEncryptionRiskPolicyConfiguration]: "Block volumes should have encryption enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.OciBlockStorageBootVolumeDefaultEncryptionRiskPolicyConfiguration]: "Boot volume should have encryption enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.OciComputeInstanceMetadataServiceVersionRiskPolicyConfiguration]: "Compute instances obtain configuration data via the local Instance Metadata Service (IMDS). Although both IMDSv1 and IMDSv2 are enabled by default, enforcing IMDSv2 is recommended to protect against attacks on misconfigured web application firewalls, open reverse proxies, unpatched SSRF vulnerabilities, and open layer-3 firewalls.\n\n**This policy evaluates only instances running supported platform images and skips custom images. IMDSv2 is supported only on {{ociComputeInstanceMetadataServiceVersion2SupportedImagesLink}}, and others will not be evaluated.**",
                    [Contract.TypeNames.OciComputeInstanceSecureBootNotEnabledRiskPolicyConfiguration]: "It is recommended to enable secure boot on the compute instance. Secure Boot ensures that only software signed by trusted authorities can run during startup, protecting against boot-level malware and unauthorized system changes.\n\n**This policy evaluates only instances running supported shapes and platform images. It skips instances running custom images or those created by OKE. Refer to {{ociComputeInstanceShieldedInstanceSupportedShapesAndImagesLink}} for a list of supported shapes and images.**",
                    [Contract.TypeNames.OciComputeInstanceVolumeEncryptionInTransitDisabledRiskPolicyConfiguration]: "Compute instances should have transport encryption enabled to secure data transfer between the instance and its volumes.\n\n**This policy evaluates only instances running supported shapes and platform images. It skips instances running custom images. Refer to {{ociComputeInstanceVolumeEncryptionInTransitSupportedShapesAndImagesLink}} for a list of supported shapes.**",
                    [Contract.TypeNames.OciFssFileSystemDefaultEncryptionRiskPolicyConfiguration]: "File System should have encryption enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskPolicyConfiguration]: "It is recommended to enable root squash and properly configure UID/GID settings to prevent unrestricted access to the file system. This ensures that the root user on client machines is mapped to an unprivileged user, reducing the risk of unauthorized access, data leakage, and unintended modifications to critical files.",
                    [Contract.TypeNames.OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration]: "Password expiration policy should require passwords to expire after a given number of days. Tenable recommends a timeframe of at least one year.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration]: "Password length policy should enforce password complexity requirements to significantly reduce the risk of password-guessing and brute-force attacks. The recommended password length is 14 characters.\n\n**Note: You can {{changeDefaultPasswordPolicyMinLengthLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.OciIamDomainPasswordReusePolicyRiskPolicyConfiguration]: "Password reuse policy should prevent reusing passwords to significantly reduce the risk of password-guessing and brute-force attacks. Tenable recommends a minimum password requirement of 24 previous passwords.\n\n**Note: You can {{changeDefaultPasswordPolicyPreventReusePreviousPasswordCountLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.OciIamUserApiKeyNotRotatedRiskPolicyConfiguration]: "User API keys should only be used for services in cases where other authentication methods are unavailable. If keys are in use, they should be rotated regularly. Tenable recommends a timeframe of every 90 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.OciIamUserAuthTokenNotRotatedRiskPolicyConfiguration]: "User Auth Tokens should only be used for services in cases where other authentication methods are unavailable. If keys are in use, they should be rotated regularly. Tenable recommends a timeframe of every 90 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.OciIamUserDatabasePasswordNotRotatedRiskPolicyConfiguration]: "User Database passwords should only be used for services in cases where other authentication methods are unavailable. If user database passwords are in use, they should be rotated regularly. Tenable recommends a timeframe of every 90 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.OciIamUserOrganizationAdministratorApiKeyExistsRiskPolicyConfiguration]: "It is recommended to use the least privilege principle when assigning permissions, instead of assigning a user with the Administrators group, which is a high privilege.\nTo mitigate the security risk posed by API keys, it is strongly advised to implement standard authentication flows instead. API keys should only be utilized as a last resort for service authentication when other, more secure authentication methods are unavailable.",
                    [Contract.TypeNames.OciIamUserSecretKeyNotRotatedRiskPolicyConfiguration]: "User Customer Secret Keys should only be used for services in cases where other authentication methods are unavailable. If keys are in use, they should be rotated regularly. Tenable recommends a timeframe of every 90 days or less.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.OciKmsVaultKeyNotRotatedRiskPolicyConfiguration]: "Vault master encryption keys should be rotated periodically to minimize the risk of a compromise. Tenable recommends a timeframe of at least once a year.\n\n**Note: You can {{changeDefaultRotationPeriodLink}} for this policy to comply with internal standards.**",
                    [Contract.TypeNames.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "Network Security Group rules should restrict RDP access to specific IP addresses.",
                    [Contract.TypeNames.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "Network Security Group rules should restrict SSH access to specific IP addresses.",
                    [Contract.TypeNames.OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "Security List rules should restrict RDP access to specific IP addresses.",
                    [Contract.TypeNames.OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "Security List rules should restrict SSH access to specific IP addresses.",
                    [Contract.TypeNames.OciNetworkingVcnFlowLogsNotEnabledRiskPolicyConfiguration]: "It's recommended to enable flow logs for every business-critical VCN subnet, and ensure proper configuration. Flow logs let you capture information about the IP traffic going to and from network interfaces in subnets.",
                    [Contract.TypeNames.OciObjectStorageBucketDefaultEncryptionRiskPolicyConfiguration]: "Object Storage buckets should have encryption at rest enabled using customer managed keys (CMK).",
                    [Contract.TypeNames.OciObjectStorageBucketLogCategoryWriteNotExistsRiskPolicyConfiguration]: "Object Storage bucket logging should be enabled to audit write-level API calls.",
                    [Contract.TypeNames.OciObjectStorageBucketObjectEventsDisabledRiskPolicyConfiguration]: "Object Storage bucket Emit Object Events should be enabled in order to track object state changes.",
                    [Contract.TypeNames.OciObjectStorageBucketObjectVersioningDisabledRiskPolicyConfiguration]: "Object Storage buckets should have object versioning enabled to automatically create an object version each time an object is uploaded, overwritten, or deleted. Versioning object storage buckets provides for additional integrity of your data.",
                    [Contract.TypeNames.OciObjectStorageBucketPublicAccessExistsRiskPolicyConfiguration]: "Object Storage buckets should only allow access to specific identities through IAM policies. If no anonymous access is needed on any object in the bucket, it is recommended that no bucket be publicly accessible.",
                    [Contract.TypeNames.VirtualMachineImageOperatingSystemEndOfLifeRiskPolicyConfiguration]: "Virtual machine images with critical vulnerabilities expose your cloud environment to the risk of being exploited by an attacker.\nAn attacker can exploit a software vulnerability to steal or manipulate sensitive data, or take control over various assets in your cloud environment.\nTenable strongly recommends that you only use machine images in which these vulnerabilities have been addressed.",
                    [Contract.TypeNames.VirtualMachineImageOperatingSystemUnpatchedRiskPolicyConfiguration]: "Virtual machine images with critical vulnerabilities expose your cloud environment to the risk of being exploited by an attacker.\nAn attacker can exploit a software vulnerability to steal or manipulate sensitive data, or take control over various assets in your cloud environment.\nTenable strongly recommends that you only use machine images in which these vulnerabilities have been addressed.",
                    [Contract.TypeNames.VirtualMachineImageVulnerabilityRiskPolicyConfiguration]: "Virtual machine images with critical vulnerabilities expose your cloud environment to the risk of being exploited by an attacker.\nAn attacker can exploit a software vulnerability to steal or manipulate sensitive data, or take control over various assets in your cloud environment.\nTenable strongly recommends that you only use machine images in which these vulnerabilities have been addressed.",
                    [Contract.TypeNames.VirtualMachineMaliciousFileRiskPolicyConfiguration]: "Malware, short for Malicious Software, is important due to its potential to compromise the security and functionality of computer systems, networks and devices. It encompasses a range of harmful software, including viruses, worms, Trojans, ransomware, spyware and more. Malware can lead to data breaches, financial losses, identity theft, and disrupt normal operations. Its significance lies in the fact that it poses a serious threat to both individual uses and organizations, compromising sensitive information, privacy, and the integrity of cloud infrastructure. We recommend urgently remediating this issue.",
                    [Contract.TypeNames.VirtualMachineOperatingSystemEndOfLifeRiskPolicyConfiguration]: "Virtual machines with vulnerabilities expose your environment to the risk of being exploited by an attacker.\nAn attacker can exploit a vulnerability to steal or manipulate sensitive data, or take control over various assets in your environment.\nAn operating system that is EOL receives no security updates, bug fixes, or patches, which means that the workload security is compromised.\nTenable strongly recommends that you update the operating system to a newer version.",
                    [Contract.TypeNames.VirtualMachineOperatingSystemUnpatchedRiskPolicyConfiguration]: "Virtual machines with vulnerabilities expose your environment to the risk of being exploited by an attacker.\nAn attacker can exploit a vulnerability to steal or manipulate sensitive data, or take control over various assets in your environment.\nThe operating system on this workload contains a large number of vulnerable software packages, which means the workload security is compromised.\nTenable strongly recommends that you update the operating system to a newer version in which most of these vulnerabilities have been addressed.",
                    [Contract.TypeNames.VirtualMachineVulnerabilityRiskPolicyConfiguration]: "Virtual machines with vulnerabilities expose your cloud environment to the risk of being exploited by an attacker.\nAn attacker can exploit a vulnerability to steal or manipulate sensitive data, or take control over various assets in your cloud environment.\nTenable strongly recommends that you update the software packages installed on this instance by upgrading to versions in which these vulnerabilities have been addressed."
                }
            }));

    return useCallback(
        (riskPolicyConfigurationTypeNameOrPolicyId: string, variant: "normal" | "text" = "normal") => {
            const customRiskPolicyModel = customRiskPolicyModelMap[riskPolicyConfigurationTypeNameOrPolicyId];
            if (_.isNil(customRiskPolicyModel) && !(riskPolicyConfigurationTypeNameOrPolicyId in localization.riskPolicyConfigurationTypeName)) {
                throw new UnexpectedError("riskPolicyIdentifier", riskPolicyConfigurationTypeNameOrPolicyId);
            }

            const linkNameToUrlMap = {
                awsCloudFrontDistributionAlternativeDomainNamesAndHttpsLink: "https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/using-https-alternate-domain-names.html",
                awsEc2InstanceMetadataServiceVersionLink: "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instancedata-data-retrieval.html#imds-considerations",
                awsEc2VolumeEncryptionLink: "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/EBSEncryption.html#encryption-by-default",
                awsEc2VpcEndpointUnrestrictedServiceAccessDataPerimeterLink: "https://docs.aws.amazon.com/whitepapers/latest/building-a-data-perimeter-on-aws/building-a-data-perimeter-on-aws.html",
                awsEksClusterEndpointAccessControlLink: "https://docs.aws.amazon.com/eks/latest/userguide/cluster-endpoint.html",
                awsElbLoadBalancerRedirectActionsLink: "https://docs.aws.amazon.com/elasticloadbalancing/latest/application/load-balancer-listeners.html",
                awsLambdaFunctionConfigurationRuntimeLink: "https://docs.aws.amazon.com/lambda/latest/dg/lambda-runtimes.html#runtime-support-policy",
                awsRotateAccessKeysLink: "https://aws.amazon.com/blogs/security/how-to-rotate-access-keys-for-iam-users/",
                awsRoute53HostedZoneEnableSigningLink: "https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/dns-configuring-dnssec-enable-signing.html#dns-configuring-dnssec-enable",
                awsS3BucketHttpsOnlyDisabledLink: "https://docs.aws.amazon.com/AmazonS3/latest/userguide/security-best-practices.html",
                awsS3BucketObjectLifecycleConfigurationLink: "https://docs.aws.amazon.com/AmazonS3/latest/userguide/lifecycle-and-other-bucket-config.html",
                awsS3BucketObjectMfaDeleteLink: "https://docs.aws.amazon.com/AmazonS3/latest/userguide/MultiFactorAuthenticationDelete.html",
                awsServiceManagementTasksLink: "https://docs.aws.amazon.com/general/latest/gr/aws_tasks-that-require-root.html",
                azureAppServiceKeyVaultReferencesLink: "https://learn.microsoft.com/en-us/azure/app-service/app-service-key-vault-references?tabs=azure-cli",
                azureContainerServiceApiServerAuthorizedIpRangesLink: "https://learn.microsoft.com/en-us/azure/aks/api-server-authorized-ip-ranges",
                azureDocumentDbFirewallLink: "https://docs.microsoft.com/azure/cosmos-db/how-to-configure-firewall?WT.mc_id=Portal-Microsoft_Azure_DocumentDB",
                azureDocumentDbRbacLink: "https://docs.microsoft.com/azure/cosmos-db/role-based-access-control#prevent-sdk-changes",
                azureKeyVaultConfigureFirewallsAndVirtualNetworksLink: "https://docs.microsoft.com/en-us/azure/key-vault/general/network-security",
                azureKeyVaultRbacMigrationLink: "https://docs.microsoft.com/azure/key-vault/general/rbac-migration",
                azureKeyVaultRenewCertificateLink: "https://docs.microsoft.com/en-us/azure/key-vault/certificates/overview-renew-certificate",
                azureLogicWorkflowSecureParametersDefinitionLink: "https://learn.microsoft.com/en-us/azure/logic-apps/logic-apps-securing-a-logic-app?tabs=azure-portal",
                azureMySqlFirewallRulesLink: "https://docs.microsoft.com/en-us/azure/mysql/concepts-firewall-rules",
                azureSqlFirewallRulesLink: "https://docs.microsoft.com/azure/azure-sql/database/firewall-configure",
                azureStorageAccountFirewallRulesLink: "https://docs.microsoft.com/en-us/azure/storage/common/storage-network-security?tabs=azure-portal",
                azureVirtualMachineSecureSecretsLink: "https://learn.microsoft.com/en-us/answers/questions/1419994/how-to-securely-deal-with-secrets-in-azure-vm-appl",
                azureWebApplicationAccessRulesLink: "https://learn.microsoft.com/en-us/azure/app-service/app-service-ip-restrictions?tabs=azurecli",
                azureWebApplicationAuthenticationLink: "https://learn.microsoft.com/en-us/azure/app-service/overview-authentication-authorization",
                changeDefaultInactivityPeriodLink: "https://docs.ermetic.com/docs/view-and-manage-policies",
                changeDefaultIncludeUnusedRootUserLink: "https://docs.ermetic.com/docs/view-and-manage-policies",
                changeDefaultPasswordPolicyMinLengthLink: "https://docs.ermetic.com/docs/view-and-manage-policies",
                changeDefaultPasswordPolicyPreventReusePreviousPasswordCountLink: "https://docs.ermetic.com/docs/view-and-manage-policies",
                changeDefaultRetentionPeriodLink: "https://docs.ermetic.com/docs/view-and-manage-policies",
                changeDefaultRotationPeriodLink: "https://docs.ermetic.com/docs/view-and-manage-policies",
                gcpArtifactRegistryProtectArtifactsLink: "https://cloud.google.com/artifact-registry/docs/protect-artifacts",
                gcpCloudRunServiceSecretsLink: "https://cloud.google.com/run/docs/configuring/secrets",
                gcpComputeInstanceIpForwardingEnabledLink: "https://cloud.google.com/vpc/docs/using-routes#canipforward",
                gcpComputeSshIamEnabledLink: "https://cloud.google.com/compute/docs/oslogin",
                gcpComputeSshSerialPortEnabledLink: "https://cloud.google.com/compute/docs/troubleshooting/troubleshooting-using-serial-console?_ga=2.157237051.-1252226614.1639037469",
                gcpComputeSslPolicyLink: "https://cloud.google.com/load-balancing/docs/ssl-policies-concepts",
                gcpContainerClusterControlPlaneAccessAuthorizationLink: "https://cloud.google.com/kubernetes-engine/docs/best-practices/networking#authorize-cp-access",
                gcpContainerLeastPrivilegeServiceAccountsLink: "https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#use_least_privilege_sa",
                gcpLoggingPricingLink: "https://cloud.google.com/stackdriver/pricing#logging-costs",
                gcpSecretManagerSecretCreateAndAccessLink: "https://cloud.google.com/secret-manager/docs/creating-and-accessing-secrets",
                gcpSecretManagerSecretRotationLink: "https://cloud.google.com/secret-manager/docs/rotation-recommendations",
                gcpSqlInstanceMysqlLoadClientFileLink: "https://dev.mysql.com/doc/refman/8.0/en/load-data-local-security.html",
                gcpStorageBucketUniformAccessControlLink: "https://cloud.google.com/storage/docs/uniform-bucket-level-access",
                gcpUnusedTenantRecommendationLink: "https://cloud.google.com/recommender/docs/unattended-project-recommender#recommendations_to_delete_a_project",
                iamSettingsLink: "https://docs.ermetic.com/v1/docs/en/excessive-permissions",
                kubernetesPodSecurityStandardsHostNamespacesLink: "https://kubernetes.io/docs/concepts/security/pod-security-standards/#:~:text=false-,Host%20Namespaces,-Sharing%20the%20host",
                kubernetesPodSecurityStandardsPrivilegedLink: "https://kubernetes.io/docs/concepts/security/pod-security-standards/#:~:text=Privileged%20Containers",
                kubernetesReleasesLink: "https://kubernetes.io/releases",
                ociComputeInstanceMetadataServiceVersion2SupportedImagesLink: "https://docs.oracle.com/en-us/iaas/Content/Compute/Tasks/gettingmetadata.htm#upgrading-v2__supported-images",
                ociComputeInstanceShieldedInstanceSupportedShapesAndImagesLink: "https://docs.oracle.com/en-us/iaas/Content/Compute/References/shielded-instances.htm#supported-shapes",
                ociComputeInstanceVolumeEncryptionInTransitSupportedShapesAndImagesLink: "https://docs.oracle.com/en-us/iaas/Content/Compute/Tasks/enable-intransit-encryption.htm#supported-shapes-and-images",
                openshiftReleasesLink: "https://access.redhat.com/support/policy/updates/openshift"
            };

            return _.isNil(customRiskPolicyModel)
                ? {
                    description:
                        localization.riskPolicyConfigurationTypeName.translate(
                            riskPolicyConfigurationTypeNameOrPolicyId,
                            {
                                settings: {
                                    decorators: variant === "normal"
                                },
                                ..._.mapValues(
                                    linkNameToUrlMap,
                                    (linkUrl, linkName) =>
                                        variant == "text"
                                            ? linkUrl
                                            : <Link
                                                key={linkName}
                                                urlOrGetUrl={linkUrl}
                                                variant="external">
                                                {localization.links.translate(linkName)}
                                            </Link>)
                            })!,
                    title: riskPolicyTitleTranslator(riskPolicyConfigurationTypeNameOrPolicyId)
                }
                : {
                    description: (customRiskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).description,
                    title: (customRiskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).name
                };
        },
        [customRiskPolicyModelMap]);
}