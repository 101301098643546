﻿import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { RiskContentProps } from "../../../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useOpenRiskedEntityRisksStep } from "../../../../../../../useOpenRiskedEntityRisksStep";
import { EntityExternalConsoleLink } from "../../../../../../components";
import { RoleBindingTable } from "../../../components";
import { getSeverityValue, useGetServiceAccountSeverity } from "../../useGetServiceAccountSeverity";

export function useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciPartialTenantEntityDefinition(riskModel: Contract.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskModel) {
    const risk = riskModel.risk as Contract.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk;
    const gciPartialTenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GciPartialTenantEntityModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciPartialTenantEntityDefinition",
            () => ({
                description: "Domain {{gciPartialTenantEntityId}} has {{roleIds}} assigned at the project level or above",
                sections: {
                    resolution: {
                        step1: "Before deleting the role bindings, assign the domain role bindings to specific service accounts",
                        step2: "{{entityExternalConsoleLink}} and filter on **Principal:{{principalRoleBindingIdentifier}}**",
                        step3: "Under **Permissions** delete the role bindings to {{roleIds}}"
                    },
                    roleBindingIds: "GCP Role Bindings"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            gciPartialTenantEntityId:
                <Entity
                    entityIdOrModel={gciPartialTenantEntityModel}
                    variant="text"/>,
            roleIds:
                <InlineEntities
                    entityIdsOrModels={riskModel.roleIds}
                    entityTypeName={Contract.TypeNames.GcpIamRole}
                    variant="itemAndTypeOrItemCountAndType"/>
        }),
        () =>
            _.concat(
                localization.sections.resolution.step1(),
                _(risk.orderedResourceManagerResourceIds).
                    flatMap(
                        resourceManagerResourceId => [
                            localization.sections.resolution.step2({
                                entityExternalConsoleLink:
                                    <EntityExternalConsoleLink
                                        entityId={resourceManagerResourceId}
                                        page={Contract.GcpConsolePage.Permissions}/>,
                                principalRoleBindingIdentifier: risk.principalRoleBindingIdentifier
                            }),
                            localization.sections.resolution.step3({
                                roleIds:
                                    <InlineEntities
                                        entityIdsOrModels={risk.resourceManagerResourceIdToRoleIdsMap[resourceManagerResourceId]}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })
                        ]).
                    value()),
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <RoleBindingTable
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const risk = riskModel.risk as Contract.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk;
    const gciPartialTenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GciPartialTenantEntityModel;
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(gciPartialTenantEntityModel, risk.id);
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const [severity, severityServiceAccountIds] = useGetServiceAccountSeverity(risk);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciPartialTenantEntityDefinition.contextSection",
            () => ({
                gciPartialTenantEntity: "Domain {{gciPartialTenantEntityId}} is an external domain",
                serviceAccountIds: {
                    withHighSeverityServiceAccountIds: "The domain grants permissions to impersonate {{serviceAccountIds}} in project {{tenantId}}, {{highSeverityServiceAccountIds}} with {{severity}} severity permissions",
                    withoutHighSeverityServiceAccountIds: "The domain grants permissions to impersonate {{serviceAccountIds}} in project {{tenantId}}, none with high severity permissions"
                }
            }));

    return (
        <Steps>
            {_.filter([
                localization.gciPartialTenantEntity({
                    gciPartialTenantEntityId:
                        <Entity
                            entityIdOrModel={gciPartialTenantEntityModel}
                            variant="text"/>
                }),
                (getSeverityValue(severity) >= getSeverityValue(Contract.Severity.High)
                    ? localization.serviceAccountIds.withHighSeverityServiceAccountIds
                    : localization.serviceAccountIds.withoutHighSeverityServiceAccountIds)({
                    highSeverityServiceAccountIds:
                        <InlineEntities
                            entityIdsOrModels={severityServiceAccountIds}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            variant="itemCountAndType"/>,
                    serviceAccountIds:
                        <InlineEntities
                            entityIdsOrModels={_.keys(risk.serviceAccountIdToSeverityMap)}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            variant="itemCountAndType"/>,
                    severity:
                        _.isNil(severity)
                            ? undefined
                            : severityTranslator(severity!, "text"),
                    tenantId:
                        <Entity
                            entityIdOrModel={risk.tenantId}
                            variant="text"/>
                }),
                openRiskedEntityRisksStep,
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!)
            ])}
        </Steps>);
}