import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzurePostgreSqlServerAuthenticationTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzurePostgreSqlServerAuthenticationTypeTranslator",
            () => ({
                [Contract.TypeNames.AzurePostgreSqlServerAuthenticationType]: {
                    [Contract.AzurePostgreSqlServerAuthenticationType.Aad]: "Microsoft Entra ID",
                    [Contract.AzurePostgreSqlServerAuthenticationType.AadAndPostgreSql]: "PostgreSQL and Microsoft Entra ID",
                    [Contract.AzurePostgreSqlServerAuthenticationType.PostgreSql]: "PostgreSQL"
                }
            }));

    return (status: Contract.AzurePostgreSqlServerAuthenticationType) =>
        localization[Contract.TypeNames.AzurePostgreSqlServerAuthenticationType][status]();
}