import { Optional } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../common";
import { useGcpApiKeysKeyDefinition, useGcpAppEngineApplicationServiceDefinition, useGcpBigQueryDatasetDefinition, useGcpCloudBuildBuildTriggerDefinition, useGcpCloudRunServiceDefinition, useGcpComputeBackendServiceDefinition, useGcpComputeInstanceDefinition, useGcpComputeInstanceTemplateDefinition, useGcpComputeLoadBalancerDefinition, useGcpComputeSslPolicyDefinition, useGcpComputeVpcDefinition, useGcpContainerClusterDefinition, useGcpDnsManagedZoneDefinition, useGcpEntityDefinition, useGcpFunctionsFunctionDefinition, useGcpIamServiceAccountDefinition, useGcpKmsKeyRingKeyDefinition, useGcpNotebooksInstanceDefinition, useGcpPubSubSubscriptionDefinition, useGcpPubSubTopicDefinition, useGcpResourceManagerFolderDefinition, useGcpResourceManagerOrganizationDefinition, useGcpSecretManagerSecretDefinition, useGcpSqlInstanceDefinition, useGcpStorageBucketDefinition, useGcpTenantEntityDefinition } from "./hooks";

export function useDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.GcpApiKeysKey:
                        return useGcpApiKeysKeyDefinition;
                    case Contract.TypeNames.GcpAppEngineApplicationService:
                        return useGcpAppEngineApplicationServiceDefinition;
                    case Contract.TypeNames.GcpBigQueryDataset:
                        return useGcpBigQueryDatasetDefinition;
                    case Contract.TypeNames.GcpCloudBuildBuildTrigger:
                        return useGcpCloudBuildBuildTriggerDefinition;
                    case Contract.TypeNames.GcpCloudRunService:
                        return useGcpCloudRunServiceDefinition;
                    case Contract.TypeNames.GcpComputeBackendService:
                        return useGcpComputeBackendServiceDefinition;
                    case Contract.TypeNames.GcpComputeInstance:
                        return useGcpComputeInstanceDefinition;
                    case Contract.TypeNames.GcpComputeInstanceTemplate:
                        return useGcpComputeInstanceTemplateDefinition;
                    case Contract.TypeNames.GcpComputeLoadBalancer:
                        return useGcpComputeLoadBalancerDefinition;
                    case Contract.TypeNames.GcpComputeSslPolicy:
                        return useGcpComputeSslPolicyDefinition;
                    case Contract.TypeNames.GcpComputeVpc:
                        return useGcpComputeVpcDefinition;
                    case Contract.TypeNames.GcpContainerCluster:
                        return useGcpContainerClusterDefinition;
                    case Contract.TypeNames.GcpDnsManagedZone:
                        return useGcpDnsManagedZoneDefinition;
                    case Contract.TypeNames.GcpFunctionsFunction:
                        return useGcpFunctionsFunctionDefinition;
                    case Contract.TypeNames.GcpIamServiceAccount:
                        return useGcpIamServiceAccountDefinition;
                    case Contract.TypeNames.GcpKmsKeyRingKey:
                        return useGcpKmsKeyRingKeyDefinition;
                    case Contract.TypeNames.GcpNotebooksInstance:
                        return useGcpNotebooksInstanceDefinition;
                    case Contract.TypeNames.GcpPubSubSubscription:
                        return useGcpPubSubSubscriptionDefinition;
                    case Contract.TypeNames.GcpPubSubTopic:
                        return useGcpPubSubTopicDefinition;
                    case Contract.TypeNames.GcpResourceManagerFolder:
                        return useGcpResourceManagerFolderDefinition;
                    case Contract.TypeNames.GcpResourceManagerOrganization:
                        return useGcpResourceManagerOrganizationDefinition;
                    case Contract.TypeNames.GcpSecretManagerSecret:
                        return useGcpSecretManagerSecretDefinition;
                    case Contract.TypeNames.GcpSqlInstance:
                        return useGcpSqlInstanceDefinition;
                    case Contract.TypeNames.GcpStorageBucket:
                        return useGcpStorageBucketDefinition;
                    case Contract.TypeNames.GcpTenantEntity:
                        return useGcpTenantEntityDefinition;
                    default:
                        return useGcpEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel, page);
}

export class EntityExternalConsoleLinkDefinition {
    constructor(public url: Optional<string>) {
    }
}