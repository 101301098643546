import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, scopeSystemEntityModelStore, TenantIcon } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { Aws, Azure, Gcp, Oci } from "./components";

export function useCloudProviderTenantOrganizationItems(): IntegrationItem[] {
    const awsOrganizationModels = scopeSystemEntityModelStore.useGetAwsOrganization();
    const azureOrganizationModels = scopeSystemEntityModelStore.useGetAzureOrganization();
    const gcpOrganizationModels = scopeSystemEntityModelStore.useGetGcpOrganization();
    const ociOrganizationModels = scopeSystemEntityModelStore.useGetOciOrganization();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems",
            () => ({
                description: "{{count | NumberFormatter.humanize}} configured",
                [Contract.TypeNames.TenantType]: {
                    [Contract.TenantType.Aws]: "AWS Organization",
                    [Contract.TenantType.Azure]: "Azure Organization",
                    [Contract.TenantType.Gcp]: "GCP Organization",
                    [Contract.TenantType.Oci]: "OCI Tenancy"
                }
            }));

    return [
        new IntegrationItem(
            IntegrationItemCategory.CloudProviderTenantOrganizations,
            awsOrganizationModels,
            [localization.description({ count: awsOrganizationModels.length })],
            <TenantIcon tenantType={Contract.TenantType.Aws}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.Aws](),
            Contract.TenantType.Aws,
            <Aws/>),
        new IntegrationItem(
            IntegrationItemCategory.CloudProviderTenantOrganizations,
            azureOrganizationModels,
            [localization.description({ count: azureOrganizationModels.length })],
            <TenantIcon tenantType={Contract.TenantType.Azure}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.Azure](),
            Contract.TenantType.Azure,
            <Azure/>),
        new IntegrationItem(
            IntegrationItemCategory.CloudProviderTenantOrganizations,
            gcpOrganizationModels,
            [localization.description({ count: gcpOrganizationModels.length })],
            <TenantIcon tenantType={Contract.TenantType.Gcp}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.Gcp](),
            Contract.TenantType.Gcp,
            <Gcp/>),
        new IntegrationItem(
            IntegrationItemCategory.CloudProviderTenantOrganizations,
            ociOrganizationModels,
            [localization.description({ count: ociOrganizationModels.length })],
            <TenantIcon tenantType={Contract.TenantType.Oci}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.Oci](),
            Contract.TenantType.Oci,
            <Oci/>)
    ];
}