import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetOciNetworkingSubnetsRiskContext() {
    return useMemo(
        () => useOciNetworkingSubnetsRiskContext,
        []);
}

function useOciNetworkingSubnetsRiskContext(subnetIdsOrModels: string[] | Contract.OciNetworkingSubnetModel[]) {
    return useGetEntitiesRiskContext()(
        subnetIdsOrModels,
        Contract.TypeNames.OciNetworkingSubnet);
}