import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { EntitySelector, EntitySelectorSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsInboundExternalSubnetRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const { scopeId } = useCustomRiskPolicyContext();
    const [subnetsValid, setSubnetsValid] = useState(false);

    const [subnetSelectorSelection, setSubnetSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.allSubnets,
                undefined,
                undefined,
                riskPolicyConfiguration?.subnetBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.subnetCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.subnetIds,
                undefined,
                undefined,
                riskPolicyConfiguration?.subnetTags,
                undefined));
    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityTags } = subnetSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAwsInboundExternalSubnetRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities ?? false,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityTags);
                }
        });

    useEffect(
        () => {
            const subnetsValid = subnetSelectorSelection?.valid() ?? false;
            setSubnetsValid(subnetsValid);
            onValidChange(subnetsValid);
        },
        [subnetSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsInboundExternalSubnetRiskPolicy.edit",
            () => ({
                prompt: "Select the required subnets"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        subnets:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                                selection={subnetSelectorSelection}/>
                    })}
                </Typography>
                <Box>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AwsEc2Subnet,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {subnetsValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.SensitiveResourceAttribute,
                                    Contract.TypeNames.CodeResourceAttribute
                                ]}
                                entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                                properties={["all", "tags", "attributes"]}
                                scopeId={scopeId}
                                selection={subnetSelectorSelection}
                                onSelectionChanged={setSubnetSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}