import { DataTable, DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import path from "path";
import React from "react";
import { Contract, EntitiesCell, InfoCard } from "../../../../../../../../../../../../common";

type RulesInfoCardProps = {
    backendResourceUrlToSearchableIdReferenceMap: Dictionary<Contract.EntitySearchableIdReference>;
    rules: Contract.GcpComputeUrlMapRule[];
};

export function RulesInfoCard({ backendResourceUrlToSearchableIdReferenceMap, rules }: RulesInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeUrlMapDefinition.rulesInfoCard",
            () => ({
                columns: {
                    backend: "Backend",
                    host: "Host",
                    path: "Path"
                },
                title: "Rules"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                fetchItems={
                    () =>
                        _.orderBy(
                            rules,
                            [
                                rule => rule.host ?? "",
                                rule => rule.path ?? ""
                            ])}
                getItemId={(item: Contract.GcpComputeUrlMapRule) => (item.host ?? "*") + item.path}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={RuleTableColumnId.Host}
                    itemProperty={(item: Contract.GcpComputeUrlMapRule) => item.host ?? "*"}
                    title={localization.columns.host()}/>
                <DataTableColumn
                    id={RuleTableColumnId.Path}
                    itemProperty={(item: Contract.GcpComputeUrlMapRule) => item.path ?? "*"}
                    title={localization.columns.path()}/>
                <DataTableColumn
                    id={RuleTableColumnId.Backend}
                    key={RuleTableColumnId.Backend}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeUrlMapRule>) =>
                            !_.isNil(item.backend.resourceUrls)
                                ? <EntitiesCell
                                    entityIdsOrModels={
                                        _.map(
                                            item.backend.resourceUrls,
                                            resourceUrl => backendResourceUrlToSearchableIdReferenceMap[resourceUrl].idReference)}
                                    entityTypeName={Contract.TypeNames.GcpScopeResource}
                                    entityVariant="iconTextTypeTenant"/>
                                : <Typography noWrap={true}>
                                    {path.join(
                                        item.backend.redirectHost ?? item.host ?? "*",
                                        item.backend.redirectPath ?? item.path ?? "*")}
                                </Typography>}
                    title={localization.columns.backend()}/>;
            </DataTable>
        </InfoCard>);
}

enum RuleTableColumnId {
    Backend = "backend",
    Host = "host",
    Path = "path"
}