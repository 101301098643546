import { DataTableColumnRenderProps, NotValidIcon, SuccessIcon } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { InstanceItem } from "..";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../../../common";
import { useJiraInstanceStateProjectIssueTranslator } from "../hooks";

export function StatusCell({ item }: DataTableColumnRenderProps<InstanceItem>) {
    const jiraInstanceStateProjectIssueTranslator = useJiraInstanceStateProjectIssueTranslator();
    const theme = useTheme();
    const projectIssue = (item.instanceModel.state as Contract.JiraInstanceState)?.projectRawIdToProjectMap[item.projectConfiguration.rawId]?.issue;
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Box sx={{ fontSize: "18px" }}>
                {_.isNil(projectIssue)
                    ? <SuccessIcon sx={{ color: theme.palette.success.main }}/>
                    : <NotValidIcon sx={{ color: theme.palette.warning.main }}/>}
            </Box>
            {projectIssue !== Contract.JiraInstanceStateProjectIssue.InstanceError && (
                <Typography>
                    {jiraInstanceStateProjectIssueTranslator(projectIssue)}
                </Typography>)}
        </Stack>);
}