import { DialogMenuItem, TimeFormatter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { Contract, EntityController, InfoItem, tenantModelStore, usePrincipalModelAccess } from "../../../../../../../../../../../common";
import { AwsTenantHelper, GenerateAwsResourceNonexcessivePolicyDialog } from "../../../../../../../../../../../tenants";
import { EntitiesInfoCard, Info, PrincipalSecurityStatsStat } from "../../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../../icons";
import { AwsPrincipalPoliciesInfoCard } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "../useAwsDefaultResourceInfoItemElements";
import { Credentials } from "./components";

export function useAwsIamUserDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();
    const user = (resourceModel.entity as Contract.AwsIamUser);
    const userModel = (resourceModel as Contract.AwsIamUserModel);
    const userModelAccess = usePrincipalModelAccess<Contract.UserModelAccess>(userModel.id);
    const [entityExcessivePermissionCustomEvaluationData] =
        useExecuteOperation(
            [useAwsIamUserDefinition, userModel.id],
            async () =>
                await EntityController.getEntityExcessivePermissionCustomEvaluationData(
                    new Contract.EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest(userModel.id)));

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsIamUserDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Groups"
                    },
                    items: {
                        activityTime: {
                            empty: "Never",
                            title: "Activity Time"
                        }
                    }
                },
                tabs: {
                    credentials: "Credentials"
                },
                topbar: {
                    menu: {
                        generate: "Generate Least Privilege Policy"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <Credentials userModel={userModel}/>,
                localization.tabs.credentials(),
                "credentials")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="activityTime"
                        location="all"
                        title={localization.info.items.activityTime.title()}
                        value={
                            _.isNil(user.activityTime)
                                ? localization.info.items.activityTime.empty()
                                : TimeFormatter.profileFormatDateTime(user.activityTime)}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={userModel.groupIds}
                    entityTypeName={Contract.TypeNames.AwsIamGroup}
                    title={localization.info.cards.groupIds()}/>
                <AwsPrincipalPoliciesInfoCard
                    groupPolicyIds={userModel.groupPolicyIds}
                    inlinePolicyIds={user.inlinePolicyIds}
                    managedPolicyIds={user.managedPolicyIds}
                    permissionBoundaryPolicyId={user.permissionBoundaryPolicyId}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                AwsTenantHelper.isAccessAdvisorEnabled(resourceModel.entity, tenantModelMap) &&
                !_.isNil(userModelAccess?.permissions)
                    ? <PrincipalSecurityStatsStat
                        analyzingReason={userModel.analyzingReason}
                        securityStats={userModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.AwsIamUser}/>
                    : undefined
            ]),
        topbarMenuItems:
            entityExcessivePermissionCustomEvaluationData.nonexcessivePermitterCustomGenerationEnabled
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateAwsResourceNonexcessivePolicyDialog
                                entityExcessivePermissionCustomEvaluationData={entityExcessivePermissionCustomEvaluationData}
                                entityModel={userModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}