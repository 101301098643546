import { DataTableColumn, DataTableColumnRenderProps, IconText, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { CodeTenant, Contract, EntitiesIcon, TenantIcon, useTheme } from "../../../../../../../common";
import { CodeTypeIcon } from "../../../../../../../tenants";
import { RisksItemsFilterId } from "../../Filters";
import { TableColumnId } from "../components";

export function useTableCommonCodeColumns(ungrouped: boolean) {
    const getFilterOptions =
        useCallback(
            (filterId: Contract.RiskFilterId) =>
                ungrouped
                    ? { externalId: RisksItemsFilterId[filterId] }
                    : undefined,
            [ungrouped]);

    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useTableCommonCodeColumns",
            () => ({
                file: "File",
                repository: "Repository",
                riskedEntity: "Resource"
            }));

    const theme = useTheme();
    return useMemo(
        () => ({
            file:
                <DataTableColumn
                    filterOptions={getFilterOptions(Contract.RiskFilterId.CodeRiskFileRelativePath)}
                    id={CodeTableColumnId.File}
                    key={CodeTableColumnId.File}
                    render={({ item }: DataTableColumnRenderProps<Contract.CodeRiskModel>) =>
                        <Stack
                            direction="row"
                            spacing={1}>
                            <CodeTypeIcon
                                codeType={item.risk.codeType}
                                sx={{ fontSize: "18px" }}/>
                            <Typography>
                                {item.risk.fileRelativePath}
                            </Typography>
                        </Stack>
                    }
                    sortOptions={{ enabled: false }}
                    title={localization.file()}/>,
            repository:
                <DataTableColumn
                    filterOptions={getFilterOptions(Contract.RiskFilterId.TenantId)}
                    id={CodeTableColumnId.Repository}
                    key={CodeTableColumnId.Repository}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.CodeRiskModel>) =>
                            <CodeTenant tenantId={item.tenantId}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.repository()}/>,
            resource:
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={getFilterOptions(Contract.RiskFilterId.CodeRiskResourceName)}
                    id={TableColumnId.RiskedEntityIds}
                    key={TableColumnId.RiskedEntityIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.CodeRiskModel>) =>
                            <IconText
                                icon={
                                    item.cloudTenantType
                                        ? <TenantIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "18px"
                                            }}
                                            tenantType={item.cloudTenantType}/>
                                        : <EntitiesIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "18px"
                                            }}/>}
                                text={
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}>
                                        <Typography
                                            noWrap={true}
                                            sx={{ width: "fit-content" }}>
                                            {item.risk.resourceName}
                                        </Typography>
                                        <Typography
                                            noWrap={true}
                                            sx={{ lineHeight: 1.5 }}
                                            variant="subtitle1">
                                            {item.risk.resourceTypeSystemName}
                                        </Typography>
                                    </Stack>}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.riskedEntity()}/>
        }),
        [localization]);
}

export enum CodeTableColumnId {
    File = "File",
    Repository = "Repository"
}