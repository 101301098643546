import { Stack, SxProps, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

type SummarySectionCardProps = {
    childrenSx?: SxProps;
    rowGap?: number;
    sx?: SxProps;
    title: string;
    titleSx?: SxProps;
};

export function SummarySectionCard({ children, childrenSx, rowGap = 2, sx, title, titleSx }: PropsWithChildren<SummarySectionCardProps>) {
    return (
        <Stack
            rowGap={rowGap}
            sx={sx}>
            <Typography
                sx={{
                    fontWeight: 500,
                    ...titleSx
                }}
                variant="h4">
                {title}
            </Typography>
            <Stack
                direction="column"
                sx={{
                    flexGrow: 1,
                    ...childrenSx
                }}>
                {children}
            </Stack>
        </Stack>);
}