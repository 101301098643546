import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { GcpTenantEntityUsageSection } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationInactivityTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationInactivityTimeFrameContextItem";
import { useGetGcpTenantEntityRiskContext } from "../contexts";

export function useGcpTenantEntityUnusedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpTenantEntityUnusedRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GcpTenantEntityModel;
    const tenantEntity = tenantEntityModel.entity as Contract.GcpTenantEntity;
    const tenantEntityUnusedRiskModel = riskModel as Contract.GcpTenantEntityUnusedRiskModel;

    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();
    const getRiskPolicyConfigurationInactivityTimeFrameContextItem = useGetRiskPolicyConfigurationInactivityTimeFrameContextItem();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.management.useGcpTenantEntityUnusedRiskDefinition",
            () => ({
                description: "{{tenantEntity}} has low usage for more than {{unusedStartTime | TimeFormatter.humanizeDuration}}",
                sections: {
                    resolution: {
                        step1: {
                            link: "Shutting down projects",
                            text: "Before shutting down the project, carefully examine the project's resources. Deleting it might lead to irreversible data loss. For more information, refer to {{shuttingDownTenantDocumentationUrl}}"
                        }
                    },
                    tenantEntityUsage: "Project Utilization Information"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            unusedStartTime: (tenantEntityModel.entityProfile as Contract.GcpTenantEntityProfile).unusedStartTime
        }),
        () => [
            localization.sections.resolution.step1.text({
                shuttingDownTenantDocumentationUrl:
                    <Link
                        urlOrGetUrl={tenantEntityUnusedRiskModel.manageProjectDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                getRiskPolicyConfigurationInactivityTimeFrameContextItem(risk.projectUsageTimeFrame),
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.owners,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <GcpTenantEntityUsageSection
                        activeOwnerReferences={tenantEntityModel.activeOwnerReferences}
                        inactiveOwnerReferences={tenantEntityModel.inactiveOwnerReferences}
                        usage={tenantEntity.usage!}/>,
                    localization.sections.tenantEntityUsage())
            ]
        });
}