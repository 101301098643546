import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeSnapshotStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeSnapshotStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeSnapshotStatus]: {
                    [Contract.GcpComputeSnapshotStatus.Creating]: "Creating",
                    [Contract.GcpComputeSnapshotStatus.Deleting]: "Deleting",
                    [Contract.GcpComputeSnapshotStatus.Failed]: "Failed",
                    [Contract.GcpComputeSnapshotStatus.Ready]: "Ready",
                    [Contract.GcpComputeSnapshotStatus.Uploading]: "Uploading"
                }
            }));
    return (status: Contract.GcpComputeSnapshotStatus) =>
        localization[Contract.TypeNames.GcpComputeSnapshotStatus][status]();
}