import React, { useEffect } from "react";
import { RiskType } from "../../../../../../../common";
import { CustomerConsoleAppSideViewType } from "../../../../../../../common/controllers/types.generated";
import { Profile, ProfileActions } from "../../../../Risks/components";
import { useItem } from "../../../../Risks/hooks";
import { RiskHelper } from "../../../../Risks/utilities";
import { SideViewItem, useSideViewContext } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useRiskDefinition(item: SideViewItem): SideViewDefinition {
    const { itemId, riskType } = RiskHelper.getSideViewItemData(item.id);
    const { codeResourceDatas, excludedEntityIds, excludedRiskItemEntityIds, executeGetRiskModel, riskModel, triggerAuditEventsChange } = useItem(itemId);
    const { triggerDataChange } = useSideViewContext();
    useEffect(
        () => {
            triggerAuditEventsChange();
            triggerDataChange(CustomerConsoleAppSideViewType.Risk);
        },
        [triggerAuditEventsChange, triggerDataChange, executeGetRiskModel]);

    return {
        getActionsElement:
            () =>
                <ProfileActions
                    executeGetRiskModel={executeGetRiskModel}
                    key={`${itemId}-actions`}
                    riskModel={riskModel}
                    riskType={riskType as RiskType}/>,
        getViewElement:
            () =>
                <Profile
                    codeResourceDatas={codeResourceDatas}
                    excludedEntityIds={excludedEntityIds}
                    excludedRiskItemEntityIds={excludedRiskItemEntityIds}
                    executeGetRiskModel={executeGetRiskModel}
                    key={`${itemId}-profile`}
                    riskModel={riskModel}
                    riskType={riskType as RiskType}/>
    };
}