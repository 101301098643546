import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlineVulnerability, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEcrRepositoryRiskContext() {
    return useMemo(
        () => useAwsEcrRepositoryRiskContext,
        []);
}

function useAwsEcrRepositoryRiskContext(repositoryModel: Contract.AwsEcrRepositoryModel) {
    const repository = repositoryModel.entity as Contract.AwsEcrRepository;
    const containerImageModels = entityModelStore.useGet(repository.containerImageIds);
    const resourceRiskContext = useGetAwsResourceRiskContext()(repositoryModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEcrRepositoryRiskContext",
            () => ({
                containerImages: {
                    empty: "The {{translatedRepositoryTypeName}} has no images",
                    notEmpty: "The {{translatedRepositoryTypeName}} has {{images}}{{resources}}",
                    resources: " used by {{resources}}"
                },
                criticalVulnerabilityContainerImages: {
                    notEmpty: {
                        text: " with {{vulnerabilities}}",
                        vulnerabilities: [
                            "1 critical severity vulnerability",
                            "{{count | NumberFormatter.humanize}} critical severity vulnerabilities"
                        ]
                    },
                    text: "The {{translatedRepositoryTypeName}} has {{containerImages}}{{notEmpty}}"
                },
                encryption: {
                    none: "not encrypted",
                    text: "The {{translatedRepositoryTypeName}} is {{encryption}}",
                    [Contract.TypeNames.AwsEcrRepositoryEncryptionType]: {
                        [Contract.AwsEcrRepositoryEncryptionType.Aes256]: "encrypted using AES-256",
                        [Contract.AwsEcrRepositoryEncryptionType.Kms]: "encrypted using KMS key {{key}}",
                        [Contract.AwsEcrRepositoryEncryptionType.KmsDualLayer]: "encrypted using KMS key {{key}}"
                    }
                }
            }));
    const translatedRepositoryTypeName =
        entityTypeNameTranslator(
            repository.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const [containerImageCriticalVulnerabilities, containerImageResourceIds, criticalVulnerabilityContainerImageModels] =
        useMemo(
            () => {
                const containerImageCriticalVulnerabilities =
                    _(containerImageModels).
                        flatMap(containerImageModel => (containerImageModel.entity as Contract.AwsContainerImage).data.vulnerabilities).
                        filter(vulnerabilityData => vulnerabilityData.severity === Contract.Severity.Critical).
                        uniqBy(vulnerabilityData => vulnerabilityData.rawId).
                        orderBy(vulnerabilityData => vulnerabilityData.rawId).
                        value();

                const containerImageResourceIds =
                    _(repositoryModel.containerImageResourceIds).
                        orderBy("asc").
                        value();

                const criticalVulnerabilityContainerImageModels =
                    _.filter(
                        containerImageModels,
                        containerImageModel =>
                            _.some(
                                (containerImageModel.entity as Contract.AwsContainerImage).data.vulnerabilities,
                                vulnerabilityData => vulnerabilityData.severity === Contract.Severity.Critical));

                return [containerImageCriticalVulnerabilities, containerImageResourceIds, criticalVulnerabilityContainerImageModels];
            },
            [repositoryModel]);

    const theme = useTheme();
    return {
        ...resourceRiskContext,
        containerImages:
            new RiskDefinitionContextItem(
                repository.containerImageCount == 0
                    ? localization.containerImages.empty({ translatedRepositoryTypeName })
                    : localization.containerImages.notEmpty({
                        images:
                            <InlineEntities
                                entityIdsOrModels={containerImageModels}
                                entityTypeName={Contract.TypeNames.AwsContainerImage}
                                variant="itemCountAndType"/>,
                        resources:
                            _.isEmpty(containerImageResourceIds)
                                ? ""
                                : localization.containerImages.resources({
                                    resources:
                                        <InlineEntities
                                            entityIdsOrModels={containerImageResourceIds}
                                            entityTypeName={Contract.TypeNames.IContainerImageResource}
                                            variant="itemCountAndType"/>
                                }),
                        translatedRepositoryTypeName
                    })),
        criticalVulnerabilityContainerImages:
            _.isEmpty(criticalVulnerabilityContainerImageModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.criticalVulnerabilityContainerImages.text({
                        containerImages:
                            <InlineEntities
                                entityIdsOrModels={criticalVulnerabilityContainerImageModels}
                                entityTypeName={Contract.TypeNames.AwsContainerImage}
                                variant="itemCountAndType"/>,
                        notEmpty:
                            _.isEmpty(containerImageCriticalVulnerabilities)
                                ? ""
                                : localization.criticalVulnerabilityContainerImages.notEmpty.text({
                                    vulnerabilities:
                                        <InlineItems
                                            items={
                                                _.flatMap(
                                                    containerImageCriticalVulnerabilities,
                                                    containerImageVulnerabilityData =>
                                                        <InlineVulnerability rawId={containerImageVulnerabilityData.rawId}/>)}
                                            namePluralizer={localization.criticalVulnerabilityContainerImages.notEmpty.vulnerabilities}
                                            sx={{ color: theme.palette.text.primary }}
                                            variant="itemCountAndType"/>
                                }),
                        translatedRepositoryTypeName
                    })),
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    encryption:
                        _.isNil(repository.encryptionType)
                            ? localization.encryption.none()
                            : localization.encryption[Contract.TypeNames.AwsEcrRepositoryEncryptionType][repository.encryptionType]({
                                key:
                                    _.isEmpty(repositoryModel.kmsEncryptionKeyIdReferences)
                                        ? undefined
                                        : <Entity
                                            entityIdOrModel={repositoryModel.kmsEncryptionKeyIdReferences[0]}
                                            variant="text"/>
                            }),
                    translatedRepositoryTypeName
                })),
        publicAccessPermissionActions:
            resourceRiskContext.getPublicAccessPermissionActions(
                repository.publicAccessHighSeveritySensitivePermissionActions,
                repository.publicAccessPermissionActions)
    };
}