import React, { Fragment } from "react";
import { AuditEventsIcon, RisksIcon } from "../../../../../../../../../common";
import { ProfileCategory } from "../profileCategory";

type CategoryViewIconProps = {
    category: ProfileCategory;
};

export function CategoryIcon({ category }: CategoryViewIconProps) {
    switch (category) {
        case ProfileCategory.Overview:
            return <AuditEventsIcon/>;
        case ProfileCategory.Findings:
            return <RisksIcon/>;
        default:
            return <Fragment/>;
    }
}