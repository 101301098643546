import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, DeferredFilter, EmptyMessageText, IconText, InlineItems, Optional, optionalTableCell, PagedValuesFilter, PagedValuesFilterValuePage, SearchList, TimeCell, TimeFormatter, TimeRangeFilter, useLocalization, useOperation, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef } from "react";
import { CodeController, CodeIntegrationFilter, CodeTenant, Contract, CustomerConsoleAppUrlHelper, ItemSelectionHelper, ScanResult, scopeSystemEntityModelStore, ScopeTenantFilter, SeverityFilter, StorageHelper, tenantModelStore, TimeRangeHelper, useTheme, VulnerabilityChart } from "../../../../../../../../../../common";
import { CodeIntegrationIcon, useCodeIntegrationTranslator } from "../../../../../../../../../../tenants";
import { ProfileCategory } from "../Profile";

export function Table() {
    const dataTableActionsRef = useRef<DataTableActions>();
    const fetchCodeScans =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { scanPage } =
                await CodeController.getContainerImageScanPage(
                    new Contract.CodeControllerGetContainerImageScanPageRequest(
                        new Contract.CodeContainerImageScanFilters(
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.CommitBranchName]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.CommitIdentityName]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.ContainerImageConfigurationDigest]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.Digest]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.IntegrationId]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.OperatingSystemDisplayName]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.PackageDisplayName]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.RepositoryRawUrl]),
                            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.Success]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.Tag]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.TenantId]),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.Time]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.VulnerabilityRawId]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPageRequestProperty.VulnerabilitySeverity])),
                        limit,
                        skip,
                        new Contract.CodeControllerGetContainerImageScanPageRequestSort(
                            _.isNil(sort) || sort.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            _.isNil(sort)
                                ? Contract.CodeControllerGetContainerImageScanPageRequestProperty.Time
                                : sort.columnId as Contract.CodeControllerGetContainerImageScanPageRequestProperty)));
            return new DataTableFetchItemsResult(
                { count: scanPage.count! },
                scanPage.items,
                !scanPage.hasMore);
        };
    const getScanFilterItemPage =
        async (property: Contract.CodeControllerGetContainerImageScanPageRequestProperty, searchText: Optional<string>, skip: number, limit: number, data: number) => {
            const { scanFilterItemPage } =
                await CodeController.getContainerImageScanFilterItemPage(
                    new Contract.CodeControllerGetContainerImageScanFilterItemPageRequest(
                        limit,
                        property,
                        searchText,
                        skip)) as Contract.CodeControllerGetContainerImageScanFilterItemPageResponse;
            const scanFilterItemCount = scanFilterItemPage.count ?? data;

            return new PagedValuesFilterValuePage(
                scanFilterItemCount,
                scanFilterItemPage.emptyValue,
                scanFilterItemPage.items,
                () => scanFilterItemCount);
        };

    const [filtersPromise] =
        useOperation(
            Table,
            async () => {
                const { filters } = await CodeController.getContainerImageScanFilters();
                return filters;
            });

    const codeIntegrationConfigurationModels = scopeSystemEntityModelStore.useGetCodeIntegration();
    const codeIntegrationConfigurationMap =
        useMemo(
            () =>
                _(codeIntegrationConfigurationModels).
                    map(scopeEntityModel => scopeEntityModel.configuration as Contract.CodeIntegrationConfiguration).
                    keyBy(codeIntegrationConfiguration => codeIntegrationConfiguration.id).
                    value(),
            [codeIntegrationConfigurationModels]);

    const codeIntegrationTranslator = useCodeIntegrationTranslator();

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.scans.table",
            () => ({
                actions: {
                    csvExport: {
                        fileNamePrefix: "Container Image CI/CD Scans"
                    }
                },
                columns: {
                    commitBranchName: "Branch",
                    commitIdentityName: "User",
                    containerImageId: "Image ID",
                    digest: "Digest",
                    integration: {
                        title: "Integration",
                        tooltip: "{{type}} | {{name}}"
                    },
                    operationSystemDisplayName: "Operating System",
                    packageDisplayNames: {
                        empty: "No Matching Software",
                        title: "Software"
                    },
                    repositoryRawUrl: "Container Repository",
                    success: {
                        false: "Failed",
                        title: "Result",
                        true: "Passed"
                    },
                    tag: "Image Tag",
                    tenantId: "Repository",
                    time: "Time",
                    vulnerabilities: "Vulnerabilities",
                    vulnerabilitySeverity: "Severity",
                    vulnerabilitySeverityChart: "Vulnerability Severity"
                },
                empty: {
                    withFilter: "No Matching Scans",
                    withoutFilter: "No Scans"
                }
            }));


    const getOperationSystemDisplayName =
        (scan: Contract.CodeContainerImageScan) =>
            _.isNil(scan.operatingSystem.distribution)
                ? scan.operatingSystem.type
                : `${scan.operatingSystem.distribution} ${scan.operatingSystem.version}`;

    async function getExportData(_itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<any>>, items: Contract.CodeContainerImageScan[]): Promise<Dictionary<Contract.CodeTenantModel>> {
        const tenantModels =
            await tenantModelStore.get(
                _(items).
                    map((item: Contract.CodeContainerImageScan) => item.tenantId).
                    uniq().
                    value());


        return _.keyBy(
            tenantModels as Contract.CodeTenantModel[],
            tenantModel => tenantModel.configuration.id);
    }

    const sortRef = useRef<DataTableSort>();

    const theme = useTheme();
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerWorkloadAnalysisCodeScansColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerWorkloadAnalysisCodeScansColumnSelector
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{
                fileNamePrefix: localization.actions.csvExport.fileNamePrefix(),
                getData: getExportData
            }}
            fetchItems={fetchCodeScans}
            filtersOptions={{
                persist: {
                    visibilityStorageItem: StorageHelper.customerWorkloadAnalysisCodeScansFilters
                }
            }}
            getItemId={(item: Contract.CodeContainerImageScan) => item.id}
            rowOptions={{
                getUrl: (item: Contract.CodeContainerImageScan) =>
                    CustomerConsoleAppUrlHelper.getWorkloadAnalysisCodeScanProfileHashUrl(item.id, StorageHelper.customerWorkloadAnalysisCodeScanProfileSelectedTab.getValue() as ProfileCategory)
            }}
            onSortChanged={sort => sortRef.current = sort}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.time()]: TimeFormatter.workloadResourceDateTime(item.time)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.time()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.timeRange.emptyValue}
                                        placeholder={localization.columns.time()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.timeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.Time}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.Time}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeContainerImageScan>) =>
                        <TimeCell time={item.time}/>}
                selectorOptions={{ disabled: true }}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.time()}/>
            <DataTableColumn
                cellMaxWidth="medium"
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan, exportData: Dictionary<Contract.CodeTenantModel>) => ({ [localization.columns.tenantId()]: exportData[item.tenantId].configuration.displayReference })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.tenantId()}>
                                {filters =>
                                    <ScopeTenantFilter
                                        placeholder={localization.columns.tenantId()}
                                        tenantIds={filters.tenantIdItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.TenantId}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.TenantId}
                render={({ item }: DataTableColumnRenderProps<Contract.CodeContainerImageScan>) => <CodeTenant tenantId={item.tenantId}/>}
                selectorOptions={{ disabled: true }}
                sortOptions={{ enabled: false }}
                title={localization.columns.tenantId()}/>
            <DataTableColumn
                cellMaxWidth="medium"
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.commitBranchName()]: item.commit.branchName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPageRequestProperty.CommitBranchName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.commitBranchName()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.CommitBranchName}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.CommitBranchName}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeContainerImageScan>) =>
                        <Typography noWrap={true}>
                            {item.commit.branchName}
                        </Typography>}
                selectorOptions={{ disabled: true }}
                sortOptions={{ enabled: false }}
                title={localization.columns.commitBranchName()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => {
                            const codeIntegrationConfiguration = codeIntegrationConfigurationMap[item.integrationId];
                            return ({
                                [localization.columns.integration.title()]:
                                    localization.columns.integration.tooltip({
                                        name: codeIntegrationConfiguration.name,
                                        type: codeIntegrationTranslator(codeIntegrationConfiguration)
                                    })
                            });
                        }
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.integration.title()}>
                                {filters =>
                                    <CodeIntegrationFilter
                                        codeIntegrationConfigurationMap={codeIntegrationConfigurationMap}
                                        codeIntegrationsIds={filters.integrationIdItems.items}
                                        emptyValue={filters.integrationIdItems.emptyValue}
                                        placeholder={localization.columns.integration.title()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.IntegrationId}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.IntegrationId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeContainerImageScan>) => {
                        const codeIntegrationConfiguration = codeIntegrationConfigurationMap[item.integrationId];
                        return (
                            <IconText
                                icon={
                                    <CodeIntegrationIcon
                                        configuration={codeIntegrationConfiguration}
                                        sx={{ fontSize: "18px" }}/>}
                                text={<Typography noWrap={true}>{codeIntegrationConfiguration.name}</Typography>}
                                titleOrGetTitle={codeIntegrationTranslator(codeIntegrationConfiguration)}/>);
                    }
                }
                selectorOptions={{ default: false }}
                sortOptions={{ enabled: false }}
                title={localization.columns.integration.title()}/>
            <DataTableColumn
                cellMaxWidth="medium"
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.commitIdentityName()]: item.commit.identityName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPageRequestProperty.CommitIdentityName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.commitIdentityName()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.CommitIdentityName}
                itemProperty={(item: Contract.CodeContainerImageScan) => item.commit.identityName}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.CommitIdentityName}
                sortOptions={{ enabled: false }}
                title={localization.columns.commitIdentityName()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.repositoryRawUrl()]: item.repositoryRawUrl ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPageRequestProperty.RepositoryRawUrl,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.repositoryRawUrl()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.RepositoryRawUrl}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.RepositoryRawUrl}
                render={
                    optionalTableCell<Contract.CodeContainerImageScan>(scan => scan.repositoryRawUrl)}
                sortOptions={{ enabled: false }}
                title={localization.columns.repositoryRawUrl()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.tag()]: item.tag ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPageRequestProperty.Tag,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.tag()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.Tag}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.Tag}
                render={
                    optionalTableCell<Contract.CodeContainerImageScan>(scan => scan.tag)}
                title={localization.columns.tag()}/>
            <DataTableColumn
                cellMaxWidth="small"
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.digest()]: item.digest ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPageRequestProperty.Digest,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.digest()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.Digest}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.Digest}
                render={
                    optionalTableCell<Contract.CodeContainerImageScan>(scan => scan.digest)}
                sortOptions={{ enabled: false }}
                title={localization.columns.digest()}/>
            <DataTableColumn
                cellMaxWidth="small"
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.containerImageId()]: item.configurationDigest
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPageRequestProperty.ContainerImageConfigurationDigest,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.containerImageId()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.ContainerImageConfigurationDigest}
                itemProperty={(scan: Contract.CodeContainerImageScan) => scan.configurationDigest}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.ContainerImageConfigurationDigest}
                sortOptions={{ enabled: false }}
                title={localization.columns.containerImageId()}/>
            <DataTableColumn
                cellMinWidth={110}
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.vulnerabilities()]: item.vulnerabilityRawIds.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: [
                        {
                            default: true,
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        (searchText, skip, limit, data) =>
                                            getScanFilterItemPage(
                                                Contract.CodeControllerGetContainerImageScanPageRequestProperty.VulnerabilityRawId,
                                                searchText,
                                                skip,
                                                limit,
                                                data)}
                                    placeholder={localization.columns.vulnerabilities()}/>,
                            id: Contract.CodeControllerGetContainerImageScanPageRequestProperty.VulnerabilityRawId,
                            title: localization.columns.vulnerabilities()
                        },
                        {
                            element: <SeverityFilter placeholder={localization.columns.vulnerabilitySeverityChart()}/>,
                            id: Contract.CodeControllerGetContainerImageScanPageRequestProperty.VulnerabilitySeverity,
                            title: localization.columns.vulnerabilitySeverityChart()
                        }
                    ]
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.VulnerabilityRawId}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.VulnerabilityRawId}
                render={
                    ({ item: scan }: DataTableColumnRenderProps<Contract.CodeContainerImageScan>) =>
                        <VulnerabilityChart
                            vulnerabilities={
                                _(scan.vulnerabilitySeverityToRawIdsMap).
                                    toPairs().
                                    map(([severity, vulnerabilityRawIds]) =>
                                        _.map(
                                            vulnerabilityRawIds,
                                            vulnerabilityRawId =>
                                                new Contract.VulnerabilityData(
                                                    vulnerabilityRawId,
                                                    severity as Contract.Severity))).
                                    flatMap().
                                    value()}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.vulnerabilities()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.packageDisplayNames.title()]: item.packageDisplayNames.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPageRequestProperty.PackageDisplayName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.packageDisplayNames.title()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.PackageDisplayName}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.PackageDisplayName}
                render={
                    optionalTableCell<Contract.CodeContainerImageScan>(
                        containerImageScan =>
                            _.isEmpty(containerImageScan.packageDisplayNames)
                                ? undefined
                                : <InlineItems
                                    items={containerImageScan.packageDisplayNames}
                                    itemsPopover={
                                        items =>
                                            <SearchList
                                                emptyMessageText={new EmptyMessageText(localization.columns.packageDisplayNames.empty())}
                                                itemOptions={{ spacing: 1 }}
                                                items={items}
                                                sx={{ padding: theme.spacing(0, 1.5) }}
                                                variant="dropdown"/>}
                                    variant="itemPlusItemCount">
                                    {packageDisplayName =>
                                        <Typography noWrap={true}>
                                            {packageDisplayName}
                                        </Typography>}
                                </InlineItems>)}
                selectorOptions={{ default: false }}
                title={localization.columns.packageDisplayNames.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.operationSystemDisplayName()]: getOperationSystemDisplayName(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPageRequestProperty.OperatingSystemDisplayName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.operationSystemDisplayName()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.OperatingSystemDisplayName}
                itemProperty={getOperationSystemDisplayName}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.OperatingSystemDisplayName}
                selectorOptions={{ default: false }}
                sortOptions={{ enabled: false }}
                title={localization.columns.operationSystemDisplayName()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageScan) => ({
                            [localization.columns.success.title()]:
                                item.success
                                    ? localization.columns.success.true()
                                    : localization.columns.success.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.success.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.success.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.success.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPageRequestProperty.Success}
                itemProperty={
                    (vulnerabilityModel: Contract.CodeContainerImageScan) =>
                        <ScanResult
                            failed={localization.columns.success.false()}
                            passed={localization.columns.success.true()}
                            success={vulnerabilityModel.success}/>}
                key={Contract.CodeControllerGetContainerImageScanPageRequestProperty.Success}
                sortOptions={{ enabled: false }}
                title={localization.columns.success.title()}/>
        </DataTable>);
}