import { map } from "@infrastructure";
import React from "react";
import { Contract } from "../../../controllers";
import { RisksIcon } from "../../../icons";
import { ObjectTypeMetadataModelHelper } from "../../../utilities";
import { EntityIcon } from "../../EntityIcon";

type ObjectIconProps = {
    objectTypeName: string;
};

export function ObjectIcon({ objectTypeName }: ObjectIconProps) {
    const objectTypeMetadataModel = ObjectTypeMetadataModelHelper.get(objectTypeName);
    return (
        map(
            objectTypeMetadataModel.udmType,
            {
                [Contract.UdmObjectType.Entity]:
                    () =>
                        <EntityIcon
                            entityTypeName={objectTypeName}
                            sx={{ fontSize: "16px" }}/>,
                [Contract.UdmObjectType.Risk]: () => <RisksIcon sx={{ fontSize: "16px" }}/>
            })
    );
}