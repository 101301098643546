import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableSort, DataTableSortDirection, DataTableSortType, EmptyMessageText, map, Optional, optionalTableCell, StringHelper, TimeFormatter, useChangeEffect, useExecuteOperation, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef } from "react";
import { useSetKubernetesClusterConnectorsContext } from "../..";
import { ConfigurationController, Contract, InlineEntities, scopeNodeModelStore, scopeSystemEntityModelStore, StorageHelper, useScopeNameTranslator, useScopeNavigationViewContext } from "../../../../../../../../../../../../../../common";
import { useIntegrationsContext, useSetIntegrationsContext } from "../../../../../../../../Integrations";
import { ActionsCell } from "./components";

export function Table() {
    const [{ kubernetesClusterConnectorIdToClusterIdsMap }] =
        useExecuteOperation(
            Table,
            ConfigurationController.getKubernetesClusterConnectorIdToClusterIdsMap);

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { childScopeEnabled } = useIntegrationsContext();
    const setIntegrationsContext = useSetIntegrationsContext();

    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const kubernetesClusterConnectorModels = scopeSystemEntityModelStore.useGetKubernetesClusterConnector();
    const filteredKubernetesClusterConnectorModels =
        useMemo(
            () =>
                _.filter(
                    kubernetesClusterConnectorModels,
                    kubernetesClusterConnectorModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            concatIf(
                                childScopeEnabled,
                                childScopeIds).
                            includes(kubernetesClusterConnectorModel.configuration.scopeId)),
            [childScopeEnabled, childScopeIds, kubernetesClusterConnectorModels, parentScopeIds]);

    const setKubernetesClusterConnectorsContext = useSetKubernetesClusterConnectorsContext();

    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useKubernetesItems.kubernetesClusterConnectors.table",
            () => ({
                actions: {
                    add: "Add Kubernetes Cluster Connector",
                    childScopeEnabled: "Flat View"
                },
                columns: {
                    clusters: "Clusters",
                    name: "Name",
                    scope: "Scope",
                    systemCreationTime: "Creation Time"
                },
                empty: "No kubernetes cluster connectors"
            }));

    function getKubernetesClusterConnectors(_filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _.orderBy(
            filteredKubernetesClusterConnectorModels,
            [
                filteredKubernetesClusterConnectorModel =>
                    map<string, number | string | undefined>(
                        sort?.columnId ?? TableColumnId.Scope,
                        {
                            [TableColumnId.Scope]: () => StringHelper.getSortValue(scopeNameTranslator(filteredKubernetesClusterConnectorModel.scopeId, { path: true })),
                            [TableColumnId.ClusterIds]: () => kubernetesClusterConnectorIdToClusterIdsMap[filteredKubernetesClusterConnectorModel.configuration.id]?.length,
                            [TableColumnId.Name]: () => StringHelper.getSortValue((filteredKubernetesClusterConnectorModel.configuration as Contract.KubernetesClusterConnectorConfiguration).name),
                            [TableColumnId.SystemCreationTime]: () => (filteredKubernetesClusterConnectorModel.configuration as Contract.KubernetesClusterConnectorConfiguration).systemCreationTime
                        }),
                filteredKubernetesClusterConnectorModel => StringHelper.getSortValue((filteredKubernetesClusterConnectorModel.configuration as Contract.KubernetesClusterConnectorConfiguration).name)
            ],
            sort?.direction === DataTableSortDirection.Descending
                ? "desc"
                : "asc");
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [filteredKubernetesClusterConnectorModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={getKubernetesClusterConnectors}
            getItemId={(item: Contract.ScopeSystemEntityModel) => item.configuration.id}>
            <DataTableAction>
                <CheckButton
                    checked={childScopeEnabled}
                    title={localization.actions.childScopeEnabled()}
                    onCheckedChanged={
                        checked => {
                            StorageHelper.customerConfigurationIntegrationsFlatView.setValue(checked);
                            setIntegrationsContext(_ => ({ childScopeEnabled: checked }));
                        }}/>
            </DataTableAction>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={
                        () =>
                            setKubernetesClusterConnectorsContext(
                                KubernetesClusterConnectorsContext => ({
                                    ...KubernetesClusterConnectorsContext,
                                    addOrEditOpen: true
                                }))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.KubernetesClusterConnectorConfiguration).name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.SystemCreationTime}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => TimeFormatter.longDateTime((item.configuration as Contract.KubernetesClusterConnectorConfiguration).systemCreationTime)}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.systemCreationTime()}/>
            <DataTableColumn
                id={TableColumnId.ClusterIds}
                render={
                    optionalTableCell<Contract.ScopeSystemEntityModel>(
                        item =>
                            _.isEmpty(kubernetesClusterConnectorIdToClusterIdsMap[item.configuration.id])
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={kubernetesClusterConnectorIdToClusterIdsMap[item.configuration.id]}
                                    entityTypeName={Contract.TypeNames.IKubernetesCluster}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.clusters()}/>
            <DataTableColumn
                id={TableColumnId.Scope}
                itemProperty={item => scopeNameTranslator(item.scopeId, { path: true })}
                title={localization.columns.scope()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    ClusterIds = "clusterIds",
    Name = "name",
    Scope = "scope",
    SystemCreationTime = "systemCreationTime"
}