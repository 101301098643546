import { InlineItems, map, UnexpectedError, useLocalization, useLocalizeList } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntityLinkOptions, EntityPropertyHelper, InlineEntities } from "..";

type InlineEntityPropertiesProps = {
    entityLinkOptions?: EntityLinkOptions;
    entityPropertyRawIdentifierToValuesMap: _.Dictionary<string[]>;
    entityTypeName: string;
    sx?: SxProps;
};

export function InlineEntityProperties({ entityLinkOptions, entityPropertyRawIdentifierToValuesMap, entityTypeName, sx }: InlineEntityPropertiesProps) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "common.inlineEntityProperties",
            () => ({
                namePluralizer: [
                    "{{count | NumberFormatter.humanize}} value for {{entityPropertyName}}",
                    "{{count | NumberFormatter.humanize}} values for {{entityPropertyName}}"
                ],
                placeholder: "0 fields"
            }));

    const rawIdentifierToIdentifierMap =
        useMemo(
            () =>
                _(entityPropertyRawIdentifierToValuesMap).
                    keys().
                    keyBy().
                    mapValues(rawIdentifier => EntityPropertyHelper.parseRawIdentifier(rawIdentifier)).
                    value(),
            [entityPropertyRawIdentifierToValuesMap]);

    return (
        <Typography
            component="span"
            sx={sx}>
            {_.some(entityPropertyRawIdentifierToValuesMap)
                ? localizeList(
                    _(entityPropertyRawIdentifierToValuesMap).
                        map(
                            (values, rawIdentifier) =>
                                map(
                                    rawIdentifierToIdentifierMap[rawIdentifier].valueType,
                                    {
                                        [Contract.EntityPropertyValueType.PrincipalReference]: () =>
                                            <InlineEntities
                                                entityIdsOrModels={values}
                                                entityLinkOptions={entityLinkOptions}
                                                entityTypeName={entityTypeName}
                                                key={rawIdentifier}
                                                namePluralizer={
                                                    count =>
                                                        localization.namePluralizer(
                                                            count,
                                                            { entityPropertyName: rawIdentifierToIdentifierMap[rawIdentifier].name })}
                                                sx={sx}
                                                variant="itemCountAndType"/>,
                                        [Contract.EntityPropertyValueType.String]: () =>
                                            <InlineItems
                                                items={values}
                                                key={rawIdentifier}
                                                namePluralizer={
                                                    count =>
                                                        localization.namePluralizer(
                                                            count,
                                                            { entityPropertyName: rawIdentifierToIdentifierMap[rawIdentifier].name })}
                                                sx={sx}
                                                variant="itemCountAndType">
                                                {(value: string, _) =>
                                                    <Typography
                                                        component="span"
                                                        noWrap={true}>
                                                        {value}
                                                    </Typography>}
                                            </InlineItems>
                                    },
                                    () => {
                                        throw new UnexpectedError("Identifier.valueType", rawIdentifierToIdentifierMap[rawIdentifier].valueType);
                                    }
                                )).
                        value())
                : localization.placeholder()}
        </Typography>);
}