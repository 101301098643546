import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useOciNetworkingLocalPeeringGatewayPeeringStatusTranslator, useOciNetworkingLocalPeeringGatewayStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useOciNetworkingLocalPeeringGatewayDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const localPeeringGatewayModel = resourceModel as Contract.OciNetworkingLocalPeeringGatewayModel;
    const localPeeringGateway = localPeeringGatewayModel.entity as Contract.OciNetworkingLocalPeeringGateway;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localPeeringGatewayPeeringStatusTranslator = useOciNetworkingLocalPeeringGatewayPeeringStatusTranslator();
    const localPeeringGatewayStatusTranslator = useOciNetworkingLocalPeeringGatewayStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingLocalPeeringGatewayDefinition",
            () => ({
                info: {
                    items: {
                        advertisedSubnets: "Advertised CIDRs",
                        associatedRouteTables: "Associated Route Tables",
                        associatedRouteTableSubnets: "Associated Subnets",
                        crossTenancyPeering: {
                            false: "No",
                            title: "Cross-Tenancy",
                            true: "Yes"
                        },
                        peerAdvertisedSubnets: "Peer Advertised CIDRs",
                        peeringStatus: "Peering Status",
                        peerLocalPeeringGateway: "Associated Local Peering Gateway",
                        peerVcn: "Peered Virtual Cloud Network",
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={localPeeringGatewayStatusTranslator(localPeeringGateway.status)}/>,
                    <InfoItem
                        key="peeringStatus"
                        title={localization.info.items.peeringStatus()}
                        value={localPeeringGatewayPeeringStatusTranslator(localPeeringGateway.peeringStatus)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={localPeeringGatewayModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={localPeeringGatewayModel.peerVcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="peerVcn"
                        title={localization.info.items.peerVcn()}/>,
                    <InfoItem
                        key="crossTenancyPeering"
                        title={localization.info.items.crossTenancyPeering.title()}
                        value={
                            localPeeringGateway.crossTenancyPeering
                                ? localization.info.items.crossTenancyPeering.true()
                                : localization.info.items.crossTenancyPeering.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={localPeeringGatewayModel.peerLocalPeeringGatewayIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingLocalPeeringGateway}
                        key="peerLocalPeeringGateway"
                        title={localization.info.items.peerLocalPeeringGateway()}/>,
                    <VerticalTopItemsInfoItem
                        items={localPeeringGatewayModel.advertisedSubnets}
                        key="advertisedSubnets"
                        title={localization.info.items.advertisedSubnets()}/>,
                    <VerticalTopItemsInfoItem
                        items={localPeeringGateway.peerAdvertisedSubnets}
                        key="peerAdvertisedSubnets"
                        title={localization.info.items.peerAdvertisedSubnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={localPeeringGatewayModel.routeTableIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingRouteTable}
                        key="routeTable"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingRouteTable)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={localPeeringGatewayModel.associatedRouteTableIds}
                        entityTypeName={Contract.TypeNames.OciNetworkingRouteTable}
                        key="associatedRouteTables"
                        title={localization.info.items.associatedRouteTables()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={localPeeringGatewayModel.associatedRouteTableSubnetIds}
                        entityTypeName={Contract.TypeNames.OciNetworkingSubnet}
                        key="associatedRouteTableSubnets"
                        title={localization.info.items.associatedRouteTableSubnets()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}