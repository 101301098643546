import { ActionMenuItem, AddIcon, CollapsedIcon, DataTable, DataTableActions, DataTableColumn, DataTableSort, DataTableSortDirection, DeleteIcon, EditIcon, EmptyMessageText, map, Menu, Message, NotValidIcon, Optional, StringHelper, SuccessIcon, useChangeEffect, useLocalization } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, ReactNode, useRef, useState } from "react";
import { JiraAddOrEditType, useSetJiraContext } from "../../../..";
import { ConfigurationController, Contract, Scope, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../../common";
import { ActionsCell, StatusCell } from "./components";
import { useJiraInstanceStateProjectIssueTranslator } from "./hooks";

type JiraInstanceProps = {
    instanceModel: Contract.ScopeSystemEntityModel;
};

export function Instance({ instanceModel }: JiraInstanceProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [deleteInstanceExecuting, setDeleteInstanceExecuting] = useState(false);
    const [deleteInstanceError, setDeleteInstanceError] = useState(false);

    const readOnly = instanceModel.configuration.scopeId != scopeNodeModel.configuration.id;

    async function deleteInstance() {
        setDeleteInstanceExecuting(true);
        setDeleteInstanceError(false);

        try {
            await ConfigurationController.deleteJiraInstance(new Contract.ConfigurationControllerDeleteJiraInstanceRequest(instanceModel.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteInstanceError(true);
        }

        setDeleteInstanceExecuting(false);
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [instanceModel]);

    const setJiraContext = useSetJiraContext();

    const jiraInstanceStateProjectIssueTranslator = useJiraInstanceStateProjectIssueTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.list.instance",
            () => ({
                actions: {
                    add: "Add Project",
                    delete: {
                        error: "Failed to delete",
                        message: "Are you sure you want to delete this Jira instance?",
                        title: "Delete"
                    },
                    edit: "Edit"
                },
                columns: {
                    projectId: "Key",
                    projectName: "Name",
                    status: "Status"
                },
                empty: "No Projects",
                scope: " | Scope: {{scope}}",
                summary: {
                    connected: "Connected",
                    title: [
                        "**{{name}}** | 1 project | status: {{instanceStatusIcon}} {{translatedInstanceStatus}}",
                        "**{{name}}** | {{count | NumberFormatter.humanize}} projects | status: {{instanceStatusIcon}} {{translatedInstanceStatus}}"
                    ],
                    [Contract.TypeNames.JiraInstanceStateIssue]: {
                        [Contract.JiraInstanceStateIssue.InvalidUrl]: "Instance deleted",
                        [Contract.JiraInstanceStateIssue.UserNotAuthenticated]: "Disconnected"
                    }
                }
            }));

    function getInstances(filterMap: Dictionary<unknown>, sort: Optional<DataTableSort>) {
        return _((instanceModel.configuration as Contract.JiraInstanceConfiguration).projects).
            map(project => new InstanceItem(instanceModel, project)).
            orderBy(
                ({ projectConfiguration }) =>
                    map<string, number | string | undefined>(
                        sort?.columnId ?? TableColumnId.ProjectName,
                        {
                            [TableColumnId.ProjectId]: () => projectConfiguration.rawId,
                            [TableColumnId.ProjectName]: () => StringHelper.getSortValue((instanceModel.state as Contract.JiraInstanceState)?.projectRawIdToProjectMap[projectConfiguration.rawId].project.name),
                            [TableColumnId.Status]: () => jiraInstanceStateProjectIssueTranslator((instanceModel.state as Contract.JiraInstanceState)?.projectRawIdToProjectMap[projectConfiguration.rawId]?.issue)
                        }),
                sort?.direction === DataTableSortDirection.Descending
                    ? "desc"
                    : "asc").
            value();
    }

    const theme = useTheme();
    return (
        <Accordion defaultExpanded={!_.isEmpty((instanceModel.configuration as Contract.JiraInstanceConfiguration).projects)}>
            <AccordionSummary
                expandIcon={
                    !_.isEmpty((instanceModel.configuration as Contract.JiraInstanceConfiguration).projects) &&
                    <CollapsedIcon/>}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    sx={{ width: "100% " }}>
                    <Typography sx={{ flex: 1 }}>
                        {localization.summary.title(
                            (instanceModel.configuration as Contract.JiraInstanceConfiguration).projects.length,
                            {
                                instanceStatusIcon:
                                    <Box
                                        sx={{
                                            display: "inline-block",
                                            fontSize: "18px",
                                            margin: theme.spacing(-0.5, 0.5)
                                        }}>
                                        {map<Contract.JiraInstanceStateIssue, ReactNode>(
                                            (instanceModel.state as Contract.JiraInstanceState)?.issue,
                                            {
                                                [Contract.JiraInstanceStateIssue.InvalidUrl]: () =>
                                                    <NotValidIcon sx={{ color: theme.palette.error.main }}/>,
                                                [Contract.JiraInstanceStateIssue.UserNotAuthenticated]: () =>
                                                    <NotValidIcon sx={{ color: theme.palette.error.main }}/>
                                            },
                                            () => <SuccessIcon sx={{ color: theme.palette.success.main }}/>)}
                                    </Box>,
                                name: (instanceModel.configuration as Contract.JiraInstanceConfiguration).connectionInfo.url,
                                translatedInstanceStatus:
                                    _.isNil((instanceModel.state as Contract.JiraInstanceState)?.issue)
                                        ? localization.summary.connected()
                                        : localization.summary[Contract.TypeNames.JiraInstanceStateIssue][(instanceModel.state as Contract.JiraInstanceState)!.issue!]()
                            })}
                        {readOnly &&
                            <Fragment>
                                {localization.scope({
                                    scope:
                                        <Scope
                                            scopeId={instanceModel.configuration.scopeId}
                                            sx={{ color: theme.palette.text.primary }}
                                            variant="text"/>
                                })}
                            </Fragment>
                        }
                    </Typography>
                    {!readOnly &&
                        <Fragment>
                            <Menu
                                itemsOrGetItems={[
                                    new ActionMenuItem(
                                        () =>
                                            setJiraContext(
                                                () => ({
                                                    addOrEditType: JiraAddOrEditType.AddOrEditProject,
                                                    instanceModel,
                                                    projectConfiguration: undefined
                                                })),
                                        localization.actions.add(),
                                        {
                                            icon: <AddIcon/>
                                        }),
                                    new ActionMenuItem(
                                        () =>
                                            setJiraContext(
                                                jiraContext => ({
                                                    ...jiraContext,
                                                    addOrEditType: JiraAddOrEditType.AddOrEditInstance,
                                                    instanceModel
                                                })),
                                        localization.actions.edit(),
                                        {
                                            icon: <EditIcon/>
                                        }),
                                    new ActionMenuItem(
                                        () => deleteInstance(),
                                        localization.actions.delete.title(),
                                        {
                                            confirmOptions: {
                                                message: localization.actions.delete.message()
                                            },
                                            icon: <DeleteIcon/>
                                        })
                                ]}/>
                        </Fragment>}
                    {deleteInstanceExecuting && (
                        <CircularProgress
                            size={theme.spacing(3)}
                            variant="indeterminate"/>)}
                    {deleteInstanceError && (
                        <Message
                            level="error"
                            title={localization.actions.delete.error()}
                            variant="minimal"/>)}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <DataTable
                    actionsRef={dataTableActionsRef}
                    emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                    fetchItems={getInstances}
                    getItemId={(item: InstanceItem) => item.projectConfiguration.rawId}>
                    <DataTableColumn
                        id={TableColumnId.ProjectName}
                        itemProperty={(item: InstanceItem) => (item.instanceModel.state as Contract.JiraInstanceState)?.projectRawIdToProjectMap[item.projectConfiguration.rawId].project.name}
                        title={localization.columns.projectName()}/>
                    <DataTableColumn
                        id={TableColumnId.ProjectId}
                        itemProperty={(item: InstanceItem) => item.projectConfiguration.rawId}
                        title={localization.columns.projectId()}/>
                    <DataTableColumn
                        id={TableColumnId.Status}
                        render={StatusCell}
                        title={localization.columns.status()}/>
                    <DataTableColumn
                        id={TableColumnId.Actions}
                        render={ActionsCell}/>
                </DataTable>
            </AccordionDetails>
        </Accordion>);
}

enum TableColumnId {
    Actions = "actions",
    ProjectId = "id",
    ProjectName = "name",
    Status = "status"
}

export class InstanceItem {
    constructor(
        public instanceModel: Contract.ScopeSystemEntityModel,
        public projectConfiguration: Contract.JiraInstanceConfigurationProject) {
    }
}