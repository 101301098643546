import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpDefaultResourceInfoItemElements } from "..";
import { Contract, entityModelStore, InfoItem } from "../../../../../../../../../../../common";
import { useGcpArtifactRegistryTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { PackagesInfoCard } from "./components";

export function useGcpArtifactPackageRepositoryDefinition(entityModel: Contract.GcpEntityModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(entityModel);
    const defaultEntitiesInfoElements = useGcpDefaultResourceInfoItemElements(entityModel as Contract.GcpResourceModel);
    const repositoryModel = entityModel as Contract.GcpArtifactPackageRepositoryModel;
    const repository = repositoryModel.entity as Contract.GcpArtifactPackageRepository;
    const registryModel = entityModelStore.useGet(repository.registryId) as Contract.GcpArtifactRegistryModel;

    const artifactRegistryTypeTranslator = useGcpArtifactRegistryTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpArtifactPackageRepositoryDefinition",
            () => ({
                info: {
                    accessLevel: "Access",
                    registry: "Registry",
                    registryType: "Format"
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={registryModel as Contract.GcpArtifactRegistryModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultEntitiesInfoElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={registryModel}
                        entityTypeName={Contract.TypeNames.GcpArtifactRegistry}
                        key="registryId"
                        location="all"
                        title={localization.info.registry()}/>,
                    <InfoItem
                        key="registryType"
                        location="all"
                        title={localization.info.registryType()}
                        value={artifactRegistryTypeTranslator(repositoryModel.registryType)}/>
                ]}
                options={options?.infoOptions}>
                <PackagesInfoCard repositoryModel={repositoryModel}/>
            </Info>
    });
}