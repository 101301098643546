import { Action1 } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ComplianceHelper, CustomCompliance, TreeItem, TreeItemMoveForm, useTheme } from "../../../../../../../common";

type MoveDialogProps = {
    compliance: CustomCompliance;
    onSave: Action1<string[]>;
    pathIds?: string[];
};

export function MoveDialog({ compliance, onSave, pathIds }: MoveDialogProps) {
    const rootItem =
        useMemo(
            () => {
                function createItem(section: CustomCompliance, pathIds: string[]): TreeItem<MoveTreeItem> {
                    return new TreeItem(
                        {
                            name: section.name,
                            pathIds
                        },
                        _.map(
                            section.sections,
                            section =>
                                createItem(
                                    section,
                                    _.concat(
                                        pathIds,
                                        ComplianceHelper.getCustomComplianceId(section)))));
                }
                return createItem(compliance, []);
            },
            [compliance]);

    const selected =
        useMemo(
            () =>
                _.find(
                    rootItem.leaves(),
                    item =>
                        _.isEqual(
                            item.value.pathIds,
                            pathIds))?.value,
            [pathIds, rootItem]);

    const theme = useTheme();
    return (
        <TreeItemMoveForm
            rootItem={rootItem}
            selected={selected}
            onSave={item => onSave(item.pathIds)}>
            {item =>
                <Box>
                    <Typography
                        noWrap={true}
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            padding: theme.spacing(0.75)
                        }}>
                        {item.value.name}
                    </Typography>
                </Box>}
        </TreeItemMoveForm>);
}

type MoveTreeItem = {
    name: string;
    pathIds: string[];
};