import { DistributionBar, DistributionBarItem, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, TypeHelper, useSeverityTranslator, useTheme } from "../../../../../../../../../../../common";
import { GroupedItem } from "../../../Grouped";

type SeverityProps = {
    item: GroupedItem;
};

export function Severity({ item }: SeverityProps) {
    const highestSeverity =
        useMemo(
            () =>
                _(Contract.Severity).
                    values().
                    orderBy(
                        riskSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskSeverity),
                        "desc").
                    find(
                        riskSeverity =>
                            !_.isNil(item.riskGroup.severityToCountMap[riskSeverity]) &&
                            item.riskGroup.severityToCountMap[riskSeverity] > 0)!,
            [item]);

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.severity",
            () => ({
                count: "{{translatedSeverity}} {{count | NumberFormatter.humanize}}",
                percentage: "{{ratio | NumberFormatter.percentage}} {{translatedSeverity}}"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Stack
                sx={{
                    flex: 1,
                    maxWidth: "100%",
                    paddingRight: theme.spacing(1)
                }}>
                <DistributionBar
                    height="narrow"
                    items={
                        _(Contract.Severity).
                            values().
                            orderBy(riskSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskSeverity)).
                            map(
                                riskSeverity =>
                                    new DistributionBarItem(
                                        theme.palette.severity(riskSeverity),
                                        item.riskGroup.severityToCountMap[riskSeverity] ?? 0,
                                        localization.count({
                                            count: item.riskGroup.severityToCountMap[riskSeverity] ?? 0,
                                            translatedSeverity: severityTranslator(riskSeverity)
                                        }))).
                            value()}/>
            </Stack>
            <Typography
                noWrap={true}
                sx={{
                    flex: 1,
                    maxWidth: "100%"
                }}
                variant="h5">
                {localization.percentage({
                    ratio: item.riskGroup.severityToCountMap[highestSeverity] / item.riskGroup.riskCount,
                    translatedSeverity: severityTranslator(highestSeverity)
                })}
            </Typography>
        </Stack>);
}