﻿import { useLocalization } from "@infrastructure";
import { Grid } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { SectionHeader } from ".";
import { Contract, TenantHelper, TenantTypeStatus, useTenantTypeToTenantTypeStatusItem } from "../../../../../../../common";

type TenantSectionProps = {
    tenantTypeToTenantModelsMap: Dictionary<Contract.TenantModel[]>;
};

export function TenantSection({ tenantTypeToTenantModelsMap }: TenantSectionProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.tenantSection",
            () => ({
                subtitle: "See an overview of all your cloud accounts that were onboarded to Tenable Cloud Security. View a breakdown of organizations and accounts by cloud provider, and see the current status of your accounts.",
                title: "Account Overview"
            }));
    const tenantTypeToTenantsStatusViewItem = useTenantTypeToTenantTypeStatusItem(tenantTypeToTenantModelsMap, false);

    return (
        <Grid
            container={true}
            direction="column"
            spacing={3}>
            <Grid item={true}>
                <SectionHeader
                    subtitle={localization.subtitle()}
                    title={localization.title()}
                    variant="main"/>
            </Grid>
            <Grid item={true}>
                <Grid
                    alignItems="center"
                    container={true}
                    spacing={1}>
                    {_.map(
                        TenantHelper.DashboardTenantTypes,
                        tenantType =>
                            _.isNil(tenantTypeToTenantModelsMap[tenantType])
                                ? undefined
                                : <Grid
                                    item={true}
                                    key={tenantType}
                                    xs={12 / _.size(tenantTypeToTenantModelsMap)}>
                                    <TenantTypeStatus item={tenantTypeToTenantsStatusViewItem[tenantType]}/>
                                </Grid>)}
                </Grid>
            </Grid>
        </Grid>);
}