import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, NumberFormatter, optionalTableCell, TimeFormatter, TimeRangeFilter, useFormatRelativeTime, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { DataAnalysisResourceScanStatusCell, DataSensitivityFilter, InlineDataClassifierNames } from "../../../..";
import { Contract, DataAnalysisSensitivity, DataCategories, dataClassifierModelStore, LicensingHelper, TimeRangeHelper, useDataAnalysisErrorTranslator, useDataAnalysisSensitivityTranslator, useDataAnalysisStatusTranslator, useDataCategoryTranslator } from "../../../../../../../../../common";
import { DefinitionData } from "../../../Table";

export function useCommonDataAnalysisDefinition(definitionData: DefinitionData) {
    const formatRelativeTime = useFormatRelativeTime();
    const dataAnalysisResourceModelFiltersPromise = _.as<Promise<Contract.IDataAnalysisResourceModelFilters>>(definitionData.entityModelFiltersPromise);
    const dataClassifierModels = dataClassifierModelStore.useGetAll();
    const dataClassifierModelMap =
        useMemo(
            () =>
                _.keyBy(
                    dataClassifierModels,
                    dataClassifierModel => dataClassifierModel.id),
            [dataClassifierModels]);

    const dataAnalysisErrorTranslator = useDataAnalysisErrorTranslator();
    const dataAnalysisStatusTranslator = useDataAnalysisStatusTranslator();
    const dataCategoryTranslator = useDataCategoryTranslator();
    const dataAnalysisSensitivityTranslator = useDataAnalysisSensitivityTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonDataAnalysisDefinition",
            () => ({
                columns: {
                    dataAnalysisSensitivity: {
                        title: "Data Sensitivity",
                        tooltip: "Highest data sensitivity in resource"
                    },
                    dataCategories: "Data Categories",
                    dataCategorySensitivities: "Data Category Sensitivity",
                    dataClassifiers: "Data Types",
                    scanTime: "Scan Time",
                    status: {
                        export: {
                            titleOnly: "{{status}}",
                            withTooltip: "{{status}} ({{error}})"
                        },
                        title: "Scan Status"
                    },
                    storageSize: "Size"
                }
            }));

    return !LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
        ? { columns: {} }
        : {
            columns: {
                dataAnalysisResourceSensitivityColumn:
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                ({ dataAnalysisResourceSensitivity }: Contract.IDataAnalysisResourceModel) => ({
                                    [localization.columns.dataAnalysisSensitivity.title()]:
                                        _.isNil(dataAnalysisResourceSensitivity)
                                            ? ""
                                            : dataAnalysisSensitivityTranslator(dataAnalysisResourceSensitivity)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element: <DataSensitivityFilter placeholder={localization.columns.dataAnalysisSensitivity.title()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities}
                        key={Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities}
                        message={localization.columns.dataAnalysisSensitivity.tooltip()}
                        messageLevel="info"
                        render={
                            optionalTableCell<Contract.IDataAnalysisResourceModel>(
                                ({ dataAnalysisResourceSensitivity }) =>
                                    !_.isNil(dataAnalysisResourceSensitivity) &&
                                    <DataAnalysisSensitivity sensitivity={dataAnalysisResourceSensitivity}/>)}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.dataAnalysisSensitivity.title()}/>,
                dataAnalysisResourceStorageSizeColumn:
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                ({ storageSize }: Contract.IDataAnalysisResourceModel) => ({
                                    [localization.columns.storageSize()]:
                                        _.isNil(storageSize)
                                            ? "-"
                                            : NumberFormatter.storage(storageSize)
                                })
                        }}
                        id={Contract.EntityModelProperty.DataAnalysisResourceStorageSize}
                        key={Contract.EntityModelProperty.DataAnalysisResourceStorageSize}
                        render={
                            optionalTableCell<Contract.IDataAnalysisResourceModel>(
                                ({ storageSize }) =>
                                    !_.isNil(storageSize) &&
                                    NumberFormatter.storage(storageSize))}
                        selectorOptions={{ default: false }}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.storageSize()}/>,
                dataAnalysisStatusColumn:
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.EntityModel) => ({
                                    [localization.columns.status.title()]:
                                        _.isNil(_.as<Contract.IDataAnalysisResourceModel>(item).dataAnalysisError)
                                            ? localization.columns.status.export.titleOnly({
                                                status: dataAnalysisStatusTranslator(_.as<Contract.IDataAnalysisResourceModel>(item).dataAnalysisStatus)
                                            })
                                            : localization.columns.status.export.withTooltip({
                                                error: dataAnalysisErrorTranslator(_.as<Contract.IDataAnalysisResourceModel>(item).dataAnalysisError!, "export"),
                                                status: dataAnalysisStatusTranslator(_.as<Contract.IDataAnalysisResourceModel>(item).dataAnalysisStatus)
                                            })
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <EnumValuesFilter
                                        enumType={Contract.DataAnalysisResourceModelDataAnalysisStatus}
                                        enumTypeTranslator={dataAnalysisStatusTranslator}
                                        placeholder={localization.columns.status.title()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.DataAnalysisResourceScanStatus}
                        key={Contract.EntityModelProperty.DataAnalysisResourceScanStatus}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                                <DataAnalysisResourceScanStatusCell
                                    dataAnalysisError={_.as<Contract.IDataAnalysisResourceModel>(item).dataAnalysisError}
                                    dataAnalysisStatus={_.as<Contract.IDataAnalysisResourceModel>(item).dataAnalysisStatus}/>}
                        title={localization.columns.status.title()}/>,
                dataCategoriesColumn:
                    <DataTableColumn
                        cellMinWidth={130}
                        exportOptions={{
                            getItem:
                                (item: Contract.IDataAnalysisResourceModel) => ({
                                    [localization.columns.dataCategories()]:
                                        _.isEmpty(item.dataCategoryToSensitivityMap)
                                            ? ""
                                            : _(item.dataCategoryToSensitivityMap).
                                                keys().
                                                map(dataCategory => dataCategoryTranslator(dataCategory as Contract.DataCategory).title).
                                                join("\n")
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: true }}
                                        placeholder={localization.columns.dataCategories()}
                                        sorted={false}>
                                        <ValuesFilterItem
                                            title={dataCategoryTranslator(Contract.DataCategory.Pci).initials}
                                            value={Contract.DataCategory.Pci}/>
                                        <ValuesFilterItem
                                            title={dataCategoryTranslator(Contract.DataCategory.Phi).initials}
                                            value={Contract.DataCategory.Phi}/>
                                        <ValuesFilterItem
                                            title={dataCategoryTranslator(Contract.DataCategory.Pii).initials}
                                            value={Contract.DataCategory.Pii}/>
                                        <ValuesFilterItem
                                            title={dataCategoryTranslator(Contract.DataCategory.Secrets).initials}
                                            value={Contract.DataCategory.Secrets}/>
                                    </ValuesFilter>
                            }
                        }}
                        id={Contract.EntityModelProperty.DataAnalysisResourceDataCategories}
                        key={Contract.EntityModelProperty.DataAnalysisResourceDataCategories}
                        render={({ item }: DataTableColumnRenderProps<Contract.IDataAnalysisResourceModel>) =>
                            <DataCategories
                                categories={_.keys(item.dataCategoryToSensitivityMap) as Contract.DataCategory[]}
                                variant="dynamic"/>}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.dataCategories()}/>,
                dataClassifiersColumn:
                    (selectorOptionsDefault: boolean) =>
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (item: Contract.IDataAnalysisResourceModel) => ({
                                        [localization.columns.dataClassifiers()]:
                                            _.isEmpty(item.dataClassifierIdToDataSegmentCountMap)
                                                ? ""
                                                : _(item.dataClassifierIdToDataSegmentCountMap).
                                                    keys().
                                                    map(dataClassifierId => dataClassifierModelMap[dataClassifierId].name).
                                                    join("\n")
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <DeferredFilter
                                            promiseOrGetPromise={dataAnalysisResourceModelFiltersPromise}
                                            title={localization.columns.dataClassifiers()}>
                                            {filters =>
                                                <ValuesFilter
                                                    emptyValueOptions={{ enabled: true }}
                                                    placeholder={localization.columns.dataClassifiers()}>
                                                    {_.map(
                                                        filters.dataAnalysisResource.dataClassifierIdItems.items,
                                                        dataClassifierId =>
                                                            <ValuesFilterItem
                                                                key={dataClassifierId}
                                                                title={dataClassifierModelMap[dataClassifierId].name}
                                                                value={dataClassifierId}/>)}
                                                </ValuesFilter>}
                                        </DeferredFilter>
                                }
                            }}
                            id={Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers}
                            key={Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers}
                            render={
                                optionalTableCell<Contract.IDataAnalysisResourceModel>(({ dataClassifierIdToDataSegmentCountMap }) =>
                                    !_.isNil(dataClassifierIdToDataSegmentCountMap) &&
                                        !_.isEmpty(dataClassifierIdToDataSegmentCountMap) &&
                                            <InlineDataClassifierNames
                                                ids={
                                                    _(dataClassifierIdToDataSegmentCountMap).
                                                        keys().
                                                        value()}/>)}
                            selectorOptions={{ default: selectorOptionsDefault }}
                            sortOptions={{ enabled: false }}
                            title={localization.columns.dataClassifiers()}/>,
                scanTimeColumn:
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.EntityModel) => ({
                                    [localization.columns.scanTime()]:
                                        _.isNil(_.as<Contract.IDataAnalysisResourceModel>(item).scanTime)
                                            ? ""
                                            : TimeFormatter.iso8601String(_.as<Contract.IDataAnalysisResourceModel>(item).scanTime)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={dataAnalysisResourceModelFiltersPromise}
                                        title={localization.columns.scanTime()}>
                                        {filters =>
                                            <TimeRangeFilter
                                                emptyValue={filters.dataAnalysisResource.scanTimeRange.emptyValue}
                                                placeholder={localization.columns.scanTime()}
                                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.dataAnalysisResource.scanTimeRange.timeRange)}/>}
                                    </DeferredFilter>
                            }
                        }}
                        id={Contract.EntityModelProperty.DataAnalysisResourceScanTime}
                        key={Contract.EntityModelProperty.DataAnalysisResourceScanTime}
                        render={
                            optionalTableCell<Contract.EntityModel>(
                                item =>
                                    _.isNil(_.as<Contract.IDataAnalysisResourceModel>(item).scanTime)
                                        ? undefined
                                        : formatRelativeTime(_.as<Contract.IDataAnalysisResourceModel>(item).scanTime))}
                        selectorOptions={{ default: false }}
                        sortOptions={{ type: DataTableSortType.Date }}
                        title={localization.columns.scanTime()}/>
            }
        };
}