import { Action1 } from "@infrastructure";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { Contract, Identity } from "../../../../../../../../../../../../../../common";
import { Edit, View } from "./components";

type RiskNoteCreationAuditEventProps = {
    auditEventModel: Contract.AuditEventModel;
    onChange: Action1<Contract.AuditEventModel>;
    onDelete: Action1<string>;
};

export function RiskNoteCreationAuditEvent({ auditEventModel, onChange, onDelete }: RiskNoteCreationAuditEventProps) {
    const riskNoteCreationAuditEvent = auditEventModel.auditEvent as Contract.RiskNoteCreationAuditEvent;
    const [edit, setEdit] = useState(false);

    return (
        <Stack spacing={1}>
            <Identity
                identityIdOrReference={auditEventModel.auditEvent.identityReference}
                sx={{
                    fontSize: "13px",
                    fontWeight: 600
                }}
                variant="text"/>
            {edit
                ? <Edit
                    riskNoteCreationAuditEvent={riskNoteCreationAuditEvent}
                    onChange={onChange}
                    onClose={() => setEdit(false)}/>
                : <View
                    riskNoteCreationAuditEvent={riskNoteCreationAuditEvent}
                    onDelete={onDelete}
                    onEdit={() => setEdit(true)}/>}
        </Stack>);
}