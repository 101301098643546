import { ContextMenuItem, StringHelper } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AccessController, Contract, InlinePermissionActions as CommonInlinePermissionActions } from "../../../../../../../common";
import { usePermissionsContext } from "../Permissions";

type InlinePermissionActionsProps = {
    getContextMenuItemsPromise: (permissionAction: string) => Promise<ContextMenuItem[]>;
    getFilters: () => Contract.PermissionManagerRequestFilters;
    permissionsModel: Contract.PermissionsModel;
};

export function InlinePermissionActions({ getContextMenuItemsPromise, getFilters, permissionsModel }: InlinePermissionActionsProps) {
    const { definition, entityType } = usePermissionsContext();

    return (
        <CommonInlinePermissionActions
            deferredPermissionActions={{
                count: permissionsModel.permissionActionCount,
                firstItem: _.first(permissionsModel.permissionTopActions),
                getItems:
                    async () => {
                        const { actions } =
                            await AccessController.getPermissionsModelActions(
                                new Contract.AccessControllerGetPermissionsModelActionsRequest(
                                    definition.getPermissionsModelEntityId(permissionsModel),
                                    entityType,
                                    getFilters()));
                        return _(actions).
                            orderBy(action => StringHelper.getSortValue(action)).
                            value();
                    }
            }}
            getContextMenuItemsPromise={getContextMenuItemsPromise}
            variant="itemPlusItemCount"/>);
}