﻿import { useExecuteOperation } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CodeController, CodeTenantLink, Contract, InfoCard, InfoItem, ProfileLayout, ProfileTopbar, ProfileTopbarTimeInfoItem, RisksIcon, Severity, tenantModelStore, useRiskPolicyTranslator, useTheme } from "../../../../../../../../common";
import { RiskTypeMetadataModelHelper } from "../../../../../../../../common/utilities/riskTypeMetadataModelHelper";
import { Snippet } from "../../../../../../../../tenants";
import { Info } from "./components";

type ProfileProps = {
    riskId: string;
    scanId: string;
};

export function Profile({ riskId, scanId }: ProfileProps) {
    const [{ risk, scan }] =
        useExecuteOperation(
            [Profile, `${riskId}:${scanId}`],
            async () => {
                const [{ risk }, { scan }] =
                    await Promise.all([
                        CodeController.getIacRisk(new Contract.CodeControllerGetIacRiskRequest(riskId)),
                        CodeController.getIacScan(new Contract.CodeControllerGetIacScanRequest(scanId))
                    ]);
                return { risk, scan };
            });
    const tenantConfiguration = tenantModelStore.useGet(scan.tenantId).configuration as Contract.CodeTenantConfiguration;

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const riskPolicy = riskPolicyTranslator(RiskTypeMetadataModelHelper.get(risk.riskTypeName).policyConfigurationTypeName);

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<RisksIcon/>}
                    infoChildren={[
                        <Severity
                            key="severity"
                            severity={risk.severity}/>,
                        <CodeTenantLink
                            iconSx={{ fontSize: "16px" }}
                            key="codeTenantLink"
                            linkSx={{ maxWidth: theme.spacing(25) }}
                            spacing={0.5}
                            tenantConfiguration={tenantConfiguration}/>,
                        <ProfileTopbarTimeInfoItem
                            key="scannedTime"
                            time={scan.time}
                            type="scanned"/>]}
                    title={riskPolicy.title}/>}>
            <Stack
                sx={{
                    height: "100%",
                    overflow: "hidden auto",
                    padding: theme.spacing(2, 3, 0, 3)
                }}>
                <InfoCard>
                    <InfoItem
                        containerSx={{
                            maxWidth: "700px",
                            paddingBottom: theme.spacing(1)
                        }}
                        value={
                            <Typography sx={{ whiteSpace: "pre-line" }}>
                                {riskPolicy.description}
                            </Typography>}/>
                </InfoCard>
                <Info risk={risk}/>
                <Divider
                    flexItem={true}
                    sx={{ margin: theme.spacing(1, 0) }}
                    variant="fullWidth"/>
                <InfoCard
                    itemContainerSx={{ padding: 0 }}
                    title="Code">
                    <Box>
                        {_.map(
                            risk.snippets,
                            snippet =>
                                <Snippet
                                    codeType={risk.codeType}
                                    snippet={snippet}
                                    tenantId={risk.tenantId}/>)}
                    </Box>
                </InfoCard>
            </Stack>
        </ProfileLayout>);
}