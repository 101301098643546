﻿import _ from "lodash";
import { useEffect, useRef } from "react";

export function usePolling(action: () => Promise<any>, options: PollingOptions) {
    const actionRunningRef = useRef(false);
    const intervalIdRef = useRef<number>();
    useEffect(
        () => {
            if (!_.isNil(intervalIdRef.current)) {
                window.clearInterval(intervalIdRef.current);
            }

            if (options.enabled) {
                intervalIdRef.current =
                    window.setInterval(
                        async () => {
                            if (actionRunningRef.current) {
                                return;
                            }

                            actionRunningRef.current = true;
                            try {
                                await action();
                            } finally {
                                actionRunningRef.current = false;
                            }
                        },
                        options.interval ?? 500);
            }
        },
        [options.enabled, options.interval]);
}

type PollingOptions = {
    enabled: boolean;
    interval?: number;
};