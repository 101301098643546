import { Message, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import { isNil } from "lodash";
import React, { Fragment } from "react";
import { scopeNodeModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../common";
import { useInheritedScopeId } from "../../../../hooks";

function useIsVirtualMachineEnabled() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const inheritedScopeId = useInheritedScopeId(workloadAnalysis => workloadAnalysis.virtualMachine.enabled);

    const { enabled } = scopeNodeModel.configuration.scopeSections.workloadAnalysis.virtualMachine;

    if (!isNil(enabled)) {
        return enabled;
    }

    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    return scopeNodeMap[inheritedScopeId!].scopeNodeModel.configuration.scopeSections.workloadAnalysis.virtualMachine.enabled;
}

export function DisabledWarning() {
    const virtualMachineScanEnabled = useIsVirtualMachineEnabled();

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.maliciousFiles.disabledWarning",
            () => ({
                disabled: "Virtual Machine Scanning is not enabled in this scope"
            }));

    const theme = useTheme();
    return virtualMachineScanEnabled
        ? <Fragment/>
        : <Box sx={{ paddingBottom: theme.spacing(2) }}>
            <Message
                level="warning"
                title={localization.disabled()}
                variant="standard"/>
        </Box>;
}