import { Link, NumberFormatter } from "@infrastructure";
import { Grid, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { CommonRiskPolicyItem, Compliances, Contract, CustomerConsoleAppUrlHelper, LicensingHelper, RiskPolicyTypeMetadataHelper, ScopeHelper, scopeNodeModelStore, SeverityCount, TenantsIcon, TypeHelper, useEntityTypeNameTranslator, useRiskPolicyTranslator, useTheme } from "../../../../../../../../../../../common";
import { useGetRiskPolicyItemBuiltInComplianceTypes } from "../../../../../../../../../../Customer/components/Compliance/hooks";

type CommonProps = {
    scopeId: string;
    tableItem: CommonRiskPolicyItem;
};

export function Common({ scopeId, tableItem }: CommonProps) {
    const getRiskPolicyItemBuiltInComplianceTypes = useGetRiskPolicyItemBuiltInComplianceTypes();
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap();

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const translatedBuiltInRiskPolicy = riskPolicyTranslator(tableItem.riskPolicyConfigurationTypeName);
    const riskSeverities =
        _(Contract.Severity).
            values().
            filter(severity => severity !== Contract.Severity.Information).
            orderBy(
                riskSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskSeverity),
                "desc").
            value();

    const theme = useTheme();
    return (
        <Fragment>
            <Grid
                item={true}
                style={{
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1)
                }}
                xs={1.25}>
                <TenantsIcon
                    containerSx={{ flexWrap: "wrap" }}
                    sx={{ fontSize: "24px" }}
                    tenantTypes={tableItem.tenantTypes}/>
            </Grid>
            <Grid
                item={true}
                style={{ paddingRight: theme.spacing(1) }}
                xs={true}>
                <Link
                    urlOrGetUrl={
                        CustomerConsoleAppUrlHelper.getRisksUrl(
                            Contract.RisksView.Open,
                            {
                                policyConfigurationTypeNameOrIds: [tableItem.riskPolicyConfigurationTypeName],
                                tenantIds:
                                    scopeId === ScopeHelper.customerId
                                        ? undefined
                                        : scopeNodeMap[scopeId].tenantIds
                            })}>
                    <Typography>
                        {translatedBuiltInRiskPolicy.title}
                    </Typography>
                </Link>
            </Grid>
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                <Grid
                    item={true}
                    sx={{
                        paddingRight: theme.spacing(1),
                        width: theme.px(120)
                    }}>
                    <Compliances
                        builtInComplianceTypes={getRiskPolicyItemBuiltInComplianceTypes(tableItem)}
                        customComplianceIds={tableItem.customComplianceIds}
                        scopeId={scopeId}/>
                </Grid>}
            <Grid
                item={true}
                style={{ paddingRight: theme.spacing(1) }}
                xs={1.25}>
                <Typography>
                    {entityTypeNameTranslator(
                        RiskPolicyTypeMetadataHelper.getRiskedEntityTypeName(tableItem.riskPolicyConfigurationTypeName),
                        {
                            count: tableItem.result.analyzedEntityCount,
                            includeCount: true
                        })}
                </Typography>
            </Grid>
            <Grid
                item={true}
                style={{ paddingRight: theme.spacing(1) }}
                xs={1}>
                <SeverityCount
                    borderColor={theme.palette.success.main}
                    count={
                        _.isNil(tableItem.securityStats)
                            ? undefined
                            : NumberFormatter.humanize(tableItem.securityStats.securedCount)}
                    sx={{ padding: theme.spacing(0.25, 0.25) }}/>
            </Grid>
            <Grid
                item={true}
                xs={4}>
                <Stack
                    direction="row"
                    spacing={0.5}>
                    {_.map(
                        riskSeverities,
                        riskSeverity =>
                            <SeverityCount
                                count={
                                    _.isNil(tableItem.securityStats)
                                        ? undefined
                                        : NumberFormatter.humanize(tableItem.securityStats.unsecuredSeverityToCountMap[riskSeverity])}

                                severity={riskSeverity}
                                sx={{
                                    padding: theme.spacing(0.25, 0.25),
                                    width: theme.spacing(6.25)
                                }}/>)}
                </Stack>
            </Grid>
        </Fragment>);
}