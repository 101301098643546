﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { useAwsEksClusterNodeGroupStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info, TagsInfoItem } from "../../../../../components";
import { KubernetesTaintsInfoCard, NetworkingInfoCard } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonNetworkingInfoItemElements } from "../useAwsCommonNetworkingInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "../useAwsDefaultResourceInfoItemElements";
import { AutoScalingGroupsInfoCard } from "./components";

export function useAwsEksClusterNodeGroupDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const nodeGroupModel = resourceModel as Contract.AwsEksClusterNodeGroupModel;
    const nodeGroup = nodeGroupModel.entity as Contract.AwsEksClusterNodeGroup;
    const commonNetworkCardInfoItems = useAwsCommonNetworkingInfoItemElements({ resourceModel });

    const eksClusterNodeGroupStatusTranslator = useAwsEksClusterNodeGroupStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEksClusterNodeGroupDefinition",
            () => ({
                info: {
                    clusterId: "Cluster",
                    nodeAutoscalingMaxCount: "Maximum Number Of Nodes",
                    nodeAutoscalingMinCount: "Minimum Number Of Nodes",
                    nodeImageRawType: "Image Type",
                    nodeImageRawVersion: "Image Version",
                    nodeKeyName: "SSH Key Pair",
                    nodeKubernetesTags: "Kubernetes Labels",
                    nodeLaunchTemplate: "Launch Template",
                    nodeRawTypes: "Instance Types",
                    roleReference: "Node IAM Role",
                    sshSecurityGroupIdReferences: {
                        anySubnet: "Any IP Address",
                        title: "Allowed SSH Remote Access Sources"
                    },
                    status: "Status",
                    vpc: "VPC"
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.status()}
                        value={eksClusterNodeGroupStatusTranslator(nodeGroup.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={nodeGroup.clusterId}
                        entityTypeName={Contract.TypeNames.AwsEksCluster}
                        key="clusterId"
                        location="all"
                        title={localization.info.clusterId()}/>,
                    <TagsInfoItem
                        key="nodeKubernetesTags"
                        resourceTags={nodeGroup.node.kubernetesTags}
                        title={localization.info.nodeKubernetesTags()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={nodeGroup.node.roleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="roleReference"
                        title={localization.info.roleReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={nodeGroup.node.launchTemplateRevisionReference?.launchTemplateIdReference}
                        entityTypeName={Contract.TypeNames.AwsEc2LaunchTemplate}
                        key="nodeLaunchTemplate"
                        title={localization.info.nodeLaunchTemplate()}/>,
                    <InfoItem
                        key="nodeImageRawType"
                        title={localization.info.nodeImageRawType()}
                        value={nodeGroup.node.imageRawType}/>,
                    <InfoItem
                        key="nodeImageRawVersion"
                        title={localization.info.nodeImageRawVersion()}
                        value={nodeGroup.node.imageRawVersion}/>,
                    <VerticalTopItemsInfoItem
                        items={nodeGroup.node.rawTypes}
                        key="nodeRawTypes"
                        title={localization.info.nodeRawTypes()}/>,
                    <InfoItem
                        key="nodeAutoscalingMinCount"
                        title={localization.info.nodeAutoscalingMinCount()}
                        value={nodeGroup.nodeAutoscaling.minCount}/>,
                    <InfoItem
                        key="nodeAutoscalingMaxCount"
                        title={localization.info.nodeAutoscalingMaxCount()}
                        value={nodeGroup.nodeAutoscaling.maxCount}/>,
                    <InfoItem
                        key="nodeKeyName"
                        title={localization.info.nodeKeyName()}
                        value={
                            _.isNil(nodeGroup.node.keyName)
                                ? undefined
                                : nodeGroup.node.keyName}/>,
                    _.isNil(nodeGroup.node.keyName)
                        ? undefined
                        : _.isEmpty(nodeGroup.node.sshSecurityGroupEntityIdReferences)
                            ? <InfoItem
                                key="sshSecurityGroupIdReferences"
                                title={localization.info.sshSecurityGroupIdReferences.title()}
                                value={localization.info.sshSecurityGroupIdReferences.anySubnet()}/>
                            : <EntitiesInfoItem
                                entityIdsOrModels={nodeGroup.node.sshSecurityGroupEntityIdReferences}
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                key="sshSecurityGroupIdReferences"
                                title={localization.info.sshSecurityGroupIdReferences.title()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={nodeGroup.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
                {!_.isEmpty(nodeGroup.node.kubernetesTaints) &&
                    <KubernetesTaintsInfoCard kubernetesTaints={nodeGroup.node.kubernetesTaints}/>}
                {!_.isEmpty(nodeGroup.autoScalingGroupIds) &&
                    <AutoScalingGroupsInfoCard autoScalingGroupIds={nodeGroup.autoScalingGroupIds}/>}
            </Info>
    });
}