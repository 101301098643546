import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { Contract } from "../../";
import { AadIcon, AwsIcon, AzureIcon, CiTenantIcon, CiTenantIconData, CodeTenantIcon, CodeTenantIconData, ColoredAadIcon, ColoredAwsIcon, ColoredAzureIcon, ColoredCiTenantIcon, ColoredGciIcon, ColoredGcpIcon, ColoredOciIcon, ColoredOktaIcon, ColoredOneLoginIcon, ColoredOpIcon, ColoredPingIdentityIcon, GciIcon, GcpIcon, OciIcon, OktaIcon, OneLoginIcon, OpIcon, PingIdentityIcon } from "./icons";

type TenantIconProps =
    SvgIconProps & {
        colored?: boolean;
        data?: TenantIconData;
        tenantType: Contract.TenantType;
    };

export type TenantIconData = CodeTenantIconData | CiTenantIconData;

export function TenantIcon({ colored = true, data, tenantType, ...props }: TenantIconProps) {
    switch (tenantType) {
        case Contract.TenantType.Aad:
            return colored
                ? <ColoredAadIcon {...props}/>
                : <AadIcon {...props}/>;
        case Contract.TenantType.Aws:
            return colored
                ? <ColoredAwsIcon {...props}/>
                : <AwsIcon {...props}/>;
        case Contract.TenantType.Azure:
            return colored
                ? <ColoredAzureIcon {...props}/>
                : <AzureIcon {...props}/>;
        case Contract.TenantType.Ci:
            return colored
                ? <ColoredCiTenantIcon
                    data={data as CiTenantIconData}
                    {...props}/>
                : <CiTenantIcon
                    data={data as CiTenantIconData}
                    {...props}/>;
        case Contract.TenantType.Code:
            return (
                <CodeTenantIcon
                    data={data as CodeTenantIconData}
                    {...props}/>);
        case Contract.TenantType.Gci:
            return colored
                ? <ColoredGciIcon {...props}/>
                : <GciIcon {...props}/>;
        case Contract.TenantType.Gcp:
            return colored
                ? <ColoredGcpIcon {...props}/>
                : <GcpIcon {...props}/>;
        case Contract.TenantType.Oci:
            return colored
                ? <ColoredOciIcon {...props}/>
                : <OciIcon {...props}/>;
        case Contract.TenantType.Okta:
            return colored
                ? <ColoredOktaIcon {...props}/>
                : <OktaIcon {...props}/>;
        case Contract.TenantType.OneLogin:
            return colored
                ? <ColoredOneLoginIcon {...props}/>
                : <OneLoginIcon {...props}/>;
        case Contract.TenantType.Op:
            return colored
                ? <ColoredOpIcon {...props}/>
                : <OpIcon {...props}/>;
        case Contract.TenantType.PingIdentity:
            return colored
                ? <ColoredPingIdentityIcon {...props}/>
                : <PingIdentityIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}