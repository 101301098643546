import _ from "lodash";
import React from "react";
import { Mail, MailImg, MailTable, MailTd } from ".";
import { useTheme } from "../../../common";

type NotificationsMailProps = {
    footer?: string;
    link?: Link;
    mailTitle: string;
    subtitle: string;
    title: string;
};

type Link = {
    text: string;
    value: string;
};

export function NotificationMail({ footer, link, mailTitle, subtitle, title }: NotificationsMailProps) {
    const theme = useTheme();
    return (
        <Mail
            backgroundColor="#EBEDF0"
            title={mailTitle}>
            <tr>
                <MailTd
                    align="center"
                    style={{ padding: "70px 10px" }}>
                    <MailTable
                        bgcolor="#ffffff"
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="580">
                        <tr>
                            <MailTd align="center">
                                <MailTable
                                    align="center"
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    style={{ background: "linear-gradient(247.48deg, #B3D6F3 9.77%, #E9FCFF  105.63%)" }}
                                    width="580">
                                    <tr>
                                        <MailTd
                                            height="93"
                                            style={{ paddingLeft: 28 }}
                                            width="580">
                                            <MailImg
                                                alt="Company Logo"
                                                height="25"
                                                src="cid:Logo"
                                                width="271"/>
                                        </MailTd>
                                    </tr>
                                </MailTable>
                            </MailTd>
                        </tr>
                        <tr>
                            <MailTd
                                align="center"
                                style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingTop: 58
                                }}>
                                <MailTable
                                    align="center"
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    width="100%">
                                    <tr>
                                        <MailTd style={{ paddingBottom: 16 }}>
                                            <span
                                                style={{
                                                    fontSize: 18,
                                                    fontWeight: 600,
                                                    lineHeight: "20px"
                                                }}>
                                                {title}
                                            </span>
                                        </MailTd>
                                    </tr>
                                    <tr>
                                        <MailTd style={{ paddingBottom: 38 }}>
                                            <span
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 400,
                                                    lineHeight: "26px"
                                                }}>
                                                {subtitle}
                                            </span>
                                        </MailTd>
                                    </tr>
                                    {(!_.isNil(link) || !_.isNil(footer)) &&
                                        <tr>
                                            <MailTd style={{ paddingBottom: 58 }}>
                                                <MailTable
                                                    border="0"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    width="100%">
                                                    {!_.isNil(link) &&
                                                        <tr>
                                                            <MailTd>
                                                                <MailTable
                                                                    border="0"
                                                                    cellPadding="0"
                                                                    cellSpacing="0">
                                                                    <tr>
                                                                        <MailTd
                                                                            align="center"
                                                                            bgcolor={theme.palette.primary.main}
                                                                            style={{ borderRadius: theme.spacing(0.75) }}>
                                                                            <a
                                                                                href={link.value}
                                                                                style={{
                                                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                                                    borderRadius: theme.spacing(0.75),
                                                                                    color: "#ffffff",
                                                                                    display: "inline-block",
                                                                                    fontSize: "11px",
                                                                                    padding: "11px 20px",
                                                                                    textDecoration: "none"
                                                                                }}
                                                                                target="_blank">
                                                                                {link.text}
                                                                            </a>
                                                                        </MailTd>
                                                                    </tr>
                                                                </MailTable>
                                                            </MailTd>
                                                        </tr>}
                                                    {!_.isNil(footer) &&
                                                        <tr>
                                                            <MailTd style={{ paddingTop: 10 }}>
                                                                <span
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 400,
                                                                        lineHeight: "26px"
                                                                    }}>
                                                                    {footer}
                                                                </span>
                                                            </MailTd>
                                                        </tr>}
                                                </MailTable>
                                            </MailTd>
                                        </tr>}
                                </MailTable>
                            </MailTd>
                        </tr>
                    </MailTable>
                </MailTd>
            </tr>
        </Mail>);
}