import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../common";
import { AzureWebApplicationNetworkRulesTable } from "../../../../../../../../../../tenants";
import { useAzureWebApplicationAuthenticationTypeTranslator, useAzureWebApplicationTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../components";

export function useAzureWebApplicationDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const application = resourceGroupResourceModel.entity as Contract.AzureWebApplication;
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);

    const webApplicationAuthenticationTypeTranslator = useAzureWebApplicationAuthenticationTypeTranslator();
    const webApplicationTypeTranslator = useAzureWebApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureWebApplicationDefinition",
            () => ({
                info: {
                    cards: {
                        networkRules: "Access Restrictions",
                        scmNetworkRules: "SCM Access Restrictions"
                    },
                    items: {
                        authenticationType: "Authentication Type",
                        httpsOnly: {
                            false: "Disabled",
                            title: "HTTPS only",
                            true: "Enabled"
                        },
                        publicNetworkAccess: {
                            false: "Disabled",
                            title: "Public network access",
                            true: "Enabled"
                        },
                        scmUrl: "SCM URL",
                        status: "Status",
                        tlsMinVersion: "Minimum TLS Version",
                        type: "App Type",
                        url: "URL"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={webApplicationTypeTranslator(application.type)}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={application.status}/>,
                    <InfoItem
                        key="publicNetworkAccess"
                        location="profile"
                        title={localization.info.items.publicNetworkAccess.title()}
                        value={
                            application.publicNetworkAccess
                                ? localization.info.items.publicNetworkAccess.true()
                                : localization.info.items.publicNetworkAccess.false()}/>,
                    <InfoItem
                        key="httpsOnly"
                        title={localization.info.items.httpsOnly.title()}
                        value={
                            application.httpsOnly
                                ? localization.info.items.httpsOnly.true()
                                : localization.info.items.httpsOnly.false()}/>,
                    <InfoItem
                        key="tlsMinVersion"
                        title={localization.info.items.tlsMinVersion()}
                        value={
                            _.isNil(application.tlsMinVersion)
                                ? undefined
                                : application.tlsMinVersion}/>,
                    <InfoItem
                        key="url"
                        title={localization.info.items.url()}
                        value={
                            _.isNil(application.url)
                                ? undefined
                                : <Link
                                    urlOrGetUrl={application.url}
                                    variant="external"/>}/>,
                    <InfoItem
                        key="scmUrl"
                        title={localization.info.items.scmUrl()}
                        value={
                            _.isNil(application.scmUrl)
                                ? undefined
                                : <Link
                                    urlOrGetUrl={application.scmUrl}
                                    variant="external"/>}/>,
                    <InfoItem
                        key="authentication"
                        title={localization.info.items.authenticationType()}
                        value={
                            _.isNil(application.authenticationType)
                                ? undefined
                                : webApplicationAuthenticationTypeTranslator(application.authenticationType)}/>
                ]}
                options={options?.infoOptions}>
                {!_.isNil(application.networkRules) && (
                    <InfoCard title={localization.info.cards.networkRules()}>
                        <AzureWebApplicationNetworkRulesTable networkRules={application.networkRules}/>
                    </InfoCard>)}
                {!_.isNil(application.scmNetworkRules) && (
                    <InfoCard title={localization.info.cards.scmNetworkRules()}>
                        <AzureWebApplicationNetworkRulesTable networkRules={application.scmNetworkRules}/>
                    </InfoCard>)}
            </Info>
    });
}