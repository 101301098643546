import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useExecuteOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { Contract, EntitiesCell, Entity, EntityController, EntityFilter, entityModelStore, InlineEntities, InlinePermissionActions, TypeHelper, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";

export function useAwsPrincipalAllowedPermissionRiskDefinition(riskModel: Contract.RiskModel) {
    const principalAllowedPermissionRiskModel = riskModel as Contract.AwsPrincipalAllowedPermissionRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsPrincipalAllowedPermissionRiskDefinition",
            () => ({
                violations: "{{principals}} have {{permissionActions}}"
            }));
    const translatedViolations =
        localization.violations({
            permissionActions:
                <InlinePermissionActions
                    permissionActions={principalAllowedPermissionRiskModel.permissionActions}
                    variant="itemOrItemCountAndType"/>,
            principals:
                <InlineEntities
                    entityIdsOrModels={principalAllowedPermissionRiskModel.principalIds}
                    entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                    variant="itemAndTypeOrItemCountAndType"/>
        });

    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <ViolationTable risk={principalAllowedPermissionRiskModel.risk}/>);
}

type ViolationTableProps = {
    risk: Contract.AwsPrincipalAllowedPermissionRisk;
};

function ViolationTable({ risk }: ViolationTableProps) {
    const principalModels =
        entityModelStore.useGet(
            _.map(
                risk.principalItems,
                principalItem => principalItem.principalId));

    const [{ principalIdToModelAccessMap: serviceIdentityIdToModelAccessMap }] =
        useExecuteOperation(
            [ViolationTable, risk.id],
            async () =>
                await EntityController.getPrincipalIdToModelAccessMap(
                    new Contract.EntityControllerGetPrincipalIdToAccessMapRequest(
                        _(principalModels).
                            filter(
                                principalModel =>
                                    TypeHelper.extendOrImplement(
                                        principalModel.entity.typeName,
                                        Contract.TypeNames.IServiceIdentity)).
                            map(serviceIdentityModel => serviceIdentityModel.id).
                            value())));
    const serviceIdentityIdToOriginatorEntityIdsMap =
        useMemo(
            () =>
                _.mapValues(
                    serviceIdentityIdToModelAccessMap,
                    serviceIdentityModelAccess => _.as<Contract.ServiceIdentityModelAccess>(serviceIdentityModelAccess).originatorEntityIds),
            [serviceIdentityIdToModelAccessMap]);
    const originatorEntityModels =
        entityModelStore.useGet(
            _(serviceIdentityIdToOriginatorEntityIdsMap).
                values().
                flatMap().
                value());
    const policyModels =
        entityModelStore.useGet(
            _.flatMap(
                risk.principalItems,
                principalItem => principalItem.principalPolicyIds));
    const items =
        useMemo(
            () => {
                const originatorEntityModelMap =
                    _.keyBy(
                        originatorEntityModels,
                        originatorEntityModel => originatorEntityModel.id);
                const policyModelMap =
                    _.keyBy(
                        policyModels,
                        policyModel => policyModel.id);
                const principalModelMap =
                    _.keyBy(
                        principalModels,
                        principalModel => principalModel.id);

                return _.map(
                    risk.principalItems,
                    principalItem => {
                        const principalModel = principalModelMap[principalItem.principalId];
                        const originatorEntityIds = serviceIdentityIdToOriginatorEntityIdsMap[principalModel.id] ?? [];
                        return new ViolationTableItem(
                            principalItem,
                            originatorEntityIds,
                            _.map(
                                originatorEntityIds,
                                originatorEntityId => originatorEntityModelMap[originatorEntityId]),
                            _.map(
                                principalItem.principalPolicyIds,
                                principalPolicyId => policyModelMap[principalPolicyId]),
                            principalModel);
                    });
            },
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Originators]: {
                    getFilterValue: item => item.originatorEntityIds,
                    getSortValue: item => item.originatorEntityIds.length
                },
                [ViolationTableColumnId.PermissionActions]: {
                    getFilterValue: item => item.riskItem.permissionActions,
                    getSortValue: item => item.riskItem.permissionActions.length
                },
                [ViolationTableColumnId.Policy]: {
                    getFilterValue: item => item.riskItem.principalPolicyIds,
                    getSortValue: item => item.policyModels.length
                },
                [ViolationTableColumnId.Principal]: {
                    getFilterValue: item => item.principalModel.entity.id,
                    getSortValue: item => item.principalModel.entity.displayName
                }
            },
            ViolationTableColumnId.Principal);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsPrincipalAllowedPermissionRiskDefinition.violationTable",
            () => ({
                columns: {
                    originators: "Originators",
                    permissionActions: "Permissions",
                    policy: "Granted Through",
                    principal: "Principal"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.principalModel.id}
            sortEnabled={true}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Principal]}
                                placeholder={localization.columns.principal()}/>
                    }
                }}
                id={ViolationTableColumnId.Principal}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.principalModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.principal()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Originators]}
                                placeholder={localization.columns.originators()}/>
                    }
                }}
                id={ViolationTableColumnId.Originators}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.originatorEntityModels}
                            entityTypeName={Contract.TypeNames.Entity}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.originators()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.permissionActions()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.PermissionActions],
                                    permissionAction =>
                                        <ValuesFilterItem
                                            key={permissionAction}
                                            title={permissionAction}
                                            value={permissionAction}/>)}
                            </ValuesFilter>
                    }
                }}
                id={ViolationTableColumnId.PermissionActions}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <InlinePermissionActions
                            permissionActions={item.riskItem.permissionActions}
                            variant="itemOrItemCountAndType"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.permissionActions()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Policy]}
                                placeholder={localization.columns.policy()}/>
                    }
                }}
                id={ViolationTableColumnId.Policy}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <InlineEntities
                            entityIdsOrModels={item.policyModels}
                            entityTypeName={Contract.TypeNames.AwsIamPolicy}
                            variant="itemPlusItemCount"/>}
                title={localization.columns.policy()}/>
        </Table>);
}

enum ViolationTableColumnId {
    Originators = "originators",
    PermissionActions = "permissionActions",
    Policy = "policy",
    Principal = "principal"
}

class ViolationTableItem {
    constructor(
        public riskItem: Contract.AwsPrincipalAllowedPermissionRiskItem,
        public originatorEntityIds: string[],
        public originatorEntityModels: Contract.EntityModel[],
        public policyModels: Contract.EntityModel[],
        public principalModel: Contract.EntityModel) {
    }
}