import { CheckboxField, CollapsedIcon, NotValidIcon, Optional, Tooltip, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Ref, useEffect, useState } from "react";
import { Contract, ElasticsearchItemPageHelper, EntityController, entityModelStore, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { CustomRiskPolicyActions, Entity, PagedEntitySelector, useCustomRiskPolicyContext } from "../../../..";
import { EntitySelector, EntitySelectorAttributeSelection, EntitySelectorIdSelection, EntitySelectorNamePatternSelection, EntitySelectorSelection, EntitySelectorTagSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsRoleTemplateMismatchRiskPolicyConfiguration;
    templateTranslator: (options?: { roles: JSX.Element; templateRole: JSX.Element }) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const { scopeId } = useCustomRiskPolicyContext();

    const [templateRoleValid, setTemplateRoleValid] = useState<boolean | undefined>();
    const [templateRoleId, setTemplateRoleId] = useState<string | undefined>(riskPolicyConfiguration?.templateRoleId);
    const [validatePermissionBoundaryPolicy, setValidatePermissionBoundaryPolicy] = useState(riskPolicyConfiguration?.validatePermissionBoundaryPolicy ?? true);
    const [validateAssumeRolePolicy, setValidateAssumeRolePolicy] = useState(riskPolicyConfiguration?.validateAssumeRolePolicy ?? true);
    const [view, setView] = useState<EditView | undefined>(EditView.Roles);
    const [roleSelectorSelection, setRoleSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                undefined,
                undefined,
                undefined,
                riskPolicyConfiguration?.roleBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.roleCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.roleIds,
                riskPolicyConfiguration?.roleNamePattern,
                undefined,
                riskPolicyConfiguration?.roleTags,
                undefined));

    const [rolesValid, setRolesValid] = useState(false);

    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) =>
                    new Contract.RiskControllerInsertAwsRoleTemplateMismatchRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        roleSelectorSelection instanceof EntitySelectorAttributeSelection
                            ? roleSelectorSelection.builtInAttributeTypeNames
                            : undefined,
                        roleSelectorSelection instanceof EntitySelectorAttributeSelection
                            ? roleSelectorSelection.customAttributeDefinitionIds
                            : undefined,
                        roleSelectorSelection instanceof EntitySelectorIdSelection
                            ? roleSelectorSelection.ids
                            : undefined,
                        roleSelectorSelection instanceof EntitySelectorNamePatternSelection
                            ? roleSelectorSelection.namePattern
                            : undefined,
                        roleSelectorSelection instanceof EntitySelectorTagSelection
                            ? roleSelectorSelection.tags
                            : undefined,
                        templateRoleId!,
                        validateAssumeRolePolicy,
                        validatePermissionBoundaryPolicy)
        });

    const templateRoleModel = entityModelStore.useGet(riskPolicyConfiguration?.templateRoleId) as Optional<Contract.AwsIamRoleModel>;
    useEffect(
        () => {
            if (!_.isNil(templateRoleModel)) {
                setTemplateRoleValid(
                    !templateRoleModel.unknown &&
                    !templateRoleModel.entity.systemDeleted);
            }
        },
        []);

    useEffect(
        () => {
            const rolesValid = roleSelectorSelection?.valid() ?? false;
            setRolesValid(rolesValid);
            onValidChange(rolesValid && templateRoleValid === true);
        },
        [roleSelectorSelection, templateRoleValid]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsRoleTemplateMismatchRiskPolicy.edit",
            () => ({
                actions: "Reference setup",
                permissionBoundary: "Check permission boundary",
                prompt: "Select the required roles and reference role",
                roleMissing: {
                    template: "{{templateRole}} (deleted-select new role)",
                    tooltip: "Reference role was deleted and the policy is disabled. Please select an alternate role or delete the policy."
                },
                templateRolePlaceholder: "this reference role",
                templateRoleSectionTitle: "Reference Role",
                trustPolicy: "Check trust policy"
            }));
    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        roles:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.AwsIamRole}
                                selection={roleSelectorSelection}/>,
                        templateRole:
                            _.isNil(templateRoleId)
                                ? <Typography
                                    component="span"
                                    sx={{
                                        fontWeight: "bold",
                                        textDecoration: "underline"
                                    }}>
                                    {localization.templateRolePlaceholder()}
                                </Typography>
                                : templateRoleModel?.entity.systemDeleted === true || templateRoleModel?.unknown === true
                                    ? <Typography
                                        component="span"
                                        sx={{
                                            color: theme.palette.error.main,
                                            fontWeight: "bold"
                                        }}>
                                        {localization.roleMissing.template({
                                            templateRole: <Entity
                                                entityIdOrModel={templateRoleModel!}
                                                variant="text"/>
                                        })}
                                    </Typography>
                                    : <Entity
                                        entityIdOrModel={templateRoleId}
                                        variant="text"/>
                    })}
                </Typography>
                <Box>
                    <Accordion
                        expanded={view === EditView.Roles}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Roles
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AwsIamRole,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {rolesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.RelatedPublicComputeAttribute,
                                    Contract.TypeNames.VendorServiceIdentityAttribute,
                                    Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute,
                                    Contract.TypeNames.AdministratorPrincipalAttribute,
                                    Contract.TypeNames.BehaviorRiskIdentityAttribute,
                                    Contract.TypeNames.InactiveIdentityAttribute,
                                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.CodeResourceAttribute
                                ]}
                                entityTypeName={Contract.TypeNames.AwsIamRole}
                                properties={["attributes", "ids", "namePattern", "tags"]}
                                scopeId={scopeId}
                                selection={roleSelectorSelection}
                                onSelectionChanged={setRoleSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.TemplateRole}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.TemplateRole
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {localization.templateRoleSectionTitle()}
                                </Typography>
                                {templateRoleValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                                {templateRoleValid === false &&
                                    <Tooltip titleOrGetTitle={
                                        <Typography>
                                            {localization.roleMissing.tooltip()}
                                        </Typography>}>
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={1}>
                                            <NotValidIcon style={{ color: theme.palette.error.main }}/>
                                        </Stack>
                                    </Tooltip>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack sx={{ padding: theme.spacing(1, 1, 0, 0) }}>
                                <PagedEntitySelector
                                    getEntityModelPage={
                                        ElasticsearchItemPageHelper.makePagedEntitySelector(
                                            async (itemNextPageSearchCursor, searchText) => {
                                                const { entityModelPage } =
                                                await EntityController.searchEntityModels(
                                                    new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                                        false,
                                                        15,
                                                        itemNextPageSearchCursor,
                                                        scopeId,
                                                        searchText,
                                                        false,
                                                        Contract.TypeNames.AwsIamRole!));
                                                return entityModelPage;
                                            })}
                                    placeholder={entityTypeNameTranslator(Contract.TypeNames.AwsIamRole)}
                                    selectedEntityId={templateRoleId}
                                    onSelectedEntityIdChanged={
                                        entityId => {
                                            setTemplateRoleId(entityId);
                                            setTemplateRoleValid(true);
                                        }}/>
                            </Stack>
                            <Stack sx={{ padding: theme.spacing(1, 0, 0) }}>
                                <CheckboxField
                                    checked={validatePermissionBoundaryPolicy}
                                    onChange={() => setValidatePermissionBoundaryPolicy(!validatePermissionBoundaryPolicy)}>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}>
                                        <Typography
                                            sx={{
                                                color:
                                                theme.palette.text.primary,
                                                fontSize: "13px"
                                            }}>
                                            {localization.permissionBoundary()}
                                        </Typography>
                                    </Stack>
                                </CheckboxField>
                            </Stack>
                            <Stack sx={{ padding: theme.spacing(1, 0, 0) }}>
                                <CheckboxField
                                    checked={validateAssumeRolePolicy}
                                    onChange={() => setValidateAssumeRolePolicy(!validateAssumeRolePolicy)}>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.text.primary,
                                                fontSize: "13px"
                                            }}>
                                            {localization.trustPolicy()}
                                        </Typography>
                                    </Stack>
                                </CheckboxField>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}

enum EditView {
    Roles = "roles",
    TemplateRole = "templateRole"
}