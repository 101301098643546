﻿import React from "react";

export function BrIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M0.73274 18.8194C4.01203 5.53968 17.4421 -2.55831 30.722 0.733453C44.0019 4.0127 52.1 17.4426 48.8082 30.7223C45.5164 44.002 32.0988 52.1 18.8189 48.8082C5.55155 45.5165 -2.55907 32.0866 0.73274 18.8194Z"
                fill="#56AF31"/>
            <path
                d="M1.72155 24.8646L24.589 39.471L47.4565 24.8646L24.589 10.2582L1.72155 24.8646Z"
                fill="#FFD300"/>
            <path
                d="M24.5891 15.0664C29.9962 15.0664 34.3769 19.4471 34.3769 24.8666C34.3769 30.2736 29.9962 34.6668 24.5891 34.6668C19.182 34.6668 14.7888 30.2861 14.7888 24.8666C14.7888 19.4596 19.1695 15.0664 24.5891 15.0664Z"
                fill="#312783"/>
            <path
                d="M34.3269 25.8555C30.0462 22.4386 24.5641 20.4735 18.6688 20.6738C17.63 20.7113 16.5911 20.824 15.5898 20.9867C15.377 21.4748 15.2143 21.9755 15.0766 22.5011C16.2532 22.2633 17.4673 22.1131 18.7189 22.0756C24.5516 21.8753 29.9336 23.9155 34.039 27.42C34.1767 26.9194 34.2768 26.3937 34.3269 25.8555Z"
                fill="white"/>
            <path
                d="M16.0653 21.6104L16.178 21.8357L16.3782 21.8857L16.5535 21.7731L16.591 21.5102L16.4909 21.2724L16.2906 21.2224L16.1154 21.335L16.0653 21.6104ZM15.8651 21.6354L15.8776 21.3851L15.9402 21.2474L16.0528 21.1473L16.2531 21.0847L16.6035 21.1723L16.7788 21.5102L16.7037 21.8857L16.3908 22.061L16.0403 21.9733L15.8651 21.6354Z"
                fill="#009545"/>
            <path
                d="M17.3671 21.3726L17.5047 21.3601L17.68 21.3351L17.73 21.285L17.7426 21.2099L17.7175 21.1348L17.6424 21.1098L17.5047 21.1223L17.3545 21.1348L17.3671 21.3726ZM17.2169 21.9358L17.1292 20.9846L17.5298 20.9471H17.7551L17.8677 21.0221L17.9178 21.1723L17.8677 21.3476L17.68 21.4477L17.7926 21.5228L17.9178 21.673L18.0555 21.8482L17.8302 21.8733L17.6675 21.673L17.5548 21.5478L17.4922 21.5103H17.3545L17.3921 21.9108L17.2169 21.9358Z"
                fill="#009545"/>
            <path
                d="M18.5811 21.0347L18.5937 21.673H18.7439L18.8565 21.6605L18.9316 21.623L18.9817 21.5228L18.9942 21.3476L18.9692 21.1724L18.9066 21.0848L18.819 21.0472H18.6563L18.5811 21.0347ZM18.3809 20.8845L18.7314 20.872L18.9191 20.8845L19.0693 20.9721L19.1694 21.1223L19.207 21.3476L19.1819 21.5479L19.0818 21.7231L18.9441 21.7982L18.7689 21.8232L18.4059 21.8357L18.3809 20.8845Z"
                fill="#009545"/>
            <path
                d="M19.5824 21.8108L19.5949 20.8595H20.3084V21.0222H19.7827V21.235H20.2583V21.3977H19.7827L19.7702 21.6606H20.3084V21.8233L19.5824 21.8108Z"
                fill="#009545"/>
            <path
                d="M20.6964 21.8357L20.759 20.8845L21.0469 20.897L21.172 21.5604L21.3973 20.922L21.6852 20.9471L21.6101 21.8983L21.4349 21.8858L21.485 21.1348L21.2471 21.8733L21.0594 21.8607L20.9217 21.0972L20.8717 21.8482L20.6964 21.8357Z"
                fill="#009545"/>
            <path
                d="M22.6114 22.0109L22.7491 21.0722L23.45 21.1723L23.425 21.3351L22.9118 21.26L22.8868 21.4727L23.3624 21.5353L23.3373 21.698L22.8617 21.6229L22.8242 21.8858L23.3499 21.9609L23.3248 22.1236L22.6114 22.0109Z"
                fill="#009545"/>
            <path
                d="M24.6391 21.5729L24.5765 21.8357L24.6766 21.8608L24.8268 21.8858L24.9019 21.8483L24.9395 21.7857L24.927 21.6981L24.8644 21.6355L24.7392 21.5979L24.6391 21.5729ZM24.2636 22.3114L24.4764 21.3727L24.7768 21.4477L25.0021 21.5103L25.1147 21.6355L25.1272 21.8357L25.0646 21.9734L24.9645 22.0485L24.8519 22.0736L24.6516 22.036L24.5264 22.011L24.4513 22.3614L24.2636 22.3114Z"
                fill="#009545"/>
            <path
                d="M25.6904 22.086L25.8281 22.1236L26.0033 22.1611L26.0659 22.1361L26.1035 22.0735V21.9984L26.0409 21.9483L25.7655 21.8733L25.6904 22.086ZM25.3525 22.5616L25.6153 21.6354L26.0033 21.7481L26.2161 21.8357L26.3037 21.9483V22.1111L26.1911 22.2613L25.9783 22.2863L26.0534 22.3864L26.116 22.5616L26.1786 22.7744L25.9533 22.7118L25.8782 22.474L25.8156 22.3113L25.7655 22.2613L25.6779 22.2237L25.6403 22.2112L25.5277 22.5992L25.3525 22.5616Z"
                fill="#009545"/>
            <path
                d="M26.7919 22.499L26.7793 22.7619L26.9295 22.8995L27.1298 22.8745L27.28 22.6617L27.2925 22.3989L27.1423 22.2612L26.9295 22.2737L26.7919 22.499ZM26.6041 22.4364L26.7293 22.2237L26.8544 22.1235L27.0046 22.086L27.2174 22.1235L27.4928 22.3613V22.7493L27.255 23.0497L26.892 23.0623L26.6166 22.8244V22.4364H26.6041Z"
                fill="#009545"/>
            <path
                d="M28.2187 23.175L28.2813 23.0248L28.6693 23.175L28.5191 23.5255L28.3189 23.563L28.081 23.513L27.8683 23.3503L27.7806 23.125L27.8307 22.8621L27.9809 22.6493L28.2187 22.5492L28.4565 22.5993L28.6818 22.7745L28.7194 23.0123L28.5316 22.9748L28.5066 22.8371L28.394 22.7495L28.1687 22.737L28.0059 22.9372L27.9809 23.2001L28.1311 23.3503L28.2563 23.3753L28.3814 23.3628L28.419 23.2501L28.2187 23.175Z"
                fill="#009545"/>
            <path
                d="M29.2451 23.4002L29.3703 23.4628L29.533 23.5254L29.5956 23.5129L29.6456 23.4628L29.6582 23.3877L29.6081 23.3251L29.4829 23.2626L29.3452 23.2L29.2451 23.4002ZM28.8321 23.8133L29.2451 22.9496L29.6081 23.1249L29.7958 23.25L29.8584 23.3752L29.8334 23.5254L29.7082 23.6631H29.4955L29.558 23.7757L29.5956 23.9635L29.6206 24.1888L29.4078 24.0886L29.3703 23.8383L29.3452 23.6631L29.3077 23.6005L29.2201 23.5504L29.1825 23.5379L29.0073 23.9009L28.8321 23.8133Z"
                fill="#009545"/>
            <path
                d="M29.896 24.3265L30.3466 23.4879L30.9724 23.8259L30.8973 23.9761L30.4342 23.7257L30.334 23.9135L30.7596 24.1388L30.6845 24.289L30.2589 24.0512L30.1463 24.289L30.6094 24.5393L30.5343 24.677L29.896 24.3265Z"
                fill="#009545"/>
            <path
                d="M30.9849 24.5768L31.1476 24.6518L31.1351 24.8146L31.2352 24.9272L31.3729 24.9648L31.4605 24.9147L31.473 24.8521L31.4355 24.777L31.3228 24.6644L31.1852 24.4641L31.2102 24.2638L31.3228 24.1637L31.473 24.1387L31.6483 24.2138L31.836 24.414L31.811 24.6518L31.6483 24.5517L31.6608 24.4391L31.5732 24.3389L31.4355 24.3014L31.3854 24.3389L31.3729 24.4015L31.4981 24.5517L31.6358 24.7144L31.6733 24.8646L31.6358 25.0023L31.5106 25.115L31.3479 25.14L31.1476 25.0649L30.9599 24.8521L30.9849 24.5768Z"
                fill="#009545"/>
            <path
                d="M31.9361 25.1651L32.1114 25.2652L32.0863 25.4154L32.174 25.528L32.3116 25.5781L32.4118 25.528L32.4243 25.4654L32.3867 25.3904L32.2866 25.2652L32.1489 25.0649L32.1865 24.8647L32.2991 24.7645L32.4618 24.752L32.6245 24.8396L32.8123 25.0524L32.7747 25.2777L32.612 25.1776L32.6245 25.0649L32.5369 24.9648L32.4118 24.9273L32.3492 24.9523L32.3367 25.0149L32.4493 25.1651L32.587 25.3403L32.6245 25.4905L32.562 25.6282L32.4368 25.7283L32.2741 25.7533L32.0863 25.6657L31.8986 25.4404L31.9361 25.1651Z"
                fill="#009545"/>
            <path
                d="M33.1377 25.7908L33.0501 26.0411L33.1502 26.2163L33.3504 26.2664L33.5632 26.1162L33.6508 25.8784L33.5507 25.7032L33.3504 25.6531L33.1377 25.7908ZM32.975 25.6781L33.1627 25.5154L33.3129 25.4654H33.4631L33.6508 25.5655L33.8386 25.8784L33.7134 26.2414L33.4005 26.4541L33.0501 26.354L32.8623 26.0411L32.975 25.6781Z"
                fill="#009545"/>
            <path
                d="M27.6931 21.1473L27.7932 21.4226H28.0811L27.8433 21.5978L27.9309 21.8732L27.6931 21.698L27.4553 21.8732L27.5554 21.5978L27.3176 21.4226H27.6055L27.6931 21.1473Z"
                fill="white"/>
            <path
                d="M24.6266 29.0577L24.7142 29.333H25.002L24.7767 29.5082L24.8644 29.7836L24.6266 29.6084L24.3887 29.7836L24.4764 29.5082L24.2511 29.333H24.5389L24.6266 29.0577Z"
                fill="white"/>
            <path
                d="M31.5731 28.0813L31.6733 28.3692H31.9611L31.7233 28.5444L31.811 28.8198L31.5731 28.6445L31.3353 28.8198L31.4229 28.5444L31.1851 28.3692H31.4855L31.5731 28.0813Z"
                fill="white"/>
            <path
                d="M32.95 28.3191L33.0125 28.4944H33.2003L33.0376 28.607L33.1001 28.7948L32.95 28.6821L32.7998 28.7948L32.8498 28.607L32.6996 28.4944H32.8874L32.95 28.3191Z"
                fill="white"/>
            <path
                d="M31.9737 29.02L32.0488 29.2703H32.3116L32.0988 29.4205L32.1865 29.6583L31.9737 29.5081L31.7609 29.6583L31.8485 29.4205L31.6357 29.2703H31.8986L31.9737 29.02Z"
                fill="white"/>
            <path
                d="M31.3604 29.8336L31.4354 30.0714H31.6858L31.4855 30.2216L31.5731 30.4594L31.3604 30.3092L31.1601 30.4594L31.2352 30.2216L31.0349 30.0714H31.2852L31.3604 29.8336Z"
                fill="white"/>
            <path
                d="M30.5718 30.4093L30.6344 30.597H30.8347L30.672 30.7097L30.7346 30.8974L30.5718 30.7847L30.4217 30.8974L30.4842 30.7097L30.3215 30.597H30.5218L30.5718 30.4093Z"
                fill="white"/>
            <path
                d="M29.5455 30.2215L29.6081 30.3967H29.7958L29.6456 30.5094L29.7082 30.6971L29.5455 30.5845L29.3953 30.6971L29.4453 30.5094L29.2951 30.3967H29.4954L29.5455 30.2215Z"
                fill="white"/>
            <path
                d="M28.4315 30.5471L28.5066 30.7474H28.7069L28.5442 30.8726L28.6067 31.0728L28.4315 30.9477L28.2688 31.0728L28.3314 30.8726L28.1687 30.7474H28.3689L28.4315 30.5471Z"
                fill="white"/>
            <path
                d="M29.5705 32.1742L29.6206 32.362H29.8208L29.6581 32.4746L29.7207 32.6624L29.5705 32.5372L29.4078 32.6624L29.4704 32.4746L29.3077 32.362H29.5079L29.5705 32.1742Z"
                fill="white"/>
            <path
                d="M27.5679 31.323L27.643 31.5734H27.9183L27.693 31.7236L27.7806 31.9739L27.5679 31.8237L27.3551 31.9739L27.4302 31.7236L27.2174 31.5734H27.4803L27.5679 31.323Z"
                fill="white"/>
            <path
                d="M26.8044 30.2968L26.867 30.4846H27.0672L26.9045 30.6097L26.9671 30.7975L26.8044 30.6848L26.6417 30.7975L26.6917 30.6097L26.5415 30.4846H26.7418L26.8044 30.2968Z"
                fill="white"/>
            <path
                d="M29.5956 31.0977L29.6832 31.348H29.9335L29.7207 31.4982L29.8083 31.736L29.5956 31.5858L29.3953 31.736L29.4704 31.4982L29.2576 31.348H29.5205L29.5956 31.0977Z"
                fill="white"/>
            <path
                d="M24.614 32.6749L24.6391 32.7625H24.7392L24.6641 32.8126L24.6891 32.9002L24.614 32.8501L24.5389 32.9002L24.564 32.8126L24.4889 32.7625H24.589L24.614 32.6749Z"
                fill="white"/>
            <path
                d="M27.2174 24.1262L27.28 24.3265H27.4928L27.3176 24.4516L27.3802 24.6519L27.2174 24.5267L27.0422 24.6519L27.1048 24.4516L26.9421 24.3265H27.1423L27.2174 24.1262Z"
                fill="white"/>
            <path
                d="M24.6141 26.2916L24.6892 26.5044H24.9145L24.7267 26.6296L24.8018 26.8423L24.6141 26.7047L24.4388 26.8423L24.5139 26.6296L24.3262 26.5044H24.5515L24.6141 26.2916Z"
                fill="white"/>
            <path
                d="M25.7656 27.1802L25.8407 27.4055H26.0785L25.8907 27.5432L25.9658 27.7685L25.7656 27.6308L25.5778 27.7685L25.6529 27.5432L25.4526 27.4055H25.703L25.7656 27.1802Z"
                fill="white"/>
            <path
                d="M23.6252 27.2052L23.6878 27.3804H23.8756L23.7129 27.493L23.7754 27.6808L23.6252 27.5681L23.4751 27.6808L23.5251 27.493L23.3749 27.3804H23.5627L23.6252 27.2052Z"
                fill="white"/>
            <path
                d="M24.1009 28.0187L24.1384 28.1314H24.2636L24.1635 28.2065L24.201 28.3316L24.1009 28.2565L24.0007 28.3316L24.0383 28.2065L23.9382 28.1314H24.0633L24.1009 28.0187Z"
                fill="white"/>
            <path
                d="M16.4909 22.8497L16.5785 23.1375H16.8789L16.6411 23.3003L16.7287 23.5756L16.4909 23.4004L16.2531 23.5756L16.3532 23.3003L16.1154 23.1375H16.4033L16.4909 22.8497Z"
                fill="white"/>
            <path
                d="M20.7715 25.0774L20.8466 25.3027H21.0844L20.8966 25.4404L20.9717 25.6782L20.7715 25.528L20.5837 25.6782L20.6588 25.4404L20.4586 25.3027H20.7089L20.7715 25.0774Z"
                fill="white"/>
            <path
                d="M17.3796 27.0174L17.4672 27.2928H17.7551L17.5298 27.468L17.6174 27.7434L17.3796 27.5681L17.1418 27.7434L17.2294 27.468L17.0041 27.2928H17.292L17.3796 27.0174Z"
                fill="white"/>
            <path
                d="M18.3433 26.6045L18.3808 26.7422H18.531L18.4184 26.8298L18.4559 26.9675L18.3433 26.8799L18.2306 26.9675L18.2682 26.8298L18.1556 26.7422H18.2932L18.3433 26.6045Z"
                fill="white"/>
            <path
                d="M19.4573 28.0564L19.5324 28.2942H19.7827L19.5825 28.4318L19.6576 28.6696L19.4573 28.532L19.257 28.6696L19.3321 28.4318L19.1319 28.2942H19.3822L19.4573 28.0564Z"
                fill="white"/>
            <path
                d="M20.6213 29.8712L20.7089 30.1591H21.0093L20.7715 30.3218L20.8591 30.5971L20.6213 30.4344L20.3835 30.5971L20.4836 30.3218L20.2458 30.1591H20.5337L20.6213 29.8712Z"
                fill="white"/>
            <path
                d="M19.1319 29.0452L19.207 29.283H19.4573L19.257 29.4207L19.3321 29.6585L19.1319 29.5208L18.9316 29.6585L19.0067 29.4207L18.8064 29.283H19.0568L19.1319 29.0452Z"
                fill="white"/>
            <path
                d="M16.1529 28.0187L16.2155 28.2065H16.4033L16.2531 28.3191L16.3031 28.4943L16.1529 28.3817L15.9902 28.4943L16.0528 28.3191L15.9026 28.2065H16.0903L16.1529 28.0187Z"
                fill="white"/>
            <path
                d="M25.6153 21.6479L25.3525 22.5741L25.5402 22.6242L25.6529 22.2362L25.6904 22.2487L25.778 22.2862L25.8281 22.3363L25.8907 22.499L25.9658 22.7368L26.1911 22.7994L26.1285 22.5866L26.0659 22.4114L25.9908 22.3113L26.2036 22.2862L26.3162 22.136V21.9733L26.2286 21.8607L26.0158 21.7731L25.6153 21.6479ZM26.0033 22.1611L25.8281 22.1236L25.6904 22.086L25.7655 21.8482L26.0409 21.9233L26.1035 21.9733V22.0484L26.0659 22.111L26.0033 22.1611Z"
                fill="#068037"/>
            <path
                d="M26.9921 22.086L26.8419 22.1235L26.7168 22.2237L26.5916 22.4364V22.8244L26.867 23.0623L27.2299 23.0497L27.4677 22.7493V22.3613L27.1924 22.1235L26.9921 22.086ZM26.9295 22.8995L26.7793 22.7619L26.7919 22.499L26.9421 22.2862L27.1548 22.2737L27.3175 22.4114L27.305 22.6743L27.1548 22.887L26.9295 22.8995Z"
                fill="#068037"/>
            <path
                d="M28.2187 22.5492L27.9809 22.6493L27.8307 22.8621L27.7806 23.125L27.8683 23.3503L28.081 23.513L28.3189 23.563L28.5191 23.5255L28.6693 23.175L28.2813 23.0248L28.2187 23.175L28.419 23.2501L28.3814 23.3628L28.2563 23.3753L28.1311 23.3503L27.9809 23.2001L28.0059 22.9372L28.1687 22.737L28.394 22.7495L28.5066 22.8371L28.5316 22.9748L28.7194 23.0123L28.6818 22.7745L28.4565 22.5993L28.2187 22.5492Z"
                fill="#068037"/>
            <path
                d="M29.2451 22.9496L28.8321 23.8133L29.0073 23.9009L29.1825 23.5379L29.2201 23.5504L29.3077 23.6005L29.3452 23.6631L29.3703 23.8383L29.4078 24.0886L29.6206 24.1888L29.5956 23.9635L29.558 23.7757L29.4955 23.6631H29.7082L29.8334 23.5254L29.8584 23.3752L29.7958 23.25L29.6081 23.1249L29.2451 22.9496ZM29.533 23.5254L29.3703 23.4628L29.2451 23.4002L29.3452 23.1875L29.4829 23.25L29.6081 23.3126L29.6582 23.3752L29.6456 23.4503L29.5956 23.5004L29.533 23.5254Z"
                fill="#068037"/>
            <path
                d="M30.3466 23.4879L29.896 24.3265L30.5343 24.677L30.6094 24.5393L30.1463 24.289L30.2589 24.0512L30.6845 24.289L30.7596 24.1388L30.334 23.9135L30.4342 23.7257L30.8973 23.9761L30.9724 23.8259L30.3466 23.4879Z"
                fill="#068037"/>
            <path
                d="M31.473 24.1387L31.3228 24.1637L31.2102 24.2638L31.1852 24.4641L31.3228 24.6644L31.4355 24.777L31.473 24.8521L31.4605 24.9147L31.3729 24.9648L31.2352 24.9272L31.1351 24.8146L31.1476 24.6518L30.9849 24.5768L30.9599 24.8521L31.1476 25.0649L31.3479 25.14L31.5106 25.115L31.6358 25.0023L31.6733 24.8646L31.6358 24.7144L31.4981 24.5517L31.3729 24.4015L31.3854 24.3389L31.4355 24.3014L31.5732 24.3389L31.6608 24.4391L31.6483 24.5517L31.811 24.6518L31.836 24.414L31.6483 24.2138L31.473 24.1387Z"
                fill="#068037"/>
            <path
                d="M32.4618 24.752L32.2991 24.7645L32.1865 24.8647L32.1489 25.0649L32.2866 25.2652L32.3867 25.3904L32.4243 25.4654L32.4118 25.528L32.3116 25.5781L32.174 25.528L32.0863 25.4154L32.1114 25.2652L31.9361 25.1651L31.8986 25.4404L32.0863 25.6657L32.2741 25.7533L32.4368 25.7283L32.562 25.6282L32.6245 25.4905L32.587 25.3403L32.4493 25.1651L32.3367 25.0149L32.3492 24.9523L32.4118 24.9273L32.5369 24.9648L32.6245 25.0649L32.612 25.1776L32.7747 25.2777L32.8123 25.0524L32.6245 24.8396L32.4618 24.752Z"
                fill="#068037"/>
            <path
                d="M33.3004 25.4654L33.1502 25.5154L32.9624 25.6781L32.8498 26.0411L33.0375 26.354L33.388 26.4541L33.7009 26.2414L33.8261 25.8784L33.6383 25.5655L33.4506 25.4654H33.3004ZM33.3504 26.2664L33.1502 26.2163L33.0501 26.0411L33.1377 25.7908L33.3504 25.6406L33.5507 25.6907L33.6508 25.8659L33.5632 26.1037L33.3504 26.2664Z"
                fill="#068037"/>
        </svg>);
}