﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function KubernetesAdmissionControllerRiskPoliciesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M12.3792 7.4939L12.3983 7.55264L12.4601 7.55264L12.4101 7.58895L12.4292 7.6477L12.3792 7.61139L12.3292 7.6477L12.3483 7.58895L12.2984 7.55264L12.3601 7.55264L12.3792 7.4939Z"/>
            <path clipRule="evenodd" d="M17 8L24 10.9697V15.4499C24 17.1499 23.4763 18.7857 22.595 20.1698C22.5658 20.2242 22.5346 20.2727 22.5097 20.3101C22.4439 20.4091 22.3305 20.566 22.1265 20.8317C21.9297 21.0879 21.8107 21.2341 21.7339 21.3217C21.7026 21.3574 21.6654 21.3983 21.624 21.4379C20.4712 22.706 18.9572 23.658 17.2264 24.0603L16.9997 24.113L16.773 24.0602C12.8689 23.1502 10 19.4347 10 15.4498V10.9697L17 8ZM20.7774 19.2978L20.8803 19.1387C21.591 18.0398 22 16.7564 22 15.4499V12.2938L17 10.1725L12 12.2938V15.4499C12 18.45 14.1375 21.2709 17.0003 22.0549C18.2244 21.7205 19.3192 21.0122 20.1756 20.0576L20.3048 19.9137C20.3554 19.8513 20.4308 19.756 20.5402 19.6136C20.6544 19.4648 20.7293 19.3641 20.7774 19.2978Z"/>
            <path clipRule="evenodd" d="M11.0004 2.01979L3.00045 6.63859L2.98047 17.2885L9.1165 20.4673C9.60688 20.7214 9.79848 21.3248 9.54443 21.8152C9.29038 22.3056 8.6869 22.4972 8.19651 22.2432L1.93626 19L1.91651 18.9886C1.33729 18.6542 0.980469 18.0362 0.980469 17.3673L1.00045 6.56474C1.00045 5.89591 1.35726 5.27788 1.93649 4.94347L10.0644 0.250811C10.6436 -0.0836037 11.3573 -0.0836039 11.9365 0.250811L21 4.94347C21.5792 5.27788 21.936 5.8959 21.936 6.56474V7C21.936 7.55228 21.4883 8 20.936 8C20.3838 8 19.936 7.55228 19.936 7V6.63859L11.0004 2.01979Z"/>
        </SvgIcon>);
}