import { Box, SxProps, Typography } from "@mui/material";
import React from "react";

export type LinkButtonProps = {
    onClick: () => void;
    sx?: SxProps;
    title: string;
};

export function LinkButton({ onClick, sx, title }: LinkButtonProps) {
    return (
        <Box
            sx={{
                cursor: "pointer",
                ...sx
            }}
            onClick={onClick}>
            <Typography
                component="span"
                sx={{ textDecoration: "underline" }}
                onClick={onClick}>
                {title}
            </Typography>
        </Box>);
}