import { Action0, Action1, EventHandlerRegister } from "@infrastructure";
import _ from "lodash";
import React, { memo, useMemo } from "react";
import { Contract, riskPolicyModelStore, RiskPolicyTypeMetadataHelper, scopeNodeModelStore, tenantModelStore, useRiskPolicyTranslator, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { Edit, View } from "./components";

const RiskPolicyTableMemo = memo(RiskPolicyTable);
export { RiskPolicyTableMemo as RiskPolicyTable };

export type RiskPolicyTableProps = {
    onSelectChange: Action1<string[]>;
    selectedRiskPoliciesIds: string[];
} & ({
    registerClearSelected: EventHandlerRegister<Action0>;
    riskPolicyIds: string[];
    variant: "view";
} | {
    registerClearSelected?: never;
    riskPolicyIds?: never;
    variant: "edit";
});

function RiskPolicyTable({ onSelectChange, registerClearSelected, riskPolicyIds, selectedRiskPoliciesIds, variant = "view" }: RiskPolicyTableProps) {
    const filteredActiveTenantTypes = tenantModelStore.useGetFilteredActiveTenantTypes();
    const customRiskPolicyModels = riskPolicyModelStore.useGetLicensedCustom();
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const { scopeNodeModel } = useScopeNavigationViewContext();

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const items =
        useMemo(
            () => {
                const builtInRiskPolicyCategoryToItemsMap =
                    _(RiskPolicyTypeMetadataHelper.getLicensedRiskPolicyConfigurationTypeNameToMetadataMap()).
                        map(
                            (metadata, configurationTypeName) => ({
                                ...metadata,
                                configurationTypeName
                            })).
                        filter(
                            riskPolicyTypeData =>
                                _(RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyTypeData.configurationTypeName)).
                                    intersection(filteredActiveTenantTypes).
                                    some() &&
                                riskPolicyTypeData.category !== Contract.RiskPolicyCategory.Custom).
                        map(
                            riskPolicyTypeData => ({
                                category: riskPolicyTypeData.category,
                                description: riskPolicyTranslator(riskPolicyTypeData.configurationTypeName).description!,
                                id: riskPolicyTypeData.configurationTypeName,
                                name: riskPolicyTranslator(riskPolicyTypeData.configurationTypeName).title,
                                severity: riskPolicyTypeData.severity!,
                                tenantTypes:
                                    _.intersection(
                                        RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyTypeData.configurationTypeName),
                                        filteredActiveTenantTypes)
                            })).
                        value();

                const parentScopeIds = scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds;
                const customRiskPolicyTypeItems =
                    _(customRiskPolicyModels).
                        filter(
                            riskPolicyModel =>
                                (riskPolicyModel.riskPolicyConfiguration.scopeId === scopeNodeModel.configuration.id || _.includes(parentScopeIds, riskPolicyModel.riskPolicyConfiguration.scopeId)) &&
                                _(RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyModel.riskPolicyConfiguration.typeName)).
                                    intersection(filteredActiveTenantTypes).
                                    some()).
                        map(
                            riskPolicy => ({
                                category: riskPolicy.riskPolicyCategory,
                                description: (riskPolicy.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).description ?? "",
                                id: riskPolicy.riskPolicyConfiguration.id,
                                name: (riskPolicy.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).name,
                                severity: (riskPolicy.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).severity,
                                tenantTypes:
                                    _.intersection(
                                        RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicy.riskPolicyConfiguration.typeName),
                                        filteredActiveTenantTypes)
                            })).
                        value();

                return [...builtInRiskPolicyCategoryToItemsMap, ...customRiskPolicyTypeItems];
            },
            [filteredActiveTenantTypes, customRiskPolicyModels, scopeNodeModel.configuration.id, scopeNodeMap]);

    return (
        variant === "edit"
            ? <Edit
                items={items}
                selectedRiskPoliciesIds={selectedRiskPoliciesIds}
                onSelectChange={onSelectChange}/>
            : <View
                items={items}
                registerClearSelected={registerClearSelected}
                riskPolicyIds={riskPolicyIds}
                selectedRiskPoliciesIds={selectedRiskPoliciesIds}
                onSelectChange={onSelectChange}/>);
}

export enum ItemTableColumnId {
    Category = "category",
    CustomRiskPolicy = "customRiskPolicy",
    Policy = "policy",
    Selected = "selected",
    Severity = "severity",
    TenantTypes = "tenantTypes"
}

export type RiskPolicySelectItem = {
    category: Contract.RiskPolicyCategory;
    description: string;
    id: string;
    name: string;
    severity: Contract.Severity;
    tenantTypes: Contract.TenantType[];
};

export type RiskPolicyTableVariant = "edit" | "view";