import { Loading, UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../..";
import { Contract, entityModelStore, InlineEntities, InlineResourceTags } from "../../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { InstanceTable } from "../../components";
import { ContextSection } from "./components";
import { useDatabricksInstanceGroupRemediationSteps, useSpotInstanceGroupRemediationSteps } from "./hooks";

export function useAwsEc2InstanceUserDataSecretExistsRiskTagResourceGroupDefinition(riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel) {
    const tagResourceGroupModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsTagResourceGroupModel;
    const tagResourceGroup = (tagResourceGroupModel.entity) as Contract.AwsTagResourceGroup;
    const useRemediationSteps =
        useMemo(
            () => {
                switch (tagResourceGroupModel.entity.typeName) {
                    case Contract.TypeNames.AwsEc2DatabricksInstanceGroup:
                        return useDatabricksInstanceGroupRemediationSteps;
                    case Contract.TypeNames.AwsEc2SpotInstanceGroup:
                        return useSpotInstanceGroupRemediationSteps;
                    default:
                        throw new UnexpectedError("tagResourceGroupDefinitionConfiguration.typeName", tagResourceGroupModel.entity.typeName);
                }
            },
            [riskModel, tagResourceGroupModel]);
    const remediationSteps = useRemediationSteps(riskModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskTagResourceGroupDefinition",
            () => ({
                description: [
                    "{{instances}} grouped by {{tags}} is exposing secrets in clear text via user data",
                    "{{instances}} grouped by {{tags}} are exposing secrets in clear text via user data"
                ],
                sections: {
                    details: "EC2 Instances"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            _.size(riskModel.risk.aggregatedEntityIds),
            {
                instances:
                    <InlineEntities
                        entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        variant="itemCountAndType"/>,
                tags:
                    <InlineResourceTags
                        tags={tagResourceGroup.groupTags}
                        textVariant="text"
                        variant="itemCountAndType"/>
            }),
        () => remediationSteps,
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    riskModel={riskModel}
                    tagResourceGroupModel={tagResourceGroupModel}/>,
            sections:
                _<RiskDefinitionSection>([]).
                    concatIf(
                        !_.isEmpty(riskModel.risk.aggregatedEntityIds),
                        () =>
                            new RiskDefinitionSection(
                                <Loading>
                                    <InstanceTable
                                        instanceIds={riskModel.risk.aggregatedEntityIds}
                                        riskModel={riskModel}/>
                                </Loading>,
                                localization.sections.details()
                            )).
                    value()
        });
}