import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingServiceGatewayStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingServiceGatewayStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingServiceGatewayStatus]: {
                    [Contract.OciNetworkingServiceGatewayStatus.Available]: "Available",
                    [Contract.OciNetworkingServiceGatewayStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingServiceGatewayStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingServiceGatewayStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciNetworkingServiceGatewayStatus) =>
        localization[Contract.TypeNames.OciNetworkingServiceGatewayStatus][status]();
}