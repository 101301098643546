import { ActionButton, CheckboxField, DeleteIcon, Dialog, ItemSelector, Message, useChangeEffect, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Divider, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AdministrationController, Contract, LicensingHelper, useTheme } from "../../../../../../common";
import { Core as LicensingCore } from "../../../Configuration/components";
import { IrisWarning } from "../IrisWarning";
import { License } from "./components";

const defaultEvaluationLicense =
    new Contract.ApplicationCustomerConfigurationLicensingEvaluationLicense(
        undefined,
        []);

const defaultSubscriptionLicense =
    new Contract.ApplicationCustomerConfigurationLicensingSubscriptionLicense(
        undefined,
        [],
        "P90D",
        undefined);

export function Licensing() {
    const [{ licensing: initialLicensing }] =
        useExecuteOperation(
            Licensing,
            AdministrationController.getLicensingConfiguration);

    const [evaluationLicenseDisabled, setEvaluationLicenseDisabled] = useState(_.isNil(initialLicensing.evaluationLicense));
    const [licensing, setLicensing] = useState(initialLicensing);
    const [subscriptionLicenseDisabled, setSubscriptionLicenseDisabled] = useState(_.isNil(initialLicensing.subscriptionLicense));

    const [updateLicensingError, setUpdateLicensingError] = useState(false);
    const [updateLicensingExecuting, setUpdateLicensingExecuting] = useState(false);
    const [updateLicensingSuccess, setUpdateLicensingSuccess] = useState(false);

    const [licenseTypeChanged, setLicenseTypeChanged] = useState(false);

    useChangeEffect(
        () => setLicenseTypeChanged(true),
        [evaluationLicenseDisabled, licensing, subscriptionLicenseDisabled]);

    async function update() {
        setUpdateLicensingError(false);
        setUpdateLicensingExecuting(true);
        setUpdateLicensingSuccess(false);

        try {
            await AdministrationController.updateLicensing(
                new Contract.AdministrationControllerUpdateLicensingRequest(({
                    ...licensing,
                    evaluationLicense:
                        evaluationLicenseDisabled
                            ? undefined
                            : licensing.evaluationLicense,
                    subscriptionLicense:
                        subscriptionLicenseDisabled
                            ? undefined
                            : licensing.subscriptionLicense
                })));
            setUpdateLicensingSuccess(true);
        } catch {
            setUpdateLicensingError(true);
        }

        setUpdateLicensingExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.administration.licensing",
            () => ({
                actions: {
                    preview: "Preview",
                    update: {
                        error: "Failed to update licensing",
                        success: "Success",
                        text: "Update"
                    }
                },
                evaluationLicense: {
                    active: "Trial"
                },
                subscriptionLicense: {
                    active: "Paid subscription",
                    resourceCount: "Resource count",
                    resourceCountType: "Resource count type"
                },
                [Contract.TypeNames.ApplicationCustomerConfigurationLicensingLicenseVersion]: {
                    [Contract.ApplicationCustomerConfigurationLicensingLicenseVersion.Version1]: "Legacy",
                    [Contract.ApplicationCustomerConfigurationLicensingLicenseVersion.Version2]: "CNAPP"
                }
            }));

    const [openPreview, setOpenPreview] = useState(false);
    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{ padding: theme.spacing(3, 2) }}>
            <IrisWarning/>
            <Stack
                spacing={2}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(2)
                }}>
                <CheckboxField
                    checked={!evaluationLicenseDisabled}
                    onChange={() => setEvaluationLicenseDisabled(!evaluationLicenseDisabled)}>
                    <Typography variant="h2">
                        {localization.evaluationLicense.active()}
                    </Typography>
                </CheckboxField>
                <Divider/>
                <License
                    disabled={evaluationLicenseDisabled}
                    license={licensing.evaluationLicense ?? defaultEvaluationLicense}
                    variant="evaluation"
                    onLicenseChanged={
                        evaluationLicense =>
                            setLicensing(
                                licensing => ({
                                    ...licensing,
                                    evaluationLicense
                                }))}/>
            </Stack>
            <Stack
                spacing={2}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(2)
                }}>
                <CheckboxField
                    checked={!subscriptionLicenseDisabled}
                    onChange={() => setSubscriptionLicenseDisabled(!subscriptionLicenseDisabled)}>
                    <Typography variant="h2">
                        {localization.subscriptionLicense.active()}
                    </Typography>
                </CheckboxField>
                <Divider/>
                <License
                    disabled={subscriptionLicenseDisabled}
                    license={licensing.subscriptionLicense ?? defaultSubscriptionLicense}
                    variant="subscription"
                    onLicenseChanged={
                        subscriptionLicense =>
                            setLicensing(
                                licensing => ({
                                    ...licensing,
                                    subscriptionLicense: subscriptionLicense as Contract.ApplicationCustomerConfigurationLicensingSubscriptionLicense
                                }))}/>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Typography
                        sx={{ width: theme.spacing(20) }}
                        variant="h5">
                        {localization.subscriptionLicense.resourceCountType()}
                    </Typography>
                    <ItemSelector
                        items={[
                            Contract.ApplicationCustomerConfigurationLicensingLicenseVersion.Version1,
                            Contract.ApplicationCustomerConfigurationLicensingLicenseVersion.Version2
                        ]}
                        selectedItem={licensing.licenseVersion}
                        onSelectedItemChanged={
                            licenseVersion =>
                                setLicensing(({
                                    ...licensing,
                                    licenseVersion
                                }))}>
                        {(licenseVersion: Contract.ApplicationCustomerConfigurationLicensingLicenseVersion) => localization[Contract.TypeNames.ApplicationCustomerConfigurationLicensingLicenseVersion][licenseVersion]()}
                    </ItemSelector>
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Typography
                        sx={{ width: theme.spacing(20) }}
                        variant="h5">
                        {localization.subscriptionLicense.resourceCount()}
                    </Typography>
                    <TextField
                        disabled={subscriptionLicenseDisabled}
                        sx={{ width: 200 }}
                        type="number"
                        value={licensing.subscriptionLicense?.objectCount ?? ""}
                        onChange={
                            event =>
                                setLicensing(
                                    licensing => ({
                                        ...licensing,
                                        subscriptionLicense: {
                                            ...licensing.subscriptionLicense ?? defaultSubscriptionLicense,
                                            objectCount: _.parseInt(event.target.value)
                                        }
                                    }))}/>
                    <ActionButton
                        disabled={subscriptionLicenseDisabled}
                        onClick={
                            () =>
                                setLicensing(
                                    licensing => ({
                                        ...licensing,
                                        subscriptionLicense: {
                                            ...licensing.subscriptionLicense ?? defaultSubscriptionLicense,
                                            objectCount: undefined
                                        }
                                    }))}>
                        <DeleteIcon/>
                    </ActionButton>
                </Stack>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}>
                {openPreview &&
                    <Dialog
                        size="large"
                        variant="viewer"
                        onClose={() => setOpenPreview(false)}>
                        <Box sx={{ padding: theme.spacing(4, 2) }}>
                            <LicensingPreview
                                evaluationLicenseDisabled={evaluationLicenseDisabled}
                                licensing={licensing}
                                subscriptionLicenseDisabled={subscriptionLicenseDisabled}/>
                        </Box>
                    </Dialog>}
                {updateLicensingExecuting && (
                    <CircularProgress
                        size={theme.spacing(2)}
                        variant="indeterminate"/>)}
                {updateLicensingError && (
                    <Message
                        level="error"
                        title={localization.actions.update.error()}/>)}
                {updateLicensingSuccess && (
                    <Message
                        level="success"
                        title={localization.actions.update.success()}/>
                )}
                <Button
                    variant="outlined"
                    onClick={() => setOpenPreview(true)}>
                    {localization.actions.preview()}
                </Button>
                <Button
                    disabled={!licenseTypeChanged}
                    onClick={update}>
                    {localization.actions.update.text()}
                </Button>
            </Stack>
        </Stack>);
}

type LicensingPreviewProps = {
    evaluationLicenseDisabled: boolean;
    licensing: Contract.ApplicationCustomerConfigurationLicensing;
    subscriptionLicenseDisabled: boolean;
};

function LicensingPreview({ evaluationLicenseDisabled, licensing, subscriptionLicenseDisabled }: LicensingPreviewProps) {
    const [{ dateToObjectCountMap, objectCountAverage, objectTypeToCountMap }] =
        useExecuteOperation(
            LicensingPreview,
            () => AdministrationController.getLicensingObjectCountAveragePreview(new Contract.AdministrationControllerGetLicensingObjectCountAveragePreviewRequest(licensing.licenseVersion)));

    const subscriptionLicenseTypes =
        subscriptionLicenseDisabled
            ? []
            : licensing.subscriptionLicense?.types ?? [];
    return (
        <LicensingCore
            activeEvaluationLicenseTypes={
                evaluationLicenseDisabled
                    ? []
                    : licensing.evaluationLicense?.types ?? []}
            activeSubscriptionLicenseTypes={subscriptionLicenseTypes}
            dateToObjectCountMap={dateToObjectCountMap}
            licenseShortName={LicensingHelper.getActiveLicenseShortName(subscriptionLicenseTypes)}
            licenseVersion={licensing.licenseVersion}
            objectCountAverage={objectCountAverage}
            objectTypeToCountMap={objectTypeToCountMap}
            subscriptionLicenseExpirationTime={
                subscriptionLicenseDisabled
                    ? undefined
                    : licensing.subscriptionLicense?.expirationTime}
            subscriptionLicenseObjectCount={
                subscriptionLicenseDisabled
                    ? undefined
                    : licensing.subscriptionLicense?.objectCount}/>);
}