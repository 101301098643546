import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, PasswordText, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, SecretExclusionActionCell } from "../../../common";

type GcpFunctionsFunctionEnvironmentVariableTableProps = {
    environmentVariables: Contract.GcpFunctionsFunctionEnvironmentVariable[];
    getHighlightColor?: (environmentVariable: Contract.GcpFunctionsFunctionEnvironmentVariable, opacity?: number) => Optional<string>;
    risk?: Contract.Risk;
    sx?: SxProps;
};

export function GcpFunctionsFunctionEnvironmentVariableTable({ environmentVariables, getHighlightColor, risk, sx }: GcpFunctionsFunctionEnvironmentVariableTableProps) {
    const localization =
        useLocalization(
            "tenants.gcp.gcpFunctionsFunctionEnvironmentVariableTable",
            () => ({
                columns: {
                    key: "Key",
                    type: {
                        title: "Type",
                        [Contract.TypeNames.GcpFunctionsFunctionEnvironmentVariableType]: {
                            [Contract.GcpFunctionsFunctionEnvironmentVariableType.Build]: "Build",
                            [Contract.GcpFunctionsFunctionEnvironmentVariableType.Runtime]: "Runtime"
                        }
                    },
                    value: "Value"
                },
                empty: "No Environment Variables"
            }));
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => environmentVariables}
            getItemId={(item: Contract.GcpFunctionsFunctionEnvironmentVariable) => `${item.type}-${item.key}`}
            rowOptions={{
                getHighlightColor: (item: Contract.GcpFunctionsFunctionEnvironmentVariable) => getHighlightColor?.(item),
                getSx:
                    (item: Contract.GcpFunctionsFunctionEnvironmentVariable) => ({
                        backgroundColor: getHighlightColor?.(item, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            sx={sx}
            variant="card">
            <DataTableColumn
                id={EnvironmentVariableColumnId.Key}
                itemProperty={(item: Contract.GcpFunctionsFunctionEnvironmentVariable) => item.key}
                key={EnvironmentVariableColumnId.Key}
                title={localization.columns.key()}/>
            <DataTableColumn
                id={EnvironmentVariableColumnId.Value}
                key={EnvironmentVariableColumnId.Value}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpFunctionsFunctionEnvironmentVariable>) =>
                        !_.isNil(getHighlightColor?.(item))
                            ? <PasswordText password={item.value}/>
                            : <Typography noWrap={true}>
                                {item.value}
                            </Typography>}
                title={localization.columns.value()}/>
            <DataTableColumn
                id={EnvironmentVariableColumnId.Type}
                itemProperty={
                    (item: Contract.GcpFunctionsFunctionEnvironmentVariable) =>
                        localization.columns.type[Contract.TypeNames.GcpFunctionsFunctionEnvironmentVariableType][item.type]()}
                key={EnvironmentVariableColumnId.Type}
                title={localization.columns.type.title()}/>
            {!_.isNil(getHighlightColor) &&
                !_.isNil(risk) &&
                <DataTableColumn
                    id={EnvironmentVariableColumnId.Actions}
                    key={EnvironmentVariableColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpFunctionsFunctionEnvironmentVariable>) =>
                            <SecretExclusionActionCell
                                displayNameToValueSecretDataMap={{
                                    [localization.columns.value()]: {
                                        secret: !_.isNil(getHighlightColor?.(item)),
                                        value: item.value
                                    }
                                }}
                                risk={risk}
                                secretKey={item.key}/>}/>}
        </DataTable>);
}

enum EnvironmentVariableColumnId {
    Actions = "actions",
    Key = "key",
    Type = "type",
    Value = "value"
}