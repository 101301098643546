import _ from "lodash";
import React from "react";
import { Contract, TenantsIcon, useTenantTypeTranslator, useTheme } from "../../../../../common";

type TenantTypeCellProps = {
    tenantTypes: Contract.TenantType[];
};

export function TenantTypeCell({ tenantTypes }: TenantTypeCellProps) {
    const tenantTypeTranslator = useTenantTypeTranslator();
    const theme = useTheme();
    return (
        <TenantsIcon
            sx={{
                fontSize: "18px",
                marginLeft: theme.px(5)
            }}
            tenantTypeToTitleMap={
                _.reduce(
                    tenantTypes,
                    (tenantTypeToTranslationMap, tenantType) => {
                        tenantTypeToTranslationMap.push({ [tenantType]: tenantTypeTranslator(tenantType) } as Record<Contract.TenantType, string>);
                        return tenantTypeToTranslationMap;
                    },
                    [] as Record<Contract.TenantType, string>[])}/>);
}