import _ from "lodash";
import React, { cloneElement, useMemo } from "react";
import { makeContextProvider } from "../../../utilities/makeContextProvider";
import { DirectedGraphLayoutNode, DirectedGraphModelNode } from "../utilities";

type NodeProps = {
    layoutNode: DirectedGraphLayoutNode;
};

class NodeContext {
    constructor(
        public modelNode: DirectedGraphModelNode) {
    }
}

export const [useNodeContext, , useNodeContextProvider] = makeContextProvider<NodeContext>();

export function Node({ layoutNode }: NodeProps) {
    const [, , ContextProvider] = useNodeContextProvider(() => new NodeContext(layoutNode.modelNode));
    return (
        <g
            style={{
                cursor:
                    !_.isNil(layoutNode.modelNode.options.onClick)
                        ? "pointer"
                        : undefined
            }}
            transform={`translate(${layoutNode.x},${layoutNode.y})`}
            onClick={layoutNode.modelNode.options.onClick}>
            <ContextProvider>
                {useMemo(
                    () => cloneElement(layoutNode.modelNode.contentElement as any),
                    [layoutNode.modelNode.options.appearance])}
            </ContextProvider>
        </g>);
}