import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, NumberFormatter, optionalTableCell, PagedValuesFilter, TimeSpanFormatter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAwsCommonCloudWatchLoggingResourceDefinition } from "./useAwsCommonCloudWatchLoggingResourceDefinition";

export function useAwsCommonRdsClusterResourceDefinition(
    clusterSnapshotTypeName: string,
    databaseInstanceTypeName: string,
    definitionData: DefinitionData,
    globalClusterTypeName: string) {
    const commonCloudWatchLoggingResourceDefinition = useAwsCommonCloudWatchLoggingResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonRdsClusterResourceDefinition",
            () => ({
                columns: {
                    backupRetentionTimeFrame: "Automatic Backup Retention Period",
                    deleteProtection: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Delete Protection"
                    },
                    engineVersion: "Engine Version",
                    status: "Status",
                    storageSize: "Size"
                }
            }));

    const translatedClusterSnapshotTypeName =
        entityTypeNameTranslator(
            clusterSnapshotTypeName,
            {
                count: 0,
                includeServiceName: true,
                variant: "title"
            });
    const translatedDatabaseInstanceTypeName =
        entityTypeNameTranslator(
            databaseInstanceTypeName,
            {
                count: 0,
                includeServiceName: true,
                variant: "title"
            });
    const translatedGlobalClusterTypeName =
        entityTypeNameTranslator(
            globalClusterTypeName,
            {
                includeServiceName: true,
                variant: "title"
            });

    return {
        columns: {
            ...commonCloudWatchLoggingResourceDefinition.columns,
            automaticBackupRetentionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsClusterResourceModel) => ({
                                [localization.columns.backupRetentionTimeFrame()]: TimeSpanFormatter.day((item.entity as Contract.AwsRdsClusterResource).backupRetentionTimeFrame)
                            })
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterResourceBackupRetentionTimeFrame}
                    itemProperty={(item: Contract.AwsRdsClusterResourceModel) => TimeSpanFormatter.day((item.entity as Contract.AwsRdsClusterResource).backupRetentionTimeFrame)}
                    key={Contract.EntityModelProperty.AwsRdsClusterResourceBackupRetentionTimeFrame}
                    selectorOptions={{ default: false }}
                    sortOptions={{
                        type: DataTableSortType.Numeric
                    }}
                    title={localization.columns.backupRetentionTimeFrame()}/>,
            deleteProtectionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsClusterResourceModel) => ({
                                [localization.columns.deleteProtection.title()]:
                                    (item.entity as Contract.AwsRdsClusterResource).deleteProtection
                                        ? localization.columns.deleteProtection.enabled()
                                        : localization.columns.deleteProtection.disabled()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.deleteProtection.title()}>
                                    <ValuesFilterItem
                                        key="false"
                                        title={localization.columns.deleteProtection.disabled()}
                                        value={false}/>
                                    <ValuesFilterItem
                                        key="true"
                                        title={localization.columns.deleteProtection.enabled()}
                                        value={true}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterResourceDeleteProtection}
                    key={Contract.EntityModelProperty.AwsRdsClusterResourceDeleteProtection}
                    render={
                        optionalTableCell<Contract.AwsRdsClusterResourceModel>(
                            item =>
                                _.isNil((item.entity as Contract.AwsRdsClusterResource).deleteProtection)
                                    ? undefined
                                    : (item.entity as Contract.AwsRdsClusterResource).deleteProtection
                                        ? localization.columns.deleteProtection.enabled()
                                        : localization.columns.deleteProtection.disabled())}
                    selectorOptions={{ default: false }}
                    title={localization.columns.deleteProtection.title()}/>,
            engineVersionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsClusterResourceModel) => ({
                                [localization.columns.engineVersion()]: (item.entity as Contract.AwsRdsClusterResource).engineVersion
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsClusterResourceEngineVersion)}
                                    placeholder={localization.columns.engineVersion()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterResourceEngineVersion}
                    itemProperty={(item: Contract.AwsRdsClusterResourceModel) => (item.entity as Contract.AwsRdsClusterResource).engineVersion}
                    key={Contract.EntityModelProperty.AwsRdsClusterResourceEngineVersion}
                    selectorOptions={{ default: false }}
                    title={localization.columns.engineVersion()}/>,
            globalClusterColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsRdsClusterResourceModel>(
                            globalClusterTypeName,
                            item => [(item.entity as Contract.AwsRdsClusterResource).globalClusterId],
                            translatedGlobalClusterTypeName)}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsClusterResourceGlobalCluster)}
                                    placeholder={translatedGlobalClusterTypeName}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterResourceGlobalCluster}
                    key={Contract.EntityModelProperty.AwsRdsClusterResourceGlobalCluster}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsRdsClusterResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AwsRdsClusterResource).globalClusterId}
                                entityTypeName={globalClusterTypeName}
                                entityVariant="iconText"/>}
                    title={translatedGlobalClusterTypeName}/>,
            instancesColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsRdsClusterResourceModel>(
                            databaseInstanceTypeName,
                            item => item.instanceIds,
                            translatedDatabaseInstanceTypeName)}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsClusterResourceInstances)}
                                    placeholder={translatedDatabaseInstanceTypeName}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterResourceInstances}
                    key={Contract.EntityModelProperty.AwsRdsClusterResourceInstances}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsRdsClusterResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.instanceIds}
                                entityTypeName={databaseInstanceTypeName}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={translatedDatabaseInstanceTypeName}/>,
            snapshotsColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsRdsClusterResourceModel>(
                            clusterSnapshotTypeName,
                            item => item.snapshotIds,
                            translatedClusterSnapshotTypeName)}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsClusterResourceSnapshots)}
                                    placeholder={translatedClusterSnapshotTypeName}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterResourceSnapshots}
                    key={Contract.EntityModelProperty.AwsRdsClusterResourceSnapshots}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsRdsClusterResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.snapshotIds}
                                entityTypeName={clusterSnapshotTypeName}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={translatedClusterSnapshotTypeName}/>,
            statusColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsClusterResourceModel) => ({
                                [localization.columns.status()]: (item.entity as Contract.AwsRdsClusterResource).status
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsClusterResourceStatus)}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterResourceStatus}
                    itemProperty={(item: Contract.AwsRdsClusterResourceModel) => (item.entity as Contract.AwsRdsClusterResource).status}
                    key={Contract.EntityModelProperty.AwsRdsClusterResourceStatus}
                    title={localization.columns.status()}/>,
            storageSizeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsClusterResourceModel) => ({
                                [localization.columns.storageSize()]: NumberFormatter.storage((item.entity as Contract.AwsRdsClusterResource).storageSize)
                            })
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterResourceStorageSize}
                    itemProperty={(item: Contract.AwsRdsClusterResourceModel) => NumberFormatter.storage((item.entity as Contract.AwsRdsClusterResource).storageSize)}
                    key={Contract.EntityModelProperty.AwsRdsClusterResourceStorageSize}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.storageSize()}/>
        }
    };
}