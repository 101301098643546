import { ActionMenuItem, Dialog, Optional, useLocalization, useSetRoute } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useScopesContext, useSetScopesContext } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, ScopeNavigationView, scopeNodeModelStore, scopeSystemEntityModelStore, tenantModelStore } from "../../../../../../common";
import { AddOrEditFolder, ScopeTooltip, Table } from "../../components";
import { Welcome } from "./components";

export function CodeTenants() {
    const generalCodeTenantModels = tenantModelStore.useGetPermittedGeneralCodeTenants();
    const organizationConfigurationModels = scopeSystemEntityModelStore.useGetCodeOrganization();
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap([Contract.TenantType.Code]);

    const { addOrEditOpen } = useScopesContext();
    const setScopesContext = useSetScopesContext();
    const rootFolderScopeModel =
        useMemo(
            () =>
                _.find(
                    scopeNodeModels,
                    scopeModel =>
                        ScopeHelper.isRootFolder(scopeModel) &&
                        (scopeModel.configuration as Contract.FolderConfiguration).tenantType === Contract.TenantType.Code)!,
            [scopeNodeModels]);
    const [parentFolderId, setParentFolderId] = useState<string>(rootFolderScopeModel.configuration.id);
    const addDisabled =
        useMemo(
            () => {
                const parentFolderConfiguration = scopeNodeMap[parentFolderId]?.scopeNodeModel.configuration as Optional<Contract.CodeFolderConfiguration>;
                return parentFolderConfiguration?.managed === true;
            },
            [parentFolderId, scopeNodeMap]);
    const tenantTypeRootFolderScopeNodeModel =
        useMemo(
            () =>
                _.find(
                    scopeNodeModels,
                    scopeNodeModel =>
                        ScopeHelper.isRootFolder(scopeNodeModel) &&
                        (scopeNodeModel.configuration as Contract.FolderConfiguration).tenantType === Contract.TenantType.Code)!,
            [scopeNodeModels]);

    const onEditClick =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: scopeNodeModel
                    }));
            },
            [setScopesContext]);

    const onScopeChanged =
        useCallback(
            (scopeModel: Contract.ScopeNodeModel) => setParentFolderId(scopeModel.configuration.id),
            []);

    const localization =
        useLocalization(
            "views.customer.scopes.codeTenants",
            () => ({
                addFolder: {
                    disabledTooltip: "Can't add a folder in an organization which is configured to automatically update folder structure",
                    title: "Add folder"
                },
                title: {
                    [Contract.CodeTenantType.AzureDevOps]: "Add Azure DevOps Organization",
                    [Contract.CodeTenantType.Bitbucket]: "Add Bitbucket Workspace",
                    [Contract.CodeTenantType.GitHub]: "Add GitHub Organization",
                    [Contract.CodeTenantType.GitLab]: "Add GitLab Group"
                }
            }));

    const getMenuItems =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) =>
                _<ActionMenuItem>([]).
                    concatIf(
                        tenantTypeRootFolderScopeNodeModel.configuration.id === scopeNodeModel.configuration.id,
                        _.map(
                            [
                                Contract.CodeTenantType.AzureDevOps,
                                Contract.CodeTenantType.Bitbucket,
                                Contract.CodeTenantType.GitHub,
                                Contract.CodeTenantType.GitLab
                            ],
                            codeTenantType =>
                                new ActionMenuItem(
                                    () => setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCodeOrganizationsRelativeUrl(codeTenantType, true)),
                                    localization.title.translate(codeTenantType)))).
                    concat(
                        new ActionMenuItem(
                            () =>
                                setScopesContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: "folder"
                                    })),
                            localization.addFolder.title(),
                            {
                                disabled: addDisabled,
                                tooltip:
                                    addDisabled
                                        ? localization.addFolder.disabledTooltip()
                                        : undefined
                            })).
                    value(),
            [addDisabled, localization]);

    const setRoute = useSetRoute();

    const codeTenantTypes =
        useMemo(
            () => [Contract.TenantType.Code],
            []);

    const onCloseAddOrEdit =
        useCallback(
            () => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: false
                    }));
            },
            []);

    return (<Fragment>
        {addOrEditOpen !== false &&
            <Fragment>
                <Dialog
                    variant="editor"
                    onClose={onCloseAddOrEdit}>
                    <AddOrEditFolder
                        folderScopeNodeModel={
                            addOrEditOpen === "folder"
                                ? undefined
                                : addOrEditOpen as Contract.ScopeNodeModel}
                        parentFolderId={parentFolderId}
                        onSave={onCloseAddOrEdit}/>
                </Dialog>
            </Fragment>}
        {_.isEmpty(generalCodeTenantModels) &&
        _.isEmpty(organizationConfigurationModels)
            ? <Welcome/>
            : <ScopeNavigationView
                getItemTooltip={scopeNode => <ScopeTooltip scopeNode={scopeNode}/>}
                hideNonPermittedScopes={true}
                identityPermissions={[Contract.IdentityPermission.SecurityAdministrationRead]}
                layout="view"
                rootFolderId={tenantTypeRootFolderScopeNodeModel.configuration.id}
                templatePath={`${CustomerConsoleAppUrlHelper.getScopesRelativeUrl(Contract.TenantType.Code)}/{scopeId}`}
                tenantTypes={codeTenantTypes}
                variant="folders"
                onScopeChanged={onScopeChanged}>
                {scopeNodeModel =>
                    <Table
                        key={scopeNodeModel.configuration.id}
                        menuItems={getMenuItems(scopeNodeModel)}
                        onEditClick={onEditClick}/>}
            </ScopeNavigationView>}
    </Fragment>);
}