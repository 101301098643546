﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { FlowLogs } from "./components";

export function useGcpComputeSubnetDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const subnetModel = scopeResourceModel as Contract.GcpComputeSubnetModel;
    const subnet = subnetModel.entity as Contract.GcpComputeSubnet;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeSubnetDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        flowLogsEnabled: {
                            disabled: "Disabled",
                            enabled: "Enabled",
                            title: "Flow Logs"
                        },
                        gatewayIpAddress: "Gateway Address",
                        subnets: "IP address ranges",
                        vpcIdReference: "VPC Network"
                    }
                },
                tabs: {
                    flowLogs: "Flow Logs",
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isNil(subnet.flowLogs),
                    () =>
                        new EntityProfileDefinitionTab(
                            ProfileCategory.Overview,
                            <FlowLogs
                                enabled={subnet.flowLogsEnabled}
                                flowLogs={subnet.flowLogs!}/>,
                            localization.tabs.flowLogs(),
                            "flowLogs")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GcpScopeResourceRoleBindings
                            csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                            scopeResourceModel={scopeResourceModel}/>,
                        localization.tabs.resourceRoleBindings(),
                        "resourceRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <VerticalTopItemsInfoItem
                        items={subnet.subnets}
                        key="subnets"
                        title={localization.info.items.subnets()}/>,
                    <InfoItem
                        key="gatewayIpAddress"
                        title={localization.info.items.gatewayIpAddress()}
                        value={subnet.gatewayIpAddress}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnetModel.vpcIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="vpcIdReference"
                        title={localization.info.items.vpcIdReference()}/>,
                    <InfoItem
                        key="flowLogsEnabled"
                        title={localization.info.items.flowLogsEnabled.title()}
                        value={
                            subnet.flowLogsEnabled
                                ? localization.info.items.flowLogsEnabled.enabled()
                                : localization.info.items.flowLogsEnabled.disabled()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={subnetModel.networkedResourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
            </Info>
    });
}