﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEmrSecurityConfigurationRiskContext() {
    return useMemo(
        () => useAwsEmrSecurityConfigurationRiskContext,
        []);
}

function useAwsEmrSecurityConfigurationRiskContext(securityConfigurationModel: Contract.AwsEmrSecurityConfigurationModel) {
    return useGetAwsResourceRiskContext()(securityConfigurationModel);
}