import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../../../common";

export function useSplunkEventCollectorIssueTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.splunk.hooks.useSplunkEventCollectorIssueTranslator",
            () => ({
                noIssue: "Connected",
                [Contract.TypeNames.SplunkEventCollectorStateIssue]: {
                    [Contract.SplunkEventCollectorStateIssue.ConnectionRefused]: "Failed to connect",
                    [Contract.SplunkEventCollectorStateIssue.InvalidCertificate]: "Invalid certificate",
                    [Contract.SplunkEventCollectorStateIssue.InvalidToken]: "Invalid token",
                    [Contract.SplunkEventCollectorStateIssue.InvalidUrl]: "Invalid URL",
                    [Contract.SplunkEventCollectorStateIssue.ServiceUnavailable]: "Service unavailable",
                    [Contract.SplunkEventCollectorStateIssue.Timeout]: "Timeout"
                }
            }));
    return (eventCollectorIssue?: Contract.SplunkEventCollectorStateIssue) =>
        _.isNil(eventCollectorIssue)
            ? localization.noIssue()
            : localization[Contract.TypeNames.SplunkEventCollectorStateIssue][eventCollectorIssue]();
}