import { Grid } from "@mui/material";
import React from "react";
import { CommonRiskPolicyItem, CustomRiskPolicyItem, RiskPolicyItem, useTheme } from "../../../../../../../../../../common";
import { Common, Custom } from "./components";

type DataProps = {
    borderBottom: boolean;
    scopeId: string;
    tableItem: RiskPolicyItem;
};

export function Data({ borderBottom, scopeId, tableItem }: DataProps) {
    const theme = useTheme();
    return (
        <Grid
            container={true}
            style={{
                border: theme.border.primary,
                borderRadius:
                    borderBottom
                        ? theme.spacing(0, 0, 0.75, 0.75)
                        : undefined,
                borderTop: "none",
                padding: theme.spacing(1)
            }}>
            {tableItem instanceof CommonRiskPolicyItem
                ? <Common
                    scopeId={scopeId}
                    tableItem={tableItem}/>
                : <Custom tableItem={tableItem as CustomRiskPolicyItem}/>}
        </Grid>);
}