import { Link, Tooltip, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { MaliciousFile, MaliciousFilesIcon, ProfileLayout, ProfileTopbar, useTheme } from "../../../../../../../common";

type ProfileProps = {
    contentSha256String: string;
};

export function Profile({ contentSha256String }: ProfileProps) {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.maliciousFiles.profile.maliciousFileProfile",
            () => ({
                intelligenceLink: {
                    title: "Intelligence (VirusTotal)",
                    tooltip: "View more details about this file in VirusTotal"
                },
                maliciousFile: "Malicious File"
            }));

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<MaliciousFilesIcon/>}
                    infoChildren={[
                        <Link
                            key="externalLink"
                            sx={{
                                "&:hover": {
                                    textDecoration: "none"
                                },
                                marginRight: theme.spacing(0.5)
                            }}
                            urlOrGetUrl={`https://www.virustotal.com/gui/file/${encodeURIComponent(contentSha256String)}`}
                            variant="external">
                            <Tooltip titleOrGetTitle={localization.intelligenceLink.tooltip()}>
                                <Typography noWrap={true}>
                                    {localization.intelligenceLink.title()}
                                </Typography>
                            </Tooltip>
                        </Link>]}
                    title={localization.maliciousFile()}/>}>
            <MaliciousFile
                contentSha256String={contentSha256String}
                variant="full"/>
        </ProfileLayout>);
}