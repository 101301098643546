import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAzureComputeVirtualMachineStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.azure.hooks.useAzureComputeVirtualMachineStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureComputeVirtualMachineStatus]: {
                    [Contract.AzureComputeVirtualMachineStatus.Deallocated]: "Deallocated",
                    [Contract.AzureComputeVirtualMachineStatus.Deallocating]: "Deallocating",
                    [Contract.AzureComputeVirtualMachineStatus.Running]: "Running",
                    [Contract.AzureComputeVirtualMachineStatus.Starting]: "Starting",
                    [Contract.AzureComputeVirtualMachineStatus.Stopped]: "Stopped",
                    [Contract.AzureComputeVirtualMachineStatus.Stopping]: "Stopping"
                }
            }));
    return (status: Contract.AzureComputeVirtualMachineStatus) =>
        localization[Contract.TypeNames.AzureComputeVirtualMachineStatus][status]();
}