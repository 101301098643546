import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, StatusCell, tenantModelStore } from "../../../../../../../../common";
import { useCodeTenantModelStatusTranslator, useCodeTenantTypeTranslator } from "../../../../../../../../tenants";
import { ProfileDefinition } from "../../useProfileDefinition";

export function useCodeProfileDefinition(scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const codeTenantTypeTranslator = useCodeTenantTypeTranslator();
    const codeTenantModelStatusTranslator = useCodeTenantModelStatusTranslator();
    const tenantModelMap = tenantModelStore.useGetCodeTenantMap();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useCodeDefinition.useCodeProfileDefinition",
            () => ({
                provider: "Provider",
                status: "Status"
            }));

    return useMemo(
        () => {
            const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
            return {
                sections: [
                    {
                        items: [
                            {
                                title: localization.provider(),
                                value: codeTenantTypeTranslator(tenantModel.configuration.codeTenantType)
                            },
                            {
                                title: localization.status(),
                                value:
                                    _.isNil(tenantModel.status)
                                        ? undefined
                                        : <StatusCell
                                            statusSeverity={tenantModel.statusSeverity}
                                            title={codeTenantModelStatusTranslator(tenantModel.status)}/>
                            }
                        ]
                    }
                ]
            };
        },
        [codeTenantTypeTranslator, codeTenantModelStatusTranslator, localization, tenantModelMap]);
}