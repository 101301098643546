import { Link, UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEc2InstancesRiskContext, useGetAwsTagResourceGroupRiskContext } from "../../../../..";
import { Contract, entityModelStore, InlineEntities, InlineResourceTags } from "../../../../../../../../../../../../../../common";

export function useAwsEc2InstanceInstanceProfileNotExistRiskTagResourceGroupDefinition(riskModel: Contract.AwsEc2InstanceInstanceProfileNotExistRiskModel) {
    const tagResourceGroupModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsTagResourceGroupModel;
    const tagResourceGroup = (tagResourceGroupModel.entity) as Contract.AwsTagResourceGroup;

    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsTagResourceGroupRiskContext = useGetAwsTagResourceGroupRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceInstanceProfileNotExistRiskDefinition.hooks.useAwsEc2InstanceInstanceProfileNotExistRiskTagResourceGroupDefinition",
            () => ({
                description: [
                    "{{instances}} grouped by {{tags}} is not configured with IAM instance profile",
                    "{{instances}} grouped by {{tags}} are not configured with IAM instance profile"
                ],
                sections: {
                    resolution: {
                        step1: {
                            documentationLink: "documentation",
                            text: "Attach an instance profile on an EC2 instance by following the {{documentationLink}}"
                        }
                    }
                }
            }));

    const documentationUrl =
        useMemo(
            () => {
                switch (tagResourceGroupModel.entity.typeName) {
                    case Contract.TypeNames.AwsEc2DatabricksInstanceGroup:
                        return riskModel.databricksDocumentationUrl;
                    case Contract.TypeNames.AwsEc2SpotInstanceGroup:
                        return riskModel.spotDocumentationUrl;
                    default:
                        throw new UnexpectedError("tagResourceGroupDefinitionConfiguration.typeName", tagResourceGroupModel.entity.typeName);
                }
            },
            [riskModel, tagResourceGroupModel]);

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            _.size(riskModel.risk.aggregatedEntityIds),
            {
                instances:
                    <InlineEntities
                        entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        variant="itemCountAndType"/>,
                tags:
                    <InlineResourceTags
                        tags={tagResourceGroup.groupTags}
                        textVariant="text"
                        variant="itemCountAndType"/>
            }),
        () => [
            localization.sections.resolution.step1.text({
                documentationLink:
                    <Link
                        urlOrGetUrl={documentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.documentationLink()}
                    </Link>
            })],
        riskModel,
        () => {
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const tagResourceGroupContextItems = getAwsTagResourceGroupRiskContext(tagResourceGroupModel);
            return [
                tagResourceGroupContextItems.getGroupTagsContextItem(
                    riskModel.risk.aggregatedEntityIds,
                    Contract.TypeNames.AwsEc2Instance),
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive];
        });
}