import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciLoadBalancingLoadBalancerStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciLoadBalancingLoadBalancerStatusTranslator",
            () => ({
                [Contract.TypeNames.OciLoadBalancingLoadBalancerStatus]: {
                    [Contract.OciLoadBalancingLoadBalancerStatus.Active]: "Active",
                    [Contract.OciLoadBalancingLoadBalancerStatus.Creating]: "Creating",
                    [Contract.OciLoadBalancingLoadBalancerStatus.Deleted]: "Deleted",
                    [Contract.OciLoadBalancingLoadBalancerStatus.Deleting]: "Deleting",
                    [Contract.OciLoadBalancingLoadBalancerStatus.Failed]: "Failed"
                }
            }));
    return (status: Contract.OciLoadBalancingLoadBalancerStatus) =>
        localization[Contract.TypeNames.OciLoadBalancingLoadBalancerStatus][status]();
}