import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";


export function useGetAwsEc2NetworkAclRiskContext() {
    return useMemo(
        () => useAwsEc2NetworkAclRiskContext,
        []);
}

function useAwsEc2NetworkAclRiskContext(networkAclModel: Contract.AwsEc2NetworkAclModel) {
    const networkAcl = networkAclModel.entity as Contract.AwsEc2NetworkAcl;
    const resourceRiskContext = useGetAwsResourceRiskContext()(networkAclModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2NetworkAclRiskContext",
            () => ({
                vpc: "The {{translatedNetworkAclTypeName}} is part of VPC {{vpc}}"
            }));
    const translatedNetworkAclTypeName =
        entityTypeNameTranslator(
            networkAcl.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        vpc:
            new RiskDefinitionContextItem(
                localization.vpc({
                    translatedNetworkAclTypeName,
                    vpc:
                        <Entity
                            entityIdOrModel={networkAcl.vpcId}
                            variant="text"/>
                }))
    };
}