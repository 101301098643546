import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../utilities";
import { useAadSeverePermissionPrincipalRiskDefinition } from "./access";
import { useAadDirectoryApplicationCertificateNotRotatedRiskDefinition, useAadDirectoryApplicationSecretNotRotatedRiskDefinition, useAadDirectoryApplicationServicePrincipalInactiveRiskDefinition, useAadDirectoryGroupInactiveRiskDefinition, useAadDirectoryUserInactiveRiskDefinition, useAadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskDefinition, useAadTenantEntityDefaultUserRoleTenantCreationEnabledRiskDefinition, useAadTenantEntityUnrestrictedExternalUserDefaultRoleRiskDefinition, useAadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskDefinition } from "./compliance";

export function useAadDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskModel.risk.typeName) {
                    case Contract.TypeNames.AadDirectoryApplicationCertificateNotRotatedRisk:
                        return useAadDirectoryApplicationCertificateNotRotatedRiskDefinition;
                    case Contract.TypeNames.AadDirectoryApplicationSecretNotRotatedRisk:
                        return useAadDirectoryApplicationSecretNotRotatedRiskDefinition;
                    case Contract.TypeNames.AadDirectoryApplicationServicePrincipalInactiveRisk:
                        return useAadDirectoryApplicationServicePrincipalInactiveRiskDefinition;
                    case Contract.TypeNames.AadDirectoryGroupInactiveRisk:
                        return useAadDirectoryGroupInactiveRiskDefinition;
                    case Contract.TypeNames.AadDirectoryUserInactiveRisk:
                        return useAadDirectoryUserInactiveRiskDefinition;
                    case Contract.TypeNames.AadSeverePermissionPrincipalRisk:
                        return useAadSeverePermissionPrincipalRiskDefinition;
                    case Contract.TypeNames.AadTenantEntityDefaultUserRoleApplicationCreationEnabledRisk:
                        return useAadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskDefinition;
                    case Contract.TypeNames.AadTenantEntityDefaultUserRoleTenantCreationEnabledRisk:
                        return useAadTenantEntityDefaultUserRoleTenantCreationEnabledRiskDefinition;
                    case Contract.TypeNames.AadTenantEntityUnrestrictedExternalUserDefaultRoleRisk:
                        return useAadTenantEntityUnrestrictedExternalUserDefaultRoleRiskDefinition;
                    case Contract.TypeNames.AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRisk:
                        return useAadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskDefinition;
                    default:
                        throw new UnexpectedError("riskModel.risk.typeName", riskModel.risk.typeName);
                }
            },
            []);

    return useDefinition(riskModel);
}