import { ItemWithValidation, useChangeEffect } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useRef, useState } from "react";
import { ServiceNowIncidentSelector, ServiceNowSelectorActions } from "../..";
import { Contract } from "../../../controllers";
import { scopeSystemEntityModelStore } from "../../../stores";
import { ScopeHelper } from "../../../utilities";
import { DeliveryProps } from "../Delivery";
import { Message } from "./Message";

export function ServiceNow({ delivery, disabled, messageOptions, onChange, scopeId }: DeliveryProps) {
    const serviceNowModels = scopeSystemEntityModelStore.useGetServiceNow();
    const [serviceNowDelivery, setServiceNowDelivery] =
        useState(
            _((delivery.destinations ?? []) as Contract.ServiceNowDeliveryInstance[]).
                map(
                    ({ incidentCreationData, instanceId }) => ({
                        incidentCreationData,
                        instanceId
                    })).
                first());
    const [message, setMessage] = useState(delivery?.message);
    const instanceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            serviceNowModels);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.ServiceNowDelivery),
                destinations:
                    _.isNil(serviceNowDelivery)
                        ? []
                        : [{
                            ...serviceNowDelivery,
                            typeName: Contract.TypeNames.ServiceNowDeliveryInstance
                        }] as Contract.ServiceNowDeliveryInstance[],
                message,
                valid: !_.isNil(serviceNowDelivery)
            } as ItemWithValidation<Contract.ServiceNowDelivery>),
        [serviceNowDelivery, message],
        500);

    const serviceNowIncidentSelectorActions = useRef<ServiceNowSelectorActions>();
    return (
        <Fragment>
            <ServiceNowIncidentSelector
                actionsRef={serviceNowIncidentSelectorActions}
                disabled={disabled}
                initialData={serviceNowDelivery?.incidentCreationData}
                instanceId={serviceNowDelivery?.instanceId}
                instanceModels={instanceModels}
                riskIds={[]}
                onDataChanged={setServiceNowDelivery}/>
            <Message
                disabled={disabled}
                message={message}
                options={messageOptions}
                setMessage={setMessage}/>
        </Fragment>);
}