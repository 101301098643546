import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpDnsManagedZoneRiskContext } from "../contexts/useGetGcpDnsManagedZoneRiskContext";

export function useGcpDnsManagedZoneDnsSecNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpDnsManagedZoneDnsSecNotEnabledRisk;
    const managedZoneModel = entityModelStore.useGet(risk.entityId) as Contract.GcpDnsManagedZoneModel;

    const getGcpDnsManagedZoneRiskContext = useGetGcpDnsManagedZoneRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpDnsManagedZoneDnsSecNotEnabledRiskDefinition",
            () => ({
                description: "{{managedZone}} DNSSEC is not enabled",
                sections: {
                    resolution: {
                        step1: "Click **Edit**",
                        step2: "Set **DNSSEC** to **On**",
                        step3: "Click **SAVE**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            managedZone:
                <Entity
                    entityIdOrModel={managedZoneModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const managedZoneRiskContext = getGcpDnsManagedZoneRiskContext(managedZoneModel);
            return [
                managedZoneRiskContext.generalInformation,
                managedZoneRiskContext.publicNetworkAccess,
                managedZoneRiskContext.loggingEnabled,
                managedZoneRiskContext.resourceRecordSets,
                managedZoneRiskContext.sensitive,
                managedZoneRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}