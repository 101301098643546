﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, EntityController } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { ElasticsearchItemPageHelper } from "../../../../../../../utilities";
import { OperatorSelector } from "../../../../../../OperatorSelector";
import { PagedEntityMultiSelect } from "../../../../../../PagedEntityMultiSelect";
import { EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";
import { useEntityMatchConditionGroupMembershipTypeOperatorTranslator } from "../hooks";

type AadDirectoryPrincipalGroupIdsProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
};

export function AadDirectoryPrincipalGroupIds({ disabled, entityTypeName, includeServiceName }: AadDirectoryPrincipalGroupIdsProps) {
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.AadDirectoryPrincipalGroupIds] as Contract.AadDirectoryPrincipalGroupIdMatchCondition;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const operatorTranslator = useEntityMatchConditionGroupMembershipTypeOperatorTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.aadDirectoryPrincipalGroupIds",
            () => ({
                text: {
                    part1: "*capitalize*{{translatedEntityTypeName}}** that are",
                    part2: "members of these groups"
                }
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.text.part1({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <OperatorSelector
                operators={_.values(Contract.EntityMatchConditionGroupMembershipTypeOperator)}
                operatorTranslator={operatorTranslator}
                selectedOperator={condition.operator}
                onSelectedOperatorChanged={
                    (operator: Contract.EntityMatchConditionGroupMembershipTypeOperator) =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.AadDirectoryPrincipalGroupIds]:
                                        new Contract.AadDirectoryPrincipalGroupIdMatchCondition(
                                            condition.groupIds,
                                            operator)
                                }
                            }))
                }/>
            <Typography>
                {localization.text.part2()}
            </Typography>
            <PagedEntityMultiSelect
                disabled={disabled}
                fieldOptions={{ dense: true }}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    new Contract.EntityControllerSearchEntityModelsDirectoryEntityTypeRequest(
                                        false,
                                        15,
                                        itemNextPageSearchCursor,
                                        undefined,
                                        searchText,
                                        Contract.TypeNames.AadDirectoryGroup));
                            return entityModelPage;
                        })}
                placeholder={
                    entityTypeNameTranslator(
                        Contract.TypeNames.AadDirectoryGroup,
                        {
                            count: 0,
                            includeServiceName: false
                        })}
                selectedEntityIds={condition.groupIds}
                onSelectedEntityIdsChanged={
                    groupIds =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.AadDirectoryPrincipalGroupIds]:
                                        new Contract.AadDirectoryPrincipalGroupIdMatchCondition(
                                            groupIds,
                                            condition.operator)

                                }
                            }))}/>
        </Stack>);
}