import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { usePingIdentityCommonDirectoryEntityDefinition } from "./usePingIdentityCommonDirectoryEntityDefinition";

export function usePingIdentityDirectoryGroupDefinition(definitionData: DefinitionData) {
    const commonDirectoryEntityDefinition = usePingIdentityCommonDirectoryEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.pingIdentity.usePingIdentityDirectoryGroupDefinition",
            () => ({
                columns: {
                    memberPrincipals: "Members"
                }
            }));
    return new EntityTableDefinition(
        [
            commonDirectoryEntityDefinition.columns.tenantColumn,
            commonDirectoryEntityDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.PingIdentityDirectoryGroupModel>(
                        Contract.TypeNames.PingIdentityDirectoryPrincipal,
                        item => item.memberPrincipalIds,
                        localization.columns.memberPrincipals())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.PingIdentityDirectoryPrincipal,
                                        true)}
                                placeholder={localization.columns.memberPrincipals()}/>
                    }
                }}
                id={Contract.EntityModelProperty.PingIdentityDirectoryGroupMemberPrincipals}
                key={Contract.EntityModelProperty.PingIdentityDirectoryGroupMemberPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PingIdentityDirectoryGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.memberPrincipalIds)}
                            entityTypeName={Contract.TypeNames.PingIdentityDirectoryPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.memberPrincipals()}/>,
            commonDirectoryEntityDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPagePingIdentityDirectoryGroupRequest(
                new Contract.EntityControllerGetEntityModelPagePingIdentityDirectoryGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.PingIdentityDirectoryEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.PingIdentityDirectoryGroupMemberPrincipals])),
                limit,
                skip,
                requestSort));
}