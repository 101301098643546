import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AkamaiIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M1.46055 21.1375C1.40759 20.6855 1.38054 20.2347 1.38054 19.7828C1.38054 12.4271 7.32981 6.47896 14.6855 6.47896C21.6423 6.47896 23.7137 9.58601 23.9797 9.37301C24.2715 9.16114 21.4564 3 13.3039 3C5.94815 3 0 8.94815 0 16.3039C0 18.0033 0.318932 19.6239 0.902703 21.1104C1.14162 21.7483 1.51352 21.7483 1.46055 21.1375Z"
                fill="#0099CC"/>
            <path
                d="M7.03725 11.5509C10.4892 10.0373 14.8449 9.98437 19.1195 11.4979C21.9876 12.5065 23.6612 13.9412 23.7942 13.8611C24.0331 13.7552 22.1206 10.7541 18.6946 9.45244C14.5519 7.88595 10.0913 8.70977 6.8513 11.259C6.4794 11.5509 6.61238 11.7368 7.03725 11.5509Z"
                fill="#0099CC"/>
        </SvgIcon>);
}