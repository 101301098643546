import { Action0, DirectedGraphModelNode, Optional, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { MutableRefObject } from "react";
import { Contract, EntityController, EntityTypeMetadataModelHelper, getEntityNodeContentAnchorPoint, getEntityNodeContentSize, ItemSelectionHelper } from "../../../../../..";
import { AccessDefinition, AccessDefinitionToolbarActions, ToolbarFilterId } from "../..";
import { GraphColumnId } from "../../../../components";
import { GcpToolbarFilterId, PermissionPathNodeContent, Permitters, Toolbar } from "./components";

export function useGcpDefinition(entityTypeName: string) {
    const entityTypeMetadataModel = EntityTypeMetadataModelHelper.get(entityTypeName);

    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useGcpDefinition",
            () => ({
                csvExport: {
                    fileNamePrefix: "GcpPermissions"
                },
                specialGroup: {
                    tooltip: {
                        text: "A principal is automatically a member of this group if it has the **{{roleName}}** role assigned at the project level or above",
                        unknown: "A principal is automatically a member of this group if it has a basic role assigned at the project level or above"
                    },
                    type: {
                        [Contract.GcpSpecialGroupType.ProjectEditors]: "Editor",
                        [Contract.GcpSpecialGroupType.ProjectOwners]: "Owner",
                        [Contract.GcpSpecialGroupType.ProjectViewers]: "Viewer"
                    }
                }
            }));

    return new AccessDefinition(
        localization.csvExport.fileNamePrefix(),
        {
            entityTypeNames: {
                destinationEntityTypeName: Contract.TypeNames.GcpResource,
                serviceTypeName: Contract.TypeNames.GcpService,
                sourceEntityTypeName: Contract.TypeNames.IGciPrincipal
            },
            getPermissionActionName: permissionsAction => permissionsAction,
            getServiceName: entityModel => (entityModel.entity as Contract.GcpService).displayName,
            permitterComponent: Permitters
        },
        (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope) =>
            EntityController.getAccessGraph(
                new Contract.EntityControllerGetGcpAccessGraphRequest(
                    direction,
                    entityId,
                    filters as Contract.EntityControllerGetGcpAccessDataRequestFilters,
                    scope)),
        (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope, selection: Contract.EntityControllerGetAccessListPermissionActionsRequestSelection, selectedPermissionPaths: Contract.AccessGraphPermissionPath[]) =>
            EntityController.getAccessListPermissionActions(
                new Contract.EntityControllerGetGcpAccessListPermissionActionsRequest(
                    direction,
                    entityId,
                    filters as Contract.EntityControllerGetGcpAccessDataRequestFilters,
                    scope,
                    selection,
                    _.map(
                        selectedPermissionPaths as Contract.GcpAccessGraphPermissionPath[],
                        permissionPath => permissionPath.identifier))),
        (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope) =>
            new Contract.ReportControllerGcpAccessReportRequestDefinition(
                undefined,
                undefined,
                Contract.TypeNames.ReportControllerGcpAccessReportRequestDefinition,
                direction,
                entityId,
                scope,
                filters as Contract.EntityControllerGetGcpAccessDataRequestFilters),
        (filterMap: Dictionary<any>, destinationResourceTenantId?: string): Contract.EntityControllerGetGcpAccessDataRequestFilters => ({
            accessLevelSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.AccessLevels]),
            destinationEntityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationEntityIds]),
            destinationResourceBuiltInEntityAttributeTypeNameSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames]),
            destinationResourceTenantIdSelection:
                _.isNil(destinationResourceTenantId)
                    ? ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationResourceTenantIds])
                    : ItemSelectionHelper.toItemSelectionFromValues([destinationResourceTenantId]),
            groupIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.GroupIds]),
            identityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.IdentityIds]),
            originatorEntityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.OriginatorEntityIds]),
            originatorEntityTypeNameSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.OriginatorEntityTypeNames]),
            permissionActionExcessiveness: filterMap[GcpToolbarFilterId.PermissionActionExcessiveness] as Optional<Contract.PermissionActionExcessiveness>,
            permissionActionRiskCategorySelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.PermissionActionRiskCategories]),
            permissionActionSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.PermissionActions]),
            permissionActionSeveritySelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.PermissionActionSeverity]),
            permitterIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.PermitterIds])
        }),
        entityTypeMetadataModel.gcpOutboundAccessGraph
            ? Contract.EntityAccessDirection.Outbound
            : Contract.EntityAccessDirection.Inbound,
        {
            getDirectedGraphModelNode: (
                permissionPath: Contract.AccessGraphPermissionPath,
                toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>,
                onClick: Action0) =>
                new DirectedGraphModelNode(
                    permissionPath.id,
                    GraphColumnId.PermissionPaths,
                    <PermissionPathNodeContent
                        permissionPath={permissionPath as Contract.GcpAccessGraphPermissionPath}
                        toolbarActionsRef={toolbarActionsRef}/>,
                    getEntityNodeContentSize("bottom", "large"),
                    edge =>
                        getEntityNodeContentAnchorPoint(
                            edge.getAnchorPlacement(permissionPath.id),
                            "bottom",
                            "large"),
                    {
                        onClick
                    }),
            getEntityPermissionPath:
                (entityId: string, permissionPaths: Contract.AccessGraphPermissionPath[]) =>
                    _(permissionPaths).
                        filter(permissionPath => (permissionPath as Contract.GcpAccessGraphPermissionPath).identifier.roleId === entityId).
                        first()
        },
        Toolbar,
        groupEntityModel =>
            groupEntityModel.entity.typeName !== Contract.TypeNames.GcpSpecialGroup
                ? undefined
                : groupEntityModel.unknown
                    ? localization.specialGroup.tooltip.unknown()
                    : localization.specialGroup.tooltip.text({
                        roleName: localization.specialGroup.type[(groupEntityModel.entity as Contract.GcpSpecialGroup).type]()
                    }));
}