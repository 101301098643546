import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsElasticFileSystemFileSystemRiskContext() {
    return useMemo(
        () => useAwsElasticFileSystemFileSystemRiskContext,
        []);
}

function useAwsElasticFileSystemFileSystemRiskContext(fileSystemModel: Contract.AwsElasticFileSystemFileSystemModel) {
    const fileSystem = fileSystemModel.entity as Contract.AwsElasticFileSystemFileSystem;
    const resourceRiskContext = useGetAwsResourceRiskContext()(fileSystemModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsElasticFileSystemFileSystemRiskContext",
            () => ({
                size: "The {{translatedFileSystemTypeName}} size is {{storageSize | NumberFormatter.storage}}"
            }));

    const translatedFileSystemTypeName =
        entityTypeNameTranslator(
            fileSystem.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        size:
            new RiskDefinitionContextItem(
                localization.size({
                    storageSize: fileSystem.storageSize,
                    translatedFileSystemTypeName
                }))
    };
}