import React from "react";
import { RiskPoliciesType } from "../../../../../../../common";
import { Profile, ProfileActions, useItem } from "../../../../RiskPolicies/components";
import { RiskPolicyHelper } from "../../../../RiskPolicies/utilities";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useRiskPoliciesDefinition(item: SideViewItem): SideViewDefinition {
    const { riskPoliciesType, riskPolicyConfigurationTypeNameOrId, scopeId } = RiskPolicyHelper.getSideViewItemData(item.id);
    const riskPolicy =
        useItem(
            riskPoliciesType as RiskPoliciesType,
            scopeId,
            riskPolicyConfigurationTypeNameOrId);

    return {
        getActionsElement:
            () =>
                <ProfileActions
                    key={`${item.id}-actions`}
                    riskPoliciesType={riskPoliciesType as RiskPoliciesType}
                    riskPolicy={riskPolicy}
                    scopeId={scopeId}/>,
        getViewElement:
            () =>
                <Profile
                    key={`${item.id}-profile`}
                    riskPoliciesType={riskPoliciesType as RiskPoliciesType}
                    riskPolicy={riskPolicy}
                    scopeId={scopeId}/>
    };
}