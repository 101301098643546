import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeInstanceGroupTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeInstanceGroupTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpInstanceGroupType]: {
                    [Contract.GcpInstanceGroupType.Managed]: "Managed",
                    [Contract.GcpInstanceGroupType.Unmanaged]: "Unmanaged"
                }
            }));
    return (type: Contract.GcpInstanceGroupType) =>
        localization[Contract.TypeNames.GcpInstanceGroupType][type]();
}