import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../common";

export function useDatadogOrganizationConfigurationSiteTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.datadog.hooks.useDatadogOrganizationConfigurationSiteTranslator",
            () => ({
                [Contract.TypeNames.DatadogOrganizationConfigurationSite]: {
                    [Contract.DatadogOrganizationConfigurationSite.Eu1]: "EU1 - https://app.datadoghq.eu",
                    [Contract.DatadogOrganizationConfigurationSite.Us1]: "US1 - https://app.datadoghq.com",
                    [Contract.DatadogOrganizationConfigurationSite.Us1Gov]: "US1-GOV - https://app.ddog-gov.com",
                    [Contract.DatadogOrganizationConfigurationSite.Us3]: "US3 - https://us3.datadoghq.com",
                    [Contract.DatadogOrganizationConfigurationSite.Us5]: "US5 - https://us5.datadoghq.com"
                }
            }));
    return (site: Contract.DatadogOrganizationConfigurationSite) =>
        localization[Contract.TypeNames.DatadogOrganizationConfigurationSite][site]();
}