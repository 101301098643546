import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { useAzureCommonBehaviorIdentityRiskDefinition } from "./useAzureCommonBehaviorIdentityRiskDefinition";

export function useAzurePermissionManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const permissionManagementRiskModel = riskModel as Contract.AzurePermissionManagementRiskModel;
    const identityModel = entityModelStore.useGet(permissionManagementRiskModel.risk.identityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.behavior.useAzurePermissionManagementRiskDefinition",
            () => ({
                description: "{{identity}} was observed modifying the permissions of {{resources}}"
            }));
    return useAzureCommonBehaviorIdentityRiskDefinition(
        riskModel,
        localization.description({
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            resources:
                <InlineEntities
                    entityIdsOrModels={permissionManagementRiskModel.resourceIdReferences}
                    entityTypeName={Contract.TypeNames.AzureResource}
                    variant="itemAndTypeOrItemCountAndType"/>
        }));
}