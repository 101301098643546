﻿import { Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, EntityController } from "..";

export class RegionModelHelper {
    private static regionModelMap: Dictionary<Contract.RegionModel>;

    public static async initialize() {
        const { regionModels } = await EntityController.getRegionModels();
        RegionModelHelper.regionModelMap =
            _.keyBy(
                regionModels,
                regionModel => regionModel.id);
    }

    public static get(regionId: string) {
        return RegionModelHelper.regionModelMap[regionId];
    }

    public static tryGet(regionId: Optional<string>) {
        return _.isNil(regionId)
            ? regionId
            : RegionModelHelper.regionModelMap[regionId];
    }
}