import { Divider } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { ProjectIcon, StringHelper, useLocalization } from "@infrastructure";
import { useCustomerContext } from "../..";
import { AccessViewIcon, AuditEventsIcon, CodeViewIcon, Contract, CustomerConsoleAppUrlHelper, DashboardIcon, DataAnalysisIcon, EntitiesIcon, EventsIcon, FeatureHelper, KubernetesIcon, LicensingHelper, projectModelStore, ReportsIcon, RiskPoliciesIcon, RisksIcon, ScopeHelper, scopeNodeModelStore, SettingIcon, SetViewRoute, Sidebar as CommonSidebar, sidebarItemSizeClose, SidebarNavigationItem, SidebarNavigationItemSubItem, TenantHelper, TenantIcon, tenantModelStore, useProjectScopeSelected, UserHelper, useTenantConfigurationTypeNameRenderer, useTenantTypeRenderer, useTenantTypeTranslator, useTheme, ViewRoute } from "../../../../common";
import { KubernetesViewIcon } from "../../../../common/icons/KubernetesViewIcon";
import { PrincipalAccessIcon } from "../../../../common/icons/PrincipalAccessIcon";
import { CodeIcon } from "../../../../tenants";
import { CustomerView } from "../../hooks";
import { AccessView } from "../Access";
import { CodeView } from "../Code";
import { DataAnalysisView } from "../DataAnalysis";
import { KubernetesView } from "../Kubernetes";
import { ScopesView } from "../Scopes";
import { WorkloadAnalysisSidebarNavigationItem } from "./components/WorkloadAnalysisSidebarNavigationItem";
import { ComplianceIcon, TenantsIcon, UdmObjectsIcon } from "./icons";

type SidebarProps = {
    setViewRoute: SetViewRoute<string>;
    viewRoute: ViewRoute<string>;
};

export function Sidebar({ setViewRoute, viewRoute }: SidebarProps) {
    const { reloadView } = useCustomerContext();

    const configurationTenantModels = tenantModelStore.useGetFilteredActiveTenants(TenantHelper.ConfigurationTenantTypes);
    const excessivePermissionsEnabled = tenantModelStore.useGetFilteredActiveCloudProviderTenantsExcessivePermissionsEnabled();
    const filteredActiveCodeTenants = tenantModelStore.useGetFilteredActiveCodeTenants();
    const filteredActiveTenantTypes = tenantModelStore.useGetFilteredActiveTenantTypes();
    const projectScopeSelected = useProjectScopeSelected();
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const projectModels = projectModelStore.useGetPermittedProjects();

    const hasPermittedProjectScopes =
        useMemo(
            () =>
                _.some(
                    scopeNodeModels,
                    scopeNodeModel =>
                        ScopeHelper.isProjectScope(scopeNodeModel) &&
                        UserHelper.hasScopePermissions(
                            scopeNodeModel.configuration.id,
                            Contract.IdentityPermission.SecurityAdministrationRead)),
            [scopeNodeModels]);

    const tenantTypeTranslator = useTenantTypeTranslator();
    const securityAdministrationReadPermissionExistsTenantsTenantTypes =
        useMemo(
            () =>
                _(scopeNodeModels).
                    filter(
                        scopeNodeModel =>
                            _.includes(
                                TenantHelper.TenantsTenantTypes,
                                ScopeHelper.getTenantType(scopeNodeModel)) &&
                            UserHelper.hasScopePermissions(
                                scopeNodeModel.configuration.id,
                                Contract.IdentityPermission.SecurityAdministrationRead)).
                    map(scopeNodeModel => ScopeHelper.getTenantType(scopeNodeModel)!).
                    uniq().
                    orderBy(tenantType => StringHelper.getSortValue(tenantTypeTranslator(tenantType))).
                    value(),
            [scopeNodeModels]);

    const securityAdministrationReadPermissionExistsTenantsConfigurationTenantTypes =
        useMemo(
            () =>
                _(configurationTenantModels).
                    filter(
                        configurationTenantModel =>
                            UserHelper.hasScopePermissions(
                                configurationTenantModel.configuration.id,
                                Contract.IdentityPermission.SecurityAdministrationRead)).
                    map(securityAdminPermittedConfigurationTenantModel => securityAdminPermittedConfigurationTenantModel.tenantType).
                    uniq().
                    value(),
            [configurationTenantModels]);

    const hasFilteredActiveGeneralCodeTenants =
        useMemo(
            () =>
                _.some(
                    filteredActiveCodeTenants,
                    codeTenant => !TenantHelper.isGeneralCodeTenant(codeTenant.configuration)),
            [filteredActiveCodeTenants]);

    const localization =
        useLocalization(
            "views.customer.sidebar",
            () => ({
                access: {
                    awsFederation: "AWS Federated Permissions",
                    dashboard: "Dashboard",
                    permissions: "Permissions Query",
                    principalExcessivePermissions: "Excessive Permissions",
                    principalPermissions: "Identity Intelligence",
                    title: "IAM"
                },
                administration: "Administration",
                approverUserPermissionRequests: "Access Reviews",
                auditEvents: "Audit",
                code: {
                    dashboard: "Dashboard",
                    policies: "Policies",
                    risks: "Findings",
                    scans: "CI/CD Scans",
                    title: "IaC"
                },
                compliance: "Compliance",
                configurations: "Settings",
                dashboard: "Dashboard",
                dataAnalysis: {
                    entities: "Data Inventory",
                    title: "Data"
                },
                entities: "Inventory",
                events: "Activity Log",
                granteeUserPermissionRequests: "Access Requests",
                kubernetes: {
                    admissionControllerClusterEvents: "Admission Controller Logs",
                    admissionControllerRiskPolicies: "Admission Controller Policies",
                    systemKubernetesClusters: "Clusters",
                    title: "Kubernetes"
                },
                permissionManagement: {
                    auditEvents: "Audit",
                    eligibilities: "Eligibilities",
                    title: "JIT Access"
                },
                reports: "Reports",
                riskPolicies: "Policies",
                risks: "Findings",
                scopes: {
                    projects: "Projects",
                    title: "Accounts"
                },
                udmObjects: "Explore"
            }));

    const tenantConfigurationTypeNameRenderer = useTenantConfigurationTypeNameRenderer();
    const tenantTypeRenderer = useTenantTypeRenderer();
    const theme = useTheme();
    return (
        <CommonSidebar
            bottomItems={
                <SidebarNavigationItem
                    disabled={
                        (!_.isNil(viewRoute.parameters?.scopeId) &&
                            !UserHelper.hasScopePermissions(viewRoute.parameters?.scopeId, Contract.IdentityPermission.SecurityAdministrationRead)) ||
                        (!UserHelper.hasScopePermissions(ScopeHelper.customerId, Contract.IdentityPermission.SecurityAdministrationRead)) &&
                        _.isEmpty(securityAdministrationReadPermissionExistsTenantsConfigurationTenantTypes) &&
                        !_.some(
                            projectModels,
                            projectModel =>
                                UserHelper.hasScopePermissions(
                                    projectModel.configuration.id,
                                    Contract.IdentityPermission.SecurityAdministrationRead))}
                    icon={SettingIcon}
                    title={localization.configurations()}
                    view={CustomerView.Configuration}/>}
            items={
                <Fragment>
                    <SidebarNavigationItem
                        icon={DashboardIcon}
                        tenantTypes={TenantHelper.DashboardTenantTypes}
                        title={localization.dashboard()}
                        view={CustomerView.Dashboard}/>
                    {tenantTypeRenderer(
                        TenantHelper.EntitiesTenantTypes,
                        configuredRenderTenantTypes =>
                            <SidebarNavigationItem
                                icon={EntitiesIcon}
                                subItems={
                                    _(configuredRenderTenantTypes).
                                        orderBy(
                                            [
                                                configuredRenderTenantType => TenantHelper.isCloudProviderTenantType(configuredRenderTenantType),
                                                configuredRenderTenantType => TenantHelper.isIdentityProviderTenantType(configuredRenderTenantType),
                                                configuredRenderTenantType => StringHelper.getSortValue(tenantTypeTranslator(configuredRenderTenantType))
                                            ],
                                            [
                                                "desc",
                                                "desc"
                                            ]).
                                        map(
                                            configuredRenderTenantType =>
                                                new SidebarNavigationItemSubItem(
                                                    <TenantIcon
                                                        colored={true}
                                                        tenantType={configuredRenderTenantType}/>,
                                                    tenantTypeTranslator(configuredRenderTenantType),
                                                    configuredRenderTenantType,
                                                    _.includes(filteredActiveTenantTypes, configuredRenderTenantType))).
                                        value()}
                                tenantTypes={TenantHelper.EntitiesTenantTypes}
                                title={localization.entities()}
                                view={CustomerView.Entities}/>)}
                    {tenantTypeRenderer(
                        TenantHelper.RisksTenantTypes,
                        () =>
                            <SidebarNavigationItem
                                icon={RisksIcon}
                                tenantTypes={TenantHelper.RisksTenantTypes}
                                title={localization.risks()}
                                view={CustomerView.Risks}/>)}
                    {FeatureHelper.enabled(Contract.FeatureName.UdmEnabled) &&
                        <SidebarNavigationItem
                            icon={UdmObjectsIcon}
                            tenantTypes={
                                _(TenantHelper.EntitiesTenantTypes).
                                    concat(TenantHelper.RisksTenantTypes).
                                    uniq().
                                    value()}
                            title={localization.udmObjects()}
                            view={CustomerView.UdmObjects}/>}
                    {tenantTypeRenderer(
                        TenantHelper.EventsTenantTypes,
                        () =>
                            <SidebarNavigationItem
                                icon={EventsIcon}
                                tenantTypes={_.without(TenantHelper.ConfigurationTenantTypes, Contract.TenantType.Code)}
                                title={localization.events()}
                                view={CustomerView.Events}/>)}
                    {tenantTypeRenderer(
                        TenantHelper.AccessTenantTypes,
                        () =>
                            <SidebarNavigationItem
                                icon={PrincipalAccessIcon}
                                subItems={
                                    _<SidebarNavigationItemSubItem>([]).
                                        concat(
                                            new SidebarNavigationItemSubItem(
                                                <AccessViewIcon view={AccessView.Dashboard}/>,
                                                localization.access.dashboard(),
                                                AccessView.Dashboard)).
                                        concat(
                                            new SidebarNavigationItemSubItem(
                                                <AccessViewIcon view={AccessView.Permissions}/>,
                                                localization.access.permissions(),
                                                AccessView.Permissions),
                                            new SidebarNavigationItemSubItem(
                                                <AccessViewIcon view={AccessView.PrincipalPermissions}/>,
                                                localization.access.principalPermissions(),
                                                AccessView.PrincipalPermissions),
                                            new SidebarNavigationItemSubItem(
                                                <AccessViewIcon view={AccessView.PrincipalExcessivePermissions}/>,
                                                localization.access.principalExcessivePermissions(),
                                                AccessView.PrincipalExcessivePermissions,
                                                excessivePermissionsEnabled)).
                                        concatIf(
                                            _.includes(filteredActiveTenantTypes, Contract.TenantType.Aws),
                                            new SidebarNavigationItemSubItem(
                                                <AccessViewIcon view={AccessView.AwsFederation}/>,
                                                localization.access.awsFederation(),
                                                AccessView.AwsFederation)).
                                        value()}
                                tenantTypes={
                                    _(TenantHelper.AccessTenantTypes).
                                        concat(TenantHelper.IdentityProviderTenantTypes).
                                        uniq().
                                        value()}
                                title={localization.access.title()}
                                view={CustomerView.Access}/>)}
                    {tenantTypeRenderer(
                        TenantHelper.DataAnalysisTenantTypes,
                        () =>
                            <SidebarNavigationItem
                                icon={DataAnalysisIcon}
                                subItems={[
                                    new SidebarNavigationItemSubItem(
                                        <EntitiesIcon/>,
                                        localization.dataAnalysis.entities(),
                                        DataAnalysisView.Entities)
                                ]}
                                tenantTypes={TenantHelper.DataAnalysisTenantTypes}
                                title={localization.dataAnalysis.title()}
                                view={CustomerView.DataAnalysis}/>)}
                    {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                        <WorkloadAnalysisSidebarNavigationItem/>}
                    {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                        UserHelper.hasAnyCloudProviderTenantPermissions(Contract.IdentityPermission.SecurityRead) &&
                        <SidebarNavigationItem
                            icon={KubernetesIcon}
                            subItems={
                                _<SidebarNavigationItemSubItem>([]).
                                    concat(
                                        new SidebarNavigationItemSubItem(
                                            <KubernetesViewIcon view={KubernetesView.SystemKubernetesClusters}/>,
                                            localization.kubernetes.systemKubernetesClusters(),
                                            KubernetesView.SystemKubernetesClusters)).
                                    concatIf(
                                        LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise),
                                        () =>
                                            [
                                                new SidebarNavigationItemSubItem(
                                                    <KubernetesViewIcon view={KubernetesView.KubernetesAdmissionControllerRiskPolicies}/>,
                                                    localization.kubernetes.admissionControllerRiskPolicies(),
                                                    KubernetesView.KubernetesAdmissionControllerRiskPolicies),
                                                new SidebarNavigationItemSubItem(
                                                    <KubernetesViewIcon view={KubernetesView.KubernetesClusterAdmissionControllerEvents}/>,
                                                    localization.kubernetes.admissionControllerClusterEvents(),
                                                    KubernetesView.KubernetesClusterAdmissionControllerEvents)
                                            ]).
                                    value()}
                            tenantTypes={TenantHelper.KubernetesTenantTypes}
                            title={localization.kubernetes.title()}
                            view={CustomerView.Kubernetes}/>}
                    {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                        tenantTypeRenderer(
                            TenantHelper.CodeTenantTypes,
                            () =>
                                tenantConfigurationTypeNameRenderer(
                                    [Contract.TypeNames.GitTenantConfiguration],
                                    () =>
                                        <SidebarNavigationItem
                                            icon={CodeIcon}
                                            subItems={[
                                                new SidebarNavigationItemSubItem(
                                                    <CodeViewIcon view={CodeView.Dashboard}/>,
                                                    localization.code.dashboard(),
                                                    CodeView.Dashboard,
                                                    hasFilteredActiveGeneralCodeTenants),
                                                new SidebarNavigationItemSubItem(
                                                    <CodeViewIcon view={CodeView.Risks}/>,
                                                    localization.code.risks(),
                                                    CodeView.Risks,
                                                    hasFilteredActiveGeneralCodeTenants),
                                                new SidebarNavigationItemSubItem(
                                                    <CodeViewIcon view={CodeView.Policies}/>,
                                                    localization.code.policies(),
                                                    CodeView.Policies,
                                                    !projectScopeSelected),
                                                new SidebarNavigationItemSubItem(
                                                    <CodeViewIcon view={CodeView.Scans}/>,
                                                    localization.code.scans(),
                                                    CodeView.Scans)]}
                                            tenantTypes={TenantHelper.CodeTenantTypes}
                                            title={localization.code.title()}
                                            view={CustomerView.Code}/>,
                                    <SidebarNavigationItem
                                        icon={CodeIcon}
                                        subItems={[
                                            new SidebarNavigationItemSubItem(
                                                <CodeViewIcon view={CodeView.Policies}/>,
                                                localization.code.policies(),
                                                CodeView.Policies),
                                            new SidebarNavigationItemSubItem(
                                                <CodeViewIcon view={CodeView.Scans}/>,
                                                localization.code.scans(),
                                                CodeView.Scans)
                                        ]}
                                        tenantTypes={TenantHelper.CodeTenantTypes}
                                        title={localization.code.title()}
                                        view={CustomerView.Code}/>),
                            <SidebarNavigationItem
                                icon={CodeIcon}
                                tenantTypes={TenantHelper.CodeTenantTypes}
                                title={localization.code.title()}
                                view={CustomerView.Code}/>)}
                    {tenantTypeRenderer(
                        TenantHelper.CloudRiskPoliciesTenantTypes,
                        () =>
                            <SidebarNavigationItem
                                disabled={projectScopeSelected}
                                icon={RiskPoliciesIcon}
                                tenantTypes={TenantHelper.CloudRiskPoliciesTenantTypes}
                                title={localization.riskPolicies()}
                                view={CustomerView.RiskPolicies}/>)}
                    {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                        tenantTypeRenderer(
                            TenantHelper.ComplianceTenantTypes,
                            () =>
                                <SidebarNavigationItem
                                    icon={ComplianceIcon}
                                    tenantTypes={TenantHelper.ComplianceTenantTypes}
                                    title={localization.compliance()}
                                    view={CustomerView.Compliance}/>)}
                    {tenantTypeRenderer(
                        TenantHelper.ReportsTenantTypes,
                        () =>
                            <SidebarNavigationItem
                                icon={ReportsIcon}
                                tenantTypes={TenantHelper.ReportsTenantTypes}
                                title={localization.reports()}
                                view={CustomerView.Reports}/>)}
                    {UserHelper.hasAnyScopePermissions(undefined, Contract.IdentityPermission.SecurityAdministrationRead) &&
                        <Fragment>
                            <Divider
                                sx={{
                                    margin: theme.spacing(1),
                                    marginLeft: theme.px(sidebarItemSizeClose * (1 - 0.5) / 2),
                                    marginRight: theme.px(sidebarItemSizeClose * (1 - 0.5) / 2)
                                }}/>
                            {_.some(securityAdministrationReadPermissionExistsTenantsTenantTypes) &&
                                <SidebarNavigationItem
                                    icon={TenantsIcon}
                                    subItems={
                                        _.concat(
                                            hasPermittedProjectScopes
                                                ? new SidebarNavigationItemSubItem(
                                                    <ProjectIcon/>,
                                                    localization.scopes.projects(),
                                                    ScopesView.Project)
                                                : [],
                                            _(securityAdministrationReadPermissionExistsTenantsTenantTypes).
                                                filter(
                                                    tenantsTenantType =>
                                                        tenantsTenantType !== Contract.TenantType.Ci ||
                                                        (LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp))).
                                                orderBy(
                                                    [
                                                        tenantsTenantType => TenantHelper.isCloudProviderTenantType(tenantsTenantType),
                                                        tenantsTenantType => TenantHelper.isIdentityProviderTenantType(tenantsTenantType),
                                                        tenantsTenantType => StringHelper.getSortValue(tenantTypeTranslator(tenantsTenantType))
                                                    ],
                                                    [
                                                        "desc",
                                                        "desc"
                                                    ]).
                                                map(
                                                    tenantsTenantType =>
                                                        new SidebarNavigationItemSubItem(
                                                            <TenantIcon
                                                                colored={true}
                                                                tenantType={tenantsTenantType}/>,
                                                            tenantTypeTranslator(tenantsTenantType),
                                                            tenantsTenantType)).
                                                value())}
                                    title={localization.scopes.title()}
                                    view={CustomerView.Scopes}/>}
                            <SidebarNavigationItem
                                icon={AuditEventsIcon}
                                title={localization.auditEvents()}
                                view={CustomerView.AuditEvents}/>
                        </Fragment>}
                </Fragment>}
            reloadView={reloadView}
            rootRelativeUrl={CustomerConsoleAppUrlHelper.getRootRelativeUrl()}
            setViewRoute={setViewRoute}
            viewRoute={viewRoute}/>);
}