import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../common";

export function useGetCodeTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.licensing.hooks.useGetCustomerLicenseObjectInfo.hooks.useGetCodeTranslator",
            () => ({
                [Contract.CustomerLicenseObjectType.CodeContainerImageRepository]: "Container Image Repositories"
            }));

    return () => ({
        tenantType: Contract.TenantType.Code,
        title: localization[Contract.CustomerLicenseObjectType.CodeContainerImageRepository]()
    });
}