import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AwsIamPolicyDocument } from "../../../components";
import { SubscriptionsInfoCard } from "./components";

export function useAwsSnsTopicDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const topic = resourceModel.entity as Contract.AwsSnsTopic;
    const topicModel = resourceModel as Contract.AwsSnsTopicModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSnsTopicDefinition",
            () => ({
                info: {
                    items: {
                        encryptionExists: "Server-Side Encryption",
                        failedDeliveryRoleIds: "Failed Delivery Roles",
                        name: "Display Name",
                        serverSideEncryptionKey: "Encryption Key",
                        successDelivery: {
                            disabled: "Not enabled",
                            enabled: "Enabled",
                            title: "Delivery Status Logging"
                        },
                        successDeliveryRoleIds: "Delivery Roles",
                        type: {
                            [Contract.TypeNames.AwsSnsTopicType]: {
                                [Contract.AwsSnsTopicType.Fifo]: "Fifo",
                                [Contract.AwsSnsTopicType.Standard]: "Standard"
                            },
                            title: "Topic Type"
                        }
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument policyDocument={topic.policyDocument}/>,
                localization.tabs.policyDocument(),
                "policyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="name"
                        title={localization.info.items.name()}
                        value={
                            _.isNil(topic.name)
                                ? undefined
                                : topic.name}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type.title()}
                        value={localization.info.items.type[Contract.TypeNames.AwsSnsTopicType][topic.type]()}/>,
                    <InfoItem
                        key="successDelivery"
                        title={localization.info.items.successDelivery.title()}
                        value={
                            _.isEmpty(topic.successDeliveryRoleReferences)
                                ? localization.info.items.successDelivery.disabled()
                                : localization.info.items.successDelivery.enabled()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={
                            _.map(
                                topic.successDeliveryRoleReferences,
                                roleReference => roleReference.idReference)}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="successDeliveryRoleReferencse"
                        title={localization.info.items.successDeliveryRoleIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={
                            _.map(
                                topic.failedDeliveryRoleReferences,
                                roleReference => roleReference.idReference)}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="failedDeliveryRoleReferences"
                        title={localization.info.items.failedDeliveryRoleIds()}/>
                ]}
                options={options?.infoOptions}>
                <SubscriptionsInfoCard topicModel={topicModel}/>
            </Info>
    });
}