import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { map, Message, useLocalization } from "@infrastructure";
import { useAddOrEditContext } from "../..";
import { useTheme } from "../../../../../../../../../../../../../../../../common";
import { CloudShell, Manual } from "./components";

export function SinkPubSubSubscriptionItem() {
    const { organizationConfiguration, sinkPubSubSubscriptionEnabled } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.sinkPubSubSubscriptionItem",
            () => ({
                onboardingType: {
                    [OnboardingType.CloudShell]: "gcloud CLI (Cloud Shell)",
                    [OnboardingType.Manual]: "Manual (Google Cloud Console)"
                },
                sinkPubSubSubscriptionDisabled: "You chose not to enable audit logs. If this was a mistake, go back to step 3 (Permissions) and enable it. Otherwise, click **Next** to go to the next step."
            }));

    const onboardingTypes =
        useMemo(
            () =>
                _<OnboardingType>([]).
                    concatIf(
                        _.isNil(organizationConfiguration),
                        OnboardingType.CloudShell).
                    concat(OnboardingType.Manual).
                    value(),
            []);

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(() => _.first(onboardingTypes)!);

    const theme = useTheme();
    return !sinkPubSubSubscriptionEnabled
        ? <Message
            level="success"
            title={localization.sinkPubSubSubscriptionDisabled()}/>
        : <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={selectedOnboardingType}
                variant="standard"
                onChange={(_, value) => setSelectedOnboardingType(value)}>
                {_.map(
                    onboardingTypes,
                    onboardingType =>
                        <Tab
                            key={onboardingType}
                            label={localization.onboardingType[onboardingType]()}
                            sx={{ padding: theme.spacing(1, 2) }}
                            value={onboardingType}/>)}
            </Tabs>
            <Stack
                key={selectedOnboardingType}
                sx={{ overflow: "hidden auto" }}>
                <Box sx={{ maxWidth: theme.spacing(80) }}>
                    {map(
                        selectedOnboardingType,
                        {
                            [OnboardingType.CloudShell]: () => <CloudShell/>,
                            [OnboardingType.Manual]: () => <Manual/>
                        })}
                </Box>
            </Stack>
        </Stack>;
}

enum OnboardingType {
    CloudShell = "cloudShell",
    Manual = "manual"
}