﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function AsiaIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M22 5.00227H2C1.44772 5.00227 1 5.451 1 6.00453V17.9955C1 18.549 1.44772 18.9977 2 18.9977H22C22.5523 18.9977 23 18.549 23 17.9955V6.00454C23 5.451 22.5523 5.00227 22 5.00227ZM2 4H22C23.1046 4 24 4.89746 24 6.00454V17.9955C24 19.1025 23.1046 20 22 20H2C0.89543 20 0 19.1025 0 17.9955V6.00453C0 4.89746 0.895431 4 2 4Z"
                fill={theme.palette.text.secondary}
                fillRule="evenodd"/>
            <path
                d="M6.52486 16.3676H5L7.77632 7.6196H9.53992L12.3201 16.3676H10.7952L8.68892 9.41362H8.62731L6.52486 16.3676ZM6.57492 12.9376H10.7336V14.2105H6.57492V12.9376Z"
                fill={theme.palette.text.primary}/>
            <path
                d="M17.5175 10.0244C17.4816 9.6514 17.3301 9.36094 17.0631 9.15306C16.7987 8.94518 16.4547 8.84124 16.0311 8.84124C15.7334 8.84124 15.4779 8.89108 15.2649 8.99075C15.0518 9.09041 14.8888 9.22568 14.7758 9.39654C14.6629 9.56739 14.6051 9.76246 14.6026 9.98173C14.6026 10.164 14.6398 10.322 14.7142 10.4559C14.7912 10.5897 14.8952 10.7036 15.0261 10.7976C15.1571 10.8887 15.3021 10.9656 15.4613 11.0282C15.6204 11.0909 15.7809 11.1436 15.9426 11.1863L16.6819 11.3913C16.9797 11.4682 17.2659 11.5721 17.5406 11.7031C17.8179 11.8341 18.0656 11.9993 18.2838 12.1986C18.5045 12.398 18.6791 12.6386 18.8075 12.9205C18.9358 13.2024 19 13.5327 19 13.9115C19 14.4241 18.8819 14.8754 18.6457 15.2655C18.4096 15.6528 18.0681 15.9561 17.6215 16.1754C17.1774 16.3918 16.6396 16.5 16.008 16.5C15.3945 16.5 14.8618 16.3946 14.41 16.1839C13.9608 15.9732 13.6091 15.6656 13.3549 15.2613C13.1034 14.8569 12.9673 14.3643 12.9468 13.7833H14.3523C14.3728 14.088 14.4575 14.3415 14.6064 14.5437C14.7553 14.7458 14.9491 14.8968 15.1879 14.9964C15.4292 15.0961 15.6987 15.1459 15.9965 15.1459C16.3071 15.1459 16.5792 15.0947 16.8128 14.9922C17.049 14.8868 17.2338 14.7416 17.3673 14.5565C17.5008 14.3685 17.5688 14.1493 17.5714 13.8987C17.5688 13.6709 17.5085 13.4829 17.3904 13.3348C17.2723 13.1839 17.1068 13.0586 16.8937 12.9589C16.6832 12.8564 16.4368 12.7653 16.1544 12.6856L15.2572 12.4293C14.6077 12.2442 14.0943 11.9637 13.7169 11.5878C13.3421 11.2091 13.1547 10.7065 13.1547 10.08C13.1547 9.56455 13.2805 9.11319 13.5321 8.72591C13.7862 8.33863 14.1315 8.03821 14.5679 7.82463C15.0043 7.60821 15.4985 7.5 16.0504 7.5C16.61 7.5 17.1003 7.60821 17.5214 7.82463C17.9449 8.03821 18.2774 8.33579 18.5187 8.71737C18.76 9.09611 18.8845 9.5318 18.8922 10.0244H17.5175Z"
                fill={theme.palette.text.primary}/>
        </SvgIcon>);
}