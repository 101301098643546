﻿import { DataTableColumn, DeferredFilter, TextValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAzureCommonKeyVaultVaultObjectDefinition } from "./useAzureCommonKeyVaultVaultObjectDefinition";

export function useAzureKeyVaultVaultCertificateDefinition(definitionData: DefinitionData) {
    const commonKeyVaultVaultObjectDefinition = useAzureCommonKeyVaultVaultObjectDefinition(definitionData);
    const certificateModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureKeyVaultVaultCertificateModelFilters>;

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultCertificateDefinition",
            () => ({
                columns: {
                    thumbprint: "Thumbprint"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonKeyVaultVaultObjectDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureKeyVaultVaultCertificateModel) => ({
                            [localization.columns.thumbprint()]:
                            (item.entity as Contract.AzureKeyVaultVaultCertificate).thumbprint
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={certificateModelFiltersPromise}
                                title={localization.columns.thumbprint()}>
                                {filters =>
                                    <TextValuesFilter
                                        emptyValue={filters.thumbprintValueItems.emptyValue}
                                        placeholder={localization.columns.thumbprint()}
                                        values={filters.thumbprintValueItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureKeyVaultVaultCertificateThumbprint}
                itemProperty={(item: Contract.AzureKeyVaultVaultCertificateModel) => (item.entity as Contract.AzureKeyVaultVaultCertificate).thumbprint}
                key={Contract.EntityModelProperty.AzureKeyVaultVaultCertificateThumbprint}
                title={localization.columns.thumbprint()}/>,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectEnabledColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectExpirationColumn,
            commonKeyVaultVaultObjectDefinition.columns.creationTimeColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectVaultColumn,
            commonKeyVaultVaultObjectDefinition.columns.creatorIdentityCsvColumn,
            commonKeyVaultVaultObjectDefinition.columns.regionColumn,
            commonKeyVaultVaultObjectDefinition.columns.regionLocationColumn,
            commonKeyVaultVaultObjectDefinition.columns.resourceGroupColumn,
            commonKeyVaultVaultObjectDefinition.columns.tagsColumn,
            commonKeyVaultVaultObjectDefinition.columns.attributesColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureKeyVaultVaultCertificateRequest(
                new Contract.EntityControllerGetEntityModelPageAzureKeyVaultVaultCertificateRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    undefined,
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectEnabled]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectExpirationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectVault]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultCertificateThumbprint])
                ),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}