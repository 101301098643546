﻿import { Link, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InlineEntities, RegionModelHelper, tenantModelStore, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder } from "../../../../../../../../../../../../../../tenants";

export function useGetResolutionSteps() {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useGetResolutionSteps",
            () => ({
                cloudWatchMetadataNoTokenMetricLink: "CloudWatch metric MetadataNoToken",
                documentationLink: "Track your transition progress",
                metadataServiceVersionV1:
                    {
                        notUsed: {
                            step1: "Tenable Cloud Security's recommendation to transition to IMDSv2 is based on 1 month of usage. If the resource was created more recently than that, you'll need to wait, or verify that IMDSv1 wasn't used in that time period",
                            step2: {
                                resource: "{{cloudWatchMetadataNoTokenMetricLink}} shows no usage of IMDSv1 and therefore the {{resourceDisplayName}} is ready to be fully transitioned to use IMDSv2",
                                resourceGroup: "The CloudWatch metric MetadataNoToken shows no usage of IMDSv1 and therefore the {{resourceDisplayName}} is ready to be fully transitioned to use IMDSv2. For more information see: **{{documentationLink}}**"
                            }
                        },
                        used: {
                            step1: "Update the SDKs, CLIs, and any software on the {{instances}} to the latest versions compatible with IMDSv2 in order to use v2 only",
                            step2: {
                                resource: "Before you can transition to IMDSv2, Tenable recommends that you verify that IMDSv1 has not been in use for at least one month.\nThe CloudWatch metric shows that IMDSv1 was last used on **{{latestInstanceMetadataServiceVersionV1UsageTime}}**. Track your transition progression using the **{{cloudWatchMetadataNoTokenMetricLink}}**",
                                resourceGroup: "Before you can transition to IMDSv2, Tenable recommends that you verify that IMDSv1 has not been in use for at least one month.\nThe CloudWatch metric shows that IMDSv1 was last used on **{{latestInstanceMetadataServiceVersionV1UsageTime}}**. For more information see: **{{documentationLink}}**"
                            }
                        }
                    }
            }));


    function getCloudWatchMetadataNoTokenMetricUrl(
        instanceModels: Contract.EntityModel[],
        partitionType: Contract.AwsPartitionType,
        resourceModel: Contract.AwsResourceModel,
        riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel) {
        return AwsConsoleUrlBuilder.getCloudWatchMetadataNoTokenMetricUrl(
            instanceModels.
                filter(instanceModel => !instanceModel.unknown).
                map(instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).rawId).
                join(riskModel.cloudWatchMetadataNoTokenMetricUrlInstanceRawIdsParameterDelimiter),
            partitionType,
            RegionModelHelper.get(resourceModel.entity.regionId!).systemName);
    }

    return (entityTypeName: string, riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel): string[] => {
        const instanceModels = entityModelStore.useGet(riskModel.risk.aggregatedEntityIds);
        const resourceModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsResourceModel;
        const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

        const resolutionSteps = [] as string[];
        if (!riskModel.risk.instanceMetadataServiceVersionV1Used) {
            resolutionSteps.push(localization.metadataServiceVersionV1.notUsed.step1());

            if (TypeHelper.extendOrImplement(entityTypeName, Contract.TypeNames.AwsResourceGroup)) {
                resolutionSteps.push(
                    localization.metadataServiceVersionV1.notUsed.step2.resourceGroup({
                        documentationLink:
                            <Link
                                sx={{ textDecorationLine: "underline" }}
                                urlOrGetUrl={riskModel.instanceMetadataDocumentationUrl}
                                variant="external">
                                {localization.documentationLink()}
                            </Link>,
                        resourceDisplayName: entityTypeNameTranslator(
                            entityTypeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                    }));
            } else {
                resolutionSteps.push(
                    localization.metadataServiceVersionV1.notUsed.step2.resource({
                        cloudWatchMetadataNoTokenMetricLink:
                            <Link
                                sx={{ textDecorationLine: "underline" }}
                                urlOrGetUrl={
                                    getCloudWatchMetadataNoTokenMetricUrl(
                                        _.take(instanceModels, 100),
                                        tenantConfiguration.partitionType,
                                        resourceModel,
                                        riskModel)
                                }
                                variant="external">
                                {localization.cloudWatchMetadataNoTokenMetricLink()}
                            </Link>,
                        resourceDisplayName: entityTypeNameTranslator(
                            entityTypeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                    }));
            }
        } else {
            resolutionSteps.push(
                localization.metadataServiceVersionV1.used.step1({
                    instances:
                        <InlineEntities
                            entityIdsOrModels={
                                _.filter(
                                    instanceModels,
                                    instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).metadataServiceVersionV1Used)}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            variant="itemCountAndType"/>
                }));

            if (TypeHelper.extendOrImplement(entityTypeName, Contract.TypeNames.AwsResourceGroup)) {
                resolutionSteps.push(
                    localization.metadataServiceVersionV1.used.step2.resourceGroup({
                        documentationLink:
                            <Link
                                sx={{ textDecorationLine: "underline" }}
                                urlOrGetUrl={riskModel.instanceMetadataDocumentationUrl}
                                variant="external">
                                {localization.documentationLink()}
                            </Link>,
                        latestInstanceMetadataServiceVersionV1UsageTime: TimeFormatter.longDateTime(riskModel.risk.latestInstanceMetadataServiceVersionV1UsageTime)
                    }));
            } else {
                resolutionSteps.push(
                    localization.metadataServiceVersionV1.used.step2.resource({
                        cloudWatchMetadataNoTokenMetricLink:
                            <Link
                                sx={{ textDecorationLine: "underline" }}
                                urlOrGetUrl={
                                    getCloudWatchMetadataNoTokenMetricUrl(
                                        _.take(instanceModels, 100),
                                        tenantConfiguration.partitionType,
                                        resourceModel,
                                        riskModel)
                                }
                                variant="external">
                                {localization.cloudWatchMetadataNoTokenMetricLink()}
                            </Link>,
                        latestInstanceMetadataServiceVersionV1UsageTime: TimeFormatter.longDateTime(riskModel.risk.latestInstanceMetadataServiceVersionV1UsageTime)
                    }));
            }
        }

        return resolutionSteps;
    };
}