﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureLogicWorkflowRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureLogicWorkflowInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const workflowInboundExternalWideRangeRiskModel = riskModel as Contract.AzureLogicWorkflowInboundExternalWideRangeRiskModel;
    const risk = riskModel.risk as Contract.AzureLogicWorkflowInboundExternalWideRangeRisk;
    const workflowModel = entityModelStore.useGet(risk.entityId) as Contract.AzureLogicWorkflowModel;

    const getAzureLogicWorkflowRiskContext = useGetAzureLogicWorkflowRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureLogicWorkflowInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{workflow}} allows inbound network access from a wide range of public IP addresses",
                sections: {
                    resolution: {
                        step1: "Click **Workflow settings**",
                        step2: {
                            link: "Azure documentation",
                            text: "In the **Allowed inbound IP addresses** section, select **Specific IP ranges** or **Only other Logic Apps**. Read more on all scenarios on {{restrictInboundIPAddressesDocumentationLink}}"
                        },
                        step3: "In case **Specific IP ranges** is selected, restrict access to the IP address ranges that the trigger should accept",
                        step4: "Click **Save**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            workflow:
                <Entity
                    entityIdOrModel={workflowModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                restrictInboundIPAddressesDocumentationLink:
                    <Link
                        urlOrGetUrl={workflowInboundExternalWideRangeRiskModel.restrictInboundIpAddressesDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()],
        riskModel,
        () => {
            const workflowRiskContext = getAzureLogicWorkflowRiskContext(workflowModel);
            return [
                workflowRiskContext.generalInformation,
                workflowRiskContext.sensitive,
                workflowRiskContext.status,
                workflowRiskContext.version,
                workflowRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}