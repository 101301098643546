import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsFirehoseDeliveryStreamRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsFirehoseDeliveryStreamEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsFirehoseDeliveryStreamEncryptionDisabledRisk;
    const deliveryStreamModel = entityModelStore.useGet(risk.entityId) as Contract.AwsFirehoseDeliveryStreamModel;

    const getAwsFirehoseDeliveryStreamRiskContext = useGetAwsFirehoseDeliveryStreamRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsFirehoseDeliveryStreamEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{stream}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Click the **Configuration** tab",
                        step2: "Under the **Server-side encryption (SSE)** section, click **Edit**",
                        step3: "Select **Enable server-side encryption for source records in Firehose stream**",
                        step4: "Choose between **Use AWS owned key** and **Use customer managed key**",
                        step5: "Before saving the changes, make sure the relevant identities have the right permissions to the KMS key used to encrypt the Data Firehose stream",
                        step6: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            stream:
                <Entity
                    entityIdOrModel={deliveryStreamModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Firehose,
                AwsConsoleUrlBuilder.getFirehoseDeliveryStreamUrl(deliveryStreamModel.entity as Contract.AwsFirehoseDeliveryStream)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const deliveryStreamRiskContext = getAwsFirehoseDeliveryStreamRiskContext(deliveryStreamModel);
            return [
                deliveryStreamRiskContext.generalInformation,
                deliveryStreamRiskContext.status,
                deliveryStreamRiskContext.sensitive,
                deliveryStreamRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}