import { map, useLocalization } from "@infrastructure";
import React from "react";
import { useGetAwsDocDbClusterRiskContext } from "../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRetentionTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRetentionTimeFrameContextItem";

export function useAwsDocDbClusterBackupRetentionTimeFrameRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsDocDbClusterBackupRetentionTimeFrameRisk;
    const resourceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsResourceModel;

    const getAwsDocDbClusterRiskContext = useGetAwsDocDbClusterRiskContext();
    const getRiskPolicyConfigurationRetentionTimeFrameContextItem = useGetRiskPolicyConfigurationRetentionTimeFrameContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsDocDbClusterBackupRetentionTimeFrameRiskDefinition",
            () => ({
                description: "{{docDbCluster}} backup retention period is less than {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Backup** section and set the **Backup retention period** to at least {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame,
            docDbCluster:
                <Entity
                    entityIdOrModel={resourceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.DocDb,
                map(
                    resourceModel.entity.typeName, {
                        [Contract.TypeNames.AwsDocDbElasticCluster]: () => AwsConsoleUrlBuilder.getDocDbElasticClusterUrl(resourceModel.entity as Contract.AwsDocDbElasticCluster),
                        [Contract.TypeNames.AwsRdsDocDbCluster]: () => AwsConsoleUrlBuilder.getRdsDocDbClusterUrl(resourceModel.entity as Contract.AwsRdsDocDbCluster)
                    })),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({ configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            {
                const docDbClusterRiskContext = getAwsDocDbClusterRiskContext(resourceModel);
                return [
                    docDbClusterRiskContext.generalInformation,
                    getRiskPolicyConfigurationRetentionTimeFrameContextItem(risk.backupRetentionTimeFrame),
                    docDbClusterRiskContext.backupRetentionTimeFrame,
                    docDbClusterRiskContext.size,
                    docDbClusterRiskContext.instances,
                    docDbClusterRiskContext.sensitive,
                    docDbClusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
                ];
            }
        });
}