import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { ActionSelector, ActionSelectorSelection, EntitySelector, EntitySelectorPermissionEvaluationEntities, EntitySelectorSelection, InlineActionsSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const { scopeId } = useCustomRiskPolicyContext();
    const [actionsValid, setActionsValid] = useState(false);
    const [principalsValid, setPrincipalsValid] = useState(false);
    const [resourcesValid, setResourcesValid] = useState(false);
    const [view, setView] = useState<EditView | undefined>(EditView.Principals);

    const [actionSelectorSelection, setActionSelectorSelection] =
        useState<ActionSelectorSelection | undefined>(
            ActionSelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.actions,
                riskPolicyConfiguration?.actionRiskCategories,
                riskPolicyConfiguration?.actionNamePatterns));
    const [principalSelectorSelection, setPrincipalSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                undefined,
                undefined,
                undefined,
                riskPolicyConfiguration?.principalBuiltInAttributeTypeNames,
                undefined,
                undefined,
                undefined,
                riskPolicyConfiguration?.principalIds,
                undefined,
                undefined,
                undefined,
                undefined));
    const [resourceSelectorSelection, setResourceSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                undefined,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.resourceIds,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceTags,
                undefined));
    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { actionNamePatterns, actionRiskCategories, actions } = actionSelectorSelection!.getInlineSelectionData();
                    const { entityBuiltInAttributeTypeNames: principalBuiltInAttributeTypeNames, entityIds: principalIds } = principalSelectorSelection!.getInlineSelectionData();
                    const { entityBuiltInAttributeTypeNames: resourceBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds: resourceCustomAttributeDefinitionIds, entityIds: resourceIds, entityTags: resourceTags } = resourceSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAzurePrincipalAllowedResourcePermissionRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        actionNamePatterns,
                        actionRiskCategories,
                        actions,
                        principalBuiltInAttributeTypeNames,
                        principalIds,
                        resourceBuiltInAttributeTypeNames,
                        resourceCustomAttributeDefinitionIds,
                        resourceIds,
                        resourceTags);
                }
        });

    useEffect(
        () => {
            const actionsValid = actionSelectorSelection?.valid() ?? false;
            const principalsValid = principalSelectorSelection?.valid() ?? false;
            const resourcesValid = resourceSelectorSelection?.valid() ?? false;
            setActionsValid(actionsValid);
            setPrincipalsValid(principalsValid);
            setResourcesValid(resourcesValid);
            onValidChange(actionsValid && principalsValid && resourcesValid);
        },
        [actionSelectorSelection, principalSelectorSelection, resourceSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.azurePrincipalAllowedResourcePermissionRiskPolicy.edit",
            () => ({
                actions: "Permissions",
                prompt: "Select the required principals, permissions and resources"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        actions: <InlineActionsSelection selection={actionSelectorSelection}/>,
                        principals:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                selection={principalSelectorSelection}/>,
                        resources:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.AzureResource}
                                selection={resourceSelectorSelection}/>
                    })}
                </Typography>
                <Box>
                    <Accordion
                        expanded={view === EditView.Principals}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Principals
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AadDirectoryPrincipal,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {principalsValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.AadDirectoryUserExternalAttribute,
                                    Contract.TypeNames.AadDirectoryUserGuestAttribute,
                                    Contract.TypeNames.AdministratorPrincipalAttribute,
                                    Contract.TypeNames.MfaDisabledUserAttribute,
                                    Contract.TypeNames.RelatedPublicComputeAttribute,
                                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.VendorServiceIdentityAttribute,
                                    Contract.TypeNames.CodeResourceAttribute
                                ]}
                                directoryEntityType={true}
                                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                includeServiceName={true}
                                properties={["attributes"]}
                                scopeId={scopeId}
                                selection={principalSelectorSelection}
                                onSelectionChanged={setPrincipalSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.Actions}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Actions
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {localization.actions()}
                                </Typography>
                                {actionsValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ActionSelector
                                selection={actionSelectorSelection}
                                onSelectionChanged={setActionSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.Resources}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Resources
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AzureResource,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {resourcesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.PublicEntityAttribute,
                                    Contract.TypeNames.RelatedPublicComputeAttribute,
                                    Contract.TypeNames.SensitiveResourceAttribute,
                                    Contract.TypeNames.CodeResourceAttribute
                                ]}
                                permissionEvaluationEntities={EntitySelectorPermissionEvaluationEntities.Azure}
                                properties={["tags", "attributes"]}
                                scopeId={scopeId}
                                selection={resourceSelectorSelection}
                                onSelectionChanged={setResourceSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}

enum EditView {
    Actions = "actions",
    Principals = "principals",
    Resources = "resources"
}