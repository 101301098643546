export enum DeliveryDerivedTypeNames {
    DatadogDelivery = "DatadogDelivery",
    JiraDelivery = "JiraDelivery",
    MailDelivery = "MailDelivery",
    QRadarDelivery = "QRadarDelivery",
    ResourceOwnerMailDelivery = "ResourceOwnerMailDelivery",
    ServiceNowDelivery = "ServiceNowDelivery",
    SlackDelivery = "SlackDelivery",
    SplunkDelivery = "SplunkDelivery",
    SqsDelivery = "SqsDelivery",
    TeamsDelivery = "TeamsDelivery",
    WebhookDelivery = "WebhookDelivery"
}

export enum PermissionRequestAuditEventDerivedTypeNames {
    PermissionRequestActivationAuditEvent = "PermissionRequestActivationAuditEvent",
    PermissionRequestActivationFailureAuditEvent = "PermissionRequestActivationFailureAuditEvent",
    PermissionRequestApprovalAuditEvent = "PermissionRequestApprovalAuditEvent",
    PermissionRequestCancellationAuditEvent = "PermissionRequestCancellationAuditEvent",
    PermissionRequestCreationAuditEvent = "PermissionRequestCreationAuditEvent",
    PermissionRequestExpirationAuditEvent = "PermissionRequestExpirationAuditEvent",
    PermissionRequestRejectionAuditEvent = "PermissionRequestRejectionAuditEvent",
    PermissionRequestRequestExpirationAuditEvent = "PermissionRequestRequestExpirationAuditEvent",
    PermissionRequestRevocationAuditEvent = "PermissionRequestRevocationAuditEvent"
}

export enum ScopeSettingConfigurationDerivedTypeNames {
    AadSeverePermissionDirectoryRoleDefinitionsConfiguration = "AadSeverePermissionDirectoryRoleDefinitionsConfiguration",
    ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration = "ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration",
    UserExcessivePermissionEvaluationTimeFrameConfiguration = "UserExcessivePermissionEvaluationTimeFrameConfiguration"
}