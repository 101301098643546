import { Checkbox, Sx, useDataTableActionsContext, useDataTableSelectionContext } from "@infrastructure";
import { SxProps } from "@mui/material";
import _ from "lodash";
import React from "react";

export type DataTableSelectionCellProps = {
    disabled?: boolean;
    itemId: string;
    sx?: SxProps;
};

export function DataTableSelectionCell({ disabled = false, itemId, sx }: DataTableSelectionCellProps) {
    const { actions } = useDataTableActionsContext();
    const { selectedItemIds } = useDataTableSelectionContext();
    return (
        <Checkbox
            checked={_.includes(selectedItemIds, itemId)}
            disabled={disabled}
            sx={
                Sx.combine(
                    {
                        fontSize: "24px",
                        opacity:
                            disabled
                                ? 0.4
                                : 1
                    },
                    sx)}
            onChange={
                () =>
                    actions.setSelectedItemIds(
                        _.includes(selectedItemIds, itemId)
                            ? _.without(selectedItemIds, itemId)
                            : _.concat(selectedItemIds, itemId))}/>);
}