import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsElastiCacheCacheAutoFailoverTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsElastiCacheCacheAutoFailoverTranslator",
            () => ({
                [Contract.TypeNames.AwsElastiCacheCacheAutoFailover]: {
                    [Contract.AwsElastiCacheCacheAutoFailover.Disabled]: "Disabled",
                    [Contract.AwsElastiCacheCacheAutoFailover.Disabling]: "Disabling",
                    [Contract.AwsElastiCacheCacheAutoFailover.Enabled]: "Enabled",
                    [Contract.AwsElastiCacheCacheAutoFailover.Enabling]: "Enabling"
                }
            }));
    return (autoFailover: Contract.AwsElastiCacheCacheAutoFailover) =>
        localization[Contract.TypeNames.AwsElastiCacheCacheAutoFailover][autoFailover]();
}