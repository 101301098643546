import { CopyToClipboardTextField, Link, Step, Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { useAddOrEditContext } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, LicensingHelper, useTenantTypeTranslator, useTheme } from "../../../../../../../../../../../../../../../../common";
import { InstructionsImageUrl } from "./images";

export function PermissionsItem() {
    const { tenantOnboardingInfo } = useAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.gciTenants.addOrEdit.permissionsItem",
            () => ({
                steps: {
                    lastStep: "Click **AUTHORIZE**",
                    permissionManagementStep: {
                        fields: {
                            serviceAccountAuthorizationScopes: "JIT OAuth scope"
                        },
                        title: "Optional - If you would like to leverage Just-in-Time (JIT) to temporarily add users to {{translatedTenantType}} groups of this organization, add the following **OAuth scope**"
                    },
                    step1: "In the newly created service account, go to the **DETAILS** tab and expand **SHOW ADVANCED SETTINGS**",
                    step2: "Copy the **Client ID** to the clipboard and click **VIEW GOOGLE WORKSPACE ADMIN CONSOLE**",
                    step3: "In the admin console, navigate to **Security --> Access and data control --> API controls**",
                    step4: "Click on **MANAGE DOMAIN WIDE DELEGATION** and then **Add New**",
                    step5: "Paste the copied **Client ID**",
                    step6: {
                        fields: {
                            serviceAccountAuthorizationScopes: "OAuth scopes"
                        },
                        title: "Copy and paste the following **OAuth scopes**"
                    }
                },
                title: {
                    documentationLink: "documentation",
                    text: "Domain-wide delegation lets Tenable impersonate any Google Workspace user. The actual permissions granted to Tenable are restricted to the specific OAuth scopes defined during the configuration of domain-wide delegation: These permissions only grant Tenable read access to Google Workspace. For more information, refer to {{documentationLink}}."
                }
            }));
    const tenantTypeTranslator = useTenantTypeTranslator();
    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: theme.spacing(80) }}>
            <Typography>
                {localization.title.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsAddAGoogleWorkspaceDomainWideDelegationRelativeUrl()}
                            variant="external">
                            {localization.title.documentationLink()}
                        </Link>
                })}
            </Typography>
            <Steps variant="letters">
                {_.filter(
                    [
                        localization.steps.step1(),
                        localization.steps.step2(),
                        localization.steps.step3(),
                        localization.steps.step4(),
                        localization.steps.step5(),
                        new Step(
                            localization.steps.step6.title(),
                            {
                                contentElement:
                                    <CopyToClipboardTextField
                                        getValue={() => _.join(tenantOnboardingInfo.serviceAccountAuthorizationScopes, ",")}
                                        multiline={true}
                                        title={localization.steps.step6.fields.serviceAccountAuthorizationScopes()}
                                        value={_.join(tenantOnboardingInfo.serviceAccountAuthorizationScopes, "\n")}/>
                            }),
                        LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement)
                            ? new Step(
                                localization.steps.permissionManagementStep.title({ translatedTenantType: tenantTypeTranslator(Contract.TenantType.Gci) }),
                                {
                                    contentElement:
                                        <CopyToClipboardTextField
                                            getValue={() => _.join(tenantOnboardingInfo.serviceAccountPermissionManagementAuthorizationScopes, ",")}
                                            multiline={true}
                                            title={localization.steps.permissionManagementStep.fields.serviceAccountAuthorizationScopes()}
                                            value={_.join(tenantOnboardingInfo.serviceAccountPermissionManagementAuthorizationScopes, "\n")}/>
                                })
                            : undefined,
                        localization.steps.lastStep()])}
            </Steps>
        </Stack>);
}