﻿import { DataTableColumn, useExecuteOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntityTypeFilter, ItemSelectionHelper, RiskController, RiskType } from "../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../useCommonCloudRiskPolicyTableDefinition";

export function useAwsEc2InstanceMetadataServiceVersionRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const [{ filters }] =
        useExecuteOperation(
            [useAwsEc2InstanceMetadataServiceVersionRiskDefinition, riskPolicyConfigurationTypeName],
            () =>
                RiskController.getRiskPolicyTypeGroupFilters(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest(
                        requestFilters!,
                        riskPolicyConfigurationTypeName)));
    const awsEc2InstanceMetadataServiceVersionRiskPolicyGroupFilters = filters as Contract.AwsEc2InstanceMetadataServiceVersionRiskPolicyGroupFilters;

    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useComputeDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskDefinition",
            () => ({
                columns: {
                    instanceMetadataServiceVersionV1Used: {
                        false: "Not used",
                        title: "IMDSv1 Used in Last Month",
                        true: "Used"
                    },
                    riskedEntityTypeName: "Service"
                }
            }));

    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonColumns.riskPolicyIdColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <ValuesFilter
                                placeholder={localization.columns.instanceMetadataServiceVersionV1Used.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.instanceMetadataServiceVersionV1Used.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.instanceMetadataServiceVersionV1Used.true()}
                                    value={true}/>
                            </ValuesFilter>
                        }
                    }}
                    id={TableColumnId.AwsEc2InstanceMetadataServiceVersionV1Used}
                    itemProperty={
                        (item: Contract.RiskModel) =>
                            (item.risk as Contract.AwsEc2InstanceMetadataServiceVersionRisk).instanceMetadataServiceVersionV1Used
                                ? localization.columns.instanceMetadataServiceVersionV1Used.true()
                                : localization.columns.instanceMetadataServiceVersionV1Used.false()}
                    key={TableColumnId.AwsEc2InstanceMetadataServiceVersionV1Used}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.instanceMetadataServiceVersionV1Used.title()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <EntityTypeFilter
                                emptyValue={awsEc2InstanceMetadataServiceVersionRiskPolicyGroupFilters.riskedEntityTypeNameItems.emptyValue}
                                entityTypeNames={awsEc2InstanceMetadataServiceVersionRiskPolicyGroupFilters.riskedEntityTypeNameItems.items}
                                placeholder={localization.columns.riskedEntityTypeName()}/>
                        }
                    }}
                    id={TableColumnId.AwsEc2InstanceMetadataServiceVersionRiskRiskedEntityTypeName}
                    key={TableColumnId.AwsEc2InstanceMetadataServiceVersionRiskRiskedEntityTypeName}
                    title={localization.columns.riskedEntityTypeName()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerAwsEc2InstanceMetadataServiceVersionRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[TableColumnId.AwsEc2InstanceMetadataServiceVersionV1Used]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsEc2InstanceMetadataServiceVersionRiskRiskedEntityTypeName])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}