import { CheckButton, SearchTextField, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../common";

type FiltersProps = {
    childScopeEnabled: boolean;
    includeEmptyItems: boolean;
    onChildScopeEnabledChanged(childScopeEnabled: boolean): void;
    onIncludeEmptyItemsChanged(configured: boolean): void;
    onSearchTextChanged(text: string): void;
    searchText: string;
};

export function Filters({ childScopeEnabled, includeEmptyItems, onChildScopeEnabledChanged, onIncludeEmptyItemsChanged, onSearchTextChanged, searchText }: FiltersProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.filters.filters",
            () => ({
                childScopeEnabled: "Flat View",
                empty: "No integrations found",
                includeEmptyItems: "Show Configured",
                placeholder: "Search"
            }));

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                alignItems: "center",
                padding: theme.spacing(0, 3)
            }}>
            <Stack
                spacing={1}
                sx={{
                    alignContent: "space-between",
                    flexFlow: "column wrap",
                    height: theme.spacing(10),
                    justifyContent: "center",
                    paddingRight: theme.spacing(1),
                    width: "100%"
                }}>
                <Box>
                    <SearchTextField
                        placeholder={localization.placeholder()}
                        searchText={searchText}
                        variant="underlined"
                        onSearchTextChanged={searchText => onSearchTextChanged(searchText || "")}/>
                </Box>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    alignItems: "center",
                    justifyContent: "end",
                    width: "100%"
                }}>
                <CheckButton
                    checked={childScopeEnabled}
                    title={localization.childScopeEnabled()}
                    onCheckedChanged={onChildScopeEnabledChanged}/>
                <CheckButton
                    checked={!includeEmptyItems}
                    title={localization.includeEmptyItems()}
                    onCheckedChanged={() => onIncludeEmptyItemsChanged(!includeEmptyItems)}/>
            </Stack>
        </Stack>);
}