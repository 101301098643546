import { DataTableColumn, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../common";
import { TableColumnId } from "../../../IdentityProviderTenantTable";
import { TenantDefinition } from "../useDefinition";
import { useCommonColumns } from "./useCommonColumns";

export function useGciDefinition(): TenantDefinition {
    const { permissionsColumn } = useCommonColumns();
    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.identityProviderTenantTable.hooks.useDefinition.hooks.useGciDefinition",
            () => ({
                columns: {
                    rawId: "ID"
                }
            }));

    return new TenantDefinition([
        <DataTableColumn
            id={TableColumnId.GciRawId}
            itemProperty={(item: Contract.GciTenantModel) => item.configuration.rawId}
            key={TableColumnId.GciRawId}
            title={localization.columns.rawId()}/>,
        permissionsColumn
    ]);
}