﻿import _ from "lodash";
import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import { Contract, ElasticsearchItemPageHelper, Entity, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../common";
import { DefinitionData } from "../../../Table";

export function useCommonKubernetesResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonKubernetesResourceDefinition",
            () => ({
                columns: {
                    cluster: "Cluster"
                }
            }));

    return {
        columns: {
            clusterColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IKubernetesResourceModel>(
                            Contract.TypeNames.IKubernetesCluster,
                            item => [_.as<Contract.IKubernetesResource>(item.entity).clusterId],
                            localization.columns.cluster())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.KubernetesResourceCluster)}
                                    placeholder={localization.columns.cluster()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesResourceCluster}
                    key={Contract.EntityModelProperty.KubernetesResourceCluster}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IKubernetesResourceModel>) =>
                            <Entity
                                entityIdOrModel={_.as<Contract.IKubernetesResource>(item.entity).clusterId}
                                variant="iconText"/>}
                    title={localization.columns.cluster()}/>
        }
    };
}