﻿import _, { Dictionary } from "lodash";
import { getTypeMetadata, TypeMetadata } from "../controllers/typeMetadata.generated";

export class TypeMetadataHelper {
    private static typeNameToMetadataMap: Dictionary<TypeMetadata> = {};

    public static getTypeMetadata(objectOrTypeOrTypeName: any) {
        if (_.isFunction(objectOrTypeOrTypeName)) {
            return getTypeMetadata(objectOrTypeOrTypeName);
        }

        if (!_.isString(objectOrTypeOrTypeName)) {
            return getTypeMetadata(objectOrTypeOrTypeName.constructor);
        }

        if (!_.isNil(this.typeNameToMetadataMap[objectOrTypeOrTypeName])) {
            return this.typeNameToMetadataMap[objectOrTypeOrTypeName];
        }

        this.typeNameToMetadataMap[objectOrTypeOrTypeName] = getTypeMetadata(objectOrTypeOrTypeName);
        return this.typeNameToMetadataMap[objectOrTypeOrTypeName];
    }
}