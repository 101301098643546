import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ArrowUpIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M4.92888 10.1569L11.2928 3.79289C11.6834 3.40237 12.3165 3.40237 12.7071 3.79289L19.071 10.1569C19.4615 10.5474 19.4615 11.1805 19.071 11.5711C18.6805 11.9616 18.0473 11.9616 17.6568 11.5711L12.9999 6.91421L12.9999 19.5C12.9999 20.0523 12.5522 20.5 11.9999 20.5C11.4477 20.5 10.9999 20.0523 10.9999 19.5L10.9999 6.91421L6.34309 11.5711C5.95257 11.9616 5.31941 11.9616 4.92888 11.5711C4.53836 11.1805 4.53836 10.5474 4.92888 10.1569Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}