﻿import { useMemo } from "react";
import { useOpResourceDefinition, useOpUnmanagedKubernetesClusterDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionOptions } from "../../useDefinition";

export function useOpEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.OpUnmanagedKubernetesCluster:
                        return useOpUnmanagedKubernetesClusterDefinition;
                    default:
                        return useOpResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.OpEntityModel, options);
}