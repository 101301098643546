﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetKubernetesResourceRiskContext } from "../contexts";

export function useKubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRisk;
    const namespaceModel = entityModelStore.useGet(risk.entityId);

    const getKubernetesResourceRiskContext = useGetKubernetesResourceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.resources.namespaces.useKubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskDefinition",
            () => ({
                contextItems: {
                    clusterRoleBindings: "The namespace default service account is used in {{clusterRoleBindings}}",
                    namespaceRoleBindings: "The namespace default service account is used in {{namespaceRoleBindings}}"
                },
                description: "{{kubernetesNamespace}} default service account has a role binding attached",
                resolutionSection: {
                    step1: "Create explicit service accounts wherever a Kubernetes workload requires specific access to the Kubernetes API server",
                    step2: "Remove the namespace default service account from the following {{clusterRoleBindings}}",
                    step3: "Remove the namespace default service account from the following {{namespaceRoleBindings}}",
                    step4: "Modify the configuration of the namespace default service account to include the value **automountServiceAccountToken: false**"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            kubernetesNamespace:
                <Entity
                    entityIdOrModel={namespaceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            _<string>([]).
                concat(localization.resolutionSection.step1()).
                concatIf(
                    !_.isEmpty(risk.clusterRoleBindingIds),
                    () =>
                        localization.resolutionSection.step2({
                            clusterRoleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.clusterRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesClusterRoleBinding}
                                    variant="itemCountAndType"/>
                        })).
                concatIf(
                    !_.isEmpty(risk.namespaceRoleBindingIds),
                    () =>
                        localization.resolutionSection.step3({
                            namespaceRoleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.namespaceRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNamespaceRoleBinding}
                                    variant="itemCountAndType"/>
                        })).
                concat(localization.resolutionSection.step4()).
                value(),
        riskModel,
        () => {
            const namespaceRiskContext = getKubernetesResourceRiskContext(namespaceModel);
            return [
                namespaceRiskContext.generalInformation,
                namespaceRiskContext.sensitive,
                namespaceRiskContext.relatedResources,
                _.isEmpty(risk.clusterRoleBindingIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.clusterRoleBindings({
                            clusterRoleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.clusterRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesClusterRoleBinding}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(risk.namespaceRoleBindingIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.namespaceRoleBindings({
                            namespaceRoleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.namespaceRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNamespaceRoleBinding}
                                    variant="itemCountAndType"/>
                        })),
                namespaceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}