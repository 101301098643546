import { DeferredFilter, Filter, Optional, PagedValuesFilterValuePage, TimeRangeFilter, useChangeEffect, useLocalization, useOperation, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { Contract, EntityPropertyHelper, PagedEntityFilter, PagedEntityFilterEntityIdPage, RiskController, ScopeTenantFilter, tenantModelStore, TimeRangeHelper, useRiskSubStatusTranslator } from "../../../../../../../../../common";
import { RisksItemsFilterId } from "../../../Filters";
import { useCommonFilters } from "./useCommonFilters";

export function useCloudItems(risksView: Contract.RisksView, relatedEntityId?: string) {
    const [viewFiltersPromise, executeGetViewFilters] =
        useOperation<Contract.RiskFilters>(
            useCloudItems,
            async () => {
                const { filters } = await RiskController.getRiskFilters(new Contract.RiskControllerGetCloudRiskFiltersRequest(risksView, relatedEntityId));
                return filters;
            });
    const commonFilters =
        useCommonFilters(
            risksView,
            viewFiltersPromise);

    useChangeEffect(
        () => {
            executeGetViewFilters();
        },
        [risksView, relatedEntityId]);

    const getRiskFilterItemPage =
        useCallback(
            async (type: Contract.CloudRiskFilterItemType, searchText: Optional<string>, skip: number, limit: number, data: RisksFilterItemPageData) => {
                const { riskFilterItemPage } =
                    await RiskController.getRiskFilterItemPage(
                        new Contract.RiskControllerGetCloudRiskFilterItemPageRequest(
                            limit,
                            searchText,
                            skip,
                            risksView,
                            relatedEntityId,
                            type)) as Contract.RiskControllerGetRiskFilterItemPageResponse<any>;

                const riskFilterItemCount = riskFilterItemPage.count ?? data.count;

                return new PagedValuesFilterValuePage(
                    riskFilterItemCount,
                    riskFilterItemPage.emptyValue,
                    riskFilterItemPage.items,
                    () => ({
                        count: riskFilterItemCount,
                        itemNextPageSearchCursor: riskFilterItemPage.itemNextPageSearchCursor
                    }));
            },
            [relatedEntityId, risksView]);

    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants();
    const isResourceEnvironmentConfigured = EntityPropertyHelper.isResourceEnvironmentConfigured(filteredActiveTenantModels);
    const isResourceOwnerConfigured = EntityPropertyHelper.isResourceOwnerConfigured(filteredActiveTenantModels);

    const riskSubStatusTranslator = useRiskSubStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.filters.hooks.useItems.hooks.useCloudItems",
            () => ({
                filters: {
                    ignoreExpirationDate: "Ignored Until",
                    riskedEntityEnvironment: "Resource Environment",
                    riskedEntityIds: "Resource",
                    riskedEntityOwner: "Resource Owner",
                    subStatus: "Sub-Status",
                    tenantIds: "Accounts"
                }
            }));

    return useMemo(
        () =>
            _(
                [
                    commonFilters.statusUpdateTime,
                    <Filter
                        default={_.isNil(relatedEntityId)}
                        id={RisksItemsFilterId[Contract.RiskFilterId.TenantId]}
                        key={RisksItemsFilterId[Contract.RiskFilterId.TenantId]}
                        title={localization.filters.tenantIds()}>
                        <DeferredFilter
                            promiseOrGetPromise={viewFiltersPromise}
                            title={localization.filters.tenantIds()}>
                            {filters =>
                                <ScopeTenantFilter
                                    placeholder={localization.filters.tenantIds()}
                                    tenantIds={filters.tenantIdItems.items}/>}
                        </DeferredFilter>
                    </Filter>,
                    commonFilters.category,
                    commonFilters.severity,
                    commonFilters.policy,
                    <Filter
                        default={true}
                        id={RisksItemsFilterId[Contract.RiskFilterId.RiskSubStatus]}
                        key={RisksItemsFilterId[Contract.RiskFilterId.RiskSubStatus]}
                        title={localization.filters.subStatus()}>
                        <DeferredFilter
                            promiseOrGetPromise={viewFiltersPromise}
                            title={localization.filters.subStatus()}>
                            {filters =>
                                <ValuesFilter
                                    emptyValueOptions={{ enabled: false }}
                                    placeholder={localization.filters.subStatus()}
                                    sorted={true}>
                                    {_.map(
                                        filters.subStatuses,
                                        riskSubStatus =>
                                            <ValuesFilterItem
                                                key={riskSubStatus}
                                                title={riskSubStatusTranslator(riskSubStatus)}
                                                value={riskSubStatus}/>)}
                                </ValuesFilter>}
                        </DeferredFilter>
                    </Filter>,
                    commonFilters.openStatusUpdateTime,
                    commonFilters.systemCreationTime,
                    <Filter
                        id={RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityId]}
                        key={RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityId]}
                        title={localization.filters.riskedEntityIds()}>
                        <PagedEntityFilter
                            getEntityIdPage={
                                async (searchText, skip, limit, data) => {
                                    const { applyData, count, emptyValue, values } =
                                        await getRiskFilterItemPage(
                                            Contract.CloudRiskFilterItemType.RiskedEntity,
                                            searchText,
                                            skip,
                                            limit,
                                            data);
                                    return new PagedEntityFilterEntityIdPage(
                                        count,
                                        emptyValue,
                                        values,
                                        applyData);
                                }}
                            placeholder={localization.filters.riskedEntityIds()}/>
                    </Filter>
                ]).
                concatIf(
                    isResourceOwnerConfigured,
                    <Filter
                        default={true}
                        id={RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityOwner]}
                        key={RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityOwner]}
                        title={localization.filters.riskedEntityOwner()}>
                        <PagedEntityFilter
                            getEntityIdPage={
                                async (searchText, skip, limit, data) => {
                                    const { applyData, count, emptyValue, values } =
                                        await getRiskFilterItemPage(
                                            Contract.CloudRiskFilterItemType.RiskedEntityOwner,
                                            searchText,
                                            skip,
                                            limit,
                                            data);
                                    return new PagedEntityFilterEntityIdPage(
                                        count,
                                        emptyValue,
                                        values,
                                        applyData);
                                }}
                            placeholder={localization.filters.riskedEntityOwner()}/>
                    </Filter>).
                concatIf(
                    isResourceEnvironmentConfigured,
                    <Filter
                        default={true}
                        id={RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityEnvironment]}
                        key={RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityEnvironment]}
                        title={localization.filters.riskedEntityEnvironment()}>
                        <PagedEntityFilter
                            getEntityIdPage={
                                async (searchText, skip, limit, data) => {
                                    const { applyData, count, emptyValue, values } =
                                        await getRiskFilterItemPage(
                                            Contract.CloudRiskFilterItemType.RiskedEntityEnvironment,
                                            searchText,
                                            skip,
                                            limit,
                                            data);
                                    return new PagedEntityFilterEntityIdPage(
                                        count,
                                        emptyValue,
                                        values,
                                        applyData);
                                }}
                            placeholder={localization.filters.riskedEntityEnvironment()}/>
                    </Filter>).
                concatIf(!_.isNil(commonFilters.compliance), commonFilters.compliance!).
                concatIf(
                    risksView === Contract.RisksView.Ignored,
                    <Filter
                        id={RisksItemsFilterId[Contract.RiskFilterId.RiskIgnoreExpirationDate]}
                        key={RisksItemsFilterId[Contract.RiskFilterId.RiskIgnoreExpirationDate]}
                        title={localization.filters.ignoreExpirationDate()}>
                        <DeferredFilter
                            promiseOrGetPromise={viewFiltersPromise}
                            title={localization.filters.ignoreExpirationDate()}>
                            {filters =>
                                <TimeRangeFilter
                                    emptyValue={filters.ignoreExpirationDateRange.emptyValue}
                                    placeholder={localization.filters.ignoreExpirationDate()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.ignoreExpirationDateRange.timeRange)}/>}
                        </DeferredFilter>
                    </Filter>).
                concat([
                    commonFilters.labels,
                    commonFilters.ticketingServiceType,
                    commonFilters.starred
                ]).
                value(),
        [viewFiltersPromise, commonFilters, filteredActiveTenantModels, risksView, isResourceEnvironmentConfigured, isResourceOwnerConfigured, getRiskFilterItemPage]);
}

export type RisksFilterItemPageData = {
    count: number;
    itemNextPageSearchCursor?: Contract.ElasticsearchIndexSearchCursor;
};