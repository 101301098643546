import _ from "lodash";
import React from "react";
import { NumberFormatter, TimeSpanFormatter, useLocalization } from "@infrastructure";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";

export function useAwsRedshiftClusterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const redshiftCluster = resourceModel.entity as Contract.AwsRedshiftCluster;
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            networkInterfaceIds: redshiftCluster.networkInterfaceIds,
            publicIpAddresses: redshiftCluster.publicIpAddresses,
            resourceModel
        });

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsRedshiftClusterDefinition",
            () => ({
                info: {
                    items: {
                        availabilityZone: "Availability Zone",
                        backupRetentionTimeFrame: "Automatic Backup Retention Period",
                        endpoint: "Endpoint",
                        publicIpAddressEnabled: {
                            false: "No",
                            title: "Public Accessibility",
                            true: "Yes"
                        },
                        storageEncryptionType: {
                            [Contract.AwsRedshiftClusterStorageEncryptionType.Hsm]: {
                                title: "Encryption Type",
                                value: "HSM"
                            },
                            [Contract.AwsRedshiftClusterStorageEncryptionType.Kms]: "Encryption Key"
                        },
                        storageSize: "Size",
                        vpc: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="backupRetentionTimeFrame"
                        title={localization.info.items.backupRetentionTimeFrame()}
                        value={TimeSpanFormatter.day(redshiftCluster.backupRetentionTimeFrame)}/>,
                    <InfoItem
                        key="endpoint"
                        title={localization.info.items.endpoint()}
                        value={
                            _.isNil(redshiftCluster.endpoint)
                                ? undefined
                                : redshiftCluster.endpoint}/>,
                    <InfoItem
                        key="storageSize"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(redshiftCluster.storageSize)}/>,
                    <InfoItem
                        key="storageEncryptionType"
                        title={localization.info.items.storageEncryptionType[Contract.AwsRedshiftClusterStorageEncryptionType.Hsm].title()}
                        value={
                            _.isNil(redshiftCluster.storageEncryptionType)
                                ? undefined
                                : redshiftCluster.storageEncryptionType === Contract.AwsRedshiftClusterStorageEncryptionType.Kms
                                    ? undefined
                                    : localization.info.items.storageEncryptionType[Contract.AwsRedshiftClusterStorageEncryptionType.Hsm].value()}/>,
                    <InfoItem
                        key="publicIpAddressEnabled"
                        title={localization.info.items.publicIpAddressEnabled.title()}
                        value={
                            redshiftCluster.publicIpAddressEnabled
                                ? localization.info.items.publicIpAddressEnabled.true()
                                : localization.info.items.publicIpAddressEnabled.false()}/>,
                    <InfoItem
                        key="availabilityZone"
                        title={localization.info.items.availabilityZone()}
                        value={redshiftCluster.availabilityZone.name}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={redshiftCluster.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getPublicIpAddresses()}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
            </Info>
    });
}