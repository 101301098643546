import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortDirection, EmptyMessageText, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { AzureContext, useSetAzureContext } from "../..";
import { Contract, scopeSystemEntityModelStore, TenantCell } from "../../../../../../../../../../../../../../common";
import { StatusCell } from "../../../StatusCell";
import { ActionsCell } from "./components";

export function Table() {
    const organizationModels = scopeSystemEntityModelStore.useGetAzureOrganization();
    const setAzureContext = useSetAzureContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.azure.table",
            () => ({
                actions: {
                    add: "Add Azure Organization"
                },
                columns: {
                    aadTenant: "Microsoft Entra ID Tenant",
                    name: "Name",
                    status: "Status"
                },
                empty: "No Azure organizations"
            }));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [organizationModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                (_filterMap, sort) =>
                    _.orderBy(
                        organizationModels,
                        organizationModel => StringHelper.getSortValue((organizationModel.configuration as Contract.AzureOrganizationConfiguration).name),
                        sort?.direction === DataTableSortDirection.Descending
                            ? "desc"
                            : "asc")}
            getItemId={(item: Contract.ScopeSystemEntityModel) => item.configuration.id}>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.AzureOrganizationConfiguration).name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.AadTenant}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) =>
                        <TenantCell
                            tenantId={(item.configuration as Contract.AzureOrganizationConfiguration).aadTenantId}
                            tenantNameTranslatorOptions={{ includeRawId: true }}
                            variant="iconText"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.aadTenant()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={StatusCell}
                sortOptions={{ enabled: false }}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setAzureContext(new AzureContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
        </DataTable>);
}

enum TableColumnId {
    AadTenant = "aadTenant",
    Actions = "actions",
    Name = "name",
    Status = "status"
}