import { AppError, NumberFormatter, TimeFormatter, TimeSpanFormatter } from "@infrastructure";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
import _ from "lodash";
import moment from "moment";
import numbro from "numbro";
import { initReactI18next } from "react-i18next";

export const supportedLocalizationLanguages = ["en", "fr", "ja"];

require("moment/min/locales.min");
require("numbro/dist/languages.min");

export function i18nInitialize(backendPath: string) {
    i18n.
        use(backend).
        use(initReactI18next).
        use(LanguageDetector).
        init({
            backend: {
                loadPath: backendPath
            },
            detection: {
                lookupLocalStorage: "language",
                order: ["localStorage", "navigator"]
            },
            fallbackLng: "en",
            interpolation: {
                escapeValue: false,
                format:
                (value, format) => {
                    const formatter =
                        _.get(
                            {
                                NumberFormatter,
                                TimeFormatter,
                                TimeSpanFormatter
                            },
                            format!);
                    if (_.isNil(formatter)) {
                        throw new AppError(`Unknown localization format [format=${format} value=${value}]`);
                    }

                    return formatter(value);
                },
                formatSeparator: "|"
            },
            keySeparator: ".",
            load: "languageOnly",
            supportedLngs: supportedLocalizationLanguages
        });

    i18n.on(
        "languageChanged",
        language => {
            moment.locale(language);
            numbro.setLanguage(language);
        });
}