import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function usePermissionRequestCloseReasonTranslator() {
    const localization =
        useLocalization(
            "common.hooks.usePermissionRequestCloseReasonTranslator",
            () => ({
                [Contract.PermissionRequestCloseReason.ActivationFailed]: "Activation failed",
                [Contract.PermissionRequestCloseReason.Cancelled]: "Request cancelled",
                [Contract.PermissionRequestCloseReason.Expired]: "Access expired",
                [Contract.PermissionRequestCloseReason.Rejected]: "Request denied",
                [Contract.PermissionRequestCloseReason.RequestExpired]: "Request timed-out",
                [Contract.PermissionRequestCloseReason.Revoked]: "Access revoked"
            }));

    return (permissionRequestCloseReason: Contract.PermissionRequestCloseReason) => localization[permissionRequestCloseReason]();
}