﻿import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, EntitiesCell, EntityFilter, ItemSelectionHelper } from "../../../../../../../../../common";
import { TableColumnId } from "../../../Table";
import { TenantDefinition } from "../useDefinition";

export function useOktaDefinition(filters: Contract.PermissionManagementControllerPermissionEligibilityFilters): TenantDefinition {
    const groupMembershipFilters = filters as Contract.PermissionManagementControllerGroupMembershipEligibilityFilters;
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.hooks.useDefinition.hooks.useOktaDefinition",
            () => ({
                columns: {
                    groupIds: "Groups"
                }
            }));
    return new TenantDefinition(
        (commonFilters, filterMap, limit, scope, skip, sort) =>
            new Contract.PermissionManagementControllerGetOktaPermissionEligibilityModelPageRequest(
                new Contract.PermissionManagementControllerGetGroupMembershipEligibilityModelPageRequestFilters(
                    commonFilters.approvalRequired,
                    commonFilters.approverPrincipalIdSelection,
                    commonFilters.granteePrincipalIdSelection,
                    commonFilters.nameSelection,
                    commonFilters.principalTenantIdSelection,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.OktaGroupIds])),
                limit,
                scope,
                skip,
                sort),
        getEntitiesExportOptions =>
            [
                <DataTableColumn
                    exportOptions={
                        getEntitiesExportOptions(
                            item => (item as Contract.GroupMembershipEligibilityModel).groupIdReferences,
                            localization.columns.groupIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={groupMembershipFilters.groupSearchableReferences}
                                    placeholder={localization.columns.groupIds()}/>
                        }
                    }}
                    id={TableColumnId.OktaGroupIds}
                    key={TableColumnId.OktaGroupIds}
                    render={
                        optionalTableCell<Contract.PermissionEligibilityModel>(
                            item =>
                                <EntitiesCell
                                    entityIdsOrModels={(item as Contract.GroupMembershipEligibilityModel).groupIdReferences}
                                    entityLinkOptions={{ disabled: true }}
                                    entityTypeName={Contract.TypeNames.OktaDirectoryGroup}
                                    entityVariant="iconText"/>)}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.groupIds()}/>
            ]);
}