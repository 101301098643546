import { Action0, Action1, Message, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, TextareaAutosize } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AuditEventController, Contract, useTheme } from "../../../../../../../../../../../../../../common";

type EditProps = {
    onChange: Action1<Contract.AuditEventModel>;
    onClose: Action0;
    riskStatusUpdateAuditEvent: Contract.RiskStatusUpdateAuditEvent;
};

export function Edit({ onChange, onClose, riskStatusUpdateAuditEvent }: EditProps) {
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [message, setMessage] = useState(riskStatusUpdateAuditEvent.message);

    async function updateNote() {
        setExecuting(true);

        try {
            const { auditEventModel } =
                await AuditEventController.updateRiskStatusUpdateAuditEvent(
                    new Contract.AuditEventControllerUpdateRiskStatusUpdateAuditEventRequest(
                        riskStatusUpdateAuditEvent.id,
                        message!));

            onChange(auditEventModel);
            onClose();
        } catch {
            setError(true);
        }

        setError(false);
        setExecuting(false);
    }


    const localization =
        useLocalization(
            "views.customer.risks.profile.core.auditEventsSection.auditEvent.riskStatusUpdateAuditEvent.edit",
            () => ({
                actions: {
                    update: {
                        cancel: "cancel",
                        confirm: "save",
                        error: "failed to edit note"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                border: theme.border.primary,
                boxShadow: theme.shadows[2],
                padding: theme.spacing(1),
                width: theme.spacing(90)
            }}>
            <TextareaAutosize
                ref={(htmlTextAreaElement: HTMLTextAreaElement) => htmlTextAreaElement && htmlTextAreaElement.focus()}
                style={{
                    border: "none",
                    fontFamily: "Inter",
                    fontSize: 12,
                    outline: "none",
                    resize: "none",
                    width: "100%"
                }}
                value={message}
                onChange={event => setMessage(event.target.value)}/>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Button
                    disabled={executing}
                    size="small"
                    variant="outlined"
                    onClick={() => onClose()}>
                    {localization.actions.update.cancel()}
                </Button>
                <Button
                    disabled={
                        executing ||
                        _.isEmpty(message) ||
                        riskStatusUpdateAuditEvent.message === message}
                    size="small"
                    onClick={() => updateNote()}>
                    {localization.actions.update.confirm()}
                </Button>
                {error && (
                    <Message
                        level="error"
                        title={localization.actions.update.error()}/>)}
                {executing && (
                    <Box>
                        <CircularProgress
                            size={theme.spacing(3)}
                            variant="indeterminate"/>
                    </Box>)}
            </Stack>
        </Stack>);
}