import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoCard } from "../../../../../../../../../../../../common";
import { EntitiesInfoCard } from "../../../../../../components";

type CredentialsProps = {
    userModel: Contract.OciIamUserModel;
};

export function Credentials({ userModel }: CredentialsProps) {

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciIamUserDefinition.credentials",
            () => ({
                cards: {
                    apiKeys: "User API Keys",
                    authTokens: "User Auth Tokens",
                    customerSecretKeys: "User Customer Secret Keys",
                    databasePasswords: "User Database Passwords"
                }
            }));

    return (
        <InfoCard>
            <EntitiesInfoCard
                entityIdsOrModels={userModel.apiKeyIds}
                entityTypeName={Contract.TypeNames.OciIamUserApiKey}
                title={localization.cards.apiKeys()}/>
            <EntitiesInfoCard
                entityIdsOrModels={userModel.authTokenIds}
                entityTypeName={Contract.TypeNames.OciIamUserAuthToken}
                title={localization.cards.authTokens()}/>
            <EntitiesInfoCard
                entityIdsOrModels={userModel.secretKeyIds}
                entityTypeName={Contract.TypeNames.OciIamUserSecretKey}
                title={localization.cards.customerSecretKeys()}/>
            <EntitiesInfoCard
                entityIdsOrModels={userModel.databasePasswordIds}
                entityTypeName={Contract.TypeNames.OciIamUserDatabasePassword}
                title={localization.cards.databasePasswords()}/>
        </InfoCard>);
}