﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureNetworkLoadBalancerSkuTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureNetworkLoadBalancerSkuTranslator",
            () => ({
                [Contract.TypeNames.AzureNetworkLoadBalancerSku]: {
                    [Contract.AzureNetworkLoadBalancerSku.Basic]: "Basic",
                    [Contract.AzureNetworkLoadBalancerSku.Gateway]: "Gateway",
                    [Contract.AzureNetworkLoadBalancerSku.Standard]: "Standard"
                }
            }));
    return (sku: Contract.AzureNetworkLoadBalancerSku) =>
        localization[Contract.TypeNames.AzureNetworkLoadBalancerSku][sku]();
}