import { useLocalization } from "@infrastructure";
import { Grid2, Stack } from "@mui/material";
import React from "react";
import { Contract, ScopeHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../common";
import { Disk } from "../Disk";
import { ExclusionTagsTable } from "../ExclusionTags";
import { AzureDiskSnapshotResourceGroup, VirtualMachineActionsCell, VirtualMachineAddExclusionTags, VirtualMachineEnabled } from "./components";

export function VirtualMachines() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.virtualMachines",
            () => ({
                resourceType: "virtual machines"
            }));
    const theme = useTheme();
    return (
        <Grid2
            size={{ xs: 8 }}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(125),
                overflowY: "auto",
                padding: theme.spacing(3, 4)
            }}>
            <Stack spacing={2}>
                <VirtualMachineEnabled/>
                <Disk resourceType={localization.resourceType()}/>
                {ScopeHelper.getTenantType(scopeNodeModel) === Contract.TenantType.Azure &&
                    <AzureDiskSnapshotResourceGroup/>}
                <VirtualMachineExclusionTags/>
            </Stack>
        </Grid2>);
}

function VirtualMachineExclusionTags() {
    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.virtualMachines.virtualMachineExclusionTags",
            () => ({
                description: "By default, all virtual machines in the account are scanned. You can exclude specific virtual machines based on their tags",
                resourceType: {
                    caps: "Virtual Machine",
                    noCaps: "virtual machine"
                }
            }));
    return (
        <ExclusionTagsTable
            ActionsCell={VirtualMachineActionsCell}
            AddComponent={VirtualMachineAddExclusionTags}
            exclusionTagsTableLocalization={{
                description: localization.description(),
                resourceType: {
                    caps: localization.resourceType.caps(),
                    noCaps: localization.resourceType.noCaps()
                }
            }}
            getScopeConfigurationWorkloadAnalysisResourceTags={scopedConfiguration => scopedConfiguration.virtualMachine.exclusionTags}/>);
}