import { map } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, useRiskSubStatusTranslator } from "../../../../..";
import { UdmObjectPropertyItemProps } from "../..";
import { AwsResourceAccessLevel } from "../../../../../../tenants";

export function InfraEnum({ item, objectProperty }: UdmObjectPropertyItemProps) {
    return map(
        objectProperty.id,
        {
            [Contract.UdmObjectPropertyId.AwsAccessResourceAccessLevel]: () =>
                <AwsResourceAccessLevel accessLevel={item}/>,
            [Contract.UdmObjectPropertyId.RiskSubStatus]:
                () =>
                    <EnumValue
                        useTranslator={useRiskSubStatusTranslator}
                        value={item}/>
        },
        () => <EnumValue value={item}/>);
}

type EnumValueProps = {
    useTranslator?: () => (value: any) => string;
    value: Contract.RiskSubStatus;
};

function EnumValue({ useTranslator, value }: EnumValueProps) {
    const enumTranslator = useTranslator?.();
    return (
        <Typography
            noWrap={true}
            sx={{ width: "max-content" }}>
            {enumTranslator?.(value) ?? value}
        </Typography>);
}