import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciIamDomainRiskContext() {
    return useMemo(
        () => useOciIamDomainRiskContext,
        []);
}

function useOciIamDomainRiskContext(domainModel: Contract.OciIamDomainModel) {
    const domain = domainModel.entity as Contract.OciIamDomain;
    const resourceRiskContext = useGetOciResourceRiskContext()(domainModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciIamDomainRiskContext",
            () => ({
                status: {
                    false: "The domain status is disabled",
                    true: "The domain status is enabled"
                }
            }));

    return {
        ...resourceRiskContext,
        status: new RiskDefinitionContextItem(
            (domain.enabled
                ? localization.status.true
                : localization.status.false)())
    };
}