import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { VulnerabilitiesCell } from "../../../../../";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, SeverityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEcsTaskDefinitionStatusTranslator, useGetAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEcsTaskDefinitionDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const getAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem = useGetAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem();

    const ecsTaskDefinitionStatusTranslator = useAwsEcsTaskDefinitionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEcsTaskDefinitionDefinition",
            () => ({
                columns: {
                    containerImages: "Container Images",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerImageVulnerabilitySeverities: "Vulnerability Severity",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEcsTaskDefinitionModel) => ({
                                [localization.columns.status()]: ecsTaskDefinitionStatusTranslator((item.entity as Contract.AwsEcsTaskDefinition).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsEcsTaskDefinitionStatus}
                                    enumTypeTranslator={ecsTaskDefinitionStatusTranslator}
                                    placeholder={localization.columns.status()}
                                    sorted={false}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEcsTaskDefinitionStatus}
                    itemProperty={(item: Contract.AwsResourceModel) => ecsTaskDefinitionStatusTranslator((item.entity as Contract.AwsEcsTaskDefinition).status)}
                    key={Contract.EntityModelProperty.AwsEcsTaskDefinitionStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEcsTaskDefinitionModel>(
                            Contract.TypeNames.IContainerImage,
                            item => item.containerImageIdReferences,
                            localization.columns.containerImages())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImages)}
                                    placeholder={localization.columns.containerImages()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImages}
                    key={Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImages}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEcsTaskDefinitionModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.containerImageIdReferences}
                                entityTypeName={Contract.TypeNames.IContainerImage}
                                entityVariant="iconText"/>}
                    title={localization.columns.containerImages()}/>,
                <DataTableColumn
                    cellMinWidth={110}
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEcsTaskDefinitionModel) => ({
                                [localization.columns.containerImageVulnerabilities()]:
                                    getAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem(
                                        item.containerImageScanStatus,
                                        item.containerImageVulnerabilities)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: [
                            {
                                element:
                                    <PagedValuesFilter
                                        getValuePage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImageVulnerabilities)}
                                        placeholder={localization.columns.containerImageVulnerabilities()}/>,
                                id: Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImageVulnerabilities,
                                title: localization.columns.containerImageVulnerabilities()
                            },
                            {
                                element:
                                    <SeverityFilter placeholder={localization.columns.containerImageVulnerabilitySeverities()}/>,
                                id: Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilitySeverities,
                                title: localization.columns.containerImageVulnerabilitySeverities()
                            }
                        ]
                    }}
                    id={Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImageVulnerabilities}
                    key={Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImageVulnerabilities}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEcsTaskDefinitionModel>) =>
                            <VulnerabilitiesCell
                                containerImageScanStatus={item.containerImageScanStatus}
                                vulnerabilities={item.containerImageVulnerabilities}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.containerImageVulnerabilities()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEcsTaskDefinitionRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEcsTaskDefinitionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImages]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcsTaskDefinitionContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcsTaskDefinitionStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}