import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, RiskPolicyTypeMetadataHelper } from "../../../../../../../../../../../../../../../../../../common";
import { TableDefinition } from "../../../../useDefinition";
import { useAccessDefinition, useBehaviorDefinition, useCustomDefinition, useDefaultDefinition, useNetworkDefinition } from "./hooks";
import { useComputeDefinition } from "./hooks/useComputeDefinition";
import { useSecretDefinition } from "./hooks/useSecretDefinition";

export function useRiskPolicyTypeDefinition(riskGroup: Contract.RiskControllerGetRiskGroupsResponseRiskGroup): TableDefinition {
    const riskPolicyTypeGroup = riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup;
    const riskPolicyConfigurationTypeName = riskPolicyTypeGroup.riskPolicyConfigurationTypeName;
    const useDefinition =
        useMemo(
            () => {
                switch (RiskPolicyTypeMetadataHelper.get(riskPolicyConfigurationTypeName).category) {
                    case Contract.RiskPolicyCategory.Access:
                        return useAccessDefinition;
                    case Contract.RiskPolicyCategory.Compute:
                        return useComputeDefinition;
                    case Contract.RiskPolicyCategory.Data:
                    case Contract.RiskPolicyCategory.Kubernetes:
                    case Contract.RiskPolicyCategory.Logging:
                    case Contract.RiskPolicyCategory.Management:
                    case Contract.RiskPolicyCategory.Monitoring:
                    case Contract.RiskPolicyCategory.WorkloadAnalysis:
                        return useDefaultDefinition;
                    case Contract.RiskPolicyCategory.Behavior:
                        return useBehaviorDefinition;
                    case Contract.RiskPolicyCategory.Custom:
                        return useCustomDefinition;
                    case Contract.RiskPolicyCategory.Network:
                        return useNetworkDefinition;
                    case Contract.RiskPolicyCategory.Secrets:
                        return useSecretDefinition;
                    default:
                        throw new UnexpectedError(riskPolicyConfigurationTypeName);
                }
            },
            []);

    return useDefinition(riskPolicyConfigurationTypeName);
}