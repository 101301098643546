import { Action0, Loading, Optional } from "@infrastructure";
import { Box, Menu as MuiMenu, MenuItem as MuiMenuItem, PopoverPosition, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useState } from "react";

export class ContextMenuItem {
    constructor(
        public onClick: Action0,
        public title: string) {
    }
}

export type ContextMenuProps = {
    children: ReactNode;
    getMenuItems?: (item: any) => Optional<ContextMenuItem[]> | Promise<Optional<ContextMenuItem[]>>;
    item: any;
};

export function ContextMenu({ children, getMenuItems, item }: ContextMenuProps) {
    return (
        _.isNil(getMenuItems)
            ? <Fragment>
                {children}
            </Fragment>
            : <ContextMenuCore
                getMenuItems={getMenuItems}
                item={item}>
                {children}
            </ContextMenuCore>);
}

type ContextMenuCoreProps = {
    children: ReactNode;
    getMenuItems: (item: any) => Optional<ContextMenuItem[]> | Promise<Optional<ContextMenuItem[]>>;
    item: any;
};

function ContextMenuCore({ children, getMenuItems, item }: ContextMenuCoreProps) {
    const [menu, setMenu] = useState<Menu>();
    const theme = useTheme();
    return (
        <Fragment>
            {!_.isNil(menu) && (
                <MuiMenu
                    anchorPosition={menu.position}
                    anchorReference="anchorPosition"
                    open={true}
                    slotProps={{
                        root: {
                            sx: {
                                maxWidth: theme.spacing(80)
                            }
                        }
                    }}
                    sx={{ zIndex: theme.zIndex.tooltip }}
                    transformOrigin={{
                        horizontal: "left",
                        vertical: "top"
                    }}
                    onClick={
                        event => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    onClose={() => setMenu(undefined)}>
                    <Loading container="popup">
                        {_.map(
                            menu.contextMenuItems,
                            (contextMenuItem, contextMenuItemIndex) =>
                                <MuiMenuItem
                                    key={contextMenuItemIndex}
                                    onClick={
                                        () => {
                                            contextMenuItem.onClick();
                                            setMenu(undefined);
                                        }}>
                                    <Typography noWrap={true}>
                                        {contextMenuItem.title}
                                    </Typography>
                                </MuiMenuItem>)}
                    </Loading>
                </MuiMenu>)}
            <Box
                onContextMenu={
                    async event => {
                        event.preventDefault();
                        event.stopPropagation();
                        const position = {
                            left: event.clientX,
                            top: event.clientY
                        };

                        const contextMenuItemsOrPromise = getMenuItems(item);
                        const contextMenuItems =
                            contextMenuItemsOrPromise instanceof Promise
                                ? await contextMenuItemsOrPromise
                                : contextMenuItemsOrPromise;
                        if (!_.isNil(contextMenuItems)) {
                            setMenu(
                                new Menu(
                                    contextMenuItems,
                                    position));
                        }
                    }}>
                {children}
            </Box>
        </Fragment>);
}

class Menu {
    constructor(
        public contextMenuItems: ContextMenuItem[],
        public position: PopoverPosition) {
    }
}