import { DataTable, DataTableActions, DataTableFetchItemsResult, DataTableSort, EmptyMessageText, makeContextProvider, Optional, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { MutableRefObject, useEffect, useRef } from "react";
import { ApproverUserPermissionRequestViewType, useApproverUserPermissionRequestsContext } from "../..";
import { Contract, entityModelStore, ItemSelectionHelper, PermissionManagementController, scopeNodeModelStore, StorageHelper, tenantModelStore, TimeRangeHelper, TypeHelper } from "../../../../../../common";
import { useDefinition } from "./hooks";

export enum TableColumnId {
    Actions = "actions",
    ExpirationTimeFrame = "expirationTimeFrame",
    GranteeUserIdReference = "granteeUserIdReference",
    PermissionAssignmentRequestTenantIds = "permissionAssignmentRequestTenantIds",
    PermissionEligibility = "permissionEligibility",
    Reason = "reason",
    StartTime = "startTime",
    Status = "status",
    SystemCreationTime = "systemCreationTime"
}

export class TableContext {
    private _entityModelMapRef: MutableRefObject<Dictionary<Contract.EntityModel>>;

    constructor(entityModelMapRef: MutableRefObject<Dictionary<Contract.EntityModel>>) {
        this._entityModelMapRef = entityModelMapRef;
    }

    public get entityModelMap() {
        return this._entityModelMapRef.current;
    }
}

export const [useTableContext, , useTableContextProvider] = makeContextProvider<TableContext>();

type TableProps = {
    viewType: ApproverUserPermissionRequestViewType;
};

export function Table({ viewType }: TableProps) {
    const entityModelMapRef = useRef<Dictionary<Contract.EntityModel>>({});
    const [, , ContextProvider] = useTableContextProvider(() => new TableContext(entityModelMapRef));

    const definition = useDefinition(viewType);
    const dataTableActionsRef = useRef<DataTableActions>();

    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests.table",
            () => ({
                empty: "No items"
            }));

    const { getFiltersResponse, refreshFilters, registerPermissionRequestModelChange } = useApproverUserPermissionRequestsContext();
    const scopeNodeModelMap = scopeNodeModelStore.useGetScopeNodeMap();

    useEffect(
        () => {
            dataTableActionsRef.current!.reset({ refreshFilters: true });
        },
        [getFiltersResponse]);

    useEffect(
        () => {
            const unregister = registerPermissionRequestModelChange(refreshFilters);
            return unregister;
        },
        []);

    const fetchPermissionRequestModels =
        async (filterMap: Dictionary<any>, _sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { permissionRequestModelPage } =
                await PermissionManagementController.getApproverUserPermissionRequestModelPage(
                    new Contract.PermissionManagementControllerGetApproverUserPermissionRequestModelPageRequest(
                        new Contract.PermissionManagementControllerApproverUserPermissionRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.Status]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.GranteeUserIdReference]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.PermissionAssignmentRequestTenantIds]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.PermissionEligibility]),
                            ItemSelectionHelper.toItemSelectionFromValues(definition.permissionApproveStatus),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[TableColumnId.SystemCreationTime])),
                        limit,
                        skip));

            const permissionRequestModelPagePermissionAssignmentRequestTenantIds =
                _(permissionRequestModelPage.items).
                    filter(permissionRequestModel => permissionRequestModel.permissionRequest.typeName === Contract.TypeNames.PermissionAssignmentRequest).
                    flatMap(permissionRequestModel => (permissionRequestModel.permissionRequest as Contract.PermissionAssignmentRequest).tenantIds).
                    value();
            await tenantModelStore.get(permissionRequestModelPagePermissionAssignmentRequestTenantIds);

            if (_.some(
                permissionRequestModelPagePermissionAssignmentRequestTenantIds,
                permissionRequestModelPagePermissionAssignmentRequestTenantId =>
                    !_.has(
                        scopeNodeModelMap,
                        permissionRequestModelPagePermissionAssignmentRequestTenantId))) {
                await scopeNodeModelStore.notify();
            }

            const groupModels =
                await entityModelStore.get(
                    _.flatMap(
                        permissionRequestModelPage.items,
                        permissionRequestModel =>
                            TypeHelper.extendOrImplement(
                                permissionRequestModel.permissionRequest.typeName,
                                Contract.TypeNames.GroupMembershipRequest) &&
                            !_.isNil(permissionRequestModel.activationFailureData)
                                ? _.keys((permissionRequestModel.activationFailureData as Contract.GroupMembershipRequestModelActivationFailureData).groupIdToErrorMessageMap)
                                : []));

            entityModelMapRef.current =
                _.merge(
                    entityModelMapRef.current,
                    _.keyBy(
                        groupModels,
                        groupModel => groupModel.id));

            return new DataTableFetchItemsResult(
                { count: permissionRequestModelPage.count! },
                permissionRequestModelPage.items,
                !permissionRequestModelPage.hasMore);
        };

    return (
        <ContextProvider>
            <DataTable
                actionsRef={dataTableActionsRef}
                columnOptions={{
                    selectorOptions: {
                        enabled: true,
                        persistenceStorageItem:
                            viewType === ApproverUserPermissionRequestViewType.Active
                                ? StorageHelper.userApproverUserPermissionRequestsActiveColumnSelector
                                : StorageHelper.userApproverUserPermissionRequestsHistoryColumnSelector
                    }
                }}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={fetchPermissionRequestModels}
                fetchItemsInterval={5000}
                getItemId={(item: Contract.PermissionRequestModel) => item.permissionRequest.id}
                virtualizationEnabled={true}>
                {definition.getColumns()}
            </DataTable>
        </ContextProvider>);
}