﻿import { Optional, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";

export function useGetTopItemsExportData() {
    const localization =
        useLocalization(
            "views.customer.hooks.useGetTopItemsExportData",
            () => ({
                partialData: "(showing {{itemTopCount}} out of {{itemCount}})\n{{exportData}}"
            }));

    return (items: any[], itemCount: number, itemProperty: (item: any) => Optional<string>, itemTopCount = 100, sort = true) => {
        const exportData =
            _(items).
                take(itemTopCount).
                map(itemProperty).
                orderBy(
                    sort
                        ? StringHelper.getSortValue
                        : _ => undefined).
                join("\n");
        return itemCount > itemTopCount
            ? localization.partialData({
                exportData,
                itemCount,
                itemTopCount
            })
            : exportData;
    };
}