import { useLocalization } from "@infrastructure";
import { Contract } from "../controllers";

export function useRiskPolicyConfigurationExclusionDataReasonTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useRiskPolicyConfigurationExclusionDataReasonTranslator",
            () => ({
                [Contract.TypeNames.RiskPolicyConfigurationExclusionDataReason]: {
                    [Contract.RiskPolicyConfigurationExclusionDataReason.ByDesign]: "By Design",
                    [Contract.RiskPolicyConfigurationExclusionDataReason.Exception]: "Exception",
                    [Contract.RiskPolicyConfigurationExclusionDataReason.FalsePositive]: "False Positive"
                }
            }));
    return (reason: Contract.RiskPolicyConfigurationExclusionDataReason) =>
        localization[Contract.TypeNames.RiskPolicyConfigurationExclusionDataReason][reason]();
}