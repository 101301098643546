import _ from "lodash";
import { Contract, CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters, EntityTypeNameTranslatorOptions } from "..";
import { TypeHelper } from "./typeHelper";

export class EntityModelHelper {
    public static formatDisplayReference(entityModel: Contract.EntityModel, entityTypeNameTranslator: (typeName: string, options?: EntityTypeNameTranslatorOptions) => string) {
        return `${entityModel.entity.displayReference} (${entityTypeNameTranslator(entityModel.entity.typeName)})`;
    }

    public static getBadge(entityModel: Contract.EntityModel) {
        if (TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IAwsAccessResourceModel) &&
            _.as<Contract.IAwsAccessResourceModel>(entityModel).accessLevel === Contract.AwsResourceAccessLevel.Public) {
            return "publicAccess";
        }

        if (TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.GcpScopeResourceModel) &&
            (entityModel as Contract.GcpScopeResourceModel).accessLevel === Contract.GcpScopeResourceAccessLevel.Public) {
            return "publicAccess";
        }

        switch (entityModel.entity.typeName) {
            case Contract.TypeNames.AadDirectoryApplicationServicePrincipal:
                if ((entityModel.entity as Contract.AadDirectoryApplicationServicePrincipal)?.vendor) {
                    return "vendor";
                }
                break;
            case Contract.TypeNames.AwsIamRole:
                if ((entityModel.entity as Contract.AwsIamRole)?.vendor) {
                    return "vendor";
                }
                break;
            case Contract.TypeNames.AzureStorageStorageAccountBlobContainer: {
                const storageAccountBlobContainer = entityModel.entity as Contract.AzureStorageStorageAccountBlobContainer;

                if (storageAccountBlobContainer.storageAccountPublicNetworkAccess) {
                    if (storageAccountBlobContainer.effectivePublicAccess === Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Container) {
                        return "publicAccess";
                    } else if (storageAccountBlobContainer.effectivePublicAccess === Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Blob) {
                        return "restrictedAccess";
                    }
                }
                break;
            }
            default:
                if ((entityModel.entityNetwork as Contract.NetworkAccessResourceStateNetwork)?.inboundExternalWideRange === true) {
                    return "publicAccess";
                }
        }

        return undefined;
    }

    public static getCommonTypeName(entityModelOrModels: Contract.EntityModel | Contract.EntityModel[]) {
        if (_.isArray(entityModelOrModels)) {
            const entityTypeNames =
                _(entityModelOrModels).
                    map(entityModel => entityModel.entity.typeName).
                    uniq().
                    value();
            return entityTypeNames.length === 1
                ? entityTypeNames[0]
                : undefined;
        } else {
            return entityModelOrModels.entity.typeName;
        }
    }

    public static getInboundExternalFilter(): Partial<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters> {
        return {
            networkInboundAccessTypes: [Contract.NetworkInboundAccessType.ExternalDirect],
            networkInboundExternalAccessScopes: [
                Contract.NetworkAccessScope.All,
                Contract.NetworkAccessScope.Wide
            ]
        };
    }
}