import { ItemSelector, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../../common";
import { useOciKmsVaultKeyVersionSourceTranslator, useOciKmsVaultKeyVersionStatusTranslator } from "../../../../../../../../hooks/";

type VersionsProps = {
    keyModel: Contract.OciKmsVaultKeyModel;
};

export function Versions({ keyModel }: VersionsProps) {
    const key = keyModel.entity as Contract.OciKmsVaultKey;
    const [selectedVersion, setSelectedVersion] = useState(() => key.versionRawOcidToVersionMap[key.currentVersionOcid]);

    const keyVersionSourceTranslator = useOciKmsVaultKeyVersionSourceTranslator();
    const keyVersionStatusTranslator = useOciKmsVaultKeyVersionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciKmsVaultKeyDefinition.versions",
            () => ({
                current: {
                    text: "{{versionOcid}} (current)"
                },
                fields: {
                    creationTime: "Creation Time",
                    ocid: "Ocid",
                    source: "Source",
                    status: "State"
                }
            }));

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(key.versionRawOcidToVersionMap).values()
                        .orderBy(
                            [
                                version => version.ocid === key.currentVersionOcid,
                                version => version.creationTime
                            ],
                            [
                                "desc",
                                "desc"
                            ])
                        .map(version => version.ocid)
                        .value()}
                selectedItem={selectedVersion.ocid}
                sorted={false}
                onSelectedItemChanged={versionOcid => setSelectedVersion(key.versionRawOcidToVersionMap[versionOcid])}>
                {versionOcid =>
                    versionOcid === key.currentVersionOcid
                        ? localization.current.text({ versionOcid })
                        : versionOcid}
            </ItemSelector>
            <InfoCard columns={true}>
                <InfoItem
                    key="ocid"
                    title={localization.fields.ocid()}
                    value={selectedVersion.ocid}/>
                <InfoItem
                    key="creationTime"
                    title={localization.fields.creationTime()}
                    value={TimeFormatter.profileFormatDateTime(selectedVersion.creationTime)}/>
                <InfoItem
                    key="status"
                    title={localization.fields.status()}
                    value={keyVersionStatusTranslator(selectedVersion.status)}/>
                <InfoItem
                    key="source"
                    title={localization.fields.source()}
                    value={keyVersionSourceTranslator(selectedVersion.source)}/>
            </InfoCard>
        </InfoCard>);
}