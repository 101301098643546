import { Action1, CollapsedIcon, DistributionBar, DistributionBarItem, ExpandedIcon, Link, useLocalization } from "@infrastructure";
import { Circle } from "@mui/icons-material";
import { Grid2, keyframes, Stack, SxProps, Theme, Typography } from "@mui/material";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { Contract, DatadogIcon, useTheme } from "../../../../../../../common";
import { childStageDatasExists } from "../Orchestration";

const rapidBlink =
    keyframes`
        0% { opacity: 1 }
        50% { opacity: 0 }
        100% { opacity: 1 }`;

type ItemProps = {
    expandedStageIds: string[];
    level: number;
    onClick: Action1<string>;
    orchestrationStageData: Contract.AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData;
    searchText?: string;
};

export function Item({ expandedStageIds, level, onClick, orchestrationStageData, searchText }: ItemProps) {
    const theme = useTheme();
    return (
        <TreeItem
            itemId={orchestrationStageData.id}
            label={
                <Title
                    expanded={expandedStageIds.includes(orchestrationStageData.id)}
                    level={level}
                    orchestrationStageData={orchestrationStageData}
                    searchText={searchText}/>}
            slots={{
                collapseIcon: Fragment,
                expandIcon: Fragment
            }}
            sx={{
                [`& .${treeItemClasses.groupTransition}`]: {
                    marginLeft: "unset"
                },
                [`& .${treeItemClasses.content}`]: {
                    [`&.${treeItemClasses.focused}`]: {
                        backgroundColor: "unset"
                    },
                    "&:hover": {
                        backgroundColor: "unset"
                    },
                    boxSizing: "border-box",
                    padding: theme.spacing(1)
                }
            }}
            onClick={() => onClick(orchestrationStageData.id)}>
            {childStageDatasExists(orchestrationStageData)
                ? _.map(
                    orchestrationStageData.childStageDatas,
                    childStageData =>
                        <Item
                            expandedStageIds={expandedStageIds}
                            key={childStageData.id}
                            level={level + 1}
                            orchestrationStageData={childStageData}
                            searchText={searchText}
                            onClick={onClick}/>)
                : undefined}
        </TreeItem>);
}

type TitleProps = {
    expanded: boolean;
    level: number;
    orchestrationStageData: Contract.AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData;
    searchText?: string;
};

function Title({ expanded, level, orchestrationStageData, searchText }: TitleProps) {
    const localization =
        useLocalization(
            "views.customer.administration.orchestration.item",
            () => ({
                completed: "{{count}}/{{totalCount}} Completed",
                duration: "Duration: {{duration | TimeSpanFormatter.dayHourMinuteSecond}}",
                retries: "Retries: {{count}}/{{totalCount}}",
                tenants: [
                    "1 Tenant",
                    "{{count | NumberFormatter.humanize}} Tenants"
                ]
            }));

    const theme = useTheme();
    return (
        <Grid2
            alignItems="center"
            container={true}>
            <Grid2
                alignItems="center"
                container={true}
                size="grow"
                spacing={1}
                sx={{
                    color:
                        orchestrationStageData.status === Contract.AdministrationControllerOrchestrationStageStatus.NotApplicable
                            ? theme.palette.text.secondary
                            : undefined
                }}
                wrap="nowrap">
                <Grid2
                    sx={{
                        fontSize: "16px",
                        marginLeft: theme.spacing(2 * level),
                        marginRight: theme.spacing(1),
                        width: theme.spacing(3)
                    }}>
                    {childStageDatasExists(orchestrationStageData) &&
                        (expanded
                            ? <ExpandedIcon/>
                            : <CollapsedIcon/>)}
                </Grid2>
                <Grid2 sx={{ width: theme.spacing(5) }}>
                    <Circle
                        sx={{
                            animation:
                                orchestrationStageData.status === Contract.AdministrationControllerOrchestrationStageStatus.Running
                                    ? `${rapidBlink} 3s linear infinite`
                                    : undefined,
                            color:
                                orchestrationStageData.status === Contract.AdministrationControllerOrchestrationStageStatus.Completed
                                    ? theme.palette.success.main
                                    : orchestrationStageData.status === Contract.AdministrationControllerOrchestrationStageStatus.NotApplicable
                                        ? theme.palette.text.secondary
                                        : orchestrationStageData.status === Contract.AdministrationControllerOrchestrationStageStatus.NotStarted
                                            ? theme.palette.text.primary
                                            : theme.palette.warning.main
                        }}/>
                </Grid2>
                <Grid2 size="grow">
                    <HighlightedTypography
                        highlightedTextPart={searchText}
                        text={orchestrationStageData.name}
                        typographySx={{ marginLeft: theme.spacing(2) }}/>
                </Grid2>
                <Grid2 size={{ xs: 3 }}>
                    <Typography noWrap={true}>
                        {localization.duration({ duration: orchestrationStageData.duration })}
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 2 }}>
                    {orchestrationStageData.type === Contract.AdministrationControllerOrchestrationStageType.Customer
                        ? <Typography
                            noWrap={true}
                            sx={{ paddingLeft: theme.spacing(2) }}>
                            {localization.retries({
                                count: (orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseCustomerOrchestrationStageData).retryCount,
                                totalCount: ((orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseCustomerOrchestrationStageData)).retryMaxCount
                            })}
                        </Typography>
                        : undefined}
                </Grid2>
            </Grid2>
            <Grid2
                container={true}
                justifyContent="flex-end"
                size={{ xs: 2 }}>
                {orchestrationStageData.type === Contract.AdministrationControllerOrchestrationStageType.Root &&
                    !_.isNil((orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseRootStagesOrchestrationStageData).orchestrationId)
                    ? <Grid2 sx={{ width: theme.spacing(5) }}>
                        <Link
                            urlOrGetUrl={`https://app.datadoghq.com/logs?query=@Properties.OrchestrationId:${(orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseRootStagesOrchestrationStageData).orchestrationId}`}
                            variant="external">
                            <DatadogIcon sx={{ fontSize: "24px" }}/>
                        </Link>
                    </Grid2>
                    : undefined}
                {orchestrationStageData.type === Contract.AdministrationControllerOrchestrationStageType.Tenant
                    ? <Stack
                        alignItems="center"
                        sx={{
                            flex: 1,
                            maxWidth: theme.spacing(20)
                        }}>
                        <DistributionBar
                            items={[
                                new DistributionBarItem(
                                    theme.palette.success.main,
                                    (orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).completedTenantCount,
                                    localization.tenants((orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).completedTenantCount)),
                                new DistributionBarItem(
                                    theme.palette.borders.primary,
                                    (orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).tenantCount - (orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).completedTenantCount,
                                    localization.tenants((orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).tenantCount - (orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).completedTenantCount))
                            ]}
                            totalValue={(orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).tenantCount}/>
                        <Typography
                            align="right"
                            sx={{ margin: theme.spacing(0, "auto") }}
                            variant="h5">
                            {localization.completed({
                                count: (orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).completedTenantCount,
                                totalCount: (orchestrationStageData as Contract.AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData).tenantCount
                            })}
                        </Typography>
                    </Stack>
                    : undefined
                }
            </Grid2>
        </Grid2>);
}

type HighlightedTypographyProps = {
    highlightedTextPart: string | undefined;
    text: string;
    typographySx: SxProps<Theme>;
};

function HighlightedTypography({ highlightedTextPart, text, typographySx }: HighlightedTypographyProps) {
    const parts = useMemo(
        () =>
            text.split(new RegExp(`(${highlightedTextPart})`, "gi")),
        [text, highlightedTextPart]);
    const theme = useTheme();
    return (
        <Typography
            noWrap={true}
            sx={typographySx}>
            {parts.map(
                (part, index) =>
                    part.toLowerCase() === highlightedTextPart?.toLowerCase()
                        ? <Typography
                            component="span"
                            key={index}
                            sx={{ background: theme.palette.warning.light }}>
                            {part}
                        </Typography>
                        : part
            )}
        </Typography>);
}