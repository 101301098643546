﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../common";


export function useCiIntegrationModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCiIntegrationItems.hooks.useCiIntegrationModelStatusTranslator",
            () => ({
                [Contract.TypeNames.CiIntegrationModelStatus]: {
                    [Contract.CiIntegrationModelStatus.AuthenticationFailed]: {
                        title: "Disconnected",
                        tooltip: "Failed to authenticate to this registry. Check authentication details"
                    },
                    [Contract.CiIntegrationModelStatus.Deleting]: {
                        title: "Deleting..."
                    },
                    [Contract.CiIntegrationModelStatus.EndpointNotExist]: {
                        title: "Endpoint Connector integration is not connected"
                    },
                    [Contract.CiIntegrationModelStatus.RegistryNotFound]: {
                        title: "Disconnected",
                        tooltip: "Failed to connect to this registry. Check the URL"
                    },
                    [Contract.CiIntegrationModelStatus.Valid]: {
                        title: "Connected"
                    }
                }
            }));

    return (status: Contract.CiIntegrationModelStatus) => ({
        title: localization[Contract.TypeNames.CiIntegrationModelStatus][status].title(),
        tooltip:
            _.has(localization[Contract.TypeNames.CiIntegrationModelStatus][status], "tooltip")
                ? localization[Contract.TypeNames.CiIntegrationModelStatus][status].translate("tooltip")
                : undefined
    });
}