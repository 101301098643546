import { Divider, Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { Children, ReactNode } from "react";
import { Page, usePageContext } from "..";
import { LogoTextIcon, useTheme } from "../../../../../../common";

type ContentPageProps = {
    children: ReactNode;
    number?: number;
    spacing?: 0 | 3 | 5;
    title?: string;
};

export function ContentPage({ children, number, spacing = 5, title }: ContentPageProps) {
    const { reportTitle } = usePageContext();
    const theme = useTheme();
    return (
        <Page
            number={number}
            sx={{ backgroundColor: theme.palette.background.paper }}>
            <Grid
                container={true}
                direction="column"
                sx={{ height: "inherit" }}>
                <Grid
                    alignItems="center"
                    container={true}
                    style={{
                        background: "linear-gradient(247.48deg, #B3D6F3 9.77%, #E9FCFF 105.63%)",
                        colorAdjust: "exact",
                        height: theme.spacing(8),
                        paddingLeft: theme.spacing(2.5)
                    }}>
                    <Grid item={true}>
                        <LogoTextIcon/>
                    </Grid>
                    <Divider
                        flexItem={true}
                        orientation="vertical"
                        style={{
                            backgroundColor: "#37474F",
                            margin: "20px 8px"
                        }}/>
                    <Grid item={true}>
                        <Typography
                            style={{
                                fontSize: "16px",
                                fontWeight: 500
                            }}>
                            {reportTitle}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container={true}
                    sx={{ padding: theme.spacing(5, 3, 3) }}>
                    <Grid
                        container={true}
                        direction="column"
                        spacing={spacing}
                        wrap="nowrap">
                        {!_.isNil(title) && (
                            <Grid item={true}>
                                <Typography
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 600
                                    }}>
                                    {title.toUpperCase()}
                                </Typography>
                            </Grid>)}
                        {_.map(
                            Children.toArray(children),
                            (childElement, childElementIndex) =>
                                <Grid
                                    item={true}
                                    key={childElementIndex}>
                                    {childElement}
                                </Grid>)}
                    </Grid>
                </Grid>
            </Grid>
        </Page>);
}