﻿import { DataTableColumn, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceDefinition } from ".";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsBedrockCustomModelCustomizationTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsBedrockCustomModelDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const customModelCustomizationTypeTranslator = useAwsBedrockCustomModelCustomizationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsBedrockCustomModelDefinition",
            () => ({
                columns: {
                    customizationType: "Customization type"
                }
            }));

    function GetCustomizationTypeTranslation(entityModel: Contract.EntityModel) {
        return _.isNil((entityModel.entity as Contract.AwsBedrockCustomModel).customizationType)
            ? undefined
            : customModelCustomizationTypeTranslator((entityModel.entity as Contract.AwsBedrockCustomModel).customizationType!);
    }

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsBedrockCustomModelModel) => ({
                                [localization.columns.customizationType()]: GetCustomizationTypeTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsBedrockCustomModelCustomizationType}
                                    enumTypeTranslator={customModelCustomizationTypeTranslator}
                                    placeholder={localization.columns.customizationType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsBedrockCustomModelCustomizationType}
                    itemProperty={GetCustomizationTypeTranslation}
                    key={Contract.EntityModelProperty.AwsBedrockCustomModelCustomizationType}
                    title={localization.columns.customizationType()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsBedrockCustomModelRequest(
                new Contract.EntityControllerGetEntityModelPageAwsBedrockCustomModelRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsBedrockCustomModelCustomizationType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}