﻿import { defined, Optional, TimeSpanHelper } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract } from "../../../../../../../../../common";

export abstract class PermissionEligibilityData {
    public approval: PermissionEligibilityDataApproval;
    public description: Optional<string>;
    public expirationTimeFrameHours: number = 6;
    public granteePrincipalIds: string[] = [];
    public reasonRequired: boolean;
    public scopeId: string;
    private _name: string = "";
    private _principalTenantId: Optional<string>;

    protected constructor(
        permissionEligibilityModel?: Optional<Contract.PermissionEligibilityModel>,
        scopeId?: string) {
        this.approval =
            _.isNil(permissionEligibilityModel?.configuration.approval)
                ? new PermissionEligibilityDataApproval(
                    true,
                    { [0]: [] },
                    [],
                    [],
                    [])
                : new PermissionEligibilityDataApproval(
                    false,
                    permissionEligibilityModel!.levelToExistingApproverPrincipalIdsMap,
                    permissionEligibilityModel!.configuration.approval.mails,
                    permissionEligibilityModel!.configuration.approval.slackChannelReferences,
                    permissionEligibilityModel!.configuration.approval.teamsChannelReferences);
        if (!_.isNil(permissionEligibilityModel)) {
            this.description = permissionEligibilityModel.configuration.description;
            this.expirationTimeFrameHours = TimeSpanHelper.getHours(permissionEligibilityModel.configuration.expirationTimeFrame);
            this.granteePrincipalIds = permissionEligibilityModel.existingGranteePrincipalIds;
            this._name = permissionEligibilityModel.configuration.name;
            this._principalTenantId = permissionEligibilityModel.configuration.principalTenantId;
            this.reasonRequired = permissionEligibilityModel.configuration.reasonRequired;
            this.scopeId = permissionEligibilityModel.configuration.scopeId;
        } else {
            this.reasonRequired = true;
            this.scopeId = defined(scopeId);
        }
    }

    public get name() {
        return this._name;
    }

    public get principalTenantId() {
        return this._principalTenantId;
    }

    public setName(value: string) {
        if (this._name !== value) {
            this._name = value;
            this.UpdateNameRelatedData();
        }
    }

    public setPrincipalTenantId(value: string) {
        if (this._principalTenantId !== value) {
            this._principalTenantId = value;
            this.UpdatePrincipalTenantRelatedData();
        }
    }

    protected UpdateNameRelatedData() {
    }

    protected UpdatePrincipalTenantRelatedData() {
        this.granteePrincipalIds = [];
        if (!_.isNil(this.approval)) {
            this.approval.levelToApproverPrincipalIdsMap =
                _.mapValues(
                    this.approval.levelToApproverPrincipalIdsMap,
                    () => []);
        }
    }
}

export class PermissionEligibilityDataApproval {
    constructor(
        public auto: boolean,
        public levelToApproverPrincipalIdsMap: Dictionary<string[]>,
        public mails: string[],
        public slackChannelReferences: Contract.SlackConversationReference[],
        public teamsChannelReferences: Contract.TeamsChannelReference[]) {
    }


    public createConfigurationControllerPermissionEligibilityApproval() {
        return this.auto
            ? undefined
            : new Contract.ConfigurationControllerPermissionEligibilityApproval(
                this.levelToApproverPrincipalIdsMap,
                this.mails,
                this.slackChannelReferences,
                this.teamsChannelReferences);
    }
}