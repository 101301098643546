﻿import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { KubernetesView } from "../../../views/Customer/components";
import { KubernetesAdmissionControllerRiskPoliciesIcon, KubernetesClusterAdmissionControllerEventIcon, SystemKubernetesClusterIcon } from "./icons";

type KubernetesViewIconProps =
    SvgIconProps & {
        view: KubernetesView;
    };

export function KubernetesViewIcon({ view, ...props }: KubernetesViewIconProps) {
    switch (view) {
        case KubernetesView.KubernetesAdmissionControllerRiskPolicies:
            return <KubernetesAdmissionControllerRiskPoliciesIcon {...props}/>;
        case KubernetesView.KubernetesClusterAdmissionControllerEvents:
            return <KubernetesClusterAdmissionControllerEventIcon {...props}/>;
        case KubernetesView.SystemKubernetesClusters:
            return <SystemKubernetesClusterIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}