﻿import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, EntitiesCell, EntityFilter, ItemSelectionHelper } from "../../../../../../../../../common";
import { TableColumnId } from "../../../Table";
import { TenantDefinition } from "../useDefinition";

export function useOneLoginDefinition(filters: Contract.PermissionManagementControllerPermissionEligibilityFilters): TenantDefinition {
    const oneLoginFilters = filters as Contract.PermissionManagementControllerOneLoginEligibilityFilters;
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.hooks.useDefinition.hooks.useOneLoginDefinition",
            () => ({
                columns: {
                    roleIds: "Roles"
                }
            }));
    return new TenantDefinition(
        (commonFilters, filterMap, limit, scope, skip, sort) =>
            new Contract.PermissionManagementControllerGetOneLoginPermissionEligibilityModelPageRequest(
                new Contract.PermissionManagementControllerGetOneLoginEligibilityModelPageRequestFilters(
                    commonFilters.approvalRequired,
                    commonFilters.approverPrincipalIdSelection,
                    commonFilters.granteePrincipalIdSelection,
                    commonFilters.nameSelection,
                    commonFilters.principalTenantIdSelection,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.OneLoginRoleIds])),
                limit,
                scope,
                skip,
                sort),
        getEntitiesExportOptions =>
            [
                <DataTableColumn
                    exportOptions={
                        getEntitiesExportOptions(
                            item => (item as Contract.OneLoginDirectoryRoleAssignmentEligibilityModel).roleIdReferences,
                            localization.columns.roleIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={oneLoginFilters.roleSearchableReferences}
                                    placeholder={localization.columns.roleIds()}/>
                        }
                    }}
                    id={TableColumnId.OneLoginRoleIds}
                    key={TableColumnId.OneLoginRoleIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.OneLoginDirectoryRoleAssignmentEligibilityModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.roleIdReferences}
                                entityLinkOptions={{ disabled: true }}
                                entityTypeName={Contract.TypeNames.OneLoginDirectoryRole}
                                entityVariant="iconText"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.roleIds()}/>
            ]);
}