import { CheckboxField, EmptyMessage, InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, Loading, Optional, PagedDropdownPage, SearchTextField, useChangeEffect, useUncaptureValue } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { Contract } from "../../../../../../../controllers";
import { useTheme } from "../../../../../../../themes";
import { UdmObjectPropertyItem } from "../../../../UdmObjectPropertyItem";

type ListFilterPopoverProps = {
    emptyText?: string;
    getItemPage: (searchText: Optional<string>, skip: number, data?: any) => Promise<PagedDropdownPage>;
    headerElement?: React.ReactNode;
    itemAdditionalProps?: object;
    objectProperty: Optional<Contract.UdmObjectProperty>;
    objectTypeName: string;
    onItemPageLoad?: (items: any[]) => void;
} & ({
    renderItem: (item: any) => React.ReactNode;
} | {
    setValues: React.Dispatch<React.SetStateAction<string[]>>;
    values: string[];
});

export function ListFilterPopover({ emptyText, getItemPage, headerElement, objectProperty, objectTypeName, onItemPageLoad, ...props }: ListFilterPopoverProps) {
    const dataRef = useRef<any>();
    const firstItemPageRef = useRef<PagedDropdownPage>();
    const [items, setItems] = useState<any[]>([]);
    const [emptyValue, setEmptyValue] = useState<Optional<boolean>>();
    const [searchText, setSearchText] = useState<string>();

    async function fetchItemPage() {
        if (firstItemPageRef.current?.hasMore === false) {
            return new InfiniteScrollFetchDataResult(false, true);
        }

        const itemPage =
            await getItemPage(
                searchText,
                items.length,
                dataRef.current);

        return new InfiniteScrollFetchDataResult(
            itemPage.items.length > 0,
            !itemPage.hasMore,
            () => {
                setItems(items => {
                    const newItems = _.concat(items, itemPage.items);
                    onItemPageLoad?.(newItems);
                    return newItems;
                });
                setEmptyValue(_.isEmpty(searchText) && (itemPage.emptyValue || emptyValue));
                dataRef.current = itemPage.applyData?.();
                firstItemPageRef.current = firstItemPageRef.current ?? itemPage;
            });
    };

    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>(null);
    useChangeEffect(
        () => {
            setEmptyValue(false);

            setItems([]);
            dataRef.current = undefined;
            firstItemPageRef.current = undefined;

            infiniteScrollActionsRef.current!.reset();
        },
        [searchText],
        500);

    const uncaptureSelectedItems =
        useUncaptureValue(
            "values" in props
                ? (props.values ?? [])
                : []);

    const theme = useTheme();
    return (
        <Stack spacing={1}>
            <Stack spacing={1}>
                <SearchTextField
                    searchText={searchText}
                    onSearchTextChanged={searchText => setSearchText(searchText)}/>
                {headerElement}
            </Stack>
            <Box sx={{ flex: 1 }}>
                <Loading>
                    <InfiniteScroll
                        actionsRef={infiniteScrollActionsRef}
                        container="popup"
                        fetchData={fetchItemPage}
                        sx={{ maxHeight: theme.spacing(50) }}>
                        {!_.isNil(firstItemPageRef.current) &&
                            _.isEmpty(firstItemPageRef.current.items) &&
                            emptyText != undefined &&
                            !emptyValue
                            ? <EmptyMessage
                                message={emptyText}
                                size="small"/>
                            : _.map(
                                items,
                                item =>
                                    "renderItem" in props
                                        ? props.renderItem(item)
                                        : <CheckboxField
                                            checked={_.includes(props.values, item)}
                                            key={item}
                                            sx={{ width: "100%" }}
                                            onChange={
                                                () =>
                                                    uncaptureSelectedItems(
                                                        selectedItems => (
                                                            _.includes(selectedItems, item)
                                                                ? props.setValues(_.without(selectedItems, item))
                                                                : props.setValues(_.concat(selectedItems, item))))}>
                                            <Typography noWrap={true}>
                                                <Loading container="cell">
                                                    <UdmObjectPropertyItem
                                                        filter={true}
                                                        item={item}
                                                        objectId={item}
                                                        objectProperty={objectProperty!}
                                                        objectTypeName={objectTypeName}/>
                                                </Loading>
                                            </Typography>
                                        </CheckboxField>)}
                    </InfiniteScroll>
                </Loading>
            </Box>
        </Stack>);
}