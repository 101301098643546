import { ObjectTypeCategory } from "../../../../../common/controllers/types.generated";

export const entitiesObjectCategories = [
    ObjectTypeCategory.EntityAi,
    ObjectTypeCategory.EntityCompute,
    ObjectTypeCategory.EntityContainers,
    ObjectTypeCategory.EntityData,
    ObjectTypeCategory.EntityIam,
    ObjectTypeCategory.EntityIot,
    ObjectTypeCategory.EntityKubernetes,
    ObjectTypeCategory.EntityManagement,
    ObjectTypeCategory.EntityNetwork,
    ObjectTypeCategory.EntitySecurity
];