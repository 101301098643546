import { UnexpectedError } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, KubernetesIcon, WorkloadAnalysisIcon } from "../..";
import { AccessIcon, BehaviorIcon, ComputeIcon, CustomIcon, DataIcon, LoggingIcon, ManagementIcon, MonitoringIcon, NetworkIcon, SecretsIcon } from "./icons";

type RiskPolicyCategoryIconProps =
    SvgIconProps & {
        riskPolicyCategory: Contract.RiskPolicyCategory;
    };

export function RiskPolicyCategoryIcon({ riskPolicyCategory, ...props }: RiskPolicyCategoryIconProps) {
    switch (riskPolicyCategory) {
        case Contract.RiskPolicyCategory.Access:
            return <AccessIcon {...props}/>;
        case Contract.RiskPolicyCategory.Behavior:
            return <BehaviorIcon {...props}/>;
        case Contract.RiskPolicyCategory.Compute:
            return <ComputeIcon {...props}/>;
        case Contract.RiskPolicyCategory.Custom:
            return <CustomIcon {...props}/>;
        case Contract.RiskPolicyCategory.Data:
            return <DataIcon {...props}/>;
        case Contract.RiskPolicyCategory.Kubernetes:
            return <KubernetesIcon {...props}/>;
        case Contract.RiskPolicyCategory.Logging:
            return <LoggingIcon {...props}/>;
        case Contract.RiskPolicyCategory.Management:
            return <ManagementIcon {...props}/>;
        case Contract.RiskPolicyCategory.Monitoring:
            return <MonitoringIcon {...props}/>;
        case Contract.RiskPolicyCategory.Network:
            return <NetworkIcon {...props}/>;
        case Contract.RiskPolicyCategory.Secrets:
            return <SecretsIcon {...props}/>;
        case Contract.RiskPolicyCategory.WorkloadAnalysis:
            return <WorkloadAnalysisIcon {...props}/>;
        default:
            throw new UnexpectedError("riskPolicyCategory", riskPolicyCategory);
    }
}