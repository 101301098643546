import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OktaFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H5.61757C5.54019 17.5114 5.5 17.0104 5.5 16.5C5.5 11.2505 9.7505 7 15 7C18.3603 7 21.3112 8.74162 23 11.372V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                fill="#BAC5D3"/>
            <path
                clipRule="evenodd"
                d="M8 16.5C8 12.6312 11.1312 9.5 15 9.5C18.8688 9.5 22 12.6312 22 16.5C22 20.3688 18.8688 23.5 15 23.5C11.1312 23.5 8 20.3688 8 16.5ZM11.493 16.5C11.493 18.4344 13.0656 20.007 15 20.007C16.9344 20.007 18.507 18.4344 18.507 16.5C18.507 14.5656 16.9344 12.993 15 12.993C13.0656 12.993 11.493 14.5656 11.493 16.5Z"
                fill="#007DC1"
                fillRule="evenodd"/>
        </SvgIcon>);
}