import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Events } from "../../../../../../../../..";
import { RiskContentProps } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, InlineIpAddresses, InlinePermissionActions, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../utilities";

export function useAzureCommonBehaviorIdentityRiskDefinition(
    riskModel: Contract.RiskModel,
    description: string) {
    const risk = riskModel.risk as Contract.AzureBehaviorIdentityRisk;
    const identityModel = entityModelStore.useGet(risk.identityId);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.behavior.useAzureCommonBehaviorIdentityRiskDefinition",
            () => ({
                sections: {
                    events: "Unusual Activity of {{identity}}",
                    infos: "Details"
                }
            }));

    return useMemo(
        () => ({
            description,
            sections: [
                new RiskDefinitionSection(
                    <InfosSection riskModel={riskModel}/>,
                    localization.sections.infos()),
                new RiskDefinitionSection(
                    <Events riskId={risk.id}/>,
                    localization.sections.events({
                        identity:
                        <Entity
                            entityIdOrModel={identityModel}
                            variant="text"/>
                    }))
            ]
        }),
        [riskModel, risk, identityModel, description]);
}

function InfosSection({ riskModel }: RiskContentProps) {
    const behaviorIdentityRiskModel = riskModel as Contract.AzureBehaviorIdentityRiskModel;
    const identityModel = entityModelStore.useGet(behaviorIdentityRiskModel.risk.identityId);
    const identity = identityModel.entity as Contract.AadDirectoryIdentity;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.behavior.useAzureCommonBehaviorIdentityRiskDefinition.infosSection",
            () => ({
                [Contract.TypeNames.AzureBehaviorIdentityRiskModelInfo]: {
                    [Contract.AzureBehaviorIdentityRiskModelInfo.EventActions]: "Actions: {{eventActions}}",
                    [Contract.AzureBehaviorIdentityRiskModelInfo.IpAddresses]: "Source IP addresses: {{ipAddresses}}",
                    [Contract.AzureBehaviorIdentityRiskModelInfo.PreviousBehaviorDateEver]: {
                        [Contract.TypeNames.AzureNetworkAccessManagementRisk]: "{{translatedIdentityTypeName}} {{identity}} was not observed modifying network access settings since: {{previousBehaviorDate | TimeFormatter.longDate}}",
                        [Contract.TypeNames.AzurePermissionManagementRisk]: "{{translatedIdentityTypeName}} {{identity}} was not observed modifying similar permissions since: {{previousBehaviorDate | TimeFormatter.longDate}}"
                    },
                    [Contract.AzureBehaviorIdentityRiskModelInfo.PreviousBehaviorDateNever]: {
                        [Contract.TypeNames.AzureNetworkAccessManagementRisk]: "{{translatedIdentityTypeName}} {{identity}} was not observed modifying network access settings before",
                        [Contract.TypeNames.AzurePermissionManagementRisk]: "{{translatedIdentityTypeName}} {{identity}} was not observed modifying similar permissions before"
                    },
                    [Contract.AzureBehaviorIdentityRiskModelInfo.Resources]: "Affected resources: {{resources}}"
                }
            }));
    const createInfoProps =
        () => ({
            eventActions:
                <InlinePermissionActions
                    permissionActions={behaviorIdentityRiskModel.risk.eventActions}
                    variant="itemOrItemCountAndType"/>,
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    variant="text"/>,
            ipAddresses: <InlineIpAddresses ipAddresses={behaviorIdentityRiskModel.risk.ipAddresses}/>,
            previousBehaviorDate: behaviorIdentityRiskModel.risk.previousBehaviorDate,
            resources:
                <InlineEntities
                    entityIdsOrModels={behaviorIdentityRiskModel.resourceIdReferences}
                    entityTypeName={Contract.TypeNames.AwsResource}
                    variant="itemAndTypeOrItemCountAndType"/>,
            translatedIdentityTypeName:
                entityTypeNameTranslator(
                    identity.typeName,
                    {
                        includeServiceName: false
                    })
        });
    return (
        <Steps>
            {_.map(
                behaviorIdentityRiskModel.infos,
                info => {
                    switch (info) {
                        case Contract.AzureBehaviorIdentityRiskModelInfo.PreviousBehaviorDateEver:
                        case Contract.AzureBehaviorIdentityRiskModelInfo.PreviousBehaviorDateNever:
                            return localization[Contract.TypeNames.AzureBehaviorIdentityRiskModelInfo][info][(behaviorIdentityRiskModel.risk.typeName as BehaviorRiskTypeNames)](createInfoProps());
                        default:
                            return localization[Contract.TypeNames.AzureBehaviorIdentityRiskModelInfo][info](createInfoProps());
                    }
                })}
        </Steps>);
}


type BehaviorRiskTypeNames =
    Contract.TypeNames.AzureNetworkAccessManagementRisk |
    Contract.TypeNames.AzurePermissionManagementRisk;