import { CollapsedIcon, TimeFormatter, useLocalization, useLocalizeList } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InlineEntities, useTheme } from "../../../../../../../../../../common";
import { useDefinition, useEntitySnapshotChangeTranslator } from "./hooks";

type SnapshotProp = {
    entitySnapshot: Contract.EntitySnapshot;
};

export function Snapshot({ entitySnapshot }: SnapshotProp) {
    const relatedEntityModels = entityModelStore.useGet(entitySnapshot.relatedEntityIds);
    const entityModels =
        entityModelStore.useGet(
            _.map(
                entitySnapshot.changes,
                entitySnapshotChange => entitySnapshotChange.entityId));
    const entityModelMap =
        _.keyBy(
            entityModels,
            entityModel => entityModel.id);
    const entitySnapshotChangeTypeNameToEntityModelsMap =
        _(entitySnapshot.changes).
            groupBy(entitySnapshotChange => entitySnapshotChange.typeName).
            mapValues(
                entitySnapshotChanges =>
                    _.map(
                        entitySnapshotChanges,
                        entitySnapshotChange => entityModelMap[entitySnapshotChange.entityId])).
            value();


    const definition = useDefinition(entitySnapshot);
    const entitySnapshotChangeTranslator = useEntitySnapshotChangeTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.entities.profile.snapshots.snapshot",
            () => ({
                summary: {
                    initialChange: "Initial snapshot"
                }
            }));

    const theme = useTheme();
    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<CollapsedIcon/>}>
                <Stack>
                    <Typography variant="subtitle1">
                        {TimeFormatter.longDateTime(entitySnapshot.systemCreationTime)}
                    </Typography>
                    <Stack>
                        <Typography variant="h4">
                            {_.isEmpty(entitySnapshotChangeTypeNameToEntityModelsMap)
                                ? localization.summary.initialChange()
                                : localizeList(
                                    _.map(
                                        entitySnapshotChangeTypeNameToEntityModelsMap,
                                        (entityModels, entitySnapshotChangeTypeName) =>
                                            entitySnapshotChangeTranslator(
                                                entitySnapshotChangeTypeName,
                                                entityModels)))}
                        </Typography>
                        <Box sx={{ marginTop: theme.spacing(0.5) }}>
                            {localizeList(
                                _(relatedEntityModels).
                                    groupBy(relatedEntityModel => relatedEntityModel.entity.typeName).
                                    map(
                                        (relatedEntityModels, relatedEntityTypeName) =>
                                            <InlineEntities
                                                entityIdsOrModels={relatedEntityModels}
                                                entityTypeName={relatedEntityTypeName}
                                                key={relatedEntityTypeName}
                                                variant="itemCountAndType"/>).
                                    value())}
                        </Box>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    spacing={3}
                    sx={{ padding: theme.spacing(2) }}>
                    <Typography>
                        {definition.description}
                    </Typography>
                    {_.map(
                        definition.tableElements,
                        (tableElement, tableElementIndex) =>
                            <Box key={tableElementIndex}>
                                {tableElement}
                            </Box>)}
                </Stack>
            </AccordionDetails>
        </Accordion>);
}