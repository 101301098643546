import { useChangeEffect, useLocalization } from "@infrastructure";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, RiskPoliciesType, UserHelper, useTheme } from "../../../../../../../../common";
import { useRiskPoliciesItemConfiguration } from "../../../../hooks";
import { useConfigurationContext, useSetConfigurationContext } from "../../Configuration";
import { SecretPattern } from "./SecretPattern";

type SecretExclusionProps = {
    riskPoliciesType: RiskPoliciesType;
    title: string;
};

export function SecretExclusion({ riskPoliciesType, title }: SecretExclusionProps) {
    const { item, riskPolicyConfiguration, scopeId } = useConfigurationContext();
    const { inheritDisabled, scopeIdToRiskPolicyModelMap } = useRiskPoliciesItemConfiguration(riskPoliciesType, item, scopeId);
    const setConfigurationContext = useSetConfigurationContext();

    const [inheritedSecretPatterns, secretPatternKeyToScopeIdsMap] =
        useMemo(
            () => {
                const secretExclusionPatternDatas =
                    _(scopeIdToRiskPolicyModelMap as _.Dictionary<Contract.RiskPolicyModel>).
                        flatMap(
                            (riskPolicyModel, scopeId) =>
                                _((riskPolicyModel.riskPolicyConfiguration as Contract.SecretExistsRiskPolicyConfiguration).exclusionSecretPatterns).
                                    map(secretExclusionPattern => ({ scopeId, secretExclusionPattern })).
                                    value()).
                        filter().
                        value();
                const inheritedSecretPatterns =
                    _(secretExclusionPatternDatas).
                        map(secretExclusionPatternData => secretExclusionPatternData.secretExclusionPattern).
                        value();
                const secretPatternKeyToScopeIdsMap =
                    _(secretExclusionPatternDatas).
                        groupBy(secretExclusionPatternData => `${secretExclusionPatternData.secretExclusionPattern.keyPattern}-${secretExclusionPatternData.secretExclusionPattern.valuePattern}`).
                        mapValues(
                            secretPatternDatas =>
                                _(secretPatternDatas).
                                    map(secretPatternData => secretPatternData.scopeId).
                                    value()).
                        value();
                return [inheritedSecretPatterns, secretPatternKeyToScopeIdsMap];
            },
            [scopeIdToRiskPolicyModelMap]);

    const userSecurityWrite =
        useMemo(
            () =>
                UserHelper.hasScopePermissions(
                    riskPolicyConfiguration.scopeId,
                    Contract.IdentityPermission.SecurityWrite),
            [riskPolicyConfiguration]);
    const initialValue =
        useMemo(
            () =>
                _((riskPolicyConfiguration as Contract.SecretExistsRiskPolicyConfiguration).exclusionSecretPatterns ?? []).
                    concatIf(
                        !inheritDisabled,
                        inheritedSecretPatterns).
                    map(
                        exclusionSecretPattern =>
                            new ExclusionSecretPatternItem({
                                ...exclusionSecretPattern,
                                message:
                                    _.isNil(exclusionSecretPattern.message)
                                        ? undefined
                                        : exclusionSecretPattern.message
                            },
                            false)).
                    orderBy(exclusionSecretPatternItem => _.isEmpty(secretPatternKeyToScopeIdsMap[exclusionSecretPatternItem.key])).
                    concatIf(
                        userSecurityWrite,
                        getEmptyExclusionSecretPatternItem()).
                    value(),
            []);
    const [exclusionSecretPatternItems, setExclusionSecretPatternItems] = useState(initialValue);

    const exclusionSecretPatternItemKeyToDuplicateMap =
        useMemo(
            () =>
                _(exclusionSecretPatternItems).
                    keyBy(exclusionSecretPatternItem => exclusionSecretPatternItem.key).
                    mapValues(exclusionSecretPatternItem => isItemDuplicate(exclusionSecretPatternItems, exclusionSecretPatternItem.key)).
                    value(),
            [exclusionSecretPatternItems]);

    function isItemDuplicate(items: ExclusionSecretPatternItem[], key: string): boolean {
        return _(items).
            filter(item => item.key === key).
            size() > 1;
    }

    useChangeEffect(
        () => {
            const newItems =
                _([...exclusionSecretPatternItems]).
                    slice(0, -1).
                    filter(exclusionSecretPatternItem => _.isEmpty(secretPatternKeyToScopeIdsMap[exclusionSecretPatternItem.key])).
                    value();

            const valid =
                _.every(
                    newItems,
                    item => item.isValid()) &&
                _(exclusionSecretPatternItems).
                    uniqBy(item => item.key).
                    size() === _.size(exclusionSecretPatternItems);

            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    additionalTabIdToSectionMap: {
                        ...configurationContext.additionalTabIdToSectionMap,
                        [title]: { valid: valid && _.some( exclusionSecretPatternItems, exclusionSecretPatternItem => exclusionSecretPatternItem.changed) }
                    },
                    dirty: true,
                    riskPolicyConfiguration: {
                        ...configurationContext.riskPolicyConfiguration,
                        exclusionSecretPatterns:
                            _.map(
                                newItems,
                                exclusionSecretPatternItem => exclusionSecretPatternItem.exclusionSecretPattern)
                    }
                }));
        },
        [exclusionSecretPatternItems]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.secretExclusion.secretExclusion",
            () => ({
                secretPatternInfo: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character.",
                subtitle: "Exclude secrets from this policy to address detected false-positives. Secrets consist of a key/value pair, either one of which may contain the secret. Enter a pattern you would like to exclude. If the pattern matches the secret, Tenable Cloud Security will exclude the secret. For example, use the value pattern \"https://*\" to exclude any URLs that were falsely detected as secrets.",
                title: "Exclude by Pattern"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                padding: theme.spacing(2, 2, 2, 3)
            }}>
            <Stack spacing={1}>
                <Typography variant="h4">
                    {localization.title()}
                </Typography>
                <Typography sx={{ maxWidth: "55%" }}>
                    {localization.subtitle()}
                </Typography>
            </Stack>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.secretPatternInfo()}
            </Typography>
            <Box
                sx={{
                    flex: 1,
                    overflowY: "auto"
                }}>
                <List disablePadding={true}>
                    {_(exclusionSecretPatternItems).
                        map(
                            (exclusionSecretPatternItem, index) =>
                                <ListItem
                                    key={exclusionSecretPatternItem.id}
                                    sx={{ paddingLeft: 0 }}>
                                    <SecretPattern
                                        duplicate={exclusionSecretPatternItemKeyToDuplicateMap[exclusionSecretPatternItem.key]}
                                        exclusionSecretPattern={exclusionSecretPatternItem.exclusionSecretPattern}
                                        last={_.size(exclusionSecretPatternItems) === index + 1}
                                        readOnly={!userSecurityWrite}
                                        scopeIds={
                                            exclusionSecretPatternItem.dirty || inheritDisabled
                                                ? undefined
                                                : secretPatternKeyToScopeIdsMap[exclusionSecretPatternItem.key]}
                                        valid={exclusionSecretPatternItem.isValid()}
                                        onDelete={
                                            () => {
                                                const items =
                                                    _.without(
                                                        exclusionSecretPatternItems,
                                                        exclusionSecretPatternItem);
                                                setExclusionSecretPatternItems(items);
                                            }}
                                        onExclusionSecretPatternChanged={
                                            exclusionSecretPattern => {
                                                const newItems = _.cloneDeep(exclusionSecretPatternItems);
                                                const itemIndex =
                                                    _.findIndex(
                                                        newItems,
                                                        currentItem => currentItem.id === exclusionSecretPatternItem.id);
                                                const newItem =
                                                    new ExclusionSecretPatternItem(
                                                        exclusionSecretPattern,
                                                        true,
                                                        exclusionSecretPatternItem.id,
                                                        !_.isEqual(initialValue[itemIndex]?.exclusionSecretPattern, exclusionSecretPattern));
                                                newItems.splice(itemIndex, 1, newItem);
                                                setExclusionSecretPatternItems(
                                                    _(newItems).
                                                        concatIf(
                                                            _.size(exclusionSecretPatternItems) === index + 1,
                                                            getEmptyExclusionSecretPatternItem()).
                                                        value());
                                            }}/>
                                </ListItem>).
                        value()}
                </List>
            </Box>
        </Stack>);
}

function getEmptyExclusionSecretPatternItem() {
    return new ExclusionSecretPatternItem(
        new Contract.SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern(
            false,
            "",
            undefined,
            ""),
        true,
        undefined,
        false);
}

export class ExclusionSecretPatternItem {
    private static _idCounter = 0;
    public dirty: boolean;
    public id: number;
    public key: string;
    public changed: boolean;

    constructor(
        public exclusionSecretPattern: Contract.SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern,
        dirty?: boolean,
        id?: number,
        changed?: boolean) {
        this.dirty = dirty ?? false;
        this.id = id ?? ExclusionSecretPatternItem._idCounter++;
        this.key = `${exclusionSecretPattern.keyPattern}-${exclusionSecretPattern.valuePattern}`;
        this.changed = changed ?? false;
    }

    public isValid(): boolean {
        return !_.isEmpty(this.exclusionSecretPattern.keyPattern) &&
            !_.isEmpty(this.exclusionSecretPattern.valuePattern);
    }
}