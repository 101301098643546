﻿import { Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";

type AwsIamPrincipalCriticalActionSeverityPermissionProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

export function AwsIamPrincipalCriticalActionSeverityPermission({ entityTypeName, includeServiceName }: AwsIamPrincipalCriticalActionSeverityPermissionProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.awsIamPrincipalCriticalActionSeverityPermission",
            () => ({
                message: "{{translatedEntityTypeName}} which are admins or can escalate their privileges to an admin.",
                title: "*capitalize*{{translatedEntityTypeName}}** with high privileges"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <Message
                level="info"
                title={
                    localization.message({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: 0,
                                    includeServiceName,
                                    variant: "text"
                                })
                    })}
                variant="minimal"/>
        </Stack>);
}