import { IconText, Link } from "@infrastructure";
import { SxProps } from "@mui/material";
import React from "react";
import { useCodeTenantTypeTranslator } from "../../tenants";
import { Contract } from "../controllers";
import { TenantIcon } from "../icons";
import { useTheme } from "../themes";
import { TenantHelper } from "../utilities";

interface CodeTenantLinkProps {
    iconSx?: SxProps;
    linkSx?: SxProps;
    spacing?: number;
    tenantConfiguration: Contract.CodeTenantConfiguration;
}

export function CodeTenantLink({ iconSx, linkSx, spacing, tenantConfiguration }: CodeTenantLinkProps) {
    const codeTenantTypeTranslator = useCodeTenantTypeTranslator();

    const theme = useTheme();
    return (
        <IconText
            containerSx={{ paddingRight: 0 }}
            icon={
                <TenantIcon
                    data={{
                        codeTenantType: tenantConfiguration.codeTenantType,
                        generalCodeTenant: TenantHelper.isGeneralCodeTenant(tenantConfiguration)
                    }}
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px",
                        ...iconSx
                    }}
                    tenantType={tenantConfiguration.type}/>}
            spacing={spacing}
            text={
                <Link
                    noWrap={true}
                    sx={{
                        maxWidth: "100%",
                        ...linkSx
                    }}
                    urlOrGetUrl={tenantConfiguration.url}
                    variant="external">
                    {tenantConfiguration.displayReference}
                </Link>}
            titleOrGetTitle={codeTenantTypeTranslator(tenantConfiguration.codeTenantType)}/>);
}