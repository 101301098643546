import { useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import { memo } from "react";
import { Contract } from "../../../../common";
import { usePermissionRequestCloseReasonAndStatusTranslator } from "./hooks";

type PermissionRequestCloseReasonAndStatusFilterProps = {
    values: Contract.PermissionRequestCloseReasonAndStatus[];
};

const PermissionRequestCloseReasonAndStatusFilterMemo = memo(PermissionRequestCloseReasonAndStatusFilter);
export { PermissionRequestCloseReasonAndStatusFilterMemo as PermissionRequestCloseReasonAndStatusFilter };

function PermissionRequestCloseReasonAndStatusFilter({ values }: PermissionRequestCloseReasonAndStatusFilterProps) {
    const permissionRequestCloseReasonAndStatusTranslator = usePermissionRequestCloseReasonAndStatusTranslator();
    const localization =
            useLocalization(
                "views.user.permissionRequestCloseReasonAndStatusFilter",
                () => ({
                    title: "Status"
                }));

    return (
        <ValuesFilter placeholder={localization.title()}>
            {_.map(
                values,
                value =>
                    <ValuesFilterItem
                        title={permissionRequestCloseReasonAndStatusTranslator(value)}
                        value={value}/>)}
        </ValuesFilter>);
}