import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, TimeFormatter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsLambdaFunctionConfigurationAccessTypeTranslator } from "../../../../../../../../../../tenants";
import { useAwsLambdaFunctionConfigurationPackageTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsLambdaFunctionConfigurationDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const functionConfigurationPackageTypeTranslator = useAwsLambdaFunctionConfigurationPackageTypeTranslator();

    const lambdaFunctionConfigurationAccessTypeTranslator = useAwsLambdaFunctionConfigurationAccessTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsLambdaFunctionConfigurationDefinition",
            () => ({
                columns: {
                    packageType: "Package Type",
                    publicAccessTypes: "Publicly Accessible Via",
                    roleIds: "Roles",
                    runtime: "Runtime",
                    triggers: "Triggers",
                    updateTime: "Update Time",
                    url: "Function URL"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsLambdaFunctionConfigurationModel) => ({
                            [localization.columns.publicAccessTypes()]:
                                _(item.publicAccessTypes).
                                    map(lambdaFunctionConfigurationAccessTypeTranslator).
                                    sort().
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.AwsLambdaFunctionConfigurationModelAccessType}
                                enumTypeTranslator={lambdaFunctionConfigurationAccessTypeTranslator}
                                placeholder={localization.columns.publicAccessTypes()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationPublicAccessTypes}
                key={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationPublicAccessTypes}
                render={
                    optionalTableCell<Contract.AwsLambdaFunctionConfigurationModel>(
                        (item: Contract.AwsLambdaFunctionConfigurationModel) =>
                            _.isEmpty(item.publicAccessTypes)
                                ? undefined
                                : <Typography noWrap={true}>
                                    <InlineItems
                                        items={
                                            _(item.publicAccessTypes).
                                                map(lambdaFunctionConfigurationAccessTypeTranslator).
                                                sort().
                                                value()}
                                        variant="itemPlusItemCount"/>
                                </Typography>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.publicAccessTypes()}/>,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsLambdaFunctionConfigurationModel) => ({
                            [localization.columns.url()]:
                                _.isNil((item.entity as Contract.AwsLambdaFunctionConfiguration).url)
                                    ? ""
                                    : (item.entity as Contract.AwsLambdaFunctionConfiguration).url?.url
                        })
                }}
                id="url"
                key="url"/>,
            commonNetworkedResourceDefinition.columns.getVpcsColumn(),
            commonNetworkedResourceDefinition.columns.securityGroupsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsLambdaFunctionConfigurationModel>(
                        Contract.TypeNames.AwsIamRole,
                        item => (item.entity as Contract.AwsLambdaFunctionConfiguration).roleIds,
                        localization.columns.roleIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsLambdaFunctionConfigurationRoles)}
                                placeholder={localization.columns.roleIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationRoles}
                key={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationRoles}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsLambdaFunctionConfigurationModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AwsLambdaFunctionConfiguration).roleIds}
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roleIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsLambdaFunctionConfigurationModel>(
                        Contract.TypeNames.AwsResource,
                        item => item.triggerResourceIdReferences,
                        localization.columns.triggers())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsLambdaFunctionConfigurationTriggerResources)}
                                placeholder={localization.columns.triggers()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationTriggerResources}
                key={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationTriggerResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsLambdaFunctionConfigurationModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.triggerResourceIdReferences}
                            entityTypeName={Contract.TypeNames.AwsResource}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.triggers()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsLambdaFunctionConfigurationModel) => ({
                            [localization.columns.updateTime()]: TimeFormatter.iso8601String((item.entity as Contract.AwsLambdaFunctionConfiguration).updateTime)
                        })
                }}
                id={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationUpdateTime}
                itemProperty={(item: Contract.AwsLambdaFunctionConfigurationModel) => TimeFormatter.monthDayAndYear((item.entity as Contract.AwsLambdaFunctionConfiguration).updateTime)}
                key={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationUpdateTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.updateTime()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsLambdaFunctionConfigurationModel) => ({
                            [localization.columns.packageType()]: functionConfigurationPackageTypeTranslator((item.entity as Contract.AwsLambdaFunctionConfiguration).packageType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsLambdaFunctionConfigurationPackageType}
                                enumTypeTranslator={(packageType: Contract.AwsLambdaFunctionConfigurationPackageType) => functionConfigurationPackageTypeTranslator(packageType)}
                                placeholder={localization.columns.packageType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationPackageType}
                itemProperty={(item: Contract.AwsLambdaFunctionConfigurationModel) => functionConfigurationPackageTypeTranslator((item.entity as Contract.AwsLambdaFunctionConfiguration).packageType)}
                key={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationPackageType}
                selectorOptions={{ default: false }}
                title={localization.columns.packageType()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsLambdaFunctionConfigurationModel>(
                        Contract.TypeNames.AwsLambdaFunctionConfiguration,
                        item => (item.entity as Contract.AwsLambdaFunctionConfiguration).runtimes,
                        localization.columns.runtime())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsLambdaFunctionConfigurationRuntimes)}
                                placeholder={localization.columns.runtime()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationRuntimes}
                key={Contract.EntityModelProperty.AwsLambdaFunctionConfigurationRuntimes}
                render={({ item }: DataTableColumnRenderProps<Contract.AwsLambdaFunctionConfigurationModel>) =>
                    <InlineItems
                        items={(item.entity as Contract.AwsLambdaFunctionConfiguration).runtimes}
                        variant="itemPlusItemCount"/>}
                title={localization.columns.runtime()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsLambdaFunctionConfigurationRequest(
                new Contract.EntityControllerGetEntityModelPageAwsLambdaFunctionConfigurationRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsLambdaFunctionConfigurationPackageType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsLambdaFunctionConfigurationPublicAccessTypes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsLambdaFunctionConfigurationRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsLambdaFunctionConfigurationRuntimes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsLambdaFunctionConfigurationTriggerResources])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}