﻿import { Stack, Typography } from "@mui/material";
import React from "react";
import { useLocalization } from "@infrastructure";
import { AnalyzingImage } from "./Images";

export function Analyzing() {
    const localization =
        useLocalization(
            "common.analyzing",
            () => ({
                subtitle: "Stay Tuned...",
                title: "We are currently processing the\n data in the account(s)"
            }));

    return (
        <Stack
            alignItems="center"
            spacing={2}
            sx={{ textAlign: "center" }}>
            <AnalyzingImage/>
            <Typography
                style={{ whiteSpace: "pre-wrap" }}
                sx={{
                    fontSize: "24px",
                    fontWeight: 700
                }}
                variant="h1">
                {localization.title()}
            </Typography>
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: 400
                }}>
                {localization.subtitle()}
            </Typography>
        </Stack>);
}