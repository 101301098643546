import { CheckboxField, Dropdown as InfrastructureDropdown, DropdownAnalyticsOptions, DropdownIcon, SelectionView, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useRiskSubStatusTranslator, useTheme } from "../..";
import { useStatusTranslator } from "../../../views/Customer/components";
import { RiskHelper } from "../../../views/Customer/components/Risks/utilities";
import { Tree, TreeItem } from "../Tree";

export type RiskStatusMultiSelectProps = {
    analyticsOptions?: DropdownAnalyticsOptions;
    fullWidth?: boolean;
    onSelectedSubStatusesChanged: (selectedSubStatuses: Contract.RiskSubStatus[]) => void;
    open?: boolean;
    readonly?: boolean;
    selectedSubStatuses: Contract.RiskSubStatus[];
};

export function RiskStatusMultiSelect({ analyticsOptions, fullWidth, onSelectedSubStatusesChanged, open, readonly = false, selectedSubStatuses }: RiskStatusMultiSelectProps) {
    const statusTranslator = useStatusTranslator();
    const subStatusTranslator = useRiskSubStatusTranslator();
    const localization =
        useLocalization(
            "common.riskStatusMultiSelect",
            () => ({
                placeholder: "Status",
                rootItem: "All Statuses"
            }));

    const rootTreeItem =
        useMemo(
            () =>
                new TreeItem<string>(
                    localization.rootItem(),
                    _.map(
                        [
                            Contract.RiskStatus.Open,
                            Contract.RiskStatus.Ignored,
                            Contract.RiskStatus.Closed
                        ],
                        riskStatus =>
                            new TreeItem<string>(
                                riskStatus,
                                _.map(
                                    RiskHelper.riskStatusToSubStatusMap[riskStatus],
                                    riskSubStatus => new TreeItem<string>(riskSubStatus))))),
            []);

    function toggle(item: TreeItem<string>) {
        const checked =
            _.every(
                item.leaves(),
                item => _.includes(selectedSubStatuses, item.value));

        const itemLeavesValues =
            _.map(
                item.leaves(),
                item => item.value);
        const newTreeItems =
            checked
                ? _.difference(selectedSubStatuses, itemLeavesValues)
                : _.union(selectedSubStatuses, itemLeavesValues);

        onSelectedSubStatusesChanged(newTreeItems as Contract.RiskSubStatus[]);
    }

    const theme = useTheme();

    return (<InfrastructureDropdown
        analyticsOptions={analyticsOptions}
        fullWidth={fullWidth}
        open={open}
        popoverElement={
            <Tree<string>
                containerSx={{
                    color: theme.palette.text.primary,
                    maxWidth: theme.spacing(80),
                    minWidth: theme.spacing(40)
                }}
                isExpanded={treeItem => !(treeItem.value in Contract.RiskSubStatus)}
                rootItem={rootTreeItem}
                searchEnabled={false}
                onTreeItemClick={
                    treeItem => {
                        if (treeItem.value in Contract.RiskSubStatus) {
                            toggle(treeItem);
                        }
                    }}>
                {treeItem => {
                    const items = treeItem.leaves();
                    const itemChecked =
                        _.every(
                            items,
                            item => _.includes(selectedSubStatuses, item.value));
                    return <CheckboxField
                        checked={itemChecked}
                        disabled={readonly}
                        indeterminate={
                            !itemChecked &&
                            _.some(
                                treeItem.leaves(),
                                item => _.includes(selectedSubStatuses, item.value))}
                        key={treeItem.value}
                        onChange={() => toggle(treeItem)}
                        onClick={event => event.stopPropagation()}>
                        {treeItem.value in Contract.RiskStatus
                            ? statusTranslator(treeItem.value as Contract.RiskStatus)
                            : treeItem.value in Contract.RiskSubStatus
                                ? subStatusTranslator(treeItem.value as Contract.RiskSubStatus)
                                : treeItem.value}
                    </CheckboxField>;
                }}
            </Tree>}
        popoverElementContainerSx={{ padding: 0 }}>
        <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            sx={{
                "&:hover": {
                    border: theme.border.hoverFocus
                },
                border:
                    open
                        ? theme.border.hoverFocus
                        : theme.border.primary,
                borderRadius: theme.spacing(0.75),
                padding: theme.spacing(0.5, 0.5, 0.5, 1.5)
            }}>
            <SelectionView
                empty={_.isNil(selectedSubStatuses) || selectedSubStatuses.length === 0}
                label={localization.placeholder()}
                selectedValues={
                    _.map(
                        selectedSubStatuses,
                        selectedSubStatus => subStatusTranslator(selectedSubStatus))}
                totalCount={rootTreeItem.leaves().length}/>
            <DropdownIcon sx={{ fontSize: "18px" }}/>
        </Stack>
    </InfrastructureDropdown>);
}