import { Link, map, useLocalization } from "@infrastructure";
import React, { Fragment } from "react";
import { Contract, tenantModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../tenants";

type ResourcesExternalConsoleLinkProps = {
    includeSignIn?: boolean;
    tenantId: string;
    typeName: string;
};

export function ResourcesExternalConsoleLink({ includeSignIn = true, tenantId, typeName }: ResourcesExternalConsoleLinkProps) {
    const tenantModel = tenantModelStore.useGet(tenantId);
    const tenantRawShortNameId = (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const externalConsoleUrl =
        map(
            typeName,
            {
                [Contract.TypeNames.GcpArtifactRegistry]: () => GcpConsoleUrlBuilder.GetArtifactRegistriesUrl(tenantRawShortNameId),
                [Contract.TypeNames.GcpCloudRunService]: () => GcpConsoleUrlBuilder.GetCloudRunServicesUrl(tenantRawShortNameId),
                [Contract.TypeNames.GcpComputeImage]: () => GcpConsoleUrlBuilder.GetComputeImagesUrl(tenantRawShortNameId),
                [Contract.TypeNames.GcpComputeInstance]: () => GcpConsoleUrlBuilder.GetComputeInstancesUrl(tenantRawShortNameId),
                [Contract.TypeNames.GcpComputeInstanceTemplate]: () => GcpConsoleUrlBuilder.GetComputeInstanceTemplatesUrl(tenantRawShortNameId),
                [Contract.TypeNames.GcpIamServiceAccount]: () => GcpConsoleUrlBuilder.GetIamServiceAccountsUrl(tenantRawShortNameId),
                [Contract.TypeNames.GcpKmsKeyRing]: () => GcpConsoleUrlBuilder.GetKmsKeyRingsUrl(tenantRawShortNameId)
            });

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.resourcesExternalConsoleLink",
            () => ({
                description: {
                    withoutSignin: "Open the {{resourcesExternalConsoleLink}}",
                    withSignIn: "Sign in to the Google Cloud Platform and open the {{resourcesExternalConsoleLink}}"
                },
                linkText: "{{translatedTypeName}} Console"
            }));
    return (
        <Fragment>
            {(includeSignIn
                ? localization.description.withSignIn
                : localization.description.withoutSignin)({
                resourcesExternalConsoleLink:
                    <Link
                        urlOrGetUrl={externalConsoleUrl}
                        variant="external">
                        {localization.linkText({ translatedTypeName: entityTypeNameTranslator(typeName) })}
                    </Link>
            })}
        </Fragment>);
}