import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useQRadarContext, useSetQRadarContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { ExtensionSetupItem, FinishItem, LogSourceSetupFirstItem, LogSourceSetupSecondItem, ServerInfoItem } from "./components";

export class AddOrEditContext {
    public serverEndpointConnectorId?: string;
    public serverHostName?: string;
    public serverName: string;
    public serverPort?: number;
    public serverServerCertificateValidationEnabled: boolean;

    constructor(public serverModel?: Contract.ScopeSystemEntityModel) {
        const configuration = serverModel?.configuration as Contract.QRadarServerConfiguration;
        const endpointParts = configuration?.endpoint?.split(":");

        this.serverEndpointConnectorId = configuration?.endpointConnectorId;
        this.serverHostName = endpointParts?.[0];
        this.serverName = configuration?.name ?? "";
        this.serverPort =
            !_.isNil(endpointParts)
                ? _.parseInt(endpointParts[1])
                : 6514;
        this.serverServerCertificateValidationEnabled = configuration?.serverCertificateValidationEnabled ?? true;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useQRadarContext();
    const setQRadarContext = useSetQRadarContext();
    const [{ serverModel }, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.qRadar.addOrEdit",
            () => ({
                steps: {
                    extensionSetup: "Add the Tenable Cloud Security Extension",
                    logSourceSetup: "Add the Tenable Cloud Security Log Source",
                    serverInfo: "Fill the QRadar server parameters"
                },
                title: {
                    add: "Add QRadar Server",
                    edit: "Edit QRadar Server"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(serverModel)
                            ? 0
                            : 3}
                    onClose={
                        () =>
                            setQRadarContext(
                                qRadarContext => ({
                                    ...qRadarContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.extensionSetup()}>
                        <ExtensionSetupItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.logSourceSetup()}>
                        <LogSourceSetupFirstItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.logSourceSetup()}>
                        <LogSourceSetupSecondItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.serverInfo()}>
                        <ServerInfoItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}