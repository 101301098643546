import { Link, NumberFormatter, Optional, StringHelper } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../controllers";
import { useTenantTypeTranslator } from "../hooks";
import { TenantIcon } from "../icons";
import { useTheme } from "../themes";

export type EntityTypeEntitiesViewNameData = {
    count: number;
    link?: string;
    title: string;
};
type TenantsEntitiesViewProps = {
    tenantTypeToEntityDatasMap: Dictionary<EntityTypeEntitiesViewNameData[]>;
};

export function TenantsEntitiesView({ tenantTypeToEntityDatasMap }: TenantsEntitiesViewProps) {
    const tenantTypeTranslator = useTenantTypeTranslator();
    const tenantTypeToEntityDatasMapRows =
        useMemo(
            () => {
                const tenantTypeToEntityDataEntries =
                    _(tenantTypeToEntityDatasMap).
                        entries().
                        orderBy(([tenantType]) => StringHelper.getSortValue(tenantTypeTranslator(tenantType as Contract.TenantType))).
                        value();

                return _.size(tenantTypeToEntityDataEntries) > 3
                    ? _.chunk(
                        tenantTypeToEntityDataEntries,
                        Math.ceil(tenantTypeToEntityDataEntries.length / 2))
                    : [tenantTypeToEntityDataEntries];
            },
            [tenantTypeToEntityDatasMap]);
    const theme = useTheme();
    return (
        <Box
            sx={{
                "& > *:not(:last-child)": {
                    marginBottom: theme.spacing(2)
                },
                padding: theme.spacing(2)
            }}>
            {_.map(
                tenantTypeToEntityDatasMapRows,
                (tenantTypeToEntityDatasMapRow, index: number) => (
                    <Stack
                        direction="row"
                        key={index}
                        spacing={theme.spacing(1.5)}>
                        {_.map(
                            tenantTypeToEntityDatasMapRow,
                            ([tenantType, tenantData]) => (
                                <Stack
                                    key={tenantType}
                                    spacing={1}
                                    sx={{
                                        border: theme.border.primary,
                                        borderRadius: theme.spacing(0.75),
                                        height: theme.spacing(30.5),
                                        padding: theme.spacing(1.5, 0.5, 1.5, 1.5),
                                        width: theme.spacing(28)
                                    }}>
                                    <TenantTypeItem
                                        entityCount={
                                            _(tenantData).
                                                map(({ count }) => count).
                                                sum()}
                                        tenantType={tenantType as Contract.TenantType}/>
                                    <Box sx={{ paddingRight: theme.spacing(1) }}>
                                        <Divider/>
                                    </Box>
                                    <Stack
                                        sx={{
                                            gap: theme.spacing(1),
                                            overflowY: "auto",
                                            paddingRight: theme.spacing(0.5),
                                            scrollbarGutter: "stable"
                                        }}>
                                        {_.map(
                                            tenantData,
                                            ({ count, link, title }) =>
                                                <Stack
                                                    key={link}>
                                                    <EntityItem
                                                        entityCount={count}
                                                        link={link}
                                                        title={title}/>
                                                </Stack>)}
                                    </Stack>
                                </Stack>))}
                    </Stack>
                ))}
        </Box>);
}

type EntityItemProps = {
    entityCount: number;
    link: Optional<string>;
    title: string;
};

function EntityItem({ entityCount, link, title }: EntityItemProps) {
    const theme = useTheme();
    return (
        <Link
            sx={{
                color: theme.palette.text.primary,
                textDecoration: "none"
            }}
            urlOrGetUrl={link}>
            <Stack
                {...(!link && {
                    sx: { color: theme.palette.text.secondary }
                })}
                direction="row"
                spacing={1}>
                <Typography
                    noWrap={true}
                    sx={{ flex: 1 }}>
                    {title}
                </Typography>
                <Typography>
                    {NumberFormatter.humanize(entityCount)}
                </Typography>
            </Stack>
        </Link>);
}

type TenantTypeItemProps = {
    entityCount: number;
    tenantType: Contract.TenantType;
};

function TenantTypeItem({ entityCount, tenantType }: TenantTypeItemProps) {
    const tenantTypeTranslator = useTenantTypeTranslator();
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ paddingRight: theme.spacing(1.5) }}>
            <TenantIcon
                sx={{ fontSize: "18px" }}
                tenantType={tenantType}/>
            <Typography
                noWrap={true}
                sx={{ flex: 1 }}
                variant="h5">
                {tenantTypeTranslator(tenantType)}
            </Typography>
            <Typography variant="h5">
                {NumberFormatter.humanize(entityCount)}
            </Typography>
        </Stack>);
}