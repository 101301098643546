import { NumberFormatter, useDebouncedEffect, useLocalization } from "@infrastructure";
import { TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useTheme } from "../../../../../../..";
import { Contract } from "../../../../../../../controllers";
import { UdmObjectPropertyFilterProps } from "../UdmObjectPropertyFilter";
import { Filter } from "./Filter";

export function InfraInteger({ onClearClicked, onFilterChange, propertyId, rule }: UdmObjectPropertyFilterProps) {
    const [value, setValue] =
        useState<string>(
            rule.values[0]
                ? String(rule.values[0])
                : "");
    const [operator, setOperator] = useState<Contract.UdmQueryRuleOperator>(rule.operator ?? operators[0]);

    useDebouncedEffect(
        () => {
            if (operator && !_.isEmpty(value) && !_.isNaN(Number(value))) {
                onFilterChange({
                    operator,
                    value: [Number(value)]
                });
            }
        },
        500,
        [operator, value]);

    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.udmObjectPropertyFilter.infraInteger",
            () => ({
                title: "Size"
            }));

    const theme = useTheme();
    return (
        <Filter
            initialOperator={operator}
            operators={operators}
            propertyId={propertyId}
            ruleId={rule.id!}
            selectionViewValue={
                !_.isEmpty(rule.values) &&
                !_.isNaN(rule.values[0]) &&
                <Typography noWrap={true}>
                    {NumberFormatter.humanize(Number(rule.values[0]))}
                </Typography>}
            values={value}
            onClearClicked={onClearClicked}
            onOperatorChange={setOperator}>
            <TextField
                autoComplete="off"
                label={localization.title()}
                size="small"
                sx={{
                    "& fieldset": {
                        borderRadius: theme.spacing(0.5)
                    },
                    "& input": {
                        padding: theme.spacing(1.25, 1.75)
                    },
                    flex: 1
                }}
                type="number"
                value={value}
                variant="outlined"
                onChange={event => setValue(event.target.value)}/>
        </Filter>);
}

const operators = [
    Contract.UdmQueryRuleOperator.Equals,
    Contract.UdmQueryRuleOperator.Gt,
    Contract.UdmQueryRuleOperator.Gte,
    Contract.UdmQueryRuleOperator.Lt,
    Contract.UdmQueryRuleOperator.Lte
];