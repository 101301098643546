﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";

export function useKubernetesClusterKubeletServerCertificateRotationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterKubeletServerCertificateRotationDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);
    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.kubelets.useKubernetesClusterKubeletServerCertificateRotationDisabledRiskDefinition",
            () => ({
                contextItems: {
                    certificateRotationDisabledNodes: "The cluster has {{nodes}} in which the --rotate-server-certificates argument is not set to **true**",
                    serverCertificateRotationFeatureGateDisabledNodes: "The cluster has {{nodes}} in which the --feature-gates RotateKubeletServerCertificate argument is not set to **true**"
                },
                description: "{{cluster}} Kubelet server certificate rotation is not enabled",
                resolutionSection: {
                    step1: "For the following {{nodes}}, ensure that the --feature-gates RotateKubeletServerCertificate argument is set to **true**",
                    step2: "For the following {{nodes}}, ensure that the --rotate-server-certificates argument is set to **true**"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1({
                nodes:
                    <InlineEntities
                        entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                        entityTypeName={Contract.TypeNames.IKubernetesNode}
                        variant="itemCountAndType"/>
            }),
            localization.resolutionSection.step2({
                nodes:
                    <InlineEntities
                        entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                        entityTypeName={Contract.TypeNames.IKubernetesNode}
                        variant="itemCountAndType"/>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                new RiskDefinitionContextItem(
                    localization.contextItems.serverCertificateRotationFeatureGateDisabledNodes({
                        nodes:
                            <InlineEntities
                                entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                                entityTypeName={Contract.TypeNames.IKubernetesNode}
                                variant="itemCountAndType"/>
                    })),
                new RiskDefinitionContextItem(
                    localization.contextItems.certificateRotationDisabledNodes({
                        nodes:
                            <InlineEntities
                                entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                                entityTypeName={Contract.TypeNames.IKubernetesNode}
                                variant="itemCountAndType"/>
                    })),
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}