import { Box, Stack, Switch, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Expand, makeContextProvider, Message } from "@infrastructure";

type ToggleListProps<TValue> = {
    children: ReactNode;
    onSelectedValuesChanged: (selectedValues: TValue[]) => void;
    selectedValues: TValue[];
    sx: SxProps;
    title?: string;
};

export class ToggleListContext {
    constructor(
        public onSelectedValuesChanged: (selectedValues: any[]) => void,
        public selectedValues: any[]) {
    }
}

const [useToggleListContext, , useToggleListContextProvider] = makeContextProvider<ToggleListContext>();

export function ToggleList<TValue>({ children, onSelectedValuesChanged, selectedValues, sx, title }: ToggleListProps<TValue>) {
    const [, , ToggleListContextProvider] =
        useToggleListContextProvider(
            () =>
                new ToggleListContext(
                    onSelectedValuesChanged,
                    selectedValues),
            [onSelectedValuesChanged, selectedValues]);

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={sx}>
            {!_.isNil(title) &&
                <Typography>
                    {title}
                </Typography>}
            <Stack
                sx={{
                    "&:hover": {
                        border: theme.border.hoverFocus
                    },
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75)
                }}>
                <ToggleListContextProvider>
                    {children}
                </ToggleListContextProvider>
            </Stack>
        </Stack>);
}

type ToggleListItemProps = {
    contentElement?: ReactNode;
    contentElementPlacement?: "bottom" | "right";
    disabled?: boolean;
    info?: string;
    subtitle: string;
    title: string;
    value: string;
};

export function ToggleListItem({ contentElement, contentElementPlacement = "bottom", disabled, info, subtitle, title, value }: ToggleListItemProps) {
    const { onSelectedValuesChanged, selectedValues } = useToggleListContext();
    const theme = useTheme();
    return (
        <Box
            sx={{
                "&:not(:last-child)": {
                    borderBottom: theme.border.primary
                }
            }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ padding: theme.spacing(1) }}>
                <Switch
                    checked={_.includes(selectedValues, value)}
                    color="secondary"
                    disabled={disabled}
                    size="small"
                    onChange={
                        (event, checked) =>
                            onSelectedValuesChanged(
                                checked
                                    ? _.concat(selectedValues, value)
                                    : _.without(selectedValues, value))}/>
                <Stack spacing={1}>
                    <Stack
                        direction="row"
                        spacing={1}>
                        <Typography variant="h5">{title}</Typography>
                        {!_.isNil(info) &&
                            <Message
                                level="info"
                                title={info}
                                variant="minimal"/>}
                    </Stack>
                    <Typography>{subtitle}</Typography>
                </Stack>
                {contentElementPlacement === "right" &&
                    <Expand
                        expanded={!_.isNil(contentElement)}
                        sx={{ flex: 1 }}>
                        <Box sx={{ padding: theme.spacing(2) }}>
                            {contentElement}
                        </Box>
                    </Expand>}
            </Stack>
            {contentElementPlacement === "bottom" &&
                <Expand expanded={!_.isNil(contentElement)}>
                    <Box sx={{ padding: theme.spacing(2) }}>
                        {contentElement}
                    </Box>
                </Expand>}
        </Box>);
}