import { InlineItems, Message, Steps, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../AddOrEdit";

export function TerraformStateStorageAccountBlobContainerMessage() {
    const { tenantModel } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.addOrEdit.permissionsItem.terraformStateStorageAccountBlobContainerMessage",
            () => ({
                blobContainerNamePluralizer: [
                    "1 blob container",
                    "{{count | NumberFormatter.humanize}} blob containers"
                ],
                storageAccountWithBlobContainers: "{{terraformStateStorageAccountName}}: {{terraformStateStorageAccountBlobContainerNames}}",
                title: "We have identified the following blob containers that contain Terraform state data associated with your onboarded subscriptions"
            }));

    const theme = useTheme();
    return (
        <Box sx={{ paddingLeft: theme.spacing(5) }}>
            <Stack
                spacing={1}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(1)
                }}>
                <Message
                    level="info"
                    title={localization.title()}/>
                <Steps sx={{ paddingLeft: theme.spacing(2) }}>
                    {_.map(
                        tenantModel!.state.codeAnalysis.terraformStateStorageAccountNameToBlobContainerNamesMap,
                        (terraformStateBlobContainerNames, terraformStateStorageAccountName) =>
                            _.isEmpty(terraformStateBlobContainerNames)
                                ? terraformStateStorageAccountName
                                : localization.storageAccountWithBlobContainers({
                                    terraformStateStorageAccountBlobContainerNames:
                                        <InlineItems
                                            items={terraformStateBlobContainerNames}
                                            namePluralizer={localization.blobContainerNamePluralizer}
                                            variant="itemCountAndType"/>,
                                    terraformStateStorageAccountName
                                }))}
                </Steps>
            </Stack>
        </Box>);
}