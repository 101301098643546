﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "./useOciDefaultResourceInfoItemElements";

export function useOciNetworkingVirtualNetworkInterfaceCardDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const virtualNetworkInterfaceCardModel = resourceModel as Contract.OciNetworkingVirtualNetworkInterfaceCardModel;
    const virtualNetworkInterfaceCard = virtualNetworkInterfaceCardModel.entity as Contract.OciNetworkingVirtualNetworkInterfaceCard;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingVirtualNetworkInterfaceCardDefinition",
            () => ({
                info: {
                    items: {
                        availabilityDomain: "Availability Domain",
                        macAddress: "MAC Address",
                        privateIpAddress: "Private IP Address",
                        publicIpAddress: "Public IP Address"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="availabilityDomain"
                        location="all"
                        title={localization.info.items.availabilityDomain()}
                        value={virtualNetworkInterfaceCard.availabilityDomain}/>,
                    <InfoItem
                        key="publicIpAddress"
                        location="all"
                        title={localization.info.items.publicIpAddress()}
                        value={virtualNetworkInterfaceCard.publicIpAddress}/>,
                    <InfoItem
                        key="privateIpAddress"
                        location="all"
                        title={localization.info.items.privateIpAddress()}
                        value={virtualNetworkInterfaceCard.privateIpAddress}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualNetworkInterfaceCardModel.subnetIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingSubnet}
                        key="subnetIdReference"
                        location="all"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingSubnet)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualNetworkInterfaceCardModel.securityGroupIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingNetworkSecurityGroup}
                        key="securityGroups"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                                { count: 0 })}/>,
                    <InfoItem
                        key="macAddress"
                        title={localization.info.items.macAddress()}
                        value={virtualNetworkInterfaceCard.macAddress}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}