﻿import { ItemSelector, Loading, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, entityModelStore, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem } from "../../../../../../../../../../../../common";
import { useGcpIamWorkforcePoolProviderTypeTranslator } from "../../../../../../../../hooks/useGcpIamWorkforcePoolProviderTypeTranslator";

type ProvidersProps = {
    providerIds: string[];
};

export function Providers({ providerIds }: ProvidersProps) {
    const providerModels = entityModelStore.useGet(providerIds) as Contract.GcpIamWorkforcePoolProviderModel[];
    const providerModelMap =
        _.keyBy(
            providerModels,
            providerModel => providerModel.id);

    const [selectedProviderModel, setSelectedProviderModel] = useState(providerModels[0]);

    const providerTypeTranslator = useGcpIamWorkforcePoolProviderTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamWorkforcePoolDefinition.providers",
            () => ({
                items: {
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    federationAttributeToGcpAttributeMap: "Attribute Mapping",
                    type: "Type"
                }
            }));

    const selectedProvider = selectedProviderModel.entity as Contract.GcpIamWorkforcePoolProvider;
    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={_.keys(providerModelMap)}
                selectedItem={selectedProvider.id}
                sorted={false}
                onSelectedItemChanged={providerModelId => setSelectedProviderModel(providerModelMap[providerModelId])}>
                {providerModelId => providerModelMap[providerModelId].entity.displayName}
            </ItemSelector>
            <Loading>
                <InfoCard
                    columns={true}
                    minChildElementLength={1}>
                    <InfoItem
                        key="type"
                        title={localization.items.type()}
                        value={providerTypeTranslator(selectedProvider.type)}/>
                    <InfoItem
                        title={localization.items.enabled.title()}
                        value={
                            selectedProvider.enabled
                                ? localization.items.enabled.true()
                                : localization.items.enabled.false()}/>
                </InfoCard>
                <InfoCard title={localization.items.federationAttributeToGcpAttributeMap()}>
                    <KeyValueTable
                        items={
                            _.map(
                                selectedProvider.attributeData.federationAttributeToGcpAttributeMap,
                                (value, key) =>
                                    new KeyValueTableItem(
                                        key,
                                        value))}/>
                </InfoCard>
            </Loading>
        </InfoCard>);
}