import { BackIcon as IncludeIcon, EmptyMessageText, NextIcon as ExcludeIcon, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, EntityController, entitySearchableReferenceStore, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../common";
import { RiskPolicyConfigurationExclusionDataReason } from "../../../../../../../../../../common/controllers/types.generated";
import { Button, List } from "./components";

type EntityTypeNameProps = {
    entityTypeName: string;
    excludedEntityIdToExclusionMap: _.Dictionary<Contract.RiskPolicyConfigurationExclusionData>;
    excludedEntityIdToScopeIdsMap?: Dictionary<string[]>;
    onExcludedEntityIdToExclusionMapChanged: (excludedEntityIdToMessageMap: Dictionary<Contract.RiskPolicyConfigurationExclusionData>) => void;
    readOnly: boolean;
    riskPolicyExclusionAnyScopeEntityTypeNames: string[];
    riskPolicyExclusionEntityTypeNames: string[];
    scopeRiskPolicyModel: Contract.RiskPolicyModel;
};

export function EntityTypeName({ entityTypeName, excludedEntityIdToExclusionMap, excludedEntityIdToScopeIdsMap = {}, onExcludedEntityIdToExclusionMapChanged, readOnly, riskPolicyExclusionAnyScopeEntityTypeNames, riskPolicyExclusionEntityTypeNames, scopeRiskPolicyModel }: EntityTypeNameProps) {
    const [entitySearchableReferences] =
        useExecuteOperation(
            EntityTypeName,
            async () => {
                const getEntityTypeEntitySearchableReferencesResponse =
                    await EntityController.getEntityTypeEntitySearchableReferences(
                        new Contract.EntityControllerGetEntityTypeEntitySearchableReferencesRequest(
                            riskPolicyExclusionAnyScopeEntityTypeNames,
                            riskPolicyExclusionEntityTypeNames,
                            scopeRiskPolicyModel.riskPolicyConfiguration.scopeId));
                const entityTypeNameToSearchableReferencesMap = getEntityTypeEntitySearchableReferencesResponse.entityTypeNameToSearchableReferencesMap;
                await entitySearchableReferenceStore.notify(
                    _(entityTypeNameToSearchableReferencesMap).
                        values().
                        flatMap().
                        value());
                return entityTypeNameToSearchableReferencesMap[entityTypeName];
            });
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.entityExclusion.entityTypeName",
            () => ({
                entities: {
                    empty: {
                        withFilter: "No matching {{translatedEntityTypeName}}",
                        withoutFilter: "No {{translatedEntityTypeName}}"
                    },
                    title: "Select {{translatedEntityTypeName}}"
                },
                excludedEntities: {
                    empty: {
                        withFilter: "No matching {{translatedEntityTypeName}}",
                        withoutFilter: "No {{translatedEntityTypeName}}"
                    },
                    title: "Excluded {{translatedEntityTypeName}}"
                }
            }));

    const [selectedEntityIds, setSelectedEntityIds] = useState<string[]>([]);
    const [selectedExcludedEntityIds, setSelectedExcludedEntityIds] = useState<string[]>([]);
    const [entityIds, entityIdToSearchableReferenceMap] =
        useMemo(
            () => {
                const entityIdToSearchableReferenceMap =
                    _.keyBy(
                        entitySearchableReferences,
                        entitySearchableReference => entitySearchableReference.id);
                const entityIds =
                    _(entityIdToSearchableReferenceMap).
                        keys().
                        difference(_.keys(excludedEntityIdToExclusionMap)).
                        difference(_.keys(excludedEntityIdToScopeIdsMap)).
                        value();
                return [entityIds, entityIdToSearchableReferenceMap];
            },
            [entitySearchableReferences, excludedEntityIdToExclusionMap]);

    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityTypeName,
            {
                count: 0,
                includeServiceName: false,
                variant: "text"
            });
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                height: "100%",
                position: "relative"
            }}>
            {!readOnly &&
                <Box
                    sx={{
                        flex: 1,
                        flexBasis: 0,
                        minWidth: 0
                    }}>
                    <List
                        emptyMessageText={
                            new EmptyMessageText(
                                localization.entities.empty.withoutFilter({ translatedEntityTypeName }),
                                localization.entities.empty.withFilter({ translatedEntityTypeName }))}
                        entityIds={entityIds}
                        entityIdToSearchableReferenceMap={entityIdToSearchableReferenceMap}
                        readOnly={readOnly}
                        selectedEntityIds={selectedEntityIds}
                        title={localization.entities.title({ translatedEntityTypeName })}
                        onSelectedEntityIdsChanged={entityIds => setSelectedEntityIds(entityIds)}/>
                </Box>}
            {!readOnly &&
                <Stack
                    spacing={1}
                    sx={{
                        height: "fit-content",
                        inset: 0,
                        margin: theme.important("auto"),
                        position: "absolute",
                        width: "fit-content"
                    }}>
                    <Button
                        disabled={_.isEmpty(selectedEntityIds)}
                        icon={<ExcludeIcon/>}
                        onClick={
                            () => {
                                setSelectedEntityIds([]);
                                setSelectedExcludedEntityIds([]);
                                onExcludedEntityIdToExclusionMapChanged(
                                    _.merge(
                                        {},
                                        excludedEntityIdToExclusionMap,
                                        _(selectedEntityIds).
                                            keyBy().
                                            mapValues(
                                                () =>
                                                    new Contract.RiskPolicyConfigurationExclusionData(
                                                        undefined,
                                                        undefined,
                                                        RiskPolicyConfigurationExclusionDataReason.Exception)).
                                            value()));
                            }}/>
                    <Button
                        disabled={_.isEmpty(selectedExcludedEntityIds)}
                        icon={<IncludeIcon/>}
                        onClick={
                            () => {
                                setSelectedEntityIds([]);
                                setSelectedExcludedEntityIds([]);
                                onExcludedEntityIdToExclusionMapChanged(
                                    _(excludedEntityIdToExclusionMap).
                                        omit(selectedExcludedEntityIds).
                                        value());
                            }}/>
                </Stack>}
            <Box
                sx={{
                    flex: 1,
                    flexBasis: 0,
                    minWidth: 0
                }}>
                <List
                    emptyMessageText={
                        new EmptyMessageText(
                            localization.excludedEntities.empty.withoutFilter({ translatedEntityTypeName }),
                            localization.excludedEntities.empty.withFilter({ translatedEntityTypeName }))}
                    entityIds={_.keys(excludedEntityIdToExclusionMap)}
                    entityIdToExclusionMap={excludedEntityIdToExclusionMap}
                    entityIdToScopeIdsMap={excludedEntityIdToScopeIdsMap}
                    entityIdToSearchableReferenceMap={entityIdToSearchableReferenceMap}
                    readOnly={readOnly}
                    selectedEntityIds={selectedExcludedEntityIds}
                    title={localization.excludedEntities.title({ translatedEntityTypeName })}
                    onSelectedEntityIdsChanged={entityIds => setSelectedExcludedEntityIds(entityIds)}
                    onSelectedEntityIdToExclusionChanged={
                        selectedExcludedEntityIdToExclusionMap => {
                            onExcludedEntityIdToExclusionMapChanged(
                                {
                                    ...excludedEntityIdToExclusionMap,
                                    ...selectedExcludedEntityIdToExclusionMap
                                });
                        }}/>
            </Box>
        </Stack>);
}