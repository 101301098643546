import { useLocalization } from "@infrastructure";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useComplianceTranslator } from "../../../../../../../../../common";

export function useBuiltInComplianceEnabledUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const complianceTranslator = useComplianceTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useBuiltInComplianceEnabledUpdateAuditEventDefinition",
            () => ({
                compliance: "Compliance",
                propertyChanges: "Updated properties",
                status: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    title: "Status"
                }
            }));
    const builtInComplianceEnabledUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.BuiltInComplianceEnabledUpdateAuditEvent;
    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.compliance(),
            complianceTranslator(builtInComplianceEnabledUpdateAuditEvent.builtInComplianceType).title),
        new AuditEventDefinitionDetailItem(
            localization.status.title(),
            builtInComplianceEnabledUpdateAuditEvent.enabled
                ? localization.status.enabled()
                : localization.status.disabled()),
        new AuditEventDefinitionDetailItem(
            localization.propertyChanges(),
            localization.status.title()
        )
    ]);
}