import { AnalyticsEventActionType } from "@infrastructure";
import { Dictionary } from "lodash";
import { useEffect } from "react";
import { useTrackAnalytics } from ".";

export function useTrackAnalyticsEvent(actionType: AnalyticsEventActionType, propertyNameToValueMap?: Dictionary<boolean | number | string>, dependencies?: any[]) {
    const trackAnalytics = useTrackAnalytics();
    useEffect(
        () => {
            trackAnalytics(
                actionType,
                propertyNameToValueMap);
        },
        dependencies ?? []);
}