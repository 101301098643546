import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpNotebooksInstanceRiskContext } from "../contexts";

export function useGcpNotebooksInstanceDefaultEncryptionRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpNotebooksInstanceDefaultEncryptionRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpNotebooksInstanceModel;

    const getGcpNotebooksInstanceRiskContext = useGetGcpNotebooksInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpNotebooksInstanceDefaultEncryptionRiskDefinition",
            () => ({
                description: "{{instance}} is not configured with encryption using CMK",
                sections: {
                    resolution: {
                        step1: "Encrypting an existing instance is not available and you need to re-create the instance",
                        step2: "Click **CREATE NEW**",
                        step3: "Select **ADVANCED OPTIONS**",
                        step4: "In the **Disks** section, under **Encryption** select **Cloud KMS key** and choose a **customer managed key**",
                        step5: "Click **Create**",
                        step6: "Delete the insecure instance"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const instanceRiskContext = getGcpNotebooksInstanceRiskContext(instanceModel);
            return [
                instanceRiskContext.generalInformation,
                instanceRiskContext.sensitive,
                instanceRiskContext.status,
                instanceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}