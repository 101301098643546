import { DataTableColumn, DataTableColumnRenderProps, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { AzureStorageStorageAccountBlobContainerPublicAccess, useAzureStorageStorageAccountBlobContainerPublicAccessTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useCommonDataAnalysisDefinition } from "../useCommonDataAnalysisDefinition";

export function useAzureStorageStorageAccountBlobContainerDefinition(definitionData: DefinitionData) {
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const storageStorageAccountBlobContainerPublicAccessTranslator = useAzureStorageStorageAccountBlobContainerPublicAccessTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureStorageStorageAccountBlobContainerDefinition",
            () => ({
                columns: {
                    effectivePublicAccess: "Access Level",
                    storageAccountId: "Storage Account",
                    url: "Container URL"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureStorageStorageAccountBlobContainerModel>(
                        Contract.TypeNames.AzureStorageStorageAccount,
                        item => [(item.entity as Contract.AzureStorageStorageAccountBlobContainer).storageAccountId],
                        localization.columns.storageAccountId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerStorageAccount)}
                                placeholder={localization.columns.storageAccountId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerStorageAccount}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerStorageAccount}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureStorageStorageAccountBlobContainerModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureStorageStorageAccountBlobContainer).storageAccountId}
                            entityTypeName={Contract.TypeNames.AzureStorageStorageAccount}/>}
                title={localization.columns.storageAccountId()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureStorageStorageAccountBlobContainerModel) => ({
                            [localization.columns.url()]: (item.entity as Contract.AzureStorageStorageAccountBlobContainer).url
                        })
                }}
                id="url"
                key="url"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureStorageStorageAccountBlobContainerModel) => ({
                            [localization.columns.effectivePublicAccess()]: storageStorageAccountBlobContainerPublicAccessTranslator((item.entity as Contract.AzureStorageStorageAccountBlobContainer).effectivePublicAccess)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.effectivePublicAccess()}
                                sorted={false}>
                                {_.map(
                                    [
                                        Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Container,
                                        Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Blob,
                                        Contract.AzureStorageStorageAccountBlobContainerPublicAccess.None
                                    ],
                                    publicAccess =>
                                        <ValuesFilterItem
                                            key={publicAccess}
                                            title={storageStorageAccountBlobContainerPublicAccessTranslator(publicAccess)}
                                            value={publicAccess}/>)}
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerEffectivePublicAccess}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerEffectivePublicAccess}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureStorageStorageAccountBlobContainerModel>) =>
                        <AzureStorageStorageAccountBlobContainerPublicAccess publicAccess={(item.entity as Contract.AzureStorageStorageAccountBlobContainer).effectivePublicAccess}/>}
                title={localization.columns.effectivePublicAccess()}/>,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn,
            commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(false),
            commonDataAnalysisResourceDefinition.columns.scanTimeColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureStorageStorageAccountBlobContainerRequest(
                new Contract.EntityControllerGetEntityModelPageAzureStorageStorageAccountBlobContainerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerEffectivePublicAccess]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerStorageAccount])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime]))));
}