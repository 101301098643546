import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { InlineEntities } from "./InlineEntities";

type InlineGroupIdentitiesProps = {
    identityIds: string[];
    identityTypeName: string;
};

export function InlineGroupIdentities({ identityIds, identityTypeName }: InlineGroupIdentitiesProps) {
    const localization =
        useLocalization(
            "common.inlineGroupIdentities",
            () => ({
                identities: [
                    "1 member",
                    "{{count | NumberFormatter.humanize}} members"
                ],
                transitiveIdentities: "{{identities}} (direct and indirect)"
            }));
    return (
        <Typography component="span">
            {localization.transitiveIdentities({
                identities:
                    <InlineEntities
                        entityIdsOrModels={identityIds}
                        entityTypeName={identityTypeName}
                        entityVariant="iconTextTypeTenant"
                        namePluralizer={localization.identities}
                        variant="itemCountAndType"/>
            })}
        </Typography>);
}