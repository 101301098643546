import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSort, DataTableSortDirection, EmptyMessageText, map, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { OneLoginTenantsContext, useSetOneLoginTenantsContext } from "../..";
import { Contract, StatusCell, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { useOneLoginTenantModelStatusTranslator } from "../../hooks";
import { ActionsCell } from "./components";


export function Table() {
    const tenantModels = tenantModelStore.useGetPermittedOneLoginTenants();
    const setOneLoginTenantsContext = useSetOneLoginTenantsContext();

    const oneLoginTenantModelStatusTranslator = useOneLoginTenantModelStatusTranslator();

    function getTenantModels(_filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _.orderBy(
            tenantModels,
            tenantModel =>
                map<string, number | string | undefined>(
                    sort?.columnId ?? TableColumnId.Name,
                    {
                        [TableColumnId.Name]: () =>
                            tenantModel.configuration.name.toLowerCase(),
                        [TableColumnId.Status]: () =>
                            oneLoginTenantModelStatusTranslator(tenantModel.status).
                                toLowerCase(),
                        [TableColumnId.Url]: () =>
                            (tenantModel.configuration as Contract.OneLoginTenantConfiguration).url.toLowerCase()
                    }),
            sort?.direction === DataTableSortDirection.Descending
                ? "desc"
                : "asc");
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [tenantModels]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oneLoginTenants.table",
            () => ({
                actions: {
                    add: "Add OneLogin Account"
                },
                columns: {
                    name: "Name",
                    status: "Status",
                    url: "Account URL"
                },
                empty: "No OneLogin accounts"
            }));
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={getTenantModels}
            getItemId={(item: Contract.OneLoginTenantModel) => item.configuration.id}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setOneLoginTenantsContext(new OneLoginTenantsContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.OneLoginTenantModel) => item.configuration.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.Url}
                itemProperty={(item: Contract.OneLoginTenantModel) => (item.configuration as Contract.OneLoginTenantConfiguration).url}
                title={localization.columns.url()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OneLoginTenantModel>) =>
                        <StatusCell
                            statusSeverity={item.statusSeverity}
                            title={oneLoginTenantModelStatusTranslator(item.status)}/>}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    Status = "status",
    Url = "url"
}