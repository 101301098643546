import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsSqsQueueRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { PolicySection } from "../../components";

export function useAwsSqsQueuePublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSqsQueuePublicAccessExistsRisk;
    const queueModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSqsQueueModel;
    const queue = queueModel.entity as Contract.AwsSqsQueue;

    const getAwsSqsQueueRiskContext = useGetAwsSqsQueueRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSqsQueuePublicAccessExistsRiskDefinition",
            () => ({
                description: "{{queue}} is publicly accessible via resource policy",
                sections: {
                    policy: "Policy",
                    resolution: {
                        step1: "Disabling public access might break identities using the queue. Before changing the policy, verify that identities using the queue can access it without public access",
                        step2: "Change the policy statements that allows access to any AWS principal",
                        step3: "Click **Save changes** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            queue:
                <Entity
                    entityIdOrModel={queueModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Sqs,
                AwsConsoleUrlBuilder.getSqsQueueEditUrl(queue)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const queueRiskContext = getAwsSqsQueueRiskContext(queueModel);
            return [
                queueRiskContext.generalInformation,
                queueRiskContext.lambdaFunctions,
                queueRiskContext.snsSubscriptions,
                queueRiskContext.encryption,
                queueRiskContext.publicAccessPermissionActions,
                queueRiskContext.sensitive,
                queueRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={queue.policyDocument}/>,
                    localization.sections.policy(),
                    {
                        expandable: true
                    })
            ]
        });
}