import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureNetworkLoadBalancerTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureNetworkLoadBalancerTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureNetworkLoadBalancerType]: {
                    [Contract.AzureNetworkLoadBalancerType.Internal]: "Internal",
                    [Contract.AzureNetworkLoadBalancerType.Public]: "Public"
                }
            }));
    return (type: Contract.AzureNetworkLoadBalancerType) =>
        localization[Contract.TypeNames.AzureNetworkLoadBalancerType][type]();
}