﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition, useGcpCommonServiceAccountOriginatorScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, SeverityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGetVulnerabilitiesCsvItem } from "../../../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../../VulnerabilitiesCell";
import { DefinitionData } from "../../../../Table";

export function useGcpCloudRunServiceDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const commonServiceAccountOriginatorScopeResourceDefinition = useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCloudRunServiceDefinition",
            () => ({
                columns: {
                    containerImages: "Container Images",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerImageVulnerabilitySeverities: "Vulnerability Severity",
                    url: "Service URL"
                }
            }));
    const getVulnerabilitiesCsvItem = useGetVulnerabilitiesCsvItem();
    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpCloudRunServiceModel) => ({
                            [localization.columns.url()]: (item.entity as Contract.GcpCloudRunService).url
                        })
                }}
                id="url"
                key="url"/>,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            commonServiceAccountOriginatorScopeResourceDefinition.columns.serviceAccountColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpCloudRunServiceModel>(
                        Contract.TypeNames.IContainerImage,
                        item => item.containerImageIdReferences,
                        localization.columns.containerImages())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpCloudRunServiceContainerImages)}
                                placeholder={localization.columns.containerImages()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpCloudRunServiceContainerImages}
                key={Contract.EntityModelProperty.GcpCloudRunServiceContainerImages}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpCloudRunServiceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.containerImageIdReferences}
                            entityTypeName={Contract.TypeNames.IContainerImage}
                            entityVariant="iconText"/>}
                title={localization.columns.containerImages()}/>,
            <DataTableColumn
                cellMinWidth={118}
                exportOptions={{
                    getItem:
                        (item: Contract.GcpCloudRunServiceModel) => ({
                            [localization.columns.containerImageVulnerabilities()]: getVulnerabilitiesCsvItem(item.containerImageVulnerabilities)
                        })
                }}
                filterOptions={{
                    itemOrItems: [
                        {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.GcpCloudRunServiceContainerImageVulnerabilities)}
                                    placeholder={localization.columns.containerImageVulnerabilities()}/>,
                            id: Contract.EntityModelProperty.GcpCloudRunServiceContainerImageVulnerabilities,
                            title: localization.columns.containerImageVulnerabilities()
                        },
                        {
                            element:
                                <SeverityFilter placeholder={localization.columns.containerImageVulnerabilitySeverities()}/>,
                            id: Contract.EntityModelProperty.GcpCloudRunServiceContainerImageVulnerabilitySeverities,
                            title: localization.columns.containerImageVulnerabilitySeverities()
                        }
                    ]
                }}
                id={Contract.EntityModelProperty.GcpCloudRunServiceContainerImageVulnerabilities}
                key={Contract.EntityModelProperty.GcpCloudRunServiceContainerImageVulnerabilities}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpCloudRunServiceModel>) =>
                        <VulnerabilitiesCell
                            containerImageScanStatus={item.containerImageScanStatus}
                            vulnerabilities={item.containerImageVulnerabilities}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.containerImageVulnerabilities()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpCloudRunServiceRequest(
                new Contract.EntityControllerGetEntityModelPageGcpCloudRunServiceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpCloudRunServiceContainerImages]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpCloudRunServiceContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpCloudRunServiceContainerImageVulnerabilitySeverities])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount]))));
}