import { DeleteIcon, useLocalization } from "@infrastructure";
import { FormHelperText, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { useTheme } from "../../../../../../../../common";

type ListParameterProps<T> = {
    children: (onChangeValue: (value: T) => void, readOnly: boolean, value: T) => ReactNode;
    description: string;
    disabled: boolean;
    getValueKey: (value: T) => string;
    inherited: boolean;
    inheritedValues?: T[];
    isParameterValueValid: (value?: T) => boolean;
    onValuesChanged: (values: T[], valid: boolean) => void;
    values?: T[];
};

export function ListParameter<T>({ children, description, disabled, getValueKey, inherited, inheritedValues, isParameterValueValid, onValuesChanged, values }: ListParameterProps<T>) {
    const [parameterItems, setParameterItems] =
        useState(
            () =>
                _(inheritedValues ?? []).
                    concatIf(
                        !disabled,
                        values ?? []).
                    filter().
                    map(
                        value =>
                            new ListParameterItem<T>(
                                false,
                                undefined,
                                _.includes(inheritedValues, value),
                                value)).
                    value());

    useEffect(
        () => {
            onValuesChanged(
                _(parameterItems).
                    filter(parameterItem => !inherited && !parameterItem.inherited).
                    map(parameterItem => parameterItem.value!).
                    value(),
                !_.isEmpty(parameterItems) &&
                _.every(
                    parameterItems,
                    parameterItem =>
                        !parameterItem.duplicate && (
                            _.isNil(parameterItem.value) ||
                            isParameterValueValid(parameterItem.value))));
        },
        [inherited, parameterItems]);

    function setValidatedParameterItems(parameterItems: ListParameterItem<T>[]) {
        const parameterValueKeyToCountMap =
            _.countBy(
                parameterItems,
                parameterItem => getValueKey(parameterItem.value!));
        setParameterItems(
            _.map(
                parameterItems,
                parameterItem =>
                    new ListParameterItem<T>(
                        parameterValueKeyToCountMap[getValueKey(parameterItem.value!)] > 1,
                        parameterItem.id,
                        parameterItem.inherited,
                        parameterItem.value)));
    }

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.kubernetesAdmissionController.listParameter",
            () => ({
                duplicate: "Duplicate value is not allowed"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={1}>
            <Typography
                sx={{
                    whiteSpace: "noWrap",
                    width: "fit-content"
                }}>
                {description}
            </Typography>
            <List disablePadding={true}>
                {_(parameterItems).
                    concatIf(
                        _.isEmpty(parameterItems) ||
                        parameterItems.length < 50 &&
                        !disabled,
                        new ListParameterItem<T>(
                            false,
                            undefined,
                            false,
                            undefined)).
                    map(
                        parameterItem =>
                            <Stack
                                key={parameterItem.id}
                                sx={{ width: "100%" }}>
                                <Stack
                                    direction="row"
                                    spacing={1}>
                                    <ListItem
                                        key={parameterItem.id}
                                        sx={{
                                            padding: theme.spacing(1, 0),
                                            width: "100%"
                                        }}>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                                alignItems: "center",
                                                height: "100%",
                                                width: "100%"
                                            }}>
                                            {children(
                                                value => {
                                                    const updatedParameterItem =
                                                        new ListParameterItem(
                                                            false,
                                                            parameterItem.id,
                                                            false,
                                                            value);

                                                    setValidatedParameterItems(
                                                        _.some(
                                                            parameterItems,
                                                            existingParameterItem => existingParameterItem.id === parameterItem.id)
                                                            ? _.map(
                                                                parameterItems,
                                                                existingParameterItem =>
                                                                    existingParameterItem.id !== parameterItem.id
                                                                        ? existingParameterItem
                                                                        : updatedParameterItem)
                                                            : _.concat(
                                                                parameterItems,
                                                                updatedParameterItem));
                                                },
                                                disabled || parameterItem.inherited,
                                                parameterItem.value!)}
                                            {!(disabled || parameterItem.inherited) &&
                                                !_.isNil(parameterItem.value) &&
                                                <IconButton
                                                    size="small"
                                                    onClick={
                                                        () =>
                                                            setValidatedParameterItems(
                                                                _.filter(
                                                                    parameterItems,
                                                                    existingParameterItem => existingParameterItem.id !== parameterItem.id))}>
                                                    <DeleteIcon/>
                                                </IconButton>}
                                        </Stack>
                                    </ListItem>
                                </Stack>
                                {parameterItem.duplicate &&
                                    isParameterValueValid(parameterItem.value) &&
                                    <Stack direction="row">
                                        <FormHelperText error={true}>{localization.duplicate()}</FormHelperText>
                                    </Stack>}
                            </Stack>).
                    value()}
            </List>
        </Stack>);
}

export class ListParameterItem<T> {
    private static _idCounter = 0;
    public duplicate: boolean;
    public id: number;
    public inherited: boolean;
    public value?: T;

    public constructor(
        duplicate?: boolean,
        id?: number,
        inherited?: boolean,
        value?: T) {
        this.duplicate = duplicate ?? false;
        this.id = id ?? ListParameterItem._idCounter++;
        this.inherited = inherited ?? false;
        this.value = value;
    }
}