﻿import { Action1, EmptyMessageText, MultiSelect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, ScopeHelper, scopeSystemEntityModelStore, SlackConversationReferenceHelper, SlackIcon, slackWorkspaceChannelOperationStore } from "..";

type SlackChannelReferenceMultiSelectProps = {
    disabled?: boolean;
    iconVisible?: boolean;
    onSelectedChannelReferencesChanged: Action1<Contract.SlackConversationReference[]>;
    scopeId: string;
    selectedChannelReferences: Contract.SlackConversationReference[];
};

export function SlackChannelReferenceMultiSelect({ disabled = false, iconVisible = true, onSelectedChannelReferencesChanged, scopeId, selectedChannelReferences }: SlackChannelReferenceMultiSelectProps) {
    const workspaceIdToChannelRawIdToNameMap = slackWorkspaceChannelOperationStore.useGet();
    const slackModels = scopeSystemEntityModelStore.useGetSlack();
    const workspaceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            slackModels);
    const [channelReferenceIds, channelReferenceIdToDataMap] =
        useMemo(
            () => {
                const channelReferenceIdToDataMap =
                    _(selectedChannelReferences).
                        map(
                            selectedChannelReference => ({
                                name: "******",
                                reference: selectedChannelReference,
                                workspaceName: "******"
                            })).
                        concat(
                            _.flatMap(
                                workspaceModels,
                                workspaceModel =>
                                    _.map(
                                        workspaceIdToChannelRawIdToNameMap[workspaceModel.id],
                                        (name, rawId) => ({
                                            name,
                                            reference:
                                                new Contract.SlackConversationReference(
                                                    rawId,
                                                    workspaceModel.configuration.id),
                                            workspaceName: workspaceModel.configuration.name
                                        })))).
                        keyBy(({ reference }) => SlackConversationReferenceHelper.getId(reference)).
                        value();
                return [_.keys(channelReferenceIdToDataMap), channelReferenceIdToDataMap];
            },
            [workspaceModels]);

    const localization =
        useLocalization(
            "common.slackChannelReferenceMultiSelect",
            () => ({
                empty: {
                    withFilter: "No matching channels",
                    withoutFilter: "No channels"
                },
                placeholder: "Channels",
                selection: "{{channelsCount | NumberFormatter.humanize}} channels"
            }));

    const [selectedChannelReferenceIds, setSelectedChannelReferenceIds] =
        useState(
            () =>
                _.map(
                    selectedChannelReferences,
                    SlackConversationReferenceHelper.getId));
    return (
        <MultiSelect
            disabled={disabled}
            emptyMessageText={
                new EmptyMessageText(
                    localization.empty.withoutFilter(),
                    localization.empty.withFilter())}
            fieldOptions={{ variant: "text" }}
            fullWidth={true}
            getItemText={channelReferenceId => `${channelReferenceIdToDataMap[channelReferenceId].workspaceName}-${channelReferenceIdToDataMap[channelReferenceId].name}`}
            icon={
                iconVisible
                    ? <SlackIcon
                        sx={{
                            fontSize: "24px",
                            opacity:
                                disabled
                                    ? 0.3
                                    : 1
                        }}/>
                    : undefined}
            items={channelReferenceIds}
            placeholder={localization.placeholder()}
            selectedItems={selectedChannelReferenceIds}
            selectedItemsTranslator={
                selectedChannelReferenceIds =>
                    selectedChannelReferenceIds.length === 1
                        ? channelReferenceIdToDataMap[selectedChannelReferenceIds[0]].name
                        : localization.selection({ channelsCount: selectedChannelReferenceIds.length })}
            onSelectedItemsChanged={
                selectedChannelReferenceIds => {
                    setSelectedChannelReferenceIds(selectedChannelReferenceIds);
                    onSelectedChannelReferencesChanged(
                        _.map(
                            selectedChannelReferenceIds,
                            selectedChannelReferenceId => channelReferenceIdToDataMap[selectedChannelReferenceId].reference));
                }}>
            {channelReferenceId =>
                <Stack>
                    <Typography>
                        {channelReferenceIdToDataMap[channelReferenceId].name}
                    </Typography>
                    <Typography variant="subtitle1">
                        {channelReferenceIdToDataMap[channelReferenceId].workspaceName}
                    </Typography>
                </Stack>}
        </MultiSelect>);
}