import { TimeFormatter, useFormatRelativeTime, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../common";
import { VulnerabilitiesChartCell } from "../../../../../../WorkloadAnalysis";
import { EntitiesInfoItem, TagsInfoItem } from "../../../components";

export function useCommonContainerImageInfoItemElements(resourceModel: Contract.EntityModel) {
    const containerImageModel = _.as<Contract.IContainerImageModel>(resourceModel);
    const containerImage = _.as<Contract.IContainerImage>(containerImageModel.entity);
    const formatRelativeTime = useFormatRelativeTime();

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useCommonContainerImageInfoItemElements",
            () => ({
                info: {
                    items: {
                        creationTime: "Date Added",
                        digest: "Digest",
                        kubernetesWorkloadResourceIds: "K8s Workloads",
                        operatingSystem: {
                            distributionAndVersion: "{{distribution}} {{version}}",
                            title: "Operating System"
                        },
                        operatingSystemType: {
                            title: "Platform",
                            [Contract.TypeNames.OperatingSystemType]: {
                                [Contract.OperatingSystemType.Linux]: "Linux",
                                [Contract.OperatingSystemType.Windows]: "Windows"
                            }
                        },
                        repository: "Repository",
                        scanTime: "Scan Time",
                        tags: "Image Tags",
                        virtualMachineIds: "Virtual Machines",
                        vulnerabilities: "Vulnerabilities",
                        workloadClusterResourceIds: "Clusters"
                    }
                }
            }));
    return {
        creationTimeInfoItemElement:
            <InfoItem
                key="creationTime"
                location="all"
                title={localization.info.items.creationTime()}
                value={
                    _.isNil(containerImage.data.creationTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(containerImage.data.creationTime)}/>,
        digestInfoItemElement:
            <InfoItem
                key="digest"
                location="all"
                title={localization.info.items.digest()}
                value={containerImage.data.digest}/>,
        kubernetesWorkloadResourceIdsInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={containerImageModel.kubernetesWorkloadResourceIds}
                entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                key="kubernetesWorkloadResourceIds"
                location="all"
                title={localization.info.items.kubernetesWorkloadResourceIds()}/>,
        operatingSystemInfoItemElement:
            <InfoItem
                key="operationSystem"
                location="all"
                title={localization.info.items.operatingSystem.title()}
                value={
                    _.isEmpty(containerImage.data.operatingSystemDistribution)
                        ? undefined
                        :_.isEmpty(containerImage.data.operatingSystemVersion)
                            ? containerImage.data.operatingSystemDistribution
                            : localization.info.items.operatingSystem.distributionAndVersion({
                                distribution: containerImage.data.operatingSystemDistribution,
                                version: containerImage.data.operatingSystemVersion
                            })}/>,
        operatingSystemTypeInfoItemElement:
            <InfoItem
                key="operatingSystemType"
                title={localization.info.items.operatingSystemType.title()}
                value={
                    _.isNil(containerImage.data.operatingSystemType)
                        ? undefined
                        : localization.info.items.operatingSystemType[Contract.TypeNames.OperatingSystemType][containerImage.data.operatingSystemType]()}/>,
        repositoryInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={containerImageModel.repositoryIdReference}
                entityTypeName={Contract.TypeNames.IContainerImageRepository}
                key="repository"
                location="all"
                title={localization.info.items.repository()}/>,
        scanTimeInfoItemElement:
            <InfoItem
                key="scanTime"
                location="all"
                title={localization.info.items.scanTime()}
                value={
                    _.isNil(containerImage.data.scanTime)
                        ? undefined
                        : formatRelativeTime(containerImage.data.scanTime)}/>,
        tagsInfoItemElement:
            <TagsInfoItem
                key="tags"
                location="all"
                resourceTags={
                    _.map(
                        containerImage.data.tags,
                        tag => ({
                            key: tag,
                            value: undefined
                        }))}
                title={localization.info.items.tags()}/>,
        virtualMachineIdsInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={containerImageModel.virtualMachineIds}
                entityTypeName={Contract.TypeNames.IVirtualMachine}
                key="virtualMachineIds"
                location="all"
                title={localization.info.items.virtualMachineIds()}/>,
        vulnerabilitiesInfoItemElement:
            <InfoItem
                key="vulnerabilities"
                location="all"
                title={localization.info.items.vulnerabilities()}
                value={
                    <VulnerabilitiesChartCell
                        variant="initialAndCount"
                        vulnerabilities={containerImage.data.vulnerabilities}
                        workloadResourceEntityId={containerImage.id}/>}
                valueSx={{ maxWidth: "100%" }}/>,
        workloadClusterResourceIdsInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={containerImageModel.workloadClusterResourceIds}
                entityTypeName={Contract.TypeNames.Entity}
                key="workloadClusterResourceIds"
                location="all"
                title={localization.info.items.workloadClusterResourceIds()}/>
    };
}