import { InputAdornment, SxProps, TextField, useTheme } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { ClearIcon, SearchIcon, Sx } from "@infrastructure";
import { SearchTextFieldElementClass } from "./inputs.element";

type SearchTextFieldProps = {
    fullWidth?: boolean;
    inputSx?: SxProps;
    onSearchTextChanged: (searchText?: string) => void;
    placeholder?: string;
    searchText?: string;
    variant?: "filled" | "outlined" | "underlined";
};

export function SearchTextField({ fullWidth = true, inputSx, onSearchTextChanged, placeholder, searchText, variant = "filled" }: SearchTextFieldProps) {
    const textFieldRef = useRef<HTMLDivElement>(null);
    const [showClear, setShowClear] = useState(true);

    useResizeObserver(
        textFieldRef,
        textFieldElement => setShowClear(textFieldElement.contentRect.width > 150));

    const theme = useTheme();
    return (
        <TextField
            autoFocus={true}
            fullWidth={fullWidth}
            placeholder={placeholder}
            ref={textFieldRef}
            slotProps={{
                htmlInput: {
                    sx:
                        variant === "outlined"
                            ? {
                                fontSize: "14px",
                                height: theme.spacing(2),
                                padding: theme.spacing(1, 3, 1, 0)
                            }
                            : undefined
                },
                input: {
                    className: SearchTextFieldElementClass.Input,
                    ...(variant !== "outlined" && {
                        disableUnderline: true
                    }),
                    endAdornment:
                        showClear && (
                            <InputAdornment
                                position="end"
                                sx={{
                                    cursor: "pointer",
                                    fontSize: "18px",
                                    marginRight: theme.spacing(1),
                                    visibility:
                                        _.isEmpty(searchText)
                                            ? "hidden"
                                            : undefined
                                }}
                                onClick={
                                    () => {
                                        onSearchTextChanged(undefined);
                                    }}>
                                <ClearIcon/>
                            </InputAdornment>),
                    startAdornment:
                        <InputAdornment
                            position="start"
                            sx={{
                                fontSize:
                                    variant === "outlined"
                                        ? "20px"
                                        : "18px",
                                marginLeft:
                                    variant === "outlined"
                                        ? undefined
                                        : theme.spacing(2)
                            }}>
                            <SearchIcon/>
                        </InputAdornment>,
                    sx:
                        Sx.combine(
                            {
                                ...(variant === "filled"
                                    && ({
                                        "& input:-webkit-autofill": {
                                            WebkitBoxShadow: `0 0 0 100px ${theme.palette.input.filledBackground} inset`
                                        }
                                    })),
                                backgroundColor:
                                    variant === "filled"
                                        ? theme.palette.input.filledBackground
                                        : undefined,
                                borderBottom:
                                    variant === "underlined"
                                        ? theme.border.primary
                                        : undefined,
                                borderRadius:
                                    variant === "filled"
                                        ? theme.spacing(0.75)
                                        : undefined
                            },
                            inputSx)
                }
            }}
            sx={
                variant !== "outlined"
                    ? undefined
                    : {
                        "& fieldset": {
                            border: theme.border.pageFrame,
                            borderRadius: theme.spacing(0.75)
                        }
                    }}
            value={searchText ?? ""}
            variant={
                variant === "outlined"
                    ? "outlined"
                    : "standard"}
            onChange={event => onSearchTextChanged(event.target.value)}/>);
}