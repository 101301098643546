import { ItemSelector, TextViewer, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract } from "../../../../../../../../../common";

type AwsIamPolicyRevisionsProps = {
    defaultRevisionIndex: number;
    revisions: AwsIamPolicyRevisionsRevision[];
};

export class AwsIamPolicyRevisionsRevision {
    constructor(
        public index: number,
        public policyDocument: Contract.AwsIamPolicyDocument,
        public time?: string) {
    }
}

export function AwsIamPolicyRevisions({ defaultRevisionIndex, revisions }: AwsIamPolicyRevisionsProps) {
    const [selectedRevision, setSelectedRevision] =
        useState(
            () =>
                _.find(
                    revisions,
                    revision => revision.index === defaultRevisionIndex)!);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.awsIamPolicyRevisions",
            () => ({
                fields: {
                    revision: {
                        default: "Version {{revisionIndex}} (Default)",
                        nonDefault: "Version {{revisionIndex}}",
                        title: {
                            withoutTime: "**{{revisionIndex}}**",
                            withTime: "**{{revisionIndex}}** - {{revisionTime | TimeFormatter.shortDateTime}}"
                        }
                    }
                }
            }));

    return (
        <Stack
            spacing={1}
            sx={{ height: "100%" }}>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _.orderBy(
                        revisions,
                        revision => revision.index,
                        "desc")}
                selectedItem={selectedRevision}
                sorted={false}
                onSelectedItemChanged={setSelectedRevision}>
                {revision =>
                    <Typography>
                        {(_.isNil(revision.time)
                            ? localization.fields.revision.title.withoutTime
                            : localization.fields.revision.title.withTime)({
                            revisionIndex:
                                revision.index === defaultRevisionIndex
                                    ? localization.fields.revision.default({ revisionIndex: revision.index })
                                    : localization.fields.revision.nonDefault({ revisionIndex: revision.index }),
                            revisionTime: revision.time
                        })}
                    </Typography>}
            </ItemSelector>
            <Box sx={{ flex: 1 }}>
                <TextViewer
                    format="json"
                    text={selectedRevision.policyDocument.raw}/>
            </Box>
        </Stack>);
}