import { CheckboxField, DeleteIcon, Message, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, FormHelperText, IconButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract } from "../controllers";
import { useSetResourceTagPatternSelectorContext } from "./ResourceTagPatternsSelector";
import { TextInput } from "./TextInput";

const MaxKeyPatternLength = 256;
const MaxValuePatternLength = 126;

type ResourceTagPatternProps = {
    disabled: boolean;
    item: ResourceTagPatternItem;
    readOnly?: boolean;
};

export function ResourceTagPattern({ disabled, item, readOnly = false }: ResourceTagPatternProps) {
    const setContext = useSetResourceTagPatternSelectorContext();

    const [focusKey, setFocusKey] = useState(false);
    const [focusValue, setFocusValue] = useState(false);
    const [keyPattern, setKeyPattern] = useState(() => item.keyPattern ?? "");
    const [valuePattern, setValuePattern] = useState(() => item.valuePattern ?? "");
    const [caseSensitive, setCaseSensitive] = useState(() => item.caseSensitive ?? false);

    const localization =
        useLocalization(
            "common.resourceTagPattern",
            () => ({
                caseInsensitive: "Ignore case",
                duplicate: "Duplicate value is not allowed",
                fields: {
                    keyPattern: {
                        emptyError: "Key cannot be empty",
                        title: "Tag Key Pattern"
                    },
                    valuePattern: {
                        emptyError: "Value cannot be empty",
                        title: "Tag Value Pattern"
                    }
                },
                helpText: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character."
            }));

    useChangeEffect(
        () => {
            setContext(
                context => {
                    const updatedItem =
                        new ResourceTagPatternItem(
                            caseSensitive,
                            keyPattern,
                            valuePattern,
                            item.id,
                            item.duplicate);
                    const updatedItems =
                        item.placeholder
                            ? _.concat(
                                context.resourceTagPatternItems,
                                updatedItem)
                            : _.map(
                                context.resourceTagPatternItems,
                                resourceTagPatternItem =>
                                    resourceTagPatternItem.id === item.id
                                        ? updatedItem
                                        : resourceTagPatternItem);
                    const validatedUpdatedItems =
                        _.map(
                            updatedItems,
                            updatedItem =>
                                new ResourceTagPatternItem(
                                    updatedItem.caseSensitive,
                                    updatedItem.keyPattern,
                                    updatedItem.valuePattern,
                                    updatedItem.id,
                                    _.filter(
                                        updatedItems,
                                        item =>
                                            item.keyPattern === updatedItem.keyPattern &&
                                            item.valuePattern === updatedItem.valuePattern).length > 1,
                                    updatedItem.placeholder));
                    return {
                        resourceTagPatternItems: validatedUpdatedItems
                    };
                });
        },
        [keyPattern, valuePattern, caseSensitive],
        500);

    const onDelete =
        () => {
            setContext(
                context => {
                    const updatedItems =
                        _.filter(
                            context.resourceTagPatternItems,
                            resourceTagPatternItem => resourceTagPatternItem.id !== item.id);
                    const validatedUpdatedItems =
                        _.map(
                            updatedItems,
                            updatedItem =>
                                new ResourceTagPatternItem(
                                    updatedItem.caseSensitive,
                                    updatedItem.keyPattern,
                                    updatedItem.valuePattern,
                                    updatedItem.id,
                                    _.filter(
                                        updatedItems,
                                        item =>
                                            item.keyPattern === updatedItem.keyPattern &&
                                            item.valuePattern === updatedItem.valuePattern).length > 1,
                                    updatedItem.placeholder));
                    return {
                        resourceTagPatternItems: validatedUpdatedItems
                    };
                });
        };

    return (
        <Stack sx={{ width: "100%" }}>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    alignItems: "center",
                    height: "100%"
                }}>
                <TextInput
                    disabled={disabled}
                    formSx={{ width: "35%" }}
                    placeholder={localization.fields.keyPattern.title()}
                    readOnly={readOnly}
                    text={keyPattern}
                    onBlur={() => setFocusKey(false)}
                    onChange={keyPattern => setKeyPattern(keyPattern.substring(0, MaxKeyPatternLength))}
                    onFocus={() => setFocusKey(true)}/>
                <TextInput
                    disabled={disabled}
                    formSx={{ width: "35%" }}
                    placeholder={localization.fields.valuePattern.title()}
                    readOnly={readOnly}
                    text={valuePattern}
                    onBlur={() => setFocusValue(false)}
                    onChange={valuePattern => setValuePattern(valuePattern.substring(0, MaxValuePatternLength))}
                    onFocus={() => setFocusValue(true)}/>
                {disabled || readOnly || _.isNil(item) || item.placeholder
                    ? <Message
                        level="info"
                        title={
                            <Typography style={{ whiteSpace: "pre-wrap" }}>
                                {localization.helpText()}
                            </Typography>}
                        variant="minimal"/>
                    : <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <CheckboxField
                            checked={!caseSensitive}
                            title={localization.caseInsensitive()}
                            onChange={() => setCaseSensitive(!caseSensitive)}>
                            {localization.caseInsensitive()}
                        </CheckboxField>
                        <IconButton
                            size="small"
                            onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </Stack>}
            </Stack>
            <Stack direction="row">
                {item.duplicate && !item.keyEmpty() && !item.valueEmpty()
                    ? <FormHelperText error={true}>{localization.duplicate()}</FormHelperText>
                    : <Fragment>
                        <Box sx={{ width: "35%" }}>
                            {!focusKey && !focusValue && !item.placeholder && item.keyEmpty() &&
                                <FormHelperText error={true}>{localization.fields.keyPattern.emptyError()}</FormHelperText>}
                        </Box>
                        <Box sx={{ width: "35%" }}>
                            {!focusKey && !focusValue && !item.placeholder && item.valueEmpty() &&
                                <FormHelperText error={true}>{localization.fields.valuePattern.emptyError()}</FormHelperText>}
                        </Box>
                    </Fragment>}
            </Stack>
        </Stack>);
}

export class ResourceTagPatternItem extends Contract.ResourceTagPattern {
    private static _idCounter = 0;
    public duplicate: boolean;
    public id: number;
    public placeholder: boolean;

    constructor(
        public caseSensitive: boolean,
        public keyPattern: string,
        public valuePattern: Optional<string>,
        id?: number,
        duplicate?: boolean,
        placeholder?: boolean) {
        super(caseSensitive, keyPattern, valuePattern);
        this.duplicate = duplicate ?? false;
        this.id = id ?? ResourceTagPatternItem._idCounter++;
        this.placeholder = placeholder ?? false;
    }

    public static create(resourceTagPattern: Contract.ResourceTagPattern) {
        return new ResourceTagPatternItem(
            resourceTagPattern.caseSensitive,
            resourceTagPattern.keyPattern,
            resourceTagPattern.valuePattern);
    }

    public keyEmpty() {
        return _.isEmpty(this.keyPattern);
    }

    public valueEmpty() {
        return _.isEmpty(this.valuePattern);
    }

    public valid() {
        return !this.keyEmpty() && !this.valueEmpty() && !this.duplicate;
    }
}