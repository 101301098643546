import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../../../utilities";
import { useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryGroupDefinition, useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryPartialUserDefinition, useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryUserDefinition, useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciPartialTenantEntityDefinition, useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciTenantEntityDefinition } from "./hooks";


export function useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const principalModel = entityModelStore.useGet((riskModel.risk as Contract.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk).entityId);
    const useDefinition =
        useMemo(
            () => {
                switch (principalModel.entity.typeName) {
                    case Contract.TypeNames.GciDirectoryGroup:
                        return useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryGroupDefinition;
                    case Contract.TypeNames.GciDirectoryPartialUser:
                        return useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryPartialUserDefinition;
                    case Contract.TypeNames.GciDirectoryUser:
                        return useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryUserDefinition;
                    case Contract.TypeNames.GciPartialTenantEntity:
                        return useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciPartialTenantEntityDefinition;
                    case Contract.TypeNames.GciTenantEntity:
                        return useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciTenantEntityDefinition;
                    default:
                        throw new UnexpectedError("principalModel.entity.typeName", principalModel.entity.typeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskModel);
}