import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureLogicWorkflowRiskContext() {
    return useMemo(
        () => useAzureLogicWorkflowRiskContext,
        []);
}

function useAzureLogicWorkflowRiskContext(workflowModel: Contract.AzureLogicWorkflowModel) {
    const resourceRiskContext = useGetAzureResourceRiskContext()(workflowModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(workflowModel);
    const workflow = workflowModel.entity as Contract.AzureLogicWorkflow;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureLogicWorkflowRiskContext",
            () => ({
                status: "The {{translatedWorkflowTypeName}} status is **{{status}}**",
                version: "The {{translatedWorkflowTypeName}} latest version was published on {{updateTime | TimeFormatter.shortDate}}"
            }));
    const translatedWorkflowTypeName =
        entityTypeNameTranslator(
            workflow.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        allNetworkAccess: resourceRiskContext.getAllNetworkAccessContextItem(workflow.triggerNetworkAccessType === Contract.AzureLogicWorkflowTriggerNetworkAccessType.Public),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: workflow.status,
                    translatedWorkflowTypeName
                })),
        version:
            new RiskDefinitionContextItem(
                localization.version({
                    translatedWorkflowTypeName,
                    updateTime: workflow.updateTime
                }))
    };
}