import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DiffViewerIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_61027_8742)">
                <path
                    clipRule="evenodd"
                    d="M14.9024 3.19551C14.3859 3.01436 14.7001 3.00009 12.818 3.00009C9.58326 3.00009 8.22476 3.01438 7.17029 3.38462C5.40692 4.00381 4.04034 5.30299 3.39869 6.92245C3.21848 7.37701 3.11182 7.9271 3.05646 8.76641C3.00051 9.61463 3 10.6874 3 12.1821V14.7271C3 16.2863 3.00162 15.3682 3.09914 16.1967C3.19389 17.0016 3.36794 17.4444 3.63059 17.7745C3.81575 18.0074 4.03359 18.2125 4.27716 18.3834L4.27918 18.3848C4.64633 18.6436 5.14341 18.8143 6.02067 18.9057C6.91377 18.9988 8.07552 19.0001 9.727 19.0001H14C14.5523 19.0001 15 19.4478 15 20.0001C15 20.5524 14.5523 21.0001 14 21.0001H9.67066C8.08846 21.0001 6.82229 21.0001 5.81333 20.895C4.77705 20.7869 3.89244 20.5588 3.12783 20.0201C2.72751 19.7393 2.36967 19.4023 2.0653 19.0195C1.48003 18.2837 1.2301 17.4265 1.11286 16.4305C0.999961 15.4714 0.999979 16.2715 1 14.7894L1 12.1464C0.999997 10.6951 0.999994 9.55651 1.06079 8.63477C1.12268 7.69667 1.25058 6.91413 1.53932 6.18574C2.40569 3.99929 4.22713 2.29836 6.5077 1.49757C7.92625 0.999495 9.65739 0.999706 12.5749 1.00007C12.655 1.00008 12.736 1.00009 12.818 1.00009C12.8886 1.00009 12.9582 1.00007 13.0268 1.00005C14.6229 0.99949 14.6836 0.99912 15.5652 1.30852C16.9781 1.8052 18.1167 2.86259 18.6602 4.23263C18.8493 4.70786 18.9273 5.20524 18.9642 5.76266C19 6.30372 19 6.96643 19 7.784V10.0001C19 10.5524 18.5523 11.0001 18 11.0001C17.4477 11.0001 17 10.5524 17 10.0001V7.81809C17 6.95836 16.9995 6.36197 16.9686 5.89478C16.9382 5.43655 16.8817 5.17252 16.8019 4.97183L16.8014 4.97068C16.4829 4.16728 15.7989 3.51079 14.9024 3.19551Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M2 13.0001C1.44772 13.0001 1 12.5524 1 12.0001C1 10.8509 1.45651 9.74882 2.26911 8.93623C3.0817 8.12363 4.18382 7.66712 5.333 7.66712C5.52509 7.66712 5.71986 7.67545 5.89682 7.6836C5.91955 7.68465 5.94198 7.6857 5.96415 7.68673C6.12146 7.69405 6.26577 7.70076 6.40959 7.70371C6.74344 7.71054 6.98767 7.69344 7.17217 7.6442C7.28521 7.61364 7.38826 7.55399 7.47106 7.47118C7.5539 7.38835 7.61356 7.28524 7.64411 7.17216C7.69333 6.98766 7.71042 6.74348 7.70359 6.40971C7.70064 6.26589 7.69393 6.12158 7.68661 5.96427C7.68557 5.9421 7.68453 5.91967 7.68348 5.89695C7.67533 5.71998 7.667 5.52522 7.667 5.33312C7.667 4.7641 7.77908 4.20066 7.99683 3.67495C8.21459 3.14925 8.53375 2.67159 8.93611 2.26923C9.33846 1.86687 9.81613 1.54771 10.3418 1.32995C10.8675 1.1122 11.431 1.00012 12 1.00012C12.5523 1.00012 13 1.44784 13 2.00012C13 2.55241 12.5523 3.00012 12 3.00012C11.6936 3.00012 11.3903 3.06047 11.1072 3.17771C10.8241 3.29496 10.567 3.4668 10.3503 3.68344C10.1337 3.90008 9.96184 4.15727 9.84459 4.44032C9.72735 4.72338 9.667 5.02675 9.667 5.33312C9.667 5.47403 9.67317 5.62714 9.68136 5.80489C9.68234 5.82609 9.68335 5.84769 9.68437 5.86964C9.6916 6.02453 9.69965 6.19687 9.70317 6.36879C9.71108 6.75537 9.69971 7.22701 9.57606 7.68944L9.57569 7.69082C9.45419 8.14263 9.2161 8.55457 8.88528 8.8854C8.55445 9.21622 8.14251 9.45431 7.6907 9.57581L7.68931 9.57618C7.22688 9.69983 6.75525 9.7112 6.36867 9.70329C6.19676 9.69977 6.02443 9.69172 5.86955 9.68449C5.84759 9.68347 5.82598 9.68246 5.80477 9.68148C5.62702 9.67329 5.47391 9.66712 5.333 9.66712C4.71425 9.66712 4.12084 9.91292 3.68332 10.3504C3.2458 10.788 3 11.3814 3 12.0001C3 12.5524 2.55229 13.0001 2 13.0001Z"
                    fillRule="evenodd"/>
                <path d="M16.4999 19C16.1999 19 15.9999 18.9 15.7999 18.7C15.3999 18.3 15.3999 17.7 15.7999 17.3C15.9999 17.2 16.1999 16.9 16.4999 16.7C16.4999 16.7 16.5999 16.6 16.6999 16.5H10.8999C10.2999 16.5 9.8999 16.1 9.8999 15.5C9.8999 14.9 10.2999 14.5 10.8999 14.5H16.6999C16.6999 14.5 16.4999 14.4 16.4999 14.3C16.1999 14.1 15.8999 13.8 15.7999 13.7C15.3999 13.3 15.3999 12.7 15.7999 12.3C16.1999 11.9 16.7999 11.9 17.1999 12.3C17.2999 12.4 17.4999 12.6 17.7999 12.8C19.0999 13.9 19.9999 14.6 19.9999 15.5C19.9999 16.4 19.0999 17.2 17.7999 18.2C17.5999 18.4 17.2999 18.6 17.1999 18.7C16.9999 18.9 16.7999 19 16.4999 19Z"/>
                <path
                    clipRule="evenodd"
                    d="M18.3661 7.04342C18.2617 7.0247 18.0673 7.00921 17.4902 7.00357L17.5098 5.00366C18.0625 5.00907 18.4231 5.02175 18.7191 5.07482C18.9735 5.12045 19.1885 5.20199 19.3572 5.26598C19.3921 5.27922 19.425 5.2917 19.4559 5.30296C20.8701 5.79354 22.1296 6.87752 22.6751 8.24847L22.6838 8.27014L22.6914 8.29218C23.0007 9.18899 23.0004 10.0844 23 11.4171C23 11.4654 23 11.5142 23 11.5637V16.6364C23 16.6812 23 16.7256 23 16.7697C23.0002 18.2443 23.0003 19.3394 22.8063 20.2014C22.5798 21.2077 22.101 21.8853 21.3169 22.5956L21.3153 22.5971C20.5611 23.2775 19.8605 23.6619 18.831 23.8419C17.9259 24.0002 16.7469 24.0001 15.0743 24L14.4497 24C13.8974 24 13.4497 23.5523 13.4497 23C13.4497 22.4478 13.8974 22 14.4497 22H14.9576C16.7785 22 17.7749 21.9962 18.4864 21.8718C19.0942 21.7655 19.4596 21.5774 19.9748 21.1128C20.5447 20.5963 20.743 20.2603 20.8551 19.7623C20.9939 19.1456 21 18.2852 21 16.6364V11.5637C21 10.0538 20.9884 9.50296 20.8083 8.96681C20.5025 8.22086 19.7393 7.5166 18.7954 7.19077L18.7881 7.18827L18.7809 7.18566C18.6739 7.14685 18.602 7.11995 18.5508 7.10077C18.4435 7.06059 18.4266 7.05429 18.3661 7.04342Z"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_61027_8742">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}