import { DataTableColumn, DataTableSortType, NumberFormatter, optionalTableCell, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonDataAnalysisDefinition } from "../useCommonDataAnalysisDefinition";

export function useAwsDynamoDbTableDefinition(definitionData: DefinitionData) {
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsDynamoDbTableDefinition",
            () => ({
                columns: {
                    accessLevel: {
                        warningMessage: "To evaluate access, grant the permission dynamodb:GetResourcePolicy to Tenable."
                    },
                    deleteProtection: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Delete Protection"
                    },
                    storageSize: "Size"
                }
            }));

    const commonAccessResourceDefinition =
        useAwsCommonAccessResourceDefinition(
            definitionData,
            { accessLevel: { warningMessage: localization.columns.accessLevel.warningMessage() } });

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                commonAccessResourceDefinition.columns.accessLevelColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsResourceModel) => ({
                                [localization.columns.storageSize()]: NumberFormatter.storage((item.entity as Contract.AwsDynamoDbTable).storageSize)
                            })
                    }}
                    id={Contract.EntityModelProperty.AwsDynamoDbTableStorageSize}
                    itemProperty={(item: Contract.AwsResourceModel) => NumberFormatter.storage((item.entity as Contract.AwsDynamoDbTable).storageSize)}
                    key={Contract.EntityModelProperty.AwsDynamoDbTableStorageSize}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.storageSize()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsDynamoDbTableModel) => ({
                                [localization.columns.deleteProtection.title()]:
                                    (item.entity as Contract.AwsDynamoDbTable).deleteProtection
                                        ? localization.columns.deleteProtection.enabled()
                                        : localization.columns.deleteProtection.disabled()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.deleteProtection.title()}>
                                    <ValuesFilterItem
                                        key="false"
                                        title={localization.columns.deleteProtection.disabled()}
                                        value={false}/>
                                    <ValuesFilterItem
                                        key="true"
                                        title={localization.columns.deleteProtection.enabled()}
                                        value={true}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsDynamoDbTableDeleteProtection}
                    key={Contract.EntityModelProperty.AwsDynamoDbTableDeleteProtection}
                    render={
                        optionalTableCell<Contract.AwsDynamoDbTableModel>(
                            item =>
                                _.isNil((item.entity as Contract.AwsDynamoDbTable).deleteProtection)
                                    ? undefined
                                    : (item.entity as Contract.AwsDynamoDbTable).deleteProtection
                                        ? localization.columns.deleteProtection.enabled()
                                        : localization.columns.deleteProtection.disabled())}
                    selectorOptions={{ default: false }}
                    title={localization.columns.deleteProtection.title()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
                commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
                commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn,
                commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(false),
                commonDataAnalysisResourceDefinition.columns.scanTimeColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsDynamoDbTableRequest(
                new Contract.EntityControllerGetEntityModelPageAwsDynamoDbTableRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDynamoDbTableDeleteProtection])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}