
import { NumberFormatter, useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { RiskPolicyConfigurationDefinitionData } from "..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { Numeric, ViewOnlyNumeric } from "../components";

export function useOciIamDomainPasswordLengthPolicyRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const passwordPolicyMinLengthMaxValue = 500;
    const passwordPolicyMinLengthMinValue = 1;

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useOciIamDomainPasswordLengthPolicyRiskPolicyConfigurationDefinition",
            () => ({
                description: "By default, Tenable Cloud Security uses a minimum password length of 14 characters to analyze this policy before opening findings.",
                invalidValue: "The value should be between {{minValue}} and {{maxValue}} characters",
                label: "Characters Length",
                title: "Password Requirements"
            }));

    const getPasswordPolicyMinLength =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration).passwordPolicyMinLength,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <Numeric
                    description={localization.description()}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    fromNumber={passwordPolicyMinLength => passwordPolicyMinLength}
                    getValue={getPasswordPolicyMinLength}
                    key={`${Contract.TypeNames.OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration}-passwordPolicyMinLength`}
                    label={localization.label()}
                    maxValue={passwordPolicyMinLengthMaxValue}
                    minValue={passwordPolicyMinLengthMinValue}
                    riskPoliciesType={riskPoliciesType}
                    setValue={
                        (riskPolicyConfiguration, passwordPolicyMinLength) => {
                            (riskPolicyConfiguration as Contract.OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration).passwordPolicyMinLength = passwordPolicyMinLength;
                            return undefined;
                        }}
                    title={localization.title()}
                    toNumber={passwordPolicyMinLength => passwordPolicyMinLength}
                    translateValue={NumberFormatter.humanize}
                    valueOutOfRangeErrorMessage={
                        localization.invalidValue({
                            maxValue: passwordPolicyMinLengthMaxValue,
                            minValue: passwordPolicyMinLengthMinValue
                        })}/>,
            viewItem: {
                getValue:
                    (_, item, scopeId) =>
                        <ViewOnlyNumeric
                            fromNumber={passwordPolicyMinLength => passwordPolicyMinLength}
                            getValue={getPasswordPolicyMinLength}
                            item={item}
                            riskPoliciesType={riskPoliciesType}
                            scopeId={scopeId}
                            toNumber={passwordPolicyMinLength => passwordPolicyMinLength}
                            translateValue={NumberFormatter.humanize}/>,
                title: localization.title()
            }
        }]
    };
}