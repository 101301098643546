import { Dropdown, DropdownActions, DropdownIcon } from "@infrastructure";
import { List, ListItemButton, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { useTheme } from "../themes";

type SeveritySelectorProps<T extends string> = {
    disabled?: boolean;
    dropdownIcon?: boolean;
    onSelectedOperatorChanged: (operator: T) => void;
    operators: T[];
    operatorTranslator: (operator: T) => string;
    selectedOperator: T;
    sx?: SxProps;
};

export function OperatorSelector<T extends string>({ disabled, dropdownIcon = true, onSelectedOperatorChanged, operators, operatorTranslator, selectedOperator, sx }: SeveritySelectorProps<T>) {
    const actionsRef = useRef<DropdownActions>();
    const theme = useTheme();

    return (
        <Dropdown
            actionsRef={actionsRef}
            disabled={disabled}
            justify="center"
            popoverElement={
                <List
                    dense={true}
                    sx={{ padding: 0 }}>
                    {_(operators).
                        orderBy(operator => operatorTranslator(operator)).
                        map(
                            operator =>
                                <ListItemButton
                                    dense={true}
                                    key={operator as string}
                                    sx={{
                                        fontSize: "18px",
                                        padding: theme.spacing(1, 1.5)
                                    }}
                                    onClick={
                                        () => {
                                            onSelectedOperatorChanged(operator);
                                            actionsRef.current?.close();
                                        }}>
                                    <Typography>
                                        {operatorTranslator(operator)}
                                    </Typography>
                                </ListItemButton>).
                        value()}
                </List>}
            popoverElementContainerSx={{ padding: 0 }}>
            <Stack
                direction="row"
                justifyContent="center"
                spacing={0.5}
                sx={{
                    borderBottom: theme.border.primary,
                    ...sx
                }}>
                <Typography component="span">
                    {operatorTranslator(selectedOperator)}
                </Typography>
                {dropdownIcon &&
                    <DropdownIcon
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: "18px"
                        }}/>}
            </Stack>
        </Dropdown>);
}