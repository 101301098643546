import { EmptyMessage, NumberFormatter, useLocalization } from "@infrastructure";
import { Box, Grid2, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo } from "react";
import { SectionHeader } from "..";
import { Chart, Contract, getOrderedOpenBehaviorRisksPolicyDataItems, TimeRangeHelper, useGetOpenBehaviorRiskPolicyMetadatas, useRiskPolicyTitleTranslator, useTheme } from "../../../../../../../../common";
import { Summary } from "./components";

type OpenBehaviorRiskSectionProps = {
    riskCount: number;
    timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap: Dictionary<Dictionary<number>>;
    timeFrameToRiskTrendsDataMap: Dictionary<Contract.DashboardSummaryRiskTrendsData>;
};

export function OpenBehaviorRiskSection({ riskCount, timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap, timeFrameToRiskTrendsDataMap }: OpenBehaviorRiskSectionProps) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const getOpenBehaviorRiskPolicyMetadatas = useGetOpenBehaviorRiskPolicyMetadatas();
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.openBehaviorRiskSection",
            () => ({
                column: {
                    [Contract.TypeNames.TimeFrame]: "{{ time }} days"
                },
                empty: "No anomaly detection findings",
                subtitle: "Track suspicious and unusual activity in your environment by viewing the number of opened anomaly-related findings over time. View the total number of findings that are currently open, as well as a breakdown of findings that were opened during set time periods (7, 30, 90 days). View a breakdown of these findings by activity category, each of which relates to a specific set of behavior patterns common to cloud identities. Ensure that relevant findings are resolved to improve your security posture.",
                title: "Anomaly Detection"
            }));

    const [orderedOpenBehaviorRiskPolicyDatas, timeFrameToCreatedBehaviorRiskCountMap] =
        useMemo(
            () => {
                const orderedOpenBehaviorRiskPolicyDatas =
                    _(timeFrameToRiskTrendsDataMap[Contract.TimeFrame.Long].dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap).
                        values().
                        flatMap(openBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap => _.keys(openBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap)).
                        uniq().
                        groupBy(openBehaviorRiskPolicyConfigurationTypeName => riskPolicyTitleTranslator(openBehaviorRiskPolicyConfigurationTypeName)).
                        map(
                            (openBehaviorRiskPolicyConfigurationTypeNames, openBehaviorRiskPolicyConfigurationTranslatedTypeName) => ({
                                timeFrameToCountMap:
                                    _.mapValues(
                                        timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap,
                                        createdBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap =>
                                            _(createdBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap).
                                                pickBy((createdBehaviorRiskCount, createdBehaviorRiskPolicyConfigurationTypeName) => _.includes(openBehaviorRiskPolicyConfigurationTypeNames, createdBehaviorRiskPolicyConfigurationTypeName)).
                                                values().
                                                sum() ?? 0),
                                translatedTypeName: openBehaviorRiskPolicyConfigurationTranslatedTypeName,
                                typeNames: openBehaviorRiskPolicyConfigurationTypeNames
                            })).
                        orderBy(
                            [
                                openBehaviorRiskPolicyData => openBehaviorRiskPolicyData.timeFrameToCountMap[Contract.TimeFrame.Long],
                                openBehaviorRiskPolicyData => openBehaviorRiskPolicyData.timeFrameToCountMap[Contract.TimeFrame.Medium],
                                openBehaviorRiskPolicyData => openBehaviorRiskPolicyData.timeFrameToCountMap[Contract.TimeFrame.Short]
                            ],
                            [
                                "desc",
                                "desc",
                                "desc"
                            ]).
                        value();

                const timeFrameToCreatedBehaviorRiskCountMap =
                    _.mapValues(
                        timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap,
                        openBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap =>
                            _(openBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap).
                                values().
                                sum());

                return [orderedOpenBehaviorRiskPolicyDatas, timeFrameToCreatedBehaviorRiskCountMap];
            },
            [timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap, timeFrameToRiskTrendsDataMap]);

    const orderedOpenBehaviorRiskPolicyMetadatas =
        useMemo(
            () =>
                getOpenBehaviorRiskPolicyMetadatas(
                    _.map(
                        orderedOpenBehaviorRiskPolicyDatas,
                        openBehaviorRiskPolicyData => openBehaviorRiskPolicyData.translatedTypeName)),
            [orderedOpenBehaviorRiskPolicyDatas]);

    const orderedOpenBehaviorRisksPolicyDataItems =
        useMemo(
            () =>
                getOrderedOpenBehaviorRisksPolicyDataItems(
                    timeFrameToRiskTrendsDataMap[Contract.TimeFrame.Long].dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap,
                    orderedOpenBehaviorRiskPolicyDatas),
            [orderedOpenBehaviorRiskPolicyDatas, timeFrameToRiskTrendsDataMap]);

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ width: "100%" }}>
            <SectionHeader
                subtitle={localization.subtitle()}
                title={localization.title()}
                variant="main"/>
            {_.isEmpty(timeFrameToRiskTrendsDataMap[Contract.TimeFrame.Long].dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap)
                ? <Box
                    style={{
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        margin: "unset",
                        marginTop: theme.spacing(1),
                        width: "unset"
                    }}>
                    <EmptyMessage message={localization.empty()}/>
                </Box>
                : <Fragment>
                    <Summary
                        timeFrameToOpenBehaviorRiskCountMap={timeFrameToCreatedBehaviorRiskCountMap}
                        totalCount={riskCount}/>
                    <Chart
                        interactive={false}
                        lineMetadatas={orderedOpenBehaviorRiskPolicyMetadatas}
                        orderedLinesDataItems={orderedOpenBehaviorRisksPolicyDataItems}/>
                    <Stack>
                        <Grid2
                            alignItems="center"
                            container={true}
                            justifyContent="space-between"
                            style={{
                                backgroundColor: theme.palette.background.paper,
                                border: theme.border.primary,
                                borderBottom: theme.border.primary,
                                borderRadius: theme.spacing(0.75, 0.75, 0, 0),
                                height: theme.spacing(5),
                                padding: theme.spacing(0, 2),
                                width: "100%"
                            }}>
                            <Grid2 size="grow"/>
                            <Grid2 sx={{ width: theme.spacing(12) }}>
                                <Typography
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: 600
                                    }}>
                                    {localization.column[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Short) })}
                                </Typography>
                            </Grid2>
                            <Grid2 sx={{ width: theme.spacing(12) }}>
                                <Typography
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: 600
                                    }}>
                                    {localization.column[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Medium) })}
                                </Typography>
                            </Grid2>
                            <Grid2 sx={{ width: theme.spacing(12) }}>
                                <Typography
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: 600
                                    }}>
                                    {localization.column[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Long) })}
                                </Typography>
                            </Grid2>
                        </Grid2>
                        {_.map(
                            orderedOpenBehaviorRiskPolicyDatas,
                            (openBehaviorRiskPolicyData, openBehaviorRiskPolicyDataIndex) =>
                                <Item
                                    borderBottom={openBehaviorRiskPolicyDataIndex === _.size(openBehaviorRiskPolicyData) - 1}
                                    key={openBehaviorRiskPolicyData.translatedTypeName}
                                    openBehaviorRiskPolicyData={openBehaviorRiskPolicyData}
                                    openBehaviorRiskPolicyDataIndex={openBehaviorRiskPolicyDataIndex}/>
                        )}
                    </Stack>
                </Fragment>}
        </Stack>);
}

type ItemProps = {
    borderBottom?: boolean;
    openBehaviorRiskPolicyData: ItemOpenBehaviorRiskPolicyData;
    openBehaviorRiskPolicyDataIndex: number;
};

type ItemOpenBehaviorRiskPolicyData = {
    timeFrameToCountMap: Dictionary<number>;
    translatedTypeName: string;
    typeNames: string[];
};

function Item({ borderBottom, openBehaviorRiskPolicyData, openBehaviorRiskPolicyDataIndex }: ItemProps) {
    const theme = useTheme();
    return (
        <Grid2
            alignItems="center"
            container={true}
            style={{
                border: theme.border.primary,
                borderRadius:
                    borderBottom
                        ? theme.spacing(0, 0, 0.75, 0.75)
                        : undefined,
                borderTop: "none",
                padding: theme.spacing(2)
            }}>
            <Grid2 size="grow">
                <Grid2
                    alignItems="center"
                    container={true}
                    spacing={1}>
                    <Grid2>
                        <Box
                            style={{
                                backgroundColor: theme.palette.risk.behavior[openBehaviorRiskPolicyDataIndex],
                                height: theme.px(14),
                                width: theme.px(3)
                            }}/>
                    </Grid2>
                    <Grid2>
                        <Typography
                            style={{
                                fontSize: "14px",
                                fontWeight: 500
                            }}>
                            {openBehaviorRiskPolicyData.translatedTypeName}
                        </Typography>
                    </Grid2>
                </Grid2>
            </Grid2>
            {_.map(
                [
                    Contract.TimeFrame.Short,
                    Contract.TimeFrame.Medium,
                    Contract.TimeFrame.Long
                ],
                timeFrame =>
                    <Grid2
                        key={timeFrame}
                        sx={{ width: theme.spacing(12) }}>
                        {NumberFormatter.humanize(openBehaviorRiskPolicyData.timeFrameToCountMap[timeFrame])}
                    </Grid2>)}
        </Grid2>);
}