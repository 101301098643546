import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useRiskPolicyCategoryTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useRiskPolicyCategoryTranslator",
            () => ({
                [Contract.TypeNames.RiskPolicyCategory]: {
                    [Contract.RiskPolicyCategory.Access]: "IAM",
                    [Contract.RiskPolicyCategory.Behavior]: "Anomaly Detection",
                    [Contract.RiskPolicyCategory.Compute]: "Compute",
                    [Contract.RiskPolicyCategory.Custom]: "Custom Policies",
                    [Contract.RiskPolicyCategory.CustomKubernetesAdmissionController]: "Custom Kubernetes Admission Controller",
                    [Contract.RiskPolicyCategory.Data]: "Data",
                    [Contract.RiskPolicyCategory.Kubernetes]: "Kubernetes",
                    [Contract.RiskPolicyCategory.KubernetesAdmissionController]: "Kubernetes Admission Controller",
                    [Contract.RiskPolicyCategory.Logging]: "Logging",
                    [Contract.RiskPolicyCategory.Management]: "Management",
                    [Contract.RiskPolicyCategory.Monitoring]: "Monitoring",
                    [Contract.RiskPolicyCategory.Network]: "Network",
                    [Contract.RiskPolicyCategory.Secrets]: "Secrets",
                    [Contract.RiskPolicyCategory.WorkloadAnalysis]: "Workload Protection"
                }
            }));
    return (riskPolicyCategory: Contract.RiskPolicyCategory) =>
        localization[Contract.TypeNames.RiskPolicyCategory][riskPolicyCategory]();
}