import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsRdsNeptuneClusterSnapshotRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsNeptuneClusterSnapshotPublicAccessExistsRisk;
    const snapshotModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsNeptuneClusterSnapshotModel;
    const snapshot = snapshotModel.entity as Contract.AwsRdsNeptuneClusterSnapshot;

    const getAwsRdsNeptuneClusterSnapshotRiskContext = useGetAwsRdsNeptuneClusterSnapshotRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{snapshot}} is publicly accessible via visibility settings",
                sections: {
                    resolution: {
                        step1: "Under **DB cluster snapshot visibility**, select **Private**",
                        step2: "Optionally add explicit AWS accounts to share the snapshot with. Before saving, make sure any existing account that needs access is included",
                        step3: "Click **Save**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                snapshot:
                    <Entity
                        entityIdOrModel={snapshotModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Neptune,
                AwsConsoleUrlBuilder.getRdsNeptuneClusterSnapshotShareUrl(snapshot)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const snapshotContextItems = getAwsRdsNeptuneClusterSnapshotRiskContext(snapshotModel);
            return [
                snapshotContextItems.generalInformation,
                snapshotContextItems.sensitive,
                snapshotContextItems.cluster,
                snapshotContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}