﻿import _, { Dictionary } from "lodash";
import { EntityTypeMetadataModelHelper } from "../../../../../utilities";

export class EntitiesWidgetHelper {
    public static getEntityTypeCategoryToCountMap(entityTypeEntitiesViewNameToCountMap: Dictionary<number>) {
        return _(entityTypeEntitiesViewNameToCountMap).
            toPairs().
            groupBy(([entityTypeEntitiesViewName]) => EntityTypeMetadataModelHelper.get(entityTypeEntitiesViewName).category).
            mapValues(
                entityTypeEntitiesViewDatas =>
                    _(entityTypeEntitiesViewDatas).
                        map(([, entityTypeEntitiesViewCount]) => entityTypeEntitiesViewCount).
                        sum()).
            value();
    }
}