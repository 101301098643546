import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SysdigIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_1938)">
                <path
                    d="M12.271 21.5226C10.142 21.5613 8.1678 20.4 7.16135 18.542C6.69684 17.7678 5.53555 15.6774 5.49684 15.6C4.99361 14.671 6.34845 13.9355 6.85168 14.8258C6.85168 14.8645 8.05168 16.9936 8.51619 17.7678C9.63877 19.6645 11.6517 20.4 13.9355 19.7033C14.942 19.3936 15.4065 20.9032 14.4001 21.2129C13.7033 21.4065 12.9678 21.5226 12.271 21.5226Z"
                    fill="#00ABC7"/>
                <path
                    d="M12.4259 24C9.44522 24 6.65813 22.6065 5.22587 20.1678L2.05168 14.6323C1.81942 14.2452 1.97426 13.7807 2.32264 13.5484L4.52909 12.271C4.91619 12.0387 5.38071 12.1936 5.61297 12.542C5.84522 12.9291 5.69038 13.3936 5.30329 13.6258L3.79361 14.4774L6.61942 19.3549C8.1291 22.0645 11.7678 23.1484 15.0968 21.9871C17.7678 19.6645 18.6194 15.9871 17.1097 13.3162L14.4001 8.63228C14.1678 8.24519 14.3226 7.78067 14.671 7.54841C15.0581 7.31615 15.5226 7.47099 15.7549 7.85809L18.4646 12.542C20.4001 15.871 19.3162 20.4774 16.0259 23.2645C15.9485 23.342 15.871 23.3807 15.7936 23.4194C14.7097 23.8065 13.5872 24 12.4259 24Z"
                    fill="#00ABC7"/>
                <path
                    d="M12.1936 18.9678C10.9549 18.9678 9.90974 18.1549 9.3678 17.2258L0.116191 1.20002C-0.387034 0.270992 0.929095 -0.503202 1.47103 0.387121L10.7226 16.4516C10.9936 16.8774 11.6904 17.6516 12.5807 17.342C13.5872 17.0323 14.0517 18.5032 13.0452 18.8516C12.813 18.9291 12.5033 18.9678 12.1936 18.9678ZM10.1807 10.6839C9.90974 10.6839 9.63877 10.5291 9.48393 10.2968L6.92909 5.80648C6.42587 4.91615 7.78071 4.14196 8.28393 5.03228L10.4904 8.86454L11.8839 8.05164C12.7743 7.54841 13.5485 8.90325 12.6581 9.40648L10.5678 10.6065C10.4904 10.6452 10.3355 10.6839 10.1807 10.6839Z"
                    fill="#00ABC7"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_1938">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}