import { Tooltip, useLocalization } from "@infrastructure";
import { ManageAccounts as ServiceAccountIcon, Podcasts as TunnelSessionIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../common";

type KubernetesClusterDataProps = {
    connector: Contract.KubernetesClusterModelHelmConnector;
};

export function KubernetesClusterData({ connector }: KubernetesClusterDataProps) {
    const localization =
        useLocalization(
            "views.customer.entities.kubernetesClusterData",
            () => ({
                identity: "Kubernetes connector identity is configured",
                network: "Kubernetes connector network is connected"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            flexWrap="nowrap"
            spacing={1}>
            {connector.network &&
                <Tooltip titleOrGetTitle={localization.network()}>
                    <TunnelSessionIcon sx={{ fontSize: "18px" }}/>
                </Tooltip>}
            {connector.identity &&
                <Tooltip titleOrGetTitle={localization.identity()}>
                    <ServiceAccountIcon sx={{ fontSize: "18px" }}/>
                </Tooltip>}
        </Stack>);
}