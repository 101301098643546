import { Contract } from "../../../../../controllers";
import { EntityDefinition } from "../useDefinition";

export function useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition(entityModel: Contract.EntityModel) {
    const roleManagementPolicyAssignmentModel = entityModel as Contract.AadDirectoryDirectoryRoleManagementPolicyAssignmentModel;

    return new EntityDefinition(
        entityModel,
        {
            displayName: `${roleManagementPolicyAssignmentModel.entity.displayName} (Directory)`
        });
}