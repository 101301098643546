import { useMemo } from "react";
import { useOpResourceDefinition, useOpUnmanagedKubernetesClusterDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOpEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.OpUnmanagedKubernetesCluster:
                        return useOpUnmanagedKubernetesClusterDefinition;
                    default:
                        return useOpResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}