import { useMemo } from "react";
import { RiskPolicyItem } from "../../../../../../../../../../../common";
import { RiskPolicyConfigurationDefinition } from "../../../useDefinition";
import { useCloudCommonRiskPolicyDefinition } from "./useCloudCommonRiskPolicyDefinition";

export function useCloudRiskPolicyBuiltInDefinition(item: RiskPolicyItem, scopeId: string): RiskPolicyConfigurationDefinition {
    const { additionalTabs, generalAdditionalItems } = useCloudCommonRiskPolicyDefinition(item, scopeId);

    return useMemo(
        () => ({
            configurationCategoryTabToViewItemMap: additionalTabs,
            generalAdditionalItems
        }),
        [additionalTabs, generalAdditionalItems]);
}