import { useMemo } from "react";
import { CustomRiskPolicyItem, RiskPoliciesType, RiskPolicyItem, riskPolicyItemConfigurationOperationStore, ScopeHelper, TypeHelper } from "../../../../../common";
import { TypeNames } from "../../../../../common/controllers/typeNames.generated";

export function useRiskPoliciesItemConfiguration(riskPoliciesType: RiskPoliciesType, riskPolicyItem: RiskPolicyItem, scopeId: string) {
    const riskPolicyItemConfiguration =
        riskPolicyItemConfigurationOperationStore.useGet(
            riskPolicyItem.riskPolicyConfigurationTypeNameOrId,
            scopeId,
            riskPolicyItem.custom,
            riskPoliciesType);

    const scopeRiskPolicyModel =
        riskPolicyItem.custom
            ? (riskPolicyItem as CustomRiskPolicyItem).riskPolicyModel
            : riskPolicyItemConfiguration.scopeRiskPolicyModel;

    return useMemo(
        () => ({
            ...riskPolicyItemConfiguration,
            enabled: scopeRiskPolicyModel.riskPolicyConfiguration.enabled,
            generalInheritToggleEnabled: riskPoliciesType === RiskPoliciesType.KubernetesAdmissionController,
            inheritDisabled:
                scopeRiskPolicyModel.riskPolicyConfiguration.scopeId === ScopeHelper.customerId ||
                TypeHelper.extendOrImplement(scopeRiskPolicyModel.riskPolicyConfiguration.typeName, TypeNames.CustomKubernetesAdmissionControllerRiskPolicyConfiguration) ||
                TypeHelper.extendOrImplement(scopeRiskPolicyModel.riskPolicyConfiguration.typeName, TypeNames.CustomRiskPolicyConfiguration),
            riskPolicyConfiguration: scopeRiskPolicyModel.riskPolicyConfiguration,
            scopeRiskPolicyModel
        }),
        [riskPoliciesType, riskPolicyItemConfiguration, scopeRiskPolicyModel]);
}