import { Action1 } from "@infrastructure";
import { Box, List, ListItemButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { memo, MouseEvent } from "react";
import { useTheme } from "../../../../../../..";
import { ObjectSelectorItem } from "../ObjectSelector";

const CategoriesMemo = memo(Categories);
export { CategoriesMemo as Categories };

type CategoriesProps = {
    hoveredItemId?: string;
    items: ObjectSelectorItem[];
    onClick: Action1<string>;
};

function Categories({ hoveredItemId, items, onClick }: CategoriesProps) {
    const theme = useTheme();
    return (
        <List disablePadding={true}>
            {_.map(
                items,
                item =>
                    <ListItemButton
                        disableGutters={true}
                        key={item.id}
                        sx={{
                            "&:hover": {
                                background: theme.palette.action.hover
                            },
                            background:
                                hoveredItemId === item.id
                                    ? theme.palette.action.hover
                                    : undefined,
                            padding: theme.spacing(1, 2)
                        }}
                        onClick={
                            (event: MouseEvent) => {
                                onClick(item.id);

                                event.preventDefault();
                                event.stopPropagation();
                            }}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={0.75}>
                            {!_.isNil(item.options?.icon) &&
                                <Box sx={{ fontSize: "16px" }}>
                                    {item.options?.icon}
                                </Box>}
                            {_.isString(item.title)
                                ? <Typography
                                    noWrap={true}>
                                    {item.title}
                                </Typography>
                                : item.title}
                        </Stack>
                    </ListItemButton>)}
        </List>);
}