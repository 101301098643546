﻿import { ItemSelector } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Entity } from ".";
import { entityModelStore } from "../stores";

type EntitySelectorProps = {
    entityIds: string[];
    onSelectedEntityIdChanged: (selectedEntityId?: string) => void;
    placeholder: string;
    selectedEntityId?: string;
    sorted?: boolean;
};

export function EntitySelector({ entityIds, onSelectedEntityIdChanged, placeholder, selectedEntityId, sorted }: EntitySelectorProps) {
    const entityModels = entityModelStore.useGet(entityIds);
    const entityModelMap =
        useMemo(
            () =>
                _.keyBy(
                    entityModels,
                    entityModel => entityModel.entity.id),
            [entityModels]);
    return (
        <ItemSelector
            fullWidth={true}
            getItemText={entityId => entityModelMap[entityId].entity.displayName}
            items={entityIds}
            placeholder={placeholder}
            selectedItem={selectedEntityId}
            sorted={sorted}
            onSelectedItemChanged={entityId => onSelectedEntityIdChanged(entityId)}>
            {(entityId, inline) =>
                <Entity
                    entityIdOrModel={entityId}
                    linkOptions={{ disabled: true }}
                    variant={
                        inline
                            ? "text"
                            : "iconTextTypeTenant"}/>}
        </ItemSelector>);
}