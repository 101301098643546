import { DataTableColumnRenderProps, NoneIcon, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, StatusCell as BaseStatusCell } from "../../../../../../../../../common";
import { useAwsTenantModelTrailStatusTranslator } from "../hooks";

export function TrailStatusCell({ item }: DataTableColumnRenderProps<Contract.AwsTenantModel>) {
    const tenantModelTrailStatusTranslator = useAwsTenantModelTrailStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.trailStatusCell",
            () => ({
                [Contract.TypeNames.AwsTenantModelTrailStatus]: {
                    [Contract.AwsTenantModelTrailStatus.AccessDenied]: "Missing permissions to the CloudTrail service (cannot fetch list of trails)",
                    [Contract.AwsTenantModelTrailStatus.BucketAccessError]: "1) Missing permissions to read CloudTrail events from the target S3 bucket (may belong to a different account), or, 2) the S3 bucket has an encryption issue",
                    [Contract.AwsTenantModelTrailStatus.DuplicateEvents]: "CloudTrail contains duplicate events. Please ensure the trail is configured correctly",
                    [Contract.AwsTenantModelTrailStatus.NotConfigured]: "CloudTrail logs aren’t being analyzed. Please configure a valid trail",
                    [Contract.AwsTenantModelTrailStatus.Valid]: "Analyzing CloudTrail logs",
                    [Contract.AwsTenantModelTrailStatus.ValidationFailure]: "Invalid CloudTrail configured. Please select a valid trail or modify its configuration"
                }
            }));
    return (
        item.configuration.audit ||
            _.isNil(item.trailStatus)
            ? <NoneIcon sx={{ fontSize: "18px" }}/>
            : <BaseStatusCell
                statusSeverity={item.trailStatusSeverity}
                title={tenantModelTrailStatusTranslator(item.trailStatus)}
                tooltip={
                    (() => {
                        if (item.state.monitoring.eventAnalysisAccessDeniedErrorMessage) {
                            return <Typography
                                key={item.state.monitoring.eventAnalysisAccessDeniedErrorMessage}
                                sx={{ whiteSpace: "pre-wrap" }}>
                                {item.state.monitoring.eventAnalysisAccessDeniedErrorMessage}
                            </Typography>;
                        }
                        switch (item.trailStatus) {
                            case Contract.AwsTenantModelTrailStatus.AccessDenied:
                            case Contract.AwsTenantModelTrailStatus.BucketAccessError:
                            case Contract.AwsTenantModelTrailStatus.DuplicateEvents:
                            case Contract.AwsTenantModelTrailStatus.NotConfigured:
                            case Contract.AwsTenantModelTrailStatus.Valid:
                            case Contract.AwsTenantModelTrailStatus.ValidationFailure:
                                return (
                                    <Typography>
                                        {localization[Contract.TypeNames.AwsTenantModelTrailStatus][item.trailStatus]()}
                                    </Typography>);
                            default:
                                return undefined;
                        }
                    })()}/>);
}