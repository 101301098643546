import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsAutoScalingAutoScalingGroupsRiskContext, useGetAwsEc2InstancesRiskContext } from "../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useGetAwsEc2LaunchTemplateRiskContext } from "../../../contexts";

export function useAwsEc2InstanceInstanceProfileNotExistRiskLaunchTemplateDefinition(riskModel: Contract.AwsEc2InstanceInstanceProfileNotExistRiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceInstanceProfileNotExistRisk;
    const launchTemplateModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2LaunchTemplateModel;

    const getAwsAutoScalingAutoScalingGroupsRiskContext = useGetAwsAutoScalingAutoScalingGroupsRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsEc2LaunchTemplateRiskContext = useGetAwsEc2LaunchTemplateRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceInstanceProfileNotExistRiskDefinition.hooks.useAwsEc2InstanceInstanceProfileNotExistRiskLaunchTemplateDefinition",
            () => ({
                description: "{{launchTemplate}} is not configured with IAM instance profile",
                sections: {
                    resolution: {
                        step1: "Select the launch template **{{launchTemplateName}}** and choose **Actions, Modify template (Create new version)**",
                        step2: "Expand the **Advanced Details** section",
                        step3: "Under **IAM instance profile**, select an existing one or create a new",
                        step4: "Click on the **Create template version** button",
                        step5: "Set the new version as the default by clicking on **Actions > Set default version**",
                        step6: "Make sure the {{autoScalingGroups}} are using the latest version",
                        step7: "Make sure the {{instances}} are using the latest version",
                        step8: "Wait until all instances are relaunched with the updated template version",
                        step9: "On the Launch Template page, click **Versions**, and delete insecure versions by clicking **Delete template version**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchTemplate:
                <Entity
                    entityIdOrModel={launchTemplateModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2LaunchTemplateUrl(launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate)),
            localization.sections.resolution.step1({ launchTemplateName: launchTemplateModel.entity.displayName }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            !_.isEmpty(riskModel.risk.autoScalingGroupIds)
                ? localization.sections.resolution.step6({
                    autoScalingGroups:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.autoScalingGroupIds}
                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                            variant="itemCountAndType"/>
                })
                : undefined,
            !_.isEmpty(riskModel.risk.aggregatedEntityIds)
                ? localization.sections.resolution.step7({
                    instances:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            variant="itemCountAndType"/>
                })
                : undefined,
            localization.sections.resolution.step8(),
            localization.sections.resolution.step9()
        ],
        riskModel,
        () => {
            const autoScalingGroupsContextItems = getAwsAutoScalingAutoScalingGroupsRiskContext(riskModel.risk.autoScalingGroupIds!);
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const launchTemplateContextItems = getAwsEc2LaunchTemplateRiskContext(launchTemplateModel);
            return [
                launchTemplateContextItems.generalInformation,
                launchTemplateContextItems.sensitive,
                launchTemplateContextItems.getAutoScalingGroupsContextItem(riskModel.risk.autoScalingGroupIds),
                autoScalingGroupsContextItems.sensitive,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                launchTemplateContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}