import { useMemo } from "react";
import { usePingIdentityDefaultDirectoryEntityDefinition, usePingIdentityDirectoryGroupDefinition, usePingIdentityDirectoryUserDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function usePingIdentityDirectoryEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.PingIdentityDirectoryGroup:
                        return usePingIdentityDirectoryGroupDefinition;
                    case Contract.TypeNames.PingIdentityDirectoryUser:
                        return usePingIdentityDirectoryUserDefinition;
                    default:
                        return usePingIdentityDefaultDirectoryEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.PingIdentityDirectoryEntityModel, options);
}