﻿import { Optional, useActions, useLocalization } from "@infrastructure";
import { TOptions } from "i18next";
import _ from "lodash";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { AwsResourceUsageRiskPolicyEdit, EntitySelector, EntitySelectorProvisionedSelection, EntitySelectorSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsSsoPermissionSetManagementRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const [resourcesValid, setResourcesValid] = useState(false);
    const { scopeId } = useCustomRiskPolicyContext();

    const [permissionSetSelectorSelection, setPermissionSetSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            !_.isNil(riskPolicyConfiguration?.provisioned)
                ? new EntitySelectorProvisionedSelection(riskPolicyConfiguration!.provisioned!)
                : EntitySelectorSelection.getSelectorSelection(
                    riskPolicyConfiguration?.allResources,
                    undefined,
                    undefined,
                    riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                    undefined,
                    riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                    undefined,
                    undefined,
                    riskPolicyConfiguration?.resourceNamePattern,
                    undefined,
                    riskPolicyConfiguration?.resourceTags,
                    undefined));
    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityNamePattern, entityTags, provisionedEntity } = permissionSetSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAwsSsoPermissionSetManagementRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        undefined,
                        entityNamePattern,
                        entityTags,
                        provisionedEntity);
                }
        });

    useEffect(
        () => {
            const resourcesValid = permissionSetSelectorSelection?.valid() ?? false;
            setResourcesValid(resourcesValid);

            onValidChange(resourcesValid);
        },
        [permissionSetSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsSsoPermissionSetManagementRiskPolicy.edit",
            () => ({
                prompt: "Select the required {{resourceTranslatedTypeName}}"
            }));

    return (
        <AwsResourceUsageRiskPolicyEdit
            accordionContents={[
                {
                    selector:
                        <EntitySelector
                            builtInEntityAttributeTypeNames={[
                                Contract.TypeNames.AdministratorPrincipalAttribute,
                                Contract.TypeNames.PublicEntityAttribute,
                                Contract.TypeNames.SensitiveResourceAttribute,
                                Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                Contract.TypeNames.CodeResourceAttribute
                            ]}
                            entityTypeName={Contract.TypeNames.AwsSsoPermissionSet}
                            namePattern={true}
                            properties={["all", "provisioned", "tags", "attributes"]}
                            scopeId={scopeId}
                            selection={permissionSetSelectorSelection}
                            onSelectionChanged={setPermissionSetSelectorSelection}/>,
                    title:
                        entityTypeNameTranslator(
                            Contract.TypeNames.AwsSsoPermissionSet,
                            {
                                count: 0,
                                includeServiceName: false
                            }),
                    valid: resourcesValid
                }
            ]}
            prompt={localization.prompt({
                resourceTranslatedTypeName:
                    entityTypeNameTranslator(
                        Contract.TypeNames.AwsSsoPermissionSet,
                        {
                            count: 0,
                            includeServiceName: false,
                            variant: "text"
                        })
            })}
            template={
                templateTranslator({
                    permissionsSets:
                        <InlineEntitySelection
                            entityTypeName={Contract.TypeNames.AwsSsoPermissionSet}
                            selection={permissionSetSelectorSelection}/>
                })}/>);
}