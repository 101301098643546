import { clearOperations, getUrlQueryParameters, Loading, map, ThemeType, useExecuteOperation, useRoute, useUniqueKey, VerticalFillGrid } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, ReactElement, useEffect, useMemo } from "react";
import { ApiController, ApplicationHelper, ApplicationView, AuthenticationController, Contract, Layout, PermissionManagementController, TeamsConsoleAppUrlHelper, useApplicationViewRoute, UserHelper, useTheme } from "../../common";
import { UserContext, UserView, useUserContextProvider } from "../User";
import { ApproverUserPermissionRequests, GranteeUserPermissionRequests, Sidebar } from "../User/components";
import { SignInError } from "./components";
import { TeamsHelper } from "./utilities";


export function Teams() {
    ApiController.initialize(Contract.ConsoleAppType.Teams);

    useExecuteOperation(
        Teams,
        () => TeamsHelper.initialize());

    const theme = useTheme();

    function setTheme(teamsTheme: string) {
        const themeType =
            teamsTheme === "dark"
                ? ThemeType.Dark
                : ThemeType.Light;
        theme.setType(themeType);
    }

    useEffect(
        () => {
            async function initTeamsTheme() {
                const teamsTheme = await TeamsHelper.getTheme();
                setTheme(teamsTheme);
                TeamsHelper.registerOnThemeChangeHandler(setTheme);
            }

            initTeamsTheme();
        },
        []);

    const { view } = useRoute(`/${ApplicationView.Teams}/{view}`);
    return view === TeamsView.SignInError
        ? <SignInError/>
        : <SignIn>
            <Core/>
        </SignIn>;
}

type SignInProps = {
    children: ReactElement;
};

function SignIn({ children }: SignInProps) {
    const [redirectUrl] =
        useExecuteOperation(
            SignIn,
            async () => {
                const { rawToken } = getUrlQueryParameters<{ rawToken?: string }>();
                if (_.isNil(rawToken)) {
                    const rawToken = await TeamsHelper.getUserJwtToken();
                    const { error, redirectUrl } = await AuthenticationController.getTeamsSignInDeploymentRedirect(new Contract.AuthenticationControllerGetTeamsSignInDeploymentRedirectRequest(rawToken));

                    return !_.isNil(error)
                        ? TeamsConsoleAppUrlHelper.getSignInErrorRelativeUrl(error)
                        : redirectUrl!;
                } else {
                    const { error } = await AuthenticationController.signInTeams(new Contract.AuthenticationControllerSignInTeamsRequest(rawToken));
                    return _.isNil(error)
                        ? undefined
                        : TeamsConsoleAppUrlHelper.getSignInErrorRelativeUrl(error);
                }
            });


    if (!_.isNil(redirectUrl)) {
        window.location.assign(redirectUrl);
        return <Fragment/>;
    }

    return children;
}

function Core() {
    const [{ approver, grantee }] =
        useExecuteOperation(
            Core,
            async () => {
                await ApplicationHelper.initialize(Contract.ConsoleAppType.Teams);
                return UserHelper.hasAnyScopePermissions(undefined, Contract.IdentityPermission.PermissionManagementPermissionRequest)
                    ? await PermissionManagementController.getUserInfo()
                    : {
                        approver: false,
                        grantee: false
                    };
            });

    const views =
        useMemo(
            () =>
                _<string>([]).
                    concatIf(
                        grantee,
                        UserView.GranteeUserPermissionRequests).
                    concatIf(
                        approver,
                        UserView.ApproverUserPermissionRequests).
                    value() as TeamsViewType[],
            [approver, grantee]);

    const [teamsViewRoute, setTeamsViewRoute] =
        useApplicationViewRoute(
            `/${ApplicationView.Teams}`,
            views,
            grantee
                ? TeamsView.GranteeUserPermissionRequests
                : TeamsView.ApproverUserPermissionRequests);

    const { view } = teamsViewRoute;

    const [viewKey, updateViewKey] = useUniqueKey();
    const [, , UserContextProvider] =
        useUserContextProvider(
            () =>
                new UserContext(
                    () => {
                        clearOperations();
                        updateViewKey();
                    }),
            []);

    return (
        <UserContextProvider>
            <Layout
                sidebar={
                    <Sidebar
                        rootRelativeUrl={TeamsConsoleAppUrlHelper.getRootRelativeUrl()}
                        setViewRoute={setTeamsViewRoute}
                        viewRoute={teamsViewRoute}
                        views={views}/>}>
                <Loading key={viewKey}>
                    <VerticalFillGrid>
                        {useMemo(
                            () =>
                                map(
                                    view,
                                    {
                                        [TeamsView.ApproverUserPermissionRequests]: () => <ApproverUserPermissionRequests applicationView={ApplicationView.Teams}/>,
                                        [TeamsView.GranteeUserPermissionRequests]: () => <GranteeUserPermissionRequests applicationView={ApplicationView.Teams}/>
                                    }),
                            [view])}
                    </VerticalFillGrid>
                </Loading>
            </Layout>
        </UserContextProvider>);
}

export const TeamsView = {
    [Contract.TeamsConsoleAppView.ApproverUserPermissionRequests]: "approverUserPermissionRequests",
    [Contract.TeamsConsoleAppView.GranteeUserPermissionRequests]: "granteeUserPermissionRequests",
    [Contract.TeamsConsoleAppView.SignInError]: "signInError"
};

export type TeamsViewType = keyof (typeof UserView);