import { TimeFormatter } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { LinesDataItem } from "./LinesDataItem";

export function getOrderedOpenBehaviorRisksPolicyDataItems(dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap: Dictionary<Dictionary<number>>, openBehaviorRiskPolicyDatas: openBehaviorRiskPolicyData[]) {
    return _(dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap).
        map(
            (openBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap, date) => ({
                dataItem:
                    new LinesDataItem(
                        TimeFormatter.monthAndDay(date),
                        _(openBehaviorRiskPolicyDatas).
                            keyBy(openBehaviorRiskPolicyData => openBehaviorRiskPolicyData.translatedTypeName).
                            mapValues(
                                openBehaviorRiskPolicyData =>
                                    _(openBehaviorRiskPolicyData.typeNames).
                                        map(openBehaviorRiskPolicyConfigurationTypeName => openBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap[openBehaviorRiskPolicyConfigurationTypeName]).
                                        sum()).
                            value()),
                date
            })).
        orderBy(({ date }) => TimeFormatter.sortableDateTime(date)).
        map(({ dataItem }) => dataItem).
        value();
}

type openBehaviorRiskPolicyData = {
    translatedTypeName: string;
    typeNames: string[];
};