﻿import { useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { CustomerLicenceObjectTranslationInfo } from "../../useGetCustomerLicenseObjectInfo";

export function useGetVersion1Translator() {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.licensing.hooks.useGetCustomerLicenseObjectInfo.hooks.version1.useGetVersion1Translator",
            () => ({
                dataAnalysis: "{{translatedTypeName}} (Data Protection)"
            }));

    return (objectType: Contract.CustomerLicenseObjectType): CustomerLicenceObjectTranslationInfo => {
        switch (objectType) {
            case Contract.CustomerLicenseObjectType.OciFunctionsFunction:
                return {
                    link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(Contract.TypeNames.OciFunctionsFunctionGenericResource),
                    title:
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciFunctionsFunctionGenericResource,
                            { count: 0 })
                };
            case Contract.CustomerLicenseObjectType.AwsDynamoDbAnalyzedTable:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.AwsDynamoDbTable,
                                    { count: 0 })
                        })
                };
            case Contract.CustomerLicenseObjectType.AwsRdsAnalyzedCluster:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.AwsRdsCluster,
                                    { count: 0 })
                        })
                };
            case Contract.CustomerLicenseObjectType.AwsRdsDatabaseAnalyzedInstance:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.AwsRdsDatabaseInstance,
                                    { count: 0 })
                        })
                };
            case Contract.CustomerLicenseObjectType.AwsS3AnalyzedBucket:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.AwsS3Bucket,
                                    { count: 0 })
                        })
                };
            case Contract.CustomerLicenseObjectType.AzureSqlAnalyzedServer:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.AzureSqlServer,
                                    { count: 0 })
                        })
                };
            case Contract.CustomerLicenseObjectType.AzureStorageAnalyzedStorageAccountBlobContainer:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.AzureStorageStorageAccountBlobContainer,
                                    { count: 0 })
                        })
                };
            case Contract.CustomerLicenseObjectType.GcpBigQueryAnalyzedDataset:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpBigQueryDataset,
                                    { count: 0 })
                        })
                };
            case Contract.CustomerLicenseObjectType.GcpSqlAnalyzedInstance:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpSqlInstance,
                                    { count: 0 })
                        })
                };
            case Contract.CustomerLicenseObjectType.GcpStorageAnalyzedBucket:
                return {
                    title:
                        localization.dataAnalysis({
                            translatedTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpStorageBucket,
                                    { count: 0 })
                        })
                };
            default:
                return {
                    link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(objectType),
                    title:
                        entityTypeNameTranslator(
                            objectType,
                            { count: 0 })
                };
        }
    };

}