import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureStorageStorageAccountRiskContext } from "../contexts";

export function useAzureStorageStorageAccountSharedKeyAccessEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureStorageStorageAccountSharedKeyAccessEnabledRisk;
    const storageAccountSharedKeyAccessEnabledRiskModel = riskModel as Contract.AzureStorageStorageAccountSharedKeyAccessEnabledRiskModel;
    const storageAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountModel;

    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureStorageStorageAccountSharedKeyAccessEnabledRiskDefinition",
            () => ({
                contextItems: {
                    listKeysPermissionActionPrincipals: "{{listKeysPermissionActionPrincipals}} have the Microsoft.Storage/storageAccounts/listKeys/action permission"
                },
                description: "{{storageAccount}} allows Shared Key authorization for data access",
                sections: {
                    resolution: {
                        step1:
                            {
                                link: "Prevent authorization with Shared Key",
                                text: "Before disabling Shared Key access, verify it will not break existing applications: {{sharedKeyAccessDocumentationLink}}"
                            },
                        step2: "Under **Allow storage account key access** switch to Disabled",
                        step3: "Click the **Save** button on top to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            storageAccount:
                <Entity
                    entityIdOrModel={storageAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Configuration}/>,
            localization.sections.resolution.step1.text({
                sharedKeyAccessDocumentationLink:
                    <Link
                        urlOrGetUrl={storageAccountSharedKeyAccessEnabledRiskModel.sharedKeyAccessDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel);
            return [
                storageAccountRiskContext.generalInformation,
                storageAccountRiskContext.sensitive,
                storageAccountRiskContext.inboundExternalAccessScope,
                storageAccountRiskContext.childResources,
                !_.isEmpty(risk.listKeysPermissionActionIdentityIds)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.listKeysPermissionActionPrincipals({
                            listKeysPermissionActionPrincipals:
                                <InlineEntities
                                    entityIdsOrModels={risk.listKeysPermissionActionIdentityIds}
                                    entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                                    variant="itemCountAndType"/>
                        }))
                    : undefined,
                storageAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}