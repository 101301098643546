﻿import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, TimeFormatter, useChangeEffect, useLocalization } from "@infrastructure";
import React, { useRef } from "react";
import { Contract, EntitiesCell, Region } from "../../../../../../../../../../../../../../../../common";


type EmrClusterTableProps = {
    clusterModels: Contract.AwsEmrClusterModel[];
    risk: Contract.AwsEc2VolumeEncryptionDisabledRisk;
};

export function EmrClusterTable({ clusterModels, risk }: EmrClusterTableProps) {
    const riskData = risk.data as Contract.AwsEc2VolumeEncryptionDisabledRiskEmrClustersData;
    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [clusterModels]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VolumeEncryptionDisabledRiskDefinition.hooks.useAwsEc2VolumeEncryptionDisabledRiskEmrClustersDefinition.emrClusterTable",
            () => ({
                columns: {
                    cluster: "Cluster",
                    creationTime: "Creation Time",
                    region: "Region",
                    volumes: "EBS volumes"
                }
            }));
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            fetchItems={() => clusterModels}
            getItemId={(item: Contract.AwsEmrClusterModel) => item.id}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={ClusterColumnId.Cluster}
                key={ClusterColumnId.Cluster}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEmrClusterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.id}
                            entityTypeName={Contract.TypeNames.AwsEmrCluster}
                            inlineEntitiesVariant="itemOrItemCountAndType"/>}
                title={localization.columns.cluster()}/>
            <DataTableColumn
                id={ClusterColumnId.Volumes}
                key={ClusterColumnId.Volumes}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEmrClusterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={riskData.emrClusterIdToVolumeIdsMap[item.id]}
                            entityTypeName={Contract.TypeNames.AwsEc2Volume}
                            inlineEntitiesVariant="itemOrItemCountAndType"/>}
                title={localization.columns.volumes()}/>
            <DataTableColumn
                id={ClusterColumnId.Created}
                itemProperty={(item: Contract.AwsEmrClusterModel) => TimeFormatter.monthDayAndYear(item.creationTime)}
                key={ClusterColumnId.Created}
                title={localization.columns.creationTime()}/>
            <DataTableColumn
                id={ClusterColumnId.Region}
                key={ClusterColumnId.Region}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEmrClusterModel>) =>
                        <Region
                            regionId={item.entity.regionId}
                            variant="iconText"/>}
                title={localization.columns.region()}/>,

        </DataTable>);
}

enum ClusterColumnId {
    Cluster = "cluster",
    Created = "created",
    Region = "region",
    Volumes = "volumes"
}