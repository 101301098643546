import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortDirection, EmptyMessageText, map, optionalTableCell, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { AwsContext, useSetAwsContext } from "../..";
import { Contract, scopeSystemEntityModelStore, TenantCell } from "../../../../../../../../../../../../../../common";
import { StatusCell } from "../../../StatusCell";
import { ActionsCell } from "./components";

export function Table() {
    const organizationModels = scopeSystemEntityModelStore.useGetAwsOrganization();
    const setAwsTenantsContext = useSetAwsContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.table",
            () => ({
                actions: {
                    add: "Add AWS Organization"
                },
                columns: {
                    masterTenant: "Management Account",
                    name: "Name",
                    rawId: "ID",
                    roleName: "Role Name",
                    status: "Status",
                    trailName: "Trail Name"
                },
                empty: "No AWS organizations"
            }));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [organizationModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                (filterMap, sort) =>
                    _.orderBy(
                        organizationModels,
                        organizationModel => {
                            const organizationConfiguration = organizationModel.configuration as Contract.AwsOrganizationConfiguration;
                            return map<string, any>(
                                sort?.columnId ?? TableColumnId.Name,
                                {
                                    [TableColumnId.Name]: () => StringHelper.getSortValue(organizationConfiguration.name),
                                    [TableColumnId.RawId]: () => StringHelper.getSortValue(organizationConfiguration.rawId),
                                    [TableColumnId.RoleName]: () => StringHelper.getSortValue(organizationConfiguration.roleName),
                                    [TableColumnId.TrailName]: () => StringHelper.getSortValue(organizationConfiguration.trailNamePattern)
                                });
                        },
                        sort?.direction === DataTableSortDirection.Descending
                            ? "desc"
                            : "asc")}
            getItemId={(item: Contract.ScopeSystemEntityModel) => item.configuration.id}>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.AwsOrganizationConfiguration).name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.RawId}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.AwsOrganizationConfiguration).rawId}
                title={localization.columns.rawId()}/>
            <DataTableColumn
                id={TableColumnId.MasterTenant}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) =>
                        <TenantCell
                            tenantId={(item.configuration as Contract.AwsOrganizationConfiguration).masterTenantId}
                            tenantNameTranslatorOptions={{ includeRawId: true }}
                            variant="iconText"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.masterTenant()}/>
            <DataTableColumn
                id={TableColumnId.RoleName}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.AwsOrganizationConfiguration).roleName}
                title={localization.columns.roleName()}/>
            <DataTableColumn
                id={TableColumnId.TrailName}
                render={optionalTableCell((item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.AwsOrganizationConfiguration).trailNamePattern)}
                title={localization.columns.trailName()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={StatusCell}
                sortOptions={{ enabled: false }}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setAwsTenantsContext(new AwsContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    MasterTenant = "masterTenant",
    Name = "name",
    RawId = "rawId",
    RoleName = "roleName",
    Status = "status",
    TrailName = "trailName"
}