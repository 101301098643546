import { DataTableColumnRenderProps, Link, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, StatusCell as BaseStatusCell } from "../../../../../../../../../common";
import { useProjectModelStatusTranslator } from "../useProjectModelStatusTranslator";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.ProjectModel>) {
    const projectStatusTranslator = useProjectModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.statusCell",
            () => ({
                [Contract.TypeNames.ProjectStateStatus]: {
                    [Contract.ProjectStateStatus.Pending]: "This project will be available after the next time {{link}}",
                    [Contract.ProjectStateStatus.Updating]: "Project is updating, this could take a while",
                    [Contract.ProjectStateStatus.Ready]: "Project is ready"
                },
                linkText: "data is synchronized"
            }));

    return (
        <BaseStatusCell
            statusSeverity={
                item.state.status !== Contract.ProjectStateStatus.Ready
                    ? Contract.Severity.Information
                    : undefined}
            title={projectStatusTranslator(item.state.status)}
            tooltip={
                <Typography>
                    {localization[Contract.TypeNames.ProjectStateStatus][item.state.status]({
                        link:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                                variant="external">
                                {localization.linkText()}
                            </Link>
                    })}
                </Typography>}/>);
}