import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { Events } from "../../../../../../../../../Events";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsS3BucketManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const s3BucketManagementRiskModel = riskModel as Contract.AwsS3BucketManagementRiskModel;
    const bucketModel = entityModelStore.useGet(_.head(s3BucketManagementRiskModel.risk.resourceIds)!);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsS3BucketManagementRiskDefinition",
            () => ({
                violations: {
                    [Contract.TypeNames.AwsS3BucketManagementRiskModelDescriptionType]: {
                        [Contract.AwsS3BucketManagementRiskModelDescriptionType.Acl]: "{{bucket}} ACL was updated",
                        [Contract.AwsS3BucketManagementRiskModelDescriptionType.AclPolicy]: "{{bucket}} policy and ACL were updated",
                        [Contract.AwsS3BucketManagementRiskModelDescriptionType.AclPolicyPublicAccessBlock]: "{{bucket}} ACL, policy and public access block were updated",
                        [Contract.AwsS3BucketManagementRiskModelDescriptionType.AclPublicAccessBlock]: "{{bucket}} ACL and public access block were updated",
                        [Contract.AwsS3BucketManagementRiskModelDescriptionType.Policy]: "{{bucket}} policy was updated",
                        [Contract.AwsS3BucketManagementRiskModelDescriptionType.PolicyPublicAccessBlock]: "{{bucket}} policy and public access block were updated",
                        [Contract.AwsS3BucketManagementRiskModelDescriptionType.PublicAccessBlock]: "{{bucket}} public access block was updated"
                    }
                }
            }));
    const translatedViolations =
        localization.violations[Contract.TypeNames.AwsS3BucketManagementRiskModelDescriptionType][s3BucketManagementRiskModel.descriptionType]({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <Events riskId={s3BucketManagementRiskModel.risk.id}/>);
}