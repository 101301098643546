import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, optionalTableCell, useLocalization, useLocalizeList } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InfoCard, useEntityTypeNameTranslator } from "../../../../../../../../../common";

type AwsEc2SubnetsInfoCardProps = {
    subnetIds: string[];
};

export function AwsEc2SubnetsInfoCard({ subnetIds }: AwsEc2SubnetsInfoCardProps) {
    const subnetModels = entityModelStore.useGet(subnetIds);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.awsEc2SubnetsInfoCard",
            () => ({
                columns: {
                    availabilityZone: "Availability Zone",
                    subnet: "Subnet ID",
                    subnets: "CIDR"
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Subnets"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsEc2Subnet,
                                        { count: 0 })
                            }))
                }}
                fetchItems={() => subnetModels}
                getItemId={(item: Contract.EntityModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={SubnetColumnId.Subnet}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTenant"/>}
                    title={localization.columns.subnet()}/>
                <DataTableColumn
                    id={SubnetColumnId.Subents}
                    render={optionalTableCell<Contract.EntityModel>(item => localizeList((item.entity as Contract.AwsEc2Subnet).subnets))}
                    title={localization.columns.subnets()}/>
                <DataTableColumn
                    id={SubnetColumnId.AvailabilityZone}
                    itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.AwsEc2Subnet).availabilityZone.name}
                    title={localization.columns.availabilityZone()}/>
            </DataTable>
        </InfoCard>);
}

enum SubnetColumnId {
    AvailabilityZone = "availabilityZone",
    Subents = "subnets",
    Subnet = "subnet"
}