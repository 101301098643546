import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureMySqlServerAuthenticationTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureMySqlServerAuthenticationTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureMySqlServerAuthenticationType]: {
                    [Contract.AzureMySqlServerAuthenticationType.Aad]: "Microsoft Entra ID",
                    [Contract.AzureMySqlServerAuthenticationType.AadAndMySql]: "MySQL and Microsoft Entra ID",
                    [Contract.AzureMySqlServerAuthenticationType.MySql]: "MySQL"
                }
            }));

    return (status: Contract.AzureMySqlServerAuthenticationType) =>
        localization[Contract.TypeNames.AzureMySqlServerAuthenticationType][status]();
}