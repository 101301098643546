import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../controllers";

export function useAzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperatorTranslator() {
    const localization =
        useLocalization(
            "common.customRiskPolicy.azurePrincipalNotAllowedResourceRoleDefinitionRiskPolicy.edit.hooks.useAzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperatorTranslator",
            () => ({
                [Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperator.Overlap]: "Any",
                [Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperator.Subset]: "All"
            }));
    return (type: Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperator) => localization[type]();
}