import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";

export function Empty() {
    const localization =
        useLocalization(
            "views.customer.code.scans.empty",
            () => ({
                title: "No scan results"
            }));

    return (
        <Stack
            spacing={2}
            sx={{
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                textAlign: "center",
                width: "100%"
            }}>
            <Typography variant="h2">
                {localization.title()}
            </Typography>
        </Stack>);
}