﻿import { DataTableColumnRenderProps, NotValidIcon, SuccessIcon, Tooltip } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../common";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex"
            }}>
            {!((item.state as Contract.QRadarServerState)?.failed)
                ? <SuccessIcon
                    sx={{
                        color: theme.palette.success.main,
                        fontSize: "18px"
                    }}/>
                : <Tooltip titleOrGetTitle={(item.state as Contract.QRadarServerState)?.errorMessage}>
                    <NotValidIcon
                        sx={{
                            color: theme.palette.error.main,
                            fontSize: "18px"
                        }}/>
                </Tooltip>}
        </Box>);
}