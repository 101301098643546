﻿import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGetGcpBigQueryDatasetRiskContext, useGetGcpScopeResourcePublicAccessExistsRiskContext } from "../../../../..";
import { Contract, Entity, EntityController, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpBigQueryDatasetPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpBigQueryDatasetPublicAccessExistsRisk;
    const datasetModel = entityModelStore.useGet(risk.entityId) as Contract.GcpBigQueryDatasetModel;

    const getGcpBigQueryDatasetRiskContext = useGetGcpBigQueryDatasetRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();
    const [{ tableIds }] =
        useExecuteOperation(
            [useGcpBigQueryDatasetPublicAccessExistsRiskDefinition, datasetModel.id],
            () => EntityController.getGcpBigQueryDatasetTableIds(new Contract.EntityControllerGetGcpBigQueryDatasetTableIdsRequest(datasetModel.id)));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpBigQueryDatasetPublicAccessExistsRiskDefinition",
            () => ({
                contextItems: {
                    tables: "The {{translatedDatasetTypeName}} exposes {{tables}} to the public"
                },
                description: "{{dataset}} is publicly accessible via IAM roles",
                sections: {
                    resolution: {
                        step1: "Click the **SHARING** button and choose **Permissions**",
                        step2: "Before changing the permissions, verify that applications can access the dataset without public access",
                        step3: "Delete the role bindings to allUsers / allAuthenticatedUsers"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            dataset:
                <Entity
                    entityIdOrModel={datasetModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const datasetRiskContext = getGcpBigQueryDatasetRiskContext(datasetModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(risk);
            return [
                datasetRiskContext.generalInformation,
                datasetRiskContext.sensitive,
                !_.isEmpty(tableIds)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.tables({
                            tables:
                                <InlineEntities
                                    entityIdsOrModels={tableIds}
                                    entityTypeName={Contract.TypeNames.GcpBigQueryDatasetTable}
                                    variant="itemCountAndType"/>,
                            translatedDatasetTypeName: entityTypeNameTranslator(
                                datasetModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                        }))
                    : undefined,
                datasetRiskContext.encryption,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                datasetRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ScopeResourceRoleBindingTableSection
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}