import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { PolicySection, PublicS3BucketAccessControlListSection } from "../../components";
import { useGetAwsS3BucketRiskContext } from "../contexts";


export function useAwsS3BucketPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsS3BucketPublicAccessExistsRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.AwsS3BucketModel;
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;

    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsS3BucketPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{bucket}} is publicly accessible via bucket policy or Access Control List (ACL)",
                sections: {
                    accessControlList: "ACL",
                    policyDocument: "Policy",
                    resolution: {
                        step1: "Since the bucket has public access, block public access might break identities access to the bucket. Verify that only identities from the same account access the bucket before applying the change",
                        step2: "Check **Block all public access** and click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.S3,
                AwsConsoleUrlBuilder.getS3BucketEditBlockPublicAccessUrl(bucket)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.storageSize,
                bucketRiskContext.encryption,
                bucketRiskContext.policyDocumentPublicAccessHighSeveritySensitivePermissionActions,
                bucketRiskContext.accessControlListPublicAccessHighSeveritySensitivePermissionActions,
                bucketRiskContext.sensitive,
                bucketRiskContext.accessLevel,
                bucketRiskContext.highestDataSensitivity,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSectionGroup(
                    [
                        new RiskDefinitionSection(
                            <PublicS3BucketAccessControlListSection accessControlList={bucket.accessControlList}/>,
                            localization.sections.accessControlList()),
                        new RiskDefinitionSection(
                            <PolicySection policyDocument={bucket.policyDocument}/>,
                            localization.sections.policyDocument(),
                            {
                                expandable: true
                            })
                    ])
            ]
        });
}