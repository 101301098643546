﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetResolutionSteps } from ".";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEc2InstanceRiskContext } from "../../../../..";
import { Contract, Entity, entityModelStore, RegionModelHelper } from "../../../../../../../../../../../../../../common";

export function useAwsEc2InstanceMetadataServiceVersionRiskInstanceDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel) {
    const instanceModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEc2InstanceModel;
    const instance = instanceModel.entity as Contract.AwsEc2Instance;

    const getAwsEc2InstanceRiskContext = useGetAwsEc2InstanceRiskContext();
    const getResolutionSteps = useGetResolutionSteps();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskInstanceDefinition",
            () => ({
                description: "{{instance}} is not enforcing Instance Metadata Service Version 2 (IMDSv2)",
                sections: {
                    resolution: "Run the following command: aws ec2 modify-instance-metadata-options --instance-id {{instanceRawId}} --http-token required --http-endpoint enabled --region {{instanceRegion}}"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            instanceModel.unknown
                ? []
                : [...getResolutionSteps(
                    instance.typeName,
                    riskModel),
                localization.sections.resolution({
                    instanceRawId: instance.rawId,
                    instanceRegion: RegionModelHelper.get(instance.regionId!).systemName
                })
                ],
        riskModel,
        () => {
            const instanceContextItems = getAwsEc2InstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.status,
                instanceContextItems.instanceRoleNotExists,
                instanceContextItems.metadataServiceVersionV1Used,
                instanceContextItems.getPermissionActionsContextItem(riskModel.risk.instanceIdToDataMap[instance.id]?.highSeverityRolePermissionActions),
                instanceContextItems.getInboundExternalAccessContextItem(
                    riskModel.risk.instanceIdToDataMap[instance.id]?.destinationNetworkScope,
                    riskModel.risk.instanceIdToDataMap[instance.id]?.securityGroupIds),
                instanceContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}