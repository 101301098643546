import { NumberFormatter } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Cell, Label, Pie, PieChart } from "recharts";
import { useTheme } from "..";

type RatioGaugeChartProps = {
    color?: string;
    count: number;
    labelFontSize: number;
    thickness: number;
    total: number;
    width: number;
};

export function RatioGaugeChart({ color, count, labelFontSize, thickness, total, width }: RatioGaugeChartProps) {
    const radius = width / 2;
    const ratio =
        total > 0
            ? count / total
            : 0;

    const data =
        _<{ value: number }>([]).
            concat({ value: ratio }).
            concatIf(
                ratio < 1,
                () => ({ value: 1 - ratio })).
            value();

    const theme = useTheme();
    return (
        <PieChart
            className="ratioGaugeChart"
            height={radius}
            style={{ shrink: 0 }}
            width={radius * 2}>
            <Pie
                cy={radius}
                data={data}
                dataKey="value"
                endAngle={0}
                innerRadius={radius - thickness}
                isAnimationActive={false}
                outerRadius={radius}
                paddingAngle={0}
                startAngle={180}>
                <Cell
                    fill={color ?? theme.palette.primary.main}
                    stroke="none"/>
                <Cell
                    fill={theme.palette.chart.grey}
                    stroke="none"/>
                <Label
                    dy={-8}
                    fill={theme.palette.text.primary}
                    fontSize={labelFontSize}
                    fontWeight={500}
                    position="centerBottom"
                    value={NumberFormatter.percentage(ratio)}/>
            </Pie>
        </PieChart>);
}