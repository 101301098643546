import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, TypeHelper } from "../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../utilities";

export function useGetNetworkAccessResourcesRiskContext() {
    return useMemo(
        () => useNetworkAccessResourcesRiskContext,
        []);
}

function useNetworkAccessResourcesRiskContext(entityModels: Contract.EntityModel[]) {
    const entityNetworks =
        _.map(
            entityModels,
            entityModel => entityModel.entityNetwork as Optional<Contract.NetworkAccessResourceStateNetwork>);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetNetworkAccessResourcesRiskContext",
            () => ({
                inboundExternalAccessScope: {
                    [Contract.TypeNames.NetworkAccessScope]: {
                        [Contract.NetworkAccessScope.All]: [
                            "{{entities}} is accessible from any IP address",
                            "{{entities}} are accessible from any IP address"
                        ],
                        [Contract.NetworkAccessScope.None]: [
                            "{{entities}} is not exposed to the internet",
                            "{{entities}} are not exposed to the internet"
                        ],
                        [Contract.NetworkAccessScope.Restricted]: [
                            "{{entities}} is accessible from specific IP addresses",
                            "{{entities}} are accessible from specific IP addresses"
                        ],
                        [Contract.NetworkAccessScope.Wide]: [
                            "{{entities}} is accessible from a wide range of public IP addresses",
                            "{{entities}} are accessible from a wide range of public IP addresses"
                        ]
                    }
                }
            }));

    const inboundExternalAccessScopeToEntityNetworksMap =
        _(entityNetworks).
            filter().
            groupBy(entityNetwork => entityNetwork!.inboundExternalAccessScope).
            value();

    const accessScope =
        _(inboundExternalAccessScopeToEntityNetworksMap).
            keys().
            maxBy(inboundExternalAccessScope => TypeHelper.getEnumValue(Contract.TypeNames.NetworkAccessScope, inboundExternalAccessScope)) as Optional<Contract.NetworkAccessScope>;

    return {
        inboundExternalAccessScope:
            _.isNil(accessScope)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.inboundExternalAccessScope[Contract.TypeNames.NetworkAccessScope][accessScope](
                        _.size(inboundExternalAccessScopeToEntityNetworksMap[accessScope]),
                        {
                            entities:
                            <InlineEntities
                                entityIdsOrModels={
                                    _.map(
                                        inboundExternalAccessScopeToEntityNetworksMap[accessScope],
                                        entityNetwork => entityNetwork!.entityReference.id)}
                                entityTypeName={Contract.TypeNames.IVirtualMachine}
                                variant="itemCountAndType"/>
                        }))
    };
}