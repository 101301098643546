﻿import { EmptyMessageText, InlineItems, SearchList } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../common";

type InlineItemsCellProps = {
    emptyMessageText?: EmptyMessageText;
    getItemDisplayName?: (item: any) => string;
    getPopoverItems: () => Promise<any[]>;
    itemCount: number;
    items: any[];
    renderItem?: (item: any) => ReactNode;
};

export function InlineItemsCell({ emptyMessageText, getItemDisplayName, getPopoverItems, itemCount, items, renderItem }: InlineItemsCellProps) {
    const theme = useTheme();
    return (
        <InlineItems
            deferredItems={{
                count: itemCount,
                firstItem: _.first(items),
                getItems: getPopoverItems
            }}
            itemsPopover={
                items =>
                    <SearchList
                        disablePadding={true}
                        emptyMessageText={emptyMessageText}
                        itemOptions={{
                            getSortValue: getItemDisplayName,
                            render: renderItem,
                            spacing: 1
                        }}
                        items={items}
                        searchOptions={{ enabled: true }}
                        sx={{
                            maxHeight: theme.spacing(50),
                            minWidth: theme.spacing(20),
                            overflow: "hidden auto",
                            padding: theme.spacing(0, 1.5)
                        }}
                        variant="dropdown"/>
            }
            variant="itemPlusItemCount">
            {renderItem}
        </InlineItems>);
}