import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortDirection, EmptyMessageText, Link, map, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { GitLabServerContext, useSetGitLabServerContext } from "../..";
import { Contract, DnsEndpointHelper, scopeSystemEntityModelStore, StatusCell } from "../../../../../../../../../../../../../../common";
import { useCodeServerModelStatusTranslator } from "../../../../hooks";
import { ActionsCell } from "./components";

export function Table() {
    const serverModels = scopeSystemEntityModelStore.useGetCodeServer(Contract.CodeTenantType.GitLab);
    const setGitLabServerContext = useSetGitLabServerContext();

    const codeServerModelStatusTranslator = useCodeServerModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.gitLabServer.table",
            () => ({
                actions: {
                    add: "Add GitLab Self-Managed Server"
                },
                columns: {
                    name: "Name",
                    status: "Status",
                    url: "URL"
                },
                empty: "No GitLab Self-Managed servers"
            }));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [serverModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                (_filterMap, sort) =>
                    _.orderBy(
                        serverModels,
                        serverModel =>
                            map<string, any>(
                                sort?.columnId ?? TableColumnId.Name,
                                {
                                    [TableColumnId.Name]: () => StringHelper.getSortValue(serverModel.configuration.name),
                                    [TableColumnId.Status]: () => StringHelper.getSortValue(codeServerModelStatusTranslator(serverModel.status))
                                }),
                        sort?.direction === DataTableSortDirection.Descending
                            ? "desc"
                            : "asc")}
            getItemId={(item: Contract.CodeServerModel) => item.configuration.id}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setGitLabServerContext(new GitLabServerContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.CodeServerModel) => item.configuration.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.Url}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeServerModel>) =>
                        <Link
                            urlOrGetUrl={`https://${DnsEndpointHelper.getHttpsEndpointString(item.state!.endpoint!)}`}
                            variant="external"
                            onClick={event => event.stopPropagation()}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.url()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeServerModel>) =>
                        <StatusCell
                            statusSeverity={item.statusSeverity}
                            title={codeServerModelStatusTranslator(item.status)}/>}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    Status = "status",
    Url = "url"
}