import { useTheme } from "@mui/material";
import React from "react";
import { LinkIcon } from "../../../assets";
import { useLocalization } from "../../../hooks";
import { ActionButton } from "../../ActionButton";
import { CopyToClipboard } from "../CopyToClipboard";

type CopyLinkButtonProps = {
    getLink: () => string;
};

export function CopyToClipboardLinkButton({ getLink }: CopyLinkButtonProps) {
    const localization =
        useLocalization(
            "infrastructure.copyToClipboard.copyToClipboardLinkButton",
            () => ({
                title: "Link"
            }));

    const theme = useTheme();
    return (
        <CopyToClipboard
            getValue={getLink}
            placement="bottom"
            title={localization.title()}>
            <ActionButton
                sx={{
                    "&:hover": {
                        textDecoration: "none"
                    },
                    height: theme.spacing(2.25),
                    minHeight: "unset",
                    padding: 0,
                    width: theme.spacing(2.25)
                }}>
                <LinkIcon sx={{ fontSize: "18px" }}/>
            </ActionButton>
        </CopyToClipboard>);
}