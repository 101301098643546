import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { Info } from "../../../../../components";
import { GciDirectoryPrincipalGcpRoleBindings, GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { Providers } from "./components";

export function useGcpIamWorkloadIdentityPoolDefinition(resourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(resourceModel);

    const workloadIdentityPoolModel = resourceModel as Contract.GcpIamWorkloadIdentityPoolModel;
    const workloadIdentityPool = workloadIdentityPoolModel.entity as Contract.GcpIamWorkloadIdentityPool;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamWorkloadIdentityPoolDefinition",
            () => ({
                info: {
                    items: {
                        enabled: {
                            false: "Disabled",
                            title: "Status",
                            true: "Enabled"
                        },
                        rawShortId: "ID"
                    }
                },
                tabs: {
                    principalRoleBindings: "Bindings",
                    providers: "Providers ({{providerCount | NumberFormatter.humanize}})",
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isEmpty(workloadIdentityPoolModel.workloadIdentityPoolProviderIds),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <Providers providerIds={workloadIdentityPoolModel.workloadIdentityPoolProviderIds}/>,
                        localization.tabs.providers({ providerCount: workloadIdentityPoolModel.workloadIdentityPoolProviderIds.length }),
                        "providers")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GciDirectoryPrincipalGcpRoleBindings
                            csvExportFilePrefix={localization.tabs.principalRoleBindings()}
                            gcpRoleBindingIds={workloadIdentityPoolModel.identityRoleBindingIds}
                            principalId={workloadIdentityPoolModel.id}/>,
                        localization.tabs.principalRoleBindings(),
                        "principalRoleBindings")
                ).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GcpScopeResourceRoleBindings
                            csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                            scopeResourceModel={resourceModel}/>,
                        localization.tabs.resourceRoleBindings(),
                        "resourceRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawShortId"
                        title={localization.info.items.rawShortId()}
                        value={workloadIdentityPoolModel.rawShortId}/>,
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="enabled"
                        title={localization.info.items.enabled.title()}
                        value={
                            workloadIdentityPool.enabled
                                ? localization.info.items.enabled.true()
                                : localization.info.items.enabled.false()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}