import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { usePingIdentityClientRegionTranslator } from "../../../../../../../../../tenants";

export function usePingIdentityTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const clientRegionTranslator = usePingIdentityClientRegionTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.usePingIdentityTenantAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                tenantClientId: "Client Id",
                tenantClientSecret: "Client Secret",
                tenantName: "Environment Name",
                tenantRawId: "Environment ID",
                tenantRegion: "Region"
            }));

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.PingIdentityTenantAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantRawId(),
                    tenantAuditEvent.tenantRawId),
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName),
                new AuditEventDefinitionDetailItem(
                    localization.tenantRegion(),
                    clientRegionTranslator(tenantAuditEvent.tenantRegion))).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.PingIdentityTenantUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (tenantAuditEvent as Contract.PingIdentityTenantUpdateAuditEvent).tenantClientIdChanged
                                ? localization.tenantClientId()
                                : undefined,
                            (tenantAuditEvent as Contract.PingIdentityTenantUpdateAuditEvent).tenantClientSecretChanged
                                ? localization.tenantClientSecret()
                                : undefined,
                            (tenantAuditEvent as Contract.PingIdentityTenantUpdateAuditEvent).tenantNameChanged
                                ? localization.tenantName()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}