import { InlineItems, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsTenantEntityRiskContext } from "../contexts";

export function useAwsSecurityHubRegionHubDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSecurityHubRegionHubDisabledRisk;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AwsTenantEntityModel;

    const getAwsTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSecurityHubRegionHubDisabledRiskDefinition",
            () => ({
                contextItems: {
                    regionSystemNames: "The AWS security hub is not enabled on **{{regionSystemNames}}**"
                },
                description: "{{tenantEntity}} security hub service is not enabled for all regions",
                regionSystemNames: [
                    "1 active region",
                    "{{count | NumberFormatter.humanize}} active regions"
                ],
                sections: {
                    resolution: {
                        step1: {
                            step1: "Select the region you want to focus on in the top right of the console",
                            step2: "Click **Go to security hub** and follow the online instructions",
                            title: "Repeat for each of the **{{regionSystemNames}}**"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.SecurityHub,
                AwsConsoleUrlBuilder.getSecurityHubUrl(tenantConfiguration.partitionType)),
            new Step(
                localization.sections.resolution.step1.title({
                    regionSystemNames:
                        <InlineItems
                            items={risk.regionSystemNames}
                            namePluralizer={localization.regionSystemNames}
                            variant="itemCountAndType"/>
                }),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getAwsTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                new RiskDefinitionContextItem(
                    localization.contextItems.regionSystemNames({
                        regionSystemNames:
                            <InlineItems
                                items={risk.regionSystemNames}
                                namePluralizer={localization.regionSystemNames}
                                variant="itemCountAndType"/>
                    })),
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}