import { map, useLocalization } from "@infrastructure";
import { Contract, useEntityTypeNameTranslator } from "../../../common";

export class AwsResourcePermitterType {
    constructor(
        public resourcePermitterType: Contract.AwsResourcePermitterType,
        public resourceTypeName?: string) {
    }

    public getResourceTypeName() {
        return map(
            this.resourcePermitterType,
            {
                [Contract.AwsResourcePermitterType.Ec2ImagePermissions]: () => Contract.TypeNames.AwsEc2Image,
                [Contract.AwsResourcePermitterType.Ec2SnapshotPermissions]: () => Contract.TypeNames.AwsEc2Snapshot,
                [Contract.AwsResourcePermitterType.KmsKeyGrantList]: () => Contract.TypeNames.AwsKmsKey,
                [Contract.AwsResourcePermitterType.ResourcePolicy]: () => this.resourceTypeName,
                [Contract.AwsResourcePermitterType.RdsSnapshotPermissions]: () => Contract.TypeNames.AwsRdsSnapshot,
                [Contract.AwsResourcePermitterType.S3BucketAccessControlList]: () => Contract.TypeNames.AwsS3Bucket
            });
    }
}

export function useAwsResourcePermitterTranslator() {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsResourcePermitterTranslator",
            () => ({
                [Contract.TypeNames.AwsResourcePermitterType]: {
                    [Contract.AwsResourcePermitterType.Ec2ImagePermissions]: "{{translatedEntityTypeName}} Share Permissions",
                    [Contract.AwsResourcePermitterType.Ec2SnapshotPermissions]: "{{translatedEntityTypeName}} Share Permissions",
                    [Contract.AwsResourcePermitterType.KmsKeyGrantList]: "{{translatedEntityTypeName}} Grant",
                    [Contract.AwsResourcePermitterType.ResourcePolicy]: "{{translatedEntityTypeName}} Policy",
                    [Contract.AwsResourcePermitterType.RdsSnapshotPermissions]: "{{translatedEntityTypeName}} Visibility",
                    [Contract.AwsResourcePermitterType.S3BucketAccessControlList]: "{{translatedEntityTypeName}} ACL"
                }
            }));
    return (resourceTypePermitterType: AwsResourcePermitterType) =>
        localization[Contract.TypeNames.AwsResourcePermitterType][resourceTypePermitterType.resourcePermitterType]({
            translatedEntityTypeName:
                entityTypeNameTranslator(
                    resourceTypePermitterType.getResourceTypeName() ??
                    Contract.TypeNames.AwsResource)
        });
}