import { Stack } from "@mui/material";
import React from "react";
import { useEntityProfileDefinition } from "../../..";
import { entityModelStore, useTheme } from "../../../../../../../common";
import { EntityProfileInfoLayout } from "../components";

type EntityProfileInfoProps = {
    entityId: string;
    entityInfoData?: any;
};

export function EntityProfileInfo({ entityId, entityInfoData }: EntityProfileInfoProps) {
    const entityModel = entityModelStore.useGet(entityId);
    const entityProfileDefinition =
        useEntityProfileDefinition(
            entityModel,
            {
                infoOptions: {
                    data: entityInfoData
                }
            });
    const theme = useTheme();
    return (
        <EntityProfileInfoLayout entityId={entityId}>
            <Stack sx={{ padding: theme.spacing(2) }}>
                {entityProfileDefinition.options.infoElement}
            </Stack>
        </EntityProfileInfoLayout>);
}