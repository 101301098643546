import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function QualysIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M12.0777 0C18.6425 0 21.9413 3.51159 21.9413 3.51159C21.9413 3.51159 22.3178 6.10641 22.0804 11.3452C21.7203 19.2524 12.0859 23.9918 12.0859 24C12.0859 24.0082 2.44332 19.2442 2.08315 11.3452C1.83759 6.10641 2.21412 3.51159 2.21412 3.51159C2.21412 3.51159 5.51289 0 12.0777 0Z"
                fill="#ED2E26"/>
            <path
                d="M7.15 10.3874C7.15 7.59618 9.36009 5.3206 12.0777 5.3206C14.7953 5.3206 17.0054 7.53069 17.0054 10.2483C17.0054 12.4175 15.1391 14.2019 13.7066 15.1187C14.2632 15.4379 15.4911 15.7735 16.6698 15.8799C18.4133 14.5293 19.4365 12.4502 19.4365 10.2483C19.4529 6.18008 16.1623 2.87313 12.0941 2.85675C8.02585 2.84038 4.7189 6.13097 4.70253 10.1992C4.70253 10.2155 4.70253 10.2319 4.70253 10.2483C4.70253 12.704 5.72572 14.8813 7.6575 16.5512C9.46651 18.1146 12.1022 19.1542 15.1145 19.6453C15.7694 19.2851 16.8826 18.221 17.3001 17.5989C14.7871 17.2797 12.4133 16.6658 10.8171 15.8308C8.38602 14.5539 7.15 12.7203 7.15 10.3874Z"
                fill="white"/>
        </SvgIcon>);
}