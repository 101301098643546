﻿import { DataTableColumn, DataTableSortType, DeferredFilter, EnumValuesFilter, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsAcmCertificateKeyTypeTranslator } from "../../../../../../../../../../tenants";
import { useAwsAcmCertificateStatusTranslator, useAwsAcmCertificateTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsAcmCertificateDefinition(definitionData: DefinitionData) {
    const certificateModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsAcmCertificateModelFilters>;
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const acmCertificateKeyTypeTranslator = useAwsAcmCertificateKeyTypeTranslator();
    const acmCertificateStatusTranslator = useAwsAcmCertificateStatusTranslator();
    const acmCertificateTypeTranslator = useAwsAcmCertificateTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsAcmCertificateDefinition",
            () => ({
                columns: {
                    attachedResources: "Attached Resources",
                    domainName: "Domain Name",
                    expirationTime: "Expiration Time",
                    keyType: "Key Algorithm",
                    status: "Status",
                    type: "Certificate Type"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsAcmCertificateModel) => ({
                                [localization.columns.type()]: acmCertificateTypeTranslator((item.entity as Contract.AwsAcmCertificate).type)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsAcmCertificateType}
                                    enumTypeTranslator={(type: Contract.AwsAcmCertificateType) => acmCertificateTypeTranslator(type)}
                                    placeholder={localization.columns.type()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsAcmCertificateType}
                    itemProperty={(item: Contract.AwsAcmCertificateModel) => acmCertificateTypeTranslator((item.entity as Contract.AwsAcmCertificate).type)}
                    key={Contract.EntityModelProperty.AwsAcmCertificateType}
                    title={localization.columns.type()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsAcmCertificateModel) => ({
                                [localization.columns.keyType()]: acmCertificateKeyTypeTranslator((item.entity as Contract.AwsAcmCertificate).keyType)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsAcmCertificateKeyType}
                                    enumTypeTranslator={acmCertificateKeyTypeTranslator}
                                    placeholder={localization.columns.keyType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsAcmCertificateKeyType}
                    itemProperty={(item: Contract.AwsAcmCertificateModel) => acmCertificateKeyTypeTranslator((item.entity as Contract.AwsAcmCertificate).keyType)}
                    key={Contract.EntityModelProperty.AwsAcmCertificateKeyType}
                    title={localization.columns.keyType()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsAcmCertificateModel) => ({
                                [localization.columns.status()]: acmCertificateStatusTranslator((item.entity as Contract.AwsAcmCertificate).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsAcmCertificateStatus}
                                    enumTypeTranslator={(status: Contract.AwsAcmCertificateStatus) => acmCertificateStatusTranslator(status)}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsAcmCertificateStatus}
                    itemProperty={(item: Contract.AwsAcmCertificateModel) => acmCertificateStatusTranslator((item.entity as Contract.AwsAcmCertificate).status)}
                    key={Contract.EntityModelProperty.AwsAcmCertificateStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsAcmCertificateModel) => ({
                                [localization.columns.expirationTime()]:
                                    _.isNil((item.entity as Contract.AwsAcmCertificate).expirationTime)
                                        ? ""
                                        : TimeFormatter.iso8601String((item.entity as Contract.AwsAcmCertificate).expirationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={certificateModelFiltersPromise}
                                    title={localization.columns.expirationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.expirationTimeRange.emptyValue}
                                            placeholder={localization.columns.expirationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.expirationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsAcmCertificateExpirationTime}
                    key={Contract.EntityModelProperty.AwsAcmCertificateExpirationTime}
                    render={
                        optionalTableCell<Contract.AwsAcmCertificateModel>(
                            item =>
                                _.isNil((item.entity as Contract.AwsAcmCertificate).expirationTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear((item.entity as Contract.AwsAcmCertificate).expirationTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.expirationTime()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsAcmCertificateModel) => ({
                                [localization.columns.domainName()]: (item.entity as Contract.AwsAcmCertificate).domainName
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsAcmCertificateDomainName)}
                                    placeholder={localization.columns.domainName()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsAcmCertificateDomainName}
                    key={Contract.EntityModelProperty.AwsAcmCertificateDomainName}
                    render={
                        optionalTableCell<Contract.AwsAcmCertificateModel>(
                            item => (item.entity as Contract.AwsAcmCertificate).domainName)}
                    title={localization.columns.domainName()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsAcmCertificateModel) => ({
                                [localization.columns.attachedResources()]:
                                    _.join(
                                        item.attachedResourceIdReferences,
                                        "\n")
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsAcmCertificateAttachedResources)}
                                    placeholder={localization.columns.attachedResources()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsAcmCertificateAttachedResources}
                    key={Contract.EntityModelProperty.AwsAcmCertificateAttachedResources}
                    render={
                        optionalTableCell<Contract.AwsAcmCertificateModel>(
                            item =>
                                _.isEmpty(item.attachedResourceIdReferences)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={item.attachedResourceIdReferences}
                                        entityTypeName={Contract.TypeNames.AwsResource}
                                        entityVariant="iconText"/>
                        )}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.attachedResources()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsAcmCertificateRequest(
                new Contract.EntityControllerGetEntityModelPageAwsAcmCertificateRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAcmCertificateAttachedResourceExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAcmCertificateAttachedResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAcmCertificateDomainName]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsAcmCertificateExpirationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAcmCertificateKeyType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAcmCertificateStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAcmCertificateType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}