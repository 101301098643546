import { EmptyMessage, MemoItemRenderer, SearchListItemsProps, useSearchListContext } from "@infrastructure";
import { List, ListItem, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";

export function Items({ dependencies, emptyMessageText, itemOptions, sx }: SearchListItemsProps) {
    const { filtered, filteredItems } = useSearchListContext();
    const theme = useTheme();
    const items =
        useMemo(
            () =>
                _.map(
                    filteredItems,
                    (filteredItem, filteredItemIndex) =>
                        <ListItem
                            disableGutters={true}
                            key={filteredItemIndex}
                            sx={{
                                paddingBottom: theme.spacing(itemOptions?.spacing ?? 0),
                                paddingTop: theme.spacing(itemOptions?.spacing ?? 0)
                            }}>
                            {_.isNil(itemOptions?.render)
                                ? filteredItem
                                : <MemoItemRenderer
                                    getDependencies={itemOptions?.getDependencies}
                                    render={itemOptions!.render}
                                    renderArguments={[filteredItem]}/>}
                        </ListItem>),
            [filteredItems, ...dependencies]);

    return items.length === 0
        ? emptyMessageText
            ? <EmptyMessage
                message={emptyMessageText.getText(filtered)}
                verticalCenter={true}/>
            : null
        : <List
            dense={true}
            disablePadding={true}
            sx={sx}>
            {items}
        </List>;
}