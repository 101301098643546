import { Steps, useLocalization, useLocalizeList } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { RiskContentProps } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, InlineGroupIdentities } from "../../../../../../../../../../../../common";
import { useAzureCommonAccessPrincipalRiskDefinition } from "./useAzureCommonAccessPrincipalRiskDefinition";

export function useAzureSeverePermissionPrincipalRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureSeverePermissionPrincipalRisk;
    const principalModel = entityModelStore.useGet(risk.entityId);
    const principal = principalModel.entity as Contract.AadDirectoryPrincipal;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureSeverePermissionPrincipalRiskDefinition",
            () => ({
                description: {
                    originatorResourceIdsPart: ", linked to {{originatorResourceIds}},",
                    [Contract.TypeNames.AadDirectoryGroup]: {
                        [Contract.TypeNames.PrincipalSeverePermissionActionRiskType]: {
                            [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: [
                                "{{groupIdentities}} was recently granted admin privileges on {{tenantEntity}} through {{principal}}",
                                "{{groupIdentities}} were recently granted admin privileges on {{tenantEntity}} through {{principal}}"
                            ],
                            [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: [
                                "{{groupIdentities}} was recently granted privileges that allow it to escalate to an admin on {{tenantEntity}} through {{principal}}",
                                "{{groupIdentities}} were recently granted privileges that allow them to escalate to admin on {{tenantEntity}} through {{principal}}"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryIdentity]: {
                        [Contract.TypeNames.PrincipalSeverePermissionActionRiskType]: {
                            [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "{{principal}}{{originatorResourceIdsPart}} was recently granted admin privileges on {{tenantEntity}}",
                            [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "{{principal}}{{originatorResourceIdsPart}} was recently granted privileges that allow it to escalate to an admin on {{tenantEntity}}"
                        }
                    }
                }
            }));

    return useAzureCommonAccessPrincipalRiskDefinition(
        Contract.EntityAccessScope.Full,
        <ContextSection riskModel={riskModel}/>,
        principal.typeName === Contract.TypeNames.AadDirectoryGroup
            ? localization.description[Contract.TypeNames.AadDirectoryGroup][Contract.TypeNames.PrincipalSeverePermissionActionRiskType][risk.type](
                risk.groupIdentityIds.length,
                {
                    groupIdentities:
                        <InlineEntities
                            entityIdsOrModels={risk.groupIdentityIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                            entityVariant="iconTextTypeTenant"
                            variant="itemCountAndType"/>,
                    principal:
                        <Entity
                            entityIdOrModel={principalModel}
                            variant="typeText"/>,
                    tenantEntity:
                        <Entity
                            entityIdOrModel={risk.tenantId}
                            variant="typeText"/>
                })
            : localization.description[Contract.TypeNames.AadDirectoryIdentity][Contract.TypeNames.PrincipalSeverePermissionActionRiskType][risk.type](
                {
                    originatorResourceIdsPart:
                        _.isEmpty(risk.originatorResourceIds)
                            ? ""
                            : <Typography component="span">
                                {localization.description.originatorResourceIdsPart({
                                    originatorResourceIds:
                                        <InlineEntities
                                            entityIdsOrModels={risk.originatorResourceIds}
                                            entityTypeName={Contract.TypeNames.AzureResource}
                                            entityVariant="iconTextTypeTenant"
                                            variant="itemAndTypeOrItemCountAndType"/>
                                })}
                            </Typography>,
                    principal:
                        <Entity
                            entityIdOrModel={principalModel}
                            entityTypeNameTranslatorOptions={{ variant: "title" }}
                            variant="typeText"/>,
                    tenantEntity:
                        <Entity
                            entityIdOrModel={risk.tenantId}
                            variant="typeText"/>
                }),
        riskModel);
}

function ContextSection({ riskModel }: RiskContentProps) {
    const severePermissionPrincipalRiskModel = riskModel as Contract.AzureSeverePermissionPrincipalRiskModel;
    const principalModel = entityModelStore.useGet(severePermissionPrincipalRiskModel.risk.entityId);
    const principal = principalModel.entity as Contract.AadDirectoryPrincipal;
    const managedIdentityModel =
        entityModelStore.useGet(
            principalModel.typeName === Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipalModel
                ? (principalModel as Contract.AadDirectoryManagedIdentityServicePrincipalModel).managedIdentityId
                : undefined) as Contract.AzureManagedIdentityManagedIdentityModel;
    const severePermissionActionPermittingEntityModels = entityModelStore.useGet(severePermissionPrincipalRiskModel.risk.severePermissionActionPermittingEntityIds);

    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureSeverePermissionPrincipalRiskDefinition.contextSection",
            () => ({
                userClassicAdministrator: "classic administrator",
                [Contract.TypeNames.AzureSeverePermissionPrincipalRiskModelInfo]: {
                    [Contract.AzureSeverePermissionPrincipalRiskModelInfo.GroupIdentities]: "{{principal}} has {{groupIdentityIds}}",
                    [Contract.AzureSeverePermissionPrincipalRiskModelInfo.IdentityCreationTime]: {
                        [Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal]: {
                            [Contract.AzureManagedIdentityManagedIdentityModelType.System]: "{{principal}} is a system assigned managed identity that was created {{creationTime | TimeFormatter.humanizePastDuration}}",
                            [Contract.AzureManagedIdentityManagedIdentityModelType.User]: "{{principal}} is a user assigned managed identity that was created {{creationTime | TimeFormatter.humanizePastDuration}}"
                        },
                        [Contract.TypeNames.AadDirectoryIdentity]: "{{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}}"
                    },
                    [Contract.AzureSeverePermissionPrincipalRiskModelInfo.ManagedIdentity]: {
                        [Contract.AzureManagedIdentityManagedIdentityModelType.System]: "{{principal}} is a system assigned managed identity",
                        [Contract.AzureManagedIdentityManagedIdentityModelType.User]: "{{principal}} is a user assigned managed identity"
                    },
                    [Contract.AzureSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionPermittingEntities]: {
                        [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "Admin privileges granted through: {{severePermissionActionPermittingEntities}}",
                        [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "New privileges granted through: {{severePermissionActionPermittingEntities}}"
                    },
                    [Contract.AzureSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionStartTime]: {
                        [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "Admin privileges granted on: {{severePermissionActionStartTime | TimeFormatter.longDate}}",
                        [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "New privileges granted on: {{severePermissionActionStartTime | TimeFormatter.longDate}}"
                    }
                }
            }));
    const createInfoProps =
        () => ({
            creationTime: principal.creationTime,
            groupIdentityIds:
                <InlineGroupIdentities
                    identityIds={severePermissionPrincipalRiskModel.risk.groupIdentityIds}
                    identityTypeName={Contract.TypeNames.AadDirectoryIdentity}/>,
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>,
            severePermissionActionPermittingEntities:
                localizeList(
                    _(severePermissionActionPermittingEntityModels).
                        groupBy(severePermissionActionPermittingEntityModel => severePermissionActionPermittingEntityModel.entity.typeName).
                        map(
                            (severePermissionActionPermittingEntityModels, severePermissionActionPermittingEntityTypeName) =>
                                <InlineEntities
                                    entityIdsOrModels={severePermissionActionPermittingEntityModels}
                                    entityTypeName={severePermissionActionPermittingEntityTypeName}
                                    key={severePermissionActionPermittingEntityTypeName}
                                    variant="itemCountAndType"/>).
                        concatIf(
                            severePermissionPrincipalRiskModel.risk.userClassicAdministrator,
                            () =>
                                <Typography component="span">
                                    {localization.userClassicAdministrator()}
                                </Typography>).
                        value()),
            severePermissionActionStartTime: severePermissionPrincipalRiskModel.risk.severePermissionActionStartTime
        });
    return (
        <Steps>
            {_.map(
                severePermissionPrincipalRiskModel.infos,
                info => {
                    switch (info) {
                        case Contract.AzureSeverePermissionPrincipalRiskModelInfo.GroupIdentities:
                            return localization[Contract.TypeNames.AzureSeverePermissionPrincipalRiskModelInfo][info](createInfoProps());
                        case Contract.AzureSeverePermissionPrincipalRiskModelInfo.IdentityCreationTime:
                            return _.isNil(managedIdentityModel)
                                ? localization[Contract.TypeNames.AzureSeverePermissionPrincipalRiskModelInfo][info][Contract.TypeNames.AadDirectoryIdentity](createInfoProps())
                                : localization[Contract.TypeNames.AzureSeverePermissionPrincipalRiskModelInfo][info][Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal][managedIdentityModel.type](createInfoProps());
                        case Contract.AzureSeverePermissionPrincipalRiskModelInfo.ManagedIdentity:
                            return localization[Contract.TypeNames.AzureSeverePermissionPrincipalRiskModelInfo][info][managedIdentityModel.type](createInfoProps());
                        case Contract.AzureSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionPermittingEntities:
                        case Contract.AzureSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionStartTime:
                            return localization[Contract.TypeNames.AzureSeverePermissionPrincipalRiskModelInfo][info][severePermissionPrincipalRiskModel.risk.type](createInfoProps());
                    }
                })}
        </Steps>);
}