﻿import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, SeveritySquare, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { useGcpComputeInstanceStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";
import { useGetGcpServiceAccountOriginatorScopeResourceRiskContext } from "./useGetGcpServiceAccountOriginatorScopeResourceRiskContext";

export function useGetGcpComputeInstanceRiskContext() {
    return useMemo(
        () => useGcpComputeInstanceRiskContext,
        []);
}

function useGcpComputeInstanceRiskContext(instanceModel: Contract.GcpComputeInstanceModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(instanceModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(instanceModel);
    const serviceAccountOriginatorScopeResourceRiskContext = useGetGcpServiceAccountOriginatorScopeResourceRiskContext()(instanceModel);

    const instance = instanceModel.entity as Contract.GcpComputeInstance;
    const serviceAccountModel = entityModelStore.useGet(instanceModel.serviceAccountIdReference);

    const computeInstanceStatusTranslator = useGcpComputeInstanceStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpComputeInstanceRiskContext",
            () => ({
                networkTags: {
                    pluralizer: [
                        "1 network tag",
                        "{{count | NumberFormatter.humanize}} network tags"
                    ],
                    tags: "The {{translatedInstanceTypeName}} has {{tags}} attached"
                },
                serviceAccount:
                    {
                        permissionAction: {
                            notAttached: "The {{translatedInstanceTypeName}} is not attached to any service account",
                            withoutPermissions: "The {{translatedInstanceTypeName}} is attached to {{serviceAccount}} with no permissions",
                            withPermissions: "The {{translatedInstanceTypeName}} is attached to {{serviceAccount}} that has {{severitySquare}}**{{serviceAccountPermissionsRiskSeverity}}** severity permissions"
                        },
                        title: "The {{translatedInstanceTypeName}} is using {{serviceAccount}}"
                    },
                status: "The {{translatedInstanceTypeName}} state is {{status}}",
                vpcsAndSubnets: "The {{translatedInstanceTypeName}} is part of virtual network {{vpcs}} and {{subnets}}"
            }));

    const translatedInstanceTypeName =
        entityTypeNameTranslator(
            instance.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        ...networkAccessResourceRiskContext,
        ...serviceAccountOriginatorScopeResourceRiskContext,
        networkTags:
            _.isEmpty(instance.networkTags)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.networkTags.tags({
                        tags:
                            <InlineItems
                                items={instance.networkTags}
                                namePluralizer={localization.networkTags.pluralizer}
                                variant="itemCountAndType"/>,
                        translatedInstanceTypeName
                    })),
        serviceAccount:
            _.isNil(instanceModel.serviceAccountIdReference)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.serviceAccount.title({
                        serviceAccount:
                            <Entity
                                entityIdOrModel={instanceModel.serviceAccountIdReference}
                                variant="text"/>,
                        translatedInstanceTypeName
                    })),
        serviceAccountPermissionAction:
            new RiskDefinitionContextItem(
                _.isNil(serviceAccountModel)
                    ? localization.serviceAccount.permissionAction.notAttached({
                        translatedInstanceTypeName
                    })
                    : _.isNil(_.as<Contract.IGcpIamServiceAccountModel>(serviceAccountModel).permissionActionSeverity)
                        ? localization.serviceAccount.permissionAction.withoutPermissions({
                            serviceAccount:
                                <Entity
                                    entityIdOrModel={serviceAccountModel!}
                                    variant="typeText"/>,
                            translatedInstanceTypeName
                        })
                        : localization.serviceAccount.permissionAction.withPermissions({
                            serviceAccount:
                                <Entity
                                    entityIdOrModel={serviceAccountModel!}
                                    variant="typeText"/>,
                            serviceAccountPermissionsRiskSeverity:
                                severityTranslator(_.as<Contract.IGcpIamServiceAccountModel>(serviceAccountModel).permissionActionSeverity!, "text"),
                            severitySquare:
                                <SeveritySquare
                                    severity={_.as<Contract.IGcpIamServiceAccountModel>(serviceAccountModel).permissionActionSeverity!}/>,
                            translatedInstanceTypeName
                        })),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: computeInstanceStatusTranslator(instance.status),
                    translatedInstanceTypeName
                })),
        vpcsAndSubnets:
            new RiskDefinitionContextItem(
                localization.vpcsAndSubnets({
                    subnets:
                        <InlineEntities
                            entityIdsOrModels={instanceModel.subnetIdReferences}
                            entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                            variant="itemCountAndType"/>,
                    translatedInstanceTypeName,
                    vpcs:
                        <InlineEntities
                            entityIdsOrModels={instanceModel.vpcIdReferences}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            variant="itemCountAndType"/>
                }))
    };
}