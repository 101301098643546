import { map, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsIamPrincipalRiskContext } from "../contexts";

export function useAwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamPrincipalAttachedPolicyPermissionScopeAnyRisk;
    const principalModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamPrincipalModel;

    const getAwsIamPrincipalRiskContext = useGetAwsIamPrincipalRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskDefinition",
            () => ({
                contextItems: {
                    permissionScopeAnyPolicyIds: "The {{translatedPrincipalTypeName}} has {{policies}} attached with administrative statements"
                },
                description: "{{principal}} has administrative policies attached",
                sections: {
                    resolution: {
                        step1: "In the **Permissions** tab, edit or remove {{policies}} which contains the administrative statements"
                    }
                }
            }));

    const translatedPrincipalTypeName =
        entityTypeNameTranslator(
            principalModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                map(
                    principalModel.entity.typeName, {
                        [Contract.TypeNames.AwsIamGroup]: () => AwsConsoleUrlBuilder.getIamGroupUrl(principalModel.entity as Contract.AwsIamGroup),
                        [Contract.TypeNames.AwsIamRole]: () => AwsConsoleUrlBuilder.getIamRoleUrl(principalModel.entity as Contract.AwsIamRole),
                        [Contract.TypeNames.AwsIamUser]: () => AwsConsoleUrlBuilder.getIamUserUrl(principalModel.entity as Contract.AwsIamUser)
                    })),
            localization.sections.resolution.step1({
                policies:
                    <InlineEntities
                        entityIdsOrModels={risk.permissionScopeAnyPolicyIds}
                        entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                        variant="itemCountAndType"/>
            })
        ],
        riskModel,
        () => {
            const principalRiskContext = getAwsIamPrincipalRiskContext(principalModel);
            return [
                principalRiskContext.generalInformation,
                principalRiskContext.permissionActionSeverity,
                new RiskDefinitionContextItem(
                    localization.contextItems.permissionScopeAnyPolicyIds({
                        policies:
                            <InlineEntities
                                entityIdsOrModels={risk.permissionScopeAnyPolicyIds}
                                entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                                variant="itemCountAndType"/>,
                        translatedPrincipalTypeName
                    })),
                principalRiskContext.sensitive,
                principalRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}