﻿import { AnalyticsEventActionType, Loading, map, useLocalization, useTrackAnalyticsEvent } from "@infrastructure";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useApplicationViewRoute, useLayoutOptions, UserConsoleAppUrlHelper } from "../../../../common";
import { PermissionEligibilityAuditEvents, PermissionRequestAuditEvents } from "./components";
import { PermissionManagementAuditEventsView } from "./permissionManagementAuditEventsView";

export function AuditEvents() {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const [{ view }, setViewRoute] =
        useApplicationViewRoute(
            UserConsoleAppUrlHelper.getAuditEventsRelativeUrl(),
            _.values(PermissionManagementAuditEventsView),
            PermissionManagementAuditEventsView.PermissionRequests);

    const localization =
        useLocalization(
            "views.user.auditEvents",
            () => ({
                tabs: {
                    [PermissionManagementAuditEventsView.PermissionEligibilities]: "Eligibilities",
                    [PermissionManagementAuditEventsView.PermissionRequests]: "Access Requests"
                },
                title: "Audit"
            }));
    useLayoutOptions({ view: { title: localization.title() } });

    return (
        <Stack sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                sx={{ border: "unset" }}
                value={view}
                variant="scrollable"
                onChange={(_event, view) => setViewRoute(view)}>
                {_.map(
                    [
                        PermissionManagementAuditEventsView.PermissionRequests,
                        PermissionManagementAuditEventsView.PermissionEligibilities
                    ],
                    permissionManagementAuditEventsView =>
                        <Tab
                            key={permissionManagementAuditEventsView}
                            label={localization.tabs[permissionManagementAuditEventsView]()}
                            value={permissionManagementAuditEventsView}/>)}
            </Tabs>
            <Box
                sx={{
                    flex: 1,
                    overflowY: "hidden"
                }}>
                <Loading>
                    {map(
                        view,
                        {
                            [PermissionManagementAuditEventsView.PermissionEligibilities]: () => <PermissionEligibilityAuditEvents/>,
                            [PermissionManagementAuditEventsView.PermissionRequests]: () => <PermissionRequestAuditEvents/>
                        })}
                </Loading>
            </Box>
        </Stack>);
}