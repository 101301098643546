import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntityIotIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path d="M7.23233 9.72083C6.92256 9.34861 6.92256 8.74513 7.23233 8.3729C7.85843 7.6206 8.60172 7.02384 9.41976 6.61669C10.2378 6.20956 11.1146 6 12 6C12.8854 6 13.7622 6.20956 14.5802 6.61669C15.3983 7.02384 16.1416 7.6206 16.7677 8.3729C17.0774 8.74512 17.0774 9.34861 16.7677 9.72083C16.4579 10.0931 15.9556 10.0931 15.6459 9.72083C15.1671 9.14554 14.5987 8.6892 13.9731 8.37785C13.3476 8.06651 12.6771 7.90626 12 7.90626C11.3229 7.90626 10.6525 8.06651 10.0269 8.37785C9.40132 8.6892 8.83292 9.14554 8.35414 9.72083C8.04436 10.0931 7.54211 10.0931 7.23233 9.72083Z"/>
            <path d="M9.23167 10.3777C8.92278 10.7488 8.92278 11.3506 9.23167 11.7217C9.54055 12.0928 10.0414 12.0928 10.3502 11.7217C10.5669 11.4614 10.8241 11.2549 11.1072 11.114C11.3903 10.9731 11.6936 10.9006 12 10.9006C12.3064 10.9006 12.6098 10.9731 12.8928 11.114C13.1759 11.2549 13.4331 11.4614 13.6498 11.7217C13.9586 12.0928 14.4595 12.0928 14.7683 11.7217C15.0772 11.3506 15.0772 10.7488 14.7683 10.3777C14.4048 9.94096 13.9732 9.59445 13.4982 9.35805C13.0232 9.12167 12.5141 9 12 9C11.4859 9 10.9768 9.12167 10.5018 9.35805C10.0268 9.59445 9.59521 9.94096 9.23167 10.3777Z"/>
            <path
                clipRule="evenodd"
                d="M11.0833 15.6667H12.9167V18.4167C12.9167 19.9355 14.1478 21.1667 15.6667 21.1667H17.6565C18.034 22.2348 19.0527 23 20.25 23C21.7688 23 23 21.7688 23 20.25C23 18.7312 21.7688 17.5 20.25 17.5C19.0527 17.5 18.034 18.2652 17.6565 19.3333H15.6667C15.1604 19.3333 14.75 18.9229 14.75 18.4167V15.6667H17.0417C20.3323 15.6667 23 12.9991 23 9.70833C23 6.74988 20.8445 4.29582 18.0177 3.82968C16.7688 2.11617 14.7442 1 12.4583 1C10.6483 1 9.00066 1.70048 7.77368 2.84312C7.65542 2.83662 7.53638 2.83333 7.41667 2.83333C3.87284 2.83333 1 5.70618 1 9.25C1 12.7938 3.87284 15.6667 7.41667 15.6667H9.25V18.4167C9.25 18.9229 8.8396 19.3333 8.33333 19.3333H6.34352C5.966 18.2652 4.94737 17.5 3.75 17.5C2.23122 17.5 1 18.7312 1 20.25C1 21.7688 2.23122 23 3.75 23C4.94737 23 5.966 22.2348 6.34352 21.1667H8.33333C9.85211 21.1667 11.0833 19.9355 11.0833 18.4167V15.6667ZM12.4583 2.83333C11.0099 2.83333 9.7054 3.4429 8.78464 4.4221L8.46466 4.76238L8.0013 4.70351C7.81024 4.67923 7.61513 4.66667 7.41667 4.66667C4.88537 4.66667 2.83333 6.7187 2.83333 9.25C2.83333 11.7813 4.88537 13.8333 7.41667 13.8333H17.0417C19.3199 13.8333 21.1667 11.9865 21.1667 9.70833C21.1667 7.55502 19.516 5.78621 17.4117 5.59965L16.9611 5.5597L16.7187 5.17776C15.8231 3.76663 14.2494 2.83333 12.4583 2.83333ZM4.66667 20.25C4.66667 19.7437 4.25626 19.3333 3.75 19.3333C3.24374 19.3333 2.83333 19.7437 2.83333 20.25C2.83333 20.7563 3.24374 21.1667 3.75 21.1667C4.25626 21.1667 4.66667 20.7563 4.66667 20.25ZM20.25 21.1667C19.7437 21.1667 19.3333 20.7563 19.3333 20.25C19.3333 19.7437 19.7437 19.3333 20.25 19.3333C20.7563 19.3333 21.1667 19.7437 21.1667 20.25C21.1667 20.7563 20.7563 21.1667 20.25 21.1667Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}