﻿import _ from "lodash";
import React from "react";
import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../common";
import { useBitbucketContext, useSetBitbucketContext } from "../../Bitbucket";
import { ConfigureOrganizationItem, FinishItem, OrganizationMemberSelectionItem } from "./components";

export class AddOrEditContext {
    public memberSelection?: Contract.OrganizationMemberSelection;
    public tenantPaths?: string[];
    public updatedAccessToken?: string;
    public updatedFolderEnabled?: boolean;
    public updatedFolderScopeNodeModel?: Contract.ScopeNodeModel;
    public updatedSyncEnabled?: boolean;

    constructor(public organizationModel?: Contract.CodeOrganizationModel) {
        this.memberSelection = organizationModel?.configuration.memberSelection;
        this.updatedFolderEnabled = organizationModel?.configuration.folderEnabled;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEditBitbucket() {
    const { addOrEditOpen } = useBitbucketContext();
    const setBitbucketContext = useSetBitbucketContext();

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.bitbucket.addOrEditBitbucket",
            () => ({
                steps: {
                    configureOrganization: "Configure Workspace",
                    tenants: "Select Repositories"
                },
                title: {
                    add: "Add Bitbucket Workspace",
                    edit: "Edit Bitbucket Workspace"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={0}
                    onClose={
                        () =>
                            setBitbucketContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.configureOrganization()}>
                        <ConfigureOrganizationItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenants()}>
                        <OrganizationMemberSelectionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}