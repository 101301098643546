﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../common";

export function useRiskPolicyConfigurationRotationTimeFrameStep(rotationTimeFrame: string) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyConfigurationRotationTimeFrameStep",
            () => ({
                documentationLink: "Learn more",
                text: "This finding was created based on a {{rotationTimeFrame | TimeSpanFormatter.day}} rotation period. {{documentationLink}}."
            }));

    return localization.text({
        documentationLink:
            <Link
                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                variant="external">
                {localization.documentationLink()}
            </Link>,
        rotationTimeFrame
    });
}