import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, customEntityAttributeDefinitionModelStore, Entity } from "../../../../../../../../../common";

export function useEntityManualCustomAttributeDefinitionUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const customEntityAttributeDefinitionModelMap =
        _.keyBy(
            customEntityAttributeDefinitionModels,
            customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id);

    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useEntityManualCustomAttributeDefinitionUpdateAuditEventDefinition",
            () => ({
                addedDefinitionIds: "Added",
                definitions: [
                    "1 label",
                    "{{count | NumberFormatter.humanize}} labels"
                ],
                entity: "Resource",
                removedDefinitionIds: "Removed"
            }));

    function getText(customEntityAttributeDefinitionIds: string[]) {
        return customEntityAttributeDefinitionIds.length === 1
            ? customEntityAttributeDefinitionModelMap[customEntityAttributeDefinitionIds[0]]?.configuration.name ?? customEntityAttributeDefinitionIds[0]
            : localization.definitions(customEntityAttributeDefinitionIds.length);
    }

    const entityManualCustomAttributeDefinitionUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.EntityManualCustomAttributeDefinitionUpdateAuditEvent;
    const entityModel = context.entityModelMap[entityManualCustomAttributeDefinitionUpdateAuditEvent.entityId];

    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.entity(),
                entityModel.entity.displayName,
                <Entity
                    entityIdOrModel={entityModel}
                    variant="iconText"/>),
            _.isEmpty(entityManualCustomAttributeDefinitionUpdateAuditEvent.addedDefinitionIds)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.addedDefinitionIds(),
                    getText(entityManualCustomAttributeDefinitionUpdateAuditEvent.addedDefinitionIds),
                    <InlineItems
                        items={entityManualCustomAttributeDefinitionUpdateAuditEvent.addedDefinitionIds}
                        variant="itemPlusItemCount">
                        {addedDefinitionId => customEntityAttributeDefinitionModelMap[addedDefinitionId]?.configuration.name ?? addedDefinitionId}
                    </InlineItems>),
            _.isEmpty(entityManualCustomAttributeDefinitionUpdateAuditEvent.removedDefinitionIds)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.removedDefinitionIds(),
                    getText(entityManualCustomAttributeDefinitionUpdateAuditEvent.removedDefinitionIds),
                    <InlineItems
                        items={entityManualCustomAttributeDefinitionUpdateAuditEvent.removedDefinitionIds}
                        variant="itemPlusItemCount">
                        {removedDefinitionId => customEntityAttributeDefinitionModelMap[removedDefinitionId]?.configuration.name ?? removedDefinitionId}
                    </InlineItems>)
        ]));
}