import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { ConfigurationController, Contract, getScopeConfigurationValueType, getScopeConfigurationValueTypeValue, RadioGroup, RadioGroupItem, ScopeConfigurationValueType, ScopeHelper, ScopeLabel, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../common";

export type EnabledProps = {
    description?: string;
    disabled?: boolean;
    getConfigurationControllerUpdateDataAnalysisRequest: (scopeNodeModelConfiguration: Contract.IScopeConfiguration, enabled: Optional<boolean>) => Contract.ConfigurationControllerUpdateDataAnalysisRequest;
    getConfigurationEnabled: (configuration: Contract.ScopeConfigurationDataAnalysis) => Optional<boolean>;
    title: string;
    tooltip?: string;
};

export function Enabled({ description, disabled = false, getConfigurationControllerUpdateDataAnalysisRequest, getConfigurationEnabled, title, tooltip }: EnabledProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    const [enabled, setEnabled] = useState(getConfigurationEnabled(scopeNodeModel.configuration.scopeSections.dataAnalysis));
    const inheritDisabled = scopeNodeModel.configuration.id === ScopeHelper.customerId;
    const inheritedEnabledScopeId =
        useMemo(
            () => {
                const parentScopeIds = scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds;
                for (const parentScopeId of parentScopeIds) {
                    if (!_.isNil(getConfigurationEnabled(scopeNodeMap[parentScopeId].scopeNodeModel.configuration.scopeSections.dataAnalysis))) {
                        return parentScopeId;
                    }
                }

                return undefined;
            },
            [scopeNodeModel, scopeNodeMap]);

    const localization =
        useLocalization(
            "views.customer.configuration.dataAnalysis.enabled",
            () => ({
                options: {
                    false: "Disabled",
                    inherit: "Inherit",
                    true: "Enabled"
                }
            }));

    const items =
        useMemo(
            () => {
                const inheritedEnabled =
                    _.isNil(inheritedEnabledScopeId)
                        ? undefined
                        : getConfigurationEnabled(scopeNodeMap[inheritedEnabledScopeId!].scopeNodeModel.configuration.scopeSections.dataAnalysis);

                return _<RadioGroupItem<ScopeConfigurationValueType>>([]).
                    concatIf(
                        !inheritDisabled,
                        {
                            label:
                                <ScopeLabel
                                    inheritedScopeId={inheritedEnabledScopeId}
                                    text={localization.options.inherit()}
                                    translatedInheritedValue={
                                        inheritedEnabled
                                            ? localization.options.true()
                                            : localization.options.false()}/>,
                            value: ScopeConfigurationValueType.Inherit
                        }).
                    concat(
                        {
                            label: <ScopeLabel text={localization.options.true()}/>,
                            value: ScopeConfigurationValueType.Enabled
                        },
                        {
                            label: <ScopeLabel text={localization.options.false()}/>,
                            value: ScopeConfigurationValueType.Disabled
                        }).
                    value();
            },
            [inheritDisabled, inheritedEnabledScopeId]);

    return (
        <RadioGroup
            description={description}
            disabled={disabled}
            items={items}
            selectedValue={getScopeConfigurationValueType(enabled)}
            title={title}
            tooltip={tooltip}
            onChange={
                async (value: ScopeConfigurationValueType) => {
                    const enabled = getScopeConfigurationValueTypeValue(value);
                    await ConfigurationController.updateDataAnalysis(
                        getConfigurationControllerUpdateDataAnalysisRequest(
                            scopeNodeModel.configuration,
                            enabled));
                    setEnabled(enabled);
                    await scopeNodeModelStore.notify(scopeNodeModel.configuration.id);
                }}/>);
}