import { Store } from "@infrastructure";
import { Contract, FileController } from "..";

export const tiFileModelStore =
    new Store<Contract.TiFileModel, never, never>(
        tiFileModel => tiFileModel.tiFile.scan.contentSha256String,
        {
            get:
                async contentSha256Strings => {
                    const { tiFileModels } = await FileController.getFileModels(new Contract.FileControllerGetFileModelsRequest(contentSha256Strings));
                    return tiFileModels;
                }
        });