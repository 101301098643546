import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAzureWebApplicationRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useAzureWebApplicationTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureWebApplicationInsecureTlsProtocolRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureWebApplicationInsecureTlsProtocolRisk;
    const applicationModel = entityModelStore.useGet(risk.entityId) as Contract.AzureWebApplicationModel;
    const application = applicationModel.entity as Contract.AzureWebApplication;

    const getAzureWebApplicationRiskContext = useGetAzureWebApplicationRiskContext();

    const webApplicationTypeTranslator = useAzureWebApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureWebApplicationInsecureTlsProtocolRiskDefinition",
            () => ({
                description: "{{applicationType}} {{application}} allows insecure communication protocols such as TLS 1.0 and 1.1",
                sections: {
                    resolution: {
                        step1: "Before enforcing minimum TLS version, verify that the clients of the {{applicationType}} support the second version",
                        step2: {
                            function: "az functionapp config set --resource-group {{resourceGroup}} --name {{name}} --min-tls-version 1.2",
                            title: "Run the following command from Azure CLI: {{command}}",
                            web: "az webapp config set --resource-group {{resourceGroup}} --name {{name}} --min-tls-version 1.2"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            application:
                <Entity
                    entityIdOrModel={applicationModel}
                    variant="text"/>,
            applicationType: webApplicationTypeTranslator(application.type)
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.CertificatesReact}/>,
            localization.sections.resolution.step1({ applicationType: webApplicationTypeTranslator(application.type) }),
            localization.sections.resolution.step2.title({
                command:
                    (application.type === Contract.AzureWebApplicationType.Web
                        ? localization.sections.resolution.step2.web
                        : localization.sections.resolution.step2.function)({
                        name:
                            <Entity
                                entityIdOrModel={application.id}
                                linkOptions={{ disabled: true }}
                                variant="text"/>,
                        resourceGroup:
                            <Entity
                                entityIdOrModel={application.resourceGroupId}
                                linkOptions={{ disabled: true }}
                                variant="text"/>
                    })
            })
        ],
        riskModel,
        () => {
            const applicationRiskContext = getAzureWebApplicationRiskContext(applicationModel);
            return [
                applicationRiskContext.generalInformation,
                applicationRiskContext.sensitive,
                applicationRiskContext.tlsMinVersion,
                applicationRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}