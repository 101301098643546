import { Dropdown, DropdownActions, DropdownIcon, Sx } from "@infrastructure";
import { List, ListItemButton, Stack, SxProps } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { Contract, Severity, TypeHelper } from "..";
import { useTheme } from "../themes";

type SeveritySelectorProps = {
    disabled?: boolean;
    justify?: "left" | "center";
    onSelectedSeverityChanged: (severity: Contract.Severity) => void;
    selectedSeverity: Contract.Severity;
    sx?: SxProps;
    variant?: "outlined" | "text";
};

export function SeveritySelector({ disabled, justify = "center", onSelectedSeverityChanged, selectedSeverity, sx, variant = "text" }: SeveritySelectorProps) {
    const actionsRef = useRef<DropdownActions>();
    const theme = useTheme();
    return (
        <Dropdown
            actionsRef={actionsRef}
            disabled={disabled}
            justify={justify}
            popoverElement={
                <List
                    dense={true}
                    sx={{ padding: 0 }}>
                    {_(Contract.Severity).
                        values().
                        orderBy(
                            severity =>
                                TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity),
                            "desc").
                        map(
                            severity =>
                                <ListItemButton
                                    dense={true}
                                    key={severity}
                                    sx={{ fontSize: "18px" }}
                                    onClick={
                                        () => {
                                            onSelectedSeverityChanged(severity);
                                            actionsRef.current?.close();
                                        }}>
                                    <Severity severity={severity}/>
                                </ListItemButton>).
                        value()}
                </List>}
            popoverElementContainerSx={{ padding: 0 }}>
            <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                sx={
                    Sx.combine(
                        {
                            "&:hover": {
                                border:
                                    variant === "outlined"
                                        ? theme.border.hoverFocus
                                        : undefined
                            },
                            border:
                                variant === "outlined"
                                    ? theme.border.primary
                                    : undefined,
                            borderRadius: theme.spacing(0.75),
                            padding: theme.spacing(0.5),
                            paddingLeft:
                                variant === "outlined"
                                    ? theme.spacing(1)
                                    : undefined
                        },
                        sx)}>
                <Severity
                    severity={selectedSeverity}
                    sx={{ fontSize: "18px" }}/>
                <DropdownIcon
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "18px"
                    }}/>
            </Stack>
        </Dropdown>);
}