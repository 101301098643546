import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, scopeNodeModelStore, scopeSystemEntityModelStore, SlackIcon, TeamsIcon, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { Slack, Teams } from "./components";

export function useCollaborationItems(childScopeEnabled: boolean): IntegrationItem[] {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCollaborationItems",
            () => ({
                description: {
                    childScope: "{{count | NumberFormatter.humanize}} configured (child scopes)",
                    title: "{{count | NumberFormatter.humanize}} configured"
                },
                [Contract.TypeNames.IntegrationType]: {
                    [Contract.IntegrationType.Slack]: "Slack",
                    [Contract.IntegrationType.Teams]: "Teams"
                }
            }));

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].childScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const slackInstanceModels = scopeSystemEntityModelStore.useGetSlack();
    const filteredChildScopeSlackInstanceModels =
        useMemo(
            () =>
                childScopeEnabled
                    ? _.filter(
                        slackInstanceModels,
                        slackInstanceModel =>
                            _.includes(
                                childScopeIds,
                                slackInstanceModel.configuration.scopeId))
                    : [],
            [childScopeEnabled, childScopeIds, slackInstanceModels]);
    const filteredSlackInstanceModels =
        useMemo(
            () =>
                _.filter(
                    slackInstanceModels,
                    slackInstanceModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            includes(slackInstanceModel.configuration.scopeId)),
            [slackInstanceModels, parentScopeIds]);
    const teamsInstanceModels = scopeSystemEntityModelStore.useGetTeams();
    const filteredChildScopeTeamsInstanceModels =
        useMemo(
            () =>
                childScopeEnabled
                    ? _.filter(
                        teamsInstanceModels,
                        teamsInstanceModel =>
                            _.includes(
                                childScopeIds,
                                teamsInstanceModel.configuration.scopeId))
                    : [],
            [childScopeEnabled, childScopeIds, slackInstanceModels]);
    const filteredTeamsInstanceModels =
        useMemo(
            () =>
                _.filter(
                    teamsInstanceModels,
                    teamsInstanceModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            includes(teamsInstanceModel.configuration.scopeId)),
            [teamsInstanceModels, parentScopeIds]);

    return [
        new IntegrationItem(
            IntegrationItemCategory.Collaboration,
            _.concat(filteredChildScopeSlackInstanceModels, filteredSlackInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredSlackInstanceModels.length })],
                childScopeEnabled && filteredChildScopeSlackInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeSlackInstanceModels.length })),
            <SlackIcon/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.Slack](),
            Contract.IntegrationType.Slack,
            <Slack/>),
        new IntegrationItem(
            IntegrationItemCategory.Collaboration,
            _.concat(filteredChildScopeTeamsInstanceModels, filteredTeamsInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredTeamsInstanceModels.length })],
                childScopeEnabled && filteredChildScopeTeamsInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeTeamsInstanceModels.length })),
            <TeamsIcon/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.Teams](),
            Contract.IntegrationType.Teams,
            <Teams/>)
    ];
}