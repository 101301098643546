import { useLocalization, WizardFinishItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAddOrEditContext } from "..";

export function FinishItem() {
    const { eventCollectorModel } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.splunk.addOrEdit.finishItem",
            () => ({
                title: {
                    add: "Splunk event collector was added successfully!",
                    edit: "Splunk event collector was changed successfully!"
                }
            }));

    return (
        <WizardFinishItem
            title={
                _.isNil(eventCollectorModel)
                    ? localization.title.add()
                    : localization.title.edit()}/>);
}