import { ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React, { memo } from "react";
import { Contract } from "..";
import { useNetworkInboundAccessTypeTranslator } from "../../views/Customer/components/Entities/hooks";

const NetworkInboundAccessTypeFilterMemo = memo(NetworkInboundAccessTypeFilter);
export { NetworkInboundAccessTypeFilterMemo as NetworkInboundAccessTypeFilter };

type NetworkInboundAccessTypeFilterProps = {
    placeholder: string;
};

function NetworkInboundAccessTypeFilter({ placeholder }: NetworkInboundAccessTypeFilterProps) {
    const networkInboundAccessTypeTranslator = useNetworkInboundAccessTypeTranslator();
    return (
        <ValuesFilter
            emptyValueOptions={{ enabled: true }}
            placeholder={placeholder}>
            <ValuesFilterItem
                title={networkInboundAccessTypeTranslator(Contract.NetworkInboundAccessType.ExternalDirect)}
                value={Contract.NetworkInboundAccessType.ExternalDirect}/>
            <ValuesFilterItem
                title={networkInboundAccessTypeTranslator(Contract.NetworkInboundAccessType.ExternalIndirect)}
                value={Contract.NetworkInboundAccessType.ExternalIndirect}/>
        </ValuesFilter>);
}