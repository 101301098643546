import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, scopeNodeModelStore, scopeSystemEntityModelStore, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { EndpointConnectorIcon } from "../../../../../../../../../../common/icons/EndpointConnectorIcon";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { EndpointConnectors } from "./components";

export function useEndpointConnectorItems(childScopeEnabled: boolean): IntegrationItem[] {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useEndpointConnectorItems",
            () => ({
                description: {
                    childScope: "{{count | NumberFormatter.humanize}} configured (child scopes)",
                    title: "{{count | NumberFormatter.humanize}} configured"
                },
                [Contract.TypeNames.IntegrationType]: {
                    [Contract.IntegrationType.EndpointConnector]: "Endpoint Connectors"
                }
            }));

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].childScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const endpointConnectorModels = scopeSystemEntityModelStore.useGetEndpointConnector();
    const filteredChildScopeEndpointConnectorModels =
        useMemo(
            () =>
                childScopeEnabled
                    ? _.filter(
                        endpointConnectorModels,
                        endpointConnectorModel =>
                            _.includes(
                                childScopeIds,
                                endpointConnectorModel.configuration.scopeId))
                    : [],
            [childScopeEnabled, childScopeIds, endpointConnectorModels]);
    const filteredEndpointConnectorModels =
        useMemo(
            () =>
                _.filter(
                    endpointConnectorModels,
                    endpointConnectorModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            includes(endpointConnectorModel.configuration.scopeId)),
            [childScopeEnabled, endpointConnectorModels, parentScopeIds]);

    return [
        new IntegrationItem(
            IntegrationItemCategory.EndpointConnectors,
            _.concat(filteredChildScopeEndpointConnectorModels, filteredEndpointConnectorModels),
            _.concatIf(
                [localization.description.title({ count: filteredEndpointConnectorModels.length })],
                childScopeEnabled && filteredChildScopeEndpointConnectorModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeEndpointConnectorModels.length })),
            <EndpointConnectorIcon/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.EndpointConnector](),
            Contract.IntegrationType.EndpointConnector,
            <EndpointConnectors/>)
    ];
}