import { Step, Steps, TextSnippet, TextSnippetItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useAddOrEditContext } from "../../..";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../../../common";
import { GcpCloudShellIcon } from "../../../../../../../../../../../../../../../../../tenants";

export function CloudShell() {
    const { gciTenantModel, organizationOnboardingInfo, permissionTypes } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.rolesItem.cloudShell",
            () => ({
                steps: {
                    step1: "Log into your Google Cloud Console.",
                    step2: "**Activate the Cloud Shell** by clicking {{cloudShellIcon}} on the top right of the console.",
                    step3: "**Run** the following script in your Cloud Shell:",
                    step4: "Wait until the script has completed successfully."
                }
            }));

    const bashScript =
        useMemo(
            () => {
                const roleRawIds =
                    _<string>([]).
                        concat(_.keys(organizationOnboardingInfo.serviceAccountOrganizationReadOnlyRoleRawIdToNameMap)).
                        concat(_.keys(organizationOnboardingInfo.serviceAccountReadOnlyRoleRawIdToNameMap)).
                        concatIf(
                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.PermissionManagement),
                            _.keys(organizationOnboardingInfo.serviceAccountPermissionManagementRoleRawIdToNameMap)).
                        concatIf(
                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Write),
                            _.keys(organizationOnboardingInfo.serviceAccountManagementRoleRawIdToNameMap)).
                        uniq().
                        orderBy().
                        map(roleName => `    '${roleName}'`).
                        join("\n");

                const bashScriptLines = [
                    `organization_id=$(gcloud organizations describe ${gciTenantModel!.configuration.primaryDomainName} --format 'value(name.sub("organizations/", ""))')`,
                    `role_ids=(\n${roleRawIds})`,
                    ``,
                    `for role_id in \${role_ids[@]};`,
                    `    do gcloud organizations add-iam-policy-binding $organization_id --condition=None --member serviceAccount:${gciTenantModel!.configuration.serviceAccountMail} --role $role_id;`,
                    `done`];

                if (_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis)) {
                    const serviceAccountWorkloadAnalysisPermissionActionsCode =
                        _(organizationOnboardingInfo.serviceAccountWorkloadAnalysisPermissionActions).
                            map(serviceAccountWorkloadAnalysisPermissionAction => `   '${serviceAccountWorkloadAnalysisPermissionAction}'`).
                            join("\n");

                    bashScriptLines.push(
                        ``,
                        `cwp_permissions=(\n${serviceAccountWorkloadAnalysisPermissionActionsCode})`,
                        ``,
                        `if gcloud iam roles describe TenableWorkloadScanning --organization=$organization_id ; then`,
                        `    gcloud iam roles update TenableWorkloadScanning --permissions=$(IFS=,; printf '%s' "\${cwp_permissions[*]}") --organization=$organization_id`,
                        `else`,
                        `    gcloud iam roles create TenableWorkloadScanning --description="Tenable Workload Scanning" --permissions=$(IFS=,; printf '%s' "\${cwp_permissions[*]}") --organization=$organization_id --title="Tenable Workload Scanning"`,
                        `fi`,
                        ``,
                        `gcloud organizations add-iam-policy-binding $organization_id --condition=None --member serviceAccount:${gciTenantModel!.configuration.serviceAccountMail} --role organizations/$organization_id/roles/TenableWorkloadScanning`);
                }

                if (_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis)) {
                    const serviceAccountDataAnalysisPermissionActionsCode =
                        _(organizationOnboardingInfo.serviceAccountDataAnalysisPermissionActions).
                            map(serviceAccountDataAnalysisPermissionAction => `   '${serviceAccountDataAnalysisPermissionAction}'`).
                            join("\n");

                    bashScriptLines.push(
                        ``,
                        `dspm_permissions=(\n${serviceAccountDataAnalysisPermissionActionsCode})`,
                        ``,
                        `if gcloud iam roles describe TenableDataScanning --organization=$organization_id ; then`,
                        `    gcloud iam roles update TenableDataScanning --permissions=$(IFS=,; printf '%s' "\${dspm_permissions[*]}") --organization=$organization_id`,
                        `else`,
                        `    gcloud iam roles create TenableDataScanning --description="Tenable Data Scanning" --permissions=$(IFS=,; printf '%s' "\${dspm_permissions[*]}") --organization=$organization_id --title="Tenable Data Scanning"`,
                        `fi`,
                        ``,
                        `gcloud organizations add-iam-policy-binding $organization_id --condition=None --member serviceAccount:${gciTenantModel!.configuration.serviceAccountMail} --role organizations/$organization_id/roles/TenableDataScanning`);
                }

                return _.join(
                    bashScriptLines,
                    "\n");
            },
            []);

    const theme = useTheme();
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2({
                cloudShellIcon:
                    <GcpCloudShellIcon
                        sx={{
                            display: "inline",
                            fontSize: "18px",
                            verticalAlign: "middle"
                        }}/>
            })}
            {new Step(
                localization.steps.step3(),
                {
                    contentElement:
                        <TextSnippet
                            height={theme.spacing(52)}
                            items={[
                                new TextSnippetItem(
                                    bashScript,
                                    "")
                            ]}
                            wordWrap={false}/>
                })}
            {localization.steps.step4()}
        </Steps>);
}