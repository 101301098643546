import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Dialog, FormLayout, InlineItems, Link, Message, useLocalization } from "@infrastructure";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, scopeNodeModelStore, ScopeSelector, UserHelper, useTheme } from "../../../../../../../common";

type InsertTrustedFilesDialogProps = {
    contentSha256Strings: string[];
    onClose: () => void;
    tenantIds: string[];
};

export function InsertTrustedFilesDialog({ contentSha256Strings, onClose, tenantIds }: InsertTrustedFilesDialogProps) {
    const [comment, setComment] = useState<string>();
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [notAddedRequestTrustedFileContentSha256Strings, setNotAddedRequestTrustedFileContentSha256Strings] = useState<string[]>([]);
    const [notifyScopeNodeModelStoreScopeIds, setNotifyScopeNodeModelStoreScopeIds] = useState<string[]>([]);
    const [scopeId, setScopeId] = useState<string>();

    async function close() {
        if (_.size(notifyScopeNodeModelStoreScopeIds) > 0) {
            await scopeNodeModelStore.notify(notifyScopeNodeModelStoreScopeIds);
        }

        onClose();
    }

    async function insertTrustedFiles() {
        setExecuting(true);
        setError(false);
        setNotAddedRequestTrustedFileContentSha256Strings([]);

        try {
            const insertWorkloadAnalysisTrustedFilesResponse =
                await ConfigurationController.insertWorkloadAnalysisTrustedFiles(
                    new Contract.ConfigurationControllerInsertWorkloadAnalysisTrustedFilesRequest(
                        comment,
                        contentSha256Strings,
                        scopeId!));
            setNotifyScopeNodeModelStoreScopeIds(
                _(notifyScopeNodeModelStoreScopeIds).
                    concat(scopeId!).
                    uniq().
                    value());

            if (_.size(insertWorkloadAnalysisTrustedFilesResponse.notAddedRequestTrustedFiles) != 0) {
                setNotAddedRequestTrustedFileContentSha256Strings(
                    _.map(
                        insertWorkloadAnalysisTrustedFilesResponse.notAddedRequestTrustedFiles,
                        notAddedTrustedFile => notAddedTrustedFile.contentSha256String));
                setExecuting(false);
                return;
            }
        } catch {
            setExecuting(false);
            setError(true);
            return;
        }

        setExecuting(false);
        setComment(undefined);
        await close();
    }

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.maliciousFiles.insertTrustedFilesDialog",
            () => ({
                actions: {
                    save: "Mark as trusted"
                },
                error: "Could not mark files as trusted",
                fields: {
                    comment: {
                        placeholder: "Write a comment...",
                        title: "Comment"
                    },
                    contentSha256String: "SHA-256",
                    scope: "Scope"
                },
                notAddedRequestTrustedFiles: [
                    "The following file has already been marked as trusted in this scope: {{notAddedRequestTrustedFiles}}",
                    "The following files have already been marked as trusted in this scope: {{notAddedRequestTrustedFiles}}"
                ],
                subtitle: {
                    text: [
                        "This action will mark the detected file as trusted, and change the status of any findings with this file to Resolved. You can always manage the trusted files from the {{workloadProtectionConfigurationLink}}.",
                        "This action will mark the detected files as trusted, and change the status of any findings with these file to Resolved. You can always manage the trusted files from the {{workloadProtectionConfigurationLink}}."
                    ],
                    workloadProtectionConfigurationLink: "Workload Protection settings page"
                },
                title: [
                    "Mark File as Trusted",
                    "Mark {{count | NumberFormatter.humanize}} Files as Trusted"
                ]
            }));

    const theme = useTheme();
    return (
        <Dialog
            variant="editor"
            onClose={close}>
            <FormLayout
                footerOptions={{
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}>
                            {executing && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={
                                    executing ||
                                    _.isNil(scopeId)}
                                onClick={insertTrustedFiles}>
                                {localization.actions.save()}
                            </Button>
                        </Stack>
                }}
                titleOptions={{
                    subtitle: localization.subtitle.text(
                        contentSha256Strings.length,
                        {
                            workloadProtectionConfigurationLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationWorkloadAnalysisMaliciousFilesRelativeUrl()}
                                    variant="external">
                                    {localization.subtitle.workloadProtectionConfigurationLink()}
                                </Link>
                        }),
                    subtitleSx: { width: "unset" },
                    text: localization.title(contentSha256Strings.length)
                }}>
                <Stack spacing={2}>
                    <Typography variant="h5">
                        {localization.fields.contentSha256String()}
                    </Typography>
                    <InlineItems
                        items={
                            _.map(
                                contentSha256Strings,
                                contentSha256String =>
                                    <Typography>
                                        {contentSha256String}
                                    </Typography>)}
                        variant="itemPlusItemCount"/>
                    <Typography variant="h5">
                        {localization.fields.scope()}
                    </Typography>
                    <ScopeSelector
                        fullWidth={true}
                        popoverElementContainerSx={{ padding: 0 }}
                        scopeIds={tenantIds}
                        scopeSelectable={
                            scopeId =>
                                UserHelper.hasScopePermissions(
                                    scopeId,
                                    Contract.IdentityPermission.SecurityAdministrationRead)}
                        selectedScopeId={scopeId}
                        onSelectedScopeIdChanged={setScopeId}/>
                    <Typography variant="h5">
                        {localization.fields.comment.title()}
                    </Typography>
                    <TextField
                        disabled={executing}
                        fullWidth={true}
                        label={localization.fields.comment.placeholder()}
                        value={comment}
                        variant="outlined"
                        onChange={event => setComment(event.target.value)}/>
                    {_.size(notAddedRequestTrustedFileContentSha256Strings) != 0 && (
                        <Message
                            level="infoWarning"
                            title={
                                localization.notAddedRequestTrustedFiles(
                                    notAddedRequestTrustedFileContentSha256Strings.length,
                                    {
                                        notAddedRequestTrustedFiles:
                                            <InlineItems
                                                items={
                                                    _.map(
                                                        notAddedRequestTrustedFileContentSha256Strings,
                                                        notAddedRequestTrustedFileContentSha256String =>
                                                            <Typography>
                                                                {notAddedRequestTrustedFileContentSha256String}
                                                            </Typography>)}
                                                variant="itemPlusItemCount"/>
                                    })
                            }/>)}
                    {error && (
                        <Message
                            level="error"
                            title={localization.error()}/>)}
                </Stack>
            </FormLayout>
        </Dialog>);
}