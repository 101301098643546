import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ContainerImagesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M11.4688 0.136686C11.7845 -0.045562 12.1734 -0.045562 12.4891 0.136686L21.9978 5.62657C22.3135 5.80881 22.5079 6.14562 22.5079 6.51012V17.4899C22.5079 17.8544 22.3135 18.1912 21.9978 18.3734L12.4891 23.8633C12.1734 24.0456 11.7845 24.0456 11.4688 23.8633L1.96007 18.3734C1.64441 18.1912 1.44995 17.8544 1.44995 17.4899V6.51012C1.44995 6.14562 1.64441 5.80881 1.96007 5.62657L11.4688 0.136686ZM11.2138 2.2474L3.15035 6.90281V16.0593L6.87775 13.8229V9.27936L11.4688 12V17.4413L7.68028 15.126L3.78467 17.4634L11.9789 22.1944L20.303 17.3885L16.4896 15.1004L12.6591 17.4413V12L17.2502 9.27936V13.8224L17.2723 13.7854L20.8075 15.9065V6.90281L12.7441 2.24739V6.28539L16.655 8.25912L12.1065 10.9798L7.55791 8.25912L11.2138 6.41412V2.2474ZM3.19055 17.1204L3.15035 17.0534V17.0972L3.19055 17.1204Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}