import { DropdownIcon, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useTenantTypeTranslator, useTheme } from "../../..";
import { Tenant } from "../../Tenant";

type DropdownProps = {
    disabled: boolean;
    includeRawId: boolean;
    selectedTenantIds: string[];
    summarized?: boolean;
    tenantModels: Contract.TenantModel[];
};

export function Dropdown({ disabled, includeRawId, selectedTenantIds, summarized = false, tenantModels }: DropdownProps) {
    const tenantTypes =
        useMemo(
            () => {
                const tenantModelMap =
                    _.keyBy(
                        tenantModels,
                        tenantModel => tenantModel.configuration.id);
                return _(selectedTenantIds).
                    map(selectedTenantId => tenantModelMap[selectedTenantId]?.tenantType).
                    filter().
                    uniq().
                    value();
            },
            [selectedTenantIds, tenantModels]);

    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "common.tenantMultiSelect.dropdown",
            () => ({
                checkbox: {
                    all: "All accounts",
                    count: "{{count}} accounts",
                    countWithTenant: "{{count}} {{tenant}} accounts",
                    none: "No accounts",
                    some: "{{firstTenant}} +{{otherTenantCount | NumberFormatter.humanize}}"
                },
                toggle: {
                    all: "**Enabled** on all accounts",
                    none: "**Disabled**",
                    some: "**Enabled** on {{selectedTenantCount | NumberFormatter.humanize}} accounts"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            sx={{
                color:
                    disabled
                        ? theme.palette.text.disabled
                        : undefined,
                cursor:
                    disabled
                        ? undefined
                        : "pointer",
                height: "36px"
            }}>
            <Typography noWrap={true}>
                {_.isEmpty(selectedTenantIds) || selectedTenantIds.length === tenantModels.length
                    ? localization.checkbox.all()
                    : selectedTenantIds.length === 1
                        ? <Tenant
                            tenantId={selectedTenantIds[0]}
                            tenantNameTranslatorOptions={{ includeRawId }}
                            variant="text"/>
                        : summarized
                            ? _.size(tenantTypes) === 1
                                ? localization.checkbox.countWithTenant({
                                    count: _.size(selectedTenantIds),
                                    tenant: tenantTypeTranslator(tenantTypes[0]!)
                                })
                                : localization.checkbox.count({ count: _.size(selectedTenantIds) })
                            : localization.checkbox.some({
                                firstTenant:
                                    <Tenant
                                        tenantId={selectedTenantIds[0]}
                                        tenantNameTranslatorOptions={{ includeRawId }}
                                        variant="text"/>,
                                otherTenantCount: selectedTenantIds.length - 1
                            })}
            </Typography>
            <DropdownIcon sx={{ fontSize: "18px" }}/>
        </Stack>);
}