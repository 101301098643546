import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsS3BucketRiskContext } from "../contexts";

export function useAwsS3BucketEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsS3BucketBlockPublicAccessDisabledRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.AwsS3BucketModel;
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;

    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsS3BucketEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{bucket}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Select the **Properties** tab",
                        step2: "Under the **Default Encryption** section, click **Edit**",
                        step3: "Check the **Enable** option",
                        step4: "For the **Encryption type**, select **Server-side encryption with AWS Key Management Service keys (SSE-KMS)** and select the KMS key to use for encryption",
                        step5: "Click **Save Changes**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                bucket:
                    <Entity
                        entityIdOrModel={bucketModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () =>
            [
                consoleSignInStepTranslator(
                    Contract.AwsConsoleView.S3,
                    AwsConsoleUrlBuilder.getS3BucketUrl(bucket)),
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2(),
                localization.sections.resolution.step3(),
                localization.sections.resolution.step4(),
                localization.sections.resolution.step5()
            ],
        riskModel,
        () => {
            const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.storageSize,
                bucketRiskContext.encryption,
                bucketRiskContext.sensitive,
                bucketRiskContext.accessLevel,
                bucketRiskContext.highestDataSensitivity,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}