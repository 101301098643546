﻿import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, EnumValuesFilter, optionalTableCell, useChangeEffect, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useRef } from "react";
import { Contract, Entity, EntityFilter, entityModelStore, useAwsOrganizationsPolicyTypeTranslator, useTableDefinition } from "../../../../../../../../../../../../common";

type AwsOrganizationsPolicyTableProps = {
    account: Contract.AwsOrganizationsAccount;
    organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap: _.Dictionary<Contract.EntitySearchableReference[]>;
    organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap: _.Dictionary<Contract.EntitySearchableReference[]>;
};

export function AwsOrganizationsPolicyTable({ account, organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap, organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap }: AwsOrganizationsPolicyTableProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap, organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap]);

    const organizationsScopeResourceIdToPolicySearchableReferencesMap =
        useMemo(
            () =>
                _.mergeWith(
                    {},
                    organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap,
                    organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap,
                    (resourceControlPolicySearchableReferences, serviceControlPolicySearchableReferences) =>
                        _.concat(
                            resourceControlPolicySearchableReferences ?? [],
                            serviceControlPolicySearchableReferences ?? [])),
            [organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap, organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap]);

    const organizationsScopeResourceModels = entityModelStore.useGet(_.keys(organizationsScopeResourceIdToPolicySearchableReferencesMap));
    const policyModels =
        entityModelStore.useGet(
            _(organizationsScopeResourceIdToPolicySearchableReferencesMap).
                values().
                flatten().
                map(policySearchableReference => policySearchableReference.id).
                value());

    const [organizationsScopeResourceModelMap, policyDatas] =
        useMemo(
            () => {
                const organizationsScopeResourceModelMap =
                    _.keyBy(
                        organizationsScopeResourceModels,
                        organizationsScopeResourceModel => organizationsScopeResourceModel.id);

                const policyModelMap =
                    _.keyBy(
                        policyModels,
                        policyModel => policyModel.id);
                const policyDatas =
                    _.flatMap(
                        organizationsScopeResourceIdToPolicySearchableReferencesMap,
                        (policySearchableReferences, organizationsScopeResourceId) =>
                            _.map(
                                policySearchableReferences,
                                policySearchableReference =>
                                    new AwsOrganizationsPolicyData(
                                        organizationsScopeResourceId,
                                        policyModelMap[policySearchableReference.id] as Contract.AwsOrganizationsPolicyModel)));
                return [organizationsScopeResourceModelMap, policyDatas];
            },
            [organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap, organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap]);
    const organizationsPolicyTypeTranslator = useAwsOrganizationsPolicyTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsTenantEntityDefinition.awsOrganizationsPolicyTable",
            () => ({
                columns: {
                    description: "Description",
                    organizationsScopeResource: {
                        account: "Attached directly",
                        parent: "Inherited from {{organizationsScopeResource}}",
                        title: "Source"
                    },
                    policy: "Policy",
                    type: "Type"
                },
                empty: {
                    withFilter: "No Matching Organization Policies",
                    withoutFilter: "No Organization Policies"
                }
            }));
    const tableDefinition =
        useTableDefinition(
            policyDatas,
            {
                [AwsOrganizationsPolicyDataColumnId.Description]: {
                    getFilterValue: item => item.policy.description,
                    getSortValue: item => item.policy.description ?? ""
                },
                [AwsOrganizationsPolicyDataColumnId.OrganizationsScopeResourceId]: {
                    getFilterValue: item => item.organizationsScopeResourceId,
                    getSortValue:
                        item =>
                            item.organizationsScopeResourceId === account.id
                                ? localization.columns.organizationsScopeResource.account()
                                : localization.columns.organizationsScopeResource.parent({ organizationsScopeResource: organizationsScopeResourceModelMap[item.organizationsScopeResourceId].entity.displayName })
                },
                [AwsOrganizationsPolicyDataColumnId.Policy]: {
                    getFilterValue: item => item.policy.id,
                    getSortValue: item => item.policy.displayName
                },
                [AwsOrganizationsPolicyDataColumnId.Type]: {
                    getFilterValue: item => item.policy.type,
                    getSortValue: item => organizationsPolicyTypeTranslator(item.policy.type)
                }
            },
            AwsOrganizationsPolicyDataColumnId.Policy);
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: AwsOrganizationsPolicyData) => `${item.organizationsScopeResourceId}${item.policy.id}`}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[AwsOrganizationsPolicyDataColumnId.Policy]}
                                placeholder={localization.columns.policy()}/>
                    }
                }}
                id={AwsOrganizationsPolicyDataColumnId.Policy}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsOrganizationsPolicyData>) =>
                        <Entity
                            entityIdOrModel={item.policyModel}
                            variant="iconTextTypeTenant"/>}
                title={localization.columns.policy()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsOrganizationsPolicyType}
                                enumTypeTranslator={organizationsPolicyTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={AwsOrganizationsPolicyDataColumnId.Type}
                itemProperty={(item: AwsOrganizationsPolicyData) => organizationsPolicyTypeTranslator(item.policy.type)}
                key={AwsOrganizationsPolicyDataColumnId.Type}
                title={localization.columns.type()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[AwsOrganizationsPolicyDataColumnId.OrganizationsScopeResourceId]}
                                placeholder={localization.columns.organizationsScopeResource.title()}/>
                    }
                }}
                id={AwsOrganizationsPolicyDataColumnId.OrganizationsScopeResourceId}
                itemProperty={
                    (item: AwsOrganizationsPolicyData) =>
                        item.organizationsScopeResourceId === account.id
                            ? localization.columns.organizationsScopeResource.account()
                            : localization.columns.organizationsScopeResource.parent({
                                organizationsScopeResource:
                                    <Entity
                                        entityIdOrModel={organizationsScopeResourceModelMap[item.organizationsScopeResourceId]}
                                        linkOptions={{ disabled: true }}
                                        variant="text"/>
                            })}
                key={AwsOrganizationsPolicyDataColumnId.OrganizationsScopeResourceId}
                title={localization.columns.organizationsScopeResource.title()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                placeholder={localization.columns.description()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[AwsOrganizationsPolicyDataColumnId.Description],
                                    description =>
                                        <ValuesFilterItem
                                            key={description}
                                            title={description}
                                            value={description}/>)}
                            </ValuesFilter>
                    }
                }}
                id={AwsOrganizationsPolicyDataColumnId.Description}
                key={AwsOrganizationsPolicyDataColumnId.Description}
                render={optionalTableCell<AwsOrganizationsPolicyData>(item => item.policy.description)}
                title={localization.columns.description()}/>
        </DataTable>);
}

enum AwsOrganizationsPolicyDataColumnId {
    Description = "description",
    Name = "name",
    OrganizationsScopeResourceId = "organizationsScopeResourceId",
    Policy = "policy",
    Type = "type"
}

class AwsOrganizationsPolicyData {
    public policy: Contract.AwsOrganizationsPolicy;

    constructor(
        public organizationsScopeResourceId: string,
        public policyModel: Contract.AwsOrganizationsPolicyModel) {
        this.policy = policyModel.entity as Contract.AwsOrganizationsPolicy;
    }
}