import { AnalyticsOptions, CheckboxField, Sx, useLocalization, useSearchListContext, useTrackAnalytics } from "@infrastructure";
import { Divider, Stack, SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";

type SearchListSelectorsProps = {
    analyticsOptions?: AnalyticsOptions<"toggleMatches" | "toggleValues">;
    getItemValue?: (value: any) => any;
    items: string[];
    onSelectedItemsChanged: (selectedItems?: string[]) => void;
    selectedItems?: string[];
    sx?: SxProps;
};

export function SearchListSelectors({ analyticsOptions, getItemValue, items, onSelectedItemsChanged, selectedItems, sx }: SearchListSelectorsProps) {
    const { filtered, filteredItems } = useSearchListContext();
    const [filteredSelectedValues, filteredValues] =
        useMemo(
            () => {
                const filteredValues =
                    _.isNil(getItemValue)
                        ? filteredItems
                        : _.map(
                            filteredItems,
                            getItemValue);
                const filteredSelectedValues =
                    _.intersection(
                        filteredValues,
                        selectedItems ?? []);
                return [filteredSelectedValues, filteredValues];
            },
            [filteredItems, selectedItems]);

    const trackAnalytics = useTrackAnalytics();
    const toggleValues =
        useCallback(
            (selectValues: boolean) => {
                onSelectedItemsChanged(
                    selectValues
                        ? items
                        : undefined);

                if (analyticsOptions?.toggleValues) {
                    trackAnalytics(
                        analyticsOptions.toggleValues,
                        {
                            "Filter Value Matches": selectValues
                        });
                }
            },
            [analyticsOptions, items]);

    const toggleMatches =
        useCallback(
            (selectValues: boolean) => {
                if (selectValues) {
                    onSelectedItemsChanged(_.union(selectedItems, filteredValues));
                } else {
                    const newSelectedValues = _.difference(selectedItems, filteredValues);
                    onSelectedItemsChanged(
                        _.isEmpty(newSelectedValues)
                            ? undefined
                            : newSelectedValues);
                }

                if (analyticsOptions?.toggleMatches) {
                    trackAnalytics(
                        analyticsOptions.toggleMatches,
                        {
                            "Filter Value All": selectValues
                        });
                }
            },
            [analyticsOptions, filteredValues, selectedItems]);

    const localization =
        useLocalization(
            "infrastructure.searchListSelectors",
            () => ({
                actions: {
                    all: {
                        select: "Select all"
                    },
                    filtered: {
                        select: "Select matches"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            sx={
                Sx.combine(
                    { padding: theme.spacing(0, 0.5) },
                    sx
                )}>
            {!filtered
                ? <CheckboxField
                    checked={!_.isEmpty(selectedItems) && selectedItems!.length === items.length}
                    indeterminate={!_.isEmpty(selectedItems) && selectedItems!.length < items.length}
                    onChange={(_event, checked) => toggleValues(checked)}>
                    {localization.actions.all.select()}
                </CheckboxField>
                : !_.isEmpty(filteredValues) &&
                <CheckboxField
                    checked={!_.isEmpty(filteredSelectedValues) && filteredSelectedValues!.length === filteredValues.length}
                    indeterminate={!_.isEmpty(filteredSelectedValues) && filteredSelectedValues!.length < filteredValues.length}
                    onChange={(_event, checked) => toggleMatches(checked)}>
                    {localization.actions.filtered.select()}
                </CheckboxField>}
            <Divider
                flexItem={true}
                sx={{ margin: theme.spacing(1, 0, 0) }}/>
        </Stack>);
}