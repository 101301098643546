import { EmptyMessage, TextViewer, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../common";

type AwsIamPolicyDocumentProps = {
    emptyMessage?: {
        noWrap: boolean;
        text: string;
    };
    policyDocument?: Contract.AwsIamPolicyDocument;
    variant?: "assumeRolePolicy" | "inlinePolicy" | "policy";
};

export function AwsIamPolicyDocument({ emptyMessage, policyDocument, variant = "policy" }: AwsIamPolicyDocumentProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.awsIamPolicyDocument",
            () => ({
                empty: {
                    assumeRolePolicy: "No resource policy",
                    inlinePolicy: "No inline policy",
                    policy: "No policy"
                }
            }));

    const theme = useTheme();
    return (
        _.isNil(policyDocument)
            ? <EmptyMessage
                message={emptyMessage?.text ?? localization.empty.translate(variant)}
                noWrap={emptyMessage?.noWrap}
                sx={{
                    textAlign: "center"
                }}
                verticalCenter={true}/>
            : <Box sx={{ paddingTop: theme.spacing(1.5) }}>
                <TextViewer
                    format="json"
                    text={policyDocument.raw}/>
            </Box>);
}