﻿import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, VulnerabilityChart } from "../../../../../common";
import { useContainerImageDataScanStatusTranslator } from "../hooks";

export type VulnerabilitiesCellProps = {
    containerImageScanStatus?: Contract.ContainerImageDataScanStatus;
    variant?: "count" | "initialAndCount";
    vulnerabilities?: Contract.VulnerabilityData[];
};

export function VulnerabilitiesCell({ containerImageScanStatus, variant = "count", vulnerabilities = [] }: VulnerabilitiesCellProps) {
    const containerImageScanStatusTranslator = useContainerImageDataScanStatusTranslator();

    const filteredInformationalSeverityVulnerabilities =
            _(vulnerabilities).
                filter(vulnerabilityData => vulnerabilityData.severity !== Contract.Severity.Information).
                value();

    if (
        !_.isNil(containerImageScanStatus) &&
        _.isEmpty(filteredInformationalSeverityVulnerabilities)) {
        return (
            <Typography noWrap={true}>
                {containerImageScanStatusTranslator(containerImageScanStatus)}
            </Typography>);
    }

    return (
        <VulnerabilityChart
            variant={variant}
            vulnerabilities={filteredInformationalSeverityVulnerabilities}/>);
}