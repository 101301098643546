import { useLocalization, useQueryParameters } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { NotificationMail } from ".";
import { CustomerConsoleAppUrlHelper } from "../../../common";

type NewUserMailQueryParameters = {
    invitingUserMail?: string;
};

export function ExportNewUserMail() {
    const { invitingUserMail } = useQueryParameters<NewUserMailQueryParameters>();
    const localization =
        useLocalization(
            "views.system.exportNewUserMail",
            () => ({
                link: "Go to Tenable Cloud Security",
                subtitle: {
                    invitingUser: "{{invitingUserMail}} invited you to join Tenable Cloud Security",
                    noInvitingUser: "You are invited to join Tenable Cloud Security"
                },
                title: "Hey there!"
            }));
    const subtitle =
        _.isNil(invitingUserMail)
            ? localization.subtitle.noInvitingUser()
            : localization.subtitle.invitingUser({ invitingUserMail });
    return (
        <NotificationMail
            link={{
                text: localization.link(),
                value: CustomerConsoleAppUrlHelper.getRootRelativeUrl()
            }}
            mailTitle={subtitle}
            subtitle={subtitle}
            title={localization.title()}/>);
}