import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useGciTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useGciTenantAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                tenantName: "Workspace name",
                tenantPrimaryDomainName: "Primary domain name",
                tenantRawId: "Customer ID",
                tenantServiceAccountMail: "Service account mail",
                tenantUserMail: "User mail"
            }));

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.GciTenantAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantRawId(),
                    tenantAuditEvent.tenantRawId),
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName),
                new AuditEventDefinitionDetailItem(
                    localization.tenantPrimaryDomainName(),
                    tenantAuditEvent.tenantPrimaryDomainName),
                new AuditEventDefinitionDetailItem(
                    localization.tenantServiceAccountMail(),
                    tenantAuditEvent.tenantServiceAccountMail)).
            concatIf(
                !_.isNil(tenantAuditEvent.tenantUserMail),
                new AuditEventDefinitionDetailItem(
                    localization.tenantUserMail(),
                    tenantAuditEvent.tenantUserMail!)).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.GciTenantUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (tenantAuditEvent as Contract.GciTenantUpdateAuditEvent).tenantServiceAccountMailChanged
                                ? localization.tenantServiceAccountMail()
                                : undefined,
                            (tenantAuditEvent as Contract.GciTenantUpdateAuditEvent).tenantUserMailChanged
                                ? localization.tenantUserMail()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}