import { Optional, useDirectedGraphNodeContext, useLocalization } from "@infrastructure";
import React, { MutableRefObject } from "react";
import { ResourcePermitterNodeContent } from ".";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../..";
import { AccessDefinitionToolbarActions } from "../../..";
import { entityModelStore } from "../../../../../../../stores";
import { PermitterDefinitionNodeContent } from "../../../components";

type PermissionPathNodeContentProps = {
    permissionPath: Contract.AzureAccessGraphPermissionPath;
    toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>;
};

export function PermissionPathNodeContent({ permissionPath, toolbarActionsRef }: PermissionPathNodeContentProps) {
    const { modelNode } = useDirectedGraphNodeContext();
    const roleDefinitionModel = entityModelStore.useGet(permissionPath.identifier.roleDefinitionId);
    const scopeEntityModel = entityModelStore.useGet(permissionPath.identifier.scopeEntityId);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAzureDefinition.permissionPathNodeContent",
            () => ({
                title: "{{roleDefinition}} | {{scopeEntityDisplayName}} ({{scopeEntityTypeName}})"
            }));

    if (permissionPath.identifier.permitterType === Contract.AzurePermitterType.AzureRoleAssignmentResource) {
        return (
            <PermitterDefinitionNodeContent
                icon={
                    <Entity
                        blurred={modelNode.options.appearance === "normal"}
                        entityIdOrModel={roleDefinitionModel!}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="icon"/>}
                options={{
                    subtitle: entityTypeNameTranslator(permissionPath.identifier.roleAssignmentResourceTypeName!),
                    title:
                        localization.title({
                            roleDefinition:
                                <Entity
                                    entityIdOrModel={roleDefinitionModel!}
                                    linkOptions={{
                                        disabled: true,
                                        disabledHighlight: false
                                    }}
                                    variant="text"/>,
                            scopeEntityDisplayName: scopeEntityModel!.entity.displayName,
                            scopeEntityTypeName: entityTypeNameTranslator(scopeEntityModel!.entity.typeName)
                        })
                }}
                permitterEntityId={permissionPath.identifier.roleDefinitionId!}
                toolbarActionsRef={toolbarActionsRef}
                variant="large"
                onViewText={entityModel => (entityModel.entity as Contract.AzureAuthorizationRoleDefinition).json}/>);
    }

    return (
        <ResourcePermitterNodeContent
            permitterType={permissionPath.identifier.permitterType}
            variant="large"/>);
}