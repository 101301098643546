﻿import { InlineSecrets, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, InlinePermissionActions, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../../../utilities";
import { useResourceSecretExistsRiskContext } from "../../../../../../useResourceSecretExistsRiskContext";
import { useGetPartialPrincipalModels } from "../../../../useGetPartialPrincipalModels";

export function useGetGcpComputeInstanceStartupScriptSecretExistsRiskContext() {
    return useMemo(
        () => useGcpComputeInstanceStartupScriptSecretExistsRiskContext,
        []);
}

function useGcpComputeInstanceStartupScriptSecretExistsRiskContext(risk: Contract.GcpComputeInstanceStartupScriptSecretExistsRisk) {
    const principalModels = entityModelStore.useGet(risk.readResourcePermissionActionPrincipalIds);
    const partialPrincipalModels = useGetPartialPrincipalModels(risk.readResourcePermissionActionPrincipalIds);

    const secretContextItems = useResourceSecretExistsRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceStartupScriptSecretExistsRiskDefinition.hooks.hooks.useGetGcpComputeInstanceStartupScriptSecretExistsRiskContext",
            () => ({
                permissionActionPrincipalsWithExternalPrincipals: "{{principalIds}} including {{partialPrincipalIds}} were granted {{readActions}}, allowing them to read your secrets",
                permissionActionPrincipalsWithoutExternalPrincipals: "{{principalIds}} were granted {{readActions}}, allowing them to read your secrets",
                secrets: "The {{translatedResourceTypeName}} is exposing {{secrets}} via startup script"
            }));

    return {
        excludedSecrets: secretContextItems.getExcludedSecretsContextItem(
            risk.excludedSecrets,
            risk.typeName,
            risk.tenantId),
        permissionActionPrincipals:
            _.isEmpty(risk.readActions)
                ? undefined
                : new RiskDefinitionContextItem(
                    (partialPrincipalModels.length <= 1
                        ? localization.permissionActionPrincipalsWithoutExternalPrincipals
                        : localization.permissionActionPrincipalsWithExternalPrincipals)({
                        partialPrincipalIds:
                            <InlineEntities
                                entityIdsOrModels={partialPrincipalModels}
                                entityTypeName={Contract.TypeNames.IGciPartialPrincipal}
                                variant="itemCountAndType"/>,
                        principalIds:
                            <InlineEntities
                                entityIdsOrModels={principalModels}
                                entityTypeName={Contract.TypeNames.IGciPrincipal}
                                variant="itemCountAndType"/>,
                        readActions:
                            <InlinePermissionActions
                                permissionActions={risk.readActions}
                                variant="itemCountAndType"/>
                    })),
        secrets:
            new RiskDefinitionContextItem(
                localization.secrets({
                    secrets:
                        <InlineSecrets secrets={risk.secrets}/>,
                    translatedResourceTypeName:
                        entityTypeNameTranslator(
                            risk.aggregatingEntityTypeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                }))
    };
}