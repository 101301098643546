import { AnalyticsEventActionType, Link, Loading, useLocalization, useTrackAnalyticsEvent, VerticalFillGrid, VerticalTabView } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, FeatureHelper, LicensingHelper, ScopeHelper, ScopeNavigationView, StorageHelper, TenantHelper, UrlHelper, useLayoutOptions, UserHelper, useScopeNavigationViewContext } from "../../../../common";
import { CustomerView } from "../../hooks";
import { Access, ApiKeys, Authentication, Automations, Code, DataAnalysis, Entities, Integrations, Licensing, NetworkInternalSubnets, PermissionManagement, Principals, WorkloadAnalysis } from "./components";
import { ElementClass } from "./Configuration.element";
import { ViewIcon } from "./icons";
import { ConfigurationView } from "./utilities";

export function Configuration() {
    const configurationTenantTypes =
        useMemo(
            () => TenantHelper.ConfigurationTenantTypes,
            []);
    const localization =
        useLocalization(
            "views.customer.configuration",
            () => ({
                title: "Settings"
            }));
    useLayoutOptions({ view: { title: localization.title() } });
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);
    return (
        <ScopeNavigationView
            hideNonPermittedScopes={true}
            identityPermissions={[Contract.IdentityPermission.SecurityAdministrationRead]}
            layout="global"
            scopeSelectionStrategy="none"
            templatePath={CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.Configuration)}
            tenantTypes={configurationTenantTypes}>
            <View/>
        </ScopeNavigationView>);
}

export type ConfigurationViewItem = {
    component: () => ReactNode;
    disabled?: boolean;
    disabledTooltip?: string;
    tooltipInteractive?: boolean;
};

function View() {
    const { scopeNodeModel, useViewRoute } = useScopeNavigationViewContext();
    const localization =
        useLocalization(
            "views.customer.configuration.view",
            () => ({
                disabledItemTooltip: "This option is not available in the selected scope",
                permissionManagementDisabledTooltip: "You need a JIT license to use this feature. Contact your representative or {{supportLink}} for more information.",
                support: "Support",
                tabs: {
                    configurationView: {
                        [ConfigurationView.Access]: "IAM",
                        [ConfigurationView.ApiKeys]: "API",
                        [ConfigurationView.Authentication]: "Single Sign-On",
                        [ConfigurationView.Automations]: "Automations",
                        [ConfigurationView.Code]: "IaC",
                        [ConfigurationView.DataAnalysis]: "Data",
                        [ConfigurationView.Entities]: "Inventory",
                        [ConfigurationView.Integrations]: "Integrations",
                        [ConfigurationView.Licensing]: "Licensing",
                        [ConfigurationView.NetworkInternalSubnets]: "Network",
                        [ConfigurationView.PermissionManagement]: "JIT",
                        [ConfigurationView.Principals]: "User Management",
                        [ConfigurationView.WorkloadAnalysis]: "Workload"
                    }
                }
            }));

    const customerHasPermissionManagementLicense = LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement);
    const orderedConfigurationViewItems: { [key in ConfigurationView]: ConfigurationViewItem } =
        useMemo(
            () => (
                {
                    [ConfigurationView.Principals]: {
                        component: () => <Principals/>,
                        disabled: !UserHelper.hasScopePermissions(scopeNodeModel.configuration.id, Contract.IdentityPermission.SecurityAdministrationRead)
                    },
                    [ConfigurationView.Integrations]: {
                        component: () => <Integrations/>,
                        disabled:
                            scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration &&
                            !_.includes(TenantHelper.IntegrationTenantTypes, ScopeHelper.getTenantType(scopeNodeModel))
                    },
                    [ConfigurationView.Automations]: {
                        component: () => <Automations/>,
                        disabled:
                            scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration &&
                            !_.includes(TenantHelper.RisksTenantTypes, ScopeHelper.getTenantType(scopeNodeModel))
                    },
                    [ConfigurationView.Entities]: {
                        component: () => <Entities/>,
                        disabled:
                            ScopeHelper.getTenantType(scopeNodeModel) === Contract.TenantType.Code ||
                            ScopeHelper.isProjectScope(scopeNodeModel)
                    },
                    [ConfigurationView.Access]: {
                        component: () => <Access/>,
                        disabled:
                            !LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Ciem) ||
                            ScopeHelper.isProjectScope(scopeNodeModel)
                    },
                    [ConfigurationView.DataAnalysis]: {
                        component: () => <DataAnalysis/>,
                        disabled:
                            (scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration &&
                                !_.includes(TenantHelper.DataAnalysisTenantTypes, ScopeHelper.getTenantType(scopeNodeModel))) ||
                            !LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                    },
                    [ConfigurationView.WorkloadAnalysis]: {
                        component: () => <WorkloadAnalysis/>,
                        disabled:
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
                                ? scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration &&
                                !_.includes(TenantHelper.WorkloadAnalysisTenantTypes, ScopeHelper.getTenantType(scopeNodeModel))
                                : !(FeatureHelper.enabled(Contract.FeatureName.TvdlEnabled) &&
                                    scopeNodeModel.configuration.typeName === Contract.TypeNames.CustomerConfiguration)
                    },
                    [ConfigurationView.Code]: {
                        component: () => <Code/>,
                        disabled:
                            (scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration &&
                                ScopeHelper.getTenantType(scopeNodeModel) !== Contract.TenantType.Code) ||
                            !LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                    },
                    [ConfigurationView.NetworkInternalSubnets]: {
                        component: () => <NetworkInternalSubnets/>,
                        disabled:
                            scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration ||
                            !LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                    },
                    [ConfigurationView.PermissionManagement]: {
                        component: () => <PermissionManagement/>,
                        disabled:
                            scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration ||
                            !customerHasPermissionManagementLicense,
                        disabledTooltip:
                            customerHasPermissionManagementLicense
                                ? undefined
                                : localization.permissionManagementDisabledTooltip({
                                    supportLink:
                                        <Link
                                            urlOrGetUrl={UrlHelper.supportUrl}
                                            variant="external">
                                            {localization.support()}
                                        </Link>
                                }),
                        tooltipInteractive: true
                    },
                    [ConfigurationView.ApiKeys]: {
                        component: () => <ApiKeys/>
                    },
                    [ConfigurationView.Authentication]: {
                        component: () => <Authentication/>,
                        disabled: scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration
                    },
                    [ConfigurationView.Licensing]: {
                        component: () => <Licensing/>,
                        disabled:
                            !LicensingHelper.visible ||
                            (scopeNodeModel.configuration.typeName !== Contract.TypeNames.CustomerConfiguration &&
                                (!ScopeHelper.isProjectScope(scopeNodeModel) &&
                                    !_.includes(TenantHelper.BillableTenantTypes, ScopeHelper.getTenantType(scopeNodeModel))))
                    }
                }),
            [scopeNodeModel]);

    const [activeConfigurationViews, items] =
        useMemo(
            () => {
                const activeConfigurationViews =
                    _(orderedConfigurationViewItems).
                        omitBy(orderedConfigurationView => orderedConfigurationView.disabled).
                        keys().
                        as<ConfigurationView>().
                        value();

                const items =
                    _.map(
                        orderedConfigurationViewItems,
                        (configurationViewItem, key) => ({
                            disabled: configurationViewItem.disabled,
                            disabledTooltipTitle: configurationViewItem.disabledTooltip ?? localization.disabledItemTooltip(),
                            icon: <ViewIcon view={key as ConfigurationView}/>,
                            title: localization.tabs.configurationView[key as ConfigurationView](),
                            tooltipInteractive: configurationViewItem.tooltipInteractive,
                            view: key
                        }));

                return [activeConfigurationViews, items];
            },
            [orderedConfigurationViewItems]);

    const [view, setView] = useViewRoute("{view}", activeConfigurationViews);

    return (
        <VerticalFillGrid key={scopeNodeModel.configuration.id}>
            <Stack
                className={ElementClass.configurationContainer}
                direction="row"
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <VerticalTabView
                    items={items}
                    selectedView={view}
                    storageItem={StorageHelper.customerConfigurationTabsOpen}
                    onSelectedViewChanged={view => setView(view as ConfigurationView)}>
                    <Stack
                        className={ElementClass.configurationViewItemContainer}
                        sx={{
                            height: "100%",
                            width: "100%"
                        }}>
                        <Loading>
                            {orderedConfigurationViewItems[view].component()}
                        </Loading>
                    </Stack>
                </VerticalTabView>
            </Stack>
        </VerticalFillGrid>);
}