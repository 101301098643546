import { DataTableSortType, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, TypeHelper } from "../../../../../../../../../../../common";
import { RisksItemsFilterId } from "../../../../../../Filters";
import { GroupedItem, GroupedTableColumnId } from "../../../Grouped";
import { RiskCount, Severity } from "../components";

export function useCommonGroupedRiskColumns() {
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.hooks.useCommonGroupedRiskColumns",
            () => ({
                riskCount: "Findings",
                severity: "Severity"
            }));

    return useMemo(
        () => {
            const orderedRiskSeverities =
                _(Contract.Severity).
                    values().
                    orderBy(
                        riskSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskSeverity),
                        "desc").
                    value();
            return ({
                riskCount: {
                    element: RiskCount,
                    id: GroupedTableColumnId.RiskCount,
                    minWidth: 280,
                    sortOptions: {
                        getIterates: () => [(item: GroupedItem) => item.riskGroup.riskCount],
                        type: DataTableSortType.Numeric
                    },
                    title: localization.riskCount(),
                    width: 3
                },
                severity: {
                    element: Severity,
                    filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity],
                    id: GroupedTableColumnId.Severity,
                    minWidth: 280,
                    sortOptions: {
                        getIterates:
                            () => {
                                const getHighestSeverity = (item: GroupedItem) => orderedRiskSeverities.find(riskSeverity => !_.isNil(item.riskGroup.severityToCountMap[riskSeverity]))!;
                                const getHighestSeverityCount = (item: GroupedItem) => item.riskGroup.severityToCountMap[getHighestSeverity(item)]!;
                                return [
                                    (item: GroupedItem) => TypeHelper.getEnumValue(Contract.TypeNames.Severity, getHighestSeverity(item)),
                                    (item: GroupedItem) => getHighestSeverityCount(item),
                                    (item: GroupedItem) => getHighestSeverityCount(item) / item.riskGroup.riskCount
                                ];
                            },
                        type: DataTableSortType.Numeric
                    },
                    title: localization.severity(),
                    width: 1.5
                }
            });
        },
        []);
}