import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useLocalization } from "@infrastructure";
import { Contract, defaultSeverities, WidgetDefinition } from "../../../../../../../..";
import { AccessDashboardContext, useDashboardContext } from "../../../../../../Dashboard";
import { CustomGrid } from "../../components";
import { Principal } from "./components";

export function useGetPrincipalsDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetPrincipalsDefinition",
            () => ({
                title: "Principals"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <Principals/>,
            options: { title: localization.title() }
        }),
        [localization]);
}

function Principals() {
    const { filters, summary } = useDashboardContext<AccessDashboardContext>();

    const severities =
        useMemo(
            () =>
                _.isEmpty(filters.severities)
                    ? defaultSeverities
                    : filters.severities,
            [filters.severities]);

    const items =
        useMemo(
            () =>
                [
                    new PrincipalsCardItem(
                        Contract.PrincipalRiskCategory.ExcessivePermissionUser,
                        Contract.PrincipalType.User,
                        Contract.PrincipalRiskCategory.InactiveUser),
                    new PrincipalsCardItem(
                        Contract.PrincipalRiskCategory.ExcessivePermissionServiceIdentity,
                        Contract.PrincipalType.ServiceIdentity,
                        Contract.PrincipalRiskCategory.InactiveServiceIdentity),
                    new PrincipalsCardItem(
                        Contract.PrincipalRiskCategory.ExcessivePermissionGroup,
                        Contract.PrincipalType.Group),
                    new PrincipalsCardItem(
                        Contract.PrincipalRiskCategory.ExcessivePermissionAwsPermissionSet,
                        Contract.PrincipalType.AwsPermissionSet,
                        Contract.PrincipalRiskCategory.UnusedAwsPermissionSet)
                ],
            []);

    return (
        <CustomGrid
            minItemWidth={200}
            sx={{ flex: 1 }}>
            {_(items).
                filter(item => summary.principalTypeToSummaryMap[item.PrincipalType].permissionPrincipalCount > 0).
                map(
                    item =>
                        <Principal
                            key={item.PrincipalType}
                            openRiskSeverityToPrincipalCountMap={
                                _.pickBy(summary.principalRiskCategoryToOpenRiskSeverityToPrincipalCountMap[item.ExcessivePermissionPrincipalRiskCategory],
                                    (_principalCount, openExcessivePermissionRiskSeverity) =>
                                        _.includes(
                                            severities,
                                            openExcessivePermissionRiskSeverity))}
                            permissionPrincipalCount={summary.principalTypeToSummaryMap[item.PrincipalType].permissionPrincipalCount}
                            principalRiskCategory={item.ExcessivePermissionPrincipalRiskCategory}
                            principalType={item.PrincipalType}
                            privileged={summary.principalTypeToSummaryMap[item.PrincipalType].severePermissionPrincipalCount}/>).
                value()}
        </CustomGrid>);
}

class PrincipalsCardItem {
    constructor(
        public ExcessivePermissionPrincipalRiskCategory: Contract.PrincipalRiskCategory,
        public PrincipalType: Contract.PrincipalType,
        public InactivePrincipalRiskCategory?: Contract.PrincipalRiskCategory) {
    }
}