﻿import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { RiskContentProps } from "../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, usePrincipalModelAccess, useRiskSeverityReasonTranslator } from "../../../../../../../../../../../../../../common";
import { useGcpIamServiceAccountDisabledTranslator } from "../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useOpenRiskedEntityRisksStep } from "../../../../../useOpenRiskedEntityRisksStep";
import { useResourceGeneralInformationStep } from "../../../../../useResourceGeneralInformationStep";
import { EntityExternalConsoleLink } from "../../../../components";
import { RoleBindingTable } from "../components";

export function useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountDefinition(riskModel: Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskModel) {
    const risk = riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk;
    const serviceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamServiceAccountModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountDefinition",
            () => ({
                description: {
                    roleIds: [
                        "1 high severity role",
                        "{{count | NumberFormatter.humanize}} high severity role"
                    ],
                    text: "Service account {{serviceAccountId}} has {{roleIds}} assigned at the project level or above"
                },
                sections: {
                    resolution: {
                        step1: "Before deleting the admin role bindings, assign least privilege role bindings according to the actual used permissions",
                        step2: "{{entityExternalConsoleLink}} and filter on **Principal:{{serviceAccountMail}}**",
                        step3: "Under **Permissions** delete the role bindings to {{roleIds}}"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            roleIds:
                <InlineEntities
                    entityIdsOrModels={riskModel.roleIds}
                    entityTypeName={Contract.TypeNames.GcpIamRole}
                    namePluralizer={localization.description.roleIds}
                    variant="itemAndTypeOrItemCountAndType"/>,
            serviceAccountId:
                <Entity
                    entityIdOrModel={serviceAccountModel}
                    variant="text"/>
        }),
        () =>
            _.concat(
                localization.sections.resolution.step1(),
                _(risk.orderedResourceManagerResourceIds).
                    flatMap(
                        resourceManagerResourceId => [
                            localization.sections.resolution.step2({
                                entityExternalConsoleLink:
                                    <EntityExternalConsoleLink
                                        entityId={resourceManagerResourceId}
                                        page={Contract.GcpConsolePage.Permissions}/>,
                                serviceAccountMail: risk.principalMail
                            }),
                            localization.sections.resolution.step3({
                                roleIds:
                                    <InlineEntities
                                        entityIdsOrModels={risk.resourceManagerResourceIdToRoleIdsMap[resourceManagerResourceId]}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })
                        ]).
                    value()),
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <RoleBindingTable
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const risk = riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk;
    const serviceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamServiceAccountModel;
    const serviceAccountModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(serviceAccountModel.id);
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(serviceAccountModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(serviceAccountModel);
    const iamServiceAccountDisabledTranslator = useGcpIamServiceAccountDisabledTranslator();
    const riskSeverityReasonTranslator = useRiskSeverityReasonTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountDefinition.contextSection",
            () => ({
                disabled: "The service account is {{disabled}}",
                originatorResourceIds: "The service account is assigned to {{originatorResourceIds}}",
                signInActivity: {
                    everExact: "The service account was last active {{signInActivityTime | TimeFormatter.humanizePastDuration}}",
                    everOlderThan: "The service account was last active more than {{signInActivityTime | TimeFormatter.humanizePastDuration}}",
                    never: "The service account was not active since it was created"
                }
            }));

    return (
        <Steps>
            {_.filter([
                resourceGeneralInformationStep,
                risk.serviceAccountSignInActivity!.timeType === Contract.ActivityTimeType.Unknown
                    ? undefined
                    : _.isNil(risk.serviceAccountSignInActivity!.time)
                        ? localization.signInActivity.never()
                        : risk.serviceAccountSignInActivity!.timeType === Contract.ActivityTimeType.Exact
                            ? localization.signInActivity.everExact({ signInActivityTime: risk.serviceAccountSignInActivity!.time })
                            : localization.signInActivity.everOlderThan({ signInActivityTime: risk.serviceAccountSignInActivity!.time }),
                localization.disabled({ disabled: iamServiceAccountDisabledTranslator(risk.serviceAccountDisabled!) }),
                _.isEmpty(serviceAccountModelAccess.originatorEntityIds)
                    ? undefined
                    : localization.originatorResourceIds({
                        originatorResourceIds:
                            <InlineEntities
                                entityIdsOrModels={serviceAccountModelAccess.originatorEntityIds}
                                entityTypeName={Contract.TypeNames.GcpScopeResource}
                                variant="itemAndTypeOrItemCountAndType"/>
                    }),
                openRiskedEntityRisksStep,
                riskSeverityReasonTranslator(
                    risk.severity,
                    risk.severityReason!)
            ])}
        </Steps>);
}