﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsAcmCertificateStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsAcmCertificateStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsAcmCertificateStatus]: {
                    [Contract.AwsAcmCertificateStatus.Expired]: "Expired",
                    [Contract.AwsAcmCertificateStatus.Failed]: "Failed",
                    [Contract.AwsAcmCertificateStatus.Inactive]: "Inactive",
                    [Contract.AwsAcmCertificateStatus.Issued]: "Issued",
                    [Contract.AwsAcmCertificateStatus.PendingValidation]: "Pending validation",
                    [Contract.AwsAcmCertificateStatus.Revoked]: "Revoked",
                    [Contract.AwsAcmCertificateStatus.ValidationTimedOut]: "Validation time out"
                }
            }));
    return (status: Contract.AwsAcmCertificateStatus) =>
        localization[Contract.TypeNames.AwsAcmCertificateStatus][status]();
}