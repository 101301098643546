import { InlineItems, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, tenantModelStore, useTenantNameTranslator } from "../../../../../../../../common";
import { ProfileDefinition } from "../../useProfileDefinition";
import { useGetCloudProviderTenantProfilePermissionsSection } from "../useGetCloudProviderTenantProfilePermissionsSection";
import { StatusCell } from "./components";
import { useGcpCommonDefinitionData } from "./useGcpCommonDefinitionData";

export function useGcpProfileDefinition(scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const tenantModelMap = tenantModelStore.useGetGcpTenantMap();
    const { gciTenantIdToServiceAccountTenantRawShortNameIdOrModel, getSinkPubSubSubscriptionRawId } = useGcpCommonDefinitionData();
    const getTenantProfilePermissionsSection = useGetCloudProviderTenantProfilePermissionsSection();
    const tenantNameTranslator = useTenantNameTranslator();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.useGcpProfileDefinition",
            () => ({
                gciTenantId: "Google Workspace",
                permissions: "Permissions",
                rawShortNameId: "ID",
                rawShortNumberId: "Number",
                serviceAccountAssignedRoleNames: "Service Account Roles",
                sinkPubSubSubscriptionRawId: "Sink PubSub Subscription",
                status: "Status"
            }));

    return useMemo(
        () => {
            const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
            return {
                sections: [
                    {
                        items: [
                            {
                                title: localization.rawShortNameId(),
                                value: tenantModel.configuration.rawShortNameId
                            },
                            {
                                title: localization.rawShortNumberId(),
                                value: tenantModel.configuration.rawShortNumberId
                            },
                            {
                                title: localization.gciTenantId(),
                                value: tenantNameTranslator((scopeNodeModel.configuration as Contract.GcpTenantConfiguration).gciTenantId)
                            },
                            {
                                title: localization.serviceAccountAssignedRoleNames(),
                                value:
                                    <InlineItems
                                        items={tenantModel.configuration.serviceAccountAssignedRoleNames}
                                        variant="itemPlusItemCount"/>
                            },
                            {
                                title: localization.sinkPubSubSubscriptionRawId(),
                                value: getSinkPubSubSubscriptionRawId(tenantModel)
                            },
                            {
                                title: localization.status(),
                                value: (
                                    <StatusCell
                                        serviceAccountTenantRawShortNameIdOrModel={gciTenantIdToServiceAccountTenantRawShortNameIdOrModel[tenantModel.configuration.gciTenantId]}
                                        tenantModel={tenantModel}/>)
                            }
                        ]
                    },
                    getTenantProfilePermissionsSection(tenantModel)
                ]
            };
        },
        [gciTenantIdToServiceAccountTenantRawShortNameIdOrModel, getTenantProfilePermissionsSection, getSinkPubSubSubscriptionRawId, localization, tenantModelMap]);
}