import { DialogMenuItem, NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements, useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { AwsIamPolicyDocument } from "../../components";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../useDefaultDataAnalysisResourceInfoItemElements";

export function useAwsDynamoDbTableDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            resourceModel as Contract.AwsDynamoDbTableModel,
            dataAnalysis => dataAnalysis.managedDatabaseScanEnabled);
    const menuItems = [getDataAnalysisResourcePriorityScanRequestDialogMenuItem()];
    const table = resourceModel.entity as Contract.AwsDynamoDbTable;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsDynamoDbTableDefinition",
            () => ({
                info: {
                    items: {
                        accessLevel: {
                            warning: "To evaluate access, grant the permission dynamodb:GetResourcePolicy to Tenable."
                        },
                        deleteProtection: {
                            disabled: "Disabled",
                            enabled: "Enabled",
                            title: "Delete Protection"
                        },
                        storageSize: "Size"
                    }
                },
                tabs: {
                    policyDocument: {
                        permissionEmptyMessage: "To display the policy, grant the permission dynamodb:GetResourcePolicy to Tenable.",
                        title: "Policy"
                    }
                }
            }));

    const commonAccessResourceInfoElements =
        useAwsCommonAccessResourceInfoElements(
            resourceModel,
            { accessLevel: { warningMessage: localization.info.items.accessLevel.warning() } });

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                table.getPolicyDocumentError
                    ? <AwsIamPolicyDocument
                        emptyMessage={{
                            noWrap: false,
                            text: localization.tabs.policyDocument.permissionEmptyMessage()
                        }}/>
                    : <AwsIamPolicyDocument policyDocument={table.policyDocument}/>,
                localization.tabs.policyDocument.title(),
                "policyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="deleteProtection"
                        title={localization.info.items.deleteProtection.title()}
                        value={
                            table.deleteProtection
                                ? localization.info.items.deleteProtection.enabled()
                                : localization.info.items.deleteProtection.disabled()}/>,
                    <InfoItem
                        key="storageSize"
                        location="all"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(table.storageSize)}/>,
                    ...defaultDataAnalysisResourceInfoItemElements]}
                options={options?.infoOptions}>
            </Info>,
        topbarMenuItems: _.filter(menuItems) as DialogMenuItem[]
    });
}