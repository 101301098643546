import { DataTableColumn, DataTableColumnRenderProps, NoneIcon, TimeCell, TimeRangeFilter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ItemSelectionHelper, RiskController, RiskType, TimeRangeHelper } from "../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../useCommonCloudRiskPolicyTableDefinition";

export function useAwsSecretsManagerSecretRotationDisabledRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const [{ filters }] =
        useExecuteOperation(
            [useAwsSecretsManagerSecretRotationDisabledRiskDefinition, riskPolicyConfigurationTypeName],
            () =>
                RiskController.getRiskPolicyTypeGroupFilters(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest(
                        requestFilters!,
                        riskPolicyConfigurationTypeName)));

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useSecretDefinition.hooks.useAwsSecretsManagerSecretRotationDisabledRiskDefinition",
            () => ({
                columns: {
                    creationTime: "Secret Creation Time",
                    rotationTime: "Rotation Time",
                    usageTime: "Usage Time"
                }
            }));

    const awsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters = filters as Contract.AwsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters;
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();
    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    emptyValue={awsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters.creationTimeRange.emptyValue}
                                    placeholder={localization.columns.creationTime()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(awsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters.creationTimeRange.timeRange)}/>
                        }
                    }}
                    id={TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskCreationTime}
                    key={TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskCreationTime}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsSecretsManagerSecretRotationDisabledRiskModel>) =>
                            <TimeCell time={item.risk.creationTime}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.creationTime()}/>,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    emptyValue={awsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters.usageTimeRange.emptyValue}
                                    placeholder={localization.columns.usageTime()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(awsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters.usageTimeRange.timeRange)}/>
                        }
                    }}
                    id={TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskUsageTime}
                    key={TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskUsageTime}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsSecretsManagerSecretRotationDisabledRiskModel>) =>
                            _.isNil(item.risk.usageTime)
                                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                : <TimeCell time={item.risk.usageTime!}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.usageTime()}/>,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    emptyValue={awsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters.rotationTimeRange.emptyValue}
                                    placeholder={localization.columns.rotationTime()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(awsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters.rotationTimeRange.timeRange)}/>
                        }
                    }}
                    id={TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskRotationTime}
                    key={TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskRotationTime}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsSecretsManagerSecretRotationDisabledRiskModel>) =>
                            _.isNil(item.risk.rotationTime)
                                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                : <TimeCell time={item.risk.rotationTime!}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.rotationTime()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            (filterMap, filtersTime) =>
                new Contract.RiskControllerAwsSecretsManagerSecretRotationDisabledRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(filtersTime, filterMap[TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(filtersTime, filterMap[TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskRotationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(filtersTime, filterMap[TableColumnId.AwsSecretsManagerSecretRotationDisabledRiskUsageTime])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}