import { Action0, Action1, useLocalization } from "@infrastructure";
import { Box, Radio, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Scope, useTheme } from "../../../../../../../../../common";

type NumericItemProps = {
    checked: boolean;
    disabled: boolean;
    disableRadio?: boolean;
    inheritedScopeId?: string;
    label: string;
    maxValue?: number;
    minValue?: number;
    onChange?: Action1<number>;
    onClick: Action0;
    title: string;
    translatedInheritedValue?: string;
    value?: number;
};

export function NumericItem({ checked, disabled, disableRadio = false, inheritedScopeId, label, maxValue, minValue, onChange, onClick, title, translatedInheritedValue, value }: NumericItemProps) {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.numeric.numericItem",
            () => ({
                inherit: "({{translatedInheritedValue}})"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            key={title}
            sx={{
                color:
                    disabled
                        ? theme.palette.text.disabled
                        : undefined
            }}
            onClick={onClick}>
            {!disableRadio &&
                <Radio
                    checked={checked}
                    disabled={disabled}
                    size="small"/>}
            {!disableRadio && title}
            {!_.isNil(onChange) &&
                <TextField
                    disabled={!checked || disabled}
                    label={label}
                    size="small"
                    slotProps={{
                        htmlInput: {
                            max: maxValue,
                            min: minValue
                        }
                    }}
                    sx={{
                        marginLeft:
                            disableRadio
                                ? undefined
                                : theme.spacing(1),
                        width: theme.spacing(16)
                    }}
                    type="number"
                    value={value}
                    variant="outlined"
                    onChange={
                        event => {
                            const newValue = _.parseInt(event.target.value);
                            if (!_.isNaN(newValue)) {
                                onChange(newValue);
                            }
                        }}/>}
            {!_.isNil(translatedInheritedValue) &&
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={0.5}
                    sx={{
                        color: theme.palette.text.secondary,
                        marginLeft: theme.spacing(1)
                    }}>
                    <Scope scopeId={inheritedScopeId!}/>
                    <Box sx={{ flex: 1 }}>
                        <Typography noWrap={true}>
                            {localization.inherit({ translatedInheritedValue })}
                        </Typography>
                    </Box>
                </Stack>}
        </Stack>);
}