import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore } from "../../../../../../..";
import { Entity } from "../../../../../..";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";

export function useAwsUpsertPrincipalManagedPolicyChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const upsertPrincipalManagedPolicyChange = change as Contract.AwsUpsertPrincipalManagedPolicyChange;
    const principalModel = entityModelStore.useGet(upsertPrincipalManagedPolicyChange.resourceId);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsUpsertPrincipalManagedPolicyChangeDefinition",
            () => ({
                create: {
                    false: "Edit managed policy **{{managedPolicyName}}**",
                    true: "Create a new managed policy **{{managedPolicyName}}** and attach it to {{translatedPrincipalTypeName}} **{{principal}}**"
                }
            }));
    return {
        title:
            localization.create[
                upsertPrincipalManagedPolicyChange.create
                    ? "true"
                    : "false"]({
                managedPolicyName: upsertPrincipalManagedPolicyChange.name,
                principal:
                    <Entity
                        entityIdOrModel={principalModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="text"/>,
                translatedPrincipalTypeName:
                    entityTypeNameTranslator(
                        principalModel.entity.typeName,
                        {
                            includeServiceName: false,
                            variant: "text"
                        })
            })
    };
}