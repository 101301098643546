import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InfraIpAddressIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M5.28505 15.9517C0.134573 10.1581 4.24808 1 12 1C19.752 1 23.8655 10.1581 18.715 15.9517L12.7474 22.6644C12.5576 22.8779 12.2856 23 12 23C11.7144 23 11.4424 22.8779 11.2527 22.6644L5.28505 15.9517ZM12 3C5.97334 3 2.77625 10.1195 6.77979 14.6229L12 20.4949L17.2203 14.6229C21.2238 10.1195 18.0267 3 12 3Z"
                fillRule="evenodd"/>
            <path d="M11.0706 14V7.45459H13.7744C14.2644 7.45459 14.6874 7.55047 15.0432 7.74223C15.4012 7.93186 15.6771 8.19713 15.871 8.53804C16.0649 8.87682 16.1618 9.271 16.1618 9.72057C16.1618 10.1723 16.0627 10.5675 15.8646 10.9063C15.6686 11.2429 15.3884 11.504 15.024 11.6893C14.6597 11.8747 14.2272 11.9674 13.7265 11.9674H12.0581V10.7209H13.4324C13.6711 10.7209 13.8703 10.6794 14.0301 10.5963C14.192 10.5132 14.3145 10.3971 14.3976 10.2479C14.4807 10.0966 14.5223 9.92085 14.5223 9.72057C14.5223 9.51816 14.4807 9.34344 14.3976 9.19642C14.3145 9.04727 14.192 8.93222 14.0301 8.85125C13.8681 8.77029 13.6689 8.7298 13.4324 8.7298H12.6526V14H11.0706Z"/>
            <path d="M10.0774 7.45459V14H8.49536V7.45459H10.0774Z"/>
        </SvgIcon>);
}