import { UnexpectedError } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { BehaviorRiskPolicyItem, CommonRiskPolicyItem, CustomRiskPolicyItem, KubernetesAdmissionControllerRiskPolicyItem, RiskPoliciesType, RiskPolicyItem, riskPolicyModelStore, RiskPolicyTypeMetadataHelper, scopeNodeModelStore } from "../../../../../../common";
import { RiskControllerGetRiskPoliciesResponseCloudBuiltInPolicy, RiskControllerGetRiskPoliciesResponseCloudCustomPolicy, RiskControllerGetRiskPoliciesResponseCloudPolicy, RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerBuiltInPolicy, RiskControllerGetRiskPoliciesResponsePolicy, RiskPolicyCategory, SecurityStats } from "../../../../../../common/controllers/types.generated";
import { useGetRiskAutomationRuleDeliveries } from "../useGetRiskAutomationRuleDeliveries";
import { useCloudRiskPoliciesItems, useCodeRiskPoliciesItems, useKubernetesAdmissionControllerRiskPoliciesItems } from "./hooks";

export function useItems(riskPoliciesType: RiskPoliciesType, childScopeCustomPoliciesEnabled: boolean, scopeId: string): RiskPolicyItem[] {
    const getRiskAutomationRuleDeliveries = useGetRiskAutomationRuleDeliveries(scopeId);
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeId].scopeIds,
            [scopeId, scopeNodeMap]);
    const customRiskPolicyModels = riskPolicyModelStore.useGetCustom();
    const customRiskPolicyModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customRiskPolicyModels,
                    customRiskPolicyModel => customRiskPolicyModel.riskPolicyConfiguration.id),
            [customRiskPolicyModels]);

    const useOperationStoreItems =
        useMemo(
            () => {
                switch (riskPoliciesType) {
                    case RiskPoliciesType.Cloud:
                        return useCloudRiskPoliciesItems;
                    case RiskPoliciesType.Code:
                        return useCodeRiskPoliciesItems;
                    case RiskPoliciesType.KubernetesAdmissionController:
                        return useKubernetesAdmissionControllerRiskPoliciesItems;
                    default:
                        throw new UnexpectedError("useItems.riskPoliciesType", riskPoliciesType);
                }
            },
            [riskPoliciesType, scopeId]);

    const {
        builtInRiskPolicyConfigurationTypeNameToStatsMap,
        policies,
        riskPolicyConfigurationTypeNameToLastViolationEventTimeMap,
        riskPolicyIdentifierToFailedEntityCountMap
    } = useOperationStoreItems(childScopeCustomPoliciesEnabled, scopeId) as OperationStoreItems;
    return useMemo(
        () =>
            _(policies).
                filter(
                    riskPolicy =>
                        RiskPolicyTypeMetadataHelper.get(riskPolicy.riskPolicyConfigurationTypeName).category !== RiskPolicyCategory.Custom ||
                        !_.isNil(customRiskPolicyModelMap[(riskPolicy as RiskControllerGetRiskPoliciesResponseCloudCustomPolicy).id])).
                map(
                    riskPolicy => {
                        if (RiskPolicyTypeMetadataHelper.get(riskPolicy.riskPolicyConfigurationTypeName).category === RiskPolicyCategory.Behavior) {
                            const builtInRiskPolicy = riskPolicy as RiskControllerGetRiskPoliciesResponseCloudBuiltInPolicy;
                            return new BehaviorRiskPolicyItem(
                                builtInRiskPolicy.customComplianceIds,
                                getRiskAutomationRuleDeliveries(builtInRiskPolicy.riskPolicyConfigurationTypeName),
                                builtInRiskPolicy.enabled,
                                builtInRiskPolicy.enabledInherited,
                                {
                                    failedTenantCount: (riskPolicy as RiskControllerGetRiskPoliciesResponseCloudPolicy).failedTenantCount,
                                    riskCount: (riskPolicy as RiskControllerGetRiskPoliciesResponseCloudPolicy).riskCount
                                },
                                builtInRiskPolicy.riskPolicyConfigurationTypeName);
                        }

                        if (RiskPolicyTypeMetadataHelper.get(riskPolicy.riskPolicyConfigurationTypeName).category === RiskPolicyCategory.Custom) {
                            const customRiskPolicy = (riskPolicy as RiskControllerGetRiskPoliciesResponseCloudCustomPolicy);
                            return new CustomRiskPolicyItem(
                                customRiskPolicy.customComplianceIds,
                                getRiskAutomationRuleDeliveries(customRiskPolicy.id),
                                customRiskPolicy.scopeId !== scopeId && !_.includes(childScopeIds, customRiskPolicy.scopeId),
                                {
                                    failedEntityCount: riskPolicyIdentifierToFailedEntityCountMap?.[customRiskPolicy.id],
                                    failedTenantCount: customRiskPolicy.failedTenantCount,
                                    riskCount: customRiskPolicy.riskCount
                                },
                                customRiskPolicyModelMap[customRiskPolicy.id],
                                customRiskPolicy.scopeId);
                        }

                        if (RiskPolicyTypeMetadataHelper.get(riskPolicy.riskPolicyConfigurationTypeName).category === RiskPolicyCategory.KubernetesAdmissionController) {
                            const kubernetesAdmissionControllerRiskPolicy = riskPolicy as RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerBuiltInPolicy;
                            return new KubernetesAdmissionControllerRiskPolicyItem(
                                [],
                                getRiskAutomationRuleDeliveries(riskPolicy.riskPolicyConfigurationTypeName),
                                kubernetesAdmissionControllerRiskPolicy.effect,
                                kubernetesAdmissionControllerRiskPolicy.enabled,
                                kubernetesAdmissionControllerRiskPolicy.enabledInherited,
                                riskPolicyConfigurationTypeNameToLastViolationEventTimeMap![kubernetesAdmissionControllerRiskPolicy.riskPolicyConfigurationTypeName],
                                kubernetesAdmissionControllerRiskPolicy.riskPolicyConfigurationTypeName);
                        }

                        const builtInRiskPolicy = riskPolicy as RiskControllerGetRiskPoliciesResponseCloudBuiltInPolicy;
                        return new CommonRiskPolicyItem(
                            builtInRiskPolicy.customComplianceIds,
                            getRiskAutomationRuleDeliveries(builtInRiskPolicy.riskPolicyConfigurationTypeName),
                            builtInRiskPolicy.enabled,
                            builtInRiskPolicy.enabledInherited,
                            {
                                failedEntityCount: riskPolicyIdentifierToFailedEntityCountMap?.[builtInRiskPolicy.riskPolicyConfigurationTypeName] ?? 0,
                                failedTenantCount: builtInRiskPolicy.failedTenantCount,
                                riskCount: builtInRiskPolicy.riskCount
                            },
                            builtInRiskPolicy.riskPolicyConfigurationTypeName,
                            builtInRiskPolicyConfigurationTypeNameToStatsMap[builtInRiskPolicy.riskPolicyConfigurationTypeName]);
                    }).
                value(),
        [builtInRiskPolicyConfigurationTypeNameToStatsMap, childScopeCustomPoliciesEnabled, childScopeIds, customRiskPolicyModelMap, getRiskAutomationRuleDeliveries, policies, riskPolicyIdentifierToFailedEntityCountMap, scopeId]);
}

type OperationStoreItems = {
    builtInRiskPolicyConfigurationTypeNameToStatsMap: Dictionary<SecurityStats>;
    policies: RiskControllerGetRiskPoliciesResponsePolicy[];
    riskPolicyConfigurationTypeNameToLastViolationEventTimeMap?: Dictionary<string>;
    riskPolicyIdentifierToFailedEntityCountMap?: Dictionary<number>;
};