import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../controllers";
import { useTheme } from "../../../../../themes";

export function PhiIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
            <path
                clipRule="evenodd"
                d="M13.7254 1.00151C14.2157 0.974716 14.6529 1.30781 14.7572 1.78759L17.086 12.5H22.55C23.1023 12.5 23.55 12.9477 23.55 13.5C23.55 14.0523 23.1023 14.5 22.55 14.5H16.28C15.8096 14.5 15.4028 14.1721 15.3028 13.7124L14.1236 8.28811L12.7745 21.1047C12.7246 21.5791 12.3465 21.9522 11.8715 21.9958C11.3964 22.0395 10.9567 21.7415 10.8212 21.2841L7.54101 10.2138L5.67421 13.9472C5.50481 14.286 5.15855 14.5 4.77979 14.5H1C0.447715 14.5 0 14.0523 0 13.5C0 12.9477 0.447715 12.5 1 12.5H4.16176L6.88547 7.05279C7.0691 6.68554 7.45856 6.46786 7.86758 6.50387C8.2766 6.53987 8.62204 6.82224 8.73869 7.21592L11.3114 15.8988L12.7855 1.89533C12.8369 1.40704 13.2352 1.0283 13.7254 1.00151Z"
                fill={theme.palette.data.category(Contract.DataCategory.Phi)}
                fillRule="evenodd"/>
        </SvgIcon>);
}