import { Function0 } from "lodash";
import { DependencyList, useEffect, useState } from "react";

export function useAsyncEffect(effect: Function0<Promise<void>>, dependencies?: DependencyList) {
    const [, setError] = useState();
    useEffect(
        () => {
            effect().catch(error => setError(() => { throw error; }));
        },
        dependencies);
}