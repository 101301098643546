import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useOciContainerEngineClusterNodePoolStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciContainerEngineClusterNodePoolDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const entitiesExportOptions = useEntitiesExportOptions();

    const clusterNodePoolStatusTranslator = useOciContainerEngineClusterNodePoolStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciContainerEngineClusterNodePoolDefinition",
            () => ({
                columns: {
                    cluster: "Cluster",
                    instance: "Nodes",
                    metadataServiceVersion1Enabled: {
                        false: "Version 2 only",
                        title: "Instance Metadata Service",
                        true: "Versions 1 and 2"
                    },
                    status: "Status",
                    volumeEncryptionInTransitEnabled: {
                        false: "Disabled",
                        title: "In-Transit Encryption",
                        true: "Enabled"
                    }
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciContainerEngineClusterNodePoolModel) => ({
                            [localization.columns.status()]: clusterNodePoolStatusTranslator((item.entity as Contract.OciContainerEngineClusterNodePool).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciContainerEngineClusterNodePoolStatus}
                                enumTypeTranslator={clusterNodePoolStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolStatus}
                itemProperty={(item: Contract.OciContainerEngineClusterNodePoolModel) => clusterNodePoolStatusTranslator((item.entity as Contract.OciContainerEngineClusterNodePool).status)}
                key={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciContainerEngineClusterNodePoolModel) => ({
                            [localization.columns.cluster()]: (item.entity as Contract.OciContainerEngineClusterNodePool).clusterOcid
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciContainerEngineClusterNodePoolCluster)}
                                placeholder={localization.columns.cluster()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolCluster}
                key={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolCluster}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciContainerEngineClusterNodePoolModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.clusterIdReference}
                            entityTypeName={Contract.TypeNames.OciContainerEngineCluster}/>}
                title={localization.columns.cluster()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciContainerEngineClusterNodePoolModel>(
                        Contract.TypeNames.OciComputeInstance,
                        item => item.instanceIdReferences,
                        localization.columns.instance())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciContainerEngineClusterNodePoolInstances)}
                                placeholder={localization.columns.instance()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolInstances}
                key={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciContainerEngineClusterNodePoolModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIdReferences}
                            entityTypeName={Contract.TypeNames.OciComputeInstance}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.instance()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciContainerEngineClusterNodePoolModel) => ({
                            [localization.columns.volumeEncryptionInTransitEnabled.title()]:
                                (item.entity as Contract.OciContainerEngineClusterNodePool).node.volumeEncryptionInTransitEnabled
                                    ? localization.columns.volumeEncryptionInTransitEnabled.true()
                                    : localization.columns.volumeEncryptionInTransitEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.volumeEncryptionInTransitEnabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.volumeEncryptionInTransitEnabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.volumeEncryptionInTransitEnabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolVolumeEncryptionInTransitEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciContainerEngineClusterNodePool).node.volumeEncryptionInTransitEnabled
                            ? localization.columns.volumeEncryptionInTransitEnabled.true()
                            : localization.columns.volumeEncryptionInTransitEnabled.false()}
                key={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolVolumeEncryptionInTransitEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.volumeEncryptionInTransitEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciContainerEngineClusterNodePoolModel) => ({
                            [localization.columns.metadataServiceVersion1Enabled.title()]:
                                (item.entity as Contract.OciContainerEngineClusterNodePool).node.metadataServiceVersion1Enabled
                                    ? localization.columns.metadataServiceVersion1Enabled.true()
                                    : localization.columns.metadataServiceVersion1Enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.metadataServiceVersion1Enabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.metadataServiceVersion1Enabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.metadataServiceVersion1Enabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolMetadataServiceVersion1Enabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciContainerEngineClusterNodePool).node.metadataServiceVersion1Enabled
                            ? localization.columns.metadataServiceVersion1Enabled.true()
                            : localization.columns.metadataServiceVersion1Enabled.false()}
                key={Contract.EntityModelProperty.OciContainerEngineClusterNodePoolMetadataServiceVersion1Enabled}
                selectorOptions={{ default: false }}
                title={localization.columns.metadataServiceVersion1Enabled.title()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciContainerEngineClusterNodePoolRequest(
                new Contract.EntityControllerGetEntityModelPageOciContainerEngineClusterNodePoolRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciContainerEngineClusterNodePoolCluster]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciContainerEngineClusterNodePoolInstances]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciContainerEngineClusterNodePoolMetadataServiceVersion1Enabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciContainerEngineClusterNodePoolStatus]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciContainerEngineClusterNodePoolVolumeEncryptionInTransitEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}