import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterApiServerInsecureListenerEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterApiServerInsecureListenerEnabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.apiServers.useKubernetesClusterApiServerInsecureListenerEnabledRiskDefinition",
            () => ({
                description: "{{cluster}} API server is configured with insecure settings",
                resolutionSection: {
                    step1: "Edit the API server manifest file (typically located in **/etc/kubernetes/manifests/kube-apiserver.yaml**)",
                    step2: "Ensure that the **--insecure-bind-address** argument is removed or explicitly set to **127.0.0.1** in the API server configuration file or startup parameters",
                    step3: "Ensure that the **--secure-port** argument is not set to **0**. Instead, set it to a valid port number (e.g., **6443**) to enable secure communication",
                    step4: "Ensure that the **bind address** argument is set to a secure and restricted network interface",
                    step5: "Restart the API server to apply the changes"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3(),
            localization.resolutionSection.step4(),
            localization.resolutionSection.step5()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}