import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, entityModelStore, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureNetworkPublicIpPrefixDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const publicIpPrefix = resourceGroupResourceModel.entity as Contract.AzureNetworkPublicIpPrefix;
    const publicIpAddressModels = entityModelStore.useGet(publicIpPrefix.publicIpAddressIds);
    const publicIpAddressDnsNames =
        _(publicIpAddressModels).
            map(entityModel => (entityModel.entity as Contract.AzureNetworkPublicIpAddress).dnsName).
            filter().
            value();

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkPublicIpPrefixDefinition",
            () => ({
                info: {
                    items: {
                        publicIpAddressDnsNames: "DNS Names",
                        publicIpAddressIds: "Public IP Addresses",
                        subnet: "IP Prefix"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="subnet"
                        title={localization.info.items.subnet()}
                        value={publicIpPrefix.subnet}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={publicIpPrefix.publicIpAddressIds}
                        entityTypeName={Contract.TypeNames.AzureNetworkPublicIpAddress}
                        key="publicIpAddressIds"
                        title={localization.info.items.publicIpAddressIds()}/>,
                    <VerticalTopItemsInfoItem
                        items={publicIpAddressDnsNames}
                        key="publicIpAddressDnsNames"
                        title={localization.info.items.publicIpAddressDnsNames()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}