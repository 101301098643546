import { ContextMenuItem, DataTableSortType, defined, MessageLevel, Optional, WithRequired } from "@infrastructure";
import { SxProps, Theme } from "@mui/material";
import { Dictionary } from "lodash";
import React, { Fragment, ReactNode } from "react";

export type DataTableColumnProps = {
    cellMaxWidth?: "large" | "medium" | "none" | "small";
    cellMinWidth?: number;
    cellSx?: SxProps<Theme>;
    disableAction?: boolean;
    exportOptions?: DataTableColumnExportOptions;
    filterOptions?: Partial<FilterOptions>;
    getContextMenuItems?: (item: any) => Optional<ContextMenuItem[]> | Promise<Optional<ContextMenuItem[]>>;
    headerSx?: SxProps<Theme>;
    id: string;
    itemProperty?: (item: any) => any;
    message?: ReactNode;
    messageLevel?: MessageLevel;
    orderable?: boolean;
    render?: React.FunctionComponent<DataTableColumnRenderProps<any>>;
    resizable?: boolean;
    selectorOptions?: DataTableColumnSelectorOptions;
    sortOptions?: DataTableColumnSortOptions;
    title?: string;
    titleElement?: ReactNode;
    tooltip?: string;
} & ({
    filterOptions?: never;
    title?: string;
} | {
    filterOptions: Omit<FilterOptions, "toggleItem">;
    title: string;
} | {
    filterOptions: Pick<FilterOptions, "toggleItem">;
});

type FilterOptionItem = {
    default?: boolean;
    element: ReactNode;
    id?: string;
    title?: string;
};

export type FilterOptions = {
    externalId?: string;
    itemOrItems?: FilterOptionItem | WithRequired<FilterOptionItem, "title" | "id">[];
    toggleItem?: ReactNode;
} & ({
    externalId: string;
    itemOrItems?: never;
    toggleItem?: never;
} | {
    externalId?: never;
    itemOrItems?: FilterOptionItem | WithRequired<FilterOptionItem, "title" | "id">[];
    toggleItem?: ReactNode;
} | {
    externalId?: never;
    itemOrItems?: never;
    toggleItem: ReactNode;
});

export type DataTableColumnExportOptions = {
    getData?: (item: any) => any;
    getItem?: (item: any, data: any) => Dictionary<any>;
};

export type DataTableColumnSelectorOptions = {
    default?: boolean;
    disabled?: boolean;
};

type DataTableColumnSortOptions = {
    enabled?: boolean;
    type?: DataTableSortType;
};

export type DataTableColumnRenderProps<TItem> = {
    item: TItem;
};

export function DataTableColumn(props: DataTableColumnProps) {
    defined(props);
    return <Fragment/>;
}