import { InfoBox, InfoIcon, InlineItems, Link, Steps, SuccessIcon, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Link as MuiLink, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, riskPolicyModelStore, tenantModelStore, useCloudProviderTenantPermissionTypesTranslator, UserHelper } from "../../../../../../../../../../../common";
import { useAddOrEditContext } from "../index";

export function FinishItem() {
    const cloudProviderTenantPermissionTypesTranslator = useCloudProviderTenantPermissionTypesTranslator();
    const { useNextEffect } = useOrderedWizardContext();
    const { tenantModel, tenantName, tenantPermissionTypes, tenantRawId, tenantTrails, updatedTenantModel, validErrorValidateAwsTenantTrailBucketResponses } = useAddOrEditContext();

    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();

    useNextEffect(
        async () => {
            await tenantModelStore.notify(updatedTenantModel!);
            await riskPolicyModelStore.notify();
            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.finishItem",
            () => ({
                clickHere: "click here",
                error: {
                    noTrail: {
                        description: "Account without a CloudTrail will have some of the functionality disabled. To read more about what is the benefit from analyzing CloudTrail data, {{clickHere}}",
                        title: "Missing CloudTrail"
                    },
                    trailValidErrors: {
                        buckets: {
                            accessDenied: {
                                multiple: "**{{bucketNames}}** located at **{{tenantName}}** ({{tenantRawId}}), {{clickHere}} to modify the permissions to allow access to S3",
                                single: "Bucket **{{bucketName}}** located at **{{tenantName}}** ({{tenantRawId}}), {{clickHere}} to modify the permissions to allow access to S3"
                            },
                            accessDeniedNoPermissions: {
                                multiple: "**{{bucketNames}}** located at **{{tenantName}}** ({{tenantRawId}}), you do not have permission to modify this account",
                                single: "Bucket **{{bucketName}}** located at **{{tenantName}}** ({{tenantRawId}}), you do not have permission to modify this account"
                            },
                            description: "One or more of the selected S3 buckets isn't accessible due to missing permissions or encryption issues.",
                            namePluralizer: [
                                "1 bucket",
                                "{{count | NumberFormatter.humanize}} buckets"
                            ],
                            tenantNotFound: "Bucket **{{bucketName}}**, please onboard the account containing the bucket",
                            title: "Tenable can't read CloudTrail events"
                        },
                        learnMore: {
                            link: "Learn more",
                            text: "{{learnMore}} about the benefits of granting CloudTrail access.\n Note that you can also grant access later on."
                        }
                    }
                },
                organization: "To proceed with onboarding the entire AWS Organization {{clickHere}}",
                permissionTypes: "**Identified the following permissions**",
                subtitle: {
                    audit: "We’re analyzing CloudTrail logs, and you'll be able to see it soon",
                    docsLink: "Learn more",
                    monitored: "We're synchronizing your data now, and you'll be able to see it soon. {{docsLink}}"
                },
                title: {
                    add: "Account {{tenantName}} ({{tenantRawId}}) was added successfully!",
                    edit: "Account {{tenantName}} ({{tenantRawId}}) was changed successfully!"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={6}
            sx={{ height: "100%" }}>
            <Stack
                alignItems="center"
                spacing={2}>
                <Typography
                    align="center"
                    sx={{ fontWeight: 400 }}
                    variant="h2">
                    {_.isNil(tenantModel)
                        ? localization.title.add({ tenantName, tenantRawId })
                        : localization.title.edit({ tenantName, tenantRawId })}
                </Typography>
                {_.isNil(tenantModel) &&
                    <Typography variant="h3">
                        {_.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.Read)
                            ? localization.subtitle.monitored({
                                docsLink:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                                        variant="external">
                                        {localization.subtitle.docsLink()}
                                    </Link>
                            })
                            : localization.subtitle.audit()}
                    </Typography>}
            </Stack>
            <Stack
                spacing={3}
                sx={{ width: theme.spacing(70) }}>
                {!updatedTenantModel!.configuration.audit &&
                    <InfoBox
                        iconElement={
                            <SuccessIcon
                                sx={{
                                    color: theme.palette.success.main,
                                    fontSize: "18px"
                                }}/>}
                        title={localization.permissionTypes()}>
                        <Steps>
                            {cloudProviderTenantPermissionTypesTranslator.multiple(
                                updatedTenantModel!.state.permissionTypes,
                                "longName")}
                        </Steps>
                    </InfoBox>}
                {!updatedTenantModel!.configuration.audit &&
                    _.isEmpty(tenantTrails) &&
                    <InfoBox
                        iconElement={
                            <SuccessIcon
                                sx={{
                                    color: theme.palette.warning.main,
                                    fontSize: "18px"
                                }}/>}
                        title={localization.error.noTrail.title()}>
                        <Typography>
                            {localization.error.noTrail.description({
                                clickHere:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsCloudtrailLogsRelativeUrl()}
                                        variant="external">
                                        {localization.clickHere()}
                                    </Link>
                            })}
                        </Typography>
                    </InfoBox>}
                {!_.isEmpty(validErrorValidateAwsTenantTrailBucketResponses) &&
                    <InfoBox
                        iconElement={
                            <InfoIcon
                                sx={{
                                    color: theme.palette.warning.main,
                                    fontSize: "18px"
                                }}/>}
                        title={localization.error.trailValidErrors.buckets.title()}>
                        <Stack spacing={1}>
                            <Typography>
                                {localization.error.trailValidErrors.buckets.description()}
                            </Typography>
                            <Steps>
                                {_(validErrorValidateAwsTenantTrailBucketResponses).
                                    filter(validErrorValidateAwsTenantTrailBucketResponse => _.isNil(validErrorValidateAwsTenantTrailBucketResponse.trailBucketTenantId)).
                                    map(
                                        validErrorValidateAwsTenantTrailBucketResponse =>
                                            localization.error.trailValidErrors.buckets.tenantNotFound({
                                                bucketName: validErrorValidateAwsTenantTrailBucketResponse.trailBucketName
                                            })).
                                    concat(
                                        _(validErrorValidateAwsTenantTrailBucketResponses).
                                            filter(validErrorValidateAwsTenantTrailBucketResponse => !_.isNil(validErrorValidateAwsTenantTrailBucketResponse.trailBucketTenantId)).
                                            groupBy(validErrorValidateAwsTenantTrailBucketResponse => validErrorValidateAwsTenantTrailBucketResponse.trailBucketTenantId).
                                            flatMap(
                                                (validErrorValidateAwsTenantTrailBucketResponses, trailBucketTenantId) => {
                                                    const trailBucketTenantConfiguration =
                                                        trailBucketTenantId === updatedTenantModel!.configuration.id
                                                            ? updatedTenantModel!.configuration
                                                            : tenantModelMap[trailBucketTenantId!].configuration;

                                                    const bucketNames =
                                                        _(validErrorValidateAwsTenantTrailBucketResponses).
                                                            map(validErrorValidateAwsTenantTrailBucketResponse => validErrorValidateAwsTenantTrailBucketResponse.trailBucketName).
                                                            uniq().
                                                            value();

                                                    if (trailBucketTenantId === updatedTenantModel!.configuration.id ||
                                                        UserHelper.hasScopePermissions(trailBucketTenantId!, Contract.IdentityPermission.SecurityAdministrationRead)) {
                                                        const tenantsRelativeUrl =
                                                            CustomerConsoleAppUrlHelper.getScopesRelativeUrl(
                                                                Contract.TenantType.Aws,
                                                                {
                                                                    addOrEdit: trailBucketTenantId,
                                                                    addOrEditStepIndex: 2
                                                                });
                                                        return (_.size(bucketNames) == 1
                                                            ? localization.error.trailValidErrors.buckets.accessDenied.single
                                                            : localization.error.trailValidErrors.buckets.accessDenied.multiple)({
                                                            bucketName: bucketNames[0],
                                                            bucketNames:
                                                                <InlineItems
                                                                    items={bucketNames}
                                                                    namePluralizer={localization.error.trailValidErrors.buckets.namePluralizer}
                                                                    variant="itemCountAndType"/>,
                                                            clickHere:
                                                                trailBucketTenantId === updatedTenantModel!.configuration.id
                                                                    ? <MuiLink
                                                                        href={tenantsRelativeUrl}
                                                                        underline="hover">
                                                                        {localization.clickHere()}
                                                                    </MuiLink>
                                                                    : <Link
                                                                        urlOrGetUrl={tenantsRelativeUrl}
                                                                        variant="external">
                                                                        {localization.clickHere()}
                                                                    </Link>,
                                                            tenantName: trailBucketTenantConfiguration.name,
                                                            tenantRawId: trailBucketTenantConfiguration.rawId
                                                        });
                                                    }

                                                    return (_.size(bucketNames) == 1
                                                        ? localization.error.trailValidErrors.buckets.accessDeniedNoPermissions.single
                                                        : localization.error.trailValidErrors.buckets.accessDeniedNoPermissions.multiple)({
                                                        bucketName: bucketNames[0],
                                                        bucketNames:
                                                            <InlineItems
                                                                items={bucketNames}
                                                                namePluralizer={localization.error.trailValidErrors.buckets.namePluralizer}
                                                                variant="itemCountAndType"/>,
                                                        tenantName: trailBucketTenantConfiguration.name,
                                                        tenantRawId: trailBucketTenantConfiguration.rawId
                                                    });
                                                }).
                                            value()).
                                    value()}
                            </Steps>
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                {localization.error.trailValidErrors.learnMore.text({
                                    learnMore:
                                        <Link
                                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsCloudtrailLogsRelativeUrl()}
                                            variant="external">
                                            {localization.error.trailValidErrors.learnMore.link()}
                                        </Link>
                                })}
                            </Typography>
                        </Stack>
                    </InfoBox>}
                {updatedTenantModel?.master == true &&
                    _.isNil(updatedTenantModel.configuration.organizationId) &&
                    <InfoBox
                        iconElement={
                            <InfoIcon
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontSize: "18px"
                                }}/>}
                        title={
                            localization.organization({
                                clickHere:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsAwsRelativeUrl(true)}
                                        variant="external">
                                        {localization.clickHere()}
                                    </Link>
                            })}/>}
            </Stack>
        </Stack>);
}