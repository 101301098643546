import { Box, Stack, Typography } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { BreakpointResizer, EmptyMessage, ItemSelector, NumberFormatter, useLocalization } from "@infrastructure";
import { WidgetDefinition } from "../../../../";
import { Contract } from "../../../../../../../controllers";
import { useTheme } from "../../../../../../../themes";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../utilities";
import { DistributionChart, DistributionChartItem } from "../../../../../../DistributionChart";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { OperatingSystemTypeSelector } from "../../../utilities";
import { WorkloadAnalysisItem, WorkloadAnalysisWidgetItem } from "../components";

export function useGetWorkloadAnalysisRiskDistributionDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisRiskDistributionDefinition",
            () => ({
                helpText: "View a breakdown of machines by the status of the installed OS. Such issues can indicate that workload security is compromised, and should be remediated by updating the OS.",
                title: "Operating System Status"
            }));

    const [operatingSystemTypeSelector, setOperatingSystemTypeSelector] = useState<OperatingSystemTypeSelector>(OperatingSystemTypeSelector.All);

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <WorkloadAnalysisRiskDistribution operatingSystemTypeSelector={operatingSystemTypeSelector}/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                topElement:
                    <WorkloadAnalysisRiskDistributionTopElement
                        operatingSystemTypeSelector={operatingSystemTypeSelector}
                        setOperatingSystemTypeSelector={setOperatingSystemTypeSelector}/>
            }
        }),
        [localization, operatingSystemTypeSelector]);
}

type WorkloadAnalysisRiskDistributionProps = {
    operatingSystemTypeSelector: OperatingSystemTypeSelector;
};

function WorkloadAnalysisRiskDistribution({ operatingSystemTypeSelector }: WorkloadAnalysisRiskDistributionProps) {
    const { summary } = useDashboardContext<SummaryDashboardContext>();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisRiskDistributionDefinition.workloadAnalysisRiskDistribution",
            () => ({
                chart: {
                    subtitle: "Virtual Machines"
                },
                empty: "No Virtual Machines Scanned",
                [Contract.TypeNames.OperatingSystemRiskPolicyType]: {
                    [Contract.OperatingSystemRiskPolicyType.EndOfLife]: "*bold*At or Nearing End-of-Life**",
                    [Contract.OperatingSystemRiskPolicyType.Unpatched]: "*bold*Unpatched**",
                    [Contract.OperatingSystemRiskPolicyType.Secured]: "*bold*Operating System OK**"
                },
                tooltipSummary: "*bold*{{workloadCount}}** Machines ({{percentage}})"
            }));

    const operatingSystemRiskPolicyTypeToConfigurationTypeNamesMap =
        useMemo(
            () => ({
                [Contract.OperatingSystemRiskPolicyType.EndOfLife]: [Contract.TypeNames.VirtualMachineOperatingSystemEndOfLifeRiskPolicyConfiguration],
                [Contract.OperatingSystemRiskPolicyType.Secured]: undefined,
                [Contract.OperatingSystemRiskPolicyType.Unpatched]: [Contract.TypeNames.VirtualMachineOperatingSystemUnpatchedRiskPolicyConfiguration]
            }),
            []);

    const operatingSystemTypeToRiskPolicyTypeToCountMap = summary.workloadAnalysisData!.operatingSystemTypeToRiskPolicyTypeToVirtualMachineCountMap;
    const [operationSystemCardItems, virtualMachineCount] =
        useMemo(
            () => {
                const operatingSystemType =
                    operatingSystemTypeSelector === OperatingSystemTypeSelector.Linux
                        ? Contract.OperatingSystemType.Linux
                        : Contract.OperatingSystemType.Windows;
                const riskPolicyTypeToCountMap =
                    operatingSystemTypeSelector === OperatingSystemTypeSelector.All
                        ? _.mergeWith(
                            {},
                            operatingSystemTypeToRiskPolicyTypeToCountMap[Contract.OperatingSystemType.Linux],
                            operatingSystemTypeToRiskPolicyTypeToCountMap[Contract.OperatingSystemType.Windows],
                            (count: number, otherCount: number) => _.sum([(count ?? 0), (otherCount ?? 0)]))
                        : operatingSystemTypeToRiskPolicyTypeToCountMap[operatingSystemType];
                const virtualMachineCount =
                    _(riskPolicyTypeToCountMap).
                        values().
                        sum();

                return [
                    _([
                        Contract.OperatingSystemRiskPolicyType.EndOfLife,
                        Contract.OperatingSystemRiskPolicyType.Unpatched,
                        Contract.OperatingSystemRiskPolicyType.Secured
                    ]).
                        filter(OperatingSystemRiskPolicyType => riskPolicyTypeToCountMap[OperatingSystemRiskPolicyType] > 0).
                        map(
                            OperatingSystemRiskPolicyType => {
                                const count = riskPolicyTypeToCountMap[OperatingSystemRiskPolicyType];
                                return new WorkloadAnalysisWidgetItem(
                                    count,
                                    NumberFormatter.percentage(count / virtualMachineCount),
                                    {
                                        [Contract.OperatingSystemRiskPolicyType.EndOfLife]: Contract.Severity.Critical,
                                        [Contract.OperatingSystemRiskPolicyType.Unpatched]: Contract.Severity.Medium,
                                        [Contract.OperatingSystemRiskPolicyType.Secured]: undefined
                                    }[OperatingSystemRiskPolicyType] ?? "None",
                                    localization[Contract.TypeNames.OperatingSystemRiskPolicyType][OperatingSystemRiskPolicyType](),
                                    OperatingSystemRiskPolicyType === Contract.OperatingSystemRiskPolicyType.Secured
                                        ? undefined
                                        : CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                                            Contract.RisksView.Open,
                                            {
                                                policyConfigurationTypeNameOrIds: operatingSystemRiskPolicyTypeToConfigurationTypeNamesMap[OperatingSystemRiskPolicyType],
                                                riskPolicyCategory: Contract.RiskPolicyCategory.WorkloadAnalysis
                                            }));
                            }).
                        value(),
                    virtualMachineCount];

            },
            [operatingSystemTypeToRiskPolicyTypeToCountMap, operatingSystemTypeSelector]);

    const [activeRiskDataSeverity, setActiveRiskDataSeverity] = useState<string>();
    const [breakpoint, setBreakpoint] = useState(0);
    const theme = useTheme();
    return (
        virtualMachineCount === 0
            ? <EmptyMessage
                message={localization.empty()}
                verticalCenter={true}/>
            : <Fragment>
                <BreakpointResizer
                    breakpointXs={[breakpointX]}
                    onSize={setBreakpoint}/>
                <Stack
                    alignItems="center"
                    direction="row"
                    flexWrap="nowrap"
                    justifyContent="center">
                    <Stack
                        alignItems="flex-start"
                        spacing={4}
                        sx={{
                            flex: 1,
                            height: "100%",
                            overflow: "hidden",
                            paddingTop: theme.spacing(2)
                        }}>
                        {_(operationSystemCardItems).
                            map(
                                operatingSystemStatusCardItem =>
                                    <Box
                                        className="operatingSystemStatusCardItem"
                                        key={operatingSystemStatusCardItem.severity}
                                        sx={{
                                            flex: 1,
                                            minWidth: theme.px(60),
                                            width: "100%"
                                        }}
                                        onMouseEnter={() => setActiveRiskDataSeverity(operatingSystemStatusCardItem.severity)}
                                        onMouseLeave={() => setActiveRiskDataSeverity(undefined)}>
                                        <WorkloadAnalysisItem
                                            highlight={operatingSystemStatusCardItem.severity === activeRiskDataSeverity}
                                            item={operatingSystemStatusCardItem}/>
                                    </Box>).
                            value()}
                    </Stack>
                    <Stack
                        alignItems="flex-end"
                        sx={{ paddingTop: theme.spacing(5) }}>
                        <DistributionChart
                            activeItemId={activeRiskDataSeverity}
                            items={
                                _.map(
                                    operationSystemCardItems,
                                    item =>
                                        new DistributionChartItem(
                                            item.severity === "None"
                                                ? "#40B99E"
                                                : theme.palette.severity(item.severity),
                                            item.severity,
                                            <Stack>
                                                <Typography>
                                                    {localization.tooltipSummary({ percentage: item.percentage, workloadCount: item.count })}
                                                </Typography>
                                            </Stack>,
                                            item.count,
                                            item.url))}
                            size={
                                breakpoint === 0
                                    ? "medium"
                                    : "xl"}
                            subtitle={localization.chart.subtitle()}
                            title={NumberFormatter.humanize(virtualMachineCount)}
                            titleUrl=
                                {
                                    CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                                        Contract.RisksView.Open,
                                        {
                                            policyConfigurationTypeNameOrIds:
                                        operatingSystemRiskPolicyTypeToConfigurationTypeNamesMap[Contract.OperatingSystemRiskPolicyType.EndOfLife].
                                            concat(operatingSystemRiskPolicyTypeToConfigurationTypeNamesMap[Contract.OperatingSystemRiskPolicyType.Unpatched]),
                                            riskPolicyCategory: Contract.RiskPolicyCategory.WorkloadAnalysis
                                        })}
                            variant="donut"
                            onActiveItemIdChanged={severity => setActiveRiskDataSeverity(severity as Contract.Severity | "None")}/>
                    </Stack>
                </Stack>
            </Fragment>);
}

type WorkloadAnalysisRiskDistributionTopElementProps = {
    operatingSystemTypeSelector: OperatingSystemTypeSelector;
    setOperatingSystemTypeSelector: React.Dispatch<React.SetStateAction<OperatingSystemTypeSelector>>;
};

function WorkloadAnalysisRiskDistributionTopElement({ operatingSystemTypeSelector, setOperatingSystemTypeSelector }: WorkloadAnalysisRiskDistributionTopElementProps) {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisRiskDistributionDefinition.workloadAnalysisRiskDistributionTopElement",
            () => ({
                operatingSystemTypeSelector: {
                    [OperatingSystemTypeSelector.All]: "All OSs",
                    [OperatingSystemTypeSelector.Linux]: "Linux",
                    [OperatingSystemTypeSelector.Windows]: "Windows"
                }
            }));
    return (
        <ItemSelector
            dense={true}
            items={[
                OperatingSystemTypeSelector.All,
                OperatingSystemTypeSelector.Linux,
                OperatingSystemTypeSelector.Windows
            ]}
            selectedItem={operatingSystemTypeSelector}
            sorted={false}
            onSelectedItemChanged={setOperatingSystemTypeSelector}>
            {(operatingSystemTypeSelector: OperatingSystemTypeSelector) => localization.operatingSystemTypeSelector[operatingSystemTypeSelector]()}
        </ItemSelector>);
}

const breakpointX = 338;