﻿import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { useLocalization } from "@infrastructure";
import { Contract, ScopeHelper, ScopeNode, scopeSystemEntityModelStore, TenantsTenantType, TypeHelper, UserHelper } from "../../../../../common";

export function useMoveEnabled(scopeIds: string[], scopeNodeMap: Dictionary<ScopeNode>, parentScopeNodeModel: Contract.ScopeNodeModel) {
    const ciOrganizationModels = scopeSystemEntityModelStore.useGetCiIntegration();
    const cloudProviderTenantOrganizationModels = scopeSystemEntityModelStore.useGetCloudProviderTenantOrganization();
    const codeOrganizationModels = scopeSystemEntityModelStore.useGetCodeOrganization();

    const scopeTenantType = ScopeHelper.getTenantType(parentScopeNodeModel) as TenantsTenantType;

    const ciOrganizationModelMap =
        useMemo(
            () =>
                _.keyBy(
                    ciOrganizationModels,
                    ciOrganizationModels => ciOrganizationModels.configuration.id),
            [ciOrganizationModels]);
    const cloudProviderTenantOrganizationModelMap =
        useMemo(
            () =>
                _.keyBy(
                    cloudProviderTenantOrganizationModels,
                    cloudProviderTenantOrganizationModel => cloudProviderTenantOrganizationModel.configuration.id),
            [cloudProviderTenantOrganizationModels]);
    const codeOrganizationModelMap =
        useMemo(
            () =>
                _.keyBy(
                    codeOrganizationModels,
                    codeOrganizationModel => codeOrganizationModel.configuration.id),
            [codeOrganizationModels]);
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useMoveEnabled",
            () => ({
                organizationExists: "The selected {{tenantTypeName}}/s or folder/s belong/s to an organization which is configured to automatically update folder structure, and can't be moved",
                organizationExistsSelection: "Some/All of the selected {{tenantTypeName}}/s and/or folders belong/s to an organization which is configured to automatically update folder structure, and can't be moved",
                [Contract.TypeNames.TenantType]: {
                    [Contract.TenantType.Aws]: "account",
                    [Contract.TenantType.Azure]: "subscription",
                    [Contract.TenantType.Ci]: "registry",
                    [Contract.TenantType.Code]: "repository",
                    [Contract.TenantType.Gcp]: "project",
                    [Contract.TenantType.Oci]: "compartment",
                    [Contract.TenantType.Op]: "account"
                }
            }));

    return useMemo(
        () => {
            if (_.some(
                scopeIds,
                scopeId =>
                    scopeNodeMap[scopeId]?.scopeNodeModel.configuration.systemDeleted ||
                    !UserHelper.hasScopePermissions(
                        scopeId,
                        Contract.IdentityPermission.SecurityAdministrationRead))) {
                return { enabled: false };
            }

            if (_.some(
                scopeIds,
                scopeId => {
                    const currentScopeNodeModel = scopeNodeMap[scopeId]?.scopeNodeModel;
                    if (_.isNil(currentScopeNodeModel)) {
                        return true;
                    }

                    if (ScopeHelper.isFolder(currentScopeNodeModel) &&
                        (currentScopeNodeModel.configuration as Contract.FolderConfiguration).managed) {
                        return true;
                    }

                    if ((currentScopeNodeModel.configuration as Contract.CloudProviderTenantConfiguration | Contract.CloudProviderTenantFolderConfiguration)?.organizationExists) {
                        const organizationId =
                            ScopeHelper.isFolder(currentScopeNodeModel)
                                ? (currentScopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration).organization!.id
                                : (currentScopeNodeModel.configuration as Contract.CloudProviderTenantConfiguration).organizationId!;
                        return (cloudProviderTenantOrganizationModelMap[organizationId].configuration as Contract.CloudProviderTenantOrganizationConfiguration).folderEnabled;
                    }

                    if (TypeHelper.extendOrImplement(currentScopeNodeModel.configuration.typeName, Contract.TypeNames.GitTenantConfiguration) &&
                        !_.isNil((currentScopeNodeModel.configuration as Contract.GitTenantConfiguration)?.organizationId)) {
                        const organizationId = (currentScopeNodeModel.configuration as Contract.GitTenantConfiguration)?.organizationId;
                        return (codeOrganizationModelMap[organizationId].configuration as Contract.CodeOrganizationConfiguration).folderEnabled;
                    }

                    if (TypeHelper.extendOrImplement(currentScopeNodeModel.configuration.typeName, Contract.TypeNames.CiTenantConfiguration) &&
                        !_.isNil((currentScopeNodeModel.configuration as Contract.CiTenantConfiguration)?.organizationId)) {
                        const organizationId = (currentScopeNodeModel.configuration as Contract.CiTenantConfiguration)?.organizationId;
                        return (ciOrganizationModelMap[organizationId!].configuration as Contract.CiIntegrationConfiguration).folderEnabled;
                    }

                    return false;
                })) {

                return {
                    enabled: false,
                    tooltip:
                        _.isNil(scopeTenantType)
                            ? undefined
                            : (_.size(scopeIds) > 1
                                ? localization.organizationExistsSelection
                                : localization.organizationExists)({ tenantTypeName: localization[Contract.TypeNames.TenantType][scopeTenantType]() })
                };
            }

            return { enabled: true };
        },
        [scopeIds, scopeNodeMap, parentScopeNodeModel]);
}