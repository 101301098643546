import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, tenantModelStore, TypeHelper } from "../../../../../../../../common";
import { RiskDefinition } from "../../utilities";
import { useAadDefinition, useAwsDefinition, useAzureDefinition, useGciDefinition, useGcpDefinition, useKubernetesDefinition, useOciDefinition, useWorkloadAnalysisDefinition } from "./hooks";

export function useCloudDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const tenantModel = tenantModelStore.useGet(riskModel.tenantId);
    const useDefinition =
        useMemo(
            () => {
                if (TypeHelper.extendOrImplement(riskModel.risk.typeName, Contract.TypeNames.IKubernetesRisk)) {
                    return useKubernetesDefinition;
                }

                if (TypeHelper.extendOrImplement(riskModel.risk.typeName, Contract.TypeNames.WorkloadAnalysisRisk)) {
                    return useWorkloadAnalysisDefinition;
                }

                switch (tenantModel.tenantType) {
                    case Contract.TenantType.Aad:
                        return useAadDefinition;
                    case Contract.TenantType.Aws:
                        return useAwsDefinition;
                    case Contract.TenantType.Azure:
                        return useAzureDefinition;
                    case Contract.TenantType.Gci:
                        return useGciDefinition;
                    case Contract.TenantType.Gcp:
                        return useGcpDefinition;
                    case Contract.TenantType.Oci:
                        return useOciDefinition;
                    default:
                        throw new UnexpectedError("tenantModel.tenantType", tenantModel.tenantType);
                }
            },
            []);

    return useDefinition(riskModel);
}

export type RiskContentProps = {
    riskModel: Contract.RiskModel;
};