﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeTargetProxyTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeTargetProxyTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeTargetProxyType]: {
                    [Contract.GcpComputeTargetProxyType.Http]: "HTTP",
                    [Contract.GcpComputeTargetProxyType.Https]: "HTTPS",
                    [Contract.GcpComputeTargetProxyType.Ssl]: "SSL",
                    [Contract.GcpComputeTargetProxyType.Tcp]: "TCP"
                }
            }));
    return (type: Contract.GcpComputeTargetProxyType) =>
        localization[Contract.TypeNames.GcpComputeTargetProxyType][type]();
}