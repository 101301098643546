import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useIdentityRoleTranslator } from "../../../../../../../../../common";
import { useCommonAuditEventDetailsItems } from "./useCommonAuditEventDetailsItems";

export function useApiKeyAuditEventDefinition(context: AuditEventDefinitionContext) {
    const commonAuditEventDetailsItems = useCommonAuditEventDetailsItems(context);
    const identityRoleTranslator = useIdentityRoleTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useApiKeyAuditEventDefinition",
            () => ({
                apiKeyName: "Token",
                apiKeyRole: "Role"
            }));
    const apiKeyAuditEvent = context.auditEventModel.auditEvent as Contract.ApiKeyAuditEvent;

    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.apiKeyName(),
                apiKeyAuditEvent.apiKeyName),
            new AuditEventDefinitionDetailItem(
                localization.apiKeyRole(),
                identityRoleTranslator(apiKeyAuditEvent.apiKeyRole)),
            commonAuditEventDetailsItems.errorMessageItem
        ]));
}