import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, InlineItems, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, NetworkedResourcesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureNetworkVirtualNetworkDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureNetworkVirtualNetworkDefinition",
            () => ({
                columns: {
                    networkedResourceIds: "Attached Resources",
                    subnets: "Address Space"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkVirtualNetworkModel) => ({
                            [localization.columns.subnets()]: (item.entity as Contract.AzureNetworkVirtualNetwork).subnets.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnets)}
                                placeholder={localization.columns.subnets()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnets}
                key={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnets}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkVirtualNetworkModel>) =>
                        <Typography noWrap={true}>
                            <InlineItems
                                items={(item.entity as Contract.AzureNetworkVirtualNetwork).subnets}
                                variant="itemPlusItemCount"/>
                        </Typography>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.subnets()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkVirtualNetworkModel>(
                        Contract.TypeNames.INetworkedResource,
                        item =>
                            _(item.networkedResourceTypeNameToIdsMap).
                                values().
                                flatMap().
                                value(),
                        localization.columns.networkedResourceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkVirtualNetworkNetworkedResources)}
                                placeholder={localization.columns.networkedResourceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkVirtualNetworkNetworkedResources}
                key={Contract.EntityModelProperty.AzureNetworkVirtualNetworkNetworkedResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkVirtualNetworkModel>) =>
                        <NetworkedResourcesCell item={item.networkedResourceTypeNameToIdsMap}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.networkedResourceIds()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkRequest(
                new Contract.EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkVirtualNetworkNetworkedResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnets])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}