import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useEndpointConnectorsContext, useSetEndpointConnectorsContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { ConfigurationItem, ConnectItem } from "./components";
import { FinishItem } from "./components/FinishItem";

export class AddOrViewContext {
    public endpointConnectorHostName: string;
    public endpointConnectorName: string;
    public endpointConnectorPort: number;

    constructor(public endpointConnectorModel?: Contract.ScopeSystemEntityModel) {
        const endpointParts = (endpointConnectorModel?.configuration as Contract.EndpointConnectorConfiguration)?.endpoint?.split(":") ?? ["", ""];
        this.endpointConnectorHostName = endpointParts[0];
        this.endpointConnectorName = (endpointConnectorModel?.configuration as Contract.EndpointConnectorConfiguration)?.name;
        this.endpointConnectorPort = _.parseInt(endpointParts[1]);
    }
}

export const [addOrViewContext, useSetAddOrViewContext, useAddOrViewContextProvider] = makeContextProvider<AddOrViewContext>();

export function AddOrView() {
    const setEndpointConnectorsContext = useSetEndpointConnectorsContext();

    const { addOrViewOpen } = useEndpointConnectorsContext();

    const endpointConnectorModel =
        _.isBoolean(addOrViewOpen)
            ? undefined
            : addOrViewOpen;

    const [, , ContextProvider] = useAddOrViewContextProvider(() => new AddOrViewContext(endpointConnectorModel));
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useEndpointConnectorItems.endpointConnectors.addOrView",
            () => ({
                items: {
                    configurationItem: {
                        subtitle: "Add a connector for each network-isolated service you want to integrate with Tenable Cloud Security.",
                        title: "Integration Details"
                    },
                    connectItem: {
                        subtitle: "Install the connector chart on a dedicated Kubernetes cluster that is in the same network as, and connected to, the integration",
                        title: "Connect K8s Cluster"
                    }
                },
                title: {
                    add: "Add Endpoint Connector",
                    view: "View Endpoint Connector"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(endpointConnectorModel)
                            ? localization.title.add()
                            : localization.title.view()
                }}>
                <OrderedWizard
                    finishItemElement={
                        _.isNil(endpointConnectorModel)
                            ? <FinishItem/>
                            : undefined}
                    startItemIndex={
                        _.isNil(endpointConnectorModel)
                            ? undefined
                            : 1}
                    onClose={
                        () =>
                            setEndpointConnectorsContext(
                                EndpointConnectorsContext => ({
                                    ...EndpointConnectorsContext,
                                    addOrViewOpen: false
                                }))}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        subtitle={localization.items.configurationItem.subtitle()}
                        title={localization.items.configurationItem.title()}>
                        <ConfigurationItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        subtitle={localization.items.connectItem.subtitle()}
                        title={localization.items.connectItem.title()}>
                        <ConnectItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}