import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, optionalTableCell, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, EntitiesCell, InfoCard } from "../../../../../../../../../../../../common";

type RouteRulesInfoCardProps = {
    routeRules: Contract.OciNetworkingRouteTableRouteRule[];
    targetResourceOcidToEntityIdReferenceMap: Dictionary<string>;
};

export function RouteRulesInfoCard({ routeRules, targetResourceOcidToEntityIdReferenceMap }: RouteRulesInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingRouteTableDefinition.routeRulesInfoCard",
            () => ({
                columns: {
                    description: "Description",
                    destination: "Destination",
                    routeType: {
                        title: "Route Type",
                        [Contract.TypeNames.OciNetworkingRouteTableRouteRuleRouteType]: {
                            [Contract.OciNetworkingRouteTableRouteRuleRouteType.Local]: "Local",
                            [Contract.OciNetworkingRouteTableRouteRuleRouteType.Static]: "Static"
                        }
                    },
                    targetResource: "Target"
                },
                empty: "No Route Rules",
                title: "Route Rules"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => routeRules}
                getItemId={(item: Contract.OciNetworkingRouteTableRouteRule) => _.indexOf(routeRules, item).toString()}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={RouteRuleColumnId.Destination}
                    itemProperty={(item: Contract.OciNetworkingRouteTableRouteRule) => item.destinationSubnet ?? item.destinationServiceCidrLabel}
                    title={localization.columns.destination()}/>
                <DataTableColumn
                    id={RouteRuleColumnId.TargetResource}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingRouteTableRouteRule>) =>
                            item.privateIpTargetIpAddress
                                ? <Typography noWrap={true}> {item.privateIpTargetIpAddress} </Typography>
                                : <EntitiesCell
                                    entityIdsOrModels={targetResourceOcidToEntityIdReferenceMap[item.targetResourceOcid]}
                                    entityTypeName={Contract.TypeNames.OciResource}
                                    entityVariant="iconText"/>
                    }
                    title={localization.columns.targetResource()}/>
                <DataTableColumn
                    id={RouteRuleColumnId.RouteType}
                    itemProperty={
                        (rule: Contract.OciNetworkingRouteTableRouteRule) =>
                            localization.columns.routeType[Contract.TypeNames.OciNetworkingRouteTableRouteRuleRouteType][rule.routeType]()
                    }
                    title={localization.columns.routeType.title()}/>
                <DataTableColumn
                    id={RouteRuleColumnId.Description}
                    render={
                        optionalTableCell<Contract.OciNetworkingRouteTableRouteRule>(
                            rule =>
                                _.isEmpty(rule.description)
                                    ? undefined
                                    : rule.description)}
                    title={localization.columns.description()}/>
            </DataTable>
        </InfoCard>);
}

enum RouteRuleColumnId {
    Description = "description",
    Destination = "destination",
    RouteType = "routeType",
    TargetResource = "target"
}