import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";
import { TableDefinition } from "../../useDefinition";
import { useRiskPolicyTypeDefinition, useRiskTenantDefinition } from "./hooks";

export function useCloudDefinition(riskGroup: Contract.RiskControllerGetRiskGroupsResponseRiskGroup): TableDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskGroup.typeName) {
                    case Contract.TypeNames.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup:
                        return useRiskPolicyTypeDefinition;
                    case Contract.TypeNames.RiskControllerGetRiskGroupsResponseRiskTenantGroup:
                        return useRiskTenantDefinition;
                    default:
                        throw new UnexpectedError(riskGroup.typeName);
                }
            },
            []);

    return useDefinition(riskGroup);
}