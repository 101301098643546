import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useLayoutEffect } from "react";
import { LayoutOptions, useSetLayoutContext } from "../components";

export function useLayoutOptions(options: LayoutOptions) {
    const setLayoutContext = useSetLayoutContext();
    const localization =
        useLocalization(
            "common.hooks.useLayoutOptions",
            () => ({
                title: {
                    default: "Tenable Cloud Security",
                    view: "Tenable Cloud Security | {{viewTitle}}"
                }
            }));

    useLayoutEffect(
        () => {
            const containedChanged = !_.isNil(options.contained);
            const scopeDisableChanged = !_.isNil(options.scopeDisabled);
            const scopeNavigationOptionsChanged = !_.isNil(options.scopeNavigationOptions);
            const viewChanged = !_.isNil(options.view);

            const changed =
                containedChanged ||
                scopeDisableChanged ||
                scopeNavigationOptionsChanged ||
                viewChanged;

            setLayoutContext(
                context => ({
                    ...context,
                    ...(changed && {
                        options: {
                            ...context.options,
                            ...(containedChanged && {
                                contained: options.contained
                            }),
                            ...(scopeDisableChanged && {
                                scopeDisabled: options.scopeDisabled
                            }),
                            ...(scopeNavigationOptionsChanged && {
                                scopeNavigationOptions: options.scopeNavigationOptions
                            }),
                            ...(viewChanged && {
                                view: options.view
                            })
                        }
                    })
                }));

            if (viewChanged) {
                document.title = localization.title.view({ viewTitle: options.view?.title });
            }

            return () => {
                setLayoutContext(
                    context => ({
                        ...context,
                        ...(changed && {
                            options: {
                                ...context.options,
                                ...(containedChanged && {
                                    contained: undefined
                                }),
                                ...(scopeDisableChanged && {
                                    scopeDisabled: undefined
                                }),
                                ...(scopeNavigationOptionsChanged && {
                                    scopeNavigationOptions: undefined
                                }),
                                ...(viewChanged && {
                                    view: undefined
                                })
                            }
                        })
                    }));

                if (viewChanged) {
                    document.title = localization.title.default();
                }
            };
        },
        [options.contained, options.view?.documentationPath, options.scopeDisabled, options.scopeNavigationOptions, options.view?.title]);
}