import { browserHistory, Optional } from "@infrastructure";
import { useTheme } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from ".";

export function TypographyTooltipProvider() {
    const childElementRef = useRef<HTMLBaseElement>(null);
    const targetElementRef = useRef<HTMLBaseElement | null>(null);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState<string>("");

    const closeTooltip =
        () => {
            targetElementRef.current = null;
            setOpen(false);
        };

    const theme = useTheme();
    useEffect(
        () => {
            const unregisterBrowserHistoryListener =
                browserHistory.listen(
                    () => {
                        closeTooltip();
                    });

            const documentBodyMutationObserver =
                new MutationObserver(
                    mutationRecords => {
                        if (!_.isNil(targetElementRef.current) &&
                            _(mutationRecords).
                                flatMap(mutationRecord => Array.from(mutationRecord.removedNodes)).
                                find(removedNode => !_.isNil(removedNode.contains(targetElementRef.current)))) {
                            closeTooltip();
                        }
                    });
            documentBodyMutationObserver.observe(
                document.body,
                {
                    childList: true,
                    subtree: true
                });

            const childElement = childElementRef.current!;
            const mouseOutHandler =
                (event: MouseEvent) => {
                    if (Object.is(event.target, targetElementRef.current)) {
                        closeTooltip();
                    }
                    document.removeEventListener("mouseout", mouseOutHandler);
                };

            const mouseOverHandler =
                (event: MouseEvent) => {
                    if (_.isNil(event.target) ||
                        !_.isNil((event.target as HTMLBaseElement).closest(".tooltip"))) {
                        return;
                    }

                    let targetElement: Optional<HTMLElement | null> = (event.target as HTMLBaseElement).closest<HTMLElement>(".MuiTypography-noWrap");
                    while (!_.isNil(targetElement) && targetElement.scrollWidth <= targetElement.offsetWidth) {
                        targetElement = targetElement.parentElement?.closest(".MuiTypography-noWrap");
                    }

                    if (_.isNil(targetElement)) {
                        return;
                    }

                    document.addEventListener("mouseout", mouseOutHandler);

                    const foreignObjectElement = targetElement.closest("foreignObject");
                    const targetElementBoundingClientRect =
                        !_.isNil(foreignObjectElement)
                            ? foreignObjectElement.closest("g")!.getBoundingClientRect()
                            : targetElement.getBoundingClientRect();

                    childElement.style.height = theme.px(targetElementBoundingClientRect.height);
                    childElement.style.left = theme.px(targetElementBoundingClientRect.left);
                    childElement.style.top = theme.px(targetElementBoundingClientRect.top);
                    childElement.style.width = theme.px(targetElementBoundingClientRect.width);

                    targetElementRef.current = event.target as HTMLBaseElement;
                    setTitle(_.truncate(targetElementRef.current.innerText, { length: 500 }));
                    setOpen(true);
                };

            const debounceMouseOverHandler = _.debounce(mouseOverHandler, 300);

            document.addEventListener("keydown", closeTooltip);
            document.addEventListener("mouseover", debounceMouseOverHandler);
            document.addEventListener("wheel", closeTooltip);
            return () => {
                unregisterBrowserHistoryListener();
                documentBodyMutationObserver.disconnect();

                document.removeEventListener("keydown", closeTooltip);
                document.removeEventListener("mouseover", debounceMouseOverHandler);
                document.removeEventListener("wheel", closeTooltip);
            };
        },
        []);

    return (
        <Tooltip
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
            open={open}
            ref={childElementRef}
            titleOrGetTitle={title}>
            <div
                style={{
                    position: "absolute",
                    zIndex: -9999
                }}/>
        </Tooltip>);
}