import { UnexpectedError, useLocalization } from "@infrastructure";
import { useCallback } from "react";
import { Contract, CustomerConsoleAppUrlHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { CustomerLicenseObjectTypeTranslator } from "../useGetVersion2Translator";

export function useGetVersion2OciTranslator(): CustomerLicenseObjectTypeTranslator {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.licensing.hooks.useGetCustomerLicenseObjectInfo.hooks.version2.hooks.useGetVersion2OciTranslator",
            () => ({
                [Contract.CustomerLicenseObjectType.OciContainerEngineClusterInstance]: "{{translatedTypeName}} running OKE"
            }));


    return useCallback(
        objectType => {
            switch (objectType) {
                case Contract.CustomerLicenseObjectType.OciComputeInstance:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(objectType),
                        title:
                            entityTypeNameTranslator(
                                objectType,
                                { count: 0 })
                    };
                case Contract.CustomerLicenseObjectType.OciFunctionsFunction:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(Contract.TypeNames.OciFunctionsFunctionGenericResource),
                        title:
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciFunctionsFunctionGenericResource,
                                { count: 0 })
                    };
                case Contract.CustomerLicenseObjectType.OciContainerEngineClusterInstance:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.OciComputeInstance,
                                        { count: 0 })
                            })
                    };
                default:
                    throw new UnexpectedError("objectType", objectType);
            }
        },
        [entityTypeNameTranslator, localization]);
}