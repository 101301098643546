import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function usePrincipalTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.usePrincipalTypeTranslator",
            () => ({
                [Contract.TypeNames.PrincipalType]: {
                    [Contract.PrincipalType.AwsPermissionSet]: "AWS Permission Sets",
                    [Contract.PrincipalType.AwsRoleGroup]: "Organization Roles",
                    [Contract.PrincipalType.FederationIdentity]: "Federated Identities",
                    [Contract.PrincipalType.GcpWorkforcePool]: "Workforce Pools",
                    [Contract.PrincipalType.GcpWorkloadIdentityPool]: "Workload Identity Pools",
                    [Contract.PrincipalType.Group]: "Groups",
                    [Contract.PrincipalType.ServiceIdentity]: "Service Identities",
                    [Contract.PrincipalType.User]: "Users",
                    [Contract.PrincipalType.VendorServiceIdentity]: "3*superscript*rd** Party Identities"
                }
            }));
    return (principalType: Contract.PrincipalType) =>
        localization[Contract.TypeNames.PrincipalType][principalType]();
}