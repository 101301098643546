import { PrivateIcon, PublicIcon, Sx, UnexpectedError } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../common";

type AccessLevelIconProps = SvgIconProps & {
    accessLevel: Contract.OciObjectStorageBucketObjectAccessLevel;
};

export function AccessLevelIcon({ accessLevel, sx, ...props }: AccessLevelIconProps) {
    const theme = useTheme();
    switch (accessLevel) {
        case Contract.OciObjectStorageBucketObjectAccessLevel.Private:
            return (
                <PrivateIcon
                    sx={Sx.combine(sx, { color: theme.palette.text.secondary })}
                    {...props}/>);
        case Contract.OciObjectStorageBucketObjectAccessLevel.PublicGet:
            return (
                <PublicIcon
                    sx={Sx.combine(sx, { color: theme.palette.warning.main })}
                    {...props}/>);
        case Contract.OciObjectStorageBucketObjectAccessLevel.PublicRead:
            return (
                <PublicIcon
                    sx={Sx.combine(sx, { color: theme.palette.error.main })}
                    {...props}/>);
        default:
            throw new UnexpectedError(accessLevel);
    }
}