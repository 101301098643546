import { useLocalization } from "@infrastructure";
import React from "react";
import { SnoozedIcon } from "../icons";
import { ProfileTopbarInfoItem } from "./ProfileTopbarInfoItem";

export type ProfileTopbarTimeInfoItemType = "closed" | "created" | "ignored" | "ignoreExpirationDate" | "opened" | "scanned";

type ProfileTopbarTimeInfoItemProps = {
    time: string;
    type: ProfileTopbarTimeInfoItemType;
};

export function ProfileTopbarTimeInfoItem({ time, type }: ProfileTopbarTimeInfoItemProps) {
    const localization =
        useLocalization(
            "common.profileTopbarTimeInfoItem",
            () => ({
                closed: "Closed at {{ time | TimeFormatter.mediumDateShortTime }}",
                created: "Created at {{ time | TimeFormatter.mediumDateShortTime }}",
                ignored: "Ignored at {{ time | TimeFormatter.mediumDateShortTime }}",
                ignoreExpirationDate: "Ignored until {{time | TimeFormatter.mediumDate}}",
                opened: "Opened at {{ time | TimeFormatter.mediumDateShortTime }}",
                scanned: "Scanned at {{ time | TimeFormatter.mediumDateShortTime }}"
            }));

    return (
        <ProfileTopbarInfoItem
            icon={<SnoozedIcon/>}
            text={
                localization.translate(
                    type,
                    { time })}/>);
}