import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useRemoveQueryParameters } from "@infrastructure";
import React from "react";
import { CodeOrganizationQueryParameters } from "../..";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEditGitLab, Table } from "./components";

export class GitLabContext {
    constructor(public addOrEditOpen: boolean | Contract.CodeOrganizationModel) {
    }
}

export const [useGitLabContext, useSetGitLabContext, useGitLabContextProvider] = makeContextProvider<GitLabContext>();

export function GitLab() {
    const removeQueryParameters = useRemoveQueryParameters();
    const [context, setContext, ContextProvider] =
        useGitLabContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<GitLabOrganizationQueryParameters>();
                removeQueryParameters("addOrEditOpen");
                return new GitLabContext(StringHelper.isTrue(queryParameters.addOrEditOpen));
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEditGitLab/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type GitLabOrganizationQueryParameters = CodeOrganizationQueryParameters;