import { BulletIcon, IconText } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../common";
import { EligibilityStatusData } from "../hooks";

export type StatusCellProps = {
    eligibilityStatusData: EligibilityStatusData;
};

export function StatusCell({ eligibilityStatusData }: StatusCellProps) {
    return (
        <IconText
            icon={eligibilityStatusData.iconElement}
            iconSx={{ fontSize: "18px" }}
            text={
                <Typography noWrap={true}>
                    {eligibilityStatusData.title}
                </Typography>}
            titleOrGetTitle={
                _.isNil(eligibilityStatusData.infos)
                    ? undefined
                    : <Infos values={eligibilityStatusData.infos}/>}
            tooltipVariant="iconText"/>);
}

type InfosProps = {
    values: string[];
};

export function Infos({ values }: InfosProps) {
    const theme = useTheme();
    return (
        <Stack spacing={1}>
            {values.length === 1
                ? <Typography sx={{ wordBreak: "break-word" }}>
                    {values[0]}
                </Typography>
                : _.map(
                    values,
                    value =>
                        <Stack
                            alignItems="baseline"
                            direction="row"
                            spacing={1}>
                            <BulletIcon
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: "9px",
                                    height: "100%"
                                }}/>
                            <Typography sx={{ wordBreak: "break-word" }}>
                                {value}
                            </Typography>
                        </Stack>)}
        </Stack>);
}