import { Loading, Optional, SelectionActionsItemPermissions, setRef } from "@infrastructure";
import _ from "lodash";
import React, { memo, Ref, useRef, useState } from "react";
import { GroupedItem } from "../..";
import { Contract, InfiniteScrollPager, useTheme } from "../../../../../../../../../../common";
import { TableActions } from "../../../Table";
import { Table } from "./components";

const ItemMemo = memo(Item);
export { ItemMemo as Item };

type ItemProps = {
    item: GroupedItem;
    onClose: () => void;
    onLoadedRiskModelsChanged: (loadedRiskModels: Contract.RiskModel[], itemId: string) => void;
    onSelectionChanged: (itemId: string, selectedRiskIds: string[], selectionPermissions?: SelectionActionsItemPermissions) => void;
    riskStatus: Contract.RiskStatus;
    tableActionsRef: Ref<Optional<TableActions>>;
};

function Item({ item, onClose, onLoadedRiskModelsChanged, onSelectionChanged, riskStatus, tableActionsRef }: ItemProps) {
    const groupTableActionsRef = useRef<TableActions>();
    const [loadedRiskModelCount, setLoadedRiskModelCount] = useState(0);
    const [riskModelCount, setRiskModelCount] = useState<number>();

    const theme = useTheme();
    return (
        <Loading>
            <InfiniteScrollPager
                fetchItems={() => groupTableActionsRef.current!.fetchItems()}
                footerSx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0, 0, 0.75, 0.75),
                    borderWidth: "0px 1px 1px 1px",
                    padding: theme.spacing(0, 1.5)
                }}
                itemCount={riskModelCount}
                loadedItemCount={loadedRiskModelCount}
                showEmpty={false}
                onClose={() => onClose()}>
                <Table
                    actionsRef={
                        actions => {
                            groupTableActionsRef.current = actions!;
                            setRef(tableActionsRef, actions);
                        }}
                    item={item}
                    riskStatus={riskStatus}
                    sx={
                        _.isNil(riskModelCount) || riskModelCount === 0
                            ? {}
                            : {
                                borderRadius: 0,
                                borderWidth: "1px 1px 0px 1px"
                            }}
                    onLoadedRiskModelsChanged={
                        loadedRiskModels => {
                            setLoadedRiskModelCount(loadedRiskModels.length);
                            onLoadedRiskModelsChanged(loadedRiskModels, item.id);
                        }}
                    onRiskModelCountChanged={riskModelCount => setRiskModelCount(riskModelCount)}
                    onSelectionChanged={
                        (selectedRiskIds, selectionPermissions) => {
                            onSelectionChanged(
                                item.id,
                                selectedRiskIds,
                                selectionPermissions);
                        }}/>
            </InfiniteScrollPager>
        </Loading>);
}