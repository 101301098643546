import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../controllers";
import { useTheme } from "../../../../../themes";

export function PciIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
            <path
                d="M13.5 21.0001H10.5C6.5 21.0001 4.5 21.0001 3 19.8001C2.8 19.6001 2.5 19.4001 2.3 19.2001C1 17.7001 1 15.7001 1 12.0001C1 8.3001 1 10.0001 1 9.2001V9.0001C1 9.0001 1 8.8001 1 8.7001C1.1 7.0001 1.4 5.8001 2.2 4.9001C2.4 4.7001 2.6 4.5001 2.9 4.3001C4.4 3.1001 6.4 3.1001 10.4 3.1001H13.4C17.4 3.1001 19.4 3.1001 20.9 4.3001C21.1 4.5001 21.4 4.7001 21.6 4.9001C22.4 5.8001 22.7 7.0001 22.8 8.7001C22.8 8.8001 22.8 8.9001 22.8 9.0001C22.8 9.1001 22.8 9.2001 22.8 9.2001C22.8 10.0001 22.8 10.9001 22.8 12.0001C22.8 15.7001 22.8 17.7001 21.5 19.2001C21.3 19.4001 21.1 19.7001 20.8 19.8001C19.3 21.0001 17.3 21.0001 13.3 21.0001H13.5ZM3 10.0001C3 10.6001 3 11.3001 3 12.0001C3 15.3001 3 16.9001 3.8 17.8001C3.9 17.9001 4.1 18.1001 4.2 18.2001C5.2 19.0001 6.9 19.0001 10.5 19.0001H13.5C17 19.0001 18.8 19.0001 19.8 18.2001C20 18.1001 20.1 17.9001 20.2 17.8001C21 16.9001 21 15.3001 21 12.0001C21 8.7001 21 10.6001 21 10.0001H3ZM3.1 8.0001H20.9C20.8 7.2001 20.6 6.6001 20.2 6.2001C20.1 6.1001 19.9 5.9001 19.8 5.8001C18.8 5.0001 17.1 5.0001 13.5 5.0001H10.5C7 5.0001 5.2 5.0001 4.2 5.8001C4 5.9001 3.9 6.1001 3.8 6.2001C3.4 6.6001 3.2 7.2001 3.1 8.0001ZM18 17.0001H14.5C13.9 17.0001 13.5 16.6001 13.5 16.0001C13.5 15.4001 13.9 15.0001 14.5 15.0001H18C18.6 15.0001 19 15.4001 19 16.0001C19 16.6001 18.6 17.0001 18 17.0001ZM11.5 17.0001H10C9.4 17.0001 9 16.6001 9 16.0001C9 15.4001 9.4 15.0001 10 15.0001H11.5C12.1 15.0001 12.5 15.4001 12.5 16.0001C12.5 16.6001 12.1 17.0001 11.5 17.0001Z"
                fill={theme.palette.data.category(Contract.DataCategory.Pci)}/>
        </SvgIcon>);
}