import { useLocalization } from "@infrastructure";
import { Contract } from "../controllers";

export function useTicketMultipleRisksDescriptionTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useTicketMultipleRisksDescriptionTranslator",
            () => ({
                riskText: "-----------------------------\nFinding {{riskIndex}}:\n-----------------------------\n{{riskTitle}} | Severity: {{severity}}\n{{riskDescription}}"
            }));

    return (riskDescription: string, riskIndex: number, riskTitle: string, severity: Contract.Severity) =>
        localization.riskText(
            {
                riskDescription,
                riskIndex,
                riskTitle,
                severity
            });
}