import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function TrendCloudOneIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2635)">
                <path
                    d="M24.003 12.0026C24.003 14.3754 23.2994 16.695 21.9811 18.6679C20.6629 20.6409 18.7892 22.1786 16.597 23.0867C14.4048 23.9948 11.9925 24.2324 9.6653 23.7696C7.33805 23.3067 5.20031 22.1641 3.52241 20.4863C1.84451 18.8086 0.701804 16.6709 0.238792 14.3437C-0.22422 12.0164 0.0132571 9.60419 0.921193 7.41194C1.82913 5.21968 3.36675 3.34589 5.33961 2.0275C7.31247 0.709111 9.63197 0.0053422 12.0048 0.00518658C13.5804 0.00508325 15.1406 0.315329 16.5963 0.91821C18.0519 1.52109 19.3746 2.4048 20.4888 3.51887C21.6029 4.63295 22.4867 5.95557 23.0897 7.41121C23.6927 8.86686 24.003 10.427 24.003 12.0026Z"
                    fill="white"/>
                <path
                    d="M2.01298 18.6504C0.338591 16.1371 -0.326405 13.0847 0.150725 10.1026C0.627856 7.12055 2.21202 4.42808 4.58701 2.56263C6.962 0.69718 9.95313 -0.204022 12.9634 0.0389017C15.9736 0.281826 18.7815 1.65101 20.8266 3.87316C18.5086 2.2885 14.0891 3.04621 9.87409 5.74657C13.6957 3.41287 17.7557 3.04385 19.3844 5.12813C21.257 7.52243 19.2884 12.1898 14.9845 15.5535C10.6806 18.9171 6.3161 19.5497 5.50725 17.2813C5.20983 16.4347 4.97614 15.3544 6.71501 11.9444L8.75445 11.9546L9.14786 11.13H7.07144L8.33901 8.4013C8.33901 8.4013 7.32559 8.4013 7.31614 8.40838C5.89108 9.74891 4.1553 10.7144 2.26476 11.2181L1.93509 11.9931H3.33877C1.76907 14.459 1.28675 16.8895 2.01298 18.6504Z"
                    fill="#D71920"/>
                <path
                    d="M24.003 12.0026C24.0028 14.3994 23.2847 16.7413 21.9413 18.7263C20.5979 20.7112 18.6908 22.2484 16.4658 23.1395C14.2407 24.0307 11.7998 24.235 9.45762 23.7262C7.11541 23.2173 4.97929 22.0187 3.32458 20.2847C5.95728 22.0943 11.3187 20.863 16.053 17.1649C20.74 13.5038 23.2327 8.66097 22.1901 5.6569C23.3773 7.56022 24.0045 9.75939 23.9998 12.0026"
                    fill="#D71920"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2635">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}