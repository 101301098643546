import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../common/themes";

export function EnableIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon fill="none" height="14" viewBox="0 0 14 14" width="14" {...props}>
            <path
                clipRule="evenodd"
                d="M11.0834 5.99051C11.8612 6.43956 11.8612 7.56219 11.0834 8.01124L4.08341 12.0527C3.30564 12.5017 2.33341 11.9404 2.33341 11.0423L2.33341 2.95942C2.33341 2.06132 3.30564 1.50001 4.08341 1.94906L11.0834 5.99051ZM3.50008 11.0423L10.5001 7.00087L3.50008 2.95942L3.50008 11.0423Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
        </SvgIcon>);


}