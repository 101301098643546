import { NumberFormatter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { GroupedTableColumnRenderProps } from "../../../../../../../../../../../common";
import { GroupedItem, useGroupedContext } from "../../../Grouped";

export function RiskCount({ item }: GroupedTableColumnRenderProps<GroupedItem>) {
    const { itemIdToSelectionMap } = useGroupedContext();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.riskCount",
            () => ({
                selection: "{{riskCount | NumberFormatter.humanize}} ({{selectedRiskCount | NumberFormatter.humanize}} Selected)"
            }));
    return (
        <Typography>
            {_.isEmpty(itemIdToSelectionMap[item.id]?.riskIds)
                ? NumberFormatter.humanize(item.riskGroup.riskCount)
                : localization.selection({
                    riskCount: item.riskGroup.riskCount,
                    selectedRiskCount: itemIdToSelectionMap[item.id].riskIds.length
                })}
        </Typography>);
}