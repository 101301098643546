import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, InlineAwsIamGroupUsers, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { ChangeStep, RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsIamGroupRiskContext } from "../contexts/useGetAwsIamGroupRiskContext";

export function useAwsIamGroupInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamGroupInactiveRisk;
    const groupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamGroupModel;
    const group = groupModel.entity as Contract.AwsIamGroup;

    const getAwsIamGroupRiskContext = useGetAwsIamGroupRiskContext();
    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamGroupInactiveRiskDefinition",
            () => ({
                description: {
                    userExists: "All users in group {{group}} are inactive",
                    userNotExist: "Group {{group}} has no users"
                },
                sections: {
                    context: {
                        activityTimeExists: [
                            "The user in the group has been inactive for more than {{activityTime | TimeFormatter.humanizeDuration}}",
                            "All users in the group have been inactive for more than {{activityTime | TimeFormatter.humanizeDuration}}"
                        ],
                        activityTimeNotExist: [
                            "The user in the group has been inactive since user creation",
                            "All users in the group have been inactive since their creation"
                        ],
                        groupUsers: {
                            userExists: "The group has {{groupUsers}}",
                            userNotExist: "The group has no users"
                        }
                    },
                    resolution: "Click **Delete** and confirm the deletion"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (risk.groupUserExists
            ? localization.description.userExists
            : localization.description.userNotExist)(
            {
                group:
                    <Entity
                        entityIdOrModel={groupModel}
                        variant="text"/>
            }),
        () => {
            const deleteGroupChange = risk.resolutionChanges![0] as Contract.AwsDeleteGroupChange;
            return [
                new ChangeStep(
                    deleteGroupChange,
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {[
                                    consoleSignInStepTranslator(
                                        Contract.AwsConsoleView.Iam,
                                        AwsConsoleUrlBuilder.getIamGroupUrl(group)),
                                    localization.sections.resolution()
                                ]}
                            </Steps>
                    }
                )];
        },
        riskModel,
        () => {
            const groupRiskContext = getAwsIamGroupRiskContext(groupModel);

            const inactivityContextItem =
                risk.groupUserExists
                    ? _.isNil(groupRiskContext.activityTime)
                        ? new RiskDefinitionContextItem(localization.sections.context.activityTimeNotExist(group.userIds.length))
                        : new RiskDefinitionContextItem(localization.sections.context.activityTimeExists(
                            group.userIds.length,
                            { activityTime: groupRiskContext.activityTime }))
                    : undefined;

            return [
                groupRiskContext.generalInformation,
                new RiskDefinitionContextItem(
                    risk.groupUserExists
                        ? localization.sections.context.groupUsers.userExists({ groupUsers: <InlineAwsIamGroupUsers userIds={group.userIds}/> })
                        : localization.sections.context.groupUsers.userNotExist()),
                inactivityContextItem,
                groupRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)];
        });
}