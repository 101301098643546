import { ReactNode } from "react";
import { RiskView } from "../../../../utilities";

export interface RiskDefinition {
    description: string;
    sections: (RiskDefinitionSection | RiskDefinitionSectionGroup)[];
}

export class RiskDefinitionContextItem {
    public impact: RiskDefinitionContextItemImpact;

    constructor(
        public contentElement: ReactNode,
        impact?: RiskDefinitionContextItemImpact) {
        this.impact = impact ?? RiskDefinitionContextItemImpact.Information;
    }

    public withImpact(impact: RiskDefinitionContextItemImpact) {
        return new RiskDefinitionContextItem(
            this.contentElement,
            impact);
    }
}

export enum RiskDefinitionContextItemImpact {
    Information,
    SeverityDecreased,
    SeverityIncreased
}

export class RiskDefinitionSection {
    constructor(
        public contentElement: ReactNode,
        public title?: string,
        public options?: RiskDefinitionSectionOptions) {
    }
}

export class RiskDefinitionSectionGroup {
    constructor(
        public sections: RiskDefinitionSection[]) {
    }
}

export type RiskDefinitionSectionOptions = {
    actionsElement?: ReactNode;
    categoryView?: RiskDefinitionSectionOptionsCategoryView;
    expandable?: boolean;
    profilePageOnly?: boolean;
    resolvedRiskVisible?: boolean;
};

type RiskDefinitionSectionOptionsCategoryView = {
    category: RiskDefinitionSectionCategory;
    view: RiskView;
};

export enum RiskDefinitionSectionCategory {
    Activity = "activity",
    CodeResource = "codeResource",
    Overview = "overview",
    Resolution = "resolution"
}