import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetAzureKeyVaultObjectRiskContext } from "./useGetAzureKeyVaultObjectRiskContext";

export function useGetAzureKeyVaultCertificateRiskContext() {
    return useMemo(
        () => useAzureKeyVaultCertificateRiskContext,
        []);
}

function useAzureKeyVaultCertificateRiskContext(certificateModel: Contract.AzureKeyVaultVaultCertificateModel) {
    return useGetAzureKeyVaultObjectRiskContext()(certificateModel);
}