﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlinePermissionActions, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsEc2InstanceStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEc2InstanceRiskContext() {
    return useMemo(
        () => useAwsEc2InstanceContext,
        []);
}

function useAwsEc2InstanceContext(instanceModel: Contract.AwsEc2InstanceModel) {
    const instance = instanceModel.entity as Contract.AwsEc2Instance;
    const resourceRiskContext = useGetAwsResourceRiskContext()(instanceModel);

    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2InstanceRiskContext",
            () => ({
                instanceRoleNotExists: "The {{translatedInstanceTypeName}} has no credentials attached",
                metadataServiceVersionV1Used: {
                    false: "The {{translatedInstanceTypeName}} has not used IMDSv1 for the past 1 month",
                    true: "The {{translatedInstanceTypeName}} used IMDSv1 for the past 1 month"
                },
                permissionActions: "The {{translatedInstanceTypeName}} is associated with {{permissionActions}} with **high** severity",
                status: "The {{translatedInstanceTypeName}} state is **{{status}}**"
            }));
    const translatedInstanceTypeName =
        entityTypeNameTranslator(
            instance.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        getPermissionActionsContextItem:
            (permissionActions?: string[]) =>
                !_.isEmpty(permissionActions)
                    ? new RiskDefinitionContextItem(
                        localization.permissionActions({
                            permissionActions:
                                <InlinePermissionActions
                                    permissionActions={permissionActions}
                                    variant="itemCountAndType"/>,
                            translatedInstanceTypeName
                        }))
                    : undefined,
        instanceRoleNotExists:
            _.isNil(instance.roleReference)
                ? new RiskDefinitionContextItem(localization.instanceRoleNotExists({ translatedInstanceTypeName }))
                : undefined,
        metadataServiceVersionV1Used:
            new RiskDefinitionContextItem(
                instance.metadataServiceVersionV1Used
                    ? localization.metadataServiceVersionV1Used.true({ translatedInstanceTypeName })
                    : localization.metadataServiceVersionV1Used.false({ translatedInstanceTypeName })
            ),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: ec2InstanceStatusTranslator(instance.status),
                    translatedInstanceTypeName
                }))
    };
}