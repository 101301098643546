﻿import _, { Dictionary } from "lodash";
import { useGcpCommonKubernetesResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { useCommonKubernetesIamResourceDefinition } from "..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpKubernetesClusterRoleBindingDefinition(definitionData: DefinitionData) {
    const commonKubernetesIamResourceDefinition = useCommonKubernetesIamResourceDefinition(definitionData);
    const commonKubernetesResourceDefinition = useGcpCommonKubernetesResourceDefinition(definitionData);

    return new EntityTableDefinition(
        _.filter([
            commonKubernetesResourceDefinition.columns.clusterColumn,
            commonKubernetesResourceDefinition.columns.tenantColumn,
            commonKubernetesResourceDefinition.columns.creationTimeColumn,
            commonKubernetesIamResourceDefinition.columns.getPrincipalIdReferencesColumn(Contract.EntityModelProperty.KubernetesRoleBindingPrincipals),
            commonKubernetesIamResourceDefinition.columns.getRoleIdReferenceColumn(Contract.TypeNames.GcpKubernetesClusterRole),
            commonKubernetesResourceDefinition.columns.tagsColumn,
            commonKubernetesResourceDefinition.columns.attributesColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpKubernetesClusterRoleBindingRequest(
                new Contract.EntityControllerGetEntityModelPageGcpKubernetesClusterRoleBindingRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesResourceCluster])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesRoleBindingPrincipals]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesRoleBindingRole]))));
}