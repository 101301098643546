import { useLocalization } from "@infrastructure";

export function useAwsUpsertBucketPolicyChangeDefinition() {
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsUpsertBucketPolicyChangeDefinition",
            () => ({
                title: "Update the bucket policy"
            }));
    return {
        title: localization.title()
    };
}