import { map, TimeFormatter, useDebouncedEffect } from "@infrastructure";
import { Typography } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Contract } from "../../../../../../../controllers";
import { UdmObjectPropertyFilterProps } from "../UdmObjectPropertyFilter";
import { Filter } from "./Filter";

export function InfraDateTime({ onClearClicked, onFilterChange, propertyId, rule }: UdmObjectPropertyFilterProps) {
    const [value, setValue] =
        useState(
            () => {
                if (_.isEmpty(rule.values)) {
                    return [] as [];
                }

                if (rule.values.length === 1) {
                    return [moment(rule.values[0])];
                }

                return [moment(rule.values[0]), moment(rule.values[1])];
            });
    const [operator, setOperator] = useState<Contract.UdmQueryRuleOperator>(rule.operator ?? operators[0]);

    useEffect(
        () => {
            if (operator === Contract.UdmQueryRuleOperator.Between && value.length === 1) {
                setValue([value[0], value[0]]);
            } else if (operator !== Contract.UdmQueryRuleOperator.Between && value.length === 2) {
                setValue([value[0]]);
            }
        },
        [operator, value]);

    useDebouncedEffect(
        () => {
            if (operator &&
                !_.isNil(value) &&
                _.every(
                    value,
                    date => !_.isNil(date))) {
                onFilterChange({
                    operator,
                    value
                });
            }
        },
        500,
        [operator, value]);

    return (
        <Filter
            initialOperator={operator}
            operators={operators}
            popoverElementContainerSx={{ maxWidth: "unset" }}
            propertyId={propertyId}
            ruleId={rule.id!}
            selectionViewValue={
                !_.isNil(value) &&
                    !_.isEmpty(value)
                    ? value.length === 2
                        ? <Typography noWrap={true}>
                            {`${TimeFormatter.mediumDate(value[0])} - ${TimeFormatter.mediumDate(value[1])}`}
                        </Typography>
                        : <Typography noWrap={true}>
                            {TimeFormatter.mediumDate(value[0])}
                        </Typography>
                    : null}
            values={value}
            onClearClicked={onClearClicked}
            onOperatorChange={setOperator}>
            {map(
                operator,
                {
                    [Contract.UdmQueryRuleOperator.Gte]:
                        () =>
                            <InfraDateTimeFilterSinglePicker
                                date={value[0]!}
                                setDate={date => setValue([date])}/>,
                    [Contract.UdmQueryRuleOperator.Lte]:
                        () =>
                            <InfraDateTimeFilterSinglePicker
                                date={value[0]!}
                                setDate={date => setValue([date])}/>,
                    [Contract.UdmQueryRuleOperator.Between]:
                        () =>
                            <InfraDateTimeFilterRangePicker
                                dates={value}
                                setDates={dates => setValue(dates)}/>
                })}
        </Filter>);
}

type InfraDateTimeFilterSinglePickerProps = {
    date: moment.Moment;
    setDate: (date: moment.Moment) => void;
};

function InfraDateTimeFilterSinglePicker({ date, setDate }: InfraDateTimeFilterSinglePickerProps) {
    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}>
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                slots={{ actionBar: () => <React.Fragment/> }}
                value={date}
                onChange={date => setDate(date!)}/>
        </LocalizationProvider>);
}

type InfraDateTimeFilterRangePickerProps = {
    dates: moment.Moment[];
    setDates: (dates: [moment.Moment, moment.Moment]) => void;
};

function InfraDateTimeFilterRangePicker({ dates, setDates }: InfraDateTimeFilterRangePickerProps) {
    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}>
            <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                slots={{ actionBar: () => <React.Fragment/> }}
                value={[
                    dates[0],
                    dates[1]
                ]}
                onChange={([startTime, endTime]: [any, any]) => setDates([startTime, endTime])}/>
        </LocalizationProvider>);
}

const operators = [
    Contract.UdmQueryRuleOperator.Gte,
    Contract.UdmQueryRuleOperator.Lte,
    Contract.UdmQueryRuleOperator.Between
];