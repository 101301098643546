import { Avatar, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Link, Tooltip, useLocalization } from "@infrastructure";
import { LicensingHelper, UrlHelper, UserHelper, useTheme } from "../../..";

export function CustomerInfo() {
    const localization =
        useLocalization(
            "common.topbar.customerInfo",
            () => ({
                customerIdTooltip: {
                    learnMore: "Learn more.",
                    title: "Your Customer ID, used to register for the Tenable Community. {{link}}"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1.5}
            sx={{ maxWidth: theme.spacing(38) }}>
            <Avatar
                sx={{
                    backgroundColor: theme.palette.avatar.identity.defaultBackground,
                    border: theme.border.primary,
                    borderRadius: theme.spacing(1.5),
                    color: theme.palette.avatar.identity.color,
                    fontSize: "18px",
                    height: theme.spacing(6),
                    width: theme.spacing(6)
                }}>
                {_.first(UserHelper.nameValue)}
            </Avatar>
            <Stack
                spacing={0.5}
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    padding: theme.spacing(1.5, 0)
                }}>
                <Typography
                    noWrap={true}
                    variant="h4">
                    {UserHelper.nameValue}
                </Typography>
                <Typography noWrap={true}>
                    {UserHelper.mail}
                </Typography>
                {!_.isNil(UserHelper.customer) && (
                    <Stack
                        direction="row"
                        spacing={0.5}>
                        <Typography
                            noWrap={true}
                            sx={{
                                color: theme.palette.text.secondary,
                                fontSize: "10px"
                            }}>
                            {UserHelper.customer.name}
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.text.secondary,
                                fontSize: "10px"
                            }}>
                            {`(${LicensingHelper.getActiveLicenseShortName()})`}
                        </Typography>
                        {!_.isNil(UserHelper.customer.supportId) &&
                            <Fragment>
                                <Divider
                                    flexItem={true}
                                    orientation="vertical"/>
                                <Tooltip
                                    titleOrGetTitle={
                                        localization.customerIdTooltip.title({
                                            link:
                                                <Link
                                                    urlOrGetUrl={UrlHelper.supportUrl}
                                                    variant="external">
                                                    {localization.customerIdTooltip.learnMore()}
                                                </Link>
                                        })}>
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontSize: "10px"
                                        }}>
                                        {UserHelper.customer.supportId}
                                    </Typography>
                                </Tooltip>
                            </Fragment>}
                    </Stack>)}
            </Stack>
        </Stack>);
}