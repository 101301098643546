﻿import { Typography } from "@mui/material";
import React from "react";
import { Contract } from "../controllers";
import { useTheme } from "../themes";

type SeveritySquareProps = {
    severity: Contract.Severity;
};

export function SeveritySquare({ severity }: SeveritySquareProps) {
    const theme = useTheme();
    return (
        <Typography
            component="span"
            style={{ color: theme.palette.severity(severity) }}>
            ■&nbsp;
        </Typography>);
}