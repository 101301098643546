import { ApiError, ConfirmButton, FormLayout, makeContextProvider, Message, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, CircularProgress, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactElement, useMemo, useState } from "react";
import { ConfigurationController, Contract, ScopeHelper, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { useEligibilitiesContext, useSetEligibilitiesContext } from "../../PermissionEligibilities";
import { PermissionEligibilityData, useDefinition } from "./hooks";

export class AddOrEditContext {
    constructor(
        public add: boolean,
        public fieldNameToValidMap: Dictionary<boolean>,
        public fieldNameToValidationExecutingMap: Dictionary<boolean>,
        public permissionEligibilityData: PermissionEligibilityData,
        public upsertButtonConfirmDisabled: boolean,
        public upsertButtonConfirmMessage: Optional<ReactElement>,
        public upsertPermissionEligibilityError: Contract.ConfigurationControllerUpsertPermissionEligibilityError | boolean,
        public upsertPermissionEligibilityExecuting: boolean) {
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen, editMode, triggerPermissionEligibilityChange } = useEligibilitiesContext();
    const setEligibilitiesContext = useSetEligibilitiesContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { createInsertPermissionEligibilityRequest, createPermissionEligibilityData, createUpdatePermissionEligibilityRequest, fieldElements } = useDefinition(ScopeHelper.getTenantType(scopeNodeModel)!);

    const [upsertPermissionEligibilityError, setUpsertPermissionEligibilityError] = useState<Contract.ConfigurationControllerUpsertPermissionEligibilityError | boolean>(false);
    const [upsertPermissionEligibilityExecuting, setUpsertPermissionEligibilityExecuting] = useState(false);
    const permissionEligibilityModel =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;

    const [context, setContext, ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isNil(permissionEligibilityModel),
                    {},
                    {},
                    createPermissionEligibilityData(
                        permissionEligibilityModel,
                        scopeNodeModel.configuration.id),
                    true,
                    undefined,
                    upsertPermissionEligibilityError,
                    upsertPermissionEligibilityExecuting));

    useChangeEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    upsertPermissionEligibilityError,
                    upsertPermissionEligibilityExecuting
                }));
        },
        [upsertPermissionEligibilityExecuting, upsertPermissionEligibilityError]);

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit",
            () => ({
                actions: {
                    add: {
                        error: "Failed to create",
                        title: "Add"
                    },
                    close: "Cancel",
                    edit: {
                        error: "Failed to save",
                        title: "Save"
                    }
                },
                title: {
                    add: "Add Eligibility",
                    edit: "Update Eligibility"
                }
            }));

    const valid =
        useMemo(
            () =>
                _(context.fieldNameToValidMap).
                    values().
                    every(),
            [context.fieldNameToValidMap]);

    const validationExecuting =
        useMemo(
            () =>
                _(context.fieldNameToValidationExecutingMap).
                    values().
                    some(),
            [context.fieldNameToValidationExecutingMap]);

    async function upsertPermissionEligibility() {
        setUpsertPermissionEligibilityError(false);
        setUpsertPermissionEligibilityExecuting(true);

        try {
            const { scopeSystemEntityModel } =
                editMode
                    ? await ConfigurationController.updatePermissionEligibility(createUpdatePermissionEligibilityRequest(context.permissionEligibilityData, permissionEligibilityModel!.configuration.id))
                    : await ConfigurationController.insertPermissionEligibility(createInsertPermissionEligibilityRequest(context.permissionEligibilityData));

            await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            await triggerPermissionEligibilityChange();

            setEligibilitiesContext(
                eligibilitiesContext => ({
                    ...eligibilitiesContext,
                    addOrEditOpen: false,
                    editMode: false
                }));
        } catch (error) {
            setUpsertPermissionEligibilityError(
                error instanceof ApiError &&
                error.statusCode === 400
                    ? error.error as Contract.ConfigurationControllerUpsertPermissionEligibilityError
                    : true);
        }

        setUpsertPermissionEligibilityExecuting(false);
    }

    const theme = useTheme();
    return (
        <ContextProvider>
            <FormLayout
                footerOptions={{
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}>
                            {upsertPermissionEligibilityError && (
                                <Message
                                    level="error"
                                    title={
                                        editMode
                                            ? localization.actions.edit.error()
                                            : localization.actions.add.error()}/>)}
                            {(validationExecuting ||
                                upsertPermissionEligibilityExecuting) && (
                                <CircularProgress
                                    size={theme.spacing(3)}
                                    variant="indeterminate"/>)}
                            <Box>
                                <ConfirmButton
                                    disableConfirm={context.upsertButtonConfirmDisabled}
                                    disabled={
                                        !valid ||
                                        validationExecuting ||
                                        upsertPermissionEligibilityExecuting}
                                    messageElement={context.upsertButtonConfirmMessage}
                                    variant="contained"
                                    onClick={upsertPermissionEligibility}>
                                    {editMode
                                        ? localization.actions.edit.title()
                                        : localization.actions.add.title()}
                                </ConfirmButton>
                            </Box>
                        </Stack>
                }}
                titleOptions={{
                    text:
                        editMode
                            ? localization.title.edit()
                            : localization.title.add()
                }}>
                <Stack spacing={2}>
                    {fieldElements}
                </Stack>
            </FormLayout>
        </ContextProvider>);
}