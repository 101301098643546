﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EventsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path clipRule="evenodd" d="M5 12.5C5 11.9477 5.3731 11.5 5.83333 11.5H10.1667C10.6269 11.5 11 11.9477 11 12.5C11 13.0523 10.6269 13.5 10.1667 13.5H5.83333C5.3731 13.5 5 13.0523 5 12.5Z"/>
            <path clipRule="evenodd" d="M5 17C5 16.4477 5.41041 16 5.91667 16H15.0833C15.5896 16 16 16.4477 16 17C16 17.5523 15.5896 18 15.0833 18H5.91667C5.41041 18 5 17.5523 5 17Z"/>
            <path clipRule="evenodd" d="M22 6C22 8.20914 20.2091 10 18 10C15.7909 10 14 8.20914 14 6C14 3.79086 15.7909 2 18 2C20.2091 2 22 3.79086 22 6ZM24 6C24 9.31371 21.3137 12 18 12C14.6863 12 12 9.31371 12 6C12 2.68629 14.6863 0 18 0C21.3137 0 24 2.68629 24 6ZM3 6C3 5.44772 3.44772 5 4 5H9.5C10.0523 5 10.5 4.55228 10.5 4C10.5 3.44772 10.0523 3 9.5 3H4C2.34315 3 1 4.34315 1 6V21C1 22.6569 2.34315 24 4 24H17C18.6569 24 20 22.6569 20 21V15C20 14.4477 19.5523 14 19 14C18.4477 14 18 14.4477 18 15V21C18 21.5523 17.5523 22 17 22H4C3.44772 22 3 21.5523 3 21V6Z"/>
        </SvgIcon>);
}