import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureContainerServiceManagedClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureContainerServiceManagedClusterVirtualMachineInboundExternalRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AzureContainerServiceManagedClusterModel;

    const getAzureContainerServiceManagedClusterRiskContext = useGetAzureContainerServiceManagedClusterRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskDefinition",
            () => ({
                contextItems: {
                    nodePublicIpAddressExistsAgentPools: "The {{translatedClusterTypeName}} has {{nodePublicIpAddressExistsAgentPools}} configured to assign public IPs to nodes",
                    virtualMachineDatas: {
                        withInboundExternalWideRangeVirtualMachines: {
                            many: [
                                "The {{translatedClusterTypeName}} has {{virtualMachines}} with a public IP address. {{inboundExternalWideRangeVirtualMachines}} of them is accessible directly from a wide range of public IP addresses",
                                "The {{translatedClusterTypeName}} has {{virtualMachines}} with a public IP address. {{inboundExternalWideRangeVirtualMachines}} of them are accessible directly from a wide range of public IP addresses"
                            ],
                            single: "The {{translatedClusterTypeName}} has {{virtualMachines}} that is accessible directly from a wide range of public IP addresses"
                        },
                        withoutInboundExternalWideRangeVirtualMachines: "The {{translatedClusterTypeName}} has {{virtualMachines}} with a public IP address"
                    }
                },
                description: "{{cluster}} contains nodes with public IPs",
                sections: {
                    resolution: {
                        step1: "Click **Add node pool**",
                        step2: "Fill in the node pool details, and under the **Optional settings** tab, make sure that **Enable public IP per node** is disabled",
                        step3: "Review and create the node pool",
                        step4: "Move your Kubernetes resources to the new node pool",
                        step5: "Repeat the steps above for any additional node pools that are required"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.ClusterNodePools}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAzureContainerServiceManagedClusterRiskContext(clusterModel);

            const inboundExternalWideRangeVirtualMachineIds =
                _(risk.virtualMachineDatas).
                    filter(virtualMachineData => virtualMachineData.inboundExternalWideRange).
                    map(virtualMachineData => virtualMachineData.id).
                    value();
            const virtualMachinesElement =
                <InlineEntities
                    entityIdsOrModels={
                        _.map(
                            risk.virtualMachineDatas,
                            virtualMachineData => virtualMachineData.id)}
                    entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine}
                    variant="itemCountAndType"/>;
            const translatedClusterTypeName =
                entityTypeNameTranslator(
                    clusterModel.entity.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    });

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.virtualNetwork,
                _.isEmpty(risk.nodePublicIpAddressExistsAgentPoolIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.nodePublicIpAddressExistsAgentPools({
                            nodePublicIpAddressExistsAgentPools:
                                <InlineEntities
                                    entityIdsOrModels={risk.nodePublicIpAddressExistsAgentPoolIds}
                                    entityTypeName={Contract.TypeNames.AzureContainerServiceManagedClusterAgentPool}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(risk.virtualMachineDatas)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        _.isEmpty(inboundExternalWideRangeVirtualMachineIds)
                            ? localization.contextItems.virtualMachineDatas.withoutInboundExternalWideRangeVirtualMachines({
                                translatedClusterTypeName,
                                virtualMachines: virtualMachinesElement
                            })
                            : _.size(risk.virtualMachineDatas) > 1
                                ? localization.contextItems.virtualMachineDatas.withInboundExternalWideRangeVirtualMachines.many(
                                    _.size(inboundExternalWideRangeVirtualMachineIds),
                                    {
                                        inboundExternalWideRangeVirtualMachines:
                                        <InlineEntities
                                            entityIdsOrModels={inboundExternalWideRangeVirtualMachineIds}
                                            entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine}
                                            variant="itemCount"/>,
                                        translatedClusterTypeName,
                                        virtualMachines: virtualMachinesElement
                                    })
                                : localization.contextItems.virtualMachineDatas.withInboundExternalWideRangeVirtualMachines.single({
                                    translatedClusterTypeName,
                                    virtualMachines: virtualMachinesElement
                                })),
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}