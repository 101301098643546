import { InlineItems, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsLambdaFunctionConfigurationRiskContext } from "../contexts";

export function useAwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRisk;
    const functionConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsLambdaFunctionConfigurationModel;
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;
    const vpcModel = entityModelStore.useGet(functionConfiguration.vpcId) as Contract.AwsEc2VpcModel;

    const getAwsLambdaFunctionConfigurationRiskContext = useGetAwsLambdaFunctionConfigurationRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskDefinition",
            () => ({
                contextItems: {
                    multiAvailabilityZoneNotExist: {
                        text: [
                            "The Lambda function has **{{versions}}** that is using **{{vpcs}}**",
                            "The Lambda function has **{{versions}}** that are using **{{vpcs}}**"
                        ],
                        versions: [
                            "1 version",
                            "{{count | NumberFormatter.humanize}} versions"
                        ],
                        vpcs: [
                            "1 vpc",
                            "{{count | NumberFormatter.humanize}} vpcs"
                        ]
                    }
                },
                description: "{{functionConfiguration}} does not operate in multiple Availability Zones",
                sections: {
                    resolution: {
                        step1: {
                            step1: "Click **Create subnet**",
                            step2: "Under **VPC ID**, choose the **{{vpc}}**",
                            step3: "For **Subnet name**, enter a name for your subnet",
                            step4: "For **Availability Zone**, select the relevant AZ that is not already associated with the VPC",
                            step5: "Click **Create subnet**",
                            title: "The VPC associated with the Lambda function must cover multiple Availability Zones (AZs). Ensure that at least two subnets from different AZs are associated with your Lambda function. If the VPC already covers multiple AZs, you can skip this step."
                        },
                        step2: {
                            step1: "Open the Lambda function console and select **{{functionConfiguration}}**",
                            step2: "Older versions are immutable and cannot be modified. To remediate the issues, you need to publish a new version, update all aliases and dependent services to reference the newly created version, and then delete the outdated versions",
                            step3: "Click on the **Configuration** tab and then select **VPC**",
                            step4: "Click **Edit**",
                            step5: "Under the **Subnets** section, select the newly created subnet. Make sure to select at least 2 subnets from different availability zones.",
                            step6: "Click **Save**",
                            step7: "Test your Lambda function to verify that it can operate successfully in multiple AZs",
                            title: "Update the Lambda function to use a subnet from an additional Availability Zone"
                        },
                        step3: {
                            step1: "Click **Actions** and then **Publish new version**",
                            step2: "Fill in the **Version description** and click **Publish**",
                            step3: "Update all aliases and dependent services that reference older outdated versions, whether by ARN or specific version",
                            step4: "Before deleting the outdated versions, make sure your environment is properly configured and running",
                            step5: "Delete the outdated versions",
                            title: "Publish the new Lambda function version and delete older versions"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            functionConfiguration:
                <Entity
                    entityIdOrModel={functionConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Subnets,
                AwsConsoleUrlBuilder.getEc2SubnetsVpcUrl(functionConfiguration, (vpcModel.entity as Contract.AwsEc2Vpc).displayName)),
            new Step(
                localization.sections.resolution.step1.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2({
                                vpc:
                                    <Entity
                                        entityIdOrModel={functionConfiguration.vpcId!}
                                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                                        variant="typeText"/>
                            })}
                            {localization.sections.resolution.step1.step3()}
                            {localization.sections.resolution.step1.step4()}
                            {localization.sections.resolution.step1.step5()}
                        </Steps>
                }
            ),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step2.step1({
                                functionConfiguration:
                                    <Entity
                                        entityIdOrModel={functionConfigurationModel}
                                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                                        variant="typeText"/>
                            })}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                            {localization.sections.resolution.step2.step6()}
                            {localization.sections.resolution.step2.step7()}
                        </Steps>
                }
            ),
            new Step(
                localization.sections.resolution.step3.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step3.step1()}
                            {localization.sections.resolution.step3.step2()}
                            {localization.sections.resolution.step3.step3()}
                            {localization.sections.resolution.step3.step4()}
                            {localization.sections.resolution.step3.step5()}
                        </Steps>
                }
            )
        ],
        riskModel,
        () => {
            const functionConfigurationRiskContext = getAwsLambdaFunctionConfigurationRiskContext(functionConfigurationModel);
            return [
                functionConfigurationRiskContext.generalInformation,
                functionConfigurationRiskContext.accessLevel,
                new RiskDefinitionContextItem(
                    localization.contextItems.multiAvailabilityZoneNotExist.text(
                        risk.multiAvailabilityZoneNotExistVersions.length,
                        {
                            versions:
                                <InlineItems
                                    items={risk.multiAvailabilityZoneNotExistVersions}
                                    namePluralizer={localization.contextItems.multiAvailabilityZoneNotExist.versions}
                                    variant="itemCountAndType"/>,
                            vpcs:
                                <InlineEntities
                                    entityIdsOrModels={risk.multiAvailabilityZoneNotExistVpcIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                                    namePluralizer={localization.contextItems.multiAvailabilityZoneNotExist.vpcs}
                                    variant="itemCountAndType"/>
                        })),
                functionConfigurationRiskContext.sensitive,
                functionConfigurationRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}