import { defined, PasswordTextField, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { Contract, TenantController, tenantModelStore, UrlHelper, useTheme } from "../../../../../../../../../../../../../../../common";

export function TenantItem() {
    const tenantModels = tenantModelStore.useGetPermittedOktaTenants();
    const { tenantConfiguration, tenantName, tenantUrl, tenantUserToken } = useAddOrEditContext();
    const [name, setName] = useState(tenantName);
    const [url, setUrl] = useState(tenantUrl);
    const [userToken, setUserToken] = useState(tenantUserToken);

    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: undefined
                }));
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oktaTenants.addOrEdit.tenantItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add Okta organization",
                            edit: "Failed to save Okta organization"
                        }
                    }
                },
                fields: {
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    url: {
                        error: {
                            exists: "Okta organization URL already exists",
                            invalidFormat: "Okta organization URL must be https://{domain}",
                            required: "Okta organization URL cannot be empty"
                        },
                        title: "Organization URL"
                    },
                    userToken: {
                        error: {
                            required: "User token cannot be empty"
                        },
                        title: "User token"
                    }
                }
            }));

    useNextEffect(
        async () => {
            try {
                let updatedTenantModel: Contract.OktaTenantModel;
                if (!_.isNil(tenantConfiguration)) {
                    const { tenantModel } =
                        await TenantController.updateOktaTenant(
                            new Contract.TenantControllerUpdateOktaTenantRequest(
                                tenantConfiguration.id,
                                name.trim(),
                                url.trim(),
                                userToken?.trim()));
                    updatedTenantModel = tenantModel;
                } else {
                    const { tenantModel } =
                        await TenantController.insertOktaTenant(
                            new Contract.TenantControllerInsertOktaTenantRequest(
                                name.trim(),
                                url.trim(),
                                defined(userToken?.trim())));
                    updatedTenantModel = tenantModel;
                }

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        updatedTenantModel
                    }));
            } catch {
                return (
                    _.isNil(tenantConfiguration)
                        ? localization.actions.save.error.add()
                        : localization.actions.save.error.edit());
            }
        },
        [name, url, userToken]);

    const existingTenantConfigurations =
        useMemo(
            () =>
                _(tenantModels).
                    filter(tenantModel => tenantModel.configuration.id !== tenantConfiguration?.id).
                    map(tenantModel => tenantModel.configuration as Contract.OktaTenantConfiguration).
                    value(),
            [tenantModels]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }
                if (_.some(
                    existingTenantConfigurations,
                    existingTenantConfiguration => existingTenantConfiguration.name === validationName)) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [urlValidationController, urlValidationMessage] =
        useInputValidation(
            () => {
                const validationUrl = url.trim();
                if (_.isEmpty(validationUrl)) {
                    return localization.fields.url.error.required();
                }
                const urlData = UrlHelper.getUrlData(validationUrl);
                if (
                    _.isNil(urlData) ||
                    urlData.protocolType !== "https" ||
                    urlData.pathname !== "/") {
                    return localization.fields.url.error.invalidFormat();
                }
                if (_.some(
                    existingTenantConfigurations,
                    existingTenantConfiguration => UrlHelper.getUrlData(existingTenantConfiguration.url)!.hostname === urlData.hostname)) {
                    return localization.fields.url.error.exists();
                }

                return undefined;
            },
            [url]);

    const [userTokenValidationController, userTokenValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(tenantConfiguration) &&
                    _.isNil(userToken)) {
                    return undefined;
                }

                const validationUserToken = userToken?.trim();
                if (_.isEmpty(validationUserToken)) {
                    return localization.fields.userToken.error.required();
                }

                return undefined;
            },
            [userToken]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantName: name.trim(),
                    tenantUrl: url.trim(),
                    tenantUserToken: userToken?.trim()
                }));

            setValid(
                nameValidationController.isValid() &&
                urlValidationController.isValid() &&
                userTokenValidationController.isValid());
        },
        [name, url, userToken]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.name.title()}
                    value={name}
                    variant="outlined"
                    onChange={event => setName(event.target.value)}/>
                {!_.isNil(nameValidationMessage) &&
                    <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.url.title()}
                    slotProps={{
                        input: {
                            readOnly: !_.isNil(tenantConfiguration)
                        }
                    }}
                    value={url}
                    variant="outlined"
                    onChange={event => setUrl(event.target.value)}/>
                {!_.isNil(urlValidationMessage) &&
                    <FormHelperText error={true}>{urlValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <PasswordTextField
                    disabled={executing}
                    label={localization.fields.userToken.title()}
                    password={userToken}
                    variant="outlined"
                    onPasswordChanged={userToken => setUserToken(userToken)}/>
                {!_.isNil(userTokenValidationMessage) &&
                    <FormHelperText error={true}>{userTokenValidationMessage}</FormHelperText>}
            </FormControl>
        </Stack>);
}