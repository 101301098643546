import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../common";

export function useGciTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.gciTenants.hooks.useGciTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.GciTenantModelStatus]: {
                    [Contract.AwsTenantModelStatus.AccessDenied]: "Access denied",
                    [Contract.GciTenantModelStatus.ApplicationServiceAccountUnauthorized]: "Service account impersonation permissions not granted",
                    [Contract.GciTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.GciTenantModelStatus.ServiceAccountNotExist]: "Cannot find service account",
                    [Contract.GciTenantModelStatus.ServiceAccountTenantExternalIdTagMismatch]: "Service account project external ID label does not match",
                    [Contract.GciTenantModelStatus.ServiceAccountTenantUnauthorized]: "Service account is missing permissions on the project",
                    [Contract.GciTenantModelStatus.ServiceAccountUnauthorized]: "Service account is missing permissions",
                    [Contract.GciTenantModelStatus.ServiceAccountUserDelegationMandatoryAuthorizationScopesMismatch]: "Service account is missing Domain Wide Delegation permissions",
                    [Contract.GciTenantModelStatus.ServiceAccountUserDelegationOptionalAuthorizationScopesMismatch]: "Service account is missing Domain Wide Delegation permissions",
                    [Contract.GciTenantModelStatus.UserNotExist]: "Cannot find user in customer",
                    [Contract.GciTenantModelStatus.UserTenantMismatch]: "Cannot find user in customer",
                    [Contract.GciTenantModelStatus.UserUnauthorized]: "User is not Super Admin",
                    [Contract.GciTenantModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.GciTenantModelStatus) =>
        localization[Contract.TypeNames.GciTenantModelStatus][status]();
}