import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { getScopeConfigurationValueType, getScopeConfigurationValueTypeValue, RadioGroup, RadioGroupItem, ScopeConfigurationValueType, ScopeHelper, ScopeLabel, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { useInheritedScopeId, useUpdateConfiguration } from "../../../../hooks";

export function VirtualMachineEnabled() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const inheritDisabled = scopeNodeModel.configuration.id === ScopeHelper.customerId;
    const inheritedEnabledScopeId = useInheritedScopeId(workloadAnalysis => workloadAnalysis.virtualMachine.enabled);

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.virtualMachines.virtualMachineEnabled",
            () => ({
                options: {
                    false: "Disabled",
                    inherit: "Inherit",
                    true: "Enabled"
                },
                title: "Virtual Machine Scanning",
                update: {
                    error: "Failed",
                    success: "Success",
                    text: "Update"
                }
            }));


    const items =
        useMemo(
            () => {
                const inheritedEnabled =
                    _.isNil(inheritedEnabledScopeId)
                        ? undefined
                        : scopeNodeMap[inheritedEnabledScopeId!].scopeNodeModel.configuration.scopeSections.workloadAnalysis.virtualMachine.enabled;

                return _<RadioGroupItem<ScopeConfigurationValueType>>([]).
                    concatIf(
                        !inheritDisabled,
                        {
                            label:
                                <ScopeLabel
                                    inheritedScopeId={inheritedEnabledScopeId}
                                    text={localization.options.inherit()}
                                    translatedInheritedValue={
                                        inheritedEnabled
                                            ? localization.options.true()
                                            : localization.options.false()}/>,
                            value: ScopeConfigurationValueType.Inherit
                        }).
                    concat(
                        {
                            label: <ScopeLabel text={localization.options.true()}/>,
                            value: ScopeConfigurationValueType.Enabled
                        },
                        {
                            label: <ScopeLabel text={localization.options.false()}/>,
                            value: ScopeConfigurationValueType.Disabled
                        }).
                    value();
            },
            [inheritDisabled, inheritedEnabledScopeId]);

    const updateConfiguration = useUpdateConfiguration();

    return (
        <RadioGroup
            items={items}
            selectedValue={getScopeConfigurationValueType(scopeNodeModel.configuration.scopeSections.workloadAnalysis.virtualMachine.enabled)}
            title={localization.title()}
            onChange={
                async (value: ScopeConfigurationValueType) => {
                    const virtualMachineEnabled = getScopeConfigurationValueTypeValue(value);
                    await updateConfiguration({ virtualMachineEnabled });
                }}/>);
}