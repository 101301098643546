import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessage, optionalTableCell, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, Entity, entityModelStore } from "../../../../../../../../../../../common";

type GroupTableProp = {
    entitySnapshot: Contract.EntitySnapshot;
    groupIds: string[];
};

class GroupTableItem {
    constructor(
        public groupModel: Contract.AwsIamGroupModel,
        public policyModels: Contract.AwsIamPrincipalPolicyModel[],
        public entitySnapshotChangeTypeName?: string) {
    }
}

export function GroupTable({ entitySnapshot, groupIds }: GroupTableProp) {
    const groupModels = entityModelStore.useGet(groupIds);
    const policyModels =
        entityModelStore.useGet(
            _.flatMap(
                groupModels,
                groupModel =>
                    _.concat(
                        (groupModel.entity as Contract.AwsIamGroup).inlinePolicyIds,
                        (groupModel.entity as Contract.AwsIamGroup).managedPolicyIds)));
    const items =
        useMemo(
            () => {
                const policyModelMap =
                    _.keyBy(
                        policyModels,
                        policyModel => policyModel.entity.id);
                return _.map(
                    groupModels,
                    groupModel =>
                        new GroupTableItem(
                            groupModel as Contract.AwsIamGroupModel,
                            _((groupModel.entity as Contract.AwsIamGroup).inlinePolicyIds).
                                concat((groupModel.entity as Contract.AwsIamGroup).managedPolicyIds).
                                map(policyId => policyModelMap[policyId] as Contract.AwsIamPrincipalPolicyModel).
                                value(),
                            _(entitySnapshot.changes).
                                filter(entitySnapshotChange => entitySnapshotChange.entityId === groupModel.entity.id).
                                some()
                                ? Contract.TypeNames.AwsIamUserAddGroupSnapshotChange
                                : undefined));
            },
            [groupModels, policyModels]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.snapshots.snapshot.groupTable",
            () => ({
                columns: {
                    entitySnapshotChangeTypeName: {
                        title: "Group Change",
                        [Contract.TypeNames.EntitySnapshotChange]: {
                            [Contract.TypeNames.AwsIamUserAddGroupSnapshotChange]: "Added"
                        }
                    },
                    groupModel: "Group Name",
                    policyModels: "Group Policies"
                },
                empty: "No Group Memberships",
                title: "GROUP MEMBERSHIPS"
            }));
    return (
        <Stack spacing={1}>
            <Typography variant="subtitle1">
                {localization.title()}
            </Typography>
            {_.isEmpty(groupModels)
                ? <EmptyMessage message={localization.empty()}/>
                : <DataTable
                    fetchItems={() => items}
                    getItemId={(item: GroupTableItem) => item.groupModel.id}
                    sortOptions={{ enabled: false }}
                    variant="card">
                    <DataTableColumn
                        id={GroupTableColumnId.GroupModel}
                        render={
                            ({ item }: DataTableColumnRenderProps<GroupTableItem>) =>
                                <Entity
                                    entityIdOrModel={item.groupModel}
                                    variant="iconTextTenant"/>}
                        title={localization.columns.groupModel()}/>
                    <DataTableColumn
                        id={GroupTableColumnId.PolicyModels}
                        render={
                            ({ item }: DataTableColumnRenderProps<GroupTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.policyModels}
                                    entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                                    entityVariant="iconTextTypeTenant"
                                    inlineEntitiesVariant="itemCountAndType"/>}/>
                    <DataTableColumn
                        id={GroupTableColumnId.EntitySnapshotChangeTypeName}
                        render={
                            optionalTableCell<GroupTableItem>(
                                item =>
                                    _.isNil(item.entitySnapshotChangeTypeName)
                                        ? undefined
                                        : localization.columns.entitySnapshotChangeTypeName[Contract.TypeNames.EntitySnapshotChange][item.entitySnapshotChangeTypeName as Contract.TypeNames.AwsIamUserAddGroupSnapshotChange]())}
                        title={localization.columns.entitySnapshotChangeTypeName.title()}/>
                </DataTable>}
        </Stack>);
}

enum GroupTableColumnId {
    EntitySnapshotChangeTypeName = "entitySnapshotChangeTypeName",
    GroupModel = "groupModel",
    PolicyModels = "policyModels"
}