import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DashboardIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M17.746 13.6595C17.5224 14.5255 17.1539 15.3525 16.6518 16.104C15.7727 17.4196 14.5233 18.445 13.0615 19.0505C11.5997 19.656 9.99113 19.8144 8.43928 19.5058C6.88743 19.1971 5.46197 18.4351 4.34315 17.3163C3.22433 16.1975 2.4624 14.772 2.15372 13.2202C1.84504 11.6683 2.00347 10.0598 2.60897 8.598C3.21447 7.13619 4.23985 5.88676 5.55544 5.00771C6.30701 4.50553 7.13401 4.13711 8 3.9135V9.65947C8 11.8686 9.79087 13.6595 12 13.6595H17.746ZM7.99975 3.65649L7.99999 3.65868L7.99975 3.65649ZM18.0008 13.6595L18.003 13.6597L18.0008 13.6595ZM19.8007 13.6462C20.0201 12.5636 19.1046 11.6595 18 11.6595H12C10.8954 11.6595 10 10.764 10 9.65947V3.65947C10 2.5549 9.09583 1.63935 8.01328 1.85881C6.74447 2.11602 5.53127 2.61848 4.4443 3.34477C2.79981 4.44359 1.51809 6.00537 0.761209 7.83264C0.00433284 9.6599 -0.193701 11.6706 0.192152 13.6104C0.578004 15.5502 1.53041 17.332 2.92894 18.7305C4.32746 20.1291 6.10929 21.0815 8.0491 21.4673C9.98891 21.8532 11.9996 21.6551 13.8268 20.8983C15.6541 20.1414 17.2159 18.8597 18.3147 17.2152C19.041 16.1282 19.5435 14.915 19.8007 13.6462Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M13.9131 2.08743V7.8334L19.6591 7.8334C19.5659 7.47252 19.4474 7.1178 19.3041 6.77193C18.9021 5.80132 18.3128 4.91941 17.5699 4.17654C16.8271 3.43367 15.9452 2.8444 14.9746 2.44236C14.6287 2.2991 14.274 2.18061 13.9131 2.08743ZM19.9161 7.83314L19.9139 7.83339L19.9161 7.83314ZM13.9131 1.8326L13.9133 1.83041L13.9131 1.8326ZM13.8998 0.0327329C12.8172 -0.186713 11.9131 0.728826 11.9131 1.8334V7.8334C11.9131 8.93797 12.8085 9.8334 13.9131 9.8334H19.9131C21.0177 9.8334 21.9332 8.92924 21.7137 7.84669C21.5863 7.21819 21.3985 6.60189 21.1519 6.00656C20.6493 4.79331 19.9127 3.69091 18.9842 2.76233C18.0556 1.83374 16.9532 1.09715 15.7399 0.5946C15.1446 0.348008 14.5283 0.160136 13.8998 0.0327329Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}