import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function IBMIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2662)">
                <path
                    d="M0 15.9572H4.66639V16.6252H0V15.9572Z"
                    fill="#1F70C1"/>
                <path
                    d="M0 14.6759H4.66639V15.3463H0V14.6759Z"
                    fill="#1F70C1"/>
                <path
                    d="M1.33123 13.397H3.33281V14.0674H1.33123V13.397Z"
                    fill="#1F70C1"/>
                <path
                    d="M1.33123 12.1181H3.33281V12.7884H1.33123V12.1181Z"
                    fill="#1F70C1"/>
                <path
                    d="M1.33123 10.8391H3.33281V11.5095H1.33123V10.8391Z"
                    fill="#1F70C1"/>
                <path
                    d="M1.33123 9.55785H3.33281V10.2282H1.33123V9.55785Z"
                    fill="#1F70C1"/>
                <path
                    d="M4.66639 8.27891H0V8.94928H4.66639V8.27891Z"
                    fill="#1F70C1"/>
                <path
                    d="M4.66639 7H0V7.67037H4.66639V7Z"
                    fill="#1F70C1"/>
                <path
                    d="M5.33201 15.3463H12.5087C12.6299 15.1395 12.7203 14.9136 12.775 14.6759H5.33201V15.3463Z"
                    fill="#1F70C1"/>
                <path
                    d="M11.9501 12.1181H6.66562V12.7884H12.5087C12.3566 12.5317 12.1688 12.3059 11.9501 12.1181Z"
                    fill="#1F70C1"/>
                <path
                    d="M6.66562 10.8391V11.5095H11.9501C12.1735 11.3217 12.3613 11.0959 12.5087 10.8391H6.66562Z"
                    fill="#1F70C1"/>
                <path
                    d="M12.5087 8.27891H5.33201V8.94928H12.775C12.7132 8.71156 12.6228 8.48574 12.5087 8.27891Z"
                    fill="#1F70C1"/>
                <path
                    d="M10.3027 7H5.33201V7.67037H12.0166C11.5697 7.25673 10.9636 7 10.3027 7Z"
                    fill="#1F70C1"/>
                <path
                    d="M8.66482 9.55785H6.66562V10.2282H8.66482V9.55785Z"
                    fill="#1F70C1"/>
                <path
                    d="M10.6664 10.2282H12.7583C12.8177 10.0143 12.8486 9.78844 12.8486 9.55785H10.6664V10.2282Z"
                    fill="#1F70C1"/>
                <path
                    d="M6.66562 13.397H8.66482V14.0674H6.66562V13.397Z"
                    fill="#1F70C1"/>
                <path
                    d="M10.6664 13.397V14.0674H12.8486C12.8486 13.8368 12.8177 13.6109 12.7583 13.397H10.6664Z"
                    fill="#1F70C1"/>
                <path
                    d="M5.33201 16.6204L10.3027 16.6276C10.9683 16.6276 11.5697 16.3708 12.019 15.9572H5.33201V16.6204Z"
                    fill="#1F70C1"/>
                <path
                    d="M13.3336 15.9572H16.6664V16.6252H13.3336V15.9572Z"
                    fill="#1F70C1"/>
                <path
                    d="M13.3336 14.6759H16.6664V15.3463H13.3336V14.6759Z"
                    fill="#1F70C1"/>
                <path
                    d="M14.6648 13.397H16.6664V14.0674H14.6648V13.397Z"
                    fill="#1F70C1"/>
                <path
                    d="M14.6648 12.1181H16.6664V12.7884H14.6648V12.1181Z"
                    fill="#1F70C1"/>
                <path
                    d="M17.5935 8.27891H13.3336V8.94928H17.8241L17.5935 8.27891Z"
                    fill="#1F70C1"/>
                <path
                    d="M17.1513 7H13.3336V7.67037H17.3819L17.1513 7Z"
                    fill="#1F70C1"/>
                <path
                    d="M20.6648 15.9572H24V16.6252H20.6648V15.9572Z"
                    fill="#1F70C1"/>
                <path
                    d="M20.6648 14.6759H24V15.3463H20.6648V14.6759Z"
                    fill="#1F70C1"/>
                <path
                    d="M20.6648 13.397H22.6664V14.0674H20.6648V13.397Z"
                    fill="#1F70C1"/>
                <path
                    d="M20.6648 12.1181H22.6664V12.7884H20.6648V12.1181Z"
                    fill="#1F70C1"/>
                <path
                    d="M20.6648 11.5095H22.6664V10.8391H18.8534L18.6656 11.3812L18.4778 10.8391H14.6648V11.5095H16.6664V10.8938L16.878 11.5095H20.4533L20.6648 10.8938V11.5095Z"
                    fill="#1F70C1"/>
                <path
                    d="M22.6664 9.55785H19.2956L19.065 10.2282H22.6664V9.55785Z"
                    fill="#1F70C1"/>
                <path
                    d="M20.1822 7L19.9517 7.67037H24V7H20.1822Z"
                    fill="#1F70C1"/>
                <path
                    d="M18.6656 16.6204L18.8962 15.9572H18.435L18.6656 16.6204Z"
                    fill="#1F70C1"/>
                <path
                    d="M18.2235 15.3463H19.1078L19.3455 14.6759H17.9881L18.2235 15.3463Z"
                    fill="#1F70C1"/>
                <path
                    d="M17.7742 14.0674H19.557L19.7924 13.397H17.5388L17.7742 14.0674Z"
                    fill="#1F70C1"/>
                <path
                    d="M17.3273 12.7884H20.0063L20.2345 12.1181H17.0967L17.3273 12.7884Z"
                    fill="#1F70C1"/>
                <path
                    d="M14.6648 10.2282H18.2662L18.0357 9.55785H14.6648V10.2282Z"
                    fill="#1F70C1"/>
                <path
                    d="M19.5095 8.94928H24V8.27891H19.7377L19.5095 8.94928Z"
                    fill="#1F70C1"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2662">
                    <rect
                        fill="white"
                        height="24"
                        width="23.9962"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}