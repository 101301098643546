import { Store } from "@infrastructure";
import { CodeController, Contract } from "..";

export const codeContainerImageScanStore =
    new Store<Contract.CodeContainerImageScan, never, never>(
        codeScan => codeScan.id,
        {
            get:
                async ids => {
                    const { scans } = await CodeController.getContainerImageScans(new Contract.CodeControllerGetContainerImageScansRequest(ids));
                    return scans;
                }
        });