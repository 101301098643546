﻿import { useLocalization } from "@infrastructure";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo, useState } from "react";
import { Contract, LicensingHelper, useTheme } from "../../../../../../../../../../../../../../../../../common";

export type ScannerItemProps = {
    commands: ScannerItemPropsCommand[];
    title: string;
};

export type ScannerItemPropsCommand = {
    contentElement: ReactNode;
    type: ScannerCommand;
};

export function ScannerItem({ commands, title }: ScannerItemProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.scannerItem",
            () => ({
                steps: {
                    [ScannerCommand.ContainerImageScan]: "Container Image Scan",
                    [ScannerCommand.IacScan]: "IaC Scan",
                    [ScannerCommand.IacSync]: "IaC Sync"
                }
            }));

    const filteredCommands =
        useMemo(
            () =>
                _.filter(
                    commands,
                    command =>
                        (command.type != ScannerCommand.ContainerImageScan ||
                        LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp))),
            [commands]);

    const [commandIndex, setCommandIndex] = useState(0);
    const theme = useTheme();
    return (
        <Stack
            spacing={theme.spacing(2)}
            sx={{
                height: "100%",
                overflow: "hidden",
                paddingBottom: theme.spacing(2)
            }}>
            <Typography>
                {title}
            </Typography>
            <Stack
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    overflow: "auto"
                }}>
                <Tabs
                    indicatorColor="primary"
                    value={commandIndex}
                    variant="standard"
                    onChange={(_, value) => setCommandIndex(value)}>
                    {_.map(
                        filteredCommands,
                        (filteredCommand, filteredCommandIndex) =>
                            <Tab
                                label={localization.steps[filteredCommand.type]()}
                                value={filteredCommandIndex}/>)}
                </Tabs>
                <Box
                    sx={{
                        height: "100%",
                        overflow: "auto",
                        padding: theme.spacing(3)
                    }}>
                    {filteredCommands[commandIndex].contentElement}
                </Box>
            </Stack>
        </Stack>);
}

export enum ScannerCommand {
    ContainerImageScan = "containerImageScan",
    IacScan = "iacScan",
    IacSync = "iacSync"
}