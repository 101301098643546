﻿import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { OciObjectStorageBucketObjectAccessLevel, useOciObjectStorageBucketObjectVersioningTranslator } from "../../../../../../../../../../tenants";
import { useOciObjectStorageBucketLogCategoryTranslator, useOciObjectStorageBucketStorageTierTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useOciCommonEncryptedResourceInfoElements } from "./useOciCommonEncryptedResourceInfoElements";
import { useOciDefaultResourceInfoItemElements } from "./useOciDefaultResourceInfoItemElements";

export function useOciObjectStorageBucketDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const bucketModel = resourceModel as Contract.OciObjectStorageBucketModel;
    const bucket = bucketModel.entity as Contract.OciObjectStorageBucket;
    const commonEncryptedResourceInfoElements = useOciCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);

    const bucketLogCategoryTranslator = useOciObjectStorageBucketLogCategoryTranslator();
    const bucketObjectVersioningTranslator = useOciObjectStorageBucketObjectVersioningTranslator();
    const bucketStorageTierTranslator = useOciObjectStorageBucketStorageTierTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciObjectStorageBucketDefinition",
            () => ({
                info: {
                    items: {
                        logCategory: "Object-Level Logging",
                        namespace: "Namespace",
                        objectAccessLevel: "Object Access",
                        objectCount: "Object Count",
                        objectEventsEnabled: {
                            false: "Disabled",
                            title: "Emit Object Events",
                            true: "Enabled"
                        },
                        objectTieringEnabled: {
                            false: "Disabled",
                            title: "Auto Tiering",
                            true: "Enabled"
                        },
                        objectVersioning: "Object Versioning",
                        storageSize: "Size",
                        storageTier: "Default Storage Tier"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="namespace"
                        title={localization.info.items.namespace()}
                        value={bucket.namespace}/>,
                    <InfoItem
                        key="storageTier"
                        location="all"
                        title={localization.info.items.storageTier()}
                        value={bucketStorageTierTranslator(bucket.storageTier)}/>,
                    <InfoItem
                        key="objectAccessLevel"
                        location="all"
                        title={localization.info.items.objectAccessLevel()}
                        value={
                            <OciObjectStorageBucketObjectAccessLevel objectAccessLevel={bucket.objectAccessLevel}/>}/>,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.encryptionVaultKeyIdReference,
                    <InfoItem
                        key="objectTieringEnabled"
                        title={localization.info.items.objectTieringEnabled.title()}
                        value={
                            bucket.objectTieringEnabled
                                ? localization.info.items.objectTieringEnabled.true()
                                : localization.info.items.objectTieringEnabled.false()}/>,
                    <InfoItem
                        key="logCategory"
                        title={localization.info.items.logCategory()}
                        value={bucketLogCategoryTranslator(bucketModel.logCategory)}/>,
                    <InfoItem
                        key="objectEventsEnabled"
                        title={localization.info.items.objectEventsEnabled.title()}
                        value={
                            bucket.objectEventsEnabled
                                ? localization.info.items.objectEventsEnabled.true()
                                : localization.info.items.objectEventsEnabled.false()}/>,
                    <InfoItem
                        key="objectVersioning"
                        title={localization.info.items.objectVersioning()}
                        value={bucketObjectVersioningTranslator(bucket.objectVersioning)}/>,
                    <InfoItem
                        key="objectCount"
                        title={localization.info.items.objectCount()}
                        value={bucket.objectCount}/>,
                    <InfoItem
                        key="storageSize"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(bucket.storageSize)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}