import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAadDirectoryDirectoryRoleDefinitionTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryEntityDefinition } from "./useAadCommonDirectoryEntityDefinition";

export function useAadDirectoryDirectoryRoleDefinitionDefinition(definitionData: DefinitionData) {
    const commonDirectoryEntityDefinition = useAadCommonDirectoryEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const directoryRoleDefinitionTypeTranslator = useAadDirectoryDirectoryRoleDefinitionTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadDirectoryDirectoryRoleDefinitionDefinition",
            () => ({
                columns: {
                    assignedPrincipalIds: "Assigned To",
                    severePermission: {
                        false: "No",
                        title: "Privileged",
                        true: "Yes"
                    },
                    type: "Role Type"
                }
            }));
    return new EntityTableDefinition(
        [
            commonDirectoryEntityDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryDirectoryRoleDefinitionModel) => ({
                            [localization.columns.type()]: (directoryRoleDefinitionTypeTranslator((item.entity as Contract.AadDirectoryDirectoryRoleDefinition).type))
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AadDirectoryDirectoryRoleDefinitionType}
                                enumTypeTranslator={directoryRoleDefinitionTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionType}
                itemProperty={(item: Contract.EntityModel) => directoryRoleDefinitionTypeTranslator((item.entity as Contract.AadDirectoryDirectoryRoleDefinition).type)}
                key={Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AadDirectoryDirectoryRoleDefinitionModel>(
                        Contract.TypeNames.AadDirectoryPrincipal,
                        item => item.assignedPrincipalIds,
                        localization.columns.assignedPrincipalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionAssignedPrincipals)}
                                placeholder={localization.columns.assignedPrincipalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionAssignedPrincipals}
                key={Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionAssignedPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryDirectoryRoleDefinitionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.assignedPrincipalIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.assignedPrincipalIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryDirectoryRoleDefinitionModel) => ({
                            [localization.columns.severePermission.title()]:
                                (item as Contract.AadDirectoryDirectoryRoleDefinitionModel).severePermission
                                    ? localization.columns.severePermission.true()
                                    : localization.columns.severePermission.false()
                        })
                }}
                filterOptions={
                    {
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.severePermission.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.severePermission.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.severePermission.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }
                }
                id={Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionSeverePermission}
                itemProperty={(servicePrincipalModel: Contract.EntityModel) =>
                    (servicePrincipalModel as Contract.AadDirectoryDirectoryRoleDefinitionModel).severePermission
                        ? localization.columns.severePermission.true()
                        : localization.columns.severePermission.false()}
                key={Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionSeverePermission}
                title={localization.columns.severePermission.title()}/>,
            commonDirectoryEntityDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleDefinitionRequest(
                new Contract.EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleDefinitionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    undefined,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionAssignedPrincipals]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionSeverePermission]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryDirectoryRoleDefinitionType])),
                limit,
                skip,
                requestSort));
}