import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeSnapshotCreationTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeSnapshotCreationTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeSnapshotCreationType]: {
                    [Contract.GcpComputeSnapshotCreationType.Manual]: "Manual",
                    [Contract.GcpComputeSnapshotCreationType.Scheduled]: "Scheduled"
                }
            }));
    return (creationType: Contract.GcpComputeSnapshotCreationType) =>
        localization[Contract.TypeNames.GcpComputeSnapshotCreationType][creationType]();
}