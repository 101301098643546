import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useAzureCognitiveServicesAccountTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { useGetAzureCognitiveServicesAccountRiskContext } from "../contexts";

export function useAzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureCognitiveServicesAccountUnrestrictedOutboundAccessRisk;
    const accountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureCognitiveServicesAccountModel;
    const account = accountModel.entity as Contract.AzureCognitiveServicesAccount;

    const getAzureCognitiveServicesAccountRiskContext = useGetAzureCognitiveServicesAccountRiskContext();

    const cognitiveServicesAccountTypeTranslator = useAzureCognitiveServicesAccountTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskDefinition",
            () => ({
                description: "{{type}} {{account}} data loss prevention is not enabled",
                sections: {
                    resolution: {
                        step1: {
                            link: "enable data loss prevention",
                            text: "Enabling this feature is not supported via Azure console. Follow Azure documentation on how to {{enablingDataLossPreventionDocumentationLink}}"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            account:
                <Entity
                    entityIdOrModel={accountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="text"/>,
            type: cognitiveServicesAccountTypeTranslator(account.type)
        }),
        () => [
            localization.sections.resolution.step1.text({
                enablingDataLossPreventionDocumentationLink:
                    <Link
                        urlOrGetUrl={(riskModel as Contract.AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskModel).enablingDataLossPreventionDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const accountRiskContext = getAzureCognitiveServicesAccountRiskContext(accountModel);
            return [
                accountRiskContext.generalInformation,
                accountRiskContext.sensitive,
                accountRiskContext.type,
                accountRiskContext.inboundExternalAccessScope,
                accountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}