import { useLocalization } from "@infrastructure";
import { Grid2, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, scopeNodeModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../common";
import { Enabled } from "./Enabled";

export function ObjectStores() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    const objectStoreScanEnabled =
        useMemo(
            () => {
                const parentScopeIds = scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds;

                if (!_.isNil(scopeNodeModel.configuration.scopeSections.dataAnalysis.objectStoreScanEnabled)) {
                    return scopeNodeModel.configuration.scopeSections.dataAnalysis.objectStoreScanEnabled;
                }

                for (const parentScopeId of parentScopeIds) {
                    if (!_.isNil(scopeNodeMap[parentScopeId].scopeNodeModel.configuration.scopeSections.dataAnalysis.objectStoreScanEnabled)) {
                        return scopeNodeMap[parentScopeId].scopeNodeModel.configuration.scopeSections.dataAnalysis.objectStoreScanEnabled;
                    }
                }
            },
            [scopeNodeModel, scopeNodeMap]);

    const localization =
        useLocalization(
            "views.customer.configuration.dataAnalysis.objectStores",
            () => ({
                objectStoreScanEnabled: "Object Storage Scanning",
                privateObjectStoreScanEnabled: "Private Object Storage Scanning"
            }));

    const theme = useTheme();
    return (
        <Grid2
            size={{ xs: 8 }}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(125),
                overflowY: "auto",
                padding: theme.spacing(3, 4)
            }}>
            <Stack>
                <Enabled
                    getConfigurationControllerUpdateDataAnalysisRequest={
                        (scopeNodeModelConfiguration, enabled) =>
                            new Contract.ConfigurationControllerUpdateDataAnalysisRequest(
                                scopeNodeModelConfiguration.scopeSections.dataAnalysis.managedDatabaseScanEnabled,
                                enabled,
                                enabled
                                    ? scopeNodeModelConfiguration.scopeSections.dataAnalysis.privateObjectStoreScanEnabled
                                    : false,
                                scopeNodeModelConfiguration.id)}
                    getConfigurationEnabled={dataAnalysis => dataAnalysis.objectStoreScanEnabled}
                    title={localization.objectStoreScanEnabled()}/>
                <Enabled
                    disabled={!objectStoreScanEnabled}
                    getConfigurationControllerUpdateDataAnalysisRequest={
                        (scopeNodeModelConfiguration, enabled) =>
                            new Contract.ConfigurationControllerUpdateDataAnalysisRequest(
                                scopeNodeModelConfiguration.scopeSections.dataAnalysis.managedDatabaseScanEnabled,
                                scopeNodeModelConfiguration.scopeSections.dataAnalysis.objectStoreScanEnabled,
                                enabled,
                                scopeNodeModelConfiguration.id)}
                    getConfigurationEnabled={dataAnalysis => dataAnalysis.privateObjectStoreScanEnabled}
                    title={localization.privateObjectStoreScanEnabled()}/>
            </Stack>
        </Grid2>);
}