import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCommonContainerImageInfoItemElements } from "../useCommonContainerImageInfoItemElements";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useOpDefaultResourceInfoItemElements } from "./useOpDefaultResourceInfoItemElements";

export function useOpContainerImageDefinition(resourceModel: Contract.OpResourceModel, options?: DefinitionOptions) {
    const commonContainerImageInfoItemElements = useCommonContainerImageInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOpDefaultResourceInfoItemElements(resourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonContainerImageInfoItemElements.repositoryInfoItemElement,
                    commonContainerImageInfoItemElements.tagsInfoItemElement,
                    commonContainerImageInfoItemElements.digestInfoItemElement,
                    commonContainerImageInfoItemElements.operatingSystemTypeInfoItemElement,
                    commonContainerImageInfoItemElements.operatingSystemInfoItemElement,
                    commonContainerImageInfoItemElements.vulnerabilitiesInfoItemElement,
                    commonContainerImageInfoItemElements.scanTimeInfoItemElement,
                    commonContainerImageInfoItemElements.workloadClusterResourceIdsInfoItemElement,
                    commonContainerImageInfoItemElements.virtualMachineIdsInfoItemElement,
                    commonContainerImageInfoItemElements.kubernetesWorkloadResourceIdsInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}