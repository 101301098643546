import React from "react";
import { CustomerConsoleAppUrlHelper, ProfileActions } from "../../../../../../../common";
import { ScanRiskProfile as Profile } from "../../../../Code/components/Scans/components";
import { CodeScanRiskHelper } from "../../../../Code/components/Scans/components/ScanRisk/utilities";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useCodeScanRiskDefinition(item: SideViewItem): SideViewDefinition {
    const { codeScanId, codeScanRiskId } = CodeScanRiskHelper.getSideViewItemData(item.id);
    return {
        getActionsElement:
            () =>
                <ProfileActions
                    getLink={() => CustomerConsoleAppUrlHelper.getCodeScanRiskProfileUrl(codeScanId, codeScanRiskId)!}
                    key={`${item.id}-actions`}/>,
        getViewElement:
            () =>
                <Profile
                    key={`${item.id}-profile`}
                    riskId={codeScanRiskId}
                    scanId={codeScanId}/>
    };
}