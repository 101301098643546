import { Action0 } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { ReactNode } from "react";

export class DirectedGraphModel {
    public readonly columnMap: Dictionary<DirectedGraphModelColumn> = {};
    public readonly edgeMap: Dictionary<DirectedGraphModelEdge> = {};
    public readonly nodeIdToDirectionEdgesMap: Dictionary<DirectedGraphModelDirectionEdges> = {};
    public readonly nodeMap: Dictionary<DirectedGraphModelNode> = {};

    constructor(
        public edges: DirectedGraphModelEdge[],
        public nodes: DirectedGraphModelNode[],
        public columns: DirectedGraphModelColumn[] = []) {
        _.each(
            nodes,
            node =>
                this.nodeIdToDirectionEdgesMap[node.id] = {
                    destinationEdges: [],
                    sourceEdges: []
                });
        _.each(
            edges,
            edge => {
                this.nodeIdToDirectionEdgesMap[edge.destinationNodeId].destinationEdges.push(edge);
                this.nodeIdToDirectionEdgesMap[edge.sourceNodeId].sourceEdges.push(edge);
            });

        this.columnMap =
            _.keyBy(
                columns,
                column => column.id);
        this.edgeMap =
            _.keyBy(
                edges,
                edge => edge.id);
        this.nodeMap =
            _.keyBy(
                nodes,
                node => node.id);
    }

    public highlight =
        (nodeId: string, nodeSourceAnchorId?: string) => {
            _.each(
                this.edges,
                directedGraphEdge => {
                    directedGraphEdge.options.appearance = "normal";
                });
            _.each(
                this.nodes,
                directedGraphNode => {
                    directedGraphNode.options.appearance = "normal";
                });

            const highlightNodes =
                (directedGraphNodes: DirectedGraphModelNode[], direction?: "destination" | "source") => {
                    _.each(
                        directedGraphNodes,
                        directedGraphNode => {
                            directedGraphNode.options.appearance = "highlighted";

                            if (_.isNil(direction) ||
                                direction === "source") {
                                highlightNodes(
                                    _(this.nodeIdToDirectionEdgesMap[directedGraphNode.id].destinationEdges).
                                        filter(
                                            directedGraphEdge =>
                                                _.isNil(directedGraphEdge.options.destinationAnchorId) ||
                                                _.isNil(nodeSourceAnchorId) ||
                                                directedGraphEdge.options.destinationAnchorId === nodeSourceAnchorId).
                                        map(
                                            directedGraphEdge => {
                                                directedGraphEdge.options.appearance = "highlighted";
                                                return this.nodeMap[directedGraphEdge.sourceNodeId];
                                            }).
                                        value(),
                                    "source");
                            }

                            if (_.isNil(direction) ||
                                direction === "destination") {
                                highlightNodes(
                                    _.map(
                                        this.nodeIdToDirectionEdgesMap[directedGraphNode.id].sourceEdges,
                                        directedGraphEdge => {
                                            directedGraphEdge.options.appearance = "highlighted";
                                            return this.nodeMap[directedGraphEdge.destinationNodeId];
                                        }),
                                    "destination");
                            }
                        });
                };

            highlightNodes([this.nodeMap[nodeId]]);
        };
}

type DirectedGraphModelDirectionEdges = {
    destinationEdges: DirectedGraphModelEdge[];
    sourceEdges: DirectedGraphModelEdge[];
};

export class DirectedGraphModelColumn {
    constructor(
        public id: string,
        public title: string) {
    }
}

export class DirectedGraphModelEdge {
    public id: string;

    constructor(
        public sourceNodeId: string,
        public destinationNodeId: string,
        public options: DirectedGraphModelEdgeOptions = {}) {
        this.id =
            DirectedGraphModelEdge.createId(
                sourceNodeId,
                destinationNodeId,
                options.destinationAnchorId);
    }

    public static createId =
        (sourceNodeId: string, destinationNodeId: string, destinationAnchorId?: string) =>
            _.isNil(destinationAnchorId)
                ? `${sourceNodeId}->${destinationNodeId}`
                : `${sourceNodeId}->${destinationNodeId}/${destinationAnchorId}`;

    public getAnchorPlacement =
        (nodeId: string) =>
            nodeId === this.sourceNodeId
                ? "right"
                : "left";
}

type DirectedGraphModelEdgeOptions = {
    appearance?: "highlighted" | "normal";
    color?: string;
    destinationAnchorId?: string;
    title?: string;
};

export class DirectedGraphModelNode {
    constructor(
        public id: string,
        public columnId: string,
        public contentElement: ReactNode,
        public contentSize: DirectedGraphModelNodeContentSize,
        public getEdgeAnchorPoint: (edge: DirectedGraphModelEdge) => DirectedGraphModelNodeEdgeAnchorPoint,
        public options: DirectedGraphModelNodeOptions = {}) {
    }
}

export type DirectedGraphModelNodeContentSize = {
    height: number;
    width: number;
};

export type DirectedGraphModelNodeOptions = {
    appearance?: "highlighted" | "normal";
    onClick?: Action0;
};

export type DirectedGraphModelNodeEdgeAnchorPoint = {
    x: number;
    y: number;
};