import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import { useSetSupportPrincipalsContext as useSetSupportPrincipalsContext, useSupportPrincipalsContext } from "..";
import { AdministrationController, Contract, useTheme } from "../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData>) {
    const { executeGetSupportPrincipals } = useSupportPrincipalsContext();
    const setSupportPrincipalsContext = useSetSupportPrincipalsContext();

    const [deleteSupportPrincipalExecuting, setDeleteSupportPrincipalExecuting] = useState(false);
    const [deleteSupportPrincipalError, setDeleteSupportPrincipalError] = useState(false);

    async function deleteSupportPrincipal() {
        setDeleteSupportPrincipalExecuting(true);
        setDeleteSupportPrincipalError(false);

        try {
            await AdministrationController.deleteSupportPrincipal(
                new Contract.AdministrationControllerDeleteSupportPrincipalRequest(
                    item.group,
                    item.identifier));
            await executeGetSupportPrincipals();
        } catch {
            setDeleteSupportPrincipalError(true);
        }

        setDeleteSupportPrincipalExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.administration.supportPrincipals.actionsCell",
            () => ({
                actions: {
                    delete: {
                        confirm: {
                            title: "Are you sure you want to remove {{supportPrincipalIdentifier}}?"
                        },
                        error: "Failed to remove {{supportPrincipalIdentifier}}",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteSupportPrincipalExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteSupportPrincipalError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error({ supportPrincipalIdentifier: item.identifier })}
                    variant="minimal"/>)}
            <Menu
                itemsOrGetItems={[
                    new ActionMenuItem(
                        () =>
                            setSupportPrincipalsContext(
                                supportPrincipalsContext => ({
                                    ...supportPrincipalsContext,
                                    addOrEditPrincipalOpen: item
                                })),
                        localization.actions.edit(),
                        {
                            disabled: deleteSupportPrincipalExecuting,
                            icon: <EditIcon/>
                        }),
                    new ActionMenuItem(
                        () => deleteSupportPrincipal(),
                        localization.actions.delete.title(),
                        {
                            confirmOptions: {
                                message: localization.actions.delete.confirm.title({ supportPrincipalIdentifier: item.identifier })
                            },
                            disabled: deleteSupportPrincipalExecuting,
                            icon: <DeleteIcon/>
                        })
                ]}/>
        </Stack>);
}