import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InfraStringIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M12 7.5C12.5523 7.5 13 7.94772 13 8.5V16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5V8.5C11 7.94772 11.4477 7.5 12 7.5Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M7 8.5C7 7.94772 7.49746 7.5 8.11111 7.5H15.8889C16.5025 7.5 17 7.94772 17 8.5C17 9.05228 16.5025 9.5 15.8889 9.5H8.11111C7.49746 9.5 7 9.05228 7 8.5Z"
                fillRule="evenodd"/>
            <rect
                height="1"
                width="2"
                x="2"
                y="6"/>
            <path
                clipRule="evenodd"
                d="M3 10C2.44772 10 2 9.55228 2 9L2 5C2 3.34315 3.34315 2 5 2H9C9.55228 2 10 2.44772 10 3C10 3.55228 9.55228 4 9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 3.55228 10 3 10Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M3 14C2.44772 14 2 14.4477 2 15L2 19C2 20.6569 3.34315 22 5 22H9C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20H5C4.44772 20 4 19.5523 4 19V15C4 14.4477 3.55228 14 3 14Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M21 10C21.5523 10 22 9.55228 22 9V5C22 3.34315 20.6569 2 19 2H15C14.4477 2 14 2.44772 14 3C14 3.55228 14.4477 4 15 4H19C19.5523 4 20 4.44772 20 5V9C20 9.55228 20.4477 10 21 10Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M21 14C21.5523 14 22 14.4477 22 15V19C22 20.6569 20.6569 22 19 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 20.4477 14 21 14Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}