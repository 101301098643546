import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsTimestreamDatabaseTableDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const table = resourceModel.entity as Contract.AwsTimestreamDatabaseTable;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsTimestreamDatabaseTableDefinition",
            () => ({
                info: {
                    items: {
                        database: "Database"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={table.databaseId}
                        entityTypeName={Contract.TypeNames.AwsTimestreamDatabase}
                        key="database"
                        title={localization.info.items.database()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}