import { DataTableColumn, DataTableSortType, EnumValuesFilter, NumberFormatter, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useGcpDnsManagedZoneDnsSecSigningKeyAlgorithmTranslator, useGcpDnsManagedZoneDnsSecStatusTranslator, useGcpDnsManagedZonePrivateZoneTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonScopeResourceDefinition } from "./useGcpCommonScopeResourceDefinition";

export function useGcpDnsManagedZoneDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);

    const dnsManagedZonePrivateZoneTypeTranslator = useGcpDnsManagedZonePrivateZoneTypeTranslator();
    const dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator = useGcpDnsManagedZoneDnsSecSigningKeyAlgorithmTranslator();
    const dnsManagedZoneDnsSecStatusTranslator = useGcpDnsManagedZoneDnsSecStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpDnsManagedZoneDefinition",
            () => ({
                columns: {
                    description: "Description",
                    dnsSec: {
                        keySigningKeyAlgorithm: "DNSSEC Key Signing Key Algorithm",
                        status: "DNSSEC",
                        zoneSigningKeyAlgorithm: "DNSSEC Zone Signing Key Algorithm"
                    },
                    loggingEnabled: {
                        false: "Off",
                        title: "Logging",
                        true: "On"
                    },
                    privateZoneType: "Private Zone Type",
                    publicNetworkAccess: {
                        false: "Private",
                        title: "Visibility",
                        true: "Public"
                    },
                    resourceRecordSetCount: "Resource Record Set Count"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpDnsManagedZoneModel) => ({
                            [localization.columns.publicNetworkAccess.title()]:
                                (item.entity as Contract.GcpDnsManagedZone).publicNetworkAccess
                                    ? localization.columns.publicNetworkAccess.true()
                                    : localization.columns.publicNetworkAccess.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.publicNetworkAccess.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.publicNetworkAccess.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.publicNetworkAccess.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpDnsManagedZonePublicNetworkAccess}
                itemProperty={
                    (item: Contract.GcpDnsManagedZoneModel) =>
                        (item.entity as Contract.GcpDnsManagedZone).publicNetworkAccess
                            ? localization.columns.publicNetworkAccess.true()
                            : localization.columns.publicNetworkAccess.false()
                }
                key={Contract.EntityModelProperty.GcpDnsManagedZonePublicNetworkAccess}
                title={localization.columns.publicNetworkAccess.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpDnsManagedZoneModel) => ({
                            [localization.columns.privateZoneType()]:
                                _.isNil((item.entity as Contract.GcpDnsManagedZone).privateZoneType)
                                    ? ""
                                    : dnsManagedZonePrivateZoneTypeTranslator((item.entity as Contract.GcpDnsManagedZone).privateZoneType!)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpDnsManagedZonePrivateZoneType}
                                enumTypeTranslator={dnsManagedZonePrivateZoneTypeTranslator}
                                placeholder={localization.columns.privateZoneType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpDnsManagedZonePrivateZoneType}
                key={Contract.EntityModelProperty.GcpDnsManagedZonePrivateZoneType}
                render={
                    optionalTableCell<Contract.GcpDnsManagedZoneModel>(
                        item =>
                            _.isNil((item.entity as Contract.GcpDnsManagedZone).privateZoneType)
                                ? undefined
                                : dnsManagedZonePrivateZoneTypeTranslator((item.entity as Contract.GcpDnsManagedZone).privateZoneType!))}
                selectorOptions={{ default: false }}
                title={localization.columns.privateZoneType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem: (item: Contract.GcpDnsManagedZoneModel) => ({ [localization.columns.description()]: (item.entity as Contract.GcpDnsManagedZone).description ?? "" })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpDnsManagedZoneDescription)}
                                placeholder={localization.columns.description()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpDnsManagedZoneDescription}
                key={Contract.EntityModelProperty.GcpDnsManagedZoneDescription}
                render={optionalTableCell<Contract.GcpDnsManagedZoneModel>(item => (item.entity as Contract.GcpDnsManagedZone).description)}
                title={localization.columns.description()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpDnsManagedZoneModel) => ({
                            [localization.columns.resourceRecordSetCount()]: NumberFormatter.humanize(item.resourceRecordSetCount)
                        })
                }}
                id={Contract.EntityModelProperty.GcpDnsManagedZoneResourceRecordSetCount}
                itemProperty={(item: Contract.GcpDnsManagedZoneModel) => item.resourceRecordSetCount}
                key={Contract.EntityModelProperty.GcpDnsManagedZoneResourceRecordSetCount}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.resourceRecordSetCount()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpDnsManagedZoneModel) => ({
                            [localization.columns.loggingEnabled.title()]:
                                (item.entity as Contract.GcpDnsManagedZone).loggingEnabled
                                    ? localization.columns.loggingEnabled.true()
                                    : localization.columns.loggingEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.loggingEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.loggingEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.loggingEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpDnsManagedZoneLoggingEnabled}
                itemProperty={
                    (item: Contract.GcpDnsManagedZoneModel) =>
                        (item.entity as Contract.GcpDnsManagedZone).loggingEnabled
                            ? localization.columns.loggingEnabled.true()
                            : localization.columns.loggingEnabled.false()}
                key={Contract.EntityModelProperty.GcpDnsManagedZoneLoggingEnabled}
                title={localization.columns.loggingEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpDnsManagedZoneModel) => ({
                            [localization.columns.dnsSec.status()]:
                                _.isNil((item.entity as Contract.GcpDnsManagedZone).dnsSec)
                                    ? ""
                                    : dnsManagedZoneDnsSecStatusTranslator((item.entity as Contract.GcpDnsManagedZone).dnsSec!.status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpDnsManagedZoneDnsSecStatus}
                                enumTypeTranslator={dnsManagedZoneDnsSecStatusTranslator}
                                placeholder={localization.columns.dnsSec.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecStatus}
                key={Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecStatus}
                render={optionalTableCell<Contract.GcpDnsManagedZoneModel>(
                    item =>
                        _.isNil((item.entity as Contract.GcpDnsManagedZone).dnsSec)
                            ? undefined
                            : dnsManagedZoneDnsSecStatusTranslator((item.entity as Contract.GcpDnsManagedZone).dnsSec!.status))}
                title={localization.columns.dnsSec.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpDnsManagedZoneModel) => ({
                            [localization.columns.dnsSec.keySigningKeyAlgorithm()]:
                                _.isNil((item.entity as Contract.GcpDnsManagedZone).dnsSec?.keySigningKeyAlgorithm)
                                    ? ""
                                    : dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator((item.entity as Contract.GcpDnsManagedZone).dnsSec!.keySigningKeyAlgorithm!)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm}
                                enumTypeTranslator={dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator}
                                placeholder={localization.columns.dnsSec.keySigningKeyAlgorithm()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecKeySigningKeyAlgorithm}
                key={Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecKeySigningKeyAlgorithm}
                render={
                    optionalTableCell<Contract.GcpDnsManagedZoneModel>(
                        item =>
                            _.isNil((item.entity as Contract.GcpDnsManagedZone).dnsSec?.keySigningKeyAlgorithm)
                                ? undefined
                                : dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator((item.entity as Contract.GcpDnsManagedZone).dnsSec!.keySigningKeyAlgorithm!)
                    )}
                selectorOptions={{ default: false }}
                title={localization.columns.dnsSec.keySigningKeyAlgorithm()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpDnsManagedZoneModel) => ({
                            [localization.columns.dnsSec.zoneSigningKeyAlgorithm()]:
                                _.isNil((item.entity as Contract.GcpDnsManagedZone).dnsSec?.zoneSigningKeyAlgorithm)
                                    ? ""
                                    : dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator((item.entity as Contract.GcpDnsManagedZone).dnsSec!.zoneSigningKeyAlgorithm!)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpDnsManagedZoneDnsSecSigningKeyAlgorithm}
                                enumTypeTranslator={dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator}
                                placeholder={localization.columns.dnsSec.zoneSigningKeyAlgorithm()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecZoneSigningKeyAlgorithm}
                key={Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecZoneSigningKeyAlgorithm}
                render={
                    optionalTableCell<Contract.GcpDnsManagedZoneModel>(
                        item =>
                            _.isNil((item.entity as Contract.GcpDnsManagedZone).dnsSec?.zoneSigningKeyAlgorithm)
                                ? undefined
                                : dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator((item.entity as Contract.GcpDnsManagedZone).dnsSec!.zoneSigningKeyAlgorithm!)
                    )}
                selectorOptions={{ default: false }}
                title={localization.columns.dnsSec.zoneSigningKeyAlgorithm()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpDnsManagedZoneRequest(
                new Contract.EntityControllerGetEntityModelPageGcpDnsManagedZoneRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpDnsManagedZoneDescription]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecKeySigningKeyAlgorithm]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpDnsManagedZoneDnsSecZoneSigningKeyAlgorithm]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpDnsManagedZoneLoggingEnabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpDnsManagedZonePrivateZoneType]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpDnsManagedZonePublicNetworkAccess])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}