import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, map, optionalTableCell, useLocalization, useLocalizeList } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { AwsElbLoadBalancerListenerProtocols } from ".";
import { Contract, EntitiesCell, entityModelStore, SourceToTargetMap } from "../../../common";

type AwsElbLoadBalancerListenersTableProps = {
    loadBalancerListenerIds: string[];
    targetGroupIds: string[];
};

export function AwsElbLoadBalancerListenersTable({ loadBalancerListenerIds, targetGroupIds }: AwsElbLoadBalancerListenersTableProps) {
    const loadBalancerListenerModels = entityModelStore.useGet(loadBalancerListenerIds);
    const targetGroupModels = entityModelStore.useGet(targetGroupIds);

    entityModelStore.useGet(
        _(targetGroupModels).
            flatMap(
                targetGroupModel => {
                    const targetGroup = targetGroupModel.entity as Contract.AwsElbTargetGroup;
                    return map(
                        targetGroup.type,
                        {
                            [Contract.AwsElbTargetGroupType.ApplicationLoadBalancer]: () => targetGroup.applicationLoadBalancerIds,
                            [Contract.AwsElbTargetGroupType.FunctionConfiguration]: () => targetGroup.functionConfigurationIds,
                            [Contract.AwsElbTargetGroupType.Instance]: () => targetGroup.instanceIds,
                            [Contract.AwsElbTargetGroupType.IpAddress]: () => []
                        });
                }).
            uniq().
            value());

    const items =
        useMemo(
            () => {
                const targetGroupModelMap =
                    _.keyBy(
                        targetGroupModels,
                        targetGroupModel => targetGroupModel.entity.id);
                return _.flatMap(
                    loadBalancerListenerModels,
                    loadBalancerListenerModel => {
                        const loadBalancerListener = loadBalancerListenerModel.entity as Contract.AwsElbModernLoadBalancerListener;
                        return _.map(
                            loadBalancerListener.targetGroupIds,
                            targetGroupId =>
                                new AwsElbLoadBalancerListenersTableItem(
                                    loadBalancerListener,
                                    targetGroupModelMap[targetGroupId].entity as Contract.AwsElbTargetGroup));
                    });
            },
            [loadBalancerListenerModels]);

    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "tenants.aws.awsElbLoadBalancerListenersTable",
            () => ({
                columns: {
                    certificates: "SSL Certificates",
                    ports: "Port",
                    protocols: "Protocol",
                    securityPolicy: {
                        custom: "Custom Policy",
                        title: "Security Policy"
                    },
                    sslProtocolNames: "Supported Protocols",
                    targetGroupName: "Target Group Name",
                    targets: "Targets"
                },
                empty: "No Listeners"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => items}
            getItemId={(item: AwsElbLoadBalancerListenersTableItem) => item.id}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={AwsElbLoadBalancerListenersTableColumnId.Protocols}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsElbLoadBalancerListenersTableItem>) =>
                        <AwsElbLoadBalancerListenerProtocols
                            protocol={item.loadBalancerListener.protocol}
                            targetProtocol={item.targetGroup.protocol ?? item.loadBalancerListener.protocol}/>}
                title={localization.columns.protocols()}/>
            <DataTableColumn
                id={AwsElbLoadBalancerListenersTableColumnId.Ports}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsElbLoadBalancerListenersTableItem>) => {
                        const targetGroupTargetsPorts =
                            _(item.targetGroup.targets).
                                map(target => target.port).
                                uniq().
                                filter().
                                value();
                        return (
                            <SourceToTargetMap
                                source={item.loadBalancerListener.port}
                                target={
                                    _.isEmpty(targetGroupTargetsPorts)
                                        ? item.targetGroup.port ?? item.loadBalancerListener.port
                                        : targetGroupTargetsPorts}>
                                {ports =>
                                    <InlineItems
                                        items={ports}
                                        variant="itemPlusItemCount"/>}
                            </SourceToTargetMap>);
                    }}
                title={localization.columns.ports()}/>
            <DataTableColumn
                id={AwsElbLoadBalancerListenersTableColumnId.SecurityPolicy}
                render={
                    optionalTableCell<AwsElbLoadBalancerListenersTableItem>(
                        item =>
                            _.isNil(item.loadBalancerListener.securityPolicy)
                                ? undefined
                                : item.loadBalancerListener.securityPolicy!.name ?? localization.columns.securityPolicy.custom())}
                title={localization.columns.securityPolicy.title()}/>
            <DataTableColumn
                id={AwsElbLoadBalancerListenersTableColumnId.Certificates}
                render={
                    optionalTableCell<AwsElbLoadBalancerListenersTableItem>(
                        item =>
                            _.isEmpty(item.loadBalancerListener.certificateIds)
                                ? undefined
                                : <EntitiesCell
                                    entityIdsOrModels={item.loadBalancerListener.certificateIds}
                                    entityTypeName={Contract.TypeNames.AwsAcmCertificate}
                                    entityVariant="iconTextTypeTenant"/>)}
                title={localization.columns.certificates()}/>
            <DataTableColumn
                id={AwsElbLoadBalancerListenersTableColumnId.SslProtocolNames}
                render={
                    optionalTableCell<AwsElbLoadBalancerListenersTableItem>(
                        item =>
                            _.isNil(item.loadBalancerListener.securityPolicy)
                                ? undefined
                                : <InlineItems
                                    items={_.orderBy(item.loadBalancerListener.securityPolicy!.sslProtocolNames)}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.sslProtocolNames()}/>
            <DataTableColumn
                id={AwsElbLoadBalancerListenersTableColumnId.TargetGroupName}
                itemProperty={(item: AwsElbLoadBalancerListenersTableItem) => item.targetGroup.displayName}
                title={localization.columns.targetGroupName()}/>
            <DataTableColumn
                id={AwsElbLoadBalancerListenersTableColumnId.Targets}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsElbLoadBalancerListenersTableItem>) =>
                        map(
                            item.targetGroup.type,
                            {
                                [Contract.AwsElbTargetGroupType.ApplicationLoadBalancer]:
                                    () =>
                                        <EntitiesCell
                                            entityIdsOrModels={item.targetGroup.applicationLoadBalancerIds}
                                            entityTypeName={Contract.TypeNames.AwsElbApplicationLoadBalancer}
                                            inlineEntitiesVariant="itemOrItemCountAndType"/>,
                                [Contract.AwsElbTargetGroupType.FunctionConfiguration]:
                                    () =>
                                        <EntitiesCell
                                            entityIdsOrModels={item.targetGroup.functionConfigurationIds}
                                            entityTypeName={Contract.TypeNames.AwsLambdaFunctionConfiguration}
                                            inlineEntitiesVariant="itemOrItemCountAndType"/>,
                                [Contract.AwsElbTargetGroupType.Instance]:
                                    () =>
                                        <EntitiesCell
                                            entityIdsOrModels={item.targetGroup.instanceIds}
                                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                            inlineEntitiesVariant="itemOrItemCountAndType"/>,
                                [Contract.AwsElbTargetGroupType.IpAddress]:
                                    () =>
                                        <Typography noWrap={true}>
                                            {localizeList(item.targetGroup.ipAddresses)}
                                        </Typography>
                            })}
                title={localization.columns.targets()}/>
        </DataTable>);
}

class AwsElbLoadBalancerListenersTableItem {
    public id: string;

    constructor(
        public loadBalancerListener: Contract.AwsElbLoadBalancerListener,
        public targetGroup: Contract.AwsElbTargetGroup) {
        this.id = `${loadBalancerListener.id}-${targetGroup.id}`;
    }
}

enum AwsElbLoadBalancerListenersTableColumnId {
    Certificates = "certificates",
    Ports = "ports",
    Protocols = "protocols",
    SecurityPolicy = "securityPolicy",
    SslProtocolNames = "sslProtocolNames",
    TargetGroupName = "targetGroupName",
    Targets = "targets"
}