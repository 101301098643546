export class GraphApiUrlHelper {
    private static url: URL;

    public static initialize(url: URL) {
        GraphApiUrlHelper.url = url;
    }

    public static getUrl(): string {
        return GraphApiUrlHelper.url.href;
    }
}