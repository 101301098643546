import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceDefinition, useAwsCommonNetworkedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEc2FlowLogFilterTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2NetworkInterfaceDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const ec2FlowLogFilterTranslator = useAwsEc2FlowLogFilterTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2NetworkInterfaceDefinition",
            () => ({
                columns: {
                    description: "Description",
                    flowLogDestinationResourceIdReferences: "Flow Logs Destinations",
                    flowLogFilter: "Flow Logs Filter"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonEc2ResourceDefinition.columns.rawIdColumn,
            commonEc2ResourceDefinition.columns.tenantColumn,
            commonEc2ResourceDefinition.columns.creationTimeColumn,
            commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
            commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEc2NetworkInterfaceModel) => ({
                            [localization.columns.description()]: (item.entity as Contract.AwsEc2NetworkInterface).description
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEc2NetworkInterfaceDescription)}
                                placeholder={localization.columns.description()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2NetworkInterfaceDescription}
                key={Contract.EntityModelProperty.AwsEc2NetworkInterfaceDescription}
                render={optionalTableCell<Contract.AwsEc2NetworkInterfaceModel>(item => (item.entity as Contract.AwsEc2NetworkInterface).description)}
                title={localization.columns.description()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEc2NetworkInterfaceModel) => ({
                            [localization.columns.flowLogFilter()]: ec2FlowLogFilterTranslator((item as Contract.AwsEc2NetworkInterfaceModel).flowLogFilter)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{
                                    enabled: true,
                                    title: ec2FlowLogFilterTranslator(undefined)
                                }}
                                enumType={Contract.AwsEc2FlowLogFilter}
                                enumTypeTranslator={ec2FlowLogFilterTranslator}
                                placeholder={localization.columns.flowLogFilter()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2NetworkInterfaceFlowLogFilter}
                key={Contract.EntityModelProperty.AwsEc2NetworkInterfaceFlowLogFilter}
                render={optionalTableCell<Contract.AwsEc2NetworkInterfaceModel>(item => ec2FlowLogFilterTranslator(item.flowLogFilter))}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.flowLogFilter()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsEc2NetworkInterfaceModel>(
                        Contract.TypeNames.AwsResource,
                        item => (item as Contract.AwsEc2NetworkInterfaceModel).flowLogDestinationResourceIdReferences,
                        localization.columns.flowLogDestinationResourceIdReferences())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEc2NetworkInterfaceFlowLogDestinationResources)}
                                placeholder={localization.columns.flowLogDestinationResourceIdReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2NetworkInterfaceFlowLogDestinationResources}
                key={Contract.EntityModelProperty.AwsEc2NetworkInterfaceFlowLogDestinationResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEc2NetworkInterfaceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.flowLogDestinationResourceIdReferences}
                            entityTypeName={Contract.TypeNames.AwsResource}
                            entityVariant="iconText"/>}
                title={localization.columns.flowLogDestinationResourceIdReferences()}/>,
            commonNetworkedResourceDefinition.columns.getVpcsColumn(),
            commonNetworkedResourceDefinition.columns.securityGroupsColumn,
            commonEc2ResourceDefinition.columns.regionColumn,
            commonEc2ResourceDefinition.columns.regionLocationColumn,
            commonEc2ResourceDefinition.columns.tagsColumn,
            commonEc2ResourceDefinition.columns.attributesColumn,
            commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2NetworkInterfaceRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2NetworkInterfaceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2NetworkInterfaceDescription]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2NetworkInterfaceFlowLogDestinationResources]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2NetworkInterfaceFlowLogFilter])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}