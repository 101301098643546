import { useEffect, useMemo } from "react";

export function useBlobUrl(content: string, contentType: string) {
    const contentUrl =
        useMemo(
            () =>
                window.URL.createObjectURL(
                    new Blob(
                        [content],
                        { type: contentType })),
            []);
    useEffect(
        () => () => window.URL.revokeObjectURL(contentUrl),
        []);

    return contentUrl;
}