import { Action0, ActionButton, Shadows } from "@infrastructure";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../../../../../../../common";

type ButtonProps = {
    disabled: boolean;
    icon: ReactNode;
    onClick: Action0;
};

export function Button({ disabled, icon, onClick }: ButtonProps) {
    const theme = useTheme();
    return (
        <ActionButton
            disabled={disabled}
            size="large"
            sx={{
                backgroundColor: theme.important(theme.palette.background.paper),
                border:
                    disabled
                        ? theme.border.primary
                        : theme.border.hoverFocus,
                boxShadow: theme.shadows[Shadows.Tooltip],
                color:
                    disabled
                        ? undefined
                        : theme.palette.text.primary,
                fontSize: "17px",
                padding: theme.spacing(1)
            }}
            onClick={onClick}>
            {icon}
        </ActionButton>);
}