import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../common";
import { useGetVulnerabilitiesCsvItem } from "../../../hooks";

export function useGetWorkloadResourceVulnerabilitiesCsvItem() {
    const getVulnerabilitiesCsvItem = useGetVulnerabilitiesCsvItem();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useGetWorkloadResourceVulnerabilitiesCsvItem",
            () => ({
                none: "No vulnerabilities"
            }));

    return (vulnerabilities?: Contract.VulnerabilityData[], vulnerabilityCount?: number, vulnerabilityTopCount = 100) => {
        if (_.isNil(vulnerabilities)) {
            return "";
        }

        if (_.isEmpty(vulnerabilities)) {
            return localization.none();
        }

        return getVulnerabilitiesCsvItem(
            vulnerabilities,
            vulnerabilityCount,
            vulnerabilityTopCount);
    };
}