import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureMachineLearningWorkspaceRiskContext() {
    return useMemo(
        () => useAzureMachineLearningWorkspaceRiskContext,
        []);
}

function useAzureMachineLearningWorkspaceRiskContext(workspaceModel: Contract.AzureMachineLearningWorkspaceModel) {
    const workspace = workspaceModel.entity as Contract.AzureMachineLearningWorkspace;
    const workspaceNetwork = workspaceModel.entityNetwork as Optional<Contract.AzureMachineLearningWorkspaceStateNetwork>;
    const resourceRiskContext = useGetAzureResourceRiskContext()(workspaceModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureMachineLearningWorkspaceRiskContext",
            () => ({
                highBusinessImpact: "The workspace is marked as High Business Impact"
            }));


    return {
        ...resourceRiskContext,
        allNetworkAccess: resourceRiskContext.getAllNetworkAccessContextItem(workspace.allNetworkAccess),
        highBusinessImpact:
            workspace.highBusinessImpact
                ? new RiskDefinitionContextItem(localization.highBusinessImpact())
                : undefined,
        wideRangeInboundSubnets:
            workspace.allNetworkAccess ||
            _.isNil(workspaceNetwork)
                ? undefined
                :resourceRiskContext.getWideRangeInboundSubnetsContextItem(workspaceNetwork.wideRangeFirewallRuleSubnets)
    };
}