import { useMemo } from "react";
import { useGcpArtifactContainerImageRepositoryDefinition, useGcpArtifactPackageRepositoryDefinition, useGcpComputeLoadBalancerDefinition, useGcpContainerImageDefinition, useGcpIamPartialServiceAccountDefinition, useGcpIamRoleBindingDefinition, useGcpResourceDefinition, useGcpUnmanagedKubernetesClusterDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function useGcpEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.GcpArtifactContainerImageRepository:
                        return useGcpArtifactContainerImageRepositoryDefinition;
                    case Contract.TypeNames.GcpArtifactPackageRepository:
                        return useGcpArtifactPackageRepositoryDefinition;
                    case Contract.TypeNames.GcpComputeLoadBalancer:
                        return useGcpComputeLoadBalancerDefinition;
                    case Contract.TypeNames.GcpContainerImage:
                        return useGcpContainerImageDefinition;
                    case Contract.TypeNames.GcpIamPartialServiceAccount:
                        return useGcpIamPartialServiceAccountDefinition;
                    case Contract.TypeNames.GcpIamRoleBinding:
                        return useGcpIamRoleBindingDefinition;
                    case Contract.TypeNames.GcpUnmanagedKubernetesCluster:
                        return useGcpUnmanagedKubernetesClusterDefinition;
                    default:
                        return useGcpResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.GcpEntityModel, options);
}