import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, IconText, useChangeEffect, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { ComplianceView, CustomerSideViewItemType, useComplianceContext, useSetComplianceContext, useSideViewContext } from "../../..";
import { ComplianceData, ComplianceHelper, ComplianceSectionIcon, ComplianceSecuredBar, Contract, CustomerConsoleAppUrlHelper, ItemTable, useComplianceTranslator, useGetComplianceScopeId, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ActionsCell } from "../ActionsCell";
import { useDefinition } from "./hooks";

type ItemsProps = {
    complianceData: ComplianceData;
    view: ComplianceView;
};

export function Items({ complianceData, view }: ItemsProps) {
    const [loading, setLoading] = useState(false);
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { childScopeCustomCompliance } = useComplianceContext();
    const { registerDataChange } = useSideViewContext();
    const setComplianceContext = useSetComplianceContext();
    const getComplianceScopeId = useGetComplianceScopeId();
    const { actions, actionsRef, defaultSortColumn, defaultSortMap, getAdditionalColumn: additionalColumn, getColumnIdToGetItemValueMap: columnIdToGetItemValueMap, initialFilterMap } =
        useDefinition(
            view,
            scopeNodeModel.configuration.id);

    const items =
        useMemo(
            () =>
                view === ComplianceView.BuiltIn
                    ? [...complianceData.enabledBuiltInComplianceSectionDatas, ...complianceData.disabledBuiltInComplianceSectionDatas]
                    : complianceData.customComplianceSectionDatas,
            [complianceData.customComplianceSectionDatas, complianceData.enabledBuiltInComplianceSectionDatas, complianceData.disabledBuiltInComplianceSectionDatas]);

    useEffect(
        () => {
            const unregister =
                registerDataChange(
                    type => {
                        if (type === Contract.CustomerConsoleAppSideViewType.Compliance) {
                            complianceData.refreshComplianceSectionDatas();
                        }
                    });
            return unregister;
        },
        []);

    useChangeEffect(
        async () => {
            setLoading(true);
            await complianceData.refreshComplianceSectionDatas();
        },
        [scopeNodeModel.configuration.id, childScopeCustomCompliance]);

    useChangeEffect(
        () => {
            setComplianceContext(
                context => ({
                    ...context,
                    refreshCompliance: complianceData.refreshComplianceSectionDatas
                }));
            actionsRef?.current?.reload({
                refreshColumns: true,
                refreshFilters: true
            });
            setLoading(false);
        },
        [items]);

    const complianceTranslator = useComplianceTranslator();
    const localization =
        useLocalization(
            "views.customer.compliance.items",
            () => ({
                columns: {
                    score: "Score"
                },
                empty: {
                    withFilter: "No Matching Compliance",
                    withoutFilter: "No Compliance"
                },
                filters: {
                    name: "Name"
                }
            }));

    const theme = useTheme();
    return (
        <ItemTable
            actionsRef={actionsRef}
            columnIdToDefaultSortDirectionMap={defaultSortMap}
            columnIdToGetItemValueMap={{
                [ItemTableColumnId.Name]: {
                    getFilterValue: (item: Contract.ComplianceSectionData) => complianceTranslator(item.identifier).title,
                    getSortValue:
                        (item: Contract.ComplianceSectionData) =>
                            item.identifier === "Tenable"
                                ? ""
                                : complianceTranslator(item.identifier).title
                },
                [ItemTableColumnId.Score]: {
                    getFilterValue: () => undefined,
                    getSortValue:
                        (item: Contract.ComplianceSectionData) =>
                            _.isNil(item.stats)
                                ? 0
                                : item.stats!.securedPercentage
                },
                ...columnIdToGetItemValueMap?.(complianceData.enabledBuiltInComplianceSectionDatasMap)
            }}
            columnOptions={{ resizable: true }}
            dataTableActions={actions}
            defaultSortColumnIdOrIds={defaultSortColumn ?? ItemTableColumnId.Name}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            externalLoading={loading}
            filterOptions={{
                initialState: initialFilterMap,
                persist: true
            }}
            getItemId={item => item.identifier}
            highlightItem={{
                getItemId:
                    (item: Contract.ComplianceSectionData) =>
                        ComplianceHelper.getSideViewItemId(
                            item.identifier,
                            getComplianceScopeId(
                                item.identifier,
                                scopeNodeModel.configuration.id)),
                hashRouteTemplate: `${CustomerSideViewItemType.Compliance}/{itemId}`
            }}
            items={items}
            rowOptions={{
                getUrl:
                    (item: Contract.ComplianceSectionData) =>
                        CustomerConsoleAppUrlHelper.getComplianceProfileHashUrl(
                            item.identifier,
                            getComplianceScopeId(
                                item.identifier,
                                scopeNodeModel.configuration.id))
            }}
            showEmptyTable={true}
            variant="view">
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <ValuesFilter placeholder={localization.filters.name()}>
                                    {_.map(
                                        columnIdToItemValuesMap[ItemTableColumnId.Name],
                                        item =>
                                            <ValuesFilterItem
                                                key={item}
                                                title={item}
                                                value={item}/>)}
                                </ValuesFilter>
                        }
                    }}
                    id={ItemTableColumnId.Name}
                    key={ItemTableColumnId.Name}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceSectionData>) => {
                            const { description, title } = complianceTranslator(item.identifier);
                            return (
                                <IconText
                                    disableInteractive={true}
                                    icon={
                                        <ComplianceSectionIcon
                                            complianceIdentifier={item.identifier}
                                            size={theme.spacing(3)}/>}
                                    placement="bottom-start"
                                    text={
                                        <Typography
                                            noWrap={true}
                                            sx={{ fontWeight: 500 }}
                                            variant="h4">
                                            {title}
                                        </Typography>}
                                    titleOrGetTitle={
                                        _.isEmpty(description)
                                            ? undefined
                                            : description}
                                    tooltipSx={{ maxWidth: "500px" }}
                                    tooltipVariant="iconText"/>);
                        }
                    }
                    title={localization.filters.name()}/>,
                additionalColumn(complianceData.enabledBuiltInComplianceSectionDatasMap),
                <DataTableColumn
                    id={ItemTableColumnId.Score}
                    key={ItemTableColumnId.Score}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceSectionData>) =>
                            <ComplianceSecuredBar
                                data={item}
                                disabled={!_.isNil(complianceData.disabledBuiltInComplianceSectionDatasMap[item.identifier])}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.score()}/>,
                <DataTableColumn
                    id={ItemTableColumnId.Actions}
                    key={ItemTableColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceSectionData>) =>
                            <ActionsCell
                                item={{
                                    disabled: !_.isNil(complianceData.disabledBuiltInComplianceSectionDatasMap[item.identifier]),
                                    refreshComplianceSectionDatas: complianceData.refreshComplianceSectionDatas,
                                    sectionData: item
                                }}
                                scopeId={scopeNodeModel.configuration.id}/>}
                    resizable={false}
                    sortOptions={{ enabled: false }}/>
            ]}
        </ItemTable>);
}

export enum ItemTableColumnId {
    Actions = "actions",
    Enabled = "enabled",
    Name = "name",
    Scope = "scope",
    Score = "score"
}