import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function IntegrationsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M6.40176 6.40284C5.23421 6.87154 4.1746 7.59407 3.30498 8.52909C1.90339 10.0361 1.08805 11.9953 1.00673 14.0517C0.925403 16.1081 1.58347 18.1257 2.86167 19.7386C4.13987 21.3516 5.95362 22.4533 7.97421 22.844C9.9948 23.2348 12.0885 22.8887 13.8759 21.8686C15.0741 21.1847 16.0816 20.2282 16.8237 19.088C17.1283 18.6202 17.3881 18.1214 17.5982 17.5978C18.8787 17.0838 20.0262 16.2656 20.9353 15.2021C22.2751 13.6346 23.0077 11.6383 22.9999 9.57621C22.9921 7.51416 22.2444 5.52341 20.8928 3.96608C19.5412 2.40874 17.6755 1.38829 15.6351 1.09035C13.5947 0.792413 11.5151 1.23677 9.77464 2.34261C8.60676 3.08464 7.64363 4.09142 6.95593 5.26987C6.74448 5.6322 6.55908 6.01076 6.40176 6.40284ZM8.97957 5.80624C10.9143 5.66443 12.844 6.18029 14.453 7.27558C15.5935 8.05195 16.5222 9.08499 17.1721 10.2803C17.336 10.5819 17.4822 10.8938 17.6096 11.2145C17.8106 11.7207 17.494 12.2649 16.9681 12.4068C16.4422 12.5487 15.9075 12.233 15.6893 11.7339C15.1916 10.5954 14.3811 9.61284 13.343 8.90619C12.0316 8.01345 10.4437 7.62007 8.86719 7.79734C7.29067 7.9746 5.82982 8.71079 4.74938 9.87247C3.66895 11.0342 3.04044 12.5445 2.97775 14.1297C2.91505 15.7149 3.42234 17.2701 4.40766 18.5135C5.39297 19.7569 6.79113 20.6061 8.34873 20.9073C9.90633 21.2086 11.5203 20.9418 12.8981 20.1554C13.7579 19.6647 14.4903 18.9919 15.0489 18.1923C13.2226 18.3322 11.3927 17.8861 9.82965 16.9095C8.6562 16.1763 7.6855 15.1768 6.98891 14.0036C6.81513 13.7109 6.65886 13.4079 6.52099 13.0968C6.29783 12.5932 6.5969 12.0311 7.12292 11.8676C7.64887 11.7041 8.20113 12.0027 8.44174 12.4981C8.98195 13.6105 9.82661 14.5556 10.8866 15.2179C12.2302 16.0573 13.8304 16.3867 15.3963 16.1459C16.9622 15.9052 18.3896 15.1104 19.419 13.9061C20.4483 12.7018 21.0112 11.168 21.0052 9.58374C20.9993 7.99948 20.4248 6.46999 19.3864 5.2735C18.348 4.07701 16.9146 3.293 15.3469 3.0641C13.7793 2.83519 12.1815 3.17659 10.8443 4.0262C10.1062 4.49517 9.47456 5.10179 8.97957 5.80624Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}