import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, NetworkedResourcesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEc2FlowLogFilterTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2VpcDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const ec2FlowLogFilterTranslator = useAwsEc2FlowLogFilterTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2VpcDefinition",
            () => ({
                columns: {
                    flowLogDestinationResourceIdReferences: "Flow Logs Destinations",
                    flowLogFilter: "Flow Logs Filter",
                    networkedResourceIds: "Attached Resources",
                    subnets: "CIDR"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2VpcModel) => ({
                                [localization.columns.subnets()]: (item.entity as Contract.AwsEc2Vpc).subnets.join("\n")
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2VpcSubnets)}
                                    placeholder={localization.columns.subnets()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcSubnets}
                    key={Contract.EntityModelProperty.AwsEc2VpcSubnets}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcModel>) =>
                            <Typography noWrap={true}>
                                <InlineItems
                                    items={(item.entity as Contract.AwsEc2Vpc).subnets}
                                    variant="itemPlusItemCount"/>
                            </Typography>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.subnets()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2VpcModel>(
                            Contract.TypeNames.INetworkedResource,
                            item =>
                                _(item.networkedResourceTypeNameToIdsMap).
                                    values().
                                    flatMap().
                                    value(),
                            localization.columns.networkedResourceIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2VpcNetworkedResources)}
                                    placeholder={localization.columns.networkedResourceIds()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcNetworkedResources}
                    key={Contract.EntityModelProperty.AwsEc2VpcNetworkedResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcModel>) =>
                            <NetworkedResourcesCell item={item.networkedResourceTypeNameToIdsMap}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.networkedResourceIds()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2VpcModel) => ({
                                [localization.columns.flowLogFilter()]: ec2FlowLogFilterTranslator((item as Contract.AwsEc2VpcModel).flowLogFilter)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    emptyValueOptions={{
                                        enabled: true,
                                        title: ec2FlowLogFilterTranslator(undefined)
                                    }}
                                    enumType={Contract.AwsEc2FlowLogFilter}
                                    enumTypeTranslator={ec2FlowLogFilterTranslator}
                                    placeholder={localization.columns.flowLogFilter()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcFlowLogFilter}
                    key={Contract.EntityModelProperty.AwsEc2VpcFlowLogFilter}
                    render={optionalTableCell<Contract.AwsEc2VpcModel>(item => ec2FlowLogFilterTranslator(item.flowLogFilter))}
                    title={localization.columns.flowLogFilter()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2VpcModel>(
                            Contract.TypeNames.AwsResource,
                            item => (item as Contract.AwsEc2VpcModel).flowLogDestinationResourceIdReferences,
                            localization.columns.flowLogDestinationResourceIdReferences())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2VpcFlowLogDestinationResources)}
                                    placeholder={localization.columns.flowLogDestinationResourceIdReferences()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcFlowLogDestinationResources}
                    key={Contract.EntityModelProperty.AwsEc2VpcFlowLogDestinationResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.flowLogDestinationResourceIdReferences}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.flowLogDestinationResourceIdReferences()}/>,
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2VpcRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2VpcRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcFlowLogDestinationResources]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcFlowLogFilter]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcNetworkedResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcSubnets])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}