import { useExecuteOperation } from "@infrastructure";
import { Box, Divider, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, PermissionManagementHelper, tenantModelStore } from "../../../../../../common";
import { GroupMembership, OneLoginDirectoryRoleAssignment, Slack, Teams } from "./components";

export function PermissionManagement() {
    useExecuteOperation(
        PermissionManagement,
        PermissionManagementHelper.initialize);

    const activeTenantTypes = tenantModelStore.useGetActiveTenantTypes();
    return (
        <Box
            sx={{
                height: "100%",
                overflow: "auto"
            }}>
            <Stack>
                <Slack/>
                <Divider
                    flexItem={true}
                    variant="middle"/>
                <Teams/>
                {_.includes(activeTenantTypes, Contract.TenantType.Aad) &&
                    <Fragment>
                        <Divider
                            flexItem={true}
                            variant="middle"/>
                        <GroupMembership tenantType={Contract.TenantType.Aad}/>
                    </Fragment>}
                {_.includes(activeTenantTypes, Contract.TenantType.Gci) &&
                    <Fragment>
                        <Divider
                            flexItem={true}
                            variant="middle"/>
                        <GroupMembership tenantType={Contract.TenantType.Gci}/>
                    </Fragment>}
                {_.includes(activeTenantTypes, Contract.TenantType.Okta) &&
                    <Fragment>
                        <Divider
                            flexItem={true}
                            variant="middle"/>
                        <GroupMembership tenantType={Contract.TenantType.Okta}/>
                    </Fragment>}
                {_.includes(activeTenantTypes, Contract.TenantType.OneLogin) &&
                    <Fragment>
                        <Divider
                            flexItem={true}
                            variant="middle"/>
                        <OneLoginDirectoryRoleAssignment/>
                    </Fragment>}
                {_.includes(activeTenantTypes, Contract.TenantType.PingIdentity) &&
                    <Fragment>
                        <Divider
                            flexItem={true}
                            variant="middle"/>
                        <GroupMembership tenantType={Contract.TenantType.PingIdentity}/>
                    </Fragment>}
            </Stack>
        </Box>);
}