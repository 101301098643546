import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureStorageStorageAccountRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { EntityExternalConsoleLink, FirewallRuleSubnetsDetailsSection } from "../../components";

export function useAzureStorageStorageAccountWideRangeInboundRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureStorageStorageAccountWideRangeInboundRisk;
    const storageAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountModel;
    const storageAccount = storageAccountModel.entity as Contract.AzureStorageStorageAccount;

    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureStorageStorageAccountWideRangeInboundRiskDefinition",
            () => ({
                description: "{{storageAccount}} network configuration allows wide network inbound access",
                sections: {
                    firewallRules: "Firewall Rules",
                    resolution: {
                        step1: "Switch the **Public network access** toggle from **Enabled from all networks** toggle to **Enabled from selected virtual networks and IP addresses**",
                        step2: "Delete all public IP address rules",
                        step3: "Add firewall rules only to specific IP addresses and virtual networks that require access to the storage account",
                        step4: "Click **Save** button on top to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            storageAccount:
                <Entity
                    entityIdOrModel={storageAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            storageAccount.publicNetworkAccess
                ? localization.sections.resolution.step1()
                : undefined,
            storageAccount.wideRangeFirewallRuleSubnets.length > 0
                ? localization.sections.resolution.step2()
                : undefined,
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel);
            return [
                storageAccountRiskContext.generalInformation,
                storageAccountRiskContext.sensitive,
                storageAccountRiskContext.childResources,
                storageAccountRiskContext.publicBlobContainers,
                storageAccountRiskContext.sensitiveBlobContainers,
                storageAccountRiskContext.wideRangeInboundSubnets,
                storageAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections:
                _.isEmpty(storageAccount.wideRangeFirewallRuleSubnets)
                    ? []
                    : [
                        new RiskDefinitionSection(
                            <FirewallRuleSubnetsDetailsSection
                                getHighlightColor={
                                    (subnet: string, opacity) =>
                                        _.includes(storageAccount.wideRangeFirewallRuleSubnets, subnet)
                                            ? opacity
                                                ?theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                                : theme.palette.severity(riskModel.risk.severity)
                                            : undefined
                                }
                                subnets={storageAccount.firewallRuleSubnets}/>,
                            localization.sections.firewallRules())
                    ]
        });
}