import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingRouteTableStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingRouteTableStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingRouteTableStatus]: {
                    [Contract.OciNetworkingRouteTableStatus.Available]: "Available",
                    [Contract.OciNetworkingRouteTableStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingRouteTableStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingRouteTableStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciNetworkingRouteTableStatus) =>
        localization[Contract.TypeNames.OciNetworkingRouteTableStatus][status]();
}