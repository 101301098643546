﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGciDirectoryUserStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGciDirectoryUserStatusTranslator",
            () => ({
                [Contract.TypeNames.GciDirectoryUserStatus] : {
                    [Contract.GciDirectoryUserStatus.Active]: "Active",
                    [Contract.GciDirectoryUserStatus.Archived]: "Archived",
                    [Contract.GciDirectoryUserStatus.Suspended]: "Suspended"
                }
            }));
    return (status: Contract.GciDirectoryUserStatus) =>
        localization[Contract.TypeNames.GciDirectoryUserStatus][status]();
}