import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useCommonEntityNetworkInfoItemElements } from "../useCommonEntityNetworkInfoItemElements";

interface AzureCommonNetworkingInfoItemElementsProps {
    networkTags?: string[];
    privateIpAddresses?: string[];
    publicIpAddresses?: string[];
    resourceModel: Contract.GcpScopeResourceModel;
    subnetIds?: string | string[];
    vpcIds?: string | string[];
}
export function useGcpCommonNetworkingInfoItemElements({ networkTags, privateIpAddresses, publicIpAddresses, resourceModel, subnetIds, vpcIds }: AzureCommonNetworkingInfoItemElementsProps) {
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonNetworkingInfoItemElements",
            () => ({
                ipAddresses: {
                    private: "Private IP Address",
                    public: "Public IP Address"
                },
                networkTags: "Network Tags"
            }));

    return {
        ...commonEntityNetworkInfoItemElements,
        getNetworkTags:
            () =>
                <VerticalTopItemsInfoItem
                    items={networkTags}
                    key="networkTags"
                    title={localization.networkTags()}/>,
        getPrivateIpAddresses:
            () =>
                <VerticalTopItemsInfoItem
                    items={privateIpAddresses}
                    key="privateIpAddress"
                    title={localization.ipAddresses.private()}/>,
        getPublicIpAddresses:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={publicIpAddresses}
                    entityTypeName={Contract.TypeNames.AzureNetworkPublicIpAddress}
                    key="publicIpAddress"
                    title={localization.ipAddresses.public()}/>,
        getSubnets:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={subnetIds}
                    entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                    key={Contract.TypeNames.GcpComputeSubnet}/>,
        getVpcs:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={vpcIds}
                    entityTypeName={Contract.TypeNames.GcpComputeVpc}
                    key={Contract.TypeNames.GcpComputeVpc}/>
    };
}