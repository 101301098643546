import { DialogMenuItem, Link, NumberFormatter, TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements, useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements, useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../useDefaultDataAnalysisResourceInfoItemElements";

export function useGcpStorageBucketDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const bucketModel = scopeResourceModel as Contract.GcpStorageBucketModel;
    const bucket = bucketModel.entity as Contract.GcpStorageBucket;
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const commonLoggingLogSinkDestinationResourceInfoItemElements = useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(scopeResourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            bucketModel,
            dataAnalysis => dataAnalysis.objectStoreScanEnabled);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpStorageBucketDefinition",
            () => ({
                info: {
                    items: {
                        defaultObjectRetentionTimeFrame: {
                            empty: "None",
                            title: "Retention Policy"
                        },
                        locationTypeSystemName: "Location Type",
                        lockedRetentionPolicy: {
                            false: "Unlocked",
                            title: "Retention Mode",
                            true: "Locked"
                        },
                        objectCount: {
                            empty: "Unknown",
                            title: "Object Count"
                        },
                        objectVersioningEnabled: {
                            false: "Off",
                            title: "Object Versioning",
                            true: "On"
                        },
                        publicAccessPreventionType: {
                            [Contract.TypeNames.PublicAccessPreventionType]: {
                                [Contract.PublicAccessPreventionType.Enforced]: "Enabled",
                                [Contract.PublicAccessPreventionType.EnforcedInherited]: "Enabled (Inherited)",
                                [Contract.PublicAccessPreventionType.NotEnforced]: "Not Enabled"
                            },
                            title: "Prevent Public Access"
                        },
                        size: {
                            empty: "Unknown",
                            title: "Size"
                        },
                        uniformAccessControl: {
                            false: "Fine Grained",
                            title: "Access Control",
                            true: "Uniform"
                        },
                        url: "Bucket URL"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    commonLoggingLogSinkDestinationResourceInfoItemElements.loggingLogSinks,
                    <InfoItem
                        key="url"
                        title={localization.info.items.url()}
                        value={
                            _.isNil(bucket.url)
                                ? undefined
                                : <Link
                                    urlOrGetUrl={bucket.url}
                                    variant="external"/>}/>,
                    <InfoItem
                        key="locationTypeSystemName"
                        title={localization.info.items.locationTypeSystemName()}
                        value={bucket.locationTypeSystemName}/>,
                    <InfoItem
                        key="publicAccessPreventionType"
                        location="all"
                        title={localization.info.items.publicAccessPreventionType.title()}
                        value={localization.info.items.publicAccessPreventionType[Contract.TypeNames.PublicAccessPreventionType][bucket.publicAccessPreventionType]()}/>,
                    <InfoItem
                        key="uniformAccessControl"
                        location="all"
                        title={localization.info.items.uniformAccessControl.title()}
                        value={
                            bucket.uniformAccessControl
                                ? localization.info.items.uniformAccessControl.true()
                                : localization.info.items.uniformAccessControl.false()}/>,
                    <InfoItem
                        emptyValue={localization.info.items.size.empty()}
                        key="size"
                        location="all"
                        title={localization.info.items.size.title()}
                        value={
                            _.isNil(bucket.size)
                                ? undefined
                                : NumberFormatter.storage(bucket.size)}/>,
                    <InfoItem
                        emptyValue={localization.info.items.objectCount.empty()}
                        key="objectCount"
                        title={localization.info.items.objectCount.title()}
                        value={
                            _.isNil(bucket.objectCount)
                                ? undefined
                                : NumberFormatter.humanize(bucket.objectCount)}/>,
                    <InfoItem
                        key="objectVersioningEnabled"
                        title={localization.info.items.objectVersioningEnabled.title()}
                        value={
                            bucket.objectVersioningEnabled
                                ? localization.info.items.objectVersioningEnabled.true()
                                : localization.info.items.objectVersioningEnabled.false()}/>,
                    <InfoItem
                        emptyValue={localization.info.items.defaultObjectRetentionTimeFrame.empty()}
                        key="defaultObjectRetentionTimeFrame"
                        title={localization.info.items.defaultObjectRetentionTimeFrame.title()}
                        value={
                            _.isNil(bucket.defaultObjectRetentionTimeFrame)
                                ? undefined
                                : TimeSpanFormatter.humanizeDays(bucket.defaultObjectRetentionTimeFrame)}/>,
                    <InfoItem
                        key="defaultObjectRetentionTimeFrame"
                        title={localization.info.items.lockedRetentionPolicy.title()}
                        value={
                            bucket.lockedRetentionPolicy
                                ? localization.info.items.lockedRetentionPolicy.true()
                                : localization.info.items.lockedRetentionPolicy.false()}/>,
                    ...defaultDataAnalysisResourceInfoItemElements
                ]}
                options={options?.infoOptions}>
            </Info>,
        topbarMenuItems: _.filter([getDataAnalysisResourcePriorityScanRequestDialogMenuItem()]) as DialogMenuItem[]
    });
}