import { DataTableColumn, DataTableColumnProps, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EmptyMessageText, EnumValuesFilter, Localization, LocalizationTranslation, Optional, optionalTableCell, PagedValuesFilter, TimeRangeFilter, useFormatRelativeTime } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { Contract, EntitiesCell, EntityAttributeFilter, EntityAttributesCell, NetworkInboundAccessTypeCell, NetworkInboundAccessTypeFilter, PagedEntityFilter, ResourceTagData, ResourceTagHelper, RiskChartCell, ScopeTenantFilter, SeverityFilter, Tenant, TimeRangeHelper, WorkloadController } from "../../../../../common";
import { useOperatingSystemTypeTranslator } from "../../Entities/hooks";
import { VulnerabilitiesChartCell } from "../components";
import { InlineItemsCell } from "../components/InlineItemsCell";
import { useGetWorkloadResourceScanPackageValueFilterItemPage, useGetWorkloadResourceScanValueFilterItemPage } from "./useGetValueFilterItemPage";
import { useGetWorkloadResourceScanEntityFilterItemPage } from "./useGetWorkloadResourceScanEntityFilterItemPage";

type PackageDisplayNameDataTableColumnLocalizationParameters = {
    columns: {
        packages: string;
    };
    empty: {
        packages: {
            withFilter: string;
            withoutFilter: string;
        };
    };
};

type WorkloadResourceScanFiltersPromise = Promise<Contract.WorkloadResourceScanFilters>;

export function useAttributesDataTableColumn(
    filtersPromise: WorkloadResourceScanFiltersPromise,
    localization: Localization<{ attributes: string }>,
    vulnerabilityRawId?: string) {
    return <DataTableColumn
        disableAction={true}
        filterOptions={{
            itemOrItems: {
                element:
                    <DeferredFilter
                        promiseOrGetPromise={filtersPromise}
                        title={localization.attributes()}>
                        {filters =>
                            <EntityAttributeFilter
                                emptyValue={filters.attributeValueItems.emptyValue}
                                entityAttributeValues={filters.attributeValueItems.items}
                                placeholder={localization.attributes()}/>}
                    </DeferredFilter>
            }
        }}
        id={Contract.WorkloadControllerRequestProperty.WorkloadResourceAttribute}
        key={Contract.WorkloadControllerRequestProperty.WorkloadResourceAttribute}
        render={
            ({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                <EntityAttributesCell
                    entityAttributes={item.attributes.attributes}
                    entityTypeName={item.typeName}/>}
        selectorOptions={{ default: !vulnerabilityRawId }}
        sortOptions={{ enabled: false }}
        title={localization.attributes()}/>;
}

export function useNetworkInboundAccessTypeDataTableColumn(
    localization: Localization<{ networkInboundAccessType: string }>,
    vulnerabilityRawId?: string) {
    return useMemo(() => <DataTableColumn
        filterOptions={{
            itemOrItems: {
                default: !vulnerabilityRawId,
                element:
                        <NetworkInboundAccessTypeFilter placeholder={localization.networkInboundAccessType()}/>
            }
        }}
        id={Contract.WorkloadControllerRequestProperty.NetworkInboundAccessType}
        key={Contract.WorkloadControllerRequestProperty.NetworkInboundAccessType}
        render={
            ({ item: workloadResourceModel }: DataTableColumnRenderProps<Contract.WorkloadResourceVirtualMachineModel>) =>
                <NetworkInboundAccessTypeCell
                    networkInboundAccessType={workloadResourceModel.networkInboundAccessType}/>}
        selectorOptions={{ default: !vulnerabilityRawId }}
        title={localization.networkInboundAccessType()}/>,
    [localization]);
}

export function usePackageDisplayNameDataTableColumn(
    localization: Localization<PackageDisplayNameDataTableColumnLocalizationParameters>,
    workloadResourceType: Contract.WorkloadAnalysisWorkloadResourceType,
    vulnerabilityRawId?: string) {
    const fetchPackages =
        useCallback(
            async (workloadResourceModelId: string) => {
                const { items } =
                    await WorkloadController.getWorkloadResourceModelPropertyItems(
                        new Contract.WorkloadControllerWorkloadResourceModelPropertyItemsRequest(
                            Contract.WorkloadControllerRequestProperty.PackageDisplayName,
                            workloadResourceModelId)) as Contract.WorkloadControllerWorkloadResourceModelPropertyItemsResponse<Contract.WorkloadResourceModelPackage>;
                return items;
            },
            []);
    const getWorkloadResourceScanPackageValueFilterItemPage =
        useGetWorkloadResourceScanPackageValueFilterItemPage(
            Contract.WorkloadControllerRequestProperty.PackageDisplayName,
            undefined,
            workloadResourceType);
    return useMemo(
        () =>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={getWorkloadResourceScanPackageValueFilterItemPage}
                                placeholder={localization.columns.packages()}/>
                    }
                }}
                id={Contract.WorkloadControllerRequestProperty.PackageDisplayName}
                key={Contract.WorkloadControllerRequestProperty.PackageDisplayName}
                render={
                    optionalTableCell((workloadResourceModel: Contract.WorkloadResourceModel) =>
                        workloadResourceModel.packageCount > 0 &&
                        !_.isEmpty(workloadResourceModel.packages) &&
                        <InlineItemsCell
                            emptyMessageText={
                                new EmptyMessageText(
                                    localization.empty.packages.withoutFilter(),
                                    localization.empty.packages.withFilter())}
                            getItemDisplayName={(workloadResourceModelPackage: Contract.WorkloadResourceModelPackage) => workloadResourceModelPackage.displayName}
                            getPopoverItems={async () => await fetchPackages(workloadResourceModel.id)}
                            itemCount={workloadResourceModel.packageCount}
                            items={workloadResourceModel.packages}
                            renderItem={
                                (workloadResourceModelPackage: Contract.WorkloadResourceModelPackage) =>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}
                                        sx={{ overflow: "hidden" }}>
                                        <Typography
                                            noWrap={true}
                                            sx={{ width: "max-content" }}>
                                            {workloadResourceModelPackage.displayName}
                                        </Typography>
                                        {!_.isNil(workloadResourceModelPackage.filePath) &&
                                            <Typography
                                                noWrap={true}
                                                sx={{ flex: 1 }}
                                                variant="subtitle1">
                                                {workloadResourceModelPackage.filePath}
                                            </Typography>}
                                    </Stack>
                            }/>)}
                selectorOptions={{ default: !vulnerabilityRawId }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.packages()}/>,
        [localization, fetchPackages, getWorkloadResourceScanPackageValueFilterItemPage]);
}

export function useScanTimeDataTableColumn(
    filtersPromise: WorkloadResourceScanFiltersPromise,
    localization: Localization<{ scanTime: string }>,
    vulnerabilityRawId?: string) {
    return useTimeRangeDataTableColumn(
        filtersPromise,
        (workloadResourceModel: Contract.WorkloadResourceModel) => workloadResourceModel.scanTime,
        Contract.WorkloadControllerRequestProperty.ScanTimeRange,
        filters => filters.scanTimeRange,
        localization.scanTime,
        vulnerabilityRawId);
}

export function useOperatingSystemDataTableColumn(
    localization: Localization<{ operatingSystem: string }>,
    workloadResourceType: Contract.WorkloadAnalysisWorkloadResourceType,
    vulnerabilityRawId?: string) {
    const workloadResourceScanValueFilterItemPage =
        useGetWorkloadResourceScanValueFilterItemPage(
            Contract.WorkloadControllerRequestProperty.OperatingSystemDisplayName,
            workloadResourceType,
            true,
            undefined,
            vulnerabilityRawId);
    return useMemo(() =>
        <DataTableColumn
            filterOptions={{
                itemOrItems: {
                    default: !!vulnerabilityRawId,
                    element:
                            <PagedValuesFilter
                                getValuePage={workloadResourceScanValueFilterItemPage}
                                placeholder={localization.operatingSystem()}/>
                }
            }}
            id={Contract.WorkloadControllerRequestProperty.OperatingSystemDisplayName}
            key={Contract.WorkloadControllerRequestProperty.OperatingSystemDisplayName}
            render={optionalTableCell<Contract.WorkloadResourceModel>(item => item.operatingSystemDisplayName)}
            title={localization.operatingSystem()}/>,
    [localization, workloadResourceScanValueFilterItemPage]);

}

export function useOperatingSystemTypeDataTableColumn(
    localization: Localization<{ operatingSystemType: string }>,
    vulnerabilityRawId?: string) {
    const operatingSystemTypeTranslator = useOperatingSystemTypeTranslator();

    return useMemo(() => <DataTableColumn
        filterOptions={{
            itemOrItems: {
                element:
                        <EnumValuesFilter
                            enumType={Contract.OperatingSystemType}
                            enumTypeTranslator={operatingSystemTypeTranslator}
                            placeholder={localization.operatingSystemType()}/>
            }
        }}
        id={Contract.WorkloadControllerRequestProperty.OperatingSystemType}
        key={Contract.WorkloadControllerRequestProperty.OperatingSystemType}
        selectorOptions={{ default: !vulnerabilityRawId }}
        title={localization.operatingSystemType()}/>,
    [localization, operatingSystemTypeTranslator]);
}

export function useTenantIdDataTableColumn(
    filtersPromise: WorkloadResourceScanFiltersPromise,
    localization: Localization<{ tenantId: string }>,
    vulnerabilityRawId?: string) {
    return <DataTableColumn
        filterOptions={{
            itemOrItems: {
                default: !!vulnerabilityRawId,
                element:
                    <DeferredFilter
                        promiseOrGetPromise={filtersPromise}
                        title={localization.tenantId()}>
                        {filters =>
                            <ScopeTenantFilter
                                placeholder={localization.tenantId()}
                                tenantIds={filters.tenantIdItems.items}/>}
                    </DeferredFilter>
            }
        }}
        id={Contract.WorkloadControllerRequestProperty.TenantId}
        key={Contract.WorkloadControllerRequestProperty.TenantId}
        render={
            ({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                <Typography noWrap={true}>
                    <Tenant
                        tenantId={item.tenantId}
                        variant="iconText"/>
                </Typography>}
        sortOptions={{ enabled: false }}
        title={localization.tenantId()}/>;
}

export function useTimeRangeDataTableColumn<TWorkloadResourceScanFilters extends Contract.WorkloadResourceScanFilters, TModel>(
    filtersPromise: Promise<TWorkloadResourceScanFilters>,
    modelTime: (model: TModel) => Optional<string>,
    property: string,
    getFilterTimeRange: (filters: TWorkloadResourceScanFilters) => Optional<Contract.FilterTimeRange>,
    title: LocalizationTranslation<string>,
    vulnerabilityRawId?: string) {
    const formatRelativeTime = useFormatRelativeTime();

    return <DataTableColumn
        filterOptions={{
            itemOrItems: {
                element:
                    <DeferredFilter
                        promiseOrGetPromise={filtersPromise}
                        title={title()}>
                        {filters => {
                            const filterTimeRange = getFilterTimeRange(filters);
                            return <TimeRangeFilter
                                emptyValue={filterTimeRange?.emptyValue}
                                placeholder={title()}
                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filterTimeRange?.timeRange)}/>;
                        }}
                    </DeferredFilter>
            }
        }}
        id={property}
        key={property}
        render={
            optionalTableCell(
                (model: TModel) => {
                    const time = modelTime(model);
                    return time && formatRelativeTime(time);
                })}
        selectorOptions={{ default: !vulnerabilityRawId }}
        sortOptions={{ type: DataTableSortType.Date }}
        title={title()}/>;
}

export function useVulnerabilitiesDataTableColumn(
    localization: Localization<{ vulnerabilities: string; vulnerabilitySeverityChart: string }>,
    workloadResourceType: Contract.WorkloadAnalysisWorkloadResourceType,
    vulnerabilityRawId?: string) {
    const vulnerabilitiesPagedValuesFilter =
        useGetWorkloadResourceScanPackageValueFilterItemPage(
            Contract.WorkloadControllerRequestProperty.VulnerabilityRawId,
            undefined,
            workloadResourceType);

    return <DataTableColumn
        cellMinWidth={190}
        filterOptions={{
            itemOrItems:
                _.isNil(vulnerabilityRawId)
                    ? [
                        {
                            default: !vulnerabilityRawId,
                            element:
                                <PagedValuesFilter
                                    getValuePage={vulnerabilitiesPagedValuesFilter}
                                    placeholder={localization.vulnerabilities()}/>,
                            id: Contract.WorkloadControllerRequestProperty.VulnerabilityRawId,
                            title: localization.vulnerabilities()
                        },
                        {
                            element:
                                <SeverityFilter
                                    information={false}
                                    placeholder={localization.vulnerabilitySeverityChart()}/>,
                            id: Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity,
                            title: localization.vulnerabilitySeverityChart()
                        }
                    ]
                    : undefined
        }}
        id={Contract.WorkloadControllerRequestProperty.VulnerabilityRawId}
        render={
            ({ item: workloadResourceModel }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                <VulnerabilitiesChartCell
                    vulnerabilities={workloadResourceModel.vulnerabilities}
                    workloadResourceEntityId={workloadResourceModel.id}/>}
        selectorOptions={{ default: !vulnerabilityRawId }}
        sortOptions={{ type: DataTableSortType.Numeric }}
        title={localization.vulnerabilities()}/>;
}

export function useResourceTagsDataTableColumn(
    localization: Localization<{ tags: string }>,
    workloadResourceType: Contract.WorkloadAnalysisWorkloadResourceType,
    render?: DataTableColumnProps["render"],
    vulnerabilityRawId?: string) {
    return <DataTableColumn
        filterOptions={{
            itemOrItems: {
                element:
                    <PagedValuesFilter
                        getValuePage={
                            useGetWorkloadResourceScanValueFilterItemPage(
                                Contract.WorkloadControllerRequestProperty.ResourceTags,
                                workloadResourceType,
                                true,
                                ResourceTagData.createId)}
                        placeholder={localization.tags()}>
                        {(_, resourceTag: Contract.ResourceTag) =>
                            <Typography>
                                {ResourceTagHelper.getDisplayName(resourceTag)}
                            </Typography>}
                    </PagedValuesFilter>
            }
        }}
        id={Contract.WorkloadControllerRequestProperty.ResourceTags}
        key={Contract.WorkloadControllerRequestProperty.ResourceTags}
        render={render}
        selectorOptions={{ default: !vulnerabilityRawId }}
        sortOptions={{ enabled: false }}
        title={localization.tags()}/>;
}

export function useVirtualMachinesDataTableColumn(
    typeName: Contract.WorkloadAnalysisWorkloadResourceType,
    localization: Localization<{ virtualMachineIds: string }>,
    vulnerabilityRawId?: string) {
    return <DataTableColumn
        filterOptions={{
            itemOrItems: {
                element:
                    <PagedEntityFilter
                        getEntityIdPage={
                            useGetWorkloadResourceScanEntityFilterItemPage(
                                Contract.WorkloadControllerRequestProperty.VirtualMachines,
                                typeName)}
                        placeholder={localization.virtualMachineIds()}/>
            }
        }}
        id={Contract.WorkloadControllerRequestProperty.VirtualMachines}
        key={Contract.WorkloadControllerRequestProperty.VirtualMachines}
        render={
            ({ item }: DataTableColumnRenderProps<{ virtualMachineIds: string[] }>) =>
                <EntitiesCell
                    entityIdsOrModels={item.virtualMachineIds}
                    entityTypeName={Contract.TypeNames.IVirtualMachine}
                    entityVariant="iconText"/>}
        selectorOptions={{ default: !vulnerabilityRawId }}
        sortOptions={{ type: DataTableSortType.Numeric }}
        title={localization.virtualMachineIds()}/>;
}

export function useRiskSeverityDataTableColumn(
    localization: Localization<{ risks: string; risksSeverity: string }>,
    vulnerabilityRawId?: string) {
    return <DataTableColumn
        cellMinWidth={180}
        filterOptions={{
            itemOrItems: {
                element:
                    <SeverityFilter
                        information={false}
                        placeholder={localization.risksSeverity()}/>,
                title: localization.risksSeverity()
            }
        }}
        id={Contract.WorkloadControllerRequestProperty.Risks}
        key={Contract.WorkloadControllerRequestProperty.Risks}
        render={
            ({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                <RiskChartCell
                    itemId={item.id}
                    riskSeverityToIdsMap={item.riskSeverityToIdsMap}/>}
        selectorOptions={{ default: !vulnerabilityRawId }}
        sortOptions={{
            type: DataTableSortType.Numeric
        }}
        title={localization.risks()}/>;
}