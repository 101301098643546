import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import _ from "lodash";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { DestinationNetworkScopes, EntitySelector, EntitySelectorSelection, InlineDestinationNetworkScopes, InlineEntitySelection } from "../..";
import { InlineSourceIpAddressSelection } from "../../InlineSourceIpAddressSelection";
import { SourceIpAddressSelector, SourceIpAddressSelectorAnyIpV4AddressSubnetSelection, SourceIpAddressSelectorExactSubnetSelection, SourceIpAddressSelectorInboundAccessScopeSelection, SourceIpAddressSelectorOverlapSubnetSelection, SourceIpAddressSelectorSelection } from "../../SourceIpAddressSelector";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    createRequest: EditCreateRequest;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

type EditCreateRequest =
    (
        allEntities: boolean,
        anyIpV4AddressSubnet: boolean,
        description: Optional<string>,
        entityBuiltInAttributeTypeNames: Optional<string[]>,
        entityCustomAttributeDefinitionIds: Optional<string[]>,
        entityIds: Optional<string[]>,
        entityTags: Optional<Contract.ResourceTag[]>,
        exactSubnets: Optional<string[]>,
        inboundAccessScope: Optional<Contract.NetworkAccessScope>,
        name: string,
        networkScopes: Contract.DestinationNetworkScope[],
        overlapSubnets: Optional<string[]>,
        scopeId: string,
        severity: Contract.Severity) => Contract.RiskControllerInsertAwsEc2SecurityGroupInboundRuleRiskPolicyRequest;

export function Edit({ actionsRef, createRequest, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const { scopeId } = useCustomRiskPolicyContext();

    const [networkScopesValid, setNetworkScopesValid] = useState(false);
    const [securityGroupsValid, setSecurityGroupsValid] = useState(false);
    const [sourceIpAddressesValid, setSourceIpAddressesValid] = useState(false);
    const [view, setView] = useState<EditView | undefined>(EditView.SecurityGroups);

    const [networkScopes, setNetworkScopes] = useState(riskPolicyConfiguration?.networkScopes ?? []);
    const [securityGroupSelectorSelection, setSecurityGroupSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.allSecurityGroups,
                undefined,
                undefined,
                riskPolicyConfiguration?.securityGroupBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.securityGroupCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.securityGroupIds,
                undefined,
                undefined,
                riskPolicyConfiguration?.securityGroupTags,
                undefined));
    const [sourceIpAddressSelectorSelection, setSourceIpAddressSelectorSelection] =
        useState<SourceIpAddressSelectorSelection | undefined>(
            !_.isNil(riskPolicyConfiguration?.inboundAccessScope)
                ? new SourceIpAddressSelectorInboundAccessScopeSelection(riskPolicyConfiguration!.inboundAccessScope)
                : riskPolicyConfiguration?.anyIpV4AddressSubnet === true
                    ? new SourceIpAddressSelectorAnyIpV4AddressSubnetSelection()
                    : !_.isNil(riskPolicyConfiguration?.exactSubnets)
                        ? SourceIpAddressSelectorExactSubnetSelection.fromSubnets(riskPolicyConfiguration!.exactSubnets)
                        : !_.isNil(riskPolicyConfiguration?.overlapSubnets)
                            ? SourceIpAddressSelectorOverlapSubnetSelection.fromSubnets(riskPolicyConfiguration!.overlapSubnets)
                            : undefined);

    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityTags } = securityGroupSelectorSelection!.getInlineSelectionData();
                    const { anyIpV4AddressSubnet, exactSubnets, inboundAccessScope, overlapSubnets } = sourceIpAddressSelectorSelection!.getInlineSelectionData();
                    return createRequest(
                        allEntities ?? false,
                        anyIpV4AddressSubnet ?? false,
                        description,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityTags,
                        exactSubnets,
                        inboundAccessScope,
                        name,
                        networkScopes,
                        overlapSubnets,
                        scopeId,
                        severity);
                }
        });

    useEffect(
        () => {
            const securityGroupsValid = securityGroupSelectorSelection?.valid() ?? false;
            const sourceIpAddressesValid = sourceIpAddressSelectorSelection?.valid() ?? false;
            setSecurityGroupsValid(securityGroupsValid);
            setSourceIpAddressesValid(sourceIpAddressesValid);
            onValidChange(
                networkScopesValid &&
                securityGroupsValid &&
                sourceIpAddressesValid);
        },
        [networkScopesValid, securityGroupSelectorSelection, sourceIpAddressSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsEc2SecurityGroupInboundRuleRiskPolicy.edit",
            () => ({
                ports: "Ports",
                prompt: "Select the required security groups, source IP addresses and ports",
                sourceIpAddresses: "Source IP Addresses"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        networkScopes:
                            <InlineDestinationNetworkScopes
                                destinationNetworkScopes={networkScopes}/>,
                        securityGroups:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                selection={securityGroupSelectorSelection}/>,
                        sourceIpAddresses:
                            <InlineSourceIpAddressSelection selection={sourceIpAddressSelectorSelection}/>
                    })}
                </Typography>
                <Box>
                    <Accordion
                        expanded={view === EditView.SecurityGroups}
                        onChange={
                            (_, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.SecurityGroups
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AwsEc2SecurityGroup,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {securityGroupsValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.SensitiveResourceAttribute,
                                    Contract.TypeNames.CodeResourceAttribute
                                ]}
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                properties={["all", "tags", "attributes"]}
                                scopeId={scopeId}
                                selection={securityGroupSelectorSelection}
                                onSelectionChanged={setSecurityGroupSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.SourceIpAddresses}
                        onChange={
                            (_, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.SourceIpAddresses
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {localization.sourceIpAddresses()}
                                </Typography>
                                {sourceIpAddressesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SourceIpAddressSelector
                                selection={sourceIpAddressSelectorSelection}
                                onSelectionChanged={setSourceIpAddressSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.NetworkScopes}
                        onChange={
                            (_, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.NetworkScopes
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {localization.ports()}
                                </Typography>
                                {networkScopesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DestinationNetworkScopes
                                existingDestinationNetworkScopes={networkScopes}
                                onDestinationNetworkScopesChanged={setNetworkScopes}
                                onValidChanged={setNetworkScopesValid}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}

enum EditView {
    NetworkScopes = "networkScopes",
    SecurityGroups = "securityGroups",
    SourceIpAddresses = "sourceIpAddresses"
}