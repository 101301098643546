﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { VulnerabilitiesCell } from "../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem } from "../../../../components";

export function useAzureCommonKubernetesWorkloadResourceInfoItemElements(workloadResourceModel: Contract.AzureKubernetesWorkloadResourceModel) {
    const workloadResource = workloadResourceModel.entity as Contract.AzureKubernetesWorkloadResourceBase;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonKubernetesWorkloadResourceInfoItemElements",
            () => ({
                aadServicePrincipalIds: [
                    "Microsoft Entra ID Application",
                    "Microsoft Entra ID Applications"
                ],
                agentPoolIds: "Node Pools",
                containerImages: "Container Images",
                containerImageVulnerabilities: "Vulnerabilities",
                controllerResourceReference: "Owner",
                serviceAccountIdReference: "Service Account"
            }));

    return {
        aadServicePrincipalsInfoItemElement:
            _.isEmpty(workloadResourceModel.aadServicePrincipalIds)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.aadServicePrincipalIds}
                    entityTypeName={Contract.TypeNames.AadDirectoryServicePrincipal}
                    key="aadServicePrincipalIds"
                    title={localization.aadServicePrincipalIds(_.size(workloadResourceModel.aadServicePrincipalIds))}/>,
        agentPoolsInfoItemElement:
            _.isEmpty(workloadResourceModel.agentPoolIds)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.agentPoolIds}
                    entityTypeName={Contract.TypeNames.AzureContainerServiceManagedClusterAgentPool}
                    key="agentPoolIds"
                    title={localization.agentPoolIds()}/>,
        containerImagesInfoItemElement:
            _.isEmpty(workloadResourceModel.containerImageIdReferences)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.containerImageIdReferences}
                    entityTypeName={Contract.TypeNames.IContainerImage}
                    key="containerImages"
                    title={localization.containerImages()}/>,
        containerImageVulnerabilitiesInfoItemElement:
            _.isEmpty(workloadResourceModel.containerImageVulnerabilities)
                ? undefined
                : <InfoItem
                    key="containerImageVulnerabilities"
                    title={localization.containerImageVulnerabilities()}
                    value={
                        <VulnerabilitiesCell
                            containerImageScanStatus={workloadResourceModel.containerImageScanStatus}
                            variant="initialAndCount"
                            vulnerabilities={workloadResourceModel.containerImageVulnerabilities}/>}/>,
        controllerResourceInfoItemElement:
            _.isNil(workloadResourceModel.controllerResourceReference)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.controllerResourceReference.idReference}
                    entityTypeName={Contract.TypeNames.AzureKubernetesResource}
                    key="controllerResourceReference"
                    location="all"
                    title={localization.controllerResourceReference()}/>,
        serviceAccountInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={workloadResource.data.podTemplateSpec.podSpec.serviceAccountReference.idReference}
                entityTypeName={Contract.TypeNames.AzureKubernetesServiceAccount}
                key="serviceAccountIdReference"
                title={localization.serviceAccountIdReference()}/>
    };
}