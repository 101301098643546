import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonIamPrincipalDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOciIamGroupDefinition(definitionData: DefinitionData) {
    const commonIamPrincipalDefinition = useOciCommonIamPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciIamGroupDefinition",
            () => ({
                columns: {
                    memberUserIds: "Members"
                }
            }));

    return new EntityTableDefinition(
        [
            commonIamPrincipalDefinition.columns.tenantColumn,
            commonIamPrincipalDefinition.columns.domainIdColumn,
            commonIamPrincipalDefinition.columns.creationTimeColumn,
            commonIamPrincipalDefinition.columns.creatorCsvColumn,
            commonIamPrincipalDefinition.columns.descriptionColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciIamGroupModel>(
                        Contract.TypeNames.OciIamUser,
                        item => item.memberUserIds,
                        localization.columns.memberUserIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.OciIamUser,
                                        true)}
                                placeholder={localization.columns.memberUserIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciIamGroupMemberUsers}
                key={Contract.EntityModelProperty.OciIamGroupMemberUsers}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciIamGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.memberUserIds}
                            entityTypeName={Contract.TypeNames.OciIamUser}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.memberUserIds()}/>,
            commonIamPrincipalDefinition.columns.tagsColumn,
            commonIamPrincipalDefinition.columns.attributesColumn,
            commonIamPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonIamPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonIamPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciIamGroupRequest(
                new Contract.EntityControllerGetEntityModelPageOciIamGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamResourceDescription]),
                    undefined,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamPrincipalDomain]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamGroupMemberUsers])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}