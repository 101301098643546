﻿import { makeContextProvider } from "@infrastructure";
import { Box, List, ListItem, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Contract } from "../../../../../../../../../common";
import { CodeExclusion } from "./CodeExclusion";

export class CodeExclusionsContext {
    constructor(public codeExclusionItems: CodeExclusionItem[]) {
    }
}

export const [, useSetCodeExclusionsContext, useCodeExclusionsContextProvider] = makeContextProvider<CodeExclusionsContext>();

type CodeExclusionsProps = {
    items: CodeExclusionItem[];
    onItemsChanged: (items: CodeExclusionItem[]) => void;
    onValidChanged: (valid: boolean) => void;
    parentFileRelativePathPatternToScopeIdsMap: Dictionary<string[]>;
    readOnly: boolean;
};

export function CodeExclusions({ items, onItemsChanged, onValidChanged, parentFileRelativePathPatternToScopeIdsMap, readOnly }: CodeExclusionsProps) {
    const [context, , ContextProvider] = useCodeExclusionsContextProvider(() => new CodeExclusionsContext(items));
    const codeExclusionItems =
        useMemo(
            () =>
                context.codeExclusionItems.length < 50 && !readOnly
                    ? _.concat(
                        context.codeExclusionItems,
                        new CodeExclusionItem(
                            new Contract.RiskPolicyConfigurationCodeExclusion(
                                new Contract.RiskPolicyConfigurationExclusionData(
                                    undefined,
                                    undefined,
                                    Contract.RiskPolicyConfigurationExclusionDataReason.Exception),
                                "",
                                undefined,
                                undefined),
                            false,
                            false,
                            false,
                            undefined,
                            false,
                            true))
                    : context.codeExclusionItems,
            [context.codeExclusionItems]);

    const initialCodeExclusionItems =
        useMemo(
            () => context.codeExclusionItems,
            []);

    useEffect(
        () => {
            onItemsChanged(context.codeExclusionItems);
            onValidChanged(
                (_.every(
                    context.codeExclusionItems,
                    item =>
                        _.size(initialCodeExclusionItems) !== _.size(context.codeExclusionItems) ||
                        (item.isValid() && !item.duplicate) &&
                        _.some(context.codeExclusionItems, item => item.changed))));
        },
        [context.codeExclusionItems]);

    return (
        <ContextProvider>
            <Stack
                spacing={2}
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <Box
                    sx={{
                        flex: 1,
                        overflowY: "auto"
                    }}>
                    <List disablePadding={true}>
                        {_(codeExclusionItems).
                            map(
                                codeExclusionItem =>
                                    <ListItem
                                        key={codeExclusionItem.id}
                                        sx={{ paddingLeft: 0 }}>
                                        <CodeExclusion
                                            item={codeExclusionItem}
                                            parentScopeIds={
                                                codeExclusionItem.dirty
                                                    ? undefined
                                                    : parentFileRelativePathPatternToScopeIdsMap[codeExclusionItem.codeExclusion.fileRelativePathPattern!]}
                                            readOnly={readOnly}/>
                                    </ListItem>).
                            value()}
                    </List>
                </Box>
            </Stack>
        </ContextProvider>);
}


export class CodeExclusionItem {
    private static _idCounter = 0;
    public changed: boolean;
    public dirty: boolean;
    public duplicate: boolean;
    public id: number;
    public inherited: boolean;
    public placeholder: boolean;

    constructor(
        public codeExclusion: Contract.RiskPolicyConfigurationCodeExclusion,
        changed: boolean,
        dirty?: boolean,
        duplicate?: boolean,
        id?: number,
        inherited?: boolean,
        placeholder?: boolean) {
        this.changed = changed;
        this.dirty = dirty ?? false;
        this.duplicate = duplicate ?? false;
        this.id = id ?? CodeExclusionItem._idCounter++;
        this.inherited = inherited ?? false;
        this.placeholder = placeholder ?? false;
    }

    public isValid(): boolean {
        return !_.isEmpty(this.codeExclusion.fileRelativePathPattern);
    }
}