import { DeleteIcon, Message, useLocalization } from "@infrastructure";
import { Box, FormHelperText, IconButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { useTheme } from "../../../../../../../themes";
import { TextInput } from "../../../../../../TextInput";
import { useSetPatternsSelectorContext } from "../PatternsSelector";

const MaxPatternLength = 256;

type ResourceTagPatternProps = {
    disabled: boolean;
    duplicate: boolean;
    item: PatternItem;
};

export function Pattern({ disabled, duplicate, item }: ResourceTagPatternProps) {
    const setContext = useSetPatternsSelectorContext();
    const [focus, setFocus] = useState(false);

    const localization =
        useLocalization(
            "common.customRiskPolicy.actionSelector.patternsSelector.pattern",
            () => ({
                duplicateError: "Duplicate value is not allowed",
                emptyError: "Pattern cannot be empty",
                helpText: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character.",
                placeholder: "Pattern"
            }));

    const onPatternChange =
        useCallback(
            (pattern: string) => {
                setContext(
                    context => {
                        const updatedItem =
                            new PatternItem(
                                pattern.substring(0, MaxPatternLength),
                                false,
                                item.id);
                        return {
                            patternItems:
                                item.placeholder
                                    ? _.concat(
                                        context.patternItems,
                                        updatedItem)
                                    : _.map(
                                        context.patternItems,
                                        patternItem =>
                                            patternItem.id === item.id
                                                ? updatedItem
                                                : patternItem)
                        };
                    });
            },
            [item]);

    const onDelete =
        () => {
            setContext(
                context => ({
                    patternItems: _.filter(
                        context.patternItems,
                        patternItem => patternItem.id !== item.id)
                }));
        };

    const theme = useTheme();
    return (
        <Stack sx={{ width: "100%" }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <TextInput
                    disabled={disabled}
                    placeholder={localization.placeholder()}
                    readOnly={disabled}
                    text={item.pattern}
                    onBlur={() => setFocus(false)}
                    onChange={onPatternChange}
                    onFocus={() => setFocus(true)}/>
                {item.placeholder
                    ? <Box sx={{ paddingLeft: theme.spacing(0.75) }}>
                        <Message
                            level="info"
                            title={
                                <Typography style={{ whiteSpace: "pre-wrap" }}>
                                    {localization.helpText()}
                                </Typography>}
                            variant="minimal"/>
                    </Box>
                    : <IconButton
                        disabled={disabled}
                        size="small"
                        onClick={onDelete}>
                        <DeleteIcon/>
                    </IconButton>}
            </Stack>
            {duplicate && !_.isEmpty(item.pattern.trim())
                ? <FormHelperText error={true}>{localization.duplicateError()}</FormHelperText>
                : !focus && !item.placeholder && _.isEmpty(item.pattern.trim()) &&
                <FormHelperText error={true}>{localization.emptyError()}</FormHelperText>}
        </Stack>);
}

export class PatternItem {
    private static _idCounter = 0;
    public id: number;
    public pattern: string;
    public placeholder: boolean;

    constructor(
        pattern: string,
        placeholder: boolean,
        id?: number) {
        this.id = id ?? PatternItem._idCounter++;
        this.pattern = pattern;
        this.placeholder = placeholder;
    }

    public valid() {
        return !_.isEmpty(this.pattern.trim());
    }
}