import { DownloadIcon, TextViewer, useBlobUrl } from "@infrastructure";
import { IconButton, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../common";

type KubernetesYamlInfoCardProps = {
    rawYaml: string;
};

export function KubernetesYamlInfoCard({ rawYaml }: KubernetesYamlInfoCardProps) {
    const documentFileUrl =
        useBlobUrl(
            rawYaml,
            "application/yaml");

    const theme = useTheme();
    return (
        <Stack sx={{ height: "100%" }}>
            <IconButton
                size="large"
                sx={{ alignSelf: "flex-end" }}
                onClick={
                    () => {
                        window.location.href = documentFileUrl;
                    }}>
                <DownloadIcon
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "18px"
                    }}/>
            </IconButton>
            <TextViewer
                format="yaml"
                text={rawYaml}/>
        </Stack>);
}