﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";

export function useDatabricksInstanceGroupRemediationSteps(riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskTagResourceGroupDefinition.hooks.useDatabricksInstanceGroupRemediationSteps",
            () => ({
                resolution: {
                    step1: {
                        link: "Secrets Manager",
                        text: "In Databricks, locate the cluster configuration and migrate your secrets to {{secretsManagerLink}}"
                    },
                    step2: {
                        link: "documentation",
                        text: "Update the cluster with the updated user data object using the following {{documentationLink}}"
                    }
                }
            }));
    return [
        localization.resolution.step1.text({
            secretsManagerLink:
                <Link
                    sx={{ textDecorationLine: "underline" }}
                    urlOrGetUrl={riskModel.secretsManagerDocumentationUrl}
                    variant="external">
                    {localization.resolution.step1.link()}
                </Link>
        }),
        localization.resolution.step2.text({
            documentationLink:
                <Link
                    sx={{ textDecorationLine: "underline" }}
                    urlOrGetUrl={riskModel.databricksDocumentationUrl}
                    variant="external">
                    {localization.resolution.step2.link()}
                </Link>
        })
    ];
}