import { useLocalization } from "@infrastructure";
import React from "react";
import { RiskPoliciesType, useLayoutOptions } from "../../../../../common";
import { RiskPoliciesView } from "../../RiskPolicies";

export function KubernetesAdmissionControllerRiskPolicies() {
    const localization =
        useLocalization(
            "views.customer.kubernetes.kubernetesAdmissionControllerRiskPolicies",
            () => ({
                title: "Admission Controller Policies"
            }));
    useLayoutOptions({ view: { title: localization.title() } });

    return <RiskPoliciesView type={RiskPoliciesType.KubernetesAdmissionController}/>;
}