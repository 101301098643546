import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function Rapid7Icon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M15.7433 3H1.45625C1.6924 3.23723 1.88919 3.43493 2.08598 3.7117C3.69967 5.64909 4.52619 8.10049 4.52619 10.7891C4.52619 14.9407 2.79443 18.6969 0 21.2273H8.73751L12.9095 10.8682H7.7142L8.58008 6.83526H18.8919L13.4605 21.1878H15.5858C20.545 21.2273 23.8904 17.0758 23.8904 12.0939C23.8904 7.11203 20.7024 3 15.7433 3Z"
                fill="#E95F26"/>
        </SvgIcon>);
}