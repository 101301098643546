import { browserHistory, CloseIcon, map, Optional } from "@infrastructure";
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { SyntheticEvent, useCallback, useEffect } from "react";
import { ActionButton, Loading } from ".";

type DialogProps = Omit<MuiDialogProps, "onClose" | "open"> & {
    onClose?: (() => void) | (() => Promise<void>);
    size?: DialogSize;
    variant: "editor" | "viewer";
};

export type DialogSize = "content" | "large" | "medium" | "small";

export function Dialog({ children, onClose, size = "small", variant, ...props }: DialogProps) {
    const handleClose =
        useCallback(
            async (_event: SyntheticEvent, reason: "backdropClick" | "escapeKeyDown") => {
                if (reason === "backdropClick" && variant === "editor") {
                    return false;
                }

                await onClose?.();
            },
            [variant, onClose]);
    useEffect(
        () => {
            const unregister =
                browserHistory.listen(
                    async update => {
                        if (update.action !== "POP") {
                            return;
                        }
                        await onClose?.();
                    });

            return () => unregister();
        },
        [onClose]);

    const theme = useTheme();
    return (
        <MuiDialog
            open={true}
            PaperProps={{
                sx: {
                    minHeight: theme.spacing(25),
                    ...map<string, Optional<SxProps>>(
                        size,
                        {
                            "content": () => ({
                                maxWidth: "unset"
                            }),
                            "large": () => ({
                                height: "85vh",
                                width: "85vw"
                            }),
                            "medium": () => ({
                                height: "85vh",
                                width: theme.spacing(112)
                            }),
                            "small": () => ({
                                maxWidth: theme.spacing(187.5),
                                width: theme.spacing(70)
                            })
                        },
                        () => undefined)
                }
            }}
            onClose={handleClose}
            {...props}>
            {!_.isNil(onClose) &&
                <ActionButton
                    size="medium"
                    sx={{
                        fontSize: "24px",
                        position: "absolute",
                        right: theme.spacing(1.5),
                        top: theme.spacing(1.5)
                    }}
                    onClick={onClose}>
                    <CloseIcon/>
                </ActionButton>}
            <Loading>
                {children}
            </Loading>
        </MuiDialog>);
}