import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, SeveritySquare, TypeHelper, useSeverityTranslator } from "../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../utilities";
import { useGetEntitiesRiskContext } from "./useGetEntitiesRiskContext";
import { useGetNetworkAccessResourcesRiskContext } from "./useGetNetworkAccessResourcesRiskContext";

export function useGetVirtualMachinesRiskContext() {
    return useMemo(
        () => useVirtualMachinesRiskContext,
        []);
}

export function useVirtualMachinesRiskContext(virtualMachineIdsOrModels: string[] | Contract.EntityModel[]) {
    const entityModels =
        entityModelStore.useGet(
            _.isString(virtualMachineIdsOrModels[0])
                ? virtualMachineIdsOrModels as string[]
                : undefined) ?? virtualMachineIdsOrModels as Contract.EntityModel[];
    const getEntitiesRiskContext = useGetEntitiesRiskContext();
    const getNetworkAccessResourcesRiskContext = useGetNetworkAccessResourcesRiskContext();
    const virtualMachineModels = _.as<Contract.IVirtualMachineModel[]>(entityModels);

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetVirtualMachinesRiskContext",
            () => ({
                identityPermissionActionSeverity: [
                    "{{entities}} has {{severityElement}}**{{severity}}** severity permissions",
                    "{{entities}} have {{severityElement}}**{{severity}}** severity permissions"
                ],
                stopped: [
                    "{{entities}} has been stopped for more than 30 days",
                    "{{entities}} have been stopped for more than 30 days"
                ]
            }));

    const identityPermissionActionSeverityToVirtualMachineModelMap =
        _(virtualMachineModels).
            filter(virtualMachineModel => !_.isNil(virtualMachineModel.identityPermissionActionSeverity)).
            groupBy(virtualMachineModel => virtualMachineModel.identityPermissionActionSeverity!).
            value();
    const identityPermissionActionSeverity =
        _(identityPermissionActionSeverityToVirtualMachineModelMap).
            keys().
            maxBy(identityPermissionActionSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, identityPermissionActionSeverity)) as Optional<Contract.Severity>;
    const stoppedVirtualMachineModels =
        _.filter(
            virtualMachineModels,
            virtualMachineModel => virtualMachineModel.stopped);

    return {
        ...getNetworkAccessResourcesRiskContext(entityModels),
        ...getEntitiesRiskContext(
            entityModels,
            Contract.TypeNames.IVirtualMachine),
        identityPermissionActionSeverity:
            _.isNil(identityPermissionActionSeverity)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.identityPermissionActionSeverity(
                        _.size(identityPermissionActionSeverityToVirtualMachineModelMap[identityPermissionActionSeverity]),
                        {
                            entities:
                                <InlineEntities
                                    entityIdsOrModels={
                                        _.map(
                                            identityPermissionActionSeverityToVirtualMachineModelMap[identityPermissionActionSeverity],
                                            virtualMachineModel => virtualMachineModel.id)}
                                    entityTypeName={Contract.TypeNames.IVirtualMachine}
                                    variant="itemCountAndType"/>,
                            severity: severityTranslator(identityPermissionActionSeverity!, "text"),
                            severityElement: <SeveritySquare severity={identityPermissionActionSeverity!}/>
                        })
                ),
        stopped:
            _.isEmpty(stoppedVirtualMachineModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.stopped(
                        stoppedVirtualMachineModels.length,
                        {
                            entities:
                                <InlineEntities
                                    entityIdsOrModels={
                                        _(virtualMachineModels).
                                            filter(virtualMachineModel => virtualMachineModel.stopped).
                                            map(virtualMachineModel => virtualMachineModel.id).
                                            value()}
                                    entityTypeName={Contract.TypeNames.IVirtualMachine}
                                    variant="itemCountAndType"/>
                        }))
    };
}