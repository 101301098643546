import { Loading, Message, setRef, useAsyncEffect, useLocalization } from "@infrastructure";
import { Box, SxProps, Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { cloneElement, forwardRef, memo, PropsWithChildren, ReactNode, Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";

const TooltipMemoForwardRef = memo(forwardRef(Tooltip));
export { TooltipMemoForwardRef as Tooltip };

export type TooltipProps =
    PropsWithChildren<
        Omit<MuiTooltipProps, "title" | "sx"> & {
            disabled?: boolean;
            maxWidth?: boolean;
            sx?: SxProps;
            titleOrGetTitle?: ReactNode | (() => Promise<ReactNode>);
            variant?: "arrow" | "default";
        }>;

function Tooltip({ children, disabled, maxWidth = true, sx, titleOrGetTitle, variant = "default", ...props }: TooltipProps, ref: Ref<HTMLBaseElement>) {
    const childElementRef = useRef<HTMLBaseElement>(null);
    const [titleElementRef, setTitleElementRef] = useState<HTMLBaseElement | null>(null);
    const titleElementRefSetter =
        useCallback(
            (ref: HTMLBaseElement) => setTitleElementRef(ref),
            []);
    const childElement =
        useMemo(
            () =>
                cloneElement(
                    children,
                    {
                        ...children.props,
                        ref: childElementRef
                    }),
            [children]);

    const [getTitleExecuting, setGetTitleExecuting] = useState(false);
    const [getTitleError, setGetTitleError] = useState(false);
    const [title, setTitle] = useState<ReactNode>();
    const [open, setOpen] = useState(false);
    useAsyncEffect(
        async () => {
            if (!_.isFunction(titleOrGetTitle)) {
                return;
            }

            if (open) {
                setGetTitleExecuting(true);
                setGetTitleError(false);

                try {
                    const title = await titleOrGetTitle();

                    setTitle(title);
                    setGetTitleExecuting(false);
                } catch {
                    setGetTitleError(true);
                }
            }
        },
        [open]);

    useEffect(
        () => {
            if (!open) {
                return;
            }

            function addScrollEventListener() {
                window.addEventListener(
                    "scroll",
                    closeOnScrollCallback,
                    {
                        capture: true,
                        once: true
                    });
            }

            function closeOnScrollCallback(event: Event) {
                const tooltipElement = titleElementRef?.closest(".MuiTooltip-popper");
                const isTooltipOrInTooltip =
                    event.target instanceof Element && (
                        !tooltipElement?.contains(event.target) ||
                        tooltipElement === event.target);
                if (tooltipElement &&
                    (event.target === window ||
                        isTooltipOrInTooltip)) {
                    setOpen(false);
                }
            }

            addScrollEventListener();

            return () => {
                window.removeEventListener(
                    "scroll",
                    closeOnScrollCallback);
            };
        },
        [open, titleElementRef]);

    useEffect(
        () => {
            const childElement = childElementRef.current;
            if (_.isNil(childElement)) {
                return undefined;
            }
            setRef(ref, childElement);
            childElement.classList.add("tooltip");
            return () => {
                childElement.classList.remove("tooltip");
            };
        },
        [children]);

    const theme = useTheme();
    return _.isNil(titleOrGetTitle) || disabled
        ? children
        : <MuiTooltip
            arrow={variant === "arrow"}
            open={open}
            title={
                <Box ref={titleElementRefSetter}>
                    {_.isFunction(titleOrGetTitle)
                        ? <Loading
                            container="popup"
                            loading={getTitleExecuting}>
                            {getTitleError
                                ? <TitleError/>
                                : title}
                        </Loading>
                        : titleOrGetTitle}
                </Box>}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            {...props}
            slotProps={{
                popper: {
                    ...props.slotProps?.popper,
                    sx:
                        {
                            ...(variant === "arrow"
                                ? {
                                    "& .MuiTooltip-arrow": {
                                        "&::before": {
                                            border: theme.border.primary
                                        },
                                        color: "white",
                                        height: "19px",
                                        marginTop: theme.important("-19px"),
                                        width: "48px"
                                    },
                                    "& .MuiTooltip-tooltip": {
                                        marginTop: theme.important("5px")
                                    }
                                }
                                : undefined),
                            ...props.slotProps?.popper?.sx
                        }
                },
                tooltip: {
                    sx: {
                        border:
                            variant === "arrow"
                                ? theme.border.primary
                                : undefined,
                        maxWidth:
                            maxWidth
                                ? undefined
                                : "none",
                        ...sx
                    }
                }
            }}>
            {childElement}
        </MuiTooltip>;
}

function TitleError() {
    const localization =
        useLocalization(
            "infrastructure.tooltip.titleError",
            () => ({
                text: "Failed to get information"
            }));
    return (
        <Message
            level="error"
            title={localization.text()}/>);
}