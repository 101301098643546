import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureComputeVirtualMachineScaleSetTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureComputeVirtualMachineScaleSetTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureComputeVirtualMachineScaleSetType]: {
                    [Contract.AzureComputeVirtualMachineScaleSetType.VirtualMachine]: "Flexible",
                    [Contract.AzureComputeVirtualMachineScaleSetType.VirtualMachineScaleSetVirtualMachine]: "Uniform"
                }
            }));
    return (type: Contract.AzureComputeVirtualMachineScaleSetType) =>
        localization[Contract.TypeNames.AzureComputeVirtualMachineScaleSetType][type]();
}