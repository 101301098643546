﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsRdsClusterEngineTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsRdsClusterEngineTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsRdsClusterEngineType]: {
                    [Contract.AwsRdsClusterEngineType.Aurora]: "Aurora",
                    [Contract.AwsRdsClusterEngineType.AuroraMySql]: "Aurora MySQL",
                    [Contract.AwsRdsClusterEngineType.AuroraPostgreSql]: "Aurora PostgreSQL",
                    [Contract.AwsRdsClusterEngineType.MySql]: "MySQL",
                    [Contract.AwsRdsClusterEngineType.Postgres]: "PostgreSQL"
                }
            }));
    return (engineType: Contract.AwsRdsClusterEngineType) =>
        localization[Contract.TypeNames.AwsRdsClusterEngineType][engineType]();
}