import { CopyToClipboardTextField, InlineCopyToClipboardText, Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../common";
import { AwsCloudFormationHelper, AwsCloudFormationTrail } from "../../../../../../../../../../../../../tenants";
import { useAddOrEditContext } from "../../../AddOrEdit";

export type CloudFormationConsoleProps = {
    roleArnStep: Step;
    tenantOnboardingPoliciesInfo: Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse;
    trail?: AwsCloudFormationTrail;
};

export function CloudFormationConsole({ roleArnStep, tenantOnboardingPoliciesInfo, trail }: CloudFormationConsoleProps) {
    const { tenantModel, tenantOnboardingInfo, tenantPartitionType, tenantPermissionTypes } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.roleItem.cloudFormationConsole",
            () => ({
                steps: {
                    add: {
                        step1: "Log in to the AWS account that you want to onboard.",
                        step2: {
                            link: "Click here",
                            steps: {
                                step1: "(Optional) Choose a different name for the IAM role by modifying the **{{roleNameParameterName}}** parameter",
                                step2: "Select *italic* I acknowledge that AWS CloudFormation might create IAM resources with custom names** and then click **Create Stack**",
                                step3: "Wait until the Stack creation is complete. You can click on the **Refresh** button to track progress"
                            },
                            title: "**{{clickHereLink}}** to create a Stack, and then perform the following"
                        },
                        step3: "Navigate to the **Outputs** tab and copy the Value that corresponds with the **{{roleArnParameterName}}** key"
                    },
                    edit: {
                        step1: {
                            link: "AWS CloudFormation",
                            title: "Login to {{link}} console and locate the stack **{{stackName}}**"
                        },
                        step2: "Open the stack and click **Update**",
                        step3: {
                            templateUrl: "Template URL",
                            title: "Choose **Replace current template**, select **Amazon S3 URL** as template source and enter the following and click **Next**"
                        },
                        step4: {
                            parameter: "**{{parameterName}}** is set to **{{parameterValue}}**",
                            title: "**Verify** the following values are configured"
                        },
                        step5: "Click **Next** to go to Step 3 and then click **Next** again to go to Step 4",
                        step6: "Select *italic*I acknowledge that AWS CloudFormation might create IAM resources with custom names** and then click **Submit**"
                    }
                }
            }));

    const { policyParameterNameToValueMap, quickCreateUrl, stacksPageUrl } =
        AwsCloudFormationHelper.getTenantSteps(
            tenantPartitionType,
            tenantOnboardingInfo,
            tenantOnboardingPoliciesInfo,
            tenantPermissionTypes,
            trail);

    const theme = useTheme();
    return (
        <Steps variant="letters">
            {_.isNil(tenantModel)
                ? [
                    localization.steps.add.step1(),
                    new Step(
                        localization.steps.add.step2.title({
                            clickHereLink:
                                <Link
                                    urlOrGetUrl={quickCreateUrl}
                                    variant="external">
                                    {localization.steps.add.step2.link()}
                                </Link>
                        }),
                        {
                            contentElement:
                                <Steps sx={{ paddingTop: theme.spacing(1) }}>
                                    {localization.steps.add.step2.steps.step1({ roleNameParameterName: AwsCloudFormationHelper.roleNameParameterName })}
                                    {localization.steps.add.step2.steps.step2()}
                                    {localization.steps.add.step2.steps.step3()}
                                </Steps>
                        }),
                    localization.steps.add.step3({ roleArnParameterName: AwsCloudFormationHelper.roleArnParameterName }),
                    roleArnStep
                ]
                : [
                    localization.steps.edit.step1.title({
                        link:
                            <Link
                                urlOrGetUrl={stacksPageUrl}
                                variant="external">
                                {localization.steps.edit.step1.link()}
                            </Link>,
                        stackName: AwsCloudFormationHelper.stackName
                    }),
                    localization.steps.edit.step2(),
                    new Step(
                        localization.steps.edit.step3.title(),
                        {
                            contentElement:
                                <CopyToClipboardTextField
                                    title={localization.steps.edit.step3.templateUrl()}
                                    value={tenantOnboardingInfo.cloudFormationTemplateUrl}/>
                        }),
                    new Step(
                        localization.steps.edit.step4.title(),
                        {
                            contentElement:
                                <Steps variant="bullets">
                                    {_<string>([]).
                                        concat(
                                            _.map(
                                                policyParameterNameToValueMap,
                                                (parameterValue, parameterName) =>
                                                    localization.steps.edit.step4.parameter({
                                                        parameterName,
                                                        parameterValue
                                                    }))).
                                        concatIf(
                                            !_.isNil(trail),
                                            () => localization.steps.edit.step4.parameter({
                                                parameterName: AwsCloudFormationHelper.trailBucketNameParameterName,
                                                parameterValue: <InlineCopyToClipboardText text={trail!.bucketName}/>
                                            })).
                                        concatIf(
                                            !_.isEmpty(trail?.encryptionKeyArns),
                                            () => localization.steps.edit.step4.parameter({
                                                parameterName: AwsCloudFormationHelper.trailEncryptionKeyArnParameterName,
                                                parameterValue: <InlineCopyToClipboardText
                                                    text={trail!.encryptionKeyArns.join(",")}/>
                                            })).
                                        value()}
                                </Steps>
                        }),
                    localization.steps.edit.step6(),
                    roleArnStep
                ]}
        </Steps>);
}