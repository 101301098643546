import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { forwardRef, memo, Ref } from "react";

const JiraIconForwardRef = memo(forwardRef(JiraIcon));
export { JiraIconForwardRef as JiraIcon };

function JiraIcon(props: SvgIconProps, ref: Ref<SVGSVGElement>) {
    return (
        <SvgIcon
            {...props}
            ref={ref}>
            <path
                d="M7.81839 11.4505C7.76011 11.3743 7.68315 11.3144 7.59493 11.2765C7.50672 11.2387 7.41025 11.2243 7.31483 11.2347C7.21941 11.245 7.12829 11.2798 7.05025 11.3357C6.97222 11.3916 6.90993 11.4667 6.86939 11.5537L2.06067 21.1681C2.01686 21.2557 1.99618 21.3531 2.00058 21.4509C2.00499 21.5488 2.03433 21.6439 2.08583 21.7273C2.13733 21.8106 2.20928 21.8794 2.29484 21.9271C2.3804 21.9748 2.47673 21.9998 2.5747 21.9999H9.27059C9.37783 22.0023 9.48349 21.9736 9.57474 21.9172C9.66599 21.8608 9.7389 21.7792 9.78462 21.6821C11.229 18.6974 10.3537 14.1593 7.81839 11.4505Z"
                fill="url(#paint0_linear_57262_4887)"/>
            <path
                d="M10.9719 2.30843C9.77373 4.15207 9.07547 6.27557 8.94566 8.47052C8.81586 10.6655 9.25899 12.8565 10.2315 14.8285L13.4597 21.2848C13.5074 21.3803 13.5808 21.4606 13.6716 21.5167C13.7624 21.5728 13.867 21.6025 13.9737 21.6025H20.6684C20.7664 21.6025 20.8627 21.5775 20.9483 21.5298C21.0338 21.4821 21.1058 21.4133 21.1573 21.3299C21.2088 21.2466 21.2381 21.1515 21.2425 21.0536C21.2469 20.9558 21.2263 20.8584 21.1824 20.7708C21.1824 20.7708 12.1758 2.75543 11.9495 2.30483C11.9047 2.2131 11.835 2.13586 11.7484 2.08194C11.6617 2.02803 11.5616 1.99963 11.4596 2C11.3575 2.00038 11.2576 2.02952 11.1714 2.08407C11.0851 2.13862 11.016 2.21638 10.9719 2.30843Z"
                fill="#2684FF"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_57262_4887"
                    x1="21.1824"
                    x2="12.7743"
                    y1="5.16275"
                    y2="22.956">
                    <stop stopColor="#0052CC"/>
                    <stop
                        offset="0.923"
                        stopColor="#2684FF"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}