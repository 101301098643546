import { InlineItems, Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { ResourceTagSecretExistsRiskTagsSection } from "../../../../components";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useResourceSecretExistsRiskContext } from "../../../useResourceSecretExistsRiskContext";
import { useGetGcpEntityRiskContext } from "../contexts";

export function useGcpResourceTagSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpResourceTagSecretExistsRisk;
    const resourceTagSecretExistsRiskModel = riskModel as Contract.GcpResourceTagSecretExistsRiskModel;
    const resourceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpScopeResourceModel;
    const principalModels = entityModelStore.useGet(risk.readTagPermissionActionPrincipalIds);

    const getGcpEntityRiskContext = useGetGcpEntityRiskContext();
    const secretRiskContext = useResourceSecretExistsRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpResourceTagSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    directorySpecialGroupExists: "All users / all authenticated users can read your secrets, which can lead to sensitive data leakage",
                    noPrincipals: "There are no external principals that can read your secrets",
                    principals: "{{principals}} can read your secrets, which can lead to sensitive data leakage",
                    tags: {
                        tags: [
                            "GCP label",
                            "{{count | NumberFormatter.humanize}} GCP labels"
                        ],
                        text: "The {{translatedResourceTypeName}} has {{tags}} exposing secrets in clear text"
                    }
                },
                description: "{{resource}} is exposing secrets via tags in clear text",
                sections: {
                    resolution: {
                        step1: {
                            link: "Secret Manager Console",
                            text: "Sign in to the Google Cloud Platform and open the {{secretManagerConsoleLink}}"
                        },
                        step2: {
                            link: "secrets",
                            text: "Migrate your {{secretManagerDocumentationLink}}"
                        },
                        step3: "Update your applications to use the new secrets instead of the GCP labels",
                        step4: {
                            tags: [
                                "GCP label",
                                "{{count | NumberFormatter.humanize}} GCP labels"
                            ],
                            text: "Delete the {{tags}} containing the secrets"
                        }
                    },
                    tags: "GCP Labels"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            resource:
                <Entity
                    entityIdOrModel={resourceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                secretManagerConsoleLink:
                    <Link
                        urlOrGetUrl={resourceTagSecretExistsRiskModel.secretManagerConsoleUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2.text({
                secretManagerDocumentationLink:
                    <Link
                        urlOrGetUrl={resourceTagSecretExistsRiskModel.secretManagerDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4.text({
                tags:
                    <InlineItems
                        items={risk.secretTagKeys}
                        namePluralizer={localization.sections.resolution.step4.tags}
                        variant="itemCountAndType"/>
            })
        ],
        riskModel,
        () => {
            const entityRiskContext = getGcpEntityRiskContext(resourceModel);
            return [
                entityRiskContext.generalInformation,
                entityRiskContext.sensitive,
                new RiskDefinitionContextItem(
                    localization.contextItems.tags.text({
                        tags:
                            <InlineItems
                                items={risk.secretTagKeys}
                                namePluralizer={localization.contextItems.tags.tags}
                                variant="itemCountAndType"/>,
                        translatedResourceTypeName:
                            entityTypeNameTranslator(
                                resourceModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                secretRiskContext.getExcludedSecretsContextItem(
                    risk.excludedSecretTagKeys,
                    risk.typeName,
                    risk.tenantId),
                _.some(
                    principalModels,
                    principalModel => principalModel.entity.typeName === Contract.TypeNames.GciDirectorySpecialGroup)
                    ? new RiskDefinitionContextItem(localization.contextItems.directorySpecialGroupExists())
                    : undefined,
                !_.isEmpty(principalModels)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.principals({
                            principals:
                                <InlineEntities
                                    entityIdsOrModels={principalModels}
                                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                                    variant="itemCountAndType"/>
                        })
                    )
                    : new RiskDefinitionContextItem(localization.contextItems.noPrincipals()),
                entityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ResourceTagSecretExistsRiskTagsSection
                        risk={risk}
                        secretTagKeys={risk.secretTagKeys}
                        tags={
                            resourceModel.unknown
                                ? []
                                : (resourceModel.entity as Contract.GcpScopeResource).tags}/>,
                    localization.sections.tags())
            ]
        });
}