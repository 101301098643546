﻿import { DataTableColumn, DataTableColumnRenderProps, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition, useGcpCommonServiceAccountOriginatorScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpLoggingLogSinkDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const commonServiceAccountOriginatorScopeResourceDefinition = useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpLoggingLogSinkDefinition",
            () => ({
                columns: {
                    destination: "Destination",
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    }
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.scopeColumn,
            commonScopeResourceDefinition.columns.gcpTenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonServiceAccountOriginatorScopeResourceDefinition.columns.serviceAccountColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpLoggingLogSinkModel>(
                        Contract.TypeNames.GcpScopeResource,
                        item => [item.destinationIdReference],
                        localization.columns.destination())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpLoggingLogSinkDestination)}
                                placeholder={localization.columns.destination()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpLoggingLogSinkDestination}
                key={Contract.EntityModelProperty.GcpLoggingLogSinkDestination}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpLoggingLogSinkModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.destinationIdReference}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            entityVariant="iconText"/>}
                title={localization.columns.destination()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpLoggingLogSinkModel) => ({
                            [localization.columns.enabled.title()]:
                                (item.entity as Contract.GcpLoggingLogSink).enabled
                                    ? localization.columns.enabled.true()
                                    : localization.columns.enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.enabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpLoggingLogSinkEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.GcpLoggingLogSink).enabled
                            ? localization.columns.enabled.true()
                            : localization.columns.enabled.false()}
                key={Contract.EntityModelProperty.GcpLoggingLogSinkEnabled}
                title={localization.columns.enabled.title()}/>,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpLoggingLogSinkRequest(
                new Contract.EntityControllerGetEntityModelPageGcpLoggingLogSinkRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpLoggingLogSinkDestination]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpLoggingLogSinkEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount]))));
}