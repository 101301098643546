import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ZoomInIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M19.8004 9.86847C20.3244 9.86847 20.7491 9.44373 20.7491 8.91979C20.7491 8.39584 20.3244 7.9711 19.8004 7.9711H17.2065L20.2736 5.03271C20.6694 4.65355 20.6699 4.02117 20.2748 3.64132C19.902 3.28294 19.3129 3.28243 18.9395 3.64016L15.8151 6.63346V4.2145C15.8151 3.6695 15.3732 3.22768 14.8282 3.22768C14.2832 3.22768 13.8414 3.6695 13.8414 4.2145V9.26476C13.8414 9.59818 14.1117 9.86847 14.4451 9.86847H19.8004Z"/>
            <path d="M4.19674 9.86853C3.67279 9.86853 3.24805 9.44379 3.24805 8.91984C3.24805 8.39589 3.67279 7.97115 4.19674 7.97115L6.79078 7.97115L3.72358 5.03273C3.32779 4.65357 3.32724 4.02117 3.72237 3.64132C4.09516 3.28294 4.68427 3.28243 5.05768 3.64016L8.18221 6.63349L8.18221 4.21452C8.18221 3.66951 8.62403 3.22769 9.16904 3.22769C9.71405 3.22769 10.1559 3.66951 10.1559 4.21452L10.1559 9.26482C10.1559 9.59824 9.88558 9.86853 9.55216 9.86853L4.19674 9.86853Z"/>
            <path d="M4.19685 13.2079C3.67291 13.2079 3.24817 13.6327 3.24817 14.1566C3.24817 14.6806 3.67291 15.1053 4.19685 15.1053H6.7909L3.72371 18.0437C3.32792 18.4229 3.32737 19.0553 3.7225 19.4351C4.09528 19.7935 4.68439 19.794 5.0578 19.4363L8.18233 16.443V18.8619C8.18233 19.4069 8.62415 19.8487 9.16916 19.8487C9.71418 19.8487 10.156 19.4069 10.156 18.8619V13.8117C10.156 13.4782 9.88571 13.2079 9.55229 13.2079H4.19685Z"/>
            <path d="M19.8003 13.2079C20.3243 13.2079 20.749 13.6326 20.749 14.1566C20.749 14.6805 20.3243 15.1053 19.8003 15.1053H17.2063L20.2735 18.0437C20.6693 18.4229 20.6698 19.0553 20.2747 19.4351C19.9019 19.7935 19.3128 19.794 18.9394 19.4363L15.8148 16.4429V18.8619C15.8148 19.4069 15.373 19.8488 14.828 19.8488C14.283 19.8488 13.8412 19.4069 13.8412 18.8619L13.8412 13.8116C13.8412 13.4782 14.1115 13.2079 14.4449 13.2079H19.8003Z"/>
        </SvgIcon>);
}