import { SvgIcon } from "@mui/material";
import React, { ReactNode } from "react";

export type IconOutlineProps = {
    children?: ReactNode;
};

export function IconOutline({ children: icon }: IconOutlineProps) {
    return (
        <SvgIcon>
            <circle
                cx={12}
                cy={12}
                fill="none"
                r={11}/>
            <g transform={`translate(${12 * (1 - 0.64)}, ${12 * (1 - 0.64)}) scale(0.64)`}>
                {icon}
            </g>
        </SvgIcon>);
}