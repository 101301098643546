import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetOciIamCredentialRiskContext } from "./useGetOciIamCredentialRiskContext";

export function useGetOciIamUserSecretKeyRiskContext() {
    return useMemo(
        () => useOciIamUserSecretKeyRiskContext,
        []);
}

function useOciIamUserSecretKeyRiskContext(UserSecretKeyModel: Contract.OciIamUserSecretKeyModel) {
    return useGetOciIamCredentialRiskContext()(UserSecretKeyModel);
}