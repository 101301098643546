﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";
import { useGetGcpServiceAccountOriginatorScopeResourceRiskContext } from "./useGetGcpServiceAccountOriginatorScopeResourceRiskContext";

export function useGetGcpComputeInstanceTemplateRiskContext() {
    return useMemo(
        () => useGcpComputeInstanceTemplateRiskContext,
        []);
}

function useGcpComputeInstanceTemplateRiskContext(instanceTemplateModel: Contract.GcpComputeInstanceTemplateModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(instanceTemplateModel);
    const serviceAccountOriginatorScopeResourceRiskContext = useGetGcpServiceAccountOriginatorScopeResourceRiskContext()(instanceTemplateModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpComputeInstanceTemplateRiskContext",
            () => ({
                instanceGroups: "The {{translatedInstanceTemplateTypeName}} is being used by {{instanceGroups}}",
                serviceAccount: "The {{translatedInstanceTemplateTypeName}} is using {{serviceAccount}}",
                vpcs: "The {{translatedInstanceTemplateTypeName}} is part of {{vpcs}}"
            }));

    const translatedInstanceTemplateTypeName =
        entityTypeNameTranslator(
            instanceTemplateModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        ...serviceAccountOriginatorScopeResourceRiskContext,
        instanceGroups:
            _.isEmpty(instanceTemplateModel.instanceGroupIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instanceGroups({
                        instanceGroups:
                            <InlineEntities
                                entityIdsOrModels={instanceTemplateModel.instanceGroupIds}
                                entityTypeName={Contract.TypeNames.GcpComputeInstanceGroup}
                                variant="itemCountAndType"/>,
                        translatedInstanceTemplateTypeName
                    })),
        serviceAccount:
            _.isNil(instanceTemplateModel.serviceAccountIdReference)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.serviceAccount({
                        serviceAccount:
                            <Entity
                                entityIdOrModel={instanceTemplateModel.serviceAccountIdReference}
                                variant="text"/>,
                        translatedInstanceTemplateTypeName
                    })),
        vpcs:
            new RiskDefinitionContextItem(
                localization.vpcs({
                    translatedInstanceTemplateTypeName,
                    vpcs:
                        <InlineEntities
                            entityIdsOrModels={instanceTemplateModel.vpcIdReferences}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            variant="itemAndTypeOrItemCountAndType"/>
                }))
    };
}