import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineWorkloadAnalysisPackages } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionContextItemImpact, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetContainerImageOperatingSystemUnpatchedRiskContext, useGetContainerImageWorkloadAnalysisRiskContext, useGetVirtualMachinesWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetWorkloadAnalysisRiskResolutionPatches } from "./useWorkloadAnalysisRiskResolutionPatches";
import { useWorkloadResourceOperatingSystemRiskRemediationStep } from "./useWorkloadResourceOperatingSystemRiskRemediationStep";

export function useContainerImageOperatingSystemUnpatchedRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const containerImageOperatingSystemUnpatchedRisk = riskModel.risk as Contract.ContainerImageOperatingSystemUnpatchedRisk;
    const containerImageModels = _.as<Contract.IContainerImageModel[]>(entityModelStore.useGet(containerImageOperatingSystemUnpatchedRisk.containerImageIds));
    const getWorkloadAnalysisRiskResolutionPatches = useGetWorkloadAnalysisRiskResolutionPatches();
    const workloadResourceOperatingSystemRiskRemediationStep = useWorkloadResourceOperatingSystemRiskRemediationStep();

    const operatingSystemReleaseNotesUrl =
        _(containerImageModels).
            filter(containerImageModel => !_.isNil(containerImageModel.operatingSystemReleaseNotesUrl)).
            first()?.
            operatingSystemReleaseNotesUrl;
    const operatingSystemType = containerImageOperatingSystemUnpatchedRisk.operatingSystemType;
    const operatingSystemDisplayName = containerImageOperatingSystemUnpatchedRisk.operatingSystemDisplayName;

    const getContainerImageOperatingSystemUnpatchedRiskContext = useGetContainerImageOperatingSystemUnpatchedRiskContext();
    const getContainerImageWorkloadAnalysisRiskContext = useGetContainerImageWorkloadAnalysisRiskContext();
    const getVirtualMachinesWorkloadAnalysisRiskContext = useGetVirtualMachinesWorkloadAnalysisRiskContext(containerImageOperatingSystemUnpatchedRisk);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useContainerImageOperatingSystemUnpatchedRiskDefinition",
            () => ({
                description: "The container image on {{riskedEntity}}, stored in {{containerImageRepository}}, is running operating system {{operatingSystem}} and contains {{packages}} with critical or high vulnerabilities with known fixes",
                sections: {
                    vulnerabilities: "Vulnerabilities"
                }
            }));


    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            containerImageRepository:
                <Entity
                    entityIdOrModel={containerImageOperatingSystemUnpatchedRisk.containerImageRepositoryReference}
                    variant="text"/>,
            operatingSystem: operatingSystemDisplayName,
            packages:
                <InlineWorkloadAnalysisPackages
                    packageNames={containerImageOperatingSystemUnpatchedRisk.vulnerabilityPackageNames}
                    packageScope={containerImageOperatingSystemUnpatchedRisk.packageScope}/>,
            riskedEntity:
                <Entity
                    entityIdOrModel={containerImageOperatingSystemUnpatchedRisk.entityId}
                    variant="text"/>
        }),
        () =>
            [workloadResourceOperatingSystemRiskRemediationStep(
                operatingSystemReleaseNotesUrl,
                operatingSystemType,
                containerImageOperatingSystemUnpatchedRisk.windowsUpdateDocumentationUrl,
                getWorkloadAnalysisRiskResolutionPatches(containerImageOperatingSystemUnpatchedRisk))],
        riskModel,
        () => {
            const containerImageOperatingSystemUnpatchedRiskContext = getContainerImageOperatingSystemUnpatchedRiskContext(containerImageOperatingSystemUnpatchedRisk);
            const containerImageWorkloadAnalysisRiskContext = getContainerImageWorkloadAnalysisRiskContext(containerImageOperatingSystemUnpatchedRisk);
            const virtualMachinesWorkloadAnalysisRiskContext = getVirtualMachinesWorkloadAnalysisRiskContext();

            return [
                containerImageWorkloadAnalysisRiskContext.generalInformation,
                containerImageWorkloadAnalysisRiskContext.virtualMachines,
                containerImageWorkloadAnalysisRiskContext.operatingSystem,
                containerImageOperatingSystemUnpatchedRiskContext.unpatchedPackages,
                containerImageOperatingSystemUnpatchedRiskContext.criticalExploitableVulnerabilityPackageNames,
                containerImageOperatingSystemUnpatchedRiskContext.criticalNonExploitableVulnerabilityPackageNames,
                containerImageOperatingSystemUnpatchedRiskContext.highExploitableVulnerabilityPackageNames,
                containerImageOperatingSystemUnpatchedRiskContext.highNonExploitableVulnerabilityPackageNames,
                containerImageWorkloadAnalysisRiskContext.getVulnerabilities(
                    containerImageOperatingSystemUnpatchedRisk.packageScope === Contract.WorkloadResourceOperatingSystemRiskPackageScope.OperatingSystem,
                    true),
                containerImageWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.Critical),
                containerImageWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.High),
                virtualMachinesWorkloadAnalysisRiskContext.sensitive?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.inboundExternalAccessScope?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.identityPermissionActionSeverity?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.stopped?.withImpact(RiskDefinitionContextItemImpact.SeverityDecreased),
                containerImageWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(containerImageOperatingSystemUnpatchedRisk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <Vulnerabilities
                        entityIds={containerImageOperatingSystemUnpatchedRisk.containerImageIds}
                        severityFilterValues={[
                            Contract.Severity.Critical,
                            Contract.Severity.High]}
                        variant="risk"/>,
                    localization.sections.vulnerabilities(),
                    {
                        expandable: true
                    })]
        });
}