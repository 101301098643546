import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciComputeInstancePoolStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciComputeInstancePoolStatusTranslator",
            () => ({
                [Contract.TypeNames.OciComputeInstancePoolStatus]: {
                    [Contract.OciComputeInstancePoolStatus.Provisioning]: "Provisioning",
                    [Contract.OciComputeInstancePoolStatus.Running]: "Running",
                    [Contract.OciComputeInstancePoolStatus.Scaling]: "Scaling",
                    [Contract.OciComputeInstancePoolStatus.Starting]: "Starting",
                    [Contract.OciComputeInstancePoolStatus.Stopped]: "Stopped",
                    [Contract.OciComputeInstancePoolStatus.Stopping]: "Stopping",
                    [Contract.OciComputeInstancePoolStatus.Terminated]: "Terminated",
                    [Contract.OciComputeInstancePoolStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciComputeInstancePoolStatus) =>
        localization[Contract.TypeNames.OciComputeInstancePoolStatus][status]();
}