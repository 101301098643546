import { Action0, Action1 } from "@infrastructure";
import _ from "lodash";
import { MutableRefObject, useEffect, useRef } from "react";

export function useElementEventEffect<TEventType extends keyof HTMLElementEventMap>(
    elementRef: MutableRefObject<HTMLElement | null>,
    eventType: TEventType,
    effect: Action0 | Action1<HTMLElementEventMap[TEventType]>,
    options?: ElementEventEffectOptions) {
    const effectRef = useRef(effect);
    effectRef.current = effect;

    useEffect(
        () => {
            const element = elementRef.current;
            if (_.isNil(element)) {
                return undefined;
            }

            let eventHandler =
                (event: HTMLElementEventMap[TEventType]) => {
                    effectRef.current(event);
                };

            if (!_.isNil(options?.debounce)) {
                eventHandler = _.debounce(eventHandler, options!.debounce);
            }

            element.addEventListener(eventType, eventHandler);
            return () => element.removeEventListener(eventType, eventHandler);
        },
        []);
}

type ElementEventEffectOptions = {
    debounce?: number;
};