import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";
import { useAzurePipelines, useGeneralCodePipeline, useGitHubWorkflow, useGitLabPipeline, useJenkinsPipeline, useTerraformCloudRunTask } from "./hooks";

export function useDefinition(pipelineType: Contract.CodePipelineType): Definition {
    const useDefinition =
        useMemo(
            () => {
                switch (pipelineType) {
                    case Contract.CodePipelineType.AzurePipeline:
                        return useAzurePipelines;
                    case Contract.CodePipelineType.General:
                        return useGeneralCodePipeline;
                    case Contract.CodePipelineType.GitHubWorkflow:
                        return useGitHubWorkflow;
                    case Contract.CodePipelineType.GitLabPipeline:
                        return useGitLabPipeline;
                    case Contract.CodePipelineType.JenkinsPipeline:
                        return useJenkinsPipeline;
                    case Contract.CodePipelineType.TerraformCloudRunTask:
                        return useTerraformCloudRunTask;
                    default:
                        throw new UnexpectedError("pipelineType", pipelineType);
                }
            },
            []);

    return useDefinition();
}

export type Definition = {
    apiKeyItem: boolean;
    orderedWizardItems: ReactNode[];
};