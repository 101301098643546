import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsCloudTrailTrailRiskContext, useGetAwsS3BucketRiskContext } from "../contexts";

export function useAwsCloudTrailTrailEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsCloudTrailTrailEncryptionDisabledRisk;
    const trailModel = entityModelStore.useGet(risk.entityId) as Contract.AwsCloudTrailTrailModel;
    const bucketModel = entityModelStore.useGet(trailModel.bucketId) as Contract.AwsS3BucketModel;

    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();
    const getAwsCloudTrailTrailRiskContext = useGetAwsCloudTrailTrailRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudTrailTrailEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{trail}} is not configured with encryption using CMK",
                sections: {
                    resolution: {
                        step1: "In the General details section, click **Edit**",
                        step2: "Set the **Log file SSE-KMS encryption** option to **Enabled**",
                        step3: "Create a New KMS key or choose an Existing key",
                        step4: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            trail:
                <Entity
                    entityIdOrModel={trailModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudTrail,
                AwsConsoleUrlBuilder.getCloudTrailTrailUrl(trailModel.entity as Contract.AwsCloudTrailTrail)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const trailRiskContext = getAwsCloudTrailTrailRiskContext(trailModel);
            return _<Optional<RiskDefinitionContextItem>>([]).
                concat(
                    trailRiskContext.generalInformation,
                    trailRiskContext.sensitive,
                    trailRiskContext.enabled,
                    trailRiskContext.organizationTrail,
                    trailRiskContext.bucketName).
                concatIf(
                    !_.isNil(bucketModel),
                    () => {
                        const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
                        return [
                            bucketRiskContext.storageSize,
                            bucketRiskContext.public,
                            bucketRiskContext.sensitive
                        ];
                    }).
                concat(trailRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)).
                value();
        });
}