import { CollapsedIcon } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ComplianceHelper, Contract, useTheme } from "../../../../../../../../../../../../common";
import { useItemContext, useSetItemContext } from "../../../../../../Profile";
import { Sections } from "../../Sections";
import { SectionItemDetails } from "./components";

type SectionItemProps = {
    sectionData: Contract.ComplianceSectionData;
};

export function SectionItem({ sectionData }: SectionItemProps) {
    const { expandedSectionIds } = useItemContext();
    const setItemContext = useSetItemContext();

    const theme = useTheme();
    return (
        <Accordion
            expanded={
                _.includes(
                    expandedSectionIds,
                    sectionData.identifier)}
            TransitionProps={{ unmountOnExit: true }}
            onChange={
                (_event, expanded) =>
                    setItemContext(
                        context => ({
                            ...context,
                            expandedSectionIds:
                                expanded
                                    ? _.concat(
                                        context.expandedSectionIds,
                                        sectionData.identifier)
                                    : _.without(
                                        context.expandedSectionIds,
                                        ...ComplianceHelper.getSectionIds(sectionData))
                        }))}>
            <AccordionSummary
                expandIcon={<CollapsedIcon/>}
                sx={{ padding: theme.spacing(0, 1) }}>
                <SectionItemDetails sectionData={sectionData}/>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    "&.MuiAccordionDetails-root": {
                        marginLeft: theme.spacing(0.5)
                    }
                }}>
                <Sections sectionData={sectionData}/>
            </AccordionDetails>
        </Accordion>);
}