export class GeneralInformation {
    public static readonly assemblyConfiguration = "Release"
    public static readonly assemblyFileVersion = "1.160.185.22299"
    public static readonly assemblyInformationalVersion = "1.160.185+571b7688d5"
    public static readonly assemblyName = "Ermetic.Sil.BuildApplication"
    public static readonly assemblyTitle = "Ermetic.Sil.BuildApplication"
    public static readonly assemblyVersion = "1.160.0.0"
    public static readonly gitCommitDate = "2025-04-01T10:17:30Z"
    public static readonly gitCommitId = "571b7688d588978f83542017ec44f1c579ee368d"
    public static readonly isPrerelease = false
    public static readonly isPublicRelease = false
    public static readonly rootNamespace = "Ermetic.Sil.BuildApplication"
}