﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, InlineResourceTags } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsTagResourceGroupRiskContext() {
    return useMemo(
        () => useAwsTagResourceGroupRiskContext,
        []);
}

function useAwsTagResourceGroupRiskContext(tagResourceGroupModel: Contract.AwsTagResourceGroupModel) {
    const tagResourceGroup = tagResourceGroupModel.entity as Contract.AwsTagResourceGroup;
    const getResourceRiskContext = useGetAwsResourceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsTagResourceGroupRiskContext",
            () => ({
                groupTags: [
                    "{{resources}} is grouped by {{tags}}",
                    "{{resources}} are grouped by {{tags}}"
                ]
            }));

    return {
        ...getResourceRiskContext(tagResourceGroupModel),
        getGroupTagsContextItem:
            (resourceIds: string[], resourceTypeName: string) =>
                !_.isEmpty(resourceIds)
                    ? new RiskDefinitionContextItem(
                        localization.groupTags(
                            resourceIds.length,
                            {
                                resources:
                                    <InlineEntities
                                        entityIdsOrModels={resourceIds}
                                        entityTypeName={resourceTypeName}
                                        variant="itemCountAndType"/>,
                                tags:
                                    <InlineResourceTags
                                        tags={tagResourceGroup.groupTags}
                                        textVariant="text"
                                        variant="itemCountAndType"/>
                            }))
                    : undefined
    };
}