import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useGetWorkloadResourceOperatingSystemContextItem() {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.contexts.useGetWorkloadResourceOperatingSystemContextItem",
            () => ({
                operatingSystem: "The {{translatedEntityTypeName}} is running operating system {{operatingSystem}}{{operatingSystemEndOfLifeDate}}",
                operatingSystemEndOfLifeDate: ", which is End-of-Life as of {{operatingSystemEndOfLifeDate | TimeFormatter.shortDate}}"
            }));
    return (operatingSystemDisplayName: Optional<string>,
        operatingSystemEndOfLifeDate: Optional<string>,
        translatedEntityTypeName: string) =>
        _.isNil(operatingSystemDisplayName)
            ? undefined
            : new RiskDefinitionContextItem(
                localization.operatingSystem({
                    operatingSystem: operatingSystemDisplayName,
                    operatingSystemEndOfLifeDate:
                        _.isNil(operatingSystemEndOfLifeDate)
                            ? ""
                            : localization.operatingSystemEndOfLifeDate({ operatingSystemEndOfLifeDate }),
                    translatedEntityTypeName
                }));
}