﻿import { Stack } from "@mui/material";
import React from "react";
import { Steps, useLocalization } from "@infrastructure";

export function ConfigureOrganizationItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.azureDevOps.addOrEditAzureDevOps.configureOrganizationItem",
            () => ({
                actions: {
                    info: {
                        error: {
                            general: "Failed to access organization {{organizationName}}"
                        }
                    }
                },
                steps: {
                    step1: "Log into Azure DevOps **for the organization you selected in the previous step**, and click **Organization settings** at the bottom left.",
                    step2: "Navigate to **General > Users** and click **Add Users**.",
                    step3: {
                        subSteps: {
                            step1: "*italic*Users or Service Principals**: Select **Tenable Cloud Security Azure DevOps**",
                            step2: "*italic*Access level**: Select **Basic**",
                            step3: "*italic*Add to projects**: Select any projects you want to use with Tenable Cloud Security",
                            step4: "*italic*Azure DevOps Groups**: Select **Project Contributors**"
                        },
                        title: "Enter the following user details:"
                    },
                    step4: "Click **Add**."
                }
            }));

    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            <Stack>
                {localization.steps.step3.title()}
                <Steps variant="plainNumbers">
                    {localization.steps.step3.subSteps.step1()}
                    {localization.steps.step3.subSteps.step2()}
                    {localization.steps.step3.subSteps.step3()}
                    {localization.steps.step3.subSteps.step4()}
                </Steps>
            </Stack>
            {localization.steps.step4()}
        </Steps>);
}