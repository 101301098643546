﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SwedenIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5277)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4.00012H0V19.9639H24V4.00012Z"
                    fill="#006AA7"/>
                <path
                    d="M10.5 4.00012H7.5V19.9639H10.5V4.00012Z"
                    fill="#FECC00"/>
                <path
                    d="M24 10.3857H0V13.5785H24V10.3857Z"
                    fill="#FECC00"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5277">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}