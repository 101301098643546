import { DataTable, DataTableActions, DataTableFetchItems, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import React, { ReactNode, Ref } from "react";
import { Contract } from "../../../../../../../../../../common";

type TableProps = {
    children: ReactNode;
    dataTableActionsRef?: Ref<Optional<DataTableActions>>;
    emptyMessageText?: EmptyMessageText;
    fetchItems: DataTableFetchItems<Contract.EntityModelSummary>;
    getItemId: (item: any) => string;
    sortEnabled?: boolean;
};

export function Table({ children, dataTableActionsRef, emptyMessageText, fetchItems, getItemId, sortEnabled = false }: TableProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.table",
            () => ({
                empty: {
                    withFilter: "No Matching Results",
                    withoutFilter: "No Results"
                }
            }));
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{
                emptyMessageText:
                    emptyMessageText ??
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
            }}
            fetchItems={fetchItems}
            filtersOptions={{
                persist: {
                    filterMap: false
                }
            }}
            getItemId={getItemId}
            sortOptions={{ enabled: sortEnabled }}
            variant="card">
            {children}
        </DataTable>);
}