import { useMemo } from "react";
import { Contract } from "../../../../../../../../common";
import { TenantType } from "../../../../../../../../common/controllers/types.generated";
import { ItemOptions, useGetItem } from "./hooks";

export function useItems(reportData: ItemOptions, tenantTypes: TenantType[]) {
    const reportItem = useGetItem(reportData, tenantTypes);

    return useMemo(
        () => ({
            [Contract.ReportType.Inventory]: () => reportItem()
        }),
        [reportData, reportItem, tenantTypes]);
}