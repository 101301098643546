import { Grid2, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../common";
import { ContainerImage, ContainerImageRepositoryEnabled } from "./components";

export function ContainerImageRepositories() {
    const theme = useTheme();
    return (
        <Grid2
            size={{ xs: 8 }}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(125),
                overflowY: "auto",
                padding: theme.spacing(3, 4)
            }}>
            <Stack spacing={2}>
                <ContainerImageRepositoryEnabled/>
                <ContainerImage/>
            </Stack>
        </Grid2>);
}