import { DataTableColumnRenderProps, NoneIcon, Optional } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, NetworkAccessScopeIcon, useNetworkAccessScopeTranslator } from "../../../../../../../../../../../common";

export function NetworkAccessScopeCell({ item }: DataTableColumnRenderProps<Contract.EntityModel>) {
    const inboundExternalAccessScope = (item.entityNetwork as Optional<Contract.AwsNetworkedResourceStateNetwork>)?.inboundExternalAccessScope;
    const networkAccessScopeTranslator = useNetworkAccessScopeTranslator();
    return item.unknown ||
    item.systemDeletionTime ||
    _.isNil(inboundExternalAccessScope) ||
    inboundExternalAccessScope === Contract.NetworkAccessScope.None
        ? <NoneIcon sx={{ fontSize: "24px" }}/>
        : <Stack
            alignItems="center"
            direction="row"
            spacing={2}>
            <NetworkAccessScopeIcon
                accessScope={inboundExternalAccessScope}
                sx={{ fontSize: "24px" }}/>
            <Typography
                noWrap={true}
                sx={{ flex: 1 }}>
                {networkAccessScopeTranslator(inboundExternalAccessScope)}
            </Typography>
        </Stack>;
}