import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { GcpScopeResourceAccessLevel } from "../../../../../../../../../../tenants";

export function useGcpCommonScopeResourceInfoItemElements(scopeResourceModel: Contract.GcpScopeResourceModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonScopeResourceInfoItemElements",
            () => ({
                accessLevel: "Access",
                rawId: "ID"
            }));

    return {
        accessLevelInfoItemElement:
            <InfoItem
                key="accessLevel"
                title={localization.accessLevel()}
                value={<GcpScopeResourceAccessLevel accessLevel={scopeResourceModel.accessLevel}/>}/>,
        rawIdInfoItemElement:
            <InfoItem
                key="rawId"
                title={localization.rawId()}
                value={(scopeResourceModel.entity as Contract.GcpScopeResource).rawId}/>
    };
}