import { ItemSelector, TimeFormatter, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";

type MonthlyProps = {
    hour: number;
    monthDay: number;
    onChange: (hour: number, monthDay: number) => void;
};

export function Monthly({ hour, monthDay, onChange }: MonthlyProps) {
    const localization =
        useLocalization(
            "common.report.monthly",
            () => ({
                at: "at",
                day: "day",
                on: "On",
                utc: "UTC"
            }));
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.on()}
            </Typography>
            <ItemSelector
                fieldVariant="underline"
                items={_.range(1, 29)}
                selectedItem={monthDay}
                sorted={false}
                onSelectedItemChanged={monthDay => onChange(hour, monthDay)}>
                {monthDay =>
                    <Typography
                        noWrap={true}
                        sx={{ width: "100%" }}>
                        {TimeFormatter.humanizeMonthDay(monthDay)}
                    </Typography>}
            </ItemSelector>
            <Typography>
                {localization.day()}
            </Typography>
            <Typography>
                {localization.at()}
            </Typography>
            <ItemSelector
                fieldVariant="underline"
                items={_.range(0, 24)}
                selectedItem={hour}
                sorted={false}
                onSelectedItemChanged={hour => onChange(hour, monthDay)}>
                {hour =>
                    <Typography
                        noWrap={true}
                        sx={{ width: "100%" }}>
                        {TimeFormatter.hour(hour)}
                    </Typography>}
            </ItemSelector>
            <Typography>
                {localization.utc()}
            </Typography>
        </Stack>);
}