import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../utilities";

export function useGetKubernetesClusterRiskContext() {
    return useMemo(
        () => useKubernetesClusterRiskContext,
        []);
}

function useKubernetesClusterRiskContext(clusterModel: Contract.IKubernetesClusterModel) {
    const cluster = _.as<Contract.IKubernetesCluster>(clusterModel.entity);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetKubernetesClusterRiskContext",
            () => ({
                platformVersion: "The {{translatedClusterTypeName}} OpenShift container platform version is {{platformVersion}}",
                version: "The {{translatedClusterTypeName}} version is {{version}}"
            }));

    return {
        platformVersion:
            cluster.platform === Contract.KubernetesClusterPlatform.Kubernetes ||
            _.isNil(cluster.platformVersion)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.platformVersion({
                        platformVersion: cluster.platformVersion,
                        translatedClusterTypeName:
                            entityTypeNameTranslator(
                                clusterModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
        version:
            _.isNil(cluster.version)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.version({
                        translatedClusterTypeName:
                            entityTypeNameTranslator(
                                clusterModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                }),
                        version: cluster.version
                    }))
    };
}