import { DataTableColumn, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOciCommonIamResourceDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciCommonIamResourceDefinition",
            () => ({
                columns: {
                    description: "Description",
                    enabled: {
                        false: "Not active",
                        title: "Status",
                        true: "Active"
                    }
                }
            }));

    function translateEnabled(ResourceModel: Contract.OciIamResourceModel) {
        return (ResourceModel.entity as Contract.OciIamResource).enabled
            ? localization.columns.enabled.true()
            : localization.columns.enabled.false();
    }

    return {
        columns: {
            ...commonResourceDefinition.columns,
            descriptionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciIamResourceModel) => ({
                                [localization.columns.description()]: (item.entity as Contract.OciIamResource).description ?? ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciIamResourceDescription)}
                                    placeholder={localization.columns.description()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciIamResourceDescription}
                    key={Contract.EntityModelProperty.OciIamResourceDescription}
                    render={optionalTableCell<Contract.OciIamResourceModel>(item => (item.entity as Contract.OciIamResource).description)}
                    title={localization.columns.description()}/>,
            enabledColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciIamResourceModel) => ({
                                [localization.columns.enabled.title()]: translateEnabled(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.enabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.enabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.enabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciIamResourceEnabled}
                    itemProperty={translateEnabled}
                    key={Contract.EntityModelProperty.OciIamResourceEnabled}
                    title={localization.columns.enabled.title()}/>
        }
    };
}