import { CopyToClipboardText } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import React from "react";
import { Contract, ResourceTagHelper } from "..";

type ResourceTagProps = {
    copyToClipboard?: boolean;
    resourceTag: Contract.ResourceTag;
    sx?: SxProps;
    variant?: "item" | "text";
};

export function ResourceTag({ copyToClipboard = false, resourceTag, sx, variant = "text" }: ResourceTagProps) {
    return (
        <Typography
            {...(variant === "text" && {
                component: "span"
            })}
            noWrap={true}
            sx={sx}>
            {copyToClipboard
                ? <CopyToClipboardText text={ResourceTagHelper.getDisplayName(resourceTag)}/>
                : ResourceTagHelper.getDisplayName(resourceTag)}
        </Typography>);
}