import { Optional, useChangeEffect, useLocalization, useSetWizardContext, useWizardContext } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, RadioGroup, Tenant, TenantController, useTheme } from "../../../../../../../..";
import { useRiskResolutionAutomationContext, useSetRiskResolutionAutomationContext } from "../../../../../../utilities";
import { AwsRiskResolutionAutomationContext } from "../../useAwsDefinition";
import { AccessKeyInput } from "./components";

export function AwsAccessKeyItem() {
    const {
        executing,
        setLoaded,
        setValid,
        useNextEffect
    } = useWizardContext();
    const {
        awsAccessKey: accessKey,
        changeModels,
        storedAwsAccessKeyUniqueId: storedUniqueId
    } = useRiskResolutionAutomationContext() as AwsRiskResolutionAutomationContext;
    const riskTenantId = changeModels[0].changeDatas[0].riskTenantId;

    const [mode, setMode] =
        useState(
            !_.isNil(storedUniqueId)
                ? AwsAccessKeyMode.Stored
                : AwsAccessKeyMode.New);
    const [secret, setSecret] = useState(accessKey?.secret ?? "");
    const [secretInputValid, setSecretInputValid] = useState<Optional<boolean>>(undefined);
    const [sessionToken, setSessionToken] = useState(accessKey?.sessionToken ?? "");
    const [store, setStore] = useState(false);
    const [uniqueId, setUniqueId] = useState(accessKey?.uniqueId ?? "");
    const [uniqueIdInputValid, setUniqueIdInputValid] = useState<Optional<boolean>>(undefined);

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useAwsDefinition.awsAccessKeyItem",
            () => ({
                actions: {
                    next: {
                        prompt: "* You’ll be able to review all changes before they are applied"
                    },
                    validateAccessKey: {
                        error: {
                            [AwsAccessKeyValidationError.General]: "Failed to validate Access Key",
                            [AwsAccessKeyValidationError.NotValid]: "Access Key is not valid"
                        }
                    }
                },
                mode: {
                    [AwsAccessKeyMode.New]: "Provide new credentials",
                    [AwsAccessKeyMode.Stored]: "Use stored credentials ({{storedUniqueId}})"
                },
                subtitle: "The provided credentials should have sufficient privileges to perform the required operations (typically IAMFullAccess) in account {{tenant}}",
                title: "Provide One-Time Credentials"
            }));

    const setWizardContext = useSetWizardContext();
    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    bottomElement:
                        <Typography>
                            {localization.actions.next.prompt()}
                        </Typography>
                }));
            setLoaded();
        },
        []);

    const setAutomationContext = useSetRiskResolutionAutomationContext();
    useEffect(
        () => {
            setAutomationContext(
                automationContext => ({
                    ...automationContext,
                    awsAccessKey:
                        mode === AwsAccessKeyMode.New
                            ? new Contract.AwsIamAccessKey(
                                secret,
                                sessionToken,
                                uniqueId)
                            : undefined,
                    storedAwsAccessKey: mode === AwsAccessKeyMode.Stored
                } as AwsRiskResolutionAutomationContext));
            setValid(
                mode !== AwsAccessKeyMode.New ||
                secretInputValid === true &&
                uniqueIdInputValid === true);
        },
        [mode, secret, secretInputValid, sessionToken, uniqueId, uniqueIdInputValid]);
    useChangeEffect(
        () => {
            setSecret("");
            setSessionToken("");
            setStore(false);
            setUniqueId("");
        },
        [mode]);

    useNextEffect(
        async () => {
            if (mode === AwsAccessKeyMode.New) {
                try {
                    const { valid } =
                        await TenantController.validateAwsAccessKey(
                            new Contract.TenantControllerValidateAwsAccessKeyRequest(
                                new Contract.AwsIamAccessKey(
                                    secret,
                                    sessionToken,
                                    uniqueId),
                                store,
                                riskTenantId));
                    if (!valid) {
                        return localization.actions.validateAccessKey.error[AwsAccessKeyValidationError.NotValid]();
                    }
                } catch {
                    return localization.actions.validateAccessKey.error[AwsAccessKeyValidationError.General]();
                }
            }

            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    bottomElement: undefined
                }));
            return undefined;
        },
        [mode, secret, sessionToken, store, uniqueId]);

    const theme = useTheme();
    return (
        <Stack spacing={4}>
            <Box>
                <Typography variant="h3">
                    {localization.title()}
                </Typography>
                <Typography>
                    {localization.subtitle({
                        tenant:
                            <Tenant
                                tenantId={riskTenantId}
                                variant="text"/>
                    })}
                </Typography>
            </Box>
            {!_.isNil(storedUniqueId)
                ? <RadioGroup
                    items={[
                        {
                            label: localization.mode[AwsAccessKeyMode.Stored]({ storedUniqueId }),
                            value: AwsAccessKeyMode.Stored
                        },
                        {
                            children:
                                <Box
                                    sx={{
                                        paddingBottom: theme.spacing(1.5),
                                        paddingLeft: theme.spacing(3),
                                        paddingTop: theme.spacing(1.5)
                                    }}>
                                    <AccessKeyInput
                                        disabled={executing}
                                        initialSecret={secret}
                                        initialSessionToken={sessionToken}
                                        initialUniqueId={uniqueId}
                                        onSecretChanged={
                                            (newSecret, newSecretInputValid) => {
                                                setSecret(newSecret);
                                                setSecretInputValid(newSecretInputValid);
                                            }
                                        }
                                        onSessionTokenChanged={setSessionToken}
                                        onStoreChanged={setStore}
                                        onUniqueIdChanged={
                                            (newUniqueId, newUniqueIdInputValid) => {
                                                setUniqueId(newUniqueId);
                                                setUniqueIdInputValid(newUniqueIdInputValid);
                                            }
                                        }/>
                                </Box>,
                            label: localization.mode[AwsAccessKeyMode.New](),
                            value: AwsAccessKeyMode.New
                        }
                    ]}
                    selectedValue={mode}
                    onChange={event => setMode(event)}/>
                : <AccessKeyInput
                    disabled={executing}
                    initialSecret={secret}
                    initialSessionToken={sessionToken}
                    initialUniqueId={uniqueId}
                    onSecretChanged={
                        (newSecret, newSecretInputValid) => {
                            setSecret(newSecret);
                            setSecretInputValid(newSecretInputValid);
                        }
                    }
                    onSessionTokenChanged={setSessionToken}
                    onStoreChanged={setStore}
                    onUniqueIdChanged={
                        (newUniqueId, newUniqueIdInputValid) => {
                            setUniqueId(newUniqueId);
                            setUniqueIdInputValid(newUniqueIdInputValid);
                        }}/>}
        </Stack>);
}

enum AwsAccessKeyMode {
    New = "new",
    Stored = "stored"
}

enum AwsAccessKeyValidationError {
    General = "general",
    NotValid = "notValid"
}