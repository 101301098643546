import { Link, Message, NumberFormatter, Shadows, useSetRoute } from "@infrastructure";
import { Avatar, ButtonBase, Grid2, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../../../../..";

type ItemProps = {
    className?: string;
    count: number;
    icon: ReactNode;
    infoItems?: ItemInfoItem[];
    linkUrl?: string;
    title: string;
};

export class ItemInfoItem {
    constructor(
        public title: string,
        public url?: string,
        public helpText?: string) {
    }
}

export function Item({ className, count, icon, infoItems, linkUrl, title }: ItemProps) {
    const theme = useTheme();
    const setRoute = useSetRoute();
    return (
        <Grid2
            className={`item ${className}`}
            size={{ xs: 6 }}>
            <Stack
                alignItems="center"
                component={ButtonBase}
                sx={{
                    "&:hover": {
                        boxShadow: theme.shadows[Shadows.Highlight]
                    },
                    background: theme.palette.background.alternate,
                    border: theme.border.alternate,
                    borderRadius: theme.spacing(0.75),
                    height: theme.spacing(21.75),
                    userSelect: "text",
                    width: "100%"
                }}
                onClick={
                    () => {
                        if (linkUrl) {
                            setRoute(linkUrl);
                        }
                    }}>
                <Avatar
                    sx={{
                        backgroundColor: theme.palette.risk.defaultCategory,
                        color: theme.palette.text.primary,
                        fontSize: "24px",
                        marginTop: theme.spacing(2),
                        position: "static"
                    }}>
                    {icon}
                </Avatar>
                <Typography
                    noWrap={true}
                    sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        lineHeight: 1,
                        marginTop: theme.spacing(2),
                        maxWidth: "100%"
                    }}>
                    {NumberFormatter.unit(count)}
                </Typography>
                <Typography
                    noWrap={true}
                    sx={{
                        fontSize: "13px",
                        maxWidth: "100%"
                    }}>
                    {title}
                </Typography>
                {!_.isEmpty(infoItems) &&
                    <Stack
                        alignItems="center"
                        sx={{
                            marginTop: theme.spacing(0.75),
                            maxWidth: "100%",
                            overflow: "hidden"
                        }}>
                        {_.map(
                            infoItems,
                            (infoItem, infoItemIndex) =>
                                <Link
                                    key={infoItemIndex}
                                    sx={{ maxWidth: "inherit" }}
                                    urlOrGetUrl={infoItem.url}
                                    variant="text">
                                    <InfoItem infoItem={infoItem}/>
                                </Link>)}
                    </Stack>}
            </Stack>
        </Grid2>);
}

type InfoItemProps = {
    infoItem: ItemInfoItem;
};

function InfoItem({ infoItem }: InfoItemProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={0.5}>
            <Typography
                noWrap={true}
                sx={{
                    "&:hover": {
                        color:
                            infoItem.url
                                ? theme.palette.link.hover
                                : undefined
                    },
                    color: theme.palette.text.secondary,
                    lineHeight: 1,
                    maxWidth: "100%"
                }}>
                {infoItem.title}
            </Typography>
            {!_.isNil(infoItem.helpText) &&
                <Message
                    level="info"
                    title={infoItem.helpText}
                    variant="minimal"/>}
        </Stack>);
}