import { Step, Steps, TextSnippet, TextSnippetItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useTheme } from "../../../../../../../../../../../../../common";
import { GcpCloudShellIcon } from "../../../../../../../../../../../../../tenants";
import { useAddOrEditContext } from "../../../index";
import { defaultSinkPubSubRawShortId, defaultSinkPubSubSubscriptionRawShortId, useSinkPubSubSubscriptionRawIdStep } from "../hooks";

export function CloudShell() {
    const { gciTenantModel, rawShortNameId, tenantOnboardingInfo } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.sinkPubSubSubscriptionItem.cloudShell",
            () => ({
                steps: {
                    step1: "Log into your Google Cloud Console.",
                    step2: "**Activate the Cloud Shell** by clicking {{cloudShellIcon}} on the top right of the console.",
                    step3: "**Run** the following script in your Cloud Shell:",
                    step4: "Wait until the script has completed successfully and copy the **Pub/Sub Subscription Name** from the output."
                }
            }));

    const cmdletCode =
        useMemo(
            () =>
                _.join([
                    `gcloud pubsub topics create ${defaultSinkPubSubRawShortId} --message-retention-duration=${tenantOnboardingInfo.pubSubMessageRetentionDayCount}d --project=${rawShortNameId}`,
                    `gcloud logging sinks create ${defaultSinkPubSubRawShortId} pubsub.googleapis.com/projects/${rawShortNameId}/topics/${defaultSinkPubSubRawShortId} --exclusion=filter='${_.replace(tenantOnboardingInfo.sinkExclusionFilter, "\n", " ")}',name=exclude-k8s-internal --log-filter='${_.replace(tenantOnboardingInfo.sinkInclusionFilter, "\n", " ")}' --project=${rawShortNameId}`,
                    `sink_writer_identity=$(gcloud logging sinks describe ${defaultSinkPubSubRawShortId} --project=${rawShortNameId} --format='value(writerIdentity)')`,
                    `gcloud pubsub topics add-iam-policy-binding ${defaultSinkPubSubRawShortId} --project=${rawShortNameId} --member=$sink_writer_identity --role='roles/pubsub.publisher'`,
                    `gcloud pubsub subscriptions create ${defaultSinkPubSubSubscriptionRawShortId} --message-retention-duration=${tenantOnboardingInfo.pubSubMessageRetentionDayCount}d --expiration-period=never --max-retry-delay=600 --topic=${defaultSinkPubSubRawShortId} --topic-project=${rawShortNameId} --project=${rawShortNameId}`,
                    `gcloud pubsub subscriptions add-iam-policy-binding ${defaultSinkPubSubSubscriptionRawShortId} --project=${rawShortNameId} --member='serviceAccount:${gciTenantModel!.configuration.serviceAccountMail}' --role='roles/pubsub.subscriber'`,
                    `echo Pub/Sub Subscription Name: projects/${rawShortNameId}/subscriptions/${defaultSinkPubSubSubscriptionRawShortId}`
                ],
                "\n"),
            []);

    const sinkPubSubSubscriptionRawIdStep = useSinkPubSubSubscriptionRawIdStep();
    const theme = useTheme();
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2({
                cloudShellIcon:
                    <GcpCloudShellIcon
                        sx={{
                            display: "inline",
                            fontSize: "18px",
                            verticalAlign: "middle"
                        }}/>
            })}
            {new Step(
                localization.steps.step3(),
                {
                    contentElement:
                        <TextSnippet
                            height={theme.spacing(52)}
                            items={[
                                new TextSnippetItem(
                                    cmdletCode,
                                    "")
                            ]}
                            wordWrap={false}/>
                })}
            {localization.steps.step4()}
            {sinkPubSubSubscriptionRawIdStep}
        </Steps>);
}