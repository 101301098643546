﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useAzureCommonEncryptedResourceInfoElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, tenantModelStore } from "../../../../../../../../../../common";
import { AzureConsoleUrlBuilder } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../components";

export function useAzureMachineLearningWorkspaceDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const workspace = resourceGroupResourceModel.entity as Contract.AzureMachineLearningWorkspace;
    const workspaceModel = resourceGroupResourceModel as Contract.AzureMachineLearningWorkspaceModel;

    const tenantModel = tenantModelStore.useGet(workspace.tenantId);
    const aadTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.AzureTenantConfiguration).aadTenantId);
    const aadTenantConfiguration = aadTenantModel.configuration as Contract.AadTenantConfiguration;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureMachineLearningWorkspaceDefinition",
            () => ({
                info: {
                    items: {
                        highBusinessImpact: {
                            false: "Disabled",
                            title: "High Business Impact",
                            true: "Enabled"
                        },
                        registry: "Container Registry",
                        storageAccount: "Storage Account",
                        studioWebUrl: "Studio web URL",
                        vault: "Key Vault"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="highBusinessImpact"
                        title={localization.info.items.highBusinessImpact.title()}
                        value={
                            workspace.highBusinessImpact
                                ? localization.info.items.highBusinessImpact.true()
                                : localization.info.items.highBusinessImpact.false()}/>,
                    <InfoItem
                        key="studioWebUrl"
                        title={localization.info.items.studioWebUrl()}
                        value={
                            <Link
                                urlOrGetUrl={
                                    AzureConsoleUrlBuilder.GetMachineLearningWorkspaceStudioWebUrl(
                                        aadTenantConfiguration.rawId,
                                        aadTenantConfiguration.partitionType,
                                        workspace.path!)}
                                variant="external"/>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={workspaceModel.registryIdReference}
                        entityTypeName={Contract.TypeNames.AzureContainerRegistryRegistry}
                        key="registry"
                        title={localization.info.items.registry()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={workspaceModel.vaultIdReference}
                        entityTypeName={Contract.TypeNames.AzureKeyVaultVault}
                        key="vault"
                        title={localization.info.items.vault()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={workspaceModel.storageAccountIdReference}
                        entityTypeName={Contract.TypeNames.AzureStorageStorageAccount}
                        key="storageAccount"
                        title={localization.info.items.storageAccount()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                </NetworkingInfoCard>
            </Info>
    });
}