import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsCommonRdsGlobalClusterResourceDefinition(
    clusterTypeName: string,
    definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonRdsGlobalClusterResourceDefinition",
            () => ({
                columns: {
                    deleteProtection: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Delete Protection"
                    },
                    engineVersion: "Engine Version",
                    status: "Status"
                }
            }));

    const translatedClusterTypeName =
        entityTypeNameTranslator(
            clusterTypeName,
            {
                count: 0,
                includeServiceName: true,
                variant: "title"
            });

    return {
        columns: {
            clustersColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsRdsGlobalClusterResourceModel>(
                            clusterTypeName,
                            item => item.clusterIdReferences,
                            translatedClusterTypeName)}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsGlobalClusterResourceClusters)}
                                    placeholder={translatedClusterTypeName}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsGlobalClusterResourceClusters}
                    key={Contract.EntityModelProperty.AwsRdsGlobalClusterResourceClusters}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsRdsGlobalClusterResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.clusterIdReferences}
                                entityTypeName={clusterTypeName}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={translatedClusterTypeName}/>,
            deleteProtectionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsGlobalClusterResourceModel) => ({
                                [localization.columns.deleteProtection.title()]:
                                    (item.entity as Contract.AwsRdsGlobalClusterResource).deleteProtection
                                        ? localization.columns.deleteProtection.enabled()
                                        : localization.columns.deleteProtection.disabled()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <ValuesFilter placeholder={localization.columns.deleteProtection.title()}>
                                    <ValuesFilterItem
                                        key="false"
                                        title={localization.columns.deleteProtection.disabled()}
                                        value={false}/>
                                    <ValuesFilterItem
                                        key="true"
                                        title={localization.columns.deleteProtection.enabled()}
                                        value={true}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsGlobalClusterResourceDeleteProtection}
                    itemProperty={
                        (item: Contract.AwsRdsGlobalClusterResourceModel) =>
                            (item.entity as Contract.AwsRdsGlobalClusterResource).deleteProtection
                                ? localization.columns.deleteProtection.enabled()
                                : localization.columns.deleteProtection.disabled()}
                    key={Contract.EntityModelProperty.AwsRdsGlobalClusterResourceDeleteProtection}
                    selectorOptions={{ default: false }}
                    title={localization.columns.deleteProtection.title()}/>,
            engineVersionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsGlobalClusterResourceModel) => ({
                                [localization.columns.engineVersion()]: (item.entity as Contract.AwsRdsGlobalClusterResource).engineVersion
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsGlobalClusterResourceEngineVersion)}
                                    placeholder={localization.columns.engineVersion()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsGlobalClusterResourceEngineVersion}
                    itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.AwsRdsGlobalClusterResource).engineVersion}
                    key={Contract.EntityModelProperty.AwsRdsGlobalClusterResourceEngineVersion}
                    title={localization.columns.engineVersion()}/>,
            statusColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsGlobalClusterResourceModel) => ({
                                [localization.columns.status()]: (item.entity as Contract.AwsRdsGlobalClusterResource).status
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsRdsGlobalClusterResourceStatus)}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsGlobalClusterResourceStatus}
                    itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.AwsRdsGlobalClusterResource).status}
                    key={Contract.EntityModelProperty.AwsRdsGlobalClusterResourceStatus}
                    title={localization.columns.status()}/>
        }
    };
}