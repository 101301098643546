﻿import { DataTableColumn, EnumValuesFilter, TextValuesFilter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ItemSelectionHelper, RiskController, RiskType } from "../../../../../../../../../../../../../../../../../../../../../common";
import { useAwsEcsTaskDefinitionStatusTranslator } from "../../../../../../../../../../../../../../../../../../Entities/hooks";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../useCommonCloudRiskPolicyTableDefinition";

export function useAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const [{ filters }] =
        useExecuteOperation(
            [useAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskDefinition, riskPolicyConfigurationTypeName],
            () =>
                RiskController.getRiskPolicyTypeGroupFilters(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest(
                        requestFilters!,
                        riskPolicyConfigurationTypeName)));

    const ecsTaskDefinitionStatusTranslator = useAwsEcsTaskDefinitionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useSecretDefinition.hooks.useAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskDefinition",
            () => ({
                columns: {
                    taskDefinitionRegionSystemName: "Region",
                    taskDefinitionStatus: "Task Definition Status"
                }
            }));

    const awsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters = filters as Contract.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters;
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();

    return useCommonCloudRiskPolicyTableDefinition({
        columns: _.filter([
            commonColumns.statusUpdateTimeColumn,
            commonColumns.severityColumn,
            commonColumns.riskPolicyIdColumn,
            commonCloudColumns.descriptionColumn,
            commonColumns.tenantIdColumn,
            commonColumns.riskedEntityIdColumn(
                riskPolicyConfigurationTypeName,
                requestFilters as Contract.RiskControllerCloudRiskModelFilters),
            commonColumns.resourceOwnerColumn,
            commonColumns.resourceEnvironmentColumn,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <TextValuesFilter
                                emptyValue={awsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters.taskDefinitionRegionSystemNameItems.emptyValue}
                                placeholder={localization.columns.taskDefinitionRegionSystemName()}
                                values={awsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters.taskDefinitionRegionSystemNameItems.items}/>
                    }
                }}
                id={TableColumnId.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskRegionSystemName}
                itemProperty={(item: Contract.RiskModel) => (item.risk as Contract.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk).taskDefinitionRegionSystemName}
                key={TableColumnId.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskRegionSystemName}
                sortOptions={{ enabled: false }}
                title={localization.columns.taskDefinitionRegionSystemName()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsEcsTaskDefinitionStatus}
                                enumTypeTranslator={ecsTaskDefinitionStatusTranslator}
                                placeholder={localization.columns.taskDefinitionStatus()}
                                sorted={false}/>
                    }
                }}
                id={TableColumnId.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskEcsTaskDefinitionStatus}
                itemProperty={(item: Contract.RiskModel) => (item.risk as Contract.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk).taskDefinitionStatus}
                key={TableColumnId.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskEcsTaskDefinitionStatus}
                sortOptions={{ enabled: false }}
                title={localization.columns.taskDefinitionStatus()}/>,
            commonColumns.statusColumn(true),
            commonColumns.subStatusColumn(true),
            commonColumns.ignoreExpirationDateColumn,
            commonColumns.openStatusUpdateTimeColumn,
            commonColumns.systemCreationTimeColumn,
            commonColumns.riskedEntityAttributesColumn,
            commonColumns.actionsCell(RiskType.Cloud)
        ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerRequestAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskRegionSystemName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskEcsTaskDefinitionStatus])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}