import { DialogMenuItem, Link, TimeFormatter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from "..";
import { Contract, EntityController, entityModelStore, InfoItem } from "../../../../../../../../../../../common";
import { GenerateAwsResourceNonexcessivePolicyDialog } from "../../../../../../../../../../../tenants";
import { useAwsLambdaFunctionConfigurationPackageTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../../icons";
import { Aliases, Revisions } from "./components";

export function useAwsLambdaFunctionConfigurationDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const functionConfigurationModel = resourceModel as Contract.AwsLambdaFunctionConfigurationModel;
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;

    const functionConfigurationPackageTypeTranslator = useAwsLambdaFunctionConfigurationPackageTypeTranslator();

    const [entityExcessivePermissionCustomEvaluationData] =
        useExecuteOperation(
            [useAwsLambdaFunctionConfigurationDefinition, functionConfigurationModel.id],
            async () =>
                await EntityController.getEntityExcessivePermissionCustomEvaluationData(
                    new Contract.EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest(functionConfigurationModel.id)));

    const roleIds =
        useMemo(
            () =>
                _(functionConfiguration.revisionMap).
                    map(revision => revision.roleReference.idReference).
                    uniq().
                    value(),
            [functionConfiguration]);

    entityModelStore.useGet(roleIds);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsLambdaFunctionConfigurationDefinition",
            () => ({
                info: {
                    items: {
                        authenticationType: {
                            false: "NONE",
                            title: "Authentication Type",
                            true: "AWS_IAM"
                        },
                        packageType: "Package Type",
                        updateTime: "Update Time",
                        url: "Function URL",
                        vpc: "VPC"
                    }
                },
                tabs: {
                    aliases: "Aliases ({{aliasCount | NumberFormatter.humanize}})",
                    revisions: "Revisions ({{revisionCount | NumberFormatter.humanize}})"
                },
                topbar: {
                    menu: {
                        generate: "Generate Least Privilege Policy"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <Revisions functionConfigurationModel={functionConfigurationModel}/>,
                        localization.tabs.revisions({ revisionCount: functionConfiguration.revisionCount }),
                        "revisions")).
                concatIf(
                    !_.isEmpty(functionConfiguration.aliasMap),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <Aliases functionConfigurationModel={functionConfigurationModel}/>,
                        localization.tabs.aliases({ aliasCount: _.size(functionConfiguration.aliasMap) }),
                        "aliases")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="updateTime"
                        title={localization.info.items.updateTime()}
                        value={TimeFormatter.profileFormatDateTime(functionConfiguration.updateTime)}/>,
                    <InfoItem
                        key="url"
                        title={localization.info.items.url()}
                        value={
                            _.isNil(functionConfiguration?.url)
                                ? undefined
                                : <Link
                                    urlOrGetUrl={functionConfiguration?.url?.url}
                                    variant="external"/>}/>,
                    <InfoItem
                        key="authenticationType"
                        title={localization.info.items.authenticationType.title()}
                        value={
                            functionConfiguration?.url?.authenticationEnabled
                                ? localization.info.items.authenticationType.true()
                                : localization.info.items.authenticationType.false()}/>,
                    <InfoItem
                        key="packageType"
                        location="all"
                        title={localization.info.items.packageType()}
                        value={functionConfigurationPackageTypeTranslator(functionConfiguration.packageType)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={functionConfiguration.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        topbarMenuItems:
            entityExcessivePermissionCustomEvaluationData.nonexcessivePermitterCustomGenerationEnabled
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateAwsResourceNonexcessivePolicyDialog
                                entityExcessivePermissionCustomEvaluationData={entityExcessivePermissionCustomEvaluationData}
                                entityModel={functionConfigurationModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}