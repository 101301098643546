import { defined, PasswordTextField, Step, Steps, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, LicensingHelper, TenantController, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../AddOrEdit";
import { useUpsertTenant } from "../../hooks";
import { InstructionsImageUrl } from "./images";

export function ReadClientCredentialsItem() {
    const { tenantConfiguration, tenantName, tenantReadClientId, tenantReadClientSecret, tenantUrl } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();

    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
            setLoaded();
        },
        []);

    const tenantModels = tenantModelStore.useGetPermittedOneLoginTenants();

    const [clientId, setClientId] = useState(tenantReadClientId);
    const [clientSecret, setClientSecret] = useState(tenantReadClientSecret);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oneLoginTenants.addOrEdit.readClientCredentialsItem",
            () => ({
                actions: {
                    validate: {
                        error: {
                            exists: "Account already exists",
                            tenantMismatch: "The client ID does not belong to this **Onelogin** account",
                            [Contract.TypeNames.TenantControllerValidateOneLoginTenantResult]: {
                                [Contract.TenantControllerValidateOneLoginTenantResult.AuthenticationFailed]: "Authentication failure",
                                [Contract.TenantControllerValidateOneLoginTenantResult.MissingPermissions]: "Insufficient permissions"
                            }
                        }
                    }
                },
                fields: {
                    clientId: {
                        error: {
                            required: "Client ID cannot be empty"
                        },
                        title: "Client ID"
                    },
                    clientSecret: {
                        error: {
                            required: "Client secret cannot be empty"
                        },
                        title: "Client Secret"
                    }
                },
                steps: {
                    step1: "Go to OneLogin **Administration** page",
                    step2: "In the top menu, click **Developers -> API Credentials**",
                    step3: "Click **New Credential**, enter a **Name**, and select **Read All** to allow Tenable Cloud Security read access to your directory data. Click **Save**",
                    step4: "Copy the **Client ID** and **Client Secret** and paste them below. Click **Done**"
                }
            }));

    const [clientIdValidationController, clientIdValidationMessage] =
        useInputValidation(
            () => {
                const validationClientId = clientId.trim();
                if (_.isEmpty(validationClientId)) {
                    return localization.fields.clientId.error.required();
                }

                return undefined;
            },
            [clientId]);

    const [clientSecretValidationController, clientSecretValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(tenantConfiguration) &&
                    _.isNil(clientSecret)) {
                    return undefined;
                }

                const validationClientSecret = clientSecret?.trim();
                if (_.isEmpty(validationClientSecret)) {
                    return localization.fields.clientSecret.error.required();
                }

                return undefined;
            },
            [clientSecret]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantReadClientId: clientId.trim(),
                    tenantReadClientSecret: clientSecret?.trim()
                }));

            setValid(
                clientIdValidationController.isValid() &&
                clientSecretValidationController.isValid());
        },
        [clientId, clientSecret]);

    const upsertTenant = useUpsertTenant();
    useNextEffect(
        async () => {
            if (LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement)) {
                const { accountRawId, result } =
                    await TenantController.validateOneLoginTenant(
                        !_.isNil(clientSecret)
                            ? new Contract.TenantControllerValidateOneLoginTenantRequest(
                                clientId,
                                clientSecret,
                                undefined,
                                tenantUrl)
                            : new Contract.TenantControllerValidateOneLoginTenantRequest(
                                clientId,
                                undefined,
                                defined(tenantConfiguration?.id),
                                tenantUrl));

                if (result !== Contract.TenantControllerValidateOneLoginTenantResult.Valid) {
                    return localization.actions.validate.error[Contract.TypeNames.TenantControllerValidateOneLoginTenantResult][result]();
                }

                if (_.isNil(tenantConfiguration)) {
                    if (_.some(
                        tenantModels,
                        tenantModel => tenantModel.configuration.rawId === accountRawId)) {
                        return localization.actions.validate.error.exists();
                    }
                } else {
                    if (tenantConfiguration.rawId !== accountRawId) {
                        return localization.actions.validate.error.tenantMismatch();
                    }
                }
            } else {
                return await upsertTenant(
                    tenantName,
                    undefined,
                    undefined,
                    clientId,
                    clientSecret,
                    tenantConfiguration,
                    tenantUrl);
            }
        },
        [clientId, clientSecret]);

    const theme = useTheme();
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
            {
                new Step(
                    localization.steps.step4(),
                    {
                        contentElement:
                            <Stack
                                spacing={2}
                                sx={{ marginTop: theme.spacing(4) }}>
                                <FormControl
                                    fullWidth={true}
                                    variant="standard">
                                    <TextField
                                        disabled={executing}
                                        label={localization.fields.clientId.title()}
                                        value={clientId}
                                        variant="outlined"
                                        onChange={event => setClientId(event.target.value)}/>
                                    {!_.isNil(clientIdValidationMessage) &&
                                        <FormHelperText error={true}>{clientIdValidationMessage}</FormHelperText>}
                                </FormControl>
                                <FormControl
                                    fullWidth={true}
                                    variant="standard">
                                    <PasswordTextField
                                        disabled={executing}
                                        label={localization.fields.clientSecret.title()}
                                        password={clientSecret}
                                        variant="outlined"
                                        onPasswordChanged={clientSecret => setClientSecret(clientSecret)}/>
                                    {!_.isNil(clientSecretValidationMessage) &&
                                        <FormHelperText error={true}>{clientSecretValidationMessage}</FormHelperText>}
                                </FormControl>
                            </Stack>
                    })}
        </Steps>);
}