import { Link, Shadows } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ComplianceSectionIcon, ComplianceSecuredBar, Contract, CustomerConsoleAppUrlHelper, useTheme } from "../../../../../../../../../../..";

type ItemProps = {
    complianceSectionData: Contract.ComplianceSectionData;
    complianceSectionTitle: string;
    scopeId: string;
    timeFrame: Contract.TimeFrame;
};

export function Item({ complianceSectionData, complianceSectionTitle, scopeId, timeFrame }: ItemProps) {
    const [hover, setHover] = useState(false);

    const theme = useTheme();
    return (
        <Link
            className="item"
            key={complianceSectionData.identifier}
            urlOrGetUrl={
                CustomerConsoleAppUrlHelper.getComplianceProfileHashUrl(
                    complianceSectionData.identifier,
                    scopeId)}
            variant="text">
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                sx={{
                    "&:hover": {
                        background: theme.palette.background.alternate,
                        border: theme.border.alternate,
                        borderRadius: theme.spacing(0.75),
                        boxShadow: theme.shadows[Shadows.Highlight],
                        zIndex: 1
                    },
                    border: "solid 1px transparent",
                    height: theme.spacing(6.5)
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    sx={{
                        flex: 1,
                        overflow: "hidden",
                        padding: theme.spacing(0, 1)
                    }}>
                    <Box sx={{ marginLeft: theme.spacing(0.5) }}>
                        <ComplianceSectionIcon
                            complianceIdentifier={complianceSectionData.identifier}
                            size={theme.spacing(3)}/>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            overflow: "hidden"
                        }}>
                        <Typography
                            noWrap={true}
                            sx={{
                                fontSize: "13px",
                                fontWeight:
                                    hover
                                        ? "bold"
                                        : 500
                            }}>
                            {complianceSectionTitle}
                        </Typography>
                    </Box>
                </Stack>
                <Box
                    sx={{
                        paddingRight: theme.spacing(2),
                        width: theme.spacing(22)
                    }}>
                    <ComplianceSecuredBar
                        data={complianceSectionData}
                        timeFrame={timeFrame}/>
                </Box>
            </Stack>
        </Link>);
}