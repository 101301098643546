import React from "react";
import { Contract } from "../../controllers";
import { DisabledBar, EnabledBar } from "./components";

type ComplianceSecuredBarProps = {
    data: Contract.ComplianceData;
    dense?: boolean;
    disabled?: boolean;
    timeFrame?: Contract.TimeFrame;
};

export function ComplianceSecuredBar({ data, dense = false, disabled = false, timeFrame }: ComplianceSecuredBarProps) {
    return (
        data.enabled && !disabled
            ? <EnabledBar
                data={data}
                dense={dense}
                timeFrame={timeFrame}/>
            : <DisabledBar dense={dense}/>);
}