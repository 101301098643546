﻿import { AddIcon, CheckButton, EmptyMessage, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ConfigurationController, Contract, scopeNodeModelStore, scopeSystemEntityModelStore, StorageHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../common";
import { ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapRequest, ConfigurationControllerGetSlackWorkspaceOnboardingInfoRequest } from "../../../../../../../../../../../../../../common/controllers/types.generated";
import { useIntegrationsContext, useSetIntegrationsContext } from "../../../../../../../../Integrations";
import { Workspace } from "./components";

export function List() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [{ onboardingOAuthAuthorizationUrl, workspaceIdToOAuthAuthorizationUrlMap }] =
        useExecuteOperation(
            List,
            async () => {
                const { oAuthAuthorizationUrl } = await ConfigurationController.getSlackWorkspaceOnboardingInfo(new ConfigurationControllerGetSlackWorkspaceOnboardingInfoRequest(scopeNodeModel.configuration.id));
                const { workspaceIdToOAuthAuthorizationUrlMap } = await ConfigurationController.getSlackWorkspaceIdToOAuthAuthorizationUrlMap(new ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapRequest(scopeNodeModel.configuration.id));
                return {
                    onboardingOAuthAuthorizationUrl: oAuthAuthorizationUrl,
                    workspaceIdToOAuthAuthorizationUrlMap
                };
            });

    const { childScopeEnabled } = useIntegrationsContext();
    const setIntegrationsContext = useSetIntegrationsContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const workspaceModels = scopeSystemEntityModelStore.useGetSlack();
    const filteredOrganizationModels =
        useMemo(
            () =>
                _.filter(
                    workspaceModels,
                    workspaceModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            concatIf(
                                childScopeEnabled,
                                childScopeIds).
                            includes(workspaceModel.configuration.scopeId)),
            [childScopeEnabled, childScopeIds, workspaceModels, parentScopeIds]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCollaborationItems.slack.list",
            () => ({
                actions: {
                    add: "Add Slack Workspace",
                    childScopeEnabled: "Flat View"
                },
                empty: "No slacks workspaces",
                slackWorkspaces: [
                    "1 Slack workspace",
                    "{{count | NumberFormatter.humanize}} Slack Workspaces"
                ]
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="stretch"
            spacing={0.5}
            sx={{
                height: "100%",
                overflow: "hidden auto",
                padding: theme.spacing(2)
            }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{ padding: theme.spacing(1) }}>
                <Typography
                    sx={{
                        color: theme.palette.text.secondary,
                        flex: 1
                    }}>
                    {localization.slackWorkspaces(filteredOrganizationModels.length)}
                </Typography>
                <CheckButton
                    checked={childScopeEnabled}
                    title={localization.actions.childScopeEnabled()}
                    onCheckedChanged={
                        checked => {
                            StorageHelper.customerConfigurationIntegrationsFlatView.setValue(checked);
                            setIntegrationsContext(_ => ({ childScopeEnabled: checked }));
                        }}/>
                <Button
                    href={onboardingOAuthAuthorizationUrl}
                    startIcon={<AddIcon/>}>
                    {localization.actions.add()}
                </Button>
            </Stack>
            <Box>
                {_.isEmpty(filteredOrganizationModels)
                    ? <EmptyMessage
                        message={localization.empty()}
                        verticalCenter={true}/>
                    : _(filteredOrganizationModels).
                        sortBy(workspaceModel => (workspaceModel.configuration as Contract.SlackWorkspaceConfiguration).name).
                        map(
                            workspaceModel =>
                                <Workspace
                                    key={workspaceModel.configuration.id}
                                    oAuthAuthorizationUrl={workspaceIdToOAuthAuthorizationUrlMap[workspaceModel.configuration.id]}
                                    workspaceModel={workspaceModel}/>).
                        value()}
            </Box>
        </Stack>);
}