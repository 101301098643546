import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciKmsVaultTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciKmsVaultTypeTranslator",
            () => ({
                [Contract.TypeNames.OciKmsVaultType]: {
                    [Contract.OciKmsVaultType.Default]: "Default",
                    [Contract.OciKmsVaultType.External]: "External",
                    [Contract.OciKmsVaultType.VirtualPrivate]: "Virtual Private"
                }
            }));
    return (type: Contract.OciKmsVaultType) =>
        localization[Contract.TypeNames.OciKmsVaultType][type]();
}