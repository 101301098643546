﻿import { DataTableColumn, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAzureCommonResourceGroupResourceDefinition } from "./useAzureCommonResourceGroupResourceDefinition";

export function useAzureNetworkApplicationGatewayDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureNetworkApplicationGatewayDefinition",
            () => ({
                columns: {
                    privateIpAddresses: "Private IP Addresses",
                    publicIpAddressDnsNames: "Public IP Address DNS Names",
                    publicIpAddresses: "Public IP Addresses"
                }
            }));
    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkApplicationGatewayModel>(
                        Contract.TypeNames.AzureNetworkPublicIpAddress,
                        item => (item.entity as Contract.AzureNetworkApplicationGateway).publicIpAddressIds,
                        localization.columns.publicIpAddressDnsNames(),
                        { getValue: entityModel => (entityModel.entity as Contract.AzureNetworkPublicIpAddress).dnsName ?? "" })
                }
                id="publicIpAddressDnsNames"
                key="publicIpAddressDnsNames"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkApplicationGatewayModel) => ({
                            [localization.columns.privateIpAddresses()]: _.join((item.entity as Contract.AzureNetworkApplicationGateway).privateIpAddresses, "\n")
                        })
                }}
                id="privateIpAddresses"
                key="privateIpAddresses"/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkApplicationGatewayModel>(
                        Contract.TypeNames.AzureNetworkPublicIpAddress,
                        item => (item.entity as Contract.AzureNetworkApplicationGateway).publicIpAddressIds,
                        localization.columns.publicIpAddresses(),
                        { getValue: entityModel => (entityModel.entity as Contract.AzureNetworkPublicIpAddress).ipAddress ?? "" })
                }
                id="publicIpAddresses"
                key="publicIpAddresses"/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureNetworkApplicationGatewayRequest(
                new Contract.EntityControllerGetEntityModelPageAzureNetworkApplicationGatewayRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}