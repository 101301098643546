import { NumberFormatter, useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../common";
import { SectionHeader } from "../../SectionHeader";

type SecretsTitleSectionProps = {
    itemDatas: {
        entityIds: string[];
        entityTypeName: string;
        riskPolicyConfigurationTypeName: string;
        secretCount: number;
    }[];
};

export function SecretsTitleSection({ itemDatas }: SecretsTitleSectionProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.entityPublicDataAndSecretsContentPages.secretsTitleSection",
            () => ({
                secretCount: "{{translatedSecretCount}} Exposed secrets",
                subtitle: "View exposed secrets that are stored in unsecured configuration variables, such as resource tags or environment variables. These secrets are accessible by any identity with basic read permissions, and may grant access to critical data.",
                title: "Exposed Secrets"
            }));

    const theme = useTheme();
    return (
        <Grid
            container={true}
            direction="column"
            spacing={2}>
            <Grid item={true}>
                <SectionHeader
                    subtitle={localization.subtitle()}
                    title={localization.title()}
                    variant="sub"/>
            </Grid>
            <Grid item={true}>
                <Grid
                    container={true}
                    style={{
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    <Typography sx={{ fontSize: "14px" }}>
                        {localization.secretCount({
                            translatedSecretCount:
                                <Typography
                                    component="span"
                                    sx={{
                                        fontSize: "25px",
                                        fontWeight: "bold",
                                        marginRight: theme.spacing(0.5)
                                    }}>
                                    {NumberFormatter.humanize(
                                        _(itemDatas).
                                            map(itemData => itemData.secretCount).
                                            sum())}
                                </Typography>
                        })}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>);
}