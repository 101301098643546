import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSetReportsContext } from "../../..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, UserHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../common";

export function ActionsCell({ item: reportScheduleModel }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setReportsContext = useSetReportsContext();

    const [deleteReportScheduleExecuting, setDeleteReportScheduleExecuting] = useState(false);
    const [deleteReportScheduleError, setDeleteReportScheduleError] = useState(false);

    async function deleteReportSchedule() {
        setDeleteReportScheduleExecuting(true);
        setDeleteReportScheduleError(false);

        try {
            await ConfigurationController.deleteReportSchedule(
                new Contract.ConfigurationControllerDeleteReportScheduleRequest(reportScheduleModel.configuration.id));
            await scopeSystemEntityModelStore.notifyDeleted(reportScheduleModel.configuration.id);
        } catch {
            setDeleteReportScheduleError(true);
        }

        setDeleteReportScheduleExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.reports.schedule.actionsCell",
            () => ({
                actions: {
                    delete: {
                        confirmMessage: "Are you sure you want to delete this scheduled report?",
                        error: "Failed to delete report schedule",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteReportScheduleExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteReportScheduleError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {UserHelper.hasScopePermissions(scopeNodeModel.configuration.id, Contract.IdentityPermission.SecurityWrite) && (
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setReportsContext(
                                        reportsContext => ({
                                            ...reportsContext,
                                            addOrEditOpen: true,
                                            reportScheduleConfiguration: reportScheduleModel.configuration as Contract.ReportScheduleConfiguration
                                        })),
                                localization.actions.edit(),
                                {
                                    disabled: deleteReportScheduleExecuting,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () => deleteReportSchedule(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.confirmMessage()
                                    },
                                    disabled: deleteReportScheduleExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>)}
        </Stack>);
}