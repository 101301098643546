import { StorageHelper, ThemeType } from "@infrastructure";

export class ThemeHelper {
    public static getThemeType(type?: ThemeType) {
        const themeType = type ?? StorageHelper.theme.getValue() ?? ThemeType.System;
        if (themeType === ThemeType.System) {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                return ThemeType.Dark;
            }
            return ThemeType.Light;
        }
        return themeType as ThemeType.Dark | ThemeType.Light;
    }
}