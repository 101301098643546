import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useQRadarServerAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useQRadarServerAuditEventDefinition",
            () => ({
                serverEndpoint: "Endpoint",
                serverEndpointConnectorName: "Endpoint connector",
                serverName: "Name",
                serverServerCertificateValidationEnabled: {
                    false: "Yes",
                    title: "Accept any server SSL certificates",
                    true: "No"
                }
            }));

    const qRadarServerAuditEvent = context.auditEventModel.auditEvent as Contract.QRadarServerAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concatIf(
                !_.isNil(qRadarServerAuditEvent.serverEndpoint),
                new AuditEventDefinitionDetailItem(
                    localization.serverEndpoint(),
                    qRadarServerAuditEvent.serverEndpoint!)).
            concatIf(
                !_.isNil(qRadarServerAuditEvent.serverEndpointConnectorName),
                new AuditEventDefinitionDetailItem(
                    localization.serverEndpointConnectorName(),
                    qRadarServerAuditEvent.serverEndpointConnectorName!)).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.serverName(),
                    qRadarServerAuditEvent.serverName),
                new AuditEventDefinitionDetailItem(
                    localization.serverServerCertificateValidationEnabled.title(),
                    qRadarServerAuditEvent.serverServerCertificateValidationEnabled
                        ? localization.serverServerCertificateValidationEnabled.true()
                        : localization.serverServerCertificateValidationEnabled.false())).
            value());
}