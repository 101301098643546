﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, ScopeHelper, scopeNodeModelStore } from "../../../../../../../../common";
import { AddOrEditDefinition } from "../../useAddOrEditDefinition";
import { AddOrEdit, Welcome } from "./components";

export function useOpAddOrEditDefinition(): AddOrEditDefinition {
    const tenantTypes =
            useMemo(
                () => [Contract.TenantType.Op],
                []);
    const activeScopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(tenantTypes);
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOpDefinition.useOpAddOrEditDefinition",
            () => ({
                addTenantTitle: "Add account"
            }));

    return useMemo(
        () => ({
            addTitle: localization.addTenantTitle(),
            dialogSize: "small",
            element: AddOrEdit,
            welcome:
                _(activeScopeNodeMap).
                    filter(
                        scopeNode =>
                            !ScopeHelper.isRootFolder(scopeNode.scopeNodeModel) &&
                            scopeNode.scopeNodeModel.configuration.id !== ScopeHelper.customerId).
                    isEmpty()
                    ? <Welcome/>
                    : undefined
        }),
        [activeScopeNodeMap, localization]);
}