﻿import { DataTableColumnRenderProps, NotValidIcon, SuccessIcon, Tooltip } from "@infrastructure";
import { Stack } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../common";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            {!((item.state as Contract.WebhookEndpointState)?.failed)
                ? <SuccessIcon
                    sx={{
                        color: theme.palette.success.main,
                        fontSize: "18px"
                    }}/>
                : <Tooltip titleOrGetTitle={(item.state as Contract.WebhookEndpointState)?.errorMessage}>
                    <NotValidIcon
                        sx={{
                            color: theme.palette.error.main,
                            fontSize: "18px"
                        }}/>
                </Tooltip>}
        </Stack>);
}