import { map, useLocalization } from "@infrastructure";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../common";
import { CloudShell, Manual } from "./components";

export function RolesItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.rolesItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add organization",
                            edit: "Failed to save organization"
                        }
                    }
                },
                onboardingType: {
                    [OnboardingType.CloudShell]: "gcloud CLI (Cloud Shell)",
                    [OnboardingType.Manual]: "Manual (Google Cloud Console)"
                }
            }));

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(OnboardingType.CloudShell);
    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={selectedOnboardingType}
                variant="standard"
                onChange={(_, value) => setSelectedOnboardingType(value)}>
                {_.map(
                    [
                        OnboardingType.CloudShell,
                        OnboardingType.Manual
                    ],
                    onboardingType =>
                        <Tab
                            key={onboardingType}
                            label={localization.onboardingType[onboardingType]()}
                            sx={{ padding: theme.spacing(1, 2) }}
                            value={onboardingType}/>)}
            </Tabs>
            <Stack
                key={selectedOnboardingType}
                sx={{ overflow: "hidden auto" }}>
                <Box sx={{ maxWidth: theme.spacing(80) }}>
                    {map(
                        selectedOnboardingType,
                        {
                            [OnboardingType.CloudShell]: () => <CloudShell/>,
                            [OnboardingType.Manual]: () => <Manual/>
                        })}
                </Box>
            </Stack>
        </Stack>);
}

enum OnboardingType {
    CloudShell = "cloudShell",
    Manual = "manual"
}