import { Action1, Shadows } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { memo } from "react";
import { useTheme, VulnerabilityEventType } from "../../../../../../../../../../common";
import { useVulnerabilityEventTypeTranslator } from "../../../hooks";

const LegendMemo = memo(Legend);
export { LegendMemo as Legend };

type LegendProps = {
    activeEventType?: VulnerabilityEventType;
    eventTypes: VulnerabilityEventType[];
    hoverEventType?: VulnerabilityEventType;
    setActiveEventType: Action1<VulnerabilityEventType | undefined>;
    setHoverEventType: Action1<VulnerabilityEventType | undefined>;
};

function Legend({ activeEventType, eventTypes, hoverEventType, setActiveEventType, setHoverEventType }: LegendProps) {
    const vulnerabilityEventNameTranslator = useVulnerabilityEventTypeTranslator();
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{ flexWrap: "wrap" }}>
            {_.map(
                eventTypes,
                eventType => (
                    <Stack
                        alignItems="center"
                        direction="row"
                        key={eventType}
                        sx={{
                            cursor: "pointer",
                            height: theme.spacing(4),
                            paddingRight: theme.spacing(1),
                            ...(hoverEventType === eventType && {
                                boxShadow: theme.shadows[Shadows.Highlight]
                            })
                        }}
                        onClick={
                            () =>
                                setActiveEventType(
                                    activeEventType === eventType
                                        ? undefined
                                        : eventType)}
                        onMouseEnter={() => setHoverEventType(eventType)}
                        onMouseLeave={() => setHoverEventType(undefined)}>
                        <Box
                            sx={{
                                backgroundColor: theme.palette.workload.vulnerabilityEventType(eventType),
                                height:
                                    activeEventType === eventType || hoverEventType === eventType
                                        ? theme.spacing(4)
                                        : theme.spacing(1.5),
                                width: "3px"
                            }}/>
                        <Typography
                            noWrap={true}
                            sx={{ margin: theme.spacing(0, 1) }}>
                            {vulnerabilityEventNameTranslator(eventType)}
                        </Typography>
                    </Stack>))}
        </Stack>);
}