import { Filter, Filters, FiltersActions, GraphIcon, InboundIcon, ListIcon, OutboundIcon, TextValuesFilter, useActions, useChangeEffect, useExecuteOperation, useLocalization, useSetRoute, ValueFilter, ValuesFilter, ValuesFilterItem, ValuesFilterSelection } from "@infrastructure";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { AwsPrincipalExcessivePermissionCustomEvaluationFilter } from ".";
import { AccessView, ActionRiskCategoryFilter, Contract, EntityAttributeFilter, EntityController, EntityFilter, EntityTypeMetadataModelHelper, PermissionActionExcessivenessFilter, SeverityFilter, StorageHelper, tenantModelStore, TypeHelper, useEntityTypeNameTranslator, useTheme } from "../../../../../../..";
import { AccessDefinitionToolbarActions, AccessDefinitionToolbarProps, ToolbarFilterId } from "../../..";
import { AwsTenantHelper } from "../../../../../../../../tenants";
import { AccessLevelFilter } from "../../../../../../AccessLevelFilter";

type ToolbarProps =
    AccessDefinitionToolbarProps & {
        filterIds?: ToolbarFilterId[];
    };

export function Toolbar({ accessGraph, actionsRef, baseUrl, csvExportButtonElement, entityModel, filterIds, graphDirection, onFiltersChanged, view }: ToolbarProps) {
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();
    const [{ accessGraphCustomExcessiveEvaluationEnabled: excessivePermissionCustomEvaluationEnabled, dateInfos: excessivePermissionCustomEvaluationDateInfos, maxTime: excessivePermissionCustomEvaluationMaxTime }] =
        useExecuteOperation(
            Toolbar,
            async () =>
                TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AwsIamIdentity) &&
                    _.every(accessGraph?.groups ?? undefined, group => !group.sinister)
                    ? await EntityController.getEntityExcessivePermissionCustomEvaluationData(new Contract.EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest(entityModel.id))
                    : new Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse(false, [], undefined, undefined, false));

    const entityTypeMetadataModel = EntityTypeMetadataModelHelper.get(entityModel.entity.typeName);
    const [filterMap, setFilterMap] = useState<Dictionary<any>>({});
    useChangeEffect(
        () => onFiltersChanged({ ...filterMap }),
        [filterMap]);

    const { accessVariant, filterToValuesMap } =
        useMemo(
            () => ({
                accessVariant: accessGraph?.variant,
                filterToValuesMap: {
                    [ToolbarFilterId.DestinationEntityIds]:
                        _(accessGraph?.destinationEntityGroups).
                            flatMap(destinationEntityGroup => destinationEntityGroup.entityIds).
                            value(),
                    [ToolbarFilterId.DestinationResourceTenantIds]: accessGraph?.destinationResourceTenantIds,
                    [ToolbarFilterId.IdentityIds]:
                        _.flatMap(
                            accessGraph?.identityGroups,
                            identityGroup => identityGroup.identityIds),
                    [ToolbarFilterId.GroupIds]:
                        _(accessGraph?.groups).
                            map(group => group.groupId).
                            uniq().
                            value(),
                    [ToolbarFilterId.OriginatorEntityIds]:
                        _.flatMap(
                            accessGraph?.originatorEntityGroups,
                            originatorEntityGroup => originatorEntityGroup.originatorEntityIds),
                    [ToolbarFilterId.OriginatorEntityTypeNames]:
                        _(accessGraph?.originatorEntityGroups).
                            flatMap(originatorEntityGroup => originatorEntityGroup.entityTypeNames).
                            uniq().
                            value(),
                    [ToolbarFilterId.PermissionActions]: accessGraph?.permissionActions,
                    [ToolbarFilterId.PermitterIds]:
                        _(accessGraph?.permissionPaths).
                            map(permissionPath => (permissionPath as Contract.AwsAccessGraphPermissionPath).identifier.principalPolicyId!).
                            filter().
                            uniq().
                            value()
                } as Dictionary<string[]>
            }),
            []);

    const filtersActionsRef = useRef<FiltersActions>();
    useActions<AccessDefinitionToolbarActions>(
        actionsRef,
        {
            filter:
                (filterId, type, values) => {
                    filtersActionsRef.current!.set(
                        filterId,
                        existingFilter =>
                            new ValuesFilterSelection(
                                type == "by"
                                    ? false
                                    : existingFilter?.emptyValue ?? false,
                                type === "by"
                                    ? values
                                    : _.difference(
                                        _.isEmpty(existingFilter)
                                            ? filterToValuesMap[filterId]
                                            : existingFilter.values,
                                        values)));
                }
        });

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAwsDefinition.toolbar",
            () => ({
                filters: {
                    [AwsToolbarFilterId.ExcessivePermissionEvaluationStartDate]: "Excessive Time Frame",
                    [AwsToolbarFilterId.PermissionActionExcessiveness]: "Usage",
                    [AwsToolbarFilterId.VendorRoles]: "3*superscript*rd** Party",
                    [ToolbarFilterId.AccessLevels]: "Access Level",
                    [ToolbarFilterId.DestinationEntityIds]: "Resources",
                    [ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames]: "Resource Labels",
                    [ToolbarFilterId.DestinationResourceTenantIds]: "Resource Accounts",
                    [ToolbarFilterId.GroupIds]: "Groups",
                    [ToolbarFilterId.IdentityIds]: "Identities",
                    [ToolbarFilterId.OriginatorEntityIds]: "Originators",
                    [ToolbarFilterId.OriginatorEntityTypeNames]: "Originators Type",
                    [ToolbarFilterId.PermissionActionRiskCategories]: "Permission Categories",
                    [ToolbarFilterId.PermissionActions]: "Permissions",
                    [ToolbarFilterId.PermissionActionSeverity]: "Severity",
                    [ToolbarFilterId.PermitterIds]: "Policies"
                }
            }));

    function filterEnabled(filterId: ToolbarFilterId | AwsToolbarFilterId) {
        if ((filterId === AwsToolbarFilterId.PermissionActionExcessiveness ||
            filterId === AwsToolbarFilterId.ExcessivePermissionEvaluationStartDate) &&
            !AwsTenantHelper.isAccessAdvisorEnabled(entityModel.entity, tenantModelMap)) {
            return false;
        }

        return _.isNil(filterIds) || _.includes(filterIds, filterId);
    }

    const setRoute = useSetRoute();
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1.5}
            sx={{ paddingBottom: theme.spacing(1) }}>
            <Filters
                actionsRef={filtersActionsRef}
                filterQueryParameterName="filterMap"
                sx={{ flex: 1 }}
                visibilityStorageItem={StorageHelper.customerAccessToolbarFilters(entityTypeMetadataModel.tenantType, graphDirection)}
                onFilterChanged={setFilterMap}>
                {filterEnabled(AwsToolbarFilterId.PermissionActionExcessiveness) &&
                    <Filter
                        default={true}
                        id={AwsToolbarFilterId.PermissionActionExcessiveness}
                        title={localization.filters[AwsToolbarFilterId.PermissionActionExcessiveness]()}>
                        <PermissionActionExcessivenessFilter/>
                    </Filter>}
                {filterEnabled(AwsToolbarFilterId.VendorRoles) &&
                    accessVariant === Contract.EntityAccessVariant.DestinationEntity && (
                    <Filter
                        default={true}
                        id={AwsToolbarFilterId.VendorRoles}
                        title={localization.filters[AwsToolbarFilterId.VendorRoles]()}>
                        <ValueFilter
                            items={[
                                {
                                    title: localization.filters[AwsToolbarFilterId.VendorRoles](),
                                    value: true
                                }
                            ]}
                            placeholder={localization.filters[AwsToolbarFilterId.VendorRoles]()}/>
                    </Filter>)}
                {filterEnabled(AwsToolbarFilterId.ExcessivePermissionEvaluationStartDate) &&
                    graphDirection === Contract.EntityAccessDirection.Outbound &&
                    excessivePermissionCustomEvaluationEnabled &&
                    <Filter
                        id={AwsToolbarFilterId.ExcessivePermissionEvaluationStartDate}
                        title={localization.filters[AwsToolbarFilterId.ExcessivePermissionEvaluationStartDate]()}>
                        <AwsPrincipalExcessivePermissionCustomEvaluationFilter
                            dateInfos={excessivePermissionCustomEvaluationDateInfos}
                            maxTime={excessivePermissionCustomEvaluationMaxTime}/>
                    </Filter>}
                {filterEnabled(ToolbarFilterId.OriginatorEntityTypeNames) &&
                    !_.isEmpty(filterToValuesMap[ToolbarFilterId.OriginatorEntityTypeNames]) && (
                    <Filter
                        id={ToolbarFilterId.OriginatorEntityTypeNames}
                        title={localization.filters[ToolbarFilterId.OriginatorEntityTypeNames]()}>
                        <ValuesFilter
                            placeholder={localization.filters[ToolbarFilterId.OriginatorEntityTypeNames]()}>
                            {_.map(
                                filterToValuesMap[ToolbarFilterId.OriginatorEntityTypeNames],
                                originatorEntityTypeName =>
                                    <ValuesFilterItem
                                        key={originatorEntityTypeName}
                                        title={entityTypeNameTranslator(originatorEntityTypeName)}
                                        value={originatorEntityTypeName}/>)}
                        </ValuesFilter>
                    </Filter>)}
                {_(
                    [
                        ToolbarFilterId.OriginatorEntityIds,
                        ToolbarFilterId.IdentityIds,
                        ToolbarFilterId.GroupIds,
                        ToolbarFilterId.PermitterIds
                    ]).
                    filter(
                        filterId =>
                            filterEnabled(filterId) &&
                            !_.isEmpty(filterToValuesMap[filterId])).
                    map(
                        filterId =>
                            <Filter
                                id={filterId}
                                key={filterId}
                                title={localization.filters[filterId]()}>
                                <EntityFilter
                                    entityIdsOrSearchableReferences={filterToValuesMap[filterId]}
                                    placeholder={localization.filters[filterId]()}/>
                            </Filter>).
                    value()}
                {filterEnabled(ToolbarFilterId.AccessLevels) && (
                    <Filter
                        default={true}
                        id={ToolbarFilterId.AccessLevels}
                        key={ToolbarFilterId.AccessLevels}
                        title={localization.filters[ToolbarFilterId.AccessLevels]()}>
                        <AccessLevelFilter placeholder={localization.filters[ToolbarFilterId.AccessLevels]()}/>
                    </Filter>)}
                {filterEnabled(ToolbarFilterId.DestinationEntityIds) &&
                    !_.isEmpty(filterToValuesMap[ToolbarFilterId.DestinationEntityIds]) && (
                    <Filter
                        default={true}
                        id={ToolbarFilterId.DestinationEntityIds}
                        key={ToolbarFilterId.DestinationEntityIds}
                        title={localization.filters[ToolbarFilterId.DestinationEntityIds]()}>
                        <EntityFilter
                            entityIdsOrSearchableReferences={filterToValuesMap[ToolbarFilterId.DestinationEntityIds]}
                            placeholder={localization.filters[ToolbarFilterId.DestinationEntityIds]()}/>
                    </Filter>)}
                {filterEnabled(ToolbarFilterId.DestinationResourceTenantIds) &&
                    !_.isEmpty(filterToValuesMap[ToolbarFilterId.DestinationResourceTenantIds]) && (
                    <Filter
                        id={ToolbarFilterId.DestinationResourceTenantIds}
                        key={ToolbarFilterId.DestinationResourceTenantIds}
                        title={localization.filters[ToolbarFilterId.DestinationResourceTenantIds]()}>
                        <EntityFilter
                            entityIdsOrSearchableReferences={filterToValuesMap[ToolbarFilterId.DestinationResourceTenantIds]}
                            placeholder={localization.filters[ToolbarFilterId.DestinationResourceTenantIds]()}/>
                    </Filter>)}
                {filterEnabled(ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames) && (
                    <Filter
                        id={ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames}
                        title={localization.filters[ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames]()}>
                        <EntityAttributeFilter
                            emptyValue={true}
                            entityAttributeValues={TypeHelper.getSealedAssignableTypeNames(Contract.TypeNames.BuiltInEntityAttribute)}
                            placeholder={localization.filters[ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames]()}/>
                    </Filter>)}
                {filterEnabled(ToolbarFilterId.PermissionActions) && (
                    <Filter
                        default={true}
                        id={ToolbarFilterId.PermissionActions}
                        title={localization.filters[ToolbarFilterId.PermissionActions]()}>
                        <TextValuesFilter
                            placeholder={localization.filters[ToolbarFilterId.PermissionActions]()}
                            values={filterToValuesMap[ToolbarFilterId.PermissionActions]}/>
                    </Filter>)}
                {filterEnabled(ToolbarFilterId.PermissionActionSeverity) && (
                    <Filter
                        default={true}
                        id={ToolbarFilterId.PermissionActionSeverity}
                        title={localization.filters[ToolbarFilterId.PermissionActionSeverity]()}>
                        <SeverityFilter
                            emptyValueOptions={{ enabled: false }}
                            information={false}
                            placeholder={localization.filters[ToolbarFilterId.PermissionActionSeverity]()}/>
                    </Filter>)}
                {filterEnabled(ToolbarFilterId.PermissionActionRiskCategories) && (
                    <Filter
                        default={true}
                        id={ToolbarFilterId.PermissionActionRiskCategories}
                        title={localization.filters[ToolbarFilterId.PermissionActionRiskCategories]()}>
                        <ActionRiskCategoryFilter placeholder={localization.filters[ToolbarFilterId.PermissionActionRiskCategories]()}/>
                    </Filter>)}
            </Filters>
            {!_.isNil(baseUrl) &&
                entityTypeMetadataModel.awsInboundAccessGraph &&
                entityTypeMetadataModel.awsOutboundAccessGraph &&
                !_.isNil(graphDirection) && (
                <ToggleButtonGroup
                    exclusive={true}
                    value={graphDirection}
                    onChange={(event, direction) => !_.isNil(direction) && setRoute(`${baseUrl}/${view}/${direction}`)}>
                    <ToggleButton value={Contract.EntityAccessDirection.Outbound}>
                        <OutboundIcon/>
                    </ToggleButton>
                    <ToggleButton value={Contract.EntityAccessDirection.Inbound}>
                        <InboundIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>)}
            {!_.isNil(baseUrl) &&
                !_.isNil(graphDirection) &&
                !_.isNil(view) && (
                <ToggleButtonGroup
                    exclusive={true}
                    value={view}
                    onChange={
                        (_event, view) =>
                            !_.isNil(view) &&
                                setRoute(
                                    `${baseUrl}/${view}/${graphDirection}`,
                                    undefined,
                                    { preserveSearchString: true })}>
                    <ToggleButton value={AccessView.Graph}>
                        <GraphIcon/>
                    </ToggleButton>
                    <ToggleButton value={AccessView.List}>
                        <ListIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>)}
            {!_.isNil(accessGraph) &&
                !_.isNil(csvExportButtonElement) &&
                csvExportButtonElement}
        </Stack>);
}

export enum AwsToolbarFilterId {
    ExcessivePermissionEvaluationStartDate = "excessivePermissionEvaluationStartDate",
    PermissionActionExcessiveness = "permissionActionExcessiveness",
    VendorRoles = "vendorRoles"
}