import { InlineItems, Loading, Step, useLocalization } from "@infrastructure";
import { Box, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Access, Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, useVendorNameTranslator } from "../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection, RiskDefinitionSectionCategory } from "../../../../../../../utilities";
import { ContextSection } from "./components";
import { ExternalIdPlaceholderImage } from "./images";

export function useAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const roleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel = riskModel as Contract.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel;
    const roleModel = entityModelStore.useGet(roleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel.risk.entityId) as Contract.AwsIamRoleModel;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const vendorNameTranslator = useVendorNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskDefinition",
            () => ({
                description: "External ID is not set for {{role}}",
                sections: {
                    accessGraph: "Role permissions",
                    resolution: {
                        links: {
                            trustRelationshipTab: "Trust relationships tab"
                        },
                        step1: {
                            withoutVendors: "Contact the vendor and request for your customer external ID",
                            withVendors: "Contact **{{vendorNames}}** and request for your customer external ID"
                        },
                        step2: "Click on **Edit trust policy** and update the JSON policy to include the sts:ExternalId condition as shown in the example below. Replace the **<ExternalIdPlaceholder>** with the external ID the vendor provides",
                        step3: "Click **Update policy** to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                role:
                    <Entity
                        entityIdOrModel={roleModel}
                        variant="typeText"/>
            }),
        () => [
            _.isEmpty(roleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel.vendors)
                ? localization.sections.resolution.step1.withoutVendors()
                : localization.sections.resolution.step1.withVendors({
                    vendorNames:
                        <InlineItems
                            items={
                                _.map(
                                    roleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel.vendors,
                                    vendor => vendorNameTranslator(vendor))}
                            variant="itemPlusItemCount"/>
                }),
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamRoleTrustRelationshipsUrl(roleModel.entity as Contract.AwsIamRole)),
            new Step(
                localization.sections.resolution.step2(),
                {
                    contentElement: <ExternalIdPlaceholderImage/>
                }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    riskModel={roleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel}
                    roleModel={roleModel}/>,
            sections:
                roleModel.entity.systemDeleted
                    ? undefined
                    : [
                        new RiskDefinitionSection(
                            <Box sx={{ minHeight: theme.spacing(20) }}>
                                <Loading>
                                    <Access
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(roleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel.risk.id, { category: RiskDefinitionSectionCategory.Overview })}
                                        entityId={roleModel.id}
                                        scope={Contract.EntityAccessScope.Full}
                                        tenantType={Contract.TenantType.Aws}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            localization.sections.accessGraph(),
                            {
                                profilePageOnly: true
                            })
                    ]
        });
}