import { EmptyMessage, makeContextProvider, StringHelper, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { StorageHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ConfigurationView } from "../../utilities";
import { Breadcrumbs, Category, Filters } from "./components";
import { useItems } from "./hooks";
import { IntegrationItemCategory } from "./utilities";

export class IntegrationsContext {
    constructor(
        public childScopeEnabled: boolean) {
    }
}

export const [useIntegrationsContext, useSetIntegrationsContext, useIntegrationsContextProvider] = makeContextProvider<IntegrationsContext>();

export function Integrations() {
    const { useViewRoute } = useScopeNavigationViewContext();
    const [{ childScopeEnabled }, setIntegrationsContext, ContextProvider] = useIntegrationsContextProvider(() => new IntegrationsContext(!StringHelper.isFalse(StorageHelper.customerConfigurationIntegrationsFlatView.getValue())));
    const items = useItems(childScopeEnabled);
    const integrationsViewNames =
        _.map(
            items,
            item => item.viewName);
    const [integrationViewName, setIntegrationViewName] = useViewRoute(`${ConfigurationView.Integrations}/{view}`, integrationsViewNames, true);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations",
            () => ({
                category: {
                    [IntegrationItemCategory.CiIntegration]: {
                        description: "Connect your container registries",
                        title: "Container Registries"
                    },
                    [IntegrationItemCategory.CloudProviderTenantOrganizations]: {
                        description: "Onboard your cloud organizations.",
                        title: "Cloud Providers"
                    },
                    [IntegrationItemCategory.CodeOrganizations]: {
                        description: "Scan IaC to address configuration issues before deployment and trace resources back to their code origins.",
                        title: "Code Repositories"
                    },
                    [IntegrationItemCategory.CodePipelines]: {
                        description: "Scan IaC to address configuration issues before deployment and trace resources back to their code origins.\nScan container images to identify software vulnerabilities.",
                        title: "CI/CD"
                    },
                    [IntegrationItemCategory.CodeServers]: {
                        description: "Connect your self-hosted, enterprise code repository servers.",
                        title: "Code Repository Servers"
                    },
                    [IntegrationItemCategory.Collaboration]: {
                        description: "Send push notifications with specific Tenable Cloud Security findings. Request/approve JIT access natively in your tools.",
                        title: "Collaboration"
                    },
                    [IntegrationItemCategory.Data]: {
                        description: "Enrich your understanding of data resources in Tenable Cloud Security with additional context.",
                        title: "Data Security"
                    },
                    [IntegrationItemCategory.EndpointConnectors]: {
                        description: "Connect your network-isolated services to allow integration with Tenable Cloud Security.",
                        title: "Network"
                    },
                    [IntegrationItemCategory.IdentityProviderTenants]: {
                        description: "Reveal a complete inventory of federated identities associated with your cloud accounts.",
                        title: "Identity Providers"
                    },
                    [IntegrationItemCategory.Kubernetes]: {
                        description: "Connect your Kubernetes clusters.",
                        title: "Kubernetes"
                    },
                    [IntegrationItemCategory.Observability]: {
                        description: "Send push notifications with specific Tenable Cloud Security findings.",
                        title: "SIEM"
                    },
                    [IntegrationItemCategory.Ticketing]: {
                        description: "Create issues related to specific Tenable Cloud Security findings.",
                        title: "Ticketing and ITSM"
                    },
                    [IntegrationItemCategory.Webhooks]: {
                        description: "Send push notifications with specific Tenable Cloud Security findings to any destination using this generic method.",
                        title: "Webhooks"
                    }
                },
                empty: "No integrations found"
            }));

    const [includeEmptyItems, setIncludeEmptyItems] = useState(true);
    const [searchText, setSearchText] = useState("");
    const filteredItems =
        useMemo(
            () =>
                _.filter(
                    items,
                    item =>
                        (includeEmptyItems || !_.isEmpty(item.configurations)) &&
                        (StringHelper.search(item.title, searchText) ||
                            StringHelper.search(localization.category[item.category as IntegrationItemCategory].title(), searchText))),
            [items, searchText, includeEmptyItems]);

    const [filtersBorder, setFiltersBorder] = useState(false);
    const theme = useTheme();
    const selectedItem =
        _.find(
            items,
            item => item.viewName === integrationViewName);
    return selectedItem
        ? <Stack sx={{ height: "100%" }}>
            <Breadcrumbs
                item={selectedItem}
                onIntegrationsClick={() => setIntegrationViewName("")}/>
            <Stack
                sx={{
                    height: "100%",
                    overflow: "hidden"
                }}>
                <ContextProvider>
                    {selectedItem.viewElement}
                </ContextProvider>
            </Stack>
        </Stack>
        : <Stack
            sx={{
                height: "100%",
                overflow: "hidden"
            }}>
            <Filters
                childScopeEnabled={childScopeEnabled}
                includeEmptyItems={includeEmptyItems}
                searchText={searchText}
                onChildScopeEnabledChanged={
                    childScopeEnabled => {
                        StorageHelper.customerConfigurationIntegrationsFlatView.setValue(childScopeEnabled);
                        setIntegrationsContext(
                            context => ({
                                ...context,
                                childScopeEnabled
                            }));
                    }}
                onIncludeEmptyItemsChanged={includeEmptyItems => setIncludeEmptyItems(includeEmptyItems)}
                onSearchTextChanged={searchText => setSearchText(searchText)}/>
            {_.isEmpty(filteredItems)
                ? <EmptyMessage
                    message={localization.empty()}
                    verticalCenter={true}/>
                : <Stack
                    spacing={1.5}
                    sx={{
                        borderTop:
                            filtersBorder
                                ? theme.border.primary
                                : "solid 1px transparent",
                        overflow: "auto",
                        paddingTop: theme.spacing(2)
                    }}
                    onScroll={event => setFiltersBorder(event.currentTarget?.scrollTop > 0)}>
                    {_(filteredItems).
                        groupBy(filteredItem => filteredItem.category).
                        map(
                            (filteredGroupItems, category) =>
                                <Box
                                    id={category}
                                    key={category}
                                    sx={{ padding: theme.spacing(0, 3) }}>
                                    <Category
                                        description={localization.category[category as IntegrationItemCategory].description()}
                                        items={filteredGroupItems}
                                        title={localization.category[category as IntegrationItemCategory].title()}
                                        onClick={item => setIntegrationViewName(item.viewName)}/>
                                </Box>).
                        value()}
                </Stack>}
        </Stack>;
}