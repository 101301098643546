﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "./useOciDefaultResourceInfoItemElements";

export function useOciAutoscalingAutoscalingConfigurationDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const autoscalingConfigurationModel = resourceModel as Contract.OciAutoscalingAutoscalingConfigurationModel;
    const autoscalingConfiguration = autoscalingConfigurationModel.entity as Contract.OciAutoscalingAutoscalingConfiguration;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciAutoscalingAutoscalingConfigurationDefinition",
            () => ({
                info: {
                    items: {
                        enabled: {
                            false: "Disabled",
                            title: "Status",
                            true: "Enabled"
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="enabled"
                        location="all"
                        title={localization.info.items.enabled.title()}
                        value={
                            autoscalingConfiguration.enabled
                                ? localization.info.items.enabled.true()
                                : localization.info.items.enabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={autoscalingConfigurationModel.instancePoolIdReference}
                        entityTypeName={Contract.TypeNames.OciComputeInstancePool}
                        key="instancePoolIdReference"
                        location="all"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstancePool)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}