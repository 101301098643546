﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceDefinition, useGcpCommonScopeResourceDefinition, useGcpCommonServiceAccountOriginatorScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { useCommonKubernetesClusterDefinition } from "..";
import { Contract, CustomerConsoleAppUrlHelper, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpContainerClusterStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpContainerClusterDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useGcpCommonEncryptedResourceDefinition(definitionData);
    const commonKubernetesClusterDefinition = useCommonKubernetesClusterDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const commonServiceAccountOriginatorScopeResourceDefinition = useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const containerClusterStatusTranslator = useGcpContainerClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpContainerClusterDefinition",
            () => ({
                columns: {
                    mode: {
                        title: "Mode",
                        [Contract.TypeNames.GcpContainerClusterMode]: {
                            [Contract.GcpContainerClusterMode.Autopilot]: "Autopilot",
                            [Contract.GcpContainerClusterMode.Standard]: "Standard"
                        }
                    },
                    nodePublicIpAddressExists: {
                        false: "Private",
                        title: "Cluster Type",
                        true: "Public"
                    },
                    publicIpAddress: "Public Endpoint",
                    status: "Status",
                    version: "Kubernetes Version",
                    vpcIdReference: "VPC Network"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpContainerClusterModel) => ({
                            [localization.columns.status()]: containerClusterStatusTranslator((item.entity as Contract.GcpContainerCluster).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpContainerClusterStatus}
                                enumTypeTranslator={containerClusterStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpContainerClusterStatus}
                itemProperty={(item: Contract.GcpContainerClusterModel) => containerClusterStatusTranslator((item.entity as Contract.GcpContainerCluster).status)}
                key={Contract.EntityModelProperty.GcpContainerClusterStatus}
                title={localization.columns.status()}/>,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            commonServiceAccountOriginatorScopeResourceDefinition.columns.serviceAccountColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpContainerClusterModel) => ({
                            [localization.columns.mode.title()]: localization.columns.mode[Contract.TypeNames.GcpContainerClusterMode][(item.entity as Contract.GcpContainerCluster).mode]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpContainerClusterMode}
                                enumTypeTranslator={(mode: Contract.GcpContainerClusterMode) => localization.columns.mode[Contract.TypeNames.GcpContainerClusterMode][mode]()}
                                placeholder={localization.columns.mode.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpContainerClusterMode}
                itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.GcpContainerCluster).mode}
                key={Contract.EntityModelProperty.GcpContainerClusterMode}
                title={localization.columns.mode.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpContainerClusterModel) => ({
                            [localization.columns.nodePublicIpAddressExists.title()]:
                                (item.entity as Contract.GcpContainerCluster).network.nodePublicIpAddressExists
                                    ? localization.columns.nodePublicIpAddressExists.true()
                                    : localization.columns.nodePublicIpAddressExists.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.nodePublicIpAddressExists.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.nodePublicIpAddressExists.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.nodePublicIpAddressExists.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpContainerClusterNodePublicIpAddressExists}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.GcpContainerCluster).network.nodePublicIpAddressExists
                            ? localization.columns.nodePublicIpAddressExists.true()
                            : localization.columns.nodePublicIpAddressExists.false()}
                key={Contract.EntityModelProperty.GcpContainerClusterNodePublicIpAddressExists}
                title={localization.columns.nodePublicIpAddressExists.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpContainerClusterModel) => ({
                            [localization.columns.version()]: (item.entity as Contract.GcpContainerCluster).version
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.KubernetesClusterVersion)}
                                placeholder={localization.columns.version()}/>
                    }
                }}
                id={Contract.EntityModelProperty.KubernetesClusterVersion}
                itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.GcpContainerCluster).version}
                key={Contract.EntityModelProperty.KubernetesClusterVersion}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.version()}/>,
            commonKubernetesClusterDefinition.columns.createApiConnectivityStatusColumn(CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl()),
            commonKubernetesClusterDefinition.columns.resourceUpdateTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpContainerClusterModel) => ({
                            [localization.columns.publicIpAddress()]: (item.entity as Contract.GcpContainerCluster).network.publicIpAddress
                        })
                }}
                id="publicIpAddress"
                key="publicIpAddress"/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpContainerClusterModel>(
                        Contract.TypeNames.GcpComputeVpc,
                        item => [item.vpcIdReference],
                        localization.columns.vpcIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpContainerClusterVpc)}
                                placeholder={localization.columns.vpcIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpContainerClusterVpc}
                key={Contract.EntityModelProperty.GcpContainerClusterVpc}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpContainerClusterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vpcIdReference}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            entityVariant="iconText"/>}
                title={localization.columns.vpcIdReference()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionKeyColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpContainerClusterRequest(
                new Contract.EntityControllerGetEntityModelPageGcpContainerClusterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesClusterApiConnectivityStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpContainerClusterMode]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpContainerClusterNodePublicIpAddressExists]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.KubernetesClusterResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpContainerClusterStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesClusterVersion]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpContainerClusterVpc])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount]))));
}