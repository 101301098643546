import { Store } from "@infrastructure";
import { ConfigurationController, Contract } from "..";

export const customEntityAttributeDefinitionModelStore =
    new Store<Contract.CustomEntityAttributeDefinitionModel, never, never>(
        customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id,
        {
            getAll:
                async () => {
                    const { customEntityAttributeDefinitionModels } = await ConfigurationController.getCustomEntityAttributeDefinitionModels();
                    return customEntityAttributeDefinitionModels;
                }
        });