import { Shadows } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Severity } from "../../../../../../../controllers/types.generated";
import { useTheme } from "../../../../../../../themes";

interface SeverityItemProps {
    className?: string;
    icon?: React.ReactNode;
    info?: React.ReactNode;
    severity: Severity;
    subTitles?: React.ReactNode[];
    title: React.ReactNode;
}

export function SeverityItem({ className, icon, info, severity, subTitles, title }: SeverityItemProps) {
    const [hover, setHover] = useState(false);

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            className={className}
            direction="row"
            sx={{
                border: theme.border.primary,
                borderLeft: "none",
                borderRadius: theme.spacing(0.75),
                boxShadow:
                    hover
                        ? theme.shadows[Shadows.Highlight]
                        : undefined
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <Box
                sx={{
                    backgroundColor: theme.palette.severity(severity),
                    borderBottomLeftRadius: theme.spacing(1),
                    borderTopLeftRadius: theme.spacing(1),
                    height: theme.spacing(7.75),
                    width: theme.spacing(0.5)
                }}/>
            {icon &&
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        margin: theme.spacing(0, 1),
                        padding: theme.spacing(0.5)
                    }}>
                    {icon}
                </Stack>}
            <Divider
                flexItem={true}
                orientation="vertical"
                sx={{ margin: theme.spacing(1.7, 0) }}
                variant="middle"/>
            <Stack
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    padding: theme.spacing(1.5)
                }}>
                <Box
                    sx={{
                        overflow: "hidden",
                        paddingBottom: theme.spacing(0.25)
                    }}>
                    <Typography
                        noWrap={true}
                        sx={{
                            fontSize: "13px",
                            fontWeight: 600,
                            lineHeight: 1.2
                        }}>
                        {title}
                    </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Stack
                        alignItems="center"
                        direction="row">
                        {_.map(
                            subTitles,
                            (subTitle, index) =>
                                <Fragment key={index}>
                                    {subTitle}
                                    {index + 1 !== _.size(subTitles) && <Separator/>}
                                </Fragment>)}
                    </Stack>
                </Box>
            </Stack>
            {info}
        </Stack>);
}

function Separator() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                borderLeft: theme.border.primary,
                height: "1.3rem",
                margin: theme.spacing(0, 0.8)
            }}/>);
}