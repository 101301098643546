import { DataTableColumn, DataTableColumnRenderProps, InlineItems, Link, map, Optional, StringHelper, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useGetCloudProviderTenantOrganizationByScopeNodeModel } from "..";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, tenantModelStore, UrlHelper } from "../../../../../../../../common";
import { useGcpTenantModelStatusTranslator } from "../../../../../../../../tenants";
import { ScopeRawId } from "../../components";
import { TenantsDefinition } from "../../useDefinition";
import { useRenderTenant } from "../useRenderTenant";
import { StatusCell } from "./components";
import { useGcpCommonDefinitionData } from "./useGcpCommonDefinitionData";

export function useGcpDefinition(): TenantsDefinition {
    const getCloudProviderTenantOrganizationByScopeNodeModel = useGetCloudProviderTenantOrganizationByScopeNodeModel();
    const tenantModelMap = tenantModelStore.useGetGcpTenantMap();
    const { gciTenantIdToServiceAccountTenantRawShortNameIdOrModel, getSinkPubSubSubscriptionRawId } = useGcpCommonDefinitionData();
    const { renderTenantCell, renderTenantCsvItem } = useRenderTenant<Contract.GcpTenantModel>();

    const tenantModelStatusTranslator = useGcpTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition",
            () => ({
                actions: {
                    delete: "delete the entire organization",
                    deleteOrganizationFolder: "This folder belongs to an organization which is configured to automatically onboard new projects, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its accounts and folders), or exclude this specific account by {{supportLink}}.",
                    deleteOrganizationTenant: "This project/s belongs to an organization which is configured to automatically onboard new projects, and can't be deleted. Instead, you can either {{deleteLink}} (including all its accounts), or exclude this specific account by {{supportLink}}",
                    deleteSelection: "Some/All of the selected projects and/or folders belong to an organization which is configured to automatically onboard new projects, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its projects and folders), or exclude this specific projects by {{supportLink}}.",
                    supportLink: "contacting Support"
                },
                columns: {
                    rawShortNameId: "ID",
                    rawShortNumberId: "Number",
                    serviceAccountAssignedRoleNames: "Service Account Roles",
                    sinkPubSubSubscriptionRawId: "Sink PubSub Subscription",
                    status: "Status"
                },
                title: "GCP Projects"
            }));

    return useMemo(
        () => ({
            filter:
                (filterMap, scopeNodeModel) => {
                    if (ScopeHelper.isFolder(scopeNodeModel)) {
                        const folderRawId = (scopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration).organization?.folderRawId;
                        if (!_.isNil(filterMap[TenantTableColumnId.RawShortNameId]) &&
                            !_.isNil(folderRawId) &&
                            !_.includes(
                                filterMap[TenantTableColumnId.RawShortNameId].values,
                                folderRawId)) {
                            return false;
                        }

                        return true;
                    }

                    const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                    if (_.isNil(tenantModel)) {
                        return true;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.RawShortNameId]) &&
                        !_.includes(filterMap[TenantTableColumnId.RawShortNameId].values, tenantModel.configuration.rawShortNameId)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.RawShortNumberId]) &&
                        !_.includes(filterMap[TenantTableColumnId.RawShortNumberId].values, tenantModel.configuration.rawShortNumberId)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.ServiceAccountAssignedRoleNames]) &&
                        _(filterMap[TenantTableColumnId.ServiceAccountAssignedRoleNames].values).
                            intersection(tenantModel.configuration.serviceAccountAssignedRoleNames).
                            isEmpty()) {
                        return false;
                    }


                    if (!_.isNil(filterMap[TenantTableColumnId.SinkPubSubSubscriptionRawId]) &&
                        !_.includes(filterMap[TenantTableColumnId.SinkPubSubSubscriptionRawId].values, getSinkPubSubSubscriptionRawId(tenantModel))) {
                        return false;
                    }


                    if (!_.isNil(filterMap[TenantTableColumnId.Status]) &&
                        !_.includes(filterMap[TenantTableColumnId.Status].values, tenantModel.status)) {
                        return false;
                    }

                    return true;
                },
            getColumns: scopeNodeModels => {
                const rawShortNameIds: string[] = [];
                const rawShortNumberIds: string[] = [];
                const serviceAccountAssignedRoleNames = new Set<string>();
                const sinkPubSubSubscriptionRawIds = new Set<string>();
                const statuses = new Set<Contract.GcpTenantModelStatus>();
                _.forEach(
                    scopeNodeModels,
                    scopeNodeModel => {
                        const folderRawId = (scopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration)?.organization?.folderRawId;
                        if (!_.isNil(folderRawId) && ScopeHelper.isFolder(scopeNodeModel)) {
                            rawShortNameIds.push(folderRawId);
                        } else if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                            const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                            rawShortNameIds.push(tenantModel.configuration.rawShortNameId);
                            rawShortNumberIds.push(tenantModel.configuration.rawShortNumberId);
                            tenantModel.configuration.serviceAccountAssignedRoleNames.forEach(serviceAccountAssignedRoleName => serviceAccountAssignedRoleNames.add(serviceAccountAssignedRoleName));
                            statuses.add(tenantModel.status);
                            const sinkPubSubSubscriptionRawId = getSinkPubSubSubscriptionRawId(tenantModel);
                            if (!_.isNil(sinkPubSubSubscriptionRawId)) {
                                sinkPubSubSubscriptionRawIds.add(sinkPubSubSubscriptionRawId);
                            }
                        }
                    });

                return [
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                    [localization.columns.rawShortNameId()]:
                                        renderTenantCsvItem(
                                            tenantModel => tenantModel.configuration.rawShortNameId,
                                            scopeNodeModel)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.rawShortNameId()}
                                        values={rawShortNameIds}/>
                            }
                        }}
                        id={TenantTableColumnId.RawShortNameId}
                        key={TenantTableColumnId.RawShortNameId}
                        render={
                            (scopeNodeModel: DataTableColumnRenderProps<Contract.ScopeNodeModel>) =>
                                <ScopeRawId
                                    fallbackRender={renderTenantCell(tenantModel => tenantModel.configuration.rawShortNameId)}
                                    scopeNodeModel={scopeNodeModel}/>}
                        title={localization.columns.rawShortNameId()}/>,
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                    [localization.columns.rawShortNumberId()]:
                                        renderTenantCsvItem(
                                            tenantModel => tenantModel.configuration.rawShortNumberId,
                                            scopeNodeModel)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.rawShortNumberId()}
                                        values={rawShortNumberIds}/>
                            }
                        }}
                        id={TenantTableColumnId.RawShortNumberId}
                        key={TenantTableColumnId.RawShortNumberId}
                        render={renderTenantCell(tenantModel => tenantModel.configuration.rawShortNumberId)}
                        title={localization.columns.rawShortNumberId()}/>,
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                    [localization.columns.serviceAccountAssignedRoleNames()]:
                                        renderTenantCsvItem(
                                            tenantModel => tenantModel.configuration.serviceAccountAssignedRoleNames.join("\n"),
                                            scopeNodeModel)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.serviceAccountAssignedRoleNames()}
                                        values={Array.from(serviceAccountAssignedRoleNames)}/>
                            }
                        }}
                        id={TenantTableColumnId.ServiceAccountAssignedRoleNames}
                        key={TenantTableColumnId.ServiceAccountAssignedRoleNames}
                        render={
                            renderTenantCell(
                                tenantModel =>
                                    <InlineItems
                                        items={tenantModel.configuration.serviceAccountAssignedRoleNames}
                                        variant="itemPlusItemCount"/>)}
                        title={localization.columns.serviceAccountAssignedRoleNames()}/>,
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                    [localization.columns.sinkPubSubSubscriptionRawId()]:
                                        renderTenantCsvItem(
                                            tenantModel => getSinkPubSubSubscriptionRawId(tenantModel),
                                            scopeNodeModel)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.sinkPubSubSubscriptionRawId()}
                                        values={Array.from(sinkPubSubSubscriptionRawIds)}/>
                            }
                        }}
                        id={TenantTableColumnId.SinkPubSubSubscriptionRawId}
                        key={TenantTableColumnId.SinkPubSubSubscriptionRawId}
                        render={renderTenantCell(tenantModel => getSinkPubSubSubscriptionRawId(tenantModel))}
                        title={localization.columns.sinkPubSubSubscriptionRawId()}/>,
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                    [localization.columns.status()]:
                                        renderTenantCsvItem(
                                            tenantModel => tenantModelStatusTranslator(tenantModel.status),
                                            scopeNodeModel)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <ValuesFilter placeholder={localization.columns.status()}>
                                        {_.map(
                                            Array.from(statuses),
                                            status => (
                                                <ValuesFilterItem
                                                    key={status}
                                                    title={tenantModelStatusTranslator(status)}
                                                    value={status}/>))}
                                    </ValuesFilter>
                            }
                        }}
                        id={TenantTableColumnId.Status}
                        key={TenantTableColumnId.Status}
                        render={
                            renderTenantCell(
                                tenantModel =>
                                    <StatusCell
                                        serviceAccountTenantRawShortNameIdOrModel={gciTenantIdToServiceAccountTenantRawShortNameIdOrModel[tenantModel.configuration.gciTenantId]}
                                        tenantModel={tenantModel}/>)}
                        title={localization.columns.status()}/>
                ];
            },
            getDeleteScopeDisabledMessage:
                (selection, scopeNodeModel) => {
                    const organizationConfiguration = getCloudProviderTenantOrganizationByScopeNodeModel(scopeNodeModel)?.configuration as Optional<Contract.CloudProviderTenantOrganizationConfiguration>;
                    if (scopeNodeModel.type === Contract.ScopeType.Folder &&
                        (scopeNodeModel.configuration as Contract.GcpFolderConfiguration).managed) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationFolder)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsGcpRelativeUrl()}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    } else if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant &&
                        organizationConfiguration?.enabled === true) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationTenant)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsGcpRelativeUrl()}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    }

                    return undefined;
                },
            sort:
                (columnId, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                        const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                        return map<string, any>(
                            columnId,
                            {
                                [TenantTableColumnId.RawShortNameId]: () => StringHelper.getSortValue(tenantModel.configuration.rawShortNameId),
                                [TenantTableColumnId.RawShortNumberId]: () => StringHelper.getSortValue(tenantModel.configuration.rawShortNumberId),
                                [TenantTableColumnId.ServiceAccountAssignedRoleNames]: () => _.size(tenantModel.configuration.serviceAccountAssignedRoleNames),
                                [TenantTableColumnId.SinkPubSubSubscriptionRawId]: () => StringHelper.getSortValue(getSinkPubSubSubscriptionRawId(tenantModel)),
                                [TenantTableColumnId.Status]: () => StringHelper.getSortValue(tenantModelStatusTranslator(tenantModel.status))
                            });
                    } else {
                        return undefined;
                    }
                },
            title: localization.title()
        }),
        [gciTenantIdToServiceAccountTenantRawShortNameIdOrModel, getSinkPubSubSubscriptionRawId, getCloudProviderTenantOrganizationByScopeNodeModel, localization, tenantModelMap]);
}

enum TenantTableColumnId {
    RawShortNameId = "rawShortNameId",
    RawShortNumberId = "rawShortNumberId",
    ServiceAccountAssignedRoleNames = "serviceAccountAssignedRoleNames",
    SinkPubSubSubscriptionRawId = "sinkPubSubSubscriptionRawId",
    Status = "status"
}