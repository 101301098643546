﻿import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { RiskContentProps } from "../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, usePrincipalModelAccess, useRiskSeverityReasonTranslator } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useOpenRiskedEntityRisksStep } from "../../../../../useOpenRiskedEntityRisksStep";
import { EntityExternalConsoleLink } from "../../../../components";
import { RoleBindingTable } from "../components";

export function useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPartialServiceAccountDefinition(riskModel: Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskModel) {
    const risk = riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk;
    const partialServiceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamPartialServiceAccountModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPartialServiceAccountDefinition",
            () => ({
                description: {
                    roleIds: [
                        "1 high severity role",
                        "{{count | NumberFormatter.humanize}} high severity role"
                    ],
                    text: "Service account {{partialServiceAccountId}} has {{roleIds}} assigned at the project level or above"
                },
                sections: {
                    resolution: {
                        step1: "Before deleting the admin role bindings, assign least privilege role bindings according to the actual used permissions",
                        step2: "{{entityExternalConsoleLink}} and filter on **Principal:{{partialServiceAccountMail}}**",
                        step3: "Under **Permissions** delete the role bindings to {{roleIds}}"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            partialServiceAccountId:
                <Entity
                    entityIdOrModel={partialServiceAccountModel}
                    variant="text"/>,
            roleIds:
                <InlineEntities
                    entityIdsOrModels={riskModel.roleIds}
                    entityTypeName={Contract.TypeNames.GcpIamRole}
                    namePluralizer={localization.description.roleIds}
                    variant="itemAndTypeOrItemCountAndType"/>
        }),
        () =>
            _.concat(
                localization.sections.resolution.step1(),
                _(risk.orderedResourceManagerResourceIds).
                    flatMap(
                        resourceManagerResourceId => [
                            localization.sections.resolution.step2({
                                entityExternalConsoleLink:
                                    <EntityExternalConsoleLink
                                        entityId={resourceManagerResourceId}
                                        page={Contract.GcpConsolePage.Permissions}/>,
                                partialServiceAccountMail: risk.principalMail
                            }),
                            localization.sections.resolution.step3({
                                roleIds:
                                    <InlineEntities
                                        entityIdsOrModels={risk.resourceManagerResourceIdToRoleIdsMap[resourceManagerResourceId]}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })
                        ]).
                    value()),
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <RoleBindingTable
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const risk = riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk;
    const partialServiceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamPartialServiceAccountModel;
    const partialServiceAccountModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(partialServiceAccountModel.id);
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(partialServiceAccountModel, risk.id);
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPartialServiceAccountDefinition.contextSection",
            () => ({
                originatorResourceIds: "The service account is assigned to {{originatorResourceIds}}",
                partialServiceAccount: {
                    withGroupIds: "Service account {{partialServiceAccountId}} is an external service account and part of {{groupIds}}",
                    withoutGroupIds: "Service account {{partialServiceAccountId}} is an external service account"
                }
            }));


    return (
        <Steps>
            {_.filter([
                (_.isEmpty(partialServiceAccountModel.groupIds)
                    ? localization.partialServiceAccount.withoutGroupIds
                    : localization.partialServiceAccount.withGroupIds)({
                    groupIds:
                        <InlineEntities
                            entityIdsOrModels={partialServiceAccountModel.groupIds}
                            entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                            variant="itemCountAndType"/>,
                    partialServiceAccountId:
                        <Entity
                            entityIdOrModel={partialServiceAccountModel}
                            variant="text"/>
                }),
                _.isEmpty(partialServiceAccountModelAccess.originatorEntityIds)
                    ? undefined
                    : localization.originatorResourceIds({
                        originatorResourceIds:
                            <InlineEntities
                                entityIdsOrModels={partialServiceAccountModelAccess.originatorEntityIds}
                                entityTypeName={Contract.TypeNames.GcpScopeResource}
                                variant="itemAndTypeOrItemCountAndType"/>
                    }),
                openRiskedEntityRisksStep,
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!)
            ])}
        </Steps>);
}