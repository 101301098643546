﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonUnmanagedKubernetesClusterInfoElements } from "..";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../../common";
import { GcpEntityModel, GcpUnmanagedKubernetesClusterModel } from "../../../../../../../../../../common/controllers/types.generated";
import { Info } from "../../../../components";
import { KubernetesClusterResourcesInfoCard } from "../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";
import { useGcpCommonEntityInfoItemElements } from "./useGcpCommonEntityInfoItemElements";

export function useGcpUnmanagedKubernetesClusterDefinition(entityModel: GcpEntityModel, options?: DefinitionOptions) {
    const clusterModel = entityModel as GcpUnmanagedKubernetesClusterModel;
    const commonEntityInfoItemElements = useGcpCommonEntityInfoItemElements(entityModel);
    const commonUnmanagedKubernetesClusterInfoElements = useCommonUnmanagedKubernetesClusterInfoElements(clusterModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpUnmanagedKubernetesClusterDefinition",
            () => ({
                tabs: {
                    resources: "Resources"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesClusterResourcesInfoCard clusterId={clusterModel.entity.id}/>,
                localization.tabs.resources(),
                "resources")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={[
                    commonEntityInfoItemElements.typeNameInfoElement,
                    commonEntityInfoItemElements.tenantInfoItemElement,
                    commonUnmanagedKubernetesClusterInfoElements.creationTime,
                    commonEntityInfoItemElements.syncTimeInfoItemElement
                ]}
                entityPropertyInfoItemElements={[
                    commonUnmanagedKubernetesClusterInfoElements.version,
                    commonUnmanagedKubernetesClusterInfoElements.createApiConnectivityStatusInfoItemElement(CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl())
                ]}
                options={options?.infoOptions}/>
    });
}