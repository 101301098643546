import { map, NoneIcon } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../controllers";
import { useTheme } from "../../../../themes";
import { PciIcon, PhiIcon, PiiIcon, SecretsIcon } from "./icons";

type DataCategoryIconProps =
    SvgIconProps & {
        category?: Contract.DataCategory;
    };

export function DataCategoryIcon({ category, ...props }: DataCategoryIconProps) {
    const theme = useTheme();
    return (
        _.isNil(category)
            ? <NoneIcon
                {...props}
                sx={{
                    color: theme.palette.text.secondary,
                    ...props?.sx
                }}/>
            : map(
                category,
                {
                    [Contract.DataCategory.Pci]: () => <PciIcon {...props}/>,
                    [Contract.DataCategory.Phi]: () => <PhiIcon {...props}/>,
                    [Contract.DataCategory.Pii]: () => <PiiIcon {...props}/>,
                    [Contract.DataCategory.Secrets]: () => <SecretsIcon {...props}/>
                }));
}