import { DirectedGraphModelNodeContentSize, NodeContentContextMenu, Optional, useDirectedGraphNodeContext } from "@infrastructure";
import { Typography } from "@mui/material";
import React, { MutableRefObject } from "react";
import { Contract, NetworkScopeFormatter, NetworkScopeHelper, useNodeContentFilterContextMenuItems, useTheme } from "../../../../../../..";
import { ToolbarActions, ToolbarFilterId } from "../../../..";

type ScopeProps = {
    scope: Contract.NetworkGraphDestinationGroupScope;
    size: DirectedGraphModelNodeContentSize;
    toolbarActionsRef: MutableRefObject<Optional<ToolbarActions>>;
};

export function Scope({ scope, size, toolbarActionsRef }: ScopeProps) {
    const { modelNode } = useDirectedGraphNodeContext();
    const filterContextMenuItems =
        useNodeContentFilterContextMenuItems(
            1,
            NetworkScopeFormatter.networkScope(
                scope.destinationPortRange,
                scope.protocolRange),
            type =>
                toolbarActionsRef.current!.filter(
                    ToolbarFilterId.DestinationScopeFilterIds,
                    type,
                    [scope.filterId]));
    const theme = useTheme();
    return (
        <NodeContentContextMenu items={filterContextMenuItems}>
            <rect
                fill={theme.palette.background.paper}
                height={size.height}
                rx="4px"
                stroke={
                    modelNode.options.appearance === "highlighted"
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary}
                strokeDasharray={8}
                width={size.width}
                x={0}
                y={0}/>
            <foreignObject
                height={size.height}
                width={size.width}>
                <Typography
                    noWrap={true}
                    sx={{
                        color:
                            NetworkScopeHelper.isSensitive(scope.destinationPortRange, scope.protocolRange)
                                ? theme.palette.error.main
                                : theme.palette.text.primary,
                        fontSize: "9px",
                        padding: theme.spacing(1.5, 0),
                        textAlign: "center"
                    }}>
                    {NetworkScopeFormatter.networkScope(
                        scope.destinationPortRange,
                        scope.protocolRange)}
                </Typography>
            </foreignObject>
        </NodeContentContextMenu>);
}