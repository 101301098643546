import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../..";

export function RejectedIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path d="M0.000425775 11.92C0.0537573 5.36002 5.41357 0 12 0C18.6931 0.0533328 24.0529 5.46665 23.9996 12.08C23.9463 18.64 18.5865 24 12 24H11.92C8.72013 23.9733 5.7069 22.72 3.46697 20.4267C1.20038 18.16 -0.02624 15.12 0.000425775 11.92Z"/>
            <path
                d="M16.7965 8.2794C17.0932 7.98274 17.0932 7.50177 16.7965 7.20511C16.4999 6.90846 16.0189 6.90846 15.7222 7.20511L12.0003 10.927L8.27842 7.20511C7.98176 6.90846 7.50079 6.90846 7.20413 7.20511C6.90748 7.50177 6.90748 7.98274 7.20413 8.2794L10.926 12.0013L7.20413 15.7232C6.90748 16.0199 6.90748 16.5008 7.20413 16.7975C7.50079 17.0941 7.98176 17.0941 8.27842 16.7975L12.0003 13.0756L15.7222 16.7975C16.0189 17.0941 16.4999 17.0941 16.7965 16.7975C17.0932 16.5008 17.0932 16.0199 16.7965 15.7232L13.0746 12.0013L16.7965 8.2794Z"
                fill={theme.palette.background.paper}/>
        </SvgIcon>);
}