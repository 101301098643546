import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ReportsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M7.42072 3.16496C6.23809 3.32393 5.57682 3.6194 5.09811 4.09811C4.61944 4.57677 4.32395 5.23824 4.16496 6.42109C4.00212 7.63261 4 9.23274 4 11.5C4 13.7678 4.00212 16.3679 4.16496 17.5793C4.32393 18.7619 4.6194 19.4232 5.09811 19.9019C5.57677 20.3806 6.23824 20.6761 7.42109 20.835C8.63261 20.9979 10.2327 21 12.5 21C14.7678 21 16.3679 20.9979 17.5793 20.835C18.7619 20.6761 19.4232 20.3806 19.9019 19.9019C20.3806 19.4232 20.6761 18.7618 20.835 17.5789C20.9979 16.3674 21 13.7673 21 11.5C21 9.23223 20.9979 7.63212 20.835 6.42072C20.6761 5.23809 20.3806 4.57682 19.9019 4.09811C19.4232 3.61944 18.7618 3.32395 17.5789 3.16496C16.3674 3.00212 14.7673 3 12.5 3C10.2322 3 8.63212 3.00212 7.42072 3.16496ZM12.4255 1H12.5744C14.7503 0.999977 16.4851 0.999958 17.8453 1.18279C19.2497 1.37155 20.4038 1.77156 21.3161 2.68389C22.2284 3.59618 22.6284 4.74991 22.8172 6.15428C23 7.51441 23 9.24925 23 11.4256V11.5744C23 13.7503 23 16.4851 22.8172 17.8453C22.6284 19.2497 22.2284 20.4038 21.3161 21.3161C20.4038 22.2284 19.2501 22.6284 17.8457 22.8172C16.4856 23 14.7508 23 12.5745 23H12.4256C10.2497 23 8.51491 23 7.15466 22.8172C5.75026 22.6284 4.59623 22.2284 3.68389 21.3161C2.7716 20.4038 2.37157 19.2501 2.18279 17.8457C1.99996 16.4856 1.99998 13.7508 2 11.5745V11.4256C1.99998 9.24975 1.99996 7.51492 2.18279 6.15466C2.37155 4.75026 2.77156 3.59623 3.68389 2.68389C4.59619 1.7716 5.74991 1.37157 7.15428 1.18279C8.51441 0.999958 10.2492 0.999977 12.4255 1Z"
                fillRule="evenodd"/>
            <path d="M12.5 6.5C13.0523 6.5 13.5 6.94772 13.5 7.5V17.5C13.5 18.0523 13.0523 18.5 12.5 18.5C11.9477 18.5 11.5 18.0523 11.5 17.5V7.5C11.5 6.94772 11.9477 6.5 12.5 6.5Z"/>
            <path d="M17 10.5C17.5523 10.5 18 10.9477 18 11.5V17.5C18 18.0523 17.5523 18.5 17 18.5C16.4477 18.5 16 18.0523 16 17.5V11.5C16 10.9477 16.4477 10.5 17 10.5Z"/>
            <path d="M8 12.5C8.55229 12.5 9 12.9477 9 13.5V17.5C9 18.0523 8.55229 18.5 8 18.5C7.44772 18.5 7 18.0523 7 17.5V13.5C7 12.9477 7.44772 12.5 8 12.5Z"/>
        </SvgIcon>);
}