import { DataTableColumn, DataTableSortType, Link, map, StringHelper, TimeFormatter, TimeHelper, UnexpectedError, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, StatusCell, tenantModelStore, TypeHelper, UrlHelper } from "../../../../../../../../common";
import { useCodeTenantModelStatusTranslator, useCodeTenantTypeTranslator } from "../../../../../../../../tenants";
import { TenantsDefinition } from "../../useDefinition";
import { useRenderTenant } from "../useRenderTenant";

enum CodeTenantModelIntegrationType {
    Organization = "Organization",
    Pipeline = "Pipeline"
}

function getCodeTenantModelIntegrationType(tenantModel: Contract.CodeTenantModel) {
    if (tenantModel.configuration.typeName === Contract.TypeNames.GeneralCodeTenantConfiguration) {
        return CodeTenantModelIntegrationType.Pipeline;
    }

    if (TypeHelper.extendOrImplement(tenantModel.configuration.typeName, Contract.TypeNames.GitTenantConfiguration)) {
        return CodeTenantModelIntegrationType.Organization;
    }

    throw new UnexpectedError("tenantConfiguration", tenantModel.configuration.typeName);
}

export function useCodeDefinition(): TenantsDefinition {
    const codeTenantModelStatusTranslator = useCodeTenantModelStatusTranslator();
    const codeTenantTypeTranslator = useCodeTenantTypeTranslator();
    const tenantModelMap = tenantModelStore.useGetCodeTenantMap();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useCodeDefinition",
            () => ({
                actions: {
                    delete: "delete the entire organization",
                    deleteOrganizationFolder: "This folder belongs to an organization which is configured to automatically onboard new repositories, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its repositories), or exclude specific repositories {{supportLink}}.",
                    deleteOrganizationTenant: "This repository/ies belongs to an organization which is configured to automatically onboard new repositories, and can't be deleted. Instead, you can either {{deleteLink}} (including all its repositories), or exclude specific repositories by {{supportLink}}",
                    deleteSelection: "Some/All of the selected repositories and/or folders belong to an organization which is configured to automatically onboard new repositories, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its repositories and folders), or exclude this specific repositories by {{supportLink}}.",
                    supportLink: "contacting Support"
                },
                columns: {
                    integrationType: {
                        title: "Integration Type",
                        [CodeTenantModelIntegrationType.Organization]: "Code Repositories",
                        [CodeTenantModelIntegrationType.Pipeline]: "CI/CD"
                    },
                    provider: "Provider",
                    status: "Status",
                    systemCreationTime: "Added"
                },
                title: "Code Repositories"
            }));

    const { renderTenantCell, renderTenantCsvItem } = useRenderTenant<Contract.CodeTenantModel>();
    return useMemo(
        () => ({

            editTenantDisabled: true,
            filter:
                (filterMap, scopeNodeModel) => {
                    if (ScopeHelper.isFolder(scopeNodeModel)) {
                        return true;
                    }

                    const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];

                    if (_.isNil(tenantModel)) {
                        return true;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.IntegrationType]) &&
                        !_.includes(filterMap[TenantTableColumnId.IntegrationType].values, getCodeTenantModelIntegrationType(tenantModel))) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.Provider]) &&
                        !_.includes(filterMap[TenantTableColumnId.Provider].values, tenantModel.configuration.codeTenantType)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.Status]) &&
                        !_.includes(filterMap[TenantTableColumnId.Status].values, tenantModel.status)) {
                        return false;
                    }

                    return true;
                },
            getColumns:
                scopeNodeModels => {
                    const integrationTypes = new Set<CodeTenantModelIntegrationType>();
                    const providers = new Set<Contract.CodeTenantType>();
                    const statuses = new Set<Contract.CodeTenantModelStatus>();

                    _(scopeNodeModels).
                        filter(scopeNodeModel => scopeNodeModel.type === Contract.ScopeType.CodeTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])).
                        map(scopeNodeModel => tenantModelMap[scopeNodeModel.configuration.id]).
                        forEach(
                            tenantModel => {
                                integrationTypes.add(getCodeTenantModelIntegrationType(tenantModel));
                                providers.add(tenantModel.configuration.codeTenantType);
                                if (!_.isNil(tenantModel.status)) {
                                    statuses.add(tenantModel.status);
                                }
                            });

                    return [
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.integrationType.title()}>
                                            {_.map(
                                                Array.from(integrationTypes),
                                                integrationType => (
                                                    <ValuesFilterItem
                                                        key={integrationType}
                                                        title={localization.columns.integrationType[integrationType]()}
                                                        value={integrationType}/>))}
                                        </ValuesFilter>
                                }
                            }}
                            id={TenantTableColumnId.IntegrationType}
                            key={TenantTableColumnId.IntegrationType}
                            title={localization.columns.integrationType.title()}/>,
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.provider()]:
                                            renderTenantCsvItem(
                                                tenantModel => codeTenantTypeTranslator(tenantModel.configuration.codeTenantType),
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.provider()}>
                                            {_.map(
                                                Array.from(providers),
                                                codeTenantType => (
                                                    <ValuesFilterItem
                                                        key={codeTenantType}
                                                        title={codeTenantTypeTranslator(codeTenantType)}
                                                        value={codeTenantType}/>))}
                                        </ValuesFilter>
                                }
                            }}
                            id={TenantTableColumnId.Provider}
                            key={TenantTableColumnId.Provider}
                            title={localization.columns.provider()}/>,
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.status()]:
                                            renderTenantCsvItem(
                                                tenantModel =>
                                                    tenantModel.status
                                                        ? codeTenantModelStatusTranslator(tenantModel.status)
                                                        : undefined,
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.status()}>
                                            {_.map(
                                                Array.from(statuses),
                                                status => (
                                                    <ValuesFilterItem
                                                        key={status}
                                                        title={codeTenantModelStatusTranslator(status)}
                                                        value={status}/>))}
                                        </ValuesFilter>
                                }
                            }}
                            id={TenantTableColumnId.Status}
                            key={TenantTableColumnId.Status}
                            render={
                                renderTenantCell(
                                    tenantModel =>
                                        _.isNil(tenantModel.status)
                                            ? undefined
                                            : <StatusCell
                                                statusSeverity={tenantModel.statusSeverity}
                                                title={codeTenantModelStatusTranslator(tenantModel.status)}/>
                                )
                            }
                            title={localization.columns.status()}/>,
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.systemCreationTime()]:
                                            renderTenantCsvItem(
                                                tenantModel => TimeFormatter.longDateTime(tenantModel.configuration.systemCreationTime),
                                                scopeNodeModel)
                                    })
                            }}
                            id={TenantTableColumnId.SystemCreationTime}
                            key={TenantTableColumnId.SystemCreationTime}
                            render={
                                renderTenantCell(tenantModel => TimeFormatter.longDateTime(tenantModel.configuration.systemCreationTime))
                            }
                            sortOptions={{ type: DataTableSortType.Date }}
                            title={localization.columns.systemCreationTime()}/>
                    ];
                },
            getDeleteScopeDisabledMessage:
                (selection, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.Folder &&
                        (scopeNodeModel.configuration as Contract.CodeFolderConfiguration).managed) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationFolder)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsRelativeUrl()}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    } else if (TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.GitTenantConfiguration)) {
                        const codeTenantType = (scopeNodeModel.configuration as Contract.CodeTenantConfiguration).codeTenantType;
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationTenant)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCodeOrganizationsRelativeUrl(codeTenantType)}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    }

                    return undefined;
                },
            sort:
                (columnId, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.CodeTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                        const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                        return map<string, any>(
                            columnId,
                            {
                                [TenantTableColumnId.Status]: () =>
                                    StringHelper.getSortValue(
                                        tenantModel.status
                                            ? codeTenantModelStatusTranslator(tenantModel.status)
                                            : undefined),
                                [TenantTableColumnId.SystemCreationTime]: () => TimeHelper.getSortable(tenantModel.configuration.systemCreationTime)
                            });
                    } else {
                        return undefined;
                    }
                },
            title: localization.title()
        }),
        [codeTenantModelStatusTranslator, codeTenantTypeTranslator, localization, tenantModelMap]);
}

export enum TenantTableColumnId {
    IntegrationType = "integrationType",
    Provider = "provider",
    Status = "status",
    SystemCreationTime = "systemCreationTime"
}