﻿import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpPubSubSubscriptionRiskContext() {
    return useMemo(
        () => useGcpPubSubSubscriptionRiskContext,
        []);
}

function useGcpPubSubSubscriptionRiskContext(subscriptionModel: Contract.GcpPubSubSubscriptionModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(subscriptionModel);
    const subscription = subscriptionModel.entity as Contract.GcpPubSubSubscription;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpPubSubSubscriptionRiskContext",
            () => ({
                messageDeliveryType: "The {{translatedSubscriptionTypeName}}'s delivery type is {{messageDeliveryType}}",
                topic: "The {{translatedSubscriptionTypeName}} is subscribed to {{topic}}"
            }));
    const translatedSubscriptionTypeName =
        entityTypeNameTranslator(
            subscription.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        messageDeliveryType:
            new RiskDefinitionContextItem(
                localization.messageDeliveryType({
                    messageDeliveryType: subscription.messageDeliverySettings.type,
                    translatedSubscriptionTypeName
                })),
        topic:
            new RiskDefinitionContextItem(
                localization.topic({
                    topic:
                        <Entity
                            entityIdOrModel={subscriptionModel.topicIdReference}
                            variant="text"/>,
                    translatedSubscriptionTypeName
                })
            )
    };
}