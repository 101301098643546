﻿import { Link, useLocalization, useSetWizardContext, WizardFinishItem } from "@infrastructure";
import React, { useEffect } from "react";
import { useCodeResolutionContext } from "../CodeResolutionContext";

export function FinishItem() {
    const setWizardContext = useSetWizardContext();
    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    backDisabled: true
                }));
        },
        []);

    const { pullRequestUrl } = useCodeResolutionContext();
    const localization =
        useLocalization(
            "views.customer.risks.profile.core.resolutionCategory.resolveCode.codeResolution.finishItem",
            () => ({
                title: {
                    link: "Pull Request",
                    text: "Your {{pullRequestLink}} was created successfully!"
                }
            }));

    return (
        <WizardFinishItem title={
            localization.title.text({
                pullRequestLink:
                    <Link
                        sx={{ textDecorationLine: "underline" }}
                        urlOrGetUrl={pullRequestUrl}
                        variant="external">
                        {localization.title.link()}
                    </Link>
            })}/>);
}