import { Action0, Dialog, FormLayout, Steps, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, riskPolicyModelStore, scopeSystemEntityModelStore, useScopeNameTranslator, useTheme } from "../../../../../../../common";
import { useReportScheduleCadenceTranslator } from "../../../../Reports/components/Schedule/hooks";
import { useReportDefinitionTranslator } from "../../../../Reports/hooks";

type CustomEntityAttributeDefinitionUsageDialogProps = {
    automationRuleConfigurationIds: string[];
    customRiskPolicyConfigurationIds: string[];
    onClose: Action0;
    reportScheduleConfigurationIds: string[];
    variant: "entityAttribute" | "entityProperty";
};

export function UsageDialog({ automationRuleConfigurationIds, customRiskPolicyConfigurationIds, onClose, reportScheduleConfigurationIds, variant }: CustomEntityAttributeDefinitionUsageDialogProps) {
    const riskAutomationRuleModels = scopeSystemEntityModelStore.useGetRiskAutomationRule();
    const riskAutomationRuleMap =
        useMemo(
            () =>
                _(riskAutomationRuleModels).
                    map(riskAutomationRuleModel => riskAutomationRuleModel.configuration as Contract.AutomationRuleConfiguration).
                    keyBy(riskAutomationRuleModel => riskAutomationRuleModel.id).
                    value(),
            [riskAutomationRuleModels]);
    const customRiskPolicyModels = riskPolicyModelStore.useGetCustom();
    const customRiskPolicyMap =
        useMemo(
            () =>
                _(customRiskPolicyModels).
                    map(customRiskPolicyModel => customRiskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).
                    keyBy(customRiskPolicyConfiguration => customRiskPolicyConfiguration.id).
                    value(),
            [customRiskPolicyModels]);

    const reportDefinitionTranslator = useReportDefinitionTranslator();
    const reportScheduleCadenceTranslator = useReportScheduleCadenceTranslator();
    const reportScheduleModels = scopeSystemEntityModelStore.useGetReportSchedule();
    const reportScheduleConfigurationMap =
        useMemo(
            () =>
                _(reportScheduleModels).
                    map(reportScheduleModel => reportScheduleModel.configuration as Contract.ReportScheduleConfiguration).
                    keyBy(reportScheduleConfiguration => reportScheduleConfiguration.id).
                    value(),
            [reportScheduleModels]);

    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.entities.usageDialog",
            () => ({
                automationRuleConfigurations: "**Automations**:",
                close: "Close",
                customRiskPolicyConfigurations: "**Custom policies**:",
                report: {
                    inventoryScheduleConfigurations: "**Inventory Reports**:",
                    scheduledText: "{{definition}} Scheduled {{cadence}} ({{scopeName}})"
                },
                title: "{{variant}} is in use",
                variant: {
                    entityAttribute: "Label",
                    entityProperty: "Field"
                }
            }));

    const theme = useTheme();
    return (
        <Dialog
            variant="viewer"
            onClose={onClose}>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        localization.title(
                            {
                                variant:
                                    variant === "entityAttribute"
                                        ? localization.variant.entityAttribute()
                                        : localization.variant.entityProperty()
                            })
                }}>
                <Stack
                    spacing={2}
                    sx={{
                        minHeight: theme.spacing(30),
                        padding: theme.spacing(3)
                    }}>
                    {!_.isEmpty(automationRuleConfigurationIds) && (
                        <Box>
                            <Typography variant="h3">
                                {localization.automationRuleConfigurations()}
                            </Typography>
                            <Steps>
                                {_.map(
                                    automationRuleConfigurationIds,
                                    automationRuleConfigurationId =>
                                        <Typography variant="h3">
                                            {`${riskAutomationRuleMap[automationRuleConfigurationId].name} (${scopeNameTranslator(riskAutomationRuleMap[automationRuleConfigurationId].scopeId, { path: true })})`}
                                        </Typography>)}
                            </Steps>
                        </Box>
                    )}
                    {!_.isEmpty(customRiskPolicyConfigurationIds) && (
                        <Box>
                            <Typography variant="h3">
                                {localization.customRiskPolicyConfigurations()}
                            </Typography>
                            <Steps>
                                {_.map(
                                    customRiskPolicyConfigurationIds,
                                    customRiskPolicyConfigurationId =>
                                        <Typography variant="h4">
                                            {`${customRiskPolicyMap[customRiskPolicyConfigurationId].name} (${scopeNameTranslator(customRiskPolicyMap[customRiskPolicyConfigurationId].scopeId, { path: true })})`}
                                        </Typography>)}
                            </Steps>
                        </Box>
                    )}
                    {!_.isEmpty(reportScheduleConfigurationIds) && (
                        <Box>
                            <Typography variant="h3">
                                {localization.report.inventoryScheduleConfigurations()}
                            </Typography>
                            <Steps>
                                {_.map(
                                    reportScheduleConfigurationIds,
                                    reportScheduleConfigurationId => {
                                        const reportScheduleConfiguration = reportScheduleConfigurationMap[reportScheduleConfigurationId] as Contract.ReportScheduleConfiguration;
                                        return (
                                            <Typography>
                                                {localization.report.scheduledText({
                                                    cadence: reportScheduleCadenceTranslator(reportScheduleConfiguration.cadence),
                                                    definition: reportDefinitionTranslator(reportScheduleConfiguration.definitionId),
                                                    scopeName: scopeNameTranslator(reportScheduleConfigurationMap[reportScheduleConfigurationId].scopeId, { path: true })
                                                })}
                                            </Typography>);
                                    })}
                            </Steps>
                        </Box>)}
                </Stack>
            </FormLayout>
        </Dialog>);
}