import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, optionalTableCell, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, Entity } from "../../../common";
import { useOciLoadBalancingLoadBalancerSslProtocolTranslator } from "../../../views/Customer/components/Entities/hooks";

type OciLoadBalancingLoadBalancerListenersTableProps = {
    backendIpAddressToInstanceIdMap: Dictionary<string>;
    backendSetNameToBackendSetMap: Dictionary<Contract.OciLoadBalancingLoadBalancerBackendSet>;
    listeners: Contract.OciLoadBalancingLoadBalancerListener[];
};

export function OciLoadBalancingLoadBalancerListenersTable({ backendIpAddressToInstanceIdMap, backendSetNameToBackendSetMap, listeners }: OciLoadBalancingLoadBalancerListenersTableProps) {
    const sslProtocolTranslator = useOciLoadBalancingLoadBalancerSslProtocolTranslator();
    const localization =
        useLocalization(
            "tenants.oci.ociLoadBalancingLoadBalancerListenersTable",
            () => ({
                columns: {
                    backendSetName: "Backend Set Name",
                    cipherSuiteName: "Cipher Suite",
                    name: "Name",
                    port: "Port",
                    protocol: "Protocol",
                    sslProtocols: "SSL Protocols",
                    targets: "Targets"
                },
                empty: "No Backends"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => listeners}
            getItemId={(item: Contract.OciLoadBalancingLoadBalancerListener) => item.name}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerListenersTableColumnId.Name}
                itemProperty={(item: Contract.OciLoadBalancingLoadBalancerListener) => item.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerListenersTableColumnId.Protocol}
                itemProperty={(item: Contract.OciLoadBalancingLoadBalancerListener) => item.protocol}
                title={localization.columns.protocol()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerListenersTableColumnId.Port}
                itemProperty={(item: Contract.OciLoadBalancingLoadBalancerListener) => item.port}
                title={localization.columns.port()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerListenersTableColumnId.SslProtocols}
                render={
                    optionalTableCell<Contract.OciLoadBalancingLoadBalancerListener>(
                        (item: Contract.OciLoadBalancingLoadBalancerListener) =>
                            _.isNil(item.sslConfiguration?.sslProtocols)
                                ? undefined
                                : <InlineItems
                                    items={
                                        item.sslConfiguration!.sslProtocols.
                                            map(sslProtocolTranslator).
                                            sort()}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.sslProtocols()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerListenersTableColumnId.CipherSuiteName}
                render={
                    optionalTableCell<Contract.OciLoadBalancingLoadBalancerListener>(
                        (item: Contract.OciLoadBalancingLoadBalancerListener) =>
                            _.isNil(item.sslConfiguration?.cipherSuiteName)
                                ? undefined
                                : item.sslConfiguration!.cipherSuiteName)}
                title={localization.columns.cipherSuiteName()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerListenersTableColumnId.BackendSetName}
                itemProperty={(item: Contract.OciLoadBalancingLoadBalancerListener) => item.backendSetName}
                title={localization.columns.backendSetName()}/>
            <DataTableColumn
                id={OciLoadBalancingLoadBalancerListenersTableColumnId.Targets}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciLoadBalancingLoadBalancerListener>) =>
                        <InlineItems
                            items={backendSetNameToBackendSetMap[item.backendSetName].backends}
                            variant="itemPlusItemCount">
                            {backend =>
                                _.has(backendIpAddressToInstanceIdMap, backend.ipAddress)
                                    ? <Entity
                                        entityIdOrModel={backendIpAddressToInstanceIdMap[backend.ipAddress]}
                                        variant="iconText"/>
                                    : <Typography noWrap={true}> {backend.ipAddress} </Typography>}
                        </InlineItems>
                }
                title={localization.columns.targets()}/>
        </DataTable>);
}

enum OciLoadBalancingLoadBalancerListenersTableColumnId {
    BackendSetName = "backendSetName",
    CipherSuiteName = "cipherSuiteName",
    Name = "name",
    Port = "port",
    Protocol = "protocol",
    SslProtocols = "sslProtocols",
    Targets = "targets"
}