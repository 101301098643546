import { InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, InfiniteScrollItemsLoader, useChangeEffect } from "@infrastructure";
import { ListItem, Stack } from "@mui/material";
import _ from "lodash";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { AuditEventController, Contract, UserHelper, useTheme } from "../../../../../../../../../../common";
import { useSideViewContext } from "../../../../../../../SideView";
import { useRiskContext } from "../../../../../../hooks";
import { AuditEvent, InsertRiskNoteSection } from "./components";

type AuditEventsSectionProps = {
    riskModel: Contract.RiskModel;
    scrollElementRef: RefObject<HTMLElement>;
};

export function AuditEventsSection({ riskModel, scrollElementRef }: AuditEventsSectionProps) {
    const { registerDataChange } = useSideViewContext();
    const { registerAuditEventsChange } = useRiskContext();

    const [auditEventModels, setAuditEventModels] = useState<Contract.AuditEventModel[]>([]);
    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>();

    function reset() {
        setAuditEventModels([]);
        fetchAuditEventModelNextPageSearchCursorRef.current = undefined;
        infiniteScrollActionsRef.current!.reset();
    }

    useEffect(
        () => {
            const unregister = registerAuditEventsChange(() => reset());
            const unregisterDataChange =
                registerDataChange(
                    async type => {
                        if (type === Contract.TypeNames.AuditEventControllerDeleteRiskNoteCreationAuditEventRequest) {
                            reset();
                        }
                    });
            return () => {
                unregisterDataChange();
                unregister();
            };
        },
        []);

    useChangeEffect(
        () => reset(),
        [riskModel]);

    const fetchAuditEventModelNextPageSearchCursorRef = useRef<Contract.ElasticsearchIndexSearchCursor>();
    const [auditEventModelCount, setAuditEventModelCount] = useState(0);
    const pageSize = 20;
    const fetchAuditEventsPage =
        async () => {
            const { auditEventModelPage } =
                await AuditEventController.getAuditEventModelPage(
                    new Contract.AuditEventControllerGetRiskAuditEventModelPageRequest(
                        pageSize,
                        fetchAuditEventModelNextPageSearchCursorRef.current,
                        riskModel.id));

            return new InfiniteScrollFetchDataResult(
                (!_.isNil(auditEventModelPage.count) && auditEventModelPage.count > 0) || auditEventModelCount > 0,
                _.isNil(auditEventModelPage.itemNextPageSearchCursor),
                () => {
                    setAuditEventModels(_.concat(auditEventModels, auditEventModelPage.items));
                    if (!_.isNil(auditEventModelPage.count)) {
                        setAuditEventModelCount(auditEventModelPage.count);
                    }
                    fetchAuditEventModelNextPageSearchCursorRef.current = auditEventModelPage.itemNextPageSearchCursor;
                });
        };

    const theme = useTheme();
    const itemElements =
        useMemo(
            () =>
                _.map(
                    auditEventModels,
                    auditEventModel =>
                        <ListItem
                            disableGutters={true}
                            key={auditEventModel.auditEvent.id}
                            sx={{ padding: theme.spacing(1.5, 0) }}>
                            <AuditEvent
                                auditEventModel={auditEventModel}
                                onChange={
                                    auditEventModel => {
                                        const auditEventModelIndex =
                                            _.findIndex(
                                                auditEventModels,
                                                currentAuditEventModel => currentAuditEventModel.auditEvent.id === auditEventModel.auditEvent.id);
                                        auditEventModels[auditEventModelIndex] = auditEventModel;
                                        setAuditEventModels([...auditEventModels]);
                                    }}
                                onDelete={
                                    auditEventId =>
                                        setAuditEventModels(
                                            _.filter(
                                                auditEventModels,
                                                auditEventModel => auditEventModel.auditEvent.id !== auditEventId))}/>
                        </ListItem>),
            [auditEventModels]);

    return (
        <Stack spacing={2}>
            {!UserHelper.support &&
                UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite) &&
                <InsertRiskNoteSection
                    riskModel={riskModel}
                    onCreated={auditEventModel => setAuditEventModels(_.concat(auditEventModel, auditEventModels))}/>}
            <InfiniteScroll
                actionsRef={infiniteScrollActionsRef}
                fetchData={fetchAuditEventsPage}
                scrollElementRef={scrollElementRef}>
                <InfiniteScrollItemsLoader
                    itemChunkSize={pageSize}
                    items={itemElements}/>
            </InfiniteScroll>
        </Stack>);
}