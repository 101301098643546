import { StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, useTenantNameTranslator } from "../../../../../common";
import { displayedItemCount } from "../usePermissionRequestActivationFailureDataTranslator";

export function usePermissionAssignmentRequestActivationFailureDataTranslator() {
    const localization =
        useLocalization(
            "views.user.hooks.usePermissionRequestActivationFailureDataTranslator.hooks.usePermissionAssignmentRequestActivationFailureDataTranslator",
            () => ({
                more: "and {{count}} more.",
                tenant: "{{tenantName}} ({{tenantErrorMessage}})",
                title: [
                    "Failed account:",
                    "Failed accounts:"
                ]
            }));

    const tenantNameTranslator = useTenantNameTranslator();

    return (permissionRequestModelActivationFailureData: Contract.PermissionRequestModelActivationFailureData) => {
        const permissionAssignmentRequestActivationFailureData = permissionRequestModelActivationFailureData.activationFailureData as Contract.PermissionAssignmentRequestActivationFailureData;
        const failedTenantCount = _.size(permissionAssignmentRequestActivationFailureData.tenantIdToErrorMessageMap);
        return _<string>([]).
            concat(localization.title(failedTenantCount)).
            concat(
                _(permissionAssignmentRequestActivationFailureData.tenantIdToErrorMessageMap).
                    toPairs().
                    orderBy(([tenantId]) => StringHelper.getSortValue(tenantNameTranslator(tenantId))).
                    take(displayedItemCount).
                    map(
                        ([tenantId, tenantErrorMessage]) =>
                            localization.tenant(
                                {
                                    tenantErrorMessage,
                                    tenantName: tenantNameTranslator(tenantId)
                                })).
                    value()).
            concatIf(
                failedTenantCount - displayedItemCount > 0,
                () => localization.more({ count: failedTenantCount - displayedItemCount })).
            join("\n");
    };
}