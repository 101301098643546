﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetCommonKubernetesClusterRiskContext } from "./contexts";

export function useKubernetesClusterClusterAdminRoleBindingExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterClusterAdminRoleBindingExistsRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.useKubernetesClusterClusterAdminRoleBindingExistsRiskDefinition",
            () => ({
                contextItems: {
                    clusterRoleBindings: "The cluster-admin role is used in {{clusterRoleBindings}}",
                    namespaceRoleBindings: "The cluster-admin role is used in {{namespaceRoleBindings}}"
                },
                description: "{{cluster}} has overprivileged cluster-admin role",
                resolutionSection: {
                    step1: "For the following {{clusterRoleBindings}}, check if they are used and if they need the cluster-admin role, or if they could use a role with fewer privileges",
                    step2: "Where possible, first bind users to a lower privileged role and then remove the cluster role binding to the cluster-admin role",
                    step3: "For the following {{namespaceRoleBindings}}, check if they are used and if they need the cluster-admin role, or if they could use a role with fewer privileges",
                    step4: "Where possible, first bind users to a lower privileged role and then remove the role binding to the cluster-admin role"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () =>
            _<string>([]).
                concatIf(
                    !_.isEmpty(risk.clusterRoleBindingIds),
                    () => [
                        localization.resolutionSection.step1({
                            clusterRoleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.clusterRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesClusterRoleBinding}
                                    variant="itemCountAndType"/>
                        }),
                        localization.resolutionSection.step2()
                    ]).
                concatIf(
                    !_.isEmpty(risk.namespaceRoleBindingIds),
                    () => [
                        localization.resolutionSection.step3({
                            namespaceRoleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.namespaceRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNamespaceRoleBinding}
                                    variant="itemCountAndType"/>
                        }),
                        localization.resolutionSection.step4()
                    ]).
                value(),
        riskModel,
        () => {
            const kubernetesClusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);
            return [
                kubernetesClusterRiskContext.generalInformation,
                kubernetesClusterRiskContext.sensitive,
                kubernetesClusterRiskContext.virtualNetwork,
                _.isEmpty(risk.clusterRoleBindingIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.clusterRoleBindings({
                            clusterRoleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.clusterRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesClusterRoleBinding}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(risk.namespaceRoleBindingIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.namespaceRoleBindings({
                            namespaceRoleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.namespaceRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNamespaceRoleBinding}
                                    variant="itemCountAndType"/>
                        })),
                kubernetesClusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}