import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../common";
import { AwsApiGatewayV1ApiRoutesTable } from "../../../../../../../../../../tenants";
import { useAwsApiGatewayApiEndpointTypeTranslator, useAwsApiGatewayApiTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsIamPolicyDocument } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useAwsApiGatewayV1ApiDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const api = resourceModel.entity as Contract.AwsApiGatewayV1Api;
    const apiModel = resourceModel as Contract.AwsApiGatewayV1ApiModel;
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const apiGatewayApiEndpointTypeTranslator = useAwsApiGatewayApiEndpointTypeTranslator();
    const apiGatewayApiProtocolTranslator = useAwsApiGatewayApiTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsApiGatewayV1ApiDefinition",
            () => ({
                info: {
                    cards: {
                        resources: "Resources"
                    },
                    items: {
                        endpointType: "Endpoint",
                        rawId: "Id",
                        type: "Protocol",
                        wafWebAcls: "Associated WAF web ACLs"
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isNil(api.policyDocument),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AwsIamPolicyDocument policyDocument={api.policyDocument}/>,
                        localization.tabs.policyDocument(),
                        "policyDocument")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={
                    [
                        ...commonAccessResourceInfoElements,
                        <InfoItem
                            key="rawId"
                            title={localization.info.items.rawId()}
                            value={api.rawId}/>,
                        <InfoItem
                            key="type"
                            title={localization.info.items.type()}
                            value={apiGatewayApiProtocolTranslator(api.type)}/>,
                        <InfoItem
                            key="endpointType"
                            location="all"
                            title={localization.info.items.endpointType()}
                            value={apiGatewayApiEndpointTypeTranslator(api.endpointType)}/>,
                        <EntitiesInfoItem
                            entityIdsOrModels={apiModel.wafWebAclIdReferences}
                            entityTypeName={Contract.TypeNames.AwsWafWebAcl}
                            key="wafWebAcls"
                            title={localization.info.items.wafWebAcls()}/>
                    ]}
                options={options?.infoOptions}>
                <InfoCard title={localization.info.cards.resources()}>
                    <AwsApiGatewayV1ApiRoutesTable apiModel={apiModel}/>
                </InfoCard>
            </Info>
    });
}