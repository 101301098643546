import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useAwsOrganizationsPolicyTypeTranslator } from "../../../../../../../../../../common";
import { DefinitionData, ExportData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsOrganizationsPolicyDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const organizationsPolicyTypeTranslator = useAwsOrganizationsPolicyTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsOrganizationsPolicyDefinition",
            () => ({
                columns: {
                    attachedAccountTenantIds: "Targets",
                    awsManaged: {
                        false: "Customer managed",
                        title: "Management",
                        true: "AWS managed"
                    },
                    organizationRawIds: "Organization ID",
                    type: "Type"
                }
            }));

    function getAwsManagedTranslation(policyModel: Contract.AwsOrganizationsPolicyModel) {
        return (policyModel.entity as Contract.AwsOrganizationsPolicy).awsManaged
            ? localization.columns.awsManaged.true()
            : localization.columns.awsManaged.false();
    }

    function getTypeTranslation(policyModel: Contract.AwsOrganizationsPolicyModel) {
        return organizationsPolicyTypeTranslator((policyModel.entity as Contract.AwsOrganizationsPolicy).type);
    }

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsOrganizationsPolicyModel) => ({
                            [localization.columns.type()]: getTypeTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsOrganizationsPolicyType}
                                enumTypeTranslator={organizationsPolicyTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsOrganizationsPolicyType}
                itemProperty={getTypeTranslation}
                key={Contract.EntityModelProperty.AwsOrganizationsPolicyType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsOrganizationsPolicyModel) => ({
                            [localization.columns.awsManaged.title()]: getAwsManagedTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.awsManaged.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.awsManaged.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.awsManaged.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsOrganizationsPolicyAwsManaged}
                itemProperty={getAwsManagedTranslation}
                key={Contract.EntityModelProperty.AwsOrganizationsPolicyAwsManaged}
                title={localization.columns.awsManaged.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsOrganizationsPolicyModel) => ({
                            [localization.columns.organizationRawIds()]: item.organizationRawIds.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsOrganizationsPolicyOrganizationRawIds)}
                                placeholder={localization.columns.organizationRawIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsOrganizationsPolicyOrganizationRawIds}
                key={Contract.EntityModelProperty.AwsOrganizationsPolicyOrganizationRawIds}
                render={
                    optionalTableCell<Contract.AwsOrganizationsPolicyModel>(
                        item =>
                            _.isEmpty(item.organizationRawIds)
                                ? undefined
                                : <Typography noWrap={true}>
                                    <InlineItems
                                        items={item.organizationRawIds}
                                        variant="itemPlusItemCount"/>
                                </Typography>)}
                title={localization.columns.organizationRawIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getData:
                        (item: Contract.AwsOrganizationsPolicyModel) => item.attachedAccountTenantIdReferences,
                    getItem:
                        (item: Contract.AwsOrganizationsPolicyModel, exportData: ExportData) => ({
                            [localization.columns.attachedAccountTenantIds()]:
                                _(item.attachedAccountTenantIdReferences).
                                    map(attachedAccountTenantId => (exportData.relatedEntityModelMap[attachedAccountTenantId].entity as Contract.AwsTenantEntity).displayReference).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsOrganizationsPolicyAttachedAccounts)}
                                placeholder={localization.columns.attachedAccountTenantIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsOrganizationsPolicyAttachedAccounts}
                key={Contract.EntityModelProperty.AwsOrganizationsPolicyAttachedAccounts}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsOrganizationsPolicyModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.attachedAccountTenantIdReferences}
                            entityTypeName={Contract.TypeNames.AwsTenantEntity}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.attachedAccountTenantIds()}/>,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsOrganizationsPolicyRequest(
                new Contract.EntityControllerGetEntityModelPageAwsOrganizationsPolicyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsOrganizationsPolicyAttachedAccounts]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsOrganizationsPolicyAwsManaged]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsOrganizationsPolicyOrganizationRawIds]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsOrganizationsPolicyType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}