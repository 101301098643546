import { Action0, Message, Steps } from "@infrastructure";
import { CircularProgress, Stack, Switch, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../../../common";

export type IntegrationInformationProps = {
    children?: ReactNode;
    emptyTitle?: string;
    icon: ReactNode;
    items: ReactNode[];
    title: string;
    toggleOptions?: ToggleOptions;
};

type ToggleOptions = {
    checked: boolean;
    disabled: boolean;
    errorMessage?: string;
    executing: boolean;
    onClick: Action0;
};

export function IntegrationInformation({ children, emptyTitle, icon, items, title, toggleOptions }: IntegrationInformationProps) {
    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(85),
                padding: theme.spacing(3)
            }}>
            <Stack spacing={1}>
                <Stack
                    alignItems="center"
                    direction="row">
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{ flex: 1 }}>
                        {icon}
                        <Typography variant="h2">
                            {title}
                        </Typography>
                    </Stack>
                    {!_.isNil(toggleOptions) &&
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}>
                            {!_.isNil(toggleOptions.errorMessage) && (
                                <Message
                                    level="error"
                                    title={toggleOptions.errorMessage}
                                    variant="minimal"/>)}
                            {toggleOptions.executing &&
                                <CircularProgress size="18px"/>}
                            <Switch
                                checked={toggleOptions.checked}
                                color="secondary"
                                disabled={toggleOptions.disabled}
                                size="small"
                                sx={{ margin: theme.spacing(0, 1) }}
                                onClick={toggleOptions.onClick}/>
                        </Stack>}
                </Stack>
                <Steps variant="bullets">
                    {items}
                </Steps>
                {emptyTitle &&
                    <Message
                        level="warning"
                        title={emptyTitle}
                        variant="standard"/>}
            </Stack>
            {children}
        </Stack>);
}