import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsTenantEntityRiskContext } from "../../contexts";
import { AwsCloudWatchRequirementsTable } from "./components";

export function useAwsCloudWatchAlarmNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const alarmNotExistRisk = riskModel.risk as Contract.AwsCloudWatchAlarmNotExistRisk;
    const alarmNotExistRiskModel = riskModel as Contract.AwsCloudWatchAlarmNotExistRiskModel;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const tenantEntityModel = entityModelStore.useGet(alarmNotExistRisk.entityId) as Contract.AwsTenantEntityModel;

    const getTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudWatchAlarmNotExistRiskDefinition",
            () => ({
                description: "{{tenantEntity}} is not configured with CloudWatch alarm",
                sections: {
                    requirements: "Details",
                    resolution: {
                        step1: {
                            link: "Creating CloudWatch alarms",
                            text: "Follow the instructions for {{createAlarmLink}} for the following metric filter: { {{logGroupMetricFilterPattern}} }"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudWatch,
                AwsConsoleUrlBuilder.getCloudWatchUrl(tenantConfiguration.partitionType)),
            localization.sections.resolution.step1.text({
                createAlarmLink:
                    <Link
                        urlOrGetUrl={alarmNotExistRiskModel.createAlarmDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>,
                logGroupMetricFilterPattern: alarmNotExistRisk.logGroupMetricFilterPattern
            })
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.accountStatus,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.path,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AwsCloudWatchRequirementsTable/>,
                    localization.sections.requirements())
            ]
        });
}