import { Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useComplianceTranslator, useGetComplianceScopeId, useSelectedScopeId } from "../../../../../../../../../..";
import { Item } from "./components";

type ItemsProps = {
    complianceSectionDatas: Contract.ComplianceSectionData[];
    timeFrame: Contract.TimeFrame;
};

export function Items({ complianceSectionDatas, timeFrame }: ItemsProps) {
    const getComplianceScopeId = useGetComplianceScopeId();
    const complianceTranslator = useComplianceTranslator();
    const { selectedScopeId } = useSelectedScopeId();
    return (
        <Stack>
            {_(complianceSectionDatas).
                orderBy(
                    complianceSectionData => complianceSectionData.custom,
                    "desc").
                map(
                    complianceSectionData =>
                        <Item
                            complianceSectionData={complianceSectionData}
                            complianceSectionTitle={complianceTranslator(complianceSectionData.identifier).title}
                            key={complianceSectionData.identifier}
                            scopeId={
                                // LongMigration: Projects | Delete when single scope selection is mandatory
                                getComplianceScopeId(
                                    complianceSectionData.identifier,
                                    selectedScopeId)}
                            timeFrame={timeFrame}/>).
                value()}
        </Stack>);
}