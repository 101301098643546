import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { AnalyticsContext, AnalyticsEventActionType, ContentCard, CopyToClipboardText, DataTableActions, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, EnumValuesFilter, NoneIcon, StringHelper, TextValuesFilter, TimeHelper, useChangeEffect, useExecuteOperation, useLocalization, useLocalizeList, useTrackAnalyticsEvent } from "@infrastructure";
import { ApiController, ApplicationHelper, AuthenticationController, ConsoleAppUrlHelper, Contract, CustomerIcon, ItemTable, LicensingHelper, StorageHelper, TenantController, topbarHeight, UrlHelper, UserHelper, useTheme } from "../../../common";
import { Topbar } from "./components";

type CustomersProps = {
    consoleAppType: Contract.ConsoleAppType;
};

export function Customers({ consoleAppType }: CustomersProps) {
    const [{ customers }] =
        useExecuteOperation(
            Customers,
            async () => {
                await ApplicationHelper.initialize(consoleAppType, false);
                return await AuthenticationController.getUserCustomerDatas();
            });

    const [searchText, setSearchText] = useState("");
    const theme = useTheme();
    return (
        <AnalyticsContext context="customers">
            <Topbar
                consoleAppType={consoleAppType}
                onSearchTextChanged={searchText => setSearchText(searchText)}/>
            <Box
                sx={{
                    bottom: 0,
                    left: 0,
                    overflow: "hidden auto",
                    position: "absolute",
                    right: 0,
                    top: theme.px(topbarHeight)
                }}>
                <Table
                    consoleAppType={consoleAppType}
                    customers={customers as Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer[]}
                    searchText={searchText}/>
            </Box>
        </AnalyticsContext>);
}

type TableProps = {
    consoleAppType: Contract.ConsoleAppType;
    customers: Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer[];
    searchText: string;
};

function Table({ consoleAppType, customers, searchText }: TableProps) {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const customerIdToViewTimeMap =
        useMemo(
            () => {
                const customerIdToViewTimeMap = StorageHelper.customerCustomersIdToViewTimeMap.getValue();
                return _.isNil(customerIdToViewTimeMap)
                    ? {}
                    : JSON.parse(customerIdToViewTimeMap) as Dictionary<string>;
            },
            []);

    async function setUserCustomer(id: string) {
        ApiController.clearScopeId();

        try {
            await AuthenticationController.setUserCustomer(new Contract.AuthenticationControllerSetUserCustomerRequest(id));
        } catch {
            window.location.assign(ConsoleAppUrlHelper.getSignInErrorRelativeUrl(consoleAppType));
            return;
        }

        customerIdToViewTimeMap[id] =
            TimeHelper.
                utcNow().
                toISOString();
        StorageHelper.customerCustomersIdToViewTimeMap.setValue(
            JSON.stringify(
                _.omitBy(
                    customerIdToViewTimeMap,
                    viewTime => TimeHelper.elapsed(viewTime, 30, "days"))));

        const { redirectUrl } = UrlHelper.getRedirectUrlQueryParameters();
        window.location.assign(
            ConsoleAppUrlHelper.getRelativeUrl(
                consoleAppType,
                _.isNil(redirectUrl) ||
                !ConsoleAppUrlHelper.isRelativeUrl(consoleAppType, redirectUrl) &&
                !(
                    consoleAppType === Contract.ConsoleAppType.Customer &&
                    redirectUrl.split("?")[0] == TenantController.processAadTenantCallbackUrl(undefined, undefined, undefined))
                    ? "/"
                    : redirectUrl));
    }

    const localization =
        useLocalization(
            "common.customers.table",
            () => ({
                columns: {
                    activeEvaluationLicenseTypes: "Evaluation Licenses",
                    activeSubscriptionLicenseTypes: "Subscription Licenses",
                    id: "ID",
                    name: "Name",
                    tenableId: "Tenable ID"
                },
                empty: {
                    withFilter: "No Matching Customers",
                    withoutFilter: "No Customers"
                }
            }));

    const filteredCustomers =
        useMemo(
            () =>
                _.filter(
                    customers,
                    customer =>
                        StringHelper.search(customer.id, searchText) ||
                        StringHelper.search(customer.name, searchText) ||
                        StringHelper.search(customer.tenableId, searchText)),
            [customers, searchText]);

    const itemTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            itemTableActionsRef.current?.reset();
        },
        [filteredCustomers]);

    const theme = useTheme();
    return (
        <ContentCard sx={{ height: "100%" }}>
            <ItemTable
                actionsRef={itemTableActionsRef}
                columnIdToDefaultSortDirectionMap={{
                    [TableColumnId.ViewTime]: "desc"
                }}
                columnIdToGetItemValueMap={{
                    [TableColumnId.ActiveEvaluationLicenseTypes]: item => getActiveStandardLicenseTypeAndAddonLicenseTypes(item.activeEvaluationLicenseTypes),
                    [TableColumnId.ActiveSubscriptionLicenseTypes]: item => getActiveStandardLicenseTypeAndAddonLicenseTypes(item.activeSubscriptionLicenseTypes),
                    [TableColumnId.Id]: item => item.id,
                    [TableColumnId.Name]: item => item.name,
                    [TableColumnId.TenableId]: item => item.tenableId,
                    [TableColumnId.ViewTime]: item => TimeHelper.getSortable(customerIdToViewTimeMap[item.id])
                }}
                defaultSortColumnIdOrIds={[TableColumnId.ViewTime, TableColumnId.Name]}
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
                }}
                getItemId={customer => customer.id}
                items={filteredCustomers}
                rowOptions={{
                    getSx:
                        () => ({
                            cursor: "pointer"
                        }),
                    onBeforeClick:
                        (event, item) => {
                            setUserCustomer(item.id);
                            return true;
                        }
                }}
                showEmptyTable={true}
                variant="view">
                {columnIdToItemValuesMap =>
                    _<ReactNode>([]).
                        concat(
                            <DataTableColumn
                                cellSx={{ width: theme.spacing(4) }}
                                id={TableColumnId.Icon}
                                key={TableColumnId.Icon}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer>) =>
                                        <Stack
                                            alignItems="center"
                                            sx={{ height: "100%" }}>
                                            <CustomerIcon
                                                customer={item}
                                                size={theme.spacing(3.5)}/>
                                        </Stack>}/>,
                            <DataTableColumn
                                cellSx={{ minWidth: theme.spacing(40) }}
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <TextValuesFilter
                                                placeholder={localization.columns.name()}
                                                values={columnIdToItemValuesMap[TableColumnId.Name]}/>
                                    }
                                }}
                                id={TableColumnId.Name}
                                key={TableColumnId.Name}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer>) =>
                                        <Typography
                                            noWrap={true}
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 600
                                            }}>
                                            {item.name}
                                        </Typography>}
                                title={localization.columns.name()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <TextValuesFilter
                                                placeholder={localization.columns.id()}
                                                values={columnIdToItemValuesMap[TableColumnId.Id]}/>
                                    }
                                }}
                                id={TableColumnId.Id}
                                key={TableColumnId.Id}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer>) =>
                                        <CopyToClipboardText text={item.id}/>}
                                title={localization.columns.id()}/>).
                        concatIf(
                            UserHelper.applicationSupport ||
                            UserHelper.support,
                            [
                                <DataTableColumn
                                    filterOptions={{
                                        itemOrItems: {
                                            default: true,
                                            element:
                                                <TextValuesFilter
                                                    placeholder={localization.columns.tenableId()}
                                                    values={columnIdToItemValuesMap[TableColumnId.TenableId]}/>
                                        }
                                    }}
                                    id={TableColumnId.TenableId}
                                    key={TableColumnId.TenableId}
                                    render={
                                        ({ item }: DataTableColumnRenderProps<Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer>) =>
                                            <CopyToClipboardText text={item.tenableId}/>}
                                    title={localization.columns.tenableId()}/>,
                                <DataTableColumn
                                    filterOptions={{
                                        itemOrItems: {
                                            default: true,
                                            element:
                                                <EnumValuesFilter
                                                    emptyValueOptions={{ enabled: true }}
                                                    enumType={Contract.ApplicationCustomerConfigurationLicensingLicenseType}
                                                    enumTypeTranslator={(licenseType: Contract.ApplicationCustomerConfigurationLicensingLicenseType) => LicensingHelper.getLicenseData(licenseType).shortName}
                                                    placeholder={localization.columns.activeSubscriptionLicenseTypes()}/>
                                        }
                                    }}
                                    id={TableColumnId.ActiveSubscriptionLicenseTypes}
                                    key={TableColumnId.ActiveSubscriptionLicenseTypes}
                                    render={
                                        ({ item }: DataTableColumnRenderProps<Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer>) =>
                                            <ActiveLicenseCell activeLicenseTypes={item.activeSubscriptionLicenseTypes}/>}
                                    sortOptions={{ enabled: false }}
                                    title={localization.columns.activeSubscriptionLicenseTypes()}/>,
                                <DataTableColumn
                                    filterOptions={{
                                        itemOrItems: {
                                            default: true,
                                            element:
                                                <EnumValuesFilter
                                                    emptyValueOptions={{ enabled: true }}
                                                    enumType={Contract.ApplicationCustomerConfigurationLicensingLicenseType}
                                                    enumTypeTranslator={(licenseType: Contract.ApplicationCustomerConfigurationLicensingLicenseType) => LicensingHelper.getLicenseData(licenseType).shortName}
                                                    placeholder={localization.columns.activeEvaluationLicenseTypes()}/>
                                        }
                                    }}
                                    id={TableColumnId.ActiveEvaluationLicenseTypes}
                                    key={TableColumnId.ActiveEvaluationLicenseTypes}
                                    render={
                                        ({ item }: DataTableColumnRenderProps<Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer>) =>
                                            <ActiveLicenseCell activeLicenseTypes={item.activeEvaluationLicenseTypes}/>}
                                    sortOptions={{ enabled: false }}
                                    title={localization.columns.activeEvaluationLicenseTypes()}/>
                            ]).
                        value()}
            </ItemTable>
        </ContentCard>);
}

type ActiveLicenseCellProps = {
    activeLicenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[];
};

function ActiveLicenseCell({ activeLicenseTypes }: ActiveLicenseCellProps) {
    const localizeList = useLocalizeList();

    const licenseShortNames =
        useMemo(
            () => {
                const standardLicenseType = getActiveStandardLicenseType(activeLicenseTypes);
                const standardLicenseShortName =
                    _.isNil(standardLicenseType)
                        ? undefined
                        : LicensingHelper.getLicenseData(standardLicenseType).shortName;

                const addonLicenseShortNames =
                    _(getActiveAddonLicenseTypes(activeLicenseTypes)).
                        map(addonLicenseType => LicensingHelper.getLicenseData(addonLicenseType).shortName).
                        orderBy(StringHelper.getSortValue).
                        value();

                return _<string>([]).
                    concatIf(
                        !_.isNil(standardLicenseShortName),
                        standardLicenseShortName!).
                    concat(addonLicenseShortNames).
                    value();
            },
            [activeLicenseTypes]);

    return _.size(licenseShortNames) === 0
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : <Typography>
            {localizeList(
                licenseShortNames,
                false)}
        </Typography>;
}

function getActiveAddonLicenseTypes(activeLicenses: Contract.ApplicationCustomerConfigurationLicensingLicenseType[]) {
    return _.intersection(
        activeLicenses,
        LicensingHelper.addonLicenseTypes);
}

function getActiveStandardLicenseType(activeLicenses: Contract.ApplicationCustomerConfigurationLicensingLicenseType[]) {
    return LicensingHelper.getActiveLicenseType(
        _.intersection(
            activeLicenses,
            LicensingHelper.standardLicenseTypes));
}

function getActiveStandardLicenseTypeAndAddonLicenseTypes(activeLicenses: Contract.ApplicationCustomerConfigurationLicensingLicenseType[]) {
    return _([getActiveStandardLicenseType(activeLicenses)]).
        concat(getActiveAddonLicenseTypes(activeLicenses)).
        filter().
        value();
}

enum TableColumnId {
    ActiveEvaluationLicenseTypes = "activeEvaluationLicenses",
    ActiveSubscriptionLicenseTypes = "activeSubscriptionLicenses",
    Icon = "icon",
    Id = "id",
    Name = "name",
    TenableId = "tenableId",
    ViewTime = "viewTime"
}