import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionContextItemImpact, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { VulnerabilitiesInlineItems } from "../../../components";
import { getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity, getWorkloadResourceVulnerabilityRiskSeverityValues, useGetWorkloadResourceRiskVulnerabilityRawIds } from "../../../utilities/utilities";
import { useGetContainerImageWorkloadAnalysisRiskContext, useGetVirtualMachinesWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetPackageVulnerabilityResolutionSteps } from "./useGetPackageVulnerabilityResolutionSteps";

export function useContainerImageVulnerabilityRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const containerImageVulnerabilityRisk = riskModel.risk as Contract.ContainerImageVulnerabilityRisk;

    const getContainerImageWorkloadAnalysisRiskContext = useGetContainerImageWorkloadAnalysisRiskContext();
    const getVirtualMachinesWorkloadAnalysisRiskContext = useGetVirtualMachinesWorkloadAnalysisRiskContext(containerImageVulnerabilityRisk);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useContainerImageVulnerabilityRiskDefinition",
            () => ({
                description: "The container image on {{riskedEntity}}, stored in {{containerImageRepository}}, has {{vulnerabilities}}",
                sections: {
                    resolution: {
                        resolutionContainerImage: "Repository {{containerImageRepository}} contains more recent images without critical vulnerabilities. It's recommended to deploy a suitable image version from that repository to {{riskedEntity}}, if possible."
                    },
                    vulnerabilities: "Vulnerabilities"
                }
            }));

    const maxVulnerabilitySeverity = getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity(containerImageVulnerabilityRisk);
    const vulnerabilitySeverityToRawIdsMap = useGetWorkloadResourceRiskVulnerabilityRawIds(containerImageVulnerabilityRisk);
    const getPackageVulnerabilityResolutionSteps = useGetPackageVulnerabilityResolutionSteps();
    const riskedEntity =
        <Entity
            entityIdOrModel={containerImageVulnerabilityRisk.entityId}
            variant="text"/>;
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            containerImageRepository:
                <Entity
                    entityIdOrModel={containerImageVulnerabilityRisk.containerImageRepositoryReference}
                    variant="text"/>,
            riskedEntity,
            vulnerabilities:
                <VulnerabilitiesInlineItems
                    severity={maxVulnerabilitySeverity}
                    severityVulnerabilityRawIds={vulnerabilitySeverityToRawIdsMap[maxVulnerabilitySeverity]}/>
        }),
        () =>
            [...(containerImageVulnerabilityRisk.resolutionContainerImageId
                ? [
                    localization.sections.resolution.resolutionContainerImage({
                        containerImageRepository:
                            <Entity
                                entityIdOrModel={containerImageVulnerabilityRisk.containerImageRepositoryReference}
                                variant="text"/>,
                        riskedEntity
                    })]
                : []),
            ...getPackageVulnerabilityResolutionSteps(
                containerImageVulnerabilityRisk.filePathToPackageNamesMap,
                containerImageVulnerabilityRisk.filePathToVulnerabilityRawIdsMap,
                containerImageVulnerabilityRisk.vulnerabilityPackageNameToResolutionVersionsMap)],
        riskModel,
        () => {
            const containerImageWorkloadAnalysisRiskContext = getContainerImageWorkloadAnalysisRiskContext(containerImageVulnerabilityRisk);
            const virtualMachinesWorkloadAnalysisRiskContext = getVirtualMachinesWorkloadAnalysisRiskContext();

            return [
                containerImageWorkloadAnalysisRiskContext.generalInformation,
                containerImageWorkloadAnalysisRiskContext.virtualMachines,
                containerImageWorkloadAnalysisRiskContext.getVulnerabilities(),
                containerImageWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.Critical),
                virtualMachinesWorkloadAnalysisRiskContext.sensitive?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.inboundExternalAccessScope?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.identityPermissionActionSeverity?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.stopped?.withImpact(RiskDefinitionContextItemImpact.SeverityDecreased),
                containerImageWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(containerImageVulnerabilityRisk.id)
            ];
        },
        {
            sections:
                [
                    new RiskDefinitionSection(
                        <Vulnerabilities
                            entityIds={containerImageVulnerabilityRisk.containerImageIds}
                            severityFilterValues={getWorkloadResourceVulnerabilityRiskSeverityValues(containerImageVulnerabilityRisk.severeVulnerabilityMinSeverity ?? Contract.Severity.Critical)}
                            variant="risk"/>,
                        localization.sections.vulnerabilities(),
                        {
                            expandable: true
                        })
                ]
        });
}