import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OracleIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M7.58983 19C3.40008 19 0 15.648 0 11.5059C0 7.36395 3.40008 4 7.58983 4H16.4114C20.6024 4 24 7.36395 24 11.5059C24 15.648 20.6024 19 16.4114 19H7.58983ZM16.2152 16.3542C18.9297 16.3542 21.1282 14.1876 21.1282 11.5059C21.1282 8.82435 18.9297 6.64558 16.2152 6.64558H7.78478C5.07159 6.64558 2.87177 8.82435 2.87177 11.5059C2.87177 14.1876 5.07141 16.3542 7.78478 16.3542H16.2152Z"
                fill="#C74634"/>
        </SvgIcon>);
}