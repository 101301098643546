import { Optional, useActions, useLocalization } from "@infrastructure";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { AwsResourceUsageRiskPolicyEdit, EntitySelector, EntitySelectorSelection, EntityTypeNameSelector, EntityTypeNameSelectorSelection, InlineEntitySelection, InlineEntityTypeNameSelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsIamPrincipalCreationRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const [principalsValid, setPrincipalsValid] = useState(false);
    const [principalTypeNamesValid, setPrincipalTypeNamesValid] = useState(false);
    const { scopeId } = useCustomRiskPolicyContext();

    const [principalSelectorSelection, setPrincipalSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.allResources,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.resourceIds,
                riskPolicyConfiguration?.resourceNamePattern,
                undefined,
                riskPolicyConfiguration?.resourceTags));
    const [principalTypeNameSelectorSelection, setPrincipalTypeNameSelectorSelection] =
        useState<EntityTypeNameSelectorSelection | undefined>(
            EntityTypeNameSelectorSelection.getSelectorSelection(riskPolicyConfiguration?.principalTypeNames));

    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityNamePattern, entityTags } = principalSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAwsIamPrincipalCreationRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities ?? false,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityNamePattern,
                        entityTags,
                        principalTypeNameSelectorSelection?.getInlineSelectionData()?.typeNames);
                }
        });

    useEffect(
        () => {
            const principalsValid = principalSelectorSelection?.valid() ?? false;
            const principalTypeNamesValid = principalTypeNameSelectorSelection?.valid() ?? false;
            setPrincipalsValid(principalsValid);
            setPrincipalTypeNamesValid(principalTypeNamesValid);

            onValidChange(principalsValid && principalTypeNamesValid);
        },
        [principalSelectorSelection, principalTypeNameSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsIamPrincipalCreationRiskPolicy.edit",
            () => ({
                prompt: "Select the required {{principalTranslatedTypeName}}",
                typeNames: "Types"
            }));
    return (
        <AwsResourceUsageRiskPolicyEdit
            accordionContents={[
                {
                    selector:
                        <EntitySelector
                            builtInEntityAttributeTypeNames={[
                                Contract.TypeNames.AdministratorPrincipalAttribute,
                                Contract.TypeNames.AwsAccessKeyEnabledUserAttribute,
                                Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute,
                                Contract.TypeNames.BehaviorRiskIdentityAttribute,
                                Contract.TypeNames.CredentialsDisabledUserAttribute,
                                Contract.TypeNames.InactiveIdentityAttribute,
                                Contract.TypeNames.MfaDisabledUserAttribute,
                                Contract.TypeNames.RelatedPublicComputeAttribute,
                                Contract.TypeNames.SensitiveResourceAttribute,
                                Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                Contract.TypeNames.VendorServiceIdentityAttribute]}
                            disableManualCustomEntityAttributes={true}
                            entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                            includeServiceName={true}
                            namePattern={true}
                            properties={["all", "tags", "attributes"]}
                            scopeId={scopeId}
                            selection={principalSelectorSelection}
                            onSelectionChanged={setPrincipalSelectorSelection}/>,
                    title:
                        entityTypeNameTranslator(
                            Contract.TypeNames.AwsIamPrincipal,
                            {
                                count: 0,
                                includeServiceName: false
                            }),
                    valid: principalsValid
                },
                {
                    selector:
                        <EntityTypeNameSelector
                            entityTypeNames={[
                                Contract.TypeNames.AwsIamGroup,
                                Contract.TypeNames.AwsIamRole,
                                Contract.TypeNames.AwsIamUser
                            ]}
                            selection={principalTypeNameSelectorSelection}
                            translatedEntityTypeName={
                                entityTypeNameTranslator(
                                    Contract.TypeNames.AwsIamPrincipal,
                                    {
                                        count: 0,
                                        includeServiceName: true,
                                        variant: "text"
                                    })}
                            onSelectionChanged={setPrincipalTypeNameSelectorSelection}/>,
                    title: localization.typeNames(),
                    valid: principalTypeNamesValid

                }
            ]}
            prompt={localization.prompt({
                principalTranslatedTypeName:
                    entityTypeNameTranslator(
                        Contract.TypeNames.AwsIamPrincipal,
                        {
                            count: 0,
                            includeServiceName: true,
                            variant: "text"
                        })
            })}
            template={
                templateTranslator({
                    principals:
                        <InlineEntitySelection
                            entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                            selection={principalSelectorSelection}/>,
                    principalTypeNames: <InlineEntityTypeNameSelection selection={principalTypeNameSelectorSelection}/>
                })}/>);
}