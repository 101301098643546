import { useCallback } from "react";
import { UnexpectedError, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { CustomerLicenseObjectTypeTranslator } from "../useGetVersion2Translator";

export function useGetVersion2AzureTranslator(): CustomerLicenseObjectTypeTranslator {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.licensing.hooks.useGetCustomerLicenseObjectInfo.hooks.version2.hooks.useGetVersion2AzureTranslator",
            () => ({
                [Contract.CustomerLicenseObjectType.AzureContainerServiceManagedClusterVirtualMachineScaleSetVirtualMachine]: "{{translatedTypeName}} running AKS",
                [Contract.CustomerLicenseObjectType.AzureContainerRegistryRepository]: "{{translatedTypeName}}",
                [Contract.CustomerLicenseObjectType.AzureSqlAnalyzedServer]: "{{translatedTypeName}} (Data Protection)",
                [Contract.CustomerLicenseObjectType.AzureStorageAnalyzedStorageAccountBlobContainer]: "{{translatedTypeName}} (Data Protection)"
            }));


    return useCallback(
        objectType => {
            switch (objectType) {
                case Contract.CustomerLicenseObjectType.AzureComputeVirtualMachine:
                case Contract.CustomerLicenseObjectType.AzureComputeVirtualMachineScaleSetVirtualMachine:
                case Contract.CustomerLicenseObjectType.AzureWebApplication:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(objectType),
                        title:
                            entityTypeNameTranslator(
                                objectType,
                                { count: 0 })
                    };
                case Contract.CustomerLicenseObjectType.AzureContainerInstanceContainerGroup:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(Contract.TypeNames.AzureContainerInstanceContainerGroupGenericResource),
                        title:
                            entityTypeNameTranslator(
                                Contract.TypeNames.AzureContainerInstanceContainerGroupGenericResource,
                                { count: 0 })
                    };
                case Contract.CustomerLicenseObjectType.AzureContainerRegistryRepository:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        objectType,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AzureContainerServiceManagedClusterVirtualMachineScaleSetVirtualMachine:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AzureSqlAnalyzedServer:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AzureSqlServer,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AzureStorageAnalyzedStorageAccountBlobContainer:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AzureStorageStorageAccountBlobContainer,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AzureUnmanagedKubernetesClusterNode:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(Contract.TypeNames.AzureKubernetesNode),
                        title:
                            entityTypeNameTranslator(
                                Contract.TypeNames.IKubernetesNode,
                                {
                                    count: 0,
                                    includeServiceName: true
                                })
                    };
                default:
                    throw new UnexpectedError("objectType", objectType);
            }
        },
        [entityTypeNameTranslator, localization]);
}