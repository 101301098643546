﻿import { EmptyMessage, map, NumberFormatter, TimeRangeFilterSelectionRelativeUnit, useLocalization, useSetRoute } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, defaultSeverities, Tenant, tenantModelStore, TimeRangeHelper, useTheme } from "../../../../../../../../../../..";

type RankingProps = {
    items?: RankingItem[];
    principalType: Contract.PrincipalType;
    severities: Contract.Severity[];
    timeFrame: Contract.TimeFrame;
    variant: "bottom" | "top";
};

export function Ranking({ items, principalType, severities, timeFrame, variant }: RankingProps) {
    const setRoute = useSetRoute();
    const tenantModels = tenantModelStore.useGetAll();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetExcessivePermissionPrincipalTrendsDefinition.principalRiskCategoryTenantRanking.ranking",
            () => ({
                empty: "No Accounts"
            }));

    function getPolicyConfigurationTypeNameOrIdsByTenantType(tenantId: string, principalType: Contract.PrincipalType) {
        const tenantType =
            _.find(
                tenantModels,
                tenantModel => tenantModel.configuration.id === tenantId)?.tenantType;

        return map(
            tenantType,
            {
                [Contract.TenantType.Aws]: () =>
                    map(
                        principalType,
                        {
                            [Contract.PrincipalType.AwsPermissionSet]: () => [Contract.TypeNames.AwsExcessivePermissionPermissionSetRiskPolicyConfiguration],
                            [Contract.PrincipalType.Group]: () => [Contract.TypeNames.AwsExcessivePermissionGroupRiskPolicyConfiguration],
                            [Contract.PrincipalType.ServiceIdentity]: () => [Contract.TypeNames.AwsExcessivePermissionRoleRiskPolicyConfiguration],
                            [Contract.PrincipalType.User]: () => [Contract.TypeNames.AwsExcessivePermissionUserRiskPolicyConfiguration]
                        }),
                [Contract.TenantType.Azure]: () =>
                    map(
                        principalType,
                        {
                            [Contract.PrincipalType.Group]: () => [Contract.TypeNames.AzureExcessivePermissionGroupRiskPolicyConfiguration],
                            [Contract.PrincipalType.ServiceIdentity]: () => [
                                Contract.TypeNames.AzureExcessivePermissionApplicationServicePrincipalRiskPolicyConfiguration,
                                Contract.TypeNames.AzureExcessivePermissionManagedIdentityServicePrincipalRiskPolicyConfiguration],
                            [Contract.PrincipalType.User]: () => [Contract.TypeNames.AzureExcessivePermissionUserRiskPolicyConfiguration]
                        }),
                [Contract.TenantType.Gcp]: () =>
                    map(
                        principalType,
                        {
                            [Contract.PrincipalType.Group]: () => [Contract.TypeNames.GcpExcessivePermissionGroupRiskPolicyConfiguration],
                            [Contract.PrincipalType.ServiceIdentity]: () => [Contract.TypeNames.GcpExcessivePermissionServiceAccountRiskPolicyConfiguration],
                            [Contract.PrincipalType.User]: () => [Contract.TypeNames.GcpExcessivePermissionUserRiskPolicyConfiguration]
                        })
            });
    }

    const theme = useTheme();
    return (
        _.isEmpty(items)
            ? <EmptyMessage
                message={localization.empty()}
                verticalCenter={true}/>
            : <Stack>
                {_.map(
                    items,
                    item =>
                        <Stack
                            alignItems="center"
                            direction="row"
                            flexWrap="wrap"
                            justifyContent="center"
                            key={item.tenantId}
                            spacing={1}
                            sx={{
                                "&:hover": {
                                    background: theme.palette.action.hover
                                },
                                cursor: "pointer",
                                padding: theme.spacing(1, 1.5)
                            }}
                            onClick={
                                () =>
                                    setRoute(
                                        CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                                            variant === "bottom"
                                                ? Contract.RisksView.Open
                                                : Contract.RisksView.Closed,
                                            {
                                                policyConfigurationTypeNameOrIds: getPolicyConfigurationTypeNameOrIdsByTenantType(item.tenantId, principalType),
                                                severity:
                                                    _(defaultSeverities).
                                                        xor(severities).
                                                        isEmpty()
                                                        ? undefined
                                                        : severities,
                                                statusUpdateTimeLast: {
                                                    unit: TimeRangeFilterSelectionRelativeUnit.Days,
                                                    value: TimeRangeHelper.getTimeFrameValue(timeFrame)
                                                },
                                                tenantIds: [item.tenantId]
                                            }))}>
                            <Tenant
                                sx={{ flex: 1 }}
                                tenantId={item.tenantId}/>
                            <Typography noWrap={true}>
                                {item.endTimePrincipalCount}
                            </Typography>
                            <Typography
                                noWrap={true}
                                sx={{
                                    color:
                                        item.delta > 0
                                            ? theme.palette.error.main
                                            : theme.palette.success.main,
                                    fontWeight: 600
                                }}>
                                {NumberFormatter.signedHumanize(item.delta)}
                            </Typography>
                        </Stack>)}
            </Stack>);
}

export class RankingItem {
    constructor(
        public delta: number,
        public endTimePrincipalCount: number,
        public tenantId: string) {
    }
}