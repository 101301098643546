import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, entityModelStore, InfoItem } from "../../../../../../../../../../common";
import { useAwsEcsServiceLaunchTypeTranslator } from "../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonNetworkingInfoItemElements } from "./useAwsCommonNetworkingInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsEcsServiceDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const service = resourceModel.entity as Contract.AwsEcsService;
    const serviceModel = resourceModel as Contract.AwsEcsServiceModel;
    const taskDefinitionModel = entityModelStore.useGet(service.taskDefinitionReference?.idReference);
    const taskDefinition =
        _.isNil(taskDefinitionModel) ||
        taskDefinitionModel.unknown
            ? undefined
            : taskDefinitionModel.entity as Contract.AwsEcsTaskDefinition;
    const taskDefinitionLatestRevision =
        _.isNil(taskDefinition?.latestRevisionId)
            ? undefined
            : taskDefinition!.revisionMap[taskDefinition!.latestRevisionId];
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            networkInterfaceIds: serviceModel.networkInterfaceIds,
            resourceModel
        });

    const ecsServiceLaunchTypeTranslator = useAwsEcsServiceLaunchTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEcsServiceDefinition",
            () => ({
                info: {
                    items: {
                        containerImages: "Container Images",
                        containerImageVulnerabilities: "Vulnerabilities",
                        launchType: "Launch Type",
                        networkInterfaceIds: "Network Interfaces",
                        taskDefinition: "Task Definition",
                        taskDefinitionRole: "Task Role",
                        vpc: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="launchType"
                        title={localization.info.items.launchType()}
                        value={
                            _.isNil(service.launchType)
                                ? undefined
                                : ecsServiceLaunchTypeTranslator(service.launchType)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={taskDefinitionModel}
                        entityTypeName={Contract.TypeNames.AwsEcsTaskDefinition}
                        key="taskDefinition"
                        title={localization.info.items.taskDefinition()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={taskDefinitionLatestRevision?.roleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="taskDefinitionRole"
                        title={localization.info.items.taskDefinitionRole()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={serviceModel.containerImageIdReferences}
                        entityTypeName={Contract.TypeNames.IContainerImage}
                        key="containerImages"
                        title={localization.info.items.containerImages()}/>,
                    <InfoItem
                        key="containerImageVulnerabilities"
                        title={localization.info.items.containerImageVulnerabilities()}
                        value={
                            <VulnerabilitiesCell
                                containerImageScanStatus={serviceModel.containerImageScanStatus}
                                variant="initialAndCount"
                                vulnerabilities={serviceModel.containerImageVulnerabilities}/>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={service.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
            </Info>
    });
}