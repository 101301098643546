import { ActionMenuItem, DataTableColumnRenderProps, Menu, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import React, { Fragment } from "react";
import { Contract } from "../../../../../../../../../../../common";
import { useGranteeUserPermissionRequestsContext, useSetGranteeUserPermissionRequestsContext } from "../../../../../../../GranteeUserPermissionRequests";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const { notDeletedPermissionEligibilityIdSet } = useGranteeUserPermissionRequestsContext();
    const setGranteeUserPermissionRequestsContext = useSetGranteeUserPermissionRequestsContext();
    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.table.hooks.useDefinition.hooks.useHistoryDefinition.actionsCell",
            () => ({
                actions: {
                    requestAgain: "Request again"
                }
            }));
    return notDeletedPermissionEligibilityIdSet.has(item.permissionRequest.permissionEligibilityId)
        ? <Stack
            direction="row"
            justifyContent="flex-end">
            <Menu
                itemsOrGetItems={[
                    new ActionMenuItem(
                        () =>
                            setGranteeUserPermissionRequestsContext(
                                granteeUserPermissionRequestsContext => ({
                                    ...granteeUserPermissionRequestsContext,
                                    addOpen: item
                                })),
                        localization.actions.requestAgain())
                ]}/>
        </Stack>
        : <Fragment/>;
}