import _, { Function0 } from "lodash";
import { useRef } from "react";
import { Optional } from "../types";
import { Cache, CacheKey } from "../utilities";

const globalOperationKeyToPersistentResultCache = new Cache<Function0<Promise<any>>>();

export function useGetOperation<TResult>(
    key: CacheKey,
    operation: Function0<Promise<TResult>>):
    Function0<Promise<TResult>> {
    const operationPromiseRef = useRef<Optional<Promise<TResult>>>(undefined);

    if (!globalOperationKeyToPersistentResultCache.has(key)) {
        globalOperationKeyToPersistentResultCache.set(
            key,
            () => {
                if (_.isNil(operationPromiseRef.current)) {
                    operationPromiseRef.current = operation();
                }

                return operationPromiseRef.current;
            });
    }

    return globalOperationKeyToPersistentResultCache.get(key)!;
}