import { InlineItems, InlineItemsVariant, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../common";


type InlineAzureKeyVaultVaultCertificatePermissionsProps = {
    permissions: Contract.AzureKeyVaultVaultCertificatePermission[];
    variant: InlineItemsVariant;
};

export function InlineAzureKeyVaultVaultCertificatePermissions({ permissions, variant }: InlineAzureKeyVaultVaultCertificatePermissionsProps) {
    const localization =
        useLocalization(
            "tenants.azure.inlineAzureKeyVaultVaultCertificatePermissions",
            () => ({
                permissions: [
                    "1 certificate permission",
                    "{{count | NumberFormatter.humanize}} certificate permissions"
                ],
                [Contract.TypeNames.AzureKeyVaultVaultCertificatePermission]: {
                    [Contract.AzureKeyVaultVaultCertificatePermission.All]: "All",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Backup]: "Backup",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Create]: "Create",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Delete]: "Delete",
                    [Contract.AzureKeyVaultVaultCertificatePermission.DeleteCertificateAuthorities]: "Delete Certificate Authorities",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Get]: "Get",
                    [Contract.AzureKeyVaultVaultCertificatePermission.GetCertificateAuthorities]: "Get Certificate Authorities",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Import]: "Import",
                    [Contract.AzureKeyVaultVaultCertificatePermission.List]: "List",
                    [Contract.AzureKeyVaultVaultCertificatePermission.ListCertificateAuthorities]: "List Certificate Authorities",
                    [Contract.AzureKeyVaultVaultCertificatePermission.ManageContacts]: "Manage Contacts",
                    [Contract.AzureKeyVaultVaultCertificatePermission.ManageCertificateAuthorities]: "Manage Certificate Authorities",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Purge]: "Purge",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Recover]: "Recover",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Restore]: "Restore",
                    [Contract.AzureKeyVaultVaultCertificatePermission.SetCertificateAuthorities]: "Set Certificate Authorities",
                    [Contract.AzureKeyVaultVaultCertificatePermission.Update]: "Update"
                }
            }));
    return (
        <InlineItems
            items={permissions}
            namePluralizer={localization.permissions}
            variant={variant}>
            {(permission: Contract.AzureKeyVaultVaultCertificatePermission) => localization[Contract.TypeNames.AzureKeyVaultVaultCertificatePermission][permission]()}
        </InlineItems>);
}