import { useDirectedGraphNodeContext } from "@infrastructure";
import React from "react";
import { Entity, EntityIcon } from "../../..";

type IconProps = {
    entityIds: string[];
    entityTypeName: string;
};

export function Icon({ entityIds, entityTypeName }: IconProps) {
    const { modelNode } = useDirectedGraphNodeContext();
    return entityIds.length === 1
        ? <Entity
            blurred={modelNode.options.appearance === "normal"}
            entityIdOrModel={entityIds[0]}
            glanceOptions={{ disabled: true }}
            linkOptions={{ disabled: true }}
            variant="icon"/>
        : <EntityIcon
            blurred={modelNode.options.appearance === "normal"}
            entityTypeName={entityTypeName}/>;
}