import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, NetworkScopeFormatter, useTheme } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, AwsEc2NetworkAclRulesTable, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useGetAwsEc2NetworkAclRiskContext } from "../contexts";

export function useAwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const networkAclInboundRuleSubnetAnyExistsRiskModel = riskModel as Contract.AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskModel;
    const networkAclInboundRuleSubnetAnyExistsRisk = riskModel.risk as Contract.AwsEc2NetworkAclInboundRuleSubnetAnyExistsRisk;
    const networkAclModel = entityModelStore.useGet(networkAclInboundRuleSubnetAnyExistsRisk.entityId) as Contract.AwsEc2NetworkAclModel;
    const networkAcl = networkAclModel.entity as Contract.AwsEc2NetworkAcl;
    const publicPorts =
        _.map(
            networkAclInboundRuleSubnetAnyExistsRiskModel.unrestrictedDestinationNetworkScopes,
            unrestrictedNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(unrestrictedNetworkScope));
    const getAwsEc2NetworkAclRiskContext = useGetAwsEc2NetworkAclRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskDefinition",
            () => ({
                attachedResources: {
                    attached: "The network ACL is attached to {{resources}}",
                    notAttached: "The network ACL is not attached to any resource"
                },
                description: "{{networkAcl}} allows inbound network traffic from any IP address on {{publicPorts}}",
                directWideRangeInboundExternalAttachedResources: "The network ACL is allowing direct public inbound network access to {{resources}} on {{publicPorts}}",
                lambdaFunctionConfigurationOnlyAttached: "The network ACL is only attached to lambda functions and affect outbound traffic",
                ports: {
                    allPorts: "all ports",
                    pluralizer: [
                        "1 port range",
                        "{{count | NumberFormatter.humanize}} port ranges"
                    ]
                },
                sections: {
                    inboundRules: "Inbound Rules",
                    resolution: {
                        step1: "In the **Inbound rules** tab, click **Edit inbound rules**",
                        step2: "For the relevant inbound rule, edit the address range to restrict it, or delete it by clicking **Remove**",
                        step3: "Click **Save changes** to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            networkAcl:
                <Entity
                    entityIdOrModel={networkAclModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            publicPorts:
                networkAclInboundRuleSubnetAnyExistsRiskModel.allPorts
                    ? localization.ports.allPorts()
                    : <InlineItems
                        items={publicPorts}
                        namePluralizer={localization.ports.pluralizer}
                        variant="itemCountAndType"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Vpc,
                AwsConsoleUrlBuilder.getEc2NetworkAclUrl(networkAcl)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        networkAclInboundRuleSubnetAnyExistsRiskModel,
        () => {
            const networkAclRiskContext = getAwsEc2NetworkAclRiskContext(networkAclModel);
            return [
                networkAclRiskContext.generalInformation,
                networkAclRiskContext.vpc,
                networkAclRiskContext.sensitive,
                new RiskDefinitionContextItem(
                    _.isEmpty(networkAclInboundRuleSubnetAnyExistsRisk.attachedResourceIds)
                        ? localization.attachedResources.notAttached()
                        : localization.attachedResources.attached({
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={networkAclInboundRuleSubnetAnyExistsRisk.attachedResourceIds}
                                    entityTypeName={Contract.TypeNames.AwsResource}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(networkAclInboundRuleSubnetAnyExistsRisk.directWideRangeInboundExternalAttachedResourceIds)
                    ? undefined
                    : new RiskDefinitionContextItem(localization.directWideRangeInboundExternalAttachedResources({
                        publicPorts:
                            networkAclInboundRuleSubnetAnyExistsRiskModel.allPorts
                                ? localization.ports.allPorts()
                                : <InlineItems
                                    items={publicPorts}
                                    namePluralizer={localization.ports.pluralizer}
                                    variant="itemCountAndType"/>,
                        resources:
                            <InlineEntities
                                entityIdsOrModels={networkAclInboundRuleSubnetAnyExistsRisk.directWideRangeInboundExternalAttachedResourceIds}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                variant="itemCountAndType"/>
                    })),
                networkAclInboundRuleSubnetAnyExistsRisk.lambdaFunctionConfigurationOnlyAttached
                    ? new RiskDefinitionContextItem(localization.lambdaFunctionConfigurationOnlyAttached())
                    : undefined,
                networkAclRiskContext.getOpenRiskedEntityRisksContextItem(networkAclInboundRuleSubnetAnyExistsRisk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AwsEc2NetworkAclRulesTable
                        getHighlightColor={
                            (rule, opacity) =>
                                _.some(
                                    networkAclInboundRuleSubnetAnyExistsRisk.unrestrictedInboundRules,
                                    unrestrictedInboundRule =>
                                        _.isEqual(
                                            unrestrictedInboundRule,
                                            rule))
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(networkAclInboundRuleSubnetAnyExistsRisk.severity), opacity)
                                        : theme.palette.severity(networkAclInboundRuleSubnetAnyExistsRisk.severity)
                                    : undefined
                        }
                        inbound={true}
                        rules={networkAcl.inboundRules}/>,
                    localization.sections.inboundRules(),
                    {
                        expandable: true
                    })
            ]
        });
}