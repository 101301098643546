import { InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, InlineEntities } from "../../../../../../../../../../../common";

type LoadBalancerTargetsCellProps = {
    applicationLoadBalancerIds: string[];
    functionConfigurationIds: string[];
    instanceIds: string[];
    ipAddresses: string[];
};

export function LoadBalancerTargetsCell({ applicationLoadBalancerIds, functionConfigurationIds, instanceIds, ipAddresses }: LoadBalancerTargetsCellProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.loadBalancerTargetsCell",
            () => ({
                ipAddresses: [
                    "1 IP Address",
                    "{{count | NumberFormatter.humanize}} IP Addresses"
                ]
            }));
    return (
        <Fragment>
            {!_.isEmpty(applicationLoadBalancerIds) &&
                <Typography>
                    <InlineEntities
                        entityIdsOrModels={applicationLoadBalancerIds}
                        entityTypeName={Contract.TypeNames.AwsElbApplicationLoadBalancer}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="itemCountAndType"/>
                </Typography>}
            {!_.isEmpty(functionConfigurationIds) &&
                <Typography>
                    <InlineEntities
                        entityIdsOrModels={functionConfigurationIds}
                        entityTypeName={Contract.TypeNames.AwsLambdaFunctionConfiguration}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="itemCountAndType"/>
                </Typography>}
            {!_.isEmpty(instanceIds) &&
                <Typography>
                    <InlineEntities
                        entityIdsOrModels={instanceIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="itemCountAndType"/>
                </Typography>}
            {!_.isEmpty(ipAddresses) &&
                <Typography>
                    <InlineItems
                        items={ipAddresses}
                        namePluralizer={localization.ipAddresses}
                        variant="itemCountAndType"/>
                </Typography>}
        </Fragment>);
}