import { useChangeEffect } from "@infrastructure";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract } from "../controllers";
import { ScopeNode, scopeNodeModelStore } from "../stores";
import { ScopeHelper } from "../utilities";
import { TenantMultiSelect, TenantMultiSelectProps } from "./TenantMultiSelect";

type ScopeMultiSelectProps =
    Pick<TenantMultiSelectProps, "analyticsOptions" | "disabled" | "displayAllAsEmpty" | "emptyValue" | "fullWidth" | "onClose" | "open" | "placeholder" | "rootFolderId" | "variant"> & {
        onSelectedScopeIdsChanged: (scopeIds: string[]) => void;
        scopeIds?: string[];
        selectedScopeIds: string[];
        selectionCompareVariant?: "organization" | "visibleOnly";
        tenantTypes?: Contract.TenantType[];
        validateScopeSelection?: (scopeId: string) => boolean;
    };

export function ScopeMultiSelect({ analyticsOptions, disabled, displayAllAsEmpty, emptyValue, fullWidth, onClose, onSelectedScopeIdsChanged, open, placeholder, rootFolderId, scopeIds, selectedScopeIds, selectionCompareVariant = "visibleOnly", tenantTypes, validateScopeSelection, variant }: ScopeMultiSelectProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(tenantTypes);
    const [rootScopeId, scopeTreeScopeNodeMap, tenantIds, visibleScopeTreeNodeMap] =
        useMemo(
            () => {
                const rootScopeId = rootFolderId ?? ScopeHelper.customerId;
                const tenantIds =
                    _.isNil(scopeIds)
                        ? scopeNodeMap[rootScopeId].tenantIds
                        : _.intersection(
                            scopeNodeMap[rootScopeId].tenantIds,
                            scopeIds);
                const visibleScopeTreeNodeMap = ScopeHelper.getScopeTree(scopeNodeMap, tenantIds);
                const scopeTreeScopeNodeMap =
                    ScopeHelper.getScopeTree(
                        scopeNodeMap,
                        _.map(
                            scopeNodeMap,
                            scopeNode => scopeNode.scopeNodeModel.configuration.id));

                return [rootScopeId, scopeTreeScopeNodeMap, tenantIds, visibleScopeTreeNodeMap];
            },
            [scopeNodeMap]);

    const [selectedTenantIds, setSelectedTenantIds] = useState<string[]>([]);
    useEffect(
        () => {
            const tenantIds =
                _(selectedScopeIds).
                    flatMap(selectedScopeId => visibleScopeTreeNodeMap[selectedScopeId]?.tenantIds ?? []).
                    uniq().
                    value();
            if (!_.isEqual(tenantIds, selectedTenantIds)) {
                setSelectedTenantIds(tenantIds);
            }
        },
        [selectedScopeIds]);

    useChangeEffect(
        () => {
            function getSelectedScopeIds(scopeNode: ScopeNode): string[] {
                if (scopeNode.scopeNodeModel.type === Contract.ScopeType.Customer ||
                    ScopeHelper.isFolder(scopeNode.scopeNodeModel)) {
                    const selectedScopeIds =
                        _.flatMap(
                            scopeNode.scopeNodes,
                            scopeNode => getSelectedScopeIds(scopeNode));
                    return _(selectionCompareVariant === "visibleOnly"
                        ? scopeNode.scopeNodes
                        : scopeTreeScopeNodeMap[scopeNode.scopeNodeModel.configuration.id].scopeNodes).
                        map(scopeNode => scopeNode.scopeNodeModel.configuration.id).
                        xor(selectedScopeIds).
                        isEmpty() &&
                        (validateScopeSelection?.(scopeNode.scopeNodeModel.configuration.id) ?? true)
                        ? [scopeNode.scopeNodeModel.configuration.id]
                        : selectedScopeIds;
                } else {
                    return _.includes(selectedTenantIds, scopeNode.scopeNodeModel.configuration.id)
                        ? [scopeNode.scopeNodeModel.configuration.id]
                        : [];
                }
            }

            const scopeIds = getSelectedScopeIds(visibleScopeTreeNodeMap[rootScopeId]);
            if (!_.isEqual(scopeIds, selectedScopeIds)) {
                onSelectedScopeIdsChanged(scopeIds);
            }
        },
        [selectedTenantIds]);

    return (
        <TenantMultiSelect
            analyticsOptions={analyticsOptions}
            disabled={disabled}
            displayAllAsEmpty={displayAllAsEmpty}
            emptyValue={emptyValue}
            fullWidth={fullWidth}
            open={open}
            placeholder={placeholder}
            rootFolderId={rootFolderId}
            selectedTenantIds={selectedTenantIds}
            tenantIds={tenantIds}
            variant={variant}
            onClose={onClose}
            onSelectedTenantIdsChanged={selectedTenantIds => setSelectedTenantIds(selectedTenantIds)}/>);
}