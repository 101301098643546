import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsElasticBeanstalkEnvironmentRiskContext() {
    return useMemo(
        () => useAwsElasticBeanstalkEnvironmentRiskContext,
        []);
}

function useAwsElasticBeanstalkEnvironmentRiskContext(environmentModel: Contract.AwsElasticBeanstalkEnvironmentModel) {
    const environment = environmentModel.entity as Contract.AwsElasticBeanstalkEnvironment;
    const resourceContextItems = useGetAwsResourceRiskContext()(environmentModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsElasticBeanstalkEnvironmentRiskContext",
            () => ({
                cloudWatchLogRetentionTimeFrame: "The retention period is currently set to **{{cloudWatchLogRetentionTimeFrame}}**"
            }));

    return {
        ...resourceContextItems,
        cloudWatchLogRetentionTimeFrame:
            new RiskDefinitionContextItem(
                localization.cloudWatchLogRetentionTimeFrame({ cloudWatchLogRetentionTimeFrame: TimeSpanFormatter.day(environment.cloudWatchLogRetentionTimeFrame) }))
    };
}