﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useAwsBedrockAgentStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";
import { Aliases, Revisions } from "./components";

export function useAwsBedrockAgentDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const agent = resourceModel.entity as Contract.AwsBedrockAgent;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const agentStatusTranslator = useAwsBedrockAgentStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsBedrockAgentDefinition",
            () => ({
                info: {
                    items: {
                        description: "Description",
                        rawId: "Id",
                        roleReference: "Role",
                        status: "Status",
                        updateTime: "Update Time"
                    }
                },
                tabs: {
                    aliases: "Aliases ({{aliasCount | NumberFormatter.humanize}})",
                    revisions: "Revisions ({{revisionCount | NumberFormatter.humanize}})"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isEmpty(agent.revisionMap),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <Revisions agentModel={resourceModel as Contract.AwsBedrockAgentModel}/>,
                        localization.tabs.revisions({ revisionCount: _.size(agent.revisionMap) }),
                        "revisions")).
                concatIf(
                    !_.isEmpty(agent.aliases),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <Aliases agent={agent}/>,
                        localization.tabs.aliases({ aliasCount: agent.aliases.length }),
                        "aliases")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={agent.rawId}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={agent.description}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={agentStatusTranslator(agent.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={agent.roleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="roleReference"
                        title={localization.info.items.roleReference()}/>,
                    <InfoItem
                        key="updateTime"
                        title={localization.info.items.updateTime()}
                        value={TimeFormatter.profileFormatDateTime(agent.updateTime)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}