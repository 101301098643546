import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureNetworkPrivateEndpointConnectionStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureNetworkPrivateEndpointConnectionStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureNetworkPrivateEndpointConnectionStatus]: {
                    [Contract.AzureNetworkPrivateEndpointConnectionStatus.Approved]: "Approved",
                    [Contract.AzureNetworkPrivateEndpointConnectionStatus.Disconnected]: "Disconnected",
                    [Contract.AzureNetworkPrivateEndpointConnectionStatus.Pending]: "Pending",
                    [Contract.AzureNetworkPrivateEndpointConnectionStatus.Rejected]: "Rejected"
                }
            }));

    return (connectionStatus: Contract.AzureNetworkPrivateEndpointConnectionStatus) =>
        localization[Contract.TypeNames.AzureNetworkPrivateEndpointConnectionStatus][connectionStatus]();
}