﻿import { Cache } from "@infrastructure";
import { Contract, RiskController } from "../../../../../../../../../../../common";

const getRiskCodeFileDiffsResultCache = new Cache<Contract.RiskControllerGetRiskCodeFileDiffsResponse>();

export async function getRiskCodeFileDiffsCache(riskId: string) {
    if (!getRiskCodeFileDiffsResultCache.has(riskId)) {
        const getRiskCodeFileDiffsResponse = await RiskController.getRiskCodeFileDiffs(new Contract.RiskControllerGetRiskCodeFileDiffsRequest(riskId));
        getRiskCodeFileDiffsResultCache.set(riskId, getRiskCodeFileDiffsResponse);
    }

    return getRiskCodeFileDiffsResultCache.get(riskId)!;
}