import { AnalyticsContext, ApplicationThemeProvider, ApplicationViewValue, defined, i18nInitialize, Loading, map, RouteContext, RouteType, ToastContainer, TypographyTooltipProvider, useApplicationContextProvider, useRoute, useRouteContextProvider, useSetApplicationContext } from "@infrastructure";
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { ApplicationView, Contract, GeneralInformation, MuiHelper } from "./common";
import { Customer, GeographySelector, System, Teams, User } from "./views";
import "./infrastructure/assets/fonts/inter/inter.css";
import "./infrastructure/packages/lodash";
import "./infrastructure/packages/moment";
import "./infrastructure/packages/mustache";

i18nInitialize(`/api/console/localization/getLocalization?v=${GeneralInformation.assemblyFileVersion}&language={{lng}}`);

function Application() {
    useEffect(
        () => {
            MuiHelper.initialize();
        },
        []);
    const [, , ContextProvider] = useRouteContextProvider(() => new RouteContext(RouteType.Url));
    const [, , ApplicationContextProvider] =
        useApplicationContextProvider(
            () => ({
                customerActionAvailable: false,
                permittedContent: true
            }),
            []);

    return (
        <ApplicationContextProvider>
            <ContextProvider>
                <Stack sx={{ height: "100dvh" }}>
                    <Core/>
                </Stack>
            </ContextProvider>
        </ApplicationContextProvider>);
}

function Core() {
    defined(Contract);
    const { view } = useRoute("/{view}");
    const setApplicationContext = useSetApplicationContext();

    useEffect(
        () => {
            setApplicationContext(
                context => ({
                    ...context,
                    viewValue: view as ApplicationViewValue
                }));
        },
        [view]);

    return (
        <ApplicationThemeProvider>
            <TypographyTooltipProvider/>
            {view !== ApplicationView.System && <ToastContainer/>}
            <Loading root={true}>
                <AnalyticsContext
                    context={view}
                    root={true}>
                    <GeographySelector>
                        {() =>
                            map(
                                view,
                                {
                                    [ApplicationView.Customer]: () => <Customer/>,
                                    [ApplicationView.System]: () => <System/>,
                                    [ApplicationView.Teams]: () => <Teams/>,
                                    [ApplicationView.User]: () => <User/>
                                })}
                    </GeographySelector>
                </AnalyticsContext>
            </Loading>
        </ApplicationThemeProvider>);
}

const containerElement = document.getElementById("root");
const root = createRoot(containerElement!);
root.render(<Application/>);