import { MouseEvent } from "react";

export class MouseHelper {
    public static isAlterActionClick(event: MouseEvent): boolean {
        return event.ctrlKey || event.metaKey;
    }

    public static isGlanceClick(event: MouseEvent): boolean {
        return MouseHelper.isAlterActionClick(event) && event.shiftKey;
    }
}