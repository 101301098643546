import { ApiError, map, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, TenantController, useTheme } from "../../../../../../../../../../../../common";
import { useAzureTenantManagerErrorTranslator } from "../../../../../../../../../../../../tenants";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../index";
import { Arm, Manual, PowerShell } from "./components";

export function RoleDefinitionsItem() {
    const { aadTenantModel, parentFolderId, rawShortId, tenantModel, tenantPermissionTypes } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const { useNextEffect } = useOrderedWizardContext();

    const tenantManagerErrorTranslator = useAzureTenantManagerErrorTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.addOrEdit.roleDefinitionsItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            general: {
                                add: "Failed to add subscription",
                                edit: "Failed to update subscription"
                            }
                        }
                    }
                },
                onboardingType: {
                    [OnboardingType.Arm]: "ARM (Azure Portal)",
                    [OnboardingType.Manual]: "Manual (Azure Portal)",
                    [OnboardingType.PowerShell]: "PowerShell (Azure CLI)"
                }
            }));

    useNextEffect(
        async () => {
            try {
                const { tenantModel: updatedTenantModel } =
                    _.isNil(tenantModel)
                        ? await TenantController.insertAzureTenant(
                            new Contract.TenantControllerInsertAzureTenantRequest(
                                aadTenantModel!.configuration.id,
                                parentFolderId,
                                tenantPermissionTypes!,
                                rawShortId!))
                        : await TenantController.updateAzureTenant(
                            new Contract.TenantControllerUpdateAzureTenantRequest(
                                tenantPermissionTypes!,
                                tenantModel.configuration.id));
                setAddOrEditContext(
                    addContext => ({
                        ...addContext,
                        updatedTenantModel
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? tenantManagerErrorTranslator(error.error as Contract.AzureTenantManagerError)
                    : localization.actions.save.error.general[
                        _.isNil(tenantModel)
                            ? "add"
                            : "edit"]();
            }

            return undefined;
        },
        []);

    const onboardingTypes =
        useMemo(
            () =>
                _<OnboardingType>([]).
                    concatIf(
                        _.isNil(tenantModel),
                        OnboardingType.Arm,
                        OnboardingType.PowerShell).
                    concat(OnboardingType.Manual).
                    value(),
            []);

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(() => _.first(onboardingTypes));
    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={selectedOnboardingType}
                variant="standard"
                onChange={(_, value) => setSelectedOnboardingType(value)}>
                {_.map(
                    onboardingTypes,
                    onboardingType =>
                        <Tab
                            key={onboardingType}
                            label={localization.onboardingType[onboardingType]()}
                            sx={{ padding: theme.spacing(1, 2) }}
                            value={onboardingType}/>)}
            </Tabs>
            <Stack
                key={selectedOnboardingType}
                sx={{ overflow: "hidden auto" }}>
                <Box sx={{ maxWidth: theme.spacing(80) }}>
                    {map(
                        selectedOnboardingType,
                        {
                            [OnboardingType.Arm]: () => <Arm/>,
                            [OnboardingType.Manual]: () => <Manual/>,
                            [OnboardingType.PowerShell]: () => <PowerShell/>
                        })}
                </Box>
            </Stack>
        </Stack>);
}

enum OnboardingType {
    Arm = "arm",
    Manual = "manual",
    PowerShell = "powerShell"
}