import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsIamUserRiskContext } from "../contexts";

export function useAwsIamUserAttachedPolicyRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamUserAttachedPolicyRisk;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamUserModel;

    const getAwsIamUserRiskContext = useGetAwsIamUserRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserAttachedPolicyRiskDefinition",
            () => ({
                description: "{{user}} has policies attached",
                sections: {
                    resolution: {
                        step1: "A new IAM group needs to be created and assigned to the user before detaching the policies so the permissions won't get lost.",
                        step2: {
                            step1: "In the menu, click **User Groups** and then click **Create group**",
                            step2: "In the **Add users to the group**, search for {{user}} and select it",
                            step3: "In the **Attach permissions policies**, select the check box for each policy that you want to attach. If the user has an inline policy, make sure to create a dedicated policy beforehand",
                            step4: "Click **Create user group**",
                            title: "Create IAM Group"
                        },
                        step3: {
                            step1: "In the IAM console, click **Users**",
                            step2: "Select the {{user}}",
                            step3: "In the **Permissions** tab, select all policies that are attached **Directly** or **Inline** and click **Remove**",
                            title: "Remove the direct association between a user and policy"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamUrl(tenantConfiguration.partitionType)),
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2({
                                user:
                                    <Entity
                                        entityIdOrModel={userModel}
                                        variant="text"/>
                            })}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step3.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step3.step1()}
                            {localization.sections.resolution.step3.step2({
                                user:
                                    <Entity
                                        entityIdOrModel={userModel}
                                        variant="typeText"/>
                            })}
                            {localization.sections.resolution.step3.step3()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const userRiskContext = getAwsIamUserRiskContext(userModel);
            return [
                userRiskContext.generalInformation,
                userRiskContext.permissionActionSeverity,
                userRiskContext.attachedPolicies,
                userRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}