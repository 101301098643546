import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetDataAnalysisResourceRiskContext } from "../../../useGetDataAnalysisResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureStorageStorageAccountBlobContainerRiskContext() {
    return useMemo(
        () => useAzureStorageStorageAccountBlobContainerRiskContext,
        []);
}

function useAzureStorageStorageAccountBlobContainerRiskContext(blobContainerModel: Contract.AzureStorageStorageAccountBlobContainerModel) {
    const blobContainer = blobContainerModel.entity as Contract.AzureStorageStorageAccountBlobContainer;
    const dataAnalysisResourceRiskContext = useGetDataAnalysisResourceRiskContext()(blobContainerModel);
    const resourceRiskContext = useGetAzureResourceRiskContext()(blobContainerModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureStorageStorageAccountBlobContainerRiskContext",
            () => ({
                storageAccount: "The {{translatedBlobContainerTypeName}} is part of {{storageAccount}}",
                [Contract.TypeNames.AzureStorageStorageAccountBlobContainerPublicAccess]: {
                    [Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Blob]: "The {{translatedBlobContainerTypeName}}'s access level is **Blob**",
                    [Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Container]: "The {{translatedBlobContainerTypeName}}'s access level is **Container**"
                }
            }));
    const translatedBlobContainerTypeName =
        entityTypeNameTranslator(
            blobContainer.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...dataAnalysisResourceRiskContext,
        ...resourceRiskContext,
        effectivePublicAccess: new RiskDefinitionContextItem(localization[Contract.TypeNames.AzureStorageStorageAccountBlobContainerPublicAccess][blobContainer.effectivePublicAccess as Exclude<Contract.AzureStorageStorageAccountBlobContainerPublicAccess, Contract.AzureStorageStorageAccountBlobContainerPublicAccess.None>]({ translatedBlobContainerTypeName })),
        storageAccount:
            new RiskDefinitionContextItem(
                localization.storageAccount({
                    storageAccount:
                        <Entity
                            entityIdOrModel={blobContainer.storageAccountId}
                            variant="typeText"/>,
                    translatedBlobContainerTypeName
                }))
    };
}