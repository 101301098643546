﻿import { AddIcon, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, Dialog, EmptyMessageText, makeContextProvider, Optional, optionalTableCell, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useRef } from "react";
import { Contract, Identity, ItemTable, Scope, ScopeNameTranslatorOptions, scopeNodeModelStore, useScopeNameTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../common";
import { ActionsCell, Add } from "./components";

class TrustedFile {
    constructor(
        public inheritedScopeId: Optional<string>,
        public scopeConfigurationTrustedFile: Contract.ScopeConfigurationWorkloadAnalysisTrustedFile
    ) {
    }
}

class TrustedFilesContext {
    constructor(
        public addOpen: boolean,
        public scopeConfigurationTrustedFiles: Contract.ScopeConfigurationWorkloadAnalysisTrustedFile[]) {
    }
}

export const [useTrustedFilesContext, useSetTrustedFilesContext, useTrustedFilesContextProvider] = makeContextProvider<TrustedFilesContext>();

export function TrustedFiles() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    const [context, setContext, ContextProvider] =
        useTrustedFilesContextProvider(
            () =>
                new TrustedFilesContext(
                    false,
                    scopeNodeModel.configuration.scopeSections.workloadAnalysis.trustedFiles));

    const inheritedTrustedFiles =
        useMemo(
            () =>
                _.flatMap(
                    scopeNodeMap[scopeNodeModel.configuration.id].
                        parentScopeIds.
                        slice().
                        reverse(),
                    parentScopeId =>
                        _.map(
                            scopeNodeMap[parentScopeId].scopeNodeModel.configuration.scopeSections.workloadAnalysis.trustedFiles,
                            scopeConfigurationTrustedFile =>
                                new TrustedFile(
                                    parentScopeId,
                                    scopeConfigurationTrustedFile))),
            [scopeNodeModel]);

    const trustedFiles =
        useMemo(
            () =>
                inheritedTrustedFiles.concat(
                    _.map(
                        context.scopeConfigurationTrustedFiles,
                        scopeConfigurationTrustedFile =>
                            new TrustedFile(
                                undefined,
                                scopeConfigurationTrustedFile))),
            [context.scopeConfigurationTrustedFiles]);

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [context.scopeConfigurationTrustedFiles]);

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.maliciousFiles.trustedFiles",
            () => ({
                actions: {
                    add: "Add Trusted File SHA-256 Hashes"
                },
                columns: {
                    comment: "Comment",
                    contentSha256String: "SHA-256",
                    identity: "Added by",
                    inherited: "Inherited"
                },
                description: "You can specify SHA-256 hashes of files which will be considered trusted.",
                empty: "No hashes",
                title: "Trusted File SHA-256 Hashes"
            }));

    const scopeNameTranslator = useScopeNameTranslator();
    const scopeNameTranslationOptions: ScopeNameTranslatorOptions = { path: true };
    const theme = useTheme();
    return (
        <ContextProvider>
            {context.addOpen &&
                <Dialog
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOpen: false
                                }))}>
                    <Add/>
                </Dialog>}
            <Stack spacing={1}>
                <Typography variant="h4">
                    {localization.title()}
                </Typography>
                <Typography>
                    {localization.description()}
                </Typography>
            </Stack>
            <Box sx={{ overflow: "hidden" }}>
                <ItemTable
                    actionsRef={dataTableActionsRef}
                    columnIdToGetItemValueMap={{
                        [TrustedFileColumnId.Comment]: trustedFile => StringHelper.getSortValue(trustedFile.scopeConfigurationTrustedFile.comment),
                        [TrustedFileColumnId.ContentSha256String]: trustedFile => StringHelper.getSortValue(trustedFile.scopeConfigurationTrustedFile.contentSha256String),
                        [TrustedFileColumnId.Identity]: trustedFile => StringHelper.getSortValue(trustedFile.scopeConfigurationTrustedFile.identityReference?.name),
                        [TrustedFileColumnId.Inherited]: trustedFile =>
                            StringHelper.getSortValue(
                                _.isNil(trustedFile.inheritedScopeId)
                                    ? undefined
                                    : scopeNameTranslator(trustedFile.inheritedScopeId, scopeNameTranslationOptions))
                    }}
                    defaultSortColumnIdOrIds={TrustedFileColumnId.ContentSha256String}
                    emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                    getItemId={(trustedFile: TrustedFile) => `${trustedFile.scopeConfigurationTrustedFile.contentSha256String}:${trustedFile.inheritedScopeId}`}
                    items={trustedFiles}
                    showEmptyTable={true}
                    sx={{
                        maxHeight: theme.spacing(80),
                        minHeight: theme.spacing(25)
                    }}
                    variant="view">
                    {() => [
                        <DataTableAction key="DataTableAction_Add">
                            <Button
                                startIcon={<AddIcon/>}
                                onClick={
                                    () =>
                                        setContext(
                                            context => ({
                                                ...context,
                                                addOpen: true
                                            }))}>
                                {localization.actions.add()}
                            </Button>
                        </DataTableAction>,
                        <DataTableColumn
                            id={TrustedFileColumnId.ContentSha256String}
                            itemProperty={trustedFile => trustedFile.scopeConfigurationTrustedFile.contentSha256String}
                            key={TrustedFileColumnId.ContentSha256String}
                            title={localization.columns.contentSha256String()}/>,
                        <DataTableColumn
                            id={TrustedFileColumnId.Identity}
                            key={TrustedFileColumnId.Identity}
                            render={optionalTableCell<TrustedFile>(
                                trustedFile =>
                                    _.isNil(trustedFile.scopeConfigurationTrustedFile.identityReference)
                                        ? undefined
                                        : <Identity
                                            identityIdOrReference={trustedFile.scopeConfigurationTrustedFile.identityReference}
                                            variant="text"/>)}
                            title={localization.columns.identity()}/>,
                        <DataTableColumn
                            id={TrustedFileColumnId.Comment}
                            key={TrustedFileColumnId.Comment}
                            render={optionalTableCell<TrustedFile>(trustedFile => trustedFile.scopeConfigurationTrustedFile.comment)}
                            title={localization.columns.comment()}/>,
                        <DataTableColumn
                            id={TrustedFileColumnId.Inherited}
                            key={TrustedFileColumnId.Inherited}
                            render={
                                optionalTableCell<TrustedFile>(
                                    trustedFile =>
                                        _.isNil(trustedFile.inheritedScopeId)
                                            ? undefined
                                            : <Scope
                                                scopeId={trustedFile.inheritedScopeId!}
                                                scopeNameTranslatorOptions={scopeNameTranslationOptions}
                                                variant="text"/>)}
                            title={localization.columns.inherited()}/>,
                        <DataTableColumn
                            id={TrustedFileColumnId.Actions}
                            key={TrustedFileColumnId.Actions}
                            render={
                                ({ item }: DataTableColumnRenderProps<TrustedFile>) =>
                                    !_.isNil(item.inheritedScopeId)
                                        ? <Fragment/>
                                        : <ActionsCell scopeConfigurationTrustedFile={item.scopeConfigurationTrustedFile}/>}/>
                    ]}
                </ItemTable>
            </Box>
        </ContextProvider>);
}

enum TrustedFileColumnId {
    Actions = "actions",
    Comment = "comment",
    ContentSha256String = "contentSha256",
    Identity = "identity",
    Inherited = "inherited"
}