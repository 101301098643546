import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpFunctionsFunctionTriggerTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpFunctionsFunctionTriggerTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpFunctionsFunctionTriggerType]: {
                    [Contract.GcpFunctionsFunctionTriggerType.Event]: "Event",
                    [Contract.GcpFunctionsFunctionTriggerType.Http]: "HTTP"
                }
            }));
    return (triggerType: Contract.GcpFunctionsFunctionTriggerType) =>
        localization[Contract.TypeNames.GcpFunctionsFunctionTriggerType][triggerType]();
}