import { app, authentication } from "@microsoft/teams-js";

export class TeamsHelper {

    public static async getUserJwtToken() {
        return await authentication.getAuthToken();
    }

    public static async getTheme() {
        const context = await app.getContext();
        return context.app.theme;
    }

    public static async initialize() {
        await app.initialize();
    }

    public static get registerOnThemeChangeHandler() {
        return app.registerOnThemeChangeHandler;
    }
}