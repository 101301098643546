import { TextViewer, useLocalization, VerticalFillGrid } from "@infrastructure";
import { Box, Button, Stack } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useTheme } from "../../../../..";
import { useChangeInfoContext } from "../ChangeInfo";

type AwsUpsertPolicyChangeProps = {
    subTitleElement?: ReactNode;
    titleElement: ReactNode;
    updatedRawDocument: string;
    updateRawDocument: (rawDocument: string) => void;
};

export function AwsUpsertPolicyChangeInfo({ subTitleElement, titleElement, updatedRawDocument, updateRawDocument }: AwsUpsertPolicyChangeProps) {
    const { updateChange } = useChangeInfoContext();
    const [edit, setEdit] = useState(false);
    const [rawDocument, setRawDocument] = useState(updatedRawDocument);
    const [valid, setValid] = useState(true);

    const theme = useTheme();
    const localization =
        useLocalization(
            "common.riskResolutionAutomation.changeInfo.awsUpsertPolicyChangeInfo",
            () => ({
                actions: {
                    edit: "Edit",
                    save: "Save"
                }
            }));
    return (
        <VerticalFillGrid>
            {titleElement}
            <Box
                sx={{
                    height: "100%",
                    padding: theme.spacing(2)
                }}>
                <Stack
                    spacing={4}
                    sx={{
                        borderRadius: theme.spacing(0.75),
                        height: "100%",
                        padding: theme.spacing(4, 2)
                    }}>
                    {subTitleElement}
                    <Box sx={{ flex: 1 }}>
                        <TextViewer
                            actionsElement={
                                <Button
                                    disabled={edit && !valid}
                                    sx={{
                                        padding: theme.spacing(1, 3)
                                    }}
                                    variant={
                                        edit
                                            ? "contained"
                                            : "outlined"}
                                    onClick={
                                        () => {
                                            if (edit) {
                                                updateChange(() => updateRawDocument(rawDocument));
                                            }

                                            setEdit(!edit);
                                        }}>
                                    {edit
                                        ? localization.actions.save()
                                        : localization.actions.edit()}
                                </Button>}
                            format="json"
                            height="100%"
                            options={{ readOnly: !edit }}
                            text={rawDocument}
                            onChange={rawDocument => setRawDocument(rawDocument!)}
                            onValidChanged={rawDocumentValid => setValid(rawDocumentValid)}/>
                    </Box>
                </Stack>
            </Box>
        </VerticalFillGrid>);
}