import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantIcon, useTenantTypeTranslator } from "../../../../../../../common";
import { IdentityProviderTenantTable, IntegrationInformation } from "../components";

type GroupMembershipProps = {
    tenantType: Contract.TenantType;
};

export function GroupMembership({ tenantType }: GroupMembershipProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.groupMembership",
            () => ({
                description: {
                    items: {
                        item1: "Administrators can create eligibilities that allow requesters to request temporary group memberships.",
                        item2: {
                            withDocumentation: {
                                link: "Learn how to grant permissions",
                                text: "Requires permission to add and remove users from groups. {{link}}"
                            },
                            withoutDocumentation: "Requires permission to add and remove users from groups."
                        }
                    }
                },
                title: "{{translatedTenantType}} group memberships"
            }));

    const documentationUrl =
        tenantType === Contract.TenantType.Aad
            ? undefined
            : CustomerConsoleAppUrlHelper.getDocsGrantTenableJitPermissionsRelativeUrl(tenantType);

    const tenantTypeTranslator = useTenantTypeTranslator();
    return (
        <IntegrationInformation
            icon={
                <TenantIcon
                    sx={{ fontSize: "30px" }}
                    tenantType={tenantType}/>}
            items={[
                localization.description.items.item1(),
                _.isNil(documentationUrl)
                    ? localization.description.items.item2.withoutDocumentation()
                    : localization.description.items.item2.withDocumentation.text({
                        link:
                            <Link
                                urlOrGetUrl={documentationUrl}
                                variant="external">
                                {localization.description.items.item2.withDocumentation.link()}
                            </Link>
                    })]}
            title={localization.title({ translatedTenantType: tenantTypeTranslator(tenantType) })}>
            <IdentityProviderTenantTable tenantType={tenantType}/>
        </IntegrationInformation>);
}