import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useCommonEntityInfoItemElements } from "../useCommonEntityInfoItemElements";

export function usePingIdentityCommonDirectoryEntityInfoItemElements(directoryEntityModel: Contract.PingIdentityDirectoryEntityModel) {
    const commonEntityInfoItemElements = useCommonEntityInfoItemElements(directoryEntityModel);
    const directoryEntity = directoryEntityModel.entity as Contract.PingIdentityDirectoryEntity;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.pingIdentity.usePingIdentityCommonDirectoryEntityInfoItemElements",
            () => ({
                creationTime: "Creation Time",
                rawId: "Id",
                syncTime: "Sync Time",
                tenant: "Environment"
            }));
    return [
        commonEntityInfoItemElements.typeNameInfoElement,
        <InfoItem
            key="rawId"
            title={localization.rawId()}
            value={(directoryEntityModel.entity as Contract.PingIdentityDirectoryEntity).rawId}/>,
        <InfoItem
            key="creationTime"
            location="all"
            title={localization.creationTime()}
            value={TimeFormatter.profileFormatDateTime(directoryEntity.creationTime)}/>,
        <InfoItem
            key="syncTime"
            title={localization.syncTime()}
            value={TimeFormatter.profileFormatDateTime(directoryEntity.syncTime)}/>
    ];
}