import { useLocalization } from "@infrastructure";
import { useCallback } from "react";
import { Contract } from "../../../../../../../common";

export function useViewTranslator() {
    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useViewTranslator",
            () => ({
                [Contract.TypeNames.RisksView]: {
                    [Contract.RisksView.Closed]: "Closed",
                    [Contract.RisksView.Ignored]: "Ignored",
                    [Contract.RisksView.Open]: "Open"
                }
            }));

    return useCallback(
        (riskView: Contract.RisksView) => localization[Contract.TypeNames.RisksView][riskView](),
        [localization]);
}