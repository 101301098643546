import { DataTableActions, Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../common";

export class WorkloadResourceModelRequestFiltersHelper {
    public static getVirtualMachineFilters(
        filterMap: Dictionary<any>,
        dataTableActionsRef: React.MutableRefObject<DataTableActions | undefined>,
        vulnerabilityRawId?: string) {
        return new Contract.WorkloadControllerVirtualMachineModelFilters(
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceAttribute]),
            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable]),
            filterMap[Contract.WorkloadControllerRequestProperty.FixableVulnerability] as Optional<boolean>,
            false,
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemDisplayName]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemType]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageDisplayName]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.Risks]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(
                dataTableActionsRef.current?.getFiltersTime(),
                filterMap[Contract.WorkloadControllerRequestProperty.ScanTimeRange]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.TenantId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector]),
            _.isNil(vulnerabilityRawId)
                ? ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityRawId])
                : new Contract.PagedItemSelection<string>(
                    false,
                    [vulnerabilityRawId],
                    Contract.PagedItemSelectionType.Include) as Optional<Contract.PagedItemSelection<string>>,
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VirtualMachineId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.IdentityPermissionActionSeverity]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.InboundExternalDestinationNetworkScopes]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.KubernetesClusterSensor]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.NetworkInboundAccessType]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.NetworkInboundExternalAccessScope]),
            ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.ResourceTags]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceStatus]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadAnalysisStatus]));
    }

    public static getVirtualMachineImageFilters(
        filterMap: Dictionary<any>,
        dataTableActionsRef: React.MutableRefObject<DataTableActions | undefined>) {
        return new Contract.WorkloadControllerVirtualMachineImageModelFilters(
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceAttribute]),
            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable]),
            filterMap[Contract.WorkloadControllerRequestProperty.FixableVulnerability] as Optional<boolean>,
            false,
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemDisplayName]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemType]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageDisplayName]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.Risks]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(
                dataTableActionsRef.current?.getFiltersTime(),
                filterMap[Contract.WorkloadControllerRequestProperty.ScanTimeRange]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.TenantId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityRawId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(
                dataTableActionsRef.current?.getFiltersTime(),
                filterMap[Contract.WorkloadControllerRequestProperty.CreationTime]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VirtualMachineImageRawId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.RegionId]),
            ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.ResourceTags]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VirtualMachines]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VirtualMachineImageAccessLevel]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VirtualMachineImageManagementType]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadAnalysisStatus]));
    }

    public static getContainerImageFilters(
        filterMap: Dictionary<any>,
        dataTableActionsRef: React.MutableRefObject<DataTableActions | undefined>,
        vulnerabilityRawId?: string) {
        return new Contract.WorkloadControllerContainerImageModelFilters(
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceAttribute]),
            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable]),
            filterMap[Contract.WorkloadControllerRequestProperty.FixableVulnerability] as boolean,
            false,
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemDisplayName]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemType]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageDisplayName]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.Risks]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.WorkloadControllerRequestProperty.ScanTimeRange]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.TenantId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector]),
            _.isNil(vulnerabilityRawId)
                ? ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityRawId])
                : new Contract.PagedItemSelection<string>(
                    false,
                    [vulnerabilityRawId],
                    Contract.PagedItemSelectionType.Include) as Optional<Contract.PagedItemSelection<string>>,
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageCreationTime]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageDigest]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageKubernetesWorkloadResources]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageRepository]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageTags]),
            filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadExists] as Optional<boolean>,
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VirtualMachines]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadClusterResources]));
    }

    public static toFilterMapFromContainerImageFilters(filters: Contract.WorkloadControllerContainerImageModelFilters): Dictionary<any> {
        return {
            ...this.toFilterMapFromWorkloadResourceModelFilters(filters),
            [Contract.WorkloadControllerRequestProperty.ContainerImageCreationTime]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.containerImageCreationTimeRangeSelection),
            [Contract.WorkloadControllerRequestProperty.ContainerImageDigest]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.digestSelection),
            [Contract.WorkloadControllerRequestProperty.ContainerImageId]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.workloadResourceIdsSelection),
            [Contract.WorkloadControllerRequestProperty.ContainerImageKubernetesWorkloadResources]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.kubernetesWorkloadResourceIdSelection),
            [Contract.WorkloadControllerRequestProperty.ContainerImageRepository]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.repositoryIdSelection),
            [Contract.WorkloadControllerRequestProperty.ContainerImageTags]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.tagsSelection),
            [Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadClusterResources]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.workloadClusterResourceIdSelection),
            [Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadExists]: filters.usage,
            [Contract.WorkloadControllerRequestProperty.VirtualMachines]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.virtualMachineIdSelection)
        };
    }

    public static toFilterMapFromVirtualMachineFilters(filters: Contract.WorkloadControllerVirtualMachineModelFilters): Dictionary<any> {
        return {
            ...this.toFilterMapFromWorkloadResourceModelFilters(filters),
            [Contract.WorkloadControllerRequestProperty.IdentityPermissionActionSeverity]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.identityPermissionActionSeveritySelection),
            [Contract.WorkloadControllerRequestProperty.InboundExternalDestinationNetworkScopes]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.inboundExternalDestinationNetworkScopesSelection),
            [Contract.WorkloadControllerRequestProperty.KubernetesClusterSensor]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.kubernetesClusterSensorSelection),
            [Contract.WorkloadControllerRequestProperty.NetworkInboundAccessType]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.networkInboundAccessTypeSelection),
            [Contract.WorkloadControllerRequestProperty.NetworkInboundExternalAccessScope]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.networkInboundExternalAccessScopeSelection),
            [Contract.WorkloadControllerRequestProperty.ResourceTags]: ItemSelectionHelper.toPagedValuesFilterSelectionFromResourceTagItemSelection(filters.resourceTagSelection),
            [Contract.WorkloadControllerRequestProperty.VirtualMachineId]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.workloadResourceIdsSelection),
            [Contract.WorkloadControllerRequestProperty.WorkloadAnalysisStatus]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.workloadAnalysisStatusSelection),
            [Contract.WorkloadControllerRequestProperty.WorkloadResourceStatus]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.statusSelection)
        };
    }

    private static toFilterMapFromWorkloadResourceModelFilters(filters: Contract.WorkloadControllerWorkloadResourceModelFilters): Dictionary<any> {
        return {
            [Contract.WorkloadControllerRequestProperty.FixableVulnerability]: filters.fixable,
            [Contract.WorkloadControllerRequestProperty.OperatingSystemDisplayName]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.operatingSystemDisplayNameSelection),
            [Contract.WorkloadControllerRequestProperty.OperatingSystemType]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.operatingSystemTypeSelection),
            [Contract.WorkloadControllerRequestProperty.PackageDisplayName]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.packageDisplayNameSelection),
            [Contract.WorkloadControllerRequestProperty.Risks]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.riskSeveritySelection),
            [Contract.WorkloadControllerRequestProperty.ScanTimeRange]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.scanTimeRangeSelection),
            [Contract.WorkloadControllerRequestProperty.TenantId]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.tenantIdSelection),
            [Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.vulnerabilityAttackVectorSelection),
            [Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable]: ItemSelectionHelper.toValuesFilterSelectionFromBoolean(filters.exploitable),
            [Contract.WorkloadControllerRequestProperty.VulnerabilityRawId]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.vulnerabilityRawIdSelection),
            [Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.vulnerabilitySeveritySelection),
            [Contract.WorkloadControllerRequestProperty.WorkloadResourceAttribute]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.attributeValueSelection),
            [Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.workloadResourceTypeNameSelection)
        };
    }
}