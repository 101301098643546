﻿import { DataTableColumn, map, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell, EntityFilter, InlineTextViewer, ItemSelectionHelper } from "../../../../../../../../../common";
import { TableColumnId } from "../../../Table";
import { TenantDefinition } from "../useDefinition";
import { useCommonColumns } from "./useCommonColumns";

export function useAwsDefinition(filters: Contract.PermissionManagementControllerPermissionEligibilityFilters): TenantDefinition {
    const awsFilters = filters as Contract.PermissionManagementControllerAwsPermissionEligibilityFilters;
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.hooks.useDefinition.hooks.useAwsDefinition",
            () => ({
                columns: {
                    permissionsEntityIdReferences: "Permissions",
                    permissionsInlinePolicyDocument: {
                        policy: "View JSON",
                        title: "Inline Policy"
                    }
                }
            }));

    function getPermitterIdReferences(permissionEligibilityModel: Contract.PermissionEligibilityModel) {
        const ssoPermissionSetAssignmentEligibilityModel = permissionEligibilityModel as Contract.AwsSsoPermissionSetAssignmentEligibilityModel;
        return map(
            ssoPermissionSetAssignmentEligibilityModel.configuration.permissions.typeName,
            {
                [Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions]: () => [(ssoPermissionSetAssignmentEligibilityModel.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions).permissionSetIdReference],
                [Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions]: () => (ssoPermissionSetAssignmentEligibilityModel.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPolicyPermissions).awsManagedPolicyIdReferences
            });
    }

    const commonColumns = useCommonColumns();
    return new TenantDefinition(
        (commonFilters, filterMap, limit, scope, skip, sort) =>
            new Contract.PermissionManagementControllerGetAwsPermissionEligibilityModelPageRequest(
                new Contract.PermissionManagementControllerGetAwsPermissionEligibilityModelPageRequestFilters(
                    commonFilters.approvalRequired,
                    commonFilters.approverPrincipalIdSelection,
                    commonFilters.granteePrincipalIdSelection,
                    commonFilters.nameSelection,
                    commonFilters.principalTenantIdSelection,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsEntityIdReferences])),
                limit,
                scope,
                skip,
                sort),
        getEntitiesExportOptions =>
            [
                <DataTableColumn
                    exportOptions={
                        getEntitiesExportOptions(
                            getPermitterIdReferences,
                            localization.columns.permissionsEntityIdReferences())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    emptyValue={awsFilters.awsPermitterSearchableReferenceItems.emptyValue}
                                    entityIdsOrSearchableReferences={awsFilters.awsPermitterSearchableReferenceItems.items}
                                    placeholder={localization.columns.permissionsEntityIdReferences()}/>
                        }
                    }}
                    id={TableColumnId.AwsEntityIdReferences}
                    key={TableColumnId.AwsEntityIdReferences}
                    render={
                        optionalTableCell<Contract.AwsSsoPermissionSetAssignmentEligibilityModel>(
                            item =>
                                <EntitiesCell
                                    entityIdsOrModels={getPermitterIdReferences(item)}
                                    entityLinkOptions={{ disabled: true }}
                                    entityTypeName={Contract.TypeNames.AwsResource}
                                    entityVariant="iconText"/>)}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.permissionsEntityIdReferences()}/>,
                <DataTableColumn
                    id={TableColumnId.AwsInlinePolicyDocument}
                    key={TableColumnId.AwsInlinePolicyDocument}
                    render={
                        optionalTableCell<Contract.AwsSsoPermissionSetAssignmentEligibilityModel>(
                            item => {
                                const inlinePolicyDocument =
                                    item.configuration.permissions.typeName === Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions
                                        ? (item.configuration.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions).inlinePolicyDocument
                                        : undefined;
                                return _.isNil(inlinePolicyDocument)
                                    ? undefined
                                    : <InlineTextViewer
                                        dialogTitle={localization.columns.permissionsInlinePolicyDocument.title()}
                                        text={inlinePolicyDocument.raw}
                                        title={localization.columns.permissionsInlinePolicyDocument.policy()}/>;
                            })}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.permissionsInlinePolicyDocument.title()}/>,
                commonColumns.permissionRequestScopeIds
            ]);
}