﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAzureTenantEntityStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.azure.hooks.useAzureTenantEntityStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureTenantEntityStatus]: {
                    [Contract.AzureTenantEntityStatus.Deleted]: "Deleted",
                    [Contract.AzureTenantEntityStatus.Disabled]: "Disabled",
                    [Contract.AzureTenantEntityStatus.Enabled]: "Active",
                    [Contract.AzureTenantEntityStatus.PastDue]: "Past Due",
                    [Contract.AzureTenantEntityStatus.Warned]: "Warned"
                }
            }));
    return (status: Contract.AzureTenantEntityStatus) =>
        localization[Contract.TypeNames.AzureTenantEntityStatus][status]();
}