import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsAutoScalingAutoScalingGroupsRiskContext, useGetAwsEc2InstancesRiskContext, useGetAwsEc2LaunchTemplateRiskContext } from "../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext } from "../useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext";
import { DetailsSection } from "./components";

export function useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskLaunchTemplateDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk;
    const launchTemplateModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2LaunchTemplateModel;
    const launchTemplate = (launchTemplateModel.entity) as Contract.AwsEc2LaunchTemplate;

    const getAwsAutoScalingAutoScalingGroupsRiskContext = useGetAwsAutoScalingAutoScalingGroupsRiskContext();
    const getAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext = useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsEc2LaunchTemplateRiskContext = useGetAwsEc2LaunchTemplateRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskLaunchTemplateDefinition",
            () => ({
                description: "{{launchTemplate}} metadata response hop limit is greater than {{maxTokenHopMaxCount}}",
                sections: {
                    details: "Launch Template Revisions",
                    resolution: {
                        step1: "Setting the hop limit to 1 can restrict network communications beyond the local network, potentially affecting applications that rely on multiple hops. In a container environment, it's recommended to set the hop limit to 2. Carefully evaluate your specific networking requirements and any potential impact before implementing this change.",
                        step2: "Note, if the launch template is running in a container environment, manually close this finding",
                        step3: "Select the launch template **{{launchTemplateName}}** and choose **Actions**, **Modify template (Create new version)**",
                        step4: "Expand the **Advanced Details** section",
                        step5: "Under **Metadata response hop limit**, set the value to **{{maxTokenHopMaxCount}}**",
                        step6: "Click on the **Create template version** button",
                        step7: "Set the new version as the default by clicking on **Actions > Set default version**",
                        step8: "Make sure the {{autoScalingGroups}} are using the latest version",
                        step9: "Make sure the {{instances}} are using the latest version",
                        step10: "Wait until all instances are relaunched with the updated metadata version",
                        step11: "On the Launch Template page, click Versions, and delete insecure versions by clicking **Delete template version**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchTemplate:
                <Entity
                    entityIdOrModel={launchTemplateModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            maxTokenHopMaxCount: risk.maxTokenHopMaxCount
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2LaunchTemplateUrl(launchTemplate)),
            localization.sections.resolution.step1(),
            _.isEmpty(riskModel.risk.aggregatedEntityIds) &&
            _.some(
                launchTemplate.revisionMap,
                revision => revision.metadataService?.tokenHopMaxCount == 2)
                ? localization.sections.resolution.step2()
                : undefined,
            localization.sections.resolution.step3({ launchTemplateName: launchTemplate.displayName }),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5({ maxTokenHopMaxCount: risk.maxTokenHopMaxCount }),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            !_.isEmpty(riskModel.risk.autoScalingGroupIds)
                ? localization.sections.resolution.step8({
                    autoScalingGroups:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.autoScalingGroupIds}
                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                            variant="itemCountAndType"/>
                })
                : undefined,
            !_.isEmpty(riskModel.risk.aggregatedEntityIds)
                ? localization.sections.resolution.step9({
                    instances:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.aggregatedEntityIds!}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            variant="itemCountAndType"/>
                })
                : undefined,
            localization.sections.resolution.step10(),
            localization.sections.resolution.step11()
        ],
        riskModel,
        () => {
            const autoScalingGroupsContextItems = getAwsAutoScalingAutoScalingGroupsRiskContext(riskModel.risk.autoScalingGroupIds!);
            const instanceMetadataServiceTokenHopMaxCountRiskContextItems = getAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext(riskModel);
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const launchTemplateContextItems = getAwsEc2LaunchTemplateRiskContext(launchTemplateModel);
            return [
                launchTemplateContextItems.generalInformation,
                launchTemplateContextItems.sensitive,
                launchTemplateContextItems.getAutoScalingGroupsContextItem(riskModel.risk.autoScalingGroupIds),
                autoScalingGroupsContextItems.sensitive,
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.instances,
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.permissionActions,
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.instancePublicAccess,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                launchTemplateContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)];
        },
        {
            sections:
                _<RiskDefinitionSection>([]).concatIf(
                    !_.isEmpty(riskModel.risk.aggregatedEntityIds),
                    () => new RiskDefinitionSection(
                        <DetailsSection riskModel={riskModel}/>,
                        localization.sections.details())).value()
        });
}