import { map, useLocalization, useUncaptureValue } from "@infrastructure";
import _ from "lodash";
import { Contract, reportDefinitionConfigurationStore, scopeSystemEntityModelStore, useComplianceTranslator, useEntityTypeNameTranslator, useReportTypeTranslator } from "../../../../../common";

export function useReportDefinitionTranslator() {
    const uncaptureReportDefinitionConfigurationMap =
        useUncaptureValue(
            _.keyBy(
                reportDefinitionConfigurationStore.useGetAll(),
                uncaptureReportDefinitionConfiguration => uncaptureReportDefinitionConfiguration.id));
    const customComplianceIdToNameMap =
        _(scopeSystemEntityModelStore.useGetCustomCompliance()).
            map(customComplianceModel => customComplianceModel.configuration as Contract.CustomComplianceConfiguration).
            keyBy(customComplianceConfiguration => customComplianceConfiguration.id).
            mapValues(customComplianceConfiguration => customComplianceConfiguration.section.name).
            value();

    const complianceTranslator = useComplianceTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const reportTypeTranslator = useReportTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.reports.hooks.useReportDefinitionTranslator",
            () => ({
                definition: "{{translatedReportType}} - {{reportName}}"
            }));

    return (reportDefinitionId: string) => {
        const reportDefinitionConfiguration = uncaptureReportDefinitionConfigurationMap(reportDefinitionConfigurationMap => reportDefinitionConfigurationMap[reportDefinitionId]);
        const translatedReportType = reportTypeTranslator(reportDefinitionConfiguration.type);
        return map(
            reportDefinitionConfiguration.type,
            {
                [Contract.ReportType.Compliance]: () =>
                    localization.definition({
                        reportName:
                            reportDefinitionConfiguration.typeName === Contract.TypeNames.CustomComplianceReportDefinitionConfiguration
                                ? customComplianceIdToNameMap[(reportDefinitionConfiguration as Contract.CustomComplianceReportDefinitionConfiguration).customComplianceId]
                                : complianceTranslator((reportDefinitionConfiguration as Contract.BuiltInComplianceReportDefinitionConfiguration).sectionType).title,
                        translatedReportType
                    }),
                [Contract.ReportType.Inventory]: () =>
                    _.isNil((reportDefinitionConfiguration as Contract.InventoryReportDefinitionConfiguration).entityTypeName)
                        ? translatedReportType
                        : localization.definition({
                            reportName:
                                entityTypeNameTranslator(
                                    (reportDefinitionConfiguration as Contract.InventoryReportDefinitionConfiguration).entityTypeName!,
                                    { count: 0 }),
                            translatedReportType
                        })
            },
            () => translatedReportType);
    };
}