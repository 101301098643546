import { EmptyMessage, ItemSelector, TextViewer, useLocalization } from "@infrastructure";
import { Stack } from "@mui/system";
import _ from "lodash";
import React, { useState } from "react";
import { Contract } from "../../../../../../../../../../../../common";

type GrantsProps = {
    grants: Contract.AwsKmsKeyGrant[];
};

export function Grants({ grants }: GrantsProps) {
    const [grant, setGrant] = useState(grants[0]);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsKmsKeyDefinition.grants",
            () => ({
                empty: "No Grants",
                grant: "{{name}} - {{creationTime | TimeFormatter.profileFormatDateTime}}"
            }));

    return (
        _.isEmpty(grants)
            ? <EmptyMessage
                message={localization.empty()}
                verticalCenter={true}/>
            : <Stack
                spacing={1}
                sx={{ height: "100%" }}>
                <ItemSelector
                    dense={true}
                    fullWidth={true}
                    items={grants}
                    selectedItem={grant}
                    sorted={false}
                    onSelectedItemChanged={setGrant}>
                    {grant =>
                        localization.grant({
                            creationTime: grant.creationTime,
                            name: grant.name ?? grant.id
                        })}
                </ItemSelector>
                <TextViewer
                    format="json"
                    height="100%"
                    text={grant.raw}/>
            </Stack>);
}