import { makeContextProvider, useLocalization } from "@infrastructure";
import { Box, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../common";

type PageProps = {
    children: ReactNode;
    number?: number;
    sx?: SxProps;
};

class PageContext {
    constructor(
        public reportTitle: string) {
    }
}

export const [usePageContext, , usePageContextProvider] = makeContextProvider<PageContext>();

export function Page({ children, number, sx }: PageProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.page",
            () => ({
                pageNumber: "-{{number}}-"
            }));
    const theme = useTheme();
    return (
        <Box
            id={number?.toString()}
            sx={{
                height: "29.89cm",
                pageBreakBefore: "always",
                position: "relative",
                width: "21.15cm",
                ...sx
            }}>
            {children}
            {!_.isNil(number) &&
                <Typography
                    sx={{
                        bottom: theme.spacing(1),
                        position: "absolute",
                        textAlign: "center",
                        width: "100%"
                    }}>
                    {localization.pageNumber({ number })}
                </Typography>}
        </Box>);
}

type PageDataProps = {
    children: ReactNode;
    reportTitle: string;
};

export function PageData({ children, reportTitle }: PageDataProps) {
    const [, , ContextProvider] = usePageContextProvider(() => new PageContext(reportTitle));
    return (
        <ContextProvider>
            {children}
        </ContextProvider>);
}