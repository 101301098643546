import { InfoIcon, Tooltip } from "@infrastructure";
import { FormControl, SxProps, TextField } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../common";

type EditableTextCellProps = {
    containerSx?: SxProps;
    disabled: boolean;
    errorMessage?: string;
    onChange: (value: string) => void;
    value?: string;
};

export function EditableTextCell({ containerSx, disabled, errorMessage, onChange, value }: EditableTextCellProps) {
    const theme = useTheme();
    return (
        <FormControl
            fullWidth={true}
            sx={{
                backgroundColor: "transparent",
                ...containerSx
            }}
            variant="standard">
            <TextField
                disabled={disabled}
                slotProps={{
                    input: {
                        endAdornment:
                            _.isNil(errorMessage)
                                ? undefined
                                : <Tooltip titleOrGetTitle={errorMessage}>
                                    <InfoIcon
                                        sx={{
                                            color: theme.palette.error.main,
                                            fontSize: "18px"
                                        }}/>
                                </Tooltip>
                    }
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            border:
                                _.isNil(errorMessage)
                                    ? theme.border.primary
                                    : theme.border.error,
                            borderWidth:
                                _.isNil(errorMessage) && !_.isEmpty(value)
                                    ? 0
                                    : 1
                        },
                        "& input": {
                            padding: theme.spacing(1)
                        },
                        "&.Mui-focused fieldset": {
                            borderWidth: "1px"
                        },
                        "&:hover fieldset": {
                            borderWidth: "1px"
                        },
                        backgroundColor: "transparent"
                    }
                }}
                value={value}
                variant="outlined"
                onChange={event => onChange(event.target.value)}/>
        </FormControl>);
}