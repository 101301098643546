import { FormLayout, makeContextProvider, Message, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { AutomationRuleItem, DeliveriesItem } from "../../../";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, TenantHelper, useDeliveries, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../common";
import { RiskHelper } from "../../../../../../../Risks/utilities";
import { useRiskAutomationsContext, useSetRiskAutomationsContext } from "../../RiskAutomations";
import { RiskPoliciesItem } from "./components";

export class AddOrEditContext {
    public automationRuleDeliveries: Contract.DeliveryWrapper[];
    public automationRuleBuiltInEntityAttributeTypeNames?: string[];
    public automationRuleCustomEntityAttributeDefinitionIds?: string[];
    public automationRuleCustomRiskPolicyIds?: string[];
    public automationRuleDescription?: string;
    public automationRuleExistingRuleId?: string;
    public automationRuleMinSeverity: Contract.Severity;
    public automationRuleName?: string;
    public automationRuleRiskPolicyConfigurationTypeNames?: string[];
    public automationRuleRiskSubStatuses: Contract.RiskSubStatus[];
    public automationRuleScopeId?: string;
    public automationRuleScopeIds?: string[];

    constructor(public riskAutomationRuleConfiguration?: Contract.RiskAutomationRuleConfiguration) {
        this.automationRuleDeliveries = riskAutomationRuleConfiguration?.deliveries ?? [];
        this.automationRuleBuiltInEntityAttributeTypeNames = riskAutomationRuleConfiguration?.builtInEntityAttributeTypeNames;
        this.automationRuleCustomEntityAttributeDefinitionIds = riskAutomationRuleConfiguration?.customEntityAttributeDefinitionIds;
        this.automationRuleCustomRiskPolicyIds = riskAutomationRuleConfiguration?.customRiskPolicyIds;
        this.automationRuleDescription = riskAutomationRuleConfiguration?.description;
        this.automationRuleExistingRuleId = riskAutomationRuleConfiguration?.id;
        this.automationRuleMinSeverity = riskAutomationRuleConfiguration?.minSeverity ?? Contract.Severity.Information;
        this.automationRuleName = riskAutomationRuleConfiguration?.name;
        this.automationRuleRiskPolicyConfigurationTypeNames = riskAutomationRuleConfiguration?.riskPolicyConfigurationTypeNames;
        this.automationRuleRiskSubStatuses = riskAutomationRuleConfiguration?.riskSubStatuses ?? RiskHelper.riskStatusToSubStatusMap[Contract.RiskStatus.Open];
        this.automationRuleScopeId = riskAutomationRuleConfiguration?.scopeId;
        this.automationRuleScopeIds = riskAutomationRuleConfiguration?.scopeIds;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const [executing, setExecuting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [sectionValidation, setSectionValidation] = useState<{ [key: string]: boolean }>({ automationRuleItem: false, riskPoliciesItem: false });
    const { addOrEditOpen } = useRiskAutomationsContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setRiskAutomationsContext = useSetRiskAutomationsContext();
    const [addOrEditContext, setAddOrEditContext, ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    addOrEditOpen === true
                        ? undefined
                        : addOrEditOpen as Contract.RiskAutomationRuleConfiguration));
    const { createItem, deliveries, onChange, valid: deliveriesValidation } = useDeliveries(addOrEditContext.automationRuleDeliveries ?? []);
    const risksTenantTypes =
        useMemo(
            () => TenantHelper.RisksTenantTypes,
            []);

    const localization =
        useLocalization(
            "views.customer.configuration.automations.riskAutomations.addOrEdit",
            () => ({
                add: {
                    button: "Add",
                    error: "Failed to create Automation Rule",
                    title: "Add an Automation Rule"
                },
                edit: {
                    button: "Save",
                    error: "Failed to edit Automation Rule",
                    title: "Edit an Automation Rule"
                },
                policies: "Policies"
            }));

    async function onFinished() {
        setExecuting(true);
        try {
            const { scopeSystemEntityModel } =
                await ConfigurationController.upsertRiskAutomationRule(
                    new Contract.ConfigurationControllerUpsertRiskAutomationRuleRequest(
                        addOrEditContext.automationRuleBuiltInEntityAttributeTypeNames,
                        addOrEditContext.automationRuleCustomEntityAttributeDefinitionIds,
                        addOrEditContext.automationRuleCustomRiskPolicyIds,
                        deliveries,
                        addOrEditContext.automationRuleDescription,
                        addOrEditContext.automationRuleExistingRuleId,
                        addOrEditContext.automationRuleMinSeverity,
                        addOrEditContext.automationRuleName!,
                        addOrEditContext.automationRuleRiskPolicyConfigurationTypeNames,
                        addOrEditContext.automationRuleRiskSubStatuses,
                        addOrEditContext.automationRuleScopeId ?? scopeNodeModel.configuration.id!,
                        addOrEditContext.automationRuleScopeIds!));
            await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            setRiskAutomationsContext(
                automationContext => ({
                    ...automationContext,
                    addOrEditOpen: false
                }));
        } catch {
            setErrorMessage(
                addOrEditOpen === true
                    ? localization.add.error()
                    : localization.edit.error());
        }
        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <ContextProvider>
            <FormLayout
                footerOptions={{
                    border: true,
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between"
                            spacing={1}>
                            <Box>
                                {!_.isNil(errorMessage) &&
                                    <Message
                                        level="error"
                                        title={errorMessage}/>}
                            </Box>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                {executing &&
                                    <CircularProgress
                                        size={theme.spacing(2)}
                                        variant="indeterminate"/>}
                                <Button
                                    disabled={
                                        !sectionValidation.automationRuleItem ||
                                        !sectionValidation.riskPoliciesItem ||
                                        !deliveriesValidation ||
                                        _.isEmpty(deliveries) ||
                                        executing}
                                    onClick={onFinished}>
                                    {addOrEditOpen === true
                                        ? localization.add.button()
                                        : localization.edit.button()}
                                </Button>
                            </Stack>
                        </Stack>
                }}
                titleOptions={{
                    text:
                        addOrEditOpen === true
                            ? localization.add.title()
                            : localization.edit.title()
                }}>
                <Stack
                    direction="column"
                    spacing={3}>
                    <AutomationRuleItem
                        executing={executing}
                        initialDescription={addOrEditContext.automationRuleDescription}
                        initialName={addOrEditContext.automationRuleName}
                        initialScopeId={addOrEditContext.automationRuleScopeId}
                        initialScopeIds={addOrEditContext.automationRuleScopeIds}
                        setValidation={
                            valid =>
                                setSectionValidation(
                                    sectionValidation => ({
                                        ...sectionValidation,
                                        automationRuleItem: valid
                                    }))}
                        tenantTypes={risksTenantTypes}
                        onValuesChange={
                            (description, name, scopeIds) =>
                                setAddOrEditContext(
                                    addOrEditContext => ({
                                        ...addOrEditContext,
                                        automationRuleDescription: description,
                                        automationRuleName: name,
                                        automationRuleScopeIds:
                                            _.isEmpty(scopeIds)
                                                ? [scopeNodeModel.configuration.id]
                                                : scopeIds
                                    }))}/>
                    <Stack spacing={1}>
                        <Typography variant="h4">
                            {localization.policies()}
                        </Typography>
                        <RiskPoliciesItem
                            setValidation={
                                valid =>
                                    setSectionValidation(
                                        sectionValidation => ({
                                            ...sectionValidation,
                                            riskPoliciesItem: valid
                                        }))}/>
                    </Stack>
                    <DeliveriesItem
                        createItem={createItem}
                        deliveries={deliveries}
                        scopeId={addOrEditContext.automationRuleScopeId ?? scopeNodeModel.configuration.id}
                        variant="risk"
                        onChange={onChange}/>
                </Stack>
            </FormLayout>
        </ContextProvider>);
}