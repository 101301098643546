import { InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";

type InlineIpAddressesProps = {
    ipAddresses: string[];
};

export function InlineIpAddresses({ ipAddresses }: InlineIpAddressesProps) {
    const localization =
        useLocalization(
            "common.inlineIpAddresses",
            () => ({
                ipAddresses: [
                    "1 IP Address",
                    "{{count | NumberFormatter.humanize}} IP Addresses"
                ]
            }));
    return (
        <InlineItems
            items={ipAddresses}
            namePluralizer={localization.ipAddresses}
            variant="itemOrItemCountAndType">
            {(ipAddress: string, inline: boolean) =>
                <Typography
                    component="span"
                    sx={{
                        fontWeight:
                            inline
                                ? 600
                                : undefined
                    }}>
                    {ipAddress}
                </Typography>}
        </InlineItems>);
}