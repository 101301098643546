import { NumberFormatter, useLocalization } from "@infrastructure";
import { Divider, Grid2, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, TimeRangeHelper, TypeHelper, useTheme } from "../../../../../../../../../common";

type SummaryProps = {
    timeFrameToOpenBehaviorRiskCountMap: Dictionary<number>;
    totalCount: number;
};

export function Summary({ timeFrameToOpenBehaviorRiskCountMap, totalCount }: SummaryProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.openBehaviorRiskSection.summary",
            () => ({
                title: "{{translatedTotalCount}} Anomaly Detection Findings",
                [Contract.TypeNames.TimeFrame]: "{{translatedRiskCount}} in the last **{{ time }} days**"
            }));

    const theme = useTheme();
    return (
        <Grid2
            alignItems="center"
            container={true}
            style={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75)
            }}>
            <Grid2
                sx={{ padding: theme.spacing(2.5, 2) }}>
                <Typography>
                    {localization.title({
                        translatedTotalCount:
                            <Typography
                                component="span"
                                variant="h4">
                                {NumberFormatter.humanize(totalCount)}
                            </Typography>
                    })}
                </Typography>
            </Grid2>
            <Divider
                flexItem={true}
                orientation="vertical"
                style={{ margin: theme.spacing(1, 0) }}
                variant="middle"/>
            <Grid2
                size="grow"
                sx={{ padding: theme.spacing(2.5, 0, 2.5, 4) }}>
                <Grid2 container={true}>
                    {_(timeFrameToOpenBehaviorRiskCountMap).
                        keys().
                        orderBy(
                            timeFrame => TypeHelper.getEnumValue(Contract.TypeNames.TimeFrame, timeFrame),
                            "desc").
                        map(
                            (timeFrame: Contract.TimeFrame) =>
                                <Grid2
                                    key={timeFrame}
                                    size="grow">
                                    <Typography>
                                        {localization[Contract.TypeNames.TimeFrame]({
                                            time: TimeRangeHelper.getTimeFrameValue(timeFrame),
                                            translatedRiskCount:
                                                <Typography
                                                    component="span"
                                                    variant="h4">
                                                    {NumberFormatter.humanize(timeFrameToOpenBehaviorRiskCountMap[timeFrame])}
                                                </Typography>
                                        })}
                                    </Typography>
                                </Grid2>).
                        value()}
                </Grid2>
            </Grid2>
        </Grid2>);
}