import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { TableColumnId } from "../../../../IdentityProviderTenantTable";
import { PermissionsCell } from "./components";

export function useCommonColumns() {
    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.identityProviderTenantTable.hooks.useDefinition.hooks.useCommonColumns",
            () => ({
                columns: {
                    permissions: "Permissions"
                }
            }));

    return {
        permissionsColumn:
            <DataTableColumn
                id={TableColumnId.Permissions}
                key={TableColumnId.Permissions}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.TenantModel>) =>
                        <PermissionsCell permissionManagementEnabled={(item.state as Contract.IdentityProviderTenantState).permissionManagementEnabled}/>}
                title={localization.columns.permissions()}/>
    };
}