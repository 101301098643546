﻿import { ItemSelector, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../../common";
import { useAwsBedrockAgentStatusTranslator } from "../../../../../../../../hooks";

type AliasesProps = {
    agent: Contract.AwsBedrockAgent;
};

export function Aliases({ agent }: AliasesProps) {
    const aliasNameMap =
        useMemo(
            () =>
                _.keyBy(
                    agent.aliases,
                    alias => alias.name),
            [agent]);
    const [selectedAlias, setSelectedAlias] =
        useState(
            () =>
                _.maxBy(
                    agent.aliases,
                    alias => alias.updateTime)!);

    const agentAliasStatusTranslator = useAwsBedrockAgentStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsBedrockAgentDefinition.aliases",
            () => ({
                fields: {
                    creationTime: "Creation Time",
                    description: "Description",
                    id: "Id",
                    name: "Name",
                    revision: "Revision",
                    status: "Status",
                    updateTime: "Update Time"
                }
            }));

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(agent.aliases).
                        orderBy(
                            alias => alias.updateTime,
                            "desc").
                        map(alias => alias.name).
                        value()}
                selectedItem={selectedAlias.name}
                sorted={false}
                onSelectedItemChanged={aliasName => setSelectedAlias(aliasNameMap[aliasName])}/>
            <InfoCard columns={true}>
                <InfoItem
                    title={localization.fields.id()}
                    value={selectedAlias.id}/>
                <InfoItem
                    title={localization.fields.name()}
                    value={selectedAlias.name}/>
                <InfoItem
                    title={localization.fields.status()}
                    value={agentAliasStatusTranslator(selectedAlias.status)}/>
                <InfoItem
                    title={localization.fields.description()}
                    value={selectedAlias.description}/>
                <InfoItem
                    title={localization.fields.revision()}
                    value={selectedAlias.revisionId}/>
                <InfoItem
                    title={localization.fields.creationTime()}
                    value={TimeFormatter.profileFormatDateTime(selectedAlias.creationTime)}/>
                <InfoItem
                    title={localization.fields.updateTime()}
                    value={TimeFormatter.profileFormatDateTime(selectedAlias.updateTime)}/>
            </InfoCard>
        </InfoCard>);
}