import { TimeHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsIamRootUserAccessKeyEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamRootUserAccessKeyEnabledRisk;
    const rootUserModel = entityModelStore.useGet(risk.entityId);
    const rootUser = rootUserModel.entity as Contract.AwsIamRootUser;
    const rootUserAccessKeyUsageTime =
        TimeHelper.getSortable(rootUser.accessKey1UsageTime) > TimeHelper.getSortable(rootUser.accessKey2UsageTime)
            ? rootUser.accessKey1UsageTime
            : rootUser.accessKey2UsageTime;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRootUserAccessKeyEnabledRiskDefinition",
            () => ({
                description: {
                    accessKeyNeverUsed: "{{rootUser}} has an access key enabled and never used it",
                    accessKeyUsed: "{{rootUser}} has an access key enabled and was last used {{rootUserAccessKeyUsageTime | TimeFormatter.humanizePastDuration}}"
                },
                sections: {
                    resolution: {
                        step1: "Before deleting the root user’s access key, verify that application are not using it",
                        step2: "Click <Root_Account_Name> at the top right and choose **My Security Credentials** from the drop-down list",
                        step3: "Choose **Access Keys**",
                        step4: "Click **Delete** at the far right end of the row",
                        step5: "Click **Yes** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isNil(rootUserAccessKeyUsageTime)
            ? localization.description.accessKeyNeverUsed
            : localization.description.accessKeyUsed)({
            rootUser:
                <Entity
                    entityIdOrModel={rootUserModel}
                    variant="text"/>,
            rootUserAccessKeyUsageTime
        }),
        () =>
            [
                consoleSignInStepTranslator(
                    Contract.AwsConsoleView.Iam,
                    AwsConsoleUrlBuilder.getIamUrl(tenantConfiguration.partitionType)),
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2(),
                localization.sections.resolution.step3(),
                localization.sections.resolution.step4(),
                localization.sections.resolution.step5()
            ],
        riskModel);
}