import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InstaclustrIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2650)">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
                    fill="#E7F5FD"/>
                <path
                    d="M9.98999 17.055H13.77V17.52L13.755 18.345V20.235L13.74 20.25H9.98999V17.055Z"
                    fill="#1C164D"/>
                <path
                    d="M10.02 3.42004H13.755L13.77 3.48004L13.755 6.61504H10.08L9.98999 6.60004V3.45004L10.02 3.42004Z"
                    fill="#00BAEF"/>
                <path
                    d="M9.98999 12.525H13.77V13.005L13.755 13.845V15.705L13.71 15.72H12.975L9.98999 15.705V12.525Z"
                    fill="#1C164D"/>
                <path
                    d="M9.98999 7.96504H13.77V8.49004L13.755 9.31504V11.145L13.71 11.16L9.98999 11.145V7.96504Z"
                    fill="#1C164D"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2650">
                    <rect
                        fill="white"
                        height="24"
                        width="23.9962"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}