﻿import { SxProps } from "@mui/material";
import React, { Fragment } from "react";
import { Contract } from "../../controllers";
import { GroupIcon, PermissionSetIcon, ServiceIdentityIcon, UserIcon, VendorIcon } from "./icons";

type PrincipalIconProps = {
    principalType: Contract.PrincipalType;
    sx?: SxProps;
};

export function PrincipalIcon({ principalType, sx }: PrincipalIconProps) {
    switch (principalType) {
        case Contract.PrincipalType.AwsPermissionSet:
            return <PermissionSetIcon sx={sx}/>;
        case Contract.PrincipalType.FederationIdentity:
            return <UserIcon sx={sx}/>;
        case Contract.PrincipalType.Group:
            return <GroupIcon sx={sx}/>;
        case Contract.PrincipalType.ServiceIdentity:
            return <ServiceIdentityIcon sx={sx}/>;
        case Contract.PrincipalType.User:
            return <UserIcon sx={sx}/>;
        case Contract.PrincipalType.VendorServiceIdentity:
            return <VendorIcon sx={sx}/>;
        default:
            return <Fragment/>;
    }
}