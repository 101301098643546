﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonComputeLoadBalancerResourceInfoItemElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoCard, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpComputeTargetPoolDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const targetPoolModel = scopeResourceModel as Contract.GcpComputeTargetPoolModel;
    const commonComputeLoadBalancerResourceInfoItemElements = useGcpCommonComputeLoadBalancerResourceInfoItemElements(targetPoolModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeTargetPoolDefinition",
            () => ({
                info: {
                    cards: {
                        instanceIdReferences: "VM Instances"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonComputeLoadBalancerResourceInfoItemElements.loadBalancerIdsInfoItemElement
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={targetPoolModel.instanceIdReferences}
                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                    title={localization.info.cards.instanceIdReferences()}/>
            </Info>
    });
}