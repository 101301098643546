﻿import { makeContextProvider } from "@infrastructure";
import { List, ListItem } from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { Pattern, PatternItem } from "./components";

export class PatternsSelectorContext {
    constructor(public patternItems: PatternItem[]) {
    }
}

export const [, useSetPatternsSelectorContext, usePatternsSelectorContextProvider] = makeContextProvider<PatternsSelectorContext>();

export type PatternsSelectorProps = {
    disabled: boolean;
    onPatternsChanged: (patterns: string[]) => void;
    selectedPatterns?: string[];
};

export function PatternsSelector({ disabled, onPatternsChanged, selectedPatterns }: PatternsSelectorProps) {
    const [context, , ContextProvider] = usePatternsSelectorContextProvider(
        () =>
            new PatternsSelectorContext(
                _.map(
                    selectedPatterns ?? [],
                    pattern =>
                        new PatternItem(
                            pattern,
                            false))));
    const patternItemIdToDuplicateMap =
        useMemo(
            () =>
                _(context.patternItems).
                    keyBy(patternItem => patternItem.id).
                    mapValues(
                        patternItem =>
                            _.filter(
                                context.patternItems,
                                otherPatternItem => otherPatternItem.pattern === patternItem.pattern).length > 1).
                    value(),
            [context.patternItems]);
    useEffect(
        () => {
            onPatternsChanged(
                _.map(
                    context.patternItems,
                    patternItem => patternItem.pattern));
        },
        [context.patternItems]);

    const patternItems =
        useMemo(
            () =>
                context.patternItems.length < 50
                    ? _.concat(
                        context.patternItems,
                        new PatternItem(
                            "",
                            true))
                    : context.patternItems,
            [context.patternItems]);

    return (
        <ContextProvider>
            <List disablePadding={true}>
                <Stack spacing={2}>
                    {_.map(
                        patternItems,
                        patternItem =>
                            <ListItem
                                disablePadding={true}
                                key={patternItem.id}>
                                <Pattern
                                    disabled={disabled}
                                    duplicate={patternItemIdToDuplicateMap[patternItem.id]}
                                    item={patternItem}/>
                            </ListItem>)}
                </Stack>
            </List>
        </ContextProvider>);
}