import _, { Dictionary } from "lodash";
import { Contract, TypeHelper } from "../../../../../../../../../common";

export class SummaryItem {
    public principalCount: number;
    public principalSeverity: Contract.Severity;
    public severityPrincipalCount: number;

    constructor(public principalSeverityToCountMap: Dictionary<number>) {
        this.principalCount =
            _(principalSeverityToCountMap).
                map(principalCount => principalCount).
                sum();
        this.principalSeverity =
            _(Contract.Severity).
                values().
                orderBy(
                    severity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity),
                    "desc").
                find(severity => principalSeverityToCountMap[severity] > 0)!;
        this.severityPrincipalCount = principalSeverityToCountMap[this.principalSeverity];
    }
}