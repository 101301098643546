import { DateTimeField, EmptyMessageText, FormLayout, InfoIcon, ItemSelector, makeContextProvider, map, Message, Optional, StringHelper, TimeFormatter, TimeHelper, TimeSpanHelper, Tooltip, useAsyncEffect, useChangeEffect, useExecuteOperation, useInputValidation, useLocalization, useUniqueKey } from "@infrastructure";
import { Box, Button, CircularProgress, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Ref, useEffect, useMemo, useRef, useState } from "react";
import { Contract, PermissionManagementController, PermissionManagementTenantType, RadioGroup, ScopeHelper, scopeNodeModelStore, scopeSystemEntityModelStore, TenantIcon, useTenantNameTranslator, useTheme } from "../../../../../../common";
import { useGranteeUserPermissionRequestsContext, useSetGranteeUserPermissionRequestsContext } from "../../GranteeUserPermissionRequests";
import { PendingActivation, PendingApproval } from "./components";
import { AdditionalPermissionRequestData, PermissionEligibilityDefinition, useGetDefinition } from "./hooks";

export class AddContext {
    constructor(
        public additionalFieldNameToValidMap: Dictionary<boolean>,
        public additionalPermissionRequestData?: AdditionalPermissionRequestData,
        public permissionRequestModel?: Contract.PermissionRequestModel) {
    }
}

export const [useAddContext, useSetAddContext, useAddContextProvider] = makeContextProvider<AddContext>();

export function Add() {
    const { addOpen, triggerPermissionRequestModelChange } = useGranteeUserPermissionRequestsContext();
    const setPermissionRequestsContext = useSetGranteeUserPermissionRequestsContext();

    const scopeNodeModelMap = scopeNodeModelStore.useGetScopeNodeMap();
    const [permissionEligibilityModels] =
        useExecuteOperation(
            Add,
            async () => {
                const { permissionEligibilityIds } = await PermissionManagementController.getUserPermissionEligibilityIds();
                const permissionEligibilityModels = await scopeSystemEntityModelStore.get(permissionEligibilityIds) as Contract.PermissionEligibilityModel[];

                if (_(permissionEligibilityModels).
                    map(permissionEligibilityModel => permissionEligibilityModel.configuration.scopeId).
                    some(
                        permissionEligibilityScopeId =>
                            !_.has(
                                scopeNodeModelMap,
                                permissionEligibilityScopeId))) {
                    await scopeNodeModelStore.notify();
                }

                return permissionEligibilityModels;
            });

    const permissionRequestModel =
        _.isBoolean(addOpen)
            ? undefined
            : addOpen;

    const [permissionEligibilityModel, setPermissionEligibilityModel] =
        useState<Optional<Contract.PermissionEligibilityModel>>(
            () =>
                _.isNil(permissionRequestModel)
                    ? undefined
                    : _.find(
                        permissionEligibilityModels,
                        permissionEligibilityModel => permissionEligibilityModel.configuration.id === permissionRequestModel.permissionRequest.permissionEligibilityId));

    const getDefinition = useGetDefinition();
    const [definition, setDefinition] =
        useState<Optional<PermissionEligibilityDefinition>>(
            () =>
                _.isNil(permissionEligibilityModel)
                    ? undefined
                    : getDefinition(permissionEligibilityModel));

    const [context, setContext, ContextProvider] =
        useAddContextProvider(
            () =>
                new AddContext(
                    {},
                    definition?.createPermissionRequestAdditionalData(),
                    permissionRequestModel));

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.add",
            () => ({
                actions: {
                    add: {
                        error: {
                            exists: "An identical access request already exists",
                            general: "Failed to issue request"
                        },
                        title: "Request"
                    }
                },
                fields: {
                    expirationTimeFrame: {
                        title: "Duration",
                        value: [
                            "1 hour",
                            "{{count}} hours"
                        ]
                    },
                    permissionEligibility: {
                        empty: "No Results",
                        error: "Failed to set permissions. Please try again.",
                        title: "Eligibility"
                    },
                    reason: {
                        error: "Justification cannot be empty",
                        placeholder: "Provide relevant details related to why you need this access",
                        title: {
                            notRequired: "Justification (optional)",
                            required: "Justification"
                        }
                    },
                    startTime: {
                        notScheduled: "Immediately",
                        scheduled: {
                            error: {
                                invalidFormat: "Start time must be a valid date",
                                invalidValue: "Start time cannot be in the past"
                            },
                            explanation: "Expires on {{date}} at {{time}}",
                            selectorTitle: "Start time",
                            title: "Select start time"
                        },
                        title: "Start time (upon approval)"
                    }
                },
                title: "Request Access"
            }));

    const [maxExpirationTimeFrameHours, setMaxExpirationTimeFrameHours] = useState<number>();

    const [expirationTimeFrameHours, setExpirationTimeFrameHours] =
        useState<Optional<number>>(
            () => {
                if (_.isNil(permissionEligibilityModel) ||
                    _.isNil(permissionRequestModel)) {
                    return undefined;
                }

                const permissionRequestExpirationTimeFrameHours = TimeSpanHelper.getHours(permissionRequestModel.permissionRequest.expirationTimeFrame);
                const permissionEligibilityExpirationTimeFrameHours = TimeSpanHelper.getHours(permissionEligibilityModel.configuration.expirationTimeFrame);
                return permissionEligibilityExpirationTimeFrameHours >= permissionRequestExpirationTimeFrameHours
                    ? permissionRequestExpirationTimeFrameHours
                    : 1;
            });
    const [reason, setReason] =
        useState<Optional<string>>(
            _.isNil(permissionEligibilityModel) ||
            _.isNil(permissionRequestModel)
                ? undefined
                : permissionRequestModel.permissionRequest.reason);
    const [scheduled, setScheduled] = useState(false);
    const [startTime, setStartTime] =
        useState<string>(
            () => {
                const now = TimeHelper.now();
                const weekday = TimeHelper.getWeekday(now);
                let startTime =
                    TimeHelper.setWeekday(
                        now,
                        weekday < 5
                            ? weekday + 1
                            : 7);
                startTime =
                    TimeHelper.setTime(
                        startTime,
                        "hour",
                        9);

                return TimeHelper.
                    round(
                        startTime,
                        "hour").
                    toISOString();
            });

    const [reasonValidationController, reasonValidationMessage] =
        useInputValidation(
            () => {
                if (permissionEligibilityModel?.configuration.reasonRequired === true &&
                    _.isEmpty(reason?.trim())) {
                    return localization.fields.reason.error();
                }

                return undefined;
            },
            [reason]);

    const [startTimeValidationController, startTimeValidationMessage] =
        useInputValidation(
            () => {
                if (!scheduled) {
                    return undefined;
                }

                if (_.isNil(startTime)) {
                    return localization.fields.startTime.scheduled.error.invalidFormat();
                }

                if (TimeHelper.isBefore(startTime, TimeHelper.now())) {
                    return localization.fields.startTime.scheduled.error.invalidValue();
                }

                return undefined;
            },
            [scheduled, startTime]);

    const additionalFieldActionsListRef = useRef<AdditionalFieldComponentActions[]>([]);
    const [additionalFieldKeyPrefix, updateAdditionalFieldKeyPrefix] = useUniqueKey();
    useChangeEffect(
        () => {
            const definition =
                _.isNil(permissionEligibilityModel)
                    ? undefined
                    : { ...getDefinition(permissionEligibilityModel) };
            setDefinition(definition);
            additionalFieldActionsListRef.current = [];
            setContext(
                context => {
                    context.additionalPermissionRequestData = definition?.createPermissionRequestAdditionalData();
                    context.additionalFieldNameToValidMap = {};
                    context.permissionRequestModel = undefined;
                    return { ...context };
                });
            updateAdditionalFieldKeyPrefix();
        },
        [permissionEligibilityModel]);

    const additionalFieldsValid =
        useMemo(
            () =>
                _(context.additionalFieldNameToValidMap).
                    values().
                    every(),
            [context.additionalFieldNameToValidMap]);

    const [valid, setValid] = useState(false);
    useEffect(
        () => {
            setValid(
                additionalFieldsValid &&
                !_.isNil(expirationTimeFrameHours) &&
                !_.isNil(permissionEligibilityModel) &&
                reasonValidationController.isValid() &&
                startTimeValidationController.isValid());
        },
        [additionalFieldsValid, expirationTimeFrameHours, permissionEligibilityModel, reason, scheduled, startTime]);

    const [insertPermissionRequestErrorMessage, setInsertPermissionRequestErrorMessage] = useState<string>();
    const [insertPermissionRequestExecuting, setInsertPermissionRequestExecuting] = useState(false);
    const [permissionRequestStatus, setPermissionRequestStatus] = useState<Contract.PermissionRequestStatus>();
    const [setPermissionEligibilityError, setSetPermissionEligibilityError] = useState(false);
    const [setPermissionEligibilityExecuting, setSetPermissionEligibilityExecuting] = useState(false);

    const expirationTime =
        _.isNil(startTime)
            ? undefined
            : TimeHelper.addTime(
                startTime,
                expirationTimeFrameHours,
                "hour");

    const firstFetchDataRef =
        useRef(
            !_.isNil(permissionRequestModel) &&
            !_.isNil(permissionEligibilityModel));
    useAsyncEffect(
        async () => {
            if (_.isNil(permissionEligibilityModel) ||
                _.isNil(definition)) {
                return;
            }

            setSetPermissionEligibilityExecuting(true);

            setSetPermissionEligibilityError(false);

            if (!firstFetchDataRef.current) {
                setExpirationTimeFrameHours(undefined);
            }

            setMaxExpirationTimeFrameHours(undefined);

            const fetchDataSuccesses =
                await Promise.all(
                    _.map(
                        additionalFieldActionsListRef.current,
                        additionalFieldActions => additionalFieldActions.fetchData(permissionEligibilityModel)));

            if (_.every(fetchDataSuccesses)) {
                setMaxExpirationTimeFrameHours(TimeSpanHelper.getHours(permissionEligibilityModel.configuration.expirationTimeFrame));
                if (!firstFetchDataRef.current) {
                    setExpirationTimeFrameHours(1);
                    reasonValidationController.clear();
                }
            } else {
                setPermissionEligibilityModel(undefined);
                setSetPermissionEligibilityError(true);
            }

            firstFetchDataRef.current = false;
            setSetPermissionEligibilityExecuting(false);
        },
        [definition]);

    async function insertPermissionRequest() {
        setInsertPermissionRequestErrorMessage(undefined);
        setInsertPermissionRequestExecuting(true);

        try {
            const { permissionRequestStatus: requestPermissionRequestStatus } =
                await PermissionManagementController.insertPermissionRequest(
                    definition!.createInsertPermissionRequestRequest(
                        context.additionalPermissionRequestData!,
                        TimeSpanHelper.fromHours(expirationTimeFrameHours!),
                        permissionEligibilityModel!.configuration.id,
                        reason?.trim(),
                        scheduled
                            ? startTime
                            : undefined));

            setPermissionRequestStatus(requestPermissionRequestStatus);
            if (_.isNil(requestPermissionRequestStatus)) {
                setInsertPermissionRequestErrorMessage(localization.actions.add.error.exists());
            }

        } catch (error) {
            setInsertPermissionRequestErrorMessage(localization.actions.add.error.general());
        }

        setInsertPermissionRequestExecuting(false);
    }

    async function triggerChange() {
        await triggerPermissionRequestModelChange();

        setPermissionRequestsContext(
            PermissionRequestsContext => ({
                ...PermissionRequestsContext,
                addOpen: false
            }));
    }

    const executing = insertPermissionRequestExecuting || setPermissionEligibilityExecuting;

    const tenantNameTranslator = useTenantNameTranslator();
    const theme = useTheme();
    return (
        <ContextProvider>
            <FormLayout
                footerOptions={
                    _.isNil(permissionRequestStatus)
                        ? {
                            contentElement:
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="flex-end"
                                    spacing={2}>
                                    {!_.isNil(insertPermissionRequestErrorMessage) && (
                                        <Message
                                            level="error"
                                            title={insertPermissionRequestErrorMessage}/>)}
                                    {insertPermissionRequestExecuting && (
                                        <CircularProgress
                                            size={theme.spacing(3)}
                                            variant="indeterminate"/>)}
                                    <Button
                                        disabled={!valid || insertPermissionRequestExecuting}
                                        onClick={() => insertPermissionRequest()}>
                                        {localization.actions.add.title()}
                                    </Button>
                                </Stack>
                        }
                        : undefined}
                titleOptions={
                    _.isNil(permissionRequestStatus)
                        ? { text: localization.title() }
                        : undefined}>
                {_.isNil(permissionRequestStatus)
                    ? <Stack spacing={3}>
                        <ItemSelector
                            disabled={executing}
                            disablePopover={true}
                            dropdownIcon={
                                setPermissionEligibilityExecuting
                                    ? <CircularProgress
                                        size={theme.spacing(2)}
                                        variant="indeterminate"/>
                                    : undefined}
                            emptyMessageText={new EmptyMessageText(localization.fields.permissionEligibility.empty())}
                            fullWidth={true}
                            getItemText={permissionEligibilityModel => `${tenantNameTranslator(ScopeHelper.getTenantType(scopeNodeModelMap[permissionEligibilityModel.configuration.scopeId].scopeNodeModel)!)}.${permissionEligibilityModel.configuration.name}`}
                            items={permissionEligibilityModels}
                            listItemSx={{ padding: 0 }}
                            placeholder={localization.fields.permissionEligibility.title()}
                            selectedItem={permissionEligibilityModel}
                            onSelectedItemChanged={setPermissionEligibilityModel}>
                            {permissionEligibilityModel =>
                                <Tooltip
                                    slotProps={{
                                        popper: {
                                            placement: "bottom-start",
                                            sx: {
                                                "&.MuiTooltip-popper[data-popper-placement*=\"bottom\"] > .MuiTooltip-tooltip": {
                                                    marginLeft: theme.spacing(5),
                                                    marginTop: theme.spacing(0)
                                                }
                                            }
                                        }
                                    }}
                                    titleOrGetTitle={
                                        !_.isEmpty(permissionEligibilityModel?.configuration.description)
                                            ? <Typography
                                                variant="h5"> {permissionEligibilityModel.configuration.description}
                                            </Typography>
                                            : undefined}>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={theme.spacing(1)}
                                        sx={{
                                            padding: theme.spacing(1, 2),
                                            width: "100%"
                                        }}>
                                        <TenantIcon
                                            sx={{ fontSize: "24px" }}
                                            tenantType={ScopeHelper.getTenantType(scopeNodeModelMap[permissionEligibilityModel.configuration.scopeId].scopeNodeModel)!}/>
                                        <Typography
                                            noWrap={true}
                                            variant="h5">
                                            {permissionEligibilityModel.configuration.name}
                                        </Typography>
                                        {!_.isEmpty(permissionEligibilityModel.configuration.description) && (
                                            <InfoIcon
                                                sx={{
                                                    color: theme.palette.text.primary,
                                                    fontSize: "18px"
                                                }}/>)}
                                    </Stack>
                                </Tooltip>}
                        </ItemSelector>
                        {setPermissionEligibilityError && (
                            <FormHelperText error={true}>
                                {localization.fields.permissionEligibility.error()}
                            </FormHelperText>)}
                        {!_.isNil(permissionEligibilityModel) &&
                            !_.isNil(definition) &&
                            _.map(
                                definition.additionalFieldComponents,
                                (AdditionalFieldComponent, additionalFieldComponentIndex) =>
                                    <AdditionalFieldComponent
                                        actionsRef={actionsRef => additionalFieldActionsListRef.current[additionalFieldComponentIndex] = actionsRef!}
                                        disabled={
                                            executing ||
                                            _.isNil(permissionEligibilityModel)}
                                        key={`${additionalFieldKeyPrefix}-${additionalFieldComponentIndex}`}/>)}
                        <Stack spacing={1}>
                            <Typography variant="h4">
                                {localization.fields.expirationTimeFrame.title()}
                            </Typography>
                            <ItemSelector
                                disabled={
                                    executing ||
                                    _.isNil(permissionEligibilityModel)}
                                fieldSx={{ width: theme.spacing(14) }}
                                items={
                                    _.isNil(maxExpirationTimeFrameHours)
                                        ? []
                                        : _.range(1, maxExpirationTimeFrameHours + 1)}
                                selectedItem={expirationTimeFrameHours}
                                sorted={false}
                                onSelectedItemChanged={expirationTimeFrameHours => setExpirationTimeFrameHours(expirationTimeFrameHours)}>
                                {expirationTimeFrameHours => localization.fields.expirationTimeFrame.value(expirationTimeFrameHours)}
                            </ItemSelector>
                        </Stack>
                        <Stack>
                            <Typography variant="h4">
                                {localization.fields.startTime.title()}
                            </Typography>
                            <RadioGroup
                                items={[
                                    {
                                        disabled:
                                            executing ||
                                            _.isNil(permissionEligibilityModel),
                                        label: localization.fields.startTime.notScheduled(),
                                        value: false
                                    },
                                    {
                                        disabled:
                                            executing ||
                                            _.isNil(permissionEligibilityModel),
                                        label: localization.fields.startTime.scheduled.title(),
                                        value: true
                                    }
                                ]}
                                selectedValue={scheduled}
                                sx={{ marginBottom: theme.spacing(1) }}
                                onChange={value => setScheduled(StringHelper.isTrue(value))}/>
                            {scheduled &&
                                <Box sx={{ marginLeft: theme.spacing(3) }}>
                                    <Stack spacing={0.5}>
                                        <DateTimeField
                                            disabled={
                                                executing ||
                                                _.isNil(permissionEligibilityModel)}
                                            disableOpenPicker={false}
                                            minDateTime={TimeHelper.now()}
                                            popperPlacement="top"
                                            time={startTime}
                                            title={localization.fields.startTime.scheduled.selectorTitle()}
                                            onChange={startTime => setStartTime(startTime!)}/>
                                        {_.isNil(startTimeValidationMessage) &&
                                            <Typography
                                                sx={{
                                                    color:
                                                        executing ||
                                                        _.isNil(permissionEligibilityModel)
                                                            ? theme.palette.text.disabled
                                                            : theme.palette.text.secondary
                                                }}>
                                                {localization.fields.startTime.scheduled.explanation({
                                                    date: TimeFormatter.longDate(expirationTime),
                                                    time: TimeFormatter.shortTime(expirationTime)
                                                })}
                                            </Typography>}
                                    </Stack>
                                    {!_.isNil(startTimeValidationMessage) && (
                                        <FormHelperText error={true}>
                                            {startTimeValidationMessage}
                                        </FormHelperText>)}
                                </Box>}
                        </Stack>
                        <Box sx={{ marginBottom: theme.spacing(4) }}>
                            <TextField
                                disabled={
                                    executing ||
                                    _.isNil(permissionEligibilityModel)}
                                fullWidth={true}
                                label={
                                    permissionEligibilityModel?.configuration.reasonRequired === false
                                        ? localization.fields.reason.title.notRequired()
                                        : localization.fields.reason.title.required()}
                                multiline={true}
                                placeholder={localization.fields.reason.placeholder()}
                                rows={3}
                                value={reason}
                                variant="outlined"
                                onChange={event => setReason(event.target.value)}/>
                            {!_.isNil(reasonValidationMessage) && (
                                <FormHelperText error={true}>
                                    {reasonValidationMessage}
                                </FormHelperText>)}
                        </Box>
                    </Stack>
                    : <Box sx={{ margin: theme.spacing(0, "auto") }}>
                        {map(
                            permissionRequestStatus,
                            {
                                [Contract.PermissionRequestStatus.PendingActivation]: () =>
                                    <PendingActivation
                                        tenantType={ScopeHelper.getTenantType(scopeNodeModelMap[permissionEligibilityModel!.configuration.scopeId].scopeNodeModel)! as PermissionManagementTenantType}
                                        onClose={() => triggerChange()}/>,
                                [Contract.PermissionRequestStatus.PendingApproval]: () =>
                                    <PendingApproval
                                        tenantType={ScopeHelper.getTenantType(scopeNodeModelMap[permissionEligibilityModel!.configuration.scopeId].scopeNodeModel)! as PermissionManagementTenantType}
                                        onClose={() => triggerChange()}/>
                            })}
                    </Box>}
            </FormLayout>
        </ContextProvider>);
}

export type AdditionalFieldComponentProps = {
    actionsRef: Ref<Optional<AdditionalFieldComponentActions>>;
    disabled: boolean;
};

export type AdditionalFieldComponentActions = {
    fetchData: (permissionEligibilityModel: Contract.PermissionEligibilityModel) => Promise<boolean>;
};