﻿import { DataTable, DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, useKubernetesTaintEffectTranslator, useTheme } from "../../../../../../../../../common";

type KubernetesTolerationsInfoCardProps = {
    tolerations: Contract.KubernetesToleration[];
};

export function KubernetesTolerationsInfoCard({ tolerations }: KubernetesTolerationsInfoCardProps) {
    const kubernetesTaintEffectTranslator = useKubernetesTaintEffectTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.kubernetesTolerationsInfoCard",
            () => ({
                columns: {
                    effect: "Effect",
                    key: "Key",
                    operator: {
                        title: "Operator",
                        [Contract.TypeNames.KubernetesTolerationOperator]: {
                            [Contract.KubernetesTolerationOperator.Equal]: "Equal",
                            [Contract.KubernetesTolerationOperator.Exists]: "Exists"
                        }
                    },
                    value: "Value"
                },
                title: "Tolerations"
            }));

    const theme = useTheme();
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                fetchItems={() => tolerations}
                getItemId={(toleration: Contract.KubernetesToleration) => _.indexOf(tolerations, toleration).toString()}
                sortOptions={{ enabled: false }}
                sx={{ maxHeight: theme.spacing(60) }}
                variant="card">
                <DataTableColumn
                    id="key"
                    render={
                        optionalTableCell<Contract.KubernetesToleration>(
                            toleration =>
                                _.isEmpty(toleration.key)
                                    ? undefined
                                    : toleration.key)}
                    title={localization.columns.key()}/>
                <DataTableColumn
                    id="operator"
                    itemProperty={(toleration: Contract.KubernetesToleration) => localization.columns.operator[Contract.TypeNames.KubernetesTolerationOperator][toleration.operator]()}
                    title={localization.columns.operator.title()}/>
                <DataTableColumn
                    id="value"
                    render={
                        optionalTableCell<Contract.KubernetesToleration>(
                            toleration =>
                                _.isEmpty(toleration.value)
                                    ? undefined
                                    : toleration.value)}
                    title={localization.columns.value()}/>
                <DataTableColumn
                    id="effect"
                    render={
                        optionalTableCell<Contract.KubernetesToleration>(
                            toleration =>
                                _.isNil(toleration.effect)
                                    ? undefined
                                    : kubernetesTaintEffectTranslator(toleration.effect))}
                    title={localization.columns.effect()}/>
            </DataTable>
        </InfoCard>);
}