﻿import { AppError, DialogMenuItem, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, DataAnalysisIcon, scopeNodeModelStore, UserHelper } from "../../../../../../../../../common";
import { GenerateDataAnalysisResourcePriorityScanRequestDialog } from "../../../components";

export function useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel, getConfigurationEnabled: (configuration: Contract.ScopeConfigurationDataAnalysis) => Optional<boolean>) {
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const dataAnalysisEnabled =
        useMemo(
            () => {
                const scopeIds = _.concat(dataAnalysisResourceModel.entity.tenantId, scopeNodeMap[dataAnalysisResourceModel.entity.tenantId].parentScopeIds);
                for (const scopeId of scopeIds) {
                    const configurationEnabled = getConfigurationEnabled(scopeNodeMap[scopeId].scopeNodeModel.configuration.scopeSections.dataAnalysis);
                    if (!_.isNil(configurationEnabled)) {
                        return configurationEnabled;
                    }
                }

                throw new AppError(`Cannot find non empty scopeNodeModel.configuration.scopeSections.dataAnalysis [tenantId=${dataAnalysisResourceModel.entity.tenantId}]`);
            },
            []);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem",
            () => ({
                enabled: "Scan Now",
                requested: "Scan Requested",
                tooltip: {
                    disabled: "Data analysis scan is disabled"
                }
            }));

    return () =>
        UserHelper.hasAnyScopePermissions(dataAnalysisResourceModel.entity.scopeIds, Contract.IdentityPermission.SecurityWrite)
            ? new DialogMenuItem(
                onClose =>
                    <GenerateDataAnalysisResourcePriorityScanRequestDialog
                        dataAnalysisResourceModel={dataAnalysisResourceModel}
                        onClose={onClose}/>,
                dataAnalysisResourceModel.dataAnalysisPriorityScanRequested
                    ? localization.requested()
                    : localization.enabled(),
                {
                    disabled:
                        dataAnalysisResourceModel.dataAnalysisPriorityScanRequested ||
                        !dataAnalysisEnabled,
                    icon: <DataAnalysisIcon/>,
                    tooltip:
                        dataAnalysisEnabled
                            ? undefined
                            : localization.tooltip.disabled()

                })
            : undefined;
}