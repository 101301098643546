import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useGciDefaultEntityInfoItemElements } from "./useGciDefaultEntityInfoItemElements";

export function useGciDefaultEntityDefinition(entityModel: Contract.GciEntityModel, options?: DefinitionOptions) {
    const defaultEntityInfoItemElements = useGciDefaultEntityInfoItemElements(entityModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultEntityInfoItemElements}
                entityPropertyInfoItemElements={[]}
                options={options?.infoOptions}/>,
        sensitive: false
    });
}