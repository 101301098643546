import { DataTableColumn, DataTableSortType, EnumValuesFilter, optionalTableCell, PagedValuesFilter, TimeSpanFormatter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsElastiCacheCacheAutoFailoverTranslator, useAwsElastiCacheCacheDeploymentTypeTranslator, useAwsElastiCacheCacheStatusTranslator, useAwsElastiCacheReplicationGroupClusterModeTranslator, useAwsElastiCacheReplicationGroupTransitEncryptionModeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsElastiCacheCacheDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);

    const elastiCacheCacheAutoFailoverTranslator = useAwsElastiCacheCacheAutoFailoverTranslator();
    const elastiCacheCacheDeploymentTypeTranslator = useAwsElastiCacheCacheDeploymentTypeTranslator();
    const elastiCacheCacheStatusTranslator = useAwsElastiCacheCacheStatusTranslator();
    const elastiCacheReplicationGroupClusterModeTranslator = useAwsElastiCacheReplicationGroupClusterModeTranslator();
    const elastiCacheReplicationGroupTransitEncryptionModeTranslator = useAwsElastiCacheReplicationGroupTransitEncryptionModeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsElastiCacheCacheDefinition",
            () => ({
                columns: {
                    autoFailover: "Auto Failover",
                    automaticBackups: {
                        false: "Disabled",
                        title: "Automatic Backups",
                        true: "Enabled"
                    },
                    autoUpgradeMinorVersions: {
                        false: "Disabled",
                        title: "Auto Upgrade Minor Versions",
                        true: "Enabled"
                    },
                    BackupRetentionTimeFrame: "Backup Retention Period",
                    clusterMode: {
                        title: "Cluster Mode",
                        unsupported: "Not supported"
                    },
                    deploymentType: "Deployment Type",
                    engineVersion: "Engine Version",
                    nodeType: "Node Type",
                    status: "Status",
                    subnetGroupName: "Subnet Group",
                    transitEncryptionEnabled: {
                        false: "Disabled",
                        title: "Encryption In Transit",
                        true: "Enabled"
                    },
                    transitEncryptionMode: "Transit Encryption Mode"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.deploymentType()]: elastiCacheCacheDeploymentTypeTranslator((item as Contract.AwsElastiCacheCacheModel).deploymentType)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsElastiCacheCacheDeploymentType}
                                    enumTypeTranslator={elastiCacheCacheDeploymentTypeTranslator}
                                    placeholder={localization.columns.deploymentType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheCacheDeploymentType}
                    itemProperty={(item: Contract.AwsElastiCacheCacheModel) => elastiCacheCacheDeploymentTypeTranslator((item as Contract.AwsElastiCacheCacheModel).deploymentType)}
                    key={Contract.EntityModelProperty.AwsElastiCacheCacheDeploymentType}
                    title={localization.columns.deploymentType()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.status()]: elastiCacheCacheStatusTranslator((item.entity as Contract.AwsElastiCacheCache).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsElastiCacheCacheStatus}
                                    enumTypeTranslator={(status: Contract.AwsElastiCacheCacheStatus) => elastiCacheCacheStatusTranslator(status)}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheCacheStatus}
                    itemProperty={(item: Contract.AwsElastiCacheCacheModel) => elastiCacheCacheStatusTranslator((item.entity as Contract.AwsElastiCacheCache).status)}
                    key={Contract.EntityModelProperty.AwsElastiCacheCacheStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.nodeType()]:
                                    item.entity.typeName === Contract.TypeNames.AwsElastiCacheReplicationGroup
                                        ? (item.entity as Contract.AwsElastiCacheReplicationGroup).nodeType
                                        : ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsElastiCacheReplicationGroupNodeType)}
                                    placeholder={localization.columns.nodeType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheReplicationGroupNodeType}
                    key={Contract.EntityModelProperty.AwsElastiCacheReplicationGroupNodeType}
                    render={
                        optionalTableCell<Contract.AwsElastiCacheCacheModel>(
                            item =>
                                item.entity.typeName === Contract.TypeNames.AwsElastiCacheReplicationGroup
                                    ? (item.entity as Contract.AwsElastiCacheReplicationGroup).nodeType
                                    : undefined
                        )
                    }
                    title={localization.columns.nodeType()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.engineVersion()]: (item.entity as Contract.AwsElastiCacheCache).engineVersion ?? ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsElastiCacheCacheEngineVersion)}
                                    placeholder={localization.columns.engineVersion()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheCacheEngineVersion}
                    key={Contract.EntityModelProperty.AwsElastiCacheCacheEngineVersion}
                    render={optionalTableCell<Contract.AwsElastiCacheCacheModel>(item => (item.entity as Contract.AwsElastiCacheCache).engineVersion)}
                    title={localization.columns.engineVersion()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.clusterMode.title()]:
                                    item.entity.typeName === Contract.TypeNames.AwsElastiCacheReplicationGroup
                                        ? elastiCacheReplicationGroupClusterModeTranslator((item.entity as Contract.AwsElastiCacheReplicationGroup).clusterMode)
                                        : ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    emptyValueOptions={{
                                        enabled: true,
                                        title: localization.columns.clusterMode.unsupported()
                                    }}
                                    enumType={Contract.AwsElastiCacheReplicationGroupClusterMode}
                                    enumTypeTranslator={(clusterMode: Contract.AwsElastiCacheReplicationGroupClusterMode) => elastiCacheReplicationGroupClusterModeTranslator(clusterMode)}
                                    placeholder={localization.columns.clusterMode.title()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheReplicationGroupClusterMode}
                    key={Contract.EntityModelProperty.AwsElastiCacheReplicationGroupClusterMode}
                    render={
                        optionalTableCell<Contract.AwsElastiCacheCacheModel>(
                            item =>
                                item.entity.typeName === Contract.TypeNames.AwsElastiCacheReplicationGroup
                                    ? elastiCacheReplicationGroupClusterModeTranslator((item.entity as Contract.AwsElastiCacheReplicationGroup).clusterMode)
                                    : undefined)
                    }
                    title={localization.columns.clusterMode.title()}/>,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(),
                commonNetworkedResourceDefinition.columns.securityGroupsColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.subnetGroupName()]:
                                    item.entity.typeName === Contract.TypeNames.AwsElastiCacheReplicationGroup
                                        ? (item.entity as Contract.AwsElastiCacheReplicationGroup).subnetGroupName
                                        : ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsElastiCacheReplicationGroupSubnetGroupName)}
                                    placeholder={localization.columns.subnetGroupName()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheReplicationGroupSubnetGroupName}
                    key={Contract.EntityModelProperty.AwsElastiCacheReplicationGroupSubnetGroupName}
                    render={
                        optionalTableCell<Contract.AwsElastiCacheCacheModel>(item =>
                            item.entity.typeName === Contract.TypeNames.AwsElastiCacheReplicationGroup
                                ? (item.entity as Contract.AwsElastiCacheReplicationGroup).subnetGroupName
                                : undefined)
                    }
                    title={localization.columns.subnetGroupName()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.transitEncryptionEnabled.title()]:
                                    (item.entity as Contract.AwsElastiCacheCache).transitEncryptionEnabled
                                        ? localization.columns.transitEncryptionEnabled.true()
                                        : localization.columns.transitEncryptionEnabled.false()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.transitEncryptionEnabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.transitEncryptionEnabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.transitEncryptionEnabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheCacheTransitEncryptionEnabled}
                    itemProperty={
                        (item: Contract.AwsElastiCacheCacheModel) =>
                            (item.entity as Contract.AwsElastiCacheCache).transitEncryptionEnabled
                                ? localization.columns.transitEncryptionEnabled.true()
                                : localization.columns.transitEncryptionEnabled.false()}
                    key={Contract.EntityModelProperty.AwsElastiCacheCacheTransitEncryptionEnabled}
                    title={localization.columns.transitEncryptionEnabled.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.transitEncryptionMode()]:
                                    item.entity.typeName === Contract.TypeNames.AwsElastiCacheReplicationGroup &&
                                        !_.isNil((item.entity as Contract.AwsElastiCacheReplicationGroup).transitEncryptionMode)
                                        ? elastiCacheReplicationGroupTransitEncryptionModeTranslator((item.entity as Contract.AwsElastiCacheReplicationGroup).transitEncryptionMode!)
                                        : ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    emptyValueOptions={{ enabled: true }}
                                    enumType={Contract.AwsElastiCacheReplicationGroupTransitEncryptionMode}
                                    enumTypeTranslator={elastiCacheReplicationGroupTransitEncryptionModeTranslator}
                                    placeholder={localization.columns.transitEncryptionMode()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheReplicationGroupTransitEncryptionMode}
                    key={Contract.EntityModelProperty.AwsElastiCacheReplicationGroupTransitEncryptionMode}
                    render={
                        optionalTableCell<Contract.AwsElastiCacheCacheModel>(
                            item =>
                                item.entity.typeName === Contract.TypeNames.AwsElastiCacheReplicationGroup &&
                                    !_.isNil((item.entity as Contract.AwsElastiCacheReplicationGroup).transitEncryptionMode)
                                    ? elastiCacheReplicationGroupTransitEncryptionModeTranslator((item.entity as Contract.AwsElastiCacheReplicationGroup).transitEncryptionMode!)
                                    : undefined)
                    }
                    title={localization.columns.transitEncryptionMode()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.automaticBackups.title()]:
                                    (item.entity as Contract.AwsElastiCacheCache).automaticBackups
                                        ? localization.columns.automaticBackups.true()
                                        : localization.columns.automaticBackups.false()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.automaticBackups.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.automaticBackups.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.automaticBackups.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheCacheAutomaticBackups}
                    itemProperty={
                        (item: Contract.AwsElastiCacheCacheModel) =>
                            (item.entity as Contract.AwsElastiCacheCache).automaticBackups
                                ? localization.columns.automaticBackups.true()
                                : localization.columns.automaticBackups.false()}
                    key={Contract.EntityModelProperty.AwsElastiCacheCacheAutomaticBackups}
                    title={localization.columns.automaticBackups.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.BackupRetentionTimeFrame()]: TimeSpanFormatter.day((item.entity as Contract.AwsElastiCacheCache).backupRetentionTimeFrame)
                            })
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheCacheBackupRetentionTimeFrame}
                    itemProperty={(item: Contract.AwsResourceModel) => (TimeSpanFormatter.day((item.entity as Contract.AwsElastiCacheCache).backupRetentionTimeFrame))}
                    key={Contract.EntityModelProperty.AwsElastiCacheCacheBackupRetentionTimeFrame}
                    selectorOptions={{ default: false }}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.BackupRetentionTimeFrame()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.autoFailover()]: elastiCacheCacheAutoFailoverTranslator((item.entity as Contract.AwsElastiCacheCache).autoFailover)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsElastiCacheCacheAutoFailover}
                                    enumTypeTranslator={(autoFailover: Contract.AwsElastiCacheCacheAutoFailover) => elastiCacheCacheAutoFailoverTranslator(autoFailover)}
                                    placeholder={localization.columns.autoFailover()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheCacheAutoFailover}
                    itemProperty={(item: Contract.AwsElastiCacheCacheModel) => elastiCacheCacheAutoFailoverTranslator((item.entity as Contract.AwsElastiCacheCache).autoFailover)}
                    key={Contract.EntityModelProperty.AwsElastiCacheCacheAutoFailover}
                    title={localization.columns.autoFailover()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElastiCacheCacheModel) => ({
                                [localization.columns.autoUpgradeMinorVersions.title()]:
                                    (item.entity as Contract.AwsElastiCacheCache).autoUpgradeMinorVersions
                                        ? localization.columns.autoUpgradeMinorVersions.true()
                                        : localization.columns.autoUpgradeMinorVersions.false()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.autoUpgradeMinorVersions.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.autoUpgradeMinorVersions.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.autoUpgradeMinorVersions.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElastiCacheCacheAutoUpgradeMinorVersions}
                    itemProperty={
                        (item: Contract.AwsElastiCacheCacheModel) =>
                            (item.entity as Contract.AwsElastiCacheCache).autoUpgradeMinorVersions
                                ? localization.columns.autoUpgradeMinorVersions.true()
                                : localization.columns.autoUpgradeMinorVersions.false()}
                    key={Contract.EntityModelProperty.AwsElastiCacheCacheAutoUpgradeMinorVersions}
                    title={localization.columns.autoUpgradeMinorVersions.title()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsElastiCacheCacheRequest(
                new Contract.EntityControllerGetEntityModelPageAwsElastiCacheCacheRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheCacheAutoFailover]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheCacheAutomaticBackups]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheCacheAutoUpgradeMinorVersions]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheReplicationGroupClusterMode]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheCacheDeploymentType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheCacheEngineVersion]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheReplicationGroupNodeType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheCacheStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheReplicationGroupSubnetGroupName]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheCacheTransitEncryptionEnabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElastiCacheReplicationGroupTransitEncryptionMode])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}