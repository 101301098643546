import { Action0, Action1, ConfirmDialog, Message, TimeFormatter, useLocalization } from "@infrastructure";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { AuditEventController, Contract, UserHelper, useTheme } from "../../../../../../../../../../../../../../common";

type ViewProps = {
    onChange: Action1<Contract.AuditEventModel>;
    onClick: Action0;
    riskStatusUpdateAuditEvent: Contract.RiskStatusUpdateAuditEvent;
};

export function View({ onChange, onClick, riskStatusUpdateAuditEvent }: ViewProps) {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);

    async function deleteNote() {
        setExecuting(true);

        try {
            const { auditEventModel } =
                await AuditEventController.deleteRiskStatusNoteAuditEvent(
                    new Contract.AuditEventControllerDeleteRiskStatusNoteAuditEventRequest(
                        riskStatusUpdateAuditEvent.id));

            onChange(auditEventModel);
        } catch {
            setError(true);
            setExecuting(false);
            return;
        }

        setError(false);
        setExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.risks.profile.core.auditEventsSection.auditEvent.riskStatusUpdateAuditEvent.view",
            () => ({
                add: "Add",
                delete: {
                    confirm: "Are you sure you want to delete this note?",
                    error: "failed to delete note",
                    title: "Delete"
                },
                update: "Edit"
            }));

    const theme = useTheme();
    return (
        <Stack>
            {_.isNil(riskStatusUpdateAuditEvent.message)
                ? undefined
                : <Typography
                    sx={{
                        border: theme.border.primary,
                        padding: theme.spacing(1),
                        whiteSpace: "pre-wrap"
                    }}>
                    {riskStatusUpdateAuditEvent.message}
                </Typography>}
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ paddingTop: theme.spacing(0.5) }}>
                <Typography
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "11px",
                        paddingTop: theme.spacing(0.25)
                    }}>
                    {TimeFormatter.longDateTime(riskStatusUpdateAuditEvent.systemCreationTime)}
                </Typography>
                {UserHelper.hasScopePermissions(riskStatusUpdateAuditEvent.scopeId, Contract.IdentityPermission.SecurityWrite) &&
                    riskStatusUpdateAuditEvent.identityReference.id === UserHelper.mail &&
                    <Fragment>
                        <Typography
                            sx={{
                                "&:hover": {
                                    color: theme.palette.text.primary
                                },
                                color: theme.palette.text.secondary,
                                cursor: "pointer",
                                textDecoration: "underline"
                            }}
                            onClick={() => onClick()}>
                            {_.isNil(riskStatusUpdateAuditEvent.message)
                                ? localization.add()
                                : localization.update()}
                        </Typography>
                        {!_.isNil(riskStatusUpdateAuditEvent.message) && (
                            <Box>
                                {confirmDelete && (
                                    <ConfirmDialog
                                        message={localization.delete.confirm()}
                                        onClose={
                                            async result => {
                                                if (result !== false) {
                                                    await deleteNote();
                                                }

                                                setConfirmDelete(false);
                                            }}/>)}
                                <Typography
                                    sx={{
                                        "&:hover": {
                                            color: theme.palette.text.primary
                                        },
                                        color: theme.palette.text.secondary,
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                    }}
                                    onClick={() => setConfirmDelete(true)}>
                                    {localization.delete.title()}
                                </Typography>
                            </Box>)}
                        {executing && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        {error && (
                            <Message
                                level="error"
                                title={localization.delete.error()}/>)}
                    </Fragment>}
            </Stack>
        </Stack>);
}