import { BulletIcon, map, useLocalization } from "@infrastructure";
import { Box, List, ListItem, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useLayoutEffect, useRef, useState } from "react";

type StepsProps = {
    children: (ReactNode | string | Step)[];
    startStepCount?: number;
    sx?: SxProps;
    variant?: "bullets" | "letters" | "numbers" | "plain" | "plainNumbers";
};

export class Step {
    constructor(
        public title: ReactNode,
        public options?: StepOptions) {
    }
}

export type StepOptions = {
    actionsElement?: ReactNode;
    contentElement?: ReactNode;
};

export function Steps({ children, startStepCount = 1, sx, variant = "bullets" }: StepsProps) {
    const localization =
        useLocalization(
            "infrastructure.steps",
            () => ({
                indexes: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                plainNumbers: "{{stepCount | NumberFormatter.humanize}}."
            }));

    const theme = useTheme();
    const bulletsContainerMaxWidthRef = useRef(0);
    const [bulletsContainerWidth, setBulletsContainerWidth] = useState<number>();
    useLayoutEffect(
        () => {
            setBulletsContainerWidth(bulletsContainerMaxWidthRef.current);
        },
        []);

    variant =
        variant === "letters" && children.length > localization.indexes().length
            ? "numbers"
            : variant;

    return (
        <List
            dense={variant === "bullets"}
            disablePadding={true}
            sx={{
                marginTop:
                    variant === "plainNumbers"
                        ? theme.spacing(1)
                        : undefined,
                ...sx
            }}>
            {_.map(
                children,
                (childElementOrStep, childElementOrStepIndex) =>
                    <ListItem
                        disableGutters={true}
                        key={childElementOrStepIndex}
                        sx={{
                            color: theme.palette.text.primary,
                            ...map<string, SxProps>(
                                variant,
                                {
                                    "letters": () => ({
                                        paddingBottom: theme.spacing(2),
                                        paddingTop: theme.spacing(2)
                                    }),
                                    "numbers": () => ({
                                        border: theme.border.primary,
                                        borderRadius: theme.spacing(0.75),
                                        marginTop:
                                            childElementOrStepIndex === 0
                                                ? undefined
                                                : theme.spacing(1),
                                        padding: theme.spacing(1)
                                    }),
                                    "plain": () => ({
                                        paddingBottom: theme.spacing(0.25),
                                        paddingTop: 0
                                    }),
                                    "plainNumbers": () => ({
                                        padding: theme.spacing(1, 0)
                                    })
                                },
                                () => ({}))
                        }}>
                        <Stack sx={{ width: "100%" }}>
                            <Stack
                                alignItems={
                                    variant === "numbers"
                                        ? "center"
                                        : "baseline"}
                                direction="row"
                                spacing={1}
                                sx={{ maxWidth: "100%" }}>
                                {variant !== "plain" && (
                                    <Box
                                        ref={
                                            (bulletsContainer: HTMLDivElement) => {
                                                if (!bulletsContainer) {
                                                    return;
                                                }

                                                const bulletsContainerBoundingClientRect = bulletsContainer.getBoundingClientRect();
                                                bulletsContainerMaxWidthRef.current =
                                                    Math.max(
                                                        bulletsContainerMaxWidthRef.current,
                                                        bulletsContainerBoundingClientRect.width);
                                            }}
                                        sx={{
                                            width:
                                                bulletsContainerWidth
                                                    ? theme.px(bulletsContainerWidth)
                                                    : undefined
                                        }}>
                                        {map(
                                            variant,
                                            {
                                                "bullets": () =>
                                                    <BulletIcon
                                                        sx={{
                                                            color: theme.palette.text.primary,
                                                            fontSize: "6px"
                                                        }}/>,
                                                "letters": () =>
                                                    <Typography>
                                                        {`${localization.indexes()[startStepCount - 1 + childElementOrStepIndex]}.`}
                                                    </Typography>,
                                                "numbers": () =>
                                                    <Box
                                                        sx={{
                                                            alignItems: "center",
                                                            border: theme.border.primary,
                                                            borderRadius: theme.spacing(0.75),
                                                            display: "flex",
                                                            height: theme.spacing(3),
                                                            justifyContent: "center",
                                                            width: theme.spacing(3)
                                                        }}>
                                                        <Typography>
                                                            {startStepCount + childElementOrStepIndex}
                                                        </Typography>
                                                    </Box>,
                                                "plainNumbers": () =>
                                                    <Typography>
                                                        {localization.plainNumbers({ stepCount: startStepCount + childElementOrStepIndex })}
                                                    </Typography>
                                            })}
                                    </Box>)}
                                <Box
                                    sx={{
                                        flex: 1,
                                        maxWidth: "inherit",
                                        paddingRight: theme.spacing(2)
                                    }}>
                                    {childElementOrStep instanceof Step
                                        ? <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={1}>
                                            <Typography>
                                                {childElementOrStep.title}
                                            </Typography>
                                            {!_.isNil(childElementOrStep.options?.actionsElement) && (
                                                <Box sx={{ flex: 1 }}>
                                                    {childElementOrStep.options?.actionsElement}
                                                </Box>)}
                                        </Stack>
                                        : <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                            {childElementOrStep}
                                        </Typography>}
                                </Box>
                            </Stack>
                            {childElementOrStep instanceof Step &&
                                !_.isNil(childElementOrStep.options?.contentElement) && (
                                <Box
                                    sx={{
                                        marginLeft:
                                            bulletsContainerWidth
                                                ? theme.px(bulletsContainerWidth)
                                                : undefined,
                                        paddingLeft: theme.spacing(1)
                                    }}>
                                    {childElementOrStep.options?.contentElement}
                                </Box>)}
                        </Stack>
                    </ListItem>)}
        </List>);
}