import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeFirewallPolicyScopeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeFirewallPolicyScopeTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeFirewallPolicyScope]: {
                    [Contract.GcpComputeFirewallPolicyScope.Organization]: "Organization",
                    [Contract.GcpComputeFirewallPolicyScope.Folder]: "Folder",
                    [Contract.GcpComputeFirewallPolicyScope.Tenant]: "Network"
                }
            }));
    return (type: Contract.GcpComputeFirewallPolicyScope) =>
        localization[Contract.TypeNames.GcpComputeFirewallPolicyScope][type]();
}