import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAzureNetworkVirtualNetworkSubnetRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRisk;
    const subnetModel = entityModelStore.useGet(risk.entityId) as Contract.AzureNetworkVirtualNetworkSubnetModel;
    const subnet = subnetModel.entity as Contract.AzureNetworkVirtualNetworkSubnet;

    const getAzureNetworkVirtualNetworkSubnetRiskContext = useGetAzureNetworkVirtualNetworkSubnetRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskDefinition",
            () => ({
                description: "{{subnet}} is not filtering network traffic since it is not attached to a network security group",
                sections: {
                    resolution: {
                        step1: "Click on subnet **{{subnet}}**",
                        step2: "Select a network security group for the subnet",
                        step3: "Click **Save** button on bottom to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            subnet:
                <Entity
                    entityIdOrModel={subnetModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={subnet.virtualNetworkId}
                key={subnet.virtualNetworkId}
                page={Contract.AzureConsoleEntityPage.VirtualNetworkSubnets}/>,
            localization.sections.resolution.step1({ subnet: subnet.displayName }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const subnetRiskContext = getAzureNetworkVirtualNetworkSubnetRiskContext(subnetModel);
            return [
                subnetRiskContext.generalInformation,
                subnetRiskContext.sensitive,
                subnetRiskContext.networkedResources,
                subnetRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}