import { StringHelper, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { memo, useMemo } from "react";
import { Contract, customEntityAttributeDefinitionModelStore, useBuiltInEntityAttributeTypeNameTranslator } from "..";

type EntityAttributeFilterProps = {
    emptyValue?: boolean;
    entityAttributeValues: string[];
    placeholder: string;
};

const EntityAttributeFilterMemo = memo(EntityAttributeFilter);
export { EntityAttributeFilterMemo as EntityAttributeFilter };

function EntityAttributeFilter({ emptyValue, entityAttributeValues, placeholder }: EntityAttributeFilterProps) {
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const builtInEntityAttributeTypeNameTranslator = useBuiltInEntityAttributeTypeNameTranslator();
    const items =
        useMemo(
            () => {
                const customEntityAttributeDefinitionModelMap =
                    _.keyBy(
                        customEntityAttributeDefinitionModels,
                        customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id);
                return _(entityAttributeValues).
                    map(
                        entityAttributeValue => ({
                            title:
                                !_.isNil(customEntityAttributeDefinitionModelMap[entityAttributeValue])
                                    ? customEntityAttributeDefinitionModelMap[entityAttributeValue].configuration.name
                                    : entityAttributeValue in Contract.TypeNames
                                        ? builtInEntityAttributeTypeNameTranslator(entityAttributeValue)
                                        : undefined,
                            value: entityAttributeValue
                        })).
                    filter(({ title }) => !_.isNil(title)).
                    orderBy(({ title }) => StringHelper.getSortValue(title)).
                    value();
            },
            [builtInEntityAttributeTypeNameTranslator, entityAttributeValues, customEntityAttributeDefinitionModels]);
    return (
        <ValuesFilter
            emptyValueOptions={{ enabled: emptyValue }}
            groupItemTitle={true}
            placeholder={placeholder}>
            {_.map(
                items,
                ({ title, value }) =>
                    <ValuesFilterItem
                        key={value}
                        title={title}
                        value={value}/>)}
        </ValuesFilter>);
}