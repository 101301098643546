import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsDynamoDbTableRiskContext } from "../contexts";

export function useAwsDynamoDbTableDeleteProtectionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsDynamoDbTableDeleteProtectionDisabledRisk;
    const tableModel = entityModelStore.useGet(risk.entityId) as Contract.AwsDynamoDbTableModel;

    const getAwsDynamoDbTableRiskContext = useGetAwsDynamoDbTableRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsDynamoDbTableDeleteProtectionDisabledRiskDefinition",
            () => ({
                description: "{{table}} delete protection is not enabled",
                sections: {
                    resolution: {
                        step1: "Go to the **Additional settings** tab",
                        step2: "Find to the **Deletion protection** section and click **Turn on**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            table:
                <Entity
                    entityIdOrModel={tableModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.DynamoDb,
                AwsConsoleUrlBuilder.getDynamoDbTableUrl(tableModel.entity as Contract.AwsDynamoDbTable)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const tableContextItems = getAwsDynamoDbTableRiskContext(tableModel);
            return [
                tableContextItems.generalInformation,
                tableContextItems.storageSize,
                tableContextItems.highestDataSensitivity,
                tableContextItems.sensitive,
                tableContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}