import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetOciIamCredentialRiskContext } from "./useGetOciIamCredentialRiskContext";

export function useGetOciIamUserDatabasePasswordRiskContext() {
    return useMemo(
        () => useOciIamUserDatabasePasswordRiskContext,
        []);
}

function useOciIamUserDatabasePasswordRiskContext(UserDatabasePasswordModel: Contract.OciIamUserDatabasePasswordModel) {
    return useGetOciIamCredentialRiskContext()(UserDatabasePasswordModel);
}