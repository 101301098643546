import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useCloudProviderTenantPermissionTypesTranslator } from "../../../../../../../../../common";

export function useGcpOrganizationAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const cloudProviderTenantPermissionTypesTranslator = useCloudProviderTenantPermissionTypesTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useGcpOrganizationAuditEventDefinition",
            () => ({
                enabled: {
                    false: "Off",
                    title: "Onboard new accounts",
                    true: "On"
                },
                folderEnabled: {
                    false: "Off",
                    title: "Folder structure sync",
                    true: "On"
                },
                gciTenantName: "Workspace name",
                gciTenantRawId: "Customer ID",
                name: "Organization name",
                permissionTypes: "Permissions",
                propertyChanges: "Updated properties",
                sinkPubSubSubscriptionRawId: "Pub/Sub subscription"
            }));

    const organizationAuditEvent = context.auditEventModel.auditEvent as Contract.GcpOrganizationAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.gciTenantName(),
                    organizationAuditEvent.gciTenantName),
                new AuditEventDefinitionDetailItem(
                    localization.gciTenantRawId(),
                    organizationAuditEvent.gciTenantRawId),
                new AuditEventDefinitionDetailItem(
                    localization.name(),
                    organizationAuditEvent.name)).
            concatIf(
                organizationAuditEvent.typeName === Contract.TypeNames.GcpOrganizationCreationAuditEvent ||
                organizationAuditEvent.typeName === Contract.TypeNames.GcpOrganizationUpdateAuditEvent,
                () => {
                    const data = (context.auditEventModel.auditEvent as Contract.GcpOrganizationCreationAuditEvent | Contract.GcpOrganizationUpdateAuditEvent).data;
                    return _<AuditEventDefinitionDetailItem>([]).
                        concat(
                            new AuditEventDefinitionDetailItem(
                                localization.permissionTypes(),
                                _.join(
                                    cloudProviderTenantPermissionTypesTranslator.multiple(
                                        data.permissionTypes,
                                        "shortName"),
                                    ",")),
                            new AuditEventDefinitionDetailItem(
                                localization.enabled.title(),
                                data.enabled
                                    ? localization.enabled.true()
                                    : localization.enabled.false()),
                            new AuditEventDefinitionDetailItem(
                                localization.folderEnabled.title(),
                                data.folderEnabled
                                    ? localization.folderEnabled.true()
                                    : localization.folderEnabled.false())).
                        concatIf(
                            !_.isNil(data.sinkPubSubSubscriptionRawId),
                            new AuditEventDefinitionDetailItem(
                                localization.sinkPubSubSubscriptionRawId(),
                                data.sinkPubSubSubscriptionRawId!)).
                        value();
                }).
            concatIf(
                organizationAuditEvent.typeName === Contract.TypeNames.GcpOrganizationUpdateAuditEvent,
                () => {
                    const organizationUpdateAuditEvent = organizationAuditEvent as Contract.GcpOrganizationUpdateAuditEvent;
                    const properties =
                        _.filter([
                            organizationUpdateAuditEvent.enabledChanged
                                ? localization.enabled.title()
                                : undefined,
                            organizationUpdateAuditEvent.folderEnabledChanged
                                ? localization.folderEnabled.title()
                                : undefined,
                            organizationUpdateAuditEvent.nameChanged
                                ? localization.name()
                                : undefined,
                            organizationUpdateAuditEvent.permissionTypesChanged
                                ? localization.permissionTypes()
                                : undefined,
                            organizationUpdateAuditEvent.sinkPubSubSubscriptionRawIdChanged
                                ? localization.sinkPubSubSubscriptionRawId()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}