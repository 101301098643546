import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useOktaCommonDirectoryEntityDefinition } from "./useOktaCommonDirectoryEntityDefinition";

export function useOktaDirectoryGroupDefinition(definitionData: DefinitionData) {
    const commonDirectoryEntityDefinition = useOktaCommonDirectoryEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.okta.useOktaDirectoryGroupDefinition",
            () => ({
                columns: {
                    awsRoles: "AWS Roles",
                    users: "Users"
                }
            }));
    return new EntityTableDefinition(
        [
            commonDirectoryEntityDefinition.columns.tenantColumn,
            commonDirectoryEntityDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OktaDirectoryGroupModel>(
                        Contract.TypeNames.AwsIamRole,
                        item => item.awsRoleIds,
                        localization.columns.awsRoles())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OktaDirectoryPrincipalAwsRoles)}
                                placeholder={localization.columns.awsRoles()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OktaDirectoryPrincipalAwsRoles}
                key={Contract.EntityModelProperty.OktaDirectoryPrincipalAwsRoles}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OktaDirectoryGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.awsRoleIds}
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.awsRoles()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OktaDirectoryGroupModel>(
                        Contract.TypeNames.OktaDirectoryUser,
                        item => (item.entity as Contract.OktaDirectoryGroup).userIds,
                        localization.columns.users())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.OktaDirectoryUser,
                                        true)}
                                placeholder={localization.columns.users()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OktaDirectoryGroupUsers}
                key={Contract.EntityModelProperty.OktaDirectoryGroupUsers}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OktaDirectoryGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.OktaDirectoryGroup).userIds}
                            entityTypeName={Contract.TypeNames.OktaDirectoryUser}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.users()}/>,
            commonDirectoryEntityDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOktaDirectoryGroupRequest(
                new Contract.EntityControllerGetEntityModelPageOktaDirectoryGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.OktaDirectoryEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OktaDirectoryPrincipalAwsRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OktaDirectoryGroupUsers])),
                limit,
                skip,
                requestSort));
}