import { EmptyMessage, NumberFormatter, useLocalization } from "@infrastructure";
import { Box, Grid2, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, useBuiltInEntityAttributeTypeNameTranslator, usePrincipalRiskCategoryTranslator, useTheme } from "../../../../../../../../../common";
import { DistributionChartGrid, DistributionChartGridItem } from "./DistributionChartGrid";
import { SecurityStatsDistributionChart } from "./SecurityStatsDistributionChart";

type PrincipalExcessivePermissionDistributionProps = {
    identityExcessivePermissionActionStats: Contract.SecurityStats;
    principalRiskCategoryToSummaryMap: Dictionary<Contract.ReportControllerGetDashboardReportResponseRiskCategorySummary>;
    report?: boolean;
};

export function PrincipalExcessivePermissionDistribution({ identityExcessivePermissionActionStats, principalRiskCategoryToSummaryMap, report = false }: PrincipalExcessivePermissionDistributionProps) {
    const [excessivePermissionActionSeverityToPercentageMap, excludedExcessivePermissionActionPercentage, nonexcessivePermissionActionPercentage, riskedPrincipalCount] =
        useMemo(
            () => {
                const excessivePermissionActionDatas =
                    _(identityExcessivePermissionActionStats.unsecuredSeverityToCountMap).
                        map(
                            (excessivePermissionActionCount, excessivePermissionActionSeverity) => ({
                                count: excessivePermissionActionCount,
                                severity: excessivePermissionActionSeverity as Contract.Severity
                            })).
                        filter(({ count }) => count > 0).
                        value();

                const [excludedExcessivePermissionActionPercentage, nonexcessivePermissionActionPercentage, ...excessivePermissionActionPercentages] =
                    NumberFormatter.distributionPercentages(
                        [
                            identityExcessivePermissionActionStats.unsecuredExcludedCount / identityExcessivePermissionActionStats.count,
                            identityExcessivePermissionActionStats.securedCount / identityExcessivePermissionActionStats.count,
                            ..._.map(
                                excessivePermissionActionDatas,
                                ({ count }) => count / identityExcessivePermissionActionStats.count)
                        ],
                        true);

                const excessivePermissionActionSeverityToPercentageMap =
                    _(excessivePermissionActionDatas).
                        map(
                            (excessivePermissionActionData, excessivePermissionActionDataIndex) => ({
                                ...excessivePermissionActionData,
                                percentage: excessivePermissionActionPercentages[excessivePermissionActionDataIndex]
                            })).
                        keyBy(({ severity }) => severity).
                        mapValues(({ percentage }) => percentage).
                        value();

                const riskedPrincipalCount =
                    _(principalRiskCategoryToSummaryMap).
                        values().
                        map(principalRiskCategorySummary => principalRiskCategorySummary.principalCount).
                        sum();

                return [excessivePermissionActionSeverityToPercentageMap, excludedExcessivePermissionActionPercentage, nonexcessivePermissionActionPercentage, riskedPrincipalCount];
            },
            [identityExcessivePermissionActionStats, principalRiskCategoryToSummaryMap]);

    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.principalExcessivePermissionDistributionSection.principalExcessivePermissionDistribution",
            () => ({
                chart: {
                    subtitle: "Excessive"
                },
                empty: "No Excessive Permissions"
            }));

    const theme = useTheme();
    return (
        riskedPrincipalCount === 0
            ? <EmptyMessage message={localization.empty()}/>
            : <Stack
                direction="row"
                sx={{
                    alignItems: "center",
                    height: "100%"
                }}>
                <Box sx={{ flex: 1 }}>
                    <DistributionChartGrid
                        itemHeight={160}
                        items={
                            _(principalRiskCategoryToSummaryMap).
                                map(
                                    (principalRiskCategorySummary, principalRiskCategory) => ({
                                        principalRiskCategory: principalRiskCategory as Contract.PrincipalRiskCategory,
                                        principalRiskCategorySummary
                                    })).
                                orderBy(
                                    ({ principalRiskCategorySummary }) => principalRiskCategorySummary.principalCount,
                                    "desc").
                                take(6).
                                map(
                                    ({ principalRiskCategory, principalRiskCategorySummary }) =>
                                        new DistributionChartGridItem(
                                            principalRiskCategory,
                                            severity =>
                                                CustomerConsoleAppUrlHelper.getPrincipalExcessivePermissionsRelativeUrl(
                                                    principalRiskCategory,
                                                    {
                                                        severities:
                                                            _.isNil(severity)
                                                                ? undefined
                                                                : [severity as Contract.Severity]
                                                    }),
                                            highlight =>
                                                <ItemSummary
                                                    highlight={highlight}
                                                    principalRiskCategory={principalRiskCategory as Contract.PrincipalRiskCategory}
                                                    principalRiskCategorySummary={principalRiskCategorySummary}/>,
                                            principalRiskCategorySummary.openRiskSeverityToPrincipalCountMap,
                                            NumberFormatter.unit(principalRiskCategorySummary.principalCount))).
                                value()}
                        report={report}/>
                </Box>
                <Grid2
                    alignItems="center"
                    container={true}
                    sx={{
                        height: "100%",
                        marginRight: theme.spacing(5)
                    }}>
                    <SecurityStatsDistributionChart
                        getUrl={
                            severity =>
                                severity === "secured" || severity === "unsecuredExcluded" || report
                                    ? undefined
                                    : CustomerConsoleAppUrlHelper.getPrincipalExcessivePermissionsRelativeUrl(
                                        undefined,
                                        {
                                            severities:
                                                _.isNil(severity)
                                                    ? undefined
                                                    : [severity as Contract.Severity]
                                        })}
                        securityStats={identityExcessivePermissionActionStats}
                        subtitle={localization.chart.subtitle()}
                        title={
                            NumberFormatter.percentage(
                                _(excessivePermissionActionSeverityToPercentageMap).
                                    map(excessivePermissionActionPercentage => excessivePermissionActionPercentage).
                                    sum())}
                        variant="donut"
                        onTooltip={
                            (excluded, severity) =>
                                <ItemTooltip
                                    excluded={excluded}
                                    percentage={
                                        _.isNil(severity)
                                            ? excluded
                                                ? excludedExcessivePermissionActionPercentage
                                                : nonexcessivePermissionActionPercentage
                                            : excessivePermissionActionSeverityToPercentageMap[severity]}
                                    severity={severity}/>}/>
                </Grid2>
            </Stack>);
}

type ItemSummaryProps = {
    highlight: boolean;
    principalRiskCategory: Contract.PrincipalRiskCategory;
    principalRiskCategorySummary: Contract.ReportControllerGetDashboardReportResponseRiskCategorySummary;
};

function ItemSummary({ highlight, principalRiskCategory, principalRiskCategorySummary }: ItemSummaryProps) {
    const builtInEntityAttributeTypeNameTranslator = useBuiltInEntityAttributeTypeNameTranslator();
    const principalRiskCategoryTranslator = usePrincipalRiskCategoryTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.principalExcessivePermissionDistributionSection.principalExcessivePermissionDistribution.itemSummary",
            () => ({
                vendorServiceIdentity: [
                    "1 identity",
                    "{{count | NumberFormatter.humanize}} identities"
                ]
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            sx={{ height: "65px" }}>
            <Typography
                align="center"
                sx={{
                    fontWeight: 500,
                    marginLeft: theme.spacing(0.5),
                    width: theme.spacing(15)
                }}>
                {principalRiskCategoryTranslator(principalRiskCategory)}
            </Typography>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                sx={{
                    marginTop: theme.spacing(0.5),
                    visibility:
                        highlight && principalRiskCategorySummary.vendorServiceIdentityCount > 0
                            ? "visible"
                            : "hidden"
                }}>
                <Typography
                    noWrap={true}
                    sx={{
                        backgroundColor: theme.palette.entityAttribute.builtIn(Contract.TypeNames.VendorServiceIdentityAttribute),
                        borderRadius: theme.spacing(2),
                        color: theme.palette.attribute.label,
                        fontSize: "9px",
                        fontWeight: 500,
                        padding: theme.spacing(0.25, 0.5)
                    }}>
                    {builtInEntityAttributeTypeNameTranslator(Contract.TypeNames.VendorServiceIdentityAttribute)}
                </Typography>
                <Typography
                    noWrap={true}
                    sx={{
                        fontSize: "9px",
                        fontWeight: 500,
                        marginLeft: theme.spacing(0.5)
                    }}>
                    {localization.vendorServiceIdentity(principalRiskCategorySummary.vendorServiceIdentityCount)}
                </Typography>
            </Stack>
        </Stack>);
}

type ItemTooltipProps = {
    excluded: boolean;
    percentage: number;
    severity?: Contract.Severity;
};

function ItemTooltip({ excluded, percentage, severity }: ItemTooltipProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.principalExcessivePermissionDistributionSection.principalExcessivePermissionDistribution.itemTooltip",
            () => ({
                title: {
                    excessivePermissionAction: {
                        [Contract.TypeNames.Severity]: {
                            [Contract.Severity.Critical]: "Critical permissions",
                            [Contract.Severity.High]: "High permissions",
                            [Contract.Severity.Low]: "Low permissions",
                            [Contract.Severity.Medium]: "Medium permissions"
                        }
                    },
                    excludedExcessivePermissionAction: "Excluded permissions",
                    nonexcessivePermissionAction: "Used permissions"
                },
                usage: "**{{percentage | NumberFormatter.percentageFraction}}** of all permissions"
            }));
    const theme = useTheme();
    return (
        <Stack sx={{ maxWidth: theme.spacing(25) }}>
            <Typography>
                {_.isNil(severity)
                    ? excluded
                        ? localization.title.excludedExcessivePermissionAction()
                        : localization.title.nonexcessivePermissionAction()
                    : localization.title.excessivePermissionAction[Contract.TypeNames.Severity][severity as Exclude<Contract.Severity, Contract.Severity.Information>]()}
            </Typography>
            <Typography>
                {localization.usage({ percentage })}
            </Typography>
        </Stack>);
}