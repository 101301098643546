import { Box, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { Action0, Dropdown, DropdownActions, DropdownIcon, ItemSelectorField, Tooltip, useLocalization } from "@infrastructure";
import { Contract, Scope, scopeNodeModelStore, ScopeTree, TreeItem, useTheme } from "..";

type ScopeSelectorProps = {
    disabled?: boolean;
    dropdownIcon?: ReactElement;
    emptyValue?: string;
    expandOnScopeClick?: boolean;
    fullWidth?: boolean;
    includeEmptyFolders?: boolean;
    includeProjects?: boolean;
    inline?: boolean;
    isScopeExpanded?: (treeItem: TreeItem<Contract.ScopeNodeModel>) => boolean;
    onSelectedScopeIdChanged: (selectedScopeId: string) => void;
    onSelectionCleared?: Action0;
    placeholder?: string;
    popoverElementContainerSx?: SxProps;
    rootFolderId?: string;
    scopeIds: string[];
    scopeItemTooltip?: (scopeId: string) => React.ReactNode | (() => Promise<ReactNode>);
    scopeSelectable?: (scopeId: string) => boolean;
    selectedScopeId?: string;
    treeSx?: SxProps;
};

export function ScopeSelector({ disabled = false, dropdownIcon, emptyValue, expandOnScopeClick = false, fullWidth, includeEmptyFolders, includeProjects, inline, isScopeExpanded, onSelectedScopeIdChanged, onSelectionCleared, placeholder, popoverElementContainerSx, rootFolderId, scopeIds, scopeItemTooltip, scopeSelectable, selectedScopeId, treeSx }: ScopeSelectorProps) {
    const localization =
        useLocalization(
            "common.scopeSelector",
            () => ({
                placeholder: "Scope"
            }));

    const dropdownActionsRef = useRef<DropdownActions>();
    const [open, setOpen] = useState(false);
    const dropdownDisabled = disabled || _.isEmpty(scopeIds);
    const theme = useTheme();
    return (
        <Dropdown
            actionsRef={dropdownActionsRef}
            disabled={dropdownDisabled}
            fullWidth={fullWidth}
            open={open}
            popoverElement={
                <ScopeTree
                    containerSx={
                        fullWidth
                            ? undefined
                            : {
                                maxWidth: theme.spacing(80),
                                minWidth: theme.spacing(40)
                            }}
                    expandOnScopeClick={expandOnScopeClick}
                    includeEmptyFolders={includeEmptyFolders}
                    includeProjects={includeProjects}
                    isExpanded={isScopeExpanded}
                    rootFolderId={rootFolderId}
                    scopeIds={scopeIds}
                    selectedItemId={selectedScopeId}
                    treeSx={treeSx}
                    onScopeClick={
                        scopeNodeModelItem => {
                            if (scopeSelectable?.(scopeNodeModelItem.value.configuration.id) ?? true) {
                                onSelectedScopeIdChanged(scopeNodeModelItem.value.configuration.id);
                                dropdownActionsRef.current!.close();
                            }
                        }}>
                    {scopeNodeModelItem =>
                        <Item
                            scopeId={scopeNodeModelItem.value.configuration.id}
                            scopeSelectable={scopeSelectable}
                            selected={scopeNodeModelItem.value.configuration.id === selectedScopeId}
                            tooltip={scopeItemTooltip?.(scopeNodeModelItem.value.configuration.id)}
                            variant="popup"/>}
                </ScopeTree>}
            popoverElementContainerSx={popoverElementContainerSx}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            {inline
                ? <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                        color:
                            dropdownDisabled
                                ? theme.palette.text.disabled
                                : undefined,
                        cursor:
                            dropdownDisabled
                                ? undefined
                                : "pointer",
                        height: "36px"
                    }}>
                    <Typography noWrap={true}>
                        {_.isNil(selectedScopeId)
                            ? emptyValue
                            : <Scope scopeId={selectedScopeId}/>}
                    </Typography>
                    <DropdownIcon sx={{ fontSize: "18px" }}/>
                </Stack>
                : <ItemSelectorField
                    disabled={dropdownDisabled}
                    emptyValue={emptyValue}
                    icon={dropdownIcon}
                    open={open}
                    placeholder={placeholder ?? localization.placeholder()}
                    readOnly={dropdownDisabled}
                    selection={
                        _.isNil(selectedScopeId)
                            ? undefined
                            : <Item
                                scopeId={selectedScopeId}
                                scopeSelectable={scopeSelectable}
                                variant="selection"/>}
                    onSelectionCleared={onSelectionCleared}/>}
        </Dropdown>);
}

type ItemProps = {
    scopeId: string;
    scopeSelectable?: (scopeId: string) => boolean;
    selected?: boolean;
    tooltip?: React.ReactNode | (() => Promise<ReactNode>);
    variant: "popup" | "selection";
};

function Item({ scopeId, scopeSelectable, selected, tooltip, variant }: ItemProps) {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const scopeContainerRef = useRef<HTMLDivElement>();

    useEffect(
        () => {
            if (selected && !_.isNil(scopeContainerRef.current)) {
                scopeContainerRef.current.scrollIntoView({
                    behavior: "instant",
                    block: "center"
                });
            }
        },
        []);

    const theme = useTheme();
    return (
        <Tooltip titleOrGetTitle={tooltip}>
            <Box ref={scopeContainerRef}>
                <Scope
                    scopeId={scopeId}
                    scopeNameTranslatorOptions={{
                        tenantNameTranslatorOptions: {
                            includeRawId:
                                scopeNodeModel.configuration.typeName === Contract.TypeNames.AwsTenantConfiguration ||
                                scopeNodeModel.configuration.typeName === Contract.TypeNames.GcpTenantConfiguration
                        }
                    }}
                    sx={
                        variant === "popup"
                            ? {
                                color: theme.palette.text.primary,
                                padding: theme.px(6),
                                ...(!(scopeSelectable?.(scopeNodeModel.configuration.id) ?? true) && {
                                    color: theme.palette.text.disabled,
                                    cursor: "default"
                                })
                            }
                            : undefined}
                    variant={
                        variant === "selection"
                            ? "text"
                            : "iconText"}/>
            </Box>
        </Tooltip>);
}