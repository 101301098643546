﻿import { DataTableActions, DataTableColumn, DataTableColumnRenderProps, InlineItems, ItemSelector, optionalTableCell, TimeFormatter, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Contract, EntitiesCell, Entity, entityModelStore, NetworkScopeFormatter, Region, Severity, useTheme } from "../../../../../../../../../../../../../../../../common";
import { useAwsEc2InstanceStatusTranslator } from "../../../../../../../../../../../../../Entities/hooks";
import { Table } from "../../../../../../../../components";

type DetailsSectionProps = {
    riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel;
};

export function DetailsSection({ riskModel }: DetailsSectionProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    const launchTemplateModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEc2LaunchTemplateModel;
    const launchTemplate = launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate;
    const revisionIdToRiskedInstanceIdsMap =
        useMemo(
            () =>
                _(launchTemplateModel.revisionIdToDataMap).
                    mapValues(revision => _.intersection(revision.instanceIds, riskModel.risk.aggregatedEntityIds)).
                    pickBy(instanceIds => !_.isEmpty(instanceIds)).
                    value(),
            [launchTemplateModel]);
    const revisionIds =
        _(revisionIdToRiskedInstanceIdsMap).
            keys().
            map(Number).
            value();

    const [selectedRevisionId, setSelectedRevisionId] = useState<number>(_.head(revisionIds)!);
    useChangeEffect(
        () => dataTableActionsRef.current?.reset(),
        [selectedRevisionId]);

    const revisionInstanceModels = entityModelStore.useGet(revisionIdToRiskedInstanceIdsMap[selectedRevisionId]) as Contract.AwsEc2InstanceModel[];
    const tableItems =
        useMemo(
            () =>
                _.map(
                    revisionInstanceModels,
                    revisionInstanceModel =>
                        new DetailsSectionItem(
                            riskModel.risk.instanceIdToDataMap[revisionInstanceModel.id],
                            revisionInstanceModel,
                            riskModel.risk.launchTemplateInstanceIdToRolePermissionActionSeverityMap![revisionInstanceModel.id])),
            [revisionInstanceModels]);

    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useAwsInboundExternalEc2InstanceRiskLaunchTemplateDefinition.detailsSection",
            () => ({
                columns: {
                    destinationPortRanges: "Public Ports",
                    inboundExternalInstance: "Instance",
                    launchTime: "Launch Time",
                    regionSystemName: "Region",
                    rolePermissionActionSeverity: "Permissions Severity",
                    securityGroupIds: "Public Security Groups",
                    status: "State",
                    subnets: "Sources",
                    vulnerabilities: "Vulnerabilities"
                },
                selectedRevision: {
                    default: "Default : {{revisionId}}",
                    latest: "Latest : {{revisionId}}"
                }
            }));
    const theme = useTheme();
    return (
        <Stack sx={{ overflow: "hidden auto" }}>
            <Box
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <ItemSelector
                    dense={true}
                    fullWidth={true}
                    items={revisionIds}
                    selectedItem={selectedRevisionId}
                    sorted={false}
                    onSelectedItemChanged={revisionId => setSelectedRevisionId(revisionId)}>
                    {(revisionId: number) =>
                        revisionId === launchTemplate.defaultRevisionId
                            ? localization.selectedRevision.default({ revisionId })
                            : revisionId === launchTemplate.latestRevisionId
                                ? localization.selectedRevision.latest({ revisionId })
                                : revisionId}
                </ItemSelector>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <Table
                    dataTableActionsRef={dataTableActionsRef}
                    fetchItems={() => tableItems}
                    getItemId={(item: DetailsSectionItem) => item.instanceModel.id}>
                    <DataTableColumn
                        cellSx={{ maxWidth: theme.spacing(44) }}
                        id={DetailsSectionColumnId.InboundExternalInstance}
                        render={
                            ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                                <Entity
                                    entityIdOrModel={item.instanceModel}
                                    variant="iconTextTenant"/>}
                        title={localization.columns.inboundExternalInstance()}/>
                    <DataTableColumn
                        id={DetailsSectionColumnId.DestinationPortRanges}
                        render={
                            ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                                <InlineItems
                                    items={
                                        _.map(
                                            item.instanceData.publicAccess.destinationNetworkScopes,
                                            networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                                    variant="itemPlusItemCount"/>}
                        title={localization.columns.destinationPortRanges()}/>
                    <DataTableColumn
                        id={DetailsSectionColumnId.Subnets}
                        render={
                            ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                                <InlineItems
                                    items={item.instanceData.publicAccess.subnets}
                                    variant="itemPlusItemCount"/>}
                        title={localization.columns.subnets()}/>
                    <DataTableColumn
                        id={DetailsSectionColumnId.SecurityGroupIds}
                        render={
                            ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.instanceData.publicAccess.securityGroupIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}/>}
                        title={localization.columns.securityGroupIds()}/>
                    <DataTableColumn
                        id={DetailsSectionColumnId.LaunchTime}
                        render={
                            optionalTableCell<DetailsSectionItem>(
                                ({ instanceModel }) =>
                                    instanceModel.unknown
                                        ? undefined
                                        : TimeFormatter.shortDateTime((instanceModel.entity as Contract.AwsEc2Instance).launchTime))}
                        title={localization.columns.launchTime()}/>
                    <DataTableColumn
                        id={DetailsSectionColumnId.Status}
                        render={
                            optionalTableCell<DetailsSectionItem>(
                                ({ instanceModel }) =>
                                    instanceModel.unknown
                                        ? undefined
                                        : ec2InstanceStatusTranslator((instanceModel.entity as Contract.AwsEc2Instance).status))
                        }
                        title={localization.columns.status()}/>
                    <DataTableColumn
                        id={DetailsSectionColumnId.RolePermissionActionSeverity}
                        render={
                            optionalTableCell<DetailsSectionItem>(
                                ({ rolePermissionActionSeverity }) =>
                                    <Severity severity={rolePermissionActionSeverity}/>)}
                        title={localization.columns.rolePermissionActionSeverity()}/>
                    <DataTableColumn
                        id={DetailsSectionColumnId.Vulnerabilities}
                        render={
                            optionalTableCell<DetailsSectionItem>(
                                item =>
                                    _.isNil(item.instanceData.vulnerabilities)
                                        ? undefined
                                        : <InlineItems
                                            items={item.instanceData.vulnerabilities!.rawIds}
                                            variant="itemPlusItemCount"/>)}
                        title={localization.columns.vulnerabilities()}/>
                    <DataTableColumn
                        id={DetailsSectionColumnId.RegionSystemName}
                        render={
                            ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                                <Region regionId={item.instanceModel.entity.regionId}/>}
                        title={localization.columns.regionSystemName()}/>
                </Table>
            </Box>
        </Stack>);
}

class DetailsSectionItem {
    constructor(
        public instanceData: Contract.AwsInboundExternalEc2InstanceRiskInstanceData,
        public instanceModel: Contract.AwsEc2InstanceModel,
        public rolePermissionActionSeverity: Contract.Severity | undefined) {
    }
}

enum DetailsSectionColumnId {
    Actions = "actions",
    DestinationPortRanges = "destinationPortRanges",
    InboundExternalInstance = "inboundExternalInstance",
    LaunchTime = "launchTime",
    RegionSystemName = "regionSystemName",
    RolePermissionActionSeverity = "rolePermissionActionSeverity",
    SecurityGroupIds = "securityGroupIds",
    Status = "status",
    Subnets = "subnets",
    Vulnerabilities = "vulnerabilities"
}