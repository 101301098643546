import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../common";

type StatementsProps = {
    policy: Contract.OciIamPolicy;
};

export function Statements({ policy }: StatementsProps) {
    const theme = useTheme();
    return (
        <Stack
            sx={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75)
            }}>
            {_.map(
                policy.statements,
                (statement, index) =>
                    <Typography
                        sx={{
                            borderBottom:
                                index + 1 === policy.statements.length
                                    ? undefined
                                    : theme.border.primary,
                            padding: theme.spacing(1)
                        }}>
                        {statement}
                    </Typography>)}
        </Stack>);
}