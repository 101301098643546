import { Action0, NavigationViewItem, PagedItems } from "@infrastructure";
import { Box, Stack, useTheme } from "@mui/material";
import React from "react";
import { Item } from "../Item";
import { getTreeTitleLevelPadding } from "./Title";

type ItemsProps = {
    item: NavigationViewItem;
    level: number;
    onNavigate?: Action0;
    paged?: boolean;
    searchMode: boolean;
    selectedItemId: string;
};

export function AccordionItems({ item, level, onNavigate, paged, searchMode, selectedItemId }: ItemsProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                position: "relative",
                width: "100%"
            }}>
            <Box
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <PagedItems
                    enabled={paged}
                    items={item.options!.items!}>
                    {item =>
                        <Item
                            item={item}
                            key={item.id}
                            level={level + 1}
                            paged={paged}
                            searchMode={searchMode}
                            selectedItemId={selectedItemId}
                            variant="accordion"
                            onNavigate={onNavigate}/>}
                </PagedItems>
            </Box>
            <Box
                sx={{
                    backgroundColor: theme.palette.divider,
                    height: "100%",
                    left: theme.spacing(level * 2 + 2),
                    position: "absolute",
                    width: "2px"
                }}/>
        </Stack>);
}

export function TreeItems({ item, level, onNavigate, paged, searchMode, selectedItemId }: ItemsProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                position: "relative",
                width: "100%"
            }}>
            <Box
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <PagedItems
                    enabled={paged}
                    items={item.options!.items!}
                    loaderSx={{ marginLeft: theme.px(getTreeTitleLevelPadding(level + 2)) }}>
                    {item =>
                        <Item
                            item={item}
                            key={item.id}
                            level={level + 1}
                            paged={paged}
                            searchMode={searchMode}
                            selectedItemId={selectedItemId}
                            variant="tree"
                            onNavigate={onNavigate}/>}
                </PagedItems>
            </Box>
            {item.id === selectedItemId && (
                <Box
                    sx={{
                        backgroundColor: theme.palette.divider,
                        height: "100%",
                        left: theme.px(8 + getTreeTitleLevelPadding(level)),
                        position: "absolute",
                        width: "2px"
                    }}/>)}
        </Stack>);
}