import { Action0, ActionMenuItem, AddIcon, DeleteIcon, Dialog, EditIcon, FormLayout, Menu, useChangeEffect, useLocalization } from "@infrastructure";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Stack, Typography } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import _ from "lodash";
import React, { Fragment, useRef, useState } from "react";
import { Contract, TextInput, useSetUdmObjectTableContext, useTheme } from "../../../../../../..";
import { Badge } from "../..";

type ActionsProps = {
    closed: boolean;
    id: string;
    name: string;
    onAddGroup: () => void;
    onChange: (operator: Contract.UdmQueryRuleGroupOperator) => void;
    onClosedChanged: (closed: boolean) => void;
    onNameChanged: (groupName: string) => void;
    onRuleGroupCleared: Action0;
    operator: Contract.UdmQueryRuleGroupOperator;
};

export function Actions({ closed, id, name, onAddGroup, onChange, onClosedChanged, onNameChanged, onRuleGroupCleared, operator }: ActionsProps) {
    const setUdmObjectTableContext = useSetUdmObjectTableContext();
    useChangeEffect(
        () => {
            setUdmObjectTableContext(
                context => ({
                    ...context,
                    closedGroupIds:
                        closed
                            ? _.concat(context.closedGroupIds, id)
                            : _.without(context.closedGroupIds, id)
                }));
        },
        [closed]);

    const lineRef = useRef<HTMLDivElement>(null);
    const [enableToggle, setEnableToggle] = useState(true);
    const [enableToggleInitialized, setEnableToggleInitialized] = useState(false);
    useResizeObserver(
        lineRef,
        () => {
            if (lineRef.current && !closed) {
                setEnableToggle(lineRef.current.clientHeight > 70);
            }
            setEnableToggleInitialized(true);
        });

    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.ruleGroup.actions",
            () => ({
                delete: "Delete",
                edit: {
                    save: "Save",
                    title: "Edit name"
                },
                filter: {
                    [Contract.UdmQueryRuleGroupOperator.And]: "And",
                    [Contract.UdmQueryRuleGroupOperator.Or]: "Or"
                },
                name: "Group: {{groupId}}",
                rule: "Rule",
                tooltip: "Toggle operator"
            }));


    const [editNameOpen, setEditNameOpen] = useState(false);
    const [editableName, setEditableName] = useState(name);
    const theme = useTheme();
    return (
        <Fragment>
            {editNameOpen &&
                <Dialog
                    size="small"
                    variant="editor"
                    onClose={() => setEditNameOpen(false)}>
                    <FormLayout
                        footerOptions={{
                            contentElement:
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="flex-end"
                                    spacing={1}>
                                    <Button
                                        disabled={editableName === name}
                                        onClick={
                                            () => {
                                                onNameChanged(editableName);
                                                setEditNameOpen(false);
                                            }}>
                                        {localization.edit.save()}
                                    </Button>
                                </Stack>
                        }}
                        titleOptions={{ text: localization.edit.title() }}>
                        <TextInput
                            disabled={false}
                            placeholder={localization.edit.title()}
                            readOnly={false}
                            text={editableName}
                            onChange={setEditableName}/>
                    </FormLayout>
                </Dialog>}
            <Box
                ref={lineRef}
                sx={{
                    borderBottom: theme.border.primary,
                    borderLeft: theme.border.primary,
                    bottom: 13,
                    left: "50%",
                    position: "absolute",
                    top: 8,
                    width: theme.spacing(2.5)
                }}/>
            {enableToggle &&
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={0.5}>
                    <Button
                        sx={{
                            "&:hover": {
                                background: theme.palette.action.hover,
                                border: theme.border.hoverFocus
                            },
                            background: theme.palette.background.paper,
                            border: theme.border.primary,
                            color: theme.palette.text.secondary,
                            fontSize: "18px",
                            left: "50%",
                            minHeight: "unset",
                            minWidth: "unset",
                            padding: 0,
                            position: "relative",
                            top: 14,
                            transform: "translate(-50%, -50%)",
                            ...(closed && {
                                left: 1,
                                paddingRight: theme.spacing(1),
                                top: 0,
                                transform: "unset"
                            })
                        }}
                        variant="outlined"
                        onClick={() => onClosedChanged(!closed)}>
                        {closed
                            ? <Stack
                                alignItems="center"
                                direction="row"
                                sx={{ width: "max-content" }}>
                                <KeyboardArrowRightIcon sx={{ color: theme.palette.text.primary }}/>
                                <Stack
                                    alignItems="center"
                                    sx={{
                                        cursor: "pointer",
                                        flex: 1,
                                        height: theme.spacing(3.5),
                                        justifyContent: "center"
                                    }}
                                    onClick={() => onClosedChanged(false)}>
                                    <Typography>
                                        {localization.name({ groupId: name })}
                                    </Typography>
                                </Stack>
                            </Stack>
                            : <KeyboardArrowDownIcon sx={{ color: theme.palette.text.primary }}/>}
                    </Button>
                    {closed &&
                        <Box>
                            <Menu
                                iconSx={{ fontSize: "16px" }}
                                itemsOrGetItems={[
                                    new ActionMenuItem(
                                        () => setEditNameOpen(true),
                                        localization.edit.title(),
                                        {
                                            icon: <EditIcon/>
                                        }),
                                    new ActionMenuItem(
                                        () => onRuleGroupCleared(),
                                        localization.delete(),
                                        {
                                            icon: <DeleteIcon/>
                                        })
                                ]}
                                sx={{
                                    "&:hover": {
                                        background: theme.palette.action.hover
                                    },
                                    borderRadius: theme.spacing(0.75),
                                    padding: theme.spacing(0.25)
                                }}
                                variant="bottomLeft"/>
                        </Box>}
                </Stack>}
            {!closed &&
                <Box
                    sx={{
                        visibility:
                            enableToggleInitialized
                                ? "visible"
                                : "hidden"
                    }}>
                    <Badge
                        sx={{
                            background: theme.palette.background.paper,
                            border: theme.border.primary,
                            color: theme.palette.text.secondary,
                            justifyContent: "center",
                            left: "50%",
                            position: "absolute",
                            top: 0,
                            transform: "translate(-50%, 0%)",
                            width: 42,
                            zIndex: 2,
                            ...(enableToggle && {
                                top: "50%",
                                transform: "translate(-50%, -50%)"
                            })
                        }}
                        text={localization.filter[operator]()}
                        tooltip={localization.tooltip()}
                        onClick={
                            () =>
                                onChange(
                                    operator === Contract.UdmQueryRuleGroupOperator.And
                                        ? Contract.UdmQueryRuleGroupOperator.Or
                                        : Contract.UdmQueryRuleGroupOperator.And)}/>
                    <Button
                        sx={{
                            "&:hover": {
                                background: theme.palette.action.hover,
                                border: theme.border.hoverFocus
                            },
                            background: theme.palette.background.paper,
                            border: theme.border.primary,
                            bottom: 4,
                            color: theme.palette.text.secondary,
                            fontSize: "18px",
                            left: "50%",
                            minHeight: "unset",
                            minWidth: "unset",
                            padding: 0,
                            position: "absolute",
                            transform: "translate(-50%, 0%)"
                        }}
                        variant="outlined"
                        onClick={() => onAddGroup()}>
                        <AddIcon sx={{ color: theme.palette.text.primary }}/>
                    </Button>
                </Box>}
        </Fragment>);
}