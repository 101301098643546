﻿import { useMemo } from "react";
import { RiskDefinitionContextItem } from "../../../utilities";
import { useRiskPolicyConfigurationRotationTimeFrameStep } from "./useRiskPolicyConfigurationRotationTimeFrameStep";

export function useGetRiskPolicyConfigurationRotationTimeFrameContextItem() {
    return useMemo(
        () => useRiskPolicyConfigurationRotationTimeFrameContextItem,
        []);
}

function useRiskPolicyConfigurationRotationTimeFrameContextItem(rotationTimeFrame: string) {
    return new RiskDefinitionContextItem(useRiskPolicyConfigurationRotationTimeFrameStep(rotationTimeFrame));
}