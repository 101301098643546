import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpComputeVpcDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const vpc = entityModel.entity as Contract.GcpComputeVpc;
    const tenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => undefined,
                [Contract.GcpConsolePage.VpcFirewallRules]: () => GcpConsoleUrlBuilder.GetComputeVpcFirewallRulesUrl,
                [Contract.GcpConsolePage.VpcSubnets]: () => GcpConsoleUrlBuilder.GetComputeVpcSubnetsUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            vpc.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}