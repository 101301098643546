﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../common";
import { DefinitionData } from "../../../Table";

export function useCommonKubernetesNamespaceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonKubernetesNamespaceDefinition",
            () => ({
                columns: {
                    resources: "Resources"
                }
            }));

    return {
        columns: {
            resourcesColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IKubernetesNamespaceModel>(
                            Contract.TypeNames.IKubernetesResource,
                            item => item.resourceIds,
                            localization.columns.resources())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.KubernetesNamespaceResources)}
                                    placeholder={localization.columns.resources()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesNamespaceResources}
                    key={Contract.EntityModelProperty.KubernetesNamespaceResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IKubernetesNamespaceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.resourceIds}
                                entityTypeName={Contract.TypeNames.IKubernetesResource}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.resources()}/>
        }
    };
}