﻿import { Link, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetGcpContainerClusterNodePoolsRiskContext, useGetGcpContainerClusterRiskContext } from "../contexts";

export function useGcpContainerClusterDefaultServiceAccountRiskDefinition(riskModel: Contract.RiskModel) {
    const clusterDefaultServiceAccountRiskModel = riskModel as Contract.GcpContainerClusterDefaultServiceAccountRiskModel;
    const clusterModel = entityModelStore.useGet(clusterDefaultServiceAccountRiskModel.risk.entityId) as Contract.GcpContainerClusterModel;
    const nodePoolModel = entityModelStore.useGet(_.first(clusterDefaultServiceAccountRiskModel.risk.nodePoolIds)) as Contract.GcpContainerClusterNodePoolModel;
    const cluster = clusterModel.entity as Contract.GcpContainerCluster;

    const getGcpContainerClusterRiskContext = useGetGcpContainerClusterRiskContext();
    const getGcpContainerClusterNodePoolsRiskContext = useGetGcpContainerClusterNodePoolsRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpContainerClusterDefaultServiceAccountRiskDefinition",
            () => ({
                contextItems: {
                    nodePoolsAndServiceAccounts: "The {{translatedClusterTypeName}} has {{nodePools}} using the {{serviceAccount}} and where GKE metadata server is disabled"
                },
                description: "{{cluster}} has {{nodePools}} using the default service account",
                sections: {
                    resolution: {
                        step1: {
                            link: "Use least privilege Google service accounts",
                            text: {
                                cluster: "Configure Workload Identity for the cluster or replace the default service account for each node pool that is using it.\nFor more information see: {{gcpContainerLeastPrivilegeServiceAccountsLink}}",
                                nodePools: "Enable the GKE metadata server for all relevant cluster node pools"
                            }
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            nodePools:
                <InlineEntities
                    entityIdsOrModels={clusterDefaultServiceAccountRiskModel.risk.nodePoolIds}
                    entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                    variant="itemCountAndType"/>
        }),
        () => [
            <Typography
                key="leastPrivilegeDocumentationLink"
                sx={{ whiteSpace: "pre-wrap" }}>
                {(cluster.workloadIdentity
                    ? localization.sections.resolution.step1.text.nodePools
                    : localization.sections.resolution.step1.text.cluster)({
                    gcpContainerLeastPrivilegeServiceAccountsLink:
                        <Link
                            urlOrGetUrl={clusterDefaultServiceAccountRiskModel.leastPrivilegeDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step1.link()}
                        </Link>
                })}
            </Typography>
        ],
        clusterDefaultServiceAccountRiskModel,
        () => {
            const clusterRiskContext = getGcpContainerClusterRiskContext(clusterModel);
            const nodePoolsRiskContext = getGcpContainerClusterNodePoolsRiskContext(clusterDefaultServiceAccountRiskModel.risk.nodePoolIds);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.mode,
                clusterRiskContext.vpc,
                new RiskDefinitionContextItem(
                    localization.contextItems.nodePoolsAndServiceAccounts({
                        nodePools:
                            <InlineEntities
                                entityIdsOrModels={clusterDefaultServiceAccountRiskModel.risk.nodePoolIds}
                                entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                                variant="itemCountAndType"/>,
                        serviceAccount:
                            <Entity
                                entityIdOrModel={nodePoolModel.serviceAccountIdReference!}
                                variant="text"/>,
                        translatedClusterTypeName:
                            entityTypeNameTranslator(
                                cluster.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                nodePoolsRiskContext.sensitive,
                ...nodePoolsRiskContext.inlineAuthorizationScopeRiskContext,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        });
}