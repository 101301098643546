import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import { GcpContext, useSetGcpContext } from "../../..";
import { Contract, scopeSystemEntityModelStore, TenantController, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const setGcpContext = useSetGcpContext();
    const [deleteOrganizationExecuting, setDeleteOrganizationExecuting] = useState(false);
    const [deleteOrganizationError, setDeleteOrganizationError] = useState(false);

    async function deleteOrganization() {
        setDeleteOrganizationExecuting(true);
        setDeleteOrganizationError(false);

        try {
            const { deletedTenantModels } = await TenantController.deleteOrganization(new Contract.TenantControllerDeleteOrganizationRequest(item.configuration.id));
            await tenantModelStore.notify(deletedTenantModels);
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteOrganizationError(true);
        }

        setDeleteOrganizationExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete organization",
                        prompt: "Are you sure you want to delete the organization {{organizationName}}?\n(this will also delete all projects)",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteOrganizationExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteOrganizationError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {!item.configuration.systemDeleted && (
                <Menu
                    itemsOrGetItems={[
                        new ActionMenuItem(
                            () => setGcpContext(new GcpContext(item.configuration as Contract.GcpOrganizationConfiguration)),
                            localization.actions.edit(),
                            {
                                disabled: deleteOrganizationExecuting,
                                icon: <EditIcon/>
                            }),
                        new ActionMenuItem(
                            () => deleteOrganization(),
                            localization.actions.delete.title(),
                            {
                                confirmOptions: {
                                    message: localization.actions.delete.prompt({ organizationName: (item.configuration as Contract.GcpOrganizationConfiguration).name })
                                },
                                disabled: deleteOrganizationExecuting,
                                icon: <DeleteIcon/>
                            })
                    ]}/>)}
        </Stack>);
}