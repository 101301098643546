import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsIamPrincipalDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsIamPrincipalDefinition",
            () => ({
                columns: {
                    policies: "Policies"
                }
            }));
    return {
        columns: {
            ...commonResourceDefinition.columns,
            policies:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsIamPrincipalModel>(
                            Contract.TypeNames.AwsIamPrincipalPolicy,
                            item =>
                                _.concat(
                                    (item.entity as Contract.AwsIamPrincipal).inlinePolicyIds,
                                    (item.entity as Contract.AwsIamPrincipal).managedPolicyIds),
                            localization.columns.policies())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsIamPrincipalPolicies)}
                                    placeholder={localization.columns.policies()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsIamPrincipalPolicies}
                    key={Contract.EntityModelProperty.AwsIamPrincipalPolicies}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={
                                    _((item.entity as Contract.AwsIamPrincipal).inlinePolicyIds).
                                        concat((item.entity as Contract.AwsIamPrincipal).managedPolicyIds).
                                        uniq().
                                        value()}
                                entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                                entityVariant="iconText"
                                inlineEntitiesVariant="itemPlusItemCount"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.policies()}/>
        }
    };
}