import { DirectedGraph, DirectedGraphModel, DirectedGraphModelEdge, DirectedGraphModelNode, Optional } from "@infrastructure";
import _ from "lodash";
import React, { MutableRefObject, useEffect, useMemo, useState } from "react";
import { Contract, entityModelStore, EntityNodeContent, getEntityNodeContentAnchorPoint, getEntityNodeContentSize } from "../../../..";
import { useNetworkContext } from "../..";
import { ToolbarActions, ToolbarFilterId } from "..";
import { DestinationGroupNodeContent, GatewayNodeContent, getDestinationGroupNodeContentAnchorPoint, getDestinationGroupNodeContentSize, getGatewayNodeContentAnchorPoint, getGatewayNodeContentSize, getSecurityPerimeterNodeContentAnchorPoint, getSecurityPerimeterNodeContentSize, getSubnetNodeContentAnchorPoint, getSubnetNodeContentSize, SecurityPerimeterNodeContent, SubnetNodeContent } from "./components";

type GraphProps = {
    entityId: string;
    networkGraph: Contract.NetworkGraph;
    toolbarActionsRef: MutableRefObject<Optional<ToolbarActions>>;
};

export function Graph({ entityId, networkGraph, toolbarActionsRef }: GraphProps) {
    entityModelStore.useGet(entityId);

    const { definition } = useNetworkContext();

    const [selectedNodeAndAnchorId, setSelectedNodeAndAnchorId] =
        useState<Optional<string | [string, string]>>(
            () => {
                const sourceGroup =
                    _.find(
                        networkGraph.sourceGroups,
                        sourceGroup => _.includes(sourceGroup.entityIds, entityId));
                if (!_.isNil(sourceGroup)) {
                    return sourceGroup.id;
                }

                const gateway =
                    _.find(
                        networkGraph.gateways,
                        gateway =>
                            gateway.gatewayResourceId === entityId ||
                            gateway.internetGatewayId === entityId);
                if (!_.isNil(gateway)) {
                    return gateway.id;
                }

                const securityPerimeter =
                    _.find(
                        networkGraph.securityPerimeters,
                        securityPerimeter => securityPerimeter.securityPerimeterResourceId === entityId);
                if (!_.isNil(securityPerimeter)) {
                    return securityPerimeter.id;
                }

                return _.find(
                    networkGraph.destinationGroups,
                    destinationGroup => _.includes(destinationGroup.entityIds, entityId))?.id;
            });

    const directedGraphModel =
        useMemo(
            () => {
                const destinationGroupScopeIdToDestinationGroupMap =
                    _(networkGraph.destinationGroups).
                        flatMap(
                            destinationGroup =>
                                _.map(
                                    destinationGroup.scopes,
                                    scope => ({
                                        destinationGroup,
                                        scope
                                    }))).
                        keyBy(({ scope }) => scope.id).
                        mapValues(({ destinationGroup }) => destinationGroup).
                        value();
                const directedGraphEdges =
                    _.map(
                        networkGraph.edges,
                        edge =>
                            _.isNil(destinationGroupScopeIdToDestinationGroupMap[edge.destinationNodeId])
                                ? new DirectedGraphModelEdge(
                                    edge.sourceNodeId,
                                    edge.destinationNodeId)
                                : new DirectedGraphModelEdge(
                                    edge.sourceNodeId,
                                    destinationGroupScopeIdToDestinationGroupMap[edge.destinationNodeId]!.id,
                                    {
                                        destinationAnchorId: edge.destinationNodeId
                                    }));

                const sourceGroupNodes =
                    _.map(
                        networkGraph.sourceGroups,
                        sourceGroup =>
                            _.isNil(sourceGroup.entityIds)
                                ? new DirectedGraphModelNode(
                                    sourceGroup.id,
                                    GraphColumnId.Sources,
                                    <SubnetNodeContent
                                        sourceGroup={sourceGroup}
                                        toolbarActionsRef={toolbarActionsRef}/>,
                                    getSubnetNodeContentSize(),
                                    edge => getSubnetNodeContentAnchorPoint(edge.getAnchorPlacement(sourceGroup.id)),
                                    {
                                        onClick: () => setSelectedNodeAndAnchorId(sourceGroup.id)
                                    })
                                : new DirectedGraphModelNode(
                                    sourceGroup.id,
                                    GraphColumnId.Sources,
                                    <EntityNodeContent
                                        entityIds={sourceGroup.entityIds}
                                        entityProfileMenuItem={sourceGroup.entityIds[0] !== entityId}
                                        variant="large"
                                        onFilter={
                                            type =>
                                                toolbarActionsRef.current!.filter(
                                                    ToolbarFilterId.SourceEntityIds,
                                                    type,
                                                    sourceGroup.entityIds!)}/>,
                                    getEntityNodeContentSize("bottom", "large"),
                                    edge =>
                                        getEntityNodeContentAnchorPoint(
                                            edge.getAnchorPlacement(sourceGroup.id),
                                            "bottom",
                                            "large"),
                                    {
                                        onClick: () => setSelectedNodeAndAnchorId(sourceGroup.id)
                                    }));

                const gatewayNodes =
                    _.map(
                        networkGraph.gateways,
                        gateway =>
                            new DirectedGraphModelNode(
                                gateway.id,
                                GraphColumnId.Gateways,
                                <GatewayNodeContent gateway={gateway}/>,
                                getGatewayNodeContentSize(gateway),
                                edge =>
                                    getGatewayNodeContentAnchorPoint(
                                        edge.getAnchorPlacement(gateway.id),
                                        gateway),
                                {
                                    onClick: () => setSelectedNodeAndAnchorId(gateway.id)
                                }));

                const securityPerimeterNodes =
                    _.map(
                        networkGraph.securityPerimeters,
                        securityPerimeter => {
                            const { graphColumnId, toolbarFilterId } = getSecurityPerimeterNodeData();
                            return new DirectedGraphModelNode(
                                securityPerimeter.id,
                                graphColumnId,
                                <SecurityPerimeterNodeContent
                                    securityPerimeterResourceId={securityPerimeter.securityPerimeterResourceId}
                                    toolbarActionsRef={toolbarActionsRef}
                                    toolbarFilterId={toolbarFilterId}/>,
                                getSecurityPerimeterNodeContentSize(),
                                edge => getSecurityPerimeterNodeContentAnchorPoint(edge.getAnchorPlacement(securityPerimeter.id)),
                                {
                                    onClick: () => setSelectedNodeAndAnchorId(securityPerimeter.id)
                                });

                            function getSecurityPerimeterNodeData() {
                                switch (securityPerimeter.type) {
                                    case Contract.NetworkGraphSecurityPerimeterType.FirewallPolicy:
                                    case Contract.NetworkGraphSecurityPerimeterType.Vpc:
                                        return {
                                            graphColumnId: GraphColumnId.FirewallPolicyAndVpcs,
                                            toolbarFilterId: ToolbarFilterId.FirewallPolicyAndVpcIds
                                        };
                                    case Contract.NetworkGraphSecurityPerimeterType.InterfaceSecurityGroup:
                                        return {
                                            graphColumnId: GraphColumnId.InterfaceSecurityGroups,
                                            toolbarFilterId: ToolbarFilterId.InterfaceSecurityGroupIds
                                        };
                                    case Contract.NetworkGraphSecurityPerimeterType.SubnetSecurityGroup:
                                        return {
                                            graphColumnId: GraphColumnId.SubnetSecurityGroups
                                        };
                                }
                            }
                        });

                const destinationGroupNodes =
                    _.map(
                        networkGraph.destinationGroups,
                        destinationGroup =>
                            new DirectedGraphModelNode(
                                destinationGroup.id,
                                GraphColumnId.DestinationGroups,
                                <DestinationGroupNodeContent
                                    destinationGroup={destinationGroup}
                                    toolbarActionsRef={toolbarActionsRef}
                                    onScopeClick={scopeId => setSelectedNodeAndAnchorId([destinationGroup.id, scopeId])}/>,
                                getDestinationGroupNodeContentSize(destinationGroup),
                                edge => getDestinationGroupNodeContentAnchorPoint(destinationGroup, edge),
                                {
                                    onClick: () => setSelectedNodeAndAnchorId(destinationGroup.id)
                                }));

                return new DirectedGraphModel(
                    directedGraphEdges,
                    [
                        ...sourceGroupNodes,
                        ...gatewayNodes,
                        ...securityPerimeterNodes,
                        ...destinationGroupNodes
                    ],
                    definition.directedGraphColumns);
            },
            [networkGraph]);

    const [, refresh] = useState({});
    useEffect(
        () => {
            if (_.isString(selectedNodeAndAnchorId)) {
                directedGraphModel.highlight(selectedNodeAndAnchorId);
            } else if (!_.isNil(selectedNodeAndAnchorId)) {
                const [nodeId, nodeAnchorId] = selectedNodeAndAnchorId;
                directedGraphModel.highlight(
                    nodeId,
                    nodeAnchorId);
            }

            refresh({});
        },
        [selectedNodeAndAnchorId]);

    return <DirectedGraph model={directedGraphModel}/>;
}

export enum GraphColumnId {
    DestinationGroups = "destinationGroups",
    FirewallPolicyAndVpcs = "firewallPolicyAndVpcs",
    Gateways = "gateways",
    InterfaceSecurityGroups = "interfaceSecurityGroups",
    Sources = "sources",
    SubnetSecurityGroups = "subnetSecurityGroups"
}