import { AutocompleteItems, CheckboxField, DropdownIcon, Message, Optional, StringHelper, UnexpectedError, useActions, useAsyncEffect, useExecuteOperation, useInputValidation, useLocalization } from "@infrastructure";
import { Autocomplete, Box, CircularProgress, FormHelperText, List, ListItemButton, Stack, TextField, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Ref, useEffect, useMemo, useRef, useState } from "react";
import { TicketingServiceMandatoryFieldSelector } from ".";
import { Contract, RiskController, RiskType, TicketingServiceController, useTheme, useTicketMultipleRisksDescriptionTranslator } from "..";

export type ServiceNowIncidentSelectorData = Omit<Contract.ServiceNowDeliveryInstance, "integrationId" | "typeName" | "typeNames">;

export type ServiceNowIncidentSelectorProps = {
    actionsRef?: Ref<Optional<ServiceNowSelectorActions>>;
    disabled?: boolean;
    initialData?: Contract.ServiceNowIncidentCreationData;
    initialInstanceNotValid?: boolean;
    instanceId?: string;
    instanceModels: Contract.ScopeSystemEntityModel[];
    instanceReadOnly?: boolean;
    multipleRisks?: boolean;
    onDataChanged: (data: Optional<ServiceNowIncidentSelectorData>, dirty: boolean, selectorInfoTranslation?: string) => void;
    readOnly?: boolean;
    riskIds: string[];
    riskModel?: Contract.RiskModel;
    riskType?: RiskType;
};

export type ServiceNowSelectorActions = {
    reset: () => void;
};


export function ServiceNowIncidentSelector({ actionsRef, disabled = false, initialData, initialInstanceNotValid, instanceId, instanceModels, instanceReadOnly = false, multipleRisks = false, onDataChanged, readOnly = false, riskIds, riskModel, riskType }: ServiceNowIncidentSelectorProps) {
    async function GetRiskIdToDataMap() {
        if (multipleRisks || !_.isNil(riskModel)) {
            switch (riskType) {
                case RiskType.Cloud:
                    return await RiskController.getRiskIdToDataMap(new Contract.RiskControllerGetCloudRiskIdToDataMapRequest(riskIds));
                case RiskType.Code:
                    return await RiskController.getRiskIdToDataMap(new Contract.RiskControllerGetCodeRiskIdToDataMapRequest(riskIds));
                default:
                    throw new UnexpectedError("riskType", riskType);
            }
        }
        return { riskIdToDataMap: undefined };
    }

    const [{ defaultImpact, defaultUrgency, riskIdToDataMap }] =
        useExecuteOperation(
            [ServiceNowIncidentSelector, riskModel?.risk.id],
            async () => {
                const { riskIdToDataMap } = await GetRiskIdToDataMap();
                if (_.isNil(riskModel)) {
                    return { riskIdToDataMap };
                }
                const { impact, urgency } = await TicketingServiceController.getServiceNowSeverityImpactAndUrgency(new Contract.TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyRequest(riskModel.risk.severity));
                return {
                    defaultImpact: impact,
                    defaultUrgency: urgency,
                    riskIdToDataMap
                };
            });
    const ticketMultipleRisksDescriptionTranslator = useTicketMultipleRisksDescriptionTranslator();

    instanceModels =
        useMemo(
            () =>
                _(instanceModels).
                    filter(instanceModel => _.isNil((instanceModel.state as Contract.ServiceNowInstanceState)?.issue)).
                    orderBy(instanceModel => StringHelper.getSortValue((instanceModel.configuration as Contract.ServiceNowInstanceConfiguration).name)).
                    value(),
            []);

    const localization =
        useLocalization(
            "common.serviceNowIncidentSelector",
            () => ({
                actions: {
                    retry: "Retry"
                },
                bulk: {
                    fieldsInfo: {
                        multipleRisks: "A zip file will be attached with the description of each finding and any relevant attachments.",
                        multipleRisksOverflow: "The description is too long for one ServiceNow incident. The full description, including remediation steps will be attached as a file named \"fullDescription.txt\", along with any relevant attachments.",
                        notMultipleRisks: "Each ServiceNow incident will contain the summary and description of each finding, including a link to the finding page and attached files if relevant."

                    }
                },
                fields: {
                    additionalMandatoryFields: {
                        autocomplete: {
                            empty: "No matching value"
                        },
                        error: "{{fieldName}} cannot be empty"
                    },
                    description: {
                        error: "Description cannot be empty",
                        multipleRisksOverflow: "{{descriptions}}\n-----------------------------\nThe description is too long to be displayed fully. The full description, including remediation steps, is attached as a file named \"FullDescription.txt\".",
                        title: "Description"
                    },
                    fileNames: {
                        description: "Select whether to attach the new suggested policy to the ServiceNow incident",
                        title: "Attachments (optional)"
                    },
                    group: {
                        error: "Group assignment cannot be empty",
                        title: "Group assignment"
                    },
                    impact: {
                        empty: "No matching value",
                        title: "Impact",
                        [Contract.TypeNames.ServiceNowIncidentImpact]: {
                            [Contract.ServiceNowIncidentImpact.High]: "1 - High",
                            [Contract.ServiceNowIncidentImpact.Low]: "3 - Low",
                            [Contract.ServiceNowIncidentImpact.Medium]: "2 - Medium"
                        }
                    },
                    instance: {
                        empty: "No matching instance",
                        error: "Failed to get instance",
                        title: "Instance"
                    },
                    summary: {
                        error: "Summary cannot be empty",
                        multipleRisks: "{{riskCount}} findings",
                        title: "Summary"
                    },
                    urgency: {
                        empty: "No matching value",
                        title: "Urgency",
                        [Contract.TypeNames.ServiceNowIncidentUrgency]: {
                            [Contract.ServiceNowIncidentUrgency.High]: "1 - High",
                            [Contract.ServiceNowIncidentUrgency.Low]: "3 - Low",
                            [Contract.ServiceNowIncidentUrgency.Medium]: "2 - Medium"
                        }
                    },
                    user: {
                        empty: "No matching user",
                        title: "Assignee"
                    }
                }
            }));

    const dirtyRef = useRef(false);
    const updateDirtyRef = useRef(false);
    const [additionalMandatoryFieldNameToValuesMap, setAdditionalMandatoryFieldNameToValuesMap] =
        useState<_.Dictionary<string[]>>(
            _.isNil(initialData?.additionalMandatoryFieldNameToValueMap)
                ? {}
                : _(initialData!.additionalMandatoryFieldNameToValueMap).
                    pickBy(additionalMandatoryFieldValue => !_.isEmpty(additionalMandatoryFieldValue)).
                    mapValues(additionalMandatoryFieldValue => [additionalMandatoryFieldValue!]).
                    value());
    const [additionalMandatoryFields, setAdditionalMandatoryFields] = useState<Contract.ServiceNowField[]>([]);
    const [additionalMandatoryFieldNameToErrorMessageMap, setAdditionalMandatoryFieldNameToErrorMessageMap] =
        useState<_.Dictionary<string | undefined>>(
            () => {
                const additionalMandatoryFieldNameToDisplayNameMap =
                    _(additionalMandatoryFields).
                        keyBy(additionalMandatoryField => additionalMandatoryField.name).
                        mapValues(additionalMandatoryField => additionalMandatoryField.displayName).
                        value();
                return _.mapValues(
                    additionalMandatoryFieldNameToValuesMap,
                    (values, additionalMandatoryFieldName) =>
                        _.isEmpty(values)
                            ? localization.fields.additionalMandatoryFields.error({ fieldName: additionalMandatoryFieldNameToDisplayNameMap[additionalMandatoryFieldName] ?? additionalMandatoryFieldName })
                            : undefined);
            });
    const [descriptionTranslation, selectorInfoTranslation, summaryTranslation] =
        useMemo(
            () => {
                if (multipleRisks) {
                    let descriptionOverFlow = false;
                    const descriptions =
                        _(riskIdToDataMap).
                            keys().
                            sort().
                            map((riskId, index) => ({ index, riskId })).
                            reduce(
                                (description, { index, riskId }) => {
                                    if (descriptionOverFlow) {
                                        return description;
                                    }
                                    const riskDescriptionTranslation =
                                        ticketMultipleRisksDescriptionTranslator(
                                            riskIdToDataMap![riskId].extendedDescription,
                                            index + 1,
                                            riskIdToDataMap![riskId].extendedTitle,
                                            riskIdToDataMap![riskId].severity);
                                    const newDescription = `${description}\n\n${riskDescriptionTranslation}`.trim();
                                    if (newDescription.length > 30000) {
                                        descriptionOverFlow = true;
                                        return description;
                                    } else {
                                        return newDescription;
                                    }
                                },
                                "");
                    return [
                        descriptionOverFlow
                            ? localization.fields.description.multipleRisksOverflow({ descriptions })
                            : descriptions,
                        descriptionOverFlow
                            ? localization.bulk.fieldsInfo.multipleRisksOverflow()
                            : localization.bulk.fieldsInfo.multipleRisks(),
                        localization.fields.summary.multipleRisks({ riskCount: riskIds.length })
                    ];
                }
                return !_.isNil(riskModel)
                    ? [
                        riskIdToDataMap![riskModel.id].extendedDescription,
                        undefined,
                        riskIdToDataMap![riskModel.id].extendedTitle
                    ]
                    : [undefined,
                        localization.bulk.fieldsInfo.notMultipleRisks(),
                        undefined];
            },
            []);
    const [description, setDescription] = useState(initialData?.description ?? descriptionTranslation);
    const [fileNames, setFileNames] =
        useState(
            _.isNil(initialData)
                ? undefined
                : initialData?.fileNames ?? []);
    const [getInstanceExecuting, setGetInstanceExecuting] = useState(false);
    const [getInstanceError, setGetInstanceError] = useState(false);
    const [group, setGroup] = useState<Contract.ServiceNowGroup>();
    const [groupRawIdToUserRawIdsMap, setGroupRawIdToUserRawIdsMap] = useState<Dictionary<string[]>>({});
    const [groups, setGroups] = useState<Contract.ServiceNowGroup[]>([]);
    const [impact, setImpact] = useState(initialData?.impact ?? defaultImpact);
    const [instanceModel, setInstanceModel] =
        useState(
            () =>
                initialInstanceNotValid
                    ? undefined
                    : instanceModels.length === 1
                        ? instanceModels[0]
                        : _.find(
                            instanceModels,
                            instanceModel =>
                                instanceModel.configuration.id === instanceId));
    const [summary, setSummary] = useState(initialData?.summary ?? summaryTranslation);
    const [urgency, setUrgency] = useState(initialData?.urgency ?? defaultUrgency);
    const [user, setUser] = useState<Contract.ServiceNowUser>();
    const [users, setUsers] = useState<Contract.ServiceNowUser[]>([]);

    const userRawIdToUserMap =
        useMemo(
            () =>
                _.keyBy(
                    users,
                    user => user.rawId),
            [users]);

    const [descriptionValidationController, descriptionValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(riskModel)) {
                    return undefined;
                }

                if ((instanceModel?.state as Optional<Contract.ServiceNowInstanceState>)?.descriptionMandatory === true &&
                    _.isEmpty(description?.trim())) {
                    return localization.fields.description.error();
                }

                return undefined;
            },
            [description, instanceModel]);

    const [groupValidationController, groupValidationMessage] =
        useInputValidation(
            () =>
                (instanceModel?.state as Optional<Contract.ServiceNowInstanceState>)?.groupMandatory === true &&
                _.isNil(group)
                    ? localization.fields.group.error()
                    : undefined,
            [group]);

    const [summaryValidationController, summaryValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(riskModel)) {
                    return undefined;
                }

                const validationSummary = summary!.trim();
                if (_.isEmpty(validationSummary)) {
                    return localization.fields.summary.error();
                }

                return undefined;
            },
            [summary]);

    const actions =
        useActions<ServiceNowSelectorActions>(
            actionsRef,
            {
                reset() {
                    setAdditionalMandatoryFieldNameToValuesMap({});
                    setAdditionalMandatoryFieldNameToErrorMessageMap({});
                    setDescription(descriptionTranslation);
                    setGroup(undefined);
                    setImpact(defaultImpact);
                    setSummary(summaryTranslation);
                    setUrgency(defaultUrgency);
                    setUser(undefined);

                    descriptionValidationController.clear();
                    groupValidationController.clear();
                    summaryValidationController.clear();

                    dirtyRef.current = false;
                    updateDirtyRef.current = false;
                }
            });

    useEffect(
        () => {
            if (updateDirtyRef.current) {
                dirtyRef.current = true;
            }

            updateDirtyRef.current = true;
            const valid =
                !_.isNil(instanceModel) &&
                descriptionValidationController.isValid() &&
                groupValidationController.isValid() &&
                summaryValidationController.isValid() &&
                _.size(additionalMandatoryFieldNameToValuesMap) === _.size(additionalMandatoryFields) &&
                !_.some(
                    additionalMandatoryFieldNameToErrorMessageMap,
                    additionalMandatoryFieldErrorMessage => !_.isNil(additionalMandatoryFieldErrorMessage));
            if (valid) {
                onDataChanged(
                    {
                        incidentCreationData:
                            new Contract.ServiceNowIncidentCreationData(
                                _.mapValues(
                                    additionalMandatoryFieldNameToValuesMap,
                                    additionalMandatoryFieldValues => additionalMandatoryFieldValues[0]),
                                description,
                                fileNames ?? [],
                                group,
                                impact,
                                summary,
                                urgency,
                                user),
                        instanceId: instanceModel!.configuration.id
                    },
                    dirtyRef.current,
                    selectorInfoTranslation);
            } else {
                onDataChanged(undefined, dirtyRef.current, selectorInfoTranslation);
            }
        },
        [additionalMandatoryFieldNameToErrorMessageMap, additionalMandatoryFieldNameToValuesMap, additionalMandatoryFields, description, fileNames, group, impact, instanceModel, summary, urgency, user]);

    const firstInstanceModelEffectRef = useRef(true);
    useAsyncEffect(
        async () => {
            if (_.isNil(instanceModel)) {
                return;
            }

            if (!firstInstanceModelEffectRef.current) {
                actions.reset();
                setAdditionalMandatoryFields([]);
            }

            setGetInstanceExecuting(true);
            setGetInstanceError(false);
            setGroupRawIdToUserRawIdsMap({});
            setGroups([]);
            setUsers([]);
            try {
                const { additionalMandatoryFields, groupRawIdToUserRawIdsMap, groups, users } = await TicketingServiceController.getServiceNowInstance(new Contract.TicketingServiceControllerGetServiceNowInstanceRequest(instanceModel.configuration.id));
                setAdditionalMandatoryFields(additionalMandatoryFields);
                setGroupRawIdToUserRawIdsMap(groupRawIdToUserRawIdsMap);
                setGroups(
                    _.orderBy(
                        groups,
                        group => StringHelper.getSortValue(group.name)));
                setUsers(
                    _.orderBy(
                        users,
                        user => StringHelper.getSortValue(user.displayName)));

                if (firstInstanceModelEffectRef.current &&
                    (instanceModel?.state as Optional<Contract.ServiceNowInstanceState>)?.groupMandatory === true &&
                    !_.isNil(initialData?.group)) {
                    setGroup(
                        _.find(
                            groups,
                            group => group.rawId === initialData!.group!.rawId));
                }
                if (firstInstanceModelEffectRef.current &&
                    !_.isNil(initialData?.user)) {
                    setUser(
                        _.find(
                            users,
                            user => user.rawId === initialData!.user!.rawId));
                    updateDirtyRef.current = false;
                }
            } catch {
                setGetInstanceError(true);
            }

            setGetInstanceExecuting(false);

            firstInstanceModelEffectRef.current = false;
        },
        [instanceModel]);

    const [additionalMandatorySelectionFields, additionalMandatorySelectionFieldNameToOrderedOptionsMap, additionalMandatoryStringFields] =
        useMemo(
            () => {
                const additionalMandatorySelectionFields =
                    _(additionalMandatoryFields).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.ServiceNowSelectionField).
                        as<Contract.ServiceNowSelectionField>().
                        value();
                const additionalMandatorySelectionFieldNameToOrderedOptionsMap =
                    _(additionalMandatorySelectionFields).
                        keyBy(additionalMandatorySelectionField => additionalMandatorySelectionField.name).
                        mapValues(
                            additionalMandatorySelectionField =>
                                _(additionalMandatorySelectionField.optionValueToDisplayNameMap).
                                    keys().
                                    orderBy(optionValue => StringHelper.getSortValue(optionValue)).
                                    value()).
                        value();
                const additionalMandatoryStringFields =
                    _(additionalMandatoryFields).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.ServiceNowStringField).
                        as<Contract.ServiceNowStringField>().
                        value();

                return [additionalMandatorySelectionFields, additionalMandatorySelectionFieldNameToOrderedOptionsMap, additionalMandatoryStringFields];
            },
            [additionalMandatoryFields]);

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <AutocompleteItems
                disableClearable={true}
                disabled={
                    disabled ||
                    getInstanceExecuting ||
                    instanceReadOnly ||
                    readOnly}
                disablePortal={true}
                fullWidth={true}
                getOptionLabel={instanceModel => (instanceModel.configuration as Contract.ServiceNowInstanceConfiguration).name}
                noOptionsText={localization.fields.instance.empty()}
                options={instanceModels}
                popupIcon={
                    <DropdownIcon
                        sx={{
                            color:
                                disabled ||
                                getInstanceExecuting ||
                                instanceReadOnly ||
                                readOnly
                                    ? theme.palette.text.disabled
                                    : theme.palette.text.secondary
                        }}/>}
                renderInput={
                    params => (
                        <TextField
                            {...params}
                            label={localization.fields.instance.title()}
                            variant="outlined"/>)}
                value={instanceModel}
                onChange={(event, instanceModel) => setInstanceModel(instanceModel)}>
                {instanceModel =>
                    <Stack>
                        <Typography>
                            {(instanceModel.configuration as Contract.ServiceNowInstanceConfiguration).name}
                        </Typography>
                        <Typography variant="subtitle1">
                            {(instanceModel.configuration as Contract.ServiceNowInstanceConfiguration).url}
                        </Typography>
                    </Stack>}
            </AutocompleteItems>
            {(instanceModel?.state as Optional<Contract.ServiceNowInstanceState>)?.groupMandatory === true &&
                <Box>
                    <Autocomplete
                        disabled={
                            disabled ||
                            getInstanceExecuting ||
                            readOnly}
                        disablePortal={true}
                        fullWidth={true}
                        getOptionLabel={group => group.name}
                        options={groups}
                        popupIcon={
                            getInstanceExecuting
                                ? <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>
                                : <DropdownIcon
                                    sx={{
                                        color:
                                            disabled ||
                                            getInstanceExecuting ||
                                            readOnly
                                                ? theme.palette.text.disabled
                                                : theme.palette.text.secondary
                                    }}/>}
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    label={localization.fields.group.title()}
                                    variant="outlined"/>)}
                        value={group ?? null}
                        onChange={
                            (event, group) => {
                                setGroup(group ?? undefined);
                                setUser(undefined);
                            }}/>
                    {!_.isNil(groupValidationMessage) &&
                        <FormHelperText error={true}>{groupValidationMessage}</FormHelperText>}
                </Box>}
            <Autocomplete
                disabled={
                    disabled ||
                    getInstanceExecuting ||
                    readOnly}
                disablePortal={true}
                fullWidth={true}
                getOptionLabel={user => user.displayName}
                noOptionsText={localization.fields.user.empty()}
                options={
                    (instanceModel?.state as Optional<Contract.ServiceNowInstanceState>)?.groupMandatory === true
                        ? _.map(
                            _.isNil(group)
                                ? []
                                : groupRawIdToUserRawIdsMap[group.rawId],
                            userRawId => userRawIdToUserMap[userRawId])
                        : users}
                popupIcon={
                    getInstanceExecuting
                        ? <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>
                        : <DropdownIcon
                            sx={{
                                color:
                                    disabled ||
                                    getInstanceExecuting ||
                                    readOnly
                                        ? theme.palette.text.disabled
                                        : theme.palette.text.secondary
                            }}/>}
                renderInput={
                    params => (
                        <TextField
                            {...params}
                            label={localization.fields.user.title()}
                            variant="outlined"/>)}
                value={user ?? null}
                onChange={(event, user) => setUser(user ?? undefined)}/>
            {getInstanceError && (
                <Message
                    level="error"
                    title={localization.fields.instance.error()}/>)}
            {!_.isNil(riskModel) && (
                <Autocomplete
                    disableClearable={true}
                    disabled={
                        disabled ||
                        getInstanceExecuting ||
                        readOnly}
                    disablePortal={true}
                    fullWidth={true}
                    getOptionLabel={impact => localization.fields.impact[Contract.TypeNames.ServiceNowIncidentImpact][impact]()}
                    noOptionsText={localization.fields.impact.empty()}
                    options={_.values(Contract.ServiceNowIncidentImpact)}
                    popupIcon={
                        <DropdownIcon
                            sx={{
                                color:
                                    disabled ||
                                    getInstanceExecuting ||
                                    readOnly
                                        ? theme.palette.text.disabled
                                        : theme.palette.text.secondary
                            }}/>}
                    renderInput={
                        params => (
                            <TextField
                                {...params}
                                label={localization.fields.impact.title()}
                                variant="outlined"/>)}
                    value={impact}
                    onChange={(event, impact) => setImpact(impact)}/>)}
            {!_.isNil(riskModel) && (
                <Autocomplete
                    disableClearable={true}
                    disabled={
                        disabled ||
                        getInstanceExecuting ||
                        readOnly}
                    disablePortal={true}
                    fullWidth={true}
                    getOptionLabel={urgency => localization.fields.urgency[Contract.TypeNames.ServiceNowIncidentUrgency][urgency]()}
                    noOptionsText={localization.fields.urgency.empty()}
                    options={_.values(Contract.ServiceNowIncidentUrgency)}
                    popupIcon={
                        <DropdownIcon
                            sx={{
                                color:
                                    disabled ||
                                    getInstanceExecuting ||
                                    readOnly
                                        ? theme.palette.text.disabled
                                        : theme.palette.text.secondary
                            }}/>}
                    renderInput={
                        params => (
                            <TextField
                                {...params}
                                label={localization.fields.urgency.title()}
                                variant="outlined"/>)}
                    value={urgency}
                    onChange={(event, urgency) => setUrgency(urgency)}/>)}
            {_.map(
                additionalMandatorySelectionFields,
                additionalMandatorySelectionField =>
                    <TicketingServiceMandatoryFieldSelector
                        disabled={disabled || readOnly}
                        disablePortal={true}
                        fetchOptionsExecuting={getInstanceExecuting}
                        getOptionLabel={optionValue => additionalMandatorySelectionField.optionValueToDisplayNameMap[optionValue]}
                        key={additionalMandatorySelectionField.name}
                        mandatoryFieldDisplayName={additionalMandatorySelectionField.displayName}
                        mandatoryFieldName={additionalMandatorySelectionField.name}
                        mandatoryFieldNameToErrorMessageMap={additionalMandatoryFieldNameToErrorMessageMap}
                        mandatoryFieldNameToValuesMap={additionalMandatoryFieldNameToValuesMap}
                        options={additionalMandatorySelectionFieldNameToOrderedOptionsMap[additionalMandatorySelectionField.name]}
                        setMandatoryFieldNameToErrorMessageMap={setAdditionalMandatoryFieldNameToErrorMessageMap}
                        setMandatoryFieldNameToValuesMap={setAdditionalMandatoryFieldNameToValuesMap}/>)}
            {_.map(
                additionalMandatoryStringFields,
                additionalMandatoryStringField =>
                    <Box key={additionalMandatoryStringField.name}>
                        <TextField
                            disabled={
                                disabled ||
                                readOnly}
                            fullWidth={true}
                            key={additionalMandatoryStringField.name}
                            label={additionalMandatoryStringField.displayName}
                            slotProps={{ htmlInput: { maxLength: 254 } }}
                            value={additionalMandatoryFieldNameToValuesMap[additionalMandatoryStringField.name] ?? ""}
                            variant="outlined"
                            onChange={
                                event => {
                                    const value = event.target.value.trim();
                                    setAdditionalMandatoryFieldNameToValuesMap(
                                        additionalMandatoryFieldNameToValuesMap => ({
                                            ...additionalMandatoryFieldNameToValuesMap,
                                            [additionalMandatoryStringField.name]: [value]
                                        }));

                                    setAdditionalMandatoryFieldNameToErrorMessageMap(
                                        additionalMandatoryFieldNameToErrorMessageMap => ({
                                            ...additionalMandatoryFieldNameToErrorMessageMap,
                                            [additionalMandatoryStringField.name]:
                                                _.isEmpty(value)
                                                    ? localization.fields.additionalMandatoryFields.error({ fieldName: additionalMandatoryStringField.displayName })
                                                    : undefined
                                        }));
                                }
                            }/>
                        {!_.isNil(additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryStringField.name]) && (
                            <FormHelperText error={true}>
                                {additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryStringField.name]}
                            </FormHelperText>)}
                    </Box>)}
            {summary && (
                <Box>
                    <TextField
                        disabled={
                            disabled ||
                            getInstanceExecuting ||
                            readOnly}
                        fullWidth={true}
                        label={localization.fields.summary.title()}
                        slotProps={{ htmlInput: { maxLength: 254 } }}
                        value={summary}
                        variant="outlined"
                        onChange={event => setSummary(event.target.value)}/>
                    {!_.isNil(summaryValidationMessage) &&
                        <FormHelperText error={true}>{summaryValidationMessage}</FormHelperText>}
                </Box>)}
            {description && (
                <Box>
                    <TextField
                        disabled={
                            disabled ||
                            getInstanceExecuting ||
                            readOnly}
                        fullWidth={true}
                        label={localization.fields.description.title()}
                        multiline={true}
                        rows={6}
                        value={description}
                        variant="outlined"
                        onChange={event => setDescription(event.target.value)}/>
                    {!_.isNil(descriptionValidationMessage) &&
                        <FormHelperText error={true}>{descriptionValidationMessage}</FormHelperText>}
                </Box>)}
            {!_.isNil(riskModel) &&
                !_.isEmpty(riskModel.files) && (
                <Box>
                    <Typography variant="h3">
                        {localization.fields.fileNames.title()}
                    </Typography>
                    <Typography variant="subtitle1">
                        {localization.fields.fileNames.description()}
                    </Typography>
                    <List disablePadding={true}>
                        {_.map(
                            riskModel.files,
                            file =>
                                <ListItemButton
                                    dense={true}
                                    disabled={
                                        disabled ||
                                            getInstanceExecuting ||
                                            readOnly}
                                    disableGutters={true}
                                    key={file.name}>
                                    <CheckboxField
                                        checked={_.includes(fileNames, file.name)}
                                        disabled={
                                            disabled ||
                                                getInstanceExecuting ||
                                                readOnly}
                                        onChange={
                                            (_event, checked) =>
                                                setFileNames(
                                                    checked
                                                        ? _.concat(fileNames!, file.name)
                                                        : _.without(fileNames!, file.name))}>
                                        {file.name}
                                    </CheckboxField>
                                </ListItemButton>)}
                    </List>
                </Box>)}
        </Stack>);
}