import { Box, Button, Card as MuiCard, Stack, SxProps, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Dispatch, Fragment, ReactNode, SetStateAction, useMemo, useState } from "react";
import { BreakpointResizer, makeContextProvider, Shadows, useExecuteOperation, useLocalization } from "@infrastructure";
import { Analyzing, Contract, dashboardWidgetMap, DashboardWidgetType, generateWidgetId, useTheme } from "../..";
import { GridStackWrapper } from "./components";

export type DashboardConfiguration<T extends DashboardContext = DashboardContext> = {
    editable?: boolean;
    fetchDashboardContextData: () => Promise<T>;
    title: string;
    widgetDatasOrGetWidgetDatas: DashboardWidgetData[] | ((dashboardContext: any) => DashboardWidgetData[]);
};

export interface DashboardWidgetData {
    location: Rectangle;
    type: DashboardWidgetType;
};

export class DashboardContext {
    constructor(public analyzing?: boolean) {
    }
}

export class SummaryDashboardContext extends DashboardContext {
    constructor(
        public entityTypeEntitiesViewNameToCountMap: Dictionary<number>,
        public summary: Contract.DashboardSummary,
        public analyzing?: boolean) {
        super(analyzing);
    }
}

export class AccessDashboardContext extends DashboardContext {
    constructor(
        public filters: DashboardFilters,
        public summary: Contract.AccessControllerGetDashboardSummaryResponseAccessSummary) {
        super(false);
    }
}

export class CodeDashboardContext extends DashboardContext {
    constructor(
        public entityTypeEntitiesViewNameToCountMap: Dictionary<number>,
        public summary: Contract.CodeControllerGetDashboardSummaryResponseSummary,
        public analyzing?: boolean) {
        super(analyzing);
    }
}

type Rectangle = {
    height: number;
    width: number;
    x?: number;
    y?: number;
};

const [useDashboardContextBase, useSetDashboardContextBase, useDashboardContextProviderBase] = makeContextProvider<DashboardContext>();

export function useDashboardContext<T>() {
    return useDashboardContextBase() as T;
}

export function useSetDashboardContext<T>() {
    return useSetDashboardContextBase() as Dispatch<SetStateAction<T>>;
}

type DashboardProps = {
    className: string;
    configuration: DashboardConfiguration;
    gridSx?: SxProps;
    topBar?: ReactNode;
};

export function Dashboard({ className, configuration: { editable = false, fetchDashboardContextData, title, widgetDatasOrGetWidgetDatas }, gridSx, topBar }: DashboardProps) {
    const [editMode, setEditMode] = useState(false);
    const editModeActive = editable && editMode;

    const [contextData] =
        useExecuteOperation(
            Dashboard,
            fetchDashboardContextData);

    const [{ analyzing }, , ContextProvider] = useDashboardContextProviderBase(() => contextData);

    const [breakpoint, setBreakpoint] = useState(0);
    const widgets =
        useMemo(
            () => {
                const widgetDatas =
                    _.isFunction(widgetDatasOrGetWidgetDatas)
                        ? widgetDatasOrGetWidgetDatas(contextData)
                        : widgetDatasOrGetWidgetDatas;

                return _.map(
                    widgetDatas,
                    ({ location, type }) => ({
                        ...dashboardWidgetMap[type],
                        h: location.height,
                        id: generateWidgetId(),
                        w: location.width,
                        x: location.x,
                        y: location.y
                    }));
            },
            [contextData, widgetDatasOrGetWidgetDatas]);

    const localization =
        useLocalization(
            "common.dashboard",
            () => ({
                editMode: "Edit Mode",
                quitEditMode: "Quit Edit Mode"
            }));

    const theme = useTheme();
    return (
        <Fragment>
            <BreakpointResizer
                breakpointXs={[breakpointX]}
                onSize={setBreakpoint}/>
            <Box
                className={className}
                sx={{
                    background: theme.palette.background.default,
                    overflowY: "auto",
                    padding: theme.spacing(0, 1, 1, 0),
                    ...(analyzing && {
                        filter: "blur(12px)",
                        pointerEvents: "none",
                        userSelect: "none"
                    }),
                    ...gridSx
                }}>
                <ContextProvider>
                    <Box
                        sx={{
                            background: "inherit",
                            position: "sticky",
                            top: 0,
                            width: "100%",
                            zIndex: 103
                        }}>
                        <MuiCard
                            sx={{
                                marginBottom: theme.spacing(1),
                                padding: theme.spacing(2)
                            }}>
                            <Stack sx={{ height: "100%" }}>
                                <Stack sx={{ paddingBottom: 0 }}>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}
                                        sx={{ overflow: "hidden" }}>
                                        <Typography
                                            noWrap={true}
                                            sx={{
                                                flex: 1,
                                                fontWeight: 600
                                            }}
                                            variant="h3">
                                            {title}
                                        </Typography>
                                        {(topBar || editable) &&
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                gap={2}>
                                                {topBar}
                                                {editable &&
                                                    <Button
                                                        disabled={
                                                            breakpoint === 0 &&
                                                            !editModeActive}
                                                        onClick={() => setEditMode(currentEditMode => !currentEditMode)}>
                                                        {editModeActive
                                                            ? localization.quitEditMode()
                                                            : localization.editMode()}
                                                    </Button>}
                                            </Stack>}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </MuiCard>
                    </Box>
                    <GridStackWrapper
                        editMode={editModeActive}
                        initialWidgets={widgets}/>
                </ContextProvider>
            </Box>
            {analyzing &&
                <Box
                    sx={{
                        background: theme.palette.background.alternate,
                        border: theme.border.alternate,
                        borderRadius: theme.spacing(0.75),
                        boxShadow: theme.shadows[Shadows.Card],
                        left: "50%",
                        padding: theme.spacing(8),
                        position: "absolute",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        width: theme.px(800),
                        zIndex: theme.zIndex.drawer
                    }}>
                    <Analyzing/>
                </Box>}
        </Fragment>);
}

type DashboardFilters = {
    severities: Contract.Severity[];
};

const breakpointX = 1023;