import { Action1, EventHandlerRegister, makeContextProvider, useEvent, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { ComplianceSectionIcon, Contract, ProfileLayout, ProfileTopbar, useComplianceSectionDatas, useComplianceTranslator, useTheme } from "../../../../../../common";
import { ComplianceItem } from "../../hooks";
import { Item } from "./components";

export class ItemContext {
    constructor(
        public disabled: boolean,
        public scopeId: string,
        public riskPoliciesDatas: Contract.RiskControllerGetComplianceSectionDatasResponseRiskPolicyData[],
        public registerExpandedChange: EventHandlerRegister<Action1<boolean>>,
        public triggerExpandedChange: Action1<boolean>,
        public expandedSectionIds: string[]) {
    };
}

export const [useItemContext, useSetItemContext, useItemContextProvider] = makeContextProvider<ItemContext>();

type ProfileProps = {
    item: ComplianceItem;
    scopeId: string;
};

export function Profile({ item, scopeId }: ProfileProps) {
    const [registerExpandedChange, triggerExpandedChange] = useEvent<Action1<boolean>>();
    const { complianceSectionIdentifierToTopRiskPolicyDatasMap } =
        useComplianceSectionDatas(
            scopeId,
            {
                childScopeCustomCompliance: true,
                timeFrameData: true
            });

    const [{ disabled }, , ContextProvider] =
        useItemContextProvider(
            () =>
                new ItemContext(
                    item.disabled,
                    scopeId,
                    complianceSectionIdentifierToTopRiskPolicyDatasMap[item.sectionData.identifier],
                    registerExpandedChange,
                    triggerExpandedChange,
                    []),
            [scopeId, complianceSectionIdentifierToTopRiskPolicyDatasMap, item]);

    const complianceTranslator = useComplianceTranslator();
    const { description, title } = complianceTranslator(item.sectionData.identifier);
    const localization =
        useLocalization(
            "views.customer.compliance.profile",
            () => ({
                disabled: "Disabled"
            }));

    const theme = useTheme();
    return (
        <ContextProvider>
            <ProfileLayout
                sx={{ scrollbarGutter: "stable" }}
                topBarElement={
                    <ProfileTopbar
                        icon={
                            <ComplianceSectionIcon
                                complianceIdentifier={item.sectionData.identifier}
                                size={theme.spacing(4)}/>}
                        title={title}
                        titleInfo={
                            disabled
                                ? <Typography
                                    sx={{
                                        border: theme.border.primary,
                                        borderRadius: theme.spacing(0.75),
                                        lineHeight: "18px",
                                        padding: theme.spacing(0, 1)
                                    }}>
                                    {localization.disabled()}
                                </Typography>
                                : undefined}/>}>
                <Item
                    description={description}
                    sectionData={item.sectionData}/>
            </ProfileLayout>
        </ContextProvider>);
}