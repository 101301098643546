import { ItemSelector, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../../common";
import { TypeNames } from "../../../../../../../../../../../../common/controllers/typeNames.generated";

type VersionsProps = {
    secretModel: Contract.OciKmsVaultSecretModel;
};

export function Versions({ secretModel }: VersionsProps) {
    const secret = secretModel.entity as Contract.OciKmsVaultSecret;
    const [selectedVersion, setSelectedVersion] = useState(() => secret.versionNumberToVersionMap[secret.currentVersionNumber]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciKmsVaultSecretDefinition.versions",
            () => ({
                current: {
                    text: "{{versionNumber}} (current)"
                },
                fields: {
                    autoGeneratedContent: {
                        false: "No",
                        title: "Content Auto Generated",
                        true: "Yes"
                    },
                    creationTime: "Creation Time",
                    status: {
                        title: "Status",
                        [Contract.TypeNames.OciKmsVaultSecretVersionStatus]: {
                            [Contract.OciKmsVaultSecretVersionStatus.Current]: "Current",
                            [Contract.OciKmsVaultSecretVersionStatus.Deprecated]: "Deprecated",
                            [Contract.OciKmsVaultSecretVersionStatus.Latest]: "Latest",
                            [Contract.OciKmsVaultSecretVersionStatus.Pending]: "Pending",
                            [Contract.OciKmsVaultSecretVersionStatus.Previous]: "Previous"
                        }
                    },
                    versionNumber: "Version Number"
                }
            }));

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(secret.versionNumberToVersionMap).values()
                        .orderBy(
                            [
                                version => version.versionNumber === secret.currentVersionNumber,
                                version => version.creationTime
                            ],
                            [
                                "desc",
                                "desc"
                            ])
                        .map(version => version.versionNumber)
                        .value()}
                selectedItem={selectedVersion.versionNumber}
                sorted={false}
                onSelectedItemChanged={versionNumber => setSelectedVersion(secret.versionNumberToVersionMap[versionNumber])}>
                {versionNumber =>
                    versionNumber === secret.currentVersionNumber
                        ? localization.current.text({ versionNumber })
                        : versionNumber}
            </ItemSelector>
            <InfoCard columns={true}>
                <InfoItem
                    key="versionNumber"
                    title={localization.fields.versionNumber()}
                    value={selectedVersion.versionNumber}/>
                <VerticalTopItemsInfoItem
                    items={
                        _.map(
                            selectedVersion.statuses,
                            status => localization.fields.status[TypeNames.OciKmsVaultSecretVersionStatus][status]())}
                    key="status"
                    title={localization.fields.status.title()}/>
                <InfoItem
                    key="creationTime"
                    title={localization.fields.creationTime()}
                    value={TimeFormatter.profileFormatDateTime(selectedVersion.creationTime)}/>
                <InfoItem
                    key="autoGeneratedContent"
                    title={localization.fields.autoGeneratedContent.title()}
                    value={
                        selectedVersion.autoGeneratedContent
                            ? localization.fields.autoGeneratedContent.true()
                            : localization.fields.autoGeneratedContent.false()}/>
            </InfoCard>
        </InfoCard>);
}