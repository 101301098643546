import { Sx } from "@infrastructure";
import { DiffEditor as MonacoDiffEditor, DiffEditorProps as MonacoDiffEditorProps, loader } from "@monaco-editor/react";
import { Box, Divider, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import { editor } from "monaco-editor/esm/vs/editor/editor.api";
import React, { isValidElement, ReactNode, useCallback, useRef } from "react";
import { commonOptions, MAX_HEIGHT } from "./commonOptions";
import { themeName, useCommonStyle, useMonacoTheme } from "./hooks";

loader.config({
    paths: {
        vs: "/monaco-editor/min/vs"
    }
});

export type DiffEditorProps = Pick<MonacoDiffEditorProps, | "height" | "modified" | "original"> & {
    autoHeight?: boolean;
    containerSx?: SxProps;
    format: MonacoDiffEditorProps["language"];
    modifiedTitle?: ReactNode;
    originalTitle?: ReactNode;
    sx?: SxProps;
    variant?: "collapsable" | "extended";
};

export function DiffEditor({ autoHeight = false, containerSx, format, height, modified, modifiedTitle, original, originalTitle, sx, variant = "extended" }: DiffEditorProps) {
    const editorRef = useRef<editor.IStandaloneDiffEditor>();
    const containerRef = useRef<HTMLDivElement>(null);
    const commonStyles = useCommonStyle();
    const monacoTheme = useMonacoTheme();

    const updateHeight =
        useCallback(
            () => {
                const diffEditor = editorRef.current;
                if (!diffEditor || !containerRef.current) {
                    return;
                }

                const maxHeight =
                    Math.max(
                        diffEditor.getOriginalEditor().
                            getContentHeight(),
                        diffEditor.getModifiedEditor().
                            getContentHeight());

                const height = Math.min(MAX_HEIGHT, maxHeight);
                containerRef.current.style.height = `${height}px`;
                const { width: originalEditorWidth } =
                    diffEditor.
                        getOriginalEditor().
                        getLayoutInfo();
                diffEditor.layout({ height, width: originalEditorWidth * 2 + 30 });
            },
            [editorRef, containerRef]);

    const theme = useTheme();
    return (
        <Stack
            sx={Sx.combine(
                {
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    ...(!autoHeight && {
                        height: "100%",
                        overflow: "hidden"
                    })
                },
                containerSx)}>
            {_.isNil(originalTitle) && _.isNil(modifiedTitle)
                ? null
                : <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        alignItems: "center",
                        borderBottom: theme.border.primary,
                        height: theme.spacing(6),
                        paddingRight: theme.spacing(3.75)
                    }}>
                    <Box
                        sx={{
                            flex: 1,
                            overflow: "hidden",
                            padding: theme.spacing(0, 2)
                        }}>
                        {isValidElement(originalTitle)
                            ? originalTitle
                            : <Typography
                                noWrap={true}
                                sx={{ paddingLeft: theme.spacing(1) }}
                                variant="h3">
                                {originalTitle}
                            </Typography>}
                    </Box>
                    <Divider
                        flexItem={true}
                        orientation="vertical"/>
                    <Box
                        sx={{
                            flex: 1,
                            overflow: "hidden",
                            padding: theme.spacing(0, 2)
                        }}>
                        {isValidElement(modifiedTitle)
                            ? modifiedTitle
                            : <Typography
                                noWrap={true}
                                sx={{ paddingLeft: theme.spacing(1) }}
                                variant="h3">
                                {modifiedTitle}
                            </Typography>}
                    </Box>
                </Stack>}
            <Box
                ref={containerRef}
                sx={
                    Sx.combine(
                        commonStyles,
                        {
                            ".diffOverview": {
                                borderLeft: theme.border.primary
                            },
                            ".monaco-diff-editor.side-by-side .editor.modified": {
                                borderLeft: theme.border.primary
                            },
                            flex:
                                !autoHeight
                                    ? 1
                                    : undefined,
                            overflow: "hidden",
                            width: "100%"
                        },
                        sx)}>
                <MonacoDiffEditor
                    beforeMount={monacoEditor => monacoEditor.editor.defineTheme(themeName, monacoTheme)}
                    height={height}
                    language={format}
                    modified={modified}
                    options={{
                        ...commonOptions,
                        enableSplitViewResizing: false,
                        hideUnchangedRegions: {
                            contextLineCount: 3,
                            enabled: variant === "collapsable",
                            minimumLineCount: 7
                        }
                    }}
                    original={original}
                    theme={themeName}
                    onMount={
                        standaloneCodeEditor => {
                            editorRef.current = standaloneCodeEditor;

                            if (autoHeight) {
                                updateHeight();
                                standaloneCodeEditor.onDidUpdateDiff(updateHeight);
                            }
                        }}/>
            </Box>
        </Stack>);
}