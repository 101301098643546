﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity } from "../../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useRemediationSteps } from "../useRemediationSteps";
import { ContextSection, DetailsSection } from "./components";

export function useAwsInboundExternalEc2InstanceRiskLaunchTemplateDefinition(riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel) {
    const remediationSteps = useRemediationSteps(riskModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useAwsInboundExternalEc2InstanceRiskLaunchTemplateDefinition",
            () => ({
                description: "{{launchTemplate}} launched EC2 instances that are directly accessible from a wide range of public IP addresses",
                sections: {
                    details: "Launch Template Revisions"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchTemplate:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => remediationSteps,
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections:
                _<RiskDefinitionSection>([]).
                    concatIf(
                        !_.isEmpty(riskModel.risk.aggregatedEntityIds),
                        () => new RiskDefinitionSection(
                            <DetailsSection riskModel={riskModel}/>,
                            localization.sections.details())).
                    value()
        });
}