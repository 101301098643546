﻿import { EmptyMessageText, ItemSelector, Loading, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { useGcpCommonServiceAccountOriginatorScopeResourceInfoElements } from "../..";
import { Contract, entityModelStore, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem } from "../../../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../../../components";

type TriggersProps = {
    triggerIds: string[];
};

export function Triggers({ triggerIds }: TriggersProps) {
    const triggerModels = entityModelStore.useGet(triggerIds) as Contract.GcpEventarcTriggerModel[];
    const triggerModelMap =
        _.keyBy(
            triggerModels,
            triggerModel => triggerModel.id);

    const [selectedTriggerModel, setSelectedTriggerModel] = useState(triggerModels[0]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCloudRunServiceDefinition.triggers",
            () => ({
                items: {
                    additionalEventFilterKeyToValueMap: {
                        empty: "No Filters",
                        title: "Filters"
                    },
                    eventFilterServiceName: "Event Provider",
                    eventFilterType: "Event Type",
                    subscriptionIdReference: "Subscription",
                    topicIdReference: "Topic"
                },
                latest: "Latest"
            }));

    const selectedTrigger = selectedTriggerModel.entity as Contract.GcpEventarcTrigger;
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(selectedTriggerModel);
    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={_.keys(triggerModelMap)}
                selectedItem={selectedTrigger.id}
                onSelectedItemChanged={triggerModelId => setSelectedTriggerModel(triggerModelMap[triggerModelId])}>
                {triggerModelId => triggerModelMap[triggerModelId].entity.displayName}
            </ItemSelector>
            <Loading>
                <InfoCard columns={true}>
                    <InfoItem
                        title={localization.items.eventFilterType()}
                        value={selectedTrigger.eventFilterType}/>
                    <InfoItem
                        title={localization.items.eventFilterServiceName()}
                        value={
                            _.isNil(selectedTrigger.eventFilterServiceName)
                                ? undefined
                                : selectedTrigger.eventFilterServiceName}/>
                    {commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount},
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedTriggerModel?.topicIdReference}
                        entityTypeName={Contract.TypeNames.GcpPubSubTopic}
                        title={localization.items.topicIdReference()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedTriggerModel?.subscriptionIdReference}
                        entityTypeName={Contract.TypeNames.GcpPubSubSubscription}
                        title={localization.items.subscriptionIdReference()}/>
                </InfoCard>
                <InfoCard
                    title={localization.items.additionalEventFilterKeyToValueMap.title()}>
                    <KeyValueTable
                        emptyMessageText={new EmptyMessageText(localization.items.additionalEventFilterKeyToValueMap.empty())}
                        items={
                            _.map(
                                selectedTrigger.additionalEventFilterKeyToValueMap,
                                (value, key) =>
                                    new KeyValueTableItem(
                                        key,
                                        value))}/>
                </InfoCard>
            </Loading>
        </InfoCard>);
}