import { Message, NoneIcon, Optional } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme, VulnerabilityChart } from "../../../../../common";
import { useGetUnsupportedOperatingSystemMessage } from "../hooks";

export type VulnerabilitiesChartCellProps = {
    variant?: "count" | "initialAndCount";
    vulnerabilities: Optional<Contract.VulnerabilityData[]>;
    workloadResourceEntityId: string;
};

export function VulnerabilitiesChartCell({ variant = "count", vulnerabilities, workloadResourceEntityId }: VulnerabilitiesChartCellProps) {
    const getUnsupportedOperatingSystemMessage = useGetUnsupportedOperatingSystemMessage();
    const infoMessage = getUnsupportedOperatingSystemMessage(workloadResourceEntityId);
    const filteredInformationalSeverityVulnerabilities =
        _.isNil(vulnerabilities)
            ? undefined
            : _(vulnerabilities).
                filter(vulnerabilityData => vulnerabilityData.severity !== Contract.Severity.Information).
                value();

    const theme = useTheme();
    return _.isNil(filteredInformationalSeverityVulnerabilities)
        ? <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <NoneIcon
                sx={{
                    color: theme.palette.text.secondary,
                    fontSize: "18px"
                }}/>
        </Stack>
        : <VulnerabilityChart
            message={
                !!infoMessage &&
                <Message
                    level="info"
                    title={
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {infoMessage}
                        </Typography>}
                    variant="minimal"/>}
            variant={variant}
            vulnerabilities={filteredInformationalSeverityVulnerabilities}/>;
}