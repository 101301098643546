import { Action1, InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, Optional, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Divider, ListItem, ListItemButton } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useMemo, useRef, useState } from "react";
import { Contract, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { Item } from "../..";

type EntityModelsProps = {
    entityModels: Contract.EntityModel[];
    entityTypeName: string;
    onSelectionChanged?: Action1<string>;
    renderEntityStats?: (entityId: string) => ReactNode;
    searchText: Optional<string>;
    selectedEntityId: Optional<string>;
};

export function EntityModels({ entityModels, entityTypeName, onSelectionChanged, renderEntityStats, searchText, selectedEntityId }: EntityModelsProps) {
    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>();
    const filteredEntityModelsRef = useRef<Contract.EntityModel[]>(entityModels);
    const [visibleEntityModels, setVisibleEntityModels] = useState<Contract.EntityModel[]>([]);
    const localization =
        useLocalization(
            "common.entityList.list.entityModels",
            () => ({
                empty: "No Matching {{translatedEntityTypeName}}"
            }));
    useChangeEffect(
        () => {
            filteredEntityModelsRef.current =
                _.filter(
                    entityModels,
                    entityModel => StringHelper.search(entityModel.entity.displayName, searchText));
            setVisibleEntityModels([]);

            infiniteScrollActionsRef.current!.reset();
        },
        [entityModels, searchText]);

    function fetch() {
        setVisibleEntityModels(
            visibleEntities =>
                _.concat(
                    visibleEntities,
                    filteredEntityModelsRef.current.slice(
                        visibleEntities.length,
                        visibleEntities.length + 20)));
        return Promise.resolve(
            new InfiniteScrollFetchDataResult(
                !_.isEmpty(filteredEntityModelsRef.current),
                visibleEntityModels.length + 20 > filteredEntityModelsRef.current.length));
    }

    const theme = useTheme();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <InfiniteScroll
            actionsRef={infiniteScrollActionsRef}
            emptyTextOptions={{
                text:
                    localization.empty({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: 0,
                                    includeServiceName: false
                                })
                    })
            }}
            fetchData={fetch}>
            {useMemo(
                () =>
                    _.map(
                        visibleEntityModels,
                        (visibleEntityModel, visibleEntityModelIndex) =>
                            <Fragment key={visibleEntityModel.entity.id}>
                                {visibleEntityModelIndex !== 0 &&
                                    <Divider/>}
                                {_.isNil(onSelectionChanged)
                                    ? <ListItem sx={{ padding: theme.spacing(1) }}>
                                        <Item
                                            entityId={visibleEntityModel.id}
                                            renderEntityStats={renderEntityStats}/>
                                    </ListItem>
                                    : <ListItemButton
                                        selected={visibleEntityModel.entity.id === selectedEntityId}
                                        sx={{ padding: theme.spacing(1) }}
                                        onClick={() => onSelectionChanged?.(visibleEntityModel.entity.id)}>
                                        <Item
                                            entityId={visibleEntityModel.id}
                                            renderEntityStats={renderEntityStats}
                                            selected={visibleEntityModel.entity.id === selectedEntityId}/>
                                    </ListItemButton>}
                            </Fragment>),
                [selectedEntityId, visibleEntityModels])}
        </InfiniteScroll>);
}