﻿import { DataTableColumn, DataTableColumnRenderProps, Link, map, StringHelper, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, StatusCell, tenantModelStore, UrlHelper } from "../../../../../../../../common";
import { useOciTenantModelStatusTranslator } from "../../../../../../../../tenants";
import { ScopeRawId } from "../../components";
import { TenantsDefinition } from "../../useDefinition";
import { useRenderTenant } from "../useRenderTenant";

export function useOciDefinition(): TenantsDefinition {
    const tenantModelMap = tenantModelStore.useGetOciTenantMap();
    const ociTenantModelStatusTranslator = useOciTenantModelStatusTranslator();
    const { renderTenantCell, renderTenantCsvItem } = useRenderTenant<Contract.OciTenantModel>();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOciDefinition",
            () => ({
                actions: {
                    delete: "delete the entire organization",
                    deleteOrganizationFolder: "This folder belongs to an organization which is configured to automatically onboard new compartments, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its compartments and folders), or exclude this specific compartment by {{supportLink}}.",
                    deleteOrganizationTenant: "This compartment/s belongs to an organization which is configured to automatically onboard new compartments, and can't be deleted. Instead, you can either {{deleteLink}} (including all its compartments), or exclude this specific compartments by {{supportLink}}",
                    deleteSelection: "Some/All of the selected compartments and/or folders belong to an organization which is configured to automatically onboard new compartments, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its compartments and folders), or exclude this specific compartments by {{supportLink}}.",
                    supportLink: "contacting Support"
                },
                columns: {
                    ocid: "OCID",
                    status: "Status"
                },
                title: "OCI Compartments"
            }));

    return useMemo(
        () => ({
            deleteTenantDisabled: true,
            editTenantDisabled: true,
            filter:
                (filterMap, scopeNodeModel) => {
                    if (ScopeHelper.isFolder(scopeNodeModel)) {
                        const folderRawId = (scopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration).organization?.folderRawId;
                        if (!_.isNil(filterMap[TenantTableColumnId.Ocid]) &&
                            !_.isNil(folderRawId) &&
                            !_.includes(
                                filterMap[TenantTableColumnId.Ocid].values,
                                folderRawId)) {
                            return false;
                        }

                        return true;
                    }

                    const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];

                    if(_.isNil(tenantModel)) {
                        return true;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.Ocid]) &&
                        !_.includes(filterMap[TenantTableColumnId.Ocid].values, tenantModel.configuration.ocid)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.Status]) &&
                        !_.includes(filterMap[TenantTableColumnId.Status].values, tenantModel.status)) {
                        return false;
                    }

                    return true;
                },
            getColumns:
                scopeNodeModels => {
                    const ocids: string[] = [];
                    const statuses = new Set<Contract.OciTenantModelStatus>();

                    _.forEach(
                        scopeNodeModels,
                        scopeNodeModel => {
                            const folderRawId = (scopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration)?.organization?.folderRawId;
                            if (!_.isNil(folderRawId) && ScopeHelper.isFolder(scopeNodeModel)) {
                                ocids.push(folderRawId);
                            } else if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                                const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                                ocids.push(tenantModel.configuration.ocid);
                                statuses.add(tenantModel.status);
                            }
                        });

                    return [
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.ocid()]:
                                            renderTenantCsvItem(
                                                tenantModel => tenantModel.configuration.ocid,
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <TextValuesFilter
                                            placeholder={localization.columns.ocid()}
                                            values={ocids}/>
                                }
                            }}
                            id={TenantTableColumnId.Ocid}
                            key={TenantTableColumnId.Ocid}
                            render={
                                (scopeNodeModel: DataTableColumnRenderProps<Contract.ScopeNodeModel>) =>
                                    <ScopeRawId
                                        fallbackRender={renderTenantCell(tenantModel => tenantModel.configuration.ocid)}
                                        scopeNodeModel={scopeNodeModel}/>}
                            title={localization.columns.ocid()}/>,
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.status()]:
                                            renderTenantCsvItem(
                                                tenantModel => ociTenantModelStatusTranslator(tenantModel.status),
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.status()}>
                                            {_.map(
                                                Array.from(statuses),
                                                status => (
                                                    <ValuesFilterItem
                                                        key={status}
                                                        title={ociTenantModelStatusTranslator(status)}
                                                        value={status}/>))}
                                        </ValuesFilter>
                                }
                            }}
                            id={TenantTableColumnId.Status}
                            key={TenantTableColumnId.Status}
                            render={
                                renderTenantCell(
                                    tenantModel =>
                                        <StatusCell
                                            statusSeverity={tenantModel.statusSeverity}
                                            title={ociTenantModelStatusTranslator(tenantModel.status)}/>)}
                            title={localization.columns.status()}/>
                    ];
                },
            getDeleteScopeDisabledMessage:
                (selection, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.Folder &&
                        (scopeNodeModel.configuration as Contract.OciFolderConfiguration).managed) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationFolder)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsAwsRelativeUrl()}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    } else if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationTenant)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsAwsRelativeUrl()}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    }

                    return undefined;
                },
            sort:
                (columnId, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                        const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                        return map<string, any>(
                            columnId,
                            {
                                [TenantTableColumnId.Ocid]: () => StringHelper.getSortValue(tenantModel.configuration.ocid),
                                [TenantTableColumnId.Status]: () => StringHelper.getSortValue(ociTenantModelStatusTranslator(tenantModel.status))
                            });
                    } else {
                        return undefined;
                    }
                },
            title: localization.title()
        }),
        [localization, ociTenantModelStatusTranslator, tenantModelMap]);
}

enum TenantTableColumnId {
    Ocid = "ocid",
    Status = "status"
}