import { UnexpectedError, useLocalization, ValuesFilterSelection } from "@infrastructure";
import { useCallback } from "react";
import { Contract, CustomerConsoleAppUrlHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { CustomerLicenseObjectTypeTranslator } from "../useGetVersion2Translator";

export function useGetVersion2GcpTranslator(): CustomerLicenseObjectTypeTranslator {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.licensing.hooks.useGetCustomerLicenseObjectInfo.hooks.version2.hooks.useGetVersion2GcpTranslator",
            () => ({
                [Contract.CustomerLicenseObjectType.GcpArtifactContainerImageRepository]: "{{translatedTypeName}}",
                [Contract.CustomerLicenseObjectType.GcpContainerAutopilotCluster]: "{{translatedTypeName}} (Autopilot mode)",
                [Contract.CustomerLicenseObjectType.GcpContainerClusterInstance]: "{{translatedTypeName}} running GKE",
                [Contract.CustomerLicenseObjectType.GcpBigQueryAnalyzedDataset]: "{{translatedTypeName}} (Data Protection)",
                [Contract.CustomerLicenseObjectType.GcpSqlAnalyzedInstance]: "{{translatedTypeName}} (Data Protection)",
                [Contract.CustomerLicenseObjectType.GcpStorageAnalyzedBucket]: "{{translatedTypeName}} (Data Protection)"
            }));

    return useCallback(
        objectType => {
            switch (objectType) {
                case Contract.CustomerLicenseObjectType.GcpArtifactContainerImageRepository:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        objectType,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.GcpBigQueryAnalyzedDataset:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpBigQueryDataset,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.GcpCloudRunService:
                case Contract.CustomerLicenseObjectType.GcpComputeInstance:
                case Contract.CustomerLicenseObjectType.GcpFunctionsFunction:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(objectType),
                        title:
                            entityTypeNameTranslator(
                                objectType,
                                { count: 0 })
                    };
                case Contract.CustomerLicenseObjectType.GcpContainerAutopilotCluster:
                    return {
                        link:
                            CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(
                                Contract.TypeNames.GcpContainerCluster,
                                {
                                    gcpContainerClusterModes:
                                        new ValuesFilterSelection(
                                            false,
                                            [Contract.GcpContainerClusterMode.Autopilot] as any)
                                }),
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpContainerCluster,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.GcpContainerClusterInstance:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpComputeInstance,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.GcpSqlAnalyzedInstance:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpSqlInstance,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.GcpStorageAnalyzedBucket:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpStorageBucket,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.GcpUnmanagedKubernetesClusterNode:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(Contract.TypeNames.GcpKubernetesNode),
                        title:
                            entityTypeNameTranslator(
                                Contract.TypeNames.IKubernetesNode,
                                {
                                    count: 0,
                                    includeServiceName: true
                                })
                    };
                default:
                    throw new UnexpectedError("objectType", objectType);
            }
        },
        [entityTypeNameTranslator, localization]);
}