import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsIamPrincipalPolicyDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsIamPrincipalPolicyDefinition",
            () => ({
                columns: {
                    attachedPrincipalIds: "Applied To",
                    management: {
                        title: "Management",
                        [Contract.TypeNames.AwsIamPrincipalPolicyModelManagement]: {
                            [Contract.AwsIamPrincipalPolicyModelManagement.AwsManaged]: "AWS Managed",
                            [Contract.AwsIamPrincipalPolicyModelManagement.Inline]: "Inline",
                            [Contract.AwsIamPrincipalPolicyModelManagement.CustomerManaged]: "Customer Managed"
                        }
                    }
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamPrincipalPolicyModel) => ({
                            [localization.columns.management.title()]: localization.columns.management[Contract.TypeNames.AwsIamPrincipalPolicyModelManagement][item.management]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsIamPrincipalPolicyModelManagement}
                                enumTypeTranslator={(management: Contract.AwsIamPrincipalPolicyModelManagement) => localization.columns.management[Contract.TypeNames.AwsIamPrincipalPolicyModelManagement][management]()}
                                placeholder={localization.columns.management.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamPrincipalPolicyManagement}
                itemProperty={(item: Contract.AwsIamPrincipalPolicyModel) => localization.columns.management[Contract.TypeNames.AwsIamPrincipalPolicyModelManagement][item.management]()}
                key={Contract.EntityModelProperty.AwsIamPrincipalPolicyManagement}
                title={localization.columns.management.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsIamPrincipalPolicyModel>(
                        Contract.TypeNames.AwsIamPrincipal,
                        item =>
                            _.concat(
                                item.permissionAttachedPrincipalIds,
                                item.permissionBoundaryAttachedIdentityIds),
                        localization.columns.attachedPrincipalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsIamPrincipalPolicyAttachedPrincipals)}
                                placeholder={localization.columns.attachedPrincipalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamPrincipalPolicyAttachedPrincipals}
                key={Contract.EntityModelProperty.AwsIamPrincipalPolicyAttachedPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsIamPrincipalPolicyModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={
                                _(item.permissionAttachedPrincipalIds).
                                    concat(item.permissionBoundaryAttachedIdentityIds).
                                    uniq().
                                    value()}
                            entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.attachedPrincipalIds()}/>,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsIamPrincipalPolicyRequest(
                new Contract.EntityControllerGetEntityModelPageAwsIamPrincipalPolicyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamPrincipalPolicyAttachedPrincipals]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamPrincipalPolicyManagement]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsIamPrincipalPolicyUsageTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}