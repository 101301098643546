import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonEncryptedResourceDefinition, useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciBlockStorageBootVolumeStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciBlockStorageBootVolumeDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useOciCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const entitiesExportOptions = useEntitiesExportOptions();

    const bootVolumeStatusTranslator = useOciBlockStorageBootVolumeStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciBlockStorageBootVolumeDefinition",
            () => ({
                columns: {
                    size: "Size",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciBlockStorageBootVolumeModel) => ({
                            [localization.columns.status()]: bootVolumeStatusTranslator((item.entity as Contract.OciBlockStorageBootVolume).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciBlockStorageBootVolumeStatus}
                                enumTypeTranslator={bootVolumeStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciBlockStorageBootVolumeStatus}
                itemProperty={(item: Contract.OciBlockStorageBootVolumeModel) => bootVolumeStatusTranslator((item.entity as Contract.OciBlockStorageBootVolume).status)}
                key={Contract.EntityModelProperty.OciBlockStorageBootVolumeStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciBlockStorageBootVolumeModel) => ({
                            [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.OciBlockStorageBootVolume).size)
                        })
                }}
                id={Contract.EntityModelProperty.OciBlockStorageBootVolumeSize}
                itemProperty={(item: Contract.OciBlockStorageBootVolumeModel) => NumberFormatter.storage((item.entity as Contract.OciBlockStorageBootVolume).size)}
                key={Contract.EntityModelProperty.OciBlockStorageBootVolumeSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciBlockStorageBootVolumeModel>(
                        Contract.TypeNames.OciComputeInstance,
                        item => [item.instanceIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciComputeInstance))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciBlockStorageBootVolumeInstance)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstance)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciBlockStorageBootVolumeInstance}
                key={Contract.EntityModelProperty.OciBlockStorageBootVolumeInstance}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciBlockStorageBootVolumeModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIdReference}
                            entityTypeName={Contract.TypeNames.OciComputeInstance}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstance)}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionVaultKeyColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciBlockStorageBootVolumeRequest(
                new Contract.EntityControllerGetEntityModelPageOciBlockStorageBootVolumeRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciBlockStorageBootVolumeInstance]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciBlockStorageBootVolumeStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageOciResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciEncryptedResourceEncryptionVaultKey]))));
}