﻿import { useLocalization } from "@infrastructure";
import { TextField } from "@mui/material";
import React from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";

export function Description() {
    const { permissionEligibilityData: { description }, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.description",
            () => ({
                title: "Description (optional)"
            }));

    return (
        <TextField
            disabled={upsertPermissionEligibilityExecuting}
            fullWidth={true}
            label={localization.title()}
            slotProps={{ htmlInput: { style: { fontSize: "1.1rem" } } }}
            value={description}
            variant="outlined"
            onChange={
                event =>
                    setAddOrEditContext(
                        addOrEditContext => {
                            addOrEditContext.permissionEligibilityData.description = event.target.value;
                            return { ...addOrEditContext };
                        })}/>);
}