import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, NetworkedResourcesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useOciNetworkingVcnStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciNetworkingVcnDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const vcnStatusTranslator = useOciNetworkingVcnStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciNetworkingVcnDefinition",
            () => ({
                columns: {
                    flowLogsEnabled: {
                        false: "Disabled",
                        title: "Flow Logs",
                        true: "Enabled"
                    },
                    ipv4Subnets: "IPv4 CIDR Blocks",
                    ipv6Subnets: "IPv6 Prefixes",
                    networkedResources: "Attached Resources",
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingVcnModel) => ({
                            [localization.columns.status()]: vcnStatusTranslator((item.entity as Contract.OciNetworkingVcn).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciNetworkingVcnStatus}
                                enumTypeTranslator={vcnStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingVcnStatus}
                itemProperty={(item: Contract.OciNetworkingVcnModel) => vcnStatusTranslator((item.entity as Contract.OciNetworkingVcn).status)}
                key={Contract.EntityModelProperty.OciNetworkingVcnStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingVcnModel) => ({
                            [localization.columns.ipv4Subnets()]: (item.entity as Contract.OciNetworkingVcn).ipv4Subnets.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingVcnIpv4Subnets)}
                                placeholder={localization.columns.ipv4Subnets()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingVcnIpv4Subnets}
                key={Contract.EntityModelProperty.OciNetworkingVcnIpv4Subnets}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingVcnModel>) =>
                        <Typography noWrap={true}>
                            <InlineItems
                                items={(item.entity as Contract.OciNetworkingVcn).ipv4Subnets}
                                variant="itemPlusItemCount"/>
                        </Typography>
                }
                title={localization.columns.ipv4Subnets()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingVcnModel) => ({
                            [localization.columns.ipv6Subnets()]: (item.entity as Contract.OciNetworkingVcn).ipv6Subnets.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingVcnIpv6Subnets)}
                                placeholder={localization.columns.ipv6Subnets()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingVcnIpv6Subnets}
                key={Contract.EntityModelProperty.OciNetworkingVcnIpv6Subnets}
                render={
                    optionalTableCell<Contract.OciNetworkingVcnModel>(
                        (item: Contract.OciNetworkingVcnModel) => {
                            const vcnIpv6Subnets = (item.entity as Contract.OciNetworkingVcn).ipv6Subnets;
                            return !_.isEmpty(vcnIpv6Subnets) &&
                                <Typography noWrap={true}>
                                    <InlineItems
                                        items={vcnIpv6Subnets}
                                        variant="itemPlusItemCount"/>
                                </Typography>;
                        })}
                title={localization.columns.ipv6Subnets()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingVcnModel>(
                        Contract.TypeNames.INetworkedResource,
                        item =>
                            _(item.networkedResourceTypeNameToIdsMap).
                                values().
                                flatMap().
                                value(),
                        localization.columns.networkedResources())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingVcnNetworkedResources)}
                                placeholder={localization.columns.networkedResources()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingVcnNetworkedResources}
                key={Contract.EntityModelProperty.OciNetworkingVcnNetworkedResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingVcnModel>) =>
                        <NetworkedResourcesCell item={item.networkedResourceTypeNameToIdsMap}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.networkedResources()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingVcnModel) => ({
                            [localization.columns.flowLogsEnabled.title()]:
                                item.flowLogsEnabled
                                    ? localization.columns.flowLogsEnabled.true()
                                    : localization.columns.flowLogsEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.flowLogsEnabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.flowLogsEnabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.flowLogsEnabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingVcnFlowLogsEnabled}
                itemProperty={
                    (item: Contract.OciNetworkingVcnModel) =>
                        item.flowLogsEnabled
                            ? localization.columns.flowLogsEnabled.true()
                            : localization.columns.flowLogsEnabled.false()}
                key={Contract.EntityModelProperty.OciNetworkingVcnFlowLogsEnabled}
                title={localization.columns.flowLogsEnabled.title()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciNetworkingVcnRequest(
                new Contract.EntityControllerGetEntityModelPageOciNetworkingVcnRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingVcnFlowLogsEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingVcnIpv4Subnets]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingVcnIpv6Subnets]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingVcnNetworkedResources]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingVcnStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}