import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciContainerEngineClusterNodePoolRiskContext() {
    return useMemo(
        () => useOciContainerEngineClusterNodePoolRiskContext,
        []);
}

function useOciContainerEngineClusterNodePoolRiskContext(clusterNodePoolModel: Contract.OciContainerEngineClusterNodePoolModel) {
    const clusterNodePool = clusterNodePoolModel.entity as Contract.OciContainerEngineClusterNodePool;
    const resourceRiskContext = useGetOciResourceRiskContext()(clusterNodePoolModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciContainerEngineClusterNodePoolRiskContext",
            () => ({
                instances: "The {{translatedClusterNodePoolTypeName}} is associated with {{instances}}"
            }));
    const translatedClusterNodePoolTypeName =
        entityTypeNameTranslator(
            clusterNodePool.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        instances:
            _.isEmpty(clusterNodePoolModel.instanceIdReferences)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instances({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={clusterNodePoolModel.instanceIdReferences}
                                entityTypeName={Contract.TypeNames.OciComputeInstance}
                                variant="itemCountAndType"/>,
                        translatedClusterNodePoolTypeName
                    }))
    };
}