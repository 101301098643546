import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { OktaTenantsContext, useOktaTenantsContext, useSetOktaTenantsContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { FinishItem, TenantItem, UserAccessTokenItem, UserItem, UserPermissionsItem } from "./components";

class AddOrEditContext {
    public tenantName: string;
    public tenantUrl: string;
    public tenantUserToken?: string;
    public updatedTenantModel?: Contract.OktaTenantModel;

    constructor(public tenantConfiguration?: Contract.OktaTenantConfiguration) {
        this.tenantName = tenantConfiguration?.name ?? "";
        this.tenantUrl = tenantConfiguration?.url ?? "";
        this.tenantUserToken =
            _.isNil(tenantConfiguration)
                ? ""
                : undefined;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useOktaTenantsContext();
    const tenantConfiguration =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;

    const setOktaTenantsContext = useSetOktaTenantsContext();
    const [, , ContextProvider] = useAddOrEditContextProvider(() => new AddOrEditContext(tenantConfiguration));
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oktaTenants.addOrEdit",
            () => ({
                steps: {
                    tenant: "Provide Details",
                    user: "Create a New Okta User",
                    userAccessToken: "Create Access Token",
                    userPermissions: "Grant Permissions"
                },
                title: {
                    add: "Add Okta Organization",
                    edit: "Edit Okta Organization"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(tenantConfiguration)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(tenantConfiguration)
                            ? 0
                            : 3}
                    onClose={() => setOktaTenantsContext(new OktaTenantsContext(false))}>
                    <OrderedWizardItem title={localization.steps.user()}>
                        <UserItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.userPermissions()}>
                        <UserPermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.userAccessToken()}>
                        <UserAccessTokenItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenant()}>
                        <TenantItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}