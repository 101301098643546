import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureWebApplicationRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { AzureWebApplicationNetworkRulesTable } from "../../../../../../../../../../../../tenants";
import { useAzureWebApplicationTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureWebApplicationScmInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureWebApplicationScmInboundExternalWideRangeRisk;
    const applicationModel = entityModelStore.useGet(risk.entityId) as Contract.AzureWebApplicationModel;
    const application = applicationModel.entity as Contract.AzureWebApplication;

    const getAzureWebApplicationRiskContext = useGetAzureWebApplicationRiskContext();

    const webApplicationTypeTranslator = useAzureWebApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureWebApplicationScmInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{applicationType}} {{application}} allows public access to the SCM site",
                sections: {
                    resolution: {
                        step1: "Under **Inbound Traffic**, click on **Access Restrictions**",
                        step2: "Select the **Advanced Tool Site** tab",
                        step3: "Change the **Unmatched rule action** setting to **Deny**, and limit each public rule to only the specific IP addresses that need access",
                        step4: "Click **Save** to confirm"
                    },
                    scmNetworkRules: "SCM Access Restrictions"
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            application:
                <Entity
                    entityIdOrModel={applicationModel}
                    variant="text"/>,
            applicationType: webApplicationTypeTranslator(application.type)
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.NetworkingHub}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const applicationRiskContext = getAzureWebApplicationRiskContext(applicationModel);
            return [
                applicationRiskContext.generalInformation,
                applicationRiskContext.sensitive,
                applicationRiskContext.managedIdentities,
                applicationRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AzureWebApplicationNetworkRulesTable
                        getHighlightColor={
                            (networkRule: Contract.AzureWebApplicationNetworkRule, opacity) =>
                                _.some(
                                    application.wideRangeScmNetworkRules,
                                    wideRangeNetworkRule =>
                                        !_.isNil(networkRule.sourceSubnets) &&
                                        networkRule.sourceSubnets.length === wideRangeNetworkRule.sourceSubnets!.length &&
                                        _.every(
                                            networkRule.sourceSubnets,
                                            sourceSubnet =>
                                                _.indexOf(
                                                    wideRangeNetworkRule.sourceSubnets,
                                                    sourceSubnet) > -1))
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        : theme.palette.severity(riskModel.risk.severity)
                                    : undefined}
                        networkRules={application.scmNetworkRules!}/>,
                    localization.sections.scmNetworkRules(),
                    {
                        expandable: true
                    })
            ]
        });
}