import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsIamPolicyDocument } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsOrganizationsPolicyDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const policy = resourceModel.entity as Contract.AwsOrganizationsPolicy;
    const policyModel = resourceModel as Contract.AwsOrganizationsPolicyModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsOrganizationsPolicyDefinition",
            () => ({
                info: {
                    items: {
                        attachedAccountTenantIdReferences: "Targets",
                        awsManaged: {
                            false: "Customer Managed",
                            title: "Management",
                            true: "AWS Managed"
                        },
                        description: "Description",
                        organizationRawIds: "Organization ID"
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument policyDocument={policy.policyDocument}/>,
                localization.tabs.policyDocument(),
                "policyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="awsManaged"
                        title={localization.info.items.awsManaged.title()}
                        value={
                            policy.awsManaged
                                ? localization.info.items.awsManaged.true()
                                : localization.info.items.awsManaged.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={policyModel.organizationRawIds}
                        entityTypeName={Contract.TypeNames.AwsOrganizationsOrganization}
                        key="organizationRawIds"
                        title={localization.info.items.organizationRawIds()}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={policy.description}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={policyModel.attachedAccountTenantIdReferences}
                        entityTypeName={Contract.TypeNames.AwsTenantEntity}
                        key="attachedAccountTenantIdReferences"
                        location="all"
                        title={localization.info.items.attachedAccountTenantIdReferences()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}