﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetAwsEmrClustersRiskContext() {
    return useMemo(
        () => useAwsEmrClustersRiskContext,
        []);
}

function useAwsEmrClustersRiskContext(clusterIdsOrModels: string[] | Contract.AwsEmrClusterModel[]) {
    return useGetEntitiesRiskContext()(
        clusterIdsOrModels,
        Contract.TypeNames.AwsEmrCluster);
}