import { Loading, SearchTextField, VerticalFillGrid } from "@infrastructure";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import React, { ReactNode, useState } from "react";

type ItemsSearchLayoutProps = {
    children: (searchText?: string) => ReactNode;
    searchPlaceholder: string;
    title: ReactNode;
    variant?: "card" | "dialog";
};

export function ItemsSearchLayout({ children: renderItems, searchPlaceholder, title, variant = "card" }: ItemsSearchLayoutProps) {
    const [searchText, setSearchText] = useState<string>();
    const theme = useTheme();
    return (
        <VerticalFillGrid>
            <Stack
                alignItems="center"
                direction="row"
                sx={{
                    minHeight: theme.spacing(12),
                    padding:
                        variant === "dialog"
                            ? theme.spacing(2, 6, 2, 2)
                            : theme.spacing(2)
                }}>
                <Typography
                    sx={{
                        flex: 1,
                        minWidth: theme.spacing(14)
                    }}
                    variant={
                        variant === "dialog"
                            ? "h2"
                            : "h4"}>
                    {title}
                </Typography>
                <SearchTextField
                    fullWidth={false}
                    placeholder={searchPlaceholder}
                    searchText={searchText}
                    variant="underlined"
                    onSearchTextChanged={searchText => setSearchText(searchText)}/>
            </Stack>
            <Divider/>
            <Box
                sx={{
                    height: "100%",
                    overflow: "auto"
                }}>
                <Loading>
                    {renderItems(searchText)}
                </Loading>
            </Box>
        </VerticalFillGrid>);
}