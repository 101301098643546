import { DeferredItems, InlineItems, ItemsPopoverActions } from "@infrastructure";
import { Box, SxProps } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { Contract, Entities, Entity, EntityGlanceOptions, EntityLinkOptions, EntityTypeNameTranslatorOptions, EntityVariant, useEntityTypeNameTranslator, useTheme } from "..";

type InlineEntitiesProps = {
    entityGlanceOptions?: EntityGlanceOptions;
    entityLinkOptions?: EntityLinkOptions;
    entityTypeName: string;
    entityTypeNameTranslatorOptions?: EntityTypeNameTranslatorOptions;
    entityVariant?: EntityVariant;
    namePluralizer?: (count: number) => string;
    sx?: SxProps;
    variant: InlineEntitiesVariant;
} & ({
    deferredEntities?: never;
    entityIdsOrModels: string | string[] | Contract.EntityModel | Contract.EntityModel[];
} | {
    deferredEntities: DeferredEntities;
    entityIdsOrModels?: never;
});

export type DeferredEntities =
    DeferredItems & {
        firstItem?: string;
        getItems: () => Promise<string[]>;
    };

export type InlineEntitiesVariant = "itemCount" | "itemCountAndType" | "itemOrItemCountAndType" | "itemAndTypeOrItemCountAndType" | "itemPlusItemCount" | "text";

export function InlineEntities({ deferredEntities, entityGlanceOptions, entityIdsOrModels, entityLinkOptions, entityTypeName, entityTypeNameTranslatorOptions, entityVariant = "iconText", namePluralizer, sx, variant }: InlineEntitiesProps) {
    const inlineItemsProps =
        !_.isNil(deferredEntities)
            ? { deferredItems: deferredEntities }
            : { items: entityIdsOrModels };
    const itemsPopoverActionsRef = useRef<ItemsPopoverActions>(null);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const theme = useTheme();
    return (
        <InlineItems
            {...inlineItemsProps}
            itemsPopover={
                (entityIdsOrModels: string[] | Contract.EntityModel[]) =>
                    <Box sx={{ maxWidth: theme.spacing(60) }}>
                        <Entities
                            entityGlanceOptions={entityGlanceOptions}
                            entityIdsOrModels={entityIdsOrModels}
                            entityLinkOptions={entityLinkOptions}
                            entityTypeName={entityTypeName}
                            entityVariant={entityVariant}
                            searchEnabled={true}
                            variant="dropdown"
                            onEntityClick={()=> itemsPopoverActionsRef.current?.close()}/>
                    </Box>}
            itemsPopoverActionsRef={itemsPopoverActionsRef}
            namePluralizer={
                namePluralizer ??
                (entityCount =>
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            count: entityCount,
                            includeCount: true,
                            includeServiceName: false,
                            variant: "text",
                            ...entityTypeNameTranslatorOptions
                        }))}
            sx={sx}
            variant={
                variant === "itemAndTypeOrItemCountAndType"
                    ? "itemOrItemCountAndType"
                    : variant}>
            {(entityIdOrModel: string | Contract.EntityModel, inline) =>
                <Entity
                    entityIdOrModel={entityIdOrModel}
                    entityTypeNameTranslatorOptions={entityTypeNameTranslatorOptions}
                    glanceOptions={{
                        disabled: variant === "text",
                        ...entityGlanceOptions
                    }}
                    linkOptions={{
                        disabled: variant === "text",
                        ...entityLinkOptions
                    }}
                    sxOptions={{ title: sx }}
                    variant={
                        variant === "itemAndTypeOrItemCountAndType" && inline
                            ? "typeText"
                            : variant === "itemPlusItemCount"
                                ? entityVariant
                                : "text"}/>}
        </InlineItems>);
}