import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useDataAnalysisSensitivityTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useDataAnalysisSensitivityTranslator",
            () => ({
                [Contract.TypeNames.DataAnalysisSensitivity]: {
                    text: {
                        [Contract.DataAnalysisSensitivity.Restricted]: "restricted",
                        [Contract.DataAnalysisSensitivity.Proprietary]: "proprietary",
                        [Contract.DataAnalysisSensitivity.NonSensitive]: "none",
                        [Contract.DataAnalysisSensitivity.Private]: "private",
                        [Contract.DataAnalysisSensitivity.Public]: "public",
                        [Contract.DataAnalysisSensitivity.Confidential]: "confidential"
                    },
                    title: {
                        [Contract.DataAnalysisSensitivity.Restricted]: "Restricted",
                        [Contract.DataAnalysisSensitivity.Proprietary]: "Proprietary",
                        [Contract.DataAnalysisSensitivity.NonSensitive]: "None",
                        [Contract.DataAnalysisSensitivity.Private]: "Private",
                        [Contract.DataAnalysisSensitivity.Public]: "Public",
                        [Contract.DataAnalysisSensitivity.Confidential]: "Confidential"
                    }
                }
            }));
    return (sensitivity: Contract.DataAnalysisSensitivity, variant: "text" | "title" = "title") =>
        localization[Contract.TypeNames.DataAnalysisSensitivity][variant][sensitivity]();
}