import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzurePostgreSqlServerStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzurePostgreSqlServerStatusTranslator",
            () => ({
                [Contract.TypeNames.AzurePostgreSqlServerStatus]: {
                    [Contract.AzurePostgreSqlServerStatus.Disabled]: "Disabled",
                    [Contract.AzurePostgreSqlServerStatus.Dropping]: "Dropping",
                    [Contract.AzurePostgreSqlServerStatus.Inaccessible]: "Inaccessible",
                    [Contract.AzurePostgreSqlServerStatus.Provisioning]: "Provisioning",
                    [Contract.AzurePostgreSqlServerStatus.Ready]: "Ready",
                    [Contract.AzurePostgreSqlServerStatus.Restarting]: "Restarting",
                    [Contract.AzurePostgreSqlServerStatus.Starting]: "Starting",
                    [Contract.AzurePostgreSqlServerStatus.Stopped]: "Stopped",
                    [Contract.AzurePostgreSqlServerStatus.Stopping]: "Stopping",
                    [Contract.AzurePostgreSqlServerStatus.Updating]: "Updating"
                }
            }));

    return (status: Contract.AzurePostgreSqlServerStatus) =>
        localization[Contract.TypeNames.AzurePostgreSqlServerStatus][status]();
}