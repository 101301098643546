import { StringHelper, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, RadioGroup, useTheme } from "../../../../..";
import { EntityProfileInfo } from "../../../../../../views/Customer/components/Entities/components/Profile/components";
import { useChangeInfoContext } from "../ChangeInfo";

export function AwsDisableUserAccessKeyChangeInfo() {
    const { change, updateChange, updatedChange } = useChangeInfoContext();
    const disableUserAccessKeyChange = change as Contract.AwsDisableUserAccessKeyChange;
    const updatedDisableUserAccessKeyChange = updatedChange as Contract.AwsDisableUserAccessKeyChange;

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.changeInfo.awsDisableUserAccessKeyChangeInfo",
            () => ({
                fields: {
                    remove: {
                        false: "Disable",
                        title: "Disable or Delete?",
                        true: "Delete"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <Stack sx={{ height: "100%" }}>
            <Box
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <EntityProfileInfo entityId={disableUserAccessKeyChange.resourceId}/>
            </Box>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{
                    borderBottom: theme.border.alternate,
                    padding: theme.spacing(2)
                }}>
                <Typography>
                    {localization.fields.remove.title()}
                </Typography>
                <RadioGroup
                    items={[
                        {
                            label: localization.fields.remove.false(),
                            value: false
                        },
                        {
                            label: localization.fields.remove.true(),
                            value: true
                        }
                    ]}
                    selectedValue={updatedDisableUserAccessKeyChange.deleteAccessKey}
                    onChange={value => updateChange(updatedChange => (updatedChange as Contract.AwsDisableUserAccessKeyChange).deleteAccessKey = StringHelper.isTrue(value))}/>
            </Stack>
        </Stack>);
}