import { CustomerConsoleAppView } from "../../../common/controllers/types.generated";

export const CustomerView = {
    [CustomerConsoleAppView.Access]: "access",
    [CustomerConsoleAppView.Administration]: "administration",
    [CustomerConsoleAppView.AuditEvents]: "auditEvents",
    [CustomerConsoleAppView.Code]: "code",
    [CustomerConsoleAppView.Compliance]: "compliance",
    [CustomerConsoleAppView.Configuration]: "configuration",
    [CustomerConsoleAppView.Customers]: "customers",
    [CustomerConsoleAppView.Dashboard]: "dashboard",
    [CustomerConsoleAppView.DataAnalysis]: "dataAnalysis",
    [CustomerConsoleAppView.Docs]: "docs",
    [CustomerConsoleAppView.Entities]: "entities",
    [CustomerConsoleAppView.Events]: "events",
    [CustomerConsoleAppView.Graphql]: "graphql",
    [CustomerConsoleAppView.Kubernetes]: "kubernetes",
    [CustomerConsoleAppView.Reports]: "reports",
    [CustomerConsoleAppView.RiskPolicies]: "riskPolicies",
    [CustomerConsoleAppView.Risks]: "risks",
    [CustomerConsoleAppView.SignIn]: "signIn",
    [CustomerConsoleAppView.SignInError]: "signInError",
    [CustomerConsoleAppView.Scopes]: "scopes",
    [CustomerConsoleAppView.UdmObjects]: "udmObjects",
    [CustomerConsoleAppView.WorkloadAnalysis]: "workloadAnalysis"
};