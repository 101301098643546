﻿import { useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { useEnabledTranslator } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType, useEntityTypeNameTranslator } from "../../../../../../../common";
import { EnabledRadioGroup } from "../components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

export function useAadCommonDirectoryIdentityInactiveRiskPolicyConfigurationDefinition(identityTypeName: string, riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const enabledTranslator = useEnabledTranslator();

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useAadCommonDirectoryIdentityInactiveRiskPolicyConfigurationDefinition",
            () => ({
                description: "Choose whether or not to create findings when the {{translatedIdentityTypeName}} is disabled.",
                title: "Disabled Identities"
            }));

    const getEnabled =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.AadDirectoryIdentityInactiveRiskPolicyConfiguration).includeDisabledIdentity,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <EnabledRadioGroup
                    description={
                        localization.description({
                            translatedIdentityTypeName:
                                entityTypeNameTranslator(
                                    identityTypeName,
                                    {
                                        includeServiceName: false,
                                        variant: "text"
                                    })
                        })}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    getEnabled={getEnabled}
                    key={`${Contract.AadDirectoryIdentityInactiveRiskPolicyConfiguration}-disabledIdentityEnabled`}
                    riskPoliciesType={riskPoliciesType}
                    setEnabled={
                        (riskPolicyConfiguration, includeDisabledIdentity) => {
                            (riskPolicyConfiguration as Contract.AadDirectoryIdentityInactiveRiskPolicyConfiguration).includeDisabledIdentity = includeDisabledIdentity;
                        }}
                    title={localization.title()}/>,
            viewItem: {
                getValue: riskPolicyConfiguration => enabledTranslator(getEnabled(riskPolicyConfiguration)),
                title: localization.title()
            }
        }]
    };
}