import { Optional, TimeSpanFormatter, TimeSpanHelper } from "@infrastructure";
import React from "react";
import { ViewOnlyNumeric } from "..";
import { Contract, RiskPoliciesType, RiskPolicyItem } from "../../../../../../../../common";

type ViewOnlyTimeFrameProps = {
    getTimeFrame: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => Optional<string>;
    item: RiskPolicyItem;
    riskPoliciesType: RiskPoliciesType;
    scopeId: string;
};

export function ViewOnlyTimeFrame({ getTimeFrame, item, riskPoliciesType, scopeId }: ViewOnlyTimeFrameProps) {

    return (
        <ViewOnlyNumeric
            fromNumber={TimeSpanHelper.fromDays}
            getValue={getTimeFrame}
            item={item}
            riskPoliciesType={riskPoliciesType}
            scopeId={scopeId}
            toNumber={TimeSpanHelper.getDays}
            translateValue={TimeSpanFormatter.day}/>);
}