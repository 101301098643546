import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../..";
import { WelcomeImage } from "./images";

export function Welcome() {
    const localization =
        useLocalization(
            "common.udmObjectTable.welcome",
            () => ({
                subtitle: "Use one of the presets below, or start fresh using the query builder",
                title: "Explore your data"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <WelcomeImage/>
            <Typography
                sx={{ fontWeight: 600 }}
                variant="h1">
                {localization.title()}
            </Typography>
            <Typography sx={{ color: theme.palette.text.secondary }}>
                {localization.subtitle()}
            </Typography>
        </Stack>);
}