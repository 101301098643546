import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciObjectStorageBucketLogCategoryTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciObjectStorageBucketLogCategoryTranslator",
            () => ({
                [Contract.TypeNames.OciObjectStorageBucketLogCategory]: {
                    [Contract.OciObjectStorageBucketLogCategory.All]: "Enabled (Read/Write)",
                    [Contract.OciObjectStorageBucketLogCategory.None]: "Disabled",
                    [Contract.OciObjectStorageBucketLogCategory.Read]: "Enabled (Read)",
                    [Contract.OciObjectStorageBucketLogCategory.Write]: "Enabled (Write)"
                }
            }));
    return (objectLevelLogging: Contract.OciObjectStorageBucketLogCategory) =>
        localization[Contract.TypeNames.OciObjectStorageBucketLogCategory][objectLevelLogging]();
}