import { Function0 } from "lodash";
import React, { useCallback } from "react";
import { useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, RiskTypeGroups, useRiskPolicyTitleTranslator, WidgetDefinition } from "../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { TopRisks } from "../components";

export function useGetTopRiskTypesDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const getRisksRelativeUrl =
        useCallback(
            (riskPolicyConfigurationTypeNameOrId: string, severity?: Contract.Severity) =>
                CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                    Contract.RisksView.Open,
                    {
                        policyConfigurationTypeNameOrIds: [riskPolicyConfigurationTypeNameOrId],
                        severity
                    },
                    RiskTypeGroups.RiskPolicyType),
            []);

    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const riskIdToElementMapper =
        useCallback(
            (riskPolicyConfigurationTypeNameOrId: string) => riskPolicyTitleTranslator(riskPolicyConfigurationTypeNameOrId),
            [riskPolicyTitleTranslator]);

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetTopRiskTypesDefinition",
            () => ({
                helpText: "View the highest risk finding types, with a breakdown of the number of findings per severity level. Click on a finding type or a specific number to assess and remediate the findings.",
                title: "Top Findings"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: (
                <TopRisks
                    getRisksRelativeUrl={getRisksRelativeUrl}
                    itemToRiskSeverityToRiskCount={summary.topRiskPolicyIdentifierToSeverityToRiskCountMap}>
                    {riskIdToElementMapper}
                </TopRisks>),
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}