﻿import { ActionMenuItem, Menu, useLocalization, useSelectionActionsContext } from "@infrastructure";
import { Box } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { Contract, RiskController, RiskType, ScopeHelper, scopeSystemEntityModelStore, SelectionActionButton, ServiceNowIcon, ticketingServiceTicketStore, UpsertServiceNowIncidentDialog } from "..";

export type UpdateServiceNowIncidentsSelectionActionProps = {
    getItemIncidentUpdateCount: (item: any) => number;
    getRiskIdToServiceNowIncidentIdsMap: (item: any) => Dictionary<string[]>;
    getSelectedRiskIdToScopeIdsMap: () => Dictionary<string[]>;
    reloadItems: () => Promise<void>;
    riskType: RiskType;
};

export function UpdateServiceNowIncidentsSelectionAction({ getItemIncidentUpdateCount, getRiskIdToServiceNowIncidentIdsMap, getSelectedRiskIdToScopeIdsMap, reloadItems, riskType }: UpdateServiceNowIncidentsSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds, loadedItems } = useSelectionActionsContext();
    const selectedRiskIdToScopeIdsMap = getSelectedRiskIdToScopeIdsMap();
    const instanceModels =
        ScopeHelper.getScopesParentScopeSystemEntityModelsIntersection(
            _.values(selectedRiskIdToScopeIdsMap),
            scopeSystemEntityModelStore.useGetServiceNow());

    const updateCount =
        useMemo(
            () => {
                if (loadedItems.length !== itemIds.length) {
                    return undefined;
                }

                return _(loadedItems).
                    map(item => getItemIncidentUpdateCount(item)).
                    sum();
            },
            [itemIds, loadedItems]);

    const localization =
        useLocalization(
            "common.updateServiceNowIncidentsSelectionAction",
            () => ({
                title: {
                    empty: "There are no incidents to update",
                    selectUpdate: "Update ServiceNow incidents of instance:"
                },
                update: [
                    "Update ServiceNow incident",
                    "Update ServiceNow incidents"
                ]
            }));

    const serviceNowIncidentMap =
        ticketingServiceTicketStore.useGetServiceNowIncidentMap(
            _(getRiskIdToServiceNowIncidentIdsMap(loadedItems)).
                values().
                flatMap().
                uniq().
                value());

    const [riskIdToServiceNowIncidentIssueMap, singleSelectedServiceNowInstanceId] =
        useMemo(
            () => {
                if (loadedItems.length !== itemIds.length) {
                    return [undefined, undefined];
                }
                const riskIdToServiceNowIncidentsMap =
                    _(getRiskIdToServiceNowIncidentIdsMap(loadedItems)).
                        mapValues(
                            incidentIds =>
                                _(incidentIds).
                                    map(incidentId => serviceNowIncidentMap![incidentId]).
                                    filter(incident => !incident.multipleRisks).
                                    value()).
                        pickBy(incidents => !_.isEmpty(incidents)).
                        value();
                if (_(riskIdToServiceNowIncidentsMap).
                    values().
                    flatMap().
                    uniqBy(serviceNowIncident => serviceNowIncident.instanceId).
                    size() !== 1) {
                    return [riskIdToServiceNowIncidentsMap, undefined];
                }

                return [riskIdToServiceNowIncidentsMap, _.values(riskIdToServiceNowIncidentsMap)[0][0].instanceId];
            },
            [itemIds, loadedItems]);

    const [open, setOpen] = useState(false);
    const [instanceId, setInstanceId] = useState<string>();
    const [riskIdToIncidentIdMap, setRiskIdToIncidentIdsMap] = useState<Dictionary<string[]>>({});
    return (
        !_.isEmpty(instanceModels) &&
        (loadedItems.length !== itemIds.length ||
            !_.isEmpty(riskIdToServiceNowIncidentIssueMap))
            ? <Box sx={{ height: "100%" }}>
                {open &&
                    <UpsertServiceNowIncidentDialog
                        instanceId={instanceId}
                        instanceModels={instanceModels}
                        riskIdToIncidentIdsMap={riskIdToIncidentIdMap}
                        riskType={riskType}
                        onClose={
                            async successRiskIds => {
                                if (!_.isEmpty(successRiskIds)) {
                                    await reloadItems();
                                }
                                actionEnded();
                                setOpen(false);
                            }}/>}
                <Menu
                    disabled={!_.isNil(singleSelectedServiceNowInstanceId)}
                    itemsOrGetItems={
                        async () => {
                            let multiSelectedRiskIdToServiceNowIncidentsMap = riskIdToServiceNowIncidentIssueMap;
                            if (_.isNil(multiSelectedRiskIdToServiceNowIncidentsMap)) {
                                const getRiskIdToServiceNowIncidentsMapResponse = await RiskController.getRiskIdToServiceNowIncidentsMap(new Contract.RiskControllerGetRiskIdToServiceNowIncidentsMapRequest(_.keys(selectedRiskIdToScopeIdsMap)));
                                multiSelectedRiskIdToServiceNowIncidentsMap = getRiskIdToServiceNowIncidentsMapResponse.riskIdToServiceNowIncidentsMap;
                            }
                            const instanceIdToRiskIdToIncidentIdsMap =
                                _(multiSelectedRiskIdToServiceNowIncidentsMap).
                                    flatMap(
                                        (serviceNowIncidents, riskId) =>
                                            _.map(
                                                serviceNowIncidents,
                                                serviceNowIncident => ({
                                                    id: serviceNowIncident.id,
                                                    instanceId: serviceNowIncident.instanceId,
                                                    riskId
                                                })
                                            )).
                                    groupBy(serviceNowIncidentData => serviceNowIncidentData.instanceId).
                                    mapValues(
                                        serviceNowIncidentDatas =>
                                            _(serviceNowIncidentDatas).
                                                groupBy(serviceNowIncidentData => serviceNowIncidentData.riskId).
                                                mapValues(
                                                    serviceNowIncidentDatas =>
                                                        _.map(
                                                            serviceNowIncidentDatas,
                                                            serviceNowIncidentData => serviceNowIncidentData.id)).
                                                value()).
                                    value();


                            return _.map(
                                instanceIdToRiskIdToIncidentIdsMap,
                                (riskIdToIncidentIdsMap, instanceId) =>
                                    new ActionMenuItem(
                                        () => {
                                            setRiskIdToIncidentIdsMap(riskIdToIncidentIdsMap);
                                            setInstanceId(instanceId);
                                            setOpen(true);
                                        },
                                        (_.find(
                                            instanceModels,
                                            instanceModel => instanceModel?.configuration.id == instanceId)!.configuration as Contract.ServiceNowInstanceConfiguration).name));
                        }}
                    sx={{ height: "100%" }}
                    titleOptions={{
                        titleOrGetTitle:
                            (items: any[]) =>
                                _.isEmpty(items)
                                    ? localization.title.empty()
                                    : localization.title.selectUpdate()
                    }}
                    variant="topCenter">
                    <SelectionActionButton
                        startIcon={
                            <ServiceNowIcon
                                sx={{
                                    opacity:
                                        actionExecuting
                                            ? 0.4
                                            : 1
                                }}/>}
                        onClick={
                            () => {
                                if (!_.isNil(singleSelectedServiceNowInstanceId)) {
                                    setRiskIdToIncidentIdsMap(
                                        _(riskIdToServiceNowIncidentIssueMap).
                                            mapValues(
                                                serviceNowIncidents =>
                                                    _.map(
                                                        serviceNowIncidents,
                                                        serviceNowIncident => serviceNowIncident.id)).
                                            value());
                                    setInstanceId(singleSelectedServiceNowInstanceId);
                                    setOpen(true);
                                    actionStarted(true);
                                }
                            }}>
                        {localization.update(updateCount ?? 0)}
                    </SelectionActionButton>
                </Menu>
            </Box>
            : <Fragment/>);
}