import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useOciComputeInstanceStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciComputeInstanceRiskContext() {
    return useMemo(
        () => useOciComputeInstanceRiskContext,
        []);
}

function useOciComputeInstanceRiskContext(instanceModel: Contract.OciComputeInstanceModel) {
    const instance = instanceModel.entity as Contract.OciComputeInstance;
    const resourceRiskContext = useGetOciResourceRiskContext()(instanceModel);

    const instanceStatusTranslator = useOciComputeInstanceStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciComputeInstanceRiskContext",
            () => ({
                status: "The {{translatedInstanceTypeName}} state is **{{status}}**"
            }));
    const translatedInstanceTypeName =
        entityTypeNameTranslator(
            instance.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: instanceStatusTranslator(instance.status),
                    translatedInstanceTypeName
                }))
    };
}