﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpComputeImageRiskContext() {
    return useMemo(
        () => useGcpComputeImageRiskContext,
        []);
}

function useGcpComputeImageRiskContext(imageModel: Contract.GcpComputeImageModel) {
    return useGetGcpEntityRiskContext()(imageModel);
}