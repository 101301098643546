import { Link, Localization, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper } from "../../../../../../../common";

type SettingDescriptionLocalization = {
    description: { [key in Contract.ScopeSettingConfigurationDerivedTypeNames]: any };
    learnMore: string;
    title: { [key in Contract.ScopeSettingConfigurationDerivedTypeNames]: string };
};

export function useScopeSettingConfigurationDerivedTypeNameTranslator() {
    const localization: Localization<SettingDescriptionLocalization> =
        useLocalization(
            "views.customer.configuration.settingConfigurationTable.hooks.useScopeSettingConfigurationDerivedTypeNameTranslator",
            () => ({
                description: {
                    [Contract.ScopeSettingConfigurationDerivedTypeNames.AadSeverePermissionDirectoryRoleDefinitionsConfiguration]: "Microsoft Entra ID role that will be considered privileged. {{ learnMore }}",
                    [Contract.ScopeSettingConfigurationDerivedTypeNames.ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration]: "Changing the time frame impacts findings, labels, and other system behavior.\nReview the documentation to fully understand the implications before proceeding. {{ learnMore }}",
                    [Contract.ScopeSettingConfigurationDerivedTypeNames.UserExcessivePermissionEvaluationTimeFrameConfiguration]: "Changing the time frame impacts findings, labels, and other system behavior.\nReview the documentation to fully understand the implications before proceeding. {{ learnMore }}"
                },
                learnMore: "Learn more",
                title: {
                    [Contract.ScopeSettingConfigurationDerivedTypeNames.AadSeverePermissionDirectoryRoleDefinitionsConfiguration]: "Microsoft Entra ID privileged roles",
                    [Contract.ScopeSettingConfigurationDerivedTypeNames.ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration]: "Service identity permission usage learning period",
                    [Contract.ScopeSettingConfigurationDerivedTypeNames.UserExcessivePermissionEvaluationTimeFrameConfiguration]: "User permission usage learning period"
                }
            }));

    return (settingType: Contract.ScopeSettingConfigurationDerivedTypeNames) => ({
        description:
            localization.description.translate(
                settingType,
                {
                    learnMore:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsSettingsConfiguration(settingType)}
                            variant="external">
                            {localization.learnMore()}
                        </Link>
                }),
        title: localization.title[settingType]()
    });
}