import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingNatGatewayStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingNatGatewayStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingNatGatewayStatus]: {
                    [Contract.OciNetworkingNatGatewayStatus.Available]: "Available",
                    [Contract.OciNetworkingNatGatewayStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingNatGatewayStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingNatGatewayStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciNetworkingNatGatewayStatus) =>
        localization[Contract.TypeNames.OciNetworkingNatGatewayStatus][status]();
}