import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzurePostgreSqlServerRiskContext } from "../contexts";

export function useAzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const serverAadOnlyAuthenticationDisabledRiskModel = riskModel as Contract.AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel;
    const risk = riskModel.risk as Contract.AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzurePostgreSqlFlexibleServerModel;
    const server = serverModel.entity as Contract.AzurePostgreSqlFlexibleServer;

    const getAzurePostgreSqlServerRiskContext = useGetAzurePostgreSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskDefinition",
            () => ({
                description: "{{server}} authentication method is not Microsoft Entra ID-only",
                sections: {
                    resolution: {
                        step1: {
                            link: "Use Microsoft Microsoft Entra ID for authentication with Azure Database for PostgreSQL - Flexible Server",
                            text: "Before disabling PostgreSQL authentication, verify that doing so will not break any existing applications. For more information, see {{documentationLink}}"
                        },
                        step2: "Under **Assign access to**, switch to **Microsoft Entra authentication only**",
                        step3: "In the **Microsoft Entra Admins** section, choose a valid Microsoft Entra ID user or an Microsoft Entra ID group in the customer tenant to be an Microsoft Entra ID administrator",
                        step4: "Click the **Save** button on top to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={`${risk.entityId}-${Contract.AzureConsoleEntityPage.ActiveDirectoryAdmins}`}
                page={Contract.AzureConsoleEntityPage.ActiveDirectoryAdmins}/>,
            localization.sections.resolution.step1.text({
                documentationLink:
                    <Link
                        urlOrGetUrl={serverAadOnlyAuthenticationDisabledRiskModel.aadAuthenticationDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            _.isEmpty(server.aadAuthenticationAdministratorPrincipalIdReferences)
                ? localization.sections.resolution.step3()
                : undefined,
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzurePostgreSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.authenticationType,
                serverRiskContext.status,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}