import { useEffect } from "react";
import { useUncaptureValue } from "./useUncaptureValue";

export function useWindowEventEffect(
    eventType: string,
    effect: (...args: any[]) => void) {
    const uncaptureEffect = useUncaptureValue(effect);
    return useEffect(
        () => {
            const eventHandler =
                (...args: any[]) => {
                    uncaptureEffect(effect => effect(...args));
                };

            window.addEventListener(eventType, eventHandler);
            return () => window.removeEventListener(eventType, eventHandler);
        },
        []);
}