﻿import { TimeSpanHelper } from "@infrastructure";
import _ from "lodash";
import React, { ReactElement } from "react";
import { Contract, ScopeHelper, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { Approval, Description, ExpirationTimeFrame, GranteePrincipals, Name, PermissionRequestScopes, PrincipalTenant, Reason } from "../../components";
import { TenantDefinition } from "../../useDefinition";
import { PermissionEligibilityData } from "../../utilities";
import { Roles } from "./components";
import { GcpRoleBindingEligibilityData } from "./utilities";

export function useGcpDefinition(): TenantDefinition {
    const { scopeNodeModel } = useScopeNavigationViewContext();

    function createInsertPermissionEligibilityRequest(permissionEligibilityData: PermissionEligibilityData): Contract.ConfigurationControllerInsertPermissionEligibilityRequest {
        const approval = permissionEligibilityData.approval.createConfigurationControllerPermissionEligibilityApproval();
        const { description, expirationTimeFrameHours, granteePrincipalIds, name, permissionRequestScopeIds, principalTenantId, reasonRequired, roleIds, scopeId } = permissionEligibilityData as GcpRoleBindingEligibilityData;
        return new Contract.ConfigurationControllerInsertGcpRoleBindingEligibilityRequest(
            approval,
            description,
            TimeSpanHelper.fromHours(expirationTimeFrameHours),
            granteePrincipalIds,
            name,
            principalTenantId!,
            reasonRequired,
            scopeId,
            _.isEmpty(permissionRequestScopeIds)
                ? [scopeId]
                : permissionRequestScopeIds,
            roleIds);
    }

    function createUpdatePermissionEligibilityRequest(permissionEligibilityData: PermissionEligibilityData, permissionEligibilityId: string): Contract.ConfigurationControllerUpdatePermissionEligibilityRequest {
        const approval = permissionEligibilityData.approval.createConfigurationControllerPermissionEligibilityApproval();
        const { description, expirationTimeFrameHours, granteePrincipalIds, name, permissionRequestScopeIds, principalTenantId, reasonRequired, roleIds, scopeId } = permissionEligibilityData as GcpRoleBindingEligibilityData;
        return new Contract.ConfigurationControllerUpdateGcpRoleBindingEligibilityRequest(
            approval,
            description,
            TimeSpanHelper.fromHours(expirationTimeFrameHours),
            granteePrincipalIds,
            permissionEligibilityId,
            name,
            principalTenantId!,
            reasonRequired,
            _.isEmpty(permissionRequestScopeIds)
                ? [scopeId]
                : permissionRequestScopeIds,
            roleIds);
    }

    return new TenantDefinition(
        createInsertPermissionEligibilityRequest,
        (permissionEligibilityModel, scopeId) =>
            new GcpRoleBindingEligibilityData(
                permissionEligibilityModel,
                scopeId),
        createUpdatePermissionEligibilityRequest,
        _<ReactElement>([]).
            concat(
                <Name/>,
                <Description/>,
                <PrincipalTenant/>,
                <GranteePrincipals/>,
                <Roles/>).
            concatIf(
                ScopeHelper.isFolder(scopeNodeModel),
                () => <PermissionRequestScopes/>).
            concat(
                <ExpirationTimeFrame/>,
                <Approval/>,
                <Reason/>).
            value());
}