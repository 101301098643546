import { InfoIcon, map, SuccessIcon, Tooltip } from "@infrastructure";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Box, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, ReactNode } from "react";

export type MessageProps = {
    description?: string;
    disabled?: boolean;
    level: MessageLevel;
    sx?: SxProps;
    title: string | ReactNode;
    variant?: "contained" | "highlight" | "minimal" | "standard";
};

export function Message({ description, disabled, level, sx, title, variant = "standard" }: MessageProps) {
    const theme = useTheme();
    const { backgroundColor, border, iconColor, iconElement } =
        map<MessageLevel, MessageStyle>(
            level,
            {
                "error":
                    () => ({
                        backgroundColor: "rgba(239, 83, 80, 0.1)",
                        border: `solid 1px ${theme.palette.error.main}`,
                        iconColor: theme.palette.error.main,
                        iconElement: <ErrorIcon/>
                    }),
                "info":
                    () => ({
                        border: `solid 1px ${theme.palette.text.secondary}`,
                        iconColor: theme.palette.text.primary,
                        iconElement: <InfoIcon/>
                    }),
                "infoWarning":
                    () => ({
                        border: `solid 1px ${theme.palette.warning.main}`,
                        iconColor: theme.palette.warning.main,
                        iconElement: <InfoIcon/>
                    }),
                "success":
                    () => ({
                        border: `solid 1px ${theme.palette.success.main}`,
                        iconColor: theme.palette.success.main,
                        iconElement: <SuccessIcon/>
                    }),
                "warning":
                    () => ({
                        border: `solid 1px ${theme.palette.warning.main}`,
                        iconColor: theme.palette.warning.main,
                        iconElement: <ErrorIcon/>
                    })
            });
    const color =
        disabled
            ? theme.palette.text.secondary
            : iconColor;
    return variant === "minimal"
        ? <Box
            sx={{
                color,
                fontSize: "18px",
                ...sx
            }}
            onClick={event => event.stopPropagation()}>
            <Tooltip
                disabled={disabled}
                titleOrGetTitle={title}>
                {iconElement}
            </Tooltip>
        </Box>
        : <Stack
            sx={{
                ...(variant === "contained"
                    ? {
                        backgroundColor: theme.palette.background.paper,
                        border,
                        padding: theme.spacing(1, 2)
                    }
                    : variant === "highlight"
                        ? {
                            backgroundColor,
                            padding: theme.spacing(2)
                        }
                        : undefined),
                ...sx
            }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Box
                    sx={{
                        color,
                        fontSize:
                            variant === "highlight"
                                ? "22px"
                                : "18px"
                    }}>
                    {iconElement}
                </Box>
                <Box sx={{ color }}>
                    {_.isString(title)
                        ? <Typography sx={{ color: "unset" }}>
                            {title}
                        </Typography>
                        : title}
                </Box>
            </Stack>
            {!_.isNil(description) && variant === "contained" &&
                <Box sx={{ marginTop: theme.spacing(1) }}>
                    {description}
                </Box>}
        </Stack>;
}

export type MessageLevel = "error" | "info" | "infoWarning" | "success" | "warning";

type MessageStyle = {
    backgroundColor?: string;
    border: string;
    iconColor: string;
    iconElement: ReactElement;
};