import { Loading, Optional, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureSqlServerRiskContext } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, Network, useTheme } from "../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../common/components/Network/components";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { EntityExternalConsoleLink, FirewallRulesDetailsSection } from "../../components";

export function useAzureSqlServerWideRangeInboundRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureSqlServerWideRangeInboundRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureSqlServerModel;
    const server = serverModel.entity as Contract.AzureSqlServer;
    const serverNetwork = serverModel.entityNetwork as Optional<Contract.AzureDatabaseResourceStateNetwork>;

    const getAzureSqlServerRiskContext = useGetAzureSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureSqlServerWideRangeInboundRiskDefinition",
            () => ({
                description: "{{server}} network configuration allows wide network inbound access",
                sections: {
                    firewallRules: "Firewall Rules",
                    networkGraph: "Network",
                    resolution: {
                        step1: "Switch **Allow Azure services and resources to access this server** button to **No**",
                        step2: "If any Azure resources require access to the server, add the virtual network to the firewall rules",
                        step3: "For each public IP address rule, edit the address range to restrict it or delete using **Delete** button",
                        step4: "Click **Save** button on top to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            server.allowAnyAzureInternalIpAddress
                ? localization.sections.resolution.step1()
                : undefined,
            localization.sections.resolution.step2(),
            _.isEmpty(serverNetwork?.wideRangeFirewallIpAddressRangeRules)
                ? undefined
                : localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.sensitive,
                serverRiskContext.allowAnyAzureInternalIpAddress,
                serverRiskContext.authenticationType,
                ...serverRiskContext.firewallRuleRiskContext,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections:
                !server.systemDeleted
                    ? _<RiskDefinitionSection>([]).
                        concat(
                            new RiskDefinitionSection(
                                <Box sx={{ minHeight: theme.spacing(20) }}>
                                    <Loading>
                                        <Network
                                            baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(riskModel.id)}
                                            entityId={risk.entityId}
                                            initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                            tenantType={Contract.TenantType.Azure}
                                            variant="risk"/>
                                    </Loading>
                                </Box>,
                                localization.sections.networkGraph(),
                                {
                                    profilePageOnly: true
                                })).
                        concatIf(
                            !_.isEmpty(serverNetwork?.wideRangeFirewallIpAddressRangeRules),
                            () =>
                                new RiskDefinitionSection(
                                    <FirewallRulesDetailsSection
                                        firewallRules={server.firewallIpAddressRangeRules}
                                        getHighlightColor={
                                            (firewallRule: Contract.AzureResourceFirewallIpAddressRangeRule, opacity) =>
                                                _.includes(serverNetwork!.wideRangeFirewallIpAddressRangeRules, firewallRule)
                                                    ? opacity
                                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                                        : theme.palette.severity(riskModel.risk.severity)
                                                    : undefined
                                        }/>,
                                    localization.sections.firewallRules())).
                        value()
                    : undefined
        });
}