﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OktaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M2 12C2 6.47316 6.47316 2 12 2C17.5268 2 22 6.47316 22 12C22 17.5268 17.5268 22 12 22C6.47316 22 2 17.5268 2 12ZM6.99006 12C6.99006 14.7634 9.23658 17.0099 12 17.0099C14.7634 17.0099 17.0099 14.7634 17.0099 12C17.0099 9.23658 14.7634 6.99006 12 6.99006C9.23658 6.99006 6.99006 9.23658 6.99006 12Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}