import { TimeHelper, useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsIamServerCertificateRiskContext() {
    return useMemo(
        () => useAwsIamServerCertificateRiskContext,
        []);
}

function useAwsIamServerCertificateRiskContext(serverCertificateModel: Contract.AwsIamServerCertificateModel) {
    const serverCertificate = serverCertificateModel.entity as Contract.AwsIamServerCertificate;
    const resourceRiskContext = useGetAwsResourceRiskContext()(serverCertificateModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsIamServerCertificateRiskContext",
            () => ({
                creationTime: "The {{translatedServerCertificateTypeName}} was uploaded at **{{creationTime | TimeFormatter.shortDate}}**",
                expirationTime: {
                    active: "The {{translatedServerCertificateTypeName}} will expire in {{expirationTime | TimeFormatter.humanizeDuration}}",
                    expired: "The {{translatedServerCertificateTypeName}} expired **{{expirationTime | TimeFormatter.humanizeDuration}} ago**"
                }
            }));
    const translatedServerCertificateTypeName =
        entityTypeNameTranslator(
            serverCertificate.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...resourceRiskContext,
        creationTime:
            new RiskDefinitionContextItem(
                localization.creationTime({
                    creationTime: serverCertificateModel.creationTime,
                    translatedServerCertificateTypeName
                })),
        expirationTime:
            new RiskDefinitionContextItem(
                TimeHelper.isAfter(serverCertificate.expirationTime, TimeHelper.utcNow())
                    ? localization.expirationTime.active({
                        expirationTime: serverCertificate.expirationTime,
                        translatedServerCertificateTypeName
                    })
                    : localization.expirationTime.expired({
                        expirationTime: serverCertificate.expirationTime,
                        translatedServerCertificateTypeName
                    }))
    };
}