import React, { useMemo, useRef } from "react";
import { CustomRiskPolicyActions, RiskPolicyItem } from "../../../../../../../../../../../common";
import { PolicyTemplate } from "../../../../../components";
import { ConfigurationCategory } from "../../../../../Configuration";
import { RiskPolicyConfigurationDefinition } from "../../../useDefinition";
import { useCloudCommonRiskPolicyDefinition } from "./useCloudCommonRiskPolicyDefinition";

export function useCloudRiskPolicyCustomDefinition(item: RiskPolicyItem, scopeId: string): RiskPolicyConfigurationDefinition {
    const { additionalTabs, generalAdditionalItems } = useCloudCommonRiskPolicyDefinition(item, scopeId);
    const customRiskPolicyActionsRef = useRef<CustomRiskPolicyActions>();

    return useMemo(
        () => ({
            configurationCategoryTabToViewItemMap: {
                [ConfigurationCategory.Template]: {
                    component:
                        () =>
                            <PolicyTemplate
                                actionsRef={customRiskPolicyActionsRef}
                                key={ConfigurationCategory.Template}/>
                },
                ...additionalTabs
            },
            customRiskPolicyActionsRef,
            generalAdditionalItems
        }),
        [additionalTabs, customRiskPolicyActionsRef, generalAdditionalItems]);
}