import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, TimeFormatter, useExecuteOperation, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, Entity, InlineTenants } from "../../../../../../../../../common";

export type AccountAssignmentsInfoCardVariant = "group" | "user";

type AwsSsoPrincipalAccountAssignmentsProps = {
    principalModel: Contract.AwsSsoPrincipalModel;
    roleIdToActivityTimeMap?: Dictionary<string>;
    variant: AccountAssignmentsInfoCardVariant;
};

export function AwsSsoPrincipalAccountAssignments({ principalModel, roleIdToActivityTimeMap, variant }: AwsSsoPrincipalAccountAssignmentsProps) {
    function GetTenantAssignments(
        permissionSetIdToTenantIdToRoleSearchableReferenceMap: Dictionary<Dictionary<Optional<Contract.EntitySearchableReference>>>,
        sourceEntityId: string,
        roleIdToActivityTimeMap?: Dictionary<string>): AwsSsoPrincipalAccountAssignmentsInfoCardItem[] {
        return _.map(
            permissionSetIdToTenantIdToRoleSearchableReferenceMap,
            (tenantIdToRoleSearchableReference, permissionSetId) => {
                const lastActivity =
                    _.isNil(roleIdToActivityTimeMap)
                        ? undefined
                        : _(tenantIdToRoleSearchableReference).
                            values().
                            filter(
                                roleSearchableReference =>
                                    !_.isNil(roleSearchableReference) &&
                                    !_.isNil(roleIdToActivityTimeMap[roleSearchableReference.id])).
                            map(roleSearchableReference => new Date(roleIdToActivityTimeMap[roleSearchableReference!.id])).
                            max();
                return new AwsSsoPrincipalAccountAssignmentsInfoCardItem(
                    _.keys(tenantIdToRoleSearchableReference),
                    permissionSetId,
                    sourceEntityId,
                    lastActivity);
            });
    }

    const [items] =
        useExecuteOperation(
            AwsSsoPrincipalAccountAssignments,
            async () => {
                if (variant === "user") {
                    const userAssignmentItems =
                        GetTenantAssignments(
                            principalModel.assignedPrincipalIdToPermissionSetIdToTenantRawIdToRoleSearchableReferenceMap[principalModel.id] ?? {},
                            principalModel.id,
                            roleIdToActivityTimeMap);

                    const userGroupAssignmentItems =
                        _(principalModel.assignedPrincipalIdToPermissionSetIdToTenantRawIdToRoleSearchableReferenceMap).
                            toPairs().
                            filter(([principalId, _]) => principalId !== principalModel.id).
                            map(([principalId, permissionSetIdToTenantRawIdToRoleSearchableReferenceMap]) =>
                                GetTenantAssignments(
                                    permissionSetIdToTenantRawIdToRoleSearchableReferenceMap,
                                    principalId,
                                    roleIdToActivityTimeMap)).
                            value();

                    return userAssignmentItems.concat(_.flatten(userGroupAssignmentItems));
                } else {
                    return GetTenantAssignments(
                        principalModel.assignedPrincipalIdToPermissionSetIdToTenantRawIdToRoleSearchableReferenceMap[principalModel.id] ?? {},
                        principalModel.id);
                }
            });

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.awsSsoPrincipalAccountAssignments",
            () => ({
                columns: {
                    lastActivity: {
                        empty: "Not Seen",
                        title: "Activity Time"
                    },
                    permissionSetId: "Permission Set",
                    sourceEntityId: "Scope",
                    tenantIds: "Account Assignments"
                },
                emptyMessage: "No Permission Set Account Assignments",
                tenants: [
                    "1 account",
                    "{{count | NumberFormatter.humanize}} accounts"
                ]
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.emptyMessage()) }}
            fetchItems={() => items}
            getItemId={(item: AwsSsoPrincipalAccountAssignmentsInfoCardItem) => item.id}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={AwsSsoPrincipalAccountAssignmentsInfoCardTableColumnId.PermissionSetId}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsSsoPrincipalAccountAssignmentsInfoCardItem>) =>
                        <Entity
                            entityIdOrModel={item.permissionSetId}
                            variant="iconTextTypeTenant"/>}
                title={localization.columns.permissionSetId()}/>
            <DataTableColumn
                id={AwsSsoPrincipalAccountAssignmentsInfoCardTableColumnId.TenantIds}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsSsoPrincipalAccountAssignmentsInfoCardItem>) =>
                        <Typography noWrap={true}>
                            <InlineTenants
                                tenantIds={item.tenantIds}
                                tenantNameTranslatorOptions={{ includeRawId: true }}
                                variant="itemPlusItemCount"/>
                        </Typography>}
                title={localization.columns.tenantIds()}/>
            {variant === "user" &&
                <DataTableColumn
                    id={AwsSsoPrincipalAccountAssignmentsInfoCardTableColumnId.SourceEntityId}
                    render={
                        ({ item }: DataTableColumnRenderProps<AwsSsoPrincipalAccountAssignmentsInfoCardItem>) =>
                            <Entity
                                entityIdOrModel={item.sourceEntityId}
                                key={item.sourceEntityId}
                                variant="iconTextTypeTenant"/>}
                    title={localization.columns.sourceEntityId()}/>}
            {!_.isNil(roleIdToActivityTimeMap) &&
                <DataTableColumn
                    id={AwsSsoPrincipalAccountAssignmentsInfoCardTableColumnId.LastActivity}
                    itemProperty={
                        (item: AwsSsoPrincipalAccountAssignmentsInfoCardItem) =>
                            _.isNil(item.lastActivity)
                                ? localization.columns.lastActivity.empty()
                                : TimeFormatter.monthDayAndYear(item.lastActivity)}
                    title={localization.columns.lastActivity.title()}/>}
        </DataTable>);
}

class AwsSsoPrincipalAccountAssignmentsInfoCardItem {
    public id: string;

    constructor(
        public tenantIds: string[],
        public permissionSetId: string,
        public sourceEntityId: string,
        public lastActivity?: Date) {
        this.id = `${sourceEntityId}-${permissionSetId}`;
    }
}

enum AwsSsoPrincipalAccountAssignmentsInfoCardTableColumnId {
    LastActivity = "lastActivity",
    PermissionSetId = "permissionSet",
    SourceEntityId = "sourceEntity",
    TenantIds = "accountAssignments"
}