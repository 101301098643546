import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpComputeVpcPeeringConnectionStatusTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonResourceDefinition } from "./useGcpCommonResourceDefinition";

export function useGcpComputeVpcPeeringConnectionDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useGcpCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const computeVpcPeeringConnectionStatusTranslator = useGcpComputeVpcPeeringConnectionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeVpcPeeringConnectionDefinition",
            () => ({
                columns: {
                    accepterSubnetIds: "Associated Subnets",
                    accepterVpc: "Accepter VPC",
                    requesterVpc: "Requester VPC",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeVpcPeeringConnectionModel) => ({
                            [localization.columns.status()]: computeVpcPeeringConnectionStatusTranslator((item.entity as Contract.GcpComputeVpcPeeringConnection).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeVpcPeeringConnectionStatus}
                                enumTypeTranslator={computeVpcPeeringConnectionStatusTranslator}
                                placeholder={localization.columns.status()}
                                sorted={false}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionStatus}
                itemProperty={(item: Contract.EntityModel) => computeVpcPeeringConnectionStatusTranslator((item.entity as Contract.GcpComputeVpcPeeringConnection).status)}
                key={Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeVpcPeeringConnectionModel>(
                        Contract.TypeNames.GcpComputeVpc,
                        item => [item.requesterVpcId],
                        localization.columns.requesterVpc())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionRequesterVpc)}
                                placeholder={localization.columns.requesterVpc()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionRequesterVpc}
                key={Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionRequesterVpc}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeVpcPeeringConnectionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.requesterVpcId}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            entityVariant="iconText"/>}
                title={localization.columns.requesterVpc()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeVpcPeeringConnectionModel>(
                        Contract.TypeNames.GcpComputeVpc,
                        item => [item.accepterVpcIdReference],
                        localization.columns.accepterVpc())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionAccepterVpc)}
                                placeholder={localization.columns.accepterVpc()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionAccepterVpc}
                key={Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionAccepterVpc}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeVpcPeeringConnectionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.accepterVpcIdReference}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            entityVariant="iconText"/>}
                title={localization.columns.accepterVpc()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeVpcPeeringConnectionModel>(
                        Contract.TypeNames.GcpComputeSubnet,
                        item => item.accepterSubnetIds,
                        localization.columns.accepterSubnetIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionAccepterSubnets)}
                                placeholder={localization.columns.accepterSubnetIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionAccepterSubnets}
                key={Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionAccepterSubnets}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeVpcPeeringConnectionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.accepterSubnetIds}
                            entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                            entityVariant="iconText"/>}
                title={localization.columns.accepterSubnetIds()}/>,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeVpcPeeringConnectionRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeVpcPeeringConnectionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionAccepterSubnets]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionAccepterVpc]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionRequesterVpc]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeVpcPeeringConnectionStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}