import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureNetworkNetworkInterfaceDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureNetworkNetworkInterfaceDefinition",
            () => ({
                columns: {
                    networkSecurityGroupId: "Network Security Group",
                    publicIpAddressIds: "Public IP Addresses",
                    virtualMachineId: "Attached To",
                    virtualNetworkId: "Virtual Network"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkNetworkInterfaceModel>(
                        Contract.TypeNames.AzureComputeVirtualMachine,
                        item => [item.virtualMachineId],
                        localization.columns.virtualMachineId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkNetworkInterfaceVirtualMachine)}
                                placeholder={localization.columns.virtualMachineId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkNetworkInterfaceVirtualMachine}
                key={Contract.EntityModelProperty.AzureNetworkNetworkInterfaceVirtualMachine}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkNetworkInterfaceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.virtualMachineId}
                            entityTypeName={Contract.TypeNames.AzureComputeVirtualMachine}/>}
                title={localization.columns.virtualMachineId()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkNetworkInterfaceModel>(
                        Contract.TypeNames.AzureNetworkNetworkSecurityGroup,
                        item => _.take((item.entity as Contract.AzureNetworkNetworkInterface).interfaceNetworkSecurityGroupIds, 1),
                        localization.columns.networkSecurityGroupId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkNetworkInterfaceNetworkSecurityGroup)}
                                placeholder={localization.columns.networkSecurityGroupId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkNetworkInterfaceNetworkSecurityGroup}
                key={Contract.EntityModelProperty.AzureNetworkNetworkInterfaceNetworkSecurityGroup}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureNetworkNetworkInterface).interfaceNetworkSecurityGroupIds}
                            entityTypeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}/>}
                title={localization.columns.networkSecurityGroupId()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkNetworkInterfaceModel>(
                        Contract.TypeNames.AzureNetworkPublicIpAddress,
                        item => (item.entity as Contract.AzureNetworkNetworkInterface).publicIpAddressIds,
                        localization.columns.publicIpAddressIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkNetworkInterfacePublicIpAddresses)}
                                placeholder={localization.columns.publicIpAddressIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkNetworkInterfacePublicIpAddresses}
                key={Contract.EntityModelProperty.AzureNetworkNetworkInterfacePublicIpAddresses}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkNetworkInterfaceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureNetworkNetworkInterface).publicIpAddressIds}
                            entityTypeName={Contract.TypeNames.AzureNetworkPublicIpAddress}/>}
                title={localization.columns.publicIpAddressIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkNetworkInterfaceModel>(
                        Contract.TypeNames.AzureNetworkVirtualNetwork,
                        item => [(item.entity as Contract.AzureNetworkNetworkInterface).virtualNetworkId],
                        localization.columns.virtualNetworkId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkNetworkInterfaceVirtualNetwork)}
                                placeholder={localization.columns.virtualNetworkId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkNetworkInterfaceVirtualNetwork}
                key={Contract.EntityModelProperty.AzureNetworkNetworkInterfaceVirtualNetwork}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureNetworkNetworkInterface).virtualNetworkId}
                            entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}/>}
                title={localization.columns.virtualNetworkId()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureNetworkNetworkInterfaceRequest(
                new Contract.EntityControllerGetEntityModelPageAzureNetworkNetworkInterfaceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkNetworkInterfaceNetworkSecurityGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkNetworkInterfacePublicIpAddresses]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkNetworkInterfaceVirtualMachine]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkNetworkInterfaceVirtualNetwork])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}