import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetOciObjectStorageBucketRiskContext } from "../contexts";

export function useOciObjectStorageBucketLogCategoryWriteNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciObjectStorageBucketLogCategoryWriteNotExistsRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.OciObjectStorageBucketModel;

    const getOciObjectStorageBucketRiskContext = useGetOciObjectStorageBucketRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciObjectStorageBucketLogCategoryWriteNotExistsRiskDefinition",
            () => ({
                description: "{{bucket}} object logging is not enabled",
                sections: {
                    resolution: {
                        step1: "Click **Logs**",
                        step2: "In the **Category** column, find **Write Access Events**",
                        step3: "In the **Enable Log** column for that row, select the checkbox to enable the log",
                        step4: "Click **Enable Log**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.ObjectStorage,
                bucketModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const bucketRiskContext = getOciObjectStorageBucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.storageSize,
                bucketRiskContext.accessLevel,
                bucketRiskContext.encryption,
                bucketRiskContext.objectVersioning,
                bucketRiskContext.sensitive,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        }
    )
    ;
}