import _, { Dictionary } from "lodash";
import { Contract } from "../controllers";
import { TypeHelper } from "./typeHelper";

export class BuiltInEntityAttributeHelper {
    private static tenantTypeToBuiltInEntityAttributeTypeNamesMap: Dictionary<string[]> = {
        [Contract.TenantType.Aad]:
            [
                Contract.TypeNames.AadDirectoryUserExternalAttribute,
                Contract.TypeNames.AadDirectoryUserGuestAttribute
            ],
        [Contract.TenantType.Aws]:
            [
                Contract.TypeNames.AwsAccessKeyEnabledUserAttribute,
                Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute
            ],
        [Contract.TenantType.Gcp]:
            [
                Contract.TypeNames.GcpUnusedTenantEntityAttribute,
                Contract.TypeNames.GcpUserManagedKeyExistsServiceAccountAttribute
            ]
    };

    public static getBuiltInEntityAttributeTypeNames(tenantTypes: Contract.TenantType[]) {
        return _(tenantTypes).
            flatMap(tenantType => this.tenantTypeToBuiltInEntityAttributeTypeNamesMap[tenantType] ?? []).
            concat(
                _.without(
                    TypeHelper.getSealedAssignableTypeNames(Contract.TypeNames.BuiltInEntityAttribute),
                    ..._.flatMap(_.values(this.tenantTypeToBuiltInEntityAttributeTypeNamesMap)))).
            value();
    }
}