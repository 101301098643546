import { DialogMenuItem, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonNetworkingInfoItemElements, useGcpCommonScopeResourceInfoItemElements, useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, InlineTextViewer, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useGetVirtualMachinePriorityScanRequestDialogMenuItem } from "../../../../../../../WorkloadAnalysis";
import { useGcpComputeInstanceStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings, NetworkingInfoCard } from "../../components";
import { useCommonVirtualMachineDiskResourceInfoItemElements } from "../useCommonVirtualMachineDiskResourceInfoItemElements";
import { useCommonVirtualMachineResourceInfoItemElements } from "../useCommonVirtualMachineResourceInfoItemElements";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpComputeInstanceDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonScopeResourcesInfoElements = useGcpCommonScopeResourceInfoItemElements(scopeResourceModel);
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const commonVirtualMachineDiskResourceInfoItemElements = useCommonVirtualMachineDiskResourceInfoItemElements(scopeResourceModel);
    const commonVirtualMachineResourceInfoItemElements = useCommonVirtualMachineResourceInfoItemElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const instanceModel = scopeResourceModel as Contract.GcpComputeInstanceModel;
    const effectiveSshSerialPortEnabled = instanceModel.effectiveSshSerialPortEnabled;
    const getVirtualMachinePriorityScanRequestDialogMenuItem = useGetVirtualMachinePriorityScanRequestDialogMenuItem(instanceModel);
    const instance = instanceModel.entity as Contract.GcpComputeInstance;
    const networkInfoItems =
        useGcpCommonNetworkingInfoItemElements({
            networkTags: instance.networkTags,
            privateIpAddresses: instance.privateIpAddresses,
            publicIpAddresses: instance.publicIpAddresses,
            resourceModel: scopeResourceModel,
            subnetIds: instanceModel.subnetIdReferences,
            vpcIds: instanceModel.vpcIdReferences
        });

    const computeInstanceStatusTranslator = useGcpComputeInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeInstanceDefinition",
            () => ({
                info: {
                    items: {
                        bootDiskIdReference: "Boot Disk",
                        bootDiskSourceResourceIdReference: "Disk Source",
                        bootIntegrityValidationEnabled: {
                            false: "Disabled",
                            title: "Integrity Monitoring",
                            true: "Enabled"
                        },
                        computeProjectSshKeysBlockEnabled: {
                            false: "Disabled",
                            title: "Block Project-Wide SSH Keys",
                            true: "Enabled"
                        },
                        diskIdReferences: "Local Disks",
                        dnsZoneIds: "DNS Zones",
                        instanceGroupIds: "Instance Groups",
                        instanceTemplateIdReference: "Instance Template",
                        ipForwardingEnabled: {
                            false: "Disabled",
                            title: "IP Forwarding",
                            true: "Enabled"
                        },
                        memoryEncryptionEnabled: {
                            false: "Disabled",
                            title: "Confidential VM Service",
                            true: "Enabled"
                        },
                        rawType: "Machine Type",
                        secureBootEnabled: {
                            false: "Disabled",
                            title: "Secure Boot",
                            true: "Enabled"
                        },
                        serviceAccountAuthorizationScopes: "Access Scopes",
                        sshIamEnabled: {
                            false: "Disabled",
                            falseInherited: "Disabled (inherited)",
                            title: "OS Login",
                            true: "Enabled",
                            trueInherited: "Enabled (inherited)"
                        },
                        sshSerialPortEnabled: {
                            false: {
                                [Contract.GcpComputeInstanceScopePropertySource.OrganizationPolicy]: "Disabled (enforced by organization policy)",
                                [Contract.GcpComputeInstanceScopePropertySource.Project]: "Disabled (inherited from project)",
                                [Contract.GcpComputeInstanceScopePropertySource.Resource]: "Disabled"
                            },
                            title: "Serial Port Access",
                            true: {
                                [Contract.GcpComputeInstanceScopePropertySource.Project]: "Enabled (inherited from project)",
                                [Contract.GcpComputeInstanceScopePropertySource.Resource]: "Enabled"
                            }
                        },
                        startupScriptNameToValueMap: "Startup Scripts",
                        status: "Status",
                        stopTime: "Stop Time",
                        targetPoolIds: "Load Balancer Target Pools",
                        virtualTpmEnabled: {
                            false: "Disabled",
                            title: "vTPM",
                            true: "Enabled"
                        },
                        vpcIdReferences: "VPC Network"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                },
                toolbar: {
                    menu: {
                        generate: "Least Privilege Policy"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonScopeResourcesInfoElements.rawIdInfoItemElement,
                    commonScopeResourcesInfoElements.accessLevelInfoItemElement,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.bootDiskIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeDisk}
                        key="bootDiskIdReference"
                        title={localization.info.items.bootDiskIdReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.bootDiskSourceResourceIdReference}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        key="bootDiskSourceResourceIdReference"
                        title={localization.info.items.bootDiskSourceResourceIdReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.diskIdReferences}
                        entityTypeName={Contract.TypeNames.GcpComputeDisk}
                        key="diskIdReferences"
                        title={localization.info.items.diskIdReferences()}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={computeInstanceStatusTranslator(instance.status)}/>,
                    <InfoItem
                        key="stopTime"
                        location="all"
                        title={localization.info.items.stopTime()}
                        value={
                            _.isNil(instanceModel.stopTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(instanceModel.stopTime)}/>,
                    <InfoItem
                        key="rawType"
                        location="all"
                        title={localization.info.items.rawType()}
                        value={instance.rawType}/>,
                    <VerticalTopItemsInfoItem
                        items={instance.serviceAccountData.authorizationScopes}
                        key="authorizationScopes"
                        title={localization.info.items.serviceAccountAuthorizationScopes()}/>,
                    <InfoItem
                        key="computeProjectSshKeysBlockEnabled"
                        title={localization.info.items.computeProjectSshKeysBlockEnabled.title()}
                        value={
                            instance.computeProjectSshKeysBlockEnabled
                                ? localization.info.items.computeProjectSshKeysBlockEnabled.true()
                                : localization.info.items.computeProjectSshKeysBlockEnabled.false()}/>,
                    <InfoItem
                        key="sshIamEnabled"
                        title={localization.info.items.sshIamEnabled.title()}
                        value={
                            _.isNil(instanceModel.effectiveSshIamEnabled)
                                ? undefined
                                : !_.isNil(instance.sshIamEnabled)
                                    ? instance.sshIamEnabled
                                        ? localization.info.items.sshIamEnabled.true()
                                        : localization.info.items.sshIamEnabled.false()
                                    : instanceModel.effectiveSshIamEnabled
                                        ? localization.info.items.sshIamEnabled.trueInherited()
                                        : localization.info.items.sshIamEnabled.falseInherited()}/>,
                    <InfoItem
                        key="sshSerialPortEnabled"
                        title={localization.info.items.sshSerialPortEnabled.title()}
                        value={
                            _.isNil(effectiveSshSerialPortEnabled)
                                ? undefined
                                : effectiveSshSerialPortEnabled!.enabled
                                    ? localization.info.items.sshSerialPortEnabled.true[effectiveSshSerialPortEnabled.source as Exclude<Contract.GcpComputeInstanceScopePropertySource, Contract.GcpComputeInstanceScopePropertySource.OrganizationPolicy>]()
                                    : localization.info.items.sshSerialPortEnabled.false[effectiveSshSerialPortEnabled.source]()}/>,
                    <InfoItem
                        key="virtualTpmEnabled"
                        title={localization.info.items.virtualTpmEnabled.title()}
                        value={
                            instance.virtualTpmEnabled
                                ? localization.info.items.virtualTpmEnabled.true()
                                : localization.info.items.virtualTpmEnabled.false()}/>,
                    <InfoItem
                        key="secureBootEnabled"
                        title={localization.info.items.secureBootEnabled.title()}
                        value={
                            instance.secureBootEnabled
                                ? localization.info.items.secureBootEnabled.true()
                                : localization.info.items.secureBootEnabled.false()}/>,
                    <InfoItem
                        key="bootIntegrityValidationEnabled"
                        title={localization.info.items.bootIntegrityValidationEnabled.title()}
                        value={
                            instance.bootIntegrityValidationEnabled
                                ? localization.info.items.bootIntegrityValidationEnabled.true()
                                : localization.info.items.bootIntegrityValidationEnabled.false()}/>,
                    <InfoItem
                        key="ipForwardingEnabled"
                        title={localization.info.items.ipForwardingEnabled.title()}
                        value={
                            _.isNil(instance.ipForwardingEnabled)
                                ? undefined
                                : instance.ipForwardingEnabled
                                    ? localization.info.items.ipForwardingEnabled.true()
                                    : localization.info.items.ipForwardingEnabled.false()}/>,
                    <InfoItem
                        key="memoryEncryptionEnabled"
                        title={localization.info.items.memoryEncryptionEnabled.title()}
                        value={
                            instance.memoryEncryptionEnabled
                                ? localization.info.items.memoryEncryptionEnabled.true()
                                : localization.info.items.memoryEncryptionEnabled.false()}/>,
                    <InfoItem
                        key="startupScriptNameToValueMap"
                        title={localization.info.items.startupScriptNameToValueMap()}
                        vertical={_.size(instance.startupScriptNameToValueMap) > 1}>
                        {_.isEmpty(instance.startupScriptNameToValueMap)
                            ? undefined
                            : _.map(
                                instance.startupScriptNameToValueMap,
                                (value, key) =>
                                    <InlineTextViewer
                                        dialogTitle={localization.info.items.startupScriptNameToValueMap()}
                                        text={value}
                                        title={key}/>)}
                    </InfoItem>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.instanceGroupIds}
                        entityTypeName={Contract.TypeNames.GcpComputeInstanceGroup}
                        key="instanceGroupIds"
                        title={localization.info.items.instanceGroupIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel?.instanceTemplateIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeInstanceTemplate}
                        key="instanceTemplateId"
                        title={localization.info.items.instanceTemplateIdReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.targetPoolIds}
                        entityTypeName={Contract.TypeNames.GcpComputeTargetPool}
                        key="targetPoolIds"
                        title={localization.info.items.targetPoolIds()}/>,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemDisplayNameInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemTypeInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.vulnerabilitiesInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.workloadAnalysisStatusInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.scanTimeInfoElement,
                    commonVirtualMachineResourceInfoItemElements.scanSourceInfoElement
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.inboundAccessType}
                    {networkInfoItems.inboundExternalAccessScope}
                    {networkInfoItems.getPublicIpAddresses()}
                    {networkInfoItems.getPrivateIpAddresses()}
                    {networkInfoItems.getVpcs()}
                    {networkInfoItems.getSubnets()}
                    {networkInfoItems.getNetworkTags()}
                </NetworkingInfoCard>
            </Info>,
        topbarMenuItems: _.filter([getVirtualMachinePriorityScanRequestDialogMenuItem()]) as DialogMenuItem[]
    });
}