import { Link, NumberFormatter } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, CustomRiskPolicyItem, ScopeHelper, scopeNodeModelStore, Severity, SeverityCount, TenantsIcon, useTheme } from "../../../../../../../../../../../common";

type CustomProps = {
    tableItem: CustomRiskPolicyItem;
};

export function Custom({ tableItem }: CustomProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap();
    const isResult =
        useMemo(
            () => _.isNil(tableItem.result) || tableItem.result.riskCount === 0,
            [tableItem]);

    const theme = useTheme();
    return (
        <Fragment>
            <Grid
                item={true}
                style={{
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1)
                }}
                xs={1}>
                <TenantsIcon
                    containerSx={{ flexWrap: "wrap" }}
                    sx={{ fontSize: "24px" }}
                    tenantTypes={tableItem.tenantTypes}/>
            </Grid>
            <Grid
                item={true}
                style={{ paddingRight: theme.spacing(1) }}
                xs={true}>
                <Link
                    urlOrGetUrl={
                        CustomerConsoleAppUrlHelper.getRisksUrl(
                            Contract.RisksView.Open,
                            {
                                policyConfigurationTypeNameOrIds: [tableItem.riskPolicyConfiguration.id],
                                tenantIds:
                                    tableItem.riskPolicyModel.riskPolicyConfiguration.scopeId === ScopeHelper.customerId
                                        ? undefined
                                        : scopeNodeMap[tableItem.riskPolicyModel.riskPolicyConfiguration.scopeId].tenantIds
                            })}>
                    <Typography>
                        {tableItem.riskPolicyConfiguration.name}
                    </Typography>
                </Link>
            </Grid>
            <Grid
                item={true}
                style={{ paddingRight: theme.spacing(1) }}
                xs={3}>
                <Severity
                    severity={tableItem.riskPolicyConfiguration.severity}
                    sx={{ fontSize: "18px" }}/>
            </Grid>
            <Grid
                item={true}
                style={{ paddingRight: theme.spacing(1) }}
                xs={3}>
                <SeverityCount
                    borderColor={
                        isResult
                            ? theme.palette.success.main
                            : undefined }
                    count={NumberFormatter.humanize(tableItem?.result?.riskCount)}/>
            </Grid>
        </Fragment>);
}