﻿import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useGcpComputeLoadBalancerLoadBalancingSchemeTranslator, useGcpComputeLoadBalancerTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpComputeLoadBalancerRiskContext() {
    return useMemo(
        () => useGcpComputeLoadBalancerRiskContext,
        []);
}

function useGcpComputeLoadBalancerRiskContext(loadBalancerModel: Contract.GcpComputeLoadBalancerModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(loadBalancerModel);
    const loadBalancer = loadBalancerModel.entity as Contract.GcpComputeLoadBalancer;

    const computeLoadBalancerLoadBalancingSchemeTranslator = useGcpComputeLoadBalancerLoadBalancingSchemeTranslator();
    const computeLoadBalancerTypeTranslator = useGcpComputeLoadBalancerTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpComputeLoadBalancerRiskContext",
            () => ({
                loadBalancingScheme: "The {{translatedLoadBalancerTypeName}} load balancing scheme is {{loadBalancerLoadBalancingScheme}}",
                type: "The {{translatedLoadBalancerTypeName}} type is {{loadBalancerType}}"
            }));
    const translatedLoadBalancerTypeName =
        entityTypeNameTranslator(
            loadBalancer.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        loadBalancingScheme:
            new RiskDefinitionContextItem(
                localization.loadBalancingScheme({
                    loadBalancerLoadBalancingScheme: computeLoadBalancerLoadBalancingSchemeTranslator(loadBalancer.loadBalancingScheme),
                    translatedLoadBalancerTypeName
                })),
        type:
            new RiskDefinitionContextItem(
                localization.type({
                    loadBalancerType: computeLoadBalancerTypeTranslator(loadBalancer.type),
                    translatedLoadBalancerTypeName
                }))
    };
}