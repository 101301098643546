import { DataTableColumn, PagedValuesFilter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, ElasticsearchItemPageHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsCommonEc2ResourceDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonEc2ResourceDefinition",
            () => ({
                columns: {
                    rawId: "ID"
                }
            }));

    return {
        columns: {
            ...commonResourceDefinition.columns,
            rawIdColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2ResourceModel) => ({
                                [localization.columns.rawId()]: (item.entity as Contract.AwsEc2Resource).rawId
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2ResourceRawId)}
                                    placeholder={localization.columns.rawId()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2ResourceRawId}
                    itemProperty={item => (item.entity as Contract.AwsEc2Resource).rawId}
                    key={Contract.EntityModelProperty.AwsEc2ResourceRawId}
                    title={localization.columns.rawId()}/>
        }
    };
}