﻿import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskCodeResolutionAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskCodeResolutionAuditEventDefinition",
            () => ({
                pullRequestUrl: "Link to Pull Request"
            }));

    const riskCodeResolutionAuditEvent = context.auditEventModel.auditEvent as Contract.RiskCodeResolutionAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            riskAuditEventDetailsItems.riskPolicyTypeItem,
            riskAuditEventDetailsItems.riskIdItem,
            riskAuditEventDetailsItems.riskDeletedItem,
            riskAuditEventDetailsItems.riskedEntitiesItem,
            riskAuditEventDetailsItems.riskLinkItem,
            new AuditEventDefinitionDetailItem(
                localization.pullRequestUrl(),
                riskCodeResolutionAuditEvent.pullRequestUrl,
                <Link
                    urlOrGetUrl={riskCodeResolutionAuditEvent.pullRequestUrl}
                    variant="external"/>),
            riskAuditEventDetailsItems.errorMessageItem]) as AuditEventDefinitionDetailItem[]);
}