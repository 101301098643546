import React from "react";
import { CustomerConsoleAppUrlHelper, ProfileActions } from "../../../../../../../common";
import { VulnerabilityProfile as Profile } from "../../../../WorkloadAnalysis/components";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useVulnerabilityDefinition(item: SideViewItem): SideViewDefinition {
    return {
        getActionsElement:
            () =>
                <ProfileActions
                    getLink={() => CustomerConsoleAppUrlHelper.getWorkloadAnalysisVulnerabilityProfileUrl(item.id)!}
                    key={`${item.id}-actions`}/>,
        getViewElement:
            () =>
                <Profile
                    key={`${item.id}-profile`}
                    rawId={item.id}/>
    };
}