import { MenuItem, UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { Contract, RiskType } from "../../../../../../../../common";
import { ActionDialogType } from "../../Actions";
import { useCloudDefinition, useCodeDefinition } from "./hooks";

export function useDefinition(riskType: RiskType, riskModel: Contract.RiskModel): ActionsDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskType) {
                    case RiskType.Cloud:
                        return useCloudDefinition;
                    case RiskType.Code:
                        return useCodeDefinition;
                    default:
                        throw new UnexpectedError(riskType);
                }
            },
            [riskType]);

    return useDefinition(riskModel);
}

export type ActionsDefinition = {
    additionalElements?: ReactNode[];
    getItemDialog: (type: ActionDialogType) => ReactNode;
    menuItems: MenuItem[];
};