import { Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function LogSourceSetupFirstItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement:
                        <img src={InstructionsImageUrl}/>
                }));
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.qRadar.addOrEdit.logSourceSetupFirstItem",
            () => ({
                download: "**Download**",
                steps: {
                    step1: "In the QRadar Admin page, click on **Log Sources**",
                    step2: "Select **Manage Log Sources** and click **New Log Source**",
                    step3: "Select Log Source type **Ermetic** and Protocol type **TLS Syslog**"
                }
            }));

    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
        </Steps>);
}