import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEc2InstancesRiskContext, useGetAwsEksClusterRiskContext } from "../../../contexts";
import { useGetAwsEc2InstanceMetadataServiceVersionRiskContext } from "./useGetAwsEc2InstanceMetadataServiceVersionRiskContext";
import { useGetResolutionSteps } from "./useGetResolutionSteps";

export function useAwsEc2InstanceMetadataServiceVersionRiskClusterDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel) {
    const clusterModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEksClusterModel;
    const cluster = (clusterModel.entity) as Contract.AwsEksCluster;
    const instanceModels = entityModelStore.useGet(riskModel.risk.aggregatedEntityIds) as Contract.AwsEc2InstanceModel[];
    const karpenterNodeClassNames =
        useMemo(
            () =>
                _(instanceModels).
                    map(instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).karpenterNodeClassName).
                    filter().
                    uniq().
                    value(),
            [riskModel]);

    const getAwsEc2InstanceMetadataServiceVersionRiskContext = useGetAwsEc2InstanceMetadataServiceVersionRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsEksClusterRiskContext = useGetAwsEksClusterRiskContext();
    const getResolutionSteps = useGetResolutionSteps();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskClusterDefinition",
            () => ({
                contextItems: {
                    karpenter: "The {{translatedClusterTypeName}} runs **Karpenter**"
                },
                description: "{{cluster}} runs EC2 instances that are not enforcing Instance Metadata Service Version 2 (IMDSv2)",
                sections: {
                    resolution: {
                        step1: {
                            karpenter: {
                                false: "The {{translatedClusterTypeName}} is spinning up and down EC2 instances. Please update the cluster’s configuration to ensure the instances are launched with the correct configuration",
                                karpenterNodeClasses: [
                                    "1 Karpenter EC2 Node Class",
                                    "{{count | NumberFormatter.humanize}} Karpenter EC2 Node Classes"
                                ],
                                true: "The {{translatedClusterTypeName}} is using **Karpenter** to spin up and down EC2 instances. Update the template on {{karpenterNodeClasses}} to run the instance with IMDSv2."
                            }
                        }
                    }
                }
            }));

    const translatedClusterTypeName =
        entityTypeNameTranslator(
            cluster.typeName,
            { variant: "text" });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            ...getResolutionSteps(
                cluster.typeName,
                riskModel),
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Eks,
                AwsConsoleUrlBuilder.getEksClusterUrl(cluster)),
            _.isEmpty(karpenterNodeClassNames)
                ? localization.sections.resolution.step1.karpenter.false({ translatedClusterTypeName })
                : localization.sections.resolution.step1.karpenter.true({
                    karpenterNodeClasses:
                        <InlineItems
                            items={karpenterNodeClassNames}
                            namePluralizer={localization.sections.resolution.step1.karpenter.karpenterNodeClasses}
                            variant="itemCountAndType"/>,
                    translatedClusterTypeName
                })
        ],
        riskModel,
        () => {
            const clusterContextItems = getAwsEksClusterRiskContext(clusterModel);
            const instanceMetadataServiceVersionRiskContextItems = getAwsEc2InstanceMetadataServiceVersionRiskContext(riskModel);
            const instancesContextItems = getAwsEc2InstancesRiskContext(instanceModels);
            return _.filter(
                [
                    clusterContextItems.generalInformation,
                    clusterContextItems.sensitive,
                    _.isEmpty(karpenterNodeClassNames)
                        ? undefined
                        : new RiskDefinitionContextItem(localization.contextItems.karpenter({ translatedClusterTypeName })),
                    instanceMetadataServiceVersionRiskContextItems.instances,
                    instanceMetadataServiceVersionRiskContextItems.permissionActions,
                    instanceMetadataServiceVersionRiskContextItems.instancePublicAccess,
                    instancesContextItems.usedInstances,
                    instancesContextItems.runningInstances,
                    instancesContextItems.sensitive,
                    clusterContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)]);
        });
}