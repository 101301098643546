import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useGetAwsNetworkedResourceRiskContext() {
    return useMemo(
        () => useAwsNetworkedResourceRiskContext,
        []);
}

function useAwsNetworkedResourceRiskContext(resourceModel: Contract.AwsResourceModel) {
    const networkedResource = _.as<Contract.IAwsNetworkedResource>(resourceModel.entity);
    const vpcModels = entityModelStore.useGet(networkedResource.vpcIds) as Contract.AwsEc2VpcModel[];
    const sensitiveVpcModels =
        useMemo(
            () =>
                _.filter(
                    vpcModels,
                    vpcModel => vpcModel.entityConfiguration?.sensitive === true),
            [vpcModels]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsNetworkedResourceRiskContext",
            () => ({
                sensitiveVpc:
                    [
                        "The {{vpcs}} is marked as sensitive",
                        "The {{vpcs}} are marked as sensitive"
                    ],
                vpc: "The {{translatedTypeName}} is part of {{vpcs}}"
            }));

    return {
        sensitiveVpc:
            !_.isEmpty(sensitiveVpcModels)
                ? new RiskDefinitionContextItem(
                    localization.sensitiveVpc(
                        sensitiveVpcModels.length,
                        {
                            vpcs:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveVpcModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                                    variant="itemOrItemCountAndType"/>
                        }))
                : undefined,
        vpc:
            !_.isEmpty(vpcModels)
                ? new RiskDefinitionContextItem(
                    localization.vpc({
                        translatedTypeName:
                            entityTypeNameTranslator(
                                networkedResource.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                }),
                        vpcs:
                            <InlineEntities
                                entityIdsOrModels={vpcModels}
                                entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                                variant="itemOrItemCountAndType"/>
                    }))
                : undefined
    };
}