import { Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import _ from "lodash";
import React, { useEffect } from "react";
import { Contract, LicensingHelper, useTenantTypeTranslator } from "../../../../../../../../../../../../../../../../common";
import { InstructionsWithoutPermissionManagementImageUrl, InstructionsWithPermissionManagementImageUrl } from "./images";

export function UserPermissionsItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement:
                        <img src={
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement)
                                ? InstructionsWithPermissionManagementImageUrl
                                : InstructionsWithoutPermissionManagementImageUrl}/>
                }));
        },
        []);
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oktaTenants.addOrEdit.userPermissionsItem",
            () => ({
                steps: {
                    lastStep: "Click **Save Changes** when finished",
                    permissionManagementStep: "(Optional) To leverage Just-in-Time (JIT) Access to temporarily add users to {{translatedTenantType}} groups in this organization, click **+ Add assignment** and then search for and select **Group Membership Administrator**",
                    step1: "Navigate to **Security -> Administrators**",
                    step2: "Click **+ Add Administrator**",
                    step3: "Under *italic*Select admin**, enter the name of the user you created in the previous step",
                    step4: "Under *italic*Role**, search for and select **Read-only Administrator**"
                }
            }));
    const tenantTypeTranslator = useTenantTypeTranslator();
    return (
        <Steps variant="letters">
            {_.filter(
                [
                    localization.steps.step1(),
                    localization.steps.step2(),
                    localization.steps.step3(),
                    localization.steps.step4(),
                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement)
                        ? localization.steps.permissionManagementStep({ translatedTenantType: tenantTypeTranslator(Contract.TenantType.Okta) })
                        : undefined,
                    localization.steps.lastStep()
                ])}
        </Steps>);
}