import { Link, useLocalization } from "@infrastructure";
import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, UrlHelper, useTheme } from "../../../../../common";

export function Onboarding() {
    const localization =
        useLocalization(
            "views.customer.dataAnalysis.onboarding",
            () => ({
                button: {
                    text: "Contact us"
                },
                description: {
                    action: "For more information, contact Tenable, or see the documentation to learn more.\n{{documentationLink}}",
                    button: "Contact us",
                    dataProtectionCapabilities: "Your current license **does not include** access to these features.\n{{body}}",
                    documentationLink: "Learn more."
                },
                license: {
                    body: "Use Data Protection to identify sensitive data resources, see who can access them, and make sure they're secure.",
                    text: "does not include"
                },
                title: "Welcome to Data Security!"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(100),
                padding: theme.spacing(10),
                position: "relative"
            }}>
            <Typography
                sx={{
                    fontSize: "30px",
                    fontWeight: 700
                }}>
                {localization.title()}
            </Typography>
            <Typography
                sx={{
                    fontSize: "14px",
                    whiteSpace: "pre-wrap"
                }}>
                {localization.description.dataProtectionCapabilities({ body: localization.license.body() })}
            </Typography>
            <Typography
                sx={{
                    fontSize: "14px",
                    paddingBottom: theme.spacing(1.5),
                    whiteSpace: "pre-wrap"
                }}>
                {localization.description.action(
                    {
                        documentationLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsDataProtectionRelativeUrl()}
                                variant="external">
                                <Typography sx={{ fontSize: "14px" }}>
                                    {localization.description.documentationLink()}
                                </Typography>
                            </Link>
                    })}
            </Typography>
            <Box>
                <Button onClick={() => UrlHelper.openNewTab(UrlHelper.supportUrl)}>
                    {localization.button.text()}
                </Button>
            </Box>
        </Stack>);
}