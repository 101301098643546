import { InlineItems, Link, Loading, Steps, useLocalization } from "@infrastructure";
import { Box, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, InlineEntities, Network, NetworkScopeFormatter, tenantModelStore, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../common/components/Network/components";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useResourceGeneralInformationStep } from "../../../useResourceGeneralInformationStep";
import { ResourcesExternalConsoleLink } from "../../components";

export function useAzureInboundExternalComputeVirtualMachineRiskDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalComputeVirtualMachineRiskModel = riskModel as Contract.AzureInboundExternalComputeVirtualMachineRiskModel;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AzureTenantConfiguration;
    const aadTenantConfiguration = tenantModelStore.useGet(tenantConfiguration.aadTenantId).configuration as Contract.AadTenantConfiguration;
    const virtualMachineModel = entityModelStore.useGet((riskModel.risk as Contract.EntityRisk).entityId) as Contract.AzureComputeVirtualMachineModel;
    const virtualMachine = virtualMachineModel.entity as Contract.AzureComputeVirtualMachine;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureInboundExternalComputeVirtualMachineRiskDefinition",
            () => ({
                description: "{{virtualMachine}} is accessible directly from a wide range of public IP addresses",
                sections: {
                    networkGraph: "Network",
                    resolution: {
                        attachedNetworkSecurityGroups: {
                            step1: {
                                link: "Create, change, or delete an Azure network security group",
                                text: "Before changing any network security group inbound rule, verify that it will not break existing communications, see {{createChangeOrDeleteAnAzureNetworkSecurityGroup}}"
                            },
                            step2: "For each public inbound rule, delete the rule and replace it with an inbound rule that limits access to specific IP addresses"
                        },
                        noAttachedNetworkSecurityGroups: {
                            step1: {
                                link: "Create, change, or delete an Azure network security group",
                                text: "Before associating any network security group, verify that it will not break existing communications, see {{createChangeOrDeleteAnAzureNetworkSecurityGroup}}"
                            },
                            step2: "Click on the selected Network Security Group name",
                            step3: "Under **Network Interfaces**, click on **Associate** and select the target network interface of the virtual machine",
                            step4: "Click **OK** to confirm"
                        }
                    }
                }
            }));
    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                virtualMachine:
                    <Entity
                        entityIdOrModel={virtualMachineModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () =>
            !_.isEmpty(inboundExternalComputeVirtualMachineRiskModel.risk.interfaceNetworkSecurityGroupIds) ||
            !_.isEmpty(inboundExternalComputeVirtualMachineRiskModel.risk.subnetNetworkSecurityGroupIds)
                ? [
                    <ResourcesExternalConsoleLink
                        key="AzureNetworkNetworkSecurityGroup"
                        partitionType={aadTenantConfiguration.partitionType}
                        typeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}/>,
                    localization.sections.resolution.attachedNetworkSecurityGroups.step1.text({
                        createChangeOrDeleteAnAzureNetworkSecurityGroup:
                            <Link
                                urlOrGetUrl={inboundExternalComputeVirtualMachineRiskModel.manageNetworkSecurityGroupDocumentationUrl}
                                variant="external">
                                {localization.sections.resolution.attachedNetworkSecurityGroups.step1.link()}
                            </Link>
                    }),
                    localization.sections.resolution.attachedNetworkSecurityGroups.step2()
                ]
                : [
                    <ResourcesExternalConsoleLink
                        key="AzureNetworkNetworkSecurityGroup"
                        partitionType={aadTenantConfiguration.partitionType}
                        typeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}/>,
                    localization.sections.resolution.noAttachedNetworkSecurityGroups.step1.text({
                        createChangeOrDeleteAnAzureNetworkSecurityGroup:
                            <Link
                                urlOrGetUrl={inboundExternalComputeVirtualMachineRiskModel.manageNetworkSecurityGroupDocumentationUrl}
                                variant="external">
                                {localization.sections.resolution.noAttachedNetworkSecurityGroups.step1.link()}
                            </Link>
                    }),
                    localization.sections.resolution.noAttachedNetworkSecurityGroups.step2(),
                    localization.sections.resolution.noAttachedNetworkSecurityGroups.step3(),
                    localization.sections.resolution.noAttachedNetworkSecurityGroups.step4()
                ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    risk={inboundExternalComputeVirtualMachineRiskModel.risk}
                    virtualMachineModel={virtualMachineModel}/>,
            sections:
                virtualMachineModel.unknown || virtualMachine.systemDeleted
                    ? undefined
                    : [
                        new RiskDefinitionSection(
                            <Box sx={{ minHeight: theme.spacing(20) }}>
                                <Loading>
                                    <Network
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(inboundExternalComputeVirtualMachineRiskModel.id)}
                                        entityId={inboundExternalComputeVirtualMachineRiskModel.risk.entityId}
                                        initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                        tenantType={Contract.TenantType.Azure}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            localization.sections.networkGraph(),
                            {
                                profilePageOnly: true
                            })
                    ]
        });
}

type ContextSectionProps = {
    risk: Contract.AzureInboundExternalComputeVirtualMachineRisk;
    virtualMachineModel: Contract.AzureComputeVirtualMachineModel;
};

function ContextSection({ risk, virtualMachineModel }: ContextSectionProps) {
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const virtualMachine =
        virtualMachineModel.unknown
            ? undefined
            : virtualMachineModel.entity as Contract.AzureComputeVirtualMachine;
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(virtualMachineModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureInboundExternalComputeVirtualMachineRiskDefinition.ContextSection",
            () => ({
                exposedManagementNetworkScopes: [
                    "1 public management port range",
                    "{{count | NumberFormatter.humanize}} public management port ranges"
                ],
                exposedNetworkScopes: [
                    "1 public port range",
                    "{{count | NumberFormatter.humanize}} public port ranges"
                ],
                managedIdentities: "The virtual machine is attached to {{managedIdentities}}",
                networkSecurityGroup: {
                    attached: "The virtual machine is associated with {{networkSecurityGroups}}, exposing it to {{exposedNetworkScopes}}",
                    notAttached: "The virtual machine is not associated with any network security groups, exposing it to all protocol and port ranges without any restrictions"
                },
                status: {
                    [Contract.TypeNames.AzureComputeVirtualMachineStatus]: {
                        [Contract.AzureComputeVirtualMachineStatus.Deallocated]: "Deallocated",
                        [Contract.AzureComputeVirtualMachineStatus.Deallocating]: "Deallocating",
                        [Contract.AzureComputeVirtualMachineStatus.Running]: "Running",
                        [Contract.AzureComputeVirtualMachineStatus.Starting]: "Starting",
                        [Contract.AzureComputeVirtualMachineStatus.Stopped]: "Stopped",
                        [Contract.AzureComputeVirtualMachineStatus.Stopping]: "Stopping"
                    }
                },
                virtualMachineStatus: "The virtual machine's status is {{status}}",
                virtualNetwork: "The virtual machine is part of virtual network {{virtualNetwork}} and {{subnets}}"
            }));
    return <Steps variant="bullets">
        {_.filter([
            resourceGeneralInformationStep,
            !_.isNil(virtualMachine) &&
            localization.virtualMachineStatus({
                status: localization.status[Contract.TypeNames.AzureComputeVirtualMachineStatus][virtualMachine.status]()
            }),
            !_.isNil(virtualMachine) &&
            localization.virtualNetwork({
                subnets:
                    <InlineEntities
                        entityIdsOrModels={virtualMachine.subnetIds!}
                        entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}
                        variant="itemCountAndType"/>,
                virtualNetwork:
                    <Entity
                        entityIdOrModel={virtualMachine.virtualNetworkId!}
                        variant="text"/>
            }),
            !_.isEmpty(risk.interfaceNetworkSecurityGroupIds) ||
            !_.isEmpty(risk.subnetNetworkSecurityGroupIds)
                ? localization.networkSecurityGroup.attached({
                    exposedNetworkScopes:
                        <InlineItems
                            items={
                                _.map(
                                    risk.exposedNetworkScopes,
                                    networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                            namePluralizer={localization.exposedNetworkScopes}
                            variant="itemCountAndType"/>,
                    networkSecurityGroups:
                        <InlineEntities
                            entityIdsOrModels={
                                _(risk.interfaceNetworkSecurityGroupIds).
                                    concat(risk.subnetNetworkSecurityGroupIds).
                                    uniq().
                                    value()}
                            entityTypeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}
                            variant="itemAndTypeOrItemCountAndType"/>
                })
                : localization.networkSecurityGroup.notAttached(),
            !virtualMachineModel.unknown &&
            !_.isEmpty(virtualMachineModel.userManagedIdentityServicePrincipalIds) &&
            localization.managedIdentities({
                managedIdentities:
                    <InlineEntities
                        entityIdsOrModels={virtualMachineModel.userManagedIdentityServicePrincipalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal}
                        variant="itemOrItemCountAndType"/>
            }),
            severityReasonTranslator(
                risk.severity,
                risk.severityReason!,
                {
                    exposedManagementNetworkScopes:
                        <InlineItems
                            items={
                                _.map(
                                    risk.exposedManagementNetworkScopes,
                                    networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                            namePluralizer={localization.exposedManagementNetworkScopes}
                            variant="itemCountAndType"/>,
                    exposedNetworkScopes:
                        <InlineItems
                            items={
                                _.map(
                                    risk.exposedNetworkScopes,
                                    networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                            namePluralizer={localization.exposedNetworkScopes}
                            variant="itemCountAndType"/>,
                    servicePrincipalPermissionsSeverity:
                        _.isNil(risk.servicePrincipalPermissionsActionSeverity)
                            ? undefined
                            : severityTranslator(
                                risk.servicePrincipalPermissionsActionSeverity,
                                "text")
                })
        ])}
    </Steps>;
}