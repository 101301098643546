import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAwsResourceAccessLevelTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsResourceAccessLevelTranslator",
            () => ({
                [Contract.TypeNames.AwsResourceAccessLevel]: {
                    [Contract.AwsResourceAccessLevel.CrossTenant]: "Cross account",
                    [Contract.AwsResourceAccessLevel.Internal]: "Internal",
                    [Contract.AwsResourceAccessLevel.Public]: "Public"
                }
            }));

    return (accessLevel: Contract.AwsResourceAccessLevel) =>
        localization[Contract.TypeNames.AwsResourceAccessLevel][accessLevel]();
}