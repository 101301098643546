import { Optional, useActions, useLocalization } from "@infrastructure";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { AwsResourceUsageRiskPolicyEdit, EntitySelector, EntitySelectorSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsSsoPermissionSetAssignmentRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const [permissionSetsValid, setPermissionSetsValid] = useState(false);
    const { scopeId } = useCustomRiskPolicyContext();

    const [permissionSetSelectorSelection, setPermissionSetSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.allResources,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.resourceIds,
                riskPolicyConfiguration?.resourceNamePattern,
                undefined,
                riskPolicyConfiguration?.resourceTags,
                riskPolicyConfiguration?.permissionSetTenantIds));

    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityNamePattern, entityTags, entityTenantIds } = permissionSetSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAwsSsoPermissionSetAssignmentRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityNamePattern,
                        entityTags,
                        entityTenantIds);
                }
        });

    useEffect(
        () => {
            const permissionSetsValid = permissionSetSelectorSelection?.valid() ?? false;
            setPermissionSetsValid(permissionSetsValid);

            onValidChange(permissionSetsValid);
        },
        [permissionSetSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsSsoPermissionSetAssignmentRiskPolicy.edit",
            () => ({
                prompt: "Select the required {{permissionSetTranslatedTypeName}}"
            }));
    return (
        <AwsResourceUsageRiskPolicyEdit
            accordionContents={[
                {
                    selector:
                        <EntitySelector
                            builtInEntityAttributeTypeNames={[
                                Contract.TypeNames.AdministratorPrincipalAttribute,
                                Contract.TypeNames.PublicEntityAttribute,
                                Contract.TypeNames.SensitiveResourceAttribute,
                                Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                Contract.TypeNames.CodeResourceAttribute]}
                            entityTypeName={Contract.TypeNames.AwsSsoPermissionSet}
                            namePattern={true}
                            properties={["all", "tags", "attributes", "tenantIds"]}
                            scopeId={scopeId}
                            selection={permissionSetSelectorSelection}
                            onSelectionChanged={setPermissionSetSelectorSelection}/>,
                    title:
                        entityTypeNameTranslator(
                            Contract.TypeNames.AwsSsoPermissionSet,
                            {
                                count: 0,
                                includeServiceName: false
                            }),
                    valid: permissionSetsValid
                }
            ]}
            prompt={localization.prompt({
                permissionSetTranslatedTypeName:
                    entityTypeNameTranslator(
                        Contract.TypeNames.AwsSsoPermissionSet,
                        {
                            count: 0,
                            includeServiceName: false,
                            variant: "text"
                        })
            })}
            template={
                templateTranslator({
                    permissionSets:
                        <InlineEntitySelection
                            entityTypeName={Contract.TypeNames.AwsSsoPermissionSet}
                            selection={permissionSetSelectorSelection}/>
                })}/>);
}