﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItemLocation } from "../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../components";

type GcpIamServiceAccountOriginatorResourcesInfoItemProps = {
    location?: InfoItemLocation;
    originatorResourceIds: string[];
};

export function GcpIamServiceAccountOriginatorResourcesInfoItem({ originatorResourceIds }: GcpIamServiceAccountOriginatorResourcesInfoItemProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.gcpIamServiceAccountOriginatorResourcesInfoItem",
            () => ({
                originatorResourceIds: "Assigned To"
            }));
    return (
        <EntitiesInfoItem
            entityIdsOrModels={originatorResourceIds}
            entityTypeName={Contract.TypeNames.GcpResource}
            key="originatorResourceIds"
            title={localization.originatorResourceIds()}/>);
}