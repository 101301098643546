﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetAwsAutoScalingAutoScalingGroupsRiskContext() {
    return useMemo(
        () => useAwsAutoScalingAutoScalingGroupsRiskContext,
        []);
}

function useAwsAutoScalingAutoScalingGroupsRiskContext(autoScalingGroupIdsOrModels: string[] | Contract.AwsAutoScalingAutoScalingGroupModel[]) {
    return useGetEntitiesRiskContext()(
        autoScalingGroupIdsOrModels,
        Contract.TypeNames.AwsAutoScalingAutoScalingGroup);
}