import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function TenantsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_59326_1194)">
                <path
                    clipRule="evenodd"
                    d="M4.79596 1.14301C5.30043 1.03219 5.88422 1 6.52637 1H12.5264C13.1685 1 13.7523 1.03219 14.2568 1.14301C14.7726 1.25632 15.2671 1.46503 15.6642 1.86214C16.0613 2.25926 16.27 2.7538 16.3834 3.26959C16.4942 3.77406 16.5264 4.35786 16.5264 5V7H18.5264C19.1685 7 19.7523 7.03219 20.2568 7.14301C20.7726 7.25632 21.2671 7.46503 21.6642 7.86214C22.0613 8.25926 22.27 8.7538 22.3834 9.26959C22.4942 9.77406 22.5264 10.3579 22.5264 11V22C22.5264 22.5523 22.0787 23 21.5264 23H3.52637C2.97408 23 2.52637 22.5523 2.52637 22V5C2.52637 4.35786 2.55855 3.77406 2.66938 3.26959C2.78269 2.7538 2.9914 2.25926 3.38851 1.86214C3.78562 1.46503 4.28016 1.25632 4.79596 1.14301ZM16.5264 9V21H20.5264V11C20.5264 10.4011 20.4938 9.98944 20.4299 9.69872C20.3686 9.41933 20.2926 9.31899 20.25 9.27636C20.2074 9.23372 20.107 9.15781 19.8276 9.09643C19.5369 9.03256 19.1252 9 18.5264 9H16.5264ZM14.5264 21V5C14.5264 4.40114 14.4938 3.98944 14.4299 3.69872C14.3686 3.41933 14.2926 3.31899 14.25 3.27636C14.2074 3.23372 14.107 3.15781 13.8276 3.09643C13.5369 3.03256 13.1252 3 12.5264 3H6.52637C5.92751 3 5.5158 3.03256 5.22509 3.09643C4.94569 3.15781 4.84536 3.23372 4.80272 3.27636C4.76009 3.31899 4.68417 3.41933 4.6228 3.69872C4.55893 3.98944 4.52637 4.40114 4.52637 5V21H14.5264ZM6.52637 6C6.52637 5.44772 6.97408 5 7.52637 5H11.5264C12.0787 5 12.5264 5.44772 12.5264 6C12.5264 6.55228 12.0787 7 11.5264 7H7.52637C6.97408 7 6.52637 6.55228 6.52637 6ZM6.52637 10C6.52637 9.44772 6.97408 9 7.52637 9H11.5264C12.0787 9 12.5264 9.44772 12.5264 10C12.5264 10.5523 12.0787 11 11.5264 11H7.52637C6.97408 11 6.52637 10.5523 6.52637 10Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M0.526367 22C0.526367 21.4477 0.924336 21 1.41526 21H23.6375C24.1284 21 24.5264 21.4477 24.5264 22C24.5264 22.5523 24.1284 23 23.6375 23H1.41526C0.924336 23 0.526367 22.5523 0.526367 22Z"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_59326_1194">
                    <rect
                        fill="white"
                        height="24"
                        transform="translate(0.526367)"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}