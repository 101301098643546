﻿import { ApiError, CheckboxField, InfoIcon, ItemSelector, Link, Optional, Tooltip, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, RadioField, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function ServerDetailsItem() {
    const setAddOrEditContext = useSetAddOrEditContext();
    const { serverModel } = useAddOrEditContext();

    const gitLabServerConfiguration = serverModel?.configuration as Optional<Contract.GitLabServerConfiguration>;

    const [endpointConnectorAvailableTunnelSessionClientIds] =
        useExecuteOperation(
            ServerDetailsItem,
            async () => {
                const { endpointConnectorTunnelSessionClientIds } = await ConfigurationController.getEndpointConnectorTunnelSessionClientIds();
                const { endpointConnectorIdToIntegrationNameMap } = await ConfigurationController.getEndpointConnectorIdToIntegrationNameMap();
                return _.difference(
                    endpointConnectorTunnelSessionClientIds,
                    _.keys(endpointConnectorIdToIntegrationNameMap));
            });
    const endpointConnectorModels = scopeSystemEntityModelStore.useGetEndpointConnector();
    const endpointConnectorConfigurations =
        useMemo(
            () =>
                _(endpointConnectorModels).
                    filter(
                        endpointConnectorModel =>
                            endpointConnectorModel.configuration.id == gitLabServerConfiguration?.endpointConnectorId ||
                            endpointConnectorAvailableTunnelSessionClientIds.includes(endpointConnectorModel.configuration.id)).
                    map(endpointConnectorModel => (endpointConnectorModel.configuration as Contract.EndpointConnectorConfiguration)).
                    value(),
            [endpointConnectorModels]);
    const updatedEndpointConnectorConfiguration =
        useMemo(
            () =>
                _.find(
                    endpointConnectorConfigurations,
                    endpointConnectorConfiguration => endpointConnectorConfiguration.id === gitLabServerConfiguration?.endpointConnectorId)
            , [endpointConnectorConfigurations, gitLabServerConfiguration]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.gitLabServer.addOrEditGitLabServer.serverDetailsItem",
            () => ({
                error: {
                    general: "Failed to validate endpoint",
                    [Contract.TypeNames.GitLabServerManagerError]: {
                        [Contract.GitLabServerManagerError.CommonErrorEndpointInvalid]: "Unreachable GitLab Self-Managed Server Endpoint",
                        [Contract.GitLabServerManagerError.CommonErrorEndpointNotExist]: "Endpoint Connector integration is not connected",
                        [Contract.GitLabServerManagerError.CommonErrorServerCertificateValidationFailed]: "SSL certificate validation fails. Select the checkbox to accept any server SSL certificates.",
                        [Contract.GitLabServerManagerError.CommonErrorServerNameExists]: "Server name already exists",
                        [Contract.GitLabServerManagerError.CommonErrorServerResponseInvalid]: "GitLab Self-Managed Server response indicates error on the server side",
                        [Contract.GitLabServerManagerError.InsertServerErrorServerExists]: "Server already exists"
                    }
                },
                fields: {
                    endpoint: {
                        title: "Endpoint",
                        tooltip: "e.g. gitlab.tenable.com or 192.82.156.1:8443"
                    },
                    endpointConnectorUsed: {
                        description: {
                            link: "click here",
                            title: "Select how Tenable connects to your server. If your server is network-isolated, use a Tenable Endpoint Connector to establish connection. If the connector option is disabled, {{addConnectorWizardLink}} to add one."
                        },
                        no: "Endpoint (internet-facing)",
                        yes: "Endpoint Connector (network-isolated)"
                    },
                    name: {
                        title: "Name",
                        tooltip: "The server name, as it will appear in the Tenable Cloud Security Console."
                    },
                    serverCertificateValidationEnabled: "Accept any server SSL certificates"
                }
            }));

    const [endpointConnectorEnabled, setEndpointConnectorEnabled] = useState(!_.isNil(gitLabServerConfiguration?.endpointConnectorId));
    const [serverEndpoint, setServerEndpoint] = useState(gitLabServerConfiguration?.endpoint ?? "");
    const [selectedEndpointConnectorConfiguration, setSelectedEndpointConnectorConfiguration] = useState(updatedEndpointConnectorConfiguration ?? _.first(endpointConnectorConfigurations));

    const [serverCertificateValidationEnabled, setServerCertificateValidationEnabled] = useState(gitLabServerConfiguration?.serverCertificateValidationEnabled ?? true);
    const [serverName, setServerName] = useState(gitLabServerConfiguration?.name ?? "");

    const { executing, setValid, useNextEffect } = useOrderedWizardContext();

    useEffect(
        () => {
            setValid(
                ((endpointConnectorEnabled && !!selectedEndpointConnectorConfiguration) ||
                    (!endpointConnectorEnabled && !!serverEndpoint)) &&
                !!serverName);
        },
        [selectedEndpointConnectorConfiguration, serverEndpoint, serverName, endpointConnectorEnabled]);

    useNextEffect(
        async () => {
            try {
                const { codeServerModel } =
                    _.isNil(gitLabServerConfiguration)
                        ? await ConfigurationController.insertGitLabServer(
                            new Contract.ConfigurationControllerInsertGitLabServerRequest(
                                endpointConnectorEnabled
                                    ? selectedEndpointConnectorConfiguration?.id
                                    : undefined,
                                serverName!,
                                endpointConnectorEnabled
                                    ? undefined
                                    : serverEndpoint,
                                serverCertificateValidationEnabled!))
                        : await ConfigurationController.updateGitLabServer(
                            new Contract.ConfigurationControllerUpdateGitLabServerRequest(
                                gitLabServerConfiguration.id,
                                serverName!,
                                serverCertificateValidationEnabled!));
                setAddOrEditContext(
                    context => ({
                        ...context,
                        serverModel: codeServerModel
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? localization.error[Contract.TypeNames.GitLabServerManagerError].translate(error.error)
                    : localization.error.general();
            }
        }, [serverCertificateValidationEnabled, serverEndpoint, serverName]);

    const endpointConnectorConfigurationItems =
        useMemo(
            () =>
                _.isNil(gitLabServerConfiguration)
                    ? endpointConnectorConfigurations
                    : [updatedEndpointConnectorConfiguration],
            [gitLabServerConfiguration, updatedEndpointConnectorConfiguration, endpointConnectorConfigurations]);
    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(70) }}>
            <TextField
                disabled={executing}
                label={localization.fields.name.title()}
                slotProps={{
                    input: {
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip titleOrGetTitle={localization.fields.name.tooltip()}>
                                    <InfoIcon
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontSize: "24px"
                                        }}/>
                                </Tooltip>
                            </InputAdornment>
                    }
                }}
                value={serverName}
                onChange={event => setServerName(event.target.value)}/>
            <Stack
                spacing={2}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    maxWidth: theme.spacing(70),
                    padding: theme.spacing(2)
                }}>
                <Typography>
                    {localization.fields.endpointConnectorUsed.description.title({
                        addConnectorWizardLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsEndpointConnectorRelativeUrl(true)}
                                variant="external">
                                {localization.fields.endpointConnectorUsed.description.link()}
                            </Link>
                    })}
                </Typography>
                <RadioField
                    checked={!endpointConnectorEnabled}
                    title={localization.fields.endpointConnectorUsed.no()}
                    onSelected={
                        () => setEndpointConnectorEnabled(false)}>
                    <TextField
                        disabled={executing || !_.isNil(gitLabServerConfiguration)}
                        fullWidth={true}
                        label={localization.fields.endpoint.title()}
                        slotProps={{
                            input: {
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Tooltip titleOrGetTitle={localization.fields.endpoint.tooltip()}>
                                            <InfoIcon
                                                sx={{
                                                    color: theme.palette.text.secondary,
                                                    fontSize: "24px"
                                                }}/>
                                        </Tooltip>
                                    </InputAdornment>
                            }
                        }}
                        value={serverEndpoint}
                        onChange={event => setServerEndpoint(event.target.value)}/>
                </RadioField>
                <RadioField
                    checked={endpointConnectorEnabled}
                    indent={false}
                    title={localization.fields.endpointConnectorUsed.yes()}
                    onSelected={
                        () => setEndpointConnectorEnabled(true)}>
                    <ItemSelector
                        disabled={executing || !_.isNil(gitLabServerConfiguration) || !_.size(endpointConnectorConfigurationItems)}
                        fullWidth={true}
                        getItemText={(endpointConnectorConfiguration: Contract.EndpointConnectorConfiguration) => endpointConnectorConfiguration.name}
                        items={endpointConnectorConfigurationItems}
                        selectedItem={selectedEndpointConnectorConfiguration}
                        sorted={true}
                        onSelectedItemChanged={setSelectedEndpointConnectorConfiguration}>
                        {(endpointConnectorConfiguration: Contract.EndpointConnectorConfiguration) => endpointConnectorConfiguration.name}
                    </ItemSelector>
                </RadioField>
            </Stack>
            <CheckboxField
                checked={!serverCertificateValidationEnabled}
                disabled={executing}
                onChange={() => setServerCertificateValidationEnabled(!serverCertificateValidationEnabled)}>
                {localization.fields.serverCertificateValidationEnabled()}
            </CheckboxField>
        </Stack>);
}