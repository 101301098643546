import { Action0, ExternalLinkIcon, IconText, MouseHelper, Optional, setUrlRoute } from "@infrastructure";
import { Link as MuiLink, LinkProps as MuiLinkProps, SxProps, Theme, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ForwardedRef, Fragment, HTMLAttributeAnchorTarget, memo, MouseEvent, ReactNode, useCallback } from "react";

const LinkMemo = memo(Link);
export { LinkMemo as Link };

export type LinkProps = Omit<MuiLinkProps, "variant"> & {
    children?: ReactNode;
    customOnClick?: (event: MouseEvent) => void;
    disabled?: boolean;
    disableIcon?: boolean;
    download?: boolean;
    iconSx?: SxProps;
    linkRef?: ForwardedRef<HTMLAnchorElement>;
    onClick?: (event: MouseEvent) => void;
    onMouseEnter?: Action0;
    onMouseLeave?: Action0;
    sx?: SxProps<Theme>;
    target?: HTMLAttributeAnchorTarget;
    urlOrGetUrl?: string | (() => Optional<string>);
    variant?: "external" | "internal" | "text";
};

function Link({ children, customOnClick, disabled, disableIcon = false, download, iconSx, linkRef, onClick, onMouseEnter, onMouseLeave, sx, target, urlOrGetUrl, variant = "internal", ...props }: LinkProps) {
    disabled = disabled || _.isNil(urlOrGetUrl);
    const getUrl =
        useCallback(
            () =>
                _.isFunction(urlOrGetUrl)
                    ? urlOrGetUrl()
                    : urlOrGetUrl,
            [urlOrGetUrl]);

    const theme = useTheme();
    return (
        <Fragment>
            {disabled
                ? children
                : <MuiLink
                    download={download}
                    href={getUrl()}
                    ref={linkRef}
                    sx={{
                        "&:hover": {
                            color:
                                variant === "external"
                                    ? theme.palette.link.secondary
                                    : variant === "internal"
                                        ? theme.palette.link.primary
                                        : (sx as { color?: string })?.color,
                            textDecoration:
                                variant === "text"
                                    ? "none"
                                    : undefined
                        },
                        alignItems:
                            variant === "external"
                                ? "center"
                                : "unset",
                        color:
                            variant === "internal" || variant === "external"
                                ? theme.palette.link.primary
                                : undefined,
                        display:
                            !disableIcon && variant === "external"
                                ? "inline-flex"
                                : undefined,
                        textAlign: "start",
                        textDecorationColor:
                            variant === "internal"
                                ? theme.palette.link.primary
                                : "inherit",
                        verticalAlign:
                            variant === "external"
                                ? "baseline"
                                : "unset",
                        width: "inherit",
                        wordBreak: "break-word",
                        ...sx
                    }}
                    target={
                        target ??
                            (variant === "external"
                                ? "_blank"
                                : undefined)}
                    underline={
                        props?.underline
                            ? props.underline
                            : variant === "internal"
                                ? "always"
                                : "none"
                    }
                    onClick={
                        !_.isNil(customOnClick)
                            ? customOnClick
                            : (event: MouseEvent) => {
                                if (download) {
                                    return;
                                }

                                if (MouseHelper.isGlanceClick(event)) {
                                    event.preventDefault();
                                    return;
                                }
                                onClick?.(event);

                                if (variant !== "external" &&
                                    target !== "_blank" &&
                                    !MouseHelper.isAlterActionClick(event)) {

                                    if (getUrl()) {
                                        setUrlRoute(getUrl()!);
                                    }

                                    event.preventDefault();
                                    event.stopPropagation();
                                } else if (variant === "external") {
                                    event.stopPropagation();
                                }
                            }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    {...props}>
                    {!disableIcon && variant === "external"
                        ? <IconText
                            containerSx={{
                                "&:hover": {
                                    color: theme.palette.link.secondary
                                },
                                color: theme.palette.link.primary
                            }}
                            direction="rtl"
                            icon={<ExternalLinkIcon/>}
                            iconSx={iconSx}
                            spacing={0.5}
                            text={
                                <Typography
                                    noWrap={true}
                                    sx={{ fontSize: "inherit" }}>
                                    {children ?? getUrl()}
                                </Typography>}/>
                        : children ?? getUrl()}
                </MuiLink>}
        </Fragment>);
}