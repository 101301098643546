import { Loading, Message, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Access, Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, EntityPropertyHelper, RiskTypeMetadataModelHelper, useRiskPolicyTranslator, useTheme } from "../../../../../../../../../../../../common";
import { RiskView } from "../../../../../../../../utilities";
import { AccessRiskDefinitionOptions, RiskDefinitionSection, RiskDefinitionSectionCategory, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { Resolve } from "../../../../components";
import { useResourceOwnerRiskDefinitionSection } from "../../../useResourceOwnerRiskDefinitionSection";

type AzureCommonAccessPrincipalRiskDefinitionOptions =
    AccessRiskDefinitionOptions & {
        accessGraphActionsElement?: ReactNode;
        customResolutionSection?: RiskDefinitionSection;
    };

export function useAzureCommonAccessPrincipalRiskDefinition(
    accessScope: Contract.EntityAccessScope,
    contextSectionContentElement: ReactNode,
    description: string,
    riskModel: Contract.RiskModel,
    options?: AzureCommonAccessPrincipalRiskDefinitionOptions) {
    const accessPrincipalRisk = riskModel.risk as Contract.AzureAccessPrincipalRisk;
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const principalModel = entityModelStore.useGet(accessPrincipalRisk.entityId);
    const resourceOwnerRiskDefinitionSection = useResourceOwnerRiskDefinitionSection(riskModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureCommonAccessPrincipalRiskDefinition",
            () => ({
                sections: {
                    accessGraph: {
                        helpText: "This view displays {{principal}}’s permissions on {{tenantEntity}} only. {{principal}} may also have other permissions on other subscriptions.",
                        title: "Permissions and usage of {{principal}} on {{tenantEntity}}"
                    },
                    context: "Context",
                    customResolution: "Remediation steps",
                    policyDescription: "Policy",
                    resolution: {
                        riskNotResolvedMessage: "Steps that are not marked as successful must be performed manually as Tenable Cloud Security does not have sufficient permissions to perform them.",
                        steps: "Remediation steps"
                    }
                }
            }));


    const theme = useTheme();
    return useMemo(
        () => ({
            description,
            sections: _([
                new RiskDefinitionSection(
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {riskPolicyTranslator(RiskTypeMetadataModelHelper.get(accessPrincipalRisk.typeName).policyConfigurationTypeName).description}
                    </Typography>,
                    localization.sections.policyDescription(),
                    {
                        resolvedRiskVisible: true
                    }),
                new RiskDefinitionSection(
                    contextSectionContentElement,
                    localization.sections.context()),
                EntityPropertyHelper.showRiskResourceOwner(riskModel)
                    ? resourceOwnerRiskDefinitionSection
                    : undefined,
                principalModel.entity.systemDeleted
                    ? undefined
                    : new RiskDefinitionSection(
                        <Box
                            sx={{
                                minHeight: theme.spacing(20),
                                width: "100%"
                            }}>
                            <Loading>
                                <Access
                                    baseUrl={
                                        CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(
                                            accessPrincipalRisk.id,
                                            { category: RiskDefinitionSectionCategory.Overview })}
                                    destinationResourceTenantId={accessPrincipalRisk.tenantId}
                                    entityId={principalModel.entity.id}
                                    scope={accessScope}
                                    tenantType={Contract.TenantType.Azure}
                                    variant="risk"/>
                            </Loading>
                        </Box>,
                        localization.sections.accessGraph.title({
                            principal:
                            <Entity
                                entityIdOrModel={principalModel}
                                variant="typeText"/>,
                            tenantEntity:
                            <Entity
                                entityIdOrModel={accessPrincipalRisk.tenantId}
                                variant="typeText"/>
                        }),
                        {
                            actionsElement:
                            options?.accessGraphActionsElement ??
                            <Message
                                level="info"
                                title={
                                    localization.sections.accessGraph.helpText({
                                        principal:
                                            <Entity
                                                entityIdOrModel={principalModel}
                                                variant="text"/>,
                                        tenantEntity:
                                            <Entity
                                                entityIdOrModel={accessPrincipalRisk.tenantId}
                                                variant="typeText"/>
                                    })}
                                variant="minimal"/>,
                            profilePageOnly: true
                        }),
                options?.resolutionSectionDefinitionComponent == undefined
                    ? undefined
                    : new RiskDefinitionSection(
                        <options.resolutionSectionDefinitionComponent riskModel={riskModel}/>,
                        localization.sections.resolution.steps(),
                        {
                            actionsElement:
                            <Box
                                alignItems="center"
                                justifyContent="flex-end"
                                sx={{
                                    display: "flex",
                                    width: "100%"
                                }}>
                                <Resolve riskNotResolvedMessage={localization.sections.resolution.riskNotResolvedMessage()}/>
                            </Box>,
                            categoryView: {
                                category: RiskDefinitionSectionCategory.Resolution,
                                view:
                                    _.isNil(options?.customResolutionSection)
                                        ? RiskView.ResolutionConsole
                                        : (accessPrincipalRisk.resolutionChanges?.length ?? 0) > 1
                                            ? RiskView.ResolutionRoles
                                            : RiskView.ResolutionRole

                            }
                        }),
                options?.customResolutionSection
            ]).
                filter().
                as<RiskDefinitionSection | RiskDefinitionSectionGroup>().
                value()
        }),
        [accessScope, contextSectionContentElement, description, options, principalModel, resourceOwnerRiskDefinitionSection, riskModel]);
}