import { Link, NumberFormatter, Shadows, Tooltip, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useState } from "react";
import { Contract, ScopeHelper, TenantIcon, TenantIconData, UserHelper, useTheme } from "../..";
import { TenantModelStatusSeverityItem } from "./components";

type TenantTypeStatusProps = {
    item: TenantTypeStatusItem;
};

export class TenantTypeStatusItem {
    constructor(
        public getTenantStatusTranslation: (status: string, tenantModelCount: number) => string,
        public tenantCount: number,
        public tenantModelStatusSeverityToStatusToCount: Dictionary<Dictionary<number>>,
        public tenantPluralizationText: string,
        public tenantType: Contract.TenantType,
        public tenantTypeDisplayName: string,
        public validTenantStatusToCount: Dictionary<number>,
        public disabled?: boolean,
        public iconData?: TenantIconData,
        public interactive?: boolean,
        public tooltip?: string,
        public url?: string) {
        this.interactive ??= true;
    }
}

export function TenantTypeStatus({ item }: TenantTypeStatusProps) {
    const { disabled, getTenantStatusTranslation, iconData, interactive, tenantCount, tenantModelStatusSeverityToStatusToCount, tenantPluralizationText, tenantType, tenantTypeDisplayName, tooltip, url, validTenantStatusToCount } = item;
    const [hover, setHover] = useState(false);

    const localization =
        useLocalization(
            "common.tenantTypeStatus",
            () => ({
                tenant: "{{formattedTenantCount}} {{tenantPluralizationText}}"
            }));

    const theme = useTheme();

    return (
        <Link
            disabled={
                !UserHelper.hasScopePermissions(ScopeHelper.customerId, Contract.IdentityPermission.SecurityAdministrationRead) ||
                !interactive ||
                disabled}
            urlOrGetUrl={url}
            variant="text">
            <Tooltip titleOrGetTitle={tooltip}>
                <Stack
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        backgroundColor:
                            tenantCount === 0
                                ? theme.palette.action.disabled
                                : theme.palette.background.alternate,
                        border:
                            tenantCount === 0
                                ? undefined
                                : theme.border.alternate,
                        borderRadius: theme.spacing(0.75),
                        boxShadow:
                            hover && tenantCount > 0
                                ? theme.shadows[Shadows.Highlight]
                                : undefined,
                        height: "100%",
                        minHeight: theme.spacing(22.5),
                        padding: theme.spacing(1.5, 1.5, 3.5, 1.5)
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    <Stack
                        alignItems="center"
                        sx={{ width: "100%" }}>
                        <TenantIcon
                            colored={tenantCount > 0}
                            data={iconData}
                            sx={{
                                color:
                                    tenantCount === 0
                                        ? theme.palette.text.secondary
                                        : undefined,
                                fontSize: "38px",
                                marginBottom: theme.spacing(1.5)
                            }}
                            tenantType={tenantType}/>
                        {<Typography
                            noWrap={true}
                            sx={{
                                color:
                                    tenantCount === 0
                                        ? theme.palette.text.secondary
                                        : undefined,
                                fontSize: "13px",
                                fontWeight: 600,
                                lineHeight: "1.2",
                                marginBottom: theme.spacing(0.75),
                                maxWidth: "100%"
                            }}>
                            {tenantTypeDisplayName}
                        </Typography>}
                        <Typography
                            noWrap={true}
                            sx={{
                                color:
                                    tenantCount === 0
                                        ? theme.palette.text.secondary
                                        : undefined,
                                fontSize: "13px",
                                lineHeight: "1.2",
                                marginBottom: theme.spacing(2.75),
                                maxWidth: "100%"
                            }}>
                            {localization.tenant({
                                formattedTenantCount:
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontSize: "unset",
                                            fontWeight: 600,
                                            lineHeight: "unset"
                                        }}>
                                        {NumberFormatter.humanize(tenantCount)}
                                    </Typography>,
                                tenantPluralizationText
                            })}
                        </Typography>
                    </Stack>
                    <Stack
                        flexDirection="row"
                        spacing={0}
                        sx={{
                            maxWidth: "100%",
                            overflow: "hidden"
                        }}>
                        {!_.isEmpty(validTenantStatusToCount) &&
                            <TenantModelStatusSeverityItem
                                getTenantStatusTranslation={getTenantStatusTranslation}
                                tenantModelsStatusToCount={validTenantStatusToCount}/>}
                        {_.map(
                            tenantModelStatusSeverityToStatusToCount,
                            (tenantModelsStatusToCount, tenantModelStatusSeverity) =>
                                <TenantModelStatusSeverityItem
                                    getTenantStatusTranslation={getTenantStatusTranslation}
                                    key={tenantModelStatusSeverity}
                                    severity={tenantModelStatusSeverity as Contract.Severity}
                                    tenantModelsStatusToCount={tenantModelsStatusToCount}/>)}
                    </Stack>
                </Stack>
            </Tooltip>
        </Link>);
}