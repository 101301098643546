﻿import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../controllers";
import { SpecificCustomRiskPolicyProps } from "../../CustomRiskPolicy";
import { Edit, View } from "./components";

export function AzureResourceCodeResourceNotExistsRiskPolicy({ editOptions, riskPolicyConfiguration, sx }: SpecificCustomRiskPolicyProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.resourceCodeResourceNotExistsRiskPolicy.azureResourceCodeResourceNotExistsRiskPolicy",
            () => ({
                template: "*capitalize*{{resource}}** should be managed as code"
            }));
    return _.isNil(editOptions)
        ? <View
            entityTypeName={Contract.TypeNames.AzureResource}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AzureResourceCodeResourceNotExistsRiskPolicyConfiguration>}
            sx={sx}
            templateTranslator={localization.template}/>
        : <Edit
            actionsRef={editOptions.actionsRef}
            createRequest={
                (
                    allEntities,
                    description,
                    name,
                    entityBuiltInAttributeTypeNames,
                    entityCustomAttributeDefinitionIds,
                    entityIds,
                    entityTags,
                    entityTypes,
                    scopeId,
                    severity) =>
                    new Contract.RiskControllerInsertAzureResourceCodeResourceNotExistsRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityTags,
                        entityTypes)}
            entityTypeName={Contract.TypeNames.AzureResource}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AzureResourceCodeResourceNotExistsRiskPolicyConfiguration>}
            templateTranslator={localization.template}
            onValidChange={editOptions.onValidChange}/>;
}