import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useGciDefaultEntityInfoItemElements } from "./useGciDefaultEntityInfoItemElements";

export function useGciTenantEntityDefinition(entityModel: Contract.GciEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = useGciDefaultEntityInfoItemElements(entityModel);
    const tenantModel = entityModel as Contract.GciTenantEntityModel;
    const tenantEntity = tenantModel.entity as Contract.GciTenantEntity;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciTenantEntityDefinition",
            () => ({
                info: {
                    items: {
                        primaryDomainName: "Primary Domain Name",
                        rawId: "Customer ID"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={tenantEntity.tenantId}/>,
                    <InfoItem
                        key="primaryDomainName"
                        title={localization.info.items.primaryDomainName()}
                        value={tenantEntity.primaryDomainName}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}