import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { useSetAadTenantsContext } from "../../../AadTenants";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.AadTenantModel>) {
    const [deleteAadTenantExecuting, setDeleteAadTenantExecuting] = useState(false);
    const [deleteAadTenantError, setDeleteAadTenantError] = useState(false);

    async function deleteAadTenant() {
        setDeleteAadTenantExecuting(true);
        setDeleteAadTenantError(false);
        try {
            await tenantModelStore.updateDeleted(item.configuration.id);
        } catch {
            setDeleteAadTenantError(true);
        }
        setDeleteAadTenantExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.aadTenants.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete tenant",
                        prompt: "Are you sure you want to delete the tenant {{tenantName}}?\n(this will also delete all related subscriptions)",
                        title: "Delete",
                        tooltip: {
                            organizationExists: "This tenant belongs to an organization, and can't be deleted"
                        }
                    },
                    edit: "Edit"
                }
            }));

    const setAadTenantsContext = useSetAadTenantsContext();
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteAadTenantExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteAadTenantError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {!item.configuration.systemDeleted && (
                <Menu
                    itemsOrGetItems={
                        _<ActionMenuItem>([]).
                            concatIf(
                                !_.isNil(item.configuration.application),
                                new ActionMenuItem(
                                    () => setAadTenantsContext(
                                        aadTenantsContext => ({
                                            ...aadTenantsContext,
                                            addOrEditOpen: item.configuration
                                        })),
                                    localization.actions.edit(),
                                    {
                                        icon: <EditIcon/>
                                    })).
                            concat(
                                new ActionMenuItem(
                                    () => deleteAadTenant(),
                                    localization.actions.delete.title(),
                                    {
                                        confirmOptions: {
                                            message: localization.actions.delete.prompt({ tenantName: item.configuration.name })
                                        },
                                        disabled: deleteAadTenantExecuting || !_.isNil(item.configuration.azureOrganizationId),
                                        icon: <DeleteIcon/>,
                                        tooltip:
                                            !_.isNil(item.configuration.azureOrganizationId)
                                                ? localization.actions.delete.tooltip.organizationExists()
                                                : undefined
                                    })).
                            value()}/>
            )}
        </Stack>);
}