﻿import { map, Message, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, FormControl, FormHelperText, Radio, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, PagedResourceTagKeySelector, PagedResourceTagSelector } from "../../../../..";
import { InlineTagsSelectionData } from "../..";
import { useTheme } from "../../../../../themes";

type TagSelectorProps = {
    onSelectionChanged: (selection?: TagSelectorSelection) => void;
    scopeId: string;
    selection?: TagSelectorSelection;
    tenantTypes: Contract.TenantType[];
};

export function TagSelector({ onSelectionChanged, scopeId, selection, tenantTypes }: TagSelectorProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.resourceTagNotExistsRiskPolicy.tagSelector",
            () => ({
                fields: {
                    existsResourceTagKeys: {
                        placeholder: "Select Tag Keys",
                        title: "Exact tag keys"
                    },
                    existsResourceTagPattern: {
                        helpText: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character.",
                        key: {
                            emptyError: "Key cannot be empty",
                            lengthExceedError: "Tag key length cannot exceed 128 characters",
                            placeholder: "Tag Key Pattern"
                        },
                        title: "Tag key and value pattern",
                        value: {
                            emptyError: "Value cannot be empty",
                            lengthExceedError: "Tag value length cannot exceed 256 characters",
                            placeholder: "Tag Value Pattern"
                        }
                    },
                    existsResourceTags: {
                        placeholder: "Select Tags",
                        title: "Exact tag keys and values"
                    }
                }
            }));

    const [existsTagKeySelection, setExistsTagKeySelection] =
        useState(
            () =>
                selection instanceof TagKeyExistsSelectorSelection
                    ? selection.existsTagKeys
                    : []);
    const [existsTagPatternKeySelection, setExistsTagPatternKeySelection] =
        useState(
            () =>
                selection instanceof ExistsTagPatternSelectorSelection
                    ? selection.existsTagPatternKey
                    : undefined);
    const [existsTagPatternValueSelection, setExistsTagPatternValueSelection] =
        useState(
            () =>
                selection instanceof ExistsTagPatternSelectorSelection
                    ? selection.existsTagPatternValue
                    : undefined);
    const [existsTagSelection, setExistsTagSelection] =
        useState(
            () =>
                selection instanceof TagExistsSelectorSelection
                    ? selection.existsTags
                    : []);
    const [type, setType] =
        useState(
            _.isNil(selection)
                ? undefined
                : selection instanceof TagExistsSelectorSelection
                    ? TagSelectorType.ExistsResourceTag
                    : selection instanceof TagKeyExistsSelectorSelection
                        ? TagSelectorType.ExistsResourceTagKey
                        : TagSelectorType.ExistsResourceTagPattern);

    useChangeEffect(
        () => {
            onSelectionChanged(
                map<string, TagSelectorSelection | undefined>(
                    type,
                    {
                        [TagSelectorType.ExistsResourceTag]: () => new TagExistsSelectorSelection(existsTagSelection),
                        [TagSelectorType.ExistsResourceTagKey]: () => new TagKeyExistsSelectorSelection(existsTagKeySelection),
                        [TagSelectorType.ExistsResourceTagPattern]: () => new ExistsTagPatternSelectorSelection(
                            existsTagPatternKeySelection,
                            existsTagPatternValueSelection)
                    },
                    () => undefined));
        },
        [existsTagSelection, existsTagKeySelection, existsTagPatternKeySelection, existsTagPatternValueSelection, type]);

    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ maxWidth: theme.spacing(50) }}>
                <Radio
                    checked={type === TagSelectorType.ExistsResourceTagKey}
                    size="small"
                    onChange={() => setType(TagSelectorType.ExistsResourceTagKey)}/>
                {localization.fields.existsResourceTagKeys.title()}
                <Box sx={{ paddingLeft: theme.spacing(2) }}>
                    <PagedResourceTagKeySelector
                        disabled={type !== TagSelectorType.ExistsResourceTagKey}
                        multiSelect={true}
                        placeholder={localization.fields.existsResourceTagKeys.placeholder()}
                        scopeId={scopeId}
                        selectedResourceTagKeys={existsTagKeySelection}
                        tenantTypes={tenantTypes}
                        onSelectedResourceTagKeyChanged={resourceTagKeys => setExistsTagKeySelection(resourceTagKeys)}/>
                </Box>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Radio
                    checked={type === TagSelectorType.ExistsResourceTag}
                    size="small"
                    onChange={() => setType(TagSelectorType.ExistsResourceTag)}/>
                {localization.fields.existsResourceTags.title()}
                <Box sx={{ paddingLeft: theme.spacing(2) }}>
                    <PagedResourceTagSelector
                        disabled={type !== TagSelectorType.ExistsResourceTag}
                        multiSelect={true}
                        placeholder={localization.fields.existsResourceTagKeys.placeholder()}
                        scopeId={scopeId}
                        selectedTags={existsTagSelection}
                        tenantTypes={tenantTypes}
                        onSelectedTagsChanged={resourceTags => setExistsTagSelection(resourceTags)}/>
                </Box>
            </Stack>
            <Stack justifyContent="flex-start">
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Radio
                        checked={type === TagSelectorType.ExistsResourceTagPattern}
                        size="small"
                        onChange={() => setType(TagSelectorType.ExistsResourceTagPattern)}/>
                    {localization.fields.existsResourceTagPattern.title()}
                    <Message
                        level="info"
                        title={
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                {localization.fields.existsResourceTagPattern.helpText()}
                            </Typography>}
                        variant="minimal"/>
                </Stack>
                <Stack
                    direction="row"
                    padding={2}
                    spacing={2}
                    sx={{ height: "100%" }}>
                    <Box
                        sx={{
                            marginRight: theme.spacing(1.5),
                            maxWidth: theme.spacing(26)
                        }}>
                        <FormControl variant="standard">
                            <TextField
                                disabled={type !== TagSelectorType.ExistsResourceTagPattern}
                                label={localization.fields.existsResourceTagPattern.key.placeholder()}
                                size="small"
                                sx={{ maxWidth: theme.spacing(19.5) }}
                                value={existsTagPatternKeySelection}
                                variant="outlined"
                                onChange={event => setExistsTagPatternKeySelection(event.target.value)}/>
                            {!(type !== TagSelectorType.ExistsResourceTagPattern) &&
                                !_.isNil(existsTagPatternKeySelection) && _.isEmpty(existsTagPatternKeySelection?.trim()) &&
                                <FormHelperText error={true}>{localization.fields.existsResourceTagPattern.key.emptyError()}</FormHelperText>}
                        </FormControl>
                    </Box>
                    <Box sx={{ maxWidth: theme.spacing(26) }}>
                        <FormControl variant="standard">
                            <TextField
                                disabled={type !== TagSelectorType.ExistsResourceTagPattern}
                                label={localization.fields.existsResourceTagPattern.value.placeholder()}
                                size="small"
                                sx={{ maxWidth: theme.spacing(19.5) }}
                                value={existsTagPatternValueSelection}
                                variant="outlined"
                                onChange={event => setExistsTagPatternValueSelection(event.target.value)}/>
                            {!(type !== TagSelectorType.ExistsResourceTagPattern) &&
                                !_.isNil(existsTagPatternValueSelection) && _.isEmpty(existsTagPatternValueSelection?.trim()) &&
                                <FormHelperText error={true}>{localization.fields.existsResourceTagPattern.value.emptyError()}</FormHelperText>}
                        </FormControl>
                    </Box>
                </Stack>
            </Stack>
        </Stack>);
}

enum TagSelectorType {
    ExistsResourceTag = "ExistsResourceTag",
    ExistsResourceTagKey = "ExistsResourceTagKey",
    ExistsResourceTagPattern = "ExistsResourceTagPattern"
}

export class TagSelectorSelection {
    public getInlineSelectionData(): InlineTagsSelectionData {
        return {};
    }

    public valid() {
        return true;
    }
}

export class TagExistsSelectorSelection extends TagSelectorSelection {
    constructor(public existsTags: Contract.ResourceTag[]) {
        super();
    }

    public getInlineSelectionData(): InlineTagsSelectionData {
        return { existsResourceTags: this.existsTags };
    }

    public valid() {
        return !_.isEmpty(this.existsTags);
    }
}

export class TagKeyExistsSelectorSelection extends TagSelectorSelection {
    constructor(public existsTagKeys: string[]) {
        super();
    }

    public getInlineSelectionData(): InlineTagsSelectionData {
        return { existsResourceTagKeys: this.existsTagKeys };
    }

    public valid() {
        return !_.isEmpty(this.existsTagKeys);
    }
}

export class ExistsTagPatternSelectorSelection extends TagSelectorSelection {
    constructor(
        public existsTagPatternKey?: string,
        public existsTagPatternValue?: string) {
        super();
    }

    public getInlineSelectionData(): InlineTagsSelectionData {
        return {
            existsResourceTagPatternKey: this.existsTagPatternKey,
            existsResourceTagPatternValue: this.existsTagPatternValue
        };
    }

    public valid() {
        return !_.isEmpty(this.existsTagPatternKey?.trim()) &&
            !_.isEmpty(this.existsTagPatternValue?.trim());
    }
}