import { useLocalization, useSelectionActionsContext } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, ReactNode, useMemo, useState } from "react";
import { JiraIcon, RiskType, ScopeHelper, scopeSystemEntityModelStore, SelectionActionButton, TicketingServicePromptMessage, UpsertJiraIssueDialog } from "..";
import { UpsertJiraIssueProps } from "./UpsertJiraIssueDialog/components";

export type CreateJiraIssuesSelectionActionProps = {
    getItemIssueCreationCount: (item: any) => number;
    getPromptMessage?: (itemIds: string[]) => TicketingServicePromptMessage;
    getSelectedRiskIdToScopeIdsMap: () => Dictionary<string[]>;
    reloadItems: () => Promise<void>;
    riskType: RiskType;
    startElement?: ReactNode;
};

export function CreateJiraIssuesSelectionAction({ getItemIssueCreationCount, getPromptMessage, getSelectedRiskIdToScopeIdsMap, reloadItems, riskType, startElement }: CreateJiraIssuesSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds, loadedItems } = useSelectionActionsContext();
    const selectedRiskIdToScopeIdsMap = getSelectedRiskIdToScopeIdsMap();
    const instanceModels =
        ScopeHelper.getScopesParentScopeSystemEntityModelsIntersection(
            _.values(selectedRiskIdToScopeIdsMap),
            scopeSystemEntityModelStore.useGetJira());
    const issueCreationCount =
        useMemo(
            () => {
                if (loadedItems.length !== itemIds.length) {
                    return undefined;
                }

                return _(loadedItems).
                    map(item => getItemIssueCreationCount(item)).
                    sum();
            },
            [itemIds, loadedItems]);
    const localization =
        useLocalization(
            "common.createJiraIssuesSelectionAction",
            () => ({
                create: [
                    "Create Jira issue",
                    "Create Jira issues"
                ]
            }));

    const [open, setOpen] = useState(false);
    const [riskIdToIssueIdsMap, setRiskIdToIssueIdsMap] = useState<Dictionary<string[]>>({});
    return (
        !_.isEmpty(instanceModels)
            ? <Box sx={{ height: "100%" }}>
                {open &&
                    <UpsertWithPrompt
                        getPromptMessage={getPromptMessage}
                        instanceModels={instanceModels}
                        riskIdToIssueIdsMap={riskIdToIssueIdsMap}
                        riskType={riskType}
                        onClose={
                            async successRiskIds => {
                                if (!_.isEmpty(successRiskIds)) {
                                    await reloadItems();
                                }

                                actionEnded();
                                setOpen(false);
                            }}/>}
                <Stack
                    direction="row"
                    sx={{ height: "100%" }}>
                    {startElement}
                    <SelectionActionButton
                        startIcon={
                            <JiraIcon
                                sx={{
                                    opacity:
                                        actionExecuting
                                            ? 0.4
                                            : 1
                                }}/>}
                        onClick={
                            () => {
                                setRiskIdToIssueIdsMap(
                                    _(selectedRiskIdToScopeIdsMap).
                                        keys().
                                        keyBy().
                                        mapValues(() => []).
                                        value());
                                setOpen(true);
                                actionStarted(true);
                            }}>
                        {localization.create(issueCreationCount ?? 0)}
                    </SelectionActionButton>
                </Stack>
            </Box>
            : <Fragment/>);
}

type UpsertWithPromptProps =
    UpsertJiraIssueProps & {
        getPromptMessage?: (itemIds: string[]) => TicketingServicePromptMessage;
    };

function UpsertWithPrompt({ getPromptMessage, instanceModels, onClose, riskIdToIssueIdsMap, riskType }: UpsertWithPromptProps) {
    const { itemIds } = useSelectionActionsContext();
    const promptMessage =
        useMemo(
            () => {
                if (_.keys(riskIdToIssueIdsMap).length === itemIds.length ||
                    _.isNil(getPromptMessage)) {
                    return undefined;
                }

                return getPromptMessage(itemIds);
            },
            [itemIds, riskIdToIssueIdsMap, getPromptMessage]);

    return (
        <UpsertJiraIssueDialog
            instanceModels={instanceModels}
            promptMessage={promptMessage}
            riskIdToIssueIdsMap={riskIdToIssueIdsMap}
            riskType={riskType}
            onClose={onClose}/>);
}