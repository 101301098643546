﻿import _, { Dictionary } from "lodash";
import { EntityTableDefinition } from "../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAzureCommonKeyVaultVaultObjectDefinition } from "./useAzureCommonKeyVaultVaultObjectDefinition";

export function useAzureKeyVaultVaultSecretDefinition(definitionData: DefinitionData) {
    const commonKeyVaultVaultObjectDefinition = useAzureCommonKeyVaultVaultObjectDefinition(definitionData);

    return new EntityTableDefinition(
        _.filter([
            commonKeyVaultVaultObjectDefinition.columns.tenantColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectAzureManagedColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectEnabledColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectExpirationColumn,
            commonKeyVaultVaultObjectDefinition.columns.creationTimeColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectVaultColumn,
            commonKeyVaultVaultObjectDefinition.columns.creatorIdentityCsvColumn,
            commonKeyVaultVaultObjectDefinition.columns.regionColumn,
            commonKeyVaultVaultObjectDefinition.columns.regionLocationColumn,
            commonKeyVaultVaultObjectDefinition.columns.resourceGroupColumn,
            commonKeyVaultVaultObjectDefinition.columns.tagsColumn,
            commonKeyVaultVaultObjectDefinition.columns.attributesColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureKeyVaultVaultSecretRequest(
                new Contract.EntityControllerGetEntityModelPageAzureKeyVaultVaultSecretRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectAzureManaged]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectEnabled]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectExpirationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectVault])
                ),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}