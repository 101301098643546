import { DataTable, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, EmptyMessage, Optional, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, InfoCard } from "../../../../../../../../../../../../common";
import { useAwsSnsDeliveryProtocolTranslator } from "../../../../../../../../hooks";

type SubscriptionsInfoCardProps = {
    topicModel: Contract.AwsSnsTopicModel;
};

export function SubscriptionsInfoCard({ topicModel }: SubscriptionsInfoCardProps) {
    const topic = topicModel.entity as Contract.AwsSnsTopic;
    const orderedSubscriptions =
        useMemo(
            () =>
                _.orderBy(
                    topic.subscriptions,
                    subscription => subscription.deliveryProtocol),
            [topic]);

    const fetchItems =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit = 10) => {
            const subscriptionPage =
                _(orderedSubscriptions).
                    drop(skip).
                    take(limit).
                    value();

            return new DataTableFetchItemsResult(
                { count: subscriptionPage.length },
                subscriptionPage,
                topic.subscriptions.length <= skip + limit);
        };

    const snsDeliveryProtocolTranslator = useAwsSnsDeliveryProtocolTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSnsTopicDefinition.subscriptionsInfoCard",
            () => ({
                columns: {
                    deliveryProtocol: "Protocol",
                    endpoint: "Endpoint"
                },
                empty: "No subscriptions",
                title: {
                    all: "Subscriptions ({{subscriptionCounts | NumberFormatter.humanize}})",
                    empty: "Subscriptions",
                    some: "Subscriptions ({{syncedSubscriptionCount | NumberFormatter.humanize}} out of {{subscriptionCounts | NumberFormatter.humanize}})"
                }
            }));
    return (
        <InfoCard
            title={
                _.isEmpty(topic.subscriptions)
                    ? localization.title.empty()
                    : topic.subscriptionCount === topic.subscriptions.length
                        ? localization.title.all({ subscriptionCounts: topic.subscriptionCount })
                        : localization.title.some({
                            subscriptionCounts: topic.subscriptionCount,
                            syncedSubscriptionCount: topic.subscriptions.length
                        })}>
            {_.isEmpty(topic.subscriptions)
                ? <EmptyMessage
                    message={localization.empty()}
                    verticalCenter={true}/>
                : <DataTable
                    fetchItems={fetchItems}
                    getItemId={(item: Contract.AwsSnsTopicSubscription) => item.arn}
                    sortOptions={{ enabled: false }}
                    variant="card">
                    <DataTableColumn
                        id={SubscriptionTableColumnId.Protocol}
                        itemProperty={(item: Contract.AwsSnsTopicSubscription) => snsDeliveryProtocolTranslator(item.deliveryProtocol)}
                        title={localization.columns.deliveryProtocol()}/>
                    <DataTableColumn
                        id={SubscriptionTableColumnId.Endpoint}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AwsSnsTopicSubscription>) =>
                                _.isNil(topicModel.subscriptionArnToEndpointEntityIdMap[item.arn])
                                    ? <Typography>{item.endpoint}</Typography>
                                    : <Entity
                                        entityIdOrModel={topicModel.subscriptionArnToEndpointEntityIdMap[item.arn]}
                                        variant="text"/>}
                        title={localization.columns.endpoint()}/>
                </DataTable>}
        </InfoCard>);
}

enum SubscriptionTableColumnId {
    Endpoint = "endpoint",
    Protocol = "protocol"
}