﻿import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InfoCard, NoneDefinition } from "../../../../../../../../../../../../common";
import { PermissionUsageInfoItem } from "./PermissionUsageInfoItem";

type PermissionUsageInfoCardProps = {
    roleBindingModel: Contract.GcpIamRoleBindingModel;
};

export function PermissionUsageInfoCard({ roleBindingModel }: PermissionUsageInfoCardProps) {
    const roleBinding = roleBindingModel.entity as Contract.GcpIamRoleBinding;
    const roleModel = entityModelStore.useGet(roleBinding.roleId);
    const role = roleModel.entity as Contract.GcpIamRole;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamRoleBindingDefinition.permissionUsageInfoCard",
            () => ({
                permissionUsage: {
                    excessivePermissionActions: "Excessive",
                    nonExcessiveNotUsedPermissionActions: "Unknown",
                    usedPermissionActions: "Used"
                },
                roleIgnoredType: {
                    deprecated: "Role launch stage is Deprecated",
                    disabled: "Role launch stage is Disabled",
                    permissionActionNonRoleEarlyAccess: "Role launch stage isn't GA",
                    permissionActionNonRoleGeneralAvailability: "Role binding doesn't grant any permissions"
                },
                title: "Permission Usage"
            }));

    function getRoleIgnoredTypeLocalization(role: Contract.GcpIamRole) {
        switch (role.stage) {
            case Contract.GcpIamRoleStage.Alpha:
            case Contract.GcpIamRoleStage.Beta:
            case Contract.GcpIamRoleStage.EarlyAccessProgram:
                return localization.roleIgnoredType.permissionActionNonRoleEarlyAccess;
            case Contract.GcpIamRoleStage.Deprecated:
                return localization.roleIgnoredType.deprecated;
            case Contract.GcpIamRoleStage.Disabled:
                return localization.roleIgnoredType.disabled;
            case Contract.GcpIamRoleStage.GeneralAvailability:
                return localization.roleIgnoredType.permissionActionNonRoleGeneralAvailability;
        }
    }

    return (
        <InfoCard
            title={localization.title()}>
            {_.isNil(roleBindingModel.permissionUsageType)
                ? <NoneDefinition/>
                : role.ignored
                    ? <Typography>
                        {getRoleIgnoredTypeLocalization(role)()}
                    </Typography>
                    : <Typography>
                        <PermissionUsageInfoItem
                            gcpSpecialGroupsPermissionActions={roleBindingModel.gcpSpecialGroupsPermissionUsage?.usedPermissionActions}
                            permissionActions={roleBindingModel.permissionUsage?.usedPermissionActions}
                            title={localization.permissionUsage.usedPermissionActions()}/>
                        <PermissionUsageInfoItem
                            gcpSpecialGroupsPermissionActions={roleBindingModel.gcpSpecialGroupsPermissionUsage?.excessivePermissionActions}
                            permissionActions={roleBindingModel.permissionUsage?.excessivePermissionActions}
                            title={localization.permissionUsage.excessivePermissionActions()}/>
                        <PermissionUsageInfoItem
                            gcpSpecialGroupsPermissionActions={roleBindingModel.gcpSpecialGroupsPermissionUsage?.nonExcessiveNotUsedPermissionActions}
                            permissionActions={roleBindingModel.permissionUsage?.nonExcessiveNotUsedPermissionActions}
                            title={localization.permissionUsage.nonExcessiveNotUsedPermissionActions()}/>
                    </Typography>}
        </InfoCard>);
}