import { ReactElement } from "react";
import { UnexpectedError } from "@infrastructure";
import { DashboardWidgetType } from "../../Widget";
import { useGetAccessRiskCombinationsDefinition, useGetAccessRisksDefinition, useGetCodeEntitiesDefinition, useGetCodeRisksDefinition, useGetCodeTenantsDefinition, useGetCodeTopRiskTenantsDefinition, useGetComplianceDefinition, useGetDataAnalysisDefinition, useGetEntitiesDefinition, useGetEntityPublicDataDefinition, useGetExcessivePermissionPrincipalTrendsDefinition, useGetOpenBehaviorRiskDefinition, useGetPrincipalsDefinition, useGetRiskCombinationsDefinition, useGetRisksDefinition, useGetRiskTrendsDefinition, useGetSecretsDefinition, useGetTenantsDefinition, useGetTopRisksDefinition, useGetTopRiskTenantsDefinition, useGetTopRiskTypesDefinition, useGetVendorsDefinition, useGetWorkloadAnalysisCriticalVulnerabilitiesDefinition, useGetWorkloadAnalysisRiskDistributionDefinition, useGetWorkloadAnalysisVulnerabilityStatusDefinition } from "./hooks";

export type WidgetDefinition = {
    element: ReactElement;
    options: WidgetDefinitionOptions;
    topElement?: ReactElement;
};

export function useDefinition(widgetType: DashboardWidgetType): WidgetDefinition {
    const getAccessAccessRisksDefinition = useGetAccessRisksDefinition();
    const getAccessExcessivePermissionPrincipalTrendsDefinition = useGetExcessivePermissionPrincipalTrendsDefinition();
    const getAccessPrincipalsDefinition = useGetPrincipalsDefinition();
    const getAccessRiskCombinationsDefinition = useGetAccessRiskCombinationsDefinition();
    const getAccessVendorsDefinition = useGetVendorsDefinition();
    const getCodeEntitiesDefinition = useGetCodeEntitiesDefinition();
    const getCodeRisksDefinition = useGetCodeRisksDefinition();
    const getCodeTenantsDefinition = useGetCodeTenantsDefinition();
    const getCodeTopRiskTenantsCard = useGetCodeTopRiskTenantsDefinition();
    const getComplianceDefinition = useGetComplianceDefinition();
    const getDataAnalysis = useGetDataAnalysisDefinition();
    const getEntitiesDefinition = useGetEntitiesDefinition();
    const getEntityPublicDataDefinition = useGetEntityPublicDataDefinition();
    const getOpenBehaviorRiskDefinition = useGetOpenBehaviorRiskDefinition();
    const getRiskCombinationsDefinition = useGetRiskCombinationsDefinition();
    const getRisksDefinition = useGetRisksDefinition();
    const getRiskTrendsDefinition = useGetRiskTrendsDefinition();
    const getSecretsDefinition = useGetSecretsDefinition();
    const getTenantsDefinition = useGetTenantsDefinition();
    const getTopRisksDefinition = useGetTopRisksDefinition();
    const getTopRiskTenantsDefinition = useGetTopRiskTenantsDefinition();
    const getTopRiskTypesDefinition = useGetTopRiskTypesDefinition();
    const getWorkloadAnalysisCriticalVulnerabilitiesDefinition = useGetWorkloadAnalysisCriticalVulnerabilitiesDefinition();
    const getWorkloadAnalysisRiskDistributionDefinition = useGetWorkloadAnalysisRiskDistributionDefinition();
    const getWorkloadAnalysisVulnerabilityStatusDefinition = useGetWorkloadAnalysisVulnerabilityStatusDefinition();

    switch (widgetType) {
        case DashboardWidgetType.AccessPrincipals:
            return getAccessPrincipalsDefinition();
        case DashboardWidgetType.AccessExcessivePermissionPrincipalTrends:
            return getAccessExcessivePermissionPrincipalTrendsDefinition();
        case DashboardWidgetType.AccessRiskCombinations:
            return getAccessRiskCombinationsDefinition();
        case DashboardWidgetType.AccessRisks:
            return getAccessAccessRisksDefinition();
        case DashboardWidgetType.AccessVendors:
            return getAccessVendorsDefinition();
        case DashboardWidgetType.CodeEntities:
            return getCodeEntitiesDefinition();
        case DashboardWidgetType.CodeRisks:
            return getCodeRisksDefinition();
        case DashboardWidgetType.CodeTenants:
            return getCodeTenantsDefinition();
        case DashboardWidgetType.CodeTopRiskTenants:
            return getCodeTopRiskTenantsCard();
        case DashboardWidgetType.Compliance:
            return getComplianceDefinition();
        case DashboardWidgetType.DataAnalysis:
            return getDataAnalysis();
        case DashboardWidgetType.Entities:
            return getEntitiesDefinition();
        case DashboardWidgetType.EntityPublicData:
            return getEntityPublicDataDefinition();
        case DashboardWidgetType.OpenBehaviorRisk:
            return getOpenBehaviorRiskDefinition();
        case DashboardWidgetType.RiskCombinations:
            return getRiskCombinationsDefinition();
        case DashboardWidgetType.Risks:
            return getRisksDefinition();
        case DashboardWidgetType.RiskTrends:
            return getRiskTrendsDefinition();
        case DashboardWidgetType.Secrets:
            return getSecretsDefinition();
        case DashboardWidgetType.Tenants:
            return getTenantsDefinition();
        case DashboardWidgetType.TopRiskTenants:
            return getTopRiskTenantsDefinition();
        case DashboardWidgetType.TopRiskTypes:
            return getTopRiskTypesDefinition();
        case DashboardWidgetType.TopRisks:
            return getTopRisksDefinition();
        case DashboardWidgetType.WorkloadAnalysisCriticalVulnerabilities:
            return getWorkloadAnalysisCriticalVulnerabilitiesDefinition();
        case DashboardWidgetType.WorkloadAnalysisRiskDistribution:
            return getWorkloadAnalysisRiskDistributionDefinition();
        case DashboardWidgetType.WorkloadAnalysisVulnerabilityStatus:
            return getWorkloadAnalysisVulnerabilityStatusDefinition();
        default:
            throw new UnexpectedError("useDefinition.widgetType", widgetType);
    }
}

type WidgetDefinitionOptions = {
    details?: WidgetDefinitionDetailsOptions;
    helpText?: string;
    title: string;
    topElement?: ReactElement;
    variant?: "default" | "scrollable";
};

type WidgetDefinitionDetailsOptions = {
    detailsUrl?: string;
    title?: string;
};