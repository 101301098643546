import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useGetAwsEc2InstanceRiskContext } from "../../../contexts";

export function useAwsEc2InstanceInstanceProfileNotExistRiskInstanceDefinition(riskModel: Contract.AwsEc2InstanceInstanceProfileNotExistRiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceInstanceProfileNotExistRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2InstanceModel;

    const getAwsEc2InstanceRiskContext = useGetAwsEc2InstanceRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceInstanceProfileNotExistRiskDefinition.hooks.useAwsEc2InstanceInstanceProfileNotExistRiskInstanceDefinition",
            () => ({
                description: "{{instance}} is not configured with IAM instance profile",
                sections: {
                    resolution: {
                        step1: "Click **Actions, Security, Modify IAM role**",
                        step2: "For **IAM role**, select the instance profile that you want to attach",
                        step3: "Click **Update IAM role**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            instanceModel.unknown
                ? []
                : [
                    consoleSignInStepTranslator(
                        Contract.AwsConsoleView.Ec2,
                        AwsConsoleUrlBuilder.getEc2InstanceUrl(instanceModel.entity as Contract.AwsEc2Instance)),
                    localization.sections.resolution.step1(),
                    localization.sections.resolution.step2(),
                    localization.sections.resolution.step3()
                ],
        riskModel,
        () => {
            const instanceContextItems = getAwsEc2InstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}