import { DeferredFilter, LocalizationTranslation, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Function0 } from "lodash";
import React from "react";
import { Contract } from "../controllers";
import { RegionModelHelper } from "../utilities";
import { Region } from "./Region";

type RegionDeferredFilterParameters<TFilters> = {
    getRegionIds: (filters:TFilters)=> Contract.FilterItems<string>;
    promiseOrGetPromise: Promise<TFilters> | Function0<Promise<TFilters>>;
    title: LocalizationTranslation<string>;
};

export function RegionDeferredFilter<TFilters>({ getRegionIds, promiseOrGetPromise, title }: RegionDeferredFilterParameters<TFilters>){
    return <DeferredFilter
        promiseOrGetPromise={promiseOrGetPromise}
        title={title()}>
        {filters =>
            <ValuesFilter
                emptyValueOptions={{ enabled: false }}
                placeholder={title()}>
                {_.map(
                    getRegionIds(filters).items,
                    regionId =>
                        <ValuesFilterItem
                            key={regionId}
                            title={RegionModelHelper.get(regionId).systemName}
                            value={regionId}>
                            {() =>
                                <Region
                                    regionId={regionId}
                                    variant="iconText"/>}
                        </ValuesFilterItem>)}
            </ValuesFilter>}
    </DeferredFilter>;
}