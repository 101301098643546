import { map } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, SeverityLabel, useTheme } from "../../../../../../../../../common";
import { useKubernetesAdmissionControllerRiskPolicyResultTranslator } from "../../../../../hooks";

type RiskPolicyResultCountProps = {
    count: number;
    riskPolicyResult: Contract.KubernetesAdmissionControllerRiskPolicyResult;
};

export function RiskPolicyResultCount({ count, riskPolicyResult }: RiskPolicyResultCountProps) {
    const riskPolicyResultTranslator = useKubernetesAdmissionControllerRiskPolicyResultTranslator();

    const theme = useTheme();
    const getThemePaletteColor =
        (riskPolicyResult: Contract.KubernetesAdmissionControllerRiskPolicyResult) =>
            map(
                riskPolicyResult,
                {
                    [Contract.KubernetesAdmissionControllerRiskPolicyResult.Alert]: () => theme.palette.severity(Contract.Severity.High),
                    [Contract.KubernetesAdmissionControllerRiskPolicyResult.Deny]: () => theme.palette.severity(Contract.Severity.Critical)
                });
    return (
        <SeverityLabel
            borderColor={getThemePaletteColor(riskPolicyResult)}
            item={
                <Typography
                    sx={{
                        textAlign: "center",
                        width: "fill-available"
                    }}>
                    {count}
                </Typography>}
            sx={{
                padding: theme.spacing(0.25, 0.75),
                width: theme.spacing(4)
            }}
            tooltipTitle={riskPolicyResultTranslator(riskPolicyResult)}/>);
}