import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEksClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useAwsEksClusterInstanceInboundExternalRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEksClusterInstanceInboundExternalRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEksClusterModel;
    const cluster = clusterModel.entity as Contract.AwsEksCluster;
    const clusterInstanceInboundExternalRiskModel = riskModel as Contract.AwsEksClusterInstanceInboundExternalRiskModel;

    const getAwsEksClusterRiskContext = useGetAwsEksClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEksClusterInstanceInboundExternalRiskDefinition",
            () => ({
                contextItems: {
                    instanceDatas: {
                        withInboundExternalWideRangeInstances: {
                            many: [
                                "The {{translatedClusterTypeName}} has {{instances}} with a public IP address. {{inboundExternalWideRangeInstances}} of them is accessible directly from a wide range of public IP addresses",
                                "The {{translatedClusterTypeName}} has {{instances}} with a public IP address. {{inboundExternalWideRangeInstances}} of them are accessible directly from a wide range of public IP addresses"
                            ],
                            single: "The {{translatedClusterTypeName}} has {{instances}} that is accessible directly from a wide range of public IP addresses"
                        },
                        withoutInboundExternalWideRangeInstances: "The {{translatedClusterTypeName}} has {{instances}} with a public IP address"
                    }
                },
                description: "{{cluster}} contains nodes with public IPs",
                sections: {
                    resolution: {
                        step1: {
                            link: "private",
                            text: "Ensure that the node subnets don't assign public IPs and are {{eksClusterSubnetDocumentationLink}}"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Eks,
                AwsConsoleUrlBuilder.getEksClusterUrl(cluster)),
            localization.sections.resolution.step1.text({
                eksClusterSubnetDocumentationLink:
                    <Link
                        urlOrGetUrl={clusterInstanceInboundExternalRiskModel.eksClusterSubnetDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAwsEksClusterRiskContext(clusterModel);

            const inboundExternalWideRangeInstanceIds =
                _(risk.instanceDatas).
                    filter(instanceData => instanceData.inboundExternalWideRange).
                    map(instanceData => instanceData.id).
                    value();
            const instancesElement =
                <InlineEntities
                    entityIdsOrModels={
                        _.map(
                            risk.instanceDatas,
                            instanceData => instanceData.id)}
                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                    variant="itemCountAndType"/>;
            const translatedClusterTypeName =
                entityTypeNameTranslator(
                    cluster.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    });

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.vpc,
                new RiskDefinitionContextItem(
                    _.isEmpty(inboundExternalWideRangeInstanceIds)
                        ? localization.contextItems.instanceDatas.withoutInboundExternalWideRangeInstances({
                            instances: instancesElement,
                            translatedClusterTypeName
                        })
                        : _.size(risk.instanceDatas) > 1
                            ? localization.contextItems.instanceDatas.withInboundExternalWideRangeInstances.many(
                                _.size(inboundExternalWideRangeInstanceIds),
                                {
                                    inboundExternalWideRangeInstances:
                                        <InlineEntities
                                            entityIdsOrModels={inboundExternalWideRangeInstanceIds}
                                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                            variant="itemCount"/>,
                                    instances: instancesElement,
                                    translatedClusterTypeName
                                })
                            : localization.contextItems.instanceDatas.withInboundExternalWideRangeInstances.single({
                                instances: instancesElement,
                                translatedClusterTypeName
                            })),
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}