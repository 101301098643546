import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useOciKmsVaultSecretStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciKmsVaultSecretDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const secretStatusTranslator = useOciKmsVaultSecretStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciKmsVaultSecretDefinition",
            () => ({
                columns: {
                    status: "Status",
                    vaultIdReference: "Vault"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciKmsVaultSecretModel) => ({
                            [localization.columns.status()]: secretStatusTranslator((item.entity as Contract.OciKmsVaultSecret).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciKmsVaultSecretStatus}
                                enumTypeTranslator={secretStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciKmsVaultSecretStatus}
                itemProperty={(item: Contract.OciKmsVaultModel) => secretStatusTranslator((item.entity as Contract.OciKmsVaultSecret).status)}
                key={Contract.EntityModelProperty.OciKmsVaultSecretStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciKmsVaultSecretModel>(
                        Contract.TypeNames.OciKmsVault,
                        item => [item.vaultIdReference],
                        localization.columns.vaultIdReference())}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciKmsVaultObjectVault)}
                                placeholder={localization.columns.vaultIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciKmsVaultObjectVault}
                key={Contract.EntityModelProperty.OciKmsVaultObjectVault}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciKmsVaultSecretModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vaultIdReference}
                            entityTypeName={Contract.TypeNames.OciKmsVault}/>}
                title={localization.columns.vaultIdReference()}/>,
            commonResourceDefinition.columns.creatorCsvColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciKmsVaultSecretRequest(
                new Contract.EntityControllerGetEntityModelPageOciKmsVaultSecretRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciKmsVaultObjectVault]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciKmsVaultSecretStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}