import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function BeyondTrustIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M20.7681 9.82993L18.6538 7.71555C18.6538 7.71555 18.6167 7.67846 18.5796 7.71555C18.5425 7.71555 18.5425 7.75265 18.5425 7.75265V22.7016C18.5425 22.7387 18.5425 22.7387 18.5796 22.7387C18.5796 22.7387 18.5796 22.7387 18.6167 22.7387H18.6538L20.7681 20.6244C20.8423 20.5502 20.9165 20.3647 20.9165 20.2534V10.2009C20.9165 10.0896 20.8423 9.90412 20.7681 9.82993Z"
                fill="#FF5500"/>
            <path
                d="M16.9474 6.4544H9.41731C9.34312 6.4544 9.30603 6.41731 9.30603 6.34312V0.0370944C9.30603 1.43378e-07 9.30603 0 9.26893 0C9.23184 0 9.23184 0 9.19475 0L7.08037 2.11437C7.00618 2.18856 6.93199 2.37403 6.93199 2.48532V8.75425C6.93199 8.79134 6.96909 8.82844 7.00618 8.82844H14.4992C14.5734 8.82844 14.6105 8.86553 14.6105 8.93972V10.0526C14.6105 10.2009 14.4621 10.3493 14.3138 10.3493H6.93199V18.3988C6.93199 19.3261 7.67388 20.068 8.60124 20.068H14.3509C14.4992 20.068 14.6476 20.2164 14.6476 20.3648V21.4776C14.6476 21.5518 14.6105 21.5889 14.5363 21.5889H8.56414C6.78362 21.5889 5.37403 20.1422 5.37403 18.3988V3.93199C5.37403 3.8949 5.37403 3.8949 5.33694 3.8949C5.29985 3.8949 5.29985 3.8949 5.26275 3.8949L3.14838 6.00927C3.07419 6.08346 3 6.26893 3 6.38022V19.1036C3 21.8114 5.18856 24 7.89645 24H16.9474C16.9845 24 17.0216 23.9629 17.0216 23.9258V6.4915C17.0216 6.45441 16.9845 6.4544 16.9474 6.4544ZM14.6105 17.3972C14.6105 17.5456 14.4621 17.694 14.3138 17.694H9.60278C9.4544 17.694 9.30603 17.5456 9.30603 17.3972V13.0201C9.30603 12.8717 9.4544 12.7233 9.60278 12.7233H14.3509C14.4992 12.7233 14.6476 12.8717 14.6476 13.0201V17.3972H14.6105Z"
                fill="#FF5500"/>
        </SvgIcon>);
}