﻿import { Dialog, FormLayout, Message, useLocalization } from "@infrastructure";
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { RiskType, useTheme } from "../../../../../common";
import { useRiskController } from "../hooks";

type OpenDialogProps = {
    onClose: (canceled?: boolean) => void;
    riskIds: string[];
    riskType: RiskType;
};

export function OpenDialog({ onClose, riskIds, riskType }: OpenDialogProps) {
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [message, setMessage] = useState<string>();

    const { updateOpenRiskStatus } = useRiskController(riskType);
    async function updateRiskStatus() {
        setExecuting(true);
        setError(false);

        try {
            await updateOpenRiskStatus(riskIds, message);
            onClose();
        } catch {
            setError(true);
        }
        setExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.risks.openDialog",
            () => ({
                error: "Failed to open",
                message: {
                    placeholder: "Write a comment...",
                    title: "Comment"
                },
                save: "Open",
                subtitle: [
                    "Change the status of the finding to Open (Reopened). If the resources are excluded from the policy, the status will be changed to Ignored.",
                    "Change the status of the findings to Open (Reopened). If the resources are excluded from the policy, the status will be changed to Ignored."
                ],
                title: [
                    "Open Finding",
                    "Open {{count | NumberFormatter.humanize}} Findings"
                ]
            }));

    const theme = useTheme();
    return (
        <Dialog
            variant="editor"
            onClose={() => onClose(true)}>
            <FormLayout
                disableContentPadding={true}
                footerOptions={{
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}>
                            {error && (
                                <Message
                                    level="error"
                                    title={localization.error()}/>)}
                            {executing && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={executing}
                                onClick={() => updateRiskStatus()}>
                                {localization.save()}
                            </Button>
                        </Stack>
                }}
                titleOptions={{
                    subtitle: localization.subtitle(_.size(riskIds)),
                    text: localization.title(_.size(riskIds))
                }}>
                <Stack
                    spacing={2}
                    sx={{
                        flex: 1,
                        padding: theme.spacing(2, 3)
                    }}>
                    <Typography variant="h5">
                        {localization.message.title()}
                    </Typography>
                    <TextField
                        disabled={executing}
                        fullWidth={true}
                        multiline={true}
                        placeholder={localization.message.placeholder()}
                        rows={4}
                        value={message}
                        variant="outlined"
                        onChange={event => setMessage(event.target.value)}/>
                </Stack>
            </FormLayout>
        </Dialog>);
}