import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpCommonComputeLoadBalancerResourceDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCommonComputeLoadBalancerResourceDefinition",
            () => ({
                columns: {
                    loadBalancers: "Load Balancers"
                }
            }));

    return {
        columns:
            {
                ...commonScopeResourceDefinition.columns,
                loadBalancerIdsColumn:
                    <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.GcpComputeLoadBalancerResourceModel>(
                                Contract.TypeNames.GcpComputeLoadBalancer,
                                item => item.loadBalancerIds,
                                localization.columns.loadBalancers())}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.GcpComputeLoadBalancerResourceLoadBalancers)}
                                        placeholder={localization.columns.loadBalancers()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.GcpComputeLoadBalancerResourceLoadBalancers}
                        key={Contract.EntityModelProperty.GcpComputeLoadBalancerResourceLoadBalancers}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GcpComputeLoadBalancerResourceModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.loadBalancerIds}
                                    entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}
                                    entityVariant="iconText"/>}
                        title={localization.columns.loadBalancers()}/>
            }
    };
}