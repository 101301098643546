import { Box, Divider, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useMemo } from "react";
import { useTheme } from "../themes";

type ProfileInfoLineProps = {
    children: ReactNode[];
};

export function ProfileInfoLine({ children }: ProfileInfoLineProps) {
    const size =
        useMemo(
            () => _.size(children) - 1,
            [children]);

    const theme = useTheme();
    return (
        <Box sx={{ width: "inherit" }}>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-start"
                spacing={1}>
                {_.map(
                    children,
                    (child, index) =>
                        <Fragment key={index}>
                            {child}
                            {index < size &&
                                <Divider
                                    flexItem={true}
                                    orientation="vertical"
                                    sx={{
                                        alignSelf: "center",
                                        height: theme.spacing(1.75)
                                    }}/>}
                        </Fragment>)}
            </Stack>
        </Box>);
}