import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsRedshiftClusterRiskContext() {
    return useMemo(
        () => useAwsRedshiftClusterRiskContext,
        []);
}

function useAwsRedshiftClusterRiskContext(clusterModel: Contract.AwsRedshiftClusterModel) {
    const cluster = clusterModel.entity as Contract.AwsRedshiftCluster;
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(clusterModel);
    const resourceRiskContext = useGetAwsResourceRiskContext()(clusterModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsRedshiftClusterRiskContext",
            () => ({
                backupRetentionTimeFrame: "The retention period is currently set to **{{backupRetentionTimeFrame | TimeSpanFormatter.day}}**",
                encryption: {
                    text: "The {{translatedClusterTypeName}} is {{encryption}}",
                    type: {
                        hsm: "encrypted using HSM",
                        kms: "encrypted using KMS key {{key}}",
                        none: "not encrypted"
                    }
                },
                storageSize: {
                    empty: "The {{translatedClusterTypeName}} is empty",
                    notEmpty: "The {{translatedClusterTypeName}}’s data size is {{storageSize | NumberFormatter.storage}}"
                }
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            cluster.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        backupRetentionTimeFrame:
            new RiskDefinitionContextItem(localization.backupRetentionTimeFrame({ backupRetentionTimeFrame: cluster.backupRetentionTimeFrame })),
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    encryption:
                        _.isNil(cluster.storageEncryptionType)
                            ? localization.encryption.type.none()
                            : cluster.storageEncryptionType === Contract.AwsRedshiftClusterStorageEncryptionType.Hsm
                                ? localization.encryption.type.hsm()
                                : localization.encryption.type.kms({
                                    key:
                                        <Entity
                                            entityIdOrModel={clusterModel.kmsEncryptionKeyIdReferences[0]}
                                            variant="text"/>
                                }),
                    translatedClusterTypeName
                })),
        storageSize:
            new RiskDefinitionContextItem(
                (cluster.storageSize > 0
                    ? localization.storageSize.notEmpty
                    : localization.storageSize.empty)({
                    storageSize: cluster.storageSize,
                    translatedClusterTypeName
                }))
    };
}