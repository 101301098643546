﻿import { Box, Stack, Typography } from "@mui/material";
import React from "react";

type SectionHeaderProps = {
    subtitle: string;
    title: string;
    variant: "main" | "sub";
};

export function SectionHeader({ subtitle, title, variant }: SectionHeaderProps) {
    return (
        <Stack>
            <Box sx={{ width: "88%" }}>
                <Typography
                    style={{
                        fontSize:
                            variant === "main"
                                ? "18px"
                                : "13px",
                        fontWeight:
                            variant === "main"
                                ? 600
                                : "bold"
                    }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{ width: "88%" }}>
                <Typography
                    style={{
                        fontSize:
                            variant === "main"
                                ? "14px"
                                : undefined
                    }}>
                    {subtitle}
                </Typography>
            </Box>
        </Stack>);
}