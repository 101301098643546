import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function LumenIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2217)">
                <path
                    d="M21.7884 3.63621L0.00317383 3.68092V1.87652C0.00317383 0.693291 0.726527 0.0146484 2.11894 0.0146484H21.7325C23.1265 0.0146484 23.8483 0.688501 23.8483 1.87652C23.8531 3.01505 23.1297 3.63621 21.7884 3.63621Z"
                    fill="#0C9ED9"/>
                <path
                    d="M16.8271 10.2805C18.2212 10.2805 18.9445 10.9591 18.9445 12.0896C18.9445 13.2793 18.2212 13.9004 16.8271 13.9004H3.92495V19.3519C3.92495 19.9715 4.18363 20.2829 4.85429 20.2829H21.9353C23.2766 20.2829 24 20.904 24 22.0936C24 23.3375 23.2766 23.9044 21.9353 23.9044H2.11737C0.723353 23.9044 0 23.3375 0 22.0936V10.231L16.8271 10.2805Z"
                    fill={theme.palette.text.primary}/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2217">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}