﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEc2InstancesRiskContext, useGetAwsEc2LaunchTemplateRiskContext, useGetAwsEc2VolumesRiskContext } from "../../../../contexts";
import { RevisionsDeviceTable } from "./components";

export function useAwsEc2VolumeEncryptionDisabledRiskLaunchTemplateDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2VolumeEncryptionDisabledRisk;
    const riskData = risk.data as Contract.AwsEc2VolumeEncryptionDisabledRiskEc2LaunchTemplateData;
    const launchTemplateModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2LaunchTemplateModel;
    const launchTemplate = launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate;
    const instanceModels = entityModelStore.useGet(riskData.instanceIds) as Contract.AwsEc2InstanceModel[];
    const volumeModels = entityModelStore.useGet(risk.aggregatedEntityIds) as Contract.AwsEc2VolumeModel[];

    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsEc2LaunchTemplateRiskContext = useGetAwsEc2LaunchTemplateRiskContext();
    const getAwsEc2VolumesRiskContext = useGetAwsEc2VolumesRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VolumeEncryptionDisabledRiskDefinition.hooks.useAwsEc2VolumeEncryptionDisabledRiskLaunchTemplateDefinition",
            () => ({
                contextItems: {
                    launchDetails: {
                        title: {
                            none: "The {{translatedLaunchTemplateTypeName}} has unencrypted device mapping configuration",
                            volume: "The {{translatedLaunchTemplateTypeName}} was used to launch {{instances}} with {{volumes}}"
                        },
                        volumes: [
                            "1 unencrypted volume",
                            "{{count | NumberFormatter.humanize}} unencrypted volumes"
                        ]
                    }
                },
                description: {
                    none: "{{launchTemplate}} unencrypted device mapping configuration",
                    volume: "{{launchTemplate}} was used to launch instances with unencrypted volumes"
                },
                sections: {
                    resolution: {
                        step1: "Click on **Actions** and **Modify template (Create new version)**",
                        step2: "For each unencrypted device under **Storage**, switch the **Encrypted** dropdown to **Yes**.",
                        step3: "Click **Create template version**",
                        step4: "Before replacing the template version, verify that all identities used to launch the instances have decrypt permissions on the selected encryption keys",
                        step5: "Update auto scaling groups and applications to use the new secured launch template",
                        step6: "Click on **Delete template version** to delete the insecure versions",
                        step7: "Wait until all unencrypted instances are relaunched with the encrypted launch template version"
                    },
                    title: "Launch Template Revisions"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.none
            : localization.description.volume)({
            launchTemplate:
                <Entity
                    entityIdOrModel={launchTemplateModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2LaunchTemplateUrl(launchTemplate)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7()
        ],
        riskModel,
        () => {
            const instancesContextItems = getAwsEc2InstancesRiskContext(instanceModels);
            const launchTemplateContextItems = getAwsEc2LaunchTemplateRiskContext(launchTemplateModel);
            const volumesContextItems = getAwsEc2VolumesRiskContext(volumeModels);
            return [
                launchTemplateContextItems.generalInformation,
                launchTemplateContextItems.sensitive,
                new RiskDefinitionContextItem(
                    (_.isEmpty(volumeModels)
                        ? localization.contextItems.launchDetails.title.none
                        : localization.contextItems.launchDetails.title.volume)({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={instanceModels}
                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                variant="itemCountAndType"/>,
                        translatedLaunchTemplateTypeName:
                            entityTypeNameTranslator(
                                launchTemplate.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                }),
                        volumes:
                            <InlineEntities
                                entityIdsOrModels={volumeModels}
                                entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                namePluralizer={localization.contextItems.launchDetails.volumes}
                                variant="itemCountAndType"/>
                    })),
                launchTemplateContextItems.getAutoScalingGroupsContextItem(riskData.autoScalingGroupIds),
                instancesContextItems.sensitive,
                volumesContextItems.sensitive,
                launchTemplateContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <RevisionsDeviceTable
                        launchTemplateModel={launchTemplateModel}
                        risk={risk}/>,
                    localization.sections.title())
            ]
        });
}