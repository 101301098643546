import { Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function UserItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
        },
        []);
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oktaTenants.addOrEdit.userItem",
            () => ({
                steps: {
                    step1: "Login to the Okta admin console as a Super Administrator and go to **Directory -> People**",
                    step2: "Click on **Add Person**",
                    step3: "Fill out the necessary information and click **Save**"
                }
            }));
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
        </Steps>);
}