import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.apiServers.useKubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} API server is not authenticating to etcd with certificates",
                resolutionSection: {
                    step1: {
                        link: "Kubernetes documentation",
                        text: "Follow the {{etcdSecuringCommunicationDocumentationLink}} and configure API server to authenticate itself to the etcd server using certificates"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1.text({
                etcdSecuringCommunicationDocumentationLink:
                    <Link
                        urlOrGetUrl={(riskModel as Contract.KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskModel).etcdSecuringCommunicationDocumentationUrl}
                        variant="external">
                        {localization.resolutionSection.step1.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}