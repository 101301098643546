import { AnalyticsHelper } from "@infrastructure";
import _ from "lodash";
import { AuthenticationHelper, BeamerHelper, ComplianceHelper, ConsoleApiUrlHelper, ConsoleAppUrlHelper, CustomerConsoleAppUrlHelper, EntityTypeMetadataModelHelper, FeatureHelper, GeneralInformation, GraphApiUrlHelper, IdentityRoleHelper, LicensingHelper, ObjectTypeMetadataModelHelper, ObservabilityHelper, PendoHelper, RegionModelHelper, RiskPolicyTypeMetadataHelper, RiskTypeMetadataModelHelper, ScopeHelper, TeamsConsoleAppUrlHelper, UserConsoleAppUrlHelper, UserHelper } from ".";
import { projectModelStore, reportDefinitionConfigurationStore, scopeNodeModelStore, scopeSystemEntityModelStore, SystemController, tenantModelStore } from "..";
import { AwsConsoleHelper, AzureConsoleHelper, OciConsoleHelper } from "../../tenants";
import { ApiController, Contract } from "../controllers";
import { PermissionManagementHelper } from "./permissionManagementHelper";

export class ApplicationHelper {
    private static _cognitoEnabled: boolean;
    private static _deploymentName: string;
    private static _environmentType: Contract.EnvironmentType;
    private static _globalDeploymentConsoleUrl: string;

    public static get cognitoEnabled() {
        return ApplicationHelper._cognitoEnabled;
    }

    public static get deploymentName() {
        return ApplicationHelper._deploymentName;
    }

    public static get environmentType() {
        return ApplicationHelper._environmentType;
    }

    public static get globalDeploymentConsoleUrl() {
        return ApplicationHelper._globalDeploymentConsoleUrl;
    }

    public static async initialize(consoleAppType: Contract.ConsoleAppType, customerContext = true) {
        const systemInfo = await SystemController.getInfo();

        ApplicationHelper._cognitoEnabled = systemInfo.cognitoEnabled;
        ApplicationHelper._deploymentName = systemInfo.deploymentName;
        ApplicationHelper._environmentType = systemInfo.environmentType;
        ApplicationHelper._globalDeploymentConsoleUrl = systemInfo.globalDeploymentConsoleUrl;
        AwsConsoleHelper.initialize(systemInfo.awsPartitionTypeToConsoleDomainNameMap);
        AzureConsoleHelper.initialize(
            systemInfo.aadPartitionTypeToAzureConsoleDomainNameMap,
            systemInfo.aadPartitionTypeToAzureMachineLearningDomainNameMap);
        OciConsoleHelper.initialize(systemInfo.ociRealmIdToDomainNameMap);
        AuthenticationHelper.initialize(
            systemInfo.authenticationUserAadClientId,
            systemInfo.authenticationCustomerAadClientId);
        ConsoleApiUrlHelper.initialize(
            systemInfo.aadPartitionTypeToAuthorityUrlMap,
            new URL(systemInfo.consoleApiUrl));
        ConsoleAppUrlHelper.initialize(new URL(systemInfo.consoleAppUrl));
        CustomerConsoleAppUrlHelper.initialize(new URL(systemInfo.customerConsoleAppUrl));
        GraphApiUrlHelper.initialize(new URL(systemInfo.graphApiUrl));
        IdentityRoleHelper.initialize(systemInfo.identityRoleToCustomerConsoleAppPermissionsMap);
        AnalyticsHelper.initialize(
            systemInfo.amplitudeApiKey,
            systemInfo.deploymentName,
            systemInfo.environmentType);
        ObservabilityHelper.initialize(
            GeneralInformation.assemblyFileVersion,
            consoleAppType,
            systemInfo.deploymentName,
            systemInfo.environmentType,
            systemInfo.datadogRumApplication);
        TeamsConsoleAppUrlHelper.initialize(new URL(systemInfo.teamsConsoleAppUrl));
        UserConsoleAppUrlHelper.initialize(new URL(systemInfo.userConsoleAppUrl));

        await UserHelper.initialize();
        PendoHelper.initialize(systemInfo);

        if (consoleAppType === Contract.ConsoleAppType.Customer || consoleAppType === Contract.ConsoleAppType.User) {
            BeamerHelper.initialize();
        }

        if (customerContext) {
            if(!UserHelper.hasScopePermissions(ApiController.scopeId, Contract.IdentityPermission.SecurityRead)) {
                ApiController.scopeId = "";
            }
            const scopeNodeModels = await scopeNodeModelStore.getAll();
            if (_.isEmpty(ApiController.scopeId) &&
                consoleAppType !== Contract.ConsoleAppType.Teams &&
                consoleAppType !== Contract.ConsoleAppType.User) {
                const scopeNodeMap =
                    scopeNodeModelStore.getScopeNodeMap(
                        false,
                        scopeNodeModels,
                        undefined,
                        true);
                const firstPermittedScopeId =
                    ScopeHelper.tryGetFirstPermittedScopeId(
                        scopeNodeMap[UserHelper.customer!.id],
                        [Contract.IdentityPermission.SecurityRead]);
                if (!_.isNil(firstPermittedScopeId)) {
                    ApiController.scopeId = firstPermittedScopeId;
                }
            }

            await Promise.all([
                EntityTypeMetadataModelHelper.initialize(),
                FeatureHelper.initialize(),
                LicensingHelper.initialize(),
                RegionModelHelper.initialize(),
                scopeSystemEntityModelStore.getAll(),
                tenantModelStore.getAll(),
                projectModelStore.getAll()
            ]);

            if (UserHelper.hasAnyScopePermissions(undefined, Contract.IdentityPermission.SecurityRead)) {
                await Promise.all([
                    ObjectTypeMetadataModelHelper.initialize(),
                    RiskPolicyTypeMetadataHelper.initialize(),
                    RiskTypeMetadataModelHelper.initialize(),
                    reportDefinitionConfigurationStore.getAll()
                ]);

                if (LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)) {
                    await ComplianceHelper.initialize();
                }
            }

            if (LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement)) {
                await PermissionManagementHelper.initialize();
            }
        }

        ObservabilityHelper.setData();
        if (UserHelper.customer) {
            AnalyticsHelper.setData(
                {
                    creationTime: UserHelper.customer.creationTime,
                    eval: _.isEmpty(LicensingHelper.activeSubscriptionLicenseTypes),
                    id: UserHelper.customer.id,
                    licenseTypes: LicensingHelper.activeSubscriptionLicenseTypes ?? [],
                    name: UserHelper.customer.name,
                    tenableSiteId: UserHelper.customer.tenableSiteId
                },
                {
                    consoleAppType,
                    internal: UserHelper.tenable,
                    mailHash: UserHelper.mailHash,
                    support: UserHelper.support,
                    tenableId: UserHelper.tenableId
                });
        }
    }
}