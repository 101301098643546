import { Link, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, ProfileActions } from "../../../../../../../common";
import { Profile } from "../../../../Documentation";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useDocumentationDefinition(item: SideViewItem): SideViewDefinition {
    const itemId = decodeURIComponent(item.id);
    const localization =
        useLocalization(
            "views.customer.sideView.hooks.useDefinition.hooks.useDocumentationDefinition",
            () => ({
                documentation: "Docs portal"
            }));

    return {
        getActionsElement:
            () =>
                <ProfileActions key="documentation-actions">
                    <Link
                        key="externalLink"
                        urlOrGetUrl={`${CustomerConsoleAppUrlHelper.getDocsRelativeUrl()}/${itemId}`}
                        variant="external">
                        <Typography noWrap={true}>
                            {localization.documentation()}
                        </Typography>
                    </Link>
                </ProfileActions>,
        getViewElement:
            () =>
                <Profile
                    itemId={itemId}
                    key="documentation-profile"/>
    };
}