import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsSsoPermissionSetRiskContext() {
    return useMemo(
        () => useAwsSsoPermissionSetRiskContext,
        []);
}

function useAwsSsoPermissionSetRiskContext(permissionSetModel: Contract.AwsSsoPermissionSetModel) {
    return useGetAwsResourceRiskContext()(permissionSetModel);}