﻿import { Message, Optional, useLocalization } from "@infrastructure";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { Contract, useTheme } from "../../..";
import { useSearchUsers } from "../hooks";

type UserFieldSelectorProps = {
    disabled: boolean;
    disablePortal?: boolean;
    fetchOptionsExecuting: boolean;
    fieldName: string;
    initUserRawId: Optional<string>;
    onUserSelected: (user: Contract.JiraUser | undefined) => void;
    searchUsers: (searchText: Optional<string>, userRawId: Optional<string>) => Promise<Contract.JiraUser[]>;
    user: Contract.JiraUser | undefined;
};

export function UserFieldSelector({ disabled, disablePortal, fetchOptionsExecuting, fieldName, initUserRawId, onUserSelected, searchUsers, user }: UserFieldSelectorProps) {
    const { initUser, searchUsersError, searchUsersExecuting, updateSearchUsersText, users } =
        useSearchUsers(
            searchUsers,
            initUserRawId);
    useEffect(
        () => onUserSelected(initUser),
        [initUser]);

    const localization =
        useLocalization(
            "common.jiraIssueSelector.userFieldSelector",
            () => ({
                empty: "No matching user",
                error: "Failed to get users"
            }));
    const theme = useTheme();
    return (
        <Fragment>
            <Autocomplete
                disabled={disabled}
                disablePortal={disablePortal}
                fullWidth={true}
                getOptionLabel={user => user.displayName}
                loadingText=""
                noOptionsText={localization.empty()}
                options={users}
                popupIcon={
                    fetchOptionsExecuting || searchUsersExecuting
                        ? <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>
                        : <Fragment/>}
                renderInput={
                    params => (
                        <TextField
                            {...params}
                            label={fieldName}
                            variant="outlined"/>)}
                value={user ?? null}
                onChange={(event, user) => onUserSelected(user ?? undefined)}
                onInputChange={(event, value) => updateSearchUsersText(value)}/>
            {searchUsersError && (
                <Message
                    level="error"
                    title={localization.error()}/>)}
        </Fragment>);
}