import { Loading, useLocalization, useRoute, useSetRoute } from "@infrastructure";
import { Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { CustomerConsoleAppUrlHelper, useTheme } from "../../../../../../../../common";
import { ContainerImages } from "../../../ContainerImages";
import { Packages } from "../../../Packages";
import { VirtualMachines } from "../../../VirtualMachines";
import { CategoryView } from "../CategoryView";

type RiskImpactProps = {
    rawId: string;
};

export function RiskImpact({ rawId }: RiskImpactProps) {
    const hashUrl = CustomerConsoleAppUrlHelper.getVulnerabilityHashUrl(rawId);
    const { view } = useRoute(`${hashUrl}/${CategoryView.RiskImpact}/{view}`);
    const setRoute = useSetRoute();

    useEffect(
        () => {
            if (_.isNil(view)) {
                setRoute(
                    `${hashUrl}/${CategoryView.RiskImpact}/${currentView}`,
                    undefined,
                    { appendBrowserHistory: false }
                );
            }
        },
        [view]);

    const orderedTabs =
        useMemo(
            () =>
                [
                    TabView.VirtualMachines,
                    TabView.ContainerImages,
                    TabView.Software
                ],
            []);

    const tabToViewMap =
        useMemo(
            () => ({
                [TabView.ContainerImages]: <ContainerImages vulnerabilityRawId={rawId}/>,
                [TabView.Software]: <Packages vulnerabilityRawId={rawId}/>,
                [TabView.VirtualMachines]: <VirtualMachines vulnerabilityRawId={rawId}/>
            }),
            []);

    const currentView =
        useMemo(
            () =>
                _.includes(orderedTabs, view)
                    ? view as TabView
                    : _.first(orderedTabs),
            [orderedTabs, view]);

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.riskImpact",
            () => (
                {
                    tabs: {
                        containerImages: "Container Images",
                        software: "Software",
                        virtualMachines: "Virtual Machines"
                    }
                }));

    const theme = useTheme();
    return (
        <Stack sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="secondary"
                sx={{
                    border: "unset",
                    padding: theme.spacing(0, 1.5)
                }}
                value={currentView}
                variant="scrollable"
                onChange={
                    (_event, view) => {
                        setRoute(`${hashUrl}/${CategoryView.RiskImpact}/${view}`);
                    }}>
                {_.map(
                    orderedTabs,
                    tab =>
                        <Tab
                            key={tab}
                            label={localization.tabs[tab]()}
                            sx={{
                                fontWeight: 400,
                                marginRight: theme.spacing(2),
                                padding: 0
                            }}
                            value={tab}/>)}
            </Tabs>
            <Stack
                sx={{
                    height: "100%",
                    overflow: "hidden auto",
                    padding: theme.spacing(2, 2, 0),
                    width: "100%"
                }}>
                <Loading>
                    {currentView && tabToViewMap[currentView]}
                </Loading>
            </Stack>
        </Stack>);
}

enum TabView {
    ContainerImages = "containerImages",
    Software = "software",
    VirtualMachines = "virtualMachines"
}