import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeImageStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeImageStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeImageStatus]: {
                    [Contract.GcpComputeImageStatus.Deleting]: "Deleting",
                    [Contract.GcpComputeImageStatus.Failed]: "Failed",
                    [Contract.GcpComputeImageStatus.Pending]: "Pending",
                    [Contract.GcpComputeImageStatus.Ready]: "Ready"
                }
            }));
    return (status: Contract.GcpComputeImageStatus) =>
        localization[Contract.TypeNames.GcpComputeImageStatus][status]();
}