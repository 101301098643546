import { AppError, formatQueryParameters } from "@infrastructure";
import path from "path";
import { TeamsView } from "../../views";
import { SignInErrorCallbackQueryParameters } from "../../views/Teams/components";
import { Contract } from "../controllers";

export class TeamsConsoleAppUrlHelper {
    private static readonly urlPath = "/teams";

    private static url: URL;

    public static initialize(url: URL) {
        TeamsConsoleAppUrlHelper.url = url;
        if (TeamsConsoleAppUrlHelper.url.pathname !== TeamsConsoleAppUrlHelper.urlPath) {
            throw new AppError("Console app url path mismatch");
        }
    }

    public static getRootRelativeUrl(): string {
        return TeamsConsoleAppUrlHelper.urlPath;
    }

    public static getSignInErrorRelativeUrl(error: Contract.AuthenticationControllerTeamsSignInError){
        const queryParameters = formatQueryParameters<SignInErrorCallbackQueryParameters>({ error });
        return `${path.join(TeamsConsoleAppUrlHelper.urlPath, TeamsView.SignInError)}?${queryParameters}`;
    }
}