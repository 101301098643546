import { Store } from "@infrastructure";
import { Contract, RiskController } from "..";

export const riskModelStore =
    new Store<Contract.RiskModel, never, never>(
        riskModel => riskModel.risk.id,
        {
            get:
                async ids => {
                    const { riskModels } = await RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(ids));
                    return riskModels;
                }
        });