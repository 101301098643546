﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useAzureContainerServiceManagedClusterAgentPoolModeTranslator, useAzureContainerServiceManagedClusterAgentPoolStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info, TagsInfoItem } from "../../../../../components";
import { AzureResourceRoleAssignmentResources, KubernetesTaintsInfoCard } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";
import { AgentPoolNetworkingInfoCard, VirtualMachineScaleSetsInfoCard } from "./components";

export function useAzureContainerServiceManagedClusterAgentPoolDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const agentPool = resourceGroupResourceModel.entity as Contract.AzureContainerServiceManagedClusterAgentPool;

    const containerServiceManagedClusterAgentPoolModeTranslator = useAzureContainerServiceManagedClusterAgentPoolModeTranslator();
    const containerServiceManagedClusterAgentPoolStatusTranslator = useAzureContainerServiceManagedClusterAgentPoolStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureContainerServiceManagedClusterAgentPoolDefinition",
            () => ({
                info: {
                    autoscaling: {
                        enabled: {
                            false: "Disabled",
                            title: "Auto Scaling",
                            true: "Enabled"
                        },
                        maxCount: "Maximum Number Of Nodes",
                        minCount: "Minimum Number Of Nodes"
                    },
                    clusterId: "Cluster",
                    mode: "Mode",
                    node: {
                        diskEncryption: {
                            false: "Disabled",
                            title: "Encryption At Host",
                            true: "Enabled"
                        },
                        kubernetesTags: "Kubernetes Labels",
                        rawType: "Node Size"
                    },
                    status: "State",
                    virtualMachineScaleSetIds: "Virtual Machine Scale Sets",
                    virtualNodes: {
                        false: "Disabled",
                        title: "Virtual Node Pools",
                        true: "Enabled"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.status()}
                        value={containerServiceManagedClusterAgentPoolStatusTranslator(agentPool.status)}/>,
                    <InfoItem
                        key="mode"
                        title={localization.info.mode()}
                        value={containerServiceManagedClusterAgentPoolModeTranslator(agentPool.mode)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={agentPool.virtualMachineScaleSetIds}
                        entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSet}
                        key="virtualMachineScaleSetIds"
                        title={localization.info.virtualMachineScaleSetIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={agentPool.clusterId}
                        entityTypeName={Contract.TypeNames.AzureContainerServiceManagedCluster}
                        key="clusterId"
                        location="all"
                        title={localization.info.clusterId()}/>,
                    <TagsInfoItem
                        key="nodeKubernetesTags"
                        resourceTags={agentPool.node.kubernetesTags}
                        title={localization.info.node.kubernetesTags()}/>,
                    <InfoItem
                        key="nodeEncryption"
                        title={localization.info.node.diskEncryption.title()}
                        value={
                            agentPool.node.diskEncryption
                                ? localization.info.node.diskEncryption.true()
                                : localization.info.node.diskEncryption.false()}/>,
                    <InfoItem
                        key="nodeRawType"
                        title={localization.info.node.rawType()}
                        value={agentPool.node.rawType}/>,
                    <InfoItem
                        key="autoscalingEnabled"
                        title={localization.info.autoscaling.enabled.title()}
                        value={
                            !_.isNil(agentPool.autoscaling)
                                ? localization.info.autoscaling.enabled.true()
                                : localization.info.autoscaling.enabled.false()}/>,
                    <InfoItem
                        key="autoscalingMinCount"
                        title={localization.info.autoscaling.minCount()}
                        value={
                            _.isNil(agentPool.autoscaling)
                                ? undefined
                                : agentPool.autoscaling.minCount}/>,
                    <InfoItem
                        key="autoscalingMaxCount"
                        title={localization.info.autoscaling.maxCount()}
                        value={
                            _.isNil(agentPool.autoscaling)
                                ? undefined
                                : agentPool.autoscaling.maxCount}/>
                ]}
                options={options?.infoOptions}>
                <AgentPoolNetworkingInfoCard agentPool={agentPool}/>
                <VirtualMachineScaleSetsInfoCard virtualMachineScaleSetIds={agentPool.virtualMachineScaleSetIds}/>
                {!_.isEmpty(agentPool.node.kubernetesTaints) &&
                    <KubernetesTaintsInfoCard kubernetesTaints={agentPool.node.kubernetesTaints}/>}
            </Info>
    });
}