import { ActionMenuItem, CollapsedIcon, DataTable, DataTableActions, DataTableColumn, DataTableSort, DataTableSortDirection, DeleteIcon, EmptyMessageText, Link, map, Menu, Message, NotValidIcon, Optional, StringHelper, SuccessIcon, useChangeEffect, useLocalization } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, ReactNode, useRef, useState } from "react";
import { ConfigurationController, Contract, Scope, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

type TeamsOrganizationProps = {
    applicationRawId: string;
    organizationModel: Contract.ScopeSystemEntityModel;
};

export function Organization({ applicationRawId, organizationModel }: TeamsOrganizationProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [deleteOrganizationExecuting, setDeleteOrganizationExecuting] = useState(false);
    const [deleteOrganizationError, setDeleteOrganizationError] = useState(false);

    async function deleteOrganization() {
        setDeleteOrganizationExecuting(true);
        setDeleteOrganizationError(false);

        try {
            await ConfigurationController.deleteTeamsOrganization(new Contract.ConfigurationControllerDeleteTeamsOrganizationRequest(organizationModel.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteOrganizationError(true);
        }

        setDeleteOrganizationExecuting(false);
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [organizationModel]);

    const organizationConfiguration = organizationModel.configuration as Contract.TeamsOrganizationConfiguration;
    const organizationState = organizationModel.state as Contract.TeamsOrganizationState;

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCollaborationItems.teams.list.organization",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete this Teams organization?",
                        title: "Delete"
                    }
                },
                columns: {
                    status: "Status",
                    teamName: "Name"
                },
                empty: "No Teams",
                inherited: "Inherited from: {{scope}}",
                summary: {
                    addTeam: {
                        links: {
                            applicationInstallation: "here"
                        },
                        title: "Click {{applicationInstallationLink}} to add Tenable Cloud Security to a Team"
                    },
                    connected: "Connected",
                    status: "Status: {{statusIcon}} {{translatedStatus}}",
                    title: [
                        "1 team {{addTeam}}",
                        "{{count | NumberFormatter.humanize}} teams {{addTeam}}"
                    ],
                    [Contract.TypeNames.TeamsOrganizationStateIssue]: {
                        [Contract.TeamsOrganizationStateIssue.AadTenantNotExist]: "Failed to connect",
                        [Contract.TeamsOrganizationStateIssue.ApplicationUnauthorized]: "Missing permissions"
                    }
                }
            }));

    function getTeams(filterMap: Dictionary<unknown>, sort: Optional<DataTableSort>) {
        return _(organizationState.teamRawIdToNameMap).
            map(
                (teamName, teamRawId) =>
                    new TeamItem(
                        teamName,
                        teamRawId)).
            orderBy(
                team =>
                    map(
                        sort?.columnId ?? TableColumnId.TeamName,
                        {
                            [TableColumnId.TeamName]: () =>
                                StringHelper.getCombineSortValue(
                                    team.name,
                                    team.rawId)
                        }),
                sort?.direction === DataTableSortDirection.Descending
                    ? "desc"
                    : "asc").
            value();
    }

    const readOnly = scopeNodeModel.configuration.id != organizationModel.configuration.scopeId;
    const theme = useTheme();
    return (
        <Accordion defaultExpanded={!_.isEmpty(organizationState.teamRawIdToNameMap)}>
            <AccordionSummary
                expandIcon={
                    !_.isEmpty(organizationState.teamRawIdToNameMap) &&
                    <CollapsedIcon/>}>
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={{ width: "100%" }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{
                            flex: 1,
                            maxWidth: "100%"
                        }}>
                        <Typography noWrap={true}>
                            {organizationConfiguration.name}
                        </Typography>
                        <Divider
                            flexItem={true}
                            orientation="vertical"
                            sx={{ margin: theme.spacing(0, 0.5) }}/>
                        <Typography noWrap={true}>
                            {localization.summary.title(
                                _.size(organizationState.teamRawIdToNameMap),
                                {
                                    addTeam:
                                        <Box
                                            sx={{
                                                display: "inline-block",
                                                fontSize: "18px",
                                                margin: theme.spacing(-0.5, 0.5)
                                            }}>
                                            <Message
                                                level="info"
                                                title={localization.summary.addTeam.title({
                                                    applicationInstallationLink:
                                                        <Link
                                                            urlOrGetUrl={`https://teams.microsoft.com/l/app/${applicationRawId}`}
                                                            variant="external">
                                                            {localization.summary.addTeam.links.applicationInstallation()}
                                                        </Link>
                                                })}
                                                variant="minimal"/>
                                        </Box>
                                })}
                        </Typography>
                        <Divider
                            flexItem={true}
                            orientation="vertical"
                            sx={{ margin: theme.spacing(0, 0.5) }}/>
                        <Typography noWrap={true}>
                            {localization.summary.status({
                                statusIcon:
                                    <Box
                                        sx={{
                                            display: "inline-block",
                                            fontSize: "18px",
                                            margin: theme.spacing(-0.5, 0.5)
                                        }}>
                                        {map<Contract.TeamsOrganizationStateIssue, ReactNode>(
                                            organizationState?.issue,
                                            {
                                                [Contract.TeamsOrganizationStateIssue.AadTenantNotExist]: () =>
                                                    <NotValidIcon sx={{ color: theme.palette.error.main }}/>,
                                                [Contract.TeamsOrganizationStateIssue.ApplicationUnauthorized]: () =>
                                                    <NotValidIcon sx={{ color: theme.palette.error.main }}/>
                                            },
                                            () => <SuccessIcon sx={{ color: theme.palette.success.main }}/>)}
                                    </Box>,
                                translatedStatus:
                                    _.isNil(organizationState?.issue)
                                        ? localization.summary.connected()
                                        : localization.summary[Contract.TypeNames.TeamsOrganizationStateIssue][organizationState!.issue!]()
                            })}
                        </Typography>
                        {readOnly &&
                            <Fragment>
                                <Divider
                                    flexItem={true}
                                    orientation="vertical"
                                    sx={{ margin: theme.spacing(0, 0.5) }}/>
                                <Typography noWrap={true}>
                                    {localization.inherited({
                                        scope: <Scope
                                            scopeId={organizationModel.configuration.scopeId}
                                            sx={{ color: theme.palette.text.primary }}
                                            variant="text"/>
                                    })}
                                </Typography>
                            </Fragment>}
                    </Stack>
                    {!readOnly &&
                        <Fragment>
                            <Menu
                                itemsOrGetItems={[
                                    new ActionMenuItem(
                                        () => deleteOrganization(),
                                        localization.actions.delete.title(),
                                        {
                                            confirmOptions: {
                                                message: localization.actions.delete.prompt()
                                            },
                                            disabled: deleteOrganizationExecuting,
                                            icon: <DeleteIcon/>
                                        })
                                ]}/>
                        </Fragment>}
                    {deleteOrganizationExecuting && (
                        <CircularProgress
                            size={theme.spacing(3)}
                            variant="indeterminate"/>)}
                    {deleteOrganizationError && (
                        <Message
                            level="error"
                            title={localization.actions.delete.error()}
                            variant="minimal"/>)}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <DataTable
                    actionsRef={dataTableActionsRef}
                    emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                    fetchItems={getTeams}
                    getItemId={(item: TeamItem) => item.rawId}>
                    <DataTableColumn
                        id={TableColumnId.TeamName}
                        itemProperty={(item: TeamItem) => item.name}
                        title={localization.columns.teamName()}/>
                </DataTable>
            </AccordionDetails>
        </Accordion>);
}

enum TableColumnId {
    Inherited = "inherited",
    TeamName = "name"
}

export class TeamItem {
    constructor(
        public name: string,
        public rawId: string) {
    }
}