import { Action0, Dropdown, NumberFormatter, Optional, useActions } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { ReactNode, Ref, useMemo, useRef, useState } from "react";
import { Contract } from "../controllers";
import { TypeHelper } from "../utilities";
import { SeverityCount } from "./SeverityCount";
import { TopItems, TopItemsActions } from "./TopItems";

export type SeverityChartProps<TData> = {
    actionsRef?: Ref<Optional<SeverityChartActions>>;
    getDropdownElement: (datas: TData[]) => ReactNode;
    message?: ReactNode;
    severityToDatasMap: Dictionary<TData[]>;
    variant?: "count" | "initialAndCount";
};

export type SeverityChartActions = {
    close: Action0;
};

export function SeverityChart<TData>({ actionsRef, getDropdownElement, message, severityToDatasMap, variant = "count" }: SeverityChartProps<TData>) {
    const riskSeverities =
        useMemo(
            () =>
                _(Contract.Severity).
                    values().
                    orderBy(
                        riskSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskSeverity),
                        "desc").
                    filter(riskSeverity => riskSeverity != Contract.Severity.Information).
                    value(),
            []);
    const [openedSeverity, setOpenedSeverity] = useState<Optional<Contract.Severity>>(undefined);
    const topItemsActionsRef = useRef<TopItemsActions>();
    useActions(
        actionsRef,
        {
            close() {
                setOpenedSeverity(undefined);
                topItemsActionsRef.current?.close();
            }
        });

    return (
        <TopItems
            actionsRef={topItemsActionsRef}
            additionalItem={{
                element: message,
                width: 20
            }}
            items={riskSeverities}
            itemSizeForLimitCalculations={
                variant === "initialAndCount"
                    ? 66
                    : 56}
            orderVariant="leftToRight"
            variant="dynamic">
            {severity =>
                <Dropdown
                    disabled={_.isEmpty(severityToDatasMap[severity]) || _.isNil(getDropdownElement(severityToDatasMap[severity]))}
                    key={severity}
                    open={openedSeverity === severity}
                    popoverElement={getDropdownElement(severityToDatasMap[severity])}
                    onClose={()=> setOpenedSeverity(undefined)}
                    onOpen={()=> setOpenedSeverity(severity)}>
                    {_open =>
                        <SeverityCount
                            count={NumberFormatter.unitRound(_.size(severityToDatasMap[severity] ?? 0))}
                            disabled={_.isNil(severityToDatasMap[severity]) || _.size(severityToDatasMap[severity]) === 0}
                            key={severity}
                            severity={severity}
                            variant={variant}/>}
                </Dropdown>}
        </TopItems>);
}