import { Box, Stack, SxProps, useTheme } from "@mui/material";
import React, { ReactNode } from "react";

type DropdownContainerProps = {
    listElement: ReactNode;
    searchElement?: ReactNode;
    sx?: SxProps;
};

export function DropdownContainer({ listElement, searchElement, sx }: DropdownContainerProps) {
    const theme = useTheme();
    return (
        <Stack>
            {searchElement}
            <Box
                sx={{
                    maxHeight: theme.spacing(50),
                    overflow: "hidden auto",
                    ...sx
                }}>
                {listElement}
            </Box>
        </Stack>);
}