import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsRdsDocDbClusterSnapshotRiskContext } from "../contexts";

export function useAwsRdsDocDbClusterSnapshotPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsDocDbClusterSnapshotPublicAccessExistsRisk;
    const snapshotModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDocDbClusterSnapshotModel;
    const snapshot = snapshotModel.entity as Contract.AwsRdsDocDbClusterSnapshot;

    const getAwsRdsDocDbClusterSnapshotRiskContext = useGetAwsRdsDocDbClusterSnapshotRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDocDbClusterSnapshotPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{snapshot}} is publicly accessible via visibility settings",
                sections: {
                    resolution: {
                        step1: "Under **DB snapshot visibility**, select **Private**",
                        step2: "Optionally add explicit AWS accounts to share the snapshot with. Before saving, make sure any existing account that needs access is included",
                        step3: "Click **Save**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                snapshot:
                    <Entity
                        entityIdOrModel={snapshotModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.DocDb,
                AwsConsoleUrlBuilder.getRdsDocDbClusterSnapshotShareUrl(snapshot)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const snapshotRiskContext = getAwsRdsDocDbClusterSnapshotRiskContext(snapshotModel);
            return [
                snapshotRiskContext.generalInformation,
                snapshotRiskContext.sensitive,
                snapshotRiskContext.cluster,
                snapshotRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}