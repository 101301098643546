import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { KubernetesAdmissionControllerRiskPolicyItem, RiskPoliciesView, RiskPolicyItem } from "../../../../../../../../../../common";
import { RiskPolicyInfoItems } from "../../useRiskPolicyInfoItems";
import { useKubernetesAdmissionControllerRiskPolicyBuiltInInfoItems } from "./hooks";

export function useKubernetesAdmissionControllerRiskPolicyInfoItems(scopeId: string, item: RiskPolicyItem, policyView: RiskPoliciesView): RiskPolicyInfoItems {
    const useKubernetesAdmissionControllerRiskPolicyInfoItems =
        useMemo(
            () => {
                switch (policyView) {
                    case RiskPoliciesView.BuiltIn:
                        return useKubernetesAdmissionControllerRiskPolicyBuiltInInfoItems;
                    default:
                        throw new UnexpectedError("useKubernetesAdmissionControllerRiskPolicyItemInfoItems.policyView", policyView);
                }
            },
            []);

    return useKubernetesAdmissionControllerRiskPolicyInfoItems(scopeId, item as KubernetesAdmissionControllerRiskPolicyItem);
}