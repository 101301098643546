import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NetworkIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M9.21952 3.31048C9.07504 3.54829 9 4.01134 9 5C9 5.98897 9.07483 6.4519 9.21923 6.68967C9.27282 6.77792 9.41749 7 10.286 7H13.714C14.5818 7 14.7267 6.77803 14.7805 6.68952C14.925 6.45171 15 5.98866 15 5C15 4.01103 14.9252 3.5481 14.7808 3.31033C14.7272 3.22208 14.5825 3 13.714 3H10.286C9.4182 3 9.27329 3.22197 9.21952 3.31048ZM13 9H13.714C14.9212 9 15.9193 8.66697 16.4898 7.72798C16.9695 6.93829 17 5.90134 17 5C17 4.09897 16.9698 3.0619 16.4902 2.27217C15.9198 1.33292 14.9215 1 13.714 1H10.286C9.07881 1 8.08072 1.33303 7.51024 2.27202C7.03047 3.06171 7 4.09866 7 5C7 5.90103 7.03018 6.9381 7.50978 7.72783C8.08019 8.66708 9.07852 9 10.286 9H11V11L8.9341 11C8.04749 10.9999 7.28375 10.9999 6.67218 11.0822C6.01655 11.1704 5.38852 11.3693 4.8789 11.8789C4.36928 12.3885 4.17038 13.0165 4.08218 13.6722C3.99991 14.2837 3.99995 15.0475 4 15.9341L4 16C4 16.5523 4.44772 17 5 17C5.55229 17 6 16.5523 6 16C6 15.0287 6.00213 14.4012 6.06433 13.9388C6.12263 13.5055 6.21673 13.3695 6.29311 13.2931C6.36949 13.2167 6.50546 13.1226 6.93883 13.0643C7.40116 13.0021 8.02871 13 9 13H15C15.9713 13 16.5989 13.0021 17.0612 13.0643C17.4946 13.1226 17.6305 13.2167 17.7069 13.2931C17.7833 13.3695 17.8774 13.5055 17.9357 13.9388C17.9979 14.4012 18 15.0287 18 16C18 16.5523 18.4477 17 19 17C19.5523 17 20 16.5523 20 16L20 15.9341C20.0001 15.0475 20.0001 14.2837 19.9178 13.6722C19.8296 13.0165 19.6307 12.3885 19.1211 11.8789C18.6115 11.3693 17.9835 11.1704 17.3278 11.0822C16.7163 10.9999 15.9525 10.9999 15.0659 11L13 11V9Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M2.21951 17.3105C2.07503 17.5483 2 18.0113 2 19C2 19.989 2.07483 20.4519 2.21923 20.6897C2.27282 20.7779 2.41749 21 3.286 21H6.714C7.58181 21 7.72672 20.778 7.78049 20.6895C7.92497 20.4517 8 19.9887 8 19C8 18.011 7.92517 17.5481 7.78077 17.3103C7.72718 17.2221 7.58251 17 6.714 17H3.286C2.41819 17 2.27328 17.222 2.21951 17.3105ZM0.510239 16.272C1.08072 15.333 2.07881 15 3.286 15H6.714C7.92149 15 8.91982 15.3329 9.49023 16.2722C9.96983 17.0619 10 18.099 10 19C10 19.9013 9.96953 20.9383 9.48976 21.728C8.91928 22.667 7.92119 23 6.714 23H3.286C2.07851 23 1.08018 22.6671 0.509772 21.7278C0.0301726 20.9381 0 19.901 0 19C0 18.0987 0.030469 17.0617 0.510239 16.272Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M16.2195 17.3105C16.075 17.5483 16 18.0113 16 19C16 19.989 16.0748 20.4519 16.2192 20.6897C16.2728 20.7779 16.4175 21 17.286 21H20.714C21.5818 21 21.7267 20.778 21.7805 20.6895C21.925 20.4517 22 19.9887 22 19C22 18.011 21.9252 17.5481 21.7808 17.3103C21.7272 17.2221 21.5825 17 20.714 17H17.286C16.4182 17 16.2733 17.222 16.2195 17.3105ZM14.5102 16.272C15.0807 15.333 16.0788 15 17.286 15H20.714C21.9215 15 22.9198 15.3329 23.4902 16.2722C23.9698 17.0619 24 18.099 24 19C24 19.9013 23.9695 20.9383 23.4898 21.728C22.9193 22.667 21.9212 23 20.714 23H17.286C16.0785 23 15.0802 22.6671 14.5098 21.7278C14.0302 20.9381 14 19.901 14 19C14 18.0987 14.0305 17.0617 14.5102 16.272Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}