﻿import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CheckboxField, ItemSelector, Optional, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../common";
import { FolderEnabled } from "../../../../../../../../../components";

export function ChooseOrganizationItem() {
    const setAddOrEditContext = useSetAddOrEditContext();
    const { organizationModel, organizationNames, updatedFolderEnabled, updatedSyncEnabled } = useAddOrEditContext();
    const gitHubOrganizationConfiguration = organizationModel?.configuration as Optional<Contract.GitHubOrganizationConfiguration>;

    const [folderEnabled, setFolderEnabled] = useState(updatedFolderEnabled ?? gitHubOrganizationConfiguration?.folderEnabled ?? true);
    const [organizationName, setOrganizationName] =
        useState(gitHubOrganizationConfiguration?.name ?? _.first(organizationNames));
    const [syncEnabled, setSyncEnabled] = useState(updatedSyncEnabled ?? gitHubOrganizationConfiguration?.syncEnabled ?? true);
    const { executing, setValid } = useOrderedWizardContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitHub.addOrEditGitHub.chooseOrganizationItem",
            () => ({
                actions: {
                    info: {
                        error: {
                            general: "Failed to access organization {{organizationName}}"
                        }
                    }
                },
                fields: {
                    folderEnabled: {
                        info: "Mirror your organization's structure as folders within Tenable, preserving its hierarchy and keeping the view synchronized.",
                        title: "Automatically update folder structure"
                    },
                    organization: {
                        details: "Select the organization you would like to add:",
                        title: "Organization name"
                    },
                    syncEnabled: "Sync Cloud to Code"
                }
            }));

    useEffect(
        () => {
            if (!_.isNil(organizationName)) {
                return;
            }

            const firstOrganizationName = _.first(organizationNames);
            if (!_.isNil(firstOrganizationName)) {
                setOrganizationName(firstOrganizationName);
            }
        },
        [organizationNames, organizationName]);

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedFolderEnabled: folderEnabled,
                    updatedSyncEnabled: syncEnabled
                }));
        },
        [folderEnabled, syncEnabled]);

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedOrganizationName: organizationName
                }));

            setValid(!!organizationName);
        },
        [organizationName]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{
                maxWidth: theme.spacing(60),
                paddingBottom: theme.spacing(3)
            }}>
            <Typography>
                {localization.fields.organization.details()}
            </Typography>
            <ItemSelector
                disabled={executing || !_.isNil(gitHubOrganizationConfiguration)}
                fullWidth={true}
                items={
                    !_.isNil(gitHubOrganizationConfiguration)
                        ? [gitHubOrganizationConfiguration.name]
                        : organizationNames ?? []}
                placeholder={localization.fields.organization.title()}
                selectedItem={organizationName}
                sorted={true}
                onSelectedItemChanged={setOrganizationName}/>
            <CheckboxField
                checked={syncEnabled}
                onChange={() => setSyncEnabled(!syncEnabled)}>
                {localization.fields.syncEnabled()}
            </CheckboxField>
            <FolderEnabled
                folderEnabled={folderEnabled}
                info={localization.fields.folderEnabled.info()}
                setFolderEnabled={setFolderEnabled}
                title={localization.fields.folderEnabled.title()}/>
        </Stack>);
}