﻿import { map, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { Contract, EntityPropertyHelper, ResourceTagData } from "../../../../../";
import { EntityMultiSelectProperty } from "../EntityMultiSelect";

export class EntityMatchConditionHelper {
    public static getConditionPropertyId(condition: Contract.EntityMatchCondition): string {
        return map(
            condition.typeName,
            {
                [Contract.TypeNames.AadDirectoryPrincipalGroupIdMatchCondition]: () => EntityMultiSelectProperty.AadDirectoryPrincipalGroupIds,
                [Contract.TypeNames.AllEntityMatchCondition]: () => EntityMultiSelectProperty.All,
                [Contract.TypeNames.AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition]: () => EntityMultiSelectProperty.AwsIamPrincipalCriticalActionSeverityPermission,
                [Contract.TypeNames.AwsIamUserGroupIdMatchCondition]: () => EntityMultiSelectProperty.AwsIamUserGroupIds,
                [Contract.TypeNames.AwsKmsKeyCustomerManagedMatchCondition]: () => EntityMultiSelectProperty.AwsKmsKeyCustomerManaged,
                [Contract.TypeNames.AwsSsoPermissionSetProvisionedMatchCondition]: () => EntityMultiSelectProperty.AwsSsoPermissionSetProvisioned,
                [Contract.TypeNames.AzureScopeResourceParentEntityIdMatchCondition]: () => EntityMultiSelectProperty.AzureScopeResourceParentEntityIds,
                [Contract.TypeNames.EntityAttributeMatchCondition]: () => EntityMultiSelectProperty.Attributes,
                [Contract.TypeNames.EntityIdMatchCondition]: () => EntityMultiSelectProperty.Ids,
                [Contract.TypeNames.EntityNamePatternMatchCondition]: () => EntityMultiSelectProperty.NamePattern,
                [Contract.TypeNames.EntityPrincipalReferencePropertyMatchCondition]: () => (condition as Contract.EntityPrincipalReferencePropertyMatchCondition).identifier.raw,
                [Contract.TypeNames.EntitySensitiveMatchCondition]: () => EntityMultiSelectProperty.Sensitive,
                [Contract.TypeNames.EntityStringPropertyMatchCondition]: () => (condition as Contract.EntityStringPropertyMatchCondition).identifier.raw,
                [Contract.TypeNames.EntityTagMatchCondition]: () => EntityMultiSelectProperty.Tags,
                [Contract.TypeNames.EntityTenantIdMatchCondition]: () => EntityMultiSelectProperty.TenantIds,
                [Contract.TypeNames.EntityTypeNameMatchCondition]: () => EntityMultiSelectProperty.TypeNames,
                [Contract.TypeNames.GciPrincipalGroupIdMatchCondition]: () => EntityMultiSelectProperty.GciPrincipalGroupIds,
                [Contract.TypeNames.GcpScopeResourceParentScopeResourceIdMatchCondition]: () => EntityMultiSelectProperty.GcpScopeResourceParentScopeResourceIds
            },
            () => {
                throw new UnexpectedError("condition.typeName", condition.typeName);
            });
    }

    public static getOverlappingConditionPropertyIds(
        conditions: Contract.EntityMatchCondition[],
        excludeConditions: Contract.EntityMatchCondition[]): string[] {
        const propertyIdToExcludeConditionMap =
            _.keyBy(
                excludeConditions,
                EntityMatchConditionHelper.getConditionPropertyId);

        return _(conditions).
            keyBy(EntityMatchConditionHelper.getConditionPropertyId).
            pickBy(
                (condition, propertyId) => {
                    const excludeCondition = propertyIdToExcludeConditionMap[propertyId];
                    if (_.isNil(excludeCondition)) {
                        return false;
                    }

                    return map(
                        condition.typeName,
                        {
                            [Contract.TypeNames.AadDirectoryPrincipalGroupIdMatchCondition]: () =>
                                _((condition as Contract.AadDirectoryPrincipalGroupIdMatchCondition).groupIds).
                                    intersection((excludeCondition as Contract.AadDirectoryPrincipalGroupIdMatchCondition).groupIds).
                                    some(),
                            [Contract.TypeNames.AllEntityMatchCondition]: () => true,
                            [Contract.TypeNames.AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition]: () => true,
                            [Contract.TypeNames.AwsIamUserGroupIdMatchCondition]:
                                () =>
                                    _((condition as Contract.AwsIamUserGroupIdMatchCondition).groupIds).
                                        intersection((excludeCondition as Contract.AwsIamUserGroupIdMatchCondition).groupIds).
                                        some(),
                            [Contract.TypeNames.AwsKmsKeyCustomerManagedMatchCondition]: () => true,
                            [Contract.TypeNames.AwsSsoPermissionSetProvisionedMatchCondition]: () => (condition as Contract.AwsSsoPermissionSetProvisionedMatchCondition).provisioned === (excludeCondition as Contract.AwsSsoPermissionSetProvisionedMatchCondition).provisioned,
                            [Contract.TypeNames.AzureScopeResourceParentEntityIdMatchCondition]:
                                () =>
                                    _((condition as Contract.AzureScopeResourceParentEntityIdMatchCondition).parentEntityIds).
                                        intersection((excludeCondition as Contract.AzureScopeResourceParentEntityIdMatchCondition).parentEntityIds).
                                        some(),
                            [Contract.TypeNames.EntityAttributeMatchCondition]:
                                () => {
                                    const attributeMatchCondition = condition as Contract.EntityAttributeMatchCondition;
                                    const attributeExcludeMatchCondition = excludeCondition as Contract.EntityAttributeMatchCondition;
                                    return _(attributeMatchCondition.builtInAttributeTypeNames).
                                        concat(attributeMatchCondition.customAttributeDefinitionIds).
                                        intersection(_.concat(attributeExcludeMatchCondition.builtInAttributeTypeNames, attributeExcludeMatchCondition.customAttributeDefinitionIds)).
                                        some();
                                },
                            [Contract.TypeNames.EntityIdMatchCondition]:
                                () =>
                                    _((condition as Contract.EntityIdMatchCondition).ids).
                                        intersection((excludeCondition as Contract.EntityIdMatchCondition).ids).
                                        some(),
                            [Contract.TypeNames.EntityNamePatternMatchCondition]: () => (condition as Contract.EntityNamePatternMatchCondition).namePattern === (excludeCondition as Contract.EntityNamePatternMatchCondition).namePattern,
                            [Contract.TypeNames.EntityPrincipalReferencePropertyMatchCondition]:
                                () => {
                                    const entityPrincipalReferencePropertyMatchCondition = condition as Contract.EntityPrincipalReferencePropertyMatchCondition;
                                    const entityPrincipalReferencePropertyExcludeMatchCondition = excludeCondition as Contract.EntityPrincipalReferencePropertyMatchCondition;
                                    return _(entityPrincipalReferencePropertyMatchCondition.values).
                                        intersection(entityPrincipalReferencePropertyExcludeMatchCondition.values).
                                        some();
                                },
                            [Contract.TypeNames.EntitySensitiveMatchCondition]: () => true,
                            [Contract.TypeNames.EntityStringPropertyMatchCondition]:
                                () => {
                                    const entityStringPropertyMatchCondition = condition as Contract.EntityStringPropertyMatchCondition;
                                    const entityStringPropertyExcludeMatchCondition = excludeCondition as Contract.EntityStringPropertyMatchCondition;
                                    return _(entityStringPropertyMatchCondition.values).
                                        intersection(entityStringPropertyExcludeMatchCondition.values).
                                        some();
                                },
                            [Contract.TypeNames.EntityTagMatchCondition]:
                                () => {
                                    const entityTagMatchConditionTags =
                                        _.map(
                                            (condition as Contract.EntityTagMatchCondition).tags,
                                            tag => ResourceTagData.createId(tag));
                                    const entityTagExcludeMatchCondition =
                                        _.map(
                                            (excludeCondition as Contract.EntityTagMatchCondition).tags,
                                            tag => ResourceTagData.createId(tag));
                                    return _(entityTagMatchConditionTags).
                                        intersection(entityTagExcludeMatchCondition).
                                        some();
                                },
                            [Contract.TypeNames.EntityTenantIdMatchCondition]:
                                () =>
                                    _((condition as Contract.EntityTenantIdMatchCondition).tenantIds).
                                        intersection((excludeCondition as Contract.EntityTenantIdMatchCondition).tenantIds).
                                        some(),
                            [Contract.TypeNames.EntityTypeNameMatchCondition]:
                                () =>
                                    _((condition as Contract.EntityTypeNameMatchCondition).entityTypeNames).
                                        intersection((excludeCondition as Contract.EntityTypeNameMatchCondition).entityTypeNames).
                                        some(),
                            [Contract.TypeNames.GciPrincipalGroupIdMatchCondition]: () =>
                                _((condition as Contract.GciPrincipalGroupIdMatchCondition).groupIds).
                                    intersection((excludeCondition as Contract.GciPrincipalGroupIdMatchCondition).groupIds).
                                    some(),
                            [Contract.TypeNames.GcpScopeResourceParentScopeResourceIdMatchCondition]:
                                () =>
                                    _((condition as Contract.GcpScopeResourceParentScopeResourceIdMatchCondition).parentScopeResourceIds).
                                        intersection((excludeCondition as Contract.GcpScopeResourceParentScopeResourceIdMatchCondition).parentScopeResourceIds).
                                        some()
                        },
                        () => {
                            throw new UnexpectedError("condition.typeName", condition.typeName);
                        });
                }).
            keys().
            value();
    }

    public static getPropertyData(propertyId: string) {
        const entityPropertyIdentifier = EntityPropertyHelper.tryParseRawIdentifier(propertyId);
        const property =
            _.isNil(entityPropertyIdentifier)
                ? propertyId as EntityMultiSelectProperty
                : EntityMultiSelectProperty.Properties;

        return {
            entityPropertyIdentifier,
            property
        };
    }

    public static validateConditions(
        conditions: Contract.EntityMatchCondition[],
        excludeConditions: Contract.EntityMatchCondition[]): boolean {
        if (_.isEmpty(conditions)) {
            return false;
        }

        const conditionsValid =
            _(conditions).
                concat(excludeConditions).
                every(
                    condition =>
                        map(
                            condition.typeName,
                            {
                                [Contract.TypeNames.AadDirectoryPrincipalGroupIdMatchCondition]: () => !_.isEmpty((condition as Contract.AadDirectoryPrincipalGroupIdMatchCondition).groupIds),
                                [Contract.TypeNames.AllEntityMatchCondition]: () => true,
                                [Contract.TypeNames.AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition]: () => true,
                                [Contract.TypeNames.AwsIamUserGroupIdMatchCondition]: () => !_.isEmpty((condition as Contract.AwsIamUserGroupIdMatchCondition).groupIds),
                                [Contract.TypeNames.AwsKmsKeyCustomerManagedMatchCondition]: () => true,
                                [Contract.TypeNames.AwsSsoPermissionSetProvisionedMatchCondition]: () => true,
                                [Contract.TypeNames.AzureScopeResourceParentEntityIdMatchCondition]: () => !_.isEmpty((condition as Contract.AzureScopeResourceParentEntityIdMatchCondition).parentEntityIds),
                                [Contract.TypeNames.EntityAttributeMatchCondition]: () => {
                                    const attributeMatchCondition = condition as Contract.EntityAttributeMatchCondition;
                                    return !_.isEmpty(attributeMatchCondition.builtInAttributeTypeNames) ||
                                        !_.isEmpty(attributeMatchCondition.customAttributeDefinitionIds);
                                },
                                [Contract.TypeNames.EntityIdMatchCondition]: () => !_.isEmpty((condition as Contract.EntityIdMatchCondition).ids),
                                [Contract.TypeNames.EntityNamePatternMatchCondition]: () => !_.isEmpty((condition as Contract.EntityNamePatternMatchCondition).namePattern),
                                [Contract.TypeNames.EntityPrincipalReferencePropertyMatchCondition]: () => !_.isEmpty((condition as Contract.EntityPrincipalReferencePropertyMatchCondition).values),
                                [Contract.TypeNames.EntitySensitiveMatchCondition]: () => true,
                                [Contract.TypeNames.EntityStringPropertyMatchCondition]: () => !_.isEmpty((condition as Contract.EntityStringPropertyMatchCondition).values),
                                [Contract.TypeNames.EntityTagMatchCondition]: () => !_.isEmpty((condition as Contract.EntityTagMatchCondition).tags),
                                [Contract.TypeNames.EntityTenantIdMatchCondition]: () => !_.isEmpty((condition as Contract.EntityTenantIdMatchCondition).tenantIds),
                                [Contract.TypeNames.EntityTypeNameMatchCondition]: () => !_.isEmpty((condition as Contract.EntityTypeNameMatchCondition).entityTypeNames),
                                [Contract.TypeNames.GciPrincipalGroupIdMatchCondition]: () => !_.isEmpty((condition as Contract.GciPrincipalGroupIdMatchCondition).groupIds),
                                [Contract.TypeNames.GcpScopeResourceParentScopeResourceIdMatchCondition]: () => !_.isEmpty((condition as Contract.GcpScopeResourceParentScopeResourceIdMatchCondition).parentScopeResourceIds)
                            },
                            () => {
                                throw new UnexpectedError("condition.typeName", condition.typeName);
                            }));

        return conditionsValid &&
            _.isEmpty(
                EntityMatchConditionHelper.getOverlappingConditionPropertyIds(
                    conditions,
                    excludeConditions));
    }
}