import { CopyToClipboardTextField, Link, Step, Steps, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { ConfigurationController, Contract, GeographyHelper } from "../../../../../../../../../../../../../../../../../common";
import { AwsCloudFormationHelper } from "../../../../../../../../../../../../../../../../../tenants";
import { AddOrEdit, useAddOrEditContext } from "../../../AddOrEdit";

export function CloudFormationCli() {
    const { masterTenantModel, organizationConfiguration, permissionTypes, roleName, rootRawId } = useAddOrEditContext();

    const [onboardingInfo] =
        useExecuteOperation(
            AddOrEdit,
            () =>
                ConfigurationController.getAwsOrganizationOnboardingInfo(
                    new Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRequest(
                        masterTenantModel?.configuration.partitionType ??
                        GeographyHelper.deploymentAwsPartitionType)));
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit.roleItem.cloudFormationCli",
            () => ({
                steps: {
                    step1: "Login into your **AWS Management account**",
                    step2: {
                        docsLink: "AWS CLI",
                        title: "Open a new shell session with the latest version of the {{docsLink}}. Administrator privileges are required to create the CloudFormation stack"
                    },
                    step3: "Copy the following command, and run it in your shell",
                    step4: {
                        link: "AWS CloudFormation",
                        title: "Open the {{link}} console and navigate to StackSets and open the new created StackSet"
                    },
                    step5: "Wait until the StackSet creation is complete, click on the refresh button in the Events tab to track progress. If it takes longer than expected, you may continue without it"
                }
            }));

    const { createStackSetCliCommand, stackSetsPageUrl, updateStackSetCliCommand } =
        AwsCloudFormationHelper.getOrganizationSteps(
            onboardingInfo,
            masterTenantModel!.configuration.partitionType,
            permissionTypes!,
            roleName!,
            rootRawId!);

    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2.title({
                docsLink:
                    <Link
                        urlOrGetUrl="https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-getting-started.html"
                        variant="external">
                        {localization.steps.step2.docsLink()}
                    </Link>
            })}
            {new Step(
                localization.steps.step3(),
                {
                    contentElement:
                        <CopyToClipboardTextField
                            maxRows={5}
                            multiline={true}
                            value={
                                _.isNil(organizationConfiguration)
                                    ? createStackSetCliCommand
                                    : updateStackSetCliCommand}/>
                })}
            {localization.steps.step4.title({
                link:
                    <Link
                        urlOrGetUrl={stackSetsPageUrl}
                        variant="external">
                        {localization.steps.step4.link()}
                    </Link>
            })}
            {localization.steps.step5()}
        </Steps>);
}