import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useAzureCommonNetworkingInfoItemElements } from "../..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";

export function useAzureNetworkVirtualNetworkSubnetDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const virtualNetworkSubnet = resourceGroupResourceModel.entity as Contract.AzureNetworkVirtualNetworkSubnet;
    const virtualNetworkSubnetModel = resourceGroupResourceModel as Contract.AzureNetworkVirtualNetworkSubnetModel;
    const networkInfoItems = useAzureCommonNetworkingInfoItemElements({ resourceModel: resourceGroupResourceModel });

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkVirtualNetworkSubnetDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        hasNetworkedResources: {
                            false: "No",
                            title: "Used",
                            true: "Yes"
                        },
                        networkSecurityGroupId: "Subnet Network Security Group",
                        routeTableId: "Route Table",
                        subnets: "Address Space"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <VerticalTopItemsInfoItem
                        items={virtualNetworkSubnet.subnets}
                        key="subnets"
                        title={localization.info.items.subnets()}/>,
                    <InfoItem
                        key="hasNetworkedResources"
                        title={localization.info.items.hasNetworkedResources.title()}
                        value={
                            virtualNetworkSubnet.hasNetworkedResources
                                ? localization.info.items.hasNetworkedResources.true()
                                : localization.info.items.hasNetworkedResources.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualNetworkSubnet.routeTableId}
                        entityTypeName={Contract.TypeNames.AzureNetworkRouteTable}
                        key="routeTableId"
                        title={localization.info.items.routeTableId()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.inboundAccessType}
                    {networkInfoItems.inboundExternalAccessScope}
                    {networkInfoItems.getVirtualNetwork()}
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualNetworkSubnet.networkSecurityGroupId}
                        entityTypeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}
                        key="networkSecurityGroupId"
                        title={localization.info.items.networkSecurityGroupId()}/>
                </NetworkingInfoCard>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={virtualNetworkSubnetModel.networkedResourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
            </Info>
    });
}