import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskSendMailAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskSendMailAuditEventDefinition",
            () => ({
                mails: {
                    text: "{{mailCount | NumberFormatter.humanize}} emails",
                    title: "Recipients"
                },
                risk: {
                    many: {
                        text: [
                            "1 finding",
                            "{{count | NumberFormatter.humanize}} findings"
                        ],
                        title: "Shared"
                    },
                    single: {
                        link: "Finding Link",
                        title: "Finding Title"
                    }
                }
            }));

    const riskSendMailAuditEvent = context.auditEventModel.auditEvent as Contract.RiskSendMailAuditEvent;
    if (riskSendMailAuditEvent.riskIds.length == 1) {
        return new AuditEventDefinition([
            new AuditEventDefinitionDetailItem(
                localization.mails.title(),
                riskSendMailAuditEvent.mails.length === 1
                    ? riskSendMailAuditEvent.mails[0]
                    : localization.mails.text({ mailCount: riskSendMailAuditEvent.mails.length }),
                <InlineItems
                    items={riskSendMailAuditEvent.mails}
                    variant="itemPlusItemCount"/>),
            _.isNil(riskAuditEventDetailsItems.riskDeletedItem)
                ? [new AuditEventDefinitionDetailItem(
                    localization.risk.single.title(),
                    riskAuditEventDetailsItems.riskPolicyTypeItem!.valueText),
                new AuditEventDefinitionDetailItem(
                    localization.risk.single.link(),
                    riskAuditEventDetailsItems.riskLinkItem!.valueText,
                    riskAuditEventDetailsItems.riskLinkItem!.valueElement)]
                : riskAuditEventDetailsItems.riskDeletedItem
        ].flat());
    } else {
        return new AuditEventDefinition([
            new AuditEventDefinitionDetailItem(
                localization.mails.title(),
                riskSendMailAuditEvent.mails.length === 1
                    ? riskSendMailAuditEvent.mails[0]
                    : localization.mails.text({ mailCount: riskSendMailAuditEvent.mails.length }),
                <InlineItems
                    items={riskSendMailAuditEvent.mails}
                    variant="itemPlusItemCount"/>),
            new AuditEventDefinitionDetailItem(
                localization.risk.many.title(),
                localization.risk.many.text(riskSendMailAuditEvent.riskCount))
        ]);
    }
}