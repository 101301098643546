﻿import { Action1, MultiSelect, useLocalization } from "@infrastructure";
import { Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { KubernetesResourceHelper } from "../../../../../../../../common";

type RawResourceTypeNameMultiSelectProps = {
    onSelectedRawResourceTypeNamesChanged: Action1<string[]>;
    readonly: boolean;
    selectedRawResourceTypeNames: string[];
};

export function RawResourceTypeNameMultiSelect({ onSelectedRawResourceTypeNamesChanged, readonly, selectedRawResourceTypeNames }: RawResourceTypeNameMultiSelectProps) {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.kubernetesAdmissionController.rawResourceTypeNameMultiSelect",
            () => ({
                placeholder: "Resource types",
                selection: [
                    "{{count | NumberFormatter.humanize}} resource type",
                    "{{count | NumberFormatter.humanize}} resource types"
                ]
            }));

    const theme = useTheme();
    return (
        <MultiSelect
            fieldOptions={{ sx: { width: theme.spacing(40) } }}
            fullWidth={true}
            items={KubernetesResourceHelper.rawResourceTypeNames}
            placeholder={localization.placeholder()}
            readonly={readonly}
            selectedItems={selectedRawResourceTypeNames}
            selectedItemsTranslator={
                selectedRawResourceTypeNames =>
                    selectedRawResourceTypeNames.length === 1
                        ? _.first(selectedRawResourceTypeNames)
                        : localization.selection(selectedRawResourceTypeNames.length)}
            onSelectedItemsChanged={onSelectedRawResourceTypeNamesChanged}>
            {value =>
                <Typography>
                    {value}
                </Typography>}
        </MultiSelect>);
}