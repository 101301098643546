import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useRemoveQueryParameters } from "@infrastructure";
import React from "react";
import { CodeOrganizationQueryParameters } from "../..";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEditGitHub, Table } from "./components";

export class GitHubContext {
    constructor(
        public addOrEditOpen: boolean | Contract.CodeOrganizationModel,
        public callbackError?: Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError,
        public oAuthAccessToken?: string,
        public serverId?: string) {
    }
}

export const [useGitHubContext, useSetGitHubContext, useGitHubContextProvider] = makeContextProvider<GitHubContext>();

export function GitHub() {
    const removeQueryParameters = useRemoveQueryParameters();
    const [context, setContext, ContextProvider] =
        useGitHubContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<GitHubOrganizationQueryParameters>();
                removeQueryParameters("accessToken");
                removeQueryParameters("addOrEditOpen");
                removeQueryParameters("error");
                removeQueryParameters("serverId");
                return new GitHubContext(
                    StringHelper.isTrue(queryParameters.addOrEditOpen) ||
                    !!queryParameters.accessToken ||
                    !!queryParameters.error,
                    queryParameters.error,
                    queryParameters.accessToken,
                    queryParameters.serverId);
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false,
                                    callbackError: undefined,
                                    oAuthAccessToken: undefined,
                                    serverId: undefined
                                }))}>
                    <AddOrEditGitHub/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type GitHubOrganizationQueryParameters = CodeOrganizationQueryParameters & {
    accessToken?: string;
    error?: Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError;
    serverId?: string;
};