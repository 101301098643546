﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstanceRiskContext } from "../../../contexts";

export function useGcpComputeInstanceDefaultServiceAccountRiskInstanceDefinition(riskModel: Contract.GcpComputeInstanceDefaultServiceAccountRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceDefaultServiceAccountRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeInstanceModel;
    const instance = instanceModel.entity as Contract.GcpComputeInstance;

    const getGcpComputeInstanceRiskContext = useGetGcpComputeInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceDefaultServiceAccountRiskDefinition.hooks.useGcpComputeInstanceDefaultServiceAccountRiskInstanceDefinition",
            () => ({
                description: "{{instance}} is using the default service account",
                sections: {
                    resolution: {
                        step1: "Click the **Stop** button to stop your instance",
                        step2: "Click the **Edit** button",
                        step3: "Under **Service accounts** select another service account. Verify that the new service account has the permissions your instance need for accessing resources",
                        step4: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            instance.status === Contract.GcpComputeInstanceStatus.Running
                ? localization.sections.resolution.step1()
                : undefined,
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const instancesContextItems = getGcpComputeInstanceRiskContext(instanceModel);
            return [
                instancesContextItems.generalInformation,
                instancesContextItems.sensitive,
                instancesContextItems.status,
                instancesContextItems.vpcsAndSubnets,
                instancesContextItems.authorizationScopes,
                instancesContextItems.serviceAccount,
                instancesContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}