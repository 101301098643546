import { DataTableColumn, DataTableColumnRenderProps, useLocalization, ValueFilter } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, tenantModelStore, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryEntityDefinition } from "./useAadCommonDirectoryEntityDefinition";

export function useAadCommonDirectoryPrincipalDefinition(definitionData: DefinitionData) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const commonDirectoryEntityDefinition = useAadCommonDirectoryEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadCommonDirectoryPrincipalDefinition",
            () => ({
                columns: {
                    azureRoleAssignmentResources: "Azure Role Assignments",
                    azureRoleDefinitions: "Azure Roles",
                    containedAzureRoleAssignmentResources: {
                        helpText: "These role assignments are \"covered\" by another role assignment at a higher scope level or one that is assigned via group membership.",
                        title: "Covered Azure Role Assignments"
                    },
                    directoryRoleAssignmentResources: "Microsoft Entra ID Administrative Role Assignments",
                    directoryRoleDefinitions: "Microsoft Entra ID Administrative Roles"
                }
            }));
    return {
        columns: {
            ...commonDirectoryEntityDefinition.columns,
            azureRoleAssignmentResourcesColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Azure)
                    ? <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.AadDirectoryPrincipalModel>(
                                Contract.TypeNames.AzureAuthorizationRoleAssignment,
                                item => item.azureRoleAssignmentResourceIds,
                                localization.columns.azureRoleAssignmentResources())}
                        id={Contract.EntityModelProperty.AadDirectoryPrincipalAzureRoleAssignmentResources}
                        key={Contract.EntityModelProperty.AadDirectoryPrincipalAzureRoleAssignmentResources}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryPrincipalModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.azureRoleAssignmentResourceIds}
                                    entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                                    entityVariant="iconText"/>}
                        title={localization.columns.azureRoleAssignmentResources()}/>
                    : undefined,
            azureRoleDefinitionsColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Azure)
                    ? <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.AadDirectoryPrincipalAzureRoleDefinitions)}
                                        placeholder={localization.columns.azureRoleDefinitions()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.AadDirectoryPrincipalAzureRoleDefinitions}
                        key={Contract.EntityModelProperty.AadDirectoryPrincipalAzureRoleDefinitions}
                        title={localization.columns.azureRoleDefinitions()}/>
                    : undefined,
            containedAzureRoleAssignmentResourcesColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Azure)
                    ? <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.AadDirectoryPrincipalModel>(
                                Contract.TypeNames.AzureAuthorizationRoleAssignment,
                                item => item.containedAzureRoleAssignmentResourceIds,
                                localization.columns.containedAzureRoleAssignmentResources.title())}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValueFilter
                                        items={[
                                            {
                                                value: true
                                            }
                                        ]}
                                        placeholder={localization.columns.containedAzureRoleAssignmentResources.title()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.AadDirectoryPrincipalContainedAzureRoleAssignmentResources}
                        key={Contract.EntityModelProperty.AadDirectoryPrincipalContainedAzureRoleAssignmentResources}
                        message={localization.columns.containedAzureRoleAssignmentResources.helpText()}
                        messageLevel="info"
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryPrincipalModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.containedAzureRoleAssignmentResourceIds}
                                    entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                                    entityVariant="iconText"/>}
                        selectorOptions={{ default: false }}
                        title={localization.columns.containedAzureRoleAssignmentResources.title()}/>
                    : undefined,
            directoryRoleAssignmentResourcesColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AadDirectoryPrincipalModel>(
                            Contract.TypeNames.AadDirectoryDirectoryRoleAssignment,
                            item => item.directoryRoleAssignmentResourceIds,
                            localization.columns.directoryRoleAssignmentResources())}
                    id={Contract.EntityModelProperty.AadDirectoryPrincipalDirectoryRoleAssignmentResources}
                    key={Contract.EntityModelProperty.AadDirectoryPrincipalDirectoryRoleAssignmentResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryPrincipalModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.directoryRoleAssignmentResourceIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryDirectoryRoleAssignmentResource}
                                entityVariant="iconText"/>}
                    title={localization.columns.directoryRoleAssignmentResources()}/>,
            directoryRoleDefinitionsColumn:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AadDirectoryPrincipalDirectoryRoleDefinitions)}
                                    placeholder={localization.columns.directoryRoleDefinitions()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AadDirectoryPrincipalDirectoryRoleDefinitions}
                    key={Contract.EntityModelProperty.AadDirectoryPrincipalDirectoryRoleDefinitions}
                    title={localization.columns.directoryRoleDefinitions()}/>
        }
    };
}