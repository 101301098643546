﻿import { Optional } from "@infrastructure";
import { Contract } from "../../../../../../../../../common";
import { PermissionEligibilityData } from "./PermissionEligibilityData";

export abstract class PermissionAssignmentEligibilityData extends PermissionEligibilityData {
    public permissionRequestScopeIds: string[];

    protected constructor(
        permissionEligibilityModel?: Optional<Contract.PermissionEligibilityModel>,
        scopeId?: string) {
        super(
            permissionEligibilityModel,
            scopeId);
        this.permissionRequestScopeIds = (permissionEligibilityModel as Contract.PermissionAssignmentEligibilityModel)?.permissionRequestScopeIds ?? [scopeId!];
    }

    protected UpdatePrincipalTenantRelatedData() {
        super.UpdatePrincipalTenantRelatedData();
        this.permissionRequestScopeIds = [this.scopeId];
    }
}