﻿import { ActionMenuItem, Menu, useLocalization, useSelectionActionsContext } from "@infrastructure";
import { Box } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { SendRisksWebhookDialog } from "../../../../../..";
import { Contract, ScopeHelper, scopeSystemEntityModelStore, SelectionActionButton, WebhookIcon } from "../../../../../../../../../../../common";

export type SendWebhookSelectionActionProps = {
    clearSelection: () => void;
    getSelectedRiskIdToScopeIdsMap: () => Dictionary<string[]>;
};

export function SendWebhookSelectionAction({ clearSelection, getSelectedRiskIdToScopeIdsMap }: SendWebhookSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds } = useSelectionActionsContext();
    const [open, setOpen] = useState(false);
    const [webhookEndpointConfiguration, setWebhookEndpointConfiguration] = useState<Contract.WebhookEndpointConfiguration>();
    const selectedRiskIdToScopeIdsMap = getSelectedRiskIdToScopeIdsMap();
    const webhookModels =
        ScopeHelper.getScopesParentScopeSystemEntityModelsIntersection(
            _.values(selectedRiskIdToScopeIdsMap),
            scopeSystemEntityModelStore.useGetWebhook());
    const webhookEndpointConfigurations =
        useMemo(
            () =>
                _(webhookModels).
                    map(webhookModel => webhookModel.configuration as Contract.WebhookEndpointConfiguration).
                    sortBy(webhookConfiguration => webhookConfiguration.name).
                    value(),
            [webhookModels]);

    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useRiskTypeDefinition.hooks.useCommonSelectionActions.sendWebhookSelectionAction",
            () => ({
                send: "Send to webhook"
            }));

    return (
        _.isEmpty(webhookEndpointConfigurations)
            ? <Fragment/>
            : <Box sx={{ height: "100%" }}>
                {open &&
                    <SendRisksWebhookDialog
                        endpointConfiguration={webhookEndpointConfiguration!}
                        riskIds={itemIds}
                        onClose={
                            canceled => {
                                if (!canceled) {
                                    clearSelection();
                                }

                                actionEnded();
                                setOpen(false);
                            }}/>}
                <Menu
                    automaticPopupWidth={true}
                    itemsOrGetItems={
                        async () =>
                            _.map(
                                webhookEndpointConfigurations,
                                webhookEndpointConfiguration =>
                                    new ActionMenuItem(
                                        () => {
                                            setOpen(true);
                                            setWebhookEndpointConfiguration(webhookEndpointConfiguration);
                                        },
                                        webhookEndpointConfiguration.name))}
                    sx={{ height: "100%" }}
                    variant="topCenter">
                    <SelectionActionButton
                        startIcon={
                            <WebhookIcon
                                sx={{
                                    opacity:
                                        actionExecuting
                                            ? 0.4
                                            : 1
                                }}/>}
                        onClick={
                            () => {
                                actionStarted(true);
                            }}>
                        {localization.send()}
                    </SelectionActionButton>
                </Menu>
            </Box>);
}