import { InfoBox, InfoIcon, InlineItems, Link, NotValidIcon, Steps, SuccessIcon, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper, scopeSystemEntityModelStore, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../AddOrEdit";

export function FinishItem() {
    const { gciTenantModel, name, organizationMemberTenantNames, serviceAccountAssignedRoleNames, serviceAccountMissingOrganizationRoleNames, serviceAccountMissingTenantRoleNames } = useAddOrEditContext();
    const { useNextEffect } = useOrderedWizardContext();

    useNextEffect(
        async () => {
            await tenantModelStore.notify(gciTenantModel!.configuration.id);
            await scopeSystemEntityModelStore.notify();
            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.finishItem",
            () => ({
                organizationMemberTenantNames: {
                    namePluralizer: [
                        "project",
                        "{{count | NumberFormatter.humanize}} projects"
                    ],
                    title: "Detected {{organizationMemberTenantNames}}"
                },
                serviceAccountAssignedRoleNames: "Identified the following roles:",
                serviceAccountMissingAssignedRoleNames: "The following mandatory roles are missing at the Organization scope:",
                serviceAccountTenantMissingAssignedRoleNames: "The following roles are mandatory at the project level and we recommend assign them at the Organization scope:",
                subtitle: {
                    documentationLink: "learn more",
                    text: "We're synchronizing your data now, and you'll be able to see it soon. {{documentationLink}}"
                },
                title: "Organization {{name}} configured successfully"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ height: "100%" }}>
            <SuccessIcon
                sx={{
                    color: theme.palette.success.main,
                    fontSize: "60px"
                }}/>
            <Typography
                align="center"
                sx={{ fontWeight: 400 }}
                variant="h2">
                {localization.title({ name })}
            </Typography>
            <Typography
                align="center"
                variant="h4">
                {localization.organizationMemberTenantNames.title({
                    organizationMemberTenantNames:
                        <InlineItems
                            items={_.orderBy(organizationMemberTenantNames)}
                            namePluralizer={localization.organizationMemberTenantNames.namePluralizer}
                            variant="itemCountAndType"/>
                })}
            </Typography>
            <Typography
                align="center"
                variant="h5">
                {localization.subtitle.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                            variant="external">
                            {localization.subtitle.documentationLink()}
                        </Link>
                })}
            </Typography>
            {!_.isEmpty(serviceAccountAssignedRoleNames) &&
                <InfoBox
                    iconElement={
                        <SuccessIcon
                            sx={{
                                color: theme.palette.success.main,
                                fontSize: "18px"
                            }}/>}
                    title={localization.serviceAccountAssignedRoleNames()}>
                    <Steps>
                        {serviceAccountAssignedRoleNames}
                    </Steps>
                </InfoBox>}
            {!_.isEmpty(serviceAccountMissingOrganizationRoleNames) &&
                <InfoBox
                    iconElement={
                        <NotValidIcon
                            sx={{
                                color: theme.palette.warning.main,
                                fontSize: "18px"
                            }}/>}
                    title={localization.serviceAccountMissingAssignedRoleNames()}>
                    <Steps>
                        {serviceAccountMissingOrganizationRoleNames}
                    </Steps>
                </InfoBox>}
            {!_.isEmpty(serviceAccountMissingTenantRoleNames) &&
                <InfoBox
                    iconElement={
                        <InfoIcon
                            sx={{
                                color: theme.palette.warning.main,
                                fontSize: "18px"
                            }}/>}
                    title={localization.serviceAccountTenantMissingAssignedRoleNames()}>
                    <Steps>
                        {serviceAccountMissingTenantRoleNames}
                    </Steps>
                </InfoBox>}
        </Stack>);
}