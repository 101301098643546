﻿export class SetHelper {
    public static intersection<T>(set: Set<T>, otherSet: Set<T>) {
        const [smallSet, largeSet] = SetHelper.sort(set, otherSet);
        const result = new Set<T>();
        for (const smallSetItem of smallSet) {
            if (largeSet.has(smallSetItem)) {
                result.add(smallSetItem);
            }
        }
        return result;
    }

    public static overlap<T>(set: Set<T>, otherSet: Set<T>) {
        const [smallSet, largeSet] = SetHelper.sort(set, otherSet);
        for (const smallSetItem of smallSet) {
            if (largeSet.has(smallSetItem)) {
                return true;
            }
        }
        return false;
    }

    public static union<T>(set: Set<T>, otherSet: Set<T>) {
        const [smallSet, largeSet] = SetHelper.sort(set, otherSet);
        const unionSet = new Set(largeSet);
        for (const smallSetItem of smallSet) {
            unionSet.add(smallSetItem);
        }
        return unionSet;
    }

    private static sort<T>(set: Set<T>, otherSet: Set<T>) {
        return set.size <= otherSet.size
            ? [set, otherSet]
            : [otherSet, set];
    }
}