import { useMemo } from "react";
import { AddOrEditDefinition } from "../../useAddOrEditDefinition";
import { AddOrEdit } from "./components";

export function useProjectsAddOrEditDefinition(): AddOrEditDefinition {
    return useMemo(
        () => ({
            dialogSize: "medium",
            element: AddOrEdit
        }),
        []);
}