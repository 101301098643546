import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortDirection, Dialog, EmptyMessageText, InlineTexts, makeContextProvider, map, StringHelper, TextValuesFilter, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useRef, useState } from "react";
import { Contract, ResourceTagPatternHelper, scopeNodeModelStore, scopeSystemEntityModelStore, StorageHelper, useScopeNameTranslator, useScopeNavigationViewContext } from "../../../../../../../common";
import { ActionsCell, AddOrEdit } from "./components";

class EntityPropertiesContext {
    constructor(
        public addOrEditOpen: boolean | Contract.EntityPropertyDefinitionConfiguration
    ) {
    }
}

export const [, useSetEntityPropertiesContext, useEntityPropertiesContextProvider] = makeContextProvider<EntityPropertiesContext>();

export function EntityProperties() {
    const entityPropertyDefinitionModels = scopeSystemEntityModelStore.useGetEntityPropertyDefinition();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const scopeParentScopeIds =
        useMemo(
            () => new Set(scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds),
            [scopeNodeModel, scopeNodeMap]);

    const [childScopeEntityPropertiesEnabled, setChildScopeEntityPropertiesEnabled] = useState(!StringHelper.isFalse(StorageHelper.customerConfigurationEntitiesEntityPropertiesFlatView.getValue()));
    const scopeEntityPropertyDefinitionConfigurations =
        useMemo(
            () =>
                _(entityPropertyDefinitionModels).
                    map(entityPropertyModel => entityPropertyModel.configuration as Contract.EntityPropertyDefinitionConfiguration).
                    filter(
                        entityPropertyDefinitionConfiguration =>
                            entityPropertyDefinitionConfiguration.scopeId === scopeNodeModel.configuration.id ||
                            scopeParentScopeIds.has(entityPropertyDefinitionConfiguration.scopeId) ||
                            (childScopeEntityPropertiesEnabled &&
                                scopeNodeMap[entityPropertyDefinitionConfiguration.scopeId]?.parentScopeIds &&
                                _.includes(
                                    scopeNodeMap[entityPropertyDefinitionConfiguration.scopeId].parentScopeIds,
                                    scopeNodeModel.configuration.id))).
                    value(),
            [childScopeEntityPropertiesEnabled, entityPropertyDefinitionModels, scopeNodeModel.configuration.id]);

    const dataTableActionsRef = useRef<DataTableActions>();
    const [context, setContext, ContextProvider] = useEntityPropertiesContextProvider(() => new EntityPropertiesContext(false));

    useChangeEffect(
        () => dataTableActionsRef.current!.reset({ refreshFilters: true }),
        [scopeEntityPropertyDefinitionConfigurations]);

    const [scopeNames, scopeResourceTagPatterns] =
        useMemo(
            () => {
                const scopeNames =
                    _(scopeEntityPropertyDefinitionConfigurations).
                        map(entityPropertyDefinitionConfiguration => entityPropertyDefinitionConfiguration.identifier.name).
                        uniq().
                        value();
                const scopeResourceTagPatterns =
                    _(scopeEntityPropertyDefinitionConfigurations).
                        flatMap(
                            entityPropertyDefinitionConfiguration =>
                                _.map(
                                    entityPropertyDefinitionConfiguration.resourceTagPatterns,
                                    ResourceTagPatternHelper.getDisplayName)).
                        uniq().
                        value();
                return [scopeNames, scopeResourceTagPatterns];
            },
            [scopeEntityPropertyDefinitionConfigurations]);

    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.entities.entityProperties",
            () => ({
                actions: {
                    add: "Add Custom Property",
                    childScopeEntityPropertiesEnabled: "Flat View"
                },
                columns: {
                    name: "Name",
                    resourceTagPatterns: "Tag Patterns",
                    scope: "Scope"
                },
                empty: {
                    withFilter: "No matching custom properties",
                    withoutFilter: "No custom properties"
                }
            }));
    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit
                        entityPropertyDefinitionConfiguration={
                            context.addOrEditOpen === true
                                ? undefined
                                : context.addOrEditOpen}/>
                </Dialog>}
            <DataTable
                actionsRef={dataTableActionsRef}
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
                }}
                fetchItems={
                    (filterMap, sort) =>
                        _(scopeEntityPropertyDefinitionConfigurations).
                            filter(
                                scopeEntityPropertyDefinitionConfiguration => {
                                    if (!_.isEmpty(filterMap[EntityPropertiesColumnId.Name]) &&
                                        !_.includes(
                                            (filterMap[EntityPropertiesColumnId.Name]).values,
                                            scopeEntityPropertyDefinitionConfiguration.identifier.name)) {
                                        return false;
                                    }

                                    if (!_.isEmpty(filterMap[EntityPropertiesColumnId.TagPatterns]) &&
                                        !_.some(
                                            _.map(
                                                scopeEntityPropertyDefinitionConfiguration.resourceTagPatterns,
                                                ResourceTagPatternHelper.getDisplayName),
                                            valueTagPatternDisplayName =>
                                                _.includes(
                                                    (filterMap[EntityPropertiesColumnId.TagPatterns]).values,
                                                    valueTagPatternDisplayName))) {
                                        return false;
                                    }

                                    return true;
                                }).
                            orderBy(
                                [
                                    scopeEntityPropertyDefinitionConfiguration =>
                                        map<string, string | undefined>(
                                            sort?.columnId ?? EntityPropertiesColumnId.Scope,
                                            {
                                                [EntityPropertiesColumnId.Name]: () => StringHelper.getSortValue(scopeEntityPropertyDefinitionConfiguration.identifier.name),
                                                [EntityPropertiesColumnId.Scope]: () => scopeNameTranslator(scopeEntityPropertyDefinitionConfiguration.scopeId, { path: true })
                                            }),
                                    entityPropertyDefinitionConfiguration => StringHelper.getSortValue(entityPropertyDefinitionConfiguration.identifier.name)
                                ],
                                [
                                    sort?.direction === DataTableSortDirection.Descending
                                        ? "desc"
                                        : "asc",
                                    "asc"
                                ]).
                            value()}
                filtersOptions={{
                    persist: { visibilityStorageItem: StorageHelper.customerConfigurationEntityPropertiesFilters }
                }}
                getItemId={(item: Contract.EntityPropertyDefinitionConfiguration) => item.id}>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.name()}
                                    values={scopeNames}/>
                        }
                    }}
                    id={EntityPropertiesColumnId.Name}
                    itemProperty={(item: Contract.EntityPropertyDefinitionConfiguration) => item.identifier.name}
                    key={EntityPropertiesColumnId.Name}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={EntityPropertiesColumnId.Scope}
                    itemProperty={(item: Contract.EntityPropertyDefinitionConfiguration) => scopeNameTranslator(item.scopeId, { path: true })}
                    key={EntityPropertiesColumnId.Scope}
                    title={localization.columns.scope()}/>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.resourceTagPatterns()}
                                    values={scopeResourceTagPatterns}/>
                        }
                    }}
                    id={EntityPropertiesColumnId.TagPatterns}
                    itemProperty={
                        (item: Contract.EntityPropertyDefinitionConfiguration) =>
                            <InlineTexts
                                texts={
                                    _.map(
                                        item.resourceTagPatterns,
                                        ResourceTagPatternHelper.getDisplayName)}
                                variant="itemPlusItemCount"/>}
                    key={EntityPropertiesColumnId.TagPatterns}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.resourceTagPatterns()}/>
                <DataTableColumn
                    id={EntityPropertiesColumnId.Actions}
                    key={EntityPropertiesColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityPropertyDefinitionConfiguration>) =>
                            scopeParentScopeIds.has(item.scopeId)
                                ? <Fragment/>
                                : <ActionsCell entityPropertyDefinitionConfiguration={item}/>}/>
                <DataTableAction>
                    <CheckButton
                        checked={childScopeEntityPropertiesEnabled}
                        title={localization.actions.childScopeEntityPropertiesEnabled()}
                        onCheckedChanged={
                            checked => {
                                StorageHelper.customerConfigurationEntitiesEntityPropertiesFlatView.setValue(checked);
                                setChildScopeEntityPropertiesEnabled(checked);
                            }}/>
                </DataTableAction>
                <DataTableAction>
                    <Button
                        size="small"
                        startIcon={<AddIcon/>}
                        onClick={
                            () =>
                                setContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: true
                                    }))}>
                        {localization.actions.add()}
                    </Button>
                </DataTableAction>
            </DataTable>
        </ContextProvider>)
    ;
}

enum EntityPropertiesColumnId {
    Actions = "actions",
    Name = "name",
    Scope = "scope",
    TagPatterns = "resourceTagPatterns"
}