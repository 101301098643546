import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureMySqlServerStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureMySqlServerStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureMySqlServerStatus]: {
                    [Contract.AzureMySqlServerStatus.Disabled]: "Disabled",
                    [Contract.AzureMySqlServerStatus.Dropping]: "Dropping",
                    [Contract.AzureMySqlServerStatus.Inaccessible]: "Inaccessible",
                    [Contract.AzureMySqlServerStatus.Ready]: "Ready",
                    [Contract.AzureMySqlServerStatus.Restarting]: "Restarting",
                    [Contract.AzureMySqlServerStatus.Starting]: "Starting",
                    [Contract.AzureMySqlServerStatus.Stopped]: "Stopped",
                    [Contract.AzureMySqlServerStatus.Stopping]: "Stopping",
                    [Contract.AzureMySqlServerStatus.Updating]: "Updating",
                    [Contract.AzureMySqlServerStatus.WaitingForMigration]: "Waiting For Migration"
                }
            }));

    return (status: Contract.AzureMySqlServerStatus) =>
        localization[Contract.TypeNames.AzureMySqlServerStatus][status]();
}