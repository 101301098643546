import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PackageIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M2 12C2 17.5121 6.48787 22 12 22C17.5121 22 22 17.5121 22 12C22 6.48787 17.5121 2 12 2C6.48787 2 2 6.48787 2 12ZM0 12C0 18.6167 5.3833 24 12 24C18.6167 24 24 18.6167 24 12C24 5.3833 18.6167 0 12 0C5.3833 0 0 5.3833 0 12Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11 12C11 12.5498 11.4502 13 12 13C12.5498 13 13 12.5498 13 12C13 11.4502 12.5498 11 12 11C11.4502 11 11 11.4502 11 12ZM9 12C9 13.6545 10.3458 15 12 15C13.6542 15 15 13.6545 15 12C15 10.3455 13.6542 9 12 9C10.3458 9 9 10.3455 9 12Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11 5.76775C11 5.34398 11.3985 4.97334 11.9179 5.00151C15.7156 5.20745 18.7629 7.87386 18.9983 11.1968C19.0305 11.6513 18.6069 12 18.1226 12C17.6229 12 17.2501 11.6489 17.2176 11.2485C17.0147 8.74472 14.7203 6.73713 11.8589 6.55958C11.4012 6.53118 11 6.20492 11 5.76775Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}