import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciComputeInstanceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciComputeInstanceStatusTranslator",
            () => ({
                [Contract.TypeNames.OciComputeInstanceStatus]: {
                    [Contract.OciComputeInstanceStatus.CreatingImage]: "Creating Image",
                    [Contract.OciComputeInstanceStatus.Moving]: "Moving",
                    [Contract.OciComputeInstanceStatus.Provisioning]: "Provisioning",
                    [Contract.OciComputeInstanceStatus.Running]: "Running",
                    [Contract.OciComputeInstanceStatus.Starting]: "Starting",
                    [Contract.OciComputeInstanceStatus.Stopped]: "Stopped",
                    [Contract.OciComputeInstanceStatus.Stopping]: "Stopping",
                    [Contract.OciComputeInstanceStatus.Terminated]: "Terminated",
                    [Contract.OciComputeInstanceStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciComputeInstanceStatus) =>
        localization[Contract.TypeNames.OciComputeInstanceStatus][status]();
}