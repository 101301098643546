import { ThemeHelper } from "@infrastructure";
import i18n from "i18next";
import _ from "lodash";
import { GeographyHelper, UserHelper } from ".";
import { Contract } from "../controllers";

declare global {
    interface Window {
        Beamer: any;
        beamer_config: any;
    }
}

export class BeamerHelper {
    public static readonly beamerButtonId = "beamer";
    private static readonly beamerProductId = "WhypDKqN71423";

    public static initialize() {
        const beamer_config = {
            alert: true,
            bounce: false,
            button: false,
            display: "right",
            filter: "logged",
            force_button: false,
            language: i18n.resolvedLanguage,
            lazy: false,
            product_id: BeamerHelper.beamerProductId,
            selector: `#${BeamerHelper.beamerButtonId}`,
            theme: _.toLower(ThemeHelper.getThemeType()),
            user_id: UserHelper.mailHash,
            ...BeamerHelper.getUserConfiguration()
        };

        const scriptElement = document.createElement("script");
        scriptElement.src = `https://app.getbeamer.com/js/beamer-embed.js`;
        scriptElement.id = "beamerScript";
        scriptElement.type = "text/javascript";
        scriptElement.defer = true;
        scriptElement.onload = () => window.beamer_config = beamer_config;
        document.body.appendChild(scriptElement);
    }

    private static getUserConfiguration() {
        const [firstName, ...lastNameParts] = _.split(UserHelper.nameValue, " ");
        return GeographyHelper.deploymentPartitionType === Contract.PartitionType.Public
            ? {
                user_email: UserHelper.mail,
                user_firstname: firstName,
                user_lastname: _.join(lastNameParts, " ")
            }
            : {
                user_email: "beamer.user@tenable.com",
                user_firstname: "Tenable",
                user_lastname: "User"
            };
    }
}