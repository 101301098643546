import { Optional } from "@infrastructure";
import { SxProps, Theme } from "@mui/material";
import _ from "lodash";

export class Sx {
    static combine(sx1: Optional<SxProps | SxProps<Theme>>, sx2: Optional<SxProps | SxProps<Theme>>, sx3?: SxProps | SxProps<Theme>): SxProps {
        return [
            ...(_.isArray(sx1)
                ? sx1
                : [sx1]),
            ...(_.isArray(sx2)
                ? sx2
                : [sx2]),
            ...(_.isArray(sx3)
                ? sx3
                : [sx3])
        ];
    }
}