import { AnalyticsEventActionType, CsvExportButton, EmptyMessage, Loading, makeContextProvider, useChangeEffect, useExecuteOperation, useGetExportFileName, useLocalization, useRoute, useSyncContext, useTrackAnalyticsEvent, VerticalFillGrid } from "@infrastructure";
import { Box, Divider } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { Contract, entityModelStore, ExportReportHelper, tenantModelStore, useEntityTypeNameTranslator, useTheme } from "../..";
import { AwsTenantHelper } from "../../../tenants";
import { Graph, List } from "./components";
import { AccessDefinition, AccessDefinitionToolbarActions, useDefinition } from "./hooks";
import { AccessGraphHelper } from "./utilities";

type AccessProps = {
    baseUrl: string;
    destinationResourceTenantId?: string;
    entityId: string;
    scope: Contract.EntityAccessScope;
    tenantType: Contract.TenantType;
    variant: "entity" | "risk";
};

export class AccessContext {
    constructor(
        public definition: AccessDefinition,
        public direction: Contract.EntityAccessDirection,
        public entityId: string,
        public filters: Contract.EntityControllerGetAccessDataRequestFilters,
        public scope: Contract.EntityAccessScope,
        public variant: "entity" | "risk") {
    }
}

export const [useAccessContext, useSetAccessContext, useAccessContextProvider] = makeContextProvider<AccessContext>();

export function Access({ baseUrl, destinationResourceTenantId, entityId, scope, tenantType, variant }: AccessProps) {
    const entityModel = entityModelStore.useGet(entityId);
    const definition = useDefinition(entityModel.entity.typeName, tenantType);
    let { direction, view } = useRoute(`${baseUrl}/{view}/{direction}`);
    view = view ?? AccessView.Graph;
    direction = direction ?? definition.graphInitialDirection;

    return (
        <Core
            baseUrl={baseUrl}
            definition={definition}
            destinationResourceTenantId={destinationResourceTenantId}
            direction={direction as Contract.EntityAccessDirection}
            entityModel={entityModel}
            key={direction}
            scope={scope}
            tenantType={tenantType}
            variant={variant}
            view={view as AccessView}/>);
}

type CoreProps = {
    baseUrl: string;
    definition: AccessDefinition;
    destinationResourceTenantId?: string;
    direction: Contract.EntityAccessDirection;
    entityModel: Contract.EntityModel;
    scope: Contract.EntityAccessScope;
    tenantType: Contract.TenantType;
    variant: "entity" | "risk";
    view: AccessView;
};

function Core({ baseUrl, definition, destinationResourceTenantId, direction, entityModel, scope, tenantType, variant, view }: CoreProps) {
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();
    const [toolbarFilterMap, setToolbarFilterMap] = useState<Dictionary<any>>({});

    const [{ accessGraph, accessGraphId, entityId, filters, result }, executeGetAccessGraph] =
        useExecuteOperation(
            [Core, `${entityModel.id}/${tenantType}`],
            async () => {
                const filters =
                    definition.getFilters(
                        toolbarFilterMap,
                        destinationResourceTenantId);

                const { accessGraph, entityId, result } =
                    await definition.getAccessGraph(
                        direction,
                        entityModel.id,
                        filters,
                        scope);
                return {
                    accessGraph,
                    accessGraphId: uuid(),
                    entityId,
                    filters,
                    result
                };
            });

    const [, , ContextProvider] =
        useAccessContextProvider(
            () =>
                new AccessContext(
                    definition,
                    direction,
                    entityId,
                    filters,
                    scope,
                    variant),
            [filters]);

    const [loading, setLoading] = useState(false);
    const executeGetAccessGraphSyncContext = useSyncContext();
    useChangeEffect(
        async () => {
            setLoading(true);
            const syncContext = executeGetAccessGraphSyncContext.create();
            await executeGetAccessGraph();
            if (executeGetAccessGraphSyncContext.isActive(syncContext)) {
                setLoading(false);
            }
        },
        [toolbarFilterMap]);

    const empty =
        useMemo(
            () => _.isNil(accessGraph),
            []);

    const excessivePermissionsEnabled =
        tenantType !== Contract.TenantType.Aws ||
        AwsTenantHelper.isAccessAdvisorEnabled(entityModel.entity, tenantModelMap);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    useTrackAnalyticsEvent(
        AnalyticsEventActionType.EntitiesProfileAccessTabGraph,
        {
            "Direction": direction,
            "View": view
        },
        [view, direction]);

    const localization =
        useLocalization(
            "common.access",
            () => ({
                csvExport: {
                    excessivePermissionAction: {
                        false: "No",
                        true: "Yes"
                    },
                    fileNamePrefix: "{{definitionFileNamePrefix}}_{{translatedEntityTypeName}}_{{entityDisplayName}}"
                },
                empty: "No Matching Results",
                [Contract.TypeNames.EntityAccessEvaluatorEvaluateResult]: {
                    [Contract.EntityAccessEvaluatorEvaluateResult.None]: "No Permissions Available",
                    [Contract.EntityAccessEvaluatorEvaluateResult.NoneDeletedEntity]: "No permissions available since this resource is deleted",
                    [Contract.EntityAccessEvaluatorEvaluateResult.NoneAwsPolicyPermissionBoundaryOnly]: "This policy does not grant any permissions since its only used as permission boundary",
                    [Contract.EntityAccessEvaluatorEvaluateResult.NoneEmptyGroup]: "This group does not grant effective permissions as there are no users in the group"
                }
            }));

    const resourceServiceModelMap = AccessGraphHelper.useGetResourceServiceModelMap(accessGraph);

    const permissionEdgeIdToDataMap =
        useMemo(
            () => AccessGraphHelper.getPermissionEdgeIdToDataMap(accessGraph),
            [accessGraph]);

    const toolbarActionsRef = useRef<AccessDefinitionToolbarActions>();
    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);
    const theme = useTheme();
    return (
        <ContextProvider>
            <Box
                sx={{
                    height:
                        variant === "risk"
                            ? theme.spacing(90)
                            : "100%",
                    position: "relative",
                    width: "100%"
                }}>
                <VerticalFillGrid>
                    <definition.toolbarComponent
                        accessGraph={accessGraph}
                        actionsRef={toolbarActionsRef}
                        baseUrl={baseUrl}
                        csvExportButtonElement={
                            <CsvExportButton
                                fileNameOptions={{
                                    filtered: !_.isEmpty(toolbarFilterMap),
                                    prefix: localization.csvExport.fileNamePrefix({
                                        definitionFileNamePrefix: definition.csvExportFileNamePrefix,
                                        entityDisplayName: entityModel.entity.displayName,
                                        translatedEntityTypeName:
                                            _.replace(
                                                entityTypeNameTranslator(
                                                    entityModel.entity.typeName,
                                                    { includeServiceName: false }),
                                                /\s/g,
                                                "")
                                    })
                                }}
                                onClick={
                                    async fileNameOptions => {
                                        const reportRequestDefinition =
                                            definition.getAccessReportRequestDefinition(
                                                direction,
                                                entityId,
                                                filters,
                                                scope);
                                        reportRequestDefinition.name = getExportFileName(fileNameOptions);
                                        await ExportReportHelper.downloadRemote(reportRequestDefinition);
                                    }}/>}
                        entityModel={entityModel}
                        graphDirection={direction}
                        view={view as AccessView}
                        onFiltersChanged={toolbarFilters => setToolbarFilterMap(toolbarFilters)}/>
                    <Divider/>
                    <Loading loading={loading}>
                        {result !== Contract.EntityAccessEvaluatorEvaluateResult.Success
                            ? <EmptyMessage
                                message={
                                    empty
                                        ? localization[Contract.TypeNames.EntityAccessEvaluatorEvaluateResult][result]()
                                        : localization.empty()}
                                verticalCenter={true}/>
                            : <Fragment>
                                {view === AccessView.Graph &&
                                    <Graph
                                        accessGraph={accessGraph!}
                                        accessGraphId={accessGraphId}
                                        baseUrl={baseUrl}
                                        excessivePermissionsEnabled={excessivePermissionsEnabled}
                                        permissionEdgeIdToDataMap={permissionEdgeIdToDataMap}
                                        resourceServiceModelMap={resourceServiceModelMap}
                                        tenantType={tenantType}
                                        toolbarActionsRef={toolbarActionsRef}/>}
                                {view === AccessView.List &&
                                    <List
                                        accessGraph={accessGraph!}
                                        accessGraphId={accessGraphId}
                                        excessivePermissionsEnabled={excessivePermissionsEnabled}
                                        permissionEdgeIdToDataMap={permissionEdgeIdToDataMap}
                                        resourceServiceModelMap={resourceServiceModelMap}/>}
                            </Fragment>}
                    </Loading>
                </VerticalFillGrid>
            </Box>
        </ContextProvider>);
}

export enum AccessView {
    Graph = "graph",
    List = "list"
}