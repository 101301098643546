import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEsDomainRiskContext } from "../contexts";

export function useAwsEsDomainCloudWatchLoggingDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEsDomainCloudWatchLoggingDisabledRisk;
    const domainModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEsDomainModel;
    const domain = domainModel.entity as Contract.AwsEsDomain;

    const getAwsEsDomainRiskContext = useGetAwsEsDomainRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEsDomainCloudWatchLoggingDisabledRiskDefinition",
            () => ({
                description: "{{domain}} does not publish logs to CloudWatch Logs",
                sections: {
                    resolution: {
                        step1: "Scroll down to the configuration table and navigate to **Logs**",
                        step2: "In the **CloudWatch Logs** section, select the **Error logs** and click **Enable**",
                        step3: "Specify CloudWatch access policy that was created earlier or Create a new policy",
                        step4: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            domain:
                <Entity
                    entityIdOrModel={domainModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Es,
                AwsConsoleUrlBuilder.getEsDomainUrl(domain)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const domainRiskContext = getAwsEsDomainRiskContext(domainModel);
            return [
                domainRiskContext.generalInformation,
                domainRiskContext.vpc,
                domainRiskContext.sensitive,
                domainRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}