import { Message, Optional, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { SecurityStatsStat } from ".";
import { Contract, InfoItem, InlineEntities, useEntityTypeNameTranslator, useTheme } from "../../../../../../../common";

type PrincipalSecurityStatsStatProps = {
    analyzingReason?: Optional<Contract.EntityAnalyzingReason>;
    analyzingTenants?: Optional<PrincipalSecurityStatsStatPropsAnalyzingReasonTenants>;
    securityStats: Contract.SecurityStats;
    typeName: string;
};

type PrincipalSecurityStatsStatPropsAnalyzingReasonTenants = {
    ids: string[];
    typeName: string;
};

export function PrincipalSecurityStatsStat({ analyzingReason, analyzingTenants, securityStats, typeName }: PrincipalSecurityStatsStatProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.principalSecurityStatsStat",
            () => ({
                analyzing: "Learning",
                excessive: "Excessive Permissions",
                info: {
                    withoutTenants: {
                        [Contract.EntityAnalyzingReason.AwsPermissionSetNewAssignment]: "This {{translatedEntityTypeName}} was recently assigned to new accounts",
                        [Contract.EntityAnalyzingReason.GroupNewMember]: "This {{translatedEntityTypeName}} has newly created members recently added to it",
                        [Contract.EntityAnalyzingReason.NewEntity]: "This {{translatedEntityTypeName}} was recently created",
                        [Contract.EntityAnalyzingReason.RecentUpdatePermissionAction]: "This {{translatedEntityTypeName}} had recent permission changes"
                    },
                    withTenants: {
                        [Contract.EntityAnalyzingReason.RecentUpdatePermissionAction]: "This {{translatedEntityTypeName}} had recent permission changes, triggering an analysis period which affects {{analyzingTenants}}"
                    }
                }
            }));

    const theme = useTheme();
    return (
        _.isNil(analyzingReason)
            ? <SecurityStatsStat securityStats={securityStats}/>
            : <InfoItem
                title={localization.excessive()}>
                <Stack flexDirection="row">
                    <Typography
                        noWrap={true}
                        sx={{ marginRight: theme.spacing(0.5) }}>
                        {localization.analyzing()}
                    </Typography>
                    <Message
                        level="info"
                        title={
                            _.isNil(analyzingTenants)
                                ? localization.info.withoutTenants[analyzingReason]({
                                    translatedEntityTypeName:
                                        entityTypeNameTranslator(
                                            typeName,
                                            {
                                                variant: "text"
                                            })
                                })
                                : localization.info.withTenants.translate(
                                    analyzingReason,
                                    {
                                        analyzingTenants:
                                            <InlineEntities
                                                entityIdsOrModels={analyzingTenants.ids}
                                                entityTypeName={analyzingTenants.typeName}
                                                variant="itemCountAndType"/>,
                                        translatedEntityTypeName:
                                            entityTypeNameTranslator(
                                                typeName,
                                                {
                                                    variant: "text"
                                                })
                                    })}
                        variant="minimal"/>
                </Stack>
            </InfoItem>);
}