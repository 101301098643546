import { DirectedGraphModelColumn, UnexpectedError, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { NetworkDefinition } from "../..";
import { Contract, EntityController } from "../../../../../../controllers";
import { ItemSelectionHelper } from "../../../../../../utilities";
import { GraphColumnId, ToolbarFilterId, ToolbarToggleFilterId } from "../../../../components";
import { GcpServiceSubnetIcon, GcpServiceSubnetsIcon } from "./components";
import { useGcpNetworkToolbarFilterIdTranslator, useGcpNetworkToolbarToggleFilterIdTranslator } from "./hooks";

export function useGcpDefinition() {
    const localization =
        useLocalization(
            "common.network.hooks.useDefinition.hooks.useGcpDefinition",
            () => ({
                columns: {
                    [GraphColumnId.DestinationGroups]: "Targets",
                    [GraphColumnId.FirewallPolicyAndVpcs]: "Security Perimeters",
                    [GraphColumnId.Gateways]: "Via",
                    [GraphColumnId.Sources]: "Sources"
                }
            }));


    return new NetworkDefinition(
        _.map(
            [
                GraphColumnId.Sources,
                GraphColumnId.FirewallPolicyAndVpcs,
                GraphColumnId.Gateways,
                GraphColumnId.DestinationGroups
            ],
            (columnId: Exclude<GraphColumnId, | GraphColumnId.InterfaceSecurityGroups | GraphColumnId.SubnetSecurityGroups>) =>
                new DirectedGraphModelColumn(
                    columnId,
                    localization.columns[columnId]())),
        (entityId: string, filterMap: Dictionary<any>) =>
            EntityController.getNetworkGraph(
                new Contract.EntityControllerGetGcpNetworkGraphRequest(
                    entityId,
                    {
                        destinationScopeFilterIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationScopeFilterIds]),
                        securityPerimeterResourceIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.FirewallPolicyAndVpcIds]),
                        sourceEntityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.SourceEntityIds]),
                        sourceSubnetSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.SourceSubnets]),
                        wideRangeSourceSubnet: filterMap[ToolbarToggleFilterId.WideRangeSourceSubnet] as boolean
                    })
            ),
        (specialSource: Contract.NetworkGraphSpecialSource) => {
            throw new UnexpectedError("specialSource", specialSource);
        },
        <GcpServiceSubnetIcon/>,
        <GcpServiceSubnetsIcon/>,
        useGcpNetworkToolbarFilterIdTranslator(),
        useGcpNetworkToolbarToggleFilterIdTranslator());
}