import { DistributionBar, DistributionBarItem } from "@infrastructure";
import React from "react";
import { useTheme } from "..";

type SecuredBarProps = {
    count: number;
    height: "normal" | "narrow";
    securedCount: number;
};

export function SecuredBar({ count, height, securedCount }: SecuredBarProps) {
    const theme = useTheme();
    return count === 0
        ? <DistributionBar
            height={height}
            items={[
                new DistributionBarItem(
                    theme.palette.success.main,
                    1)
            ]}
            totalValue={1}/>
        : <DistributionBar
            height={height}
            items={[
                new DistributionBarItem(
                    theme.palette.securedColor(securedCount / count),
                    securedCount),
                new DistributionBarItem(
                    theme.palette.borders.primary,
                    count - securedCount)
            ]}
            totalValue={count}/>;
}