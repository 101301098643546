import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsIamPrincipalRiskContext() {
    return useMemo(
        () => useAwsIamPrincipalRiskContext,
        []);
}

function useAwsIamPrincipalRiskContext(principalModel: Contract.AwsIamPrincipalModel) {
    const principal = principalModel.entity as Contract.AwsIamPrincipal;
    const resourceRiskContext = useGetAwsResourceRiskContext()(principalModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsIamPrincipalRiskContext",
            () => ({
                attachedPolicies: "The {{translatedPrincipalTypeName}} has {{policies}} attached",
                permissionActionSeverity: {
                    any: "The {{translatedPrincipalTypeName}} has **{{principalPermissionActionSeverity}}** severity permissions",
                    none: "The {{translatedPrincipalTypeName}} has no permissions"
                }
            }));

    const translatedPrincipalTypeName =
        entityTypeNameTranslator(
            principalModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...resourceRiskContext,
        attachedPolicies:
            new RiskDefinitionContextItem(
                localization.attachedPolicies({
                    policies:
                        <InlineEntities
                            entityIdsOrModels={[
                                ...principal.inlinePolicyIds,
                                ...principal.managedPolicyIds
                            ]}
                            entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                            variant="itemCountAndType"/>,
                    translatedPrincipalTypeName
                })),
        permissionActionSeverity:
            new RiskDefinitionContextItem(
                _.isNil(principalModel.permissionActionSeverity)
                    ? localization.permissionActionSeverity.none({ translatedPrincipalTypeName })
                    : localization.permissionActionSeverity.any({
                        principalPermissionActionSeverity: severityTranslator(principalModel.permissionActionSeverity, "text"),
                        translatedPrincipalTypeName
                    }))
    };
}