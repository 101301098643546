﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, Optional, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, EntityTypeFilter, ItemSelectionHelper, NoneDefinition, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpIamRoleBindingExpiredTranslator, useGcpIamRoleBindingPermissionUsageTypeTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonEntityDefinition } from "./useGcpCommonEntityDefinition";

export function useGcpIamRoleBindingDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useGcpCommonEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const roleBindingModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GcpIamRoleBindingModelFilters>;

    const roleBindingExpiredTranslator = useGcpIamRoleBindingExpiredTranslator();
    const roleBindingPermissionUsageTypeTranslator = useGcpIamRoleBindingPermissionUsageTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpIamRoleBindingDefinition",
            () => ({
                columns: {
                    containingRoleBindingIds: {
                        helpText: "The role binding is \"covered\" by these role bindings, which may come from a higher scope level or group membership.",
                        title: "Covered By"
                    },
                    expired: "Status",
                    permissionUsageType: {
                        helpText: "'Unknown' indicates that Tenable Cloud Security hasn't collected enough activity history to know with certainty whether the binding was active in the last 90 days.",
                        title: "Usage"
                    },
                    principalIdReference: "Principal",
                    roleId: "Role",
                    scopeResourceIdReference: "Scope",
                    scopeResourceTypeName: "Scope Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonEntityDefinition.columns.gcpTenantColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamRoleBindingModel>(
                        Contract.TypeNames.GcpIamRole,
                        item => [(item.entity as Contract.GcpIamRoleBinding).roleId],
                        localization.columns.roleId()
                    )
                }
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamRoleBindingRole)}
                                placeholder={localization.columns.roleId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamRoleBindingRole}
                key={Contract.EntityModelProperty.GcpIamRoleBindingRole}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamRoleBindingModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.GcpIamRoleBinding).roleId}
                            entityTypeName={Contract.TypeNames.GcpIamRole}
                            entityVariant="iconText"/>
                }
                title={localization.columns.roleId()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamRoleBindingModel>(
                        Contract.TypeNames.GcpScopeResource,
                        item => [item.scopeResourceIdReference],
                        localization.columns.scopeResourceIdReference()
                    )
                }
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamRoleBindingScopeResource)}
                                placeholder={localization.columns.scopeResourceIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamRoleBindingScopeResource}
                key={Contract.EntityModelProperty.GcpIamRoleBindingScopeResource}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamRoleBindingModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.scopeResourceIdReference}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            entityVariant="iconText"/>
                }
                title={localization.columns.scopeResourceIdReference()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamRoleBindingModel>(
                        Contract.TypeNames.IGciPrincipal,
                        item => [item.principalIdReference],
                        localization.columns.principalIdReference()
                    )
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamRoleBindingPrincipal)}
                                placeholder={localization.columns.principalIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamRoleBindingPrincipal}
                key={Contract.EntityModelProperty.GcpIamRoleBindingPrincipal}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamRoleBindingModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.principalIdReference}
                            entityTypeName={Contract.TypeNames.IGciPrincipal}
                            entityVariant="iconText"/>
                }
                title={localization.columns.principalIdReference()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamRoleBindingModel) => ({
                            [localization.columns.expired()]: roleBindingExpiredTranslator((item.entity as Contract.GcpIamRoleBinding).expired)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.expired()}>
                                <ValuesFilterItem
                                    title={roleBindingExpiredTranslator(true)}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={roleBindingExpiredTranslator(false)}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamRoleBindingExpired}
                itemProperty={(item: Contract.GcpIamRoleBindingModel) => roleBindingExpiredTranslator((item.entity as Contract.GcpIamRoleBinding).expired)}
                key={Contract.EntityModelProperty.GcpIamRoleBindingExpired}
                title={localization.columns.expired()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamRoleBindingModel) => ({
                            [localization.columns.permissionUsageType.title()]:
                                _.isNil(item.permissionUsageType)
                                    ? "-"
                                    : roleBindingPermissionUsageTypeTranslator(item.permissionUsageType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.GcpIamRoleBindingModelPermissionUsageType}
                                enumTypeTranslator={roleBindingPermissionUsageTypeTranslator}
                                placeholder={localization.columns.permissionUsageType.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamRoleBindingPermissionUsageType}
                itemProperty={
                    (item: Contract.GcpIamRoleBindingModel) =>
                        _.isNil(item.permissionUsageType)
                            ? <NoneDefinition/>
                            : roleBindingPermissionUsageTypeTranslator(item.permissionUsageType)
                }
                key={Contract.EntityModelProperty.GcpIamRoleBindingPermissionUsageType}
                message={localization.columns.permissionUsageType.helpText()}
                messageLevel="info"
                title={localization.columns.permissionUsageType.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamRoleBindingModel>(
                        Contract.TypeNames.GcpIamRoleBinding,
                        item => item.containingRoleBindingIds,
                        localization.columns.containingRoleBindingIds.title())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.containingRoleBindingIds.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamRoleBindingContainingRoleBindings}
                key={Contract.EntityModelProperty.GcpIamRoleBindingContainingRoleBindings}
                message={localization.columns.containingRoleBindingIds.helpText()}
                messageLevel="info"
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamRoleBindingModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.containingRoleBindingIds}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.containingRoleBindingIds.title()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={roleBindingModelFiltersPromise}
                                title={localization.columns.scopeResourceTypeName()}>
                                {filters =>
                                    <EntityTypeFilter
                                        emptyValue={false}
                                        entityTypeNames={filters.scopeResourceTypeNameItems.items}
                                        placeholder={localization.columns.scopeResourceTypeName()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamRoleBindingScopeResourceTypeName}
                key={Contract.EntityModelProperty.GcpIamRoleBindingScopeResourceTypeName}
                sortOptions={{ enabled: false }}
                title={localization.columns.scopeResourceTypeName()}/>,
            commonEntityDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: _.Dictionary<any>, limit: number, propertyFilterMap: _.Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpIamRoleBindingRequest(
                new Contract.EntityControllerGetEntityModelPageGcpIamRoleBindingRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    filterMap[Contract.EntityModelProperty.GcpIamRoleBindingContainingRoleBindings] as Optional<boolean>,
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamRoleBindingExpired]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamRoleBindingPermissionUsageType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamRoleBindingPrincipal]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamRoleBindingRole]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamRoleBindingScopeResource]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamRoleBindingScopeResourceTypeName])),
                limit,
                skip,
                requestSort));
}