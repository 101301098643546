import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonKubernetesNamespaceResourceInfoItemElements, useGcpCommonKubernetesWorkloadResourceInfoItemElements, useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { KubernetesContainers, KubernetesTolerationsInfoCard, KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpKubernetesCronJobDefinition(resourceModel: Contract.GcpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useGcpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const commonKubernetesWorkloadResourceInfoItemElements = useGcpCommonKubernetesWorkloadResourceInfoItemElements(resourceModel as Contract.GcpKubernetesCronJobModel);
    const cronJob = resourceModel.entity as Contract.GcpKubernetesCronJob;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpKubernetesCronJobDefinition",
            () => ({
                tabs: {
                    containers: "Containers",
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isEmpty(cronJob.data.podTemplateSpec.podSpec.containers),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <KubernetesContainers workloadResourceModel={resourceModel as Contract.GcpKubernetesWorkloadResourceModel}/>,
                        localization.tabs.containers(),
                        "containers")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <KubernetesYamlInfoCard rawYaml={cronJob.data.rawYaml!}/>,
                        localization.tabs.yaml(),
                        "yaml")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.containerImagesInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.containerImageVulnerabilitiesInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.controllerResourceInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.nodePoolsInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.serviceAccountInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.gcpServiceAccountsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement
                ]}
                options={options?.infoOptions}>
                {!_.isEmpty(cronJob.data.podTemplateSpec.podSpec.tolerations) &&
                    <KubernetesTolerationsInfoCard tolerations={cronJob.data.podTemplateSpec.podSpec.tolerations}/>}
            </Info>
    });
}