import { sanitizeUrl } from "@braintree/sanitize-url";
import _ from "lodash";
import { formatQueryParameters, getUrlQueryParameters } from "../hooks/useQueryParameters";
import { Optional } from "../types";

export class UrlHelper {
    public static formatRedirectUrlQueryParameters(redirectUrl: string): string {
        return formatQueryParameters<UrlHelperRedirectUrlQueryParameters>({ redirectUrl });
    }

    public static getPathRootSegment(path?: string): string {
        return _.trimStart(path ?? window.location.pathname, "/").
            split("?")[0].
            split("/")[0];
    }

    public static getRedirectUrlQueryParameters() {
        return getUrlQueryParameters<UrlHelperRedirectUrlQueryParameters>();
    }

    public static getUrl<TRelativeUrl extends string | undefined>(url: URL, relativeUrl?: TRelativeUrl): TRelativeUrl extends string ? string : undefined {
        return relativeUrl == undefined
            ? undefined
            : new URL(
                relativeUrl as string,
                url).href as any;
    }

    public static getUrlData(rawUrl: string): Optional<UrlHelperGetUrlData> {
        try {
            const url = new URL(rawUrl);
            const protocolType =
                url.protocol === "http:"
                    ? "http"
                    : url.protocol === "https:"
                        ? "https"
                        : undefined;
            return protocolType === "http" || protocolType === "https"
                ? Object.assign(url, { protocolType }) as UrlHelperGetUrlData
                : undefined;
        } catch {
            return undefined;
        }
    }

    public static isRelativeUrl(url: URL, relativeUrl: string): boolean {
        try {
            new URL(relativeUrl, url);
            return true;
        } catch {
            return false;
        }
    }

    public static openNewTab(url: string) {
        window.open(url, "_blank");
    }

    public static sanitize(url: string) {
        return sanitizeUrl(url).
            replace("about:blank", "");
    }
}

type UrlHelperRedirectUrlQueryParameters = {
    RedirectPath?: string;
    redirectUrl?: string;
};

type UrlHelperGetUrlData =
    URL & {
        protocolType?: "http" | "https";
    };