import { Optional } from "@infrastructure";
import React from "react";
import { AzureFirewallRulesSubnetTable } from "../../../../../../../../../../../tenants";

type FirewallRuleSubnetsDetailsSectionProps = {
    getHighlightColor?: (subnets: string, opacity?: number) => Optional<string>;
    subnets: string[];
};

export function FirewallRuleSubnetsDetailsSection({ getHighlightColor, subnets }: FirewallRuleSubnetsDetailsSectionProps) {
    return (
        <AzureFirewallRulesSubnetTable
            rowOptions={{
                getHighlightColor: (subnet: string) => getHighlightColor?.(subnet),
                getSx:
                    (subnet: string) =>
                        ({ backgroundColor: getHighlightColor?.(subnet, 0.1) })
            }}
            subnets={subnets}/>);
}