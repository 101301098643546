import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsRdsSnapshotEncryptionDisabledRiskResolutionSteps } from ".";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsSnapshotRiskContext } from "../../../contexts";

export function useAwsRdsSnapshotEncryptionDisabledRiskSnapshotDefinition(riskModel: Contract.AwsRdsSnapshotEncryptionDisabledRiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsSnapshotEncryptionDisabledRisk;
    const snapshotModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsSnapshotModel;

    const getAwsRdsSnapshotRiskContext = useGetAwsRdsSnapshotRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsSnapshotEncryptionDisabledRiskDefinition.hooks.useAwsRdsSnapshotEncryptionDisabledRiskSnapshotDefinition",
            () => ({
                contextItems: {
                    internalAccessLevel: "The RDS snapshot is not associated with an RDS instance"
                },
                description: "{{snapshot}} is not configured with KMS encryption"
            }));

    const resolutionSteps = useAwsRdsSnapshotEncryptionDisabledRiskResolutionSteps(snapshotModel);

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                snapshot:
                    <Entity
                        entityIdOrModel={snapshotModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => resolutionSteps,
        riskModel,
        () => {
            const snapshotContextItems = getAwsRdsSnapshotRiskContext(snapshotModel);
            return [
                snapshotContextItems.generalInformation,
                snapshotContextItems.type,
                snapshotContextItems.storageSize,
                snapshotContextItems.sensitive,
                snapshotContextItems.accessLevel,
                snapshotModel.accessLevel == Contract.AwsResourceAccessLevel.Internal
                    ? new RiskDefinitionContextItem(localization.contextItems.internalAccessLevel())
                    : undefined,
                snapshotContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}