import { ToggleButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { PassedIcon } from "../assets";

export type CheckButtonProps = {
    checked: boolean;
    disabled?: boolean;
    onCheckedChanged: (checked: boolean) => void;
    title: string;
};

export function CheckButton({ checked, disabled, onCheckedChanged, title }: CheckButtonProps) {
    const theme = useTheme();
    return (
        <ToggleButton
            className={
                checked
                    ? "Mui-selected"
                    : undefined}
            disabled={disabled}
            value={checked}
            onClick={() => onCheckedChanged(!checked)}>
            {checked &&
                <PassedIcon
                    sx={{
                        fontSize: "14px",
                        marginRight: theme.spacing(0.75)
                    }}/>}
            <Typography noWrap={true}>
                {title}
            </Typography>
        </ToggleButton>);
}