﻿import { Action0, ActionMenuItem, DeleteIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../common";
import { ExclusionTag } from "./ExclusionTagsTable";

export type ActionsCellProps = {
    deleteExclusionTag: Action0;
    deleteExclusionTagError: boolean;
    deleteExclusionTagExecuting: boolean;
    exclusionTag: ExclusionTag;
};

export function ActionsCell({ deleteExclusionTag, deleteExclusionTagError, deleteExclusionTagExecuting, exclusionTag }: ActionsCellProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.exclusionTags.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete {{name}}?",
                        title: "Delete"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteExclusionTagExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteExclusionTagError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            <Menu
                itemsOrGetItems={[
                    new ActionMenuItem(
                        deleteExclusionTag,
                        localization.actions.delete.title(),
                        {
                            confirmOptions: {
                                message: localization.actions.delete.prompt({ name: `${exclusionTag.key}:${exclusionTag.value}` })
                            },
                            disabled: deleteExclusionTagExecuting,
                            icon: <DeleteIcon/>
                        })
                ]}/>
        </Stack>);
}