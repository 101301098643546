import { CopyToClipboardTextField, PasswordTextField, SuccessIcon, useLocalization } from "@infrastructure";
import { Box, FormControl, Stack, Typography } from "@mui/material";
import React from "react";
import { useAddOrEditContext } from "..";
import { useTheme } from "../../../../../../../../../../../../../../../common";
import { useCodePipelineContext } from "../../../CodePipeline";

export function ApiKeyItem() {
    const { apiKeyToken } = useAddOrEditContext();
    const { addOrEditOpen } = useCodePipelineContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.apiKeyItem",
            () => ({
                description: "Copy and store the generated token in a secure location.\nYou will need it for the next step in the wizard.",
                label: "Token",
                success: "Token created successfully!"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={4}>
            <Typography
                sx={{
                    maxWidth: theme.spacing(55),
                    whiteSpace: "pre-wrap"
                }}>
                {localization.description()}
            </Typography>
            {addOrEditOpen === true &&
                <Stack
                    direction="row"
                    spacing={1}>
                    <SuccessIcon
                        sx={{
                            color: theme.palette.success.main,
                            fontSize: "24px"
                        }}/>
                    <Typography
                        sx={{ color: theme.palette.success.main }}
                        variant="h4">
                        {localization.success()}
                    </Typography>
                </Stack>}
            <Stack spacing={1}>
                <Box sx={{ maxWidth: theme.spacing(60) }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        {addOrEditOpen === true
                            ? <CopyToClipboardTextField value={apiKeyToken!}/>
                            : <PasswordTextField
                                fullWidth={true}
                                label={localization.label()}
                                password={apiKeyToken}
                                slotProps={{ input:{ readOnly: true } }}
                                variant="outlined"/>}
                    </FormControl>
                </Box>
            </Stack>
        </Stack>);
}