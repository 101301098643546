﻿import { Dialog, Link, useExecuteOperation, useLocalization } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, entityModelStore, useTheme, WorkloadController } from "../../../../../common";

export type GenerateVirtualMachinePriorityScanRequestDialogProps =
    {
        onClose: () => void;
        virtualMachineModel: Contract.IVirtualMachineModel;
    };

export function GenerateVirtualMachinePriorityScanRequestDialog({ onClose, virtualMachineModel }: GenerateVirtualMachinePriorityScanRequestDialogProps) {
    return (
        <Dialog
            variant="editor"
            onClose={onClose}>
            <Core
                virtualMachineModel={virtualMachineModel}
                onClose={onClose}/>
        </Dialog>);
}

function Core({ onClose, virtualMachineModel }: GenerateVirtualMachinePriorityScanRequestDialogProps) {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.generateVirtualMachinePriorityScanRequestDialog",
            () => ({
                dialog: {
                    description: {
                        documentation: "Learn more.",
                        text: "This workload has been moved to the top of the scanning queue, and results will be ready soon. {{documentationLink}}"
                    },
                    failure: "On demand scan request failed",
                    success: "On-demand scan requested"
                },
                ok: "OK"
            }));

    useExecuteOperation(
        [GenerateVirtualMachinePriorityScanRequestDialog, virtualMachineModel.id],
        async () => {
            await WorkloadController.updateVirtualMachinePriorityScanRequest(new Contract.WorkloadControllerUpdateVirtualMachinePriorityScanRequestRequest(virtualMachineModel.id));
            await entityModelStore.notify(virtualMachineModel.id);
        });

    const theme = useTheme();
    return (
        <Stack
            spacing={3.5}
            sx={{
                height: "100%",
                padding: theme.spacing(8.25, 7.5)
            }}>
            <Typography
                sx={{ textAlign: "center" }}
                variant="h1">
                {localization.dialog.success()}
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
                {localization.dialog.description.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                            variant="external">
                            {localization.dialog.description.documentation()}
                        </Link>
                })}
            </Typography>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center">
                <Button
                    size="large"
                    variant="contained"
                    onClick={onClose}>
                    {localization.ok()}
                </Button>
            </Stack>
        </Stack>);
}