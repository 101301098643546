import { Optional, TimeHelper, TimeRangeFilterRange, TimeRangeFilterSelection, TimeRangeFilterSelectionRelative, TimeRangeFilterSelectionRelativeUnit, TimeRangeFilterType, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import moment, { DurationInputArg2 } from "moment";
import { Contract } from "..";


export class TimeRangeHelper {
    private static _timeRangeFilterRelativeUnitToSelectionRelativeUnitMap: { [key in TimeRangeFilterSelectionRelativeUnit]: Contract.TimeRangeSelectionRelativeUnit };
    private static _timeRangeFilterTypeToSelectionTypeMap: { [key in TimeRangeFilterType]: Contract.TimeRangeSelectionType };
    private static timeRangeSelectionRelativeUnitToFilterRelativeUnitMap: { [key in Contract.TimeRangeSelectionRelativeUnit]: TimeRangeFilterSelectionRelativeUnit } = {
        [Contract.TimeRangeSelectionRelativeUnit.Days]: TimeRangeFilterSelectionRelativeUnit.Days,
        [Contract.TimeRangeSelectionRelativeUnit.Months]: TimeRangeFilterSelectionRelativeUnit.Months,
        [Contract.TimeRangeSelectionRelativeUnit.Weeks]: TimeRangeFilterSelectionRelativeUnit.Weeks
    };
    private static timeRangeSelectionTypeToFilterTypeMap: { [key in Contract.TimeRangeSelectionType]: TimeRangeFilterType } = {
        [Contract.TimeRangeSelectionType.DateAfter]: TimeRangeFilterType.DateAfter,
        [Contract.TimeRangeSelectionType.DateBefore]: TimeRangeFilterType.DateBefore,
        [Contract.TimeRangeSelectionType.DateBetween]: TimeRangeFilterType.DateBetween,
        [Contract.TimeRangeSelectionType.Empty]: TimeRangeFilterType.Empty,
        [Contract.TimeRangeSelectionType.RelativeAfterTheNext]: TimeRangeFilterType.RelativeAfterTheNext,
        [Contract.TimeRangeSelectionType.RelativeBeforeTheLast]: TimeRangeFilterType.RelativeBeforeTheLast,
        [Contract.TimeRangeSelectionType.RelativeInTheLast]: TimeRangeFilterType.RelativeInTheLast,
        [Contract.TimeRangeSelectionType.RelativeInTheNext]: TimeRangeFilterType.RelativeInTheNext
    };

    private static get timeRangeFilterRelativeUnitToSelectionRelativeUnitMap() {
        if (_.isNil(TimeRangeHelper._timeRangeFilterRelativeUnitToSelectionRelativeUnitMap)) {
            TimeRangeHelper._timeRangeFilterRelativeUnitToSelectionRelativeUnitMap = _.toValueToKeyMap(TimeRangeHelper.timeRangeSelectionRelativeUnitToFilterRelativeUnitMap);
        }

        return TimeRangeHelper._timeRangeFilterRelativeUnitToSelectionRelativeUnitMap;
    }

    private static get timeRangeFilterTypeToSelectionTypeMap() {
        if (_.isNil(TimeRangeHelper._timeRangeFilterTypeToSelectionTypeMap)) {
            TimeRangeHelper._timeRangeFilterTypeToSelectionTypeMap = _.toValueToKeyMap(TimeRangeHelper.timeRangeSelectionTypeToFilterTypeMap);
        }

        return TimeRangeHelper._timeRangeFilterTypeToSelectionTypeMap;
    }

    public static getTimeRange(referenceTime?: string, timeRangeFilterSelection?: TimeRangeFilterSelection) {
        if (_.isNil(referenceTime) ||
            _.isNil(timeRangeFilterSelection)) {
            return undefined;
        }

        switch (timeRangeFilterSelection.type) {
            case TimeRangeFilterType.DateAfter:
                return new Contract.TimeRange(
                    TimeHelper.DateTimeMaxValue,
                    timeRangeFilterSelection.dateStartTime!);
            case TimeRangeFilterType.DateBefore:
                return new Contract.TimeRange(
                    timeRangeFilterSelection.dateEndTime!,
                    TimeHelper.DateTimeMinValue);
            case TimeRangeFilterType.DateBetween:
                return new Contract.TimeRange(
                    timeRangeFilterSelection.dateEndTime!,
                    timeRangeFilterSelection.dateStartTime!);
            case TimeRangeFilterType.RelativeAfterTheNext:
                return new Contract.TimeRange(
                    TimeHelper.DateTimeMaxValue,
                    moment(referenceTime).
                        add(
                            timeRangeFilterSelection.relative!.value,
                            timeRangeFilterSelection.relative!.unit.toString() as DurationInputArg2).
                        toISOString());
            case TimeRangeFilterType.RelativeBeforeTheLast:
                return new Contract.TimeRange(
                    moment(referenceTime).
                        subtract(
                            timeRangeFilterSelection.relative!.value,
                            timeRangeFilterSelection.relative!.unit.toString() as DurationInputArg2).
                        toISOString(),
                    TimeHelper.DateTimeMinValue);
            case TimeRangeFilterType.RelativeInTheLast:
                return new Contract.TimeRange(
                    referenceTime,
                    moment(referenceTime).
                        subtract(
                            timeRangeFilterSelection.relative!.value,
                            timeRangeFilterSelection.relative!.unit.toString() as DurationInputArg2).
                        toISOString());
            case TimeRangeFilterType.RelativeInTheNext:
                return new Contract.TimeRange(
                    moment(referenceTime).
                        add(
                            timeRangeFilterSelection.relative!.value,
                            timeRangeFilterSelection.relative!.unit.toString() as DurationInputArg2).
                        toISOString(),
                    referenceTime);
            default:
                throw new UnexpectedError("timeRangeFilterSelection.type", timeRangeFilterSelection.type);
        }
    }

    public static getTimeRangeFilterRange(timeRange?: Contract.TimeRange) {
        return _.isNil(timeRange)
            ? undefined
            : new TimeRangeFilterRange(timeRange.endTime, timeRange.startTime);
    }

    public static getTimesFilterRange(times: Optional<moment.MomentInput>[]) {
        const momentTimes =
            _(times).
                filter().
                map(time => moment(time)).
                value();
        return _.isEmpty(times)
            ? undefined
            : new TimeRangeFilterRange(
                moment.max(momentTimes).
                    toISOString(),
                moment.min(momentTimes).
                    toISOString());
    }

    public static inTimeRange(time: moment.MomentInput, timeRange: TimeRangeFilterRange) {
        return _.isNil(time)
            ? false
            : moment(time).
                isBetween(
                    timeRange.startTime,
                    timeRange.endTime,
                    undefined,
                    "[]");
    }

    public static toTimeRangeFilterSelectionFromTimeRangeSelection(timeRangeSelection?: Contract.TimeRangeSelection): Optional<TimeRangeFilterSelection> {
        return _.isNil(timeRangeSelection)
            ? undefined
            : new TimeRangeFilterSelection(
                timeRangeSelection.endTime,
                timeRangeSelection.startTime,
                !_.isNil(timeRangeSelection.relative)
                    ? new TimeRangeFilterSelectionRelative(
                        TimeRangeHelper.timeRangeSelectionRelativeUnitToFilterRelativeUnitMap[timeRangeSelection.relative.unit],
                        timeRangeSelection.relative.value)
                    : undefined,
                TimeRangeHelper.timeRangeSelectionTypeToFilterTypeMap[timeRangeSelection.type]);
    }

    public static toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference?: string, timeRangeFilterSelection?: TimeRangeFilterSelection): Optional<Contract.TimeRangeSelection> {
        return _.isNil(timeRangeFilterSelection)
            ? undefined
            : new Contract.TimeRangeSelection(
                timeRangeFilterSelection.dateEndTime,
                !_.isNil(timeRangeFilterSelection.relative)
                    ? new Contract.TimeRangeSelectionRelative(
                        timeReference,
                        TimeRangeHelper.timeRangeFilterRelativeUnitToSelectionRelativeUnitMap[timeRangeFilterSelection.relative.unit],
                        timeRangeFilterSelection.relative.value)
                    : undefined,
                timeRangeFilterSelection.dateStartTime,
                TimeRangeHelper.timeRangeFilterTypeToSelectionTypeMap[timeRangeFilterSelection.type]);
    }

    public static getTimeFrameValue(timeFrame: Contract.TimeFrame) {
        switch (timeFrame) {
            case Contract.TimeFrame.Long:
                return 90;
            case Contract.TimeFrame.Medium:
                return 30;
            case Contract.TimeFrame.Short:
                return 7;
        }
    }
}