import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { ResourcesExternalConsoleLink } from "../../../../components";
import { useGetGcpKmsKeyRingRiskContext, useGetGcpScopeResourcePublicAccessExistsRiskContext } from "../../../contexts";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpKmsKeyRingPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpKmsKeyRingPublicAccessExistsRisk;
    const keyRingModel = entityModelStore.useGet(risk.entityId) as Contract.GcpKmsKeyRingModel;

    const getGcpKmsKeyRingRiskContext = useGetGcpKmsKeyRingRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpKmsKeyRingPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{keyRing}} is publicly accessible via IAM roles",
                sections: {
                    resolution: {
                        step1: "Before changing the role assignments, verify that applications can access the key ring and the keys it contains without public access",
                        step2: "Check the key ring and under **Permissions** delete the role bindings to allUsers / allAuthenticatedUsers"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            keyRing:
                <Entity
                    entityIdOrModel={keyRingModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <ResourcesExternalConsoleLink
                key={risk.tenantId}
                tenantId={risk.tenantId}
                typeName={keyRingModel.entity.typeName}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const keyRingRiskContext = getGcpKmsKeyRingRiskContext(keyRingModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(risk);
            return [
                keyRingRiskContext.generalInformation,
                keyRingRiskContext.sensitive,
                keyRingRiskContext.keys,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                keyRingRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ScopeResourceRoleBindingTableSection
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}