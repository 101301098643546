import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { forwardRef, memo, Ref } from "react";

const NotValidIconForwardRef = memo(forwardRef(NotValidIcon));
export { NotValidIconForwardRef as NotValidIcon };

function NotValidIcon(props: SvgIconProps, ref: Ref<SVGSVGElement>) {
    const theme = useTheme();
    return (
        <SvgIcon
            ref={ref}
            {...props}>
            <g clipPath="url(#clip0_53591_247807)">
                <path d="M0.500408 11.9233C0.551517 5.63669 5.68801 0.5 12 0.5C18.4142 0.551111 23.5507 5.73887 23.4996 12.0767C23.4485 18.3633 18.3121 23.5 12 23.5H11.9233C8.85679 23.4744 5.96911 22.2733 3.82251 20.0756C1.65036 17.9033 0.474853 14.99 0.500408 11.9233Z"/>
                <path
                    clipRule="evenodd"
                    d="M12.0001 5.5C13.1046 5.5 14 6.3954 14 7.49991C14 7.58446 13.9946 7.66895 13.9839 7.75283L13.0309 13.2292C13.0309 13.8052 12.5761 14.2601 12.0001 14.2601C11.4241 14.2601 10.9694 13.8052 10.9694 13.2292L10.0163 7.75283C9.8766 6.65718 10.6516 5.65577 11.7472 5.5161C11.8311 5.50541 11.9156 5.5 12.0001 5.5ZM13.3947 17.7162C13.3947 18.4741 12.758 19.1108 12.0001 19.1108C11.2422 19.1108 10.6056 18.4741 10.6056 17.7162V17.6556C10.6056 16.8976 11.2422 16.261 12.0001 16.261C12.758 16.261 13.3947 16.8976 13.3947 17.6556V17.7162Z"
                    fill={theme.palette.background.alternate}
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_53591_247807">
                    <rect
                        fill={theme.palette.background.alternate}
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}