import { Action0, Dropdown, Link, Loading, NumberFormatter, Shadows, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, DashboardController, Entities as CommonEntities, EntityIcon, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../..";

export type TileProps = {
    entityPublicData: Contract.ScopeSummaryCloudProviderEntityPublicData;
    entityTypeName: string;
};

export function Tile({ entityPublicData, entityTypeName }: TileProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetEntityPublicDataDefinition.tile",
            () => ({
                newTitle: "{{newPublicAccessResources}} new in the last 30 days",
                title: "{{publicAccessResources}} {{translatedEntityTypeName}}"
            }));
    const theme = useTheme();
    return (
        <Link
            urlOrGetUrl={
                CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                    Contract.RisksView.Open,
                    { policyConfigurationTypeNameOrIds: entityPublicData.riskPolicyConfigurationTypeNames })}
            variant="text">
            <Stack
                alignItems="center"
                sx={{
                    "&:hover": {
                        boxShadow: theme.shadows[Shadows.Highlight]
                    },
                    background: theme.palette.background.alternate,
                    border: theme.border.alternate,
                    borderRadius: theme.spacing(0.75),
                    height: "100%",
                    margin: "auto",
                    paddingBottom: theme.spacing(2)
                }}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.severity(entityPublicData.severity),
                        borderTopLeftRadius: theme.spacing(1),
                        borderTopRightRadius: theme.spacing(1),
                        height: theme.spacing(0.375),
                        width: "100%"
                    }}/>
                <EntityIcon
                    badge="publicAccess"
                    entityTypeName={entityTypeName}
                    sx={{
                        fontSize: "36px",
                        marginTop: theme.spacing(4)
                    }}/>
                <Typography
                    align="center"
                    noWrap={true}
                    sx={{
                        fontSize: "13px",
                        fontWeight: 700,
                        padding: theme.spacing(1, 2, 0, 2),
                        width: "calc(100% - 32px)"
                    }}>
                    {localization.title({
                        publicAccessResources:
                            <Entities
                                entityTypeName={entityTypeName}
                                idCount={entityPublicData.riskedEntityCount}
                                recent={false}
                                riskPolicyConfigurationTypeNames={entityPublicData.riskPolicyConfigurationTypeNames}/>,
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: entityPublicData.riskedEntityCount,
                                    includeServiceName: true
                                })
                    })}
                </Typography>
                <Box
                    sx={{
                        flex: 1,
                        padding: theme.spacing(1, 3),
                        width: "100%"
                    }}>
                    {entityPublicData.recentRiskedEntityCount > 0 &&
                        <Typography
                            align="center"
                            noWrap={true}
                            sx={{ color: theme.palette.text.primary }}>
                            {localization.newTitle({
                                newPublicAccessResources:
                                    <Entities
                                        entityTypeName={entityTypeName}
                                        idCount={entityPublicData.recentRiskedEntityCount}
                                        recent={true}
                                        riskPolicyConfigurationTypeNames={entityPublicData.riskPolicyConfigurationTypeNames}/>
                            })}
                        </Typography>}
                </Box>
            </Stack>
        </Link>);
}

type EntitiesProps = {
    entityTypeName: string;
    idCount: number;
    recent: boolean;
    riskPolicyConfigurationTypeNames: string[];
};

export function Entities({ entityTypeName, idCount, recent, riskPolicyConfigurationTypeNames }: EntitiesProps) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    return (
        <Dropdown
            inline={true}
            open={open}
            popoverElement={
                <Loading container="popup">
                    <Box
                        sx={{
                            maxWidth: theme.spacing(60),
                            minWidth: theme.spacing(30),
                            padding: theme.spacing(1)
                        }}>
                        <EntitiesPopover
                            entityTypeName={entityTypeName}
                            recent={recent}
                            riskPolicyConfigurationTypeNames={riskPolicyConfigurationTypeNames}
                            onEntityClick={() => setOpen(false)}/>
                    </Box>
                </Loading>}
            popoverElementContainerSx={{ padding: 0 }}
            sx={{
                color: theme.palette.text.primary,
                fontSize: "unset",
                fontWeight: 700,
                textDecoration: "underline"
            }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            {NumberFormatter.unit(idCount)}
        </Dropdown>);
}

type EntitiesPopoverProps = {
    entityTypeName: string;
    onEntityClick: Action0;
    recent: boolean;
    riskPolicyConfigurationTypeNames: string[];
};

function EntitiesPopover({ entityTypeName, onEntityClick, recent, riskPolicyConfigurationTypeNames }: EntitiesPopoverProps) {
    const [{ ids }] =
        useExecuteOperation(
            [EntitiesPopover, `${entityTypeName}:${recent}`],
            () =>
                DashboardController.getPublicEntityIds(
                    new Contract.DashboardControllerGetPublicEntityIdsRequest(
                        entityTypeName,
                        recent,
                        riskPolicyConfigurationTypeNames)));
    return (
        <CommonEntities
            entityIdsOrModels={ids}
            entityTypeName={entityTypeName}
            entityVariant="iconTextTypeTenant"
            searchEnabled={true}
            variant="dropdown"
            onEntityClick={onEntityClick}/>);
}