import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureSqlServerAuthenticationTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureSqlServerAuthenticationTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureSqlServerAuthenticationType]: {
                    [Contract.AzureSqlServerAuthenticationType.Aad]: "Microsoft Entra ID",
                    [Contract.AzureSqlServerAuthenticationType.AadAndSql]: "SQL and Microsoft Entra ID",
                    [Contract.AzureSqlServerAuthenticationType.Sql]: "SQL"
                }
            }));

    return (status: Contract.AzureSqlServerAuthenticationType) =>
        localization[Contract.TypeNames.AzureSqlServerAuthenticationType][status]();
}