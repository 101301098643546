import { TimeSpanFormatter, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { SettingConfigurationHelper } from "../../../../../../../../../common";
import { ValueEditProps, ValueProps, ValueViewProps } from "../Value";

export function DayValue(props: ValueProps) {
    return (
        props.variant === "view"
            ? <View {...props}/>
            : <Edit {...props}/>);
}

function View({ settingConfiguration }: ValueViewProps) {
    const timeSpan = SettingConfigurationHelper.getValue(settingConfiguration) as string;

    return (
        <Typography noWrap={true}>
            {TimeSpanFormatter.humanizeDays(timeSpan)}
        </Typography>);
}

function Edit({ disabled, setValidation, valueRef }: ValueEditProps) {
    const [daysNumber, setDaysNumber] =
        useState(
            _.isNil(valueRef?.current)
                ? minDays
                : TimeSpanFormatter.durationAsDays(valueRef.current as string));

    const localization =
        useLocalization(
            "views.customer.configuration.settingConfigurationTable.value.dayValue",
            () => ({
                days: "Days",
                empty: "Cannot be empty",
                number: "Must be a number",
                range: "Must be between {{minDays}} and {{ maxDays }} days"
            }));

    const [daysNumberValidationController, daysNumberValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(daysNumber)) {
                    return localization.empty();
                }

                if (!_.isNumber(daysNumber)) {
                    return localization.number();
                }

                if (daysNumber < minDays || daysNumber > maxDays) {
                    return localization.range({
                        maxDays,
                        minDays
                    });
                }

                return undefined;
            },
            [daysNumber]);

    useChangeEffect(
        () => {
            valueRef.current = TimeSpanFormatter.duration(daysNumber).toISOString();
            setValidation(daysNumberValidationController.isValid());
        },
        [daysNumber],
        500);

    return (
        <FormControl
            fullWidth={true}
            variant="standard">
            <TextField
                disabled={disabled}
                label={localization.days()}
                slotProps={{
                    htmlInput: {
                        max: maxDays,
                        min: minDays
                    }
                }}
                type="number"
                value={daysNumber}
                variant="outlined"
                onChange={
                    event => {
                        const updatedDay = _.toNumber(event.target.value.match(/\d+/));
                        setDaysNumber(
                            updatedDay <= 0
                                ? minDays
                                : updatedDay >= maxDays
                                    ? maxDays
                                    : updatedDay);
                    }}/>
            {!_.isNil(daysNumberValidationMessage) &&
                <FormHelperText error={true}>
                    {daysNumberValidationMessage}
                </FormHelperText>}
        </FormControl>);
}

const maxDays = 365;
const minDays = 1;