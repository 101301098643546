import { IconText } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import React from "react";
import { useCodeTenantTypeTranslator } from "../../tenants";
import { Contract } from "../controllers";
import { TenantIcon } from "../icons";
import { tenantModelStore } from "../stores";
import { useTheme } from "../themes";
import { TenantHelper } from "../utilities";

type CodeTenantProps = {
    iconSx?: SxProps;
    tenantId: string;
    textSx?: SxProps;
};

export function CodeTenant({ iconSx, tenantId, textSx }: CodeTenantProps) {
    const codeTenantTypeTranslator = useCodeTenantTypeTranslator();
    const tenantConfiguration = tenantModelStore.useGet(tenantId).configuration as Contract.CodeTenantConfiguration;

    const theme = useTheme();
    return (
        <IconText
            icon={
                <TenantIcon
                    data={{
                        codeTenantType: tenantConfiguration.codeTenantType,
                        generalCodeTenant: TenantHelper.isGeneralCodeTenant(tenantConfiguration)
                    }}
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px",
                        ...iconSx
                    }}
                    tenantType={tenantConfiguration.type}/>}
            text={
                <Typography
                    noWrap={true}
                    sx={textSx}>
                    {tenantConfiguration.displayReference}
                </Typography>}
            titleOrGetTitle={codeTenantTypeTranslator(tenantConfiguration.codeTenantType)}/>);
}