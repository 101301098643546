import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAwsRdsClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsRdsClusterDeleteProtectionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsClusterDeleteProtectionDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsClusterModel;
    const cluster = clusterModel.entity as Contract.AwsRdsCluster;

    const getAwsRdsClusterRiskContext = useGetAwsRdsClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsClusterDeleteProtectionDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} delete protection is not enabled",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Additional configuration** section and select the **Enable deletion protection** checkbox",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsClusterUrl(cluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const clusterContextItems = getAwsRdsClusterRiskContext(clusterModel);
            return [
                clusterContextItems.generalInformation,
                clusterContextItems.storageSize,
                clusterContextItems.instances,
                clusterContextItems.sensitive,
                clusterContextItems.highestDataSensitivity,
                clusterContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}