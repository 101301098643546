import { ItemSelector, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../../common";
import { useAwsBedrockAgentStatusTranslator } from "../../../../../../../../hooks";
import { EntitiesInfoItem } from "../../../../../../components";

type RevisionsProps = {
    agentModel: Contract.AwsBedrockAgentModel;
};

export function Revisions({ agentModel }: RevisionsProps) {
    const agent = agentModel.entity as Contract.AwsBedrockAgent;

    const [selectedRevision, setSelectedRevision] =
        useState(
            () =>
                _(agent.revisionMap).
                    values().
                    maxBy(revision => Number(revision.id))!);

    const agentStatusTranslator = useAwsBedrockAgentStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsBedrockAgentDefinition.revisions",
            () => ({
                fields: {
                    creationTime: "Creation Time",
                    description: "Description",
                    kmsEncryptionKeyReferences: "KMS Encryption Key",
                    roleReference: "Role",
                    status: "Status"
                }
            }));

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(agent.revisionMap).
                        keys().
                        orderBy(
                            revisionId => Number(revisionId),
                            "desc").
                        value()}
                selectedItem={selectedRevision.id}
                sorted={false}
                onSelectedItemChanged={revisionId => setSelectedRevision(agent.revisionMap[revisionId])}/>
            <InfoCard>
                <InfoItem
                    title={localization.fields.creationTime()}
                    value={TimeFormatter.profileFormatDateTime(selectedRevision.creationTime)}/>
                <InfoItem
                    title={localization.fields.description()}
                    value={selectedRevision.description}/>
                <InfoItem
                    title={localization.fields.status()}
                    value={agentStatusTranslator(selectedRevision.status)}/>
                <EntitiesInfoItem
                    entityIdsOrModels={selectedRevision.roleReference?.idReference}
                    entityTypeName={Contract.TypeNames.AwsIamRole}
                    title={localization.fields.roleReference()}/>
                <EntitiesInfoItem
                    entityIdsOrModels={agentModel.revisionIdToKeyIdReferenceMap?.[selectedRevision.id]}
                    entityTypeName={Contract.TypeNames.AwsKmsKey}
                    title={localization.fields.kmsEncryptionKeyReferences()}/>
            </InfoCard>
        </InfoCard>);
}