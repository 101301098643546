import { DiffDialog, ItemSelector, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../../../../common";

type RoleDefinitionsDiffProps = {
    existingRoleDefinitionIds: string[];
    newRoleDefinitionIds: string[];
    onClose: () => void;
};

export function RoleDefinitionsDiffDialog({ existingRoleDefinitionIds, newRoleDefinitionIds, onClose }: RoleDefinitionsDiffProps) {
    const [selectedExistingRoleDefinitionId, setSelectedExistingRoleDefinitionId] = useState(existingRoleDefinitionIds[0]);
    const [selectedNewRoleDefinitionId, setSelectedNewRoleDefinitionId] = useState(newRoleDefinitionIds[0]);
    const roleDefinitionModels = entityModelStore.useGet(_.concat(existingRoleDefinitionIds, newRoleDefinitionIds));
    const roleDefinitionMap =
        useMemo(
            () =>
                _(roleDefinitionModels).
                    map(roleDefinitionModel => roleDefinitionModel.entity as Contract.AzureAuthorizationRoleDefinition).
                    keyBy(roleDefinition => roleDefinition.id).
                    value(),
            [roleDefinitionModels]);

    const [selectedNewRoleDefinitionFileName, selectedNewRoleDefinitionFileUrl] =
        useMemo(
            () => {
                const newRoleDefinitionFileName = `${roleDefinitionMap[selectedNewRoleDefinitionId].displayName}.json`;
                return [
                    newRoleDefinitionFileName,
                    window.URL.createObjectURL(
                        new Blob(
                            [roleDefinitionMap[selectedNewRoleDefinitionId].json],
                            { type: "application/json" }))
                ];
            },
            [selectedNewRoleDefinitionId]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureExcessivePermissionPrincipalRiskDefinition.replaceRoleAssignmentResourcesChangeDiff.roleDefinitionsDiffDialog",
            () => ({
                title: "Role difference"
            }));
    return (
        <DiffDialog
            downloadOptions={{
                data: selectedNewRoleDefinitionFileUrl,
                fileName: selectedNewRoleDefinitionFileName
            }}
            format="json"
            modified={roleDefinitionMap[selectedNewRoleDefinitionId].json}
            modifiedTitle={
                newRoleDefinitionIds.length === 1
                    ? <Typography
                        noWrap={true}
                        variant="h4">
                        {roleDefinitionMap[newRoleDefinitionIds[0]].displayName}
                    </Typography>
                    : <ItemSelector
                        dense={true}
                        fieldSx={{ border: "none" }}
                        fullWidth={true}
                        items={newRoleDefinitionIds}
                        selectedItem={selectedNewRoleDefinitionId}
                        sorted={false}
                        onSelectedItemChanged={setSelectedNewRoleDefinitionId}>
                        {newRoleId =>
                            <Typography
                                noWrap={true}
                                sx={{ fontWeight: "unset" }}
                                variant="h4">
                                {roleDefinitionMap[newRoleId].displayName}
                            </Typography>}
                    </ItemSelector>}
            original={roleDefinitionMap[selectedExistingRoleDefinitionId].json}
            originalTitle={
                existingRoleDefinitionIds.length === 1
                    ? <Typography
                        noWrap={true}
                        variant="h4">
                        {roleDefinitionMap[existingRoleDefinitionIds[0]].displayName}
                    </Typography>
                    : <ItemSelector
                        dense={true}
                        fieldSx={{ border: "none" }}
                        fullWidth={true}
                        items={existingRoleDefinitionIds}
                        selectedItem={selectedExistingRoleDefinitionId}
                        sorted={false}
                        onSelectedItemChanged={setSelectedExistingRoleDefinitionId}>
                        {existingRoleDefinitionId =>
                            <Typography
                                noWrap={true}
                                variant="h4">
                                {roleDefinitionMap[existingRoleDefinitionId].displayName}
                            </Typography>}
                    </ItemSelector>}
            title={localization.title()}
            onClose={onClose}/>);
}