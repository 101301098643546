import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAwsRdsDatabaseInstanceEngineTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsRdsDatabaseInstanceEngineTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsRdsDatabaseInstanceEngineType]: {
                    [Contract.AwsRdsDatabaseInstanceEngineType.Aurora]: "Aurora",
                    [Contract.AwsRdsDatabaseInstanceEngineType.AuroraMySql]: "Aurora MySQL",
                    [Contract.AwsRdsDatabaseInstanceEngineType.AuroraPostgreSql]: "Aurora PostgreSQL",
                    [Contract.AwsRdsDatabaseInstanceEngineType.CustomOracleEe]: "Custom Oracle Enterprise Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.CustomSqlServerDev]: "Custom SQL Server Developer Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.CustomSqlServerEe]: "Custom SQL Server Enterprise Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.CustomSqlServerSe]: "Custom SQL Server Standard Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.CustomSqlServerWeb]: "Custom SQL Server Web Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.IbmDb2Ae]: "IBM Db2 Advanced Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.IbmDb2Se]: "IBM Db2 Standard Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.MariaDb]: "MariaDB",
                    [Contract.AwsRdsDatabaseInstanceEngineType.MySql]: "MySQL",
                    [Contract.AwsRdsDatabaseInstanceEngineType.OracleEe]: "Oracle Enterprise Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.OracleEeCdb]: "Oracle Enterprise Edition Container Database",
                    [Contract.AwsRdsDatabaseInstanceEngineType.OracleSe]: "Oracle Standard Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.OracleSe1]: "Oracle Standard Edition One",
                    [Contract.AwsRdsDatabaseInstanceEngineType.OracleSe2]: "Oracle Standard Edition Two",
                    [Contract.AwsRdsDatabaseInstanceEngineType.OracleSe2Cdb]: "Oracle Standard Edition Two Container Database",
                    [Contract.AwsRdsDatabaseInstanceEngineType.Postgres]: "PostgreSQL",
                    [Contract.AwsRdsDatabaseInstanceEngineType.SqlServerEe]: "SQL Server Enterprise Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.SqlServerEx]: "SQL Server Express Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.SqlServerSe]: "SQL Server Standard Edition",
                    [Contract.AwsRdsDatabaseInstanceEngineType.SqlServerWeb]: "SQL Server Web Edition"
                }
            }));
    return (engineType: Contract.AwsRdsDatabaseInstanceEngineType) =>
        localization[Contract.TypeNames.AwsRdsDatabaseInstanceEngineType][engineType]();
}