﻿import { useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useAddOrEditContext } from "..";
import { ConsoleApiUrlHelper, Contract, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function AuthenticateGitHubUserItem() {
    const { callbackError, defaultServerConfiguration, oAuthAccessToken, organizationModel, updatedServerId } = useAddOrEditContext();
    const isUserAuthenticated = !!oAuthAccessToken || !!organizationModel;

    const { setError, setValid } = useOrderedWizardContext();

    const gitHubServerModels = scopeSystemEntityModelStore.useGetCodeServer(Contract.CodeTenantType.GitHub);
    const [serverApplicationClientId, serverEndpoint] =
        useMemo(
            () => {
                if (_.isNil(updatedServerId)) {
                    return [defaultServerConfiguration.applicationClientId, defaultServerConfiguration.endpoint!];
                }

                const serverModel =
                    _.find(
                        gitHubServerModels,
                        serverModel => serverModel.configuration.id == updatedServerId)!;

                return [(serverModel.configuration as Contract.GitHubServerConfiguration).applicationClientId, serverModel.state!.endpoint];
            },
            [defaultServerConfiguration, updatedServerId]);

    useEffect(
        () => {
            setValid(isUserAuthenticated);
            setError(
                callbackError
                    ? localization[Contract.TypeNames.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError].translate(callbackError!)
                    : undefined);
        },
        [callbackError, isUserAuthenticated]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitHub.addOrEditGitHub.authenticateGitHubUserItem",
            () => ({
                [Contract.TypeNames.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError]: {
                    [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError.AuthenticationError]: "GitHub user authentication failed",
                    [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError.AuthenticationRedirectError]: "App authorization was unsuccessful or resulted in unexpected redirect"
                },
                withGitHubDetails: "No GitHub authentication needed",
                withoutGitHubDetails: {
                    button: "Authenticate GitHub User",
                    details: "Authenticate with a GitHub user that has access to app installations on the organization you would like to onboard"
                }
            }));

    const theme = useTheme();
    return isUserAuthenticated
        ? <Typography
            sx={{ color: theme.palette.success.main }}
            variant="h4">
            {localization.withGitHubDetails()}
        </Typography>
        : <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(50) }}>
            <Typography>
                {localization.withoutGitHubDetails.details()}
            </Typography>
            <Button
                component="a"
                href={
                    ConsoleApiUrlHelper.getGitHubOAuthAuthorizationUrl(
                        serverApplicationClientId,
                        serverEndpoint!,
                        updatedServerId)}
                sx={{ width: theme.spacing(25) }}
                target="_self"
                variant="outlined">
                {localization.withoutGitHubDetails.button()}
            </Button>
        </Stack>;
}