import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../..";
import { GcpRoleBindingTableColumnId } from "../../../../../../tenants";
import { EntityProfileInfoLayout } from "../../../../../../views/Customer/components/Entities/components/Profile/components";
import { GciDirectoryPrincipalGcpRoleBindings } from "../../../../../../views/Customer/components/Entities/components/Profile/hooks/useDefinition/components";

type InfoProps = {
    principalModel: Contract.EntityModel;
    scopeResourceModel: Contract.EntityModel;
};

export function GcpDeleteScopeResourcePrincipalRoleBindingsChangeInfo({ principalModel, scopeResourceModel }: InfoProps) {
    const principalRoleBindingIds = (scopeResourceModel as Contract.GcpScopeResourceModel).roleBindingIds;

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.changeInfo.gcpDeleteScopeResourcePrincipalRoleBindingsChangeInfo",
            () => ({
                title: "Identity GCP Role Bindings"
            }));

    const theme = useTheme();
    return (
        <EntityProfileInfoLayout entityId={principalModel.id}>
            <Stack
                spacing={2}
                sx={{
                    height: "100%",
                    padding: theme.spacing(2)
                }}>
                {principalModel.entity.typeName === Contract.TypeNames.GcpIamServiceAccount &&
                    <Typography variant="h3">
                        {localization.title()}
                    </Typography>}
                <GciDirectoryPrincipalGcpRoleBindings
                    csvExportFilePrefix={
                        principalModel.entity.typeName === Contract.TypeNames.GcpIamServiceAccount
                            ? localization.title()
                            : ""}
                    gcpRoleBindingIds={principalRoleBindingIds}
                    initialFilterMap={{
                        [GcpRoleBindingTableColumnId.PrincipalReference]: [principalModel.id],
                        [GcpRoleBindingTableColumnId.ScopeResourceReference]: [scopeResourceModel.id]
                    }}
                    principalId={principalModel.id}/>
            </Stack>
        </EntityProfileInfoLayout>);
}