﻿import { useGetGcpEntityRiskContext, useResourceTagNotExistsRiskDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../../../common";

export function useGcpResourceTagNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const getGcpEntityRiskContext = useGetGcpEntityRiskContext();

    return useResourceTagNotExistsRiskDefinition({
        getContextItems:
            (resourceModel: Contract.EntityModel) => {
                const entityRiskContext = getGcpEntityRiskContext(resourceModel as Contract.GcpEntityModel);

                return [
                    entityRiskContext.generalInformation,
                    entityRiskContext.sensitive,
                    entityRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)];
            },
        riskModel
    });
}