import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../tenants";
import { ContextSection } from "./components";

export function useAwsIamUserUnrotatedAccessKeyRiskDefinition(riskModel: Contract.RiskModel) {
    const userUnrotatedAccessKeyRiskModel = riskModel as Contract.AwsIamUserUnrotatedAccessKeyRiskModel;
    const userModel = entityModelStore.useGet(userUnrotatedAccessKeyRiskModel.risk.entityId) as Contract.AwsIamUserModel;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserUnrotatedAccessKeyRiskDefinition",
            () => ({
                description: [
                    "Access key of the {{user}} was not rotated for more than {{accessKeyRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                    "{{count | NumberFormatter.humanize}} access keys of the {{user}} were not rotated for more than {{accessKeyRotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                ],
                sections: {
                    accessKeys: "Access Keys",
                    resolution: {
                        createAccessKey: {
                            step1: "Click **Create access key**",
                            step2: "Update programmatic calls with newly created access key credentials"
                        },
                        deleteAccessKey: "Delete access key **{{accessKeyUniqueId}}**",
                        disableAccessKey: "Disable/Delete access key **{{accessKeyUniqueId}}**",
                        useAlreadyRotatedAccessKey: "Update programmatic calls to use only rotated access key **{{accessKeyUniqueId}}**",
                        useOtherUnrotatedAccessKey: "Temporary update programmatic calls to use only access key **{{firstAccessKeyUniqueId}}** instead of **{{secondAccessKeyUniqueId}}**"
                    }
                }
            }));

    function getResolutionSectionSteps() {
        const user = userModel.entity as Contract.AwsIamUser;
        const signInStep =
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamUserSecurityCredentialsUrl(user));
        if (userUnrotatedAccessKeyRiskModel.risk.userAccessKeys.length === 1) {
            return [
                signInStep,
                localization.sections.resolution.createAccessKey.step1(),
                localization.sections.resolution.createAccessKey.step2(),
                localization.sections.resolution.disableAccessKey({ accessKeyUniqueId: (userUnrotatedAccessKeyRiskModel.risk.userAccessKeys)[0].uniqueId })
            ];
        } else if (userUnrotatedAccessKeyRiskModel.risk.userUnrotatedAccessKeys.length === 1) {
            const [userAccessKey, userUnrotatedAccessKey] =
                (userUnrotatedAccessKeyRiskModel.risk.userAccessKeys)[0].uniqueId === (userUnrotatedAccessKeyRiskModel.risk.userUnrotatedAccessKeys)[0].uniqueId
                    ? [(userUnrotatedAccessKeyRiskModel.risk.userAccessKeys)[1], (userUnrotatedAccessKeyRiskModel.risk.userAccessKeys)[0]]
                    : [(userUnrotatedAccessKeyRiskModel.risk.userAccessKeys)[0], (userUnrotatedAccessKeyRiskModel.risk.userAccessKeys)[1]];


            return userUnrotatedAccessKeyRiskModel.risk.invalidAccessKeyExists
                ? [
                    signInStep,
                    localization.sections.resolution.deleteAccessKey({ accessKeyUniqueId: userAccessKey.uniqueId }),
                    localization.sections.resolution.createAccessKey.step1(),
                    localization.sections.resolution.createAccessKey.step2(),
                    localization.sections.resolution.disableAccessKey({ accessKeyUniqueId: userUnrotatedAccessKey.uniqueId })
                ]
                : [
                    signInStep,
                    localization.sections.resolution.useAlreadyRotatedAccessKey({ accessKeyUniqueId: userAccessKey.uniqueId }),
                    localization.sections.resolution.disableAccessKey({ accessKeyUniqueId: userUnrotatedAccessKey.uniqueId })
                ];

        } else {
            return [
                signInStep,
                localization.sections.resolution.useOtherUnrotatedAccessKey({
                    firstAccessKeyUniqueId: (userUnrotatedAccessKeyRiskModel.risk.userUnrotatedAccessKeys)[0].uniqueId,
                    secondAccessKeyUniqueId: (userUnrotatedAccessKeyRiskModel.risk.userUnrotatedAccessKeys)[1].uniqueId
                }),
                localization.sections.resolution.disableAccessKey({ accessKeyUniqueId: (userUnrotatedAccessKeyRiskModel.risk.userUnrotatedAccessKeys)[1].uniqueId }),
                localization.sections.resolution.createAccessKey.step1(),
                localization.sections.resolution.createAccessKey.step2(),
                localization.sections.resolution.disableAccessKey({ accessKeyUniqueId: (userUnrotatedAccessKeyRiskModel.risk.userUnrotatedAccessKeys)[0].uniqueId })
            ];
        }
    }

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            userUnrotatedAccessKeyRiskModel.risk.userUnrotatedAccessKeys.length,
            {
                accessKeyRotationTimeFrame: userUnrotatedAccessKeyRiskModel.risk.accessKeyRotationTimeFrame,
                user:
                    <Entity
                        entityIdOrModel={userModel}
                        variant="typeText"/>
            }),
        getResolutionSectionSteps,
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    riskModel={userUnrotatedAccessKeyRiskModel}
                    userModel={userModel}/>
        });
}