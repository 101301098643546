import { Message } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RiskTypeMetadataModelHelper, Severity, StorageHelper, Tenant, ticketingServiceTicketStore, useDynamicRiskSeverityTranslator, useTheme } from "../../../../../../../../../common";
import { RiskProfileDefinition } from "../useProfileDefinition";
import { ProfileTopbarJiraInfoItem, ProfileTopbarServiceNowInfoItem } from "./components";
import { useCommonInfo } from "./useCommonInfo";

export function useCloudDefinition(riskModel: Contract.RiskModel): RiskProfileDefinition {
    const commonInfo = useCommonInfo(riskModel);
    const jiraIssues = ticketingServiceTicketStore.useGet(riskModel.riskState?.integration.jira.issueIds) as Contract.JiraIssue[];
    const serviceNowIncidents = ticketingServiceTicketStore.useGet(riskModel.riskState?.integration.serviceNow.incidentIds) as Contract.ServiceNowIncident[];
    const dynamicRiskSeverityTranslator = useDynamicRiskSeverityTranslator();
    const dynamicRiskSeverityText = dynamicRiskSeverityTranslator(RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).policyConfigurationTypeName);

    const theme = useTheme();
    return useMemo(
        () => ({
            cardData: {
                infoElements: _([
                    <Severity
                        key="Severity"
                        message={
                            _.isNil(dynamicRiskSeverityText)
                                ? undefined
                                : <Message
                                    level="info"
                                    sx={{ fontSize: "14px" }}
                                    title={dynamicRiskSeverityText}
                                    variant="minimal"/>}
                        severity={riskModel.risk.severity}
                        sx={{ fontSize: "18px" }}/>,
                    <Tenant
                        iconSx={{ fontSize: "16px" }}
                        key="Tenant"
                        sx={{ color: theme.palette.text.primary }}
                        tenantId={riskModel.risk.tenantId}
                        tenantNameTranslatorOptions={{ includeRawId: true }}
                        variant="iconText"/>,
                    commonInfo.openStatusUpdateTime,
                    commonInfo.statusUpdateTime,
                    commonInfo.ignoreExpirationDate,
                    commonInfo.status
                ]).
                    filter().
                    concatIf(
                        !_.isEmpty(jiraIssues),
                        <ProfileTopbarJiraInfoItem
                            issues={jiraIssues}
                            key="jiraIssues"/>).
                    concatIf(
                        !_.isEmpty(serviceNowIncidents),
                        <ProfileTopbarServiceNowInfoItem
                            incidents={serviceNowIncidents}
                            key="serviceNowIncidents"/>).
                    value()
            },
            storageData: {
                openTab: StorageHelper.customerRisksProfileTabsOpen
            },
            urlHelper: {
                getHashUrl: CustomerConsoleAppUrlHelper.getRiskProfileHashUrl,
                getProfileUrl: CustomerConsoleAppUrlHelper.getRiskProfileUrl,
                getRelativeUrl: CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl
            }
        }),
        [CustomerConsoleAppUrlHelper, StorageHelper, commonInfo, riskModel, jiraIssues, serviceNowIncidents]);
}