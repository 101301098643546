import _, { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableSortType, DeferredFilter, InlineItems, Optional, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { useAwsCommonAccessResourceDefinition, useAwsIamIdentityDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, tenantModelStore, TimeRangeHelper, useVendorNameTranslator } from "../../../../../../../../../../common";
import { Vendor } from "../../../../../../../../../../common/components/Vendor";
import { AwsIamRoleAssumeRolePolicyDocumentStatementGranteesCell, AwsTenantHelper, useAwsIamRoleAssumeRolePolicyDocumentStatementGranteeTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";

export function useAwsIamRoleDefinition(definitionData: DefinitionData) {
    const iamRoleModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsIamRoleModelFilters>;
    const identityDefinition = useAwsIamIdentityDefinition(definitionData);
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();

    const iamRoleAssumeRolePolicyDocumentStatementGranteeTranslator = useAwsIamRoleAssumeRolePolicyDocumentStatementGranteeTranslator();
    const vendorNameTranslator = useVendorNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsIamRoleDefinition",
            () => ({
                columns: {
                    accessLevel: "Trust Access Level",
                    activityTime: {
                        empty: "Never",
                        title: "Activity Time",
                        unknown: "Unknown"
                    },
                    assumeRoleGranteeType: {
                        title: "Trusted Entities",
                        [Contract.TypeNames.AwsIamRoleModelAssumeRoleGranteeType]: {
                            [Contract.AwsIamRoleModelAssumeRoleGranteeType.Identity]: "Principals",
                            [Contract.AwsIamRoleModelAssumeRoleGranteeType.IdentityProvider]: "Identity providers",
                            [Contract.AwsIamRoleModelAssumeRoleGranteeType.Service]: "AWS Services",
                            [Contract.AwsIamRoleModelAssumeRoleGranteeType.Tenant]: "Accounts"
                        }
                    },
                    vendor: "3*superscript*rd** Party",
                    vendors: "Vendors"
                }
            }));

    const commonAccessResourceDefinition =
        useAwsCommonAccessResourceDefinition(
            definitionData,
            { accessLevel: { title: localization.columns.accessLevel() } });

    function getActivityTimeTranslation(roleModel: Contract.EntityModel) {
        if (!AwsTenantHelper.isAccessAdvisorEnabled(roleModel.entity, tenantModelMap)) {
            return localization.columns.activityTime.unknown();
        }

        const activityTime = (roleModel.entity as Contract.AwsIamRole).activityTime;
        return _.isNil(activityTime)
            ? localization.columns.activityTime.empty()
            : TimeFormatter.humanizePastDuration(activityTime);
    }

    return new EntityTableDefinition(
        _.filter([
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        title: localization.columns.vendor(),
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.vendor()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamRoleVendor}
                key={Contract.EntityModelProperty.AwsIamRoleVendor}
                title={localization.columns.vendor()}/>,
            identityDefinition.columns.tenantColumn,
            identityDefinition.columns.creationTimeColumn,
            identityDefinition.columns.creatorIdentityCsvColumn,
            identityDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamRoleModel) => ({
                            [localization.columns.assumeRoleGranteeType.title()]: _.join(iamRoleAssumeRolePolicyDocumentStatementGranteeTranslator(item.entity as Contract.AwsIamRole), "\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                placeholder={localization.columns.assumeRoleGranteeType.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.assumeRoleGranteeType[Contract.TypeNames.AwsIamRoleModelAssumeRoleGranteeType][Contract.AwsIamRoleModelAssumeRoleGranteeType.Identity]()}
                                    value={Contract.AwsIamRoleModelAssumeRoleGranteeType.Identity}/>
                                <ValuesFilterItem
                                    title={localization.columns.assumeRoleGranteeType[Contract.TypeNames.AwsIamRoleModelAssumeRoleGranteeType][Contract.AwsIamRoleModelAssumeRoleGranteeType.IdentityProvider]()}
                                    value={Contract.AwsIamRoleModelAssumeRoleGranteeType.IdentityProvider}/>
                                <ValuesFilterItem
                                    title={localization.columns.assumeRoleGranteeType[Contract.TypeNames.AwsIamRoleModelAssumeRoleGranteeType][Contract.AwsIamRoleModelAssumeRoleGranteeType.Service]()}
                                    value={Contract.AwsIamRoleModelAssumeRoleGranteeType.Service}/>
                                <ValuesFilterItem
                                    title={localization.columns.assumeRoleGranteeType[Contract.TypeNames.AwsIamRoleModelAssumeRoleGranteeType][Contract.AwsIamRoleModelAssumeRoleGranteeType.Tenant]()}
                                    value={Contract.AwsIamRoleModelAssumeRoleGranteeType.Tenant}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamRoleAssumeRoleGranteeTypes}
                key={Contract.EntityModelProperty.AwsIamRoleAssumeRoleGranteeTypes}
                render={optionalTableCell<Contract.EntityModel>(AwsIamRoleAssumeRolePolicyDocumentStatementGranteesCell)}
                title={localization.columns.assumeRoleGranteeType.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamRoleModel) => ({
                            [localization.columns.activityTime.title()]: getActivityTimeTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={definitionData.entityModelFiltersPromise as Promise<Contract.AwsIamIdentityModelFilters>}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamIdentityActivityTime}
                itemProperty={getActivityTimeTranslation}
                key={Contract.EntityModelProperty.AwsIamIdentityActivityTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            commonAccessResourceDefinition.columns.accessLevelColumn,
            identityDefinition.columns.policies,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamRoleModel) => ({
                            [localization.columns.vendors()]:
                                _.join(
                                    _.map(
                                        (item.entity as Contract.AwsIamRole).vendors,
                                        vendor => vendorNameTranslator(vendor)),
                                    "\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={iamRoleModelFiltersPromise}
                                title={localization.columns.vendors()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.vendorItems.emptyValue }}
                                        placeholder={localization.columns.vendors()}>
                                        {_.map(
                                            filters.vendorItems.items,
                                            vendor =>
                                                <ValuesFilterItem
                                                    key={vendor}
                                                    title={vendorNameTranslator(vendor)}
                                                    value={vendor}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamRoleVendors}
                key={Contract.EntityModelProperty.AwsIamRoleVendors}
                render={
                    optionalTableCell<Contract.AwsIamRoleModel>(
                        (item: Contract.AwsIamRoleModel) =>
                            _.isEmpty((item.entity as Contract.AwsIamRole).vendors)
                                ? undefined
                                : <InlineItems
                                    items={
                                        _.map(
                                            (item.entity as Contract.AwsIamRole).vendors,
                                            vendor => Vendor({ vendorType: vendor }))}
                                    variant="itemPlusItemCount"/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.vendors()}/>,
            identityDefinition.columns.boundary,
            identityDefinition.columns.tagsColumn,
            identityDefinition.columns.attributesColumn,
            identityDefinition.columns.openRiskedEntityRisksCsvColumn,
            identityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            identityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsIamRoleRequest(
                new Contract.EntityControllerGetEntityModelPageAwsIamRoleRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamPrincipalPolicies]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsIamIdentityActivityTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamIdentityPermissionBoundary]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamRoleAssumeRoleGranteeTypes]),
                    filterMap[Contract.EntityModelProperty.AwsIamRoleVendor] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamRoleVendors])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel]))));
}