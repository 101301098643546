import { Loading, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import React from "react";
import { UdmObjectTable, useLayoutOptions } from "../../../common";

export function UdmObjects() {
    const localization =
        useLocalization(
            "views.customer.udmObjects",
            () => ({
                title: "Explore"
            }));

    useLayoutOptions({ view: { title: localization.title() } });

    return (
        <Stack sx={{ height: "100%" }}>
            <Loading>
                <UdmObjectTable/>
            </Loading>
        </Stack>);
}