﻿import { useLocalization, useSelectionActionsContext } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, ReactNode, useMemo, useState } from "react";
import { RiskType, ScopeHelper, scopeSystemEntityModelStore, SelectionActionButton, ServiceNowIcon, TicketingServicePromptMessage, UpsertServiceNowIncidentDialog } from "..";
import { UpsertServiceNowIncidentProps } from "./UpsertServiceNowIncidentDialog/components";

export type CreateServiceNowIncidentsSelectionActionProps = {
    getItemIncidentCreationCount: (item: any) => number;
    getPromptMessage?: (itemIds: string[]) => TicketingServicePromptMessage;
    getSelectedRiskIdToScopeIdsMap: () => Dictionary<string[]>;
    reloadItems: () => Promise<void>;
    riskType: RiskType;
    startElement?: ReactNode;
};

export function CreateServiceNowIncidentsSelectionAction({ getItemIncidentCreationCount, getPromptMessage, getSelectedRiskIdToScopeIdsMap, reloadItems, riskType, startElement }: CreateServiceNowIncidentsSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds, loadedItems } = useSelectionActionsContext();
    const selectedRiskIdToScopeIdsMap = getSelectedRiskIdToScopeIdsMap();
    const instanceModels =
        ScopeHelper.getScopesParentScopeSystemEntityModelsIntersection(
            _.values(selectedRiskIdToScopeIdsMap),
            scopeSystemEntityModelStore.useGetServiceNow());
    const incidentCreationCount =
        useMemo(
            () => {
                if (loadedItems.length !== itemIds.length) {
                    return undefined;
                }

                return _(loadedItems).
                    map(item => getItemIncidentCreationCount(item)).
                    sum();
            },
            [itemIds, loadedItems]);

    const localization =
        useLocalization(
            "common.createServiceNowIncidentsSelectionAction",
            () => ({
                create: [
                    "Create ServiceNow incident",
                    "Create ServiceNow incidents"
                ]
            }));

    const [open, setOpen] = useState(false);
    const [riskIdToIncidentIdsMap, setRiskIdToIncidentIdsMap] = useState<Dictionary<string[]>>({});
    return (
        !_.isEmpty(instanceModels)
            ? <Box sx={{ height: "100%" }}>
                {open && !_.isEmpty(instanceModels) &&
                    <UpsertWithPrompt
                        getPromptMessage={getPromptMessage}
                        instanceModels={instanceModels}
                        riskIdToIncidentIdsMap={riskIdToIncidentIdsMap}
                        riskType={riskType}
                        onClose={
                            async successRiskIds => {
                                if (!_.isEmpty(successRiskIds)) {
                                    await reloadItems();
                                }

                                actionEnded();
                                setOpen(false);
                            }}/>}
                <Stack
                    direction="row"
                    sx={{ height: "100%" }}>
                    {startElement}
                    <SelectionActionButton
                        startIcon={
                            <ServiceNowIcon
                                sx={{
                                    opacity:
                                        actionExecuting
                                            ? 0.4
                                            : 1
                                }}/>}
                        onClick={
                            () => {
                                setRiskIdToIncidentIdsMap(
                                    _(selectedRiskIdToScopeIdsMap).
                                        keys().
                                        keyBy().
                                        mapValues(() => []).
                                        value());
                                setOpen(true);
                                actionStarted(true);
                            }}>
                        {localization.create(incidentCreationCount ?? 0)}
                    </SelectionActionButton>
                </Stack>
            </Box>
            : <Fragment/>);
}

type UpsertWithPromptProps =
    UpsertServiceNowIncidentProps & {
        getPromptMessage?: (itemIds: string[]) => TicketingServicePromptMessage;
    };

function UpsertWithPrompt({ getPromptMessage, instanceModels, onClose, riskIdToIncidentIdsMap, riskType }: UpsertWithPromptProps) {
    const { itemIds } = useSelectionActionsContext();
    const promptMessage =
        useMemo(
            () => {
                if (_.keys(riskIdToIncidentIdsMap).length === itemIds.length ||
                    _.isNil(getPromptMessage)) {
                    return undefined;
                }

                return getPromptMessage(itemIds);
            },
            [riskIdToIncidentIdsMap, getPromptMessage, itemIds]);

    return (
        <UpsertServiceNowIncidentDialog
            instanceModels={instanceModels}
            promptMessage={promptMessage}
            riskIdToIncidentIdsMap={riskIdToIncidentIdsMap}
            riskType={riskType}
            onClose={onClose}/>);
}