import { Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, TypeMetadataHelper } from "..";

export class TypeHelper {
    private static typeNameToSealedAssignableTypeNamesMap: Dictionary<string[]> = {};

    public static extendOrImplement(typeName: string, ...baseTypeNames: string[]) {
        if (_.includes(baseTypeNames, typeName)) {
            return true;
        } else {
            const typeMetadata = TypeMetadataHelper.getTypeMetadata(typeName);
            return !_(typeMetadata.extendTypeNames).
                concat(typeMetadata.implementTypeNames ?? []).
                intersection(baseTypeNames as Contract.TypeNames[]).
                isEmpty();
        }
    }

    public static getEnumValue(
        enumTypeName: string,
        enumMemberName: string) {
        return TypeMetadataHelper.getTypeMetadata(enumTypeName).nameToValueMap![enumMemberName];
    }

    public static getEnumValueMemberName<TEnum>(
        enumTypeName: string,
        enumValue: number): Optional<TEnum> {
        const enumMemberName =
            _.findKey(
                TypeMetadataHelper.getTypeMetadata(enumTypeName).nameToValueMap!,
                value => value === enumValue);
        return _.as<Optional<TEnum>>(enumMemberName);
    }

    public static getSealedAssignableTypeNames(baseTypeName: string) {
        if (_.isNil(TypeHelper.typeNameToSealedAssignableTypeNamesMap[baseTypeName])) {
            TypeHelper.typeNameToSealedAssignableTypeNamesMap[baseTypeName] =
                _(Contract.TypeNames).
                    values().
                    map(typeName => TypeMetadataHelper.getTypeMetadata(typeName)).
                    filter(
                        typeMetadata =>
                            typeMetadata.kind === "class" &&
                            typeMetadata.sealed === true &&
                            TypeHelper.extendOrImplement(typeMetadata.name, baseTypeName)).
                    map(typeMetadata => typeMetadata.name).
                    value();
        }

        return TypeHelper.typeNameToSealedAssignableTypeNamesMap[baseTypeName];
    }

    public static isNumeric(value: string): boolean {
        return /^\d+$/.test(value);
    }

    public static tryParseEnum<TEnum>(
        enumTypeName: string,
        enumMemberName?: string): Optional<TEnum> {
        return _.isNil(enumMemberName) || _.isNil(TypeMetadataHelper.getTypeMetadata(enumTypeName).nameToValueMap![enumMemberName])
            ? undefined
            : _.as<TEnum>(enumMemberName);
    }
}