import { Action1, Optional, useLocalization } from "@infrastructure";
import { Stack, SxProps } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, RadioField, riskPolicyModelStore, RiskPolicyTypeMetadataHelper, scopeNodeModelStore, TenantHelper, tenantModelStore, useRiskPolicyTranslator, useTheme } from "../..";
import { Item, ItemItem } from "./components";

type RiskPolicySelectProps =
    {
        containerSx?: SxProps;
        scopeId: string;
        selectedScopeIds?: string[];
    } & ({
        allPoliciesEnabled: true;
        selectedBuiltInRiskPolicyConfigurationTypeNames?: string[];
        selectedCustomRiskPolicyIds?: string[];
        setSelectedBuiltInRiskPolicyConfigurationTypeNames: Action1<Optional<string[]>>;
        setSelectedCustomRiskPolicyIds: Action1<Optional<string[]>>;
    } | {
        allPoliciesEnabled: false;
        selectedBuiltInRiskPolicyConfigurationTypeNames: string[];
        selectedCustomRiskPolicyIds: string[];
        setSelectedBuiltInRiskPolicyConfigurationTypeNames: Action1<string[]>;
        setSelectedCustomRiskPolicyIds: Action1<string[]>;
    });

export function RiskPolicySelect({ allPoliciesEnabled, containerSx, scopeId, selectedBuiltInRiskPolicyConfigurationTypeNames, selectedCustomRiskPolicyIds, selectedScopeIds, setSelectedBuiltInRiskPolicyConfigurationTypeNames, setSelectedCustomRiskPolicyIds }: RiskPolicySelectProps) {
    const customRiskPolicyModels = riskPolicyModelStore.useGetLicensedCustom();
    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const riskPolicyTenantTypes =
        useMemo(
            () => TenantHelper.CloudRiskPoliciesTenantTypes,
            []);
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(riskPolicyTenantTypes);

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "common.riskPolicySelect",
            () => ({
                selection: {
                    all: "All (existing and future policies)",
                    some: "Select specific policies"
                }
            }));

    const [builtInRiskPolicyCategoryToItemsMap, customRiskPolicyTypeItems] =
        useMemo(
            () => {
                const activeTenantModelMap =
                    _.keyBy(
                        activeTenantModels,
                        activeTenantModel => activeTenantModel.configuration.id);
                const scopeIds =
                    selectedScopeIds ??
                    [scopeId];

                const tenantIds =
                    _(scopeIds).
                        flatMap(scopeId => scopeNodeMap[scopeId].tenantIds).
                        filter(tenantId => !_.isNil(activeTenantModelMap[tenantId])).
                        value();

                const tenantTypes =
                    _(tenantIds).
                        map(tenantId => activeTenantModelMap[tenantId].tenantType).
                        uniq().
                        value();

                const builtInRiskPolicyCategoryToItemsMap =
                    _(RiskPolicyTypeMetadataHelper.getLicensedRiskPolicyConfigurationTypeNameToMetadataMap()).
                        map(
                            (metadata, configurationTypeName) => ({
                                ...metadata,
                                configurationTypeName
                            })).
                        filter(
                            riskPolicyTypeData =>
                                _(RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyTypeData.configurationTypeName)).
                                    intersection(tenantTypes).
                                    some() &&
                                riskPolicyTypeData.category !== Contract.RiskPolicyCategory.Custom).
                        groupBy(riskPolicyTypeData => riskPolicyTypeData.category).
                        mapValues(
                            riskPolicyTypeDatas =>
                                _.map(
                                    riskPolicyTypeDatas,
                                    riskPolicyTypeData =>
                                        new ItemItem(
                                            riskPolicyTranslator(riskPolicyTypeData.configurationTypeName).description!,
                                            riskPolicyTypeData.configurationTypeName,
                                            riskPolicyTranslator(riskPolicyTypeData.configurationTypeName).title,
                                            riskPolicyTypeData.severity,
                                            _.intersection(
                                                RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyTypeData.configurationTypeName),
                                                tenantTypes)))).
                        value();

                const parentScopeIds = scopeNodeMap[scopeId].parentScopeIds;
                const customRiskPolicyTypeItems =
                    _(customRiskPolicyModels).
                        filter(
                            riskPolicyModel =>
                                (riskPolicyModel.riskPolicyConfiguration.scopeId === scopeId || _.includes(parentScopeIds, riskPolicyModel.riskPolicyConfiguration.scopeId)) &&
                                _(RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyModel.riskPolicyConfiguration.typeName)).
                                    intersection(tenantTypes).
                                    some()).
                        map(
                            riskPolicyModel =>
                                new ItemItem(
                                    (riskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).description ?? "",
                                    riskPolicyModel.riskPolicyConfiguration.id,
                                    (riskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).name,
                                    (riskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).severity,
                                    _.intersection(
                                        RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyModel.riskPolicyConfiguration.typeName),
                                        tenantTypes))).
                        value();

                return [builtInRiskPolicyCategoryToItemsMap, customRiskPolicyTypeItems];
            },
            [activeTenantModels, customRiskPolicyModels, scopeId, scopeNodeMap, selectedScopeIds]);
    const [all, setAll] =
        useState(
            _.isNil(selectedBuiltInRiskPolicyConfigurationTypeNames) &&
            _.isNil(selectedCustomRiskPolicyIds));
    const [builtInRiskPolicyCategoryToSelectedTypeNamesMap, setBuiltInRiskPolicyCategoryToSelectedTypeNamesMap] =
        useState(
            _.mapValues(
                builtInRiskPolicyCategoryToItemsMap,
                builtInRiskPolicyTypeItems =>
                    _(builtInRiskPolicyTypeItems).
                        filter(builtInRiskPolicyTypeItem => _.includes(selectedBuiltInRiskPolicyConfigurationTypeNames, builtInRiskPolicyTypeItem.id)).
                        map(builtInRiskPolicyTypeItem => builtInRiskPolicyTypeItem.id).
                        value()));
    const [customRiskPolicyIds, setCustomRiskPolicyIds] = useState(selectedCustomRiskPolicyIds ?? []);
    const specificPolicies =
        useMemo(
            () =>
                _(
                    [
                        Contract.RiskPolicyCategory.Access,
                        Contract.RiskPolicyCategory.Compute,
                        Contract.RiskPolicyCategory.Data,
                        Contract.RiskPolicyCategory.Kubernetes,
                        Contract.RiskPolicyCategory.Logging,
                        Contract.RiskPolicyCategory.Management,
                        Contract.RiskPolicyCategory.Monitoring,
                        Contract.RiskPolicyCategory.Network,
                        Contract.RiskPolicyCategory.Secrets,
                        Contract.RiskPolicyCategory.Behavior,
                        Contract.RiskPolicyCategory.WorkloadAnalysis
                    ]).
                    filter(builtInRiskPolicyCategory => !_.isNil(builtInRiskPolicyCategoryToItemsMap[builtInRiskPolicyCategory])).
                    map(
                        builtInRiskPolicyCategory =>
                            <Item
                                category={builtInRiskPolicyCategory}
                                items={builtInRiskPolicyCategoryToItemsMap[builtInRiskPolicyCategory]}
                                key={builtInRiskPolicyCategory}
                                selectedItemIds={builtInRiskPolicyCategoryToSelectedTypeNamesMap[builtInRiskPolicyCategory]}
                                onSelectedItemIdsChanged={
                                    (selectedBuiltInRiskPolicyConfigurationTypeNames: string[]) => {
                                        const updatedBuiltInRiskPolicyCategoryToSelectedTypeNamesMap = {
                                            ...builtInRiskPolicyCategoryToSelectedTypeNamesMap,
                                            [builtInRiskPolicyCategory]: selectedBuiltInRiskPolicyConfigurationTypeNames
                                        };
                                        setBuiltInRiskPolicyCategoryToSelectedTypeNamesMap(updatedBuiltInRiskPolicyCategoryToSelectedTypeNamesMap);
                                        setSelectedBuiltInRiskPolicyConfigurationTypeNames(_.flatMap(updatedBuiltInRiskPolicyCategoryToSelectedTypeNamesMap));
                                    }}/>).
                    value(),
            [builtInRiskPolicyCategoryToItemsMap, builtInRiskPolicyCategoryToSelectedTypeNamesMap]);

    const theme = useTheme();
    return (
        allPoliciesEnabled
            ? <Stack
                spacing={2}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(2),
                    padding: theme.spacing(2),
                    ...containerSx
                }}>
                <RadioField
                    checked={all}
                    title={localization.selection.all()}
                    onSelected={() => {
                        setAll(true);
                        setSelectedBuiltInRiskPolicyConfigurationTypeNames(undefined);
                        setSelectedCustomRiskPolicyIds(undefined);
                    }}>
                </RadioField>
                <RadioField
                    checked={!all}
                    indent={false}
                    title={localization.selection.some()}
                    onSelected={() => {
                        setAll(false);
                        setSelectedBuiltInRiskPolicyConfigurationTypeNames(_.flatMap(builtInRiskPolicyCategoryToSelectedTypeNamesMap));
                        setSelectedCustomRiskPolicyIds(customRiskPolicyIds);
                    }}>
                    <Stack>
                        {specificPolicies}
                        {!_.isEmpty(customRiskPolicyTypeItems) && (
                            <Item
                                category={Contract.RiskPolicyCategory.Custom}
                                items={customRiskPolicyTypeItems}
                                selectedItemIds={customRiskPolicyIds}
                                onSelectedItemIdsChanged={
                                    (selectedCustomRiskPolicyIds: string[]) => {
                                        setCustomRiskPolicyIds(selectedCustomRiskPolicyIds);
                                        setSelectedCustomRiskPolicyIds(selectedCustomRiskPolicyIds);
                                    }}/>)}
                    </Stack>
                </RadioField>
            </Stack>
            : <Stack>
                {specificPolicies}
                {!_.isEmpty(customRiskPolicyTypeItems) && (
                    <Item
                        category={Contract.RiskPolicyCategory.Custom}
                        items={customRiskPolicyTypeItems}
                        selectedItemIds={customRiskPolicyIds}
                        onSelectedItemIdsChanged={
                            (selectedCustomRiskPolicyIds: string[]) => {
                                setCustomRiskPolicyIds(selectedCustomRiskPolicyIds);
                                setSelectedCustomRiskPolicyIds(selectedCustomRiskPolicyIds);
                            }}/>)}
            </Stack>);
}