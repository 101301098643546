import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useOciKmsVaultStatusTranslator, useOciKmsVaultTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "./useOciDefaultResourceInfoItemElements";

export function useOciKmsVaultDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const vaultModel = resourceModel as Contract.OciKmsVaultModel;
    const vault = vaultModel.entity as Contract.OciKmsVault;

    const vaultStatusTranslator = useOciKmsVaultStatusTranslator();
    const vaultTypeTranslator = useOciKmsVaultTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciKmsVaultDefinition",
            () => ({
                info: {
                    items: {
                        keys: "Master Encryption Keys",
                        secrets: "Secrets",
                        status: "State",
                        type: "Vault Type"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={vaultStatusTranslator(vault.status)}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={vaultTypeTranslator(vault.type)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vaultModel.keyIds}
                        entityTypeName={Contract.TypeNames.OciKmsVaultKey}
                        key="keys"
                        title={localization.info.items.keys()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vaultModel.secretIds}
                        entityTypeName={Contract.TypeNames.OciKmsVaultSecret}
                        key="secrets"
                        title={localization.info.items.secrets()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}