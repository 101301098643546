import { InlineCopyToClipboardText, Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../index";

export function Arm() {
    const { aadTenantModel, tenantPermissionTypes } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.addOrEdit.roleDefinitionsItem.arm",
            () => ({
                steps: {
                    step1: {
                        link: "here",
                        title: "Click {{link}} to create a Custom deployment."
                    },
                    step2: {
                        permissions: {
                            disabled: "false",
                            enabled: "true"
                        },
                        steps: {
                            step1: "Subscription: Choose the subscription you want to add",
                            step2: "Region: Specify a single region",
                            step3: "Principal ID is set to: {{applicationServicePrincipalRawId}}",
                            step4: "**Data Protection Permissions** is set to **{{permission}}**",
                            step5: "**Workload Protection Permissions** is set to **{{permission}}**",
                            step6: "**Remediation Permissions** is set to **{{permission}}**",
                            step7: "**Just In Time Access Permissions** is set to **{{permission}}**"
                        },
                        title: "Configure the project and instance details as follows:"
                    },
                    step3: "Click **Review + create**",
                    step4: "Click **Create**",
                    step5: "Wait until the deployment is completed successfully"
                }
            }));

    return (
        <Steps variant="letters">
            {localization.steps.step1.title({
                link:
                    <Link
                        urlOrGetUrl={`https://portal.azure.com/#create/Microsoft.Template/uri/${encodeURIComponent("https://tenable-utilities.s3.us-east-2.amazonaws.com/Onboarding/Azure/ARM/TemplateSubscription.json")}`}
                        variant="external">
                        {localization.steps.step1.link()}
                    </Link>
            })}
            {new Step(
                localization.steps.step2.title(),
                {
                    contentElement:
                        <Steps variant="bullets">
                            {localization.steps.step2.steps.step1()}
                            {localization.steps.step2.steps.step2()}
                            {localization.steps.step2.steps.step3({
                                applicationServicePrincipalRawId:
                                    <InlineCopyToClipboardText text={aadTenantModel!.configuration.applicationServicePrincipalRawId}/>
                            })}
                            {localization.steps.step2.steps.step4({
                                permission:
                                    _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis)
                                        ? localization.steps.step2.permissions.enabled()
                                        : localization.steps.step2.permissions.disabled()
                            })}
                            {localization.steps.step2.steps.step5({
                                permission:
                                    _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis)
                                        ? localization.steps.step2.permissions.enabled()
                                        : localization.steps.step2.permissions.disabled()
                            })}
                            {localization.steps.step2.steps.step6({
                                permission:
                                    _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.Write)
                                        ? localization.steps.step2.permissions.enabled()
                                        : localization.steps.step2.permissions.disabled()
                            })}
                            {localization.steps.step2.steps.step7({
                                permission:
                                    _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.PermissionManagement)
                                        ? localization.steps.step2.permissions.enabled()
                                        : localization.steps.step2.permissions.disabled()
                            })}
                        </Steps>
                })}
            {localization.steps.step3()}
            {localization.steps.step4()}
            {localization.steps.step5()}
        </Steps>);
}