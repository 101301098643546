﻿import { DataTableColumn, DataTableSortType, DeferredFilter, EnumValuesFilter, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, TimeRangeHelper, useKubernetesClusterPlatformTranslator } from "../../../../../../../../../common";
import { DefinitionData } from "../../../Table";
import { useCommonKubernetesClusterDefinition } from "./useCommonKubernetesClusterDefinition";

export function useCommonUnmanagedKubernetesClusterDefinition(definitionData: DefinitionData) {
    const commonKubernetesClusterModelDefinition = useCommonKubernetesClusterDefinition(definitionData);
    const unmanagedKubernetesClusterModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.IKubernetesClusterModelFilters<Contract.IUnmanagedKubernetesClusterModelFiltersSection>>;

    const kubernetesClusterPlatformTranslator = useKubernetesClusterPlatformTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonUnmanagedKubernetesClusterDefinition",
            () => ({
                columns: {
                    creationTime: "Creation Time",
                    platform: "Platform",
                    version: "Kubernetes Version"
                }
            }));

    return {
        columns: {
            ...commonKubernetesClusterModelDefinition.columns,
            creationTimeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IUnmanagedKubernetesClusterModel) => ({
                                [localization.columns.creationTime()]:
                                    _.isNil(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).data.creationTime)
                                        ? ""
                                        : TimeFormatter.iso8601String(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).data.creationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={unmanagedKubernetesClusterModelFiltersPromise}
                                    title={localization.columns.creationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.kubernetesCluster.creationTimeRange.emptyValue}
                                            placeholder={localization.columns.creationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.kubernetesCluster.creationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.UnmanagedKubernetesClusterCreationTime}
                    key={Contract.EntityModelProperty.UnmanagedKubernetesClusterCreationTime}
                    render={
                        optionalTableCell<Contract.IUnmanagedKubernetesClusterModel>(
                            item =>
                                _.isNil(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).data.creationTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).data.creationTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>,
            platformColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IUnmanagedKubernetesClusterModel) => ({
                                [localization.columns.platform()]:
                                    _.isNil(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).platform)
                                        ? undefined
                                        : kubernetesClusterPlatformTranslator(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).platform!)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    emptyValueOptions={{ enabled: true }}
                                    enumType={Contract.KubernetesClusterPlatform}
                                    enumTypeTranslator={kubernetesClusterPlatformTranslator}
                                    placeholder={localization.columns.platform()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.UnmanagedKubernetesClusterPlatform}
                    render={
                        optionalTableCell<Contract.IUnmanagedKubernetesClusterModel>(
                            (item: Contract.IUnmanagedKubernetesClusterModel) =>
                                _.isNil(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).data.platform)
                                    ? undefined
                                    : kubernetesClusterPlatformTranslator(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).platform!))}
                    title={localization.columns.platform()}/>,
            versionColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IUnmanagedKubernetesClusterModel) => ({
                                [localization.columns.version()]: _.as<Contract.IUnmanagedKubernetesCluster>(item.entity).data.version
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.KubernetesClusterVersion)}
                                    placeholder={localization.columns.version()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesClusterVersion}
                    key={Contract.EntityModelProperty.KubernetesClusterVersion}
                    render={
                        optionalTableCell<Contract.IUnmanagedKubernetesClusterModel>(
                            (item: Contract.IUnmanagedKubernetesClusterModel) =>
                                _.isNil(_.as<Contract.IUnmanagedKubernetesCluster>(item.entity).data.version)
                                    ? undefined
                                    : _.as<Contract.IUnmanagedKubernetesCluster>(item.entity).data.version)}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.version()}/>
        }
    };
}