import { Store } from "@infrastructure";
import { Contract, IdentityController } from "..";

export const identityModelStore =
    new Store<Contract.IdentityModel, never, never>(
        identityModel => identityModel.identity.id,
        {
            getAll:
                async () => {
                    const { identityModels } = await IdentityController.getIdentityModels();
                    return identityModels;
                }
        });