import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AmazonIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M3 5.5L12 3L21 5.5L12 8L3 5.5Z"
                fill="#FDB013"/>
            <path
                d="M13 9.5L21 7V19.5L13 22.4374V9.5Z"
                fill="#FDB013"/>
            <path
                d="M3 7L11 9.5V22.4374L3 19.5V7Z"
                fill="#FDB013"/>
        </SvgIcon>);
}