import { DataTableSortType, useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RisksItemsFilterId } from "../../../../../../../Filters";
import { GroupedItem, GroupedTableColumnId } from "../../../../Grouped";
import { useCommonGroupedRiskColumns } from "../useCommonGroupedRiskColumns";
import { RiskPolicies } from "./components";

export function useCommonGroupedRiskTenantColumns() {
    const commonGroupedRiskColumns = useCommonGroupedRiskColumns();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.hooks.useCommonGroupedRiskTenantColumns",
            () => ({
                riskPolicies: "Policies"
            }));

    return useMemo(
        () => ({
            riskCount: commonGroupedRiskColumns.riskCount,
            riskPolicies: {
                element: RiskPolicies,
                filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId],
                id: GroupedTableColumnId.RiskPolicies,
                minWidth: 280,
                sortOptions: {
                    getIterates: () => [
                        (item: GroupedItem) => {
                            const riskGroup = item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup;
                            return riskGroup.customRiskPolicyIds.length + riskGroup.builtInRiskPolicyConfigurationTypeNames.length;
                        }],
                    type: DataTableSortType.Numeric
                },
                title: localization.riskPolicies(),
                width: 4.5
            },
            severity: commonGroupedRiskColumns.severity
        }),
        []);
}