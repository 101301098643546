import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AzureNetworkNetworkSecurityGroupRulesTable } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureNetworkNetworkSecurityGroupRiskContext } from "../contexts";


export function useAzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk;
    const networkSecurityGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AzureNetworkNetworkSecurityGroupModel;
    const networkSecurityGroup = networkSecurityGroupModel.entity as Contract.AzureNetworkNetworkSecurityGroup;

    const getAzureNetworkNetworkSecurityGroupRiskContext = useGetAzureNetworkNetworkSecurityGroupRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskDefinition",
            () => ({
                contextItems: {
                    sensitivePermissionActionSeverityNetworkedResourceIds: {
                        empty: "The {{translatedSecurityGroupTypeName}} is not attached to resources with critical or high severity permissions",
                        text: "The {{translatedSecurityGroupTypeName}} is attached to {{sensitivePermissionActionSeverityNetworkedResourceIds}} with critical or high severity permissions"
                    }
                },
                description: "{{networkSecurityGroup}} allows traffic from any IP address",
                sections: {
                    inboundRules: "Inbound Rules",
                    resolution: {
                        step1: "For each relevant inbound rule, edit the address range to restrict it, or delete the rule using the **Delete** button",
                        step2: "Click **Save** to confirm"
                    }
                }
            }));
    const translatedSecurityGroupTypeName =
        entityTypeNameTranslator(
            networkSecurityGroup.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            networkSecurityGroup:
                <Entity
                    entityIdOrModel={networkSecurityGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                <EntityExternalConsoleLink
                    entityId={networkSecurityGroupModel.id}
                    key={networkSecurityGroupModel.id}
                    page={Contract.AzureConsoleEntityPage.InboundRules}/>,
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2()
            ],
        riskModel,
        () => {
            const networkSecurityGroupRiskContext = getAzureNetworkNetworkSecurityGroupRiskContext(networkSecurityGroupModel);
            return [
                networkSecurityGroupRiskContext.generalInformation,
                networkSecurityGroupRiskContext.sensitive,
                networkSecurityGroupRiskContext.networkedResources,
                networkSecurityGroupRiskContext.sensitiveNetworkedResources,
                networkSecurityGroupRiskContext.subnets,
                _.isEmpty(networkSecurityGroupModel.networkedResourceTypeNameToIdsMap)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        (_.isEmpty(risk.sensitivePermissionActionSeverityNetworkedResourceIds)
                            ? localization.contextItems.sensitivePermissionActionSeverityNetworkedResourceIds.empty
                            : localization.contextItems.sensitivePermissionActionSeverityNetworkedResourceIds.text)({
                            sensitivePermissionActionSeverityNetworkedResourceIds:
                                <InlineEntities
                                    entityIdsOrModels={risk.sensitivePermissionActionSeverityNetworkedResourceIds}
                                    entityTypeName={Contract.TypeNames.AzureResourceGroupResource}
                                    variant="itemCountAndType"/>,
                            translatedSecurityGroupTypeName
                        })),
                networkSecurityGroupRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AzureNetworkNetworkSecurityGroupRulesTable
                        getHighlightColor={
                            (rulePath, opacity) =>
                                _.includes(risk.insecureNetworkSecurityGroupRulePaths, rulePath)
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        :theme.palette.severity(riskModel.risk.severity)
                                    : undefined}
                        rules={networkSecurityGroup.inboundRules}/>,
                    localization.sections.inboundRules(),
                    {
                        expandable: true
                    })
            ]
        });
}