﻿import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "./useOciDefaultResourceInfoItemElements";

export function useOciComputeImageDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const imageModel = resourceModel as Contract.OciComputeImageModel;
    const image = imageModel.entity as Contract.OciComputeImage;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciComputeImageDefinition",
            () => ({
                info: {
                    items: {
                        baseImage: "Base Image",
                        ociManaged: {
                            false: "Customer managed",
                            title: "Management",
                            true: "OCI managed"
                        },
                        operatingSystemType: "Operating System Type",
                        operatingSystemVersion: "Operating System Version",
                        size: "Size",
                        status: {
                            title: "Status",
                            [Contract.TypeNames.OciComputeImageStatus]: {
                                [Contract.OciComputeImageStatus.Available]: "Available",
                                [Contract.OciComputeImageStatus.Deleted]: "Deleted",
                                [Contract.OciComputeImageStatus.Disabled]: "Disabled",
                                [Contract.OciComputeImageStatus.Exporting]: "Exporting",
                                [Contract.OciComputeImageStatus.Importing]: "Importing",
                                [Contract.OciComputeImageStatus.Provisioning]: "Provisioning"
                            }
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="ociManaged"
                        title={localization.info.items.ociManaged.title()}
                        value={
                            image.ociManaged
                                ? localization.info.items.ociManaged.true()
                                : localization.info.items.ociManaged.false()}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status.title()}
                        value={localization.info.items.status[Contract.TypeNames.OciComputeImageStatus][image.status]()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={imageModel.baseImageIdReference}
                        entityTypeName={Contract.TypeNames.OciComputeImage}
                        key="baseImage"
                        title={localization.info.items.baseImage()}/>,
                    <InfoItem
                        key="operatingSystemType"
                        title={localization.info.items.operatingSystemType()}
                        value={image.operatingSystemType}/>,
                    <InfoItem
                        key="operatingSystemVersion"
                        title={localization.info.items.operatingSystemVersion()}
                        value={image.operatingSystemVersion}/>,
                    <InfoItem
                        key="size"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(image.size)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}