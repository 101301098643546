﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsSageMakerNotebookInstanceMetadataServiceVersionTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsSageMakerNotebookInstanceMetadataServiceVersionTranslator",
            () => ({
                [Contract.TypeNames.AwsSageMakerNotebookInstanceMetadataServiceVersion]: {
                    [Contract.AwsSageMakerNotebookInstanceMetadataServiceVersion.V1]: "V1",
                    [Contract.AwsSageMakerNotebookInstanceMetadataServiceVersion.V2]: "V2"
                }
            }));
    return (metadataServiceVersion: Contract.AwsSageMakerNotebookInstanceMetadataServiceVersion) =>
        localization[Contract.TypeNames.AwsSageMakerNotebookInstanceMetadataServiceVersion][metadataServiceVersion]();
}