import { useCallback } from "react";
import { ConfigurationController, Contract, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../common";

type UpdateConfigurationRequest = Omit<Contract.ConfigurationControllerUpdateWorkloadAnalysisRequest, "scopeId">;

export function useUpdateConfiguration() {
    const { scopeNodeModel: { configuration } } = useScopeNavigationViewContext();
    const { workloadAnalysis } = configuration.scopeSections;

    return useCallback(
        async (updateRequest: Partial<UpdateConfigurationRequest>) => {
            const aggregatedRequest: UpdateConfigurationRequest = {
                containerImageRepositoryEnabled: workloadAnalysis.containerImageRepositoryEnabled,
                excludeUnresolvableVulnerabilities: workloadAnalysis.excludeUnresolvableVulnerabilities,
                fileScanEnabled: workloadAnalysis.fileScanEnabled,
                severeVulnerabilityMinSeverity: workloadAnalysis.severeVulnerabilityMinSeverity,
                virtualMachineEnabled: workloadAnalysis.virtualMachine.enabled,
                virtualMachineExclusionTags: workloadAnalysis.virtualMachine.exclusionTags,
                virtualMachineImageEnabled: workloadAnalysis.virtualMachineImage.enabled,
                virtualMachineImageExclusionTags: workloadAnalysis.virtualMachineImage.exclusionTags,
                ...updateRequest
            };

            const updatedConfig =
                await ConfigurationController.updateWorkloadAnalysis(
                    new Contract.ConfigurationControllerUpdateWorkloadAnalysisRequest(
                        aggregatedRequest.containerImageRepositoryEnabled,
                        aggregatedRequest.excludeUnresolvableVulnerabilities,
                        aggregatedRequest.fileScanEnabled,
                        configuration.id,
                        aggregatedRequest.severeVulnerabilityMinSeverity,
                        aggregatedRequest.virtualMachineEnabled,
                        aggregatedRequest.virtualMachineExclusionTags,
                        aggregatedRequest.virtualMachineImageEnabled,
                        aggregatedRequest.virtualMachineImageExclusionTags));

            await scopeNodeModelStore.notify(configuration.id);
            return updatedConfig;
        },
        [configuration]);
}