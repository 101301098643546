import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, Optional, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, SourceToTargetMap } from "../../../common";
import { AzureNetworkLoadBalancerFrontendIpConfiguration, AzureNetworkLoadBalancerRuleProtocol } from "../../../common/controllers/types.generated";

type AzureNetworkLoadBalancerRulesTableProps = {
    loadBalancer: Contract.AzureNetworkLoadBalancer;
};

export function AzureNetworkLoadBalancerRulesTable({ loadBalancer }: AzureNetworkLoadBalancerRulesTableProps) {
    const localization =
        useLocalization(
            "tenants.azure.azureNetworkLoadBalancerRulesTable",
            () => ({
                columns: {
                    backendAddressPoolName: "Backend Pool",
                    ipAddress: "IP Address",
                    name: "Name",
                    ports: "Port",
                    protocol: {
                        title: "Protocol",
                        [Contract.TypeNames.AzureNetworkLoadBalancerRuleProtocol]: {
                            [Contract.AzureNetworkLoadBalancerRuleProtocol.All]: "All",
                            [Contract.AzureNetworkLoadBalancerRuleProtocol.Tcp]: "TCP",
                            [Contract.AzureNetworkLoadBalancerRuleProtocol.Udp]: "UDP"
                        }
                    },
                    targets: "Targets",
                    type: {
                        inboundNat: "Inbound NAT",
                        loadBalancing: "Load Balancing",
                        title: "Type"
                    }
                },
                empty: "No Rules"
            }));

    const items =
        useMemo(
            () => {
                const inboundNatRuleItems =
                    _.map(
                        loadBalancer.inboundNatRules,
                        inboundNatRule =>
                            new AzureNetworkLoadBalancerRulesTableItem(
                                undefined,
                                [],
                                inboundNatRule.backendPort,
                                _.filter([
                                    inboundNatRule.backendVirtualMachineId!,
                                    inboundNatRule.backendVirtualMachineScaleSetId!
                                ]),
                                inboundNatRule.frontendIpConfiguration,
                                inboundNatRule.frontendPort,
                                inboundNatRule.name,
                                inboundNatRule.protocol,
                                localization.columns.type.inboundNat()));
                const loadBalancingRuleItems =
                    _.map(
                        loadBalancer.loadBalancingRules,
                        loadBalancingRule =>
                            new AzureNetworkLoadBalancerRulesTableItem(
                                loadBalancingRule.backendAddressPool.displayName,
                                _.flatMap(_.values(loadBalancingRule.backendAddressPool.virtualNetworkIdToIpAddressesMap)),
                                loadBalancingRule.backendPort,
                                _.concat(
                                    loadBalancingRule.backendAddressPool.virtualMachineIds,
                                    loadBalancingRule.backendAddressPool.virtualMachineScaleSetIds),
                                loadBalancingRule.frontendIpConfiguration,
                                loadBalancingRule.frontendPort,
                                loadBalancingRule.name,
                                loadBalancingRule.protocol,
                                localization.columns.type.loadBalancing()));
                return _(inboundNatRuleItems).
                    concat(loadBalancingRuleItems).
                    orderBy(
                        rulesInfoCardItem => StringHelper.getSortValue(rulesInfoCardItem.name),
                        "asc").
                    value();
            },
            [loadBalancer.id]);

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => items}
            getItemId={(item: AzureNetworkLoadBalancerRulesTableItem) => _.indexOf(items, item).
                toString()}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={AzureNetworkLoadBalancerRulesTableColumnId.Name}
                itemProperty={(item: AzureNetworkLoadBalancerRulesTableItem) => item.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={AzureNetworkLoadBalancerRulesTableColumnId.Type}
                itemProperty={(item: AzureNetworkLoadBalancerRulesTableItem) => item.type}
                title={localization.columns.type.title()}/>
            <DataTableColumn
                id={AzureNetworkLoadBalancerRulesTableColumnId.IpAddress}
                render={
                    ({ item }: DataTableColumnRenderProps<AzureNetworkLoadBalancerRulesTableItem>) => {
                        const frontendIpConfiguration = item.frontendIpConfiguration;
                        return _.isNil(frontendIpConfiguration.publicIpAddressId)
                            ? <InlineItems
                                items={frontendIpConfiguration.privateIpAddress}
                                variant="text"/>
                            : <EntitiesCell
                                entityIdsOrModels={frontendIpConfiguration.publicIpAddressId}
                                entityTypeName={Contract.TypeNames.AzureNetworkPublicIpAddress}/>;
                    }}
                title={localization.columns.ipAddress()}/>
            <DataTableColumn
                id={AzureNetworkLoadBalancerRulesTableColumnId.Protocol}
                itemProperty={(item: AzureNetworkLoadBalancerRulesTableItem) => localization.columns.protocol[Contract.TypeNames.AzureNetworkLoadBalancerRuleProtocol][item.protocol]()}
                title={localization.columns.protocol.title()}/>
            <DataTableColumn
                id={AzureNetworkLoadBalancerRulesTableColumnId.Ports}
                render={
                    ({ item }: DataTableColumnRenderProps<AzureNetworkLoadBalancerRulesTableItem>) =>
                        <SourceToTargetMap
                            source={item.frontendPort}
                            target={item.backendPort}/>}
                title={localization.columns.ports()}/>
            <DataTableColumn
                id={AzureNetworkLoadBalancerRulesTableColumnId.BackendAddressPool}
                itemProperty={(item: AzureNetworkLoadBalancerRulesTableItem) => item.backendAddressPoolDisplayName}
                title={localization.columns.backendAddressPoolName()}/>
            <DataTableColumn
                id={AzureNetworkLoadBalancerRulesTableColumnId.Targets}
                render={
                    ({ item }: DataTableColumnRenderProps<AzureNetworkLoadBalancerRulesTableItem>) =>
                        _.isEmpty(item.backendIpAddresses)
                            ? <EntitiesCell
                                entityIdsOrModels={item.backendResourceIds}
                                entityTypeName={Contract.TypeNames.AzureResource}/>
                            : <InlineItems
                                items={item.backendIpAddresses}
                                variant="itemPlusItemCount"/>}
                title={localization.columns.targets()}/>
        </DataTable>);
}

enum AzureNetworkLoadBalancerRulesTableColumnId {
    BackendAddressPool = "backendAddressPool",
    IpAddress = "IpAddress",
    Name = "name",
    Ports = "ports",
    Protocol = "protocol",
    Targets = "targets",
    Type = "type"
}

class AzureNetworkLoadBalancerRulesTableItem {
    constructor(
        public backendAddressPoolDisplayName: Optional<string>,
        public backendIpAddresses: string[],
        public backendPort: number,
        public backendResourceIds: string[],
        public frontendIpConfiguration: AzureNetworkLoadBalancerFrontendIpConfiguration,
        public frontendPort: number,
        public name: string,
        public protocol: AzureNetworkLoadBalancerRuleProtocol,
        public type: string) {
    }
}