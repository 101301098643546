﻿import { useLocalization, useOrderedWizardContext, WizardFinishItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useBitbucketContext } from "../../..";
import { useAddOrEditContext } from "..";
import { scopeNodeModelStore, scopeSystemEntityModelStore } from "../../../../../../../../../../../../../../../common";

export function FinishItem() {
    const { useNextEffect } = useOrderedWizardContext();
    const { addOrEditOpen } = useBitbucketContext();
    const { organizationModel, updatedFolderScopeNodeModel } = useAddOrEditContext();

    useNextEffect(
        async () => {
            if (updatedFolderScopeNodeModel) {
                await scopeNodeModelStore.notify(updatedFolderScopeNodeModel);
            }

            if (organizationModel) {
                await scopeSystemEntityModelStore.notify(organizationModel);
            }

            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.bitbucket.addOrEditBitbucket.finishItem",
            () => ({
                title: {
                    add: "Your Bitbucket Workspace was added successfully!",
                    edit: "Your Bitbucket Workspace was changed successfully!"
                }
            }));

    return (
        <WizardFinishItem
            title={
                _.isBoolean(addOrEditOpen)
                    ? localization.title.add()
                    : localization.title.edit()}/>);
}