import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntityController, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { InlineAzureKeyVaultVaultCertificatePermissions, InlineAzureKeyVaultVaultKeyPermissions, InlineAzureKeyVaultVaultSecretPermissions } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureKeyVaultVaultRiskContext() {
    return useMemo(
        () => useAzureKeyVaultVaultRiskContext,
        []);
}

function useAzureKeyVaultVaultRiskContext(vaultModel: Contract.AzureKeyVaultVaultModel) {
    const vault = vaultModel.entity as Contract.AzureKeyVaultVault;
    const resourceRiskContext = useGetAzureResourceRiskContext()(vaultModel);
    const [{ secretIds }] =
        useExecuteOperation(
            [useGetAzureKeyVaultVaultRiskContext, vaultModel.id],
            async () => await EntityController.getAzureKeyVaultVaultObjectIds(new Contract.EntityControllerGetAzureKeyVaultVaultObjectIdsRequest(vault.id)));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureKeyVaultVaultRiskContext",
            () => ({
                accessPolicy: {
                    none: "The {{translatedVaultTypeName}} does not have any access policies",
                    permissions: "The {{translatedVaultTypeName}} is using vault access policies granting {{permissions}} to {{principals}}"
                },
                secrets: "The {{translatedVaultTypeName}} contains {{secrets}}",
                softDeleteEnabled: {
                    false: "The {{translatedVaultTypeName}} has **Soft Delete** disabled",
                    true: "The {{translatedVaultTypeName}} has **Soft Delete** enabled"
                },
                vaultObjects: {
                    empty: "The {{translatedVaultTypeName}} contains no objects",
                    some: "The {{translatedVaultTypeName}} contains objects"
                }
            }));
    const translatedVaultTypeName =
        entityTypeNameTranslator(
            vault.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const [accessPolicyCertificatePermissions, accessPolicyKeyPermissions, accessPolicyPrincipalIds, accessPolicySecretPermissions] =
        useMemo(
            () => {
                const accessPolicyCertificatePermissions =
                    _(vault.accessPolicies).
                        flatMap(accessPolicy => accessPolicy.certificatePermissions).
                        uniq().
                        value();
                const accessPolicyKeyPermissions =
                    _(vault.accessPolicies).
                        flatMap(accessPolicy => accessPolicy.keyPermissions).
                        uniq().
                        value();
                const accessPolicySecretPermissions =
                    _(vault.accessPolicies).
                        flatMap(accessPolicy => accessPolicy.secretPermissions).
                        uniq().
                        value();
                const accessPolicyPrincipalIds =
                    _(vault.accessPolicies).
                        map(accessPolicy => accessPolicy.principalReference.id!).
                        filter().
                        value();
                return [accessPolicyCertificatePermissions, accessPolicyKeyPermissions, accessPolicyPrincipalIds, accessPolicySecretPermissions];
            },
            [vault]);

    return {
        ...resourceRiskContext,
        accessPolicyPermissions: [
            _.isEmpty(accessPolicyCertificatePermissions) &&
            _.isEmpty(accessPolicyCertificatePermissions) &&
            _.isEmpty(accessPolicyCertificatePermissions)
                ? new RiskDefinitionContextItem(localization.accessPolicy.none({ translatedVaultTypeName }))
                : undefined,
            _.isEmpty(accessPolicyCertificatePermissions)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.accessPolicy.permissions({
                        permissions:
                            <InlineAzureKeyVaultVaultCertificatePermissions
                                permissions={accessPolicyCertificatePermissions}
                                variant="itemCountAndType"/>,
                        principals:
                            <InlineEntities
                                entityIdsOrModels={accessPolicyPrincipalIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                variant="itemCountAndType"/>,
                        translatedVaultTypeName
                    })),
            _.isEmpty(accessPolicyKeyPermissions)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.accessPolicy.permissions({
                        permissions:
                            <InlineAzureKeyVaultVaultKeyPermissions
                                permissions={accessPolicyKeyPermissions}
                                variant="itemCountAndType"/>,
                        principals:
                            <InlineEntities
                                entityIdsOrModels={accessPolicyPrincipalIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                variant="itemCountAndType"/>,
                        translatedVaultTypeName
                    })),
            _.isEmpty(accessPolicySecretPermissions)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.accessPolicy.permissions({
                        permissions:
                            <InlineAzureKeyVaultVaultSecretPermissions
                                permissions={accessPolicySecretPermissions}
                                variant="itemCountAndType"/>,
                        principals:
                            <InlineEntities
                                entityIdsOrModels={accessPolicyPrincipalIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                variant="itemCountAndType"/>,
                        translatedVaultTypeName
                    }))
        ],
        allNetworkAccess: resourceRiskContext.getAllNetworkAccessContextItem(vault.allNetworkAccess),
        secrets:
            _.isEmpty(secretIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.secrets({
                        secrets:
                            <InlineEntities
                                entityIdsOrModels={secretIds}
                                entityTypeName={Contract.TypeNames.AzureKeyVaultVaultSecret}
                                variant="itemCountAndType"/>,
                        translatedVaultTypeName
                    })),
        softDeleteEnabled:
            new RiskDefinitionContextItem(
                (_.isNil(vault.softDelete)
                    ? localization.softDeleteEnabled.false
                    : localization.softDeleteEnabled.true)({ translatedVaultTypeName })),
        vaultObjectsExist:
            new RiskDefinitionContextItem(
                (vaultModel.vaultObjectCount > 0
                    ? localization.vaultObjects.some
                    : localization.vaultObjects.empty)({ translatedVaultTypeName })),
        wideRangeInboundSubnets: resourceRiskContext.getWideRangeInboundSubnetsContextItem(vaultModel.wideRangeFirewallRuleSubnets)
    };
}