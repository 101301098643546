import _, { Function0 } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, RiskTypeGroups, StorageHelper, SummaryDashboardContext, useDashboardContext, WidgetDefinition } from "../../../../../../..";
import { Risks, RisksWidgetSeverityMultiSelect } from "../components";

export function useGetRisksDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetRisksDefinition",
            () => ({
                helpText: "See a high-level overview of all your open findings. Findings represent at-risk identities and resources, including hard-to-spot toxic combinations. View all findings that are currently open, the number of different finding types (categorized by the policy that triggered them), and a breakdown of findings/types by category.",
                title: "Open Findings"
            }));

    const severityToRiskPolicyCategoryToCountMap = summary.severityToRiskPolicyCategoryToCountMap;
    const riskSeverityToCountMap =
        useMemo(
            () =>
                _.mapValues(
                    severityToRiskPolicyCategoryToCountMap,
                    riskPolicyCategoryToCountMap =>
                        _(riskPolicyCategoryToCountMap).
                            values().
                            sum()),
            [severityToRiskPolicyCategoryToCountMap]);
    const [selectedSeverities, setSelectedSeverities] =
        useState(
            () => {
                const riskSeveritiesJson = StorageHelper.customerDashboardRisksWidgetSeverities.getValue();
                return _.isNil(riskSeveritiesJson)
                    ? riskSeverityToCountMap[Contract.Severity.Critical] > 0
                        ? [
                            Contract.Severity.Critical,
                            Contract.Severity.High
                        ]
                        : []
                    : JSON.parse(riskSeveritiesJson) as Contract.Severity[];
            });

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <Risk selectedSeverities={selectedSeverities}/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                topElement:
                    <RisksWidgetSeverityMultiSelect
                        selectedSeverities={selectedSeverities}
                        setSelectedSeverities={setSelectedSeverities}
                        storageItem={StorageHelper.customerDashboardRisksWidgetSeverities}/>
            }
        }),
        [localization, selectedSeverities]);
}

type RiskProps = {
    selectedSeverities: Contract.Severity[];
};

function Risk({ selectedSeverities }: RiskProps) {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const getPolicyCategoryUrl =
        useCallback(
            (riskPolicyCategory: Contract.RiskPolicyCategory, severities: Contract.Severity[]) =>
                CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                    Contract.RisksView.Open,
                    {
                        riskPolicyCategory,
                        severity: severities
                    },
                    RiskTypeGroups.RiskPolicyType),
            []);
    const getSeverityUrl =
        useCallback(
            (severity: Contract.Severity) =>
                CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                    Contract.RisksView.Open,
                    { severity: [severity] },
                    RiskTypeGroups.RiskPolicyType),
            []);

    return (
        <Risks
            getPolicyCategoryUrl={getPolicyCategoryUrl}
            getSeverityUrl={getSeverityUrl}
            selectedSeverities={selectedSeverities}
            severityToRiskPolicyCategoryToCountMap={summary.severityToRiskPolicyCategoryToCountMap}/>);
}