import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { OperatorSelector, useTheme } from "../../../../../../../../../../../../../../../../../common";

type OperatorProps = {
    not: boolean;
    onChange?: (not: boolean) => void;
    readOnly?: boolean;
};

enum OperatorType {
    Is = "is",
    Not = "not"
}

export function Operator({ not, onChange, readOnly }: OperatorProps) {
    const [value, setValue] =
        React.useState<OperatorType>(
            not
                ? OperatorType.Not
                : OperatorType.Is);

    useEffect(
        () =>
            setValue(
                not
                    ? OperatorType.Not
                    : OperatorType.Is),
        [not]);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.addOrEdit.projectConfigurationRules.conditions.condition.operator",
            () => ({
                items: {
                    [OperatorType.Is]: "is",
                    [OperatorType.Not]: "is not"
                }
            }));

    const theme = useTheme();
    return readOnly
        ? <Typography>
            {localization.items[value]()}
        </Typography>
        : <OperatorSelector
            dropdownIcon={false}
            operators={[
                OperatorType.Is,
                OperatorType.Not
            ]}
            operatorTranslator={operator => localization.items[operator as OperatorType]?.()}
            selectedOperator={value}
            sx={{
                "&:hover": {
                    borderBottomColor: theme.palette.borders.hoverFocus
                },
                borderBottomColor: theme.palette.borders.primary,
                color: theme.palette.text.secondary,
                marginLeft: theme.spacing(0.5)
            }}
            onSelectedOperatorChanged={
                item => {
                    setValue(item);
                    onChange?.(item === OperatorType.Not);
                }}/>;
}