import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function LaceworkIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M11.9515 24C11.9515 24 1 21.6233 1 10.9049V0L5.35728 4.19417V10.9049C5.35728 17.266 11.9515 19.433 11.9515 19.433V24Z" fill="url(#paint0_linear_58343_2032)"/>
            <path d="M11.9515 24C11.9515 24 22.9029 21.6233 22.9029 10.9049V0L18.5456 4.19417V10.9049C18.5456 17.266 11.9515 19.433 11.9515 19.433V24Z" fill="url(#paint1_linear_58343_2032)"/>
            <path d="M18.5456 4.19417H5.35728L1 0H22.8796L18.5456 4.19417Z" fill="url(#paint2_linear_58343_2032)"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_58343_2032"
                    x1="-4.73977"
                    x2="-1.3961"
                    y1="0.616194"
                    y2="12.2004">
                    <stop stopColor="#01023B"/>
                    <stop
                        offset="1"
                        stopColor="#FF646C"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_58343_2032"
                    x1="26.1399"
                    x2="22.9567"
                    y1="2.76212"
                    y2="13.7894">
                    <stop stopColor="#01023B"/>
                    <stop
                        offset="0.24"
                        stopColor="#071573"/>
                    <stop
                        offset="0.49"
                        stopColor="#0D27AA"/>
                    <stop
                        offset="0.72"
                        stopColor="#1135D2"/>
                    <stop
                        offset="0.89"
                        stopColor="#143DEA"/>
                    <stop
                        offset="1"
                        stopColor="#1540F3"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint2_linear_58343_2032"
                    x1="1"
                    x2="22.89"
                    y1="2.09823"
                    y2="2.09823">
                    <stop
                        offset="0.2"
                        stopColor="#00F8F8"/>
                    <stop
                        offset="0.3"
                        stopColor="#00F4F8"/>
                    <stop
                        offset="0.4"
                        stopColor="#02E7F8"/>
                    <stop
                        offset="0.51"
                        stopColor="#04D3F7"/>
                    <stop
                        offset="0.61"
                        stopColor="#08B6F6"/>
                    <stop
                        offset="0.72"
                        stopColor="#0C90F5"/>
                    <stop
                        offset="0.83"
                        stopColor="#1163F4"/>
                    <stop
                        offset="0.9"
                        stopColor="#1540F3"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}