﻿import { Optional, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsEc2InstanceMetadataServiceVersionTranslator } from "../../../../../../hooks";

export function useAwsEc2InstanceMetadataServiceInfoItems(metadataService: Optional<Contract.AwsEc2InstanceMetadataService>) {
    const ec2InstanceMetadataServiceVersionTranslator = useAwsEc2InstanceMetadataServiceVersionTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2InstanceMetadataServiceInfoItems",
            () => ({
                accessibilityEnabled:
                    {
                        false: "Disabled",
                        title: "Metadata Accessible",
                        true: "Enabled"
                    },
                tokenHopMaxCount: "Metadata Token Hop Limit",
                version: "Metadata Version"
            }));
    return [
        <InfoItem
            key="metadataServiceAccessibility"
            title={localization.accessibilityEnabled.title()}
            value={
                metadataService?.enabled
                    ? localization.accessibilityEnabled.true()
                    : localization.accessibilityEnabled.false()
            }/>,
        metadataService?.enabled
            ? <InfoItem
                key="metadataServiceVersion"
                title={localization.version()}
                value={ec2InstanceMetadataServiceVersionTranslator(metadataService.version)}/>
            : undefined,
        metadataService?.enabled
            ? <InfoItem
                key="metadataServiceTokenHopLimit"
                title={localization.tokenHopMaxCount()}
                value={metadataService.tokenHopMaxCount}/>
            : undefined
    ];
}