import React from "react";
import { Link, ToggleList, ToggleListItem, useLocalization } from "@infrastructure";
import { useAddOrEditContext, useSetAddOrEditContext } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, FeatureHelper, LicensingHelper, useTheme } from "../../../../../../../../../../../../../../../common";

export function PermissionsItem() {
    const { permissionTypes } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit.permissionsItem",
            () => ({
                permissions: {
                    containerImageRepositoryAnalysis: {
                        subtitle: "Scan container registries for vulnerabilities and misconfigurations. For more information about required permissions, see the {{docsLink}}",
                        title: "ECR Scanning (Workload Protection)"
                    },
                    dataAnalysis: {
                        subtitle: "Scan and classify cloud resources. For more information about enabling data protection and required permissions, see the {{docsLink}}",
                        title: "Data Resources Scanning (Data Protection)"
                    },
                    docsLink: "documentation",
                    instanceAnalysis: {
                        subtitle: "Scan EC2 Instances for vulnerabilities and misconfigurations. For more information about required permissions, see the {{docsLink}}",
                        title: "EC2 Instance Scanning (Workload Protection)"
                    },
                    instanceAndImageAnalysis: {
                        subtitle: "Scan EC2 instances and AMI (private and public machine images) for vulnerabilities and misconfigurations. For more information about required permissions, see the {{docsLink}}",
                        title: "EC2 Instance and AMI Scanning (Workload Protection)"
                    },
                    instanceAndInternalImageAnalysis: {
                        subtitle: "Scan EC2 instances and internal AMIs for vulnerabilities and misconfigurations. For more information about required permissions, see the {{docsLink}}",
                        title: "EC2 Instance and Internal AMI Scanning (Workload Protection)"
                    },
                    read: {
                        subtitle: "Gain full visibility for all cloud assets, including information about permissions, account usage, and security configurations. Additional permissions may be required to read the CloudTrail for the onboarded account. For more information about required permissions, see the {{docsLink}}",
                        title: "Monitoring (read-only)"
                    },
                    title: "Choose which features to enable, keeping in mind that each feature requires different permissions. You can modify your selection after the initial onboarding",
                    write: {
                        subtitle: "Allow Tenable to make changes in your environment. This lets you automatically remediate findings with one click. For more information about required permissions, see the {{docsLink}}",
                        title: "Remediation (read-write)"
                    }
                }
            }));

    const workloadAnalysisLocalization =
        !FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
            ? localization.permissions.instanceAnalysis
            : FeatureHelper.enabled(Contract.FeatureName.AwsEc2ExternalMachineImageAnalysisEnabled)
                ? localization.permissions.instanceAndImageAnalysis
                : localization.permissions.instanceAndInternalImageAnalysis;

    const theme = useTheme();
    return (
        <ToggleList
            selectedValues={permissionTypes!}
            sx={{ maxWidth: theme.spacing(80) }}
            title={localization.permissions.title()}
            onSelectedValuesChanged={
                permissionTypes =>
                    setAddOrEditContext(
                        addOrEditContext => ({
                            ...addOrEditContext,
                            permissionTypes
                        }))}>
            <ToggleListItem
                disabled={true}
                subtitle={
                    localization.permissions.read.subtitle({
                        docsLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsTenableReadonlyPolicyRelativeUrl()}
                                variant="external">
                                {localization.permissions.docsLink()}
                            </Link>
                    })}
                title={localization.permissions.read.title()}
                value={Contract.CloudProviderTenantPermissionType.Read}/>
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                <ToggleListItem
                    subtitle={
                        localization.permissions.dataAnalysis.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsDataProtectionPolicyRelativeUrl()}
                                    variant="external">
                                    {localization.permissions.docsLink()}
                                </Link>
                        })}
                    title={localization.permissions.dataAnalysis.title()}
                    value={Contract.CloudProviderTenantPermissionType.DataAnalysis}/>}
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                <ToggleListItem
                    subtitle={
                        workloadAnalysisLocalization.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsWorkloadProtectionPolicyRelativeUrl()}
                                    variant="external">
                                    {localization.permissions.docsLink()}
                                </Link>
                        })
                    }
                    title={workloadAnalysisLocalization.title()}
                    value={Contract.CloudProviderTenantPermissionType.WorkloadAnalysis}/>
            }
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                <ToggleListItem
                    subtitle={
                        localization.permissions.containerImageRepositoryAnalysis.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsWorkloadProtectionPolicyRelativeUrl()}
                                    variant="external">
                                    {localization.permissions.docsLink()}
                                </Link>
                        })
                    }
                    title={localization.permissions.containerImageRepositoryAnalysis.title()}
                    value={Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis}/>}
            <ToggleListItem
                subtitle={
                    localization.permissions.write.subtitle({
                        docsLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsTenableManagementPolicyRelativeUrl()}
                                variant="external">
                                {localization.permissions.docsLink()}
                            </Link>
                    })}
                title={localization.permissions.write.title()}
                value={Contract.CloudProviderTenantPermissionType.Write}/>
        </ToggleList>);
}