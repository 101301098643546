﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpSqlInstanceDatabaseVersionTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpSqlInstanceDatabaseVersionTranslator",
            () => ({
                [Contract.TypeNames.GcpSqlInstanceDatabaseVersion]: {
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_5_1]: "MySQL 5.1",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_5_5]: "MySQL 5.5",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_5_6]: "MySQL 5.6",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_5_7]: "MySQL 5.7",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0]: "MySQL 8.0",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_18]: "MySQL 8.0.18",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_26]: "MySQL 8.0.26",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_27]: "MySQL 8.0.27",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_28]: "MySQL 8.0.28",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_29]: "MySQL 8.0.29",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_30]: "MySQL 8.0.30",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_31]: "MySQL 8.0.31",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_32]: "MySQL 8.0.32",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_33]: "MySQL 8.0.33",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_34]: "MySQL 8.0.34",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_35]: "MySQL 8.0.35",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_36]: "MySQL 8.0.36",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_37]: "MySQL 8.0.37",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_39]: "MySQL 8.0.39",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_40]: "MySQL 8.0.40",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_0_41]: "MySQL 8.0.41",
                    [Contract.GcpSqlInstanceDatabaseVersion.Mysql_8_4]: "MySQL 8.4",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_9_6]: "PostgreSQL 9.6",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_11]: "PostgreSQL 11",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_10]: "PostgreSQL 10",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_12]: "PostgreSQL 12",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_13]: "PostgreSQL 13",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_14]: "PostgreSQL 14",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_15]: "PostgreSQL 15",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_16]: "PostgreSQL 16",
                    [Contract.GcpSqlInstanceDatabaseVersion.Postgres_17]: "PostgreSQL 17",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2017_Standard]: "SQL Server 2017 Standard",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2017_Enterprise]: "SQL Server 2017 Enterprise",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2017_Express]: "SQL Server 2017 Express",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2017_Web]: "SQL Server 2017 Web",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2019_Standard]: "SQL Server 2019 Standard",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2019_Enterprise]: "SQL Server 2019 Enterprise",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2019_Express]: "SQL Server 2019 Express",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2019_Web]: "SQL Server 2019 Web",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2022_Standard]: "SQL Server 2022 Standard",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2022_Enterprise]: "SQL Server 2022 Enterprise",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2022_Express]: "SQL Server 2022 Express",
                    [Contract.GcpSqlInstanceDatabaseVersion.Sqlserver_2022_Web]: "SQL Server 2022 Web"
                }
            }));
    return (status: Contract.GcpSqlInstanceDatabaseVersion) =>
        localization[Contract.TypeNames.GcpSqlInstanceDatabaseVersion][status]();
}