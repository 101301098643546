import _, { Dictionary } from "lodash";
import React, { MutableRefObject, ReactNode, useEffect, useMemo, useRef } from "react";
import { AppError, buildRoute, Loading, makeContextProvider, NavigationView, NavigationViewItem, Optional, setUrlRoute, StringHelper, useLocalization, useRoute, WelcomeView } from "@infrastructure";
import { Contract, Scope, ScopeHelper, ScopeNode, scopeNodeModelStore, tenantModelStore, TypeHelper, UserHelper, useScopeNameTranslator, useTheme } from "..";
import { useLayoutOptions } from "../../common";

export class ScopeNavigationViewContext {
    constructor(
        private relativeTemplatePathToLastViewMapRef: MutableRefObject<Dictionary<string>>,
        private scopeNodeRef: MutableRefObject<ScopeNode>,
        private templatePath: string) {
    }

    public get scopeNodeModel() {
        return this.scopeNodeRef.current.scopeNodeModel;
    }

    public setRoute =
        (scopeId: string) =>
            setUrlRoute(
                this.templatePath,
                {
                    scopeId: encodeURIComponent(scopeId)
                });

    public useViewRoute =
        <TView extends string>(relativeTemplatePath: string, views: TView[], defaultView = false): [TView, (view: TView) => void] => {
            const viewTemplatePath = `${this.templatePath}/${relativeTemplatePath}`;
            const scopeId = this.scopeNodeRef.current.scopeNodeModel.configuration.id;
            let { view } = useRoute(viewTemplatePath);
            if (!_.isNil(view) &&
                !_.includes(views, view)) {
                view = undefined!;
            }

            if (_.isNil(view) && !defaultView) {
                view =
                    !_.isNil(this.relativeTemplatePathToLastViewMapRef.current[relativeTemplatePath]) &&
                    _.includes(views, this.relativeTemplatePathToLastViewMapRef.current[relativeTemplatePath])
                        ? this.relativeTemplatePathToLastViewMapRef.current[relativeTemplatePath] as TView
                        : views[0];
                setUrlRoute(
                    viewTemplatePath,
                    {
                        scopeId: encodeURIComponent(scopeId),
                        view
                    },
                    { appendBrowserHistory: false });
            }

            this.relativeTemplatePathToLastViewMapRef.current[relativeTemplatePath] = view;
            return [
                view as TView,
                (view: TView) =>
                    setUrlRoute(
                        viewTemplatePath,
                        {
                            scopeId: encodeURIComponent(scopeId),
                            view
                        })
            ];
        };
}

export const [useScopeNavigationViewContext, , useScopeNavigationViewContextProvider] = makeContextProvider<ScopeNavigationViewContext>();

type ScopeNavigationViewProps = {
    children: ReactNode | ((scopeNodeModel: Contract.ScopeNodeModel) => ReactNode);
    disabledScopeIds?: string[];
    emptyMessage?: string;
    getItemTooltip?: (scopeNode: ScopeNode) => ReactNode;
    hideNonPermittedScopes?: boolean;
    identityPermissions?: Contract.IdentityPermission[];
    initialExpanded?: (scopeNodeModel: Contract.ScopeNodeModel) => boolean;
    layout?: "global" | "view" | "viewTitle";
    onScopeChanged?: (scopeNodeModel: Contract.ScopeNodeModel) => void;
    rootFolderId?: string;
    scopeSelectionStrategy?: "none" | "selectFirst";
    search?: boolean;
    templatePath: string;
    tenantTypes?: Contract.TenantType[];
    variant?: "folders" | "tenants";
};

export function ScopeNavigationView({ children, disabledScopeIds = [], emptyMessage, getItemTooltip, hideNonPermittedScopes = false, identityPermissions = [Contract.IdentityPermission.SecurityRead], initialExpanded, layout, rootFolderId = ScopeHelper.customerId, search = true, templatePath, tenantTypes, variant = "tenants", ...props }: ScopeNavigationViewProps) {
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            layout !== "viewTitle");
    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const filteredActiveTenantModels =
        tenantModelStore.useGetFilteredActiveTenants(
            _.intersection(
                tenantTypes,
                scopeNodeMap[ScopeHelper.customerId].getTenantTypes()));

    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "common.scopeNavigationView",
            () => ({
                empty: "Permitted scopes are currently filtered out"
            }));

    const theme = useTheme();
    const [itemMap, items] =
        useMemo(
            () => {
                const itemMap: Dictionary<NavigationViewItem> = {};
                const itemsScopeNodeMap =
                    variant === "tenants"
                        ? ScopeHelper.getScopeTree(
                            scopeNodeMap,
                            _(activeTenantModels).
                                filter(
                                    activeTenantModel =>
                                        !TypeHelper.extendOrImplement(activeTenantModel.configuration.typeName, Contract.TypeNames.CloudProviderTenantConfiguration) &&
                                        !TypeHelper.extendOrImplement(activeTenantModel.configuration.typeName, Contract.TypeNames.IdentityProviderTenantConfiguration) &&
                                        _.includes(tenantTypes, activeTenantModel.tenantType)).
                                map(activeTenantModel => activeTenantModel.configuration.id).
                                concat(
                                    _.map(
                                        filteredActiveTenantModels,
                                        filteredActiveTenantModel => filteredActiveTenantModel.configuration.id)).
                                filter(activeTenantId => UserHelper.hasScopePermissions(activeTenantId, ...identityPermissions)).
                                value())
                        : scopeNodeMap;

                function createScopeItem(scopeId: string): Optional<NavigationViewItem> {
                    const scopeNode = itemsScopeNodeMap[scopeId];
                    const items =
                        _.isEmpty(scopeNode.scopeNodes)
                            ? undefined
                            : _(scopeNode.scopeNodes).
                                orderBy([
                                    scopeNode => !ScopeHelper.isFolder(scopeNode.scopeNodeModel),
                                    scopeNode => StringHelper.getSortValue(scopeNode.scopeNodeModel.configuration.name)
                                ]).
                                filter(
                                    scopeNode =>
                                        (variant === "tenants" ||
                                            ScopeHelper.isFolder(scopeNode.scopeNodeModel))).
                                map(scopeNode => createScopeItem(scopeNode.scopeNodeModel.configuration.id)).
                                filter().
                                as<NavigationViewItem>().
                                value();
                    const path =
                        !_.includes(disabledScopeIds, scopeNode.scopeNodeModel.configuration.id) &&
                        _([scopeNode.scopeNodeModel.configuration.id]).
                            concatIf(
                                variant == "folders",
                                scopeNode.scopeIds).
                            some(scopeId => UserHelper.hasScopePermissions(scopeId, ...identityPermissions))
                            ? buildRoute(
                                templatePath,
                                {
                                    scopeId: encodeURIComponent(scopeNode.scopeNodeModel.configuration.id)
                                })
                            : undefined;

                    if (hideNonPermittedScopes &&
                        _.isNil(path) &&
                        _.isEmpty(items)) {
                        return undefined;
                    }

                    const itemSearchText =
                        ScopeHelper.isFolder(scopeNode.scopeNodeModel)
                            ? scopeNameTranslator(scopeNode.scopeNodeModel.configuration.id)
                            : `${scopeNameTranslator(scopeNode.scopeNodeModel.configuration.id)} ${scopeNode.scopeNodeModel.configuration.id}`;

                    const item =
                        new NavigationViewItem(
                            scopeNode.scopeNodeModel.configuration.id,
                            itemSearchText,
                            <Scope
                                scopeId={scopeNode.scopeNodeModel.configuration.id}
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontWeight:
                                        scopeNode.scopeNodeModel.type === Contract.ScopeType.Customer ||
                                        ScopeHelper.isRootFolder(scopeNode.scopeNodeModel)
                                            ? "bold"
                                            : undefined
                                }}
                                variant="treeNode"/>,
                            {
                                disabled: _.isNil(path),
                                initialExpanded:
                                    scopeNode.scopeNodeModel.type === Contract.ScopeType.Customer ||
                                    ScopeHelper.isRootFolder(scopeNode.scopeNodeModel) ||
                                    initialExpanded?.(scopeNode.scopeNodeModel),
                                items,
                                path,
                                tooltip: getItemTooltip?.(scopeNode)
                            });

                    itemMap[item.id] = item;

                    return item;
                }

                let items: NavigationViewItem[] = [];
                if (_.has(itemsScopeNodeMap, rootFolderId)) {
                    const rootItem = createScopeItem(rootFolderId);
                    if (!_.isNil(rootItem)) {
                        items = [rootItem];
                    }
                }

                return [itemMap, items];
            },
            [filteredActiveTenantModels, templatePath, scopeNodeModels, theme]);

    return _.isEmpty(items)
        ? <WelcomeView title={emptyMessage ?? localization.empty()}/>
        : <Core
            itemMap={itemMap}
            items={items}
            layout={layout}
            scopeNodeMap={scopeNodeMap}
            search={search}
            templatePath={templatePath}
            {...props}>
            {children}
        </Core>;
}

type CoreProps =
    Omit<ScopeNavigationViewProps, "tenantTypes"> & {
        itemMap: Dictionary<NavigationViewItem>;
        items: NavigationViewItem[];
        scopeNodeMap: Dictionary<ScopeNode>;
        search?: boolean;
    };

function Core({ children, itemMap, items, layout = "viewTitle", onScopeChanged, scopeNodeMap, scopeSelectionStrategy = "selectFirst", search = false, templatePath }: CoreProps) {
    let { scopeId } = useRoute(templatePath);
    if ((_.isNil(scopeId) ||
        _.isNil(itemMap[scopeId]?.options?.path) &&
        scopeSelectionStrategy === "selectFirst")) {
        const findActiveItem =
            (item: NavigationViewItem): NavigationViewItem => {
                if (_.isNil(item.options?.path)) {
                    for (const childItem of item.options?.items ?? []) {
                        const activeChildItem = findActiveItem(childItem as NavigationViewItem);
                        if (!_.isNil(activeChildItem)) {
                            return activeChildItem;
                        }
                    }

                    throw new AppError("Cannot find active item");
                } else {
                    return item;
                }
            };

        const defaultItem = findActiveItem(items[0]);
        scopeId = defaultItem.id;
        setUrlRoute(
            templatePath,
            {
                scopeId: encodeURIComponent(scopeId)
            },
            { appendBrowserHistory: false });
    }

    useEffect(
        () => {
            onScopeChanged?.(scopeNodeMap[scopeId].scopeNodeModel);
        },
        [scopeId]);

    const relativeTemplatePathToLastViewMapRef = useRef<Dictionary<string>>({});
    const scopeNodeRef = useRef(scopeNodeMap[scopeId]);
    scopeNodeRef.current = scopeNodeMap[scopeId];
    const [, , ContextProvider] =
        useScopeNavigationViewContextProvider(
            () =>
                new ScopeNavigationViewContext(
                    relativeTemplatePathToLastViewMapRef,
                    scopeNodeRef,
                    templatePath),
            [scopeId]);

    const scopeNavigationProps =
        useMemo(
            () => ({
                items,
                itemsCount: _.size(itemMap),
                paged: true,
                search,
                selectedItemId: scopeId
            }),
            [items, search, scopeId]);

    const scopeNavigationOptions =
        useMemo(
            () => ({
                scopeNavigationOptions:
                    layout === "viewTitle"
                        ? { ...scopeNavigationProps }
                        : undefined
            }),
            [scopeNavigationProps, layout]);

    useLayoutOptions(scopeNavigationOptions);

    const renderChildren =
        () =>
            _.isFunction(children)
                ? children(scopeNodeRef.current.scopeNodeModel)
                : children;

    return (
        <ContextProvider>
            {layout === "viewTitle" || layout === "global"
                ? <Loading>
                    {renderChildren()}
                </Loading>
                : <NavigationView
                    {...scopeNavigationProps}
                    variant="tree">
                    {renderChildren()}
                </NavigationView>}
        </ContextProvider>);
}