import { DataTableSummaryProps, useLocalization } from "@infrastructure";
import { Divider, Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { Contract, DataCategories, dataCategories, DataSensitivities, dataSensitivities, SummarySectionCard, useTheme } from "../../../../../../../../../../../../common";
import { DataAnalysisTableSummaryResourcesSection } from "./components";

export function DataAnalysisTableSummarySection({ data, filterActionsRef, filterMap: initialFilterMap, loading }: DataTableSummaryProps<Contract.DataAnalysisResourceModelSummary>) {
    const filterMap = useRef({ ...initialFilterMap });

    useEffect(
        () => {
            if (!_.isEqual(filterMap, initialFilterMap)) {
                filterMap.current = { ...initialFilterMap };
            }
        },
        [initialFilterMap]);

    function onClick(filterKey: string, value: string | string[]) {
        if (!filterActionsRef.current) {
            return;
        }

        filterActionsRef.current.set(
            filterKey,
            existingFilter => {
                const { values } = _.defaults(existingFilter, { values: [] });
                const newValues = new Set<string>(values);
                if (_.isArray(value)) {
                    const valuesIntersection =
                        _.intersection(
                            values,
                            value);

                    _.forEach(
                        value,
                        val => {
                            if (_.size(valuesIntersection) === _.size(value)) {
                                newValues.delete(val);
                            } else {
                                newValues.add(val);
                            }
                        });
                } else {
                    if (newValues.has(value)) {
                        newValues.delete(value);
                    } else {
                        newValues.add(value);
                    }
                }

                const filterValue =
                    _.isEmpty(newValues)
                        ? undefined
                        : {
                            ...existingFilter,
                            values: _.toArray(newValues)
                        };

                filterMap.current = {
                    ...filterMap,
                    [filterKey]: filterValue
                };

                return filterValue;
            });
    }

    function getFilterActive(filterKey: string, valueOrValues: string | string[]) {
        if (!filterMap.current?.[filterKey]) {
            return;
        }

        const { values: filterMapValues } = filterMap.current[filterKey];
        const values = _.concat([], valueOrValues);
        const existingValues =
            _.intersection(
                filterMapValues,
                values);

        return _.size(existingValues) === _.size(values);
    }

    const dataCategoryToCountMap =
        useMemo(
            () =>
                _.defaults(
                    {},
                    data.dataCategoryToCountMap,
                    _.mapValues(
                        Contract.DataCategory,
                        () => 0)) as NonNullable<typeof data.dataCategoryToCountMap>,
            [data.dataCategoryToCountMap]);

    const dataSensitivityToCountMap =
        useMemo(
            () =>
                _.defaults(
                    {},
                    data.dataSensitivityToCountMap,
                    _.mapValues(
                        Contract.DataSensitivity,
                        () => 0)) as NonNullable<typeof data.dataSensitivityToCountMap>,
            [data.dataSensitivityToCountMap]);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useDataAnalysisResourceDefinition.dataAnalysisTableSummarySection",
            () => ({
                dataResources: "Data Resources",
                resourcesByDataCategory: "Resources by Data Category",
                resourcesByDataSensitivity: "Resources by Data Sensitivity"
            }));

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            divider={
                <Divider
                    flexItem={true}
                    orientation="vertical"
                    sx={{ margin: `0 clamp(${theme.spacing(3)}, 2.75%, ${theme.spacing(8)})` }}/>}
            width="100%">
            <SummarySectionCard
                sx={{
                    flexShrink: 0,
                    width: 280
                }}
                title={localization.dataResources()}>
                <DataAnalysisTableSummaryResourcesSection
                    countLoading={loading}
                    dataSensitiveResourceCount={data.dataSensitiveResourceCount}
                    getFilterActive={getFilterActive}
                    monitoredResourceCount={data.monitoredResourceCount}
                    notMonitoredResourceCount={data.notMonitoredResourceCount}
                    onClick={onClick}/>
            </SummarySectionCard>
            <SummarySectionCard title={localization.resourcesByDataCategory()}>
                <DataCategories
                    categories={dataCategories}
                    categoryToCountMap={dataCategoryToCountMap}
                    countLoading={loading}
                    getCategoryActive={
                        category =>
                            getFilterActive?.(
                                Contract.EntityModelProperty.DataAnalysisResourceDataCategories,
                                category)}
                    onClick={
                        category =>
                            onClick(
                                Contract.EntityModelProperty.DataAnalysisResourceDataCategories,
                                category)}/>
            </SummarySectionCard>
            <SummarySectionCard title={localization.resourcesByDataSensitivity()}>
                <DataSensitivities
                    countLoading={loading}
                    getSensitivityActive={
                        sensitivity =>
                            getFilterActive?.(
                                Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities,
                                sensitivity)}
                    sensitivities={dataSensitivities}
                    sensitivityToCountMap={dataSensitivityToCountMap}
                    onClick={
                        sensitivity =>
                            onClick(
                                Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities,
                                sensitivity)}/>
            </SummarySectionCard>
        </Stack>);
}