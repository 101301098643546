import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpNotebooksInstanceRiskContext } from "../contexts";

export function useGcpNotebooksInstanceRootAccessEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpNotebooksInstanceRootAccessEnabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpNotebooksInstanceModel;

    const getGcpNotebooksInstanceRiskContext = useGetGcpNotebooksInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpNotebooksInstanceRootAccessEnabledRiskDefinition",
            () => ({
                description: "{{instance}} root access is enabled",
                sections: {
                    resolution: {
                        step1: "Select the **SOFTWARE AND SECURITY** tab",
                        step2: "Under the **Modify software and security configuration** section,  uncheck the **Root access to the instance** checkbox",
                        step3: "Click **SUBMIT**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const instanceRiskContext = getGcpNotebooksInstanceRiskContext(instanceModel);
            return [
                instanceRiskContext.generalInformation,
                instanceRiskContext.sensitive,
                instanceRiskContext.status,
                instanceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}