import { Dictionary } from "lodash";
import React from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { GcpRoleBindingTable } from "../../../../../../../../../tenants";

type GcpResourceRoleBindingsProps = {
    csvExportFilePrefix: string;
    initialFilterMap?: Dictionary<any>;
    scopeResourceModel: Contract.GcpScopeResourceModel;
};

export function GcpScopeResourceRoleBindings({ csvExportFilePrefix, initialFilterMap, scopeResourceModel }: GcpResourceRoleBindingsProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <GcpRoleBindingTable
            csvExportFilePrefixes={[
                entityTypeNameTranslator(scopeResourceModel.entity.typeName, { includeServiceName: false }),
                scopeResourceModel.entity.displayName,
                csvExportFilePrefix
            ]}
            initialFilterMap={initialFilterMap}
            principalOrResourceId={scopeResourceModel.entity.id}
            roleBindingIds={scopeResourceModel.roleBindingIds}
            variant="principalAndRoleAndScope"/>);
}