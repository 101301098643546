import { Contract } from "../controllers";

export class SettingConfigurationHelper {
    public static getValue(scopeSettingConfiguration: Partial<Contract.ScopeSettingConfiguration>) {
        switch (scopeSettingConfiguration.typeName) {
            case Contract.ScopeSettingConfigurationDerivedTypeNames.ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration:
                return (scopeSettingConfiguration as Contract.ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration).timeFrame;
            case Contract.ScopeSettingConfigurationDerivedTypeNames.UserExcessivePermissionEvaluationTimeFrameConfiguration:
                return (scopeSettingConfiguration as Contract.UserExcessivePermissionEvaluationTimeFrameConfiguration).timeFrame;
            case Contract.ScopeSettingConfigurationDerivedTypeNames.AadSeverePermissionDirectoryRoleDefinitionsConfiguration:
                return (scopeSettingConfiguration as Contract.AadSeverePermissionDirectoryRoleDefinitionsConfiguration).directoryRoleDefinitionDatas;
        }
    }
}