import { Box, SxProps } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import _ from "lodash";
import React, { memo, ReactElement, useCallback, useEffect, useState } from "react";
import { useEffectEvent } from "../hooks";
import { Action1, Optional } from "../types";

type WidthCounterProps = {
    children: (count?: number) => ReactElement | ReactElement[];
    onCountChange?: Action1<Optional<number>>;
    reducedWidth?: number;
    sx?: SxProps;
    width: number;
};

const WidthCounterMemo = memo(WidthCounter);
export { WidthCounterMemo as WidthCounter };

function WidthCounter({ children, onCountChange, reducedWidth = 0, sx, width }: WidthCounterProps) {
    const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
    const wrapperRefSetter =
        useCallback(
            (ref: HTMLDivElement) => setWrapperRef(ref),
            []);

    function calculateCount() {
        if (_.isNil(wrapperRef) || width === 0) {
            return 0;
        }

        const measurableWidth = Math.max(wrapperRef.getBoundingClientRect().width - reducedWidth, 0);
        return Math.floor(measurableWidth / width);
    }

    const [count, setCount] = useState<Optional<number>>();
    const stableOnCountChange = useEffectEvent(onCountChange);
    useEffect(
        () => {
            stableOnCountChange(count);
        },
        [count, stableOnCountChange]);

    useResizeObserver(
        wrapperRef,
        () => {
            const nextCount = calculateCount();
            if (count !== nextCount) {
                setCount(nextCount);
            }
        });

    return (
        <Box
            ref={wrapperRefSetter}
            sx={{
                flex: 1,
                ...sx
            }}>
            {children(count)}
        </Box>);
}