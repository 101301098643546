import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GranteeUserPermissionRequestsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M3 17C3 18.6569 4.34315 20 6 20H10.5L15.2059 23.4225C15.5364 23.6628 16 23.4268 16 23.0181V20H17C18.6569 20 20 18.6569 20 17V9C20 7.34315 18.6569 6 17 6H15V8H17C17.5523 8 18 8.44772 18 9V17C18 17.5523 17.5523 18 17 18H14V20.0725L11.1504 18H6C5.44772 18 5 17.5523 5 17H3Z"/>
            <path
                clipRule="evenodd"
                d="M11 7H3L3 13H11V7ZM3 5C1.89543 5 1 5.89543 1 7V13C1 14.1046 1.89543 15 3 15H11C12.1046 15 13 14.1046 13 13V7C13 5.89543 12.1046 5 11 5H3Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M4 4C4 2.34315 5.34315 1 7 1C8.65685 1 10 2.34315 10 4V6H8V4C8 3.44772 7.55228 3 7 3C6.44772 3 6 3.44772 6 4V6H4V4Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}