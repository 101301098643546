﻿import { ActionMenuItem, DeleteIcon, Menu, Message, Tooltip, useLocalization } from "@infrastructure";
import { CircularProgress, Stack, SxProps, Typography } from "@mui/material";
import React, { useState } from "react";
import { ConfigurationController, Contract, scopeNodeModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../common";
import { useSetTrustedFilesContext } from "../TrustedFiles";

export type ActionsCellProps = {
    scopeConfigurationTrustedFile: Contract.ScopeConfigurationWorkloadAnalysisTrustedFile;
};

export function ActionsCell({ scopeConfigurationTrustedFile }: ActionsCellProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setContext = useSetTrustedFilesContext();

    const [deleteTrustedFileExecuting, setDeleteTrustedFileExecuting] = useState(false);
    const [deleteTrustedFileError, setDeleteTrustedFileError] = useState(false);

    async function deleteTrustedFile() {
        setDeleteTrustedFileExecuting(true);
        setDeleteTrustedFileExecuting(false);

        try {
            const deleteWorkloadAnalysisTrustedFileResponse =
                await ConfigurationController.deleteWorkloadAnalysisTrustedFile(
                    new Contract.ConfigurationControllerDeleteWorkloadAnalysisTrustedFileRequest(
                        scopeConfigurationTrustedFile.contentSha256String,
                        scopeNodeModel.configuration.id));

            await scopeNodeModelStore.notify(scopeNodeModel.configuration.id);

            setContext(
                context => ({
                    ...context,
                    scopeConfigurationTrustedFiles: deleteWorkloadAnalysisTrustedFileResponse.trustedFiles
                }));
        } catch {
            setDeleteTrustedFileError(true);
        }

        setDeleteTrustedFileExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.maliciousFiles.trustedFiles.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete {{contentSha256}}?",
                        title: "Delete"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteTrustedFileExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteTrustedFileError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            <Menu
                itemsOrGetItems={[
                    new ActionMenuItem(
                        () => deleteTrustedFile(),
                        localization.actions.delete.title(),
                        {
                            confirmOptions: {
                                message: localization.actions.delete.prompt({
                                    contentSha256:
                                        <Sha256
                                            sha256String={scopeConfigurationTrustedFile.contentSha256String}
                                            sx={{
                                                fontSize: "unset",
                                                fontWeight: "unset"
                                            }}/>
                                })
                            },
                            disabled: deleteTrustedFileExecuting,
                            icon: <DeleteIcon/>
                        })
                ]}/>
        </Stack>);
}

type Sha256Props = {
    sha256String: string;
    sx?: SxProps;
};

function Sha256({ sha256String, sx }: Sha256Props) {
    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.maliciousFiles.trustedFiles.actionsCell.sha256",
            () => ({
                sha256ShortenedString: "{{sha256StringStart}}...{{sha256StringEnd}}"
            }));
    return (
        <Tooltip titleOrGetTitle={sha256String}>
            <Typography
                component="span"
                sx={sx}>
                {localization.sha256ShortenedString({
                    sha256StringEnd: sha256String.slice(-4),
                    sha256StringStart: sha256String.slice(0, 4)
                })}
            </Typography>
        </Tooltip>);
}