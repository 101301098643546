import _ from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useFormatRelativeTime, useLocalization } from "@infrastructure";
import { Contract, ElasticsearchItemPageHelper, LicensingHelper, SeverityFilter, TimeRangeHelper, TypeHelper } from "../../../../../../../../../common";
import { VulnerabilitiesChartCell, WorkloadAnalysisScanStatusCell } from "../../../../../../WorkloadAnalysis";
import { useGetWorkloadAnalysisScanStatusExportItem, useGetWorkloadResourceVulnerabilitiesCsvItem, useWorkloadAnalysisScanStatusTranslator } from "../../../../../../WorkloadAnalysis/hooks";
import { useOperatingSystemTypeTranslator } from "../../../../../hooks";
import { DefinitionData } from "../../../Table";

export function useCommonVirtualMachineDiskResourceDefinition(definitionData: DefinitionData) {
    const formatRelativeTime = useFormatRelativeTime();
    const virtualMachineModelFiltersPromise = _.as<Promise<Contract.IVirtualMachineDiskResourceFilters>>(definitionData.entityModelFiltersPromise);
    const getWorkloadAnalysisScanStatusExportItem = useGetWorkloadAnalysisScanStatusExportItem();
    const getWorkloadResourceVulnerabilitiesCsvItem = useGetWorkloadResourceVulnerabilitiesCsvItem();

    const operatingSystemTypeTranslator = useOperatingSystemTypeTranslator();
    const workloadAnalysisScanStatusTranslator = useWorkloadAnalysisScanStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonVirtualMachineDiskResourceDefinition",
            () => ({
                columns: {
                    operatingSystem: "Operating System",
                    operatingSystemType: "Operating System Type",
                    scanTime: "Scan Time",
                    vulnerabilities: "Vulnerabilities",
                    vulnerabilitySeverities: "Vulnerability Severity",
                    workloadAnalysisStatus: "Scan Status"
                }
            }));

    return {
        columns: {
            operatingSystemTypeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IVirtualMachineDiskResourceModel) => ({
                                [localization.columns.operatingSystemType()]:
                                    _.isNil(item.operatingSystemType)
                                        ? ""
                                        : operatingSystemTypeTranslator(item.operatingSystemType)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.OperatingSystemType}
                                    enumTypeTranslator={operatingSystemTypeTranslator}
                                    placeholder={localization.columns.operatingSystemType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.VirtualMachineOperatingSystemType}
                    key={Contract.EntityModelProperty.VirtualMachineOperatingSystemType}
                    render={
                        optionalTableCell<Contract.IVirtualMachineDiskResourceModel>(
                            item =>
                                _.isNil(item.operatingSystemType)
                                    ? undefined
                                    : operatingSystemTypeTranslator(item.operatingSystemType))}
                    selectorOptions={{ default: false }}
                    title={localization.columns.operatingSystemType()}/>,
            ...LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
                ? {
                    operatingSystemColumn:
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (item: Contract.IVirtualMachineDiskResourceModel) => ({
                                        [localization.columns.operatingSystem()]: item.workloadAnalysisOperatingSystem?.displayName ?? ""
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <PagedValuesFilter
                                            getValuePage={
                                                ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                                    definitionData.entityTypeEntitiesViewName,
                                                    Contract.EntityModelProperty.VirtualMachineOperatingSystemDisplayName)}
                                            placeholder={localization.columns.operatingSystem()}/>
                                }
                            }}
                            id={Contract.EntityModelProperty.VirtualMachineOperatingSystemDisplayName}
                            key={Contract.EntityModelProperty.VirtualMachineOperatingSystemDisplayName}
                            render={optionalTableCell<Contract.IVirtualMachineDiskResourceModel>(item => item.workloadAnalysisOperatingSystem?.displayName)}
                            title={localization.columns.operatingSystem()}/>,
                    scanTimeColumn:
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (item: Contract.IVirtualMachineDiskResourceModel) => ({
                                        [localization.columns.scanTime()]:
                                            _.isNil(item.scanTime)
                                                ? ""
                                                : TimeFormatter.workloadResourceDateTime(item.scanTime)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <DeferredFilter
                                            promiseOrGetPromise={virtualMachineModelFiltersPromise}
                                            title={localization.columns.scanTime()}>
                                            {filters =>
                                                <TimeRangeFilter
                                                    emptyValue={filters.scanTimeRange.emptyValue}
                                                    placeholder={localization.columns.scanTime()}
                                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.scanTimeRange.timeRange)}/>}
                                        </DeferredFilter>
                                }
                            }}
                            id={Contract.EntityModelProperty.VirtualMachineScanTime}
                            key={Contract.EntityModelProperty.VirtualMachineScanTime}
                            render={
                                optionalTableCell<Contract.IVirtualMachineDiskResourceModel>(
                                    item =>
                                        _.isNil(item.scanTime)
                                            ? undefined
                                            : formatRelativeTime(item.scanTime))}
                            sortOptions={{ type: DataTableSortType.Date }}
                            title={localization.columns.scanTime()}/>,
                    vulnerabilitiesColumn:
                        <DataTableColumn
                            cellMinWidth={190}
                            exportOptions={{
                                getItem:
                                    (item: Contract.IVirtualMachineDiskResourceModel) => ({
                                        [localization.columns.vulnerabilities()]: getWorkloadResourceVulnerabilitiesCsvItem(item.vulnerabilities)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: [
                                    {
                                        element:
                                            <PagedValuesFilter
                                                getValuePage={
                                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                                        definitionData.entityTypeEntitiesViewName,
                                                        Contract.EntityModelProperty.VirtualMachineVulnerabilities)}
                                                placeholder={localization.columns.vulnerabilities()}/>,
                                        id: Contract.EntityModelProperty.VirtualMachineVulnerabilities,
                                        title: localization.columns.vulnerabilities()
                                    },
                                    {
                                        element:
                                            <SeverityFilter placeholder={localization.columns.vulnerabilitySeverities()}/>,
                                        id: Contract.EntityModelProperty.VirtualMachineVulnerabilitySeverities,
                                        title: localization.columns.vulnerabilitySeverities()
                                    }
                                ]
                            }}
                            id={Contract.EntityModelProperty.VirtualMachineVulnerabilities}
                            key={Contract.EntityModelProperty.VirtualMachineVulnerabilities}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.IVirtualMachineDiskResourceModel>) =>
                                    <VulnerabilitiesChartCell
                                        vulnerabilities={item.vulnerabilities}
                                        workloadResourceEntityId={item.id}/>}
                            sortOptions={{ type: DataTableSortType.Numeric }}
                            title={localization.columns.vulnerabilities()}/>,
                    workloadAnalysisStatusColumn:
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (item: Contract.IVirtualMachineDiskResourceModel) => ({
                                        [localization.columns.workloadAnalysisStatus()]:
                                            getWorkloadAnalysisScanStatusExportItem(
                                                TypeHelper.extendOrImplement(item.entity.typeName, Contract.TypeNames.IVirtualMachine)
                                                    ? Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine
                                                    : Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage,
                                                item.workloadAnalysisStatus,
                                                item.workloadAnalysisError,
                                                TypeHelper.extendOrImplement(item.typeName, Contract.TypeNames.IVirtualMachineModel)
                                                    ? _.as<Contract.IVirtualMachineModel>(item).diskEncryptionVaultIdReference
                                                    : undefined)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <EnumValuesFilter
                                            enumType={Contract.WorkloadAnalysisStatus}
                                            enumTypeTranslator={workloadAnalysisScanStatusTranslator}
                                            placeholder={localization.columns.workloadAnalysisStatus()}/>
                                }
                            }}
                            id={Contract.EntityModelProperty.VirtualMachineScanStatus}
                            key={Contract.EntityModelProperty.VirtualMachineScanStatus}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.IVirtualMachineDiskResourceModel>) =>
                                    <WorkloadAnalysisScanStatusCell
                                        azureKeyVaultId={
                                            TypeHelper.extendOrImplement(item.typeName, Contract.TypeNames.IVirtualMachineModel)
                                                ? _.as<Contract.IVirtualMachineModel>(item).diskEncryptionVaultIdReference
                                                : undefined}
                                        resourceType={
                                            TypeHelper.extendOrImplement(item.entity.typeName, Contract.TypeNames.IVirtualMachine)
                                                ? Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine
                                                : Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage}
                                        workloadAnalysisError={item.workloadAnalysisError}
                                        workloadAnalysisStatus={item.workloadAnalysisStatus}/>}
                            title={localization.columns.workloadAnalysisStatus()}/>
                }
                : undefined
        }
    };
}