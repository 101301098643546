import { AnalyticsEventActionType, ClearIcon, useFilterConnectorContext, useSetFilterConnectorContext, useSetFiltersContext, useTrackAnalytics } from "@infrastructure";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useCallback } from "react";

type FilterFieldProps = {
    emptyValue?: boolean;
    focused?: boolean;
    placeholder: string;
    selection?: ReactNode;
};

export function FilterField({ emptyValue, focused, placeholder, selection }: FilterFieldProps) {
    const { id, showByDefault } = useFilterConnectorContext();
    const setFilterConnectorContext = useSetFilterConnectorContext();
    const setFiltersContext = useSetFiltersContext();
    const trackAnalytics = useTrackAnalytics();
    const onClearClicked =
        useCallback(
            (event: React.MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                setFiltersContext(
                    context => ({
                        ...context,
                        filterMap:
                            _.has(context.filterMap, id)
                                ? _.omit(context.filterMap, id)
                                : context.filterMap,
                        visibleFilters:
                            _.has(context.filterMap, id)
                                ? context.visibleFilters
                                : _.without(context.visibleFilters, id)
                    }));
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        filter: undefined
                    }));
                trackAnalytics(
                    AnalyticsEventActionType.FilterRemove,
                    {
                        "Filter Default": !!showByDefault,
                        "Filter Name": placeholder
                    });
            },
            [id, placeholder, showByDefault]);

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={0.75}
            sx={{
                "&:hover": {
                    border: theme.border.hoverFocus
                },
                alignItems: "center",
                backgroundColor: theme.palette.background.paper,
                border:
                    emptyValue && !focused
                        ? theme.border.primary
                        : theme.border.hoverFocus,
                borderRadius: theme.spacing(0.75),
                color: theme.palette.text.secondary,
                padding: theme.spacing(0, 0.25, 0, 1),
                position: "relative",
                userSelect: "none",
                ...(!emptyValue && {
                    color: theme.palette.text.primary,
                    fontWeight: 500
                })
            }}>
            <Typography
                noWrap={true}
                sx={{ padding: theme.spacing(0.5, 0) }}>
                {selection ?? placeholder}
            </Typography>
            <IconButton
                sx={{
                    height: theme.spacing(2.5),
                    width: theme.spacing(2.5)
                }}
                onClick={onClearClicked}>
                <ClearIcon
                    sx={{
                        color:
                            emptyValue
                                ? theme.palette.text.secondary
                                : theme.palette.text.primary,
                        fontSize: "12px"
                    }}/>
            </IconButton>
        </Stack>);
}