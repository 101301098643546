import { EmptyMessage, Optional, TextViewer, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../common";

export type PolicySectionProps = {
    policyDocument: Optional<Contract.AwsIamPolicyDocument>;
};

export function PolicySection({ policyDocument }: PolicySectionProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.policySection",
            () => ({
                empty: "No policy"
            }));

    return !policyDocument
        ? <EmptyMessage
            message={localization.empty()}
            verticalCenter={true}/>
        : <TextViewer
            format="json"
            text={policyDocument.raw}/>;
}