import { useExecuteOperation, useLocalization, useRoute, useSetRoute } from "@infrastructure";
import React, { useEffect } from "react";
import { CodeController, CustomerConsoleAppUrlHelper, useLayoutOptions } from "../../../../../../common";
import { Empty, ProfileCategory, Table } from "./components";

export function Scans() {
    const localization =
        useLocalization(
            "views.customer.code.scans",
            () => ({
                title: "IaC CI/CD Scans"
            }));
    useLayoutOptions({ view: { title: localization.title() } });

    const { match, viewOrId } = useRoute(`${CustomerConsoleAppUrlHelper.getCodeScansRelativeUrl()}/{viewOrId}`);
    const setRoute = useSetRoute();

    useEffect(
        () => {
            if (viewOrId
                && match) {
                setRoute(
                    `${CustomerConsoleAppUrlHelper.getCodeScansRelativeUrl()}${CustomerConsoleAppUrlHelper.getCodeScanProfileHashUrl(viewOrId, ProfileCategory.Overview)}`,
                    undefined,
                    { appendBrowserHistory: false });
            }
        },
        [viewOrId]);

    const [{ scanExists }] =
        useExecuteOperation(
            Scans,
            () => CodeController.getIacScanExists());

    return scanExists
        ? <Table/>
        : <Empty/>;
}