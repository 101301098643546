﻿import { DataTableColumn, InlineItems, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper } from "../../../../../../../../../../common";
import { GcpApiKeysKeyRestrictionsClientRawIdentifiers } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";

export function useGcpApiKeysKeyDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpApiKeysKeyDefinition",
            () => ({
                columns: {
                    clientRawIdentifiers: "Application Restrictions",
                    serviceHostNames: {
                        pluralizer: [
                            "1 API",
                            "{{count | NumberFormatter.humanize}} APIs"
                        ],
                        title: "API Restrictions"
                    }
                }
            }));
    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpApiKeysKeyModel) => ({
                            [localization.columns.clientRawIdentifiers()]:
                            (item.entity as Contract.GcpApiKeysKey).
                                restrictions.
                                clientRawIdentifiers?.
                                join("\n") ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpApiKeysKeyRestrictionsClientRawIdentifiers)}
                                placeholder={localization.columns.clientRawIdentifiers()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpApiKeysKeyRestrictionsClientRawIdentifiers}
                key={Contract.EntityModelProperty.GcpApiKeysKeyRestrictionsClientRawIdentifiers}
                render={
                    optionalTableCell<Contract.GcpApiKeysKeyModel>(
                        item =>
                            (item.entity as Contract.GcpApiKeysKey).restrictions.clientType === Contract.GcpApiKeysKeyRestrictionsClientType.None
                                ? undefined
                                : <GcpApiKeysKeyRestrictionsClientRawIdentifiers keyRestrictions={(item.entity as Contract.GcpApiKeysKey).restrictions}/>)}
                title={localization.columns.clientRawIdentifiers()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpApiKeysKeyModel) => ({
                            [localization.columns.serviceHostNames.title()]: (item.entity as Contract.GcpApiKeysKey).restrictions.serviceHostNames ?? []
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpApiKeysKeyRestrictionsServiceHostNames)}
                                placeholder={localization.columns.serviceHostNames.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpApiKeysKeyRestrictionsServiceHostNames}
                key={Contract.EntityModelProperty.GcpApiKeysKeyRestrictionsServiceHostNames}
                render={
                    optionalTableCell<Contract.GcpApiKeysKeyModel>(
                        item =>
                            !_.isEmpty((item.entity as Contract.GcpApiKeysKey).restrictions.serviceHostNames)
                                ? <InlineItems
                                    items={(item.entity as Contract.GcpApiKeysKey).restrictions.serviceHostNames}
                                    namePluralizer={localization.columns.serviceHostNames.pluralizer}
                                    variant="itemCountAndType"/>
                                : undefined)}
                title={localization.columns.serviceHostNames.title()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpApiKeysKeyRequest(
                new Contract.EntityControllerGetEntityModelPageGcpApiKeysKeyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpApiKeysKeyRestrictionsClientRawIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpApiKeysKeyRestrictionsServiceHostNames])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}