import { Link, NumberFormatter, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { scopeNodeModelStore, UrlHelper, useScopeNavigationViewContext } from "../../../../../../../../common";


type DiskProps = {
    resourceType: string;
};

export function Disk({ resourceType }: DiskProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.disk.disk",
            () => ({
                contactSupport: "contact support",
                description: "Workload Protection is enabled for {{resourceType}} with a disk size up to {{diskSize}}. For more information, or to change this value, {{contactSupportLink}}.",
                title: "Maximum Disk Size"
            }));

    const inheritedDiskMaxSize =
        useMemo(
            () => {
                const parentScopeIds = scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds;

                for (const scopeId of [scopeNodeModel.configuration.id, ...parentScopeIds]) {
                    if (!_.isNil(scopeNodeMap[scopeId].scopeNodeModel.configuration.scopeSections.workloadAnalysis.diskMaxSize)) {
                        return scopeNodeMap[scopeId].scopeNodeModel.configuration.scopeSections.workloadAnalysis.diskMaxSize;
                    }
                }

                return undefined;
            },
            [scopeNodeModel]);

    return (
        <Stack spacing={1}>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <Typography>
                {localization.description({
                    contactSupportLink:
                        <Link
                            urlOrGetUrl={UrlHelper.supportUrl}
                            variant="external">
                            {localization.contactSupport()}
                        </Link>,
                    diskSize: NumberFormatter.storage(inheritedDiskMaxSize!),
                    resourceType
                })}
            </Typography>
        </Stack>);
}