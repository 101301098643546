import { Dropdown, Loading } from "@infrastructure";
import React, { useState } from "react";
import { Contract } from "../../../../../../controllers";
import { useTheme } from "../../../../../../themes";
import { JoinIcon } from "../../icons";
import { Popup } from "./components";

type UdmObjectJoinSelectorProps = {
    disabled?: boolean;
    objectTypeName: string;
    onRelationRuleAdd?: (property: Contract.UdmObjectProperty) => void;
};

export function UdmObjectJoinSelector({ disabled, objectTypeName, onRelationRuleAdd }: UdmObjectJoinSelectorProps) {
    const [open, setOpen] = useState(false);

    const theme = useTheme();
    return (
        <Dropdown
            disabled={disabled}
            justify="left"
            open={open}
            popoverElement={
                () =>
                    <Loading>
                        <Popup
                            objectTypeName={objectTypeName}
                            onPropertySelected={
                                property => {
                                    onRelationRuleAdd?.(property);
                                    setOpen(false);
                                }}/>
                    </Loading>}
            popoverElementContainerSx={{
                minWidth: "500px",
                overflow: "hidden",
                padding: 0
            }}
            sx={{
                "&:hover": {
                    background: theme.palette.action.hover
                },
                background:
                    open
                        ? theme.palette.action.hover
                        : undefined,
                borderRadius: theme.spacing(0.75),
                padding: theme.spacing(0.5)
            }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            <JoinIcon sx={{ fontSize: "14px" }}/>
        </Dropdown>);
}