import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciFssMountTargetExportStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciFssMountTargetExportDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const mountTargetExportStatusTranslator = useOciFssMountTargetExportStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciFssMountTargetExportDefinition",
            () => ({
                columns: {
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciFssMountTargetExportModel) => ({
                                [localization.columns.status()]: mountTargetExportStatusTranslator((item.entity as Contract.OciFssMountTargetExport).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.OciFssMountTargetExportStatus}
                                    enumTypeTranslator={mountTargetExportStatusTranslator}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetExportStatus}
                    itemProperty={(item: Contract.OciFssMountTargetExportModel) => mountTargetExportStatusTranslator((item.entity as Contract.OciFssMountTargetExport).status)}
                    key={Contract.EntityModelProperty.OciFssMountTargetExportStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.OciFssMountTargetExportModel>(
                            Contract.TypeNames.OciFssFileSystem,
                            item => [item.fileSystemIdReference],
                            entityTypeNameTranslator(Contract.TypeNames.OciFssFileSystem))}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciFssMountTargetExportFileSystem)}
                                    placeholder={entityTypeNameTranslator(Contract.TypeNames.OciFssFileSystem)}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetExportFileSystem}
                    key={Contract.EntityModelProperty.OciFssMountTargetExportFileSystem}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.OciFssMountTargetExportModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.fileSystemIdReference}
                                entityTypeName={Contract.TypeNames.OciFssFileSystem}/>}
                    title={entityTypeNameTranslator(Contract.TypeNames.OciFssFileSystem)}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.OciFssMountTargetExportModel>(
                            Contract.TypeNames.OciFssMountTarget,
                            item => [item.mountTargetId],
                            entityTypeNameTranslator(Contract.TypeNames.OciFssMountTarget))}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciFssMountTargetExportMountTarget)}
                                    placeholder={entityTypeNameTranslator(Contract.TypeNames.OciFssMountTarget)}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetExportMountTarget}
                    key={Contract.EntityModelProperty.OciFssMountTargetExportMountTarget}
                    render={
                        optionalTableCell<Contract.OciFssMountTargetExportModel>(
                            item =>
                                _.isNil(item.mountTargetId)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={item.mountTargetId}
                                        entityTypeName={Contract.TypeNames.OciFssMountTarget}/>)}
                    title={entityTypeNameTranslator(Contract.TypeNames.OciFssMountTarget)}/>,
                commonResourceDefinition.columns.creatorCsvColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciFssMountTargetExportRequest(
                new Contract.EntityControllerGetEntityModelPageOciFssMountTargetExportRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetExportFileSystem]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetExportMountTarget]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetExportStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(undefined)));
}