import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstancesRiskContext, useGetGcpComputeInstanceTemplateRiskContext } from "../../../contexts";

export function useGcpComputeInstanceDefaultServiceAccountRiskInstanceTemplateDefinition(riskModel: Contract.GcpComputeInstanceDefaultServiceAccountRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceDefaultServiceAccountRisk;
    const instanceTemplateModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeInstanceTemplateModel;

    const getGcpComputeInstancesRiskContext = useGetGcpComputeInstancesRiskContext();
    const getGcpComputeInstanceTemplateRiskContext = useGetGcpComputeInstanceTemplateRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceDefaultServiceAccountRiskDefinition.hooks.useGcpComputeInstanceDefaultServiceAccountRiskInstanceTemplateDefinition",
            () => ({
                description: {
                    withInstanceIds: "{{instanceTemplate}} launched {{instances}} configured with the default service account",
                    withoutInstanceIds: "{{instanceTemplate}} is configured with the default service account"
                },
                sections: {
                    resolution: {
                        step1: "Click the **CREATE SIMILAR** button",
                        step2: "Under **Identity and API access** select another service account. Verify that the new service account has the permissions your instances need to access resources",
                        step3: "Click on **Create**",
                        step4: "For each instance group and cluster using the instance template, update the configuration to use the newly created instance template",
                        step5: "Delete the old unsecured instance template. Note that you cannot delete an instance template if any managed instance group still references it"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.withoutInstanceIds
            : localization.description.withInstanceIds)({
            instances:
                <InlineEntities
                    entityIdsOrModels={risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                    variant="itemCountAndType"/>,
            instanceTemplate:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const instancesContextItems = getGcpComputeInstancesRiskContext(risk.aggregatedEntityIds);
            const instanceTemplateContextItems = getGcpComputeInstanceTemplateRiskContext(instanceTemplateModel);
            return [
                instanceTemplateContextItems.generalInformation,
                instanceTemplateContextItems.sensitive,
                instancesContextItems.sensitive,
                instanceTemplateContextItems.vpcs,
                instanceTemplateContextItems.instanceGroups,
                instanceTemplateContextItems.authorizationScopes,
                instanceTemplateContextItems.serviceAccount,
                instanceTemplateContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}