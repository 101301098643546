﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function IsraelIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60209_7023)">
                <rect
                    fill="white"
                    height="16"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    clipRule="evenodd"
                    d="M21.9697 5H2.08643C1.53414 5 1.08643 5.44772 1.08643 6V17.9343C1.08643 18.4866 1.53414 18.9343 2.08642 18.9343H21.9697C22.5219 18.9343 22.9697 18.4866 22.9697 17.9343V6C22.9697 5.44771 22.5219 5 21.9697 5ZM2.08643 4C0.981856 4 0.0864258 4.89543 0.0864258 6V17.9343C0.0864258 19.0388 0.981855 19.9343 2.08642 19.9343H21.9697C23.0742 19.9343 23.9697 19.0388 23.9697 17.9343V6C23.9697 4.89543 23.0742 4 21.9697 4H2.08643Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <rect
                    fill="#0038B8"
                    height="3.05405"
                    width="24"
                    y="4"/>
                <rect
                    fill="#0038B8"
                    height="3.05405"
                    width="24"
                    y="17"/>
                <path
                    clipRule="evenodd"
                    d="M11.199 9.95964L12.3591 7.91797L13.5191 9.95964L15.8789 9.95964L14.699 12.0362L15.8393 14.043H13.5588L12.3987 16.0846L11.2387 14.043H8.87891L10.0588 11.9664L8.91854 9.95964L11.199 9.95964ZM10.9448 10.4071H9.68132L10.313 11.5189L10.9448 10.4071ZM10.5673 11.9664L11.4533 10.4071H13.2649L14.1905 12.0362L13.3045 13.5955H11.4929L10.5673 11.9664ZM10.313 12.4139L9.64169 13.5955H10.9844L10.313 12.4139ZM11.7472 14.043L12.3987 15.1897L13.0503 14.043H11.7472ZM13.813 13.5955H15.0765L14.4448 12.4837L13.813 13.5955ZM14.4448 11.5887L15.1161 10.4071H13.7734L14.4448 11.5887ZM13.0106 9.95964H11.7075L12.3591 8.81295L13.0106 9.95964Z"
                    fill="#0038B8"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_60209_7023">
                    <rect
                        fill="white"
                        height="16"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}