import { Action1, ActionButton, AnalyticsContext, AnalyticsEventActionType, AnalyticsOptionsEventPropertyNameToValueMap, MenuIcon, StorageItem, StringHelper, Sx, Tooltip, useTrackAnalytics } from "@infrastructure";
import { Box, Stack, SxProps, Tab, Tabs, TabsProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useState } from "react";
import { ElementClass } from "./VerticalTabView.element";

type VerticalTabViewProps<T> = {
    children: ReactNode;
    collapseEnabled?: boolean;
    items: VerticalTabViewItem<T>[];
    onSelectedViewChanged: Action1<T>;
    selectedView: T;
    storageItem?: StorageItem;
    sx?: SxProps;
    tabsProps?: TabsProps;
    tabsSx?: SxProps;
    tabsTitle?: ReactNode;
};

export type VerticalTabViewItem<TView> = {
    additionalPropertyNameToValueMap?: AnalyticsOptionsEventPropertyNameToValueMap;
    disabled?: boolean;
    disabledTooltipTitle?: string;
    icon?: ReactNode;
    title: ReactNode;
    tooltipInteractive?: boolean;
    view: TView;
};

export function VerticalTabView<TView extends string>({ children, collapseEnabled = true, items, onSelectedViewChanged, selectedView, storageItem, sx, tabsProps = {}, tabsSx = {}, tabsTitle }: VerticalTabViewProps<TView>) {
    const [open, setOpen] =
        useState(
            _.isNil(storageItem)
                ? true
                : !StringHelper.isFalse(storageItem.getValue()));

    const trackAnalytics = useTrackAnalytics();
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={
                Sx.combine(
                    {
                        height: "100%",
                        overflow: "hidden",
                        width: "100%"
                    },
                    sx)}>
            <Box
                sx={{
                    borderRight: theme.border.primary,
                    height: "100%",
                    ...tabsSx
                }}>
                {tabsTitle}
                {collapseEnabled &&
                    <Box sx={{ fontSize: "18px" }}>
                        <ActionButton
                            sx={{
                                borderRadius: theme.spacing(0.75),
                                height: theme.px(42),
                                margin: theme.spacing(0.75, 0.75, 0),
                                width: theme.px(42)
                            }}
                            variant="icon"
                            onClick={
                                () => {
                                    if (!_.isNil(storageItem)) {
                                        storageItem.setValue((!open).toString());
                                    }
                                    setOpen(!open);
                                }}>
                            <MenuIcon/>
                        </ActionButton>
                    </Box>}
                <Tabs
                    indicatorColor="secondary"
                    orientation="vertical"
                    sx={{
                        alignItems: "center",
                        border: 0,
                        height: "100%"
                    }}
                    value={selectedView}
                    variant="scrollable"
                    {...tabsProps}>
                    {_.map(
                        items,
                        item => {
                            const tab =
                                <Tab
                                    className={ElementClass.verticalTabViewTab}
                                    disabled={item.disabled}
                                    icon={
                                        item.icon
                                            ? <Box sx={{ fontSize: "18px" }}>
                                                {item.icon}
                                            </Box>
                                            : undefined}
                                    iconPosition="start"
                                    id={item.view}
                                    key={item.view}
                                    label={
                                        open
                                            ? item.title
                                            : undefined}
                                    value={item.view}
                                    onClick={
                                        () => {
                                            trackAnalytics(
                                                AnalyticsEventActionType.PageContentView,
                                                {
                                                    "Page Content": item.view,
                                                    ...item.additionalPropertyNameToValueMap
                                                });
                                            onSelectedViewChanged(item.view);
                                        }}/>;

                            return item.disabled
                                ? <Tooltip
                                    disableInteractive={!item.tooltipInteractive}
                                    enterDelay={500}
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [0, -14]
                                                    }
                                                }
                                            ]
                                        }
                                    }}
                                    titleOrGetTitle={item.disabledTooltipTitle}>
                                    <Box sx={{ width: "100%" }}>
                                        {tab}
                                    </Box>
                                </Tooltip>
                                : tab;
                        })}
                </Tabs>
            </Box>
            <Stack
                sx={{
                    flex: 1,
                    overflow: "auto hidden"
                }}>
                <AnalyticsContext context={selectedView}>
                    {children}
                </AnalyticsContext>
            </Stack>
        </Stack>);
}