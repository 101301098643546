import { Link, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../../../common";

export function SecretItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGitHubWorkflow.secretItem",
            () => ({
                description: {
                    link: "GitHub Actions Secrets",
                    title: "To store the API token securely, it is recommended to use {{gitHubSecretLink}}, or an alternative secret store."
                },
                steps: {
                    step1: "On GitHub.com, navigate to the main page of the repository.",
                    step2: "Under your repository name, select **Settings**.",
                    step3: "In the **“Security”** section of the sidebar, select **Secrets**, then click **Actions**.",
                    step4: "Click **New repository secret**.",
                    step5: "Type **TENABLE_API_TOKEN** in the Name input box.",
                    step6: "Enter the value of the Tenable Cloud Security token created in the previous step.",
                    step7: "Click **Add secret**.",
                    title: "To create a new secret, follow this steps:"
                }
            }));
    const theme = useTheme();

    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: theme.spacing(58) }}>
            <Typography>
                {localization.description.title({
                    gitHubSecretLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://docs.github.com/en/actions/security-guides/encrypted-secrets"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <Typography>
                {localization.steps.title()}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1()}
                {localization.steps.step2()}
                {localization.steps.step3()}
                {localization.steps.step4()}
                {localization.steps.step5()}
                {localization.steps.step6()}
                {localization.steps.step7()}
            </Steps>
        </Stack>);
}