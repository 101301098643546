import { DialogMenuItem, Menu, UnexpectedError, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, entityModelStore, TypeHelper, UserHelper, WorkloadAnalysisIcon } from "../../../../../common";
import { GenerateVirtualMachinePriorityScanRequestDialog } from "./GenerateVirtualMachinePriorityScanRequestDialog";


type PriorityScanActionCellProps = {
    virtualMachineId: string;
};

export function PriorityScanActionCell({ virtualMachineId }: PriorityScanActionCellProps) {
    const entityModel = entityModelStore.useGet(virtualMachineId);

    if (
        !TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IVirtualMachineModel) &&
        !entityModel.unknown) {
        throw new UnexpectedError("entityModel.typeName", entityModel.typeName);
    }

    const virtualMachineModel = _.as<Contract.IVirtualMachineModel>(entityModel);

    const [workloadAnalysisPriorityScanRequested, setWorkloadAnalysisPriorityScanRequested] = useState(virtualMachineModel.workloadAnalysisPriorityScanRequested);

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.priorityScanActionCell",
            () => ({
                enabled: "Scan Now",
                requested: "Scan Requested",
                tooltip: {
                    disabled: "Workload Protection scanning is disabled",
                    excluded: "This VM is excluded from scanning"
                }
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            <Menu
                itemsOrGetItems={[
                    new DialogMenuItem(
                        onClose =>
                            <GenerateVirtualMachinePriorityScanRequestDialog
                                virtualMachineModel={virtualMachineModel}
                                onClose={
                                    () => {
                                        setWorkloadAnalysisPriorityScanRequested(true);
                                        onClose();
                                    }}/>,
                        workloadAnalysisPriorityScanRequested
                            ? localization.requested()
                            : localization.enabled(),
                        {
                            disabled:
                                virtualMachineModel.kubernetesClusterSensor ||
                                !UserHelper.hasAnyScopePermissions(entityModel.entity.scopeIds, Contract.IdentityPermission.SecurityWrite) ||
                                workloadAnalysisPriorityScanRequested ||
                                virtualMachineModel.workloadAnalysisError === Contract.WorkloadAnalysisError.VirtualMachineExcluded ||
                                virtualMachineModel.workloadAnalysisError === Contract.WorkloadAnalysisError.WorkloadAnalysisDisabled,
                            icon: <WorkloadAnalysisIcon/>,
                            tooltip:
                                virtualMachineModel.workloadAnalysisError === Contract.WorkloadAnalysisError.WorkloadAnalysisDisabled
                                    ? localization.tooltip.disabled()
                                    : virtualMachineModel.workloadAnalysisError === Contract.WorkloadAnalysisError.VirtualMachineExcluded
                                        ? localization.tooltip.excluded()
                                        : undefined
                        })]}/>
        </Stack>);
}