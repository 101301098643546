import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OpFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H4.84948C5.05416 17.4534 5.34274 16.9485 5.69916 16.5C4.94953 15.5567 4.5 14.3638 4.5 13.0588C4.5 9.96424 7.02779 7.5 10.0909 7.5H20.9091C21.6463 7.5 22.3526 7.64275 23 7.90268V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                fill="#BAC5D3"/>
            <path
                clipRule="evenodd"
                d="M10.0909 16.8824C8.38385 16.8824 7 18.2518 7 19.9412C7 21.6305 8.38385 23 10.0909 23H20.9091C22.6162 23 24 21.6305 24 19.9412C24 18.2518 22.6162 16.8824 20.9091 16.8824H10.0909ZM10.0909 19.1765C9.66414 19.1765 9.31818 19.5188 9.31818 19.9412C9.31818 20.3635 9.66414 20.7059 10.0909 20.7059H15.5C15.9268 20.7059 16.2727 20.3635 16.2727 19.9412C16.2727 19.5188 15.9268 19.1765 15.5 19.1765H10.0909ZM18.5912 18.4117C19.018 18.4117 19.364 18.7541 19.364 19.1765V20.7059C19.364 21.1282 19.018 21.4706 18.5912 21.4706C18.1645 21.4706 17.8185 21.1282 17.8185 20.7059V19.1765C17.8185 18.7541 18.1645 18.4117 18.5912 18.4117ZM21.6821 19.1765C21.6821 18.7541 21.3362 18.4117 20.9094 18.4117C20.4826 18.4117 20.1367 18.7541 20.1367 19.1765V20.7059C20.1367 21.1282 20.4826 21.4706 20.9094 21.4706C21.3362 21.4706 21.6821 21.1282 21.6821 20.7059V19.1765Z"
                fill="url(#paint0_linear_61567_7252)"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M10.0909 10C8.38385 10 7 11.3695 7 13.0588C7 14.7482 8.38385 16.1176 10.0909 16.1176H20.9091C22.6162 16.1176 24 14.7482 24 13.0588C24 11.3695 22.6162 10 20.9091 10H10.0909ZM10.0909 12.2941C9.66414 12.2941 9.31818 12.6365 9.31818 13.0588C9.31818 13.4812 9.66414 13.8235 10.0909 13.8235H15.5C15.9268 13.8235 16.2727 13.4812 16.2727 13.0588C16.2727 12.6365 15.9268 12.2941 15.5 12.2941H10.0909ZM18.5912 11.5294C19.018 11.5294 19.364 11.8718 19.364 12.2941V13.8235C19.364 14.2458 19.018 14.5882 18.5912 14.5882C18.1645 14.5882 17.8185 14.2458 17.8185 13.8235V12.2941C17.8185 11.8718 18.1645 11.5294 18.5912 11.5294ZM21.6821 12.2941C21.6821 11.8718 21.3362 11.5294 20.9094 11.5294C20.4826 11.5294 20.1367 11.8718 20.1367 12.2941V13.8235C20.1367 14.2458 20.4826 14.5882 20.9094 14.5882C21.3362 14.5882 21.6821 14.2458 21.6821 13.8235V12.2941Z"
                fill="url(#paint1_linear_61567_7252)"
                fillRule="evenodd"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_61567_7252"
                    x1="25.993"
                    x2="7.35203"
                    y1="24.3282"
                    y2="9.65037">
                    <stop stopColor="#35B5EC"/>
                    <stop
                        offset="1"
                        stopColor="#0889C0"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_61567_7252"
                    x1="25.993"
                    x2="7.35203"
                    y1="24.3282"
                    y2="9.65037">
                    <stop stopColor="#35B5EC"/>
                    <stop
                        offset="1"
                        stopColor="#0889C0"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}