import { AnalyticsEventActionType, Dropdown, ItemSelector, Optional, SelectionView, useChangeEffect, useFilterConnectorContext, useLocalization, useSetFilterConnectorContext, useSetFiltersContext, useTrackAnalytics } from "@infrastructure";
import { Box, Divider, Stack, TextField, Typography, useTheme } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { FilterField } from "./FilterField";

export class TimeRangeFilterSelection {
    constructor(
        public dateEndTime: Optional<string>,
        public dateStartTime: Optional<string>,
        public relative: Optional<TimeRangeFilterSelectionRelative>,
        public type: TimeRangeFilterType) {
    }
}

export class TimeRangeFilterSelectionRelative {
    constructor(
        public unit: TimeRangeFilterSelectionRelativeUnit,
        public value: number) {
    }
}

export enum TimeRangeFilterSelectionRelativeUnit {
    Days = "days",
    Months = "months",
    Weeks = "weeks"
}

export enum TimeRangeFilterType {
    DateAfter = "dateAfter",
    DateBefore = "dateBefore",
    DateBetween = "dateBetween",
    Empty = "Empty",
    RelativeAfterTheNext = "relativeAfterTheNext",
    RelativeBeforeTheLast = "relativeBeforeTheLast",
    RelativeInTheLast = "relativeInTheLast",
    RelativeInTheNext = "relativeInTheNext"
}

type TimeRangeFilterProps = {
    emptyValue?: boolean;
    placeholder: string;
    timeRange?: TimeRangeFilterRange;
};

export class TimeRangeFilterRange {
    constructor(
        public endTime: string,
        public startTime: string) {
    }
}

export function TimeRangeFilter({ emptyValue, placeholder, timeRange }: TimeRangeFilterProps) {
    const { filter, open } =
        useFilterConnectorContext() as {
            filter?: TimeRangeFilterSelection;
            id: string;
            open: boolean;
        };
    const setFilterConnectorContext = useSetFilterConnectorContext();
    const setFiltersContext = useSetFiltersContext();

    const [type, setType] = useState(filter?.type ?? TimeRangeFilterType.RelativeInTheLast);
    const localization =
        useLocalization(
            "infrastructure.filters.timeRangeFilter",
            () => ({
                dateAfter: "after {{startTime | TimeFormatter.mediumDate}}",
                dateBefore: "before {{endTime | TimeFormatter.mediumDate}}",
                dateBetween: "between {{startTime | TimeFormatter.mediumDate}} - {{endTime | TimeFormatter.mediumDate}}",
                empty: "not set",
                include: "is",
                relative: "{{type}} {{value}} {{unit}}",
                type: {
                    [TimeRangeFilterType.DateAfter]: "After",
                    [TimeRangeFilterType.DateBefore]: "Before",
                    [TimeRangeFilterType.DateBetween]: "Between",
                    [TimeRangeFilterType.Empty]: "Not set",
                    [TimeRangeFilterType.RelativeAfterTheNext]: "After the next",
                    [TimeRangeFilterType.RelativeBeforeTheLast]: "Before the last",
                    [TimeRangeFilterType.RelativeInTheLast]: "In the last",
                    [TimeRangeFilterType.RelativeInTheNext]: "In the next"
                }
            }));

    const theme = useTheme();
    const selectionText =
        useMemo(
            () => {
                switch (filter?.type) {
                    case TimeRangeFilterType.DateAfter:
                        return localization.dateAfter({ startTime: filter.dateStartTime });
                    case TimeRangeFilterType.DateBefore:
                        return localization.dateBefore({ endTime: filter.dateEndTime });
                    case TimeRangeFilterType.DateBetween:
                        return localization.dateBetween({
                            endTime: filter.dateEndTime,
                            startTime: filter.dateStartTime
                        });
                    case TimeRangeFilterType.Empty:
                        return localization.empty();
                    case TimeRangeFilterType.RelativeAfterTheNext:
                    case TimeRangeFilterType.RelativeBeforeTheLast:
                    case TimeRangeFilterType.RelativeInTheLast:
                    case TimeRangeFilterType.RelativeInTheNext:
                        return localization.relative({
                            type:
                                localization.
                                    type[filter.type]().
                                    toLowerCase(),
                            unit: filter.relative!.unit,
                            value: filter.relative!.value
                        });
                }
            },
            [filter]);

    useChangeEffect(
        () => {
            if (type === TimeRangeFilterType.Empty) {
                setFilterConnectorContext(
                    context => (
                        {
                            ...context,
                            filter: new TimeRangeFilterSelection(
                                undefined,
                                undefined,
                                undefined,
                                TimeRangeFilterType.Empty)
                        }
                    )
                );
            }
        },
        [type]);

    const trackAnalytics = useTrackAnalytics();
    return (
        <Dropdown
            analyticsOptions={{
                onClose: {
                    actionType: AnalyticsEventActionType.FilterValueClose,
                    propertyNameToValueMap: {
                        "Filter Name": placeholder,
                        "Filter Type": "TimeRangeFilter"
                    }
                },
                onOpen: {
                    actionType: AnalyticsEventActionType.FilterValueOpen,
                    propertyNameToValueMap: {
                        "Filter Name": placeholder,
                        "Filter Type": "TimeRangeFilter"
                    }
                }
            }}
            open={open}
            popoverElement={
                <Stack spacing={1}>
                    <ItemSelector
                        dense={true}
                        fieldSx={{
                            minWidth: theme.spacing(40),
                            padding: theme.spacing(0.5, 0)
                        }}
                        fullWidth={true}
                        items={
                            _(TimeRangeFilterType).
                                values().
                                filter(
                                    type =>
                                        emptyValue ||
                                        type !== TimeRangeFilterType.Empty).
                                sortBy(
                                    type => type == TimeRangeFilterType.Empty,
                                    type => localization.type[type]()).
                                value()}
                        selectedItem={type}
                        sorted={false}
                        onSelectedItemChanged={setType}>
                        {item =>
                            <Typography noWrap={true}>
                                {localization.type[item as TimeRangeFilterType]()}
                            </Typography>}
                    </ItemSelector>
                    {type !== TimeRangeFilterType.Empty &&
                        <Fragment>
                            <Divider/>
                            <Box sx={{ display: "flex" }}>
                                {type === TimeRangeFilterType.DateAfter ||
                                type === TimeRangeFilterType.DateBefore ||
                                type === TimeRangeFilterType.DateBetween
                                    ? <DateTimeRangeFilter
                                        range={timeRange}
                                        type={type}/>
                                    : <RelativeTimeRangeFilter type={type}/>}
                            </Box>
                        </Fragment>}
                </Stack>}
            onClose={() => {
                setFiltersContext(
                    context => ({
                        ...context,
                        activeFilter: undefined
                    }));
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        open: false
                    }));

                if (!_.isNil(filter?.type)) {
                    trackAnalytics(
                        AnalyticsEventActionType.FilterValueTimeSet,
                        {
                            "Filter Time Type": localization.type[filter!.type]()
                        });
                }
            }}>
            <FilterField
                emptyValue={_.isNil(filter?.type)}
                focused={open}
                placeholder={placeholder}
                selection={
                    <SelectionView
                        empty={_.isNil(filter?.type) || _.isNil(selectionText)}
                        enableAll={false}
                        label={placeholder}
                        selectedValues={[selectionText]}
                        totalCount={1}/>}/>
        </Dropdown>);
}

type DateTimeRangeFilterProps = {
    range?: TimeRangeFilterRange;
    type: TimeRangeFilterType;
};

function DateTimeRangeFilter({ range, type }: DateTimeRangeFilterProps) {
    const setFilterConnectorContext = useSetFilterConnectorContext();
    const { filter } = useFilterConnectorContext() as { filter?: TimeRangeFilterSelection };

    useEffect(
        () => {
            if (filter?.type !== type) {
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        filter: undefined
                    }));
            }
        },
        [filter, type]);

    const defaultTime =
        useMemo(
            () => {
                const today =
                    moment().
                        endOf("day");
                if (moment(range?.startTime).
                    endOf("day").
                    isAfter(today)) {
                    return range?.startTime;
                }

                if (moment(range?.endTime).
                    endOf("day").
                    isBefore(today)) {
                    return range?.endTime;
                }

                return today.
                    utc().
                    toISOString();
            },
            [range]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {type === TimeRangeFilterType.DateBetween
                ? <StaticDateRangePicker
                    displayStaticWrapperAs="desktop"
                    maxDate={
                        _.isNil(range)
                            ? undefined
                            : moment(range?.endTime)}
                    minDate={
                        _.isNil(range)
                            ? undefined
                            : moment(range?.startTime)}
                    slots={{
                        actionBar: () => <React.Fragment/>
                    }}
                    value={
                        filter?.type === TimeRangeFilterType.DateBetween
                            ? [
                                moment(filter.dateStartTime),
                                moment(filter.dateEndTime)
                            ]
                            : [
                                moment(defaultTime),
                                moment(defaultTime)
                            ]}
                    onChange={
                        ([startTime, endTime]: [any, any]) => {
                            setFilterConnectorContext(
                                context => ({
                                    ...context,
                                    filter:
                                        !_.isNil(startTime) && !_.isNil(endTime)
                                            ? new TimeRangeFilterSelection(
                                                moment(endTime).
                                                    endOf("day").
                                                    utc().
                                                    toISOString(),
                                                moment(startTime).
                                                    startOf("day").
                                                    utc().
                                                    toISOString(),
                                                undefined,
                                                TimeRangeFilterType.DateBetween)
                                            : undefined
                                }));
                        }}/>
                : <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={
                        filter?.type === type
                            ? moment(filter.dateStartTime) ?? moment(filter.dateEndTime)
                            : null}
                    onChange={
                        time => {
                            setFilterConnectorContext(
                                context => ({
                                    ...context,
                                    filter:
                                        !_.isNil(time)
                                            ? new TimeRangeFilterSelection(
                                                type === TimeRangeFilterType.DateBefore
                                                    ? moment(time).
                                                        startOf("day").
                                                        utc().
                                                        toISOString()
                                                    : undefined,
                                                type === TimeRangeFilterType.DateAfter
                                                    ? moment(time).
                                                        startOf("day").
                                                        utc().
                                                        toISOString()
                                                    : undefined,
                                                undefined,
                                                type)
                                            : undefined
                                }));
                        }}/>}
        </LocalizationProvider>);
}

type RelativeTimeRangeFilterProps = {
    type: TimeRangeFilterType;
};

function RelativeTimeRangeFilter({ type }: RelativeTimeRangeFilterProps) {
    const setFilterConnectorContext = useSetFilterConnectorContext();
    const { filter } = useFilterConnectorContext() as { filter?: TimeRangeFilterSelection };

    const [unit, setUnit] = useState(filter?.relative?.unit ?? TimeRangeFilterSelectionRelativeUnit.Days);
    const [value, setValue] =
        useState(
            type === filter?.type
                ? filter.relative!.value
                : type === TimeRangeFilterType.RelativeInTheLast || type === TimeRangeFilterType.RelativeInTheNext
                    ? 30
                    : 0);
    const localization =
        useLocalization(
            "infrastructure.filters.timeRangeFilter.relativeTimeRangeFilter",
            () => ({
                title: "Period",
                unit: {
                    [TimeRangeFilterSelectionRelativeUnit.Days]: "Days",
                    [TimeRangeFilterSelectionRelativeUnit.Months]: "Months",
                    [TimeRangeFilterSelectionRelativeUnit.Weeks]: "Weeks"
                }
            }));

    useChangeEffect(
        () => {
            setUnit(TimeRangeFilterSelectionRelativeUnit.Days);
            setValue(
                type === TimeRangeFilterType.RelativeInTheLast || type === TimeRangeFilterType.RelativeInTheNext
                    ? 30
                    : 0);
        },
        [type]);

    useLayoutEffect(
        () => {
            if (filter?.type !== type ||
                filter?.relative?.unit !== unit ||
                filter?.relative?.value !== value) {
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        filter:
                            new TimeRangeFilterSelection(
                                undefined,
                                undefined,
                                new TimeRangeFilterSelectionRelative(
                                    unit,
                                    value),
                                type)
                    }));
            }
        },
        [filter, type, unit, value]);

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{ width: "100%" }}>
            <TextField
                size="small"
                slotProps={{
                    htmlInput: {
                        max: 999,
                        min: 0
                    },
                    input: {
                        sx: {
                            borderRadius: theme.spacing(0.75),
                            height: theme.spacing(4.5)
                        }
                    }
                }}
                sx={{ flex: 1 }}
                type="number"
                value={value}
                onChange={
                    event => {
                        const newValue =
                            _.isEmpty(event.target.value)
                                ? 0
                                : _.parseInt(event.target.value.substring(0, 3));
                        event.target.value = newValue.toString();
                        setValue(newValue);
                    }}
                onFocus={event => event.target.select()}/>
            <Box sx={{ flex: 2 }}>
                <ItemSelector
                    dense={true}
                    fieldSx={{ padding: theme.spacing(0.5, 0) }}
                    fullWidth={true}
                    items={[
                        TimeRangeFilterSelectionRelativeUnit.Days,
                        TimeRangeFilterSelectionRelativeUnit.Weeks,
                        TimeRangeFilterSelectionRelativeUnit.Months
                    ]}
                    placeholder={localization.title()}
                    selectedItem={unit}
                    sorted={false}
                    onSelectedItemChanged={setUnit}>
                    {item =>
                        <Typography>
                            {localization.unit[item as TimeRangeFilterSelectionRelativeUnit]()}
                        </Typography>}
                </ItemSelector>
            </Box>
        </Stack>);
}