import _, { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, Entity, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAzureCommonKubernetesNamespaceResourceDefinition } from "./useAzureCommonKubernetesNamespaceResourceDefinition";

export function useAzureKubernetesIngressDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const { columns, initialFilterMap } = useAzureCommonKubernetesNamespaceResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureKubernetesIngressDefinition",
            () => ({
                columns: {
                    loadBalancer: "Load Balancer"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            columns.clusterColumn,
            columns.tenantColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureKubernetesIngressModel>(
                        Contract.TypeNames.EntityModel,
                        item => [item.loadBalancerSearchableReference?.id],
                        localization.columns.loadBalancer())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.KubernetesIngressLoadBalancer)}
                                placeholder={localization.columns.loadBalancer()}/>
                    }
                }}
                id={Contract.EntityModelProperty.KubernetesIngressLoadBalancer}
                key={Contract.EntityModelProperty.KubernetesIngressLoadBalancer}
                render={
                    optionalTableCell(
                        item =>
                            _.isNil(item.loadBalancerSearchableReference)
                                ? undefined
                                : <Entity
                                    entityIdOrModel={item.loadBalancerSearchableReference.id}
                                    variant="iconText"/>)}
                title={localization.columns.loadBalancer()}/>,
            columns.namespaceColumn,
            columns.tagsColumn,
            columns.attributesColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureKubernetesIngressRequest(
                new Contract.EntityControllerGetEntityModelPageAzureKubernetesIngressRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesResourceCluster])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesNamespaceResourceNamespace])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesIngress(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesIngressLoadBalancer]))),
        {
            initialFilterMap
        });
}