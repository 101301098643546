import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useContainerImageDataScanStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useContainerImageDataScanStatusTranslator",
            () => ({
                [Contract.TypeNames.ContainerImageDataScanStatus]: {
                    [Contract.ContainerImageDataScanStatus.ErrorAccessDenied]: "Missing Permissions",
                    [Contract.ContainerImageDataScanStatus.ErrorScanFailure]: "Scan error",
                    [Contract.ContainerImageDataScanStatus.ErrorUnsupportedMediaType]: "Image type not supported by AWS",
                    [Contract.ContainerImageDataScanStatus.NotScanned]: "Not scanned",
                    [Contract.ContainerImageDataScanStatus.Scanned]: "No vulnerabilities"
                }
            }));
    return (status: Contract.ContainerImageDataScanStatus) =>
        localization[Contract.TypeNames.ContainerImageDataScanStatus][status]();
}