import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InsertTrustedFileIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M23.457 13.5429C23.8475 13.9334 23.8475 14.5666 23.457 14.9571L18.192 20.2221C17.8014 20.6126 17.1683 20.6126 16.7778 20.2221L14.5278 17.9721C14.1372 17.5816 14.1372 16.9484 14.5278 16.5579C14.9183 16.1674 15.5514 16.1674 15.942 16.5579L17.4849 18.1008L22.0428 13.5429C22.4333 13.1524 23.0664 13.1524 23.457 13.5429Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M17.4024 3.19551C16.8859 3.01436 16.2001 3.00009 14.318 3.00009C11.0833 3.00009 9.72476 3.01438 8.67029 3.38462C6.90692 4.00381 5.54034 5.30299 4.89869 6.92245C4.71848 7.37701 4.61182 7.9271 4.55646 8.76641C4.50051 9.61463 4.5 10.6874 4.5 12.1821V14.7271C4.5 16.2863 4.50162 17.3682 4.59914 18.1967C4.69389 19.0016 4.86794 19.4444 5.13059 19.7745C5.31575 20.0074 5.53359 20.2125 5.77716 20.3834L5.77918 20.3848C6.14633 20.6436 6.64341 20.8143 7.52067 20.9057C8.41377 20.9988 9.57552 21.0001 11.227 21.0001H12.5C13.0523 21.0001 13.5 21.4478 13.5 22.0001C13.5 22.5524 13.0523 23.0001 12.5 23.0001H11.1707C9.58846 23.0001 8.32229 23.0001 7.31333 22.895C6.27705 22.7869 5.39244 22.5588 4.62783 22.0201C4.22751 21.7393 3.86967 21.4023 3.5653 21.0195C2.98003 20.2837 2.7301 19.4265 2.61286 18.4305C2.49996 17.4714 2.49998 16.2715 2.5 14.7894L2.5 12.1464C2.5 10.6951 2.49999 9.55651 2.56079 8.63477C2.62268 7.69667 2.75058 6.91413 3.03932 6.18574C3.90569 3.99929 5.72713 2.29836 8.0077 1.49757C9.42625 0.999495 11.1574 0.999706 14.0749 1.00007C14.155 1.00008 14.236 1.00009 14.318 1.00009C14.3886 1.00009 14.4582 1.00007 14.5268 1.00005C16.1229 0.99949 17.1836 0.99912 18.0652 1.30852C19.4781 1.8052 20.6167 2.86259 21.1602 4.23263C21.3493 4.70786 21.4273 5.20524 21.4642 5.76266C21.5 6.30372 21.5 6.96643 21.5 7.784V10.0001C21.5 10.5524 21.0523 11.0001 20.5 11.0001C19.9477 11.0001 19.5 10.5524 19.5 10.0001V7.81809C19.5 6.95836 19.4995 6.36197 19.4686 5.89478C19.4382 5.43655 19.3817 5.17252 19.3019 4.97183L19.3014 4.97068C18.9829 4.16728 18.2989 3.51079 17.4024 3.19551Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M3.5 13.0001C2.94772 13.0001 2.5 12.5524 2.5 12.0001C2.5 10.8509 2.95651 9.74882 3.76911 8.93623C4.5817 8.12363 5.68382 7.66712 6.833 7.66712C7.02509 7.66712 7.21986 7.67545 7.39682 7.6836C7.41955 7.68465 7.44198 7.6857 7.46415 7.68673C7.62146 7.69405 7.76577 7.70076 7.90959 7.70371C8.24344 7.71054 8.48767 7.69344 8.67217 7.6442C8.78521 7.61364 8.88826 7.55399 8.97106 7.47118C9.0539 7.38835 9.11356 7.28524 9.14411 7.17216C9.19333 6.98766 9.21042 6.74348 9.20359 6.40971C9.20064 6.26589 9.19393 6.12158 9.18661 5.96427C9.18557 5.9421 9.18453 5.91967 9.18348 5.89695C9.17533 5.71998 9.167 5.52522 9.167 5.33312C9.167 4.7641 9.27908 4.20066 9.49683 3.67495C9.71459 3.14925 10.0338 2.67159 10.4361 2.26923C10.8385 1.86687 11.3161 1.54771 11.8418 1.32995C12.3675 1.1122 12.931 1.00012 13.5 1.00012C14.0523 1.00012 14.5 1.44784 14.5 2.00012C14.5 2.55241 14.0523 3.00012 13.5 3.00012C13.1936 3.00012 12.8903 3.06047 12.6072 3.17771C12.3241 3.29496 12.067 3.4668 11.8503 3.68344C11.6337 3.90008 11.4618 4.15727 11.3446 4.44032C11.2273 4.72338 11.167 5.02675 11.167 5.33312C11.167 5.47403 11.1732 5.62714 11.1814 5.80489C11.1823 5.82609 11.1833 5.84769 11.1844 5.86964C11.1916 6.02453 11.1996 6.19687 11.2032 6.36879C11.2111 6.75537 11.1997 7.22701 11.0761 7.68944L11.0757 7.69082C10.9542 8.14263 10.7161 8.55457 10.3853 8.8854C10.0545 9.21622 9.64251 9.45431 9.1907 9.57581L9.18931 9.57618C8.72688 9.69983 8.25525 9.7112 7.86867 9.70329C7.69676 9.69977 7.52443 9.69172 7.36955 9.68449C7.34759 9.68347 7.32598 9.68246 7.30477 9.68148C7.12702 9.67329 6.97391 9.66712 6.833 9.66712C6.21425 9.66712 5.62084 9.91292 5.18332 10.3504C4.7458 10.788 4.5 11.3814 4.5 12.0001C4.5 12.5524 4.05229 13.0001 3.5 13.0001Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}