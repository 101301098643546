import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { RiskPoliciesType, RiskPoliciesView, RiskPolicyItem } from "../../../../../../../../common";
import { useCloudRiskPolicyInfoItems, useCodeRiskPolicyInfoItems, useKubernetesAdmissionControllerRiskPolicyInfoItems } from "./hooks";

export type RiskPolicyInfoItems = {
    additionalInfoCard?: RiskPolicyInfoItemsInfoCard[];
    description?: ReactNode;
    general: ReactNode[];
    topbar: RiskPolicyInfoItemsTopbar;
};

type RiskPolicyInfoItemsInfoCard = {
    items: ReactNode[];
    title?: string;
};

type RiskPolicyInfoItemsTopbar = {
    subtitle: string;
    title: string;
};

export function useRiskPolicyInfoItems(policyType: RiskPoliciesType, scopeId: string, item: RiskPolicyItem, policyView = RiskPoliciesView.BuiltIn): RiskPolicyInfoItems {
    const useRiskPolicyInfoItems =
        useMemo(
            () => {
                switch (policyType) {
                    case RiskPoliciesType.Cloud:
                        return useCloudRiskPolicyInfoItems;
                    case RiskPoliciesType.Code:
                        return useCodeRiskPolicyInfoItems;
                    case RiskPoliciesType.KubernetesAdmissionController:
                        return useKubernetesAdmissionControllerRiskPolicyInfoItems;
                    default:
                        throw new UnexpectedError("useRiskPolicyInfoItems.policyType", policyType);
                }
            },
            [policyType, scopeId, policyView]);

    return useRiskPolicyInfoItems(scopeId, item, policyView);
}