import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingLocalPeeringGatewayPeeringStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingLocalPeeringGatewayPeeringStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingLocalPeeringGatewayPeeringStatus]: {
                    [Contract.OciNetworkingLocalPeeringGatewayPeeringStatus.Invalid]: "Invalid",
                    [Contract.OciNetworkingLocalPeeringGatewayPeeringStatus.New]: "New",
                    [Contract.OciNetworkingLocalPeeringGatewayPeeringStatus.Peered]: "Peered",
                    [Contract.OciNetworkingLocalPeeringGatewayPeeringStatus.Pending]: "Pending",
                    [Contract.OciNetworkingLocalPeeringGatewayPeeringStatus.Revoked]: "Revoked"
                }
            }));
    return (peeringStatus: Contract.OciNetworkingLocalPeeringGatewayPeeringStatus) =>
        localization[Contract.TypeNames.OciNetworkingLocalPeeringGatewayPeeringStatus][peeringStatus]();
}