import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsKmsKeyRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";

export function useAwsKmsKeyRotationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const kmsKeyRotationDisabledRiskModel = riskModel as Contract.AwsKmsKeyRotationDisabledRiskModel;
    const risk = riskModel.risk as Contract.AwsKmsKeyRotationDisabledRisk;
    const kmsKeyModel = entityModelStore.useGet(kmsKeyRotationDisabledRiskModel.risk.entityId) as Contract.AwsKmsKeyModel;
    const kmsKey = kmsKeyModel.entity as Contract.AwsKmsKey;

    const getAwsKmsKeyRiskContext = useGetAwsKmsKeyRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsKmsKeyRotationDisabledRiskDefinition",
            () => ({
                description: {
                    rotationTimeFrameExists: {
                        false: "{{rotationDisabledCustomerManagedKey}} automatic rotation is not enabled",
                        true: "{{rotationDisabledCustomerManagedKey}} was not rotated for more than {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                    }
                },
                sections: {
                    resolution: {
                        step1: "Select the **Key rotation** tab",
                        step2: "Within the **Automatic key rotation** section click **Edit**",
                        step3: "Set the **Key rotation** to **Enabled** and the **Rotation period (in days)** to {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}} or less",
                        step4: "Click **Save** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (kmsKey.rotationEnabled
            ? localization.description.rotationTimeFrameExists.true
            : localization.description.rotationTimeFrameExists.false)({
            rotationDisabledCustomerManagedKey:
                <Entity
                    entityIdOrModel={kmsKeyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            rotationTimeFrame: risk.keyRotationTimeFrame
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Kms,
                AwsConsoleUrlBuilder.getKmsKeyUrl(kmsKey)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3({ keyRotationTimeFrame: kmsKeyRotationDisabledRiskModel.risk.keyRotationTimeFrame }),
            localization.sections.resolution.step4()
        ],
        kmsKeyRotationDisabledRiskModel,
        () => {
            const keyRiskContext = getAwsKmsKeyRiskContext(kmsKeyModel);
            return [
                keyRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.keyRotationTimeFrame),
                keyRiskContext.rotationTime,
                keyRiskContext.enabled,
                keyRiskContext.sensitive,
                keyRiskContext.resources,
                keyRiskContext.publicAccessPermissionActions,
                keyRiskContext.accessLevel,
                keyRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}