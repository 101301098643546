import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Permitter } from "../../../../../../..";
import { AwsResourcePermitterType } from "../../../../../../../../tenants";

export type InlinePermitterPoliciesProps = {
    entityTypeName: string;
    permissionPathIdentifiers: Contract.AwsAccessGraphPermissionPathIdentifier[];
};

export function InlinePermitterPolicies({ entityTypeName, permissionPathIdentifiers }: InlinePermitterPoliciesProps) {
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAwsDefinition.inlinePermitterPolicies",
            () => ({
                policies: [
                    "1 policy",
                    "{{count | NumberFormatter.humanize}} policies"
                ]
            }));
    return (
        <InlineItems
            items={
                _<string | AwsResourcePermitterType>([]).
                    concat(
                        _(permissionPathIdentifiers).
                            map(principalPolicyPermissionPathIdentifier => principalPolicyPermissionPathIdentifier.principalPolicyId!).
                            filter().
                            uniq().
                            value()).
                    concat(
                        _(permissionPathIdentifiers).
                            map(resourcePermitterPermissionPathIdentifier => resourcePermitterPermissionPathIdentifier.resourcePermitterType!).
                            filter().
                            uniq().
                            map(
                                resourcePermitterType =>
                                    new AwsResourcePermitterType(
                                        resourcePermitterType,
                                        entityTypeName)).
                            value()).
                    value()}
            namePluralizer={localization.policies}
            variant="itemOrItemCountAndType">
            {(item: string | AwsResourcePermitterType, inline) =>
                <Permitter
                    permitterEntityIdOrPermitterType={item}
                    variant={
                        inline
                            ? "text"
                            : "iconTextTenant"}/>}
        </InlineItems>);
}