import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../utilities";
import { useGcpComputeInstanceMemoryEncryptionNotEnabledRiskInstanceDefinition, useGcpComputeInstanceMemoryEncryptionNotEnabledRiskInstanceTemplateDefinition } from "./hooks";

export function useGcpComputeInstanceMemoryEncryptionNotEnabledRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const risk = riskModel.risk as Contract.GcpComputeInstanceMemoryEncryptionNotEnabledRisk;
    const useDefinition =
        useMemo(
            () => {
                switch (risk.aggregatingEntityTypeName) {
                    case Contract.TypeNames.GcpComputeInstance:
                        return useGcpComputeInstanceMemoryEncryptionNotEnabledRiskInstanceDefinition;
                    case Contract.TypeNames.GcpComputeInstanceTemplate:
                        return useGcpComputeInstanceMemoryEncryptionNotEnabledRiskInstanceTemplateDefinition;
                    default:
                        throw new UnexpectedError("risk.aggregatingEntityTypeName", risk.aggregatingEntityTypeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.GcpComputeInstanceMemoryEncryptionNotEnabledRiskModel);
}