import { DataTableColumn, EmptyMessageText, ItemSelector, Loading, optionalTableCell, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Base64Helper, Contract, InfoCard, InfoItem, InlineEntities, InlineTextViewer } from "../../../../../../../../../../../../common";
import { AwsEc2DeviceTable } from "../../../../../../../../../../../../tenants";
import { EntitiesInfoItem } from "../../../../../../components";
import { useAwsEc2InstanceMetadataServiceInfoItems } from "../../useAwsEc2InstanceMetadataServiceInfoItems";

type RevisionsProps = {
    launchTemplateModel: Contract.AwsEc2LaunchTemplateModel;
};

export function Revisions({ launchTemplateModel }: RevisionsProps) {
    const launchTemplate = launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate;
    const [selectedRevision, setSelectedRevision] = useState(() => launchTemplate.revisionMap[launchTemplate.latestRevisionId]);
    const instanceMetadataServiceInfoItems = useAwsEc2InstanceMetadataServiceInfoItems(selectedRevision.metadataService);

    const deviceNameToKmsEncryptionKeyIdReferenceMap =
        useMemo(
            () =>
                _(selectedRevision.devices).
                    filter(device => !_.isNil(device.kmsEncryptionKeyOrAliasArn)).
                    keyBy(device => device.name).
                    mapValues(device => launchTemplateModel.kmsEncryptionKeyOrAliasArnToIdReferenceMap[device.kmsEncryptionKeyOrAliasArn!]).
                    value(),
            [selectedRevision]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2LaunchTemplateDefinition.revisions",
            () => ({
                fields: {
                    autoScalingGroupIds: "Auto Scaling Groups",
                    creationTime: "Creation Time",
                    creatorIdentityReference: "Created By",
                    description: "Description",
                    devices: {
                        empty: "No Block Device Mappings",
                        title: "Block Device Mappings"
                    },
                    imageId: "Image",
                    instanceIds: "Instances",
                    instanceRawType: "Instance Type",
                    keyName: "Key Pair",
                    kmsEncryptionKeyReferences: "Encryption Key",
                    network: {
                        empty: "No Network",
                        networkInterfaceIds: "Network Interfaces",
                        securityGroupIds: "Security Groups",
                        subnetIds: "Subnet",
                        title: "Network",
                        vpcIds: "VPC"
                    },
                    roleReference: "Role",
                    selectedRevision: {
                        default: "Default : {{revisionId}}",
                        latest: "Latest : {{revisionId}}"
                    },
                    userData: {
                        open: "View",
                        title: "User Data"
                    }
                }
            }));

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(launchTemplate.revisionMap).
                        keys().
                        orderBy(
                            [
                                revisionId => Number(revisionId) === launchTemplate.latestRevisionId,
                                revisionId => Number(revisionId) !== launchTemplate.latestRevisionId && Number(revisionId)
                            ],
                            [
                                "desc",
                                "desc"
                            ]).
                        value()}
                selectedItem={selectedRevision.id}
                sorted={false}
                onSelectedItemChanged={revisionId => setSelectedRevision(launchTemplate.revisionMap[revisionId])}>
                {revisionId =>
                    Number(revisionId) === launchTemplate.defaultRevisionId
                        ? localization.fields.selectedRevision.default({ revisionId })
                        : Number(revisionId) === launchTemplate.latestRevisionId
                            ? localization.fields.selectedRevision.latest({ revisionId })
                            : revisionId}
            </ItemSelector>
            <Loading>
                <InfoCard columns={true}>
                    <InfoItem
                        key="description"
                        title={localization.fields.description()}
                        value={selectedRevision.description}/>
                    <InfoItem
                        key="creationTime"
                        title={localization.fields.creationTime()}
                        value={TimeFormatter.profileFormatDateTime(selectedRevision.creationTime)}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevision.creatorIdentityReference.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamResource}
                        key="creatorIdentityReference"
                        title={localization.fields.creatorIdentityReference()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={launchTemplateModel.revisionIdToDataMap[selectedRevision.id].instanceIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        key="instanceIds"
                        title={localization.fields.instanceIds()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={launchTemplateModel.revisionIdToDataMap[selectedRevision.id].autoScalingGroupIds}
                        entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                        key="autoScalingGroupIds"
                        title={localization.fields.autoScalingGroupIds()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevision?.roleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="roleReference"
                        title={localization.fields.roleReference()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={launchTemplateModel.revisionIdToDataMap[selectedRevision.id]?.imageId}
                        entityTypeName={Contract.TypeNames.AwsEc2Image}
                        key="imageId"
                        title={localization.fields.imageId()}/>
                    <InfoItem
                        key="instanceRawType"
                        title={localization.fields.instanceRawType()}
                        value={
                            _.isNil(selectedRevision.instanceRawType)
                                ? undefined
                                : selectedRevision.instanceRawType}/>
                    <InfoItem
                        key="keyName"
                        title={localization.fields.keyName()}
                        value={
                            _.isNil(selectedRevision.keyName)
                                ? undefined
                                : selectedRevision.keyName}/>
                    {...instanceMetadataServiceInfoItems}
                    <InfoItem
                        key="userData"
                        title={localization.fields.userData.title()}
                        value={
                            _.isNil(selectedRevision.userData)
                                ? undefined
                                : <InlineTextViewer
                                    dialogTitle={localization.fields.userData.title()}
                                    text={
                                        Base64Helper.isValid(selectedRevision.userData)
                                            ? atob(selectedRevision.userData)
                                            : selectedRevision.userData}
                                    title={localization.fields.userData.open()}/>}/>
                </InfoCard>
                <InfoCard
                    emptyMessage={new EmptyMessageText(localization.fields.network.empty())}
                    title={localization.fields.network.title()}>
                    <InfoCard columns={true}>
                        <EntitiesInfoItem
                            entityIdsOrModels={selectedRevision.vpcIds}
                            entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                            key="vpcIds"
                            title={localization.fields.network.vpcIds()}/>
                        <EntitiesInfoItem
                            entityIdsOrModels={selectedRevision.subnetIds}
                            entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                            key="subnetIds"
                            title={localization.fields.network.subnetIds()}/>
                        <EntitiesInfoItem
                            entityIdsOrModels={selectedRevision.securityGroupIds}
                            entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                            key="securityGroupIds"
                            title={localization.fields.network.securityGroupIds()}/>
                        <EntitiesInfoItem
                            entityIdsOrModels={selectedRevision.networkInterfaceIds}
                            entityTypeName={Contract.TypeNames.AwsEc2NetworkInterface}
                            key="networkInterfaceIds"
                            title={localization.fields.network.networkInterfaceIds()}/>
                    </InfoCard>
                </InfoCard>
                <InfoCard
                    emptyMessage={new EmptyMessageText(localization.fields.devices.empty())}
                    key="devices"
                    title={localization.fields.devices.title()}>
                    {_.isEmpty(selectedRevision.devices)
                        ? undefined
                        : <AwsEc2DeviceTable
                            additionalColumns={[
                                <DataTableColumn
                                    id="kmsEncryptionKeyReferences"
                                    key="kmsEncryptionKeyReferences"
                                    render={
                                        optionalTableCell<Contract.AwsEc2Device>(
                                            item =>
                                                _.isEmpty(deviceNameToKmsEncryptionKeyIdReferenceMap[item.name])
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={deviceNameToKmsEncryptionKeyIdReferenceMap[item.name]}
                                                        entityTypeName={Contract.TypeNames.AwsKmsKey}
                                                        entityVariant="iconTextTypeTenant"
                                                        variant="itemPlusItemCount"/>)}
                                    title={localization.fields.kmsEncryptionKeyReferences()}/>
                            ]}
                            devices={selectedRevision.devices}
                            snapshotRawIdToIdMap={launchTemplateModel.snapshotRawIdToIdMap}/>}
                </InfoCard>
            </Loading>
        </InfoCard>);
}