import { Optional, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, memo, useState } from "react";
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Contract, useTheme, VulnerabilityEventType, VulnerabilityScoreSourceType } from "../../../../../../../../../../../common";
import { ChartDataItem } from "../../TimelineScoreAndEventTrendChart";
import { CustomizedPoint, CustomReferenceLineLabel, CustomYAxisTick, Tooltip as CustomTooltip, TooltipData } from "./components";

const TimelineScoreAndEventTrendMemo = memo(TimelineScoreAndEventTrend);
export { TimelineScoreAndEventTrendMemo as TimelineScoreAndEventTrend };

export type ChartClickEvent = {
    activeCoordinate?: ChartClickEventCoordinate;
    activePayload?: any[];
    chartX?: number;
    chartY?: number;
};

export type ChartClickEventCoordinate = {
    x: Optional<number>;
    y: Optional<number>;
};

type TimelineScoreAndEventTrendProps = {
    activeEventType?: VulnerabilityEventType;
    hoverEventType?: VulnerabilityEventType;
    items: ChartDataItem[];
    ticks: number[];
    title: string;
    type: VulnerabilityScoreSourceType.Vpr | VulnerabilityScoreSourceType.Epss;
    xAxisDomain: [number, number];
    yAxisDomain: [number, number];
};

function TimelineScoreAndEventTrend({ activeEventType, hoverEventType, items, ticks, title, type, xAxisDomain, yAxisDomain }: TimelineScoreAndEventTrendProps) {
    const [tooltipData, setTooltipData] = React.useState<TooltipData>();
    const [tooltipFreeze, setTooltipFreeze] = React.useState(false);
    const [hoveringGraph, setHoveringGraph] = useState(false);

    function handleChartClick(event: ChartClickEvent) {
        if (event && event.activeCoordinate) {
            setTooltipData({
                coordinate: {
                    x: event.chartX,
                    y: event.chartY
                },
                payload: event.activePayload
            });
            setTooltipFreeze(true);
        } else {
            setTooltipFreeze(false);
        }
    }

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.timelineScoreAndEventTrendChart.timelineScoreAndEventTrend",
            () => ({
                critical: "Critical",
                high: "High"
            }));

    const theme = useTheme();
    return (
        <ResponsiveContainer height={300}>
            <LineChart
                data={items}
                margin={{
                    bottom: 0,
                    left:
                        type === VulnerabilityScoreSourceType.Vpr
                            ? -25
                            : -8,
                    right: 10,
                    top: 25
                }}
                onClick={handleChartClick}
                onMouseEnter={() => setHoveringGraph(true)}
                onMouseLeave={
                    () => {
                        setHoveringGraph(false);
                        setTooltipFreeze(false);
                    }}>
                <CartesianGrid
                    horizontal={
                        props =>
                            type === VulnerabilityScoreSourceType.Vpr &&
                                props.index === _.ceil(_.last(yAxisDomain)!)
                                ? <Fragment/>
                                : <line {...(props as unknown as React.SVGProps<SVGLineElement>)}/>
                    }
                    stroke={theme.palette.borders.pageFrame}
                    vertical={false}/>
                <XAxis
                    axisLine={{ stroke: theme.palette.borders.primary }}
                    dataKey="dayTime"
                    domain={xAxisDomain}
                    tick={{ fill: theme.palette.text.secondary }}
                    tickFormatter={TimeFormatter.monthDayAndYear}
                    tickLine={false}
                    tickMargin={8}
                    type="number"/>
                <YAxis
                    axisLine={{ stroke: theme.palette.borders.primary }}
                    dataKey="score"
                    domain={yAxisDomain}
                    label={{
                        angle: -90,
                        bold: true,
                        dx:
                            type === VulnerabilityScoreSourceType.Vpr
                                ? 25
                                : 8,
                        dy: 0,
                        fill: theme.palette.text.primary,
                        fontFamily: "inherit",
                        fontSize: 12,
                        fontWeight: 600,
                        lineHeight: 1.2,
                        position: "insideLeft",
                        value: title
                    }}
                    tick={<CustomYAxisTick percentage={type === VulnerabilityScoreSourceType.Epss}/>}
                    tickLine={true}
                    tickMargin={4}
                    ticks={ticks}/>
                <Tooltip
                    animationDuration={0}
                    content={
                        <CustomTooltip
                            activeEventType={activeEventType}
                            data={tooltipData}
                            freeze={tooltipFreeze}
                            hoverEventType={hoverEventType}
                            startTime={items[0].dayTime}
                            type={type}/>}
                    cursor={!tooltipFreeze}
                    trigger={
                        type === VulnerabilityScoreSourceType.Vpr ||
                            hoveringGraph &&
                            !tooltipFreeze
                            ? "hover"
                            : "click"}/>
                {!_.isNil(tooltipData?.payload?.[0].payload.dayTime) &&
                    tooltipFreeze &&
                    <ReferenceLine
                        isFront={false}
                        stroke={theme.palette.chart.verticalLine}
                        strokeWidth={1}
                        x={tooltipData?.payload?.[0].payload.dayTime}/>}
                <ReferenceLine
                    isFront={false}
                    label={
                        <CustomReferenceLineLabel
                            color={theme.palette.severity(Contract.Severity.High)}
                            value={localization.high()}/>}
                    stroke={theme.palette.severity(Contract.Severity.High)}
                    strokeDasharray={"5 5"}
                    strokeWidth={1.5}
                    y={7}/>
                <ReferenceLine
                    isFront={false}
                    label={
                        <CustomReferenceLineLabel
                            color={theme.palette.severity(Contract.Severity.Critical)}
                            value={localization.critical()}/>}
                    stroke={theme.palette.severity(Contract.Severity.Critical)}
                    strokeDasharray={"5 5"}
                    strokeWidth={1.5}
                    y={9}/>
                <Line
                    activeDot={false}
                    dataKey="score"
                    dot={
                        <CustomizedPoint
                            activeEventType={activeEventType}
                            hoverEventType={hoverEventType}/>}
                    isAnimationActive={false}
                    stroke={theme.palette.text.primary}
                    strokeWidth={1.5}
                    type="monotone"/>
            </LineChart>
        </ResponsiveContainer>);
}