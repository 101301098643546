﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ItalyIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5261)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M8 4.00012H0V19.9639H8V4.00012Z"
                    fill="#009246"/>
                <path
                    d="M15.9999 4.00012H7.99994V19.9639H15.9999V4.00012Z"
                    fill="white"/>
                <path
                    clipRule="evenodd"
                    d="M16 5H8V4H16V5Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M16 20H8V19H16V20Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    d="M24.0001 4.00012H16.0001V19.9639H24.0001V4.00012Z"
                    fill="#CE2B37"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5261">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}