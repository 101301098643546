import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGetGcpKmsKeyRingKeyRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpKmsKeyRingKeyRotationTimeFrameRiskDefinition(riskModel: Contract.RiskModel) {
    const kmsKeyRingKeyRotationTimeFrameRiskModel = riskModel as Contract.GcpKmsKeyRingKeyRotationTimeFrameRiskModel;
    const risk = riskModel.risk as Contract.GcpKmsKeyRingKeyRotationTimeFrameRisk;
    const keyModel = entityModelStore.useGet(kmsKeyRingKeyRotationTimeFrameRiskModel.risk.entityId) as Contract.GcpKmsKeyRingKeyModel;
    const key = keyModel.entity as Contract.GcpKmsKeyRingKey;

    const getGcpKmsKeyRingKeyRiskContext = useGetGcpKmsKeyRingKeyRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpKmsKeyRingKeyRotationTimeFrameRiskDefinition",
            () => ({
                description: {
                    rotationTimeFrameExists: {
                        false: "{{key}} rotation period is not set",
                        true: "{{key}} was not rotated for more than {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                    }
                },
                sections: {
                    resolution: {
                        step1: "Click **Edit Rotation Period** on top, set the retention period for {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}} or less and click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isNil(key.rotationTimeFrame)
            ? localization.description.rotationTimeFrameExists.false
            : localization.description.rotationTimeFrameExists.true)({
            key:
                <Entity
                    entityIdOrModel={keyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            rotationTimeFrame: risk.keyRotationTimeFrame
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={kmsKeyRingKeyRotationTimeFrameRiskModel.risk.entityId}
                key={kmsKeyRingKeyRotationTimeFrameRiskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1({ keyRotationTimeFrame: kmsKeyRingKeyRotationTimeFrameRiskModel.risk.keyRotationTimeFrame })
        ],
        kmsKeyRingKeyRotationTimeFrameRiskModel,
        () => {
            const keyRiskContext = getGcpKmsKeyRingKeyRiskContext(keyModel);
            return [
                keyRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.keyRotationTimeFrame),
                keyRiskContext.rotationTime,
                keyRiskContext.sensitive,
                keyRiskContext.encryptedResources,
                keyRiskContext.keyRing,
                keyRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        });
}