﻿import { map, TimeFormatter, useLocalization } from "@infrastructure";
import React, { Fragment } from "react";
import { Contract, RiskHelper, RiskType, tenantModelStore, useRiskPolicyTitleTranslator, useTenantNameTranslator, useTheme } from "../../../../../common";
import { useRiskDefinition } from "../../../../Customer/components/Risks";
import { MailImg, MailTable, MailTd } from "../../Mail";

export type RiskMailCardProps = {
    riskModel: Contract.RiskModel;
    riskType: RiskType;
};

export function RiskMailCard({ riskModel, riskType }: RiskMailCardProps) {
    const riskDefinition = useRiskDefinition(riskType, riskModel);
    const tenantModel = tenantModelStore.useGet(riskModel.tenantId);

    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.system.exportRisksMail.riskMailCard",
            () => ({
                dateAndAccount: "Date: {{date}} | {{tenantType}}: {{tenant}}",
                riskTypeTenantType: {
                    [RiskType.Cloud]: "Account",
                    [RiskType.Code]: "Repository"
                }
            }));

    const theme = useTheme();
    return (
        <tr>
            <MailTd style={{ paddingBottom: 6 }}>
                <MailTable
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                    width="100%">
                    <tr>
                        <MailTd>
                            <MailTable
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                    border: "1px solid #F2F2F2",
                                    borderLeft: `4px solid ${theme.palette.severity(riskModel.risk.severity)}`
                                }}
                                width="100%">
                                <tr>
                                    <MailTd
                                        height="24"
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            paddingBottom: 2,
                                            paddingLeft: 12,
                                            paddingTop: 13
                                        }}>
                                        <a
                                            href={RiskHelper.getRiskProfileUrlByRiskType(riskModel.risk)}
                                            style={{ color: "#37474F" }}>
                                            {riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(riskModel.risk))}
                                        </a>
                                    </MailTd>
                                </tr>
                                <tr>
                                    <MailTd
                                        height="32"
                                        style={{
                                            fontSize: 12,
                                            paddingBottom: 11,
                                            paddingLeft: 12
                                        }}>
                                        {riskDefinition.description}
                                    </MailTd>
                                </tr>
                                <tr>
                                    <MailTd
                                        height="16"
                                        style={{
                                            paddingBottom: 15,
                                            paddingLeft: 12
                                        }}>
                                        <span style={{ fontSize: 12 }}>
                                            {localization.dateAndAccount({
                                                date:
                                                    <span
                                                        style={{
                                                            color: theme.palette.grey[500],
                                                            fontSize: 12
                                                        }}>
                                                        {TimeFormatter.shortDateTime(riskModel.risk.systemCreationTime)}
                                                    </span>,
                                                tenant:
                                                    <Fragment>
                                                        <MailImg
                                                            height="16"
                                                            src={
                                                                riskType === RiskType.Code
                                                                    ? map(
                                                                        (tenantModel as Contract.GitTenantModel).configuration.codeTenantType,
                                                                        {
                                                                            [Contract.CodeTenantType.AzureDevOps]: () => "cid:AzureDevOpsLogo",
                                                                            [Contract.CodeTenantType.Bitbucket]: () => "cid:BitbucketLogo",
                                                                            [Contract.CodeTenantType.GitHub]: () => "cid:GitHubLogo",
                                                                            [Contract.CodeTenantType.GitLab]: () => "cid:GitLabLogo"
                                                                        })
                                                                    : map(
                                                                        tenantModel.tenantType,
                                                                        {
                                                                            [Contract.TenantType.Aad]: () => "cid:AadLogo",
                                                                            [Contract.TenantType.Aws]: () => "cid:AwsLogo",
                                                                            [Contract.TenantType.Azure]: () => "cid:AzureLogo",
                                                                            [Contract.TenantType.Ci]: () => "cid:CiLogo",
                                                                            [Contract.TenantType.Gci]: () => "cid:GciLogo",
                                                                            [Contract.TenantType.Gcp]: () => "cid:GcpLogo",
                                                                            [Contract.TenantType.Oci]: () => "cid:OciLogo",
                                                                            [Contract.TenantType.Okta]: () => "cid:OktaLogo",
                                                                            [Contract.TenantType.OneLogin]: () => "cid:OneLoginLogo",
                                                                            [Contract.TenantType.Op]: () => "cid:OpLogo",
                                                                            [Contract.TenantType.PingIdentity]: () => "cid:PingIdentityLogo"
                                                                        })}
                                                            style={{
                                                                display: "inline-block",
                                                                height: 16,
                                                                verticalAlign: "middle",
                                                                width: 16
                                                            }}
                                                            width="16"/>
                                                        <span
                                                            style={{
                                                                color: theme.palette.grey[500],
                                                                fontSize: 12
                                                            }}>
                                                                &nbsp;{tenantNameTranslator(riskModel.risk.tenantId)}
                                                        </span>
                                                    </Fragment>,
                                                tenantType: localization.riskTypeTenantType[riskType]()
                                            })}
                                        </span>
                                    </MailTd>
                                </tr>
                                <tr>
                                    <MailTd
                                        height="1"
                                        style={{
                                            height: 1,
                                            width: 540
                                        }}
                                        width="540">
                                    </MailTd>
                                </tr>
                            </MailTable>
                        </MailTd>
                    </tr>
                </MailTable>
            </MailTd>
        </tr>);
}