import { Action1, MultiSelect, StringHelper, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useAuditEventTypeNameTranslator } from "../../../../../../../../../../AuditEvents/hooks";
import { AuditEventCategory } from "../AuditEventTypesItem";

type CategoryItemProps = {
    category: AuditEventCategory;
    disabled: boolean;
    onSelectedTypeNamesChanged: Action1<string[]>;
    selectedTypeNames: string[];
    typeNames: string[];
};

export function CategoryItem({ category, disabled, onSelectedTypeNamesChanged, selectedTypeNames, typeNames }: CategoryItemProps) {
    const auditEventTypeNameTranslator = useAuditEventTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.automations.auditEventAutomations.addOrEdit.auditEventTypesItem.categoryItem",
            () => ({
                category: {
                    [AuditEventCategory.General]: "Platform",
                    [AuditEventCategory.PermissionEligibilities]: "JIT Eligibilities",
                    [AuditEventCategory.PermissionRequests]: "JIT Access Requests"
                },
                multiple: "{{ selectedTypeNameCount }} selected",
                search: "Search",
                typeNames: "**Actions**"
            }));

    return (
        <MultiSelect
            disabled={disabled}
            fieldOptions={{ variant: "itemSelector" }}
            getItemText={auditEventTypeNameTranslator}
            items={
                _(typeNames).
                    orderBy(filteredTranslatedTypeName => StringHelper.getSortValue(filteredTranslatedTypeName)).
                    value()}
            placeholder={localization.category[category]()}
            selectedItems={selectedTypeNames}
            selectedItemsTranslator={
                selectedEntitiesViewEntityTypeNames =>
                    _.size(selectedEntitiesViewEntityTypeNames) === 1
                        ? auditEventTypeNameTranslator(selectedEntitiesViewEntityTypeNames[0])
                        : localization.multiple({ selectedTypeNameCount: selectedEntitiesViewEntityTypeNames.length })}
            onSelectedItemsChanged={onSelectedTypeNamesChanged}>
            {categoryItem =>
                <Typography noWrap={true}>
                    {auditEventTypeNameTranslator(categoryItem)}
                </Typography>}
        </MultiSelect>);
}