import React from "react";
import { Contract, GroupedTableColumnRenderProps, InlineTenants } from "../../../../../../../../../../../../../../../common";
import { GroupedItem } from "../../../../../../../Grouped";

export function Tenants({ item }: GroupedTableColumnRenderProps<GroupedItem>) {
    const riskPolicyTypeGroup = item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup;
    return (
        <InlineTenants
            sx={{
                color: "unset",
                textDecoration: "underline"
            }}
            tenantIds={riskPolicyTypeGroup.tenantIds}
            tenantVariant="iconText"
            title={true}
            variant="itemCountAndType"/>);
}