import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { useAwsCommonBehaviorIdentityRiskDefinition } from "./useAwsCommonBehaviorIdentityRiskDefinition";

export function useAwsPrivilegeEscalationRiskDefinition(riskModel: Contract.RiskModel) {
    const privilegeEscalationRiskModel = riskModel as Contract.AwsPrivilegeEscalationRiskModel;
    const identityModel = entityModelStore.useGet(privilegeEscalationRiskModel.risk.identityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.behavior.useAwsPrivilegeEscalationRiskDefinition",
            () => ({
                description: {
                    [Contract.TypeNames.AwsPrivilegeEscalationRiskModelDescriptionType]: {
                        [Contract.AwsPrivilegeEscalationRiskModelDescriptionType.AccessKeyUsers]: "{{identity}} was observed escalating its privileges by creating an access key for {{accessKeyUsers}}",
                        [Contract.AwsPrivilegeEscalationRiskModelDescriptionType.AssumedRoles]: "{{identity}} was observed escalating its privileges by assuming {{assumedRoles}}",
                        [Contract.AwsPrivilegeEscalationRiskModelDescriptionType.AssumedRolesAndAccessKeyUsers]: "{{identity}} was observed escalating its privileges by assuming {{assumedRoles}} and creating an access key for {{accessKeyUsers}}",
                        [Contract.AwsPrivilegeEscalationRiskModelDescriptionType.PermissionActionModification]: "{{identity}} was observed escalating its privileges by modifying their own permissions",
                        [Contract.AwsPrivilegeEscalationRiskModelDescriptionType.PermissionActionModificationAndAccessKeyUsers]: "{{identity}} was observed escalating its privileges by modifying their own permissions and creating an access key for {{accessKeyUsers}}",
                        [Contract.AwsPrivilegeEscalationRiskModelDescriptionType.PermissionActionModificationAndAssumedRoles]: "{{identity}} was observed escalating its privileges by modifying their own permissions and assuming {{assumedRoles}}",
                        [Contract.AwsPrivilegeEscalationRiskModelDescriptionType.PermissionActionModificationAndAssumedRolesAndAccessKeyUsers]: "{{identity}} was observed escalating its privileges by modifying their own permissions, assuming {{assumedRoles}} and creating an access key for {{accessKeyUsers}}"
                    }
                }
            }));

    return useAwsCommonBehaviorIdentityRiskDefinition(
        riskModel,
        localization.description[Contract.TypeNames.AwsPrivilegeEscalationRiskModelDescriptionType][privilegeEscalationRiskModel.descriptionType]({
            accessKeyUsers:
                <InlineEntities
                    entityIdsOrModels={privilegeEscalationRiskModel.accessKeyUserIds}
                    entityTypeName={Contract.TypeNames.AwsIamUser}
                    variant="itemCountAndType"/>,
            assumedRoles:
                <InlineEntities
                    entityIdsOrModels={privilegeEscalationRiskModel.assumedRoleIds}
                    entityTypeName={Contract.TypeNames.AwsIamRole}
                    variant="itemCountAndType"/>,
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }));
}