import { ActionButton, DeleteIcon, GuidParser, ItemWithId, ItemWithValidation, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Grid2 } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../common";
import { EditableTextCell } from "./components";

type ItemProps = {
    disabled: boolean;
    isDuplicate: (templateId: string) => boolean;
    onChange: (setting: ItemWithValidation<ItemWithId<Contract.AadSeverePermissionDirectoryRoleDefinitionData>>) => void;
    onDelete: () => void;
    valueItem: ItemWithValidation<ItemWithId<Contract.AadSeverePermissionDirectoryRoleDefinitionData>>;
};

export function Item({ disabled, isDuplicate, onChange, onDelete, valueItem }: ItemProps) {
    const [hover, setHover] = useState(false);
    const [name, setName] = useState(valueItem.name);
    const [templateId, setTemplateId] = useState(valueItem.templateId);

    const localization =
        useLocalization(
            "views.customer.configuration.settingConfigurationTable.value.aadSeverePermissionDirectoryRoleDefinitionValue.edit.item",
            () => ({
                validation: {
                    duplicate: "Must be unique",
                    empty: "Cannot be empty",
                    guid: "Must be a valid GUID"
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                if (_.isEmpty(name) || _.isNil(name)) {
                    return localization.validation.empty();
                }

                return undefined;
            },
            [name]);
    const [templateIdValidationController, templateIdValidationMessage] =
        useInputValidation(
            () => {
                if (_.isEmpty(templateId) || _.isNil(templateId)) {
                    return localization.validation.empty();
                }

                if (!GuidParser.validate(templateId)) {
                    return localization.validation.guid();
                }

                if (isDuplicate(templateId)) {
                    return localization.validation.duplicate();
                }

                return undefined;
            },
            [templateId]);

    useChangeEffect(
        () => {
            onChange({
                ...valueItem,
                name,
                templateId,
                valid: nameValidationController.isValid() && templateIdValidationController.isValid()
            });
        },
        [name, nameValidationMessage, templateId, templateIdValidationMessage],
        500);

    const theme = useTheme();
    return (
        <Grid2
            container={true}
            spacing={0.25}
            sx={{
                "&:hover": {
                    backgroundColor: theme.palette.action.hover
                }
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <Grid2
                size={4}
                sx={{ padding: theme.spacing(1) }}>
                <EditableTextCell
                    disabled={disabled}
                    errorMessage={nameValidationMessage}
                    value={name}
                    onChange={setName}/>
            </Grid2>
            <Grid2
                size={7}
                sx={{ padding: theme.spacing(1) }}>
                <EditableTextCell
                    disabled={disabled}
                    errorMessage={templateIdValidationMessage}
                    value={templateId}
                    onChange={setTemplateId}/>
            </Grid2>
            <Grid2
                size={1}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: theme.spacing(1)
                }}>
                {hover &&
                    <ActionButton
                        disabled={disabled}
                        onClick={onDelete}>
                        <DeleteIcon/>
                    </ActionButton>}
            </Grid2>
        </Grid2>);
}