import { DataTableColumn, InlineItems, ItemsPopoverActions, map, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useRef } from "react";
import { Contract, projectModelStore, Scope, ScopeHelper, scopeNodeModelStore } from "../../../../../../../../common";
import { TenantsDefinition } from "../../useDefinition";
import { StatusCell } from "./components";
import { useProjectModelStatusTranslator } from "./useProjectModelStatusTranslator";
import { useProjectsCommonDefinitionData } from "./useProjectsCommonDefinitionData";
import { useRenderProject } from "./useRenderProject";

export function useProjectsDefinition(): TenantsDefinition {
    const projectModelMap = projectModelStore.useGetProjectModelMap();
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(undefined, true);
    const { renderProjectCell, renderProjectCsvItem } = useRenderProject();
    const { getScopeIds } = useProjectsCommonDefinitionData();
    const projectStatusTranslator = useProjectModelStatusTranslator();
    const scopesPopoverActionsRef = useRef<ItemsPopoverActions>();
    const tenantsPopoverActionsRef = useRef<ItemsPopoverActions>();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition",
            () => ({
                scopes: {
                    text: "{{scopeCount | NumberFormatter.humanize}} scopes",
                    title: "Scopes"
                },
                status: {
                    title: "Status"
                },
                tenants: {
                    text: "{{tenantCount | NumberFormatter.humanize}} accounts",
                    title: "Accounts"
                },
                title: "Projects"
            }));

    return useMemo(
        () => ({
            filter: () => true,
            getColumns:
                () => [
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                    [localization.scopes.title()]:
                                        renderProjectCsvItem(
                                            (projectModel: Contract.ProjectModel) =>
                                                _(getScopeIds(projectModel)).
                                                    sortBy(scopeId => scopeNodeMap[scopeId].path).
                                                    map(scopeId => scopeNodeMap[scopeId].path).
                                                    join("\n"),
                                            scopeNodeModel)
                                })
                        }}
                        id={TableColumnId.Scopes}
                        key={TableColumnId.Scopes}
                        render={
                            renderProjectCell(
                                projectModel =>
                                    <InlineItems
                                        items={
                                            _(getScopeIds(projectModel)).
                                                sortBy(scopeId => scopeNodeMap[scopeId].path).
                                                map(scopeId =>
                                                    <Scope
                                                        key={scopeId}
                                                        scopeId={scopeId}
                                                        scopeNameSx={{
                                                            direction: "rtl",
                                                            textAlign: "left"
                                                        }}
                                                        scopeNameTranslatorOptions={{ path: true }}
                                                        sx={{ width: "fill-available" }}
                                                        variant={
                                                            ScopeHelper.isFolder(scopeNodeMap[scopeId].scopeNodeModel) ||
                                                            scopeId === ScopeHelper.customerId ||
                                                            ScopeHelper.isIdentityProviderTenantsScope(scopeNodeMap[scopeId].scopeNodeModel)
                                                                ? "iconText"
                                                                : "link"}
                                                        onClick={() => scopesPopoverActionsRef.current?.close()}/>).
                                                value()}
                                        itemsPopoverActionsRef={scopesPopoverActionsRef}
                                        title={localization.scopes.text({ scopeCount: _.size(getScopeIds(projectModel)) })}
                                        variant="title"/>)}
                        title={localization.scopes.title()}/>,
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.tenants.title()]:
                                            renderProjectCsvItem(
                                                (projectModel: Contract.ProjectModel) =>
                                                    _(projectModel.tenantIds).
                                                        sortBy(tenantId => scopeNodeMap[tenantId].path).
                                                        map(tenantId => scopeNodeMap[tenantId].path).
                                                        join("\n"),
                                                scopeNodeModel)
                                    })
                        }}
                        id={TableColumnId.Tenants}
                        key={TableColumnId.Tenants}
                        render={
                            renderProjectCell(
                                projectModel =>
                                    <InlineItems
                                        items={
                                            _(projectModel.tenantIds).
                                                sortBy(tenantId => scopeNodeMap[tenantId].path).
                                                map(
                                                    tenantId =>
                                                        <Scope
                                                            key={tenantId}
                                                            scopeId={tenantId}
                                                            scopeNameSx={{
                                                                direction: "rtl",
                                                                textAlign: "left"
                                                            }}
                                                            scopeNameTranslatorOptions={{ path: true }}
                                                            sx={{ width: "fill-available" }}
                                                            variant={
                                                                ScopeHelper.isIdentityProviderTenantsScope(scopeNodeMap[tenantId].scopeNodeModel)
                                                                    ? "iconText"
                                                                    : "link"}
                                                            onClick={() => tenantsPopoverActionsRef.current?.close()}/>).
                                                value()}
                                        itemsPopoverActionsRef={tenantsPopoverActionsRef}
                                        title={localization.tenants.text({ tenantCount: _.size(projectModel.tenantIds) })}
                                        variant="title"/>)}
                        title={localization.tenants.title()}/>,
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.status.title()]:
                                            renderProjectCsvItem(
                                                projectModel => projectStatusTranslator(projectModel.state.status),
                                                scopeNodeModel)
                                    })
                        }}
                        id={TableColumnId.Status}
                        key={TableColumnId.Status}
                        render={renderProjectCell(projectModel => <StatusCell item={projectModel}/>)}
                        title={localization.status.title()}/>
                ],
            sort:
                (columnId, scopeNodeModel) => {
                    const projectModel = projectModelMap[scopeNodeModel.configuration.id];
                    if (!_.isNil(projectModel)) {
                        return map<string, any>(
                            columnId,
                            {
                                [TableColumnId.Scopes]: () => _.size(getScopeIds(projectModel)),
                                [TableColumnId.Status]: () => StringHelper.getSortValue(projectStatusTranslator(projectModel.state.status)),
                                [TableColumnId.Tenants]: () => _.size(projectModel.tenantIds)
                            });
                    } else {
                        return undefined;
                    }
                },
            title: localization.title()
        }),
        [getScopeIds, localization, projectModelMap, renderProjectCell, renderProjectCsvItem, scopeNodeMap]);
}

enum TableColumnId {
    Scopes = "scopes",
    Status= "status",
    Tenants = "tenants"
}