import { Message, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAzureAuthorizationRoleAssignmentResourceStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureAuthorizationRoleAssignmentResourceDefinition(resourceModel: Contract.AzureResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceModel);
    const roleAssignmentModel = resourceModel as Contract.AzureAuthorizationRoleAssignmentResourceModel;
    const roleAssignmentResource = resourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource;

    const roleAssignmentResourceStatusTranslator = useAzureAuthorizationRoleAssignmentResourceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureAuthorizationRoleAssignmentResourceDefinition",
            () => ({
                info: {
                    containingRoleAssignmentResourceIds: {
                        helpText: "The role assignment is \"covered\" by these role assignments, which may come from a higher scope level or group membership",
                        title: "Covered By"
                    },
                    endTime: "Assignment End Time",
                    principalIdReference: "Principal",
                    roleDefinitionId: "Role",
                    scopeEntityIdReference: "Scope",
                    status: "Assignment Type"
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={roleAssignmentResource.roleDefinitionId}
                        entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                        key="roleDefinitionId"
                        location="all"
                        title={localization.info.roleDefinitionId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={roleAssignmentModel.scopeEntityIdReference}
                        entityTypeName={Contract.TypeNames.AzureScopeResource}
                        key="scopeEntityIdReference"
                        location="all"
                        title={localization.info.scopeEntityIdReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={roleAssignmentModel.principalIdReference}
                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                        key="principalIdReference"
                        location="all"
                        title={localization.info.principalIdReference()}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.status()}
                        value={roleAssignmentResourceStatusTranslator(roleAssignmentResource.status)}/>,
                    <InfoItem
                        key="endTime"
                        location="all"
                        title={localization.info.endTime()}
                        value={
                            _.isNil(roleAssignmentModel.endTime)
                                ? undefined
                                : TimeFormatter.sortableDateTime(roleAssignmentModel.endTime)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={roleAssignmentModel.containingRoleAssignmentResourceIds}
                        entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                        key="containingRoleAssignmentResourceIds"
                        location="all"
                        title={localization.info.containingRoleAssignmentResourceIds.title()}
                        titleInfoElement={
                            <Message
                                level="info"
                                title={localization.info.containingRoleAssignmentResourceIds.helpText()}
                                variant="minimal"/>}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}