import { useLocalization } from "@infrastructure";
import { Contract } from "../controllers";

export function useVulnerabilitySourceTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useVulnerabilitySourceTypeTranslator",
            () => ({
                [Contract.TypeNames.VulnerabilitySourceType]: {
                    [Contract.VulnerabilitySourceType.Alma]: "Alma Linux",
                    [Contract.VulnerabilitySourceType.Alpine]: "Alpine Linux",
                    [Contract.VulnerabilitySourceType.Amazon]: "Amazon Linux",
                    [Contract.VulnerabilitySourceType.Aws]: "AWS",
                    [Contract.VulnerabilitySourceType.Azure]: "Azure",
                    [Contract.VulnerabilitySourceType.Arch]: "Arch Linux",
                    [Contract.VulnerabilitySourceType.Bitnami]: "Bitnami",
                    [Contract.VulnerabilitySourceType.CblMariner]: "CBL-Mariner",
                    [Contract.VulnerabilitySourceType.CentOs]: "CentOs",
                    [Contract.VulnerabilitySourceType.Debian]: "Debian",
                    [Contract.VulnerabilitySourceType.Fedora]: "Fedora",
                    [Contract.VulnerabilitySourceType.Gcp]: "GCP",
                    [Contract.VulnerabilitySourceType.GitHubSecurityAdvisories]: "GitHub Advisory",
                    [Contract.VulnerabilitySourceType.GitLabAdvisoryDatabase]: "GitLab Advisory",
                    [Contract.VulnerabilitySourceType.GoVulnerabilityDatabase]: "Go Workgroup",
                    [Contract.VulnerabilitySourceType.Kubernetes]: "Kubernetes",
                    [Contract.VulnerabilitySourceType.NodejsSecurityWorkgroup]: "NodeJs Workgroup",
                    [Contract.VulnerabilitySourceType.Nvd]: "NVD",
                    [Contract.VulnerabilitySourceType.Oracle]: "Oracle",
                    [Contract.VulnerabilitySourceType.Osv]: "OSV",
                    [Contract.VulnerabilitySourceType.Photon]: "Photon Os",
                    [Contract.VulnerabilitySourceType.PhpSecurityAdvisories]: "Php Advisory",
                    [Contract.VulnerabilitySourceType.RedHat]: "RedHat",
                    [Contract.VulnerabilitySourceType.Rocky]: "Rocky Linux",
                    [Contract.VulnerabilitySourceType.RubySec]: "Ruby Security",
                    [Contract.VulnerabilitySourceType.Suse]: "SUSE Linux",
                    [Contract.VulnerabilitySourceType.Tenable]: "Tenable",
                    [Contract.VulnerabilitySourceType.Ubuntu]: "Ubuntu",
                    [Contract.VulnerabilitySourceType.Windows]: "Windows"
                }
            }));
    return (type: Contract.VulnerabilitySourceType) => localization[Contract.TypeNames.VulnerabilitySourceType][type]();
}