﻿import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, NetworkScopeFormatter } from "../../../../../../../../../common";
import { DestinationNetworkScope } from "../../../../../../../../../common/controllers/types.generated";
import { RiskDefinitionContextItem } from "../../../utilities";

export function useGetEntitiesNetworkRiskContext() {
    return useMemo(
        () => useEntitiesNetworkRiskContext,
        []);
}

function useEntitiesNetworkRiskContext(entityIdsOrModels: string[] | Contract.EntityModel[], entityTypeName: string) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetEntitiesNetworkRiskContext",
            () => ({
                inboundExternalAccess: {
                    ports: [
                        "1 port",
                        "{{count | NumberFormatter.humanize}} ports"
                    ],
                    title: [
                        "{{entities}} is directly accessible via {{securityGroups}} through {{networkScopes}}",
                        "{{entities}} are directly accessible via {{securityGroups}} through {{networkScopes}}"
                    ]
                }
            }));

    return {
        getInboundExternalAccessContextItem:
            (destinationNetworkScopes: DestinationNetworkScope[], securityGroupIds: string[]) =>
                !_.isEmpty(destinationNetworkScopes) && !_.isEmpty(securityGroupIds)
                    ? new RiskDefinitionContextItem(
                        localization.inboundExternalAccess.title(
                            entityIdsOrModels.length,
                            {
                                entities:
                                    <InlineEntities
                                        entityIdsOrModels={entityIdsOrModels}
                                        entityTypeName={entityTypeName}
                                        variant="itemCountAndType"/>,
                                networkScopes:
                                    <InlineItems
                                        items={
                                            _(destinationNetworkScopes).
                                                map(networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope)).
                                                uniq().
                                                value()}
                                        namePluralizer={localization.inboundExternalAccess.ports}
                                        variant="itemCountAndType"/>,
                                securityGroups:
                                    <InlineEntities
                                        entityIdsOrModels={securityGroupIds}
                                        entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                        variant="itemCountAndType"/>
                            }))
                    : undefined
    };
}