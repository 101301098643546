import { Steps, useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlineGroupIdentities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskContentProps } from "../../../../useCloudDefinition";
import { useGcpCommonAccessPrincipalRiskDefinition } from "./useGcpCommonAccessPrincipalRiskDefinition";

export function useGcpSeverePermissionPrincipalRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpSeverePermissionPrincipalRisk;
    const principalModel = entityModelStore.useGet(risk.entityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpSeverePermissionPrincipalRiskDefinition",
            () => ({
                description: {
                    [Contract.TypeNames.GciDirectoryGroup]: {
                        [Contract.TypeNames.PrincipalSeverePermissionActionRiskType]: {
                            [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: [
                                "{{groupIdentities}} was recently granted admin privileges on {{tenantEntity}} through {{principal}}",
                                "{{groupIdentities}} were recently granted admin privileges on {{tenantEntity}} through {{principal}}"
                            ],
                            [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: [
                                "{{groupIdentities}} was recently granted privileges that allow it to escalate to an admin on {{tenantEntity}} through {{principal}}",
                                "{{groupIdentities}} were recently granted privileges that allow them to escalate to admin on {{tenantEntity}} through {{principal}}"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGciIdentity]: {
                        [Contract.TypeNames.PrincipalSeverePermissionActionRiskType]: {
                            [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "{{principal}} was recently granted admin privileges on {{tenantEntity}}",
                            [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "{{principal}} was recently granted privileges that allow it to escalate to an admin on {{tenantEntity}}"
                        }
                    }
                }
            }));

    return useGcpCommonAccessPrincipalRiskDefinition(
        <ContextSection riskModel={riskModel}/>,
        principalModel.entity.typeName === Contract.TypeNames.GciDirectoryGroup
            ? localization.description[Contract.TypeNames.GciDirectoryGroup][Contract.TypeNames.PrincipalSeverePermissionActionRiskType][risk.type](
                risk.groupIdentityIds.length,
                {
                    groupIdentities:
                        <InlineEntities
                            entityIdsOrModels={risk.groupIdentityIds}
                            entityTypeName={Contract.TypeNames.IGciIdentity}
                            entityVariant="iconTextTypeTenant"
                            variant="itemCountAndType"/>,
                    principal:
                        <Entity
                            entityIdOrModel={principalModel}
                            variant="typeText"/>,
                    tenantEntity:
                        <Entity
                            entityIdOrModel={risk.tenantId}
                            variant="typeText"/>
                })
            : localization.description[Contract.TypeNames.IGciIdentity][Contract.TypeNames.PrincipalSeverePermissionActionRiskType][risk.type](
                {
                    principal:
                        <Entity
                            entityIdOrModel={principalModel}
                            entityTypeNameTranslatorOptions={{ variant: "title" }}
                            variant="typeText"/>,
                    tenantEntity:
                        <Entity
                            entityIdOrModel={risk.tenantId}
                            variant="typeText"/>
                }),
        riskModel,
        Contract.EntityAccessScope.GciSpecialGroupExcluded);
}

function ContextSection({ riskModel }: RiskContentProps) {
    const severePermissionPrincipalRiskModel = riskModel as Contract.GcpSeverePermissionPrincipalRiskModel;
    const principalModel = entityModelStore.useGet(severePermissionPrincipalRiskModel.risk.entityId);
    const severePermissionActionPermittingEntityModels = entityModelStore.useGet(severePermissionPrincipalRiskModel.risk.severePermissionActionPermittingEntityIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpSeverePermissionPrincipalRiskDefinition.infosSection",
            () => ({
                [Contract.TypeNames.GcpSeverePermissionPrincipalRiskModelInfo]: {
                    [Contract.GcpSeverePermissionPrincipalRiskModelInfo.GroupIdentities]: "{{principal}} has {{groupIdentityIds}}",
                    [Contract.GcpSeverePermissionPrincipalRiskModelInfo.IdentityCreationTime]: "{{translatedPrincipalTypeName}} {{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.GcpSeverePermissionPrincipalRiskModelInfo.IdentityCreationTimeCreator]: "{{translatedPrincipalTypeName}} {{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}} by {{principalServiceAccountCreatorIdentityIdReference}}",
                    [Contract.GcpSeverePermissionPrincipalRiskModelInfo.IdentityCreationTimeCreatorCreatorOriginator]: "{{translatedPrincipalTypeName}} {{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}} by {{principalServiceAccountCreatorIdentityIdReference}} (Originator: {{principalServiceAccountCreatorOriginatorEntityIdReference}})",
                    [Contract.GcpSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionPermittingEntities]: {
                        [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "Admin privileges granted through: {{severePermissionActionPermittingEntities}}",
                        [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "New privileges granted through: {{severePermissionActionPermittingEntities}}"
                    },
                    [Contract.GcpSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionStartTime]: {
                        [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "Admin privileges granted on: {{severePermissionActionStartTime | TimeFormatter.longDate}}",
                        [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "New privileges granted on: {{severePermissionActionStartTime | TimeFormatter.longDate}}"
                    }
                }
            }));
    const createInfoProps =
        () => ({
            creationTime: severePermissionPrincipalRiskModel.risk.identityCreationTime,
            groupIdentityIds:
                <InlineGroupIdentities
                    identityIds={severePermissionPrincipalRiskModel.risk.groupIdentityIds}
                    identityTypeName={Contract.TypeNames.IGciIdentity}/>,
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    variant="text"/>,
            principalServiceAccountCreatorIdentityIdReference:
                _.isNil(severePermissionPrincipalRiskModel.risk.principalServiceAccountCreatorIdentityIdReference)
                    ? undefined
                    : <Entity
                        entityIdOrModel={severePermissionPrincipalRiskModel.risk.principalServiceAccountCreatorIdentityIdReference}
                        variant="text"/>,
            principalServiceAccountCreatorOriginatorEntityIdReference:
                _.isNil(severePermissionPrincipalRiskModel.risk.principalServiceAccountCreatorOriginatorEntityIdReference)
                    ? undefined
                    : <Entity
                        entityIdOrModel={severePermissionPrincipalRiskModel.risk.principalServiceAccountCreatorOriginatorEntityIdReference}
                        variant="text"/>,
            severePermissionActionPermittingEntities:
                localizeList(
                    _(severePermissionActionPermittingEntityModels).
                        groupBy(severePermissionActionPermittingEntityModel => severePermissionActionPermittingEntityModel.entity.typeName).
                        map(
                            (severePermissionActionPermittingEntityModels, severePermissionActionPermittingEntityTypeName) =>
                                <InlineEntities
                                    entityIdsOrModels={severePermissionActionPermittingEntityModels}
                                    entityTypeName={severePermissionActionPermittingEntityTypeName}
                                    key={severePermissionActionPermittingEntityTypeName}
                                    variant={
                                        severePermissionActionPermittingEntityTypeName === Contract.TypeNames.GcpIamRoleBinding
                                            ? "itemOrItemCountAndType"
                                            : "itemCountAndType"}/>).
                        value()),
            severePermissionActionStartTime: severePermissionPrincipalRiskModel.risk.severePermissionActionStartTime,
            translatedPrincipalTypeName: entityTypeNameTranslator(principalModel.entity.typeName)
        });
    return (
        <Steps>
            {_.map(
                (riskModel as Contract.GcpSeverePermissionPrincipalRiskModel).infos,
                info => {
                    switch (info) {
                        case Contract.GcpSeverePermissionPrincipalRiskModelInfo.GroupIdentities:
                        case Contract.GcpSeverePermissionPrincipalRiskModelInfo.IdentityCreationTime:
                        case Contract.GcpSeverePermissionPrincipalRiskModelInfo.IdentityCreationTimeCreator:
                        case Contract.GcpSeverePermissionPrincipalRiskModelInfo.IdentityCreationTimeCreatorCreatorOriginator:
                            return localization[Contract.TypeNames.GcpSeverePermissionPrincipalRiskModelInfo][info](createInfoProps());
                        case Contract.GcpSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionPermittingEntities:
                        case Contract.GcpSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionStartTime:
                            return localization[Contract.TypeNames.GcpSeverePermissionPrincipalRiskModelInfo][info][severePermissionPrincipalRiskModel.risk.type](createInfoProps());
                    }
                })}
        </Steps>);
}