import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSort, DataTableSortDirection, EmptyMessageText, ListWelcomeView, map, Optional, optionalTableCell, StringHelper, TextValuesFilter, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { automaticCustomEntityAttributeDefinitionTemplateModelStore, Contract, customEntityAttributeDefinitionModelStore, scopeNodeModelStore, StorageHelper, useScopeNameTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../common";
import { Container } from "../../../../../../../../../common/components/EntityAttributes/components/EntityAttribute/components";
import { CustomEntityAttributeItem, CustomEntityAttributeItemType, useSetCustomEntityAttributesContext } from "../../CustomEntityAttributeDefinitions";
import { ActionsCell } from "../ActionsCell";

export function Table() {
    const scopeNameTranslator = useScopeNameTranslator();
    const setContext = useSetCustomEntityAttributesContext();

    const [childScopeEnabled, setChildScopeEnabled] = useState(!StringHelper.isFalse(StorageHelper.customerConfigurationEntitiesCustomEntityAttributeDefinitionsFlatView.getValue()));
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const scopeFilteredScopeIds =
        useMemo(
            () =>
                _([scopeNodeModel.configuration.id]).
                    concat(scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds).
                    concatIf(
                        childScopeEnabled,
                        scopeNodeMap[scopeNodeModel.configuration.id].scopeIds).
                    value(),
            [scopeNodeModel, scopeNodeMap, childScopeEnabled]);

    const automaticCustomEntityAttributeDefinitionTemplateModels = automaticCustomEntityAttributeDefinitionTemplateModelStore.useGetAll();
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const customEntityAttributeItems =
        useMemo(
            () => {
                const automaticCustomEntityAttributeItems =
                    _.map(
                        automaticCustomEntityAttributeDefinitionTemplateModels,
                        automaticCustomEntityAttributeDefinitionTemplateModel =>
                            new CustomEntityAttributeItem(
                                automaticCustomEntityAttributeDefinitionTemplateModel.configuration,
                                automaticCustomEntityAttributeDefinitionTemplateModel.configuration.displayName,
                                CustomEntityAttributeItemType.Automatic));
                const manualCustomEntityAttributeItems =
                    _(customEntityAttributeDefinitionModels).
                        filter(customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.typeName === Contract.TypeNames.ManualCustomEntityAttributeDefinitionConfiguration).
                        map(
                            manualCustomEntityAttributeDefinitionModel =>
                                new CustomEntityAttributeItem(
                                    manualCustomEntityAttributeDefinitionModel.configuration,
                                    manualCustomEntityAttributeDefinitionModel.configuration.name,
                                    CustomEntityAttributeItemType.Manual)).
                        value();

                return _.concat(
                    automaticCustomEntityAttributeItems,
                    manualCustomEntityAttributeItems);
            },
            [automaticCustomEntityAttributeDefinitionTemplateModels, customEntityAttributeDefinitionModels]);
    const scopedCustomEntityAttributeItems =
        useMemo(
            () =>
                _.filter(
                    customEntityAttributeItems,
                    customEntityAttributeItem =>
                        _.includes(
                            scopeFilteredScopeIds,
                            customEntityAttributeItem.configuration.scopeId)),
            [customEntityAttributeItems, scopeFilteredScopeIds]);

    const customEntityAttributeNames =
        useMemo(
            () =>
                _(customEntityAttributeItems).
                    map(item => item.configuration.name).
                    filter().
                    uniq().
                    value(),
            [customEntityAttributeItems]);

    const fetchItems =
        (filterMap: Dictionary<any>, sort: Optional<DataTableSort>) =>
            _(scopedCustomEntityAttributeItems).
                filter(
                    entityAttributeItem => {
                        if (!_.isNil(filterMap[CustomEntityAttributesColumnId.Name]) &&
                            !_.includes(filterMap[CustomEntityAttributesColumnId.Name].values, entityAttributeItem.configuration.name) &&
                            !(filterMap[CustomEntityAttributesColumnId.Name].emptyValue && _.isEmpty(entityAttributeItem.configuration.name))) {
                            return false;
                        }

                        if (!_.isEmpty(filterMap[CustomEntityAttributesColumnId.Type]) &&
                            !_.includes(filterMap[CustomEntityAttributesColumnId.Type].values, entityAttributeItem.type)) {
                            return false;
                        }

                        return true;
                    }).
                orderBy(
                    [
                        entityAttributeItem =>
                            map<string, number | string | undefined>(
                                sort?.columnId ?? CustomEntityAttributesColumnId.Scope,
                                {
                                    [CustomEntityAttributesColumnId.Type]: () => entityAttributeItem.type,
                                    [CustomEntityAttributesColumnId.Name]: () => StringHelper.getSortValue(entityAttributeItem.configuration.name),
                                    [CustomEntityAttributesColumnId.Scope]: () => scopeNameTranslator(entityAttributeItem.configuration.scopeId, { path: true })
                                }),
                        entityAttributeItem => scopeNameTranslator(entityAttributeItem.configuration.scopeId, { path: true })
                    ],
                    [
                        sort?.direction === DataTableSortDirection.Descending
                            ? "desc"
                            : "asc",
                        "asc"
                    ]).
                value();

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => dataTableActionsRef.current!.reset({ refreshFilters: true }),
        [scopedCustomEntityAttributeItems]);

    const localization =
        useLocalization(
            "views.customer.configuration.entities.customEntityAttributeDefinitions.table",
            () => ({
                actions: {
                    add: "Add Label",
                    childScopeEnabled: "Flat View"
                },
                columns: {
                    name: "Name",
                    preview: "Preview",
                    scope: "Scope",
                    type: "Type"
                },
                empty: {
                    welcome: "Add Label",
                    withFilter: "No matching label",
                    withoutFilter: "No labels"
                },
                type: {
                    automatic: "Automatic",
                    manual: "Manual"
                }
            }));

    const theme = useTheme();
    return (
        _.isEmpty(scopedCustomEntityAttributeItems)
            ? <ListWelcomeView
                title={localization.empty.welcome()}
                onStart={
                    () =>
                        setContext(
                            context => ({
                                ...context,
                                addOrEditOpen: true,
                                item: undefined
                            }))}/>
            : <DataTable
                actionsRef={dataTableActionsRef}
                cellMaxWidth="small"
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
                }}
                fetchItems={fetchItems}
                getItemId={item => item.id}>
                <DataTableAction>
                    <CheckButton
                        checked={childScopeEnabled}
                        title={localization.actions.childScopeEnabled()}
                        onCheckedChanged={
                            checked => {
                                StorageHelper.customerConfigurationEntitiesCustomEntityAttributeDefinitionsFlatView.setValue(checked);
                                setChildScopeEnabled(checked);
                            }}/>
                </DataTableAction>
                <DataTableAction>
                    <Button
                        size="small"
                        startIcon={<AddIcon/>}
                        onClick={
                            () =>
                                setContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: true,
                                        addOrEditReadonly: false,
                                        item: undefined
                                    }))}>
                        {localization.actions.add()}
                    </Button>
                </DataTableAction>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TextValuesFilter
                                    emptyValue={true}
                                    placeholder={localization.columns.name()}
                                    values={customEntityAttributeNames}/>
                        }
                    }}
                    id={CustomEntityAttributesColumnId.Name}
                    key={CustomEntityAttributesColumnId.Name}
                    render={optionalTableCell<CustomEntityAttributeItem>(({ configuration }) => configuration.name)}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.type()}
                                    values={[
                                        localization.type.automatic(),
                                        localization.type.manual()
                                    ]}/>
                        }
                    }}
                    id={CustomEntityAttributesColumnId.Type}
                    itemProperty={
                        (entityAttributeItem: CustomEntityAttributeItem) =>
                            entityAttributeItem.type == CustomEntityAttributeItemType.Automatic
                                ? localization.type.automatic()
                                : localization.type.manual()}
                    key={CustomEntityAttributesColumnId.Type}
                    title={localization.columns.type()}/>
                <DataTableColumn
                    id={CustomEntityAttributesColumnId.Scope}
                    itemProperty={(item: CustomEntityAttributeItem) => scopeNameTranslator(item.configuration.scopeId, { path: true })}
                    key={CustomEntityAttributesColumnId.Scope}
                    title={localization.columns.scope()}/>
                <DataTableColumn
                    cellMaxWidth="medium"
                    id={CustomEntityAttributesColumnId.Preview}
                    key={CustomEntityAttributesColumnId.Preview}
                    render={
                        ({ item }: DataTableColumnRenderProps<CustomEntityAttributeItem>) =>
                            <Box sx={{ width: "fit-content" }}>
                                <Container
                                    color={theme.palette.entityAttribute.custom[item.configuration.colorIndex]}
                                    name={item.displayName}
                                    variant={
                                        map(
                                            item.type,
                                            {
                                                [CustomEntityAttributeItemType.Automatic]: () => "automatic",
                                                [CustomEntityAttributeItemType.Manual]: () => "manual"
                                            })}/>
                            </Box>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.preview()}/>
                <DataTableColumn
                    id={CustomEntityAttributesColumnId.Actions}
                    key={CustomEntityAttributesColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<CustomEntityAttributeItem>) =>
                            <ActionsCell item={item}/>}/>
            </DataTable>);
}

enum CustomEntityAttributesColumnId {
    Actions = "actions",
    Name = "name",
    Preview = "preview",
    Scope = "scope",
    Type = "type"
}