import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Entity, useTheme } from "../../..";

type ItemProps = {
    entityId: string;
    renderEntityStats?: (entityId: string) => ReactNode;
    selected?: boolean;
};

export function Item({ entityId, renderEntityStats, selected }: ItemProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ width: "100%" }}>
            <Box
                sx={{
                    fontSize: "24px",
                    padding: theme.spacing(1)
                }}>
                <Entity
                    entityIdOrModel={entityId}
                    linkOptions={{ disabled: true }}
                    variant="icon"/>
            </Box>
            <Box sx={{ overflow: "hidden" }}>
                <Entity
                    entityIdOrModel={entityId}
                    sxOptions={{
                        title: {
                            fontWeight:
                                selected
                                    ? "bold"
                                    : undefined
                        }
                    }}
                    variant="textTypeTenant"/>
            </Box>
            <Box sx={{ flex: 1 }}/>
            {!_.isNil(renderEntityStats) &&
                <Box>
                    {renderEntityStats(entityId)}
                </Box>}
        </Stack>);
}