import { InlineItems, map, Sx, UnexpectedError, useLocalization, useLocalizeList } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useCallback, useMemo } from "react";
import { Contract, EntityLinkOptions, InlineEntities, InlineEntityAttributes, InlineEntityProperties, InlineResourceTags, Scope } from "../../..";
import { useEntityTypeNameTranslator } from "../../../hooks";
import { SelectedValue } from "./SelectedValue";

type InlineEntitySelectionProps = {
    conditions: Contract.EntityMatchCondition[];
    entityLinkOptions?: EntityLinkOptions;
    entityTypeName: string;
    excludeConditions: Contract.EntityMatchCondition[];
    includeEntityServiceName?: boolean;
    sx?: SxProps;
};

export function InlineEntityMultiSelection({ conditions, entityLinkOptions, entityTypeName, excludeConditions, includeEntityServiceName = false, sx }: InlineEntitySelectionProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();

    const localization =
        useLocalization(
            "common.customRiskPolicy.inlineEntityMultiSelection",
            () => ({
                entityMatchConditions: {
                    aadDirectoryPrincipalGroupIds: "{{translatedTypeName}} that are members of {{groupIds}}",
                    all: "all {{translatedTypeName}}",
                    attributes: "{{translatedTypeName}} with {{attributes}}",
                    awsIamPrincipalCriticalActionSeverityPermission: "{{translatedTypeName}} with high privileges",
                    awsIamUserGroupIds: "{{translatedTypeName}} in {{groupIds}}",
                    awsKmsKeyCustomerManaged: "any customer managed encryption {{translatedTypeName}}",
                    awsSsoPermissionSetProvisioned: {
                        false: "unprovisioned",
                        title: "{{provisioned}} {{translatedTypeName}}",
                        true: "provisioned"
                    },
                    azureScopeResourceParentEntityIds: {
                        namePluralizer: [
                            "1 scope",
                            "{{count | NumberFormatter.humanize}} scopes"
                        ],
                        text: "{{translatedTypeName}} in {{parentEntityIds}}"
                    },
                    gciPrincipalGroupIds: "{{translatedTypeName}} that are members of {{groupIds}}",
                    gcpScopeResourceParentScopeResourceIds: {
                        namePluralizer: [
                            "1 scope",
                            "{{count | NumberFormatter.humanize}} scopes"
                        ],
                        text: "{{translatedTypeName}} in {{parentScopeResourceIds}}"
                    },
                    namePattern: "{{translatedTypeName}} with this name pattern",
                    properties: "{{translatedTypeName}} with {{propertyValues}}",
                    sensitive: "sensitive {{translatedTypeName}}",
                    tags: "{{translatedTypeName}} with {{tags}}",
                    tenantIds: [
                        "{{translatedTypeName}} from this 1 account",
                        "{{translatedTypeName}} from these {{count | NumberFormatter.humanize}} accounts"
                    ],
                    typeNames: [
                        "{{translatedTypeName}} from this 1 type",
                        "{{translatedTypeName}} from these {{count | NumberFormatter.humanize}} types"
                    ]
                },
                exclude: ", excluding {{excludeSelectors}}",
                placeholder: "these {{translatedTypeName}}"
            }));

    const translatedTypeName =
        useMemo(
            () =>
                entityTypeNameTranslator(
                    entityTypeName,
                    {
                        count: 0,
                        includeServiceName: includeEntityServiceName,
                        variant: "text"
                    }),
            [entityTypeName, includeEntityServiceName]);
    const textSx =
        useMemo(
            () =>
                Sx.combine(
                    {
                        fontWeight: 600,
                        textDecoration: "underline"
                    },
                    sx),
            [sx]);
    const localizeConditions =
        useCallback(
            (conditions: Contract.EntityMatchCondition[]) =>
                localizeList(
                    _.map(
                        conditions,
                        condition =>
                            map<string, ReactNode | string>(
                                condition.typeName,
                                {
                                    [Contract.TypeNames.AadDirectoryPrincipalGroupIdMatchCondition]: () => localization.entityMatchConditions.aadDirectoryPrincipalGroupIds({
                                        groupIds:
                                                <InlineEntities
                                                    entityIdsOrModels={(condition as Contract.AadDirectoryPrincipalGroupIdMatchCondition).groupIds}
                                                    entityLinkOptions={{
                                                        color: "inherit",
                                                        ...entityLinkOptions
                                                    }}
                                                    entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                                                    sx={textSx}
                                                    variant="itemOrItemCountAndType"/>,
                                        translatedTypeName
                                    }),
                                    [Contract.TypeNames.AllEntityMatchCondition]: () => localization.entityMatchConditions.all({ translatedTypeName }),
                                    [Contract.TypeNames.AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition]: () => localization.entityMatchConditions.awsIamPrincipalCriticalActionSeverityPermission({ translatedTypeName }),
                                    [Contract.TypeNames.AwsIamUserGroupIdMatchCondition]: () =>
                                        localization.entityMatchConditions.awsIamUserGroupIds({
                                            groupIds:
                                                <InlineEntities
                                                    entityIdsOrModels={(condition as Contract.AwsIamUserGroupIdMatchCondition).groupIds}
                                                    entityLinkOptions={{
                                                        color: "inherit",
                                                        ...entityLinkOptions
                                                    }}
                                                    entityTypeName={Contract.TypeNames.AwsIamGroup}
                                                    sx={textSx}
                                                    variant="itemOrItemCountAndType"/>,
                                            translatedTypeName
                                        }),
                                    [Contract.TypeNames.AwsKmsKeyCustomerManagedMatchCondition]: () =>
                                        localization.entityMatchConditions.awsKmsKeyCustomerManaged({
                                            translatedTypeName:
                                                entityTypeNameTranslator(
                                                    entityTypeName,
                                                    {
                                                        includeServiceName: includeEntityServiceName,
                                                        variant: "text"
                                                    })
                                        }),
                                    [Contract.TypeNames.AwsSsoPermissionSetProvisionedMatchCondition]: () =>
                                        localization.entityMatchConditions.awsSsoPermissionSetProvisioned.title({
                                            provisioned:
                                                (condition as Contract.AwsSsoPermissionSetProvisionedMatchCondition).provisioned
                                                    ? localization.entityMatchConditions.awsSsoPermissionSetProvisioned.true()
                                                    : localization.entityMatchConditions.awsSsoPermissionSetProvisioned.false(),
                                            translatedTypeName
                                        }),
                                    [Contract.TypeNames.AzureScopeResourceParentEntityIdMatchCondition]: () => {
                                        const azureScopeResourceParentEntityIdMatchCondition = condition as Contract.AzureScopeResourceParentEntityIdMatchCondition;
                                        return localization.entityMatchConditions.azureScopeResourceParentEntityIds.text({
                                            parentEntityIds:
                                                <InlineEntities
                                                    entityIdsOrModels={azureScopeResourceParentEntityIdMatchCondition.parentEntityIds}
                                                    entityLinkOptions={{
                                                        color: "inherit",
                                                        ...entityLinkOptions
                                                    }}
                                                    entityTypeName={entityTypeName}
                                                    namePluralizer={localization.entityMatchConditions.azureScopeResourceParentEntityIds.namePluralizer}
                                                    sx={textSx}
                                                    variant="itemOrItemCountAndType"/>,
                                            translatedTypeName
                                        });
                                    },
                                    [Contract.TypeNames.EntityAttributeMatchCondition]: () => {
                                        const entityAttributeMatchCondition = condition as Contract.EntityAttributeMatchCondition;
                                        return localization.entityMatchConditions.attributes({
                                            attributes:
                                                <SelectedValue>
                                                    <InlineEntityAttributes
                                                        builtInAttributeTypeNames={entityAttributeMatchCondition.builtInAttributeTypeNames}
                                                        customAttributeDefinitionIds={entityAttributeMatchCondition.customAttributeDefinitionIds}
                                                        sx={textSx}
                                                        variant="itemCountAndType"/>
                                                </SelectedValue>,
                                            translatedTypeName
                                        });
                                    },
                                    [Contract.TypeNames.EntityIdMatchCondition]: () =>
                                        <InlineEntities
                                            entityIdsOrModels={(condition as Contract.EntityIdMatchCondition).ids}
                                            entityLinkOptions={{
                                                color: "inherit",
                                                ...entityLinkOptions
                                            }}
                                            entityTypeName={entityTypeName}
                                            sx={textSx}
                                            variant="itemOrItemCountAndType"/>,
                                    [Contract.TypeNames.EntityNamePatternMatchCondition]: () => localization.entityMatchConditions.namePattern({ translatedTypeName }),
                                    [Contract.TypeNames.EntityPrincipalReferencePropertyMatchCondition]: () => {
                                        const entityPrincipalReferencePropertyMatchCondition = condition as Contract.EntityPrincipalReferencePropertyMatchCondition;
                                        return localization.entityMatchConditions.properties({
                                            propertyValues:
                                                <InlineEntityProperties
                                                    entityLinkOptions={{
                                                        color: "inherit",
                                                        ...entityLinkOptions
                                                    }}
                                                    entityPropertyRawIdentifierToValuesMap={{ [entityPrincipalReferencePropertyMatchCondition.identifier.raw]: entityPrincipalReferencePropertyMatchCondition.values }}
                                                    entityTypeName={entityTypeName}
                                                    sx={textSx}/>,
                                            translatedTypeName
                                        });
                                    },
                                    [Contract.TypeNames.EntitySensitiveMatchCondition]: () => localization.entityMatchConditions.sensitive({ translatedTypeName }),
                                    [Contract.TypeNames.EntityStringPropertyMatchCondition]: () => {
                                        const entityStringPropertyMatchCondition = condition as Contract.EntityStringPropertyMatchCondition;
                                        return localization.entityMatchConditions.properties({
                                            propertyValues:
                                                <InlineEntityProperties
                                                    entityLinkOptions={{
                                                        color: "inherit",
                                                        ...entityLinkOptions
                                                    }}
                                                    entityPropertyRawIdentifierToValuesMap={{ [entityStringPropertyMatchCondition.identifier.raw]: entityStringPropertyMatchCondition.values }}
                                                    entityTypeName={entityTypeName}
                                                    sx={textSx}/>,
                                            translatedTypeName
                                        });
                                    },
                                    [Contract.TypeNames.EntityTagMatchCondition]: () =>
                                        localization.entityMatchConditions.tags({
                                            tags:
                                                <SelectedValue>
                                                    <InlineResourceTags
                                                        sx={textSx}
                                                        tags={(condition as Contract.EntityTagMatchCondition).tags}
                                                        textVariant="text"
                                                        variant="itemCountAndType"/>
                                                </SelectedValue>,
                                            translatedTypeName
                                        }),
                                    [Contract.TypeNames.EntityTenantIdMatchCondition]: () =>
                                        <InlineItems
                                            items={
                                                _.map(
                                                    (condition as Contract.EntityTenantIdMatchCondition).tenantIds,
                                                    entityTenantId => <Scope scopeId={entityTenantId}/>)}
                                            namePluralizer={
                                                count =>
                                                    localization.entityMatchConditions.tenantIds(
                                                        count,
                                                        { translatedTypeName })}
                                            sx={textSx}
                                            variant="itemCountAndType"/>,
                                    [Contract.TypeNames.EntityTypeNameMatchCondition]: () =>
                                        <InlineItems
                                            items={
                                                _.map(
                                                    (condition as Contract.EntityTypeNameMatchCondition).entityTypeNames,
                                                    entityTypeName => entityTypeNameTranslator(entityTypeName))}
                                            namePluralizer={
                                                count =>
                                                    localization.entityMatchConditions.typeNames(
                                                        count,
                                                        { translatedTypeName })}
                                            sx={textSx}
                                            variant="itemCountAndType"/>,
                                    [Contract.TypeNames.GciPrincipalGroupIdMatchCondition]: () => localization.entityMatchConditions.gciPrincipalGroupIds({
                                        groupIds:
                                            <InlineEntities
                                                entityIdsOrModels={(condition as Contract.GciPrincipalGroupIdMatchCondition).groupIds}
                                                entityLinkOptions={{
                                                    color: "inherit",
                                                    ...entityLinkOptions
                                                }}
                                                entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                                                sx={textSx}
                                                variant="itemOrItemCountAndType"/>,
                                        translatedTypeName
                                    }),
                                    [Contract.TypeNames.GcpScopeResourceParentScopeResourceIdMatchCondition]: () => {
                                        const gcpScopeResourceParentScopeResourceIdMatchCondition = condition as Contract.GcpScopeResourceParentScopeResourceIdMatchCondition;
                                        return localization.entityMatchConditions.gcpScopeResourceParentScopeResourceIds.text({
                                            parentScopeResourceIds:
                                                <InlineEntities
                                                    entityIdsOrModels={gcpScopeResourceParentScopeResourceIdMatchCondition.parentScopeResourceIds}
                                                    entityLinkOptions={{
                                                        color: "inherit",
                                                        ...entityLinkOptions
                                                    }}
                                                    entityTypeName={entityTypeName}
                                                    namePluralizer={localization.entityMatchConditions.gcpScopeResourceParentScopeResourceIds.namePluralizer}
                                                    sx={textSx}
                                                    variant="itemOrItemCountAndType"/>,
                                            translatedTypeName
                                        });
                                    }
                                },
                                () => {
                                    throw new UnexpectedError("condition.typeName", condition.typeName);
                                })
                    )),
            [localizeList]);

    return (
        <Typography
            component="span"
            sx={textSx}>
            {!_.isEmpty(conditions)
                ? localizeConditions(conditions)
                : localization.placeholder({
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {!_.isEmpty(excludeConditions) &&
                localization.exclude({ excludeSelectors: localizeConditions(excludeConditions) })}
        </Typography>);
}