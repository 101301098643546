import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciNetworkingVcnRiskContext() {
    return useMemo(
        () => useOciNetworkingVcnRiskContext,
        []);
}

function useOciNetworkingVcnRiskContext(vcnModel: Contract.OciNetworkingVcnModel) {
    const vcn = vcnModel.entity as Contract.OciNetworkingVcn;
    const resourceRiskContext = useGetOciResourceRiskContext()(vcnModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciNetworkingVcnRiskContext",
            () => ({
                subnets: "The {{translatedVcnTypeName}} contains {{subnets}}"
            }));
    const translatedVcnTypeName =
        entityTypeNameTranslator(
            vcn.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        subnets:
            _.isEmpty(vcnModel.subnetIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.subnets({
                        subnets:
                            <InlineEntities
                                entityIdsOrModels={vcnModel.subnetIds}
                                entityTypeName={Contract.TypeNames.OciNetworkingSubnet}
                                variant="itemCountAndType"/>,
                        translatedVcnTypeName
                    }))
    };
}