﻿import { useMemo } from "react";
import { RiskDefinitionContextItem } from "../../../utilities";
import { useRiskPolicyConfigurationPasswordRequirementStep } from "./useRiskPolicyConfigurationPasswordRequirementStep";

export function useGetRiskPolicyConfigurationPasswordRequirementContextItem() {
    return useMemo(
        () => useRiskPolicyConfigurationPasswordRequirementContextItem,
        []);
}

function useRiskPolicyConfigurationPasswordRequirementContextItem(translatedPasswordRequirement: string) {
    return new RiskDefinitionContextItem(useRiskPolicyConfigurationPasswordRequirementStep(translatedPasswordRequirement));
}