import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { ActionMenuItem, ContextMenuItem, CsvExportButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DeferredFilter, DropdownIcon, EmptyMessageText, makeContextProvider, map, Menu, MenuItem, Optional, PagedValuesFilterSelection, setUrlRoute, TextValuesFilter, UnexpectedError, useLocalization, useOperation, useRoute, useSetRoute, ValuesFilter, ValuesFilterItem, ValuesFilterSelection, VerticalFillGrid } from "@infrastructure";
import { AccessController, ActionRiskCategoryFilter, AnalyzingView, Contract, CustomerConsoleAppUrlHelper, ElasticsearchItemPageHelper, Entity, EntityAttributeFilter, EntityContextMenuContextProvider, EntityController, EntityFilter, EntityTypeFilter, ItemSelectionHelper, PagedEntityFilter, PagedEntityFilterEntityIdPage, PagedEntityResourceTagFilter, PermissionActionExcessivenessFilter, ScopeTenantFilter, StorageHelper, tenantModelStore, TypeHelper, useGetSelectedScopeHasData, useLayoutOptions, useTenantNameTranslator, useTheme } from "../../../../../../common";
import { AccessLevelFilter } from "../../../../../../common/components/AccessLevelFilter";
import { useGetTopItemsExportData } from "../../../../hooks";
import { InlineDestinationEntities, InlineOriginatorEntities, InlinePermissionActions, InlinePermitterEntities } from "./components";
import { InlineSourceEntities } from "./components/InlineSourceEntities";
import { PermissionsDefinition, useDefinition } from "./hooks";

export class PermissionsContext {
    constructor(
        public definition: PermissionsDefinition,
        public entityType: Contract.AccessControllerGetPermissionsModelEntityType) {
    }
}

export const [usePermissionsContext, , usePermissionsContextProvider] = makeContextProvider<PermissionsContext>();

export function Permissions() {
    let { entityType } = useRoute(`${CustomerConsoleAppUrlHelper.getAccessPermissionsRelativeUrl()}/{entityType}`);
    const getSelectedScopeHasData = useGetSelectedScopeHasData();
    const filteredActiveCloudProviderTenantModels = tenantModelStore.useGetFilteredActiveCloudProviderTenants();

    entityType = entityType ?? Contract.AccessControllerGetPermissionsModelEntityType.SourceEntity;

    const localization =
        useLocalization(
            "views.customer.access.permissions",
            () => ({
                title: "Permissions Query"
            }));
    useLayoutOptions({ view: { title: localization.title() } });

    return !getSelectedScopeHasData(filteredActiveCloudProviderTenantModels)
        ? <AnalyzingView/>
        : <Table
            entityType={entityType as Contract.AccessControllerGetPermissionsModelEntityType}
            key={entityType}/>;
}

type TableProps = {
    entityType: Contract.AccessControllerGetPermissionsModelEntityType;
};

function Table({ entityType }: TableProps) {
    const definition = useDefinition(entityType);
    const [, , ContextProvider] =
        usePermissionsContextProvider(
            () =>
                new PermissionsContext(
                    definition,
                    entityType));
    const excessivePermissionsEnabled = tenantModelStore.useGetFilteredActiveCloudProviderTenantsExcessivePermissionsEnabled();

    const localization =
        useLocalization(
            "views.customer.access.permissions.table",
            () => ({
                actions: {
                    entityType: {
                        item: {
                            [Contract.AccessControllerGetPermissionsModelEntityType.SourceEntity]: "Identities",
                            [Contract.AccessControllerGetPermissionsModelEntityType.DestinationEntity]: "Resources"
                        },
                        placeholder: {
                            [Contract.AccessControllerGetPermissionsModelEntityType.SourceEntity]: "Group by **Identities**",
                            [Contract.AccessControllerGetPermissionsModelEntityType.DestinationEntity]: "Group by **Resources**"
                        }
                    },
                    filter: {
                        exclude: "Filter out **{{value}}**",
                        include: "Filter only **{{value}}**"
                    },
                    profile: "Go to profile page"
                },
                columns: {
                    accessLevels: "Access Level",
                    actionConditionExists: {
                        false: "Without",
                        title: "Conditions",
                        true: "With"
                    },
                    actionExcessive: "Usage",
                    actionRiskCategories: "Permission Category",
                    actions: "Permissions",
                    actionScopes: {
                        title: "Permission Scope",
                        [Contract.PermitterPermissionScope.Any]: "All actions",
                        [Contract.PermitterPermissionScope.Pattern]: "Action pattern",
                        [Contract.PermitterPermissionScope.Exact]: "Specific actions"
                    },
                    actionServices: "Permission Service",
                    destinationResourceAttributes: "Resource Labels",
                    destinationResourceIds: "Resources",
                    destinationResourceScopes: {
                        title: "Resource Scope",
                        [Contract.PermitterPermissionScope.Any]: "All resources",
                        [Contract.PermitterPermissionScope.Pattern]: "Group of resources",
                        [Contract.PermitterPermissionScope.Exact]: "Specific resources"
                    },
                    destinationResourceTags: "Resource Tags",
                    destinationResourceTenantIds: "Resource Account",
                    destinationResourceTypeNames: "Resource Type",
                    originatorEntityIds: "Originators",
                    originatorEntityTenantIds: "Originator Account",
                    originatorEntityTypeNames: "Originator Type",
                    permitterEntityIds: "Granted Through",
                    sourceIdentity: "Identity",
                    sourceIdentityAttributes: "Identity Labels",
                    sourceIdentityTags: "Identity Tags",
                    sourceIdentityTenantIds: "Identity Account",
                    sourceIdentityTypeNames: "Identity Type",
                    tenantId: "Platform"
                },
                empty: {
                    withFilter: "No Matching Data",
                    withoutFilter: "No Data"
                },
                title: "Permissions Query"
            }));

    const [permissionFiltersPromise] =
        useOperation<Contract.PermissionFilters>(
            Permissions,
            async () => {
                const { filters } = await AccessController.getPermissionFilters();
                return filters;
            });

    const getFilters =
        (filterMap: Dictionary<any>) =>
            new Contract.PermissionManagerRequestFilters(
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AccessLevels]),
                ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[TableColumnId.ActionConditionExists]),
                map(
                    filterMap[TableColumnId.ActionExcessive],
                    {
                        [Contract.PermissionActionExcessiveness.Excessive]: () => true,
                        [Contract.PermissionActionExcessiveness.Nonexcessive]: () => false
                    },
                    () => undefined),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.ActionRiskCategories]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.ActionScopes]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.Actions]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.ActionServices]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.DestinationEntityAttributes]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.DestinationEntities]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.DestinationEntityScopes]),
                ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.DestinationEntityTags]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.DestinationEntityTenantIds]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.DestinationEntityTypeNames]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.OriginatorEntities]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.OriginatorEntityTenantIds]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.OriginatorEntityTypeNames]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.PermitterEntities]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.SourceEntityAttributes]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.SourceEntities]),
                ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.SourceEntityTags]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.SourceEntityTenantIds]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.SourceEntityTypeNames]));

    const fetchItems =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const requestFilters = getFilters(filterMap);
            const getPermissionsModelEntitySummary =
                async () => {
                    const { permissionsModelEntityCount } =
                        await AccessController.getPermissionsModelCount(
                            new Contract.AccessControllerGetPermissionsModelCountRequest(
                                entityType,
                                requestFilters));

                    return { count: permissionsModelEntityCount };
                };

            const { permissionsModelPage } =
                await AccessController.getPermissionsModelPage(
                    new Contract.AccessControllerGetPermissionsModelPageRequest(
                        entityType,
                        requestFilters,
                        limit,
                        skip));

            return new DataTableFetchItemsResult(
                getPermissionsModelEntitySummary,
                permissionsModelPage.items,
                !permissionsModelPage.hasMore);
        };

    const getFilterOriginatorEntityPage =
        async (searchText: Optional<string>, skip: number, limit: number, data?: number) => {
            const { principalFilterItemPage: filterOriginatorEntityPage } =
                await AccessController.getPrincipalFilterItemPage(
                    new Contract.AccessControllerGetPrincipalPermissionFilterItemPageRequest(
                        limit,
                        Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntities,
                        searchText,
                        skip)) as Contract.AccessControllerGetPrincipalFilterItemPageResponse<string>;

            const filterOriginatorEntityCount = filterOriginatorEntityPage?.count ?? data ?? 0;
            return new PagedEntityFilterEntityIdPage(
                filterOriginatorEntityCount,
                false,
                filterOriginatorEntityPage.items,
                () => filterOriginatorEntityCount);
        };

    const getEntityFilterEntityItemPage =
        (type: Contract.EntityControllerSearchEntityIdsPermissionRequestType) =>
            ElasticsearchItemPageHelper.makePagedEntityFilter(
                async (itemNextPageSearchCursor, searchText, limit) => {
                    const { entityIdPage } =
                        await EntityController.searchEntityIds(
                            new Contract.EntityControllerSearchEntityIdsPermissionRequest(
                                true,
                                limit,
                                itemNextPageSearchCursor,
                                searchText,
                                type));
                    return entityIdPage;
                });

    const filterMapRef = useRef<Dictionary<any>>();
    const [entityCount, setEntityCount] = useState<number>();
    const getTopItemsExportData = useGetTopItemsExportData();
    const tenantNameTranslator = useTenantNameTranslator();

    const getCsvItemPage =
        async (limit: number, skip: number) => {
            const { entityIdToDisplayReferenceMap, entityIdToTenantIdMap, permissionsModels } =
                await AccessController.getPermissionsModelExport(
                    new Contract.AccessControllerGetPermissionsModelExportRequest(
                        entityType,
                        getFilters(filterMapRef.current ?? {}),
                        limit,
                        skip));


            return _.map(
                permissionsModels,
                permissionsModel => {
                    const actions = {
                        "Permissions":
                            getTopItemsExportData(
                                permissionsModel.permissionTopActions,
                                permissionsModel.permissionActionCount,
                                _ => _,
                                _.size(permissionsModel.permissionTopActions))
                    };
                    const destinationEntities = {
                        "Resources":
                            getTopItemsExportData(
                                permissionsModel.destinationEntityTopIds,
                                permissionsModel.destinationEntityCount,
                                destinationResourceId => entityIdToDisplayReferenceMap[destinationResourceId],
                                _.size(permissionsModel.destinationEntityTopIds))
                    };
                    const originators = {
                        "Originators":
                            getTopItemsExportData(
                                permissionsModel.originatorEntityTopIds,
                                permissionsModel.originatorEntityCount,
                                originatorEntityId => entityIdToDisplayReferenceMap[originatorEntityId],
                                _.size(permissionsModel.originatorEntityTopIds))
                    };
                    const permitters = {
                        "Policies":
                            getTopItemsExportData(
                                permissionsModel.permitterEntityIds,
                                _.size(permissionsModel.permitterEntityIds),
                                permitterEntityId => entityIdToDisplayReferenceMap[permitterEntityId])
                    };
                    const sourceEntities = {
                        "Identities":
                            getTopItemsExportData(
                                permissionsModel.sourceEntityTopIds,
                                permissionsModel.sourceEntityCount,
                                sourceEntityId => entityIdToDisplayReferenceMap[sourceEntityId],
                                _.size(permissionsModel.sourceEntityTopIds))
                    };

                    switch (entityType) {
                        case Contract.AccessControllerGetPermissionsModelEntityType.DestinationEntity:
                            return {
                                ...destinationEntities,
                                "Resource Account":
                                    tenantNameTranslator(
                                        entityIdToTenantIdMap[definition.getPermissionsModelEntityId(permissionsModel)],
                                        { includeRawId: true }),
                                ...actions,
                                ...permitters,
                                ...sourceEntities,
                                ...originators
                            };
                        case Contract.AccessControllerGetPermissionsModelEntityType.SourceEntity:
                            return {
                                ...sourceEntities,
                                "Identity Account":
                                    tenantNameTranslator(
                                        entityIdToTenantIdMap[definition.getPermissionsModelEntityId(permissionsModel)],
                                        { includeRawId: true }),
                                ...originators,
                                ...actions,
                                ...permitters,
                                ...destinationEntities
                            };
                        default:
                            throw new UnexpectedError("entityType", entityType);
                    }
                });
        };

    const dataTableActionsRef = useRef<DataTableActions>();
    const setRoute = useSetRoute();

    function getEntityContextMenuItems(columnId: string, entityModel: Optional<Contract.EntityModel>) {
        if (_.isNil(entityModel)) {
            return [];
        }

        const entityProfileUrl = CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel);

        let entityId = entityModel.id;
        if (TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AzureAuthorizationRoleAssignmentResource)) {
            entityId = _.as<Contract.AzureAuthorizationRoleAssignmentResource>(entityModel.entity).roleDefinitionId;
        } else if (TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.GcpIamRoleBinding)) {
            entityId = _.as<Contract.GcpIamRoleBinding>(entityModel.entity).roleId;
        }

        const entityElement = (
            <Entity
                entityIdOrModel={entityId}
                glanceOptions={{ disabled: true }}
                linkOptions={{ disabled: true }}
                variant="text"/>);
        return _.filter([
            new ContextMenuItem(
                () =>
                    dataTableActionsRef.current!.setFilter(
                        columnId,
                        () => new PagedValuesFilterSelection(false, "include", [entityId])),
                localization.actions.filter.include({ value: entityElement })),
            new ContextMenuItem(
                () =>
                    dataTableActionsRef.current!.setFilter(
                        columnId,
                        filter => {
                            if (_.isNil(filter) || filter.type === "exclude") {
                                return new PagedValuesFilterSelection(
                                    false,
                                    "exclude",
                                    _.union(filter?.values, [entityId]));
                            } else {
                                const values = _.difference(filter?.values, [entityId]);
                                return _.isEmpty(values)
                                    ? undefined
                                    : new PagedValuesFilterSelection(
                                        false,
                                        "include",
                                        values);
                            }
                        }),
                localization.actions.filter.exclude({ value: entityElement })),
            _.isNil(entityProfileUrl)
                ? undefined!
                : new ContextMenuItem(
                    () => setRoute(entityProfileUrl),
                    localization.actions.profile())
        ]);
    }

    function getEntityTypeMenuItems() {
        function createActionMenuItem(itemEntityType: Contract.AccessControllerGetPermissionsModelEntityType) {
            return new ActionMenuItem(
                () => {
                    if (entityType === itemEntityType) {
                        return;
                    }

                    setUrlRoute(
                        `${CustomerConsoleAppUrlHelper.getAccessPermissionsRelativeUrl()}/${itemEntityType}`,
                        undefined,
                        { preserveSearchString: true });
                },
                localization.actions.entityType.item[itemEntityType](),
                { selected: entityType === itemEntityType });
        }

        return _<MenuItem>([]).
            concat(createActionMenuItem(Contract.AccessControllerGetPermissionsModelEntityType.SourceEntity)).
            concat(createActionMenuItem(Contract.AccessControllerGetPermissionsModelEntityType.DestinationEntity)).
            value();
    }

    const theme = useTheme();
    return (
        <VerticalFillGrid>
            <ContextProvider>
                <DataTable
                    actionsRef={dataTableActionsRef}
                    columnOptions={{
                        orderOptions: {
                            enabled: true,
                            persistenceStorageItem: StorageHelper.customerAccessPermissionQueryColumnOrder(entityType)
                        },
                        resizable: true,
                        selectorOptions: {
                            enabled: true,
                            persistenceStorageItem: StorageHelper.customerAccessPermissionQueryColumnSelector(entityType)
                        }
                    }}
                    emptyMessageOptions={{
                        emptyMessageText:
                            new EmptyMessageText(
                                localization.empty.withoutFilter(),
                                localization.empty.withFilter())
                    }}
                    fetchItems={fetchItems}
                    filtersOptions={{
                        onChanged: filterMap => filterMapRef.current = filterMap,
                        persist: {
                            visibilityStorageItem: StorageHelper.customerAccessPermissionsEntitiesFilters(entityType)
                        }
                    }}
                    getItemId={(item: Contract.PermissionsModel) => definition.getPermissionsModelEntityId(item)}
                    sortOptions={{ enabled: false }}
                    virtualizationEnabled={true}
                    onItemCountChanged={entityCount => setEntityCount(entityCount)}>
                    {(() => {
                        const sourceEntitiesColumns = [
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <PagedEntityFilter
                                                getEntityIdPage={getEntityFilterEntityItemPage(Contract.EntityControllerSearchEntityIdsPermissionRequestType.SourceIdentity)}
                                                placeholder={localization.columns.sourceIdentity()}/>
                                    }
                                }}
                                id={TableColumnId.SourceEntities}
                                key={TableColumnId.SourceEntities}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.PermissionsModel>) =>
                                        <EntityContextMenuContextProvider
                                            getContextMenuItems={
                                                entityModel =>
                                                    getEntityContextMenuItems(
                                                        TableColumnId.SourceEntities,
                                                        entityModel)}>
                                            <InlineSourceEntities
                                                getFilters={() => getFilters(filterMapRef.current ?? {})}
                                                permissionsModel={item}/>
                                        </EntityContextMenuContextProvider>}
                                selectorOptions={{ disabled: entityType === Contract.AccessControllerGetPermissionsModelEntityType.SourceEntity }}
                                title={definition.columns.sourceEntities}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.sourceIdentityTypeNames()}>
                                                {filters =>
                                                    <EntityTypeFilter
                                                        emptyValue={false}
                                                        entityTypeNames={filters.sourceEntityTypeNameItems.items}
                                                        placeholder={localization.columns.sourceIdentityTypeNames()}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.SourceEntityTypeNames}
                                key={TableColumnId.SourceEntityTypeNames}
                                title={localization.columns.sourceIdentityTypeNames()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.sourceIdentityTenantIds()}>
                                                {filters =>
                                                    <ScopeTenantFilter
                                                        placeholder={localization.columns.sourceIdentityTenantIds()}
                                                        tenantIds={filters.sourceEntityTenantIdItems.items}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.SourceEntityTenantIds}
                                key={TableColumnId.SourceEntityTenantIds}
                                title={localization.columns.sourceIdentityTenantIds()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <PagedEntityResourceTagFilter
                                                emptyValue={false}
                                                placeholder={localization.columns.sourceIdentityTags()}/>
                                    }
                                }}
                                id={TableColumnId.SourceEntityTags}
                                key={TableColumnId.SourceEntityTags}
                                title={localization.columns.sourceIdentityTags()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.sourceIdentityAttributes()}>
                                                {filters =>
                                                    <EntityAttributeFilter
                                                        emptyValue={false}
                                                        entityAttributeValues={filters.sourceEntityAttributeValueItems.items}
                                                        placeholder={localization.columns.sourceIdentityAttributes()}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.SourceEntityAttributes}
                                key={TableColumnId.SourceEntityAttributes}
                                title={localization.columns.sourceIdentityAttributes()}/>
                        ];
                        const originatorEntitiesColumns = [
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <PagedEntityFilter
                                                getEntityIdPage={getFilterOriginatorEntityPage}
                                                placeholder={localization.columns.originatorEntityIds()}/>
                                    }
                                }}
                                id={TableColumnId.OriginatorEntities}
                                key={TableColumnId.OriginatorEntities}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.PermissionsModel>) =>
                                        <EntityContextMenuContextProvider
                                            getContextMenuItems={
                                                entityModel =>
                                                    getEntityContextMenuItems(
                                                        TableColumnId.OriginatorEntities,
                                                        entityModel)}>
                                            <InlineOriginatorEntities
                                                entityId={definition.getPermissionsModelEntityId(item)}
                                                getFilters={() => getFilters(filterMapRef.current ?? {})}
                                                permissionsModel={item}/>
                                        </EntityContextMenuContextProvider>}
                                title={localization.columns.originatorEntityIds()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.originatorEntityTenantIds()}>
                                                {filters =>
                                                    <ScopeTenantFilter
                                                        placeholder={localization.columns.originatorEntityTenantIds()}
                                                        tenantIds={filters.originatorEntityTenantIdItems.items}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.OriginatorEntityTenantIds}
                                key={TableColumnId.OriginatorEntityTenantIds}
                                title={localization.columns.originatorEntityTenantIds()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.originatorEntityTypeNames()}>
                                                {filters =>
                                                    <EntityTypeFilter
                                                        emptyValue={false}
                                                        entityTypeNames={filters.originatorEntityTypeNameItems.items}
                                                        placeholder={localization.columns.originatorEntityTypeNames()}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.OriginatorEntityTypeNames}
                                key={TableColumnId.OriginatorEntityTypeNames}
                                title={localization.columns.originatorEntityTypeNames()}/>
                        ];
                        const accessLevelColumns = [
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <AccessLevelFilter placeholder={localization.columns.accessLevels()}/>
                                    }
                                }}
                                id={TableColumnId.AccessLevels}
                                key={TableColumnId.AccessLevels}
                                title={localization.columns.accessLevels()}/>
                        ];
                        const actionServicesColumns = [
                            <DataTableColumn
                                cellMinWidth={50}
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.actionServices()}>
                                                {filters =>
                                                    <EntityFilter
                                                        emptyValue={false}
                                                        entityIdsOrSearchableReferences={filters.permissionActionServiceIdItems.items}
                                                        placeholder={localization.columns.actionServices()}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.ActionServices}
                                key={TableColumnId.ActionServices}
                                selectorOptions={{ disabled: true }}
                                title={localization.columns.actionServices()}/>
                        ];
                        const actionsColumns = [
                            <DataTableColumn
                                cellMinWidth={50}
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.actions()}>
                                                {filters =>
                                                    <TextValuesFilter
                                                        emptyValue={false}
                                                        placeholder={localization.columns.actions()}
                                                        values={filters.permissionActionRawValueItems.items}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.Actions}
                                key={TableColumnId.Actions}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.PermissionsModel>) =>
                                        <InlinePermissionActions
                                            getContextMenuItemsPromise={
                                                async permissionAction => {
                                                    const filters = await permissionFiltersPromise;
                                                    return [
                                                        new ContextMenuItem(
                                                            () =>
                                                                dataTableActionsRef.current!.setFilter(
                                                                    TableColumnId.Actions,
                                                                    () =>
                                                                        new ValuesFilterSelection(
                                                                            false,
                                                                            _.concat(permissionAction))),
                                                            localization.actions.filter.include({ value: permissionAction })),
                                                        new ContextMenuItem(
                                                            () =>
                                                                dataTableActionsRef.current!.setFilter(
                                                                    TableColumnId.Actions,
                                                                    filter =>
                                                                        new ValuesFilterSelection(
                                                                            false,
                                                                            _.difference(
                                                                                _.isNil(filter)
                                                                                    ? filters.permissionActionRawValueItems.items
                                                                                    : filter.values as string[],
                                                                                _.concat(permissionAction)))),
                                                            localization.actions.filter.exclude({ value: permissionAction }))];
                                                }}
                                            getFilters={() => getFilters(filterMapRef.current ?? {})}
                                            permissionsModel={item}/>}
                                selectorOptions={{ disabled: true }}
                                title={localization.columns.actions()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <ValuesFilter
                                                placeholder={localization.columns.actionScopes.title()}
                                                sorted={false}>
                                                <ValuesFilterItem
                                                    title={localization.columns.actionScopes[Contract.PermitterPermissionScope.Any]()}
                                                    value={Contract.PermitterPermissionScope.Any}/>
                                                <ValuesFilterItem
                                                    title={localization.columns.actionScopes[Contract.PermitterPermissionScope.Pattern]()}
                                                    value={Contract.PermitterPermissionScope.Pattern}/>
                                                <ValuesFilterItem
                                                    title={localization.columns.actionScopes[Contract.PermitterPermissionScope.Exact]()}
                                                    value={Contract.PermitterPermissionScope.Exact}/>
                                            </ValuesFilter>
                                    }
                                }}
                                id={TableColumnId.ActionScopes}
                                key={TableColumnId.ActionScopes}
                                title={localization.columns.actionScopes.title()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <ActionRiskCategoryFilter
                                                placeholder={localization.columns.actionRiskCategories()}/>
                                    }
                                }}
                                id={TableColumnId.ActionRiskCategories}
                                key={TableColumnId.ActionRiskCategories}
                                title={localization.columns.actionRiskCategories()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <PagedEntityFilter
                                                getEntityIdPage={getEntityFilterEntityItemPage(Contract.EntityControllerSearchEntityIdsPermissionRequestType.Permitter)}
                                                placeholder={localization.columns.permitterEntityIds()}/>
                                    }
                                }}
                                id={TableColumnId.PermitterEntities}
                                key={TableColumnId.PermitterEntities}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.PermissionsModel>) =>
                                        <EntityContextMenuContextProvider
                                            getContextMenuItems={
                                                entityModel =>
                                                    getEntityContextMenuItems(
                                                        TableColumnId.PermitterEntities,
                                                        entityModel)}>
                                            <InlinePermitterEntities
                                                awsResourcePermitterTypes={item.awsResourcePermitterTypes}
                                                azureResourcePermitterTypes={item.azureResourcePermitterTypes}
                                                permitterEntityIds={item.permitterEntityIds}/>
                                        </EntityContextMenuContextProvider>}
                                title={localization.columns.permitterEntityIds()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <ValuesFilter
                                                placeholder={localization.columns.actionConditionExists.title()}
                                                sorted={false}>
                                                <ValuesFilterItem
                                                    title={localization.columns.actionConditionExists.true()}
                                                    value={true}/>
                                                <ValuesFilterItem
                                                    title={localization.columns.actionConditionExists.false()}
                                                    value={false}/>
                                            </ValuesFilter>
                                    }
                                }}
                                id={TableColumnId.ActionConditionExists}
                                key={TableColumnId.ActionConditionExists}
                                title={localization.columns.actionConditionExists.title()}/>
                        ];
                        const destinationEntitiesColumns = [
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <PagedEntityFilter
                                                getEntityIdPage={getEntityFilterEntityItemPage(Contract.EntityControllerSearchEntityIdsPermissionRequestType.DestinationResource)}
                                                placeholder={localization.columns.destinationResourceIds()}/>
                                    }
                                }}
                                id={TableColumnId.DestinationEntities}
                                key={TableColumnId.DestinationEntities}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.PermissionsModel>) =>
                                        <EntityContextMenuContextProvider
                                            getContextMenuItems={
                                                entityModel =>
                                                    getEntityContextMenuItems(
                                                        TableColumnId.DestinationEntities,
                                                        entityModel)}>
                                            <InlineDestinationEntities
                                                getFilters={() => getFilters(filterMapRef.current ?? {})}
                                                permissionsModel={item}/>
                                        </EntityContextMenuContextProvider>}
                                selectorOptions={{ disabled: entityType === Contract.AccessControllerGetPermissionsModelEntityType.DestinationEntity }}
                                title={definition.columns.destinationEntities}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.destinationResourceTypeNames()}>
                                                {filters =>
                                                    <EntityTypeFilter
                                                        emptyValue={false}
                                                        entityTypeNames={filters.destinationEntityTypeNameItems.items}
                                                        placeholder={localization.columns.destinationResourceTypeNames()}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.DestinationEntityTypeNames}
                                key={TableColumnId.DestinationEntityTypeNames}
                                title={localization.columns.destinationResourceTypeNames()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.destinationResourceTenantIds()}>
                                                {filters =>
                                                    <ScopeTenantFilter
                                                        placeholder={localization.columns.destinationResourceTenantIds()}
                                                        tenantIds={filters.destinationEntityTenantIdItems.items}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.DestinationEntityTenantIds}
                                key={TableColumnId.DestinationEntityTenantIds}
                                title={localization.columns.destinationResourceTenantIds()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <PagedEntityResourceTagFilter
                                                emptyValue={false}
                                                placeholder={localization.columns.destinationResourceTags()}/>
                                    }
                                }}
                                id={TableColumnId.DestinationEntityTags}
                                key={TableColumnId.DestinationEntityTags}
                                title={localization.columns.destinationResourceTags()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={permissionFiltersPromise}
                                                title={localization.columns.destinationResourceAttributes()}>
                                                {filters =>
                                                    <EntityAttributeFilter
                                                        emptyValue={false}
                                                        entityAttributeValues={filters.destinationEntityAttributeValueItems.items}
                                                        placeholder={localization.columns.destinationResourceAttributes()}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={TableColumnId.DestinationEntityAttributes}
                                key={TableColumnId.DestinationEntityAttributes}
                                title={localization.columns.destinationResourceAttributes()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <ValuesFilter
                                                placeholder={localization.columns.destinationResourceScopes.title()}
                                                sorted={false}>
                                                <ValuesFilterItem
                                                    title={localization.columns.destinationResourceScopes[Contract.PermitterPermissionScope.Any]()}
                                                    value={Contract.PermitterPermissionScope.Any}/>
                                                <ValuesFilterItem
                                                    title={localization.columns.destinationResourceScopes[Contract.PermitterPermissionScope.Pattern]()}
                                                    value={Contract.PermitterPermissionScope.Pattern}/>
                                                <ValuesFilterItem
                                                    title={localization.columns.destinationResourceScopes[Contract.PermitterPermissionScope.Exact]()}
                                                    value={Contract.PermitterPermissionScope.Exact}/>
                                            </ValuesFilter>
                                    }
                                }}
                                id={TableColumnId.DestinationEntityScopes}
                                key={TableColumnId.DestinationEntityScopes}
                                title={localization.columns.destinationResourceScopes.title()}/>
                        ];

                        switch (entityType) {
                            case Contract.AccessControllerGetPermissionsModelEntityType.DestinationEntity:
                                return [
                                    ...destinationEntitiesColumns,
                                    ...actionsColumns,
                                    ...actionServicesColumns,
                                    ...accessLevelColumns,
                                    ...sourceEntitiesColumns,
                                    ...originatorEntitiesColumns
                                ];
                            case Contract.AccessControllerGetPermissionsModelEntityType.SourceEntity:
                                return [
                                    ...sourceEntitiesColumns,
                                    ...originatorEntitiesColumns,
                                    ...actionsColumns,
                                    ...actionServicesColumns,
                                    ...accessLevelColumns,
                                    ...destinationEntitiesColumns
                                ];
                            default:
                                throw new UnexpectedError("entityType", entityType);
                        }
                    })()}
                    <DataTableColumn
                        filterOptions={{
                            toggleItem:
                                <Menu
                                    itemsOrGetItems={
                                        useMemo(
                                            getEntityTypeMenuItems,
                                            [entityType])}
                                    variant="bottomCenter">
                                    <Stack
                                        direction="row"
                                        spacing={0.5}
                                        sx={{
                                            alignItems: "center",
                                            color: theme.palette.text.primary,
                                            height: theme.spacing(3.5),
                                            marginRight: theme.spacing(2)
                                        }}>
                                        <Typography noWrap={true}>
                                            {localization.actions.entityType.placeholder[entityType]()}
                                        </Typography>
                                        <DropdownIcon sx={{ fontSize: "18px" }}/>
                                    </Stack>
                                </Menu>
                        }}
                        id={TableColumnId.EntityType}/>
                    {excessivePermissionsEnabled &&
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element: <PermissionActionExcessivenessFilter/>
                                }
                            }}
                            id={TableColumnId.ActionExcessive}
                            title={localization.columns.actionExcessive()}/>}
                    <DataTableAction>
                        <CsvExportButton
                            fileNameOptions={{
                                filtered: !_.isEmpty(filterMapRef.current),
                                prefix: localization.title()
                            }}
                            getItemPage={getCsvItemPage}
                            itemCount={entityCount}/>
                    </DataTableAction>
                </DataTable>
            </ContextProvider>
        </VerticalFillGrid>);
}

enum TableColumnId {
    AccessLevels = "accessLevels",
    ActionConditionExists = "actionConditionExists",
    ActionExcessive = "actionExcessive",
    ActionRiskCategories = "actionRiskCategories",
    Actions = "actions",
    ActionScopes = "actionScopes",
    ActionServices = "actionServices",
    DestinationEntities = "destinationEntities",
    DestinationEntityAttributes = "destinationEntityAttributes",
    DestinationEntityScopes = "destinationEntityScopes",
    DestinationEntityTags = "destinationEntityTags",
    DestinationEntityTenantIds = "destinationEntityTenantIds",
    DestinationEntityTypeNames = "destinationEntityTypeNames",
    EntityType = "entityType",
    OriginatorEntities = "originatorEntities",
    OriginatorEntityTenantIds = "originatorEntityTenantIds",
    OriginatorEntityTypeNames = "originatorEntityTypeNames",
    PermitterEntities = "permitterEntities",
    SourceEntities = "sourceEntities",
    SourceEntityAttributes = "SourceEntityAttributes",
    SourceEntityTags = "SourceEntityTags",
    SourceEntityTenantIds = "SourceEntityTenantIds",
    SourceEntityTypeNames = "sourceEntityTypeNames"
}