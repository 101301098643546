import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, EntityTypeNameTranslatorOptions, RegionModelHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";

export function useAwsResourceGeneralInformationStep(resourceModel: Contract.EntityModel, resourceTypeNameTranslationOptions?: EntityTypeNameTranslatorOptions) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceGeneralInformationStep.hooks.useAwsResourceGeneralInformationStep",
            () => ({
                creationTime: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}}",
                creationTimeAndCreator: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}} by {{creatorEntity}}",
                creationTimeAndCreatorAndRegion: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}} by {{creatorEntity}} and is located at {{region}}",
                creationTimeAndRegion: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}} and is located at {{region}}",
                creator: "*capitalize*{{resourceTypeName}}** {{resource}} was created by {{creatorEntity}}",
                creatorAndRegion: "*capitalize*{{resourceTypeName}}** {{resource}} was created by {{creatorEntity}} and is located at {{region}}",
                region: "*capitalize*{{resourceTypeName}}** {{resource}} is located at {{region}}"
            }));
    return useMemo(
        () => {
            const awsResourceModel = resourceModel as Contract.AwsResourceModel;
            const resource = awsResourceModel.entity as Contract.AwsResource;
            const resourceLocalizationOptions = {
                creationTime: awsResourceModel.creationTime,
                creatorEntity:
                    _.isNil(awsResourceModel.creatorEntityIdReference)
                        ? undefined
                        : <Entity
                            entityIdOrModel={awsResourceModel.creatorEntityIdReference}
                            variant="text"/>,
                region: RegionModelHelper.tryGet(awsResourceModel.entity.regionId)?.name,
                resource:
                    <Entity
                        entityIdOrModel={awsResourceModel}
                        variant="text"/>,
                resourceTypeName:
                    entityTypeNameTranslator(
                        awsResourceModel.entity.typeName,
                        {
                            includeServiceName: false,
                            variant: "text",
                            ...resourceTypeNameTranslationOptions
                        })
            };
            const hasCreationTime = !_.isNil(awsResourceModel.creationTime);
            const hasCreator = !_.isNil(awsResourceModel.creatorEntityIdReference);
            const hasRegion = !_.isNil(resource.regionId);
            if (hasCreationTime && hasCreator && hasRegion) {
                return localization.creationTimeAndCreatorAndRegion(resourceLocalizationOptions);
            } else if (hasCreationTime && hasCreator) {
                return localization.creationTimeAndCreator(resourceLocalizationOptions);
            } else if (hasCreationTime && hasRegion) {
                return localization.creationTimeAndRegion(resourceLocalizationOptions);
            } else if (hasCreator && hasRegion) {
                return localization.creatorAndRegion(resourceLocalizationOptions);
            } else if (hasCreationTime) {
                return localization.creationTime(resourceLocalizationOptions);
            } else if (hasCreator) {
                return localization.creator(resourceLocalizationOptions);
            } else if (hasRegion) {
                return localization.region(resourceLocalizationOptions);
            } else {
                return undefined;
            }
        },
        [resourceModel]);
}