import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureSqlServerDatabaseRiskContext } from "../contexts";

export function useAzureSqlServerDatabaseEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureSqlServerDatabaseEncryptionDisabledRisk;
    const serverDatabaseModel = entityModelStore.useGet(risk.entityId) as Contract.AzureSqlServerDatabaseModel;

    const getAzureSqlServerDatabaseRiskContext = useGetAzureSqlServerDatabaseRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureSqlServerDatabaseEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{serverDatabase}} is not configured with transparent data encryption",
                sections: {
                    resolution: {
                        step1: "In the **Transparent data encryption** tab, switch **Data encryption** to On",
                        step2: "Click **Save**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            serverDatabase:
                <Entity
                    entityIdOrModel={serverDatabaseModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.DataEncryption}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const serverDatabaseRiskContext = getAzureSqlServerDatabaseRiskContext(serverDatabaseModel);
            return [
                serverDatabaseRiskContext.generalInformation,
                serverDatabaseRiskContext.server,
                serverDatabaseRiskContext.status,
                serverDatabaseRiskContext.storageSize,
                serverDatabaseRiskContext.sensitive,
                serverDatabaseRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}