import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureSqlServerStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureSqlServerStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureSqlServerStatus]: {
                    [Contract.AzureSqlServerStatus.Disabled]: "Disabled",
                    [Contract.AzureSqlServerStatus.Online]: "Online",
                    [Contract.AzureSqlServerStatus.Paused]: "Paused",
                    [Contract.AzureSqlServerStatus.Ready]: "Ready"
                }
            }));

    return (status: Contract.AzureSqlServerStatus) =>
        localization[Contract.TypeNames.AzureSqlServerStatus][status]();
}