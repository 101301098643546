import { ThemeType, useChangeEffect } from "@infrastructure";
import { IconButton } from "@mui/material";
import _ from "lodash";
import React from "react";
import { BeamerHelper, NotificationsIcon, useTheme } from "../../../..";
import "./Beamer.css";

export function BeamerButton() {
    const theme = useTheme();
    useChangeEffect(
        () => {
            if (!window.Beamer) return;

            window.Beamer.update({
                theme:
                    _.toLower(
                        theme.dark
                            ? ThemeType.Dark
                            : ThemeType.Light)
            });
        },
        [theme.dark]);

    return (
        <IconButton
            className={BeamerHelper.beamerButtonId}
            id={BeamerHelper.beamerButtonId}
            size="medium">
            <NotificationsIcon/>
        </IconButton>);
}