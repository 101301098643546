﻿import { InlineItems, Sx, useLocalization } from "@infrastructure";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { EntityTypeNameSelectorSelection } from ".";
import { useEntityTypeNameTranslator } from "../../../hooks";
import { EntityIcon } from "../../EntityIcon";

type InlineEntityTypeNameSelectionProps = {
    selection?: InlineEntityTypeNameSelectionData | EntityTypeNameSelectorSelection;
    sx?: SxProps;
};

export function InlineEntityTypeNameSelection({ selection, sx }: InlineEntityTypeNameSelectionProps) {
    const [typeNames] =
        useMemo(
            () => {
                const inlineSelectionData =
                    selection instanceof EntityTypeNameSelectorSelection
                        ? selection.getInlineSelectionData()
                        : selection;
                return [
                    inlineSelectionData?.typeNames
                ];
            },
            [selection]);

    const localization =
        useLocalization(
            "common.customRiskPolicy.inlineEntityTypeNameSelection",
            () => ({
                placeholder: "these types",
                selectedEntityTypeNames: [
                    "1 type",
                    "{{count | NumberFormatter.humanize}} types"
                ]
            }));

    const textSx =
        Sx.combine(
            {
                fontWeight: 600,
                textDecoration: "underline"
            },
            sx);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <Typography
            component="span"
            sx={textSx}>
            {!_.isNil(typeNames)
                ? <InlineItems
                    items={_.map(
                        typeNames,
                        item => (
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <EntityIcon
                                    entityTypeName={item}
                                    sx={{ fontSize: "24px" }}/>
                                <Box>
                                    {entityTypeNameTranslator(item)}
                                </Box>
                            </Stack>))}
                    namePluralizer={localization.selectedEntityTypeNames}
                    sx={textSx}
                    variant="itemCountAndType"/>
                : localization.placeholder()}
        </Typography>);
}

export type InlineEntityTypeNameSelectionData = {
    typeNames?: string[];
};