import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpAppEngineApplicationServiceRiskContext() {
    return useMemo(
        () => useGcpAppEngineApplicationServiceRiskContext,
        []);
}

function useGcpAppEngineApplicationServiceRiskContext(serviceModel: Contract.GcpAppEngineApplicationServiceModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(serviceModel);
    const revisionModels = entityModelStore.useGet(serviceModel.revisionIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpAppEngineApplicationServiceRiskContext",
            () => ({
                application: "The {{translatedServiceTypeName}} is part of {{application}}",
                revisionMaxCreationTime: "The {{translatedServiceTypeName}} was last deployed at {{revisionMaxCreationTime | TimeFormatter.shortDate}}",
                revisions:
                    {
                        allRunning: "The {{translatedServiceTypeName}} has {{revisions}} with the status of Serving",
                        allStopped: "The {{translatedServiceTypeName}} has {{revisions}} with the status of Stopped",
                        someRunning: "The {{translatedServiceTypeName}} has {{revisions}}, {{runningRevisions}} with the status of Serving"
                    }
            }));

    const runningRevisionModels =
        _.filter(
            revisionModels,
            revisionModel => (revisionModel.entity as Contract.GcpAppEngineApplicationServiceRevision).status === Contract.GcpAppEngineApplicationResourceStatus.Running);
    const translatedServiceTypeName =
        entityTypeNameTranslator(
            serviceModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        application:
            new RiskDefinitionContextItem(
                localization.application({
                    application:
                        <Entity
                            entityIdOrModel={(serviceModel.entity as Contract.GcpAppEngineApplicationService).applicationId}
                            variant="typeText"/>,
                    translatedServiceTypeName
                })),
        revisionMaxCreationTime:
            _.isNil(serviceModel.revisionMaxCreationTime)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.revisionMaxCreationTime({
                        revisionMaxCreationTime: serviceModel.revisionMaxCreationTime,
                        translatedServiceTypeName
                    })),
        revisions:
            !_.isEmpty(revisionModels)
                ? new RiskDefinitionContextItem(
                    (revisionModels.length === runningRevisionModels.length
                        ? localization.revisions.allRunning
                        : _.isEmpty(runningRevisionModels)
                            ? localization.revisions.allStopped
                            : localization.revisions.someRunning)({
                        revisions:
                            <InlineEntities
                                entityIdsOrModels={revisionModels}
                                entityTypeName={Contract.TypeNames.GcpAppEngineApplicationServiceRevision}
                                variant="itemCountAndType"/>,
                        runningRevisions:
                            <InlineEntities
                                entityIdsOrModels={runningRevisionModels}
                                entityTypeName={Contract.TypeNames.GcpAppEngineApplicationServiceRevision}
                                variant="itemCountAndType"/>,
                        translatedServiceTypeName
                    }))
                : undefined
    };
}