import { IpAddressHelper, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAzurePostgreSqlServerAuthenticationTypeTranslator, useAzurePostgreSqlServerStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzurePostgreSqlServerRiskContext() {
    return useMemo(
        () => useAzurePostgreSqlServerRiskContext,
        []);
}

function useAzurePostgreSqlServerRiskContext(serverModel: Contract.AzurePostgreSqlServerModel) {
    const server = serverModel.entity as Contract.AzurePostgreSqlServer;
    const resourceRiskContext = useGetAzureResourceRiskContext()(serverModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(serverModel);
    const serverNetwork = serverModel.entityNetwork as Optional<Contract.AzureDatabaseResourceStateNetwork>;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const postgreSqlServerAuthenticationTypeTranslator = useAzurePostgreSqlServerAuthenticationTypeTranslator();
    const postgreSqlServerStatusTranslator = useAzurePostgreSqlServerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzurePostgreSqlServerRiskContext",
            () => ({
                allowAnyAzureInternalIpAddress: {
                    [Contract.TypeNames.AzurePostgreSqlFlexibleServer]: "**Allow public access from any Azure service within Azure to this server** is enabled allowing unrestricted network inbound access to your database server from all Azure resources, including resources from **any** subscription",
                    [Contract.TypeNames.AzurePostgreSqlSingleServer]: "**Allow access to Azure services** is enabled allowing unrestricted network inbound access to your database server from all Azure resources, including resources from **any** subscription"
                },
                authenticationType: "The {{translatedServerTypeName}} is using **{{authenticationType}}** authentication",
                ipAddressRange: "IP address range {{startIpAddress}}-{{endIpAddress}} is open allowing access to {{ipCount | NumberFormatter.unit}} IP addresses",
                status: "The {{translatedServerTypeName}}'s status is **{{status}}**"
            }));
    const translatedServerTypeName =
        entityTypeNameTranslator(
            server.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        allowAnyAzureInternalIpAddress:
            server.allowAnyAzureInternalIpAddress
                ? new RiskDefinitionContextItem(localization.allowAnyAzureInternalIpAddress[serverModel.entity.typeName as Contract.TypeNames.AzurePostgreSqlFlexibleServer | Contract.TypeNames.AzurePostgreSqlSingleServer]())
                : undefined,
        authenticationType:
            new RiskDefinitionContextItem(
                localization.authenticationType({
                    authenticationType: postgreSqlServerAuthenticationTypeTranslator(server.authenticationType),
                    translatedServerTypeName
                })),
        firewallRules:
            _.isNil(serverNetwork)
                ? []
                : _.map(
                    serverNetwork.wideRangeFirewallIpAddressRangeRules,
                    firewallRule =>
                        new RiskDefinitionContextItem(
                            localization.ipAddressRange({
                                endIpAddress: firewallRule.endIpAddress,
                                ipCount:
                                    IpAddressHelper.getIpAddressCount(
                                        firewallRule.startIpAddress,
                                        firewallRule.endIpAddress),
                                startIpAddress: firewallRule.startIpAddress
                            }))),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: postgreSqlServerStatusTranslator(server.status),
                    translatedServerTypeName
                }))
    };
}