import { EmptyMessage, makeContextProvider, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, EntityController, entityModelStore, EntitySnapshotController, useTheme } from "../../../../../../../../common";
import { Snapshot } from "./components";

class SnapshotsContext {
    constructor(
        public entitySnapshotMap: Dictionary<Contract.EntitySnapshot>) {
    }
}

export const [useSnapshotsContext, , useSnapshotsContextProvider] = makeContextProvider<SnapshotsContext>();

type SnapshotsProp = {
    entityId: string;
    riskId?: string;
    variant: "entity" | "risk";
};

export function Snapshots({ entityId, riskId, variant }: SnapshotsProp) {
    const [{ entitySnapshotMap, entitySnapshots }] =
        useExecuteOperation(
            [Snapshots, entityId],
            async () => {
                const { entitySnapshots } =
                    await EntityController.getEntitySnapshots(
                        new Contract.EntityControllerGetEntitySnapshotsRequest(
                            entityId,
                            riskId));

                await entityModelStore.get(
                    _.flatMap(
                        entitySnapshots,
                        entitySnapshot => entitySnapshot.relatedEntityIds));

                const relatedEntitySnapshotIds =
                    _(entitySnapshots).
                        flatMap(entitySnapshot => entitySnapshot.relatedEntitySnapshotIds).
                        uniq().
                        value();

                const { entitySnapshots: relatedEntitySnapshots } =
                    _.isEmpty(relatedEntitySnapshotIds)
                        ? { entitySnapshots: [] }
                        : await EntitySnapshotController.getSnapshots(new Contract.EntitySnapshotControllerGetSnapshotsRequest(relatedEntitySnapshotIds));
                const entitySnapshotMap =
                    _(entitySnapshots).
                        concat(relatedEntitySnapshots).
                        keyBy(entitySnapshot => entitySnapshot.id).
                        value();
                return { entitySnapshotMap, entitySnapshots };
            });

    const [, , ContextProvider] = useSnapshotsContextProvider(() => new SnapshotsContext(entitySnapshotMap));
    const localization =
        useLocalization(
            "views.customer.entities.profile.snapshots",
            () => ({
                empty: "No Snapshots Available"
            }));
    const theme = useTheme();
    return _.isEmpty(entitySnapshots)
        ? <EmptyMessage message={localization.empty()}/>
        : <ContextProvider>
            <Stack sx={
                variant === "risk"
                    ? undefined
                    : {
                        height: "100%",
                        overflow: "hidden auto"
                    }}>
                {_.map(
                    entitySnapshots,
                    entitySnapshot =>
                        <Box
                            key={entitySnapshot.id}
                            sx={{ padding: theme.spacing(0.5, 0) }}>
                            <Snapshot entitySnapshot={entitySnapshot}/>
                        </Box>)}
            </Stack>
        </ContextProvider>;
}