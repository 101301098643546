﻿import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { GcpComputeLoadBalancerLoadBalancingScheme } from "../../../../../../../../../../../../common/controllers/types.generated";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpComputeSslPolicyRiskContext() {
    return useMemo(
        () => useGcpComputeSslPolicyRiskContext,
        []);
}

function useGcpComputeSslPolicyRiskContext(sslPolicyModel: Contract.GcpComputeSslPolicyModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(sslPolicyModel);
    const sslPolicy = sslPolicyModel.entity as Contract.GcpComputeSslPolicy;
    const loadBalancerModels = entityModelStore.useGet(sslPolicyModel.loadBalancerIds) as Contract.GcpComputeLoadBalancerModel[];

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpComputeSslPolicyRiskContext",
            () => ({
                loadBalancers: {
                    empty: "The {{translatedSslPolicyTypeName}} is not used by any load balancer",
                    external: [
                        "1 external load balancer",
                        "{{count | NumberFormatter.humanize}} external load balancer"
                    ],
                    internal: [
                        "1 internal load balancer",
                        "{{count | NumberFormatter.humanize}} internal load balancer"
                    ],
                    title: "The {{translatedSslPolicyTypeName}} is used by {{loadBalancers}}"
                },
                tlsMinVersion: "The {{translatedSslPolicyTypeName}} is configured with minimum TLS version {{tlsMinVersion}}"
            }));
    const translatedSslPolicyTypeName =
        entityTypeNameTranslator(
            sslPolicy.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const [externalLoadBalancerModels, internalLoadBalancerModels] =
        useMemo(
            () =>
                _.partition(
                    loadBalancerModels,
                    loadBalancerModel =>
                        (loadBalancerModel.entity as Contract.GcpComputeLoadBalancer).loadBalancingScheme === GcpComputeLoadBalancerLoadBalancingScheme.External ||
                        (loadBalancerModel.entity as Contract.GcpComputeLoadBalancer).loadBalancingScheme === GcpComputeLoadBalancerLoadBalancingScheme.ExternalManaged),
            [loadBalancerModels]);

    return {
        ...entityRiskContext,
        loadBalancers:
            new RiskDefinitionContextItem(
                _.isEmpty(sslPolicyModel.loadBalancerIds)
                    ? localization.loadBalancers.empty({ translatedSslPolicyTypeName })
                    : localization.loadBalancers.title({
                        loadBalancers:
                            localizeList(
                                _.filter([
                                    _.isEmpty(externalLoadBalancerModels)
                                        ? undefined
                                        : <InlineEntities
                                            entityIdsOrModels={externalLoadBalancerModels}
                                            entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}
                                            namePluralizer={localization.loadBalancers.external}
                                            variant="itemCountAndType"/>,
                                    _.isEmpty(internalLoadBalancerModels)
                                        ? undefined
                                        : <InlineEntities
                                            entityIdsOrModels={internalLoadBalancerModels}
                                            entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}
                                            namePluralizer={localization.loadBalancers.internal}
                                            variant="itemCountAndType"/>])),
                        translatedSslPolicyTypeName
                    })),
        tlsMinVersion:
            new RiskDefinitionContextItem(
                localization.tlsMinVersion({
                    tlsMinVersion: sslPolicy.tlsMinVersion,
                    translatedSslPolicyTypeName
                })
            )
    };
}