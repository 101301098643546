﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpServerlessNetworkedResourceNetworkInboundAccessTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpServerlessNetworkedResourceNetworkInboundAccessTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpServerlessNetworkedResourceNetworkInboundAccessType]: {
                    [Contract.GcpServerlessNetworkedResourceNetworkInboundAccessType.External]: "Allow all",
                    [Contract.GcpServerlessNetworkedResourceNetworkInboundAccessType.InternalVpc]: "Allow internal",
                    [Contract.GcpServerlessNetworkedResourceNetworkInboundAccessType.LoadBalancerOrInternalVpc]: "Allow internal and Cloud Load Balancing"
                }
            }));
    return (networkInboundAccessType: Contract.GcpServerlessNetworkedResourceNetworkInboundAccessType) =>
        localization[Contract.TypeNames.GcpServerlessNetworkedResourceNetworkInboundAccessType][networkInboundAccessType]();
}