﻿import { NumberFormatter, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsSageMakerNotebookInstanceMetadataServiceVersionTranslator, useAwsSageMakerNotebookInstanceStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";

export function useAwsSageMakerNotebookInstanceDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const notebookInstance = resourceModel.entity as Contract.AwsSageMakerNotebookInstance;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            networkInterfaceIds:
                _.isNil(notebookInstance.networkInterfaceId)
                    ? undefined
                    : [notebookInstance.networkInterfaceId],
            resourceModel
        });
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const notebookInstanceMetadataServiceVersionTranslator = useAwsSageMakerNotebookInstanceMetadataServiceVersionTranslator();
    const notebookInstanceStatusTranslator = useAwsSageMakerNotebookInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSageMakerNotebookInstanceDefinition",
            () => ({
                info: {
                    items: {
                        metadataServiceVersion: "Metadata Version",
                        operatingSystemName: "Platform",
                        rawType: "Instance Type",
                        roleReference: "Role",
                        rootAccess: {
                            false: "Disabled",
                            title: "Root Access",
                            true: "Enabled"
                        },
                        status: "Status",
                        unrestrictedOutboundAccess: {
                            false: "Disabled",
                            title: "Direct Internet Access",
                            true: "Enabled"
                        },
                        updateTime: "Update Time",
                        volumeSize: "Volume Size"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={notebookInstanceStatusTranslator(notebookInstance.status)}/>,
                    <InfoItem
                        key="unrestrictedOutboundAccess"
                        title={localization.info.items.unrestrictedOutboundAccess.title()}
                        value={
                            notebookInstance.unrestrictedOutboundAccess
                                ? localization.info.items.unrestrictedOutboundAccess.true()
                                : localization.info.items.unrestrictedOutboundAccess.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={notebookInstance.roleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="roleReference"
                        title={localization.info.items.roleReference()}/>,
                    <InfoItem
                        key="rootAccess"
                        title={localization.info.items.rootAccess.title()}
                        value={
                            notebookInstance.rootAccess
                                ? localization.info.items.rootAccess.true()
                                : localization.info.items.rootAccess.false()}/>,
                    <InfoItem
                        key="metadataServiceVersion"
                        title={localization.info.items.metadataServiceVersion()}
                        value={notebookInstanceMetadataServiceVersionTranslator(notebookInstance.metadataServiceVersion)}/>,
                    <InfoItem
                        key="rawType"
                        title={localization.info.items.rawType()}
                        value={notebookInstance.rawType}/>,
                    <InfoItem
                        key="updateTime"
                        title={localization.info.items.updateTime()}
                        value={TimeFormatter.profileFormatDateTime(notebookInstance.updateTime)}/>,
                    <InfoItem
                        key="operatingSystemName"
                        title={localization.info.items.operatingSystemName()}
                        value={notebookInstance.operatingSystemName}/>,
                    <InfoItem
                        key="volumeSize"
                        title={localization.info.items.volumeSize()}
                        value={NumberFormatter.storage(notebookInstance.volumeSize)}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
            </Info>
    });
}