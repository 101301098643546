import { EnumValuesFilter } from "@infrastructure";
import React from "react";
import { Contract } from "../../../common";
import { useGcpScopeResourceAccessLevelTranslator } from "../hooks";

type GcpScopeResourceAccessLevelFilterProps = {
    placeholder: string;
};

export function GcpScopeResourceAccessLevelFilter({ placeholder }: GcpScopeResourceAccessLevelFilterProps) {
    const scopeResourceAccessLevelTranslator = useGcpScopeResourceAccessLevelTranslator();

    return (
        <EnumValuesFilter
            enumType={Contract.GcpScopeResourceAccessLevel}
            enumTypeTranslator={scopeResourceAccessLevelTranslator}
            placeholder={placeholder}
            sorted={false}/>);
}