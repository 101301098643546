﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEksClusterStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEksClusterStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsEksClusterStatus]: {
                    [Contract.AwsEksClusterStatus.Active]: "Active",
                    [Contract.AwsEksClusterStatus.Creating]: "Creating",
                    [Contract.AwsEksClusterStatus.Deleting]: "Deleting",
                    [Contract.AwsEksClusterStatus.Failed]: "Failed",
                    [Contract.AwsEksClusterStatus.Pending]: "Pending",
                    [Contract.AwsEksClusterStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.AwsEksClusterStatus) =>
        localization[Contract.TypeNames.AwsEksClusterStatus][status]();
}