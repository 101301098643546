import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext } from "../..";
import { Contract } from "../../../../../../../../../../common";
import { useRiskPolicyAuditEventDetailsItems } from "../useRiskPolicyAuditEventDetailsItems";
import { useGetRiskPolicyUpdateAuditEventDetailsItems } from "./hooks/useGetRiskPolicyUpdateAuditEventDetailsItems";

export function useRiskPolicyAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskPolicyAuditEventDetailsItems = useRiskPolicyAuditEventDetailsItems(context);
    const getRiskPolicyUpdateAuditEventDetailsItems = useGetRiskPolicyUpdateAuditEventDetailsItems();
    const riskPolicyUpdateAuditEventChanges = (context.auditEventModel.auditEvent as Contract.RiskPolicyUpdateAuditEvent).changes;

    return new AuditEventDefinition(
        _.filter([
            riskPolicyAuditEventDetailsItems.titleItem,
            riskPolicyAuditEventDetailsItems.customPolicyDescriptionItem,
            ...(_.isNil(riskPolicyUpdateAuditEventChanges)
                ? []
                : getRiskPolicyUpdateAuditEventDetailsItems(
                    riskPolicyUpdateAuditEventChanges,
                    context))
        ]));
}