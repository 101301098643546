import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useOneLoginTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useOneLoginTenantAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                tenantClientId: "Client Id",
                tenantClientSecret: "Client Secret",
                tenantName: "Account name",
                tenantUrl: "Account URL"
            }));

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.OneLoginTenantAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantUrl(),
                    tenantAuditEvent.tenantUrl),
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName)).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.OneLoginTenantUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (tenantAuditEvent as Contract.OneLoginTenantUpdateAuditEvent).tenantClientIdChanged
                                ? localization.tenantClientId()
                                : undefined,
                            (tenantAuditEvent as Contract.OneLoginTenantUpdateAuditEvent).tenantClientSecretChanged
                                ? localization.tenantClientSecret()
                                : undefined,
                            (tenantAuditEvent as Contract.OneLoginTenantUpdateAuditEvent).tenantNameChanged
                                ? localization.tenantName()
                                : undefined,
                            (tenantAuditEvent as Contract.OneLoginTenantUpdateAuditEvent).tenantUrlChanged
                                ? localization.tenantUrl()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}