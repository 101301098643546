import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import { GitLabServerContext, useSetGitLabServerContext } from "../../..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.CodeServerModel>) {
    const setGitLabContext = useSetGitLabServerContext();

    const [deleteServerExecuting, setDeleteServerExecuting] = useState(false);
    const [deleteOrganizationError, setDeleteOrganizationError] = useState(false);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.gitLabServer.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete server",
                        prompt: "Are you sure you want to delete the server {{serverName}}?\n This will also delete any associated groups/projects that you configured",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteServerExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteOrganizationError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {!item.configuration.systemDeleted && (
                <Menu
                    itemsOrGetItems={[
                        new ActionMenuItem(
                            () => setGitLabContext(new GitLabServerContext(item)),
                            localization.actions.edit(),
                            {
                                disabled: deleteServerExecuting,
                                icon: <EditIcon/>
                            }),
                        new ActionMenuItem(
                            async () => {
                                setDeleteServerExecuting(true);
                                setDeleteOrganizationError(false);

                                try {
                                    const { deletedOrganizationIds, deletedTenantIds } =
                                        await ConfigurationController.deleteGitLabServer(
                                            new Contract.ConfigurationControllerDeleteGitLabServerRequest(item.configuration.id));
                                    await tenantModelStore.notify(deletedTenantIds);
                                    await scopeSystemEntityModelStore.notify(deletedOrganizationIds);
                                    await scopeSystemEntityModelStore.notify(item.configuration.id);
                                } catch {
                                    setDeleteOrganizationError(true);
                                }

                                setDeleteServerExecuting(false);
                            },
                            localization.actions.delete.title(),
                            {
                                confirmOptions: {
                                    message: localization.actions.delete.prompt({ serverName: (item.configuration as Contract.GitLabServerConfiguration).name })
                                },
                                disabled: deleteServerExecuting,
                                icon: <DeleteIcon/>
                            })
                    ]}/>)}
        </Stack>);
}