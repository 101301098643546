import { Link, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureContainerServiceManagedClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";

export function useAzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AzureContainerServiceManagedClusterModel;
    const clusterKubernetesSecretEncryptionDisabledRiskModel = riskModel as Contract.AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskModel;

    const getAzureContainerServiceManagedClusterRiskContext = useGetAzureContainerServiceManagedClusterRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} is not configured to encrypt Kubernetes Secrets using CMK",
                sections: {
                    resolution: {
                        step1: {
                            link: "relevant Azure documentation",
                            step1: "Create a key vault and key",
                            step2: "Create a user-assigned managed identity",
                            step3: "Assign permissions (decrypt and encrypt) to access key vault",
                            step4: "Update the AKS cluster to enable KMS etcd encryption",
                            step5: "Rotate the existing keys",
                            title: "Add KMS etcd encryption to the cluster using the Azure CLI (version 2.39.0 or later). Perform the following steps, using the {{aksKmsEtcdEncryptionDocumentationLink}}:"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                    variant="typeText"/>
        }),
        () => [
            new Step(
                localization.sections.resolution.step1.title({
                    aksKmsEtcdEncryptionDocumentationLink:
                        <Link
                            urlOrGetUrl={clusterKubernetesSecretEncryptionDisabledRiskModel.aksKmsEtcdEncryptionDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step1.link()}
                        </Link>
                }),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2()}
                            {localization.sections.resolution.step1.step3()}
                            {localization.sections.resolution.step1.step4()}
                            {localization.sections.resolution.step1.step5()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAzureContainerServiceManagedClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}