import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useTenantTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useTenantTypeTranslator",
            () => ({
                [Contract.TypeNames.TenantType]: {
                    [Contract.TenantType.Aad]: "Microsoft Entra ID",
                    [Contract.TenantType.Aws]: "AWS",
                    [Contract.TenantType.Azure]: "Azure",
                    [Contract.TenantType.Ci]: "Container Registries",
                    [Contract.TenantType.Code]: "Code",
                    [Contract.TenantType.Gci]: "Google Workspace",
                    [Contract.TenantType.Gcp]: "GCP",
                    [Contract.TenantType.Oci]: "OCI",
                    [Contract.TenantType.Okta]: "Okta",
                    [Contract.TenantType.OneLogin]: "OneLogin",
                    [Contract.TenantType.Op]: "On-Premises",
                    [Contract.TenantType.PingIdentity]: "Ping Identity",
                    [Contract.TenantType.Unknown]: "Unknown"
                }
            }));
    return (tenantType: Contract.TenantType) =>
        localization[Contract.TypeNames.TenantType][tenantType]();
}