import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InlineEntities } from "../../../common";

type InlineAwsIamGroupUsersProps = {
    userIds: string[];
};

export function InlineAwsIamGroupUsers({ userIds }: InlineAwsIamGroupUsersProps) {
    const localization =
        useLocalization(
            "tenants.aws.inlineAwsIamGroupUsers",
            () => ({
                users: [
                    "1 user",
                    "{{count | NumberFormatter.humanize}} users"
                ]
            }));
    return (
        <InlineEntities
            entityIdsOrModels={userIds}
            entityTypeName={Contract.TypeNames.AwsIamUser}
            entityVariant="iconTextTypeTenant"
            namePluralizer={localization.users}
            variant="itemCountAndType"/>);
}