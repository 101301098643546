import { Optional, useActions, useLocalization } from "@infrastructure";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { AwsResourceUsageRiskPolicyEdit, EntitySelector, EntitySelectorSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsIamRoleManagementRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const [resourcesValid, setResourcesValid] = useState(false);
    const { scopeId } = useCustomRiskPolicyContext();

    const [roleSelectorSelection, setRoleSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.allResources,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                riskPolicyConfiguration?.criticalActionSeverityPermissionRole,
                riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.resourceIds,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceTags,
                undefined));
    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, criticalActionSeverityPermissionPrincipal, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityTags } = roleSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAwsIamRoleManagementRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        undefined,
                        entityTags,
                        criticalActionSeverityPermissionPrincipal ?? false);
                }
        });

    useEffect(
        () => {
            const resourcesValid = roleSelectorSelection?.valid() ?? false;
            setResourcesValid(resourcesValid);
            onValidChange(resourcesValid);
        },
        [roleSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsIamRoleManagementRiskPolicy.edit",
            () => ({
                prompt: "Select the required {{resourceTranslatedTypeName}}"
            }));
    return (
        <AwsResourceUsageRiskPolicyEdit
            accordionContents={[
                {
                    selector:
                        <EntitySelector
                            builtInEntityAttributeTypeNames={[
                                Contract.TypeNames.RelatedPublicComputeAttribute,
                                Contract.TypeNames.VendorServiceIdentityAttribute,
                                Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute,
                                Contract.TypeNames.AdministratorPrincipalAttribute,
                                Contract.TypeNames.BehaviorRiskIdentityAttribute,
                                Contract.TypeNames.InactiveIdentityAttribute,
                                Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute,
                                Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                Contract.TypeNames.CodeResourceAttribute
                            ]}
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            properties={["all", "criticalActionSeverityPermissionPrincipal", "tags", "attributes"]}
                            scopeId={scopeId}
                            selection={roleSelectorSelection}
                            onSelectionChanged={setRoleSelectorSelection}/>,
                    title:
                        entityTypeNameTranslator(
                            Contract.TypeNames.AwsIamRole,
                            {
                                count: 0,
                                includeServiceName: false
                            }),
                    valid: resourcesValid
                }
            ]}
            prompt={
                localization.prompt({
                    resourceTranslatedTypeName:
                        entityTypeNameTranslator(
                            Contract.TypeNames.AwsIamRole,
                            {
                                count: 0,
                                includeServiceName: false,
                                variant: "text"
                            })
                })}
            template={
                templateTranslator({
                    roles:
                        <InlineEntitySelection
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            selection={roleSelectorSelection}/>
                })}/>);
}