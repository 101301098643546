﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsResourceSnapshotTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsRdsNeptuneClusterSnapshotRiskContext() {
    return useMemo(
        () => useAwsRdsNeptuneClusterSnapshotRiskContext,
        []);
}

function useAwsRdsNeptuneClusterSnapshotRiskContext(snapshotModel: Contract.AwsRdsNeptuneClusterSnapshotModel) {
    const snapshot = snapshotModel.entity as Contract.AwsRdsNeptuneClusterSnapshot;
    const clusterModel = entityModelStore.useGet(snapshot.clusterId);
    const resourceContextItems = useGetAwsResourceRiskContext()(snapshotModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const snapshotTypeTranslator = useAwsResourceSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsRdsNeptuneClusterSnapshotRiskContext",
            () => ({
                cluster: "The {{translatedSnapshotTypeName}} is associated with {{cluster}}",
                storageSize: "The {{translatedSnapshotTypeName}}'s size is {{storageSize | NumberFormatter.storage}}",
                type: "The {{translatedSnapshotTypeName}}'s type is **{{type}}**"
            }));
    const translatedSnapshotTypeName =
        entityTypeNameTranslator(
            snapshot.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceContextItems,
        cluster:
            _.isNil(clusterModel)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.cluster({
                        cluster:
                            <Entity
                                entityIdOrModel={clusterModel}
                                variant="text"/>,
                        translatedSnapshotTypeName
                    })),
        storageSize:
            _.isNil(snapshot.clusterId)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.storageSize({
                        storageSize: snapshot.storageSize,
                        translatedSnapshotTypeName
                    })),
        type:
            _.isNil(snapshot.clusterId)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.type({
                        translatedSnapshotTypeName,
                        type: snapshotTypeTranslator(snapshot.type)
                    }))
    };
}