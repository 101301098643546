import { OperationStore } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, RiskController, RiskPoliciesHelper, RiskPoliciesType } from "..";

export const riskPolicyItemConfigurationOperationStore =
    new OperationStore(
        async (riskPolicyConfigurationTypeName: string, scopeId: string, customRiskPolicyConfiguration: boolean, riskPolicyType: RiskPoliciesType) => {
            if (customRiskPolicyConfiguration) {
                return {
                    parentRiskPolicyModels: [] as Contract.RiskPolicyModel[],
                    scopeIdToRiskPolicyModelMap: {} as Dictionary<Contract.RiskPolicyModel>,
                    scopeRiskPolicyModel: {} as Contract.RiskPolicyModel,
                    tenantRiskPolicyModels: [] as Contract.RiskPolicyModel[]
                };
            }
            const getBuiltInRiskPolicyModelsRequest = RiskPoliciesHelper.getGetBuiltInRiskPolicyModelsRequest(riskPolicyType);
            const { riskPolicyConfigurationTypeNameToDataMap } =
                await RiskController.getBuiltInRiskPolicyModels(
                    new getBuiltInRiskPolicyModelsRequest(
                        [riskPolicyConfigurationTypeName],
                        scopeId));

            const parentRiskPolicyModels = riskPolicyConfigurationTypeNameToDataMap[riskPolicyConfigurationTypeName].parentRiskPolicyModels;
            const scopeIdToRiskPolicyModelMap =
                _.keyBy(
                    parentRiskPolicyModels,
                    parentRiskPolicyModel => parentRiskPolicyModel.riskPolicyConfiguration.scopeId);

            return {
                parentRiskPolicyModels,
                scopeIdToRiskPolicyModelMap,
                scopeRiskPolicyModel: riskPolicyConfigurationTypeNameToDataMap[riskPolicyConfigurationTypeName].scopeRiskPolicyModel,
                tenantRiskPolicyModels: riskPolicyConfigurationTypeNameToDataMap[riskPolicyConfigurationTypeName].tenantRiskPolicyModels
            };
        });