﻿import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureContainerRegistryRegistryRiskContext } from "../contexts/useGetAzureContainerRegistryRegistryRiskContext";

export function useAzureContainerRegistryRegistryInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureContainerRegistryRegistryInboundExternalWideRangeRisk;
    const registryModel = entityModelStore.useGet(risk.entityId) as Contract.AzureContainerRegistryRegistryModel;

    const getAzureContainerRegistryRegistryRiskContext = useGetAzureContainerRegistryRegistryRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureContainerRegistryRegistryInboundExternalWideRangeRiskDefinition",
            () => ({
                contextItems: {
                    listCredentialsActionIdentities: {
                        empty: "No identities have the Microsoft.ContainerRegistry/registries/listCredentials/action permission",
                        notEmpty: "{{listCredentialsActionIdentities}} have the Microsoft.ContainerRegistry/registries/listCredentials/action permission"
                    }
                },
                description: "{{registry}} allows inbound network access from a wide range of public IP addresses",
                sections: {
                    resolution: {
                        step1: "Set **Public network access** to **Selected networks**",
                        step2: {
                            step1: "Any specific IP addresses that require access to the registry",
                            step2: {
                                link: "documentation",
                                text: "Tenable Cloud Security's IP addresses, so that Tenable Cloud Security can continue to analyze this resource.\nYou can find the relevant IP addresses in the following {{documentation}}"
                            },
                            title: "In the **Address range** section, add the following:"
                        },
                        step3: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            registry:
                <Entity
                    entityIdOrModel={registryModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2.text({
                                documentation:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingApplicationIpAddressesRelativeUrl()}
                                        variant="external">
                                        {localization.sections.resolution.step2.step2.link()}
                                    </Link>
                            })}
                        </Steps>
                }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const registryRiskContext = getAzureContainerRegistryRegistryRiskContext(registryModel);
            return [
                registryRiskContext.generalInformation,
                registryRiskContext.sensitive,
                registryRiskContext.repositories,
                registryRiskContext.sensitiveRepositories,
                registryRiskContext.accessKey,
                registryRiskContext.wideRangeInboundSubnets,
                new RiskDefinitionContextItem(
                    _.isEmpty(risk.listCredentialsPermissionActionIdentityIds)
                        ? localization.contextItems.listCredentialsActionIdentities.empty()
                        : localization.contextItems.listCredentialsActionIdentities.notEmpty({
                            listCredentialsActionIdentities:
                                <InlineEntities
                                    entityIdsOrModels={risk.listCredentialsPermissionActionIdentityIds}
                                    entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                                    variant="itemCountAndType"/>
                        })),
                registryRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}