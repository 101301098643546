import _ from "lodash";
import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { Optional } from "../types";
import { AppError } from "../utilities";

export type AnalyticsContextProps = {
    children: ReactNode;
    context: string;
    root?: boolean;
};

export class AnalyticsContextContext {
    constructor(
        public context: string,
        public root: boolean) {
    }
}

export const analyticsContextContext = createContext<Optional<AnalyticsContextContext>>(undefined);

export function AnalyticsContext({ children, context, root = false }: AnalyticsContextProps) {
    const parentContext = useContext(analyticsContextContext);
    const pathContext =
        _.isNil(parentContext) || root
            ? context
            : `${parentContext.context}/${context}`;
    const value =
        useMemo(
            () =>
                new AnalyticsContextContext(
                    pathContext,
                    root),
            [pathContext, root]);

    return (
        <analyticsContextContext.Provider value={value}>
            {children}
        </analyticsContextContext.Provider>);
}

export function useAnalyticsContext() {
    const context = useContext(analyticsContextContext);
    if (_.isNil(context)) {
        throw new AppError("Analytics context not found");
    }

    return context.context;
}