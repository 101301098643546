import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEc2VpcRiskContext } from "../contexts";

export function useAwsEc2VpcRejectFlowLogNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2VpcRejectFlowLogNotExistRisk;
    const vpcModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2VpcModel;

    const getAwsEc2VpcRiskContext = useGetAwsEc2VpcRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VpcRejectFlowLogNotExistRiskDefinition",
            () => ({
                description: "{{flowLogNotExistVpc}} Flow Logs on rejected traffic are not enabled",
                sections: {
                    resolution: {
                        step1: "Select the **Flow Logs** tab in the bottom panel and click **Create Flow Log**",
                        step2: "Complete the **Create Flow Log** wizard"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            flowLogNotExistVpc:
                <Entity
                    entityIdOrModel={vpcModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Vpc,
                AwsConsoleUrlBuilder.getEc2VpcUrl(vpcModel.entity as Contract.AwsEc2Vpc)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const vpcRiskContext = getAwsEc2VpcRiskContext(vpcModel);
            return [
                vpcRiskContext.generalInformation,
                vpcRiskContext.sensitive,
                vpcRiskContext.attachedResources,
                vpcRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}