﻿import { ClearIcon, Dropdown, DropdownActions, DropdownIcon, Message, Optional, useActions, useAsyncEffect, useChangeEffect, useLocalization, useSyncContext } from "@infrastructure";
import { Box, CircularProgress, Divider, InputAdornment, List, ListItemButton, Stack, TextField, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Dispatch, Fragment, Ref, SetStateAction, useMemo, useRef, useState } from "react";
import { Contract, TenantController, useTenantNameTranslator, useTheme } from "../../../../../../../../../../../../../common";
import { useAwsCloudTrailTrailEventSelectorTypeTranslator } from "../../../../../../../../../../../../../tenants";
import { useAddOrEditContext } from "../../../AddOrEdit";

type TrailSelectorProps = {
    actionsRef: Ref<Optional<TrailSelectorActions>>;
    disabled?: boolean;
    eventCategory: Contract.AwsCloudTrailTrailEventCategory;
    onBucketValidationChanged: (validateBucketResponse?: Contract.TenantControllerValidateAwsTenantTrailBucketResponse) => void;
    onSelectedTrailIdChanged: (trailId: string | false | undefined) => void;
    selectedTrailId?: string | false;
    setEventCategoryToBucketValidationExecutingMap: Dispatch<SetStateAction<Dictionary<boolean>>>;
    showNoTrailOption?: boolean;
    subtitle: string;
    tenantValidTrails?: Contract.AwsCloudTrailTrail[];
    title: string;
};

export type TrailSelectorActions = {
    validateBucket: () => void;
};

export function TrailSelector({ actionsRef, disabled = false, eventCategory, onBucketValidationChanged, onSelectedTrailIdChanged, selectedTrailId, setEventCategoryToBucketValidationExecutingMap, showNoTrailOption = false, subtitle, tenantValidTrails, title }: TrailSelectorProps) {
    const { parentFolderId, tenantRegionSystemNames, tenantRoleArn } = useAddOrEditContext();
    const [trailId, setTrailId] = useState(selectedTrailId);

    useChangeEffect(
        () => {
            if (trailId !== selectedTrailId) {
                setTrailId(selectedTrailId);
            }
        },
        [selectedTrailId]);

    const [executeValidateBucket, setExecuteValidateBucket] = useState({});
    const [validateBucketExecuting, setValidateBucketExecuting] = useState(false);
    const validateBucketSyncContext = useSyncContext();
    useAsyncEffect(
        async () => {
            onBucketValidationChanged(undefined);

            if (_.isString(trailId)) {
                const selectedTrail =
                    _.find(
                        tenantValidTrails,
                        tenantValidTrail => tenantValidTrail.id === trailId)!;

                setValidateBucketExecuting(true);
                setEventCategoryToBucketValidationExecutingMap(
                    eventCategoryToBucketValidationExecutingMap => ({
                        ...eventCategoryToBucketValidationExecutingMap,
                        [eventCategory]: true
                    }));

                const syncContext = validateBucketSyncContext.create();
                const validateTrailBucketResponse =
                    await TenantController.validateAwsTenantTrailBucket(
                        new Contract.TenantControllerValidateAwsTenantTrailBucketRequest(
                            parentFolderId,
                            tenantRegionSystemNames,
                            tenantRoleArn!,
                            selectedTrail.arn!));
                if (!validateBucketSyncContext.isActive(syncContext)) {
                    return;
                }

                setValidateBucketExecuting(false);
                onBucketValidationChanged(validateTrailBucketResponse);
                setEventCategoryToBucketValidationExecutingMap(
                    eventCategoryToBucketValidationExecutingMap => ({
                        ...eventCategoryToBucketValidationExecutingMap,
                        [eventCategory]: false
                    }));
            }

            onSelectedTrailIdChanged(trailId);
        },
        [executeValidateBucket]);

    useActions(
        actionsRef,
        {
            validateBucket() {
                setExecuteValidateBucket({});
            }
        });

    const dropdownActionsRef = useRef<DropdownActions>(null);

    function selectTrail(newTrailId: string | false | undefined) {
        dropdownActionsRef.current!.close();

        if (trailId !== newTrailId) {
            setTrailId(newTrailId);
            setExecuteValidateBucket({});
        }
    }

    const trails =
        useMemo(
            () =>
                eventCategory === Contract.AwsCloudTrailTrailEventCategory.Management
                    ? _.filter(
                        tenantValidTrails,
                        tenantValidTrail => tenantValidTrail.eventCategoryToSelectorTypeMap[eventCategory] === Contract.AwsCloudTrailTrailEventSelectorType.All)
                    : _.filter(
                        tenantValidTrails,
                        tenantValidTrail =>
                            tenantValidTrail.eventCategoryToSelectorTypeMap[eventCategory] === Contract.AwsCloudTrailTrailEventSelectorType.All &&
                            _.isNil(tenantValidTrail.eventCategoryToSelectorTypeMap[Contract.AwsCloudTrailTrailEventCategory.Management])),
            [tenantValidTrails]);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.trailItem.trailSelector",
            () => ({
                noTrail: "Continue without CloudTrail",
                select: "Select CloudTrail"
            }));

    const theme = useTheme();
    const empty = _.isEmpty(trails) && !showNoTrailOption;
    return (
        <Stack spacing={1}>
            <Stack
                direction="row"
                justifyItems="center"
                spacing={1}>
                <Typography
                    sx={{ paddingBottom: theme.spacing(0.5) }}
                    variant="h4">
                    {title}
                </Typography>
                <Message
                    level="info"
                    title={subtitle}
                    variant="minimal"/>
            </Stack>
            <Dropdown
                actionsRef={dropdownActionsRef}
                disabled={
                    disabled ||
                    empty ||
                    validateBucketExecuting}
                fullWidth={true}
                popoverElement={
                    <List
                        dense={true}
                        disablePadding={true}>
                        {_.map(
                            trails,
                            trail =>
                                <ListItemButton
                                    dense={true}
                                    key={trail.id}
                                    sx={{ padding: theme.spacing(1) }}
                                    onClick={() => selectTrail(trail.id)}>
                                    <Trail trail={trail}/>
                                </ListItemButton>)}
                        {showNoTrailOption && (
                            <Fragment>
                                <Divider
                                    sx={{ margin: theme.spacing(1, 0) }}
                                    variant="fullWidth"/>
                                <ListItemButton
                                    dense={true}
                                    sx={{ padding: theme.spacing(1) }}
                                    onClick={() => selectTrail(false)}>
                                    <Typography>
                                        {localization.noTrail()}
                                    </Typography>
                                </ListItemButton>
                            </Fragment>)}
                    </List>}
                popoverElementContainerSx={{ padding: 0 }}>
                <TextField
                    disabled={disabled || empty}
                    fullWidth={true}
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment position="end">
                                    <Stack
                                        direction="row"
                                        spacing={1}>
                                        {_.isString(trailId) &&
                                            !disabled &&
                                            !empty &&
                                            !validateBucketExecuting &&
                                            !showNoTrailOption &&
                                            <ClearIcon
                                                onClick={
                                                    event => {
                                                        event.stopPropagation();
                                                        selectTrail(undefined);
                                                    }}/>}
                                        <Box>
                                            {validateBucketExecuting
                                                ? <CircularProgress
                                                    size={theme.spacing(2)}
                                                    variant="indeterminate"/>
                                                : <DropdownIcon/>}
                                        </Box>
                                    </Stack>
                                </InputAdornment>
                        }
                    }}
                    value={
                        _.isNil(trailId)
                            ? localization.select()
                            : trailId === false
                                ? localization.noTrail()
                                : _.find(
                                    tenantValidTrails,
                                    trail => trail.id === trailId)?.displayName}
                    variant="outlined"/>
            </Dropdown>
        </Stack>);
}

type TrailProps = {
    trail: Contract.AwsCloudTrailTrail;
};

function Trail({ trail }: TrailProps) {
    const { tenantName, tenantRawId } = useAddOrEditContext();

    const cloudTrailTrailEventSelectorTypeTranslator = useAwsCloudTrailTrailEventSelectorTypeTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.trailItem.trailSelector.trail",
            () => ({
                false: "No",
                tenant: "{{tenantName}} ({{tenantRawId}})",
                title: "Name: **{{displayName}}** | Account: **{{formattedTenantName}}** | Organization trail: **{{organizationTrail}}** | Management Events: **{{translatedManagementEventSelectorType}}** | S3 Data Events: **{{translatedBucketObjectEventSelectorType}}** | Lambda Data Events: **{{translatedLambdaFunctionConfigurationInvokeEventSelectorType}}**",
                true: "Yes"
            }));
    return (
        <Typography>
            {localization.title({
                displayName: trail.displayName,
                formattedTenantName:
                    trail.tenantId === tenantRawId
                        ? localization.tenant({ tenantName, tenantRawId })
                        : tenantNameTranslator(trail.tenantId),
                organizationTrail:
                    trail.organizationTrail
                        ? localization.true()
                        : localization.false(),
                translatedBucketObjectEventSelectorType: cloudTrailTrailEventSelectorTypeTranslator(trail.bucketObjectEventSelectorType),
                translatedLambdaFunctionConfigurationInvokeEventSelectorType: cloudTrailTrailEventSelectorTypeTranslator(trail.lambdaFunctionConfigurationInvokeEventSelectorType),
                translatedManagementEventSelectorType: cloudTrailTrailEventSelectorTypeTranslator(trail.managementEventSelectorType)
            })}
        </Typography>);
}