import { InfoIcon, Tooltip } from "@infrastructure";
import { FormControl, InputAdornment, SxProps, TextField, Theme, Typography, useTheme } from "@mui/material";
import React from "react";

type TextInputProps = {
    disabled: boolean;
    formSx?: SxProps<Theme>;
    info?: string;
    onBlur?: () => void;
    onChange: (label: string) => void;
    onFocus?: () => void;
    placeholder?: string;
    readOnly: boolean;
    text: string;
};

export function TextInput({ disabled, formSx, info, onBlur, onChange, onFocus, placeholder, readOnly, text }: TextInputProps) {
    const theme = useTheme();
    return (
        <FormControl
            fullWidth={true}
            sx={formSx}
            variant="standard">
            <TextField
                disabled={disabled}
                label={placeholder}
                slotProps={{
                    htmlInput: { disabled: readOnly },

                    input: {
                        endAdornment:
                            info
                                ? <InputAdornment position="end">
                                    <Tooltip
                                        titleOrGetTitle={
                                            <Typography
                                                sx={{
                                                    fontWeight: "initial",
                                                    padding: theme.spacing(1),
                                                    whiteSpace: "pre-wrap"
                                                }}>
                                                {info}
                                            </Typography>}>
                                        <InfoIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "24px"
                                            }}/>
                                    </Tooltip>
                                </InputAdornment>
                                : undefined
                    }
                }}
                sx={{
                    ".MuiOutlinedInput-root":
                        {
                            background:
                                readOnly
                                    ? theme.palette.input.disabledBackground
                                    : undefined,
                            color: theme.palette.text.primary
                        },
                    ".MuiOutlinedInput-root:hover":
                        {
                            ".MuiOutlinedInput-notchedOutline":
                                {
                                    border:
                                        readOnly
                                            ? theme.border.primary
                                            : undefined
                                }
                        }
                }}
                value={text}
                variant="outlined"
                onBlur={onBlur}
                onChange={event => onChange(event.target.value)}
                onFocus={onFocus}/>
        </FormControl>);
}