import { useMemo } from "react";
import { useGcpDefaultKubernetesNamespaceResourceDefinition, useGcpDefaultKubernetesResourceDefinition, useGcpKubernetesClusterRoleBindingDefinition, useGcpKubernetesClusterRoleDefinition, useGcpKubernetesCronJobDefinition, useGcpKubernetesDaemonSetDefinition, useGcpKubernetesDeploymentDefinition, useGcpKubernetesGroupDefinition, useGcpKubernetesIngressDefinition, useGcpKubernetesJobDefinition, useGcpKubernetesNamespaceDefinition, useGcpKubernetesNamespaceRoleBindingDefinition, useGcpKubernetesNamespaceRoleDefinition, useGcpKubernetesReplicaSetDefinition, useGcpKubernetesServiceAccountDefinition, useGcpKubernetesServiceDefinition, useGcpKubernetesStatefulSetDefinition, useGcpKubernetesUserDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract, TypeHelper } from "../../../../../../../../../../common";

export function useGcpKubernetesResourceDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.GcpKubernetesClusterRole:
                        return useGcpKubernetesClusterRoleDefinition;
                    case Contract.TypeNames.GcpKubernetesClusterRoleBinding:
                        return useGcpKubernetesClusterRoleBindingDefinition;
                    case Contract.TypeNames.GcpKubernetesCronJob:
                        return useGcpKubernetesCronJobDefinition;
                    case Contract.TypeNames.GcpKubernetesDaemonSet:
                        return useGcpKubernetesDaemonSetDefinition;
                    case Contract.TypeNames.GcpKubernetesDeployment:
                        return useGcpKubernetesDeploymentDefinition;
                    case Contract.TypeNames.GcpKubernetesGroup:
                        return useGcpKubernetesGroupDefinition;
                    case Contract.TypeNames.GcpKubernetesIngress:
                        return useGcpKubernetesIngressDefinition;
                    case Contract.TypeNames.GcpKubernetesJob:
                        return useGcpKubernetesJobDefinition;
                    case Contract.TypeNames.GcpKubernetesNamespace:
                        return useGcpKubernetesNamespaceDefinition;
                    case Contract.TypeNames.GcpKubernetesNamespaceRole:
                        return useGcpKubernetesNamespaceRoleDefinition;
                    case Contract.TypeNames.GcpKubernetesNamespaceRoleBinding:
                        return useGcpKubernetesNamespaceRoleBindingDefinition;
                    case Contract.TypeNames.GcpKubernetesReplicaSet:
                        return useGcpKubernetesReplicaSetDefinition;
                    case Contract.TypeNames.GcpKubernetesService:
                        return useGcpKubernetesServiceDefinition;
                    case Contract.TypeNames.GcpKubernetesServiceAccount:
                        return useGcpKubernetesServiceAccountDefinition;
                    case Contract.TypeNames.GcpKubernetesStatefulSet:
                        return useGcpKubernetesStatefulSetDefinition;
                    case Contract.TypeNames.GcpKubernetesUser:
                        return useGcpKubernetesUserDefinition;
                    default:
                        return TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.GcpKubernetesNamespaceResourceBase)
                            ? useGcpDefaultKubernetesNamespaceResourceDefinition
                            : useGcpDefaultKubernetesResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.GcpResourceModel, options);
}