﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonIamCredentialInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciIamUserSecretKeyDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const userSecretKeyModel = resourceModel as Contract.OciIamUserSecretKeyModel;
    const commonIamCredentialInfoItemElements = useOciCommonIamCredentialInfoItemElements(userSecretKeyModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const userSecretKey = userSecretKeyModel.entity as Contract.OciIamUserSecretKey;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciIamUserSecretKeyDefinition",
            () => ({
                info: {
                    items: {
                        accessKey: "Access Key"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={
                    [
                        commonIamCredentialInfoItemElements.typeNameInfoElement,
                        commonIamCredentialInfoItemElements.organizationInfoItemElement,
                        commonIamCredentialInfoItemElements.tenantInfoItemElement,
                        commonIamCredentialInfoItemElements.ocidInfoItemElement,
                        commonIamCredentialInfoItemElements.creationTimeInfoItemElement,
                        commonIamCredentialInfoItemElements.createdByInfoItemElement,
                        commonIamCredentialInfoItemElements.syncTimeInfoItemElement
                    ]
                }
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="accessKey"
                        location="all"
                        title={localization.info.items.accessKey()}
                        value={userSecretKey.accessKey}/>,
                    commonIamCredentialInfoItemElements.userIdInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}