import { FormLayout, Message, SubnetHelper, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { ConfigurationController, Contract, useTheme } from "../../../../../../../common";
import { useNetworkInternalSubnetsContext, useSetNetworkInternalSubnetsContext } from "../NetworkInternalSubnets";

export type AddOrEditProps = {
    onClose: () => void;
};

export function AddOrEdit({ onClose }: AddOrEditProps) {
    const { addOrEditOpen, internalSubnetDatas } = useNetworkInternalSubnetsContext();
    const setContext = useSetNetworkInternalSubnetsContext();

    const existingInternalSubnetData =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;
    const [name, setName] = useState(existingInternalSubnetData?.name ?? "");
    const [subnet, setSubnet] = useState(existingInternalSubnetData?.subnet ?? "");

    const [valid, setValid] = useState(false);
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);

    const localization =
        useLocalization(
            "views.customer.configuration.networkInternalSubnets.addOrEdit",
            () => ({
                actions: {
                    add: {
                        label: "Add",
                        title: "Add Internal IP Address Range"
                    },
                    edit: {
                        label: "Save",
                        title: "Edit Internal IP Address Range"
                    }
                },
                errors: {
                    unkownAddError: "Failed to add new Internal IP address range",
                    unkownEditError: "Failed to edit Internal IP address range"
                },
                fields: {
                    name: {
                        error: {
                            required: "Name cannot be empty"
                        },
                        label: "Name"
                    },
                    subnet: {
                        error: {
                            exists: "Internal IP Address Range already exists",
                            format: "Must be a valid IP Address (CIDR)",
                            required: "Internal IP Address Range cannot be empty"
                        },
                        label: "Internal IP Address Range",
                        title: "Enter Internal IP Address Range"
                    }
                },
                subtitle: "Internal IP address range that will not be considered as public across the system."
            }));

    function subnetValidation(subnet: string) {
        if (_.isEmpty(subnet)) {
            return localization.fields.subnet.error.required();
        } else if (!SubnetHelper.isValid(subnet)) {
            return localization.fields.subnet.error.format();
        } else if (
            _.some(
                internalSubnetDatas,
                internalSubnetData => internalSubnetData.subnet === subnet)) {
            return localization.fields.subnet.error.exists();
        }

        return undefined;
    }

    function nameValidation(name?: string) {
        if (_.isEmpty(name)) {
            return localization.fields.name.error.required();
        }

        return undefined;
    }

    const [subnetValidationController, subnetValidationMessage] =
        useInputValidation(
            () => {
                if (subnet != existingInternalSubnetData?.subnet) {
                    return subnetValidation(subnet);
                }
                return undefined;
            },
            [subnet]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                if (name != existingInternalSubnetData?.name) {
                    return nameValidation(name);
                }
                return undefined;
            },
            [name]);

    useChangeEffect(
        () => {
            setValid(
                subnetValidationController.isValid() &&
                nameValidationController.isValid() &&
                (subnet != existingInternalSubnetData?.subnet ||
                    name != existingInternalSubnetData?.name));
        },
        [subnet, name]);

    async function addNetworkInternalSubnet() {
        setExecuting(true);
        setError(false);

        try {
            const subnetData =
                await ConfigurationController.upsertAnalysisNetwork(
                    new Contract.ConfigurationControllerUpsertAnalysisNetworkRequest(
                        existingInternalSubnetData?.subnet,
                        subnet!,
                        name!));

            setContext(
                context => ({
                    ...context,
                    internalSubnetDatas:
                        existingInternalSubnetData?.subnet
                            ? _.map(
                                context.internalSubnetDatas,
                                internalSubnetData =>
                                    internalSubnetData.subnet !== existingInternalSubnetData?.subnet
                                        ? internalSubnetData
                                        : {
                                            name: subnetData.subnetName,
                                            subnet: subnetData.subnet
                                        })
                            : [
                                ...context.internalSubnetDatas,
                                {
                                    name: subnetData.subnetName,
                                    subnet: subnetData.subnet
                                }
                            ]
                }));

            onClose();
        } catch (error) {
            setError(true);
        }

        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        <Fragment>
                            {executing && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={!valid || executing}
                                onClick={() => addNetworkInternalSubnet()}>
                                {_.isNil(existingInternalSubnetData)
                                    ? localization.actions.add.label()
                                    : localization.actions.edit.label()}
                            </Button>
                        </Fragment>
                    </Stack>
            }}
            titleOptions={{
                subtitle: localization.subtitle(),
                text:
                    _.isNil(existingInternalSubnetData)
                        ? localization.actions.add.title()
                        : localization.actions.edit.title()
            }}>
            <Stack spacing={2}>
                <TextField
                    error={!_.isNil(nameValidationMessage)}
                    fullWidth={true}
                    label={localization.fields.name.label()}
                    slotProps={{
                        input: {
                            ...(!_.isNil(nameValidationMessage)
                                ? {
                                    endAdornment: (
                                        <Tooltip title={nameValidationMessage}>
                                            <InputAdornment position="end">
                                                <ErrorIcon
                                                    sx={{
                                                        color: theme.palette.error.main,
                                                        fontSize: "18px"
                                                    }}/>
                                            </InputAdornment>
                                        </Tooltip>
                                    )
                                }
                                : {})
                        }
                    }}
                    type="text"
                    value={name}
                    variant="outlined"
                    onChange={event => setName(event.target.value)}/>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        error={!_.isNil(subnetValidationMessage)}
                        label={localization.fields.subnet.label()}
                        slotProps={{
                            input: {
                                ...(!_.isNil(subnetValidationMessage)
                                    ? {
                                        endAdornment: (
                                            <Tooltip title={subnetValidationMessage}>
                                                <InputAdornment position="end">
                                                    <ErrorIcon
                                                        sx={{
                                                            color: theme.palette.error.main,
                                                            fontSize: "18px"
                                                        }}/>
                                                </InputAdornment>
                                            </Tooltip>
                                        )
                                    }
                                    : {})
                            }
                        }}
                        value={subnet}
                        variant="outlined"
                        onChange={event => setSubnet(event.target.value)}/>
                </FormControl>
                {error && (
                    <Message
                        level="error"
                        title={
                            _.isNil(existingInternalSubnetData)
                                ? localization.errors.unkownAddError()
                                : localization.errors.unkownEditError()}/>)}
            </Stack>
        </FormLayout>);
}