﻿import { Action0, Link as CustomLink, LinkProps as CustomLinkProps, useSetRoute } from "@infrastructure";
import { SxProps, Theme, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ForwardedRef, forwardRef, MouseEvent, ReactNode, useCallback } from "react";
import { EntityLinkOptions } from "../Entity";

export type LinkProps = Pick<CustomLinkProps, "urlOrGetUrl"> & {
    children: ReactNode;
    className?: string;
    linkOptions?: EntityLinkOptions;
    onClick?: Action0;
    onMouseEnter?: Action0;
    onMouseLeave?: Action0;
    sx?: SxProps<Theme>;
    variant?: "internal" | "text";
};

const LinkForwardRef = forwardRef<HTMLAnchorElement, LinkProps>(Link);
export { LinkForwardRef as Link };

function Link({ children, className, linkOptions, onClick, onMouseEnter, onMouseLeave, sx, urlOrGetUrl, variant }: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) {
    const setRoute = useSetRoute();
    const disabled = linkOptions?.disabled || _.isNil(urlOrGetUrl);
    const getUrl =
        useCallback(
            () =>
                _.isFunction(urlOrGetUrl)
                    ? urlOrGetUrl()
                    : urlOrGetUrl,
            [urlOrGetUrl]);

    const theme = useTheme();
    return (
        <CustomLink
            className={className}
            customOnClick={
                (event: MouseEvent) => {
                    if (disabled || !_.isEmpty(window.document.getElementsByClassName("MuiDialog-root"))) {
                        event.preventDefault();
                        return;
                    }

                    onClick?.();

                    if (getUrl()) {
                        setRoute(getUrl()!);
                    }

                    event.preventDefault();
                    event.stopPropagation();
                }}
            linkRef={ref}
            sx={{
                cursor:
                    disabled
                        ? "default"
                        : undefined,
                textAlign: "start",
                wordBreak: "break-word",
                ...(disabled && {
                    "&:hover": {
                        color: theme.palette.link.primary
                    }
                }),
                ...sx
            }}
            urlOrGetUrl={urlOrGetUrl}
            variant={variant}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {children ?? getUrl()}
        </CustomLink>);
}