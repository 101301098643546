import { Message, Optional } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useDataAnalysisErrorTranslator, useDataAnalysisStatusTranslator, useTheme } from "../../../../../common";

export type DataAnalysisResourceScanStatusCellProps = {
    dataAnalysisError: Optional<Contract.DataAnalysisResourceModelDataAnalysisError>;
    dataAnalysisStatus: Contract.DataAnalysisResourceModelDataAnalysisStatus;
};

export function DataAnalysisResourceScanStatusCell({ dataAnalysisError, dataAnalysisStatus }: DataAnalysisResourceScanStatusCellProps) {
    const dataAnalysisErrorTranslator = useDataAnalysisErrorTranslator();
    const dataAnalysisStatusTranslator = useDataAnalysisStatusTranslator();

    const showDataAnalysisErrorMessage =
        !_.isNil(dataAnalysisError) &&
        !_.includes(
            [
                Contract.DataAnalysisResourceModelDataAnalysisError.Empty,
                Contract.DataAnalysisResourceModelDataAnalysisError.NotSupported
            ],
            dataAnalysisError);
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{ paddingRight: theme.px(1) }}>
            <Typography noWrap={true}>
                {dataAnalysisStatusTranslator(dataAnalysisStatus)}
            </Typography>
            {showDataAnalysisErrorMessage &&
                <Message
                    level="info"
                    title={
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {dataAnalysisErrorTranslator(dataAnalysisError)}
                        </Typography>}
                    variant="minimal"/>}
        </Stack>);
}