﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpFunctionsFunctionRiskContext() {
    return useMemo(
        () => useGcpFunctionsFunctionRiskContext,
        []);
}

function useGcpFunctionsFunctionRiskContext(functionsModel: Contract.GcpFunctionsFunctionModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(functionsModel);
    const functionEntity = functionsModel.entity as Contract.GcpCloudRunService;

    return {
        ...entityRiskContext,
        networkInboundAccessType: entityRiskContext.getNetworkInboundAccessType(functionEntity.networkInboundAccessType!)
    };
}