import { DataTableColumn, optionalTableCell, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, InlineEntities, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsCommonEncryptedResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonEncryptedResourceDefinition",
            () => ({
                columns: {
                    encryptionExists: {
                        false: "Disabled",
                        title: "Encryption",
                        true: "Enabled"
                    },
                    kmsEncryptionKeyReferences: "Encryption Key"
                }
            }));

    function getEncryptionExistsTranslation(encryptedResourceModel: Contract.AwsResourceModel) {
        return (_.as<Contract.IAwsEncryptedResource>(encryptedResourceModel.entity)).encryptionExists
            ? localization.columns.encryptionExists.true()
            : localization.columns.encryptionExists.false();
    }

    return {
        columns: {
            encryptionExistsColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsResourceModel) => ({
                                [localization.columns.encryptionExists.title()]: getEncryptionExistsTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.encryptionExists.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.encryptionExists.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.encryptionExists.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists}
                    itemProperty={getEncryptionExistsTranslation}
                    key={Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists}
                    title={localization.columns.encryptionExists.title()}/>,
            kmsEncryptionKeyColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsResourceModel>(
                            Contract.TypeNames.AwsKmsKey,
                            item =>_.as<Contract.IAwsEncryptedResourceModel>(item).kmsEncryptionKeyIdReferences,
                            localization.columns.kmsEncryptionKeyReferences())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys)}
                                    placeholder={localization.columns.kmsEncryptionKeyReferences()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys}
                    key={Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys}
                    render={
                        optionalTableCell<Contract.IAwsEncryptedResourceModel>(
                            item =>
                                _.isEmpty(item.kmsEncryptionKeyIdReferences)
                                    ? undefined
                                    : <InlineEntities
                                        entityIdsOrModels={item.kmsEncryptionKeyIdReferences}
                                        entityTypeName={Contract.TypeNames.AwsKmsKey}
                                        entityVariant="iconText"
                                        variant="itemPlusItemCount"/>)}
                    title={localization.columns.kmsEncryptionKeyReferences()}/>
        }
    };
}