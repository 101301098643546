import { Loading, Message, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Access, Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, EntityPropertyHelper, RiskTypeMetadataModelHelper, useRiskPolicyTranslator, useTheme } from "../../../../../../../../../../../../common";
import { RiskView } from "../../../../../../../../utilities";
import { AccessRiskDefinitionOptions, RiskDefinitionSection, RiskDefinitionSectionCategory } from "../../../../../../utilities";
import { Resolve } from "../../../../components";
import { useResourceOwnerRiskDefinitionSection } from "../../../useResourceOwnerRiskDefinitionSection";

type GcpCommonAccessPrincipalRiskDefinitionOptions =
    AccessRiskDefinitionOptions & {
        accessGraphActionsElement?: ReactNode;
        sections?: RiskDefinitionSection[];
    };

export function useGcpCommonAccessPrincipalRiskDefinition(
    contextSectionContentElement: ReactNode,
    description: string,
    riskModel: Contract.RiskModel,
    accessScope?: Contract.EntityAccessScope,
    options?: GcpCommonAccessPrincipalRiskDefinitionOptions) {
    const accessPrincipalRisk = riskModel.risk as Contract.GcpAccessPrincipalRisk;
    const resourceOwnerRiskDefinitionSection = useResourceOwnerRiskDefinitionSection(riskModel);
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const principalModel = entityModelStore.useGet(accessPrincipalRisk.entityId);
    const sections = _.filter(options?.sections ?? []);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpCommonAccessPrincipalRiskDefinition",
            () => ({
                sections: {
                    accessGraph: {
                        helpText: "This view displays {{principal}}’s permissions on project {{tenantEntity}} only. {{principal}} may also have other permissions on other projects.",
                        title: "Permissions and usage of {{principal}} on {{tenantEntity}}"
                    },
                    context: "Context",
                    policyDescription: "Policy",
                    resolution: {
                        riskNotResolvedMessage: "Steps that are not marked as successful must be performed manually either because they are not yet supported or because Tenable Cloud Security does not have sufficient permissions to perform them.",
                        steps: "Remediation steps"
                    }
                }
            }));

    const theme = useTheme();
    return useMemo(
        () => ({
            description,
            sections:
                _.filter([
                    new RiskDefinitionSection(
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {riskPolicyTranslator(RiskTypeMetadataModelHelper.get(accessPrincipalRisk.typeName).policyConfigurationTypeName).description}
                        </Typography>,
                        localization.sections.policyDescription(),
                        {
                            resolvedRiskVisible: true
                        }),
                    new RiskDefinitionSection(
                        contextSectionContentElement,
                        localization.sections.context()),
                    EntityPropertyHelper.showRiskResourceOwner(riskModel)
                        ? resourceOwnerRiskDefinitionSection
                        : undefined!,
                    !principalModel.entity.systemDeleted &&
                        !_.isNil(accessScope)
                        ? new RiskDefinitionSection(
                            <Box
                                sx={{
                                    minHeight: theme.spacing(20),
                                    width: "100%"
                                }}>
                                <Loading>
                                    <Access
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(accessPrincipalRisk.id, { category: RiskDefinitionSectionCategory.Overview })}
                                        destinationResourceTenantId={accessPrincipalRisk.tenantId}
                                        entityId={principalModel.entity.id}
                                        scope={accessScope}
                                        tenantType={Contract.TenantType.Gcp}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            localization.sections.accessGraph.title({
                                principal:
                                    <Entity
                                        entityIdOrModel={principalModel}
                                        variant="typeText"/>,
                                tenantEntity:
                                    <Entity
                                        entityIdOrModel={accessPrincipalRisk.tenantId}
                                        variant="typeText"/>
                            }),
                            {
                                actionsElement:
                                    options?.accessGraphActionsElement ??
                                    <Message
                                        level="info"
                                        title={
                                            localization.sections.accessGraph.helpText({
                                                principal:
                                                    <Entity
                                                        entityIdOrModel={principalModel}
                                                        variant="text"/>,
                                                tenantEntity:
                                                    <Entity
                                                        entityIdOrModel={accessPrincipalRisk.tenantId}
                                                        variant="typeText"/>
                                            })}
                                        variant="minimal"/>
                            })
                        : undefined!,
                    options?.resolutionSectionDefinitionComponent == undefined
                        ? undefined!
                        : new RiskDefinitionSection(
                            <options.resolutionSectionDefinitionComponent riskModel={riskModel}/>,
                            localization.sections.resolution.steps(),
                            {
                                actionsElement:
                                    <Box
                                        sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            width: "100%"
                                        }}>
                                        <Resolve riskNotResolvedMessage={localization.sections.resolution.riskNotResolvedMessage()}/>
                                    </Box>,
                                categoryView: {
                                    category: RiskDefinitionSectionCategory.Resolution,
                                    view:
                                        _.some(
                                            sections,
                                            section =>
                                                section.options?.categoryView?.view === RiskView.ResolutionGcpCustomRole ||
                                                section.options?.categoryView?.view === RiskView.ResolutionGcpCustomRoles)
                                            ? (accessPrincipalRisk.resolutionChanges?.length ?? 0) > 1
                                                ? RiskView.ResolutionRoles
                                                : RiskView.ResolutionRole
                                            : RiskView.ResolutionConsole
                                }
                            }),
                    ...sections
                ])
        }),
        [accessScope, contextSectionContentElement, description, options, riskModel]);
}