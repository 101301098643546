import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function UncheckedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <rect
                fill="none"
                height="15"
                rx="1.5"
                stroke="#9E9E9E"
                width="15"
                x="4.5"
                y="4.5"/>
        </SvgIcon>);
}