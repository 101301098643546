import { useLocalization } from "@infrastructure";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesResourceDefinition } from "../useCommonKubernetesResourceDefinition";
import { useGcpCommonResourceDefinition } from "./useGcpCommonResourceDefinition";

export function useGcpCommonKubernetesResourceDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useGcpCommonResourceDefinition(definitionData);
    const commonKubernetesResourceDefinition = useCommonKubernetesResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCommonKubernetesResourceDefinition",
            () => ({
                columns: {
                    tags: "Kubernetes Labels"
                }
            }));
    return {
        columns: {
            ...commonResourceDefinition.columns,
            ...commonKubernetesResourceDefinition.columns,
            tagsColumn:
                commonResourceDefinition.createTagsColumn(localization.columns.tags())
        }
    };
}