import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GcpIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M15.6309 7.88268L17.5432 5.9703L17.6703 5.1654C14.1859 1.99621 8.64662 2.35599 5.50458 5.91502C4.6318 6.90321 3.98496 8.13579 3.63973 9.40909L4.32438 9.31211L8.14913 8.68176L8.44394 8.38017C10.1449 6.51143 13.0222 6.26026 14.986 7.8497L15.6309 7.88268Z"
                fill="#EA4335"/>
            <path
                d="M20.268 9.35143C19.8286 7.73289 18.9258 6.27728 17.6709 5.16399L14.9866 7.8483C16.1202 8.77442 16.7651 10.1689 16.738 11.6323V12.1085C18.0569 12.1085 19.1265 13.1781 19.1265 14.497C19.1265 15.8159 18.0569 16.8593 16.738 16.8593H11.9541L11.4847 17.3694V20.2351L11.9541 20.6851H16.738C20.169 20.7122 22.9727 17.9785 22.9998 14.5474C23.0163 12.4673 21.9903 10.5171 20.268 9.35143Z"
                fill="#4285F4"/>
            <path
                d="M7.17685 20.6852L11.9541 20.6851L11.9549 16.8604H7.17685C6.8384 16.8604 6.5048 16.7867 6.19739 16.6461L5.51952 16.8537L3.59357 18.766L3.4258 19.4168C4.50612 20.2323 5.82403 20.691 7.17685 20.6852Z"
                fill="#34A853"/>
            <path
                d="M7.17649 8.27832C3.74452 8.29868 0.979747 11.0974 1.00011 14.5294C1.01175 16.4457 1.90683 18.2494 3.42547 19.418L6.19739 16.6461C4.99489 16.1031 4.4602 14.6885 5.00326 13.486C5.54633 12.2834 6.9612 11.7491 8.1637 12.2922C8.69318 12.5317 9.11794 12.9555 9.35747 13.486L12.129 10.7144C10.9498 9.17244 9.11697 8.27153 7.17649 8.27832Z"
                fill="#FBBC05"/>
        </SvgIcon>);
}