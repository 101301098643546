﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../..";
import { Contract } from "../../../controllers";

type CodeTenantIconProps =
    SvgIconProps & {
        data?: CodeTenantIconData;
    };

export type CodeTenantIconData = {
    codeTenantType?: Contract.CodeTenantType;
    generalCodeTenant?: boolean;
};

export function CodeTenantIcon({ data, ...props }: CodeTenantIconProps) {
    const theme = useTheme();
    switch (data?.codeTenantType) {
        case Contract.CodeTenantType.AzureDevOps:
            return data?.generalCodeTenant
                ? <SvgIcon {...props}>
                    <path
                        d="M17.519 4.95269L3.7188 7.63665V13.2854H3.53341C3.19388 13.2282 2.85293 13.2018 2.51402 13.2018C2.00171 13.2018 1.49376 13.2649 1 13.3978V8.13164L3.05972 5.40735L10.7642 2.26964V0L17.519 4.95269Z"
                        fill="#0075D1"/>
                    <path
                        d="M22.9995 13.853V4.04438L17.5207 4.95279V13.2854H20.3597C21.2476 13.2854 22.1528 13.4459 22.9995 13.853Z"
                        fill="#0075D1"/>
                    <path
                        d="M17.519 4.95269L3.7188 7.63665V13.2854H3.53341C3.19388 13.2282 2.85293 13.2018 2.51402 13.2018C2.00171 13.2018 1.49376 13.2649 1 13.3978V8.13164L3.05972 5.40735L10.7642 2.26964V0L17.519 4.95269Z"
                        fill="#0066B6"/>
                    <path
                        d="M13 5.83168L17.519 4.95279L13 1.6394V5.83168Z"
                        fill="#0075D1"/>
                    <path
                        d="M9.63837 21.3477L11.6471 14.3477H10.5301L8.52139 21.3477H9.63837Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M17.7955 16.6688H16.4624C16.4381 16.507 16.3884 16.3633 16.3133 16.2377C16.2382 16.1102 16.1418 16.0017 16.0242 15.9123C15.9065 15.8228 15.7705 15.7543 15.6163 15.7067C15.4642 15.6591 15.2988 15.6354 15.1202 15.6354C14.7976 15.6354 14.5166 15.7105 14.2772 15.8609C14.0378 16.0093 13.8521 16.2263 13.7202 16.5118C13.5883 16.7954 13.5224 17.1398 13.5224 17.5452C13.5224 17.962 13.5883 18.3122 13.7202 18.5958C13.8541 18.8794 14.0408 19.0935 14.2802 19.2381C14.5196 19.3828 14.7966 19.4551 15.1111 19.4551C15.2876 19.4551 15.451 19.4332 15.6011 19.3894C15.7533 19.3457 15.8882 19.2819 16.0059 19.1982C16.1236 19.1125 16.221 19.0088 16.2981 18.887C16.3772 18.7652 16.432 18.6262 16.4624 18.4702L17.7955 18.4759C17.761 18.7442 17.6748 19.0031 17.5368 19.2524C17.4008 19.4998 17.2172 19.7215 16.9859 19.9176C16.7566 20.1117 16.4827 20.2659 16.1642 20.3801C15.8476 20.4923 15.4895 20.5485 15.0898 20.5485C14.5338 20.5485 14.0367 20.4305 13.5985 20.1945C13.1622 19.9585 12.8173 19.6169 12.5637 19.1696C12.3121 18.7224 12.1863 18.1809 12.1863 17.5452C12.1863 16.9076 12.3141 16.3652 12.5698 15.918C12.8254 15.4707 13.1724 15.1301 13.6106 14.896C14.0489 14.66 14.542 14.542 15.0898 14.542C15.451 14.542 15.7857 14.5895 16.0942 14.6847C16.4046 14.7799 16.6795 14.9188 16.919 15.1015C17.1584 15.2823 17.3532 15.504 17.5033 15.7667C17.6555 16.0293 17.7529 16.33 17.7955 16.6688Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M20.9504 20.4691H18.7408V14.6224H20.9687C21.5956 14.6224 22.1353 14.7395 22.5878 14.9736C23.0403 15.2058 23.3883 15.5398 23.6317 15.9756C23.8772 16.4114 24 16.9329 24 17.5401C24 18.1491 23.8772 18.6725 23.6317 19.1102C23.3883 19.5479 23.0382 19.8839 22.5817 20.1179C22.1272 20.352 21.5834 20.4691 20.9504 20.4691ZM20.0586 19.41H20.8956C21.2852 19.41 21.6129 19.3452 21.8787 19.2158C22.1465 19.0845 22.3474 18.8818 22.4813 18.6077C22.6172 18.3318 22.6852 17.9759 22.6852 17.5401C22.6852 17.108 22.6172 16.755 22.4813 16.4809C22.3474 16.2069 22.1475 16.0051 21.8817 15.8757C21.6159 15.7463 21.2882 15.6816 20.8986 15.6816H20.0586V19.41Z"
                        fill={theme.palette.text.primary}
                        fillRule="evenodd"/>
                    <path
                        d="M7.87236 14.6224V20.4691H6.55452V14.6224H7.87236Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M4.27615 16.6688H5.60922C5.56661 16.33 5.46921 16.0293 5.31704 15.7667C5.16689 15.504 4.97211 15.2823 4.73268 15.1015C4.49326 14.9188 4.21833 14.7799 3.90789 14.6847C3.59948 14.5895 3.26469 14.542 2.90352 14.542C2.35569 14.542 1.86264 14.66 1.42437 14.896C0.986102 15.1301 0.63914 15.4707 0.383484 15.918C0.127828 16.3652 0 16.9076 0 17.5452C0 18.1809 0.125799 18.7224 0.377397 19.1696C0.631024 19.6169 0.975957 19.9585 1.4122 20.1945C1.85046 20.4305 2.34757 20.5485 2.90352 20.5485C3.30324 20.5485 3.66136 20.4923 3.97789 20.3801C4.29644 20.2659 4.57036 20.1117 4.79964 19.9176C5.03095 19.7215 5.21457 19.4998 5.35052 19.2524C5.48849 19.0031 5.57472 18.7442 5.60922 18.4759L4.27615 18.4702C4.24572 18.6262 4.19093 18.7652 4.1118 18.887C4.0347 19.0088 3.93731 19.1125 3.81962 19.1982C3.70194 19.2819 3.56701 19.3457 3.41484 19.3894C3.26469 19.4332 3.10135 19.4551 2.92483 19.4551C2.61033 19.4551 2.33337 19.3828 2.09395 19.2381C1.85452 19.0935 1.66785 18.8794 1.53394 18.5958C1.40205 18.3122 1.33611 17.962 1.33611 17.5452C1.33611 17.1398 1.40205 16.7954 1.53394 16.5118C1.66582 16.2263 1.85148 16.0093 2.0909 15.8609C2.33033 15.7105 2.61134 15.6354 2.93396 15.6354C3.11251 15.6354 3.27788 15.6591 3.43005 15.7067C3.58426 15.7543 3.7202 15.8228 3.83789 15.9123C3.95557 16.0017 4.05195 16.1102 4.12702 16.2377C4.20209 16.3633 4.2518 16.507 4.27615 16.6688Z"
                        fill={theme.palette.text.primary}/>
                </SvgIcon>
                : <SvgIcon {...props}>
                    <g clipPath="url(#clip0_53355_197670)">
                        <path
                            d="M2.7188 7.63665L16.519 4.95269L9.76421 0V2.26964L2.05972 5.40735L0 8.13164V14.4043L2.7188 15.189V7.63665Z"
                            fill="#0075D1"/>
                        <path
                            d="M8.19851 19.194L16.7262 21.9999L21.9997 17.5009V4.04443L16.5208 4.95284V16.8409L2.71875 15.1891L8.19851 21.9999V19.194Z"
                            fill="#0075D1"/>
                        <path
                            d="M9.76421 0V2.26964L2.05972 5.40735L0 8.13164V14.4043L2.7188 15.189V7.63665L10.9999 6.02609V0.905655L9.76421 0Z"
                            fill="#0066B6"/>
                        <path
                            d="M2.71875 15.1892L8.19851 22V19.1941L10.9998 20.1153V16.1801L2.71875 15.1892Z"
                            fill="#0066B6"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_53355_197670">
                            <rect
                                fill="white"
                                height="24"
                                transform="translate(0.000488281)"
                                width="24"/>
                        </clipPath>
                    </defs>
                </SvgIcon>;
        case Contract.CodeTenantType.Bitbucket:
            return data?.generalCodeTenant
                ? <SvgIcon {...props}>
                    <path
                        d="M23.1624 2.49315C23.1881 2.58741 23.1928 2.68615 23.1762 2.78241L21.4642 13.3732C21.0966 13.3123 20.7267 13.2848 20.3597 13.2848C19.2887 13.2986 18.219 13.2717 17.1506 13.2449C16.3569 13.2249 15.5639 13.205 14.7717 13.2017L15.5399 8.72021H8.53228L9.39977 13.2664L7.9423 13.2848C7.9423 13.2848 4.56681 13.0702 3.53341 13.2848C3.30016 13.3333 3.03096 13.3919 2.75002 13.4535L1.00975 2.78241C0.993031 2.68559 0.997862 2.58626 1.02389 2.49151C1.04993 2.39676 1.09652 2.30892 1.16037 2.23422C1.22421 2.15953 1.30373 2.09982 1.39327 2.05935C1.48281 2.01888 1.58017 1.99864 1.67842 2.00007H22.5142C22.6119 1.99963 22.7085 2.02059 22.7972 2.06149C22.8859 2.10239 22.9646 2.16224 23.0277 2.23681C23.0908 2.31139 23.1368 2.39888 23.1624 2.49315Z"
                        fill="#2684FF"/>
                    <path
                        d="M9.63837 21.3477L11.6471 14.3477H10.5301L8.52139 21.3477H9.63837Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M17.7955 16.6688H16.4624C16.4381 16.507 16.3884 16.3633 16.3133 16.2377C16.2382 16.1102 16.1418 16.0017 16.0242 15.9123C15.9065 15.8228 15.7705 15.7543 15.6163 15.7067C15.4642 15.6591 15.2988 15.6354 15.1202 15.6354C14.7976 15.6354 14.5166 15.7105 14.2772 15.8609C14.0378 16.0093 13.8521 16.2263 13.7202 16.5118C13.5883 16.7954 13.5224 17.1398 13.5224 17.5452C13.5224 17.962 13.5883 18.3122 13.7202 18.5958C13.8541 18.8794 14.0408 19.0935 14.2802 19.2381C14.5196 19.3828 14.7966 19.4551 15.1111 19.4551C15.2876 19.4551 15.451 19.4332 15.6011 19.3894C15.7533 19.3457 15.8882 19.2819 16.0059 19.1982C16.1236 19.1125 16.221 19.0088 16.2981 18.887C16.3772 18.7652 16.432 18.6262 16.4624 18.4702L17.7955 18.4759C17.761 18.7442 17.6748 19.0031 17.5368 19.2524C17.4008 19.4998 17.2172 19.7215 16.9859 19.9176C16.7566 20.1117 16.4827 20.2659 16.1642 20.3801C15.8476 20.4923 15.4895 20.5485 15.0898 20.5485C14.5338 20.5485 14.0367 20.4305 13.5985 20.1945C13.1622 19.9585 12.8173 19.6169 12.5637 19.1696C12.3121 18.7224 12.1863 18.1809 12.1863 17.5452C12.1863 16.9076 12.3141 16.3652 12.5698 15.918C12.8254 15.4707 13.1724 15.1301 13.6106 14.896C14.0489 14.66 14.542 14.542 15.0898 14.542C15.451 14.542 15.7857 14.5895 16.0942 14.6847C16.4046 14.7799 16.6795 14.9188 16.919 15.1015C17.1584 15.2823 17.3532 15.504 17.5033 15.7667C17.6555 16.0293 17.7529 16.33 17.7955 16.6688Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        clipRule="evenodd"
                        d="M20.9504 20.4691H18.7408V14.6224H20.9687C21.5956 14.6224 22.1353 14.7395 22.5878 14.9736C23.0403 15.2058 23.3883 15.5398 23.6317 15.9756C23.8772 16.4114 24 16.9329 24 17.5401C24 18.1491 23.8772 18.6725 23.6317 19.1102C23.3883 19.5479 23.0382 19.8839 22.5817 20.1179C22.1272 20.352 21.5834 20.4691 20.9504 20.4691ZM20.0586 19.41H20.8956C21.2852 19.41 21.6129 19.3452 21.8787 19.2158C22.1465 19.0845 22.3474 18.8818 22.4813 18.6077C22.6172 18.3318 22.6852 17.9759 22.6852 17.5401C22.6852 17.108 22.6172 16.755 22.4813 16.4809C22.3474 16.2069 22.1475 16.0051 21.8817 15.8757C21.6159 15.7463 21.2882 15.6816 20.8986 15.6816H20.0586V19.41Z"
                        fill={theme.palette.text.primary}
                        fillRule="evenodd"/>
                    <path
                        d="M7.87236 14.6224V20.4691H6.55452V14.6224H7.87236Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M4.27615 16.6688H5.60922C5.56661 16.33 5.46921 16.0293 5.31704 15.7667C5.16689 15.504 4.97211 15.2823 4.73268 15.1015C4.49326 14.9188 4.21833 14.7799 3.90789 14.6847C3.59948 14.5895 3.26469 14.542 2.90352 14.542C2.35569 14.542 1.86264 14.66 1.42437 14.896C0.986102 15.1301 0.63914 15.4707 0.383484 15.918C0.127828 16.3652 0 16.9076 0 17.5452C0 18.1809 0.125799 18.7224 0.377397 19.1696C0.631024 19.6169 0.975957 19.9585 1.4122 20.1945C1.85046 20.4305 2.34757 20.5485 2.90352 20.5485C3.30324 20.5485 3.66136 20.4923 3.97789 20.3801C4.29644 20.2659 4.57036 20.1117 4.79964 19.9176C5.03095 19.7215 5.21457 19.4998 5.35052 19.2524C5.48849 19.0031 5.57472 18.7442 5.60922 18.4759L4.27615 18.4702C4.24572 18.6262 4.19093 18.7652 4.1118 18.887C4.0347 19.0088 3.93731 19.1125 3.81962 19.1982C3.70194 19.2819 3.56701 19.3457 3.41484 19.3894C3.26469 19.4332 3.10135 19.4551 2.92483 19.4551C2.61033 19.4551 2.33337 19.3828 2.09395 19.2381C1.85452 19.0935 1.66785 18.8794 1.53394 18.5958C1.40205 18.3122 1.33611 17.962 1.33611 17.5452C1.33611 17.1398 1.40205 16.7954 1.53394 16.5118C1.66582 16.2263 1.85148 16.0093 2.0909 15.8609C2.33033 15.7105 2.61134 15.6354 2.93396 15.6354C3.11251 15.6354 3.27788 15.6591 3.43005 15.7067C3.58426 15.7543 3.7202 15.8228 3.83789 15.9123C3.95557 16.0017 4.05195 16.1102 4.12702 16.2377C4.20209 16.3633 4.2518 16.507 4.27615 16.6688Z"
                        fill={theme.palette.text.primary}/>
                </SvgIcon>
                : <SvgIcon {...props}>
                    <path
                        d="M23.1762 2.78242C23.1928 2.68615 23.1881 2.58741 23.1624 2.49315C23.1368 2.39888 23.0908 2.31139 23.0277 2.23681C22.9646 2.16224 22.8859 2.10239 22.7972 2.06149C22.7085 2.02059 22.6119 1.99963 22.5142 2.00007H1.67842C1.58017 1.99864 1.48281 2.01888 1.39327 2.05935C1.30373 2.09982 1.22421 2.15953 1.16037 2.23422C1.09652 2.30892 1.04993 2.39676 1.02389 2.49151C0.997862 2.58626 0.993031 2.68559 1.00975 2.78242L4.05219 21.4383C4.07765 21.5954 4.1583 21.7382 4.27964 21.8412C4.40097 21.9442 4.55505 22.0005 4.71418 22H19.4918C19.8261 22 20.107 21.766 20.1605 21.4383L23.1762 2.78242ZM14.4166 15.2732H9.7827L8.53228 8.72021H15.5399L14.4166 15.2732Z"
                        fill="#2684FF"/>
                </SvgIcon>;
        case Contract.CodeTenantType.GitHub:
            return data?.generalCodeTenant
                ? <SvgIcon {...props}>
                    <path
                        d="M12 1C18.0752 1 23 6.0589 23 12.2995C23 12.8109 22.9669 13.3143 22.9029 13.8077C22.0841 13.434 21.2139 13.2854 20.3597 13.2854H19.1179C19.2862 12.6408 19.3773 11.8798 19.3954 10.9804C19.3919 8.98041 18.6306 8.02946 18.2255 7.52342C18.1316 7.40609 18.0568 7.31268 18.015 7.23566C18.3417 5.37045 17.9602 4.52087 17.7831 4.22977C17.1288 3.99205 15.5074 4.84113 14.6217 5.43964C13.1773 5.00768 10.1246 5.05019 8.97997 5.55133C6.86782 4.00589 5.75016 4.24262 5.75016 4.24262C5.75016 4.24262 5.02607 5.56517 5.55771 7.50007C5.54451 7.51725 5.53137 7.53434 5.5183 7.55134C4.84301 8.4297 4.34479 9.07774 4.34479 10.7427C4.34479 11.6918 4.45332 12.5421 4.69764 13.2854H3.53341C3.19388 13.2282 2.85293 13.2018 2.51402 13.2018C2.019 13.2018 1.52806 13.2607 1.05006 13.3846C1.01694 13.0275 1 12.6655 1 12.2995C1 6.0589 5.92481 1 12 1Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M9.63837 21.3477L11.6471 14.3477H10.5301L8.52139 21.3477H9.63837Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M17.7955 16.6688H16.4624C16.4381 16.507 16.3884 16.3633 16.3133 16.2377C16.2382 16.1102 16.1418 16.0017 16.0242 15.9123C15.9065 15.8228 15.7705 15.7543 15.6163 15.7067C15.4642 15.6591 15.2988 15.6354 15.1202 15.6354C14.7976 15.6354 14.5166 15.7105 14.2772 15.8609C14.0378 16.0093 13.8521 16.2263 13.7202 16.5118C13.5883 16.7954 13.5224 17.1398 13.5224 17.5452C13.5224 17.962 13.5883 18.3122 13.7202 18.5958C13.8541 18.8794 14.0408 19.0935 14.2802 19.2381C14.5196 19.3828 14.7966 19.4551 15.1111 19.4551C15.2876 19.4551 15.451 19.4332 15.6011 19.3894C15.7533 19.3457 15.8882 19.2819 16.0059 19.1982C16.1236 19.1125 16.221 19.0088 16.2981 18.887C16.3772 18.7652 16.432 18.6262 16.4624 18.4702L17.7955 18.4759C17.761 18.7442 17.6748 19.0031 17.5368 19.2524C17.4008 19.4998 17.2172 19.7215 16.9859 19.9176C16.7566 20.1117 16.4827 20.2659 16.1642 20.3801C15.8476 20.4923 15.4895 20.5485 15.0898 20.5485C14.5338 20.5485 14.0367 20.4305 13.5985 20.1945C13.1622 19.9585 12.8173 19.6169 12.5637 19.1696C12.3121 18.7224 12.1863 18.1809 12.1863 17.5452C12.1863 16.9076 12.3141 16.3652 12.5698 15.918C12.8254 15.4707 13.1724 15.1301 13.6106 14.896C14.0489 14.66 14.542 14.542 15.0898 14.542C15.451 14.542 15.7857 14.5895 16.0942 14.6847C16.4046 14.7799 16.6795 14.9188 16.919 15.1015C17.1584 15.2823 17.3532 15.504 17.5033 15.7667C17.6555 16.0293 17.7529 16.33 17.7955 16.6688Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        clipRule="evenodd"
                        d="M20.9504 20.4691H18.7408V14.6224H20.9687C21.5956 14.6224 22.1353 14.7395 22.5878 14.9736C23.0403 15.2058 23.3883 15.5398 23.6317 15.9756C23.8772 16.4114 24 16.9329 24 17.5401C24 18.1491 23.8772 18.6725 23.6317 19.1102C23.3883 19.5479 23.0382 19.8839 22.5817 20.1179C22.1272 20.352 21.5834 20.4691 20.9504 20.4691ZM20.0586 19.41H20.8956C21.2852 19.41 21.6129 19.3452 21.8787 19.2158C22.1465 19.0845 22.3474 18.8818 22.4813 18.6077C22.6172 18.3318 22.6852 17.9759 22.6852 17.5401C22.6852 17.108 22.6172 16.755 22.4813 16.4809C22.3474 16.2069 22.1475 16.0051 21.8817 15.8757C21.6159 15.7463 21.2882 15.6816 20.8986 15.6816H20.0586V19.41Z"
                        fill={theme.palette.text.primary}
                        fillRule="evenodd"/>
                    <path
                        d="M7.87236 14.6224V20.4691H6.55452V14.6224H7.87236Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M4.27615 16.6688H5.60922C5.56661 16.33 5.46921 16.0293 5.31704 15.7667C5.16689 15.504 4.97211 15.2823 4.73268 15.1015C4.49326 14.9188 4.21833 14.7799 3.90789 14.6847C3.59948 14.5895 3.26469 14.542 2.90352 14.542C2.35569 14.542 1.86264 14.66 1.42437 14.896C0.986102 15.1301 0.63914 15.4707 0.383484 15.918C0.127828 16.3652 0 16.9076 0 17.5452C0 18.1809 0.125799 18.7224 0.377397 19.1696C0.631024 19.6169 0.975957 19.9585 1.4122 20.1945C1.85046 20.4305 2.34757 20.5485 2.90352 20.5485C3.30324 20.5485 3.66136 20.4923 3.97789 20.3801C4.29644 20.2659 4.57036 20.1117 4.79964 19.9176C5.03095 19.7215 5.21457 19.4998 5.35052 19.2524C5.48849 19.0031 5.57472 18.7442 5.60922 18.4759L4.27615 18.4702C4.24572 18.6262 4.19093 18.7652 4.1118 18.887C4.0347 19.0088 3.93731 19.1125 3.81962 19.1982C3.70194 19.2819 3.56701 19.3457 3.41484 19.3894C3.26469 19.4332 3.10135 19.4551 2.92483 19.4551C2.61033 19.4551 2.33337 19.3828 2.09395 19.2381C1.85452 19.0935 1.66785 18.8794 1.53394 18.5958C1.40205 18.3122 1.33611 17.962 1.33611 17.5452C1.33611 17.1398 1.40205 16.7954 1.53394 16.5118C1.66582 16.2263 1.85148 16.0093 2.0909 15.8609C2.33033 15.7105 2.61134 15.6354 2.93396 15.6354C3.11251 15.6354 3.27788 15.6591 3.43005 15.7067C3.58426 15.7543 3.7202 15.8228 3.83789 15.9123C3.95557 16.0017 4.05195 16.1102 4.12702 16.2377C4.20209 16.3633 4.2518 16.507 4.27615 16.6688Z"
                        fill={theme.palette.text.primary}/>
                </SvgIcon>
                : <SvgIcon {...props}>
                    <path
                        d="M23 12.2995C23 6.0589 18.0752 1 12 1C5.92481 1 1 6.0589 1 12.2995C1 17.2531 4.10279 21.4615 8.42042 22.9872C8.68359 22.7919 8.98719 22.5512 8.98719 22.147C8.98719 21.5252 8.97371 19.9264 8.97371 19.9264C8.58982 19.9842 8.20218 20.0118 7.8142 20.0089C6.09706 20.0089 5.5529 18.89 5.2955 18.2633C4.95871 17.4474 4.5257 17.0643 4.05997 16.7579C3.77562 16.5701 3.71019 16.3482 4.03928 16.2849C5.55916 15.9924 5.94743 18.0454 6.9626 18.3731C7.68718 18.6063 8.61864 18.5055 9.08197 18.1991C9.14307 17.5724 9.58571 17.0297 9.95425 16.7441C7.37252 16.491 5.84206 15.5747 5.04676 14.1024L4.9616 13.9374L4.76241 13.4698L4.70323 13.3023C4.45497 12.555 4.34479 11.699 4.34479 10.7427C4.34479 9.04551 4.86249 8.405 5.55771 7.50007C5.02607 5.56517 5.75016 4.24262 5.75016 4.24262C5.75016 4.24262 6.86782 4.00589 8.97997 5.55133C10.1246 5.05019 13.1773 5.00768 14.6217 5.43964C15.5074 4.84113 17.1288 3.99205 17.7831 4.22977C17.9602 4.52087 18.3417 5.37045 18.015 7.23566C18.2368 7.64488 19.3911 8.51669 19.3954 10.9804C19.3771 11.8893 19.2843 12.6568 19.1125 13.3057L19.0163 13.6374C19.0163 13.6374 18.9619 13.794 18.9022 13.9433L18.832 14.1079C18.066 15.8263 16.4952 16.4678 13.9567 16.7347C14.7795 17.2635 15.0152 17.9273 15.0152 19.7233C15.0152 21.5193 14.9921 21.7605 14.9974 22.1737C15.0022 22.5389 15.2861 22.8013 15.5421 23C19.8804 21.4847 23 17.2665 23 12.2995Z"
                        fill={theme.palette.text.primary}/>
                </SvgIcon>;
        case Contract.CodeTenantType.GitLab:
            return data?.generalCodeTenant
                ? <SvgIcon {...props}>
                    <path
                        d="M22.3475 9.62569L22.3772 9.70819C22.8029 10.8775 22.8551 12.1604 22.5259 13.3636C22.5241 13.3701 22.5223 13.3767 22.5205 13.3832C22.1346 13.3156 21.7458 13.2854 21.3602 13.2854C20.3939 13.2854 19.4267 13.2644 18.4603 13.2435C17.4884 13.2225 16.5174 13.2015 15.5489 13.2018C13.6174 13.2024 8.94279 13.2854 8.94279 13.2854C8.03662 13.2854 7.12979 13.2644 6.22378 13.2435C5.31939 13.2227 4.41581 13.2018 3.51451 13.2018C2.75125 13.2018 1.99768 13.3418 1.28628 13.6442C1.25607 13.5517 1.22805 13.4583 1.20226 13.3639C0.873235 12.1606 0.925665 10.8775 1.35164 9.70819L1.38219 9.62661L4.34083 1.51628C4.40237 1.35801 4.50929 1.2238 4.64677 1.13226C4.78424 1.04073 4.94544 0.996422 5.10798 1.00549C5.27052 1.01456 5.42634 1.07656 5.55381 1.18287C5.68127 1.28919 5.77406 1.43455 5.81928 1.59877L7.81789 8.02434H15.911L17.9096 1.59877C17.9538 1.43369 18.0462 1.2873 18.1736 1.18013C18.3011 1.07296 18.4573 1.01038 18.6204 1.00118C18.7834 0.991984 18.9451 1.03662 19.0828 1.12883C19.2205 1.22105 19.3272 1.35621 19.388 1.51536L22.3475 9.62569Z"
                        fill="#E24329"/>
                    <path
                        d="M22.3772 9.70819L22.3475 9.62569C20.9052 9.93649 19.5457 10.5778 18.3674 11.505L16.2049 13.2226C16.52 13.2295 16.8353 13.2374 17.1507 13.2454C18.219 13.2722 19.2888 13.2991 20.3598 13.2854C21.0576 13.2854 21.7661 13.3846 22.4486 13.6246C22.4765 13.538 22.5026 13.4506 22.5267 13.3624C22.8554 12.1593 22.8032 10.8772 22.3772 9.70819Z"
                        fill="#FC6D26"/>
                    <path
                        d="M1.32614 13.7625C1.28023 13.6314 1.23917 13.4989 1.20226 13.3639C0.873235 12.1606 0.925665 10.8775 1.35164 9.70819L1.38089 9.62589C2.82326 9.93613 4.18236 10.5778 5.36066 11.505L7.57441 13.2643C6.67102 13.216 4.35756 13.1142 3.53347 13.2854C3.2311 13.3482 2.8683 13.428 2.49783 13.5095C2.09354 13.5985 1.68012 13.6894 1.32614 13.7625Z"
                        fill="#FC6D26"/>
                    <path
                        d="M9.63837 21.3477L11.6471 14.3477H10.5301L8.52139 21.3477H9.63837Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M17.7955 16.6688H16.4624C16.4381 16.507 16.3884 16.3633 16.3133 16.2377C16.2382 16.1102 16.1418 16.0017 16.0242 15.9123C15.9065 15.8228 15.7705 15.7543 15.6163 15.7067C15.4642 15.6591 15.2988 15.6354 15.1202 15.6354C14.7976 15.6354 14.5166 15.7105 14.2772 15.8609C14.0378 16.0093 13.8521 16.2263 13.7202 16.5118C13.5883 16.7954 13.5224 17.1398 13.5224 17.5452C13.5224 17.962 13.5883 18.3122 13.7202 18.5958C13.8541 18.8794 14.0408 19.0935 14.2802 19.2381C14.5196 19.3828 14.7966 19.4551 15.1111 19.4551C15.2876 19.4551 15.451 19.4332 15.6011 19.3894C15.7533 19.3457 15.8882 19.2819 16.0059 19.1982C16.1236 19.1125 16.221 19.0088 16.2981 18.887C16.3772 18.7652 16.432 18.6262 16.4624 18.4702L17.7955 18.4759C17.761 18.7442 17.6748 19.0031 17.5368 19.2524C17.4008 19.4998 17.2172 19.7215 16.9859 19.9176C16.7566 20.1117 16.4827 20.2659 16.1642 20.3801C15.8476 20.4923 15.4895 20.5485 15.0898 20.5485C14.5338 20.5485 14.0367 20.4305 13.5985 20.1945C13.1622 19.9585 12.8173 19.6169 12.5637 19.1696C12.3121 18.7224 12.1863 18.1809 12.1863 17.5452C12.1863 16.9076 12.3141 16.3652 12.5698 15.918C12.8254 15.4707 13.1724 15.1301 13.6106 14.896C14.0489 14.66 14.542 14.542 15.0898 14.542C15.451 14.542 15.7857 14.5895 16.0942 14.6847C16.4046 14.7799 16.6795 14.9188 16.919 15.1015C17.1584 15.2823 17.3532 15.504 17.5033 15.7667C17.6555 16.0293 17.7529 16.33 17.7955 16.6688Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        clipRule="evenodd"
                        d="M20.9504 20.4691H18.7408V14.6224H20.9687C21.5956 14.6224 22.1353 14.7395 22.5878 14.9736C23.0403 15.2058 23.3883 15.5398 23.6317 15.9756C23.8772 16.4114 24 16.9329 24 17.5401C24 18.1491 23.8772 18.6725 23.6317 19.1102C23.3883 19.5479 23.0382 19.8839 22.5817 20.1179C22.1272 20.352 21.5834 20.4691 20.9504 20.4691ZM20.0586 19.41H20.8956C21.2852 19.41 21.6129 19.3452 21.8787 19.2158C22.1465 19.0845 22.3474 18.8818 22.4813 18.6077C22.6172 18.3318 22.6852 17.9759 22.6852 17.5401C22.6852 17.108 22.6172 16.755 22.4813 16.4809C22.3474 16.2069 22.1475 16.0051 21.8817 15.8757C21.6159 15.7463 21.2882 15.6816 20.8986 15.6816H20.0586V19.41Z"
                        fill={theme.palette.text.primary}
                        fillRule="evenodd"/>
                    <path
                        d="M7.87236 14.6224V20.4691H6.55452V14.6224H7.87236Z"
                        fill={theme.palette.text.primary}/>
                    <path
                        d="M4.27615 16.6688H5.60922C5.56661 16.33 5.46921 16.0293 5.31704 15.7667C5.16689 15.504 4.97211 15.2823 4.73268 15.1015C4.49326 14.9188 4.21833 14.7799 3.90789 14.6847C3.59948 14.5895 3.26469 14.542 2.90352 14.542C2.35569 14.542 1.86264 14.66 1.42437 14.896C0.986102 15.1301 0.63914 15.4707 0.383484 15.918C0.127828 16.3652 0 16.9076 0 17.5452C0 18.1809 0.125799 18.7224 0.377397 19.1696C0.631024 19.6169 0.975957 19.9585 1.4122 20.1945C1.85046 20.4305 2.34757 20.5485 2.90352 20.5485C3.30324 20.5485 3.66136 20.4923 3.97789 20.3801C4.29644 20.2659 4.57036 20.1117 4.79964 19.9176C5.03095 19.7215 5.21457 19.4998 5.35052 19.2524C5.48849 19.0031 5.57472 18.7442 5.60922 18.4759L4.27615 18.4702C4.24572 18.6262 4.19093 18.7652 4.1118 18.887C4.0347 19.0088 3.93731 19.1125 3.81962 19.1982C3.70194 19.2819 3.56701 19.3457 3.41484 19.3894C3.26469 19.4332 3.10135 19.4551 2.92483 19.4551C2.61033 19.4551 2.33337 19.3828 2.09395 19.2381C1.85452 19.0935 1.66785 18.8794 1.53394 18.5958C1.40205 18.3122 1.33611 17.962 1.33611 17.5452C1.33611 17.1398 1.40205 16.7954 1.53394 16.5118C1.66582 16.2263 1.85148 16.0093 2.0909 15.8609C2.33033 15.7105 2.61134 15.6354 2.93396 15.6354C3.11251 15.6354 3.27788 15.6591 3.43005 15.7067C3.58426 15.7543 3.7202 15.8228 3.83789 15.9123C3.95557 16.0017 4.05195 16.1102 4.12702 16.2377C4.20209 16.3633 4.2518 16.507 4.27615 16.6688Z"
                        fill={theme.palette.text.primary}/>
                </SvgIcon>
                : <SvgIcon {...props}>
                    <path
                        d="M22.3772 9.70819L22.3475 9.62569L19.388 1.51536C19.3272 1.35621 19.2205 1.22105 19.0828 1.12883C18.9451 1.03662 18.7834 0.991984 18.6204 1.00118C18.4573 1.01038 18.3011 1.07296 18.1736 1.18013C18.0462 1.2873 17.9538 1.43369 17.9096 1.59877L15.911 8.02434H7.81789L5.81928 1.59877C5.77406 1.43455 5.68127 1.28919 5.55381 1.18287C5.42634 1.07656 5.27052 1.01456 5.10798 1.00549C4.94544 0.996422 4.78424 1.04073 4.64677 1.13226C4.50929 1.2238 4.40237 1.35801 4.34083 1.51628L1.38219 9.62661L1.35164 9.70819C0.925665 10.8775 0.873235 12.1606 1.20226 13.3639C1.53128 14.5673 2.22391 15.6256 3.1757 16.3794L3.18617 16.3886L3.21236 16.4087L7.71578 19.9533L9.95004 21.727L11.3063 22.805C11.4656 22.9315 11.6599 23 11.8596 23C12.0594 23 12.2537 22.9315 12.413 22.805L13.7701 21.727L16.0044 19.9533L20.5401 16.3877L20.5523 16.3785C21.504 15.6249 22.1967 14.5667 22.5259 13.3636C22.8551 12.1604 22.8029 10.8775 22.3772 9.70819Z"
                        fill="#E24329"/>
                    <path
                        d="M22.377 9.7075L22.3474 9.625C20.905 9.9358 19.546 10.5778 18.3676 11.505L11.8682 16.6675L16.0068 19.9527L20.5425 16.387L20.5547 16.3778C21.506 15.6238 22.1982 14.5655 22.5269 13.3624C22.8556 12.1593 22.803 10.8765 22.377 9.7075Z"
                        fill="#FC6D26"/>
                    <path
                        d="M7.71533 19.9532L9.94959 21.7268L11.3067 22.8048C11.466 22.9313 11.6603 22.9998 11.8601 22.9998C12.0598 22.9998 12.2541 22.9313 12.4134 22.8048L13.7705 21.7268L16.0048 19.9532L11.8653 16.668L7.71533 19.9532Z"
                        fill="#FCA326"/>
                    <path
                        d="M5.36108 11.506C4.18278 10.5789 2.82368 9.93719 1.38131 9.62695L1.35164 9.70853C0.925665 10.8778 0.873235 12.1609 1.20226 13.3643C1.53128 14.5676 2.22391 15.626 3.1757 16.3798L3.18617 16.3889L3.21236 16.4091L7.71578 19.9537L11.857 16.6685L5.36021 11.506H5.36108Z"
                        fill="#FC6D26"/>
                </SvgIcon>;
        default:
            return (
                <SvgIcon {...props}>
                    <path
                        clipRule="evenodd"
                        d="M7.95756 18.5811C7.61981 18.9864 7.01746 19.0412 6.61217 18.7034L0 13.1933L6.61217 7.68313C7.01746 7.34539 7.61981 7.40015 7.95756 7.80544C8.2953 8.21074 8.24054 8.81309 7.83525 9.15083L2.98432 13.1933L7.83525 17.2357C8.24054 17.5735 8.2953 18.1758 7.95756 18.5811Z"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M16.0424 18.5811C16.3802 18.9864 16.9825 19.0412 17.3878 18.7034L24 13.1933L17.3878 7.68313C16.9825 7.34539 16.3802 7.40015 16.0424 7.80544C15.7047 8.21074 15.7595 8.81309 16.1647 9.15083L21.0157 13.1933L16.1647 17.2357C15.7595 17.5735 15.7047 18.1758 16.0424 18.5811Z"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M10.5966 21.7087C10.0806 21.599 9.75117 21.0918 9.86086 20.5757L13.4358 3.75684C13.5455 3.2408 14.0528 2.91138 14.5688 3.02107C15.0849 3.13076 15.4143 3.63802 15.3046 4.15406L11.7296 20.973C11.6199 21.489 11.1127 21.8184 10.5966 21.7087Z"
                        fillRule="evenodd"/>
                </SvgIcon>);
    }
}