import { DataTableColumn, useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { PermissionRequestCloseReasonAndStatusFilter } from "../../../../../../..";
import { Contract } from "../../../../../../../../../../common";
import { TableColumnId } from "../../../../Table";
import { TableDefinition } from "../../useDefinition";
import { useCommonColumns } from "../useCommonColumns";
import { StatusCell } from "./components";

export function useHistoryDefinition(): TableDefinition {
    const commonColumns = useCommonColumns();
    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests.table.hooks.useDefinition.hooks.useHistoryDefinition",
            () => ({
                columns: {
                    status: "Status"
                }
            }));

    return useMemo(
        () =>
            new TableDefinition(
                () =>
                    [
                        commonColumns.systemCreationTime,
                        commonColumns.granteeUserIdReference,
                        commonColumns.permissionEligibility,
                        commonColumns.permissionAssignmentRequestTenantIds,
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: { 
                                    default: true,
                                    element:
                                        <PermissionRequestCloseReasonAndStatusFilter
                                            values={[
                                                Contract.PermissionRequestCloseReasonAndStatus.ActivationFailed,
                                                Contract.PermissionRequestCloseReasonAndStatus.Cancelled,
                                                Contract.PermissionRequestCloseReasonAndStatus.Expired,
                                                Contract.PermissionRequestCloseReasonAndStatus.Rejected,
                                                Contract.PermissionRequestCloseReasonAndStatus.RequestExpired,
                                                Contract.PermissionRequestCloseReasonAndStatus.Revoked
                                            ]}/> 
                                }
                            }}
                            id={TableColumnId.Status}
                            key={TableColumnId.Status}
                            render={StatusCell}
                            sortOptions={{ enabled: false }}
                            title={localization.columns.status()}/>,
                        commonColumns.expirationTimeFrame,
                        commonColumns.reason
                    ],
                [Contract.PermissionRequestStatus.Closed]
            ),
        [commonColumns, localization]);
}