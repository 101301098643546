import { NumberFormatter, Optional, Resizer, TimeFormatter, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Area, AreaChart, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "../../../../../../../common";

type ChartProps = {
    dateToObjectCountMap: Dictionary<number>;
    subscriptionLicenseObjectCount: Optional<number>;
};

export function Chart({ dateToObjectCountMap, subscriptionLicenseObjectCount }: ChartProps) {
    const items =
        useMemo(
            () =>
                _(dateToObjectCountMap).
                    map(
                        (objectCount, date) => ({
                            date,
                            objectCount
                        })).
                    orderBy(({ date }) => TimeFormatter.sortableDateTime(date)).
                    value(),
            [dateToObjectCountMap]);
    const [chartRect, setChartRect] = useState<DOMRect>();

    const localization =
        useLocalization(
            "views.customer.configuration.licensing.chart",
            () => ({ billableResources: "**{{count | NumberFormatter.humanize}}** Billable Resources" }));

    const theme = useTheme();
    return (
        <Stack
            sx={{
                height: "100%",
                position: "relative",
                width: "100%"
            }}>
            <Resizer onSize={setChartRect}/>
            <AreaChart
                data={items}
                height={(chartRect?.height ?? 0) - 16}
                margin={{
                    left: -10,
                    right: 20
                }}
                width={(chartRect?.width ?? 0) - 16}>
                <defs>
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint0_linear_55690_81548"
                        x1="419.882"
                        x2="419.372"
                        y1="0.624997"
                        y2="150.876">
                        <stop stopColor="#88B5FE"/>
                        <stop
                            offset="1"
                            stopColor="#88B5FE"
                            stopOpacity="0"/>
                    </linearGradient>
                </defs>
                {!_.isNil(subscriptionLicenseObjectCount) &&
                    <ReferenceLine
                        isFront={true}
                        stroke={theme.palette.error.main}
                        strokeDasharray={16}
                        strokeWidth={2}
                        y={subscriptionLicenseObjectCount}/>}
                <XAxis
                    dataKey={item => TimeFormatter.monthAndDay(item.date)}
                    interval="preserveStartEnd"
                    minTickGap={20}
                    stroke={theme.palette.text.secondary}/>
                <YAxis
                    domain={
                        ([_dataMin, dataMax], _) =>
                            [0,
                                Math.max(
                                    Math.floor(1.1 * dataMax),
                                    subscriptionLicenseObjectCount ?? 0)]}
                    stroke={theme.palette.text.secondary}
                    tick={({ fill, height, payload, width, x, y }) => (
                        <text
                            className="recharts-text recharts-cartesian-axis-tick-value"
                            fill={fill}
                            height={height}
                            orientation="left"
                            stroke="none"
                            textAnchor="end"
                            width={width}
                            x={x}
                            y={y}>
                            <tspan
                                dy="0.355em"
                                x={x}>{NumberFormatter.unitRound(payload.value)}</tspan>
                        </text>
                    )}/>
                <Tooltip
                    contentStyle={{
                        background: theme.palette.background.paper,
                        borderRadius: theme.spacing(0.75)
                    }}
                    formatter={
                        (objectCount, _name, _entry, _, _payload) => (
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                sx={{ color: theme.palette.text.primary }}>
                                <Typography>
                                    {localization.billableResources({ count: objectCount as number })}
                                </Typography>
                            </Stack>) as any}
                    labelStyle={{
                        color: theme.palette.text.secondary,
                        fontSize: "10px"
                    }}/>
                <Area
                    dataKey={item => item.objectCount}
                    dot={false}
                    fill="url(#paint0_linear_55690_81548)"
                    isAnimationActive={false}
                    key="chart"
                    stroke={theme.palette.primary.main}
                    strokeWidth={2}
                    type="monotone"/>)
            </AreaChart>
        </Stack>);
}