import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEc2InstancesRiskContext, useGetAwsEc2VolumeRiskContext } from "../../../contexts";


export function useAwsEc2VolumeEncryptionDisabledRiskVolumeDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2VolumeEncryptionDisabledRisk;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const volumeModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2VolumeModel;
    const volume = volumeModel.entity as Contract.AwsEc2Volume;
    const riskData = risk.data as Contract.AwsEc2VolumeEncryptionDisabledRiskEc2VolumeData;
    const instanceModels = entityModelStore.useGet(riskData.instanceIds) as Contract.AwsEc2InstanceModel[];

    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsEc2VolumeRiskContext = useGetAwsEc2VolumeRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VolumeEncryptionDisabledRiskDefinition.hooks.useAwsEc2VolumeEncryptionDisabledRiskVolumeDefinition",
            () => ({
                description: "{{volume}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Click the **Actions** button on the top and **Create Snapshot**",
                        step2: "In the left navigation pane click on **Snapshots** and select the new created snapshot",
                        step3: "Click the **Actions** button on the top and **Create Volume**",
                        step4: "Fill the parameters and check the **Encrypt this volume** checkbox",
                        step5: "Click the **Create Volume** button",
                        step6: "Before replacing the volume, verify that all identities used to launch the instance has decrypt permissions on the selected encryption key",
                        step7: "Stop the EC2 instance",
                        step8: "Under **Volumes** select the current unencrypted volume and click **Actions** on top and then **Detach Volume**",
                        step9: "Select the new encrypted volume and click **Actions** on top and than **Attach Volume**",
                        step10: "Select the instance and click **Attach Volume**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                volume:
                    <Entity
                        entityIdOrModel={volumeModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                volumeModel.unknown
                    ? AwsConsoleUrlBuilder.getEc2Url(tenantConfiguration.partitionType)
                    : AwsConsoleUrlBuilder.getEc2VolumeUrl(volume)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8(),
            localization.sections.resolution.step9(),
            localization.sections.resolution.step10()
        ],
        riskModel,
        () => {
            const instancesRiskContext = getAwsEc2InstancesRiskContext(instanceModels);
            const volumeRiskContext = getAwsEc2VolumeRiskContext(volumeModel);
            return [
                volumeRiskContext.generalInformation,
                volumeRiskContext.sensitive,
                volumeRiskContext.volumeSize,
                volumeRiskContext.instanceTerminationDeletion,
                volumeRiskContext.getInstancesContextItem(riskData.instanceIds),
                volumeRiskContext.snapshots,
                instancesRiskContext.sensitive,
                volumeRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}