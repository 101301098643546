import { InlineItems, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsElbLoadBalancerRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, NetworkScopeFormatter, tenantModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useAwsInboundExternalElbLoadBalancerRiskDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalElbLoadBalancerRiskModel = riskModel as Contract.AwsInboundExternalElbLoadBalancerRiskModel;
    const risk = inboundExternalElbLoadBalancerRiskModel.risk as Contract.AwsInboundExternalElbLoadBalancerRisk;
    const loadBalancerModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElbLoadBalancerModel;
    const loadBalancer = loadBalancerModel.entity as Contract.AwsElbLoadBalancer;
    const networkedResourceStateNetwork = loadBalancerModel.entityNetwork as Optional<Contract.AwsNetworkedResourceStateNetwork>;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

    const getAwsElbLoadBalancerRiskContext = useGetAwsElbLoadBalancerRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalElbLoadBalancerRiskDefinition",
            () => ({
                contextItems: {
                    managementDestinationNetworkScopes: "The {{translatedLoadBalancerTypeName}} is exposing {{managementDestinationNetworkScopes}}",
                    networkScopes: [
                        "1 management port",
                        "{{count | NumberFormatter.humanize}} management ports"
                    ],
                    ports: [
                        "1 port",
                        "{{count | NumberFormatter.humanize}} ports"
                    ],
                    securityGroupPorts: "The {{translatedLoadBalancerTypeName}} is exposing {{ports}} via {{securityGroups}}",
                    targetGroupManagementPortOpenAndHighPermission: [
                        "The target {{targets}} is associated with high severity permissions and expose {{networkScopes}}",
                        "{{targets}} are associated with high severity permissions and expose {{networkScopes}}"
                    ],
                    targets: [
                        "1 target",
                        "{{count | NumberFormatter.humanize}} targets"
                    ]
                },
                description: "{{loadBalancer}} is accessible directly from a wide range of public IP addresses",
                sections: {
                    details: "Details",
                    resolution: {
                        step1: {
                            securityGroups: {
                                pluralizer: [
                                    "1 security group",
                                    "{{count | NumberFormatter.humanize}} security groups"
                                ],
                                title: "For each of the {{securityGroups}}, under the **Inbound rules** tab, review the existing rules and click on the **Edit inbound rules** button"
                            },
                            targetGroups: {
                                pluralizer: [
                                    "1 target group",
                                    "{{count | NumberFormatter.humanize}} target groups"
                                ],
                                title: "For each of the {{targetGroups}}, review the existing configuration and restrict it only to the required addresses and ports"
                            }
                        },
                        step2: "Delete or edit each rule's type, port, and source range to restrict it only to the required IP addresses and ports",
                        step3: "Click **Save rules**"
                    }
                }
            }));

    const translatedLoadBalancerTypeName =
        entityTypeNameTranslator(
            loadBalancer.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            loadBalancer:
                <Entity
                    entityIdOrModel={loadBalancerModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            !_.isEmpty(risk.securityGroupIds)
                ? [
                    consoleSignInStepTranslator(
                        Contract.AwsConsoleView.SecurityGroups,
                        AwsConsoleUrlBuilder.getEc2SecurityGroupsUrl(
                            loadBalancer,
                            tenantConfiguration.partitionType)),
                    localization.sections.resolution.step1.securityGroups.title({
                        securityGroups:
                            <InlineEntities
                                entityIdsOrModels={risk.securityGroupIds}
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                namePluralizer={localization.sections.resolution.step1.securityGroups.pluralizer}
                                variant="itemCountAndType"/>
                    }),
                    localization.sections.resolution.step2(),
                    localization.sections.resolution.step3()
                ]
                : [
                    consoleSignInStepTranslator(
                        Contract.AwsConsoleView.Ec2,
                        AwsConsoleUrlBuilder.getElbTargetGroupsUrl(loadBalancer)),
                    localization.sections.resolution.step1.targetGroups.title({
                        targetGroups:
                            <InlineEntities
                                entityIdsOrModels={loadBalancerModel.targetGroupIds}
                                entityTypeName={Contract.TypeNames.AwsElbTargetGroup}
                                namePluralizer={localization.sections.resolution.step1.targetGroups.pluralizer}
                                variant="itemCountAndType"/>
                    })
                ],
        riskModel,
        () => {
            const loadBalancerRiskContext = getAwsElbLoadBalancerRiskContext(loadBalancerModel);
            return [
                loadBalancerRiskContext.generalInformation,
                loadBalancerRiskContext.sensitive,
                loadBalancerRiskContext.targetExists,
                loadBalancerRiskContext.sensitiveTargets,
                (
                    loadBalancer.typeName === Contract.TypeNames.AwsElbApplicationLoadBalancer ||
                    loadBalancer.typeName === Contract.TypeNames.AwsElbClassicLoadBalancer) &&
                !_.isEmpty(risk.securityGroupIds) &&
                !_.isNil(networkedResourceStateNetwork)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.securityGroupPorts({
                            ports:
                                <InlineItems
                                    items={
                                        _.map(
                                            networkedResourceStateNetwork.inboundExternalDestinationNetworkScopes,
                                            destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope))}
                                    namePluralizer={localization.contextItems.ports}
                                    variant="itemCountAndType"/>,
                            securityGroups:
                                <InlineEntities
                                    entityIdsOrModels={risk.securityGroupIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                    variant="itemAndTypeOrItemCountAndType"/>,
                            translatedLoadBalancerTypeName
                        }))
                    : undefined,
                loadBalancerRiskContext.targetGroupPorts,
                !_.isEmpty(inboundExternalElbLoadBalancerRiskModel.managementDestinationNetworkScopes)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.managementDestinationNetworkScopes({
                            managementDestinationNetworkScopes:
                                <InlineItems
                                    items={
                                        _.map(
                                            inboundExternalElbLoadBalancerRiskModel.managementDestinationNetworkScopes,
                                            destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope))}
                                    namePluralizer={localization.contextItems.networkScopes}
                                    variant="itemCountAndType"/>,
                            translatedLoadBalancerTypeName
                        }))
                    : undefined,
                !_.isEmpty(risk.targetIds)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.targetGroupManagementPortOpenAndHighPermission(
                            risk.targetIds.length,
                            {
                                networkScopes:
                                    <InlineItems
                                        items={
                                            _.map(
                                                risk.destinationNetworkScopes,
                                                destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope))}
                                        namePluralizer={localization.contextItems.networkScopes}
                                        variant="itemCountAndType"/>,
                                targets:
                                    <InlineEntities
                                        entityIdsOrModels={risk.targetIds}
                                        entityTypeName={Contract.TypeNames.AwsResource}
                                        namePluralizer={localization.contextItems.targets}
                                        variant="itemOrItemCountAndType"/>
                            }))
                    : undefined,
                loadBalancerRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}