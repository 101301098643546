import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsCloudTrailTrailRiskContext, useGetAwsS3BucketRiskContext } from "../contexts";

export function useAwsCloudTrailTrailBucketEventDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const trailBucketEventDisabledRiskModel = riskModel as Contract.AwsCloudTrailTrailBucketEventDisabledRiskModel;
    const trailModel = entityModelStore.useGet(trailBucketEventDisabledRiskModel.risk.entityId) as Contract.AwsCloudTrailTrailModel;
    const bucketModel = entityModelStore.useGet(trailModel.bucketId) as Contract.AwsS3BucketModel;
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;

    const getAwsCloudTrailTrailRiskContext = useGetAwsCloudTrailTrailRiskContext();
    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudTrailTrailBucketEventDisabledRiskDefinition",
            () => ({
                description: "{{trail}} does not have logging enabled",
                sections: {
                    resolution: {
                        step1: "Set **Server access logging** to **Enable** and specify a destination {{translatedBucketTypeName}}",
                        step2: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            trail:
                <Entity
                    entityIdOrModel={trailModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.S3,
                AwsConsoleUrlBuilder.getS3BucketEditLoggingUrl(bucket)),
            localization.sections.resolution.step1({
                translatedBucketTypeName:
                    entityTypeNameTranslator(
                        bucket.typeName,
                        { variant: "text" })
            }),
            localization.sections.resolution.step2()
        ],
        trailBucketEventDisabledRiskModel,
        () => {
            const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
            const trailRiskContext = getAwsCloudTrailTrailRiskContext(trailModel);
            return [
                trailRiskContext.generalInformation,
                trailRiskContext.sensitive,
                trailRiskContext.enabled,
                trailRiskContext.multiRegion,
                trailRiskContext.organizationTrail,
                trailRiskContext.bucketName,
                bucketRiskContext.sensitive,
                trailRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)];
        });
}