import { map } from "@infrastructure";
import { Contract, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder, GcpLocationHelper } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpComputeLoadBalancerDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const loadBalancer = entityModel.entity as Contract.GcpComputeLoadBalancer;
    const tenantModel = tenantModelStore.useGet(loadBalancer.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Edit]: () =>
                    (name: string, regionSystemName: string, tenantRawShortNameId: string) => {
                        switch (loadBalancer.type) {
                            case Contract.GcpComputeLoadBalancerType.HttpClassicProxy:
                                return GcpConsoleUrlBuilder.GetComputeLoadBalancerHttpClassicProxyEditUrl(name, tenantRawShortNameId);
                            case Contract.GcpComputeLoadBalancerType.HttpInternalProxy:
                                return GcpConsoleUrlBuilder.GetComputeLoadBalancerHttpInternalProxyEditUrl(regionSystemName, name, tenantRawShortNameId);
                            case Contract.GcpComputeLoadBalancerType.HttpProxy:
                                return GcpLocationHelper.isGlobal(loadBalancer.regionId!)
                                    ? GcpConsoleUrlBuilder.GetComputeLoadBalancerHttpProxyGlobalUrl(name, tenantRawShortNameId)
                                    : GcpConsoleUrlBuilder.GetComputeLoadBalancerHttpProxyRegionUrl(regionSystemName, name, tenantRawShortNameId);
                            case Contract.GcpComputeLoadBalancerType.NetworkBackendService:
                                return GcpConsoleUrlBuilder.GetComputeLoadBalancerNetworkBackendServiceEditUrl(regionSystemName, name, tenantRawShortNameId);
                            case Contract.GcpComputeLoadBalancerType.NetworkInternal:
                                return GcpConsoleUrlBuilder.GetComputeLoadBalancerNetworkInternalEditUrl(regionSystemName, name, tenantRawShortNameId);
                            case Contract.GcpComputeLoadBalancerType.NetworkTargetPool:
                                return GcpConsoleUrlBuilder.GetComputeLoadBalancerNetworkTargetPoolEditUrl(regionSystemName, name, tenantRawShortNameId);
                            case Contract.GcpComputeLoadBalancerType.SslTcpProxy:
                                return GcpLocationHelper.isGlobal(loadBalancer.regionId!)
                                    ? GcpConsoleUrlBuilder.GetComputeLoadBalancerSslTcpProxyEditGlobalUrl(name, tenantRawShortNameId)
                                    : GcpConsoleUrlBuilder.GetComputeLoadBalancerSslTcpProxyEditRegionUrl(regionSystemName, name, tenantRawShortNameId);
                            case Contract.GcpComputeLoadBalancerType.TcpInternalProxy:
                                return GcpConsoleUrlBuilder.GetComputeLoadBalancerTcpInternalEditUrl(regionSystemName, name, tenantRawShortNameId);

                        }
                    },
                [Contract.GcpConsolePage.Permissions]: () => undefined
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            loadBalancer.name,
            RegionModelHelper.get(loadBalancer.regionId!).systemName,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}