import { Tooltip, useLocalization } from "@infrastructure";
import { Button, useTheme } from "@mui/material";
import React from "react";
import { CopyToClipboard } from "../CopyToClipboard";

type CopyToClipboardButtonProps = {
    getValue: () => string;
    tooltip?: string;
};

export function CopyToClipboardButton({ getValue, tooltip }: CopyToClipboardButtonProps) {
    const localization =
        useLocalization(
            "infrastructure.copyToClipboard.copyToClipboardButton",
            () => ({
                action: {
                    copy: "Copy"
                }
            }));

    const theme = useTheme();
    return (
        <CopyToClipboard
            getValue={getValue}>
            <Tooltip titleOrGetTitle={tooltip}>
                <Button
                    sx={{
                        minWidth: "unset",
                        padding: theme.spacing(1, 3)
                    }}
                    variant="outlined">
                    {localization.action.copy()}
                </Button>
            </Tooltip>
        </CopyToClipboard>);
}