﻿import { Optional, UnexpectedError } from "@infrastructure";
import { FunctionComponent, useMemo } from "react";
import { Contract } from "../../../../../../../../common";
import { PermissionManagementControllerInsertPermissionAssignmentRequestRequest } from "../../../../../../../../common/controllers/types.generated";
import { AdditionalFieldComponentProps } from "../../Add";
import { PermissionAssignmentRequestTenantIds } from "./components";

export function useGetDefinition() {
    return useMemo(
        () => {
            const groupMembershipEligibilityDefinition =
                new PermissionEligibilityDefinition(
                    [],
                    (_, expirationTimeFrame, permissionEligibilityId, reason, startTime) =>
                        new Contract.PermissionManagementControllerInsertGroupMembershipRequestRequest(
                            expirationTimeFrame,
                            permissionEligibilityId,
                            reason,
                            startTime),
                    () => new GroupMembershipRequestData());

            const permissionAssignmentEligibilityDefinition =
                new PermissionEligibilityDefinition(
                    [PermissionAssignmentRequestTenantIds],
                    (additionalPermissionRequestData, expirationTimeFrame, permissionEligibilityId, reason, startTime) =>
                        new PermissionManagementControllerInsertPermissionAssignmentRequestRequest(
                            expirationTimeFrame,
                            permissionEligibilityId,
                            reason,
                            startTime,
                            (additionalPermissionRequestData as AdditionalPermissionAssignmentRequestData).tenantIds),
                    () => new AdditionalPermissionAssignmentRequestData());

            const oneLoginDirectoryRoleAssignmentEligibilityDefinition =
                new PermissionEligibilityDefinition(
                    [],
                    (_, expirationTimeFrame, permissionEligibilityId, reason, startTime) =>
                        new Contract.PermissionManagementControllerInsertOneLoginDirectoryRoleAssignmentRequestRequest(
                            expirationTimeFrame,
                            permissionEligibilityId,
                            reason,
                            startTime),
                    () => new OneLoginDirectoryRoleAssignmentRequestData());

            return (permissionEligibilityModel: Contract.PermissionEligibilityModel): PermissionEligibilityDefinition => {
                switch (permissionEligibilityModel.configuration.typeName) {
                    case Contract.TypeNames.AadDirectoryGroupMembershipEligibilityConfiguration:
                    case Contract.TypeNames.GciDirectoryGroupMembershipEligibilityConfiguration:
                    case Contract.TypeNames.OktaDirectoryGroupMembershipEligibilityConfiguration:
                    case Contract.TypeNames.PingIdentityDirectoryGroupMembershipEligibilityConfiguration:
                        return groupMembershipEligibilityDefinition;
                    case Contract.TypeNames.OneLoginDirectoryRoleAssignmentEligibilityConfiguration:
                        return oneLoginDirectoryRoleAssignmentEligibilityDefinition;
                    case Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfiguration:
                    case Contract.TypeNames.AzureRoleAssignmentEligibilityConfiguration:
                    case Contract.TypeNames.GcpRoleBindingEligibilityConfiguration:
                        return permissionAssignmentEligibilityDefinition;
                    default:
                        throw new UnexpectedError("permissionEligibilityModel.configuration.typeName", permissionEligibilityModel.configuration.typeName);
                }
            };
        },
        []);
}

export abstract class AdditionalPermissionRequestData {
}

export class AdditionalPermissionAssignmentRequestData {
    constructor(public tenantIds: string[] = []) {
    }
}

export class GroupMembershipRequestData {
    constructor() {
    }
}

export class OneLoginDirectoryRoleAssignmentRequestData {
    constructor() {
    }
}

export class PermissionEligibilityDefinition {
    constructor(
        public additionalFieldComponents: FunctionComponent<AdditionalFieldComponentProps>[],
        public createInsertPermissionRequestRequest: (additionalPermissionRequestData: AdditionalPermissionRequestData, expirationTimeFrame: string, permissionEligibilityId: string, reason: Optional<string>, startTime: Optional<string>) => Contract.PermissionManagementControllerInsertPermissionRequestRequest,
        public createPermissionRequestAdditionalData: () => AdditionalPermissionRequestData) {
    }
}