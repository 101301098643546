import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Base64Helper, Contract, InfoCard, InfoItem, InlineTextViewer } from "../../../../../../../../../../common";
import { AwsEc2DeviceTable } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";
import { useAwsEc2InstanceMetadataServiceInfoItems } from "./useAwsEc2InstanceMetadataServiceInfoItems";

export function useAwsAutoScalingLaunchConfigurationDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const launchConfiguration = resourceModel.entity as Contract.AwsAutoScalingLaunchConfiguration;
    const instanceMetadataServiceInfoItems = useAwsEc2InstanceMetadataServiceInfoItems(launchConfiguration.metadataService);
    const launchConfigurationModel = resourceModel as Contract.AwsAutoScalingLaunchConfigurationModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsAutoScalingLaunchConfigurationDefinition",
            () => ({
                info: {
                    cards: {
                        autoScalingGroupIds: {
                            emptyMessage: "No auto scaling groups",
                            title: "Auto Scaling Groups"
                        }
                    },
                    items: {
                        devices: "Block Device Mappings",
                        id: "ID",
                        imageId: "Image",
                        instanceRawType: "Instance Type",
                        keyName: "Key Pair",
                        metadataServiceVersion: "Metadata Version",
                        name: "Name",
                        roleReference: "Role",
                        securityGroupIds: "Security Groups",
                        userData: {
                            open: "View",
                            title: "User Data"
                        },
                        vpcIds: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="name"
                        title={localization.info.items.name()}
                        value={launchConfiguration.name}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={launchConfiguration?.roleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="roleReference"
                        title={localization.info.items.roleReference()}/>,
                    <EntitiesInfoItem
                        emptyValue={launchConfiguration.imageRawId}
                        entityIdsOrModels={launchConfigurationModel.imageId}
                        entityTypeName={Contract.TypeNames.AwsEc2Image}
                        key="imageId"
                        title={localization.info.items.imageId()}/>,
                    <InfoItem
                        key="instanceRawType"
                        title={localization.info.items.instanceRawType()}
                        value={launchConfiguration.instanceRawType}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={launchConfiguration.securityGroupIds}
                        entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                        key="securityGroupIds"
                        title={localization.info.items.securityGroupIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={launchConfigurationModel.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpcIds"
                        title={localization.info.items.vpcIds()}/>,
                    ...instanceMetadataServiceInfoItems,
                    <InfoItem
                        key="userData"
                        title={localization.info.items.userData.title()}
                        value={
                            _.isNil(launchConfiguration.userData)
                                ? undefined
                                : <InlineTextViewer
                                    dialogTitle={localization.info.items.userData.title()}
                                    text={
                                        Base64Helper.isValid(launchConfiguration.userData)
                                            ? atob(launchConfiguration.userData)
                                            : launchConfiguration.userData}
                                    title={localization.info.items.userData.open()}/>}/>,
                    <InfoItem
                        key="keyName"
                        title={localization.info.items.keyName()}
                        value={launchConfiguration.keyName}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={launchConfigurationModel.autoScalingGroupIds}
                    entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                    title={localization.info.cards.autoScalingGroupIds.title()}/>
                {_.isEmpty(launchConfiguration.devices)
                    ? undefined
                    : <InfoCard title={localization.info.items.devices()}>
                        <AwsEc2DeviceTable
                            devices={launchConfiguration.devices}
                            snapshotRawIdToIdMap={launchConfigurationModel.snapshotRawIdToIdMap}/>
                    </InfoCard>}
            </Info>
    });
}