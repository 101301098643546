import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetWorkloadAnalysisRiskContext } from "./useGetWorkloadAnalysisRiskContext";
import { useGetWorkloadResourceOperatingSystemContextItem } from "./useGetWorkloadResourceOperatingSystemContextItem";

export function useGetVirtualMachineWorkloadAnalysisRiskContext() {
    return useMemo(
        () => useVirtualMachineWorkloadAnalysisRiskContext,
        []);
}

function useVirtualMachineWorkloadAnalysisRiskContext(risk: Contract.VirtualMachineWorkloadAnalysisRisk) {
    const entityModel = entityModelStore.useGet(risk.entityId);
    const virtualMachineModels = _.as<Contract.IVirtualMachineModel[]>(entityModelStore.useGet(risk.virtualMachineIds));
    const operatingSystemDisplayName =
        _(virtualMachineModels).
            filter(virtualMachineModel => !_.isNil(virtualMachineModel.workloadAnalysisOperatingSystem?.displayName)).
            first()?.
            workloadAnalysisOperatingSystem!.
            displayName;
    const operatingSystemEndOfLifeDate =
        _(virtualMachineModels).
            filter(virtualMachineModel => !_.isNil(virtualMachineModel.workloadAnalysisOperatingSystemEndOfLifeDate)).
            first()?.
            workloadAnalysisOperatingSystemEndOfLifeDate;
    const operatingSystemType =
        _(virtualMachineModels).
            filter(virtualMachineModel => !_.isNil(virtualMachineModel.operatingSystemType)).
            first()?.
            operatingSystemType;

    const getWorkloadResourceOperatingSystemContextItem = useGetWorkloadResourceOperatingSystemContextItem();
    const workloadAnalysisRiskContext = useGetWorkloadAnalysisRiskContext()(risk);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.contexts.useGetVirtualMachineWorkloadAnalysisRiskContext",
            () => ({
                virtualMachines: "The {{translatedEntityTypeName}} contains {{virtualMachines}}",
                vulnerabilities: "The {{translatedEntityTypeName}} contains {{vulnerabilities}}{{resolvableVulnerabilitiesSuffix}}"
            }));

    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityModel.entity.typeName,
            { includeServiceName: false });
    return {
        ...workloadAnalysisRiskContext,
        getVulnerabilities: (
            displayResolvableOperatingSystemPackageVulnerabilities: boolean = false,
            displayResolvableVulnerabilities: boolean = false) =>
            _.isEmpty(workloadAnalysisRiskContext.severityVulnerabilities)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.vulnerabilities({
                        ...workloadAnalysisRiskContext.getVulnerabilitiesProps(
                            operatingSystemType,
                            displayResolvableOperatingSystemPackageVulnerabilities,
                            displayResolvableVulnerabilities),
                        translatedEntityTypeName
                    })),
        operatingSystem:
            getWorkloadResourceOperatingSystemContextItem(
                operatingSystemDisplayName,
                operatingSystemEndOfLifeDate,
                translatedEntityTypeName),
        virtualMachines:
            risk.singleVirtualMachine
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.virtualMachines({
                        translatedEntityTypeName,
                        virtualMachines:
                            <InlineEntities
                                entityIdsOrModels={risk.virtualMachineIds}
                                entityTypeName={Contract.TypeNames.IVirtualMachine}
                                variant="itemCountAndType"/>
                    }))
    };
}