import { DataTableColumn, useLocalization } from "@infrastructure";
import React from "react";
import { useTheme } from "../../../../../../../../common";
import { TableColumnId } from "../../components";
import { DescriptionCell } from "./components";

export function useTableCommonCloudColumns() {
    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useTableCommonCloudColumns",
            () => ({
                columns: {
                    description: "Description"
                }
            }));

    const theme = useTheme();
    return {
        descriptionColumn:
            <DataTableColumn
                cellMaxWidth="medium"
                cellSx={{ minWidth: theme.spacing(25) }}
                id={TableColumnId.Description}
                key={TableColumnId.Description}
                render={DescriptionCell}
                selectorOptions={{ default: false }}
                sortOptions={{ enabled: false }}
                title={localization.columns.description()}/>
    };
}