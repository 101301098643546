import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useGcpDnsManagedZoneDnsSecSigningKeyAlgorithmTranslator, useGcpDnsManagedZoneDnsSecStatusTranslator, useGcpDnsManagedZonePrivateZoneTypeTranslator } from "../../../../../../../hooks";
import { Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useGcpDefaultResourceInfoItemElements } from "../useGcpDefaultResourceInfoItemElements";
import { useGcpDefaultScopeResourceInfoItemElements } from "../useGcpDefaultScopeResourceInfoItemElements";
import { ResourceRecordSetsInfoCard } from "./components";

export function useGcpDnsManagedZoneDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const managedZoneModel = scopeResourceModel as Contract.GcpDnsManagedZoneModel;
    const managedZone = managedZoneModel.entity as Contract.GcpDnsManagedZone;

    const dnsManagedZonePrivateZoneTypeTranslator = useGcpDnsManagedZonePrivateZoneTypeTranslator();
    const dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator = useGcpDnsManagedZoneDnsSecSigningKeyAlgorithmTranslator();
    const dnsManagedZoneSecurityStatusTranslator = useGcpDnsManagedZoneDnsSecStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpDnsManagedZoneDefinition",
            () => ({
                info: {
                    items: {
                        description: "Description",
                        dnsName: "DNS Name",
                        dnsSec: {
                            keySigningKeyAlgorithm: "DNSSEC Key Signing Key Algorithm",
                            status: "DNSSEC",
                            zoneSigningKeyAlgorithm: "DNSSEC Zone Signing Key Algorithm"
                        },
                        loggingEnabled: {
                            false: "Off",
                            title: "Logging",
                            true: "On"
                        },
                        privateZoneType: "Private Zone Type",
                        publicNetworkAccess: {
                            false: "Private",
                            title: "Visibility",
                            true: "Public"
                        },
                        resourceRecordSetCount: "Resource Record Set Count"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="publicNetworkAccess"
                        title={localization.info.items.publicNetworkAccess.title()}
                        value={
                            managedZone.publicNetworkAccess
                                ? localization.info.items.publicNetworkAccess.true()
                                : localization.info.items.publicNetworkAccess.false()}/>,
                    <InfoItem
                        key="privateZoneType"
                        title={localization.info.items.privateZoneType()}
                        value={
                            _.isNil(managedZone.privateZoneType)
                                ? undefined
                                : dnsManagedZonePrivateZoneTypeTranslator(managedZone.privateZoneType)}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={managedZone.description}/>,
                    <InfoItem
                        key="dnsSecStatus"
                        title={localization.info.items.dnsSec.status()}
                        value={
                            _.isNil(managedZone.dnsSec)
                                ? undefined
                                : dnsManagedZoneSecurityStatusTranslator(managedZone.dnsSec.status)}/>,
                    <InfoItem
                        key="loggingEnabled"
                        title={localization.info.items.loggingEnabled.title()}
                        value={
                            managedZone.loggingEnabled
                                ? localization.info.items.loggingEnabled.true()
                                : localization.info.items.loggingEnabled.false()}/>,
                    <InfoItem
                        key="keySigningKeyAlgorithm"
                        title={localization.info.items.dnsSec.keySigningKeyAlgorithm()}
                        value={
                            _.isNil(managedZone.dnsSec?.keySigningKeyAlgorithm)
                                ? undefined
                                : dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator(managedZone.dnsSec!.keySigningKeyAlgorithm)}/>,
                    <InfoItem
                        key="zoneSigningKeyAlgorithm"
                        title={localization.info.items.dnsSec.zoneSigningKeyAlgorithm()}
                        value={
                            _.isNil(managedZone.dnsSec?.zoneSigningKeyAlgorithm)
                                ? undefined
                                : dnsManagedZoneDnsSecSigningKeyAlgorithmTranslator(managedZone.dnsSec!.zoneSigningKeyAlgorithm)}/>,
                    <InfoItem
                        key="dnsName"
                        title={localization.info.items.dnsName()}
                        value={managedZone.dnsName}/>,
                    <InfoItem
                        key="resourceRecordSetCount"
                        title={localization.info.items.resourceRecordSetCount()}
                        value={managedZoneModel.resourceRecordSetCount}/>
                ]}
                options={options?.infoOptions}>
                <ResourceRecordSetsInfoCard managedZoneModel={managedZoneModel}/>
            </Info>
    });
}