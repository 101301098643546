import { useLocalization, useQueryParameters } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { MailImg, NotificationMail } from "..";
import { Contract, PermissionManagementHelper, UserConsoleAppUrlHelper, useTheme } from "../../../../common";
import { useResourcesTranslator } from "./hooks";

type RejectedPermissionAssignmentRequestMailQueryParameters = {
    additionalGroupCount?: string;
    additionalRoleCount?: string;
    additionalTenantCount?: string;
    approverUserName: string;
    granteeUserName: string;
    groupNames?: string | string[];
    permissionRequestType: Contract.PermissionRequestMailPermissionRequestType;
    roleNames?: string | string[];
    tenantDisplayReferences: string | string[];
    tenantNames: string | string[];
};

export function ExportRejectedPermissionAssignmentRequestMail() {
    const { additionalGroupCount, additionalRoleCount, additionalTenantCount, approverUserName, granteeUserName, groupNames, permissionRequestType, roleNames, tenantDisplayReferences, tenantNames } = useQueryParameters<RejectedPermissionAssignmentRequestMailQueryParameters>();
    const localization =
        useLocalization(
            "views.system.exportPermissionRequestMail.exportRejectedPermissionAssignmentRequestMail",
            () => ({
                link: "Go to Tenable Cloud Security Portal",
                mailTitle: "{{approverUserName}} has denied your access request",
                subtitle: "**{{approverUserName}}** has {{rejectedElement}} your access request to {{resources}}. Go to Tenable Cloud Security portal to create a new request.",
                title: "Hi {{granteeUserName}},"
            }));
    const resourcesTranslator = useResourcesTranslator();
    return (
        <NotificationMail
            link={{
                text: localization.link(),
                value: UserConsoleAppUrlHelper.getGranteeUserPermissionRequestsRelativeUrl()
            }}
            mailTitle={localization.mailTitle({ approverUserName })}
            subtitle={
                localization.subtitle(
                    {
                        approverUserName,
                        rejectedElement: <Rejected/>,
                        resources:
                            resourcesTranslator(
                                permissionRequestType,
                                additionalGroupCount,
                                additionalRoleCount,
                                additionalTenantCount,
                                groupNames,
                                roleNames,
                                tenantDisplayReferences,
                                tenantNames)
                    })}
            title={localization.title({ granteeUserName })}/>);
}

function Rejected() {
    const localization =
        useLocalization(
            "views.system.exportPermissionRequestMail.exportRejectedPermissionAssignmentRequestMail.rejected",
            () => ({
                text: "denied"
            }));

    const theme = useTheme();
    return (
        <Typography
            component="span"
            style={{ color: PermissionManagementHelper.getPermissionRequestCloseReasonColor(Contract.PermissionRequestCloseReason.Rejected) }}>
            <MailImg
                alt="Rejected icon"
                height="18"
                src="cid:PermissionRequestRejected"
                style={{
                    display: "inline",
                    paddingRight: theme.spacing(0.5),
                    verticalAlign: "middle"
                }}
                width="18"/>
            {localization.text()}
        </Typography>);
}