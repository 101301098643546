import { ApiError, CheckboxField, Dialog, FormLayout, Message, Optional, SuccessIcon, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, RiskPoliciesType, useRiskControllerUpdateRiskPolicyConfigurationErrorTranslator, useRiskPolicyConfigurationControllerDefinition, useTheme } from "../../common";

type SecretExclusionActionProps = {
    onExcludeChanged: (exclude: boolean) => void;
    riskPoliciesType?: RiskPoliciesType;
    riskPolicyConfiguration: Contract.RiskPolicyConfiguration;
    secretKey?: string;
    secretValue?: string;
};

export function SecretExclusionAction({ onExcludeChanged, riskPoliciesType = RiskPoliciesType.Cloud, riskPolicyConfiguration, secretKey, secretValue }: SecretExclusionActionProps) {
    const [caseSensitive, setCaseSensitive] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [executing, setExecuting] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [saved, setSaved] = useState(false);
    const [secretKeyPattern, setSecretKeyPattern] = useState(secretKey ?? "");
    const [secretValuePattern, setSecretValuePattern] = useState(secretValue ?? "");
    const [valid, setValid] = useState(!_.isEmpty(secretKeyPattern) && !_.isEmpty(secretValuePattern));
    const { updateRiskPolicyConfiguration } = useRiskPolicyConfigurationControllerDefinition(riskPoliciesType, riskPolicyConfiguration.typeName, riskPolicyConfiguration.scopeId);

    useChangeEffect(
        () => {
            setValid(!_.isEmpty(secretKeyPattern) && !_.isEmpty(secretValuePattern));
            setErrorMessage(undefined);
        },
        [secretKeyPattern, secretValuePattern]);

    const riskControllerUpdateRiskPolicyConfigurationErrorTranslator = useRiskControllerUpdateRiskPolicyConfigurationErrorTranslator();
    const localization =
        useLocalization(
            "common.secretExclusionAction",
            () => ({
                caseInsensitive: "Ignore case",
                dialog: {
                    button: {
                        done: "Done",
                        save: "Exclude"
                    },
                    error: {
                        duplicate: "The key/value pattern already exists",
                        failed: "Failed to exclude"
                    },
                    subtitle: "Exclude secrets from this policy to address detected false-positives. Enter a pattern you would like to exclude. For example, use the value pattern \"https://*\" to exclude any URLs that were falsely detected as secrets.",
                    success: "Secret pattern excluded successfully",
                    title: "Exclude Secret"
                },
                key: "Key Pattern",
                message: {
                    placeholder: "Write a Comment...",
                    title: "Comment"
                },
                value: "Value Pattern"
            }));

    async function updatePolicyExcludedSecret() {
        setExecuting(true);
        try {
            const secretExistsRiskPolicyConfiguration = riskPolicyConfiguration as Contract.SecretExistsRiskPolicyConfiguration;
            const exclusionSecretPatterns = secretExistsRiskPolicyConfiguration.exclusionSecretPatterns ?? [];
            const duplicate =
                _.includes(
                    _(exclusionSecretPatterns).
                        map(
                            exclusionSecretPattern =>
                                `${exclusionSecretPattern.keyPattern}-${exclusionSecretPattern.valuePattern}`).
                        value(),
                    `${secretKeyPattern}-${secretValuePattern}`);
            if (duplicate) {
                setErrorMessage(localization.dialog.error.duplicate());
                setExecuting(false);
                return;
            }

            secretExistsRiskPolicyConfiguration.exclusionSecretPatterns =
                _(exclusionSecretPatterns).
                    concat(
                        new Contract.SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern(
                            caseSensitive,
                            secretKeyPattern,
                            message as Optional<string>,
                            secretValuePattern)).
                    value();
            setErrorMessage(undefined);

            await updateRiskPolicyConfiguration({
                codeExclusions: riskPolicyConfiguration.codeExclusions,
                entityTypeNameToExclusionsMap: riskPolicyConfiguration.entityTypeNameToExclusionsMap,
                riskPoliciesIds: [riskPolicyConfiguration.id],
                riskPolicyConfiguration: secretExistsRiskPolicyConfiguration,
                riskPolicyIdToSystemUpdateTimeMap: {}
            });

            setSaved(true);
        } catch (error) {
            setErrorMessage(
                error instanceof ApiError &&
                error.statusCode === 400 &&
                error.error === Contract.RiskControllerUpdateRiskPolicyConfigurationError.Conflict
                    ? riskControllerUpdateRiskPolicyConfigurationErrorTranslator(error.error)
                    : localization.dialog.error.failed());
        }
        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <Fragment>
            <Dialog
                variant={"editor"}
                onClose={() => onExcludeChanged(false)}>
                <FormLayout
                    disableContentPadding={true}
                    footerOptions={{
                        contentElement:
                            <Stack
                                alignItems="center"
                                direction="row"
                                justifyContent="space-between"
                                spacing={1}>
                                <Box>
                                    {!_.isNil(errorMessage) && (
                                        <Message
                                            level="error"
                                            title={errorMessage}/>)}
                                </Box>
                                <Box>
                                    {executing && (
                                        <CircularProgress
                                            size={theme.spacing(2)}
                                            variant="indeterminate"/>)}
                                    <Button
                                        disabled={!valid || executing || !_.isNil(errorMessage)}
                                        onClick={() =>
                                            saved
                                                ? onExcludeChanged(false)
                                                : updatePolicyExcludedSecret()}>
                                        {saved
                                            ? localization.dialog.button.done()
                                            : localization.dialog.button.save()}
                                    </Button>
                                </Box>
                            </Stack>
                    }}
                    titleOptions={
                        saved
                            ? undefined
                            : {
                                subtitle: localization.dialog.subtitle(),
                                text: localization.dialog.title()
                            }}>
                    {saved
                        ? <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                            spacing={2}
                            sx={{
                                margin: theme.spacing(10, 10, 5),
                                width: theme.spacing(45)
                            }}>
                            <SuccessIcon
                                sx={{
                                    color: theme.palette.success.main,
                                    fontSize: "18px"
                                }}/>
                            <Typography
                                align="center"
                                sx={{ fontWeight: 400 }}
                                variant="h2">
                                {localization.dialog.success()}
                            </Typography>
                        </Stack>
                        : <Stack
                            spacing={2}
                            sx={{
                                flex: 1,
                                padding: theme.spacing(2, 3)
                            }}>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    alignItems: "center",
                                    height: "100%"
                                }}>
                                <TextField
                                    disabled={executing}
                                    fullWidth={true}
                                    label={localization.key()}
                                    value={secretKeyPattern}
                                    variant="outlined"
                                    onChange={event => setSecretKeyPattern(event.target.value.trim())}/>
                                <TextField
                                    disabled={executing}
                                    fullWidth={true}
                                    label={localization.value()}
                                    value={secretValuePattern}
                                    variant="outlined"
                                    onChange={event => setSecretValuePattern(event.target.value.trim())}/>
                                <CheckboxField
                                    checked={!caseSensitive}
                                    title={localization.caseInsensitive()}
                                    onChange={() => setCaseSensitive(!caseSensitive)}>
                                    {localization.caseInsensitive()}
                                </CheckboxField>
                            </Stack>
                            <Typography variant="h5">
                                {localization.message.title()}
                            </Typography>
                            <TextField
                                disabled={executing}
                                fullWidth={true}
                                multiline={true}
                                placeholder={localization.message.placeholder()}
                                rows={4}
                                value={message}
                                variant="outlined"
                                onChange={
                                    event =>
                                        setMessage(
                                            _.isEmpty(event.target.value)
                                                ? null
                                                : event.target.value)}/>
                        </Stack>}
                </FormLayout>
            </Dialog>
        </Fragment>);
}