import { InlineItems, Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlinePermissionActions, useTheme } from "../../../../../../../../../../../../common";
import { GcpFunctionsFunctionEnvironmentVariableTable } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useResourceSecretExistsRiskContext } from "../../../useResourceSecretExistsRiskContext";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpFunctionsFunctionRiskContext } from "../contexts";

export function useGcpFunctionsFunctionEnvironmentVariableSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpFunctionsFunctionEnvironmentVariableSecretExistsRisk;
    const functionEnvironmentVariableSecretExistsRiskModel = riskModel as Contract.GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskModel;
    const functionModel = entityModelStore.useGet(risk.entityId) as Contract.GcpFunctionsFunctionModel;
    const functionEntity = functionModel.entity as Contract.GcpFunctionsFunction;

    const getGcpFunctionsFunctionRiskContext = useGetGcpFunctionsFunctionRiskContext();
    const secretRiskContext = useResourceSecretExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpFunctionsFunctionEnvironmentVariableSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    nonPublicReadPermissionActions: "{{principalIds}} were granted {{nonPublicReadPermissionActions}}, allowing them to read your secrets",
                    nonPublicReadPermissionActionsExternalPrincipals: "{{principalIds}} including {{partialPrincipalIds}} were granted {{nonPublicReadPermissionActions}}, allowing them to read your secrets",
                    publicReadActions: "All users / All authenticated users were granted {{publicReadActions}}, allowing them to read your secrets"
                },
                description: {
                    environmentVariables: [
                        "1 environment variable",
                        "{{count | NumberFormatter.humanize}} environment variables"
                    ],
                    text: "{{function}} is exposing secrets using {{secretExistsEnvironmentVariables}} in clear text"
                },
                sections: {
                    environmentVariables: "Environment Variables",
                    resolution: {
                        step1: {
                            link: "Secrets Manager",
                            text: "Migrate your secrets to {{secretManagerConsoleLink}}"
                        },
                        step2: "Delete all environment variables that expose secrets",
                        step3: "Click **Deploy** to deploy the new secured version"
                    }
                }
            }));
    const createPrincipalIdsProps =
        () => ({
            partialPrincipalIds:
                <InlineEntities
                    entityIdsOrModels={partialPrincipalIds}
                    entityTypeName={Contract.TypeNames.IGciPartialPrincipal}
                    variant="itemCountAndType"/>,
            principalIds:
                <InlineEntities
                    entityIdsOrModels={_.keys(risk.principalIdToExternalMap)}
                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                    variant="itemCountAndType"/>
        });

    const partialPrincipalIds =
        _(risk.principalIdToExternalMap).
            pickBy(external => external).
            keys().
            value();
    const getEnvironmentVariableId = (environmentVariableKey: string, environmentVariableType: Contract.GcpFunctionsFunctionEnvironmentVariableType) => `${environmentVariableType}-${environmentVariableKey}`;
    const environmentVariableIdToDataMap =
        _.keyBy(
            risk.environmentVariableDatas,
            environmentVariableData => getEnvironmentVariableId(environmentVariableData.key, environmentVariableData.type));
    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            function:
                <Entity
                    entityIdOrModel={functionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            secretExistsEnvironmentVariables:
                <InlineItems
                    items={functionEnvironmentVariableSecretExistsRiskModel.secretExistsEnvironmentVariables}
                    namePluralizer={localization.description.environmentVariables}
                    variant="itemCountAndType"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Variables}/>,
            localization.sections.resolution.step1.text({
                secretManagerConsoleLink:
                    <Link
                        urlOrGetUrl={functionEnvironmentVariableSecretExistsRiskModel.secretManagerConsoleUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const functionsRiskContext = getGcpFunctionsFunctionRiskContext(functionModel);
            return [
                functionsRiskContext.generalInformation,
                functionsRiskContext.sensitive,
                secretRiskContext.getSecretExistsContextItem(
                    risk.entityId,
                    Contract.TypeNames.GcpFunctionsFunction,
                    functionEnvironmentVariableSecretExistsRiskModel.secretExistsEnvironmentVariables),
                secretRiskContext.getExcludedSecretsContextItem(
                    _(risk.environmentVariableDatas).
                        filter(environmentVariableData => environmentVariableData.secretExcluded).
                        map(environmentVariableData => environmentVariableData.key).
                        value(),
                    risk.typeName,
                    risk.tenantId),
                _.isEmpty(risk.publicReadActions)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.publicReadActions({
                            publicReadActions:
                                <InlinePermissionActions
                                    permissionActions={risk.publicReadActions}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(risk.nonPublicReadActions)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        _.isEmpty(partialPrincipalIds)
                            ? localization.contextItems.nonPublicReadPermissionActions({
                                ...createPrincipalIdsProps(),
                                nonPublicReadPermissionActions:
                                    <InlinePermissionActions
                                        permissionActions={risk.nonPublicReadActions}
                                        variant="itemCountAndType"/>
                            })
                            : localization.contextItems.nonPublicReadPermissionActionsExternalPrincipals({
                                ...createPrincipalIdsProps(),
                                nonPublicReadPermissionActions:
                                    <InlinePermissionActions
                                        permissionActions={risk.nonPublicReadActions}
                                        variant="itemCountAndType"/>
                            })),
                functionsRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <GcpFunctionsFunctionEnvironmentVariableTable
                        environmentVariables={functionEntity.environmentVariables}
                        getHighlightColor={
                            (environmentVariable, opacity) =>
                                environmentVariableIdToDataMap[getEnvironmentVariableId(environmentVariable.key, environmentVariable.type)].secretExists
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        : theme.palette.severity(riskModel.risk.severity)
                                    : undefined}
                        risk={risk}/>,
                    localization.sections.environmentVariables())
            ]
        });
}