import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useMailUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useMailUpdateAuditEventDefinition",
            () => ({
                addedMails: "Added",
                mails: [
                    "1 email",
                    "{{count | NumberFormatter.humanize}} emails"
                ],
                removedMails: "Deleted"
            }));

    function getText(mails: string[]) {
        return mails.length === 1
            ? mails[0]
            : localization.mails(mails.length);
    }

    const mailUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.MailUpdateAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            _.isEmpty(mailUpdateAuditEvent.addedMails)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.addedMails(),
                    getText(mailUpdateAuditEvent.addedMails),
                    <InlineItems
                        items={mailUpdateAuditEvent.addedMails}
                        variant="itemPlusItemCount"/>),
            _.isEmpty(mailUpdateAuditEvent.removedMails)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.removedMails(),
                    getText(mailUpdateAuditEvent.removedMails),
                    <InlineItems
                        items={mailUpdateAuditEvent.removedMails}
                        variant="itemPlusItemCount"/>)
        ]));
}