import { UnexpectedError } from "@infrastructure";

export class CodeScanRiskHelper {
    public static getSideViewItemData(itemId: string) {
        const decodedItemId = decodeURIComponent(itemId);
        const delimiterIndex = decodedItemId.indexOf("~");

        if (delimiterIndex === -1) {
            throw new UnexpectedError("CodeScanRiskHelper.getSideViewItemData", itemId);
        }

        const itemIdParts = [decodedItemId.substring(0, delimiterIndex), decodedItemId.substring(delimiterIndex + 1)];

        return {
            codeScanId: itemIdParts[0],
            codeScanRiskId: itemIdParts[1]
        };
    }

    public static getSideViewItemId(codeScanId: string, codeScanRiskId: string) {
        return `${codeScanId}~${codeScanRiskId}`;
    }
}