﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";

export function useKubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterKubeletAuthorizationModeAlwaysAllowRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.kubelets.useKubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskDefinition",
            () => ({
                contextItems: {
                    anonymousAuthenticationNodes: "The cluster has {{nodes}} in which anonymous requests to Kubelet servers are enabled",
                    authorizationModeAlwaysAllowNodes: [
                        "The cluster has {{nodes}} that is allowing all authenticated requests to Kubelet servers",
                        "The cluster has {{nodes}} that are allowing all authenticated requests to Kubelet servers"
                    ],
                    debugApiNodes: "The cluster has {{nodes}} in which the Kubelet server debugging handlers are enabled",
                    inboundExternalPortNodes: "The cluster has {{nodes}} in which the Kubelet server port is open to the internet"
                },
                description: "{{cluster}} allows all authenticated requests sent to Kubelet servers",
                resolutionSection: {
                    step1: "For the following {{nodes}}, ensure that the Kubelet's --authorization-mode argument is not set to **AlwaysAllow**"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1({
                nodes:
                    <InlineEntities
                        entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                        entityTypeName={Contract.TypeNames.IKubernetesNode}
                        variant="itemCountAndType"/>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);
            const anonymousAuthenticationNodeIds =
                _(risk.nodeDatas).
                    filter(nodeData => nodeData.anonymousAuthentication).
                    map(nodeData => nodeData.nodeOrVirtualMachineId).
                    value();
            const debugApiNodeIds =
                _(risk.nodeDatas).
                    filter(nodeData => nodeData.debugApi).
                    map(nodeData => nodeData.nodeOrVirtualMachineId).
                    value();
            const inboundExternalPortNodeIds =
                _(risk.nodeDatas).
                    filter(nodeData => !_.isNil(nodeData.inboundExternalPort)).
                    map(nodeData => nodeData.nodeOrVirtualMachineId).
                    value();

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                new RiskDefinitionContextItem(
                    localization.contextItems.authorizationModeAlwaysAllowNodes(
                        _.size(risk.nodeOrVirtualMachineIds),
                        {
                            nodes:
                                <InlineEntities
                                    entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNode}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(inboundExternalPortNodeIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.inboundExternalPortNodes({
                            nodes:
                                <InlineEntities
                                    entityIdsOrModels={inboundExternalPortNodeIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNode}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(anonymousAuthenticationNodeIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.anonymousAuthenticationNodes({
                            nodes:
                                <InlineEntities
                                    entityIdsOrModels={anonymousAuthenticationNodeIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNode}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(debugApiNodeIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.debugApiNodes({
                            nodes:
                                <InlineEntities
                                    entityIdsOrModels={debugApiNodeIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNode}
                                    variant="itemCountAndType"/>
                        })),
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}