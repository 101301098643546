import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NewIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            {...props}>
            <path
                color="currentColor"
                d="M2.5 12c0-4.478 0-6.718 1.391-8.109S7.521 2.5 12 2.5c4.478 0 6.718 0 8.109 1.391S21.5 7.521 21.5 12c0 4.478 0 6.718-1.391 8.109S16.479 21.5 12 21.5c-4.478 0-6.718 0-8.109-1.391S2.5 16.479 2.5 12M12 8v8m4-4H8"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5">
            </path>
        </SvgIcon>);
}