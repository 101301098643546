import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { useTheme } from "../../..";

type SelectedValueProps = {
    children: ReactNode;
};

export function SelectedValue({ children }: SelectedValueProps) {
    const theme = useTheme();
    return (
        <Typography
            noWrap={true}
            sx={{
                display: "inline-block",
                maxWidth: theme.spacing(30),
                textDecoration: "underline",
                verticalAlign: "bottom"
            }}>
            {children}
        </Typography>);
}