import { UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { RiskPoliciesType, RiskPoliciesView } from "../../../../../common";
import { useItems } from "./useItems";

export function useRiskPoliciesViewItems(riskPoliciesType: RiskPoliciesType, riskPoliciesView: RiskPoliciesView, childScopeCustomPoliciesEnabled: boolean, scopeId: string) {
    const items = useItems(riskPoliciesType, childScopeCustomPoliciesEnabled, scopeId);
    const [customItems, builtInItems] =
        useMemo(
            () =>
                _.partition(
                    items,
                    item => item.custom),
            [items]);

    const riskPoliciesViewItems =
        useMemo(
            () => {
                switch (riskPoliciesView) {
                    case RiskPoliciesView.BuiltIn:
                        return builtInItems;
                    case RiskPoliciesView.Custom:
                        return customItems;
                    default:
                        throw new UnexpectedError("useRiskPoliciesViewItems.riskPoliciesView", riskPoliciesView);
                }
            },
            [builtInItems, customItems, riskPoliciesView]);

    return riskPoliciesViewItems;
}