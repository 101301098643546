import { Optional, useInputValidation, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, FormControl, FormHelperText, InputAdornment, Stack, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";

type AddProps = {
    disabled: boolean;
    executing: boolean;
    onSave: (value: string) => void;
    onValidate: (value: string) => Optional<string>;
    placeholder: string;
};

export function Add({ disabled, executing, onSave, onValidate, placeholder }: AddProps) {
    const [value, setValue] = useState("");

    const [valueValidationController, valueValidationMessage] =
        useInputValidation(
            () => onValidate(value.trim()),
            [value]);

    const localization =
        useLocalization(
            "infrastructure.inputs.valuesField.add",
            () => ({
                actions: {
                    add: "Add"
                }
            }));

    const theme = useTheme();
    return (
        <FormControl
            fullWidth={true}
            variant="standard">
            <Stack direction="row">
                <TextField
                    fullWidth={true}
                    hiddenLabel={true}
                    placeholder={placeholder}
                    size="small"
                    slotProps={{
                        input: {
                            endAdornment:
                                executing &&
                                <InputAdornment position="start">
                                    <CircularProgress
                                        size={theme.spacing(3)}
                                        variant="indeterminate"/>
                                </InputAdornment>,
                            sx: { borderRadius: theme.spacing(0.75, 0, 0) }
                        }
                    }}
                    value={value}
                    variant="outlined"
                    onChange={event => setValue(event.target.value)}/>
                <Button
                    disabled={!valueValidationController.isValid() || disabled}
                    sx={{ borderRadius: theme.spacing(0, 0.75, 0, 0) }}
                    onClick={
                        () => {
                            onSave(value);
                            setValue("");
                            valueValidationController.clear();
                        }}>
                    {localization.actions.add()}
                </Button>
            </Stack>
            <Box
                sx={{
                    "&:hover": {
                        borderLeft: theme.border.hoverFocus,
                        borderRight: theme.border.hoverFocus
                    },
                    borderLeft: theme.border.primary,
                    borderRight: theme.border.primary,
                    paddingLeft: theme.spacing(1)
                }}>
                {valueValidationMessage &&
                    <FormHelperText error={true}>
                        {valueValidationMessage}
                    </FormHelperText>}
            </Box>
        </FormControl>);
}