﻿import { EmptyMessage, TextViewer, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";

type AwsCloudFormationStackTemplateProps = {
    template?: string;
};

export function AwsCloudFormationStackTemplateTextViewer({ template }: AwsCloudFormationStackTemplateProps) {
    const templateFormat =
        useMemo(
            () => {
                if (_.isNil(template)) {
                    return;
                }

                try {
                    JSON.parse(template);
                    return "json";
                } catch {
                    return "yaml";
                }
            },
            [template]);

    const localization =
        useLocalization(
            "tenants.aws.awsCloudFormationStackTemplateTextViewer",
            () => ({
                empty: "No Template"
            }));

    return (
        _.isNil(template)
            ? <EmptyMessage
                message={localization.empty()}
                verticalCenter={true}/>
            : <TextViewer
                format={templateFormat!}
                text={template}/>);
}