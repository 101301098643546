import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, EntitiesCell, EntityController, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureKeyVaultVaultDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultDefinition",
            () => ({
                columns: {
                    rbacEnabled: {
                        false: "Vault access policy",
                        title: "Permission Model",
                        true: "Azure role-based access control"
                    },
                    vaultObjects: "Objects"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureKeyVaultVaultModel) => ({
                            [localization.columns.rbacEnabled.title()]:
                                (item.entity as Contract.AzureKeyVaultVault).rbacEnabled
                                    ? localization.columns.rbacEnabled.true()
                                    : localization.columns.rbacEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.rbacEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.rbacEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.rbacEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureKeyVaultVaultRbacEnabled}
                itemProperty={
                    (item: Contract.AzureKeyVaultVaultModel) =>
                        (item.entity as Contract.AzureKeyVaultVault).rbacEnabled
                            ? localization.columns.rbacEnabled.true()
                            : localization.columns.rbacEnabled.false()}
                key={Contract.EntityModelProperty.AzureKeyVaultVaultRbacEnabled}
                title={localization.columns.rbacEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureKeyVaultVaultModel) => ({
                            [localization.columns.vaultObjects()]: item.vaultObjectCount
                        })
                }}
                id={Contract.EntityModelProperty.AzureKeyVaultVaultObjects}
                key={Contract.EntityModelProperty.AzureKeyVaultVaultObjects}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureKeyVaultVaultModel>) =>
                        <EntitiesCell
                            deferredEntities={{
                                count: item.vaultObjectCount,
                                firstItem: item.firstVaultObjectId,
                                getItems:
                                    async () => {
                                        const { certificateIds, keyIds, secretIds } = await EntityController.getAzureKeyVaultVaultObjectIds(new Contract.EntityControllerGetAzureKeyVaultVaultObjectIdsRequest(item.id));
                                        return _(certificateIds).
                                            concat(keyIds).
                                            concat(secretIds).
                                            value();
                                    }
                            }}
                            entityTypeName={Contract.TypeNames.AzureKeyVaultVaultObject}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.vaultObjects()}/>,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureKeyVaultVaultRequest(
                new Contract.EntityControllerGetEntityModelPageAzureKeyVaultVaultRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultRbacEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}