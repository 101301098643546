import { ActionButton, defined, Dialog, ResetZoomIcon, useWindowEventEffect, ZoomInIcon, ZoomOutIcon } from "@infrastructure";
import { Box, Stack, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useLayoutEffect, useRef, useState } from "react";
import { makeContextProvider } from "../../utilities/makeContextProvider";
import { Column, Edge, Node } from "./components";
import { DirectedGraphLayout, DirectedGraphModel, DirectedGraphZoom } from "./utilities";

type DirectedGraphProps = {
    dense?: boolean;
    legendElement?: ReactNode;
    model: DirectedGraphModel;
};

export class DirectedGraphContext {
    constructor(
        public layout: DirectedGraphLayout,
        public dialogContentElement?: ReactNode,
        public dialogContentElementSize?: "content" | "large" | "medium" | "small") {
    }
}

export const [, useSetDirectedGraphContext, useDirectedGraphContextProvider] = makeContextProvider<DirectedGraphContext>();

export function DirectedGraph({ dense = false, legendElement, model }: DirectedGraphProps) {
    const [context, setContext, ContextProvider] = useDirectedGraphContextProvider();
    const [layout, setLayout] = useState<DirectedGraphLayout>();
    const [, render] = useState({});

    function buildLayout() {
        const layout =
            new DirectedGraphLayout(
                model,
                dense
                    ? 1
                    : 2,
                () => render({}));
        setLayout(layout);
        setContext(new DirectedGraphContext(layout));
    }

    useLayoutEffect(
        () => {
            buildLayout();
        },
        [model]);
    useWindowEventEffect("resize", buildLayout);

    const zoomRef = useRef<DirectedGraphZoom>();
    const svgElementRef = useRef<SVGSVGElement | null>(null);
    const groupElementRef = useRef<SVGGElement | null>(null);
    useLayoutEffect(
        () => {
            if (_.isNil(layout) ||
                !_.isNil(zoomRef.current)) {
                return;
            }

            zoomRef.current =
                new DirectedGraphZoom(
                    defined(svgElementRef.current),
                    defined(groupElementRef.current));

            zoomRef.current.fitToView();
        },
        [layout]);

    const theme = useTheme();
    return (
        <Fragment>
            {!_.isNil(context?.dialogContentElement) && (
                <Dialog
                    size={context?.dialogContentElementSize}
                    variant="viewer"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    dialogContentElement: undefined
                                }))}>
                    <Box sx={{ height: "60vh" }}>
                        {context.dialogContentElement}
                    </Box>
                </Dialog>)}
            <Box
                sx={{
                    height: "100%",
                    position: "relative",
                    width: "100%"
                }}>
                <Stack
                    spacing={1}
                    sx={{
                        position: "absolute",
                        right: 0,
                        top: theme.spacing(2)
                    }}>
                    <ActionButton
                        size="medium"
                        variant="outlined"
                        onClick={() => zoomRef.current?.fitToView()}>
                        <ResetZoomIcon sx={{ fontSize: "16px" }}/>
                    </ActionButton>
                    <ActionButton
                        size="medium"
                        variant="outlined"
                        onClick={() => zoomRef.current?.zoomIn()}>
                        <ZoomInIcon sx={{ fontSize: "16px" }}/>
                    </ActionButton>
                    <ActionButton
                        size="medium"
                        variant="outlined"
                        onClick={() => zoomRef.current?.zoomOut()}>
                        <ZoomOutIcon sx={{ fontSize: "16px" }}/>
                    </ActionButton>
                </Stack>
                {!_.isNil(legendElement) &&
                    <Box
                        sx={{
                            background: theme.palette.background.paper,
                            border: theme.border.primary,
                            borderRadius: theme.spacing(0.75),
                            left: 0,
                            padding: theme.spacing(1),
                            position: "absolute",
                            top: theme.spacing(2),
                            width: "unset"
                        }}>
                        {legendElement}
                    </Box>}
                <svg
                    height="100%"
                    ref={svgElementRef}
                    viewBox={`0 0 ${layout?.getDimensions().width ?? 0} ${layout?.getDimensions().height ?? 0}`}
                    width="100%">
                    <g ref={groupElementRef}>
                        <ContextProvider>
                            {!_.isNil(layout) &&
                                <Fragment>
                                    {_.map(
                                        layout.columns,
                                        layoutColumn =>
                                            <Column
                                                key={layoutColumn.modelColumn.id}
                                                layoutColumn={layoutColumn}/>)}
                                    {_.map(
                                        layout.edges,
                                        layoutEdge =>
                                            <Edge
                                                key={layoutEdge.modelEdge.id}
                                                layoutEdge={layoutEdge}/>)}
                                    {_.map(
                                        layout.nodes,
                                        layoutNode =>
                                            <Node
                                                key={layoutNode.modelNode.id}
                                                layoutNode={layoutNode}/>)}
                                </Fragment>}
                        </ContextProvider>
                    </g>
                </svg>
            </Box>
        </Fragment>);
}