import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../common";

export function useAwsTenantModelTrailStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsTenantModelTrailStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsTenantModelTrailStatus]: {
                    [Contract.AwsTenantModelTrailStatus.AccessDenied]: "Missing permissions",
                    [Contract.AwsTenantModelTrailStatus.BucketAccessError]: "Bucket data is inaccessible",
                    [Contract.AwsTenantModelTrailStatus.DuplicateEvents]: "Duplicate events",
                    [Contract.AwsTenantModelTrailStatus.NotConfigured]: "Not configured",
                    [Contract.AwsTenantModelTrailStatus.Valid]: "Connected",
                    [Contract.AwsTenantModelTrailStatus.ValidationFailure]: "Invalid CloudTrail configured"
                }
            }));
    return (status: Contract.AwsTenantModelTrailStatus) => localization[Contract.TypeNames.AwsTenantModelTrailStatus][status]();
}