import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../..";

export function useAzureDeleteSystemManagedIdentityChangeDefinition(change: Contract.AzureChange, entityLinkDisabled?: boolean) {
    const deleteManagedIdentityChange = change as Contract.AzureDeleteManagedIdentityChange;
    const manageIdentity = entityModelStore.useGet(deleteManagedIdentityChange.resourceId).entity as Contract.AzureManagedIdentityManagedIdentity;
    const servicePrincipalModel = entityModelStore.useGet(manageIdentity.servicePrincipalId) as Contract.AadDirectoryServicePrincipalModel;

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAzureDefinition.hooks.useAzureDeleteSystemManagedIdentityChangeDefinition",
            () => ({
                title: "Delete {{servicePrincipal}}"
            }));

    return {
        title:
            localization.title({
                servicePrincipal:
                    <Entity
                        entityIdOrModel={servicePrincipalModel}
                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}