import { Action1, CollapsedIcon, DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, Loading, Optional, StringHelper, TextValuesFilter, useChangeEffect, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Severity, SeverityFilter, TypeHelper, useRiskPolicyCategoryTranslator, useTableDefinition, useTenantTypeTranslator, useTheme } from "../../../../../common";
import { Contract } from "../../../../controllers";
import { TenantsIcon } from "../../../../icons";
import { CustomRiskPolicyCell } from "./components";

type ItemProps = {
    category: Contract.RiskPolicyCategory;
    items: ItemItem[];
    onSelectedItemIdsChanged: Action1<string[]>;
    selectedItemIds: string[];
};

export class ItemItem {
    constructor(
        public description: string,
        public id: string,
        public name: string,
        public severity: Optional<Contract.Severity>,
        public tenantTypes: Contract.TenantType[]) {
    }
}

export function Item({ category, items, onSelectedItemIdsChanged, selectedItemIds }: ItemProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    useEffect(
        () => {
            if (!_.isEmpty(selectedItemIds)) {
                dataTableActionsRef.current!.setSelectedItemIds(selectedItemIds);
            }
        },
        []);

    useChangeEffect(
        () => dataTableActionsRef.current?.reset(),
        [items]);

    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "common.riskPolicySelect.item",
            () => ({
                columns: {
                    customTemplate: "Template",
                    policy: "Policy",
                    severity: {
                        empty: "Dynamic",
                        title: "Severity"
                    },
                    tenantType: "Platform"
                },
                empty: {
                    withFilter: "No matching policies",
                    withoutFilter: "No policies"
                },
                title: "{{translatedRiskPolicyCategory}} ({{selectedItemCount}} selected)"
            }));

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ItemTableColumnId.Policy]: item => item.name,
                [ItemTableColumnId.Severity]: {
                    getFilterValue: item => item.severity,
                    getSortValue:
                        item =>
                            _.isNil(item.severity)
                                ? 0
                                : TypeHelper.getEnumValue(Contract.TypeNames.Severity, item.severity) + 1
                },
                [ItemTableColumnId.TenantTypes]: {
                    getFilterValue: item => item.tenantTypes,
                    getSortValue: item => StringHelper.getCombineSortValue(item.tenantTypes.length, ...item.tenantTypes)
                }
            },
            [ItemTableColumnId.TenantTypes, ItemTableColumnId.Policy],
            {
                getItemId: item => item.id
            });

    const theme = useTheme();
    return (
        <Accordion>
            <AccordionSummary expandIcon={<CollapsedIcon/>}>
                <Typography variant="h5">
                    {_.isEmpty(selectedItemIds)
                        ? riskPolicyCategoryTranslator(category)
                        : localization.title({
                            selectedItemCount: _.size(selectedItemIds),
                            translatedRiskPolicyCategory: riskPolicyCategoryTranslator(category)
                        })}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ border: 0 }}>
                <Loading>
                    <DataTable
                        actionsRef={dataTableActionsRef}
                        emptyMessageOptions={{
                            emptyMessageText:
                                new EmptyMessageText(
                                    localization.empty.withoutFilter(),
                                    localization.empty.withFilter())
                        }}
                        fetchItems={tableDefinition.filterAndSortItems}
                        filtersOptions={{
                            persist: {
                                filterMap: false
                            },
                            sx: { position: "relative" }
                        }}
                        getItemId={(item: ItemItem) => item.id}
                        selectionOptions={{
                            onChanged: selectedItemIds => onSelectedItemIdsChanged(selectedItemIds),
                            persistence: true,
                            showActions: false
                        }}
                        sortOptions={{
                            enabled: true,
                            reload: true
                        }}
                        variant="card">
                        <DataTableColumn
                            cellSx={{ width: theme.spacing(8) }}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValuesFilter placeholder={localization.columns.tenantType()}>
                                            {_.map(
                                                tableDefinition.columnIdToItemValuesMap[ItemTableColumnId.TenantTypes],
                                                tenantType =>
                                                    <ValuesFilterItem
                                                        key={tenantType}
                                                        title={tenantTypeTranslator(tenantType)}
                                                        value={tenantType}/>)}
                                        </ValuesFilter>
                                }
                            }}
                            id={ItemTableColumnId.TenantTypes}
                            render={
                                ({ item }: DataTableColumnRenderProps<ItemItem>) =>
                                    <TenantsIcon
                                        sx={{ fontSize: "18px" }}
                                        tenantTypes={item.tenantTypes}/>}
                            title={localization.columns.tenantType()}/>
                        <DataTableColumn
                            cellMaxWidth="medium"
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <TextValuesFilter
                                            placeholder={localization.columns.policy()}
                                            values={tableDefinition.columnIdToItemValuesMap[ItemTableColumnId.Policy]}/>
                                }
                            }}
                            id={ItemTableColumnId.Policy}
                            itemProperty={(item: ItemItem) => item.name}
                            title={localization.columns.policy()}/>
                        {category === Contract.RiskPolicyCategory.Custom && (
                            <DataTableColumn
                                id={ItemTableColumnId.CustomRiskPolicy}
                                render={CustomRiskPolicyCell}
                                sortOptions={{ enabled: false }}
                                title={localization.columns.customTemplate()}/>)}
                        {category !== Contract.RiskPolicyCategory.Behavior && (
                            <DataTableColumn
                                cellSx={{ width: theme.spacing(8) }}
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <SeverityFilter
                                                emptyValueOptions={{
                                                    enabled: category !== Contract.RiskPolicyCategory.Custom,
                                                    title: localization.columns.severity.empty()
                                                }}
                                                placeholder={localization.columns.severity.title()}/>
                                    }
                                }}
                                id={ItemTableColumnId.Severity}
                                render={
                                    ({ item }: DataTableColumnRenderProps<ItemItem>) =>
                                        <Severity
                                            dynamicRiskPolicyConfigurationTypeName={item.id}
                                            severity={item.severity}/>}
                                sortOptions={{ type: DataTableSortType.Numeric }}
                                title={localization.columns.severity.title()}/>)}
                    </DataTable>
                </Loading>
            </AccordionDetails>
        </Accordion>);
}

enum ItemTableColumnId {
    CustomRiskPolicy = "customRiskPolicy",
    Policy = "policy",
    Severity = "severity",
    TenantTypes = "tenantTypes"
}