import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciLoadBalancingLoadBalancerSslProtocolTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciLoadBalancingLoadBalancerSslProtocolTranslator",
            () => ({
                [Contract.TypeNames.OciLoadBalancingLoadBalancerSslProtocol]: {
                    [Contract.OciLoadBalancingLoadBalancerSslProtocol.TlsV1]: "TLSv1",
                    [Contract.OciLoadBalancingLoadBalancerSslProtocol.TlsV11]: "TLSv1.1",
                    [Contract.OciLoadBalancingLoadBalancerSslProtocol.TlsV12]: "TLSv1.2",
                    [Contract.OciLoadBalancingLoadBalancerSslProtocol.TlsV13]: "TLSv1.3"
                }
            }));
    return (sslProtocol: Contract.OciLoadBalancingLoadBalancerSslProtocol) =>
        localization[Contract.TypeNames.OciLoadBalancingLoadBalancerSslProtocol][sslProtocol]();
}