import { TranslateOptions, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React from "react";

interface ExploitableValueFilterLocalization {
    false: (options?: TranslateOptions) => string;
    title: (options?: TranslateOptions) => string;
    true: (options?: TranslateOptions) => string;
}

interface ExploitableValueFilterProps {
    localization: ExploitableValueFilterLocalization;
}

export function ExploitableValueFilter({ localization }: ExploitableValueFilterProps) {
    return <ValuesFilter
        placeholder={localization.title()}
        sorted={false}>
        <ValuesFilterItem
            title={localization.true()}
            value={true}/>
        <ValuesFilterItem
            title={localization.false()}
            value={false}/>
    </ValuesFilter>;
}