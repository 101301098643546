import { Button, FormControl, FormHelperText, InputBase, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useEffect, useRef } from "react";
import { CheckboxField, Link, Message, PasswordTextField, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { useAddOrEditContext, useSetAddOrEditContext } from "../..";
import { CustomerConsoleAppUrlHelper, useTheme } from "../../../../../../../../../../../../../../../common";
import { FolderEnabled } from "../../../../../../../../../components";

export function OrganizationDetailsItem() {
    const { executing, setValid } = useOrderedWizardContext();
    const { enabled, existingOrganizationModel, folderEnabled, homeRegionSystemName, ocid, userOcid, userPrivateKeyPem, userPublicKeyFingerprint } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.oci.addOrEdit.organizationDetailsItem",
            () => ({
                fields: {
                    homeRegionSystemName: {
                        error: {
                            required: "Region cannot be empty"
                        },
                        placeholder: "us-ashburn-1",
                        subtitle: "The Oracle Cloud Infrastructure region",
                        title: "Region"
                    },
                    ocid: {
                        error: {
                            format: "Invalid Tenancy OCID",
                            required: "Tenancy OCID cannot be empty"
                        },
                        placeholder: "ocid1.tenancy.oc1..xxx",
                        subtitle: "The OCID of your tenancy",
                        title: "Tenancy OCID"
                    },
                    settings: {
                        enabled: {
                            info: "Uncheck this option to prevent new compartments that you add in OCI from being automatically onboarded to Tenable.",
                            title: "Automatically onboard new compartments"
                        },
                        folderEnabled: {
                            info: "Mirror your compartment's structure as folders within Tenable, preserving its hierarchy and keeping the view synchronized.",
                            title: "Automatically update folder structure"
                        },
                        title: "Onboarding Settings"
                    },
                    userOcid: {
                        error: {
                            format: "Invalid User OCID",
                            required: "User OCID cannot be empty"
                        },
                        placeholder: "ocid1.user.oc1..xxx",
                        subtitle: "The OCID of the user you created for Tenable",
                        title: "User OCID"
                    },
                    userPrivateKeyPem: {
                        error: {
                            required: "Private key cannot be empty"
                        },
                        subtitle: "The PEM file for the private API key",
                        title: "Private Key",
                        upload: "Upload"
                    },
                    userPublicKeyFingerprint: {
                        error: {
                            required: "Fingerprint cannot be empty"
                        },
                        placeholder: "aa:bb:cc:dd:ee:ff:11:22:33:44:55:66:77:88:99:00",
                        subtitle: "The fingerprint for the public key that was added to the user",
                        title: "Fingerprint"
                    }
                },
                subtitle: {
                    links: {
                        documentationOnboardOracleCloud: "documentation"
                    },
                    text: "Use the {{documentationOnboardOracleCloudLink}} to learn how to access the credentials required to onboard your tenancy."
                }
            }));


    const [homeRegionSystemNameValidationController, homeRegionSystemNameValidationMessage] =
        useInputValidation(
            () => {
                const validationHomeRegionSystemName = homeRegionSystemName?.trim();
                if (_.isEmpty(validationHomeRegionSystemName)) {
                    return localization.fields.homeRegionSystemName.error.required();
                }

                return undefined;
            },
            [homeRegionSystemName]);

    const [ocidValidationController, ocidValidationMessage] =
        useInputValidation(
            () => {
                const validationOcid = ocid?.trim();
                if (_.isEmpty(validationOcid)) {
                    return localization.fields.ocid.error.required();
                }
                if (!/^ocid1.tenancy.oc([0-9]+)..([0-9a-zA-Z-_]+)$/.test(validationOcid)) {
                    return localization.fields.ocid.error.format();
                }

                return undefined;
            },
            [ocid]);

    const [userOcidValidationController, userOcidValidationMessage] =
        useInputValidation(
            () => {
                const validationUserOcid = userOcid?.trim();
                if (_.isEmpty(validationUserOcid)) {
                    return localization.fields.userOcid.error.required();
                }
                if (!/^ocid1.user.oc([0-9]+)..([0-9a-zA-Z-_]+)$/.test(validationUserOcid)) {
                    return localization.fields.userOcid.error.format();
                }

                return undefined;
            },
            [userOcid]);

    const [userPrivateKeyPemValidationController, userPrivateKeyPemValidationMessage] =
        useInputValidation(
            () => {
                const validationUserPrivateKeyPem = userPrivateKeyPem?.trim();
                if (_.isNil(existingOrganizationModel) &&
                    _.isEmpty(validationUserPrivateKeyPem)) {
                    return localization.fields.userPrivateKeyPem.error.required();
                }

                return undefined;
            },
            [userPrivateKeyPem]);

    const [userPublicKeyFingerprintValidationController, userPublicKeyFingerprintValidationMessage] =
        useInputValidation(
            () => {
                const validationUserPublicKeyFingerprint = userPublicKeyFingerprint?.trim();
                if (_.isEmpty(validationUserPublicKeyFingerprint)) {
                    return localization.fields.userPublicKeyFingerprint.error.required();
                }

                return undefined;
            },
            [userPublicKeyFingerprint]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    enabled,
                    folderEnabled,
                    homeRegionSystemName,
                    ocid,
                    userOcid,
                    userPrivateKeyPem,
                    userPublicKeyFingerprint
                }));
        },
        [enabled, folderEnabled, homeRegionSystemName, ocid, userOcid, userPrivateKeyPem, userPublicKeyFingerprint]);

    useEffect(
        () => {
            setValid(
                homeRegionSystemNameValidationController.isValid() &&
                ocidValidationController.isValid() &&
                userOcidValidationController.isValid() &&
                userPrivateKeyPemValidationController.isValid() &&
                userPublicKeyFingerprintValidationController.isValid());
        },
        [enabled, folderEnabled, homeRegionSystemName, ocid, userOcid, userPrivateKeyPem, userPublicKeyFingerprint]);

    const uploadPrivateKeyFileButtonRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{
                maxWidth: theme.spacing(80),
                paddingBottom: theme.spacing(3)
            }}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.subtitle.text({
                    documentationOnboardOracleCloudLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardOracleCloudRelativeUrl()}
                            variant="external">
                            {localization.subtitle.links.documentationOnboardOracleCloud()}
                        </Link>
                })}
            </Typography>
            <Stack spacing={2}>
                <DetailsItemSection
                    subtitle={localization.fields.userOcid.subtitle()}
                    title={localization.fields.userOcid.title()}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            placeholder={localization.fields.userOcid.placeholder()}
                            value={userOcid}
                            variant="outlined"
                            onChange={
                                event =>
                                    setAddOrEditContext(
                                        addOrEditContext => ({
                                            ...addOrEditContext,
                                            userOcid: event.target.value
                                        }))}/>
                        {!_.isNil(userOcidValidationMessage) &&
                            <FormHelperText error={true}>
                                {userOcidValidationMessage}
                            </FormHelperText>}
                    </FormControl>
                </DetailsItemSection>
                <DetailsItemSection
                    subtitle={localization.fields.userPublicKeyFingerprint.subtitle()}
                    title={localization.fields.userPublicKeyFingerprint.title()}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            placeholder={localization.fields.userPublicKeyFingerprint.placeholder()}
                            value={userPublicKeyFingerprint}
                            variant="outlined"
                            onChange={
                                event => {
                                    setAddOrEditContext(
                                        addOrEditContext => ({
                                            ...addOrEditContext,
                                            ...(_.isNil(userPrivateKeyPem) && {
                                                userPrivateKeyPem: ""
                                            }),
                                            userPublicKeyFingerprint: event.target.value
                                        }));
                                }}/>
                        {!_.isNil(userPublicKeyFingerprintValidationMessage) &&
                            <FormHelperText error={true}>
                                {userPublicKeyFingerprintValidationMessage}
                            </FormHelperText>}
                    </FormControl>
                </DetailsItemSection>
                <DetailsItemSection
                    subtitle={localization.fields.ocid.subtitle()}
                    title={localization.fields.ocid.title()}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            autoFocus={_.isNil(existingOrganizationModel)}
                            disabled={executing || !_.isNil(existingOrganizationModel)}
                            placeholder={localization.fields.ocid.placeholder()}
                            slotProps={{
                                input: {
                                    readOnly: !_.isNil(existingOrganizationModel)
                                }
                            }}
                            value={ocid}
                            variant="outlined"
                            onChange={
                                event =>
                                    setAddOrEditContext(
                                        addOrEditContext => ({
                                            ...addOrEditContext,
                                            ocid: event.target.value
                                        }))}/>
                        {!_.isNil(ocidValidationMessage) && (
                            <FormHelperText error={true}>{ocidValidationMessage}</FormHelperText>)}
                    </FormControl>
                </DetailsItemSection>
                <DetailsItemSection
                    subtitle={localization.fields.homeRegionSystemName.subtitle()}
                    title={localization.fields.homeRegionSystemName.title()}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing || !_.isNil(existingOrganizationModel)}
                            placeholder={localization.fields.homeRegionSystemName.placeholder()}
                            slotProps={{
                                input: {
                                    readOnly: !_.isNil(existingOrganizationModel)
                                }
                            }}
                            value={homeRegionSystemName}
                            variant="outlined"
                            onChange={
                                event =>
                                    setAddOrEditContext(
                                        addOrEditContext => ({
                                            ...addOrEditContext,
                                            homeRegionSystemName: event.target.value
                                        }))}/>
                        {!_.isNil(homeRegionSystemNameValidationMessage) &&
                            <FormHelperText error={true}>{homeRegionSystemNameValidationMessage}</FormHelperText>}
                    </FormControl>
                </DetailsItemSection>
                <DetailsItemSection
                    subtitle={localization.fields.userPrivateKeyPem.subtitle()}
                    title={localization.fields.userPrivateKeyPem.title()}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}>
                        <FormControl
                            fullWidth={true}
                            variant="standard">
                            <InputBase
                                inputProps={{ accept: ".pem" }}
                                inputRef={uploadPrivateKeyFileButtonRef}
                                sx={{ display: "none" }}
                                type="file"
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                        const pemFilePath = event.target.files?.item(0);
                                        if (pemFilePath) {
                                            const fileReader = new FileReader();
                                            fileReader.readAsText(pemFilePath);
                                            fileReader.onloadend =
                                                () => {
                                                    const pem = fileReader.result as string;
                                                    setAddOrEditContext(
                                                        addOrEditContext => ({
                                                            ...addOrEditContext,
                                                            userPrivateKeyPem: pem
                                                        }));
                                                };
                                        }
                                    }}/>
                            <PasswordTextField
                                password={userPrivateKeyPem}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"/>
                            {!_.isNil(userPrivateKeyPemValidationMessage) &&
                                <FormHelperText error={true}>{userPrivateKeyPemValidationMessage}</FormHelperText>}
                        </FormControl>
                        <Button
                            variant="contained"
                            onClick={() => uploadPrivateKeyFileButtonRef.current!.click()}>
                            {localization.fields.userPrivateKeyPem.upload()}
                        </Button>
                    </Stack>
                </DetailsItemSection>
            </Stack>
            <Stack spacing={1}>
                <Typography variant="h4">
                    {localization.fields.settings.title()}
                </Typography>
                {!_.isNil(existingOrganizationModel) &&
                    <CheckboxField
                        checked={enabled}
                        onChange={
                            (_event, checked) =>
                                setAddOrEditContext(
                                    addOrEditContext => ({
                                        ...addOrEditContext,
                                        enabled: checked
                                    }))}>
                        <Stack
                            direction="row"
                            justifyItems="center"
                            spacing={1}>
                            <Typography>
                                {localization.fields.settings.enabled.title()}
                            </Typography>
                            <Message
                                level="info"
                                title={localization.fields.settings.enabled.info()}
                                variant="minimal"/>
                        </Stack>
                    </CheckboxField>}
                <FolderEnabled
                    folderEnabled={folderEnabled}
                    info={localization.fields.settings.folderEnabled.info()}
                    setFolderEnabled={
                        item =>
                            setAddOrEditContext(
                                addOrEditContext => ({
                                    ...addOrEditContext,
                                    folderEnabled: item
                                }))}
                    title={localization.fields.settings.folderEnabled.title()}/>
            </Stack>
        </Stack>);
}

type DetailsItemSectionProps = {
    children: ReactNode;
    subtitle: string;
    title: string;
};

function DetailsItemSection({ children, subtitle, title }: DetailsItemSectionProps) {
    return (
        <Stack spacing={0.5}>
            <Typography variant="h4">
                {title}
            </Typography>
            <Typography variant="subtitle1">
                {subtitle}
            </Typography>
            {children}
        </Stack>);
}