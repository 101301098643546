import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements, useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpLoggingLogSinkDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonLoggingLogSinkDestinationResourceInfoItemElements = useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements(scopeResourceModel);
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const logSinkModel = scopeResourceModel as Contract.GcpLoggingLogSinkModel;
    const logSink = logSinkModel.entity as Contract.GcpLoggingLogSink;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpLoggingLogSinkDefinition",
            () => ({
                info: {
                    items: {
                        childResourceAggregation: {
                            false: "Disabled",
                            title: "Include child resources",
                            true: "Enabled"
                        },
                        description: "Description",
                        destinationIdReference: "Destination",
                        enabled: {
                            false: "Disabled",
                            title: "Status",
                            true: "Enabled"
                        },
                        exclusionNameToFilterMap: {
                            items: [
                                "1 exclusion filter",
                                "{{count | NumberFormatter.humanize}} exclusion filters"
                            ],
                            title: "Exclusion filters"
                        },
                        inclusionFilter: "Inclusion filter"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    commonLoggingLogSinkDestinationResourceInfoItemElements.loggingLogSinks,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={logSink.description}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={logSinkModel.destinationIdReference}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        key="destinationIdReference"
                        location="all"
                        title={localization.info.items.destinationIdReference()}/>,
                    <InfoItem
                        key="enabled"
                        location="all"
                        title={localization.info.items.enabled.title()}
                        value={
                            logSink.enabled
                                ? localization.info.items.enabled.true()
                                : localization.info.items.enabled.false()}/>,
                    <InfoItem
                        key="inclusionFilter"
                        title={localization.info.items.inclusionFilter()}
                        value={logSink.inclusionFilter}/>,
                    <VerticalTopItemsInfoItem
                        items={_.values(logSink.exclusionNameToFilterMap)}
                        key="exclusionNameToFilterMap"
                        title={localization.info.items.exclusionNameToFilterMap.title()}/>,
                    <InfoItem
                        key="childResourceAggregation"
                        title={localization.info.items.childResourceAggregation.title()}
                        value={
                            logSink.childResourceAggregation
                                ? localization.info.items.childResourceAggregation.true()
                                : localization.info.items.childResourceAggregation.false()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}