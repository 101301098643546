﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useAzureCommonEncryptedResourceInfoElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useAzureCognitiveServicesAccountTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { AzureFirewallInfoCard, AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../components";

export function useAzureCognitiveServicesAccountDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const account = resourceGroupResourceModel.entity as Contract.AzureCognitiveServicesAccount;
    const accountModel = resourceGroupResourceModel as Contract.AzureCognitiveServicesAccountModel;

    const cognitiveServicesAccountTypeTranslator = useAzureCognitiveServicesAccountTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCognitiveServicesAccountDefinition",
            () => ({
                info: {
                    items: {
                        endpointUrl: "Endpoint",
                        outboundDnsNames: "Outbound Restricted Domains",
                        restrictOutboundAccess: {
                            false: "Disabled",
                            title: "Restrict Outbound Network Access",
                            true: "Enabled"
                        },
                        sharedKeyAccess: {
                            false: "Disabled",
                            title: "Local Authentication",
                            true: "Enabled"
                        },
                        type: "Kind"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={cognitiveServicesAccountTypeTranslator(account.type)}/>,
                    <InfoItem
                        key="endpointUrl"
                        title={localization.info.items.endpointUrl()}
                        value={account.endpointUrl}/>,
                    <InfoItem
                        key="restrictOutboundAccess"
                        title={localization.info.items.restrictOutboundAccess.title()}
                        value={
                            account.restrictOutboundAccess
                                ? localization.info.items.restrictOutboundAccess.true()
                                : localization.info.items.restrictOutboundAccess.false()}/>,
                    <VerticalTopItemsInfoItem
                        items={account.outboundDnsNames}
                        key="outboundDnsNames"
                        title={localization.info.items.outboundDnsNames()}/>,
                    <InfoItem
                        key="sharedKeyAccess"
                        title={localization.info.items.sharedKeyAccess.title()}
                        value={
                            account.sharedKeyAccess
                                ? localization.info.items.sharedKeyAccess.true()
                                : localization.info.items.sharedKeyAccess.false()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                </NetworkingInfoCard>
                {!account.publicNetworkAccess &&
                    <AzureFirewallInfoCard
                        networkResourcePathToIdReferencesMap={accountModel.networkResourcePathToIdReferencesMap}
                        subnets={account.firewallRuleSubnets}
                        virtualNetworkPathToFirewallSubnetRulesMap={account.virtualNetworkPathToFirewallSubnetRulesMap}/>}
            </Info>
    });
}