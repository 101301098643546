import { Contract } from "../../../../../common";

export type ScopesViewType = Contract.TenantType.Aws | Contract.TenantType.Azure | Contract.TenantType.Ci | Contract.TenantType.Code | Contract.TenantType.Gcp | Contract.TenantType.Oci | Contract.TenantType.Op | Contract.ScopeType.Project;

export const ScopesView: { [index in ScopesViewType]: ScopesViewType } = {
    Aws: Contract.TenantType.Aws,
    Azure: Contract.TenantType.Azure,
    Ci: Contract.TenantType.Ci,
    Code: Contract.TenantType.Code,
    Gcp: Contract.TenantType.Gcp,
    Oci: Contract.TenantType.Oci,
    Op: Contract.TenantType.Op,
    Project: Contract.ScopeType.Project
};