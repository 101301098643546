﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetGcpAppEngineApplicationServiceRiskContext } from "../contexts";

export function useGcpAppEngineApplicationServiceUnencryptedTransportExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpAppEngineApplicationServiceUnencryptedTransportExistsRisk;
    const appEngineApplicationServiceUnencryptedTransportExistsRiskModel = riskModel as Contract.GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskModel;
    const serviceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpAppEngineApplicationServiceModel;

    const getGcpAppEngineApplicationServiceRiskContext = useGetGcpAppEngineApplicationServiceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpAppEngineApplicationServiceUnencryptedTransportExistsRiskDefinition",
            () => ({
                contextItems: {
                    unencryptedTransportRevisionIds: "The {{translatedServiceTypeName}} has {{unencryptedTransportRevisionIds}} without HTTPS enforced"
                },
                description: {
                    text: "{{service}} has {{unencryptedTransportRevisionIds}} not enforcing HTTPS connections",
                    unencryptedTransportRevisionIds: [
                        "1 version",
                        "{{count | NumberFormatter.humanize}} versions"
                    ]
                },
                sections: {
                    resolution: {
                        step1: {
                            link: "App Engine documentation",
                            text: "Add **secure: always** line to the app.yaml file to enforce HTTPS. For more information, see: {{appEngineDocumentationUrl}}"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            service:
                <Entity
                    entityIdOrModel={serviceModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>,
            unencryptedTransportRevisionIds:
                <InlineEntities
                    entityIdsOrModels={risk.unencryptedTransportRevisionIds}
                    entityTypeName={Contract.TypeNames.GcpAppEngineApplicationServiceRevision}
                    namePluralizer={localization.description.unencryptedTransportRevisionIds}
                    variant="itemCountAndType"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                appEngineDocumentationUrl:
                    <Link
                        urlOrGetUrl={appEngineApplicationServiceUnencryptedTransportExistsRiskModel.appEngineApplicationDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const serviceRiskContext = getGcpAppEngineApplicationServiceRiskContext(serviceModel);
            return [
                serviceRiskContext.generalInformation,
                serviceRiskContext.sensitive,
                serviceRiskContext.application,
                serviceRiskContext.revisionMaxCreationTime,
                serviceRiskContext.revisions,
                new RiskDefinitionContextItem(
                    localization.contextItems.unencryptedTransportRevisionIds({
                        translatedServiceTypeName:
                            entityTypeNameTranslator(
                                serviceModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                }
                            ),
                        unencryptedTransportRevisionIds:
                            <InlineEntities
                                entityIdsOrModels={risk.unencryptedTransportRevisionIds}
                                entityTypeName={Contract.TypeNames.GcpAppEngineApplicationServiceRevision}
                                variant="itemCountAndType"/>
                    })),
                serviceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}