﻿import { Assert, InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useGetGcpServiceAccountOriginatorScopeResourceRiskContext() {
    return useMemo(
        () => useGcpServiceAccountOriginatorScopeResourceRiskContext,
        []);
}

export function useGcpServiceAccountOriginatorScopeResourceRiskContext(serviceAccountOriginatorScopeResourceModel: Contract.IGcpServiceAccountOriginatorScopeResourceModel) {
    const entityModel = _.as<Contract.EntityModel>(serviceAccountOriginatorScopeResourceModel);
    const serviceAccountData = _.as<Contract.IGcpServiceAccountOriginatorScopeResource>(serviceAccountOriginatorScopeResourceModel.entity).serviceAccountData;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpServiceAccountOriginatorScopeResourceRiskContext",
            () => ({
                cloudPlatformAccessScope: {
                    section: "The {{translatedResourceTypeName}} has the https://www.googleapis.com/auth/cloud-platform access scope attached, allowing {{entity}} access to all Cloud APIs"
                },
                customAuthorizationScopes: {
                    authorizationScopes: [
                        "1 access scope",
                        "{{count | NumberFormatter.humanize}} access scopes"
                    ],
                    section: "The {{translatedResourceTypeName}} has {{authorizationScopes}} attached, limiting {{entity}} access only to specific Cloud APIs"
                },
                defaultAuthorizationScopes: {
                    section: "The {{translatedResourceTypeName}} has {{defaultAuthorizationScopes}} attached, limiting {{entity}} only to read from storage buckets, write logs, write traces and write metrics",
                    title: "default access scopes"
                },
                none: {
                    section: "The {{translatedResourceTypeName}} has the no access scopes attached, limiting {{entity}} access to all Cloud API"
                }
            }));
    const translatedResourceTypeName =
        entityTypeNameTranslator(
            serviceAccountOriginatorScopeResourceModel.entity.typeName,
            {
                variant: "text"
            });
    const getAuthorizationScopesContextItem = () => {
        switch (serviceAccountData.authorizationScopeType) {
            case Contract.GcpServiceAccountOriginatorAuthorizationScopeType.All:
                return new RiskDefinitionContextItem(
                    localization.cloudPlatformAccessScope.section({
                        entity:
                            <Entity
                                entityIdOrModel={entityModel}
                                variant="text"/>,
                        translatedResourceTypeName
                    }));
            case Contract.GcpServiceAccountOriginatorAuthorizationScopeType.Custom:
            case Contract.GcpServiceAccountOriginatorAuthorizationScopeType.DefaultContainerScopes:
                return new RiskDefinitionContextItem(
                    localization.customAuthorizationScopes.section({
                        authorizationScopes:
                            <InlineItems
                                items={serviceAccountData.authorizationScopes}
                                namePluralizer={localization.customAuthorizationScopes.authorizationScopes}
                                variant="itemCountAndType"/>,
                        entity:
                            <Entity
                                entityIdOrModel={entityModel}
                                variant="text"/>,
                        translatedResourceTypeName
                    }));
            case Contract.GcpServiceAccountOriginatorAuthorizationScopeType.DefaultComputeScopes:
                return new RiskDefinitionContextItem(
                    localization.defaultAuthorizationScopes.section({
                        defaultAuthorizationScopes:
                            <InlineItems
                                items={serviceAccountData.authorizationScopes}
                                title={localization.defaultAuthorizationScopes.title()}
                                variant="title"/>,
                        entity:
                            <Entity
                                entityIdOrModel={entityModel}
                                variant="text"/>,
                        translatedResourceTypeName
                    }));
            case Contract.GcpServiceAccountOriginatorAuthorizationScopeType.None:
                return new RiskDefinitionContextItem(
                    localization.none.section({
                        entity:
                            <Entity
                                entityIdOrModel={entityModel}
                                variant="text"/>,
                        translatedResourceTypeName
                    }));
            default:
                Assert.unreachable("serviceAccountData.authorizationScopeType", serviceAccountData.authorizationScopeType);
        }
    };
    return { authorizationScopes: getAuthorizationScopesContextItem() };
}