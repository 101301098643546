import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../common";
import { useWorkloadAnalysisErrorTranslator } from "./useWorkloadAnalysisErrorTranslator";
import { useWorkloadAnalysisScanStatusTranslator } from "./useWorkloadAnalysisScanStatusTranslator";

export function useGetWorkloadAnalysisScanStatusExportItem() {
    const virtualMachineWorkloadAnalysisErrorTranslator = useWorkloadAnalysisErrorTranslator();
    const workloadAnalysisScanStatusTranslator = useWorkloadAnalysisScanStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useGetWorkloadAnalysisScanStatusExportItem",
            () => ({
                titleOnly: "{{title}}",
                withTooltip: "{{title}} ({{tooltip}})"
            }));
    return (
        resourceType: Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine | Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage,
        workloadAnalysisStatus: Contract.WorkloadAnalysisStatus,
        workloadAnalysisError?: Contract.WorkloadAnalysisError,
        azureKeyVaultId?: string): string => {
        const props =
            {
                title: workloadAnalysisScanStatusTranslator(workloadAnalysisStatus),
                tooltip: virtualMachineWorkloadAnalysisErrorTranslator(
                    resourceType,
                    workloadAnalysisError,
                    azureKeyVaultId,
                    true)
            };
        return _.isNil(props.tooltip)
            ? localization.titleOnly(props)
            : localization.withTooltip(props);
    };
}