﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SentraIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M21.9987 2H9.7207V6.90269H21.9987V2Z"/>
            <path d="M14.2428 17.0618H1.99951V21.9997H14.2776V17.0618H14.2428Z"/>
            <path d="M6.83421 2H1.99951V6.90269H6.83421V2Z"/>
            <path d="M21.9988 17.0618H17.1641V21.9997H21.9988V17.0618Z"/>
            <path
                d="M11.947 14.7672C13.4774 14.7672 14.6948 13.5327 14.6948 11.9808C14.6948 10.4288 13.4774 9.19434 11.947 9.19434C10.4166 9.19434 9.19922 10.4288 9.19922 11.9808C9.19922 13.5327 10.4166 14.7672 11.947 14.7672Z"
                fill="#FBAE35"/>
            <path d="M6.83421 9.54956H1.99951V14.4522H6.83421V9.54956Z"/>
            <path d="M21.9988 9.54956H17.1641V14.4522H21.9988V9.54956Z"/>
        </SvgIcon>);
}