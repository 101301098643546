import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, InlineTexts, StringHelper, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntityFilter, EntityModelHelper, entityModelStore, InfoCard, InlineEntities, ItemTable, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type RecordSetInfoCardProps = {
    dnsZoneModel: Contract.AzureNetworkDnsZoneModel;
};

export function RecordSetsInfoCard({ dnsZoneModel }: RecordSetInfoCardProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const recordSets =
        _(entityModelStore.useGet(dnsZoneModel.recordSetIds)).
            map(recordSetModel => recordSetModel.entity as Contract.AzureNetworkDnsZoneRecordSetResource).
            value();
    const dnsRecordEntityModels = entityModelStore.useGet(_.flatMap(dnsZoneModel.recordSetIdToDnsRecordEntityIdsMap));
    const recordSetIdToDnsRecordEntityModelsMap =
        useMemo(
            () => {
                const dnsRecordEntityModelMap =
                    _.keyBy(
                        dnsRecordEntityModels,
                        dnsRecordSetEntityModel => dnsRecordSetEntityModel.id);
                return _.mapValues(
                    dnsZoneModel.recordSetIdToDnsRecordEntityIdsMap,
                    dnsRecordSetEntityIds =>
                        _.map(
                            dnsRecordSetEntityIds,
                            dnsRecordSetEntityId => dnsRecordEntityModelMap[dnsRecordSetEntityId]));
            },
            []);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkDnsZoneDefinition.recordSetsInfoCard",
            () => ({
                columns: {
                    entities: "Resources",
                    name: "Name",
                    type: {
                        title: "Type",
                        [Contract.TypeNames.AzureNetworkDnsZoneRecordSetResource]: {
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetA]: "A",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetAaaa]: "AAAA",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetCaa]: "CAA",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetCname]: "CNAME",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetMx]: "MX",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetNs]: "NS",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetPtr]: "PTR",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetSoa]: "SOA",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetSrv]: "SRV",
                            [Contract.TypeNames.AzureNetworkDnsZoneRecordSetTxt]: "TXT"
                        }
                    },
                    values: "Value"
                },
                empty: "No Record Sets",
                title: "Record Sets"
            }));

    return (
        <InfoCard title={localization.title()}>
            <ItemTable
                columnIdToGetItemValueMap={{
                    [RecordSetInfoCardTableColumnId.Entities]: {
                        getFilterValue: item => dnsZoneModel.recordSetIdToDnsRecordEntityIdsMap[item.id],
                        getSortValue:
                            item =>
                                StringHelper.getCombineSortValue(
                                    _.size(recordSetIdToDnsRecordEntityModelsMap[item.id]),
                                    ..._.map(
                                        recordSetIdToDnsRecordEntityModelsMap[item.id],
                                        entityModel => entityModel.entity.displayName))
                    },
                    [RecordSetInfoCardTableColumnId.Name]: item => item.name,
                    [RecordSetInfoCardTableColumnId.Type]: item => localization.columns.type[Contract.TypeNames.AzureNetworkDnsZoneRecordSetResource].translate(item.typeName),
                    [RecordSetInfoCardTableColumnId.Values]: {
                        getFilterValue: item => item.values,
                        getSortValue: item => StringHelper.getCombineSortValue(_.size(item.values), ...item.values)
                    }
                }}
                csvExportFilePrefixes={
                    [
                        entityTypeNameTranslator((dnsZoneModel.entity).typeName, { includeServiceName: false }),
                        (dnsZoneModel.entity as Contract.AzureNetworkDnsZone).displayName,
                        localization.title()
                    ]}
                defaultSortColumnIdOrIds={RecordSetInfoCardTableColumnId.Name}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                getCsvItem={
                    item => ({
                        /* eslint-disable sort-keys-fix/sort-keys-fix */
                        "Name": item.name,
                        "Type": localization.columns.type[Contract.TypeNames.AzureNetworkDnsZoneRecordSetResource].translate(item.typeName),
                        "Value": _.join(item.values, "\n"),
                        "Resources":
                            _(recordSetIdToDnsRecordEntityModelsMap[item.id]).
                                map(dnsRecordEntityModel => dnsRecordEntityModel.entity.displayReference).
                                join("\n")
                        /* eslint-enable sort-keys-fix/sort-keys-fix */
                    })}
                getItemId={item => item.id}
                items={recordSets}>
                {columnIdToItemValuesMap => [
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.name()}
                                        values={columnIdToItemValuesMap[RecordSetInfoCardTableColumnId.Name]}/>
                            }
                        }}
                        id={RecordSetInfoCardTableColumnId.Name}
                        itemProperty={(recordSet: Contract.AzureNetworkDnsZoneRecordSetResource) => recordSet.name}
                        key={RecordSetInfoCardTableColumnId.Name}
                        title={localization.columns.name()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter placeholder={localization.columns.type.title()}>
                                        {_.map(
                                            columnIdToItemValuesMap[RecordSetInfoCardTableColumnId.Type],
                                            type =>
                                                <ValuesFilterItem
                                                    key={type}
                                                    title={type}
                                                    value={type}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={RecordSetInfoCardTableColumnId.Type}
                        itemProperty={(recordSet: Contract.AzureNetworkDnsZoneRecordSetResource) => localization.columns.type[Contract.TypeNames.AzureNetworkDnsZoneRecordSetResource].translate(recordSet.typeName)}
                        key={RecordSetInfoCardTableColumnId.Type}
                        title={localization.columns.type.title()}/>,
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.values()}
                                        values={columnIdToItemValuesMap[RecordSetInfoCardTableColumnId.Values]}/>
                            }
                        }}
                        id={RecordSetInfoCardTableColumnId.Values}
                        key={RecordSetInfoCardTableColumnId.Values}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkDnsZoneRecordSetResource>) =>
                                <InlineTexts
                                    texts={item.values}
                                    variant="itemPlusItemCount"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.values()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[RecordSetInfoCardTableColumnId.Entities]}
                                        placeholder={localization.columns.entities()}/>
                            }
                        }}
                        id={RecordSetInfoCardTableColumnId.Entities}
                        key={RecordSetInfoCardTableColumnId.Entities}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkDnsZoneRecordSetResource>) =>
                                <InlineEntities
                                    entityIdsOrModels={recordSetIdToDnsRecordEntityModelsMap[item.id]}
                                    entityTypeName={EntityModelHelper.getCommonTypeName(recordSetIdToDnsRecordEntityModelsMap[item.id]) ?? Contract.TypeNames.Entity}
                                    variant="itemPlusItemCount"/>}
                        title={localization.columns.entities()}/>
                ]}
            </ItemTable>
        </InfoCard>);
}

enum RecordSetInfoCardTableColumnId {
    Entities = "entities",
    Name = "name",
    Type = "type",
    Values = "value"
}