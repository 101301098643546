import { ClearIcon as RejectIcon, ConfirmButton, DataTableColumnRenderProps, Message, useLocalization } from "@infrastructure";
import { Check as ApproveIcon } from "@mui/icons-material";
import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useApproverUserPermissionRequestsContext } from "../../../../../../..";
import { Contract, PermissionManagementController, useTheme } from "../../../../../../../../../../../common";

export function PendingActionsCell({ item: permissionRequestModel }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const { triggerPermissionRequestModelChange } = useApproverUserPermissionRequestsContext();

    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests.table.hooks.useDefinition.hooks.useActiveDefinition.pendingActionsCell",
            () => ({
                actions: {
                    approve: {
                        confirm: "Are you sure you want to approve this request?",
                        error: "Failed to approve request",
                        title: "Approve"
                    },
                    reject: {
                        confirm: "Are you sure you want to deny this request?",
                        error: "Failed to deny request",
                        title: "Deny"
                    }
                }
            }));

    const [error, setError] = useState<string>();
    const [executing, setExecuting] = useState(false);

    async function approvePermissionRequest() {
        setExecuting(true);
        setError(undefined);

        try {
            await PermissionManagementController.approvePermissionRequest(new Contract.PermissionManagementControllerApprovePermissionRequestRequest(permissionRequestModel.permissionRequest.id));
            await triggerPermissionRequestModelChange();
        } catch {
            setError(localization.actions.approve.error());
        }

        setExecuting(false);
    }

    async function rejectPermissionRequest() {
        setExecuting(true);
        setError(undefined);

        try {
            await PermissionManagementController.rejectPermissionRequest(new Contract.PermissionManagementControllerRejectPermissionRequestRequest(permissionRequestModel.permissionRequest.id));

            await triggerPermissionRequestModelChange();
        } catch {
            setError(localization.actions.reject.error());
        }

        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {executing && (
                <CircularProgress
                    size={theme.spacing(3)}
                    variant="indeterminate"/>)}
            {!_.isNil(error) && (
                <Message
                    level="error"
                    title={error}
                    variant="minimal"/>)}
            <Stack
                direction="row"
                spacing={1}
                sx={{ marginRight: theme.spacing(0.5) }}>
                <ConfirmButton
                    disabled={executing}
                    icon={
                        <ApproveIcon
                            sx={{
                                color: theme.palette.success.main,
                                fontSize: "20px",
                                opacity:
                                    executing
                                        ? 0.4
                                        : 1
                            }}/>}
                    message={localization.actions.approve.confirm()}
                    sx={{ textTransform: "none" }}
                    variant="outlined"
                    onClick={() => approvePermissionRequest()}>
                    {localization.actions.approve.title()}
                </ConfirmButton>
                <ConfirmButton
                    disabled={executing}
                    icon={
                        <RejectIcon
                            sx={{
                                color: theme.palette.error.main,
                                fontSize: "17px",
                                opacity:
                                    executing
                                        ? 0.4
                                        : 1
                            }}/>}
                    message={localization.actions.reject.confirm()}
                    sx={{ textTransform: "none" }}
                    variant="outlined"
                    onClick={() => rejectPermissionRequest()}>
                    {localization.actions.reject.title()}
                </ConfirmButton>
            </Stack>
        </Stack>);
}