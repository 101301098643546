import { InlineItems } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { dataClassifierModelStore } from "../../../../../common";

type InlineDataClassifierNamesProps = {
    ids: string[];
};

export function InlineDataClassifierNames({ ids }: InlineDataClassifierNamesProps) {
    const dataClassifierModels = dataClassifierModelStore.useGetAll();
    const dataClassifierModelMap =
        useMemo(
            () =>
                _.keyBy(
                    dataClassifierModels,
                    dataClassifierModel => dataClassifierModel.id),
            [dataClassifierModels]);
    const items =
        useMemo(
            () =>
                _(ids).
                    sort().
                    map(id => dataClassifierModelMap[id].name).
                    value(),
            [dataClassifierModelMap, ids]);

    return (
        <InlineItems
            items={items}
            variant="itemPlusItemCount"/>);
}