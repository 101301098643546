import { ItemWithValidation, MultiSelect, useChangeEffect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract } from "../../../controllers";
import { scopeSystemEntityModelStore } from "../../../stores";
import { ScopeHelper } from "../../../utilities";
import { DeliveryProps } from "../Delivery";

export function QRadar({ delivery, disabled, onChange, scopeId }: DeliveryProps) {
    const qRadarModels = scopeSystemEntityModelStore.useGetQRadar();
    const [selectedServerIds, setSelectedServerIds] =
        useState(
            _.map(
                (delivery?.destinations ?? []) as Contract.QRadarDeliveryServer[],
                ({ serverId }) => serverId));
    const qRadarServerModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            qRadarModels);
    const qRadarServerModelMap =
        useMemo(
            () =>
                _.keyBy(
                    qRadarServerModels,
                    qRadarServerModel => qRadarServerModel.configuration.id),
            [qRadarServerModels]);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.QRadarDelivery),
                destinations:
                    _.map(
                        selectedServerIds,
                        serverId => ({
                            serverId,
                            typeName: Contract.TypeNames.QRadarDeliveryServer
                        })) as Contract.QRadarDeliveryServer[],
                valid: !_.isEmpty(selectedServerIds)
            } as ItemWithValidation<Contract.QRadarDelivery>),
        [selectedServerIds],
        500);

    const localization =
        useLocalization(
            "common.delivery.qradar",
            () => ({
                servers: "{{serverCount | NumberFormatter.humanize}} QRadars",
                title: "QRadars"
            }));

    return (
        <MultiSelect
            disabled={disabled}
            fullWidth={true}
            items={_.keys(qRadarServerModelMap)}
            placeholder={localization.title()}
            selectedItems={selectedServerIds}
            selectedItemsTranslator={
                selectedItems =>
                    selectedItems.length === 1
                        ? (qRadarServerModelMap[selectedItems[0]].configuration as Contract.QRadarServerConfiguration).name
                        : localization.servers({ serverCount: selectedItems.length })}
            onSelectedItemsChanged={setSelectedServerIds}>
            {item => (qRadarServerModelMap[item].configuration as Contract.QRadarServerConfiguration).name}
        </MultiSelect>);
}