import { Optional, UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, RiskType } from "../../../../../../common";
import { useCloudRiskController, useCodeRiskController } from "./hooks";

export function useRiskController(riskType: RiskType): RiskController {
    const useRiskController =
        useMemo(
            () => {
                switch (riskType) {
                    case RiskType.Cloud:
                        return useCloudRiskController;
                    case RiskType.Code:
                        return useCodeRiskController;
                    default:
                        throw new UnexpectedError(riskType);
                }
            },
            [riskType]);

    return useRiskController();
}

export type RiskController = {
    excludeEntity?: (entityId: string, ignoreExpirationDate: Optional<string>, ignoreReason: Contract.RiskPolicyConfigurationExclusionDataReason, message: Optional<string>, riskId: string) => Promise<void>;
    getRiskGroups: (requestFilters: Contract.RiskControllerRiskModelFilters, groupRequestType: Contract.RiskControllerGetRiskGroupsRequestType) => Promise<Contract.RiskControllerGetRiskGroupsResponse>;
    getRiskIdToScopeIdentityPermissionsMap: (requestFilters: Contract.RiskControllerRiskModelFilters) => Promise<Contract.RiskControllerGetRiskIdToScopeIdentityPermissionsMapResponse>;
    getRiskModelPage: (requestFilters: Contract.RiskControllerRiskModelFilters, limit: number, skip: number, sort: Optional<Contract.RiskControllerRiskModelSort>) => Promise<Contract.RiskControllerGetRiskModelPageResponse>;
    updateCloseRiskStatus: (riskIds: string[], message?: string) => Promise<void>;
    updateIgnoreRiskStatus?: (message: Optional<string>, riskIds: string[], ignoreExpirationDate: Optional<string>, ignoreReason: Contract.RiskPolicyConfigurationExclusionDataReason) => Promise<void>;
    updateOpenRiskStatus: (riskIds: string[], message?: string) => Promise<void>;
};