﻿import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useGcpPubSubSubscriptionMessageDeliveryTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpPubSubSubscriptionDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const subscriptionModel = scopeResourceModel as Contract.GcpPubSubSubscriptionModel;
    const subscription = subscriptionModel.entity as Contract.GcpPubSubSubscription;

    const pubSubSubscriptionMessageDeliveryTypeTranslator = useGcpPubSubSubscriptionMessageDeliveryTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpPubSubSubscriptionDefinition",
            () => ({
                info: {
                    items: {
                        deadLetterTopicIdReference: "Dead letter topic",
                        expirationTimeFrame: {
                            empty: "Never",
                            title: "Subscription expiration"
                        },
                        filter: "Subscription filter",
                        messageDeliverySettings: {
                            acknowledgementTimeoutDuration: "Acknowledgement deadline",
                            deleteAcknowledged: {
                                false: "Yes",
                                title: "Retain acknowledged messages",
                                true: "No"
                            },
                            orderingEnabled: {
                                false: "Disabled",
                                title: "Message ordering",
                                true: "Enabled"
                            },
                            retryPolicy: {
                                title: "Retry policy",
                                [Contract.TypeNames.GcpPubSubSubscriptionMessageDeliveryRetryPolicy]: {
                                    [Contract.GcpPubSubSubscriptionMessageDeliveryRetryPolicy.ExponentialBackoff]: "Retry after exponential backoff delay",
                                    [Contract.GcpPubSubSubscriptionMessageDeliveryRetryPolicy.Immediately]: "Retry immediately"
                                }
                            },
                            singleDeliveryEnabled: {
                                false: "Disabled",
                                title: "Exactly once delivery",
                                true: "Enabled"
                            },
                            type: "Delivery type"
                        },
                        messageRetentionDuration: "Subscription message retention duration",
                        status: {
                            title: "State",
                            [Contract.TypeNames.GcpPubSubSubscriptionStatus]: {
                                [Contract.GcpPubSubSubscriptionStatus.Active]: "Active",
                                [Contract.GcpPubSubSubscriptionStatus.ResourceError]: "Resource Error"
                            }
                        },
                        topicIdReference: "Topic",
                        topicMessageRetentionDuration: "Topic message retention duration"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status.title()}
                        value={localization.info.items.status[Contract.TypeNames.GcpPubSubSubscriptionStatus][subscription.status]()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subscriptionModel.topicIdReference}
                        entityTypeName={Contract.TypeNames.GcpPubSubTopic}
                        key="topicIdReference"
                        title={localization.info.items.topicIdReference()}/>,
                    <InfoItem
                        key="messageDeliverySettingsType"
                        title={localization.info.items.messageDeliverySettings.type()}
                        value={pubSubSubscriptionMessageDeliveryTypeTranslator(subscription.messageDeliverySettings.type)}/>,
                    <InfoItem
                        key="expirationTimeFrame"
                        title={localization.info.items.expirationTimeFrame.title()}
                        value={
                            _.isNil(subscription.expirationTimeFrame)
                                ? localization.info.items.expirationTimeFrame.empty()
                                : TimeSpanFormatter.humanizeDays(subscription.expirationTimeFrame)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subscriptionModel?.deadLetterTopicIdReference}
                        entityTypeName={Contract.TypeNames.GcpPubSubTopic}
                        key="deadLetterTopicIdReference"
                        title={localization.info.items.deadLetterTopicIdReference()}/>,
                    <InfoItem
                        key="messageDeliverySettingsAcknowledgementTimeoutDuration"
                        title={localization.info.items.messageDeliverySettings.acknowledgementTimeoutDuration()}
                        value={TimeSpanFormatter.humanizeDays(subscription.messageDeliverySettings.acknowledgementTimeoutDuration)}/>,
                    <InfoItem
                        key="filter"
                        title={localization.info.items.filter()}
                        value={
                            _.isNil(subscription.filter)
                                ? undefined
                                : subscription.filter}/>,
                    <InfoItem
                        key="messageRetentionDuration"
                        title={localization.info.items.messageRetentionDuration()}
                        value={TimeSpanFormatter.humanizeDays(subscription.messageRetentionDuration)}/>,
                    <InfoItem
                        key="topicMessageRetentionDuration"
                        title={localization.info.items.topicMessageRetentionDuration()}
                        value={
                            _.isNil(subscription.topicMessageRetentionDuration)
                                ? undefined
                                : TimeSpanFormatter.humanizeDays(subscription.topicMessageRetentionDuration)}/>,
                    <InfoItem
                        key="messageDeliverySettingsDeleteAcknowledged"
                        title={localization.info.items.messageDeliverySettings.deleteAcknowledged.title()}
                        value={
                            subscription.messageDeliverySettings.deleteAcknowledged
                                ? localization.info.items.messageDeliverySettings.deleteAcknowledged.true()
                                : localization.info.items.messageDeliverySettings.deleteAcknowledged.false()}/>,
                    <InfoItem
                        key="messageDeliverySettingsSingleDeliveryEnabled"
                        title={localization.info.items.messageDeliverySettings.singleDeliveryEnabled.title()}
                        value={
                            subscription.messageDeliverySettings.singleDeliveryEnabled
                                ? localization.info.items.messageDeliverySettings.singleDeliveryEnabled.true()
                                : localization.info.items.messageDeliverySettings.singleDeliveryEnabled.false()}/>,
                    <InfoItem
                        key="messageDeliverySettingsOrderingEnabled"
                        title={localization.info.items.messageDeliverySettings.orderingEnabled.title()}
                        value={
                            subscription.messageDeliverySettings.orderingEnabled
                                ? localization.info.items.messageDeliverySettings.orderingEnabled.true()
                                : localization.info.items.messageDeliverySettings.orderingEnabled.false()}/>,
                    <InfoItem
                        key="messageDeliverySettingsRetryPolicy"
                        title={localization.info.items.messageDeliverySettings.retryPolicy.title()}
                        value={localization.info.items.messageDeliverySettings.retryPolicy[Contract.TypeNames.GcpPubSubSubscriptionMessageDeliveryRetryPolicy][subscription.messageDeliverySettings.retryPolicy]()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}