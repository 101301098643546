import { DropdownIcon } from "@infrastructure";
import { Box, InputAdornment, InputBase, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactElement } from "react";

type TextFieldProps = {
    disabled?: boolean;
    fieldVariant: "filter" | "outlined" | "text" | "textLarge";
    icon?: ReactElement;
    placeholder?: string;
    selectedItems: any[];
    selectedItemsTranslator: (items: any[]) => string;
};

export function TextField({ disabled, fieldVariant, icon, placeholder = "", selectedItems, selectedItemsTranslator }: TextFieldProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                "&:hover": {
                    border: theme.border.hoverFocus
                },
                background:
                    disabled
                        ? theme.palette.input.disabledBackground
                        : "unset",
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                height:
                    fieldVariant === "textLarge"
                        ? theme.spacing(4.5)
                        : undefined,
                padding: theme.spacing(1.5),
                width: "100%"
            }}>
            <InputBase
                disabled={disabled}
                endAdornment={
                    <DropdownIcon
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: "18px"
                        }}/>}
                fullWidth={true}
                placeholder={placeholder}
                startAdornment={
                    _.isNil(icon)
                        ? undefined
                        : <InputAdornment position="start">
                            {icon}
                        </InputAdornment>}
                sx={{
                    color:
                        _.isEmpty(selectedItems)
                            ? theme.palette.text.secondary
                            : undefined,
                    cursor: "pointer",
                    fontSize:
                        fieldVariant === "textLarge"
                            ? "13px"
                            : undefined,
                    height: "100%"
                }}
                value={
                    _.isEmpty(selectedItems)
                        ? undefined
                        : selectedItemsTranslator(selectedItems)}/>
        </Box>);
}