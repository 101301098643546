import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureStorageStorageAccountRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRisk;
    const storageAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountModel;
    const blobContainerModels = entityModelStore.useGet(risk.blobContainerIds) as Contract.AzureStorageStorageAccountBlobContainerModel[];
    const sensitiveBlobContainerModels =
        _.filter(
            blobContainerModels,
            blobContainerModel => blobContainerModel.entityConfiguration?.sensitive === true);

    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskDefinition",
            () => ({
                contextItems: {
                    activityLogsBlobContainer: "The storage account contains {{blobContainers}} with activity logs",
                    sensitiveBlobContainers: "The storage account contains {{sensitiveBlobContainers}} marked as sensitive"
                },
                description: "{{storageAccount}} is not configured with encryption using CMK",
                sections: {
                    resolution: {
                        step1: "Before changing the encryption, verify that applications using the storage account have decrypt permissions",
                        step2: "Under the **Security + Networking** section, click the **Encryption** button",
                        step3: "Under the **Encryption type** section, select **Customer-managed keys**",
                        step4: "Select your **Encryption Key**",
                        step5: "Click **Save** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            storageAccount:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()],
        riskModel,
        () => {
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel);
            return [
                storageAccountRiskContext.generalInformation,
                storageAccountRiskContext.inboundExternalAccessScope,
                new RiskDefinitionContextItem(
                    localization.contextItems.activityLogsBlobContainer({
                        blobContainers:
                            <InlineEntities
                                entityIdsOrModels={blobContainerModels}
                                entityTypeName={Contract.TypeNames.AzureStorageStorageAccountBlobContainer}
                                variant="itemCountAndType"/>
                    })),
                _.isEmpty(sensitiveBlobContainerModels)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.sensitiveBlobContainers({
                            sensitiveBlobContainers:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveBlobContainerModels}
                                    entityTypeName={Contract.TypeNames.AzureStorageStorageAccountBlobContainer}
                                    variant="itemCountAndType"/>
                        })),
                storageAccountRiskContext.sensitive,
                storageAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)];
        });
}