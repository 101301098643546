﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAwsAcmCertificateKeyTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsAcmCertificateKeyTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsAcmCertificateKeyType]: {
                    [Contract.AwsAcmCertificateKeyType.Ecdsa256]: "ECDSA 256",
                    [Contract.AwsAcmCertificateKeyType.Ecdsa384]: "ECDSA 384",
                    [Contract.AwsAcmCertificateKeyType.Ecdsa521]: "ECDSA 521",
                    [Contract.AwsAcmCertificateKeyType.Rsa1024]: "RSA 1024",
                    [Contract.AwsAcmCertificateKeyType.Rsa2048]: "RSA 2048",
                    [Contract.AwsAcmCertificateKeyType.Rsa3072]: "RSA 3072",
                    [Contract.AwsAcmCertificateKeyType.Rsa4096]: "RSA 4096"
                }
            }));
    return (type: Contract.AwsAcmCertificateKeyType) => localization[Contract.TypeNames.AwsAcmCertificateKeyType][type]();
}