import { useMemo } from "react";
import { useGciDefaultEntityDefinition, useGciDirectoryDirectoryRoleDefinition, useGciDirectoryGroupDefinition, useGciDirectoryPartialGroupDefinition, useGciDirectoryPartialUserDefinition, useGciDirectoryUserDefinition, useGciTenantEntityDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGciEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.GciDirectoryDirectoryRole:
                        return useGciDirectoryDirectoryRoleDefinition;
                    case Contract.TypeNames.GciDirectoryPartialGroup:
                        return useGciDirectoryPartialGroupDefinition;
                    case Contract.TypeNames.GciDirectoryPartialUser:
                        return useGciDirectoryPartialUserDefinition;
                    case Contract.TypeNames.GciDirectoryGroup:
                        return useGciDirectoryGroupDefinition;
                    case Contract.TypeNames.GciDirectoryUser:
                        return useGciDirectoryUserDefinition;
                    case Contract.TypeNames.GciTenantEntity:
                        return useGciTenantEntityDefinition;
                    default:
                        return useGciDefaultEntityDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}