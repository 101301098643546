import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { useGetOciIamUserDatabasePasswordRiskContext } from "../contexts";

export function useOciIamUserDatabasePasswordNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciIamUserDatabasePasswordNotRotatedRisk;
    const userDatabasePasswordModel = entityModelStore.useGet(risk.entityId) as Contract.OciIamUserDatabasePasswordModel;

    const getOciIamUserDatabasePasswordRiskContext = useGetOciIamUserDatabasePasswordRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciIamUserDatabasePasswordNotRotatedRiskDefinition",
            () => ({
                description: "{{userDatabasePassword}} was not rotated for more than {{userDatabasePasswordRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                sections: {
                    resolution: {
                        step1: "Regenerating user database passwords may break existing client connectivity, as the client will try to connect with older user database passwords. Therefore, it is better to create a new user database password and delete the old one after you migrate your application to use the new User database password.",
                        step2: "In the Database passwords section, click **Create database password**",
                        step3: "Enter a password and click **Create database password**",
                        step4: "Before deleting the old user database password, migrate your applications (if any) to use the newly created user database password",
                        step5: "Select the old password and click **Delete**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (localization.description({
            userDatabasePassword:
                <Entity
                    entityIdOrModel={userDatabasePasswordModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            userDatabasePasswordRotationTimeFrame: risk.credentialRotationTimeFrame
        })),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.User,
                userDatabasePasswordModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const keyRiskContext = getOciIamUserDatabasePasswordRiskContext(userDatabasePasswordModel);
            return [
                keyRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.credentialRotationTimeFrame),
                keyRiskContext.creationTime,
                keyRiskContext.belongsTo,
                keyRiskContext.sensitive
            ];
        });
}