import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureSqlServerDatabaseStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureSqlServerDatabaseStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureSqlServerDatabaseStatus]: {
                    [Contract.AzureSqlServerDatabaseStatus.AutoClosed]: "Auto Closed",
                    [Contract.AzureSqlServerDatabaseStatus.Copying]: "Copying",
                    [Contract.AzureSqlServerDatabaseStatus.Creating]: "Creating",
                    [Contract.AzureSqlServerDatabaseStatus.Disabled]: "Disabled",
                    [Contract.AzureSqlServerDatabaseStatus.EmergencyMode]: "Emergency Mode",
                    [Contract.AzureSqlServerDatabaseStatus.Inaccessible]: "Inaccessible",
                    [Contract.AzureSqlServerDatabaseStatus.Offline]: "Offline Secondary",
                    [Contract.AzureSqlServerDatabaseStatus.OfflineChangingDwPerformanceTiers]: "Offline Changing Data Warehouse Performance Tiers",
                    [Contract.AzureSqlServerDatabaseStatus.OfflineSecondary]: "Offline Secondary",
                    [Contract.AzureSqlServerDatabaseStatus.Online]: "Online",
                    [Contract.AzureSqlServerDatabaseStatus.OnlineChangingDwPerformanceTiers]: "Online Changing Data Warehouse Performance Tiers",
                    [Contract.AzureSqlServerDatabaseStatus.Paused]: "Paused",
                    [Contract.AzureSqlServerDatabaseStatus.Pausing]: "Pausing",
                    [Contract.AzureSqlServerDatabaseStatus.Recovering]: "Recovering",
                    [Contract.AzureSqlServerDatabaseStatus.RecoveryPending]: "Recovery Pending",
                    [Contract.AzureSqlServerDatabaseStatus.Restoring]: "Restoring",
                    [Contract.AzureSqlServerDatabaseStatus.Resuming]: "Resuming",
                    [Contract.AzureSqlServerDatabaseStatus.Scaling]: "Scaling",
                    [Contract.AzureSqlServerDatabaseStatus.Shutdown]: "Shutdown",
                    [Contract.AzureSqlServerDatabaseStatus.Standby]: "Standby",
                    [Contract.AzureSqlServerDatabaseStatus.Starting]: "Starting",
                    [Contract.AzureSqlServerDatabaseStatus.Stopped]: "Stopped",
                    [Contract.AzureSqlServerDatabaseStatus.Stopping]: "Stopping",
                    [Contract.AzureSqlServerDatabaseStatus.Suspect]: "Suspect"
                }
            }));

    return (status: Contract.AzureSqlServerDatabaseStatus) =>
        localization[Contract.TypeNames.AzureSqlServerDatabaseStatus][status]();
}