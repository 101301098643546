import { ApiError, FormLayout, ItemSelector, map, Message, Optional, TimeFormatter, useLocalization } from "@infrastructure";
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import _, { Function0, Function1 } from "lodash";
import React, { Fragment, ReactNode, useMemo, useState } from "react";
import { ConfigurationController, Contract, ReportController } from "../../controllers";
import { useDeliveries } from "../../hooks";
import { slackWorkspaceChannelOperationStore } from "../../operationStores";
import { projectModelStore, reportDefinitionConfigurationStore, scopeSystemEntityModelStore, tenantModelStore } from "../../stores";
import { useTheme } from "../../themes";
import { DownloadHelper, LicensingHelper, ScopeHelper, UserHelper } from "../../utilities";
import { Deliveries } from "../Deliveries";
import { RadioGroup } from "../RadioGroup";
import { ScopeSelector } from "../ScopeSelector";
import { Daily, Monthly, Weekly } from "./components";
import { useReportTypeTranslator } from "./hooks";

type ReportProps = {
    items: ReportItems;
    onGenerate?: (type?: ReportDeliveryType) => void;
    onScopeChange?: Function1<string, void>;
    options?: ReportOptions;
    rootScopeId: string;
    scopeSelectorDisabled?: boolean;
};

export type ReportItem = {
    configuration: ReactNode;
    generate: Function1<Optional<string>, Contract.ReportControllerReportRequestDefinition>;
    notValid?: Function0<boolean>;
};

export type ReportItems = Partial<{ [key in Contract.ReportType]: () => ReportItem | undefined }>;

export type ReportOptions = {
    reportName?: string;
    reportScheduleConfiguration?: Contract.ReportScheduleConfiguration;
    reportType?: Contract.ReportType;
    scheduleReportType?: ReportDeliveryType;
    selectedScopeId?: string;
};

export function Report({ items, onGenerate, onScopeChange, options, rootScopeId, scopeSelectorDisabled }: ReportProps) {
    const { createItem, deliveries, onChange, valid: deliveriesValid } = useDeliveries(options?.reportScheduleConfiguration?.deliveries);
    const workspaceIdToChannelRawIdToNameMap = slackWorkspaceChannelOperationStore.useGet();
    const activeCloudProviderTenantModels = tenantModelStore.useGetActiveCloudProviderTenants();
    const activeGitTenantModels = tenantModelStore.useGetActiveGitTenants();
    const projectModels = projectModelStore.useGetPermittedProjects();
    const scopeIds =
        useMemo(
            () =>
                _(_.concat<Contract.ScopeModel>(activeCloudProviderTenantModels, activeGitTenantModels, projectModels)).
                    map(scopeModel => scopeModel.configuration.id).
                    filter(
                        tenantId =>
                            UserHelper.hasScopePermissions(
                                tenantId,
                                Contract.IdentityPermission.SecurityWrite)).
                    value(),
            [activeCloudProviderTenantModels, activeGitTenantModels, projectModels]);

    const [selectedScopeId, setSelectedScopeId] = useState(options?.reportScheduleConfiguration?.scopeId ?? options?.selectedScopeId ?? rootScopeId);

    const [reportDeliveryType, setReportDeliveryType] =
        useState(
            !_.isNil(options?.scheduleReportType)
                ? options?.scheduleReportType
                : ReportDeliveryType.Generate);
    const [reportType, setReportType] = useState(options?.reportType);
    const [reportName, setReportName] = useState(options?.reportName);

    const reportDefinitionConfigurations = reportDefinitionConfigurationStore.useGetAll();
    const unlicensedReportDefinitionConfiguration =
        useMemo(
            () => {
                const reportDefinitionConfiguration =
                    _.isNil(options?.reportScheduleConfiguration)
                        ? undefined
                        : _.find(
                            reportDefinitionConfigurations,
                            reportDefinitionConfiguration => reportDefinitionConfiguration.id === options?.reportScheduleConfiguration?.definitionId);
                return !(_.isNil(reportDefinitionConfiguration) || LicensingHelper.isActiveLicenseType(reportDefinitionConfiguration.licenseType));
            },
            [reportDefinitionConfigurations]);
    const slackWorkspaceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [selectedScopeId],
            scopeSystemEntityModelStore.useGetSlack());
    const teamsOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [selectedScopeId],
            scopeSystemEntityModelStore.useGetTeams());

    const [cadenceHour, setCadenceHour] = useState(options?.reportScheduleConfiguration?.cadence.hour ?? 0);
    const [cadenceInterval, setCadenceInterval] = useState(options?.reportScheduleConfiguration?.cadence.interval ?? Contract.ReportScheduleCadenceInterval.Monthly);
    const [cadenceMonthDay, setCadenceMonthDay] = useState(options?.reportScheduleConfiguration?.cadence.monthDay ?? 1);
    const [cadenceWeekDay, setCadenceWeekDay] =
        useState(
            options?.reportScheduleConfiguration?.cadence.weekDay ??
            (TimeFormatter.weekStartDay() === 0
                ? Contract.DayOfWeek.Sunday
                : Contract.DayOfWeek.Monday));
    const [scheduleOrGenerateError, setScheduleOrGenerateError] = useState<Optional<string>>(undefined);
    const [scheduleOrGenerateExecuting, setScheduleOrGenerateExecuting] = useState(false);
    const deliveryDerivedTypeNames =
        useMemo(
            () =>
                (reportDeliveryType === ReportDeliveryType.Generate && UserHelper.hasScopePermissions(selectedScopeId, Contract.IdentityPermission.SecurityRead)) ||
                (reportDeliveryType === ReportDeliveryType.Schedule && UserHelper.hasScopePermissions(selectedScopeId, Contract.IdentityPermission.SecurityWrite))
                    ? _<Contract.DeliveryDerivedTypeNames>([]).
                        concat(Contract.DeliveryDerivedTypeNames.MailDelivery).
                        concatIf(
                            reportType == Contract.ReportType.Risks &&
                            !_.some(
                                deliveries,
                                ({ delivery }) => delivery.typeName === Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery),
                            Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery).
                        concatIf(
                            !_(slackWorkspaceModels).
                                flatMap(slackWorkspaceModel => _.keys(workspaceIdToChannelRawIdToNameMap[slackWorkspaceModel.id])).
                                isEmpty(),
                            Contract.DeliveryDerivedTypeNames.SlackDelivery).
                        concatIf(
                            !_(teamsOrganizationModels).
                                flatMap(teamsOrganizationModel => (teamsOrganizationModel.state as Contract.TeamsOrganizationState).teamRawIdToChannelReferencesMap).
                                isEmpty(),
                            Contract.DeliveryDerivedTypeNames.TeamsDelivery).
                        value()
                    : [],
            [deliveries, reportDeliveryType, reportType, selectedScopeId, slackWorkspaceModels, teamsOrganizationModels]);

    const reportTypeTranslator = useReportTypeTranslator();
    const localization =
        useLocalization(
            "common.report",
            () => ({
                actions: {
                    close: "Close",
                    [ReportDeliveryType.Generate]: {
                        error: {
                            general: "Failed to generate report",
                            timeout: "Looks like it takes a long time to generate this report... we will still generate the report and it will appear in the Reports History page when ready"
                        },
                        title: "Generate"
                    },
                    [ReportDeliveryType.Schedule]: {
                        error: {
                            add: "Failed to schedule report",
                            update: "Failed to save new schedule"
                        },
                        title: {
                            add: "Schedule",
                            update: "Save"
                        }
                    }
                },
                fields: {
                    cadence: {
                        placeholder: "Cadence",
                        [Contract.TypeNames.ReportScheduleCadenceInterval]: {
                            [Contract.ReportScheduleCadenceInterval.Daily]: "Daily",
                            [Contract.ReportScheduleCadenceInterval.Monthly]: "Monthly",
                            [Contract.ReportScheduleCadenceInterval.Weekly]: "Weekly"
                        }
                    },
                    deliveryTime: "Delivery",
                    name: "Name (optional)",
                    reportType: "Type",
                    scope: "Scope",
                    scopeTargets: {
                        subtitle: "The accounts you want to include in the report. When you choose a folder, the report will cover all existing and future accounts within that folder.",
                        title: "Targets"
                    },
                    type: {
                        [ReportDeliveryType.Generate]: "Now",
                        [ReportDeliveryType.Schedule]: "Schedule"
                    },
                    unlicensedReportDefinitionConfiguration: "This report type is not supported with your current license"
                },
                title: "New Report"
            }));

    const selectedReportItem =
        useMemo(
            () =>
                _.isNil(reportType)
                    ? undefined
                    : items[reportType!]?.(),
            [items, reportType]);

    async function scheduleOrGenerate() {
        setScheduleOrGenerateExecuting(true);
        setScheduleOrGenerateError(undefined);

        try {
            const reportControllerGenerateReportRequestDefinition =
                selectedReportItem?.generate(reportName?.trim()) ??
                new Contract.ReportControllerCommonReportRequestDefinition(
                    reportName?.trim(),
                    undefined,
                    Contract.TypeNames.ReportControllerCommonReportRequestDefinition,
                    reportType!);

            if (reportDeliveryType === ReportDeliveryType.Generate) {
                const { report } =
                    await ReportController.generateReport(
                        new Contract.ReportControllerGenerateReportRequest(
                            reportControllerGenerateReportRequestDefinition,
                            _.map(
                                deliveries,
                                ({ delivery }) => delivery),
                            selectedScopeId));
                await reportDefinitionConfigurationStore.notify();
                DownloadHelper.downloadFile(ReportController.getReportFileUrl(report.id, false));
            } else {
                const { scopeSystemEntityModel: updatedReportScheduleModel } =
                    await ConfigurationController.upsertReportSchedule(
                        new Contract.ConfigurationControllerUpsertReportScheduleRequest(
                            new Contract.ReportScheduleCadence(
                                cadenceHour,
                                cadenceInterval!,
                                cadenceMonthDay,
                                cadenceWeekDay),
                            reportControllerGenerateReportRequestDefinition,
                            _.map(
                                deliveries,
                                ({ delivery }) => delivery),
                            options?.reportScheduleConfiguration?.id,
                            selectedScopeId));

                await reportDefinitionConfigurationStore.notify();
                await scopeSystemEntityModelStore.notify(updatedReportScheduleModel);
            }

            onGenerate?.(reportDeliveryType!);
        } catch (error) {
            if (error instanceof ApiError &&
                error.statusCode === 400 &&
                error.error === Contract.GenerateReportError.Timeout) {
                setScheduleOrGenerateError(localization.actions.generate.error.timeout());
            } else {
                setScheduleOrGenerateError(
                    reportDeliveryType === ReportDeliveryType.Schedule
                        ? _.isNil(options?.reportScheduleConfiguration)
                            ? localization.actions.schedule.error.add()
                            : localization.actions.schedule.error.update()
                        : localization.actions.generate.error.general());
            }
        }

        setScheduleOrGenerateExecuting(false);
    }

    const theme = useTheme();
    return (
        <FormLayout
            disableContentPadding={true}
            footerOptions={{
                border: true,
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {scheduleOrGenerateExecuting &&
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>}
                        {scheduleOrGenerateError === localization.actions.generate.error.timeout()
                            ? <Button onClick={() => onGenerate?.()}>
                                {localization.actions.close()}
                            </Button>
                            : <Button
                                disabled={
                                    scheduleOrGenerateExecuting ||
                                    _.isNil(reportType) ||
                                    unlicensedReportDefinitionConfiguration ||
                                    selectedReportItem?.notValid?.() ||
                                    !deliveriesValid}
                                onClick={() => scheduleOrGenerate()}>
                                {reportDeliveryType === ReportDeliveryType.Schedule
                                    ? _.isNil(options?.reportScheduleConfiguration)
                                        ? localization.actions.schedule.title.add()
                                        : localization.actions.schedule.title.update()
                                    : localization.actions.generate.title()}
                            </Button>}
                    </Stack>
            }}
            titleOptions={{ text: localization.title() }}>
            <Stack
                spacing={2}
                sx={{ padding: theme.spacing(2, 3) }}>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Stack spacing={2}>
                            <ItemSelector
                                fullWidth={true}
                                getItemDisabled={
                                    () =>
                                        !_.isNil(selectedReportItem) &&
                                        _(items).
                                            keys().
                                            size() === 1}
                                getItemText={reportTypeTranslator}
                                items={_.keys(items)}
                                placeholder={localization.fields.reportType()}
                                selectedItem={reportType}
                                sorted={true}
                                onSelectedItemChanged={setReportType}>
                                {reportTypeTranslator}
                            </ItemSelector>
                            {unlicensedReportDefinitionConfiguration &&
                                <Message
                                    level="warning"
                                    title={localization.fields.unlicensedReportDefinitionConfiguration()}/>}
                        </Stack>
                        <TextField
                            fullWidth={true}
                            placeholder={localization.fields.name()}
                            value={reportName}
                            variant="outlined"
                            onChange={event => setReportName(event.target.value)}/>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="h4">
                            {localization.fields.scope()}
                        </Typography>
                        <ScopeSelector
                            disabled={scopeSelectorDisabled}
                            fullWidth={true}
                            includeProjects={true}
                            popoverElementContainerSx={{ padding: 0 }}
                            rootFolderId={rootScopeId}
                            scopeIds={scopeIds}
                            scopeSelectable={scopeId => UserHelper.hasScopePermissions(scopeId, Contract.IdentityPermission.SecurityWrite)}
                            selectedScopeId={selectedScopeId}
                            onSelectedScopeIdChanged={
                                selectedScope => {
                                    setSelectedScopeId(selectedScope);
                                    onScopeChange?.(selectedScope);
                                }}/>
                    </Stack>
                </Stack>
                {!_.isNil(selectedReportItem?.configuration) &&
                    <Stack spacing={1}>
                        {selectedReportItem?.configuration}
                    </Stack>}
                <Stack spacing={1}>
                    {UserHelper.hasScopePermissions(selectedScopeId, Contract.IdentityPermission.SecurityWrite) &&
                        <Stack spacing={0.5}>
                            <Typography variant="h4">
                                {localization.fields.deliveryTime()}
                            </Typography>
                            {_.isNil(options?.reportScheduleConfiguration) &&
                                <RadioGroup
                                    items={[
                                        {
                                            label: localization.fields.type[ReportDeliveryType.Generate](),
                                            value: ReportDeliveryType.Generate
                                        },
                                        {
                                            label: localization.fields.type[ReportDeliveryType.Schedule](),
                                            value: ReportDeliveryType.Schedule
                                        }
                                    ]}
                                    selectedValue={reportDeliveryType}
                                    onChange={value => setReportDeliveryType(value)}/>}
                            {reportDeliveryType === ReportDeliveryType.Schedule &&
                                <Stack
                                    spacing={2}
                                    sx={{ padding: theme.spacing(1, 2) }}>
                                    <ItemSelector
                                        containerSx={{ width: theme.spacing(30) }}
                                        items={[
                                            Contract.ReportScheduleCadenceInterval.Daily,
                                            Contract.ReportScheduleCadenceInterval.Weekly,
                                            Contract.ReportScheduleCadenceInterval.Monthly
                                        ]}
                                        placeholder={localization.fields.cadence.placeholder()}
                                        selectedItem={cadenceInterval}
                                        sorted={false}
                                        onSelectedItemChanged={setCadenceInterval}>
                                        {(cadenceInterval: Contract.ReportScheduleCadenceInterval) => localization.fields.cadence[Contract.TypeNames.ReportScheduleCadenceInterval][cadenceInterval]()}
                                    </ItemSelector>
                                    {map(
                                        cadenceInterval,
                                        {
                                            [Contract.ReportScheduleCadenceInterval.Daily]:
                                                () =>
                                                    <Daily
                                                        hour={cadenceHour}
                                                        onChange={hour => setCadenceHour(hour)}/>,
                                            [Contract.ReportScheduleCadenceInterval.Monthly]:
                                                () =>
                                                    <Monthly
                                                        hour={cadenceHour}
                                                        monthDay={cadenceMonthDay}
                                                        onChange={
                                                            (hour, monthDay) => {
                                                                setCadenceHour(hour);
                                                                setCadenceMonthDay(monthDay);
                                                            }}/>,
                                            [Contract.ReportScheduleCadenceInterval.Weekly]:
                                                () =>
                                                    <Weekly
                                                        hour={cadenceHour}
                                                        weekDay={cadenceWeekDay}
                                                        onChange={
                                                            (hour, weekDay) => {
                                                                setCadenceHour(hour);
                                                                setCadenceWeekDay(weekDay);
                                                            }}/>
                                        },
                                        () => <Fragment/>)}
                                </Stack>}
                        </Stack>}
                    <Deliveries
                        createItem={createItem}
                        deliveries={deliveries}
                        disabled={scheduleOrGenerateExecuting}
                        scopeId={selectedScopeId}
                        typeNames={deliveryDerivedTypeNames}
                        onChange={onChange}/>
                </Stack>
                {!_.isNil(scheduleOrGenerateError) &&
                    <Message
                        level={
                            scheduleOrGenerateError === localization.actions.generate.error.timeout()
                                ? "info"
                                : "error"}
                        title={scheduleOrGenerateError}/>}
            </Stack>
        </FormLayout>);
}

export enum ReportDeliveryType {
    Generate = "generate",
    Schedule = "schedule"
}