import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function LucidchartIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M2 6V18L12.224 24V21.024L4.544 16.512V7.488L2 6Z"
                fill="#F96B13"/>
            <path
                d="M19.904 7.488V19.488L22.448 18V6L19.904 7.488Z"
                fill="#CC4E00"/>
            <path
                d="M9.68 1.488L19.904 7.488L22.448 6L12.224 0L9.68 1.488Z"
                fill="#FFCA73"/>
            <path
                d="M2 6L4.544 7.488L7.088 6L4.544 4.512L2 6Z"
                fill="#FFCA73"/>
            <path
                d="M4.544 7.488V16.464L7.088 14.976V6L4.544 7.488Z"
                fill="#CC4E00"/>
            <path
                d="M12.224 21.024V24L14.768 22.512V19.488L12.224 21.024Z"
                fill="#CC4E00"/>
            <path
                d="M4.544 16.512L12.224 21.024L14.768 19.536L7.088 15.024L4.544 16.512Z"
                fill="#FFCA73"/>
            <path
                d="M9.68 1.488V4.512L17.36 9.024V18.048L19.904 19.536V7.536L9.68 1.488Z"
                fill="#F96B13"/>
        </SvgIcon>);
}