import { useLocalization } from "@infrastructure";
import { useCallback } from "react";
import { Contract } from "../../../../../common";

export function usePermissionRequestCloseReasonAndStatusTranslator() {
    const localization =
        useLocalization(
            "views.user.permissionRequestCloseReasonAndStatusFilter.hooks.usePermissionRequestCloseReasonAndStatusTranslator",
            () => ({
                [Contract.TypeNames.PermissionRequestCloseReasonAndStatus]: {
                    [Contract.PermissionRequestCloseReasonAndStatus.ActivationFailed]: "Activation failed",
                    [Contract.PermissionRequestCloseReasonAndStatus.Active]: "Active",
                    [Contract.PermissionRequestCloseReasonAndStatus.Cancelled]: "Request cancelled",
                    [Contract.PermissionRequestCloseReasonAndStatus.Expired]: "Access expired",
                    [Contract.PermissionRequestCloseReasonAndStatus.PendingActivation]: "Pending activation",
                    [Contract.PermissionRequestCloseReasonAndStatus.PendingApproval]: "Pending approval",
                    [Contract.PermissionRequestCloseReasonAndStatus.Rejected]: "Request denied",
                    [Contract.PermissionRequestCloseReasonAndStatus.RequestExpired]: "Request timed-out",
                    [Contract.PermissionRequestCloseReasonAndStatus.Revoked]: "Access revoked"
                }
            }));

    return useCallback(
        (status: Contract.PermissionRequestCloseReasonAndStatus) => localization[Contract.TypeNames.PermissionRequestCloseReasonAndStatus][status](),
        [localization]);
}