import { Typography } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { EmptyMessage, TimeFormatter, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, getOrderedOpenBehaviorRisksPolicyDataItems, SummaryDashboardContext, TimeFrameSelector, useDashboardContext, useGetOpenBehaviorRiskPolicyMetadatas, useRiskPolicyTitleTranslator, useTheme, WidgetDefinition } from "../../../../../../..";
import { ChartWithLegend, LineLegendData } from "../components";

export function useGetOpenBehaviorRiskDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<SummaryDashboardContext>();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const getOpenBehaviorRiskPolicyMetadatas = useGetOpenBehaviorRiskPolicyMetadatas();

    const [timeFrame, setTimeFrame] = useState(Contract.TimeFrame.Medium);
    const orderedOpenBehaviorRiskPolicyDatas =
        useMemo(
            () => {
                const dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap =
                    _.isNil(summary.timeFrameToRiskTrendsDataMap)
                        ? {}
                        : summary.timeFrameToRiskTrendsDataMap[timeFrame].dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap;
                if (_.isEmpty(dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap)) {
                    return [];
                }

                const latestTimeFrameDate =
                    _(dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap).
                        toPairs().
                        maxBy(([date, _openBehaviorRiskPolicyConfigurationTypeNameToRiskCount]) => TimeFormatter.sortableDateTime(date))![0];
                const behaviorRiskPolicyConfigurationTypeNameToLatestRiskCountMap = dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap[latestTimeFrameDate];
                return _(dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap).
                    values().
                    flatMap(openBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap => _.keys(openBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap)).
                    uniq().
                    groupBy(openBehaviorRiskPolicyConfigurationTypeName => riskPolicyTitleTranslator(openBehaviorRiskPolicyConfigurationTypeName)).
                    map(
                        (openBehaviorRiskPolicyConfigurationTypeNames, openBehaviorRiskPolicyConfigurationTranslatedTypeName) => ({
                            latestCount:
                                _(openBehaviorRiskPolicyConfigurationTypeNames).
                                    map(openBehaviorRiskPolicyConfigurationTypeName => behaviorRiskPolicyConfigurationTypeNameToLatestRiskCountMap[openBehaviorRiskPolicyConfigurationTypeName]).
                                    sum(),
                            translatedTypeName: openBehaviorRiskPolicyConfigurationTranslatedTypeName,
                            typeNames: openBehaviorRiskPolicyConfigurationTypeNames
                        })).
                    orderBy(
                        ({ latestCount }) => latestCount,
                        "desc").
                    value();
            },
            [timeFrame]);

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetOpenBehaviorRiskDefinition",
            () => ({
                empty: "No Anomaly Findings",
                helpText: "Track suspicious activity in your environment by viewing the number of open anomaly-related findings over time (last 7, 30, or 90 days). View a breakdown of findings by activity category, which relates to a set of behavior patterns common to cloud identities. Ensure that relevant findings are resolved to improve your security posture.",
                legend: "{{translatedTypeName}} ({{latestCount | NumberFormatter.humanize}})",
                title: "Anomaly Detection"
            }));
    const theme = useTheme();
    const [orderedOpenBehaviorRiskPolicyMetadatas, openBehaviorRiskPolicyKeyToLegendDataMap] =
        useMemo(
            () => {
                const orderedOpenBehaviorRiskPolicyMetadatas =
                    getOpenBehaviorRiskPolicyMetadatas(
                        _.map(
                            orderedOpenBehaviorRiskPolicyDatas,
                            openBehaviorRiskPolicyData => openBehaviorRiskPolicyData.translatedTypeName));

                const openBehaviorRiskPolicyKeyToLegendDataMap =
                    _(orderedOpenBehaviorRiskPolicyDatas).
                        keyBy(openBehaviorRiskPolicyData => openBehaviorRiskPolicyData.translatedTypeName).
                        mapValues(
                            openBehaviorRiskPolicyData =>
                                new LineLegendData(
                                    <Typography sx={{ marginRight: theme.spacing(1) }}>
                                        {localization.legend({
                                            latestCount: openBehaviorRiskPolicyData.latestCount,
                                            translatedTypeName: openBehaviorRiskPolicyData.translatedTypeName
                                        })}
                                    </Typography>,
                                    openBehaviorRiskPolicyData.translatedTypeName.replaceAll(" ", ""),
                                    CustomerConsoleAppUrlHelper.getRisksUrl(
                                        Contract.RisksView.Open,
                                        {
                                            policyConfigurationTypeNameOrIds: openBehaviorRiskPolicyData.typeNames
                                        }))).
                        value();

                return [orderedOpenBehaviorRiskPolicyMetadatas, openBehaviorRiskPolicyKeyToLegendDataMap];
            },
            [orderedOpenBehaviorRiskPolicyDatas]);

    const orderedOpenBehaviorRisksPolicyDataItems =
        useMemo(
            () =>
                _.isNil(summary.timeFrameToRiskTrendsDataMap)
                    ? []
                    : getOrderedOpenBehaviorRisksPolicyDataItems(
                        summary.timeFrameToRiskTrendsDataMap[timeFrame].dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap,
                        orderedOpenBehaviorRiskPolicyDatas),
            [orderedOpenBehaviorRiskPolicyDatas, summary, timeFrame]);

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element:
                _.isEmpty(orderedOpenBehaviorRiskPolicyDatas)
                    ? <EmptyMessage
                        message={localization.empty()}
                        verticalCenter={true}/>
                    : <ChartWithLegend
                        lineKeyToLegendDataMap={openBehaviorRiskPolicyKeyToLegendDataMap}
                        lineMetadatas={orderedOpenBehaviorRiskPolicyMetadatas}
                        orderedLinesDataItems={orderedOpenBehaviorRisksPolicyDataItems}/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                topElement:
                    <TimeFrameSelector
                        timeFrame={timeFrame}
                        onTimeFrameChanged={setTimeFrame}/>
            }
        }),
        [localization, orderedOpenBehaviorRiskPolicyDatas, orderedOpenBehaviorRisksPolicyDataItems, openBehaviorRiskPolicyKeyToLegendDataMap, orderedOpenBehaviorRiskPolicyMetadatas, timeFrame]);
}