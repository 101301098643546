import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { OneLoginTenantsContext, useOneLoginTenantsContext, useSetOneLoginTenantsContext } from "../..";
import { Contract, LicensingHelper } from "../../../../../../../../../../../../../../common";
import { FinishItem, PermissionManagementClientCredentialsItem, ReadClientCredentialsItem, TenantItem } from "./components";

class AddOrEditContext {
    public tenantName: string;
    public tenantPermissionManagementClientId: string;
    public tenantPermissionManagementClientSecret?: string;
    public tenantReadClientId: string;
    public tenantReadClientSecret?: string;
    public tenantUrl: string;
    public updatedTenantModel?: Contract.OneLoginTenantModel;

    constructor(public tenantConfiguration?: Contract.OneLoginTenantConfiguration) {
        this.tenantPermissionManagementClientId = tenantConfiguration?.permissionManagementClientData?.id ?? "";
        this.tenantPermissionManagementClientSecret =
            _.isNil(tenantConfiguration?.permissionManagementClientData)
                ? ""
                : undefined;
        this.tenantName = tenantConfiguration?.name ?? "";
        this.tenantReadClientId = tenantConfiguration?.clientData.id ?? "";
        this.tenantReadClientSecret =
            _.isNil(tenantConfiguration)
                ? ""
                : undefined;
        this.tenantUrl = tenantConfiguration?.url ?? "";
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useOneLoginTenantsContext();
    const tenantConfiguration =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;

    const setOneLoginTenantsContext = useSetOneLoginTenantsContext();
    const [, , ContextProvider] = useAddOrEditContextProvider(() => new AddOrEditContext(tenantConfiguration));
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oneLoginTenants.addOrEdit",
            () => ({
                steps: {
                    permissionManagementClientCredentials: "Grant JIT Access (Optional)",
                    readClientCredentials: "Grant Access",
                    tenant: "Account Details"
                },
                title: {
                    add: "Add OneLogin Account",
                    edit: "Edit OneLogin Account"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(tenantConfiguration)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    onClose={() => setOneLoginTenantsContext(new OneLoginTenantsContext(false))}>
                    <OrderedWizardItem title={localization.steps.tenant()}>
                        <TenantItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.readClientCredentials()}>
                        <ReadClientCredentialsItem/>
                    </OrderedWizardItem>
                    {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement) &&
                        <OrderedWizardItem
                            deferredLoading={true}
                            title={localization.steps.permissionManagementClientCredentials()}>
                            <PermissionManagementClientCredentialsItem/>
                        </OrderedWizardItem>}
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}