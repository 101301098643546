import { Optional, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonNetworkingInfoItemElements, useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, entityModelStore, InfoItem, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { GcpRoleBindingTable } from "../../../../../../../../../../tenants";
import { useGcpComputeLoadBalancerTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoCard, EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { RulesInfoCard } from "./useGcpComputeUrlMapDefinition/components";

export function useGcpComputeLoadBalancerDefinition(entityModel: Contract.GcpEntityModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(entityModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(entityModel as Contract.GcpResourceModel);
    const loadBalancerModel = entityModel as Contract.GcpComputeLoadBalancerModel;
    const loadBalancer = loadBalancerModel.entity as Contract.GcpComputeLoadBalancer;
    const forwardingRuleModels = entityModelStore.useGet(loadBalancer.resources.forwardingRuleIds) as Contract.GcpComputeForwardingRuleModel[];
    const networkInfoItems =
        useGcpCommonNetworkingInfoItemElements({
            publicIpAddresses:
                _.map(
                    forwardingRuleModels,
                    forwardingRuleModel => (forwardingRuleModel.entity as Contract.GcpComputeForwardingRule).ipAddress),
            resourceModel: (entityModel as Contract.GcpScopeResourceModel)
        });

    const backendResourceModels = entityModelStore.useGet(loadBalancer.resources.backendResourceIdReferences);
    const backendBucketModels =
        _.filter(
            backendResourceModels,
            backendResourceModel =>
                TypeHelper.extendOrImplement(
                    backendResourceModel.entity.typeName,
                    Contract.TypeNames.GcpComputeBackendBucket)) as Contract.GcpComputeBackendBucketModel[];
    const backendServiceModels =
        _.filter(
            backendResourceModels,
            backendResourceModel =>
                TypeHelper.extendOrImplement(
                    backendResourceModel.entity.typeName,
                    Contract.TypeNames.GcpComputeBackendService)) as Contract.GcpComputeBackendServiceModel[];
    const targetPoolModel = entityModelStore.useGet(loadBalancer.resources.targetPoolId) as Optional<Contract.GcpComputeTargetPoolModel>;
    const targetProxyModels = entityModelStore.useGet(loadBalancer.resources.targetProxyIds) as Contract.GcpComputeTargetProxyModel[];
    const urlMapModel = entityModelStore.useGet(loadBalancer.resources.urlMapId) as Optional<Contract.GcpComputeUrlMapModel>;
    const targetHttpsOrSslProxyModels =
        _.filter(
            targetProxyModels,
            targetProxyModel =>
                TypeHelper.extendOrImplement(
                    targetProxyModel.entity.typeName,
                    Contract.TypeNames.GcpComputeTargetHttpsProxy,
                    Contract.TypeNames.GcpComputeTargetSslProxy)) as Contract.GcpComputeTargetHttpsProxyModel[] | Contract.GcpComputeTargetSslProxyModel[];
    const sslCertificateIds =
        _(targetHttpsOrSslProxyModels).
            flatMap(targetProxyModel => targetProxyModel.sslCertificateIds).
            uniq().
            value();
    const sslPolicyIds =
        _(targetHttpsOrSslProxyModels).
            map(targetProxyModel => targetProxyModel.sslPolicyId).
            uniq().
            value();
    const resourceTypeNameToIdsMap: Dictionary<string[]> = {
        [Contract.TypeNames.GcpComputeBackendBucket]:
            _.map(
                backendBucketModels,
                backendBucketModel => backendBucketModel.id),
        [Contract.TypeNames.GcpComputeBackendService]:
            _.map(
                backendServiceModels,
                backendServiceModel => backendServiceModel.id),
        [Contract.TypeNames.GcpComputeForwardingRule]: loadBalancer.resources.forwardingRuleIds,
        [Contract.TypeNames.GcpComputeTargetPool]:
            loadBalancer.resources.targetPoolId
                ? [loadBalancer.resources.targetPoolId]
                : [],
        [Contract.TypeNames.GcpComputeTargetProxy]: loadBalancer.resources.targetProxyIds ?? [],
        [Contract.TypeNames.GcpComputeUrlMap]:
            loadBalancer.resources.urlMapId
                ? [loadBalancer.resources.urlMapId]
                : []
    };

    const computeLoadBalancerTypeTranslator = useGcpComputeLoadBalancerTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeLoadBalancerDefinition",
            () => ({
                info: {
                    cards: {
                        backendResourceIds: "Backends",
                        instanceIdReferences: "VM Instances",
                        resources: {
                            emptyMessage: "No Components",
                            title: "Components"
                        }
                    },
                    items: {
                        creationTime: "Creation Time",
                        creator: {
                            identity: "Identity: {{creatorIdentity}}",
                            originator: "Originator: {{creatorOriginatorEntity}}",
                            title: "Created By"
                        },
                        dnsZoneIds: "DNS Zones",
                        forwardingRuleIpAddresses: "IP Addresses",
                        protocols: {
                            [Contract.TypeNames.GcpComputeLoadBalancerProtocol]: {
                                [Contract.GcpComputeLoadBalancerProtocol.Http]: "HTTP",
                                [Contract.GcpComputeLoadBalancerProtocol.Https]: "HTTPS",
                                [Contract.GcpComputeLoadBalancerProtocol.Ssl]: "SSL",
                                [Contract.GcpComputeLoadBalancerProtocol.Tcp]: "TCP",
                                [Contract.GcpComputeLoadBalancerProtocol.Udp]: "UDP",
                                [Contract.GcpComputeLoadBalancerProtocol.Unspecified]: "Unspecified"
                            },
                            title: "Protocols"
                        },
                        sslCertificateIds: "SSL Certificates",
                        sslPolicyIds: {
                            default: "Default",
                            title: "SSL Policies"
                        },
                        type: "Load Balancer Type"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpRoleBindingTable
                    csvExportFilePrefixes={[
                        entityTypeNameTranslator(loadBalancerModel.entity.typeName, { includeServiceName: false }),
                        loadBalancerModel.entity.displayName,
                        localization.tabs.resourceRoleBindings()
                    ]}
                    principalOrResourceId={loadBalancerModel.entity.id}
                    roleBindingIds={
                        _.union(
                            _.flatMap(backendBucketModels, backendBucketModel => backendBucketModel.roleBindingIds),
                            _.flatMap(backendServiceModels, backendServiceModel => backendServiceModel.roleBindingIds),
                            _.flatMap(forwardingRuleModels, forwardingRuleModel => forwardingRuleModel.roleBindingIds),
                            targetPoolModel?.roleBindingIds,
                            _.flatMap(targetProxyModels, targetProxyModel => targetProxyModel.roleBindingIds),
                            urlMapModel?.roleBindingIds)}
                    variant="principalAndRoleAndScope"/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={computeLoadBalancerTypeTranslator(loadBalancer.type)}/>,
                    <InfoItem
                        key="protocols"
                        title={localization.info.items.protocols.title()}
                        value={
                            _(loadBalancer.protocols).
                                map(protocol => localization.info.items.protocols[Contract.TypeNames.GcpComputeLoadBalancerProtocol][protocol]()).
                                join(", ")}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={sslCertificateIds}
                        entityTypeName={Contract.TypeNames.GcpComputeSslCertificate}
                        key="sslCertificateIds"
                        title={localization.info.items.sslCertificateIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={
                            _.map(
                                sslPolicyIds,
                                sslPolicyId => sslPolicyId ?? localization.info.items.sslPolicyIds.default())}
                        entityTypeName={Contract.TypeNames.GcpComputeSslPolicy}
                        key="sslPolicyId"
                        title={localization.info.items.sslPolicyIds.title()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={loadBalancerModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.inboundAccessType}
                    {networkInfoItems.inboundExternalAccessScope}
                    {networkInfoItems.getPublicIpAddresses()}
                </NetworkingInfoCard>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={resourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
                {!_.isNil(urlMapModel) &&
                    <RulesInfoCard
                        backendResourceUrlToSearchableIdReferenceMap={urlMapModel.backendResourceUrlToSearchableIdReferenceMap}
                        rules={(urlMapModel.entity as Contract.GcpComputeUrlMap).rules}/>}
                {!_.isEmpty(backendServiceModels) &&
                    <EntitiesInfoCard
                        entityIdsOrModels={
                            _.flatMap(
                                backendServiceModels,
                                backendServiceModel => backendServiceModel.backendResourceIds)}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        title={localization.info.cards.backendResourceIds()}/>}
                {!_.isNil(targetPoolModel) &&
                    <EntitiesInfoCard
                        entityIdsOrModels={targetPoolModel.instanceIdReferences}
                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                        title={localization.info.cards.instanceIdReferences()}/>}
            </Info>
    });
}