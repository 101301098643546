import { UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { useCallback } from "react";
import { Contract, IdentityRoleHelper, useIdentityRoleTranslator } from "../../../common";

export function useIdentityPermissionsTranslator() {
    const identityRoleTranslator = useIdentityRoleTranslator();
    return useCallback(
        (permissions: Contract.IdentityPermission[]) => {
            let identityRole: Contract.IdentityRole;
            if (
                _(IdentityRoleHelper.getIdentityRoleCustomerConsoleAppPermissions(Contract.IdentityRole.Administrator)).
                    difference(permissions).
                    isEmpty()) {
                identityRole = Contract.IdentityRole.Administrator;
            } else if (
                _(IdentityRoleHelper.getIdentityRoleCustomerConsoleAppPermissions(Contract.IdentityRole.Collaborator)).
                    difference(permissions).
                    isEmpty()) {
                identityRole = Contract.IdentityRole.Collaborator;
            } else if (
                _(IdentityRoleHelper.getIdentityRoleCustomerConsoleAppPermissions(Contract.IdentityRole.Viewer)).
                    difference(permissions).
                    isEmpty()) {
                identityRole = Contract.IdentityRole.Viewer;
            } else {
                throw new UnexpectedError("permissions", _.join(permissions, ","));
            }

            return identityRoleTranslator(identityRole);
        },
        [identityRoleTranslator]);
}