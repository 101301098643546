import { useLocalization, Wizard, WizardItem } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore, RiskTypeMetadataModelHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { CodeResolutionContext, useCodeResolutionContextProvider } from "./CodeResolutionContext";
import { FinishItem, InfoItem, PullRequestItem } from "./components";

export type CodeResolutionAutomationProps = {
    changeModels: Contract.ChangeModel[];
    codeTenantModel: Contract.CodeTenantModel;
    onFinished: (canceled: boolean) => void;
    riskModel: Contract.RiskModel;
};

export function CodeResolution({ changeModels, codeTenantModel, onFinished, riskModel }: CodeResolutionAutomationProps) {
    const riskedEntityModel = entityModelStore.useGet((riskModel.risk as Contract.EntityRisk).entityId);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const [, , ContextProvider] = useCodeResolutionContextProvider(() => new CodeResolutionContext());

    const localization =
        useLocalization(
            "views.customer.risks.profile.core.resolutionCategory.resolveCode.codeResolution",
            () => ({
                info: "The following steps are required in order to perform the remediation. Select a step to view more details about it",
                title: "**Create Pull Request Remediation** for {{translatedEntityTypeName}} **{{entityDisplayName}}**"
            }));

    return (
        <ContextProvider>
            <Wizard
                titleOptions={{
                    text:
                        localization.title({
                            entityDisplayName: riskedEntityModel.entity.displayName,
                            translatedEntityTypeName:
                                entityTypeNameTranslator(
                                    riskedEntityModel.entity.typeName,
                                    {
                                        includeServiceName: false,
                                        variant: "title"
                                    })
                        })
                }}
                onFinished={onFinished}>
                <WizardItem
                    disablePadding={true}
                    subtitle={localization.info()}>
                    <InfoItem
                        changeModels={changeModels}
                        entityId={riskedEntityModel.id}/>
                </WizardItem>
                <WizardItem>
                    <PullRequestItem
                        codeTenantModel={codeTenantModel}
                        entityDisplayName={riskedEntityModel.entity.displayName}
                        riskId={riskModel.id}
                        riskPolicyConfigurationTypeName={RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).policyConfigurationTypeName}/>
                </WizardItem>
                <WizardItem>
                    <FinishItem/>
                </WizardItem>
            </Wizard>
        </ContextProvider>);
}