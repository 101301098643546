import { ActionButton, Link } from "@infrastructure";
import React from "react";
import { DocumentationIcon } from "..";

type DocButtonProps = {
    path: string;
};

export function DocumentationButton({ path }: DocButtonProps) {
    return (
        <ActionButton size="small">
            <Link
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center"
                }}
                urlOrGetUrl={path}>
                <DocumentationIcon sx={{ fontSize: "16px" }}/>
            </Link>
        </ActionButton>);
}