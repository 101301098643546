import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsTenantEntityRiskContext } from "../contexts";

export function useAwsIamSupportRoleNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamSupportRoleNotExistRisk;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AwsTenantEntityModel;

    const getAwsTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamSupportRoleNotExistRiskDefinition",
            () => ({
                description: "{{tenantEntity}} support role is not set",
                sections: {
                    resolution: {
                        step1: "In the menu, click **Roles** and then click **Create role**",
                        step2: "For **Trusted entity type**, select **AWS account**",
                        step3: "If the user that will assume this role is in the same account, select **This account**. Otherwise, select **Another AWS account** and enter the account id",
                        step4: "Click **Next**",
                        step5: "In the **Permissions policies**, search for managed policy **AWSSupportAccess** and select it",
                        step6: "Click **Next**",
                        step7: "For **Role name**, enter a name for your role",
                        step8: "Click **Create role**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamUrl(tenantConfiguration.partitionType)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8()
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getAwsTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}