﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpKmsKeyRingRiskContext() {
    return useMemo(
        () => useGcpKmsKeyRingRiskContext,
        []);
}

function useGcpKmsKeyRingRiskContext(keyRingModel: Contract.GcpKmsKeyRingModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(keyRingModel);
    const keyRing = keyRingModel.entity as Contract.GcpKmsKeyRing;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpKmsKeyRingRiskContext",
            () => ({
                keys: "The {{translatedKeyRingTypeName}} contains {{keys}}"
            }));

    return {
        ...entityRiskContext,
        keys:
            _.isEmpty(keyRingModel.keyIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.keys({
                        keys:
                            <InlineEntities
                                entityIdsOrModels={keyRingModel.keyIds}
                                entityTypeName={Contract.TypeNames.GcpKmsKeyRing}
                                variant="itemCountAndType"/>,
                        translatedKeyRingTypeName:
                            entityTypeNameTranslator(
                                keyRing.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })
                )
    };
}