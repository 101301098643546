﻿import { useChangeEffect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Contract, UserHelper, useTheme } from "../../../../../../../../common";
import { useConfigurationContext, useSetConfigurationContext } from "../../Configuration";
import { CodeExclusionItem, CodeExclusions } from "./components";

export class CodeExclusionSection {
    constructor(
        public items: CodeExclusionItem[],
        public valid: boolean) {
    }
}

type CodeExclusionProps = {
    parentRiskPolicyModels?: Contract.RiskPolicyModel[];
    scopeRiskPolicyModel: Contract.RiskPolicyModel;
};

export function CodeExclusion({ parentRiskPolicyModels, scopeRiskPolicyModel }: CodeExclusionProps) {
    const { codeExclusionSection } = useConfigurationContext();
    const setConfigurationContext = useSetConfigurationContext();

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.codeExclusion",
            () => ({
                description: "Exclude paths from code based scans to prevent potential findings associated with resources in files located in those paths from being created. For each path, enter a pattern you would like to exclude. If the pattern matches the path, Tenable Cloud Security will exclude the path.\nFor example, */*keys.tf.",
                patternInfo: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character."
            }));

    const parentFileRelativePathPatternToScopeIdsMap =
        useMemo(
            () =>
                _(parentRiskPolicyModels).
                    flatMap(
                        riskPolicyModel =>
                            _.map(
                                riskPolicyModel.riskPolicyConfiguration.codeExclusions,
                                codeExclusion => ({
                                    codeExclusion,
                                    scopeId: riskPolicyModel.riskPolicyConfiguration.scopeId
                                }))).
                    groupBy(({ codeExclusion }) => codeExclusion.fileRelativePathPattern).
                    mapValues(
                        codeExclusionToDatasGroup =>
                            _.map(
                                codeExclusionToDatasGroup,
                                ({ scopeId }) => scopeId)).
                    value(),
            [parentRiskPolicyModels]);


    const [codeExclusionItems, setCodeExclusionItems] =
        useState(
            codeExclusionSection?.items ??
            _(parentRiskPolicyModels).
                map(parentRiskPolicyModel => parentRiskPolicyModel.riskPolicyConfiguration.codeExclusions).
                concat(scopeRiskPolicyModel.riskPolicyConfiguration.codeExclusions).
                flatten().
                map(
                    codeExclusion =>
                        new CodeExclusionItem(
                            codeExclusion,
                            false,
                            false,
                            false,
                            undefined,
                            !_.isNil(parentFileRelativePathPatternToScopeIdsMap[codeExclusion.fileRelativePathPattern!]),
                            false)).
                value());

    const [codeExclusionsValid, setCodeExclusionsValid] = useState(codeExclusionSection?.valid ?? false);

    useChangeEffect(
        () => {
            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    dirty: true
                }));
        },
        [codeExclusionItems]);

    useEffect(
        () => {
            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    codeExclusionSection: {
                        items: codeExclusionItems,
                        valid: codeExclusionsValid
                    }
                }));
        },
        [codeExclusionItems, codeExclusionsValid]);

    const userSecurityWrite =
        useMemo(
            () =>
                UserHelper.hasScopePermissions(
                    scopeRiskPolicyModel.riskPolicyConfiguration.scopeId,
                    Contract.IdentityPermission.SecurityWrite),
            [scopeRiskPolicyModel.riskPolicyConfiguration.scopeId]);

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                padding: theme.spacing(2, 2, 2, 3)
            }}>
            <Fragment>
                <Typography sx={{ maxWidth: "55%" }}>
                    {localization.description()}
                </Typography>
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                    {localization.patternInfo()}
                </Typography>
            </Fragment>
            <CodeExclusions
                items={codeExclusionItems}
                parentFileRelativePathPatternToScopeIdsMap={parentFileRelativePathPatternToScopeIdsMap}
                readOnly={!userSecurityWrite}
                onItemsChanged={items => setCodeExclusionItems(items)}
                onValidChanged={valid => setCodeExclusionsValid(valid)}/>
        </Stack>);
}