import { InlineItems, Link, Loading, Steps, useLocalization } from "@infrastructure";
import { Box, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, InlineEntities, Network, NetworkScopeFormatter, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../common/components/Network/components";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useResourceGeneralInformationStep } from "../../../useResourceGeneralInformationStep";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureInboundExternalComputeVirtualMachineScaleSetRiskDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalComputeVirtualMachineScaleSetRiskModel = riskModel as Contract.AzureInboundExternalComputeVirtualMachineScaleSetRiskModel;
    const virtualMachineScaleSetModel = entityModelStore.useGet(inboundExternalComputeVirtualMachineScaleSetRiskModel.risk.entityId) as Contract.AzureComputeVirtualMachineScaleSetModel;
    const virtualMachineScaleSet = virtualMachineScaleSetModel.entity as Contract.AzureComputeVirtualMachineScaleSet;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureInboundExternalComputeVirtualMachineScaleSetRiskDefinition",
            () => ({
                description: "{{virtualMachineScaleSet}} is accessible directly from a wide range of public IP addresses",
                sections: {
                    networkGraph: "Network",
                    resolution: {
                        step1: {
                            link: "Create, change, or delete an Azure network security group",
                            text: "Before changing any network security group inbound rule, verify that it will not break existing communications, see {{createChangeOrDeleteAnAzureNetworkSecurityGroup}}"
                        },
                        step2: "For each public inbound rule, delete the rule and replace it with an inbound rule that limits access to specific IP addresses"
                    }
                }
            }));
    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                virtualMachineScaleSet:
                    <Entity
                        entityIdOrModel={virtualMachineScaleSetModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            <EntityExternalConsoleLink
                entityId={inboundExternalComputeVirtualMachineScaleSetRiskModel.risk.entityId}
                key={inboundExternalComputeVirtualMachineScaleSetRiskModel.risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            localization.sections.resolution.step1.text({
                createChangeOrDeleteAnAzureNetworkSecurityGroup:
                    <Link
                        urlOrGetUrl={inboundExternalComputeVirtualMachineScaleSetRiskModel.manageNetworkSecurityGroupDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    risk={inboundExternalComputeVirtualMachineScaleSetRiskModel.risk}
                    virtualMachineScaleSetModel={virtualMachineScaleSetModel}/>,
            sections:
                virtualMachineScaleSetModel.unknown || virtualMachineScaleSet.systemDeleted
                    ? undefined
                    : [
                        new RiskDefinitionSection(
                            <Box sx={{ minHeight: theme.spacing(20) }}>
                                <Loading>
                                    <Network
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(inboundExternalComputeVirtualMachineScaleSetRiskModel.risk.id)}
                                        entityId={inboundExternalComputeVirtualMachineScaleSetRiskModel.risk.entityId}
                                        initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                        tenantType={Contract.TenantType.Azure}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            localization.sections.networkGraph(),
                            {
                                profilePageOnly: true
                            })
                    ]
        });
}

type ContextSectionProps = {
    risk: Contract.AzureInboundExternalComputeVirtualMachineScaleSetRisk;
    virtualMachineScaleSetModel: Contract.AzureComputeVirtualMachineScaleSetModel;
};

function ContextSection({ risk, virtualMachineScaleSetModel }: ContextSectionProps) {
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(virtualMachineScaleSetModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureInboundExternalComputeVirtualMachineScaleSetRiskDefinition.ContextSection",
            () => ({
                exposedManagementNetworkScopes: [
                    "1 public management port range",
                    "{{count | NumberFormatter.humanize}} public management port ranges"
                ],
                exposedNetworkScopes: [
                    "1 public port range",
                    "{{count | NumberFormatter.humanize}} public port ranges"
                ],
                infoNetwork: "The scale set is associated with {{networkSecurityGroups}}, exposing it to {{exposedNetworkScopes}}",
                infoRunningInstances: {
                    all: "The scale set contains {{instances}}, all instances are in running state",
                    none: "The scale set contains {{instances}}, no instances are in running state",
                    some: "The scale set contains {{instances}}, {{runningInstances}} are in running state"
                },
                instances: [
                    "1 instance",
                    "{{count | NumberFormatter.humanize}} instances"
                ]
            }));
    return <Steps variant="bullets">
        {resourceGeneralInformationStep}
        {localization.infoNetwork({
            exposedNetworkScopes:
                <InlineItems
                    items={
                        _.map(
                            risk.exposedNetworkScopes,
                            networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                    namePluralizer={localization.exposedNetworkScopes}
                    variant="itemCountAndType"/>,
            networkSecurityGroups:
                <InlineEntities
                    entityIdsOrModels={
                        _(risk.interfaceNetworkSecurityGroupIds).
                            concat(risk.subnetNetworkSecurityGroupIds).
                            uniq().
                            value()}
                    entityTypeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}
                    variant="itemAndTypeOrItemCountAndType"/>
        })}
        {localization.infoRunningInstances[
            risk.runningVirtualMachineIds.length === 0
                ? "none"
                : risk.runningVirtualMachineIds.length === risk.virtualMachineIds.length
                    ? "all"
                    : "some"]({
            instances:
                <InlineEntities
                    entityIdsOrModels={risk.virtualMachineIds}
                    entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine}
                    namePluralizer={localization.instances}
                    variant="itemCountAndType"/>,
            runningInstances:
                <InlineEntities
                    entityIdsOrModels={risk.runningVirtualMachineIds}
                    entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine}
                    namePluralizer={localization.instances}
                    variant="itemCountAndType"/>
        })}
        {severityReasonTranslator(
            risk.severity,
            risk.severityReason!,
            {
                exposedManagementNetworkScopes:
                    <InlineItems
                        items={
                            _.map(
                                risk.exposedManagementNetworkScopes,
                                networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                        namePluralizer={localization.exposedManagementNetworkScopes}
                        variant="itemCountAndType"/>,
                exposedNetworkScopes:
                    <InlineItems
                        items={
                            _.map(
                                risk.exposedNetworkScopes,
                                networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                        namePluralizer={localization.exposedNetworkScopes}
                        variant="itemCountAndType"/>,
                servicePrincipalPermissionsSeverity:
                    _.isNil(risk.servicePrincipalPermissionsActionSeverity)
                        ? undefined
                        : severityTranslator(
                            risk.servicePrincipalPermissionsActionSeverity,
                            "text")
            })}
    </Steps>;
}