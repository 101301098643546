import { DataTableColumn, DataTableSortType, DeferredFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsSecretsManagerSecretDefinition(definitionData: DefinitionData) {
    const secretModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsSecretsManagerSecretModelFilters>;
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsSecretsManagerSecretDefinition",
            () => ({
                columns: {
                    usageTime: {
                        empty: "Never",
                        title: "Usage Time"
                    }
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                commonAccessResourceDefinition.columns.accessLevelColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsSecretsManagerSecretModel) => ({
                                [localization.columns.usageTime.title()]:
                                    _.isNil((item.entity as Contract.AwsSecretsManagerSecret).usageTime)
                                        ? localization.columns.usageTime.empty()
                                        : TimeFormatter.iso8601String((item.entity as Contract.AwsSecretsManagerSecret).usageTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={secretModelFiltersPromise}
                                    title={localization.columns.usageTime.title()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.usageTimeRange.emptyValue}
                                            placeholder={localization.columns.usageTime.title()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.usageTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsSecretsManagerSecretUsageTime}
                    itemProperty={
                        (item: Contract.AwsResourceModel) =>
                            _.isNil((item.entity as Contract.AwsSecretsManagerSecret).usageTime)
                                ? localization.columns.usageTime.empty()
                                : TimeFormatter.monthDayAndYear((item.entity as Contract.AwsSecretsManagerSecret).usageTime)}
                    key={Contract.EntityModelProperty.AwsSecretsManagerSecretUsageTime}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.usageTime.title()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsSecretsManagerSecretRequest(
                new Contract.EntityControllerGetEntityModelPageAwsSecretsManagerSecretRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsSecretsManagerSecretUsageTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}