import { AppError, Optional, useLayoutChangeEffect } from "@infrastructure";
import _, { Function0 } from "lodash";
import React, { ComponentType, createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";

class ContextProviderValue<TValue> {
    constructor(
        public value: TValue,
        public setValue: Dispatch<SetStateAction<TValue>>) {
    }
}

export function makeContextProvider<TValue>(): [
    Function0<TValue>,
    Function0<Dispatch<SetStateAction<TValue>>>,
    (getValue?: Function0<TValue>, dependencies?: any[]) => [TValue, Dispatch<SetStateAction<TValue>>, ComponentType<any>]
] {
    const context = createContext<Optional<ContextProviderValue<TValue>>>(undefined);

    const useContextValue = (): TValue => {
        const contextValue = useContext(context);
        if (_.isNil(contextValue)) {
            throw new AppError("Context value not set");
        }

        return contextValue.value;
    };

    const useSetContextValue = (): Dispatch<SetStateAction<TValue>> => {
        const contextValue = useContext(context);
        if (_.isNil(contextValue)) {
            throw new AppError("Context value not set");
        }

        return contextValue.setValue;
    };

    const useContextProvider = (getValue?: Function0<TValue>, dependencies?: any[]): [TValue, Dispatch<SetStateAction<TValue>>, ComponentType<any>] => {
        const [contextValue, setContextValue] = useState<TValue>(getValue!);
        const contextValueRef = useRef(contextValue);
        contextValueRef.current = contextValue;

        const setContextProviderRefreshRef = useRef<Dispatch<SetStateAction<TValue>>>();
        useEffect(
            () => setContextProviderRefreshRef.current?.(contextValue),
            [contextValue]);

        const ContextProvider =
            useMemo(
                () =>
                    function ContextProvider({ children }: ContextProviderProps) {
                        const [, setRefresh] = useState<TValue>(contextValue);
                        setContextProviderRefreshRef.current = setRefresh;

                        const contextProviderValue =
                            useMemo(
                                () => new ContextProviderValue<TValue>(contextValueRef.current, setContextValue),
                                [contextValueRef.current]);

                        return (
                            <context.Provider value={contextProviderValue}>
                                {!_.isNil(contextValueRef.current) &&
                                    children}
                            </context.Provider>);
                    },
                []);

        useLayoutChangeEffect(
            () => {
                if (!_.isNil(getValue)) {
                    setContextValue(getValue());
                }
            },
            dependencies ?? []);

        return [contextValue, setContextValue, ContextProvider];
    };

    return [useContextValue, useSetContextValue, useContextProvider];
}

type ContextProviderProps = {
    children: ReactNode;
};