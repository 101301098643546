import { SxProps } from "@mui/material";
import React, { Fragment } from "react";
import { Contract, NvdIcon, TenableIcon } from "../../../../../../../../../../../../common";

type VulnerabilityReferenceAuthorityIconProps = {
    authority: Contract.VulnerabilityReferenceAuthority;
    sx?: SxProps;
};

export function VulnerabilityReferenceAuthorityIcon({ authority, sx }: VulnerabilityReferenceAuthorityIconProps) {
    switch(authority) {
        case Contract.VulnerabilityReferenceAuthority.Nvd:
            return <NvdIcon sx={sx}/>;
        case Contract.VulnerabilityReferenceAuthority.TenableResearch:
            return <TenableIcon sx={sx}/>;
        default:
            return <Fragment/>;
    }
}