import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo } from "react";
import { CheckboxField, Optional, StringHelper, useLocalization } from "@infrastructure";
import { Contract, FolderIcon, OrganizationIcon, RadioGroup, ScopeHelper, scopeNodeModelStore, TenantIcon, Tree, TreeItem } from "../../../../../../../../../common";
import { SearchTextFieldElementClass } from "../../../../../../../../../infrastructure/components/inputs/inputs.element";

type OrganizationMemberSelectionProps = {
    codeTenantType?: Contract.CodeTenantType.AzureDevOps | Contract.CodeTenantType.Bitbucket | Contract.CodeTenantType.GitHub | Contract.CodeTenantType.GitLab;
    onError: (error?: string) => void;
    onSelectedTenantIdsChanged: (selectedTenantIds: string[]) => void;
    onSelectionTypeChanged: (selection: Optional<Contract.OrganizationMemberSelectionType>) => void;
    scopeNodeRootTreeItem: TreeItem<Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode>;
    selectedTenantIds: string[];
    selectionType: Optional<Contract.OrganizationMemberSelectionType>;
    tenantType: OrganizationMemberSelectionTenantTypes;
};

type OrganizationMemberSelectionTenantTypes =
    Contract.TenantType.Aws |
    Contract.TenantType.Azure |
    Contract.TenantType.Code |
    Contract.TenantType.Gcp |
    Contract.TenantType.Oci;

export function OrganizationMemberSelection({ codeTenantType, onError, onSelectedTenantIdsChanged, onSelectionTypeChanged, scopeNodeRootTreeItem, selectedTenantIds, selectionType, tenantType }: OrganizationMemberSelectionProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap([tenantType]);

    const itemsCount =
        useMemo(
            () => _.size(scopeNodeRootTreeItem.leaves()),
            [scopeNodeRootTreeItem]);

    function toggle(organizationMemberDatasNodeTreeItem: TreeItem<Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode>) {
        const rawTenantIds =
            _.map(
                organizationMemberDatasNodeTreeItem.leaves(),
                ({ value: { rawId } }) => rawId);

        const itemChecked =
            !_.some(
                rawTenantIds,
                rawTenantId =>
                    !_.includes(
                        selectedTenantIds,
                        rawTenantId));

        const selectedRawTenantIds =
            itemChecked
                ? _.difference(selectedTenantIds, rawTenantIds)
                : _.union(selectedTenantIds, rawTenantIds);

        onSelectedTenantIdsChanged(selectedRawTenantIds);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.organizationMemberSelection",
            () => ({
                description: "Choose which {{name}} to onboard",
                errors: {
                    excludeAll: "Excluding all {{name}} is not allowed"
                },
                fields: {
                    scopes: {
                        all: "Sync all {{name}} (recommended)",
                        exclude: "Exclude specific {{name}}",
                        include: "Include specific {{name}}"
                    }
                },
                scope: {
                    [Contract.OrganizationMemberSelectionType.Exclude]: "excluded",
                    [Contract.OrganizationMemberSelectionType.Include]: "included"
                },
                search: "Search",
                searchResult: "{{selected}}/{{total}} {{name}} are **{{scope}}**",
                tenants: {
                    cloud: {
                        [Contract.TenantType.Aws]: {
                            all: "accounts",
                            description: "Organization Units (OUs) and accounts",
                            inclusion: "OUs and accounts"
                        },
                        [Contract.TenantType.Azure]: {
                            all: "subscriptions",
                            description: "folders and subscriptions",
                            inclusion: "folders and subscriptions"
                        },
                        [Contract.TenantType.Gcp]: {
                            all: "projects",
                            description: "folders and projects",
                            inclusion: "folders and projects"
                        },
                        [Contract.TenantType.Oci]: {
                            all: "compartments",
                            description: "folders and compartments",
                            inclusion: "folders and compartments"
                        }
                    },
                    code: {
                        [Contract.CodeTenantType.GitHub]: {
                            all: "repositories",
                            description: "folders and repositories",
                            inclusion: "folders and repositories"
                        },
                        [Contract.CodeTenantType.GitLab]: {
                            all: "projects",
                            description: "folders and projects",
                            inclusion: "folders and projects"
                        },
                        [Contract.CodeTenantType.Bitbucket]: {
                            all: "repositories",
                            description: "folders and repositories",
                            inclusion: "folders and repositories"
                        },
                        [Contract.CodeTenantType.AzureDevOps]: {
                            all: "repositories",
                            description: "folders and repositories",
                            inclusion: "folders and repositories"
                        }
                    }
                },
                title: "Scopes"
            }));

    useEffect(
        () => {
            const allItemsExcluded =
                selectionType === Contract.OrganizationMemberSelectionType.Exclude &&
                selectedTenantIds.length === itemsCount;
            onError(
                allItemsExcluded
                    ? localization.errors.excludeAll({ name: localizedTenant.all() })
                    : undefined);
        },
        [selectedTenantIds, selectionType]);

    const localizedTenant =
        _.isNil(codeTenantType)
            ? localization.tenants.cloud[tenantType as Exclude<OrganizationMemberSelectionTenantTypes, Contract.TenantType.Code>]
            : localization.tenants.code[codeTenantType];

    const theme = useTheme();
    return (
        <Fragment>
            <Typography variant="h3">
                {localization.title()}
            </Typography>
            <Typography variant="body1">
                {localization.description({ name: localizedTenant.description() })}
            </Typography>
            <RadioGroup<Contract.OrganizationMemberSelectionType | "">
                items={[
                    {
                        disabled: false,
                        label: localization.fields.scopes.all({ name: localizedTenant.all() }),
                        value: ""
                    },
                    ...(tenantType === Contract.TenantType.Oci
                        ? []
                        : [{
                            disabled: false,
                            label: localization.fields.scopes.include({ name: localizedTenant.inclusion() }),
                            value: Contract.OrganizationMemberSelectionType.Include
                        }]),
                    {
                        disabled: false,
                        label: localization.fields.scopes.exclude({ name: localizedTenant.inclusion() }),
                        value: Contract.OrganizationMemberSelectionType.Exclude
                    }
                ]}
                selectedValue={selectionType ?? ""}
                sx={{ margin: 0 }}
                onChange={selectionType => onSelectionTypeChanged(selectionType || undefined)}/>
            {!_.isNil(selectionType) &&
                <Tree<Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode>
                    containerSx={{
                        border: theme.border.primary,
                        borderRadius: theme.spacing(1),
                        maxWidth: theme.px(412),
                        minHeight: 0,
                        padding: theme.spacing(1),
                        [`.${SearchTextFieldElementClass.Input}`]: {
                            borderRadius: theme.spacing(0.75)
                        }
                    }}
                    getSearchableTexts={({ value: { name, rawId } }) => StringHelper.getCombineSortValue(name, rawId)}
                    isExpanded={({ value: { isRoot } }) => isRoot}
                    orderBy={[
                        ({ value: { isTenant } }) => isTenant,
                        ({ value: { name } }) => StringHelper.getSortValue(name)
                    ]}
                    rootItem={scopeNodeRootTreeItem}
                    topElement={
                        <Fragment>
                            <Typography
                                sx={{ fontVariantNumeric: "tabular-nums" }}
                                variant="body1">
                                {localization.searchResult({
                                    name: localizedTenant.all(),
                                    scope: localization.scope[selectionType](),
                                    selected: selectedTenantIds.length,
                                    total: itemsCount
                                })}
                            </Typography>
                            <Divider flexItem={true}/>
                        </Fragment>}
                    treeSx={{
                        maxHeight: "initial",
                        maxWidth: theme.spacing(80),
                        minWidth: theme.spacing(40)
                    }}
                    onTreeItemClick={
                        scopeNodeTreeItem => {
                            if (scopeNodeTreeItem.value.isTenant) {
                                toggle(scopeNodeTreeItem);
                            }
                        }}>
                    {scopeNodeTreeItem => {
                        const { isRoot, isTenant, rawId } = scopeNodeTreeItem.value;
                        const rawScopeNodeModels = scopeNodeTreeItem.leaves();
                        const checkedItems =
                            _.filter(
                                rawScopeNodeModels,
                                ({ value }) => _.includes(selectedTenantIds, value.rawId));

                        const checked = checkedItems.length === rawScopeNodeModels.length;
                        const indeterminate =
                            !checked &&
                            checkedItems.length > 0;
                        const name = scopeNodeMap[rawId]?.scopeNodeModel.configuration.name ?? scopeNodeTreeItem.value.name;

                        return (
                            <CheckboxField
                                checked={checked}
                                indeterminate={indeterminate}
                                key={rawId}
                                onChange={() => toggle(scopeNodeTreeItem)}
                                onClick={event => event.stopPropagation()}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                    sx={{ color: theme.palette.text.primary }}>
                                    <Box sx={{ fontSize: "16px" }}>
                                        {isRoot
                                            ? <OrganizationIcon/>
                                            : isTenant
                                                ? <TenantIcon
                                                    data={{ codeTenantType }}
                                                    tenantType={tenantType}/>
                                                : <FolderIcon/>}
                                    </Box>
                                    <Typography noWrap={true}>
                                        {isRoot
                                            ? ScopeHelper.customerDisplayName
                                            : name}
                                    </Typography>
                                </Stack>
                            </CheckboxField>);
                    }}
                </Tree>}
        </Fragment>);
}