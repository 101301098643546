import { map } from "@infrastructure";
import _ from "lodash";
import { Contract, LicensingHelper, PermissionManagementController, UserHelper } from "..";

export class PermissionManagementHelper {
    public static readonly approvedPermissionRequestColor = "#64C9BB";
    public static readonly awsInlinePolicyDocumentMaxLength = 7680;
    public static readonly closedPermissionRequestColor = "#B4C6CA";
    public static readonly noConsoleUrlPermissionRequestTypeNames = [
        Contract.TypeNames.AadDirectoryGroupMembershipRequest,
        Contract.TypeNames.GciDirectoryGroupMembershipRequest
    ];
    public static readonly pendingPermissionRequestColor = "#6680F7";

    public static permissionAuditEventExists: boolean;
    public static slackEnabled: boolean;
    public static teamsEnabled: boolean;

    public static async initialize() {
        if (LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement) &&
            UserHelper.hasAnyCloudProviderTenantPermissions(
                Contract.IdentityPermission.PermissionManagementAdministrationRead,
                Contract.IdentityPermission.SecurityAdministrationRead)) {
            const { permissionAuditEventExists, slackEnabled, teamsEnabled } = await PermissionManagementController.getInfo();
            PermissionManagementHelper.permissionAuditEventExists = permissionAuditEventExists;
            PermissionManagementHelper.slackEnabled = slackEnabled;
            PermissionManagementHelper.teamsEnabled = teamsEnabled;
        } else {
            PermissionManagementHelper.permissionAuditEventExists = false;
            PermissionManagementHelper.slackEnabled = false;
            PermissionManagementHelper.teamsEnabled = true;
        }
    }

    public static getAwsIamPolicyDocumentCharacterCount(policyDocument?: Contract.AwsIamPolicyDocument) {
        return PermissionManagementHelper.getAwsIamRawPolicyDocumentCharacterCount(policyDocument?.raw);
    }

    public static getAwsIamRawPolicyDocumentCharacterCount(policyDocumentJson?: string) {
        return _.size(policyDocumentJson?.replace(/\s/g, ""));
    }

    public static getPermissionRequestCloseReasonColor =
        (closeReason: Contract.PermissionRequestCloseReason) =>
            map(
                closeReason,
                {
                    [Contract.PermissionRequestCloseReason.ActivationFailed]: () => "#F9537B",
                    [Contract.PermissionRequestCloseReason.Cancelled]: () => "#D0D6E1",
                    [Contract.PermissionRequestCloseReason.Expired]: () => "#FF9800",
                    [Contract.PermissionRequestCloseReason.Rejected]: () => "#F9537B",
                    [Contract.PermissionRequestCloseReason.RequestExpired]: () => "#D0D6E1",
                    [Contract.PermissionRequestCloseReason.Revoked]: () => "#F9537B"
                });
}