import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useAadPartitionTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useAadPartitionTypeTranslator",
            () => ({
                [Contract.AadPartitionType.China]: "China",
                [Contract.AadPartitionType.Public]: "Public",
                [Contract.AadPartitionType.UsGov]: "US Government"
            }));
    return (partitionType: Contract.AadPartitionType) =>
        localization[partitionType]();
}