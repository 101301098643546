import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpApiKeysKeyRiskContext, useGetGcpTenantEntityRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpApiKeysKeyNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpApiKeysKeyNotRotatedRisk;
    const keyModel = entityModelStore.useGet(risk.entityId) as Contract.GcpApiKeysKeyModel;
    const tenantEntityModel = entityModelStore.useGet(risk.tenantId) as Contract.GcpTenantEntityModel;

    const getGcpApiKeysKeyRiskContext = useGetGcpApiKeysKeyRiskContext();
    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpApiKeysKeyNotRotatedRiskDefinition",
            () => ({
                description: "{{project}} has an {{key}} that was not rotated for more than {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                sections: {
                    resolution: {
                        step1: "Regenerating API keys may break existing client connectivity as the client will try to connect with older API keys stored on devices. Therefore, it is better to create a new API key and delete the old one after you migrate your application to use the new key.",
                        step2: "On the top menu, click **CREATE CREDENTIALS** and select **API key**",
                        step3: "Select the newly created API key and apply the same application and API restriction the old API key have (if any)",
                        step4: "Before deleting the old key, migrate your applications (if any) to use the newly created API key",
                        step5: "In the **API keys** page, select the 3-dot menu next to the old API key and click **Delete API key**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            key:
                <Entity
                    entityIdOrModel={keyModel}
                    entityTypeNameTranslatorOptions={{ variant: "text" }}
                    variant="typeText"/>,
            keyRotationTimeFrame: risk.keyRotationTimeFrame,
            project:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.tenantId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.ApiKeys}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const keyRiskContext = getGcpApiKeysKeyRiskContext(keyModel);
            const tenantEntityRiskContext = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                keyRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.keyRotationTimeFrame),
                keyRiskContext.sensitive,
                tenantEntityRiskContext.sensitive,
                keyRiskContext.creationTime,
                keyRiskContext.emptyRestrictions,
                keyRiskContext.clientRawIdentifiers,
                keyRiskContext.serviceHostNames,
                keyRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}