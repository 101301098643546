import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCiDefaultEntityInfoItemElements } from "./useCiDefaultEntityInfoItemElements";

export function useCiDefaultEntityDefinition(entityModel: Contract.CiEntityModel, options?: DefinitionOptions) {
    const defaultTenantInfoItemElements = useCiDefaultEntityInfoItemElements(entityModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultTenantInfoItemElements}
                entityPropertyInfoItemElements={[]}
                options={options?.infoOptions}/>,
        sensitive: false
    });
}