import _ from "lodash";
import { AwsConsoleHelper } from ".";
import { Contract, ScopeHelper } from "../../../common";

export class AwsCloudFormationTrail {
    constructor(
        public bucketName: string,
        public encryptionKeyArns: string[]) {
    }
}

export class AwsCloudFormationHelper {
    private static _containerImageRepositoryAnalysisPolicy = "RoleContainerImageRepositoryScanningPolicy";
    private static _dataAnalysisPolicy = "RoleDataAnalysisScanningPolicy";
    private static _managementPolicy = "RoleRemediationPolicy";
    private static _monitoringPolicy = "RoleMonitoringPolicy";
    private static _permissionManagementPolicy = "RoleJitPolicy";
    private static _roleExternalIdParameterName = "RoleExternalId";
    private static _roleTrustedPrincipalIdParameterName = "RoleTrustedPrincipalId";
    private static _virtualMachineAnalysisPolicy = "RoleVirtualMachineScanningPolicy";

    private static _roleArnParameterName = "TenableRoleArn";
    public static get roleArnParameterName() {
        return AwsCloudFormationHelper._roleArnParameterName;
    }

    private static _roleNameParameterName = "RoleName";
    public static get roleNameParameterName() {
        return AwsCloudFormationHelper._roleNameParameterName;
    }

    private static _stackName = "TenableStack";
    public static get stackName() {
        return AwsCloudFormationHelper._stackName;
    }

    private static _trailBucketNameParameterName = "CloudTrailBucketName";
    public static get trailBucketNameParameterName() {
        return AwsCloudFormationHelper._trailBucketNameParameterName;
    }

    private static _trailEncryptionKeyArnParameterName = "CloudTrailKeyArn";
    public static get trailEncryptionKeyArnParameterName() {
        return AwsCloudFormationHelper._trailEncryptionKeyArnParameterName;
    }

    public static getOrganizationSteps(
        onboardingInfo: Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoResponse,
        partitionType: Contract.AwsPartitionType,
        permissionTypes: Contract.CloudProviderTenantPermissionType[],
        roleName: string,
        rootRawId: string) {

        const containerImageRepositoryPermissions =
            _.includes(
                permissionTypes,
                Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis);
        const dataAnalysisPermissions =
            _.includes(
                permissionTypes,
                Contract.CloudProviderTenantPermissionType.DataAnalysis);
        const domainName = AwsConsoleHelper.getDomainName(partitionType);
        const principal =
            partitionType === Contract.AwsPartitionType.China
                ? onboardingInfo.chinaAuthenticationUserArn!
                : onboardingInfo.applicationRoleTenantRawId!;
        const writePermissions =
            _.includes(
                permissionTypes,
                Contract.CloudProviderTenantPermissionType.Write);
        const virtualMachineAnalysisPermissions =
            _.includes(
                permissionTypes,
                Contract.CloudProviderTenantPermissionType.WorkloadAnalysis);

        const createStackSetCliCommand =
            `aws cloudformation create-stack-set --stack-set-name ${AwsCloudFormationHelper.stackName} ` +
            `--template-url ${onboardingInfo.cloudFormationTemplateUrl} ` +
            `--auto-deployment Enabled=true,RetainStacksOnAccountRemoval=false ` +
            `--capabilities CAPABILITY_NAMED_IAM ` +
            `--permission-model SERVICE_MANAGED ` +
            `--parameters ` +
            `ParameterKey=${AwsCloudFormationHelper._roleNameParameterName},ParameterValue=${roleName} ` +
            `ParameterKey=${AwsCloudFormationHelper._roleExternalIdParameterName},ParameterValue=${ScopeHelper.customerId} ` +
            `ParameterKey=${AwsCloudFormationHelper._roleTrustedPrincipalIdParameterName},ParameterValue=${principal} ` +
            `ParameterKey=${AwsCloudFormationHelper._managementPolicy},ParameterValue=${writePermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._containerImageRepositoryAnalysisPolicy},ParameterValue=${containerImageRepositoryPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._virtualMachineAnalysisPolicy},ParameterValue=${virtualMachineAnalysisPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._dataAnalysisPolicy},ParameterValue=${dataAnalysisPermissions};` +
            `aws cloudformation create-stack-instances --stack-set-name ${AwsCloudFormationHelper.stackName} ` +
            `--deployment-targets OrganizationalUnitIds=${rootRawId} ` +
            `--regions $(printenv AWS_REGION)`;

        const policyParameterNameToValueMap = {
            [AwsCloudFormationHelper._containerImageRepositoryAnalysisPolicy]: containerImageRepositoryPermissions,
            [AwsCloudFormationHelper._dataAnalysisPolicy]: dataAnalysisPermissions,
            [AwsCloudFormationHelper._managementPolicy]: writePermissions,
            [AwsCloudFormationHelper._monitoringPolicy]: true,
            [AwsCloudFormationHelper._virtualMachineAnalysisPolicy]: virtualMachineAnalysisPermissions
        };

        const roleParameterNameToValueMap = {
            [AwsCloudFormationHelper._roleNameParameterName]: roleName,
            [AwsCloudFormationHelper._roleExternalIdParameterName]: ScopeHelper.customerId,
            [AwsCloudFormationHelper._roleTrustedPrincipalIdParameterName]: principal
        };

        const stackSetsPageUrl = `https://${domainName}/cloudformation/home/stacksets#/stacksets`;

        const updateStackSetCliCommand =
            `aws cloudformation update-stack --stack-name ${AwsCloudFormationHelper.stackName} ` +
            `--template-url ${onboardingInfo.cloudFormationTemplateUrl} ` +
            `--auto-deployment Enabled=true,RetainStacksOnAccountRemoval=false ` +
            `--capabilities CAPABILITY_NAMED_IAM ` +
            `--permission-model SERVICE_MANAGED ` +
            `--parameters ` +
            `ParameterKey=${AwsCloudFormationHelper._roleNameParameterName},ParameterValue=${roleName} ` +
            `ParameterKey=${AwsCloudFormationHelper._roleExternalIdParameterName},UsePreviousValue=true ` +
            `ParameterKey=${AwsCloudFormationHelper._roleTrustedPrincipalIdParameterName},UsePreviousValue=true ` +
            `ParameterKey=${AwsCloudFormationHelper._managementPolicy},ParameterValue=${writePermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._containerImageRepositoryAnalysisPolicy},ParameterValue=${containerImageRepositoryPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._virtualMachineAnalysisPolicy},ParameterValue=${virtualMachineAnalysisPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._dataAnalysisPolicy},ParameterValue=${dataAnalysisPermissions};`;

        return { createStackSetCliCommand, policyParameterNameToValueMap, roleParameterNameToValueMap, stackSetsPageUrl, updateStackSetCliCommand };
    }

    public static getTenantSteps(
        tenantPartitionType: Contract.AwsPartitionType,
        tenantOnboardingInfo: Contract.TenantControllerGetAwsTenantOnboardingInfoResponse,
        tenantOnboardingPoliciesInfo: Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse,
        tenantPermissionTypes: Contract.CloudProviderTenantPermissionType[],
        trail?: AwsCloudFormationTrail) {

        const roleName = "TenableRole";
        const cloudTrailEncryptionKeyArnsValue =
            _.isEmpty(trail?.encryptionKeyArns)
                ? undefined
                : trail?.encryptionKeyArns.join(",");
        const containerImageRepositoryPermissions =
            _.includes(
                tenantPermissionTypes,
                Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis);
        const dataAnalysisPermissions =
            _.includes(
                tenantPermissionTypes,
                Contract.CloudProviderTenantPermissionType.DataAnalysis);
        const domainName = AwsConsoleHelper.getDomainName(tenantPartitionType);
        const permissionManagementPermissions =
            _.includes(
                tenantPermissionTypes,
                Contract.CloudProviderTenantPermissionType.PermissionManagement);
        const principal =
            tenantPartitionType === Contract.AwsPartitionType.China
                ? tenantOnboardingInfo.chinaAuthenticationUserArn!
                : tenantOnboardingPoliciesInfo.applicationRoleTenantRawId!;
        const readPermissions =
            _.includes(
                tenantPermissionTypes,
                Contract.CloudProviderTenantPermissionType.Read);
        const writePermissions =
            _.includes(
                tenantPermissionTypes,
                Contract.CloudProviderTenantPermissionType.Write);
        const virtualMachineAnalysisPermissions =
            _.includes(
                tenantPermissionTypes,
                Contract.CloudProviderTenantPermissionType.WorkloadAnalysis);

        const createStackCliCommand =
            `aws cloudformation create-stack --stack-name ${AwsCloudFormationHelper.stackName} ` +
            `--template-url ${tenantOnboardingInfo.cloudFormationTemplateUrl} ` +
            `--capabilities CAPABILITY_NAMED_IAM ` +
            `--parameters ` +
            `ParameterKey=${AwsCloudFormationHelper._roleNameParameterName},ParameterValue=${roleName} ` +
            `ParameterKey=${AwsCloudFormationHelper._roleExternalIdParameterName},ParameterValue=${ScopeHelper.customerId} ` +
            `ParameterKey=${AwsCloudFormationHelper._roleTrustedPrincipalIdParameterName},ParameterValue=${principal} ` +
            `ParameterKey=${AwsCloudFormationHelper._monitoringPolicy},ParameterValue=${readPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._managementPolicy},ParameterValue=${writePermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._permissionManagementPolicy},ParameterValue=${permissionManagementPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._containerImageRepositoryAnalysisPolicy},ParameterValue=${containerImageRepositoryPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._virtualMachineAnalysisPolicy},ParameterValue=${virtualMachineAnalysisPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._dataAnalysisPolicy},ParameterValue=${dataAnalysisPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._trailBucketNameParameterName},ParameterValue=${trail?.bucketName ?? "-"} ` +
            `ParameterKey=${AwsCloudFormationHelper._trailEncryptionKeyArnParameterName},ParameterValue=${cloudTrailEncryptionKeyArnsValue ?? "-"};` +
            `aws cloudformation wait stack-create-complete --stack-name ${AwsCloudFormationHelper.stackName};` +
            `aws cloudformation describe-stacks --stack-name ${AwsCloudFormationHelper.stackName} --query "Stacks[].Outputs"`;

        const policyParameterNameToValueMap = {
            [AwsCloudFormationHelper._containerImageRepositoryAnalysisPolicy]: containerImageRepositoryPermissions,
            [AwsCloudFormationHelper._dataAnalysisPolicy]: dataAnalysisPermissions,
            [AwsCloudFormationHelper._managementPolicy]: writePermissions,
            [AwsCloudFormationHelper._monitoringPolicy]: readPermissions,
            [AwsCloudFormationHelper._permissionManagementPolicy]: permissionManagementPermissions,
            [AwsCloudFormationHelper._virtualMachineAnalysisPolicy]: virtualMachineAnalysisPermissions
        };

        const quickCreateUrl =
            `https://${domainName}/cloudformation/home?#/stacks/quickcreate?` +
            `templateURL=${tenantOnboardingInfo.cloudFormationTemplateUrl}&` +
            `stackName=${AwsCloudFormationHelper.stackName}&` +
            `param_${AwsCloudFormationHelper._roleNameParameterName}=${roleName}&` +
            `param_${AwsCloudFormationHelper._roleExternalIdParameterName}=${ScopeHelper.customerId}&` +
            `param_${AwsCloudFormationHelper._roleTrustedPrincipalIdParameterName}=${principal}&` +
            `param_${AwsCloudFormationHelper._monitoringPolicy}=${readPermissions}&` +
            `param_${AwsCloudFormationHelper._managementPolicy}=${writePermissions}&` +
            `param_${AwsCloudFormationHelper._permissionManagementPolicy}=${permissionManagementPermissions}&` +
            `param_${AwsCloudFormationHelper._containerImageRepositoryAnalysisPolicy}=${containerImageRepositoryPermissions}&` +
            `param_${AwsCloudFormationHelper._virtualMachineAnalysisPolicy}=${virtualMachineAnalysisPermissions}&` +
            `param_${AwsCloudFormationHelper._dataAnalysisPolicy}=${dataAnalysisPermissions}${
                _.isNil(trail)
                    ? ""
                    : `&param_${AwsCloudFormationHelper._trailBucketNameParameterName}=${trail.bucketName}`
            }${_.isNil(cloudTrailEncryptionKeyArnsValue)
                ? ""
                : `&param_${AwsCloudFormationHelper._trailEncryptionKeyArnParameterName}=${cloudTrailEncryptionKeyArnsValue}`}`;

        const stacksPageUrl = `https://${domainName}/cloudformation/home?#/stacks`;

        const updateStackCliCommand =
            `aws cloudformation update-stack --stack-name ${AwsCloudFormationHelper.stackName} ` +
            `--template-url ${tenantOnboardingInfo.cloudFormationTemplateUrl} ` +
            `--capabilities CAPABILITY_NAMED_IAM ` +
            `--parameters ` +
            `ParameterKey=${AwsCloudFormationHelper._roleNameParameterName},UsePreviousValue=true ` +
            `ParameterKey=${AwsCloudFormationHelper._roleExternalIdParameterName},UsePreviousValue=true ` +
            `ParameterKey=${AwsCloudFormationHelper._roleTrustedPrincipalIdParameterName},UsePreviousValue=true ` +
            `ParameterKey=${AwsCloudFormationHelper._monitoringPolicy},ParameterValue=${readPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._managementPolicy},ParameterValue=${writePermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._permissionManagementPolicy},ParameterValue=${permissionManagementPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._virtualMachineAnalysisPolicy},ParameterValue=${virtualMachineAnalysisPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._containerImageRepositoryAnalysisPolicy},ParameterValue=${containerImageRepositoryPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._dataAnalysisPolicy},ParameterValue=${dataAnalysisPermissions} ` +
            `ParameterKey=${AwsCloudFormationHelper._trailBucketNameParameterName},ParameterValue=${trail?.bucketName ?? "-"} ` +
            `ParameterKey=${AwsCloudFormationHelper._trailEncryptionKeyArnParameterName},ParameterValue=${cloudTrailEncryptionKeyArnsValue ?? "-"};` +
            `aws cloudformation wait stack-update-complete --stack-name ${AwsCloudFormationHelper.stackName};`;

        return { createStackCliCommand, policyParameterNameToValueMap, quickCreateUrl, stacksPageUrl, updateStackCliCommand };
    }
}