import { ActionMenuItem, AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSort, DataTableSortDirection, DataTableSortType, Dialog, EmptyMessageText, makeContextProvider, map, Menu, Optional, optionalTableCell, StringHelper, TextValuesFilter, TimeFormatter, TimeHelper, useChangeEffect, useExecuteOperation, useLocalization, useUncaptureValue } from "@infrastructure";
import { Group as GroupIcon, Person as UserIcon } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import _, { Dictionary, Function0 } from "lodash";
import React, { Fragment, ReactNode, useMemo, useRef, useState } from "react";
import { ConfigurationController, Contract, IdentityRoleFilter, scopeNodeModelStore, StorageHelper, useIdentityRoleTranslator, useScopeNameTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ActionsCell, AddOrEditPrincipal } from "./components";

class PrincipalsContext {
    constructor(
        public aadGroups: boolean,
        public authorizationDomainNames: string[],
        public dialogContentElement: ReactNode | undefined,
        public executeGetPrincipals: Function0<Promise<void>>,
        public groups: Contract.Group[],
        public principalRoleAssignments: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment[],
        public users: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponseUser[]) {
    }
}

export const [usePrincipalsContext, useSetPrincipalsContext, usePrincipalsContextProvider] = makeContextProvider<PrincipalsContext>();

export function Principals() {
    const dataTableActionsRef = useRef<DataTableActions>();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [childScopeRoleAssignmentsEnabled, setChildScopeRoleAssignmentsEnabled] = useState(!StringHelper.isFalse(StorageHelper.customerConfigurationPrincipalRoleAssignmentsFlatView.getValue()));

    const uncaptureChildScopeRoleAssignmentsEnabled = useUncaptureValue(childScopeRoleAssignmentsEnabled);
    const [{ aadGroups, authorizationDomainNames, groups, principalRoleAssignments, users }, executeGetPrincipals] =
        useExecuteOperation(
            Principals,
            () =>
                uncaptureChildScopeRoleAssignmentsEnabled(
                    uncapturedChildScopeRoleAssignmentsEnabled =>
                        ConfigurationController.getPrincipalRoleAssignments(
                            new Contract.ConfigurationControllerGetPrincipalRoleAssignmentsRequest(
                                uncapturedChildScopeRoleAssignmentsEnabled,
                                scopeNodeModel.configuration.id))));

    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const scopeParentScopeIds = new Set(scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds);

    const [context, setContext, ContextProvider] =
        usePrincipalsContextProvider(
            () =>
                new PrincipalsContext(
                    aadGroups,
                    authorizationDomainNames,
                    undefined,
                    executeGetPrincipals,
                    groups,
                    principalRoleAssignments,
                    users));
    useChangeEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    aadGroups,
                    authorizationDomainNames,
                    groups,
                    principalRoleAssignments,
                    users
                }));

            dataTableActionsRef.current!.reset({ refreshFilters: true });
        },
        [principalRoleAssignments]);

    useChangeEffect(
        () => executeGetPrincipals(),
        [childScopeRoleAssignmentsEnabled]);

    function fetchItems(filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _(principalRoleAssignments).
            filter(
                item => {
                    if (!_.isNil(filterMap[PrincipalsColumnId.DisplayName]) &&
                        !_.includes(filterMap[PrincipalsColumnId.DisplayName].values, item.principalDisplayName)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[PrincipalsColumnId.Role]) &&
                        !_.includes(filterMap[PrincipalsColumnId.Role].values, item.role)) {
                        return false;
                    }

                    return true;
                }).
            orderBy(
                [
                    item =>
                        map<string, number | string | undefined>(
                            sort?.columnId ?? PrincipalsColumnId.Scope,
                            {
                                [PrincipalsColumnId.DisplayName]: () => item.principalDisplayName,
                                [PrincipalsColumnId.Scope]: () => scopeNameTranslator(item.scopeId, { path: true }),
                                [PrincipalsColumnId.Role]: () => StringHelper.getSortValue(identityRoleTranslator(item.role)),
                                [PrincipalsColumnId.SignInTime]:
                                    () =>
                                        TimeHelper.getSortable(
                                            _.find(
                                                users,
                                                user => user.mail === item.principalIdentifier)?.
                                                signInTime)
                            }),
                    item => item.principalGroup,
                    item => StringHelper.getSortValue(item.principalDisplayName),
                    item => StringHelper.getSortValue(identityRoleTranslator(item.role))
                ],
                [
                    sort?.direction === DataTableSortDirection.Descending
                        ? "desc"
                        : "asc",
                    "desc",
                    "asc",
                    "asc"
                ]).
            value();
    }

    const principalDisplayNames =
        useMemo(
            () =>
                _(principalRoleAssignments).
                    map(principalRoleAssignment => principalRoleAssignment.principalDisplayName).
                    uniq().
                    value(),
            [principalRoleAssignments]);

    const identityRoleTranslator = useIdentityRoleTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.principals",
            () => ({
                actions: {
                    add: "Add Role Assignment",
                    addGroup: "Add group role assignment",
                    addUser: "Add user role assignment",
                    childScopeRolesEnabled: "Flat View"
                },
                columns: {
                    displayName: "Principal",
                    role: "Role",
                    scope: "Scope",
                    signInTime: "Sign-in Time"
                },
                empty: {
                    withFilter: "No matching principals",
                    withoutFilter: "No principals"
                }
            }));
    const theme = useTheme();
    return (
        <ContextProvider>
            {!_.isNil(context.dialogContentElement) &&
                <Dialog
                    disableEnforceFocus={true}
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context =>
                                    ({
                                        ...context,
                                        dialogContentElement: undefined
                                    }))}>
                    {context.dialogContentElement}
                </Dialog>}
            <DataTable
                actionsRef={dataTableActionsRef}
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
                }}
                fetchItems={fetchItems}
                filtersOptions={{
                    persist: {
                        visibilityStorageItem: StorageHelper.customerConfigurationPrincipalsFilters
                    }
                }}
                getItemId={(item: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment) => `${item.scopeId}/${item.principalIdentifier}/${item.role}`}>
                <DataTableAction>
                    <CheckButton
                        checked={childScopeRoleAssignmentsEnabled}
                        title={localization.actions.childScopeRolesEnabled()}
                        onCheckedChanged={
                            checked => {
                                setChildScopeRoleAssignmentsEnabled(checked);
                                StorageHelper.customerConfigurationPrincipalRoleAssignmentsFlatView.setValue(checked);
                            }}/>
                </DataTableAction>
                <DataTableAction>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setContext(
                                        context =>
                                            ({
                                                ...context,
                                                dialogContentElement: <AddOrEditPrincipal type="user"/>
                                            })),
                                localization.actions.addUser(),
                                { icon: <UserIcon/> }),
                            new ActionMenuItem(
                                () =>
                                    setContext(
                                        context =>
                                            ({
                                                ...context,
                                                dialogContentElement: <AddOrEditPrincipal type="group"/>
                                            })),
                                localization.actions.addGroup(),
                                { icon: <GroupIcon/> })]}
                        variant="bottomRight">
                        <Button
                            size="small"
                            startIcon={<AddIcon/>}
                            onClick={
                                () =>
                                    setContext(
                                        context => ({
                                            ...context,
                                            addOrEditOpen: true
                                        }))}>
                            {localization.actions.add()}
                        </Button>
                    </Menu>
                </DataTableAction>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.displayName()}
                                    values={principalDisplayNames}/>
                        }
                    }}
                    id={PrincipalsColumnId.DisplayName}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment>) =>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                {item.principalGroup
                                    ? <GroupIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "18px"
                                        }}/>
                                    : <UserIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "18px"
                                        }}/>}
                                <Typography>{item.principalDisplayName}</Typography>
                            </Stack>}
                    title={localization.columns.displayName()}/>
                <DataTableColumn
                    id={PrincipalsColumnId.Scope}
                    itemProperty={(item: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment) => scopeNameTranslator(item.scopeId, { path: true })}
                    title={localization.columns.scope()}/>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element: <IdentityRoleFilter placeholder={localization.columns.role()}/>
                        }
                    }}
                    id={PrincipalsColumnId.Role}
                    itemProperty={(item: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment) => identityRoleTranslator(item.role)}
                    title={localization.columns.role()}/>
                <DataTableColumn
                    id={PrincipalsColumnId.SignInTime}
                    render={
                        optionalTableCell<Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment>(
                            item => {
                                if (item.principalGroup) {
                                    return undefined;
                                }

                                const user =
                                    _.find(
                                        users,
                                        user => user.mail === item.principalIdentifier);
                                return _.isNil(user?.signInTime)
                                    ? undefined
                                    : TimeFormatter.shortDateTime(user!.signInTime);
                            })}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.signInTime()}/>
                <DataTableColumn
                    id={PrincipalsColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment>) =>
                            scopeParentScopeIds.has(item.scopeId)
                                ? <Fragment/>
                                : <ActionsCell roleAssignment={item}/>}/>
            </DataTable>
        </ContextProvider>);
}

enum PrincipalsColumnId {
    Actions = "actions",
    DisplayName = "displayName",
    Role = "role",
    Scope = "scope",
    SignInTime = "signInTime"
}