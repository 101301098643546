import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TenantFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData, ExportData } from "../../../../Table";

export function useAwsSsoUserDefinition(definitionData: DefinitionData) {
    const principalModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsSsoPrincipalModelFilters>;
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsSsoUserDefinition",
            () => ({
                columns: {
                    active: {
                        false: "Inactive",
                        title: "Status",
                        true: "Active"
                    },
                    groupIds: "Group Memberships",
                    roleIds: "Assigned Roles",
                    roleTenantIds: "Assigned Role Account"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsSsoUserModel) => ({
                            [localization.columns.active.title()]:
                                (item.entity as Contract.AwsSsoUser).active
                                    ? localization.columns.active.true()
                                    : localization.columns.active.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.active.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.active.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.active.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoUserActive}
                itemProperty={
                    (item: Contract.AwsSsoUserModel) =>
                        (item.entity as Contract.AwsSsoUser).active
                            ? localization.columns.active.true()
                            : localization.columns.active.false()}
                key={Contract.EntityModelProperty.AwsSsoUserActive}
                title={localization.columns.active.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.AwsSsoUserModel) => item.roleIds,
                    getItem:
                        (item: Contract.AwsSsoUserModel, exportData: ExportData) => ({
                            [localization.columns.roleIds()]:
                                _(item.roleIds).
                                    map(roleId => exportData.relatedEntityModelMap[roleId].entity.displayReference).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsSsoPrincipalRoles)}
                                placeholder={localization.columns.roleIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoPrincipalRoles}
                key={Contract.EntityModelProperty.AwsSsoPrincipalRoles}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsSsoUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleIds}
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roleIds()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={principalModelFiltersPromise}
                                title={localization.columns.roleTenantIds()}>
                                {filters =>
                                    <TenantFilter
                                        emptyValueEnabled={filters.roleTenantIdItems.emptyValue}
                                        placeholder={localization.columns.roleTenantIds()}
                                        tenantIds={filters.roleTenantIdItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoPrincipalRoleTenants}
                key={Contract.EntityModelProperty.AwsSsoPrincipalRoleTenants}
                title={localization.columns.roleTenantIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsSsoUserModel>(
                        Contract.TypeNames.AwsSsoGroup,
                        item => item.groupIds,
                        localization.columns.groupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsSsoUserGroups)}
                                placeholder={localization.columns.groupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoUserGroups}
                key={Contract.EntityModelProperty.AwsSsoUserGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsSsoUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupIds}
                            entityTypeName={Contract.TypeNames.AwsSsoGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groupIds()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsSsoUserRequest(
                new Contract.EntityControllerGetEntityModelPageAwsSsoUserRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    undefined,
                    undefined,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoPrincipalRoles]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoPrincipalRoleTenants]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoUserActive]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoUserGroups])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(undefined)));
}