import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../../../common";

export function useServiceNowInstanceIssueTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.serviceNow.hooks.useServiceNowInstanceIssueTranslator",
            () => ({
                noIssue: "Connected",
                [Contract.TypeNames.ServiceNowInstanceStateIssue]: {
                    [Contract.ServiceNowInstanceStateIssue.InvalidUrl]: "Invalid URL",
                    [Contract.ServiceNowInstanceStateIssue.MissingPermissions]: "Missing permissions",
                    [Contract.ServiceNowInstanceStateIssue.UserNotAuthenticated]: "Invalid user name or password"
                }
            }));
    return (instanceIssue?: Contract.ServiceNowInstanceStateIssue) =>
        _.isNil(instanceIssue)
            ? localization.noIssue()
            : localization[Contract.TypeNames.ServiceNowInstanceStateIssue][instanceIssue]();
}