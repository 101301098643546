﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useGcpComputeLoadBalancerLoadBalancingSchemeTranslator } from "../../../../../../hooks";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useGcpCommonComputeLoadBalancerResourceInfoItemElements } from "./useGcpCommonComputeLoadBalancerResourceInfoItemElements";

export function useGcpComputeBackendServiceDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const backendServiceModel = scopeResourceModel as Contract.GcpComputeBackendServiceModel;
    const backendService = backendServiceModel.entity as Contract.GcpComputeBackendService;
    const commonComputeLoadBalancerResourceInfoItemElements = useGcpCommonComputeLoadBalancerResourceInfoItemElements(backendServiceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);

    const computeLoadBalancerLoadBalancingSchemeTranslator = useGcpComputeLoadBalancerLoadBalancingSchemeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeBackendServiceDefinition",
            () => ({
                info: {
                    cards: {
                        backendResourceIds: "Backends"
                    },
                    items: {
                        forwardingRuleIds: "Forwarding Rules",
                        identityAwareProxyEnabled: {
                            false: "Disabled",
                            title: "Identity Aware Proxy",
                            true: "Enabled"
                        },
                        loadBalancingScheme: "Load Balancer Type",
                        loggingEnabled: {
                            false: "Off",
                            title: "Logging",
                            true: "On"
                        },
                        portName: "Port Name",
                        protocol: {
                            title: "Protocol",
                            [Contract.TypeNames.GcpComputeBackendServiceProtocol]: {
                                [Contract.GcpComputeBackendServiceProtocol.Grpc]: "gRPC",
                                [Contract.GcpComputeBackendServiceProtocol.Http]: "HTTP",
                                [Contract.GcpComputeBackendServiceProtocol.Http2]: "HTTP2",
                                [Contract.GcpComputeBackendServiceProtocol.Https]: "HTTPS",
                                [Contract.GcpComputeBackendServiceProtocol.Ssl]: "SSL",
                                [Contract.GcpComputeBackendServiceProtocol.Tcp]: "TCP",
                                [Contract.GcpComputeBackendServiceProtocol.Udp]: "UDP",
                                [Contract.GcpComputeBackendServiceProtocol.Unspecified]: "Unspecified"
                            }
                        },
                        targetProxyIds: "Proxies",
                        urlMapIds: "URL Maps",
                        vpcIdReference: "VPC Network"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="protocol"
                        title={localization.info.items.protocol.title()}
                        value={localization.info.items.protocol[Contract.TypeNames.GcpComputeBackendServiceProtocol][backendService.protocol]()}/>,
                    <InfoItem
                        key="portName"
                        title={localization.info.items.portName()}
                        value={
                            _.isNil(backendService.portName)
                                ? undefined
                                : backendService.portName}/>,
                    <InfoItem
                        key="loadBalancingScheme"
                        title={localization.info.items.loadBalancingScheme()}
                        value={computeLoadBalancerLoadBalancingSchemeTranslator(backendService.loadBalancingScheme)}/>,
                    <InfoItem
                        key="identityAwareProxyEnabled"
                        title={localization.info.items.identityAwareProxyEnabled.title()}
                        value={
                            _.isNil(backendService.identityAwareProxyEnabled)
                                ? undefined
                                : backendService.identityAwareProxyEnabled
                                    ? localization.info.items.identityAwareProxyEnabled.true()
                                    : localization.info.items.identityAwareProxyEnabled.false()}/>,
                    commonComputeLoadBalancerResourceInfoItemElements.loadBalancerIdsInfoItemElement,
                    <InfoItem
                        key="identityAwareProxyEnabled"
                        title={localization.info.items.loggingEnabled.title()}
                        value={
                            backendService.loggingEnabled
                                ? localization.info.items.loggingEnabled.true()
                                : localization.info.items.loggingEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={backendServiceModel.forwardingRuleIds}
                        entityTypeName={Contract.TypeNames.GcpComputeForwardingRule}
                        key="forwardingRuleIds"
                        title={localization.info.items.forwardingRuleIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={backendServiceModel.targetProxyIds}
                        entityTypeName={Contract.TypeNames.GcpComputeTargetProxy}
                        key="targetProxyIds"
                        title={localization.info.items.targetProxyIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={backendServiceModel.urlMapIds}
                        entityTypeName={Contract.TypeNames.GcpComputeUrlMap}
                        key="urlMapIds"
                        title={localization.info.items.urlMapIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={backendServiceModel?.vpcIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="vpcIdReference"
                        title={localization.info.items.vpcIdReference()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={backendServiceModel.backendResourceIds}
                    entityTypeName={Contract.TypeNames.GcpScopeResource}
                    title={localization.info.cards.backendResourceIds()}/>
            </Info>
    });
}