import { InfoBox, NotValidIcon, Steps, SuccessIcon, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, riskPolicyModelStore, tenantModelStore } from "../../../../../../../../../../../common";
import { AzureAuthorizationRoleAssignmentResourceHelper } from "../../../../../../../../../../../tenants";
import { useAddOrEditContext } from "../AddOrEdit";

export function FinishItem() {
    const { useNextEffect } = useOrderedWizardContext();
    const { updatedTenantModel } = useAddOrEditContext();

    useNextEffect(
        async () => {
            await tenantModelStore.notify(updatedTenantModel!);
            await riskPolicyModelStore.notify();
            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.addOrEdit.finishItem",
            () => ({
                servicePrincipalMissingAssignedRoleDefinitionNames: {
                    info: "The following role assignments are missing:",
                    subtitle: "Some of the permissions you chose are still missing. You can add them after completing onboarding."
                },
                subtitle: "We started scanning your environment, this could take a while.",
                tenantServicePrincipalAssignedRoleDefinitionNames: "Identified the following permissions:",
                title: "Successfully added {{tenantName}}!"
            }));

    const theme = useTheme();
    const tenantServicePrincipalAssignedRoleDefinitionNames = AzureAuthorizationRoleAssignmentResourceHelper.getTenantAssignedRoleDefinitionNames(updatedTenantModel!);
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={6}
            sx={{ height: "100%" }}>
            <Stack
                alignItems="center"
                spacing={2}>
                <Typography
                    align="center"
                    sx={{ fontWeight: 400 }}
                    variant="h2">
                    {localization.title({
                        tenantName: updatedTenantModel!.configuration.name
                    })}
                </Typography>
                <Typography align="center">
                    {localization.subtitle()}
                </Typography>
                {!_.isEmpty((updatedTenantModel!.state as Contract.AzureTenantState).monitoring.servicePrincipalMissingAssignedRoleDefinitionNames) &&
                    <Typography
                        align="center"
                        sx={{ fontWeight: 600 }}>
                        {localization.servicePrincipalMissingAssignedRoleDefinitionNames.subtitle()}
                    </Typography>}
            </Stack>
            {!_.isEmpty(tenantServicePrincipalAssignedRoleDefinitionNames) &&
                <InfoBox
                    iconElement={
                        <SuccessIcon
                            sx={{
                                color: theme.palette.success.main,
                                fontSize: "18px"
                            }}/>}
                    title={localization.tenantServicePrincipalAssignedRoleDefinitionNames()}>
                    <Steps>
                        {tenantServicePrincipalAssignedRoleDefinitionNames}
                    </Steps>
                </InfoBox>}
            {!_.isEmpty((updatedTenantModel!.state as Contract.AzureTenantState).monitoring.servicePrincipalMissingAssignedRoleDefinitionNames) &&
                <InfoBox
                    iconElement={
                        <NotValidIcon
                            sx={{
                                color: theme.palette.warning.main,
                                fontSize: "18px"
                            }}/>}
                    title={localization.servicePrincipalMissingAssignedRoleDefinitionNames.info()}>
                    <Steps>
                        {(updatedTenantModel!.state as Contract.AzureTenantState).monitoring.servicePrincipalMissingAssignedRoleDefinitionNames}
                    </Steps>
                </InfoBox>}
        </Stack>);
}