import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NetappIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M1 3V21.8096H9.77794V10.5237H14.7939V21.8096H23.5716V3H1Z"
                fill="#0077C0"/>
        </SvgIcon>);
}