import { Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, RatioGaugeChart, useTheme } from "../../../../../../../../../../../../common";
import { Score } from "./components";

type ScoreSummaryProps = {
    complianceData: Contract.ComplianceSectionData;
};

export function ScoreSummary({ complianceData }: ScoreSummaryProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={3}
            sx={{ alignItems: "center" }}>
            <RatioGaugeChart
                color={theme.palette.securedColor(complianceData.stats!.securedPercentage)}
                count={complianceData.stats!.securedCount}
                labelFontSize={34}
                thickness={8}
                total={complianceData.stats!.count}
                width={164}/>
            <Stack
                spacing={1}
                sx={{
                    flex: 1,
                    maxWidth: theme.spacing(25),
                    paddingTop: theme.spacing(2)
                }}>
                {_.map(
                    orderedTimeFrames,
                    timeFrame =>
                        <Score
                            currentPercentagePassed={complianceData.stats!.securedPercentage}
                            key={timeFrame}
                            percentagePassed={_.round(complianceData.timeFrameToStatsMap![timeFrame]!.securedPercentage, 2)}
                            timeFrame={timeFrame as Contract.TimeFrame}/>)}
            </Stack>
        </Stack>);
}

const orderedTimeFrames = [
    Contract.TimeFrame.Short,
    Contract.TimeFrame.Medium,
    Contract.TimeFrame.Long
];