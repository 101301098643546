﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureAuthorizationRoleAssignmentResourceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureAuthorizationRoleAssignmentResourceStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureAuthorizationRoleAssignmentResourceStatus]: {
                    [Contract.AzureAuthorizationRoleAssignmentResourceStatus.Active]: "Active",
                    [Contract.AzureAuthorizationRoleAssignmentResourceStatus.Eligible]: "Eligible"
                }
            }));
    return (type: Contract.AzureAuthorizationRoleAssignmentResourceStatus) =>
        localization[Contract.TypeNames.AzureAuthorizationRoleAssignmentResourceStatus][type]();
}