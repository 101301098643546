import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AadConsoleUrlBuilder } from "../../../../../../../../../../../../tenants/aad/utilities/aadConsoleUrlBuilder.generated";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAadTenantEntityRiskContext } from "../contexts";

export function useAadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AadTenantEntityDefaultUserRoleApplicationCreationEnabledRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AadTenantEntityModel;

    const getAadTenantEntityRiskContext = useGetAadTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.compliance.useAadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskDefinition",
            () => ({
                description: "{{tenantEntity}} ‘Users can register application' is enabled",
                sections: {
                    resolution: {
                        step1: {
                            link: "User Settings",
                            text: "In Azure Portal, open the {{userSettingsLink}} page of {{tenantEntity}}"
                        },
                        step2: "Set **Users can register applications** to **No**",
                        step3: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                tenantEntity:
                    <Entity
                        entityIdOrModel={tenantEntityModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>,
                userSettingsLink:
                    <Link
                        urlOrGetUrl={AadConsoleUrlBuilder.GetTenantUserSettingsUrl(tenantEntityModel.partitionType)}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getAadTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        }
    );
}