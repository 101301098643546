import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { Contract } from "../../../../..";
import { InlineEntitySelection } from "../..";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AwsIdentityActivityRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                identities:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AwsIamIdentity}
                        selection={{
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.identityBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.identityCustomAttributeDefinitionIds,
                            entityIds: riskPolicyConfiguration?.identityIds,
                            entityTags: riskPolicyConfiguration?.identityTags
                        }}
                        sx={sx}/>
            })}
        </Typography>);
}