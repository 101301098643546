import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsEc2ElasticIpDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const elasticIp = resourceModel.entity as Contract.AwsEc2ElasticIp;
    const elasticIpModel = resourceModel as Contract.AwsEc2ElasticIpModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2ElasticIpDefinition",
            () => ({
                info: {
                    items: {
                        dnsZoneIds: "DNS Zones",
                        networkBorderGroupName: "Network Border Group",
                        networkInterfaceId: "Attached To",
                        onPremiseIpAddress: "Customer Owned IP Address",
                        onPremiseIpAddressPoolName: "Customer Owned IP Address Pool",
                        privateIpAddress: "Private IP Address",
                        publicIpAddress: "Public IP Address",
                        publicIpAddressPoolName: "Address Pool"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={elasticIpModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <InfoItem
                        key="networkBorderGroupName"
                        title={localization.info.items.networkBorderGroupName()}
                        value={elasticIp.networkBorderGroupName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={elasticIp.networkInterfaceId}
                        entityTypeName={Contract.TypeNames.AwsEc2NetworkInterface}
                        key="networkInterfaceId"
                        title={localization.info.items.networkInterfaceId()}/>,
                    <InfoItem
                        key="onPremiseIpAddress"
                        title={localization.info.items.onPremiseIpAddress()}
                        value={elasticIp.onPremiseIpAddress}/>,
                    <InfoItem
                        key="onPremiseIpAddressPoolName"
                        title={localization.info.items.onPremiseIpAddressPoolName()}
                        value={elasticIp.onPremiseIpAddressPoolName}/>,
                    <InfoItem
                        key="privateIpAddress"
                        title={localization.info.items.privateIpAddress()}
                        value={elasticIp.privateIpAddress}/>,
                    <InfoItem
                        key="publicIpAddress"
                        title={localization.info.items.publicIpAddress()}
                        value={elasticIp.publicIpAddress}/>,
                    <InfoItem
                        key="publicIpAddressPoolName"
                        title={localization.info.items.publicIpAddressPoolName()}
                        value={elasticIp.publicIpAddressPoolName}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}