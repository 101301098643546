import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, scopeSystemEntityModelStore } from "../../../../../../../../../../common";
import { AzurePipelineIcon, DockerIcon, GitHubWorkflowIcon, GitLabPipelineIcon, JenkinsPipelineIcon, TerraformCloudRunTaskIcon } from "../../../../../../../../../../tenants/code/icons/CodePipelineIcon/icons";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { CodePipeline } from "./components";

export function useCodePipelineItems(): IntegrationItem[] {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems",
            () => ({
                description: "{{count | NumberFormatter.humanize}} configured",
                [Contract.TypeNames.CodePipelineType]: {
                    [Contract.CodePipelineType.GitHubWorkflow]: "GitHub Actions",
                    [Contract.CodePipelineType.JenkinsPipeline]: "Jenkins Pipeline",
                    [Contract.CodePipelineType.AzurePipeline]: "Azure Pipelines",
                    [Contract.CodePipelineType.GitLabPipeline]: "GitLab CI/CD",
                    [Contract.CodePipelineType.TerraformCloudRunTask]: "Terraform Cloud",
                    [Contract.CodePipelineType.General]: "Generic (Docker)"
                }
            }));

    const azurePipelineModels = scopeSystemEntityModelStore.useGetCodePipeline(Contract.CodePipelineType.AzurePipeline) as Contract.CodePipelineModel[];
    const generalModels = scopeSystemEntityModelStore.useGetCodePipeline(Contract.CodePipelineType.General) as Contract.CodePipelineModel[];
    const gitHubWorkflowModels = scopeSystemEntityModelStore.useGetCodePipeline(Contract.CodePipelineType.GitHubWorkflow) as Contract.CodePipelineModel[];
    const gitLabPipelineModels = scopeSystemEntityModelStore.useGetCodePipeline(Contract.CodePipelineType.GitLabPipeline) as Contract.CodePipelineModel[];
    const jenkinsPipelineModels = scopeSystemEntityModelStore.useGetCodePipeline(Contract.CodePipelineType.JenkinsPipeline) as Contract.CodePipelineModel[];
    const terraformCloudRunTaskModels = scopeSystemEntityModelStore.useGetCodePipeline(Contract.CodePipelineType.TerraformCloudRunTask) as Contract.CodePipelineModel[];

    return [
        new IntegrationItem(
            IntegrationItemCategory.CodePipelines,
            gitHubWorkflowModels,
            [localization.description({ count: gitHubWorkflowModels.length })],
            <GitHubWorkflowIcon/>,
            localization[Contract.TypeNames.CodePipelineType][Contract.CodePipelineType.GitHubWorkflow](),
            Contract.CodePipelineType.GitHubWorkflow,
            <CodePipeline type={Contract.CodePipelineType.GitHubWorkflow}/>),
        new IntegrationItem(
            IntegrationItemCategory.CodePipelines,
            jenkinsPipelineModels,
            [localization.description({ count: jenkinsPipelineModels.length })],
            <JenkinsPipelineIcon/>,
            localization[Contract.TypeNames.CodePipelineType][Contract.CodePipelineType.JenkinsPipeline](),
            Contract.CodePipelineType.JenkinsPipeline,
            <CodePipeline type={Contract.CodePipelineType.JenkinsPipeline}/>),
        new IntegrationItem(
            IntegrationItemCategory.CodePipelines,
            azurePipelineModels,
            [localization.description({ count: azurePipelineModels.length })],
            <AzurePipelineIcon/>,
            localization[Contract.TypeNames.CodePipelineType][Contract.CodePipelineType.AzurePipeline](),
            Contract.CodePipelineType.AzurePipeline,
            <CodePipeline type={Contract.CodePipelineType.AzurePipeline}/>),
        new IntegrationItem(
            IntegrationItemCategory.CodePipelines,
            gitLabPipelineModels,
            [localization.description({ count: gitLabPipelineModels.length })],
            <GitLabPipelineIcon/>,
            localization[Contract.TypeNames.CodePipelineType][Contract.CodePipelineType.GitLabPipeline](),
            Contract.CodePipelineType.GitLabPipeline,
            <CodePipeline type={Contract.CodePipelineType.GitLabPipeline}/>),
        new IntegrationItem(
            IntegrationItemCategory.CodePipelines,
            terraformCloudRunTaskModels,
            [localization.description({ count: terraformCloudRunTaskModels.length })],
            <TerraformCloudRunTaskIcon/>,
            localization[Contract.TypeNames.CodePipelineType][Contract.CodePipelineType.TerraformCloudRunTask](),
            Contract.CodePipelineType.TerraformCloudRunTask,
            <CodePipeline type={Contract.CodePipelineType.TerraformCloudRunTask}/>),
        new IntegrationItem(
            IntegrationItemCategory.CodePipelines,
            generalModels,
            [localization.description({ count: generalModels.length })],
            <DockerIcon/>,
            localization[Contract.TypeNames.CodePipelineType][Contract.CodePipelineType.General](),
            Contract.CodePipelineType.General,
            <CodePipeline type={Contract.CodePipelineType.General}/>)
    ];
}