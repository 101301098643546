﻿import { DataTable, DataTableColumn, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, useTheme } from "../../../../../../../../../../../../common";

type AuthorizedSubnetDatasInfoCardProps = {
    authorizedSubnetDatas: Contract.GcpContainerClusterAuthorizedSubnetData[];
};

export function AuthorizedSubnetDatasInfoCard({ authorizedSubnetDatas }: AuthorizedSubnetDatasInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpContainerClusterDefinition.authorizedSubnetDatasInfoCard",
            () => ({
                columns: {
                    name: "Name",
                    subnet: "IP Address Range"
                },
                title: "Control Plane Authorized Networks"
            }));

    const theme = useTheme();
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                fetchItems={() => authorizedSubnetDatas}
                getItemId={(subnet: Contract.GcpContainerClusterAuthorizedSubnetData) => _.indexOf(authorizedSubnetDatas, subnet).toString()}
                sortOptions={{ enabled: false }}
                sx={{ maxHeight: theme.spacing(60) }}
                variant="card">
                <DataTableColumn
                    id="name"
                    itemProperty={(subnet: Contract.GcpContainerClusterAuthorizedSubnetData) => subnet.name}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id="subnet"
                    itemProperty={(subnet: Contract.GcpContainerClusterAuthorizedSubnetData) => subnet.subnet}
                    title={localization.columns.subnet()}/>
            </DataTable>
        </InfoCard>);
}