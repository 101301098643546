﻿import { DataTableColumn, DataTableColumnRenderProps, optionalTableCell, TimeCell, TimeRangeFilter, TimeSpanHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { GranteeUserPermissionRequestsViewType, PermissionEligibilityFilter, PermissionRequestCloseReasonAndStatusFilter, useGranteeUserPermissionRequestsContext } from "../../../../../../..";
import { TableColumnId } from "../../../..";
import { Contract, InlineTenants, ScopeTenantFilter, TimeRangeHelper, TypeHelper } from "../../../../../../../../../../common";
import { PermissionEligibilityCell } from "../../../../../../../PermissionEligibilityCell";
import { StatusCell } from "./components";


export function useCommonColumns(viewType: GranteeUserPermissionRequestsViewType) {
    const { getFiltersResponse } = useGranteeUserPermissionRequestsContext();
    
    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.table.hooks.useDefinition.hooks.useCommonColumns",
            () => ({
                columns: {
                    expirationTimeFrame: {
                        text: [
                            "1 hour",
                            "{{count | NumberFormatter.humanize}} hours"
                        ],
                        title: "Duration"
                    },
                    permissionAssignmentRequestTenantIds: "Accounts",
                    permissionEligibility: "Eligibility",
                    permissionRequestStatus: "Status",
                    reason: "Justification",
                    systemCreationTime: "Creation Time"
                },
                filters: {
                    approverUserSearchableReference: "Approvers",
                    permissionEligibilityId: "Eligibility",
                    systemCreationTime: "Creation Time",
                    tenantIds: "Accounts"
                }
            }));

    return useMemo(
        () => ({
            expirationTimeFrame:
                <DataTableColumn
                    id={TableColumnId.ExpirationTimeFrame}
                    itemProperty={(item: Contract.PermissionRequestModel) => localization.columns.expirationTimeFrame.text(TimeSpanHelper.getHours(item.permissionRequest.expirationTimeFrame))}
                    key={TableColumnId.ExpirationTimeFrame}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.expirationTimeFrame.title()}/>,
            permissionAssignmentRequestTenantIds:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <ScopeTenantFilter
                                    placeholder={localization.filters.tenantIds()}
                                    tenantIds={getFiltersResponse.tenantIdItems.items}/>
                        }
                    }}
                    id={TableColumnId.TenantId}
                    key={TableColumnId.TenantId}
                    render={
                        optionalTableCell<Contract.PermissionRequestModel>(
                            item =>
                                TypeHelper.extendOrImplement(item.permissionRequest.typeName, Contract.TypeNames.PermissionAssignmentRequest)
                                    ? <InlineTenants
                                        sx={{ color: "unset" }}
                                        tenantIds={(item.permissionRequest as Contract.PermissionAssignmentRequest).tenantIds}
                                        tenantVariant="iconText"
                                        variant="itemPlusItemCount"/>
                                    : undefined)}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.permissionAssignmentRequestTenantIds()}/>,
            permissionEligibility:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <PermissionEligibilityFilter
                                    emptyValue={getFiltersResponse.permissionEligibilityIdItems.emptyValue}
                                    permissionEligibilityIds={getFiltersResponse.permissionEligibilityIdItems.items}
                                    placeholder={localization.filters.permissionEligibilityId()}/>
                        }
                    }}
                    id={TableColumnId.PermissionEligibilityId}
                    key={TableColumnId.PermissionEligibilityId}
                    render={PermissionEligibilityCell}
                    selectorOptions={{ disabled: true }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.permissionEligibility()}/>,
            reason:
                <DataTableColumn
                    cellMaxWidth="small"
                    id={TableColumnId.Reason}
                    itemProperty={(item: Contract.PermissionRequestModel) => item.permissionRequest.reason}
                    key={TableColumnId.Reason}
                    render={
                        optionalTableCell<Contract.PermissionRequestModel>(
                            item =>
                                _.isEmpty(item.permissionRequest.reason)
                                    ? undefined
                                    : item.permissionRequest.reason)}
                    selectorOptions={{
                        default: false
                    }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.reason()}/>,
            status:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <PermissionRequestCloseReasonAndStatusFilter
                                    values={
                                        viewType === GranteeUserPermissionRequestsViewType.Active
                                            ? [
                                                Contract.PermissionRequestCloseReasonAndStatus.Active,
                                                Contract.PermissionRequestCloseReasonAndStatus.PendingActivation,
                                                Contract.PermissionRequestCloseReasonAndStatus.PendingApproval
                                            ]
                                            : [
                                                Contract.PermissionRequestCloseReasonAndStatus.ActivationFailed,
                                                Contract.PermissionRequestCloseReasonAndStatus.Cancelled,
                                                Contract.PermissionRequestCloseReasonAndStatus.Expired,
                                                Contract.PermissionRequestCloseReasonAndStatus.Rejected,
                                                Contract.PermissionRequestCloseReasonAndStatus.RequestExpired,
                                                Contract.PermissionRequestCloseReasonAndStatus.Revoked
                                            ]}/>
                        }
                    }}
                    id={TableColumnId.CloseReasonAndStatus}
                    key={TableColumnId.CloseReasonAndStatus}
                    render={({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) => <StatusCell item={item}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.permissionRequestStatus()}/>,
            systemCreationTime:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TimeRangeFilter
                                    emptyValue={getFiltersResponse.systemCreationTimeRange.emptyValue}
                                    placeholder={localization.filters.systemCreationTime()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(getFiltersResponse.systemCreationTimeRange.timeRange)}/>
                        }
                    }}
                    id={TableColumnId.SystemCreationTime}
                    key={TableColumnId.SystemCreationTime}
                    render={({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) => <TimeCell time={item.permissionRequest.systemCreationTime}/>}
                    selectorOptions={{ disabled: true }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.systemCreationTime()}/>
        }),
        [getFiltersResponse, viewType, localization]);
}