import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PrincipalAccessIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path clipRule="evenodd" d="M12.703 2.01614C10.6813 1.88164 8.85506 2.05345 7.9135 2.46386C7.41363 2.68175 6.83352 2.45406 6.61778 1.9553C6.40205 1.45654 6.63238 0.87558 7.13225 0.657694C8.52811 0.0492551 10.7535 -0.0894927 12.8426 0.0494885C14.9517 0.189793 17.1917 0.631704 18.6527 1.37925C19.1365 1.62678 19.3262 2.2204 19.0765 2.70513C18.8267 3.18986 18.2321 3.38215 17.7483 3.13462C16.6349 2.5649 14.7048 2.14931 12.703 2.01614Z" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M12.8445 6.09598C9.92683 5.80155 7.15502 6.16813 5.54076 7.07122C5.06569 7.337 4.46513 7.16733 4.19935 6.69227C3.93358 6.2172 4.10324 5.61663 4.57831 5.35086C6.69945 4.1642 9.94348 3.82193 13.0424 4.13466C16.1504 4.44829 19.3847 5.44764 21.4677 7.21624C21.8827 7.56856 21.9335 8.19057 21.5812 8.60553C21.2288 9.02049 20.6068 9.07127 20.1919 8.71895C18.5363 7.3133 15.7532 6.38951 12.8445 6.09598Z" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M12.8122 9.43673C8.55225 8.85088 5.19025 10.5292 3.90198 13.7704C3.70067 14.2769 3.1274 14.5259 2.62155 14.3267C2.11569 14.1274 1.86882 13.5553 2.07013 13.0488C3.78308 8.73917 8.18798 6.81018 13.0884 7.48411C13.0887 7.48415 13.0881 7.48407 13.0884 7.48411C14.998 7.74528 16.9495 8.53166 18.498 9.64443C20.0287 10.7444 21.2964 12.2626 21.5793 14.0435C21.6647 14.5807 21.298 15.087 20.7603 15.1745C20.2225 15.2619 19.7175 14.8973 19.6321 14.3601C19.4638 13.301 18.6528 12.1876 17.3431 11.2464C16.0514 10.3182 14.4003 9.65381 12.8122 9.43673Z" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M11.2244 16.7963C11.7498 16.6541 12.2911 16.9647 12.4333 17.4901C12.5352 17.8662 12.5566 18.3313 12.5456 18.7767C12.534 19.2428 12.484 19.7685 12.4031 20.3036C12.2435 21.3585 11.9497 22.5418 11.5309 23.4332C11.2995 23.9259 10.7124 24.1377 10.2197 23.9062C9.72705 23.6748 9.51527 23.0877 9.74673 22.595C10.0536 21.9418 10.3094 20.9646 10.4539 20.0088C10.5251 19.5386 10.5658 19.0964 10.5749 18.7278C10.5846 18.3384 10.5567 18.1019 10.5305 18.0052C10.3883 17.4798 10.699 16.9385 11.2244 16.7963Z" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M12.0092 15.8617C11.4065 15.8141 10.8261 15.9576 10.399 16.2263C9.97849 16.491 9.78424 16.8214 9.74108 17.1017C9.73047 17.2098 9.72751 17.3181 9.73216 17.4263L9.73271 17.439L9.73293 17.4517C9.73308 17.4605 9.73324 17.4694 9.7334 17.4785C9.74595 18.1981 9.77119 19.646 8.83119 21.0296C8.52528 21.4799 7.91228 21.5969 7.46201 21.291C7.01174 20.9851 6.8947 20.3721 7.20061 19.9219C7.76936 19.0847 7.77396 18.1957 7.76214 17.4974C7.7539 17.2907 7.76068 17.0838 7.78241 16.8778L7.78403 16.8625L7.78613 16.8472C7.91848 15.8832 8.52441 15.0768 9.34921 14.5579C10.1717 14.0403 11.1892 13.8158 12.1829 13.8981L12.194 13.899L12.205 13.9001C13.1832 14.0033 14.1186 14.3908 14.8129 15.0296C15.5128 15.6734 15.9339 16.5495 15.8991 17.4997C15.8614 18.9904 15.694 20.2344 15.3483 21.1343C15.1532 21.6425 14.583 21.8962 14.0748 21.7011C13.5667 21.5059 13.3129 20.9357 13.5081 20.4276C13.7326 19.843 13.893 18.8653 13.9285 17.444L13.9287 17.4371L13.929 17.4301C13.9407 17.132 13.8148 16.7899 13.4783 16.4803C13.1365 16.1659 12.6178 15.9281 12.0092 15.8617Z" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M12.1259 12.9257C11.4586 12.8609 10.7823 12.9025 10.1393 13.0468C9.49609 13.1911 8.90707 13.4334 8.40514 13.7517L8.39932 13.7554C7.88543 14.0763 7.47538 14.4644 7.18143 14.8872C6.88831 15.3088 6.71542 15.7574 6.65756 16.2058L6.65714 16.2091C6.62812 16.4283 6.60986 16.6442 6.59001 16.8813L6.58797 16.9056L6.58779 16.9077L6.58717 16.9149C6.58503 16.9399 6.58289 16.965 6.58073 16.9905C6.52177 17.6849 6.44696 18.566 5.85987 19.3844C5.54258 19.8267 4.92679 19.928 4.48447 19.6107C4.04215 19.2935 3.9408 18.6777 4.2581 18.2353C4.50404 17.8925 4.55601 17.5273 4.62312 16.7462C4.62312 16.7462 4.62312 16.7462 4.62312 16.7462L4.62356 16.7411L4.62374 16.739L4.62635 16.7078C4.64545 16.4797 4.66707 16.2214 4.7027 15.9519C4.80387 15.1699 5.10157 14.4254 5.56289 13.7619C6.02309 13.1 6.63557 12.5332 7.35233 12.0851C8.05801 11.6381 8.85964 11.3136 9.70769 11.1233C10.5567 10.9328 11.4426 10.8789 12.3151 10.9635C14.3378 11.1547 16.0208 11.8444 17.2135 13.0679L17.2187 13.0733C18.674 14.5893 19.1443 16.7156 18.6923 18.9569C18.5847 19.4905 18.0649 19.8359 17.5313 19.7282C16.9977 19.6206 16.6523 19.1008 16.7599 18.5672C17.1109 16.8265 16.7182 15.4004 15.7992 14.4412C15.0143 13.6375 13.8161 13.0853 12.1285 12.926L12.1259 12.9257Z" fillRule="evenodd"/>
        </SvgIcon>);
}