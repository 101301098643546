import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { AwsTimestreamDatabaseTablesItem } from "../../../../../../../../../../tenants";
import { Info } from "../../../../components";

export function useAwsTimestreamDatabaseDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const databaseModel = resourceModel as Contract.AwsTimestreamDatabaseModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsTimestreamDatabaseDefinition",
            () => ({
                info: {
                    items: {
                        tables: "Tables"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="tables"
                        title={localization.info.items.tables()}
                        value={
                            <AwsTimestreamDatabaseTablesItem
                                databaseId={databaseModel.id}
                                tableCount={databaseModel.tableCount}/>}/>]}
                options={options?.infoOptions}>
            </Info>
    });
}