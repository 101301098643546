import _, { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, optionalTableCell, useLocalization } from "@infrastructure";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useAwsDocDbClusterModelTypeTranslator, useAwsResourceSnapshotTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsDocDbClusterSnapshotDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const docDbClusterModelTypeTranslator = useAwsDocDbClusterModelTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const snapshotTypeTranslator = useAwsResourceSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsDocDbClusterSnapshotDefinition",
            () => ({
                columns: {
                    clusterType: "Cluster Type",
                    storageSize: "Size",
                    type: "Snapshot Type"
                }
            }));
    const translatedDocDbClusterTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.IAwsDocDbCluster,
            {
                includeServiceName: true,
                variant: "title"
            });

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IAwsDocDbClusterSnapshotModel) => ({
                            [localization.columns.type()]: snapshotTypeTranslator(_.as<Contract.IAwsDocDbClusterSnapshot>(item.entity).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsResourceSnapshotType}
                                enumTypeTranslator={snapshotTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterSnapshotType}
                itemProperty={(item: Contract.EntityModel) => snapshotTypeTranslator(_.as<Contract.IAwsDocDbClusterSnapshot>(item.entity).type)}
                key={Contract.EntityModelProperty.AwsDocDbClusterSnapshotType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IAwsDocDbClusterSnapshotModel) => ({
                            [localization.columns.clusterType()]: docDbClusterModelTypeTranslator(item.clusterType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsDocDbClusterModelType}
                                enumTypeTranslator={docDbClusterModelTypeTranslator}
                                placeholder={localization.columns.clusterType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterSnapshotClusterType}
                itemProperty={(item: Contract.IAwsDocDbClusterSnapshotModel) => docDbClusterModelTypeTranslator(item.clusterType)}
                key={Contract.EntityModelProperty.AwsDocDbClusterSnapshotClusterType}
                title={localization.columns.clusterType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IAwsDocDbClusterSnapshotModel) => ({
                            [localization.columns.storageSize()]:
                                _.isNil(_.as<Contract.IAwsDocDbClusterSnapshot>(item.entity).storageSize)
                                    ? ""
                                    : NumberFormatter.storage(_.as<Contract.IAwsDocDbClusterSnapshot>(item.entity).storageSize!)
                        })
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterSnapshotStorageSize}
                key={Contract.EntityModelProperty.AwsDocDbClusterSnapshotStorageSize}
                render={
                    optionalTableCell<Contract.IAwsDocDbClusterSnapshotModel>(
                        item =>
                            _.isNil(_.as<Contract.IAwsDocDbClusterSnapshot>(item.entity).storageSize)
                                ? undefined
                                : NumberFormatter.storage(_.as<Contract.IAwsDocDbClusterSnapshot>(item.entity).storageSize!))}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.storageSize()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.IAwsDocDbClusterSnapshotModel>(
                        Contract.TypeNames.IAwsDocDbCluster,
                        item => [(item.entity as Contract.IEntity as Contract.IAwsDocDbClusterSnapshot).clusterId],
                        translatedDocDbClusterTypeName)}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsDocDbClusterSnapshotCluster)}
                                placeholder={translatedDocDbClusterTypeName}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterSnapshotCluster}
                key={Contract.EntityModelProperty.AwsDocDbClusterSnapshotCluster}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.IAwsDocDbClusterSnapshotModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={_.as<Contract.IAwsDocDbClusterSnapshot>(item.entity).clusterId}
                            entityTypeName={Contract.TypeNames.IAwsDocDbCluster}
                            entityVariant="iconText"/>}
                title={translatedDocDbClusterTypeName}/>,
            commonAccessResourceDefinition.columns.accessLevelColumn,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsDocDbClusterSnapshotRequest(
                new Contract.EntityControllerGetEntityModelPageAwsDocDbClusterSnapshotRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterSnapshotCluster]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterSnapshotClusterType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterSnapshotType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}