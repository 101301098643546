import { Action1, DataTable, DataTableActions } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { Contract, StorageHelper } from "../../../../../../../../../../../common";
import { useCommonContainerImageDefinition } from "../../../../../../../../WorkloadAnalysis/hooks";

type ContainerImageVulnerabilityReportFiltersProps = {
    dataTableActionsRef: React.MutableRefObject<DataTableActions | undefined>;
    filterMap: Dictionary<unknown>;
    setFilterMap: Action1<Dictionary<string>>;
};

export function ContainerImageVulnerabilityReportFilters({ dataTableActionsRef, filterMap, setFilterMap }: ContainerImageVulnerabilityReportFiltersProps) {
    const commonContainerImageDefinition = useCommonContainerImageDefinition();

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            filtersOptions={{
                initial: {
                    state: filterMap
                },
                onChanged: setFilterMap,
                persist: {
                    visibilityStorageItem: StorageHelper.customerVulnerabilityReportContainerImageFilters
                }
            }}
            getItemId={(workloadResourceModel: Contract.WorkloadResourceModel) => workloadResourceModel.id}
            variant="filtersOnly">
            {commonContainerImageDefinition.columns.containerImageId}
            {commonContainerImageDefinition.columns.operatingSystemDisplayName}
            {commonContainerImageDefinition.columns.operatingSystemType}
            {commonContainerImageDefinition.columns.packageDisplayName}
            {commonContainerImageDefinition.columns.vulnerabilities}
            {commonContainerImageDefinition.columns.containerImageRepository}
            {commonContainerImageDefinition.columns.containerImageTags}
            {commonContainerImageDefinition.columns.containerImageDigest}
            {commonContainerImageDefinition.columns.containerImageUsage}
            {commonContainerImageDefinition.columns.creationTime}
            {commonContainerImageDefinition.columns.workloadClusterResources}
            {commonContainerImageDefinition.columns.virtualMachines}
            {commonContainerImageDefinition.columns.kubernetesWorkloadResources}
            {commonContainerImageDefinition.columns.vulnerabilityAttackVector}
            {commonContainerImageDefinition.columns.vulnerabilityExploitable}
            {commonContainerImageDefinition.columns.scanTime}
            {commonContainerImageDefinition.columns.attributes}
            {commonContainerImageDefinition.columns.fixableVulnerability}
        </DataTable>);
}