import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function FedoraIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254732)">
                <path
                    d="M12.0064 0C5.37949 0 0.013956 5.36772 0.0022321 11.9946H0V21.2797H0.0022321C0.00531453 22.7826 1.22492 24 2.72896 24H12.0076C18.6356 23.9957 24 18.6287 24 12.0054C24 5.37847 18.6286 0.010752 11.9958 0.010752L12.0064 0Z"
                    fill="#51A2DA"/>
                <path
                    d="M14.446 4.93555C12.5739 4.93555 10.9201 6.34401 10.6912 8.19219C10.6758 8.3469 10.6714 8.45842 10.6705 8.59868L10.6727 16.155C10.6727 17.6045 9.58714 18.591 8.23081 18.591C6.8726 18.591 5.7784 17.5062 5.7784 16.1527C5.80067 14.6667 6.99058 13.7243 8.37856 13.6832H8.38598L9.79505 13.6703V11.6752L8.38598 11.6858C5.85972 11.6631 3.85449 13.6403 3.78125 16.1539C3.78125 18.5982 5.79056 20.5909 8.23008 20.5909C10.5313 20.5909 12.4463 18.7941 12.6555 16.5457C12.6856 15.7804 12.6709 14.7704 12.6709 14.7704C12.6752 14.3545 12.6645 14.5039 12.6687 13.6781L14.4166 13.6652C15.7691 13.6747 15.7539 11.6385 14.4011 11.6665L12.6684 11.6794C12.6684 10.6543 12.6845 9.63562 12.6684 8.60903C12.6696 7.72114 13.511 6.93653 14.4444 6.93653C15.3782 6.93653 16.3658 7.4024 16.3658 8.60903C16.3658 8.78143 16.3623 8.88177 16.3528 8.94564C16.2486 9.50523 16.631 10.04 17.1946 10.1208C17.7581 10.2006 18.2755 9.79381 18.3314 9.22776C18.3637 9.004 18.3625 8.80658 18.3625 8.60922C18.3625 6.48149 16.4599 4.93909 14.4447 4.93909L14.446 4.93555Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_52885_254732">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}