import { Action0, AnalyticsOptions, Resizer, UrlHelper, useTrackAnalytics } from "@infrastructure";
import { MoreVert as MenuIcon } from "@mui/icons-material";
import { Box, SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactElement, ReactNode, useCallback, useRef, useState } from "react";
import { Popup, PopupTitleOptions } from "./components";

type MenuProps = {
    analyticsOptions?: AnalyticsOptions<"onClose" | "onOpen">;
    automaticPopupWidth?: boolean;
    children?: ReactNode;
    disabled?: boolean;
    iconSx?: SxProps;
    itemsOrGetItems: MenuItem[] | (() => Promise<MenuItem[]>);
    itemSx?: SxProps;
    onClick?: Action0;
    sx?: SxProps;
    titleOptions?: PopupTitleOptions;
    variant?: "bottomCenter" | "bottomLeft" | "bottomRight" | "topCenter" | "topRight";
};

export function Menu({ analyticsOptions, automaticPopupWidth = false, children, disabled = false, iconSx, itemsOrGetItems, itemSx, onClick, sx, titleOptions, variant = "topRight" }: MenuProps) {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [menuWidth, setMenuWidth] = useState(0);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const trackAnalytics = useTrackAnalytics();
    const onClose =
        useCallback(
            () => {
                setOpen(false);

                if (analyticsOptions?.onClose) {
                    trackAnalytics(analyticsOptions.onClose);
                }
            },
            [analyticsOptions]);

    disabled =
        _.isFunction(itemsOrGetItems)
            ? disabled
            : _.isEmpty(itemsOrGetItems)
                ? true
                : disabled;

    return (
        <Fragment>
            <Box
                ref={elementRef}
                sx={{
                    ...(disabled
                        ? {
                            color: theme.palette.text.disabled,
                            cursor: "default"
                        }
                        : {
                            color: theme.palette.text.primary,
                            cursor: "pointer"
                        }),
                    ...sx
                }}
                onClick={
                    event => {
                        if (!disabled) {
                            setOpen(true);

                            if (analyticsOptions?.onOpen) {
                                trackAnalytics(analyticsOptions.onOpen);
                            }

                            onClick?.();
                        }

                        event.stopPropagation();
                    }}>
                <Resizer onSize={() => setMenuWidth(() => elementRef.current?.getBoundingClientRect().width ?? 0)}/>
                {children ??
                    <MenuIcon
                        sx={{
                            fontSize: "18px",
                            ...iconSx
                        }}/>}
            </Box>
            <Popup
                elementRef={elementRef}
                itemsOrGetItems={itemsOrGetItems}
                itemSx={itemSx}
                open={open}
                sx={{
                    width:
                        automaticPopupWidth
                            ? theme.px(menuWidth)
                            : undefined
                }}
                titleOptions={titleOptions}
                variant={variant}
                onClose={onClose}/>
        </Fragment>);
}

export abstract class MenuItem {
}

type MenuItemOptions = {
    disabled?: boolean | undefined;
    icon?: ReactNode;
    onClick?: Action0;
    tooltip?: ReactNode | string;
};

export class StaticMenuItem extends MenuItem {
    constructor(public element: ReactNode) {
        super();
    }
}

export class ActionMenuItem extends MenuItem {
    constructor(
        public onClick: Action0,
        public title: ReactNode,
        public options?: ActionMenuItemOptions) {
        super();
    }
}

type ActionMenuItemOptions = MenuItemOptions & {
    analyticsOptions?: AnalyticsOptions<"onClick">;
    confirmOptions?: ActionMenuItemConfirmOptions;
    disabled?: boolean;
    selected?: boolean;
};

type ActionMenuItemConfirmOptions = {
    cancelTitle?: false | string;
    descriptionElement?: ReactElement;
    message: string;
    messageElement?: ReactElement;
    okTitles?: string[];
};

export class ContentMenuItem extends MenuItem {
    constructor(
        public content: (onClose: () => void) => ReactNode,
        public title: ReactNode,
        public options?: ContentMenuItemOptions) {
        super();
    }
}

type ContentMenuItemOptions = MenuItemOptions & {
    placement?: "bottomLeft" | "topRight";
    variant?: "default" | "subMenu";
};

export class DialogMenuItem extends MenuItem {
    constructor(
        public dialog: (onClose: () => void) => ReactNode,
        public title: ReactNode,
        public options?: MenuItemOptions) {
        super();
    }
}

export class SeparatorMenuItem extends MenuItem {
    constructor() {
        super();
    }
}

export class SubMenuItem extends MenuItem {
    constructor(
        public itemsOrGetItems: MenuItem[] | (() => Promise<MenuItem[]>),
        public title: string | ReactNode,
        public options?: SubMenuItemOptions) {
        super();
    }
}

type SubMenuItemOptions = MenuItemOptions & {
    subMenuTitle?: string;
};

export class UrlMenuItem extends ActionMenuItem {
    constructor(
        public title: string,
        public url: string,
        public options?: UrlMenuItemOptions) {
        super(
            () => {
                if (options?.newTab === false) {
                    window.location.href = url;
                } else {
                    UrlHelper.openNewTab(url);
                }
            },
            title,
            options);
    }
}

type UrlMenuItemOptions = ActionMenuItemOptions & {
    newTab?: boolean;
};