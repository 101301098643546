import { Box, Stack, useTheme } from "@mui/material";
import React, { ReactNode } from "react";

type WizardLayoutProps = {
    children: ReactNode;
    disablePadding?: boolean;
    sideElement?: ReactNode;
};

export function WizardLayout({ children, disablePadding, sideElement }: WizardLayoutProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{ height: "100%" }}>
            {sideElement &&
                <Box
                    sx={{
                        borderRight: theme.border.primary,
                        height: "100%",
                        overflowY: "auto",
                        width: theme.spacing(32)
                    }}>
                    {sideElement}
                </Box>}
            <Stack
                sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding:
                        disablePadding
                            ? undefined
                            : theme.spacing(0, 4, 2, 4)
                }}>
                {children}
            </Stack>
        </Stack>);
}