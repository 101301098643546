import { EmptyMessage, useLocalization } from "@infrastructure";
import { Grid, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, EntityTypeMetadataModelHelper, LicensingHelper, SecretItem, useTenantTypeTranslator, useTheme } from "../../../../../../../../common";
import { ContentPages, ContentPagesItem, ContentPagesProps } from "../../../ContentPages";
import { SectionHeader } from "../SectionHeader";
import { Summary } from "../Summary";
import { EntityPublicDataItem, SecretsTitleSection } from "./components";

type EntityPublicDataAndSecretsContentPagesProps = Omit<ContentPagesProps, "children"> & {
    entityEntitiesViewTypeNameToPublicRiskSeverityStatsMap: Dictionary<Contract.SecurityStats>;
    publicEntityRiskSeverityStats: Contract.SecurityStats;
    secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap: Dictionary<Dictionary<Contract.DashboardSummarySecretExistsRiskEntityTypeData>>;
};

export function EntityPublicDataAndSecretsContentPages({ entityEntitiesViewTypeNameToPublicRiskSeverityStatsMap, publicEntityRiskSeverityStats, secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap, ...props }: EntityPublicDataAndSecretsContentPagesProps) {
    const entityPublicDatas =
        useMemo(
            () =>
                _(entityEntitiesViewTypeNameToPublicRiskSeverityStatsMap).
                    map(
                        (publicRiskSeverityStats, entityEntitiesViewTypeName) => ({
                            entityEntitiesViewTypeName,
                            publicRiskSeverityStats
                        })).
                    orderBy(
                        [
                            entityData => entityData.publicRiskSeverityStats.unsecuredSeverityToCountMap[Contract.Severity.Critical],
                            entityData => entityData.publicRiskSeverityStats.unsecuredSeverityToCountMap[Contract.Severity.High],
                            entityData => entityData.publicRiskSeverityStats.unsecuredSeverityToCountMap[Contract.Severity.Medium],
                            entityData => entityData.publicRiskSeverityStats.unsecuredSeverityToCountMap[Contract.Severity.Low]
                        ],
                        [
                            "desc",
                            "desc",
                            "desc",
                            "desc"
                        ]).
                    value(),
            [entityEntitiesViewTypeNameToPublicRiskSeverityStatsMap]);

    const tenantTypeTranslator = useTenantTypeTranslator();
    const secretDatas =
        useMemo(
            () =>
                _(secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap).
                    flatMap(
                        (entityTypeNameToDataMap, riskPolicyConfigurationTypeName) =>
                            _.map(
                                entityTypeNameToDataMap,
                                ({ entityIds, secretCount }, entityTypeName) =>
                                    ({
                                        entityIds,
                                        entityTypeName,
                                        riskPolicyConfigurationTypeName,
                                        secretCount
                                    }))).
                    orderBy(
                        [
                            itemData => tenantTypeTranslator(EntityTypeMetadataModelHelper.get(itemData.entityTypeName).tenantType),
                            itemData => itemData.secretCount
                        ],
                        [
                            "asc",
                            "desc"
                        ]).
                    value(),
            [secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap]);

    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.entityPublicDataAndSecretsContentPages",
            () => ({
                empty: {
                    entityPublicData: "No Public Resources",
                    secrets: "No Exposed Secrets"
                },
                entityPublicData: {
                    subtitle: "View resources that are publicly exposed to the internet. Resources are categorized by type, with a breakdown by risk severity.",
                    title: "Public Resources"
                },
                subtitle: "View information about exposed resources and secrets to prevent account misuse and potential breaches.",
                title: "Exposed Resources and Secrets"
            }));

    const theme = useTheme();
    return (
        <ContentPages {...props}>
            <ContentPagesItem>
                <Stack
                    spacing={2}
                    sx={{ width: "100%" }}>
                    {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                        <SectionHeader
                            subtitle={localization.subtitle()}
                            title={localization.title()}
                            variant="main"/>}
                    <SectionHeader
                        subtitle={localization.entityPublicData.subtitle()}
                        title={localization.entityPublicData.title()}
                        variant={
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                                ? "sub"
                                : "main"}/>
                    {publicEntityRiskSeverityStats.count !== 0 &&
                        <Summary
                            severityToCountMap={publicEntityRiskSeverityStats.unsecuredSeverityToCountMap}
                            title={localization.entityPublicData.title()}/>}
                </Stack>
            </ContentPagesItem>
            {_.isEmpty(entityPublicDatas)
                ? <ContentPagesItem>
                    <Grid
                        container={true}
                        style={{
                            border: theme.border.primary,
                            borderRadius: theme.spacing(0.75),
                            margin: "unset",
                            width: "unset"
                        }}>
                        <EmptyMessage message={localization.empty.entityPublicData()}/>
                    </Grid>
                </ContentPagesItem>
                : _.map(
                    entityPublicDatas,
                    ({ entityEntitiesViewTypeName, publicRiskSeverityStats }, entityPublicDataIndex) =>
                        <ContentPagesItem
                            key={entityEntitiesViewTypeName}
                            paddingTop={
                                entityPublicDataIndex === 0
                                    ? 16
                                    : undefined}>
                            {({ pageFirstItem, pageLastItem }) =>
                                <EntityPublicDataItem
                                    entityTypeName={entityEntitiesViewTypeName}
                                    pageFirstItem={pageFirstItem || entityPublicDataIndex === 0}
                                    pageLastItem={pageLastItem || entityPublicDataIndex === entityPublicDatas.length - 1}
                                    securityStats={publicRiskSeverityStats}/>}
                        </ContentPagesItem>)}
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) && [
                <ContentPagesItem
                    key={`secrets_${Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm}_item`}
                    paddingTop={32}>
                    <SecretsTitleSection itemDatas={secretDatas}/>
                </ContentPagesItem>,
                ..._.isEmpty(secretDatas)
                    ? [
                        <ContentPagesItem key="secrets_empty_state">
                            <Grid
                                container={true}
                                style={{
                                    border: theme.border.primary,
                                    borderRadius: theme.spacing(0.75),
                                    margin: "unset",
                                    width: "unset"
                                }}>
                                <EmptyMessage message={localization.empty.secrets()}/>
                            </Grid>
                        </ContentPagesItem>
                    ]
                    : _.map(
                        secretDatas,
                        (secretData, secretDataIndex) =>
                            <ContentPagesItem
                                key={secretData.entityTypeName}
                                paddingTop={
                                    secretDataIndex === 0
                                        ? 16
                                        : undefined
                                }>
                                {({ pageFirstItem, pageLastItem }) =>
                                    <Grid
                                        container={true}
                                        style={{
                                            border: theme.border.primary,
                                            borderRadius:
                                                pageLastItem
                                                    ? theme.spacing(0, 0, 0.75, 0.75)
                                                    : pageFirstItem || secretDataIndex === 0
                                                        ? theme.spacing(0.75, 0.75, 0, 0)
                                                        : undefined,
                                            borderTop:
                                                pageFirstItem || secretDataIndex === 0
                                                    ? theme.border.primary
                                                    : "none",
                                            padding: theme.spacing(2),
                                            width: "100%"
                                        }}>
                                        <SecretItem
                                            entityIds={secretData.entityIds}
                                            entityTypeName={secretData.entityTypeName}
                                            interactive={false}
                                            riskPolicyConfigurationTypeName={secretData.riskPolicyConfigurationTypeName}
                                            secretCount={secretData.secretCount}/>
                                    </Grid>}
                            </ContentPagesItem>)
            ]}
        </ContentPages>);
}