import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpApiKeysKeysRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpTenantEntityRiskContext } from "../contexts";

export function useGcpApiKeysKeyExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpApiKeysKeyExistsRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GcpTenantEntityModel;

    const getGcpApiKeysKeysRiskContext = useGetGcpApiKeysKeysRiskContext();
    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpApiKeysKeyExistsRiskDefinition",
            () => ({
                description: "{{tenantEntity}} has insecure API keys",
                sections: {
                    resolution: {
                        step1: "Before deleting the API keys, migrate your applications (if any) to use a standard authentication flow",
                        step2: "For each API Key under **API keys** section, select the 3-dot menu and click **Delete API key**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.ApiKeys}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const keysRiskContext = getGcpApiKeysKeysRiskContext(risk.apiKeysKeyIds);
            const tenantEntityRiskContext = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.apiKeys,
                keysRiskContext.sensitive,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}