import { FormLayout, useChangeEffect, useLocalization, VerticalFillGrid } from "@infrastructure";
import { Box, Tab, Tabs } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator, UserHelper, useTheme } from "../../../../../../../../common";
import { useCloudRiskPolicyItemEntityExclusion } from "../../../../hooks";
import { useConfigurationContext, useSetConfigurationContext } from "../../Configuration";
import { EntityTypeName, EntityTypeNamePropertyPattern, EntityTypeNamePropertyPatternItem } from "./components";

export class EntityExclusionSection {
    constructor(
        public excludedEntityTypeNameToIdToExclusionMap: Dictionary<Dictionary<Contract.RiskPolicyConfigurationExclusionData>>,
        public entityTypeNameToPropertyPatternItemsMap: Dictionary<EntityTypeNamePropertyPatternItem[]>,
        public entityTypeNameToPropertyPatternValidMap: Dictionary<boolean>,
        public exclusionEntityTypeNameAndPropertyPatternValue: string,
        public excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap: Dictionary<Dictionary<string[]>>) {
    }
}

type EntityExclusionProps = {
    parentRiskPolicyModels?: Contract.RiskPolicyModel[];
    scopeRiskPolicyModel: Contract.RiskPolicyModel;
    tenantRiskPolicyModels?: Contract.RiskPolicyModel[];
};

export function EntityExclusion({ scopeRiskPolicyModel }: EntityExclusionProps) {
    const { entityExclusionSection, item, scopeId } = useConfigurationContext();
    const setConfigurationContext = useSetConfigurationContext();

    const {
        defaultEntityTypeNameToPropertyPatternItemsMap,
        defaultEntityTypeNameToPropertyPatternValidMap,
        defaultExcludedEntityTypeNameToIdToExclusionMap,
        defaultExclusionEntityTypeNameAndPropertyPatternValue,
        excludedEntityTypeNameToIdToScopeIdsMap,
        excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap,
        riskPolicyExclusionAnyScopeEntityTypeNames,
        riskPolicyExclusionEntityTypeNames,
        riskPolicyExclusionEntityTypeNameToPropertyPatternTypeNamesMap
    } = useCloudRiskPolicyItemEntityExclusion(item, scopeId, entityExclusionSection);

    const [excludedEntityTypeNameToIdToExclusionMap, setExcludedEntityTypeNameToIdToExclusionMap] = useState(defaultExcludedEntityTypeNameToIdToExclusionMap);
    const [entityTypeNameToPropertyPatternItemsMap, setEntityTypeNameToPropertyPatternItemsMap] = useState(defaultEntityTypeNameToPropertyPatternItemsMap);
    const [entityTypeNameToPropertyPatternValidMap, setEntityTypeNameToPropertyPatternValidMap] = useState(defaultEntityTypeNameToPropertyPatternValidMap);
    const [exclusionEntityTypeNameAndPropertyPatternValue, setExclusionEntityTypeNameAndPropertyPatternValue] = useState(defaultExclusionEntityTypeNameAndPropertyPatternValue);

    useChangeEffect(
        () => {
            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    dirty: true
                }));
        },
        [entityTypeNameToPropertyPatternItemsMap, excludedEntityTypeNameToIdToExclusionMap]);

    useEffect(
        () => {
            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    entityExclusionSection: {
                        entityTypeNameToPropertyPatternItemsMap,
                        entityTypeNameToPropertyPatternValidMap,
                        excludedEntityTypeNameToIdToExclusionMap,
                        excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap,
                        exclusionEntityTypeNameAndPropertyPatternValue
                    }
                }));
        },
        [entityTypeNameToPropertyPatternItemsMap, excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap, entityTypeNameToPropertyPatternValidMap, excludedEntityTypeNameToIdToExclusionMap, exclusionEntityTypeNameAndPropertyPatternValue]);

    const exclusionEntityTypeName = exclusionEntityTypeNameAndPropertyPatternValue.split("-")[0];

    const userSecurityWrite =
        UserHelper.hasScopePermissions(
            scopeRiskPolicyModel.riskPolicyConfiguration.scopeId,
            Contract.IdentityPermission.SecurityWrite);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.entityExclusion",
            () => ({
                tab: "{{translatedEntityTypeName}} ({{excludedEntityCount}})",
                tabPropertyPattern: "{{translatedEntityTypeName}} by Pattern ({{propertyPatternItemCount}})"
            }));

    const theme = useTheme();
    return (
        <FormLayout disableContentPadding={true}>
            <VerticalFillGrid>
                <Tabs
                    indicatorColor="primary"
                    value={exclusionEntityTypeNameAndPropertyPatternValue}
                    variant="scrollable"
                    onChange={(event, exclusionEntityTypeNameAndPatternValue) => setExclusionEntityTypeNameAndPropertyPatternValue(exclusionEntityTypeNameAndPatternValue)}>
                    {_.map(
                        riskPolicyExclusionEntityTypeNames,
                        exclusionEntityTypeName =>
                            <Tab
                                key={`${exclusionEntityTypeName}-false`}
                                label={
                                    localization.tab({
                                        excludedEntityCount: _.size(_.keys(excludedEntityTypeNameToIdToExclusionMap[exclusionEntityTypeName])),
                                        translatedEntityTypeName:
                                            entityTypeNameTranslator(
                                                exclusionEntityTypeName,
                                                {
                                                    count: 0,
                                                    includeServiceName: false,
                                                    variant: "title"
                                                })
                                    })}
                                value={`${exclusionEntityTypeName}-false`}/>)}
                    {_.map(
                        riskPolicyExclusionEntityTypeNames,
                        exclusionEntityTypeName =>
                            <Tab
                                key={`${exclusionEntityTypeName}-true`}
                                label={
                                    localization.tabPropertyPattern({
                                        propertyPatternItemCount: _.size(entityTypeNameToPropertyPatternItemsMap[exclusionEntityTypeName]),
                                        translatedEntityTypeName:
                                            entityTypeNameTranslator(
                                                exclusionEntityTypeName,
                                                {
                                                    count: 0,
                                                    includeServiceName: false,
                                                    variant: "title"
                                                })
                                    })}
                                value={`${exclusionEntityTypeName}-true`}/>)}
                </Tabs>
                <Box
                    sx={{
                        height: "100%",
                        padding: theme.spacing(3)
                    }}>
                    {_.includes(exclusionEntityTypeNameAndPropertyPatternValue, "-true")
                        ? <EntityTypeNamePropertyPattern
                            entityPropertyPatternTypeNames={riskPolicyExclusionEntityTypeNameToPropertyPatternTypeNamesMap[exclusionEntityTypeName]}
                            entityTypeName={exclusionEntityTypeName}
                            propertyPatternItems={entityTypeNameToPropertyPatternItemsMap[exclusionEntityTypeName]}
                            propertyPatternKeyToScopeIdsMap={excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap[exclusionEntityTypeName]}
                            readOnly={!userSecurityWrite}
                            onPropertyPatternItemsChanged={
                                propertyPatternItems =>
                                    setEntityTypeNameToPropertyPatternItemsMap({
                                        ...entityTypeNameToPropertyPatternItemsMap,
                                        [exclusionEntityTypeName]: propertyPatternItems
                                    })
                            }
                            onValidChanged={
                                valid =>
                                    setEntityTypeNameToPropertyPatternValidMap({
                                        ...entityTypeNameToPropertyPatternValidMap,
                                        [exclusionEntityTypeName]: valid
                                    })}/>
                        : <EntityTypeName
                            entityTypeName={exclusionEntityTypeName}
                            excludedEntityIdToExclusionMap={excludedEntityTypeNameToIdToExclusionMap[exclusionEntityTypeName]}
                            excludedEntityIdToScopeIdsMap={excludedEntityTypeNameToIdToScopeIdsMap[exclusionEntityTypeName]}
                            readOnly={!userSecurityWrite}
                            riskPolicyExclusionAnyScopeEntityTypeNames={riskPolicyExclusionAnyScopeEntityTypeNames}
                            riskPolicyExclusionEntityTypeNames={riskPolicyExclusionEntityTypeNames}
                            scopeRiskPolicyModel={scopeRiskPolicyModel}
                            onExcludedEntityIdToExclusionMapChanged={
                                excludedEntityIdToExclusionMap => {
                                    setExcludedEntityTypeNameToIdToExclusionMap(
                                        excludedEntityTypeNameToIdToExclusionMap => ({
                                            ...excludedEntityTypeNameToIdToExclusionMap,
                                            [exclusionEntityTypeName]: excludedEntityIdToExclusionMap
                                        }));
                                }}/>
                    }
                </Box>
            </VerticalFillGrid>
        </FormLayout>);
}