import { AnalyticsContext, clearOperations, Link, Loading, makeContextProvider, map, useExecuteOperation, useRoute, useUniqueKey, VerticalFillGrid } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ApiController, ApplicationHelper, ApplicationView, Contract, Customers, Layout, LogoTextIcon, PermissionManagementController, PermissionManagementHelper, scopeSystemEntityModelStore, SignIn, SupportFrame, TenantHelper, tenantModelStore, Topbar, useApplicationViewRoute, UserConsoleAppUrlHelper, UserHelper } from "../../common";
import { ApproverUserPermissionRequests, AuditEvents, GranteeUserPermissionRequests, PermissionEligibilities, Sidebar, SignInError, Welcome } from "./components";
import { UserView } from "./userView";

export class UserContext {
    constructor(public reloadView: () => void) {
    }
}

export const [useUserContext, , useUserContextProvider] = makeContextProvider<UserContext>();

export function User() {
    ApiController.initialize(Contract.ConsoleAppType.User);

    const { view } = useRoute(`/${ApplicationView.User}/{view}`);
    return map(
        view,
        {
            [UserView.Customers]: () => <Customers consoleAppType={Contract.ConsoleAppType.User}/>,
            [UserView.SignIn]:
                () =>
                    <SignIn
                        consoleAppType={Contract.ConsoleAppType.User}
                        enableTenable={false}/>,
            [UserView.SignInError]: () => <SignInError/>
        },
        () => <Core/>);
}

function Core() {
    const [{ approver, grantee }] =
        useExecuteOperation(
            Core,
            async () => {
                await ApplicationHelper.initialize(Contract.ConsoleAppType.User);
                return UserHelper.hasAnyScopePermissions(undefined, Contract.IdentityPermission.PermissionManagementPermissionRequest)
                    ? await PermissionManagementController.getUserInfo()
                    : {
                        approver: false,
                        grantee: false
                    };
            });

    const views =
        useMemo(
            () => {
                const admin = UserHelper.hasAnyScopePermissions(undefined, Contract.IdentityPermission.PermissionManagementAdministrationRead);
                return _<string>([]).
                    concatIf(
                        grantee,
                        UserView.GranteeUserPermissionRequests).
                    concatIf(
                        approver,
                        UserView.ApproverUserPermissionRequests).
                    concatIf(
                        admin,
                        UserView.PermissionEligibilities).
                    concatIf(
                        admin,
                        UserView.AuditEvents).
                    value();
            },
            [approver, grantee]);

    const [userViewRoute, setUserViewRoute] =
        useApplicationViewRoute(
            `/${ApplicationView.User}`,
            views,
            views[0]);

    const { view } = userViewRoute;

    const [viewKey, updateViewKey] = useUniqueKey();
    const [, , UserContextProvider] =
        useUserContextProvider(
            () =>
                new UserContext(
                    () => {
                        clearOperations();
                        updateViewKey();
                    }),
            [view]);

    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const activePermissionManagementTenantModels = tenantModelStore.useGetActiveTenants(TenantHelper.PermissionManagementTenantTypes);

    const permissionEligibilityModels = scopeSystemEntityModelStore.useGetPermissionEligibility();
    const permissionManagementValid =
        useMemo(
            () => {
                if (PermissionManagementHelper.permissionAuditEventExists ||
                    !_.isEmpty(permissionEligibilityModels)) {
                    return true;
                }

                const activePermissionManagementEnabledIdentityProviderTenantExists =
                    _.some(
                        activePermissionManagementTenantModels,
                        activePermissionManagementTenantModel =>
                            TenantHelper.isIdentityProviderTenant(activePermissionManagementTenantModel) &&
                            (activePermissionManagementTenantModel.state as Contract.IdentityProviderTenantState).permissionManagementEnabled);

                if (activePermissionManagementEnabledIdentityProviderTenantExists) {
                    return true;
                }

                const activePermissionManagementEnabledCloudProviderTenantModels =
                    _.filter(
                        activePermissionManagementTenantModels,
                        activePermissionManagementTenantModel =>
                            TenantHelper.isCloudProviderTenant(activePermissionManagementTenantModel) &&
                            (activePermissionManagementTenantModel.state as Contract.CloudProviderTenantState).permissionManagementEnabled);

                const activePermissionManagementEnabledCloudProviderTenantPrincipalTenantIds =
                    _(activePermissionManagementEnabledCloudProviderTenantModels).
                        map(activePermissionManagementEnabledTenantModel => (activePermissionManagementEnabledTenantModel.state as Contract.CloudProviderTenantState).permissionManagementPrincipalTenantId).
                        filter().
                        as<string>().
                        uniq().
                        value();
                const activePrincipalPermissionManagementTenantExist =
                    _(activeTenantModels).
                        map(activeTenantModel => activeTenantModel.configuration.id).
                        intersection(activePermissionManagementEnabledCloudProviderTenantPrincipalTenantIds).
                        some();

                return _.some(activePermissionManagementEnabledCloudProviderTenantModels) &&
                    activePrincipalPermissionManagementTenantExist;
            },
            [activeTenantModels, activePermissionManagementTenantModels]);

    const viewElement =
        useMemo(
            () =>
                map(
                    view,
                    {
                        [UserView.ApproverUserPermissionRequests]: () => <ApproverUserPermissionRequests applicationView={ApplicationView.User}/>,
                        [UserView.AuditEvents]: () => <AuditEvents/>,
                        [UserView.GranteeUserPermissionRequests]: () => <GranteeUserPermissionRequests applicationView={ApplicationView.User}/>,
                        [UserView.PermissionEligibilities]: () => <PermissionEligibilities/>
                    }),
            [view]);

    return (
        <UserContextProvider>
            <SupportFrame>
                <Layout
                    contained={permissionManagementValid}
                    sidebar={
                        permissionManagementValid
                            ? <Sidebar
                                rootRelativeUrl={UserConsoleAppUrlHelper.getRootRelativeUrl()}
                                setViewRoute={setUserViewRoute}
                                viewRoute={userViewRoute}
                                views={views as UserViewType[]}/>
                            : undefined}
                    topbar={
                        <Topbar consoleAppType={Contract.ConsoleAppType.User}>
                            <Box sx={{ width: "fit-content" }}>
                                <Link
                                    urlOrGetUrl={UserConsoleAppUrlHelper.getRootRelativeUrl()}
                                    variant="text">
                                    <LogoTextIcon
                                        sx={{
                                            height: "22px",
                                            width: "194px"
                                        }}/>
                                </Link>
                            </Box>
                        </Topbar>}>
                    {permissionManagementValid
                        ? <Loading key={viewKey}>
                            <VerticalFillGrid>
                                <AnalyticsContext context={view}>
                                    {viewElement}
                                </AnalyticsContext>
                            </VerticalFillGrid>
                        </Loading>
                        : <Welcome/>}
                </Layout>
            </SupportFrame>
        </UserContextProvider>);
}

export type UserViewType = keyof (typeof UserView);