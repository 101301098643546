import { Card, Stack, Typography } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { Link, NumberFormatter, useLocalization, ValuesFilterSelection } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection, EntitiesWidgetHelper, EntityCategoryData, NotConnectedIcon, useTheme, WidgetDefinition } from "../../../../../../..";
import { CodeDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { Entities } from "../components";

export function useGetCodeEntitiesDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<CodeDashboardContext>();

    const codeEntitiesWidgetTitleLocalization = useCodeEntitiesWidgetTitleLocalization();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetCodeEntitiesDefinition",
            () => ({
                helpText: "See all your cloud resources that were traced back to their code origins."
            }));

    const codeEntityTypeCategoryToCount =
        useMemo(
            () => EntitiesWidgetHelper.getEntityTypeCategoryToCountMap(summary.entityTypeEntitiesViewNameToCountMap),
            [summary.entityTypeEntitiesViewNameToCountMap]);

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element:
                _.isEmpty(codeEntityTypeCategoryToCount)
                    ? <CodeEntitiesNotSynced/>
                    : <CodeEntities/>,
            options: {
                helpText: localization.helpText(),
                title: codeEntitiesWidgetTitleLocalization.title()
            }
        }),
        [codeEntityTypeCategoryToCount, localization]);
}

function CodeEntities() {
    const { entityTypeEntitiesViewNameToCountMap, summary } = useDashboardContext<CodeDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetCodeEntitiesDefinition.codeEntities",
            () => ({
                entityCategoryDataTitle: "{{codeEntityTypeCategoryResourceCount}}/{{entityTypeCategoryResourceCount}}"
            }));

    const entityTypeCategoryToCount =
        useMemo(
            () => EntitiesWidgetHelper.getEntityTypeCategoryToCountMap(entityTypeEntitiesViewNameToCountMap),
            [entityTypeEntitiesViewNameToCountMap]);
    const codeEntityTypeCategoryToCount =
        useMemo(
            () => EntitiesWidgetHelper.getEntityTypeCategoryToCountMap(summary.entityTypeEntitiesViewNameToCountMap),
            [summary.entityTypeEntitiesViewNameToCountMap]);

    const entityTypeCategoryToEntityCategoryDataMap =
        _.mapValues(
            entityTypeCategoryToCount,
            (entityTypeEntitiesCount, entityTypeCategory) =>
                new EntityCategoryData(
                    entityTypeEntitiesCount,
                    (codeEntityTypeCategoryToCount[entityTypeCategory] ?? 0) === 0,
                    localization.entityCategoryDataTitle({
                        codeEntityTypeCategoryResourceCount: NumberFormatter.humanize(codeEntityTypeCategoryToCount[entityTypeCategory] ?? 0),
                        entityTypeCategoryResourceCount: NumberFormatter.humanize(entityTypeCategoryToCount[entityTypeCategory])
                    })));

    const entitiesRelativeUrlFilters = {
        builtInAttributeTypeNames:
            new ValuesFilterSelection(
                false,
                [Contract.TypeNames.CodeResourceAttribute] as any)
    } as CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection;

    return (
        <Entities
            entitiesRelativeUrlFilters={entitiesRelativeUrlFilters}
            entityTypeCategoryToEntityCategoryDataMap={entityTypeCategoryToEntityCategoryDataMap}
            entityTypeEntitiesViewNameToCountMap={entityTypeEntitiesViewNameToCountMap}/>);
}

function CodeEntitiesNotSynced() {
    const codeEntitiesWidgetTitleLocalization = useCodeEntitiesWidgetTitleLocalization();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetCodeEntitiesDefinition.codeEntitiesNotSynced",
            () => ({
                notSynced: {
                    description: {
                        documentationLink: "Learn more.",
                        title: "To get started, sync the code associated with your cloud resources."
                    },
                    title: "Cloud to Code Is Not Synced"
                }
            }));

    const theme = useTheme();
    return (
        <Card sx={{ height: theme.spacing(30.75) }}>
            <Stack
                alignItems="center"
                justifyContent="center"
                spacing={0.75}
                sx={{
                    height: "100%",
                    padding: theme.spacing(2),
                    width: "100%"
                }}>
                <NotConnectedIcon
                    sx={{
                        height: "28px",
                        width: "28px"
                    }}/>
                <Typography
                    sx={{
                        fontSize: "16px",
                        fontWeight: 600
                    }}>
                    {codeEntitiesWidgetTitleLocalization.title()}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: 400
                    }}>
                    {localization.notSynced.description.title()}
                </Typography>
                <Stack
                    sx={{ paddingTop: theme.spacing(2) }}>
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsTraceIssuesBackToCodeRelativeUrl()}
                        variant="external">
                        {localization.notSynced.description.documentationLink()}
                    </Link>
                </Stack>
            </Stack>
        </Card>);
}

function useCodeEntitiesWidgetTitleLocalization() {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetCodeEntitiesDefinition.useCodeEntitiesWidgetTitleLocalization",
            () => ({
                title: "Cloud to Code"
            }));

    return localization;
}