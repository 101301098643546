import { DataTable, DataTableColumn, DataTableColumnRenderProps, InlineItems, optionalTableCell, PasswordText, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, SecretExclusionActionCell, useTheme } from "../../../../../../../../../../../../../../common";


export type EnvironmentVariableSecretTableProps = {
    risk: Contract.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk;
    taskDefinition: Contract.AwsEcsTaskDefinition;
};

export function EnvironmentVariableSecretTable({ risk, taskDefinition }: EnvironmentVariableSecretTableProps) {
    const environmentVariableSecretTableItems =
        useMemo(
            () =>
                _.flatMap(
                    risk.secretEnvironmentVariableNameToContainerDefinitionNameToRevisionIdsMap,
                    (containerDefinitionNameToRevisionIdsMap, secretEnvironmentVariableName) =>
                        _.flatMap(
                            containerDefinitionNameToRevisionIdsMap,
                            (revisionIds, containerDefinitionName) => {
                                const secretEnvironmentVariableValueToRevisionIdsMap =
                                    _(revisionIds).
                                        map(
                                            revisionId => {
                                                const filteredContainerDefinition =
                                                    _.find(
                                                        taskDefinition.revisionMap[revisionId]?.containerDefinitions,
                                                        containerDefinition => containerDefinition.name == containerDefinitionName);

                                                const secretEnvironmentVariableValue = filteredContainerDefinition?.environmentVariableNameToValueMap[secretEnvironmentVariableName] ?? "";

                                                return { revisionId, secretEnvironmentVariableValue };
                                            }).
                                        groupBy(secretEnvironmentVariableValueData => secretEnvironmentVariableValueData.secretEnvironmentVariableValue).
                                        mapValues(
                                            secretEnvironmentVariableValueDatas =>
                                                _.map(
                                                    secretEnvironmentVariableValueDatas,
                                                    secretEnvironmentVariableValueData => secretEnvironmentVariableValueData.revisionId)).
                                        value();

                                return _.map(
                                    secretEnvironmentVariableValueToRevisionIdsMap,
                                    (revisionIds, secretEnvironmentVariableValue) =>
                                        new EnvironmentVariableSecretTableItem(
                                            containerDefinitionName,
                                            revisionIds,
                                            secretEnvironmentVariableName,
                                            secretEnvironmentVariableValue));
                            })),
            [risk, taskDefinition]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskDefinition.environmentVariableSecretTable",
            () => ({
                columns: {
                    containerDefinitionName: "Container",
                    revisions: {
                        namePluralizer: [
                            "1 revision",
                            "{{count | NumberFormatter.humanize}} revisions"],
                        title: "Revisions"
                    },
                    secretEnvironmentVariableName: "Key",
                    secretEnvironmentVariableValue: "Value"
                }
            }));

    const theme = useTheme();
    return (
        <DataTable
            fetchItems={() => environmentVariableSecretTableItems}
            getItemId={(item: EnvironmentVariableSecretTableItem) => `${item.containerDefinitionName}${item.secretEnvironmentVariableName}${item.secretEnvironmentVariableValue}`}
            rowOptions={{
                getHighlightColor: () => theme.palette.severity(risk.severity),
                getSx: () => ({ backgroundColor: theme.palette.opacity(theme.palette.severity(risk.severity), 0.1) })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                cellSx={{ width: theme.px(350) }}
                id={KeyValueTableColumnId.SecretEnvironmentVariableName}
                itemProperty={(item: EnvironmentVariableSecretTableItem) => item.secretEnvironmentVariableName}
                key={KeyValueTableColumnId.SecretEnvironmentVariableName}
                title={localization.columns.secretEnvironmentVariableName()}/>
            <DataTableColumn
                id={KeyValueTableColumnId.SecretEnvironmentVariableValue}
                key={KeyValueTableColumnId.SecretEnvironmentVariableValue}
                render={
                    optionalTableCell<EnvironmentVariableSecretTableItem>(
                        item =>
                            _.isEmpty(item.secretEnvironmentVariableValue)
                                ? undefined
                                : <PasswordText password={item.secretEnvironmentVariableValue!}/>)}
                title={localization.columns.secretEnvironmentVariableValue()}/>
            <DataTableColumn
                id={KeyValueTableColumnId.ContainerDefinitionName}
                itemProperty={(item: EnvironmentVariableSecretTableItem) => item.containerDefinitionName}
                key={KeyValueTableColumnId.ContainerDefinitionName}
                title={localization.columns.containerDefinitionName()}/>
            <DataTableColumn
                id={KeyValueTableColumnId.RevisionIds}
                key={KeyValueTableColumnId.RevisionIds}
                render={
                    ({ item }: DataTableColumnRenderProps<EnvironmentVariableSecretTableItem>) =>
                        <InlineItems
                            items={item.revisionIds}
                            namePluralizer={localization.columns.revisions.namePluralizer}
                            variant="itemCountAndType"/>}
                title={localization.columns.revisions.title()}/>
            <DataTableColumn
                id={KeyValueTableColumnId.Actions}
                key={KeyValueTableColumnId.Actions}
                render={
                    ({ item }: DataTableColumnRenderProps<EnvironmentVariableSecretTableItem>) =>
                        <SecretExclusionActionCell
                            displayNameToValueSecretDataMap={{
                                [localization.columns.secretEnvironmentVariableValue()]: {
                                    secret: !_.isEmpty(item.secretEnvironmentVariableValue),
                                    value: item.secretEnvironmentVariableValue
                                }
                            }}
                            risk={risk}
                            secretKey={item.secretEnvironmentVariableName}/>}/>
        </DataTable>);
}

export class EnvironmentVariableSecretTableItem {
    constructor(
        public containerDefinitionName: string,
        public revisionIds: number[],
        public secretEnvironmentVariableName: string,
        public secretEnvironmentVariableValue?: string) {
    }
}

enum KeyValueTableColumnId {
    Actions = "actions",
    ContainerDefinitionName = "containerDefinitionName",
    RevisionIds = "revisionIds",
    SecretEnvironmentVariableName = "secretEnvironmentVariableName",
    SecretEnvironmentVariableValue = "secretEnvironmentVariableValue"
}