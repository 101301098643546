import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpNotebooksInstanceStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonScopeResourceDefinition } from "./useGcpCommonScopeResourceDefinition";

export function useGcpNotebooksInstanceDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const notebooksInstanceStatusTranslator = useGcpNotebooksInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpNotebooksInstanceDefinition",
            () => ({
                columns: {
                    computeInstanceIdReference: "VM Instance",
                    rootAccess: {
                        false: "Disabled",
                        title: "Root Access",
                        true: "Enabled"
                    },
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonScopeResourceDefinition.columns.tenantColumn,
                commonScopeResourceDefinition.columns.creationTimeColumn,
                commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
                commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GcpNotebooksInstanceModel) => ({
                                [localization.columns.status()]: notebooksInstanceStatusTranslator((item.entity as Contract.GcpNotebooksInstance).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.GcpNotebooksInstanceStatus}
                                    enumTypeTranslator={notebooksInstanceStatusTranslator}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.GcpNotebooksInstanceStatus}
                    itemProperty={(item: Contract.GcpNotebooksInstanceModel) => notebooksInstanceStatusTranslator((item.entity as Contract.GcpNotebooksInstance).status)}
                    key={Contract.EntityModelProperty.GcpNotebooksInstanceStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GcpNotebooksInstanceModel) => ({
                                [localization.columns.rootAccess.title()]:
                                    (item.entity as Contract.GcpNotebooksInstance).rootAccess
                                        ? localization.columns.rootAccess.true()
                                        : localization.columns.rootAccess.false()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.rootAccess.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.rootAccess.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.rootAccess.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.GcpNotebooksInstanceRootAccess}
                    itemProperty={
                        (item: Contract.GcpNotebooksInstanceModel) =>
                            (item.entity as Contract.GcpNotebooksInstance).rootAccess
                                ? localization.columns.rootAccess.true()
                                : localization.columns.rootAccess.false()}
                    key={Contract.EntityModelProperty.GcpNotebooksInstanceRootAccess}
                    title={localization.columns.rootAccess.title()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.GcpNotebooksInstanceModel>(
                            Contract.TypeNames.GcpComputeInstance,
                            item => [item.computeInstanceIdReference],
                            localization.columns.computeInstanceIdReference())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.GcpNotebooksInstanceComputeInstance)}
                                    placeholder={localization.columns.computeInstanceIdReference()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.GcpNotebooksInstanceComputeInstance}
                    key={Contract.EntityModelProperty.GcpNotebooksInstanceComputeInstance}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpNotebooksInstanceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.computeInstanceIdReference}
                                entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                entityVariant="iconText"/>}
                    title={localization.columns.computeInstanceIdReference()}/>,
                commonScopeResourceDefinition.columns.accessLevelColumn,
                commonScopeResourceDefinition.columns.updateTimeColumn,
                commonScopeResourceDefinition.columns.regionColumn,
                commonScopeResourceDefinition.columns.regionLocationColumn,
                commonScopeResourceDefinition.columns.tagsColumn,
                commonScopeResourceDefinition.columns.attributesColumn,
                commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpNotebooksInstanceRequest(
                new Contract.EntityControllerGetEntityModelPageGcpNotebooksInstanceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpNotebooksInstanceComputeInstance]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpNotebooksInstanceRootAccess]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpNotebooksInstanceStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}