import { List, ListItem, Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, NetworkScopeHelper, NetworkScopeHelperProtocol } from "../../../..";
import { Item } from "./components";

type DestinationNetworkScopesProps = {
    existingDestinationNetworkScopes: Contract.DestinationNetworkScope[];
    onDestinationNetworkScopesChanged: (destinationNetworkScopes: Contract.DestinationNetworkScope[]) => void;
    onValidChanged: (destinationNetworkScopesValid: boolean) => void;
};

export function DestinationNetworkScopes({ existingDestinationNetworkScopes, onDestinationNetworkScopesChanged, onValidChanged }: DestinationNetworkScopesProps) {
    const [destinationNetworkScopeItems, setDestinationNetworkScopeItems] =
        useState(
            () =>
                _.map(
                    existingDestinationNetworkScopes,
                    existingDestinationNetworkScope => {
                        const protocolType = NetworkScopeHelper.getProtocolType(existingDestinationNetworkScope.protocolRange);
                        return new DestinationNetworkScopeItem(
                            true,
                            undefined,
                            existingDestinationNetworkScope.destinationPortRange,
                            existingDestinationNetworkScope.protocolRange,
                            NetworkScopeHelper.getProtocol(existingDestinationNetworkScope.destinationPortRange, protocolType) ?? protocolType);
                    }));

    useEffect(
        () => {
            onDestinationNetworkScopesChanged(
                _(destinationNetworkScopeItems).
                    filter(destinationNetworkScopeItem => destinationNetworkScopeItem.valid).
                    map(
                        destinationNetworkScopeItem =>
                            new Contract.DestinationNetworkScope(
                                destinationNetworkScopeItem.portRange!,
                                destinationNetworkScopeItem.protocolRange!)).
                    value());
            onValidChanged(
                !_.isEmpty(destinationNetworkScopeItems) &&
                _.every(
                    destinationNetworkScopeItems,
                    destinationNetworkScopeItem => destinationNetworkScopeItem.valid));
        },
        [destinationNetworkScopeItems]);

    return (
        <List>
            <Stack spacing={1}>
                {_(destinationNetworkScopeItems).
                    concat(new DestinationNetworkScopeItem(false)).
                    map(
                        (destinationNetworkScopeItem, destinationNetworkScopeItemIndex) =>
                            <ListItem
                                key={destinationNetworkScopeItem.id}
                                sx={{ padding: 0 }}>
                                {destinationNetworkScopeItemIndex < destinationNetworkScopeItems.length
                                    ? <Item
                                        destinationNetworkScopeItem={destinationNetworkScopeItem}
                                        onChange={
                                            updatedDestinationNetworkScopeItem => {
                                                destinationNetworkScopeItems[destinationNetworkScopeItemIndex] = updatedDestinationNetworkScopeItem;
                                                setDestinationNetworkScopeItems([...destinationNetworkScopeItems]);
                                            }}
                                        onDelete={
                                            () =>
                                                setDestinationNetworkScopeItems(
                                                    _.filter(
                                                        destinationNetworkScopeItems,
                                                        (destinationNetworkScopeItem, currentDestinationNetworkScopeItemIndex) => currentDestinationNetworkScopeItemIndex !== destinationNetworkScopeItemIndex))}/>
                                    : <Item
                                        destinationNetworkScopeItem={destinationNetworkScopeItem}
                                        onChange={destinationNetworkScopeItem => setDestinationNetworkScopeItems(_.concat(destinationNetworkScopeItems, destinationNetworkScopeItem))}/>}
                            </ListItem>).
                    value()}
            </Stack>
        </List>);
}

export class DestinationNetworkScopeItem {
    private static _idCounter = 1;
    public id: number;

    constructor(
        public valid: boolean,
        id?: number,
        public portRange?: Contract.IntRange,
        public protocolRange?: Contract.IntRange,
        public protocolType?: NetworkScopeHelperProtocol | Contract.ProtocolType) {
        this.id = id ?? DestinationNetworkScopeItem._idCounter++;
    }
}