import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskPoliciesView } from "../../../..";
import { useKubernetesAdmissionControllerBuiltInRiskPolicyConfigurationControllerDefinition } from "./hooks";

export function useKubernetesAdmissionControllerRiskPolicyConfigurationControllerDefinition(view: RiskPoliciesView, riskPolicyConfigurationTypeNameOrId: string, scopeId: string) {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case RiskPoliciesView.BuiltIn:
                        return useKubernetesAdmissionControllerBuiltInRiskPolicyConfigurationControllerDefinition;
                    default:
                        throw new UnexpectedError("useKubernetesAdmissionControllerRiskPolicyConfigurationControllerDefinition.view", view);
                }
            },
            []);

    return useDefinition(riskPolicyConfigurationTypeNameOrId, scopeId);
}