﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useCodeTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.code.hooks.useCodeTypeTranslator",
            () => ({
                [Contract.TypeNames.CodeType]: {
                    [Contract.CodeType.CloudFormation]: "CloudFormation",
                    [Contract.CodeType.Kubernetes]: "Kubernetes",
                    [Contract.CodeType.Terraform]: "Terraform Code",
                    [Contract.CodeType.TerraformPlan]: "Terraform Plan"
                }
            }));
    return (codeType: Contract.CodeType) =>
        localization[Contract.TypeNames.CodeType][codeType]();
}