import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToHorizontalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { Action1, AnalyticsEventActionType, DataTableActionHeader, DataTableColumnHeader, DataTableColumnHeaderFilterOptions, DataTableColumnOptions, DataTableColumnProps, DataTableFilterItem, DataTableSort, DataTableSortDirection, DataTableSortType, FiltersActions, getColumnFilters, GrabIcon, Optional, useTrackAnalytics } from "@infrastructure";
import { Portal, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import _ from "lodash";
import React, { memo, MutableRefObject, useCallback, useMemo, useRef, useState } from "react";

const DataTableHeaderMemo = memo(DataTableHeader);
export { DataTableHeaderMemo as DataTableHeader };

type DataTableHeaderProps = {
    columnOptions?: DataTableColumnOptions;
    filters: DataTableHeaderFilterOptions;
    highlightedItemExists: boolean;
    itemSelection: DataTableHeaderItemSelectionOptions;
    onSelectedVisibleColumnIdsChange: Action1<string[]>;
    orderedColumnIds: string[];
    selectedVisibleColumnIds: string[];
    setOrderedColumnIds: React.Dispatch<React.SetStateAction<string[]>>;
    sortOptions: DataTableHeaderSortOptions;
    tableHeight: number;
    visibleColumnElementsMap: Record<string, React.FunctionComponentElement<DataTableColumnProps>>;
    visibleOrderedColumnElements: React.FunctionComponentElement<DataTableColumnProps>[];
};

type DataTableHeaderFilterOptions = {
    actionsRef: MutableRefObject<Optional<FiltersActions>>;
    active: string[];
    enabled: string[];
    visible: string[];
};

type DataTableHeaderItemSelectionOptions = {
    enabled: boolean;
    selectAllEnabled: boolean;
};

type DataTableHeaderSortOptions = {
    active?: DataTableHeaderSortOptionsActiveOptions;
    enabled: boolean;
    onClick: (dataTableSort: DataTableSort) => void;
};

type DataTableHeaderSortOptionsActiveOptions = {
    columnId: string;
    direction: DataTableSortDirection;
};

function DataTableHeader({ columnOptions, filters, highlightedItemExists, itemSelection, onSelectedVisibleColumnIdsChange, orderedColumnIds, selectedVisibleColumnIds, setOrderedColumnIds, sortOptions, tableHeight, visibleColumnElementsMap, visibleOrderedColumnElements }: DataTableHeaderProps) {
    const trackAnalytics = useTrackAnalytics();
    const onFilterClick =
        useCallback(
            (columnTitle: string) =>
                (filterItem: DataTableFilterItem) => {
                    filters.actionsRef.current?.add(filterItem.id);
                    trackAnalytics(
                        AnalyticsEventActionType.FilterValueOpen,
                        {
                            "Column Name": columnTitle,
                            "Filter Default": !!filterItem.default,
                            "Filter Name": filterItem.name ?? columnTitle
                        });
                },
            [filters]);

    const createFilterOptions =
        useCallback(
            (columnProps: DataTableColumnProps): DataTableColumnHeaderFilterOptions => {
                if (!columnProps.filterOptions) {
                    return { enabled: false };
                } else if (columnProps.filterOptions?.externalId) {
                    const { externalId } = columnProps.filterOptions;

                    return {
                        enabled: _.includes(filters.enabled, externalId),
                        items: [{
                            active: _.includes(filters.active, externalId),
                            id: externalId,
                            visible: _.includes(filters.visible, externalId)
                        }],
                        onClick: onFilterClick(columnProps.title!)
                    };
                }

                const columnFilters =
                    _.map(
                        getColumnFilters(columnProps),
                        (filterOptionItem): DataTableFilterItem => {
                            const filterId = filterOptionItem.id ?? columnProps.id;

                            return {
                                active: _.includes(filters.active, filterId),
                                id: filterId,
                                name: filterOptionItem.title,
                                visible: _.includes(filters.visible, filterId)
                            };
                        });

                const enabled = _.some(columnFilters, ({ id }) => _.includes(filters.enabled, id));

                return {
                    enabled,
                    items: columnFilters,
                    onClick: onFilterClick(columnProps.title!)
                };
            },
            [filters, onFilterClick]);

    const visibleColumnIdToVisibilityMap =
        useMemo(
            () =>
                _.reduce(
                    visibleOrderedColumnElements,
                    (agg, visibleColumnElement) => {
                        const visible =
                            visibleColumnElement.props.selectorOptions?.disabled ||
                            _.includes(
                                selectedVisibleColumnIds,
                                visibleColumnElement.props.id);
                        agg[visibleColumnElement.props.id] = visible;

                        return agg;
                    },
                    {} as Record<string, boolean>),
            [selectedVisibleColumnIds, visibleOrderedColumnElements]);

    const columnHeaderElements =
        useMemo(
            () =>
                _(visibleOrderedColumnElements).
                    filter(visibleOrderedColumnElement => !!visibleOrderedColumnElement.props.selectorOptions?.disabled || _.includes(selectedVisibleColumnIds, visibleOrderedColumnElement.props.id)).
                    map(
                        visibleColumnElement =>
                            <DataTableColumnHeader
                                columnProps={visibleColumnElement.props}
                                filterOptions={createFilterOptions(visibleColumnElement.props)}
                                itemSelectionEnabled={itemSelection.enabled}
                                key={visibleColumnElement.props.id}
                                sortOptions={{
                                    direction:
                                        !_.isNil(sortOptions.active) && sortOptions.active?.columnId === visibleColumnElement.props.id
                                            ? sortOptions.active.direction
                                            : undefined,
                                    enabled: sortOptions.enabled,
                                    onClick:
                                        (direction: DataTableSortDirection) =>
                                            sortOptions.onClick({
                                                columnId: visibleColumnElement.props.id,
                                                direction
                                            }),
                                    type: visibleColumnElement.props.sortOptions?.type ?? DataTableSortType.Alphabetic
                                }}
                                sticky={columnOptions?.stickyColumnId === visibleColumnElement.props.id}
                                tableOptions={{
                                    height: tableHeight,
                                    orderable: columnOptions?.orderOptions?.enabled ?? false,
                                    resizable: columnOptions?.resizable ?? false,
                                    selectable: columnOptions?.selectorOptions?.enabled || columnOptions?.selectorOptions?.enabledExternal,
                                    selection: itemSelection.enabled
                                }}
                                visible={visibleColumnIdToVisibilityMap[visibleColumnElement.props.id]}
                                onRemoveVisibleColumn={(id: string) => onSelectedVisibleColumnIdsChange(_.without(selectedVisibleColumnIds, id))}/>).
                    value(),
            [createFilterOptions, visibleOrderedColumnElements, itemSelection.enabled, sortOptions, selectedVisibleColumnIds, tableHeight]);

    const visibleOrderedColumnIds =
        useMemo(
            () =>
                _.filter(
                    orderedColumnIds,
                    columnId =>
                        visibleColumnElementsMap[columnId]?.props.orderable !== false &&
                        visibleColumnIdToVisibilityMap[columnId] &&
                        columnId !== columnOptions?.stickyColumnId),
            [orderedColumnIds, visibleColumnIdToVisibilityMap]);

    const [draggedColumnId, setDraggedColumnId] = useState<string>();

    const dndSensors =
        useSensors(
            useSensor(
                PointerSensor,
                {
                    activationConstraint: { distance: 10 }
                }));
    const onDragEnd =
        useCallback(
            ({ active, over }: DragEndEvent) => {
                window.document.body.classList.remove("col-drag");
                setDraggedColumnId(undefined);
                if (_.isNil(over)) {
                    return;
                }

                const overColumnElement =
                    over?.id
                        ? visibleColumnElementsMap[over?.id]
                        : undefined;

                if (active.id !== over?.id &&
                    overColumnElement?.props.orderable !== false &&
                    columnOptions?.stickyColumnId !== overColumnElement?.props.id &&
                    over?.data.current?.sortable.index >= 0) {
                    setOrderedColumnIds(
                        orderedColumnIds => {
                            const oldIndex = orderedColumnIds.indexOf(active.id as string);
                            const newIndex = orderedColumnIds.indexOf(over.id as string);
                            const nextOrderedColumnIds = arrayMove(orderedColumnIds, oldIndex, newIndex);

                            if (columnOptions?.orderOptions?.enabled) {
                                columnOptions?.orderOptions?.persistenceStorageItem?.setValue(JSON.stringify(nextOrderedColumnIds));
                            }

                            return nextOrderedColumnIds;
                        });
                }
            },
            [visibleColumnElementsMap]);
    const onDragStart =
        useCallback(
            ({ active }: DragStartEvent) => {
                window.document.body.classList.add("col-drag");
                setDraggedColumnId(active.id as string);
            },
            []);

    const tableRowRef = useRef<HTMLTableCellElement>(null);
    const theme = useTheme();
    return (
        <TableHead
            sx={{
                backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 97.5%, ${theme.palette.borders.primary} 97.5%, ${theme.palette.borders.primary} 100%)`,
                position: "sticky",
                top: 0,
                zIndex: 2
            }}>
            <DndContext
                accessibility={{ container: tableRowRef.current! }}
                autoScroll={{
                    threshold: {
                        x: 0.1,
                        y: 0
                    }
                }}
                collisionDetection={closestCenter}
                sensors={dndSensors}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}>
                <SortableContext
                    items={visibleOrderedColumnIds}
                    strategy={horizontalListSortingStrategy}>
                    <TableRow
                        sx={{
                            ":hover": {
                                backgroundColor: "transparent !important"
                            }
                        }}>
                        <DataTableActionHeader
                            highlightedItemExists={highlightedItemExists}
                            itemSelectionEnabled={itemSelection.selectAllEnabled}
                            sticky={!_.isNil(columnOptions?.stickyColumnId) || itemSelection.enabled}/>
                        {columnHeaderElements}
                        <TableCell sx={{ padding: 0 }}/>
                    </TableRow>
                </SortableContext>
                <TableRow sx={visuallyHidden}>
                    <TableCell ref={tableRowRef}/>
                </TableRow>
                <Portal>
                    <DragOverlay
                        modifiers={[
                            restrictToHorizontalAxis,
                            restrictToParentElement
                        ]}
                        zIndex={theme.zIndex.tooltip}>
                        {!_.isNil(draggedColumnId)
                            ? <Stack
                                alignItems="center"
                                direction="row"
                                justifyContent="space-between"
                                sx={{
                                    background: theme.palette.action.hover,
                                    borderRadius: theme.spacing(0.75),
                                    cursor: "grabbing",
                                    height: "39px",
                                    padding: theme.spacing(0, 2),
                                    width: "100%"
                                }}>
                                <Typography
                                    noWrap={true}
                                    sx={{ fontWeight: 400 }}>
                                    {visibleColumnElementsMap[draggedColumnId]?.props.title}
                                </Typography>
                                <GrabIcon
                                    sx={{
                                        color: theme.palette.borders.hoverFocus,
                                        fontSize: "16px"
                                    }}/>
                            </Stack>
                            : undefined}
                    </DragOverlay>
                </Portal>
            </DndContext>
        </TableHead>);
}