import moment from "moment";

export class TimeSpanHelper {
    public static fromDays(days: number) {
        return moment.
            duration(days, "d").
            toISOString();
    }

    public static fromHours(hours: number) {
        return moment.
            duration(hours, "h").
            toISOString();
    }

    public static getDays(timeSpan: string) {
        return moment.
            duration(timeSpan).
            asDays();
    }

    public static getHours(timeSpan: string) {
        return moment.
            duration(timeSpan).
            asHours();
    }
}