import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";

export function useKubernetesClusterResourceEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterResourceEncryptionDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.useKubernetesClusterResourceEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} encryption providers are not properly configured for secure data handling",
                resolutionSection: {
                    step1: "Modify the **APIServer** object",
                    step2: "Set the **spec.encryption.type** field to **aesgcm** or **aescbc** (**aesgcm** for AES-GCM encryption or **aescbc** for AES-CBC encryption)",
                    step3: "Save the changes",
                    step4: {
                        link: "Encrypting etcd data",
                        text: "For more information, see {{openShiftEtcdDataEncryptionDocumentationLink}}"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3(),
            localization.resolutionSection.step4.text({
                openShiftEtcdDataEncryptionDocumentationLink:
                    <Link
                        urlOrGetUrl={(riskModel as Contract.KubernetesClusterResourceEncryptionDisabledRiskModel).openShiftEtcdDataEncryptionDocumentationUrl}
                        variant="external">
                        {localization.resolutionSection.step4.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}