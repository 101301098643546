import { useLocalization, useSelectionActionsContext } from "@infrastructure";
import React, { Fragment, useState } from "react";
import { InsertRiskNoteDialog } from "../..";
import { CommentIcon, SelectionActionButton } from "../../../../../../../common";

type InsertRiskNoteSelectionActionProps = {
    clearSelection: () => void;
};

export function InsertRiskNoteSelectionAction({ clearSelection }: InsertRiskNoteSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds } = useSelectionActionsContext();
    const [open, setOpen] = useState(false);

    const localization =
        useLocalization(
            "views.customer.risks.items.insertRiskNoteSelectionAction",
            () => ({
                title: "Comment"
            }));

    return (
        <Fragment>
            {open &&
                <InsertRiskNoteDialog
                    riskIds={itemIds}
                    onClose={
                        canceled => {
                            if (!canceled) {
                                clearSelection();
                            }

                            actionEnded();
                            setOpen(false);
                        }}/>}
            <SelectionActionButton
                startIcon={
                    <CommentIcon
                        sx={{
                            opacity:
                                actionExecuting
                                    ? 0.4
                                    : 1
                        }}/>}
                onClick={
                    async () => {
                        setOpen(true);
                        actionStarted(true);
                    }}>
                {localization.title()}
            </SelectionActionButton>
        </Fragment>);
}