﻿import { InlineSecrets, Link, TextViewer, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstancesRiskContext, useGetGcpComputeInstanceTemplateRiskContext } from "../../../contexts";
import { useGetGcpComputeInstanceStartupScriptSecretExistsRiskContext } from "./hooks";

export function useGcpComputeInstanceStartupScriptSecretExistsRiskInstanceTemplateDefinition(riskModel: Contract.GcpComputeInstanceStartupScriptSecretExistsRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceStartupScriptSecretExistsRisk;
    const instanceTemplateModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeInstanceTemplateModel;

    const getGcpComputeInstancesRiskContext = useGetGcpComputeInstancesRiskContext();
    const getGcpComputeInstanceStartupScriptSecretExistsRiskContext = useGetGcpComputeInstanceStartupScriptSecretExistsRiskContext();
    const getGcpComputeInstanceTemplateRiskContext = useGetGcpComputeInstanceTemplateRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceStartupScriptSecretExistsRiskDefinition.hooks.useGcpComputeInstanceStartupScriptSecretExistsRiskInstanceTemplateDefinition",
            () => ({
                description: {
                    withInstances: "{{instances}} exposing {{secrets}} via a startup script defined on the {{instanceTemplate}}",
                    withoutInstances: "{{secrets}} exposed via a startup script defined on the {{instanceTemplate}}"
                },
                sections: {
                    resolution: {
                        step1: "Click the **CREATE SIMILAR** button",
                        step2: {
                            link: "Secrets Manager",
                            text: "Migrate your secrets to {{secretManagerDocumentationLink}}"
                        },
                        step3: "Edit the metadata entry **{{scriptName}}** to remove the exposed secrets and point to the newly created Secret Manager secret instead",
                        step4: "Click on **Create**",
                        step5: "For each instance group and cluster using the instance template, update the configuration to use the newly created instance template",
                        step6: "Delete the old unsecured instance template. Note that you cannot delete an instance template if any managed instance group still references it"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(riskModel.risk.aggregatedEntityIds)
            ? localization.description.withoutInstances
            : localization.description.withInstances)({
            instances:
                <InlineEntities
                    entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                    variant="itemCountAndType"/>,
            instanceTemplate:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    variant="typeText"/>,
            secrets:
                <InlineSecrets
                    secrets={riskModel.risk.secrets}/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                secretManagerDocumentationLink:
                    <Link
                        urlOrGetUrl={riskModel.secretManagerDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            ...(
                _.map(
                    riskModel.risk.startupScriptNameToSecretExistsTextBlockMap,
                    (_textBlock, startupScriptName) => localization.sections.resolution.step3({ scriptName: startupScriptName }))
            ),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const computeInstanceStartupScriptSecretExistsRiskContextItems = getGcpComputeInstanceStartupScriptSecretExistsRiskContext(risk);
            const instancesContextItems = getGcpComputeInstancesRiskContext(risk.aggregatedEntityIds);
            const instanceTemplateContextItems = getGcpComputeInstanceTemplateRiskContext(instanceTemplateModel);
            return [
                instanceTemplateContextItems.generalInformation,
                instanceTemplateContextItems.sensitive,
                instancesContextItems.sensitive,
                instancesContextItems.runningInstances,
                instanceTemplateContextItems.instanceGroups,
                instanceTemplateContextItems.vpcs,
                computeInstanceStartupScriptSecretExistsRiskContextItems.secrets,
                computeInstanceStartupScriptSecretExistsRiskContextItems.excludedSecrets,
                computeInstanceStartupScriptSecretExistsRiskContextItems.permissionActionPrincipals,
                instanceTemplateContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                ...(_.map(
                    riskModel.risk.startupScriptNameToSecretExistsTextBlockMap,
                    (textBlock, startupScriptName) =>
                        new RiskDefinitionSection(
                            <TextViewer
                                format="json"
                                highlightedLines={textBlock.highlightedLines}
                                startLine={textBlock.startLine}
                                text={textBlock.text}/>,
                            startupScriptName)))
            ]
        });
}