import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetOciObjectStorageBucketRiskContext } from "../contexts";

export function useOciObjectStorageBucketPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciObjectStorageBucketPublicAccessExistsRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.OciObjectStorageBucketModel;
    const bucket = bucketModel.entity as Contract.OciObjectStorageBucket;

    const getOciObjectStorageBucketRiskContext = useGetOciObjectStorageBucketRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciObjectStorageBucketPublicAccessExistsRiskDefinition",
            () => ({
                description: {
                    [Contract.TypeNames.OciObjectStorageBucketObjectAccessLevel]: {
                        [Contract.OciObjectStorageBucketObjectAccessLevel.Private]: "Private",
                        [Contract.OciObjectStorageBucketObjectAccessLevel.PublicGet]: "Object",
                        [Contract.OciObjectStorageBucketObjectAccessLevel.PublicRead]: "Bucket"
                    },
                    text: "{{bucket}} has {{translatedAccessLevel}} public access level"
                },
                sections: {
                    resolution: {
                        step1: "Click **Edit Visibility**",
                        step2: "Under **Visibility**, select **Private**",
                        step3: "Click **Save Changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            translatedAccessLevel: localization.description[Contract.TypeNames.OciObjectStorageBucketObjectAccessLevel][bucket.objectAccessLevel]()
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.ObjectStorage,
                bucketModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const bucketRiskContext = getOciObjectStorageBucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.sensitive,
                bucketRiskContext.accessLevel,
                bucketRiskContext.encryption,
                bucketRiskContext.objectEventsEnabled,
                bucketRiskContext.objectVersioning,
                bucketRiskContext.storageSize,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}