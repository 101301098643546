import { MenuItem } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, RiskType } from "../../../../../../../../../common";
import { ActionsDefinition } from "../useDefinition";
import { useCommonItems } from "./useCommonItems";

export function useCodeDefinition(riskModel: Contract.RiskModel): ActionsDefinition {
    const { additionalElements, getItemDialog, menuItems: commonMenuItems } = useCommonItems(riskModel, RiskType.Code);
    return useMemo(
        () => ({
            additionalElements,
            getItemDialog,
            menuItems:
                _<MenuItem>([]).
                    concatIf(
                        !_.isNil(commonMenuItems.close),
                        commonMenuItems.close!).
                    concatIf(
                        !_.isNil(commonMenuItems.open),
                        commonMenuItems.open!).
                    concatIf(
                        !_.isNil(commonMenuItems.separator),
                        commonMenuItems.separator!).
                    concatIf(
                        !_.isNil(commonMenuItems.comment),
                        commonMenuItems.comment!).
                    concatIf(
                        !_.isNil(commonMenuItems.sendRisksMail),
                        commonMenuItems.sendRisksMail!).
                    concatIf(
                        !_.isNil(commonMenuItems.sendRiskWebhook),
                        commonMenuItems.sendRiskWebhook!).
                    concatIf(
                        !_.isNil(commonMenuItems.separator),
                        commonMenuItems.separator!).
                    concatIf(
                        !_.isNil(commonMenuItems.jiraCreate),
                        commonMenuItems.jiraCreate!).
                    concatIf(
                        !_.isNil(commonMenuItems.jiraUpdate),
                        commonMenuItems.jiraUpdate!).
                    concatIf(
                        !_.isNil(commonMenuItems.serviceNowCreate),
                        commonMenuItems.serviceNowCreate!).
                    concatIf(
                        !_.isNil(commonMenuItems.serviceNowUpdate),
                        commonMenuItems.serviceNowUpdate!).
                    value()
        }),
        [commonMenuItems, getItemDialog]);
}