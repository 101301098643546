import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useGcpCommonKubernetesResourceInfoItemElements } from "./useGcpCommonKubernetesResourceInfoItemElements";

export function useGcpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel: Contract.GcpResourceModel) {
    const commonResourceInfoElements = useGcpCommonKubernetesResourceInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.GcpKubernetesResource<Contract.KubernetesNamespaceResourceData>;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonKubernetesNamespaceResourceInfoItemElements",
            () => ({
                namespace: "Namespace"
            }));

    return {
        ...commonResourceInfoElements,
        namespaceInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={resource.data.namespaceId}
                entityTypeName={Contract.TypeNames.GcpKubernetesNamespace}
                key="namespaceId"
                location="all"
                title={localization.namespace()}/>
    };
}