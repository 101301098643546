import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessage, optionalTableCell, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { useSnapshotsContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineTextViewer } from "../../../../../../../../../../../common";

type PolicyTable = {
    entitySnapshot: Contract.EntitySnapshot;
    permissionBoundaryPolicySnapshotId?: string;
    policySnapshotIds: string[];
};

export function PolicyTable({ entitySnapshot, permissionBoundaryPolicySnapshotId, policySnapshotIds }: PolicyTable) {
    const { entitySnapshotMap } = useSnapshotsContext();
    const policyModels =
        entityModelStore.useGet(
            _(policySnapshotIds).
                concat(permissionBoundaryPolicySnapshotId!).
                filter().
                map(policySnapshotId => entitySnapshotMap[policySnapshotId].entityId).
                value());

    const items =
        useMemo(
            () => {
                const policyModelMap =
                    _.keyBy(
                        policyModels,
                        policyModel => policyModel.entity.id);
                const entityIdToSnapshotChangeTypeNamesMap =
                    _(entitySnapshot.changes).
                        groupBy(entitySnapshotChange => entitySnapshotChange.entityId).
                        mapValues(
                            entitySnapshotChanges =>
                                _.map(
                                    entitySnapshotChanges,
                                    entitySnapshotChange => entitySnapshotChange.typeName)).
                        value();
                const items =
                    _.map(
                        policySnapshotIds,
                        policySnapshotId => {
                            const policySnapshot = entitySnapshotMap[policySnapshotId];
                            return new PolicyTableItem(
                                false,
                                policyModelMap[policySnapshot.entityId] as Contract.AwsIamPrincipalPolicyModel,
                                policySnapshot as Contract.AwsIamPrincipalPolicySnapshot,
                                _(entityIdToSnapshotChangeTypeNamesMap[policySnapshot.entityId]).
                                    filter(
                                        entitySnapshotChangeTypeName =>
                                            entitySnapshotChangeTypeName !== Contract.TypeNames.AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange &&
                                            entitySnapshotChangeTypeName !== Contract.TypeNames.AwsIamIdentityDetachPermissionBoundaryPolicySnapshotChange &&
                                            entitySnapshotChangeTypeName !== Contract.TypeNames.AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange).
                                    first() as PolicyTableItemSnapshotChangeTypeName);
                        });
                if (!_.isNil(permissionBoundaryPolicySnapshotId)) {
                    const policySnapshot = entitySnapshotMap[permissionBoundaryPolicySnapshotId];
                    items.push(
                        new PolicyTableItem(
                            true,
                            policyModelMap[policySnapshot.entityId] as Contract.AwsIamPrincipalPolicyModel,
                            policySnapshot as Contract.AwsIamPrincipalPolicySnapshot,
                            _(entityIdToSnapshotChangeTypeNamesMap[policySnapshot.entityId]).
                                filter(
                                    entitySnapshotChangeTypeName =>
                                        entitySnapshotChangeTypeName === Contract.TypeNames.AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange ||
                                        entitySnapshotChangeTypeName === Contract.TypeNames.AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange).
                                first() as PolicyTableItemSnapshotChangeTypeName));
                }

                return items;
            },
            []);

    const localization =
        useLocalization(
            "views.customer.entities.profile.snapshots.snapshot.policyTable",
            () => ({
                columns: {
                    entitySnapshotChangeTypeName: {
                        title: "Policy Change",
                        [Contract.TypeNames.EntitySnapshotChange]: {
                            [Contract.TypeNames.AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange]: "Attached",
                            [Contract.TypeNames.AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange]: "Edited",
                            [Contract.TypeNames.AwsIamPrincipalAddInlinePolicySnapshotChange]: "Added",
                            [Contract.TypeNames.AwsIamPrincipalAttachManagedPolicySnapshotChange]: "Attached",
                            [Contract.TypeNames.AwsIamPrincipalPolicyEditSnapshotChange]: "Edited"
                        }
                    },
                    policyModel: "Policy Name",
                    policyModelManagement: {
                        permissionsBoundary: "Permissions Boundary",
                        title: "Management",
                        [Contract.TypeNames.AwsIamPrincipalPolicyModelManagement]: {
                            [Contract.AwsIamPrincipalPolicyModelManagement.AwsManaged]: "AWS Managed",
                            [Contract.AwsIamPrincipalPolicyModelManagement.Inline]: "Inline",
                            [Contract.AwsIamPrincipalPolicyModelManagement.CustomerManaged]: "Customer Managed"
                        }
                    },
                    principalPolicySnapshot: {
                        title: "JSON",
                        view: "View JSON"
                    }
                },
                empty: "No Policies",
                title: "POLICIES"
            }));

    return (
        <Stack spacing={1}>
            <Typography variant="subtitle1">
                {localization.title()}
            </Typography>
            {_.isEmpty(items)
                ? <EmptyMessage message={localization.empty()}/>
                : <DataTable
                    fetchItems={() => items}
                    getItemId={(item: PolicyTableItem) => item.policyModel.id}
                    sortOptions={{ enabled: false }}
                    variant="card">
                    <DataTableColumn
                        id={PolicyTableColumnId.PolicyModel}
                        render={
                            ({ item }: DataTableColumnRenderProps<PolicyTableItem>) =>
                                <Entity
                                    entityIdOrModel={item.policyModel}
                                    variant="iconTextTenant"/>}
                        title={localization.columns.policyModel()}/>
                    <DataTableColumn
                        id={PolicyTableColumnId.policyModelManagement}
                        itemProperty={
                            (item: PolicyTableItem) =>
                                item.permissionsBoundary
                                    ? localization.columns.policyModelManagement.permissionsBoundary()
                                    : localization.columns.policyModelManagement[Contract.TypeNames.AwsIamPrincipalPolicyModelManagement][item.policyModel.management]()}
                        title={localization.columns.policyModelManagement.title()}/>
                    <DataTableColumn
                        id={PolicyTableColumnId.EntitySnapshotChangeTypeName}
                        render={
                            optionalTableCell<PolicyTableItem>(
                                item =>
                                    _.isNil(item.entitySnapshotChangeTypeName)
                                        ? undefined
                                        : localization.columns.entitySnapshotChangeTypeName[Contract.TypeNames.EntitySnapshotChange][item.entitySnapshotChangeTypeName]())}
                        title={localization.columns.entitySnapshotChangeTypeName.title()}/>
                    <DataTableColumn
                        id={PolicyTableColumnId.PrincipalPolicySnapshot}
                        render={
                            ({ item }: DataTableColumnRenderProps<PolicyTableItem>) =>
                                <InlineTextViewer
                                    dialogTitle={localization.columns.principalPolicySnapshot.title()}
                                    text={item.principalPolicySnapshot.policyDocument.raw}
                                    title={localization.columns.principalPolicySnapshot.view()}/>}
                        title={localization.columns.principalPolicySnapshot.title()}/>
                </DataTable>}
        </Stack>);
}

class PolicyTableItem {
    constructor(
        public permissionsBoundary: boolean,
        public policyModel: Contract.AwsIamPrincipalPolicyModel,
        public principalPolicySnapshot: Contract.AwsIamPrincipalPolicySnapshot,
        public entitySnapshotChangeTypeName?: PolicyTableItemSnapshotChangeTypeName) {
    }
}

type PolicyTableItemSnapshotChangeTypeName =
    Contract.TypeNames.AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange |
    Contract.TypeNames.AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange |
    Contract.TypeNames.AwsIamPrincipalAddInlinePolicySnapshotChange |
    Contract.TypeNames.AwsIamPrincipalAttachManagedPolicySnapshotChange |
    Contract.TypeNames.AwsIamPrincipalPolicyEditSnapshotChange;

enum PolicyTableColumnId {
    EntitySnapshotChangeTypeName = "entitySnapshotChangeTypeName",
    PolicyModel = "policyModel",
    policyModelManagement = "policyModelManagement",
    PrincipalPolicySnapshot = "principalPolicySnapshot"
}