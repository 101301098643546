﻿import { useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { FormHelperText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Contract, ElasticsearchItemPageHelper, EntityController, PagedEntityMultiSelect } from "../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../../../AddOrEdit";
import { AzureRoleAssignmentEligibilityData } from "../utilities";

export function RoleDefinitions() {
    const { add, permissionEligibilityData, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const principalTenantId = permissionEligibilityData.principalTenantId;
    const roleDefinitionIds = (permissionEligibilityData as AzureRoleAssignmentEligibilityData).roleDefinitionIds;
    const roleDefinitionIdsResetRef = useRef(add);

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.hooks.useAzureDefinition.roleDefinitions",
            () => ({
                error: {
                    empty: "You must select at least one role"
                },
                title: "Permissions"
            }));

    useChangeEffect(
        () => {
            roleDefinitionIdsResetRef.current = true;
        },
        [principalTenantId]);

    const [roleDefinitionIdsValidationController, roleDefinitionIdsValidationMessage] =
        useInputValidation(
            () => {
                if (roleDefinitionIdsResetRef.current) {
                    return undefined;
                }

                if (_.isEmpty(roleDefinitionIds)) {
                    return localization.error.empty();
                }

                return undefined;
            },
            [roleDefinitionIds]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        roleDefinitions:
                            !roleDefinitionIdsResetRef.current &&
                            roleDefinitionIdsValidationController.isValid()
                    }
                }));
        },
        [roleDefinitionIds]);

    return (
        <Stack spacing={1.5}>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <PagedEntityMultiSelect
                disabled={
                    _.isNil(principalTenantId) ||
                    upsertPermissionEligibilityExecuting}
                fullWidth={true}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntitySelector(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    new Contract.EntityControllerSearchEntityModelsPermissionManagementAzureAuthorizationRoleDefinitionRequest(
                                        false,
                                        15,
                                        itemNextPageSearchCursor,
                                        undefined,
                                        searchText,
                                        principalTenantId!));
                            return entityModelPage;
                        })}
                placeholder={localization.title()}
                selectedEntityIds={roleDefinitionIds}
                onSelectedEntityIdsChanged={
                    roleDefinitionIds => {
                        roleDefinitionIdsResetRef.current = false;
                        setAddOrEditContext(
                            addOrEditContext => {
                                (addOrEditContext.permissionEligibilityData as AzureRoleAssignmentEligibilityData).roleDefinitionIds = roleDefinitionIds;
                                return { ...addOrEditContext };
                            });
                    }}/>
            {!_.isNil(roleDefinitionIdsValidationMessage) && (
                <FormHelperText error={true}>
                    {roleDefinitionIdsValidationMessage}
                </FormHelperText>)}
        </Stack>);
}