import { DataTableRowOptions, StorageItem, UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract, RiskType, RiskTypeGroups } from "../../../../../../../../common";
import { useCloudDefinition, useCodeDefinition } from "./hooks";

export function useRiskTypeDefinition(type: RiskType, view: Contract.RisksView): RiskTypeDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (type) {
                    case RiskType.Cloud:
                        return useCloudDefinition;
                    case RiskType.Code:
                        return useCodeDefinition;
                    default:
                        throw new UnexpectedError(type);
                }
            },
            [type]);

    return useDefinition(view);
}

export type RiskTypeDefinition = {
    groups: RiskTypeGroups[];
    rowOptions: DataTableRowOptions;
    selectionActionElements: (props: RiskTypeSelectionActionsProps) => ReactNode[];
    storageOptions: RiskTypeStorageOptions;
    toRequestRiskFiltersFromFilterMap: (filterMap: Dictionary<any>, status: Contract.RiskStatus, timeReference?: string, relatedEntityId?: string) => Contract.RiskControllerRiskModelFilters;
    toRiskReportRequestDefinitionFromFilters: (filtered: boolean, filters: Contract.RiskControllerRiskModelFilters, status: Contract.RiskStatus, sort?: Contract.RiskControllerRiskModelSort, timeZoneInfoId?: string) => Contract.ReportControllerRisksReportRequestDefinition;
    ungrouped: RiskTypeUngrouped;
};

type RiskTypeUngrouped = {
    columns: ReactNode[];
};

type RiskTypeStorageOptions = {
    groupBy: StorageItem;
    grouped: {
        getColumnOrderStorageItem: (groupId: string) => StorageItem;
        getColumnSelectorStorageItem: (groupId: string) => StorageItem;
    };
    ungrouped: {
        columnOrder: StorageItem;
        columnSelector: StorageItem;
    };
};

type RiskTypeSelectionActionsProps = {
    clearSelection: (ids?: string[]) => void;
    getSelectedRiskIdToScopeIdsMap: () => Dictionary<string[]>;
    reloadRiskModels: () => Promise<void>;
};