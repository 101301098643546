import { VariantProps } from "@infrastructure";
import { Box, Stack, useTheme } from "@mui/material";
import React from "react";

export function Field({ addElement, listItemElements }: VariantProps) {
    const theme = useTheme();
    return (
        <Stack sx={{ height: "100%" }}>
            <Box sx={{ backgroundColor: theme.palette.background.paper }}>
                {addElement}
            </Box>
            <Stack
                sx={{
                    "&:hover": {
                        border: theme.border.hoverFocus
                    },
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0, 0, 0.75, 0.75),
                    borderTop: "none",
                    flex: 1,
                    height: "100%",
                    overflow: "hidden auto"
                }}>
                {listItemElements}
            </Stack>
        </Stack>);
}