import React, { Fragment } from "react";
import { PackageIcon, VulnerabilitiesIcon, WorkloadAnalysisIcon } from "../../../../../../../../../../../common";
import { ProfileCategory } from "../utilities";

type CategoryViewIconProps = {
    category: ProfileCategory;
};

export function CategoryIcon({ category }: CategoryViewIconProps) {
    switch (category) {
        case ProfileCategory.Overview:
            return <WorkloadAnalysisIcon/>;
        case ProfileCategory.Packages:
            return <PackageIcon/>;
        case ProfileCategory.Vulnerabilities:
            return <VulnerabilitiesIcon/>;
        default:
            return <Fragment/>;
    }
}