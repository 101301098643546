import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { EmptyMessage, Loading, useLocalization } from "@infrastructure";
import { Contract, TypeHelper, WidgetDefinition } from "../../../../../../../..";
import { AccessDashboardContext, useDashboardContext } from "../../../../../../Dashboard";
import { VendorItem } from "./components";

export function useGetVendorsDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetVendorsDefinition",
            () => ({
                helpText: "AWS roles and Microsoft Entra ID applications identified as third-party are displayed",
                title: "3rd Parties"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <Vendors/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}

function Vendors() {
    const { summary } = useDashboardContext<AccessDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetVendorsDefinition.vendors",
            () => ({
                empty: "No 3rd Parties"
            }));

    const orderedVendorsData =
        useMemo(
            () =>
                _.orderBy(
                    summary.vendorDatas,
                    [
                        ({ permissionSeverity }) => TypeHelper.getEnumValue(Contract.TypeNames.Severity, permissionSeverity),
                        ({ permissionTenantIds }) => _.sum(permissionTenantIds),
                        ({ serviceIdentityIds }) => _.sum(serviceIdentityIds)
                    ],
                    ["desc", "asc", "asc"]),
            [summary.vendorDatas]);

    return (
        <Loading>
            {_.isEmpty(orderedVendorsData)
                ? <EmptyMessage
                    message={localization.empty()}
                    verticalCenter={true}/>
                : _.map(
                    orderedVendorsData,
                    vendorData =>
                        <VendorItem
                            key={`${vendorData.vendor}-${vendorData.serviceIdentityTypeName}`}
                            vendorData={vendorData}/>)}
        </Loading>);
}