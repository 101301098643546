import { Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlineRisks } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionContextItemImpact, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { VulnerabilitiesInlineItems } from "../../../components";
import { getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity, getWorkloadResourceVulnerabilityRiskSeverityValues, useGetWorkloadResourceRiskVulnerabilityRawIds } from "../../../utilities/utilities";
import { useGetVirtualMachinesWorkloadAnalysisRiskContext, useGetVirtualMachineWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetPackageVulnerabilityResolutionSteps } from "./useGetPackageVulnerabilityResolutionSteps";
import { useGetVirtualMachineTerminationResolutionStep } from "./useGetVirtualMachineTerminationResolutionStep";

export function useVirtualMachineVulnerabilityRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const virtualMachineVulnerabilityRisk = riskModel.risk as Contract.VirtualMachineVulnerabilityRisk;
    const { virtualMachineImageRiskRawId } = riskModel as Contract.VirtualMachineVulnerabilityRiskModel;
    const getVirtualMachineWorkloadAnalysisRiskContext = useGetVirtualMachineWorkloadAnalysisRiskContext();
    const virtualMachineImageModel = entityModelStore.useGet(virtualMachineVulnerabilityRisk.virtualMachineImageId) as Contract.IVirtualMachineImageModel | undefined;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useVirtualMachineVulnerabilityRiskDefinition",
            () => ({
                description: {
                    many: "{{riskedEntity}} with {{virtualMachines}} has {{vulnerabilities}}",
                    single: "{{riskedEntity}} has {{vulnerabilities}}"
                },
                resolution: {
                    internalVirtualMachineImage: {
                        remediate: "Remediate the finding {{virtualMachineImageFinding}} to resolve all critical vulnerabilities coming from the machine image",
                        updateEntity: "Update this {{entityTypeName}} to use the new image",
                        upgradeOnMachine: "Upgrade the following packages which were not detected on machine image but were installed directly on the machine:"
                    },
                    publicVirtualMachineImage: {
                        create: "Create or use a new image in which all critical vulnerabilities have been resolved",
                        updateVirtualMachineImageConfiguration: "Update this EC2 instance / launch template to use the new image",
                        upgradeOnMachine: "Upgrade all packages with critical vulnerabilities found directly on the instance - see below:"
                    }
                },
                sections: {
                    vulnerabilities: "Vulnerabilities"
                }
            }));

    const maxVulnerabilitySeverity = getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity(virtualMachineVulnerabilityRisk);
    const vulnerabilitySeverityToRawIdsMap = useGetWorkloadResourceRiskVulnerabilityRawIds(virtualMachineVulnerabilityRisk);

    const getPackageVulnerabilityResolutionSteps = useGetPackageVulnerabilityResolutionSteps(virtualMachineVulnerabilityRisk.windowsUpdateDocumentationUrl);
    const getVirtualMachinesWorkloadAnalysisRiskContext = useGetVirtualMachinesWorkloadAnalysisRiskContext(virtualMachineVulnerabilityRisk);
    const getVirtualMachineTerminationResolutionStep = useGetVirtualMachineTerminationResolutionStep(virtualMachineVulnerabilityRisk);

    return useCommonSectionsAndDescriptionDefinition(
        (virtualMachineVulnerabilityRisk.singleVirtualMachine
            ? localization.description.single
            : localization.description.many)({
            riskedEntity:
                <Entity
                    entityIdOrModel={virtualMachineVulnerabilityRisk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            virtualMachines:
                <InlineEntities
                    entityIdsOrModels={virtualMachineVulnerabilityRisk.virtualMachineIds}
                    entityTypeName={Contract.TypeNames.IVirtualMachine}
                    variant="itemOrItemCountAndType"/>,
            vulnerabilities: <VulnerabilitiesInlineItems
                severity={maxVulnerabilitySeverity}
                severityVulnerabilityRawIds={vulnerabilitySeverityToRawIdsMap[maxVulnerabilitySeverity]}/>
        }),
        () => {
            if (!virtualMachineVulnerabilityRisk.riskedVirtualMachineImage || !virtualMachineImageRiskRawId) {
                return getPackageVulnerabilityResolutionSteps(
                    virtualMachineVulnerabilityRisk.filePathToPackageNamesMap,
                    virtualMachineVulnerabilityRisk.filePathToVulnerabilityRawIdsMap,
                    virtualMachineVulnerabilityRisk.vulnerabilityPackageNameToResolutionVersionsMap);
            }

            const resolutionLocalization = localization.resolution;
            const virtualMachineVulnerabilities =
                _.pick(
                    virtualMachineVulnerabilityRisk.vulnerabilityPackageNameToResolutionVersionsMap,
                    _.filter(
                        _.keys(virtualMachineVulnerabilityRisk.vulnerabilityPackageNameToResolutionVersionsMap),
                        vulnerabilityPackageName =>
                            !(virtualMachineVulnerabilityRisk.virtualMachineImageVulnerabilityPackageNameToResolutionVersionsMap || {})[vulnerabilityPackageName]));
            const getPackageRawResolutionSteps =
                (title: string) =>
                    (_.some(virtualMachineVulnerabilities) &&
                        new Step(title,
                            {
                                actionsElement:
                                    <Steps variant={"letters"}>
                                        {...getPackageVulnerabilityResolutionSteps(
                                            virtualMachineVulnerabilityRisk.filePathToVulnerabilityRawIdsMap,
                                            virtualMachineVulnerabilityRisk.filePathToVulnerabilityRawIdsMap,
                                            virtualMachineVulnerabilities)}
                                    </Steps>
                            }));
            return [
                getVirtualMachineTerminationResolutionStep(),
                ...(virtualMachineImageModel?.virtualMachineImageAccessLevel === Contract.VirtualMachineImageAccessLevel.Internal
                    ? [
                        resolutionLocalization.internalVirtualMachineImage.remediate({
                            virtualMachineImageFinding:
                                <InlineRisks
                                    riskIdsOrModels={[virtualMachineImageRiskRawId]}
                                    variant={"title"}/>
                        }),
                        resolutionLocalization.internalVirtualMachineImage.updateEntity({ entityTypeName: riskModel.riskedEntityTypeName }),
                        getPackageRawResolutionSteps(resolutionLocalization.internalVirtualMachineImage.upgradeOnMachine())
                    ]
                    : [
                        resolutionLocalization.publicVirtualMachineImage.create(),
                        resolutionLocalization.publicVirtualMachineImage.updateVirtualMachineImageConfiguration(),
                        getPackageRawResolutionSteps(resolutionLocalization.publicVirtualMachineImage.updateVirtualMachineImageConfiguration())
                    ])
            ];
        },
        riskModel,
        () => {
            const virtualMachineWorkloadAnalysisRiskContext = getVirtualMachineWorkloadAnalysisRiskContext(virtualMachineVulnerabilityRisk);
            const virtualMachinesWorkloadAnalysisRiskContext = getVirtualMachinesWorkloadAnalysisRiskContext();

            return [
                virtualMachineWorkloadAnalysisRiskContext.generalInformation,
                virtualMachineWorkloadAnalysisRiskContext.virtualMachines,
                virtualMachineWorkloadAnalysisRiskContext.getVulnerabilities(),
                ...virtualMachineWorkloadAnalysisRiskContext.severityVulnerabilities,
                virtualMachinesWorkloadAnalysisRiskContext.sensitive?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.inboundExternalAccessScope?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.identityPermissionActionSeverity?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.stopped?.withImpact(RiskDefinitionContextItemImpact.SeverityDecreased),
                virtualMachineWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(virtualMachineVulnerabilityRisk.id)
            ];
        },
        {
            sections:
                [
                    new RiskDefinitionSection(
                        <Vulnerabilities
                            entityIds={virtualMachineVulnerabilityRisk.virtualMachineIds}
                            severityFilterValues={getWorkloadResourceVulnerabilityRiskSeverityValues(virtualMachineVulnerabilityRisk.severeVulnerabilityMinSeverity ?? Contract.Severity.Critical)}
                            variant="risk"/>,
                        localization.sections.vulnerabilities(),
                        {
                            expandable: true
                        })
                ]
        });
}