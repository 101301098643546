import _ from "lodash";
import React, { ReactNode, useLayoutEffect, useRef } from "react";
import { makeContextProvider } from "../../../utilities";
import { Loading } from "../../Loading";

type ToggleFilterConnectorProps = {
    children: ReactNode;
    filter: unknown;
    id: string;
    onFilterChanged: (filterId: string, filter: unknown) => void;
};

interface ToggleFilterConnectorContext {
    filter?: unknown;
}

export const [useToggleFilterConnectorContext, useSetToggleFilterConnectorContext, useToggleFilterConnectorContextProvider] = makeContextProvider<ToggleFilterConnectorContext>();

export function ToggleFilterConnector({ children, filter, id, onFilterChanged }: ToggleFilterConnectorProps) {
    const [context, setContext, ContextProvider] = useToggleFilterConnectorContextProvider(() => ({ filter }));
    const contextRef = useRef(context);

    useLayoutEffect(
        () => {
            if (!_.isEqual(contextRef.current, context)) {
                contextRef.current = context;
                onFilterChanged(id, context.filter);
            }
        },
        [onFilterChanged, id, context, contextRef.current]);

    const filterRef = useRef(filter);
    useLayoutEffect(
        () => {
            if (!_.isEqual(filterRef.current, filter)) {
                filterRef.current = filter;
                setContext({ filter });
            }
        },
        [id, filter, filterRef.current]);

    return (
        <ContextProvider>
            <Loading container="cell">
                {children}
            </Loading>
        </ContextProvider>);
}