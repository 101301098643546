import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSort, DataTableSortDirection, EmptyMessageText, map, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { PingIdentityTenantsContext, useSetPingIdentityTenantsContext } from "../..";
import { Contract, StatusCell, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { usePingIdentityClientRegionTranslator } from "../../../../../../../../../../../../../../tenants";
import { usePingIdentityTenantModelStatusTranslator } from "../../hooks";
import { ActionsCell } from "./components";

export function Table() {
    const tenantModels = tenantModelStore.useGetPermittedPingIdentityTenants();
    const setPingIdentityTenantsContext = useSetPingIdentityTenantsContext();

    const pingIdentityClientRegionTranslator = usePingIdentityClientRegionTranslator();
    const pingIdentityTenantModelStatusTranslator = usePingIdentityTenantModelStatusTranslator();

    function getTenantModels(_filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _.orderBy(
            tenantModels,
            tenantModel =>
                map<string, number | string | undefined>(
                    sort?.columnId ?? TableColumnId.Name,
                    {
                        [TableColumnId.Name]: () =>
                            tenantModel.configuration.name.
                                toLowerCase(),
                        [TableColumnId.RawId]: () =>
                            (tenantModel.configuration as Contract.PingIdentityTenantConfiguration).rawId.
                                toLowerCase(),
                        [TableColumnId.Region]: () =>
                            pingIdentityClientRegionTranslator((tenantModel.configuration as Contract.PingIdentityTenantConfiguration).region).
                                toLowerCase(),
                        [TableColumnId.Status]: () =>
                            pingIdentityTenantModelStatusTranslator(tenantModel.status).
                                toLowerCase()
                    }),
            sort?.direction === DataTableSortDirection.Descending
                ? "desc"
                : "asc");
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [tenantModels]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.pingIdentityTenants.table",
            () => ({
                actions: {
                    add: "Add Ping Identity Environment"
                },
                columns: {
                    name: "Name",
                    rawId: "Environment ID",
                    region: "Region",
                    status: "Status"
                },
                empty: "No Ping Identity environments"
            }));
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={getTenantModels}
            getItemId={(item: Contract.PingIdentityTenantModel) => item.configuration.id}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setPingIdentityTenantsContext(new PingIdentityTenantsContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.PingIdentityTenantModel) => item.configuration.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.RawId}
                itemProperty={(item: Contract.PingIdentityTenantModel) => (item.configuration as Contract.PingIdentityTenantConfiguration).rawId}
                title={localization.columns.rawId()}/>
            <DataTableColumn
                id={TableColumnId.Region}
                itemProperty={(item: Contract.PingIdentityTenantModel) => pingIdentityClientRegionTranslator((item.configuration as Contract.PingIdentityTenantConfiguration).region)}
                title={localization.columns.region()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PingIdentityTenantModel>) =>
                        <StatusCell
                            statusSeverity={item.statusSeverity}
                            title={pingIdentityTenantModelStatusTranslator(item.status)}/>}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    RawId = "rawId",
    Region = "region",
    Status = "status"
}