import React from "react";
import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useRemoveQueryParameters } from "@infrastructure";
import { CodeOrganizationQueryParameters } from "../..";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEditAzureDevOps, Table } from "./components";

export class AzureDevOpsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.CodeOrganizationModel,
        public aadTenantRawId?: string,
        public callbackError?: Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError,
        public oAuthAccessToken?: string) {
    }
}

export const [useAzureDevOpsContext, useSetAzureDevOpsContext, useAzureDevOpsContextProvider] = makeContextProvider<AzureDevOpsContext>();

export function AzureDevOps() {
    const removeQueryParameters = useRemoveQueryParameters();
    const [context, setContext, ContextProvider]
        = useAzureDevOpsContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<AzureDevOpsOrganizationQueryParameters>();
                removeQueryParameters("aadTenantRawId");
                removeQueryParameters("accessToken");
                removeQueryParameters("addOrEditOpen");
                removeQueryParameters("error");
                return new AzureDevOpsContext(
                    StringHelper.isTrue(queryParameters.addOrEditOpen) ||
                !!queryParameters.aadTenantRawId ||
                !!queryParameters.error,
                    queryParameters.aadTenantRawId,
                    queryParameters.error,
                    queryParameters.accessToken);
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    aadTenantRawId: undefined,
                                    accessToken: undefined,
                                    addOrEditOpen: false,
                                    callbackError: undefined
                                }))}>
                    <AddOrEditAzureDevOps/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type AzureDevOpsOrganizationQueryParameters = CodeOrganizationQueryParameters & {
    aadTenantRawId?: string;
    accessToken?: string;
    error?: Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError;
};