import { Action0, DeleteIcon, getItemWithId, getItemWithValidation, Message, useLocalization } from "@infrastructure";
import { FormHelperText, IconButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";
import { ProjectConfigurationRuleConditionWithValidationWithId } from "../../../../../../AddOrEdit";
import { useConditionTypeTranslator, useDefinition } from "../../hooks";
import { ConditionsHelper } from "../../utilities";
import { Operator, PatternTextField } from "./components";

type ConditionProps = {
    condition: ProjectConfigurationRuleConditionWithValidationWithId;
    errorMessage?: string;
    onConditionChanged: (condition: ProjectConfigurationRuleConditionWithValidationWithId) => void;
    onConditionClear: Action0;
};

export function Condition({ condition, errorMessage, onConditionChanged, onConditionClear }: ConditionProps) {
    const definition = useDefinition(condition);

    const [not, setNot] = useState(definition.not);
    const [value, setValue] = useState(definition.value);
    const [hover, setHover] = useState(false);

    const conditionTypeTranslator = useConditionTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.addOrEdit.projectConfigurationRules.conditions.condition",
            () => ({
                tooltip: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character."
            }));

    useEffect(
        () => {
            onConditionChanged(
                getItemWithValidation(
                    getItemWithId(
                        ConditionsHelper.createCondition(
                            definition.conditionType,
                            not,
                            value),
                        condition.id),
                    _.isString(value)
                        ? !_.isEmpty(value)
                        : !_.isEmpty((value as Contract.ResourceTagPattern)?.keyPattern) && !_.isEmpty((value as Contract.ResourceTagPattern)?.valuePattern)));
        },
        [not, value]);

    return (
        <Stack>
            <Stack
                alignItems="baseline"
                direction="row"
                spacing={1}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                {conditionTypeTranslator(
                    definition.conditionType,
                    "description",
                    {
                        keyPattern:
                            <PatternTextField
                                value={(value as Contract.ResourceTagPattern)?.keyPattern}
                                onChange={
                                    keyPattern =>
                                        setValue(
                                            value =>
                                                ({
                                                    ...(value as Contract.ResourceTagPattern)!,
                                                    keyPattern
                                                }))}/>,
                        namePattern:
                            <PatternTextField
                                value={value as string}
                                onChange={namePattern => setValue(namePattern)}/>,
                        operatorType:
                            <Operator
                                not={not}
                                onChange={setNot}/>,
                        operatorValue:
                            <Operator
                                not={not}
                                readOnly={true}/>,
                        valuePattern:
                            <PatternTextField
                                value={(value as Contract.ResourceTagPattern)?.valuePattern}
                                onChange={
                                    valuePattern =>
                                        setValue(
                                            value =>
                                                ({
                                                    ...(value as Contract.ResourceTagPattern)!,
                                                    valuePattern
                                                }))}/>
                    })}
                <Stack
                    alignSelf="center"
                    direction="row"
                    spacing={0.5}
                    sx={{
                        visibility:
                            hover
                                ? "visible"
                                : "hidden"
                    }}>
                    <Message
                        level="info"
                        sx={{ alignSelf: "center" }}
                        title={
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                {localization.tooltip()}
                            </Typography>}
                        variant="minimal"/>
                    <IconButton
                        size="small"
                        onClick={onConditionClear}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
            </Stack>
            {errorMessage &&
                <FormHelperText error={true}>
                    {errorMessage}
                </FormHelperText>}
        </Stack>);
}