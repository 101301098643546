import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpKmsKeyRingKeyRiskContext, useGetGcpScopeResourcePublicAccessExistsRiskContext } from "../../../contexts";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpKmsKeyRingKeyPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpKmsKeyRingKeyPublicAccessExistsRisk;
    const keyModel = entityModelStore.useGet(risk.entityId) as Contract.GcpKmsKeyRingKeyModel;

    const getGcpKmsKeyRingKeyRiskContext = useGetGcpKmsKeyRingKeyRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpKmsKeyRingKeyPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{key}} is publicly accessible via IAM roles",
                sections: {
                    resolution: {
                        step1: "Before changing the roles, verify that applications can access the key without public access",
                        step2: "Delete the role bindings to allUsers / allAuthenticatedUsers"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                key:
                    <Entity
                        entityIdOrModel={keyModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Permissions}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const keyRiskContext = getGcpKmsKeyRingKeyRiskContext(keyModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(risk);
            return [
                keyRiskContext.generalInformation,
                keyRiskContext.sensitive,
                keyRiskContext.keyRing,
                keyRiskContext.encryptedResources,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                keyRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ScopeResourceRoleBindingTableSection
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}