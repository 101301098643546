﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAadDirectoryApplicationObjectExpirationStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.aad.hooks.useAadDirectoryApplicationObjectExpirationStatusTranslator",
            () => ({
                [Contract.AadDirectoryApplicationObjectExpirationStatus.Expired]: "Expired",
                [Contract.AadDirectoryApplicationObjectExpirationStatus.ExpiringSoon]: "Expiring Soon",
                [Contract.AadDirectoryApplicationObjectExpirationStatus.Valid]: "Valid"
            }));

    return (expirationStatus: Contract.AadDirectoryApplicationObjectExpirationStatus) => localization[expirationStatus]();
}