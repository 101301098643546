import { Action0, CollapsedIcon, IconText, useLocalization } from "@infrastructure";
import { ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../common";
import { IntegrationItem } from "../utilities";

type BreadcrumbsProps = {
    item: IntegrationItem;
    onIntegrationsClick: Action0;
};

export function Breadcrumbs({ item, onIntegrationsClick }: BreadcrumbsProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.breadcrumbs",
            () => ({
                title: "Integrations"
            }));

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                alignItems: "center",
                padding: theme.spacing(1.5, 1.5, 0)
            }}>
            <ButtonBase onClick={onIntegrationsClick}>
                <Typography
                    sx={{ fontWeight: "400" }}
                    variant="h5">
                    {localization.title()}
                </Typography>
            </ButtonBase>
            <CollapsedIcon sx={{ fontSize: "18px" }}/>
            <IconText
                containerSx={{ paddingRight: 0 }}
                icon={item.iconElement}
                iconSx={{ fontSize: "18px" }}
                spacing={0.5}
                text={
                    <Typography
                        sx={{ fontWeight: "600" }}
                        variant="h5">
                        {item.title}
                    </Typography>}/>
        </Stack>);
}