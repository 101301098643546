﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsOrganizationsAccountOrganizationJoinMethodTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsOrganizationsAccountOrganizationJoinMethodTranslator",
            () => ({
                [Contract.TypeNames.AwsOrganizationsAccountOrganizationJoinMethod]: {
                    [Contract.AwsOrganizationsAccountOrganizationJoinMethod.Created]: "Created",
                    [Contract.AwsOrganizationsAccountOrganizationJoinMethod.Invited]: "Invited"
                }
            }));
    return (method: Contract.AwsOrganizationsAccountOrganizationJoinMethod) =>
        localization[Contract.TypeNames.AwsOrganizationsAccountOrganizationJoinMethod][method]();
}