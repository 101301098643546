import { Optional, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, SeveritySquare, useSeverityTranslator } from "../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionContextItemImpact } from "../../../utilities";

type ContextItemStepsProps = {
    getContextItems: () => Optional<RiskDefinitionContextItem>[];
    risk: Contract.Risk;
};

export function ContextItemSteps({ getContextItems, risk }: ContextItemStepsProps) {
    const contextItems = _.filter(getContextItems()) as RiskDefinitionContextItem[];

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.contextItemSteps",
            () => ({
                riskSeverity: "This finding has {{severityElement}}**{{severity}}** severity because:"
            }));

    if (_.isEmpty(contextItems)) {
        return null;
    }

    const impactToContextItemMap =
        _.groupBy(
            contextItems,
            context => context.impact);

    return _.isEmpty(contextItems)
        ? null
        : <Steps variant="bullets">
            {_.filter([
                ..._.map(
                    impactToContextItemMap[RiskDefinitionContextItemImpact.Information],
                    contextItem => contextItem.contentElement),
                _.isEmpty(impactToContextItemMap[RiskDefinitionContextItemImpact.SeverityDecreased]) &&
                _.isEmpty(impactToContextItemMap[RiskDefinitionContextItemImpact.SeverityIncreased])
                    ? undefined
                    : new Step(
                        localization.riskSeverity({
                            severity: severityTranslator(risk.severity, "text"),
                            severityElement: <SeveritySquare severity={risk.severity}/>
                        }),
                        {
                            contentElement:
                                <Steps variant="bullets">
                                    {_<RiskDefinitionContextItem>([]).concatIf(
                                        !_.isEmpty(impactToContextItemMap[RiskDefinitionContextItemImpact.SeverityDecreased]),
                                        impactToContextItemMap[RiskDefinitionContextItemImpact.SeverityDecreased]).concatIf(
                                        !_.isEmpty(impactToContextItemMap[RiskDefinitionContextItemImpact.SeverityIncreased]),
                                        impactToContextItemMap[RiskDefinitionContextItemImpact.SeverityIncreased]).map(contextItem => contextItem.contentElement).value()}
                                </Steps>
                        })
            ])}
        </Steps>;
}