import { NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../common";
import { EntitiesInfoItem } from "../../../../views/Customer/components/Entities/components/Profile/components";

type GcpTenantEntityUsageSectionProps = {
    activeOwnerReferences: string[];
    inactiveOwnerReferences: string[];
    usage: Contract.GcpTenantEntityUsage;
};

export function GcpTenantEntityUsageSection({ activeOwnerReferences, inactiveOwnerReferences, usage }: GcpTenantEntityUsageSectionProps) {
    const localization =
        useLocalization(
            "tenants.gcp.gcpTenantEntityUsageSection",
            () => ({
                items: {
                    api: "API Activity",
                    appEngine: {
                        activeInstancesDailyAverageCount: "activeAppengineInstanceDailyCount"
                    },
                    bigQuery: {
                        inflightJobDailyAverageCount: "bigqueryInflightJobDailyCount",
                        inflightQueryDailyAverageCount: "bigqueryInflightQueryDailyCount",
                        storageDailyAverageSize: "bigqueryStorageDailyBytes",
                        tableDailyAverageCount: "bigqueryTableDailyCount"
                    },
                    billing: "Billing Usage",
                    cloudSql: {
                        activeInstanceDailyAverageCount: "activeCloudSqlInstanceDailyCount"
                    },
                    common: {
                        activeOauthTokens: {
                            false: "no",
                            title: "hasActiveOauthTokens",
                            true: "yes"
                        },
                        activeOwners: "Active owners",
                        apiRequestDailyAverageCount: "consumedApiDailyCount",
                        apiUniqueClientDailyAverageCount: "apiClientDailyCount",
                        billingAccount: {
                            false: "no",
                            title: "hasBillingAccount",
                            true: "yes"
                        },
                        billingServiceSystemNames: {
                            none: "**none**",
                            some: "serviceWithBillableUsage"
                        },
                        carbonFootprintDailyKg: "carbonFootprintDailyKgCO2e",
                        dataStoreRequestDailyAverageCount: "datastoreApiDailyCount",
                        inactiveOwners: "Non-active owners"
                    },
                    compute: {
                        activeInstanceDailyAverageCount: "activeGceInstanceDailyCount",
                        vpcEgressDailyAverageBytes: "vpcEgressDailyBytes",
                        vpcIngressDailyAverageBytes: "vpcIngressDailyBytes"
                    },
                    iam: {
                        authenticatedServiceAccountDailyAverageCount: "activeServiceAccountDailyCount"
                    },
                    network: "Networking Activity",
                    other: "Other Fields",
                    serviceUsage: "Cloud Services Usage",
                    storage: {
                        dailyAverageSize: "gcsStorageDailyBytes",
                        objectDailyAverageCount: "gcsObjectDailyCount",
                        requestDailyAverageCount: "gcsRequestDailyCount"
                    },
                    userActivity: "User Activity"
                },
                title: "Utilization"
            }));

    return (
        <InfoCard>
            <InfoCard
                columns={true}
                title={localization.items.api()}>
                <InfoItem
                    title={localization.items.iam.authenticatedServiceAccountDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.iam.authenticatedServiceAccountDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.common.apiUniqueClientDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.common.apiUniqueClientDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.common.apiRequestDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.common.apiRequestDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.common.dataStoreRequestDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.common.dataStoreRequestDailyAverageCount)}/>
            </InfoCard>
            <InfoCard
                columns={true}
                minChildElementLength={1}
                title={localization.items.network()}>
                <InfoItem
                    title={localization.items.compute.vpcEgressDailyAverageBytes()}
                    value={NumberFormatter.storage(usage.compute.vpcEgressDailyAverageBytes)}/>
                <InfoItem
                    title={localization.items.compute.vpcIngressDailyAverageBytes()}
                    value={NumberFormatter.storage(usage.compute.vpcIngressDailyAverageBytes)}/>
            </InfoCard>
            <InfoCard
                columns={true}
                minChildElementLength={1}
                title={localization.items.billing()}>
                <InfoItem
                    title={localization.items.common.billingAccount.title()}
                    value={
                        usage.common.billingAccount
                            ? localization.items.common.billingAccount.true()
                            : localization.items.common.billingAccount.false()}/>
                <VerticalTopItemsInfoItem
                    items={usage.common.billingServiceSystemNames}
                    title={localization.items.common.billingServiceSystemNames.some()}/>
            </InfoCard>
            <InfoCard
                columns={true}
                minChildElementLength={1}
                title={localization.items.userActivity()}>
                <EntitiesInfoItem
                    entityIdsOrModels={activeOwnerReferences}
                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                    title={localization.items.common.activeOwners()}/>
                <EntitiesInfoItem
                    entityIdsOrModels={inactiveOwnerReferences}
                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                    title={localization.items.common.inactiveOwners()}/>
            </InfoCard>
            <InfoCard
                columns={true}
                title={localization.items.serviceUsage()}>
                <InfoItem
                    title={localization.items.appEngine.activeInstancesDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.appEngine.activeInstancesDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.cloudSql.activeInstanceDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.cloudSql.activeInstanceDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.compute.activeInstanceDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.compute.activeInstanceDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.bigQuery.inflightJobDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.bigQuery.inflightJobDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.bigQuery.inflightQueryDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.bigQuery.inflightQueryDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.bigQuery.storageDailyAverageSize()}
                    value={NumberFormatter.storage(usage.bigQuery.storageDailyAverageSize)}/>
                <InfoItem
                    title={localization.items.bigQuery.tableDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.bigQuery.tableDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.storage.objectDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.storage.objectDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.storage.requestDailyAverageCount()}
                    value={NumberFormatter.humanize(usage.storage.requestDailyAverageCount)}/>
                <InfoItem
                    title={localization.items.storage.dailyAverageSize()}
                    value={NumberFormatter.storage(usage.storage.dailyAverageSize)}/>
            </InfoCard>
            <InfoCard
                columns={true}
                minChildElementLength={1}
                title={localization.items.other()}>
                <InfoItem
                    title={localization.items.common.activeOauthTokens.title()}
                    value={
                        usage.common.activeOauthTokens
                            ? localization.items.common.activeOauthTokens.true()
                            : localization.items.common.activeOauthTokens.false()}/>
                <InfoItem
                    title={localization.items.common.carbonFootprintDailyKg()}
                    value={
                        _.isNil(usage.common.carbonFootprintDailyKg)
                            ? undefined
                            : NumberFormatter.humanize(usage.common.carbonFootprintDailyKg)}/>
            </InfoCard>
        </InfoCard>);
}