import _ from "lodash";
import React, { useMemo } from "react";
import { RiskPoliciesType, RiskPolicyItem, RiskPolicyTypeMetadataHelper, tenantModelStore } from "../../../../../../../../../../../common";
import { useConfigurationDefinition, useRiskPoliciesItemConfiguration } from "../../../../../../../hooks";
import { RiskPolicyHelper } from "../../../../../../../utilities";
import { EntityCreationTimeAnalysisDelayTimeFrame, EntityExclusion } from "../../../../../components";
import { ConfigurationCategory, useConfigurationContext } from "../../../../../Configuration";
import { useCommonRiskPolicyDefinition } from "../../useCommonRiskPolicyDefinition";

export function useCloudCommonRiskPolicyDefinition(item: RiskPolicyItem, scopeId: string) {
    const { parentRiskPolicyModels, riskPolicyConfiguration: initialRiskPolicyConfiguration, scopeRiskPolicyModel, tenantRiskPolicyModels } = useRiskPoliciesItemConfiguration(RiskPoliciesType.Cloud, item, scopeId);
    const additionalTabs = useCommonRiskPolicyDefinition(initialRiskPolicyConfiguration);
    const configurationDefinition = useConfigurationDefinition(initialRiskPolicyConfiguration, RiskPoliciesType.Cloud);
    const { riskPolicyConfiguration } = useConfigurationContext();
    const tenantModels = tenantModelStore.useGetAll();
    const isEntityExclusionsExists =
        useMemo(
            () => RiskPolicyHelper.isEntityExclusionsExists(item, tenantModels),
            [item, tenantModels]);

    return useMemo(
        () => ({
            additionalTabs: {
                ...(isEntityExclusionsExists
                    ? {
                        [ConfigurationCategory.EntityExclusions]: {
                            component:
                                () =>
                                    <EntityExclusion
                                        key={ConfigurationCategory.EntityExclusions}
                                        parentRiskPolicyModels={parentRiskPolicyModels}
                                        scopeRiskPolicyModel={scopeRiskPolicyModel}
                                        tenantRiskPolicyModels={tenantRiskPolicyModels}/>
                        }
                    }
                    : {}),
                ...additionalTabs
            },
            generalAdditionalItems:
                _(configurationDefinition.generalAdditionalItems).
                    map(generalAdditionalItem => generalAdditionalItem.configurationElement).
                    concatIf(
                        RiskPolicyTypeMetadataHelper.get(riskPolicyConfiguration.typeName).riskPolicyEntityCreationTimeAnalysisDelayTimeFrameChangeable,
                        <EntityCreationTimeAnalysisDelayTimeFrame riskPoliciesType={RiskPoliciesType.Cloud}/>).
                    value()
        }),
        [additionalTabs, configurationDefinition.generalAdditionalItems, isEntityExclusionsExists, parentRiskPolicyModels, riskPolicyConfiguration.typeName, scopeRiskPolicyModel, tenantRiskPolicyModels]);
}