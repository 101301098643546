﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function QatarIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5284)">
                <rect
                    fill="white"
                    height="16"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M0 4.00012H24V20.0001H0V4.00012Z"
                    fill="white"/>
                <path
                    clipRule="evenodd"
                    d="M22 5.00227H2C1.44772 5.00227 1 5.451 1 6.00454V18.0317C1 18.5853 1.44772 19.034 2 19.034H22C22.5523 19.034 23 18.5853 23 18.0317V6.00454C23 5.451 22.5523 5.00227 22 5.00227ZM2 4C0.895431 4 0 4.89746 0 6.00454V18.0317C0 19.1388 0.89543 20.0363 2 20.0363H22C23.1046 20.0363 24 19.1388 24 18.0317V6.00454C24 4.89746 23.1046 4 22 4H2Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    d="M22 20.0002C23.1046 20.0002 24 19.1048 24 18.0002L24 6.00024C24 4.89567 23.1046 4.00024 22 4.00024L9.66749 4.00024L5.99999 4.89024L9.66749 5.77691L6.00374 6.66691L9.66749 7.55691L6.00374 8.44358L9.66749 9.33358L5.99999 10.2236L9.66749 11.1102L6.00374 12.0002L9.66749 12.8902L6.00374 13.7769L9.66749 14.6669L5.99999 15.5569L9.66749 16.4436L6.00374 17.3336L9.66749 18.2236L5.99999 19.1102L9.67124 20.0002L22 20.0002Z"
                    fill="#8D1B3D"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5284">
                    <rect
                        fill="white"
                        height="16"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}