import { Optional, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../common";

export function useGcpComputeInstanceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeInstanceStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeInstanceStatus]: {
                    [Contract.GcpComputeInstanceStatus.Provisioning]: "Provisioning",
                    [Contract.GcpComputeInstanceStatus.Repairing]: "Repairing",
                    [Contract.GcpComputeInstanceStatus.Running]: "Running",
                    [Contract.GcpComputeInstanceStatus.Staging]: "Staging",
                    [Contract.GcpComputeInstanceStatus.Stopping]: "Stopping",
                    [Contract.GcpComputeInstanceStatus.Suspended]: "Suspended",
                    [Contract.GcpComputeInstanceStatus.Suspending]: "Suspending",
                    [Contract.GcpComputeInstanceStatus.Terminated]: "Stopped"
                },
                terminatedWithTime: "Stopped (Since {{stopTime}})"
            }));
    return (status: Contract.GcpComputeInstanceStatus, stopTime: Optional<string> = undefined) =>
        status === Contract.GcpComputeInstanceStatus.Terminated &&
        !_.isNil(stopTime)
            ? localization.terminatedWithTime({ stopTime: TimeFormatter.monthDayAndYear(stopTime) })
            : localization[Contract.TypeNames.GcpComputeInstanceStatus][status]();
}