import { useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import React from "react";
import { UrlHelper } from "../utilities";

export function SupportButton() {
    const localization =
        useLocalization(
            "common.supportButton",
            () => ({
                text: "Contact Us"
            }));
    return (
        <Button onClick={() => UrlHelper.openSupportTab()}>
            {localization.text()}
        </Button>);
}