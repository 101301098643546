import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { AwsNetworkResourceHelper } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";

export function useAwsCommonNetworkedResourceDefinition(definitionData: DefinitionData) {
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonNetworkedResourceDefinition",
            () => ({
                columns: {
                    loadBalancers: "Load Balancers",
                    securityGroups: "Security Groups",
                    vpc: "VPC"
                }
            }));

    return {
        columns: {
            ...commonNetworkAccessResourceDefinition.columns,
            getLoadBalancersColumn: (
                getLoadBalancerIdReferences: (item: Contract.AwsResourceModel) => string[],
                property: Contract.EntityModelProperty) =>
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsResourceModel>(
                            Contract.TypeNames.AwsElbLoadBalancer,
                            item => getLoadBalancerIdReferences(item),
                            localization.columns.loadBalancers())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            property)}
                                    placeholder={localization.columns.loadBalancers()}/>
                        }
                    }}
                    id={property}
                    key={property}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={getLoadBalancerIdReferences(item)}
                                entityTypeName={Contract.TypeNames.AwsElbLoadBalancer}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: false }}
                    title={localization.columns.loadBalancers()}/>,
            getVpcsColumn:
                (property: Contract.EntityModelProperty = Contract.EntityModelProperty.AwsNetworkedResourceVpcs) =>
                    <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.AwsResourceModel>(
                                Contract.TypeNames.AwsEc2Vpc,
                                item => AwsNetworkResourceHelper.getVpcIds(item.entity as Contract.AwsResource),
                                localization.columns.vpc())}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                property)}
                                        placeholder={localization.columns.vpc()}/>
                            }
                        }}
                        id={property}
                        key={property}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AwsResourceModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={AwsNetworkResourceHelper.getVpcIds(item.entity)}
                                    entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                                    entityVariant="iconText"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.vpc()}/>,
            securityGroupsColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsResourceModel>(
                            Contract.TypeNames.AwsEc2SecurityGroup,
                            item => _.as<Contract.IAwsNetworkedResource>(item.entity).securityGroupIds,
                            localization.columns.securityGroups())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups)}
                                    placeholder={localization.columns.securityGroups()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups}
                    key={Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={_.as<Contract.IAwsNetworkedResource>(item.entity).securityGroupIds}
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: false }}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.securityGroups()}/>
        }
    };
}