import { Action0 } from "@infrastructure";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, ObjectTypeMetadataModelHelper } from "../../../../../../../../..";
import { UdmObjectPropertyFilter } from "../../../..";

type RuleProps = {
    objectTypeName: string;
    onRuleChanged: (rule: Contract.UdmQueryRule) => void;
    onRuleCleared: Action0;
    rule: Contract.UdmQueryRule;
};

export function Rule({ objectTypeName, onRuleChanged, onRuleCleared, rule }: RuleProps) {
    const property = ObjectTypeMetadataModelHelper.getProperty(rule.propertyId);
    return (
        <Fragment>
            {!_.isNil(property) &&
                <UdmObjectPropertyFilter
                    objectTypeName={objectTypeName}
                    propertyId={rule.propertyId}
                    rule={rule}
                    onClearClicked={onRuleCleared}
                    onFilterChange={
                        ({ operator, value }) => {
                            if (rule.operator !== operator || !_.isEqual(rule.values, value)) {
                                onRuleChanged({
                                    ...rule,
                                    operator,
                                    values: value
                                });
                            }
                        }}/>}
        </Fragment>);
}