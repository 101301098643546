import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useAzureCommonResourceGroupResourceInfoItemElements(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonResourceGroupResourceInfoItemElements",
            () => ({
                resourceGroupId: "Resource Group"
            }));
    return {
        resourceGroupInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={(resourceGroupResourceModel.entity as Contract.AzureResourceGroupResource).resourceGroupId}
                entityTypeName={Contract.TypeNames.AzureResourcesResourceGroup}
                key="resourceGroupId"
                location="all"
                title={localization.resourceGroupId()}/>
    };
}