﻿import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { Contract } from "../../../../..";
import { InlineDestinationNetworkScopes, InlineSourceIpAddressSelection } from "../..";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AzureResourceInboundExternalPortRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                destinationNetworkScopes:
                    <InlineDestinationNetworkScopes
                        destinationNetworkScopes={riskPolicyConfiguration?.destinationNetworkScopes}
                        sx={sx}/>,
                sourceIpAddresses:
                    <InlineSourceIpAddressSelection
                        selection={{
                            anyIpV4AddressSubnet: riskPolicyConfiguration?.anyIpV4AddressSubnet,
                            exactSubnets: riskPolicyConfiguration?.exactSubnets,
                            inboundAccessScope: riskPolicyConfiguration?.inboundAccessScope,
                            overlapSubnets: riskPolicyConfiguration?.overlapSubnets
                        }}
                        sx={sx}/>
            })}
        </Typography>);
}