import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { GcpApiKeysKeyRestrictionsClientRawIdentifiers } from "../../../../../../../../../../tenants";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpApiKeysKeyDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const keyModel = scopeResourceModel as Contract.GcpApiKeysKeyModel;
    const key = keyModel.entity as Contract.GcpApiKeysKey;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpApiKeysKeyDefinition",
            () => ({
                info: {
                    items: {
                        clientRawIdentifiers: "Application Restrictions",
                        rawId: "Key ID",
                        serviceHostNames: {
                            pluralizer: [
                                "1 API",
                                "{{count | NumberFormatter.humanize}} APIs"
                            ],
                            title: "API Restrictions"
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={key.rawId}/>,
                    <InfoItem
                        key="clientRawIdentifiers"
                        title={localization.info.items.clientRawIdentifiers()}
                        value={
                            key.restrictions.clientType === Contract.GcpApiKeysKeyRestrictionsClientType.None
                                ? undefined
                                : <GcpApiKeysKeyRestrictionsClientRawIdentifiers keyRestrictions={key.restrictions}/>}/>,
                    <InfoItem
                        key="serviceHostNames"
                        title={localization.info.items.serviceHostNames.title()}
                        value={
                            _.isEmpty(key.restrictions.serviceHostNames)
                                ? undefined
                                : <InlineItems
                                    items={key.restrictions.serviceHostNames}
                                    namePluralizer={localization.info.items.serviceHostNames.pluralizer}
                                    variant="itemCountAndType"/>}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}