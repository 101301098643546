import { Box, Chip, Grid2, InputBase, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import { Action1, KeyCodes, MailIcon, MailParser, Message, useLocalization } from "@infrastructure";

type MailsProps = {
    disabled?: boolean;
    iconVisible?: boolean;
    mails?: string[];
    onChange: Action1<string[]>;
    placeholder?: string;
    variant?: "normal" | "large";
};

export function Mails({ disabled = false, iconVisible = true, mails: initialMails, onChange, placeholder, variant = "normal" }: MailsProps) {
    const [mail, setMail] = useState("");
    const [mails, setMails] = useState(initialMails ?? []);

    const addValidMail =
        useCallback(
            () => {
                if (!_.isEmpty(mail) && validate(mail)) {
                    setMails([...mails, mail]);
                    setMail("");
                }
            },
            [mails, mail]);

    useEffect(
        () => {
            onChange(mails);
        },
        [mails]);

    const localization =
        useLocalization(
            "infrastructure.inputs.mails",
            () => ({
                fields: {
                    mail: {
                        error: {
                            exists: "Mail already exist",
                            invalidFormat: "Not a valid mail address",
                            required: "Mail cannot be empty"
                        },
                        placeholder: "Mail address"
                    }
                }
            }));

    const [mailValidationMessage, setMailValidationMessage] = useState<string>();

    function validate(mail: string) {
        const trimmedMail = mail?.trim();
        if (_.isEmpty(trimmedMail)) {
            setMailValidationMessage(localization.fields.mail.error.required());
            return false;
        }
        if (!MailParser.validate(trimmedMail)) {
            setMailValidationMessage(localization.fields.mail.error.invalidFormat());
            return false;
        }
        if (_.includes(mails, trimmedMail)) {
            setMailValidationMessage(localization.fields.mail.error.exists());
            return false;
        }

        setMailValidationMessage(undefined);
        return true;
    }

    const inputRef = useRef<HTMLInputElement>();
    const theme = useTheme();
    return (
        <Box
            sx={{
                "&:hover, &:focus": {
                    border: theme.border.hoverFocus,
                    color: theme.palette.text.primary
                },
                background:
                    disabled
                        ? theme.palette.input.disabledBackground
                        : theme.palette.background.paper,
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                color: theme.palette.text.secondary,
                padding: theme.spacing(0.5, 1.5)
            }}>
            <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                sx={{
                    minHeight:
                        variant === "large"
                            ? theme.px(44)
                            : undefined
                }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                    sx={{
                        minHeight:
                            variant === "large"
                                ? theme.px(44)
                                : theme.px(34)
                    }}>
                    {iconVisible &&
                        <MailIcon
                            sx={{
                                fontSize: "18px",
                                opacity:
                                    disabled
                                        ? 0.3
                                        : 1,
                                padding: theme.spacing(0, 0.5, 0, 0.75)
                            }}/>}
                    <Typography sx={{ fontSize: "12px" }}>
                        {placeholder ?? localization.fields.mail.placeholder()}
                    </Typography>
                </Stack>
                <Grid2
                    alignItems="center"
                    container={true}
                    direction="row"
                    spacing={1}
                    sx={{
                        flex: 1,
                        overflow: "hidden"
                    }}>
                    {_.map(
                        mails,
                        mail =>
                            <Grid2 key={mail}>
                                <Chip
                                    disabled={disabled}
                                    label={mail}
                                    sx={{
                                        border: theme.border.primary,
                                        fontSize:
                                            variant === "large"
                                                ? "11px"
                                                : undefined,
                                        fontWeight:
                                            disabled
                                                ? 400
                                                : 500
                                    }}
                                    variant="outlined"
                                    onDelete={
                                        disabled
                                            ? undefined
                                            : () => {
                                                setMails(_.without(mails, mail));
                                                inputRef.current!.focus();
                                            }}/>
                            </Grid2>)}
                    {!_.isNil(mailValidationMessage) &&
                        <Grid2>
                            <Message
                                level="error"
                                title={mailValidationMessage}
                                variant="minimal"/>
                        </Grid2>}
                    <Grid2
                        sx={{
                            flex: 1,
                            minWidth: theme.spacing(15)
                        }}>
                        <InputBase
                            disabled={disabled}
                            fullWidth={true}
                            inputRef={inputRef}
                            sx={{
                                fontSize:
                                    variant === "large"
                                        ? "13px"
                                        : undefined,
                                height: theme.px(34),
                                minWidth: theme.spacing(15)
                            }}
                            value={mail}
                            onBlur={addValidMail}
                            onChange={event => setMail(_.trim(event.target.value))}
                            onKeyDown={
                                (keyboardEvent: KeyboardEvent) => {
                                    setMailValidationMessage(undefined);

                                    switch (keyboardEvent.keyCode) {
                                        case KeyCodes.Backspace:
                                            if (_.isEmpty(mail)) {
                                                setMails(_.initial(mails));
                                                inputRef.current!.focus();
                                            }
                                            break;
                                        case KeyCodes.Enter:
                                        case KeyCodes.Tab:
                                        case KeyCodes.Semicolon:
                                        case KeyCodes.Space:
                                            addValidMail();
                                            inputRef.current!.focus();
                                            keyboardEvent.preventDefault();
                                            break;
                                    }
                                }}/>
                    </Grid2>
                </Grid2>
            </Stack>
        </Box>);
}