import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, TextValuesFilter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { Contract, EntitiesCell, Entity, EntityController, EntityFilter, entityModelStore, InlineEntities, InlinePermissionActions, TypeHelper, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";

export function useGcpPrincipalResourcePermissionRiskDefinition(riskModel: Contract.RiskModel) {
    const principalResourcePermissionRiskModel = riskModel as Contract.GcpPrincipalResourcePermissionRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.custom.useGcpPrincipalResourcePermissionRiskDefinition",
            () => ({
                violation: [
                    "{{principals}} has {{permissionActions}} on {{resources}}",
                    "{{principals}} have {{permissionActions}} on {{resources}}"
                ]
            }));
    return useCommonCustomSectionsAndDescriptionDefinition(
        localization.violation(
            principalResourcePermissionRiskModel.principalIds.length,
            {
                permissionActions:
                    <InlinePermissionActions
                        permissionActions={principalResourcePermissionRiskModel.permissionActions}
                        variant="itemOrItemCountAndType"/>,
                principals:
                    <InlineEntities
                        entityIdsOrModels={principalResourcePermissionRiskModel.principalIds}
                        entityTypeName={Contract.TypeNames.IGciPrincipal}
                        variant="itemOrItemCountAndType"/>,
                resources:
                    <InlineEntities
                        entityIdsOrModels={principalResourcePermissionRiskModel.resourceIds}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        variant="itemOrItemCountAndType"/>
            }),
        riskModel,
        "violations",
        <ViolationTable risk={principalResourcePermissionRiskModel.risk}/>);
}

type ViolationTableProps = {
    risk: Contract.GcpPrincipalResourcePermissionRisk;
};

function ViolationTable({ risk }: ViolationTableProps) {
    const principalModels =
        entityModelStore.useGet(
            _.map(
                risk.principalResourcePermissionItems,
                riskItem => riskItem.principalId));
    const [{ principalIdToModelAccessMap: serviceIdentityIdToModelAccessMap }] =
        useExecuteOperation(
            [ViolationTable, risk.id],
            async () =>
                await EntityController.getPrincipalIdToModelAccessMap(
                    new Contract.EntityControllerGetPrincipalIdToAccessMapRequest(
                        _(principalModels).
                            filter(
                                principalModel =>
                                    TypeHelper.extendOrImplement(
                                        principalModel.entity.typeName,
                                        Contract.TypeNames.IServiceIdentity)).
                            map(serviceIdentityModel => serviceIdentityModel.id).
                            value())));
    const serviceIdentityIdToOriginatorEntityIdsMap =
        useMemo(
            () =>
                _.mapValues(
                    serviceIdentityIdToModelAccessMap,
                    serviceIdentityModelAccess => _.as<Contract.ServiceIdentityModelAccess>(serviceIdentityModelAccess).originatorEntityIds),
            [serviceIdentityIdToModelAccessMap]);
    const originatorEntityModels =
        entityModelStore.useGet(
            _(serviceIdentityIdToOriginatorEntityIdsMap).
                values().
                flatMap().
                value());
    const resourceModels =
        entityModelStore.useGet(
            _.map(
                risk.principalResourcePermissionItems,
                riskItem => riskItem.resourceId));
    const roleBindingModels =
        entityModelStore.useGet(
            _.flatMap(
                risk.principalResourcePermissionItems,
                riskItem => riskItem.roleBindingIds));
    const items =
        useMemo(
            () => {
                const originatorEntityModelMap =
                    _.keyBy(
                        originatorEntityModels,
                        originatorEntityModel => originatorEntityModel.id);
                const principalModelMap =
                    _.keyBy(
                        principalModels,
                        principalModel => principalModel.id);
                const resourceModelMap =
                    _.keyBy(
                        resourceModels,
                        resourceModel => resourceModel.id);
                const roleBindingModelMap =
                    _.keyBy(
                        roleBindingModels,
                        roleBindingModel => roleBindingModel.id);

                return _.map(
                    risk.principalResourcePermissionItems,
                    riskItem => {
                        const principalModel = principalModelMap[riskItem.principalId];
                        const originatorEntityIds = serviceIdentityIdToOriginatorEntityIdsMap[principalModel.id] ?? [];
                        return new ViolationTableItem(
                            riskItem,
                            originatorEntityIds,
                            _.map(
                                originatorEntityIds,
                                originatorEntityId => originatorEntityModelMap[originatorEntityId]),
                            principalModel,
                            resourceModelMap[riskItem.resourceId],
                            _.map(
                                riskItem.roleBindingIds,
                                roleBindingId => roleBindingModelMap[roleBindingId]));
                    });
            },
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Originators]: {
                    getFilterValue: item => item.originatorEntityIds,
                    getSortValue: item => item.originatorEntityIds.length
                },
                [ViolationTableColumnId.PermissionActions]: {
                    getFilterValue: item => item.riskItem.permissionActions,
                    getSortValue: item => item.riskItem.permissionActions.length
                },
                [ViolationTableColumnId.Principal]: {
                    getFilterValue: item => item.principalModel.entity.id,
                    getSortValue: item => item.principalModel.entity.displayName
                },
                [ViolationTableColumnId.Resource]: {
                    getFilterValue: item => item.resourceModel.entity.id,
                    getSortValue: item => item.resourceModel.entity.displayName
                },
                [ViolationTableColumnId.RoleBindings]: {
                    getFilterValue: item => item.riskItem.roleBindingIds,
                    getSortValue: item => item.riskItem.roleBindingIds.length
                }
            },
            ViolationTableColumnId.Principal);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.custom.useGcpPrincipalResourcePermissionRiskDefinition.violationTable",
            () => ({
                columns: {
                    originators: "Originators",
                    permissionActions: "Permissions",
                    principal: "Principal",
                    resource: "Resource",
                    roleBinding: "Role Bindings"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.id}
            sortEnabled={true}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Principal]}
                                placeholder={localization.columns.principal()}/>
                    }
                }}
                id={ViolationTableColumnId.Principal}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.principalModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.principal()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Originators]}
                                placeholder={localization.columns.originators()}/>
                    }
                }}
                id={ViolationTableColumnId.Originators}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.originatorEntityModels}
                            entityTypeName={Contract.TypeNames.GcpResource}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.originators()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.RoleBindings]}
                                placeholder={localization.columns.roleBinding()}/>
                    }
                }}
                id={ViolationTableColumnId.RoleBindings}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleBindingModels}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roleBinding()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <TextValuesFilter
                                placeholder={localization.columns.permissionActions()}
                                values={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.PermissionActions]}/>
                    }
                }}
                id={ViolationTableColumnId.PermissionActions}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <InlinePermissionActions
                            permissionActions={item.riskItem.permissionActions}
                            variant="itemOrItemCountAndType"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.permissionActions()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Resource]}
                                placeholder={localization.columns.resource()}/>
                    }
                }}
                id={ViolationTableColumnId.Resource}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.resourceModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.resource()}/>
        </Table>);
}

enum ViolationTableColumnId {
    Originators = "originators",
    PermissionActions = "permissionActions",
    Principal = "principal",
    Resource = "resource",
    RoleBindings = "roleBindings"
}

class ViolationTableItem {
    public id: string;

    constructor(
        public riskItem: Contract.GcpPrincipalResourcePermissionRiskItem,
        public originatorEntityIds: string[],
        public originatorEntityModels: Contract.EntityModel[],
        public principalModel: Contract.EntityModel,
        public resourceModel: Contract.EntityModel,
        public roleBindingModels: Contract.EntityModel[]) {
        this.id = `${principalModel.id}-${resourceModel.id}`;
    }
}