import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NotIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19 6.99988L5.99999 18.9999L4.64343 17.5303L17.6434 5.53027L19 6.99988Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}