import { Optional, PagedValuesFilterValuePage } from "@infrastructure";
import _ from "lodash";
import { useCallback } from "react";
import { Contract } from "../../../../../common";
import { useGetWorkloadResourceScanFileFilterItemPage, useGetWorkloadResourceScanFilterItemPage, useGetWorkloadResourceScanPackageFilterItemPage } from "./useGetWorkloadResourceScanFilterItemPage";

export function useGetWorkloadResourceScanValueFilterItemPage(
    property: Contract.WorkloadControllerRequestProperty,
    workloadResourceType: Contract.WorkloadAnalysisWorkloadResourceType,
    emptyValue = true,
    transformItem?: (item: any) => string,
    vulnerabilityRawId?: string) {
    const getWorkloadResourceScanFilterItemPage =
        useGetWorkloadResourceScanFilterItemPage(
            property,
            workloadResourceType,
            vulnerabilityRawId);

    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number, data: number) => {
            const workloadResourceScanFilterItemPage = await getWorkloadResourceScanFilterItemPage(searchText, skip, limit);
            const filterItemCount = workloadResourceScanFilterItemPage.count ?? data;
            return new PagedValuesFilterValuePage(
                filterItemCount,
                workloadResourceScanFilterItemPage.emptyValue && emptyValue,
                _.isNil(transformItem)
                    ? workloadResourceScanFilterItemPage.items
                    : _.map(
                        workloadResourceScanFilterItemPage.items,
                        transformItem),
                () => filterItemCount);

        },
        [getWorkloadResourceScanFilterItemPage, emptyValue]);
}

export function useGetWorkloadResourceScanPackageValueFilterItemPage(
    property: Contract.WorkloadControllerRequestProperty,
    entityIds?: Optional<string[]>,
    workloadResourceType?: Optional<Contract.WorkloadAnalysisWorkloadResourceType>,
    resolvedVulnerabilities = false,
    vulnerableOnly = false,
    emptyValue = true,
    vulnerabilityRawId?: string) {
    const getWorkloadPackageScanFilterItemPage =
        useGetWorkloadResourceScanPackageFilterItemPage(
            property,
            entityIds,
            workloadResourceType,
            resolvedVulnerabilities,
            vulnerableOnly,
            vulnerabilityRawId);

    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number, data: number) => {
            const workloadPackageScanFilterItemPage = await getWorkloadPackageScanFilterItemPage(searchText, skip, limit);
            const filterItemCount = workloadPackageScanFilterItemPage.count ?? data;
            return new PagedValuesFilterValuePage(
                filterItemCount,
                workloadPackageScanFilterItemPage.emptyValue && emptyValue,
                workloadPackageScanFilterItemPage.items,
                () => filterItemCount);

        },
        [getWorkloadPackageScanFilterItemPage, emptyValue]);
}

export function useGetWorkloadResourceScanFileValueFilterItemPage(
    property: Contract.WorkloadControllerRequestProperty,
    entityIds?: Optional<string[]>,
    emptyValue = true) {
    const getWorkloadFileScanFilterItemPage =
        useGetWorkloadResourceScanFileFilterItemPage(
            property,
            entityIds);

    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number, data: number) => {
            const workloadFileScanFilterItemPage = await getWorkloadFileScanFilterItemPage(searchText, skip, limit);
            const filterItemCount = workloadFileScanFilterItemPage.count ?? data;
            return new PagedValuesFilterValuePage(
                filterItemCount,
                workloadFileScanFilterItemPage.emptyValue && emptyValue,
                workloadFileScanFilterItemPage.items,
                () => filterItemCount);

        },
        [getWorkloadFileScanFilterItemPage, emptyValue]);
}