import { useTheme } from "@mui/material";
import _ from "lodash";
import React, { FunctionComponentElement } from "react";
import { Action1, ActionButton, CheckboxField, ColumnsIcon, DataTableColumnProps, Dropdown, SearchList, SearchListSelectors, useLocalization, useUncaptureValue } from "@infrastructure";

type DataTableColumnMultiSelectProps = {
    columns: FunctionComponentElement<DataTableColumnProps>[];
    onSelectedIdsChanged: Action1<string[]>;
    selectedIds: string[];
};

export function DataTableColumnMultiSelect({ columns, onSelectedIdsChanged, selectedIds }: DataTableColumnMultiSelectProps) {
    const localization =
        useLocalization(
            "infrastructure.dataTable.dataTableColumnMultiSelect",
            () => ({
                tooltip: "Column Picker"
            }));
    const uncaptureSelectedIds = useUncaptureValue(selectedIds);
    const theme = useTheme();
    return (
        <Dropdown
            disabled={_.isEmpty(columns)}
            popoverElement={
                () =>
                    <SearchList
                        dense={true}
                        dependencies={[selectedIds]}
                        disablePadding={true}
                        headerElement={
                            columns.length > 5
                                ? <SearchListSelectors
                                    getItemValue={item => item.props.id}
                                    items={
                                        _.map(
                                            columns,
                                            column => column.props.id)}
                                    selectedItems={selectedIds}
                                    onSelectedItemsChanged={selectedItems => onSelectedIdsChanged(selectedItems ?? [])}/>
                                : undefined}
                        itemOptions={{
                            getDependencies: (item: FunctionComponentElement<DataTableColumnProps>) => [_.includes(selectedIds, item.props.id)],
                            getSortValue: (item: FunctionComponentElement<DataTableColumnProps>) => item.props.title!,
                            render:
                                (item: FunctionComponentElement<DataTableColumnProps>) =>
                                    <CheckboxField
                                        checked={_.includes(selectedIds, item.props.id)}
                                        onChange={
                                            (event, checked) =>
                                                uncaptureSelectedIds(
                                                    selectedIds =>
                                                        checked
                                                            ? onSelectedIdsChanged(_.concat(selectedIds, item.props.id))
                                                            : onSelectedIdsChanged(_.without(selectedIds, item.props.id)))}>
                                        {item.props.title}
                                    </CheckboxField>,
                            spacing: 0.5
                        }}
                        items={columns}
                        listOptions={{ infinite: false }}
                        searchOptions={{ enabled: false }}
                        sorted={false}
                        sx={{
                            minWidth: theme.spacing(25),
                            padding: theme.spacing(0, 1, 0.5)
                        }}
                        variant="dropdown"/>}
            popoverElementContainerSx={{ padding: 0 }}>
            <ActionButton
                size="medium"
                tooltip={localization.tooltip()}
                variant="outlined">
                <ColumnsIcon/>
            </ActionButton>
        </Dropdown>);
}