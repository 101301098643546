import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsRedshiftClusterRiskContext } from "../contexts";

export function useAwsRedshiftClusterEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRedshiftClusterEncryptionDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRedshiftClusterModel;

    const getAwsRedshiftClusterRiskContext = useGetAwsRedshiftClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRedshiftClusterEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Select the **Properties** tab",
                        step2: "In the **Database configurations** section, click **Edit > Edit encryption**",
                        step3: "In the Encryption section, select **Use AWS Key Management Service (AWS KMS)**.",
                        step4: "In the AWS KMS section, choose the relevant key that you want to use.",
                        step5: "Before saving your changes, make sure the relevant identities have the right permissions to the KMS key used to encrypt the Redshift cluster",
                        step6: "Click **Save changes**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Redshift,
                AwsConsoleUrlBuilder.getRedshiftClusterUrl(clusterModel.entity as Contract.AwsRedshiftCluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAwsRedshiftClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.inboundExternalAccessScope,
                clusterRiskContext.sensitive,
                clusterRiskContext.storageSize,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}