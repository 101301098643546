import { DataTableColumnMultiSelectActions, DataTableFilterExternalOptions, DataTableSort, Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useCallback, useEffect } from "react";
import { Contract } from "../../../../../../../common";
import { useRiskController } from "../../../hooks";
import { RequestData, useItemsContextProvider, useRiskTypeDefinition } from "../hooks";
import { useRiskContext } from "../Items";
import { Table, TableActions } from "./Table";

type UngroupedProps = {
    columnMultiSelectActionRef: React.MutableRefObject<DataTableColumnMultiSelectActions | undefined>;
    filterExternalOptions: DataTableFilterExternalOptions;
    filtersInitialized: boolean;
    filtersMap: Dictionary<any>;
    onRiskModelCountChanged?: (riskModelCount?: number) => void;
    onSortChanged?: (sort?: DataTableSort) => void;
    requestData: RequestData;
    tableActionsRef: React.MutableRefObject<TableActions | undefined>;
    view: Contract.RisksView;
};

export function Ungrouped({ columnMultiSelectActionRef, filterExternalOptions, filtersInitialized, filtersMap, onRiskModelCountChanged, onSortChanged, requestData, tableActionsRef, view }: UngroupedProps) {
    const { riskType } = useRiskContext();
    const riskTypeDefinition = useRiskTypeDefinition(riskType, view);

    const [{ getRequestSort, requestFilters, riskIdToScopeIdsMapRef }, , ItemsContextProvider] =
        useItemsContextProvider(
            filtersMap,
            filtersInitialized,
            () => tableActionsRef.current!.reloadRiskModels(),
            requestData,
            view);

    useEffect(
        () => {
            if (!_.isNil(requestFilters)) {
                tableActionsRef.current?.reset({ clearFilterAndSort: true });
            }
        },
        [requestFilters, view]);

    const riskController = useRiskController(riskType);
    const getRiskModelPage =
        useCallback(
            (_filterMap: Dictionary<any>, limit: number, skip: number, sort: Optional<DataTableSort>): Promise<Contract.RiskControllerGetRiskModelPageResponse> =>
                riskController.getRiskModelPage(
                    requestFilters!,
                    limit,
                    skip,
                    getRequestSort(sort)),
            [getRequestSort, requestFilters, riskTypeDefinition]);

    const getRiskIdToScopesIdentityPermissionsMap =
        useCallback(
            async () => {
                const { riskIdToScopeIdToIdentityPermissionsMap } =
                    await riskController.getRiskIdToScopeIdentityPermissionsMap(requestFilters!);
                riskIdToScopeIdsMapRef.current =
                    _.merge(
                        riskIdToScopeIdsMapRef.current,
                        _.mapValues(
                            riskIdToScopeIdToIdentityPermissionsMap,
                            scopeIdToIdentityPermissionsMap => _.keys(scopeIdToIdentityPermissionsMap)));

                return _.mapValues(
                    riskIdToScopeIdToIdentityPermissionsMap,
                    scopeIdToIdentityPermissionsMap =>
                        _(scopeIdToIdentityPermissionsMap).
                            values().
                            flatMap().
                            uniq().
                            value());
            },
            [requestFilters, riskIdToScopeIdsMapRef]);

    return (
        <ItemsContextProvider>
            <Table
                actionsRef={tableActionsRef}
                columns={riskTypeDefinition.ungrouped.columns}
                filterExternalOptions={filterExternalOptions}
                getRiskIdToScopeIdentityPermissionsMap={getRiskIdToScopesIdentityPermissionsMap}
                getRiskModelPage={getRiskModelPage}
                waitForReset={true}
                onRiskModelCountChanged={onRiskModelCountChanged}
                onSelectorChanged={ids => columnMultiSelectActionRef.current?.setSelectedColumnIds(ids)}
                onSortChanged={onSortChanged}/>
        </ItemsContextProvider>);
}