import { useFormatRelativeTime, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DataAnalysisResourceScanStatusCell } from "../../../..";
import { Contract, DataCategories, InfoItem, LicensingHelper, useDataAnalysisSensitivityTranslator } from "../../../../../../../../../common";
import { DataAnalysisSensitivityCell } from "../../../../../../DataAnalysis/components/DataAnalysisSensitivityCell";

export function useCommonDataAnalysisResourceInfoItemElements(entityModel: Contract.EntityModel) {
    const dataAnalysisResourceModel = _.as<Contract.IDataAnalysisResourceModel>(entityModel);
    const dataAnalysisSensitivityTranslator = useDataAnalysisSensitivityTranslator();
    const formatRelativeTime = useFormatRelativeTime();

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useCommonDataAnalysisResourceInfoItemElements",
            () => ({
                info: {
                    items: {
                        dataAnalysisSensitivity: "Data Sensitivity",
                        dataAnalysisStatus: "Scan Status",
                        dataCategories: "Data Categories",
                        scanTime: "Scan Time"
                    }
                }
            }));

    return !LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
        ? {}
        : {
            dataAnalysisSensitivityInfoElement:
                <InfoItem
                    key="dataAnalysisSensitivity"
                    location="all"
                    title={localization.info.items.dataAnalysisSensitivity()}
                    value={
                        _.isNil(dataAnalysisResourceModel.dataAnalysisResourceSensitivity)
                            ? undefined
                            : <DataAnalysisSensitivityCell dataSensitivityLevel={dataAnalysisSensitivityTranslator(dataAnalysisResourceModel.dataAnalysisResourceSensitivity)}/>}/>,
            dataAnalysisStatusInfoElement:
                <InfoItem
                    key="scanStatus"
                    location="all"
                    title={localization.info.items.dataAnalysisStatus()}
                    value={
                        <DataAnalysisResourceScanStatusCell
                            dataAnalysisError={dataAnalysisResourceModel.dataAnalysisError}
                            dataAnalysisStatus={dataAnalysisResourceModel.dataAnalysisStatus}/>}/>,
            dataCategoriesInfoElement:
                <InfoItem
                    key="dataCategories"
                    location="all"
                    title={localization.info.items.dataCategories()}
                    value={
                        _.isEmpty(dataAnalysisResourceModel.dataCategoryToSensitivityMap)
                            ? undefined
                            : <DataCategories categories={_.keys(dataAnalysisResourceModel.dataCategoryToSensitivityMap) as Contract.DataCategory[]}/>}
                    vertical={true}/>,
            scanTimeInfoElement:
                <InfoItem
                    key="scanTime"
                    location="all"
                    title={localization.info.items.scanTime()}
                    value={
                        _.isNil(dataAnalysisResourceModel.scanTime)
                            ? undefined
                            : formatRelativeTime(dataAnalysisResourceModel.scanTime)}/>
        };
}