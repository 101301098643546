import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../utilities";
import { useOciBlockStorageBlockVolumeDefaultEncryptionRiskDefinition, useOciBlockStorageBootVolumeDefaultEncryptionRiskDefinition, useOciComputeInstanceMetadataServiceVersionRiskDefinition, useOciComputeInstanceSecureBootNotEnabledRiskDefinition, useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskDefinition, useOciFssFileSystemDefaultEncryptionRiskDefinition, useOciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskDefinition, useOciIamDomainPasswordExpirationPolicyRiskDefinition, useOciIamDomainPasswordLengthPolicyRiskDefinition, useOciIamDomainPasswordReusePolicyRiskDefinition, useOciIamUserApiKeyNotRotatedRiskDefinition, useOciIamUserAuthTokenNotRotatedRiskDefinition, useOciIamUserDatabasePasswordNotRotatedRiskDefinition, useOciIamUserOrganizationAdministratorApiKeyExistsRiskDefinition, useOciIamUserSecretKeyNotRotatedRiskDefinition, useOciKmsVaultKeyNotRotatedRiskDefinition, useOciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskDefinition, useOciNetworkingSecurityListInboundRuleSubnetAnyExistsRiskDefinition, useOciNetworkingVcnFlowLogsNotEnabledRiskDefinition, useOciObjectStorageBucketDefaultEncryptionRiskDefinition, useOciObjectStorageBucketLogCategoryWriteNotExistsRiskDefinition, useOciObjectStorageBucketObjectEventsDisabledRiskDefinition, useOciObjectStorageBucketObjectVersioningDisabledRiskDefinition, useOciObjectStorageBucketPublicAccessExistsRiskDefinition } from "./compliance";

export function useOciDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskModel.risk.typeName) {
                    case Contract.TypeNames.OciBlockStorageBlockVolumeDefaultEncryptionRisk:
                        return useOciBlockStorageBlockVolumeDefaultEncryptionRiskDefinition;
                    case Contract.TypeNames.OciBlockStorageBootVolumeDefaultEncryptionRisk:
                        return useOciBlockStorageBootVolumeDefaultEncryptionRiskDefinition;
                    case Contract.TypeNames.OciComputeInstanceMetadataServiceVersionRisk:
                        return useOciComputeInstanceMetadataServiceVersionRiskDefinition;
                    case Contract.TypeNames.OciComputeInstanceSecureBootNotEnabledRisk:
                        return useOciComputeInstanceSecureBootNotEnabledRiskDefinition;
                    case Contract.TypeNames.OciComputeInstanceVolumeEncryptionInTransitDisabledRisk:
                        return useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskDefinition;
                    case Contract.TypeNames.OciFssFileSystemDefaultEncryptionRisk:
                        return useOciFssFileSystemDefaultEncryptionRiskDefinition;
                    case Contract.TypeNames.OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRisk:
                        return useOciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskDefinition;
                    case Contract.TypeNames.OciIamDomainPasswordExpirationPolicyRisk:
                        return useOciIamDomainPasswordExpirationPolicyRiskDefinition;
                    case Contract.TypeNames.OciIamDomainPasswordLengthPolicyRisk:
                        return useOciIamDomainPasswordLengthPolicyRiskDefinition;
                    case Contract.TypeNames.OciIamDomainPasswordReusePolicyRisk:
                        return useOciIamDomainPasswordReusePolicyRiskDefinition;
                    case Contract.TypeNames.OciIamUserApiKeyNotRotatedRisk:
                        return useOciIamUserApiKeyNotRotatedRiskDefinition;
                    case Contract.TypeNames.OciIamUserAuthTokenNotRotatedRisk:
                        return useOciIamUserAuthTokenNotRotatedRiskDefinition;
                    case Contract.TypeNames.OciIamUserDatabasePasswordNotRotatedRisk:
                        return useOciIamUserDatabasePasswordNotRotatedRiskDefinition;
                    case Contract.TypeNames.OciIamUserOrganizationAdministratorApiKeyExistsRisk:
                        return useOciIamUserOrganizationAdministratorApiKeyExistsRiskDefinition;
                    case Contract.TypeNames.OciIamUserSecretKeyNotRotatedRisk:
                        return useOciIamUserSecretKeyNotRotatedRiskDefinition;
                    case Contract.TypeNames.OciKmsVaultKeyNotRotatedRisk:
                        return useOciKmsVaultKeyNotRotatedRiskDefinition;
                    case Contract.TypeNames.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk:
                    case Contract.TypeNames.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk:
                        return useOciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskDefinition;
                    case Contract.TypeNames.OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRisk:
                    case Contract.TypeNames.OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRisk:
                        return useOciNetworkingSecurityListInboundRuleSubnetAnyExistsRiskDefinition;
                    case Contract.TypeNames.OciNetworkingVcnFlowLogsNotEnabledRisk:
                        return useOciNetworkingVcnFlowLogsNotEnabledRiskDefinition;
                    case Contract.TypeNames.OciObjectStorageBucketDefaultEncryptionRisk:
                        return useOciObjectStorageBucketDefaultEncryptionRiskDefinition;
                    case Contract.TypeNames.OciObjectStorageBucketLogCategoryWriteNotExistsRisk:
                        return useOciObjectStorageBucketLogCategoryWriteNotExistsRiskDefinition;
                    case Contract.TypeNames.OciObjectStorageBucketObjectEventsDisabledRisk:
                        return useOciObjectStorageBucketObjectEventsDisabledRiskDefinition;
                    case Contract.TypeNames.OciObjectStorageBucketObjectVersioningDisabledRisk:
                        return useOciObjectStorageBucketObjectVersioningDisabledRiskDefinition;
                    case Contract.TypeNames.OciObjectStorageBucketPublicAccessExistsRisk:
                        return useOciObjectStorageBucketPublicAccessExistsRiskDefinition;
                    default:
                        throw new UnexpectedError("riskModel.risk.typeName", riskModel.risk.typeName);
                }
            },
            []);

    return useDefinition(riskModel);
}