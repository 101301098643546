import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { AzureAccessPrincipalRiskDefinition } from "../useDefinition";

export function useAadDirectoryGroupDefinition(riskModel: Contract.AzureAccessPrincipalRiskModel) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.hooks.useDefinition.hooks.useAadDirectoryGroupDefinition",
            () => ({
                empty: "Group has no members"
            }));

    return new AzureAccessPrincipalRiskDefinition([
        _.isEmpty(riskModel.risk.groupIdentityIds)
            ? localization.empty()
            : undefined
    ]);
}