﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, entityModelStore, TypeHelper } from "../../../../../common";

export function useGetUnsupportedOperatingSystemMessage() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useGetUnsupportedOperatingSystemMessage",
            () => ({
                containerImage: "Since this container image runs an OS which isn't currently supported for scanning, the listed vulnerabilities only relate to non-OS software",
                virtualMachine: "Since this machine runs an OS which isn't currently supported for scanning, the listed vulnerabilities only relate to non-OS software"
            }));

    return (entityId: string) => {
        const entityModel = entityModelStore.useGet(entityId);
        if (TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IContainerImageModel)) {
            if (_.as<Contract.IContainerImageModel>(entityModel).workloadAnalysisOperatingSystem?.supported === false) {
                return localization.containerImage();
            }
        } else if (TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IVirtualMachineModel)) {
            if (_.as<Contract.IVirtualMachineModel>(entityModel).workloadAnalysisOperatingSystem?.supported === false) {
                return localization.virtualMachine();
            }
        }
        return undefined;
    };
}