import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useDataAnalysisUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useDataAnalysisUpdateAuditEventDefinition",
            () => ({
                managedDatabaseScanEnabled: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set managed database scan status",
                    true: "Enabled"
                },
                objectStoreScanEnabled: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set bucket scan status",
                    true: "Enabled"
                },
                privateObjectStoreScanEnabled: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set private bucket scan status",
                    true: "Enabled"
                }
            }));
    const dataAnalysisUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.DataAnalysisUpdateAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            dataAnalysisUpdateAuditEvent.managedDatabaseScanEnabledChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.managedDatabaseScanEnabled.title(),
                    _.isNil(dataAnalysisUpdateAuditEvent.managedDatabaseScanEnabled)
                        ? localization.managedDatabaseScanEnabled.inherit()
                        : dataAnalysisUpdateAuditEvent.managedDatabaseScanEnabled
                            ? localization.managedDatabaseScanEnabled.true()
                            : localization.managedDatabaseScanEnabled.false())
                : undefined!,
            dataAnalysisUpdateAuditEvent.objectStoreScanEnabledChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.objectStoreScanEnabled.title(),
                    _.isNil(dataAnalysisUpdateAuditEvent.objectStoreScanEnabled)
                        ? localization.objectStoreScanEnabled.inherit()
                        : dataAnalysisUpdateAuditEvent.objectStoreScanEnabled
                            ? localization.objectStoreScanEnabled.true()
                            : localization.objectStoreScanEnabled.false())
                : undefined!,
            dataAnalysisUpdateAuditEvent.privateObjectStoreScanEnabledChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.privateObjectStoreScanEnabled.title(),
                    _.isNil(dataAnalysisUpdateAuditEvent.privateObjectStoreScanEnabled)
                        ? localization.privateObjectStoreScanEnabled.inherit()
                        : dataAnalysisUpdateAuditEvent.privateObjectStoreScanEnabled
                            ? localization.privateObjectStoreScanEnabled.true()
                            : localization.privateObjectStoreScanEnabled.false())
                : undefined!
        ]));
}