import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useAzureCommonKubernetesResourceInfoItemElements } from "./useAzureCommonKubernetesResourceInfoItemElements";

export function useAzureCommonKubernetesNamespaceResourceInfoItemElements(resourceModel: Contract.AzureResourceModel) {
    const commonResourceInfoElements = useAzureCommonKubernetesResourceInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.AzureKubernetesResource<Contract.KubernetesNamespaceResourceData>;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonKubernetesNamespaceResourceInfoItemElements",
            () => ({
                namespace: "Namespace"
            }));

    return {
        ...commonResourceInfoElements,
        namespaceInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={resource.data.namespaceId}
                entityTypeName={Contract.TypeNames.AzureKubernetesNamespace}
                key="namespaceId"
                location="all"
                title={localization.namespace()}/>
    };
}