import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Container } from ".";
import { Contract, customEntityAttributeDefinitionModelStore, Identity, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { EntityAttributeProps } from "..";

export function Manual({ entityAttribute, entityCount, entityTypeName }: EntityAttributeProps) {
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const customEntityAttributeDefinitionModel =
        useMemo(
            () => {
                const customEntityAttribute = entityAttribute as Contract.CustomEntityAttribute;
                return _.find(
                    customEntityAttributeDefinitionModels,
                    customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id === customEntityAttribute.definitionId)!;
            },
            []);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.entityAttributes.entityAttribute.manual",
            () => ({
                tooltip: {
                    notSelf: {
                        many: "Some {{translatedEntityTypeName}} are linked to compute resources with the manual label",
                        single: "This {{translatedEntityTypeName}} is linked to some compute resources with the manual label"
                    },
                    self: "This resource was labeled by **{{identity}}**"
                }
            }));
    const theme = useTheme();
    return (
        <Container
            color={theme.palette.entityAttribute.custom[customEntityAttributeDefinitionModel.configuration.colorIndex]}
            name={customEntityAttributeDefinitionModel.configuration.name}
            tooltip={
                (entityAttribute as Contract.ManualCustomEntityAttribute).self && entityCount === 1
                    ? localization.tooltip.self({
                        identity:
                            <Identity
                                identityIdOrReference={(entityAttribute as Contract.ManualCustomEntityAttribute).identityReference!}
                                variant="textAndInfo"/>
                    })
                    : localization.tooltip.notSelf[
                        entityCount === 1
                            ? "single"
                            : "many"]({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: entityCount,
                                    variant: "text"
                                })
                    })}
            variant="manual"/>);
}