import { Step, TextViewer, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { PolicySection } from "../../components";
import { useGetAwsS3BucketRiskContext } from "../contexts";

export function useAwsS3BucketHttpsOnlyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsS3BucketHttpsOnlyDisabledRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.AwsS3BucketModel;
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;

    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsS3BucketHttpsOnlyDisabledRiskDefinition",
            () => ({
                description: "{{bucket}} is not enforcing transport encryption over HTTPS via the bucket policy",
                sections: {
                    policyDocument: "Policy",
                    resolution: {
                        step1: "Before enforcing transport encryption, verify that applications use only HTTPS to access the bucket",
                        step2: "Under the **Bucket policy** section, press the **Edit** button",
                        step3: "Add the deny statement to the bucket policy",
                        step4: "Click **Save** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                bucket:
                    <Entity
                        entityIdOrModel={bucketModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.S3,
                AwsConsoleUrlBuilder.getS3BucketEditPermissionsUrl(bucket)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            new Step(
                localization.sections.resolution.step3(),
                {
                    contentElement:
                        <TextViewer
                            format="json"
                            text={(riskModel as Contract.AwsS3BucketHttpsOnlyDisabledRiskModel).resolutionPolicyDocumentRawStatement!}/>
                }),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.storageSize,
                bucketRiskContext.encryption,
                bucketRiskContext.sensitive,
                bucketRiskContext.accessLevel,
                bucketRiskContext.highestDataSensitivity,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={bucket.policyDocument}/>,
                    localization.sections.policyDocument(),
                    {
                        expandable: true
                    })
            ]
        });
}