import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useAwsCommonRdsGlobalClusterResourceInfoElements(
    clusterTypeName: string,
    globalClusterResourceModel: Contract.AwsRdsGlobalClusterResourceModel) {
    const globalClusterResource = globalClusterResourceModel.entity as Contract.AwsRdsGlobalClusterResource;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonRdsGlobalClusterResourceInfoElements",
            () => ({
                info: {
                    items: {
                        deleteProtection: {
                            disabled: "Disabled",
                            enabled: "Enabled",
                            title: "Delete Protection"
                        },
                        engineVersion: "Engine Version",
                        status: "Status"
                    }
                }
            }));

    return {
        clusters:
            <EntitiesInfoItem
                entityIdsOrModels={globalClusterResourceModel.clusterIdReferences}
                entityTypeName={clusterTypeName}
                key="clusters"/>,
        deleteProtection:
            <InfoItem
                key="deleteProtection"
                title={localization.info.items.deleteProtection.title()}
                value={
                    globalClusterResource.deleteProtection
                        ? localization.info.items.deleteProtection.enabled()
                        : localization.info.items.deleteProtection.disabled()}/>,
        engineVersion:
            <InfoItem
                key="engineVersion"
                title={localization.info.items.engineVersion()}
                value={globalClusterResource.engineVersion}/>,
        status:
            <InfoItem
                key="status"
                title={localization.info.items.status()}
                value={globalClusterResource.status}/>
    };
}