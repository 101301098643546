import { IconText, InfoIcon, Loading, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../controllers";
import { useTheme } from "../themes";
import { ResourceTagHelper } from "../utilities";
import { Entity } from "./Entity";
import { Identity } from "./Identity";

type EntityPropertyProps = {
    principalReferencePropertyEntityModels: Contract.EntityModel[];
    showSourceEntity: boolean;
    value: Contract.EntityPropertyValue;
};

export function EntityPropertyValue({ principalReferencePropertyEntityModels, showSourceEntity, value }: EntityPropertyProps) {
    const localization =
        useLocalization(
            "common.entityPropertyValue",
            () => ({
                tooltip: {
                    automatic: {
                        resource: "**Source**\n{{resource}}\n",
                        title: "{{resource}}**Tag**\n{{tag}}"
                    },
                    manual: "Manually added by {{identity}} at {{creationTime | TimeFormatter.shortDate}}"
                }
            }));

    const principalReferencePropertyEntityModelMap =
        useMemo(
            () =>
                _.keyBy(
                    principalReferencePropertyEntityModels,
                    entityModel => entityModel.id),
            [principalReferencePropertyEntityModels]);

    let entityModel;
    if (value.typeName === Contract.TypeNames.EntityPropertyPrincipalReferenceValue) {
        entityModel = principalReferencePropertyEntityModelMap[(value as Contract.EntityPropertyPrincipalReferenceValue).principalSearchableIdReference.idReference];
    }

    const theme = useTheme();
    return (
        <IconText
            direction="rtl"
            icon={
                <InfoIcon
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "14px"
                    }}/>}
            text={
                value.typeName === Contract.TypeNames.EntityPropertyPrincipalReferenceValue
                    ? <Entity
                        entityIdOrModel={entityModel!}
                        sxOptions={{
                            title: {
                                paddingRight: 0
                            }
                        }}
                        variant="iconText"/>
                    : <Typography noWrap={true}>
                        {(value as Contract.EntityPropertyStringValue).string}
                    </Typography>}
            titleOrGetTitle={
                <Loading container="popup">
                    <Typography style={{ whiteSpace: "pre-wrap" }}>
                        {value.source.typeName === Contract.TypeNames.EntityPropertyValueAutomaticSource
                            ? localization.tooltip.automatic.title(
                                {
                                    resource:
                                        showSourceEntity
                                            ? localization.tooltip.automatic.resource({
                                                resource:
                                                        <Entity
                                                            entityIdOrModel={(value.source as Contract.EntityPropertyValueAutomaticSource).entityId}
                                                            variant="text"/>
                                            })
                                            : "",
                                    tag: ResourceTagHelper.getDisplayName((value.source as Contract.EntityPropertyValueAutomaticSource).tag)
                                })
                            : localization.tooltip.manual(
                                {
                                    creationTime: (value.source as Contract.EntityPropertyValueManualSource).creationTime,
                                    identity:
                                            <Identity
                                                identityIdOrReference={(value.source as Contract.EntityPropertyValueManualSource).creatorIdentityReference}
                                                variant="textAndInfo"/>
                                })}
                    </Typography>
                </Loading>}/>);
}