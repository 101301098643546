﻿import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, NetworkScopeFormatter, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";

export function useGetGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext() {
    return useMemo(
        () => useGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext,
        []);
}

function useGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext(riskModel: Contract.GcpInboundExternalComputeInstanceRiskModel) {
    const instanceModels = entityModelStore.useGet(riskModel.risk.aggregatedEntityIds) as Contract.GcpComputeInstanceModel[];

    const networkScopes =
        _(riskModel.risk.networkData.securityPerimeterResourceIdToExposedNetworkScopesMap).
            values().
            flatten().
            map(exposedNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(exposedNetworkScope)).
            uniq().
            value();
    const securityPerimeterResourceIds =
        _(riskModel.risk.networkData.securityPerimeterResourceIdToExposedNetworkScopesMap).
            map((_exposedNetworkScopes, networkPathResourceId) => networkPathResourceId).
            uniq().
            value();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpInboundExternalComputeInstanceRiskDefinition.hooks.useGetGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext",
            () => ({
                networkScopes: [
                    "1 public port range",
                    "{{count | NumberFormatter.humanize}} public port ranges"
                ],
                networkSecurityPerimeterResourceIdsAndExposedNetworkScopes: [
                    "The {{translatedInstanceTypeName}} is exposed to {{networkScopes}} via {{securityPerimeterResourceIds}} rules",
                    "The {{translatedInstanceTypeName}} are exposed to {{networkScopes}} via {{securityPerimeterResourceIds}} rules"]
            }));

    return {
        networkSecurityPerimeterResourceIdsAndExposedNetworkScopes:
            new RiskDefinitionContextItem(
                localization.networkSecurityPerimeterResourceIdsAndExposedNetworkScopes(
                    instanceModels.length,
                    {
                        networkScopes:
                            <InlineItems
                                items={networkScopes}
                                namePluralizer={localization.networkScopes}
                                variant="itemCountAndType"/>,
                        securityPerimeterResourceIds:
                            <InlineEntities
                                entityIdsOrModels={securityPerimeterResourceIds}
                                entityTypeName={Contract.TypeNames.GcpScopeResource}
                                variant="itemCountAndType"/>,
                        translatedInstanceTypeName:
                            entityTypeNameTranslator(
                                instanceModels[0].entity.typeName,
                                {
                                    count: 0,
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    }))
    };
}