﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useOpCommonKubernetesNamespaceResourceInfoItemElements, useOpDefaultResourceInfoItemElements } from "..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { Info } from "../../../../components";
import { KubernetesRoleBindings, KubernetesYamlInfoCard } from "../../components";

export function useOpKubernetesNamespaceRoleDefinition(resourceModel: Contract.OpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useOpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOpDefaultResourceInfoItemElements(resourceModel);
    const namespaceRoleModel = resourceModel as Contract.OpKubernetesNamespaceRoleModel;
    const namespaceRole = resourceModel.entity as Contract.OpKubernetesNamespaceRole;

    const localization = useLocalization(
        "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpKubernetesNamespaceRoleDefinition",
        () => ({
            tabs: {
                roleBindings: "Role Bindings",
                yaml: "YAML"
            }
        }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <KubernetesRoleBindings principalOrRoleModel={namespaceRoleModel}/>,
                localization.tabs.roleBindings(),
                "roleBindings"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={namespaceRole.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.tagsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(namespaceRole.data)
    });
}