import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OrganizationIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M2 3C2 2.44772 2.44772 2 3 2H12C12.5523 2 13 2.44772 13 3V19H2V3ZM5.20898 5H9.79232V7H5.20898V5ZM9.79232 10H5.20898V12H9.79232V10ZM15 8C14.4477 8 14 8.44771 14 9V19H22V9C22 8.44772 21.5523 8 21 8H15ZM19.7782 11H16.2227V13H19.7782V11ZM1 20C0.447715 20 0 20.4477 0 21C0 21.5523 0.447715 22 1 22H23C23.5523 22 24 21.5523 24 21C24 20.4477 23.5523 20 23 20H1Z"
                fill="#BAC5D3"
                fillRule="evenodd"/>
        </SvgIcon>);
}