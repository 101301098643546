import { IconText, TooltipProps } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../themes";

type TopbarInfoItemProps = {
    icon: ReactNode;
    text: string | ReactNode;
} & Pick<TooltipProps, "titleOrGetTitle">;

export function ProfileTopbarInfoItem({ icon, text, titleOrGetTitle }: TopbarInfoItemProps) {
    const theme = useTheme();
    return (
        <IconText
            containerSx={{ paddingRight: 0 }}
            icon={icon}
            iconSx={{
                color: theme.palette.text.secondary,
                fontSize: "16px"
            }}
            spacing={0.5}
            text={
                _.isString(text)
                    ? <Typography noWrap={true}>
                        {text}
                    </Typography>
                    : text}
            titleOrGetTitle={titleOrGetTitle}/>);
}