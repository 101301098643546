import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciComputeInstanceConfigurationRiskContext() {
    return useMemo(
        () => useOciComputeInstanceConfigurationRiskContext,
        []);
}

function useOciComputeInstanceConfigurationRiskContext(instanceConfigurationModel: Contract.OciComputeInstanceConfigurationModel) {
    const instanceConfiguration = instanceConfigurationModel.entity as Contract.OciComputeInstanceConfiguration;
    const resourceRiskContext = useGetOciResourceRiskContext()(instanceConfigurationModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciComputeInstanceConfigurationRiskContext",
            () => ({
                instancePools: "The {{translatedInstanceConfigurationTypeName}} is associated with {{instancePools}}",
                instances: "The {{translatedInstanceConfigurationTypeName}} is associated with {{instances}}"
            }));
    const translatedInstanceConfigurationTypeName =
        entityTypeNameTranslator(
            instanceConfiguration.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        instancePools:
            _.isEmpty(instanceConfigurationModel.instancePoolIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instancePools({
                        instancePools:
                            <InlineEntities
                                entityIdsOrModels={instanceConfigurationModel.instancePoolIds}
                                entityTypeName={Contract.TypeNames.OciComputeInstancePool}
                                variant="itemCountAndType"/>,
                        translatedInstanceConfigurationTypeName
                    })),
        instances:
            _.isEmpty(instanceConfigurationModel.instanceIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instances({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={instanceConfigurationModel.instanceIds}
                                entityTypeName={Contract.TypeNames.OciComputeInstance}
                                variant="itemCountAndType"/>,
                        translatedInstanceConfigurationTypeName
                    }))
    };
}