import { Step, useLocalization } from "@infrastructure";

export function useGetVirtualMachineImageResolutionSteps() {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useGetVirtualMachineImageResolutionSteps",
            () => ({
                deregister: "Deregister this AMI instance.",
                terminate: "Terminate any EC2 instances based on this image and relaunch them based on the new image"
            }));
    return (actionStep: string | Step) =>
        [
            localization.deregister(),
            actionStep,
            localization.terminate()
        ];
}