import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableFetchItemsResult, DataTableSort, EmptyMessageText, makeContextProvider, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Contract, entityModelStore, ItemSelectionHelper, permissionManagementActivePermissionStore, PermissionManagementController, StorageHelper, tenantModelStore, TimeRangeHelper, TypeHelper } from "../../../../../../common";
import { GranteeUserPermissionRequestsViewType, useGranteeUserPermissionRequestsContext, useSetGranteeUserPermissionRequestsContext } from "../../GranteeUserPermissionRequests";
import { useDefinition } from "./hooks";

export enum TableColumnId {
    Actions = "actions",
    ApproverUserSearchableReference = "approverUserSearchableReference",
    CloseReasonAndStatus = "closeReasonAndStatus",
    ExpirationTimeFrame = "expirationTimeFrame",
    PermissionEligibilityId = "permissionEligibilityId",
    Reason = "reason",
    StartTime = "startTime",
    SystemCreationTime = "systemCreationTime",
    TenantId = "tenantId"
}

export class TableContext {
    private _entityModelMapRef: MutableRefObject<Dictionary<Contract.EntityModel>>;

    constructor(entityModelMapRef: MutableRefObject<Dictionary<Contract.EntityModel>>) {
        this._entityModelMapRef = entityModelMapRef;
    }

    public get entityModelMap() {
        return this._entityModelMapRef.current;
    }
}

export const [useTableContext, , useTableContextProvider] = makeContextProvider<TableContext>();

type TableProps = {
    viewType: GranteeUserPermissionRequestsViewType;
};

export function Table({ viewType }: TableProps) {
    const entityModelMapRef = useRef<Dictionary<Contract.EntityModel>>({});
    const [, , ContextProvider] = useTableContextProvider(() => new TableContext(entityModelMapRef));

    const definition = useDefinition(viewType);
    const dataTableActionsRef = useRef<DataTableActions>();
    const [maxLevel, setMaxLevel] = useState(1);
    const { getFiltersResponse, refreshFilters, registerPermissionRequestModelChange } = useGranteeUserPermissionRequestsContext();
    const setGranteeUserPermissionRequestsContext = useSetGranteeUserPermissionRequestsContext();

    useChangeEffect(
        () => dataTableActionsRef.current!.reload(),
        [maxLevel]);

    useEffect(
        () => {
            dataTableActionsRef.current!.reset({ refreshFilters: true });
        },
        [getFiltersResponse]);

    useEffect(
        () => {
            const unregister =
                registerPermissionRequestModelChange(
                    async () => {
                        if (viewType === GranteeUserPermissionRequestsViewType.Active) {
                            await permissionManagementActivePermissionStore.notify();
                        }
                        await refreshFilters();
                    });
            return unregister;
        },
        []);

    const fetchPermissionRequestModels =
        async (filterMap: Dictionary<any>, _sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { permissionRequestModelPage } =
                await PermissionManagementController.getGranteeUserPermissionRequestModelPage(
                    new Contract.PermissionManagementControllerGetGranteeUserPermissionRequestModelPageRequest(
                        new Contract.PermissionManagementControllerGranteeUserPermissionRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.ApproverUserSearchableReference]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.CloseReasonAndStatus]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.PermissionEligibilityId]),
                            ItemSelectionHelper.toItemSelectionFromValues(definition.statuses),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[TableColumnId.SystemCreationTime]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.TenantId])),
                        limit,
                        skip));

            await tenantModelStore.get(
                _(permissionRequestModelPage.items).
                    filter(permissionRequestModel => permissionRequestModel.permissionRequest.typeName === Contract.TypeNames.PermissionAssignmentRequest).
                    flatMap(permissionRequestModel => (permissionRequestModel.permissionRequest as Contract.PermissionAssignmentRequest).tenantIds).
                    value());

            const groupModels =
                await entityModelStore.get(
                    _.flatMap(
                        permissionRequestModelPage.items,
                        permissionRequestModel =>
                            TypeHelper.extendOrImplement(
                                permissionRequestModel.permissionRequest.typeName,
                                Contract.TypeNames.GroupMembershipRequest) &&
                                !_.isNil(permissionRequestModel.activationFailureData)
                                ? _.keys((permissionRequestModel.activationFailureData as Contract.GroupMembershipRequestModelActivationFailureData).groupIdToErrorMessageMap)
                                : []));

            entityModelMapRef.current =
                _.merge(
                    entityModelMapRef.current,
                    _.keyBy(
                        groupModels,
                        groupModel => groupModel.id));

            return new DataTableFetchItemsResult(
                { count: permissionRequestModelPage.count! },
                permissionRequestModelPage.items,
                !permissionRequestModelPage.hasMore,
                {
                    onAppendData:
                        () =>
                            setMaxLevel(
                                _(permissionRequestModelPage.items).
                                    map(
                                        permissionRequestModel =>
                                            viewType === GranteeUserPermissionRequestsViewType.Active
                                                ? _.size(permissionRequestModel.levelToPotentialApproverUserIdReferencesMap)
                                                : 1).
                                    concat(1).
                                    max()!)
                });
        };

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.table",
            () => ({
                actions: {
                    add: "Request Access"
                },
                empty: "No items"
            }));

    return (
        <ContextProvider>
            <DataTable
                actionsRef={dataTableActionsRef}
                columnOptions={{
                    selectorOptions: {
                        enabled: true,
                        persistenceStorageItem:
                            viewType === GranteeUserPermissionRequestsViewType.Active
                                ? StorageHelper.userGranteeUserPermissionRequestsActiveColumnSelector
                                : StorageHelper.userGranteeUserPermissionRequestsHistoryColumnSelector
                    }
                }}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={fetchPermissionRequestModels}
                fetchItemsInterval={5000}
                getItemId={(item: Contract.PermissionRequestModel) => item.permissionRequest.id}
                virtualizationEnabled={true}>
                <DataTableAction>
                    <Button
                        size="small"
                        startIcon={<AddIcon/>}
                        onClick={
                            () =>
                                setGranteeUserPermissionRequestsContext(
                                    context => ({
                                        ...context,
                                        addOpen: true
                                    }))}>
                        {localization.actions.add()}
                    </Button>
                </DataTableAction>
                {definition.getColumns(maxLevel)}
            </DataTable>
        </ContextProvider>);
}