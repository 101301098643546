import { DeleteIcon, ItemSelector, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, IconButton, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, IntRangeHelper, NetworkScopeFormatter, NetworkScopeHelper, NetworkScopeHelperProtocol, TypeHelper, useTheme } from "../../../../..";
import { DestinationNetworkScopeItem } from "..";

type ItemProps = {
    destinationNetworkScopeItem: DestinationNetworkScopeItem;
    onChange: (destinationNetworkScopeItem: DestinationNetworkScopeItem) => void;
    onDelete?: () => void;
};

export function Item({ destinationNetworkScopeItem, onChange, onDelete }: ItemProps) {
    const protocolKeyToDataMap =
        useMemo(
            () =>
                _(NetworkScopeHelper.protocolToProtocolDataMap).
                    flatMap(
                        (protocolData, protocol) =>
                            _.map(
                                protocolData.types,
                                protocolType =>
                                    new ProtocolData(
                                        protocolType,
                                        protocolData.portNumber,
                                        protocol as NetworkScopeHelperProtocol))).
                    concat(new ProtocolData(Contract.ProtocolType.Tcp)).
                    concat(new ProtocolData(Contract.ProtocolType.Udp)).
                    keyBy(protocolData => protocolData.key).
                    value(),
            []);

    const [portRange, setPortRange] =
        useState(
            _.isNil(destinationNetworkScopeItem.portRange)
                ? undefined
                : IntRangeHelper.format(destinationNetworkScopeItem.portRange));
    const [protocolKey, setProtocolKey] =
        useState(
            () => {
                const protocolType =
                    _.isNil(destinationNetworkScopeItem.protocolRange)
                        ? undefined
                        : NetworkScopeHelper.getProtocolType(destinationNetworkScopeItem.protocolRange);
                const protocolData =
                    _.isNil(destinationNetworkScopeItem.protocolType)
                        ? undefined
                        : _.find(
                            protocolKeyToDataMap,
                            protocolData =>
                                protocolData.protocol === destinationNetworkScopeItem.protocolType &&
                                protocolData.protocolType === protocolType) ??
                        _.find(
                            protocolKeyToDataMap,
                            protocolData =>
                                _.isNil(protocolData.protocol) &&
                                protocolData.protocolType === destinationNetworkScopeItem.protocolType);
                return protocolData?.key;
            });

    const localization =
        useLocalization(
            "common.customRiskPolicy.destinationNetworkScopes.item",
            () => ({
                customPort: "Custom {{protocol}}",
                error: {
                    format: "Port range should be a number or two numbers seperated by dash",
                    invalid: "Port range start should be smaller than range end",
                    outOfRange: "Port numbers can only be from 0 to {{maxPortRange | NumberFormatter.humanize}}",
                    required: "Port cannot be empty"
                },
                placeholder: {
                    port: "Port or port range",
                    protocol: "Protocol"
                }
            }));

    const [portRangeValidationController, portRangeValidationMessage] =
        useInputValidation(
            () => {
                const validationPortRange = portRange?.trim();
                if (_.isEmpty(validationPortRange)) {
                    return localization.error.required();
                }

                if (!IntRangeHelper.validate(validationPortRange!)) {
                    return localization.error.format();
                }

                const parsedPortRange = IntRangeHelper.tryParse(validationPortRange!)!;
                if (parsedPortRange.end < parsedPortRange.start) {
                    return localization.error.invalid();
                }

                const maxPortRange = Math.pow(2, 16) - 1;
                if (parsedPortRange.start < 0 ||
                    parsedPortRange.end > maxPortRange) {
                    return localization.error.outOfRange({ maxPortRange });
                }

                return undefined;
            },
            [portRange]);

    useChangeEffect(
        () => {
            if (_.isNil(protocolKey)) {
                return;
            }

            const protocolData = protocolKeyToDataMap[protocolKey];
            if (!_.isNil(protocolData.protocol)) {
                const portRange =
                    new Contract.IntRange(
                        protocolData.portNumber!,
                        protocolData.portNumber!);
                setPortRange(IntRangeHelper.format(portRange));
                onChange(
                    new DestinationNetworkScopeItem(
                        true,
                        destinationNetworkScopeItem.id,
                        portRange,
                        new Contract.IntRange(
                            TypeHelper.getEnumValue(Contract.TypeNames.ProtocolType, protocolData.protocolType),
                            TypeHelper.getEnumValue(Contract.TypeNames.ProtocolType, protocolData.protocolType)),
                        protocolData.protocol));
            } else {
                onChange(
                    new DestinationNetworkScopeItem(
                        portRangeValidationController.isValid(),
                        destinationNetworkScopeItem.id,
                        IntRangeHelper.tryParse(portRange!),
                        new Contract.IntRange(
                            TypeHelper.getEnumValue(Contract.TypeNames.ProtocolType, protocolData.protocolType),
                            TypeHelper.getEnumValue(Contract.TypeNames.ProtocolType, protocolData.protocolType)),
                        protocolData.protocolType));
            }
        },
        [portRange, protocolKey]);

    const theme = useTheme();
    return (
        <Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <ItemSelector
                    fieldSx={{ minWidth: theme.spacing(32) }}
                    fullWidth={true}
                    items={
                        _(protocolKeyToDataMap).
                            orderBy(
                                [
                                    protocolData => _.isNil(protocolData.protocol),
                                    protocolData => protocolData.portNumber
                                ],
                                [
                                    "asc",
                                    "asc"
                                ]).
                            map(protocolData => protocolData.key).
                            value()}
                    placeholder={localization.placeholder.protocol()}
                    selectedItem={protocolKey}
                    sorted={false}
                    onSelectedItemChanged={setProtocolKey}>
                    {protocolKey => {
                        const protocolData = protocolKeyToDataMap[protocolKey];
                        return _.isNil(protocolData.protocol)
                            ? localization.customPort({ protocol: NetworkScopeFormatter.protocolType(protocolData.protocolType, Contract.TenantType.Aws) })
                            : NetworkScopeHelper.getProtocolProtocolTypes(protocolData.protocol).length > 1
                                ? `${NetworkScopeFormatter.portRange(new Contract.IntRange(protocolData.portNumber!, protocolData.portNumber!), protocolData.protocolType)} (${NetworkScopeFormatter.protocolType(protocolData.protocolType, Contract.TenantType.Aws)})`
                                : `${NetworkScopeFormatter.portRange(new Contract.IntRange(protocolData.portNumber!, protocolData.portNumber!), protocolData.protocolType)}`;
                    }}
                </ItemSelector>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        label={localization.placeholder.port()}
                        slotProps={{
                            htmlInput: {
                                readOnly:
                                    protocolKey !== Contract.ProtocolType.Tcp &&
                                    protocolKey !== Contract.ProtocolType.Udp
                            }
                        }}
                        sx={{ minWidth: theme.spacing(32) }}
                        value={portRange ?? ""}
                        variant="outlined"
                        onChange={event => setPortRange(event.target.value)}/>
                </FormControl>
                {!_.isNil(onDelete) && (
                    <IconButton
                        size="large"
                        onClick={onDelete}>
                        <DeleteIcon/>
                    </IconButton>)}
            </Stack>
            {(protocolKey === Contract.ProtocolType.Tcp || protocolKey === Contract.ProtocolType.Udp) &&
                !_.isNil(portRangeValidationMessage) && (
                <FormHelperText error={true}>{portRangeValidationMessage}</FormHelperText>)}
        </Stack>);
}

class ProtocolData {
    public key: string;

    constructor(
        public protocolType: Contract.ProtocolType,
        public portNumber?: number,
        public protocol?: NetworkScopeHelperProtocol) {
        this.key =
            _.isNil(protocol)
                ? protocolType
                : `${protocol}-${protocolType}`;
    }
}