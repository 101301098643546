import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useDataSensitivityTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useDataSensitivityTranslator",
            () => ({
                [Contract.TypeNames.DataSensitivity]: {
                    text: {
                        [Contract.DataSensitivity.Restricted]: "restricted",
                        [Contract.DataSensitivity.Proprietary]: "proprietary",
                        [Contract.DataSensitivity.NonSensitive]: "public",
                        [Contract.DataSensitivity.Private]: "private",
                        [Contract.DataSensitivity.Confidential]: "confidential"
                    },
                    title: {
                        [Contract.DataSensitivity.Restricted]: "Restricted",
                        [Contract.DataSensitivity.Proprietary]: "Proprietary",
                        [Contract.DataSensitivity.NonSensitive]: "Public",
                        [Contract.DataSensitivity.Private]: "Private",
                        [Contract.DataSensitivity.Confidential]: "Confidential"
                    }
                }
            }));
    return (sensitivity: Contract.DataSensitivity, variant: "text" | "title" = "title") =>
        localization[Contract.TypeNames.DataSensitivity][variant][sensitivity]();
}