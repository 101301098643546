import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, customEntityAttributeDefinitionModelStore, TopItems, TypeHelper } from "../..";
import { EntityAttribute } from "./components";

type EntityAttributesProps = {
    breakpointToTopCount?: Dictionary<number>;
    entityAttributes: Contract.EntityAttribute[];
    entityCount?: number;
    entityTypeName: string;
    orderVariant?: "leftToRight" | "rightToLeft";
    variant?: "breakpoints" | "dynamic" | "wrap";
};

export function EntityAttributes({ breakpointToTopCount = { "xs": 4 }, entityAttributes, entityCount = 1, entityTypeName, orderVariant = "rightToLeft", variant = "breakpoints" }: EntityAttributesProps) {
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const filteredOrderedEntityAttributes =
        useMemo(
            () => {
                const orderedBuiltInEntityAttributeTypeNames = [
                    Contract.TypeNames.AdministratorPrincipalAttribute,
                    Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                    Contract.TypeNames.AadDirectoryUserExternalAttribute,
                    Contract.TypeNames.AadDirectoryUserGuestAttribute,
                    Contract.TypeNames.VendorServiceIdentityAttribute,
                    Contract.TypeNames.InactiveIdentityAttribute,
                    Contract.TypeNames.GcpUnusedTenantEntityAttribute,
                    Contract.TypeNames.BehaviorRiskIdentityAttribute,
                    Contract.TypeNames.MfaDisabledUserAttribute,
                    Contract.TypeNames.AwsAccessKeyEnabledUserAttribute,
                    Contract.TypeNames.GcpUserManagedKeyExistsServiceAccountAttribute,
                    Contract.TypeNames.PublicEntityAttribute,
                    Contract.TypeNames.WorkloadResourceVulnerabilityAttribute,
                    Contract.TypeNames.RelatedPublicComputeAttribute,
                    Contract.TypeNames.RelatedWorkloadResourceVulnerabilityAttribute,
                    Contract.TypeNames.SensitiveResourceAttribute,
                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute,
                    Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                    Contract.TypeNames.CredentialsDisabledUserAttribute,
                    Contract.TypeNames.DisabledIdentityAttribute,
                    Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute
                ];

                const customEntityAttributeDefinitionModelMap =
                    _.keyBy(
                        customEntityAttributeDefinitionModels,
                        customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id);
                return _(entityAttributes).
                    filter(
                        entityAttribute =>
                            !(TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.CustomEntityAttribute) &&
                                _.isNil(customEntityAttributeDefinitionModelMap[(entityAttribute as Contract.CustomEntityAttribute).definitionId]))).
                    orderBy(
                        [
                            entityAttribute => TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.CustomEntityAttribute),
                            entityAttribute => {
                                const entityAttributeTypeNameIndex = _.indexOf(orderedBuiltInEntityAttributeTypeNames, entityAttribute.typeName);
                                return entityAttributeTypeNameIndex === -1
                                    ? _.size(orderedBuiltInEntityAttributeTypeNames)
                                    : entityAttributeTypeNameIndex;
                            },
                            entityAttribute =>
                                TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.CustomEntityAttribute) &&
                                customEntityAttributeDefinitionModelMap[(entityAttribute as Contract.CustomEntityAttribute).definitionId].configuration.name
                        ]).
                    value();
            },
            [entityAttributes]);

    return (
        <TopItems
            breakpointToTopCount={breakpointToTopCount}
            getItemId={
                (item: Contract.EntityAttribute) =>
                    TypeHelper.extendOrImplement(item.typeName, Contract.TypeNames.CustomEntityAttribute)
                        ? (item as Contract.CustomEntityAttribute).definitionId
                        : item.typeName}
            items={filteredOrderedEntityAttributes}
            itemSizeForLimitCalculations={80}
            orderVariant={orderVariant}
            variant={variant}>
            {(item: Contract.EntityAttribute) =>
                <EntityAttribute
                    entityAttribute={item}
                    entityCount={entityCount}
                    entityTypeName={entityTypeName}/>}
        </TopItems>);
}