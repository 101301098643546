import { Action0, Dropdown, DropdownActions, Loading, NumberFormatter, Optional, useExecuteOperation } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { AccessController, Contract, Entities as CommonEntities, entitySearchableReferenceStore, useTheme } from "../../../../../../../../../../../common";
import { AccessView } from "../../../../../../../hooks";

type EntitiesProps = {
    count: number;
    principalId: string;
    property: Contract.AccessControllerGetPrincipalModelPageRequestProperty;
    view: AccessView.PrincipalExcessivePermissions | AccessView.PrincipalPermissions;
};

export function Entities({ count, principalId, property, view }: EntitiesProps) {
    const actionsRef = useRef<DropdownActions>();
    const theme = useTheme();
    return (
        <Dropdown
            actionsRef={actionsRef}
            disabled={count === 0}
            popoverElement={
                <Loading container="popup">
                    <Box
                        sx={{
                            maxWidth: theme.spacing(60),
                            minWidth: theme.spacing(30),
                            padding: theme.spacing(1)
                        }}>
                        <Popover
                            principalId={principalId}
                            property={property}
                            view={view}
                            onClick={()=> actionsRef.current?.close()}/>
                    </Box>
                </Loading>}
            popoverElementContainerSx={{ padding: 0 }}>
            <Typography
                sx={{
                    cursor:
                        count === 0
                            ? undefined
                            : "pointer",
                    fontWeight:
                        count === 0
                            ? undefined
                            : 600,
                    textDecoration: "underline"
                }}>
                {NumberFormatter.unit(count)}
            </Typography>
        </Dropdown>);
}

type PopoverProps = {
    onClick: Optional<Action0>;
    principalId: string;
    property: Contract.AccessControllerGetPrincipalModelPageRequestProperty;
    view: AccessView.PrincipalExcessivePermissions | AccessView.PrincipalPermissions;
};

function Popover({ onClick, principalId, property, view }: PopoverProps) {
    const [entityIds] =
        useExecuteOperation(
            [Popover, `${principalId}/${property}`],
            async () => {
                const getPrincipalAccessPropertyItemsResponse =
                    await AccessController.getPrincipalModelPropertyItems(
                        view === AccessView.PrincipalPermissions
                            ? new Contract.AccessControllerGetPrincipalPermissionPropertyItemsRequest(principalId, property)
                            : new Contract.AccessControllerGetPrincipalExcessivePermissionPropertyItemsRequest(principalId, property));

                const entitySearchableReferences = (getPrincipalAccessPropertyItemsResponse as Contract.AccessControllerGetPrincipalPropertyEntitySearchableReferencesResponse).entitySearchableReferences;
                await entitySearchableReferenceStore.notify(entitySearchableReferences);

                return _.map(
                    entitySearchableReferences,
                    entitySearchableReference => entitySearchableReference.id);
            });
    return (
        <CommonEntities
            entityIdsOrModels={entityIds}
            entityTypeName={Contract.TypeNames.Entity}
            entityVariant="iconTextTypeTenant"
            searchEnabled={true}
            variant="dropdown"
            onEntityClick={onClick}/>);
}