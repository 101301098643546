﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useGcpCommonEncryptedResourceInfoElements(scopeResourceModel: Contract.GcpScopeResourceModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonEncryptedResourceInfoElements",
            () => ({
                encryptionExists: {
                    disabled: "Not enabled",
                    enabled: "Enabled",
                    title: "Encryption"
                },
                kmsEncryptionKeyReferences: "Encryption Key"
            }));

    return {
        encryptionExists:
            <InfoItem
                key="encryptionExists"
                title={localization.encryptionExists.title()}
                value={
                    (_.as<Contract.IGcpEncryptedResource>(scopeResourceModel.entity).encryptionExists
                        ? localization.encryptionExists.enabled()
                        : localization.encryptionExists.disabled())}/>,
        kmsEncryptionKeys:
            <EntitiesInfoItem
                entityIdsOrModels={
                    _.map(
                        _.as<Contract.IGcpEncryptedResourceModel>(scopeResourceModel).kmsEncryptionKeyReferences,
                        kmsEncryptionKeyReference => kmsEncryptionKeyReference.idReference)}
                entityTypeName={Contract.TypeNames.GcpKmsKeyRingKey}
                key="kmsEncryptionKeys"
                title={localization.kmsEncryptionKeyReferences()}/>
    };
}