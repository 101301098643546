import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, Entity, InlineEntities } from "../../../../../../../../../../../../common";

type PrincipalAssignmentsProps = {
    permissionSetModel: Contract.AwsSsoPermissionSetModel;
};

export function PrincipalAssignments({ permissionSetModel }: PrincipalAssignmentsProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSsoPermissionSetDefinition.principalAssignments",
            () => ({
                columns: {
                    principalId: "Principal",
                    tenantIds: "Accounts"
                },
                emptyMessage: "No Permission Set Account Assignments"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.emptyMessage()) }}
            fetchItems={
                () =>
                    _.map(
                        permissionSetModel.principalIdToAccountIdsMap,
                        (accountIds, principalId) =>
                            new PrincipalAssignmentsInfoCardItem(
                                accountIds,
                                principalId))}
            getItemId={(item: PrincipalAssignmentsInfoCardItem) => item.principalId}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={PrincipalAssignmentsInfoCardTableColumnId.PrincipalId}
                key={PrincipalAssignmentsInfoCardTableColumnId.PrincipalId}
                render={
                    ({ item }: DataTableColumnRenderProps<PrincipalAssignmentsInfoCardItem>) =>
                        <Entity
                            entityIdOrModel={item.principalId}
                            key={item.principalId}
                            variant="iconTextTypeTenant"/>}
                title={localization.columns.principalId()}/>
            <DataTableColumn
                id={PrincipalAssignmentsInfoCardTableColumnId.AccountIds}
                key={PrincipalAssignmentsInfoCardTableColumnId.AccountIds}
                render={
                    ({ item }: DataTableColumnRenderProps<PrincipalAssignmentsInfoCardItem>) =>
                        <Typography noWrap={true}>
                            <InlineEntities
                                entityIdsOrModels={item.accountIds}
                                entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                                key={`${item.principalId}_accountIds`}
                                variant="itemPlusItemCount"/>
                        </Typography>}
                title={localization.columns.tenantIds()}/>
        </DataTable>);
}

class PrincipalAssignmentsInfoCardItem {
    constructor(
        public accountIds: string[],
        public principalId: string) {
    }
}

enum PrincipalAssignmentsInfoCardTableColumnId {
    AccountIds = "accountIds",
    PrincipalId = "principalId"
}