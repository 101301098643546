import _, { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, optionalTableCell, PagedValuesFilter, TextValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { useAwsCommonEc2ResourceDefinition, useAwsCommonNetworkedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, FeatureHelper, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsResourceProductCodeTypeTranslator } from "../../../../../../../../../../tenants";
import { useAwsEc2InstanceHypervisorTranslator, useAwsEc2InstanceMetadataServiceVersionTranslator, useAwsEc2InstanceStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useCommonVirtualMachineDiskResourceDefinition } from "../useCommonVirtualMachineDiskResourceDefinition";
import { useCommonVirtualMachineResourceDefinition } from "../useCommonVirtualMachineResourceDefinition";

export function useAwsEc2InstanceDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonVirtualMachineDiskResourceDefinition = useCommonVirtualMachineDiskResourceDefinition(definitionData);
    const commonVirtualMachineResourceDefinition = useCommonVirtualMachineResourceDefinition();
    const entitiesExportOptions = useEntitiesExportOptions();
    const instanceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsEc2InstanceModelFilters>;

    function getBareMetalTranslation(instanceModel: Contract.AwsEc2InstanceModel) {
        return (instanceModel.entity as Contract.AwsEc2Instance).typeData.bareMetal
            ? localization.columns.bareMetal.true()
            : localization.columns.bareMetal.false();
    }

    function getEnclaveEnabledTranslation(instanceModel: Contract.AwsEc2InstanceModel) {
        const enclaveEnabled = (instanceModel.entity as Contract.AwsEc2Instance).enclaveEnabled;
        return _.isNil(enclaveEnabled)
            ? localization.columns.enclaveEnabled.unsupported()
            : enclaveEnabled
                ? localization.columns.enclaveEnabled.true()
                : localization.columns.enclaveEnabled.false();
    }

    const ec2InstanceMetadataServiceVersionTranslator = useAwsEc2InstanceMetadataServiceVersionTranslator();
    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const ec2InstanceTypeHypervisorTranslator = useAwsEc2InstanceHypervisorTranslator();
    const resourceProductCodeTypeTranslator = useAwsResourceProductCodeTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2InstanceDefinition",
            () => ({
                columns: {
                    autoScalingGroup: "Auto Scaling Group",
                    bareMetal: {
                        false: "No",
                        title: "Bare Metal",
                        true: "Yes"
                    },
                    enclaveEnabled: {
                        false: "Disabled",
                        title: "Nitro Enclave",
                        true: "Enabled",
                        unsupported: "Not supported"
                    },
                    hypervisor: "Hypervisor",
                    imageName: "Ami Name",
                    imageRawId: "Ami Id",
                    keyName: "Key Pair",
                    launchConfiguration: "Launch Configuration",
                    launchTemplateRevision: "Launch Template",
                    launchTime: "Launch Time",
                    metadataServiceOptions: {
                        accessible: "Metadata Accessible",
                        lastUsed: "Metadata Service V1 Last Usage Time",
                        version: "Metadata Version"
                    },
                    operatingSystemDescription: "Platform",
                    privateIpAddresses: "Private IP Addresses",
                    productCodeId: "Product Code Id",
                    productCodeType: "Product Code Type",
                    publicIpAddressDnsNames: "Public IP Address DNS Names",
                    publicIpAddresses: "Public IP Addresses",
                    rawType: "Instance Type",
                    roleReference: "Role",
                    status: "State",
                    stopTime: "Stop Time"
                }
            }));
    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.launchTime()]: TimeFormatter.iso8601String((item.entity as Contract.AwsEc2Instance).launchTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={instanceModelFiltersPromise}
                                    title={localization.columns.launchTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.launchTimeRange.emptyValue}
                                            placeholder={localization.columns.launchTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.launchTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceLaunchTime}
                    itemProperty={item => TimeFormatter.monthDayAndYear((item.entity as Contract.AwsEc2Instance).launchTime)}
                    key={Contract.EntityModelProperty.AwsEc2InstanceLaunchTime}
                    selectorOptions={{ default: false }}
                    title={localization.columns.launchTime()}/>,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.status()]: ec2InstanceStatusTranslator((item.entity as Contract.AwsEc2Instance).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsEc2InstanceStatus}
                                    enumTypeTranslator={ec2InstanceStatusTranslator}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceStatus}
                    itemProperty={(item: Contract.EntityModel) => ec2InstanceStatusTranslator((item.entity as Contract.AwsEc2Instance).status)}
                    key={Contract.EntityModelProperty.AwsEc2InstanceStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.stopTime()]:
                                    _.isNil(item.stopTime)
                                        ? ""
                                        : TimeFormatter.iso8601String(item.stopTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={instanceModelFiltersPromise}
                                    title={localization.columns.stopTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.stopTimeRange.emptyValue}
                                            placeholder={localization.columns.stopTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.stopTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceStopTime}
                    key={Contract.EntityModelProperty.AwsEc2InstanceStopTime}
                    render={
                        optionalTableCell<Contract.AwsEc2InstanceModel>(
                            item =>
                                _.isNil(item.stopTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear(item.stopTime))}
                    selectorOptions={{ default: false }}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.stopTime()}/>,
                commonVirtualMachineDiskResourceDefinition.columns.operatingSystemColumn,
                commonVirtualMachineDiskResourceDefinition.columns.operatingSystemTypeColumn,
                // Migration: 1.161 Remove FeatureHelper
                FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerEntityModelsCompleted) &&
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.operatingSystemDescription()]: (item.entity as Contract.AwsEc2Instance).operatingSystemDescription ?? ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2InstanceOperatingSystemDescription)}
                                    placeholder={localization.columns.operatingSystemDescription()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceOperatingSystemDescription}
                    key={Contract.EntityModelProperty.AwsEc2InstanceOperatingSystemDescription}
                    render={
                        optionalTableCell<Contract.AwsEc2InstanceModel>(
                            item =>
                                (item.entity as Contract.AwsEc2Instance).operatingSystemDescription)}
                    selectorOptions={{ default: false }}
                    title={localization.columns.operatingSystemDescription()}/>,
                commonNetworkedResourceDefinition.columns.accessLevelColumn,
                commonNetworkedResourceDefinition.columns.inboundExternalAccessScopeColumn,
                commonNetworkedResourceDefinition.columns.destinationNetworkScopesColumn(instanceModelFiltersPromise),
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.publicIpAddressDnsNames()]: _.join((item.entity as Contract.AwsEc2Instance).publicIpAddressDnsNames, "\n"),
                                [localization.columns.publicIpAddresses()]: _.join((item.entity as Contract.AwsEc2Instance).publicIpAddresses, "\n"),
                                [localization.columns.privateIpAddresses()]: _.join((item.entity as Contract.AwsEc2Instance).privateIpAddresses, "\n")
                            })
                    }}
                    id="ipAddresses"
                    key="ipAddresses"/>,
                commonVirtualMachineDiskResourceDefinition.columns.vulnerabilitiesColumn,
                commonVirtualMachineDiskResourceDefinition.columns.workloadAnalysisStatusColumn,
                commonVirtualMachineDiskResourceDefinition.columns.scanTimeColumn,
                commonVirtualMachineResourceDefinition.columns.scanSource,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.keyName()]: (item.entity as Contract.AwsEc2Instance).keyName ?? ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2InstanceKeyName)}
                                    placeholder={localization.columns.keyName()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceKeyName}
                    key={Contract.EntityModelProperty.AwsEc2InstanceKeyName}
                    render={optionalTableCell<Contract.AwsEc2InstanceModel>(item => (item.entity as Contract.AwsEc2Instance).keyName)}
                    title={localization.columns.keyName()}/>,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(),
                commonNetworkedResourceDefinition.columns.securityGroupsColumn,
                commonNetworkedResourceDefinition.columns.getLoadBalancersColumn(
                    item => (item as Contract.AwsEc2InstanceModel).loadBalancerIdReferences,
                    Contract.EntityModelProperty.AwsEc2InstanceLoadBalancers),
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2InstanceModel>(
                            Contract.TypeNames.AwsAutoScalingAutoScalingGroup,
                            item => [(item as Contract.AwsEc2InstanceModel).autoScalingGroupId],
                            localization.columns.autoScalingGroup())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2InstanceAutoScalingGroup)}
                                    placeholder={localization.columns.autoScalingGroup()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceAutoScalingGroup}
                    key={Contract.EntityModelProperty.AwsEc2InstanceAutoScalingGroup}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2InstanceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.autoScalingGroupId}
                                entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: false }}
                    title={localization.columns.autoScalingGroup()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) =>
                                ({ [localization.columns.rawType()]: (item.entity as Contract.AwsEc2Instance).typeData.rawType })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={instanceModelFiltersPromise}
                                    title={localization.columns.rawType()}>
                                    {filters =>
                                        <TextValuesFilter
                                            placeholder={localization.columns.rawType()}
                                            values={filters.rawTypeItems.items}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceTypeDataRawType}
                    itemProperty={(item: Contract.AwsEc2InstanceModel) => (item.entity as Contract.AwsEc2Instance).typeData.rawType}
                    key={Contract.EntityModelProperty.AwsEc2InstanceTypeDataRawType}
                    selectorOptions={{ default: false }}
                    title={localization.columns.rawType()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) =>
                                ({ [localization.columns.bareMetal.title()]: getBareMetalTranslation(item) })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.bareMetal.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.bareMetal.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.bareMetal.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceTypeDataBareMetal}
                    itemProperty={getBareMetalTranslation}
                    key={Contract.EntityModelProperty.AwsEc2InstanceTypeDataBareMetal}
                    selectorOptions={{ default: false }}
                    title={localization.columns.bareMetal.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) =>
                                ({
                                    [localization.columns.hypervisor()]:
                                        _.isNil((item.entity as Contract.AwsEc2Instance).typeData.hypervisor)
                                            ? ""
                                            : ec2InstanceTypeHypervisorTranslator((item.entity as Contract.AwsEc2Instance).typeData.hypervisor!)
                                })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    emptyValueOptions={{ enabled: true }}
                                    enumType={Contract.AwsEc2InstanceHypervisor}
                                    enumTypeTranslator={ec2InstanceTypeHypervisorTranslator}
                                    placeholder={localization.columns.hypervisor()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceTypeDataHypervisor}
                    itemProperty={
                        (item: Contract.EntityModel) =>
                            _.isNil((item.entity as Contract.AwsEc2Instance).typeData.hypervisor)
                                ? undefined
                                : ec2InstanceTypeHypervisorTranslator((item.entity as Contract.AwsEc2Instance).typeData.hypervisor!)}
                    key={Contract.EntityModelProperty.AwsEc2InstanceTypeDataHypervisor}
                    selectorOptions={{ default: false }}
                    title={localization.columns.hypervisor()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) =>
                                ({ [localization.columns.enclaveEnabled.title()]: getEnclaveEnabledTranslation((item)) })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    emptyValueOptions={{
                                        enabled: true,
                                        title: localization.columns.enclaveEnabled.unsupported()
                                    }}
                                    placeholder={localization.columns.enclaveEnabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.enclaveEnabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.enclaveEnabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2InstanceEnclaveEnabled}
                    itemProperty={getEnclaveEnabledTranslation}
                    key={Contract.EntityModelProperty.AwsEc2InstanceEnclaveEnabled}
                    selectorOptions={{ default: false }}
                    title={localization.columns.enclaveEnabled.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.roleReference()]: (item.entity as Contract.AwsEc2Instance).roleReference?.arn ?? ""
                            })
                    }}
                    id="exportItems"
                    key="exportItems"/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2InstanceModel>(
                            Contract.TypeNames.AwsAutoScalingLaunchConfiguration,
                            (item: Contract.AwsEc2InstanceModel) => [item.launchConfigurationId],
                            localization.columns.launchConfiguration())}
                    id="launchConfigurationId"
                    key="launchConfigurationId"/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2InstanceModel>(
                            Contract.TypeNames.AwsEc2LaunchTemplate,
                            (item: Contract.AwsEc2InstanceModel) => [(item.entity as Contract.AwsEc2Instance).launchTemplateRevisionReference?.launchTemplateIdReference],
                            localization.columns.launchTemplateRevision())}
                    id="launchTemplateRevisionReference"
                    key="launchTemplateRevisionReference"/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.metadataServiceOptions.version()]: ec2InstanceMetadataServiceVersionTranslator((item.entity as Contract.AwsEc2Instance).metadataService.version),
                                [localization.columns.metadataServiceOptions.accessible()]: (item.entity as Contract.AwsEc2Instance).metadataService.enabled,
                                [localization.columns.metadataServiceOptions.lastUsed()]:
                                    _.isNil((item.entity as Contract.AwsEc2Instance).metadataServiceVersionV1UsageTime)
                                        ? ""
                                        : TimeFormatter.iso8601String((item.entity as Contract.AwsEc2Instance).metadataServiceVersionV1UsageTime),
                                [localization.columns.imageName()]: item.imageName ?? "",
                                [localization.columns.imageRawId()]: (item.entity as Contract.AwsEc2Instance).imageRawId
                            })
                    }}
                    id="postfixExportItems"
                    key="postfixExportItems"/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.productCodeType()]:
                                    _.isNil((item.entity as Contract.AwsEc2Instance).productCode)
                                        ? ""
                                        : resourceProductCodeTypeTranslator((item.entity as Contract.AwsEc2Instance).productCode!.type)
                            })
                    }}
                    id="productCodeType"
                    key="productCodeType"/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2InstanceModel) => ({
                                [localization.columns.productCodeId()]:
                                    _.isNil((item.entity as Contract.AwsEc2Instance).productCode)
                                        ? ""
                                        : (item.entity as Contract.AwsEc2Instance).productCode!.id
                            })
                    }}
                    id="productCodeId"
                    key="productCodeId"/>,
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2InstanceRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2InstanceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceAutoScalingGroup]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceTypeDataBareMetal]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceEnclaveEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceTypeDataHypervisor]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceKeyName]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsEc2InstanceLaunchTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceLoadBalancers]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceOperatingSystemDescription]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceTypeDataRawType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2InstanceStatus]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineStopTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemDisplayName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemType]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.VirtualMachineScanTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineScanStatus])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineKubernetesClusterSensor]))));
}