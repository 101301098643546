﻿import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, tenantModelStore, useSeverityTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AadDirectoryApplicationSecretsTable } from "../../../../../../../../../../../../tenants";
import { AadConsoleUrlBuilder } from "../../../../../../../../../../../../tenants/aad/utilities/aadConsoleUrlBuilder.generated";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { useGetAadDirectoryApplicationRiskContext } from "../contexts";

export function useAadDirectoryApplicationSecretNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AadDirectoryApplicationSecretNotRotatedRisk;
    const applicationModel = entityModelStore.useGet(risk.entityId) as Contract.AadDirectoryApplicationModel;
    const application = applicationModel.entity as Contract.AadDirectoryApplication;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AadTenantConfiguration;

    const getAadDirectoryApplicationRiskContext = useGetAadDirectoryApplicationRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.compliance.useAadDirectoryApplicationSecretNotRotatedRiskDefinition",
            () => ({
                contextItems: {
                    permissions: "{{servicePrincipals}} granting **{{actionSeverity}}** severity permissions on {{tenants}}"
                },
                description: [
                    "{{application}} has {{secrets}} that was not rotated for more than {{secretRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                    "{{application}} has {{secrets}} that were not rotated for more than {{secretRotationTimeFrame | TimeSpanFormatter.humanizeDays}}"],
                sections: {
                    resolution: {
                        step1: {
                            link: "Certificates & secrets",
                            text: "In Azure Portal, open the {{credentialsLink}} page of app registration {{application}}"
                        },
                        step2: "Click **Client secrets**, and then click **New client secret**",
                        step3: "Enter secret details, making sure that the expiration time meets policy requirements. When finished, click **Add**",
                        step4: "Copy the secret value and update your application with the new credentials",
                        step5: "Before deleting the old secret, update and verify that your software is properly running with the new secret",
                        step6: "Delete the old secret"
                    },
                    secrets: "Secrets"
                }
            }));

    const highActionSeverityTenantIds = risk.permissionActionSeverityToTenantIdsMap[Contract.Severity.High] ?? [];
    const criticalActionSeverityTenantIds = risk.permissionActionSeverityToTenantIdsMap[Contract.Severity.Critical] ?? [];

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            risk.aggregatedEntityIds.length,
            {
                application:
                    <Entity
                        entityIdOrModel={applicationModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>,
                secretRotationTimeFrame: risk.secretRotationTimeFrame,
                secrets:
                    <InlineEntities
                        entityIdsOrModels={risk.aggregatedEntityIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplicationSecret}
                        variant="itemCountAndType"/>
            }),
        () => [
            localization.sections.resolution.step1.text({
                application:
                    <Entity
                        entityIdOrModel={applicationModel}
                        variant="text"/>,
                credentialsLink:
                    <Link
                        urlOrGetUrl={AadConsoleUrlBuilder.GetApplicationCredentialsUrl(
                            application.applicationRawId,
                            tenantConfiguration.partitionType)}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const applicationRiskContext = getAadDirectoryApplicationRiskContext(applicationModel);
            return [
                applicationRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.secretRotationTimeFrame),
                applicationRiskContext.applicationObjects,
                applicationRiskContext.ownerPrincipals,
                applicationRiskContext.servicePrincipals,
                _.isEmpty(highActionSeverityTenantIds) && _.isEmpty(criticalActionSeverityTenantIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.permissions({
                            actionSeverity:
                                severityTranslator(
                                    _.isEmpty(criticalActionSeverityTenantIds)
                                        ? Contract.Severity.High
                                        : Contract.Severity.Critical,
                                    "text"),
                            servicePrincipals:
                                <InlineEntities
                                    entityIdsOrModels={applicationModel.servicePrincipalIds}
                                    entityTypeName={Contract.TypeNames.AadDirectoryApplicationServicePrincipal}
                                    variant="itemCountAndType"/>,
                            tenants:
                                <InlineEntities
                                    entityIdsOrModels={
                                        _.concat(
                                            highActionSeverityTenantIds,
                                            criticalActionSeverityTenantIds)}
                                    entityTypeName={Contract.TypeNames.AzureTenantEntity}
                                    variant="itemCountAndType"/>
                        })
                    ),
                applicationRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AadDirectoryApplicationSecretsTable
                        applicationModel={applicationModel}
                        getHighlightColor={
                            (secretId: string, opacity) =>
                                _.includes(risk.aggregatedEntityIds, secretId)
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(risk.severity), opacity)
                                        : theme.palette.severity(risk.severity)
                                    : undefined}/>,
                    localization.sections.secrets(),
                    {
                        expandable: true
                    })
            ]
        });
}