﻿import { useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { memo } from "react";
import { CodeIntegrationIcon } from "../../tenants";
import { Contract } from "../controllers";

type CodeIntegrationFilterProps = {
    codeIntegrationConfigurationMap: _.Dictionary<Contract.CodeIntegrationConfiguration>;
    codeIntegrationsIds: string[];
    emptyValue: boolean;
    placeholder: string;
};

const CodeIntegrationFilterMemo = memo(CodeIntegrationFilter);
export { CodeIntegrationFilterMemo as CodeIntegrationFilter };

function CodeIntegrationFilter({ codeIntegrationConfigurationMap, codeIntegrationsIds, emptyValue, placeholder }: CodeIntegrationFilterProps) {
    const localization =
        useLocalization(
            "common.codeIntegrationFilter",
            () => ({
                filter: "{{icon}} {{name}}"
            }));
    return (
        <ValuesFilter
            emptyValueOptions={{ enabled: emptyValue }}
            placeholder={placeholder}>
            {_.map(
                codeIntegrationsIds,
                integrationId =>
                    <ValuesFilterItem
                        key={integrationId}
                        title={codeIntegrationConfigurationMap[integrationId].name}
                        value={integrationId}>
                        {() =>
                            <Typography>
                                {localization.filter({
                                    icon:
                                        <CodeIntegrationIcon
                                            configuration={codeIntegrationConfigurationMap[integrationId]}
                                            sx={{
                                                display: "inline-flex",
                                                fontSize: "18px",
                                                verticalAlign: "middle"
                                            }}/>,
                                    name: codeIntegrationConfigurationMap[integrationId].name
                                })}
                            </Typography>}
                    </ValuesFilterItem>)}
        </ValuesFilter>);
}