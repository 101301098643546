import * as amplitude from "@amplitude/analytics-browser";
import { EnrichmentPlugin, Event } from "@amplitude/analytics-types";
import _, { Dictionary } from "lodash";
import { Optional } from "../types";

export class AnalyticsHelper {
    private static deploymentName: string;
    private static environmentType: string;
    private static initialized = false;
    private static _selectedScopeType: string;

    public static get commonPropertyNameToValueMap() {
        return {
            component: "ConsoleApp",
            "Deployment Name": AnalyticsHelper.deploymentName,
            "Environment Type": AnalyticsHelper.environmentType,
            product: "Cloud Security"
        };
    }

    public static set selectedScopeType(selectedScopeType: string) {
        AnalyticsHelper._selectedScopeType = selectedScopeType;
    }

    public static initialize(amplitudeApiKey: Optional<string>, deploymentName: string, environmentType: string) {
        if (_.isNil(amplitudeApiKey)) {
            return;
        }

        amplitude.init(
            amplitudeApiKey,
            {
                autocapture: true,
                defaultTracking: false
            });

        AnalyticsHelper.deploymentName = deploymentName;
        AnalyticsHelper.environmentType = environmentType;

        amplitude.add(new CommonPropertiesEnrichmentPlugin());
    }

    public static setData(customer: AnalyticsHelperCustomer, user: AnalyticsHelperUser) {
        const customerGroupType = "Customer";
        amplitude.setGroup(customerGroupType, customer.id);
        const customerGroupIdentify = new amplitude.Identify();
        customerGroupIdentify.set("Customer Creation Time", customer.creationTime);
        customerGroupIdentify.set("Customer Eval", customer.eval);
        customerGroupIdentify.set("Customer License Types", customer.licenseTypes);
        customerGroupIdentify.set("Customer Name", customer.name);
        customerGroupIdentify.set("Customer Site Id", customer.tenableSiteId ?? "");

        const traitsGroupType = "Traits";
        const traitsGroupName = `${customer.id}/${user.mailHash}/traits`;
        amplitude.setGroup(traitsGroupType, traitsGroupName);
        const traitsIdentify = new amplitude.Identify();
        traitsIdentify.set("Traits Internal", user.internal);

        amplitude.setUserId(user.tenableId ?? `${customer.id}/${user.mailHash}`);
        const userIdentify = new amplitude.Identify();
        userIdentify.set("User Support", user.support);
        userIdentify.set("User Console App Type", user.consoleAppType);

        _.each(
            AnalyticsHelper.commonPropertyNameToValueMap,
            (commonPropertyValue, commonPropertyName) => userIdentify.set(commonPropertyName, commonPropertyValue));

        amplitude.groupIdentify(customerGroupType, customer.id, customerGroupIdentify);
        amplitude.groupIdentify(traitsGroupType, traitsGroupName, traitsIdentify);
        amplitude.identify(userIdentify);

        AnalyticsHelper.initialized = true;
    }

    public static track(actionType: AnalyticsEventActionType, context: string, propertyNameToValueMap?: AnalyticsOptionsEventPropertyNameToValueMap) {
        if (!AnalyticsHelper.initialized) {
            return;
        }

        amplitude.track(
            actionType,
            {
                ...propertyNameToValueMap,
                Context: context,
                "Selected Scope Type": AnalyticsHelper._selectedScopeType
            });
    }
}

export type AnalyticsOptions<EventNames extends string> = {
    [EventName in EventNames]?: AnalyticsOptionsEvent;
};

export type AnalyticsOptionsEvent = {
    actionType: AnalyticsEventActionType;
    propertyNameToValueMap?: AnalyticsOptionsEventPropertyNameToValueMap;
};

export type AnalyticsOptionsEventPropertyNameToValueMap = Dictionary<boolean | number | string>;

export enum AnalyticsEventActionType {
    AccessGenerateLeastPrivilege = "Access Generate Least Privilege",
    AccessPermissionCategoryClick = "Access Permission Category Click",
    AccessPrincipalExcessivePermissionsRemediateClick = "Access Principal Excessive Permissions Remediate Click",
    AccessPrincipalTableMenuClick ="Access Principal Table Menu Click",
    AuthenticationAdd = "Authentication Add",
    AuthenticationEdit = "Authentication Edit",
    AuthenticationRemove = "Authentication Remove",
    CsvExportButtonClick = "Csv Export Button Click",
    EntitiesProfileAccessTabGraph = "Entities Profile Access Tab Graph",
    EntityPropertyInsert = "Entity Property Insert",
    EntityPropertyUpdate = "Entity Property Update",
    FilterAdd = "Filter Add",
    FilterRemove = "Filter Remove",
    FilterValueAllSet = "Filter Value All Set",
    FilterValueClose = "Filter Value Close",
    FilterValueMatchesSet = "Filter Value Matches Set",
    FilterValueOnlySet = "Filter Value Only Set",
    FilterValueOpen = "Filter Value Open",
    FilterValueSet = "Filter Value Set",
    FilterValueTimeSet = "Filter Value Time Set",
    InventoryScheduleReportButtonClick = "Inventory Schedule Report Button Click",
    PageContentView = "Page Content View",
    PageView = "Page View",
    PermissionManagementPermissionEligibilityDelete = "Permission Management Permission Eligibility Delete",
    PermissionManagementPermissionEligibilityInsert = "Permission Management Permission Eligibility Insert",
    PermissionManagementPermissionEligibilityUpdate = "Permission Management Permission Eligibility Update",
    PermissionManagementPermissionRequestApprove = "Permission Management Permission Request Approve",
    PermissionManagementPermissionRequestCancel = "Permission Management Permission Request Cancel",
    PermissionManagementPermissionRequestDeny = "Permission Management Permission Request Deny",
    PermissionManagementPermissionRequestInsert = "Permission Management Permission Request Insert",
    PermissionManagementPermissionRequestRevoke = "Permission Management Permission Request Revoke",
    RiskCommentInsert = "Risk Comment Insert",
    RisksStatusUpdate = "Risks Status Update",
    RiskStatusUpdate = "Risk Status Update",
    ScheduleReportButtonClick = "Schedule Report Button Click",
    SideViewClose = "Side View Close",
    SideViewNavigate = "Side View Navigate",
    SideViewOpen = "Side View Open",
    UpsertTicketing = "Upsert Ticketing"
}

type AnalyticsHelperCustomer = {
    creationTime: string;
    eval: boolean;
    id: string;
    licenseTypes: string[];
    name: string;
    tenableSiteId?: string;
};

type AnalyticsHelperUser = {
    consoleAppType: string;
    internal: boolean;
    mailHash: string;
    support: boolean;
    tenableId: Optional<string>;
};

class CommonPropertiesEnrichmentPlugin implements EnrichmentPlugin {
    name = "common-properties-plugin";

    constructor() {
    }

    async execute(event: Event) {
        event.event_properties = {
            ...AnalyticsHelper.commonPropertyNameToValueMap,
            ...event.event_properties
        };

        return event;
    }
}