import { InlineItems, Sx, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { SelectedValue } from ".";
import { Contract, InlineEntities, InlineEntityAttributes, InlineEntityProperties, InlineResourceTags, Scope } from "../../..";
import { useEntityTypeNameTranslator } from "../../../hooks";
import { EntitySelectorSelection } from "./EntitySelector";

type InlineEntitySelectionProps = {
    entityTypeName: string;
    includeEntityServiceName?: boolean;
    selection?: EntitySelectorSelection | InlineEntitySelectionData;
    sx?: SxProps;
};

export function InlineEntitySelection({ entityTypeName, includeEntityServiceName = false, selection, sx }: InlineEntitySelectionProps) {
    const [allEntities, anyEntity, anyCustomerManagedKmsKey, criticalRiskSeverityPermissionActionPrincipal, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityPropertyRawIdentifierToValuesMap, entityTags, entityTenantIds, entityTypeNames, namePattern, provisionedEntity, sensitiveEntity] =
        useMemo(
            () => {
                const inlineSelectionData =
                    selection instanceof EntitySelectorSelection
                        ? selection.getInlineSelectionData()
                        : selection;
                return [
                    inlineSelectionData?.allEntities ?? false,
                    inlineSelectionData?.anyEntity ?? false,
                    inlineSelectionData?.anyCustomerManagedKmsKey ?? false,
                    inlineSelectionData?.criticalActionSeverityPermissionPrincipal ?? false,
                    inlineSelectionData?.entityBuiltInAttributeTypeNames,
                    inlineSelectionData?.entityCustomAttributeDefinitionIds,
                    inlineSelectionData?.entityIds,
                    inlineSelectionData?.entityPropertyRawIdentifierToValuesMap,
                    inlineSelectionData?.entityTags,
                    inlineSelectionData?.entityTenantIds,
                    inlineSelectionData?.entityTypeNames,
                    inlineSelectionData?.entityNamePattern,
                    inlineSelectionData?.provisionedEntity,
                    inlineSelectionData?.sensitiveEntity ?? false
                ];
            },
            [selection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.inlineEntitySelection",
            () => ({
                allEntities: "all {{translatedTypeName}}",
                anyCustomerManagedKmsKey: "any customer managed encryption {{translatedTypeName}}",
                anyEntity: "any {{translatedTypeName}}",
                criticalRiskSeverityPermissionActionPrincipal: "{{translatedTypeName}} with high privileges",
                entityAttributes: "{{translatedTypeName}} with {{attributes}}",
                entityNamePattern: "{{translatedTypeName}} with this name pattern",
                entityPlaceholder: "these {{translatedTypeName}}",
                entityProperties: "{{translatedTypeName}} with {{properties}}",
                entityTags: "{{translatedTypeName}} with {{tags}}",
                entityTenantIds: [
                    "{{translatedTypeName}} from this 1 account",
                    "{{translatedTypeName}} from these {{count | NumberFormatter.humanize}} accounts"
                ],
                entityTypeNames: [
                    "{{translatedTypeName}} from this 1 type",
                    "{{translatedTypeName}} from these {{count | NumberFormatter.humanize}} types"
                ],
                provisionedEntities: {
                    false: "unprovisioned",
                    title: "{{provisioned}} {{translatedTypeName}}",
                    true: "provisioned"
                },
                sensitiveEntities: "sensitive {{translatedTypeName}}"
            }));
    const textSx =
        Sx.combine(
            {
                fontWeight: 600,
                textDecoration: "underline"
            },
            sx);

    return (
        <Typography
            component="span"
            sx={textSx}>
            {allEntities &&
                localization.allEntities({
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {anyEntity &&
                localization.anyEntity({
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {anyCustomerManagedKmsKey &&
                localization.anyCustomerManagedKmsKey({
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {criticalRiskSeverityPermissionActionPrincipal &&
                localization.criticalRiskSeverityPermissionActionPrincipal({
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {(!_.isNil(entityBuiltInAttributeTypeNames) || !_.isNil(entityCustomAttributeDefinitionIds)) &&
                localization.entityAttributes({
                    attributes:
                        <SelectedValue>
                            <InlineEntityAttributes
                                builtInAttributeTypeNames={entityBuiltInAttributeTypeNames}
                                customAttributeDefinitionIds={entityCustomAttributeDefinitionIds}
                                sx={textSx}
                                variant="itemCountAndType"/>
                        </SelectedValue>,
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {!_.isNil(entityIds) &&
                <InlineEntities
                    entityIdsOrModels={entityIds}
                    entityLinkOptions={{
                        color: "inherit",
                        disabled: selection instanceof EntitySelectorSelection
                    }}
                    entityTypeName={entityTypeName}
                    sx={textSx}
                    variant="itemOrItemCountAndType"/>}
            {!_.isNil(entityPropertyRawIdentifierToValuesMap) &&
                localization.entityProperties({
                    properties:
                        <InlineEntityProperties
                            entityLinkOptions={{
                                color: "inherit",
                                disabled: selection instanceof EntitySelectorSelection
                            }}
                            entityPropertyRawIdentifierToValuesMap={entityPropertyRawIdentifierToValuesMap}
                            entityTypeName={entityTypeName}
                            sx={textSx}/>,
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {!_.isNil(entityTags) &&
                localization.entityTags({
                    tags:
                        <SelectedValue>
                            <InlineResourceTags
                                sx={textSx}
                                tags={entityTags}
                                textVariant="text"
                                variant="itemCountAndType"/>
                        </SelectedValue>,
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {!_.isNil(namePattern) &&
                localization.entityNamePattern({
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {!_.isNil(provisionedEntity) &&
                localization.provisionedEntities.title({
                    provisioned:
                        provisionedEntity
                            ? localization.provisionedEntities.true()
                            : localization.provisionedEntities.false(),
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {sensitiveEntity &&
                localization.sensitiveEntities({
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
            {!_.isNil(entityTenantIds) &&
                <InlineItems
                    items={
                        _.map(
                            entityTenantIds,
                            entityTenantId => <Scope scopeId={entityTenantId}/>)}
                    namePluralizer={
                        count =>
                            localization.entityTenantIds(
                                count,
                                {
                                    translatedTypeName:
                                        entityTypeNameTranslator(
                                            entityTypeName,
                                            {
                                                count: 0,
                                                includeServiceName: includeEntityServiceName,
                                                variant: "text"
                                            })
                                })}
                    variant="itemCountAndType"/>}
            {!_.isNil(entityTypeNames) &&
                <InlineItems
                    items={
                        _.map(
                            entityTypeNames,
                            entityTypeName => entityTypeNameTranslator(entityTypeName))}
                    namePluralizer={
                        count =>
                            localization.entityTypeNames(
                                count,
                                {
                                    translatedTypeName:
                                        entityTypeNameTranslator(
                                            entityTypeName,
                                            {
                                                count: 0,
                                                includeServiceName: includeEntityServiceName,
                                                variant: "text"
                                            })
                                })}
                    variant="itemCountAndType"/>}
            {(!allEntities &&
                    !anyEntity &&
                    !anyCustomerManagedKmsKey &&
                    !criticalRiskSeverityPermissionActionPrincipal &&
                    _.isNil(entityBuiltInAttributeTypeNames) &&
                    _.isNil(entityCustomAttributeDefinitionIds) &&
                    _.isNil(entityIds) &&
                    _.isNil(entityPropertyRawIdentifierToValuesMap) &&
                    _.isNil(entityTags) &&
                    _.isNil(entityTenantIds) &&
                    _.isNil(entityTypeNames) &&
                    _.isNil(namePattern) &&
                    _.isNil(provisionedEntity) &&
                    !sensitiveEntity ||
                    _.isNil(selection)) &&
                localization.entityPlaceholder({
                    translatedTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName: includeEntityServiceName,
                                variant: "text"
                            })
                })}
        </Typography>);
}

export type InlineEntitySelectionData = {
    allEntities?: boolean;
    anyCustomerManagedKmsKey?: boolean;
    anyEntity?: boolean;
    criticalActionSeverityPermissionPrincipal?: boolean;
    entityBuiltInAttributeTypeNames?: string[];
    entityCustomAttributeDefinitionIds?: string[];
    entityIds?: string[];
    entityNamePattern?: string;
    entityPropertyRawIdentifierToValuesMap?: _.Dictionary<string[]>;
    entityTags?: Contract.ResourceTag[];
    entityTenantIds?: string[];
    entityTypeNames?: string[];
    provisionedEntity?: boolean;
    sensitiveEntity?: boolean;
};