import { Optional } from "@infrastructure";
import { useCallback } from "react";
import { Contract, PagedEntityFilterEntityIdPage } from "../../../../../common";
import { useGetWorkloadResourceScanFileFilterItemPage, useGetWorkloadResourceScanFilterItemPage, useGetWorkloadResourceScanPackageFilterItemPage } from "./useGetWorkloadResourceScanFilterItemPage";

export function useGetWorkloadResourceScanEntityFilterItemPage(
    property: Contract.WorkloadControllerRequestProperty,
    workloadResourceType: Contract.WorkloadAnalysisWorkloadResourceType,
    vulnerabilityRawId?: string) {
    const getWorkloadResourceScanFilterItemPage =
        useGetWorkloadResourceScanFilterItemPage(
            property,
            workloadResourceType,
            vulnerabilityRawId);

    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number, data?: Optional<number>) => {
            const workloadResourceScanFilterItemPage = await getWorkloadResourceScanFilterItemPage(searchText, skip, limit);

            const filterItemCount = workloadResourceScanFilterItemPage?.count ?? data ?? 0;
            return new PagedEntityFilterEntityIdPage(
                filterItemCount,
                workloadResourceScanFilterItemPage?.emptyValue,
                workloadResourceScanFilterItemPage.items,
                () => filterItemCount);
        },
        [getWorkloadResourceScanFilterItemPage]);
}

export function useGetWorkloadResourceScanPackageEntityFilterIdPage(
    property: Contract.WorkloadControllerRequestProperty,
    entityIds?: Optional<string[]>,
    vulnerableOnly = false,
    vulnerabilityRawId?: string) {
    const getWorkloadPackageScanFilterItemPage =
        useGetWorkloadResourceScanPackageFilterItemPage(
            property,
            entityIds,
            undefined,
            false,
            vulnerableOnly,
            vulnerabilityRawId);

    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number, data?: Optional<number>) => {
            const workloadPackageScanFilterItemPage = await getWorkloadPackageScanFilterItemPage(searchText, skip, limit);

            const filterItemCount = workloadPackageScanFilterItemPage?.count ?? data ?? 0;
            return new PagedEntityFilterEntityIdPage(
                filterItemCount,
                workloadPackageScanFilterItemPage?.emptyValue,
                workloadPackageScanFilterItemPage.items,
                () => filterItemCount);
        },
        [getWorkloadPackageScanFilterItemPage]);
}

export function useGetWorkloadResourceScanFileEntityFilterIdPage(
    property: Contract.WorkloadControllerRequestProperty,
    entityIds?: Optional<string[]>) {
    const getWorkloadFileScanFilterItemPage =
        useGetWorkloadResourceScanFileFilterItemPage(
            property,
            entityIds);

    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number, data?: Optional<number>) => {
            const workloadFileScanFilterItemPage = await getWorkloadFileScanFilterItemPage(searchText, skip, limit);

            const filterItemCount = workloadFileScanFilterItemPage?.count ?? data ?? 0;
            return new PagedEntityFilterEntityIdPage(
                filterItemCount,
                workloadFileScanFilterItemPage?.emptyValue,
                workloadFileScanFilterItemPage.items,
                () => filterItemCount);
        },
        [getWorkloadFileScanFilterItemPage]);
}