import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, optionalTableCell, useChangeEffect, useLocalization } from "@infrastructure";
import { SxProps } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useRef } from "react";
import { useTheme } from "..";

export type KeyValueTableProps = {
    emptyMessageText?: EmptyMessageText;
    items: KeyValueTableItem[];
    keyTitle?: string;
    renderActions?: (item: KeyValueTableItem) => ReactNode;
    renderValue?: (item: KeyValueTableItem) => ReactNode;
    rowOptions?: KeyValueTableDataTableRowOptions;
    valueTitle?: string;
};

export type KeyValueTableDataTableRowOptions = {
    getHighlightColor?: (item: KeyValueTableItem) => Optional<string>;
    getSx?: (item: KeyValueTableItem) => Optional<SxProps>;
    getUrl?: (item: KeyValueTableItem) => string;
    onBeforeClick?: (event: KeyValueTableItem, item: KeyValueTableItem) => boolean;
};

export function KeyValueTable({ emptyMessageText, items, keyTitle, renderActions, renderValue, rowOptions, valueTitle }: KeyValueTableProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current?.reset();
        },
        [items]);

    const localization =
        useLocalization(
            "common.keyValueTable",
            () => ({
                columns: {
                    key: "Key",
                    value: "Value"
                }
            }));

    const theme = useTheme();
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText }}
            fetchItems={() => items}
            getItemId={(item: KeyValueTableItem) => item.key}
            rowOptions={rowOptions}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                cellSx={{ width: theme.px(350) }}
                id={KeyValueTableColumnId.Key}
                itemProperty={(item: KeyValueTableItem) => item.key}
                key={KeyValueTableColumnId.Key}
                title={keyTitle ?? localization.columns.key()}/>
            <DataTableColumn
                id={KeyValueTableColumnId.Value}
                key={KeyValueTableColumnId.Value}
                render={
                    optionalTableCell<KeyValueTableItem>(
                        item =>
                            renderValue?.(item) ??
                            item.value)}
                title={valueTitle ?? localization.columns.value()}/>
            {!_.isNil(renderActions) &&
                <DataTableColumn
                    disableAction={true}
                    id={KeyValueTableColumnId.Actions}
                    key={KeyValueTableColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<KeyValueTableItem>) =>
                            <Fragment>
                                {renderActions(item)}
                            </Fragment>}
                    sortOptions={{ enabled: false }}/>}
        </DataTable>);
}

enum KeyValueTableColumnId {
    Actions = "actions",
    Key = "key",
    Value = "value"
}

export class KeyValueTableItem {
    constructor(
        public key: string,
        public value?: string) {
    }
}