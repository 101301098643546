import { Avatar, Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { map, setUrlRoute, StringHelper, Tooltip } from "@infrastructure";
import { ComplianceSectionIcon, Contract, CustomerConsoleAppUrlHelper, scopeNodeModelStore, scopeSystemEntityModelStore, TopItems, TopItemsVariants, useComplianceTranslator, useGetComplianceScopeId, useSelectedScopeId, useTheme, VerticalTopItems } from "../";
import { BuiltInComplianceSectionType } from "../controllers/types.generated";

type CompliancesProps = {
    builtInComplianceTypes: BuiltInComplianceSectionType[];
    customComplianceIds?: string[];
    scopeId?: string;
    topItemsVariant?: TopItemsVariants;
    variant?: "icon" | "list";
};

export function Compliances({ builtInComplianceTypes, customComplianceIds, scopeId: complianceScopeId, topItemsVariant = "dynamic", variant = "icon" }: CompliancesProps) {
    const customComplianceModels = scopeSystemEntityModelStore.useGet(customComplianceIds ?? []);
    const getComplianceId = useGetComplianceScopeId();
    const scopeNodeMap =
        scopeNodeModelStore.useGetScopeNodeMap(
            undefined,
            true);
    const { selectedScopeId } = useSelectedScopeId();

    const complianceTranslator = useComplianceTranslator();

    const scopeId =
        useMemo(
            () => complianceScopeId ?? selectedScopeId,
            [complianceScopeId, selectedScopeId]);

    const complianceIdentifiers =
        useMemo(
            () => {
                const filteredCustomComplianceIds =
                    _(customComplianceModels).
                        filter(
                            customComplianceModel =>
                                _(scopeNodeMap[scopeId!].parentScopeIds).
                                    concat(scopeId).
                                    includes(customComplianceModel.configuration.scopeId)).
                        map(customComplianceModel => customComplianceModel.configuration.id).
                        value();
                return _([] as string[]).
                    concat(builtInComplianceTypes).
                    concat(filteredCustomComplianceIds).
                    orderBy([
                        complianceIdentifier => !_.includes(Contract.BuiltInComplianceSectionType, complianceIdentifier),
                        complianceIdentifier => StringHelper.getSortValue(complianceTranslator(complianceIdentifier).title)
                    ]).
                    value();
            },
            [builtInComplianceTypes, customComplianceModels]);

    return (
        <Fragment>
            {map(
                variant,
                {
                    "icon":
                        () =>
                            <TopItems
                                dropdownRenderer={
                                    complianceIdentifier =>
                                        <Compliance
                                            complianceIdentifier={complianceIdentifier}
                                            complianceTitle={complianceTranslator(complianceIdentifier).title}
                                            scopeId={getComplianceId(complianceIdentifier, scopeId)}
                                            variant="text"/>}
                                items={complianceIdentifiers}
                                itemSizeForLimitCalculations={32}
                                orderVariant="leftToRight"
                                variant={topItemsVariant}>
                                {complianceIdentifier =>
                                    <Compliance
                                        complianceIdentifier={complianceIdentifier}
                                        complianceTitle={complianceTranslator(complianceIdentifier).title}
                                        scopeId={getComplianceId(complianceIdentifier, scopeId)}
                                        variant="tooltip"/>}
                            </TopItems>,
                    "list":
                        () =>
                            <VerticalTopItems
                                getSearchValue={topComplianceIdentifier => complianceTranslator(topComplianceIdentifier).title}
                                getSortValue={topComplianceIdentifier => `${!_.includes(Contract.BuiltInComplianceSectionType, topComplianceIdentifier)}${complianceTranslator(topComplianceIdentifier).title}`}
                                items={complianceIdentifiers}>
                                {topComplianceIdentifier =>
                                    <Compliance
                                        complianceIdentifier={topComplianceIdentifier}
                                        complianceTitle={complianceTranslator(topComplianceIdentifier).title}
                                        scopeId={getComplianceId(topComplianceIdentifier, scopeId)}
                                        variant="text"/>}
                            </VerticalTopItems>
                }
            )}
        </Fragment>);
}

type ComplianceProps = {
    complianceIdentifier: string | Contract.BuiltInComplianceSectionType;
    complianceTitle: string;
    scopeId: string;
    variant: "text" | "tooltip";
};

function Compliance({ complianceIdentifier, complianceTitle, scopeId, variant }: ComplianceProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                ":hover": {
                    cursor: "pointer"
                }
            }}
            onClick={
                (event: React.MouseEvent) => {
                    event.stopPropagation();
                    setUrlRoute(CustomerConsoleAppUrlHelper.getComplianceProfileHashUrl(complianceIdentifier, scopeId));
                }}>
            {variant === "tooltip"
                ? <Tooltip titleOrGetTitle={complianceTitle}>
                    <Avatar
                        sx={{
                            color: "unset",
                            height: theme.spacing(3),
                            width: theme.spacing(3)
                        }}>
                        <ComplianceSectionIcon
                            complianceIdentifier={complianceIdentifier}
                            size={theme.spacing(2.25)}/>
                    </Avatar>
                </Tooltip>
                : <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        marginRight: theme.spacing(1),
                        width: "inherit"
                    }}>
                    <Avatar
                        sx={{
                            color: "unset",
                            height: theme.spacing(3),
                            width: theme.spacing(3)
                        }}>
                        <ComplianceSectionIcon
                            complianceIdentifier={complianceIdentifier}
                            size={theme.spacing(2.25)}/>
                    </Avatar>
                    <Box
                        sx={{
                            flex: 1,
                            overflow: "hidden",
                            width: "inherit"
                        }}>
                        <Typography
                            noWrap={true}
                            sx={{ width: "inherit" }}>
                            {complianceTitle}
                        </Typography>
                    </Box>
                </Stack>}
        </Box>);
}