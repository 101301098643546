import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useComplianceSectionDatas, useComplianceTranslator } from "../../../../../../../../common";
import { ContentPages, ContentPagesItem, ContentPagesProps } from "../../../ContentPages";
import { SectionHeader } from "../SectionHeader";
import { Item } from "./components";

type ComplianceContentPagesProps = Omit<ContentPagesProps, "children"> & {
    scopeId: string;
};

export function ComplianceContentPages({ scopeId, ...props }: ComplianceContentPagesProps) {
    const { customComplianceSectionDatas, enabledBuiltInComplianceSectionDatas } = useComplianceSectionDatas(scopeId, { timeFrameData: true });
    const complianceTranslator = useComplianceTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.complianceContentPages",
            () => ({
                subtitle: "Ensure compliance with common industry standards and security best practices. The percentage shown next to each standard reflects how compliant you are with relevant policy requirements. Track how your compliance status has changed over time.",
                title: "Compliance"
            }));
    return (
        <ContentPages {...props}>
            <ContentPagesItem>
                <SectionHeader
                    subtitle={localization.subtitle()}
                    title={localization.title()}
                    variant="main"/>
            </ContentPagesItem>
            {_(enabledBuiltInComplianceSectionDatas).
                concat(customComplianceSectionDatas).
                map(
                    (complianceSectionData, complianceSectionDataIndex) =>
                        <ContentPagesItem key={complianceTranslator(complianceSectionData.identifier).title}>
                            {({ pageFirstItem, pageLastItem }) =>
                                <Item
                                    complianceSectionData={complianceSectionData}
                                    complianceSectionTitle={complianceTranslator(complianceSectionData.identifier).title}
                                    itemIndex={complianceSectionDataIndex}
                                    pageFirstItem={pageFirstItem}
                                    pageLastItem={pageLastItem}/>}
                        </ContentPagesItem>).
                value()}
        </ContentPages>);
}