import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, NumberFormatter, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InfoCard } from "../../../../../../../../../../../../common";

export type DatabasesInfoCardProps = {
    databaseIds: string[];
};

export function DatabasesInfoCard({ databaseIds }: DatabasesInfoCardProps) {
    const databaseModels = entityModelStore.useGet(databaseIds);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureSqlServerDefinition.databasesInfoCard",
            () => ({
                columns: {
                    database: "Name",
                    encryptionExists: {
                        false: "Disabled",
                        title: "Encryption At Rest",
                        true: "Enabled"
                    },
                    size: "Size",
                    status: {
                        title: "Status",
                        [Contract.TypeNames.AzureSqlServerDatabaseStatus]: {
                            [Contract.AzureSqlServerDatabaseStatus.AutoClosed]: "Auto Closed",
                            [Contract.AzureSqlServerDatabaseStatus.Copying]: "Copying",
                            [Contract.AzureSqlServerDatabaseStatus.Creating]: "Creating",
                            [Contract.AzureSqlServerDatabaseStatus.Disabled]: "Disabled",
                            [Contract.AzureSqlServerDatabaseStatus.EmergencyMode]: "Emergency Mode",
                            [Contract.AzureSqlServerDatabaseStatus.Inaccessible]: "Inaccessible",
                            [Contract.AzureSqlServerDatabaseStatus.Offline]: "Offline",
                            [Contract.AzureSqlServerDatabaseStatus.OfflineChangingDwPerformanceTiers]: "Offline, Changing Data Warehouse Performance Tiers",
                            [Contract.AzureSqlServerDatabaseStatus.OfflineSecondary]: "Offline Secondary",
                            [Contract.AzureSqlServerDatabaseStatus.Online]: "Online",
                            [Contract.AzureSqlServerDatabaseStatus.OnlineChangingDwPerformanceTiers]: "Online, Changing Data Warehouse Performance Tiers",
                            [Contract.AzureSqlServerDatabaseStatus.Paused]: "Paused",
                            [Contract.AzureSqlServerDatabaseStatus.Pausing]: "Pausing",
                            [Contract.AzureSqlServerDatabaseStatus.Recovering]: "Recovering",
                            [Contract.AzureSqlServerDatabaseStatus.RecoveryPending]: "Recovery Pending",
                            [Contract.AzureSqlServerDatabaseStatus.Restoring]: "Restoring",
                            [Contract.AzureSqlServerDatabaseStatus.Resuming]: "Resuming",
                            [Contract.AzureSqlServerDatabaseStatus.Scaling]: "Scaling",
                            [Contract.AzureSqlServerDatabaseStatus.Shutdown]: "Shutdown",
                            [Contract.AzureSqlServerDatabaseStatus.Standby]: "Standby",
                            [Contract.AzureSqlServerDatabaseStatus.Starting]: "Starting",
                            [Contract.AzureSqlServerDatabaseStatus.Stopped]: "Stopped",
                            [Contract.AzureSqlServerDatabaseStatus.Stopping]: "Stopping",
                            [Contract.AzureSqlServerDatabaseStatus.Suspect]: "Suspect"
                        }
                    }
                },
                empty: "No Databases",
                title: "Databases"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => databaseModels}
                getItemId={(item: Contract.AzureResourceGroupResourceModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={DatabasesInfoCardTableColumnId.Database}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTenant"/>}
                    title={localization.columns.database()}/>
                <DataTableColumn
                    id={DatabasesInfoCardTableColumnId.Status}
                    render={
                        optionalTableCell<Contract.AzureResourceGroupResourceModel>(
                            item => {
                                const entityStatus = (item.entity as Contract.AzureSqlServerDatabase).status;
                                return _.isNil(entityStatus)
                                    ? undefined
                                    : localization.columns.status[Contract.TypeNames.AzureSqlServerDatabaseStatus][entityStatus]();
                            })}
                    title={localization.columns.status.title()}/>
                <DataTableColumn
                    id={DatabasesInfoCardTableColumnId.Size}
                    itemProperty={
                        (item: Contract.AzureResourceGroupResourceModel) =>
                            NumberFormatter.storage((item.entity as Contract.AzureSqlServerDatabase).storageSize ?? 0)}
                    title={localization.columns.size()}/>
                <DataTableColumn
                    id={DatabasesInfoCardTableColumnId.Enryption}
                    itemProperty={
                        (item: Contract.AzureResourceGroupResourceModel) =>
                            (item.entity as Contract.AzureSqlServerDatabase).encryptionExists
                                ? localization.columns.encryptionExists.true()
                                : localization.columns.encryptionExists.false()}
                    title={localization.columns.encryptionExists.title()}/>
            </DataTable>
        </InfoCard>);
}

enum DatabasesInfoCardTableColumnId {
    Database = "database",
    Enryption = "encryption",
    Size = "size",
    Status = "status"
}