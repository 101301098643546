﻿import { DataTableColumn, DataTableColumnRenderProps, map, StringHelper, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, ScopeHelper, StatusCell, tenantModelStore } from "../../../../../../../../common";
import { useOpTenantModelStatusTranslator } from "../../../../../../../../tenants";
import { ScopeRawId } from "../../components";
import { TenantsDefinition } from "../../useDefinition";
import { useRenderTenant } from "../useRenderTenant";

export function useOpDefinition(): TenantsDefinition {
    const tenantModelMap = tenantModelStore.useGetOpTenantMap();
    const { renderTenantCell, renderTenantCsvItem } = useRenderTenant<Contract.OpTenantModel>();

    const opTenantModelStatusTranslator = useOpTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOpDefinition",
            () => ({
                columns: {
                    id: "ID",
                    status: "Status"
                },
                title: "On-Premises accounts"
            }));

    return useMemo(
        () => ({
            filter:
                (filterMap, scopeNodeModel) => {
                    if (ScopeHelper.isFolder(scopeNodeModel)) {
                        const folderRawId = (scopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration).organization?.folderRawId;
                        if (!_.isNil(filterMap[TenantTableColumnId.Id]) &&
                            !_.isNil(folderRawId) &&
                            !_.includes(
                                filterMap[TenantTableColumnId.Id].values,
                                folderRawId)) {
                            return false;
                        }

                        return true;
                    }

                    const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];

                    if(_.isNil(tenantModel)) {
                        return true;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.Id]) &&
                        !_.includes(filterMap[TenantTableColumnId.Id].values, tenantModel.configuration.id)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.Status]) &&
                        !_.includes(filterMap[TenantTableColumnId.Status].values, tenantModel.status)) {
                        return false;
                    }

                    return true;
                },
            getColumns:
                scopeNodeModels => {
                    const ids: string[] = [];
                    const statuses = new Set<Contract.OpTenantModelStatus>();

                    _.forEach(
                        scopeNodeModels,
                        scopeNodeModel => {
                            ids.push(scopeNodeModel.configuration.id);
                            if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                                const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                                statuses.add(tenantModel.status);
                            }
                        });

                    return [
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.id()]:
                                            renderTenantCsvItem(
                                                tenantModel => tenantModel.configuration.id,
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <TextValuesFilter
                                            placeholder={localization.columns.id()}
                                            values={ids}/>
                                }
                            }}
                            id={TenantTableColumnId.Id}
                            key={TenantTableColumnId.Id}
                            render={
                                (scopeNodeModel: DataTableColumnRenderProps<Contract.ScopeNodeModel>) =>
                                    <ScopeRawId
                                        fallbackRender={renderTenantCell(tenantModel => tenantModel.configuration.id)}
                                        scopeNodeModel={scopeNodeModel}/>}
                            title={localization.columns.id()}/>,
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.status()]:
                                            renderTenantCsvItem(
                                                tenantModel => opTenantModelStatusTranslator(tenantModel.status),
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.status()}>
                                            {_.map(
                                                Array.from(statuses),
                                                status => (
                                                    <ValuesFilterItem
                                                        key={status}
                                                        title={opTenantModelStatusTranslator(status)}
                                                        value={status}/>))}
                                        </ValuesFilter>
                                }
                            }}
                            id={TenantTableColumnId.Status}
                            key={TenantTableColumnId.Status}
                            render={
                                renderTenantCell(
                                    tenantModel =>
                                        <StatusCell
                                            statusSeverity={tenantModel.statusSeverity}
                                            title={opTenantModelStatusTranslator(tenantModel.status)}/>)}
                            title={localization.columns.status()}/>
                    ];
                },
            sort:
                (columnId, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                        const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                        return map<string, any>(
                            columnId,
                            {
                                [TenantTableColumnId.Id]: () => StringHelper.getSortValue(tenantModel.configuration.id),
                                [TenantTableColumnId.Status]: () => StringHelper.getSortValue(opTenantModelStatusTranslator(tenantModel.status))
                            });
                    } else {
                        return undefined;
                    }
                },
            title: localization.title()
        }),
        [opTenantModelStatusTranslator, localization, tenantModelMap]);
}

enum TenantTableColumnId {
    Id = "id",
    Status = "status"
}