import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { Events } from "../../../../../../../../../Events";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsEc2SecurityGroupManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const securityGroupManagementRiskModel = riskModel as Contract.AwsEc2SecurityGroupManagementRiskModel;
    const securityGroupModel = entityModelStore.useGet(_.head(securityGroupManagementRiskModel.risk.resourceIds)!);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsEc2SecurityGroupManagementRiskDefinition",
            () => ({
                violations: {
                    [Contract.TypeNames.AwsEc2SecurityGroupManagementRiskModelDescriptionType]: {
                        [Contract.AwsEc2SecurityGroupManagementRiskModelDescriptionType.EgressRule]: "{{securityGroup}} outbound rules were modified",
                        [Contract.AwsEc2SecurityGroupManagementRiskModelDescriptionType.EgressRuleIngressRule]: "{{securityGroup}} inbound and outbound rules were modified",
                        [Contract.AwsEc2SecurityGroupManagementRiskModelDescriptionType.IngressRule]: "{{securityGroup}} inbound rules were modified"
                    }
                }
            }));
    const translatedViolations =
        localization.violations[Contract.TypeNames.AwsEc2SecurityGroupManagementRiskModelDescriptionType][securityGroupManagementRiskModel.descriptionType]({
            securityGroup:
                <Entity
                    entityIdOrModel={securityGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <Events riskId={securityGroupManagementRiskModel.risk.id}/>);
}