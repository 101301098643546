import { Link, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpBigQueryDatasetRiskContext } from "../contexts";

export function useGcpBigQueryDatasetTableEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const datasetTableEncryptionDisabledRiskModel = riskModel as Contract.GcpBigQueryDatasetTableEncryptionDisabledRiskModel;
    const risk = datasetTableEncryptionDisabledRiskModel.risk;
    const datasetModel = entityModelStore.useGet(risk.entityId) as Contract.GcpBigQueryDatasetModel;

    const getGcpBigQueryDatasetRiskContext = useGetGcpBigQueryDatasetRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpBigQueryDatasetTableEncryptionDisabledRiskDefinition",
            () => ({
                contextItems: {
                    unencryptedTables: "The {{translatedDatasetTypeName}} contains {{datasetTables}} that are not encrypted with CMEK"
                },
                datasetTables: [
                    "1 unencrypted dataset table",
                    "{{count | NumberFormatter.humanize}} unencrypted dataset tables"
                ],
                description: "{{dataset}} is not configured with encryption using CMEK",
                sections: {
                    resolution: {
                        step1: {
                            link: "Customer-managed Cloud KMS keys for BigQuery",
                            text: "Before you begin, make sure your encryption key is in a location that matches the location of your BigQuery dataset and BigQuery service account has sufficient permission to encrypt and decrypt using that key. See {{managedEncryptionLink}} for more information."
                        },
                        step2: {
                            step1: "Changing encryption of an existing table is not possible. To apply CMEK, the data must be copied to the same original table.",
                            step2: "Run the following command: bq cp --destination_kms_key projects/KMS_PROJECT_ID/locations/LOCATION/keyRings/KEY_RING/cryptoKeys/KEY DATASET_ID.TABLE_ID DATASET_ID.TABLE_ID",
                            title: "Set encryption for {{datasetTables}}"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            dataset:
                <Entity
                    entityIdOrModel={datasetModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1.text({
                managedEncryptionLink:
                    <Link
                        urlOrGetUrl={datasetTableEncryptionDisabledRiskModel.managedEncryptionDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            new Step(
                localization.sections.resolution.step2.title({
                    datasetTables:
                        <InlineEntities
                            entityIdsOrModels={risk.aggregatedEntityIds}
                            entityTypeName={Contract.TypeNames.GcpBigQueryDatasetTable}
                            namePluralizer={localization.datasetTables}
                            variant="itemCountAndType"/>
                }),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const datasetRiskContext = getGcpBigQueryDatasetRiskContext(datasetModel);
            return [
                datasetRiskContext.generalInformation,
                datasetRiskContext.sensitive,
                datasetRiskContext.accessLevel,
                new RiskDefinitionContextItem(
                    localization.contextItems.unencryptedTables({
                        datasetTables:
                            <InlineEntities
                                entityIdsOrModels={risk.aggregatedEntityIds}
                                entityTypeName={Contract.TypeNames.GcpBigQueryDatasetTable}
                                variant="itemCountAndType"/>,
                        translatedDatasetTypeName: entityTypeNameTranslator(
                            datasetModel.entity.typeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                    })),
                datasetRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}