import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { forwardRef, memo, Ref } from "react";

const CellErrorIconForwardRef = memo(forwardRef(CellErrorIcon));
export { CellErrorIconForwardRef as CellErrorIcon };

function CellErrorIcon(props: SvgIconProps, ref: Ref<SVGSVGElement>) {
    return (
        <SvgIcon
            ref={ref}
            {...props}>
            <path d="M12 1.74999C6.48385 1.74999 1.99999 6.23385 1.99999 11.75C1.99999 17.2661 6.48385 21.75 12 21.75C17.5161 21.75 22 17.2661 22 11.75C22 6.23385 17.5161 1.74999 12 1.74999ZM12 2.9633C16.8595 2.9633 20.7867 6.89052 20.7867 11.75C20.7867 16.6095 16.8595 20.5367 12 20.5367C7.14052 20.5367 3.2133 16.6095 3.2133 11.75C3.2133 6.89052 7.14052 2.9633 12 2.9633Z"/>
            <path
                clipRule="evenodd"
                d="M1.79999 11.75C1.79999 6.12339 6.3734 1.54999 12 1.54999C17.6266 1.54999 22.2 6.12339 22.2 11.75C22.2 17.3766 17.6266 21.95 12 21.95C6.3734 21.95 1.79999 17.3766 1.79999 11.75ZM20.5867 11.75C20.5867 7.00098 16.749 3.1633 12 3.1633C7.25098 3.1633 3.4133 7.00098 3.4133 11.75C3.4133 16.499 7.25098 20.3367 12 20.3367C16.749 20.3367 20.5867 16.499 20.5867 11.75ZM1.99999 11.75C1.99999 6.23385 6.48385 1.74999 12 1.74999C17.5161 1.74999 22 6.23385 22 11.75C22 17.2661 17.5161 21.75 12 21.75C6.48385 21.75 1.99999 17.2661 1.99999 11.75ZM20.7867 11.75C20.7867 6.89052 16.8595 2.9633 12 2.9633C7.14052 2.9633 3.2133 6.89052 3.2133 11.75C3.2133 16.6095 7.14052 20.5367 12 20.5367C16.8595 20.5367 20.7867 16.6095 20.7867 11.75Z"
                fillRule="evenodd"/>
            <path d="M11.9992 17.225C11.2122 17.225 10.5742 16.587 10.5742 15.8C10.5742 15.013 11.2122 14.375 11.9992 14.375C12.7862 14.375 13.4242 15.013 13.4242 15.8C13.4242 16.587 12.7862 17.225 11.9992 17.225Z"/>
            <path d="M11.6135 6.53538C11.0464 6.5355 10.5925 7.00649 10.6138 7.57319L10.7894 12.2303C10.8097 12.7676 11.2508 13.1926 11.7884 13.1926C11.8941 13.1926 12.0225 13.1926 12.2106 13.1926C12.7482 13.1926 13.1898 12.7676 13.21 12.2303L13.3856 7.57259C13.407 7.00612 12.9534 6.53508 12.3865 6.53516C12.1359 6.53519 11.939 6.53531 11.6135 6.53538Z"/>
        </SvgIcon>);
}