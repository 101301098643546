import { Link, Loading, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, Entity } from "../../../../../common";

export function useWorkloadAnalysisErrorTranslator() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useWorkloadAnalysisErrorTranslator",
            () => ({
                learnMore: {
                    csvExport: "Learn more at {{url}}",
                    normal: "Learn more"
                },
                resourceType: {
                    [Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine]: "virtual machine",
                    [Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage]: "virtual machine image"
                },
                [Contract.TypeNames.WorkloadAnalysisError]: {
                    [Contract.WorkloadAnalysisError.AccessDenied]: "This {{resourceType}} is not being scanned as there is an SCP that blocks Workload Protection permissions",
                    [Contract.WorkloadAnalysisError.AzureVirtualMachineDiskResourceGroupLocked]: "This {{resourceType}} has not been scanned because it resides in a resource group with a lock that prevents the deletion of snapshots.",
                    [Contract.WorkloadAnalysisError.AzureVirtualMachineResourceGroupDeleted]: "This {{resourceType}} has not been scanned because the configured snapshot resource group was deleted.",
                    [Contract.WorkloadAnalysisError.AzureVirtualMachineUnmanagedDisk]: "Scanning is not supported for this {{resourceType}} because the operating system disk is unmanaged",
                    [Contract.WorkloadAnalysisError.DiskMaxSizeExceeded]: "This {{resourceType}} is not being scanned since the disk exceeds the maximum size",
                    [Contract.WorkloadAnalysisError.DiskNetworkAccessDenied]: "This {{resourceType}} is not being scanned since outbound network access is blocked for snapshots related to this {{resourceType}}",
                    [Contract.WorkloadAnalysisError.DiskNotSupported]: "Scanning is not supported for this workload since the disk is an instance store, and not an EBS volume",
                    [Contract.WorkloadAnalysisError.DiskPartitionEncrypted]: "Scanning is not supported for this {{resourceType}} since the operating system encryption is enabled",
                    [Contract.WorkloadAnalysisError.DiskSupportedPartitionsNotFound]: "Scanning is not supported for this {{resourceType}} since the disk contains no supported partitions",
                    [Contract.WorkloadAnalysisError.KeyVaultMissingPermissions]: "This virtual machine is not being scanned due to missing Azure Key Vault permissions for vault {{azureKeyVault}}. {{learnMore}}",
                    [Contract.WorkloadAnalysisError.MissingPermissions]: "This {{resourceType}} is not being scanned due to missing Workload Protection permissions",
                    [Contract.WorkloadAnalysisError.VirtualMachineExcluded]: "This {{resourceType}} is excluded from scanning",
                    [Contract.WorkloadAnalysisError.VirtualMachineManaged]: "Scanning is not supported for this {{resourceType}} since it is fully managed by a cloud service",
                    [Contract.WorkloadAnalysisError.VirtualMachineProductCodeConfigurationNotSupported]: "This {{resourceType}} is not being scanned since it was launched from an AWS Marketplace AMI with unsupported instance specifications",
                    [Contract.WorkloadAnalysisError.VirtualMachineProductCodeNotSubscribed]: "Scanning this image requires an AWS marketplace subscription. Login to your dedicated AWS account used for in-account scanning, go to the AWS marketplace, find the relevant AMI, and click  'Continue to Subscribe'",
                    [Contract.WorkloadAnalysisError.VirtualMachineProductCodeRemoved]: "Scanning is not supported for this {{resourceType}} since it is based on an AWS image that has been removed from the marketplace. We recommend upgrading to a more recent image",
                    [Contract.WorkloadAnalysisError.VirtualMachineProductCodeSignInRequired]: "This {{resourceType}} is not being scanned since it was launched from an AWS Marketplace AMI, and no product ID was found",
                    [Contract.WorkloadAnalysisError.VirtualMachineProductCodeTypeNotSupported]: "Scanning is not supported for this {{resourceType}} since it's based on an Amazon DevPay image, which is a legacy service that no longer accepts new sellers or products",
                    [Contract.WorkloadAnalysisError.WorkloadAnalysisDisabled]: "Workload protection scanning is disabled"
                }
            }));

    return (
        resourceType: Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine | Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage,
        workloadAnalysisError: Optional<Contract.WorkloadAnalysisError>,
        azureKeyVaultId?: string,
        csvExport: boolean = false) =>
        workloadAnalysisError &&
        localization[Contract.TypeNames.WorkloadAnalysisError][workloadAnalysisError]({
            azureKeyVault:
                _.isNil(azureKeyVaultId)
                    ? ""
                    : csvExport
                        ? azureKeyVaultId
                        : <Loading>
                            <Entity
                                entityIdOrModel={azureKeyVaultId}
                                variant="text"/>
                        </Loading>,
            learnMore:
                csvExport
                    ? localization.learnMore.csvExport({
                        url: CustomerConsoleAppUrlHelper.getDocsOnboardAzureWorkloadProtectionPolicyRelativeUrl()
                    })
                    : <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAzureWorkloadProtectionPolicyRelativeUrl()}
                        variant="external">
                        {localization.learnMore.normal()}
                    </Link>,
            resourceType: localization.resourceType[resourceType]()
        });
}