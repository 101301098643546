﻿import { useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { useEnabledTranslator } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { EnabledRadioGroup } from "../components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

export function useGciDirectoryUserInactiveRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const enabledTranslator = useEnabledTranslator();

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useGciDirectoryUserInactiveRiskPolicyConfigurationDefinition",
            () => ({
                description: "Choose whether or not to create findings when the user is suspended or archived.",
                title: "Disabled Identities"
            }));

    const getEnabled =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.GciDirectoryUserInactiveRiskPolicyConfiguration).includeDisabledUser,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <EnabledRadioGroup
                    description={localization.description()}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    getEnabled={getEnabled}
                    key={`${Contract.GciDirectoryUserInactiveRiskPolicyConfiguration}-disabledUserEnabled`}
                    riskPoliciesType={riskPoliciesType}
                    setEnabled={
                        (riskPolicyConfiguration, includeDisabledUser) => {
                            (riskPolicyConfiguration as Contract.GciDirectoryUserInactiveRiskPolicyConfiguration).includeDisabledUser = includeDisabledUser;
                        }}
                    title={localization.title()}/>,
            viewItem: {
                getValue: riskPolicyConfiguration => enabledTranslator(getEnabled(riskPolicyConfiguration)),
                title: localization.title()
            }
        }]
    };
}