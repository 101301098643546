import { Loading } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ReportItem } from "../../../../../../../../../../common";
import { ComplianceReportItemProps, ComplianceSelector } from "./components";

export function useGetComplianceScopesReportItem() {
    return (complianceReportItemProps: ComplianceReportItemProps): ReportItem =>
        ({
            configuration:
                <Loading>
                    <ComplianceSelector {...complianceReportItemProps}/>
                </Loading>,
            generate:
                name =>
                    new Contract.ReportControllerComplianceScopesReportRequestDefinition(
                        name,
                        undefined,
                        Contract.TypeNames.ReportControllerComplianceScopesReportRequestDefinition,
                        complianceReportItemProps.complianceIdentifier!),
            notValid: () => _.isNil(complianceReportItemProps.complianceIdentifier)
        });
}