import { DialogMenuItem, Link, TimeFormatter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, InfoItem, tenantModelStore, usePrincipalModelAccess, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { GenerateGcpIdentityNonexcessiveRoleDialog } from "../../../../../../../../../../tenants";
import { useGciDirectoryUserStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoCard, EntitiesInfoItem, GciIdentityActivityInfoItem, Info, PrincipalSecurityStatsStat } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { GciDirectoryPrincipalGcpRoleBindings } from "../../components";
import { useGciCommonDirectoryPrincipalInfoItemElements } from "./useGciCommonDirectoryPrincipalInfoItemElements";
import { useGciDefaultEntityInfoItemElements } from "./useGciDefaultEntityInfoItemElements";

export function useGciDirectoryUserDefinition(entityModel: Contract.GciEntityModel, options?: DefinitionOptions) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const userModel = entityModel as Contract.GciDirectoryUserModel;
    const commonDirectoryPrincipalInfoItemElements = useGciCommonDirectoryPrincipalInfoItemElements(userModel);
    const defaultResourceInfoItemElements = useGciDefaultEntityInfoItemElements(entityModel);
    const user = userModel.entity as Contract.GciDirectoryUser;
    const userModelAccess = usePrincipalModelAccess<Contract.UserModelAccess>(userModel.id);

    const directoryUserStatusTranslator = useGciDirectoryUserStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciDirectoryUserDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        authorizationScopeToTokenCreationTimeMap: {
                            title: "Last GCP OAuth Tokens"
                        },
                        organizationalUnitId: "Organizational Unit",
                        signInTime: {
                            empty: "Not seen",
                            title: "Sign-in Time"
                        },
                        status: "Status"
                    }
                },
                tabs: {
                    gcpRoleBindings: "Bindings"
                },
                topbar: {
                    menu: {
                        generate: "Generate Custom Role"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GciDirectoryPrincipalGcpRoleBindings
                            csvExportFilePrefix={localization.tabs.gcpRoleBindings()}
                            gcpRoleBindingIds={userModel.gcpRoleBindingIds}
                            principalId={user.id}/>,
                        localization.tabs.gcpRoleBindings(),
                        "gcpRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonDirectoryPrincipalInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={user?.organizationalUnitId}
                        entityTypeName={Contract.TypeNames.GciDirectoryOrganizationalUnit}
                        key="organizationalUnitId"
                        location="all"
                        title={localization.info.items.organizationalUnitId()}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={
                            _.isNil(user.status)
                                ? undefined
                                : directoryUserStatusTranslator(user.status)}/>,
                    <InfoItem
                        emptyValue={localization.info.items.signInTime.empty()}
                        key="signInTime"
                        title={localization.info.items.signInTime.title()}
                        value={
                            _.isNil(user.signInTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(user.signInTime)}/>,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                userModel?.authorizationScopeToTokenCreationTimeMap,
                                (_, authorizationScope) => authorizationScope)}
                        key="authorizationScopeToTokenCreationTimeMap"
                        title={localization.info.items.authorizationScopeToTokenCreationTimeMap.title()}>
                        {authorizationScopeToTokenCreationTime => <Fragment>
                            <Link
                                urlOrGetUrl={authorizationScopeToTokenCreationTime}
                                variant="external"/>
                            <Typography sx={{ padding: "0px 0px 0px 4px" }}>
                                {TimeFormatter.profileFormatDateTime(userModel.authorizationScopeToTokenCreationTimeMap[authorizationScopeToTokenCreationTime])}
                            </Typography>
                        </Fragment>}
                    </VerticalTopItemsInfoItem>,
                    <GciIdentityActivityInfoItem
                        identityModel={userModel}
                        key="activityTime"/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={userModel.groupIds}
                    entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                _.isNil(userModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={userModel.analyzing?.reason}
                        analyzingTenants={
                            _.isNil(userModel.analyzing?.tenantIds)
                                ? undefined
                                : {
                                    ids: userModel.analyzing!.tenantIds,
                                    typeName: Contract.TypeNames.GcpTenantEntity
                                }}
                        securityStats={userModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.GciDirectoryUser}/>
            ]),
        topbarMenuItems:
            _.isNil(userModel.systemDeletionTime)
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateGcpIdentityNonexcessiveRoleDialog
                                identityModel={userModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}