import { DataTableActions, DataTableColumn, DataTableColumnRenderProps, ItemSelector, Loading, Optional, optionalTableCell, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Contract, Entity, entityModelStore, NetworkInboundAccessTypeCell, Region, Severity } from "../../../../../../../../../../../../../../../../common";
import { useAwsEc2InstanceStatusTranslator } from "../../../../../../../../../../../../../Entities/hooks";
import { Table } from "../../../../../../../../components";
import { NetworkAccessScopeCell } from "../../../../../../components";

type DetailsSectionProps = {
    riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel;
};

export function DetailsSection({ riskModel }: DetailsSectionProps) {
    const launchTemplateModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEc2LaunchTemplateModel;
    const launchTemplate = launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate;
    const [revisionIdToRiskedInstanceIdsMap, revisionIds] =
        useMemo(
            () => {
                const revisionIdToRiskedInstanceIdsMap =
                    _(launchTemplateModel.revisionIdToDataMap).
                        mapValues(revision => _.intersection(revision.instanceIds, riskModel.risk.aggregatedEntityIds)).
                        pickBy(instanceIds => !_.isEmpty(instanceIds)).
                        value();
                const revisionIds =
                    _(revisionIdToRiskedInstanceIdsMap).
                        keys().
                        map(Number).
                        value();
                return [revisionIdToRiskedInstanceIdsMap, revisionIds];
            },
            [launchTemplateModel]);
    const [selectedRevisionId, setSelectedRevisionId] = useState<number>(_.head(revisionIds)!);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskLaunchTemplateDefinition.detailsSection",
            () => ({
                selectedRevision: {
                    default: "Default : {{revisionId}}",
                    latest: "Latest : {{revisionId}}"
                }
            }));

    return (
        <Stack sx={{ overflow: "hidden auto" }}>
            <Box
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <ItemSelector
                    dense={true}
                    fullWidth={true}
                    items={revisionIds}
                    selectedItem={selectedRevisionId}
                    sorted={false}
                    onSelectedItemChanged={revisionId => setSelectedRevisionId(revisionId)}>
                    {(revisionId: number) =>
                        revisionId === launchTemplate.defaultRevisionId
                            ? localization.selectedRevision.default({ revisionId })
                            : revisionId === launchTemplate.latestRevisionId
                                ? localization.selectedRevision.latest({ revisionId })
                                : revisionId}
                </ItemSelector>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <Loading>
                    <InstanceTable
                        instanceIds={revisionIdToRiskedInstanceIdsMap[selectedRevisionId]}
                        riskModel={riskModel}/>
                </Loading>
            </Box>
        </Stack>);
}

type InstanceTableProps = {
    instanceIds: string[];
    riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel;
};

function InstanceTable({ instanceIds, riskModel }: InstanceTableProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    const instanceModels = entityModelStore.useGet(instanceIds) as Contract.AwsEc2InstanceModel[];

    useChangeEffect(
        () => dataTableActionsRef.current?.reset(),
        [instanceIds]);

    const tableItems =
        _.map(
            instanceModels,
            instanceModel =>
                new InstanceTableItem(
                    instanceModel,
                    riskModel.risk.launchTemplateInstanceIdToRolePermissionActionSeverityMap![instanceModel.entity.id]));

    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskLaunchTemplateDefinition.detailsSection.instanceTable",
            () => ({
                columns: {
                    instance: "Instance",
                    networkInboundAccessType: "Exposure",
                    networkInboundExternalAccessScope: "Exposure Scope",
                    region: "Region",
                    rolePermissionActionSeverity: "Permissions Severity",
                    status: "State"
                }
            }));
    return (
        <Table
            dataTableActionsRef={dataTableActionsRef}
            fetchItems={() => tableItems}
            getItemId={(item: InstanceTableItem) => item.instanceModel.id}>
            <DataTableColumn
                id={DetailsSectionColumnId.Instance}
                render={
                    ({ item }: DataTableColumnRenderProps<InstanceTableItem>) =>
                        <Entity
                            entityIdOrModel={item.instanceModel}
                            variant="iconTextTenant"/>}
                title={localization.columns.instance()}/>
            <DataTableColumn
                id={DetailsSectionColumnId.NetworkInboundAccessType}
                render={
                    ({ item }: DataTableColumnRenderProps<InstanceTableItem>) => (
                        <NetworkInboundAccessTypeCell
                            networkInboundAccessType={(item.instanceModel.entityNetwork as Optional<Contract.AwsNetworkedResourceStateNetwork>)?.inboundAccessType}
                            variant="risk"/>)}
                title={localization.columns.networkInboundAccessType()}/>
            <DataTableColumn
                id={DetailsSectionColumnId.NetworkInboundExternalAccessScope}
                render={
                    ({ item }: DataTableColumnRenderProps<InstanceTableItem>) =>
                        <NetworkAccessScopeCell item={item.instanceModel}/>}
                title={localization.columns.networkInboundExternalAccessScope()}/>
            <DataTableColumn
                id={DetailsSectionColumnId.Status}
                render={
                    optionalTableCell<InstanceTableItem>(
                        ({ instanceModel }) =>
                            instanceModel.unknown
                                ? undefined
                                : ec2InstanceStatusTranslator((instanceModel.entity as Contract.AwsEc2Instance).status))}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={DetailsSectionColumnId.RolePermissionActionSeverity}
                render={
                    ({ item }: DataTableColumnRenderProps<InstanceTableItem>) =>
                        <Severity severity={item.rolePermissionActionSeverity}/>}
                title={localization.columns.rolePermissionActionSeverity()}/>
            <DataTableColumn
                id={DetailsSectionColumnId.RegionSystemName}
                render={
                    ({ item }: DataTableColumnRenderProps<InstanceTableItem>) =>
                        <Region
                            regionId={item.instanceModel.entity.regionId}
                            variant="iconText"/>}
                title={localization.columns.region()}/>
        </Table>
    );
}

class InstanceTableItem {
    constructor(
        public instanceModel: Contract.AwsEc2InstanceModel,
        public rolePermissionActionSeverity: Contract.Severity | undefined) {
    }
}

enum DetailsSectionColumnId {
    Instance = "instance",
    NetworkInboundAccessType = "networkInboundAccessType",
    NetworkInboundExternalAccessScope = "networkInboundExternalAccessScope",
    RegionSystemName = "regionSystemName",
    RolePermissionActionSeverity = "rolePermissionActionSeverity",
    Status = "status"
}