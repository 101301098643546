import { Loading, useLocalization } from "@infrastructure";
import { Button, useTheme } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Contract, riskPolicyModelStore, SecretExclusionAction, UserHelper } from "../../../../../../../../../../../../../../common";
import { AwsEc2InstanceUserDataTextViewer } from "../../../../../../../../../../../../../../tenants";

type UserDataProps = {
    riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel;
    textBlock: Contract.TextBlock;
};

export function UserData({ riskModel, textBlock }: UserDataProps) {
    const { riskPolicyConfiguration } = riskPolicyModelStore.useGet(riskModel.risk.policyId);
    const [exclude, setExclude] = useState<boolean>(false);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.userData",
            () => ({
                excludeAction: "Exclude Secret"
            }));

    const theme = useTheme();
    return (
        <Fragment>
            <Loading>
                <Fragment>
                    {exclude &&
                            <SecretExclusionAction
                                riskPolicyConfiguration={riskPolicyConfiguration}
                                onExcludeChanged={setExclude}/>}
                    <AwsEc2InstanceUserDataTextViewer
                        actionsElement={
                            UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)
                                ? <Button
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: theme.palette.textEditor.background
                                        },
                                        backgroundColor: theme.palette.textEditor.background,
                                        margin: theme.spacing(1),
                                        padding: theme.spacing(1, 3)
                                    }}
                                    variant="outlined"
                                    onClick={() => setExclude(true)}>
                                    {localization.excludeAction()}
                                </Button>
                                : undefined}
                        actionsElementSx={{ backgroundColor: "unset" }}
                        highlightedLines={textBlock.highlightedLines}
                        startLine={textBlock.startLine}
                        userData={textBlock.text}/>
                </Fragment>
            </Loading>
        </Fragment>);
}