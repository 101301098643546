import { DataTableColumn, DataTableSortType, EnumValuesFilter, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsEksClusterStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesClusterDefinition } from "../useCommonKubernetesClusterDefinition";

export function useAwsEksClusterDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonKubernetesClusterDefinition = useCommonKubernetesClusterDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const eksClusterStatusTranslator = useAwsEksClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEksClusterDefinition",
            () => ({
                columns: {
                    status: "Status",
                    url: "Endpoint",
                    version: "Kubernetes Version"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEksClusterModel) => ({
                            [localization.columns.status()]: eksClusterStatusTranslator((item.entity as Contract.AwsEksCluster).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsEksClusterStatus}
                                enumTypeTranslator={eksClusterStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEksClusterStatus}
                itemProperty={(item: Contract.EntityModel) => eksClusterStatusTranslator((item.entity as Contract.AwsEksCluster).status)}
                key={Contract.EntityModelProperty.AwsEksClusterStatus}
                title={localization.columns.status()}/>,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEksClusterModel) => ({
                            [localization.columns.version()]: (item.entity as Contract.AwsEksCluster).version
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.KubernetesClusterVersion)}
                                placeholder={localization.columns.version()}/>
                    }
                }}
                id={Contract.EntityModelProperty.KubernetesClusterVersion}
                itemProperty={(item: Contract.AwsEksClusterModel) => (item.entity as Contract.AwsEksCluster).version}
                key={Contract.EntityModelProperty.KubernetesClusterVersion}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.version()}/>,
            commonKubernetesClusterDefinition.columns.createApiConnectivityStatusColumn(CustomerConsoleAppUrlHelper.getDocsOnboardAwsEksClusterGrantPermissionsRelativeUrl()),
            commonKubernetesClusterDefinition.columns.resourceUpdateTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEksClusterModel) => ({
                            [localization.columns.url()]:
                                _.isNil((item.entity as Contract.AwsEksCluster).url)
                                    ? ""
                                    : (item.entity as Contract.AwsEksCluster).url
                        })
                }}
                id="url"
                key="url"/>,
            commonNetworkedResourceDefinition.columns.getVpcsColumn(),
            commonNetworkedResourceDefinition.columns.securityGroupsColumn,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEksClusterRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEksClusterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesClusterApiConnectivityStatus]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.KubernetesClusterResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEksClusterStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesClusterVersion])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}