import { Optional } from "@infrastructure";
import { Dictionary } from "lodash";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useGciCommonDirectoryPartialPrincipalDefinition } from "./useGciCommonDirectoryPartialPrincipalDefinition";

export function useGciDirectoryPartialGroupDefinition(definitionData: DefinitionData) {
    const commonDirectoryPartialPrincipalDefinition = useGciCommonDirectoryPartialPrincipalDefinition(definitionData);
    return new EntityTableDefinition(
        [
            commonDirectoryPartialPrincipalDefinition.columns.gcpRoleBindingIdsColumn,
            commonDirectoryPartialPrincipalDefinition.columns.gcpRoleIdsFilterColumn,
            commonDirectoryPartialPrincipalDefinition.columns.containedGcpRoleBindingIdsColumn,
            commonDirectoryPartialPrincipalDefinition.columns.attributesColumn,
            commonDirectoryPartialPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryPartialPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryPartialPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGciDirectoryPartialGroupRequest(
                new Contract.EntityControllerGetEntityModelPageGciDirectoryPartialGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    filterMap[Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryPartialPrincipalRoles])),
                limit,
                skip,
                requestSort));
}