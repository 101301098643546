import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, EntityTypeNameTranslatorOptions, TypeHelper } from "../../../../../../../../../../common";
import { useAwsResourceGeneralInformationStep, useAzureResourceGeneralInformationStep, useGcpResourceGeneralInformationStep, useOciResourceGeneralInformationStep, useOpResourceGeneralInformationStep, useUnmanagedKubernetesClusterGeneralInformationStep } from "./hooks";

export function useResourceGeneralInformationStep(resourceModel: Contract.EntityModel, resourceTypeNameTranslationOptions?: EntityTypeNameTranslatorOptions) {
    const resourceModelTypeName = resourceModel.typeName;
    const useDefinition =
        useMemo(
            () => {
                if (resourceModel.unknown) {
                    return () => undefined;
                }

                if (TypeHelper.extendOrImplement(resourceModelTypeName, Contract.TypeNames.IUnmanagedKubernetesClusterModel)) {
                    return useUnmanagedKubernetesClusterGeneralInformationStep;
                }

                if (TypeHelper.extendOrImplement(resourceModelTypeName, Contract.TypeNames.AwsResourceModel)) {
                    return useAwsResourceGeneralInformationStep;
                } else if (TypeHelper.extendOrImplement(resourceModelTypeName, Contract.TypeNames.AzureResourceModel)) {
                    return useAzureResourceGeneralInformationStep;
                } else if (TypeHelper.extendOrImplement(resourceModelTypeName, Contract.TypeNames.CiEntityModel)) {
                    return () => undefined;
                } else if (TypeHelper.extendOrImplement(resourceModelTypeName, Contract.TypeNames.GcpEntityModel)) {
                    return useGcpResourceGeneralInformationStep;
                } else if (TypeHelper.extendOrImplement(resourceModelTypeName, Contract.TypeNames.OciResourceModel)) {
                    return useOciResourceGeneralInformationStep;
                } else if (TypeHelper.extendOrImplement(resourceModelTypeName, Contract.TypeNames.OpResourceModel)) {
                    return useOpResourceGeneralInformationStep;
                } else {
                    throw new UnexpectedError(resourceModelTypeName);
                }
            },
            []);

    return useDefinition(resourceModel, resourceTypeNameTranslationOptions);
}