import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAzureStorageStorageAccountBlobContainerPublicAccessTranslator() {
    const localization =
        useLocalization(
            "tenants.azure.hooks.useAzureStorageStorageAccountBlobContainerPublicAccessTranslator",
            () => ({
                [Contract.TypeNames.AzureStorageStorageAccountBlobContainerPublicAccess]: {
                    [Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Blob]: "Blob",
                    [Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Container]: "Container",
                    [Contract.AzureStorageStorageAccountBlobContainerPublicAccess.None]: "Private"
                }
            }));
    return (azureStorageStorageAccountBlobContainerPublicAccess: Contract.AzureStorageStorageAccountBlobContainerPublicAccess) =>
        localization[Contract.TypeNames.AzureStorageStorageAccountBlobContainerPublicAccess][azureStorageStorageAccountBlobContainerPublicAccess]();
}