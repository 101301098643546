import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { forwardRef, memo, Ref } from "react";

const SuccessIconForwardRef = memo(forwardRef(SuccessIcon));
export { SuccessIconForwardRef as SuccessIcon };

function SuccessIcon(props: SvgIconProps, ref: Ref<SVGSVGElement>) {
    const theme = useTheme();
    return (
        <SvgIcon
            ref={ref}
            {...props}>
            <g clipPath="url(#clip0_53999_152682)">
                <path
                    d="M0.50007 11.92C0.55007 5.64 5.69007 0.5 12.0001 0.5C18.4101 0.55 23.5501 5.74 23.5001 12.08C23.4501 18.36 18.3101 23.5 12.0001 23.5H11.9201C8.85007 23.47 5.97007 22.27 3.82007 20.08C1.65007 17.9 0.47007 14.99 0.50007 11.92Z"
                    fill="#64C9BB"/>
                <path
                    d="M10.2076 17C9.81067 17 9.45153 16.8434 9.17745 16.567L6.33266 13.6467C5.88846 13.1953 5.88846 12.486 6.33266 12.0345C6.78632 11.5739 7.61801 11.5739 8.07167 12.0345L10.2076 14.2271L15.9274 8.34546C16.381 7.88485 17.2127 7.88485 17.6664 8.34546C18.1106 8.79687 18.1106 9.50622 17.6664 9.95762L11.2378 16.567C10.9637 16.8434 10.5951 17 10.2076 17Z"
                    fill={theme.palette.background.alternate}/>
            </g>
            <defs>
                <clipPath id="clip0_53999_152682">
                    <rect
                        fill={theme.palette.background.alternate}
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}