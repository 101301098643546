import { Link, SearchTextField, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, LogoTextIcon, Topbar as CommonTopbar, topbarHeight, useTheme } from "../../../../common";
import { avatarSize } from "../../Topbar/components";

type TopbarProps = {
    consoleAppType: Contract.ConsoleAppType;
    onSearchTextChanged: (searchText: string) => void;
};

const logoWidth = 194;

export function Topbar({ consoleAppType, onSearchTextChanged }: TopbarProps) {
    const [searchText, setSearchText] = useState("");
    useChangeEffect(
        () => {
            onSearchTextChanged(
                searchText.
                    toLowerCase().
                    trim());
        },
        [searchText],
        500);

    const localization =
        useLocalization(
            "common.customers.topbar",
            () => ({
                textPlaceholder: "Search customers"
            }));
    const theme = useTheme();
    return (
        <CommonTopbar
            consoleAppType={consoleAppType}
            customerContext={false}>
            <Stack
                alignItems="center"
                direction="row"
                sx={{ height: theme.px(topbarHeight - 1) }}>
                <Link
                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getRootRelativeUrl()}
                    variant="text">
                    <LogoTextIcon
                        sx={{
                            height: "22px",
                            width: theme.px(logoWidth)
                        }}/>
                </Link>
                <Box sx={{ flex: 5 }}>
                    <Box
                        sx={{
                            margin: theme.spacing(0, "auto"),
                            maxWidth: theme.spacing(54),
                            minWidth: theme.spacing(29),
                            paddingLeft: theme.spacing(2)
                        }}>
                        <SearchTextField
                            inputSx={{ borderRadius: theme.spacing(0.75) }}
                            placeholder={localization.textPlaceholder()}
                            searchText={searchText}
                            variant="outlined"
                            onSearchTextChanged={searchText => setSearchText(searchText ?? "")}/>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        maxWidth: theme.px(logoWidth - avatarSize)
                    }}/>
            </Stack>
        </CommonTopbar>);
}