﻿import { useLocalization } from "@infrastructure";

export function useAwsUpsertVpcEndpointServicePolicyChangeDefinition() {
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsUpsertVpcEndpointServicePolicyChangeDefinition",
            () => ({
                title: "Update the vpc endpoint policy"
            }));
    return {
        title: localization.title()
    };
}