import { Link, Message, useLocalization } from "@infrastructure";
import React from "react";
import { UserHelper } from "../../../../../common";

export function IrisWarning() {
    const localization =
        useLocalization(
            "views.customer.administration.irisWarning",
            () => ({
                title: "IRIS",
                warning: "Changes made here may be overridden by {{IrisLink}} if they don't match license records"
            }));

    return (
        <Message
            level="warning"
            title={
                localization.warning({
                    IrisLink:
                        <Link
                            urlOrGetUrl={`https://iris.tenablesecurity.com/containers/${UserHelper.customer?.tenableId}`}
                            variant="external">
                            {localization.title()}
                        </Link>
                })}/>);
}