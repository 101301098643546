import { Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { AnalyticsEventActionType, ApplicationViewValue, Dialog, EventHandlerRegister, Loading, makeContextProvider, useEvent, useExecuteOperation, useLocalization, useRoute, useSetRoute, useTrackAnalyticsEvent } from "@infrastructure";
import { UserView } from "../..";
import { Contract, PermissionManagementController, scopeSystemEntityModelStore, useLayoutOptions, useTheme } from "../../../../common";
import { Add, Table } from "./components";

export class GranteeUserPermissionRequestsContext {
    constructor(
        public addOpen: boolean | Contract.PermissionRequestModel,
        public getFiltersResponse: Contract.PermissionManagementControllerGetGranteeUserPermissionRequestFiltersResponse,
        public notDeletedPermissionEligibilityIdSet: Set<string>,
        public refreshFilters: () => Promise<void>,
        public registerPermissionRequestModelChange: EventHandlerRegister<() => Promise<void>>,
        public triggerPermissionRequestModelChange: () => Promise<void>) {
    }
}

export enum GranteeUserPermissionRequestsViewType {
    Active = "active",
    History = "history"
}

export const [useGranteeUserPermissionRequestsContext, useSetGranteeUserPermissionRequestsContext, useGranteeUserPermissionRequestsContextProvider] = makeContextProvider<GranteeUserPermissionRequestsContext>();


type GranteeUserPermissionRequestsProps = {
    applicationView: ApplicationViewValue;
};

export function GranteeUserPermissionRequests({ applicationView }: GranteeUserPermissionRequestsProps) {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests",
            () => ({
                tabs: {
                    active: "Active",
                    history: "History"
                },
                title: "Access Requests"
            }));
    useLayoutOptions({ view: { title: localization.title() } });
    const { view } = useRoute(`/${applicationView}/${UserView.GranteeUserPermissionRequests}/{view}`);
    const setRoute = useSetRoute();
    const orderedTabs =
        useMemo(
            () => [
                GranteeUserPermissionRequestsViewType.Active,
                GranteeUserPermissionRequestsViewType.History
            ],
            []);

    const currentView =
        useMemo(
            () =>
                _.includes(orderedTabs, view)
                    ? view as GranteeUserPermissionRequestsViewType
                    : _.first(orderedTabs),
            [orderedTabs, view]);

    const [getFiltersResponse, executeGetFilters] =
        useExecuteOperation(
            [GranteeUserPermissionRequests, PermissionManagementController.getGranteeUserPermissionRequestFilters],
            PermissionManagementController.getGranteeUserPermissionRequestFilters);

    const permissionEligibilityModels = scopeSystemEntityModelStore.useGetPermissionEligibility();
    const [registerPermissionRequestModelChange, triggerPermissionRequestModelChange] = useEvent<() => Promise<void>>();
    const [{ addOpen }, setContext, ContextProvider] =
        useGranteeUserPermissionRequestsContextProvider(
            () =>
                new GranteeUserPermissionRequestsContext(
                    false,
                    getFiltersResponse,
                    new Set(
                        _(permissionEligibilityModels).
                            filter(permissionEligibilityModel => !permissionEligibilityModel.configuration.systemDeleted).
                            map(permissionEligibilityModel => permissionEligibilityModel.configuration.id).
                            value()),
                    executeGetFilters,
                    registerPermissionRequestModelChange,
                    triggerPermissionRequestModelChange),
            []);

    useEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    getFiltersResponse,
                    notDeletedPermissionEligibilityIdSet:
                        new Set(
                            _(permissionEligibilityModels).
                                filter(permissionEligibilityModel => !permissionEligibilityModel.configuration.systemDeleted).
                                map(permissionEligibilityModel => permissionEligibilityModel.configuration.id).
                                value())
                }));
        },
        [getFiltersResponse, permissionEligibilityModels]);

    const theme = useTheme();
    return (
        <ContextProvider>
            {addOpen !== false &&
                <Dialog
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOpen: false
                                }))}>
                    <Add/>
                </Dialog>}
            <Stack sx={{ height: "100%" }}>
                <Tabs
                    indicatorColor="secondary"
                    sx={{
                        border: "unset",
                        padding: theme.spacing(0, 2)
                    }}
                    value={currentView}
                    variant="scrollable"
                    onChange={(_event, view) => setRoute(`/${applicationView}/${UserView.GranteeUserPermissionRequests}/${view}`)}>
                    {_.map(
                        orderedTabs,
                        tab =>
                            <Tab
                                key={tab}
                                label={localization.tabs[tab]()}
                                sx={{
                                    marginRight: theme.spacing(3),
                                    padding: 0
                                }}
                                value={tab}/>)}
                </Tabs>
                <Stack
                    sx={{
                        height: "100%",
                        overflow: "hidden auto",
                        width: "100%"
                    }}>
                    <Loading key={currentView}>
                        {currentView && <Table viewType={currentView}/>}
                    </Loading>
                </Stack>
            </Stack>
        </ContextProvider>);
}