import { Action1, ConcealIcon, RevealIcon, useChangeEffect } from "@infrastructure";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React, { forwardRef, Ref, useState } from "react";

const PasswordTextFieldForwardRef = forwardRef(PasswordTextField);
export { PasswordTextFieldForwardRef as PasswordTextField };

type PasswordTextFieldProps = TextFieldProps & {
    onPasswordChanged?: Action1<string | undefined>;
    password?: string;
};

function PasswordTextField({ InputProps, onPasswordChanged, password, ...props }: PasswordTextFieldProps, ref: Ref<HTMLInputElement>) {
    const [passwordValue, setPasswordValue] = useState<string | undefined>(password);

    useChangeEffect(
        () => {
            setPasswordValue(password);
        },
        [password]);
    useChangeEffect(
        () => {
            onPasswordChanged?.(passwordValue);
        },
        [passwordValue]);

    const [focused, setFocused] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    return (
        <form
            role="input"
            style={{ display: "contents" }}
            onSubmit={event => event.preventDefault()}>
            <TextField
                ref={ref}
                slotProps={{
                    input: {
                        endAdornment:
                            passwordValue
                                ? <InputAdornment position="end">
                                    <IconButton
                                        size="large"
                                        sx={{ fontSize: "18px" }}
                                        onClick={() => setPasswordVisible(!passwordVisible)}>
                                        {passwordVisible
                                            ? <ConcealIcon/>
                                            : <RevealIcon/>}
                                    </IconButton>
                                </InputAdornment>
                                : undefined,
                        ...InputProps
                    }
                }}
                type={
                    passwordVisible
                        ? "text"
                        : "password"}
                value={
                    focused && !InputProps?.readOnly
                        ? passwordValue ?? ""
                        : passwordValue ?? "********"}
                variant="standard"
                onBlur={() => setFocused(false)}
                onChange={event => setPasswordValue(event.target.value)}
                onFocus={() => setFocused(true)}
                {...props}/>
        </form>);
}