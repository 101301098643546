import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useCommonEntityNetworkInfoItemElements } from "../useCommonEntityNetworkInfoItemElements";

interface AwsCommonNetworkingInfoItemElementsProps {
    elasticIpIds?: string[];
    loadBalancerIdReferences?: string[];
    macAddress?: string;
    networkInterfaceIds?: string[];
    privateIpAddresses?: string[];
    publicIpAddresses?: string[];
    resourceModel: Contract.AwsResourceModel;
}

export function useAwsCommonNetworkingInfoItemElements({ elasticIpIds, loadBalancerIdReferences, macAddress, networkInterfaceIds, privateIpAddresses, publicIpAddresses, resourceModel }: AwsCommonNetworkingInfoItemElementsProps) {
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceModel);
    const networkedResource = _.as<Contract.IAwsNetworkedResource>(resourceModel.entity);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonNetworkingInfoItemElements",
            () => ({
                ipAddresses: {
                    private: "Private IP Address",
                    public: "Public IP Address"
                },
                macAddress: "MAC Address"
            }));

    return {
        ...commonEntityNetworkInfoItemElements,
        getElasticIps:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={elasticIpIds}
                    entityTypeName={Contract.TypeNames.AwsEc2ElasticIp}
                    key={Contract.TypeNames.AwsEc2ElasticIp}/>,
        getLoadBalancers:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={loadBalancerIdReferences}
                    entityTypeName={Contract.TypeNames.AwsElbLoadBalancer}
                    key={Contract.TypeNames.AwsElbLoadBalancer}/>,
        getMacAddress:
            () =>
                <InfoItem
                    key="macAddress"
                    title={localization.macAddress()}
                    value={macAddress}/>,
        getNetworkInterfaces:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkInterfaceIds}
                    entityTypeName={Contract.TypeNames.AwsEc2NetworkInterface}
                    key={Contract.TypeNames.AwsEc2NetworkInterface}/>,
        getPrivateIpAddresses:
            () =>
                <VerticalTopItemsInfoItem
                    items={privateIpAddresses}
                    key="privateIpAddress"
                    title={localization.ipAddresses.private()}/>,
        getPublicIpAddresses:
            () =>
                <VerticalTopItemsInfoItem
                    items={publicIpAddresses}
                    key="publicIpAddress"
                    title={localization.ipAddresses.public()}/>,
        getSecurityGroups:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource.securityGroupIds}
                    entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                    key={Contract.TypeNames.AwsEc2SecurityGroup}/>,
        getSubnets:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource.subnetIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                    key={Contract.TypeNames.AwsEc2Subnet}/>,
        getVpcs:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource.vpcIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                    key={Contract.TypeNames.AwsEc2Vpc}/>
    };
}