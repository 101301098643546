import { ItemSelector, TimeFormatter, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, TypeMetadataHelper } from "../../..";

type WeeklyProps = {
    hour: number;
    onChange: (hour: number, weekDay: Contract.DayOfWeek) => void;
    weekDay: Contract.DayOfWeek;
};

export function Weekly({ hour, onChange, weekDay }: WeeklyProps) {
    const [weekDays, weekDayToValueMap] =
        useMemo(
            () => {
                const weekDays = [
                    Contract.DayOfWeek.Monday,
                    Contract.DayOfWeek.Tuesday,
                    Contract.DayOfWeek.Wednesday,
                    Contract.DayOfWeek.Thursday,
                    Contract.DayOfWeek.Friday,
                    Contract.DayOfWeek.Saturday
                ];
                return [
                    TimeFormatter.weekStartDay() === 0
                        ? [Contract.DayOfWeek.Sunday, ...weekDays]
                        : [...weekDays, Contract.DayOfWeek.Sunday],
                    TypeMetadataHelper.getTypeMetadata(Contract.TypeNames.DayOfWeek).nameToValueMap!
                ];
            },
            []);

    const localization =
        useLocalization(
            "common.report.weekly",
            () => ({
                at: "at",
                every: {
                    title: "Every",
                    value: "{{day | TimeFormatter.humanizeWeekDay}}"
                },
                utc: "UTC"
            }));
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.every.title()}
            </Typography>
            <ItemSelector
                fieldVariant="underline"
                items={weekDays}
                selectedItem={weekDay}
                sorted={false}
                onSelectedItemChanged={weekDay => onChange(hour, weekDay)}>
                {weekDay =>
                    <Typography
                        noWrap={true}
                        sx={{ width: "100%" }}>
                        {localization.every.value({ day: weekDayToValueMap[weekDay] })}
                    </Typography>}
            </ItemSelector>
            <Typography>
                {localization.at()}
            </Typography>
            <ItemSelector
                fieldVariant="underline"
                items={_.range(0, 24)}
                selectedItem={hour}
                sorted={false}
                onSelectedItemChanged={hour => onChange(hour, weekDay)}>
                {hour =>
                    <Typography
                        noWrap={true}
                        sx={{ width: "100%" }}>
                        {TimeFormatter.hour(hour)}
                    </Typography>}
            </ItemSelector>
            <Typography>
                {localization.utc()}
            </Typography>
        </Stack>);
}