import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetAadDirectoryEntityRiskContext } from "./useGetAadDirectoryEntityRiskContext";

export function useGetAadTenantEntityRiskContext() {
    return useMemo(
        () => useAadTenantEntityRiskContext,
        []);
}

function useAadTenantEntityRiskContext(tenantEntityModel: Contract.AadTenantEntityModel) {
    return useGetAadDirectoryEntityRiskContext()(tenantEntityModel);
}