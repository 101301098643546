import { SxProps } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { StringHelper } from "@infrastructure";
import { Contract, Scope, ScopeHelper, scopeNodeModelStore, Tree, TreeItem, useScopeNameTranslator, useTheme } from "../..";

type ScopeTreeProps = {
    children?: (scopeNodeModelItem: TreeItem<Contract.ScopeNodeModel>) => ReactNode;
    containerSx?: SxProps;
    expandOnScopeClick?: boolean;
    includeEmptyFolders?: boolean;
    includeProjects?: boolean;
    isExpanded?: (treeItem: TreeItem<Contract.ScopeNodeModel>) => boolean;
    itemHover?: boolean;
    onFilteredScopeIdsChanged?: (filteredScopeIds?: string[]) => void;
    onScopeClick?: (scopeNodeModelItem: TreeItem<Contract.ScopeNodeModel>) => void;
    rootFolderId?: string;
    scopeIds: string[];
    selectedItemId?: string;
    treeSx?: SxProps;
};

export function ScopeTree({ children, containerSx, expandOnScopeClick = true, includeEmptyFolders = false, includeProjects, isExpanded, itemHover = true, onFilteredScopeIdsChanged, onScopeClick, rootFolderId, scopeIds, selectedItemId, treeSx }: ScopeTreeProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(undefined, includeProjects);
    const scopeNodeModels = scopeNodeModelStore.useGet(scopeIds);
    const rootScopeItem =
        useMemo(
            () => {
                const scopeTree =
                    ScopeHelper.getScopeTree(
                        scopeNodeMap,
                        _.map(
                            scopeNodeModels,
                            scopeNodeModel => scopeNodeModel.configuration.id),
                        includeProjects,
                        includeEmptyFolders);

                function createItem(scopeId: string): TreeItem<Contract.ScopeNodeModel> {
                    const scopeNode = scopeTree[scopeId];

                    const items =
                        _(scopeNode.scopeNodes).
                            orderBy([
                                scopeNode => !ScopeHelper.isFolder(scopeNode.scopeNodeModel),
                                scopeNode => StringHelper.getSortValue(scopeNode.scopeNodeModel.configuration.name)
                            ]).
                            map(scopeNode => createItem(scopeNode.scopeNodeModel.configuration.id)).
                            filter().
                            as<TreeItem<Contract.ScopeNodeModel>>().
                            value();

                    return new TreeItem(
                        scopeNode.scopeNodeModel,
                        items);
                }

                return createItem(rootFolderId ?? ScopeHelper.customerId);

            },
            [scopeNodeModels, scopeNodeMap]);

    const scopeNameTranslator = useScopeNameTranslator();

    const theme = useTheme();
    return (
        <Tree
            containerSx={containerSx}
            expandOnItemClick={expandOnScopeClick}
            getItemSx={
                scopeNodeModelItem =>
                    ({
                        background:
                            selectedItemId === scopeNodeModelItem.value.configuration.id
                                ? theme.important(theme.palette.action.selected)
                                : "inherit"
                    })}
            getSearchableTexts={
                scopeNodeModelItem => {
                    const scopeNodeModelConfiguration = (scopeNodeModelItem.value.configuration as Contract.CloudProviderTenantFolderConfiguration);
                    return _<string>([]).
                        concat(StringHelper.normalize(scopeNameTranslator(scopeNodeModelConfiguration.id))).
                        concatIf(
                            !ScopeHelper.isFolder(scopeNodeModelItem.value),
                            () => StringHelper.normalize(scopeNodeModelConfiguration.id)).
                        concatIf(
                            ScopeHelper.isFolder(scopeNodeModelItem.value) &&
                            !_.isNil(scopeNodeModelConfiguration.organization?.folderRawId),
                            () => StringHelper.normalize(scopeNodeModelConfiguration.organization!.folderRawId!)).
                        value();
                }}
            isExpanded={
                scopeNodeModelItem =>
                    scopeNodeModelItem.value.type === Contract.ScopeType.Customer ||
                    ScopeHelper.isRootFolder(scopeNodeModelItem.value) ||
                    !!isExpanded?.(scopeNodeModelItem)}
            itemHover={itemHover}
            orderBy={
                [
                    scopeNodeModelItem => !ScopeHelper.isProjectScope(scopeNodeModelItem.value),
                    scopeNodeModelItem => !ScopeHelper.isCloudProviderTenantsScope(scopeNodeModelItem.value),
                    scopeNodeModelItem => !ScopeHelper.isIdentityProviderTenantsScope(scopeNodeModelItem.value),
                    scopeNodeModelItem => !ScopeHelper.isFolder(scopeNodeModelItem.value),
                    scopeNodeModelItem => StringHelper.getSortValue(scopeNodeModelItem.value.configuration.name)
                ]}
            rootItem={rootScopeItem}
            spacing={0}
            treeSx={treeSx}
            onFilteredItemsChanged={
                scopeNodeModelItems =>
                    onFilteredScopeIdsChanged?.(
                        _.map(
                            scopeNodeModelItems,
                            scopeNodeModelItem => scopeNodeModelItem.value.configuration.id))}
            onTreeItemClick={scopeNodeModelItem => onScopeClick?.(scopeNodeModelItem)}>
            {
                scopeNodeModelItem =>
                    children?.(scopeNodeModelItem) ??
                    <Scope
                        scopeId={scopeNodeModelItem.value.configuration.id}
                        variant="text"/>
            }
        </Tree>);
}