import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, EntityTypeNameTranslatorOptions, RegionModelHelper, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { GcpLocationHelper } from "../../../../../../../../../../../tenants";

export function useGcpResourceGeneralInformationStep(entityModel: Contract.EntityModel, resourceTypeNameTranslationOptions?: EntityTypeNameTranslatorOptions) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceGeneralInformationStep.hooks.useGcpResourceGeneralInformationStep",
            () => ({
                creationTime: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}}",
                creationTimeAndCreator: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}} by {{creatorEntity}}",
                creationTimeAndCreatorAndRegion:
                    {
                        global: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}} by {{creatorEntity}} and is a global resource",
                        nonGlobal: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}} by {{creatorEntity}} and is located at {{region}}"
                    },
                creationTimeAndRegion:
                    {
                        global: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}} and is a global resource",
                        nonGlobal: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}} and is located at {{region}}"
                    },
                creator: "*capitalize*{{resourceTypeName}}** {{resource}} was created by {{creatorEntity}}",
                creatorAndRegion:
                    {
                        global: "*capitalize*{{resourceTypeName}}** {{resource}} was created by {{creatorEntity}} and is a global resource",
                        nonGlobal: "*capitalize*{{resourceTypeName}}** {{resource}} was created by {{creatorEntity}} and is located at {{region}}"
                    },
                region:
                    {
                        global: "*capitalize*{{resourceTypeName}}** {{resource}} is a global resource",
                        nonGlobal: "*capitalize*{{resourceTypeName}}** {{resource}} is located at {{region}}"
                    }
            }));
    return useMemo(
        () => {
            const gcpEntityModel = entityModel as Contract.GcpEntityModel;
            const gcpResourceModel =
                TypeHelper.extendOrImplement(gcpEntityModel.typeName, Contract.TypeNames.GcpResourceModel)
                    ? gcpEntityModel as Contract.GcpResourceModel
                    : undefined;


            const region =
                TypeHelper.extendOrImplement(gcpEntityModel.entity.typeName, Contract.TypeNames.GcpScopeResource)
                    ? RegionModelHelper.tryGet(gcpEntityModel.entity.regionId)?.name
                    : undefined;

            const resourceLocalizationOptions = {
                creationTime: gcpEntityModel.creationTime,
                creatorEntity:
                    _.isNil(gcpResourceModel) ||
                    _.isNil(gcpResourceModel.creatorEntityIdReference)
                        ? undefined
                        : <Entity
                            entityIdOrModel={gcpResourceModel.creatorEntityIdReference}
                            variant="text"/>,
                region,
                resource:
                    <Entity
                        entityIdOrModel={gcpEntityModel}
                        variant="text"/>,
                resourceTypeName:
                    entityTypeNameTranslator(
                        gcpEntityModel.entity.typeName,
                        {
                            includeServiceName: false,
                            variant: "text",
                            ...resourceTypeNameTranslationOptions
                        })
            };
            const hasCreationTime = !_.isNil(gcpEntityModel.creationTime);
            const hasCreator = !_.isNil(gcpResourceModel) && !_.isNil(gcpResourceModel.creatorEntityIdReference);
            const hasRegion = !_.isNil(region);
            const hasGlobalRegion = hasRegion && GcpLocationHelper.isGlobal(region!);
            if (hasCreationTime && hasCreator && hasRegion) {
                return hasGlobalRegion
                    ? localization.creationTimeAndCreatorAndRegion.global(resourceLocalizationOptions)
                    : localization.creationTimeAndCreatorAndRegion.nonGlobal(resourceLocalizationOptions);
            } else if (hasCreationTime && hasCreator) {
                return localization.creationTimeAndCreator(resourceLocalizationOptions);
            } else if (hasCreationTime && hasRegion) {
                return hasGlobalRegion
                    ? localization.creationTimeAndRegion.global(resourceLocalizationOptions)
                    : localization.creationTimeAndRegion.nonGlobal(resourceLocalizationOptions);
            } else if (hasCreator && hasRegion) {
                return hasGlobalRegion
                    ? localization.creatorAndRegion.global(resourceLocalizationOptions)
                    : localization.creatorAndRegion.nonGlobal(resourceLocalizationOptions);
            } else if (hasCreationTime) {
                return localization.creationTime(resourceLocalizationOptions);
            } else if (hasCreator) {
                return localization.creator(resourceLocalizationOptions);
            } else if (hasRegion) {
                return hasGlobalRegion
                    ? localization.region.global(resourceLocalizationOptions)
                    : localization.region.nonGlobal(resourceLocalizationOptions);
            } else {
                return undefined;
            }
        },
        [entityModel]);
}