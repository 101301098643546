import { DataTableColumn, DataTableSortType, DeferredFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function useOpCommonResourceDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);
    const resourceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.OpResourceModelFilters>;

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.op.useOpCommonResourceDefinition",
            () => ({
                columns: {
                    creationTime: "Creation Time"
                }
            }));
    return {
        columns:
            {
                ...commonEntityDefinition.columns,
                creationTimeColumn:
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.OpResourceModel) => ({
                                    [localization.columns.creationTime()]:
                                        _.isNil(item.creationTime)
                                            ? ""
                                            : TimeFormatter.iso8601String(item.creationTime)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={resourceModelFiltersPromise}
                                        title={localization.columns.creationTime()}>
                                        {filters =>
                                            <TimeRangeFilter
                                                emptyValue={filters.creationTimeRange.emptyValue}
                                                placeholder={localization.columns.creationTime()}
                                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                                    </DeferredFilter>
                            }
                        }}
                        id={Contract.EntityModelProperty.ResourceCreationTime}
                        key={Contract.EntityModelProperty.ResourceCreationTime}
                        render={
                            optionalTableCell<Contract.OpResourceModel>(
                                item =>
                                    _.isNil(item.creationTime)
                                        ? undefined
                                        : TimeFormatter.monthDayAndYear(item.creationTime))}
                        sortOptions={{ type: DataTableSortType.Date }}
                        title={localization.columns.creationTime()}/>
            }
    };
}