﻿import { Action1, MultiSelect, useLocalization } from "@infrastructure";
import { Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { KubernetesResourceHelper } from "../../../../../../../../common";

type CapabilitiesMultiSelectProps = {
    onSelectedCapabilitiesChanged: Action1<string[]>;
    readonly: boolean;
    selectedCapabilities: string[];
};

export function CapabilitiesMultiSelect({ onSelectedCapabilitiesChanged, readonly, selectedCapabilities }: CapabilitiesMultiSelectProps) {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.kubernetesAdmissionController.capabilitiesMultiSelect",
            () => ({
                placeholder: "Capabilities",
                selection: [
                    "{{count | NumberFormatter.humanize}} capability",
                    "{{count | NumberFormatter.humanize}} capabilities"
                ]
            }));

    const theme = useTheme();
    return (
        <MultiSelect
            fieldOptions={{ sx: { width: theme.spacing(40) } }}
            fullWidth={true}
            items={KubernetesResourceHelper.capabilities}
            placeholder={localization.placeholder()}
            readonly={readonly}
            selectedItems={selectedCapabilities}
            selectedItemsTranslator={
                selectedCapabilities =>
                    selectedCapabilities.length === 1
                        ? _.first(selectedCapabilities)
                        : localization.selection(selectedCapabilities.length)}
            onSelectedItemsChanged={onSelectedCapabilitiesChanged}>
            {value =>
                <Typography>
                    {value}
                </Typography>}
        </MultiSelect>);
}