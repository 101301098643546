import { TextViewer, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, InfoItem, useEntityTypeNameTranslator, useTenantNameTranslator, useTheme } from "../../../../../../../../../../common";
import { useAzureAuthorizationRoleDefinitionTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureAuthorizationRoleDefinitionDefinition(resourceModel: Contract.AzureResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceModel);
    const roleDefinition = resourceModel.entity as Contract.AzureAuthorizationRoleDefinition;

    const authorizationRoleDefinitionTypeTranslator = useAzureAuthorizationRoleDefinitionTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureAuthorizationRoleDefinitionDefinition",
            () => ({
                info: {
                    items: {
                        description: "Description",
                        type: "Role Type"
                    },
                    tenant: "Tenant",
                    typeName: "Type"
                },
                tabs: {
                    definition: "Definition"
                }
            }));

    const theme = useTheme();
    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Box sx={{ paddingTop: theme.spacing(1.5) }}>
                    <TextViewer
                        format="json"
                        text={roleDefinition.json}/>
                </Box>,
                localization.tabs.definition(),
                "definition")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="typeName"
                        title={localization.info.typeName()}
                        value={entityTypeNameTranslator(roleDefinition.typeName)}/>,
                    <InfoItem
                        key="tenant"
                        title={localization.info.tenant()}
                        value={tenantNameTranslator(roleDefinition.tenantId, { includeRawId: true })}/>,
                    <InfoItem
                        key="description"
                        location="all"
                        title={localization.info.items.description()}
                        value={roleDefinition.description}/>,
                    <InfoItem
                        key="type"
                        location="all"
                        title={localization.info.items.type()}
                        value={authorizationRoleDefinitionTypeTranslator(roleDefinition.type)}/>]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}