import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { Contract, TenantIcon } from "../../../../../../../common";
import { MicrosoftIcon } from "../../../../../../../common/components/SignIn/icons";
import { GoogleIcon } from "../../../../../../../common/icons/VendorIcon";
import { IdentityProviderType } from "../Authentication";

type IdentityProviderIconProps =
    SvgIconProps & {
        type: IdentityProviderType;
    };

export function IdentityProviderIcon({ type, ...props }: IdentityProviderIconProps) {
    switch (type) {
        case Contract.TenantType.Azure:
            return <MicrosoftIcon {...props}/>;
        case Contract.TenantType.Gcp:
            return <GoogleIcon {...props}/>;
        case Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Aad:
            return <TenantIcon {...props} tenantType={Contract.TenantType.Aad}/>;
        case Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.AwsSso:
            return <TenantIcon {...props} tenantType={Contract.TenantType.Aws}/>;
        case Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Duo:
            return <TenantIcon {...props} tenantType={Contract.TenantType.Unknown}/>;
        case Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Gci:
            return <TenantIcon {...props} tenantType={Contract.TenantType.Gci}/>;
        case Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Okta:
            return <TenantIcon {...props} tenantType={Contract.TenantType.Okta}/>;
        case Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.OneLogin:
            return <TenantIcon {...props} tenantType={Contract.TenantType.OneLogin}/>;
        case Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Other:
            return <TenantIcon {...props} tenantType={Contract.TenantType.Unknown}/>;
        case Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.PingIdentity:
            return <TenantIcon {...props} tenantType={Contract.TenantType.PingIdentity}/>;
        default:
            return <Fragment/>;
    }
}