import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useGcpScopeResourceAccessLevelTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useEntityRiskContext } from "../../../useEntityRiskContext";
import { useResourceGeneralInformationStep } from "../../../useResourceGeneralInformationStep";

export function useGetGcpEntityRiskContext() {
    return useMemo(
        () => useGcpEntityRiskContext,
        []);
}

function useGcpEntityRiskContext(entityModel: Contract.GcpEntityModel) {
    const entityRiskContext = useEntityRiskContext(entityModel);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(entityModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const resourceAccessLevelTranslator = useGcpScopeResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpEntityRiskContext",
            () => ({
                accessLevel: "The {{translatedEntityTypeName}}’s access level is **{{accessLevel}}**",
                encryption: {
                    default: "using a Google-Managed encryption",
                    disabled: "not encrypted",
                    enabled: "encrypted using {{kmsEncryptionKeyReferences}}",
                    text: "The {{translatedEntityTypeName}} is {{encryption}}"
                },
                loggingLogSink: "The {{translatedEntityTypeName}} is used by {{loggingLogSinks}}",
                networkInboundAccessType: {
                    [Contract.TypeNames.GcpServerlessNetworkedResourceNetworkInboundAccessType]: {
                        [Contract.GcpServerlessNetworkedResourceNetworkInboundAccessType.External]: "The {{translatedEntityTypeName}}'s ingress traffic is **Allow All**",
                        [Contract.GcpServerlessNetworkedResourceNetworkInboundAccessType.InternalVpc]: "The {{translatedEntityTypeName}}'s ingress traffic is **Allow internal**",
                        [Contract.GcpServerlessNetworkedResourceNetworkInboundAccessType.LoadBalancerOrInternalVpc]: "The {{translatedEntityTypeName}}'s ingress traffic is **Allow internal and Cloud Load Balancing**"
                    }
                }
            }));
    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        accessLevel:
            TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.GcpScopeResourceModel)
                ? new RiskDefinitionContextItem(
                    localization.accessLevel({
                        accessLevel: resourceAccessLevelTranslator(_.as<Contract.GcpScopeResourceModel>(entityModel).accessLevel!),
                        translatedEntityTypeName
                    }))
                : undefined,
        encryption:
            TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IGcpEncryptedResourceModel)
                ? new RiskDefinitionContextItem(
                    localization.encryption.text({
                        encryption:
                            !_.as<Contract.IGcpEncryptedResource>(entityModel.entity).encryptionExists
                                ? localization.encryption.disabled
                                : _.isEmpty(_.as<Contract.IGcpEncryptedResourceModel>(entityModel).kmsEncryptionKeyReferences)
                                    ? localization.encryption.default()
                                    : localization.encryption.enabled({
                                        kmsEncryptionKeyReferences:
                                            <InlineEntities
                                                entityIdsOrModels={
                                                    _.map(
                                                        _.as<Contract.IGcpEncryptedResourceModel>(entityModel).kmsEncryptionKeyReferences,
                                                        kmsEncryptionKeyReference => kmsEncryptionKeyReference.idReference)}
                                                entityTypeName={Contract.TypeNames.GcpKmsKeyRingKey}
                                                variant="itemOrItemCountAndType"/>
                                    }),
                        translatedEntityTypeName
                    }))
                : undefined,
        generalInformation:
            _.isNil(resourceGeneralInformationStep)
                ? undefined
                : new RiskDefinitionContextItem(resourceGeneralInformationStep),
        getNetworkInboundAccessType:
            (networkInboundAccessType: Contract.GcpServerlessNetworkedResourceNetworkInboundAccessType) =>
                new RiskDefinitionContextItem(localization.networkInboundAccessType[Contract.TypeNames.GcpServerlessNetworkedResourceNetworkInboundAccessType][networkInboundAccessType]({ translatedEntityTypeName })),

        loggingLogSinks:
            TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IGcpLoggingLogSinkDestinationResourceModel) &&
            !_.isEmpty(_.as<Contract.IGcpLoggingLogSinkDestinationResourceModel>(entityModel).loggingLogSinkIds)
                ? new RiskDefinitionContextItem(
                    localization.loggingLogSink({
                        loggingLogSinks:
                            <InlineEntities
                                entityIdsOrModels={_.as<Contract.IGcpLoggingLogSinkDestinationResourceModel>(entityModel).loggingLogSinkIds}
                                entityTypeName={Contract.TypeNames.GcpLoggingLogSink}
                                variant="itemOrItemCountAndType"/>,
                        translatedEntityTypeName
                    }))
                : undefined
    };
}