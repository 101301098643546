import { IconNodeContent, NodeContentContextMenuItem, Optional, useLocalization, useSetDirectedGraphContext } from "@infrastructure";
import _ from "lodash";
import React, { MutableRefObject } from "react";
import { Contract, getEntityNodeContentAnchorPoint, getEntityNodeContentSize, useNodeContentFilterContextMenuItems, useTheme } from "../../../../../..";
import { ToolbarActions, ToolbarFilterId } from "../../..";
import { Subnets } from "./components";
import { useSubnetDefinition } from "./hooks";

type SubnetNodeContentProps = {
    sourceGroup: Contract.NetworkGraphSourceGroup;
    toolbarActionsRef: MutableRefObject<Optional<ToolbarActions>>;
};

export function SubnetNodeContent({ sourceGroup, toolbarActionsRef }: SubnetNodeContentProps) {
    const setDirectedGraphContext = useSetDirectedGraphContext();
    const localization =
        useLocalization(
            "common.network.graph.subnetNodeContent",
            () => ({
                filterSubnets: "IP Address ranges",
                menu: {
                    view: "View IP Address ranges"
                }
            }));

    const filterContextMenuItems =
        useNodeContentFilterContextMenuItems(
            !_.isNil(sourceGroup.subnets)
                ? sourceGroup.subnets!.length
                : 0,
            !_.isNil(sourceGroup.subnets) && sourceGroup.subnets!.length === 1
                ? sourceGroup.subnets![0]
                : localization.filterSubnets(),
            !_.isNil(sourceGroup.subnets)
                ? type =>
                    toolbarActionsRef.current!.filter(
                        ToolbarFilterId.SourceSubnets,
                        type,
                        sourceGroup.subnets!)
                : undefined);

    const sourceDefinition =
        useSubnetDefinition(
            sourceGroup.serviceSubnets,
            sourceGroup.specialSource,
            sourceGroup.subnets);
    const theme = useTheme();
    return (
        <IconNodeContent
            contextMenuItems={
                _<NodeContentContextMenuItem>([]).
                    concatIf(
                        !_.isNil(sourceGroup.subnets) &&
                        sourceGroup.subnets!.length > 1,
                        () =>
                            new NodeContentContextMenuItem(
                                () =>
                                    setDirectedGraphContext(
                                        context => ({
                                            ...context,
                                            dialogContentElement: <Subnets sourceGroup={sourceGroup}/>
                                        })),
                                localization.menu.view())).
                    concat(filterContextMenuItems).
                    value()}
            iconOptions={{
                highlightColor:
                    theme.dark && theme.palette.text.primary === sourceDefinition.highlightColor
                        ? theme.palette.common.black
                        : sourceDefinition.highlightColor,
                icon: sourceDefinition.icon,
                normalColor: sourceDefinition.normalColor,
                size: 70
            }}
            size={getSubnetNodeContentSize()}
            titleElementOrOptions={{
                highlightColor: sourceDefinition.highlightColor,
                normalColor: sourceDefinition.normalColor,
                subtitle: sourceDefinition.subtitle,
                subtitleSize: "12px",
                title: sourceDefinition.title
            }}/>);
}

export const getSubnetNodeContentAnchorPoint =
    (anchorPlacement: "left" | "right") => {
        const size = getSubnetNodeContentSize();
        const anchorPoint = getEntityNodeContentAnchorPoint(anchorPlacement, "bottom", "large");
        return ({
            x:
                anchorPlacement === "right"
                    ? size.width / 2 + 60
                    : size.width / 2 - 60,
            y: anchorPoint.y
        });
    };

export const getSubnetNodeContentSize =
    () => getEntityNodeContentSize("bottom", "large");