import { v4 as uuid } from "uuid";

export type ItemWithId<TItem> = {
    id?: string;
} & {
    [P in keyof TItem]: TItem[P];
};

export function getItemWithId<TItem>(item: ItemWithId<TItem>, id?: string) {
    item.id = id ?? uuid();
    return item;
}