﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InlineEntities } from "../../../../../../../../../../../../common";
import { Events } from "../../../../../../../../../Events";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsIamPrincipalCreationRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamPrincipalCreationRisk;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsIamPrincipalCreationRiskDefinition",
            () => ({
                events: [
                    "{{principals}} was created",
                    "{{principals}} were created"
                ]
            }));
    const translatedViolations =
        localization.events(
            risk.resourceIds.length,
            {
                principals:
                    <InlineEntities
                        entityIdsOrModels={risk.resourceIds}
                        entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                        variant="itemAndTypeOrItemCountAndType"/>
            });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "events",
        <Events riskId={risk.id}/>);
}