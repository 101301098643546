import { CollapsedIcon, DeleteIcon, IconText, InfoIcon, Tooltip } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract } from "../../../controllers";
import { useDeliveryTranslator } from "../../../hooks";
import { Delivery, DeliveryProps } from "../../Delivery";
import { DeliveryIcon } from "../../DeliveryIcon";

export type DeliveryListItemProps = {
    onDelete: () => void;
} & DeliveryProps;

export function DeliveryListItem({ delivery, disabled, onChange, onDelete, scopeId }: DeliveryListItemProps) {
    const [hover, setHover] = useState(false);

    const deliveryTranslator = useDeliveryTranslator();
    const { message, text } = deliveryTranslator(delivery.typeName as Contract.DeliveryDerivedTypeNames, "title");

    return (
        <Accordion
            defaultExpanded={true}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <AccordionSummary
                expandIcon={<CollapsedIcon/>}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ flex: 1 }}>
                    <IconText
                        icon={<DeliveryIcon typeName={delivery.typeName as Contract.DeliveryDerivedTypeNames}/>}
                        iconSx={{ fontSize: "18px" }}
                        text={
                            <Stack
                                direction="row"
                                spacing={0.5}>
                                <Typography noWrap={true}>
                                    {text}
                                </Typography>
                                {!_.isNil(message) &&
                                    <Tooltip titleOrGetTitle={message}>
                                        <InfoIcon sx={{ fontSize: "18px" }}/>
                                    </Tooltip>}
                            </Stack>}/>
                    <Box>
                        {hover &&
                            <IconButton onClick={() => onDelete()}>
                                <DeleteIcon sx={{ fontSize: "18px" }}/>
                            </IconButton>}
                    </Box>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1.5}>
                    <Delivery
                        delivery={delivery}
                        disabled={disabled}
                        iconVisible={false}
                        scopeId={scopeId}
                        onChange={onChange}/>
                </Stack>
            </AccordionDetails>
        </Accordion>);
}