import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAzureSqlServerDatabaseStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureSqlServerDatabaseRiskContext() {
    return useMemo(
        () => useAzureSqlServerDatabaseRiskContext,
        []);
}

function useAzureSqlServerDatabaseRiskContext(serverDatabaseModel: Contract.AzureSqlServerDatabaseModel) {
    const serverDatabase = serverDatabaseModel.entity as Contract.AzureSqlServerDatabase;
    const resourceRiskContext = useGetAzureResourceRiskContext()(serverDatabaseModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const sqlServerDatabaseStatusTranslator = useAzureSqlServerDatabaseStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureSqlServerDatabaseRiskContext",
            () => ({
                server: "The {{translatedServerDatabaseTypeName}} is associated with {{server}}",
                status: "The {{translatedServerDatabaseTypeName}}'s status is **{{status}}**",
                storageSize: "The {{translatedServerDatabaseTypeName}}'s total size is {{storageSize | NumberFormatter.storage}}"
            }));

    const translatedServerDatabaseTypeName =
        entityTypeNameTranslator(
            serverDatabase.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        server:
            new RiskDefinitionContextItem(
                localization.server({
                    server:
                        <Entity
                            entityIdOrModel={serverDatabase.serverId}
                            variant="typeText"/>,
                    translatedServerDatabaseTypeName
                })),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: sqlServerDatabaseStatusTranslator(serverDatabase.status),
                    translatedServerDatabaseTypeName
                })),
        storageSize:
            new RiskDefinitionContextItem(
                localization.storageSize({
                    storageSize: serverDatabase.storageSize ?? 0,
                    translatedServerDatabaseTypeName
                }))
    };
}