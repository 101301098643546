import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskPoliciesView } from "../../../../../../../../common";
import { useKubernetesAdmissionControllerRiskPoliciesBuiltInDefinition } from "./hooks";

export function useKubernetesAdmissionControllerRiskPoliciesDefinition(scopeId: string, view: RiskPoliciesView) {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case RiskPoliciesView.BuiltIn:
                        return useKubernetesAdmissionControllerRiskPoliciesBuiltInDefinition;
                    default:
                        throw new UnexpectedError("view", view);
                }
            },
            []);

    return useDefinition(scopeId);
}