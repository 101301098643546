import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EventsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M16.3672 13.6289C16.9195 13.6289 17.3672 14.0766 17.3672 14.6289V16.7476L18.9722 18.0501C19.4662 18.2971 19.6664 18.8978 19.4194 19.3918C19.1725 19.8858 18.5718 20.086 18.0778 19.839L15.92 18.2601C15.5812 18.0907 15.3672 17.7444 15.3672 17.3657V14.6289C15.3672 14.0766 15.8149 13.6289 16.3672 13.6289Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M16.5 13C14.0147 13 12 15.0147 12 17.5C12 19.9853 14.0147 22 16.5 22C18.9853 22 21 19.9853 21 17.5C21 15.0147 18.9853 13 16.5 13ZM10 17.5C10 13.9101 12.9101 11 16.5 11C20.0899 11 23 13.9101 23 17.5C23 21.0899 20.0899 24 16.5 24C12.9101 24 10 21.0899 10 17.5Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M5 7C5 6.44772 5.44772 6 6 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H6C5.44772 8 5 7.55228 5 7ZM5 11C5 10.4477 5.44772 10 6 10H10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H6C5.44772 12 5 11.5523 5 11Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M9.92682 1L10.0647 1C11.8921 0.999979 15.3644 0.999962 16.522 1.15582C17.7221 1.31738 18.7322 1.6627 19.5348 2.46459C20.3378 3.26692 20.6832 4.27755 20.8446 5.47771C21.0001 6.63368 21 6.10351 21 7.92683V9C21 9.55229 20.5523 10 20 10C19.4477 10 19 9.55229 19 9V8C19 6.08622 18.9979 6.7514 18.8624 5.7443C18.7308 4.76595 18.4902 4.24808 18.1212 3.87941C17.7518 3.51031 17.2334 3.26962 16.2552 3.13794C15.2483 3.00237 11.9136 3 10 3C8.08622 3 6.7514 3.00213 5.7443 3.13758C4.76595 3.26916 4.24808 3.50982 3.87941 3.87881C3.51031 4.24823 3.26962 4.76662 3.13794 5.7448C3.00237 6.75174 3 8.08638 3 10V14C3 15.9138 3.00213 17.2486 3.13758 18.2557C3.26916 19.2341 3.50982 19.7519 3.87881 20.1206C4.24823 20.4897 4.76662 20.7304 5.7448 20.8621C6.75174 20.9976 7.08638 21 9 21C9.55229 21 10 21.4477 10 22C10 22.5523 9.55229 23 9 23H8.93535C7.10793 23 6.63564 23 5.47795 22.8442C4.27789 22.6826 3.26778 22.3373 2.4652 21.5354C1.66218 20.7331 1.31684 19.7225 1.15543 18.5223C0.999951 17.3663 0.999973 15.8965 1 14.0732L1 9.93534C0.999979 8.10792 0.999962 6.63564 1.15582 5.47795C1.31738 4.27789 1.6627 3.26778 2.46459 2.4652C3.26692 1.66218 4.27755 1.31684 5.47771 1.15543C6.63368 0.999951 8.1035 0.999973 9.92682 1Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}