import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, useSeverityTranslator } from "../../../../../../../../../common";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../useDefinition";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskSeverityAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskSeverityAuditEventDefinition",
            () => ({
                riskNewSeverity: "New Severity",
                riskOldSeverity: "Old Severity",
                serviceNowIncidentUrl: "Link to incident"
            }));

    const riskSeverityUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.RiskSeverityUpdateAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            riskAuditEventDetailsItems.riskPolicyTypeItem,
            riskAuditEventDetailsItems.riskIdItem,
            riskAuditEventDetailsItems.riskDeletedItem,
            riskAuditEventDetailsItems.riskedEntitiesItem,
            riskAuditEventDetailsItems.riskLinkItem,
            new AuditEventDefinitionDetailItem(
                localization.riskOldSeverity(),
                severityTranslator(riskSeverityUpdateAuditEvent.oldSeverity)),
            new AuditEventDefinitionDetailItem(
                localization.riskNewSeverity(),
                severityTranslator(riskSeverityUpdateAuditEvent.newSeverity))
        ]) as AuditEventDefinitionDetailItem[]);
}