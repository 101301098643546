﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useKubernetesAdmissionControllerResourceOperationTranslator() {
    const localization =
        useLocalization(
            "views.customer.kubernetes.hooks.useKubernetesAdmissionControllerResourceOperationTranslator",
            () => ({
                [Contract.TypeNames.KubernetesAdmissionControllerResourceOperation]: {
                    [Contract.KubernetesAdmissionControllerResourceOperation.Create]: "Create",
                    [Contract.KubernetesAdmissionControllerResourceOperation.Delete]: "Delete",
                    [Contract.KubernetesAdmissionControllerResourceOperation.Update]: "Update"
                }
            }));
    return (resourceOperation: Contract.KubernetesAdmissionControllerResourceOperation) =>
        localization[Contract.TypeNames.KubernetesAdmissionControllerResourceOperation][resourceOperation]();
}