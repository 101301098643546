﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEc2VolumeRiskContext() {
    return useMemo(
        () => useAwsEc2VolumeContext,
        []);
}

function useAwsEc2VolumeContext(volumeModel: Contract.AwsEc2VolumeModel) {
    const volume = volumeModel.entity as Contract.AwsEc2Volume;
    const getResourceRiskContext = useGetAwsResourceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2VolumeRiskContext",
            () => ({
                instances: "The {{translatedVolumeTypeName}} is associated with {{instances}}",
                instanceTerminationDeletion: {
                    false: "The {{translatedVolumeTypeName}}'s data is marked to **persist** on instance termination",
                    true: "The {{translatedVolumeTypeName}}'s data is marked to **delete** on instance termination"
                },
                snapshots: "The {{translatedVolumeTypeName}} is associated with {{snapshots}}",
                volumeSize: {
                    false: "The {{translatedVolumeTypeName}}'s size is {{storageSize | NumberFormatter.storage}}",
                    true: "The {{translatedVolumeTypeName}}'s size is {{storageSize | NumberFormatter.storage}} and it is a root volume"
                }
            }));
    const translatedVolumeTypeName =
        entityTypeNameTranslator(
            volume.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...getResourceRiskContext(volumeModel),
        getInstancesContextItem:
            (instanceIds: string[]) =>
                _.isEmpty(instanceIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.instances({
                            instances:
                                <InlineEntities
                                    entityIdsOrModels={instanceIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                    variant="itemCountAndType"/>,
                            translatedVolumeTypeName
                        })),
        instanceTerminationDeletion:
            new RiskDefinitionContextItem(
                (volume.instanceTerminationDeletion
                    ? localization.instanceTerminationDeletion.true
                    : localization.instanceTerminationDeletion.false)({ translatedVolumeTypeName })),
        snapshots:
            _.isEmpty(volumeModel.snapshotIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.snapshots({
                        snapshots:
                            <InlineEntities
                                entityIdsOrModels={volumeModel.snapshotIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                variant="itemCountAndType"/>,
                        translatedVolumeTypeName
                    })),
        volumeSize:
            new RiskDefinitionContextItem(
                (volumeModel.root
                    ? localization.volumeSize.true
                    : localization.volumeSize.false)({
                    storageSize: volume.size,
                    translatedVolumeTypeName
                }))
    };
}