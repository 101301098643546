import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function TeraprocIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2251)">
                <path
                    d="M8.90847 4H9.84407L10.1492 4.02034L10.3119 4.06102L10.5559 4.1017L10.739 4.12203L10.861 4.14237L11.3492 4.28475L11.939 4.48814L12.5288 4.77288L12.8339 4.97627L13.0576 5.13898L13.3017 5.32203L13.5051 5.50508L13.6678 5.48475L14.0746 5.32203L14.3186 5.26102L14.8678 5.2H15.3356L15.6407 5.24068L16.0881 5.32203L16.3119 5.42373L16.5356 5.50508L16.9627 5.72881L17.3085 5.97288L17.5932 6.21695L17.7763 6.4L17.9593 6.66441L18.2034 7.11186L18.3051 7.4983L18.3254 7.62034H19.0373L19.6271 7.68136L20.0746 7.76271L20.5627 7.88475L21.2949 8.12881L21.8237 8.37288L22.1898 8.57627L22.5153 8.8L22.8407 9.04407L23.3288 9.5322L23.5322 9.81695L23.7356 10.1831L23.878 10.5085L24 10.9559V11.6881L23.939 11.9729L23.7966 12.3797L23.6339 12.6847L23.4305 12.9898L23.1661 13.3153L22.9424 13.539L22.7797 13.6814L22.4542 13.9254L21.8441 14.2915L21.3966 14.4949L21.0915 14.6169L20.9085 14.6576L20.8881 14.6373V13.417L20.9085 10.9356L20.9288 10.2441V9.81695L20.6847 9.71525L19.9729 9.49153L16.6576 8.51525L16.1085 8.35254L15.6814 8.23051L15.4983 8.16949L15.3966 8.10847L14.9085 8.18983L14.5017 8.27119L12.4678 8.61695L11.4712 8.8L10.9627 8.88136L8.4 9.32881L7.52542 9.47119L6.52881 9.65424L5.77627 9.77627L4.61695 9.97966L2.96949 10.2644L2.07458 10.4271L1.6678 10.5085L1.62712 10.5288L1.64746 13.7627L1.60678 13.8034L1.42373 13.6814L1.22034 13.4983L1.01695 13.2949L0.874576 13.1322L0.650847 12.8678L0.467797 12.5831L0.305085 12.278L0.142373 11.8508L0.040678 11.5051L0 11.2407V10.6712L0.020339 10.3458L0.142373 9.87797L0.305085 9.47119L0.549153 9.02373L0.711864 8.77966L0.955932 8.49492L1.05763 8.37288L1.22034 8.23051L1.42373 8.04746L1.68814 7.86441L1.95254 7.7017L2.19661 7.55932L2.7661 7.31525L3.19322 7.19322L3.62034 7.11186L4.0678 7.07119L4.18983 7.03051L4.27119 6.8678L4.39322 6.54237L4.55593 6.21695L4.69831 5.99322L4.88136 5.76949L5.10508 5.52542L5.2678 5.36271L5.55254 5.13898L5.79661 4.95593L6.1017 4.77288L6.42712 4.61017L6.85424 4.42712L7.52542 4.20339L8.07458 4.08136L8.48136 4.02034L8.90847 4Z"
                    fill="#02B0F4"/>
                <path
                    d="M15.7627 8.65763L15.8644 8.67797L16.4136 8.86102L17.4712 9.1661L17.9186 9.30847L18.5492 9.49152L20.0542 9.93898L20.4814 10.0814L20.5017 10.1017V13.0102L20.461 17.8915L20.1559 18.0339L19.0169 18.5627L18.6915 18.7254L18.3661 18.8678L17.939 19.0712L17.4712 19.2746L17.1458 19.4373L16.3729 19.8034L15.9254 20.0068L15.7424 20.0475V9.36949L15.7627 8.65763Z"
                    fill="#0A5D84"/>
                <path
                    d="M14.9085 8.57627H14.9695L14.9898 9.87797L15.0305 14.0881L15.0508 16.7729L15.0915 20.1492L15.0712 20.1695L14.9085 20.1492L14.4203 20.0068L13.5864 19.8034L13.0576 19.661L11.5932 19.2746L11.2271 19.1729L11.1864 19.1322V10.2441L11.2068 9.24746L11.3492 9.20678L12.8339 8.94237L14.522 8.65763L14.9085 8.57627Z"
                    fill="#0177AE"/>
                <path
                    d="M10.678 9.32881H10.7797V19.0305L10.5966 19.0102L8.48136 18.461L7.99322 18.3186L7.97288 18.2983V17.6678L7.99322 15.6339L8.01356 11.4441L8.0339 9.79661L8.15593 9.75593L9.43729 9.5322L10.678 9.32881Z"
                    fill="#0177AF"/>
                <path
                    d="M5.34915 10.2441L5.41017 10.2644V12.0339L5.36949 17.6271L5.1661 17.6068L4.57627 17.4441L3.84407 17.2407L3.82373 17.2203V15.7153L3.84407 12.6441V10.6102L3.82373 10.5695L3.64068 10.5898L3.62034 10.9559L3.6 12.6847L3.57966 17.139L3.37627 17.1186L2.11525 16.7932L2.0339 16.7525L2.01356 12.5017L2.0339 10.8542L2.21695 10.7932L3.25424 10.6102L3.68136 10.5288L4.31186 10.4271L5.34915 10.2441Z"
                    fill="#0178AF"/>
                <path
                    d="M7.58644 9.85763H7.68814L7.70847 9.87797V12.2983L7.68814 15.4305L7.64746 18.1966L7.5661 18.2169L6.71186 17.9932L5.93898 17.7898L5.65424 17.7085L5.6339 17.6881V15.2475L5.67458 10.2034L5.69492 10.1831L7.11864 9.93898L7.58644 9.85763Z"
                    fill="#0178AF"/>
                <path
                    d="M3.76271 10.5085H3.90508L3.86441 10.5288L3.84407 10.6102L3.82373 10.5695L3.64068 10.5898L3.62034 10.9559H3.6V10.5695L3.76271 10.5085Z"
                    fill="#02B0F4"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2251">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}