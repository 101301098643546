import { DataTableColumn, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonEncryptedResourceDefinition, useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useOciFssFileSystemStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciFssFileSystemDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useOciCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const fileSystemStatusTranslator = useOciFssFileSystemStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciFssFileSystemDefinition",
            () => ({
                columns: {
                    size: "Utilization Size",
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciFssFileSystemModel) => ({
                            [localization.columns.status()]: fileSystemStatusTranslator((item.entity as Contract.OciFssFileSystem).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciFssFileSystemStatus}
                                enumTypeTranslator={fileSystemStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciFssFileSystemStatus}
                itemProperty={(item: Contract.OciFssFileSystemModel) => fileSystemStatusTranslator((item.entity as Contract.OciFssFileSystem).status)}
                key={Contract.EntityModelProperty.OciFssFileSystemStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciFssFileSystemModel) => ({
                            [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.OciFssFileSystem).size)
                        })
                }}
                id={Contract.EntityModelProperty.OciFssFileSystemSize}
                itemProperty={(item: Contract.OciFssFileSystemModel) => NumberFormatter.storage((item.entity as Contract.OciFssFileSystem).size)}
                key={Contract.EntityModelProperty.OciFssFileSystemSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionVaultKeyColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciFssFileSystemRequest(
                new Contract.EntityControllerGetEntityModelPageOciFssFileSystemRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssFileSystemStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageOciResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciEncryptedResourceEncryptionVaultKey]))));
}