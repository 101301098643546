import _ from "lodash";
import React from "react";
import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, FeatureHelper, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonCloudWatchLoggingResourceInfoElements } from "./useAwsCommonCloudWatchLoggingResourceInfoElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsElasticBeanstalkEnvironmentDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const commonCloudWatchLoggingResourceInfoElements = useAwsCommonCloudWatchLoggingResourceInfoElements(resourceModel);
    const environmentModel = resourceModel as Contract.AwsElasticBeanstalkEnvironmentModel;
    const environment = environmentModel.entity as Contract.AwsElasticBeanstalkEnvironment;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsElasticBeanstalkEnvironmentDefinition",
            () => ({
                info: {
                    items: {
                        cloudWatchLogRetentionTimeFrame: "CloudWatch Log Retention Period",
                        dnsZoneIds: "DNS Zones"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={environmentModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    // Migration: 1.161 | Delete FeatureHelper
                    FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerDocumentModelsCompleted)
                        ? commonCloudWatchLoggingResourceInfoElements.cloudWatchLoggingEnabled
                        : undefined,
                    <InfoItem
                        key="cloudWatchLogRetentionTimeFrame"
                        title={localization.info.items.cloudWatchLogRetentionTimeFrame()}
                        value={
                            _.isNil(environment.cloudWatchLogRetentionTimeFrame)
                                ? undefined
                                : TimeSpanFormatter.day(environment.cloudWatchLogRetentionTimeFrame)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}