import { ItemsSearchLayout, useLocalization } from "@infrastructure";
import { Box, Divider, List, ListItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract } from "../../../../../../..";
import { useSubnetDefinition } from "../hooks";

type SubnetsProps = {
    sourceGroup: Contract.NetworkGraphSourceGroup;
};

export function Subnets({ sourceGroup }: SubnetsProps) {
    const localization =
        useLocalization(
            "common.network.graph.subnetNodeContent.subnets",
            () => ({
                actions: {
                    search: "Search IP Address ranges"
                },
                title: "IP Address ranges"
            }));
    return (
        <ItemsSearchLayout
            searchPlaceholder={localization.actions.search()}
            title={localization.title()}>
            {searchText =>
                <List
                    disablePadding={true}
                    sx={{ width: "100%" }}>
                    {_(sourceGroup.subnets!).
                        filter(subnet => _.isNil(searchText) || subnet.includes(searchText)).
                        map(
                            (subnet, subnetIndex) =>
                                <Fragment key={subnet}>
                                    {subnetIndex !== 0 &&
                                        <Divider/>}
                                    <Item
                                        serviceSubnet={sourceGroup.serviceSubnets!}
                                        subnet={subnet}/>
                                </Fragment>).
                        value()}
                </List>}
        </ItemsSearchLayout>);
}

type ItemProps = {
    serviceSubnet: boolean;
    subnet: string;
};

function Item({ serviceSubnet, subnet }: ItemProps) {
    const subnetDefinition =
        useSubnetDefinition(
            serviceSubnet,
            undefined,
            subnet);
    return (
        <ListItem>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}>
                <Box
                    sx={{
                        color: subnetDefinition.highlightColor,
                        fontSize: "48px"
                    }}>
                    {subnetDefinition.icon}
                </Box>
                <Stack
                    spacing={0}
                    sx={{ flex: 1 }}>
                    <Typography sx={{ color: subnetDefinition.highlightColor }}>
                        {subnetDefinition.title}
                    </Typography>
                    <Typography>
                        {subnetDefinition.subtitle}
                    </Typography>
                </Stack>
            </Stack>
        </ListItem>);
}