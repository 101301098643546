import React from "react";
import { CustomerConsoleAppUrlHelper, ProfileActions } from "../../../../../../../common";
import { Profile } from "../../../../Kubernetes/components/KubernetesClusterAdmissionControllerEvents/components";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useKubernetesClusterAdmissionControllerEventsDefinition(item: SideViewItem): SideViewDefinition {
    return {
        getActionsElement:
            () =>
                <ProfileActions
                    getLink={() => CustomerConsoleAppUrlHelper.getKubernetesClusterAdmissionControllerEventProfileUrl(item.id)!}
                    key={`${item.id}-actions`}/>,
        getViewElement:
            () =>
                <Profile
                    eventId={item.id}
                    key={`${item.id}-profile`}/>
    };
}