import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useOneLoginCommonDirectoryEntityInfoItemElements } from "./useOneLoginCommonDirectoryEntityInfoItemElements";

export function useOneLoginDefaultDirectoryEntityDefinition(directoryEntityModel: Contract.OneLoginDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = useOneLoginCommonDirectoryEntityInfoItemElements(directoryEntityModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[]}
                options={options?.infoOptions}/>,
        sensitive: false
    });
}