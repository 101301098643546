﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureContainerRegistryRegistrySkuTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureContainerRegistryRegistrySkuTranslator",
            () => ({
                [Contract.TypeNames.AzureContainerRegistryRegistrySku]: {
                    [Contract.AzureContainerRegistryRegistrySku.Classic]: "Classic",
                    [Contract.AzureContainerRegistryRegistrySku.Basic]: "Basic",
                    [Contract.AzureContainerRegistryRegistrySku.Standard]: "Standard",
                    [Contract.AzureContainerRegistryRegistrySku.Premium]: "Premium"
                }
            }));
    return (sku: Contract.AzureContainerRegistryRegistrySku) =>
        localization[Contract.TypeNames.AzureContainerRegistryRegistrySku][sku]();
}