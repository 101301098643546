import { FailedIcon, IconText, PassedIcon } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../common";

interface ScanResultProps {
    failed: string;
    passed: string;
    success: boolean;
}
export function ScanResult({ failed, passed, success }: ScanResultProps) {
    const theme = useTheme();
    return (
        success
            ? <IconText
                icon={
                    <PassedIcon
                        sx={{
                            color: theme.palette.success.main,
                            fontSize: "18px",
                            height: "auto"
                        }}/>}
                text={
                    <Typography noWrap={true}>
                        {passed}
                    </Typography>}/>
            : <IconText
                icon={
                    <FailedIcon
                        sx={{
                            color: theme.palette.error.main,
                            fontSize: "18px",
                            height: "auto"
                        }}/>}
                text={
                    <Typography noWrap={true}>
                        {failed}
                    </Typography>}/>);
}