import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAzureDeleteRoleAssignmentResourceChangeDefinition(change: Contract.AzureChange, entityLinkDisabled?: boolean) {
    const deleteRoleAssignmentResourceChange = change as Contract.AzureDeleteRoleAssignmentResourceChange;
    const roleAssignmentResource = entityModelStore.useGet(deleteRoleAssignmentResourceChange.resourceId).entity as Contract.AzureAuthorizationRoleAssignmentResource;
    const roleAssignmentResourceScopeEntity = entityModelStore.useGet(deleteRoleAssignmentResourceChange.scopeEntityId)?.entity as Contract.AzureEntity;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAzureDefinition.hooks.useAzureDeleteRoleAssignmentResourceChangeDefinition",
            () => ({
                [Contract.TypeNames.AzureRoot]: {
                    [Contract.TypeNames.AzureDeleteRoleAssignmentChange]: "Remove role assignment with {{roleDefinition}} assigned to **{{scopeEntityTranslatedTypeName}}**",
                    [Contract.TypeNames.AzureDeleteRoleAssignmentScheduleChange]: "Remove PIM active role assignment with {{roleDefinition}} assigned to **{{scopeEntityTranslatedTypeName}}**",
                    [Contract.TypeNames.AzureDeleteRoleEligibilityScheduleChange]: "Remove PIM eligible role assignment with {{roleDefinition}} assigned to **{{scopeEntityTranslatedTypeName}}**"
                },
                [Contract.TypeNames.AzureResource]: {
                    [Contract.TypeNames.AzureDeleteRoleAssignmentChange]: "Remove role assignment with {{roleDefinition}} assigned to {{scopeEntity}}",
                    [Contract.TypeNames.AzureDeleteRoleAssignmentScheduleChange]: "Remove PIM active role assignment with {{roleDefinition}} assigned to {{scopeEntity}}",
                    [Contract.TypeNames.AzureDeleteRoleEligibilityScheduleChange]: "Remove PIM eligible role assignment with {{roleDefinition}} assigned to {{scopeEntity}}"
                }
            }));

    return {
        title:
            localization[
                roleAssignmentResourceScopeEntity.typeName === Contract.TypeNames.AzureRoot
                    ? Contract.TypeNames.AzureRoot
                    : Contract.TypeNames.AzureResource].
                translate(
                    deleteRoleAssignmentResourceChange.typeName,
                    {
                        roleDefinition:
                            <Entity
                                entityIdOrModel={roleAssignmentResource.roleDefinitionId}
                                glanceOptions={{ disabled: true }}
                                linkOptions={{ disabled: entityLinkDisabled }}
                                variant="typeText"/>,
                        scopeEntity:
                            <Entity
                                entityIdOrModel={roleAssignmentResourceScopeEntity.id}
                                entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                                glanceOptions={{ disabled: true }}
                                linkOptions={{ disabled: entityLinkDisabled }}
                                variant="typeText"/>,
                        scopeEntityTranslatedTypeName:
                            entityTypeNameTranslator(
                                roleAssignmentResourceScopeEntity.typeName,
                                {
                                    variant: "text"
                                })
                    })
    };
}