import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortType, EmptyMessageText, getUrlQueryParameters, NoneIcon, Optional, StringHelper, TimeFormatter, TimeRangeFilter, useChangeEffect, useExecuteOperation, useLocalization, useRemoveQueryParameters, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useReportsContext, useSetReportsContext } from "../..";
import { Contract, DownloadHelper, InlineDeliveries, ItemSelectionHelper, ReportController, StorageHelper, TimeRangeHelper, useScopeNameTranslator, useScopeNavigationViewContext } from "../../../../../../common";
import { useReportDefinitionTranslator } from "../../hooks";
import { ActionsCell } from "./components";

export type ReportsHistoryQueryParameters = {
    downloadReportId?: string;
};

export function History() {
    const removeQueryParameters = useRemoveQueryParameters();

    useEffect(
        () => {
            function downloadFile() {
                const { downloadReportId } = getUrlQueryParameters<ReportsHistoryQueryParameters>();
                if (!_.isNil(downloadReportId)) {
                    DownloadHelper.downloadFile(ReportController.getReportFileUrl(downloadReportId, false));
                    removeQueryParameters("downloadReportId");
                }
            }
            downloadFile();
        },
        []);

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [childScopeReportsEnabled, setChildScopeReportEnabled] = useState(!StringHelper.isFalse(StorageHelper.customerReportsHistoryFlatView.getValue()));
    const [getReportFiltersResponse, executeGetReportFilters] =
        useExecuteOperation(
            History,
            () =>
                ReportController.getReportFilters(
                    new Contract.ReportControllerGetReportFiltersRequest(
                        childScopeReportsEnabled,
                        scopeNodeModel.configuration.id)));

    const fetchReports =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { reportPage } =
                await ReportController.getReportPage(
                    new Contract.ReportControllerGetReportPageRequest(
                        childScopeReportsEnabled,
                        new Contract.ReportControllerGetReportPageRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[HistoryColumnId.DefinitionId]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[HistoryColumnId.FileName]),
                            scopeNodeModel.configuration.id,
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[HistoryColumnId.SystemCreationTime])),
                        limit,
                        skip));
            return new DataTableFetchItemsResult(
                { count: reportPage.count! },
                reportPage.items,
                !reportPage.hasMore);
        };

    const localization =
        useLocalization(
            "views.customer.reports.history",
            () => ({
                actions: {
                    add: "New Report",
                    childScopeReportsEnabled: "Flat View"
                },
                columns: {
                    delivery: "Delivery",
                    fileName: "Name",
                    scope: "Scope",
                    systemCreationTime: "Creation Time",
                    type: "Type"
                },
                empty: {
                    withFilter: "No Matching Reports",
                    withoutFilter: "No Reports"
                }
            }));

    const { registerReportChange } = useReportsContext();
    const dataTableActionsRef = useRef<DataTableActions>();
    useEffect(
        () => {
            const unregister =
                registerReportChange(
                    async () => {
                        await executeGetReportFilters();
                        await dataTableActionsRef.current!.reload({ refreshFilters: true });
                    });
            return () => {
                unregister();
            };
        },
        []);

    useChangeEffect(
        async () => {
            await executeGetReportFilters();
            await dataTableActionsRef.current!.reload({ refreshFilters: true });
        },
        [childScopeReportsEnabled]);

    const setReportsContext = useSetReportsContext();

    const reportDefinitionTranslator = useReportDefinitionTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerReportsHistoryColumnOrder
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            fetchItems={fetchReports}
            filtersOptions={{
                persist: {
                    visibilityStorageItem: StorageHelper.customerReportsHistoryTableFilters
                }
            }}
            getItemId={(item: Contract.Report) => item.id}
            sortOptions={{ enabled: false }}>
            <DataTableAction>
                <CheckButton
                    checked={childScopeReportsEnabled}
                    title={localization.actions.childScopeReportsEnabled()}
                    onCheckedChanged={
                        checked => {
                            setChildScopeReportEnabled(checked);
                            StorageHelper.customerReportsHistoryFlatView.setValue(checked);
                        }}/>
            </DataTableAction>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={
                        () =>
                            setReportsContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: true
                                }))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <TimeRangeFilter
                                emptyValue={getReportFiltersResponse.systemCreationTimeRange.emptyValue}
                                placeholder={localization.columns.systemCreationTime()}
                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(getReportFiltersResponse.systemCreationTimeRange.timeRange)}/>
                    }
                }}
                id={HistoryColumnId.SystemCreationTime}
                itemProperty={(item: Contract.Report) => TimeFormatter.shortDateTime(item.systemCreationTime)}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.systemCreationTime()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                groupItemTitle={true}
                                placeholder={localization.columns.type()}>
                                {_.map(
                                    getReportFiltersResponse.definitionIds,
                                    reportDefinitionId =>
                                        <ValuesFilterItem
                                            key={reportDefinitionId}
                                            title={reportDefinitionTranslator(reportDefinitionId)}
                                            value={reportDefinitionId}/>)}
                            </ValuesFilter>
                    }
                }}
                id={HistoryColumnId.DefinitionId}
                itemProperty={(item: Contract.Report) => reportDefinitionTranslator(item.definitionId)}
                title={localization.columns.type()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                groupItemTitle={true}
                                placeholder={localization.columns.fileName()}>
                                {_.map(
                                    getReportFiltersResponse.fileNames,
                                    reportFileName =>
                                        <ValuesFilterItem
                                            key={reportFileName}
                                            title={reportFileName}
                                            value={reportFileName}/>)}
                            </ValuesFilter>
                    }
                }}
                id={HistoryColumnId.FileName}
                itemProperty={(item: Contract.Report) => item.fileName}
                title={localization.columns.fileName()}/>
            <DataTableColumn
                id={HistoryColumnId.Delivery}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.Report>) =>
                        _.isEmpty(item.deliveries)
                            ? <NoneIcon/>
                            : <InlineDeliveries deliveries={item.deliveries}/>}
                title={localization.columns.delivery()}/>
            <DataTableColumn
                id={HistoryColumnId.Scope}
                itemProperty={(item: Contract.Report) => scopeNameTranslator(item.scopeId, { path: true })}
                title={localization.columns.scope()}/>
            <DataTableColumn
                id={HistoryColumnId.Actions}
                orderable={false}
                render={ActionsCell}
                resizable={false}/>
        </DataTable>);
}

enum HistoryColumnId {
    Actions = "actions",
    DefinitionId = "definitionId",
    Delivery = "delivery",
    FileName = "fileName",
    Scope = "scope",
    SystemCreationTime = "systemCreationTime"
}