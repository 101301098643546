import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, DownloadIcon, Link, Menu, MenuItem, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, DownloadHelper, ReportController, UserHelper, useTheme } from "../../../../../../../common";
import { useReportsContext } from "../../../Reports";

export function ActionsCell({ item: report }: DataTableColumnRenderProps<Contract.Report>) {
    const [deleteReportError, setDeleteReportError] = useState(false);
    const [deleteReportExecuting, setDeleteReportExecuting] = useState(false);
    const { triggerReportChange } = useReportsContext();

    async function deleteReport() {
        setDeleteReportExecuting(true);
        setDeleteReportError(false);

        try {
            await ReportController.deleteReport(new Contract.ReportControllerDeleteReportRequest(report.id));
            await triggerReportChange();
        } catch {
            setDeleteReportError(true);
        }

        setDeleteReportExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.reports.history.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete report",
                        prompt: "Are you sure you want to delete this report?",
                        title: "Delete"
                    },
                    download: "Download",
                    view: "View report"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteReportExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteReportError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            <Link
                urlOrGetUrl={ReportController.getReportFileUrl(report.id, true)}
                variant="external">
                <Typography noWrap={true}>
                    {localization.actions.view()}
                </Typography>
            </Link>
            <Menu
                disabled={deleteReportExecuting}
                itemsOrGetItems={
                    _<MenuItem>([]).
                        concat(
                            new ActionMenuItem(
                                () => DownloadHelper.downloadFile(ReportController.getReportFileUrl(report.id, false)),
                                localization.actions.download(),
                                {
                                    disabled: deleteReportExecuting,
                                    icon: <DownloadIcon/>
                                })).
                        concatIf(
                            UserHelper.hasScopePermissions(report.scopeId, Contract.IdentityPermission.SecurityWrite),
                            new ActionMenuItem(
                                () => deleteReport(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt()
                                    },
                                    disabled: deleteReportExecuting,
                                    icon: <DeleteIcon/>
                                })).
                        value()}/>
        </Stack>);
}