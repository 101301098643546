import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RiskImpactIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_59706_7488)">
                <path
                    clipRule="evenodd"
                    d="M11.008 6C11.5603 6 12.008 6.44772 12.008 7V12C12.008 12.5523 11.5603 13 11.008 13C10.4557 13 10.008 12.5523 10.008 12V7C10.008 6.44772 10.4557 6 11.008 6ZM10 15C10 14.4477 10.4477 14 11 14H11.009C11.5613 14 12.009 14.4477 12.009 15C12.009 15.5523 11.5613 16 11.009 16H11C10.4477 16 10 15.5523 10 15Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M21.56 22.2423L16.8682 17.5504L18.2824 16.1362L22.9742 20.8281C23.3648 21.2186 23.3648 21.8518 22.9742 22.2423C22.5837 22.6328 21.9505 22.6328 21.56 22.2423Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M16.8595 17.0104C20.179 13.6909 20.179 8.30905 16.8595 4.98959C13.5401 1.67014 8.15817 1.67014 4.83871 4.98959C1.51926 8.30905 1.51926 13.691 4.83871 17.0104C8.15817 20.3299 13.5401 20.3299 16.8595 17.0104ZM18.2737 18.4246C22.3742 14.3241 22.3742 7.67588 18.2737 3.57538C14.1732 -0.525126 7.52501 -0.525126 3.4245 3.57538C-0.676005 7.67588 -0.676005 14.3241 3.4245 18.4246C7.52501 22.5251 14.1732 22.5251 18.2737 18.4246Z"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_59706_7488">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}