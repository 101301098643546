﻿import _ from "lodash";
import { useMemo } from "react";
import { DataTableSortDirection, DataTableSortType, useLocalization } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { RisksItemsFilterId } from "../../../../../../../../../Filters";
import { GroupedItem, GroupedTableColumnId } from "../../../../../../Grouped";
import { GroupedDefinition } from "../../../../useDefinition";
import { useCommonGroupedRiskTenantColumns } from "../../../useCommonGroupedRiskTenantColumns";
import { Tenant } from "./components";

export function useRiskTenantDefinition() {
    const commonColumns = useCommonGroupedRiskTenantColumns();

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskTenantDefinition",
            () => ({
                tenant: "Account"
            }));

    const tenantModels = tenantModelStore.useGetAll();
    const tenantIdToNameMap =
        useMemo(
            () => _(tenantModels).
                keyBy(tenantModel => tenantModel.configuration.id).
                mapValues(tenantModel => tenantModel.configuration.name.toLocaleLowerCase()).
                value(),
            [tenantModels]);

    return useMemo(
        (): GroupedDefinition => ({
            columns: [
                {
                    element: Tenant,
                    filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.TenantId],
                    id: GroupedTableColumnId.Tenant,
                    minWidth: 280,
                    sortOptions: {
                        getIterates: () => [(item: GroupedItem) => tenantIdToNameMap[(item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup).tenantId]],
                        type: DataTableSortType.Alphabetic
                    },
                    title: localization.tenant(),
                    width: 3
                },
                commonColumns.riskCount,
                commonColumns.riskPolicies,
                commonColumns.severity
            ],
            defaultSort: {
                columnId: commonColumns.severity.id,
                direction: DataTableSortDirection.Descending
            },
            groupRequestType: Contract.RiskControllerGetRiskGroupsRequestType.Tenant,
            riskGroupToId: riskGroup => (riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup).tenantId
        }),
        [commonColumns]);
}