import { DialogMenuItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsCommonRdsDatabaseInstanceResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements, useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsRdsDatabaseInstanceEngineTypeTranslator } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../useDefaultDataAnalysisResourceInfoItemElements";

export function useAwsRdsDatabaseInstanceDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const databaseInstanceModel = resourceModel as Contract.AwsRdsDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDatabaseInstance;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            networkInterfaceIds: databaseInstance.networkInterfaceIds,
            publicIpAddresses: databaseInstance.publicIpAddresses,
            resourceModel
        });
    const commonRdsDatabaseInstanceResourceInfoElements =
        useAwsCommonRdsDatabaseInstanceResourceInfoElements(
            Contract.TypeNames.AwsRdsCluster,
            databaseInstanceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            resourceModel as Contract.AwsRdsDatabaseInstanceModel,
            dataAnalysis => dataAnalysis.managedDatabaseScanEnabled);
    const menuItems = [getDataAnalysisResourcePriorityScanRequestDialogMenuItem()];

    const rdsDatabaseInstanceEngineTypeTranslator = useAwsRdsDatabaseInstanceEngineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsRdsDatabaseInstanceDefinition",
            () => ({
                info: {
                    items: {
                        engineType: "Engine"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="engineType"
                        title={localization.info.items.engineType()}
                        value={rdsDatabaseInstanceEngineTypeTranslator(databaseInstance.engineType)}/>,
                    commonRdsDatabaseInstanceResourceInfoElements.status,
                    commonRdsDatabaseInstanceResourceInfoElements.engineVersion,
                    commonRdsDatabaseInstanceResourceInfoElements.storageSize,
                    commonRdsDatabaseInstanceResourceInfoElements.instanceRawType,
                    commonRdsDatabaseInstanceResourceInfoElements.cluster,
                    <EntitiesInfoItem
                        entityIdsOrModels={databaseInstanceModel.snapshotIds}
                        entityTypeName={Contract.TypeNames.AwsRdsDatabaseInstanceSnapshot}
                        key="snapshots"/>,
                    commonRdsDatabaseInstanceResourceInfoElements.endpoint,
                    commonRdsDatabaseInstanceResourceInfoElements.vpc,
                    ...commonEncryptedResourceInfoElements,
                    commonRdsDatabaseInstanceResourceInfoElements.backupRetentionTimeFrame,
                    commonRdsDatabaseInstanceResourceInfoElements.cloudWatchLoggingEnabled,
                    commonRdsDatabaseInstanceResourceInfoElements.cloudWatchLogTypes,
                    commonRdsDatabaseInstanceResourceInfoElements.deleteProtection,
                    commonRdsDatabaseInstanceResourceInfoElements.minorVersionUpgradeEnabled,
                    commonRdsDatabaseInstanceResourceInfoElements.availabilityZone,
                    ...defaultDataAnalysisResourceInfoItemElements
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getPublicIpAddresses()}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
            </Info>,
        topbarMenuItems: _.filter(menuItems) as DialogMenuItem[]
    });
}