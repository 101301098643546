﻿import { CsvExportButton } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, useComplianceExportCsvData, useComplianceSectionDatas } from "../../../../../../../common";
import { ComplianceReportProps } from "../ComplianceReport";

export function CsvReport({ reportDefinitionConfiguration, scopeId }: ComplianceReportProps) {
    const { customComplianceSectionDatas, disabledBuiltInComplianceSectionDatas, enabledBuiltInComplianceSectionDatas } = useComplianceSectionDatas(scopeId);

    const complianceIdentifier =
        reportDefinitionConfiguration.typeName === Contract.TypeNames.CustomComplianceReportDefinitionConfiguration
            ? (reportDefinitionConfiguration as Contract.CustomComplianceReportDefinitionConfiguration).customComplianceId
            : (reportDefinitionConfiguration as Contract.BuiltInComplianceReportDefinitionConfiguration).sectionType;
    const complianceSectionData =
        _([] as Contract.ComplianceSectionData[]).
            concat(
                customComplianceSectionDatas,
                disabledBuiltInComplianceSectionDatas,
                enabledBuiltInComplianceSectionDatas).
            find(complianceSectionData => complianceSectionData.identifier === complianceIdentifier)!;
    const { fileNameOptions, getCsvItems } = useComplianceExportCsvData(scopeId, complianceSectionData);

    return (
        <CsvExportButton
            fileNameOptions={fileNameOptions}
            getItemPage={getCsvItems}/>);
}