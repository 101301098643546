import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useIdentityRoleTranslator } from "../../../../../../../../../common";
import { useCommonAuditEventDetailsItems } from "./useCommonAuditEventDetailsItems";

export function useGroupRoleAssignmentAuditEventDefinition(context: AuditEventDefinitionContext) {
    const commonAuditEventDetailsItems = useCommonAuditEventDetailsItems(context);
    const identityRoleTranslator = useIdentityRoleTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useGroupRoleAssignmentAuditEventDefinition",
            () => ({
                groupIdentifier: "Group",
                groupRole: "Role"
            }));
    const groupRoleAssignmentAuditEvent = context.auditEventModel.auditEvent as Contract.GroupRoleAssignmentAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.groupIdentifier(),
                groupRoleAssignmentAuditEvent.groupIdentifier),
            new AuditEventDefinitionDetailItem(
                localization.groupRole(),
                identityRoleTranslator(groupRoleAssignmentAuditEvent.groupRole)),
            commonAuditEventDetailsItems.errorMessageItem
        ]));
}