import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { forwardRef, memo, Ref } from "react";

const AddIconForwardRef = memo(forwardRef(AddIcon));
export { AddIconForwardRef as AddIcon };

function AddIcon(props: SvgIconProps, ref: Ref<SVGSVGElement>) {
    const theme = useTheme();
    return (
        <SvgIcon
            ref={ref}
            sx={{
                width: theme.spacing(1.5),
                ...props?.sx
            }}
            viewBox="0 0 12 24"
            {...props}>
            <rect
                height="12"
                rx="1"
                width="2"
                x="5"
                y="6"/>
            <path d="M11 11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13L1 13C0.447715 13 -6.78525e-08 12.5523 -4.37114e-08 12C-1.95703e-08 11.4477 0.447716 11 1 11L11 11Z"/>
        </SvgIcon>);
}