﻿import { DataTable, DataTableColumn, EmptyMessageText, optionalTableCell, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoCard } from "../../../../../../../../../common";

type KubernetesServicePortsInfoCardProps = {
    ports: Contract.KubernetesServiceDataPort[];
};

export function KubernetesServicePortsInfoCard({ ports }: KubernetesServicePortsInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.kubernetesServicePortsInfoCard",
            () => ({
                columns: {
                    name: "Name",
                    port: "Port",
                    protocolType: "Protocol",
                    targetPortName: "Target Port"
                },
                emptyMessage: "No Ports",
                title: "Ports"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.emptyMessage()) }}
                fetchItems={() => ports}
                getItemId={(item: Contract.KubernetesServiceDataPort) => `${item.name ?? ""}-${item.port}-${item.protocolType}-${item.targetPortName ?? ""}`}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={TableColumnId.Name}
                    render={optionalTableCell((item: Contract.KubernetesServiceDataPort) => item.name)}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={TableColumnId.Port}
                    itemProperty={(item: Contract.KubernetesServiceDataPort) => item.port}
                    title={localization.columns.port()}/>
                <DataTableColumn
                    id={TableColumnId.ProtocolType}
                    itemProperty={(item: Contract.KubernetesServiceDataPort) => item.protocolType.toUpperCase()}
                    title={localization.columns.protocolType()}/>
                <DataTableColumn
                    id={TableColumnId.TargetPortName}
                    render={optionalTableCell((item: Contract.KubernetesServiceDataPort) => item.targetPortName)}
                    title={localization.columns.targetPortName()}/>
            </DataTable>
        </InfoCard>);
}

enum TableColumnId {
    Name = "name",
    Port = "port",
    ProtocolType = "protocolType",
    TargetPortName = "targetPortName"
}