import { DataTableColumnRenderProps } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, Tenant, useTheme } from "../../../../../../../common";

export function TenantCell({ item }: DataTableColumnRenderProps<Contract.KubernetesClusterAdmissionControllerEventModel>) {
    const theme = useTheme();
    return (
        <Typography noWrap={true}>
            <Tenant
                sx={{ color: theme.palette.text.primary }}
                tenantId={item.tenantId}
                variant="iconText"/>
        </Typography>);
}