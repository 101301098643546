﻿import { ItemSelector, Loading, useLocalization } from "@infrastructure";
import { Box, Stack, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../../../../common";
import { InstanceTable, UserData } from "../../../components";

type DetailsSectionProps = {
    riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel;
};

export function DetailsSection({ riskModel }: DetailsSectionProps) {
    const launchTemplateModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEc2LaunchTemplateModel;
    const launchTemplate = launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate;

    const [revisionIdToRiskedInstanceIdsMap, revisionIds] =
        useMemo(
            () => {
                const revisionIdToRiskedInstanceIdsMap =
                    _(launchTemplateModel.revisionIdToDataMap).
                        mapValues(revision => _.intersection(revision.instanceIds, riskModel.risk.aggregatedEntityIds)).
                        pickBy(instanceIds => !_.isEmpty(instanceIds)).
                        value();
                const revisionIds =
                    _(riskModel.risk.launchTemplateRevisionIdToUserDataSecretExistsTextBlockMap).
                        keys().
                        map(Number).
                        value();
                return [revisionIdToRiskedInstanceIdsMap, revisionIds];
            },
            [launchTemplateModel]);
    const [selectedRevisionId, setSelectedRevisionId] = useState<number>(_.head(revisionIds)!);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskLaunchTemplateDefinition.detailsSection",
            () => ({
                excludeAction: "Exclude Secret",
                instances: "Instances",
                revisionUserData: "Launch Template Revision User Data",
                selectedRevision: {
                    default: "Default : {{revisionId}}",
                    latest: "Latest : {{revisionId}}"
                }
            }));

    const theme = useTheme();
    return (
        <Stack sx={{ overflow: "hidden auto" }}>
            <Box
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <ItemSelector
                    dense={true}
                    fullWidth={true}
                    items={revisionIds}
                    selectedItem={selectedRevisionId}
                    sorted={false}
                    onSelectedItemChanged={revisionId => setSelectedRevisionId(revisionId)}>
                    {(revisionId: number) =>
                        revisionId === launchTemplate.defaultRevisionId
                            ? localization.selectedRevision.default({ revisionId })
                            : revisionId === launchTemplate.latestRevisionId
                                ? localization.selectedRevision.latest({ revisionId })
                                : revisionId}
                </ItemSelector>
            </Box>
            {!_.isEmpty(revisionIdToRiskedInstanceIdsMap[selectedRevisionId])
                ? <Box
                    sx={{
                        flex: 1,
                        paddingTop: theme.spacing(2),
                        width: "100%"
                    }}>
                    {localization.instances()}
                    <Loading>
                        <InstanceTable
                            instanceIds={revisionIdToRiskedInstanceIdsMap[selectedRevisionId]}
                            riskModel={riskModel}/>
                    </Loading>
                </Box>
                : undefined}
            <Box
                sx={{
                    flex: 1,
                    paddingTop: theme.spacing(2),
                    width: "100%"
                }}>
                {localization.revisionUserData()}
                <Loading>
                    <UserData
                        riskModel={riskModel}
                        textBlock={riskModel.risk.launchTemplateRevisionIdToUserDataSecretExistsTextBlockMap![selectedRevisionId]}/>
                </Loading>
            </Box>
        </Stack>);
}