import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useAzureDefaultResourceInfoItemElements, useCustomEntityPropertyInfoItemElements } from "../..";
import { Contract, InfoCard } from "../../../../../../../../../../../common";
import { AzureNetworkNetworkSecurityGroupRulesTable } from "../../../../../../../../../../../tenants";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../../components";

export function useAzureNetworkNetworkSecurityGroupDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const networkSecurityGroup = resourceGroupResourceModel.entity as Contract.AzureNetworkNetworkSecurityGroup;
    const networkSecurityGroupModel = resourceGroupResourceModel as Contract.AzureNetworkNetworkSecurityGroupModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkNetworkSecurityGroupDefinition",
            () => ({
                info: {
                    cards: {
                        inboundRules: "Inbound Rules",
                        outboundRules: "Outbound Rules",
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        subnetIds: "Subnets"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={networkSecurityGroupModel.subnetIds}
                        entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}
                        key="subnetIds"
                        title={localization.info.items.subnetIds()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={networkSecurityGroupModel.networkedResourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
                <InfoCard title={localization.info.cards.outboundRules()}>
                    <AzureNetworkNetworkSecurityGroupRulesTable rules={networkSecurityGroup.outboundRules}/>
                </InfoCard>
                <InfoCard title={localization.info.cards.inboundRules()}>
                    <AzureNetworkNetworkSecurityGroupRulesTable rules={networkSecurityGroup.inboundRules}/>
                </InfoCard>
            </Info>
    });
}