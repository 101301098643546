import { useTheme } from "@mui/material";
import React from "react";
import { useLocalization } from "../../../../hooks";
import { Action0 } from "../../../../types";
import { ActionButton } from "../../../ActionButton";
import { DataTableSummaryToggleIcon } from "./icons";

type DataTableSummaryToggleProps = {
    onClick?: Action0 | (() => Promise<void>);
    visible?: boolean;
};

export function DataTableSummaryToggle({ onClick, visible }: DataTableSummaryToggleProps) {
    const localization =
        useLocalization(
            "infrastructure.dataTable.dataTableSummaryToggle",
            () => ({
                tooltip: {
                    hide: "Hide Summary",
                    show: "Show Summary"
                }
            }));

    const theme = useTheme();
    return (
        <ActionButton
            size="medium"
            sx={{
                "&:hover": {
                    backgroundColor:
                        visible
                            ? theme.palette.action.hover
                            : "transparent"
                },
                backgroundColor:
                    visible
                        ? theme.palette.action.hover
                        : "transparent",
                padding: theme.px(5)
            }}
            tooltip={
                visible
                    ? localization.tooltip.show()
                    : localization.tooltip.hide()}
            variant="outlined"
            onClick={onClick}>
            <DataTableSummaryToggleIcon sx={{ fontSize: theme.px(16) }}/>
        </ActionButton>);
}