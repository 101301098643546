import { Action0, ItemWithId, map } from "@infrastructure";
import React, { Fragment } from "react";
import { Contract } from "../../../../../../controllers";
import { ObjectTypeMetadataModelHelper } from "../../../../../../utilities";
import { CommonEntityAttributes, CommonSelfId, CommonSeverity, CommonTenantType, CommonVendor, InfraBoolean, InfraDate, InfraDateTime, InfraEnum, InfraInteger, InfraString, InfraUrl } from "./components";

export type UdmObjectPropertyFilterProps = {
    loadedFilterValuesPageSize?: number;
    objectTypeName: string;
    onClearClicked: Action0;
    onFilterChange: (data: UdmObjectPropertyFilterData) => void;
    propertyId: Contract.UdmObjectPropertyId;
    rule: ItemWithId<Contract.UdmQueryRule>;
};

type UdmObjectPropertyFilterData = {
    operator: Contract.UdmQueryRuleOperator;
    value: any;
};

export function UdmObjectPropertyFilter(props: UdmObjectPropertyFilterProps) {
    return map(
        ObjectTypeMetadataModelHelper.getProperty(props.propertyId).dataType,
        {
            [Contract.UdmObjectPropertyDataType.CommonEntityAttributes]: () => <CommonEntityAttributes {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonEntityTags]: () => <Fragment/>,
            [Contract.UdmObjectPropertyDataType.CommonSelfId]: () => <CommonSelfId {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonSeverity]: () => <CommonSeverity {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonTenantType]: () => <CommonTenantType {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonVendor]: () => <CommonVendor {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraBoolean]: () => <InfraBoolean {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraByteSize]: () => <Fragment/>,
            [Contract.UdmObjectPropertyDataType.InfraDate]: () => <InfraDate {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraDateTime]: () => <InfraDateTime {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraEnum]: () => <InfraEnum {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraInteger]: () => <InfraInteger {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraIpAddress]: () => <Fragment/>,
            [Contract.UdmObjectPropertyDataType.InfraString]: () => <InfraString {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraTimeSpan]: () => <Fragment/>,
            [Contract.UdmObjectPropertyDataType.InfraUrl]: () => <InfraUrl {...props}/>
        });
}