import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsSnsTopicRiskContext } from "../contexts";

export function useAwsSnsTopicEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSnsTopicEncryptionDisabledRisk;
    const topicModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSnsTopicModel;

    const getAwsSnsTopicRiskContext = useGetAwsSnsTopicRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSnsTopicEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{topic}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Before enabling the encryption, verify that applications using the topic have the necessary permissions to use the customer master key associated with the topic",
                        step2: "In the **Encryption - optional** section, enable **Encryption**",
                        step3: "Select the relevant KMS key. By default, the managed SNS key will be used",
                        step4: "Click **Save changes**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            topic:
                <Entity
                    entityIdOrModel={topicModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Sns,
                AwsConsoleUrlBuilder.getSnsTopicEditUrl(topicModel.entity as Contract.AwsSnsTopic)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const topicRiskContext = getAwsSnsTopicRiskContext(topicModel);

            return [
                topicRiskContext.generalInformation,
                topicRiskContext.subscriptions,
                topicRiskContext.accessLevel,
                topicRiskContext.publicAccessPermissionActions,
                topicRiskContext.sensitive,
                topicRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}