import { StringHelper, useExecuteOperation, useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ContentPages, ContentPagesItem, CoverPage, PageData } from "..";
import { CommonRiskPolicyItem, Contract, CustomRiskPolicyItem, RiskController, RiskPoliciesType, useRiskPolicyCategoryTranslator, useRiskPolicyTranslator, useTenantTypeTranslator } from "../../../../../../common";
import { GroupedItem, useItems } from "../../../../../Customer/components";
import { Item } from "./components";

type RiskPolicyReportProps = {
    scopeId: string;
};

export function RiskPolicyReport({ scopeId }: RiskPolicyReportProps) {
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.riskPolicyReport",
            () => ({
                overview: {
                    subtitle: "View a breakdown of Tenable Cloud Security policies by logical category to understand the scope and severity of misconfigurations in your environment, as identified by each policy. For every policy, view the specific compliance standards that map to policy benchmarks, the total number of resources monitored, and the number of resources that passed or failed (grouped by severity) those benchmarks.",
                    title: "Policy Overview"
                },
                subtitle: "This report provides an overview of misconfigurations in your environment, by giving you a high-level snapshot of how resources are assessed by Tenable Cloud Security policies. Quickly see which compliance standards map to each policy. Gain insight into the scope of such misconfigurations by viewing how many resources failed to pass policy benchmarks, and the resulting severity levels of such failures.",
                title: "MISCONFIGURATIONS REPORT"
            }));

    const items =
        useItems(
            RiskPoliciesType.Cloud,
            false,
            scopeId);
    const [enrichedItems] =
        useExecuteOperation(
            RiskPolicyReport,
            async () => {
                const builtInItems =
                    _.filter(
                        items,
                        item => item.riskPolicyTypeMetadata.category !== Contract.RiskPolicyCategory.Custom);
                const { riskPolicyDatas } =
                    await RiskController.getRiskPolicyAnalyzedEntityCount(
                        new Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequest(
                            _.map(
                                builtInItems,
                                builtInItem =>
                                    new Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequestRiskPolicyData(
                                        Contract.RiskPolicyTypeMetadataAnalysisGroupType.AllEntities,
                                        builtInItem.riskPolicyConfigurationTypeNameOrId)),
                            scopeId));
                _.each(
                    builtInItems,
                    builtInItem =>
                        builtInItem.result.analyzedEntityCount =
                            _.find(
                                riskPolicyDatas,
                                riskPolicyData => riskPolicyData.riskPolicyIdentifier === builtInItem.riskPolicyConfigurationTypeNameOrId)!.
                                analyzedEntityCount);

                return items;
            });

    const groupedItems =
        useMemo(
            () => {
                const customRiskPolicyConfigurationTypeNames = [
                    Contract.TypeNames.AwsInboundExternalSubnetRiskPolicyConfiguration,
                    Contract.TypeNames.AwsInboundExternalVpcRiskPolicyConfiguration,
                    Contract.TypeNames.AwsNetworkedResourceInboundExternalPortRiskPolicyConfiguration,
                    Contract.TypeNames.AwsPrincipalAllowedPermissionRiskPolicyConfiguration,
                    Contract.TypeNames.AwsPrincipalAllowedResourcePermissionRiskPolicyConfiguration,
                    Contract.TypeNames.AwsRoleTemplateMismatchRiskPolicyConfiguration,
                    Contract.TypeNames.AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration,
                    Contract.TypeNames.AzurePrincipalNotAllowedResourcePermissionRiskPolicyConfiguration,
                    Contract.TypeNames.AzureResourceInboundExternalPortRiskPolicyConfiguration,
                    Contract.TypeNames.GcpEntityInboundExternalPortRiskPolicyConfiguration,
                    Contract.TypeNames.GcpPrincipalAllowedResourcePermissionRiskPolicyConfiguration,
                    Contract.TypeNames.GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration
                ];

                return _(enrichedItems).
                    filter(
                        item =>
                            item.enabled &&
                            !item.riskPolicyTypeMetadata.temporal &&
                            (item.riskPolicyTypeMetadata.category !== Contract.RiskPolicyCategory.Custom || _.includes(customRiskPolicyConfigurationTypeNames, item.riskPolicyConfigurationTypeName))).
                    groupBy(item => item.riskPolicyTypeMetadata.category).
                    map((riskPolicyCategoryItems, riskPolicyCategory: Contract.RiskPolicyCategory) => new GroupedItem(riskPolicyCategory, riskPolicyCategoryItems)).
                    as<GroupedItem>().
                    orderBy([
                        groupedItem => groupedItem.riskPolicyCategory === Contract.RiskPolicyCategory.Custom,
                        groupedItem => StringHelper.getSortValue(riskPolicyCategoryTranslator(groupedItem.riskPolicyCategory))
                    ]).
                    value();
            },
            [enrichedItems]);

    return (
        <PageData reportTitle={localization.title()}>
            <CoverPage
                reportType={Contract.ReportType.RiskPolicy}
                scopeId={scopeId}
                subtitle={localization.subtitle()}/>
            <ContentPages number={1}>
                <ContentPagesItem>
                    <Grid
                        container={true}
                        direction="column">
                        <Grid item={true}>
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    fontWeight: 600
                                }}>
                                {localization.overview.title()}
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography sx={{ fontSize: "14px" }}>
                                {localization.overview.subtitle()}
                            </Typography>
                        </Grid>
                    </Grid>
                </ContentPagesItem>
                {_.flatMap(
                    groupedItems,
                    groupedItem =>
                        _(groupedItem.tableItems).
                            orderBy([
                                riskPolicyTableItem =>
                                    StringHelper.getCombineSortValue(
                                        riskPolicyTableItem.tenantTypes.length,
                                        ...(
                                            _.map(
                                                riskPolicyTableItem.tenantTypes,
                                                tenantType => tenantTypeTranslator(tenantType)))),
                                riskPolicyTableItem =>
                                    riskPolicyTableItem instanceof CommonRiskPolicyItem
                                        ? StringHelper.getSortValue(riskPolicyTranslator(riskPolicyTableItem.riskPolicyConfigurationTypeName).title)
                                        : StringHelper.getSortValue((riskPolicyTableItem as CustomRiskPolicyItem).riskPolicyConfiguration.name)
                            ]).
                            map(
                                (riskPolicyTableItem, riskPolicyTableItemIndex) =>
                                    <ContentPagesItem
                                        key={`${groupedItem.riskPolicyCategory}-${riskPolicyTableItemIndex}`}
                                        paddingTop={
                                            riskPolicyTableItemIndex === 0
                                                ? 40
                                                : undefined}>
                                        {({ pageFirstItem, pageLastItem }) =>
                                            <Item
                                                groupedItem={groupedItem}
                                                pageFirstItem={pageFirstItem}
                                                pageLastItem={pageLastItem}
                                                scopeId={scopeId}
                                                tableItem={riskPolicyTableItem}
                                                tableItemIndex={riskPolicyTableItemIndex}/>}
                                    </ContentPagesItem>).
                            value())}
            </ContentPages>
        </PageData>);
}