import { EmptyMessage, useLocalization } from "@infrastructure";
import _ from "lodash";
import { ReactNode, useMemo } from "react";
import { ItemToRiskSeverityToRiskCountList } from "./components";

type TopRisksProps = {
    children: (item: string) => ReactNode;
    getRisksRelativeUrl: (tenantId: any, severity?: any) => string;
    itemToRiskSeverityToRiskCount: _.Dictionary<_.Dictionary<number>>;
};

export function TopRisks({ children, getRisksRelativeUrl, itemToRiskSeverityToRiskCount }: TopRisksProps) {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.topRisks",
            () => ({
                empty: "No Findings"
            }));

    const riskCount =
        useMemo(
            () =>
                _(itemToRiskSeverityToRiskCount).
                    values().
                    sum(),
            [itemToRiskSeverityToRiskCount]);

    return riskCount === 0
        ? <EmptyMessage
            message={localization.empty()}
            verticalCenter={true}/>
        : <ItemToRiskSeverityToRiskCountList
            getRisksRelativeUrl={getRisksRelativeUrl}
            itemToRiskSeverityToRiskCount={itemToRiskSeverityToRiskCount}>
            {children}
        </ItemToRiskSeverityToRiskCountList>;
}