import _ from "lodash";
import { v4 as uuid } from "uuid";
import { DashboardWidgetData } from "..";

export function generateWidgetId() {
    return uuid();
}

export function compactLayout(widgets: DashboardWidgetData[], widgetWidths: { [key: string]: number[] }) {
    return compactLayoutStep(
        _.reverse(widgets),
        widgetWidths,
        [])!;
}

function compactLayoutStep(widgets: DashboardWidgetData[], widgetIdsToWidths: { [key: string]: number[] }, compactedWidgets: DashboardWidgetData[]): DashboardWidgetData[] | undefined {
    if (_.isEmpty(widgets)) {
        return compactedWidgets;
    }

    const lastWidget = _.last(widgets)!;
    const compactedWidgetsTotalSize =
        _(compactedWidgets).
            map(widget => widget.location.width!).
            sum();

    const widgetWidths = widgetIdsToWidths[lastWidget.type];
    for (const itemAvailableSize of widgetWidths) {
        if (compactedWidgetsTotalSize % 12 + lastWidget.location.width! > 12) {
            continue;
        }

        const newCompactedWidgets =
            compactLayoutStep(
                _.slice(
                    widgets,
                    0,
                    -1),
                widgetIdsToWidths,
                _.concat(
                    compactedWidgets,
                    {
                        ...lastWidget,
                        location: {
                            ...lastWidget.location,
                            width: itemAvailableSize
                        }
                    }));

        if (!_.isNil(newCompactedWidgets)) {
            return newCompactedWidgets;
        }
    }

    return undefined;
}