import _ from "lodash";
import { useCallback, useMemo } from "react";
import { scopeNodeModelStore, scopeSystemEntityModelStore } from "..";

export function useGetComplianceScopeId() {
    const customComplianceModels = scopeSystemEntityModelStore.useGetCustomCompliance();
    const customComplianceModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customComplianceModels,
                    customComplianceModel => customComplianceModel.configuration.id),
            [customComplianceModels]);
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);

    return useCallback(
        (complianceIdentifier: string, scopeId: string) =>
            _.isNil(customComplianceModelMap[complianceIdentifier]) ||
            scopeNodeMap[scopeId].parentScopeIds.includes(customComplianceModelMap[complianceIdentifier].configuration.scopeId)
                ? scopeId
                : customComplianceModelMap[complianceIdentifier].configuration.scopeId,
        [customComplianceModelMap, scopeNodeMap]);
}