import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureNetworkPublicIpAddressDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureNetworkPublicIpAddressDefinition",
            () => ({
                columns: {
                    attachedResourceId: "Attached To",
                    dnsName: "DNS Name",
                    ipAddress: "IP Address",
                    sku: {
                        title: "SKU",
                        [Contract.TypeNames.AzureNetworkPublicIpAddressSku]: {
                            [Contract.AzureNetworkPublicIpAddressSku.Basic]: "Basic",
                            [Contract.AzureNetworkPublicIpAddressSku.Standard]: "Standard"
                        }
                    },
                    type: {
                        title: "Allocation Method",
                        [Contract.TypeNames.AzureNetworkPublicIpAddressType]: {
                            [Contract.AzureNetworkPublicIpAddressType.Dynamic]: "Dynamic",
                            [Contract.AzureNetworkPublicIpAddressType.Static]: "Static"
                        }
                    }
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkPublicIpAddressModel) => ({
                            [localization.columns.dnsName()]: (item.entity as Contract.AzureNetworkPublicIpAddress).dnsName ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkPublicIpAddressDnsName)}
                                placeholder={localization.columns.dnsName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkPublicIpAddressDnsName}
                key={Contract.EntityModelProperty.AzureNetworkPublicIpAddressDnsName}
                render={optionalTableCell<Contract.AzureNetworkPublicIpAddressModel>(item => (item.entity as Contract.AzureNetworkPublicIpAddress).dnsName)}
                title={localization.columns.dnsName()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkPublicIpAddressModel) => ({
                            [localization.columns.ipAddress()]: (item.entity as Contract.AzureNetworkPublicIpAddress).ipAddress ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkPublicIpAddress)}
                                placeholder={localization.columns.ipAddress()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkPublicIpAddress}
                key={Contract.EntityModelProperty.AzureNetworkPublicIpAddress}
                render={optionalTableCell<Contract.AzureNetworkPublicIpAddressModel>(item => (item.entity as Contract.AzureNetworkPublicIpAddress).ipAddress)}
                title={localization.columns.ipAddress()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkPublicIpAddressModel) => ({
                            [localization.columns.sku.title()]: localization.columns.sku[Contract.TypeNames.AzureNetworkPublicIpAddressSku][(item.entity as Contract.AzureNetworkPublicIpAddress).sku]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureNetworkPublicIpAddressSku}
                                enumTypeTranslator={(sku: Contract.AzureNetworkPublicIpAddressSku) => localization.columns.sku[Contract.TypeNames.AzureNetworkPublicIpAddressSku][sku]()}
                                placeholder={localization.columns.sku.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkPublicIpAddressSku}
                itemProperty={(item: Contract.AzureNetworkPublicIpAddressModel) => localization.columns.sku[Contract.TypeNames.AzureNetworkPublicIpAddressSku][(item.entity as Contract.AzureNetworkPublicIpAddress).sku]()}
                key={Contract.EntityModelProperty.AzureNetworkPublicIpAddressSku}
                title={localization.columns.sku.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkPublicIpAddressModel) => ({
                            [localization.columns.type.title()]: localization.columns.type[Contract.TypeNames.AzureNetworkPublicIpAddressType][(item.entity as Contract.AzureNetworkPublicIpAddress).type]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureNetworkPublicIpAddressType}
                                enumTypeTranslator={(type: Contract.AzureNetworkPublicIpAddressType) => localization.columns.type[Contract.TypeNames.AzureNetworkPublicIpAddressType][type]()}
                                placeholder={localization.columns.type.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkPublicIpAddressType}
                itemProperty={(item: Contract.AzureNetworkPublicIpAddressModel) => localization.columns.type[Contract.TypeNames.AzureNetworkPublicIpAddressType][(item.entity as Contract.AzureNetworkPublicIpAddress).type]()}
                key={Contract.EntityModelProperty.AzureNetworkPublicIpAddressType}
                title={localization.columns.type.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkPublicIpAddressModel>(
                        Contract.TypeNames.AzureResourceGroupResource,
                        item => [item.attachedResourceId],
                        localization.columns.attachedResourceId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkPublicIpAddressAttachedResource)}
                                placeholder={localization.columns.attachedResourceId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkPublicIpAddressAttachedResource}
                key={Contract.EntityModelProperty.AzureNetworkPublicIpAddressAttachedResource}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkPublicIpAddressModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.attachedResourceId}
                            entityTypeName={Contract.TypeNames.AzureResourceGroupResource}
                            entityVariant="iconText"/>}
                title={localization.columns.attachedResourceId()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureNetworkPublicIpAddressRequest(
                new Contract.EntityControllerGetEntityModelPageAzureNetworkPublicIpAddressRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkPublicIpAddressAttachedResource]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkPublicIpAddressDnsName]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkPublicIpAddress]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkPublicIpAddressSku]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkPublicIpAddressType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}