import { useCallback } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetVirtualMachineRiskContext } from "../../../useGetVirtualMachineRiskContext";
import { useGetVirtualMachinesRiskContext } from "../../../useGetVirtualMachinesRiskContext";

export function useGetVirtualMachinesWorkloadAnalysisRiskContext(workloadAnalysisRisk: Contract.WorkloadAnalysisRisk) {
    const getVirtualMachineRiskContext = useGetVirtualMachineRiskContext();
    const getVirtualMachinesRiskContext = useGetVirtualMachinesRiskContext();

    return useCallback(
        () =>
            workloadAnalysisRisk.singleVirtualMachine
                ? getVirtualMachineRiskContext(workloadAnalysisRisk.virtualMachineIds[0])
                : getVirtualMachinesRiskContext(workloadAnalysisRisk.virtualMachineIds),
        [workloadAnalysisRisk]);
}