import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { PolicySection } from "../../components";
import { useGetAwsKmsKeyRiskContext } from "../contexts";

export function useAwsKmsKeyPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsKmsKeyPublicAccessExistsRisk;
    const kmsKeyModel = entityModelStore.useGet(risk.entityId) as Contract.AwsKmsKeyModel;
    const kmsKey = kmsKeyModel.entity as Contract.AwsKmsKey;

    const getAwsKmsKeyRiskContext = useGetAwsKmsKeyRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsKmsKeyPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{key}} is publicly accessible via key policy",
                sections: {
                    policy: "Policy",
                    resolution: {
                        step1: "Disabling public access might break identities using the key. Before changing the key policy, verify that identities using the key can access it without public access",
                        step2: "Click **Edit** and change the policy statements that allows access to any AWS principal",
                        step3: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            key:
                <Entity
                    entityIdOrModel={kmsKeyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Kms,
                AwsConsoleUrlBuilder.getKmsKeyUrl(kmsKey)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const keyRiskContext = getAwsKmsKeyRiskContext(kmsKeyModel);

            return [
                keyRiskContext.generalInformation,
                keyRiskContext.enabled,
                keyRiskContext.sensitive,
                keyRiskContext.resources,
                keyRiskContext.publicAccessPermissionActions,
                keyRiskContext.accessLevel,
                keyRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={kmsKey.policyDocument}/>,
                    localization.sections.policy(),
                    {
                        expandable: true
                    })]
        });
}