import { AutocompleteItems, CheckboxField, DateField, DateTimeField, DropdownIcon, Link, Message, Optional, StringHelper, TimeFormatter, UnexpectedError, useActions, useAsyncEffect, useChangeEffect, useExecuteOperation, useInputValidation, useLocalization } from "@infrastructure";
import { Autocomplete, Box, CircularProgress, FormHelperText, List, ListItemButton, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { Ref, useEffect, useMemo, useRef, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RiskController, RiskType, TicketingServiceController, useTheme, useTicketMultipleRisksDescriptionTranslator } from "../..";
import { TicketingServiceMandatoryFieldSelector } from "..";
import { MandatoryLabelsSelector, UserFieldSelector, UserMandatoryFieldSelector } from "./components";

export type JiraDeliveryProjectSelectorData = Omit<Contract.JiraDeliveryProject, "integrationId" | "typeName" | "typeNames">;

export type JiraIssueSelectorProps = {
    actionsRef?: Ref<Optional<JiraIssueSelectorActions>>;
    disabled?: boolean;
    initialData?: JiraDeliveryProjectSelectorData;
    initialProjectNotValid?: boolean;
    instanceModels: Contract.ScopeSystemEntityModel[];
    multipleRisks?: boolean;
    onDataChanged: (data: Optional<JiraDeliveryProjectSelectorData>, dirty: boolean, selectorInfoTranslation?: string) => void;
    projectReadOnly?: boolean;
    projectReference?: JiraProjectReference;
    readOnly?: boolean;
    riskIds: string[];
    riskModel?: Contract.RiskModel;
    riskType?: RiskType;
};

export type JiraProjectReference = {
    instanceId: string;
    projectRawId: string;
};

export type JiraIssueSelectorActions = {
    reset: () => void;
};

export function JiraIssueSelector({ actionsRef, disabled = false, initialData, initialProjectNotValid = false, instanceModels, multipleRisks = false, onDataChanged, projectReadOnly = false, projectReference, readOnly = false, riskIds, riskModel, riskType }: JiraIssueSelectorProps) {
    const [{ riskIdToDataMap }] =
        useExecuteOperation(
            [JiraIssueSelector],
            async () => {
                if (multipleRisks || !_.isNil(riskModel)) {
                    switch (riskType) {
                        case RiskType.Cloud:
                            return await RiskController.getRiskIdToDataMap(new Contract.RiskControllerGetCloudRiskIdToDataMapRequest(riskIds));
                        case RiskType.Code:
                            return await RiskController.getRiskIdToDataMap(new Contract.RiskControllerGetCodeRiskIdToDataMapRequest(riskIds));
                        default:
                            throw new UnexpectedError("riskType", riskType);
                    }
                }
                return { riskIdToDataMap: undefined };
            });
    const ticketMultipleRisksDescriptionTranslator = useTicketMultipleRisksDescriptionTranslator();

    const projectDatas =
        useMemo(
            () =>
                _(instanceModels).
                    flatMap(
                        instanceModel =>
                            _((instanceModel.configuration as Contract.JiraInstanceConfiguration).projects).
                                filter(projectConfiguration => _.isNil((instanceModel.state as Contract.JiraInstanceState)?.projectRawIdToProjectMap[projectConfiguration.rawId]?.issue)).
                                map(
                                    projectConfiguration => ({
                                        ...projectConfiguration,
                                        attachmentsEnabled: (instanceModel.state as Contract.JiraInstanceState)?.attachmentsEnabled,
                                        deploymentType: (instanceModel.state as Contract.JiraInstanceState)?.deploymentType,
                                        instanceId: instanceModel.configuration.id,
                                        labels: (instanceModel.state as Contract.JiraInstanceState)?.labels ?? [],
                                        projectState: (instanceModel.state as Contract.JiraInstanceState)?.projectRawIdToProjectMap[projectConfiguration.rawId]
                                    })).
                                value()).
                    orderBy(projectData => StringHelper.getSortValue(projectData.projectState?.project.name)).
                    value(),
            []);

    const localization =
        useLocalization(
            "common.jiraIssueSelector",
            () => ({
                actions: {
                    projectDatas: {
                        error: {
                            links: {
                                jiraConfiguration: "Jira Configuration"
                            },
                            title: "There was a problem accessing your Jira projects, go to {{jiraConfigurationLink}} and make sure they are properly configured"
                        }
                    }
                },
                bulk: {
                    fieldsInfo: {
                        multipleRisks: "A zip file will be attached with the description of each finding and any relevant attachments.",
                        multipleRisksOverflow: "The description is too long for one Jira issue. The full description, including remediation steps will be attached as a file named \"fullDescription.txt\", along with any relevant attachments.",
                        notMultipleRisks: "Each Jira issue will contain the summary and description of each finding, including a link to the finding page and attached files if relevant."
                    }
                },
                fields: {
                    additionalMandatoryFields: {
                        autocomplete: {
                            empty: "No matching value"
                        },
                        error: {
                            date: "{{fieldName}} must contain a valid date",
                            dateTime: "{{fieldName}} must contain a valid date and time",
                            number: "{{fieldName}} is required",
                            required: "{{fieldName}} cannot be empty"
                        }
                    },
                    description: {
                        multipleRisksOverflow: "{{descriptions}}\n-----------------------------\nThe description is too long to be displayed fully. The full description, including remediation steps, is attached as a file named \"FullDescription.txt\".",
                        title: "Description"
                    },
                    epic: {
                        empty: "No matching epic",
                        error: {
                            required: "Epic cannot be empty"
                        },
                        title: "Epic"
                    },
                    fileNames: {
                        description: "Select whether to attach the new suggested policy to the jira issue",
                        title: "Attachments (optional)"
                    },
                    issueType: {
                        empty: "No matching issue type",
                        error: {
                            required: "Issue Type cannot be empty"
                        },
                        title: "Issue Type"
                    },
                    labels: {
                        error: {
                            required: "Labels cannot be empty",
                            spaces: "Labels cannot contain spaces"
                        },
                        title: "Labels"
                    },
                    project: {
                        empty: "No matching project",
                        error: "Failed to get Jira project",
                        title: "Project"
                    },
                    sprint: {
                        empty: "No matching sprint",
                        error: {
                            required: "Sprint cannot be empty"
                        },
                        title: "Sprint"
                    },
                    summary: {
                        error: {
                            required: "Summary cannot be empty"
                        },
                        multipleRisks: "{{riskCount}} findings",
                        title: "Summary"
                    },
                    user: {
                        empty: "No matching user",
                        error: {
                            required: "Assignee cannot be empty",
                            search: "Failed to get users"
                        },
                        title: "Assignee"
                    }
                },
                optional: "optional"
            }));

    const [descriptionTranslation, selectorInfoTranslation, summaryTranslation] =
        useMemo(
            () => {
                if (multipleRisks) {
                    let descriptionOverFlow = false;
                    const descriptions =
                        _(riskIdToDataMap).
                            keys().
                            sort().
                            map((riskId, index) => ({ index, riskId })).
                            reduce(
                                (description, { index, riskId }) => {
                                    if (descriptionOverFlow) {
                                        return description;
                                    }
                                    const riskDescriptionTranslation =
                                        ticketMultipleRisksDescriptionTranslator(
                                            riskIdToDataMap![riskId].extendedDescription,
                                            index + 1,
                                            riskIdToDataMap![riskId].extendedTitle,
                                            riskIdToDataMap![riskId].severity);
                                    const newDescription = `${description}\n\n${riskDescriptionTranslation}`.trim();
                                    if (newDescription.length > 30000) {
                                        descriptionOverFlow = true;
                                        return description;
                                    } else {
                                        return newDescription;
                                    }
                                },
                                "");
                    return [
                        descriptionOverFlow
                            ? localization.fields.description.multipleRisksOverflow({ descriptions })
                            : descriptions,
                        descriptionOverFlow
                            ? localization.bulk.fieldsInfo.multipleRisksOverflow()
                            : localization.bulk.fieldsInfo.multipleRisks(),
                        localization.fields.summary.multipleRisks({ riskCount: riskIds.length })
                    ];
                }

                return !_.isNil(riskModel)
                    ? [
                        riskIdToDataMap![riskModel.id].extendedDescription,
                        undefined,
                        riskIdToDataMap![riskModel.id].extendedTitle
                    ]
                    : [undefined, localization.bulk.fieldsInfo.notMultipleRisks(), undefined];
            },
            []);
    const dirtyRef = useRef(false);
    const updateDirtyRef = useRef(false);
    const [description, setDescription] = useState(initialData?.issueCreationData.description ?? descriptionTranslation);
    const [epic, setEpic] = useState(initialData?.issueCreationData.epic);
    const [epics, setEpics] = useState<Contract.JiraEpic[]>([]);
    const [fileNames, setFileNames] =
        useState(
            _.isNil(initialData)
                ? undefined
                : initialData.issueCreationData.fileNames ?? []);
    const [getProjectError, setGetProjectError] = useState(false);
    const [getProjectExecuting, setGetProjectExecuting] = useState(false);
    const [issueTypeName, setIssueTypeName] = useState(initialData?.issueCreationData.typeName);
    const [issueTypeNames, setIssueTypeNames] = useState<string[]>([]);
    const [issueTypeNameToFieldsMap, setIssueTypeNameToFieldsMap] = useState<_.Dictionary<Contract.JiraField[]>>({});
    const [issueTypeNameToAdditionalMandatoryFieldsMap, setIssueTypeNameToAdditionalMandatoryFieldsMap] = useState<_.Dictionary<Contract.JiraField[]>>({});
    const [labels, setLabels] = useState(initialData?.issueCreationData.labels);
    const [additionalMandatoryFieldNameToValuesMap, setAdditionalMandatoryFieldNameToValuesMap] = useState<_.Dictionary<string[]>>(initialData?.issueCreationData.additionalMandatoryFieldNameToValuesMap ?? {});
    const [additionalMandatoryFieldNameToErrorMessageMap, setAdditionalMandatoryFieldNameToErrorMessageMap] =
        useState<_.Dictionary<string | undefined>>(
            () =>
                _.mapValues(
                    additionalMandatoryFieldNameToValuesMap,
                    (values, additionalMandatoryFieldName) =>
                        _.isEmpty(values)
                            ? localization.fields.additionalMandatoryFields.error.required({ fieldName: additionalMandatoryFieldName })
                            : undefined));

    useChangeEffect(
        () => {
            setAdditionalMandatoryFieldNameToValuesMap({});
            setAdditionalMandatoryFieldNameToErrorMessageMap({});
        },
        [issueTypeName]);

    const [projectData, setProjectData] =
        useState<Optional<JiraIssueSelectorProjectData>>(
            () =>
                initialProjectNotValid
                    ? undefined
                    : projectDatas.length === 1
                        ? projectDatas[0]
                        : _.find(
                            projectDatas,
                            projectData =>
                                (projectData.instanceId === initialData?.instanceId && projectData.rawId === initialData?.issueCreationData.projectRawId) ||
                                (projectData.instanceId === projectReference?.instanceId && projectData.rawId === projectReference?.projectRawId)) as JiraIssueSelectorProjectData);
    const [sprint, setSprint] = useState(initialData?.issueCreationData.sprint);
    const [sprints, setSprints] = useState<Contract.JiraSprint[]>([]);
    const [summary, setSummary] = useState(initialData?.issueCreationData.summary ?? summaryTranslation);
    const [user, setUser] = useState<Contract.JiraUser | undefined>();

    const [epicValidationController, epicValidationMessage] =
        useInputValidation(
            () => {
                if (epicRequired &&
                    _.isNil(epic)) {
                    return localization.fields.epic.error.required();
                }

                return undefined;
            },
            [epic]);
    const [issueTypeValidationController, issueTypeValidationMessage] =
        useInputValidation(
            () => {
                if (_.isEmpty(issueTypeName)) {
                    return localization.fields.issueType.error.required();
                }

                return undefined;
            },
            [issueTypeName]);
    const [labelsValidationController, labelsValidationMessage] =
        useInputValidation(
            () => {
                if (labelsRequired &&
                    _.isNil(labels)) {
                    return localization.fields.labels.error.required();
                }
                if (!_.isNil(labels) &&
                    _.some(
                        labels,
                        label => _.includes(label, " "))) {
                    return localization.fields.labels.error.spaces();
                }

                return undefined;
            },
            [labels]);
    const [sprintValidationController, sprintValidationMessage] =
        useInputValidation(
            () => {
                if (sprintRequired &&
                    _.isNil(sprint)) {
                    return localization.fields.sprint.error.required();
                }

                return undefined;
            },
            [sprint]);
    const [summaryValidationController, summaryValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(riskModel) && !multipleRisks) {
                    return undefined;
                }

                const validationSummary = summary!.trim();
                if (_.isEmpty(validationSummary)) {
                    return localization.fields.summary.error.required();
                }

                return undefined;
            },
            [summary]);
    const [userValidationController, userValidationMessage] =
        useInputValidation(
            () => {
                if (userRequired &&
                    _.isNil(user)) {
                    return localization.fields.user.error.required();
                }

                return undefined;
            },
            [user]);

    const actions =
        useActions<JiraIssueSelectorActions>(
            actionsRef,
            {
                reset() {
                    setDescription(descriptionTranslation);
                    setEpic(undefined);
                    setIssueTypeName(undefined);
                    setLabels(undefined);
                    setSprint(undefined);
                    setSummary(summaryTranslation);
                    setUser(undefined);

                    epicValidationController.clear();
                    issueTypeValidationController.clear();
                    labelsValidationController.clear();
                    sprintValidationController.clear();
                    summaryValidationController.clear();
                    userValidationController.clear();

                    dirtyRef.current = false;
                    updateDirtyRef.current = false;
                }
            });

    useEffect(
        () => {
            if (updateDirtyRef.current) {
                dirtyRef.current = true;
            }

            updateDirtyRef.current = true;

            const valid =
                !_.isNil(projectData) &&
                !_.isNil(issueTypeName) &&
                epicValidationController.isValid() &&
                issueTypeValidationController.isValid() &&
                labelsValidationController.isValid() &&
                sprintValidationController.isValid() &&
                summaryValidationController.isValid() &&
                userValidationController.isValid() &&
                _.size(additionalMandatoryFieldNameToValuesMap) === _.size(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName]) &&
                !_.some(
                    additionalMandatoryFieldNameToErrorMessageMap,
                    additionalMandatoryFieldErrorMessage => !_.isNil(additionalMandatoryFieldErrorMessage));
            if (valid) {
                onDataChanged(
                    {
                        instanceId: projectData!.instanceId,
                        issueCreationData:
                            new Contract.JiraIssueCreationData(
                                additionalMandatoryFieldNameToValuesMap,
                                description,
                                epic,
                                projectData!.attachmentsEnabled
                                    ? fileNames ?? []
                                    : [],
                                labels,
                                projectData!.rawId,
                                sprint,
                                summary,
                                issueTypeName!,
                                user!)
                    },
                    dirtyRef.current,
                    selectorInfoTranslation);
            } else {
                onDataChanged(undefined, dirtyRef.current, selectorInfoTranslation);
            }
        },
        [additionalMandatoryFieldNameToErrorMessageMap, additionalMandatoryFieldNameToValuesMap, description, epic, fileNames, issueTypeName, issueTypeNameToAdditionalMandatoryFieldsMap, labels, projectData, sprint, summary, user]);

    const firstProjectDataEffectRef = useRef(true);
    useAsyncEffect(
        async () => {
            if (_.isNil(projectData)) {
                return;
            }

            setGetProjectExecuting(true);
            setGetProjectError(false);

            if (!firstProjectDataEffectRef.current) {
                actions.reset();
            }

            setEpics([]);
            setIssueTypeNames([]);
            setSprints([]);
            try {
                const { epics, issueTypeNames, issueTypeNameToAdditionalMandatoryFieldsMap, issueTypeNameToFieldsMap, sprints } =
                    await TicketingServiceController.getJiraProject(
                        new Contract.TicketingServiceControllerGetJiraProjectRequest(
                            projectData.rawId,
                            projectData.instanceId));
                setEpics(
                    _.orderBy(
                        epics,
                        epic => StringHelper.getSortValue(epic.name)));
                setIssueTypeNames(
                    _.orderBy(
                        issueTypeNames,
                        issueTypeName => StringHelper.getSortValue(issueTypeName)));
                setIssueTypeNameToAdditionalMandatoryFieldsMap(issueTypeNameToAdditionalMandatoryFieldsMap);
                setIssueTypeNameToFieldsMap(issueTypeNameToFieldsMap);
                setSprints(
                    _.orderBy(
                        sprints,
                        sprint => StringHelper.getSortValue(sprint.name)));
            } catch {
                setGetProjectError(true);
            }

            setGetProjectExecuting(false);
            firstProjectDataEffectRef.current = false;
        },
        [projectData]);

    const [epicRequired, labelsRequired, sprintRequired, userRequired] =
        useMemo(
            () => {
                let epicRequired = undefined;
                let labelsRequired = undefined;
                let sprintRequired = undefined;
                let userRequired = undefined;
                if (!_.isNil(issueTypeNameToFieldsMap[issueTypeName!])) {
                    for (const field of issueTypeNameToFieldsMap[issueTypeName!]) {
                        switch (field.id) {
                            case projectData?.projectState.project.epicLinkFieldId:
                                if (projectData?.deploymentType === Contract.JiraInstanceDeploymentType.OnPrem) {
                                    epicRequired = field.required;
                                }
                                break;
                            case "parent":
                                if (projectData?.deploymentType === Contract.JiraInstanceDeploymentType.Cloud) {
                                    epicRequired = field.required;
                                }
                                break;
                            case "labels":
                                labelsRequired = field.required;
                                break;
                            case projectData?.projectState.project.sprintFieldId:
                                sprintRequired = field.required;
                                break;
                            case "assignee":
                                userRequired = field.required;
                                break;
                        }
                    }
                }

                return [epicRequired, labelsRequired, sprintRequired, userRequired];
            },
            [issueTypeName, issueTypeNameToFieldsMap]);

    const [additionalMandatoryDateFields, additionalMandatoryDateTimeFields, additionalMandatoryLabelsFields, additionalMandatoryMultiSelectionFields, additionalMandatoryNumberFields, additionalMandatorySingleSelectionFields, additionalMandatoryStringFields, additionalMandatoryUserFields] =
        useMemo(
            () => {
                const additionalMandatoryDateFields =
                    _(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName!]).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.JiraDateField).
                        as<Contract.JiraDateField>().
                        value();
                const additionalMandatoryDateTimeFields =
                    _(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName!]).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.JiraDateTimeField).
                        as<Contract.JiraDateTimeField>().
                        value();
                const additionalMandatoryLabelsFields =
                    _(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName!]).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.JiraCustomLabelsField).
                        as<Contract.JiraCustomLabelsField>().
                        value();
                const additionalMandatoryMultiSelectionFields =
                    _(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName!]).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.JiraMultiSelectionField).
                        as<Contract.JiraMultiSelectionField>().
                        value();
                const additionalMandatoryNumberFields =
                    _(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName!]).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.JiraNumberField).
                        as<Contract.JiraNumberField>().
                        value();
                const additionalMandatorySingleSelectionFields =
                    _(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName!]).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.JiraSingleSelectionField).
                        as<Contract.JiraSingleSelectionField>().
                        value();
                const additionalMandatoryStringFields =
                    _(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName!]).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.JiraStringField).
                        as<Contract.JiraStringField>().
                        value();
                const additionalMandatoryUserFields =
                    _(issueTypeNameToAdditionalMandatoryFieldsMap[issueTypeName!]).
                        filter(additionalMandatoryField => additionalMandatoryField.typeName === Contract.TypeNames.JiraUserField).
                        as<Contract.JiraUserField>().
                        value();

                return [additionalMandatoryDateFields, additionalMandatoryDateTimeFields, additionalMandatoryLabelsFields, additionalMandatoryMultiSelectionFields, additionalMandatoryNumberFields, additionalMandatorySingleSelectionFields, additionalMandatoryStringFields, additionalMandatoryUserFields];
            },
            [issueTypeName, issueTypeNameToAdditionalMandatoryFieldsMap]);

    useChangeEffect(
        () => {
            setAdditionalMandatoryFieldNameToValuesMap({
                ..._(additionalMandatoryDateFields).
                    filter(additionalMandatoryDateField => !_.isNil(additionalMandatoryDateField.defaultDate)).
                    keyBy(additionalMandatoryDateField => additionalMandatoryDateField.name).
                    mapValues(additionalMandatoryDateField => [additionalMandatoryDateField.defaultDate!]).
                    value(),
                ..._(additionalMandatoryDateTimeFields).
                    filter(additionalMandatoryDateTimeField => !_.isNil(additionalMandatoryDateTimeField.defaultTime)).
                    keyBy(additionalMandatoryDateTimeField => additionalMandatoryDateTimeField.name).
                    mapValues(additionalMandatoryDateTimeField => [additionalMandatoryDateTimeField.defaultTime!]).
                    value(),
                ..._(additionalMandatoryLabelsFields).
                    filter(additionalMandatoryLabelsField => !_.isNil(additionalMandatoryLabelsField.defaultValues)).
                    keyBy(additionalMandatoryLabelsField => additionalMandatoryLabelsField.name).
                    mapValues(additionalMandatoryLabelsField => additionalMandatoryLabelsField.defaultValues!).
                    value(),
                ..._(additionalMandatoryMultiSelectionFields).
                    filter(additionalMandatoryMultiSelectionField => !_.isNil(additionalMandatoryMultiSelectionField.defaultValues)).
                    keyBy(additionalMandatoryMultiSelectionField => additionalMandatoryMultiSelectionField.name).
                    mapValues(additionalMandatoryMultiSelectionField => additionalMandatoryMultiSelectionField.defaultValues!).
                    value(),
                ..._(additionalMandatoryNumberFields).
                    filter(additionalMandatoryNumberField => !_.isNil(additionalMandatoryNumberField.defaultValue)).
                    keyBy(additionalMandatoryNumberField => additionalMandatoryNumberField.name).
                    mapValues(additionalMandatoryNumberField => [additionalMandatoryNumberField.defaultValue!.toString()]).
                    value(),
                ..._(additionalMandatorySingleSelectionFields).
                    filter(additionalMandatorySingleSelectionField => !_.isNil(additionalMandatorySingleSelectionField.defaultValue)).
                    keyBy(additionalMandatorySingleSelectionField => additionalMandatorySingleSelectionField.name).
                    mapValues(additionalMandatorySingleSelectionField => [additionalMandatorySingleSelectionField.defaultValue!]).
                    value(),
                ..._(additionalMandatoryStringFields).
                    filter(additionalMandatoryStringField => !_.isNil(additionalMandatoryStringField.defaultValue)).
                    keyBy(additionalMandatoryStringField => additionalMandatoryStringField.name).
                    mapValues(additionalMandatoryStringField => [additionalMandatoryStringField.defaultValue!]).
                    value(),
                ..._(additionalMandatoryUserFields).
                    filter(additionalMandatoryUserField => !_.isNil(additionalMandatoryUserField.defaultUserRawId)).
                    keyBy(additionalMandatoryUserField => additionalMandatoryUserField.name).
                    mapValues(additionalMandatoryUserField => [additionalMandatoryUserField.defaultUserRawId!]).
                    value()
            });
        },
        [issueTypeName]);

    const searchUsers =
        async (searchText: Optional<string>, userRawId: Optional<string>) => {
            const { users } =
                await TicketingServiceController.searchJiraProjectUsers(
                    new Contract.TicketingServiceControllerSearchJiraProjectUsersRequest(
                        projectData!.instanceId,
                        projectData!.rawId,
                        searchText,
                        userRawId));
            return users;
        };

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <AutocompleteItems
                disableClearable={true}
                disabled={
                    _.isEmpty(projectDatas) ||
                    disabled ||
                    getProjectExecuting ||
                    projectReadOnly ||
                    readOnly}
                disablePortal={true}
                fullWidth={true}
                getOptionLabel={projectData => projectData.projectState?.project.name ?? ""}
                noOptionsText={localization.fields.project.empty()}
                options={projectDatas}
                popupIcon={
                    <DropdownIcon
                        sx={{
                            color:
                                _.isEmpty(projectDatas) ||
                                disabled ||
                                getProjectExecuting ||
                                projectReadOnly ||
                                readOnly
                                    ? theme.palette.text.disabled
                                    : theme.palette.text.secondary
                        }}/>}
                renderInput={
                    params => (
                        <TextField
                            {...params}
                            label={localization.fields.project.title()}
                            variant="outlined"/>)}
                value={projectData}
                onChange={(event, projectData) => setProjectData(projectData)}>
                {projectData =>
                    <Stack>
                        <Typography>
                            {projectData.projectState?.project.name}
                        </Typography>
                        <Typography variant="subtitle1">
                            {(_.find(
                                instanceModels,
                                instanceModel => instanceModel.configuration.id === projectData.instanceId)?.configuration as Contract.JiraInstanceConfiguration)?.connectionInfo.url}
                        </Typography>
                    </Stack>}
            </AutocompleteItems>
            {getProjectError && (
                <Message
                    level="error"
                    title={localization.fields.project.error()}/>)}
            <Autocomplete
                disabled={
                    _.isEmpty(projectDatas) ||
                    disabled ||
                    readOnly}
                disablePortal={true}
                fullWidth={true}
                loadingText=""
                noOptionsText={localization.fields.issueType.empty()}
                options={issueTypeNames}
                popupIcon={
                    getProjectExecuting
                        ? <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>
                        : <DropdownIcon
                            sx={{
                                color:
                                    _.isEmpty(projectDatas) ||
                                    disabled ||
                                    readOnly
                                        ? theme.palette.text.disabled
                                        : theme.palette.text.secondary
                            }}/>}
                renderInput={
                    params => (
                        <TextField
                            {...params}
                            label={localization.fields.issueType.title()}
                            variant="outlined"/>)}
                value={issueTypeName ?? null}
                onChange={(event, issueTypeName) => setIssueTypeName(issueTypeName ?? undefined)}/>
            {!_.isNil(issueTypeValidationMessage) && (
                <FormHelperText error={true}>
                    {issueTypeValidationMessage}
                </FormHelperText>)}
            {!_.isNil(userRequired) &&
                <Box>
                    <UserFieldSelector
                        disabled={
                            _.isEmpty(projectDatas) ||
                            disabled ||
                            readOnly}
                        disablePortal={true}
                        fetchOptionsExecuting={getProjectExecuting}
                        fieldName={
                            localization.fields.user.title() +
                            (!userRequired
                                ? ` (${localization.optional()})`
                                : "")}
                        initUserRawId={initialData?.issueCreationData.user?.rawId}
                        searchUsers={searchUsers}
                        user={user}
                        onUserSelected={setUser}/>
                    {!_.isNil(userValidationMessage) && (
                        <FormHelperText error={true}>
                            {userValidationMessage}
                        </FormHelperText>)}
                </Box>}
            {_.map(
                additionalMandatorySingleSelectionFields,
                additionalMandatorySingleSelectionField =>
                    <TicketingServiceMandatoryFieldSelector
                        disabled={disabled || readOnly}
                        disablePortal={true}
                        fetchOptionsExecuting={getProjectExecuting}
                        key={additionalMandatorySingleSelectionField.id}
                        mandatoryFieldName={additionalMandatorySingleSelectionField.name}
                        mandatoryFieldNameToErrorMessageMap={additionalMandatoryFieldNameToErrorMessageMap}
                        mandatoryFieldNameToValuesMap={additionalMandatoryFieldNameToValuesMap}
                        options={additionalMandatorySingleSelectionField.options}
                        setMandatoryFieldNameToErrorMessageMap={setAdditionalMandatoryFieldNameToErrorMessageMap}
                        setMandatoryFieldNameToValuesMap={setAdditionalMandatoryFieldNameToValuesMap}/>)}
            {_.map(
                additionalMandatoryUserFields,
                additionalMandatoryUserField =>
                    <UserMandatoryFieldSelector
                        disabled={disabled || readOnly}
                        disablePortal={true}
                        fetchOptionsExecuting={getProjectExecuting}
                        key={additionalMandatoryUserField.id}
                        mandatoryFieldName={additionalMandatoryUserField.name}
                        mandatoryFieldNameToErrorMessageMap={additionalMandatoryFieldNameToErrorMessageMap}
                        mandatoryFieldNameToValuesMap={additionalMandatoryFieldNameToValuesMap}
                        searchUsers={searchUsers}
                        setMandatoryFieldNameToErrorMessageMap={setAdditionalMandatoryFieldNameToErrorMessageMap}
                        setMandatoryFieldNameToValuesMap={setAdditionalMandatoryFieldNameToValuesMap}/>)}
            {_.map(
                additionalMandatoryMultiSelectionFields,
                additionalMandatoryMultiSelectionField =>
                    <TicketingServiceMandatoryFieldSelector
                        disabled={disabled || readOnly}
                        disablePortal={true}
                        fetchOptionsExecuting={getProjectExecuting}
                        key={additionalMandatoryMultiSelectionField.id}
                        mandatoryFieldName={additionalMandatoryMultiSelectionField.name}
                        mandatoryFieldNameToErrorMessageMap={additionalMandatoryFieldNameToErrorMessageMap}
                        mandatoryFieldNameToValuesMap={additionalMandatoryFieldNameToValuesMap}
                        multiple={true}
                        options={additionalMandatoryMultiSelectionField.options}
                        setMandatoryFieldNameToErrorMessageMap={setAdditionalMandatoryFieldNameToErrorMessageMap}
                        setMandatoryFieldNameToValuesMap={setAdditionalMandatoryFieldNameToValuesMap}/>)}
            {!_.isNil(epicRequired) &&
                <Box>
                    <Autocomplete
                        disabled={
                            _.isEmpty(projectDatas) ||
                            disabled ||
                            readOnly}
                        disablePortal={true}
                        fullWidth={true}
                        getOptionLabel={epic => epic.name}
                        loadingText=""
                        noOptionsText={localization.fields.epic.empty()}
                        options={epics}
                        popupIcon={
                            getProjectExecuting
                                ? <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>
                                : <DropdownIcon
                                    sx={{
                                        color:
                                            _.isEmpty(projectDatas) ||
                                            disabled ||
                                            readOnly
                                                ? theme.palette.text.disabled
                                                : theme.palette.text.secondary
                                    }}/>}
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    label={
                                        localization.fields.epic.title() +
                                        (!epicRequired
                                            ? ` (${localization.optional()})`
                                            : "")}
                                    variant="outlined"/>)}
                        value={epic ?? null}
                        onChange={(event, epic) => setEpic(epic ?? undefined)}/>
                    {!_.isNil(epicValidationMessage) && (
                        <FormHelperText error={true}>
                            {epicValidationMessage}
                        </FormHelperText>)}
                </Box>}
            {!_.isNil(sprintRequired) && (
                <Box>
                    <Autocomplete
                        disabled={
                            _.isEmpty(projectDatas) ||
                            disabled ||
                            readOnly}
                        disablePortal={true}
                        fullWidth={true}
                        getOptionLabel={sprint => sprint.name}
                        loadingText=""
                        noOptionsText={localization.fields.sprint.empty()}
                        options={sprints}
                        popupIcon={
                            getProjectExecuting
                                ? <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>
                                : <DropdownIcon
                                    sx={{
                                        color:
                                            _.isEmpty(projectDatas) ||
                                            disabled ||
                                            readOnly
                                                ? theme.palette.text.disabled
                                                : theme.palette.text.secondary
                                    }}/>}
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    label={
                                        localization.fields.sprint.title() +
                                        (!sprintRequired
                                            ? ` (${localization.optional()})`
                                            : "")}
                                    variant="outlined"/>)}
                        value={sprint ?? null}
                        onChange={(event, sprint) => setSprint(sprint ?? undefined)}/>
                    {!_.isNil(sprintValidationMessage) && (
                        <FormHelperText error={true}>
                            {sprintValidationMessage}
                        </FormHelperText>)}
                </Box>)}
            {_.map(
                additionalMandatoryDateFields,
                additionalMandatoryDateField =>
                    <Box key={additionalMandatoryDateField.id}>
                        <DateField
                            date={additionalMandatoryFieldNameToValuesMap[additionalMandatoryDateField.name]?.[0]}
                            disabled={
                                disabled ||
                                readOnly}
                            disableOpenPicker={true}
                            title={additionalMandatoryDateField.name}
                            onChange={
                                date => {
                                    setAdditionalMandatoryFieldNameToValuesMap(
                                        additionalMandatoryFieldNameToValueMap => ({
                                            ...additionalMandatoryFieldNameToValueMap,
                                            [additionalMandatoryDateField.name]:
                                                _.isNil(date)
                                                    ? []
                                                    : [TimeFormatter.jiraDate(date)]
                                        }));
                                    setAdditionalMandatoryFieldNameToErrorMessageMap(
                                        additionalMandatoryFieldNameToErrorMessageMap => ({
                                            ...additionalMandatoryFieldNameToErrorMessageMap,
                                            [additionalMandatoryDateField.name]:
                                                _.isEmpty(date)
                                                    ? localization.fields.additionalMandatoryFields.error.date({ fieldName: additionalMandatoryDateField.name })
                                                    : undefined
                                        }));
                                }}/>
                        {!_.isNil(additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryDateField.name]) && (
                            <FormHelperText error={true}>
                                {additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryDateField.name]}
                            </FormHelperText>)}
                    </Box>)}
            {_.map(
                additionalMandatoryDateTimeFields,
                additionalMandatoryDateTimeField =>
                    <Box key={additionalMandatoryDateTimeField.id}>
                        <DateTimeField
                            disabled={
                                disabled ||
                                readOnly}
                            time={additionalMandatoryFieldNameToValuesMap[additionalMandatoryDateTimeField.name]?.[0]}
                            title={additionalMandatoryDateTimeField.name}
                            onChange={
                                dateTime => {
                                    setAdditionalMandatoryFieldNameToValuesMap(
                                        additionalMandatoryFieldNameToValueMap => ({
                                            ...additionalMandatoryFieldNameToValueMap,
                                            [additionalMandatoryDateTimeField.name]:
                                                _.isNil(dateTime)
                                                    ? []
                                                    : [TimeFormatter.jiraDateTime(dateTime)]
                                        }));

                                    setAdditionalMandatoryFieldNameToErrorMessageMap(
                                        additionalMandatoryFieldNameToErrorMessageMap => ({
                                            ...additionalMandatoryFieldNameToErrorMessageMap,
                                            [additionalMandatoryDateTimeField.name]:
                                                _.isEmpty(dateTime)
                                                    ? localization.fields.additionalMandatoryFields.error.dateTime({ fieldName: additionalMandatoryDateTimeField.name })
                                                    : undefined
                                        }));
                                }}/>
                        {!_.isNil(additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryDateTimeField.name]) && (
                            <FormHelperText error={true}>
                                {additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryDateTimeField.name]}
                            </FormHelperText>)}
                    </Box>)}
            {_.map(
                additionalMandatoryLabelsFields,
                additionalMandatoryLabelsField =>
                    <Box>
                        <MandatoryLabelsSelector
                            disabled={
                                disabled ||
                                readOnly}
                            disablePortal={true}
                            instanceId={projectData?.instanceId}
                            key={additionalMandatoryLabelsField.id}
                            mandatoryField={additionalMandatoryLabelsField}
                            mandatoryFieldName={additionalMandatoryLabelsField.name}
                            mandatoryFieldNameToErrorMessageMap={additionalMandatoryFieldNameToErrorMessageMap}
                            mandatoryFieldNameToValuesMap={additionalMandatoryFieldNameToValuesMap}
                            setMandatoryFieldNameToErrorMessageMap={setAdditionalMandatoryFieldNameToErrorMessageMap}
                            setMandatoryFieldNameToValuesMap={setAdditionalMandatoryFieldNameToValuesMap}/>
                    </Box>)}
            {_.map(
                additionalMandatoryNumberFields,
                additionalMandatoryNumberField =>
                    <Box key={additionalMandatoryNumberField.id}>
                        <TextField
                            disabled={
                                disabled ||
                                readOnly}
                            fullWidth={true}
                            key={additionalMandatoryNumberField.id}
                            label={additionalMandatoryNumberField.name}
                            slotProps={{ htmlInput: { maxLength: 254 } }}
                            type="number"
                            value={additionalMandatoryFieldNameToValuesMap[additionalMandatoryNumberField.name]?.[0] ?? ""}
                            variant="outlined"
                            onChange={
                                event => {
                                    const value = event.target.value.trim();
                                    setAdditionalMandatoryFieldNameToValuesMap(
                                        additionalMandatoryFieldNameToValuesMap => ({
                                            ...additionalMandatoryFieldNameToValuesMap,
                                            [additionalMandatoryNumberField.name]: [value]
                                        }));

                                    setAdditionalMandatoryFieldNameToErrorMessageMap(
                                        additionalMandatoryFieldNameToErrorMessageMap => ({
                                            ...additionalMandatoryFieldNameToErrorMessageMap,
                                            [additionalMandatoryNumberField.name]:
                                                _.isEmpty(value)
                                                    ? localization.fields.additionalMandatoryFields.error.number({ fieldName: additionalMandatoryNumberField.name })
                                                    : undefined
                                        }));
                                }
                            }/>
                        {!_.isNil(additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryNumberField.name]) && (
                            <FormHelperText error={true}>
                                {additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryNumberField.name]}
                            </FormHelperText>)}
                    </Box>)}
            {_.map(
                additionalMandatoryStringFields,
                additionalMandatoryStringField =>
                    <Box key={additionalMandatoryStringField.id}>
                        <TextField
                            disabled={
                                disabled ||
                                readOnly}
                            fullWidth={true}
                            key={additionalMandatoryStringField.id}
                            label={additionalMandatoryStringField.name}
                            slotProps={{ htmlInput: { maxLength: 254 } }}
                            value={additionalMandatoryFieldNameToValuesMap[additionalMandatoryStringField.name]?.[0] ?? ""}
                            variant="outlined"
                            onChange={
                                event => {
                                    const value = event.target.value.trim();
                                    setAdditionalMandatoryFieldNameToValuesMap(
                                        additionalMandatoryFieldNameToValuesMap => ({
                                            ...additionalMandatoryFieldNameToValuesMap,
                                            [additionalMandatoryStringField.name]: [value]
                                        }));

                                    setAdditionalMandatoryFieldNameToErrorMessageMap(
                                        additionalMandatoryFieldNameToErrorMessageMap => ({
                                            ...additionalMandatoryFieldNameToErrorMessageMap,
                                            [additionalMandatoryStringField.name]:
                                                _.isEmpty(value)
                                                    ? localization.fields.additionalMandatoryFields.error.required({ fieldName: additionalMandatoryStringField.name })
                                                    : undefined
                                        }));
                                }
                            }/>
                        {!_.isNil(additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryStringField.name]) && (
                            <FormHelperText error={true}>
                                {additionalMandatoryFieldNameToErrorMessageMap[additionalMandatoryStringField.name]}
                            </FormHelperText>)}
                    </Box>)}
            {!_.isNil(labelsRequired) &&
                <Box>
                    <Autocomplete
                        disabled={
                            _.isEmpty(projectDatas) ||
                            disabled ||
                            readOnly
                        }
                        disablePortal={true}
                        filterSelectedOptions={true}
                        forcePopupIcon={true}
                        freeSolo={true}
                        fullWidth={true}
                        includeInputInList={true}
                        loadingText=""
                        multiple={true}
                        options={projectData?.labels ?? []}
                        popupIcon={
                            getProjectExecuting
                                ? <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>
                                : <DropdownIcon
                                    sx={{
                                        color:
                                            _.isEmpty(projectDatas) ||
                                            disabled ||
                                            readOnly
                                                ? theme.palette.text.disabled
                                                : theme.palette.text.secondary
                                    }}/>}
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    label={localization.fields.labels.title()}
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            sx: { maxHeight: "unset" }
                                        }
                                    }}
                                    variant="outlined"/>)}
                        slotProps={{
                            chip: {
                                clickable: true,
                                variant: "outlined"
                            }
                        }}
                        sx={{ display: "grid" }}
                        value={labels ?? []}
                        onChange={(event, fieldOptionValue) => setLabels(fieldOptionValue)}/>
                    {!_.isNil(labelsValidationMessage) && (
                        <FormHelperText error={true}>
                            {labelsValidationMessage}
                        </FormHelperText>)}
                </Box>}
            {summary && (
                <Box>
                    <TextField
                        disabled={
                            _.isEmpty(projectDatas) ||
                            disabled ||
                            readOnly}
                        fullWidth={true}
                        label={localization.fields.summary.title()}
                        slotProps={{ htmlInput: { maxLength: 254 } }}
                        value={summary}
                        variant="outlined"
                        onChange={event => setSummary(event.target.value)}/>
                    {!_.isNil(summaryValidationMessage) && (
                        <FormHelperText error={true}>{summaryValidationMessage}</FormHelperText>)}
                </Box>)}
            {description && (
                <Box>
                    <TextField
                        disabled={
                            _.isEmpty(projectDatas) ||
                            disabled ||
                            readOnly}
                        fullWidth={true}
                        label={localization.fields.description.title()}
                        multiline={true}
                        rows={12}
                        value={description}
                        variant="outlined"
                        onChange={event => setDescription(event.target.value)}/>
                </Box>)}
            {!_.isNil(riskModel) &&
                projectData?.attachmentsEnabled &&
                !_.isEmpty(riskModel.files) && (
                <Box>
                    <Typography variant="h3">
                        {localization.fields.fileNames.title()}
                    </Typography>
                    <Typography variant="subtitle1">
                        {localization.fields.fileNames.description()}
                    </Typography>
                    <List disablePadding={true}>
                        {_.map(
                            riskModel.files,
                            file =>
                                <ListItemButton
                                    dense={true}
                                    disabled={
                                        _.isEmpty(projectDatas) ||
                                            disabled ||
                                            readOnly}
                                    disableGutters={true}
                                    key={file.name}>
                                    <CheckboxField
                                        checked={_.includes(fileNames, file.name)}
                                        disabled={
                                            _.isEmpty(projectDatas) ||
                                                disabled ||
                                                readOnly}
                                        onChange={
                                            (event, checked) =>
                                                setFileNames(
                                                    checked
                                                        ? _.concat(fileNames!, file.name)
                                                        : _.without(fileNames!, file.name))}>
                                        {file.name}
                                    </CheckboxField>
                                </ListItemButton>)}
                    </List>
                </Box>)}
            {_.isEmpty(projectDatas) && (
                <Message
                    level="error"
                    title={
                        localization.actions.projectDatas.error.title({
                            jiraConfigurationLink:
                                <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsJiraRelativeUrl()}>
                                    {localization.actions.projectDatas.error.links.jiraConfiguration()}
                                </Link>
                        })}
                    variant="contained"/>)}
        </Stack>);
}

type JiraIssueSelectorProjectData = {
    attachmentsEnabled: boolean;
    deploymentType: Contract.JiraInstanceDeploymentType;
    instanceId: string;
    labels: string[];
    projectState: Contract.JiraInstanceStateProject;
    rawId: string;
    type: Contract.JiraProjectType;
};