import { CheckboxField, DeleteIcon, MultiSelect, useLocalization } from "@infrastructure";
import { Box, FormControl, FormHelperText, Grid2, IconButton, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, InlineScopes, useTheme } from "../../../../../../../../common";
import { useAwsApiGatewayApiRouteMethodTranslator } from "../../../../../../../../tenants";

type ApiGatewayRoutePatternProps = {
    duplicate: boolean;
    exclusionApiGatewayRoutePattern: Contract.AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern;
    last: boolean;
    onDelete?: () => void;
    onExclusionApiGatewayRoutePatternChanged: (exclusionApiGatewayRoutePattern: Contract.AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern) => void;
    readOnly: boolean;
    scopeIds?: string[];
    valid: boolean;
};

export function ApiGatewayRoutePattern({ duplicate, exclusionApiGatewayRoutePattern, last, onDelete, onExclusionApiGatewayRoutePatternChanged, readOnly, scopeIds, valid }: ApiGatewayRoutePatternProps) {
    const inherited = !_.isEmpty(scopeIds);
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.apiGatewayRouteExclusion.apiGatewayRoutePattern",
            () => ({
                caseInsensitive: "Ignore case",
                duplicate: "Duplicate value is not allowed",
                inherited: "Inherited from",
                message: "Comment",
                method: "Methods",
                path: "Path Pattern",
                selection: "{{methodsCount | NumberFormatter.humanize}} Methods"
            }));

    const apiGatewayApiMethodTranslator = useAwsApiGatewayApiRouteMethodTranslator();
    const theme = useTheme();
    const [selectedMethods, setSelectedMethods] = useState(exclusionApiGatewayRoutePattern.methods);

    return (
        <Grid2
            alignItems="start"
            container={true}
            spacing={1}
            width="100%">
            <Grid2 size={{ xs: 4 }}>
                <Stack
                    direction="row"
                    flexDirection="row"
                    justifyContent="space-evenly"
                    spacing={1}>
                    <FormControl
                        variant="standard">
                        <TextField
                            disabled={inherited || readOnly}
                            label={localization.path()}
                            value={exclusionApiGatewayRoutePattern.pathPattern}
                            variant="outlined"
                            onChange={
                                event => {
                                    onExclusionApiGatewayRoutePatternChanged(
                                        {
                                            ...exclusionApiGatewayRoutePattern,
                                            pathPattern: event.target.value.trim()
                                        });
                                }}/>
                        {!readOnly &&
                            valid &&
                            duplicate &&
                            !inherited &&
                            <FormHelperText error={true}>
                                {localization.duplicate()}
                            </FormHelperText>}
                    </FormControl>
                    <MultiSelect
                        disabled={inherited || readOnly}
                        items={
                            _.map(
                                Contract.AwsApiGatewayApiRouteMethod,
                                apiMethod => apiGatewayApiMethodTranslator(apiMethod))}
                        placeholder={localization.method()}
                        selectedItems={selectedMethods}
                        selectedItemsTranslator={
                            selectedMethods =>
                                selectedMethods.length === 1
                                    ? _.first(selectedMethods)
                                    : localization.selection({ methodsCount: selectedMethods.length })}
                        onSelectedItemsChanged={
                            selectedMethods => {
                                setSelectedMethods(selectedMethods);
                                onExclusionApiGatewayRoutePatternChanged(
                                    {
                                        ...exclusionApiGatewayRoutePattern,
                                        methods: selectedMethods
                                    });
                            }}/>
                </Stack>
            </Grid2>
            {inherited && (
                <Grid2 sx={{ padding: theme.spacing(2.5, 1, 0) }}>
                    <Stack
                        direction="row"
                        spacing={1}>
                        <Typography>
                            {localization.inherited()}
                        </Typography>
                        <Box>
                            <InlineScopes
                                scopeIds={scopeIds!}
                                scopeVariant="iconText"
                                variant="itemCountAndType"/>
                        </Box>
                    </Stack>
                </Grid2>)}
            <Grid2 size={{ xs: 5 }}>
                {(!inherited) && (
                    <TextField
                        disabled={readOnly}
                        fullWidth={true}
                        label={localization.message()}
                        maxRows={1}
                        multiline={true}
                        slotProps={{ input: { readOnly } }}
                        value={exclusionApiGatewayRoutePattern.message ?? ""}
                        variant="outlined"
                        onChange={
                            event => {
                                onExclusionApiGatewayRoutePatternChanged(
                                    {
                                        ...exclusionApiGatewayRoutePattern,
                                        message:
                                            _.isEmpty(event.target.value)
                                                ? undefined
                                                : event.target.value
                                    });
                            }}/>)}
            </Grid2>
            <Grid2 sx={{ padding: theme.spacing(2, 1, 0) }}>
                {!inherited &&
                    !readOnly && (
                    <CheckboxField
                        checked={!exclusionApiGatewayRoutePattern.caseSensitive}
                        title={localization.caseInsensitive()}
                        onChange={
                            () => {
                                onExclusionApiGatewayRoutePatternChanged(
                                    {
                                        ...exclusionApiGatewayRoutePattern,
                                        caseSensitive: !exclusionApiGatewayRoutePattern.caseSensitive
                                    });
                            }}>
                        {localization.caseInsensitive()}
                    </CheckboxField>)}
            </Grid2>
            <Grid2 sx={{ padding: theme.spacing(2, 1, 0) }}>
                {!last &&
                    !inherited &&
                    !readOnly && (
                    <IconButton
                        size="medium"
                        sx={{ alignSelf: "center" }}
                        onClick={onDelete}>
                        <DeleteIcon/>
                    </IconButton>)}
            </Grid2>
        </Grid2>);
}