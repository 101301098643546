import { ActionButton, AddIcon, Dropdown, EditIcon, useLocalization } from "@infrastructure";
import { Button, Typography } from "@mui/material";
import React from "react";
import { EntityAttributeDefinitionList, useTheme } from "..";

type EntityAttributeDefinitionMultiSelectProps = {
    builtInEntityAttributeTypeNames?: string[];
    customEntityAttributeDefinitionIds?: string[];
    disabled?: boolean;
    onToggleBuiltInEntityAttributeItem?: (builtInEntityAttributeTypeNames: string[]) => Promise<void>;
    onToggleCustomEntityAttributeItem?: (customEntityAttributeDefinitionId: string) => Promise<void>;
    selectedBuiltInEntityAttributeTypeNames?: string[];
    selectedCustomEntityAttributeDefinitionIds?: string[];
    variant: "button" | "icon";
};

export function EntityAttributeDefinitionMultiSelect({ builtInEntityAttributeTypeNames = [], customEntityAttributeDefinitionIds = [], disabled = false, onToggleBuiltInEntityAttributeItem = undefined, onToggleCustomEntityAttributeItem = undefined, selectedBuiltInEntityAttributeTypeNames = [], selectedCustomEntityAttributeDefinitionIds = [], variant }: EntityAttributeDefinitionMultiSelectProps) {
    const localization =
        useLocalization(
            "common.entityAttributeDefinitionMultiSelect",
            () => ({
                actions: {
                    add: "Add Label"
                },
                title: "Labels"
            }));
    const theme = useTheme();
    return (
        <Dropdown
            disabled={disabled}
            justify="center"
            popoverElement={
                <EntityAttributeDefinitionList
                    builtInEntityAttributeTypeNames={builtInEntityAttributeTypeNames}
                    customEntityAttributeDefinitionIds={customEntityAttributeDefinitionIds}
                    selectedBuiltInEntityAttributeTypeNames={selectedBuiltInEntityAttributeTypeNames}
                    selectedCustomEntityAttributeDefinitionIds={selectedCustomEntityAttributeDefinitionIds}
                    onToggleBuiltInEntityAttributeItem={onToggleBuiltInEntityAttributeItem}
                    onToggleCustomEntityAttributeItem={onToggleCustomEntityAttributeItem}/>}
            popoverElementContainerSx={{ padding: 0 }}
            title={localization.title()}>
            {variant === "button"
                ? <Button
                    disabled={disabled}
                    size="small"
                    startIcon={<AddIcon/>}
                    variant="outlined">
                    <Typography>
                        {localization.actions.add()}
                    </Typography>
                </Button>
                : <ActionButton variant="outlined">
                    <EditIcon
                        sx={{
                            fontSize: "16px",
                            ...(disabled && { color: theme.palette.text.disabled })
                        }}/>
                </ActionButton>}
        </Dropdown>);
}