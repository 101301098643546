﻿import { Message, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, TicketingServiceMandatoryFieldSelector } from "../../..";
import { useSearchUsers } from "../hooks";

type UserMandatoryFieldSelectorProps = {
    disabled: boolean;
    disablePortal?: boolean;
    fetchOptionsExecuting: boolean;
    mandatoryFieldName: string;
    mandatoryFieldNameToErrorMessageMap: _.Dictionary<string | undefined>;
    mandatoryFieldNameToValuesMap: _.Dictionary<string[]>;
    searchUsers: (searchText: Optional<string>, userRawId: Optional<string>) => Promise<Contract.JiraUser[]>;
    setMandatoryFieldNameToErrorMessageMap: React.Dispatch<React.SetStateAction<_.Dictionary<string | undefined>>>;
    setMandatoryFieldNameToValuesMap: React.Dispatch<React.SetStateAction<_.Dictionary<string[]>>>;
};

export function UserMandatoryFieldSelector({ disabled, disablePortal, fetchOptionsExecuting, mandatoryFieldName, mandatoryFieldNameToErrorMessageMap, mandatoryFieldNameToValuesMap, searchUsers, setMandatoryFieldNameToErrorMessageMap, setMandatoryFieldNameToValuesMap }: UserMandatoryFieldSelectorProps) {
    const { initUser, searchUsersError, searchUsersExecuting, updateSearchUsersText, userRawIdToDisplayNameMap } =
        useSearchUsers(
            searchUsers,
            mandatoryFieldNameToValuesMap[mandatoryFieldName]?.[0]);

    const localization =
        useLocalization(
            "common.jiraIssueSelector.userMandatoryFieldSelector",
            () => ({
                error: "Failed to get users"
            }));
    return (
        <Fragment>
            <TicketingServiceMandatoryFieldSelector
                disabled={disabled}
                disablePortal={disablePortal}
                fetchOptionsExecuting={fetchOptionsExecuting || searchUsersExecuting}
                getOptionLabel={userRawId => userRawIdToDisplayNameMap[userRawId]}
                key={initUser?.rawId}
                mandatoryFieldName={mandatoryFieldName}
                mandatoryFieldNameToErrorMessageMap={mandatoryFieldNameToErrorMessageMap}
                mandatoryFieldNameToValuesMap={mandatoryFieldNameToValuesMap}
                options={_.keys(userRawIdToDisplayNameMap)}
                setMandatoryFieldNameToErrorMessageMap={setMandatoryFieldNameToErrorMessageMap}
                setMandatoryFieldNameToValuesMap={setMandatoryFieldNameToValuesMap}
                onTextChange={updateSearchUsersText}/>
            {searchUsersError && (
                <Message
                    level="error"
                    title={localization.error()}/>)}
        </Fragment>);
}