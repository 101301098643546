import { DirectedGraphModelColumn, UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../controllers";
import { ToolbarFilterId, ToolbarToggleFilterId } from "../../components";
import { useAwsDefinition, useAzureDefinition, useGcpDefinition } from "./hooks";

export function useDefinition(tenantType: Contract.TenantType) {
    const useDefinition =
        useMemo(
            () => {
                switch (tenantType) {
                    case Contract.TenantType.Aws:
                        return useAwsDefinition;
                    case Contract.TenantType.Azure:
                        return useAzureDefinition;
                    case Contract.TenantType.Gcp:
                        return useGcpDefinition;
                    default:
                        throw new UnexpectedError("tenantType", tenantType);
                }
            },
            []);

    return useDefinition();
}

export class NetworkDefinition {
    constructor(
        public directedGraphColumns: DirectedGraphModelColumn[],
        public getNetworkGraph: NetworkDefinitionGetNetworkGraph,
        public getSpecialSourceSubnetDefinition: NetworkDefinitionGetSpecialSourceDefinition,
        public serviceSubnetIcon: ReactNode,
        public servicesSubnetIcon: ReactNode,
        public toolbarFilterIdTranslator: NetworkDefinitionToolbarFilterIdTranslator,
        public toolbarToggleFilterIdTranslator: NetworkDefinitionToolbarToggleFilterIdTranslator,
        public getGatewayResourceRulesDefinition?: NetworkDefinitionGetGatewayResourceRulesDefinition
    ) {
    }
}

export type NetworkDefinitionGetSpecialSourceDefinition = (specialSource: Contract.NetworkGraphSpecialSource) => {
    highlightColor: string;
    icon: ReactNode;
    normalColor: string;
    subtitle: string;
    title: string;
};
export type NetworkDefinitionGetGatewayResourceRulesDefinition = (gatewayResourceModel: Contract.EntityModel) => {
    rules: ReactNode;
    title: string;
};
export type NetworkDefinitionGetNetworkGraph = (entityId: string, filterMap: Dictionary<any>) => Promise<Contract.EntityControllerGetNetworkGraphResponse>;
export type NetworkDefinitionToolbarFilterIdTranslator = (toolbarFilterId: ToolbarFilterId) => string;
export type NetworkDefinitionToolbarToggleFilterIdTranslator = (toolbarToggleFilterId: ToolbarToggleFilterId) => string;