import { map, StringFormatter, Tooltip, UnexpectedError, useLocalization } from "@infrastructure";
import { Avatar, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, identityModelStore, LogoIcon, useTheme } from "../..";
import { ApiKeyIcon } from "./components";

type IdentityProps = {
    identityIdOrReference: string | Contract.IdentityReference;
    sx?: SxProps;
    variant: "icon" | "text" | "textAndInfo";
};

export function Identity({ identityIdOrReference, sx, variant }: IdentityProps) {
    const identityModels = identityModelStore.useGetAll();
    const [identityId, identityModel] =
        useMemo(
            () => {
                const identityId =
                    _.isString(identityIdOrReference)
                        ? identityIdOrReference
                        : identityIdOrReference.id;

                const identityModel =
                    _.find(
                        identityModels,
                        identityModel =>
                            identityModel.identity.id === identityId);

                return [identityId, identityModel];
            },
            [identityIdOrReference]);

    if (_.isNil(identityModel) &&
        _.isString(identityIdOrReference)) {
        throw new UnexpectedError("identityIdOrReference", identityIdOrReference);
    }

    const localization =
        useLocalization(
            "common.identity",
            () => ({
                apiKey: "{{name}} (token)",
                user: "{{name}} ({{mail}})"
            }));

    const mail = (identityModel?.identity as Contract.User)?.mail ?? (identityIdOrReference as Contract.IdentityReference).mail;
    const name = identityModel?.identity.name ?? (identityIdOrReference as Contract.IdentityReference).name;
    const typeName = identityModel?.identity.typeName ?? (identityIdOrReference as Contract.IdentityReference).typeName;
    return map(
        variant,
        {
            "icon": () =>
                <Icon
                    identityId={identityId}
                    name={name}
                    typeName={typeName}/>,
            "text": () =>
                <Tooltip titleOrGetTitle={mail}>
                    <Typography
                        component="span"
                        sx={sx}>
                        {typeName === Contract.TypeNames.ApiKey
                            ? localization.apiKey({ name })
                            : name}
                    </Typography>
                </Tooltip>,
            "textAndInfo": () =>
                <Typography
                    component="span"
                    sx={sx}>
                    {map(
                        typeName,
                        {
                            [Contract.TypeNames.ApiKey]: () => localization.apiKey({ name }),
                            [Contract.TypeNames.SystemIdentity]: () => name,
                            [Contract.TypeNames.User]: () =>
                                localization.user({
                                    mail,
                                    name
                                })
                        })}
                </Typography>
        });
}

type IconProps = {
    identityId: string;
    name: string;
    typeName: string;
};

function Icon({ identityId, name, typeName }: IconProps) {
    const theme = useTheme();
    return map(
        typeName,
        {
            [Contract.TypeNames.ApiKey]: () =>
                <Avatar
                    sx={{
                        color: "unset",
                        height: theme.spacing(4.5),
                        width: theme.spacing(4.5)
                    }}>
                    <ApiKeyIcon sx={{ fontSize: "24px" }}/>
                </Avatar>,
            [Contract.TypeNames.SystemIdentity]: () =>
                <Avatar
                    sx={{
                        color: "unset",
                        height: theme.spacing(4.5),
                        width: theme.spacing(4.5)
                    }}>
                    <LogoIcon sx={{ fontSize: "28px" }}/>
                </Avatar>,
            [Contract.TypeNames.User]: () =>
                <Avatar
                    sx={{
                        backgroundColor: theme.palette.avatar.identity.background(identityId),
                        color: theme.palette.avatar.identity.color,
                        height: theme.spacing(4.5),
                        width: theme.spacing(4.5)
                    }}>
                    <Typography sx={{ fontSize: "16px" }}>
                        {StringFormatter.initials(name)}
                    </Typography>
                </Avatar>
        });
}