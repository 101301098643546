import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { EntityAttributes, ProfileTopbar, RiskHelper, RiskPolicyTypeMetadataHelper, RisksIcon, RiskTypeMetadataModelHelper, useRiskPolicyTitleTranslator, useTheme } from "../../../../../../../../../common";
import { useRiskContext } from "../../../../../hooks";
import { RiskProfileDefinition } from "../../../hooks";

export type CardProps = {
    profileDefinition: RiskProfileDefinition;
    subtitle: string;
};

export function Card({ profileDefinition, subtitle }: CardProps) {
    const { riskModel } = useRiskContext();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();

    const theme = useTheme();
    return (
        <ProfileTopbar
            icon={<RisksIcon/>}
            infoChildren={profileDefinition.cardData.infoElements}
            subtitle={
                <Typography
                    noWrap={true}
                    sx={{ color: theme.palette.text.secondary }}>
                    {subtitle}
                </Typography>}
            title={riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(riskModel.risk))}
            titleInfo={
                RiskPolicyTypeMetadataHelper.isLicensed(RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).policyConfigurationTypeName) &&
                !_.isEmpty(riskModel.entityAttributes)
                    ? <EntityAttributes
                        entityAttributes={riskModel.entityAttributes}
                        entityCount={riskModel.risk.riskedEntityIds.length}
                        entityTypeName={riskModel.riskedEntityTypeName}/>
                    : undefined}/>);
}