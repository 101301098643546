import { Action0, NextIcon, Tooltip } from "@infrastructure";
import { Box, MenuItem as MuiMenuItem, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useRef, useState } from "react";
import { Popup } from ".";
import { SubMenuItem } from "../Menu";

type SubMenuProps = {
    item: SubMenuItem;
    onClose: Action0;
};

export function SubMenu({ item: { itemsOrGetItems, options, title }, onClose }: SubMenuProps) {
    const elementRef = useRef<HTMLLIElement | null>(null);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    return (
        <Fragment>
            <Popup
                elementRef={elementRef}
                itemsOrGetItems={itemsOrGetItems}
                open={open}
                titleOptions={
                    _.isNil(options?.subMenuTitle)
                        ? undefined
                        : { titleOrGetTitle: options?.subMenuTitle }}
                onClose={
                    () => {
                        setOpen(false);
                        onClose();
                    }}/>
            <Tooltip titleOrGetTitle={options?.tooltip}>
                <Box>
                    <MuiMenuItem
                        disabled={options?.disabled}
                        ref={elementRef}
                        selected={open}
                        sx={{ padding: theme.spacing(1, 1.5) }}
                        onClick={() => setOpen(true)}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}
                            sx={{ width: "100%" }}>
                            {!_.isNil(options?.icon) &&
                                <Box sx={{ fontSize: "14px" }}>
                                    {options?.icon}
                                </Box>}
                            {_.isString(title)
                                ? <Typography sx={{ flex: 1 }}>
                                    {title}
                                </Typography>
                                : title}
                            <NextIcon sx={{ fontSize: "14px" }}/>
                        </Stack>
                    </MuiMenuItem>
                </Box>
            </Tooltip>
        </Fragment>);
}