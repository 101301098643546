import { Store } from "@infrastructure";
import { Contract, EventController } from "..";

export const eventModelStore =
    new Store<Contract.EventModel, never, never>(
        eventModel => eventModel.event.id,
        {
            get:
                async ids => {
                    const { eventModels } = await EventController.getEventModels(new Contract.EventControllerGetEventModelsRequest(ids));
                    return eventModels;
                }
        });