import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useClearQueryParameters } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class OciContext {
    constructor(public addOrEditOpen: boolean | Contract.OciOrganizationModel) {
    }
}

export const [useOciContext, useSetOciContext, useOciContextProvider] = makeContextProvider<OciContext>();

export function Oci() {
    const clearQueryParameters = useClearQueryParameters();
    const [context, setContext, ContextProvider] =
        useOciContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<CloudProviderTenantOrganizationOciQueryParameters>();
                clearQueryParameters();
                return new OciContext(StringHelper.isTrue(queryParameters.addOrEditOpen));
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type CloudProviderTenantOrganizationOciQueryParameters = {
    addOrEditOpen?: string;
};