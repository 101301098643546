import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEsDomainRiskContext } from "../contexts";


export function useAwsEsDomainEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEsDomainEncryptionDisabledRisk;
    const domainModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEsDomainModel;
    const domain = domainModel.entity as Contract.AwsEsDomain;

    const getAwsEsDomainRiskContext = useGetAwsEsDomainRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEsDomainEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{domain}} is not configured with encryption at rest using KMS",
                sections: {
                    resolution: {
                        step1: "Before setting the encryption, verify that identities accessing the domain's data have encryption permissions",
                        step2: "Check the **Enable encryption of data at rest** option and select an encryption key",
                        step3: "Click **Submit** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                domain:
                    <Entity
                        entityIdOrModel={domainModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Es,
                AwsConsoleUrlBuilder.getEsDomainEditSecurityConfigUrl(domain)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const domainRiskContext = getAwsEsDomainRiskContext(domainModel);
            return [
                domainRiskContext.generalInformation,
                domainRiskContext.sensitive,
                domainRiskContext.storageSize,
                domainRiskContext.vpc,
                domainRiskContext.accessLevel,
                domainRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}