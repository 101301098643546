﻿import { Link, Optional, useLocalization, useLocalizeAttributes } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../controllers";
import { scopeSystemEntityModelStore } from "../stores";

export function useServiceNowIncidentTranslator() {
    const instancesModels = scopeSystemEntityModelStore.useGetServiceNow();
    const localizeAttributes = useLocalizeAttributes();
    const localization =
        useLocalization(
            "common.hooks.useServiceNowIncidentTranslator",
            () => ({
                groupName: "Group Assignment: **{{groupName}}**",
                incidentLink: "Link",
                instanceName: "Project: **{{instanceName}}**",
                multipleRisks: "Unified incident",
                rawId: "Id: **{{rawId}}**",
                userDisplayName: "Assignee: **{{userDisplayName}}** "
            }));
    return (incident: Contract.ServiceNowIncident, variant: "id" | "link") => {
        const instanceModel =
            _.find(
                instancesModels,
                instanceModel => instanceModel.configuration.id == incident.instanceId)!;
        return localizeAttributes(
            variant === "id"
                ? localization.rawId({ rawId: incident.rawId })
                : undefined,
            localization.instanceName({ instanceName: (instanceModel.configuration as Contract.ServiceNowInstanceConfiguration).name }),
            _.isNil(incident.user)
                ? undefined
                : localization.userDisplayName({ userDisplayName: incident.user!.displayName }),
            (instanceModel?.state as Optional<Contract.ServiceNowInstanceState>)?.groupMandatory === true &&
            !_.isNil(incident.group)
                ? localization.groupName({ groupName: incident.group!.name })
                : undefined,
            variant === "link"
                ? <Link
                    sx={{ fontWeight: 700 }}
                    urlOrGetUrl={incident.url}
                    variant="external">
                    {localization.incidentLink()}
                </Link>
                : undefined,
            incident.multipleRisks
                ? localization.multipleRisks()
                : undefined);
    };
}