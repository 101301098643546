import { Function0 } from "lodash";
import React, { useCallback } from "react";
import { useLocalization } from "@infrastructure";
import { WidgetDefinition } from "..";
import { AccessDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { RiskCombinations } from "../components";

export function useGetAccessRiskCombinationsDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<AccessDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetAccessRiskCombinationsDefinition",
            () => ({
                title: "Toxic Combinations"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <RiskCombinations riskCombinationToDataMap={summary.riskCombinationToDataMap}/>,
            options: {
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}