import { CsvExportButton, DataTableAction, DataTableSort, Optional, SelectionActionsItemPermissions, useGetExportFileName, useLocalization } from "@infrastructure";
import { SxProps } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Ref, useRef } from "react";
import { GroupedItem } from "../../../..";
import { Contract, ExportReportHelper, RiskController } from "../../../../../../../../../../../../common";
import { useItemsContext, useViewTranslator } from "../../../../../../hooks";
import { useRiskContext } from "../../../../../../Items";
import { Table as CoreTable, TableActions } from "../../../../../Table";
import { useDefinition } from "./hooks";

type TableProps = {
    actionsRef?: Ref<Optional<TableActions>>;
    item: GroupedItem;
    onLoadedRiskModelsChanged?: (loadedRiskModels: Contract.RiskModel[]) => void;
    onRiskModelCountChanged?: (riskModelCount?: number) => void;
    onSelectionChanged?: (selectedRiskIds: string[], selectionPermissions?: SelectionActionsItemPermissions) => void;
    riskStatus: Contract.RiskStatus;
    sx?: SxProps;
};

export function Table({ actionsRef, item, onLoadedRiskModelsChanged, onRiskModelCountChanged, onSelectionChanged, riskStatus, sx }: TableProps) {
    const { getRequestSort, requestFilters, requestFiltersTime, riskIdToScopeIdsMapRef, view } = useItemsContext();
    const { riskType } = useRiskContext();
    const filterMapRef = useRef<Dictionary<any>>();
    const sortRef = useRef<DataTableSort>();
    const definition = useDefinition(item.riskGroup, riskType);
    const viewTranslator = useViewTranslator();
    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.table",
            () => ({
                csvExportFileName: "Findings_{{view}}"
            }));

    return (
        <CoreTable
            actionsRef={actionsRef}
            columns={definition.columns}
            getRiskIdToScopeIdentityPermissionsMap={
                async () => {
                    const { riskIdToScopeIdToIdentityPermissionsMap } = await RiskController.getRiskIdToScopeIdentityPermissionsMap(definition.getRiskIdToScopeIdentityPermissionsMapRequest(requestFilters!));
                    riskIdToScopeIdsMapRef.current =
                        _.merge(
                            riskIdToScopeIdsMapRef.current,
                            _.mapValues(
                                riskIdToScopeIdToIdentityPermissionsMap,
                                scopeIdToIdentityPermissionsMap => _.keys(scopeIdToIdentityPermissionsMap)));

                    return _.mapValues(
                        riskIdToScopeIdToIdentityPermissionsMap,
                        scopeIdToIdentityPermissionsMap =>
                            _(scopeIdToIdentityPermissionsMap).
                                values().
                                flatMap().
                                uniq().
                                value());
                }}
            getRiskModelPage={
                async (filterMap, limit, skip, sort) =>
                    await RiskController.getRiskModelPage(
                        definition.getRiskModelPageRequest(
                            limit,
                            requestFilters!,
                            skip,
                            getRequestSort(sort),
                            filterMap,
                            requestFiltersTime))}
            groupId={item.id}
            pageSize={10}
            sx={sx}
            onFiltersChanged={filterMap => filterMapRef.current = filterMap}
            onLoadedRiskModelsChanged={onLoadedRiskModelsChanged}
            onRiskModelCountChanged={onRiskModelCountChanged}
            onSelectionChanged={onSelectionChanged}
            onSortChanged={sort => sortRef.current = sort}>
            {!_.isNil(definition.getRisksReportRequestDefinition) &&
                <DataTableAction>
                    <CsvExportButton
                        fileNameOptions={{
                            filtered: !_.isEmpty(filterMapRef.current),
                            prefix: localization.csvExportFileName({ view: viewTranslator(view) })
                        }}
                        showLimitMessage={false}
                        onClick={
                            async fileNameOptions => {
                                const reportRequestDefinition =
                                    definition.getRisksReportRequestDefinition!(
                                        filterMapRef.current ?? {},
                                        requestFiltersTime,
                                        requestFilters!,
                                        riskStatus,
                                        getRequestSort(sortRef.current));
                                reportRequestDefinition.name = getExportFileName(fileNameOptions);
                                await ExportReportHelper.downloadRemote(reportRequestDefinition);
                            }}/>
                </DataTableAction>}
        </CoreTable>);
}