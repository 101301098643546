import { useMemo } from "react";
import { useAwsResourceDefinition, useAwsServiceDefinition, useAwsUnmanagedKubernetesClusterDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function useAwsEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.AwsService:
                        return useAwsServiceDefinition;
                    case Contract.TypeNames.AwsUnmanagedKubernetesCluster:
                        return useAwsUnmanagedKubernetesClusterDefinition;
                    default:
                        return useAwsResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.AwsEntityModel, options);
}