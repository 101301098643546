import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { useCommonNetworkAccessResourceDefinition } from "..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpComputeInstanceGroupManagerStatusTranslator } from "../../../../../../../../../../tenants";
import { useGcpComputeInstanceGroupTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpComputeInstanceGroupDefinition(definitionData: DefinitionData) {
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const instanceGroupModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GcpComputeInstanceGroupModelFilters>;

    const computeInstanceGroupManagerStatusTranslator = useGcpComputeInstanceGroupManagerStatusTranslator();
    const computeInstanceGroupTypeTranslator = useGcpComputeInstanceGroupTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeInstanceGroupDefinition",
            () => ({
                columns: {
                    instanceGroupManagerStatus: "Status",
                    instanceIds: "VM Instances",
                    status: "Status",
                    type: "Instance Group Type",
                    vpcIdReference: "VPC Network"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkAccessResourceDefinition.columns.destinationNetworkScopesColumn(instanceGroupModelFiltersPromise),
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeInstanceGroupModel) => ({
                            [localization.columns.type()]: computeInstanceGroupTypeTranslator(item.type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpInstanceGroupType}
                                enumTypeTranslator={computeInstanceGroupTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeInstanceGroupType}
                itemProperty={(item: Contract.GcpComputeInstanceGroupModel) => computeInstanceGroupTypeTranslator(item.type)}
                key={Contract.EntityModelProperty.GcpComputeInstanceGroupType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeInstanceGroupModel>(
                        Contract.TypeNames.GcpComputeInstance,
                        item => item.instanceIds,
                        localization.columns.instanceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeInstanceGroupInstances)}
                                placeholder={localization.columns.instanceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeInstanceGroupInstances}
                key={Contract.EntityModelProperty.GcpComputeInstanceGroupInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeInstanceGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIds}
                            entityTypeName={Contract.TypeNames.GcpComputeInstance}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.instanceIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeInstanceGroupModel>(
                        Contract.TypeNames.GcpComputeVpc,
                        item => [item.vpcIdReference],
                        localization.columns.vpcIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeInstanceGroupVpc)}
                                placeholder={localization.columns.vpcIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeInstanceGroupVpc}
                key={Contract.EntityModelProperty.GcpComputeInstanceGroupVpc}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeInstanceGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vpcIdReference}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}/>}
                title={localization.columns.vpcIdReference()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeInstanceGroupModel) => ({
                            [localization.columns.instanceGroupManagerStatus()]:
                                _.isNil(item.instanceGroupManagerStatus)
                                    ? ""
                                    : computeInstanceGroupManagerStatusTranslator(item.instanceGroupManagerStatus)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeInstanceGroupManagerStatus}
                                enumTypeTranslator={computeInstanceGroupManagerStatusTranslator}
                                placeholder={localization.columns.instanceGroupManagerStatus()}
                                sorted={false}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeInstanceGroupInstanceGroupManagerStatus}
                key={Contract.EntityModelProperty.GcpComputeInstanceGroupInstanceGroupManagerStatus}
                render={
                    optionalTableCell(
                        (item: Contract.GcpComputeInstanceGroupModel) =>
                            _.isNil(item.instanceGroupManagerStatus)
                                ? undefined
                                : computeInstanceGroupManagerStatusTranslator(item.instanceGroupManagerStatus))}
                title={localization.columns.instanceGroupManagerStatus()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeInstanceGroupRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeInstanceGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeInstanceGroupInstanceGroupManagerStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeInstanceGroupInstances]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeInstanceGroupType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeInstanceGroupVpc])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}