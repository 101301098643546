﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, tenantModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEc2VolumesRiskContext, useGetAwsEmrClustersRiskContext, useGetAwsEmrSecurityConfigurationRiskContext } from "../../../contexts";

export function useAwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationDefinition(riskModel: Contract.RiskModel) {
    const volumeEncryptionDisabledRiskModel = riskModel as Contract.AwsEc2VolumeEncryptionDisabledRiskModel;
    const risk = volumeEncryptionDisabledRiskModel.risk;
    const riskData = risk.data as Contract.AwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationData;
    const clusterModels = entityModelStore.useGet(riskData.emrClusterIds) as Contract.AwsEmrClusterModel[];
    const securityConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEmrSecurityConfigurationModel;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const volumeModels = entityModelStore.useGet(risk.aggregatedEntityIds) as Contract.AwsEc2VolumeModel[];

    const getAwsEc2VolumesRiskContext = useGetAwsEc2VolumesRiskContext();
    const getAwsEmrClustersRiskContext = useGetAwsEmrClustersRiskContext();
    const getAwsEmrSecurityConfigurationRiskContext = useGetAwsEmrSecurityConfigurationRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VolumeEncryptionDisabledRiskDefinition.hooks.useAwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationDefinition",
            () => ({
                contextItems: {
                    clusters: "The {{translatedSecurityConfigurationTypeName}} was used to launch {{emrClusters}} with {{volumes}}",
                    none: "The {{translatedSecurityConfigurationTypeName}} is not configured with KMS encryption"
                },
                description: {
                    clusters: "{{securityConfiguration}} was used to launch clusters with unencrypted volumes",
                    none: "{{securityConfiguration}} is not configured with KMS encryption"
                },
                sections: {
                    resolution: {
                        step1: "Click on the **Create** button",
                        step2: "Check **Turn on at-rest encryption for local disk**",
                        step3: "Select a KMS key and click on **Create**",
                        step4: "Use the new security configuration when launching new EMR clusters"
                    }
                },
                volumes: [
                    "1 unencrypted volume",
                    "{{count | NumberFormatter.humanize}} unencrypted volumes"
                ]
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(riskData.emrClusterIds)
            ? localization.description.none
            : localization.description.clusters)({
            securityConfiguration:
                <Entity
                    entityIdOrModel={securityConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    glanceOptions={{ disabled: true }}
                    linkOptions={{ disabled: true }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Emr,
                AwsConsoleUrlBuilder.getEmrSecurityConfigurationUrl(
                    securityConfigurationModel.entity as Contract.AwsEmrSecurityConfiguration,
                    tenantConfiguration.partitionType)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const clustersContextItems = getAwsEmrClustersRiskContext(clusterModels);
            const securityConfigurationContextItems = getAwsEmrSecurityConfigurationRiskContext(securityConfigurationModel);
            const volumesContextItems = getAwsEc2VolumesRiskContext(volumeModels);
            return [
                securityConfigurationContextItems.generalInformation,
                new RiskDefinitionContextItem(
                    (_.isEmpty(riskData.emrClusterIds)
                        ? localization.contextItems.none
                        : localization.contextItems.clusters)({
                        emrClusters:
                            <InlineEntities
                                entityIdsOrModels={riskData.emrClusterIds}
                                entityTypeName={Contract.TypeNames.AwsEmrCluster}
                                variant="itemCountAndType"/>,
                        translatedSecurityConfigurationTypeName:
                            entityTypeNameTranslator(
                                securityConfigurationModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                }),
                        volumes:
                            <InlineEntities
                                entityIdsOrModels={risk.aggregatedEntityIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                namePluralizer={localization.volumes}
                                variant="itemCountAndType"/>
                    })),
                clustersContextItems.sensitive,
                volumesContextItems.sensitive,
                securityConfigurationContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}