﻿import { Step, Steps } from "@infrastructure";
import React, { FunctionComponent } from "react";
import { Contract } from "../../../../../../../../common";

export type AccessRiskDefinitionOptions = {
    resolutionSectionDefinitionComponent?: FunctionComponent<AccessRiskDefinitionResolutionSectionDefinitionProps>;
};

export type AccessRiskDefinitionResolutionSectionDefinitionProps = {
    riskModel: Contract.RiskModel;
};

export function makeAccessResolutionSectionDefinitionComponent(getRiskResolutionChangeSteps: (riskModel: Contract.RiskModel) => Step[]) {
    return function AccessResolutionSectionDefinition({ riskModel }: AccessRiskDefinitionResolutionSectionDefinitionProps) {
        return (
            <Steps variant="plainNumbers">
                {getRiskResolutionChangeSteps(riskModel)}
            </Steps>);
    };
}