﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useAzureCommonComputeDiskResourceInfoItemElements, useAzureDefaultResourceInfoItemElements } from ".";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAzureComputeDiskSnapshotTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";

export function useAzureComputeDiskSnapshotDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonComputeDiskResourceInfoItemElements = useAzureCommonComputeDiskResourceInfoItemElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const snapshot = resourceGroupResourceModel.entity as Contract.AzureComputeDiskSnapshot;
    const snapshotModel = resourceGroupResourceModel as Contract.AzureComputeDiskSnapshotModel;

    const computeDiskSnapshotTypeTranslator = useAzureComputeDiskSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureComputeDiskSnapshotDefinition",
            () => ({
                info: {
                    items: {
                        encryptionType: "Encryption",
                        sourceResourceSearchableIdReference: "Source",
                        type: "Snapshot Type",
                        unauthenticatedDataAccess: {
                            false: "Microsoft Entra ID",
                            title: "Data Access Authentication",
                            true: "None"
                        }
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="unauthenticatedDataAccess"
                        title={localization.info.items.unauthenticatedDataAccess.title()}
                        value={
                            snapshot.unauthenticatedDataAccess
                                ? localization.info.items.unauthenticatedDataAccess.true()
                                : localization.info.items.unauthenticatedDataAccess.false()}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={computeDiskSnapshotTypeTranslator(snapshot.type)}/>,
                    commonComputeDiskResourceInfoItemElements.networkAccessPolicyType,
                    <EntitiesInfoItem
                        entityIdsOrModels={snapshotModel?.sourceResourceSearchableIdReference?.idReference}
                        entityTypeName={Contract.TypeNames.AzureResourceGroupResource}
                        key="sourceResourceSearchableIdReference"
                        location="all"
                        title={localization.info.items.sourceResourceSearchableIdReference()}/>,
                    commonComputeDiskResourceInfoItemElements.encryptionType,
                    commonComputeDiskResourceInfoItemElements.diskEncryptionSetVaultIdReference,
                    commonComputeDiskResourceInfoItemElements.diskEncryptionSetVaultKeyIdReference
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}