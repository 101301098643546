import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonRdsClusterResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsDocDbClusterModelTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";

export function useAwsRdsDocDbClusterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const clusterModel = resourceModel as Contract.AwsRdsDocDbClusterModel;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonRdsClusterResourceInfoElements =
        useAwsCommonRdsClusterResourceInfoElements(
            clusterModel,
            Contract.TypeNames.AwsRdsDocDbDatabaseInstance,
            Contract.TypeNames.AwsRdsDocDbGlobalCluster,
            Contract.TypeNames.AwsRdsDocDbClusterSnapshot);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const docDbClusterModelTypeTranslator = useAwsDocDbClusterModelTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsRdsDocDbClusterDefinition",
            () => ({
                info: {
                    items: {
                        clusterType: "Cluster Type"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="clusterType"
                        title={localization.info.items.clusterType()}
                        value={docDbClusterModelTypeTranslator(clusterModel.type)}/>,
                    commonRdsClusterResourceInfoElements.status,
                    commonRdsClusterResourceInfoElements.engineVersion,
                    commonRdsClusterResourceInfoElements.storageSize,
                    commonRdsClusterResourceInfoElements.instances,
                    commonRdsClusterResourceInfoElements.snapshots,
                    commonRdsClusterResourceInfoElements.globalCluster,
                    ...commonEncryptedResourceInfoElements,
                    commonRdsClusterResourceInfoElements.backupRetentionTimeFrame,
                    commonRdsClusterResourceInfoElements.cloudWatchLoggingEnabled,
                    commonRdsClusterResourceInfoElements.cloudWatchLogTypes,
                    commonRdsClusterResourceInfoElements.deleteProtection,
                    commonRdsClusterResourceInfoElements.availabilityZones
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}