import { ActionButton, map, useLocalization } from "@infrastructure";
import { Box, CircularProgress, SxProps } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { Contract, EmptyStarIcon, FullStarIcon, RiskController, SelectionActionButton, UserHelper, useTheme } from "../../../../../common";
import { RiskControllerUpdateRiskStarredRequest, RiskModel } from "../../../../../common/controllers/types.generated";

type StarActionProps = {
    onError?: () => void;
    onStart?: () => void;
    onToggle?: () => Promise<void>;
    riskModels: RiskModel[];
    title?: string;
    variant?: StarActionVariant;
};

export enum StarActionVariant {
    Action = "action",
    SelectionAction = "selectionAction"
}

export function StarAction({ onError, onStart, onToggle, riskModels, variant = StarActionVariant.Action }: StarActionProps) {
    const starredMap =
        useMemo(
            () =>
                _(riskModels).
                    keyBy(riskModel => riskModel.id).
                    mapValues(riskModel => riskModel.riskConfiguration?.starred ?? false).
                    value(),
            [riskModels]);
    const [loading, setLoading] = useState(false);
    const localization =
        useLocalization(
            "views.customer.risks.starAction",
            () => ({
                confirm: {
                    message: "Are you sure you want to {{action}} {{riskCount | NumberFormatter.humanize}} findings?",
                    starAction: "star",
                    unstarAction: "unstar"
                },
                error: "Failed to update starred",
                star: "Star",
                unstar: "Unstar"
            }));

    async function toggleStarred() {
        onStart?.();
        const updatedStarred = _.some(riskModels, riskModel => !riskModel.riskConfiguration?.starred);
        setLoading(true);
        try {
            await RiskController.updateRiskStarred(
                new RiskControllerUpdateRiskStarredRequest(
                    _.map(riskModels, riskModel => riskModel.id),
                    updatedStarred));

            await onToggle?.();
        } catch {
            onError?.();
        }
        setLoading(false);
    }

    const disabled =
        useMemo(
            () =>
                _.some(
                    riskModels,
                    riskModel =>
                        !UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)),
            [riskModels]);

    const allRiskModelsStarred =
        useMemo(
            () => _.every(starredMap),
            [starredMap]);

    const theme = useTheme();
    return (
        <Fragment>
            {map(
                variant,
                {
                    [StarActionVariant.Action]:
                        () =>
                            <Box
                                sx={{
                                    alignItems: "center",
                                    cursor:
                                        !disabled
                                            ? undefined
                                            : "default",
                                    display: "flex",
                                    height: "18px",
                                    justifyContent: "center",
                                    width: "18px"
                                }}
                                onClick={event => event.stopPropagation()}>
                                {loading
                                    ? <CircularProgress
                                        size={theme.spacing(2)}
                                        variant="indeterminate"/>
                                    : <ActionButton
                                        disabled={disabled}
                                        sx={{
                                            fontSize: "18px",
                                            height: theme.spacing(2.25),
                                            width: theme.spacing(2.25)
                                        }}
                                        onClick={toggleStarred}>
                                        <StarIcon
                                            variant={
                                                allRiskModelsStarred
                                                    ? "full"
                                                    : "empty"}/>
                                    </ActionButton>}
                            </Box>,
                    [StarActionVariant.SelectionAction]:
                        () =>
                            <SelectionActionButton
                                disabled={disabled}
                                message={
                                    localization.confirm.message({
                                        action:
                                            allRiskModelsStarred
                                                ? localization.confirm.unstarAction()
                                                : localization.confirm.starAction(),
                                        riskCount: _.size(starredMap)
                                    })}
                                startIcon={
                                    <StarIcon
                                        sx={{
                                            opacity:
                                                loading || disabled
                                                    ? 0.4
                                                    : 1
                                        }}
                                        variant={
                                            allRiskModelsStarred
                                                ? "empty"
                                                : "full"}/>}
                                onClick={toggleStarred}>
                                {allRiskModelsStarred
                                    ? localization.unstar()
                                    : localization.star()}
                            </SelectionActionButton>
                })}
        </Fragment>);
}

type StarIconProps = {
    sx?: SxProps;
    variant: "empty" | "full";
};

function StarIcon({ sx, variant }: StarIconProps) {
    const theme = useTheme();
    return (
        variant === "empty"
            ? <EmptyStarIcon
                sx={{
                    color: theme.palette.text.primary,
                    ...sx
                }}/>
            : <FullStarIcon
                sx={{
                    color: theme.palette.warning.light,
                    ...sx
                }}/>);
}