//@ts-nocheck
import Mustache from "mustache";
import { Contract } from "../../../common";
import { AzureConsoleHelper } from "./azureConsoleHelper";

export class AzureConsoleUrlBuilder {
    private static readonly _computeVirtualMachineUrlTemplate = "https://{{domainName}}/#blade/HubsExtension/BrowseResource/resourceType/Microsoft.Compute%2FVirtualMachines";
    private static readonly _containerServiceManagedClusterClusterConfigurationUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/configurationBlade";
    private static readonly _containerServiceManagedClusterClusterNodePoolsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/nodePools";
    private static readonly _entityAccessControlUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/accessControl";
    private static readonly _entityAccessKeysUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/accessKey";
    private static readonly _entityActiveDirectoryAdminsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/activeDirectoryAdmins";
    private static readonly _entityActiveDirectoryAdminUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/activeDirectoryAdmin";
    private static readonly _entityAuthenticationUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/eauth";
    private static readonly _entityCertificatesReactUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/certificatesReact";
    private static readonly _entityCertificatesUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/certificates";
    private static readonly _entityConnectionSecurityUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/connectionSecurity";
    private static readonly _entityDataEncryptionUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/tde";
    private static readonly _entityDiagnosticsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/diagnostics";
    private static readonly _entityDisksUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/disks";
    private static readonly _entityFirewallUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/firewall";
    private static readonly _entityInboundRulesUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/inboundSecurityRules";
    private static readonly _entityKeysUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/keys";
    private static readonly _entityNetworkingHubUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/networkingHub";
    private static readonly _entityNetworkingUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/networking";
    private static readonly _entityNetworkSettingsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/networkSettings";
    private static readonly _entityNetworkUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/network";
    private static readonly _entityOverviewUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/overview";
    private static readonly _entityPropertiesUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/properties";
    private static readonly _entityRoleAssignmentsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/users";
    private static readonly _entityRoleManagementPolicyAssignmentsUrlTemplate = "https://{{domainName}}/#view/Microsoft_Azure_PIMCommon/RoleSettingsView/policyId/{{policyId}}/roleName/{{roleName}}";
    private static readonly _entitySecretsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/secrets";
    private static readonly _entityServerAuditingUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/serverAuditing";
    private static readonly _entityServerConfigurationsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/serverParameters";
    private static readonly _entityVirtualNetworkSubnetsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/subnets";
    private static readonly _keyVaultVaultAccessPoliciesUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/access_policies";
    private static readonly _keyVaultVaultObjectUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/asset/Microsoft_Azure_KeyVault/{{typeDisplayNameSingular}}/{{url}}/";
    private static readonly _keyVaultVaultUrlTemplate = "https://{{domainName}}/#blade/HubsExtension/BrowseResource/resourceType/Microsoft.KeyVault%2Fvaults";
    private static readonly _machineLearningWorkspaceStudioWebUrlTemplate = "https://{{machineLearningDomainName}}?tid={{aadTenantRawId}}&wsid={{path}}";
    private static readonly _managementManagementGroupOverviewUrlTemplate = "https://{{domainName}}/#blade/Microsoft_Azure_ManagementGroups/ManagmentGroupDrilldownMenuBlade/overview/tenantId/{{aadTenantRawId}}/mgId/{{rawShortId}}/mgDisplayName/{{displayName}}/mgCanAddOrMoveSubscription/true/mgParentAccessLevel/Owner/defaultMenuItemId/overview/drillDownMode/true";
    private static readonly _managementManagementGroupRoleAssignmentsUrlTemplate = "https://{{domainName}}/#blade/Microsoft_Azure_ManagementGroups/ManagmentGroupDrilldownMenuBlade/iam/tenantId/{{aadTenantRawId}}/mgId/{{rawShortId}}/mgDisplayName/{{displayName}}/mgCanAddOrMoveSubscription/true/mgParentAccessLevel/Owner/defaultMenuItemId/overview/drillDownMode/true";
    private static readonly _networkNetworkSecurityGroupUrlTemplate = "https://{{domainName}}/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.Network%2FNetworkSecurityGroups";
    private static readonly _resourceActivityLogUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/eventlogs";
    private static readonly _resourceUrlTemplate = "https://{{domainName}}/#@/resource{{path}}";
    private static readonly _scopeResourceRoleSchedulesUrlTemplate = "https://{{domainName}}/#blade/Microsoft_Azure_PIMCommon/ResourceMenuBlade/members/provider/azurerbac/resourceExternalId/{{path}}";
    private static readonly _securityAlertPolicyUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/serverADSSetting";
    private static readonly _serverTransportDataEncryptionUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/serverTde";
    private static readonly _storageStorageAccountBlobContainerBlobUrlTemplate = "https://{{domainName}}/#view/Microsoft_Azure_Storage/BlobPropertiesBladeV2/storageAccountId/{{storageAccountPath}}/path/{{blobContainerDisplayName}}%2F{{objectName}}/isDeleted~/false/tabToload~/0";
    private static readonly _storageStorageAccountBlobContainerOverviewUrlTemplate = "https://{{domainName}}/#blade/Microsoft_Azure_Storage/ContainerMenuBlade/overview/storageAccountId/{{storageAccountPath}}/path/{{displayName}}/etag//defaultEncryptionScope//denyEncryptionScopeOverride//defaultId//publicAccessVal/";
    private static readonly _storageStorageAccountBlobContainerRoleAssignmentsUrlTemplate = "https://{{domainName}}/#blade/Microsoft_Azure_Storage/ContainerMenuBlade/accesscontrol/storageAccountId/{{storageAccountPath}}/path/{{displayName}}/etag//defaultEncryptionScope//denyEncryptionScopeOverride//defaultId//publicAccessVal/";
    private static readonly _storageStorageAccountConfigurationUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/configuration";
    private static readonly _storageStorageAccountDataProtectionUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/dataProtection";
    private static readonly _storageStorageAccountEncryptionUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/accountEncryption";
    private static readonly _storageStorageAccountQueueOverviewUrlTemplate = "https://{{domainName}}/#blade/Microsoft_Azure_Storage/QueueMenuBlade/overview/storageAccountId/{{storageAccountPath}}/queueName/{{displayName}}";
    private static readonly _storageStorageAccountQueueRoleAssignmentsUrlTemplate = "https://{{domainName}}/#blade/Microsoft_Azure_Storage/QueueMenuBlade/accesscontrol/storageAccountId/{{storageAccountPath}}/queueName/{{displayName}}";
    private static readonly _storageStorageAccountRoleAssignmentsUrlTemplate = "https://{{domainName}}/#@{{aadTenantPrimaryDomainName}}/resource{{path}}/iamAccessControl";
    private static readonly _storageStorageAccountUrlTemplate = "https://{{domainName}}/#blade/HubsExtension/BrowseResource/resourceType/Microsoft.Storage%2FStorageAccounts";

public static GetComputeVirtualMachineUrl(aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AzureConsoleUrlBuilder._computeVirtualMachineUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }

public static GetContainerServiceManagedClusterClusterConfigurationUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._containerServiceManagedClusterClusterConfigurationUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetContainerServiceManagedClusterClusterNodePoolsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._containerServiceManagedClusterClusterNodePoolsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityAccessControlUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityAccessControlUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityAccessKeysUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityAccessKeysUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityActiveDirectoryAdminsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityActiveDirectoryAdminsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityActiveDirectoryAdminUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityActiveDirectoryAdminUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityAuthenticationUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityAuthenticationUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityCertificatesReactUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityCertificatesReactUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityCertificatesUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityCertificatesUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityConnectionSecurityUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityConnectionSecurityUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityDataEncryptionUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityDataEncryptionUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityDiagnosticsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityDiagnosticsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityDisksUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityDisksUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityFirewallUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityFirewallUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityInboundRulesUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityInboundRulesUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityKeysUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityKeysUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityNetworkingHubUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityNetworkingHubUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityNetworkingUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityNetworkingUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityNetworkSettingsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityNetworkSettingsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityNetworkUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityNetworkUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityOverviewUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityOverviewUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityPropertiesUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityPropertiesUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityRoleAssignmentsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityRoleAssignmentsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityRoleManagementPolicyAssignmentsUrl(

        aadPartitionType: Contract.AadPartitionType,
        policyId: string,
        roleName: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityRoleManagementPolicyAssignmentsUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "policyId": policyId,
                "roleName": roleName
            });
    }

public static GetEntitySecretsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entitySecretsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityServerAuditingUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityServerAuditingUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityServerConfigurationsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityServerConfigurationsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetEntityVirtualNetworkSubnetsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._entityVirtualNetworkSubnetsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetKeyVaultVaultAccessPoliciesUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._keyVaultVaultAccessPoliciesUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetKeyVaultVaultObjectUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        typeDisplayNameSingular: string,
        url: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._keyVaultVaultObjectUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "typeDisplayNameSingular": typeDisplayNameSingular,
                "url": url
            });
    }

public static GetKeyVaultVaultUrl(aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AzureConsoleUrlBuilder._keyVaultVaultUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }

public static GetMachineLearningWorkspaceStudioWebUrl(

        aadTenantRawId: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._machineLearningWorkspaceStudioWebUrlTemplate,
            {
                "aadTenantRawId": aadTenantRawId,
                "machineLearningDomainName": AzureConsoleHelper.getMachineLearningDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetManagementManagementGroupOverviewUrl(

        aadTenantRawId: string,
        displayName: string,
        aadPartitionType: Contract.AadPartitionType,
        rawShortId: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._managementManagementGroupOverviewUrlTemplate,
            {
                "aadTenantRawId": aadTenantRawId,
                "displayName": displayName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawShortId": rawShortId
            });
    }

public static GetManagementManagementGroupRoleAssignmentsUrl(

        aadTenantRawId: string,
        displayName: string,
        aadPartitionType: Contract.AadPartitionType,
        rawShortId: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._managementManagementGroupRoleAssignmentsUrlTemplate,
            {
                "aadTenantRawId": aadTenantRawId,
                "displayName": displayName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawShortId": rawShortId
            });
    }

public static GetNetworkNetworkSecurityGroupUrl(aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AzureConsoleUrlBuilder._networkNetworkSecurityGroupUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }

public static GetResourceActivityLogUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._resourceActivityLogUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetResourceUrl(

        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._resourceUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetScopeResourceRoleSchedulesUrl(

        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._scopeResourceRoleSchedulesUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetSecurityAlertPolicyUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._securityAlertPolicyUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetServerTransportDataEncryptionUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._serverTransportDataEncryptionUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetStorageStorageAccountBlobContainerBlobUrl(

        blobContainerDisplayName: string,
        aadPartitionType: Contract.AadPartitionType,
        objectName: string,
        storageAccountPath: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountBlobContainerBlobUrlTemplate,
            {
                "blobContainerDisplayName": blobContainerDisplayName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "objectName": objectName,
                "storageAccountPath": storageAccountPath
            });
    }

public static GetStorageStorageAccountBlobContainerOverviewUrl(

        displayName: string,
        aadPartitionType: Contract.AadPartitionType,
        storageAccountPath: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountBlobContainerOverviewUrlTemplate,
            {
                "displayName": displayName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "storageAccountPath": storageAccountPath
            });
    }

public static GetStorageStorageAccountBlobContainerRoleAssignmentsUrl(

        displayName: string,
        aadPartitionType: Contract.AadPartitionType,
        storageAccountPath: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountBlobContainerRoleAssignmentsUrlTemplate,
            {
                "displayName": displayName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "storageAccountPath": storageAccountPath
            });
    }

public static GetStorageStorageAccountConfigurationUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountConfigurationUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetStorageStorageAccountDataProtectionUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountDataProtectionUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetStorageStorageAccountEncryptionUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountEncryptionUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetStorageStorageAccountQueueOverviewUrl(

        displayName: string,
        aadPartitionType: Contract.AadPartitionType,
        storageAccountPath: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountQueueOverviewUrlTemplate,
            {
                "displayName": displayName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "storageAccountPath": storageAccountPath
            });
    }

public static GetStorageStorageAccountQueueRoleAssignmentsUrl(

        displayName: string,
        aadPartitionType: Contract.AadPartitionType,
        storageAccountPath: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountQueueRoleAssignmentsUrlTemplate,
            {
                "displayName": displayName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "storageAccountPath": storageAccountPath
            });
    }

public static GetStorageStorageAccountRoleAssignmentsUrl(

        aadTenantPrimaryDomainName: string,
        aadPartitionType: Contract.AadPartitionType,
        path: string) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountRoleAssignmentsUrlTemplate,
            {
                "aadTenantPrimaryDomainName": aadTenantPrimaryDomainName,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "path": path
            });
    }

public static GetStorageStorageAccountUrl(aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AzureConsoleUrlBuilder._storageStorageAccountUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }
}