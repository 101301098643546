﻿import { Optional } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../common";
import { GroupMembershipEligibilityData } from "../../../utilities";

export class OktaDirectoryGroupMembershipEligibilityData extends GroupMembershipEligibilityData {
    constructor(
        permissionEligibilityModel?: Optional<Contract.PermissionEligibilityModel>,
        scopeId?: string) {
        super(
            permissionEligibilityModel,
            scopeId);
    }
}