import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpPubSubTopicRiskContext, useGetGcpScopeResourcePublicAccessExistsRiskContext } from "../../../contexts";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpPubSubTopicPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpPubSubTopicPublicAccessExistsRisk;
    const topicModel = entityModelStore.useGet(risk.entityId) as Contract.GcpPubSubTopicModel;

    const getGcpPubSubTopicRiskContext = useGetGcpPubSubTopicRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpPubSubTopicPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{topic}} is publicly accessible via IAM roles",
                sections: {
                    resolution: {
                        step1: "Before changing the permissions, verify that identities can access the topic without public access",
                        step2: "Click on **SHOW INFO PANEL** if not already shown",
                        step3: "Delete the role bindings to allUsers / allAuthenticatedUsers"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            topic:
                <Entity
                    entityIdOrModel={topicModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const topicRiskContext = getGcpPubSubTopicRiskContext(topicModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(risk);
            return [
                topicRiskContext.generalInformation,
                topicRiskContext.sensitive,
                topicRiskContext.subscriptions,
                topicRiskContext.triggerFunctions,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                topicRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ScopeResourceRoleBindingTableSection
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}