﻿import { InlineItems, TimeHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { GcpApiKeysKeyRestrictionsClientRawIdentifiers } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpApiKeysKeyRiskContext() {
    return useMemo(
        () => useGcpApiKeysKeyRiskContext,
        []);
}

function useGcpApiKeysKeyRiskContext(keyModel: Contract.GcpApiKeysKeyModel) {
    const key = keyModel.entity as Contract.GcpApiKeysKey;
    const entityRiskContext = useGetGcpEntityRiskContext()(keyModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpApiKeysKeyRiskContext",
            () => ({
                apis: [
                    "1 API",
                    "{{count | NumberFormatter.humanize}} APIs"
                ],
                creationTime: "The {{translatedKeyTypeName}} was created **{{creationTime | TimeSpanFormatter.humanizeDays}} ago**",
                restrictions: {
                    empty: "The {{translatedKeyTypeName}} has no restrictions",
                    notEmpty: "The {{translatedKeyTypeName}} has {{restrictions}}"
                }
            }));

    const translatedKeyTypeName =
        entityTypeNameTranslator(
            keyModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        clientRawIdentifiers:
            key.restrictions.clientType === Contract.GcpApiKeysKeyRestrictionsClientType.None
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.restrictions.notEmpty({
                        restrictions: <GcpApiKeysKeyRestrictionsClientRawIdentifiers keyRestrictions={key.restrictions}/>,
                        translatedKeyTypeName
                    })),
        creationTime:
            new RiskDefinitionContextItem(
                localization.creationTime(
                    {
                        creationTime: TimeHelper.getInterval(keyModel.creationTime!),
                        translatedKeyTypeName
                    })),
        emptyRestrictions:
            _.isEmpty(key.restrictions.serviceHostNames) && _.isEmpty(key.restrictions.clientRawIdentifiers)
                ? new RiskDefinitionContextItem(localization.restrictions.empty({ translatedKeyTypeName }))
                : undefined,
        serviceHostNames:
            _.isEmpty(key.restrictions.serviceHostNames)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.restrictions.notEmpty({
                        restrictions:
                            <InlineItems
                                items={key.restrictions.serviceHostNames}
                                namePluralizer={localization.apis}
                                variant="itemCountAndType"/>,
                        translatedKeyTypeName
                    }))
    };
}