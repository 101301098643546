import { EmptyMessageText, ItemSelector, Loading, map, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, Entity, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem, VerticalTopItemsInfoItem, VulnerabilityChart } from "../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../components";

type KubernetesContainersProps = {
    workloadResourceModel: Contract.IKubernetesWorkloadResourceModel;
};

export function KubernetesContainers({ workloadResourceModel }: KubernetesContainersProps) {
    const workloadResource = _.as<Contract.IKubernetesWorkloadResource>(workloadResourceModel.entity);
    const containers = workloadResource.data.podTemplateSpec.podSpec.containers;
    const [selectedContainer, setSelectedContainer] = useState(() => _.head(containers)!);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.kubernetesContainers",
            () => ({
                fields: {
                    environmentVariables: {
                        empty: "No Environment Variables",
                        reference: "{{entityName}}:{{key}}",
                        title: "Environment Variables"
                    },
                    image: "Image",
                    imageVulnerabilities: "Vulnerabilities",
                    ports: {
                        port: "{{protocolType}} : {{port}}",
                        title: "Ports"
                    }
                }
            }));
    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                getItemText={container => container.name}
                items={containers}
                selectedItem={selectedContainer}
                sorted={true}
                onSelectedItemChanged={container => setSelectedContainer(container)}>
                {container => container.name}
            </ItemSelector>
            <Loading>
                <InfoCard columns={true}>
                    <EntitiesInfoItem
                        entityIdsOrModels={workloadResourceModel.containerImageRawUrlToDataMap[selectedContainer.imageRawUrl].containerImageIdReference}
                        entityTypeName={Contract.TypeNames.IContainerImage}
                        title={localization.fields.image()}/>
                    <InfoItem
                        title={localization.fields.imageVulnerabilities()}
                        value={
                            _.isEmpty(workloadResourceModel.containerImageRawUrlToDataMap[selectedContainer.imageRawUrl]?.vulnerabilities)
                                ? undefined
                                : <VulnerabilityChart
                                    variant="initialAndCount"
                                    vulnerabilities={workloadResourceModel.containerImageRawUrlToDataMap[selectedContainer.imageRawUrl].vulnerabilities}/>}/>
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                selectedContainer.ports,
                                port =>
                                    localization.fields.ports.port({
                                        port: port.port,
                                        protocolType: port.protocolType.toUpperCase()
                                    }))}
                        title={localization.fields.ports.title()}/>
                </InfoCard>
                <InfoCard title={localization.fields.environmentVariables.title()}>
                    <KeyValueTable
                        emptyMessageText={new EmptyMessageText(localization.fields.environmentVariables.empty())}
                        items={
                            _.map(
                                selectedContainer.environmentVariables,
                                environmentVariable =>
                                    new KeyValueTableItem(
                                        environmentVariable.name,
                                        environmentVariable.value ??
                                        (_.isNil(environmentVariable.valueReference)
                                            ? undefined
                                            : map(
                                                environmentVariable.valueReference.typeName,
                                                {
                                                    [Contract.TypeNames.KubernetesEnvironmentVariableValueReference]:
                                                        () => environmentVariable.valueReference!.key,
                                                    [Contract.TypeNames.KubernetesEnvironmentVariableContainerPropertyReference]:
                                                        () =>
                                                            localization.fields.environmentVariables.reference({
                                                                entityName: (environmentVariable.valueReference! as Contract.KubernetesEnvironmentVariableContainerPropertyReference).containerName,
                                                                key: environmentVariable.valueReference!.key
                                                            }),
                                                    [Contract.TypeNames.KubernetesEnvironmentVariableResourceReference]:
                                                        () =>
                                                            localization.fields.environmentVariables.reference({
                                                                entityName:
                                                                    <Entity
                                                                        entityIdOrModel={(environmentVariable.valueReference! as Contract.KubernetesEnvironmentVariableResourceReference).resourceReference.idReference}
                                                                        variant="text"/>,
                                                                key: environmentVariable.valueReference!.key
                                                            })
                                                }))))}/>
                </InfoCard>
            </Loading>
        </InfoCard>);
}