import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureSqlServerRiskContext } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useGetRiskPolicyConfigurationRetentionTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRetentionTimeFrameContextItem";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureSqlServerEventRetentionTimeFrameRiskDefinition(riskModel: Contract.RiskModel) {
    const eventRetentionTimeFrameRiskModel = riskModel as Contract.AzureSqlServerEventRetentionTimeFrameRiskModel;
    const risk = riskModel.risk as Contract.AzureSqlServerEventRetentionTimeFrameRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureSqlServerModel;

    const getAzureSqlServerRiskContext = useGetAzureSqlServerRiskContext();
    const getRiskPolicyConfigurationRetentionTimeFrameContextItem = useGetRiskPolicyConfigurationRetentionTimeFrameContextItem();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureSqlServerEventRetentionTimeFrameRiskDefinition",
            () => ({
                description: "{{ server }} audit retention is less than {{configuredEventRetentionTimeFrame | TimeSpanFormatter.humanizeDays}}",
                sections: {
                    resolution: {
                        step1: "In the **Azure SQL Auditing** section, click **Advanced properties**",
                        step2: "Set **Retention (Days)** to **{{eventRetentionTimeFrame | TimeSpanFormatter.humanizeDays}}**",
                        step3: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            configuredEventRetentionTimeFrame: eventRetentionTimeFrameRiskModel.risk.eventRetentionTimeFrame,
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.ServerAuditing}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({ eventRetentionTimeFrame: eventRetentionTimeFrameRiskModel.risk.eventRetentionTimeFrame }),
            localization.sections.resolution.step3()

        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                getRiskPolicyConfigurationRetentionTimeFrameContextItem(risk.eventRetentionTimeFrame),
                serverRiskContext.authenticationType,
                serverRiskContext.status,
                serverRiskContext.storageSize,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}