﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetKubernetesClusterRiskContext } from "../../../useGetKubernetesClusterRiskContext";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpContainerClusterRiskContext() {
    return useMemo(
        () => useGcpContainerClusterRiskContext,
        []);
}

export function useGcpContainerClusterRiskContext(entityModel: Contract.EntityModel) {
    const clusterModel = entityModel as Contract.GcpContainerClusterModel;
    const cluster = entityModel.entity as Contract.GcpContainerCluster;
    const entityRiskContext = useGetGcpEntityRiskContext()(clusterModel);
    const kubernetesClusterRiskContext = useGetKubernetesClusterRiskContext()(clusterModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpContainerClusterRiskContext",
            () => ({
                authorizedSubnetDataDisabled: "The {{translatedClusterTypeName}}'s authorized networks is **disabled**",
                inboundExternalGcpRange: "**Allow access through Google Cloud public IP addresses** is enabled allowing unrestricted network inbound internet access from all GCP resources to the Kubernetes API, including virtual machines from any project",
                mode: "The {{translatedClusterTypeName}} mode is **{{mode}}**",
                nodePools: "The {{translatedClusterTypeName}} has {{nodePools}}",
                vpc: "The {{translatedClusterTypeName}} is part of {{vpc}}"
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            cluster.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        ...kubernetesClusterRiskContext,
        mode:
            new RiskDefinitionContextItem(
                localization.mode({
                    mode: cluster.mode,
                    translatedClusterTypeName
                })
            ),
        network:
            _.isNil(cluster.network.authorizedSubnetDatas)
                ? new RiskDefinitionContextItem(localization.authorizedSubnetDataDisabled({ translatedClusterTypeName }))
                : cluster.network.inboundExternalGcpRange
                    ? new RiskDefinitionContextItem(localization.inboundExternalGcpRange())
                    : undefined,
        nodePools:
            _.isEmpty(clusterModel.nodePoolIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.nodePools({
                        nodePools:
                            <InlineEntities
                                entityIdsOrModels={clusterModel.nodePoolIds}
                                entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                                variant="itemCountAndType"/>,
                        translatedClusterTypeName
                    })),
        vpc:
            new RiskDefinitionContextItem(
                localization.vpc({
                    translatedClusterTypeName,
                    vpc:
                        <InlineEntities
                            entityIdsOrModels={clusterModel.vpcIdReference}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            variant="itemAndTypeOrItemCountAndType"/>
                })),
        wideRangeAuthorizedSubnets:
            entityRiskContext.getWideRangeInboundSubnetsContextItem(
                _.map(
                    clusterModel.wideRangeAuthorizedSubnetDatas,
                    wideRangeAuthorizedSubnetData => wideRangeAuthorizedSubnetData.subnet))
    };
}