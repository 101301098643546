import { CopyToClipboardText, IconText, Tooltip, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, InfoItem, ScanResult, scopeSystemEntityModelStore } from "../../../../../../../../../../../common";
import { CodeIntegrationIcon, useCodeIntegrationTranslator } from "../../../../../../../../../../../tenants";
import { VulnerabilitiesChartCell } from "../../../../../../VulnerabilitiesChartCell";

type OverviewProps = {
    scan: Contract.CodeContainerImageScan;
};

export function Overview({ scan }: OverviewProps) {
    const codeIntegrationConfiguration = scopeSystemEntityModelStore.useGet(scan.integrationId).configuration as Contract.CodeIntegrationConfiguration;

    const codeIntegrationTranslator = useCodeIntegrationTranslator();

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.scans.profile.overview",
            () => ({
                configurationDigest: "Image ID",
                digest: "Digest",
                integration: "Integration",
                operatingSystem: "Operating System",
                repositoryRawUrl: "Container Repository",
                success: {
                    false: "Failed",
                    title: "Result",
                    true: "Passed"
                },
                tag: "Tag",
                vulnerabilities: "Vulnerabilities"
            }));

    return (
        <InfoCard columns={true}>
            <InfoItem
                title={localization.repositoryRawUrl()}
                value={scan.repositoryRawUrl}/>
            <InfoItem
                title={localization.tag()}
                value={scan.tag}/>
            <InfoItem
                title={localization.digest()}
                value={scan.digest}/>
            <InfoItem
                title={localization.configurationDigest()}
                value={scan.configurationDigest}/>
            <InfoItem
                title={localization.vulnerabilities()}
                value={
                    <VulnerabilitiesChartCell
                        variant="initialAndCount"
                        vulnerabilities={
                            _(scan.vulnerabilitySeverityToRawIdsMap).
                                toPairs().
                                map(([severity, vulnerabilityRawIds]) =>
                                    _.map(
                                        vulnerabilityRawIds,
                                        vulnerabilityRawId =>
                                            new Contract.VulnerabilityData(
                                                vulnerabilityRawId,
                                                severity as Contract.Severity))).
                                flatMap().
                                value()}
                        workloadResourceEntityId={scan.id}/>}
                valueSx={{ maxWidth: "100%" }}/>
            <InfoItem
                title={localization.operatingSystem()}
                value={
                    _.isNil(scan.operatingSystem.distribution)
                        ? scan.operatingSystem.type
                        : `${scan.operatingSystem.distribution} ${scan.operatingSystem.version}`}/>
            <InfoItem
                title={localization.integration()}
                value={
                    <IconText
                        icon={
                            <Tooltip titleOrGetTitle={codeIntegrationTranslator(codeIntegrationConfiguration)}>
                                <Box>
                                    <CodeIntegrationIcon
                                        configuration={codeIntegrationConfiguration}
                                        sx={{ fontSize: "18px" }}/>
                                </Box>
                            </Tooltip>}
                        text={<CopyToClipboardText text={codeIntegrationConfiguration.name}/>}/>}/>
            <InfoItem
                title={localization.success.title()}
                value={
                    <ScanResult
                        failed={localization.success.false()}
                        passed={localization.success.true()}
                        success={scan.success}/>}/>
        </InfoCard>);
}