import { Action1 } from "@infrastructure";
import _ from "lodash";
import { UserHelper } from ".";
import { Contract } from "../controllers";

declare global {
    interface Window {
        pendo: Pendo;
    }
}

export class PendoHelper {
    private static readonly resourceCenterGuideId = "C76FPlyc5UlBUkNUWGIu4-Bd0BA";
    private static _guides: PendoGuideData[] = [];

    public static initialize(systemInfo: Contract.SystemControllerGetInfoResponse) {
        if (_.isEmpty(systemInfo.pendoApiKey)) {
            return;
        }

        const scriptElement = document.createElement("script");
        scriptElement.src = `https://content.pendo-tio.tenable.com/agent/static/${systemInfo.pendoApiKey}/pendo.js`;
        scriptElement.type = "text/javascript";
        scriptElement.onload =
            () => {
                window.pendo.initialize({
                    account: {
                        id: UserHelper.customer?.tenableId,
                        name: UserHelper.customer?.name
                    },
                    apiKey: systemInfo.pendoApiKey,
                    delayGuides: false,
                    disablePersistence: true,
                    usePendoAgentAPI: true,
                    visitor: {
                        email: UserHelper.mail,
                        id: `${UserHelper.customer?.tenableId}-${UserHelper.tenableId ?? UserHelper.mailHash}`,
                        name: UserHelper.name,
                        tenable: UserHelper.tenable
                    }
                });
            };

        document.head.appendChild(scriptElement);
    }

    public static get guides() {
        if (!_.isNil(window.pendo) && _.isEmpty(PendoHelper._guides)) {
            const resourceCenterGuide = window.pendo.findGuideById(PendoHelper.resourceCenterGuideId)!;
            PendoHelper._guides =
                _(resourceCenterGuide.children).
                    map(childGuideId => window.pendo.findGuideById(childGuideId)).
                    filter().
                    map(
                        guide => {
                            const { templateChildren } =
                                window.pendo.BuildingBlocks.BuildingBlockGuides.findDomBlockInDomJson(
                                    guide.steps[0]?.domJson ?? {},
                                    json => json?.templateChildren) ?? {};

                            return ({
                                id: guide.id,
                                items:
                                    _(guide.children).
                                        map(childGuideId => window.pendo.findGuideById(childGuideId)).
                                        filter().
                                        map(
                                            guide => ({
                                                id: guide.id,
                                                name:
                                                _.find(
                                                    templateChildren,
                                                    templateChild => templateChild.id === guide.id)?.title ?? guide.name,
                                                seenSteps: guide.getSeenSteps(),
                                                steps: guide.getTotalSteps()
                                            })).
                                        value(),
                                name: guide.name
                            } as PendoGuideData);
                        }).
                    value();
        }

        return PendoHelper._guides;
    }
}

type Pendo = {
    BuildingBlocks: PendoBuildingBlocks;
    findGuideById: (id: string) => PendoGuide;
    initialize: Action1<PendoInitialize>;
    showGuideById: Action1<string>;
};

type PendoBuildingBlocks = {
    BuildingBlockGuides: PendoBuildingBlockGuides;
};

type PendoBuildingBlockGuides = {
    findDomBlockInDomJson: (domJson: any, find: (json: any) => PendoBuildingBlockGuideDomBlockTemplateChildren[]) => PendoBuildingBlockGuideDomBlock;
};

type PendoBuildingBlockGuideDomBlock = {
    templateChildren: PendoBuildingBlockGuideDomBlockTemplateChildren[];
};

type PendoBuildingBlockGuideDomBlockTemplateChildren = {
    id: string;
    title: string;
};

type PendoGuide = {
    children: string[];
    getSeenSteps: () => number;
    getTotalSteps: () => number;
    id: string;
    name: string;
    steps: PendoGuideStep[];
};

type PendoGuideData = {
    id: string;
    items: PendoGuideDataItem[];
    name: string;
};

type PendoGuideDataItem = {
    id: string;
    name: string;
    seenSteps: number;
    steps: number;
};

type PendoGuideStep = {
    domJson?: any;
};

type PendoInitialize = {
    account: PendoInitializeAccount;
    apiKey?: string;
    delayGuides: boolean;
    disablePersistence: boolean;
    usePendoAgentAPI: boolean;
    visitor: PendoInitializeVisitor;
};

type PendoInitializeAccount = {
    id?: string;
    name?: string;
};

type PendoInitializeVisitor = {
    email: string;
    id: string;
    name: string;
    tenable: boolean;
};