import moment from "moment-timezone";

export class TimeFormatter {
    public static date(time: moment.MomentInput) {
        return moment(time).
            utc().
            format();
    }

    public static duration(time: moment.MomentInput) {
        return moment.duration(moment(time).diff(moment())).humanize({ d: 50, h: 40, M: 20, m: 90, s: 90, ss: -1 });
    }

    public static fileNameDateTime(time: moment.MomentInput) {
        return moment(time).format("YYYY-MM-DD-HH_mm");
    }

    public static hour(hour: number) {
        return moment().
            hour(hour).
            minutes(0).
            format("hh:mmA");
    }

    public static humanizeDuration(time: moment.MomentInput) {
        return moment.duration(moment(time).diff(moment())).humanize({ M: 36 });
    }

    public static humanizeHour(time: moment.MomentInput) {
        return moment(time).format("LT");
    }

    public static humanizeMonthDay(monthDay: number) {
        return moment().
            date(monthDay).
            format("Do");
    }

    public static humanizePastDuration(time: moment.MomentInput) {
        return moment.duration(moment(time).diff(moment())).humanize(true, { M: 36 });
    }

    public static humanizeWeekDay(weekDay: number) {
        return moment().
            day(weekDay).
            format("dddd");
    }

    public static humanizeWeekDayAndDate(time: moment.MomentInput) {
        return moment(time).format("ddd, D MMM");
    }

    public static humanizeWeekDayAndMediumDate(time: moment.MomentInput) {
        return moment(time).format("ddd, D MMM YYYY");
    }

    public static iso8601String(time: moment.MomentInput) {
        return moment(time).format("yyyy-MM-DDTHH:mm:ssZ");
    }

    public static jiraDate(time: moment.MomentInput) {
        return moment(time).format("YYYY-MM-DD");
    }

    public static jiraDateTime(time: moment.MomentInput) {
        return moment(time).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
    }

    public static longDate(time: moment.MomentInput) {
        return moment(time).format("LL");
    }

    public static longDateTime(time: moment.MomentInput) {
        return moment(time).format("LLL");
    }

    public static longTime(time: moment.MomentInput) {
        return moment(time).format("LTS");
    }

    public static mediumDate(time: moment.MomentInput) {
        return moment(time).format("ll");
    }

    public static mediumDateShortTime(time: moment.MomentInput) {
        return moment(time).format("ll LT");
    }

    public static mediumDateTime(time: moment.MomentInput) {
        return moment(time).format("ll LTS");
    }

    public static monthAndDay(time: moment.MomentInput) {
        return moment(time).format("D MMM");
    }

    public static monthDayAndYear(time: moment.MomentInput) {
        return moment(time).format("MMM D, YYYY");
    }

    public static shortDate(time: moment.MomentInput) {
        return moment(time).format("L");
    }

    public static shortDateShortTime(time: moment.MomentInput) {
        return moment(time).format("L LT");
    }

    public static shortDateTime(time: moment.MomentInput) {
        return moment(time).format("L LTS");
    }

    public static shortTime(time: moment.MomentInput) {
        return moment(time).format("LT");
    }

    public static sortableDateTime(time: moment.MomentInput) {
        return moment(time).format("yyyy-MM-DD HH:mm:ss.mmm");
    }

    public static weekStartDay(): number {
        return moment.localeData().firstDayOfWeek();
    }

    public static workloadResourceDateTime(time: moment.MomentInput) {
        return moment(time).format("yyyy-MM-DD HH:mm");
    }

    public static profileFormatDateTime(time: moment.MomentInput) {
        return TimeFormatter.mediumDateShortTime(time);
    }
}