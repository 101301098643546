import { CheckboxField, Link, Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../common";

type FolderEnabledProps = {
    folderEnabled: boolean;
    info: string;
    setFolderEnabled: (item: any) => void;
    title: string;
};

export function FolderEnabled({ folderEnabled, info, setFolderEnabled, title }: FolderEnabledProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.folderEnabled",
            () => ({
                docsLink: "Learn more",
                info: "{{info}}. {{docsLink}}"
            }));
    return (
        <CheckboxField
            checked={folderEnabled}
            onChange={() => setFolderEnabled(!folderEnabled)}>
            <Stack
                direction="row"
                justifyItems="center"
                spacing={1}>
                <Typography>
                    {title}
                </Typography>
                <Message
                    level="info"
                    title={
                        localization.info({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsCreateAnAccountHierarchyWithFoldersRelativeUrl()}
                                    variant="external">
                                    {localization.docsLink()}
                                </Link>,
                            info
                        })}
                    variant="minimal"/>
            </Stack>
        </CheckboxField>);
}