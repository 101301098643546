import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { AwsCloudFormationStackOutputTable, AwsCloudFormationStackParameterTable, AwsCloudFormationStackTemplateTextViewer } from "../../../../../../../../../../tenants";
import { useAwsCloudFormationStackDriftStatusTranslator, useAwsCloudFormationStackStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsCloudFormationStackDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const stack = resourceModel.entity as Contract.AwsCloudFormationStack;
    const stackModel = resourceModel as Contract.AwsCloudFormationStackModel;

    const stackDriftStatusTranslator = useAwsCloudFormationStackDriftStatusTranslator();
    const stackStatusTranslator = useAwsCloudFormationStackStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCloudFormationStackDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        description: "Description",
                        driftCheckTime: "Drift Check Time",
                        driftStatus: "Drift Status",
                        role: "IAM Role",
                        root: "Root Stack",
                        status: "Status",
                        terminationProtection: {
                            false: "Deactivated",
                            title: "Termination Protection",
                            true: "Activated"
                        },
                        updateTime: "Update Time"
                    }
                },
                tabs: {
                    outputs: "Outputs",
                    parameters: "Parameters",
                    template: "Template"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <AwsCloudFormationStackOutputTable
                    outputResourceReferences={stackModel.outputResourceReferences}
                    outputs={stack.outputs}/>,
                localization.tabs.outputs(),
                "stackOutputs"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <AwsCloudFormationStackParameterTable
                    parameterDefaultValuesMissing={stack.parameterDefaultValuesMissing}
                    parameters={stack.parameters}/>,
                localization.tabs.parameters(),
                "stackParameters"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <AwsCloudFormationStackTemplateTextViewer template={stack.template}/>,
                localization.tabs.template(),
                "stackTemplate")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        emptyValue={stack.rootRawId}
                        entityIdsOrModels={stackModel.rootId}
                        entityTypeName={Contract.TypeNames.AwsCloudFormationStack}
                        key="root"
                        title={localization.info.items.root()}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={stackStatusTranslator(stack.status)}/>,
                    <InfoItem
                        key="updateTime"
                        title={localization.info.items.updateTime()}
                        value={
                            _.isNil(stack.updateTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(stack.updateTime)}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={stack.description}/>,
                    <InfoItem
                        key="driftStatus"
                        title={localization.info.items.driftStatus()}
                        value={stackDriftStatusTranslator(stack.driftStatus)}/>,
                    <InfoItem
                        key="driftCheckTime"
                        title={localization.info.items.driftCheckTime()}
                        value={
                            _.isNil(stack.driftCheckTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(stack.driftCheckTime)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={stackModel.roleIdReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="role"
                        title={localization.info.items.role()}/>,
                    <InfoItem
                        key="terminationProtection"
                        title={localization.info.items.terminationProtection.title()}
                        value={
                            stack.terminationProtection
                                ? localization.info.items.terminationProtection.true()
                                : localization.info.items.terminationProtection.false()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={stackModel.resourceTypeNameToIdReferencesMap}
                    title={localization.info.cards.resources.title()}/>
            </Info>
    });
}