import { InlineItems, Sx, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useNetworkAccessScopeTranslator } from "../../..";
import { SourceIpAddressSelectorSelection } from "./SourceIpAddressSelector";

type InlineSourceIpAddressSelectionProps = {
    selection?: InlineSourceIpAddressSelectionData | SourceIpAddressSelectorSelection;
    sx?: SxProps;
};

export function InlineSourceIpAddressSelection({ selection, sx }: InlineSourceIpAddressSelectionProps) {
    const [anyIpV4AddressSubnet, exactSubnets, inboundAccessScope, overlapSubnets] =
        useMemo(
            () => {
                const inlineSelectionData =
                    selection instanceof SourceIpAddressSelectorSelection
                        ? selection.getInlineSelectionData()
                        : selection;
                return [
                    inlineSelectionData?.anyIpV4AddressSubnet,
                    inlineSelectionData?.exactSubnets,
                    inlineSelectionData?.inboundAccessScope,
                    inlineSelectionData?.overlapSubnets
                ];
            },
            [selection]);

    const networkAccessScopeTranslator = useNetworkAccessScopeTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.inlineSourceIpAddressSelection",
            () => ({
                anyIpV4AddressSubnet: "Any IPv4 address",
                exactSubnets: [
                    "1 IPv4 address range (exact match)",
                    "{{count | NumberFormatter.humanize}} IPv4 address ranges (exact match)"
                ],
                overlapSubnets: [
                    "1 IPv4 address range (inclusive)",
                    "{{count | NumberFormatter.humanize}} IPv4 address ranges (inclusive)"
                ],
                placeholder: "these IPv4 address ranges"
            }));

    const textSx =
        Sx.combine(
            {
                fontWeight: 600,
                textDecoration: "underline"
            },
            sx);

    return (
        <Typography
            component="span"
            sx={textSx}>
            {!_.isNil(inboundAccessScope)
                ? networkAccessScopeTranslator(inboundAccessScope)
                : !_.isNil(anyIpV4AddressSubnet)
                    ? localization.anyIpV4AddressSubnet()
                    : _.isEmpty(exactSubnets) && _.isEmpty(overlapSubnets)
                        ? localization.placeholder()
                        : !_.isEmpty(exactSubnets)
                            ? <InlineItems
                                items={exactSubnets}
                                namePluralizer={localization.exactSubnets}
                                sx={textSx}
                                variant="itemCountAndType"/>
                            : <InlineItems
                                items={overlapSubnets}
                                namePluralizer={localization.overlapSubnets}
                                sx={textSx}
                                variant="itemCountAndType"/>}
        </Typography>);
}

export type InlineSourceIpAddressSelectionData = {
    anyIpV4AddressSubnet?: boolean;
    exactSubnets?: string[];
    inboundAccessScope?: Contract.NetworkAccessScope;
    overlapSubnets?: string[];
};