﻿import { Sx, useChangeEffect, useLocalization } from "@infrastructure";
import { SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useState } from "react";

type PagedItemsProps<T> = {
    children: (item: T, index: number) => ReactNode;
    enabled?: boolean;
    items: T[];
    loaderSx?: SxProps;
};

export function PagedItems<T>({ children: renderItem, enabled = true, items, loaderSx }: PagedItemsProps<T>) {
    const localization =
        useLocalization(
            "infrastructure.pagedItems",
            () => ({
                actions: {
                    load: "Load more..."
                }
            }));

    const itemPageSize = 50;
    const [loadedItems, setLoadedItems] = useState(() => _.take(items, itemPageSize));
    useChangeEffect(
        () => {
            setLoadedItems(_.take(items, itemPageSize));
        },
        [items]);

    const theme = useTheme();
    return (
        <Fragment>
            {_.map(
                enabled
                    ? loadedItems
                    : items,
                (item, index) =>
                    renderItem(
                        item,
                        index))}
            {enabled &&
                loadedItems.length < items.length &&
                <Typography
                    sx={
                        Sx.combine(
                            {
                                cursor: "pointer",
                                fontWeight: 600,
                                padding: theme.spacing(0.5, 0),
                                textDecoration: "underline"
                            },
                            loaderSx)}
                    onClick={
                        () => {
                            setLoadedItems(
                                _.concat(
                                    loadedItems,
                                    _(items).
                                        drop(loadedItems.length).
                                        take(itemPageSize).
                                        value()));
                        }}>
                    {localization.actions.load()}
                </Typography>}
        </Fragment>);
}