import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingInternetGatewayStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingInternetGatewayStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingInternetGatewayStatus]: {
                    [Contract.OciNetworkingInternetGatewayStatus.Available]: "Available",
                    [Contract.OciNetworkingInternetGatewayStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingInternetGatewayStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingInternetGatewayStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciNetworkingInternetGatewayStatus) =>
        localization[Contract.TypeNames.OciNetworkingInternetGatewayStatus][status]();
}