import { Function0 } from "lodash";
import React, { useCallback } from "react";
import { useLocalization } from "@infrastructure";
import { WidgetDefinition } from "../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { RiskCombinations } from "../components";

export function useGetRiskCombinationsDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetRiskCombinationsDefinition",
            () => ({
                helpText: "View resources with multiple risks that, when combined, are both hard-to-spot and pose a greater threat to your organization. Hover over the icons to see all categories that contribute to the risk.",
                title: "Toxic Combinations"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <RiskCombinations riskCombinationToDataMap={summary.riskCombinationToDataMap}/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}