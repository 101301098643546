import { CopyToClipboardActionButton, CopyToClipboardTextField, PasswordTextField, Steps, useLocalization } from "@infrastructure";
import { InputAdornment, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ScopeHelper, useTheme } from "../../../../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../../AddOrEdit";

export function RunTaskItem() {
    const { apiKeyToken, terraformCloudScanApiUrl } = useAddOrEditContext();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useTerraformCloudRunTask.runTaskItem",
            () => ({
                description: "Follow these steps to create a new Run Task in Terraform Cloud:",
                steps: {
                    step1: "**Sign in** to Terraform Cloud with a user that has permissions to manage run tasks.",
                    step2: "Open the **Settings** menu.",
                    step3: "Select **Run Tasks**.",
                    step4: "Click on **Create run task**.",
                    step5: {
                        step1: "Select the **Enabled** box.",
                        step2: "Fill in a **Name** (for example, **Tenable Cloud Security**).",
                        step3: {
                            description: "**Endpoint URL** - copy and paste the following value:",
                            label: "Endpoint URL",
                            url: "{{terraformCloudScanApiUrl}}?apiToken={{apiKeyToken}}"
                        },
                        step4: "Fill in a **Description** that makes sense to you (Optional).",
                        step5: {
                            description: "**HMAC Key** - copy and paste the following value:",
                            label: "HMAC Key"
                        },
                        step6: "Click on **Create run task**.",
                        title: "To create the run task, follow these steps:"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: theme.spacing(58) }}>
            <Typography>
                {localization.description()}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1()}
                {localization.steps.step2()}
                {localization.steps.step3()}
                {localization.steps.step4()}
                <Stack>
                    <Typography>
                        {localization.steps.step5.title()}
                    </Typography>
                    <Stack
                        spacing={1}
                        sx={{ paddingLeft: theme.spacing(1) }}>
                        <Steps variant="plainNumbers">
                            {localization.steps.step5.step1()}
                            {localization.steps.step5.step2()}
                            {localization.steps.step5.step3.description()}
                        </Steps>
                        <CopyToClipboardTextField
                            title={localization.steps.step5.step3.label()}
                            value={
                                localization.steps.step5.step3.url({
                                    apiKeyToken,
                                    terraformCloudScanApiUrl
                                })}/>
                        <Steps
                            startStepCount={4}
                            variant="plainNumbers">
                            {localization.steps.step5.step4()}
                            {localization.steps.step5.step5.description()}
                        </Steps>
                        <PasswordTextField
                            fullWidth={true}
                            label={localization.steps.step5.step5.label()}
                            password={ScopeHelper.customerId}
                            slotProps={{
                                input: {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <CopyToClipboardActionButton getValue={() => ScopeHelper.customerId}/>
                                        </InputAdornment>,
                                    readOnly: true
                                }
                            }}
                            variant="outlined"/>
                        <Steps
                            startStepCount={6}
                            variant="plainNumbers">
                            {_<string>([]).
                                concat(localization.steps.step5.step6()).
                                value()}
                        </Steps>
                    </Stack>
                </Stack>
            </Steps>
        </Stack>);
}