import { MemoItemRenderer, Optional, PagedDropdownPage } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, Entity, EntityController, entityModelStore, PagedSelector } from "..";

type PagedEntityPropertyValueSelectorProps = {
    disabled?: boolean;
    entityTypeName?: string;
    identifier: Contract.EntityPropertyIdentifier;
    multiSelect?: boolean;
    onSelectedValuesChanged: (property: string[]) => void;
    scopeId: string;
    selectedValues: string[];
    tenantTypes?: Contract.TenantType[];
};

export function PagedEntityPropertyValueSelector({ disabled = false, entityTypeName, identifier, multiSelect = true, onSelectedValuesChanged, scopeId, selectedValues, tenantTypes }: PagedEntityPropertyValueSelectorProps) {
    const identifierPrincipalReferenceValueType = identifier.valueType === Contract.EntityPropertyValueType.PrincipalReference;
    return (
        <PagedSelector
            disabled={disabled}
            fieldOptions={{
                dense: true,
                selection:
                    multiSelect || _.isNil(selectedValues[0])
                        ? undefined
                        : identifierPrincipalReferenceValueType
                            ? <Entity
                                entityIdOrModel={selectedValues[0]}
                                linkOptions={{
                                    disabled: true,
                                    disabledHighlight: false
                                }}
                                variant="text"/>
                            : selectedValues[0]
            }}
            getItemPage={
                async (searchText: Optional<string>, skip: number, data?: any) => {
                    const limit = 15;
                    const { entityModelFilterItemPage } =
                        await EntityController.getEntityModelFilterItemPage(
                            new Contract.EntityControllerGetEntityModelPropertyFilterItemPageRequest(
                                entityTypeName,
                                limit,
                                Contract.EntityModelProperty.EntityProperties,
                                scopeId,
                                searchText,
                                skip,
                                tenantTypes,
                                identifier)) as Contract.EntityControllerGetEntityModelFilterItemPageResponse<any>;

                    if (identifierPrincipalReferenceValueType) {
                        await entityModelStore.notify(entityModelFilterItemPage.items);
                    }

                    const count = entityModelFilterItemPage.count ?? data!;
                    return new PagedDropdownPage(
                        skip + limit < count,
                        entityModelFilterItemPage.items,
                        () => count);
                }}
            multiSelect={multiSelect}
            placeholder={identifier.name}
            selectedItems={selectedValues}
            onSelectedItemsChanged={onSelectedValuesChanged}>
            {(value: string) =>
                identifierPrincipalReferenceValueType
                    ? <MemoItemRenderer
                        render={
                            entityIdReference =>
                                <Entity
                                    entityIdOrModel={entityIdReference}
                                    linkOptions={{ disabled: true }}
                                    variant="iconTextTypeTenant"/>}
                        renderArguments={[value]}/>
                    : <Typography noWrap={true}>
                        {value}
                    </Typography>}
        </PagedSelector>);
}