import { useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetWorkloadAnalysisRiskContext } from "./useGetWorkloadAnalysisRiskContext";

export function useGetContainerImageRepositoryWorkloadAnalysisRiskContext() {
    return useMemo(
        () => useContainerImageRepositoryWorkloadAnalysisRiskContext,
        []);
}

function useContainerImageRepositoryWorkloadAnalysisRiskContext(risk: Contract.ContainerImageRepositoryWorkloadAnalysisRisk, vulnerabilitySeverityToRawIdsMap: Dictionary<string[]>) {
    const workloadAnalysisRiskContext = useGetWorkloadAnalysisRiskContext()(risk, vulnerabilitySeverityToRawIdsMap);
    const virtualMachineModels = _.as<Contract.IVirtualMachineModel[]>(entityModelStore.useGet(risk.virtualMachineIds));

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.contexts.useGetContainerImageRepositoryWorkloadAnalysisRiskContext",
            () => ({
                virtualMachines: "Images from this repository are being used by {{virtualMachines}}",
                vulnerabilities: "The latest tagged image in this repository has {{vulnerabilities}}{{resolvableVulnerabilitiesSuffix}}"
            }));

    const operatingSystemType =
        _(virtualMachineModels).
            filter(virtualMachineModel => !_.isNil(virtualMachineModel.operatingSystemType)).
            first()?.
            operatingSystemType;

    return {
        ...workloadAnalysisRiskContext,
        getVulnerabilities: () => {
            const vulnerabilitiesProps = workloadAnalysisRiskContext.getVulnerabilitiesProps(operatingSystemType);

            return _.isNil(vulnerabilitiesProps)
                ? undefined
                : new RiskDefinitionContextItem(localization.vulnerabilities({ ...vulnerabilitiesProps }));
        },
        virtualMachines:
            _.isEmpty(risk.virtualMachineIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.virtualMachines({
                        virtualMachines:
                            <InlineEntities
                                entityIdsOrModels={risk.virtualMachineIds}
                                entityTypeName={Contract.TypeNames.IVirtualMachine}
                                variant="itemCountAndType"/>
                    }))
    };
}