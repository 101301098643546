import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem, useTenantNameTranslator } from "../../../../../../../../../../common";
import { useCommonEntityInfoItemElements } from "../useCommonEntityInfoItemElements";

export function useGciCommonEntityInfoItemElements(entityModel: Contract.GciEntityModel) {
    const commonEntityInfoItemElements = useCommonEntityInfoItemElements(entityModel);

    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciCommonEntityInfoItemElements",
            () => ({
                syncTime: "Sync Time",
                tenant: "Workspace"
            }));
    return {
        ...commonEntityInfoItemElements,
        syncTimeInfoItemElement:
            <InfoItem
                key="syncTime"
                title={localization.syncTime()}
                value={TimeFormatter.profileFormatDateTime(entityModel.entity.syncTime)}/>,
        tenantInfoItemElement:
            <InfoItem
                key="tenant"
                title={localization.tenant()}
                value={tenantNameTranslator(entityModel.entity.tenantId, { includeRawId: true })}/>
    };
}