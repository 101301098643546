import { AnalyticsContext, AnalyticsEventActionType, Link, makeOperationContextProvider, useLocalization, useTrackAnalyticsEvent, WelcomeView } from "@infrastructure";
import _ from "lodash";
import React, { memo, useMemo } from "react";
import { AnalyzingView, Contract, CustomerConsoleAppUrlHelper, EntityController, TenantHelper, tenantModelStore, useApplicationViewRoute, useGetSelectedScopeHasData, useLayoutOptions, useTenantTypeTranslator } from "../../../../common";
import { CustomerView } from "../../hooks";
import { View } from "./components";

export const [useEntitiesOperationContext, , useEntitiesOperationContextProvider] = makeOperationContextProvider<Contract.EntityControllerGetSummaryResponse>();

export function Entities() {
    const [{ view: tenantType }] =
        useApplicationViewRoute(
            CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.Entities),
            _.values(Contract.TenantType),
            Contract.TenantType.Aws);
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities",
            () => ({
                title: "{{translatedTenantType}}"
            }));
    useLayoutOptions({ view: { title: localization.title({ translatedTenantType: tenantTypeTranslator(tenantType) }) } });

    return (
        <AnalyticsContext context={tenantType}>
            <CoreMemo
                key={tenantType}
                tenantType={tenantType}/>
        </AnalyticsContext>);
}

type CoreProps = {
    tenantType: Contract.TenantType;
};

const CoreMemo = memo(Core);

function Core({ tenantType }: CoreProps) {
    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants(TenantHelper.EntitiesTenantTypes);
    const filteredActiveTenantTypes = tenantModelStore.useGetFilteredActiveTenantTypes();
    const getSelectedScopeHasData = useGetSelectedScopeHasData();
    const tenantTypeActiveTenantModels =
        useMemo(
            () =>
                _.filter(
                    activeTenantModels,
                    activeTenantModel =>
                        activeTenantModel.tenantType === tenantType &&
                        _.includes(filteredActiveTenantModels, activeTenantModel)),
            [tenantType]);

    const [{ entityTypeEntitiesViewNameToCountMap }, ContextProvider] =
        useEntitiesOperationContextProvider(
            [Entities, tenantType],
            () => EntityController.getSummary(new Contract.EntityControllerGetSummaryRequest(tenantType)));
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.core",
            () => ({
                empty: {
                    text: "Your account(s) are empty",
                    [Contract.TypeNames.TenantType]: {
                        [Contract.TenantType.Op]: {
                            link: "Clusters",
                            title: "You haven't connected any on-premises clusters yet. To connect a cluster, go to {{systemKubernetesClustersPageLink}}."
                        }
                    }
                },
                filtered: "{{translatedTenantType}} is currently filtered out"
            }));

    if (!_.includes(filteredActiveTenantTypes, tenantType)) {
        return <WelcomeView title={localization.filtered({ translatedTenantType: tenantTypeTranslator(tenantType) })}/>;
    } else if (!getSelectedScopeHasData(tenantTypeActiveTenantModels)) {
        return <AnalyzingView/>;
    } else if (_.isEmpty(entityTypeEntitiesViewNameToCountMap)) {
        return (
            <WelcomeView
                title={
                    tenantType === Contract.TenantType.Op
                        ? localization.empty[Contract.TypeNames.TenantType][Contract.TenantType.Op].title({
                            systemKubernetesClustersPageLink:
                                <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getSystemKubernetesClustersRelativeUrl()}>
                                    {localization.empty[Contract.TypeNames.TenantType][Contract.TenantType.Op].link()}
                                </Link>
                        })
                        : localization.empty.text()}/>);
    } else {
        return (
            <ContextProvider>
                <View/>
            </ContextProvider>);
    }
}