import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, riskPolicyModelStore, RiskType, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { ExcludeDialog } from "../../../../../components";
import { useRiskController } from "../../../../useRiskController";

type ExcludeEntityDialogProps = {
    entityModel: Contract.EntityModel;
    onClose: (canceled?: boolean) => void;
    reloadRiskModel: (status?: Contract.RiskStatus) => Promise<void>;
    riskId: string;
    riskPolicyConfiguration: Contract.RiskPolicyConfiguration;
    riskType: RiskType;
};

export function ExcludeEntityDialog({ entityModel, onClose, reloadRiskModel, riskId, riskPolicyConfiguration, riskType }: ExcludeEntityDialogProps) {
    const { excludeEntity } = useRiskController(riskType);
    async function excludeEntityAsync(reason: Contract.RiskPolicyConfigurationExclusionDataReason, expirationDate?: string, message?: string) {
        await excludeEntity?.(entityModel.id, expirationDate, reason, message, riskId);

        onClose();
        await reloadRiskModel();
        await riskPolicyModelStore.notify(riskPolicyConfiguration.id);
    }

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.excludeEntityDialog",
            () => ({
                action: "Exclude",
                subtitle: "Exclude the resource from the policy. When all resources are excluded, change the status of the finding to Ignored. You can manage resource exclusions from the Policies page.",
                title: "Exclude {{translatedEntityTypeName}} **{{entity}}**"
            }));

    return (
        <ExcludeDialog
            action={localization.action()}
            description={localization.subtitle()}
            executeAsync={excludeEntityAsync}
            title={
                localization.title({
                    entity: entityModel.entity.displayName,
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityModel.entity.typeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                })}
            onClose={onClose}/>);
}