import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, UrlHelper } from "../../../../../../../../../common";

export function useSplunkEventCollectorAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useSplunkEventCollectorAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                splunkEventCollectorEndpointConnectorName: "Endpoint connector",
                splunkEventCollectorName: "Name",
                splunkEventCollectorServerCertificateValidationEnabled: {
                    false: "Yes",
                    title: "Accept any server SSL certificates",
                    true: "No"
                },
                splunkEventCollectorToken: "Token",
                splunkEventCollectorUrl: "Event Collector URL"
            }));

    const splunkEventCollectorAuditEvent = context.auditEventModel.auditEvent as Contract.SplunkEventCollectorAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concatIf(
                !_.isNil(splunkEventCollectorAuditEvent.splunkEventCollectorUrl),
                new AuditEventDefinitionDetailItem(
                    localization.splunkEventCollectorUrl(),
                    splunkEventCollectorAuditEvent.splunkEventCollectorUrl)).
            concatIf(
                !_.isNil(splunkEventCollectorAuditEvent.splunkEventCollectorEndpointConnectorName),
                new AuditEventDefinitionDetailItem(
                    localization.splunkEventCollectorEndpointConnectorName(),
                    splunkEventCollectorAuditEvent.splunkEventCollectorEndpointConnectorName!)).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.splunkEventCollectorName(),
                    splunkEventCollectorAuditEvent.splunkEventCollectorName)).
            concatIf(
                !_.isNil(splunkEventCollectorAuditEvent.splunkEventCollectorUrl) &&
                UrlHelper.getUrlData(splunkEventCollectorAuditEvent.splunkEventCollectorUrl)?.protocolType === "https",
                new AuditEventDefinitionDetailItem(
                    localization.splunkEventCollectorServerCertificateValidationEnabled.title(),
                    splunkEventCollectorAuditEvent.splunkEventCollectorServerCertificateValidationEnabled
                        ? localization.splunkEventCollectorServerCertificateValidationEnabled.true()
                        : localization.splunkEventCollectorServerCertificateValidationEnabled.false())
            ).
            concatIf(
                splunkEventCollectorAuditEvent.typeName === Contract.TypeNames.SplunkEventCollectorUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (splunkEventCollectorAuditEvent as Contract.SplunkEventCollectorUpdateAuditEvent).splunkEventCollectorNameChanged
                                ? localization.splunkEventCollectorName()
                                : undefined,
                            (splunkEventCollectorAuditEvent as Contract.SplunkEventCollectorUpdateAuditEvent).splunkEventCollectorTokenChanged
                                ? localization.splunkEventCollectorToken()
                                : undefined,
                            (splunkEventCollectorAuditEvent as Contract.SplunkEventCollectorUpdateAuditEvent).splunkEventCollectorServerCertificateValidationEnabledChanged
                                ? localization.splunkEventCollectorServerCertificateValidationEnabled.title()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}