﻿import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, map, Message, StringHelper, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, Entity, EntityFilter, entityModelStore, ItemTable, useTheme } from "../../../common";
import { ProfileCategory } from "../../../views/Customer/components/Entities/components/Profile/hooks";
import { useGcpIamRoleBindingExpiredTranslator, useGcpIamRoleBindingPermissionUsageTypeTranslator } from "../hooks";
import { GcpRoleBindingHelper } from "../utilities";
import { GciPrincipalReferenceCell } from "./GciPrincipalReferenceCell";

export type GcpRoleBindingTableProps = {
    csvExportFilePrefixes: string[];
    initialFilterMap?: Dictionary<any>;
    principalOrResourceId?: string;
    roleBindingIds: string[];
    variant: "principalAndRoleAndScope" | "principalAndScope" | "roleAndScopeAndPrincipal";
};

export function GcpRoleBindingTable({ csvExportFilePrefixes, initialFilterMap, principalOrResourceId, roleBindingIds, variant }: GcpRoleBindingTableProps) {
    const [roleBindingModels, unknownRoleBindingModels] =
        _.partition(
            entityModelStore.useGet(roleBindingIds),
            entityModel => !entityModel.unknown);
    const roleBindingRelatedEntityModels =
        entityModelStore.useGet(
            _(roleBindingModels).
                map(roleBindingModel => roleBindingModel.entity as Contract.GcpIamRoleBinding).
                flatMap(
                    roleBinding => [
                        roleBinding.roleId,
                        roleBinding.principalReference.id,
                        roleBinding.scopeResourceReference.idReference
                    ]).
                filter().
                as<string>().
                value());

    const roleBindingExpiredTranslator = useGcpIamRoleBindingExpiredTranslator();
    const roleBindingPermissionUsageTypeTranslator = useGcpIamRoleBindingPermissionUsageTypeTranslator();
    const localization =
        useLocalization(
            "tenants.gcp.gcpRoleBindingTable",
            () => ({
                columns: {
                    containingRoleBindingExists: {
                        false: "-",
                        helpText: "This role binding is \"covered\" by another role binding at a higher scope level or one that is assigned via group membership.",
                        title: "Covered Role Binding",
                        true: "Yes"
                    },
                    expired: "Status",
                    permissionUsageType: {
                        helpText: "'Unknown' indicates that Tenable Cloud Security hasn't collected enough activity history to know with certainty whether the binding was active in the last 90 days.",
                        title: "Usage"
                    },
                    principalReference: {
                        this: "This Principal",
                        title: "Principal"
                    },
                    roleId: "Role",
                    scopeResourceReference: {
                        this: "This Resource",
                        title: "Scope"
                    }
                },
                empty: "No Bindings",
                unknownRoleBindingExists: {
                    helpText: "Since the current user is missing permissions on some of the projects, some role bindings from those projects might not be displayed",
                    title: "Some role bindings might not be displayed"
                }
            }));

    const [items, roleBindingRelatedEntityModelMap] =
        useMemo(
            () => {
                const roleBindingRelatedEntityModelMap =
                    _.keyBy(
                        roleBindingRelatedEntityModels,
                        roleBindingRelatedEntityModel => roleBindingRelatedEntityModel.id);
                const items =
                    _(roleBindingModels as Contract.GcpIamRoleBindingModel[]).
                        map(
                            roleBindingModel =>
                                ({
                                    roleBinding: roleBindingModel.entity as Contract.GcpIamRoleBinding,
                                    roleBindingModel
                                })).
                        as<GcpRoleBindingTableItem>().
                        value();

                return [items, roleBindingRelatedEntityModelMap];
            },
            [roleBindingModels, roleBindingRelatedEntityModels]);

    const theme = useTheme();
    return (
        <Stack sx={{ overflow: "hidden" }}>
            {!_.isEmpty(unknownRoleBindingModels) &&
                <Stack
                    direction="row"
                    spacing={1}>
                    <Message
                        level="info"
                        title={localization.unknownRoleBindingExists.helpText()}
                        variant="minimal"/>
                    <Typography sx={{ color: theme.palette.error.main }}>
                        {localization.unknownRoleBindingExists.title()}
                    </Typography>
                </Stack>}
            <ItemTable
                columnIdToGetItemValueMap={{
                    [GcpRoleBindingTableColumnId.Expired]: {
                        getFilterValue: item => roleBindingExpiredTranslator(item.roleBinding.expired),
                        getSortValue: item => item.roleBinding.expired
                    },
                    [GcpRoleBindingTableColumnId.ContainingRoleBindingExists]: {
                        getFilterValue: item =>
                            item.roleBindingModel.containingRoleBindingExists
                                ? localization.columns.containingRoleBindingExists.true()
                                : localization.columns.containingRoleBindingExists.false(),
                        getSortValue: item => item.roleBindingModel.containingRoleBindingExists
                    },
                    [GcpRoleBindingTableColumnId.PrincipalReference]: {
                        getFilterValue: item => item.roleBinding.principalReference.id ?? item.roleBinding.principalReference.rawId,
                        getSortValue:
                            item =>
                                StringHelper.getCombineSortValue(
                                    GcpRoleBindingHelper.getPrincipalTypeSortValue(item.roleBinding.principalReference.type),
                                    StringHelper.getSortValue(
                                        _.isNil(item.roleBinding.principalReference.id)
                                            ? item.roleBinding.principalReference.rawId
                                            : item.roleBinding.principalReference.id === principalOrResourceId
                                                ? ""
                                                : roleBindingRelatedEntityModelMap[item.roleBinding.principalReference.id].entity.displayName))
                    },
                    [GcpRoleBindingTableColumnId.RoleId]: {
                        getFilterValue: item => item.roleBinding.roleId,
                        getSortValue: item => roleBindingRelatedEntityModelMap[item.roleBinding.roleId].entity.displayName
                    },
                    [GcpRoleBindingTableColumnId.ScopeResourceReference]: {
                        getFilterValue: item => item.roleBinding.scopeResourceReference.idReference,
                        getSortValue:
                            item =>
                                item.roleBinding.scopeResourceReference.idReference === principalOrResourceId
                                    ? ""
                                    : roleBindingRelatedEntityModelMap[item.roleBinding.scopeResourceReference.idReference].entity.displayName
                    },
                    [GcpRoleBindingTableColumnId.PermissionUsageType]: item => {
                        const permissionUsageType = item.roleBindingModel.permissionUsageType;
                        return _.isNil(permissionUsageType)
                            ? "-"
                            : roleBindingPermissionUsageTypeTranslator(permissionUsageType);
                    }
                }}
                csvExportFilePrefixes={csvExportFilePrefixes}
                defaultSortColumnIdOrIds={
                    variant === "roleAndScopeAndPrincipal"
                        ? GcpRoleBindingTableColumnId.RoleId
                        : GcpRoleBindingTableColumnId.PrincipalReference}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                filterOptions={{
                    initialState: initialFilterMap
                }}
                getCsvItem={
                    item => {
                        const principal =
                            _.isNil(item.roleBinding.principalReference.id)
                                ? item.roleBinding.principalReference.rawId
                                : roleBindingRelatedEntityModelMap[item.roleBinding.principalReference.id].entity.displayReference;
                        const containingRoleBindingExists =
                            item.roleBindingModel.containingRoleBindingExists
                                ? localization.columns.containingRoleBindingExists.true()
                                : localization.columns.containingRoleBindingExists.false();
                        const expired = roleBindingExpiredTranslator(item.roleBinding.expired);
                        const permissionUsageType = item.roleBindingModel.permissionUsageType;
                        const permissionUsage =
                            _.isNil(permissionUsageType)
                                ? "-"
                                : roleBindingPermissionUsageTypeTranslator(permissionUsageType);
                        return map(
                            variant,
                            {
                                "principalAndRoleAndScope": () => ({
                                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                                    "Principal": principal,
                                    "Role": roleBindingRelatedEntityModelMap[item.roleBinding.roleId].entity.displayReference,
                                    "Scope": roleBindingRelatedEntityModelMap[item.roleBinding.scopeResourceReference.idReference].entity.displayReference,
                                    "Status": expired,
                                    "Usage": permissionUsage,
                                    "Covered Role Binding": containingRoleBindingExists
                                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                                }),
                                "principalAndScope": () => ({
                                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                                    "Principal": principal,
                                    "Scope": roleBindingRelatedEntityModelMap[item.roleBinding.scopeResourceReference.idReference].entity.displayReference,
                                    "Status": expired,
                                    "Usage": permissionUsage,
                                    "Covered Role Binding": containingRoleBindingExists
                                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                                }),
                                "roleAndScopeAndPrincipal": () => ({
                                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                                    "Role": roleBindingRelatedEntityModelMap[item.roleBinding.roleId].entity.displayReference,
                                    "Scope": roleBindingRelatedEntityModelMap[item.roleBinding.scopeResourceReference.idReference].entity.displayReference,
                                    "Principal": principal,
                                    "Status": expired,
                                    "Usage": permissionUsage,
                                    "Covered Role Binding": containingRoleBindingExists
                                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                                })
                            });
                    }}
                getItemId={item => item.roleBinding.id}
                items={items}
                rowOptions={{
                    getUrl: (item: GcpRoleBindingTableItem) => CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(item.roleBindingModel, { category: ProfileCategory.Overview })!
                }}>
                {columnIdToItemValuesMap => {
                    const containingRoleBindingsElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValueFilter
                                            items={[
                                                {
                                                    value: localization.columns.containingRoleBindingExists.true()
                                                }
                                            ]}
                                            placeholder={localization.columns.containingRoleBindingExists.title()}/>
                                }
                            }}
                            id={GcpRoleBindingTableColumnId.ContainingRoleBindingExists}
                            itemProperty={
                                (item: GcpRoleBindingTableItem) =>
                                    item.roleBindingModel.containingRoleBindingExists
                                        ? localization.columns.containingRoleBindingExists.true()
                                        : localization.columns.containingRoleBindingExists.false()}
                            key={GcpRoleBindingTableColumnId.ContainingRoleBindingExists}
                            message={localization.columns.containingRoleBindingExists.helpText()}
                            messageLevel="info"
                            title={localization.columns.containingRoleBindingExists.title()}/>;
                    const expiredColumnElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValuesFilter placeholder={localization.columns.expired()}>
                                            <ValuesFilterItem
                                                title={roleBindingExpiredTranslator(false)}
                                                value={roleBindingExpiredTranslator(false)}/>
                                            <ValuesFilterItem
                                                title={roleBindingExpiredTranslator(true)}
                                                value={roleBindingExpiredTranslator(true)}/>
                                        </ValuesFilter>
                                }
                            }}
                            id={GcpRoleBindingTableColumnId.Expired}
                            itemProperty={(item: GcpRoleBindingTableItem) => roleBindingExpiredTranslator(item.roleBinding.expired)}
                            key={GcpRoleBindingTableColumnId.Expired}
                            title={localization.columns.expired()}/>;
                    const permissionUsageTypeColumnElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValuesFilter placeholder={localization.columns.permissionUsageType.title()}>
                                            {_.map(
                                                columnIdToItemValuesMap[GcpRoleBindingTableColumnId.PermissionUsageType],
                                                permissionUsageType =>
                                                    <ValuesFilterItem
                                                        key={permissionUsageType}
                                                        value={permissionUsageType}/>)}
                                        </ValuesFilter>
                                }
                            }}
                            id={GcpRoleBindingTableColumnId.PermissionUsageType}
                            itemProperty={
                                (item: GcpRoleBindingTableItem) => {
                                    const permissionUsageType = item.roleBindingModel.permissionUsageType;
                                    return _.isNil(permissionUsageType)
                                        ? "-"
                                        : roleBindingPermissionUsageTypeTranslator(permissionUsageType);
                                }}
                            key={GcpRoleBindingTableColumnId.PermissionUsageType}
                            message={localization.columns.permissionUsageType.helpText()}
                            messageLevel="info"
                            title={localization.columns.permissionUsageType.title()}/>;
                    const principalColumnElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValuesFilter placeholder={localization.columns.principalReference.title()}>
                                            {_.map(
                                                columnIdToItemValuesMap[GcpRoleBindingTableColumnId.PrincipalReference],
                                                principalReferenceValue =>
                                                    <ValuesFilterItem
                                                        key={principalReferenceValue}
                                                        title={roleBindingRelatedEntityModelMap[principalReferenceValue]?.entity.displayName ?? principalReferenceValue}
                                                        value={principalReferenceValue}>
                                                        {() =>
                                                            _.isNil(roleBindingRelatedEntityModelMap[principalReferenceValue])
                                                                ? <Typography
                                                                    noWrap={true}>{principalReferenceValue}</Typography>
                                                                : <Entity
                                                                    entityIdOrModel={principalReferenceValue}
                                                                    linkOptions={{ disabled: true }}
                                                                    variant="iconTextTypeTenant"/>}
                                                    </ValuesFilterItem>)}
                                        </ValuesFilter>
                                }
                            }}
                            id={GcpRoleBindingTableColumnId.PrincipalReference}
                            key={GcpRoleBindingTableColumnId.PrincipalReference}
                            render={
                                ({ item }: DataTableColumnRenderProps<GcpRoleBindingTableItem>) =>
                                    item.roleBinding.principalReference.id === principalOrResourceId
                                        ? <Typography noWrap={true}>{localization.columns.principalReference.this()}</Typography>
                                        : <GciPrincipalReferenceCell
                                            principalReference={item.roleBinding.principalReference}/>}
                            title={localization.columns.principalReference.title()}/>;
                    const roleColumnElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <EntityFilter
                                            entityIdsOrSearchableReferences={columnIdToItemValuesMap[GcpRoleBindingTableColumnId.RoleId]}
                                            placeholder={localization.columns.roleId()}/>
                                }
                            }}
                            id={GcpRoleBindingTableColumnId.RoleId}
                            key={GcpRoleBindingTableColumnId.RoleId}
                            render={
                                ({ item }: DataTableColumnRenderProps<GcpRoleBindingTableItem>) =>
                                    <EntitiesCell
                                        entityIdsOrModels={item.roleBinding.roleId}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        entityVariant="iconTextTypeTenant"/>}
                            title={localization.columns.roleId()}/>;
                    const scopeResourceColumnElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <EntityFilter
                                            entityIdsOrSearchableReferences={columnIdToItemValuesMap[GcpRoleBindingTableColumnId.ScopeResourceReference]}
                                            placeholder={localization.columns.scopeResourceReference.title()}/>
                                }
                            }}
                            id={GcpRoleBindingTableColumnId.ScopeResourceReference}
                            key={GcpRoleBindingTableColumnId.ScopeResourceReference}
                            render={
                                ({ item }: DataTableColumnRenderProps<GcpRoleBindingTableItem>) =>
                                    item.roleBinding.scopeResourceReference.idReference === principalOrResourceId
                                        ? <Typography noWrap={true}>
                                            {localization.columns.scopeResourceReference.this()}
                                        </Typography>
                                        : <EntitiesCell
                                            entityIdsOrModels={item.roleBinding.scopeResourceReference.idReference}
                                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                                            entityVariant="iconTextTypeTenant"/>}
                            title={localization.columns.scopeResourceReference.title()}/>;
                    return map(
                        variant,
                        {
                            "principalAndRoleAndScope": () => [
                                principalColumnElement,
                                roleColumnElement,
                                scopeResourceColumnElement,
                                expiredColumnElement,
                                permissionUsageTypeColumnElement,
                                containingRoleBindingsElement
                            ],
                            "principalAndScope": () => [
                                principalColumnElement,
                                scopeResourceColumnElement,
                                expiredColumnElement,
                                permissionUsageTypeColumnElement,
                                containingRoleBindingsElement
                            ],
                            "roleAndScopeAndPrincipal": () => [
                                roleColumnElement,
                                scopeResourceColumnElement,
                                principalColumnElement,
                                expiredColumnElement,
                                permissionUsageTypeColumnElement,
                                containingRoleBindingsElement
                            ]
                        });
                }}
            </ItemTable>
        </Stack>);
}

export enum GcpRoleBindingTableColumnId {
    ContainingRoleBindingExists = "containingRoleBindingIds",
    Expired = "expired",
    PermissionUsageType = "permissionUsageType",
    PrincipalReference = "principalReference",
    RoleId = "roleId",
    ScopeResourceReference = "scopeResourceReference"
}

type GcpRoleBindingTableItem = {
    roleBinding: Contract.GcpIamRoleBinding;
    roleBindingModel: Contract.GcpIamRoleBindingModel;
};