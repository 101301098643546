import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsSecretsManagerSecretRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsSecretsManagerSecretRotationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSecretsManagerSecretRotationDisabledRisk;
    const secretsManagerSecretRotationDisabledRiskModel = riskModel as Contract.AwsSecretsManagerSecretRotationDisabledRiskModel;
    const secretModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSecretsManagerSecretModel;
    const secret = secretModel.entity as Contract.AwsSecretsManagerSecret;

    const getAwsSecretsManagerSecretRiskContext = useGetAwsSecretsManagerSecretRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSecretsManagerSecretRotationDisabledRiskDefinition",
            () => ({
                description: "{{secret}} automatic rotation is not enabled",
                sections: {
                    resolution: {
                        step1: "Scroll down to the **Rotation configuration** section and click on **Edit rotation**",
                        step2: {
                            link: "AWS documentation",
                            text: "Enable the **Automatic rotation** toggle, enter rotation interval and rotation lambda function and click **Save**.\nFor more information about automatic secret rotation, go to the {{awsDocumentationLink}}"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            secret:
                <Entity
                    entityIdOrModel={secretModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.SecretsManager,
                AwsConsoleUrlBuilder.getSecretsManagerSecretUrl(secret)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                awsDocumentationLink:
                    <Link
                        urlOrGetUrl={secretsManagerSecretRotationDisabledRiskModel.secretRotationDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const secretRiskContext = getAwsSecretsManagerSecretRiskContext(secretModel);

            return [
                secretRiskContext.generalInformation,
                secretRiskContext.rotated,
                secretRiskContext.rotationTime,
                secretRiskContext.usage,
                secretRiskContext.sensitive,
                secretRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}