﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem, TenantEntityParentPathCell } from "../../../../../../../../../../common";
import { useAzureTenantEntityStatusTranslator } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureTenantEntityDefinition(resourceModel: Contract.AzureResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceModel);
    const tenantEntityModel = resourceModel as Contract.AzureTenantEntityModel;
    const tenantEntity = tenantEntityModel.entity as Contract.AzureTenantEntity;

    const azureTenantEntityStatusTranslator = useAzureTenantEntityStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureTenantEntityDefinition",
            () => ({
                info: {
                    cards: {
                        resourceGroupIds: "Resource Groups"
                    },
                    items: {
                        parentPath: "Path",
                        status: "Status"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition(
        {
            additionalTabs: [
                new EntityProfileDefinitionTab(
                    ProfileCategory.Iam,
                    <AzureResourceRoleAssignmentResources
                        csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                        scopeResourceModel={tenantEntityModel}/>,
                    localization.tabs.resourceRoleAssignments(),
                    "resourceRoleAssignments")
            ],
            infoElement:
                <Info
                    customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                    defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                    entityPropertyInfoItemElements={[
                        <InfoItem
                            key="status"
                            location="all"
                            title={localization.info.items.status()}
                            value={azureTenantEntityStatusTranslator(tenantEntity.status)}/>,
                        <InfoItem
                            key="parentPath"
                            title={localization.info.items.parentPath()}
                            value={
                                <TenantEntityParentPathCell
                                    parentEntityId={(tenantEntityModel.entity as Contract.AzureTenantEntity).parentEntityId}
                                    parentEntityPath={tenantEntityModel.parentEntityPath}/>}/>
                    ]}
                    options={options?.infoOptions}>
                    <EntitiesInfoCard
                        entityIdsOrModels={tenantEntityModel.resourceGroupIds}
                        entityTypeName={Contract.TypeNames.AzureResourcesResourceGroup}
                        title={localization.info.cards.resourceGroupIds()}/>
                </Info>
        });
}