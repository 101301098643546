import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAzureCreateRoleAssignmentResourceChangeDefinition(change: Contract.AzureChange, entityLinkDisabled?: boolean) {
    const createRoleAssignmentResourceChange = change as Contract.AzureCreateRoleAssignmentResourceChange;
    const roleAssignmentResourceScopeEntity = entityModelStore.useGet(createRoleAssignmentResourceChange.scopeEntityId).entity as Contract.AzureEntity;

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAzureDefinition.hooks.useAzureCreateRoleAssignmentResourceChangeDefinition",
            () => ({
                [Contract.TypeNames.AzureCreateRoleAssignmentResourceChange]: {
                    [Contract.TypeNames.AzureCreateRoleAssignmentChange]: "Add role assignment with {{roleAssignmentDefinition}} assigned to {{scopeEntity}}",
                    [Contract.TypeNames.AzureCreateRoleAssignmentScheduleChange]: "Add PIM active role assignment with {{roleAssignmentDefinition}} assigned to {{scopeEntity}}",
                    [Contract.TypeNames.AzureCreateRoleEligibilityScheduleChange]: "Add PIM eligible role assignment with {{roleAssignmentDefinition}} assigned to {{scopeEntity}}"
                }
            }));

    return {
        title:
            localization[Contract.TypeNames.AzureCreateRoleAssignmentResourceChange].translate(
                createRoleAssignmentResourceChange.typeName,
                {
                    roleAssignmentDefinition:
                        <Entity
                            entityIdOrModel={createRoleAssignmentResourceChange.resourceId}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: entityLinkDisabled }}
                            variant="typeText"/>,
                    scopeEntity:
                        <Entity
                            entityIdOrModel={roleAssignmentResourceScopeEntity.id}
                            entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: entityLinkDisabled }}
                            variant="typeText"/>
                })
    };
}