import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../controllers";
import { EntityDefinition } from "../useDefinition";

export function useUnmanagedKubernetesClusterDefinition(entityModel: Contract.EntityModel) {
    const localization =
        useLocalization(
            "common.entity.hooks.useDefinition.hooks.useUnmanagedKubernetesClusterDefinition",
            () => ({
                openShift: "Self-Managed OpenShift cluster"
            }));
    return new EntityDefinition(
        entityModel,
        {
            translatedEntityTypeName:
                _.as<Contract.IUnmanagedKubernetesCluster>(entityModel.entity).data.platform === Contract.KubernetesClusterPlatform.OpenShift
                    ? localization.openShift()
                    : undefined
        });
}