import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useCloudProviderTenantPermissionTypesTranslator } from "../../../../../../../../../common";

export function useAzureOrganizationAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const cloudProviderTenantPermissionTypesTranslator = useCloudProviderTenantPermissionTypesTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAzureOrganizationAuditEventDefinition",
            () => ({
                enabled: {
                    false: "Off",
                    title: "Onboard new subscriptions",
                    true: "On"
                },
                folderEnabled: {
                    false: "Off",
                    title: "Folder structure sync",
                    true: "On"
                },
                name: "Organization name",
                permissionTypes: "Permissions",
                propertyChanges: "Updated properties"
            }));

    const organizationAuditEvent = context.auditEventModel.auditEvent as Contract.AzureOrganizationAuditEvent;
    const auditEventData =
        organizationAuditEvent.typeName === Contract.TypeNames.AzureOrganizationCreationAuditEvent ||
        organizationAuditEvent.typeName === Contract.TypeNames.AzureOrganizationUpdateAuditEvent
            ? (organizationAuditEvent as Contract.AzureOrganizationCreationAuditEvent | Contract.AzureOrganizationUpdateAuditEvent).data
            : undefined;

    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.name(),
                    organizationAuditEvent.name)).
            concatIf(
                !_.isNil(auditEventData),
                () => {
                    const data = auditEventData!;
                    return [
                        new AuditEventDefinitionDetailItem(
                            localization.permissionTypes(),
                            _.join(
                                cloudProviderTenantPermissionTypesTranslator.multiple(
                                    data.permissionTypes,
                                    "shortName"),
                                ",")),
                        new AuditEventDefinitionDetailItem(
                            localization.enabled.title(),
                            data.enabled
                                ? localization.enabled.true()
                                : localization.enabled.false()),
                        new AuditEventDefinitionDetailItem(
                            localization.folderEnabled.title(),
                            data.folderEnabled
                                ? localization.folderEnabled.true()
                                : localization.folderEnabled.false())
                    ];
                }).
            concatIf(
                organizationAuditEvent.typeName === Contract.TypeNames.AzureOrganizationUpdateAuditEvent,
                () => {
                    const organizationUpdateAuditEvent = organizationAuditEvent as Contract.AzureOrganizationUpdateAuditEvent;
                    const properties =
                        _.filter([
                            organizationUpdateAuditEvent.enabledChanged
                                ? localization.enabled.title()
                                : undefined,
                            organizationUpdateAuditEvent.folderEnabledChanged
                                ? localization.folderEnabled.title()
                                : undefined,
                            organizationUpdateAuditEvent.nameChanged
                                ? localization.name()
                                : undefined,
                            organizationUpdateAuditEvent.permissionTypesChanged
                                ? localization.permissionTypes()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}