import _ from "lodash";
import { PagedValuesFilterSelection, useExecuteOperation } from "@infrastructure";
import { Contract, EntityController } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesNamespaceResourceDefinition } from "../useCommonKubernetesNamespaceResourceDefinition";
import { useAwsCommonKubernetesResourceDefinition } from "./useAwsCommonKubernetesResourceDefinition";

export function useAwsCommonKubernetesNamespaceResourceDefinition(definitionData: DefinitionData) {
    const [{ kubernetesSystemNamespaceIds }] =
        useExecuteOperation(
            useAwsCommonKubernetesNamespaceResourceDefinition,
            () => EntityController.getKubernetesSystemNamespaceIds(new Contract.EntityControllerGetKubernetesSystemNamespaceIdsRequest(definitionData.entityTypeEntitiesViewName)));

    const commonKubernetesNamespaceResourceDefinition = useCommonKubernetesNamespaceResourceDefinition(definitionData);
    const commonKubernetesResourceDefinition = useAwsCommonKubernetesResourceDefinition(definitionData);

    return {
        columns: {
            ...commonKubernetesResourceDefinition.columns,
            ...commonKubernetesNamespaceResourceDefinition.columns
        },
        initialFilterMap:
            _.isNil(kubernetesSystemNamespaceIds)
                ? undefined
                : {
                    [Contract.EntityModelProperty.KubernetesNamespaceResourceNamespace]:
                        new PagedValuesFilterSelection(
                            false,
                            "exclude",
                            kubernetesSystemNamespaceIds)
                }
    };
}