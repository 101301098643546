import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RedLine13Icon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <rect
                fill="black"
                height="24"
                rx="12"
                width="24"/>
            <path
                d="M18.9129 17.8561H18.9855L19 17.8706V17.9431L18.9274 17.9867L18.5502 18.0447L17.6651 18.1753L17.5054 18.1898L17.2878 18.2333L16.3881 18.3639L15.9093 18.4365L15.1112 18.5526L14.1681 18.6977L13.6602 18.7702L13.399 18.8138L12.8767 18.8863L12.2237 18.9879L11.7013 19.0604L11.3096 19.1185L10.0326 19.3071L8.91536 19.4667L8.11729 19.5973L7.91415 19.6263L7.87062 19.5828L7.66747 19.191L7.49335 18.8718L7.30472 18.5236L7.15961 18.2479L7 17.9286V17.8851L9.90206 17.8706H16.3011L18.9129 17.8561Z"
                fill="#CE2313"/>
            <path
                clipRule="evenodd"
                d="M12.63 5H7.78359L7.76908 5.01451L7.69653 5.63845L7.62398 6.21886L7.58044 6.59613L7.59495 6.65417L7.63849 6.66868L7.66751 6.72672L7.84163 6.88634L8.01575 7.01693L8.26243 7.14752L8.49459 7.2636L8.48008 14.4317L8.46557 14.4462L8.18988 14.5768L7.9432 14.7219L7.75457 14.867L7.59495 15.0411V15.2297L7.66751 15.8392L7.76908 16.5937L7.7981 16.6808L10.2068 16.6953H10.7002L12.543 16.6808L12.572 16.6518L12.6155 16.26L12.7316 15.2733L12.7461 15.0556L12.7316 15.0266L12.572 14.867L12.3833 14.7364L12.1367 14.6058L11.861 14.4897L11.4692 14.3736L11.2515 14.3301L11.2661 12.081H11.89L12.2092 12.0955L12.2818 12.1536L12.3979 12.3132L12.5139 12.5308L12.9783 13.5175L13.2249 14.0399L13.3991 14.4172L13.5151 14.6493L13.7183 15.0992L13.965 15.607L14.1826 16.0859L14.2842 16.289L14.4148 16.5647L14.4873 16.6808H17.5345L17.5635 16.5647L17.7376 15.2878V15.1572L17.6361 15.0411L17.5635 14.9686L17.3749 14.8089L17.1572 14.6784L16.7945 14.5042L16.6349 14.4462L16.5188 14.243L16.3011 13.8222L16.127 13.503L15.9529 13.1693L15.8078 12.8936L15.5611 12.4148L15.416 12.1536L15.2854 11.9794L15.1403 11.8343L14.9807 11.7037L14.7921 11.5732L14.7195 11.5151L14.8646 11.4426L15.1403 11.2829L15.4595 11.0798L15.6772 10.9057L15.8368 10.7751L16.0254 10.5865L16.127 10.4704L16.3011 10.2672L16.5043 9.96251L16.6349 9.68682L16.7509 9.33857L16.8235 8.97582L16.8525 8.61306V8.33736L16.8235 7.90206L16.7655 7.51028L16.6784 7.16203L16.5333 6.78476L16.4027 6.53809L16.2576 6.32044L16.098 6.1318L15.8803 5.92866L15.6627 5.76904L15.445 5.63845L15.2129 5.52237L14.8501 5.37727L14.6324 5.30472L14.1681 5.18863L13.6748 5.10157L13.1959 5.04353L12.63 5ZM12.2673 7.17656H11.2515V9.20799L11.266 9.88998H12.0786L12.4124 9.87547L12.6735 9.84645L12.9928 9.78841L13.2394 9.71585L13.4571 9.62879L13.6602 9.4982L13.7908 9.3531L13.9069 9.12093L13.965 8.90328L13.994 8.68562V8.39542L13.965 8.16325L13.9069 7.96011L13.8199 7.78599L13.7038 7.64088L13.5587 7.51029L13.312 7.3797L13.0508 7.29264L12.7026 7.22009L12.2673 7.17656Z"
                fill="#FEFEFE"
                fillRule="evenodd"/>
        </SvgIcon>);
}