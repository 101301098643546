import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { AzureAccessPrincipalRiskDefinition } from "../useDefinition";

export function useAadDirectoryUserDefinition(riskModel: Contract.AzureAccessPrincipalRiskModel) {
    const risk = riskModel.risk as Contract.AzureAccessPrincipalRisk;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.hooks.useDefinition.hooks.useAadDirectoryUserDefinition",
            () => ({
                disabled: "User is disabled"
            }));
    return new AzureAccessPrincipalRiskDefinition([
        risk.identityEnabled
            ? undefined
            : localization.disabled()
    ]);
}