import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InlineEntities } from "../../../../../../../../../../../../common";
import { Events } from "../../../../../../../../../Events";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsSsoPermissionSetManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSsoPermissionSetManagementRisk;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsSsoPermissionSetManagementRiskDefinition",
            () => ({
                events: [
                    "{{permissionSets}} was created or modified",
                    "{{permissionSets}} were created or modified"
                ]
            }));
    const translatedEvents =
        localization.events(
            risk.resourceIds.length,
            {
                permissionSets:
                    <InlineEntities
                        entityIdsOrModels={risk.resourceIds}
                        entityTypeName={Contract.TypeNames.AwsSsoPermissionSet}
                        variant="itemAndTypeOrItemCountAndType"/>
            });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedEvents,
        riskModel,
        "events",
        <Events riskId={risk.id}/>);
}