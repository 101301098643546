import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingNetworkSecurityGroupStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingNetworkSecurityGroupStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingNetworkSecurityGroupStatus]: {
                    [Contract.OciNetworkingNetworkSecurityGroupStatus.Available]: "Available",
                    [Contract.OciNetworkingNetworkSecurityGroupStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingNetworkSecurityGroupStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingNetworkSecurityGroupStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciNetworkingNetworkSecurityGroupStatus) =>
        localization[Contract.TypeNames.OciNetworkingNetworkSecurityGroupStatus][status]();
}