import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableSort, DataTableSortDirection, EmptyMessageText, map, Optional, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { OktaTenantsContext, useSetOktaTenantsContext } from "../..";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { useOktaTenantModelStatusTranslator } from "../../hooks";
import { ActionsCell, StatusCell } from "./components";

export function Table() {
    const tenantModels = tenantModelStore.useGetPermittedOktaTenants();
    const setOktaTenantsContext = useSetOktaTenantsContext();

    const oktaTenantModelStatusTranslator = useOktaTenantModelStatusTranslator();

    function getTenantModels(_filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _.orderBy(
            tenantModels,
            tenantModel =>
                map<string, number | string | undefined>(
                    sort?.columnId ?? TableColumnId.Name,
                    {
                        [TableColumnId.Name]: () => StringHelper.getSortValue(tenantModel.configuration.name),
                        [TableColumnId.Status]: () => StringHelper.getSortValue(oktaTenantModelStatusTranslator(tenantModel.status)),
                        [TableColumnId.Url]: () => StringHelper.getSortValue((tenantModel.configuration as Contract.OktaTenantConfiguration).url)
                    }),
            sort?.direction === DataTableSortDirection.Descending
                ? "desc"
                : "asc");
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [tenantModels]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oktaTenants.table",
            () => ({
                actions: {
                    add: "Add Okta Organization"
                },
                columns: {
                    name: "Name",
                    status: "Status",
                    url: "Okta Organization URL"
                },
                empty: "No Okta organizations"
            }));
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={getTenantModels}
            getItemId={(item: Contract.OktaTenantModel) => item.configuration.id}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setOktaTenantsContext(new OktaTenantsContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.OktaTenantModel) => item.configuration.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.Url}
                itemProperty={(item: Contract.OktaTenantModel) => (item.configuration as Contract.OktaTenantConfiguration).url}
                title={localization.columns.url()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={StatusCell}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    Status = "status",
    Url = "url"
}