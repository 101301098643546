﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { useGetGcpContainerClusterRiskContext } from "..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpContainerClusterAttributeBasedAuthorizationEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpContainerClusterAttributeBasedAuthorizationEnabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.GcpContainerClusterModel;

    const getGcpContainerClusterRiskContext = useGetGcpContainerClusterRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpContainerClusterAttributeBasedAuthorizationEnabledRiskDefinition",
            () => ({
                contextItems: {
                    attributeBasedAuthorizationEnabled: "The cluster has legacy authorization (ABAC) enabled"
                },
                description: "{{cluster}} has legacy authorization (ABAC) enabled",
                resolutionSection: {
                    step1: "In the **Details** tab, under **Security**, click on the pencil icon next to **Legacy authorization**",
                    step2: "Uncheck the **Enable legacy authorization** checkbox",
                    step3: "Click **SAVE CHANGES**",
                    step4: "Note: Once legacy authorization is disabled for the cluster, you must grant your user the ability to create authorization roles using RBAC to ensure that your role-based access control permissions take effect"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={`${risk.entityId}-${Contract.GcpConsolePage.Resource}`}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3(),
            localization.resolutionSection.step4()
        ],
        riskModel,
        () => {
            const containerClusterRiskContext = getGcpContainerClusterRiskContext(clusterModel);
            return [
                containerClusterRiskContext.generalInformation,
                containerClusterRiskContext.sensitive,
                containerClusterRiskContext.vpc,
                new RiskDefinitionContextItem(localization.contextItems.attributeBasedAuthorizationEnabled()),
                containerClusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}