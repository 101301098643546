﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../components";
import { useGetGcpComputeInstanceRiskContext, useGetGcpTenantEntityRiskContext } from "../../contexts";

export function useGcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskInstanceDefinition(riskModel: Contract.GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskModel) {
    const instanceModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.GcpComputeInstanceModel;
    const tenantEntityModel = entityModelStore.useGet(instanceModel.entity.tenantId) as Contract.GcpTenantEntityModel;
    const risk = riskModel.risk as Contract.GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRisk;

    const getGcpComputeInstanceRiskContext = useGetGcpComputeInstanceRiskContext();
    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskDefinition.useGcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskInstanceDefinition",
            () => ({
                description: "{{instance}} is not blocking project wide SSH keys",
                sections: {
                    resolution: {
                        step1: "Click the **Edit** button",
                        step2: "Under **Security and access** check **Block project-wide SSH keys**",
                        step3: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const instanceContextItems = getGcpComputeInstanceRiskContext(instanceModel);
            const tenantEntityContextItems = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.status,
                instanceContextItems.vpcsAndSubnets,
                tenantEntityContextItems.sshKeys,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}