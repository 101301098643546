import { useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { tenantModelStore, UrlHelper, useTheme } from "../../../../../../../../../../../../../../../common";

export function TenantItem() {
    const tenantModels = tenantModelStore.useGetPermittedOneLoginTenants();
    const { tenantConfiguration, tenantName, tenantUrl } = useAddOrEditContext();
    const [name, setName] = useState(tenantName);
    const [url, setUrl] = useState(tenantUrl);

    const { executing, setLoaded, setValid } = useOrderedWizardContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: undefined
                }));
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oneLoginTenants.addOrEdit.tenantItem",
            () => ({
                fields: {
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    url: {
                        error: {
                            exists: "Account URL already exists",
                            invalidFormat: "Account URL must be https://{domain}",
                            required: "Account URL cannot be empty"
                        },
                        title: "Account URL"
                    }
                }
            }));

    const existingTenantConfigurations =
        useMemo(
            () =>
                _(tenantModels).
                    filter(tenantModel => tenantModel.configuration.id !== tenantConfiguration?.id).
                    map(tenantModel => tenantModel.configuration).
                    value(),
            [tenantModels]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }
                if (_.some(
                    existingTenantConfigurations,
                    existingTenantConfiguration => existingTenantConfiguration.name === validationName)) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [urlValidationController, urlValidationMessage] =
        useInputValidation(
            () => {
                const validationUrl = url.trim();
                if (_.isEmpty(validationUrl)) {
                    return localization.fields.url.error.required();
                }
                const urlData = UrlHelper.getUrlData(validationUrl);
                if (
                    _.isNil(urlData) ||
                    urlData.protocolType !== "https" ||
                    urlData.pathname !== "/") {
                    return localization.fields.url.error.invalidFormat();
                }
                if (_.some(
                    existingTenantConfigurations,
                    existingTenantConfiguration => existingTenantConfiguration.url === validationUrl)) {
                    return localization.fields.url.error.exists();
                }
                return undefined;
            },
            [url]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantName: name.trim(),
                    tenantUrl: url.trim()
                }));

            setValid(
                nameValidationController.isValid() &&
                urlValidationController.isValid());
        },
        [name, url]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.name.title()}
                    value={name}
                    variant="outlined"
                    onChange={event => setName(event.target.value)}/>
                {!_.isNil(nameValidationMessage) &&
                    <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.url.title()}
                    value={url}
                    variant="outlined"
                    onChange={event => setUrl(event.target.value)}/>
                {!_.isNil(urlValidationMessage) &&
                    <FormHelperText error={true}>{urlValidationMessage}</FormHelperText>}
            </FormControl>
        </Stack>);
}