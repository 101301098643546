import { EmptyMessageText, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard } from "../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../components";

type AwsPrincipalPoliciesInfoCardProps = {
    groupPolicyIds?: string[];
    inlinePolicyIds: string[];
    managedPolicyIds: string[];
    permissionBoundaryPolicyId?: string;
};

export function AwsPrincipalPoliciesInfoCard({ groupPolicyIds, inlinePolicyIds, managedPolicyIds, permissionBoundaryPolicyId }: AwsPrincipalPoliciesInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.awsPrincipalPoliciesInfoCard",
            () => ({
                empty: "No policies",
                groupPolicies: [
                    "Group Policy",
                    "Group Policies"
                ],
                permissionBoundaryPolicy: "Permissions Boundary policy",
                title: "Policies"
            }));
    return (
        <InfoCard
            emptyMessage={new EmptyMessageText(localization.empty())}
            title={localization.title()}>
            <InfoCard
                columns={true}>
                <EntitiesInfoItem
                    entityIdsOrModels={inlinePolicyIds}
                    entityTypeName={Contract.TypeNames.AwsIamInlinePolicy}/>
                <EntitiesInfoItem
                    entityIdsOrModels={managedPolicyIds}
                    entityTypeName={Contract.TypeNames.AwsIamManagedPolicy}/>
                <EntitiesInfoItem
                    entityIdsOrModels={groupPolicyIds}
                    entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                    title={localization.groupPolicies(_.size(groupPolicyIds))}/>
                <EntitiesInfoItem
                    entityIdsOrModels={permissionBoundaryPolicyId}
                    entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                    title={localization.permissionBoundaryPolicy()}/>
            </InfoCard>
        </InfoCard>);
}