import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../common";
import { AwsEc2SecurityGroupRulesTable } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";

export function useAwsEc2SecurityGroupDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const securityGroup = resourceModel.entity as Contract.AwsEc2SecurityGroup;
    const securityGroupModel = resourceModel as Contract.AwsEc2SecurityGroupModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2SecurityGroupDefinition",
            () => ({
                info: {
                    cards: {
                        inboundRules: "Inbound Rules",
                        outboundRules: "Outbound Rules",
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        defaultSecurityGroup: {
                            false: "No",
                            title: "Default Security Group",
                            true: "Yes"
                        },
                        description: "Description",
                        referencingSecurityGroups: "Referencing Security Groups",
                        vpcId: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={securityGroup.description}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={securityGroup.vpcId}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpcId"
                        title={localization.info.items.vpcId()}/>,
                    <InfoItem
                        key="defaultSecurityGroup"
                        title={localization.info.items.defaultSecurityGroup.title()}
                        value={
                            securityGroup.defaultSecurityGroup
                                ? localization.info.items.defaultSecurityGroup.true()
                                : localization.info.items.defaultSecurityGroup.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={securityGroupModel.referencingSecurityGroupIds}
                        entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                        key="referencingSecurityGroups"
                        title={localization.info.items.referencingSecurityGroups()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={securityGroupModel.networkedResourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
                <InfoCard title={localization.info.cards.inboundRules()}>
                    <AwsEc2SecurityGroupRulesTable
                        inbound={true}
                        referencedSecurityGroupId={options?.infoOptions?.data}
                        rules={securityGroup.inboundRules}/>
                </InfoCard>
                <InfoCard title={localization.info.cards.outboundRules()}>
                    <AwsEc2SecurityGroupRulesTable
                        inbound={false}
                        referencedSecurityGroupId={options?.infoOptions?.data}
                        rules={securityGroup.outboundRules}/>
                </InfoCard>
            </Info>
    });
}