import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, tenantModelStore, useTheme } from "../../../../../../../../../../../../common";
import { AwsCloudFrontDistributionOriginsTable, AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsCloudFrontDistributionRiskContext } from "../contexts";

export function useAwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel = riskModel as Contract.AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel;
    const risk = riskModel.risk as Contract.AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRisk;
    const distributionModel = entityModelStore.useGet(risk.entityId) as Contract.AwsCloudFrontDistributionModel;
    const distribution = distributionModel.entity as Contract.AwsCloudFrontDistribution;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

    const getAwsCloudFrontDistributionRiskContext = useGetAwsCloudFrontDistributionRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskDefinition",
            () => ({
                description: "{{distribution}} S3 origin access control is not enabled",
                sections: {
                    origins: "Origins",
                    resolution: {
                        step1: {
                            step1: "Before you create an origin access control (OAC) or set it up in a CloudFront distribution, make sure the OAC has permission to access the S3 bucket origin. This step must be completed before adding the OAC to the S3 origin in the distribution configuration.",
                            step2: {
                                link: "AWS documentation",
                                text: "To give the OAC permission to access the S3 bucket, use an S3 bucket policy to allow the CloudFront service principal to access the bucket. For more information, see {{createOacLink}}."
                            },
                            title: "Give the origin access control permission to access the S3 bucket"
                        },
                        step2: {
                            step1: "Open CloudFront console and navigate to the **Origin access** page",
                            step2: "Click **Create control setting**",
                            step3: "In the Create new OAC window, enter a Name and Description (optional)",
                            step4: "Under Signing behavior, select **Sign requests (recommended)**",
                            step5: "From the **Origin type** dropdown, select **S3**",
                            step6: "Click **Create**",
                            title: "Create the origin access control"
                        },
                        step3: {
                            step1: {
                                link: "AWS recommendation",
                                text: "Before applying changes, ensure the OAC has permissions to access the S3 bucket origin. If you’re migrating from origin access identity (OAI) to origin access control (OAC), make sure you’ve followed {{oaiToOacLink}} to ensure CloudFront never loses access to the bucket during the transition."
                            },
                            step2: "Open the distribution page and click on the **Origins** tab",
                            step3: "Select the S3 origin and click **Edit**",
                            step4: "For **Origin access**, select **Origin access control settings (recommended)** and select the OAC you created",
                            step5: "Click **Save changes**",
                            title: "Add the newly created origin access control to an S3 origin in a distribution"
                        }
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            distribution:
                <Entity
                    entityIdOrModel={distributionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.S3,
                AwsConsoleUrlBuilder.getS3Url(tenantConfiguration.partitionType)),
            new Step(
                localization.sections.resolution.step1.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2.text({
                                createOacLink:
                                    <Link
                                        urlOrGetUrl={AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel.createOacDocumentationUrl}
                                        variant="external">
                                        {localization.sections.resolution.step1.step2.link()}
                                    </Link>
                            })}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                            {localization.sections.resolution.step2.step6()}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step3.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step3.step1.text({
                                oaiToOacLink:
                                    <Link
                                        urlOrGetUrl={AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel.oaiToOacDocumentationUrl}
                                        variant="external">
                                        {localization.sections.resolution.step1.step2.link()}
                                    </Link>
                            })}
                            {localization.sections.resolution.step3.step2()}
                            {localization.sections.resolution.step3.step3()}
                            {localization.sections.resolution.step3.step4()}
                            {localization.sections.resolution.step3.step5()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const distributionRiskContext = getAwsCloudFrontDistributionRiskContext(distributionModel);
            return [
                distributionRiskContext.generalInformation,
                distributionRiskContext.status,
                distributionRiskContext.originResources,
                distributionRiskContext.sensitiveOrigin,
                distributionRiskContext.sensitive,
                distributionRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AwsCloudFrontDistributionOriginsTable
                        getHighlightColor={
                            (origin: Contract.AwsCloudFrontDistributionOrigin, opacity) =>
                                _.includes(AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel.risk.riskedOriginIds, origin.id)
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        : theme.palette.severity(riskModel.risk.severity)
                                    : undefined
                        }
                        originIdToResourceIdReferenceMap={distributionModel.originIdToResourceIdReferenceMap}
                        origins={distribution.origins}/>,
                    localization.sections.origins(),
                    {
                        expandable: true
                    })
            ]
        });
}