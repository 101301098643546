﻿import { FormLayout, SetHelper, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../../../../../controllers";
import { AccessGraphPermissionEdgeData } from "../../../utilities";
import { EntitiesPermissionActions } from "../../EntitiesPermissionActions";

type GraphEntitiesPermissionActionsProps = {
    accessGraphId: string;
    accessVariant: Contract.EntityAccessVariant;
    entitiesPermissionActionsDestinationEntityGroup: Contract.AccessGraphDestinationEntityGroup;
    excessivePermissionsEnabled: boolean;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    resourceServiceModelMap: Dictionary<Contract.EntityModel>;
    selectedIdentityIds: string[];
    selectedPermissionPaths: Contract.AccessGraphPermissionPath[];
};

export function GraphEntitiesPermissionActions({ accessGraphId, accessVariant, entitiesPermissionActionsDestinationEntityGroup, excessivePermissionsEnabled, permissionEdgeIdToDataMap, resourceServiceModelMap, selectedIdentityIds, selectedPermissionPaths }: GraphEntitiesPermissionActionsProps) {
    const filteredPermissionEdgeIdToDataMap =
        useMemo(
            () => {
                const sourceEntityIdsSet = new Set(selectedIdentityIds);
                const destinationEntityIdsSet = new Set(entitiesPermissionActionsDestinationEntityGroup.entityIds);
                return _(permissionEdgeIdToDataMap).
                    mapValues(
                        permissionEdgeData =>
                            new AccessGraphPermissionEdgeData(
                                SetHelper.intersection(
                                    destinationEntityIdsSet,
                                    permissionEdgeData.destinationEntityIds),
                                permissionEdgeData.destinationEntitiesServiceId,
                                permissionEdgeData.excessivePermissionActionCount,
                                permissionEdgeData.nonexcessivePermissionActionCount,
                                SetHelper.intersection(
                                    sourceEntityIdsSet,
                                    permissionEdgeData.sourceEntityIds))).
                    omitBy(
                        permissionEdgeData =>
                            permissionEdgeData.destinationEntityIds.size == 0 ||
                            permissionEdgeData.sourceEntityIds.size == 0).
                    value() as Dictionary<AccessGraphPermissionEdgeData>;
            },
            [entitiesPermissionActionsDestinationEntityGroup, selectedIdentityIds]);

    const localization =
        useLocalization(
            "common.access.graph.graphEntitiesPermissionActions",
            () => ({
                title: "Permissions"
            }));

    return (
        <FormLayout
            disableContentPadding={true}
            titleOptions={{ text: localization.title() }}>
            <EntitiesPermissionActions
                accessGraphId={accessGraphId}
                accessVariant={accessVariant}
                excessivePermissionsEnabled={excessivePermissionsEnabled}
                permissionEdgeIdToDataMap={filteredPermissionEdgeIdToDataMap!}
                permissionPaths={selectedPermissionPaths}
                resourceServiceModelMap={resourceServiceModelMap}
                variant="graph"/>
        </FormLayout>);
}