import React, { useMemo } from "react";
import { RiskPoliciesType, RiskPolicyItem } from "../../../../../../../../../../../common";
import { useRiskPoliciesItemConfiguration } from "../../../../../../../hooks";
import { CodeExclusion } from "../../../../../components";
import { ConfigurationCategory } from "../../../../../Configuration";
import { RiskPolicyConfigurationDefinition } from "../../../useDefinition";

export function useCodeRiskPolicyBuiltInDefinition(item: RiskPolicyItem, scopeId: string): RiskPolicyConfigurationDefinition {
    const { parentRiskPolicyModels, scopeRiskPolicyModel } = useRiskPoliciesItemConfiguration(RiskPoliciesType.Code, item, scopeId);

    return useMemo(
        () => ({
            configurationCategoryTabToViewItemMap: {
                [ConfigurationCategory.CodeExclusions]: {
                    component:
                        () =>
                            <CodeExclusion
                                key={ConfigurationCategory.CodeExclusions}
                                parentRiskPolicyModels={parentRiskPolicyModels}
                                scopeRiskPolicyModel={scopeRiskPolicyModel}/>
                }
            },
            generalAdditionalItems: []
        }),
        [parentRiskPolicyModels, scopeRiskPolicyModel]);
}