import { useLocalization } from "@infrastructure";
import { Contract } from "../controllers";

export function useDynamicRiskSeverityTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useDynamicRiskSeverityTranslator",
            () => ({
                [Contract.TypeNames.AadDirectoryApplicationCertificateNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the certificate is used by an application with high permissions",
                [Contract.TypeNames.AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the certificate is used by an application with high permissions",
                [Contract.TypeNames.AwsAccessAnalyzerRegionAnalyzerNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AadDirectoryApplicationServicePrincipalInactiveRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the application is enabled",
                [Contract.TypeNames.AadDirectoryUserInactiveRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the user is enabled.",
                [Contract.TypeNames.AwsAcmCertificateKeyMinLengthRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the certificate is marked as sensitive",
                [Contract.TypeNames.AwsApiGatewayApiWebAclNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the network exposure and sensitivity of the API, whether its integrations are sensitive, and whether the method is empty, null, or mock",
                [Contract.TypeNames.AwsAthenaWorkgroupEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Athena workgroup is marked as sensitive",
                [Contract.TypeNames.AwsAthenaWorkgroupLoggingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Athena workgroup is marked as sensitive",
                [Contract.TypeNames.AwsCloudFormationStackSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the stack is marked as sensitive",
                [Contract.TypeNames.AwsCloudFrontDistributionDefaultSslCertificateRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the distribution or its origins are marked as sensitive",
                [Contract.TypeNames.AwsCloudFrontDistributionHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the distribution or its origins are marked as sensitive",
                [Contract.TypeNames.AwsCloudFrontDistributionInsecureTlsProtocolRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the distribution or its origins are marked as sensitive",
                [Contract.TypeNames.AwsCloudFrontDistributionLoggingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the distribution or its origins are marked as sensitive",
                [Contract.TypeNames.AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the CloudFront distribution or its origins are marked as sensitive",
                [Contract.TypeNames.AwsCloudFrontDistributionRootObjectNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the distribution or its origins are marked as sensitive",
                [Contract.TypeNames.AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the distribution or its origins are marked as sensitive",
                [Contract.TypeNames.AwsCloudFrontDistributionWebAclNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the distribution or its origins are marked as sensitive",
                [Contract.TypeNames.AwsCloudTrailTrailBucketEventDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the bucket or the trail is marked as sensitive",
                [Contract.TypeNames.AwsCloudTrailTrailBucketPublicAccessEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the bucket's public permissions severity and whether the bucket or the trail is marked as sensitive",
                [Contract.TypeNames.AwsCloudTrailTrailDigestDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the trail is marked as sensitive",
                [Contract.TypeNames.AwsCloudTrailTrailEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the CloudTrail associated with the S3 bucket is publicly accessible or marked as sensitive",
                [Contract.TypeNames.AwsCloudTrailTrailLogGroupDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the trail is marked as sensitive",
                [Contract.TypeNames.AwsCloudTrailTrailMultiRegionNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account or its S3 buckets are marked as sensitive",
                [Contract.TypeNames.AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account or its S3 buckets are marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmCloudTrailChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmConfigChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmEc2VpcChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmIamPolicyChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmRootUsageNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsDynamoDbTableDeleteProtectionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the resource contains sensitive data or it is marked as sensitive",
                [Contract.TypeNames.AwsDynamoDbTableEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the storage size of the table and whether the table is marked as sensitive",
                [Contract.TypeNames.AwsEc2ImagePublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the image or any EC2 instance that was launched with the image is marked as sensitive",
                [Contract.TypeNames.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskPolicyConfiguration]: "The severity is dynamically determined based on the EC2 instance state, exposed ports, associated permissions, and whether the instance is marked as sensitive",
                [Contract.TypeNames.AwsEc2InstanceMetadataServiceVersionRiskPolicyConfiguration]: "The severity is dynamically determined based on the EC2 instance state, exposed ports, associated permissions, and whether the instance is marked as sensitive",
                [Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public management ports, identities that can access the user data, public permissions severity and whether the instance is marked as sensitive",
                [Contract.TypeNames.AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the load balancer has any targets, is publicly accessible, or is marked as sensitive",
                [Contract.TypeNames.AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the network ACL resources and whether the network ACL is marked as sensitive",
                [Contract.TypeNames.AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the security group resources and whether the security group is marked as sensitive",
                [Contract.TypeNames.AwsEc2SecurityGroupUnusedRiskPolicyConfiguration]: "The severity is dynamically determined based on the inbound security group rules and whether the security group is marked as sensitive",
                [Contract.TypeNames.AwsEc2SnapshotEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the snapshot is associated with instances and the snapshot’s sensitivity",
                [Contract.TypeNames.AwsEc2SnapshotPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the snapshot is associated with instances and the snapshot’s sensitivity",
                [Contract.TypeNames.AwsEc2VolumeEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the volume's data is marked for deletion and on the volume's sensitivity",
                [Contract.TypeNames.AwsEc2VpcDefaultSecurityGroupRuleExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the security group or VPC is marked as sensitive",
                [Contract.TypeNames.AwsEc2VpcEndpointUnrestrictedServiceAccessRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the VPC endpoint or VPC is marked as sensitive",
                [Contract.TypeNames.AwsEc2VpcRejectFlowLogNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the VPC is marked as sensitive",
                [Contract.TypeNames.AwsEcrRepositoryPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity and whether the repository is marked as sensitive",
                [Contract.TypeNames.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the task definition is marked as sensitive",
                [Contract.TypeNames.AwsEksClusterInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of public IP addresses that can access the Kubernetes API, and whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsEksClusterInstanceInboundExternalRiskPolicyConfiguration]: "The severity is dynamically determined based on the network accessibility of the nodes, and whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsEksClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsElastiCacheReplicationGroupEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cache is marked as sensitive",
                [Contract.TypeNames.AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the environment is marked as sensitive.",
                [Contract.TypeNames.AwsElasticFileSystemFileSystemEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the file system is marked as sensitive",
                [Contract.TypeNames.AwsEsDomainCloudWatchLoggingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the domain is marked as sensitive.",
                [Contract.TypeNames.AwsEsDomainEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the domain is marked as sensitive",
                [Contract.TypeNames.AwsEsDomainNodeCommunicationEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the domain is marked as sensitive",
                [Contract.TypeNames.AwsEsDomainPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity and whether the domain is marked as sensitive",
                [Contract.TypeNames.AwsExcessivePermissionBucketRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the bucket, and whether the bucket is marked as sensitive",
                [Contract.TypeNames.AwsExcessivePermissionGroupRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the group",
                [Contract.TypeNames.AwsExcessivePermissionPermissionSetRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the permission set",
                [Contract.TypeNames.AwsExcessivePermissionRepositoryRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the repository, and whether the repository is marked as sensitive",
                [Contract.TypeNames.AwsExcessivePermissionRoleGroupRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the role with the highest severity",
                [Contract.TypeNames.AwsExcessivePermissionRoleRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the role",
                [Contract.TypeNames.AwsExcessivePermissionSecretRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the secret, and whether the secret is marked as sensitive",
                [Contract.TypeNames.AwsExcessivePermissionUserRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the user",
                [Contract.TypeNames.AwsFirehoseDeliveryStreamEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the data firehose stream is marked as sensitive",
                [Contract.TypeNames.AwsIamPasswordLengthPolicyRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsIamPasswordReusePolicyRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the account is marked as sensitive",
                [Contract.TypeNames.AwsIamRoleInactiveRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the role is used by a 3rd party",
                [Contract.TypeNames.AwsIamRolePublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the role",
                [Contract.TypeNames.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the 3rd party role",
                [Contract.TypeNames.AwsIamUserAttachedPolicyRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the user",
                [Contract.TypeNames.AwsIamUserInactiveRiskPolicyConfiguration]: "The severity is dynamically determined based on the type of valid authentication credentials the user has",
                [Contract.TypeNames.AwsIamUserMfaDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the user",
                [Contract.TypeNames.AwsIamUserMultipleEnabledAccessKeyRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the user",
                [Contract.TypeNames.AwsIamUserUnrotatedAccessKeyRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the user",
                [Contract.TypeNames.AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the unused access keys",
                [Contract.TypeNames.AwsIamUserUnusedPasswordEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the unused password and whether it is protected with MFA",
                [Contract.TypeNames.AwsInboundExternalEc2InstanceRiskPolicyConfiguration]: "The severity is dynamically determined based on the state, public management ports, public permissions severity, and vulnerabilities of the instance, and whether the instance is marked as sensitive",
                [Contract.TypeNames.AwsInboundExternalElbLoadBalancerRiskPolicyConfiguration]: "The severity is dynamically determined based on the public management ports and targets the load balancer (ELB) exposes and whether the ELB is marked as sensitive",
                [Contract.TypeNames.AwsInboundExternalRdsDatabaseInstanceRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of IP addresses that this instance is exposed to and whether the instance is marked as sensitive",
                [Contract.TypeNames.AwsInboundExternalRedshiftClusterRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of IP addresses that this cluster is exposed to and whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsKinesisStreamEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the data stream is marked as sensitive",
                [Contract.TypeNames.AwsKmsKeyPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity and whether the key is marked as sensitive",
                [Contract.TypeNames.AwsKmsKeyRotationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the key is used to encrypt any resource, or whether it is marked as sensitive",
                [Contract.TypeNames.AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Lambda function is publicly accessible or if it is marked as sensitive",
                [Contract.TypeNames.AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the lambda is marked as sensitive",
                [Contract.TypeNames.AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Lambda function is is marked as sensitive",
                [Contract.TypeNames.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the public permissions and whether the function is marked as sensitive",
                [Contract.TypeNames.AwsLambdaFunctionConfigurationVpcNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Lambda function is marked as sensitive",
                [Contract.TypeNames.AwsRdsClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the resource contains sensitive data or it is marked as sensitive",
                [Contract.TypeNames.AwsRdsClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the resource's sensitivity, whether it contains any sensitive data, or is marked as sensitive.",
                [Contract.TypeNames.AwsRdsClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster contains sensitive data or is marked as sensitive",
                [Contract.TypeNames.AwsRdsClusterMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive or contains sensitive data.",
                [Contract.TypeNames.AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the resource contains sensitive data or it is marked as sensitive",
                [Contract.TypeNames.AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the resource's sensitivity, whether it contains any sensitive data, or is marked as sensitive.",
                [Contract.TypeNames.AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the instance contains sensitive data or is marked as sensitive",
                [Contract.TypeNames.AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the RDS instance's network exposure and sensitivity",
                [Contract.TypeNames.AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the RDS instance's network exposure and sensitivity",
                [Contract.TypeNames.AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive.",
                [Contract.TypeNames.AwsRdsDocDbClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the snapshot's access level and sensitivity",
                [Contract.TypeNames.AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the snapshot or the cluster is marked as sensitive",
                [Contract.TypeNames.AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive.",
                [Contract.TypeNames.AwsRdsNeptuneClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the snapshot's access level and sensitivity",
                [Contract.TypeNames.AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the snapshot or the cluster is marked as sensitive",
                [Contract.TypeNames.AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the instance is marked as sensitive",
                [Contract.TypeNames.AwsRdsSnapshotEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the snapshot's access level and sensitivity",
                [Contract.TypeNames.AwsRdsSnapshotPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the snapshot or the database is marked as sensitive",
                [Contract.TypeNames.AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AwsRedshiftClusterEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the Redshift cluster's network exposure, whether it contains any data, or is marked as sensitive",
                [Contract.TypeNames.AwsResourceTagSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the resource is marked as sensitive",
                [Contract.TypeNames.AwsRoute53HostedZoneSigningDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Route 53 hosted zone is marked as sensitive",
                [Contract.TypeNames.AwsS3BucketBlockPublicAccessDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the bucket access level and if it is marked as sensitive",
                [Contract.TypeNames.AwsS3BucketEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the S3 bucket is marked as sensitive",
                [Contract.TypeNames.AwsS3BucketHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the bucket access level and if it is marked as sensitive",
                [Contract.TypeNames.AwsS3BucketObjectMfaDeleteDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the bucket is storing Cloud Trail logs or if it is marked as sensitive",
                [Contract.TypeNames.AwsS3BucketPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity and whether the bucket is marked as sensitive",
                [Contract.TypeNames.AwsSageMakerNotebookInstanceDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SageMaker Notebook instance is marked as sensitive",
                [Contract.TypeNames.AwsSageMakerNotebookInstanceRootAccessEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SageMaker Notebook instance is marked as sensitive",
                [Contract.TypeNames.AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SageMaker Notebook instance is marked as sensitive",
                [Contract.TypeNames.AwsSecretsManagerSecretPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity and whether the secret is marked as sensitive",
                [Contract.TypeNames.AwsSecretsManagerSecretRotationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the rotation of the secret and whether the secret is marked as sensitive",
                [Contract.TypeNames.AwsSnsTopicEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SNS topic is publicly accessible, has any subscriptions, or is marked as sensitive",
                [Contract.TypeNames.AwsSnsTopicHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the topic access level and whether it is marked as sensitive",
                [Contract.TypeNames.AwsSnsTopicPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity and whether the topic is marked as sensitive",
                [Contract.TypeNames.AwsSqsQueueEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the SQS queue’s access level and whether it is marked as sensitive",
                [Contract.TypeNames.AwsSqsQueuePublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity and whether the queue is marked as sensitive",
                [Contract.TypeNames.AwsSsmParameterSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the identities that have access to the parameter secret value and whether the parameter is marked as sensitive",
                [Contract.TypeNames.AwsSsoPermissionSetUnusedRiskPolicyConfiguration]: "The severity is dynamically determined based on the assignments of the permission set",
                [Contract.TypeNames.AzureBotServiceBotServiceInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the bot service is marked as sensitive",
                [Contract.TypeNames.AzureBotServiceBotServiceSharedKeyAccessEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the bot service blocks public network access and whether it is marked as sensitive",
                [Contract.TypeNames.AzureCognitiveServicesAccountInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the AI service is marked as sensitive",
                [Contract.TypeNames.AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the AI service blocks public network access and whether it is marked as sensitive",
                [Contract.TypeNames.AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the AI service is marked as sensitive",
                [Contract.TypeNames.AzureComputeVirtualMachineUnmanagedDiskExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the status of the virtual machine and if it is marked as sensitive",
                [Contract.TypeNames.AzureContainerRegistryRegistryAccessKeyEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the container registry blocks public network access, the number of repositories, and whether the container registry or its repositories are marked as sensitive",
                [Contract.TypeNames.AzureContainerRegistryRegistryInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the admin user is enabled, and whether the container registry or its repositories are marked as sensitive",
                [Contract.TypeNames.AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AzureContainerServiceManagedClusterInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of public IP addresses that can access the Kubernetes API, and whether the cluster is marked as sensitive",
                [Contract.TypeNames.AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskPolicyConfiguration]: "The severity is dynamically determined based on the network accessibility of the nodes, and whether the cluster is marked as sensitive",
                [Contract.TypeNames.AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the CosmosDB account network exposure and whether the CosmosDB account is marked as sensitive",
                [Contract.TypeNames.AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the CosmosDB account is marked as sensitive",
                [Contract.TypeNames.AzureDocumentDbDatabaseAccountWideRangeInboundRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of public IP addresses that can access your CosmosDB account and whether the CosmosDB account is marked as sensitive",
                [Contract.TypeNames.AzureEventHubNamespaceInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Event Hubs Namespace is marked as sensitive",
                [Contract.TypeNames.AzureExcessivePermissionApplicationServicePrincipalRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the application",
                [Contract.TypeNames.AzureExcessivePermissionGroupRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the group",
                [Contract.TypeNames.AzureExcessivePermissionManagedIdentityServicePrincipalRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the managed identity",
                [Contract.TypeNames.AzureExcessivePermissionUserRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the user",
                [Contract.TypeNames.AzureInboundExternalComputeVirtualMachineRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Virtual Machine is running, if any management ports are exposed and whether the machine is marked as sensitive",
                [Contract.TypeNames.AzureInboundExternalComputeVirtualMachineScaleSetRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Virtual Machine Scale Set is running, if any management ports are exposed and whether the scale set is marked as sensitive",
                [Contract.TypeNames.AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account or its blob containers are marked as sensitive",
                [Contract.TypeNames.AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the container’s access level and whether the storage account or its blob containers are marked as sensitive",
                [Contract.TypeNames.AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the subscription is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultCertificateNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the certificate or the key vault is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultEventDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the key vault is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultKeyExpirationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the key is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultKeyNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the key or the key vault is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultKeyRotationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the key vault is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultPrivateEndpointNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the key vault is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultRbacDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the key vault has any access policy and whether the key vault is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultSecretExpirationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the secret or the key vault is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultSoftDeleteDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on \"Soft Delete\" and \"Do Not Purge\" flags and whether the key vault is marked as sensitive",
                [Contract.TypeNames.AzureKeyVaultVaultWideRangeInboundRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the key vault is marked as sensitive",
                [Contract.TypeNames.AzureLogicWorkflowInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the logic app is marked as sensitive",
                [Contract.TypeNames.AzureLogicWorkflowSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on which identities have access to the logic app and whether it is marked as sensitive",
                [Contract.TypeNames.AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Machine Learning workspace is marked as high business impact or sensitive",
                [Contract.TypeNames.AzureMachineLearningWorkspaceInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Machine Learning workspace is marked as high business impact or sensitive",
                [Contract.TypeNames.AzureManagedIdentityManagedIdentityInactiveRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the managed identity can be removed in Azure",
                [Contract.TypeNames.AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the MySQL database server and whether it is publicly accessible",
                [Contract.TypeNames.AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the network exposure of the database and whether it is marked as sensitive",
                [Contract.TypeNames.AzureMySqlFlexibleServerAuditLogDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the network exposure of the database and whether it is marked as sensitive",
                [Contract.TypeNames.AzureMySqlFlexibleServerInsecureTlsProtocolRiskPolicyConfiguration]: "The severity is dynamically determined based on the network exposure of the database and whether it is marked as sensitive",
                [Contract.TypeNames.AzureMySqlServerHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the network exposure of the database and whether it is marked as sensitive",
                [Contract.TypeNames.AzureMySqlServerWideRangeInboundRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of public IP addresses that can access your server and whether the server is marked as sensitive",
                [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the security group is marked as sensitive, and whether resources associated with the group either have high permissions or are marked as sensitive",
                [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the security group is marked as sensitive, and whether resources associated with the group either have high permissions or are marked as sensitive",
                [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the security group is marked as sensitive, and whether resources associated with the group either have high permissions or are marked as sensitive",
                [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the security group is marked as sensitive, and whether resources associated with the group either have high permissions or are marked as sensitive",
                [Contract.TypeNames.AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskPolicyConfiguration]: "The severity is dynamically determined based on the subnet resources and whether the subnet is marked as sensitive",
                [Contract.TypeNames.AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the PostgreSQL database server and whether it is publicly accessible",
                [Contract.TypeNames.AzurePostgreSqlServerConnectionThrottlingDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the database is marked as sensitive",
                [Contract.TypeNames.AzurePostgreSqlServerHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the database is marked as sensitive",
                [Contract.TypeNames.AzurePostgreSqlServerLogCheckpointsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the database is marked as sensitive",
                [Contract.TypeNames.AzurePostgreSqlServerLogConnectionsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the database is marked as sensitive",
                [Contract.TypeNames.AzurePostgreSqlServerLogDisconnectionsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the database is marked as sensitive",
                [Contract.TypeNames.AzurePostgreSqlServerLogRetentionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the database is marked as sensitive",
                [Contract.TypeNames.AzurePostgreSqlServerWideRangeInboundRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of public IP addresses that can access your server and whether the server is marked as sensitive",
                [Contract.TypeNames.AzureResourceTagSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the resource is marked as sensitive",
                [Contract.TypeNames.AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL server is marked as sensitive",
                [Contract.TypeNames.AzureSqlServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the SQL server, whether it's publicly accessible, and whether it's empty",
                [Contract.TypeNames.AzureSqlServerDatabaseEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL database is marked as sensitive",
                [Contract.TypeNames.AzureSqlServerDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL server is marked as sensitive",
                [Contract.TypeNames.AzureSqlServerEventDisabledRiskPolicyConfiguration]: "The severity will be dynamically determined based on whether the SQL server is marked as sensitive",
                [Contract.TypeNames.AzureSqlServerEventRetentionTimeFrameRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL server is marked as sensitive",
                [Contract.TypeNames.AzureSqlServerSecurityAlertPolicyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL server is marked as sensitive",
                [Contract.TypeNames.AzureSqlServerWideRangeInboundRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of public IP addresses that can access your server and whether the server is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the blob container's access level and sensitivity",
                [Contract.TypeNames.AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountInsecureTlsProtocolRiskPolicyConfiguration]: "The severity is dynamically determined based on the storage account network exposure and whether it is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountPrivateEndpointNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountSharedKeyAccessEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account blocks public network access, the number of resources the storage account contains and whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountSoftDeleteDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureStorageStorageAccountWideRangeInboundRiskPolicyConfiguration]: "The severity is dynamically determined based on the storage account's access level and whether the storage account is marked as sensitive",
                [Contract.TypeNames.AzureWebApplicationHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the app service is marked as sensitive",
                [Contract.TypeNames.AzureWebApplicationInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on if the app service is marked as sensitive",
                [Contract.TypeNames.AzureWebApplicationInsecureTlsProtocolRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the app service is marked as sensitive",
                [Contract.TypeNames.AzureWebApplicationScmInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the app service is marked as sensitive",
                [Contract.TypeNames.ContainerImageOperatingSystemEndOfLifeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the virtual machines on which this image was seen are marked as sensitive, whether they are publicly accessible, and their permission severity",
                [Contract.TypeNames.ContainerImageOperatingSystemUnpatchedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the virtual machines on which this image was seen are marked as sensitive, whether they are publicly accessible, and their permission severity",
                [Contract.TypeNames.ContainerImageRepositoryVulnerabilityRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the certificate is used by an application with high permissions",
                [Contract.TypeNames.ContainerImageVulnerabilityRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the virtual machines on which this image was seen are marked as sensitive, whether they are publicly accessible, and their permission severity",
                [Contract.TypeNames.GciDirectoryUserInactiveRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the user is enabled",
                [Contract.TypeNames.GcpApiKeysKeyClientRestrictionNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the API key or the project it belongs to is marked as sensitive",
                [Contract.TypeNames.GcpApiKeysKeyExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project or its API keys are marked as sensitive",
                [Contract.TypeNames.GcpApiKeysKeyNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the API key or the project it belongs to is marked as sensitive",
                [Contract.TypeNames.GcpApiKeysKeyServiceRestrictionNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the API key or the project it belongs to is marked as sensitive",
                [Contract.TypeNames.GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on which principals have access to the environment variables and whether the  service is marked as sensitive",
                [Contract.TypeNames.GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the App Engine service is marked as sensitive",
                [Contract.TypeNames.GcpArtifactRegistryPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity and whether the registry is marked as sensitive",
                [Contract.TypeNames.GcpBigQueryDatasetEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the BigQuery dataset is marked as sensitive",
                [Contract.TypeNames.GcpBigQueryDatasetPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of tables affected, the severity of the public permission and whether the dataset is marked as sensitive",
                [Contract.TypeNames.GcpBigQueryDatasetTableEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of tables affected and whether the dataset or its tables are marked as sensitive",
                [Contract.TypeNames.GcpCloudBuildBuildTriggerDefaultServiceAccountRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Cloud Build trigger is marked as sensitive",
                [Contract.TypeNames.GcpCloudRunServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the principals that can access the environment variables and on the service's sensitivity",
                [Contract.TypeNames.GcpCloudRunServicePublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the public permissions and whether the service is marked as sensitive",
                [Contract.TypeNames.GcpComputeBackendServiceLoggingNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the backend service or the load balancer is marked as sensitive",
                [Contract.TypeNames.GcpComputeImagePublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the image's public permissions and whether the image is marked as sensitive",
                [Contract.TypeNames.GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the SSH exposure level and whether the VM instance is marked as sensitive",
                [Contract.TypeNames.GcpComputeInstanceDefaultServiceAccountRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the VM instance is marked as sensitive",
                [Contract.TypeNames.GcpComputeInstanceIpForwardingEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the status of the virtual machine and if it is marked as sensitive",
                [Contract.TypeNames.GcpComputeInstanceMemoryEncryptionNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the status of the virtual machine and whether it is marked as sensitive",
                [Contract.TypeNames.GcpComputeInstanceShieldedNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the status of the virtual machine and whether it is marked as sensitive",
                [Contract.TypeNames.GcpComputeInstanceSshSerialPortEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the status of the virtual machine and if it is marked as sensitive",
                [Contract.TypeNames.GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the status of the virtual machine and if it is marked as sensitive",
                [Contract.TypeNames.GcpComputeLoadBalancerInsecureSslPolicyRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the load balancer or SSL policy is marked as sensitive",
                [Contract.TypeNames.GcpComputeProjectSshIamNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the project's status and if it is marked as sensitive",
                [Contract.TypeNames.GcpComputeVpcFlowLogsNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the VPC or its subnets are marked as sensitive",
                [Contract.TypeNames.GcpComputeVpcLegacyVpcRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the legacy VPC is marked as sensitive",
                [Contract.TypeNames.GcpComputeVpcUnrestrictedIcmpInboundAccessRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions severity of the instances attached to the VPC network and whether the VPC network or any attached instance is marked as sensitive",
                [Contract.TypeNames.GcpComputeVpcUnrestrictedRdpInboundAccessRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions severity of the instances attached to the VPC network and whether the VPC network or any attached instance is marked as sensitive",
                [Contract.TypeNames.GcpComputeVpcUnrestrictedSshInboundAccessRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions severity of the instances attached to the VPC network and whether the VPC network or any attached instance is marked as sensitive",
                [Contract.TypeNames.GcpContainerClusterAttributeBasedAuthorizationEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.GcpContainerClusterDefaultServiceAccountRiskPolicyConfiguration]: "The severity is dynamically determined based on the default service account access scopes and whether the cluster or any of its node pools are marked as sensitive",
                [Contract.TypeNames.GcpContainerClusterInboundExternalWideRangeRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of public IP Addresses that can access the Kubernetes API and whether the cluster is marked as sensitive",
                [Contract.TypeNames.GcpContainerClusterInstanceInboundExternalRiskPolicyConfiguration]: "The severity is dynamically determined based on the network accessibility of the nodes, and whether the cluster is marked as sensitive",
                [Contract.TypeNames.GcpContainerClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.GcpContainerClusterLegacyComputeMetadataServerEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.GcpContainerClusterWorkloadIdentityDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster or one of the node pools that have Metadata Server disabled is marked as sensitive, and the permissions severity of service accounts attached to the node pools",
                [Contract.TypeNames.GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the DNS managed zone is marked as sensitive",
                [Contract.TypeNames.GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the DNS managed zone is marked as sensitive",
                [Contract.TypeNames.GcpDnsManagedZoneDnsSecNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the DNS managed zone is marked as sensitive",
                [Contract.TypeNames.GcpExcessivePermissionGroupRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the group and if it's internal or external",
                [Contract.TypeNames.GcpExcessivePermissionServiceAccountRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the service account, if it's internal or external and based on other risks such as if it's connected to a public compute instance",
                [Contract.TypeNames.GcpExcessivePermissionUserRiskPolicyConfiguration]: "The severity is dynamically determined based on the excessive permissions of the user and if it's internal or external",
                [Contract.TypeNames.GcpFunctionsFunctionBuildDefaultServiceAccountRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Cloud function is marked as sensitive",
                [Contract.TypeNames.GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the principals that can access the environment variables and on the function's sensitivity",
                [Contract.TypeNames.GcpFunctionsFunctionPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the public permissions and whether the function is marked as sensitive",
                [Contract.TypeNames.GcpIamServiceAccountInactiveRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the service account is enabled",
                [Contract.TypeNames.GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the service account",
                [Contract.TypeNames.GcpIamServiceAccountUserManagedKeyExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions of the service account",
                [Contract.TypeNames.GcpInboundExternalComputeInstanceRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the VM instance is running or marked as sensitive, whether any management ports are exposed, and the permissions of any attached service account",
                [Contract.TypeNames.GcpKmsKeyRingKeyPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of resources the key encrypts and if it is marked as sensitive",
                [Contract.TypeNames.GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of resources the key encrypts and whether the key is marked as sensitive",
                [Contract.TypeNames.GcpKmsKeyRingPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the public permissions and whether the key ring is marked as sensitive",
                [Contract.TypeNames.GcpLoggingAuditLogTenantDefaultNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the audit log configuration and whether the project is marked as sensitive",
                [Contract.TypeNames.GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Vertex AI Workbench instance is marked as sensitive",
                [Contract.TypeNames.GcpNotebooksInstanceDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Vertex AI Workbench instance is marked as sensitive",
                [Contract.TypeNames.GcpNotebooksInstanceRootAccessEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Vertex AI Workbench instance is marked as sensitive",
                [Contract.TypeNames.GcpNotebooksInstanceSecureBootNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Vertex AI Workbench instance is marked as sensitive",
                [Contract.TypeNames.GcpNotebooksInstanceVirtualTpmNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Vertex AI Workbench instance is marked as sensitive",
                [Contract.TypeNames.GcpPermissionManagementRiskPolicyConfiguration]: "The severity is dynamically determined based on the scope and sensitivity of permissions, and the sensitivity of the impacted resources",
                [Contract.TypeNames.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions severity granted via the admin roles",
                [Contract.TypeNames.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions severity the service account can act as",
                [Contract.TypeNames.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the permissions severity the user can act as",
                [Contract.TypeNames.GcpPubSubSubscriptionPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the public permissions and whether the subscription is marked as sensitive",
                [Contract.TypeNames.GcpPubSubTopicPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the public permissions and whether the topic is marked as sensitive",
                [Contract.TypeNames.GcpResourceTagSecretExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the resource is marked as sensitive",
                [Contract.TypeNames.GcpSecretManagerSecretRotationTimeFrameRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the secret is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceBackupDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceHttpsOnlyDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the SQL instance's network exposure and if it is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the MySQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceMySqlLoadClientFileEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the MySQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlAuditDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlLogErrorVerbosityRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlLogMinLevelRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstancePostgreSqlStatementLogDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the PostgreSQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceSqlServerScriptExecutionEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceSqlServerUserOptionsEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the SQL instance is marked as sensitive",
                [Contract.TypeNames.GcpSqlInstanceWideRangeInboundRiskPolicyConfiguration]: "The severity is dynamically determined based on the number of IP addresses that can access your instance and whether the instance is marked as sensitive",
                [Contract.TypeNames.GcpStorageBucketLockedRetentionPolicyNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the bucket is marked as sensitive",
                [Contract.TypeNames.GcpStorageBucketPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the public permission and whether the bucket is marked as sensitive",
                [Contract.TypeNames.GcpStorageBucketUniformAccessControlDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the bucket is uniformly public or if it is marked as sensitive",
                [Contract.TypeNames.GcpTenantEntityCloudAssetServiceNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the project is marked as sensitive",
                [Contract.TypeNames.KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerBasicAuthenticationEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerInsecureListenerEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerInsecureTlsProtocolRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerServiceAccountLookupDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterApiServerTlsCertificateNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the severity of the role or cluster role, and the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterClusterAdminRoleBindingExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.KubernetesClusterControllerManagerInsecureListenerIpAddressRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterDefaultNamespaceUsedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.KubernetesClusterEndOfLifeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterEtcdPeerTlsCertificateNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterEtcdTlsCertificateNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterIdentityProviderAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Kubelet server debugging handlers are enabled, whether the Kubelet server port is open to the internet, the authorization mode, and the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Kubelet server debugging handlers are enabled, whether the Kubelet server port is open to the internet, whether anonymous requests are enabled, and the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletClientCertificateRotationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletHostnameOverrideUsedRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletInsecureTlsProtocolRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletManageIpTablesDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletReadOnlyPortEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Kubelet server port is open to the internet, and the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletServerCertificateRotationDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the Kubelet server port is open to the internet, and the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterKubeletTlsCertificateNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterNetworkPolicyUnsupportedRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskPolicyConfiguration]: "The severity is dynamically determined based on whether both the permissions and the ownership of the same configuration file are not restrictive, and the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesClusterPlatformEndOfLifeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster is marked as sensitive",
                [Contract.TypeNames.KubernetesClusterResourceEncryptionDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster",
                [Contract.TypeNames.KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the cluster or namespace is marked as sensitive",
                [Contract.TypeNames.KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskPolicyConfiguration]: "The severity is dynamically determined based on the existence of a Pod Security Standard policy and the sensitivity of the namespace and cluster",
                [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskPolicyConfiguration]: "The severity is dynamically determined based on the assigned capabilities, and the sensitivity of the cluster, namespace, and workloads",
                [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster, namespace, and workloads",
                [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster, namespace, and workloads",
                [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster, namespace, and workloads",
                [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster, namespace, and workloads",
                [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the cluster, namespace, and workloads",
                [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on ptrace capabilities, whether a Pod Security Standard policy is configured, and the sensitivity of the cluster, namespace and workload resources",
                [Contract.TypeNames.KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskPolicyConfiguration]: "The severity is dynamically determined based on the sensitivity of the workload, namespace and cluster",
                [Contract.TypeNames.OciBlockStorageBlockVolumeDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the volume is marked as sensitive",
                [Contract.TypeNames.OciBlockStorageBootVolumeDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the boot volume is marked as sensitive",
                [Contract.TypeNames.OciComputeInstanceMetadataServiceVersionRiskPolicyConfiguration]: "The severity is dynamically determined based on the instance status and whether the instance is marked as sensitive",
                [Contract.TypeNames.OciComputeInstanceSecureBootNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the instance status and whether the instance is marked as sensitive",
                [Contract.TypeNames.OciComputeInstanceVolumeEncryptionInTransitDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the instance status and whether the instance is marked as sensitive",
                [Contract.TypeNames.OciFssFileSystemDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the file system is marked as sensitive",
                [Contract.TypeNames.OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the file system is marked as sensitive",
                [Contract.TypeNames.OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the domain is marked as sensitive",
                [Contract.TypeNames.OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the domain is marked as sensitive",
                [Contract.TypeNames.OciIamDomainPasswordReusePolicyRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the domain is marked as sensitive",
                [Contract.TypeNames.OciIamUserApiKeyNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the user API key is marked as sensitive",
                [Contract.TypeNames.OciIamUserAuthTokenNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the user auth token is marked as sensitive",
                [Contract.TypeNames.OciIamUserDatabasePasswordNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the user database password is marked as sensitive",
                [Contract.TypeNames.OciIamUserOrganizationAdministratorApiKeyExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the API keys are marked as sensitive",
                [Contract.TypeNames.OciIamUserSecretKeyNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the user customer secret key is marked as sensitive",
                [Contract.TypeNames.OciKmsVaultKeyNotRotatedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the master encryption key or the vault is marked as sensitive",
                [Contract.TypeNames.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the network security group is marked as sensitive",
                [Contract.TypeNames.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the network security group is marked as sensitive",
                [Contract.TypeNames.OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the security list is marked as sensitive",
                [Contract.TypeNames.OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the security list is marked as sensitive",
                [Contract.TypeNames.OciNetworkingVcnFlowLogsNotEnabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the VCN or its subnets are marked as sensitive",
                [Contract.TypeNames.OciObjectStorageBucketDefaultEncryptionRiskPolicyConfiguration]: "The severity is dynamically determined based on the storage size of the bucket and whether the bucket is marked as sensitive",
                [Contract.TypeNames.OciObjectStorageBucketLogCategoryWriteNotExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the storage size and whether the bucket is marked as sensitive",
                [Contract.TypeNames.OciObjectStorageBucketObjectEventsDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on the storage size of the bucket and whether the bucket is marked as sensitive",
                [Contract.TypeNames.OciObjectStorageBucketObjectVersioningDisabledRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the bucket is marked as sensitive",
                [Contract.TypeNames.OciObjectStorageBucketPublicAccessExistsRiskPolicyConfiguration]: "The severity is dynamically determined based on the public permissions severity of the bucket and whether the bucket is marked as sensitive",
                [Contract.TypeNames.VirtualMachineOperatingSystemEndOfLifeRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the virtual machines are marked as sensitive, whether they are publicly accessible, and their permission severity",
                [Contract.TypeNames.VirtualMachineOperatingSystemUnpatchedRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the virtual machines are marked as sensitive, whether they are publicly accessible, and their permission severity",
                [Contract.TypeNames.VirtualMachineVulnerabilityRiskPolicyConfiguration]: "The severity is dynamically determined based on whether the virtual machines are marked as sensitive, whether they are publicly accessible, and their permission severity"
            }));

    return (riskPolicyConfigurationTypeName: string) =>
        riskPolicyConfigurationTypeName in localization
            ? localization.translate(riskPolicyConfigurationTypeName)
            : undefined;
}