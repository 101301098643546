import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetGcpDnsManagedZoneRiskContext } from "../contexts/useGetGcpDnsManagedZoneRiskContext";

export function useGcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskDefinition(riskModel: Contract.RiskModel) {
    const dnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskModel = riskModel as Contract.GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskModel;
    const risk = riskModel.risk as Contract.GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRisk;
    const managedZoneModel = entityModelStore.useGet(risk.entityId) as Contract.GcpDnsManagedZoneModel;

    const getGcpDnsManagedZoneRiskContext = useGetGcpDnsManagedZoneRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskDefinition",
            () => ({
                description: "{{managedZone}} is using RSASHA1 for the zone-signing key in DNSSEC",
                sections: {
                    resolution: {
                        step1: "Updating the key signing algorithm via GCP console is not currently supported.",
                        step2: "DNSSEC must be turned off and re-enabled with different settings. Run the following command in GCP cloud shell:\ngcloud dns managed-zones update {{managedZoneName}} --dnssec-state off",
                        step3: {
                            documentationLink: "GCP documentation",
                            text: "When providing any key-signing key (KSK) or zone-signing key (ZSK) algorithms or key lengths, you must provide all of them. Avoid using different algorithms for the KSK and ZSK in your managed zones. It reduces compatibility and may compromise security. See {{signingKeyAlgorithmLink}} on supported algorithms and key lengths."
                        },
                        step4: "Run the following command:\ngcloud dns managed-zones update {{managedZoneName}} --dnssec-state on --ksk-algorithm KSK_ALGORITHM --ksk-key-length KSK_KEY_LENGTH --zsk-algorithm ZSK_ALGORITHM --zsk-key-length ZSK_KEY_LENGTH --denial-of-existence DENIAL_OF_EXISTENCE"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            managedZone:
                <Entity
                    entityIdOrModel={managedZoneModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({
                managedZoneName: (managedZoneModel.entity as Contract.GcpDnsManagedZone).name!
            }),
            localization.sections.resolution.step3.text({
                signingKeyAlgorithmLink:
                    <Link
                        urlOrGetUrl={dnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskModel.signingKeyAlgorithmUrl}
                        variant="external">
                        {localization.sections.resolution.step3.documentationLink()}
                    </Link>
            }),
            localization.sections.resolution.step4({
                managedZoneName: (managedZoneModel.entity as Contract.GcpDnsManagedZone).name!
            })
        ],
        riskModel,
        () => {
            const managedZoneRiskContext = getGcpDnsManagedZoneRiskContext(managedZoneModel);
            return [
                managedZoneRiskContext.generalInformation,
                managedZoneRiskContext.publicNetworkAccess,
                managedZoneRiskContext.loggingEnabled,
                managedZoneRiskContext.resourceRecordSets,
                managedZoneRiskContext.sensitive,
                managedZoneRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}