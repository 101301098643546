import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, RiskType } from "../../../../../common";
import { useRiskController } from "../hooks";
import { ExclusionHelper } from "../utilities";
import { ExcludeDialog } from "./ExcludeDialog";


type IgnoreDialogProps = {
    edit?: boolean;
    onClose: (canceled?: boolean) => void;
    riskIdsOrModel: string[] | Contract.RiskModel;
    riskType: RiskType;
};

export function IgnoreDialog({ edit, onClose, riskIdsOrModel, riskType }: IgnoreDialogProps) {
    const [riskIds, riskModel] =
        useMemo(
            () =>
                _.isArray(riskIdsOrModel)
                    ? [riskIdsOrModel, undefined]
                    : [[riskIdsOrModel.risk.id], riskIdsOrModel],
            [riskIdsOrModel]);

    const { updateIgnoreRiskStatus } = useRiskController(riskType);
    async function updateRiskStatusAsync(reason: Contract.RiskPolicyConfigurationExclusionDataReason, expirationDate?: string, message?: string) {
        await updateIgnoreRiskStatus?.(message, riskIds, expirationDate, reason);
        onClose();
    }

    const localization =
        useLocalization(
            "views.customer.risks.ignoreDialog",
            () => ({
                action: "Ignore",
                subtitle: [
                    "Exclude resources from the policy and change the status of the finding to Ignored. You can manage resource exclusions from the Policies page.",
                    "Exclude resources from the policy and change the status of the findings to Ignored. You can manage resource exclusions from the Policies page."
                ],
                title:
                    {
                        add: [
                            "Ignore Finding",
                            "Ignore {{count | NumberFormatter.humanize}} Findings"
                        ],
                        edit: [
                            "Edit Ignore Finding",
                            "Edit Ignore {{count | NumberFormatter.humanize}} Findings"
                        ]
                    }
            }));

    return (
        <ExcludeDialog
            action={localization.action()}
            description={localization.subtitle(_.size(riskIds))}
            exclusionExpirationDate={
                edit && !_.isNil(riskModel)
                    ? riskModel.risk.ignoreExpirationDate
                    : undefined}
            exclusionReason={
                edit && !_.isNil(riskModel)
                    ? ExclusionHelper.getRiskPolicyConfigurationExclusionDataReason(riskModel.risk.subStatus)
                    : undefined}
            executeAsync={updateRiskStatusAsync}
            title={
                edit
                    ? localization.title.edit(_.size(riskIds))
                    : localization.title.add(_.size(riskIds))}
            onClose={onClose}/>);
}