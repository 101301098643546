﻿import { DataTableColumn, DeferredFilter, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAzurePostgreSqlServerAuthenticationTypeTranslator, useAzurePostgreSqlServerStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";

export function useAzurePostgreSqlServerDefinition(definitionData: DefinitionData) {
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);

    const postgreSqlServerAuthenticationTypeTranslator = useAzurePostgreSqlServerAuthenticationTypeTranslator();
    const postgreSqlServerStatusTranslator = useAzurePostgreSqlServerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzurePostgreSqlServerDefinition",
            () => ({
                columns: {
                    administratorUserName: "Server Admin",
                    authenticationType: "Authentication Method",
                    endpoint: "Server",
                    status: "Status",
                    typeName: {
                        title: "Server Type",
                        [Contract.TypeNames.AzurePostgreSqlFlexibleServer]: "Flexible",
                        [Contract.TypeNames.AzurePostgreSqlSingleServer]: "Single"
                    }
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzurePostgreSqlServerModel) => ({
                            [localization.columns.typeName.title()]: localization.columns.typeName.translate(item.entity.typeName)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                title={localization.columns.typeName.title()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.typeNameItems.emptyValue }}
                                        placeholder={localization.columns.typeName.title()}>
                                        {_.map(
                                            filters.typeNameItems.items,
                                            typeName =>
                                                <ValuesFilterItem
                                                    key={typeName}
                                                    title={localization.columns.typeName.translate(typeName)}
                                                    value={typeName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzurePostgreSqlServerTypeName}
                itemProperty={(item: Contract.AzurePostgreSqlServerModel) => localization.columns.typeName.translate(item.entity.typeName)}
                key={Contract.EntityModelProperty.AzurePostgreSqlServerTypeName}
                title={localization.columns.typeName.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzurePostgreSqlServerModel) => ({
                            [localization.columns.administratorUserName()]: (item.entity as Contract.AzurePostgreSqlServer).administratorUserName ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzurePostgreSqlServerAdministratorUserName)}
                                placeholder={localization.columns.administratorUserName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzurePostgreSqlServerAdministratorUserName}
                key={Contract.EntityModelProperty.AzurePostgreSqlServerAdministratorUserName}
                render={
                    optionalTableCell<Contract.AzureResourceGroupResourceModel>(
                        item =>
                            (item.entity as Contract.AzurePostgreSqlServer).administratorUserName)}
                title={localization.columns.administratorUserName()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzurePostgreSqlServerModel) => ({
                            [localization.columns.endpoint()]: (item.entity as Contract.AzurePostgreSqlServer).endpoint
                        })
                }}
                id="endpoint"
                key="endpoint"/>,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzurePostgreSqlServerModel) => ({
                            [localization.columns.authenticationType()]: postgreSqlServerAuthenticationTypeTranslator((item.entity as Contract.AzurePostgreSqlServer).authenticationType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzurePostgreSqlServerAuthenticationType}
                                enumTypeTranslator={postgreSqlServerAuthenticationTypeTranslator}
                                placeholder={localization.columns.authenticationType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzurePostgreSqlServerAuthenticationType}
                itemProperty={(item: Contract.AzureResourceGroupResourceModel) => postgreSqlServerAuthenticationTypeTranslator((item.entity as Contract.AzurePostgreSqlServer).authenticationType)}
                key={Contract.EntityModelProperty.AzurePostgreSqlServerAuthenticationType}
                title={localization.columns.authenticationType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzurePostgreSqlServerModel) => ({
                            [localization.columns.status()]: postgreSqlServerStatusTranslator((item.entity as Contract.AzurePostgreSqlServer).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzurePostgreSqlServerStatus}
                                enumTypeTranslator={postgreSqlServerStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzurePostgreSqlServerStatus}
                itemProperty={(item: Contract.AzureResourceGroupResourceModel) => postgreSqlServerStatusTranslator((item.entity as Contract.AzurePostgreSqlServer).status)}
                key={Contract.EntityModelProperty.AzurePostgreSqlServerStatus}
                title={localization.columns.status()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzurePostgreSqlServerRequest(
                new Contract.EntityControllerGetEntityModelPageAzurePostgreSqlServerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzurePostgreSqlServerAdministratorUserName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzurePostgreSqlServerAuthenticationType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzurePostgreSqlServerStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzurePostgreSqlServerTypeName])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}