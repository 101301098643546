import { Link, SuccessIcon, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useGitLabServerContext } from "../../..";
import { useAddOrEditContext } from "..";
import { Contract, CustomerConsoleAppUrlHelper, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function FinishItem() {
    const { useNextEffect } = useOrderedWizardContext();
    const { addOrEditOpen } = useGitLabServerContext();
    const { serverModel } = useAddOrEditContext();

    useNextEffect(
        async () => {
            if (serverModel) {
                await scopeSystemEntityModelStore.notify(serverModel);
            }

            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.gitLabServer.addOrEditGitLabServer.finishItem",
            () => ({
                addOrganization: {
                    link: "Add a GitLab Group",
                    title: "Next step: {{addOrganizationWizardLink}}"
                },
                title: {
                    add: "Your GitLab Self-Managed Server was added successfully!",
                    edit: "Your GitLab Self-Managed Server was changed successfully!"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{
                margin: theme.spacing(10),
                width: theme.spacing(40)
            }}>
            <SuccessIcon
                sx={{
                    color: theme.palette.success.main,
                    fontSize: "60px"
                }}/>
            <Typography
                align="center"
                sx={{ fontWeight: 400 }}
                variant="h2">
                {_.isBoolean(addOrEditOpen)
                    ? localization.title.add()
                    : localization.title.edit()}
            </Typography>
            {_.isBoolean(addOrEditOpen) &&
                <Typography
                    align="center"
                    sx={{ fontWeight: 300 }}
                    variant="h4">
                    {localization.addOrganization.title({
                        addOrganizationWizardLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCodeOrganizationsRelativeUrl(Contract.CodeTenantType.GitLab, true)}
                                variant="external">
                                {localization.addOrganization.link()}
                            </Link>
                    })}
                </Typography>}
        </Stack>);
}