import { Action0 } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Entity, useTheme } from "..";
import { AwsResourcePermitterIcon, AwsResourcePermitterType, AzurePermitterIcon, AzureResourcePermitterType, useAwsResourcePermitterTranslator, useAzurePermitterTranslator } from "../../tenants";

type PermitterProps = {
    onClick?: Action0;
    permitterEntityIdOrPermitterType: string | AwsResourcePermitterType | AzureResourcePermitterType;
    variant: "text" | "iconText" | "iconTextTenant" | "iconTextTypeTenant";
};

export function Permitter({ onClick, permitterEntityIdOrPermitterType, variant }: PermitterProps) {
    return (
        _.isString(permitterEntityIdOrPermitterType)
            ? <Entity
                entityIdOrModel={permitterEntityIdOrPermitterType}
                variant={variant}
                onClick={onClick}/>
            : <ResourceTypePermitterType
                permitterType={permitterEntityIdOrPermitterType}
                variant={variant}/>);
}

type ResourceTypePermitterTypeProps = {
    permitterType: AwsResourcePermitterType | AzureResourcePermitterType;
    variant: "text" | "iconText" | "iconTextTenant" | "iconTextTypeTenant";
};

function ResourceTypePermitterType({ permitterType, variant }: ResourceTypePermitterTypeProps) {
    const awsResourcePermitterTranslator = useAwsResourcePermitterTranslator();
    const azurePermitterTranslator = useAzurePermitterTranslator();

    const translatedPermitter =
        permitterType instanceof AwsResourcePermitterType
            ? awsResourcePermitterTranslator(permitterType)
            : azurePermitterTranslator(permitterType);

    const PermitterIcon =
        permitterType instanceof AwsResourcePermitterType
            ? <AwsResourcePermitterIcon resourceTypeName={permitterType.getResourceTypeName()}/>
            : <AzurePermitterIcon permitterType={permitterType.resourcePermitterType}/>;

    const theme = useTheme();
    return (
        variant === "text"
            ? <Typography
                component="span"
                sx={{
                    color: theme.palette.text.primary,
                    fontSize: "unset"
                }}>
                {translatedPermitter}
            </Typography>
            : <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{
                    color: theme.palette.text.primary,
                    fontSize: "24px"
                }}>
                {PermitterIcon}
                <Typography>
                    {translatedPermitter}
                </Typography>
            </Stack>);
}