import { ActionMenuItem, DeleteIcon, DuplicateIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { ConfigurationController, Contract, Scope, ScopeHelper, scopeSystemEntityModelStore, TenantHelper, useGetSelectedScopeHasData, useScopeNavigationViewContext, useTheme } from "../../../../../../../common";
import { useEligibilitiesContext, useSetEligibilitiesContext } from "../../../PermissionEligibilities";
import { awsSsoPermissionSetNamePrefixMaxLength } from "../../AddOrEdit/hooks/useDefinition/hooks/useAwsDefinition/utilities";

type ActionsCellProps = {
    activePrincipalTenantModels: Contract.TenantModel[];
    permissionEligibilityModel: Contract.PermissionEligibilityModel;
    scopeActiveTenantModels: Contract.TenantModel[];
    scopeScopeIds: Set<string>;
};

export function ActionsCell({ activePrincipalTenantModels, permissionEligibilityModel, scopeActiveTenantModels, scopeScopeIds }: ActionsCellProps) {
    const { triggerPermissionEligibilityChange } = useEligibilitiesContext();
    const setEligibilitiesContext = useSetEligibilitiesContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const getSelectedScopeHasData = useGetSelectedScopeHasData();
    const tenantType = ScopeHelper.getTenantType(scopeNodeModel)!;
    const [deletePermissionEligibilityExecuting, setDeletePermissionEligibilityExecuting] = useState(false);
    const [deletePermissionEligibilityError, setDeletePermissionEligibilityError] = useState(false);

    const principalTenantModel =
        useMemo(
            () =>
                _.find(
                    activePrincipalTenantModels,
                    activePrincipalTenantModel => activePrincipalTenantModel.configuration.id === (permissionEligibilityModel.configuration as Contract.PermissionEligibilityConfiguration).principalTenantId),
            [activePrincipalTenantModels, permissionEligibilityModel]);

    async function deletePermissionEligibility() {
        setDeletePermissionEligibilityExecuting(true);
        setDeletePermissionEligibilityError(false);
        try {
            await ConfigurationController.deletePermissionEligibility(new Contract.ConfigurationControllerDeletePermissionEligibilityRequest(permissionEligibilityModel.configuration.id));

            await scopeSystemEntityModelStore.notify(permissionEligibilityModel.configuration.id);
            await triggerPermissionEligibilityChange();
        } catch {
            setDeletePermissionEligibilityError(true);
        }
        setDeletePermissionEligibilityExecuting(false);
    }

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete eligibility",
                        prompt: "Are you sure you want to delete eligibility **{{permissionEligibilityName}}**?",
                        title: "Delete"
                    },
                    duplicate: {
                        copy: "Copy of {{name}}",
                        copyId: "copy-{{id}}",
                        title: "Duplicate"
                    },
                    edit: "Edit"
                },
                inherited: "Inherited from {{scope}}"
            }));

    const editDisabled =
        useMemo(
            () =>
                deletePermissionEligibilityExecuting ||
                _.isNil(principalTenantModel) ||
                !getSelectedScopeHasData([principalTenantModel]) ||
                TenantHelper.isCloudProviderTenantType(tenantType) &&
                _.every(
                    scopeActiveTenantModels,
                    scopeActiveTenantModel => !(scopeActiveTenantModel as Contract.CloudProviderTenantModel).state.permissionManagementEnabled) ||
                TenantHelper.isIdentityProviderTenantType(tenantType) &&
                !(principalTenantModel.state as Contract.IdentityProviderTenantState).permissionManagementEnabled,
            [deletePermissionEligibilityExecuting, principalTenantModel, scopeActiveTenantModels, tenantType]);

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deletePermissionEligibilityExecuting &&
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>}
            {deletePermissionEligibilityError &&
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>}
            {scopeScopeIds.has(permissionEligibilityModel.configuration.scopeId)
                ? <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setEligibilitiesContext(
                                        eligibilitiesContext => ({
                                            ...eligibilitiesContext,
                                            addOrEditOpen: permissionEligibilityModel,
                                            editMode: true
                                        })),
                                localization.actions.edit(),
                                {
                                    disabled: editDisabled,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () =>
                                    setEligibilitiesContext(
                                        eligibilitiesContext => ({
                                            ...eligibilitiesContext,
                                            addOrEditOpen: {
                                                ...permissionEligibilityModel,
                                                configuration: {
                                                    ...permissionEligibilityModel.configuration,
                                                    name: localization.actions.duplicate.copy({ name: permissionEligibilityModel.configuration.name }),
                                                    permissionSetNamePrefix:
                                                        localization.actions.duplicate.copyId({ id: (permissionEligibilityModel.configuration as Contract.AwsSsoPermissionSetAssignmentEligibilityConfiguration).permissionSetNamePrefix ?? "" }).
                                                            substring(0, awsSsoPermissionSetNamePrefixMaxLength)
                                                }
                                            },
                                            editMode: false
                                        })),
                                localization.actions.duplicate.title(),
                                {
                                    disabled: editDisabled,
                                    icon: <DuplicateIcon/>
                                }),
                            new ActionMenuItem(
                                () => deletePermissionEligibility(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ permissionEligibilityName: permissionEligibilityModel.configuration.name })
                                    },
                                    disabled: deletePermissionEligibilityExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>
                : <Typography noWrap={true}>
                    {localization.inherited({
                        scope:
                            <Scope
                                scopeId={permissionEligibilityModel.configuration.scopeId}
                                sx={{ color: theme.palette.text.primary }}
                                variant="text"/>
                    })}
                </Typography>}
        </Stack>);
}