import { VerticalFillGrid } from "@infrastructure";
import { Box, Stack, SxProps } from "@mui/material";
import React, { ReactNode } from "react";
import { useTheme } from "../..";
import { ElementClass } from "./ProfileLayout.element";

type ProfileLayoutProps = {
    children?: ReactNode;
    sx?: SxProps;
    topBarElement: ReactNode;
    topBarSx?: SxProps;
};

export function ProfileLayout({ children, sx, topBarElement, topBarSx }: ProfileLayoutProps) {
    const theme = useTheme();
    return (
        <VerticalFillGrid>
            <Stack
                spacing={1.5}
                sx={{
                    padding: theme.spacing(0, 2, 1.5),
                    ...topBarSx
                }}>
                {topBarElement}
            </Stack>
            <Box
                className={ElementClass.profileLayoutContainer}
                sx={{
                    borderTop: theme.border.primary,
                    height: "100%",
                    overflowY: "auto",
                    width: "100%",
                    ...sx
                }}>
                {children}
            </Box>
        </VerticalFillGrid>);
}