import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColoredGciIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M7.21878 12.0004C7.21878 11.1086 7.46449 10.2732 7.89136 9.55785V6.52356H4.85707C3.65279 8.0876 3 9.99375 3 12.0004C3 14.0071 3.65279 15.9132 4.85707 17.4773H7.89136V14.443C7.46449 13.7277 7.21878 12.8922 7.21878 12.0004Z"
                fill="#FBBD00"/>
            <path
                d="M12.0001 16.781L9.89068 18.8904L12.0001 20.9997C14.0068 20.9997 15.9129 20.347 17.4769 19.1427V16.1116H14.4459C13.7243 16.54 12.8853 16.781 12.0001 16.781Z"
                fill="#0F9D58"/>
            <path
                d="M7.89136 14.443L4.85707 17.4773C5.0955 17.7869 5.35433 18.0831 5.63526 18.364C7.33515 20.0639 9.59606 20.9997 12.0001 20.9997V16.781C10.2555 16.781 8.7264 15.8423 7.89136 14.443Z"
                fill="#31AA52"/>
            <path
                d="M21.0001 11.9992C21.0001 11.4517 20.9506 10.9031 20.8528 10.3688L20.7736 9.93638H12.0001V14.1552H16.2699C15.8553 14.98 15.2178 15.6533 14.4459 16.1116L17.4769 19.1427C17.7866 18.9042 18.0831 18.6442 18.3641 18.3633C20.0639 16.6633 21.0001 14.4032 21.0001 11.9992Z"
                fill="#3C79E6"/>
            <path
                d="M15.381 8.61917L15.7538 8.99204L18.737 6.00894L18.3641 5.63607C16.6642 3.93618 14.4041 3 12.0001 3L9.89068 5.10939L12.0001 7.21878C13.2772 7.21878 14.4779 7.7161 15.381 8.61917Z"
                fill="#CF2D48"/>
            <path
                d="M12.0001 7.21878V3C9.59606 3 7.33606 3.93618 5.63614 5.63603C5.3552 5.91697 5.0955 6.21387 4.85707 6.52356L7.89136 9.55785C8.72643 8.15851 10.2555 7.21878 12.0001 7.21878Z"
                fill="#EB4132"/>
        </SvgIcon>);
}