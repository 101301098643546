import { Dictionary } from "lodash";
import { Contract, SystemController } from "..";

export class GeographyHelper {
    private static _consoleAppGeographyTypeToUrlMap: Dictionary<string>;
    private static _deploymentAadPartitionType: Contract.AadPartitionType;
    private static _deploymentAwsPartitionType: Contract.AwsPartitionType;
    private static _deploymentNameToConsoleUrlMap: Dictionary<string>;
    private static _deploymentPartitionType: Contract.PartitionType;
    private static _globalConsoleAppUrl: string;

    public static get consoleAppGeographyTypeToUrlMap() {
        return GeographyHelper._consoleAppGeographyTypeToUrlMap;
    }

    public static get deploymentAadPartitionType() {
        return GeographyHelper._deploymentAadPartitionType;
    }

    public static get deploymentAwsPartitionType() {
        return GeographyHelper._deploymentAwsPartitionType;
    }

    public static get deploymentNameToConsoleUrlMap() {
        return GeographyHelper._deploymentNameToConsoleUrlMap;
    }

    public static get deploymentPartitionType() {
        return GeographyHelper._deploymentPartitionType;
    }

    public static get globalConsoleAppUrl() {
        return GeographyHelper._globalConsoleAppUrl;
    }

    public static async initialize() {
        const { consoleAppGeographyTypeToUrlMap, deploymentAadPartitionType, deploymentAwsPartitionType, deploymentNameToConsoleUrlMap, deploymentPartitionType, globalConsoleAppUrl } = await SystemController.getGeographyInfo();

        GeographyHelper._consoleAppGeographyTypeToUrlMap = consoleAppGeographyTypeToUrlMap;
        GeographyHelper._deploymentAadPartitionType = deploymentAadPartitionType;
        GeographyHelper._deploymentAwsPartitionType = deploymentAwsPartitionType;
        GeographyHelper._deploymentNameToConsoleUrlMap = deploymentNameToConsoleUrlMap;
        GeographyHelper._deploymentPartitionType = deploymentPartitionType;
        GeographyHelper._globalConsoleAppUrl = globalConsoleAppUrl;
    }
}