import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCommonVirtualMachineDiskResourceInfoItemElements } from "../useCommonVirtualMachineDiskResourceInfoItemElements";
import { useCommonVirtualMachineResourceInfoItemElements } from "../useCommonVirtualMachineResourceInfoItemElements";
import { useOpDefaultResourceInfoItemElements } from "./useOpDefaultResourceInfoItemElements";

export function useOpVirtualMachineDefinition(resourceModel: Contract.OpResourceModel, options?: DefinitionOptions) {
    const commonVirtualMachineDiskResourceInfoItemElements = useCommonVirtualMachineDiskResourceInfoItemElements(resourceModel);
    const commonVirtualMachineResourceInfoItemElements = useCommonVirtualMachineResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOpDefaultResourceInfoItemElements(resourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemDisplayNameInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemTypeInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.vulnerabilitiesInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.workloadAnalysisStatusInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.scanTimeInfoElement,
                    commonVirtualMachineResourceInfoItemElements.scanSourceInfoElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}