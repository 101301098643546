﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useAwsEc2VpcPeeringConnectionStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";

export function useAwsEc2VpcPeeringConnectionDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const vpcPeeringConnection = resourceModel.entity as Contract.AwsEc2VpcPeeringConnection;
    const vpcPeeringConnectionModel = resourceModel as Contract.AwsEc2VpcPeeringConnectionModel;

    const ec2VpcPeeringConnectionStatusTranslator = useAwsEc2VpcPeeringConnectionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2VpcPeeringConnectionDefinition",
            () => ({
                info: {
                    items: {
                        accepterVpcId: "Accepter VPC",
                        accepterVpcSubnets: "Accepter CIDRs",
                        requesterVpcId: "Requester VPC",
                        requesterVpcSubnets: "Requester CIDRs",
                        respectiveConnection: "Associated VPC Peering Connection",
                        routeTableId: "Associated Route Tables",
                        routeTableSubnetId: "Associated Subnets",
                        status: "Status"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel?.respectiveVpcPeeringConnectionId}
                        entityTypeName={Contract.TypeNames.AwsEc2VpcPeeringConnection}
                        key="respectiveConnection"
                        title={localization.info.items.respectiveConnection()}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={ec2VpcPeeringConnectionStatusTranslator(vpcPeeringConnection.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel.requesterVpcIdReference}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="requesterVpcId"
                        title={localization.info.items.requesterVpcId()}/>,
                    <VerticalTopItemsInfoItem
                        items={vpcPeeringConnection.requesterVpcSubnets}
                        key="requesterVpcSubnet"
                        title={localization.info.items.requesterVpcSubnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel.accepterVpcIdReference}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="accepterVpcId"
                        title={localization.info.items.accepterVpcId()}/>,
                    <VerticalTopItemsInfoItem
                        items={vpcPeeringConnection.accepterVpcSubnets}
                        key="accepterVpcSubnets"
                        title={localization.info.items.accepterVpcSubnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel.routeTableIds}
                        entityTypeName={Contract.TypeNames.AwsEc2RouteTable}
                        key="routeTableId"
                        title={localization.info.items.routeTableId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcPeeringConnectionModel.routeTableSubnetIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                        key="routeTableSubnetId"
                        title={localization.info.items.routeTableSubnetId()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}