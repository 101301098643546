import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonIamResourceInfoItemElements, useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useOciIamDomainLicenseTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciIamDomainDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const domainModel = resourceModel as Contract.OciIamDomainModel;
    const commonIamResourceInfoItemElements = useOciCommonIamResourceInfoItemElements(domainModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const domain = domainModel.entity as Contract.OciIamDomain;

    const domainLicenseTypeTranslator = useOciIamDomainLicenseTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciIamDomainDefinition",
            () => ({
                info: {
                    items: {
                        consoleLoginEnabled: {
                            false: "Off",
                            title: "Visible on login",
                            true: "On"
                        },
                        defaultDomain: {
                            false: "Secondary",
                            title: "Type",
                            true: "Default"
                        },
                        licenseType: "License Type",
                        replicationRegionSystemName: "Replication",
                        url: "URL"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="defaultDomain"
                        location="all"
                        title={localization.info.items.defaultDomain.title()}
                        value={
                            domain.defaultDomain
                                ? localization.info.items.defaultDomain.true()
                                : localization.info.items.defaultDomain.false()}/>,
                    <InfoItem
                        key="licenseType"
                        location="all"
                        title={localization.info.items.licenseType()}
                        value={domainLicenseTypeTranslator(domain.licenseType)}/>,
                    commonIamResourceInfoItemElements.descriptionInfoItemElement,
                    <VerticalTopItemsInfoItem
                        items={domain.replicationRegionSystemNames}
                        key="replicationRegionSystemName"
                        title={localization.info.items.replicationRegionSystemName()}/>,
                    <InfoItem
                        key="consoleLoginEnabled"
                        title={localization.info.items.consoleLoginEnabled.title()}
                        value={
                            domain.consoleLoginEnabled
                                ? localization.info.items.consoleLoginEnabled.true()
                                : localization.info.items.consoleLoginEnabled.false()}/>,
                    <InfoItem
                        key="url"
                        location="all"
                        title={localization.info.items.url()}
                        value={
                            <Link
                                urlOrGetUrl={domain.url}
                                variant="external"/>}/>,
                    commonIamResourceInfoItemElements.enabledInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}