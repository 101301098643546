import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function
useGcpCreateRoleBindingChangeDefinition(change: Contract.GcpChange, entityLinkDisabled?: boolean) {
    const createRoleBindingChange = change as Contract.GcpCreateRoleBindingChange;
    const roleBindingScopeResource = entityModelStore.useGet(createRoleBindingChange.scopeResourceId).entity as Contract.GcpScopeResource;

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useGcpDefinition.hooks.useGcpCreateRoleBindingChangeDefinition",
            () => ({
                title: "Add IAM binding with {{role}} assigned to {{scopeResource}}"
            }));

    return {
        title:
            localization.title({
                role:
                    <Entity
                        entityIdOrModel={createRoleBindingChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>,
                scopeResource:
                    <Entity
                        entityIdOrModel={roleBindingScopeResource.id}
                        entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}