import { DataTableColumn, optionalTableCell, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, InlineEntities, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureCommonEncryptedResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureCommonEncryptedResourceDefinition",
            () => ({
                columns: {
                    encryptionExists: {
                        false: "Disabled",
                        title: "Encryption",
                        true: "Enabled"
                    },
                    vaultKeyIdReferences: "Encryption Key"
                }
            }));

    function getEncryptionExistsTranslation(encryptedResourceModel: Contract.AzureResourceModel) {
        return (_.as<Contract.IAzureEncryptedResource>(encryptedResourceModel.entity)).encryptionExists
            ? localization.columns.encryptionExists.true()
            : localization.columns.encryptionExists.false();
    }

    return {
        columns: {
            encryptionExistsColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AzureResourceModel) => ({
                                [localization.columns.encryptionExists.title()]: getEncryptionExistsTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.encryptionExists.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.encryptionExists.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.encryptionExists.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureEncryptedResourceEncryptionExists}
                    itemProperty={getEncryptionExistsTranslation}
                    key={Contract.EntityModelProperty.AzureEncryptedResourceEncryptionExists}
                    title={localization.columns.encryptionExists.title()}/>,
            vaultKeyColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureResourceModel>(
                            Contract.TypeNames.AzureKeyVaultVaultKey,
                            item => _.as<Contract.IAzureEncryptedResourceModel>(item).vaultKeyIdReferences,
                            localization.columns.vaultKeyIdReferences())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys)}
                                    placeholder={localization.columns.vaultKeyIdReferences()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys}
                    key={Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys}
                    render={
                        optionalTableCell<Contract.IAzureEncryptedResourceModel>(
                            item =>
                                _.isEmpty(item.vaultKeyIdReferences)
                                    ? undefined
                                    : <InlineEntities
                                        entityIdsOrModels={item.vaultKeyIdReferences}
                                        entityTypeName={Contract.TypeNames.AzureKeyVaultVaultKey}
                                        entityVariant="iconText"
                                        variant="itemPlusItemCount"/>)}
                    title={localization.columns.vaultKeyIdReferences()}/>
        }
    };
}