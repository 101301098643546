import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { Events } from "../../../../../../../../../Events";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsSecretsManagerSecretManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSecretsManagerSecretManagementRisk;
    const secretModel = entityModelStore.useGet(_.head(risk.resourceIds)!);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsSecretsManagerSecretManagementRiskDefinition",
            () => ({
                violations: "{{secret}} policy was modified"
            }));
    const translatedViolations =
        localization.violations({
            secret:
                <Entity
                    entityIdOrModel={secretModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <Events riskId={risk.id}/>);
}