import { Store } from "@infrastructure";
import { ConfigurationController, Contract } from "..";

export const reportDefinitionConfigurationStore =
    new Store<Contract.ReportDefinitionConfiguration, never, never>(
        reportDefinitionConfiguration => reportDefinitionConfiguration.id,
        {
            getAll:
                async () => {
                    const { reportDefinitionConfigurations } = await ConfigurationController.getReportDefinitions();
                    return reportDefinitionConfigurations;
                }
        });