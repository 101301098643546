import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React from "react";

export function DisabledIndeterminateIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <rect
                fill={theme.palette.text.primary}
                height="15"
                opacity="0.7"
                rx="1.5"
                stroke="#9E9E9E"
                width="15"
                x="4.5"
                y="4.5"/>
            <rect
                height="8"
                rx="1"
                width="8"
                x="8"
                y="8"/>
        </SvgIcon>);
}