﻿import { Link } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";

type VulnerabilityResolutionVersionProps = {
    resolutionVersion: string;
};

export function VulnerabilityResolutionVersion({ resolutionVersion }: VulnerabilityResolutionVersionProps) {
    return /^KB\d{6,}$/.test(resolutionVersion)
        ? <Link
            urlOrGetUrl={`https://support.microsoft.com/help/${resolutionVersion.substring(2)}`}
            variant="external">
            {resolutionVersion}
        </Link>
        : <Typography component="span">{resolutionVersion}</Typography>;
}