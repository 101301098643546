import { useSetToggleFilterConnectorContext, useToggleFilterConnectorContext } from "@infrastructure";
import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Tooltip } from "../../Tooltip";

type ValuesToggleFilterProps = {
    defaultValue: string;
    items: ValuesToggleFilterItem[];
};

export function ValuesToggleFilter({ defaultValue, items }: ValuesToggleFilterProps) {
    const { filter: selectedValue } = useToggleFilterConnectorContext();
    const setToggleFilterConnectorContext = useSetToggleFilterConnectorContext();
    return (
        <ToggleButtonGroup
            exclusive={true}
            value={selectedValue ?? defaultValue}
            onChange={
                (event, value) => {
                    if (value !== null) {
                        setToggleFilterConnectorContext({ filter: value });
                    }
                }}>
            {_.map(
                items,
                item =>
                    <ToggleButton
                        key={item.value}
                        value={item.value}>
                        {!_.isNil(item.element)
                            ? <Tooltip titleOrGetTitle={item.name}>
                                <Box>
                                    {item.element}
                                </Box>
                            </Tooltip>
                            : <Typography>
                                {item.name}
                            </Typography>}
                    </ToggleButton>)}
        </ToggleButtonGroup>);
}

export class ValuesToggleFilterItem {
    constructor(
        public name: string,
        public value: string,
        public element?: ReactNode) {
    }
}