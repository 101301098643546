import { FormLayout, Message, Optional, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Button, CircularProgress, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _, { Function2 } from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, ScopeHelper, TypeHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../common";


type AddExclusionTagsProps = {
    addExclusionTag: Function2<string, Optional<string>, Promise<void>>;
    addExclusionTagError: boolean;
    addExclusionTagExecuting: boolean;
    resourceTypeLocalization: { resourceTypeCaps: string; resourceTypeNoCaps: string };
};

export function AddExclusionTags({ addExclusionTag, addExclusionTagError, addExclusionTagExecuting, resourceTypeLocalization }: AddExclusionTagsProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [exclusionTagKey, setExclusionTagKey] = useState("");
    const [exclusionTagValue, setExclusionTagValue] = useState<Optional<string>>(undefined);

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.exclusionTags.addExclusionTags",
            () => ({
                actions: {
                    add: {
                        error: "Failed to add the {{resourceType}} exclusion tag",
                        title: "Add"
                    }
                },
                fields: {
                    key: {
                        emptyError: {
                            required: "Key cannot be empty"
                        },
                        invalidValue: {
                            required: "Tag key contain only lowercase letters, numeric characters, underscores, and dashes"
                        },
                        lengthExceedError: {
                            required: "Tag key length cannot exceed {{maxResourceTagKeyLength}} characters"
                        },
                        title: "Tag Key"
                    },
                    value: {
                        invalidValue: {
                            required: "Tag value contain only lowercase letters, numeric characters, underscores, and dashes"
                        },
                        lengthExceedError: {
                            required: "Tag value length cannot exceed {{maxResourceTagValueLength}} characters"
                        },
                        title: "Tag Value (Optional)"
                    }
                },
                title: "Add {{resourceType}} Exclusion Tag"
            }));

    const gcpRequirements =
        scopeNodeModel.configuration.id === ScopeHelper.customerId ||
        TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.FolderConfiguration)
            ? (scopeNodeModel.configuration as Contract.FolderConfiguration).tenantType === Contract.TenantType.Gcp
            : TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.CloudProviderTenantConfiguration)
                ? (scopeNodeModel.configuration as Contract.CloudProviderTenantConfiguration).type === Contract.TenantType.Gcp
                : false;
    const maxResourceTagKeyLength =
        gcpRequirements
            ? 63
            : 128;
    const maxResourceTagValueLength =
        gcpRequirements
            ? 63
            : 256;

    const validGcpVirtualMachineResourceTagValueRegex = /^[a-z0-9_-]{0,63}?$/;
    const [resourceTagKeyValidationController, resourceTagKeyValidationMessage] =
        useInputValidation(
            () => {
                if (_.isEmpty(exclusionTagKey.trim())) {
                    return localization.fields.key.emptyError.required();
                }

                if (exclusionTagKey.length > maxResourceTagKeyLength) {
                    return localization.fields.key.lengthExceedError.required({ maxResourceTagKeyLength });
                }

                if (gcpRequirements &&
                    !validGcpVirtualMachineResourceTagValueRegex.test(exclusionTagKey!)) {
                    return localization.fields.key.invalidValue.required();
                }

                return undefined;
            },
            [scopeNodeModel, exclusionTagKey]);
    const [resourceTagValueValidationController, resourceTagValueValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(exclusionTagValue)) {
                    return undefined;
                }

                if (exclusionTagValue.length > maxResourceTagValueLength) {
                    return localization.fields.value.lengthExceedError.required({ maxResourceTagValueLength });
                } else if (
                    gcpRequirements &&
                    !validGcpVirtualMachineResourceTagValueRegex.test(exclusionTagKey!)) {
                    return localization.fields.value.invalidValue.required();
                }
                return undefined;
            },
            [scopeNodeModel, exclusionTagValue]);

    const [valid, setValid] = useState(false);
    useChangeEffect(
        () => {
            setValid(
                resourceTagKeyValidationController.isValid() &&
                resourceTagValueValidationController.isValid);
        },
        [exclusionTagKey, exclusionTagValue]);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        <Fragment>
                            {addExclusionTagExecuting && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={!valid || addExclusionTagExecuting}
                                size="small"
                                onClick={() => addExclusionTag(exclusionTagKey, exclusionTagValue)}>
                                {localization.actions.add.title()}
                            </Button>
                        </Fragment>
                    </Stack>
            }}
            titleOptions={{ text: localization.title({ resourceType: resourceTypeLocalization.resourceTypeCaps }) }}>
            <Stack spacing={2}>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={addExclusionTagExecuting}
                        label={localization.fields.key.title()}
                        sx={{ marginBottom: theme.spacing(2) }}
                        value={exclusionTagKey}
                        variant="outlined"
                        onChange={event => setExclusionTagKey(event.target.value)}/>
                    {!_.isNil(resourceTagKeyValidationMessage) && (
                        <FormHelperText error={true}>{resourceTagKeyValidationMessage}</FormHelperText>)}
                </FormControl>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={addExclusionTagExecuting}
                        label={localization.fields.value.title()}
                        sx={{ marginBottom: theme.spacing(2) }}
                        value={exclusionTagValue}
                        variant="outlined"
                        onChange={event => setExclusionTagValue(event.target.value)}/>
                    {!_.isNil(resourceTagValueValidationMessage) && (
                        <FormHelperText error={true}>{resourceTagValueValidationMessage}</FormHelperText>)}
                </FormControl>
                {addExclusionTagError && (
                    <Message
                        level="error"
                        title={localization.actions.add.error({ resourceType: resourceTypeLocalization.resourceTypeNoCaps })}/>)}
            </Stack>
        </FormLayout>);
}