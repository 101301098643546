import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciBlockStorageBlockVolumeStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciBlockStorageBlockVolumeStatusTranslator",
            () => ({
                [Contract.TypeNames.OciBlockStorageBlockVolumeStatus]: {
                    [Contract.OciBlockStorageBlockVolumeStatus.Available]: "Available",
                    [Contract.OciBlockStorageBlockVolumeStatus.Faulty]: "Faulty",
                    [Contract.OciBlockStorageBlockVolumeStatus.Provisioning]: "Provisioning",
                    [Contract.OciBlockStorageBlockVolumeStatus.Restoring]: "Restoring",
                    [Contract.OciBlockStorageBlockVolumeStatus.Terminated]: "Terminated",
                    [Contract.OciBlockStorageBlockVolumeStatus.Terminating]: "Terminating"
                }
            }));
    return (status: Contract.OciBlockStorageBlockVolumeStatus) =>
        localization[Contract.TypeNames.OciBlockStorageBlockVolumeStatus][status]();
}