import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceDefinition, useAwsCommonNetworkedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEc2VpcEndpointStatusTranslator, useAwsEc2VpcEndpointTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2VpcEndpointDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const ec2VpcEndpointStatusTranslator = useAwsEc2VpcEndpointStatusTranslator();
    const ec2VpcEndpointTypeTranslator = useAwsEc2VpcEndpointTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2VpcEndpointDefinition",
            () => ({
                columns: {
                    routeTables: "Route Tables",
                    service: "Service",
                    status: "Status",
                    subnets: "Subnets",
                    type: "Endpoint Type"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2VpcEndpointModel) => ({
                                [localization.columns.type()]: ec2VpcEndpointTypeTranslator((item.entity as Contract.AwsEc2VpcEndpoint).type)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsEc2VpcEndpointType}
                                    enumTypeTranslator={ec2VpcEndpointTypeTranslator}
                                    placeholder={localization.columns.type()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcEndpointType}
                    itemProperty={(item: Contract.AwsEc2VpcEndpointModel) => ec2VpcEndpointTypeTranslator((item.entity as Contract.AwsEc2VpcEndpoint).type)}
                    key={Contract.EntityModelProperty.AwsEc2VpcEndpointType}
                    title={localization.columns.type()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2VpcEndpointModel) => ({
                                [localization.columns.service()]: (item.entity as Contract.AwsEc2VpcEndpoint).serviceName ?? ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2VpcEndpointServiceName)}
                                    placeholder={localization.columns.service()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcEndpointServiceName}
                    key={Contract.EntityModelProperty.AwsEc2VpcEndpointServiceName}
                    render={optionalTableCell<Contract.AwsEc2VpcEndpointModel>(item => (item.entity as Contract.AwsEc2VpcEndpoint).serviceName)}
                    title={localization.columns.service()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2VpcEndpointModel) => ({
                                [localization.columns.status()]: ec2VpcEndpointStatusTranslator((item.entity as Contract.AwsEc2VpcEndpoint).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsEc2VpcEndpointStatus}
                                    enumTypeTranslator={ec2VpcEndpointStatusTranslator}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcEndpointStatus}
                    itemProperty={(item: Contract.AwsEc2VpcEndpointModel) => ec2VpcEndpointStatusTranslator((item.entity as Contract.AwsEc2VpcEndpoint).status)}
                    key={Contract.EntityModelProperty.AwsEc2VpcEndpointStatus}
                    title={localization.columns.status()}/>,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(),
                commonNetworkedResourceDefinition.columns.securityGroupsColumn,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2VpcEndpointModel>(
                            Contract.TypeNames.AwsEc2RouteTable,
                            item => item.routeTableIds,
                            localization.columns.routeTables())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2VpcEndpointRouteTables)}
                                    placeholder={localization.columns.routeTables()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcEndpointRouteTables}
                    key={Contract.EntityModelProperty.AwsEc2VpcEndpointRouteTables}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcEndpointModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.routeTableIds}
                                entityTypeName={Contract.TypeNames.AwsEc2RouteTable}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.routeTables()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2VpcEndpointModel>(
                            Contract.TypeNames.AwsEc2Subnet,
                            item => item.subnetIds,
                            localization.columns.subnets())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2VpcEndpointSubnets)}
                                    placeholder={localization.columns.subnets()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcEndpointSubnets}
                    key={Contract.EntityModelProperty.AwsEc2VpcEndpointSubnets}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcEndpointModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.subnetIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.subnets()}/>,
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2VpcEndpointRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2VpcEndpointRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcEndpointRouteTables]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcEndpointServiceName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcEndpointStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcEndpointSubnets]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcEndpointType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}