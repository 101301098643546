import { ItemWithValidation, useChangeEffect } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useRef, useState } from "react";
import { JiraIssueSelector, JiraIssueSelectorActions } from "../..";
import { Contract } from "../../../controllers";
import { scopeSystemEntityModelStore } from "../../../stores";
import { ScopeHelper } from "../../../utilities";
import { DeliveryProps } from "../Delivery";
import { Message } from "./Message";

export function Jira({ delivery, disabled, messageOptions, onChange, scopeId }: DeliveryProps) {
    const jiraModels = scopeSystemEntityModelStore.useGetJira();
    const [issue, setIssue] =
        useState(
            _((delivery?.destinations ?? []) as Contract.JiraDeliveryProject[]).
                map(
                    ({ instanceId, issueCreationData }) =>
                        ({ instanceId, issueCreationData })).
                first());
    const [message, setMessage] = useState(delivery?.message);
    const instanceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            jiraModels);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.JiraDelivery),
                destinations:
                    _.isNil(issue)
                        ? []
                        : [{
                            ...issue,
                            typeName: Contract.TypeNames.JiraDeliveryProject
                        }] as Contract.JiraDeliveryProject[],
                message,
                valid: !_.isNil(issue)
            } as ItemWithValidation<Contract.JiraDelivery>),
        [issue, message],
        500);

    const jiraIssueSelectorActions = useRef<JiraIssueSelectorActions>();
    return (
        <Fragment>
            <JiraIssueSelector
                actionsRef={jiraIssueSelectorActions}
                disabled={disabled}
                initialData={issue}
                instanceModels={instanceModels}
                projectReference={
                    _.isNil(issue)
                        ? undefined
                        : {
                            instanceId: issue.instanceId,
                            projectRawId: issue.issueCreationData.projectRawId!
                        }}
                riskIds={[]}
                onDataChanged={setIssue}/>
            <Message
                disabled={disabled}
                message={message}
                options={messageOptions}
                setMessage={setMessage}/>
        </Fragment>);
}