import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, InlineTexts, StringHelper, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntityFilter, EntityModelHelper, entityModelStore, InfoCard, InlineEntities, ItemTable, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type ResourceRecordSetInfoCardProps = {
    managedZoneModel: Contract.GcpDnsManagedZoneModel;
};

export function ResourceRecordSetsInfoCard({ managedZoneModel }: ResourceRecordSetInfoCardProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const resourceRecordSets =
        _(entityModelStore.useGet(managedZoneModel.resourceRecordSetIds)).
            map(resourceRecordSetModel => resourceRecordSetModel.entity as Contract.GcpDnsManagedZoneResourceRecordSet).
            value();
    const dnsRecordEntityModels = entityModelStore.useGet(_.flatMap(managedZoneModel.resourceRecordSetIdToDnsRecordEntityIdsMap));
    const resourceRecordSetIdToDnsRecordEntityModelsMap =
        useMemo(
            () => {
                const dnsRecordEntityModelMap =
                    _.keyBy(
                        dnsRecordEntityModels,
                        dnsRecordEntityModel => dnsRecordEntityModel.id);
                return _.mapValues(
                    managedZoneModel.resourceRecordSetIdToDnsRecordEntityIdsMap,
                    dnsRecordEntityIds =>
                        _.map(
                            dnsRecordEntityIds,
                            dnsRecordEntityId => dnsRecordEntityModelMap[dnsRecordEntityId]));
            },
            []);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpDnsManagedZoneDefinition.resourceRecordSetsInfoCard",
            () => ({
                columns: {
                    entities: "Resources",
                    name: "DNS Name",
                    routingPolicy: {
                        title: "Routing Policy",
                        [Contract.TypeNames.GcpDnsManagedZoneResourceRecordSetRoutingPolicy]: {
                            [Contract.GcpDnsManagedZoneResourceRecordSetRoutingPolicy.Default]: "Default",
                            [Contract.GcpDnsManagedZoneResourceRecordSetRoutingPolicy.Failover]: "Failover",
                            [Contract.GcpDnsManagedZoneResourceRecordSetRoutingPolicy.Geolocation]: "Geolocation",
                            [Contract.GcpDnsManagedZoneResourceRecordSetRoutingPolicy.Weighted]: "Weighted Round Robin"
                        }
                    },
                    type: "Type",
                    values: "Value"
                },
                empty: "No Resource Record Sets",
                title: "Resource Record Sets"
            }));

    return (
        <InfoCard title={localization.title()}>
            <ItemTable
                columnIdToGetItemValueMap={{
                    [ResourceRecordSetInfoCardTableColumnId.Name]: item => item.name,
                    [ResourceRecordSetInfoCardTableColumnId.Entities]: {
                        getFilterValue: item => managedZoneModel.resourceRecordSetIdToDnsRecordEntityIdsMap[item.id],
                        getSortValue:
                            item =>
                                StringHelper.getCombineSortValue(
                                    _.size(resourceRecordSetIdToDnsRecordEntityModelsMap[item.id]),
                                    ..._.map(
                                        resourceRecordSetIdToDnsRecordEntityModelsMap[item.id],
                                        entityModel => entityModel.entity.displayName))
                    },
                    [ResourceRecordSetInfoCardTableColumnId.RoutingPolicy]: {
                        getFilterValue: item => item.routingPolicy,
                        getSortValue: item => localization.columns.routingPolicy[Contract.TypeNames.GcpDnsManagedZoneResourceRecordSetRoutingPolicy][item.routingPolicy]()
                    },
                    [ResourceRecordSetInfoCardTableColumnId.Type]: item => item.type,
                    [ResourceRecordSetInfoCardTableColumnId.Values]: {
                        getFilterValue: item => item.values,
                        getSortValue: item => StringHelper.getCombineSortValue(_.size(item.values), ...item.values)
                    }
                }}
                csvExportFilePrefixes={
                    [
                        entityTypeNameTranslator((managedZoneModel.entity).typeName, { includeServiceName: false }),
                        (managedZoneModel.entity as Contract.GcpDnsManagedZone).displayName,
                        localization.title()
                    ]}
                defaultSortColumnIdOrIds={ResourceRecordSetInfoCardTableColumnId.Name}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                getCsvItem={
                    item => ({
                        /* eslint-disable sort-keys-fix/sort-keys-fix */
                        "Name": item.name,
                        "Type": item.type.toUpperCase(),
                        "Value": _.join(item.values, "\n"),
                        "Resources":
                            _(resourceRecordSetIdToDnsRecordEntityModelsMap[item.id]).
                                map(dnsRecordEntityModel => dnsRecordEntityModel.entity.displayReference).
                                join("\n"),
                        "Routing Policy": localization.columns.routingPolicy[Contract.TypeNames.GcpDnsManagedZoneResourceRecordSetRoutingPolicy][item.routingPolicy]()
                        /* eslint-enable sort-keys-fix/sort-keys-fix */
                    })}
                getItemId={item => item.id}
                items={resourceRecordSets}>
                {columnIdToItemValuesMap => [
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.name()}
                                        values={columnIdToItemValuesMap[ResourceRecordSetInfoCardTableColumnId.Name]}/>
                            }
                        }}
                        id={ResourceRecordSetInfoCardTableColumnId.Name}
                        itemProperty={(resourceRecordSet: Contract.GcpDnsManagedZoneResourceRecordSet) => resourceRecordSet.name}
                        key={ResourceRecordSetInfoCardTableColumnId.Name}
                        title={localization.columns.name()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter placeholder={localization.columns.type()}>
                                        {_.map(
                                            columnIdToItemValuesMap[ResourceRecordSetInfoCardTableColumnId.Type],
                                            type =>
                                                <ValuesFilterItem
                                                    key={type}
                                                    title={type.toUpperCase()}
                                                    value={type}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={ResourceRecordSetInfoCardTableColumnId.Type}
                        itemProperty={(resourceRecordSet: Contract.GcpDnsManagedZoneResourceRecordSet) => resourceRecordSet.type.toUpperCase()}
                        key={ResourceRecordSetInfoCardTableColumnId.Type}
                        title={localization.columns.type()}/>,
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.values()}
                                        values={columnIdToItemValuesMap[ResourceRecordSetInfoCardTableColumnId.Values]}/>
                            }
                        }}
                        id={ResourceRecordSetInfoCardTableColumnId.Values}
                        key={ResourceRecordSetInfoCardTableColumnId.Values}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GcpDnsManagedZoneResourceRecordSet>) =>
                                <InlineTexts
                                    texts={item.values}
                                    variant="itemPlusItemCount"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.values()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[ResourceRecordSetInfoCardTableColumnId.Entities]}
                                        placeholder={localization.columns.entities()}/>
                            }
                        }}
                        id={ResourceRecordSetInfoCardTableColumnId.Entities}
                        key={ResourceRecordSetInfoCardTableColumnId.Entities}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GcpDnsManagedZoneResourceRecordSet>) =>
                                <InlineEntities
                                    entityIdsOrModels={resourceRecordSetIdToDnsRecordEntityModelsMap[item.id]}
                                    entityTypeName={EntityModelHelper.getCommonTypeName(resourceRecordSetIdToDnsRecordEntityModelsMap[item.id]) ?? Contract.TypeNames.Entity}
                                    variant="itemPlusItemCount"/>}
                        title={localization.columns.entities()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter placeholder={localization.columns.routingPolicy.title()}>
                                        {_.map(
                                            columnIdToItemValuesMap[ResourceRecordSetInfoCardTableColumnId.RoutingPolicy],
                                            (routingPolicy: Contract.GcpDnsManagedZoneResourceRecordSetRoutingPolicy) =>
                                                <ValuesFilterItem
                                                    key={routingPolicy}
                                                    title={localization.columns.routingPolicy[Contract.TypeNames.GcpDnsManagedZoneResourceRecordSetRoutingPolicy][routingPolicy]()}
                                                    value={routingPolicy}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={ResourceRecordSetInfoCardTableColumnId.RoutingPolicy}
                        itemProperty={(resourceRecordSet: Contract.GcpDnsManagedZoneResourceRecordSet) => localization.columns.routingPolicy[Contract.TypeNames.GcpDnsManagedZoneResourceRecordSetRoutingPolicy][resourceRecordSet.routingPolicy]()}
                        key={ResourceRecordSetInfoCardTableColumnId.RoutingPolicy}
                        title={localization.columns.routingPolicy.title()}/>
                ]}
            </ItemTable>
        </InfoCard>);
}

enum ResourceRecordSetInfoCardTableColumnId {
    Entities = "entities",
    Name = "name",
    RoutingPolicy = "routingPolicy",
    Type = "type",
    Values = "value"
}