import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntityAiIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M14.2809 4.98972C14.6997 3.85792 16.3005 3.85791 16.7193 4.98972L17.9079 8.20173C18.232 9.07763 18.9226 9.76823 19.7985 10.0923L23.0105 11.2809C24.1423 11.6997 24.1423 13.3005 23.0105 13.7193L19.7985 14.9079C18.9226 15.232 18.232 15.9226 17.9079 16.7985L16.7193 20.0105C16.3005 21.1423 14.6997 21.1423 14.2809 20.0105L13.0923 16.7985C12.7682 15.9226 12.0776 15.232 11.2017 14.9079L7.98972 13.7193C6.85792 13.3005 6.85791 11.6997 7.98972 11.2809L11.2017 10.0923C12.0776 9.76823 12.7682 9.07763 13.0923 8.20173L14.2809 4.98972ZM15.5001 6.30533L14.5929 8.75699C14.1067 10.0708 13.0708 11.1067 11.757 11.5929L9.30533 12.5001L11.757 13.4073C13.0708 13.8935 14.1067 14.9294 14.5929 16.2432L15.5001 18.6949L16.4073 16.2432C16.8935 14.9294 17.9294 13.8935 19.2432 13.4073L21.6949 12.5001L19.2432 11.5929C17.9294 11.1067 16.8935 10.0708 16.4073 8.75699L15.5001 6.30533Z"
                fillRule="evenodd"/>
            <path d="M5.31243 16.5069C5.37686 16.3328 5.62314 16.3328 5.68757 16.5069L5.80702 16.8297C6.21216 17.9246 7.07541 18.7878 8.17028 19.193L8.4931 19.3124C8.66722 19.3769 8.66722 19.6231 8.4931 19.6876L8.17028 19.807C7.07541 20.2122 6.21216 21.0754 5.80702 22.1703L5.68757 22.4931C5.62314 22.6672 5.37686 22.6672 5.31243 22.4931L5.19298 22.1703C4.78784 21.0754 3.92459 20.2122 2.82972 19.807L2.5069 19.6876C2.33278 19.6231 2.33278 19.3769 2.5069 19.3124L2.82972 19.193C3.92459 18.7878 4.78784 17.9246 5.19298 16.8297L5.31243 16.5069Z"/>
            <path d="M4.31243 0.506901C4.37686 0.332777 4.62314 0.332777 4.68757 0.506901L5.07711 1.55963C5.48225 2.6545 6.3455 3.51775 7.44037 3.92289L8.4931 4.31243C8.66722 4.37686 8.66722 4.62314 8.4931 4.68757L7.44037 5.07711C6.3455 5.48225 5.48225 6.3455 5.07711 7.44037L4.68757 8.4931C4.62314 8.66722 4.37686 8.66722 4.31243 8.4931L3.92289 7.44037C3.51775 6.3455 2.6545 5.48225 1.55963 5.07711L0.506901 4.68757C0.332777 4.62314 0.332777 4.37686 0.506901 4.31243L1.55963 3.92289C2.6545 3.51775 3.51775 2.6545 3.92289 1.55963L4.31243 0.506901Z"/>
        </SvgIcon>);
}