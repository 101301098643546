import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function NewRelicIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                d="M18.9001 8.3V15.675L12.4751 19.375V24L22.9501 18V6L18.9001 8.3Z"
                fill="#00AC69"/>
            <path
                d="M12.475 4.625L18.925 8.325L22.925 6L12.475 0L2 6L6.025 8.3L12.475 4.625Z"
                fill="#1CE783"/>
            <path
                d="M8.45 14.3V21.675L12.475 24V12L2 6V10.625L8.45 14.3Z"
                fill={theme.palette.text.primary}/>
        </SvgIcon>);
}