import { Dialog, FormLayout, Message, useLocalization } from "@infrastructure";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AuditEventController, Contract, useTheme } from "../../../../../common";
import { useSideViewContext } from "../../SideView";

type InsertRiskNoteProps = {
    onClose: (canceled?: boolean) => void;
    riskIds: string[];
};

export function InsertRiskNoteDialog({ onClose, riskIds }: InsertRiskNoteProps) {
    const { triggerDataChange } = useSideViewContext();
    const [message, setMessage] = useState<string>();
    const [insertRiskNoteError, setInsertRiskNoteError] = useState(false);
    const [insertRiskNoteExecuting, setInsertRiskNoteExecuting] = useState(false);

    async function insertRiskNote() {
        setInsertRiskNoteExecuting(true);
        setInsertRiskNoteError(false);

        try {
            await AuditEventController.insertRiskNoteCreationAuditEvent(
                new Contract.AuditEventControllerInsertRiskNoteCreationAuditEventRequest(
                    riskIds,
                    message!));
            triggerDataChange(Contract.TypeNames.AuditEventControllerDeleteRiskNoteCreationAuditEventRequest);

            onClose();
        } catch {
            setInsertRiskNoteError(true);
        }

        setInsertRiskNoteError(false);
        setInsertRiskNoteExecuting(false);
        setMessage(undefined);
    }

    const localization =
        useLocalization(
            "views.customer.risks.insertRiskNoteDialog",
            () => ({
                actions: {
                    save: "Save"
                },
                error: "Failed adding Comment",
                fields: {
                    message: {
                        placeholder: "Write a comment..."
                    }
                },
                title: "Finding Comment"
            }));

    const theme = useTheme();
    return (
        <Dialog
            variant="editor"
            onClose={() => onClose(true)}>
            <FormLayout
                footerOptions={{
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}>
                            {insertRiskNoteExecuting && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={
                                    insertRiskNoteExecuting ||
                                    _.isEmpty(message?.trim())}
                                onClick={() => insertRiskNote()}>
                                {localization.actions.save()}
                            </Button>
                        </Stack>
                }}
                titleOptions={{ text: localization.title() }}>
                <Stack spacing={2}>
                    <TextField
                        disabled={insertRiskNoteExecuting}
                        fullWidth={true}
                        label={localization.fields.message.placeholder()}
                        multiline={true}
                        rows={12}
                        value={message}
                        variant="outlined"
                        onChange={event => setMessage(event.target.value)}/>
                    {insertRiskNoteError && (
                        <Message
                            level="error"
                            title={localization.error()}/>)}
                </Stack>
            </FormLayout>
        </Dialog>);
}