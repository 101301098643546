import { Public as PublicIcon } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Dialog, FormLayout, Link, ThemeType, useColorTheme, useLocalization, useQueryParameters } from "@infrastructure";
import { Contract, LogoTextIcon, TenableOverlay } from "../..";
import { useGeographySelectorContext } from "../../../views";
import { useTheme } from "../../themes";
import { GeneralInformation, GeographyHelper } from "../../utilities";
import { Authentication, ChangePassword } from "./components";

type SignInProps = {
    consoleAppType: Contract.ConsoleAppType;
    enableTenable?: boolean;
};

export function SignIn({ consoleAppType, enableTenable = true }: SignInProps) {
    const { changePasswordUserMail } = useQueryParameters<SignInQueryParameters>();

    const { open } = useGeographySelectorContext();

    const localization =
        useLocalization(
            "common.signIn",
            () => ({
                actions: {
                    geographySelector: "Switch Region"
                },
                copyright: "© Tenable {{year}}",
                dialog: {
                    button: "I Agree",
                    text: "This is a government computer system. This system may contain U.S. Government information, which is restricted to authorized users ONLY. Unauthorized access, use, misuse, or modification of this computer system or of the data contained herein or in transit to/from this system may subject the individual to administrative disciplinary actions, criminal and civil penalties. Users have no expectation of privacy. This system and equipment are subject to monitoring to ensure proper performance of applicable security features or procedures. Such monitoring may result in the acquisition, recording, and analysis of all data being communicated, transmitted, processed, or stored in this system by a user. If monitoring reveals possible evidence of criminal activity, such evidence may be provided to Law Enforcement Personnel. ANYONE USING THIS SYSTEM EXPRESSLY CONSENTS TO SUCH MONITORING.",
                    title: "Warning: Privacy Consent"
                },
                termsOfService: {
                    links: {
                        termsOfServiceLink: "Terms of Service"
                    },
                    title: "By signing In, you agree to the {{termsOfServiceLink}}"
                }
            }));

    const [privacyConsentOpen, setPrivacyConsentOpen] = useState(GeographyHelper.deploymentPartitionType === Contract.PartitionType.UsGov);
    const theme = useTheme();
    const colorTheme = useColorTheme(ThemeType.Dark);
    return (
        <Stack
            direction="row"
            sx={{ flex: 1 }}>
            {privacyConsentOpen &&
                <Dialog variant="viewer">
                    <FormLayout
                        footerOptions={{
                            contentElement:
                                <Stack alignItems="end">
                                    <Button
                                        variant="contained"
                                        onClick={() => setPrivacyConsentOpen(false)}>
                                        {localization.dialog.button()}
                                    </Button>
                                </Stack>
                        }}
                        titleOptions={{ text: localization.dialog.title() }}>
                        <Typography>
                            {localization.dialog.text()}
                        </Typography>
                    </FormLayout>
                </Dialog>}
            <Stack
                alignItems="center"
                direction="column-reverse"
                spacing={2}
                sx={{
                    background: `radial-gradient(150% 100% at 100% 35%, ${colorTheme.colorPalette.action.active} 0%, ${colorTheme.colorPalette.background.primary}  40%, ${colorTheme.colorPalette.background.primary} 0%)`,
                    height: "100%",
                    maxWidth: "35%",
                    paddingBottom: theme.spacing(2),
                    position: "relative",
                    width: "35%",
                    zIndex: 1
                }}>
                <TenableOverlay
                    sx={{
                        fontSize: "800px",
                        left: "40%",
                        opacity: "0.4",
                        position: "absolute",
                        top: 0,
                        width: "800px"
                    }}/>
                <LogoTextIcon sx={{ color: colorTheme.colorPalette.text.primary }}/>
            </Stack>
            <Stack
                alignItems="center"
                sx={{
                    background: theme.palette.background.paper,
                    flex: 1,
                    height: "100%",
                    zIndex: 2
                }}>
                <Stack
                    alignItems="flex-end"
                    sx={{
                        padding: theme.spacing(4),
                        width: "100%"
                    }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{
                            color: theme.palette.text.primary,
                            cursor: "pointer"
                        }}
                        onClick={() => open()}>
                        <PublicIcon sx={{ fontSize: "18px" }}/>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500
                            }}>
                            {localization.actions.geographySelector()}
                        </Typography>
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flex: 1,
                        height: "100%"
                    }}>
                    {_.isNil(changePasswordUserMail)
                        ? <Authentication
                            consoleAppType={consoleAppType}
                            disabled={privacyConsentOpen}
                            enableTenable={enableTenable}/>
                        : <ChangePassword consoleAppType={consoleAppType}/>}
                </Box>
                <Stack
                    alignItems="center"
                    sx={{ padding: theme.spacing(2) }}>
                    <Typography>
                        {localization.termsOfService.title({
                            termsOfServiceLink:
                                <Link
                                    urlOrGetUrl="https://ermetic.com/legal/terms-of-service"
                                    variant="external">
                                    {localization.termsOfService.links.termsOfServiceLink()}
                                </Link>
                        })}
                    </Typography>
                    <Typography>
                        {localization.copyright({
                            year:
                                moment().
                                    format("YYYY")
                        })}
                    </Typography>
                    <Typography sx={{ color: theme.palette.text.secondary }}>
                        {GeneralInformation.assemblyFileVersion}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>);
}

export type SignInQueryParameters = {
    changePasswordRecovery: boolean;
    changePasswordUserMail: string;
};