import { Loading, useLocalization } from "@infrastructure";
import { Box, useTheme } from "@mui/material";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpComputeInstanceRiskContext } from "../../../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, Network } from "../../../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../../../common/components/Network/components";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext } from "./useGetGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext";

export function useGcpInboundExternalComputeInstanceRiskInstanceDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalComputeInstanceRiskModel = riskModel as Contract.GcpInboundExternalComputeInstanceRiskModel;
    const instanceModel = entityModelStore.useGet(inboundExternalComputeInstanceRiskModel.risk.entityId) as Contract.GcpComputeInstanceModel;

    const getGcpComputeInstanceRiskContext = useGetGcpComputeInstanceRiskContext();
    const getGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext = useGetGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpInboundExternalComputeInstanceRiskDefinition.hooks.useGcpInboundExternalComputeInstanceRiskInstanceDefinition",
            () => ({
                description: "{{instance}} is accessible directly from a wide range of public IP addresses",
                sections: {
                    networkGraph: "Network",
                    resolution: {
                        step1: "Before changing any ingress (inbound) rule, verify that doing so will not break existing communications for the VM instance or any other instance in the network",
                        step2: "For each relevant ingress (inbound) rule, edit the source address range to restrict it to only the IP addresses that require access, or, if access is not required, delete the rule"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                instance:
                    <Entity
                        entityIdOrModel={instanceModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () =>
            [
                <EntityExternalConsoleLink
                    entityId={riskModel.risk.tenantId}
                    key={riskModel.risk.tenantId}
                    page={Contract.GcpConsolePage.Firewalls}/>,
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2()
            ],
        riskModel,
        () => {
            const instanceContextItems = getGcpComputeInstanceRiskContext(instanceModel);
            const instanceRiskSourceResourceContextItems = getGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext(inboundExternalComputeInstanceRiskModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.vpcsAndSubnets,
                instanceContextItems.status,
                instanceContextItems.serviceAccountPermissionAction,
                instanceContextItems.networkTags,
                instanceRiskSourceResourceContextItems.networkSecurityPerimeterResourceIdsAndExposedNetworkScopes,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        instanceModel.unknown || instanceModel.entity.systemDeleted
            ? undefined
            : {
                sections: [
                    new RiskDefinitionSection(
                        <Box sx={{ minHeight: theme.spacing(20) }}>
                            <Loading>
                                <Network
                                    baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(inboundExternalComputeInstanceRiskModel.risk.id)}
                                    entityId={inboundExternalComputeInstanceRiskModel.risk.entityId}
                                    initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                    tenantType={Contract.TenantType.Gcp}
                                    variant="risk"/>
                            </Loading>
                        </Box>,
                        localization.sections.networkGraph(),
                        { profilePageOnly: true })
                ]
            });
}