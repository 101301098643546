import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetOciBlockStorageBootVolumeRiskContext } from "../contexts";

export function useOciBlockStorageBootVolumeDefaultEncryptionRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciBlockStorageBootVolumeDefaultEncryptionRisk;
    const bootVolumeModel = entityModelStore.useGet(risk.entityId) as Contract.OciBlockStorageBootVolumeModel;

    const getOciBlockStorageBootVolumeRiskContext = useGetOciBlockStorageBootVolumeRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciBlockStorageBootVolumeDefaultEncryptionRiskDefinition",
            () => ({
                description: "{{bootVolume}} is not configured with encryption using CMK",
                sections: {
                    resolution: {
                        step1: "Under **Performance**, click **Assign** next to **Encryption Key**",
                        step2: "Select a **Vault** and a **Master Encryption Key**",
                        step3: "Click **Assign**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            bootVolume:
                <Entity
                    entityIdOrModel={bootVolumeModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.BlockStorage,
                bootVolumeModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const bootVolumeRiskContext = getOciBlockStorageBootVolumeRiskContext(bootVolumeModel);
            return [
                bootVolumeRiskContext.generalInformation,
                bootVolumeRiskContext.status,
                bootVolumeRiskContext.size,
                bootVolumeRiskContext.instance,
                bootVolumeRiskContext.sensitive,
                bootVolumeRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}