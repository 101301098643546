import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpComputeLoadBalancersRiskContext } from "./useGetGcpComputeLoadBalancersRiskContext";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpComputeBackendServiceRiskContext() {
    return useMemo(
        () => useGcpComputeBackendServiceRiskContext,
        []);
}

function useGcpComputeBackendServiceRiskContext(backendServiceModel: Contract.GcpComputeBackendServiceModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(backendServiceModel);
    const loadBalancerRiskContext = useGetGcpComputeLoadBalancersRiskContext()(backendServiceModel.loadBalancerIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpComputeBackendServiceRiskContext",
            () => ({
                loadBalancers: "The {{translatedBackendServiceTypeName}} is used by {{loadBalancers}}"
            }));
    return {
        ...entityRiskContext,
        loadBalancers:
            _.isEmpty(backendServiceModel.loadBalancerIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.loadBalancers({
                        loadBalancers:
                            <InlineEntities
                                entityIdsOrModels={backendServiceModel.loadBalancerIds}
                                entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}
                                variant="itemCountAndType"/>,
                        translatedBackendServiceTypeName:
                            entityTypeNameTranslator(
                                backendServiceModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
        sensitiveLoadBalancers: loadBalancerRiskContext.sensitive
    };
}