﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { VulnerabilitiesCell } from "../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem } from "../../../../components";

export function useGcpCommonKubernetesWorkloadResourceInfoItemElements(workloadResourceModel: Contract.GcpKubernetesWorkloadResourceModel) {
    const workloadResource = workloadResourceModel.entity as Contract.GcpKubernetesWorkloadResourceBase;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonKubernetesWorkloadResourceInfoItemElements",
            () => ({
                containerImages: "Container Images",
                containerImageVulnerabilities: "Vulnerabilities",
                controllerResourceReference: "Owner",
                gcpServiceAccountIds: [
                    "GCP Service Account",
                    "GCP Service Accounts"
                ],
                nodePoolIds: "Node Pools",
                serviceAccountIdReference: "Service Account"
            }));
    return {
        containerImagesInfoItemElement:
            _.isEmpty(workloadResourceModel.containerImageIdReferences)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.containerImageIdReferences}
                    entityTypeName={Contract.TypeNames.IContainerImage}
                    key="containerImages"
                    title={localization.containerImages()}/>,
        containerImageVulnerabilitiesInfoItemElement:
            _.isEmpty(workloadResourceModel.containerImageVulnerabilities)
                ? undefined
                : <InfoItem
                    key="containerImageVulnerabilities"
                    title={localization.containerImageVulnerabilities()}
                    value={
                        <VulnerabilitiesCell
                            containerImageScanStatus={workloadResourceModel.containerImageScanStatus}
                            variant="initialAndCount"
                            vulnerabilities={workloadResourceModel.containerImageVulnerabilities}/>}/>,
        controllerResourceInfoItemElement:
            _.isNil(workloadResourceModel.controllerResourceReference)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.controllerResourceReference.idReference}
                    entityTypeName={Contract.TypeNames.GcpKubernetesResource}
                    key="controllerResourceReference"
                    location="all"
                    title={localization.controllerResourceReference()}/>,
        gcpServiceAccountsInfoItemElement:
            _.isEmpty(workloadResourceModel.gcpServiceAccountIds)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.gcpServiceAccountIds}
                    entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                    key="gcpServiceAccountIds"
                    title={localization.gcpServiceAccountIds(_.size(workloadResourceModel.gcpServiceAccountIds))}/>,
        nodePoolsInfoItemElement:
            _.isEmpty(workloadResourceModel.nodePoolIds)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.nodePoolIds}
                    entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                    key="nodePoolIds"
                    title={localization.nodePoolIds()}/>,
        serviceAccountInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={workloadResource.data.podTemplateSpec.podSpec.serviceAccountReference.idReference}
                entityTypeName={Contract.TypeNames.GcpKubernetesServiceAccount}
                key="serviceAccountIdReference"
                title={localization.serviceAccountIdReference()}/>
    };
}