import { DataTableColumn, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsCloudTrailTrailEventSelectorTypeTranslator } from "../../../../../../../../../../tenants";
import { useAwsS3BucketObjectOwnershipTranslator, useAwsS3BucketServerSideEncryptionTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useCommonDataAnalysisDefinition } from "../useCommonDataAnalysisDefinition";
import { AwsCloudTrailTrailEventSelectorTypeFilter } from "./useAwsCloudTrailTrailDefinition/components";

export function useAwsS3BucketDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const cloudTrailTrailEventSelectorTypeTranslator = useAwsCloudTrailTrailEventSelectorTypeTranslator();
    const s3BucketObjectOwnershipTranslator = useAwsS3BucketObjectOwnershipTranslator();
    const s3BucketServerSideEncryptionTypeTranslator = useAwsS3BucketServerSideEncryptionTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsS3BucketDefinition",
            () => ({
                columns: {
                    objectCount: "Object Count",
                    objectEventSelectorType: "Object-Level Logging",
                    objectLifecycleConfigurationEnabled: {
                        false: "Disabled",
                        title: "Object Lifecycle Configurations",
                        true: "Enabled",
                        unknown: "Unknown"
                    },
                    objectVersioningEnabled: {
                        false: "Not enabled",
                        title: "Object Versioning",
                        true: "Enabled"
                    },
                    serverSideEncryptionType: "Server-Side Encryption",
                    storageSize: "Size",
                    website: {
                        false: "Disabled",
                        title: "Static Website Hosting",
                        true: "Enabled"
                    }
                },
                export: {
                    disabled: "Not enabled",
                    enabled: "Enabled",
                    eventEnabled: "Server Access Logging",
                    objectEventSelectorType: "Object-Level Logging",
                    objectLockEnabled: "Object Lock",
                    objectMfaDeleteEnabled: "Object MFA Delete",
                    objectOwnership: "Object Ownership"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsS3BucketModel) => ({
                            [localization.columns.storageSize()]: NumberFormatter.storage((item.entity as Contract.AwsS3Bucket).storageSize)
                        })
                }}
                id={Contract.EntityModelProperty.AwsS3BucketStorageSize}
                itemProperty={(item: Contract.AwsResourceModel) => NumberFormatter.storage((item.entity as Contract.AwsS3Bucket).storageSize)}
                key={Contract.EntityModelProperty.AwsS3BucketStorageSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.storageSize()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsS3BucketModel) => ({
                            [localization.columns.objectCount()]: NumberFormatter.humanize((item.entity as Contract.AwsS3Bucket).objectCount)
                        })
                }}
                id={Contract.EntityModelProperty.AwsS3BucketObjectCount}
                itemProperty={(item: Contract.AwsResourceModel) => (NumberFormatter.humanize((item.entity as Contract.AwsS3Bucket).objectCount))}
                key={Contract.EntityModelProperty.AwsS3BucketObjectCount}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.objectCount()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsS3BucketModel) => ({
                            [localization.columns.serverSideEncryptionType()]: s3BucketServerSideEncryptionTypeTranslator((item.entity as Contract.AwsS3Bucket).serverSideEncryptionType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.AwsS3BucketServerSideEncryptionType}
                                enumTypeTranslator={s3BucketServerSideEncryptionTypeTranslator}
                                placeholder={localization.columns.serverSideEncryptionType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsS3BucketServerSideEncryptionType}
                itemProperty={(item: Contract.AwsResourceModel) => s3BucketServerSideEncryptionTypeTranslator((item.entity as Contract.AwsS3Bucket).serverSideEncryptionType)}
                key={Contract.EntityModelProperty.AwsS3BucketServerSideEncryptionType}
                title={localization.columns.serverSideEncryptionType()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsS3BucketModel) => ({
                            [localization.columns.objectEventSelectorType()]: cloudTrailTrailEventSelectorTypeTranslator(item.objectEventSelectorType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <AwsCloudTrailTrailEventSelectorTypeFilter
                                placeholder={localization.columns.objectEventSelectorType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsS3BucketObjectEventSelectorType}
                itemProperty={(item: Contract.AwsS3BucketModel) => cloudTrailTrailEventSelectorTypeTranslator(item.objectEventSelectorType)}
                key={Contract.EntityModelProperty.AwsS3BucketObjectEventSelectorType}
                selectorOptions={{ default: false }}
                title={localization.columns.objectEventSelectorType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsS3BucketModel) => ({
                            [localization.columns.objectLifecycleConfigurationEnabled.title()]:
                                _.isNil((item.entity as Contract.AwsS3Bucket).objectLifecycleConfigurationEnabled)
                                    ? localization.columns.objectLifecycleConfigurationEnabled.unknown()
                                    : (item.entity as Contract.AwsS3Bucket).objectLifecycleConfigurationEnabled
                                        ? localization.columns.objectLifecycleConfigurationEnabled.true()
                                        : localization.columns.objectLifecycleConfigurationEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                emptyValueOptions={{
                                    enabled: true,
                                    title: localization.columns.objectLifecycleConfigurationEnabled.unknown()
                                }}
                                placeholder={localization.columns.objectLifecycleConfigurationEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.objectLifecycleConfigurationEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.objectLifecycleConfigurationEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsS3BucketObjectLifecycleConfigurationEnabled}
                itemProperty={
                    (item: Contract.AwsResourceModel) =>
                        _.isNil((item.entity as Contract.AwsS3Bucket).objectLifecycleConfigurationEnabled)
                            ? localization.columns.objectLifecycleConfigurationEnabled.unknown()
                            : (item.entity as Contract.AwsS3Bucket).objectLifecycleConfigurationEnabled
                                ? localization.columns.objectLifecycleConfigurationEnabled.true()
                                : localization.columns.objectLifecycleConfigurationEnabled.false()}
                key={Contract.EntityModelProperty.AwsS3BucketObjectLifecycleConfigurationEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.objectLifecycleConfigurationEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsS3BucketModel) => ({
                            [localization.columns.objectVersioningEnabled.title()]:
                                (item.entity as Contract.AwsS3Bucket).objectVersioningEnabled
                                    ? localization.columns.objectVersioningEnabled.true()
                                    : localization.columns.objectVersioningEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.objectVersioningEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.objectVersioningEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.objectVersioningEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsS3BucketObjectVersioningEnabled}
                itemProperty={
                    (item: Contract.AwsResourceModel) =>
                        (item.entity as Contract.AwsS3Bucket).objectVersioningEnabled
                            ? localization.columns.objectVersioningEnabled.true()
                            : localization.columns.objectVersioningEnabled.false()}
                key={Contract.EntityModelProperty.AwsS3BucketObjectVersioningEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.objectVersioningEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsS3BucketModel) => ({
                            [localization.columns.website.title()]:
                                (item.entity as Contract.AwsS3Bucket).websiteEnabled
                                    ? localization.columns.website.true()
                                    : localization.columns.website.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.website.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.website.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.website.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsS3BucketWebsiteEnabled}
                itemProperty={
                    (item: Contract.AwsResourceModel) =>
                        (item.entity as Contract.AwsS3Bucket).websiteEnabled
                            ? localization.columns.website.true()
                            : localization.columns.website.false()}
                key={Contract.EntityModelProperty.AwsS3BucketWebsiteEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.website.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsS3BucketModel) => {
                            function getEnabledTranslation(enabled: boolean) {
                                return enabled
                                    ? localization.export.enabled()
                                    : localization.export.disabled();
                            }

                            const bucket = item.entity as Contract.AwsS3Bucket;
                            return ({
                                [localization.export.eventEnabled()]: getEnabledTranslation(bucket.eventEnabled),
                                [localization.export.objectEventSelectorType()]: cloudTrailTrailEventSelectorTypeTranslator(item.objectEventSelectorType),
                                [localization.export.objectLockEnabled()]: getEnabledTranslation(bucket.objectLockEnabled),
                                [localization.export.objectMfaDeleteEnabled()]: getEnabledTranslation(bucket.objectMfaDeleteEnabled),
                                [localization.export.objectOwnership()]: s3BucketObjectOwnershipTranslator(bucket.objectOwnership)
                            });
                        }
                }}
                id="additionalExportItems"
                key="additionalExportItems"/>,
            commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn,
            commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(false),
            commonDataAnalysisResourceDefinition.columns.scanTimeColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsS3BucketRequest(
                new Contract.EntityControllerGetEntityModelPageAwsS3BucketRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsS3BucketObjectEventSelectorType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsS3BucketObjectLifecycleConfigurationEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsS3BucketObjectVersioningEnabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsS3BucketServerSideEncryptionType]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsS3BucketWebsiteEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}