import { CollapsedIcon, SearchTextField, StringHelper, useLocalization } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo, useState } from "react";

type GroupedSearchListProps<T> = {
    groupItemRenderer: (item: T) => ReactNode;
    groups: GroupedSearchListGroup<T>[];
} & ({
    getGroupItemSearchText: (item: T) => string;
    searchEnabled?: true;
} | {
    getGroupItemSearchText: never;
    searchEnabled: false;
});

export type GroupedSearchListGroup<T> = {
    id: string;
    items: T[];
    name: string;
};

export function GroupedSearchList<T>({ getGroupItemSearchText, groupItemRenderer, groups, searchEnabled = true }: GroupedSearchListProps<T>) {
    const [searchText, setSearchText] = useState<string>();

    const searchable =
        useMemo(
            () =>
                searchEnabled &&
                    _(groups).
                        flatMap(group => group.items).
                        size() > 10,
            [searchEnabled, groups]);

    const filteredGroups =
        useMemo(
            () => {
                if(_.isEmpty(searchText)) {
                    return groups;
                }

                return _<GroupedSearchListGroup<T>>(groups).
                    map(
                        section => ({
                            ...section,
                            items:
                            _.filter(
                                section.items,
                                item =>
                                    StringHelper.search(
                                        section.name,
                                        searchText) ||
                                    StringHelper.search(
                                        getGroupItemSearchText(item),
                                        searchText))
                        })).
                    filter(section => !_.isEmpty(section.items)).
                    value();
            },
            [searchText, groups]);

    const localization =
        useLocalization(
            "infrastructure.groupedSearchList",
            () => ({
                empty: "No matching items",
                search: {
                    placeholder: "Search"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{
                maxHeight: theme.spacing(65),
                maxWidth: theme.spacing(50),
                overflow: "hidden",
                padding:
                    searchable
                        ? theme.spacing(1)
                        : undefined
            }}>
            {searchable &&
                <SearchTextField
                    placeholder={localization.search.placeholder()}
                    searchText={searchText}
                    onSearchTextChanged={setSearchText}/>}
            {_.isEmpty(filteredGroups)
                ? <Box
                    sx={{
                        padding: theme.spacing(5, 3),
                        textAlign: "center"
                    }}>
                    {localization.empty()}
                </Box>
                : <Stack sx={{ overflow: "auto" }}>
                    {_.map(
                        filteredGroups,
                        filteredGroup =>
                            <Accordion
                                defaultExpanded={true}
                                key={filteredGroup.id}>
                                <AccordionSummary
                                    expandIcon={<CollapsedIcon/>}
                                    sx={{
                                        "&.Mui-expanded": {
                                            minHeight: "42px"
                                        },
                                        minHeight: "42px",
                                        padding: theme.spacing(0, 1)
                                    }}>
                                    <Typography
                                        noWrap={true}
                                        sx={{ fontWeight: 500 }}
                                        variant="h5">
                                        {filteredGroup.name}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        margin: theme.spacing(0, 0, 0, 2),
                                        padding: 0
                                    }}>
                                    {_.map(
                                        filteredGroup.items,
                                        item => groupItemRenderer(item))}
                                </AccordionDetails>
                            </Accordion>)}
                </Stack>}
        </Stack>);
}