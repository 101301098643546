import { Grid } from "@mui/material";
import _ from "lodash";
import React, { memo } from "react";
import { GroupedTableColumn } from "../GroupedTable";

const RowMemo = memo(Row);
export { RowMemo as Row };

type RowProps = {
    columns: GroupedTableColumn[];
    item: any;
};

function Row({ columns, item }: RowProps) {
    return (
        <Grid
            alignItems="center"
            container={true}>
            {_.map(
                columns,
                column =>
                    <Grid
                        item={true}
                        key={column.id}
                        xs={column.width}>
                        <column.element
                            item={item}
                            key={column.id}/>
                    </Grid>
            )}
        </Grid>);
}