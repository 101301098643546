import { map, MouseHelper, setUrlRoute } from "@infrastructure";
import { Box, Tooltip } from "@mui/material";
import _ from "lodash";
import React, { CSSProperties, Fragment, MouseEvent, ReactNode, useMemo, useState } from "react";
import { Cell, Label, Pie, PieChart, Sector, SectorProps } from "recharts";
import { UrlHelper, useTheme } from "..";

export type DistributionChartProps = {
    activeItemId?: string;
    disableShadows?: boolean;
    highlightedItemId?: string;
    items: DistributionChartItem[];
    onActiveItemIdChanged?: (itemId?: string) => void;
    size?: "large" | "medium" | "small" | "smallThin" | "xl";
    style?: CSSProperties;
    subtitle?: string;
    title?: string;
    titleUrl?: string;
    variant: "donut" | "pie";
};

export class DistributionChartItem {
    constructor(
        public color: string,
        public id: string,
        public infoElement: ReactNode,
        public value: number,
        public url?: string) {
    }

    public get sectorClassName() {
        return `sector-${this.id}`;
    }
}

export function DistributionChart({ activeItemId, disableShadows = false, highlightedItemId, items: chartItems, onActiveItemIdChanged, size = "xl", style, subtitle, title, titleUrl, variant }: DistributionChartProps) {
    const [controlledActiveItemIndex, setControlledActiveItemIndex] = useState<number>();
    const [itemTooltip, setItemTooltip] = useState<DistributionChartItemTooltip>();
    const [pieChart, setPieChart] = useState<any | null>(null);

    const theme = useTheme();
    const [items, chartEmpty] =
        useMemo(
            () => {
                const chartEmpty = _.isEmpty(chartItems);
                const items =
                    chartEmpty
                        ? [
                            new DistributionChartItem(
                                theme.palette.chart.grey,
                                "",
                                <Fragment/>,
                                1)
                        ]
                        : chartItems;

                return [items, chartEmpty];
            },
            [chartItems, theme]);

    const activeItemIndex =
        useMemo(
            () => {
                if (_.isNil(pieChart) || chartEmpty) {
                    return [];
                }

                const activeItemIndex =
                    _.isNil(activeItemId)
                        ? controlledActiveItemIndex
                        : _.findIndex(
                            items,
                            item => item.id === activeItemId);
                if (_.isNil(activeItemIndex)) {
                    setItemTooltip(undefined);
                } else {
                    const activeItem = items[activeItemIndex];
                    const sectorElement = pieChart.container.getElementsByClassName(activeItem.sectorClassName)[0];
                    setItemTooltip(
                        new DistributionChartItemTooltip(
                            <ItemTooltip item={activeItem}/>,
                            sectorElement));
                }

                return activeItemIndex;
            },
            [activeItemId, chartEmpty, controlledActiveItemIndex, items, pieChart]);

    function openUrl(event: MouseEvent, url: string) {
        if (MouseHelper.isAlterActionClick(event)) {
            UrlHelper.openNewTab(url);
        } else {
            setUrlRoute(url);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    const [innerRadius, outerRadius, subtitleFontSize, titleFontSize] =
        map(
            size,
            {
                "large": () => [95, 105, "12px", "30px"],
                "medium": () => [60, 66, "12px", "30px"],
                "small": () => [30, 36, "10px", "17px"],
                "smallThin": () => [37, 40, "10px", "17px"],
                "xl": () => [100, 110, "18px", "42px"]
            });
    const radius = outerRadius - innerRadius;

    function clearActiveItem() {
        setControlledActiveItemIndex(undefined);
        onActiveItemIdChanged?.(undefined);
    }

    return (
        <Box
            className="distributionChart"
            onMouseEnter={() => clearActiveItem()}
            onMouseLeave={() => clearActiveItem()}>
            <Tooltip
                disableFocusListener={true}
                disableHoverListener={true}
                disableInteractive={true}
                disableTouchListener={true}
                open={!_.isNil(itemTooltip)}
                slotProps={{
                    popper: {
                        anchorEl: itemTooltip,
                        disablePortal: true,
                        placement: "top",
                        sx: { zIndex: theme.zIndex.appBar }
                    },
                    tooltip: {
                        sx: {
                            backgroundColor: theme.palette.background.alternate,
                            border: "none",
                            borderRadius: theme.spacing(0.75),
                            padding: 0
                        }
                    }
                }}
                title={itemTooltip?.contentElement ?? ""}>
                <Box>
                    <PieChart
                        height={outerRadius * 2.3}
                        ref={pieChart => setPieChart(pieChart)}
                        style={style}
                        width={outerRadius * 2.3}
                        {...{
                            filter:
                                variant === "pie" && !disableShadows
                                    ? "url(#DistributionChart_shadow)"
                                    : undefined
                        }}>
                        <defs>
                            <filter
                                height="200%"
                                id="DistributionChart_shadow"
                                width="200%"
                                x="-50%"
                                y="-50%">
                                <feDropShadow
                                    dx="2"
                                    dy="2"
                                    floodColor="rgba(0, 0, 0, 0.25)"
                                    stdDeviation="5"/>
                            </filter>
                        </defs>
                        <Pie
                            activeIndex={
                                _(activeItemIndex).
                                    concat(
                                        _.findIndex(
                                            items,
                                            item => item.id === highlightedItemId)).
                                    filter(itemIndex => !_.isNil(itemIndex)).
                                    value() as number[]}
                            activeShape={
                                ({ innerRadius, outerRadius, ...props }: SectorProps) =>
                                    variant === "donut"
                                        ? <Sector
                                            filter="url(#DistributionChart_shadow)"
                                            innerRadius={(innerRadius ?? radius) - 1}
                                            outerRadius={(outerRadius ?? radius) + 1}
                                            tabIndex={-1}
                                            {...props}/>
                                        : <Sector
                                            filter="url(#DistributionChart_shadow)"
                                            innerRadius={innerRadius ?? radius}
                                            outerRadius={(outerRadius ?? radius) + 10}
                                            tabIndex={-1}
                                            {...props}/>}
                            cx="50%"
                            cy="50%"
                            data={items}
                            dataKey={({ value }: DistributionChartItem) => value}
                            endAngle={90}
                            innerRadius={
                                variant === "donut"
                                    ? innerRadius
                                    : undefined}
                            isAnimationActive={false}
                            minAngle={2.5}
                            outerRadius={outerRadius}
                            paddingAngle={
                                variant === "donut" && items.length > 1
                                    ? 3
                                    : undefined}
                            startAngle={450}
                            strokeWidth={0}>
                            {!_.isNil(title) &&
                                <Label
                                    dy={
                                        _.isNil(subtitle)
                                            ? 0
                                            : -innerRadius / 6}
                                    fill={theme.palette.text.primary}
                                    fontSize={titleFontSize}
                                    fontWeight={600}
                                    position="center"
                                    value={title}/>}
                            {subtitle &&
                                <Label
                                    dy={innerRadius / 4}
                                    fill={theme.palette.text.primary}
                                    fontSize={subtitleFontSize}
                                    fontWeight={500}
                                    position="center"
                                    value={subtitle}/>}
                            {!_.isNil(titleUrl) &&
                                !chartEmpty &&
                                <Label
                                    content={
                                        () =>
                                            <circle
                                                cx="50%"
                                                cy="50%"
                                                fill="rgba(0,0,0,0)"
                                                r={innerRadius}
                                                style={{ cursor: "pointer" }}
                                                onClick={event => openUrl(event, titleUrl)}/>}/>}
                            {_.map(
                                items,
                                (dataItem, dataItemIndex) =>
                                    <Cell
                                        className={dataItem.sectorClassName}
                                        fill={dataItem.color}
                                        key={dataItemIndex}
                                        style={{
                                            cursor:
                                                _.isNil(dataItem.url)
                                                    ? undefined
                                                    : "pointer"
                                        }}
                                        onClick={
                                            event => {
                                                if (!_.isNil(dataItem.url)) {
                                                    openUrl(event, dataItem.url);
                                                }
                                            }}
                                        onMouseEnter={
                                            () => {
                                                if (!chartEmpty) {
                                                    setControlledActiveItemIndex(dataItemIndex);
                                                    onActiveItemIdChanged?.(dataItem.id);
                                                }
                                            }}
                                        onMouseLeave={() => clearActiveItem()}/>)}
                        </Pie>
                    </PieChart>
                </Box>
            </Tooltip>
        </Box>);
}

class DistributionChartItemTooltip {
    private readonly boundingClientRect: DOMRect;

    constructor(
        public contentElement: ReactNode,
        sectorElement: SVGPathElement) {
        const sectorOuterMiddlePoint = sectorElement.getPointAtLength(sectorElement.getTotalLength() / 4);
        const sectorStartPoint = sectorElement.getBBox();
        const sectorBoundingClientRect = sectorElement.getBoundingClientRect();
        this.boundingClientRect =
            new DOMRect(
                sectorBoundingClientRect.x + sectorOuterMiddlePoint.x - sectorStartPoint.x,
                sectorBoundingClientRect.y + sectorOuterMiddlePoint.y - sectorStartPoint.y + 40,
                1,
                1);
    }

    public get clientHeight() {
        return this.boundingClientRect.height;
    }

    public get clientWidth() {
        return this.boundingClientRect.width;
    }

    public getBoundingClientRect =
        () => this.boundingClientRect;
}

type ItemTooltipProps = {
    item: DistributionChartItem;
};

function ItemTooltip({ item }: ItemTooltipProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                borderLeft: `solid 4px ${item.color}`,
                borderRadius: theme.spacing(0.75),
                padding: theme.spacing(1)
            }}>
            {item.infoElement}
        </Box>);
}