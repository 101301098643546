import React, { Fragment, ReactNode } from "react";

type FilterProps = {
    children: ReactNode;
    default?: boolean;
    id: string;
    title?: string;
};

export function Filter(_props: FilterProps) {
    return <Fragment/>;
}