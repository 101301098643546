﻿import { Optional } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { ConfigurationController, Contract, ScopeHelper, scopeNodeModelStore, SeveritySelector, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { FailureRiskPolicyMinSeverityEnabled } from "./components";

export function Code() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    const [inheritedScopeId, inheritedFailureRiskPolicyMinSeverity] =
        useMemo(
            () => {
                const parentScopeIds = scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds;
                for (const parentScopeId of parentScopeIds) {
                    if (!_.isNil(scopeNodeMap[parentScopeId].scopeNodeModel.configuration.scopeSections.code.failureRiskPolicyMinSeverityEnabled)) {
                        return [parentScopeId, scopeNodeMap[parentScopeId].scopeNodeModel.configuration.scopeSections.code.failureRiskPolicyMinSeverity];
                    }
                }

                return [undefined, undefined];
            },
            [scopeNodeModel, scopeNodeMap]);

    const [failureRiskPolicyMinSeverity, setFailureRiskPolicyMinSeverity] = useState(scopeNodeModel.configuration.scopeSections.code.failureRiskPolicyMinSeverity ?? inheritedFailureRiskPolicyMinSeverity ?? Contract.Severity.High);
    const [failureRiskPolicyMinSeverityEnabled, setFailureRiskPolicyMinSeverityEnabled] = useState(scopeNodeModel.configuration.scopeSections.code.failureRiskPolicyMinSeverityEnabled);

    const updateConfiguration =
        useCallback(
            async (failureRiskPolicyMinSeverity: Optional<Contract.Severity>, failureRiskPolicyMinSeverityEnabled: Optional<boolean>) => {
                await ConfigurationController.updateCode(
                    new Contract.ConfigurationControllerUpdateCodeRequest(
                        failureRiskPolicyMinSeverityEnabled
                            ? failureRiskPolicyMinSeverity
                            : undefined,
                        failureRiskPolicyMinSeverityEnabled,
                        scopeNodeModel.configuration.id));
                await scopeNodeModelStore.notify(scopeNodeModel.configuration.id);
            },
            [scopeNodeModel]);

    const theme = useTheme();
    return (
        <Stack sx={{ margin: theme.spacing(2) }}>
            <FailureRiskPolicyMinSeverityEnabled
                failureRiskPolicyMinSeverityEnabled={failureRiskPolicyMinSeverityEnabled}
                inheritDisabled={scopeNodeModel.configuration.id === ScopeHelper.customerId}
                inheritedFailureRiskPolicyMinSeverity={inheritedFailureRiskPolicyMinSeverity}
                inheritedScopeId={inheritedScopeId}
                setFailureRiskPolicyMinSeverityEnabled={
                    enabled => {
                        setFailureRiskPolicyMinSeverityEnabled(enabled);
                        updateConfiguration(
                            failureRiskPolicyMinSeverity,
                            enabled);
                    }
                }/>
            {failureRiskPolicyMinSeverityEnabled &&
                <Box sx={{ marginLeft: theme.spacing(3) }}>
                    <SeveritySelector
                        justify="left"
                        selectedSeverity={failureRiskPolicyMinSeverity}
                        sx={{ width: "fit-content" }}
                        variant="outlined"
                        onSelectedSeverityChanged={
                            severity => {
                                setFailureRiskPolicyMinSeverity(severity);
                                updateConfiguration(
                                    severity,
                                    failureRiskPolicyMinSeverityEnabled);
                            }
                        }/>
                </Box>}
        </Stack>);
}