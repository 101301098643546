import _ from "lodash";
import { useCallback } from "react";
import { Contract } from "../controllers";
import { projectModelStore, scopeNodeModelStore } from "../stores";
import { ScopeHelper, TenantHelper } from "../utilities";
import { useSelectedScopeId } from "./useSelectedScopeId";

export function useGetSelectedScopeHasData() {
    const activeScopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(undefined, true);
    const projectModelMap = projectModelStore.useGetProjectModelMap();
    const { selectedScopeId } = useSelectedScopeId();
    return useCallback(
        (tenantModels: Contract.TenantModel[]) => {
            const selectedScopeNode = activeScopeNodeMap[selectedScopeId];
            return !_.isNil(selectedScopeNode) && ScopeHelper.isProjectScope(selectedScopeNode.scopeNodeModel)
                ? _.some(
                    selectedScopeNode.scopeIds,
                    scopeId =>
                        ScopeHelper.isProject(activeScopeNodeMap[scopeId].scopeNodeModel) &&
                        projectModelMap[scopeId].state.status !== Contract.ProjectStateStatus.Pending)
                : TenantHelper.hasData(tenantModels);
        },
        [activeScopeNodeMap, projectModelMap, selectedScopeId]);
}