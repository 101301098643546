import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { useGetOciIamUserApiKeyRiskContext } from "../contexts";

export function useOciIamUserApiKeyNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciIamUserApiKeyNotRotatedRisk;
    const userApiKeyModel = entityModelStore.useGet(risk.entityId) as Contract.OciIamUserApiKeyModel;

    const getOciIamUserApiKeyRiskContext = useGetOciIamUserApiKeyRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();
    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciIamUserApiKeyNotRotatedRiskDefinition",
            () => ({
                description: "{{user}} has an {{userApiKey}} that was not rotated for more than {{userApiKeyRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                sections: {
                    resolution: {
                        step1: "Regenerating user API keys may break existing client connectivity, as the client will try to connect with older user API keys. Therefore, it is better to create a new user API key and delete the old one after you migrate your application to use the new key.",
                        step2: "Click **Add API key** and then **Add**",
                        step3: "Before deleting the old key, migrate your applications (if any) to use the newly created API key",
                        step4: "In the **API keys** page, select the 3-dot menu next to the old user API key and click **Delete**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (localization.description({
            user:
                <Entity
                    entityIdOrModel={userApiKeyModel.userId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            userApiKey:
                <Entity
                    entityIdOrModel={userApiKeyModel}
                    entityTypeNameTranslatorOptions={{ variant: "text" }}
                    variant="typeText"/>,
            userApiKeyRotationTimeFrame: risk.credentialRotationTimeFrame
        })),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.User,
                userApiKeyModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const keyRiskContext = getOciIamUserApiKeyRiskContext(userApiKeyModel);
            return [
                keyRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.credentialRotationTimeFrame),
                keyRiskContext.creationTime,
                keyRiskContext.belongsTo,
                keyRiskContext.sensitive
            ];
        });
}