import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EditIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M6.75556 22C6.35556 22 5.85556 22 5.35556 22H5.05556C4.35556 22 3.85556 22 3.35556 21.5C2.95556 21 2.95556 20.4 3.05556 19.8V19.5C3.15556 17.9 3.25556 17 3.65556 16.2C4.05556 15.4 4.55556 14.7 5.65556 13.6L13.5556 4.9C14.2556 4.2 14.5556 3.8 15.0556 3.5C15.5556 3.2 16.2556 3 16.8556 3C17.4556 3 18.1556 3.1 18.6556 3.5C19.1556 3.8 19.4556 4.1 20.1556 4.9C20.8556 5.6 21.2556 6 21.4556 6.5C21.7556 7.1 21.9556 7.7 21.8556 8.3C21.8556 8.9 21.6556 9.6 21.3556 10.1C21.0556 10.6 20.6556 11 19.9556 11.6L11.9556 19.3C10.6556 20.6 9.85556 21.3 8.95555 21.7C8.25556 22 7.55556 22 6.55556 22H6.75556ZM5.05556 20H5.25556C6.85556 20 7.65556 20 8.25556 19.8C8.85556 19.6 9.45556 19 10.6556 17.9L18.6556 10.2C19.2556 9.7 19.5556 9.3 19.6556 9.1C19.7556 8.8 19.8556 8.5 19.8556 8.2C19.8556 7.9 19.8556 7.6 19.6556 7.3C19.5556 7.1 19.2556 6.7 18.6556 6.2C18.0556 5.6 17.7556 5.3 17.5556 5.2C17.2556 5.1 17.0556 5 16.7556 5C16.4556 5 16.1556 5 15.9556 5.2C15.7556 5.3 15.4556 5.7 14.8556 6.2L6.95556 14.7C5.95556 15.7 5.55556 16.2 5.25556 16.8C5.05556 17.3 4.95556 18 4.85556 19.5V19.8C4.85556 19.8 4.85556 19.8 4.85556 19.9L5.05556 20Z"/>
            <path d="M7.26546 13.8506L5.85124 15.2648L10.3767 19.7903L11.7909 18.3761L7.26546 13.8506Z"/>
            <path d="M14.9696 5.58567L13.5553 6.99989L18.406 11.8059L19.8202 10.3917L14.9696 5.58567Z"/>
        </SvgIcon>);
}