import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, Loading, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { BuiltInRiskPolicyItem, Contract, CustomerConsoleAppUrlHelper, LicensingHelper, RiskController, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem, StorageHelper, useComplianceTranslator, useDeliveryTranslator, useEntityTypeNameTranslator, useFileNameScopeTranslator, useRiskPolicyCategoryTranslator, useRiskPolicyTranslator, useScopeNameTranslator, useTenantTypeTranslator, useTheme } from "../../../../../../../../../common";
import { useGetRiskPolicyItemBuiltInComplianceTypes } from "../../../../../../Compliance/hooks";
import { ExclusionHelper } from "../../../../../../Risks/utilities";
import { CustomerSideViewItemType } from "../../../../../../SideView";
import { ActionsCell, AnalyzedEntityCount, FailedEntitiesCell, useRiskPoliciesContext } from "../../../../../components";
import { RiskPolicyHelper } from "../../../../../utilities";
import { PolicyTableDefinition } from "../../../useDefinition";
import { CommonTableColumnId, useCommonRiskPolicyItemTableItems } from "../../useCommonRiskPolicyItemTableItems";

export function useCloudRiskPoliciesBuiltInDefinition(scopeId: string): PolicyTableDefinition {
    const getRiskPolicyItemBuiltInComplianceTypes = useGetRiskPolicyItemBuiltInComplianceTypes();
    const { scopeNodeMap } = useRiskPoliciesContext();
    const scopeNode = scopeNodeMap[scopeId];
    const commonRiskPolicyItemTableItems =
        useCommonRiskPolicyItemTableItems(
            scopeId,
            RiskPoliciesType.Cloud);

    const complianceTranslator = useComplianceTranslator();
    const deliveryTranslator = useDeliveryTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const fileNameScopeTranslator = useFileNameScopeTranslator();
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useDefinition.hooks.cloud.hooks.useCloudRiskPoliciesBuiltInDefinition",
            () => ({
                columns: {
                    analyzedEntityCount: {
                        count: "{{entityCount | NumberFormatter.humanize}}",
                        title: "Scanned Resources"
                    },
                    exclusions: {
                        entities: "{{count | NumberFormatter.humanize}} Excluded {{translatedEntityTypeName}}",
                        patterns: "{{count | NumberFormatter.humanize}} Excluded {{translatedEntityTypeName}} by pattern"
                    },
                    failedEntityCount: "Failed Resources",
                    severity: {
                        empty: "Dynamic",
                        title: "Severity"
                    },
                    status: {
                        false: "Disabled",
                        inheritedFalse: "Disabled (Inherited)",
                        inheritedTrue: "Enabled (Inherited)",
                        true: "Enabled"
                    }
                },
                csvExport: {
                    fileName: "Cloud_Built_In_Policies_{{translatedScopeName}}",
                    result: {
                        failure: "Failed ({{riskCount | NumberFormatter.humanize}} findings on {{failedTenantCount | NumberFormatter.humanize}} accounts)",
                        success: "Success"
                    }
                }
            }));

    const getCsvItemPage =
        (items: RiskPolicyItem[]) =>
            async () => {
                const { riskPolicyConfigurationTypeNameToDataMap: builtInRiskPolicyConfigurationTypeNameToDataMap } =
                    await RiskController.getBuiltInRiskPolicyModels(
                        new Contract.RiskControllerGetBuiltInCloudRiskPolicyModelsRequest(
                            _.map(
                                items,
                                item => item.riskPolicyConfigurationTypeName),
                            scopeId));
                const riskPolicyConfigurationTypeNameToScopeIdToParentRiskPolicyModelMap =
                    _.mapValues(
                        builtInRiskPolicyConfigurationTypeNameToDataMap,
                        builtInRiskData =>
                            _.keyBy(
                                builtInRiskData.parentRiskPolicyModels,
                                parentRiskPolicyModel => parentRiskPolicyModel.riskPolicyConfiguration.scopeId));

                function getEnabledInheritedScopeName(riskPolicyConfigurationTypeName: string) {
                    for (const parentScopeId of scopeNode.parentScopeIds) {
                        if (!_.isNil(riskPolicyConfigurationTypeNameToScopeIdToParentRiskPolicyModelMap[riskPolicyConfigurationTypeName][parentScopeId].riskPolicyConfiguration.enabled)) {
                            return scopeNameTranslator(parentScopeId);
                        }
                    }

                    return undefined;
                }

                return _(items).
                    map(
                        item => {
                            const [exclusionEntityTypeNameToCountMap, exclusionEntityTypeNameToPropertyPatternCountMap] =
                                ExclusionHelper.getExclusionEntityTypeNameToCountMap(
                                    builtInRiskPolicyConfigurationTypeNameToDataMap[item.riskPolicyConfigurationTypeName].parentRiskPolicyModels,
                                    builtInRiskPolicyConfigurationTypeNameToDataMap[item.riskPolicyConfigurationTypeName].scopeRiskPolicyModel,
                                    item.riskPolicyConfigurationTypeName,
                                    builtInRiskPolicyConfigurationTypeNameToDataMap[item.riskPolicyConfigurationTypeName].tenantRiskPolicyModels);
                            const complianceIdOrSectionTypes =
                                LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                                    ? _(getRiskPolicyItemBuiltInComplianceTypes(item)).
                                        as<string>().
                                        concat(item.customComplianceIds).
                                        value()
                                    : undefined;
                            return {
                                /* eslint-disable sort-keys-fix/sort-keys-fix */
                                Cloud:
                                    _(item.tenantTypes).
                                        map(tenantType => tenantTypeTranslator(tenantType)).
                                        join("\n"),
                                Category: riskPolicyCategoryTranslator(item.riskPolicyTypeMetadata.category),
                                Name: riskPolicyTranslator(item.riskPolicyConfigurationTypeName).title,
                                Description:
                                    riskPolicyTranslator(
                                        item.riskPolicyConfigurationTypeName,
                                        "text").
                                        description,
                                Severity: item.riskPolicyTypeMetadata.severity ?? localization.columns.severity.empty(),
                                Compliance:
                                    _.isNil(complianceIdOrSectionTypes)
                                        ? undefined
                                        : _.isEmpty(complianceIdOrSectionTypes)
                                            ? "-"
                                            : _(complianceIdOrSectionTypes).
                                                map(complianceIdOrSectionType => complianceTranslator(complianceIdOrSectionType).title).
                                                join("\n"),
                                Status:
                                    item.enabled
                                        ? (item as BuiltInRiskPolicyItem).enabledInherited
                                            ? localization.columns.status.inheritedTrue({
                                                scope: getEnabledInheritedScopeName(item.riskPolicyConfigurationTypeName)
                                            })
                                            : localization.columns.status.true()
                                        : (item as BuiltInRiskPolicyItem).enabledInherited
                                            ? localization.columns.status.inheritedFalse({
                                                scope: getEnabledInheritedScopeName(item.riskPolicyConfigurationTypeName)
                                            })
                                            : localization.columns.status.false(),
                                Result:
                                    _.isNil(item.result)
                                        ? "-"
                                        : item.success
                                            ? localization.csvExport.result.success()
                                            : localization.csvExport.result.failure({
                                                failedTenantCount: item.result.failedTenantCount,
                                                riskCount: item.result.riskCount
                                            }),
                                Exclusions:
                                    _<string>([]).
                                        concat(
                                            _(exclusionEntityTypeNameToCountMap).
                                                pickBy(exclusionEntityCount => exclusionEntityCount > 0).
                                                map(
                                                    (exclusionEntityCount, entityTypeName) =>
                                                        localization.columns.exclusions.entities({
                                                            count: exclusionEntityCount,
                                                            translatedEntityTypeName:
                                                                entityTypeNameTranslator(
                                                                    entityTypeName,
                                                                    {
                                                                        count: exclusionEntityCount,
                                                                        includeServiceName: true,
                                                                        variant: "text"
                                                                    })
                                                        })).
                                                value(),
                                            _(exclusionEntityTypeNameToPropertyPatternCountMap).
                                                pickBy(exclusionEntityPropertyPatternCount => exclusionEntityPropertyPatternCount > 0).
                                                map(
                                                    (exclusionEntityPropertyPatternCount, entityTypeName) =>
                                                        localization.columns.exclusions.patterns({
                                                            count: exclusionEntityPropertyPatternCount,
                                                            translatedEntityTypeName:
                                                                entityTypeNameTranslator(
                                                                    entityTypeName,
                                                                    {
                                                                        count: exclusionEntityPropertyPatternCount,
                                                                        includeServiceName: true,
                                                                        variant: "text"
                                                                    })
                                                        })).
                                                value()).
                                        join("\n"),
                                Findings:
                                    !item.success
                                        ? CustomerConsoleAppUrlHelper.getRisksUrl(
                                            Contract.RisksView.Open,
                                            {
                                                policyConfigurationTypeNameOrIds: [item.riskPolicyConfigurationTypeName],
                                                tenantIds:
                                                    scopeNode.scopeNodeModel.type === Contract.ScopeType.Customer
                                                        ? undefined
                                                        : scopeNodeMap[scopeId].tenantIds
                                            })
                                        : "-",
                                Actions:
                                    _.isEmpty(item.deliveries)
                                        ? "-"
                                        : _(item.deliveries).
                                            map(({ delivery }) => deliveryTranslator(delivery.typeName as Contract.DeliveryDerivedTypeNames, "title").text).
                                            join("\n")
                                /* eslint-enable sort-keys-fix/sort-keys-fix */
                            };
                        }).
                    orderBy([
                        csvItem => csvItem.Cloud,
                        csvItem => csvItem.Category,
                        csvItem => csvItem.Name
                    ]).
                    value();
            };

    const theme = useTheme();
    return useMemo(
        () =>
            new PolicyTableDefinition(
                columnIdToItemValuesMap =>
                    _<ReactNode>([]).
                        concat(
                            _.map(
                                [
                                    CommonTableColumnId.TenantsType,
                                    CommonTableColumnId.Category,
                                    CommonTableColumnId.Name,
                                    CommonTableColumnId.Severity
                                ],
                                tableColumnId => commonRiskPolicyItemTableItems[tableColumnId].getColumn(columnIdToItemValuesMap[tableColumnId]))).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                            commonRiskPolicyItemTableItems.compliances.getColumn(columnIdToItemValuesMap[CommonTableColumnId.Compliances])).
                        concat([
                            commonRiskPolicyItemTableItems.status.getColumn(),
                            <DataTableColumn
                                id={TableColumnId.AnalyzedEntityCount}
                                key={TableColumnId.AnalyzedEntityCount}
                                render={
                                    ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                        <Loading container="cell">
                                            <AnalyzedEntityCount
                                                item={item}
                                                scopeId={scopeId}/>
                                        </Loading>}
                                sortOptions={{ enabled: false }}
                                title={localization.columns.analyzedEntityCount.title()}/>,
                            <DataTableColumn
                                id={TableColumnId.FailedEntityCount}
                                key={TableColumnId.FailedEntityCount}
                                render={
                                    ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                        <Loading container="cell">
                                            <FailedEntitiesCell item={item}/>
                                        </Loading>}
                                sortOptions={{ type: DataTableSortType.Numeric }}
                                title={localization.columns.failedEntityCount()}/>,
                            commonRiskPolicyItemTableItems.excludedEntityIds.getColumn(),
                            <DataTableColumn
                                disableAction={true}
                                headerSx={{ minWidth: theme.spacing(5) }}
                                id={TableColumnId.Actions}
                                key={TableColumnId.Actions}
                                orderable={false}
                                render={
                                    ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                        <ActionsCell
                                            item={item}
                                            riskPoliciesType={RiskPoliciesType.Cloud}
                                            scopeId={scopeId}/>}
                                resizable={false}
                                selectorOptions={{ disabled: true }}
                                sortOptions={{ enabled: false }}/>
                        ]).
                        value(),
                {
                    ...commonRiskPolicyItemTableItems.tenantsType.getValueMap(),
                    ...commonRiskPolicyItemTableItems.category.getValueMap(),
                    ...commonRiskPolicyItemTableItems.name.getValueMap(),
                    ...commonRiskPolicyItemTableItems.severity.getValueMap(),
                    ...commonRiskPolicyItemTableItems.compliances.getValueMap(),
                    [TableColumnId.FailedEntityCount]:
                        item =>
                            item.riskPolicyTypeMetadata.temporal || !item.enabled
                                ? -1
                                : item.result.failedEntityCount,
                    ...commonRiskPolicyItemTableItems.status.getValueMap()
                },
                item => RiskPolicyHelper.getSideViewItemId(item.riskPolicyConfigurationTypeNameOrId, scopeId, RiskPoliciesType.Cloud),
                {
                    hashRouteTemplate: `${CustomerSideViewItemType.RiskPolicies}/{itemId}`
                },
                {
                    columnOrder: StorageHelper.customerRiskPoliciesTableColumnOrder(RiskPoliciesView.BuiltIn),
                    columnSelector: StorageHelper.customerRiskPoliciesTableColumnSelector(RiskPoliciesView.BuiltIn),
                    tableFilters: StorageHelper.customerRiskPoliciesTableFilters(RiskPoliciesView.BuiltIn)
                },
                undefined,
                {
                    getCsvItemPage,
                    prefix: localization.csvExport.fileName({ translatedScopeName: fileNameScopeTranslator(scopeId) })
                },
                undefined,
                {
                    defaultSortColumnIds: [CommonTableColumnId.TenantsType, CommonTableColumnId.Category, CommonTableColumnId.Name],
                    rowOptions: {
                        getUrl: (item: any) => CustomerConsoleAppUrlHelper.getRiskPoliciesProfileHashUrl(RiskPoliciesType.Cloud, item.riskPolicyConfigurationTypeNameOrId, scopeId)
                    }
                }
            ),
        [commonRiskPolicyItemTableItems, scopeId]);
}

export enum TableColumnId {
    Actions = "actions",
    AnalyzedEntityCount = "analyzedEntityCount",
    FailedEntityCount = "failedEntityCount"
}