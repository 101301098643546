import { InlineItems, Sx, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ActionSelectorSelection } from ".";
import { Contract, InlinePermissionActions, useActionRiskCategoryTranslator } from "../../..";

type InlineActionsSelectionProps = {
    selection?: InlineActionsSelectionData | ActionSelectorSelection;
    sx?: SxProps;
};

export function InlineActionsSelection({ selection, sx }: InlineActionsSelectionProps) {
    const { actionNamePatterns, actionRiskCategories, actions } =
        selection instanceof ActionSelectorSelection
            ? selection.getInlineSelectionData()
            : { actionNamePatterns: selection?.actionNamePatterns, actionRiskCategories: selection?.actionRiskCategories, actions: selection?.actions };

    const actionRiskCategoryTranslator = useActionRiskCategoryTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.inlineActionsSelection",
            () => ({
                actionNamePattern: [
                    "permission pattern",
                    "{{count | NumberFormatter.humanize}} permission patterns"
                ],
                actionRiskCategory: [
                    "permission category",
                    "{{count | NumberFormatter.humanize}} permission categories"
                ],
                actionsPlaceholder: "these permissions"
            }));

    const textSx =
        Sx.combine(
            {
                fontWeight: 600,
                textDecoration: "underline"
            },
            sx);

    return (
        <Typography
            component="span"
            sx={textSx}>
            {!_.isNil(actionNamePatterns) &&
                <InlineItems
                    items={actionNamePatterns}
                    namePluralizer={localization.actionNamePattern}
                    sx={textSx}
                    variant="itemOrItemCountAndType">
                    {actionNamePattern =>
                        <Typography
                            component="span"
                            noWrap={true}>
                            {actionNamePattern}
                        </Typography>}
                </InlineItems>}
            {!_.isNil(actionRiskCategories) &&
                <InlineItems
                    items={actionRiskCategories}
                    namePluralizer={localization.actionRiskCategory}
                    sx={textSx}
                    variant="itemOrItemCountAndType">
                    {actionRiskCategory =>
                        <Typography
                            component="span"
                            key={actionRiskCategory}
                            noWrap={true}>
                            {actionRiskCategoryTranslator(actionRiskCategory).title}
                        </Typography>}
                </InlineItems>}
            {!_.isNil(actions) &&
                <InlinePermissionActions
                    permissionActions={actions}
                    sx={textSx}
                    variant="itemOrItemCountAndType"/>}
            {(_.isNil(actionNamePatterns) &&
                _.isNil(actionRiskCategories) &&
                _.isNil(actions) ||
                _.isNil(selection)) &&
                localization.actionsPlaceholder()}
        </Typography>);
}

export type InlineActionsSelectionData = {
    actionNamePatterns?: string[];
    actionRiskCategories?: Contract.ActionRiskCategory[];
    actions?: string[];
};