import { Link, NumberFormatter, Optional, Shadows, TimeFormatter, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ChartDataItem } from "../../..";
import { useTheme, useVulnerabilityScoreSourceTypeTranslator, VulnerabilityEventType, VulnerabilityScoreSourceType } from "../../../../../../../../../../../../common";
import { useVulnerabilityEventTypeTranslator } from "../../../../../hooks";
import { ChartClickEventCoordinate } from "../TimelineScoreAndEventTrend";
import { getFilteredEvents } from "./utilities";

export type TooltipData = {
    coordinate: Optional<ChartClickEventCoordinate>;
    payload: Optional<TooltipDataPayload[]>;
};

type TooltipDataPayload = {
    payload: ChartDataItem;
};

type TooltipProps = {
    active?: boolean;
    activeEventType?: Optional<VulnerabilityEventType>;
    coordinate?: ChartClickEventCoordinate;
    data: Optional<TooltipData>;
    freeze: boolean;
    hoverEventType?: Optional<VulnerabilityEventType>;
    label?: string;
    payload?: TooltipDataPayload[];
    startTime: number;
    type: VulnerabilityScoreSourceType.Vpr | VulnerabilityScoreSourceType.Epss;
};

export function Tooltip({ active, activeEventType, coordinate, data, freeze, hoverEventType, label, payload, startTime, type }: TooltipProps) {
    const vulnerabilityNameTranslator = useVulnerabilityEventTypeTranslator();
    const vulnerabilityScoreSourceTypeTranslator = useVulnerabilityScoreSourceTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.timelineScoreAndEventTrendChart.timelineScoreAndEventTrend.tooltip",
            () => ({
                label: {
                    title: "Current {{type}}: {{value}}"
                }
            }));

    const [events, score, time] =
        useMemo(
            () => {
                const freezePayload = data?.payload?.[0]?.payload;
                const hoverPayload = payload?.[0]?.payload;

                return freeze
                    ? [
                        getFilteredEvents(
                            activeEventType,
                            freezePayload?.events,
                            hoverEventType),
                        freezePayload?.score,
                        freezePayload?.dayTime
                    ]
                    : [
                        getFilteredEvents(
                            activeEventType,
                            hoverPayload?.events,
                            hoverEventType),
                        hoverPayload?.score,
                        Number(label)
                    ];
            },
            [activeEventType, data, freeze, hoverEventType, label, payload]);

    const leftTooltipDirection =
        useMemo(
            () =>
                time
                    ? ((time - startTime) / (Date.now() - startTime)) > 0.5
                    : false,
            [startTime, time]);

    const theme = useTheme();
    return (
        _.isNil(active)
            ? null
            : <Stack
                sx={[
                    {
                        left:
                            !freeze && coordinate?.x
                                ? coordinate.x
                                : data?.coordinate?.x ?? 0,
                        position: "absolute",
                        top:
                            !freeze && coordinate?.y
                                ? coordinate.y
                                : data?.coordinate?.y ?? 0
                    },
                    leftTooltipDirection && {
                        transform: "translateX(-100%)"
                    }
                ]}>
                {_.isNil(hoverEventType) &&
                    <Stack direction="row">
                        <Stack
                            sx={{
                                backgroundColor: theme.palette.background.alternate,
                                border: theme.border.alternate,
                                borderRadius: theme.spacing(0.75),
                                boxShadow: theme.shadows[Shadows.Tooltip],
                                color: theme.palette.text.primary
                            }}>
                            <Stack
                                gap={theme.spacing(1)}
                                sx={{
                                    borderBottom: theme.border.primary,
                                    padding: theme.spacing(1.5),
                                    paddingTop: theme.spacing(1)
                                }}>
                                {time &&
                                    <Typography
                                        sx={{ color: theme.palette.text.secondary }}
                                        variant="body1">
                                        {TimeFormatter.monthDayAndYear(time)}
                                    </Typography>}
                                {_.map(
                                    events,
                                    (event, index) =>
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            gap={theme.spacing(1)}
                                            key={`${event.eventType}:${index}`}>
                                            <Stack
                                                sx={{
                                                    backgroundColor: theme.palette.workload.vulnerabilityEventType(event.eventType),
                                                    height: "100%",
                                                    width: theme.spacing(0.25)
                                                }}/>
                                            <Link
                                                sx={{ pointerEvents: "auto" }}
                                                urlOrGetUrl={event.url}
                                                variant="external">
                                                <Typography
                                                    noWrap={true}
                                                    variant="h5">
                                                    {vulnerabilityNameTranslator(event.eventType)}
                                                </Typography>
                                            </Link>
                                        </Stack>)}
                            </Stack>
                            {!_.isNil(score) &&
                                <Typography
                                    noWrap={true}
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        padding: theme.spacing(1)
                                    }}
                                    variant="body1">
                                    {localization.label.title({
                                        type: vulnerabilityScoreSourceTypeTranslator(type),
                                        value:
                                            type === VulnerabilityScoreSourceType.Vpr
                                                ? score
                                                : NumberFormatter.percentageFraction(score)
                                    })}
                                </Typography>}
                        </Stack>
                    </Stack>}
            </Stack>);
}