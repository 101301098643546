import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../common";
import { ConditionType } from "../../../utilities";

export function useTenantNamePatternConditionDefinition(condition: Contract.ProjectConfigurationRuleCondition) {
    const entityNamePatternCondition = condition as Contract.ProjectConfigurationRuleNamePatternTenantCondition;
    return useMemo(
        () => ({
            conditionType: ConditionType.TenantNamePattern,
            not: entityNamePatternCondition.not,
            value: entityNamePatternCondition.namePattern
        }),
        [entityNamePatternCondition]);
}