﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetDataAnalysisResourceRiskContext } from "../../../useGetDataAnalysisResourceRiskContext";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpStorageBucketRiskContext() {
    return useMemo(
        () => useGcpStorageBucketRiskContext,
        []);
}

function useGcpStorageBucketRiskContext(bucketModel: Contract.GcpStorageBucketModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(bucketModel);
    const dataAnalysisResourceRiskContext = useGetDataAnalysisResourceRiskContext()(bucketModel);
    const bucket = bucketModel.entity as Contract.GcpStorageBucket;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpStorageBucketRiskContext",
            () => ({
                retentionPolicy: "The {{translatedBucketTypeName}}'s retention policy is set to **{{defaultObjectRetentionTimeFrame | TimeSpanFormatter.humanizeDays}}**"
            }));

    return {
        ...dataAnalysisResourceRiskContext,
        ...entityRiskContext,
        retentionPolicy:
            _.isNil(bucket.defaultObjectRetentionTimeFrame)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.retentionPolicy({
                        defaultObjectRetentionTimeFrame: bucket.defaultObjectRetentionTimeFrame,
                        translatedBucketTypeName:
                            entityTypeNameTranslator(
                                bucket.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    }))
    };
}