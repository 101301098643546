import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAzurePostgreSqlServerRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useAzurePostgreSqlServerLogConnectionsDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzurePostgreSqlServerLogConnectionsDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzurePostgreSqlServerModel;

    const getAzurePostgreSqlServerRiskContext = useGetAzurePostgreSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzurePostgreSqlServerLogConnectionsDisabledRiskDefinition",
            () => ({
                description: "{{server}} server parameter log_connections is not enabled",
                sections: {
                    resolution: {
                        step1: "Set the **log_connections** parameter to **ON** and click on **Save** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.ServerConfigurations}/>,
            localization.sections.resolution.step1()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzurePostgreSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}