﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsAutoScalingAutoScalingGroupsRiskContext, useGetAwsEc2InstancesRiskContext, useGetAwsEc2LaunchTemplateRiskContext } from "../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useGetAwsEc2InstanceMetadataServiceVersionRiskContext } from "../useGetAwsEc2InstanceMetadataServiceVersionRiskContext";
import { useGetResolutionSteps } from "../useGetResolutionSteps";
import { DetailsSection } from "./components";

export function useAwsEc2InstanceMetadataServiceVersionRiskLaunchTemplateDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel) {
    const launchTemplateModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEc2LaunchTemplateModel;
    const launchTemplate = (launchTemplateModel.entity) as Contract.AwsEc2LaunchTemplate;

    const getAwsAutoScalingAutoScalingGroupsRiskContext = useGetAwsAutoScalingAutoScalingGroupsRiskContext();
    const getAwsEc2InstanceMetadataServiceVersionRiskContext = useGetAwsEc2InstanceMetadataServiceVersionRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsEc2LaunchTemplateRiskContext = useGetAwsEc2LaunchTemplateRiskContext();
    const getResolutionSteps = useGetResolutionSteps();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskLaunchTemplateDefinition",
            () => ({
                description: "{{launchTemplate}} is not enforcing Instance Metadata Service Version 2 (IMDSv2)",
                sections: {
                    details: "Launch Template Revisions",
                    resolution: {
                        step1: "Select the launch template **{{launchTemplateName}}** and choose **Actions**, **Modify template (Create new version)**",
                        step2: "Expand the **Advanced Details** section",
                        step3: "Under **Metadata accessible**, select **Enabled**",
                        step4: "Under **Metadata version**, select **V2 only (token required)**",
                        step5: "Click on the **Create template version** button",
                        step6: "Set the new version as the default by clicking on **Actions > Set default version**",
                        step7: "Make sure the {{autoScalingGroups}} are using the latest version",
                        step8: "Make sure the {{instances}} are using the latest version",
                        step9: "Wait until all instances are relaunched with the updated metadata version",
                        step10: "On the Launch Template page, click Versions, and delete insecure versions by clicking **Delete template version**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchTemplate:
                <Entity
                    entityIdOrModel={launchTemplateModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            ...getResolutionSteps(
                launchTemplate.typeName,
                riskModel),
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2LaunchTemplateUrl(launchTemplate)),
            localization.sections.resolution.step1({ launchTemplateName: launchTemplate.displayName }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            !_.isEmpty(riskModel.risk.autoScalingGroupIds)
                ? localization.sections.resolution.step7({
                    autoScalingGroups:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.autoScalingGroupIds}
                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                            variant="itemCountAndType"/>
                })
                : undefined,
            !_.isEmpty(riskModel.risk.aggregatedEntityIds)
                ? localization.sections.resolution.step8({
                    instances:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.aggregatedEntityIds!}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            variant="itemCountAndType"/>
                })
                : undefined,
            localization.sections.resolution.step9(),
            localization.sections.resolution.step10()
        ],
        riskModel,
        () => {
            const autoScalingGroupsContextItems = getAwsAutoScalingAutoScalingGroupsRiskContext(riskModel.risk.autoScalingGroupIds!);
            const instanceMetadataServiceVersionRiskContextItems = getAwsEc2InstanceMetadataServiceVersionRiskContext(riskModel);
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const launchTemplateContextItems = getAwsEc2LaunchTemplateRiskContext(launchTemplateModel);
            return [
                launchTemplateContextItems.generalInformation,
                launchTemplateContextItems.sensitive,
                launchTemplateContextItems.getAutoScalingGroupsContextItem(riskModel.risk.autoScalingGroupIds),
                autoScalingGroupsContextItems.sensitive,
                instanceMetadataServiceVersionRiskContextItems.instances,
                instanceMetadataServiceVersionRiskContextItems.permissionActions,
                instanceMetadataServiceVersionRiskContextItems.instancePublicAccess,
                instancesContextItems.usedInstances,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                launchTemplateContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)];
        },
        {
            sections:
                _<RiskDefinitionSection>([]).
                    concatIf(
                        !_.isEmpty(riskModel.risk.aggregatedEntityIds),
                        () => new RiskDefinitionSection(
                            <DetailsSection riskModel={riskModel}/>,
                            localization.sections.details())).
                    value()
        });
}