﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpContainerClusterNodePoolNodeImageTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpContainerClusterNodePoolNodeImageTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpContainerClusterNodePoolNodeImageType]: {
                    [Contract.GcpContainerClusterNodePoolNodeImageType.Cos]: "Container-Optimized OS with Docker",
                    [Contract.GcpContainerClusterNodePoolNodeImageType.CosContainerd]: "Container-Optimized OS with containerd",
                    [Contract.GcpContainerClusterNodePoolNodeImageType.Ubuntu]: "Ubuntu with Docker",
                    [Contract.GcpContainerClusterNodePoolNodeImageType.UbuntuContainerd]: "Ubuntu with containerd",
                    [Contract.GcpContainerClusterNodePoolNodeImageType.WindowsLtsc]: "Windows Long Term Servicing Channel with Docker",
                    [Contract.GcpContainerClusterNodePoolNodeImageType.WindowsLtscContainerd]: "Windows Long Term Servicing Channel with containerd",
                    [Contract.GcpContainerClusterNodePoolNodeImageType.WindowsSac]: "Windows Semi-Annual Channel with Docker",
                    [Contract.GcpContainerClusterNodePoolNodeImageType.WindowsSacContainerd]: "Windows Semi-Annual Channel with containerd"
                }
            }));
    return (imageType: Contract.GcpContainerClusterNodePoolNodeImageType) =>
        localization[Contract.TypeNames.GcpContainerClusterNodePoolNodeImageType][imageType]();
}