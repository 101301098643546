import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, EntitiesCell, IntRangeHelper, NetworkScopeFormatter, NetworkScopeHelper } from "../../../common";

type OciNetworkingNetworkSecurityGroupRulesTableProps = {
    getHighlightColor?: (rule: Contract.OciNetworkingNetworkSecurityGroupRule, opacity?: number) => Optional<string>;
    inbound: boolean;
    networkSecurityGroupOcidToEntityIdReferenceMap: Dictionary<string>;
    rules: Contract.OciNetworkingNetworkSecurityGroupRule[];
};

export function OciNetworkingNetworkSecurityGroupRulesTable({ getHighlightColor, inbound, networkSecurityGroupOcidToEntityIdReferenceMap, rules }: OciNetworkingNetworkSecurityGroupRulesTableProps) {

    const localization =
        useLocalization(
            "tenants.oci.ociNetworkingNetworkSecurityGroupRulesTable",
            () => ({
                columns: {
                    description: "Description",
                    endpoint: {
                        title: {
                            inbound: "Source",
                            outbound: "Destination"
                        }
                    },
                    endpointType: {
                        title: {
                            inbound: "Source Type",
                            outbound: "Destination Type"
                        },
                        [Contract.TypeNames.OciNetworkingNetworkSecurityGroupRuleEndpointType]: {
                            [Contract.OciNetworkingNetworkSecurityGroupRuleEndpointType.CidrBlock]: "CIDR",
                            [Contract.OciNetworkingNetworkSecurityGroupRuleEndpointType.NetworkSecurityGroup]: "NSG",
                            [Contract.OciNetworkingNetworkSecurityGroupRuleEndpointType.Service]: "Service"
                        }
                    },
                    icmp: {
                        all: "All",
                        title: "Type and Code"
                    },
                    portRange:
                        {
                            all: "All",
                            title: {
                                destination: "Destination Port Range",
                                source: "Source Port Range"
                            }
                        },
                    protocol: "Protocol",
                    stateless: {
                        false: "No",
                        title: "Stateless",
                        true: "Yes"
                    }
                },
                empty: "No Rules"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() =>
                _.orderBy(
                    rules,
                    rule => !_.isNil(getHighlightColor?.(rule)),
                    "desc"
                )}
            getItemId={(rule: Contract.OciNetworkingNetworkSecurityGroupRule) => _.indexOf(rules, rule).toString()}
            rowOptions={{
                getHighlightColor: (rule: Contract.OciNetworkingNetworkSecurityGroupRule) => getHighlightColor?.(rule),
                getSx:
                    (rule: Contract.OciNetworkingNetworkSecurityGroupRule) => ({
                        backgroundColor: getHighlightColor?.(rule, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={OciNetworkingNetworkSecurityGroupRulesTableColumnId.Stateless}
                itemProperty={(rule: Contract.OciNetworkingNetworkSecurityGroupRule) =>
                    rule.stateless
                        ? localization.columns.stateless.true()
                        : localization.columns.stateless.false()
                }
                title={localization.columns.stateless.title()}/>
            <DataTableColumn
                id={OciNetworkingNetworkSecurityGroupRulesTableColumnId.EndpointType}
                itemProperty={
                    (rule: Contract.OciNetworkingNetworkSecurityGroupRule) =>
                        localization.columns.endpointType[Contract.TypeNames.OciNetworkingNetworkSecurityGroupRuleEndpointType][rule.endpointType]()
                }
                title={
                    inbound
                        ? localization.columns.endpointType.title.inbound()
                        : localization.columns.endpointType.title.outbound()}/>
            <DataTableColumn
                id={OciNetworkingNetworkSecurityGroupRulesTableColumnId.Endpoint}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingNetworkSecurityGroupRule>) => {
                        switch (item.endpointType) {
                            case Contract.OciNetworkingNetworkSecurityGroupRuleEndpointType.CidrBlock:
                                return (
                                    <Typography noWrap={true}>
                                        {item.subnet}
                                    </Typography>);
                            case Contract.OciNetworkingNetworkSecurityGroupRuleEndpointType.NetworkSecurityGroup:
                                return (
                                    <EntitiesCell
                                        entityIdsOrModels={networkSecurityGroupOcidToEntityIdReferenceMap[item.securityGroupOcid!]}
                                        entityTypeName={Contract.TypeNames.OciNetworkingNetworkSecurityGroup}
                                        entityVariant="iconText"/>);
                            case Contract.OciNetworkingNetworkSecurityGroupRuleEndpointType.Service:
                                return (
                                    <Typography noWrap={true}>
                                        {item.serviceCidrLabel}
                                    </Typography>);
                        }
                    }
                }
                title={
                    inbound
                        ? localization.columns.endpoint.title.inbound()
                        : localization.columns.endpoint.title.outbound()}/>
            <DataTableColumn
                id={OciNetworkingNetworkSecurityGroupRulesTableColumnId.Protocol}
                itemProperty={(rule: Contract.OciNetworkingNetworkSecurityGroupRule) => NetworkScopeFormatter.protocolRange(rule.protocolRange, Contract.TenantType.Oci)}
                title={localization.columns.protocol()}/>
            <DataTableColumn
                id={OciNetworkingNetworkSecurityGroupRulesTableColumnId.SourcePortRange}
                render={
                    optionalTableCell<Contract.OciNetworkingNetworkSecurityGroupRule>(
                        rule =>
                            _.isNil(rule.sourcePortRange)
                                ? undefined
                                : <Typography noWrap={true}>
                                    {NetworkScopeHelper.isAll(rule.sourcePortRange)
                                        ? localization.columns.portRange.all()
                                        : IntRangeHelper.format(rule.sourcePortRange)}
                                </Typography>)}
                title={localization.columns.portRange.title.source()}/>
            <DataTableColumn
                id={OciNetworkingNetworkSecurityGroupRulesTableColumnId.DestinationPortRange}
                render={
                    optionalTableCell<Contract.OciNetworkingNetworkSecurityGroupRule>(
                        rule =>
                            _.isNil(rule.destinationPortRange)
                                ? undefined
                                : <Typography noWrap={true}>
                                    {NetworkScopeHelper.isAll(rule.destinationPortRange)
                                        ? localization.columns.portRange.all()
                                        : IntRangeHelper.format(rule.destinationPortRange)}
                                </Typography>)}
                title={localization.columns.portRange.title.destination()}/>
            <DataTableColumn
                id={OciNetworkingNetworkSecurityGroupRulesTableColumnId.Icmp}
                render={
                    optionalTableCell<Contract.OciNetworkingNetworkSecurityGroupRule>(
                        rule =>
                            _.isNil(rule.icmp)
                                ? undefined
                                : `${rule.icmp.type ?? localization.columns.icmp.all()}, ${rule.icmp.code ?? localization.columns.icmp.all()}`)}
                title={localization.columns.icmp.title()}/>
            <DataTableColumn
                id={OciNetworkingNetworkSecurityGroupRulesTableColumnId.Description}
                render={
                    optionalTableCell<Contract.OciNetworkingNetworkSecurityGroupRule>(
                        rule =>
                            _.isEmpty(rule.description)
                                ? undefined
                                : rule.description)}
                title={localization.columns.description()}/>
        </DataTable>);
}

enum OciNetworkingNetworkSecurityGroupRulesTableColumnId {
    Description = "description",
    DestinationPortRange = "destinationPortRange",
    Endpoint = "endpoint",
    EndpointType = "endpointType",
    Icmp = "icmp",
    Protocol = "protocol",
    SourcePortRange = "sourcePortRange",
    Stateless = "stateless"
}