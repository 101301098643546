import { TimeHelper } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../../../common";
import { useCommonCodeTableDefinition } from "./useCommonCodeTableDefinition";

export function useRiskPolicyTypeDefinition(riskGroup: Contract.RiskControllerGetRiskGroupsResponseRiskGroup) {
    const riskPolicyTypeGroup = riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup;
    const riskPolicyConfigurationTypeName = riskPolicyTypeGroup.riskPolicyConfigurationTypeName;

    return useCommonCodeTableDefinition({
        getRiskModelPageRequest:
            (limit, requestFilters, skip, sort) =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCodeRiskModelPageRequest(
                    requestFilters,
                    limit,
                    skip,
                    sort,
                    new Contract.RiskControllerCodeRiskPolicyTypeFilters(riskPolicyConfigurationTypeName)),
        getRisksReportRequestDefinition:
            (_filterMap, _filtersTime, riskModelFilters, riskStatus, sort) =>
                new Contract.ReportControllerCodeRisksReportRequestDefinition(
                    undefined,
                    TimeHelper.timeZoneId(),
                    Contract.TypeNames.ReportControllerCodeRisksReportRequestDefinition,
                    undefined,
                    true,
                    riskModelFilters,
                    new Contract.RiskControllerCodeRiskPolicyTypeFilters(riskPolicyConfigurationTypeName),
                    sort,
                    riskStatus)
    });
}