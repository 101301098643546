import { StringHelper } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../controllers";

export class ResourceTagHelper {
    public static getDatas(tags: Contract.ResourceTag[]) {
        return _(tags).
            map(tag => new ResourceTagData(tag)).
            orderBy(tag => StringHelper.getSortValue(tag.displayName)).
            value();
    }

    public static getDisplayName(tagOrTagId: Contract.ResourceTag | string) {
        if (_.isString(tagOrTagId)) {
            tagOrTagId = ResourceTagHelper.parse([tagOrTagId])![0];
        }

        return _.isEmpty(tagOrTagId.value)
            ? tagOrTagId.key
            : `${tagOrTagId.key}: ${tagOrTagId.value}`;
    }

    public static parse(tagIds?: string[]) {
        return _.isEmpty(tagIds)
            ? undefined
            : _.map(
                tagIds,
                tagId => JSON.parse(tagId)) as Contract.ResourceTag[];
    }
}

export class ResourceTagData extends Contract.ResourceTag {
    public displayName: string;
    public id: string;

    public static createId =
        (tag: Contract.ResourceTag) => JSON.stringify(tag);

    constructor(tag: Contract.ResourceTag) {
        super(tag.key, tag.value);
        this.displayName = ResourceTagHelper.getDisplayName(tag);
        this.id = ResourceTagData.createId(tag);
    }
}