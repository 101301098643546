import { TimeHelper, TimeRangeFilterSelection, TimeRangeFilterType, UnexpectedError, useLocalization, ValuesFilterSelection } from "@infrastructure";
import _ from "lodash";
import { useCallback } from "react";
import { Contract, CustomerConsoleAppUrlHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { CustomerLicenseObjectTypeTranslator } from "../useGetVersion2Translator";

export function useGetVersion2AwsTranslator(): CustomerLicenseObjectTypeTranslator {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.licensing.hooks.useGetCustomerLicenseObjectInfo.hooks.version2.hooks.useGetVersion2AwsTranslator",
            () => ({
                [Contract.CustomerLicenseObjectType.AwsDynamoDbAnalyzedTable]: "{{translatedTypeName}} (Data Protection)",
                [Contract.CustomerLicenseObjectType.AwsEcsClusterInstance]: "{{translatedTypeName}} running ECS",
                [Contract.CustomerLicenseObjectType.AwsEcsFargateService]: "{{translatedTypeName}} (On fargate)",
                [Contract.CustomerLicenseObjectType.AwsEksClusterInstance]: "{{translatedTypeName}} running EKS",
                [Contract.CustomerLicenseObjectType.AwsRdsAnalyzedCluster]: "{{translatedTypeName}} (Data Protection)",
                [Contract.CustomerLicenseObjectType.AwsRdsDatabaseAnalyzedInstance]: "{{translatedTypeName}} (Data Protection)",
                [Contract.CustomerLicenseObjectType.AwsS3AnalyzedBucket]: "{{translatedTypeName}} (Data Protection)"
            }));

    return useCallback(
        (objectType, snapshotDate?) => {
            switch (objectType) {
                case Contract.CustomerLicenseObjectType.AwsDynamoDbAnalyzedTable:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsDynamoDbTable,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AwsEc2Image:
                    return {
                        link:
                            CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(
                                objectType,
                                _.isNil(snapshotDate)
                                    ? {}
                                    : {
                                        scanTime:
                                            new TimeRangeFilterSelection(
                                                TimeHelper.
                                                    addTime(snapshotDate!, -1, "days").
                                                    toISOString(),
                                                snapshotDate,
                                                undefined,
                                                TimeRangeFilterType.DateBetween)
                                    }),
                        title:
                            entityTypeNameTranslator(
                                objectType,
                                { count: 0 })
                    };
                case Contract.CustomerLicenseObjectType.AwsEc2Instance:
                case Contract.CustomerLicenseObjectType.AwsLambdaFunctionConfiguration:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(objectType),
                        title:
                            entityTypeNameTranslator(
                                objectType,
                                { count: 0 })
                    };
                case Contract.CustomerLicenseObjectType.AwsEcrRepository:
                    return {
                        title:
                            entityTypeNameTranslator(
                                objectType,
                                { count: 0 })
                    };
                case Contract.CustomerLicenseObjectType.AwsEcsClusterInstance:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsEc2Instance,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AwsEcsFargateService:
                    return {
                        link:
                            CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(
                                Contract.TypeNames.AwsEcsService,
                                {
                                    awsEcsServiceLaunchTypes:
                                        new ValuesFilterSelection(
                                            false,
                                            [Contract.AwsEcsServiceLaunchType.Fargate] as any)
                                }),
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsEcsService,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AwsEksClusterInstance:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsEc2Instance,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AwsRdsAnalyzedCluster:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsRdsCluster,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AwsRdsDatabaseAnalyzedInstance:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsRdsDatabaseInstance,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AwsS3AnalyzedBucket:
                    return {
                        title:
                            localization[objectType]({
                                translatedTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AwsS3Bucket,
                                        { count: 0 })
                            })
                    };
                case Contract.CustomerLicenseObjectType.AwsUnmanagedKubernetesClusterNode:
                    return {
                        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(Contract.TypeNames.AwsKubernetesNode),
                        title:
                            entityTypeNameTranslator(
                                Contract.TypeNames.IKubernetesNode,
                                {
                                    count: 0,
                                    includeServiceName: true
                                })
                    };
                default:
                    throw new UnexpectedError("objectType", objectType);
            }
        },
        [entityTypeNameTranslator, localization]);
}