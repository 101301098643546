﻿import { useInputValidation, useLocalization } from "@infrastructure";
import { FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { Contract } from "../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";

export function Name() {
    const { permissionEligibilityData: { name }, upsertPermissionEligibilityError, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.name",
            () => ({
                error: {
                    exists: "Name already exists",
                    required: "Name cannot be empty"
                },
                title: "Name"
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = _.trim(name);
                if (_.isEmpty(validationName)) {
                    return localization.error.required();
                }

                return undefined;
            },
            [name]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        name: nameValidationController.isValid()
                    }
                }));
        },
        [name]);

    return (
        <Stack spacing={1.5}>
            <TextField
                disabled={upsertPermissionEligibilityExecuting}
                fullWidth={true}
                label={localization.title()}
                slotProps={{ htmlInput: { style: { fontSize: "1.1rem" } } }}
                value={name}
                variant="outlined"
                onChange={
                    event => {
                        setAddOrEditContext(
                            addOrEditContext => {
                                addOrEditContext.permissionEligibilityData.setName(event.target.value);
                                return { ...addOrEditContext };
                            });
                    }}/>
            {!_.isNil(nameValidationMessage) ||
                upsertPermissionEligibilityError === Contract.ConfigurationControllerUpsertPermissionEligibilityError.NameExists && (
                    <FormHelperText error={true}>
                        {nameValidationMessage ?? localization.error.exists()}
                    </FormHelperText>)}
        </Stack>);
}