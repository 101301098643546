import { Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function AccessTokenItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement:
                        <img src={InstructionsImageUrl}/>
                }));
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEdit.accessTokenItem",
            () => ({
                steps: {
                    step1: "Login as the user and go to \"**Account Settings**\"",
                    step2: "On the side menu go to \"**Security**\" and click on \"**Create and manage API tokens**\"",
                    step3: "Click on \"**Create Api token**\"",
                    step4: "Name it and Click \"**Create**\"",
                    step5: "Click on \"**Copy**\" to copy the API token"
                }
            }));

    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
            {localization.steps.step4()}
            {localization.steps.step5()}
        </Steps>);
}