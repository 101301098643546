import { Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function LogSourceSetupSecondItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement:
                        <img src={InstructionsImageUrl}/>
                }));
        },
        []);
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.qRadar.addOrEdit.logSourceSetupSecondItem",
            () => ({
                steps: {
                    step4: "Configure the Log Source parameters:\n\nSelect **Extension** ErmeticCustom_ext\nUncheck **Coalescing Events**\nFill **Log Source Identifier** Ermetic\nChange **Max Payload Length** to 30000\nOptionally change the port number or the generated certificate",
                    step5: "Click on **Deploy Changes**"
                }
            }));

    return (
        <Steps
            startStepCount={4}
            variant="letters">
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.steps.step4()}
            </Typography>
            {localization.steps.step5()}
        </Steps>);
}