﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../common";

export function useOciOrganizationModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.oci.hooks.useOciOrganizationModelStatusTranslator",
            () => ({
                [Contract.TypeNames.OciOrganizationModelStatus]: {
                    [Contract.OciOrganizationModelStatus.Deleting]: "Deleting...",
                    [Contract.OciOrganizationModelStatus.UserNotAuthenticated]: "Failed to connect",
                    [Contract.OciOrganizationModelStatus.UserNotAuthorized]: "User is not authorized",
                    [Contract.OciOrganizationModelStatus.UserPrivateKeyNotValid]: "User credentials are not valid",
                    [Contract.OciOrganizationModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.OciOrganizationModelStatus) => localization[Contract.TypeNames.OciOrganizationModelStatus][status]();
}