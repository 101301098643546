import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function TimelineIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60392_186)">
                <path
                    clipRule="evenodd"
                    d="M7 0C7.55228 2.41411e-08 8 0.447715 8 1V5C8 5.55228 7.55228 6 7 6C6.44772 6 6 5.55228 6 5V1C6 0.447715 6.44772 -2.41411e-08 7 0Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M17 0C17.5523 2.41411e-08 18 0.447715 18 1V5C18 5.55228 17.5523 6 17 6C16.4477 6 16 5.55228 16 5V1C16 0.447715 16.4477 -2.41411e-08 17 0Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M2 10C2 9.44772 2.44772 9 3 9H21C21.5523 9 22 9.44772 22 10C22 10.5523 21.5523 11 21 11H3C2.44772 11 2 10.5523 2 10Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M6.92072 4.16496C5.73809 4.32393 5.07682 4.6194 4.59811 5.09811C4.11944 5.57677 3.82395 6.23824 3.66496 7.42109C3.50212 8.63261 3.5 10.2327 3.5 12.5C3.5 14.7678 3.50212 16.3679 3.66496 17.5793C3.82393 18.7619 4.1194 19.4232 4.59811 19.9019C5.07677 20.3806 5.73824 20.6761 6.92109 20.835C8.13261 20.9979 9.73274 21 12 21C14.2678 21 15.8679 20.9979 17.0793 20.835C18.2619 20.6761 18.9232 20.3806 19.4019 19.9019C19.8806 19.4232 20.1761 18.7618 20.335 17.5789C20.4979 16.3674 20.5 14.7673 20.5 12.5C20.5 10.2322 20.4979 8.63212 20.335 7.42072C20.1761 6.23809 19.8806 5.57682 19.4019 5.09811C18.9232 4.61944 18.2618 4.32395 17.0789 4.16496C15.8674 4.00212 14.2673 4 12 4C9.73223 4 8.13212 4.00212 6.92072 4.16496ZM11.9255 2H12.0744C14.2503 1.99998 15.9851 1.99996 17.3453 2.18279C18.7497 2.37155 19.9038 2.77156 20.8161 3.68389C21.7284 4.59619 22.1284 5.74991 22.3172 7.15428C22.5 8.51441 22.5 10.2492 22.5 12.4256V12.5744C22.5 14.7503 22.5 16.4851 22.3172 17.8453C22.1284 19.2497 21.7284 20.4038 20.8161 21.3161C19.9038 22.2284 18.7501 22.6284 17.3457 22.8172C15.9856 23 14.2508 23 12.0745 23H11.9256C9.74974 23 8.01491 23 6.65466 22.8172C5.25026 22.6284 4.09623 22.2284 3.18389 21.3161C2.2716 20.4038 1.87157 19.2501 1.68279 17.8457C1.49996 16.4856 1.49998 14.7508 1.5 12.5745V12.4256C1.49998 10.2497 1.49996 8.51492 1.68279 7.15466C1.87155 5.75026 2.27156 4.59623 3.18389 3.68389C4.09619 2.7716 5.24991 2.37157 6.65428 2.18279C8.01441 1.99996 9.74924 1.99998 11.9255 2Z"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_60392_186">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}