import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActionMenuItem, AddIcon, CheckboxField, makeContextProvider, map, Menu, Message, Optional, UnexpectedError, useChangeEffect, useLocalization } from "@infrastructure";
import { Contract, customEntityAttributeDefinitionModelStore, ElasticsearchItemPageHelper, EntityPropertyHelper, InlinePagedEntities, RiskController, ScopeHelper, tenantModelStore, useEntityTypeNameTranslator, useTheme } from "../../../..";
import { useCustomRiskPolicyContext } from "../../..";
import { Condition } from "./components";
import { EntityMatchConditionHelper } from "./utilities";

type EntityMultiSelectProps = {
    builtInEntityAttributeTypeNames?: string[];
    conditions: Contract.EntityMatchCondition[];
    directoryEntityType?: boolean;
    disableManualCustomEntityAttributes?: boolean;
    entityTypeName: string;
    entityTypeNameVariant?: "name" | "viewName";
    excludeCommonCloudProviderTenants?: boolean;
    excludeConditions: Contract.EntityMatchCondition[];
    includeServiceName?: boolean;
    onConditionsChanged: (conditions: Contract.EntityMatchCondition[]) => void;
    onExcludeConditionsChanged: (conditions: Contract.EntityMatchCondition[]) => void;
    permissionEvaluationEntities?: EntityMultiSelectPermissionEvaluationEntities;
    previewFilterScope: boolean;
    properties: EntityMultiSelectProperty[];
    scopeResourceParentEntityTypeName?: string;
    tenantsTenantTypes?: Contract.TenantType[];
};

export function EntityMultiSelect({ conditions, excludeConditions, onConditionsChanged, onExcludeConditionsChanged, properties, ...props }: EntityMultiSelectProps) {
    const { scopeId, tenantTypes } = useCustomRiskPolicyContext();
    const scopeActiveTenantIds = ScopeHelper.getScopeActiveTenantIds([scopeId]);
    const activeTenantModels = tenantModelStore.useGet(scopeActiveTenantIds);
    const propertyDefinitionIdentifiers =
        EntityPropertyHelper.getDefinitionIdentifiers(
            _.isEmpty(tenantTypes)
                ? activeTenantModels
                : _.filter(
                    activeTenantModels,
                    activeTenantModel => _.includes(tenantTypes, activeTenantModel.tenantType)));
    const [exclusion, setExclusion] = useState(!_.isEmpty(excludeConditions));
    const [propertyIdToConditionErrorMessageMap, setPropertyIdToConditionErrorMessageMap] = useState<_.Dictionary<string>>({});
    const [propertyIdToExcludeConditionErrorMessageMap, setPropertyIdToExcludeConditionErrorMessageMap] = useState<_.Dictionary<string>>({});

    customEntityAttributeDefinitionModelStore.useGetAll();

    useChangeEffect(
        () => {
            if (!exclusion) {
                onExcludeConditionsChanged([]);
            }
        },
        [exclusion]);

    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect",
            () => ({
                error: {
                    exclusion: "Overlaps with inclusion selection",
                    inclusion: "Overlaps with exclusion selection"
                },
                exclusion: "Exclusion"
            }));

    const onConditionsUpdated =
        useCallback(
            (conditions: Contract.EntityMatchCondition[]) => {
                const overlappingPropertyIds =
                    EntityMatchConditionHelper.getOverlappingConditionPropertyIds(
                        conditions,
                        excludeConditions);
                setPropertyIdToConditionErrorMessageMap(
                    _(overlappingPropertyIds).
                        without(..._.keys(propertyIdToExcludeConditionErrorMessageMap)).
                        keyBy().
                        mapValues(_ => localization.error.inclusion()).
                        value());
                setPropertyIdToExcludeConditionErrorMessageMap(
                    propertyIdToExcludeConditionErrorMessageMap =>
                        _.pickBy(
                            propertyIdToExcludeConditionErrorMessageMap,
                            (_errorMessage, propertyId) => _.includes(overlappingPropertyIds, propertyId)));

                onConditionsChanged(conditions);
            },
            [excludeConditions, onConditionsChanged, propertyIdToExcludeConditionErrorMessageMap]);
    const onExcludeConditionsUpdated =
        useCallback(
            (excludeConditions: Contract.EntityMatchCondition[]) => {
                const overlappingPropertyIds =
                    EntityMatchConditionHelper.getOverlappingConditionPropertyIds(
                        conditions,
                        excludeConditions);
                setPropertyIdToExcludeConditionErrorMessageMap(
                    _(overlappingPropertyIds).
                        without(..._.keys(propertyIdToConditionErrorMessageMap)).
                        keyBy().
                        mapValues(_ => localization.error.exclusion()).
                        value());
                setPropertyIdToConditionErrorMessageMap(
                    propertyIdToConditionErrorMessageMap =>
                        _.pickBy(
                            propertyIdToConditionErrorMessageMap,
                            (errorMessage, propertyId) => _.includes(overlappingPropertyIds, propertyId)));

                onExcludeConditionsChanged(excludeConditions);
            },
            [conditions, onExcludeConditionsChanged, propertyIdToConditionErrorMessageMap]);

    return (
        <Stack spacing={2}>
            <MultiSelector
                conditions={conditions}
                properties={properties}
                propertyDefinitionIdentifiers={propertyDefinitionIdentifiers}
                propertyIdToErrorMessageMap={propertyIdToConditionErrorMessageMap}
                onConditionsChanged={onConditionsUpdated}
                {...props}/>
            <Divider/>
            <Stack spacing={2}>
                <CheckboxField
                    checked={exclusion}
                    onChange={() => setExclusion(!exclusion)}>
                    {localization.exclusion()}
                </CheckboxField>
                {exclusion &&
                    <MultiSelector
                        conditions={excludeConditions}
                        properties={_.without(properties, EntityMultiSelectProperty.All)}
                        propertyDefinitionIdentifiers={propertyDefinitionIdentifiers}
                        propertyIdToErrorMessageMap={propertyIdToExcludeConditionErrorMessageMap}
                        onConditionsChanged={onExcludeConditionsUpdated}
                        {...props}/>}
            </Stack>
            <Divider/>
            <Preview
                conditions={conditions}
                entityTypeName={props.entityTypeName}
                excludeConditions={excludeConditions}
                filterScope={props.previewFilterScope}
                scopeId={scopeId}
                tenantTypes={tenantTypes}/>
        </Stack>);
}

type MultiSelectorProps = Omit<EntityMultiSelectProps, "excludeConditions" | "onExcludeConditionsChanged"> & {
    propertyDefinitionIdentifiers: Contract.EntityPropertyIdentifier[];
    propertyIdToErrorMessageMap: _.Dictionary<string>;
};

export class MultiSelectorContext {
    constructor(public propertyIdToConditionMap: _.Dictionary<Contract.EntityMatchCondition>) {
    }
}

export const [useMultiSelectorContext, useSetMultiSelectorContext, useMultiSelectorContextProvider] = makeContextProvider<MultiSelectorContext>();

function MultiSelector({ conditions, entityTypeName, includeServiceName = false, onConditionsChanged, properties, propertyDefinitionIdentifiers, propertyIdToErrorMessageMap, ...props }: MultiSelectorProps) {
    const [propertyDefinitionIdentifierRawIds, propertyIds] =
        useMemo(
            () => {
                const propertyDefinitionIdentifierRawIds =
                    _.map(
                        propertyDefinitionIdentifiers,
                        propertyDefinitionIdentifier => propertyDefinitionIdentifier.raw);
                return [
                    propertyDefinitionIdentifierRawIds,
                    _(properties).
                        without(EntityMultiSelectProperty.Properties).
                        uniq().
                        as<string>().
                        concatIf(
                            _.some(
                                properties,
                                property => property === EntityMultiSelectProperty.Properties),
                            propertyDefinitionIdentifierRawIds).
                        value()];
            },
            [properties, propertyDefinitionIdentifiers]);

    const [context, setContext, ContextProvider] =
        useMultiSelectorContextProvider(
            () =>
                new MultiSelectorContext(
                    _.keyBy(
                        conditions,
                        EntityMatchConditionHelper.getConditionPropertyId)));
    useEffect(
        () => onConditionsChanged(_.values(context.propertyIdToConditionMap)),
        [context.propertyIdToConditionMap]);

    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.multiSelector",
            () => ({
                actions: {
                    add: "Condition"
                },
                property: {
                    [EntityMultiSelectProperty.AadDirectoryPrincipalGroupIds]: "*capitalize*{{translatedEntityTypeName}}** that are members of these groups",
                    [EntityMultiSelectProperty.All]: "All {{translatedEntityTypeName}}",
                    [EntityMultiSelectProperty.Attributes]: "*capitalize*{{translatedEntityTypeName}}** with these labels",
                    [EntityMultiSelectProperty.AwsIamPrincipalCriticalActionSeverityPermission]: "*capitalize*{{translatedEntityTypeName}}** with high privileges",
                    [EntityMultiSelectProperty.AwsIamUserGroupIds]: "*capitalize*{{translatedEntityTypeName}}** in these groups",
                    [EntityMultiSelectProperty.AwsKmsKeyCustomerManaged]: "Any customer managed encryption {{translatedEntityTypeName}}",
                    [EntityMultiSelectProperty.AwsSsoPermissionSetProvisioned]: "*capitalize*{{translatedEntityTypeName}}** provisioned status",
                    [EntityMultiSelectProperty.AzureScopeResourceParentEntityIds]: "*capitalize*{{translatedEntityTypeName}}** in selected scopes or lower",
                    [EntityMultiSelectProperty.GciPrincipalGroupIds]: "*capitalize*{{translatedEntityTypeName}}** that are members of these groups",
                    [EntityMultiSelectProperty.GcpScopeResourceParentScopeResourceIds]: "*capitalize*{{translatedEntityTypeName}}** in selected scopes or lower",
                    [EntityMultiSelectProperty.Ids]: "Specific {{translatedEntityTypeName}}",
                    [EntityMultiSelectProperty.NamePattern]: "*capitalize*{{translatedEntityTypeName}}** that match this pattern",
                    [EntityMultiSelectProperty.Properties]: "*capitalize*{{translatedEntityTypeName}}** matching {{identifierName}} (Custom Property)",
                    [EntityMultiSelectProperty.Sensitive]: "Sensitive {{translatedEntityTypeName}}",
                    [EntityMultiSelectProperty.Tags]: "*capitalize*{{translatedEntityTypeName}}** with these tags",
                    [EntityMultiSelectProperty.TenantIds]: "*capitalize*{{translatedEntityTypeName}}** in these accounts",
                    [EntityMultiSelectProperty.TypeNames]: "*capitalize*{{translatedEntityTypeName}}** from these types"
                }
            }));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const menuItems =
        useMemo(
            () => _(propertyIds).
                without(..._.keys(context.propertyIdToConditionMap)).
                orderBy(
                    [
                        propertyId => propertyId === EntityMultiSelectProperty.All,
                        propertyId => propertyId === EntityMultiSelectProperty.Ids,
                        propertyId => propertyId === EntityMultiSelectProperty.NamePattern,
                        propertyId => !_.includes(propertyDefinitionIdentifierRawIds, propertyId),
                        propertyId => propertyId.toLowerCase()
                    ],
                    [
                        "desc",
                        "desc",
                        "desc",
                        "desc"
                    ]).
                map(
                    propertyId => {
                        const { entityPropertyIdentifier, property } = EntityMatchConditionHelper.getPropertyData(propertyId);
                        const condition =
                            map(
                                property,
                                {
                                    [EntityMultiSelectProperty.AadDirectoryPrincipalGroupIds]: () =>
                                        new Contract.AadDirectoryPrincipalGroupIdMatchCondition(
                                            [],
                                            Contract.EntityMatchConditionGroupMembershipTypeOperator.Direct),
                                    [EntityMultiSelectProperty.All]: () =>
                                        new Contract.AllEntityMatchCondition(),
                                    [EntityMultiSelectProperty.Attributes]: () =>
                                        new Contract.EntityAttributeMatchCondition(
                                            [],
                                            [],
                                            Contract.EntityMatchConditionCollectionOperator.Overlap),
                                    [EntityMultiSelectProperty.AwsIamPrincipalCriticalActionSeverityPermission]: () =>
                                        new Contract.AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition(),
                                    [EntityMultiSelectProperty.AwsIamUserGroupIds]: () =>
                                        new Contract.AwsIamUserGroupIdMatchCondition([]),
                                    [EntityMultiSelectProperty.AwsKmsKeyCustomerManaged]: () =>
                                        new Contract.AwsKmsKeyCustomerManagedMatchCondition(),
                                    [EntityMultiSelectProperty.AwsSsoPermissionSetProvisioned]: () =>
                                        new Contract.AwsSsoPermissionSetProvisionedMatchCondition(true),
                                    [EntityMultiSelectProperty.AzureScopeResourceParentEntityIds]: () =>
                                        new Contract.AzureScopeResourceParentEntityIdMatchCondition([]),
                                    [EntityMultiSelectProperty.GciPrincipalGroupIds]: () =>
                                        new Contract.GciPrincipalGroupIdMatchCondition(
                                            [],
                                            Contract.EntityMatchConditionGroupMembershipTypeOperator.Direct),
                                    [EntityMultiSelectProperty.GcpScopeResourceParentScopeResourceIds]: () =>
                                        new Contract.GcpScopeResourceParentScopeResourceIdMatchCondition([]),
                                    [EntityMultiSelectProperty.Ids]: () =>
                                        new Contract.EntityIdMatchCondition([]),
                                    [EntityMultiSelectProperty.NamePattern]: () =>
                                        new Contract.EntityNamePatternMatchCondition(""),
                                    [EntityMultiSelectProperty.Properties]: () =>
                                        map(
                                            entityPropertyIdentifier!.valueType,
                                            {
                                                [Contract.EntityPropertyValueType.PrincipalReference]: () =>
                                                    new Contract.EntityPrincipalReferencePropertyMatchCondition(
                                                        entityPropertyIdentifier!,
                                                        Contract.EntityMatchConditionCollectionOperator.Overlap,
                                                        []),
                                                [Contract.EntityPropertyValueType.String]: () =>
                                                    new Contract.EntityStringPropertyMatchCondition(
                                                        entityPropertyIdentifier!,
                                                        Contract.EntityMatchConditionCollectionOperator.Overlap,
                                                        [])
                                            },
                                            () => {
                                                throw new UnexpectedError("entityPropertyIdentifier.valueType", entityPropertyIdentifier!.valueType);
                                            }),
                                    [EntityMultiSelectProperty.Sensitive]: () =>
                                        new Contract.EntitySensitiveMatchCondition(),
                                    [EntityMultiSelectProperty.Tags]: () =>
                                        new Contract.EntityTagMatchCondition(
                                            Contract.EntityMatchConditionCollectionOperator.Overlap,
                                            []),
                                    [EntityMultiSelectProperty.TenantIds]: () =>
                                        new Contract.EntityTenantIdMatchCondition([]),
                                    [EntityMultiSelectProperty.TypeNames]: () =>
                                        new Contract.EntityTypeNameMatchCondition([])
                                },
                                () => {
                                    throw new UnexpectedError("EntityMultiSelectProperty", property);
                                });
                        return new ActionMenuItem(
                            () => setContext(
                                context => ({
                                    propertyIdToConditionMap: ({
                                        ...context.propertyIdToConditionMap,
                                        [propertyId]: condition
                                    })
                                })),
                            localization.property.translate(
                                property,
                                {
                                    identifierName: entityPropertyIdentifier?.name,
                                    translatedEntityTypeName:
                                        entityTypeNameTranslator(
                                            entityTypeName,
                                            {
                                                count:
                                                    property === EntityMultiSelectProperty.AwsKmsKeyCustomerManaged
                                                        ? 1
                                                        : 0,
                                                includeServiceName,
                                                variant: "text"
                                            })
                                }),
                            {
                                disabled:
                                    property === EntityMultiSelectProperty.All
                                        ? _(context.propertyIdToConditionMap).
                                            keys().
                                            some(selectedPropertyId => selectedPropertyId !== EntityMultiSelectProperty.All)
                                        : _(context.propertyIdToConditionMap).
                                            keys().
                                            includes(EntityMultiSelectProperty.All)
                            });
                    }).
                value(),
            [entityTypeName, includeServiceName, propertyIds, context.propertyIdToConditionMap]);
    const theme = useTheme();
    return (
        <ContextProvider>
            <Stack spacing={1.5}>
                {_.map(
                    context.propertyIdToConditionMap,
                    (condition, propertyId) => (
                        <Condition
                            condition={condition}
                            entityTypeName={entityTypeName}
                            errorMessage={propertyIdToErrorMessageMap[propertyId]}
                            includeServiceName={includeServiceName}
                            key={propertyId}
                            onDelete={
                                () =>
                                    setContext(
                                        context => ({
                                            propertyIdToConditionMap:
                                                _.pickBy(
                                                    context.propertyIdToConditionMap,
                                                    (_, conditionPropertyId) => conditionPropertyId !== propertyId)
                                        }))}
                            {...props}/>)
                )}
                <Stack direction="row">
                    <Menu
                        itemsOrGetItems={menuItems}
                        itemSx={{ minWidth: theme.spacing(20) }}
                        variant="bottomLeft">
                        <Button
                            disabled={_.isEmpty(menuItems)}
                            size="small"
                            startIcon={<AddIcon/>}
                            variant="outlined">
                            <Typography>
                                {localization.actions.add()}
                            </Typography>
                        </Button>
                    </Menu>
                </Stack>
            </Stack>
        </ContextProvider>);
}

type PreviewProps = {
    conditions: Contract.EntityMatchCondition[];
    entityTypeName: string;
    excludeConditions: Contract.EntityMatchCondition[];
    filterScope: boolean;
    scopeId: string;
    tenantTypes: Contract.TenantType[];
};

function Preview({ conditions, entityTypeName, excludeConditions, filterScope, scopeId, tenantTypes }: PreviewProps) {
    const [conditionsChanged, setConditionsChanged] = useState(false);
    const [conditionsValid, setConditionsValid] = useState(false);
    const [entityCount, setEntityCount] = useState(undefined as Optional<number>);
    const [previewExecuting, setPreviewExecuting] = useState(false);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    useEffect(
        () => {
            const conditionsValid =
                EntityMatchConditionHelper.validateConditions(
                    conditions,
                    excludeConditions);
            setConditionsValid(conditionsValid);
            setConditionsChanged(true);
        },
        [conditions, excludeConditions]);

    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.preview",
            () => ({
                entities: [
                    "{{entities}} meets the conditions",
                    "{{entities}} meet the conditions"
                ],
                title: "Preview",
                warning: "Conditions have changed. Click Preview again"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between">
            <Box>
                {!_.isNil(entityCount) && (
                    conditionsChanged
                        ? <Message
                            level="infoWarning"
                            title={localization.warning()}
                            variant="standard"/>
                        : <Typography>
                            {localization.entities(
                                entityCount,
                                {
                                    entities:
                                        <InlinePagedEntities
                                            getEntityModelPage={
                                                ElasticsearchItemPageHelper.makePagedEntitySelector(
                                                    async (itemNextPageSearchCursor, searchText) => {
                                                        const { entityModelPage } =
                                                            await RiskController.getCustomRiskEntityMatchConditionsMatchEntityModelPage(
                                                                new Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageRequest(
                                                                    conditions,
                                                                    entityTypeName,
                                                                    excludeConditions,
                                                                    filterScope,
                                                                    15,
                                                                    itemNextPageSearchCursor,
                                                                    scopeId,
                                                                    searchText,
                                                                    tenantTypes));
                                                        return entityModelPage;
                                                    })
                                            }
                                            placeholder={
                                                entityTypeNameTranslator(
                                                    entityTypeName,
                                                    {
                                                        count: entityCount,
                                                        includeCount: true,
                                                        includeServiceName: false,
                                                        variant: "text"
                                                    })}/>
                                })}
                        </Typography>)}
            </Box>
            <Button
                disabled={previewExecuting || !conditionsValid}
                size="small"
                variant="outlined"
                onClick={
                    async () => {
                        setPreviewExecuting(true);
                        try {
                            const { count } =
                                await RiskController.getCustomRiskEntityMatchConditionsMatchEntityModelCount(
                                    new Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountRequest(
                                        conditions,
                                        entityTypeName,
                                        excludeConditions,
                                        filterScope,
                                        scopeId,
                                        tenantTypes));
                            setEntityCount(count);
                            setConditionsChanged(false);
                        } finally {
                            setPreviewExecuting(false);
                        }
                    }}>
                <Typography>
                    {localization.title()}
                </Typography>
            </Button>
        </Stack>);
}

export enum EntityMultiSelectPermissionEvaluationEntities {
    Azure = "azure",
    Gcp = "gcp"
}

export enum EntityMultiSelectProperty {
    AadDirectoryPrincipalGroupIds = "aadDirectoryPrincipalGroupIds",
    All = "all",
    Attributes = "attributes",
    AwsIamPrincipalCriticalActionSeverityPermission = "awsIamPrincipalCriticalActionSeverityPermission",
    AwsIamUserGroupIds = "awsIamUserGroupIds",
    AwsKmsKeyCustomerManaged = "awsKmsKeyCustomerManaged",
    AwsSsoPermissionSetProvisioned = "awsSsoPermissionSetProvisioned",
    AzureScopeResourceParentEntityIds = "azureScopeResourceParentEntityIds",
    GciPrincipalGroupIds = "gciPrincipalGroupIds",
    GcpScopeResourceParentScopeResourceIds = "gcpScopeResourceParentEntityIds",
    Ids = "ids",
    NamePattern = "namePattern",
    Properties = "properties",
    Sensitive = "sensitive",
    Tags = "tags",
    TenantIds = "tenantIds",
    TypeNames = "typeNames"
}