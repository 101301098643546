import { BulletIcon, ItemsSearchLayout, NumberFormatter, StringHelper, useExecuteOperation, useLocalization } from "@infrastructure";
import { Divider, List as MuiList, ListItem, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo } from "react";
import { Contract, entityModelStore, useAccessContext, useTheme } from "../../../../..";
import { AccessGraphPermissionEdgeData } from "../../../utilities";

type PermissionActionsProps = {
    accessGraphId: string;
    excessivePermissionsEnabled: boolean;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    selectedDestinationEntityId: string;
    selectedPermissionPaths: Contract.AccessGraphPermissionPath[];
    selectedSourceEntityId: string;
    serviceName: string;
};

export function PermissionActions({ accessGraphId, excessivePermissionsEnabled, permissionEdgeIdToDataMap, selectedDestinationEntityId, selectedPermissionPaths, selectedSourceEntityId, serviceName }: PermissionActionsProps) {
    const { definition, direction, entityId, filters, scope } = useAccessContext();
    const [{ actionToDataMap }] =
        useExecuteOperation(
            [PermissionActions, accessGraphId],
            () =>
                definition.getAccessListPermissionActions(
                    direction,
                    entityId,
                    filters,
                    scope,
                    new Contract.EntityControllerGetAccessListPermissionActionsRequestSelection(
                        selectedDestinationEntityId,
                        selectedSourceEntityId),
                    _.filter(
                        selectedPermissionPaths,
                        selectedPermissionPath =>
                            _(selectedPermissionPath.permissionEdgeIdToPathActionsExcessivenessMap).
                                keys().
                                some(
                                    permissionEdgeId => {
                                        const permissionEdgeData = permissionEdgeIdToDataMap[permissionEdgeId];
                                        return !_.isNil(permissionEdgeData) &&
                                            permissionEdgeData.sourceEntityIds.has(selectedSourceEntityId) &&
                                            permissionEdgeData.destinationEntityIds.has(selectedDestinationEntityId);
                                    }))));

    const localization =
        useLocalization(
            "common.access.entitiesPermissionActions.permissionActions",
            () => ({
                actions: {
                    search: "Search Permissions"
                },
                title: {
                    excessivePermissionsEnabled: {
                        false: "Permissions",
                        true: "Permissions ({{excessivePermissionActionCountElement}}/{{permissionActionCount | NumberFormatter.humanize}} excessive)"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <ItemsSearchLayout
            searchPlaceholder={localization.actions.search()}
            title={
                excessivePermissionsEnabled
                    ? localization.title.excessivePermissionsEnabled.true({
                        excessivePermissionActionCountElement:
                            <Typography
                                component="span"
                                sx={{
                                    color: theme.palette.excessivePermission(Contract.AccessGraphPermissionPathActionsExcessiveness.All),
                                    fontSize: "inherit",
                                    fontWeight: "inherit"
                                }}>
                                {NumberFormatter.humanize(
                                    _(actionToDataMap).
                                        values().
                                        filter(actionData => actionData.excessive).
                                        size())}
                            </Typography>,
                        permissionActionCount: _.size(actionToDataMap)
                    })
                    : localization.title.excessivePermissionsEnabled.false()}>
            {searchText =>
                <List
                    actionToDataMap={actionToDataMap}
                    excessivePermissionsEnabled={excessivePermissionsEnabled}
                    searchText={searchText}
                    selectedEntityId={selectedDestinationEntityId}
                    serviceName={serviceName}/>}
        </ItemsSearchLayout>);
}

type ListProps = {
    actionToDataMap: Dictionary<Contract.AccessListPermissionActionData>;
    excessivePermissionsEnabled: boolean;
    searchText?: string;
    selectedEntityId: string;
    serviceName: string;
};

function List({ actionToDataMap, excessivePermissionsEnabled, searchText, selectedEntityId, serviceName }: ListProps) {
    const { definition } = useAccessContext();
    const selectedEntityModel = entityModelStore.useGet(selectedEntityId);
    const theme = useTheme();
    return (
        <MuiList
            disablePadding={true}
            sx={{ width: "100%" }}>
            {useMemo(
                () =>
                    _(actionToDataMap).
                        toPairs().
                        filter(([action]) => StringHelper.search(action, searchText)).
                        orderBy(
                            [
                                ([_, actionData]) => actionData.excessive,
                                ([action, _]) => action.toLowerCase()
                            ],
                            [
                                "desc",
                                "asc"
                            ]).
                        map(
                            ([action, actionData], entityActionIndex) =>
                                <Fragment key={action}>
                                    {entityActionIndex !== 0 && <Divider/>}
                                    <ListItem sx={{ padding: theme.spacing(1.25, 1) }}>
                                        <Stack>
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing={1}>
                                                {excessivePermissionsEnabled &&
                                                    <BulletIcon
                                                        sx={{
                                                            color:
                                                                theme.palette.excessivePermission(
                                                                    actionData.excessive
                                                                        ? Contract.AccessGraphPermissionPathActionsExcessiveness.All
                                                                        : Contract.AccessGraphPermissionPathActionsExcessiveness.None),
                                                            fontSize: "12px"
                                                        }}/>}
                                                <Typography sx={{ color: theme.palette.text.primary }}>
                                                    {definition.entitiesPermissionActions.getPermissionActionName(action, serviceName)}
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.text.secondary,
                                                    fontSize: "10px",
                                                    paddingLeft: theme.spacing(2.5)
                                                }}>
                                                <definition.entitiesPermissionActions.permitterComponent
                                                    actionData={actionData}
                                                    entityTypeName={selectedEntityModel.entity.typeName}/>
                                            </Typography>
                                        </Stack>
                                    </ListItem>
                                </Fragment>).
                        value(),
                [actionToDataMap, searchText])}
        </MuiList>);
}