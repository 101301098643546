import { defined, executeOperation, Optional, useDeepDependency } from "@infrastructure";
import _, { Dictionary, Function0, Function1, Function2 } from "lodash";
import { delay } from "q";
import React, { Dispatch, Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { AppError } from "./errors";

type StoreDefinition<TDocument, TAddDocumentRequest, TUpdateDocumentRequest> = {
    add?: Function1<TAddDocumentRequest, Promise<TDocument>>;
    delete?: Function1<string, Promise<void>>;
    get?: Function1<string[], Promise<TDocument[]>>;
    getAll?: Function0<Promise<TDocument[]>>;
    onNotify?: Function1<Optional<TDocument[]>, Promise<void>>;
    onNotifyDeleted?: Function1<string[], Promise<void>>;
    update?: Function2<string, TUpdateDocumentRequest, Promise<TDocument>>;
};

export class Store<TDocument, TAddDocumentRequest, TUpdateDocumentRequest> {
    private deletedDocumentIds: string[] = [];
    private documentIdToPromiseMap: Dictionary<Promise<void>> = {};
    private documentIdToSetRefreshesMap: Dictionary<Dispatch<any>[]> = {};
    private documentMap: Dictionary<TDocument> = {};
    private documentsSetRefreshes: Dispatch<any>[] = [];
    private fetchDocumentIds: string[] = [];
    private fetchDocumentIdsPromise: Optional<Promise<void>> = undefined;
    private hasAllDocuments = false;
    private typeToHasAllDocumentsMap: Dictionary<boolean> = {};

    constructor(
        private getDocumentId: Function1<TDocument, string>,
        private definition: StoreDefinition<TDocument, TAddDocumentRequest, TUpdateDocumentRequest>) {
    }

    public add =
        async (request: TAddDocumentRequest) => {
            const document = await defined(this.definition.add)(request);
            this.documentMap[this.getDocumentId(document)] = document;
            this.refresh();
        };

    public clear =
        () => {
            this.deletedDocumentIds = [];
            this.documentMap = {};
            this.hasAllDocuments = false;
            this.typeToHasAllDocumentsMap = {};
        };

    public delete =
        async (id: string) => {
            await defined(this.definition.delete)(id);
            delete this.documentMap[id];
            this.refresh(id);
        };

    public get =
        async <TDocumentIds extends string | string[]>(idOrIds: TDocumentIds): Promise<TDocumentIds extends string ? TDocument : TDocument[]> => {
            const ids = this.toDocumentIds(idOrIds);
            if (_.isString(idOrIds) &&
                _.includes(this.deletedDocumentIds, ids[0])) {
                throw new StoreDeletedDocumentError(ids[0]);
            }

            const missingIds =
                _.filter(
                    ids,
                    id =>
                        _.isNil(this.documentMap[id]) &&
                        !_.includes(this.deletedDocumentIds, id));
            if (!_.isEmpty(missingIds)) {
                await this.fetchDocuments(missingIds);
            }

            return _.isString(idOrIds)
                ? this.documentMap[ids[0]]
                : _(ids).
                    map(id => this.documentMap[id]).
                    filter().
                    value() as any;
        };

    public getAll =
        async (): Promise<TDocument[]> => {
            if (!this.hasAllDocuments) {
                const documents = await defined(this.definition.getAll)();
                this.documentMap = _.keyBy(documents, this.getDocumentId);
                this.hasAllDocuments = true;
            }

            return _.values(this.documentMap);
        };

    public notify =
        async <TDocumentIds extends string | string[] | TDocument | TDocument[]>(idsOrDocuments?: TDocumentIds): Promise<void> => {
            if (_.isNil(idsOrDocuments)) {
                const documents = await defined(this.definition.getAll)();
                this.documentMap = _.keyBy(documents, this.getDocumentId);
                this.hasAllDocuments = true;

                await this.definition.onNotify?.(undefined);
                this.refresh();
            } else {
                let documents: TDocument[];

                if (_.isString(idsOrDocuments) ||
                    _.isArray(idsOrDocuments) && _.isString(idsOrDocuments[0])) {
                    const ids = this.toDocumentIds(idsOrDocuments);
                    documents = await this.getDocuments(ids);
                } else {
                    documents = _.concat(idsOrDocuments) as TDocument[];
                }

                _.each(
                    documents,
                    document => this.documentMap[this.getDocumentId(document)] = document);

                await this.definition.onNotify?.(documents);
                this.refresh(
                    _.map(
                        documents,
                        document => this.getDocumentId(document)));
            }
        };

    public notifyDeleted =
        async (idOrIds: string | string[]) => {
            const ids = this.toDocumentIds(idOrIds);
            this.deletedDocumentIds =
                _.concat(
                    this.deletedDocumentIds,
                    ids);
            this.documentMap = _.omit(this.documentMap, ids);
            await this.definition.onNotifyDeleted?.(ids);
            this.refresh(ids);
        };

    public update =
        async (id: string, request: TUpdateDocumentRequest) => {
            const document = await defined(this.definition.update)(id, request);
            this.documentMap[this.getDocumentId(document)] = document;
            this.refresh(this.getDocumentId(document));
        };

    public useGet =
        <TDocumentIds extends string | string[] | undefined>(idOrIds: TDocumentIds): TDocumentIds extends string[] ? TDocument[] : TDocumentIds extends string ? TDocument : undefined => {
            const ids =
                _.isNil(idOrIds)
                    ? []
                    : this.toDocumentIds(idOrIds as string | string[]);
            if (!_.isEmpty(ids)) {
                if (_.isString(idOrIds) &&
                    _.includes(this.deletedDocumentIds, ids[0])) {
                    throw new StoreDeletedDocumentError(ids[0]);
                }

                const missingIds =
                    _.filter(
                        ids,
                        id =>
                            _.isNil(this.documentMap[id]) &&
                            !_.includes(this.deletedDocumentIds, id));
                if (!_.isEmpty(missingIds)) {
                    executeOperation(
                        [defined(this.fetchDocuments), JSON.stringify(missingIds)],
                        () => this.fetchDocuments(missingIds));
                }
            }

            const idsDeepDependency = useDeepDependency(ids);
            const refresh = this.useRefresh(ids);
            return useMemo(
                () => {
                    if (_.isNil(idOrIds)) {
                        return undefined;
                    }

                    return _.isString(idOrIds)
                        ? this.documentMap[ids[0]]
                        : _(ids).
                            map(id => this.documentMap[id]).
                            filter().
                            value() as any;
                },
                [idsDeepDependency, refresh]);
        };

    public useGetAll =
        () => {
            if (!this.hasAllDocuments) {
                executeOperation(
                    defined(this.definition.getAll),
                    this.getAll);
            }

            const refresh = this.useRefresh();
            return useMemo(
                () => _.values(this.documentMap),
                [refresh]);
        };

    protected useGetType =
        (type: string, fetch: Function0<Promise<TDocument[]>>, find: Function1<TDocument, boolean>) => {
            if (!this.hasAllDocuments &&
                !this.typeToHasAllDocumentsMap[type]) {
                executeOperation(
                    type,
                    async () => {
                        const documents = await fetch();
                        _.assign(
                            this.documentMap,
                            _.keyBy(documents, this.getDocumentId));
                        this.typeToHasAllDocumentsMap[type] = true;
                    });
            }
            const filteredDocumentMap = _.pickBy(this.documentMap, find);
            const refresh = this.useRefresh();

            return useMemo(
                () => _.values(filteredDocumentMap),
                [refresh]);
        };

    private fetchDocuments =
        async (ids: string[]) => {
            const fetchMissingDocuments =
                async () => {
                    let fetchDocumentCount: number;
                    do {
                        fetchDocumentCount = this.fetchDocumentIds.length;
                        await delay((window as any).fetchDocumentsInterval ?? 100);
                    }
                    while (fetchDocumentCount != this.fetchDocumentIds.length);

                    const fetchDocumentIds = this.fetchDocumentIds;
                    this.fetchDocumentIds = [];
                    this.fetchDocumentIdsPromise = undefined;

                    const missingDocuments = await this.getDocuments(fetchDocumentIds);
                    for (const missingDocument of missingDocuments) {
                        const missingDocumentId = this.getDocumentId(missingDocument);
                        this.documentMap[missingDocumentId] = missingDocument;
                        delete this.documentIdToPromiseMap[missingDocumentId];
                    }
                };

            const missingIds =
                _.filter(
                    ids,
                    id => _.isNil(this.documentIdToPromiseMap[id]));
            if (!_.isEmpty(missingIds)) {
                this.fetchDocumentIds = _.union(this.fetchDocumentIds, missingIds);
                if (_.isNil(this.fetchDocumentIdsPromise)) {
                    this.fetchDocumentIdsPromise = fetchMissingDocuments();
                }

                _.each(
                    missingIds,
                    missingId => this.documentIdToPromiseMap[missingId] = this.fetchDocumentIdsPromise!);
            }

            await Promise.all(
                _(ids).
                    map(id => this.documentIdToPromiseMap[id]).
                    uniq().
                    value());

            this.refresh(ids);
        };

    private getDocuments =
        async (ids: string[]) =>
            _.flatMap(
                await Promise.all(
                    _(ids).
                        chunk(10000).
                        map(idBatch => this.definition.get!(idBatch)).
                        value()));

    private refresh =
        (idOrIds?: string | string[]) =>
            _<Dispatch<any>>(_.isNil(idOrIds)
                ? _.flatMap(this.documentIdToSetRefreshesMap)
                : _.flatMap(
                    this.toDocumentIds(idOrIds),
                    id => this.documentIdToSetRefreshesMap[id] ?? [])).
                concat(this.documentsSetRefreshes).
                each(setRefresh => setRefresh({}));

    private toDocumentIds =
        (idOrIds: string | string[]) =>
            _.isString(idOrIds)
                ? [idOrIds as string]
                : _.uniq(idOrIds as string[]);

    private useRefresh =
        (ids?: string[]) => {
            const [refresh, setRefresh] = useState({});
            useEffect(
                () => {
                    if (_.isNil(ids)) {
                        this.documentsSetRefreshes.push(setRefresh);

                        return () => {
                            this.documentsSetRefreshes.splice(this.documentsSetRefreshes.indexOf(setRefresh), 1);
                        };
                    } else {
                        _.each(
                            ids,
                            id => {
                                this.documentIdToSetRefreshesMap[id] = this.documentIdToSetRefreshesMap[id] ?? [];
                                this.documentIdToSetRefreshesMap[id].push(setRefresh);
                            });

                        return () => {
                            _.each(
                                ids,
                                id => {
                                    const documentSetRefreshes = this.documentIdToSetRefreshesMap[id];
                                    documentSetRefreshes.splice(documentSetRefreshes.indexOf(setRefresh), 1);
                                });
                        };
                    }
                },
                []);

            return refresh;
        };
}

export class StoreDeletedDocumentError extends AppError {
    constructor(id: string) {
        super(`Deleted [id=${id}]`);
        Object.setPrototypeOf(this, StoreDeletedDocumentError.prototype);
    }
}

export function ignoreStoreDeletedDocumentError<TProps extends object>(Component: React.VoidFunctionComponent<TProps>): React.VoidFunctionComponent<TProps> {
    return function IgnoreStoreDeletedDocumentErrorBoundaryComponent(props: TProps) {
        return (
            <IgnoreStoreDeletedDocumentErrorBoundary>
                <Component {...props}/>
            </IgnoreStoreDeletedDocumentErrorBoundary>);
    };
}

class IgnoreStoreDeletedDocumentErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
    constructor(props: { children: ReactNode }) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error: Error) {
        if (error instanceof StoreDeletedDocumentError) {
            return {
                hasError: true
            };
        } else {
            return;
        }
    }

    render() {
        if (this.state.hasError) {
            return <Fragment/>;
        }
        return this.props.children;
    }
}