﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";
import { useGetKubernetesResourceRiskContext } from "../contexts";

export function useKubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRisk;
    const namespaceModel = entityModelStore.useGet(risk.entityId);
    const namespace = _.as<Contract.IKubernetesNamespace>(namespaceModel.entity);

    const getKubernetesResourceRiskContext = useGetKubernetesResourceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.resources.workloadResources.useKubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskDefinition",
            () => ({
                contextItems: {
                    podSecurityPolicy: {
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.None]: "The {{translatedEntityTypeName}} doesn't have a Pod Security Standard policy configured, meaning that containers can be provisioned with hostPID set to **true**",
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.Privileged]: "The {{translatedEntityTypeName}} has a Privileged Pod Security Standard policy configured, meaning that containers can be provisioned with hostPID set to **true**"
                    },
                    processTraceCapabilityEnabledContainers: "The {{translatedEntityTypeName}} contains {{workloadResources}} with access to ptrace capabilities. The combination of hostPID and ptrace can be used to escalate privileges outside of the container",
                    vulnerabilitySeverity: "The containers that are provisioned with hostPID set to **true** have {{vulnerabilitySeverity}} sensitivity vulnerabilities",
                    workloadResources: "The {{translatedEntityTypeName}} contains {{workloadResources}} with the hostPID flag set to **true**"
                },
                description: "{{kubernetesNamespace}} contains {{workloadResources}} running containers sharing the host process ID namespace",
                resolutionSection: {
                    step1: "For each of the {{workloadResources}}, set the hostPID flag to **false**",
                    step2: "Add a Pod Security Standard policy to the {{kubernetesNamespace}}"
                }
            }));

    const getLocalizationParameters =
        () => ({
            kubernetesNamespace:
                <Entity
                    entityIdOrModel={namespaceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            translatedEntityTypeName:
                entityTypeNameTranslator(
                    namespace.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    }),
            workloadResources:
                <InlineEntities
                    entityIdsOrModels={risk.workloadResourceIds}
                    entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                    variant="itemAndTypeOrItemCountAndType"/>
        });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(getLocalizationParameters()),
        () => [
            localization.resolutionSection.step1(getLocalizationParameters()),
            localization.resolutionSection.step2({
                kubernetesNamespace:
                    <Entity
                        entityIdOrModel={namespaceModel}
                        variant="typeText"/>
            })
        ],
        riskModel,
        () => {
            const namespaceRiskContext = getKubernetesResourceRiskContext(namespaceModel);
            return [
                namespaceRiskContext.generalInformation,
                namespaceRiskContext.sensitive,
                namespaceRiskContext.relatedResources,
                new RiskDefinitionContextItem(localization.contextItems.workloadResources(getLocalizationParameters())),
                new RiskDefinitionContextItem(localization.contextItems.podSecurityPolicy[risk.podSecurityStandard as Exclude<Contract.KubernetesNamespaceDataPodSecurityStandard, Contract.KubernetesNamespaceDataPodSecurityStandard.Baseline | Contract.KubernetesNamespaceDataPodSecurityStandard.Restricted>](getLocalizationParameters())),
                _.isNil(risk.vulnerabilitySeverity)
                    ? undefined
                    : new RiskDefinitionContextItem(localization.contextItems.vulnerabilitySeverity({ vulnerabilitySeverity: severityTranslator(risk.vulnerabilitySeverity) })),
                risk.processTraceCapabilityEnabledContainers
                    ? new RiskDefinitionContextItem(localization.contextItems.processTraceCapabilityEnabledContainers(getLocalizationParameters()))
                    : undefined,
                namespaceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}