﻿import { useChangeEffect, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, RiskPoliciesType } from "../../../../../../../common";
import { ConfigurationCategory, useConfigurationContext, useSetConfigurationContext } from "../../../components";
import { useGetGeneralInheritToggleInheritedValues } from "../../useGetGeneralInheritToggleInheritedValues";
import { CapabilitiesMultiSelect, Parameter, Parameters } from "../components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

export function useKubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfigurationDefinition(): RiskPolicyConfigurationDefinitionData {
    const { item, riskPolicyConfiguration } = useConfigurationContext();
    const setConfigurationContext = useSetConfigurationContext();

    const {
        inherited,
        overridablePropertyNameToDataMap: {
            allowedCapabilities: allowedCapabilitiesData,
            requiredDropCapabilities: requiredDropCapabilitiesData
        }
    } = useGetGeneralInheritToggleInheritedValues(
        RiskPoliciesType.KubernetesAdmissionController,
        riskPolicyConfiguration,
        item,
        undefined,
        riskPolicyConfiguration => {
            const kubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration = riskPolicyConfiguration as Contract.KubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration;
            return ({
                allowedCapabilities: kubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration.allowedCapabilities,
                requiredDropCapabilities: kubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration.requiredDropCapabilities
            });
        });

    const [allowedCapabilitiesParameterValues, setAllowedCapabilitiesParameterValues] = useState(allowedCapabilitiesData.value);
    const [requiredDropCapabilitiesParameterValues, setRequiredDropCapabilitiesParameterValues] = useState(requiredDropCapabilitiesData.value);

    useEffect(
        () => {
            if (inherited) {
                setAllowedCapabilitiesParameterValues(undefined);
                setRequiredDropCapabilitiesParameterValues(undefined);
            }
        },
        [inherited]);

    useChangeEffect(
        () =>
            setConfigurationContext(
                configurationContext =>
                    ({
                        ...configurationContext,
                        additionalTabIdToSectionMap: {
                            ...configurationContext.additionalTabIdToSectionMap,
                            [ConfigurationCategory.KubernetesAdmissionControllerParameters]: {
                                valid:
                                    !configurationContext.riskPolicyConfiguration.enabled ||
                                    !_.isEmpty(allowedCapabilitiesParameterValues) ||
                                    !_.isEmpty(requiredDropCapabilitiesParameterValues)
                            }
                        },
                        dirty: true,
                        riskPolicyConfiguration: {
                            ...configurationContext.riskPolicyConfiguration,
                            allowedCapabilities: allowedCapabilitiesParameterValues,
                            requiredDropCapabilities: requiredDropCapabilitiesParameterValues
                        }
                    })),
        [allowedCapabilitiesParameterValues, requiredDropCapabilitiesParameterValues, riskPolicyConfiguration.enabled]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useKubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfigurationDefinition",
            () => ({
                allowedCapabilities: "Allowed capabilities:",
                requiredDropCapabilities: "Capabilities to drop:"
            }));

    return useMemo(
        () => ({
            additionalTabs: [
                {
                    element:
                        <Parameters inherited={inherited}>
                            {disabled =>
                                <Stack>
                                    <Parameter description={localization.allowedCapabilities()}>
                                        <CapabilitiesMultiSelect
                                            readonly={disabled}
                                            selectedCapabilities={
                                                inherited
                                                    ? allowedCapabilitiesData.inheritedValue
                                                    : allowedCapabilitiesParameterValues ?? []}
                                            onSelectedCapabilitiesChanged={setAllowedCapabilitiesParameterValues}/>
                                    </Parameter>
                                    <Parameter description={localization.requiredDropCapabilities()}>
                                        <CapabilitiesMultiSelect
                                            readonly={disabled}
                                            selectedCapabilities={
                                                inherited
                                                    ? requiredDropCapabilitiesData.inheritedValue
                                                    : requiredDropCapabilitiesParameterValues ?? []}
                                            onSelectedCapabilitiesChanged={setRequiredDropCapabilitiesParameterValues}/>
                                    </Parameter>
                                </Stack>}
                        </Parameters>,
                    id: ConfigurationCategory.KubernetesAdmissionControllerParameters
                }
            ]
        }),
        [allowedCapabilitiesData, allowedCapabilitiesParameterValues, inherited, requiredDropCapabilitiesData, requiredDropCapabilitiesParameterValues]);
}