import { Autocomplete, AutocompleteProps } from "@mui/material";
import React from "react";

type AutocompleteItemsProps<T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined> =
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
    & { children: (props: T) => React.ReactNode };

export function AutocompleteItems<T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined>({ children, ...props }: AutocompleteItemsProps<T, Multiple, DisableClearable, FreeSolo>) {
    return (
        <Autocomplete
            {...props}
            renderOption={
                (renderProps, instanceModel) =>
                    <li {...renderProps}>
                        {children(instanceModel)}
                    </li>}/>);
}