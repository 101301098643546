import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { AccessView } from "../../../views/Customer/components/Access";
import { DashboardIcon } from "../DashboardIcon";
import { AwsFederationIcon, PermissionsIcon, PrincipalExcessivePermissionsIcon, PrincipalPermissionsIcon } from "./icons";

type AccessViewIconProps =
    SvgIconProps & {
        view: AccessView;
    };

export function AccessViewIcon({ view, ...props }: AccessViewIconProps) {
    switch (view) {
        case AccessView.AwsFederation:
            return <AwsFederationIcon {...props}/>;
        case AccessView.Dashboard:
            return <DashboardIcon {...props}/>;
        case AccessView.Permissions:
            return <PermissionsIcon {...props}/>;
        case AccessView.PrincipalExcessivePermissions:
            return <PrincipalExcessivePermissionsIcon {...props}/>;
        case AccessView.PrincipalPermissions:
            return <PrincipalPermissionsIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}