import { NoneIcon } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InlineEntities, RiskPolicyItem } from "../../../../../../../common";
import { useRiskPoliciesItemExcludedEntities } from "../../../hooks";

type ExcludedEntitiesProps = {
    item: RiskPolicyItem;
    scopeId: string;
};

export function ExcludedEntities({ item, scopeId }: ExcludedEntitiesProps) {
    const riskPolicyExcludedEntityIds = useRiskPoliciesItemExcludedEntities(item, scopeId);
    const entityModels = entityModelStore.useGet(riskPolicyExcludedEntityIds);

    return (
        <Stack spacing={1}>
            {_.size(entityModels) === 0
                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                : <InlineEntities
                    entityIdsOrModels={entityModels}
                    entityTypeName={Contract.TypeNames.Entity}
                    entityVariant="iconTextTypeTenant"
                    variant="itemCountAndType"/>}
        </Stack>);
}