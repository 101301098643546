import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class OneLoginTenantsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.OneLoginTenantConfiguration) {
    }
}

export const [useOneLoginTenantsContext, useSetOneLoginTenantsContext, useOneLoginTenantsContextProvider] = makeContextProvider<OneLoginTenantsContext>();

export function OneLoginTenants() {
    const [context, setContext, ContextProvider] = useOneLoginTenantsContextProvider(() => new OneLoginTenantsContext(false));

    return (
        <ContextProvider>
            {context.addOrEditOpen != false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={() => setContext(new OneLoginTenantsContext(false))}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}