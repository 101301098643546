import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../common";

export function useAwsS3BucketServerSideEncryptionTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsS3BucketServerSideEncryptionTypeTranslator",
            () => ({
                none: "Not enabled",
                [Contract.TypeNames.AwsS3BucketServerSideEncryptionType]: {
                    [Contract.AwsS3BucketServerSideEncryptionType.Kms]: "KMS Key (SSE-KMS)",
                    [Contract.AwsS3BucketServerSideEncryptionType.KmsDualLayer]: "KMS Key (DSSE-KMS)",
                    [Contract.AwsS3BucketServerSideEncryptionType.S3]: "S3 Key (SSE-S3)"
                }
            }));
    return (encryptionType?: Contract.AwsS3BucketServerSideEncryptionType) =>
        _.isNil(encryptionType)
            ? localization.none()
            : localization[Contract.TypeNames.AwsS3BucketServerSideEncryptionType][encryptionType]();

}