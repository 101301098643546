import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function UdmObjectsIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M17.3854 4.94171C17.5579 5.46637 17.2724 6.03152 16.7477 6.20402L3.25446 10.6405C3.23917 10.6465 3.22644 10.6577 3.21854 10.6721C3.21037 10.6871 3.20794 10.7045 3.2117 10.7211L3.73604 12.8204L9.85683 11.515C10.397 11.3999 10.9282 11.7443 11.0434 12.2845C11.1586 12.8246 10.8141 13.3559 10.274 13.4711L4.09749 14.7883C4.09685 14.7884 4.09621 14.7886 4.09557 14.7887C3.59944 14.8959 3.08105 14.8037 2.65225 14.532C2.22575 14.2618 1.92195 13.8356 1.80551 13.3446L1.26883 11.1959C1.14669 10.6947 1.21607 10.166 1.46334 9.71336C1.71061 9.26068 2.11797 8.91664 2.60564 8.7486L2.61907 8.74409L16.1231 4.30409C16.6477 4.13158 17.2129 4.41706 17.3854 4.94171Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.8698 10.6144C18.985 11.1545 18.6405 11.6858 18.1004 11.801L13.7684 12.725C13.2282 12.8402 12.697 12.4957 12.5818 11.9556C12.4666 11.4154 12.811 10.8842 13.3512 10.769L17.6832 9.84496C18.2233 9.72975 18.7546 10.0742 18.8698 10.6144Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M12.448 13.8956C12.942 13.6486 13.5427 13.8488 13.7897 14.3428L16.8947 20.5528C17.1417 21.0468 16.9414 21.6475 16.4475 21.8944C15.9535 22.1414 15.3528 21.9412 15.1058 21.4472L12.0008 15.2372C11.7538 14.7432 11.954 14.1426 12.448 13.8956Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.7879 2.27273C19.3021 2.14445 19.8462 2.22553 20.3007 2.49815C20.7552 2.77078 21.0829 3.21266 21.2119 3.72673L22.7269 9.78701C22.8557 10.3014 22.775 10.8463 22.5024 11.3011C22.2297 11.7561 21.7875 12.0841 21.2729 12.213L20.1823 12.4852C20.1822 12.4852 20.1824 12.4852 20.1823 12.4852C19.4106 12.6779 18.5937 12.5563 17.9116 12.1471C17.2295 11.7378 16.7379 11.0744 16.5448 10.3027L15.5148 6.18253C15.322 5.41076 15.4435 4.59386 15.8528 3.91172C16.2621 3.22959 16.9255 2.73794 17.6972 2.54489L18.7879 2.27273ZM19.272 4.21326L18.1826 4.4851C18.1825 4.48513 18.1827 4.48508 18.1826 4.4851C17.9255 4.5495 17.7042 4.71342 17.5678 4.94071C17.4314 5.16805 17.3908 5.44025 17.4551 5.69746C17.455 5.69741 17.4551 5.69751 17.4551 5.69746L18.485 9.81732C18.5494 10.0746 18.7133 10.2957 18.9406 10.4321C19.168 10.5686 19.4403 10.6091 19.6975 10.5448L20.7869 10.273L19.272 4.21326Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M5.91542 7.66291C6.45121 7.52896 6.99415 7.85472 7.12809 8.39051L8.24209 12.8465C8.37604 13.3823 8.05028 13.9252 7.51449 14.0592C6.97869 14.1931 6.43576 13.8674 6.30181 13.3316L5.18781 8.87558C5.05386 8.33979 5.37962 7.79686 5.91542 7.66291Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11.5524 13.8956C12.0464 14.1426 12.2466 14.7432 11.9996 15.2372L8.89465 21.4472C8.64766 21.9412 8.04698 22.1414 7.55301 21.8944C7.05903 21.6475 6.8588 21.0468 7.10579 20.5528L10.2108 14.3428C10.4578 13.8488 11.0585 13.6486 11.5524 13.8956Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12ZM9 13C9 11.3431 10.3431 10 12 10C13.6569 10 15 11.3431 15 13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}