import _ from "lodash";

declare module "lodash" {
    interface Collection<T> {
        aggregate: <TValue>(aggregateItem?: (value: TValue, otherValue: TValue) => TValue) => _.Object<T>;
        as: <T>() => Collection<T>;
        concatIf: (condition: boolean | (() => boolean), ...concatItems: (T | (() => T) | T[] | (() => T[]))[]) => Collection<T>;
    }

    interface LoDashStatic {
        aggregate: <T, TValue>(items: T[], aggregateItem?: (item: TValue, otherItem: TValue) => TValue) => _.Object<T>;
        as: <T>(item: any) => T;
        concatIf: <T>(items: T[], condition: boolean | (() => boolean), ...concatItems: (T | (() => T) | T[] | (() => T[]))[]) => T[];
        isEmpty<T extends object>(value: T | null | undefined): value is EmptyObjectOf<T> | null | undefined;
        isEqualByProperties: (item: any, otherItem: any) => boolean;
        toValueToKeyMap: <T extends keyof any, TValue extends keyof any>(keyToValueMap: { [index in T]: TValue }) => { [key in TValue]: T };
    }
}

_.mixin({
    aggregate:
        (items: any[], aggregateItem?: (item: any, otherItem: any) => any) => {
            if (_.isNil(aggregateItem)) {
                return _.merge({}, ...items);
            } else {
                return _.reduce(
                    items,
                    (aggregatedItem, item) =>
                        _.mergeWith(
                            aggregatedItem,
                            item,
                            (value, otherValue) => aggregateItem(value, otherValue)));
            }
        },
    as: (item: any) => item,
    concatIf:
        (items: any[], condition: boolean, ...concatItems: any[]) =>
            _.isFunction(condition)
                ? condition()
                : condition
                    ? _.concat(
                        items,
                        ..._.map(
                            concatItems,
                            concatItem =>
                                _.isFunction(concatItem)
                                    ? concatItem()
                                    : concatItem))
                    : items,
    isEqualByProperties:
        (item: any, otherItem: any) =>
            _.isEqual(
                JSON.parse(JSON.stringify(item)),
                JSON.parse(JSON.stringify(otherItem))),
    toValueToKeyMap:
        <T extends keyof any, TValue extends keyof any>(keyToValueMap: { [index in T]: TValue }) =>
            _.reduce(
                keyToValueMap,
                (result, value, key) => {
                    result[value] = key as T;
                    return result;
                },
                {} as { [key in TValue]: T })
});