﻿import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceDefinition, useGcpCommonScopeResourceDefinition, useGcpCommonServiceAccountOriginatorScopeResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper } from "../../../../../../../../../../common";
import { useGcpFunctionsFunctionTriggerTypeTranslator, useGcpFunctionsFunctionTypeTranslator } from "../../../../../../hooks";
import { EntityTableDefinition } from "../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpFunctionsFunctionDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useGcpCommonEncryptedResourceDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const commonServiceAccountOriginatorScopeResourceDefinition = useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData);

    const functionsFunctionTypeTranslator = useGcpFunctionsFunctionTypeTranslator();
    const functionsFunctionTriggerTypeTranslator = useGcpFunctionsFunctionTriggerTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpFunctionsFunctionDefinition",
            () => ({
                columns: {
                    artifactRegistry: "Artifact Registry",
                    buildServiceAccount: "Build Service Account",
                    triggerType: "Trigger Type",
                    triggerUrl: "Function URL",
                    type: "Environment"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            commonServiceAccountOriginatorScopeResourceDefinition.columns.serviceAccountColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpFunctionsFunctionModel) => ({
                            [localization.columns.buildServiceAccount()]: (item.entity as Contract.GcpFunctionsFunction).buildServiceAccountData.serviceAccountMail ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpFunctionsFunctionBuildServiceAccount)}
                                placeholder={localization.columns.buildServiceAccount()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpFunctionsFunctionBuildServiceAccount}
                key={Contract.EntityModelProperty.GcpFunctionsFunctionBuildServiceAccount}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpFunctionsFunctionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.buildServiceAccountIdReference}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}/>}
                selectorOptions={{ default: false }}
                title={localization.columns.buildServiceAccount()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpFunctionsFunctionModel) => ({
                            [localization.columns.type()]: functionsFunctionTypeTranslator((item.entity as Contract.GcpFunctionsFunction).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpFunctionsFunctionType}
                                enumTypeTranslator={functionsFunctionTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpFunctionsFunctionType}
                itemProperty={(item: Contract.GcpFunctionsFunctionModel) => functionsFunctionTypeTranslator((item.entity as Contract.GcpFunctionsFunction).type)}
                key={Contract.EntityModelProperty.GcpFunctionsFunctionType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpFunctionsFunctionModel) => ({
                            [localization.columns.triggerType()]: functionsFunctionTriggerTypeTranslator((item.entity as Contract.GcpFunctionsFunction).triggerType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpFunctionsFunctionTriggerType}
                                enumTypeTranslator={functionsFunctionTriggerTypeTranslator}
                                placeholder={localization.columns.triggerType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpFunctionsFunctionTriggerType}
                itemProperty={(item: Contract.GcpFunctionsFunctionModel) => functionsFunctionTriggerTypeTranslator((item.entity as Contract.GcpFunctionsFunction).triggerType)}
                key={Contract.EntityModelProperty.GcpFunctionsFunctionTriggerType}
                title={localization.columns.triggerType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpFunctionsFunctionModel) => ({
                            [localization.columns.triggerUrl()]: (item.entity as Contract.GcpFunctionsFunction).triggerUrl
                        })
                }}
                id="triggerUrl"
                key="triggerUrl"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpFunctionsFunctionModel) => ({
                            [localization.columns.artifactRegistry()]:
                                _.isNil((item.entity as Contract.GcpFunctionsFunction).artifactRegistryAssetPath)
                                    ? ""
                                    : (item.entity as Contract.GcpFunctionsFunction).artifactRegistryAssetPath
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpFunctionsFunctionArtifactRegistry)}
                                placeholder={localization.columns.artifactRegistry()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpFunctionsFunctionArtifactRegistry}
                key={Contract.EntityModelProperty.GcpFunctionsFunctionArtifactRegistry}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpFunctionsFunctionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.artifactRegistryIdReference}
                            entityTypeName={Contract.TypeNames.GcpArtifactRegistry}/>}
                title={localization.columns.artifactRegistry()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionKeyColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpFunctionsFunctionRequest(
                new Contract.EntityControllerGetEntityModelPageGcpFunctionsFunctionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpFunctionsFunctionArtifactRegistry]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpFunctionsFunctionBuildServiceAccount]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpFunctionsFunctionTriggerType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpFunctionsFunctionType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount]))));
}