import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceDefinition, useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpComputeSnapshotStatusTranslator, useGcpComputeSnapshotTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpComputeSnapshotDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useGcpCommonEncryptedResourceDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const computeSnapshotStatusTranslator = useGcpComputeSnapshotStatusTranslator();
    const computeSnapshotTypeTranslator = useGcpComputeSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeSnapshotDefinition",
            () => ({
                columns: {
                    instanceIdReferences: "VM Instances",
                    size: "Size",
                    sourceDiskIdReference: "Source",
                    status: "Status",
                    type: "Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeSnapshotModel) => ({
                            [localization.columns.status()]: computeSnapshotStatusTranslator((item.entity as Contract.GcpComputeSnapshot).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeSnapshotStatus}
                                enumTypeTranslator={computeSnapshotStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeSnapshotStatus}
                itemProperty={(item: Contract.EntityModel) => computeSnapshotStatusTranslator((item.entity as Contract.GcpComputeSnapshot).status)}
                key={Contract.EntityModelProperty.GcpComputeSnapshotStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeSnapshotModel) => ({
                            [localization.columns.type()]: computeSnapshotTypeTranslator((item.entity as Contract.GcpComputeSnapshot).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeSnapshotType}
                                enumTypeTranslator={computeSnapshotTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeSnapshotType}
                itemProperty={(item: Contract.EntityModel) => computeSnapshotTypeTranslator((item.entity as Contract.GcpComputeSnapshot).type)}
                key={Contract.EntityModelProperty.GcpComputeSnapshotType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeSnapshotModel>(
                        Contract.TypeNames.GcpComputeDisk,
                        item => [item.sourceDiskIdReference],
                        localization.columns.sourceDiskIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeSnapshotSourceDisk)}
                                placeholder={localization.columns.sourceDiskIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeSnapshotSourceDisk}
                key={Contract.EntityModelProperty.GcpComputeSnapshotSourceDisk}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeSnapshotModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.sourceDiskIdReference}
                            entityTypeName={Contract.TypeNames.GcpComputeDisk}
                            entityVariant="iconText"/>}
                title={localization.columns.sourceDiskIdReference()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeSnapshotModel) => ({
                            [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.GcpComputeSnapshot).size)
                        })
                }}
                id={Contract.EntityModelProperty.GcpComputeSnapshotSize}
                itemProperty={(item: Contract.GcpComputeSnapshotModel) => NumberFormatter.storage((item.entity as Contract.GcpComputeSnapshot).size)}
                key={Contract.EntityModelProperty.GcpComputeSnapshotSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeSnapshotModel>(
                        Contract.TypeNames.GcpComputeInstance,
                        item => item.instanceIdReferences,
                        localization.columns.instanceIdReferences())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeSnapshotInstances)}
                                placeholder={localization.columns.instanceIdReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeSnapshotInstances}
                key={Contract.EntityModelProperty.GcpComputeSnapshotInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeSnapshotModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIdReferences}
                            entityTypeName={Contract.TypeNames.GcpComputeInstance}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.instanceIdReferences()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionKeyColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeSnapshotRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeSnapshotRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeSnapshotInstances]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeSnapshotSourceDisk]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeSnapshotStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeSnapshotType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys]))));
}