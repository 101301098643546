import { Store } from "@infrastructure";
import { Contract, EntityController } from "..";

export const entityModelStore =
    new Store<Contract.EntityModel, never, never>(
        entityModel => entityModel.entity.id,
        {
            get:
                async ids => {
                    const { entityModels } = await EntityController.getEntityModels(new Contract.EntityControllerGetEntityModelsRequest(ids));
                    return entityModels;
                }
        });