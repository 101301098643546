﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../controllers";
import { Entity } from "../../../../../../Entity";

export function useAwsDeleteSecurityGroupChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const deleteSecurityGroupChange = change as Contract.AwsDeleteSecurityGroupChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteSecurityGroupChangeDefinition",
            () => ({
                title: "Delete the {{securityGroup}}"
            }));
    return {
        title:
            localization.title({
                securityGroup:
                    <Entity
                        entityIdOrModel={deleteSecurityGroupChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}