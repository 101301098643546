import { DataTableColumn, DataTableSortType, DeferredFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, InlineResourceTags, PagedEntityResourceTagFilter, ResourceTagHelper, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonEntityDefinition } from "./useGcpCommonEntityDefinition";

export function useGcpCommonResourceDefinition(definitionData: DefinitionData) {
    const resourceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GcpResourceModelFilters>;
    const commonEntityDefinition = useGcpCommonEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCommonResourceDefinition",
            () => ({
                columns: {
                    accessLevel: "Access",
                    creatorIdentity: "Created By Identity",
                    creatorOriginatorEntity: "Created By Originator",
                    scopeId: {
                        text: "{{displayName}} ({{rawShortId}})",
                        title: "Scope"
                    },
                    tags: "GCP Labels",
                    updateTime: "Update Time"
                }
            }));

    const createTagsColumn =
        (title: string) =>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpResourceModel) => ({
                            [title]:
                                _((item.entity as Contract.GcpResource).tags).
                                    map(ResourceTagHelper.getDisplayName).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityResourceTagFilter
                                entityTypeName={definitionData.entityTypeEntitiesViewName}
                                placeholder={title}/>
                    }
                }}
                id={Contract.EntityModelProperty.ResourceTags}
                key={Contract.EntityModelProperty.ResourceTags}
                render={
                    optionalTableCell<Contract.GcpResourceModel>(
                        (item: Contract.GcpResourceModel) =>
                            _.isEmpty((item.entity as Contract.GcpResource).tags)
                                ? undefined
                                : <InlineResourceTags tags={(item.entity as Contract.GcpResource).tags}/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={title}/>;

    return {
        columns: {
            ...commonEntityDefinition.columns,
            creatorIdentityCsvColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.GcpResourceModel>(
                            Contract.TypeNames.GciDirectoryPrincipal,
                            item => [item.creatorIdentityIdReference],
                            localization.columns.creatorIdentity())}
                    id="creatorIdentityIdReference"
                    key="creatorIdentityIdReference"/>,
            creatorOriginatorEntityCsvColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.GcpResourceModel>(
                            Contract.TypeNames.GciDirectoryPrincipal,
                            item => [item.creatorOriginatorEntityIdReference],
                            localization.columns.creatorOriginatorEntity())}
                    id="creatorOriginatorEntityIdReference"
                    key="creatorOriginatorEntityIdReference"/>,
            scopeColumn:
                <DataTableColumn
                    id="gcpResourceScopeId"
                    key="gcpResourceScopeId"
                    render={
                        optionalTableCell<Contract.EntityModel>(
                            item => {
                                const gciParentResourceData = _.as<Contract.IGciGcpResourceModel>(item).gciParentResourceData!;
                                return _.isNil(gciParentResourceData)
                                    ? undefined
                                    : <Typography noWrap={true}>
                                        {localization.columns.scopeId.text({
                                            displayName: gciParentResourceData.displayName,
                                            rawShortId: gciParentResourceData.rawShortId
                                        })}
                                    </Typography>;
                            })}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.scopeId.title()}/>,
            tagsColumn: createTagsColumn(localization.columns.tags()),
            updateTimeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GcpResourceModel) => ({
                                [localization.columns.updateTime()]:
                                    _.isNil((item.entity as Contract.GcpResource).updateTime)
                                        ? ""
                                        : TimeFormatter.iso8601String((item.entity as Contract.GcpResource).updateTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={resourceModelFiltersPromise}
                                    title={localization.columns.updateTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.updateTimeRange.emptyValue}
                                            placeholder={localization.columns.updateTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.updateTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.GcpResourceUpdateTime}
                    key={Contract.EntityModelProperty.GcpResourceUpdateTime}
                    render={
                        optionalTableCell<Contract.GcpResourceModel>(
                            item =>
                                _.isNil((item.entity as Contract.GcpResource).updateTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear((item.entity as Contract.GcpResource).updateTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.updateTime()}/>
        },
        createTagsColumn
    };
}