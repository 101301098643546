import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../../common";
import { useAwsResourceAccessLevelTranslator } from "../../../../../../../../../../../../../../../../tenants";
import { useOpenRiskedEntityRisksStep } from "../../../../../../../useOpenRiskedEntityRisksStep";
import { useResourceGeneralInformationStep } from "../../../../../../../useResourceGeneralInformationStep";


export type SnapshotContextSectionProps = {
    risk: Contract.AwsEc2SnapshotEncryptionDisabledRisk;
    snapshotModel: Contract.AwsEc2SnapshotModel;
};

export function SnapshotContextSection({ risk, snapshotModel }: SnapshotContextSectionProps) {
    const riskData = risk.data as Contract.AwsEc2SnapshotEncryptionDisabledRiskSnapshotData;
    const imageModels = entityModelStore.useGet(riskData.imageIds);

    const sensitiveImageModels =
        useMemo(
            () =>
                _.filter(
                    imageModels,
                    imageModel => imageModel.entityConfiguration?.sensitive === true),
            [imageModels]);

    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(snapshotModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(snapshotModel);

    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotEncryptionDisabledRiskDefinition.hooks.useAwsEc2SnapshotEncryptionDisabledRiskSnapshotDefinition.snapshotContextSection",
            () => ({
                accessLevel: "The EBS snapshot's access level is **{{accessLevel}}**",
                images: "The EBS snapshot is associated with {{images}}",
                sensitive: [
                    "The {{resources}} is marked as sensitive",
                    "The {{resources}} are marked as sensitive"]
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                _.isEmpty(riskData.imageIds)
                    ? undefined
                    : localization.images({
                        images:
                            <InlineEntities
                                entityIdsOrModels={riskData.imageIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Image}
                                variant="itemCountAndType"/>
                    }),
                snapshotModel?.entityConfiguration?.sensitive
                    ? localization.sensitive(
                        1,
                        {
                            resources:
                                <Entity
                                    entityIdOrModel={snapshotModel}
                                    variant="text"/>
                        })
                    : undefined,
                _.isEmpty(sensitiveImageModels)
                    ? undefined
                    : localization.sensitive(
                        _.size(sensitiveImageModels),
                        {
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveImageModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Image}
                                    variant="itemOrItemCountAndType"/>
                        }),
                _.isNil(snapshotModel.accessLevel)
                    ? undefined
                    : localization.accessLevel({ accessLevel: resourceAccessLevelTranslator(snapshotModel.accessLevel) }),
                openRiskedEntityRisksStep
            ])}
        </Steps>);
}