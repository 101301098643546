import { InlineItems, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, InlineMaliciousFile } from "../../../../../../../../../../../../../common";
import { MaliciousFiles } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { InsertTrustedFiles } from "../../../components";
import { useGetContainerImageWorkloadAnalysisRiskContext } from "../../contexts";

export function useContainerImageMaliciousFileRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const containerImageMaliciousFileRisk = riskModel.risk as Contract.ContainerImageMaliciousFileRisk;

    const getContainerImageWorkloadAnalysisRiskContext = useGetContainerImageWorkloadAnalysisRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useContainerImageMaliciousFileRiskDefinition",
            () => ({
                description: "The container image on {{riskedEntity}}, stored in {{containerImageRepository}}, has {{maliciousFiles}}",
                maliciousFiles: [
                    "1 suspected malicious file",
                    "{{count | NumberFormatter.humanize}} suspected malicious files"
                ],
                sections: {
                    maliciousFiles: "Malicious Files",
                    resolution: {
                        step2: {
                            step1: "Isolate and Disconnect: Isolate the nodes on which the container is deployed from the network, to prevent Malware from spreading to other devices.",
                            step2: "Quarantine: Isolate/quarantine the infected files or software. This prevents the Malware from further executing and causing harm.",
                            step3: "Take a snapshot of the nodes on which the container is deployed for analysis and forensic evidence.",
                            step4: "Terminate the nodes on which the container is deployed.",
                            title: "Otherwise:"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            containerImageRepository:
                <Entity
                    entityIdOrModel={containerImageMaliciousFileRisk.containerImageRepositoryReference}
                    variant="text"/>,
            maliciousFiles:
                <InlineItems
                    items={
                        _.map(
                            containerImageMaliciousFileRisk.fileContentSha256Strings,
                            fileContentSha256String => <InlineMaliciousFile contentSha256String={fileContentSha256String}/>)}
                    namePluralizer={localization.maliciousFiles}
                    variant="itemCountAndType"/>,
            riskedEntity:
                <Entity
                    entityIdOrModel={containerImageMaliciousFileRisk.entityId}
                    variant="text"/>
        }),
        () => [
            <InsertTrustedFiles
                contentSha256Strings={containerImageMaliciousFileRisk.fileContentSha256Strings}
                key={riskModel.id}
                tenantId={riskModel.tenantId}/>,
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const containerImageWorkloadAnalysisRiskContext = getContainerImageWorkloadAnalysisRiskContext(containerImageMaliciousFileRisk);

            return [
                containerImageWorkloadAnalysisRiskContext.generalInformation,
                containerImageWorkloadAnalysisRiskContext.virtualMachines,
                containerImageWorkloadAnalysisRiskContext.getVulnerabilities(),
                containerImageWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.Critical),
                containerImageWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.High),
                containerImageWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(containerImageMaliciousFileRisk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <MaliciousFiles
                        entityIds={containerImageMaliciousFileRisk.containerImageIds}
                        tenantId={riskModel.tenantId}
                        variant="risk"/>,
                    localization.sections.maliciousFiles(),
                    {
                        expandable: true
                    })
            ]
        });
}