﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsRoute53HostedZoneSigningStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsRoute53HostedZoneSigningStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsRoute53HostedZoneSigningStatus]: {
                    [Contract.AwsRoute53HostedZoneSigningStatus.ActionNeeded]: "Action needed",
                    [Contract.AwsRoute53HostedZoneSigningStatus.Deleting]: "Deleting",
                    [Contract.AwsRoute53HostedZoneSigningStatus.InternalFailure]: "Internal failure",
                    [Contract.AwsRoute53HostedZoneSigningStatus.NotSigning]: "Not signing",
                    [Contract.AwsRoute53HostedZoneSigningStatus.Signing]: "Signing"
                }
            }));
    return (signingStatus: Contract.AwsRoute53HostedZoneSigningStatus) =>
        localization[Contract.TypeNames.AwsRoute53HostedZoneSigningStatus][signingStatus]();
}