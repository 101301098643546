import { Steps, UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Change, Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { ChangeStep, RiskDefinitionContextItem } from "../../../../../../utilities";
import { ResourcesExternalConsoleLink } from "../../components";
import { useGetGcpIamServiceAccountRiskContext } from "../contexts";

export function useGcpIamServiceAccountInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpIamServiceAccountInactiveRisk;
    const serviceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamServiceAccountModel;
    const serviceAccount = serviceAccountModel.entity as Contract.GcpIamServiceAccount;

    const getGcpIamServiceAccountRiskContext = useGetGcpIamServiceAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpIamServiceAccountInactiveRiskDefinition",
            () => ({
                description: "{{serviceAccount}} is inactive and should be disabled or deleted",
                sections: {
                    context: {
                        [Contract.TypeNames.GcpIamServiceAccountInactiveRiskModelInfoType]: {
                            [Contract.GcpIamServiceAccountInactiveRiskModelInfoType.SignInNever]: "The service account was not active since it was created",
                            [Contract.GcpIamServiceAccountInactiveRiskModelInfoType.SignInTime]: "The service account was last active {{signInTime | TimeFormatter.humanizePastDuration}}",
                            [Contract.GcpIamServiceAccountInactiveRiskModelInfoType.SignInTimeNotExact]: "The service account was last active more than {{signInTime | TimeFormatter.humanizeDuration}}"
                        }
                    },
                    resolution: {
                        delete: "Locate and select service account **{{serviceAccountMail}}** and click **DELETE**",
                        disable: {
                            step2: "To delete the service account, locate and select service account **{{serviceAccountMail}}** and click **DELETE**",
                            step3: "To disable the service account, click on **{{serviceAccountMail}}** to enter its profile page and then click on **DISABLE SERVICE ACCOUNT**"
                        }
                    }
                }
            }));

    function getDeleteServiceAccountChangeStep(deleteServiceAccountChange: Contract.GcpDeleteServiceAccountChange) {
        return new ChangeStep(
            deleteServiceAccountChange,
            {
                contentElement:
                    <Steps variant="plainNumbers">
                        {[
                            <ResourcesExternalConsoleLink
                                key={risk.tenantId}
                                tenantId={risk.tenantId}
                                typeName={serviceAccount.typeName}/>,
                            localization.sections.resolution.delete({ serviceAccountMail: serviceAccount.mail })
                        ]}
                    </Steps>,
                title:
                    <Change
                        change={deleteServiceAccountChange}
                        entityLinkDisabled={true}/>
            });
    }

    function getDisableServiceAccountChangeStep(disableServiceAccountChange: Contract.GcpDisableServiceAccountChange) {
        return new ChangeStep(
            disableServiceAccountChange,
            {
                contentElement:
                    <Steps variant="plainNumbers">
                        {[
                            <ResourcesExternalConsoleLink
                                key={risk.tenantId}
                                tenantId={risk.tenantId}
                                typeName={serviceAccount.typeName}/>,
                            localization.sections.resolution.disable.step2({ serviceAccountMail: serviceAccount.mail }),
                            localization.sections.resolution.disable.step3({ serviceAccountMail: serviceAccount.mail })
                        ]}
                    </Steps>,
                title:
                    <Change
                        change={disableServiceAccountChange}
                        entityLinkDisabled={true}/>
            });
    }

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            serviceAccount:
                <Entity
                    entityIdOrModel={serviceAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            _.map(
                risk.resolutionChanges,
                resolutionChange => {
                    switch (resolutionChange.typeName) {
                        case Contract.TypeNames.GcpDeleteServiceAccountChange:
                            return getDeleteServiceAccountChangeStep(resolutionChange as Contract.GcpDeleteServiceAccountChange);
                        case Contract.TypeNames.GcpDisableServiceAccountChange:
                            return getDisableServiceAccountChangeStep(resolutionChange as Contract.GcpDisableServiceAccountChange);
                        default:
                            throw new UnexpectedError("change.typeName", resolutionChange.typeName);
                    }
                }),
        riskModel,
        () => {
            const serviceAccountRiskContext = getGcpIamServiceAccountRiskContext(serviceAccountModel);
            return [
                serviceAccountRiskContext.generalInformation,
                serviceAccountRiskContext.originatorEntities,
                new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.GcpIamServiceAccountInactiveRiskModelInfoType][(riskModel as Contract.GcpIamServiceAccountInactiveRiskModel).infoType]({ signInTime: risk.signInActivity?.time })),
                serviceAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}