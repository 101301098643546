import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";

export function useAwsAossCollectionDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const collection = resourceModel.entity as Contract.AwsAossCollection;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsAossCollectionDefinition",
            () => ({
                info: {
                    items: {
                        storageSize: "Size"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="storageSize"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(collection.storageSize)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}