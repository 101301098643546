﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetAwsEc2VolumesRiskContext() {
    return useMemo(
        () => useAwsEc2VolumesRiskContext,
        []);
}

function useAwsEc2VolumesRiskContext(volumeIdsOrModels: string[] | Contract.AwsEc2VolumeModel[]) {
    return useGetEntitiesRiskContext()(
        volumeIdsOrModels,
        Contract.TypeNames.AwsEc2Volume);
}