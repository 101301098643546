import { Action1, AnalyticsEventActionType, EmptyValueIcon, EmptyValueOptions, makeContextProvider, Optional, PagedDropdown, PagedDropdownPage, useChangeEffect, useFilterConnectorContext, useLocalization, useOperation, useSetFilterConnectorContext, useSetFiltersContext, useTrackAnalytics } from "@infrastructure";
import { List, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { PagedSelectionView, PagedValueListItem, Selectors } from "./components";

type PagedValuesFilterProps = {
    children?: (inline: boolean, value: any) => ReactNode;
    emptyValueOptions?: EmptyValueOptions;
    getValuePage: (searchText: Optional<string>, skip: number, limit: number, data?: any) => Promise<PagedValuesFilterValuePage>;
    getValuesData?: (values: string[]) => Promise<Dictionary<any[]>>;
    placeholder: string;
};

export class PagedValuesFilterValuePage {
    constructor(
        public count: number,
        public emptyValue: Optional<boolean>,
        public values: string[],
        public applyData?: () => any) {
    }
}

export class PagedValuesFilterContext {
    constructor(
        public valuesData: Record<string, unknown>,
        public onChange: React.MutableRefObject<Action1<Record<string, unknown>> | undefined>,
        public fetchValuesData?: (values: string[]) => Promise<Dictionary<any>>) {
    }

    public async getValuesData(values: string[]): Promise<Record<string, unknown>> {
        const existingValues = _.keys(this.valuesData);
        const missingValues =
            _.without(
                values,
                ...existingValues);

        if (missingValues.length === 0) {
            return _.pick(this.valuesData, values);
        }

        if (!this.fetchValuesData) {
            const newData = _.reduce(
                values,
                (arr, value) => ({ ...arr, [value]: value }),
                {});

            this.updateContext(newData);
            return newData;
        }

        const newData = await this.fetchValuesData(missingValues);

        if (_.size(newData) > 0) {
            this.updateContext(newData);
        }

        return _.pick(this.valuesData, values);
    }

    private updateContext(newData: Record<string, unknown>) {
        this.valuesData = {
            ...this.valuesData,
            ...newData
        };
        this.onChange.current?.(this.valuesData);
    }
}

export const [usePagedValuesFilterContext, , usePagedValuesFilterContextProvider] = makeContextProvider<PagedValuesFilterContext>();

export function PagedValuesFilter({ children: renderValue, emptyValueOptions = { enabled: true, variant: "text" }, getValuePage, getValuesData, placeholder }: PagedValuesFilterProps) {
    const filteredValueFirstPageLimit = 65000;
    const valueDataLimit = 30;
    const valueLimit = 100;

    const { filter, open } = useFilterConnectorContext() as {
        filter?: PagedValuesFilterSelection<any>;
        open: boolean;
    };
    const setContextRef = useRef<Action1<Record<string, unknown>>>();
    const [context, setContext, ContextProvider] = usePagedValuesFilterContextProvider(() => new PagedValuesFilterContext({}, setContextRef, getValuesData));
    setContextRef.current = (data: any) => setContext(() => new PagedValuesFilterContext(data, setContextRef, getValuesData));
    const [initialFirstValuePagePromise, executeGetValuePage] =
        useOperation(
            [PagedValuesFilter, placeholder],
            async () => {
                if (_.isNil(filter) ||
                    (filter.type === "include" && _.isEmpty(filter.values) && !filter.emptyValue)) {
                    return undefined;
                }

                return await getValuePage(
                    undefined,
                    0,
                    valueLimit,
                    undefined);
            });

    const [initialFirstValuePage, setInitialFirstValuePage] = useState<PagedValuesFilterValuePage | undefined>(undefined);
    const [unfilteredValueCount, setUnfilteredValueCount] = useState(0);
    const [emptyValueEnabled, setEmptyValueEnabled] = useState<boolean | undefined>(undefined);

    useEffect(
        () => {
            async function initialFirstValuePage() {
                const initialFirstValuePagePromiseValue = await initialFirstValuePagePromise;

                setInitialFirstValuePage(initialFirstValuePagePromiseValue);
                setUnfilteredValueCount(initialFirstValuePagePromiseValue?.count ?? 0);
                setEmptyValueEnabled(
                    initialFirstValuePagePromiseValue?.emptyValue ||
                    (_.isNil(initialFirstValuePagePromiseValue?.emptyValue) && emptyValueOptions.enabled) ||
                    undefined);
            }

            initialFirstValuePage();
        },
        [initialFirstValuePagePromise]);

    useChangeEffect(
        () => {
            if (_.isNil(initialFirstValuePage) && !_.isNil(filter)) {
                executeGetValuePage();
            }
        },
        [filter]);

    const setFiltersContext = useSetFiltersContext();
    const setFilterConnectorContext = useSetFilterConnectorContext();
    const valuesRef = useRef<string[]>([]);
    const valuesPageSkipRef = useRef<number>(0);
    const valueChecked =
        useCallback(
            (value?: string) =>
                _.isNil(filter)
                    ? false
                    : _.isNil(value)
                        ? filter.type === "include"
                            ? filter.emptyValue
                            : !filter.emptyValue
                        : filter.type === "include"
                            ? _.includes(filter?.values, value)
                            : !_.includes(filter?.values, value),
            [filter]);
    const indexChecked =
        useCallback(
            (valueDataIndex: number | undefined) => {
                const value =
                    _.isNil(valueDataIndex)
                        ? undefined
                        : valuesRef.current[valueDataIndex];
                return valueChecked(value);
            },
            [filter]);

    const trackAnalytics = useTrackAnalytics();
    const onOnlyClick =
        (valueDataIndex?: number) => {
            setFilterConnectorContext(
                context =>
                    ({
                        ...context,
                        filter:
                            _.isNil(valueDataIndex)
                                ? new PagedValuesFilterSelection(true, "include", [])
                                : new PagedValuesFilterSelection(false, "include", [valuesRef.current[valueDataIndex]]),
                        open: false
                    }));
            trackAnalytics(
                AnalyticsEventActionType.FilterValueOnlySet,
                {
                    "Filter Name": placeholder
                });
        };

    const toggleValue =
        useCallback(
            (valueDataIndex?: number) => {
                // eslint-disable-next-line prefer-const
                let { emptyValue, type, values } = filter ?? PagedValuesFilterSelection.createEmpty();

                if (_.isNil(valueDataIndex)) {
                    emptyValue = !emptyValue;
                } else {
                    const value = valuesRef.current[valueDataIndex];
                    values =
                        _.includes(values, value)
                            ? _.without(values, value)
                            : _.concat(values, value);
                }

                const newFilter =
                    new PagedValuesFilterSelection(
                        emptyValue,
                        type,
                        values);

                setFilterConnectorContext(
                    context =>
                        ({
                            ...context,
                            filter:
                                newFilter.isEmpty(unfilteredValueCount)
                                    ? undefined
                                    : newFilter
                        }));
            },
            [filter, unfilteredValueCount]);

    const onDropdownClosed =
        useCallback(
            () => {
                setFiltersContext(
                    context => ({
                        ...context,
                        activeFilter: undefined
                    }));
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        open: false
                    }));
                setFiltered(false);
            },
            []);

    const localization =
        useLocalization(
            "infrastructure.filters.pagedValuesFilter",
            () => ({
                empty: "(Blanks)",
                search: {
                    empty: "No results",
                    title: "Search"
                }
            }));

    const valueCountRef = useRef(0);
    const [limitedFilteredFirstValuePageValues, setLimitedFilteredFirstValuePageValues] = useState<string[]>();
    const [filtered, setFiltered] = useState(false);
    const [loaded, setLoaded] = useState(false);

    return (
        <ContextProvider>
            <PagedDropdown
                analyticsOptions={{
                    onClose: {
                        actionType: AnalyticsEventActionType.FilterValueClose,
                        propertyNameToValueMap: {
                            "Filter Name": placeholder,
                            "Filter Selected Value Count": filter?.values.length ?? 0,
                            "Filter Selection Type": filter?.type ?? "",
                            "Filter Type": "PagedValuesFilter"
                        }
                    },
                    onOpen: {
                        actionType: AnalyticsEventActionType.FilterValueOpen,
                        propertyNameToValueMap: {
                            "Filter Name": placeholder,
                            "Filter Type": "PagedValuesFilter"
                        }
                    }
                }}
                containerSx={{ padding: 0 }}
                disabled={unfilteredValueCount === 0}
                emptyText={localization.search.empty()}
                fieldOptions={{
                    emptyValue: _.isNil(filter?.values) && !filter?.emptyValue,
                    placeholder,
                    selection:
                        <PagedSelectionView
                            emptyValueOptions={{
                                emptyValue: emptyValueEnabled && !!filter?.emptyValue,
                                title: emptyValueOptions.title
                            }}
                            label={placeholder}
                            loaded={loaded || !_.isNil(initialFirstValuePage)}
                            renderValue={renderValue}
                            selectedEntities={filter?.values}
                            totalCount={unfilteredValueCount}
                            type={filter?.type}/>,
                    variant: "filter"
                }}
                getItemPage={
                    async (searchText, skip, data?: PagedValuesFilterValuePageData) => {
                        const filtered = !_.isEmpty(searchText);
                        let emptyValue = false;
                        let valueCount = valueCountRef.current;
                        let valuePageApplyData: Optional<() => any>;
                        let values = valuesRef.current;
                        let valuesPageSkip = valuesPageSkipRef.current;
                        const firstFetch = skip === 0;

                        if (firstFetch ||
                            skip >= values.length) {
                            const valuePage =
                                !_.isNil(initialFirstValuePage) &&
                                !filtered &&
                                firstFetch
                                    ? initialFirstValuePage
                                    : await getValuePage(
                                        searchText,
                                        firstFetch
                                            ? 0
                                            : valuesPageSkip,
                                        filtered && firstFetch
                                            ? filteredValueFirstPageLimit
                                            : valueLimit,
                                        data?.data);

                            valuePageApplyData = valuePage.applyData;

                            const valuesWithSelected =
                                _.isEmpty(filter?.values) ||
                                !_.isEmpty(searchText) ||
                                !firstFetch
                                    ? valuePage.values
                                    : [...filter!.values, ...valuePage.values];

                            if (firstFetch) {
                                setLoaded(true);
                                emptyValue = valuePage.emptyValue ?? false;
                                setEmptyValueEnabled(
                                    valuePage.emptyValue || (_.isNil(valuePage.emptyValue) && emptyValueOptions.enabled)
                                        ? true
                                        : undefined);
                                valueCount = valuePage.count;
                                values = _.uniq(valuesWithSelected);
                                valuesPageSkip = valuePage.values.length;
                            } else {
                                values =
                                    _.concat(
                                        values,
                                        _.difference(
                                            valuesWithSelected,
                                            values));
                                valuesPageSkip += valuePage.values.length;
                            }
                        }

                        const orderedValues =
                            !firstFetch
                                ? values
                                : _(values).
                                    orderBy(valueChecked, "desc").
                                    value();
                        const valueDataValuePage =
                            _.slice(
                                orderedValues,
                                skip,
                                skip + valueDataLimit);
                        const valuesData = await context.getValuesData(valueDataValuePage);
                        const valuesDataList =
                            _.map(
                                valueDataValuePage,
                                dataValue => valuesData[dataValue]);
                        return new PagedDropdownPage(
                            skip + valuesDataList.length < valueCount,
                            valuesDataList,
                            () => {
                                if(firstFetch)
                                {
                                    if (filtered) {
                                        setLimitedFilteredFirstValuePageValues(
                                            valueCount <= filteredValueFirstPageLimit
                                                ? orderedValues
                                                : undefined);
                                    } else {
                                        setUnfilteredValueCount(valueCount);
                                    }
                                }

                                valueCountRef.current = valueCount;
                                valuesRef.current = orderedValues;
                                valuesPageSkipRef.current = valuesPageSkip;

                                setFiltered(filtered);

                                return {
                                    count: valueCount,
                                    data: valuePageApplyData?.() ?? data?.data
                                };
                            },
                            emptyValue);
                    }}
                headerElement={
                    <Selectors
                        analyticsOptions={{
                            toggleMatches: {
                                actionType: AnalyticsEventActionType.FilterValueMatchesSet,
                                propertyNameToValueMap: {
                                    "Filter Name": placeholder
                                }
                            },
                            toggleValues: {
                                actionType: AnalyticsEventActionType.FilterValueAllSet,
                                propertyNameToValueMap: {
                                    "Filter Name": placeholder
                                }
                            }
                        }}
                        emptyValueEnabled={emptyValueEnabled && !!filter?.emptyValue}
                        filtered={filtered}
                        limitedFilteredFirstValuePageValues={limitedFilteredFirstValuePageValues}
                        unfilteredValueCount={unfilteredValueCount}/>}
                open={open}
                popoverElementContainerSx={{ padding: 0 }}
                searchOptions={{
                    enabled: true,
                    onTextChanged: () => setFiltered(false),
                    placeholder: localization.search.title()
                }}
                onClose={onDropdownClosed}
                onOpen={
                    () =>
                        setFilterConnectorContext(
                            context => ({
                                ...context,
                                open: true
                            }))}>
                {(valuesData: any[]) =>
                    <List
                        dense={true}
                        disablePadding={true}>
                        {!!emptyValueEnabled && loaded &&
                            <PagedValueListItem
                                checked={indexChecked(undefined)}
                                onCheckboxClick={() => toggleValue(undefined)}
                                onOnlyClick={() => onOnlyClick(undefined)}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    {emptyValueOptions.variant == "iconText" &&
                                        <EmptyValueIcon sx={{ fontSize: "24px" }}/>}
                                    <Typography>
                                        {emptyValueOptions.title ?? localization.empty()}
                                    </Typography>
                                </Stack>
                            </PagedValueListItem>}
                        {_(valuesData).
                            filter().
                            map(
                                (valueData, valueDataIndex) =>
                                    <PagedValueListItem
                                        checked={indexChecked(valueDataIndex)}
                                        key={valuesRef.current[valueDataIndex]}
                                        onCheckboxClick={() => toggleValue(valueDataIndex)}
                                        onOnlyClick={() => onOnlyClick(valueDataIndex)}>
                                        {renderValue?.(false, valueData) ?? valueData}
                                    </PagedValueListItem>).
                            value()}
                    </List>}
            </PagedDropdown>
        </ContextProvider>);
}

export class PagedValuesFilterSelection<TValue> {
    constructor(
        public emptyValue: boolean,
        public type: "exclude" | "include",
        public values: TValue[]) {
    }

    static createEmpty() {
        return new PagedValuesFilterSelection(false, "include", []);
    }

    isEmpty(valueCount?: number) {
        return this.type === "include"
            ? !this.emptyValue && _.isEmpty(this.values)
            : this.emptyValue && this.values.length === valueCount;
    }
}

type PagedValuesFilterValuePageData = {
    count: number;
    data: any;
};