﻿import { ItemSelector, Loading, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../../common";
import { EntitiesInfoItem, TagsInfoItem } from "../../../../../../components";

type CertificateVersionsProps = {
    certificateModel: Contract.AzureKeyVaultVaultCertificateModel;
};

export function CertificateVersions({ certificateModel }: CertificateVersionsProps) {
    const certificate = certificateModel.entity as Contract.AzureKeyVaultVaultCertificate;
    const [selectedVersion, setSelectedVersion] = useState(() => certificate.versionNameToVersionMap[certificate.currentVersionName]);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultCertificateDefinition.certificateVersions",
            () => ({
                current: {
                    empty: "current",
                    text: "{{versionName}} (current)"
                },
                fields: {
                    aadDirectoryApplications: "Microsoft Entra ID App Registrations",
                    activationTime: "Activation Time",
                    creationTime: "Creation Time",
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    expirationTime: "Expiration Time",
                    issuerName: "Issuer",
                    name: "Identifier",
                    serialNumber: "Serial Number",
                    subject: "Subject",
                    tags: "Tags",
                    thumbprint: "X.509 SHA-1 Thumbprint (in hex)",
                    updateTime: "Update Time",
                    vaultKeyId: "Key",
                    vaultSecretId: "Secret"
                }
            }));

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(certificate.versionNameToVersionMap).
                        values().
                        orderBy(
                            [
                                version => version.name === certificate.currentVersionName,
                                version => version.creationTime
                            ],
                            [
                                "desc",
                                "desc"
                            ]).
                        map(version => version.name).
                        value()}
                selectedItem={selectedVersion.name}
                sorted={false}
                onSelectedItemChanged={versionName => setSelectedVersion(certificate.versionNameToVersionMap[versionName])}>
                {versionName =>
                    versionName === certificate.currentVersionName
                        ? _.isEmpty(versionName)
                            ? localization.current.empty()
                            : localization.current.text({ versionName })
                        : versionName}
            </ItemSelector>
            <Loading>
                <InfoCard columns={true}>
                    <EntitiesInfoItem
                        entityIdsOrModels={certificateModel.versionNameToDirectoryApplicationIdsMap[selectedVersion.name]}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplication}
                        title={localization.fields.aadDirectoryApplications()}/>
                    <InfoItem
                        key="creationTime"
                        title={localization.fields.creationTime()}
                        value={
                            _.isNil(selectedVersion.creationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(selectedVersion.creationTime)}/>
                    <InfoItem
                        key="updateTime"
                        title={localization.fields.updateTime()}
                        value={TimeFormatter.profileFormatDateTime(selectedVersion.updateTime)}/>
                    <InfoItem
                        key="name"
                        title={localization.fields.name()}
                        value={
                            _.isEmpty(selectedVersion.name)
                                ? undefined
                                : selectedVersion.name}/>
                    <InfoItem
                        key="enabled"
                        title={localization.fields.enabled.title()}
                        value={
                            selectedVersion.enabled
                                ? localization.fields.enabled.true()
                                : localization.fields.enabled.false()}/>
                    <InfoItem
                        key="activationTime"
                        title={localization.fields.activationTime()}
                        value={
                            _.isNil(selectedVersion.activationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(selectedVersion.activationTime)}/>
                    <InfoItem
                        key="expirationTime"
                        title={localization.fields.expirationTime()}
                        value={
                            _.isNil(selectedVersion.expirationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(selectedVersion.expirationTime)}/>
                    <TagsInfoItem
                        resourceTags={selectedVersion.tags}
                        title={localization.fields.tags()}/>
                    {!_.isNil(selectedVersion.subject) &&
                        <InfoItem
                            key="subject"
                            title={localization.fields.subject()}
                            value={selectedVersion.subject}/>}
                    {!_.isNil(selectedVersion.issuerName) &&
                        <InfoItem
                            key="issuerName"
                            title={localization.fields.issuerName()}
                            value={selectedVersion.issuerName}/>}
                    {!_.isNil(selectedVersion.serialNumber) &&
                        <InfoItem
                            key="serialNumber"
                            title={localization.fields.serialNumber()}
                            value={selectedVersion.serialNumber}/>}
                    <InfoItem
                        key="thumbprint"
                        title={localization.fields.thumbprint()}
                        value={selectedVersion.thumbprint}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedVersion.vaultKeyId}
                        entityTypeName={Contract.TypeNames.AzureKeyVaultVaultKey}
                        key="vaultKeyId"
                        title={localization.fields.vaultKeyId()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedVersion.vaultSecretId}
                        entityTypeName={Contract.TypeNames.AzureKeyVaultVaultSecret}
                        key="vaultSecretId"
                        title={localization.fields.vaultSecretId()}/>
                </InfoCard>
            </Loading>
        </InfoCard>);
}