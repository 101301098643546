import { Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../../../../common";

interface ParameterProps {
    children: ReactNode;
    description: string;
}

export function Parameter({ children, description }: ParameterProps) {
    const theme = useTheme();
    return (
        <Stack sx={{ width: "100%" }}>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    alignItems: "center",
                    height: "100%",
                    padding: 0.25
                }}>
                <Typography
                    noWrap={true}
                    sx={{ width: theme.spacing(15) }}>
                    {description}
                </Typography>
                {children}
            </Stack>
        </Stack>);
}