﻿import _ from "lodash";
import { Contract, RiskPoliciesType } from "../../../../../../../../../common";
import { useConfigurationDefinition } from "../../../../../hooks";

export function useCommonRiskPolicyDefinition(initialRiskPolicyConfiguration: Contract.RiskPolicyConfiguration, riskPoliciesType = RiskPoliciesType.Cloud) {
    const { additionalTabs } = useConfigurationDefinition(initialRiskPolicyConfiguration, riskPoliciesType);

    return {
        ..._.reduce(
            additionalTabs,
            (result, additionalTab) => ({
                ...result,
                [additionalTab.id]: {
                    component:
                        () => additionalTab.element
                }
            }),
            {})
    };
}