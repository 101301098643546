import { useLocalization } from "@infrastructure";

export function useEpssScoreTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useEpssScoreTranslator",
            () => ({
                epss: "{{value}}%"
            }));

    return (epssScore: number) => localization.epss({ value: trimNumber(epssScore * 100) });
}

function trimNumber(number: number, to = 2) {
    return parseFloat(number.toFixed(to));
}