﻿import { ActionButton, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { DiffViewerIcon, useTheme } from "../../../../../../../../../../../../../../../common";
import { RoleDefinitionsDiffDialog } from "./components";

type ReplaceRoleAssignmentResourcesChangeDiffProps = {
    existingRoleDefinitionIds: string[];
    newRoleDefinitionIds: string[];
};

export function ReplaceRoleAssignmentResourcesChangeDiff({ existingRoleDefinitionIds, newRoleDefinitionIds }: ReplaceRoleAssignmentResourcesChangeDiffProps) {
    const [roleDefinitionDiff, setRoleDefinitionDiff] = useState(false);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureExcessivePermissionPrincipalRiskDefinition.replaceRoleAssignmentResourcesChangeDiff",
            () => ({
                actions: {
                    diff: "Diff"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            {roleDefinitionDiff &&
                <RoleDefinitionsDiffDialog
                    existingRoleDefinitionIds={existingRoleDefinitionIds}
                    newRoleDefinitionIds={newRoleDefinitionIds}
                    onClose={() => setRoleDefinitionDiff(false)}/>}
            <ActionButton
                tooltip={localization.actions.diff()}
                onClick={() => setRoleDefinitionDiff(true)}>
                <DiffViewerIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "24px"
                    }}/>
            </ActionButton>
        </Stack>);
}