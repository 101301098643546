import { AnalyticsEventActionType, useFilterConnectorContext, useSetFilterConnectorContext, useSetFiltersContext, ValuesFilterSelection } from "@infrastructure";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { TenantMultiSelect } from ".";
import { tenantModelStore } from "../stores";

type ScopeTenantFilterProps = {
    placeholder: string;
    tenantIds: string[];
};

export function ScopeTenantFilter({ placeholder, tenantIds }: ScopeTenantFilterProps) {
    const { filter, open } = useFilterConnectorContext() as { filter?: ValuesFilterSelection<string>; open: boolean };
    const setFilterConnectorContext = useSetFilterConnectorContext();
    const setFiltersContext = useSetFiltersContext();

    const tenantModels = tenantModelStore.useGetAll();
    const activeTenantIds =
        useMemo(
            () =>
                _(tenantModels).
                    map(tenantModel => tenantModel.configuration.id).
                    intersection(tenantIds).
                    value(),
            [tenantIds, tenantModels]);
    const onClose =
        useCallback(
            () => {
                setFiltersContext(
                    context => ({
                        ...context,
                        activeFilter: undefined
                    }));
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        open: false
                    }));
            },
            []);
    return (
        <TenantMultiSelect
            analyticsOptions={{
                onClose: {
                    actionType: AnalyticsEventActionType.FilterValueSet,
                    propertyNameToValueMap: {
                        "Filter Name": placeholder,
                        "Filter Type": "ScopeFilter",
                        "Selected Values Count": filter?.values.length ?? 0
                    }
                },
                onOpen: {
                    actionType: AnalyticsEventActionType.FilterValueOpen,
                    propertyNameToValueMap: {
                        "Filter Name": placeholder,
                        "Filter Type": "ScopeFilter"
                    }
                }
            }}
            disabled={_.isEmpty(activeTenantIds)}
            dropdownTitle={true}
            open={!_.isEmpty(tenantIds) && open}
            placeholder={placeholder}
            selectedTenantIds={
                _.intersection(
                    filter?.values ?? [],
                    activeTenantIds)}
            tenantIds={activeTenantIds}
            variant="filter"
            onClose={onClose}
            onSelectedTenantIdsChanged={
                selectedTenantIds => {
                    if (_.isEmpty(selectedTenantIds)) {
                        setFilterConnectorContext(
                            context => ({
                                ...context,
                                filter: undefined
                            }));
                    } else {
                        setFilterConnectorContext(
                            context => ({
                                ...context,
                                filter:
                                    new ValuesFilterSelection(
                                        _.includes(selectedTenantIds, undefined),
                                        _.filter(
                                            selectedTenantIds,
                                            selectedTenantId => !_.isNil(selectedTenantId)))
                            }));
                    }
                }}/>);
}