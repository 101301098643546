import { map } from "@infrastructure";
import React from "react";
import { Contract, EntityIcon } from "../../../../common";
import { Ec2ImageIcon, Ec2SnapshotIcon, EcrRepositoryIcon, EsDomainIcon, IamRoleIcon, KmsKeyIcon, LambdaFunctionConfigurationIcon, RdsClusterSnapshotIcon, RdsDatabaseInstanceSnapshotIcon, S3BucketAccessPointIcon, S3BucketIcon, SecretsManagerSecretIcon, SnsTopicIcon, SqsQueueIcon } from "./icons";

type AwsResourcePermitterIconProps = {
    blurred?: boolean;
    resourceTypeName?: string;
};

export function AwsResourcePermitterIcon({ blurred = false, resourceTypeName }: AwsResourcePermitterIconProps) {
    return map(
        resourceTypeName,
        {
            [Contract.TypeNames.AwsEc2Image]: () => <Ec2ImageIcon/>,
            [Contract.TypeNames.AwsEc2Snapshot]: () => <Ec2SnapshotIcon/>,
            [Contract.TypeNames.AwsEsDomain]: () => <EsDomainIcon/>,
            [Contract.TypeNames.AwsEcrRepository]: () => <EcrRepositoryIcon/>,
            [Contract.TypeNames.AwsIamRole]: () => <IamRoleIcon/>,
            [Contract.TypeNames.AwsKmsKey]: () => <KmsKeyIcon/>,
            [Contract.TypeNames.AwsLambdaFunctionConfiguration]: () => <LambdaFunctionConfigurationIcon/>,
            [Contract.TypeNames.AwsRdsClusterSnapshot]: () => <RdsClusterSnapshotIcon/>,
            [Contract.TypeNames.AwsRdsDatabaseInstanceSnapshot]: () => <RdsDatabaseInstanceSnapshotIcon/>,
            [Contract.TypeNames.AwsS3Bucket]: () => <S3BucketIcon/>,
            [Contract.TypeNames.AwsS3BucketAccessPoint]: () => <S3BucketAccessPointIcon/>,
            [Contract.TypeNames.AwsSecretsManagerSecret]: () => <SecretsManagerSecretIcon/>,
            [Contract.TypeNames.AwsSnsTopic]: () => <SnsTopicIcon/>,
            [Contract.TypeNames.AwsSqsQueue]: () => <SqsQueueIcon/>
        },
        () =>
            <EntityIcon
                blurred={blurred}
                entityTypeName={Contract.TypeNames.AwsIamPolicy}/>);
}