import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../..";

export function useGcpDeleteScopeResourcePrincipalRoleBindingsChangeDefinition(change: Contract.GcpChange, entityLinkDisabled?: boolean) {
    const deleteScopeResourcePrincipalRoleBindingsChange = change as Contract.GcpDeleteScopeResourcePrincipalRoleBindingsChange;
    const scopeResource = entityModelStore.useGet(deleteScopeResourcePrincipalRoleBindingsChange.resourceId).entity as Contract.GcpScopeResource;

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useGcpDefinition.hooks.useGcpDeleteScopeResourcePrincipalRoleBindingsChangeDefinition",
            () => ({
                title: "Remove all IAM bindings assigned to {{scopeResource}}"
            }));

    return {
        title:
            localization.title({
                scopeResource:
                    <Entity
                        entityIdOrModel={scopeResource.id}
                        entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}