import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureMySqlServerRiskContext } from "../contexts";

export function useAzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const serverAadOnlyAuthenticationDisabledRiskModel = riskModel as Contract.AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel;
    const risk = riskModel.risk as Contract.AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureMySqlFlexibleServerModel;
    const server = serverModel.entity as Contract.AzureMySqlFlexibleServer;

    const getAzureMySqlServerRiskContext = useGetAzureMySqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskDefinition",
            () => ({
                description: "{{server}} authentication method is not Microsoft Entra ID-only",
                sections: {
                    resolution: {
                        step1: {
                            link: "Microsoft Entra ID authentication for Azure Database for MySQL - Flexible Server",
                            text: "Before disabling MySQL authentication, verify that doing so will not break any existing applications. For more information, see {{documentationLink}}"
                        },
                        step2: "Under **Assign access to**, switch to **Microsoft Entra authentication only**",
                        step3: {
                            applicationPermissionDocumentationLink: "Application.Read.All",
                            groupPermissionDocumentationLink: "GroupMember.Read.All",
                            text: "In the **Select Identity** section, select or create a **User assigned managed identity** with the following permissions: {{userPermissionDocumentationLink}}, {{groupPermissionDocumentationLink}}, {{applicationPermissionDocumentationLink}}",
                            userPermissionDocumentationLink: "User.Read.All"
                        },
                        step4: "In the **Microsoft Entra Admins** section, choose a valid Microsoft Entra ID user or an Microsoft Entra ID group in the customer tenant to be an Microsoft Entra ID administrator",
                        step5: "Click the **Save** button on top to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={`${risk.entityId}-${Contract.AzureConsoleEntityPage.ActiveDirectoryAdmins}`}
                page={Contract.AzureConsoleEntityPage.ActiveDirectoryAdmins}/>,
            localization.sections.resolution.step1.text({
                documentationLink:
                    <Link
                        urlOrGetUrl={serverAadOnlyAuthenticationDisabledRiskModel.aadAuthenticationDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            _.isNil(server.aadAuthenticationAdministratorPrincipalIdReference)
                ? localization.sections.resolution.step3.text({
                    applicationPermissionDocumentationLink:
                        <Link
                            urlOrGetUrl={serverAadOnlyAuthenticationDisabledRiskModel.applicationPermissionDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step3.applicationPermissionDocumentationLink()}
                        </Link>,
                    groupPermissionDocumentationLink: <Link
                        urlOrGetUrl={serverAadOnlyAuthenticationDisabledRiskModel.groupPermissionDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step3.groupPermissionDocumentationLink()}
                    </Link>,
                    userPermissionDocumentationLink: <Link
                        urlOrGetUrl={serverAadOnlyAuthenticationDisabledRiskModel.userPermissionDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step3.userPermissionDocumentationLink()}
                    </Link>
                })
                : undefined,
            _.isNil(server.aadAuthenticationAdministratorPrincipalIdReference)
                ? localization.sections.resolution.step4()
                : undefined,
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureMySqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.authenticationType,
                serverRiskContext.status,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}