import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, InfoItem, KubernetesAdmissionControllerRiskPolicyItem, RiskPoliciesType, useRiskPolicyTranslator } from "../../../../../../../../../../../common";
import { useGetRiskPolicyItemBuiltInComplianceTypes } from "../../../../../../../../Compliance/hooks";
import { useGetInheritedOverridableValue, useKubernetesAdmissionControllerRiskPolicyEffectTranslator, useRiskPoliciesItemConfiguration, useRiskPoliciesTypeData } from "../../../../../../../hooks";
import { RiskPolicyInfoItems } from "../../../useRiskPolicyInfoItems";
import { useCommonRiskPolicyInfoItems } from "../../useCommonRiskPolicyInfoItems";

export function useKubernetesAdmissionControllerRiskPolicyBuiltInInfoItems(scopeId: string, item: KubernetesAdmissionControllerRiskPolicyItem): RiskPolicyInfoItems {
    const { riskPolicyConfiguration } = useRiskPoliciesItemConfiguration(RiskPoliciesType.KubernetesAdmissionController, item, scopeId);
    const commonRiskPolicyInfoItems = useCommonRiskPolicyInfoItems(item, scopeId);
    const getRiskPolicyItemBuiltInComplianceTypes = useGetRiskPolicyItemBuiltInComplianceTypes();

    const { inherited, inheritedValue: inheritedEffect, value: effect } =
        useGetInheritedOverridableValue(
            riskPolicyConfiguration => (riskPolicyConfiguration as Contract.BuiltInKubernetesAdmissionControllerRiskPolicyConfiguration).effect,
            RiskPoliciesType.KubernetesAdmissionController,
            riskPolicyConfiguration,
            item);
    const effectValue =
        inherited
            ? inheritedEffect!
            : effect!;
    const { tenantTypes } = useRiskPoliciesTypeData(RiskPoliciesType.KubernetesAdmissionController);

    const effectTranslator = useKubernetesAdmissionControllerRiskPolicyEffectTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.profile.hooks.useRiskPolicyInfoItems.hooks.kubernetesAdmissionController.hooks.useKubernetesAdmissionControllerRiskPolicyBuiltInInfoItems",
            () => ({
                configuration: "Configuration",
                effect: "Effect",
                subtitle: "Kubernetes Admission Controller Policy"
            }));

    return useMemo(
        () => {
            const { description, title } = riskPolicyTranslator(item.riskPolicyConfigurationTypeName);
            return ({
                ...commonRiskPolicyInfoItems,
                additionalInfoCard: [
                    { items: [commonRiskPolicyInfoItems.getCompliances(getRiskPolicyItemBuiltInComplianceTypes(item))] },
                    {
                        items: [
                            commonRiskPolicyInfoItems.status,
                            <InfoItem
                                key={localization.effect()}
                                title={localization.effect()}
                                value={effectTranslator(effectValue)}/>
                        ],
                        title: localization.configuration()
                    }
                ],
                description: commonRiskPolicyInfoItems.getDescription(description),
                general: [
                    commonRiskPolicyInfoItems.getTenantId(tenantTypes)
                ],
                topbar: {
                    menu: commonRiskPolicyInfoItems.getActions(RiskPoliciesType.KubernetesAdmissionController),
                    subtitle: localization.subtitle(),
                    title
                }
            });
        },
        [effectValue, item, scopeId, tenantTypes]);
}