import { Stack, SxProps, useTheme } from "@mui/material";
import React, { Fragment, useState } from "react";
import { BreakpointResizer } from "@infrastructure";


interface CustomGridProps {
    children: React.ReactNode;
    minItemWidth: number;
    sx?: SxProps;
}

export function CustomGrid({ children, minItemWidth, sx }: CustomGridProps) {
    const theme = useTheme();
    const [breakpoint, setBreakpoint] = useState(0);

    return (
        <Fragment>
            <BreakpointResizer
                breakpointXs={[breakpointX]}
                onSize={setBreakpoint}/>
            <Stack
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                sx={{
                    display: "grid",
                    gap: theme.spacing(1.5),
                    gridTemplateColumns:
                        breakpoint === 0
                            ? "1fr 1fr"
                            : `repeat(auto-fit, minmax(${theme.px(minItemWidth)}, 1fr))`,
                    ...sx
                }}>
                {children}
            </Stack>
        </Fragment>);
}

const breakpointX = 983;