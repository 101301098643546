﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciComputeInstanceStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "./useOciDefaultResourceInfoItemElements";

export function useOciComputeInstanceDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const instanceModel = resourceModel as Contract.OciComputeInstanceModel;
    const instance = instanceModel.entity as Contract.OciComputeInstance;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const instanceStatusTranslator = useOciComputeInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciComputeInstanceDefinition",
            () => ({
                info: {
                    items: {
                        availabilityDomain: "Availability Domain",
                        bootSource: "Boot Source",
                        faultDomain: "Fault Domain",
                        launchMode: {
                            title: "Launch Mode",
                            [Contract.TypeNames.OciComputeInstanceLaunchMode]: {
                                [Contract.OciComputeInstanceLaunchMode.Custom]: "Custom",
                                [Contract.OciComputeInstanceLaunchMode.Emulated]: "Emulated",
                                [Contract.OciComputeInstanceLaunchMode.Native]: "Native",
                                [Contract.OciComputeInstanceLaunchMode.ParaVirtualized]: "PARAVIRTUALIZED"
                            }
                        },
                        metadataServiceVersion1Enabled: {
                            false: "Version 2 only",
                            title: "Instance Metadata Service",
                            true: "Versions 1 and 2"
                        },
                        primaryPrivateIpAddress: "Private IP Address",
                        primaryPublicIpAddress: "Public IP Address",
                        security: {
                            enabled: {
                                false: "Disabled",
                                true: "Enabled"
                            },
                            measuredBootEnabled: "Measured Boot",
                            memoryEncryptionEnabled: "Confidential Computing",
                            secureBootEnabled: "Secure Boot",
                            tpmEnabled: "Trusted Platform Module"
                        },
                        shape: "Shape",
                        status: "Status",
                        virtualNetworkInterfaceCards: "VNICs",
                        volumeEncryptionInTransitEnabled: {
                            false: "Disabled",
                            title: "In-Transit Encryption",
                            true: "Enabled"
                        }
                    }
                }
            }));

    function translateSecurityEnabled(enabled: boolean) {
        return enabled
            ? localization.info.items.security.enabled.true()
            : localization.info.items.security.enabled.false();
    }

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={instanceStatusTranslator(instance.status)}/>,
                    <InfoItem
                        key="shape"
                        location="all"
                        title={localization.info.items.shape()}
                        value={instance.shape}/>,
                    <InfoItem
                        key="availabilityDomain"
                        location="all"
                        title={localization.info.items.availabilityDomain()}
                        value={instance.availabilityDomain}/>,
                    <InfoItem
                        key="faultDomain"
                        title={localization.info.items.faultDomain()}
                        value={instance.faultDomain}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.blockVolumeIdReferences}
                        entityTypeName={Contract.TypeNames.OciBlockStorageBlockVolume}
                        key="blockVolumes"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciBlockStorageBlockVolume,
                                { count: 0 })}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.bootVolumeIdReference}
                        entityTypeName={Contract.TypeNames.OciBlockStorageBootVolume}
                        key="bootVolume"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciBlockStorageBootVolume)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.bootSourceResourceIdReference}
                        entityTypeName={Contract.TypeNames.OciResource}
                        key="bootSource"
                        title={localization.info.items.bootSource()}/>,
                    <InfoItem
                        key="launchMode"
                        title={localization.info.items.launchMode.title()}
                        value={localization.info.items.launchMode[Contract.TypeNames.OciComputeInstanceLaunchMode][instance.launchMode]()}/>,
                    <InfoItem
                        key="metadataServiceVersion1Enabled"
                        title={localization.info.items.metadataServiceVersion1Enabled.title()}
                        value={
                            instance.metadataServiceVersion1Enabled
                                ? localization.info.items.metadataServiceVersion1Enabled.true()
                                : localization.info.items.metadataServiceVersion1Enabled.false()}/>,
                    <InfoItem
                        key="volumeEncryptionInTransitEnabled"
                        title={localization.info.items.volumeEncryptionInTransitEnabled.title()}
                        value={
                            instance.volumeEncryptionInTransitEnabled
                                ? localization.info.items.volumeEncryptionInTransitEnabled.true()
                                : localization.info.items.volumeEncryptionInTransitEnabled.false()}/>,
                    <InfoItem
                        key="securityMemoryEncryptionEnabled"
                        title={localization.info.items.security.memoryEncryptionEnabled()}
                        value={translateSecurityEnabled(instance.security.memoryEncryptionEnabled)}/>,
                    <InfoItem
                        key="securityMeasuredBootEnabled"
                        title={localization.info.items.security.measuredBootEnabled()}
                        value={translateSecurityEnabled(instance.security.measuredBootEnabled)}/>,
                    <InfoItem
                        key="securitySecureBootEnabled"
                        title={localization.info.items.security.secureBootEnabled()}
                        value={translateSecurityEnabled(instance.security.secureBootEnabled)}/>,
                    <InfoItem
                        key="securityTpmEnabled"
                        title={localization.info.items.security.tpmEnabled()}
                        value={translateSecurityEnabled(instance.security.tpmEnabled)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.instanceConfigurationIdReference}
                        entityTypeName={Contract.TypeNames.OciComputeInstanceConfiguration}
                        key="instanceConfiguration"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstanceConfiguration)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.instancePoolId}
                        entityTypeName={Contract.TypeNames.OciComputeInstancePool}
                        key="instancePool"
                        location="all"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstancePool)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.virtualNetworkInterfaceCardIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingVirtualNetworkInterfaceCard}
                        key="virtualNetworkInterfaceCards"
                        title={localization.info.items.virtualNetworkInterfaceCards()}/>,
                    <InfoItem
                        key="primaryPublicIpAddress"
                        location="all"
                        title={localization.info.items.primaryPublicIpAddress()}
                        value={instanceModel.primaryPublicIpAddress}/>,
                    <InfoItem
                        key="primaryPrivateIpAddress"
                        location="all"
                        title={localization.info.items.primaryPrivateIpAddress()}
                        value={instanceModel.primaryPrivateIpAddress}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}