import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useRiskSubStatusTranslator } from ".";
import { useStatusTranslator } from "../../views/Customer/components";
import { Contract } from "../controllers";

export function useRiskStatusSummeryTranslator() {
    const riskSubStatusTranslator = useRiskSubStatusTranslator();
    const statusTranslator = useStatusTranslator();
    const localization =
        useLocalization(
            "common.hooks.useRiskStatusSummeryTranslator",
            () => ({
                closeDate: "on {{statusUpdateTime | TimeFormatter.longDateTime}} ",
                ignoreExpirationDate: "until {{ignoreExpirationDate | TimeFormatter.mediumDate}} ",
                [Contract.TypeNames.RiskStatus]: {
                    [Contract.RiskStatus.Closed]: "{{status}} {{closeDate}}({{subStatus}})",
                    [Contract.RiskStatus.Ignored]: "{{status}} {{ignoreExpirationDate}}({{subStatus}})",
                    [Contract.RiskStatus.Open]: "{{status}} ({{subStatus}})"
                }
            }));

    return (
        status: Contract.RiskStatus,
        subStatus: Contract.RiskSubStatus,
        ignoreExpirationDate?: string,
        statusUpdateTime?: string) =>
        localization[Contract.TypeNames.RiskStatus][status]({
            closeDate:
                _.isNil(statusUpdateTime)
                    ? ""
                    : localization.closeDate({ statusUpdateTime }),
            ignoreExpirationDate:
                _.isNil(ignoreExpirationDate)
                    ? ""
                    : localization.ignoreExpirationDate({ ignoreExpirationDate }),
            status: statusTranslator(status),
            subStatus: riskSubStatusTranslator(subStatus)
        });
}