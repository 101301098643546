﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsWafWebAclDefaultActionTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsWafWebAclRulesInfoCard } from "../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsWafWebAclDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const webAcl = resourceModel.entity as Contract.AwsWafWebAcl;
    const webAclModel = resourceModel as Contract.AwsWafWebAclModel;

    const wafWebAclDefaultActionTranslator = useAwsWafWebAclDefaultActionTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsWafWebAclDefinition",
            () => ({
                info: {
                    items: {
                        associatedResources: "Associated Resources",
                        defaultAction: "Default Action",
                        description: "Description",
                        rawId: "ID"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={webAcl.rawId}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={webAcl.description}/>,
                    <InfoItem
                        key="defaultAction"
                        title={localization.info.items.defaultAction()}
                        value={wafWebAclDefaultActionTranslator(webAcl.defaultAction)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={webAclModel.associatedResourceIdReferences}
                        entityTypeName={Contract.TypeNames.AwsResource}
                        key="resources"
                        title={localization.info.items.associatedResources()}/>
                ]}
                options={options?.infoOptions}>
                <AwsWafWebAclRulesInfoCard rules={webAcl.rules}/>
            </Info>
    });
}