import { IconText, Link, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { CustomerConsoleAppUrlHelper, InfoItem, RiskPoliciesType, RiskPolicyItem, ScopeHelper, TenantIcon, useRiskPolicyTitleTranslator } from "../../../../../../../../../../../common";
import { TenantType } from "../../../../../../../../../../../common/controllers/types.generated";
import { useCommonRiskPolicyInfoItems } from "../../useCommonRiskPolicyInfoItems";

export function useCommonCodeRiskPolicyInfoItems(item: RiskPolicyItem, scopeId: string) {
    const commonRiskPolicyInfoItems = useCommonRiskPolicyInfoItems(item, scopeId);

    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.profile.hooks.useRiskPolicyInfoItems.hooks.code.hooks.useCommonCodeRiskPolicyInfoItems",
            () => ({
                cloudPolicy: "Matching Cloud Policy",
                risks: {
                    open: [
                        "1 Finding",
                        "{{count | NumberFormatter.humanize}} Findings"
                    ],
                    title: "Findings"
                }
            }));

    return useMemo(
        () => ({
            ...commonRiskPolicyInfoItems,
            cloudPolicy:
                <InfoItem
                    title={localization.cloudPolicy()}
                    value={
                        _.size(item.tenantTypes) <= 1
                            ? undefined
                            : <IconText
                                icon={
                                    <TenantIcon
                                        tenantType={
                                            _(item.tenantTypes)
                                                .filter(item => item != TenantType.Code)
                                                .first()!}/>}
                                iconSx={{ fontSize: "18px" }}
                                text={
                                    <Link
                                        urlOrGetUrl={
                                            CustomerConsoleAppUrlHelper.getRiskPoliciesProfileHashUrl(
                                                RiskPoliciesType.Cloud,
                                                item.riskPolicyConfigurationTypeNameOrId,
                                                ScopeHelper.customerId)}>
                                        {riskPolicyTitleTranslator(item.riskPolicyConfigurationTypeName)}
                                    </Link>}/>}
                    vertical={_.size(item.tenantTypes) > 1}/>,
            getRisks:
                () =>
                    <InfoItem
                        title={localization.risks.title()}>
                        {item.result.riskCount === 0 || !item.enabled
                            ? undefined
                            : <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getCodeRisksRelativeUrl({ policyConfigurationTypeNameOrIds: [item.riskPolicyConfigurationTypeNameOrId] })}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        textDecoration: "underline"
                                    }}>
                                    {localization.risks.open(
                                        item.result.riskCount,
                                        { count: item.result.riskCount })}
                                </Typography>
                            </Link>}
                    </InfoItem>
        }),
        [commonRiskPolicyInfoItems, item, scopeId]);
}