import { Steps, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../..";
import { Contract, useSeverityTranslator } from "../../../../../../../../../../../../../../common";
import { useResourceGeneralInformationStep } from "../../../../../useResourceGeneralInformationStep";
import { useRiskPolicyConfigurationInactivityTimeFrameStep } from "../../../../../useRiskPolicyConfigurationInactivityTimeFrameStep";

type ContextSectionProps = {
    risk: Contract.AwsIamUserUnusedAccessKeysEnabledRisk;
    unusedAccessKeys: Contract.AwsIamUserAccessKey[];
    userModel: Contract.AwsIamUserModel;
};

export function ContextSection({ risk, unusedAccessKeys, userModel }: ContextSectionProps) {
    const userPermissionActionSeverity = risk.userPermissionActionSeverity;

    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(userModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(userModel);
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserUnusedAccessKeysEnabledRiskDefinition.contextSection",
            () => ({
                unusedAccessKey: {
                    lastUsed: "last used on **{{lastUsedDate}}**",
                    neverUsed: "**never used**",
                    text: "The user access key {{key}} is **enabled**, was created on {{createdDate}} and was {{lastUsed}}"
                },
                userMfaEnabled: {
                    false: "The user has no MFA enabled",
                    true: "The user has MFA enabled"
                },
                userPermissionActionSeverity: {
                    any: "The user has {{userPermissionActionSeverity}} severity permissions",
                    none: "The user has no permissions"
                }
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                useRiskPolicyConfigurationInactivityTimeFrameStep(risk.accessKeyUsageTimeFrame),
                ..._.map(
                    unusedAccessKeys,
                    unusedAccessKey =>
                        localization.unusedAccessKey.text({
                            createdDate: TimeFormatter.mediumDate(unusedAccessKey.creationTime),
                            key: unusedAccessKey.uniqueId,
                            lastUsed:
                                _.isNil(unusedAccessKey.usageTime)
                                    ? localization.unusedAccessKey.neverUsed()
                                    : localization.unusedAccessKey.lastUsed({ lastUsedDate: TimeFormatter.mediumDate(unusedAccessKey.usageTime) })
                        })),
                risk.userMfaEnabled
                    ? localization.userMfaEnabled.true()
                    : localization.userMfaEnabled.false(),
                _.isNil(userPermissionActionSeverity)
                    ? localization.userPermissionActionSeverity.none()
                    : localization.userPermissionActionSeverity.any({
                        userPermissionActionSeverity: severityTranslator(userPermissionActionSeverity, "text")
                    }),
                openRiskedEntityRisksStep
            ])}
        </Steps>);
}