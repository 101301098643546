import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { KubernetesAdmissionControllerRiskPolicyItem, RiskPoliciesView, RiskPolicyItem } from "../../../../../../../../../../common";
import { useKubernetesAdmissionControllerRiskPolicyBuiltInDefinition } from "./hooks";

export function useKubernetesAdmissionControllerRiskPolicyDefinition(view: RiskPoliciesView, item: RiskPolicyItem, scopeId: string) {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case RiskPoliciesView.BuiltIn:
                        return useKubernetesAdmissionControllerRiskPolicyBuiltInDefinition;
                    default:
                        throw new UnexpectedError("useKubernetesAdmissionControllerRiskPolicyDefinition.view", view);
                }
            },
            []);

    return useDefinition(item as KubernetesAdmissionControllerRiskPolicyItem, scopeId);
}