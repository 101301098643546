import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InfoCard } from "../../../../../../../../../../../../common";

type InstancesInfoCardProps = {
    instancesIds: string[];
};

export function InstancesInfoCard({ instancesIds }: InstancesInfoCardProps) {
    const instancesModels = entityModelStore.useGet(instancesIds);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeInstanceGroupDefinition.instancesInfoCard",
            () => ({
                columns: {
                    instance: "VM Instance",
                    status: "Status"
                },
                empty: "No Instances",
                title: "VM Instances"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => instancesModels}
                getItemId={(item: Contract.GcpComputeInstanceModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={instanceInfoCardTableColumnId.Instance}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeInstanceModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTenant"/>}
                    title={localization.columns.instance()}/>
                <DataTableColumn
                    id={instanceInfoCardTableColumnId.Status}
                    itemProperty={(item: Contract.GcpComputeInstanceModel) => (item.entity as Contract.GcpComputeInstance).status}
                    title={localization.columns.status()}/>
            </DataTable>
        </InfoCard>);
}

enum instanceInfoCardTableColumnId {
    Instance = "instance",
    Status = "status"
}