﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { SnsTopicSubscriptions } from "../../components";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsSnsTopicRiskContext() {
    return useMemo(
        () => useAwsSnsTopicRiskContext,
        []);
}

function useAwsSnsTopicRiskContext(topicModel: Contract.AwsSnsTopicModel) {
    const topic = topicModel.entity as Contract.AwsSnsTopic;
    const resourceRiskContext = useGetAwsResourceRiskContext()(topicModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsSnsTopicRiskContext",
            () => ({
                encryption: {
                    text: "The {{translatedTopicTypeName}} is {{encryption}}",
                    type: {
                        kms: "encrypted using KMS key {{key}}",
                        none: "not encrypted"
                    }
                },
                subscriptions: "The {{translatedTopicTypeName}} has {{subscriptions}}"
            }));
    const translatedTopicTypeName =
        entityTypeNameTranslator(
            topic.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    encryption:
                        _.isEmpty(topic.kmsEncryptionKeyOrAliasArns)
                            ? localization.encryption.type.none()
                            : localization.encryption.type.kms(
                                {
                                    key:
                                        <Entity
                                            entityIdOrModel={topicModel.kmsEncryptionKeyIdReferences[0]}
                                            variant="text"/>
                                }),
                    translatedTopicTypeName
                })
            ),
        publicAccessPermissionActions:
            resourceRiskContext.getPublicAccessPermissionActions(
                topic.publicAccessHighSeveritySensitivePermissionActions,
                topic.publicAccessPermissionActions),
        subscriptions:
            new RiskDefinitionContextItem(
                localization.subscriptions({
                    subscriptions: <SnsTopicSubscriptions topicModel={topicModel}/>,
                    translatedTopicTypeName
                }))
    };
}