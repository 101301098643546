import { Dropdown, Loading, NoneIcon, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Contract, Entities, EntityController, useTheme } from "../../../common";

type GcpBigQueryDatasetTablesItemProps = {
    datasetId: string;
    tableCount: number;
};

export function GcpBigQueryDatasetTablesItem({ datasetId, tableCount }: GcpBigQueryDatasetTablesItemProps) {
    const localization =
        useLocalization(
            "tenants.gcp.gcpBigQueryDatasetTablesItem",
            () => ({
                tables: [
                    "1 table",
                    "{{count | NumberFormatter.humanize}} tables"
                ]
            }));
    const theme = useTheme();
    return (
        <Dropdown
            disabled={tableCount === 0}
            popoverElement={
                <Loading container="popup">
                    <Box
                        sx={{
                            maxWidth: theme.spacing(60),
                            minWidth: theme.spacing(30),
                            padding: theme.spacing(1)
                        }}>
                        <Popover datasetId={datasetId}/>
                    </Box>
                </Loading>}
            popoverElementContainerSx={{ padding: 0 }}>
            <Typography
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    ...(tableCount === 0
                        ? undefined
                        : {
                            cursor: "pointer",
                            textDecoration: "underline"
                        })
                }}>
                {tableCount === 0
                    ? <NoneIcon/>
                    : localization.tables(tableCount)}
            </Typography>
        </Dropdown>);
}

type PopoverProps = {
    datasetId: string;
};

function Popover({ datasetId }: PopoverProps) {
    const [{ tableIds }] =
        useExecuteOperation(
            [Popover, datasetId],
            () => EntityController.getGcpBigQueryDatasetTableIds(new Contract.EntityControllerGetGcpBigQueryDatasetTableIdsRequest(datasetId)));
    return (
        <Entities
            entityIdsOrModels={tableIds}
            entityTypeName={Contract.TypeNames.GcpBigQueryDatasetTable}
            entityVariant="iconTextTypeTenant"
            searchEnabled={true}
            variant="dropdown"/>);
}