import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import { InstanceItem } from "..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../../../common";
import { JiraAddOrEditType, useSetJiraContext } from "../../../../../Jira";

export function ActionsCell({ item }: DataTableColumnRenderProps<InstanceItem>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setJiraContext = useSetJiraContext();
    const [deleteProjectExecuting, setDeleteProjectExecuting] = useState(false);
    const [deleteProjectError, setDeleteProjectError] = useState(false);

    async function deleteProject() {
        setDeleteProjectExecuting(true);
        setDeleteProjectError(false);

        try {
            await ConfigurationController.deleteJiraProject(
                new Contract.ConfigurationControllerDeleteJiraProjectRequest(
                    item.instanceModel.configuration.id,
                    item.projectConfiguration.rawId));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteProjectError(true);
        }

        setDeleteProjectExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.list.instance.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        message: "Are you sure you want to delete this Jira project?",
                        title: "Delete"
                    },
                    edit: {
                        title: "Edit"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteProjectExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteProjectError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {item.instanceModel.configuration.scopeId === scopeNodeModel.configuration.id &&
                <Menu
                    itemsOrGetItems={[
                        new ActionMenuItem(
                            () =>
                                setJiraContext(
                                    () => ({
                                        addOrEditType: JiraAddOrEditType.AddOrEditProject,
                                        instanceModel: item.instanceModel,
                                        projectConfiguration: item.projectConfiguration
                                    })),
                            localization.actions.edit.title(),
                            { icon: <EditIcon/> }),
                        new ActionMenuItem(
                            () => deleteProject(),
                            localization.actions.delete.title(),
                            {
                                confirmOptions: {
                                    message: localization.actions.delete.message()
                                },
                                disabled: deleteProjectExecuting,
                                icon: <DeleteIcon/>
                            })
                    ]}/>
            }
        </Stack>);
}