import _ from "lodash";
import { useMemo } from "react";
import { Contract, EntityModelHelper, TypeHelper } from "../../../..";
import { useAadDirectoryDirectoryRoleAssignmentResourceDefinition, useAadDirectoryDirectoryRoleDefinitionDefinition, useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition, useAwsElbLoadBalancerListenerDefinition, useAwsOrganizationsAccountDefinition, useAwsOrganizationsPolicyDefinition, useAwsPartialTenantEntityDefinition, useAwsServiceDefinition, useAwsTenantEntityDefinition, useAzureAuthorizationRoleAssignmentResourceDefinition, useAzureAuthorizationRoleDefinitionDefinition, useAzureAuthorizationRoleManagementPolicyAssignmentDefinition, useAzureCognitiveServicesAccountDefinition, useAzureServiceDefinition, useAzureTenantEntityDefinition, useAzureWebApplicationDefinition, useDefaultEntityDefinition, useGciGcpResourceDefinition, useGciPartialPrincipalDefinition, useGcpIamRoleBindingDefinition, useGcpTenantEntityDefinition, useUnmanagedKubernetesClusterDefinition } from "./hooks";

export function useDefinition(entityModel: Contract.EntityModel) {
    const useDefinition =
        useMemo(
            () => {
                if (TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IGciGcpResourceModel) &&
                    !_.isNil(_.as<Contract.IGciGcpResourceModel>(entityModel).gciParentResourceData)) {
                    return useGciGcpResourceDefinition;
                }

                if (TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IUnmanagedKubernetesClusterModel)) {
                    return useUnmanagedKubernetesClusterDefinition;
                }

                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.AadDirectoryDirectoryRoleAssignment:
                    case Contract.TypeNames.AadDirectoryDirectoryRoleEligibilitySchedule:
                        return useAadDirectoryDirectoryRoleAssignmentResourceDefinition;
                    case Contract.TypeNames.AadDirectoryDirectoryRoleDefinition:
                        return useAadDirectoryDirectoryRoleDefinitionDefinition;
                    case Contract.TypeNames.AadDirectoryDirectoryRoleManagementPolicyAssignment:
                        return useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition;
                    case Contract.TypeNames.AwsElbClassicLoadBalancerListener:
                    case Contract.TypeNames.AwsElbApplicationLoadBalancerListener:
                    case Contract.TypeNames.AwsElbNetworkLoadBalancerListener:
                        return useAwsElbLoadBalancerListenerDefinition;
                    case Contract.TypeNames.AwsOrganizationsAccount:
                        return useAwsOrganizationsAccountDefinition;
                    case Contract.TypeNames.AwsOrganizationsPolicy:
                        return useAwsOrganizationsPolicyDefinition;
                    case Contract.TypeNames.AwsPartialTenantEntity:
                        return useAwsPartialTenantEntityDefinition;
                    case Contract.TypeNames.AwsService:
                        return useAwsServiceDefinition;
                    case Contract.TypeNames.AwsTenantEntity:
                        return useAwsTenantEntityDefinition;
                    case Contract.TypeNames.AzureAuthorizationRoleAssignment:
                    case Contract.TypeNames.AzureAuthorizationRoleAssignmentClassicAdministrator:
                    case Contract.TypeNames.AzureAuthorizationRoleAssignmentSchedule:
                    case Contract.TypeNames.AzureAuthorizationRoleEligibilitySchedule:
                        return useAzureAuthorizationRoleAssignmentResourceDefinition;
                    case Contract.TypeNames.AzureAuthorizationRoleDefinition:
                        return useAzureAuthorizationRoleDefinitionDefinition;
                    case Contract.TypeNames.AzureAuthorizationRoleManagementPolicyAssignment:
                        return useAzureAuthorizationRoleManagementPolicyAssignmentDefinition;
                    case Contract.TypeNames.AzureCognitiveServicesAccount:
                        return useAzureCognitiveServicesAccountDefinition;
                    case Contract.TypeNames.AzureService:
                        return useAzureServiceDefinition;
                    case Contract.TypeNames.AzureTenantEntity:
                        return useAzureTenantEntityDefinition;
                    case Contract.TypeNames.AzureWebApplication:
                        return useAzureWebApplicationDefinition;
                    case Contract.TypeNames.GciDirectoryPartialGroup:
                    case Contract.TypeNames.GciDirectoryPartialUser:
                    case Contract.TypeNames.GcpIamPartialServiceAccount:
                        return useGciPartialPrincipalDefinition;
                    case Contract.TypeNames.GcpIamRoleBinding:
                        return useGcpIamRoleBindingDefinition;
                    case Contract.TypeNames.GcpTenantEntity:
                        return useGcpTenantEntityDefinition;
                    default:
                        return useDefaultEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel);
}

type EntityDefinitionOptions = {
    displayName?: string;
    glanceOptions?: EntityDefinitionGlanceOptions;
    iconVariant?: string;
    showTenant?: boolean;
    subtitleAdditionalAttributes?: string[];
    textDisplayName?: string;
    translatedEntityTypeName?: string;
    withoutTenant?: boolean;
};

type EntityDefinitionGlanceOptions = {
    enabled?: boolean;
    entityId?: string;
};

export class EntityDefinition {
    public badge?: "publicAccess" | "restrictedAccess" | "vendor";
    public displayName: string;
    public glanceEntityId: string;
    public glanceEnabled: boolean;
    public iconVariant?: string;
    public sensitive?: boolean;
    public showTenant?: boolean;
    public subtitleAdditionalAttributes?: string[];
    public tags?: Contract.ResourceTag[];
    public tenantId?: string;
    public textDisplayName: string;
    public translatedEntityTypeName?: string;
    public typeName: string;

    constructor(
        entityModel: Contract.EntityModel,
        options?: EntityDefinitionOptions) {
        this.badge = EntityModelHelper.getBadge(entityModel);
        this.displayName = options?.displayName ?? entityModel.entity.displayName;
        this.glanceEnabled = options?.glanceOptions?.enabled ?? true;
        this.glanceEntityId = options?.glanceOptions?.entityId ?? entityModel.id;
        this.iconVariant = options?.iconVariant;
        this.sensitive = entityModel.entityConfiguration?.sensitive;
        this.subtitleAdditionalAttributes = options?.subtitleAdditionalAttributes;
        this.showTenant = options?.showTenant;
        this.tags =
            TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.IResource)
                ? _.as<Contract.IResource>(entityModel.entity).tags
                : undefined;
        this.tenantId =
            options?.withoutTenant
                ? undefined
                : entityModel.entity.tenantId;
        this.textDisplayName = options?.textDisplayName ?? this.displayName;
        this.translatedEntityTypeName = options?.translatedEntityTypeName;
        this.typeName = entityModel.entity.typeName;
    }
}