import { Action1, Dom, InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, Loading, MouseClickAreaScope, Optional, Resizer, SearchTextField, setUrlRoute, Shadows, useChangeEffect, useLocalization, useWindowEventEffect } from "@infrastructure";
import { Box, List, ListItemButton, Popper, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, MouseEvent, useRef, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, Entity, EntityController, EntityTypeMetadataModelHelper, TenantHelper, TenantIcon, tenantModelStore, useEntityTypeNameTranslator, useTheme } from "../../../../../common";

export function SearchEntities() {
    const entityModelNextPageSearchCursorRef = useRef<Contract.ElasticsearchIndexSearchCursor>();
    const searchTextRef = useRef<string>();
    const [entityModelCount, setEntityModelCount] = useState<number>();
    const [entityTypeNameToCountMap, setEntityTypeNameToCountMap] = useState<Dictionary<number>>({});
    const [entityModels, setEntityModels] = useState<Contract.EntityModel[]>([]);
    const [selectedEntityTypeName, setSelectedEntityTypeName] = useState<string>();
    const [searchText, setSearchText] = useState<string>();

    async function fetchEntities() {
        if (_.isEmpty(searchTextRef.current?.trim())) {
            return new InfiniteScrollFetchDataResult(false, true);
        }

        const { entityModelPage, entityTypeNameToCountMap } =
            await EntityController.searchAllEntityModels(
                new Contract.EntityControllerSearchAllEntityModelsRequest(
                    20,
                    entityModelNextPageSearchCursorRef.current,
                    searchTextRef.current,
                    selectedEntityTypeName));

        return new InfiniteScrollFetchDataResult(
            (entityModelPage.count ?? entityModelCount!) > 0,
            _.isNil(entityModelPage!.itemNextPageSearchCursor),
            () => {
                if (!_.isNil(entityTypeNameToCountMap)) {
                    setEntityModelCount(entityModelPage.count);
                    setEntityTypeNameToCountMap(entityTypeNameToCountMap);

                    if (!_.isNil(selectedEntityTypeName) &&
                        _.isNil(entityTypeNameToCountMap[selectedEntityTypeName])) {
                        setSelectedEntityTypeName(undefined);
                    }
                }

                setEntityModels(entityModels => _.concat(entityModels, entityModelPage!.items));
                entityModelNextPageSearchCursorRef.current = entityModelPage!.itemNextPageSearchCursor;
            });
    }

    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants([...TenantHelper.CloudProviderTenantTypes, ...TenantHelper.IdentityProviderTenantTypes]);
    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>();
    useChangeEffect(
        () => {
            setEntityModelCount(undefined);
            setEntityModels([]);
            setEntityTypeNameToCountMap({});

            entityModelNextPageSearchCursorRef.current = undefined;
            searchTextRef.current = searchText;

            if (!_.isNil(infiniteScrollActionsRef.current)) {
                infiniteScrollActionsRef.current.reset();
            }
        },
        [filteredActiveTenantModels, searchText],
        500);

    useChangeEffect(
        () => {
            setEntityModels([]);

            entityModelNextPageSearchCursorRef.current = undefined;
            infiniteScrollActionsRef.current!.reset();
        },
        [selectedEntityTypeName]);

    const resultEntitiesElementRef = useRef<HTMLDivElement | null>(null);
    const searchContainerElementRef = useRef<HTMLDivElement | null>(null);
    const [resultEntitiesElementWidth, setResultsElementWidth] = useState(0);
    useWindowEventEffect(
        "click",
        (event: MouseEvent) => {
            if (
                _.isEmpty(searchText) ||
                !event.target ||
                Dom.isChildrenOf(
                    event.target as Element,
                    parentElement =>
                        parentElement === resultEntitiesElementRef.current ||
                        parentElement === searchContainerElementRef.current)) {
                return;
            }

            setEntityModelCount(undefined);
            setEntityModels([]);
            setEntityTypeNameToCountMap({});
            setSearchText(undefined);
            setSelectedEntityTypeName(undefined);

            searchTextRef.current = undefined;
        });

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.topbar.searchEntities",
            () => ({
                empty: "No Results",
                placeholder: "Search users, roles & resources",
                typeName: {
                    all: "All",
                    title: "Resource types"
                }
            }));

    const theme = useTheme();
    return (
        <Fragment>
            <Box ref={searchContainerElementRef}>
                <Resizer onSize={rect => setResultsElementWidth(rect.width)}/>
                <SearchTextField
                    inputSx={{ borderRadius: theme.spacing(0.75) }}
                    placeholder={localization.placeholder()}
                    searchText={searchText}
                    variant="outlined"
                    onSearchTextChanged={searchText => setSearchText(searchText ?? "")}/>
            </Box>
            {!_.isEmpty(searchText) &&
                <Popper
                    anchorEl={searchContainerElementRef.current}
                    open={true}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: theme.spacing(0.75),
                        boxShadow: theme.shadows[Shadows.Tooltip],
                        width: resultEntitiesElementWidth,
                        zIndex: theme.zIndex.tooltip
                    }}>
                    <Loading>
                        <MouseClickAreaScope disabled={true}>
                            <Stack
                                direction="row"
                                ref={resultEntitiesElementRef}>
                                <Stack sx={{ width: theme.spacing(23.5) }}>
                                    <Typography
                                        noWrap={true}
                                        sx={{
                                            borderBottom: theme.border.primary,
                                            padding: theme.spacing(2, 2, 1.5, 3)
                                        }}
                                        variant="h5">
                                        {localization.typeName.title()}
                                    </Typography>
                                    <Box
                                        sx={{
                                            height: theme.spacing(58),
                                            marginRight: theme.px(1),
                                            overflow: "auto"
                                        }}>
                                        {!_.isNil(entityModelCount) &&
                                            <List
                                                disablePadding={true}
                                                sx={{ paddingTop: theme.px(5) }}>
                                                <Item
                                                    count={entityModelCount}
                                                    selected={_.isNil(selectedEntityTypeName)}
                                                    onClick={() => setSelectedEntityTypeName(undefined)}/>
                                                {_(entityTypeNameToCountMap).
                                                    toPairs().
                                                    orderBy(
                                                        [
                                                            ([, entityCount]) => entityCount,
                                                            ([entityTypeName]) =>
                                                                entityTypeNameTranslator(
                                                                    entityTypeName,
                                                                    {
                                                                        count: 0,
                                                                        includeServiceName: true
                                                                    })
                                                        ],
                                                        [
                                                            "desc",
                                                            "asc"
                                                        ]).
                                                    map(
                                                        ([entityTypeName, entityCount]) =>
                                                            <Item
                                                                count={entityCount}
                                                                entityTypeName={entityTypeName}
                                                                key={entityTypeName}
                                                                selected={selectedEntityTypeName == entityTypeName}
                                                                onClick={() => setSelectedEntityTypeName(entityTypeName)}/>).
                                                    value()}
                                            </List>}
                                    </Box>
                                </Stack>
                                <Stack
                                    sx={{
                                        borderLeft: theme.border.primary,
                                        flex: 1,
                                        overflow: "hidden"
                                    }}>
                                    <Stack
                                        direction="row"
                                        sx={{ borderBottom: theme.border.primary }}>
                                        {!_.isNil(selectedEntityTypeName) &&
                                            <TenantIcon
                                                sx={{
                                                    fontSize: "24px",
                                                    padding: theme.spacing(1.75, 1, 1, 3)
                                                }}
                                                tenantType={EntityTypeMetadataModelHelper.get(selectedEntityTypeName).tenantType}/>}
                                        <Typography
                                            noWrap={true}
                                            sx={{
                                                padding:
                                                    _.isNil(selectedEntityTypeName)
                                                        ? theme.spacing(2, 2, 1.5, 3)
                                                        : theme.spacing(2, 2, 1.5, 0)
                                            }}
                                            variant="h5">
                                            {_.isNil(selectedEntityTypeName)
                                                ? localization.typeName.all()
                                                : entityTypeNameTranslator(
                                                    selectedEntityTypeName,
                                                    {
                                                        count: 0,
                                                        includeServiceName: true
                                                    })}
                                        </Typography>
                                    </Stack>
                                    <InfiniteScroll
                                        actionsRef={infiniteScrollActionsRef}
                                        container="popup"
                                        emptyTextOptions={{
                                            text:
                                                entityModelCount === 0
                                                    ? localization.empty()
                                                    : undefined
                                        }}
                                        fetchData={fetchEntities}
                                        sx={{ maxHeight: theme.spacing(58) }}>
                                        <List
                                            disablePadding={true}
                                            sx={{ paddingTop: theme.px(5) }}>
                                            {_.map(
                                                entityModels,
                                                entityModel =>
                                                    <ListItemButton
                                                        component="a"
                                                        disableGutters={true}
                                                        href={CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel)!}
                                                        key={entityModel.entity.id}
                                                        sx={{ padding: theme.spacing(1.25, 3) }}
                                                        onClick={
                                                            (event: MouseEvent) => {
                                                                setUrlRoute(CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel)!);

                                                                setSearchText(undefined);
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                            }}>
                                                        <Entity
                                                            entityIdOrModel={entityModel}
                                                            linkOptions={{ disabled: true }}
                                                            variant="iconTextTypeTenant"/>
                                                    </ListItemButton>)}
                                        </List>
                                    </InfiniteScroll>
                                </Stack>
                            </Stack>
                        </MouseClickAreaScope>
                    </Loading>
                </Popper>}
        </Fragment>);
}

type ItemProps = {
    count: number;
    entityTypeName?: string;
    onClick: Action1<Optional<string>>;
    selected: boolean;
};

export function Item({ count, entityTypeName, onClick, selected }: ItemProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.topbar.searchEntities.item",
            () => ({
                all: {
                    withNumber: "All ({{count | NumberFormatter.humanize}})",
                    withoutNumber: "All"
                },
                typeName: "{{translatedEntityTypeName}} ({{count | NumberFormatter.humanize}})"
            }));

    const theme = useTheme();
    return (
        <ListItemButton
            component="a"
            disableGutters={true}
            sx={{ padding: theme.spacing(1, 3) }}
            onClick={
                (event: MouseEvent) => {
                    onClick(entityTypeName);
                    event.preventDefault();
                    event.stopPropagation();
                }}>
            <Typography
                sx={{
                    fontWeight:
                        selected
                            ? 700
                            : 400
                }}>
                {_.isNil(entityTypeName)
                    ? localization.all.withNumber({ count })
                    : localization.typeName({
                        count,
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: 0,
                                    includeServiceName: true
                                })
                    })}
            </Typography>
        </ListItemButton>);
}