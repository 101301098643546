import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, NetworkedResourcesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpComputeVpcDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeVpcDefinition",
            () => ({
                columns: {
                    networkedResourceIds: "Attached Resources",
                    subnetRegions: "Regions",
                    subnetSubnets: "IP Address Ranges"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeVpcModel) => ({
                            [localization.columns.subnetSubnets()]: item.subnetSubnets.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeVpcSubnetSubnets)}
                                placeholder={localization.columns.subnetSubnets()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeVpcSubnetSubnets}
                key={Contract.EntityModelProperty.GcpComputeVpcSubnetSubnets}
                render={
                    optionalTableCell<Contract.GcpComputeVpcModel>(
                        (item: Contract.GcpComputeVpcModel) =>
                            !_.isEmpty(item.subnetSubnets) &&
                            <InlineItems
                                items={item.subnetSubnets}
                                variant="itemPlusItemCount"/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.subnetSubnets()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeVpcModel>(
                        Contract.TypeNames.INetworkedResource,
                        item =>
                            _(item.networkedResourceTypeNameToIdsMap).
                                values().
                                flatMap().
                                value(),
                        localization.columns.networkedResourceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeVpcNetworkResources)}
                                placeholder={localization.columns.networkedResourceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeVpcNetworkResources}
                key={Contract.EntityModelProperty.GcpComputeVpcNetworkResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeVpcModel>) =>
                        <NetworkedResourcesCell item={item.networkedResourceTypeNameToIdsMap}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.networkedResourceIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeVpcModel) => ({
                            [localization.columns.subnetRegions()]: item.subnetRegions.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeVpcSubnetRegions)}
                                placeholder={localization.columns.subnetRegions()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeVpcSubnetRegions}
                key={Contract.EntityModelProperty.GcpComputeVpcSubnetRegions}
                render={
                    optionalTableCell<Contract.GcpComputeVpcModel>(
                        (item: Contract.GcpComputeVpcModel) =>
                            !_.isEmpty(item.subnetRegions) &&
                            <InlineItems
                                items={item.subnetRegions}
                                variant="itemPlusItemCount"/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.subnetRegions()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeVpcRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeVpcRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeVpcNetworkResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeVpcSubnetRegions]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeVpcSubnetSubnets])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}