import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { CustomRiskPolicyActions } from "../../..";
import { ActionSelector, ActionSelectorSelection, EntityMultiSelect, EntityMultiSelectPermissionEvaluationEntities, EntityMultiSelectProperty, InlineActionsSelection, InlineEntityMultiSelection } from "../..";
import { EntityMatchConditionHelper } from "../../EntityMultiSelect/utilities";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const [actionsValid, setActionsValid] = useState(false);
    const [managedIdentitiesValid, setManagedIdentitiesValid] = useState(false);
    const [scopeResourcesValid, setScopeResourcesValid] = useState(false);
    const [view, setView] = useState<EditView | undefined>(EditView.ManagedIdentities);

    const [actionSelectorSelection, setActionSelectorSelection] =
        useState<ActionSelectorSelection | undefined>(
            ActionSelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.actions,
                riskPolicyConfiguration?.actionRiskCategories,
                riskPolicyConfiguration?.actionNamePatterns));
    const [managedIdentityExcludeMatchConditions, setManagedIdentityExcludeMatchConditions] = useState<Contract.EntityMatchCondition[]>(riskPolicyConfiguration?.excludeManagedIdentityMatchConditions ?? []);
    const [managedIdentityMatchConditions, setManagedIdentityMatchConditions] = useState<Contract.EntityMatchCondition[]>(riskPolicyConfiguration?.managedIdentityMatchConditions ?? []);
    const [scopeResourceExcludeMatchConditions, setScopeResourceExcludeMatchConditions] = useState<Contract.EntityMatchCondition[]>(riskPolicyConfiguration?.excludeScopeResourceMatchConditions ?? []);
    const [scopeResourceMatchConditions, setScopeResourceMatchConditions] = useState<Contract.EntityMatchCondition[]>(riskPolicyConfiguration?.scopeResourceMatchConditions ?? []);

    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { actionNamePatterns, actionRiskCategories, actions } = actionSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAzureManagedIdentityNotAllowedResourcePermissionRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        actionNamePatterns,
                        actionRiskCategories,
                        actions,
                        managedIdentityExcludeMatchConditions,
                        scopeResourceExcludeMatchConditions,
                        managedIdentityMatchConditions,
                        scopeResourceMatchConditions);
                }
        });

    useEffect(
        () => {
            const actionsValid = actionSelectorSelection?.valid() ?? false;
            const managedIdentitiesValid =
                EntityMatchConditionHelper.validateConditions(
                    managedIdentityMatchConditions,
                    managedIdentityExcludeMatchConditions);
            const scopeResourcesValid =
                EntityMatchConditionHelper.validateConditions(
                    scopeResourceMatchConditions,
                    scopeResourceExcludeMatchConditions);
            setActionsValid(actionsValid);
            setManagedIdentitiesValid(managedIdentitiesValid);
            setScopeResourcesValid(scopeResourcesValid);
            onValidChange(actionsValid && managedIdentitiesValid && scopeResourcesValid);
        },
        [actionSelectorSelection, managedIdentityExcludeMatchConditions, managedIdentityMatchConditions, scopeResourceExcludeMatchConditions, scopeResourceMatchConditions]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.azureManagedIdentityNotAllowedResourcePermissionRiskPolicy.edit",
            () => ({
                actions: "Permissions",
                prompt: "Select the required managed identities, permissions and resources"
            }));
    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        actions: <InlineActionsSelection selection={actionSelectorSelection}/>,
                        managedIdentities:
                            <InlineEntityMultiSelection
                                conditions={managedIdentityMatchConditions}
                                entityTypeName={Contract.TypeNames.AzureManagedIdentityManagedIdentity}
                                excludeConditions={managedIdentityExcludeMatchConditions}/>,
                        scopeResources:
                            <InlineEntityMultiSelection
                                conditions={scopeResourceMatchConditions}
                                entityTypeName={Contract.TypeNames.AzureScopeResource}
                                excludeConditions={scopeResourceExcludeMatchConditions}/>
                    })}
                </Typography>
                <Box>
                    <Accordion
                        expanded={view === EditView.ManagedIdentities}
                        onChange={
                            (_, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.ManagedIdentities
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AzureManagedIdentityManagedIdentity,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {managedIdentitiesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntityMultiSelect
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.SensitiveResourceAttribute,
                                    Contract.TypeNames.AdministratorPrincipalAttribute,
                                    Contract.TypeNames.BehaviorRiskIdentityAttribute,
                                    Contract.TypeNames.CodeResourceAttribute,
                                    Contract.TypeNames.DisabledIdentityAttribute,
                                    Contract.TypeNames.InactiveIdentityAttribute,
                                    Contract.TypeNames.RelatedPublicComputeAttribute,
                                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                                ]}
                                conditions={managedIdentityMatchConditions}
                                entityTypeName={Contract.TypeNames.AzureManagedIdentityManagedIdentity}
                                excludeConditions={managedIdentityExcludeMatchConditions}
                                previewFilterScope={true}
                                properties={[
                                    EntityMultiSelectProperty.All,
                                    EntityMultiSelectProperty.Attributes,
                                    EntityMultiSelectProperty.AzureScopeResourceParentEntityIds,
                                    EntityMultiSelectProperty.Ids,
                                    EntityMultiSelectProperty.NamePattern,
                                    EntityMultiSelectProperty.Properties,
                                    EntityMultiSelectProperty.Tags,
                                    EntityMultiSelectProperty.TenantIds
                                ]}
                                scopeResourceParentEntityTypeName={Contract.TypeNames.AzureContainerResource}
                                onConditionsChanged={setManagedIdentityMatchConditions}
                                onExcludeConditionsChanged={setManagedIdentityExcludeMatchConditions}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.Actions}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Actions
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {localization.actions()}
                                </Typography>
                                {actionsValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ActionSelector
                                selection={actionSelectorSelection}
                                onSelectionChanged={setActionSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.ScopeResources}
                        onChange={
                            (_, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.ScopeResources
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AzureScopeResource,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {scopeResourcesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntityMultiSelect
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.CodeResourceAttribute,
                                    Contract.TypeNames.PublicEntityAttribute,
                                    Contract.TypeNames.RelatedPublicComputeAttribute,
                                    Contract.TypeNames.SensitiveResourceAttribute
                                ]}
                                conditions={scopeResourceMatchConditions}
                                entityTypeName={Contract.TypeNames.AzureScopeResource}
                                excludeConditions={scopeResourceExcludeMatchConditions}
                                previewFilterScope={true}
                                permissionEvaluationEntities={EntityMultiSelectPermissionEvaluationEntities.Azure}
                                properties={[
                                    EntityMultiSelectProperty.All,
                                    EntityMultiSelectProperty.Attributes,
                                    EntityMultiSelectProperty.AzureScopeResourceParentEntityIds,
                                    EntityMultiSelectProperty.Ids,
                                    EntityMultiSelectProperty.NamePattern,
                                    EntityMultiSelectProperty.Properties,
                                    EntityMultiSelectProperty.Tags,
                                    EntityMultiSelectProperty.TenantIds,
                                    EntityMultiSelectProperty.TypeNames
                                ]}
                                onConditionsChanged={setScopeResourceMatchConditions}
                                onExcludeConditionsChanged={setScopeResourceExcludeMatchConditions}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}

enum EditView {
    Actions = "actions",
    ManagedIdentities = "managedIdentities",
    ScopeResources = "scopeResources"
}