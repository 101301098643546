﻿import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpPubSubSubscriptionMessageDeliveryTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpPubSubSubscriptionDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const pubSubSubscriptionMessageDeliveryTypeTranslator = useGcpPubSubSubscriptionMessageDeliveryTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpPubSubSubscriptionDefinition",
            () => ({
                columns: {
                    messageDeliveryType: "Delivery Type",
                    topicIdReference: "Topic"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpPubSubSubscriptionModel>(
                        Contract.TypeNames.GcpPubSubTopic,
                        item => [item.topicIdReference],
                        localization.columns.topicIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpPubSubSubscriptionTopics)}
                                placeholder={localization.columns.topicIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpPubSubSubscriptionTopics}
                key={Contract.EntityModelProperty.GcpPubSubSubscriptionTopics}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpPubSubSubscriptionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.topicIdReference}
                            entityTypeName={Contract.TypeNames.GcpPubSubTopic}
                            entityVariant="iconText"/>}
                title={localization.columns.topicIdReference()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpPubSubSubscriptionModel) => ({
                            [localization.columns.messageDeliveryType()]: pubSubSubscriptionMessageDeliveryTypeTranslator((item.entity as Contract.GcpPubSubSubscription).messageDeliverySettings.type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpPubSubSubscriptionMessageDeliveryType}
                                enumTypeTranslator={pubSubSubscriptionMessageDeliveryTypeTranslator}
                                placeholder={localization.columns.messageDeliveryType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpPubSubSubscriptionMessageDeliveryTypes}
                itemProperty={(item: Contract.EntityModel) => pubSubSubscriptionMessageDeliveryTypeTranslator((item.entity as Contract.GcpPubSubSubscription).messageDeliverySettings.type)}
                key={Contract.EntityModelProperty.GcpPubSubSubscriptionMessageDeliveryTypes}
                title={localization.columns.messageDeliveryType()}/>,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpPubSubSubscriptionRequest(
                new Contract.EntityControllerGetEntityModelPageGcpPubSubSubscriptionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpPubSubSubscriptionMessageDeliveryTypes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpPubSubSubscriptionTopics])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}