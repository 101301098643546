import { InlineItems, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { ConfigurationController, Contract, Entity, GeographyHelper, InlineEntities, Scope, scopeNodeModelStore, tenantModelStore } from "../../../../../../../../common";
import { AwsTenantHelper } from "../../../../../../../../tenants";
import { ProfileDefinition } from "../../useProfileDefinition";
import { useGetCloudProviderTenantProfilePermissionsSection } from "../useGetCloudProviderTenantProfilePermissionsSection";
import { StatusCell, TrailStatusCell } from "./components";

export function useAwsProfileDefinition(scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap([Contract.TenantType.Aws]);
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();
    const [{ partitionTypeToRegionSystemNamesMap }] =
        useExecuteOperation(
            useAwsProfileDefinition,
            ConfigurationController.getAwsRegions);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.useAwsProfileDefinition",
            () => ({
                managementAccountId: "Organization Management Account",
                organizationId: "Organization ID",
                partition: {
                    title: "Partition"
                },
                permissions: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    terraformStateBucket: "Terraform State (S3)",
                    trailBucket: "CloudTrail (S3)"
                },
                rawId: "ID",
                regions: {
                    title: "Active Regions"
                },
                roleName: "Role Name",
                status: "Status",
                trails: {
                    lambdaFunctionConfigurationInvoke: "Lambda Data CloudTrail",
                    management: "Management CloudTrail",
                    s3Object: "S3 Data CloudTrail",
                    status: "Status",
                    title: "CloudTrail"
                },
                trailStatus: "CloudTrail Status"
            }));

    const getTenantProfilePermissionsSection = useGetCloudProviderTenantProfilePermissionsSection();

    return useMemo(
        () => {
            const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
            const trailEventCategoryToTrailMap = AwsTenantHelper.getTrailEventCategoryToTrailMap(tenantModel.configuration.trails);

            const lambdaFunctionConfigurationInvokeWriteTrailId = trailEventCategoryToTrailMap[Contract.AwsTenantEventCategory.LambdaFunctionConfigurationInvokeWrite]?.id;
            const managementReadTrailId = trailEventCategoryToTrailMap[Contract.AwsTenantEventCategory.ManagementRead]?.id;
            const managementWriteTrailId = trailEventCategoryToTrailMap[Contract.AwsTenantEventCategory.ManagementWrite]?.id;
            const s3ObjectReadTrailId = trailEventCategoryToTrailMap[Contract.AwsTenantEventCategory.S3ObjectRead]?.id;
            const s3ObjectWriteTrailId = trailEventCategoryToTrailMap[Contract.AwsTenantEventCategory.S3ObjectWrite]?.id;

            const partitionType = tenantModel?.configuration.partitionType ?? GeographyHelper.deploymentAwsPartitionType;
            const partitionRegionSystemNames = partitionTypeToRegionSystemNamesMap[partitionType!];

            const defaultPermissionsSection = getTenantProfilePermissionsSection(tenantModel);
            const managementAccountId = tenantModel.state.organization?.masterTenantRawId;
            return {
                sections: [
                    {
                        items: [
                            {
                                title: localization.rawId(),
                                value: tenantModel.configuration.rawId
                            },
                            {
                                title: localization.organizationId(),
                                value: tenantModel.state.organization?.rawId
                            },
                            {
                                title: localization.managementAccountId(),
                                value:
                                    _.isNil(managementAccountId) || _.isNil(scopeNodeMap[managementAccountId])
                                        ? undefined
                                        : <Scope
                                            scopeId={managementAccountId!}
                                            variant={
                                                managementAccountId === tenantModel.configuration.rawId
                                                    ? "text"
                                                    : "link"}/>
                            },
                            {
                                title: localization.partition.title(),
                                value: partitionType
                            },
                            {
                                title: localization.roleName(),
                                value: tenantModel.roleName
                            },
                            {
                                title: localization.status(),
                                value: <StatusCell item={tenantModel}/>
                            },
                            {
                                title: localization.regions.title(),
                                value:
                                    <InlineItems
                                        items={tenantModel?.configuration.regionSystemNames ?? partitionRegionSystemNames}
                                        variant="itemPlusItemCount"/>
                            }
                        ]
                    },
                    {
                        items: [
                            {
                                title: localization.trails.status(),
                                value: <TrailStatusCell item={tenantModel}/>
                            },
                            {
                                title: localization.trails.management(),
                                value:
                                    _.isNil(managementReadTrailId) && _.isNil(managementWriteTrailId)
                                        ? undefined
                                        : <InlineEntities
                                            entityIdsOrModels={
                                                _([managementReadTrailId, managementWriteTrailId]).
                                                    filter().
                                                    uniq().
                                                    value()}
                                            entityTypeName={Contract.TypeNames.AwsTenantConfigurationTrail}
                                            variant="itemOrItemCountAndType"/>
                            },
                            {
                                title: localization.trails.s3Object(),
                                value:
                                    _.isNil(s3ObjectReadTrailId) && _.isNil(s3ObjectWriteTrailId)
                                        ? undefined
                                        : <InlineEntities
                                            entityIdsOrModels={
                                                _([s3ObjectReadTrailId, s3ObjectWriteTrailId]).
                                                    filter().
                                                    uniq().
                                                    value()}
                                            entityTypeName={Contract.TypeNames.AwsTenantConfigurationTrail}
                                            variant="itemOrItemCountAndType"/>
                            },
                            {
                                title: localization.trails.lambdaFunctionConfigurationInvoke(),
                                value:
                                    _.isNil(lambdaFunctionConfigurationInvokeWriteTrailId)
                                        ? undefined
                                        : <Entity
                                            entityIdOrModel={lambdaFunctionConfigurationInvokeWriteTrailId}
                                            variant="text"/>
                            }
                        ],
                        title: "CloudTrail"
                    },
                    {
                        ...defaultPermissionsSection,
                        items: [
                            ...defaultPermissionsSection.items,
                            {
                                title: localization.permissions.trailBucket(),
                                value:
                                    !_.isNil(tenantModel?.configuration.trailBucket) ||
                                        !_.isEmpty(tenantModel?.state.eventAnalysis.trailBucketNameToEncryptionKeyArnsMap)
                                        ? localization.permissions.enabled()
                                        : localization.permissions.disabled()
                            },
                            {
                                title: localization.permissions.terraformStateBucket(),
                                value:
                                    !_.isEmpty(tenantModel?.state.codeAnalysis.terraformStateBucketNameToEncryptionKeyArnsMap)
                                        ? localization.permissions.enabled()
                                        : localization.permissions.disabled()
                            }
                        ]
                    }]
            };
        },
        [localization, tenantModelMap, partitionTypeToRegionSystemNamesMap, getTenantProfilePermissionsSection]);
}