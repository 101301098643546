import { useLocalization } from "@infrastructure";
import { Contract } from "../../..";

export function useAzureResourcesGenericResourceTypeNameLocalization() {
    return useLocalization(
        "common.hooks.useEntityTypeNameTranslator.hooks.useAzureResourcesGenericResourceTypeNameLocalization",
         () => ({
             typeNames: {
                    [Contract.TypeNames.AzureAadDomainServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "domain service",
                                "domain services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Domain Service",
                                "Domain Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAadiamAzureAdMetricGenericResource]: {
                         text: {
                             withServiceName: [
                                "ad metrics definition",
                                "ad metrics definitions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "AD Metrics Definition",
                                "AD Metrics Definitions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAadiamPrivateLinkForAzureAdGenericResource]: {
                         text: {
                             withServiceName: [
                                "private link policy definition",
                                "private link policy definitions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Link Policy Definition",
                                "Private Link Policy Definitions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAadiamTenantGenericResource]: {
                         text: {
                             withServiceName: [
                                "tenant",
                                "tenants"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Tenant",
                                "Tenants"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureActiveDirectoryB2cDirectoryGenericResource]: {
                         text: {
                             withServiceName: [
                                "b2c directory",
                                "b2c directories"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "B2C Directory",
                                "B2C Directories"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureActiveDirectoryCiamDirectoryGenericResource]: {
                         text: {
                             withServiceName: [
                                "ciam directory",
                                "ciam directories"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "CIAM Directory",
                                "CIAM Directories"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureActiveDirectoryGuestUsageGenericResource]: {
                         text: {
                             withServiceName: [
                                "guest usage",
                                "guest usages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Guest Usage",
                                "Guest Usages"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAgFoodPlatformFarmBeatGenericResource]: {
                         text: {
                             withServiceName: [
                                "ag food platform farm beat",
                                "ag food platform farm beats"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ag Food Platform Farm Beat",
                                "Ag Food Platform Farm Beats"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAgriculturePlatformAgriServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "agri service",
                                "agri services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Agri Service",
                                "Agri Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAlertsManagementActionRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "action rule",
                                "action rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Action Rule",
                                "Action Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAlertsManagementPrometheusRuleGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "prometheus rule group",
                                "prometheus rule groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Prometheus Rule Group",
                                "Prometheus Rule Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAlertsManagementSmartDetectorAlertRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "smart detector alert rule",
                                "smart detector alert rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Smart Detector Alert Rule",
                                "Smart Detector Alert Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAnalysisServicesServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "server",
                                "servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Server",
                                "Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAnyBuildClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster",
                                "clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster",
                                "Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureApiCenterServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "service",
                                "services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service",
                                "Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureApiManagementGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "gateway",
                                "gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gateway",
                                "Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureApiManagementServiceResourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "api management service",
                                "api management services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "API Management Service",
                                "API Management Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppAssessmentMigrateProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "migrate project",
                                "migrate projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migrate Project",
                                "Migrate Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppBuilderGenericResource]: {
                         text: {
                             withServiceName: [
                                "builder",
                                "builders"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Builder",
                                "Builders"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppConfigurationConfigurationStoreGenericResource]: {
                         text: {
                             withServiceName: [
                                "app configuration",
                                "app configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "App Configuration",
                                "App Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppConnectedEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "connected environment",
                                "connected environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connected Environment",
                                "Connected Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppConnectedenvironmentsCertificateGenericResource]: {
                         text: {
                             withServiceName: [
                                "certificate",
                                "certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Certificate",
                                "Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppContainerAppGenericResource]: {
                         text: {
                             withServiceName: [
                                "container app",
                                "container apps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container App",
                                "Container Apps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "container apps job",
                                "container apps jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container Apps Job",
                                "Container Apps Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppManagedEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed environment",
                                "managed environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed Environment",
                                "Managed Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppManagedEnvironmentsCertificateGenericResource]: {
                         text: {
                             withServiceName: [
                                "certificate",
                                "certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Certificate",
                                "Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppManagedenvironmentsManagedcertificateGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed certificate",
                                "managed certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed Certificate",
                                "Managed Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppPlatformSpringGenericResource]: {
                         text: {
                             withServiceName: [
                                "spring apps service instance",
                                "spring apps service instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Spring Apps Service Instance",
                                "Spring Apps Service Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppSecurityPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "policy",
                                "policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Policy",
                                "Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppSessionPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "session pool",
                                "session pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Session Pool",
                                "Session Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAppSpaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "space",
                                "spaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Space",
                                "Spaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureArcDataDataControllerGenericResource]: {
                         text: {
                             withServiceName: [
                                "data controller",
                                "data controllers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Controller",
                                "Data Controllers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureArcDataPostgresInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "postgres instance",
                                "postgres instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Postgres Instance",
                                "Postgres Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureArcDataSqlManagedInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql managed instance",
                                "sql managed instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sql Managed Instance",
                                "Sql Managed Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureArcDataSqlServerEsuLicenseGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql server esu license",
                                "sql server esu licenses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sql Server Esu License",
                                "Sql Server Esu Licenses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureArcDataSqlServerInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql server instance",
                                "sql server instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sql Server Instance",
                                "Sql Server Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureArcDataSqlServerInstancesAvailabilityGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql server instances availability group",
                                "sql server instances availability groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sql Server Instances Availability Group",
                                "Sql Server Instances Availability Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureArcDataSqlServerInstancesDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql server instances database",
                                "sql server instances databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sql Server Instances Database",
                                "Sql Server Instances Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureArcDataSqlServerLicenseGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql server license",
                                "sql server licenses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sql Server License",
                                "Sql Server Licenses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAstronomerAstroOrganizationGenericResource]: {
                         text: {
                             withServiceName: [
                                "organization",
                                "organizations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Organization",
                                "Organizations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAttestationAttestationProviderGenericResource]: {
                         text: {
                             withServiceName: [
                                "attestation",
                                "attestations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Attestation",
                                "Attestations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAuthorizationResourceManagementPrivateLinkGenericResource]: {
                         text: {
                             withServiceName: [
                                "resource management private link",
                                "resource management private links"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Resource Management Private Link",
                                "Resource Management Private Links"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAutomanageConfigurationProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "automanage configuration profile",
                                "automanage configuration profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Automanage Configuration Profile",
                                "Automanage Configuration Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAutomanageConfigurationProfilesVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "automanage configuration profiles version",
                                "automanage configuration profiles versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Automanage Configuration Profiles Version",
                                "Automanage Configuration Profiles Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAutomanagePatchJobConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "patch job configuration",
                                "patch job configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Patch Job Configuration",
                                "Patch Job Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAutomanagePatchTierGenericResource]: {
                         text: {
                             withServiceName: [
                                "patch tier",
                                "patch tiers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Patch Tier",
                                "Patch Tiers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAutomationAutomationAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "automation account",
                                "automation accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Automation Account",
                                "Automation Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAutomationAutomationAccountsConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "automation desired state configuration",
                                "automation desired state configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Automation Desired State Configuration",
                                "Automation Desired State Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAutomationAutomationAccountsRunbookGenericResource]: {
                         text: {
                             withServiceName: [
                                "automation runbook",
                                "automation runbooks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Automation Runbook",
                                "Automation Runbooks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAvsPrivateCloudGenericResource]: {
                         text: {
                             withServiceName: [
                                "private cloud",
                                "private clouds"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Cloud",
                                "Private Clouds"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorAccessAnalyzerAnalyzerGenericResource]: {
                         text: {
                             withServiceName: [
                                "access analyzer analyzer",
                                "access analyzer analyzers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Access Analyzer Analyzer",
                                "Access Analyzer Analyzers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorAcmCertificateSummaryGenericResource]: {
                         text: {
                             withServiceName: [
                                "acm certificate summary",
                                "acm certificate summaries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Acm Certificate Summary",
                                "Acm Certificate Summaries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorApiGatewayRestApiGenericResource]: {
                         text: {
                             withServiceName: [
                                "api gateway rest api",
                                "api gateway rest apis"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Gateway Rest Api",
                                "Api Gateway Rest Apis"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorApiGatewayStageGenericResource]: {
                         text: {
                             withServiceName: [
                                "api gateway stage",
                                "api gateway stages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Gateway Stage",
                                "Api Gateway Stages"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorAppSyncGraphqlApiGenericResource]: {
                         text: {
                             withServiceName: [
                                "app sync graphql api",
                                "app sync graphql apis"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "App Sync Graphql Api",
                                "App Sync Graphql Apis"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorAutoScalingAutoScalingGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "auto scaling auto scaling group",
                                "auto scaling auto scaling groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Auto Scaling Auto Scaling Group",
                                "Auto Scaling Auto Scaling Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorCloudFormationStackGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud formation stack",
                                "cloud formation stacks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Formation Stack",
                                "Cloud Formation Stacks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorCloudFormationStackSetGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud formation stack set",
                                "cloud formation stack sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Formation Stack Set",
                                "Cloud Formation Stack Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorCloudFrontDistributionGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud front distribution",
                                "cloud front distributions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Front Distribution",
                                "Cloud Front Distributions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorCloudTrailTrailGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud trail trail",
                                "cloud trail trails"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Trail Trail",
                                "Cloud Trail Trails"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorCloudWatchAlarmGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud watch alarm",
                                "cloud watch alarms"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Watch Alarm",
                                "Cloud Watch Alarms"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorCodeBuildProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "code build project",
                                "code build projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Code Build Project",
                                "Code Build Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorCodeBuildSourceCredentialsInfoGenericResource]: {
                         text: {
                             withServiceName: [
                                "code build source credentials info",
                                "code build source credentials infos"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Code Build Source Credentials Info",
                                "Code Build Source Credentials Infos"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorConfigServiceConfigurationRecorderGenericResource]: {
                         text: {
                             withServiceName: [
                                "config service configuration recorder",
                                "config service configuration recorders"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Config Service Configuration Recorder",
                                "Config Service Configuration Recorders"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorConfigServiceConfigurationRecorderStatusGenericResource]: {
                         text: {
                             withServiceName: [
                                "config service configuration recorder status",
                                "config service configuration recorder statuses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Config Service Configuration Recorder Status",
                                "Config Service Configuration Recorder Statuses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorConfigServiceDeliveryChannelGenericResource]: {
                         text: {
                             withServiceName: [
                                "config service delivery channel",
                                "config service delivery channels"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Config Service Delivery Channel",
                                "Config Service Delivery Channels"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorDatabaseMigrationServiceReplicationInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "database migration service replication instance",
                                "database migration service replication instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database Migration Service Replication Instance",
                                "Database Migration Service Replication Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorDaxClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "dax cluster",
                                "dax clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dax Cluster",
                                "Dax Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorDynamoDbContinuousBackupsDescriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "dynamo db continuous backups description",
                                "dynamo db continuous backups descriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dynamo DB Continuous Backups Description",
                                "Dynamo DB Continuous Backups Descriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorDynamoDbTableGenericResource]: {
                         text: {
                             withServiceName: [
                                "dynamo db table",
                                "dynamo db tables"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dynamo DB Table",
                                "Dynamo DB Tables"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2AccountAttributeGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 account attribute",
                                "ec 2 account attributes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Account Attribute",
                                "Ec 2 Account Attributes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2AddressGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 address",
                                "ec 2 addresses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Address",
                                "Ec 2 Addresses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2FlowLogGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 flow log",
                                "ec 2 flow logs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Flow Log",
                                "Ec 2 Flow Logs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2ImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 image",
                                "ec 2 images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Image",
                                "Ec 2 Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2InstanceStatusGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 instance status",
                                "ec 2 instance statuses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Instance Status",
                                "Ec 2 Instance Statuses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2IpamGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 ipam",
                                "ec 2 ipams"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Ipam",
                                "Ec 2 Ipams"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2KeyPairGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 key pair",
                                "ec 2 key pairs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Key Pair",
                                "Ec 2 Key Pairs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2NetworkAclGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 network acl",
                                "ec 2 network acls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Network Acl",
                                "Ec 2 Network Acls"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2NetworkInterfaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 network interface",
                                "ec 2 network interfaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Network Interface",
                                "Ec 2 Network Interfaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2RouteTableGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 route table",
                                "ec 2 route tables"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Route Table",
                                "Ec 2 Route Tables"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2SecurityGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 security group",
                                "ec 2 security groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Security Group",
                                "Ec 2 Security Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2SnapshotGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 snapshot",
                                "ec 2 snapshots"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Snapshot",
                                "Ec 2 Snapshots"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2SubnetGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 subnet",
                                "ec 2 subnets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Subnet",
                                "Ec 2 Subnets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2VolumeGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 volume",
                                "ec 2 volumes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Volume",
                                "Ec 2 Volumes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2VpcEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 vpc endpoint",
                                "ec 2 vpc endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 VPC Endpoint",
                                "Ec 2 VPC Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2VpcGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 vpc",
                                "ec 2 vpcs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 Vpc",
                                "Ec 2 Vpcs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEc2VpcPeeringConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "ec 2 vpc peering connection",
                                "ec 2 vpc peering connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ec 2 VPC Peering Connection",
                                "Ec 2 VPC Peering Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEcrImageDetailGenericResource]: {
                         text: {
                             withServiceName: [
                                "ecr image detail",
                                "ecr image details"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ecr Image Detail",
                                "Ecr Image Details"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEcrRepositoryGenericResource]: {
                         text: {
                             withServiceName: [
                                "ecr repository",
                                "ecr repositories"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ecr Repository",
                                "Ecr Repositories"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEcsClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "ecs cluster",
                                "ecs clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ecs Cluster",
                                "Ecs Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEcsServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "ecs service",
                                "ecs services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ecs Service",
                                "Ecs Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEcsTaskDefinitionGenericResource]: {
                         text: {
                             withServiceName: [
                                "ecs task definition",
                                "ecs task definitions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ecs Task Definition",
                                "Ecs Task Definitions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEfsFileSystemGenericResource]: {
                         text: {
                             withServiceName: [
                                "efs file system",
                                "efs file systems"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Efs File System",
                                "Efs File Systems"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEfsMountTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "efs mount target",
                                "efs mount targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Efs Mount Target",
                                "Efs Mount Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEksNodegroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "eks nodegroup",
                                "eks nodegroups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Eks Nodegroup",
                                "Eks Nodegroups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorElasticBeanstalkApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "elastic beanstalk application",
                                "elastic beanstalk applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Elastic Beanstalk Application",
                                "Elastic Beanstalk Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorElasticBeanstalkConfigurationTemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "elastic beanstalk configuration template",
                                "elastic beanstalk configuration templates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Elastic Beanstalk Configuration Template",
                                "Elastic Beanstalk Configuration Templates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorElasticBeanstalkEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "elastic beanstalk environment",
                                "elastic beanstalk environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Elastic Beanstalk Environment",
                                "Elastic Beanstalk Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorElasticLoadBalancingV2ListenerGenericResource]: {
                         text: {
                             withServiceName: [
                                "elastic load balancing v 2 listener",
                                "elastic load balancing v 2 listeners"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Elastic Load Balancing V 2 Listener",
                                "Elastic Load Balancing V 2 Listeners"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorElasticLoadBalancingV2LoadBalancerGenericResource]: {
                         text: {
                             withServiceName: [
                                "elastic load balancing v 2 load balancer",
                                "elastic load balancing v 2 load balancers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Elastic Load Balancing V 2 Load Balancer",
                                "Elastic Load Balancing V 2 Load Balancers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorElasticLoadBalancingV2TargetGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "elastic load balancing v 2 target group",
                                "elastic load balancing v 2 target groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Elastic Load Balancing V 2 Target Group",
                                "Elastic Load Balancing V 2 Target Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorElasticLoadBalancingV2TargetHealthDescriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "elastic load balancing v 2 target health description",
                                "elastic load balancing v 2 target health descriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Elastic Load Balancing V 2 Target Health Description",
                                "Elastic Load Balancing V 2 Target Health Descriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorEmrClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "emr cluster",
                                "emr clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Emr Cluster",
                                "Emr Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorGuardDutyDetectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "guard duty detector",
                                "guard duty detectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Guard Duty Detector",
                                "Guard Duty Detectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamAccessKeyLastUsedGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam access key last used",
                                "iam access key last useds"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Access Key Last Used",
                                "Iam Access Key Last Useds"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamAccessKeyMetadatumGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam access key metadatum",
                                "iam access key metadata"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Access Key Metadatum",
                                "Iam Access Key Metadata"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam group",
                                "iam groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Group",
                                "Iam Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamInstanceProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam instance profile",
                                "iam instance profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Instance Profile",
                                "Iam Instance Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamMfaDeviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam mfa device",
                                "iam mfa devices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam MFA Device",
                                "Iam MFA Devices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamPasswordPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam password policy",
                                "iam password policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Password Policy",
                                "Iam Password Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamPolicyVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam policy version",
                                "iam policy versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Policy Version",
                                "Iam Policy Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamRoleGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam role",
                                "iam roles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Role",
                                "Iam Roles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamServerCertificateGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam server certificate",
                                "iam server certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Server Certificate",
                                "Iam Server Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorIamVirtualMfaDeviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "iam virtual mfa device",
                                "iam virtual mfa devices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Iam Virtual MFA Device",
                                "Iam Virtual MFA Devices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorKmsAliasGenericResource]: {
                         text: {
                             withServiceName: [
                                "kms alias",
                                "kms aliases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kms Alias",
                                "Kms Aliases"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorKmsKeyGenericResource]: {
                         text: {
                             withServiceName: [
                                "kms key",
                                "kms keys"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kms Key",
                                "Kms Keys"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLambdaFunctionCodeLocationGenericResource]: {
                         text: {
                             withServiceName: [
                                "lambda function code location",
                                "lambda function code locations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Lambda Function Code Location",
                                "Lambda Function Code Locations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLambdaFunctionConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "lambda function configuration",
                                "lambda function configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Lambda Function Configuration",
                                "Lambda Function Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLambdaFunctionGenericResource]: {
                         text: {
                             withServiceName: [
                                "lambda function",
                                "lambda functions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Lambda Function",
                                "Lambda Functions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLightsailBucketGenericResource]: {
                         text: {
                             withServiceName: [
                                "lightsail bucket",
                                "lightsail buckets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Lightsail Bucket",
                                "Lightsail Buckets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLightsailInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "lightsail instance",
                                "lightsail instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Lightsail Instance",
                                "Lightsail Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLogsLogGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "logs log group",
                                "logs log groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Logs Log Group",
                                "Logs Log Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLogsLogStreamGenericResource]: {
                         text: {
                             withServiceName: [
                                "logs log stream",
                                "logs log streams"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Logs Log Stream",
                                "Logs Log Streams"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLogsMetricFilterGenericResource]: {
                         text: {
                             withServiceName: [
                                "logs metric filter",
                                "logs metric filters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Logs Metric Filter",
                                "Logs Metric Filters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorLogsSubscriptionFilterGenericResource]: {
                         text: {
                             withServiceName: [
                                "logs subscription filter",
                                "logs subscription filters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Logs Subscription Filter",
                                "Logs Subscription Filters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorMacie2JobSummaryGenericResource]: {
                         text: {
                             withServiceName: [
                                "macie 2 job summary",
                                "macie 2 job summaries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Macie 2 Job Summary",
                                "Macie 2 Job Summaries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorMacieAllowListGenericResource]: {
                         text: {
                             withServiceName: [
                                "macie allow list",
                                "macie allow lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Macie Allow List",
                                "Macie Allow Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorNetworkFirewallFirewallGenericResource]: {
                         text: {
                             withServiceName: [
                                "network firewall firewall",
                                "network firewall firewalls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Firewall Firewall",
                                "Network Firewall Firewalls"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorNetworkFirewallFirewallPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "network firewall firewall policy",
                                "network firewall firewall policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Firewall Firewall Policy",
                                "Network Firewall Firewall Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorNetworkFirewallRuleGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "network firewall rule group",
                                "network firewall rule groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Firewall Rule Group",
                                "Network Firewall Rule Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorOpenSearchDomainStatusGenericResource]: {
                         text: {
                             withServiceName: [
                                "open search domain status",
                                "open search domain statuses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Open Search Domain Status",
                                "Open Search Domain Statuses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorOrganizationsAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "organizations account",
                                "organizations accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Organizations Account",
                                "Organizations Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorOrganizationsOrganizationGenericResource]: {
                         text: {
                             withServiceName: [
                                "organizations organization",
                                "organizations organizations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Organizations Organization",
                                "Organizations Organizations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRdsDbClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "rds db cluster",
                                "rds db clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rds DB Cluster",
                                "Rds DB Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRdsDbInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "rds db instance",
                                "rds db instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rds DB Instance",
                                "Rds DB Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRdsDbSnapshotAttributesResultGenericResource]: {
                         text: {
                             withServiceName: [
                                "rds db snapshot attributes result",
                                "rds db snapshot attributes results"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rds DB Snapshot Attributes Result",
                                "Rds DB Snapshot Attributes Results"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRdsDbSnapshotGenericResource]: {
                         text: {
                             withServiceName: [
                                "rds db snapshot",
                                "rds db snapshots"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rds DB Snapshot",
                                "Rds DB Snapshots"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRdsEventSubscriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "rds event subscription",
                                "rds event subscriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rds Event Subscription",
                                "Rds Event Subscriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRdsExportTaskGenericResource]: {
                         text: {
                             withServiceName: [
                                "rds export task",
                                "rds export tasks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rds Export Task",
                                "Rds Export Tasks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRedshiftClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "redshift cluster",
                                "redshift clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Redshift Cluster",
                                "Redshift Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRedshiftClusterParameterGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "redshift cluster parameter group",
                                "redshift cluster parameter groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Redshift Cluster Parameter Group",
                                "Redshift Cluster Parameter Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRoute53DomainsDomainSummaryGenericResource]: {
                         text: {
                             withServiceName: [
                                "route 53 domains domain summary",
                                "route 53 domains domain summaries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Route 53 Domains Domain Summary",
                                "Route 53 Domains Domain Summaries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRoute53HostedZoneGenericResource]: {
                         text: {
                             withServiceName: [
                                "route 53 hosted zone",
                                "route 53 hosted zones"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Route 53 Hosted Zone",
                                "Route 53 Hosted Zones"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorRoute53ResourceRecordSetGenericResource]: {
                         text: {
                             withServiceName: [
                                "route 53 resource record set",
                                "route 53 resource record sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Route 53 Resource Record Set",
                                "Route 53 Resource Record Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorS3AccessControlPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "s 3 access control policy",
                                "s 3 access control policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "S 3 Access Control Policy",
                                "S 3 Access Control Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorS3AccessPointGenericResource]: {
                         text: {
                             withServiceName: [
                                "s 3 access point",
                                "s 3 access points"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "S 3 Access Point",
                                "S 3 Access Points"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorS3BucketGenericResource]: {
                         text: {
                             withServiceName: [
                                "s 3 bucket",
                                "s 3 buckets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "S 3 Bucket",
                                "S 3 Buckets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorS3BucketPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "s 3 bucket policy",
                                "s 3 bucket policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "S 3 Bucket Policy",
                                "S 3 Bucket Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSageMakerAppGenericResource]: {
                         text: {
                             withServiceName: [
                                "sage maker app",
                                "sage maker apps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sage Maker App",
                                "Sage Maker Apps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSageMakerNotebookInstanceSummaryGenericResource]: {
                         text: {
                             withServiceName: [
                                "sage maker notebook instance summary",
                                "sage maker notebook instance summaries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sage Maker Notebook Instance Summary",
                                "Sage Maker Notebook Instance Summaries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSecretsManagerResourcePolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "secrets manager resource policy",
                                "secrets manager resource policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Secrets Manager Resource Policy",
                                "Secrets Manager Resource Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSecretsManagerSecretGenericResource]: {
                         text: {
                             withServiceName: [
                                "secrets manager secret",
                                "secrets manager secrets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Secrets Manager Secret",
                                "Secrets Manager Secrets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSnsSubscriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "sns subscription",
                                "sns subscriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sns Subscription",
                                "Sns Subscriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSnsTopicGenericResource]: {
                         text: {
                             withServiceName: [
                                "sns topic",
                                "sns topics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sns Topic",
                                "Sns Topics"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSqsQueueGenericResource]: {
                         text: {
                             withServiceName: [
                                "sqs queue",
                                "sqs queues"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sqs Queue",
                                "Sqs Queues"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSsmInstanceInformationGenericResource]: {
                         text: {
                             withServiceName: [
                                "ssm instance information",
                                "ssm instance informations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ssm Instance Information",
                                "Ssm Instance Informations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSsmParameterGenericResource]: {
                         text: {
                             withServiceName: [
                                "ssm parameter",
                                "ssm parameters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ssm Parameter",
                                "Ssm Parameters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorSsmResourceComplianceSummaryItemGenericResource]: {
                         text: {
                             withServiceName: [
                                "ssm resource compliance summary item",
                                "ssm resource compliance summary items"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ssm Resource Compliance Summary Item",
                                "Ssm Resource Compliance Summary Items"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureAwsConnectorWafv2LoggingConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "wafv 2 logging configuration",
                                "wafv 2 logging configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Wafv 2 Logging Configuration",
                                "Wafv 2 Logging Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBackupSolutionsVMwareApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "application",
                                "applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application",
                                "Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBareMetalBareMetalConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "bare metal connection",
                                "bare metal connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bare Metal Connection",
                                "Bare Metal Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBareMetalConsoleConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "console connection",
                                "console connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Console Connection",
                                "Console Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBareMetalCrayServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "cray server",
                                "cray servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cray Server",
                                "Cray Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBareMetalInfrastructureBareMetalInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "bare metal instance",
                                "bare metal instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bare Metal Instance",
                                "Bare Metal Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBareMetalInfrastructureBareMetalStorageInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "bare metal storage instance",
                                "bare metal storage instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bare Metal Storage Instance",
                                "Bare Metal Storage Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBareMetalMonitoringServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitoring server",
                                "monitoring servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitoring Server",
                                "Monitoring Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBatchBatchAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "batch account",
                                "batch accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Batch Account",
                                "Batch Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBillingBenefitsCreditGenericResource]: {
                         text: {
                             withServiceName: [
                                "credit",
                                "credits"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Credit",
                                "Credits"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBillingBenefitsIncentiveScheduleGenericResource]: {
                         text: {
                             withServiceName: [
                                "incentive schedule",
                                "incentive schedules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Incentive Schedule",
                                "Incentive Schedules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBillingBenefitsMaccGenericResource]: {
                         text: {
                             withServiceName: [
                                "macc",
                                "maccs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "MACC",
                                "MACCs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBingAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "bing search account",
                                "bing search accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bing Search Account",
                                "Bing Search Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBluefinInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance",
                                "instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance",
                                "Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBluefinInstancesDatasetGenericResource]: {
                         text: {
                             withServiceName: [
                                "instances dataset",
                                "instances datasets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instances Dataset",
                                "Instances Datasets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBluefinInstancesPipelineGenericResource]: {
                         text: {
                             withServiceName: [
                                "instances pipeline",
                                "instances pipelines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instances Pipeline",
                                "Instances Pipelines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureBotServiceBotServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "bot service",
                                "bot services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bot Service",
                                "Bot Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCacheRedisEnterpriseGenericResource]: {
                         text: {
                             withServiceName: [
                                "redis enterprise cache",
                                "redis enterprise caches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Redis Enterprise Cache",
                                "Redis Enterprise Caches"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCacheRedisGenericResource]: {
                         text: {
                             withServiceName: [
                                "redis cache",
                                "redis caches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Redis Cache",
                                "Redis Caches"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCdnCdnwebapplicationfirewallpolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "web application firewall policy",
                                "web application firewall policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web Application Firewall Policy",
                                "Web Application Firewall Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCdnEdgeActionGenericResource]: {
                         text: {
                             withServiceName: [
                                "edge action",
                                "edge actions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edge Action",
                                "Edge Actions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCdnEdgeActionsAttachmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "edge actions attachment",
                                "edge actions attachments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edge Actions Attachment",
                                "Edge Actions Attachments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCdnEdgeActionsExecutionFilterGenericResource]: {
                         text: {
                             withServiceName: [
                                "edge actions execution filter",
                                "edge actions execution filters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edge Actions Execution Filter",
                                "Edge Actions Execution Filters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCdnEdgeActionsVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "edge actions version",
                                "edge actions versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edge Actions Version",
                                "Edge Actions Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCdnProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "cdn profile",
                                "cdn profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "CDN Profile",
                                "CDN Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCdnProfilesAfdendpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "afd endpoint",
                                "afd endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Afd Endpoint",
                                "Afd Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCdnProfilesEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "endpoint",
                                "endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Endpoint",
                                "Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCertificateRegistrationCertificateOrderGenericResource]: {
                         text: {
                             withServiceName: [
                                "app service certificate",
                                "app service certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "App Service Certificate",
                                "App Service Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureChaosApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "application",
                                "applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application",
                                "Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureChaosExperimentGenericResource]: {
                         text: {
                             withServiceName: [
                                "chaos experiment",
                                "chaos experiments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Chaos Experiment",
                                "Chaos Experiments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureChaosPrivateAccessGenericResource]: {
                         text: {
                             withServiceName: [
                                "private access",
                                "private accesses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Access",
                                "Private Accesses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureChaosResilienceProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "resilience profile",
                                "resilience profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Resilience Profile",
                                "Resilience Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureClassicComputeDomainNameGenericResource]: {
                         text: {
                             withServiceName: [
                                "domain name (classic)",
                                "domain names (classic)"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Domain Name (classic)",
                                "Domain Names (classic)"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureClassicComputeVirtualMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "classic virtual machine",
                                "classic virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Classic Virtual Machine",
                                "Classic Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureClassicNetworkNetworkSecurityGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "classic network security group",
                                "classic network security groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Classic Network Security Group",
                                "Classic Network Security Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureClassicNetworkReservedIpGenericResource]: {
                         text: {
                             withServiceName: [
                                "classic reserved ip address",
                                "classic reserved ip addresses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Classic Reserved IP Address",
                                "Classic Reserved IP Addresses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureClassicNetworkVirtualNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "classic virtual network",
                                "classic virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Classic Virtual Network",
                                "Classic Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureClassicStorageStorageAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "classic storage account",
                                "classic storage accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Classic Storage Account",
                                "Classic Storage Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCleanRoomCleanroomGenericResource]: {
                         text: {
                             withServiceName: [
                                "cleanroom",
                                "cleanrooms"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cleanroom",
                                "Cleanrooms"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCleanRoomMicroserviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "microservice",
                                "microservices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Microservice",
                                "Microservices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCloudDevicePlatformDelegatedIdentityGenericResource]: {
                         text: {
                             withServiceName: [
                                "delegated identity",
                                "delegated identities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Delegated Identity",
                                "Delegated Identities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCloudHealthHealthmodelGenericResource]: {
                         text: {
                             withServiceName: [
                                "healthmodel",
                                "healthmodels"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Healthmodel",
                                "Healthmodels"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCloudTestAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCloudTestBuildcacheGenericResource]: {
                         text: {
                             withServiceName: [
                                "buildcache",
                                "buildcaches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Buildcache",
                                "Buildcaches"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCloudTestHostedpoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "hostedpool",
                                "hostedpools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Hostedpool",
                                "Hostedpools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCloudTestImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "image",
                                "images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Image",
                                "Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCloudTestPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "pool",
                                "pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Pool",
                                "Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCloudesAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCodeSigningCodeSigningAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "code signing account",
                                "code signing accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Code Signing Account",
                                "Code Signing Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCognitiveServicesAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "cognitive services api account",
                                "cognitive services api accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cognitive Services API Account",
                                "Cognitive Services API Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCognitiveServicesCommitmentPlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "commitment plan",
                                "commitment plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Commitment Plan",
                                "Commitment Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCommunityCommunityTrainingGenericResource]: {
                         text: {
                             withServiceName: [
                                "community training",
                                "community trainings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Community Training",
                                "Community Trainings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeAvailabilitySetGenericResource]: {
                         text: {
                             withServiceName: [
                                "availability set",
                                "availability sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Availability Set",
                                "Availability Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeCapacityReservationGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "capacity reservation group",
                                "capacity reservation groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Capacity Reservation Group",
                                "Capacity Reservation Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeCapacityReservationGroupsCapacityReservationGenericResource]: {
                         text: {
                             withServiceName: [
                                "capacity reservation",
                                "capacity reservations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Capacity Reservation",
                                "Capacity Reservations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeCloudServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud service resource",
                                "cloud service resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Service Resource",
                                "Cloud Service Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeCloudServicesRoleInstancesNetworkInterfaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud services role instances network interface",
                                "cloud services role instances network interfaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Services Role Instances Network Interface",
                                "Cloud Services Role Instances Network Interfaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeDiskAccessGenericResource]: {
                         text: {
                             withServiceName: [
                                "disk access",
                                "disk accesses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Disk Access",
                                "Disk Accesses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeDiskEncryptionSetGenericResource]: {
                         text: {
                             withServiceName: [
                                "disk encryption set",
                                "disk encryption sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Disk Encryption Set",
                                "Disk Encryption Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeDiskGenericResource]: {
                         text: {
                             withServiceName: [
                                "disk",
                                "disks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Disk",
                                "Disks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleriesApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "gallery application",
                                "gallery applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gallery Application",
                                "Gallery Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleriesApplicationsVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "gallery application version",
                                "gallery application versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gallery Application Version",
                                "Gallery Application Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleriesImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "gallery image",
                                "gallery images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gallery Image",
                                "Gallery Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleriesImagesVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "gallery image version",
                                "gallery image versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gallery Image Version",
                                "Gallery Image Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleriesInVmAccessControlProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "galleries in vm access control profile",
                                "galleries in vm access control profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Galleries in VM Access Control Profile",
                                "Galleries in VM Access Control Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleriesInVmAccessControlProfilesVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "galleries in vm access control profiles version",
                                "galleries in vm access control profiles versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Galleries in VM Access Control Profiles Version",
                                "Galleries in VM Access Control Profiles Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleriesRemoteContainerImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "gallery remote container image",
                                "gallery remote container images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gallery Remote Container Image",
                                "Gallery Remote Container Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleriesServiceArtifactGenericResource]: {
                         text: {
                             withServiceName: [
                                "gallery service artifact",
                                "gallery service artifacts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gallery Service Artifact",
                                "Gallery Service Artifacts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeGalleryGenericResource]: {
                         text: {
                             withServiceName: [
                                "gallery",
                                "galleries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gallery",
                                "Galleries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeHostGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "host group",
                                "host groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Host Group",
                                "Host Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeHostGroupsHostGenericResource]: {
                         text: {
                             withServiceName: [
                                "host",
                                "hosts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Host",
                                "Hosts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "image",
                                "images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Image",
                                "Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeProximityPlacementGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "proximity placement group",
                                "proximity placement groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Proximity Placement Group",
                                "Proximity Placement Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeRestorePointCollectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "restore point collection",
                                "restore point collections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Restore Point Collection",
                                "Restore Point Collections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeScheduleAutoActionGenericResource]: {
                         text: {
                             withServiceName: [
                                "auto action",
                                "auto actions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Auto Action",
                                "Auto Actions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeSharedVmExtensionGenericResource]: {
                         text: {
                             withServiceName: [
                                "shared vm extension",
                                "shared vm extensions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Shared VM Extension",
                                "Shared VM Extensions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeSharedVmExtensionsVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "shared vm extension version",
                                "shared vm extension versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Shared VM Extension Version",
                                "Shared VM Extension Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeSharedVmImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "shared vm image",
                                "shared vm images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Shared VM Image",
                                "Shared VM Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeSharedVmImagesVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "shared vm image version",
                                "shared vm image versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Shared VM Image Version",
                                "Shared VM Image Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeSnapshotGenericResource]: {
                         text: {
                             withServiceName: [
                                "snapshot",
                                "snapshots"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Snapshot",
                                "Snapshots"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeSshPublicKeyGenericResource]: {
                         text: {
                             withServiceName: [
                                "ssh public key",
                                "ssh public keys"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SSH Public Key",
                                "SSH Public Keys"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machine",
                                "virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machine",
                                "Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachineScaleSetGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machine scale set",
                                "virtual machine scale sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machine Scale Set",
                                "Virtual Machine Scale Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachineScaleSetsVirtualMachinesNetworkInterfacesIpConfigurationsPublicIpAddressGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip address created using virtual machine scale set",
                                "ip address created using virtual machine scale sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IP Address Created Using Virtual Machine Scale Set",
                                "IP Address Created Using Virtual Machine Scale Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachinesExtensionGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machine extension",
                                "virtual machine extensions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machine Extension",
                                "Virtual Machine Extensions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachinesRunCommandGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machine run command",
                                "virtual machine run commands"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machine Run Command",
                                "Virtual Machine Run Commands"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachinesVmApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machines vm application",
                                "virtual machines vm applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machines VM Application",
                                "Virtual Machines VM Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConfidentialLedgerLedgerGenericResource]: {
                         text: {
                             withServiceName: [
                                "ledger",
                                "ledgers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ledger",
                                "Ledgers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConfidentialLedgerManagedCcFGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed cc f",
                                "managed cc fs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed CC F",
                                "Managed CC Fs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConfluentOrganizationGenericResource]: {
                         text: {
                             withServiceName: [
                                "organization",
                                "organizations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Organization",
                                "Organizations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedCacheCacheNodeGenericResource]: {
                         text: {
                             withServiceName: [
                                "cache node",
                                "cache nodes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cache Node",
                                "Cache Nodes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedCacheEnterpriseCustomerGenericResource]: {
                         text: {
                             withServiceName: [
                                "enterprise customer",
                                "enterprise customers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Enterprise Customer",
                                "Enterprise Customers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedCacheEnterpriseMccCustomerGenericResource]: {
                         text: {
                             withServiceName: [
                                "enterprise mcc customer",
                                "enterprise mcc customers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Enterprise Mcc Customer",
                                "Enterprise Mcc Customers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedCacheEnterpriseMccCustomersEnterpriseMccCacheNodeGenericResource]: {
                         text: {
                             withServiceName: [
                                "enterprise mcc customers enterprise mcc cache node",
                                "enterprise mcc customers enterprise mcc cache nodes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Enterprise Mcc Customers Enterprise Mcc Cache Node",
                                "Enterprise Mcc Customers Enterprise Mcc Cache Nodes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedCacheIspCustomerGenericResource]: {
                         text: {
                             withServiceName: [
                                "isp customer",
                                "isp customers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Isp Customer",
                                "Isp Customers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedCacheIspCustomersIspCacheNodeGenericResource]: {
                         text: {
                             withServiceName: [
                                "isp customers isp cache node",
                                "isp customers isp cache nodes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Isp Customers Isp Cache Node",
                                "Isp Customers Isp Cache Nodes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedCredentialsCredentialGenericResource]: {
                         text: {
                             withServiceName: [
                                "credential",
                                "credentials"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Credential",
                                "Credentials"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster",
                                "clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster",
                                "Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereDatastoreGenericResource]: {
                         text: {
                             withServiceName: [
                                "datastore",
                                "datastores"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Datastore",
                                "Datastores"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereHostGenericResource]: {
                         text: {
                             withServiceName: [
                                "host",
                                "hosts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Host",
                                "Hosts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereResourcepoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware vsphere resource pool",
                                "vmware vsphere resource pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware vSphere Resource Pool",
                                "VMware vSphere Resource Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereVcenterGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware vsphere vcenter",
                                "vmware vsphere vcenters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware vSphere vCenter",
                                "VMware vSphere vCenters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereVirtualmachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware vsphere virtual machine",
                                "vmware vsphere virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware vSphere Virtual Machine",
                                "VMware vSphere Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereVirtualmachinesExtensionGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware vsphere virtual machine extension",
                                "vmware vsphere virtual machine extensions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware vSphere Virtual Machine Extension",
                                "VMware vSphere Virtual Machine Extensions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereVirtualmachinetemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware vsphere virtual machine template",
                                "vmware vsphere virtual machine templates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware vSphere Virtual Machine Template",
                                "VMware vSphere Virtual Machine Templates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVMwareVSphereVirtualnetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware vsphere virtual network",
                                "vmware vsphere virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware vSphere Virtual Network",
                                "VMware vSphere Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedVehiclePlatformAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "platform account",
                                "platform accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Platform Account",
                                "Platform Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedopenstackHeatStackGenericResource]: {
                         text: {
                             withServiceName: [
                                "heat stack",
                                "heat stacks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Heat Stack",
                                "Heat Stacks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureConnectedopenstackOpenStackIdentityGenericResource]: {
                         text: {
                             withServiceName: [
                                "open stack identity",
                                "open stack identities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Open Stack Identity",
                                "Open Stack Identities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerInstanceContainerGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "container instance",
                                "container instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container Instance",
                                "Container Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerInstanceContainerGroupProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "container group profile",
                                "container group profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container Group Profile",
                                "Container Group Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerInstanceContainerScaleSetGenericResource]: {
                         text: {
                             withServiceName: [
                                "container scale set",
                                "container scale sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container Scale Set",
                                "Container Scale Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerInstanceNGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "n group",
                                "n groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "N Group",
                                "N Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRegistriesAgentpoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "registry agent pool",
                                "registry agent pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registry Agent Pool",
                                "Registry Agent Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRegistriesBuildTaskGenericResource]: {
                         text: {
                             withServiceName: [
                                "registry build task",
                                "registry build tasks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registry Build Task",
                                "Registry Build Tasks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRegistriesReplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "registries replication",
                                "registries replications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registries Replication",
                                "Registries Replications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRegistriesTaskGenericResource]: {
                         text: {
                             withServiceName: [
                                "registry task",
                                "registry tasks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registry Task",
                                "Registry Tasks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRegistriesTaskrunGenericResource]: {
                         text: {
                             withServiceName: [
                                "registry task run",
                                "registry task runs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registry Task Run",
                                "Registry Task Runs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRegistriesWebhookGenericResource]: {
                         text: {
                             withServiceName: [
                                "registries webhook",
                                "registries webhooks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registries webhook",
                                "Registries webhooks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRegistryGenericResource]: {
                         text: {
                             withServiceName: [
                                "registry",
                                "registries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registry",
                                "Registries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerServiceContainerServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "container service",
                                "container services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container Service",
                                "Container Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerServiceFleetGenericResource]: {
                         text: {
                             withServiceName: [
                                "fleet",
                                "fleets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fleet",
                                "Fleets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerServiceManagedClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed cluster",
                                "managed clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed Cluster",
                                "Managed Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerServiceManagedclustersnapshotGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed cluster snapshot",
                                "managed cluster snapshots"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed Cluster Snapshot",
                                "Managed Cluster Snapshots"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureContainerServiceSnapshotGenericResource]: {
                         text: {
                             withServiceName: [
                                "snapshot",
                                "snapshots"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Snapshot",
                                "Snapshots"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCostManagementConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "connector",
                                "connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connector",
                                "Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureCustomProvidersResourceproviderGenericResource]: {
                         text: {
                             withServiceName: [
                                "custom resource provider",
                                "custom resource providers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Custom Resource Provider",
                                "Custom Resource Providers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureD365CustomerInsightsInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance",
                                "instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance",
                                "Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDashboardGrafanaGenericResource]: {
                         text: {
                             withServiceName: [
                                "grafana",
                                "grafanas"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Grafana",
                                "Grafanas"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDashboardGrafanaManagedPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed private endpoint",
                                "managed private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed Private Endpoint",
                                "Managed Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataBoxEdgeDataBoxEdgeDeviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "data box edge device",
                                "data box edge devices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Box Edge Device",
                                "Data Box Edge Devices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataBoxJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "order",
                                "orders"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Order",
                                "Orders"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataFactoryFactoryGenericResource]: {
                         text: {
                             withServiceName: [
                                "data factory v2",
                                "data factories  v2"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Factory V2",
                                "Data Factories  V2"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataLakeAnalyticsAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataLakeStoreAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "data lake storage",
                                "data lake storages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Lake Storage",
                                "Data Lake Storages"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataMigrationMigrationServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "migration service",
                                "migration services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migration Service",
                                "Migration Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataMigrationServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "database migration service",
                                "database migration services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database Migration Service",
                                "Database Migration Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataMigrationServicesProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "database migration project",
                                "database migration projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database Migration Project",
                                "Database Migration Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataMigrationSlotGenericResource]: {
                         text: {
                             withServiceName: [
                                "slot",
                                "slots"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Slot",
                                "Slots"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataMigrationSqlMigrationServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql migration service",
                                "sql migration services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Migration Service",
                                "SQL Migration Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataProtectionBackupVaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "backup vault",
                                "backup vaults"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Backup Vault",
                                "Backup Vaults"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataProtectionResourceGuardGenericResource]: {
                         text: {
                             withServiceName: [
                                "resource guard",
                                "resource guards"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Resource Guard",
                                "Resource Guards"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataReplicationReplicationFabricGenericResource]: {
                         text: {
                             withServiceName: [
                                "replication fabric",
                                "replication fabrics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Replication Fabric",
                                "Replication Fabrics"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataReplicationReplicationVaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "protected item",
                                "protected items"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Protected Item",
                                "Protected Items"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataShareAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "data share",
                                "data shares"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Share",
                                "Data Shares"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataTransferConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "connection",
                                "connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connection",
                                "Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataTransferConnectionsFlowGenericResource]: {
                         text: {
                             withServiceName: [
                                "connections flow",
                                "connections flows"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connections Flow",
                                "Connections Flows"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDataTransferPipelineGenericResource]: {
                         text: {
                             withServiceName: [
                                "pipeline",
                                "pipelines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Pipeline",
                                "Pipelines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDatabaseFleetManagerFleetGenericResource]: {
                         text: {
                             withServiceName: [
                                "fleet resource",
                                "fleet resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fleet Resource",
                                "Fleet Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDatabaseWatcherWatcherGenericResource]: {
                         text: {
                             withServiceName: [
                                "watcher",
                                "watchers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Watcher",
                                "Watchers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDatabricksAccessConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "access connector",
                                "access connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Access Connector",
                                "Access Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDatabricksWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "databricks workspace",
                                "databricks workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Databricks Workspace",
                                "Databricks Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDatadogMonitorGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitor",
                                "monitors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitor",
                                "Monitors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDbForMariaDbServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "mariadb database server",
                                "mariadb database servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "MariaDB Database Server",
                                "MariaDB Database Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDbForMySqlFlexibleServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "mysql flexible database server",
                                "mysql flexible database servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "MySQL Flexible Database Server",
                                "MySQL Flexible Database Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDbForMySqlServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "mysql database server",
                                "mysql database servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "MySQL Database Server",
                                "MySQL Database Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDbForPostgreSqlFlexibleServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "postgre sql server",
                                "postgre sql servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Postgre SQL Server",
                                "Postgre SQL Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDbForPostgreSqlServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "postgre sql server",
                                "postgre sql servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Postgre SQL Server",
                                "Postgre SQL Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDbForPostgreSqlServerGroupsv2GenericResource]: {
                         text: {
                             withServiceName: [
                                "database for postgre sql s gv 2 private endpoint connections approval resource",
                                "database for postgre sql s gv 2 private endpoint connections approval resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database for Postgre SQL S Gv 2 Private Endpoint Connections Approval Resource",
                                "Database for Postgre SQL S Gv 2 Private Endpoint Connections Approval Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDelegatedNetworkControllerGenericResource]: {
                         text: {
                             withServiceName: [
                                "microsoft delegated network controller service instance",
                                "microsoft delegated network controller service instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Microsoft Delegated Network Controller Service Instance",
                                "Microsoft Delegated Network Controller Service Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDelegatedNetworkDelegatedSubnetGenericResource]: {
                         text: {
                             withServiceName: [
                                "microsoft delegated subnet instance",
                                "microsoft delegated subnet instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Microsoft Delegated Subnet Instance",
                                "Microsoft Delegated Subnet Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDelegatedNetworkOrchestratorGenericResource]: {
                         text: {
                             withServiceName: [
                                "orchestrator",
                                "orchestrators"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Orchestrator",
                                "Orchestrators"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDesktopVirtualizationAppattachpackageGenericResource]: {
                         text: {
                             withServiceName: [
                                "appattachpackage",
                                "appattachpackages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Appattachpackage",
                                "Appattachpackages"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDesktopVirtualizationApplicationgroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual desktop application group",
                                "virtual desktop application groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Desktop Application Group",
                                "Virtual Desktop Application Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDesktopVirtualizationConnectionPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "connection policy",
                                "connection policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connection Policy",
                                "Connection Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDesktopVirtualizationHostpoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual desktop host pool",
                                "virtual desktop host pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Desktop Host Pool",
                                "Virtual Desktop Host Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDesktopVirtualizationScalingPlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual desktop scaling plan",
                                "virtual desktop scaling plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Desktop Scaling Plan",
                                "Virtual Desktop Scaling Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDesktopVirtualizationWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual desktop workspace",
                                "virtual desktop workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Desktop Workspace",
                                "Virtual Desktop Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevCenterDevcenterGenericResource]: {
                         text: {
                             withServiceName: [
                                "devcenter",
                                "devcenters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Devcenter",
                                "Devcenters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevCenterDevcentersDevboxdefinitionGenericResource]: {
                         text: {
                             withServiceName: [
                                "devcenters devboxdefinition",
                                "devcenters devboxdefinitions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Devcenters Devboxdefinition",
                                "Devcenters Devboxdefinitions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevCenterNetworkConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "network connection",
                                "network connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Connection",
                                "Network Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevCenterPlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "plan",
                                "plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Plan",
                                "Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevCenterProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "project",
                                "projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Project",
                                "Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevCenterProjectsPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "projects pool",
                                "projects pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Projects Pool",
                                "Projects Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevHubIacProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "ia c profile",
                                "ia c profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ia C Profile",
                                "Ia C Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevHubWorkflowGenericResource]: {
                         text: {
                             withServiceName: [
                                "workflow",
                                "workflows"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workflow",
                                "Workflows"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevOpsInfrastructurePoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "pool",
                                "pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Pool",
                                "Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevTestLabLabGenericResource]: {
                         text: {
                             withServiceName: [
                                "lab",
                                "labs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Lab",
                                "Labs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevTestLabLabsServiceRunnerGenericResource]: {
                         text: {
                             withServiceName: [
                                "service runner",
                                "service runners"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Runner",
                                "Service Runners"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevTestLabLabsVirtualMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machine",
                                "virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machine",
                                "Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevTestLabScheduleGenericResource]: {
                         text: {
                             withServiceName: [
                                "schedule",
                                "schedules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Schedule",
                                "Schedules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceRegistryAssetEndpointProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "asset endpoint profile",
                                "asset endpoint profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Asset Endpoint Profile",
                                "Asset Endpoint Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceRegistryAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "asset",
                                "assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Asset",
                                "Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceRegistryDeviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "device",
                                "devices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Device",
                                "Devices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceRegistryDiscoveredAssetEndpointProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "discovered asset endpoint profile",
                                "discovered asset endpoint profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Discovered Asset Endpoint Profile",
                                "Discovered Asset Endpoint Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceRegistryDiscoveredAssetGenericResource]: {
                         text: {
                             withServiceName: [
                                "discovered asset",
                                "discovered assets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Discovered Asset",
                                "Discovered Assets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceRegistrySchemaRegistryGenericResource]: {
                         text: {
                             withServiceName: [
                                "schema registry",
                                "schema registries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Schema Registry",
                                "Schema Registries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "iot hub device update account",
                                "iot hub device update accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IoT Hub Device Update Account",
                                "IoT Hub Device Update Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateAccountsAgentGenericResource]: {
                         text: {
                             withServiceName: [
                                "device update agent",
                                "device update agents"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Device Update Agent",
                                "Device Update Agents"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateAccountsInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "iot hub device update instance",
                                "iot hub device update instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IoT Hub Device Update Instance",
                                "IoT Hub Device Update Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateUpdateAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "update account",
                                "update accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Update Account",
                                "Update Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateUpdateAccountsActiveDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "update accounts active deployment",
                                "update accounts active deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Update Accounts Active Deployment",
                                "Update Accounts Active Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateUpdateAccountsAgentGenericResource]: {
                         text: {
                             withServiceName: [
                                "update accounts agent",
                                "update accounts agents"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Update Accounts Agent",
                                "Update Accounts Agents"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateUpdateAccountsDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "update accounts deployment",
                                "update accounts deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Update Accounts Deployment",
                                "Update Accounts Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateUpdateAccountsDeviceClassGenericResource]: {
                         text: {
                             withServiceName: [
                                "update accounts device class",
                                "update accounts device classes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Update Accounts Device Class",
                                "Update Accounts Device Classes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDeviceUpdateUpdateAccountsUpdateGenericResource]: {
                         text: {
                             withServiceName: [
                                "update accounts update",
                                "update accounts updates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Update Accounts Update",
                                "Update Accounts Updates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevicesIotHubGenericResource]: {
                         text: {
                             withServiceName: [
                                "iot hub",
                                "iot hubs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IoT Hub",
                                "IoT Hubs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDevicesProvisioningServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "iot hub device provisioning service",
                                "iot hub device provisioning services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IoT Hub Device Provisioning Service",
                                "IoT Hub Device Provisioning Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDigitalTwinsDigitalTwinsInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "digital twins instance",
                                "digital twins instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Digital Twins Instance",
                                "Digital Twins Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDocumentDbCassandraClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cassandra cluster",
                                "cassandra clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cassandra Cluster",
                                "Cassandra Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "database account",
                                "database accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Database Account",
                                "Database Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDocumentDbGarnetClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "garnet cluster",
                                "garnet clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Garnet Cluster",
                                "Garnet Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDocumentDbMongoClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "mongo cluster",
                                "mongo clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mongo Cluster",
                                "Mongo Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDocumentDbThroughputPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "throughput pool",
                                "throughput pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Throughput Pool",
                                "Throughput Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDomainRegistrationDomainGenericResource]: {
                         text: {
                             withServiceName: [
                                "domain",
                                "domains"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Domain",
                                "Domains"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDurableTaskNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "durable task scheduler namespace",
                                "durable task scheduler namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Durable Task Scheduler Namespace",
                                "Durable Task Scheduler Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDurableTaskSchedulerGenericResource]: {
                         text: {
                             withServiceName: [
                                "scheduler",
                                "schedulers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Scheduler",
                                "Schedulers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureDynatraceObservabilityMonitorGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitor",
                                "monitors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitor",
                                "Monitors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEasmWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspace",
                                "workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspace",
                                "Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeCapabilityListGenericResource]: {
                         text: {
                             withServiceName: [
                                "capability list",
                                "capability lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Capability List",
                                "Capability Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "configuration",
                                "configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Configuration",
                                "Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeDeploymentTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "deployment target",
                                "deployment targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Deployment Target",
                                "Deployment Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeHierarchyListGenericResource]: {
                         text: {
                             withServiceName: [
                                "hierarchy list",
                                "hierarchy lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Hierarchy List",
                                "Hierarchy Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeOrderAddressGenericResource]: {
                         text: {
                             withServiceName: [
                                "address",
                                "addresses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Address",
                                "Addresses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeOrderBootstrapConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "bootstrap configuration",
                                "bootstrap configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bootstrap Configuration",
                                "Bootstrap Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeOrderOrderItemGenericResource]: {
                         text: {
                             withServiceName: [
                                "order item",
                                "order items"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Order Item",
                                "Order Items"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeSchemaGenericResource]: {
                         text: {
                             withServiceName: [
                                "schema",
                                "schemas"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Schema",
                                "Schemas"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeSolutionBindingGenericResource]: {
                         text: {
                             withServiceName: [
                                "solution binding",
                                "solution bindings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Solution Binding",
                                "Solution Bindings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeSolutionGenericResource]: {
                         text: {
                             withServiceName: [
                                "solution",
                                "solutions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Solution",
                                "Solutions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEdgeWinfieldGenericResource]: {
                         text: {
                             withServiceName: [
                                "winfield",
                                "winfields"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Winfield",
                                "Winfields"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureElasticMonitorGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitor",
                                "monitors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitor",
                                "Monitors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureElasticSanElasticSanGenericResource]: {
                         text: {
                             withServiceName: [
                                "elastic san",
                                "elastic sans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Elastic San",
                                "Elastic Sans"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureElasticSanElasticSansVolumeGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume group",
                                "volume groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume Group",
                                "Volume Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEnterpriseSupportEnterpriseSupportGenericResource]: {
                         text: {
                             withServiceName: [
                                "enterprise support",
                                "enterprise supports"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Enterprise Support",
                                "Enterprise Supports"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridDomainGenericResource]: {
                         text: {
                             withServiceName: [
                                "domain",
                                "domains"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Domain",
                                "Domains"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "namespace",
                                "namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Namespace",
                                "Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridPartnerConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "partner configuration",
                                "partner configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Partner Configuration",
                                "Partner Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridPartnerDestinationGenericResource]: {
                         text: {
                             withServiceName: [
                                "partner destination",
                                "partner destinations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Partner Destination",
                                "Partner Destinations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridPartnerNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "partner namespace",
                                "partner namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Partner Namespace",
                                "Partner Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridPartnerRegistrationGenericResource]: {
                         text: {
                             withServiceName: [
                                "partner registration",
                                "partner registrations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Partner Registration",
                                "Partner Registrations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridPartnerTopicGenericResource]: {
                         text: {
                             withServiceName: [
                                "partner topic",
                                "partner topics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Partner Topic",
                                "Partner Topics"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridSystemTopicGenericResource]: {
                         text: {
                             withServiceName: [
                                "system topic",
                                "system topics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "System Topic",
                                "System Topics"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventGridTopicGenericResource]: {
                         text: {
                             withServiceName: [
                                "topic",
                                "topics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Topic",
                                "Topics"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventHubClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "event hub cluster",
                                "event hub clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Event Hub Cluster",
                                "Event Hub Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureEventHubNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "namespace",
                                "namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Namespace",
                                "Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureExperimentationExperimentWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "experiment workspace",
                                "experiment workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Experiment Workspace",
                                "Experiment Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureExtendedLocationCustomLocationGenericResource]: {
                         text: {
                             withServiceName: [
                                "custom location",
                                "custom locations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Custom Location",
                                "Custom Locations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureExtendedLocationCustomLocationsResourceSyncRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "resource sync rule",
                                "resource sync rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Resource Sync Rule",
                                "Resource Sync Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFabricCapacityGenericResource]: {
                         text: {
                             withServiceName: [
                                "capacity",
                                "capacities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Capacity",
                                "Capacities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFabricPrivateLinkServicesForFabricGenericResource]: {
                         text: {
                             withServiceName: [
                                "private link services for fabric",
                                "private link services for fabrics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Link Services for Fabric",
                                "Private Link Services for Fabrics"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFairfieldGardensProvisioningResourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "provisioning resource",
                                "provisioning resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Provisioning Resource",
                                "Provisioning Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFairfieldGardensProvisioningResourcesProvisioningPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "provisioning resources provisioning policy",
                                "provisioning resources provisioning policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Provisioning Resources Provisioning Policy",
                                "Provisioning Resources Provisioning Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFalconNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "namespace",
                                "namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Namespace",
                                "Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFileSharesFileShareGenericResource]: {
                         text: {
                             withServiceName: [
                                "file share",
                                "file shares"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "File Share",
                                "File Shares"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFleetFleetGenericResource]: {
                         text: {
                             withServiceName: [
                                "fleet",
                                "fleets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fleet",
                                "Fleets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFluidRelayFluidRelayServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "fluid relay server",
                                "fluid relay servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fluid Relay Server",
                                "Fluid Relay Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureFootprintMonitoringProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "profile",
                                "profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Profile",
                                "Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureGitHubNetworkNetworkSettingGenericResource]: {
                         text: {
                             withServiceName: [
                                "network setting",
                                "network settings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Setting",
                                "Network Settings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureGraphServicesAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHanaOnHanaInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sap hana instance",
                                "sap hana instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SAP HANA Instance",
                                "SAP HANA Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHardwareOrderGenericResource]: {
                         text: {
                             withServiceName: [
                                "order",
                                "orders"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Order",
                                "Orders"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHardwareSecurityModulesCloudHsmClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud hsm",
                                "cloud hsms"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Hsm",
                                "Cloud Hsms"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHardwareSecurityModulesDedicatedhsmGenericResource]: {
                         text: {
                             withServiceName: [
                                "hardware security module",
                                "hardware security modules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Hardware Security Module",
                                "Hardware Security Modules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHdInsightClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "hd insight cluster",
                                "hd insight clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "HD Insight Cluster",
                                "HD Insight Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHdInsightClusterPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "hd insight on aks cluster pool",
                                "hd insight on aks cluster pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "HD Insight on AKS Cluster Pool",
                                "HD Insight on AKS Cluster Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHdInsightClusterPoolsClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "hd insight on aks cluster",
                                "hd insight on aks clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "HD Insight on AKS Cluster",
                                "HD Insight on AKS Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthBotHealthBotGenericResource]: {
                         text: {
                             withServiceName: [
                                "health bot",
                                "health bots"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Health Bot",
                                "Health Bots"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthDataAiServicesDeidServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "deid service",
                                "deid services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Deid Service",
                                "Deid Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthModelHealthmodelGenericResource]: {
                         text: {
                             withServiceName: [
                                "healthmodel",
                                "healthmodels"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Healthmodel",
                                "Healthmodels"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthcareApisServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "service",
                                "services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service",
                                "Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthcareApisServicesPrivateEndpointConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "private endpoint connection",
                                "private endpoint connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Endpoint Connection",
                                "Private Endpoint Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthcareApisWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspace",
                                "workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspace",
                                "Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthcareApisWorkspacesAnalyticsconnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspaces analyticsconnector",
                                "workspaces analyticsconnectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspaces Analyticsconnector",
                                "Workspaces Analyticsconnectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthcareApisWorkspacesDicomserviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspaces dicomservice",
                                "workspaces dicomservices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspaces Dicomservice",
                                "Workspaces Dicomservices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthcareApisWorkspacesFhirserviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspaces fhirservice",
                                "workspaces fhirservices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspaces Fhirservice",
                                "Workspaces Fhirservices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHealthcareApisWorkspacesIotconnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspaces iotconnector",
                                "workspaces iotconnectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspaces Iotconnector",
                                "Workspaces Iotconnectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridCloudCloudConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud connection",
                                "cloud connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Connection",
                                "Cloud Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridCloudCloudConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud connector",
                                "cloud connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Connector",
                                "Cloud Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridComputeGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "gateway",
                                "gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gateway",
                                "Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridComputeLicenseGenericResource]: {
                         text: {
                             withServiceName: [
                                "license",
                                "licenses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "License",
                                "Licenses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridComputeMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine",
                                "machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine",
                                "Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridComputeMachinesExtensionGenericResource]: {
                         text: {
                             withServiceName: [
                                "extension",
                                "extensions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Extension",
                                "Extensions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridComputeMachinesLicenseProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "license profile",
                                "license profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "License Profile",
                                "License Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridComputeMachinesRuncommandGenericResource]: {
                         text: {
                             withServiceName: [
                                "runcommand",
                                "runcommands"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Runcommand",
                                "Runcommands"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridComputePrivateLinkScopeGenericResource]: {
                         text: {
                             withServiceName: [
                                "private link scope",
                                "private link scopes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Link Scope",
                                "Private Link Scopes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridConnectivityPublicCloudConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "public cloud connector",
                                "public cloud connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Public Cloud Connector",
                                "Public Cloud Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridContainerServiceProvisionedClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "provisioned cluster",
                                "provisioned clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Provisioned Cluster",
                                "Provisioned Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridContainerServiceProvisionedClustersAgentPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "provisioned clusters agent pool",
                                "provisioned clusters agent pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Provisioned Clusters Agent Pool",
                                "Provisioned Clusters Agent Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridContainerServiceStorageSpaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage space",
                                "storage spaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Space",
                                "Storage Spaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridContainerServiceVirtualNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual network",
                                "virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Network",
                                "Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkConfigurationGroupValueGenericResource]: {
                         text: {
                             withServiceName: [
                                "configuration group value",
                                "configuration group values"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Configuration Group Value",
                                "Configuration Group Values"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkDeviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "device",
                                "devices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Device",
                                "Devices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkNetworkFunctionGenericResource]: {
                         text: {
                             withServiceName: [
                                "network function",
                                "network functions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Function",
                                "Network Functions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublisherGenericResource]: {
                         text: {
                             withServiceName: [
                                "publisher",
                                "publishers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publisher",
                                "Publishers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublishersArtifactStoreGenericResource]: {
                         text: {
                             withServiceName: [
                                "publishers artifact store",
                                "publishers artifact stores"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publishers Artifact Store",
                                "Publishers Artifact Stores"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublishersArtifactStoresArtifactManifestGenericResource]: {
                         text: {
                             withServiceName: [
                                "publishers artifact stores artifact manifest",
                                "publishers artifact stores artifact manifests"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publishers Artifact Stores Artifact Manifest",
                                "Publishers Artifact Stores Artifact Manifests"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublishersConfigurationGroupSchemaGenericResource]: {
                         text: {
                             withServiceName: [
                                "publishers configuration group schema",
                                "publishers configuration group schemas"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publishers Configuration Group Schema",
                                "Publishers Configuration Group Schemas"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "publishers network function definition group",
                                "publishers network function definition groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publishers Network Function Definition Group",
                                "Publishers Network Function Definition Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsNetworkFunctionDefinitionVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "publishers network function definition groups network function definition version",
                                "publishers network function definition groups network function definition versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publishers Network Function Definition Groups Network Function Definition Version",
                                "Publishers Network Function Definition Groups Network Function Definition Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsPreviewSubscriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "publishers network function definition groups preview subscription",
                                "publishers network function definition groups preview subscriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publishers Network Function Definition Groups Preview Subscription",
                                "Publishers Network Function Definition Groups Preview Subscriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublishersNetworkServiceDesignGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "publishers network service design group",
                                "publishers network service design groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publishers Network Service Design Group",
                                "Publishers Network Service Design Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkPublishersNetworkServiceDesignGroupsNetworkServiceDesignVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "publishers network service design groups network service design version",
                                "publishers network service design groups network service design versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Publishers Network Service Design Groups Network Service Design Version",
                                "Publishers Network Service Design Groups Network Service Design Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkServiceManagementContainerGenericResource]: {
                         text: {
                             withServiceName: [
                                "service management container",
                                "service management containers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Management Container",
                                "Service Management Containers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkServiceManagementContainersRolloutSequenceGenericResource]: {
                         text: {
                             withServiceName: [
                                "service management containers rollout sequence",
                                "service management containers rollout sequences"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Management Containers Rollout Sequence",
                                "Service Management Containers Rollout Sequences"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkServiceManagementContainersRolloutTierGenericResource]: {
                         text: {
                             withServiceName: [
                                "service management containers rollout tier",
                                "service management containers rollout tiers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Management Containers Rollout Tier",
                                "Service Management Containers Rollout Tiers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkServiceManagementContainersUpdateSpecificationGenericResource]: {
                         text: {
                             withServiceName: [
                                "service management containers update specification",
                                "service management containers update specifications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Management Containers Update Specification",
                                "Service Management Containers Update Specifications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkServiceManagementContainersUpdateSpecificationsRolloutGenericResource]: {
                         text: {
                             withServiceName: [
                                "service management containers update specifications rollout",
                                "service management containers update specifications rollouts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Management Containers Update Specifications Rollout",
                                "Service Management Containers Update Specifications Rollouts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "site",
                                "sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Site",
                                "Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureHybridNetworkSiteNetworkServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "site network service",
                                "site network services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Site Network Service",
                                "Site Network Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureImageTestingForLinuxJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "job",
                                "jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Job",
                                "Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureImageTestingForLinuxJobTemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "job template",
                                "job templates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Job Template",
                                "Job Templates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInformaticaDataManagementOrganizationGenericResource]: {
                         text: {
                             withServiceName: [
                                "organization",
                                "organizations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Organization",
                                "Organizations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsActionGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "action group",
                                "action groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Action Group",
                                "Action Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsActivityLogAlertGenericResource]: {
                         text: {
                             withServiceName: [
                                "activity log alert",
                                "activity log alerts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Activity Log Alert",
                                "Activity Log Alerts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsAutoscaleSettingGenericResource]: {
                         text: {
                             withServiceName: [
                                "autoscale setting",
                                "autoscale settings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autoscale Setting",
                                "Autoscale Settings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsComponentGenericResource]: {
                         text: {
                             withServiceName: [
                                "application insights component",
                                "application insights components"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Insights Component",
                                "Application Insights Components"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsDataCollectionEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "data collection endpoint",
                                "data collection endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Collection Endpoint",
                                "Data Collection Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsDataCollectionRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "data collection rule",
                                "data collection rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Collection Rule",
                                "Data Collection Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsGuestDiagnosticSettingGenericResource]: {
                         text: {
                             withServiceName: [
                                "guest diagnostic setting",
                                "guest diagnostic settings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Guest Diagnostic Setting",
                                "Guest Diagnostic Settings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsMetricAlertGenericResource]: {
                         text: {
                             withServiceName: [
                                "metric alert",
                                "metric alerts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Metric Alert",
                                "Metric Alerts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsPrivateLinkScopeGenericResource]: {
                         text: {
                             withServiceName: [
                                "private link scope",
                                "private link scopes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Link Scope",
                                "Private Link Scopes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsScheduledQueryRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "scheduled query rule",
                                "scheduled query rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Scheduled Query Rule",
                                "Scheduled Query Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsWebtestGenericResource]: {
                         text: {
                             withServiceName: [
                                "web test",
                                "web tests"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web Test",
                                "Web Tests"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsWorkbookGenericResource]: {
                         text: {
                             withServiceName: [
                                "application insights workbooks",
                                "application insights workbooks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Insights Workbooks",
                                "Application Insights Workbooks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureInsightsWorkbookTemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "workbook template",
                                "workbook templates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workbook Template",
                                "Workbook Templates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIntegrationSpacesSpaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "space",
                                "spaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Space",
                                "Spaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIntegrationSpacesSpacesApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "spaces application",
                                "spaces applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Spaces Application",
                                "Spaces Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTFirmwareDefenseWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspace",
                                "workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspace",
                                "Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsDataProcessorInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance",
                                "instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance",
                                "Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsDataProcessorInstancesDatasetGenericResource]: {
                         text: {
                             withServiceName: [
                                "instances dataset",
                                "instances datasets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instances Dataset",
                                "Instances Datasets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsDataProcessorInstancesPipelineGenericResource]: {
                         text: {
                             withServiceName: [
                                "instances pipeline",
                                "instances pipelines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instances Pipeline",
                                "Instances Pipelines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance",
                                "instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance",
                                "Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqBrokerAuthenticationGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq broker authentication",
                                "mq broker authentications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Broker Authentication",
                                "Mq Broker Authentications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqBrokerAuthorizationGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq broker authorization",
                                "mq broker authorizations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Broker Authorization",
                                "Mq Broker Authorizations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqBrokerGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq broker",
                                "mq brokers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Broker",
                                "Mq Brokers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqBrokerListenerGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq broker listener",
                                "mq broker listeners"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Broker Listener",
                                "Mq Broker Listeners"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqDataLakeConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq data lake connector",
                                "mq data lake connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Data Lake Connector",
                                "Mq Data Lake Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqDataLakeConnectorTopicMapGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq data lake connector topic map",
                                "mq data lake connector topic maps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Data Lake Connector Topic Map",
                                "Mq Data Lake Connector Topic Maps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqDiagnosticServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq diagnostic service",
                                "mq diagnostic services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Diagnostic Service",
                                "Mq Diagnostic Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq",
                                "mqs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq",
                                "Mqs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqKafkaConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq kafka connector",
                                "mq kafka connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Kafka Connector",
                                "Mq Kafka Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqKafkaConnectorTopicMapGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq kafka connector topic map",
                                "mq kafka connector topic maps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Kafka Connector Topic Map",
                                "Mq Kafka Connector Topic Maps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqMqttBridgeConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq mqtt bridge connector",
                                "mq mqtt bridge connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Mqtt Bridge Connector",
                                "Mq Mqtt Bridge Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsMqMqMqttBridgeConnectorTopicMapGenericResource]: {
                         text: {
                             withServiceName: [
                                "mq mqtt bridge connector topic map",
                                "mq mqtt bridge connector topic maps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mq Mqtt Bridge Connector Topic Map",
                                "Mq Mqtt Bridge Connector Topic Maps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsOrchestratorInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance",
                                "instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance",
                                "Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsOrchestratorSolutionGenericResource]: {
                         text: {
                             withServiceName: [
                                "solution",
                                "solutions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Solution",
                                "Solutions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIoTOperationsOrchestratorTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "target",
                                "targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Target",
                                "Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureIotCentralIoTAppGenericResource]: {
                         text: {
                             withServiceName: [
                                "iot app",
                                "iot apps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IoT App",
                                "IoT Apps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureKeyVaultHsmPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "hsm pool",
                                "hsm pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "HSM Pool",
                                "HSM Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureKeyVaultManagedHsMGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed hsm",
                                "managed hsms"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed HSM",
                                "Managed HSMs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureKeyVaultVaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "key vault",
                                "key vaults"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Key Vault",
                                "Key Vaults"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureKubernetesConfigurationPrivateLinkScopeGenericResource]: {
                         text: {
                             withServiceName: [
                                "microsoft kubernetes configuration private link scope",
                                "microsoft kubernetes configuration private link scopes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Microsoft Kubernetes Configuration Private Link Scope",
                                "Microsoft Kubernetes Configuration Private Link Scopes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureKubernetesConnectedClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "connected cluster",
                                "connected clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connected Cluster",
                                "Connected Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureKustoClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "data explorer cluster",
                                "data explorer clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Data Explorer Cluster",
                                "Data Explorer Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLabServicesLabAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "lab account",
                                "lab accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Lab Account",
                                "Lab Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLabServicesLabGenericResource]: {
                         text: {
                             withServiceName: [
                                "microsoft lab services lab",
                                "microsoft lab services labs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Microsoft Lab Services Lab",
                                "Microsoft Lab Services Labs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLabServicesLabPlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "microsoft lab services lab plan",
                                "microsoft lab services lab plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Microsoft Lab Services Lab Plan",
                                "Microsoft Lab Services Lab Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLargeInstanceAzureLargeInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "large instance",
                                "large instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Large Instance",
                                "Large Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLargeInstanceAzureLargeStorageInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "large storage instance",
                                "large storage instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Large Storage Instance",
                                "Large Storage Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLoadTestServiceLoadTestGenericResource]: {
                         text: {
                             withServiceName: [
                                "loadtest",
                                "loadtests"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Loadtest",
                                "Loadtests"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLogicBusinessprocessGenericResource]: {
                         text: {
                             withServiceName: [
                                "business process",
                                "business processes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Business Process",
                                "Business Processes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLogicIntegrationAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "integration account",
                                "integration accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Integration Account",
                                "Integration Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLogicIntegrationServiceEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "integration service environment",
                                "integration service environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Integration Service Environment",
                                "Integration Service Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLogicIntegrationServiceEnvironmentsManagedApiGenericResource]: {
                         text: {
                             withServiceName: [
                                "integration service environment managed api",
                                "integration service environment managed apis"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Integration Service Environment Managed API",
                                "Integration Service Environment Managed APIs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLogicWorkflowGenericResource]: {
                         text: {
                             withServiceName: [
                                "workflow",
                                "workflows"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workflow",
                                "Workflows"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLogzMonitorGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitor",
                                "monitors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitor",
                                "Monitors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLogzMonitorsAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureLogzMonitorsMetricsSourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "metrics source",
                                "metrics sources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Metrics Source",
                                "Metrics Sources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesRegistryGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine learning service registry",
                                "machine learning service registries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine Learning Service Registry",
                                "Machine Learning Service Registries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesVirtualclusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine learning service virtual cluster",
                                "machine learning service virtual clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine Learning Service Virtual Cluster",
                                "Machine Learning Service Virtual Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine learning service workspace",
                                "machine learning service workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine Learning Service Workspace",
                                "Machine Learning Service Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspacesBatchEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine learning service workspace batch endpoint",
                                "machine learning service workspace batch endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine Learning Service Workspace Batch Endpoint",
                                "Machine Learning Service Workspace Batch Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspacesBatchEndpointsDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine learning service workspace batch endpoint deployment",
                                "machine learning service workspace batch endpoint deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine Learning Service Workspace Batch Endpoint Deployment",
                                "Machine Learning Service Workspace Batch Endpoint Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspacesInferencePoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspaces inference pool",
                                "workspaces inference pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspaces Inference Pool",
                                "Workspaces Inference Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspacesInferencePoolsEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspaces inference pools endpoint",
                                "workspaces inference pools endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspaces Inference Pools Endpoint",
                                "Workspaces Inference Pools Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspacesInferencePoolsGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspaces inference pools group",
                                "workspaces inference pools groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspaces Inference Pools Group",
                                "Workspaces Inference Pools Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspacesOnlineEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine learning service workspace online endpoint",
                                "machine learning service workspace online endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine Learning Service Workspace Online Endpoint",
                                "Machine Learning Service Workspace Online Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspacesOnlineEndpointsDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine learning service workspace online endpoint deployment",
                                "machine learning service workspace online endpoint deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine Learning Service Workspace Online Endpoint Deployment",
                                "Machine Learning Service Workspace Online Endpoint Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMachineLearningServicesWorkspacesServerlessEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "machine learning service workspace serverless endpoint",
                                "machine learning service workspace serverless endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Machine Learning Service Workspace Serverless Endpoint",
                                "Machine Learning Service Workspace Serverless Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMaintenanceMaintenanceConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "microsoft maintenance service configuration instance",
                                "microsoft maintenance service configuration instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Microsoft Maintenance Service Configuration Instance",
                                "Microsoft Maintenance Service Configuration Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedIdentityUserAssignedIdentityGenericResource]: {
                         text: {
                             withServiceName: [
                                "user assigned identity",
                                "user assigned identities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "User Assigned Identity",
                                "User Assigned Identities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricAccessControlListGenericResource]: {
                         text: {
                             withServiceName: [
                                "access control list",
                                "access control lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Access Control List",
                                "Access Control Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricInternetGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "internet gateway",
                                "internet gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Internet Gateway",
                                "Internet Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricInternetGatewayRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "internet gateway rule",
                                "internet gateway rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Internet Gateway Rule",
                                "Internet Gateway Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricIpCommunityGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip community",
                                "ip communities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ip Community",
                                "Ip Communities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricIpCommunityListGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip community list",
                                "ip community lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ip Community List",
                                "Ip Community Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricIpExtendedCommunityGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip extended community",
                                "ip extended communities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ip Extended Community",
                                "Ip Extended Communities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricIpPrefixGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip prefix",
                                "ip prefixes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ip Prefix",
                                "Ip Prefixes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricIpPrefixListGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip prefix list",
                                "ip prefix lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ip Prefix List",
                                "Ip Prefix Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricL2IsolationDomainGenericResource]: {
                         text: {
                             withServiceName: [
                                "l 2 isolation domain",
                                "l 2 isolation domains"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "L 2 Isolation Domain",
                                "L 2 Isolation Domains"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricL3IsolationDomainGenericResource]: {
                         text: {
                             withServiceName: [
                                "l 3 isolation domain",
                                "l 3 isolation domains"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "L 3 Isolation Domain",
                                "L 3 Isolation Domains"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricNeighborGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "neighbor group",
                                "neighbor groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Neighbor Group",
                                "Neighbor Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricNetworkDeviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "network device",
                                "network devices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Device",
                                "Network Devices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricNetworkFabricControllerGenericResource]: {
                         text: {
                             withServiceName: [
                                "network fabric controller",
                                "network fabric controllers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Fabric Controller",
                                "Network Fabric Controllers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricNetworkFabricGenericResource]: {
                         text: {
                             withServiceName: [
                                "network fabric",
                                "network fabrics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Fabric",
                                "Network Fabrics"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricNetworkPacketBrokerGenericResource]: {
                         text: {
                             withServiceName: [
                                "network packet broker",
                                "network packet brokers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Packet Broker",
                                "Network Packet Brokers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricNetworkRackGenericResource]: {
                         text: {
                             withServiceName: [
                                "network rack",
                                "network racks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Rack",
                                "Network Racks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricNetworkTapGenericResource]: {
                         text: {
                             withServiceName: [
                                "network tap",
                                "network taps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Tap",
                                "Network Taps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricNetworkTapRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "network tap rule",
                                "network tap rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Tap Rule",
                                "Network Tap Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManagedNetworkFabricRoutePolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "route policy",
                                "route policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Route Policy",
                                "Route Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureManufacturingPlatformManufacturingDataServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "manufacturing data service",
                                "manufacturing data services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Manufacturing Data Service",
                                "Manufacturing Data Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMapsAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "maps account",
                                "maps accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Maps Account",
                                "Maps Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMapsAccountsCreatorGenericResource]: {
                         text: {
                             withServiceName: [
                                "creator",
                                "creators"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Creator",
                                "Creators"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMediaMediaserviceGenericResource]: {
                         text: {
                             withServiceName: [
                                "media service",
                                "media services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Media Service",
                                "Media Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMediaMediaservicesLiveEventGenericResource]: {
                         text: {
                             withServiceName: [
                                "live event",
                                "live events"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Live Event",
                                "Live Events"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMediaMediaservicesStreamingEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "streaming endpoint",
                                "streaming endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Streaming Endpoint",
                                "Streaming Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMediaVideoAnalyzerGenericResource]: {
                         text: {
                             withServiceName: [
                                "video analyzer account",
                                "video analyzer accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Video Analyzer Account",
                                "Video Analyzer Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMessagingCatalogCatalogGenericResource]: {
                         text: {
                             withServiceName: [
                                "catalog",
                                "catalogs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Catalog",
                                "Catalogs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMessagingConnectorsConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "connector",
                                "connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connector",
                                "Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMigrateAssessmentProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "assessment project",
                                "assessment projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Assessment Project",
                                "Assessment Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMigrateMigrateProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "migrate project",
                                "migrate projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migrate Project",
                                "Migrate Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMigrateModernizeProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "modernization resource",
                                "modernization resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Modernization Resource",
                                "Modernization Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMigrateMoveCollectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "move collection",
                                "move collections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Move Collection",
                                "Move Collections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionCatalogGenericResource]: {
                         text: {
                             withServiceName: [
                                "catalog",
                                "catalogs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Catalog",
                                "Catalogs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionCommunitiesCommunityEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "communities community endpoint",
                                "communities community endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Communities Community Endpoint",
                                "Communities Community Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionCommunitiesTransitHubGenericResource]: {
                         text: {
                             withServiceName: [
                                "communities transit hub",
                                "communities transit hubs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Communities Transit Hub",
                                "Communities Transit Hubs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionCommunityGenericResource]: {
                         text: {
                             withServiceName: [
                                "community",
                                "communities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Community",
                                "Communities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionEnclaveConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "enclave connection",
                                "enclave connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Enclave Connection",
                                "Enclave Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionExternalConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "external connection",
                                "external connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "External Connection",
                                "External Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionInternalConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "internal connection",
                                "internal connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Internal Connection",
                                "Internal Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionVirtualEnclafeGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual enclafe",
                                "virtual enclaves"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Enclafe",
                                "Virtual Enclaves"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionVirtualEnclavesEnclaveEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual enclaves enclave endpoint",
                                "virtual enclaves enclave endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Enclaves Enclave Endpoint",
                                "Virtual Enclaves Enclave Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionVirtualEnclavesEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual enclaves endpoint",
                                "virtual enclaves endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Enclaves Endpoint",
                                "Virtual Enclaves Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMissionVirtualEnclavesWorkloadGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual enclaves workload",
                                "virtual enclaves workloads"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Enclaves Workload",
                                "Virtual Enclaves Workloads"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMixedRealityObjectAnchorsAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "object anchors account",
                                "object anchors accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Object Anchors Account",
                                "Object Anchors Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMixedRealityObjectUnderstandingAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "object understanding account",
                                "object understanding accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Object Understanding Account",
                                "Object Understanding Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMixedRealityRemoteRenderingAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "remote rendering account",
                                "remote rendering accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Remote Rendering Account",
                                "Remote Rendering Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMixedRealitySpatialAnchorsAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "spatial anchors account",
                                "spatial anchors accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Spatial Anchors Account",
                                "Spatial Anchors Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMixedRealitySpatialMapsAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "spatial maps account",
                                "spatial maps accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Spatial Maps Account",
                                "Spatial Maps Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkMobileNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobile network",
                                "mobile networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobile Network",
                                "Mobile Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkMobileNetworksDataNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobile networks data network",
                                "mobile networks data networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobile Networks Data Network",
                                "Mobile Networks Data Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkMobileNetworksEdgeNetworkSecurityGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobile networks edge network security group",
                                "mobile networks edge network security groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobile Networks Edge Network Security Group",
                                "Mobile Networks Edge Network Security Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkMobileNetworksServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobile networks service",
                                "mobile networks services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobile Networks Service",
                                "Mobile Networks Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkMobileNetworksSimPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobile networks sim policy",
                                "mobile networks sim policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobile Networks Sim Policy",
                                "Mobile Networks Sim Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkMobileNetworksSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobile networks site",
                                "mobile networks sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobile Networks Site",
                                "Mobile Networks Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkMobileNetworksSliceGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobile networks slice",
                                "mobile networks slices"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobile Networks Slice",
                                "Mobile Networks Slices"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkMobileNetworksWifiSsidGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobile networks wifi ssid",
                                "mobile networks wifi ssids"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobile Networks Wifi Ssid",
                                "Mobile Networks Wifi Ssids"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkPacketCoreControlPlaneGenericResource]: {
                         text: {
                             withServiceName: [
                                "packet core control plane",
                                "packet core control planes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Packet Core Control Plane",
                                "Packet Core Control Planes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlaneGenericResource]: {
                         text: {
                             withServiceName: [
                                "packet core control planes packet core data plane",
                                "packet core control planes packet core data planes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Packet Core Control Planes Packet Core Data Plane",
                                "Packet Core Control Planes Packet Core Data Planes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedDataNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "packet core control planes packet core data planes attached data network",
                                "packet core control planes packet core data planes attached data networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Packet Core Control Planes Packet Core Data Planes Attached Data Network",
                                "Packet Core Control Planes Packet Core Data Planes Attached Data Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedWifiSsidGenericResource]: {
                         text: {
                             withServiceName: [
                                "packet core control planes packet core data planes attached wifi ssid",
                                "packet core control planes packet core data planes attached wifi ssids"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Packet Core Control Planes Packet Core Data Planes Attached Wifi Ssid",
                                "Packet Core Control Planes Packet Core Data Planes Attached Wifi Ssids"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesEdgeVirtualNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "packet core control planes packet core data planes edge virtual network",
                                "packet core control planes packet core data planes edge virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Packet Core Control Planes Packet Core Data Planes Edge Virtual Network",
                                "Packet Core Control Planes Packet Core Data Planes Edge Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkRadioAccessNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "radio access network",
                                "radio access networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Radio Access Network",
                                "Radio Access Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkSimGenericResource]: {
                         text: {
                             withServiceName: [
                                "sim",
                                "sims"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sim",
                                "Sims"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMobileNetworkSimGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "sim group",
                                "sim groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sim Group",
                                "Sim Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance",
                                "instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance",
                                "Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchInstancesChamberGenericResource]: {
                         text: {
                             withServiceName: [
                                "instances chamber",
                                "instances chambers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instances Chamber",
                                "Instances Chambers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchInstancesChambersConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "instances chambers connector",
                                "instances chambers connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instances Chambers Connector",
                                "Instances Chambers Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchInstancesChambersWorkloadGenericResource]: {
                         text: {
                             withServiceName: [
                                "instances chambers workload",
                                "instances chambers workloads"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instances Chambers Workload",
                                "Instances Chambers Workloads"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchWorkbenchGenericResource]: {
                         text: {
                             withServiceName: [
                                "workbench",
                                "workbenches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workbench",
                                "Workbenches"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchWorkbenchesChamberGenericResource]: {
                         text: {
                             withServiceName: [
                                "workbenches chamber",
                                "workbenches chambers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workbenches Chamber",
                                "Workbenches Chambers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchWorkbenchesChambersConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "workbenches chambers connector",
                                "workbenches chambers connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workbenches Chambers Connector",
                                "Workbenches Chambers Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchWorkbenchesChambersStorageGenericResource]: {
                         text: {
                             withServiceName: [
                                "workbenches chambers storage",
                                "workbenches chambers storages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workbenches Chambers Storage",
                                "Workbenches Chambers Storages"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchWorkbenchesChambersWorkloadGenericResource]: {
                         text: {
                             withServiceName: [
                                "workbenches chambers workload",
                                "workbenches chambers workloads"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workbenches Chambers Workload",
                                "Workbenches Chambers Workloads"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureModSimWorkbenchWorkbenchesSharedStorageGenericResource]: {
                         text: {
                             withServiceName: [
                                "workbenches shared storage",
                                "workbenches shared storages"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workbenches Shared Storage",
                                "Workbenches Shared Storages"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMonitorAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitoring account",
                                "monitoring accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitoring Account",
                                "Monitoring Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMonitorPipelineGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitoring account",
                                "monitoring accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitoring Account",
                                "Monitoring Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureMySqlDiscoveryMySqlSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "my sql site",
                                "my sql sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "My SQL Site",
                                "My SQL Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetAppNetAppAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "accounts resource type",
                                "accounts resource types"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Accounts Resource Type",
                                "Accounts Resource Types"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetAppNetAppAccountsBackupPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "backup policy resource type",
                                "backup policy resource types"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Backup Policy Resource Type",
                                "Backup Policy Resource Types"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetAppNetAppAccountsBackupVaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "backup vault resource type",
                                "backup vault resource types"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Backup Vault Resource Type",
                                "Backup Vault Resource Types"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetAppNetAppAccountsCapacityPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "pools resource type",
                                "pools resource types"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Pools Resource Type",
                                "Pools Resource Types"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetAppNetAppAccountsCapacityPoolsVolumeGenericResource]: {
                         text: {
                             withServiceName: [
                                "volumes resource type",
                                "volumes resource types"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volumes Resource Type",
                                "Volumes Resource Types"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetAppNetAppAccountsSnapshotPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "snapshot policies resource type",
                                "snapshot policies resource types"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Snapshot Policies Resource Type",
                                "Snapshot Policies Resource Types"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkApplicationGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "application gateway",
                                "application gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Gateway",
                                "Application Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkApplicationGatewayWebApplicationFirewallPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "application gateway waf policy",
                                "application gateway waf policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Gateway WAF Policy",
                                "Application Gateway WAF Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkApplicationSecurityGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "application security group",
                                "application security groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Security Group",
                                "Application Security Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkAzurefirewallGenericResource]: {
                         text: {
                             withServiceName: [
                                "firewall",
                                "firewalls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Firewall",
                                "Firewalls"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkBastionHostGenericResource]: {
                         text: {
                             withServiceName: [
                                "bastion host",
                                "bastion hosts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bastion Host",
                                "Bastion Hosts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudBareMetalMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "bare metal machine",
                                "bare metal machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Bare Metal Machine",
                                "Bare Metal Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudCloudServicesNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud services network",
                                "cloud services networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Services Network",
                                "Cloud Services Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster",
                                "clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster",
                                "Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudClusterManagerGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster manager",
                                "cluster managers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster Manager",
                                "Cluster Managers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudClustersBareMetalMachineKeySetGenericResource]: {
                         text: {
                             withServiceName: [
                                "clusters bare metal machine key set",
                                "clusters bare metal machine key sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Clusters Bare Metal Machine Key Set",
                                "Clusters Bare Metal Machine Key Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudClustersBmcKeySetGenericResource]: {
                         text: {
                             withServiceName: [
                                "clusters bmc key set",
                                "clusters bmc key sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Clusters Bmc Key Set",
                                "Clusters Bmc Key Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudClustersMetricsConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "clusters metrics configuration",
                                "clusters metrics configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Clusters Metrics Configuration",
                                "Clusters Metrics Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudEdgeClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "edge cluster",
                                "edge clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edge Cluster",
                                "Edge Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudEdgeClustersNodeGenericResource]: {
                         text: {
                             withServiceName: [
                                "edge clusters node",
                                "edge clusters nodes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edge Clusters Node",
                                "Edge Clusters Nodes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudKubernetesClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "kubernetes cluster",
                                "kubernetes clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kubernetes Cluster",
                                "Kubernetes Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudKubernetesClustersAgentPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "kubernetes clusters agent pool",
                                "kubernetes clusters agent pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kubernetes Clusters Agent Pool",
                                "Kubernetes Clusters Agent Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudKubernetesClustersFeatureGenericResource]: {
                         text: {
                             withServiceName: [
                                "kubernetes clusters feature",
                                "kubernetes clusters features"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kubernetes Clusters Feature",
                                "Kubernetes Clusters Features"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudL2NetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "l 2 network",
                                "l 2 networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "L 2 Network",
                                "L 2 Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudL3NetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "l 3 network",
                                "l 3 networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "L 3 Network",
                                "L 3 Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudRackGenericResource]: {
                         text: {
                             withServiceName: [
                                "rack",
                                "racks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Rack",
                                "Racks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudRegistrationHubGenericResource]: {
                         text: {
                             withServiceName: [
                                "registration hub",
                                "registration hubs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registration Hub",
                                "Registration Hubs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudRegistrationHubsImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "registration hubs image",
                                "registration hubs images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registration Hubs Image",
                                "Registration Hubs Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudRegistrationHubsMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "registration hubs machine",
                                "registration hubs machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registration Hubs Machine",
                                "Registration Hubs Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudStorageApplianceGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage appliance",
                                "storage appliances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Appliance",
                                "Storage Appliances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudTrunkedNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "trunked network",
                                "trunked networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Trunked Network",
                                "Trunked Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudVirtualMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machine",
                                "virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machine",
                                "Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudVirtualMachinesConsoleGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machines console",
                                "virtual machines consoles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machines Console",
                                "Virtual Machines Consoles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCloudVolumeGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume",
                                "volumes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume",
                                "Volumes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual network gateway connection",
                                "virtual network gateway connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Network Gateway Connection",
                                "Virtual Network Gateway Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkCustomIpPrefixGenericResource]: {
                         text: {
                             withServiceName: [
                                "custom ip prefix",
                                "custom ip prefixes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Custom Ip Prefix",
                                "Custom Ip Prefixes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDdosCustomPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "ddos custom policy",
                                "ddos custom policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DDoS Custom Policy",
                                "DDoS Custom Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDdosProtectionPlanGenericResource]: {
                         text: {
                             withServiceName: [
                                "ddos protection plan",
                                "ddos protection plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DDoS Protection Plan",
                                "DDoS Protection Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnsForwardingRulesetGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns forwarding ruleset",
                                "dns forwarding rulesets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DNS Forwarding Ruleset",
                                "DNS Forwarding Rulesets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnsResolverDomainListGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns resolver domain list",
                                "dns resolver domain lists"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns Resolver Domain List",
                                "Dns Resolver Domain Lists"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnsResolverGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns resolver",
                                "dns resolvers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DNS Resolver",
                                "DNS Resolvers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnsResolverPoliciesDnsSecurityRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns resolver policies dns security rule",
                                "dns resolver policies dns security rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns Resolver Policies Dns Security Rule",
                                "Dns Resolver Policies Dns Security Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnsResolverPoliciesVirtualNetworkLinkGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns resolver policies virtual network link",
                                "dns resolver policies virtual network links"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns Resolver Policies Virtual Network Link",
                                "Dns Resolver Policies Virtual Network Links"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnsResolverPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns resolver policy",
                                "dns resolver policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dns Resolver Policy",
                                "Dns Resolver Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnsResolversInboundEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns resolver inbound endpoint",
                                "dns resolver inbound endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DNS Resolver Inbound Endpoint",
                                "DNS Resolver Inbound Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnsResolversOutboundEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns resolver outbound endpoint",
                                "dns resolver outbound endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DNS Resolver Outbound Endpoint",
                                "DNS Resolver Outbound Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnszoneGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns zone",
                                "dns zones"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DNS Zone",
                                "DNS Zones"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnszonesAGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns a record set",
                                "dns a record sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DNS A Record Set",
                                "DNS A Record Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDnszonesCnameGenericResource]: {
                         text: {
                             withServiceName: [
                                "dns cname record set",
                                "dns cname record sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "DNS CNAME Record Set",
                                "DNS CNAME Record Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkDscpConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "dscp configuration",
                                "dscp configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dscp Configuration",
                                "Dscp Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkExpressRouteCircuitGenericResource]: {
                         text: {
                             withServiceName: [
                                "express route circuit",
                                "express route circuits"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Express Route Circuit",
                                "Express Route Circuits"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkExpressRouteCrossConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "express route cross connection",
                                "express route cross connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Express Route Cross Connection",
                                "Express Route Cross Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkExpressRouteGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "express route gateway",
                                "express route gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Express Route Gateway",
                                "Express Route Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkExpressRoutePortGenericResource]: {
                         text: {
                             withServiceName: [
                                "express route port",
                                "express route ports"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Express Route Port",
                                "Express Route Ports"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkFirewallPoliciesRuleGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "firewall policy rule group",
                                "firewall policy rule groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Firewall Policy Rule Group",
                                "Firewall Policy Rule Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkFirewallPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "firewall policy",
                                "firewall policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Firewall Policy",
                                "Firewall Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkFrontDoorGenericResource]: {
                         text: {
                             withServiceName: [
                                "front door",
                                "front doors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Front Door",
                                "Front Doors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "waf policy",
                                "waf policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "WAF Policy",
                                "WAF Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkFunctionAzureTrafficCollectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "traffic collector",
                                "traffic collectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Traffic Collector",
                                "Traffic Collectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkFunctionAzureTrafficCollectorsCollectorPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "collector policy",
                                "collector policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Collector Policy",
                                "Collector Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkFunctionMeshVpnGenericResource]: {
                         text: {
                             withServiceName: [
                                "mesh vpn",
                                "mesh vpns"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mesh Vpn",
                                "Mesh Vpns"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkFunctionVpnBranchGenericResource]: {
                         text: {
                             withServiceName: [
                                "vpn branch",
                                "vpn branches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vpn Branch",
                                "Vpn Branches"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkGatewayLoadBalancerAliasGenericResource]: {
                         text: {
                             withServiceName: [
                                "gateway load balancer alias",
                                "gateway load balancer aliases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gateway Load Balancer Alias",
                                "Gateway Load Balancer Aliases"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkIpAllocationGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip allocation",
                                "ip allocations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ip Allocation",
                                "Ip Allocations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkIpGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "ip group",
                                "ip groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IP Group",
                                "IP Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkLoadBalancerGenericResource]: {
                         text: {
                             withServiceName: [
                                "load balancer",
                                "load balancers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Load Balancer",
                                "Load Balancers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkLocalnetworkgatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "local network gateway",
                                "local network gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Local Network Gateway",
                                "Local Network Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNatGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "nat gateway",
                                "nat gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "NAT Gateway",
                                "NAT Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkExperimentProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "internet analyzer profile",
                                "internet analyzer profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Internet Analyzer Profile",
                                "Internet Analyzer Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkIntentPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "network intent policy",
                                "network intent policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Intent Policy",
                                "Network Intent Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkInterfaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "network interface",
                                "network interfaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Interface",
                                "Network Interfaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkManagerGenericResource]: {
                         text: {
                             withServiceName: [
                                "network manager",
                                "network managers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Manager",
                                "Network Managers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkManagersIpamPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "ipam pool",
                                "ipam pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ipam Pool",
                                "Ipam Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkManagersVerifierWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "verifier workspace",
                                "verifier workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Verifier Workspace",
                                "Verifier Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "network profile",
                                "network profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Profile",
                                "Network Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "network security group",
                                "network security groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Security Group",
                                "Network Security Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkSecurityPerimeterGenericResource]: {
                         text: {
                             withServiceName: [
                                "network security perimeter",
                                "network security perimeters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Security Perimeter",
                                "Network Security Perimeters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkVerifierGenericResource]: {
                         text: {
                             withServiceName: [
                                "network verifier",
                                "network verifiers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Verifier",
                                "Network Verifiers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkVirtualApplianceGenericResource]: {
                         text: {
                             withServiceName: [
                                "network virtual appliance",
                                "network virtual appliances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Virtual Appliance",
                                "Network Virtual Appliances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkWatcherGenericResource]: {
                         text: {
                             withServiceName: [
                                "network watcher",
                                "network watchers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Watcher",
                                "Network Watchers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkWatchersConnectionMonitorGenericResource]: {
                         text: {
                             withServiceName: [
                                "connection monitor",
                                "connection monitors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connection Monitor",
                                "Connection Monitors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkWatchersFlowLogGenericResource]: {
                         text: {
                             withServiceName: [
                                "flow log",
                                "flow logs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Flow Log",
                                "Flow Logs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkWatchersLensGenericResource]: {
                         text: {
                             withServiceName: [
                                "lens",
                                "lenses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Lens",
                                "Lenses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkWatchersPingMeshGenericResource]: {
                         text: {
                             withServiceName: [
                                "ping mesh",
                                "ping meshes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ping Mesh",
                                "Ping Meshes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkP2sVpnGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "p2s vpn gateway",
                                "p2s vpn gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "P2S Vpn Gateway",
                                "P2S Vpn Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneGenericResource]: {
                         text: {
                             withServiceName: [
                                "private dns zone",
                                "private dns zones"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private DNS Zone",
                                "Private DNS Zones"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZonesVirtualNetworkLinkGenericResource]: {
                         text: {
                             withServiceName: [
                                "private dns zone link to virtual network",
                                "private dns zone link to virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private DNS Zone Link to Virtual Network",
                                "Private DNS Zone Link to Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "private endpoint",
                                "private endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Endpoint",
                                "Private Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateEndpointRedirectMapGenericResource]: {
                         text: {
                             withServiceName: [
                                "private endpoint redirect map",
                                "private endpoint redirect maps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Endpoint Redirect Map",
                                "Private Endpoint Redirect Maps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateLinkServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "private link service",
                                "private link services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Link Service",
                                "Private Link Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkPublicIpAddressGenericResource]: {
                         text: {
                             withServiceName: [
                                "public ip address",
                                "public ip addresses"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Public Ip Address",
                                "Public Ip Addresses"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkPublicIpPrefixGenericResource]: {
                         text: {
                             withServiceName: [
                                "public ip prefix",
                                "public ip prefixes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Public IP Prefix",
                                "Public IP Prefixes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkRouteFilterGenericResource]: {
                         text: {
                             withServiceName: [
                                "route filter",
                                "route filters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Route Filter",
                                "Route Filters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkRouteTableGenericResource]: {
                         text: {
                             withServiceName: [
                                "route table",
                                "route tables"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Route Table",
                                "Route Tables"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkSecurityPartnerProviderGenericResource]: {
                         text: {
                             withServiceName: [
                                "security partner provider",
                                "security partner providers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Security Partner Provider",
                                "Security Partner Providers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkServiceEndpointPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "service endpoint policy",
                                "service endpoint policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Endpoint Policy",
                                "Service Endpoint Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkTrafficManagerProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "traffic manager profile",
                                "traffic manager profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Traffic Manager Profile",
                                "Traffic Manager Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualHubGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual hub",
                                "virtual hubs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Hub",
                                "Virtual Hubs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualHubsBgpConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual hub bgp connection",
                                "virtual hub bgp connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Hub BGP Connection",
                                "Virtual Hub BGP Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualHubsIpConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual hub ip configuration",
                                "virtual hub ip configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Hub IP Configuration",
                                "Virtual Hub IP Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual network",
                                "virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Network",
                                "Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualNetworkTapGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual network tap",
                                "virtual network taps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Network Tap",
                                "Virtual Network Taps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualRouterGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual router",
                                "virtual routers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Router",
                                "Virtual Routers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualWanGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual wan",
                                "virtual wans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual WAN",
                                "Virtual WANs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualnetworkgatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual network gateway",
                                "virtual network gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Network Gateway",
                                "Virtual Network Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVpnGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "vpn gateway",
                                "vpn gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VPN Gateway",
                                "VPN Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVpnServerConfigurationGenericResource]: {
                         text: {
                             withServiceName: [
                                "vpn server configuration",
                                "vpn server configurations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vpn Server Configuration",
                                "Vpn Server Configurations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNetworkVpnsiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "vpn site",
                                "vpn sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VPN Site",
                                "VPN Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNewRelicObservabilityMonitorGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitor",
                                "monitors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitor",
                                "Monitors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNexusIdentityIdentityControllerGenericResource]: {
                         text: {
                             withServiceName: [
                                "identity controller",
                                "identity controllers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Identity Controller",
                                "Identity Controllers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNexusIdentityIdentitySetGenericResource]: {
                         text: {
                             withServiceName: [
                                "identity set",
                                "identity sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Identity Set",
                                "Identity Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNginxNginxplusNginxDeploymentGenericResource]: {
                         text: {
                             withServiceName: [
                                "nginx deployment",
                                "nginx deployments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Nginx Deployment",
                                "Nginx Deployments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNotificationHubsNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "notification hub",
                                "notification hubs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Notification Hub",
                                "Notification Hubs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNotificationHubsNamespacesNotificationHubGenericResource]: {
                         text: {
                             withServiceName: [
                                "notification hub",
                                "notification hubs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Notification Hub",
                                "Notification Hubs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNutanixInterfaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "interface",
                                "interfaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Interface",
                                "Interfaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureNutanixNodeGenericResource]: {
                         text: {
                             withServiceName: [
                                "node",
                                "nodes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Node",
                                "Nodes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureObjectStoreOsNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "os namespace",
                                "os namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Os Namespace",
                                "Os Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOffApplianceGenericResource]: {
                         text: {
                             withServiceName: [
                                "appliance",
                                "appliances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Appliance",
                                "Appliances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOffHypervSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "hyperv site",
                                "hyperv sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "HyperV Site",
                                "HyperV Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOffImportSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "import site",
                                "import sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Import Site",
                                "Import Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOffMasterSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "master site",
                                "master sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Master Site",
                                "Master Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOffServerSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "server site",
                                "server sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Server Site",
                                "Server Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOffSpringBootSpringbootsiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "springbootsite",
                                "springbootsites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Springbootsite",
                                "Springbootsites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOffVmwareSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware site",
                                "vmware sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware Site",
                                "VMware Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOpenEnergyPlatformEnergyServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "energy service",
                                "energy services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Energy Service",
                                "Energy Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOperationalInsightsClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster",
                                "clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster",
                                "Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOperationalInsightsQuerypackGenericResource]: {
                         text: {
                             withServiceName: [
                                "query pack",
                                "query packs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Query Pack",
                                "Query Packs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOperationalInsightsWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "workspace",
                                "workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workspace",
                                "Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOperationsManagementSolutionGenericResource]: {
                         text: {
                             withServiceName: [
                                "solution",
                                "solutions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Solution",
                                "Solutions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOperationsManagementViewGenericResource]: {
                         text: {
                             withServiceName: [
                                "view",
                                "views"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "View",
                                "Views"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOracleDatabaseAutonomousDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "autonomous database",
                                "autonomous databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Autonomous Database",
                                "Autonomous Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOracleDatabaseCloudExadataInfrastructureGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud exadata infrastructure",
                                "cloud exadata infrastructures"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Exadata Infrastructure",
                                "Cloud Exadata Infrastructures"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOracleDatabaseCloudVmClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud vm cluster",
                                "cloud vm clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Vm Cluster",
                                "Cloud Vm Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOracleDiscoveryOracleSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "oracle site",
                                "oracle sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oracle Site",
                                "Oracle Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalCloudAccessRouterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud access router",
                                "cloud access routers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Access Router",
                                "Cloud Access Routers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalContactProfileGenericResource]: {
                         text: {
                             withServiceName: [
                                "contact profile",
                                "contact profiles"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Contact Profile",
                                "Contact Profiles"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalEdgeSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "edge site",
                                "edge sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edge Site",
                                "Edge Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalGeoCatalogGenericResource]: {
                         text: {
                             withServiceName: [
                                "geo catalog",
                                "geo catalogs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Geo Catalog",
                                "Geo Catalogs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalGroundStationGenericResource]: {
                         text: {
                             withServiceName: [
                                "ground station",
                                "ground stations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Ground Station",
                                "Ground Stations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalL2ConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "l 2 connection",
                                "l 2 connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "L 2 Connection",
                                "L 2 Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalSdwanControllerGenericResource]: {
                         text: {
                             withServiceName: [
                                "sdwan controller",
                                "sdwan controllers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sdwan Controller",
                                "Sdwan Controllers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalSpacecraftGenericResource]: {
                         text: {
                             withServiceName: [
                                "spacecraft",
                                "spacecrafts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Spacecraft",
                                "Spacecrafts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureOrbitalTerminalGenericResource]: {
                         text: {
                             withServiceName: [
                                "terminal",
                                "terminals"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Terminal",
                                "Terminals"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePaloAltoNetworksCloudngfwFirewallGenericResource]: {
                         text: {
                             withServiceName: [
                                "firewall",
                                "firewalls"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Firewall",
                                "Firewalls"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePaloAltoNetworksCloudngfwLocalRulestackGenericResource]: {
                         text: {
                             withServiceName: [
                                "local rulestack",
                                "local rulestacks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Local Rulestack",
                                "Local Rulestacks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePeeringPeeringGenericResource]: {
                         text: {
                             withServiceName: [
                                "peering",
                                "peerings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Peering",
                                "Peerings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePeeringPeeringServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "peering service",
                                "peering services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Peering Service",
                                "Peering Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePkiPkiGenericResource]: {
                         text: {
                             withServiceName: [
                                "pki",
                                "pkis"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Pki",
                                "Pkis"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePlaywrightServiceAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePortalDashboardGenericResource]: {
                         text: {
                             withServiceName: [
                                "dashboard",
                                "dashboards"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Dashboard",
                                "Dashboards"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePowerBiDedicatedAutoScaleVCoreGenericResource]: {
                         text: {
                             withServiceName: [
                                "autoscale vcore",
                                "autoscale vcores"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "AutoScale VCore",
                                "AutoScale VCores"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePowerBiDedicatedCapacityGenericResource]: {
                         text: {
                             withServiceName: [
                                "capacity",
                                "capacities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Capacity",
                                "Capacities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePowerBiPrivateLinkServicesForPowerBiGenericResource]: {
                         text: {
                             withServiceName: [
                                "private link service resource",
                                "private link service resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Link Service Resource",
                                "Private Link Service Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePowerBiTenantGenericResource]: {
                         text: {
                             withServiceName: [
                                "tenant",
                                "tenants"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Tenant",
                                "Tenants"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePowerBiWorkspaceCollectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "power bi workspace collection",
                                "power bi workspace collections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Power BI Workspace Collection",
                                "Power BI Workspace Collections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePowerPlatformAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePowerPlatformEnterprisePolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "enterprise policy",
                                "enterprise policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Enterprise Policy",
                                "Enterprise Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureProfessionalServiceResourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "professional service resource",
                                "professional service resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Professional Service Resource",
                                "Professional Service Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureProgrammableConnectivityGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "gateway",
                                "gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gateway",
                                "Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureProgrammableConnectivityOperatorApiConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "operator api connection",
                                "operator api connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Operator Api Connection",
                                "Operator Api Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureProgrammableConnectivityOperatorConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "operator connection",
                                "operator connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Operator Connection",
                                "Operator Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureProviderHubProviderMonitorSettingGenericResource]: {
                         text: {
                             withServiceName: [
                                "provider monitor setting",
                                "provider monitor settings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Provider Monitor Setting",
                                "Provider Monitor Settings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzurePurviewAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureQuantumWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "quantum workspace",
                                "quantum workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Quantum Workspace",
                                "Quantum Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureQumuloStorageFileSystemGenericResource]: {
                         text: {
                             withServiceName: [
                                "file system",
                                "file systems"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "File System",
                                "File Systems"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureRecommendationsServiceAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "recommendations service",
                                "recommendations services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Recommendations Service",
                                "Recommendations Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureRecommendationsServiceAccountsModelingGenericResource]: {
                         text: {
                             withServiceName: [
                                "accounts modeling",
                                "accounts modelings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Accounts Modeling",
                                "Accounts Modelings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureRecommendationsServiceAccountsServiceEndpointGenericResource]: {
                         text: {
                             withServiceName: [
                                "accounts service endpoint",
                                "accounts service endpoints"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Accounts Service Endpoint",
                                "Accounts Service Endpoints"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureRecoveryServicesVaultGenericResource]: {
                         text: {
                             withServiceName: [
                                "recovery service vault",
                                "recovery service vaults"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Recovery Service Vault",
                                "Recovery Service Vaults"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureRedHatOpenShiftOpenShiftClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "red hat openshift cluster",
                                "red hat openshift clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Red Hat OpenShift Cluster",
                                "Red Hat OpenShift Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureRelayNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "namespace",
                                "namespaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Namespace",
                                "Namespaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureResourceConnectorApplianceGenericResource]: {
                         text: {
                             withServiceName: [
                                "appliance",
                                "appliances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Appliance",
                                "Appliances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureResourceGraphQueryGenericResource]: {
                         text: {
                             withServiceName: [
                                "resource graph query",
                                "resource graph queries"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Resource Graph Query",
                                "Resource Graph Queries"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureResourceManagerDeploymentScriptGenericResource]: {
                         text: {
                             withServiceName: [
                                "deployment script",
                                "deployment scripts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Deployment Script",
                                "Deployment Scripts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureResourceManagerMoboBrokerGenericResource]: {
                         text: {
                             withServiceName: [
                                "mobo broker",
                                "mobo brokers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Mobo Broker",
                                "Mobo Brokers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureResourceManagerTemplateSpecGenericResource]: {
                         text: {
                             withServiceName: [
                                "template spec",
                                "template specs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Template Spec",
                                "Template Specs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureResourceManagerTemplateSpecsVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "template spec version",
                                "template spec versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Template Spec Version",
                                "Template Spec Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSaaSApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "saa s application",
                                "saa s applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Saa S Application",
                                "Saa S Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSaaSHubCloudServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud service",
                                "cloud services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Service",
                                "Cloud Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSaaSResourceGenericResource]: {
                         text: {
                             withServiceName: [
                                "saas resource",
                                "saas resources"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SaaS Resource",
                                "SaaS Resources"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScanScanningAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "scanning account",
                                "scanning accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Scanning Account",
                                "Scanning Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScomManagedInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed instance",
                                "managed instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed Instance",
                                "Managed Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScvmmAvailabilitySetGenericResource]: {
                         text: {
                             withServiceName: [
                                "availability set",
                                "availability sets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Availability Set",
                                "Availability Sets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScvmmCloudGenericResource]: {
                         text: {
                             withServiceName: [
                                "scvmm cloud",
                                "scvmm clouds"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "ScVmm Cloud",
                                "ScVmm Clouds"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScvmmVirtualMachinesExtensionGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machines extension",
                                "virtual machines extensions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machines Extension",
                                "Virtual Machines Extensions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScvmmVirtualmachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "scvmm virtual machine",
                                "scvmm virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "ScVmm Virtual Machine",
                                "ScVmm Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScvmmVirtualmachinetemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "scvmm virtual machine template",
                                "scvmm virtual machine templates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "ScVmm Virtual Machine Template",
                                "ScVmm Virtual Machine Templates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScvmmVirtualnetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "scvmm virtual network",
                                "scvmm virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "ScVmm Virtual Network",
                                "ScVmm Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureScvmmVmmserverGenericResource]: {
                         text: {
                             withServiceName: [
                                "scvmm server",
                                "scvmm servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "ScVmm Server",
                                "ScVmm Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSearchSearchServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "search service",
                                "search services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Search Service",
                                "Search Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecretManagementSampleProviderForecastGenericResource]: {
                         text: {
                             withServiceName: [
                                "forecast",
                                "forecasts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Forecast",
                                "Forecasts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecretSyncControllerAzureKeyVaultSecretProviderClassGenericResource]: {
                         text: {
                             withServiceName: [
                                "key vault secret provider class",
                                "key vault secret provider classes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Key Vault Secret Provider Class",
                                "Key Vault Secret Provider Classes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecretSyncControllerSecretSyncGenericResource]: {
                         text: {
                             withServiceName: [
                                "secret sync",
                                "secret syncs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Secret Sync",
                                "Secret Syncs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityAssignmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "secrity assignment",
                                "secrity assignments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Secrity Assignment",
                                "Secrity Assignments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityAutomationGenericResource]: {
                         text: {
                             withServiceName: [
                                "automation",
                                "automations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Automation",
                                "Automations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityCopilotCapacityGenericResource]: {
                         text: {
                             withServiceName: [
                                "capacity",
                                "capacities"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Capacity",
                                "Capacities"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityCustomAssessmentAutomationGenericResource]: {
                         text: {
                             withServiceName: [
                                "custom assessment automation",
                                "custom assessment automations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Custom Assessment Automation",
                                "Custom Assessment Automations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityDataScannerGenericResource]: {
                         text: {
                             withServiceName: [
                                "datascanner",
                                "datascanners"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Datascanner",
                                "Datascanners"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityDetonationChamberGenericResource]: {
                         text: {
                             withServiceName: [
                                "security detonation chamber",
                                "security detonation chambers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Security Detonation Chamber",
                                "Security Detonation Chambers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityIotSecuritySolutionGenericResource]: {
                         text: {
                             withServiceName: [
                                "iot security solution",
                                "iot security solutions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "IoT Security Solution",
                                "IoT Security Solutions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityPlatformAccountLinkGenericResource]: {
                         text: {
                             withServiceName: [
                                "account link",
                                "account links"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account Link",
                                "Account Links"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecuritySecurityConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "security connector",
                                "security connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Security Connector",
                                "Security Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSecurityStandardGenericResource]: {
                         text: {
                             withServiceName: [
                                "secrity standard",
                                "secrity standards"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Secrity Standard",
                                "Secrity Standards"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceBusNamespaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "service bus",
                                "service bus"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Bus",
                                "Service Bus"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceFabricClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "service fabric cluster",
                                "service fabric clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Fabric Cluster",
                                "Service Fabric Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceFabricManagedclusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "service fabric managed cluster",
                                "service fabric managed clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Fabric Managed Cluster",
                                "Service Fabric Managed Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceFabricMeshApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "application",
                                "applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application",
                                "Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceFabricMeshGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "gateway",
                                "gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gateway",
                                "Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceFabricMeshNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "network",
                                "networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network",
                                "Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceFabricMeshSecretGenericResource]: {
                         text: {
                             withServiceName: [
                                "secret",
                                "secrets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Secret",
                                "Secrets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceFabricMeshVolumeGenericResource]: {
                         text: {
                             withServiceName: [
                                "volume",
                                "volumes"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Volume",
                                "Volumes"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceNetworkingTrafficControllerGenericResource]: {
                         text: {
                             withServiceName: [
                                "application gateway for container",
                                "application gateway for containers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Gateway for Container",
                                "Application Gateway for Containers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceNetworkingTrafficControllersAssociationGenericResource]: {
                         text: {
                             withServiceName: [
                                "application gateway for containers association",
                                "application gateway for containers associations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Gateway for Containers Association",
                                "Application Gateway for Containers Associations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceNetworkingTrafficControllersFrontendGenericResource]: {
                         text: {
                             withServiceName: [
                                "application gateway for containers frontend",
                                "application gateway for containers frontends"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Gateway for Containers Frontend",
                                "Application Gateway for Containers Frontends"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServiceNetworkingTrafficControllersSecurityPolicyGenericResource]: {
                         text: {
                             withServiceName: [
                                "traffic controllers security policy",
                                "traffic controllers security policies"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Traffic Controllers Security Policy",
                                "Traffic Controllers Security Policies"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureServicesHubConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "service hub connector",
                                "service hub connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Service Hub Connector",
                                "Service Hub Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSignalRServiceSignalRGenericResource]: {
                         text: {
                             withServiceName: [
                                "signalr service",
                                "signalr services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SignalR Service",
                                "SignalR Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSignalRServiceSignalRReplicaGenericResource]: {
                         text: {
                             withServiceName: [
                                "signal r replica",
                                "signal r replicas"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Signal R Replica",
                                "Signal R Replicas"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSignalRServiceWebPubSubGenericResource]: {
                         text: {
                             withServiceName: [
                                "web pub sub",
                                "web pub subs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web Pub Sub",
                                "Web Pub Subs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSignalRServiceWebPubSubReplicaGenericResource]: {
                         text: {
                             withServiceName: [
                                "web pub sub replica",
                                "web pub sub replicas"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web Pub Sub Replica",
                                "Web Pub Sub Replicas"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSingularityAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "singularity account",
                                "singularity accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Singularity Account",
                                "Singularity Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSoftwarePlanSoftwareSubscriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "software subscription",
                                "software subscriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Software Subscription",
                                "Software Subscriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSolarWindsObservabilityOrganizationGenericResource]: {
                         text: {
                             withServiceName: [
                                "organization",
                                "organizations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Organization",
                                "Organizations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSolutionsApplicationDefinitionGenericResource]: {
                         text: {
                             withServiceName: [
                                "application definition",
                                "application definitions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application Definition",
                                "Application Definitions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSolutionsApplicationGenericResource]: {
                         text: {
                             withServiceName: [
                                "application",
                                "applications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Application",
                                "Applications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSolutionsJitRequestGenericResource]: {
                         text: {
                             withServiceName: [
                                "jit request",
                                "jit requests"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Jit Request",
                                "Jit Requests"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSovereignLandingZoneAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "landing zone account",
                                "landing zone accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Landing Zone Account",
                                "Landing Zone Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSphereCatalogGenericResource]: {
                         text: {
                             withServiceName: [
                                "catalog",
                                "catalogs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Catalog",
                                "Catalogs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSphereCatalogsProductGenericResource]: {
                         text: {
                             withServiceName: [
                                "catalogs product",
                                "catalogs products"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Catalogs Product",
                                "Catalogs Products"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlInstancePoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance pool",
                                "instance pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance Pool",
                                "Instance Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlManagedInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql managed instance",
                                "sql managed instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Managed Instance",
                                "SQL Managed Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlManagedInstancesDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql managed database",
                                "sql managed databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Managed Database",
                                "SQL Managed Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlServerGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql server",
                                "sql servers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Server",
                                "SQL Servers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlServersDatabaseGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql database",
                                "sql databases"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Database",
                                "SQL Databases"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlServersElasticPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql database elastic pool",
                                "sql database elastic pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Database Elastic Pool",
                                "SQL Database Elastic Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlServersJobAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "servers job account",
                                "servers job accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Servers Job Account",
                                "Servers Job Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlServersJobAgentGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql db job agent",
                                "sql db job agents"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL DB Job Agent",
                                "SQL DB Job Agents"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlVirtualClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql virtual cluster",
                                "sql virtual clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sql Virtual Cluster",
                                "Sql Virtual Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlVirtualMachineSqlVirtualMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql virtual machine",
                                "sql virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Virtual Machine",
                                "SQL Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSqlVirtualMachineSqlVirtualMachineGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql virtual machine group",
                                "sql virtual machine groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Virtual Machine Group",
                                "SQL Virtual Machine Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "cluster",
                                "clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cluster",
                                "Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciEdgeNodePoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "edge node pool",
                                "edge node pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Edge Node Pool",
                                "Edge Node Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciGalleryImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "gallery image",
                                "gallery images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Gallery Image",
                                "Gallery Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciLogicalNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "logical network",
                                "logical networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Logical Network",
                                "Logical Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciMarketplaceGalleryImageGenericResource]: {
                         text: {
                             withServiceName: [
                                "market place gallery image",
                                "market place gallery images"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Market Place Gallery Image",
                                "Market Place Gallery Images"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciNetworkInterfaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "network interface",
                                "network interfaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Interface",
                                "Network Interfaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciNetworkSecurityGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "network security group",
                                "network security groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Network Security Group",
                                "Network Security Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciStorageContainerGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage container",
                                "storage containers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Container",
                                "Storage Containers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciVirtualHardDiskGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual hard disk",
                                "virtual hard disks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Hard Disk",
                                "Virtual Hard Disks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciVirtualMachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machine",
                                "virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machine",
                                "Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciVirtualMachinesExtensionGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual machines extension",
                                "virtual machines extensions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Machines Extension",
                                "Virtual Machines Extensions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackHciVirtualNetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtual network",
                                "virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtual Network",
                                "Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackLinkedSubscriptionGenericResource]: {
                         text: {
                             withServiceName: [
                                "stack linked subscription",
                                "stack linked subscriptions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stack Linked Subscription",
                                "Stack Linked Subscriptions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStackRegistrationGenericResource]: {
                         text: {
                             withServiceName: [
                                "stack registration",
                                "stack registrations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stack Registration",
                                "Stack Registrations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStandbyPoolStandbyContainerGroupPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "standby container group pool",
                                "standby container group pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Standby Container Group Pool",
                                "Standby Container Group Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStandbyPoolStandbyVirtualMachinePoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "standby virtual machine pool",
                                "standby virtual machine pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Standby Virtual Machine Pool",
                                "Standby Virtual Machine Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStorageActionsStorageTaskGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage task",
                                "storage tasks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Task",
                                "Storage Tasks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStorageCacheAmlFilesystemGenericResource]: {
                         text: {
                             withServiceName: [
                                "aml filesystem",
                                "aml filesystems"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Aml Filesystem",
                                "Aml Filesystems"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStorageCacheCacheGenericResource]: {
                         text: {
                             withServiceName: [
                                "cache",
                                "caches"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cache",
                                "Caches"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStorageMoverStorageMoverGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage mover",
                                "storage movers"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Mover",
                                "Storage Movers"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStorageStorageAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage account",
                                "storage accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Account",
                                "Storage Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStorageStorageTaskGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage task",
                                "storage tasks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Task",
                                "Storage Tasks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStorageSyncStorageSyncServiceGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage sync service",
                                "storage sync services"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Sync Service",
                                "Storage Sync Services"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStorageTasksStorageTaskGenericResource]: {
                         text: {
                             withServiceName: [
                                "storage task",
                                "storage tasks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Storage Task",
                                "Storage Tasks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStreamAnalyticsClusterGenericResource]: {
                         text: {
                             withServiceName: [
                                "stream analytics cluster",
                                "stream analytics clusters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stream Analytics Cluster",
                                "Stream Analytics Clusters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureStreamAnalyticsStreamingJobGenericResource]: {
                         text: {
                             withServiceName: [
                                "stream analytics job",
                                "stream analytics jobs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Stream Analytics Job",
                                "Stream Analytics Jobs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSustainabilityServicesCalculationGenericResource]: {
                         text: {
                             withServiceName: [
                                "calculation",
                                "calculations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Calculation",
                                "Calculations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSynapsePrivateLinkHubGenericResource]: {
                         text: {
                             withServiceName: [
                                "private link hub",
                                "private link hubs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Private Link Hub",
                                "Private Link Hubs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSynapseWorkspaceGenericResource]: {
                         text: {
                             withServiceName: [
                                "synapse workspace",
                                "synapse workspaces"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Synapse Workspace",
                                "Synapse Workspaces"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSynapseWorkspacesBigDataPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "spark pool",
                                "spark pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Spark Pool",
                                "Spark Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSynapseWorkspacesKustoPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "clusters resource type",
                                "clusters resource types"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Clusters Resource Type",
                                "Clusters Resource Types"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSynapseWorkspacesSqlPoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "sql analytics pool",
                                "sql analytics pools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "SQL Analytics Pool",
                                "SQL Analytics Pools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSyntexAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureSyntexDocumentProcessorGenericResource]: {
                         text: {
                             withServiceName: [
                                "document processor",
                                "document processors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Document Processor",
                                "Document Processors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorActivationGenericResource]: {
                         text: {
                             withServiceName: [
                                "activation",
                                "activations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Activation",
                                "Activations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorCampaignGenericResource]: {
                         text: {
                             withServiceName: [
                                "campaign",
                                "campaigns"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Campaign",
                                "Campaigns"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorCampaignsVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "campaigns version",
                                "campaigns versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Campaigns Version",
                                "Campaigns Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorCatalogGenericResource]: {
                         text: {
                             withServiceName: [
                                "catalog",
                                "catalogs"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Catalog",
                                "Catalogs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorCatalogsVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "catalogs version",
                                "catalogs versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Catalogs Version",
                                "Catalogs Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorDiagnosticGenericResource]: {
                         text: {
                             withServiceName: [
                                "diagnostic",
                                "diagnostics"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Diagnostic",
                                "Diagnostics"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "instance",
                                "instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instance",
                                "Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorInstancesVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "instances version",
                                "instances versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Instances Version",
                                "Instances Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorSolutionGenericResource]: {
                         text: {
                             withServiceName: [
                                "solution",
                                "solutions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Solution",
                                "Solutions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorSolutionsVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "solutions version",
                                "solutions versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Solutions Version",
                                "Solutions Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "target",
                                "targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Target",
                                "Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureToolchainOrchestratorTargetsVersionGenericResource]: {
                         text: {
                             withServiceName: [
                                "targets version",
                                "targets versions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Targets Version",
                                "Targets Versions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureUsageBillingAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVMwareArczoneGenericResource]: {
                         text: {
                             withServiceName: [
                                "arczone",
                                "arczones"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Arczone",
                                "Arczones"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVMwareResourcepoolGenericResource]: {
                         text: {
                             withServiceName: [
                                "resourcepool",
                                "resourcepools"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Resourcepool",
                                "Resourcepools"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVMwareVcenterGenericResource]: {
                         text: {
                             withServiceName: [
                                "vcenter",
                                "vcenters"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Vcenter",
                                "Vcenters"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVMwareVirtualmachineGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware virtual machine",
                                "vmware virtual machines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware Virtual Machine",
                                "VMware Virtual Machines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVMwareVirtualmachinetemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "virtualmachinetemplate",
                                "virtualmachinetemplates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Virtualmachinetemplate",
                                "Virtualmachinetemplates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVMwareVirtualnetworkGenericResource]: {
                         text: {
                             withServiceName: [
                                "vmware virtual network",
                                "vmware virtual networks"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VMware Virtual Network",
                                "VMware Virtual Networks"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVideoIndexerAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVirtualMachineImagesImageTemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "vm image template",
                                "vm image templates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "VM Image Template",
                                "VM Image Templates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVisualStudioAccountExtensionGenericResource]: {
                         text: {
                             withServiceName: [
                                "account extension",
                                "account extensions"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account Extension",
                                "Account Extensions"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVisualStudioAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "account",
                                "accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVisualStudioAccountProjectGenericResource]: {
                         text: {
                             withServiceName: [
                                "account project",
                                "account projects"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Account Project",
                                "Account Projects"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVoiceServicesCommunicationsGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "communications gateway",
                                "communications gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Communications Gateway",
                                "Communications Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVoiceServicesCommunicationsGatewaysContactGenericResource]: {
                         text: {
                             withServiceName: [
                                "communications gateways contact",
                                "communications gateways contacts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Communications Gateways Contact",
                                "Communications Gateways Contacts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVoiceServicesCommunicationsGatewaysTestLineGenericResource]: {
                         text: {
                             withServiceName: [
                                "communications gateways test line",
                                "communications gateways test lines"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Communications Gateways Test Line",
                                "Communications Gateways Test Lines"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureVoiceServicesOperatorVoicemailInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "operator voicemail instance",
                                "operator voicemail instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Operator Voicemail Instance",
                                "Operator Voicemail Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionFusionGroupGenericResource]: {
                         text: {
                             withServiceName: [
                                "fusion group",
                                "fusion groups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Fusion Group",
                                "Fusion Groups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionFusionGroupsAzureZoneGenericResource]: {
                         text: {
                             withServiceName: [
                                "zone",
                                "zones"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Zone",
                                "Zones"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionFusionGroupsAzureZonesPluginGenericResource]: {
                         text: {
                             withServiceName: [
                                "plugin",
                                "plugins"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Plugin",
                                "Plugins"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionFusionGroupsManagedOnPremZoneGenericResource]: {
                         text: {
                             withServiceName: [
                                "managed on prem zone",
                                "managed on prem zones"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Managed on Prem Zone",
                                "Managed on Prem Zones"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionFusionGroupsReplicationRuleGenericResource]: {
                         text: {
                             withServiceName: [
                                "replication rule",
                                "replication rules"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Replication Rule",
                                "Replication Rules"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorGenericResource]: {
                         text: {
                             withServiceName: [
                                "migrator",
                                "migrators"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migrator",
                                "Migrators"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorsDataTransferAgentGenericResource]: {
                         text: {
                             withServiceName: [
                                "migrators data transfer agent",
                                "migrators data transfer agents"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migrators Data Transfer Agent",
                                "Migrators Data Transfer Agents"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorsExclusionTemplateGenericResource]: {
                         text: {
                             withServiceName: [
                                "exclusion template",
                                "exclusion templates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Exclusion Template",
                                "Exclusion Templates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorsLiveDataMigrationGenericResource]: {
                         text: {
                             withServiceName: [
                                "live data migration",
                                "live data migrations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Live Data Migration",
                                "Live Data Migrations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorsMetadataMigrationGenericResource]: {
                         text: {
                             withServiceName: [
                                "metadata migration",
                                "metadata migrations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Metadata Migration",
                                "Metadata Migrations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorsMetadataTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "metadata target",
                                "metadata targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Metadata Target",
                                "Metadata Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorsPathMappingGenericResource]: {
                         text: {
                             withServiceName: [
                                "path mapping",
                                "path mappings"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Path Mapping",
                                "Path Mappings"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorsTargetGenericResource]: {
                         text: {
                             withServiceName: [
                                "target",
                                "targets"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Target",
                                "Targets"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWandiscoFusionMigratorsVerificationGenericResource]: {
                         text: {
                             withServiceName: [
                                "migrators verification",
                                "migrators verifications"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migrators Verification",
                                "Migrators Verifications"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebApiManagementAccountGenericResource]: {
                         text: {
                             withServiceName: [
                                "api management account",
                                "api management accounts"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Api Management Account",
                                "Api Management Accounts"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebApimanagementaccountsApiGenericResource]: {
                         text: {
                             withServiceName: [
                                "management account api",
                                "management account apis"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Management Account API",
                                "Management Account APIs"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebCertificateGenericResource]: {
                         text: {
                             withServiceName: [
                                "certificate",
                                "certificates"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Certificate",
                                "Certificates"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebConnectionGatewayGenericResource]: {
                         text: {
                             withServiceName: [
                                "connection gateway",
                                "connection gateways"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connection Gateway",
                                "Connection Gateways"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebConnectionGenericResource]: {
                         text: {
                             withServiceName: [
                                "connection",
                                "connections"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connection",
                                "Connections"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebContainerAppGenericResource]: {
                         text: {
                             withServiceName: [
                                "container app",
                                "container apps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Container App",
                                "Container Apps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebCustomApiGenericResource]: {
                         text: {
                             withServiceName: [
                                "custom api",
                                "custom apis"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Custom Api",
                                "Custom Apis"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebHostingEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "app service environment",
                                "app service environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "App Service Environment",
                                "App Service Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebKubeEnvironmentGenericResource]: {
                         text: {
                             withServiceName: [
                                "kubernetes environment",
                                "kubernetes environments"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Kubernetes Environment",
                                "Kubernetes Environments"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebServerfarmGenericResource]: {
                         text: {
                             withServiceName: [
                                "app service plan",
                                "app service plans"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "App Service Plan",
                                "App Service Plans"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "web app",
                                "web apps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web App",
                                "Web Apps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebSitesSlotGenericResource]: {
                         text: {
                             withServiceName: [
                                "web app slot",
                                "web app slots"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web App Slot",
                                "Web App Slots"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebSitesWorkflowGenericResource]: {
                         text: {
                             withServiceName: [
                                "web apps workflow",
                                "web apps workflows"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Web Apps Workflow",
                                "Web Apps Workflows"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebStaticSiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "static site",
                                "static sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Static Site",
                                "Static Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWebWorkerAppGenericResource]: {
                         text: {
                             withServiceName: [
                                "worker app",
                                "worker apps"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Worker App",
                                "Worker Apps"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWindows365CloudPcDelegatedMsiGenericResource]: {
                         text: {
                             withServiceName: [
                                "cloud pc delegated msi",
                                "cloud pc delegated msis"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Cloud Pc Delegated Msi",
                                "Cloud Pc Delegated Msis"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWindowsPushNotificationServicesRegistrationGenericResource]: {
                         text: {
                             withServiceName: [
                                "registration",
                                "registrations"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Registration",
                                "Registrations"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadBuilderMigrationAgentGenericResource]: {
                         text: {
                             withServiceName: [
                                "migration agent",
                                "migration agents"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Migration Agent",
                                "Migration Agents"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadBuilderWorkloadGenericResource]: {
                         text: {
                             withServiceName: [
                                "workload",
                                "workloads"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Workload",
                                "Workloads"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsConnectorGenericResource]: {
                         text: {
                             withServiceName: [
                                "connector",
                                "connectors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connector",
                                "Connectors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsConnectorsAcssBackupGenericResource]: {
                         text: {
                             withServiceName: [
                                "connectors acss backup",
                                "connectors acss backups"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connectors Acss Backup",
                                "Connectors Acss Backups"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsConnectorsAmsInsightGenericResource]: {
                         text: {
                             withServiceName: [
                                "connectors ams insight",
                                "connectors ams insights"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connectors Ams Insight",
                                "Connectors Ams Insights"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsConnectorsSapVirtualInstanceMonitorGenericResource]: {
                         text: {
                             withServiceName: [
                                "connectors sap virtual instance monitor",
                                "connectors sap virtual instance monitors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Connectors Sap Virtual Instance Monitor",
                                "Connectors Sap Virtual Instance Monitors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsEpicVirtualInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "epic virtual instance",
                                "epic virtual instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Epic Virtual Instance",
                                "Epic Virtual Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsEpicVirtualInstancesDatabaseInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "epic virtual instances database instance",
                                "epic virtual instances database instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Epic Virtual Instances Database Instance",
                                "Epic Virtual Instances Database Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsEpicVirtualInstancesHyperspaceWebInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "epic virtual instances hyperspace web instance",
                                "epic virtual instances hyperspace web instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Epic Virtual Instances Hyperspace Web Instance",
                                "Epic Virtual Instances Hyperspace Web Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsEpicVirtualInstancesPresentationInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "epic virtual instances presentation instance",
                                "epic virtual instances presentation instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Epic Virtual Instances Presentation Instance",
                                "Epic Virtual Instances Presentation Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsEpicVirtualInstancesSharedInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "epic virtual instances shared instance",
                                "epic virtual instances shared instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Epic Virtual Instances Shared Instance",
                                "Epic Virtual Instances Shared Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsEpicVirtualInstancesWssInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "epic virtual instances wss instance",
                                "epic virtual instances wss instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Epic Virtual Instances Wss Instance",
                                "Epic Virtual Instances Wss Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsInsightGenericResource]: {
                         text: {
                             withServiceName: [
                                "insight",
                                "insights"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Insight",
                                "Insights"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsMonitorGenericResource]: {
                         text: {
                             withServiceName: [
                                "monitor",
                                "monitors"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Monitor",
                                "Monitors"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsOracleVirtualInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "oracle virtual instance",
                                "oracle virtual instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oracle Virtual Instance",
                                "Oracle Virtual Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsOracleVirtualInstancesDatabaseInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "oracle virtual instances database instance",
                                "oracle virtual instances database instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Oracle Virtual Instances Database Instance",
                                "Oracle Virtual Instances Database Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsPhpWorkloadGenericResource]: {
                         text: {
                             withServiceName: [
                                "php workload",
                                "php workloads"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Php Workload",
                                "Php Workloads"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsSapDiscoverySiteGenericResource]: {
                         text: {
                             withServiceName: [
                                "sap discovery site",
                                "sap discovery sites"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sap Discovery Site",
                                "Sap Discovery Sites"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsSapDiscoverySitesSapInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sap discovery sites sap instance",
                                "sap discovery sites sap instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sap Discovery Sites Sap Instance",
                                "Sap Discovery Sites Sap Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsSapVirtualInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sap virtual instance",
                                "sap virtual instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sap Virtual Instance",
                                "Sap Virtual Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsSapVirtualInstancesApplicationInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sap virtual instances application instance",
                                "sap virtual instances application instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sap Virtual Instances Application Instance",
                                "Sap Virtual Instances Application Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsSapVirtualInstancesCentralInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sap virtual instances central instance",
                                "sap virtual instances central instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sap Virtual Instances Central Instance",
                                "Sap Virtual Instances Central Instances"
                            ]
                         }
                    },
                    [Contract.TypeNames.AzureWorkloadsSapVirtualInstancesDatabaseInstanceGenericResource]: {
                         text: {
                             withServiceName: [
                                "sap virtual instances database instance",
                                "sap virtual instances database instances"
                            ]
                         },
                         title: {
                             withServiceName: [
                                "Sap Virtual Instances Database Instance",
                                "Sap Virtual Instances Database Instances"
                            ]
                         }
                    }
                }
            }
        ));
}