import { DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, FunctionResult, Optional, StringHelper, TimeHelper, TimeRangeFilterSelection } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { useMemo, useRef } from "react";
import { TimeRangeHelper } from "..";

export type ColumnIdToItemValuesMap<TValuesMap> = { [TKey in keyof TValuesMap]: GetItemValueDefinitionResult<TValuesMap[TKey]>[] };

export type GetItemValueDefinition<TItem> =
    ((item: TItem) => any) |
    {
        getFilterValue: (item: TItem) => any;
        getSortValue: (item: TItem) => any;
    };

type GetFilterValue<FilterValue> = NonNullable<FilterValue extends (infer Value)[] ? Value : FilterValue>;

type GetItemValueDefinitionResult<GetItemValueDefinition> = GetFilterValue<FunctionResult<GetItemValueDefinition extends {
    getFilterValue: infer FilterValue;
} ? FilterValue : GetItemValueDefinition>>;

type TableDefinitionOptions<TItem> = {
    columnIdToDefaultSortDirectionMap?: Dictionary<"asc" | "desc">;
    getCsvItem?: (item: TItem) => any;
    getItemId?: (item: TItem) => string;
    onFilteredItemsChanged?: (items: TItem[]) => void;
};

export function useTableDefinition<TItem, TValuesMap extends { [columnId: string]: GetItemValueDefinition<TItem> }>(
    items: TItem[],
    columnIdToGetItemValueMap: TValuesMap,
    defaultSortColumnIdOrIds: string | string[],
    options?: TableDefinitionOptions<TItem>) {
    const filterAndSortedItemsRef = useRef<TItem[]>([]);
    return useMemo(
        () => {
            const filtersTime =
                TimeHelper.
                    utcNow().
                    toISOString();
            const filterItem =
                (item: TItem, filterMap: Dictionary<any>) =>
                    _.every(
                        columnIdToGetItemValueMap,
                        (getItemValueDefinition: GetItemValueDefinition<TItem>, columnId) => {
                            const columnFilter = filterMap[columnId];
                            if (_.isEmpty(columnFilter) && !isPrimitive(columnFilter)) {
                                return true;
                            }

                            const itemValue =
                                _.isFunction(getItemValueDefinition)
                                    ? getItemValueDefinition(item)
                                    : getItemValueDefinition.getFilterValue(item);
                            const itemValues =
                                _(itemValue).
                                    concat().
                                    filter(value => !_.isNil(value)).
                                    value();

                            if (isPrimitive(columnFilter)) {
                                return _.includes(
                                    itemValues,
                                    columnFilter);
                            }

                            if (_.isArray(columnFilter) &&
                                _(itemValues).
                                    intersection(columnFilter).
                                    isEmpty()) {
                                return false;
                            }

                            if (!(columnFilter instanceof TimeRangeFilterSelection) &&
                                !_.isArray(columnFilter) &&
                                _(itemValues).
                                    intersection(columnFilter.values).
                                    isEmpty() && (
                                !(columnFilter.emptyValue && _.isEmpty(itemValues)))) {
                                return false;
                            }

                            if (columnFilter instanceof TimeRangeFilterSelection &&
                                !TimeRangeHelper.inTimeRange(itemValue, TimeRangeHelper.getTimeRange(filtersTime, columnFilter)!)) {
                                return false;
                            }

                            return true;
                        });
            const itemIdToPermissionsMap =
                _.isNil(options?.getItemId)
                    ? undefined
                    : _(items).
                        keyBy(item => options!.getItemId!(item)).
                        mapValues(() => [] as string[]).
                        value();

            const getItemSortValue =
                (item: TItem, columnId: string) => {
                    const getItemValueDefinition = columnIdToGetItemValueMap[columnId];
                    const itemValue =
                        _.isFunction(getItemValueDefinition)
                            ? getItemValueDefinition(item)
                            : getItemValueDefinition.getSortValue(item);
                    return _.isString(itemValue)
                        ? StringHelper.getSortValue(itemValue)
                        : itemValue;
                };

            return {
                columnIdToItemValuesMap:
                    _.mapValues(
                        columnIdToGetItemValueMap,
                        (getItemValueDefinition: GetItemValueDefinition<TItem>) =>
                            _(items).
                                flatMap(
                                    item =>
                                        _.isFunction(getItemValueDefinition)
                                            ? getItemValueDefinition(item)
                                            : getItemValueDefinition.getFilterValue(item)).
                                filter().
                                uniq().
                                value()) as ColumnIdToItemValuesMap<TValuesMap>,
                filterAndSortItems:
                    (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
                        const sortColumnIds =
                            _<string>([]).
                                concatIf(
                                    !_.isNil(sort?.columnId),
                                    () => sort!.columnId).
                                concat(defaultSortColumnIdOrIds).
                                concat(_.keys(columnIdToGetItemValueMap)).
                                uniq().
                                value();

                        const filteredAndSortedItems =
                            _(items).
                                filter(item => filterItem(item, filterMap)).
                                orderBy(
                                    _.map(
                                        sortColumnIds,
                                        sortColumnId => (item: TItem) => getItemSortValue(item, sortColumnId)),
                                    [
                                        _.isNil(sort)
                                            ? options?.columnIdToDefaultSortDirectionMap?.[sortColumnIds[0]] ?? "asc"
                                            : sort.direction === DataTableSortDirection.Descending
                                                ? "desc"
                                                : "asc",
                                        ..._(sortColumnIds).
                                            drop(1).
                                            map(sortColumnId => options?.columnIdToDefaultSortDirectionMap?.[sortColumnId] ?? "asc").
                                            value() as ("asc" | "desc")[]
                                    ]).
                                value();

                        let filteredItemIdToPermissionsMap: Optional<Dictionary<string[]>> = undefined;
                        if (skip === 0 &&
                            !_.isNil(options?.getItemId)) {
                            filteredItemIdToPermissionsMap =
                                _(filteredAndSortedItems).
                                    map(options!.getItemId!).
                                    keyBy().
                                    mapValues(itemId => itemIdToPermissionsMap![itemId]).
                                    value();
                        }

                        const itemPage =
                            filteredAndSortedItems.length <= skip
                                ? filteredAndSortedItems
                                : _(filteredAndSortedItems).
                                    drop(skip).
                                    take(limit).
                                    value();

                        options?.onFilteredItemsChanged?.(filteredAndSortedItems);

                        return new DataTableFetchItemsResult(
                            { count: filteredAndSortedItems.length },
                            itemPage,
                            skip + limit >= filteredAndSortedItems.length,
                            {
                                itemIdToPermissionsMap: filteredItemIdToPermissionsMap,
                                onAppendData:
                                    () => {
                                        filterAndSortedItemsRef.current = filteredAndSortedItems;
                                    }
                            });
                    },
                getCsvItemPage:
                    (limit: number, skip: number) =>
                        skip > 0
                            ? []
                            : _.map(
                                filterAndSortedItemsRef.current,
                                item => options!.getCsvItem!(item)) as any[]
            };
        },
        [items]);
}

function isPrimitive(value: any) {
    return _.isBoolean(value) ||
        _.isNumber(value) ||
        _.isString(value);
}