import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsElbLoadBalancerRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, AwsElbClassicLoadBalancerListenersTable, AwsElbLoadBalancerListenersTable, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";

export function useAwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRisk;
    const loadBalancerModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElbLoadBalancerModel;
    const loadBalancer = loadBalancerModel.entity as Contract.AwsElbLoadBalancer;

    const getAwsElbLoadBalancerRiskContext = useGetAwsElbLoadBalancerRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskDefinition",
            () => ({
                description: "{{loadBalancer}} allows insecure communication protocols such as TLS 1.0 and 1.1",
                sections: {
                    details: "Details",
                    resolution: {
                        application: {
                            step1: "On the selected load balancer settings, select the **Listeners and rules** tab",
                            step2: "In the **Listeners and rules**, review each listener that does not use secured protocol, select it and click on **Manage listener** and then **Edit listener**",
                            step3: "From the **Protocol** drop-down list, select the new secured protocol **HTTPS**",
                            step4: "In the **Secure listener settings** section, select the **Policy name** that enforces TLS v1.2 and above",
                            step5: "Select the **Default SSL/TLS server certificate**",
                            step6: "Click **Save Changes**"
                        },
                        classic: {
                            step1: "Click **Manage listeners**",
                            step2: "In the **Listeners** section, review each listener that does not use secured protocol",
                            step3: "From the **Listener protocol** drop-down list, select the new secured protocol **HTTPS** or **SSL**",
                            step4: "Select the **Security policy** that enforces TLS v1.2 and above",
                            step5: "Select the **Default SSL/TLS server certificate**",
                            step6: "Click **Save Changes**"
                        },
                        network: {
                            step1: "On the selected load balancer settings, select the **Listeners and rules** tab",
                            step2: "In the **Listeners and rules**, review each TLS listener that does not use TLS version 1.2 and above, select it and click on **Manage listener** and then **Edit listener**",
                            step3: "In the **Secure listener settings** section, select the **Policy name** that enforces TLS v1.2 and above",
                            step4: "Click **Save Changes**"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            loadBalancer:
                <Entity
                    entityIdOrModel={loadBalancerModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            loadBalancer.typeName === Contract.TypeNames.AwsElbApplicationLoadBalancer
                ? [
                    consoleSignInStepTranslator(
                        Contract.AwsConsoleView.Ec2,
                        AwsConsoleUrlBuilder.getElbModernLoadBalancerUrl(loadBalancer)),
                    localization.sections.resolution.application.step1(),
                    localization.sections.resolution.application.step2(),
                    localization.sections.resolution.application.step3(),
                    localization.sections.resolution.application.step4(),
                    localization.sections.resolution.application.step5(),
                    localization.sections.resolution.application.step6()
                ]
                : loadBalancer.typeName === Contract.TypeNames.AwsElbClassicLoadBalancer
                    ? [
                        consoleSignInStepTranslator(
                            Contract.AwsConsoleView.Ec2,
                            AwsConsoleUrlBuilder.getElbClassicLoadBalancerUrl(loadBalancer)),
                        localization.sections.resolution.classic.step1(),
                        localization.sections.resolution.classic.step2(),
                        localization.sections.resolution.classic.step3(),
                        localization.sections.resolution.classic.step4(),
                        localization.sections.resolution.classic.step5(),
                        localization.sections.resolution.classic.step6()
                    ]
                    : [
                        consoleSignInStepTranslator(
                            Contract.AwsConsoleView.Ec2,
                            AwsConsoleUrlBuilder.getElbModernLoadBalancerUrl(loadBalancer)),
                        localization.sections.resolution.network.step1(),
                        localization.sections.resolution.network.step2(),
                        localization.sections.resolution.network.step3(),
                        localization.sections.resolution.network.step4()
                    ],
        riskModel,
        () => {
            const loadBalancerRiskContext = getAwsElbLoadBalancerRiskContext(loadBalancerModel);
            return [
                loadBalancerRiskContext.generalInformation,
                loadBalancerRiskContext.sensitive,
                loadBalancerRiskContext.inboundExternalAccessScope,
                loadBalancerRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    loadBalancer.typeName === Contract.TypeNames.AwsElbClassicLoadBalancer
                        ? <AwsElbClassicLoadBalancerListenersTable
                            instanceIds={(loadBalancer as Contract.AwsElbClassicLoadBalancer).instanceIds}
                            loadBalancerListenerIds={risk.insecureSslProtocolLoadBalancerListenerIds}/>
                        : <AwsElbLoadBalancerListenersTable
                            loadBalancerListenerIds={risk.insecureSslProtocolLoadBalancerListenerIds}
                            targetGroupIds={(loadBalancerModel as Contract.AwsElbLoadBalancerModel).targetGroupIds}/>,
                    localization.sections.details()
                )
            ]
        });
}