﻿import { useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { AzureDevOps } from "../../..";
import { ConfigurationController, ConsoleApiUrlHelper, Contract, useTheme } from "../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../AddOrEditAzureDevOps";

export function AllowAppAccessItem() {
    const { callbackError, oAuthAccessToken, organizationModel } = useAddOrEditContext();
    const { setError, setValid } = useOrderedWizardContext();

    const isUserAuthenticated = !!oAuthAccessToken || !!organizationModel;

    useEffect(
        () => {
            setValid(isUserAuthenticated);
            setError(
                callbackError
                    ? localization[Contract.TypeNames.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError].translate(callbackError)
                    : undefined);
        },
        [callbackError, isUserAuthenticated]);

    const [{ applicationRawId }] =
        useExecuteOperation(
            AzureDevOps,
            ConfigurationController.getAzureDevOpsOrganizationOnboardingApplicationInfo);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.azureDevOps.addOrEditAzureDevOps.allowAppAccessItem",
            () => ({
                withAadTenantRawId: "Permissions already granted",
                [Contract.TypeNames.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError]: {
                    [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError.ApplicationConsentError]: "Application consent grant was unsuccessful or resulted in unexpected redirect",
                    [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError.AuthenticationError]: "Microsoft Entra ID tenant authentication failed",
                    [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError.AuthenticationRedirectError]: "Microsoft Entra ID authorization was unsuccessful or resulted in unexpected redirect"
                },
                withoutAadTenantRawId: {
                    button: "Grant consent",
                    details: "Grant Admin consent to approve the **Tenable Cloud Security Azure DevOps** app in Azure and grant Tenable the necessary permissions. You need to sign in as a user that is authorized to consent on behalf of the organization. Once the consent is approved, you’ll be redirected back to this wizard to finish the setup."
                }
            }));

    const theme = useTheme();
    return isUserAuthenticated
        ? <Typography
            sx={{ color: theme.palette.success.main }}
            variant="h4">
            {localization.withAadTenantRawId()}
        </Typography>
        : <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(50) }}>
            <Typography>
                {localization.withoutAadTenantRawId.details()}
            </Typography>
            <Button
                component="a"
                href={ConsoleApiUrlHelper.getAzureDevOpsConsentUrl(applicationRawId)}
                sx={{ width: theme.spacing(25) }}
                target="_self"
                variant="outlined">
                {localization.withoutAadTenantRawId.button()}
            </Button>
        </Stack>;
}