﻿import { ApiError, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAddOrEditProjectContext, useSetAddOrEditProjectContext } from "..";
import { ConfigurationController, Contract, useTheme } from "../../../../../../../../../../../../../../../common";
import { useJiraContext } from "../../../Jira";

export function RawIdItem() {
    const { instanceModel, projectConfiguration } = useJiraContext();
    const { rawId } = useAddOrEditProjectContext();
    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const setAddOrEditProjectContext = useSetAddOrEditProjectContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: undefined
                }));
            setLoaded();
        },
        []);

    const [selectedRawId, setSelectedRawId] = useState(rawId ?? "");

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEditProject.rawIdItem",
            () => ({
                actions: {
                    save: {
                        error: "Failed to add",
                        title: "Save",
                        [Contract.TypeNames.ConfigurationControllerGetJiraRawProjectDataError]: {
                            [Contract.ConfigurationControllerGetJiraRawProjectDataError.InstanceError]: "Instance has an error",
                            [Contract.ConfigurationControllerGetJiraRawProjectDataError.InvalidId]: "Project doesn't exist",
                            [Contract.ConfigurationControllerGetJiraRawProjectDataError.MissingPermissions]: "Missing permissions",
                            [Contract.ConfigurationControllerGetJiraRawProjectDataError.MissingProductPermissions]: "Missing product permissions",
                            [Contract.ConfigurationControllerGetJiraRawProjectDataError.UnsupportedProduct]: "Unsupported project product",
                            [Contract.ConfigurationControllerGetJiraRawProjectDataError.UnsupportedProject]: "Unsupported project type"
                        }
                    }
                },
                fields: {
                    rawId: {
                        error: {
                            invalidFormat: "Project key must contain only uppercase letters",
                            invalidLength: "Project key must be at least 2 characters long and no more than 10",
                            required: "Project key cannot be empty"
                        },
                        title: "Project key"
                    }
                }
            }));

    const [rawIdValidationController, rawIdValidationMessage] =
        useInputValidation(
            () => {
                const validationSelectedRawId = selectedRawId.trim();
                if (_.isEmpty(validationSelectedRawId)) {
                    return localization.fields.rawId.error.required();
                }
                if (validationSelectedRawId.length < 2 ||
                    validationSelectedRawId.length > 10) {
                    return localization.fields.rawId.error.invalidLength();
                }
                if (validationSelectedRawId.toUpperCase() !== validationSelectedRawId) {
                    return localization.fields.rawId.error.invalidFormat();
                }

                return undefined;
            },
            [rawId]);

    useNextEffect(
        async () => {
            if (!_.isNil(projectConfiguration)) {
                return;
            }

            try {
                const { issueTypeNameToStatusesMap, priorities } =
                    await ConfigurationController.getJiraRawProjectData(
                        new Contract.ConfigurationControllerGetJiraRawProjectDataRequest(
                            instanceModel!.configuration.id,
                            selectedRawId));

                setAddOrEditProjectContext(
                    context => ({
                        ...context,
                        issueTypeNameToStatusesMap,
                        priorities,
                        rawId: selectedRawId
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? localization.actions.save[Contract.TypeNames.ConfigurationControllerGetJiraRawProjectDataError][error.error as Contract.ConfigurationControllerGetJiraRawProjectDataError]()
                    : localization.actions.save.error();
            }
        },
        [selectedRawId]);

    useEffect(
        () => {
            setValid(rawIdValidationController.isValid());
        },
        [selectedRawId]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing || !_.isNil(projectConfiguration)}
                    label={localization.fields.rawId.title()}
                    value={selectedRawId}
                    variant="outlined"
                    onChange={event => setSelectedRawId(event.target.value.toUpperCase())}/>
                {!_.isNil(rawIdValidationMessage) &&
                    <FormHelperText error={true}>{rawIdValidationMessage}</FormHelperText>}
            </FormControl>
        </Stack>);
}