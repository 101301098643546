﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SouthKoreaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60209_7039)">
                <rect
                    fill="white"
                    height="15.8919"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M0 4.00073H23.8931V19.8926H0V4.00073Z"
                    fill="#000008"/>
                <path
                    clipRule="evenodd"
                    d="M0 4.00098H23.8966V19.8929H0V4.00098Z"
                    fill="white"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M22 5.00024H2C1.44772 5.00024 1 5.44796 1 6.00024V17.964C1 18.5163 1.44772 18.964 2 18.964H22C22.5523 18.964 23 18.5163 23 17.964V6.00024C23 5.44796 22.5523 5.00024 22 5.00024ZM2 4.00024H22C23.1046 4.00024 24 4.89567 24 6.00024V17.964C24 19.0686 23.1046 19.964 22 19.964H2C0.89543 19.964 0 19.0686 0 17.964V6.00024C0 4.89567 0.895431 4.00024 2 4.00024Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M3.83057 8.83667L6.04458 5.54733L6.57186 5.89576L4.35785 9.1851L3.83057 8.83667ZM4.62149 9.35931L6.8355 6.06997L7.36278 6.4184L5.14877 9.70774L4.62149 9.35931ZM5.41241 9.88196L7.62642 6.59261L8.1537 6.94104L5.93969 10.2304L5.41241 9.88196Z"
                    fill="#000008"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M15.7172 16.7639L17.9312 13.4746L18.4585 13.823L16.2444 17.1123L15.7172 16.7639ZM16.5081 17.2866L18.7221 13.9972L19.2494 14.3456L17.0354 17.635L16.5081 17.2866ZM17.299 17.8092L19.513 14.5199L20.0403 14.8683L17.8263 18.1576L17.299 17.8092Z"
                    fill="#000008"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M16.5271 14.9143L19.2285 16.716L16.5271 14.9143Z"
                    fill="#000008"
                    fillRule="evenodd"/>
                <path
                    d="M16.5271 14.9143L19.2285 16.716"
                    stroke="white"
                    strokeWidth="0.324712"/>
                <path
                    clipRule="evenodd"
                    d="M8.69324 9.7749C9.28661 8.89249 10.1933 8.2695 11.2137 8.04299C12.2342 7.81647 13.2849 8.00498 14.1346 8.56705C14.9844 9.12912 15.5636 10.0187 15.745 11.0401C15.9263 12.0615 15.6948 13.131 15.1014 14.0135L8.69324 9.7749Z"
                    fill="#CD2E3A"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M8.75975 9.72464C8.16867 10.603 7.93806 11.6676 8.11866 12.6842C8.29927 13.7009 8.87628 14.5864 9.72278 15.1459C10.5693 15.7053 11.6159 15.893 12.6324 15.6675C13.6489 15.442 14.5521 14.8219 15.1432 13.9436C15.4387 13.5044 15.554 12.9721 15.4637 12.4638C15.3734 11.9554 15.0849 11.5127 14.6617 11.233C14.2384 10.9532 13.7151 10.8594 13.2068 10.9722C12.6986 11.0849 12.247 11.3949 11.9515 11.8341L8.75975 9.72464Z"
                    fill="#0047A0"
                    fillRule="evenodd"/>
                <path
                    d="M11.9049 11.822C12.5185 10.9105 12.3035 9.70079 11.4249 9.12015C10.5462 8.53952 9.33658 8.80779 8.72304 9.71935C8.1095 10.6309 8.32441 11.8406 9.20307 12.4212C10.0817 13.0018 11.2914 12.7336 11.9049 11.822Z"
                    fill="#CD2E3A"/>
                <path
                    clipRule="evenodd"
                    d="M6.05359 18.1584L3.83957 14.8691L4.36686 14.5207L6.58087 17.81L6.05359 18.1584ZM6.84451 17.6358L4.6305 14.3465L5.15778 13.998L7.37179 17.2874L6.84451 17.6358ZM7.63543 17.1132L5.42142 13.8238L5.9487 13.4754L8.16271 16.7647L7.63543 17.1132Z"
                    fill="#000008"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M17.9402 10.2312L15.7262 6.94186L16.2535 6.59343L18.4675 9.88277L17.9402 10.2312ZM18.7311 9.70856L16.5171 6.41921L17.0444 6.07079L19.2584 9.36013L18.7311 9.70856ZM19.5221 9.18591L17.308 5.89657L17.8353 5.54814L20.0493 8.83749L19.5221 9.18591Z"
                    fill="#000008"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M5.59534 16.0876L6.40577 15.5472L5.59534 16.0876ZM16.5362 8.79099L17.4817 8.16041L16.5362 8.79099ZM18.2922 7.61992L19.1026 7.07942L18.2922 7.61992Z"
                    fill="#000008"
                    fillRule="evenodd"/>
                <path
                    d="M5.59534 16.0876L6.40577 15.5472M16.5362 8.79099L17.4817 8.16041M18.2922 7.61992L19.1026 7.07942"
                    stroke="white"
                    strokeWidth="0.324712"/>
            </g>
            <defs>
                <clipPath id="clip0_60209_7039">
                    <rect
                        fill="white"
                        height="15.8919"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}