import { makeContextProvider, map, Optional } from "@infrastructure";
import { SxProps } from "@mui/material";
import React, { Ref } from "react";
import { Contract, ScopeHelper } from "../..";
import { RiskPolicyTypeMetadataHelper } from "../../utilities";
import { AwsEc2SecurityGroupAllowedInboundRuleRiskPolicy, AwsEc2SecurityGroupManagementRiskPolicy, AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicy, AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicy, AwsIamPrincipalCreationRiskPolicy, AwsIamRoleManagementRiskPolicy, AwsIdentityActivityRiskPolicy, AwsInboundExternalSubnetRiskPolicy, AwsInboundExternalVpcsRiskPolicy, AwsKmsKeyManagementRiskPolicy, AwsNetworkedResourceInboundExternalPortRiskPolicy, AwsPrincipalAllowedPermissionsRiskPolicy, AwsPrincipalAllowedResourcePermissionRiskPolicy, AwsPrincipalNotAllowedResourcePermissionRiskPolicy, AwsResourceCodeResourceNotExistsRiskPolicy, AwsResourceTagNotExistsRiskPolicy, AwsRoleTemplateMismatchRiskPolicy, AwsS3BucketManagementRiskPolicy, AwsSecretsManagerSecretManagementRiskPolicy, AwsSsoPermissionSetAssignmentRiskPolicy, AwsSsoPermissionSetManagementRiskPolicy, AwsSsoPrincipalCreationRiskPolicy, AzureManagedIdentityNotAllowedResourcePermissionRiskPolicy, AzurePrincipalAllowedResourcePermissionRiskPolicy, AzurePrincipalNotAllowedResourcePermissionRiskPolicy, AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicy, AzureResourceCodeResourceNotExistsRiskPolicy, AzureResourceInboundExternalPortRiskPolicy, AzureResourceTagNotExistsRiskPolicy, GcpEntityInboundExternalPortRiskPolicy, GcpIdentityActivityRiskPolicy, GcpPrincipalAllowedResourcePermissionRiskPolicy, GcpPrincipalNotAllowedResourcePermissionRiskPolicy, GcpPrincipalNotAllowedResourceRoleRiskPolicy, GcpResourceCodeResourceNotExistsRiskPolicy, GcpResourceTagNotExistsRiskPolicy } from "./components";

type CustomRiskPolicyProps = {
    editOptions?: SpecificCustomRiskPolicyEditOptions;
    riskPolicyConfiguration?: Contract.CustomRiskPolicyConfiguration;
    riskPolicyConfigurationTypeName: string;
    scopeId?: string;
    sx?: SxProps;
};

export type CustomRiskPolicyActions = {
    createRequest: (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => Contract.RiskControllerInsertCustomRiskPolicyRequest;
};

export class CustomRiskPolicyContext {
    constructor(
        public scopeId: string,
        public tenantTypes: Contract.TenantType[]) {
    }
}

export const [useCustomRiskPolicyContext, , useCustomRiskPolicyContextProvider] = makeContextProvider<CustomRiskPolicyContext>();

export function CustomRiskPolicy({ editOptions, riskPolicyConfiguration, riskPolicyConfigurationTypeName, scopeId, sx }: CustomRiskPolicyProps) {
    const SpecificRiskPolicyComponent =
        map(
            riskPolicyConfigurationTypeName,
            {
                [Contract.TypeNames.AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration]: () => AwsEc2SecurityGroupAllowedInboundRuleRiskPolicy,
                [Contract.TypeNames.AwsEc2SecurityGroupManagementRiskPolicyConfiguration]: () => AwsEc2SecurityGroupManagementRiskPolicy,
                [Contract.TypeNames.AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration]: () => AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicy,
                [Contract.TypeNames.AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration]: () => AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicy,
                [Contract.TypeNames.AwsIamPrincipalCreationRiskPolicyConfiguration]: () => AwsIamPrincipalCreationRiskPolicy,
                [Contract.TypeNames.AwsIamRoleManagementRiskPolicyConfiguration]: () => AwsIamRoleManagementRiskPolicy,
                [Contract.TypeNames.AwsRoleTemplateMismatchRiskPolicyConfiguration]: () => AwsRoleTemplateMismatchRiskPolicy,
                [Contract.TypeNames.AwsIdentityActivityRiskPolicyConfiguration]: () => AwsIdentityActivityRiskPolicy,
                [Contract.TypeNames.AwsInboundExternalSubnetRiskPolicyConfiguration]: () => AwsInboundExternalSubnetRiskPolicy,
                [Contract.TypeNames.AwsInboundExternalVpcRiskPolicyConfiguration]: () => AwsInboundExternalVpcsRiskPolicy,
                [Contract.TypeNames.AwsKmsKeyManagementRiskPolicyConfiguration]: () => AwsKmsKeyManagementRiskPolicy,
                [Contract.TypeNames.AwsNetworkedResourceInboundExternalPortRiskPolicyConfiguration]: () => AwsNetworkedResourceInboundExternalPortRiskPolicy,
                [Contract.TypeNames.AwsPrincipalAllowedPermissionRiskPolicyConfiguration]: () => AwsPrincipalAllowedPermissionsRiskPolicy,
                [Contract.TypeNames.AwsPrincipalAllowedResourcePermissionRiskPolicyConfiguration]: () => AwsPrincipalAllowedResourcePermissionRiskPolicy,
                [Contract.TypeNames.AwsPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration]: () => AwsPrincipalNotAllowedResourcePermissionRiskPolicy,
                [Contract.TypeNames.AwsResourceCodeResourceNotExistsRiskPolicyConfiguration]: () => AwsResourceCodeResourceNotExistsRiskPolicy,
                [Contract.TypeNames.AwsResourceTagNotExistsRiskPolicyConfiguration]: () => AwsResourceTagNotExistsRiskPolicy,
                [Contract.TypeNames.AwsS3BucketManagementRiskPolicyConfiguration]: () => AwsS3BucketManagementRiskPolicy,
                [Contract.TypeNames.AwsSecretsManagerSecretManagementRiskPolicyConfiguration]: () => AwsSecretsManagerSecretManagementRiskPolicy,
                [Contract.TypeNames.AwsSsoPermissionSetAssignmentRiskPolicyConfiguration]: () => AwsSsoPermissionSetAssignmentRiskPolicy,
                [Contract.TypeNames.AwsSsoPermissionSetManagementRiskPolicyConfiguration]: () => AwsSsoPermissionSetManagementRiskPolicy,
                [Contract.TypeNames.AwsSsoPrincipalCreationRiskPolicyConfiguration]: () => AwsSsoPrincipalCreationRiskPolicy,
                [Contract.TypeNames.AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration]: () => AzureManagedIdentityNotAllowedResourcePermissionRiskPolicy,
                [Contract.TypeNames.AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration]: () => AzurePrincipalAllowedResourcePermissionRiskPolicy,
                [Contract.TypeNames.AzurePrincipalNotAllowedResourcePermissionRiskPolicyConfiguration]: () => AzurePrincipalNotAllowedResourcePermissionRiskPolicy,
                [Contract.TypeNames.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfiguration]: () => AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicy,
                [Contract.TypeNames.AzureResourceCodeResourceNotExistsRiskPolicyConfiguration]: () => AzureResourceCodeResourceNotExistsRiskPolicy,
                [Contract.TypeNames.AzureResourceInboundExternalPortRiskPolicyConfiguration]: () => AzureResourceInboundExternalPortRiskPolicy,
                [Contract.TypeNames.AzureResourceTagNotExistsRiskPolicyConfiguration]: () => AzureResourceTagNotExistsRiskPolicy,
                [Contract.TypeNames.GcpEntityInboundExternalPortRiskPolicyConfiguration]: () => GcpEntityInboundExternalPortRiskPolicy,
                [Contract.TypeNames.GcpIdentityActivityRiskPolicyConfiguration]: () => GcpIdentityActivityRiskPolicy,
                [Contract.TypeNames.GcpPrincipalAllowedResourcePermissionRiskPolicyConfiguration]: () => GcpPrincipalAllowedResourcePermissionRiskPolicy,
                [Contract.TypeNames.GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration]: () => GcpPrincipalNotAllowedResourcePermissionRiskPolicy,
                [Contract.TypeNames.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfiguration]: () => GcpPrincipalNotAllowedResourceRoleRiskPolicy,
                [Contract.TypeNames.GcpResourceCodeResourceNotExistsRiskPolicyConfiguration]: () => GcpResourceCodeResourceNotExistsRiskPolicy,
                [Contract.TypeNames.GcpResourceTagNotExistsRiskPolicyConfiguration]: () => GcpResourceTagNotExistsRiskPolicy
            });

    const [, , ContextProvider] =
        useCustomRiskPolicyContextProvider(
            () =>
                new CustomRiskPolicyContext(
                    scopeId ?? ScopeHelper.customerId,
                    RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyConfigurationTypeName)),
            [riskPolicyConfigurationTypeName, scopeId]);

    return (
        <ContextProvider>
            <SpecificRiskPolicyComponent
                editOptions={editOptions}
                riskPolicyConfiguration={riskPolicyConfiguration}
                sx={sx}/>
        </ContextProvider>);
}

export type SpecificCustomRiskPolicyProps = {
    editOptions?: SpecificCustomRiskPolicyEditOptions;
    riskPolicyConfiguration?: Contract.CustomRiskPolicyConfiguration;
    sx?: SxProps;
};

type SpecificCustomRiskPolicyEditOptions = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
};