import { DialogMenuItem, EmptyMessageText, NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonNetworkingInfoItemElements, useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "..";
import { Contract, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem } from "../../../../../../../../../../common";
import { GcpSqlInstanceAuthorizedSubnetsTable } from "../../../../../../../../../../tenants";
import { useGcpSqlInstanceDatabaseVersionTranslator, useGcpSqlInstanceStatusTranslator, useGcpSqlInstanceTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings, NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../useDefaultDataAnalysisResourceInfoItemElements";

export function useGcpSqlInstanceDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(scopeResourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const instanceModel = scopeResourceModel as Contract.GcpSqlInstanceModel;
    const instance = instanceModel.entity as Contract.GcpSqlInstance;
    const networkInfoItems =
        useGcpCommonNetworkingInfoItemElements({
            privateIpAddresses: instance.privateIpAddresses,
            publicIpAddresses: instance.publicIpAddresses,
            resourceModel: scopeResourceModel,
            vpcIds: instanceModel.vpcIdReferences
        });

    const sqlInstanceDatabaseVersionTranslator = useGcpSqlInstanceDatabaseVersionTranslator();
    const sqlInstanceStatusTranslator = useGcpSqlInstanceStatusTranslator();
    const sqlInstanceTypeTranslator = useGcpSqlInstanceTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpSqlInstanceDefinition",
            () => ({
                info: {
                    cards: {
                        authorizedSubnets: "Authorized Networks",
                        databaseConfigurationKeyToValueMap: {
                            empty: "No Database Flags",
                            keyTitle: "Name",
                            title: "Database Flags"
                        }
                    },
                    items: {
                        auditLogBucketIdReference: "Audit Logs Bucket",
                        auditLogEnabled: {
                            false: "Disabled",
                            title: "Audit Logs Service Status",
                            true: "Enabled"
                        },
                        backupEnabled: {
                            false: "Disabled",
                            title: "Automatic Backup Service",
                            true: "Enabled"
                        },
                        connectionName: "Connection Name",
                        databaseIds: "Databases",
                        databaseVersion: "Database Version",
                        httpsOnly: {
                            false: "Disabled",
                            title: "Require SSL",
                            true: "Enabled"
                        },
                        httpsServerCertificateValidationEnabled: {
                            false: "False",
                            title: "Require Trusted Client Certificate",
                            true: "True"
                        },
                        ipAddresses: {
                            private: "Private",
                            public: "Public",
                            title: "IP Addresses"
                        },
                        primaryInstanceId: "Primary Instance",
                        replicaInstanceIds: "Replicas",
                        size: {
                            empty: "Unknown",
                            title: "Size"
                        },
                        status: "Status",
                        type: "Type",
                        userIdReferences: "Users",
                        vpcIdReferences: "VPC Network"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));
    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            scopeResourceModel as Contract.GcpSqlInstanceModel,
            dataAnalysis => dataAnalysis.managedDatabaseScanEnabled);
    const menuItems = [getDataAnalysisResourcePriorityScanRequestDialogMenuItem()];

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={sqlInstanceTypeTranslator(instance.type)}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={
                            _.isNil(instance.status)
                                ? undefined
                                : sqlInstanceStatusTranslator(instance.status)}/>,
                    <InfoItem
                        key="databaseVersion"
                        title={localization.info.items.databaseVersion()}
                        value={sqlInstanceDatabaseVersionTranslator(instance.databaseVersion)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel?.primaryInstanceId}
                        entityTypeName={Contract.TypeNames.GcpSqlInstance}
                        key="primaryInstanceId"
                        title={localization.info.items.primaryInstanceId()}/>,
                    <InfoItem
                        emptyValue={localization.info.items.size.empty()}
                        key="size"
                        location="all"
                        title={localization.info.items.size.title()}
                        value={
                            _.isNil(instance.size)
                                ? undefined
                                : NumberFormatter.storage(instance.size)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.replicaInstanceIds}
                        entityTypeName={Contract.TypeNames.GcpSqlInstance}
                        key="replicaInstanceIds"
                        title={localization.info.items.replicaInstanceIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.databaseIds}
                        entityTypeName={Contract.TypeNames.GcpSqlInstanceDatabase}
                        key="databaseIds"
                        title={localization.info.items.databaseIds()}/>,
                    <InfoItem
                        key="connectionName"
                        title={localization.info.items.connectionName()}
                        value={instance.connectionName}/>,
                    <InfoItem
                        key="auditLogEnabled"
                        title={localization.info.items.auditLogEnabled.title()}
                        value={
                            instance.auditLogEnabled
                                ? localization.info.items.auditLogEnabled.true()
                                : localization.info.items.auditLogEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel?.auditLogBucketIdReference}
                        entityTypeName={Contract.TypeNames.GcpStorageBucket}
                        key="auditLogBucketIdReference"
                        title={localization.info.items.auditLogBucketIdReference()}/>,
                    <InfoItem
                        key="backupEnabled"
                        title={localization.info.items.backupEnabled.title()}
                        value={
                            instance.backupEnabled
                                ? localization.info.items.backupEnabled.true()
                                : localization.info.items.backupEnabled.false()}/>,
                    <InfoItem
                        key="httpsOnly"
                        title={localization.info.items.httpsOnly.title()}
                        value={
                            instance.httpsOnly
                                ? localization.info.items.httpsOnly.true()
                                : localization.info.items.httpsOnly.false()}/>,
                    <InfoItem
                        key="httpsServerCertificateValidationEnabled"
                        title={localization.info.items.httpsServerCertificateValidationEnabled.title()}
                        value={
                            !instance.httpsOnly
                                ? undefined
                                : instance.httpsServerCertificateValidationEnabled
                                    ? localization.info.items.httpsServerCertificateValidationEnabled.true()
                                    : localization.info.items.httpsServerCertificateValidationEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.userIdReferences}
                        entityTypeName={Contract.TypeNames.GcpSqlInstanceUser}
                        key="userIds"
                        title={localization.info.items.userIdReferences()}/>,
                    ...defaultDataAnalysisResourceInfoItemElements
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.inboundAccessType}
                    {networkInfoItems.inboundExternalAccessScope}
                    {networkInfoItems.getPublicIpAddresses()}
                    {networkInfoItems.getPrivateIpAddresses()}
                    {networkInfoItems.getVpcs()}
                </NetworkingInfoCard>
                <InfoCard title={localization.info.cards.databaseConfigurationKeyToValueMap.title()}>
                    <KeyValueTable
                        emptyMessageText={new EmptyMessageText(localization.info.cards.databaseConfigurationKeyToValueMap.empty())}
                        items={
                            _.map(
                                instance.databaseConfigurationKeyToValueMap,
                                (value, key) =>
                                    new KeyValueTableItem(
                                        key,
                                        value))}
                        keyTitle={localization.info.cards.databaseConfigurationKeyToValueMap.keyTitle()}/>
                </InfoCard>
                <InfoCard title={localization.info.cards.authorizedSubnets()}>
                    <GcpSqlInstanceAuthorizedSubnetsTable authorizedSubnetDatas={instance.authorizedSubnetDatas}/>
                </InfoCard>
            </Info>,
        topbarMenuItems: _.filter(menuItems) as DialogMenuItem[]
    });
}