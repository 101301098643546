﻿import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { useTheme } from "../../../../..";
import { InfoCard, InfoItem } from "../../../../../../common";
import { EntityProfileInfoLayout, Info } from "../../../../../../views/Customer/components/Entities/components/Profile/components";
import { useRiskResolutionAutomationContext } from "../../../utilities";

export function EmptyChangeInfo() {
    const { riskedEntityModel } = useRiskResolutionAutomationContext();
    const theme = useTheme();
    return (
        <Fragment>
            <Box
                sx={{
                    backdropFilter: "blur(8px)",
                    height: "100%",
                    left: 0,
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: 999
                }}/>
            <EntityProfileInfoLayout entityId={riskedEntityModel.entity.id}>
                <Box sx={{ padding: theme.spacing(2) }}>
                    <Info
                        customEntityPropertyInfoItemElements={[]}
                        defaultTenantInfoItemElements={[
                            <InfoItem
                                key="type"
                                title="Type"
                                value="Resource"/>,
                            <InfoItem
                                key="account"
                                title="Account"
                                value="Account1"/>,
                            <InfoItem
                                key="creationTime"
                                title="Creation Time"
                                value="Unknown"/>,
                            <InfoItem
                                key="email"
                                title="Email"
                                value="Unknown"/>,
                            <InfoItem
                                key="status"
                                title="Status"
                                value="Unknown"/>
                        ]}
                        entityPropertyInfoItemElements={[]}>
                        <InfoCard title="Group Membership">
                            <InfoItem
                                key="group1"
                                title="Group1"
                                value="Group1 Name"/>
                            <InfoItem
                                key="group2"
                                title="Group2"
                                value="Group2 Name"/>
                        </InfoCard>
                        <InfoCard title="Access">
                            <InfoItem
                                key="access1"
                                title="Access1"
                                value="Access1 Name"/>
                            <InfoItem
                                key="access2"
                                title="Access2"
                                value="Access2 Name"/>
                            <InfoItem
                                key="access3"
                                title="Access3"
                                value="Access3 Name"/>
                        </InfoCard>
                    </Info>
                </Box>
            </EntityProfileInfoLayout>
        </Fragment>);
}