import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciBlockStorageBlockVolumeRiskContext() {
    return useMemo(
        () => useOciBlockStorageBlockVolumeRiskContext,
        []);
}

function useOciBlockStorageBlockVolumeRiskContext(blockVolumeModel: Contract.OciBlockStorageBlockVolumeModel) {
    const blockVolume = blockVolumeModel.entity as Contract.OciBlockStorageBlockVolume;
    const resourceRiskContext = useGetOciResourceRiskContext()(blockVolumeModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciBlockStorageBlockVolumeRiskContext",
            () => ({
                instances: "The {{translatedBlockVolumeTypeName}} is associated with {{instances}}",
                size: "The {{translatedBlockVolumeTypeName}}'s size is {{size | NumberFormatter.storage}}"
            }));

    const translatedBlockVolumeTypeName =
        entityTypeNameTranslator(
            blockVolume.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        instances:
            _.isEmpty(blockVolumeModel.instanceIdReferences)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instances({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={blockVolumeModel.instanceIdReferences}
                                entityTypeName={Contract.TypeNames.OciComputeInstance}
                                variant="itemCountAndType"/>,
                        translatedBlockVolumeTypeName
                    })),
        size:
            new RiskDefinitionContextItem(
                localization.size({
                    size: blockVolume.size,
                    translatedBlockVolumeTypeName
                }))
    };
}