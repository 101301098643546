import { UnexpectedError } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";

export class ConditionsHelper {
    public static createCondition(type: ConditionType, not: boolean, value?: string | Contract.ResourceTagPattern): Contract.ProjectConfigurationRuleCondition {
        switch (type) {
            case ConditionType.EntityNamePattern:
                return new Contract.ProjectConfigurationRuleAzureResourceGroupNamePatternEntityCondition(
                    not,
                    defaultCaseSensitive,
                    value as string ?? "");
            case ConditionType.EntityTagPattern:
                return new Contract.ProjectConfigurationRuleTagPatternEntityCondition(
                    not,
                    value as Contract.ResourceTagPattern ?? new Contract.ResourceTagPattern(defaultCaseSensitive, "", ""));
            case ConditionType.TenantNamePattern:
                return new Contract.ProjectConfigurationRuleNamePatternTenantCondition(
                    not,
                    defaultCaseSensitive,
                    value as string ?? "");
            case ConditionType.TenantTagPattern:
                return new Contract.ProjectConfigurationRuleTagPatternTenantCondition(
                    not,
                    value as Contract.ResourceTagPattern ?? new Contract.ResourceTagPattern(defaultCaseSensitive, "", ""));
            default:
                throw new UnexpectedError("type", type);
        }
    }
}

export enum ConditionType {
    EntityNamePattern = Contract.TypeNames.ProjectConfigurationRuleAzureResourceGroupNamePatternEntityCondition,
    EntityTagPattern = Contract.TypeNames.ProjectConfigurationRuleTagPatternEntityCondition,
    TenantNamePattern = Contract.TypeNames.ProjectConfigurationRuleNamePatternTenantCondition,
    TenantTagPattern = Contract.TypeNames.ProjectConfigurationRuleTagPatternTenantCondition
}

const defaultCaseSensitive = false;