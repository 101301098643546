import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsCloudTrailTrailRiskContext } from "../contexts";

export function useAwsCloudTrailTrailDigestDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsCloudTrailTrailDigestDisabledRisk;
    const trailModel = entityModelStore.useGet(risk.entityId) as Contract.AwsCloudTrailTrailModel;

    const getAwsCloudTrailTrailRiskContext = useGetAwsCloudTrailTrailRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudTrailTrailDigestDisabledRiskDefinition",
            () => ({
                description: "{{trail}} is not configured with log file validation",
                sections: {
                    resolution: {
                        step1: "click **Edit** In the **General details** section",
                        step2: "Set the **Log file validation** to **Enabled**",
                        step3: "Click **Save changes**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            trail:
                <Entity
                    entityIdOrModel={trailModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudTrail,
                AwsConsoleUrlBuilder.getCloudTrailTrailUrl(trailModel.entity as Contract.AwsCloudTrailTrail)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const trailRiskContext = getAwsCloudTrailTrailRiskContext(trailModel);
            return [
                trailRiskContext.generalInformation,
                trailRiskContext.sensitive,
                trailRiskContext.enabled,
                trailRiskContext.organizationTrail,
                trailRiskContext.bucketName,
                trailRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)];
        });
}