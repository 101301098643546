import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsEc2NatGatewayDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const natGateway = resourceModel.entity as Contract.AwsEc2NatGateway;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2NatGatewayDefinition",
            () => ({
                info: {
                    items: {
                        privateIpAddress: "Private IP Addresses",
                        publicIpAddress: "Elastic IP Addresses",
                        subnet: "Subnet",
                        vpcId: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={natGateway.vpcId}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpcId"
                        title={localization.info.items.vpcId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={natGateway.subnetId}
                        entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                        key="subnet"
                        title={localization.info.items.subnet()}/>,
                    <VerticalTopItemsInfoItem
                        items={natGateway.publicIpAddresses}
                        key="publicIpAddresses"
                        title={localization.info.items.publicIpAddress()}/>,
                    <VerticalTopItemsInfoItem
                        items={natGateway.privateIpAddresses}
                        key="privateIpAddress"
                        title={localization.info.items.privateIpAddress()}/>
                ]}
                options={options?.infoOptions}/>
    });
}