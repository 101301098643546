import { Loading, useRoute, useSetRoute } from "@infrastructure";
import { Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { CustomerConsoleAppUrlHelper, entityModelStore, EntityTypeMetadataModelHelper, StorageHelper, useTheme } from "../../../../../../../../common";
import { EntityProfileDefinitionTab, ProfileCategory } from "../../hooks";
import { ProfileView } from "../../Profile";
import { ElementClass } from "./Category.element";

type CategoryProps = {
    category: ProfileCategory;
    entityId: string;
    initView?: boolean;
    tabs: EntityProfileDefinitionTab[];
    title: string;
};

export function Category({ category, entityId, initView = false, tabs, title }: CategoryProps) {
    const entityModel = entityModelStore.useGet(entityId);

    const { view } = useRoute(`${CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel)}/${category}/{view}`);
    const setRoute = useSetRoute();

    const [views, viewToTabMap] =
        useMemo(
            () => {
                const views =
                    _.map(
                        tabs,
                        tab => tab.view);

                const viewToTabMap =
                    _.keyBy(
                        tabs,
                        tab => tab.view);

                return [views, viewToTabMap];
            },
            [tabs]);

    const currentView =
        useMemo(
            () =>
                _.includes(views, view)
                    ? view
                    : _.first(views)!,
            [views, view]);

    useEffect(
        () => {
            if (initView && _.isNil(view)) {
                setRoute(
                    CustomerConsoleAppUrlHelper.getEntityProfileHashUrl(
                        entityId,
                        {
                            category,
                            view: currentView as ProfileView
                        }),
                    undefined,
                    { appendBrowserHistory: false });
            }
        },
        [category, entityId]);

    const theme = useTheme();
    return (
        <Stack
            className={ElementClass.categoryContainer}
            sx={{ height: "100%" }}>
            {(_.size(viewToTabMap) > 1 || title !== viewToTabMap[currentView].title && category !== ProfileCategory.Overview) &&
                <Tabs
                    indicatorColor="secondary"
                    sx={{
                        border: "unset",
                        padding: theme.spacing(0, 1.5)
                    }}
                    value={currentView}
                    variant="scrollable"
                    onChange={
                        (_event, view) => {
                            const entityViewName = EntityTypeMetadataModelHelper.get(entityModel.entity.typeName).entitiesViewName;
                            if (StorageHelper.customerEntitiesSelectedTableView.getValue() === entityViewName) {
                                StorageHelper.
                                    customerEntitiesProfileSelectedTab(entityViewName).
                                    setValue(`${category}/${view}`);
                            }
                            setRoute(`${CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel)}/${category}/${view}`);
                        }}>
                    {_.map(
                        views,
                        view =>
                            <Tab
                                key={view}
                                label={viewToTabMap[view].title}
                                sx={{
                                    fontWeight: 400,
                                    marginRight: theme.spacing(2),
                                    padding: 0
                                }}
                                value={view}/>)}
                </Tabs>}
            <Stack
                sx={{
                    height: "100%",
                    overflow: "hidden auto",
                    padding: theme.spacing(2, 2, 0),
                    width: "100%"
                }}>
                {!_.isNil(viewToTabMap[currentView]) && (
                    <Loading>
                        {viewToTabMap[currentView].element}
                    </Loading>)}
            </Stack>
        </Stack>);
}