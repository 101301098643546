import { Loading } from "@infrastructure";
import React from "react";
import { DocumentationView } from "./components";

type ProfileProps = {
    itemId: string;
};

export function Profile({ itemId }: ProfileProps) {
    return (
        <Loading>
            <DocumentationView
                itemId={itemId}
                style={{ height: "100%" }}/>
        </Loading>);
}