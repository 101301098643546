import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEc2VpcRiskContext() {
    return useMemo(
        () => useAwsEc2VpcRiskContext,
        []);
}

function useAwsEc2VpcRiskContext(vpcModel: Contract.AwsEc2VpcModel) {
    const vpc = vpcModel.entity as Contract.AwsEc2Vpc;
    const resourceRiskContext = useGetAwsResourceRiskContext()(vpcModel);
    const networkedResourceModels =
        entityModelStore.useGet(
            _(vpcModel.networkedResourceTypeNameToIdsMap).
                values().
                flatten().
                value());

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2VpcRiskContext",
            () => ({
                attachedResources: {
                    empty: "The {{translatedVpcTypeName}} is not attached to any resources",
                    text: "The {{translatedVpcTypeName}} is attached to {{resources}}"
                }
            }));
    const translatedVpcTypeName =
        entityTypeNameTranslator(
            vpc.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        attachedResources:
            new RiskDefinitionContextItem(
                (_.isEmpty(networkedResourceModels)
                    ? localization.attachedResources.empty
                    : localization.attachedResources.text)({
                    resources:
                        <InlineEntities
                            entityIdsOrModels={networkedResourceModels}
                            entityTypeName={Contract.TypeNames.IAwsNetworkedResource}
                            variant="itemCountAndType"/>,
                    translatedVpcTypeName
                }))
    };
}