﻿import { DropdownIcon, Optional, useLocalization } from "@infrastructure";
import { Autocomplete, CircularProgress, FormHelperText, TextField } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { useTheme } from "..";

type TicketingServiceMandatoryFieldSelectorProps = {
    disabled: boolean;
    disablePortal?: boolean;
    fetchOptionsExecuting: boolean;
    getOptionLabel?: (value: string) => string;
    mandatoryFieldDisplayName?: string;
    mandatoryFieldName: string;
    mandatoryFieldNameToErrorMessageMap: _.Dictionary<string | undefined>;
    mandatoryFieldNameToValuesMap: _.Dictionary<string[]>;
    multiple?: boolean;
    onTextChange?: (text: Optional<string>) => void;
    options: string[];
    setMandatoryFieldNameToErrorMessageMap: React.Dispatch<React.SetStateAction<_.Dictionary<string | undefined>>>;
    setMandatoryFieldNameToValuesMap: React.Dispatch<React.SetStateAction<_.Dictionary<string[]>>>;
};

export function TicketingServiceMandatoryFieldSelector({ disabled, disablePortal, fetchOptionsExecuting, getOptionLabel, mandatoryFieldDisplayName, mandatoryFieldName, mandatoryFieldNameToErrorMessageMap, mandatoryFieldNameToValuesMap, multiple, onTextChange, options, setMandatoryFieldNameToErrorMessageMap, setMandatoryFieldNameToValuesMap }: TicketingServiceMandatoryFieldSelectorProps) {
    const localization =
        useLocalization(
            "common.ticketingServiceMandatoryFieldSelector",
            () => ({
                empty: "No matching value",
                error: "{{fieldName}} cannot be empty"
            }));

    const theme = useTheme();
    return (
        <Fragment>
            <Autocomplete
                disabled={disabled}
                disablePortal={disablePortal}
                filterSelectedOptions={multiple}
                fullWidth={true}
                getOptionLabel={getOptionLabel}
                loadingText=""
                multiple={multiple}
                noOptionsText={localization.empty()}
                options={options}
                popupIcon={
                    fetchOptionsExecuting
                        ? <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>
                        : <DropdownIcon
                            sx={{
                                color:
                                    disabled
                                        ? theme.palette.text.disabled
                                        : theme.palette.text.secondary
                            }}/>}
                renderInput={
                    params => (
                        <TextField
                            {...params}
                            label={mandatoryFieldDisplayName ?? mandatoryFieldName}
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    sx: { maxHeight: "unset" }
                                }
                            }}
                            variant="outlined"/>)}
                slotProps={{
                    chip: {
                        clickable: true,
                        variant: "outlined"
                    }
                }}
                sx={{ display: "grid" }}
                value={
                    multiple
                        ? mandatoryFieldNameToValuesMap[mandatoryFieldName] ?? []
                        : mandatoryFieldNameToValuesMap[mandatoryFieldName]?.[0] ?? null}
                onChange={
                    (event, fieldOptionValue) => {
                        setMandatoryFieldNameToValuesMap(
                            mandatoryFieldNameToValuesMap => ({
                                ...mandatoryFieldNameToValuesMap,
                                [mandatoryFieldName]:
                                    _.isNil(fieldOptionValue)
                                        ? []
                                        : _.concat(fieldOptionValue)
                            }));
                        setMandatoryFieldNameToErrorMessageMap(
                            mandatoryFieldNameToErrorMessageMap => ({
                                ...mandatoryFieldNameToErrorMessageMap,
                                [mandatoryFieldName]:
                                    _.isEmpty(fieldOptionValue)
                                        ? localization.error({ fieldName: mandatoryFieldDisplayName ?? mandatoryFieldName })
                                        : undefined
                            }));
                    }}
                onInputChange={(event, value) => onTextChange?.(value)}/>
            {!_.isNil(mandatoryFieldNameToErrorMessageMap[mandatoryFieldName]) && (
                <FormHelperText error={true}>
                    {mandatoryFieldNameToErrorMessageMap[mandatoryFieldName]}
                </FormHelperText>)}
        </Fragment>);
}