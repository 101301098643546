import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { Store } from "@infrastructure";
import { Contract, projectModelStore, ScopeHelper, scopeNodeModelStore, TenantController, TypeHelper, UserHelper, useSelectedScopeId } from "..";

export class TenantModelStore extends Store<Contract.TenantModel, never, never> {
    public updateDeleted =
        async (id: string) => {
            const { deletedTenantModels } = await TenantController.deleteTenant(new Contract.TenantControllerDeleteTenantRequest(id));
            await this.notify(deletedTenantModels);
        };

    public useGetActiveCloudProviderTenants =
        (tenantTypes?: Contract.TenantType[]) =>
            this.useGetPermittedTenants(
                Contract.TypeNames.CloudProviderTenantConfiguration,
                tenantModel =>
                    (_.isNil(tenantTypes) || _.includes(tenantTypes, tenantModel.tenantType)) &&
                    tenantModel.configuration.active);

    public useGetActiveCloudProviderTenantTypes =
        () => {
            const activeCloudProviderTenantModels = tenantModelStore.useGetActiveCloudProviderTenants();
            return useMemo(
                () =>
                    _(activeCloudProviderTenantModels).
                        map(activeCloudProviderTenantModel => activeCloudProviderTenantModel.tenantType).
                        uniq().
                        value(),
                [activeCloudProviderTenantModels]);
        };

    public useGetActiveGitTenants =
        (tenantTypes?: Contract.TenantType[]) =>
            this.useGetPermittedTenants(
                Contract.TypeNames.GitTenantConfiguration,
                tenantModel =>
                    (_.isNil(tenantTypes) || _.includes(tenantTypes, tenantModel.tenantType)) &&
                    tenantModel.configuration.active);

    public useGetActiveTenants =
        (tenantTypes?: Contract.TenantType[]) =>
            this.useGetPermittedTenants(
                undefined,
                tenantModel =>
                    (_.isNil(tenantTypes) || _.includes(tenantTypes, tenantModel.tenantType)) &&
                    tenantModel.configuration.active);

    public useGetActiveTenantTypes =
        () => {
            const activeTenantModels = tenantModelStore.useGetActiveTenants();
            return useMemo(
                () =>
                    _(activeTenantModels).
                        map(activeTenantModel => activeTenantModel.tenantType).
                        uniq().
                        value(),
                [activeTenantModels]);
        };

    public useGetAadTenants =
        () => {
            const tenantModels = this.useGetAll();
            return useMemo(
                () =>
                    _.filter(
                        tenantModels,
                        tenantModel => TypeHelper.extendOrImplement(tenantModel.configuration.typeName, Contract.TypeNames.AadTenantConfiguration)),
                [tenantModels]) as Contract.AadTenantModel[];
        };

    public useGetGciTenants =
        () => {
            const tenantModels = this.useGetAll();
            return useMemo(
                () =>
                    _.filter(
                        tenantModels,
                        tenantModel => TypeHelper.extendOrImplement(tenantModel.configuration.typeName, Contract.TypeNames.GciTenantConfiguration)),
                [tenantModels]) as Contract.GciTenantModel[];
        };

    public useGetAzureTenantMap =
        () => this.useGetTenantMap(Contract.TypeNames.AzureTenantConfiguration) as Dictionary<Contract.AzureTenantModel>;

    public useGetAwsTenantMap =
        () => this.useGetTenantMap(Contract.TypeNames.AwsTenantConfiguration) as Dictionary<Contract.AwsTenantModel>;

    public useGetCiTenantMap =
        () => this.useGetTenantMap(Contract.TypeNames.CiTenantConfiguration) as Dictionary<Contract.CiTenantModel>;

    public useGetCodeTenantMap =
        () => this.useGetTenantMap(Contract.TypeNames.CodeTenantConfiguration) as Dictionary<Contract.CodeTenantModel>;

    public useGetGcpTenantMap =
        () => this.useGetTenantMap(Contract.TypeNames.GcpTenantConfiguration) as Dictionary<Contract.GcpTenantModel>;

    public useGetOciTenantMap =
        () => this.useGetTenantMap(Contract.TypeNames.OciTenantConfiguration) as Dictionary<Contract.OciTenantModel>;

    public useGetOpTenantMap =
        () => this.useGetTenantMap(Contract.TypeNames.OpTenantConfiguration) as Dictionary<Contract.OpTenantModel>;

    public useGetFilteredActiveCloudProviderTenants =
        (tenantTypes?: Contract.TenantType[]) => {
            const activeCloudProviderTenantModels = tenantModelStore.useGetActiveCloudProviderTenants(tenantTypes);
            return this.useGetFilteredTenantsCore(activeCloudProviderTenantModels);
        };

    public useGetFilteredActiveCloudProviderTenantsExcessivePermissionsEnabled =
        () => {
            const filteredActiveCloudProviderTenantModels = tenantModelStore.useGetFilteredActiveCloudProviderTenants();
            return useMemo(
                () =>
                    _.some(
                        filteredActiveCloudProviderTenantModels,
                        filteredActiveCloudProviderTenantModel =>
                            filteredActiveCloudProviderTenantModel.tenantType !== Contract.TenantType.Aws ||
                            (filteredActiveCloudProviderTenantModel.configuration as Contract.AwsTenantConfiguration).accessAdvisorEnabled),
                [filteredActiveCloudProviderTenantModels]);
        };

    public useGetFilteredActiveCodeTenants =
        () => {
            const activeCodeTenantModels = tenantModelStore.useGetActiveTenants([Contract.TenantType.Code]);
            return this.useGetFilteredTenantsCore(activeCodeTenantModels);
        };

    public useGetFilteredActiveTenants =
        (tenantTypes?: Contract.TenantType[]) => {
            const activeTenantModels = tenantModelStore.useGetActiveTenants(tenantTypes);
            return this.useGetFilteredTenantsCore(activeTenantModels);
        };

    public useGetFilteredActiveTenantTypes =
        () => {
            const activeTenantModels = tenantModelStore.useGetActiveTenants();
            const filteredActiveTenantIds = this.useGetFilteredActiveTenantIds(activeTenantModels);
            return useMemo(
                () =>
                    _(activeTenantModels).
                        filter(
                            activeTenantModel =>
                                _.includes(filteredActiveTenantIds, activeTenantModel.configuration.id)).
                        map(activeTenantModel => activeTenantModel.tenantType).
                        uniq().
                        value(),
                [filteredActiveTenantIds]);
        };

    public useGetFilteredCloudProviderTenants =
        (tenantTypes?: Contract.TenantType[]) => {
            const cloudProviderTenantModels = this.useGetPermittedCloudProviderTenants(tenantTypes);
            return this.useGetFilteredTenantsCore(cloudProviderTenantModels);
        };

    public useGetFilteredGitTenants =
        () => {
            const gitTenantModels = this.useGetPermittedGitTenants();
            return this.useGetFilteredTenantsCore(gitTenantModels) as Contract.GitTenantModel[];
        };

    public useGetPermittedAadTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.AadTenantConfiguration) as Contract.AadTenantModel[];

    public useGetPermittedAwsTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.AwsTenantConfiguration) as Contract.AwsTenantModel[];

    public useGetPermittedAzureTenants =
        (aadTenantId?: string) =>
            this.useGetPermittedTenants(
                Contract.TypeNames.AzureTenantConfiguration,
                _.isNil(aadTenantId)
                    ? undefined
                    : (tenantModel: Contract.TenantModel) => (tenantModel.configuration as Contract.AzureTenantConfiguration).aadTenantId === aadTenantId) as Contract.AzureTenantModel[];

    public useGetPermittedCiTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.CiTenantConfiguration) as Contract.CiTenantModel[];

    public useGetPermittedGciTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.GciTenantConfiguration) as Contract.GciTenantModel[];

    public useGetPermittedGcpTenants =
        (gciTenantId?: string) =>
            this.useGetPermittedTenants(
                Contract.TypeNames.GcpTenantConfiguration,
                _.isNil(gciTenantId)
                    ? undefined
                    : (tenantModel: Contract.TenantModel) => (tenantModel.configuration as Contract.GcpTenantConfiguration).gciTenantId === gciTenantId) as Contract.GcpTenantModel[];

    public useGetPermittedCloudProviderTenants =
        (tenantTypes?: Contract.TenantType[]) =>
            this.useGetPermittedTenants(
                Contract.TypeNames.CloudProviderTenantConfiguration,
                tenantModel =>
                    (_.isNil(tenantTypes) || _.includes(tenantTypes, tenantModel.tenantType)));

    public useGetPermittedCodeTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.CodeTenantConfiguration) as Contract.CodeTenantModel[];

    public useGetPermittedGeneralCodeTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.GeneralCodeTenantConfiguration) as Contract.GeneralCodeTenantModel[];

    public useGetPermittedGitTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.GitTenantConfiguration) as Contract.GitTenantModel[];

    public useGetPermittedOciTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.OciTenantConfiguration) as Contract.OciTenantModel[];

    public useGetPermittedOpTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.OpTenantConfiguration) as Contract.OpTenantModel[];

    public useGetPermittedOktaTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.OktaTenantConfiguration) as Contract.OktaTenantModel[];

    public useGetPermittedOneLoginTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.OneLoginTenantConfiguration) as Contract.OneLoginTenantModel[];

    public useGetPermittedPingIdentityTenants =
        () => this.useGetPermittedTenants(Contract.TypeNames.PingIdentityTenantConfiguration) as Contract.PingIdentityTenantModel[];

    private useGetFilteredActiveTenantIds =
        (tenantModels: Contract.TenantModel[]) => {
            const scopeNodeModels = scopeNodeModelStore.useGetAll();
            const activeScopeNodeMap =
                scopeNodeModelStore.useGetActiveScopeNodeMap(
                    undefined,
                    true);
            const projectModelMap = projectModelStore.useGetProjectModelMap();
            const { selectedScopeId } = useSelectedScopeId();

            return useMemo(
                () => {
                    const tenantIds =
                        _.map(
                            tenantModels,
                            tenantModel => tenantModel.configuration.id);

                    if (_.isEmpty(selectedScopeId)) {
                        return tenantIds;
                    }

                    const scopeNodeTree =
                        ScopeHelper.getScopeTree(
                            activeScopeNodeMap,
                            _.map(
                                scopeNodeModels,
                                scopeNodeModel => scopeNodeModel.configuration.id),
                            true,
                            true);

                    if (ScopeHelper.isProjectScope(activeScopeNodeMap[selectedScopeId].scopeNodeModel)) {
                        return _(scopeNodeTree[selectedScopeId].scopeIds).
                            filter(scopeId => !ScopeHelper.isFolder(activeScopeNodeMap[scopeId].scopeNodeModel)).
                            flatMap(scopeId => projectModelMap[scopeId].tenantIds).
                            uniq().
                            value();
                    }

                    return scopeNodeTree[selectedScopeId].tenantIds;
                },
                [activeScopeNodeMap, tenantModels, selectedScopeId, scopeNodeModels, projectModelMap]);
        };

    private useGetFilteredTenantsCore =
        (tenantModels: Contract.TenantModel[]) => {
            const filteredActiveTenantIds = this.useGetFilteredActiveTenantIds(tenantModels);
            return useMemo(
                () =>
                    _.filter(
                        tenantModels,
                        tenantModel => _.includes(filteredActiveTenantIds, tenantModel.configuration.id)),
                [filteredActiveTenantIds]);
        };

    private useGetPermittedTenants =
        (typeName?: string, tenantModelFilter?: (tenantModel: Contract.TenantModel) => boolean) => {
            const permittedProjects = projectModelStore.useGetPermittedProjects();
            const tenantModels = this.useGetAll();
            return useMemo(
                () => {
                    const permittedProjectTenantIds =
                        new Set(
                            _.flatMap(
                                permittedProjects,
                                projectModel => projectModel.tenantIds));

                    return _.filter(
                        tenantModels,
                        tenantModel => (
                            _.isNil(typeName) ||
                                TypeHelper.extendOrImplement(tenantModel.configuration.typeName, typeName)) &&
                            tenantModelFilter?.(tenantModel) !== false &&
                            (
                                permittedProjectTenantIds.has(tenantModel.configuration.id) ||
                                UserHelper.hasScopePermissions(
                                    tenantModel.configuration.id,
                                    Contract.IdentityPermission.PermissionManagementAdministrationRead,
                                    Contract.IdentityPermission.PermissionManagementPermissionRequest,
                                    Contract.IdentityPermission.PermissionManagementResourceRead,
                                    Contract.IdentityPermission.SecurityRead)));
                },
                [permittedProjects, tenantModels, typeName]);
        };

    private useGetTenantMap =
        (typeName?: string, tenantModelFilter?: (tenantModel: Contract.TenantModel) => boolean) => {
            const tenantModels = this.useGetPermittedTenants(typeName, tenantModelFilter);
            return useMemo(
                () =>
                    _.keyBy(
                        tenantModels,
                        tenantModel => tenantModel.configuration.id),
                [typeName, tenantModels]);
        };
}

export const tenantModelStore =
    new TenantModelStore(
        tenantModel => tenantModel.configuration.id,
        {
            get:
                async ids => {
                    const { tenantModels } = await TenantController.getTenantModels(new Contract.TenantControllerGetTenantModelsRequest(ids));
                    return tenantModels;
                },
            getAll:
                async () => {
                    const { tenantModels } = await TenantController.getTenantModels(new Contract.TenantControllerGetTenantModelsRequest(undefined));
                    return tenantModels;
                },
            onNotify:
                async (tenantModels?: Contract.TenantModel[]) => {
                    await scopeNodeModelStore.notify(
                        _.isNil(tenantModels)
                            ? undefined
                            : _.map(
                                tenantModels,
                                tenantModel => tenantModel.configuration.id));
                },
            onNotifyDeleted:
                async (ids: string[]) => {
                    await scopeNodeModelStore.notifyDeleted(ids);
                }
        });