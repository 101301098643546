import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, InlineTextViewer } from "../../../../../../../../../common";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskJiraIssueAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);

    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskJiraIssueAuditEventDefinition",
            () => ({
                jiraError: {
                    text: "Show more",
                    title: "Jira Error"
                },
                jiraIssuePriority: "Priority",
                jiraIssueStatus: "Status",
                jiraIssueUrl: "Link to issue"
            }));

    const riskJiraIssueAuditEvent = context.auditEventModel.auditEvent as Contract.RiskJiraIssueAuditEvent;
    let detailsItems = [
        riskAuditEventDetailsItems.riskPolicyTypeItem,
        riskAuditEventDetailsItems.riskIdItem,
        riskAuditEventDetailsItems.riskDeletedItem,
        riskAuditEventDetailsItems.riskedEntitiesItem,
        riskAuditEventDetailsItems.riskLinkItem,
        _.isNil(riskJiraIssueAuditEvent.jiraIssueUrl)
            ? undefined!
            : new AuditEventDefinitionDetailItem(
                localization.jiraIssueUrl(),
                riskJiraIssueAuditEvent.jiraIssueUrl,
                <Link
                    urlOrGetUrl={riskJiraIssueAuditEvent.jiraIssueUrl}
                    variant="external"/>),
        riskAuditEventDetailsItems.errorMessageItem,
        _.isNil(riskJiraIssueAuditEvent.jiraRawError)
            ? undefined!
            : new AuditEventDefinitionDetailItem(
                localization.jiraError.title(),
                localization.jiraError.text(),
                <InlineTextViewer
                    dialogTitle={localization.jiraError.title()}
                    text={JSON.stringify(JSON.parse(riskJiraIssueAuditEvent.jiraRawError), undefined, 2)}
                    title={localization.jiraError.text()}/>)];
    if (riskJiraIssueAuditEvent.typeName == Contract.TypeNames.RiskJiraIssueUpdateAuditEvent) {
        detailsItems =
            _(detailsItems).
                concatIf(
                    !_.isNil((riskJiraIssueAuditEvent as Contract.RiskJiraIssueUpdateAuditEvent).status),
                    () => new AuditEventDefinitionDetailItem(
                        localization.jiraIssueStatus(),
                        (riskJiraIssueAuditEvent as Contract.RiskJiraIssueUpdateAuditEvent).status!)).
                concatIf(
                    !_.isNil((riskJiraIssueAuditEvent as Contract.RiskJiraIssueUpdateAuditEvent).priority),
                    () => new AuditEventDefinitionDetailItem(
                        localization.jiraIssuePriority(),
                        (riskJiraIssueAuditEvent as Contract.RiskJiraIssueUpdateAuditEvent).priority!)).
                value();
    }

    return new AuditEventDefinition(_.filter(detailsItems) as AuditEventDefinitionDetailItem[]);
}