import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsSageMakerNotebookInstanceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsSageMakerNotebookInstanceStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsSageMakerNotebookInstanceStatus]: {
                    [Contract.AwsSageMakerNotebookInstanceStatus.Deleting]: "Deleting",
                    [Contract.AwsSageMakerNotebookInstanceStatus.Failed]: "Failed",
                    [Contract.AwsSageMakerNotebookInstanceStatus.InService]: "InService",
                    [Contract.AwsSageMakerNotebookInstanceStatus.Pending]: "Pending",
                    [Contract.AwsSageMakerNotebookInstanceStatus.Stopped]: "Stopped",
                    [Contract.AwsSageMakerNotebookInstanceStatus.Stopping]: "Stopping",
                    [Contract.AwsSageMakerNotebookInstanceStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.AwsSageMakerNotebookInstanceStatus) =>
        localization[Contract.TypeNames.AwsSageMakerNotebookInstanceStatus][status]();
}