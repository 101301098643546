import { makeContextProvider } from "@infrastructure";

export type ApplicationViewValue = "customer" | "system" | "teams" | "user";

interface ApplicationContext {
    customerActionAvailable: boolean;
    permittedContent: boolean;
    selectedScopeHasData?: boolean;
    viewValue?: ApplicationViewValue;
}

export const [useApplicationContext, useSetApplicationContext, useApplicationContextProvider] = makeContextProvider<ApplicationContext>();