import { useExecuteOperation, useLocalization } from "@infrastructure";
import { Stack, Switch, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AdministrationController, Contract, useTheme } from "../../../../../common";

export function Features() {
    const [{ enabledFeatureNames: initialEnabledFeatureNames, featureNames }] =
        useExecuteOperation(
            Features,
            AdministrationController.getFeatures);

    const localization =
        useLocalization(
            "views.customer.administration.features",
            () => ({
                [Contract.TypeNames.FeatureName]: {
                    [Contract.FeatureName.ExcessivePermissionPrincipalRiskLegacySeverityEnabled]: "Excessive permission principal risk legacy severity enabled",
                    [Contract.FeatureName.LicensingUpdateDisabled]: "Licensing update disabled",
                    [Contract.FeatureName.UiEventsReportEnabled]: "UI Events report enabled"
                }
            }));

    const [enabledFeatureNames, setEnabledFeatureNames] = useState(initialEnabledFeatureNames);
    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{
                height: "100%",
                overflow: "auto",
                padding: theme.spacing(2, 2)
            }}>
            <Stack
                alignItems="start"
                spacing={3}
                sx={{
                    padding: theme.spacing(2),
                    width: theme.spacing(60)
                }}>
                {_.map(
                    featureNames,
                    featureName =>
                        <Stack
                            alignItems="center"
                            direction="row"
                            key={featureName}
                            sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    flex: 1,
                                    fontWeight: 500
                                }}
                                variant="h4">
                                {localization[Contract.TypeNames.FeatureName].translate(featureName)}
                            </Typography>
                            <Switch
                                checked={_.includes(enabledFeatureNames, featureName)}
                                color="secondary"
                                size="small"
                                onChange={
                                    async (event, checked) => {
                                        setEnabledFeatureNames(
                                            checked
                                                ? _.concat(enabledFeatureNames, featureName)
                                                : _.without(enabledFeatureNames, featureName));

                                        await AdministrationController.updateFeature(
                                            new Contract.AdministrationControllerUpdateFeatureRequest(
                                                checked,
                                                featureName));
                                    }}/>
                        </Stack>)}
            </Stack>
        </Stack>);
}