import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { Events } from "../../../../../../../../../Events";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsIamRoleManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamRoleManagementRisk;
    const roleModel = entityModelStore.useGet(_.head(risk.resourceIds)!);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsIamRoleManagementRiskDefinition",
            () => ({
                violations: "{{role}} trust policy was modified"
            }));
    const translatedViolations =
        localization.violations({
            role:
                <Entity
                    entityIdOrModel={roleModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <Events riskId={risk.id}/>);
}