﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpPubSubSubscriptionMessageDeliveryTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpPubSubSubscriptionMessageDeliveryTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpPubSubSubscriptionMessageDeliveryType]: {
                    [Contract.GcpPubSubSubscriptionMessageDeliveryType.Pull]: "Pull",
                    [Contract.GcpPubSubSubscriptionMessageDeliveryType.Push]: "Push"
                }
            }));
    return (type: Contract.GcpPubSubSubscriptionMessageDeliveryType) =>
        localization[Contract.TypeNames.GcpPubSubSubscriptionMessageDeliveryType][type]();
}