import { Store } from "@infrastructure";
import { ConfigurationController, Contract } from "..";

export const automaticCustomEntityAttributeDefinitionTemplateModelStore =
    new Store<Contract.AutomaticCustomEntityAttributeDefinitionTemplateModel, never, never>(
        automaticCustomEntityAttributeDefinitionTemplateModel => automaticCustomEntityAttributeDefinitionTemplateModel.configuration.id,
        {
            getAll:
                async () => {
                    const { automaticCustomEntityAttributeDefinitionTemplateModels } = await ConfigurationController.getAutomaticCustomEntityAttributeDefinitionTemplateModels();
                    return automaticCustomEntityAttributeDefinitionTemplateModels;
                }
        });