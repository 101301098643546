import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class ServiceNowContext {
    constructor(public addOrEditOpen: boolean | Contract.ScopeSystemEntityModel) {
    }
}

export const [useServiceNowContext, useSetServiceNowContext, useServiceNowContextProvider] = makeContextProvider<ServiceNowContext>();

export function ServiceNow() {
    const [context, setContext, ContextProvider] = useServiceNowContextProvider(() => new ServiceNowContext(false));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                })
                            )}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}