import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, optionalTableCell, useLocalization, useLocalizeList } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InfoCard, useEntityTypeNameTranslator } from "../../../../../../../../../common";

type OciNetworkingSubnetsInfoCardProps = {
    subnetIds: string[];
};

export function OciNetworkingSubnetsInfoCard({ subnetIds }: OciNetworkingSubnetsInfoCardProps) {
    const subnetModels = entityModelStore.useGet(subnetIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.ociNetworkingSubnetsInfoCard",
            () => ({
                columns: {
                    flowLogsEnabled: {
                        false: "Disabled",
                        title: "Flow Logs",
                        true: "Enabled"
                    },
                    ipv4Subnet: "IPv4 CIDR Block",
                    ipv6Subnets: "IPv6 Prefixes",
                    name: "Name",
                    prohibitPublicIpOnVnic: {
                        false: "Public",
                        title: "Subnet Access",
                        true: "Private"
                    }
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Subnets"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.OciNetworkingSubnet,
                                        { count: 0 })
                            }))
                }}
                fetchItems={() => subnetModels}
                getItemId={(item: Contract.EntityModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={SubnetColumnId.Name}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTenant"/>}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={SubnetColumnId.Ipv4Subnet}
                    itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.OciNetworkingSubnet).ipv4Subnet}
                    title={localization.columns.ipv4Subnet()}/>
                <DataTableColumn
                    id={SubnetColumnId.Ipv6Subnets}
                    render={optionalTableCell<Contract.EntityModel>(item => localizeList((item.entity as Contract.OciNetworkingSubnet).ipv6Subnets))}
                    title={localization.columns.ipv6Subnets()}/>
                <DataTableColumn
                    id={SubnetColumnId.ProhibitPublicIpOnVnic}
                    itemProperty={
                        (item: Contract.EntityModel) =>
                            (item.entity as Contract.OciNetworkingSubnet).prohibitPublicIpOnVnic
                                ? localization.columns.prohibitPublicIpOnVnic.true()
                                : localization.columns.prohibitPublicIpOnVnic.false()}
                    title={localization.columns.prohibitPublicIpOnVnic.title()}/>
                <DataTableColumn
                    id={SubnetColumnId.FlowLogsEnabled}
                    itemProperty={
                        (item: Contract.OciNetworkingSubnetModel) =>
                            item.flowLogsEnabled
                                ? localization.columns.flowLogsEnabled.true()
                                : localization.columns.flowLogsEnabled.false()}
                    title={localization.columns.flowLogsEnabled.title()}/>
            </DataTable>
        </InfoCard>);
}

enum SubnetColumnId {
    FlowLogsEnabled = "flowLogsEnabled",
    Ipv4Subnet = "ipv4Subnet",
    Ipv6Subnets = "ipv6Subnets",
    Name = "name",
    ProhibitPublicIpOnVnic = "prohibitPublicIpOnVnic"
}