import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, map, Optional, optionalTableCell, useLocalization, useLocalizeList, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ReplaceRoleBindingsChangeDiff } from ".";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, EntityFilter, entityModelStore, InlineEntities, ItemTable } from "../../../../../../../../../../../../../../common";
import { useGcpIamRoleBindingPermissionUsageTypeTranslator } from "../../../../../../../../../../../../../../tenants";
import { ProfileCategory } from "../../../../../../../../../../../Entities/components/Profile/hooks";

export type IamRoleBindingTableProps = {
    csvExportFilePrefixes: string[];
    risk: Contract.GcpExcessivePermissionPrincipalRisk;
};

export function RoleBindingTable({ csvExportFilePrefixes, risk }: IamRoleBindingTableProps) {
    const roleBindingModels = entityModelStore.useGet(_.keys(risk.roleBindingIdToDataMap)) as Contract.GcpIamRoleBindingModel[];
    const roleBindingRelatedEntityModels =
        entityModelStore.useGet(
            _(roleBindingModels).
                map(roleBindingModel => roleBindingModel.entity as Contract.GcpIamRoleBinding).
                flatMap(
                    roleBinding => [
                        roleBinding.roleId,
                        roleBinding.principalReference.id,
                        roleBinding.scopeResourceReference.idReference
                    ]).
                filter().
                as<string>().
                value());
    const resolutionRoleModels =
        entityModelStore.useGet(
            _.flatMap(
                risk.roleBindingIdToDataMap,
                roleBindingData => roleBindingData.resolutionRoleIds)) as Contract.GcpIamRoleModel[];

    const localizeList = useLocalizeList();
    const roleBindingPermissionUsageTypeTranslator = useGcpIamRoleBindingPermissionUsageTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpExcessivePermissionPrincipalRiskDefinition.roleBindingTable",
            () => ({
                columns: {
                    diff: "Diff",
                    permissionUsageType: {
                        helpText: "'Unknown' indicates that Tenable Cloud Security hasn't collected enough activity history to know with certainty whether the binding was active in the last 90 days.",
                        title: "Usage"
                    },
                    resolution: {
                        title: "Recommendation",
                        [Contract.TypeNames.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType]: {
                            [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.Delete]: "Remove binding",
                            [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.DeletePermissionOverlapExistingRole]: "Remove binding (permissions covered by {{scopeExistingResolutionRoleIds}})",
                            [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.DeletePermissionOverlapUnknownExistingRole]: "Remove binding (permissions covered by another role)",
                            [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.None]: "None",
                            [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.Replace]: "Replace with {{resolutionRoleIds}}",
                            [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.ReplacePermissionOverlapExistingRoleMany]: "Replace with {{resolutionRoleIds}} (some of the needed permissions are covered by {{scopeExistingResolutionRoleIds}} which are already bound to the same scope)",
                            [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.ReplacePermissionOverlapExistingRoleSingle]: "Replace with {{resolutionRoleIds}} (some of the needed permissions are covered by {{scopeExistingResolutionRoleIds}} which is already bound to the same scope)"
                        }
                    },
                    roleId: "Role",
                    scopeResourceReference: "Scope"
                },
                empty: "No Bindings"
            }));

    const [items, resolutionRoleModelsMap, roleBindingRelatedEntityModelMap] =
        useMemo(
            () => {
                const roleBindingRelatedEntityModelMap =
                    _.keyBy(
                        roleBindingRelatedEntityModels,
                        roleBindingRelatedEntityModel => roleBindingRelatedEntityModel.id);
                const resolutionRoleModelsMap =
                    _.keyBy(
                        resolutionRoleModels,
                        resolutionRoleEntityModel => resolutionRoleEntityModel.id);
                const items =
                    _(roleBindingModels).
                        map(
                            roleBindingModel => {
                                const roleBinding = roleBindingModel.entity as Contract.GcpIamRoleBinding;
                                const data = risk.roleBindingIdToDataMap[roleBinding.id];
                                return ({
                                    resolutionRoleIds: data.resolutionRoleIds,
                                    resolutionType: data.resolutionType,
                                    roleBinding,
                                    roleBindingModel,
                                    scopeExistingResolutionRoleIds: data.scopeExistingResolutionRoleIds
                                });
                            }).
                        as<RoleBindingTableItem>().
                        value();
                return [items, resolutionRoleModelsMap, roleBindingRelatedEntityModelMap];
            },
            [roleBindingModels, roleBindingRelatedEntityModels]);

    const getResolutionRoleIdsPropCsvValue =
        (roleIds: string[], roleMap: _.Dictionary<Contract.EntityModel>) =>
            _.isEmpty(roleIds)
                ? undefined
                : roleIds.length === 1
                    ? `role ${roleMap[roleIds[0]].entity.displayReference}`
                    : `roles ${localizeList(
                        _.map(
                            roleIds,
                            resolutionRoleId => roleMap[resolutionRoleId].entity.displayReference
                        ))}`;
    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [RoleBindingTableColumnId.Resolution]: {
                    getFilterValue: item => item.resolutionType,
                    getSortValue: item =>
                        map(
                            item.resolutionType,
                            {
                                [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.Delete]: () => 0,
                                [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.DeletePermissionOverlapExistingRole]: () => 1,
                                [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.Replace]: () => 2,
                                [Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.None]: () => 3
                            },
                            () => 4)
                },
                [RoleBindingTableColumnId.RoleId]: {
                    getFilterValue: item => item.roleBinding.roleId,
                    getSortValue: item => roleBindingRelatedEntityModelMap[item.roleBinding.roleId].entity.displayName
                },
                [RoleBindingTableColumnId.ScopeResourceReference]: {
                    getFilterValue: item => item.roleBinding.scopeResourceReference.idReference,
                    getSortValue: item => roleBindingRelatedEntityModelMap[item.roleBinding.scopeResourceReference.idReference].entity.displayName
                },
                [RoleBindingTableColumnId.PermissionUsageType]: item => {
                    const permissionUsageType = item.roleBindingModel.permissionUsageType;
                    return _.isNil(permissionUsageType)
                        ? "-"
                        : roleBindingPermissionUsageTypeTranslator(permissionUsageType);
                }
            }}
            csvExportFilePrefixes={csvExportFilePrefixes}
            defaultSortColumnIdOrIds={RoleBindingTableColumnId.Resolution}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => {
                    const permissionUsageType = item.roleBindingModel.permissionUsageType;
                    return ({
                        /* eslint-disable sort-keys-fix/sort-keys-fix */
                        "Role": roleBindingRelatedEntityModelMap[item.roleBinding.roleId].entity.displayReference,
                        "Scope": roleBindingRelatedEntityModelMap[item.roleBinding.scopeResourceReference.idReference].entity.displayReference,
                        "Usage":
                            _.isNil(permissionUsageType)
                                ? "-"
                                : roleBindingPermissionUsageTypeTranslator(permissionUsageType),
                        "Recommendation":
                            _.isNil(item.resolutionType)
                                ? "-"
                                : localization.columns.resolution[Contract.TypeNames.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType][item.resolutionType]({
                                    resolutionRoleIds:
                                        getResolutionRoleIdsPropCsvValue(
                                            item.resolutionRoleIds,
                                            resolutionRoleModelsMap),
                                    scopeExistingResolutionRoleIds:
                                        getResolutionRoleIdsPropCsvValue(
                                            item.scopeExistingResolutionRoleIds,
                                            roleBindingRelatedEntityModelMap)
                                })
                        /* eslint-enable sort-keys-fix/sort-keys-fix */
                    });
                }}
            getItemId={item => item.roleBinding.id}
            items={items}
            rowOptions={{
                getUrl: (item: RoleBindingTableItem) => CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(item.roleBindingModel, { category: ProfileCategory.Overview })!
            }}>
            {columnIdToItemValuesMap =>
                [
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[RoleBindingTableColumnId.RoleId]}
                                        placeholder={localization.columns.roleId()}/>
                            }
                        }}
                        id={RoleBindingTableColumnId.RoleId}
                        key={RoleBindingTableColumnId.RoleId}
                        render={
                            ({ item }: DataTableColumnRenderProps<RoleBindingTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.roleBinding.roleId}
                                    entityTypeName={Contract.TypeNames.GcpIamRole}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.columns.roleId()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[RoleBindingTableColumnId.ScopeResourceReference]}
                                        placeholder={localization.columns.scopeResourceReference()}/>
                            }
                        }}
                        id={RoleBindingTableColumnId.ScopeResourceReference}
                        key={RoleBindingTableColumnId.ScopeResourceReference}
                        render={
                            ({ item }: DataTableColumnRenderProps<RoleBindingTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.roleBinding.scopeResourceReference.idReference}
                                    entityTypeName={Contract.TypeNames.GcpScopeResource}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.columns.scopeResourceReference()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter placeholder={localization.columns.permissionUsageType.title()}>
                                        {_.map(
                                            columnIdToItemValuesMap[RoleBindingTableColumnId.PermissionUsageType],
                                            permissionUsageType =>
                                                <ValuesFilterItem
                                                    key={permissionUsageType}
                                                    value={permissionUsageType}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={RoleBindingTableColumnId.PermissionUsageType}
                        itemProperty={
                            (item: RoleBindingTableItem) => {
                                const permissionUsageType = item.roleBindingModel.permissionUsageType;
                                return _.isNil(permissionUsageType)
                                    ? "-"
                                    : roleBindingPermissionUsageTypeTranslator(permissionUsageType);
                            }}
                        key={RoleBindingTableColumnId.PermissionUsageType}
                        message={localization.columns.permissionUsageType.helpText()}
                        messageLevel="info"
                        title={localization.columns.permissionUsageType.title()}/>,
                    <DataTableColumn
                        id={RoleBindingTableColumnId.Resolution}
                        key={RoleBindingTableColumnId.Resolution}
                        render={
                            optionalTableCell<RoleBindingTableItem>(
                                item =>
                                    _.isNil(item.resolutionType)
                                        ? undefined
                                        : <Typography>
                                            {localization.columns.resolution[Contract.TypeNames.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType][item.resolutionType]({
                                                resolutionRoleIds:
                                                    <InlineEntities
                                                        entityIdsOrModels={item.resolutionRoleIds}
                                                        entityLinkOptions={{ disabled: true }}
                                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                                        variant="itemAndTypeOrItemCountAndType"/>,
                                                scopeExistingResolutionRoleIds:
                                                    <InlineEntities
                                                        entityIdsOrModels={item.scopeExistingResolutionRoleIds}
                                                        entityLinkOptions={{ disabled: true }}
                                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                                        variant="itemAndTypeOrItemCountAndType"/>
                                            })}
                                        </Typography>)}
                        title={localization.columns.resolution.title()}/>,
                    <DataTableColumn
                        id={RoleBindingTableColumnId.Diff}
                        key={RoleBindingTableColumnId.Diff}
                        render={
                            optionalTableCell<RoleBindingTableItem>(
                                item =>
                                    item.resolutionType === Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType.Replace
                                        ? <ReplaceRoleBindingsChangeDiff
                                            existingRoleIds={[item.roleBinding.roleId]}
                                            newRoleIds={item.resolutionRoleIds}/>
                                        : undefined)}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.diff()}/>
                ]}
        </ItemTable>);
}

export enum RoleBindingTableColumnId {
    Diff = "diff",
    PermissionUsageType = "permissionUsageType",
    Resolution = "resolution",
    RoleId = "roleId",
    ScopeResourceReference = "scopeResourceReference"
}

type RoleBindingTableItem = {
    resolutionRoleIds: string[];
    resolutionType: Optional<Contract.GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType>;
    roleBinding: Contract.GcpIamRoleBinding;
    roleBindingModel: Contract.GcpIamRoleBindingModel;
    scopeExistingResolutionRoleIds: string[];
};