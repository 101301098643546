import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpPubSubTopicDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const topic = entityModel.entity as Contract.GcpPubSubTopic;
    const tenantModel = tenantModelStore.useGet(topic.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => undefined,
                [Contract.GcpConsolePage.Resource]: () => GcpConsoleUrlBuilder.GetPubSubTopicUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            topic.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}