import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, DeferredFilter, EmptyMessageText, formatFilterQueryParameters, IconText, InlineItems, NoneIcon, Optional, optionalTableCell, PagedValuesFilter, PagedValuesFilterValuePage, setUrlRoute, TimeCell, TimeRangeFilter, useLocalization, useOperation, ValuesFilter, ValuesFilterItem, ValuesFilterSelection } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef } from "react";
import { CodeController, CodeIntegrationFilter, CodeTenant, Contract, CustomerConsoleAppUrlHelper, ItemSelectionHelper, RiskCountsChart, ScanResult, scopeSystemEntityModelStore, ScopeTenantFilter, SeverityFilter, StorageHelper, TimeRangeHelper, useTheme } from "../../../../../../../../common";
import { CodeIntegrationIcon, CodeTypeIcon, useCodeIntegrationTranslator, useCodeTypeTranslator } from "../../../../../../../../tenants";
import { ProfileCategory } from "../Scan";

export function Table() {
    const dataTableActionsRef = useRef<DataTableActions>();
    const fetchCodeScans =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { scanPage } =
                await CodeController.getIacScanPage(
                    new Contract.CodeControllerGetIacScanPageRequest(
                        new Contract.CodeControllerGetIacScanPageRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.CodeType]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.CommitBranchName]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.CommitIdentityName]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.IntegrationId]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.RelativePath]),
                            filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.RiskChange] as Optional<boolean>,
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.Severity]),
                            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.Success]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.TenantId]),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.CodeControllerGetIacScanPageRequestProperty.Time])),
                        limit,
                        skip,
                        new Contract.CodeControllerGetIacScanPageRequestSort(
                            _.isNil(sort) || sort.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            Contract.CodeControllerGetIacScanPageRequestProperty.Time)));

            return new DataTableFetchItemsResult(
                { count: scanPage.count! },
                scanPage.items,
                !scanPage.hasMore);
        };
    const getScanFilterItemPage =
        async (property: Contract.CodeControllerGetIacScanPageRequestProperty, searchText: Optional<string>, skip: number, limit: number, data: number) => {
            const { scanFilterItemPage } =
                await CodeController.getIacScanFilterItemPage(
                    new Contract.CodeControllerGetIacScanFilterItemPageRequest(
                        limit,
                        property,
                        searchText,
                        skip)) as Contract.CodeControllerGetIacScanFilterItemPageResponse;
            const scanFilterItemCount = scanFilterItemPage.count ?? data;

            return new PagedValuesFilterValuePage(
                scanFilterItemCount,
                scanFilterItemPage.emptyValue,
                scanFilterItemPage.items,
                () => scanFilterItemCount);
        };

    const [filtersPromise] =
        useOperation(
            Table,
            async () => {
                const { filters } = await CodeController.getIacScanFilters();
                return filters;
            });

    const sortRef = useRef<DataTableSort>();
    const codeTypeTranslator = useCodeTypeTranslator();
    const codeIntegrationTranslator = useCodeIntegrationTranslator();
    const codeIntegrationConfigurationModels = scopeSystemEntityModelStore.useGetCodeIntegration();
    const codeIntegrationConfigurationMap =
        useMemo(
            () =>
                _(codeIntegrationConfigurationModels).
                    map(scopeEntityModel => scopeEntityModel.configuration as Contract.CodeIntegrationConfiguration).
                    keyBy(codeIntegrationConfiguration => codeIntegrationConfiguration.id).
                    value(),
            [codeIntegrationConfigurationModels]);

    const localization =
        useLocalization(
            "views.customer.code.scans.table",
            () => ({
                columns: {
                    blockCount: "IaC Scanned Resources",
                    codeTypes: {
                        all: "All Frameworks",
                        title: "Framework"
                    },
                    commitBranchName: "Branch",
                    commitHash: "Commit",
                    commitIdentityName: "User",
                    integration: {
                        title: "Integration",
                        tooltip: "{{type}} | {{name}}"
                    },
                    path: "Path",
                    riskChange: {
                        filter: "With Changes Only",
                        title: "Change"
                    },
                    riskSeverityToCountMap: "Findings",
                    severity: "Severity",
                    success: {
                        false: "Failed",
                        title: "Result",
                        true: "Passed"
                    },
                    tenantId: "Repository",
                    time: "Time"
                },
                empty: {
                    withFilter: "No Matching Scans",
                    withoutFilter: "No Scans"
                }
            }));

    const theme = useTheme();
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerCodeScansColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerCodeScansColumnSelector
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            fetchItems={fetchCodeScans}
            filtersOptions={{
                persist: {
                    visibilityStorageItem: StorageHelper.customerCodeScansFilters
                }
            }}
            getItemId={(item: Contract.CodeIacScan) => item.id}
            rowOptions={{
                getUrl: (item: Contract.CodeIacScan) => CustomerConsoleAppUrlHelper.getCodeScanProfileHashUrl(item.id, StorageHelper.customerCodeScansProfileSelectedTab.getValue() as ProfileCategory)
            }}
            onSortChanged={sort => sortRef.current = sort}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.time()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.timeRange.emptyValue}
                                        placeholder={localization.columns.time()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.timeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.Time}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.Time}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeIacScan>) =>
                        <TimeCell time={item.time}/>}
                selectorOptions={{ disabled: true }}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.time()}/>
            <DataTableColumn
                cellMaxWidth="medium"
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.tenantId()}>
                                {filters =>
                                    <ScopeTenantFilter
                                        placeholder={localization.columns.tenantId()}
                                        tenantIds={filters.tenantIdItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.TenantId}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.TenantId}
                render={({ item }: DataTableColumnRenderProps<Contract.CodeIacScan>) => <CodeTenant tenantId={item.tenantId}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.tenantId()}/>
            <DataTableColumn
                cellMaxWidth="medium"
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetIacScanPageRequestProperty.CommitBranchName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.commitBranchName()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.CommitBranchName}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.CommitBranchName}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeIacScan>) =>
                        <Typography noWrap={true}>
                            {item.commit.branchName}
                        </Typography>}
                selectorOptions={{ disabled: true }}
                sortOptions={{ enabled: false }}
                title={localization.columns.commitBranchName()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetIacScanPageRequestProperty.RelativePath,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.path()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.RelativePath}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.RelativePath}
                render={
                    optionalTableCell<Contract.CodeIacScan>((item: Contract.CodeIacScan) =>
                        _.isNil(item.relativePath)
                            ? <NoneIcon/>
                            : <Typography
                                noWrap={true}
                                sx={{
                                    direction: "rtl",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}>
                                {item.relativePath}
                            </Typography>)}
                selectorOptions={{ default: false }}
                sortOptions={{ enabled: false }}
                title={localization.columns.path()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.integration.title()}>
                                {filters =>
                                    <CodeIntegrationFilter
                                        codeIntegrationConfigurationMap={codeIntegrationConfigurationMap}
                                        codeIntegrationsIds={filters.integrationIdItems.items}
                                        emptyValue={filters.integrationIdItems.emptyValue}
                                        placeholder={localization.columns.integration.title()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.IntegrationId}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.IntegrationId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeIacScan>) => {
                        const codeIntegrationConfiguration = codeIntegrationConfigurationMap[item.integrationId];
                        return (
                            <IconText
                                icon={
                                    <CodeIntegrationIcon
                                        configuration={codeIntegrationConfiguration}
                                        sx={{ fontSize: "18px" }}/>}
                                text={
                                    <Typography noWrap={true}>
                                        {codeIntegrationConfiguration.name}
                                    </Typography>}
                                titleOrGetTitle={codeIntegrationTranslator(codeIntegrationConfiguration)}/>);
                    }}
                selectorOptions={{ default: false }}
                sortOptions={{ enabled: false }}
                title={localization.columns.integration.title()}/>,
            <DataTableColumn
                cellMaxWidth="medium"
                id="commitHash"
                itemProperty={(item: Contract.CodeIacScan) => item.commit.hash.substring(0, 7)}
                key="commitHash"
                sortOptions={{ enabled: false }}
                title={localization.columns.commitHash()}/>,
            <DataTableColumn
                cellMaxWidth="medium"
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanFilterItemPage(
                                            Contract.CodeControllerGetIacScanPageRequestProperty.CommitIdentityName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.commitIdentityName()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.CommitIdentityName}
                itemProperty={(item: Contract.CodeIacScan) => item.commit.identityName}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.CommitIdentityName}
                sortOptions={{ enabled: false }}
                title={localization.columns.commitIdentityName()}/>
            <DataTableColumn
                cellSx={{ minWidth: theme.spacing(15) }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.codeTypes.title()}>
                                {_.map(
                                    _.values(Contract.CodeType),
                                    codeType =>
                                        <ValuesFilterItem
                                            key={codeType}
                                            title={codeTypeTranslator(codeType)}
                                            value={codeType}/>)}
                            </ValuesFilter>
                    }
                }}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.CodeType}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.CodeType}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeIacScan>) =>
                        <Typography noWrap={true}>
                            {_(_.values(Contract.CodeType)).
                                difference(item.codeTypes).
                                isEmpty()
                                ? localization.columns.codeTypes.all()
                                : <InlineItems
                                    items={item.codeTypes}
                                    variant="itemPlusItemCount">
                                    {codeType =>
                                        <IconText
                                            icon={
                                                <CodeTypeIcon
                                                    codeType={codeType}
                                                    sx={{ fontSize: "18px" }}/>}
                                            text={
                                                <Typography noWrap={true}>
                                                    {codeTypeTranslator(codeType)}
                                                </Typography>}/>}
                                </InlineItems>}
                        </Typography>}
                selectorOptions={{ default: false }}
                sortOptions={{ enabled: false }}
                title={localization.columns.codeTypes.title()}/>
            <DataTableColumn
                disableAction={true}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.RiskSeverityToCountMap}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeIacScan>) =>
                        <RiskCountsChart
                            itemMap={item.stats.riskSeverityToCountMap}
                            variant="dynamic"
                            onClick={
                                severity => {
                                    const queryParameters =
                                        formatFilterQueryParameters(
                                            "filterMap",
                                            {
                                                [Contract.CodeControllerGetIacScanPageRequestProperty.Severity]:
                                                    new ValuesFilterSelection(
                                                        false,
                                                        [severity])
                                            });
                                    setUrlRoute(`${(CustomerConsoleAppUrlHelper.getCodeScanProfileHashUrl(item.id, ProfileCategory.Findings))}?${queryParameters}`);
                                }}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.riskSeverityToCountMap()}/>
            <DataTableColumn
                id={Contract.CodeControllerGetIacScanPageRequestProperty.BlockCount}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.BlockCount}
                render={optionalTableCell<Contract.CodeIacScan>((item: Contract.CodeIacScan) => item.blockCount)}
                selectorOptions={{ default: false }}
                sortOptions={{ enabled: false }}
                title={localization.columns.blockCount()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element: <SeverityFilter placeholder={localization.columns.severity()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.Severity}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.Severity}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.severity()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.success.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.success.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.success.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.CodeControllerGetIacScanPageRequestProperty.Success}
                itemProperty={
                    (vulnerabilityModel: Contract.CodeIacScan) =>
                        <ScanResult
                            failed={localization.columns.success.false()}
                            passed={localization.columns.success.true()}
                            success={vulnerabilityModel.success}/>}
                key={Contract.CodeControllerGetIacScanPageRequestProperty.Success}
                sortOptions={{ enabled: false }}
                title={localization.columns.success.title()}/>
        </DataTable>);
}