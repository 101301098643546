import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DuplicateIcon(props: SvgIconProps) {
    return (
        <SvgIcon fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
            <path
                clipRule="evenodd"
                d="M11 10C10.4477 10 10 10.4477 10 11V20C10 20.5523 10.4477 21 11 21H20C20.5523 21 21 20.5523 21 20V11C21 10.4477 20.5523 10 20 10H11ZM8 11C8 9.34315 9.34315 8 11 8H20C21.6569 8 23 9.34315 23 11V20C23 21.6569 21.6569 23 20 23H11C9.34315 23 8 21.6569 8 20V11Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M4 3C3.44771 3 3 3.44771 3 4V13C3 13.5523 3.44771 14 4 14H5C5.55228 14 6 14.4477 6 15C6 15.5523 5.55228 16 5 16H4C2.34315 16 1 14.6569 1 13V4C1 2.34315 2.34315 1 4 1H13C14.6569 1 16 2.34315 16 4V5C16 5.55228 15.5523 6 15 6C14.4477 6 14 5.55228 14 5V4C14 3.44771 13.5523 3 13 3H4Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}