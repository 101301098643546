﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColombiaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M0 6C0 4.89543 0.895431 4 2 4H22C23.1046 4 24 4.89543 24 6V18C24 19.1046 23.1046 20 22 20H2C0.89543 20 0 19.1046 0 18V6Z"
                fill="#FFE800"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M0 12H24V17H0V12Z"
                fill="#00148E"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M0 16.5H24V18C24 19.1046 23.1046 20 22 20H2C0.89543 20 0 19.1046 0 18V16.5Z"
                fill="#DA0010"
                fillRule="evenodd"/>
        </SvgIcon>);
}