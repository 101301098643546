import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadDirectoryApplicationTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadDirectoryApplicationTypeTranslator",
            () => ({
                [Contract.AadDirectoryApplicationType.AadAndPersonalAccount]: "Personal Microsoft Accounts",
                [Contract.AadDirectoryApplicationType.AadMultiTenant]: "Multi Tenant",
                [Contract.AadDirectoryApplicationType.AadSingleTenant]: "Single Tenant",
                [Contract.AadDirectoryApplicationType.PersonalAccount]: "Other"
            }));
    return (type: Contract.AadDirectoryApplicationType) => localization[type]();
}