import { DropdownActions, useLocalization } from "@infrastructure";
import React, { useMemo, useRef } from "react";
import { Contract, projectModelStore, Scope, ScopeHelper, scopeNodeModelStore, VerticalTopItemsInfoItem } from "../../../../../../../../common";
import { ProfileDefinition } from "../../useProfileDefinition";
import { StatusCell } from "./components";
import { useProjectsCommonDefinitionData } from "./useProjectsCommonDefinitionData";

export function useProjectsProfileDefinition(scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const projectModelMap = projectModelStore.useGetProjectModelMap();
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(undefined, true);
    const { getScopeIds } = useProjectsCommonDefinitionData();
    const scopesDropdownActionsRef = useRef<DropdownActions>();
    const tenantsDropdownActionsRef = useRef<DropdownActions>();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.useProjectsProfileDefinition",
            () => ({
                scopes: "Scopes",
                status: "Status",
                tenants: "Accounts"
            }));

    return useMemo(
        () => {
            const projectModel = projectModelMap[scopeNodeModel.configuration.id];
            return {
                sections: [
                    {
                        items: [
                            {
                                title: localization.status(),
                                value: <StatusCell item={projectModel}/>
                            },
                            {
                                value:
                                    <VerticalTopItemsInfoItem
                                        dropdownActionsRef={scopesDropdownActionsRef}
                                        getSortValue={scopeId => scopeNodeMap[scopeId].path}
                                        items={getScopeIds(projectModel)}
                                        title={localization.scopes()}>
                                        {scopeId =>
                                            <Scope
                                                key={scopeId}
                                                scopeId={scopeId}
                                                scopeNameSx={{
                                                    direction: "rtl",
                                                    textAlign: "left"
                                                }}
                                                scopeNameTranslatorOptions={{ path: true }}
                                                sx={{ width: "fill-available" }}
                                                variant={
                                                    ScopeHelper.isFolder(scopeNodeMap[scopeId].scopeNodeModel) ||
                                                    scopeId === ScopeHelper.customerId ||
                                                    ScopeHelper.isIdentityProviderTenantsScope(scopeNodeMap[scopeId].scopeNodeModel)
                                                        ? "iconText"
                                                        : "link"}
                                                onClick={() => scopesDropdownActionsRef.current?.close()}/>}
                                    </VerticalTopItemsInfoItem>
                            },
                            {
                                value:
                                    <VerticalTopItemsInfoItem
                                        dropdownActionsRef={tenantsDropdownActionsRef}
                                        getSortValue={tenantId => scopeNodeMap[tenantId].path}
                                        items={projectModel.tenantIds}
                                        title={localization.tenants()}>
                                        {tenantId =>
                                            <Scope
                                                key={tenantId}
                                                scopeId={tenantId}
                                                scopeNameSx={{
                                                    direction: "rtl",
                                                    textAlign: "left"
                                                }}
                                                scopeNameTranslatorOptions={{ path: true }}
                                                sx={{ width: "fill-available" }}
                                                variant={
                                                    ScopeHelper.isIdentityProviderTenantsScope(scopeNodeMap[tenantId].scopeNodeModel)
                                                        ? "iconText"
                                                        : "link"}
                                                onClick={() => tenantsDropdownActionsRef.current?.close()}/>}
                                    </VerticalTopItemsInfoItem>
                            }
                        ]
                    }
                ]
            };
        },
        [getScopeIds, localization, projectModelMap, scopeNodeMap]);
}