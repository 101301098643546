﻿import _ from "lodash";
import { ReactNode, useCallback, useMemo } from "react";
import { optionalTableCell, useUncaptureValue } from "@infrastructure";
import { Contract, projectModelStore, ScopeHelper } from "../../../../../../../../common";

export function useRenderProject() {
    const projectModelMap = projectModelStore.useGetProjectModelMap();
    const uncaptureProjectModelMap = useUncaptureValue(projectModelMap);

    const renderProjectCell =
        useCallback(
            (render: (projectModel: Contract.ProjectModel) => ReactNode) =>
                optionalTableCell<Contract.ScopeNodeModel>(
                    scopeNodeModel =>
                        uncaptureProjectModelMap(
                            projectModelMap =>
                                !ScopeHelper.isProject(scopeNodeModel) || _.isNil(projectModelMap[scopeNodeModel.configuration.id])
                                    ? undefined
                                    : render(projectModelMap[scopeNodeModel.configuration.id]))),
            [projectModelMap]);

    const renderProjectCsvItem =
        useCallback(
            (render: (projectModel: Contract.ProjectModel) => string | undefined, scopeNodeModel: Contract.ScopeNodeModel) =>
                !ScopeHelper.isProject(scopeNodeModel) || _.isNil(projectModelMap[scopeNodeModel.configuration.id])
                    ? ""
                    : render(projectModelMap[scopeNodeModel.configuration.id]) ?? "",
            [projectModelMap]);

    return useMemo(
        () =>
            ({
                renderProjectCell,
                renderProjectCsvItem
            }),
        [renderProjectCell, renderProjectCsvItem]);
}