import { DataTableActions, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Link, Loading, NoneIcon, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { ComplianceSecuredBar, Contract, CustomerConsoleAppUrlHelper, ItemTable, RiskPolicyTypeMetadataHelper, TenantHelper, TenantsIcon, useProjectScopeSelected, useRiskPolicyTranslator, useTheme } from "../../../../../../../../../../../../common";
import { useItemContext } from "../../../../../../Profile";
import { CountCell, FailedEntitiesCell } from "./components";

type RiskPoliciesTableProps = {
    riskPolicyDatas: Contract.ComplianceRiskPolicyData[];
};

export function RiskPoliciesTable({ riskPolicyDatas }: RiskPoliciesTableProps) {
    const { disabled, scopeId } = useItemContext();
    const projectScopeSelected = useProjectScopeSelected();

    const dataTableActionsRef = useRef<DataTableActions>();

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.compliance.profile.item.sections.riskPoliciesTable",
            () => ({
                columns: {
                    count: "Scanned Resources",
                    failedEntityIds: "Failed Resources",
                    platform: "Platform",
                    stats: "Score",
                    title: "Policy"
                },
                disabled: "Policy disabled",
                empty: "No Policies"
            }));

    const theme = useTheme();
    return (
        <ItemTable
            actionsRef={dataTableActionsRef}
            columnIdToGetItemValueMap={{
                [PoliciesTableColumnId.Title]: (riskPolicyData: Contract.ComplianceRiskPolicyData) => riskPolicyTranslator(riskPolicyData.riskPolicyIdentifier).title,
                [PoliciesTableColumnId.Platform]:
                    (riskPolicyData: Contract.ComplianceRiskPolicyData) =>
                        _.intersection(
                            RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyData.riskPolicyConfigurationTypeName),
                            TenantHelper.ComplianceTenantTypes)
            }}
            columnOptions={{ resizable: true }}
            defaultSortColumnIdOrIds={[
                PoliciesTableColumnId.Platform,
                PoliciesTableColumnId.Title
            ]}
            emptyMessageOptions={{
                emptyMessageText: new EmptyMessageText(localization.empty()),
                size: "small",
                sx: { minHeight: theme.spacing(5) }
            }}
            getItemId={(riskPolicyData: Contract.ComplianceRiskPolicyData) => riskPolicyData.riskPolicyIdentifier}
            items={riskPolicyDatas}
            pageSize={10}
            showEmptyTable={true}
            sortOptions={{ enabled: false }}
            variant="card">
            {() => [
                <DataTableColumn
                    cellSx={{ width: theme.spacing(15) }}
                    id={PoliciesTableColumnId.Platform}
                    key={PoliciesTableColumnId.Platform}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceRiskPolicyData>) =>
                            <TenantsIcon
                                sx={{ fontSize: "22px" }}
                                tenantTypes={
                                    _.intersection(
                                        RiskPolicyTypeMetadataHelper.getTenantTypes(item.riskPolicyConfigurationTypeName),
                                        TenantHelper.ComplianceTenantTypes)}/>}
                    title={localization.columns.platform()}/>,
                <DataTableColumn
                    id={PoliciesTableColumnId.Title}
                    key={PoliciesTableColumnId.Title}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceRiskPolicyData>) =>
                            <Link
                                urlOrGetUrl={
                                    projectScopeSelected
                                        ? undefined
                                        : CustomerConsoleAppUrlHelper.getCloudRiskPoliciesProfileHashUrl(
                                            item.riskPolicyIdentifier,
                                            scopeId)}>
                                <Typography noWrap={true}>
                                    {riskPolicyTranslator(item.riskPolicyIdentifier).title}
                                </Typography>
                            </Link>}
                    title={localization.columns.title()}/>,
                <DataTableColumn
                    cellSx={{ width: theme.spacing(25) }}
                    id={PoliciesTableColumnId.FailedEntityIds}
                    key={PoliciesTableColumnId.FailedEntityIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceRiskPolicyData>) =>
                            item.enabled && !disabled
                                ? <FailedEntitiesCell item={item}/>
                                : <NoneIcon sx={{ fontSize: "18px" }}/>}
                    title={localization.columns.failedEntityIds()}/>,
                <DataTableColumn
                    cellSx={{ width: theme.spacing(25) }}
                    id={PoliciesTableColumnId.Count}
                    key={PoliciesTableColumnId.Count}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceRiskPolicyData>) =>
                            item.enabled && !disabled
                                ? <Loading container="cell">
                                    <CountCell item={item}/>
                                </Loading>
                                : <NoneIcon sx={{ fontSize: "18px" }}/>}
                    title={localization.columns.count()}/>,
                <DataTableColumn
                    cellSx={{ width: theme.spacing(20) }}
                    id={PoliciesTableColumnId.Stats}
                    key={PoliciesTableColumnId.Stats}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceRiskPolicyData>) =>
                            item.enabled
                                ? <ComplianceSecuredBar
                                    data={item}
                                    disabled={disabled}/>
                                : <Typography noWrap={true}>
                                    {localization.disabled()}
                                </Typography>}
                    resizable={false}
                    title={localization.columns.stats()}/>]}
        </ItemTable>);
}

enum PoliciesTableColumnId {
    Count = "count",
    FailedEntityIds = "failedEntityIds",
    Platform = "platform",
    Stats = "stats",
    Title = "title"
}