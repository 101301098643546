import _ from "lodash";
import { useEffect, useState } from "react";
import { ApiError, Optional, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { ConfigurationController, Contract } from "../../../../../../../../../../../../../../../common";
import { useOciTenantManagerErrorTranslator } from "../../../../../../../../../../../../../../../tenants";
import { OrganizationMemberSelection } from "../../../../../../../components";
import { OrganizationMemberSelectionHelper } from "../../../../../../../utilities";
import { useAddOrEditContext, useSetAddOrEditContext } from "../AddOrEdit";

export function OrganizationMemberSelectionItem() {
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const { setError, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();

    const [scopeNodeRootTreeItem] =
        useExecuteOperation(
            OrganizationMemberSelectionItem,
            async () => {
                const { node } =
                    await ConfigurationController.getOrganizationMemberDatas(
                        new Contract.ConfigurationControllerGetOciOrganizationMemberDatasRequest(
                            addOrEditContext.existingOrganizationModel?.id,
                            addOrEditContext.ocid,
                            addOrEditContext.homeRegionSystemName,
                            addOrEditContext.userOcid,
                            addOrEditContext.userPrivateKeyPem,
                            addOrEditContext.userPublicKeyFingerprint));

                return OrganizationMemberSelectionHelper.createTreeItem(node);
            });

    const [selectionType, setSelectionType] = useState(addOrEditContext.memberSelection?.type);
    const [selectedTenantIds, setSelectedTenantIds] =
        useState(
            () =>
                OrganizationMemberSelectionHelper.getSelectedTenantIds(
                    scopeNodeRootTreeItem.value,
                    addOrEditContext.memberSelection?.rawIds));

    useEffect(setLoaded, []);

    const tenantManagerErrorTranslator = useOciTenantManagerErrorTranslator();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.oci.addOrEdit.organizationMemberSelectionItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add tenancy",
                            edit: "Failed to save tenancy"
                        }
                    }
                },
                errors: {
                    empty: "Empty selection is not allowed",
                    excludeAll: "Excluding all {{name}} is not allowed"
                }
            }));

    useNextEffect(
        async () => {
            if (!_.isNil(selectionType) && _.isEmpty(selectedTenantIds)) {
                return localization.errors.empty();
            }
            const newMemberSelection: Optional<Contract.OrganizationMemberSelection> =
                _.isNil(selectionType)
                    ? undefined
                    : {
                        rawIds:
                            OrganizationMemberSelectionHelper.getSelectedScopeIds(
                                scopeNodeRootTreeItem.value,
                                selectedTenantIds),
                        type: selectionType
                    };

            try {
                let updatedOrganizationTenantNames: string[];
                let updatedOrganizationModel: Contract.OciOrganizationModel;
                if (!_.isNil(addOrEditContext.existingOrganizationModel)) {
                    const { organizationModel, organizationTenantNames } =
                        await ConfigurationController.updateOciOrganization(
                            new Contract.ConfigurationControllerUpdateOciOrganizationRequest(
                                addOrEditContext.enabled,
                                addOrEditContext.folderEnabled,
                                addOrEditContext.existingOrganizationModel.configuration.id,
                                newMemberSelection,
                                [Contract.CloudProviderTenantPermissionType.Read],
                                _.isNil(addOrEditContext.userPrivateKeyPem)
                                    ? undefined
                                    : new Contract.ConfigurationControllerUpdateOciOrganizationRequestUser(
                                        addOrEditContext.userOcid.trim(),
                                        addOrEditContext.userPrivateKeyPem.trim(),
                                        addOrEditContext.userPublicKeyFingerprint.trim())));

                    updatedOrganizationModel = organizationModel;
                    updatedOrganizationTenantNames = organizationTenantNames;
                } else {
                    const { organizationModel, organizationTenantNames } =
                        await ConfigurationController.insertOciOrganization(
                            new Contract.ConfigurationControllerInsertOciOrganizationRequest(
                                addOrEditContext.folderEnabled,
                                addOrEditContext.homeRegionSystemName.trim(),
                                newMemberSelection,
                                addOrEditContext.ocid.trim(),
                                [Contract.CloudProviderTenantPermissionType.Read],
                                addOrEditContext.userOcid.trim(),
                                addOrEditContext.userPrivateKeyPem!.trim(),
                                addOrEditContext.userPublicKeyFingerprint.trim()));

                    updatedOrganizationModel = organizationModel;
                    updatedOrganizationTenantNames = organizationTenantNames;
                }

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        updatedOrganizationModel,
                        updatedOrganizationTenantNames
                    }));
            } catch (error) {
                return (
                    error instanceof ApiError && error.statusCode === 400
                        ? tenantManagerErrorTranslator(error.error as Contract.OciTenantManagerError)
                        : _.isNil(addOrEditContext.existingOrganizationModel)
                            ? localization.actions.save.error.add()
                            : localization.actions.save.error.edit());
            }
        },
        [selectedTenantIds, selectionType]);

    return (
        <OrganizationMemberSelection
            scopeNodeRootTreeItem={scopeNodeRootTreeItem}
            selectedTenantIds={selectedTenantIds}
            selectionType={selectionType}
            tenantType={Contract.TenantType.Oci}
            onError={
                error => {
                    setValid(_.isNil(error));
                    setError(error);
                }}
            onSelectedTenantIdsChanged={setSelectedTenantIds}
            onSelectionTypeChanged={setSelectionType}/>);
}