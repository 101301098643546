import { Divider, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { InfoCard, ProfileLayout, ProfileTopbar, RiskPoliciesIcon, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem, useTheme } from "../../../../../../common";
import { useRiskPolicyInfoItems } from "./hooks";

export type ProfileProps = {
    riskPoliciesType: RiskPoliciesType;
    riskPolicy: RiskPolicyItem;
    scopeId: string;
};

export function Profile({ riskPoliciesType, riskPolicy, scopeId }: ProfileProps) {
    const infoItems =
        useRiskPolicyInfoItems(
            riskPoliciesType,
            scopeId,
            riskPolicy,
            riskPolicy.custom
                ? RiskPoliciesView.Custom
                : RiskPoliciesView.BuiltIn);

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<RiskPoliciesIcon/>}
                    subtitle={infoItems.topbar.subtitle}
                    title={infoItems.topbar.title}/>}>
            <Stack
                sx={{
                    height: "100%",
                    overflow: "hidden auto",
                    padding: theme.spacing(2, 2, 0, 3)
                }}>
                <InfoCard>
                    {infoItems.description}
                    <InfoCard columns={true}>
                        {...infoItems.general}
                    </InfoCard>
                    {_.map(
                        infoItems?.additionalInfoCard,
                        ({ items, title }, index) =>
                            <Fragment key={index}>
                                <Divider sx={{ marginBottom: theme.spacing(1) }}/>
                                <InfoCard
                                    columns={true}
                                    title={title}>
                                    {...items}
                                </InfoCard>
                            </Fragment>)}
                </InfoCard>
            </Stack>
        </ProfileLayout>);
}