import { Loading, useExecuteOperation, useLocalization, useRoute, useSetRoute, VerticalTabView } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { CodeController, CodeTenantLink, Contract, CustomerConsoleAppUrlHelper, ProfileLayout, ProfileTopbar, ProfileTopbarBranchInfoItem, ProfileTopbarCommitInfoItem, ProfileTopbarTimeInfoItem, ScansIcon, StorageHelper, tenantModelStore, useTheme } from "../../../../../../../../common";
import { Info, RisksTable } from "./components";
import { CategoryIcon } from "./icons";
import { ElementClass } from "./Profile.element";
import { ProfileCategory } from "./profileCategory";

type ProfileProps = {
    scanId: string;
};

export function Profile({ scanId }: ProfileProps) {
    const setRoute = useSetRoute();
    const { category } = useRoute(`${CustomerConsoleAppUrlHelper.getCodeScanProfileHashUrl(scanId)}/{category}`);
    const [{ scan }] =
        useExecuteOperation(
            [scanId],
            () => CodeController.getIacScan(new Contract.CodeControllerGetIacScanRequest(scanId)));
    const tenantConfiguration = tenantModelStore.useGet(scan.tenantId).configuration as Contract.CodeTenantConfiguration;

    const orderedCategories =
        useMemo(
            () => [
                ProfileCategory.Overview,
                ProfileCategory.Findings
            ],
            []);
    const currentCategory =
        useMemo(
            () =>
                _.includes(orderedCategories, category)
                    ? category as ProfileCategory
                    : _.first(orderedCategories)!,
            [category, orderedCategories]);

    const localization =
        useLocalization(
            "views.customer.code.scans.scan.profile",
            () => ({
                commit: {
                    branchName: "Branch"
                },
                tabs: {
                    [ProfileCategory.Overview]: "Overview",
                    [ProfileCategory.Findings]: "Findings"
                },
                time: "Time",
                title: "IaC Scan"
            }));

    const theme = useTheme();
    const infoItems =
        useMemo(
            () =>
                _<ReactNode>([]).
                    concat(
                        <CodeTenantLink
                            iconSx={{ fontSize: "16px" }}
                            key="codeTenantLink"
                            linkSx={{ maxWidth: theme.spacing(25) }}
                            spacing={0.5}
                            tenantConfiguration={tenantConfiguration}/>).
                    concatIf(
                        !_.isNil(scan.commit.branchName),
                        <ProfileTopbarBranchInfoItem
                            branchName={scan.commit.branchName!}
                            codeTenantType={tenantConfiguration.codeTenantType}
                            key="commitBranchName"
                            url={tenantConfiguration.url}/>).
                    concat(
                        <ProfileTopbarCommitInfoItem
                            codeTenantType={tenantConfiguration.codeTenantType}
                            hash={scan.commit.hash}
                            key="hash"
                            url={tenantConfiguration.url}/>,
                        <ProfileTopbarTimeInfoItem
                            key="scannedTime"
                            time={scan.time}
                            type="scanned"/>).
                    value(),
            [scan, tenantConfiguration]);

    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<ScansIcon/>}
                    infoChildren={infoItems}
                    title={localization.title()}/>}>
            <VerticalTabView
                items={
                    _.map(
                        orderedCategories,
                        category => ({
                            icon: <CategoryIcon category={category}/>,
                            title: localization.tabs[category](),
                            view: category
                        }))}
                selectedView={currentCategory}
                storageItem={StorageHelper.customerCodeScansProfileSelectedTab}
                onSelectedViewChanged={
                    view => {
                        StorageHelper.customerCodeScansProfileSelectedTab.setValue(view);
                        setRoute(CustomerConsoleAppUrlHelper.getCodeScanProfileHashUrl(scanId, view)!);
                    }}>
                <Stack
                    className={ElementClass.categoryContainer}
                    sx={{
                        height: "100%",
                        overflow: "hidden auto",
                        padding: theme.spacing(2, 2, 0, 2)
                    }}>
                    {currentCategory === ProfileCategory.Overview &&
                        <Info scan={scan}/>}
                    {currentCategory === ProfileCategory.Findings &&
                        <Loading>
                            <RisksTable scan={scan}/>
                        </Loading>}
                </Stack>
            </VerticalTabView>
        </ProfileLayout>);
}