﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../../../../common";
import { useDefaultDefinition } from "../useDefaultDefinition";
import { useAadDirectoryGroupInactiveRiskDefinition, useAwsExcessivePermissionGroupRiskDefinition, useAwsIamGroupInactiveRiskDefinition, useAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskDefinition, useGciDirectoryGroupInactiveRiskDefinition } from "./hooks";

export function useAccessDefinition(riskPolicyConfigurationTypeName: string) {
    const useDefinition =
        useMemo(
            () => {
                switch (riskPolicyConfigurationTypeName) {
                    case Contract.TypeNames.AadDirectoryGroupInactiveRiskPolicyConfiguration:
                        return useAadDirectoryGroupInactiveRiskDefinition;
                    case Contract.TypeNames.AwsExcessivePermissionGroupRiskPolicyConfiguration:
                        return useAwsExcessivePermissionGroupRiskDefinition;
                    case Contract.TypeNames.AwsIamGroupInactiveRiskPolicyConfiguration:
                        return useAwsIamGroupInactiveRiskDefinition;
                    case Contract.TypeNames.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyConfiguration:
                        return useAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskDefinition;
                    case Contract.TypeNames.GciDirectoryGroupInactiveRiskPolicyConfiguration:
                        return useGciDirectoryGroupInactiveRiskDefinition;
                    default:
                        return useDefaultDefinition;
                }
            },
            []);

    return useDefinition(riskPolicyConfigurationTypeName);
}