import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEksClusterRiskContext } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsEksClusterInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEksClusterInboundExternalWideRangeRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEksClusterModel;
    const cluster = clusterModel.entity as Contract.AwsEksCluster;

    const getAwsEksClusterRiskContext = useGetAwsEksClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEksClusterInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{cluster}} authorized networks configuration allows wide inbound internet access to the Kubernetes API",
                sections: {
                    authorizedNetworks: "Control Plane Authorized Networks",
                    resolution: {
                        step1: {
                            link: "Tenable Cloud Security's IP addresses",
                            text: "Under **Advanced Settings**, edit the CIDR blocks to allow access only to the specific IP addresses that require access to the Kubernetes API, including {{documentationApplicationIpAddressesLink}}"
                        },
                        step2: "Click **Save Changes** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Eks,
                AwsConsoleUrlBuilder.getEksClusterEditEndpointAccessUrl(cluster)),
            localization.sections.resolution.step1.text({
                documentationApplicationIpAddressesLink:
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingApplicationIpAddressesRelativeUrl()}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAwsEksClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.vpc,
                clusterRiskContext.wideRangeInboundSubnetsContextItem,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}