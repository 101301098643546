﻿import { DropdownActions, Optional, PagedDropdown, PagedDropdownPage, useLocalization } from "@infrastructure";
import { List, ListItem } from "@mui/material";
import _ from "lodash";
import { useRef, useState } from "react";
import { entityModelStore } from "../stores";
import { Entity } from "./Entity";
import { PagedEntitySelectorPage } from "./PagedEntitySelector";

export type InlinePagedEntitiesProps = {
    disabled?: boolean;
    fullWidth?: boolean;
    getEntityModelPage: (searchText: Optional<string>, skip: number, data?: any) => Promise<PagedEntitySelectorPage>;
    placeholder: string;
};

export function InlinePagedEntities({ disabled = false, fullWidth, getEntityModelPage, placeholder }: InlinePagedEntitiesProps) {
    const localization =
        useLocalization(
            "common.inlinePagedEntities",
            () => ({
                empty: "No Results",
                search: "Search"
            }));

    const dropdownActionsRef = useRef<DropdownActions>(null);
    const [open, setOpen] = useState(false);

    return (
        <PagedDropdown
            actionsRef={dropdownActionsRef}
            disabled={disabled}
            emptyText={localization.empty()}
            fieldOptions={{
                placeholder,
                variant: "text"
            }}
            fullWidth={fullWidth}
            getItemPage={
                async (searchText, skip, data) => {
                    const page =
                        await getEntityModelPage(
                            searchText,
                            skip,
                            data);

                    await entityModelStore.notify(page.items);

                    return new PagedDropdownPage(
                        page.hasMore,
                        _.map(
                            page.items,
                            entityModel => entityModel.id),
                        page.applyData);
                }}
            inline={true}
            open={open}
            searchOptions={{
                enabled: true,
                placeholder: localization.search()
            }}
            sx={{ textDecoration: "underline" }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            {(entityIds: string[]) =>
                <List dense={true}>
                    {_.map(
                        entityIds,
                        entityId =>
                            <ListItem>
                                <Entity
                                    entityIdOrModel={entityId}
                                    linkOptions={{ disabled: true }}
                                    variant="iconText"/>
                            </ListItem>)}
                </List>}
        </PagedDropdown>);
}