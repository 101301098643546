import { Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../common";
import { VulnerabilitiesCell } from "../../../../../../../VulnerabilitiesCell";

export type AwsEcrVulnerabilitiesCellProps = {
    containerImageScanStatus?: Contract.ContainerImageDataScanStatus;
    scanningConfiguration: Contract.AwsEcrRepositoryScanningConfiguration;
    vulnerabilities?: Contract.VulnerabilityData[];
};

export function AwsEcrRepositoryVulnerabilitiesCell({ containerImageScanStatus, scanningConfiguration, vulnerabilities }: AwsEcrVulnerabilitiesCellProps) {
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEcrRepositoryDefinition.awsEcrRepositoryVulnerabilitiesCell",
            () => ({
                scanAccessDenied: "Missing permission for ECR scanning, vulnerabilities will not be up to date"
            }));

    const scanAccessDenied =
        containerImageScanStatus == Contract.ContainerImageDataScanStatus.ErrorAccessDenied ||
        scanningConfiguration == Contract.AwsEcrRepositoryScanningConfiguration.Unknown;

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{ paddingRight: theme.px(1) }}>
            <VulnerabilitiesCell
                containerImageScanStatus={containerImageScanStatus}
                vulnerabilities={vulnerabilities}/>
            {scanAccessDenied &&
                <Message
                    level="infoWarning"
                    title={
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {localization.scanAccessDenied()}
                        </Typography>}
                    variant="minimal"/>}
        </Stack>);
}