﻿import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, EntitiesCell, entityModelStore, InfoCard, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useOciComputeInstanceStatusTranslator } from "../../../../../../../../hooks";

type InstanceInfoCardProps = {
    instanceIdReferences: string[];
};

export function InstanceInfoCard({ instanceIdReferences }: InstanceInfoCardProps) {
    const instanceModels = entityModelStore.useGet(instanceIdReferences);

    const computeInstanceStatusTranslator = useOciComputeInstanceStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciContainerEngineClusterNodePoolDefinition.instanceInfoCard",
            () => ({
                columns: {
                    instance: "Node",
                    status: "Status"
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Nodes"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.OciComputeInstance,
                                        { count: 0 })
                            }))
                }}
                fetchItems={() => instanceModels}
                getItemId={(item: Contract.GcpComputeInstanceGroup) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id="instance"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.OciComputeInstance>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.id}
                                entityTypeName={Contract.TypeNames.OciComputeInstance}
                                entityVariant="iconTextTenant"/>}
                    title={localization.columns.instance()}/>
                <DataTableColumn
                    id="status"
                    itemProperty={(item: Contract.OciComputeInstanceModel) => computeInstanceStatusTranslator((item.entity as Contract.OciComputeInstance).status)}
                    title={localization.columns.status()}/>
            </DataTable>
        </InfoCard>);
}