﻿import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useRemoveQueryParameters } from "@infrastructure";
import React from "react";
import { CodeOrganizationQueryParameters } from "../..";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEditBitbucket, Table } from "./components";

export class BitbucketContext {
    constructor(public addOrEditOpen: boolean | Contract.CodeOrganizationModel) {
    }
}

export const [useBitbucketContext, useSetBitbucketContext, useBitbucketContextProvider] = makeContextProvider<BitbucketContext>();

export function Bitbucket() {
    const removeQueryParameters = useRemoveQueryParameters();
    const [context, setContext, ContextProvider] =
        useBitbucketContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<BitbucketOrganizationQueryParameters>();
                removeQueryParameters("addOrEditOpen");
                return new BitbucketContext(StringHelper.isTrue(queryParameters.addOrEditOpen));
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEditBitbucket/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type BitbucketOrganizationQueryParameters = CodeOrganizationQueryParameters;