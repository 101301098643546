//@ts-nocheck
import Mustache from "mustache";
import { Contract } from "../../../common";

export class CodeConsoleUrlBuilder {
    private static readonly _azureDevOpsBranchUrlTemplate = "{{tenantRawUrl}}?{{branchName}}";
    private static readonly _azureDevOpsCommitUrlTemplate = "{{tenantRawUrl}}/commit/{{commitId}}";
    private static readonly _azureDevOpsFileLineUrlTemplate = "{{tenantRawUrl}}?{{commitHash}}&path={{filePath}}&line={{line}}";
    private static readonly _azureDevOpsFileUrlTemplate = "{{tenantRawUrl}}?{{commitHash}}&path={{filePath}}";
    private static readonly _azureDevOpsOrganizationUrlTemplate = "https://dev.azure.com/{{organizationName}}";
    private static readonly _bitbucketBranchUrlTemplate = "{{tenantRawUrl}}/branch/{{branchName}}";
    private static readonly _bitbucketCommitUrlTemplate = "{{tenantRawUrl}}/commits/{{commitHash}}";
    private static readonly _bitbucketFileLineUrlTemplate = "{{tenantRawUrl}}/src/{{commitHash}}/{{filePath}}#{{line}}";
    private static readonly _bitbucketFileUrlTemplate = "{{tenantRawUrl}}/src/{{commitHash}}/{{filePath}}";
    private static readonly _bitbucketOrganizationUrlTemplate = "https://bitbucket.org/{{organizationName}}";
    private static readonly _gitHubBranchUrlTemplate = "{{tenantRawUrl}}/tree/{{branchName}}";
    private static readonly _gitHubCommitUrlTemplate = "{{tenantRawUrl}}/commit/{{commitHash}}";
    private static readonly _gitHubFileLineUrlTemplate = "{{tenantRawUrl}}/blob/{{commitHash}}/{{filePath}}#L{{line}}";
    private static readonly _gitHubFileUrlTemplate = "{{tenantRawUrl}}/blob/{{commitHash}}/{{filePath}}";
    private static readonly _gitHubOrganizationUrlTemplate = "https://{{serverEndpoint}}/{{organizationName}}";
    private static readonly _gitLabBranchUrlTemplate = "{{tenantRawUrl}}/tree/{{branchName}}";
    private static readonly _gitLabCommitUrlTemplate = "{{tenantRawUrl}}/commit/{{commitHash}}";
    private static readonly _gitLabFileLineUrlTemplate = "{{tenantRawUrl}}/blob/{{commitHash}}/{{filePath}}#L{{line}}";
    private static readonly _gitLabFileUrlTemplate = "{{tenantRawUrl}}/blob/{{commitHash}}/{{filePath}}";
    private static readonly _gitLabOrganizationUrlTemplate = "https://{{serverEndpoint}}/{{organizationName}}";

public static GetAzureDevOpsBranchUrl(

        branchName: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._azureDevOpsBranchUrlTemplate,
            {
                "branchName": branchName,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetAzureDevOpsCommitUrl(

        commitId: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._azureDevOpsCommitUrlTemplate,
            {
                "commitId": commitId,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetAzureDevOpsFileLineUrl(

        commitHash: string,
        filePath: string,
        line: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._azureDevOpsFileLineUrlTemplate,
            {
                "commitHash": commitHash,
                "filePath": filePath,
                "line": line,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetAzureDevOpsFileUrl(

        commitHash: string,
        filePath: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._azureDevOpsFileUrlTemplate,
            {
                "commitHash": commitHash,
                "filePath": filePath,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetAzureDevOpsOrganizationUrl(organizationName: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._azureDevOpsOrganizationUrlTemplate,
            {
                "organizationName": organizationName
            });
    }

public static GetBitbucketBranchUrl(

        branchName: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._bitbucketBranchUrlTemplate,
            {
                "branchName": branchName,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetBitbucketCommitUrl(

        commitHash: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._bitbucketCommitUrlTemplate,
            {
                "commitHash": commitHash,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetBitbucketFileLineUrl(

        commitHash: string,
        filePath: string,
        line: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._bitbucketFileLineUrlTemplate,
            {
                "commitHash": commitHash,
                "filePath": filePath,
                "line": line,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetBitbucketFileUrl(

        commitHash: string,
        filePath: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._bitbucketFileUrlTemplate,
            {
                "commitHash": commitHash,
                "filePath": filePath,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetBitbucketOrganizationUrl(organizationName: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._bitbucketOrganizationUrlTemplate,
            {
                "organizationName": organizationName
            });
    }

public static GetGitHubBranchUrl(

        branchName: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitHubBranchUrlTemplate,
            {
                "branchName": branchName,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetGitHubCommitUrl(

        commitHash: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitHubCommitUrlTemplate,
            {
                "commitHash": commitHash,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetGitHubFileLineUrl(

        commitHash: string,
        filePath: string,
        line: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitHubFileLineUrlTemplate,
            {
                "commitHash": commitHash,
                "filePath": filePath,
                "line": line,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetGitHubFileUrl(

        commitHash: string,
        filePath: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitHubFileUrlTemplate,
            {
                "commitHash": commitHash,
                "filePath": filePath,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetGitHubOrganizationUrl(

        organizationName: string,
        serverEndpoint: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitHubOrganizationUrlTemplate,
            {
                "organizationName": organizationName,
                "serverEndpoint": serverEndpoint
            });
    }

public static GetGitLabBranchUrl(

        branchName: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitLabBranchUrlTemplate,
            {
                "branchName": branchName,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetGitLabCommitUrl(

        commitHash: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitLabCommitUrlTemplate,
            {
                "commitHash": commitHash,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetGitLabFileLineUrl(

        commitHash: string,
        filePath: string,
        line: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitLabFileLineUrlTemplate,
            {
                "commitHash": commitHash,
                "filePath": filePath,
                "line": line,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetGitLabFileUrl(

        commitHash: string,
        filePath: string,
        tenantRawUrl: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitLabFileUrlTemplate,
            {
                "commitHash": commitHash,
                "filePath": filePath,
                "tenantRawUrl": tenantRawUrl
            });
    }

public static GetGitLabOrganizationUrl(

        organizationName: string,
        serverEndpoint: string) {
        return Mustache.render(
            CodeConsoleUrlBuilder._gitLabOrganizationUrlTemplate,
            {
                "organizationName": organizationName,
                "serverEndpoint": serverEndpoint
            });
    }
}