﻿import { InlineItems, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, DataCategory, useDataSensitivityTranslator, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../utilities";

export function useGetDataAnalysisResourceRiskContext() {
    return useMemo(
        () => useDataAnalysisResourceRiskContext,
        []);
}

function useDataAnalysisResourceRiskContext(dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel) {
    const dataSensitivityTranslator = useDataSensitivityTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetDataAnalysisResourceRiskContext",
            () => ({
                highestDataSensitivity: {
                    dataCategories: [
                        "1 data category",
                        "{{count | NumberFormatter.humanize}} data categories"
                    ],
                    text: "The {{translatedEntityTypeName}}'s sensitivity level is {{highestDataSensitivity}} and includes {{dataCategories}}"
                }
            }));

    const translatedEntityTypeName =
        entityTypeNameTranslator(
            dataAnalysisResourceModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    const dataCategories =
        _(dataAnalysisResourceModel.dataCategoryToSensitivityMap).
            keys().
            value() as Contract.DataCategory[];

    return {
        highestDataSensitivity:
            _.isEmpty(dataCategories) ||
            _.isNil(dataAnalysisResourceModel.highestDataSensitivity)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.highestDataSensitivity.text({
                        dataCategories:
                            <InlineItems
                                items={dataCategories}
                                itemsPopover={
                                    items =>
                                        <Stack
                                            alignItems="self-start"
                                            gap={1}>
                                            {_.map(
                                                items,
                                                category =>
                                                    <DataCategory
                                                        category={category}
                                                        key={category}/>)}
                                        </Stack>}
                                namePluralizer={localization.highestDataSensitivity.dataCategories}
                                popoverElementSx={{ minWidth: "auto" }}
                                variant="itemCountAndType"/>,
                        highestDataSensitivity: dataSensitivityTranslator(dataAnalysisResourceModel.highestDataSensitivity, "text"),
                        translatedEntityTypeName
                    }))
    };
}