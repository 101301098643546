import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsTenantEntityRiskContext } from "../contexts";

export function useAwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRisk;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AwsTenantEntityModel;

    const getAwsTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskDefinition",
            () => ({
                description: "{{tenantEntity}} CloudTrail write data events for S3 bucket object-level is not enabled",
                sections: {
                    resolution: {
                        step1: "AWS CloudTrail multi-Region trail must log all write S3 data events for your buckets. You can either create a new CloudTrail or edit an existing one.",
                        step2: {
                            step1: "In **Trails**, select the relevant multi-region trail",
                            step2: "Under **Data events**, click **Edit**",
                            step3: "For **Event type**, check **Data events**",
                            step4: "Under **Data events**, select **S3** for **Data event type** and **Log all events** for **Log selector template**",
                            step5: "Click **Save changes**",
                            title: "Edit an existing CloudTrail"
                        },
                        step3: {
                            step1: "In **Trails**, click **Create trail**",
                            step2: "Fill the trail details in step 1 and click Next",
                            step3: "Under **Events**, check **Data events**",
                            step4: "Under **Data events**, select **S3** for **Data event type** and **Log all events** for **Log selector template**",
                            step5: "Click **Next** and **Create trail**",
                            title: "Create a new CloudTrail"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudTrail,
                AwsConsoleUrlBuilder.getCloudTrailUrl(tenantConfiguration.partitionType)),
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step3.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step3.step1()}
                            {localization.sections.resolution.step3.step2()}
                            {localization.sections.resolution.step3.step3()}
                            {localization.sections.resolution.step3.step4()}
                            {localization.sections.resolution.step3.step5()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getAwsTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}