import { Action0, Dropdown, Loading, useExecuteOperation } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Contract, Entities, RiskController, RiskPolicyTypeMetadataHelper, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../../../../common";
import { useItemContext } from "../../../../../../../Profile";

type FailedEntitiesCellProps = {
    item: Contract.ComplianceRiskPolicyData;
};

export function FailedEntitiesCell({ item }: FailedEntitiesCellProps) {
    const [open, setOpen] = useState(false);
    const entityTypeName =
        useMemo(
            () =>
                RiskPolicyTypeMetadataHelper.getComplianceRiskedEntityTypeName(item.riskPolicyConfigurationTypeName) ??
                RiskPolicyTypeMetadataHelper.getRiskedEntityTypeName(item.riskPolicyConfigurationTypeName),
            [item]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const theme = useTheme();
    return (
        <Dropdown
            disabled={item.failedEntityCount === 0}
            justify="center"
            open={open}
            popoverElement={
                () =>
                    <Loading container="popup">
                        <FailedEntities
                            entityTypeName={entityTypeName}
                            item={item}
                            onEntityClick={() => setOpen(false)}/>
                    </Loading>}
            sx={{ width: "fit-content" }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            <Typography
                sx={{
                    color: theme.palette.text.primary,
                    cursor: "pointer",
                    textDecoration: "underline"
                }}>
                {entityTypeNameTranslator(
                    entityTypeName,
                    {
                        count: item.failedEntityCount,
                        includeCount: true,
                        includeServiceName: false,
                        variant: "text"
                    })}
            </Typography>
        </Dropdown>);
}

type FailedEntitiesProps = {
    entityTypeName: string;
    item: Contract.ComplianceRiskPolicyData;
    onEntityClick: Action0;
};

function FailedEntities({ entityTypeName, item, onEntityClick }: FailedEntitiesProps) {
    const { scopeId } = useItemContext();
    const [{ failedEntityIds }] =
        useExecuteOperation(
            [FailedEntities, `${scopeId}/${item.riskPolicyIdentifier}`],
            async () =>
                await RiskController.getRiskPolicyFailedEntityIds(
                    new Contract.RiskControllerGetRiskPolicyFailedEntityIdsRequest(
                        item.analysisGroupType,
                        item.riskPolicyIdentifier,
                        scopeId)));

    const theme = useTheme();
    return (
        <Box
            sx={{
                minWidth: theme.spacing(20),
                padding: theme.spacing(1)
            }}>
            <Entities
                entityIdsOrModels={failedEntityIds}
                entityTypeName={entityTypeName}
                entityVariant="iconTextTypeTenant"
                searchEnabled={true}
                variant="dropdown"
                onEntityClick={onEntityClick}/>
        </Box>);
}