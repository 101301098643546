﻿import { CheckboxField, Message, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../../../../../themes";

type AccessKeyInputProps = {
    disabled: boolean;
    initialSecret: string;
    initialSessionToken: string;
    initialUniqueId: string;
    onSecretChanged: (secret: string, valid: boolean) => void;
    onSessionTokenChanged: (sessionToken: string) => void;
    onStoreChanged: (store: boolean) => void;
    onUniqueIdChanged: (uniqueId: string, valid: boolean) => void;
};

export function AccessKeyInput({ disabled, initialSecret, initialSessionToken, initialUniqueId, onSecretChanged, onSessionTokenChanged, onStoreChanged, onUniqueIdChanged }: AccessKeyInputProps) {
    const [secret, setSecret] = useState(initialSecret);
    const [sessionToken, setSessionToken] = useState(initialSessionToken);
    const [store, setStore] = useState(false);
    const [uniqueId, setUniqueId] = useState(initialUniqueId);

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useAwsDefinition.awsAccessKeyItem.accessKeyInput",
            () => ({
                secret: {
                    error: {
                        required: "Secret Access Key cannot be empty"
                    },
                    title: "Secret Access Key"
                },
                sessionToken: "Session Token (optional)",
                store: {
                    info: "Credentials will be stored only for the current browser session and will be deleted when you sign out of Tenable Cloud Security.",
                    title: "Store credentials for the current session"
                },
                uniqueId: {
                    error: {
                        format: "Access Key ID must contain only alphanumeric characters",
                        prefix: "Access Key ID must start with AKIA or ASIA",
                        required: "Access Key ID cannot be empty"
                    },
                    title: "Access Key ID"
                }
            }));

    const [secretValidationController, secretValidationMessage] =
        useInputValidation(
            () => {
                const validationSecret = secret?.trim();
                if (_.isEmpty(validationSecret)) {
                    return localization.secret.error.required();
                }

                return undefined;
            },
            [secret]);
    const [uniqueIdValidationController, uniqueIdValidationMessage] =
        useInputValidation(
            () => {
                const validationUniqueId = uniqueId?.trim();
                if (_.isEmpty(validationUniqueId)) {
                    return localization.uniqueId.error.required();
                }
                if (!_.startsWith(validationUniqueId, "AKIA") &&
                    !_.startsWith(validationUniqueId, "ASIA")) {
                    return localization.uniqueId.error.prefix();
                }
                if (!/^[\w]+$/.test(validationUniqueId)) {
                    return localization.uniqueId.error.format();
                }

                return undefined;
            },
            [uniqueId]);

    useEffect(
        () => onSecretChanged(secret?.trim(), secretValidationController.isValid()),
        [secret]);

    useEffect(
        () => onSessionTokenChanged(sessionToken?.trim()),
        [sessionToken]);

    useChangeEffect(
        () => onStoreChanged(store),
        [store]);

    useEffect(
        () => onUniqueIdChanged(uniqueId?.trim(), uniqueIdValidationController.isValid()),
        [uniqueId]);

    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{ maxWidth: theme.spacing(50) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={disabled}
                    label={localization.uniqueId.title()}
                    value={uniqueId}
                    variant="outlined"
                    onChange={event => setUniqueId(event.target.value)}/>
                {!_.isNil(uniqueIdValidationMessage) && (
                    <FormHelperText error={true}>{uniqueIdValidationMessage}</FormHelperText>)}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={disabled}
                    label={localization.secret.title()}
                    value={secret}
                    variant="outlined"
                    onChange={event => setSecret(event.target.value)}/>
                {!_.isNil(secretValidationMessage) && (
                    <FormHelperText error={true}>{secretValidationMessage}</FormHelperText>)}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={disabled}
                    label={localization.sessionToken()}
                    value={sessionToken}
                    variant="outlined"
                    onChange={event => setSessionToken(event.target.value)}/>
            </FormControl>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <CheckboxField
                    checked={store}
                    disabled={disabled}
                    onChange={() => setStore(!store)}>
                    {localization.store.title()}
                </CheckboxField>
                <Message
                    disabled={disabled}
                    level="info"
                    title={localization.store.info()}
                    variant="minimal"/>
            </Stack>
        </Stack>);
}