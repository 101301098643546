import { FormLayout, makeContextProvider, Message, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AutomationRuleItem, DeliveriesItem } from "../../../";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useDeliveries, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../common";
import { useAuditEventAutomationsContext, useSetAuditEventAutomationsContext } from "../../AuditEventAutomations";
import { AuditEventTypesItem } from "./components";

export class AddOrEditContext {
    public automationRuleActionDeliveries: Contract.DeliveryWrapper[];
    public automationRuleAuditEventTypeNames?: string[];
    public automationRuleDescription?: string;
    public automationRuleExistingRuleId?: string;
    public automationRuleIncludeSystemIdentity?: boolean;
    public automationRuleName?: string;
    public automationRuleScopeId?: string;
    public automationRuleScopeIds?: string[];

    constructor(public auditEventAutomationRuleConfiguration?: Contract.AuditEventAutomationRuleConfiguration) {
        this.automationRuleActionDeliveries = auditEventAutomationRuleConfiguration?.deliveries ?? [];
        this.automationRuleAuditEventTypeNames = auditEventAutomationRuleConfiguration?.auditEventTypeNames;
        this.automationRuleDescription = auditEventAutomationRuleConfiguration?.description;
        this.automationRuleExistingRuleId = auditEventAutomationRuleConfiguration?.id;
        this.automationRuleIncludeSystemIdentity = auditEventAutomationRuleConfiguration?.includeSystemIdentity;
        this.automationRuleName = auditEventAutomationRuleConfiguration?.name;
        this.automationRuleScopeId = auditEventAutomationRuleConfiguration?.scopeId;
        this.automationRuleScopeIds = auditEventAutomationRuleConfiguration?.scopeIds;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const [executing, setExecuting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { addOrEditOpen } = useAuditEventAutomationsContext();
    const setAuditEventAutomationsContext = useSetAuditEventAutomationsContext();
    const [addOrEditContext, setAddOrEditContext, ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    addOrEditOpen === true
                        ? undefined
                        : addOrEditOpen as Contract.AuditEventAutomationRuleConfiguration));
    const [sectionValidation, setSectionValidation] =
        useState<{ [key: string]: boolean }>({
            auditEventTypesItem: _.isNil(addOrEditContext.automationRuleAuditEventTypeNames) || !_.isEmpty(addOrEditContext.automationRuleAuditEventTypeNames),
            automationRuleItem: false
        });
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { createItem, deliveries, onChange, valid: deliveriesValidation } = useDeliveries(addOrEditContext.automationRuleActionDeliveries ?? []);
    const localization =
        useLocalization(
            "views.customer.configuration.automations.auditEventAutomations.addOrEdit",
            () => ({
                add: {
                    button: "Add",
                    error: "Failed to create Automation Rule",
                    title: "Add an Automation Rule"
                },
                edit: {
                    button: "Save",
                    error: "Failed to edit Automation Rule",
                    title: "Edit an Automation Rule"
                },
                typeNames: {
                    subtitle: "Select any actions that you want to trigger the automation rule.",
                    title: "Actions"
                }
            }));

    async function onFinished() {
        setExecuting(true);
        try {
            const { scopeSystemEntityModel } =
                await ConfigurationController.upsertAuditEventAutomationRule(
                    new Contract.ConfigurationControllerUpsertAuditEventAutomationRuleRequest(
                        addOrEditContext.automationRuleAuditEventTypeNames,
                        deliveries,
                        addOrEditContext.automationRuleDescription,
                        addOrEditContext.automationRuleExistingRuleId,
                        addOrEditContext.automationRuleIncludeSystemIdentity!,
                        addOrEditContext.automationRuleName!,
                        scopeNodeModel.configuration.id,
                        addOrEditContext.automationRuleScopeIds!));
            await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            setAuditEventAutomationsContext(
                automationContext => ({
                    ...automationContext,
                    addOrEditOpen: false
                }));
        } catch {
            setErrorMessage(
                addOrEditOpen === true
                    ? localization.add.error()
                    : localization.edit.error());
        }
        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <ContextProvider>
            <FormLayout
                footerOptions={{
                    border: true,
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between"
                            spacing={1}>
                            <Box>
                                {!_.isNil(errorMessage) &&
                                    <Message
                                        level="error"
                                        title={errorMessage}/>}
                            </Box>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                {executing &&
                                    <CircularProgress
                                        size={theme.spacing(2)}
                                        variant="indeterminate"/>}
                                <Button
                                    disabled={
                                        !sectionValidation.automationRuleItem ||
                                        !sectionValidation.auditEventTypesItem ||
                                        !deliveriesValidation ||
                                        _.isEmpty(deliveries) ||
                                        executing}
                                    onClick={onFinished}>
                                    {addOrEditOpen === true
                                        ? localization.add.button()
                                        : localization.edit.button()}
                                </Button>
                            </Stack>
                        </Stack>
                }}
                titleOptions={{
                    text:
                        addOrEditOpen === true
                            ? localization.add.title()
                            : localization.edit.title()
                }}>
                <Stack
                    direction="column"
                    spacing={3}>
                    <AutomationRuleItem
                        executing={executing}
                        initialDescription={addOrEditContext.automationRuleDescription}
                        initialName={addOrEditContext.automationRuleName}
                        initialScopeId={addOrEditContext.automationRuleScopeId}
                        initialScopeIds={addOrEditContext.automationRuleScopeIds}
                        setValidation={
                            valid =>
                                setSectionValidation(
                                    sectionValidation => ({
                                        ...sectionValidation,
                                        automationRuleItem: valid
                                    }))}
                        onValuesChange={
                            (description, name, scopeIds) =>
                                setAddOrEditContext(
                                    addOrEditContext => ({
                                        ...addOrEditContext,
                                        automationRuleDescription: description,
                                        automationRuleName: name,
                                        automationRuleScopeIds:
                                            _.isEmpty(scopeIds)
                                                ? addOrEditContext.automationRuleScopeIds ?? [scopeNodeModel.configuration.id]
                                                : scopeIds
                                    }))}/>
                    <Stack spacing={1}>
                        <Box>
                            <Typography variant="h4">
                                {localization.typeNames.title()}
                            </Typography>
                            <Typography variant="subtitle1">
                                {localization.typeNames.subtitle()}
                            </Typography>
                        </Box>
                        <AuditEventTypesItem
                            setValidation={
                                valid =>
                                    setSectionValidation(
                                        sectionValidation => ({
                                            ...sectionValidation,
                                            auditEventTypesItem: valid
                                        }))}/>
                    </Stack>
                    <DeliveriesItem
                        createItem={createItem}
                        deliveries={deliveries}
                        scopeId={addOrEditContext.automationRuleScopeId ?? scopeNodeModel.configuration.id}
                        variant="auditEvent"
                        onChange={onChange}/>
                </Stack>
            </FormLayout>
        </ContextProvider>);
}