﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsResourceAccessLevelTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useEntityRiskContext } from "../../../useEntityRiskContext";
import { useResourceGeneralInformationStep } from "../../../useResourceGeneralInformationStep";
import { InlineActions } from "../../components";

export function useGetAwsResourceRiskContext() {
    return useMemo(
        () => useAwsResourceRiskContext,
        []);
}

function useAwsResourceRiskContext(resourceModel: Contract.AwsResourceModel) {
    const resource = resourceModel.entity as Contract.AwsResource;
    const entityRiskContext = useEntityRiskContext(resourceModel);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(resourceModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsResourceRiskContext",
            () => ({
                accessLevel: "The {{translatedResourceTypeName}}’s access level is **{{accessLevel}}**",
                permissions: {
                    allPrincipalHighSeverity: "The {{translatedResourceTypeName}}'s resource policy grants {{publicAccessHighSeveritySensitivePermissionActions}} with high severity to any principal",
                    anyPrincipal: "The {{translatedResourceTypeName}}'s resource policy grants {{publicAccessPermissionActions}} to any principal",
                    anyPrincipalHighSeverity: "The {{translatedResourceTypeName}}'s resource policy grants {{publicAccessPermissionActions}} to any principal, {{publicAccessHighSeveritySensitivePermissionActions}} with high severity"
                }
            }));
    const translatedResourceTypeName =
        entityTypeNameTranslator(
            resource.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        accessLevel:
            TypeHelper.extendOrImplement(resourceModel.typeName, Contract.TypeNames.IAwsAccessResourceModel) &&
            !_.isNil(_.as<Contract.IAwsAccessResourceModel>(resourceModel).accessLevel)
                ? new RiskDefinitionContextItem(
                    localization.accessLevel({
                        accessLevel: resourceAccessLevelTranslator(_.as<Contract.IAwsAccessResourceModel>(resourceModel).accessLevel!),
                        translatedResourceTypeName
                    }))
                : undefined,
        generalInformation:
            _.isNil(resourceGeneralInformationStep)
                ? undefined
                : new RiskDefinitionContextItem(resourceGeneralInformationStep),
        getPublicAccessPermissionActions:
            (publicAccessHighSeveritySensitivePermissionActions: string[], publicAccessPermissionActions: string[]) =>
                _.isEmpty(publicAccessPermissionActions)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        (_.isEmpty(publicAccessHighSeveritySensitivePermissionActions)
                            ? localization.permissions.anyPrincipal
                            : publicAccessHighSeveritySensitivePermissionActions.length === publicAccessPermissionActions.length
                                ? localization.permissions.allPrincipalHighSeverity
                                : localization.permissions.anyPrincipalHighSeverity)({
                            publicAccessHighSeveritySensitivePermissionActions:
                                <InlineActions actions={publicAccessHighSeveritySensitivePermissionActions}/>,
                            publicAccessPermissionActions:
                                <InlineActions actions={publicAccessPermissionActions}/>,
                            translatedResourceTypeName
                        }))
    };
}