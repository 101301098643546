import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsElastiCacheReplicationGroupRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsElastiCacheReplicationGroupAutoFailoverDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const replicationGroupAutoFailoverDisabledRiskModel = riskModel as Contract.AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskModel;
    const risk = riskModel.risk as Contract.AwsElastiCacheReplicationGroupAutoFailoverDisabledRisk;
    const replicationGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElastiCacheReplicationGroupModel;

    const getAwsElastiCacheReplicationGroupRiskContext = useGetAwsElastiCacheReplicationGroupRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsElastiCacheReplicationGroupAutoFailoverDisabledRiskDefinition",
            () => ({
                description: "{{replicationGroup}} automatic failover is not enabled",
                sections: {
                    resolution: {
                        step1: {
                            link: "AWS documentation",
                            text: "Note, Redis OSS cluster cache must have at least one read replica to enable autofailover. See {{elastiCacheReplicationReadReplicaDocumentationUrl}} for more information."
                        },
                        step2: "Click **Modify**",
                        step3: "In the **Location** section, check the **Auto-failover** option",
                        step4: "Click **Preview changes**",
                        step5: "Click **Modify**"
                    }

                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            replicationGroup:
                <Entity
                    entityIdOrModel={replicationGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ElastiCache,
                AwsConsoleUrlBuilder.getElastiCacheReplicationGroupUrl(replicationGroupModel.entity as Contract.AwsElastiCacheReplicationGroup)),
            localization.sections.resolution.step1.text({
                elastiCacheReplicationReadReplicaDocumentationUrl:
                    <Link
                        urlOrGetUrl={replicationGroupAutoFailoverDisabledRiskModel.elastiCacheReplicationReadReplicaDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const replicationGroupContextItems = getAwsElastiCacheReplicationGroupRiskContext(replicationGroupModel);
            return [
                replicationGroupContextItems.generalInformation,
                replicationGroupContextItems.sensitive,
                replicationGroupContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}