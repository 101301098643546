﻿import { CopyToClipboardActionButton, DiffEditor, ItemSelector } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Contract, useTheme } from "../../../../../../../../../../../common";

type CodeResolutionSectionProps = {
    fileDiffsData: Contract.RiskControllerGetRiskCodeFileDiffsResponseData;
};

export function CodeResolutionSection({ fileDiffsData }: CodeResolutionSectionProps) {
    const [fileDiff, setFileDiff] = useState(fileDiffsData.fileDiffs[0]);

    const theme = useTheme();
    return (
        <Stack
            sx={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                marginTop: theme.spacing(1)
            }}>
            <Stack
                alignItems="center"
                direction="row"
                sx={{ borderBottom: theme.border.primary }}>
                <Box
                    sx={{
                        flex: 1,
                        flexBasis: 0,
                        minWidth: 0,
                        overflow: "hidden"
                    }}>
                    <ItemSelector
                        fieldSx={{ border: "none" }}
                        fullWidth={true}
                        getItemText={fileDiff => fileDiff.relativePath}
                        items={fileDiffsData.fileDiffs}
                        selectedItem={fileDiff}
                        onSelectedItemChanged={fileDiff => setFileDiff(fileDiff)}>
                        {codeFileChange =>
                            <Typography>
                                {codeFileChange.relativePath}
                            </Typography>}
                    </ItemSelector>
                </Box>
                <Box
                    sx={{
                        alignItems: "center",
                        borderLeft: theme.border.primary,
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        width: "44px"
                    }}>
                    <CopyToClipboardActionButton
                        getValue={() => fileDiff.newContent}
                        size="small"/>
                </Box>
            </Stack>
            <DiffEditor
                containerSx={{
                    border: "unset",
                    borderRadius: "unset"
                }}
                format={
                    fileDiff!.codeType == Contract.CodeType.Terraform
                        ? "hcl"
                        : "json"
                }
                height="450px"
                modified={fileDiff!.newContent}
                original={fileDiff!.existingContent}
                variant="collapsable"/>
        </Stack>);
}