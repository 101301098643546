import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlinePermissionActions, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { ChangeStep, RiskDefinitionContextItem, RiskDefinitionSection, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { ResourceRiskPolicyDiff, UpsertResourcePolicyChangeActions } from "../../components";

export function useAwsCommonExcessivePermissionResourceRiskDefinition(
    getContextItems: () => Optional<RiskDefinitionContextItem>[],
    riskModel: Contract.RiskModel,
    sections?: (RiskDefinitionSection | RiskDefinitionSectionGroup)[]) {
    const excessivePermissionResourceRiskModel = riskModel as Contract.AwsExcessivePermissionResourceRiskModel;
    const resourceModel = entityModelStore.useGet(excessivePermissionResourceRiskModel.risk.entityId) as Contract.AwsResourceModel;
    const resource = resourceModel.entity as Contract.AwsResource;
    const resolutionChange = excessivePermissionResourceRiskModel.risk.resolutionChanges![0] as Contract.AwsResourceChange;
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const getAwsCommonExcessivePermissionResourceRiskDefinitionRiskContext = useGetAwsCommonExcessivePermissionResourceRiskDefinitionRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsCommonExcessivePermissionResourceRiskDefinition",
            () => ({
                description: {
                    [Contract.TypeNames.AwsExcessivePermissionResourceRiskModelDescriptionType]: {
                        [Contract.AwsExcessivePermissionResourceRiskModelDescriptionType.AnonymousIdentityWithoutStatementCondition]:
                            "{{resource}} has a {{translatedResourceTypeName}} policy that grants excessive **public** access",
                        [Contract.AwsExcessivePermissionResourceRiskModelDescriptionType.AnonymousIdentityWithStatementCondition]:
                            "{{resource}} has a {{translatedResourceTypeName}} policy that grants excessive access to any principal under conditions",
                        [Contract.AwsExcessivePermissionResourceRiskModelDescriptionType.InternalTenant]:
                            "{{resource}} has a {{translatedResourceTypeName}} policy that grants excessive internal access",
                        [Contract.AwsExcessivePermissionResourceRiskModelDescriptionType.KnownExternalTenant]:
                            "{{resource}} has a {{translatedResourceTypeName}} policy that grants excessive access to {{existingPermissionActionKnownExternalTenants}}",
                        [Contract.AwsExcessivePermissionResourceRiskModelDescriptionType.UnknownExternalTenant]:
                            "{{resource}} has a {{translatedResourceTypeName}} policy that grants excessive access to {{existingPermissionActionUnknownExternalTenants}}"
                    }
                },
                knownTenants: [
                    "1 account",
                    "{{count | NumberFormatter.humanize}} accounts"
                ],
                unknownAndVendorTenants: "{{permissionActionUnknownExternalTenants}} and {{permissionActionVendorTenants}}",
                unknownTenants: [
                    "1 unknown account",
                    "{{count | NumberFormatter.humanize}} unknown accounts"
                ],
                vendorTenants: [
                    "1 3rd party account",
                    "{{count | NumberFormatter.humanize}} 3rd party accounts"
                ]
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description[Contract.TypeNames.AwsExcessivePermissionResourceRiskModelDescriptionType][excessivePermissionResourceRiskModel.descriptionType]({
            existingPermissionActionKnownExternalTenants:
                excessivePermissionResourceRiskModel.risk.severityInfo.accessType == Contract.AwsExcessivePermissionResourceRiskAccessType.KnownExternalTenant
                    ? <InlineEntities
                        entityIdsOrModels={excessivePermissionResourceRiskModel.risk.severityInfo.existingPermissionActionIdentityIds}
                        entityTypeName={Contract.TypeNames.AwsTenantEntity}
                        namePluralizer={localization.knownTenants}
                        variant="itemAndTypeOrItemCountAndType"/>
                    : undefined,
            existingPermissionActionUnknownExternalTenants:
                excessivePermissionResourceRiskModel.risk.severityInfo.accessType == Contract.AwsExcessivePermissionResourceRiskAccessType.UnknownExternalTenant
                    ? _.isEmpty(excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds)
                        ? <InlineEntities
                            entityIdsOrModels={excessivePermissionResourceRiskModel.risk.severityInfo.existingPermissionActionIdentityIds}
                            entityTypeName={Contract.TypeNames.AwsTenantEntity}
                            namePluralizer={localization.unknownTenants}
                            variant="itemCountAndType"/>
                        : excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds.length === excessivePermissionResourceRiskModel.risk.severityInfo.existingPermissionActionIdentityIds.length
                            ? <InlineEntities
                                entityIdsOrModels={excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds}
                                entityTypeName={Contract.TypeNames.AwsTenantEntity}
                                namePluralizer={localization.vendorTenants}
                                variant="itemCountAndType"/>
                            : localization.unknownAndVendorTenants({
                                permissionActionUnknownExternalTenants:
                                    <InlineEntities
                                        entityIdsOrModels={
                                            _.difference(
                                                excessivePermissionResourceRiskModel.risk.severityInfo.existingPermissionActionIdentityIds,
                                                excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds)}
                                        entityTypeName={Contract.TypeNames.AwsTenantEntity}
                                        namePluralizer={localization.unknownTenants}
                                        variant="itemCountAndType"/>,
                                permissionActionVendorTenants:
                                    <InlineEntities
                                        entityIdsOrModels={excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds}
                                        entityTypeName={Contract.TypeNames.AwsTenantEntity}
                                        namePluralizer={localization.vendorTenants}
                                        variant="itemCountAndType"/>
                            })
                    : undefined,
            resource:
                <Entity
                    entityIdOrModel={resourceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            translatedResourceTypeName:
                entityTypeNameTranslator(
                    resource.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    })
        }),
        () => [
            TypeHelper.extendOrImplement(resolutionChange.typeName, Contract.TypeNames.AwsUpsertResourcePolicyChange)
                ? new ChangeStep(
                    resolutionChange,
                    {
                        actionElements: [
                            <UpsertResourcePolicyChangeActions
                                key="upsertResourcePolicyChangeActions"
                                newPolicyFileTypeToFileMap={excessivePermissionResourceRiskModel.newPolicyFileTypeToFileMap}/>
                        ],
                        contentElement: <ResourceRiskPolicyDiff
                            existingRawPolicyDocument={_.as<Contract.IAwsPolicyResource>(resource).policyDocument!.raw}
                            newRawPolicyDocument={excessivePermissionResourceRiskModel.risk.newPolicyDocument?.raw}
                            policyFileName="resource_policy"/>
                    })
                : new ChangeStep(resolutionChange)
        ],
        excessivePermissionResourceRiskModel,
        () => {
            const commonExcessivePermissionResourceRiskDefinitionRiskContext = getAwsCommonExcessivePermissionResourceRiskDefinitionRiskContext(riskModel);
            return _<Optional<RiskDefinitionContextItem>>([]).
                concat(getContextItems()).
                concat(commonExcessivePermissionResourceRiskDefinitionRiskContext).
                value();
        },
        {
            sections
        });
}

function useGetAwsCommonExcessivePermissionResourceRiskDefinitionRiskContext() {
    return useMemo(
        () => useAwsCommonExcessivePermissionResourceRiskDefinitionRiskContext,
        []);
}

function useAwsCommonExcessivePermissionResourceRiskDefinitionRiskContext(riskModel: Contract.RiskModel) {
    const excessivePermissionResourceRiskModel = riskModel as Contract.AwsExcessivePermissionResourceRiskModel;
    const resourceModel = entityModelStore.useGet(excessivePermissionResourceRiskModel.risk.entityId) as Contract.AwsResourceModel;
    const resource = resourceModel.entity as Contract.AwsResource;
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsCommonExcessivePermissionResourceRiskDefinition.contextSection",
            () => ({
                highSeverityPermissionActions: [
                    "1 high severity permission",
                    "{{count | NumberFormatter.humanize}} high severity permissions"
                ],
                knownTenants: [
                    "1 account",
                    "{{count | NumberFormatter.humanize}} accounts"
                ],
                unknownAndVendorTenants: "{{permissionActionUnknownExternalTenants}} and {{permissionActionVendorTenants}}",
                unknownTenants: [
                    "1 unknown account",
                    "{{count | NumberFormatter.humanize}} unknown accounts"
                ],
                vendorTenants: [
                    "1 3rd party account",
                    "{{count | NumberFormatter.humanize}} 3rd party accounts"
                ],
                [Contract.TypeNames.AwsExcessivePermissionResourceRiskAccessType]: {
                    [Contract.AwsExcessivePermissionResourceRiskAccessType.AnonymousIdentityWithoutStatementCondition]: "the public",
                    [Contract.AwsExcessivePermissionResourceRiskAccessType.AnonymousIdentityWithStatementCondition]: "the public **using conditions**",
                    [Contract.AwsExcessivePermissionResourceRiskAccessType.InternalTenant]: "internal identities",
                    [Contract.AwsExcessivePermissionResourceRiskAccessType.KnownExternalTenant]: "{{permissionActionExternalTenants}}",
                    [Contract.AwsExcessivePermissionResourceRiskAccessType.UnknownExternalTenant]: "{{permissionActionExternalTenants}}"
                },
                [Contract.TypeNames.AwsExcessivePermissionResourceRiskModelInfoType]: {
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypePermissionActions]: "The {{translatedResourceTypeName}} exposes {{permissionActions}} to {{accessType}} via the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypeHighSeverityPermissionActionsAll]: "The {{translatedResourceTypeName}} exposes {{highSeverityPermissionActions}} to {{accessType}} via the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypeHighSeverityPermissionActionsSomeMany]: "The {{translatedResourceTypeName}} exposes {{permissionActions}} to {{accessType}} via the {{translatedResourceTypeName}} policy. Of these, {{highSeverityPermissionActions}} have high severity",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypeHighSeverityPermissionActionsSomeSingle]: "The {{translatedResourceTypeName}} exposes {{permissionActions}} to {{accessType}} via the {{translatedResourceTypeName}} policy. Of these, {{highSeverityPermissionActions}} has high severity",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.AnonymousIdentityPermissionActionUnusedAll]: "Anonymous identity was not observed performing any of the actions granted via the {{translatedResourceTypeName}} policy during the learning period and can be safely removed from the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.AnonymousIdentityPermissionActionUnusedSome]: "Anonymous identity was not observed performing actions using {{unusedPermissionActions}} granted via the {{translatedResourceTypeName}} policy during the learning period and can be safely removed from the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.ExternalTenantPermissionActionUnusedSome]: "Since no identities from the {{permissionActionExternalTenants}} were observed using {{unusedPermissionActions}} granted via the {{translatedResourceTypeName}} policy during the learning period, the permissions can be safely removed from the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.InternalTenantPermissionActionUnusedAll]: "Since no identities from the {{translatedResourceTypeName}} account were observed using any of the permissions granted via the {{translatedResourceTypeName}} policy during the learning period, all permissions can be safely removed from the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.InternalTenantPermissionActionUnusedSome]: "Since no identities from the {{translatedResourceTypeName}} account were observed using {{unusedPermissionActions}} granted via the {{translatedResourceTypeName}} policy during the learning period, the permissions can be safely removed from the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.PermissionActionUnusedAll]: "Since no identities were observed using any of the permissions granted via the {{translatedResourceTypeName}} policy during the learning period, all permissions can be safely removed from the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.PermissionActionUnusedSome]: "Since no identities were observed using {{unusedPermissionActions}} granted via the {{translatedResourceTypeName}} policy during the learning period, the permissions can be safely removed from the {{translatedResourceTypeName}} policy",
                    [Contract.AwsExcessivePermissionResourceRiskModelInfoType.TenantS3ObjectEventsNotSynced]: "Since object-level actions (for example, **GetObject** or **PutObject**) are not audited in the CloudTrail configured for Tenable Cloud Security they cannot always be removed, even if they should be"
                }
            }));

    const createCommonInfoProps =
        () => ({
            resource:
                <Entity
                    entityIdOrModel={resourceModel}
                    variant="text"/>,
            translatedResourceTypeName:
                entityTypeNameTranslator(
                    resource.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    })
        });
    const createPermissionActionExternalTenantsElement =
        (accessType?: Contract.AwsExcessivePermissionResourceRiskAccessType, identityIds?: string[]) =>
            accessType == Contract.AwsExcessivePermissionResourceRiskAccessType.KnownExternalTenant
                ? <InlineEntities
                    entityIdsOrModels={identityIds!}
                    entityTypeName={Contract.TypeNames.AwsTenantEntity}
                    namePluralizer={localization.knownTenants}
                    variant="itemAndTypeOrItemCountAndType"/>
                : accessType == Contract.AwsExcessivePermissionResourceRiskAccessType.UnknownExternalTenant
                    ? _.isEmpty(excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds)
                        ? <InlineEntities
                            entityIdsOrModels={identityIds!}
                            entityTypeName={Contract.TypeNames.AwsTenantEntity}
                            namePluralizer={localization.unknownTenants}
                            variant="itemCountAndType"/>
                        : excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds.length === identityIds!.length
                            ? <InlineEntities
                                entityIdsOrModels={excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds}
                                entityTypeName={Contract.TypeNames.AwsTenantEntity}
                                namePluralizer={localization.vendorTenants}
                                variant="itemCountAndType"/>
                            : localization.unknownAndVendorTenants({
                                permissionActionUnknownExternalTenants:
                                    <InlineEntities
                                        entityIdsOrModels={
                                            _.difference(
                                                identityIds!,
                                                excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds)}
                                        entityTypeName={Contract.TypeNames.AwsTenantEntity}
                                        namePluralizer={localization.unknownTenants}
                                        variant="itemCountAndType"/>,
                                permissionActionVendorTenants:
                                    <InlineEntities
                                        entityIdsOrModels={excessivePermissionResourceRiskModel.risk.existingPermissionActionVendorTenantIds}
                                        entityTypeName={Contract.TypeNames.AwsTenantEntity}
                                        namePluralizer={localization.vendorTenants}
                                        variant="itemCountAndType"/>
                            })
                    : undefined!;
    return _.map(
        excessivePermissionResourceRiskModel.infos,
        info => {
            switch (info.type) {
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypePermissionActions:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypeHighSeverityPermissionActionsAll:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypeHighSeverityPermissionActionsSomeMany:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypeHighSeverityPermissionActionsSomeSingle: {
                    const accessTypeInfo = info as Contract.AwsExcessivePermissionResourceRiskModelAccessTypeInfo;
                    return new RiskDefinitionContextItem(
                        localization[Contract.TypeNames.AwsExcessivePermissionResourceRiskModelInfoType][info.type]({
                            ...createCommonInfoProps(),
                            accessType:
                                localization[Contract.TypeNames.AwsExcessivePermissionResourceRiskAccessType][accessTypeInfo.accessType]({
                                    permissionActionExternalTenants:
                                        createPermissionActionExternalTenantsElement(
                                            accessTypeInfo.accessType,
                                            accessTypeInfo.identityIds)
                                }),
                            highSeverityPermissionActions:
                                <InlinePermissionActions
                                    namePluralizer={
                                        info.type == Contract.AwsExcessivePermissionResourceRiskModelInfoType.AccessTypeHighSeverityPermissionActionsAll
                                            ? localization.highSeverityPermissionActions
                                            : undefined!}
                                    permissionActions={accessTypeInfo.highSeverityPermissionActions}
                                    variant="itemCountAndType"/>,
                            permissionActions:
                                <InlinePermissionActions
                                    permissionActions={accessTypeInfo.permissionActions}
                                    variant="itemCountAndType"/>
                        }));
                }
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.AnonymousIdentityPermissionActionUnusedAll:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.InternalTenantPermissionActionUnusedAll:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.PermissionActionUnusedAll:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.TenantS3ObjectEventsNotSynced: {
                    return new RiskDefinitionContextItem(localization[Contract.TypeNames.AwsExcessivePermissionResourceRiskModelInfoType][info.type](createCommonInfoProps()));
                }
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.AnonymousIdentityPermissionActionUnusedSome:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.ExternalTenantPermissionActionUnusedSome:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.InternalTenantPermissionActionUnusedSome:
                case Contract.AwsExcessivePermissionResourceRiskModelInfoType.PermissionActionUnusedSome: {
                    const unusedPermissionActionInfo = info as Contract.AwsExcessivePermissionResourceRiskModelUnusedPermissionActionInfo;

                    return new RiskDefinitionContextItem(
                        localization[Contract.TypeNames.AwsExcessivePermissionResourceRiskModelInfoType][info.type]({
                            ...createCommonInfoProps(),
                            permissionActionExternalTenants:
                                createPermissionActionExternalTenantsElement(
                                    unusedPermissionActionInfo.accessType,
                                    unusedPermissionActionInfo.identityIds),
                            unusedPermissionActions:
                                _.isNil(unusedPermissionActionInfo.permissionActions)
                                    ? undefined!
                                    : <InlinePermissionActions
                                        permissionActions={unusedPermissionActionInfo.permissionActions}
                                        variant="itemCountAndType"/>
                        }));
                }
                default:
                    return undefined!;
            }
        });
}