import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpIamRoleTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpIamRoleTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpIamRoleType]: {
                    [Contract.GcpIamRoleType.Basic]: "Basic",
                    [Contract.GcpIamRoleType.Custom]: "Custom",
                    [Contract.GcpIamRoleType.Predefined]: "Predefined"
                }
            }));
    return (type: Contract.GcpIamRoleType) =>
        localization[Contract.TypeNames.GcpIamRoleType][type]();
}