import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../../common";
import { CodeTenantIcon } from "../../../common/icons/TenantIcon/icons";
import { CodePipelineIcon } from "./CodePipelineIcon";

type CodeIntegrationIconProps =
    SvgIconProps & {
        configuration: Contract.CodeIntegrationConfiguration;
    };

export function CodeIntegrationIcon({ configuration, ...props }: CodeIntegrationIconProps) {
    return configuration.typeName == Contract.TypeNames.CodePipelineConfiguration
        ? <CodePipelineIcon
            pipelineType={(configuration as Contract.CodePipelineConfiguration).type}
            sx={{ fontSize: "24px" }}
            {...props}/>
        : <CodeTenantIcon
            data={{ codeTenantType: (configuration as Contract.CodeOrganizationConfiguration).type }}
            sx={{ fontSize: "24px" }}
            {...props}/>;
}