import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useAwsOrganizationsPolicyTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useAwsOrganizationsPolicyTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsOrganizationsPolicyType]: {
                    [Contract.AwsOrganizationsPolicyType.ResourceControlPolicy]: "Resource control policy",
                    [Contract.AwsOrganizationsPolicyType.ServiceControlPolicy]: "Service control policy"
                }
            }));

    return (type: Contract.AwsOrganizationsPolicyType) =>
        localization[Contract.TypeNames.AwsOrganizationsPolicyType][type]();
}