import { DataTableFetchItemsResult, DataTableSort, Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../common";
import { useRiskContext } from "../../../../useRiskContextProvider";
import { RiskDetailsSectionItem } from "../../../utilities";

type DetailSectionTableFetchItemsOptions<TItem> = {
    createItem?: (entityModelMap: Dictionary<Contract.EntityModel>, item: Contract.RiskItem) => TItem;
    getRelatedEntityIds?: (entityModel: Contract.EntityModel, riskItem: Contract.RiskItem) => string[];
    loadRelatedEntityModels?: (entityModelMap: Dictionary<Contract.EntityModel>, riskItemPage: Contract.RiskItem[]) => Promise<Contract.EntityModel[]>;
};

export function useDetailSectionTableFetchItems<TItem>(
    riskItems: Contract.RiskItem[],
    options?: DetailSectionTableFetchItemsOptions<TItem>) {
    const { excludedRiskItemEntityIds } = useRiskContext();
    const orderedRiskItems =
        _.orderBy(
            riskItems,
            riskItem => _.includes(excludedRiskItemEntityIds, riskItem.entityId));
    return useMemo(
        () => {
            let entityModelMap: Dictionary<Contract.EntityModel> = {};
            return async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
                const riskItemPage =
                    _(orderedRiskItems).
                        drop(skip).
                        take(limit).
                        value();

                const entityModels =
                    await entityModelStore.get(
                        _.map(
                            riskItemPage,
                            riskItem => riskItem.entityId));

                entityModelMap =
                    _(entityModels).
                        keyBy(entityModel => entityModel.entity.id).
                        merge(entityModelMap).
                        value();

                if (!_.isNil(options?.getRelatedEntityIds)) {
                    const relatedEntityModels =
                        await entityModelStore.get(
                            _(riskItemPage).
                                flatMap(riskItem => options!.getRelatedEntityIds!(entityModelMap[riskItem.entityId], riskItem)).
                                filter().
                                uniq().
                                value());
                    entityModelMap =
                        _(relatedEntityModels).
                            keyBy(relatedEntityModel => relatedEntityModel.entity.id).
                            merge(entityModelMap).
                            value();
                }

                if (!_.isNil(options?.loadRelatedEntityModels)) {
                    const relatedEntityModels =
                        await options!.loadRelatedEntityModels!(
                            entityModelMap,
                            riskItemPage);
                    entityModelMap =
                        _(relatedEntityModels).
                            keyBy(relatedEntityModel => relatedEntityModel.entity.id).
                            merge(entityModelMap).
                            value();
                }

                const itemPage =
                    _.map(
                        riskItemPage,
                        riskItem =>
                            options?.createItem?.(entityModelMap, riskItem) ??
                            new RiskDetailsSectionItem(
                                entityModelMap[riskItem.entityId],
                                riskItem));
                return new DataTableFetchItemsResult(
                    { count: riskItems.length },
                    itemPage,
                    skip + limit >= riskItems.length);
            };
        },
        [riskItems]);
}