import { AnalyticsContext, AnalyticsEventActionType, map, useTrackAnalyticsEvent } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, LicensingHelper, useApplicationViewRoute } from "../../../../common";
import { CustomerView } from "../../hooks";
import { Entities, Onboarding } from "./components";

export enum DataAnalysisView {
    Entities = "entities"
}

export function DataAnalysis() {
    const [{ view }] =
        useApplicationViewRoute(
            CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.DataAnalysis),
            _.values(DataAnalysisView),
            DataAnalysisView.Entities);

    return (
        <AnalyticsContext context={view}>
            <View view={view}/>
        </AnalyticsContext>);
}

type ViewProps = {
    view: DataAnalysisView;
};

function View({ view }: ViewProps) {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    return LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
        ? map(
            view,
            {
                [DataAnalysisView.Entities]: () => <Entities/>
            })
        : <Onboarding/>;
}