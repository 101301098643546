import { NoneIcon, Tooltip, useLocalization } from "@infrastructure";
import { Box, Grid2, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, InfoItem, SettingConfigurationHelper, useTheme } from "../../../../../../../../../../common";
import { ValueProps, ValueViewProps } from "../../Value";
import { Edit } from "./components";

export function AadSeverePermissionDirectoryRoleDefinitionValue(props: ValueProps) {
    return (
        props.variant === "view"
            ? <View {...props}/>
            : <Edit {...props}/>);
}

function View({ settingConfiguration }: ValueViewProps) {
    const items = SettingConfigurationHelper.getValue(settingConfiguration) as Contract.AadSeverePermissionDirectoryRoleDefinitionData[];

    const localization =
        useLocalization(
            "views.customer.configuration.settingConfigurationTable.value.aadSeverePermissionDirectoryRoleDefinitionValue",
            () => ({
                additionalItems: "+{{otherItemCount | NumberFormatter.humanize}}"
            }));

    const theme = useTheme();
    return (
        _.isEmpty(items)
            ? <NoneIcon/>
            : <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}>
                <Box sx={{ maxWidth: "90%" }}>
                    <InfoItem
                        copyToClipboardOptions={{ disabled: true }}
                        title={items[0].name}
                        value={
                            <Typography noWrap={true}>
                                {items[0].templateId}
                            </Typography>}/>
                </Box>
                <Box sx={{ maxWidth: "10%" }}>
                    {items.length > 1 &&
                    <Tooltip
                        placement="left-start"
                        sx={{ maxWidth: "350px" }}
                        titleOrGetTitle={
                            <Grid2
                                container={true}
                                spacing={1}>
                                {_.map(
                                    items,
                                    item =>
                                        <Fragment>
                                            <Grid2 size={3}>
                                                <Typography
                                                    noWrap={true}
                                                    sx={{ color: theme.palette.text.secondary }}>
                                                    {item.name}
                                                </Typography>
                                            </Grid2>
                                            <Grid2 size={9}>
                                                <Typography noWrap={true}>
                                                    {item.templateId}
                                                </Typography>
                                            </Grid2>
                                        </Fragment>)}
                            </Grid2>}>
                        <Typography
                            sx={{
                                "&::hover": {
                                    border: theme.border.hoverFocus
                                },
                                backgroundColor: theme.palette.background.paper,
                                border: theme.border.primary,
                                borderRadius: theme.spacing(0.75),
                                fontWeight: 500,
                                padding: theme.spacing(0, 0.5)
                            }}>
                            {localization.additionalItems({ otherItemCount: items.length - 1 })}
                        </Typography>
                    </Tooltip>}
                </Box>
            </Stack>);
}