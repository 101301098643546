﻿import { Box, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useCallback, useRef, useState } from "react";
import { CsvExportButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DeferredFilter, EmptyMessageText, EnumValuesFilter, InlineItems, map, Optional, optionalTableCell, PagedValuesFilter, SearchList, TimeFormatter, TimeHelper, TimeRangeFilter, useGetExportFileName, useLocalization, useOperation, ValueFilter, ValuesFilterSelection, VerticalFillGrid } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, EntityTypeFilter, ExportReportHelper, InlineVulnerability, ItemSelectionHelper, PagedEntityFilter, ScopeTenantFilter, Severity, SeverityFilter, StorageHelper, Tenant, TimeRangeHelper, TypeHelper, useEpssScoreTranslator, useLayoutOptions, useTheme, vulnerabilityModelStore, VulnerabilityResolutionVersion, workloadAnalysisVulnerabilityScoreTypeStore, WorkloadController } from "../../../../../../common";
import { ProfileView } from "../../../Entities/components";
import { ProfileCategory } from "../../../Entities/components/Profile/hooks";
import { ContainerImageUsageFilter } from "../../../Entities/components/Table/hooks/useDefinition/components";
import { useOperatingSystemTypeTranslator } from "../../../Entities/hooks";
import { useAttackVectorTranslator, useGetUnsupportedOperatingSystemMessage, useGetWorkloadResourceScanPackageEntityFilterIdPage, useGetWorkloadResourceScanPackageValueFilterItemPage } from "../../hooks";
import { ExploitableValueFilter } from "../ExploitableValueFilter";
import { InlineItemsCell } from "../InlineItemsCell";
import { Tabs } from "./Tabs";

export enum VulnerabilitiesView {
    Open = "Open",
    Resolved = "Resolved"
}

type VulnerabilitiesProp = {
    entityIds?: string[];
    entityModel?: Contract.EntityModel;
    severityFilterValues?: Contract.Severity[];
    variant?: "risk" | "view" | "entity";
};

export function Vulnerabilities({ entityIds, entityModel, severityFilterValues, variant = "view" }: VulnerabilitiesProp) {
    const attackVectorTranslator = useAttackVectorTranslator();
    const epssScoreTranslator = useEpssScoreTranslator();
    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);
    const operatingSystemTypeTranslator = useOperatingSystemTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities",
            () => ({
                columns: {
                    attackVector: {
                        filterPlaceholder: "Attack Vector",
                        title: "Attack Vector"
                    },
                    containerImageUsage: "Container Image Usage",
                    cvssScore: "CVSS Score",
                    cvssSeverity: "CVSS Severity",
                    description: "Description",
                    epssScore: "EPSS Score",
                    exploitable: {
                        false: "No",
                        title: "Known Exploit",
                        true: "Yes"
                    },
                    firstScanTime: "First Scan Time",
                    fixableVulnerability: "With Fix Only",
                    name: "Name",
                    operatingSystemType: "Operating System Type",
                    packageDisplayNames: "Software",
                    packageFilePath: "Path",
                    packageType: {
                        title: "Software Type",
                        [Contract.TypeNames.WorkloadResourceScanPackageType]: {
                            [Contract.WorkloadResourceScanPackageType.LanguagePackage]: "Library",
                            [Contract.WorkloadResourceScanPackageType.OperatingSystem]: "Operating System",
                            [Contract.WorkloadResourceScanPackageType.OperatingSystemPackage]: "Package"
                        }
                    },
                    resolutionTime: "Resolved",
                    resolutionVersions: "Fixed By",
                    severity: "Severity",
                    tenantIds: "Accounts",
                    vprScore: "VPR Score",
                    vprSeverity: "VPR Severity",
                    vulnerabilityRawId: "ID",
                    workloadResourceIds: "Scanned Resources",
                    workloadResourceTypeName: "Scanned Resources Type"
                },
                empty: {
                    packages: {
                        withFilter: "No Matching Software",
                        withoutFilter: "No Software"
                    },
                    vulnerabilities: {
                        withFilter: "No Matching Vulnerabilities",
                        withoutFilter: "No Vulnerabilities"
                    }
                },
                title: "Vulnerabilities"
            }));
    useLayoutOptions({
        view:
            _.isNil(entityIds)
                ? { title: localization.title() }
                : undefined
    });

    const getFilters =
        (filterMap: Dictionary<any>,
            vulnerabilitiesView: VulnerabilitiesView) =>
            new Contract.WorkloadControllerPackageVulnerabilityModelsFilters(
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.TenantId]),
                _.isNil(entityIds)
                    ? ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceId])
                    : new Contract.PagedItemSelection<string>(
                        false,
                        entityIds,
                        Contract.PagedItemSelectionType.Include),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityCvssSeverity]),
                ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable]),
                TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityFirstScanTime]),
                filterMap[Contract.WorkloadControllerRequestProperty.FixableVulnerability] as boolean &&
                vulnerabilitiesView !== VulnerabilitiesView.Resolved,
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityName]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemType]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageDisplayName]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageFilePath]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageType]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityRawId]),
                TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityResolutionTime]),
                map(
                    vulnerabilitiesView,
                    {
                        [VulnerabilitiesView.Open]: () => false,
                        [VulnerabilitiesView.Resolved]: () => true
                    },
                    () => false),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]),
                filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadExists] as boolean,
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityVprSeverity]));


    const getVulnerabilityModelMap =
        async (packageVulnerabilityModels: Contract.WorkloadAnalysisPackageVulnerabilityModel[]): Promise<Dictionary<Contract.VulnerabilityModel>> => {
            const vulnerabilityModels =
                await vulnerabilityModelStore.get(
                    _.map(
                        packageVulnerabilityModels,
                        vulnerabilityModel => vulnerabilityModel.vulnerabilityRawId));
            return _.keyBy(
                vulnerabilityModels,
                vulnerabilityModel => vulnerabilityModel.vulnerability.rawId);
        };

    const [vulnerabilitiesView, setVulnerabilitiesView] = useState<VulnerabilitiesView>(VulnerabilitiesView.Open);

    const vulnerabilityModelMapRef = useRef<Dictionary<Contract.VulnerabilityModel>>({});

    const fetchPackageVulnerabilityModelNextPageAggregationCursorRef = useRef<Contract.ElasticsearchIndexAggregationCursor>();

    async function getPackageVulnerabilityModelPageRequest(filterMap: Dictionary<any>, limit: number, skip: number) {
        const nextPageAggregationCursor =
            skip === 0
                ? undefined
                : fetchPackageVulnerabilityModelNextPageAggregationCursorRef.current;

        return new Contract.WorkloadControllerGetPackageVulnerabilityModelPageRequest(
            getFilters(
                filterMap,
                vulnerabilitiesView),
            limit,
            nextPageAggregationCursor);
    }


    const fetchPackageVulnerabilityModels =
        async (filterMap: Dictionary<any>, _sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const packageVulnerabilityModelPageRequest =
                await getPackageVulnerabilityModelPageRequest(
                    filterMap,
                    limit,
                    skip);

            const getPackageVulnerabilityModelCount =
                async () => {
                    const { packageVulnerabilityModelCount } = await WorkloadController.getPackageVulnerabilityModelCount(packageVulnerabilityModelPageRequest);
                    return { count: packageVulnerabilityModelCount };
                };

            const { packageVulnerabilityModelPage } = await WorkloadController.getPackageVulnerabilityModelPage(packageVulnerabilityModelPageRequest);

            const vulnerabilityModelMap = await getVulnerabilityModelMap(packageVulnerabilityModelPage.items);
            return new DataTableFetchItemsResult(
                getPackageVulnerabilityModelCount,
                packageVulnerabilityModelPage.items,
                _.isNil(packageVulnerabilityModelPage.itemNextPageSearchCursor),
                {
                    onAppendData() {
                        fetchPackageVulnerabilityModelNextPageAggregationCursorRef.current = packageVulnerabilityModelPage.itemNextPageSearchCursor;
                        vulnerabilityModelMapRef.current =
                            skip === 0
                                ? vulnerabilityModelMap
                                : _.merge(
                                    vulnerabilityModelMapRef.current,
                                    vulnerabilityModelMap);
                    }
                });
        };

    const filterMapRef =
        useRef<Dictionary<any>>(
            variant === "risk"
                ? {
                    [Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]:
                        new ValuesFilterSelection(
                            false,
                            severityFilterValues ?? [])
                }
                : {});

    const [vulnerabilityCount, setVulnerabilityCount] = useState<number>();

    const dataTableActionsRef = useRef<DataTableActions>();


    const fetchPropertyItems =
        async (item: Contract.WorkloadAnalysisPackageVulnerabilityModel, property: Contract.WorkloadControllerRequestProperty) => {
            const { items } =
                await WorkloadController.getPackageVulnerabilityModelPropertyItems(
                    new Contract.WorkloadControllerPackageVulnerabilityModelPropertyItemsRequest(
                        getFilters(
                            filterMapRef.current,
                            vulnerabilitiesView),
                        property,
                        item.sortIdentifier)) as Contract.WorkloadControllerPackageVulnerabilityModelPropertyItemsResponse<any>;
            return items;
        };

    const [filtersPromise] =
        useOperation(
            Vulnerabilities,
            useCallback(
                async () => {
                    const { filters } = await WorkloadController.getWorkloadResourceScanPackageFilters(new Contract.WorkloadControllerGetWorkloadPackageScanFiltersRequest(undefined));
                    return filters;
                },
                []));

    const getUnsupportedOperatingSystemMessage = useGetUnsupportedOperatingSystemMessage();
    const infoMessage =
        variant === "risk" || _.isEmpty(entityIds)
            ? undefined
            : getUnsupportedOperatingSystemMessage(_.first(entityIds)!);

    const getWorkloadResourceScanPackageEntityFilterIdPage =
        useGetWorkloadResourceScanPackageEntityFilterIdPage(
            Contract.WorkloadControllerRequestProperty.WorkloadResourceId,
            undefined,
            true);

    const vulnerabilityCvssScoreDataTableColumn =
        <DataTableColumn
            id={Contract.WorkloadControllerRequestProperty.VulnerabilityCvssScore}
            itemProperty={
                (packageVulnerabilityModel: Contract.WorkloadAnalysisPackageVulnerabilityModel) =>
                    _.isNil(packageVulnerabilityModel.cvssScore) || packageVulnerabilityModel.cvssScore === 0
                        ? "-"
                        : packageVulnerabilityModel.cvssScore}
            key={Contract.WorkloadControllerRequestProperty.VulnerabilityCvssScore}
            title={localization.columns.cvssScore()}/>;
    const vulnerabilityCvssSeverityDataTableColumn =
        <DataTableColumn
            filterOptions={{
                itemOrItems: {
                    element:
                        <SeverityFilter
                            information={false}
                            placeholder={localization.columns.cvssSeverity()}/>
                }
            }}
            id={Contract.WorkloadControllerRequestProperty.VulnerabilityCvssSeverity}
            key={Contract.WorkloadControllerRequestProperty.VulnerabilityCvssSeverity}
            render={
                ({ item: packageVulnerabilityModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageVulnerabilityModel>) =>
                    <Severity severity={packageVulnerabilityModel.cvssSeverity}/>}
            selectorOptions={{ default: false }}
            title={localization.columns.cvssSeverity()}/>;
    const vulnerabilityVprScoreDataTableColumn =
        <DataTableColumn
            id={Contract.WorkloadControllerRequestProperty.VulnerabilityVprScore}
            itemProperty={
                (packageVulnerabilityModel: Contract.WorkloadAnalysisPackageVulnerabilityModel) =>
                    _.isNil(packageVulnerabilityModel.vprScore)
                        ? "-"
                        : packageVulnerabilityModel.vprScore}
            key={Contract.WorkloadControllerRequestProperty.VulnerabilityVprScore}
            title={localization.columns.vprScore()}/>;
    const vulnerabilityVprSeverityDataTableColumn =
        <DataTableColumn
            filterOptions={{
                itemOrItems: {
                    element:
                        <SeverityFilter
                            information={false}
                            placeholder={localization.columns.vprSeverity()}/>
                }
            }}
            id={Contract.WorkloadControllerRequestProperty.VulnerabilityVprSeverity}
            key={Contract.WorkloadControllerRequestProperty.VulnerabilityVprSeverity}
            render={
                ({ item: packageVulnerabilityModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageVulnerabilityModel>) =>
                    <Severity severity={packageVulnerabilityModel.vprSeverity}/>}
            selectorOptions={{ default: false }}
            title={localization.columns.vprSeverity()}/>;

    const vulnerabilityScoreType = workloadAnalysisVulnerabilityScoreTypeStore.useGet();

    const theme = useTheme();
    return (
        <Box
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <VerticalFillGrid>
                {!_.isNil(infoMessage) &&
                    <Typography
                        sx={{
                            borderBottom: theme.border.primary,
                            padding: theme.spacing(2)
                        }}>
                        {infoMessage}
                    </Typography>}
                <DataTable
                    actionsRef={dataTableActionsRef}
                    columnOptions={{
                        orderOptions: {
                            enabled: true,
                            persistenceStorageItem: StorageHelper.customerWorkloadAnalysisVulnerabilitiesTableColumnOrder
                        },
                        resizable: true,
                        selectorOptions: {
                            enabled: true,
                            persistenceStorageItem: StorageHelper.customerWorkloadAnalysisVulnerabilitiesTableColumnSelector
                        },
                        stickyColumnId: Contract.WorkloadControllerRequestProperty.VulnerabilityRawId
                    }}
                    emptyMessageOptions={{
                        emptyMessageText:
                            new EmptyMessageText(
                                localization.empty.vulnerabilities.withoutFilter(),
                                localization.empty.vulnerabilities.withFilter())
                    }}
                    fetchItems={fetchPackageVulnerabilityModels}
                    filtersOptions={{
                        initial: {
                            state: filterMapRef.current
                        },
                        onChanged: filterMap => filterMapRef.current = filterMap,
                        persist: {
                            visibilityStorageItem: StorageHelper.customerWorkloadAnalysisVulnerabilitiesTableFilters
                        }
                    }}
                    getItemId={packageVulnerabilityModel => packageVulnerabilityModel.id}
                    rowOptions={{
                        getUrl: item => CustomerConsoleAppUrlHelper.getVulnerabilityHashUrl(item.vulnerabilityRawId)
                    }}
                    sortOptions={{ enabled: false }}
                    variant={
                        variant === "risk"
                            ? "card"
                            : undefined}
                    virtualizationEnabled={true}
                    onItemCountChanged={vulnerabilityCount => setVulnerabilityCount(vulnerabilityCount)}>
                    {_<ReactNode>([]).
                        concat(
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <PagedValuesFilter
                                                getValuePage={
                                                    useGetWorkloadResourceScanPackageValueFilterItemPage(
                                                        Contract.WorkloadControllerRequestProperty.VulnerabilityRawId,
                                                        entityIds,
                                                        undefined,
                                                        vulnerabilitiesView === VulnerabilitiesView.Resolved,
                                                        true,
                                                        false)}
                                                placeholder={localization.columns.vulnerabilityRawId()}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.VulnerabilityRawId}
                                key={Contract.WorkloadControllerRequestProperty.VulnerabilityRawId}
                                render={
                                    ({ item: packageVulnerabilityModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageVulnerabilityModel>) =>
                                        <InlineVulnerability rawId={packageVulnerabilityModel.vulnerabilityRawId}/>}
                                selectorOptions={{ disabled: true }}
                                title={localization.columns.vulnerabilityRawId()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <PagedValuesFilter
                                                getValuePage={
                                                    useGetWorkloadResourceScanPackageValueFilterItemPage(
                                                        Contract.WorkloadControllerRequestProperty.VulnerabilityName,
                                                        entityIds,
                                                        undefined,
                                                        vulnerabilitiesView === VulnerabilitiesView.Resolved,
                                                        true)}
                                                placeholder={localization.columns.name()}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.VulnerabilityName}
                                key={Contract.WorkloadControllerRequestProperty.VulnerabilityName}
                                render={
                                    optionalTableCell<Contract.WorkloadAnalysisPackageVulnerabilityModel>(
                                        packageVulnerabilityModel =>
                                            _.isNil(packageVulnerabilityModel.name)
                                                ? undefined
                                                : <Typography noWrap={true}>
                                                    {packageVulnerabilityModel.name}
                                                </Typography>)}
                                selectorOptions={{ default: false }}
                                title={localization.columns.name()}/>,
                            <DataTableColumn
                                id={Contract.WorkloadControllerRequestProperty.VulnerabilityDescription}
                                key={Contract.WorkloadControllerRequestProperty.VulnerabilityDescription}
                                render={
                                    optionalTableCell<Contract.WorkloadAnalysisPackageVulnerabilityModel>(
                                        packageVulnerabilityModel =>
                                            vulnerabilityModelMapRef.current[packageVulnerabilityModel.vulnerabilityRawId]?.vulnerability.description)}
                                selectorOptions={{ default: false }}
                                title={localization.columns.description()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <SeverityFilter placeholder={localization.columns.severity()}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity}
                                key={Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity}
                                render={
                                    ({ item: packageVulnerabilityModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageVulnerabilityModel>) =>
                                        <Severity severity={packageVulnerabilityModel.severity}/>}
                                title={localization.columns.severity()}/>).
                        concat(
                            vulnerabilityScoreType === Contract.VulnerabilityScoreType.Cvss
                                ? [
                                    vulnerabilityCvssScoreDataTableColumn,
                                    vulnerabilityCvssSeverityDataTableColumn,
                                    vulnerabilityVprScoreDataTableColumn,
                                    vulnerabilityVprSeverityDataTableColumn
                                ]
                                : [
                                    vulnerabilityVprScoreDataTableColumn,
                                    vulnerabilityVprSeverityDataTableColumn,
                                    vulnerabilityCvssScoreDataTableColumn,
                                    vulnerabilityCvssSeverityDataTableColumn
                                ]).
                        concat(
                            <DataTableColumn
                                id={Contract.WorkloadControllerRequestProperty.VulnerabilityEpssScore}
                                itemProperty={
                                    (packageVulnerabilityModel: Contract.WorkloadAnalysisPackageVulnerabilityModel) =>
                                        _.isNil(packageVulnerabilityModel.epssScore)
                                            ? "-"
                                            : epssScoreTranslator(packageVulnerabilityModel.epssScore)}
                                key={Contract.WorkloadControllerRequestProperty.VulnerabilityEpssScore}
                                selectorOptions={{ default: false }}
                                title={localization.columns.epssScore()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <EnumValuesFilter
                                                emptyValueOptions={{ enabled: true }}
                                                enumType={Contract.VulnerabilityAttackVector}
                                                enumTypeTranslator={attackVectorTranslator}
                                                placeholder={localization.columns.attackVector.filterPlaceholder()}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector}
                                key={Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector}
                                render={
                                    optionalTableCell<Contract.WorkloadAnalysisPackageVulnerabilityModel>(
                                        packageVulnerabilityModel =>
                                            _.isNil(packageVulnerabilityModel.attackVector)
                                                ? undefined
                                                : attackVectorTranslator(packageVulnerabilityModel.attackVector))}
                                title={localization.columns.attackVector.title()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element: <ExploitableValueFilter
                                            localization={localization.columns.exploitable}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable}
                                itemProperty={
                                    (packageVulnerabilityModel: Contract.WorkloadAnalysisPackageVulnerabilityModel) =>
                                        packageVulnerabilityModel.exploitable
                                            ? localization.columns.exploitable.true()
                                            : localization.columns.exploitable.false()}
                                key={Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable}
                                title={localization.columns.exploitable.title()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <EnumValuesFilter
                                                enumType={Contract.OperatingSystemType}
                                                enumTypeTranslator={operatingSystemTypeTranslator}
                                                placeholder={localization.columns.operatingSystemType()}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.OperatingSystemType}
                                itemProperty={(packageVulnerabilityModel: Contract.WorkloadAnalysisPackageVulnerabilityModel) => operatingSystemTypeTranslator(packageVulnerabilityModel.operatingSystemType)}
                                key={Contract.WorkloadControllerRequestProperty.OperatingSystemType}
                                title={localization.columns.operatingSystemType()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <PagedValuesFilter
                                                getValuePage={
                                                    useGetWorkloadResourceScanPackageValueFilterItemPage(
                                                        Contract.WorkloadControllerRequestProperty.PackageDisplayName,
                                                        entityIds,
                                                        undefined,
                                                        vulnerabilitiesView === VulnerabilitiesView.Resolved,
                                                        true)}
                                                placeholder={localization.columns.packageDisplayNames()}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.PackageDisplayName}
                                key={Contract.WorkloadControllerRequestProperty.PackageDisplayName}
                                render={
                                    ({ item: packageVulnerabilityModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageVulnerabilityModel>) =>
                                        <InlineItems
                                            items={packageVulnerabilityModel.packageDisplayNames}
                                            itemsPopover={
                                                items =>
                                                    <SearchList
                                                        emptyMessageText={new EmptyMessageText(localization.empty.packages.withFilter())}
                                                        itemOptions={{
                                                            render:
                                                                (packageDisplayName: string) =>
                                                                    <Typography noWrap={true}>
                                                                        {packageDisplayName}
                                                                    </Typography>,
                                                            spacing: 1
                                                        }}
                                                        items={items}
                                                        sx={{ padding: theme.spacing(0, 1.5) }}
                                                        variant="dropdown"/>}
                                            variant="itemPlusItemCount">
                                            {packageDisplayName =>
                                                <Typography noWrap={true}>
                                                    {packageDisplayName}
                                                </Typography>}
                                        </InlineItems>}
                                title={localization.columns.packageDisplayNames()}/>).
                        concatIf(
                            vulnerabilitiesView === VulnerabilitiesView.Open,
                            () =>
                                <DataTableColumn
                                    id={Contract.WorkloadControllerRequestProperty.ResolutionVersions}
                                    key={Contract.WorkloadControllerRequestProperty.ResolutionVersions}
                                    render={
                                        optionalTableCell<Contract.WorkloadAnalysisPackageVulnerabilityModel>(
                                            packageVulnerabilityModel =>
                                                _.isEmpty(packageVulnerabilityModel.resolutionVersions)
                                                    ? undefined
                                                    : <Typography noWrap={true}>
                                                        <InlineItems
                                                            items={packageVulnerabilityModel.resolutionVersions}
                                                            variant="itemPlusItemCount">
                                                            {(resolutionVersion: string) =>
                                                                <VulnerabilityResolutionVersion resolutionVersion={resolutionVersion}/>}
                                                        </InlineItems>
                                                    </Typography>)}
                                    title={localization.columns.resolutionVersions()}/>).
                        concat(
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <PagedValuesFilter
                                                getValuePage={
                                                    useGetWorkloadResourceScanPackageValueFilterItemPage(
                                                        Contract.WorkloadControllerRequestProperty.PackageFilePath,
                                                        entityIds,
                                                        undefined,
                                                        vulnerabilitiesView === VulnerabilitiesView.Resolved,
                                                        true)}
                                                placeholder={localization.columns.packageFilePath()}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.PackageFilePath}
                                key={Contract.WorkloadControllerRequestProperty.PackageFilePath}
                                render={
                                    ({ item: packageVulnerabilityModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageVulnerabilityModel>) =>
                                        <InlineItemsCell
                                            emptyMessageText={
                                                new EmptyMessageText(
                                                    localization.empty.packages.withoutFilter(),
                                                    localization.empty.packages.withFilter())}
                                            getItemDisplayName={path => path}
                                            getPopoverItems={
                                                async () =>
                                                    await fetchPropertyItems(
                                                        packageVulnerabilityModel,
                                                        Contract.WorkloadControllerRequestProperty.PackageFilePath)}
                                            itemCount={packageVulnerabilityModel.packageFilePathCount}
                                            items={packageVulnerabilityModel.packageTopFilePaths}
                                            renderItem={path => <Typography noWrap={true}>{path}</Typography>}/>}
                                title={localization.columns.packageFilePath()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <EnumValuesFilter
                                                enumType={Contract.WorkloadResourceScanPackageType}
                                                enumTypeTranslator={(packageType: Contract.WorkloadResourceScanPackageType) => localization.columns.packageType[Contract.TypeNames.WorkloadResourceScanPackageType][packageType]()}
                                                placeholder={localization.columns.packageType.title()}/>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.PackageType}
                                key={Contract.WorkloadControllerRequestProperty.PackageType}
                                render={optionalTableCell<Contract.WorkloadAnalysisPackageVulnerabilityModel>((packageVulnerabilityModel: Contract.WorkloadAnalysisPackageVulnerabilityModel) => localization.columns.packageType[Contract.TypeNames.WorkloadResourceScanPackageType][packageVulnerabilityModel.packageType]())}
                                title={localization.columns.packageType.title()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={filtersPromise}
                                                title={localization.columns.firstScanTime()}>
                                                {filters =>
                                                    <TimeRangeFilter
                                                        emptyValue={filters.firstScanTimeRange.emptyValue}
                                                        placeholder={localization.columns.firstScanTime()}
                                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.firstScanTimeRange.timeRange)}/>}
                                            </DeferredFilter>
                                    }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.VulnerabilityFirstScanTime}
                                key={Contract.WorkloadControllerRequestProperty.VulnerabilityFirstScanTime}
                                render={optionalTableCell<Contract.WorkloadAnalysisPackageVulnerabilityModel>(packageVulnerabilityModel => TimeFormatter.workloadResourceDateTime(packageVulnerabilityModel.firstScanTime))}
                                title={localization.columns.firstScanTime()}/>).
                        concatIf(
                            vulnerabilitiesView === VulnerabilitiesView.Resolved,
                            () =>
                                <DataTableColumn
                                    filterOptions={{
                                        itemOrItems: {
                                            element:
                                                <DeferredFilter
                                                    promiseOrGetPromise={filtersPromise}
                                                    title={localization.columns.resolutionTime()}>
                                                    {filters =>
                                                        <TimeRangeFilter
                                                            emptyValue={filters.resolutionTimeRange.emptyValue}
                                                            placeholder={localization.columns.resolutionTime()}
                                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.resolutionTimeRange.timeRange)}/>}
                                                </DeferredFilter>
                                        }
                                    }}
                                    id={Contract.WorkloadControllerRequestProperty.VulnerabilityResolutionTime}
                                    key={Contract.WorkloadControllerRequestProperty.VulnerabilityResolutionTime}
                                    render={optionalTableCell<Contract.WorkloadAnalysisPackageVulnerabilityModel>(packageVulnerabilityModel => TimeFormatter.workloadResourceDateTime(packageVulnerabilityModel.resolutionTime))}
                                    title={localization.columns.resolutionTime()}/>).
                        concatIf(
                            _.isNil(entityIds),
                            () =>
                                <DataTableColumn
                                    filterOptions={{
                                        itemOrItems: {
                                            default: true,
                                            element:
                                                <DeferredFilter
                                                    promiseOrGetPromise={filtersPromise}
                                                    title={localization.columns.tenantIds()}>
                                                    {filters =>
                                                        <ScopeTenantFilter
                                                            placeholder={localization.columns.tenantIds()}
                                                            tenantIds={filters.tenantIdItems.items}/>}
                                                </DeferredFilter>
                                        }
                                    }}
                                    id={Contract.WorkloadControllerRequestProperty.TenantId}
                                    key={Contract.WorkloadControllerRequestProperty.TenantId}
                                    render={
                                        ({ item: packageVulnerabilityModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageVulnerabilityModel>) =>
                                            <InlineItemsCell
                                                getPopoverItems={
                                                    async () =>
                                                        await fetchPropertyItems(
                                                            packageVulnerabilityModel,
                                                            Contract.WorkloadControllerRequestProperty.TenantId)}
                                                itemCount={packageVulnerabilityModel.tenantCount}
                                                items={packageVulnerabilityModel.tenantTopIds}
                                                renderItem={
                                                    tenantId =>
                                                        <Tenant
                                                            tenantId={tenantId}
                                                            variant="iconText"/>}/>}
                                    title={localization.columns.tenantIds()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: [
                                        {
                                            element: <ContainerImageUsageFilter/>,
                                            id: Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadExists,
                                            title: localization.columns.containerImageUsage()
                                        },
                                        {
                                            default: true,
                                            element:
                                                <PagedEntityFilter
                                                    getEntityIdPage={getWorkloadResourceScanPackageEntityFilterIdPage}
                                                    placeholder={localization.columns.workloadResourceIds()}/>,
                                            id: Contract.WorkloadControllerRequestProperty.WorkloadResourceId,
                                            title: localization.columns.workloadResourceIds()
                                        },
                                        {
                                            element:
                                                <DeferredFilter
                                                    promiseOrGetPromise={filtersPromise}
                                                    title={localization.columns.workloadResourceTypeName()}>
                                                    {filters =>
                                                        <EntityTypeFilter
                                                            emptyValue={filters.workloadResourceTypeNameItems.emptyValue}
                                                            entityTypeNames={filters.workloadResourceTypeNameItems.items}
                                                            groupItemTitle={false}
                                                            placeholder={localization.columns.workloadResourceTypeName()}/>}
                                                </DeferredFilter>,
                                            id: Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName,
                                            title: localization.columns.workloadResourceTypeName()
                                        }
                                    ]
                                }}
                                id={Contract.WorkloadControllerRequestProperty.WorkloadResourceId}
                                key={Contract.WorkloadControllerRequestProperty.WorkloadResourceId}
                                render={
                                    ({ item: packageVulnerabilityModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageVulnerabilityModel>) =>
                                        <EntitiesCell
                                            deferredEntities={{
                                                count: packageVulnerabilityModel.workloadResourceCount,
                                                firstItem: _.first(packageVulnerabilityModel.workloadResourceTopIds),
                                                getItems:
                                                    async () =>
                                                        await fetchPropertyItems(
                                                            packageVulnerabilityModel,
                                                            Contract.WorkloadControllerRequestProperty.WorkloadResourceId)
                                            }}
                                            entityTypeName={Contract.TypeNames.Entity}
                                            entityVariant="iconTextTypeTenant"/>}
                                title={localization.columns.workloadResourceIds()}/>).
                        concat(
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems:
                                        vulnerabilitiesView === VulnerabilitiesView.Resolved
                                            ? undefined
                                            : {
                                                default: true,
                                                element:
                                                    <ValueFilter
                                                        items={[
                                                            {
                                                                title: localization.columns.fixableVulnerability(),
                                                                value: true
                                                            }
                                                        ]}
                                                        placeholder={localization.columns.fixableVulnerability()}/>
                                            }
                                }}
                                id={Contract.WorkloadControllerRequestProperty.FixableVulnerability}
                                key={Contract.WorkloadControllerRequestProperty.FixableVulnerability}
                                title={localization.columns.fixableVulnerability()}/>).
                        concatIf(
                            variant === "entity" &&
                            !!entityModel &&
                            !TypeHelper.extendOrImplement(
                                entityModel.typeName,
                                Contract.TypeNames.IContainerImageModel),
                            () =>
                                <DataTableAction>
                                    <Tabs
                                        baseUrl={
                                            CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(
                                                _.as<Contract.EntityModel>(entityModel),
                                                {
                                                    category: ProfileCategory.WorkloadAnalysis,
                                                    view: ProfileView.Vulnerabilities
                                                })!}
                                        selectedView={vulnerabilitiesView}
                                        onChange={
                                            newVulnerabilitiesView => {
                                                if (vulnerabilitiesView !== newVulnerabilitiesView) {
                                                    dataTableActionsRef.current!.reset(
                                                        {
                                                            clearFilterAndSort: true,
                                                            refreshFilters: true
                                                        });
                                                    filterMapRef.current = {};
                                                    setVulnerabilitiesView(newVulnerabilitiesView);
                                                }
                                            }}/>
                                </DataTableAction>).
                        concat(
                            <DataTableAction>
                                <CsvExportButton
                                    fileNameOptions={{
                                        filtered: !_.isEmpty(filterMapRef.current),
                                        prefix: localization.title()
                                    }}
                                    itemCount={vulnerabilityCount}
                                    showLimitMessage={false}
                                    onClick={
                                        async fileNameOptions => {
                                            await ExportReportHelper.downloadRemote(
                                                new Contract.ReportControllerWorkloadAnalysisPackageVulnerabilitiesReportRequestDefinition(
                                                    getExportFileName(fileNameOptions),
                                                    TimeHelper.timeZoneId(),
                                                    Contract.TypeNames.ReportControllerWorkloadAnalysisPackageVulnerabilitiesReportRequestDefinition,
                                                    _.isNil(filterMapRef.current),
                                                    getFilters(
                                                        filterMapRef.current ?? {},
                                                        vulnerabilitiesView)));
                                        }}/>
                            </DataTableAction>).
                        value()}
                </DataTable>
            </VerticalFillGrid>
        </Box>);
}