import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../../../../../../../common";

export function useSlackWorkspaceIssueTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCollaborationItems.slack.list.workspace.hooks.useSlackWorkspaceIssueTranslator",
            () => ({
                valid: "Connected",
                [Contract.TypeNames.SlackWorkspaceStateIssue]: {
                    [Contract.SlackWorkspaceStateIssue.AuthenticationFailure]: "Disconnected",
                    [Contract.SlackWorkspaceStateIssue.MandatoryPermissionsNotExist]: "Missing permissions",
                    [Contract.SlackWorkspaceStateIssue.OptionalPermissionsNotExist]: "Connected with Issues"
                }
            }));
    return (workspaceIssue?: Contract.SlackWorkspaceStateIssue) =>
        _.isNil(workspaceIssue)
            ? localization.valid()
            : localization[Contract.TypeNames.SlackWorkspaceStateIssue][workspaceIssue]();
}