import { useLocalization } from "@infrastructure";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../../../../common";
import { DataClassifiers, Properties } from "./components";

type DataAnalysisResourceOverviewProps = {
    dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel;
};

export function DataAnalysisResourceOverview({ dataAnalysisResourceModel }: DataAnalysisResourceOverviewProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.dataAnalysisResourceOverview.dataAnalysisResourceOverview",
            () => ({
                dataTypes: "Data Types"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ paddingBottom: theme.spacing(2) }}>
            <Properties dataAnalysisResourceModel={dataAnalysisResourceModel}/>
            <Divider/>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        paddingRight: theme.spacing(0.5)
                    }}
                    variant="h4">
                    {localization.dataTypes()}
                </Typography>
                <DataClassifiers dataAnalysisResourceModel={dataAnalysisResourceModel}/>
            </Stack>
        </Stack>);
}