import { Dictionary } from "lodash";
import { Contract } from "../../../common";

export class AzureConsoleHelper {
    private static _partitionTypeToDomainNameMap: Dictionary<string>;
    private static _partitionTypeToMachineLearningDomainNameMap: Dictionary<string>;

    public static initialize(
        partitionTypeToDomainNameMap: Dictionary<string>,
        partitionTypeToMachineLearningDomainNameMap: Dictionary<string>) {
        AzureConsoleHelper._partitionTypeToDomainNameMap = partitionTypeToDomainNameMap;
        AzureConsoleHelper._partitionTypeToMachineLearningDomainNameMap = partitionTypeToMachineLearningDomainNameMap;
    }

    public static getDomainName(partitionType: Contract.AadPartitionType) {
        return AzureConsoleHelper._partitionTypeToDomainNameMap[partitionType];
    }

    public static getMachineLearningDomainName(partitionType: Contract.AadPartitionType) {
        return AzureConsoleHelper._partitionTypeToMachineLearningDomainNameMap[partitionType];
    }
}