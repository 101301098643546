import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciNetworkingNatGatewayStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useOciNetworkingNatGatewayDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const natGatewayModel = resourceModel as Contract.OciNetworkingNatGatewayModel;
    const natGateway = natGatewayModel.entity as Contract.OciNetworkingNatGateway;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const natGatewayStatusTranslator = useOciNetworkingNatGatewayStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingNatGatewayDefinition",
            () => ({
                info: {
                    items: {
                        blockTraffic: {
                            false: "Disabled",
                            title: "Block Traffic",
                            true: "Enabled"
                        },
                        publicIpAddress: "Public IP Address",
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={natGatewayStatusTranslator(natGateway.status)}/>,
                    <InfoItem
                        key="blockTraffic"
                        title={localization.info.items.blockTraffic.title()}
                        value={
                            natGateway.blockTraffic
                                ? localization.info.items.blockTraffic.true()
                                : localization.info.items.blockTraffic.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={natGatewayModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
                    <InfoItem
                        key="publicIpAddress"
                        location="all"
                        title={localization.info.items.publicIpAddress()}
                        value={natGateway.publicIpAddress}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={natGatewayModel.routeTableIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingRouteTable}
                        key="routeTable"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingRouteTable)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}