import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOciCommonEntityInfoItemElements } from ".";
import { Contract, entityModelStore, InfoItem, ResourceTagHelper, scopeSystemEntityModelStore, TenantHelper, tenantModelStore } from "../../../../../../../../../../common";
import { EntitiesInfoItem, TagsInfoItem } from "../../../../components";

export function useOciCommonResourceInfoItemElements(resourceModel: Contract.OciResourceModel) {
    const resource = resourceModel.entity as Contract.OciResource;
    const commonEntityInfoItemElements = useOciCommonEntityInfoItemElements(resourceModel);
    const creatorIdentityModel = entityModelStore.useGet(resourceModel.creatorIdentityIdReference);
    const tenantModel =
        tenantModelStore.useGet(
            TenantHelper.isCommonTenant(resource.tenantId)
                ? undefined
                : resource.tenantId);
    const organizationModel = scopeSystemEntityModelStore.useGet((tenantModel?.configuration as Contract.OciTenantConfiguration)?.organizationId);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciCommonResourceInfoItemElements",
            () => ({
                creationTime: "Creation Time",
                creatorIdentity: "Created By",
                ocid: "OCID",
                organizationId: "Tenancy",
                regionSystemName: "Region",
                tags: "Tags"
            }));

    return {
        ...commonEntityInfoItemElements,
        createdByInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={
                    _.isNil(creatorIdentityModel)
                        ? undefined
                        : creatorIdentityModel}
                entityTypeName={
                    _.isNil(creatorIdentityModel)
                        ? Contract.TypeNames.OciResource
                        : creatorIdentityModel.typeName}
                key="creatorIdentityModel"
                title={localization.creatorIdentity()}/>,
        creationTimeInfoItemElement:
            <InfoItem
                key="creationTime"
                location="all"
                title={localization.creationTime()}
                value={
                    _.isNil(resourceModel.creationTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(resourceModel.creationTime)}/>,
        ocidInfoItemElement:
            <InfoItem
                key="ocid"
                location="all"
                title={localization.ocid()}
                value={resource.ocid}/>,
        organizationInfoItemElement:
            <InfoItem
                key="organizationId"
                location="all"
                title={localization.organizationId()}
                value={
                    _.isNil(organizationModel)
                        ? undefined
                        : (organizationModel.configuration as Contract.OciOrganizationConfiguration).name}/>,
        tagsInfoItemElement:
            <TagsInfoItem
                key="tags"
                location="all"
                resourceTags={ResourceTagHelper.getDatas(resource.tags)}
                title={localization.tags()}/>
    };
}