import { InlineItems, Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsResourceRiskContext, useResourceSecretExistsRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { ResourceTagSecretExistsRiskTagsSection } from "../../../../components";

export function useAwsResourceTagSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsResourceTagSecretExistsRisk;
    const resourceTagSecretExistsRiskModel = riskModel as Contract.AwsResourceTagSecretExistsRiskModel;
    const resourceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsResourceModel;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

    const getAwsResourceRiskContext = useGetAwsResourceRiskContext();
    const secretRiskContext = useResourceSecretExistsRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsResourceTagSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    secretTags: "The {{translatedResourceTypeName}} has {{tags}} exposing secrets in clear text",
                    tags: [
                        "1 tag",
                        "{{count | NumberFormatter.humanize}} tags"
                    ]
                },
                description: "{{resource}} is exposing secrets via tags in clear text",
                sections: {
                    resolution: {
                        step1: "Fill the **Region** and **Resource type** and click on **Search resources**",
                        step2: "Under **Resource search results** filter for the {{resource}} resource",
                        step3: "Check {{resource}} in the search results and click **Manage tags of selected resources**",
                        step4: {
                            link: "Secrets Manager",
                            text: "Migrate your secrets to {{secretsManagerGuideLink}}"
                        },
                        step5: "Before removing the tags, update identities to use the new secrets from Secrets Manager",
                        step6: "Click **Remove tag** for all tags that need to be deleted",
                        step7: "Click **Review and apply tag changes** and then **Apply changes to all selected** to confirm"
                    },
                    Tags: "Tags"
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            resource:
                <Entity
                    entityIdOrModel={resourceModel}
                    entityTypeNameTranslatorOptions={{ variant: "text" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ResourceGroups,
                AwsConsoleUrlBuilder.getResourceGroupsTagEditorUrl(tenantConfiguration.partitionType)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({
                resource:
                    <Entity
                        entityIdOrModel={resourceModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="text"/>
            }),
            localization.sections.resolution.step3({
                resource:
                    <Entity
                        entityIdOrModel={resourceModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="text"/>
            }),
            localization.sections.resolution.step4.text({
                secretsManagerGuideLink:
                    <Link
                        urlOrGetUrl={resourceTagSecretExistsRiskModel.secretsManagerDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step4.link()}
                    </Link>
            }),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7()
        ],
        riskModel,
        () => {
            const resourceRiskContext = getAwsResourceRiskContext(resourceModel);
            return [
                resourceRiskContext.generalInformation,
                resourceRiskContext.sensitive,
                new RiskDefinitionContextItem(
                    localization.contextItems.secretTags({
                        tags:
                            <InlineItems
                                items={risk.secretTagKeys}
                                namePluralizer={localization.contextItems.tags}
                                sx={{ color: theme.palette.text.primary }}
                                variant="itemCountAndType"/>,
                        translatedResourceTypeName:
                            entityTypeNameTranslator(
                                resourceModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                secretRiskContext.getExcludedSecretsContextItem(
                    risk.excludedSecretTagKeys,
                    risk.typeName,
                    risk.tenantId),
                resourceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ResourceTagSecretExistsRiskTagsSection
                        risk={risk}
                        secretTagKeys={risk.secretTagKeys}
                        tags={
                            resourceModel.unknown
                                ? []
                                : (resourceModel.entity as Contract.AwsResource).tags}/>,
                    localization.sections.Tags(),
                    {
                        expandable: true
                    })
            ]
        });
}