import _ from "lodash";

export class StringFormatter {
    public static initials(name: string) {
        const nameParts = _.filter(name.split(" "), namePart => !_.isEmpty(namePart));
        const initials =
            nameParts.length > 1
                ? `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`
                : nameParts.length === 1
                    ? nameParts[0][0]
                    : "";
        return initials.toUpperCase();
    }
}