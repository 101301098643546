import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeDiskStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeDiskStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeDiskStatus]: {
                    [Contract.GcpComputeDiskStatus.Creating]: "Creating",
                    [Contract.GcpComputeDiskStatus.Deleting]: "Deleting",
                    [Contract.GcpComputeDiskStatus.Failed]: "Failed",
                    [Contract.GcpComputeDiskStatus.Ready]: "Ready",
                    [Contract.GcpComputeDiskStatus.Restoring]: "Restoring",
                    [Contract.GcpComputeDiskStatus.Unavailable]: "Unavailable"
                }
            }));
    return (status: Contract.GcpComputeDiskStatus) =>
        localization[Contract.TypeNames.GcpComputeDiskStatus][status]();
}