import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonKubernetesNamespaceResourceInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { Info } from "../../../../components";
import { KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useAwsDefaultKubernetesNamespaceResourceDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonKubernetesResourceInfoItemElements = useAwsCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.AwsKubernetesNamespaceResourceBase;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsDefaultKubernetesNamespaceResourceDefinition",
            () => ({
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={resource.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesResourceInfoItemElements.namespaceInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(resource.data)
    });
}