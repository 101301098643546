import { useTheme } from "@mui/material";
import type { editor } from "monaco-editor/esm/vs/editor/editor.api";
import { useMemo } from "react";

export const themeName = "tenableEditor";

export function useMonacoTheme() {
    const theme = useTheme();
    const monacoTheme =
        useMemo(
            (): editor.IStandaloneThemeData => ({
                base:
                    theme.dark
                        ? "vs-dark"
                        : "vs",
                colors: {
                    "diffEditor.insertedLineBackground": theme.palette.textEditor.insertedLineBackground,
                    "diffEditor.insertedTextBackground": theme.palette.textEditor.insertedTextBackground,
                    "diffEditor.removedLineBackground": theme.palette.textEditor.removedLineBackground,
                    "editor.lineHighlightBackground": theme.palette.action.hover,
                    "editorLineNumber.foreground": theme.palette.text.primary
                },
                inherit: true,
                rules: []
            }),
            [theme]);

    return monacoTheme;
}