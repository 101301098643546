import { NumberFormatter, setUrlRoute, Shadows, useRoute } from "@infrastructure";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { usePrincipalsContext } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, PrincipalIcon, PrincipalRiskCategoryHelper, TypeHelper, usePrincipalRiskCategoryTranslator, usePrincipalTypeTranslator, useTheme } from "../../../../../../../../../common";
import { SummaryItem } from "../utilities";

export function ExcessivePermissionSummary() {
    const { getPrincipalSummaryResponse } = usePrincipalsContext();
    const items =
        useMemo(
            () => {
                const getPrincipalExcessivePermissionSummaryResponse = getPrincipalSummaryResponse as Contract.AccessControllerGetPrincipalExcessivePermissionSummaryResponse;
                return _(getPrincipalExcessivePermissionSummaryResponse.principalRiskCategoryToOpenRiskSeverityToCountMap).
                    map(
                        (principalOpenRiskSeverityToCountMap, principalRiskCategory) =>
                            new ExcessivePermissionSummaryItem(
                                principalRiskCategory as Contract.PrincipalRiskCategory,
                                PrincipalRiskCategoryHelper.getPrincipalType(principalRiskCategory as Contract.PrincipalRiskCategory),
                                principalOpenRiskSeverityToCountMap)).
                    orderBy(
                        [
                            ({ principalSeverity }) => TypeHelper.getEnumValue(Contract.TypeNames.Severity, principalSeverity),
                            ({ severityPrincipalCount }) => severityPrincipalCount,
                            ({ principalCount }) => principalCount
                        ],
                        [
                            "desc",
                            "desc",
                            "desc"
                        ]).
                    value();
            },
            [getPrincipalSummaryResponse]);

    const baseUrl = CustomerConsoleAppUrlHelper.getPrincipalExcessivePermissionsRelativeUrl();
    let { selectedPrincipalRiskCategory } = useRoute(`${baseUrl}/{selectedPrincipalRiskCategory}`);
    if (_.isNil(selectedPrincipalRiskCategory) ||
        _.isNil(
            _.find(
                items,
                item => item.principalRiskCategory === selectedPrincipalRiskCategory))) {
        selectedPrincipalRiskCategory = items[0].principalRiskCategory;
        setUrlRoute(
            CustomerConsoleAppUrlHelper.getPrincipalExcessivePermissionsRelativeUrl(selectedPrincipalRiskCategory as Contract.PrincipalRiskCategory),
            undefined,
            { appendBrowserHistory: false });
    }

    const theme = useTheme();
    return (
        <Stack direction="row">
            {_.map(
                items,
                item =>
                    <Box
                        key={item.principalRiskCategory}
                        sx={{
                            cursor: "pointer",
                            flex: 1,
                            margin:
                                item.principalRiskCategory === selectedPrincipalRiskCategory
                                    ? theme.spacing(0, 0.25)
                                    : theme.spacing(0.5),
                            transition: "margin 200ms ease 0s"
                        }}
                        onClick={() => setUrlRoute(CustomerConsoleAppUrlHelper.getPrincipalExcessivePermissionsRelativeUrl(item.principalRiskCategory))}>
                        <Item
                            item={item}
                            selected={item.principalRiskCategory === selectedPrincipalRiskCategory}/>
                    </Box>)}
        </Stack>);
}

type ItemProps = {
    item: ExcessivePermissionSummaryItem;
    selected: boolean;
};

function Item({ item, selected }: ItemProps) {
    const principalRiskCategoryTranslator = usePrincipalRiskCategoryTranslator();
    const principalTypeTranslator = usePrincipalTypeTranslator();
    const theme = useTheme();
    return (
        <Stack
            sx={{
                background:
                    selected
                        ? theme.palette.background.alternate
                        : undefined,
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                height: "100%",
                overflow: "hidden",
                position: "relative"
            }}>
            <Box
                sx={{
                    backgroundColor: theme.palette.severity(item.principalSeverity),
                    height: "5px"
                }}>
            </Box>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{
                    "&:hover": {
                        boxShadow: theme.shadows[Shadows.Highlight]
                    },
                    flex: 1,
                    padding: theme.spacing(1.5)
                }}>
                <Stack>
                    <Stack
                        alignItems="center"
                        direction="row"
                        sx={{ marginBottom: theme.spacing(0.5) }}>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                marginRight: theme.spacing(0.5)
                            }}
                            variant="h1">
                            {NumberFormatter.humanize(item.principalCount)}
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.severity(item.principalSeverity),
                                fontWeight: 700
                            }}>
                            ({NumberFormatter.humanize(item.severityPrincipalCount)})
                        </Typography>
                    </Stack>
                    <Typography
                        sx={{
                            fontSize: "13px",
                            fontWeight: 500,
                            textTransform: "uppercase"
                        }}>
                        {principalRiskCategoryTranslator(item.principalRiskCategory, "shortTitle")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {principalTypeTranslator(item.principalType)}
                    </Typography>
                </Stack>
                <Avatar
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: "24px",
                        height: "34px",
                        width: "34px"
                    }}>
                    <PrincipalIcon principalType={item.principalType}/>
                </Avatar>
            </Stack>
        </Stack>);
}

class ExcessivePermissionSummaryItem extends SummaryItem {
    constructor(
        public principalRiskCategory: Contract.PrincipalRiskCategory,
        public principalType: Contract.PrincipalType,
        principalOpenRiskSeverityToCountMap: Dictionary<number>) {
        super(principalOpenRiskSeverityToCountMap);
    }
}