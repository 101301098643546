import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function TerraformCloudRunTaskIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            sx={{ fontSize: "24px" }}
            {...props}>
            <path
                d="M8.75585 4.81678L15.1364 8.42151V15.5025L8.75585 11.8978V4.81678ZM15.475 15.4707L21.8 11.8767V4.8415L15.475 8.4108V15.4707ZM2 8.08097L8.38052 11.6857V4.60474L2 1V8.08097ZM8.75585 19.3953L15.1364 23V15.8943L8.75585 12.2896V19.3953Z"
                fill="#623CE4"/>
            <path
                d="M15.475 15.166L21.8 11.572V4.53676L15.475 8.10606V15.166Z"
                fill="#3C2AA8"/>
        </SvgIcon>);
}