import { Link, Steps, TextViewer, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../AddOrEdit";

export function SyncIacTaskItem() {
    const { apiRootUrl } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useAzurePipelines.syncIacTaskItem",
            () => ({
                description: {
                    link: "Azure Pipelines",
                    text: "To sync your Cloud to Code, and remediate cloud findings at source, add the following step to your {{azurePipelinesLink}} (adjust as needed):"
                },
                note: {
                    link: "product documentation",
                    text: "Once the sync is triggered, you should start seeing resources/findings tagged with the IaC label in Tenable Cloud Security. Relevant resources will have an IaC tab in the Inventory view, showing the relevant code snippet. Please refer to the {{docsLink}} for more details"
                },
                steps: {
                    step1: "Select the stage where you would like to add the task. **This task should be added within the 'terraform apply' stage, after the 'apply' command.**",
                    step2: "In the task assistant, search for and click the **Tenable Cloud Security IaC Sync Terraform** task.",
                    step3: {
                        step1: "**API Token: $(TENABLE_API_TOKEN)**",
                        step2: "**API URL: {{apiUrl}}**",
                        step3: {
                            link: "README file",
                            title: "Use any Advanced settings as needed (refer to the {{readmeFileLink}} for more details)."
                        },
                        title: "To add the task, type these values and then click **Add**:"
                    },
                    step4: {
                        command: "- script: terraform state pull > terraform.tfstate",
                        title: "Add the following before the Tenable task, and after the 'terraform apply' step:"
                    },
                    step5: "**Save** the updated pipeline YAML."
                }
            }));

    return (
        <Stack spacing={2}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.description.text({
                    azurePipelinesLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://learn.microsoft.com/en-us/azure/devops/pipelines/get-started/what-is-azure-pipelines?view=azure-devops"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1()}
                {localization.steps.step2()}
                <Stack>
                    <Typography>
                        {localization.steps.step3.title()}
                    </Typography>
                    <Steps variant="plainNumbers">
                        {localization.steps.step3.step1()}
                        {localization.steps.step3.step2({ apiUrl: apiRootUrl })}
                        {localization.steps.step3.step3.title({
                            readmeFileLink:
                                <Link
                                    sx={{ textDecorationLine: "underline" }}
                                    urlOrGetUrl="https://marketplace.visualstudio.com/items?itemName=Ermetic.ermetic"
                                    variant="external">
                                    {localization.steps.step3.step3.link()}
                                </Link>
                        })}
                    </Steps>
                </Stack>
                <Stack>
                    <Typography>
                        {localization.steps.step4.title()}
                    </Typography>
                    <TextViewer
                        copyToClipboard={true}
                        format="groovy"
                        text={localization.steps.step4.command()}/>
                </Stack>
                {localization.steps.step5()}
            </Steps>
            <Typography>
                {localization.note.text({
                    docsLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsConnectYourCICDPipelineAddAzurePipelinesRelativeUrl()}
                            variant="external">
                            {localization.note.link()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}