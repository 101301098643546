﻿import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../../common";
import { useAwsResourceAccessLevelTranslator } from "../../../../../../../../../../../../../../../../tenants";
import { useOpenRiskedEntityRisksStep } from "../../../../../../../useOpenRiskedEntityRisksStep";
import { useResourceGeneralInformationStep } from "../../../../../../../useResourceGeneralInformationStep";

export type LaunchTemplateContextSectionProps = {
    launchTemplateModel: Contract.AwsEc2LaunchTemplateModel;
    risk: Contract.AwsEc2SnapshotEncryptionDisabledRisk;
};

export function LaunchTemplateContextSection({ launchTemplateModel, risk }: LaunchTemplateContextSectionProps) {
    const riskData = risk.data as Contract.AwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateData;
    const instanceModels = entityModelStore.useGet(riskData.instanceIds);
    const volumeModels = entityModelStore.useGet(riskData.volumeIds);
    const snapshotModels = entityModelStore.useGet(risk.aggregatedEntityIds);

    const [accessLevelSnapshotModels, sensitiveSnapshotModels] =
        useMemo(
            () => {
                const accessLevelSnapshotModels =
                    _.filter(
                        snapshotModels,
                        snapshotModel => (snapshotModel as Contract.AwsEc2SnapshotModel).accessLevel === risk.accessLevel);
                const sensitiveSnapshotModels =
                    _.filter(
                        snapshotModels,
                        snapshotModel => snapshotModel.entityConfiguration?.sensitive === true);

                return [accessLevelSnapshotModels, sensitiveSnapshotModels];
            },
            [snapshotModels]);

    const sensitiveInstanceModels =
        useMemo(
            () =>
                _.filter(
                    instanceModels,
                    instanceModel => instanceModel.entityConfiguration?.sensitive === true),
            [instanceModels]);

    const [sensitiveVolumesModels, unencryptedVolumeModels] =
        useMemo(
            () => {
                const sensitiveVolumesModels =
                    _.filter(
                        volumeModels,
                        volumeModel => volumeModel.entityConfiguration?.sensitive === true);
                const unencryptedVolumeModels =
                    _.filter(
                        volumeModels,
                        volumeModel => _.isEmpty((volumeModel as Contract.AwsEc2VolumeModel).kmsEncryptionKeyIdReferences));

                return [sensitiveVolumesModels, unencryptedVolumeModels];
            },
            [volumeModels]);

    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(launchTemplateModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(launchTemplateModel);

    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotEncryptionDisabledRiskDefinition.hooks.useAwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateDefinition.launchTemplateContextSection",
            () => ({
                autoScalingGroups: "The launch template is part of {{autoScalingGroups}}",
                launchTemplate: {
                    title: {
                        none: "The launch template {{launchTemplate}} has unencrypted device mapping configuration",
                        snapshot: "The launch template {{launchTemplate}} was used to launch {{instances}} with {{volumes}}"
                    },
                    volumes: [
                        "1 unencrypted volume",
                        "{{count | NumberFormatter.humanize}} unencrypted volumes"
                    ]
                },
                networkAccess: {
                    all: "All the EBS snapshots have **{{accessLevel}}** access",
                    some: "{{accessLevelSnapshots}} out of {{snapshotIds}} have **{{accessLevel}}** access"
                },
                sensitive: [
                    "The {{resources}} is marked as sensitive",
                    "The {{resources}} are marked as sensitive"],
                snapshots: "The launch template has {{snapshots}} without KMS encryption"
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                _.isEmpty(riskData.autoScalingGroupIds)
                    ? undefined
                    : localization.autoScalingGroups({
                        autoScalingGroups:
                            <InlineEntities
                                entityIdsOrModels={riskData.autoScalingGroupIds}
                                entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                variant="itemCountAndType"/>
                    }),
                (_.isEmpty(risk.aggregatedEntityIds)
                    ? localization.launchTemplate.title.none
                    : localization.launchTemplate.title.snapshot)({
                    instances:
                        <InlineEntities
                            entityIdsOrModels={riskData.instanceIds}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            variant="itemCountAndType"/>,
                    launchTemplate:
                        <Entity
                            entityIdOrModel={launchTemplateModel}
                            variant="text"/>,
                    volumes:
                        _.isEmpty(unencryptedVolumeModels)
                            ? _.isEmpty(volumeModels)
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={volumeModels!}
                                    entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                    variant="itemCountAndType"/>
                            : <InlineEntities
                                entityIdsOrModels={unencryptedVolumeModels}
                                entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                namePluralizer={localization.launchTemplate.volumes}
                                variant="itemCountAndType"/>
                }),
                _.isEmpty(risk.aggregatedEntityIds)
                    ? undefined
                    : localization.snapshots({
                        snapshots:
                            <InlineEntities
                                entityIdsOrModels={risk.aggregatedEntityIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                variant="itemCountAndType"/>
                    }),
                launchTemplateModel?.entityConfiguration?.sensitive &&
                !_.isEmpty(risk.aggregatedEntityIds)
                    ? localization.sensitive(
                        1,
                        {
                            resources:
                                <Entity
                                    entityIdOrModel={launchTemplateModel}
                                    variant="text"/>
                        })
                    : undefined,
                _.isEmpty(sensitiveInstanceModels)
                    ? undefined
                    : localization.sensitive(
                        _.size(sensitiveInstanceModels),
                        {
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveInstanceModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                    variant="itemOrItemCountAndType"/>
                        }),
                _.isEmpty(sensitiveVolumesModels)
                    ? undefined
                    : localization.sensitive(
                        _.size(sensitiveVolumesModels),
                        {
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveVolumesModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                    variant="itemOrItemCountAndType"/>
                        }),
                _.isEmpty(sensitiveSnapshotModels)
                    ? undefined
                    : localization.sensitive(
                        _.size(sensitiveSnapshotModels),
                        {
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveSnapshotModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                    variant="itemOrItemCountAndType"/>
                        }),
                _.isNil(risk.accessLevel) ||
                risk.accessLevel === Contract.AwsResourceAccessLevel.Internal ||
                _.isEmpty(risk.aggregatedEntityIds)
                    ? undefined
                    : (accessLevelSnapshotModels.length === risk.aggregatedEntityIds.length)
                        ? localization.networkAccess.all({ accessLevel: resourceAccessLevelTranslator(risk.accessLevel) })
                        : localization.networkAccess.some({
                            accessLevel: resourceAccessLevelTranslator(risk.accessLevel),
                            accessLevelSnapshots: (
                                <InlineEntities
                                    entityIdsOrModels={accessLevelSnapshotModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                    variant="itemCountAndType"/>),
                            snapshotIds: (
                                <InlineEntities
                                    entityIdsOrModels={risk.aggregatedEntityIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                    variant="itemCountAndType"/>)
                        }),
                openRiskedEntityRisksStep
            ])}
        </Steps>);
}