﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEc2ImageRiskContext() {
    return useMemo(
        () => useAwsEc2ImageContext,
        []);
}

function useAwsEc2ImageContext(imageModel: Contract.AwsEc2ImageModel) {
    const image = imageModel.entity as Contract.AwsEc2Image;
    const resourceRiskContext = useGetAwsResourceRiskContext()(imageModel);
    const entitiesRiskContext =
        useGetEntitiesRiskContext()(
            imageModel.instanceIds,
            Contract.TypeNames.AwsEc2Instance);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2ImageRiskContext",
            () => ({
                instances: "The {{translatedImageTypeName}} was used to launch {{instances}}"
            }));

    return {
        ...resourceRiskContext,
        instances:
            _.isEmpty(imageModel.instanceIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instances({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={imageModel.instanceIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Image}
                                variant="itemCountAndType"/>,
                        translatedImageTypeName:
                            entityTypeNameTranslator(
                                image.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
        sensitiveInstances: entitiesRiskContext.sensitive
    };
}