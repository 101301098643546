import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useClearQueryParameters } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class AwsContext {
    constructor(public addOrEditOpen: boolean | Contract.AwsOrganizationConfiguration) {
    }
}

export const [useAwsContext, useSetAwsContext, useAwsContextProvider] = makeContextProvider<AwsContext>();

export function Aws() {
    const clearQueryParameters = useClearQueryParameters();
    const [context, setContext, ContextProvider] =
        useAwsContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<CloudProviderTenantOrganizationAwsQueryParameters>();
                clearQueryParameters();
                return new AwsContext(StringHelper.isTrue(queryParameters.addOrEditOpen));
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type CloudProviderTenantOrganizationAwsQueryParameters = {
    addOrEditOpen?: string;
};