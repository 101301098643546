import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, SpecificCustomRiskPolicyProps } from "../../../..";
import { Edit, View } from "./Components";

export function AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicy({ editOptions, riskPolicyConfiguration, sx }: SpecificCustomRiskPolicyProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsEncryptedResourceKmsEncryptionNotExistsRiskPolicy",
            () => ({
                template: "*capitalize*{{resources}}** should be encrypted using {{keys}}"
            }));

    return _.isNil(editOptions)
        ? <View
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration>}
            sx={sx}
            templateTranslator={localization.template}/>
        : <Edit
            actionsRef={editOptions.actionsRef}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration>}
            templateTranslator={localization.template}
            onValidChange={editOptions.onValidChange}/>;
}