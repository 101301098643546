import { AddIcon, EmptyMessage, getItemWithId, getItemWithValidation, InfoIcon, ItemWithId, ItemWithValidation, Tooltip, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Button, Grid2, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../common";
import { ValueEditProps } from "../../../../Value";
import { Item } from "./components";

export function Edit({ disabled, setValidation, valueRef }: ValueEditProps) {
    const [value, setValue] =
        useState(
            _.map(
                valueRef.current as ItemWithId<Contract.AadSeverePermissionDirectoryRoleDefinitionData>[],
                (value): ItemWithValidation<ItemWithId<Contract.AadSeverePermissionDirectoryRoleDefinitionData>> =>
                    getItemWithValidation(getItemWithId(value, value?.id), !_.isEmpty(value.name) && !_.isEmpty(value.templateId))));

    useChangeEffect(
        () => {
            valueRef.current =
                _(value).
                    filter(setting => setting.valid! && !_.isEmpty(setting.templateId)).
                    value();
            setValidation(
                _.every(
                    value,
                    setting =>
                        setting.valid! &&
                        !_.isEmpty(setting.templateId)));
        },
        [value],
        500);

    function onAdd() {
        setValue(
            value =>
                [
                    ...value,
                    getItemWithValidation(
                        getItemWithId({
                            name: "",
                            templateId: ""
                        }),
                        false)
                ]);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.settingConfigurationTable.value.aadSeverePermissionDirectoryRoleDefinitionValue.edit",
            () => ({
                actions: {
                    add: "Add"
                },
                empty: "No Settings",
                name: "Name",
                templateId: {
                    description: "To find the ID in the Azure portal, navigate to Microsoft Microsoft Entra ID > Roles and administrators, click on the role, and then, under Manage, click on Description.",
                    title: "Template ID"
                }
            }));

    const theme = useTheme();
    return (
        <Fragment>
            <Box
                sx={{
                    background:
                        disabled
                            ? theme.palette.input.disabledBackground
                            : "unset",
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75)
                }}>
                {_.isEmpty(value)
                    ? <EmptyMessage message={localization.empty()}/>
                    : <Fragment>
                        <Grid2
                            container={true}
                            spacing={0.25}
                            sx={{ borderBottom: theme.border.primary }}>
                            <Grid2
                                size={4}
                                sx={{ padding: theme.spacing(1, 2) }}>
                                <Typography>
                                    {localization.name()}
                                </Typography>
                            </Grid2>
                            <Grid2
                                size={7}
                                sx={{ padding: theme.spacing(1, 2) }}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={0.5}>
                                    <Typography>
                                        {localization.templateId.title()}
                                    </Typography>
                                    <Tooltip
                                        placement="top"
                                        titleOrGetTitle={localization.templateId.description()}>
                                        <InfoIcon sx={{ fontSize: "16px" }}/>
                                    </Tooltip>
                                </Stack>
                            </Grid2>
                            <Grid2
                                size={1}
                                sx={{ padding: theme.spacing(1, 2) }}/>
                        </Grid2>
                        {_.map(
                            value,
                            (valueItem, valueItemIndex) =>
                                <Item
                                    disabled={disabled}
                                    isDuplicate={
                                        templateId =>
                                            !_(value).
                                                filter(
                                                    (currentValueItem, currentValueItemIndex) =>
                                                        valueItemIndex !== currentValueItemIndex &&
                                                        currentValueItem.templateId === templateId).
                                                isEmpty()}
                                    key={valueItem.id}
                                    valueItem={valueItem}
                                    onChange={
                                        updatedValueItem =>
                                            setValue(
                                                value =>
                                                    _.map(
                                                        value,
                                                        (currentValueItem, currentValueItemIndex) =>
                                                            valueItemIndex === currentValueItemIndex
                                                                ? updatedValueItem
                                                                : currentValueItem))}
                                    onDelete={
                                        () =>
                                            setValue(
                                                value =>
                                                    _.filter(
                                                        value,
                                                        (_, currentValueItemIndex) =>
                                                            currentValueItemIndex !== valueItemIndex))}/>)}
                    </Fragment>}
            </Box>
            <Box>
                <Button
                    disabled={disabled}
                    size="small"
                    startIcon={<AddIcon/>}
                    variant="outlined"
                    onClick={onAdd}>
                    {localization.actions.add()}
                </Button>
            </Box>
        </Fragment>);
}