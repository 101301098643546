import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, TypeHelper, usePrincipalModelAccess } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsIamPrincipalRiskContext } from "./useGetAwsIamPrincipalRiskContext";

export function useGetAwsIamRoleRiskContext() {
    return useMemo(
        () => useAwsIamRoleRiskContext,
        []);
}

function useAwsIamRoleRiskContext(roleModel: Contract.AwsIamRoleModel) {
    const iamPrincipalRiskContext = useGetAwsIamPrincipalRiskContext()(roleModel);
    const roleModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(roleModel.id);
    const originatorEntityModels = entityModelStore.useGet(roleModelAccess.originatorEntityIds);

    const originatorWorkloadResourceModels =
        useMemo(
            () =>
                _.filter(
                    originatorEntityModels,
                    originatorEntityModel =>
                        TypeHelper.extendOrImplement(
                            originatorEntityModel.entity.typeName,
                            Contract.TypeNames.IAwsOriginatorWorkloadResource)),
            [originatorEntityModels]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsIamRoleRiskContext",
            () => ({
                originatorWorkloadResources: "The role is linked to {{originatorWorkloadResources}}",
                vendor: "The role is used by a 3rd party vendor"
            }));

    return {
        ...iamPrincipalRiskContext,
        originatorWorkloadResources:
            _.isEmpty(originatorWorkloadResourceModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.originatorWorkloadResources({
                        originatorWorkloadResources:
                            <InlineEntities
                                entityIdsOrModels={originatorWorkloadResourceModels}
                                entityTypeName={Contract.TypeNames.IAwsOriginatorWorkloadResource}
                                entityVariant="iconTextTypeTenant"
                                variant="itemAndTypeOrItemCountAndType"/>
                    })),
        vendor:
            (roleModel.entity as Contract.AwsIamRole).vendor
                ? new RiskDefinitionContextItem(localization.vendor())
                : undefined
    };
}