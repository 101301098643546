﻿import { InlineItems, Sx, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../../../controllers";
import { ResourceTagPatternHelper } from "../../../utilities";
import { InlineResourceTags } from "../../InlineResourceTags";
import { TagSelectorSelection } from "./ResourceTagNotExistsRiskPolicy/components";
import { SelectedValue } from "./SelectedValue";

type InlineTagsSelectionProps = {
    selection?: InlineTagsSelectionData | TagSelectorSelection;
    sx?: SxProps;
};

export function InlineTagsSelection({ selection, sx }: InlineTagsSelectionProps) {
    const [existsResourceTagKeys, existsResourceTags, existsResourceTagPatternKey, existsResourceTagPatternValue] =
        useMemo(
            () => {
                const inlineSelectionData =
                    selection instanceof TagSelectorSelection
                        ? selection.getInlineSelectionData()
                        : selection;
                return [
                    inlineSelectionData?.existsResourceTagKeys,
                    inlineSelectionData?.existsResourceTags,
                    inlineSelectionData?.existsResourceTagPatternKey,
                    inlineSelectionData?.existsResourceTagPatternValue
                ];
            },
            [selection]);

    const localization =
        useLocalization(
            "common.customRiskPolicy.inlineTagsSelection",
            () => ({
                existsResourceTagKeys: [
                    "1 tag key",
                    "{{count | NumberFormatter.humanize}} tag keys"
                ],
                existsResourceTagPattern: [
                    "1 tag key and value pattern",
                    "{{count | NumberFormatter.humanize}} tag key and value patterns"
                ],
                existsResourceTags: [
                    "1 tag key and value",
                    "{{count | NumberFormatter.humanize}} tag keys and values"
                ],
                placeholder: "these tags"
            }));

    const textSx =
        Sx.combine(
            {
                fontWeight: 600,
                textDecoration: "underline"
            },
            sx);
    return (
        <Typography
            component="span"
            sx={textSx}>
            {!_.isNil(existsResourceTagPatternKey) && !_.isNil(existsResourceTagPatternValue)
                ? <InlineItems
                    items={[
                        ResourceTagPatternHelper.getDisplayName(
                            new Contract.ResourceTagPattern(
                                true,
                                existsResourceTagPatternKey,
                                existsResourceTagPatternValue))
                    ]}
                    namePluralizer={localization.existsResourceTagPattern}
                    sx={textSx}
                    variant="itemCountAndType"/>
                : _.isEmpty(existsResourceTagKeys) && _.isEmpty(existsResourceTags)
                    ? localization.placeholder()
                    : !_.isEmpty(existsResourceTagKeys)
                        ? <InlineItems
                            items={existsResourceTagKeys}
                            namePluralizer={localization.existsResourceTagKeys}
                            sx={textSx}
                            variant="itemCountAndType"/>
                        : <SelectedValue>
                            <InlineResourceTags
                                sx={textSx}
                                tags={existsResourceTags!}
                                textVariant="text"
                                variant="itemCountAndType"/>
                        </SelectedValue>}
        </Typography>);
}

export type InlineTagsSelectionData = {
    existsResourceTagKeys?: string[];
    existsResourceTagPatternKey?: string;
    existsResourceTagPatternValue?: string;
    existsResourceTags?: Contract.ResourceTag[];
};