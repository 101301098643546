import { Action1, CsvExportButton, DataTable, DataTableAction, DataTableProps, DataTableSummary, EmptyMessage, StorageItem } from "@infrastructure";
import { SxProps } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useState } from "react";
import { ColumnIdToItemValuesMap, GetItemValueDefinition, useTableDefinition } from "../../../common";

export type ItemTableProps<TItem, TSummary extends DataTableSummary, TValuesMap extends { [columnId: string]: GetItemValueDefinition<TItem> }> =
    Pick<DataTableProps<TSummary>, "actionsRef" | "columnOptions" | "getItemId" | "emptyMessageOptions" | "externalLoading" | "highlightItem" | "manualScroll" | "onItemCountChanged" | "onLoadedItemsChanged" | "pageSize" | "rowOptions" | "selectionOptions" | "sortOptions" | "sx" | "variant"> & {
        children: (columnIdToItemValuesMap: ColumnIdToItemValuesMap<TValuesMap>) => ReactNode[];
        columnIdToDefaultSortDirectionMap?: Dictionary<"asc" | "desc">;
        columnIdToGetItemValueMap: TValuesMap;
        csvExportFilePrefixes?: string[];
        dataTableActions?: ReactNode;
        defaultSortColumnIdOrIds: string | string[];
        filterOptions?: ItemTableFilterOptions;
        getCsvItem?: (item: TItem) => any;
        items: TItem[];
        onFilteredItemsChanged?: (items: TItem[]) => void;
        showEmptyTable?: boolean;
    };

export type ItemTableFilterOptions = {
    initialState?: _.Dictionary<any> | undefined;
    onChanged?: Action1<_.Dictionary<any>>;
    persist?: boolean;
    sx?: SxProps;
    visibilityStorageItem?: StorageItem;
};

export function ItemTable<TItem, TSummary extends DataTableSummary>({ actionsRef, children, columnIdToDefaultSortDirectionMap, columnIdToGetItemValueMap, columnOptions, csvExportFilePrefixes, dataTableActions, defaultSortColumnIdOrIds, emptyMessageOptions, externalLoading, filterOptions, getCsvItem, getItemId, highlightItem, items, manualScroll = false, onFilteredItemsChanged, onItemCountChanged, onLoadedItemsChanged, pageSize, rowOptions, selectionOptions, showEmptyTable = false, sortOptions, sx, variant = "card" }: ItemTableProps<TItem, TSummary, {
    [columnId: string]: GetItemValueDefinition<TItem>;
}>) {
    const tableDefinition =
        useTableDefinition(
            items,
            columnIdToGetItemValueMap,
            defaultSortColumnIdOrIds,
            {
                columnIdToDefaultSortDirectionMap,
                getCsvItem,
                getItemId,
                onFilteredItemsChanged
            });

    const [filtered, setFiltered] = useState(false);
    const [filteredItemsCount, setFilteredItemsCount] = useState<number | undefined>(items.length);
    return _.isEmpty(items) && !showEmptyTable && !_.isNil(emptyMessageOptions?.emptyMessageText)
        ? <EmptyMessage
            message={emptyMessageOptions!.emptyMessageText.getText(false)}
            verticalCenter={true}/>
        : <DataTable
            actionsRef={actionsRef}
            columnOptions={columnOptions}
            emptyMessageOptions={emptyMessageOptions}
            externalLoading={externalLoading}
            fetchItems={tableDefinition.filterAndSortItems}
            filtersOptions={{
                initial: {
                    state: filterOptions?.initialState
                },
                onChanged:
                    filterMap => {
                        filterOptions?.onChanged?.(filterMap);
                        setFiltered(!_.isEmpty(filterMap));
                    },
                persist: {
                    filterMap: filterOptions?.persist ?? false,
                    visibilityStorageItem: filterOptions?.visibilityStorageItem
                },
                sx: filterOptions?.sx
            }}
            getItemId={getItemId}
            highlightItem={highlightItem}
            manualScroll={manualScroll}
            pageSize={pageSize}
            rowOptions={rowOptions}
            selectionOptions={selectionOptions}
            sortOptions={sortOptions}
            sx={sx}
            variant={variant}
            onItemCountChanged={
                itemsCount => {
                    setFilteredItemsCount(itemsCount);
                    onItemCountChanged?.(itemsCount);
                }}
            onLoadedItemsChanged={onLoadedItemsChanged}>
            {dataTableActions}
            {!_.isNil(csvExportFilePrefixes) &&
                <DataTableAction>
                    <CsvExportButton
                        disabled={!_.isNil(filteredItemsCount) && filteredItemsCount === 0}
                        fileNameOptions={{
                            filtered,
                            prefix:
                                csvExportFilePrefixes.
                                    join("_").
                                    replace(/\s/, "_")
                        }}
                        getItemPage={async (limit, skip) => tableDefinition.getCsvItemPage(limit, skip)}
                        itemCount={items.length}/>
                </DataTableAction>}
            {children(tableDefinition.columnIdToItemValuesMap)}
        </DataTable>;
}