import { useMemo } from "react";
import { ItemOptions, useGetItem } from "./hooks";

export function useItems(reportData: ItemOptions) {
    const reportItem = useGetItem(reportData);

    return useMemo(
        () => ({
            [reportData.reportType]: () => reportItem()
        }),
        [reportData, reportItem]);
}