import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../../..";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { FirewallRulesInfoCard, RoutesInfoCard, SubnetsInfoCard } from "./components";

export function useGcpComputeVpcDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const vpcModel = scopeResourceModel as Contract.GcpComputeVpcModel;
    const vpc = scopeResourceModel.entity as Contract.GcpComputeVpc;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeVpcDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        associatedVpcPeeringConnectionIds: "Associated VPC Peering Connections",
                        firewallPolicyIds: "Firewall Policies",
                        legacy:
                            {
                                false: "False",
                                title: "Legacy VPC",
                                true: "True"
                            },
                        subnetRegions: "Regions",
                        subnetSubnets: "IP address ranges"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <VerticalTopItemsInfoItem
                        items={vpcModel.subnetRegions}
                        key="subnetRegions"
                        title={localization.info.items.subnetRegions()}/>,
                    <VerticalTopItemsInfoItem
                        items={vpcModel.subnetSubnets}
                        key="subnetSubnets"
                        title={localization.info.items.subnetSubnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcModel.associatedVpcPeeringConnectionIds}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="associatedVpcPeeringConnectionIds"
                        title={localization.info.items.associatedVpcPeeringConnectionIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcModel.firewallPolicyIds}
                        entityTypeName={Contract.TypeNames.GcpComputeFirewallPolicy}
                        key="firewallPolicyIds"
                        title={localization.info.items.firewallPolicyIds()}/>,
                    <InfoItem
                        key="legacy"
                        title={localization.info.items.legacy.title()}
                        value={
                            vpc.legacy
                                ? localization.info.items.legacy.true()
                                : localization.info.items.legacy.false()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={vpcModel.networkedResourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
                <SubnetsInfoCard subnetIds={vpcModel.subnetIds}/>
                <FirewallRulesInfoCard firewallRuleIds={vpcModel.firewallRuleIds}/>
                <RoutesInfoCard routeIds={vpcModel.routeIds}/>
            </Info>
    });
}