import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonEntityNetworkInfoItemElements } from "../..";
import { useAzureCommonEncryptedResourceInfoElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useAzureEventHubNamespaceSkuTranslator } from "../../../../../../../hooks/useAzureEventHubNamespaceSkuTranslator";
import { useAzureEventHubNamespaceStatusTranslator } from "../../../../../../../hooks/useAzureEventHubNamespaceStatusTranslator";
import { Info } from "../../../../../components";
import { AzureFirewallInfoCard, AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { EventHubsInfoCard } from "./components";

export function useAzureEventHubNamespaceDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const namespace = resourceGroupResourceModel.entity as Contract.AzureEventHubNamespace;
    const namespaceModel = resourceGroupResourceModel as Contract.AzureEventHubNamespaceModel;

    const eventHubNamespaceSkuTranslator = useAzureEventHubNamespaceSkuTranslator();
    const eventHubNamespaceStatusTranslator = useAzureEventHubNamespaceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureEventHubNamespaceDefinition",
            () => ({
                info: {
                    items: {
                        allowAnyAzureTrustedServices: {
                            false: "Disabled",
                            title: "Allow Azure Services Access",
                            true: "Enabled"
                        },
                        endpointUrl: "Access Endpoint",
                        networkAccess: {
                            title: "Network Access",
                            [Contract.TypeNames.AzureEventHubNamespaceNetworkAccessType]: {
                                [Contract.AzureEventHubNamespaceNetworkAccessType.Public]: "All Networks",
                                [Contract.AzureEventHubNamespaceNetworkAccessType.Restricted]: "Selected Networks",
                                [Contract.AzureEventHubNamespaceNetworkAccessType.Private]: "Disabled"
                            }
                        },
                        sharedKeyAccess: {
                            false: "Disabled",
                            title: "Local Authentication",
                            true: "Enabled"
                        },
                        sku: "Tier",
                        status: "Status",
                        tlsMinVersion: "Minimum TLS Version"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="sku"
                        title={localization.info.items.sku()}
                        value={eventHubNamespaceSkuTranslator(namespace.sku)}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={eventHubNamespaceStatusTranslator(namespace.status)}/>,
                    <InfoItem
                        key="networkAccess"
                        title={localization.info.items.networkAccess.title()}
                        value={localization.info.items.networkAccess[Contract.TypeNames.AzureEventHubNamespaceNetworkAccessType][namespace.networkAccessType]()}/>,
                    <InfoItem
                        key="sharedKeyAccess"
                        title={localization.info.items.sharedKeyAccess.title()}
                        value={
                            namespace.sharedKeyAccess
                                ? localization.info.items.sharedKeyAccess.true()
                                : localization.info.items.sharedKeyAccess.false()}/>,
                    <InfoItem
                        key="endpointUrl"
                        title={localization.info.items.endpointUrl()}
                        value={
                            <Link
                                urlOrGetUrl={namespace.endpointUrl}
                                variant="external"/>}/>,
                    <InfoItem
                        key="tlsMinVersion"
                        title={localization.info.items.tlsMinVersion()}
                        value={namespace.tlsMinVersion}/>,
                    <InfoItem
                        key="allowAnyAzureTrustedServices"
                        title={localization.info.items.allowAnyAzureTrustedServices.title()}
                        value={
                            namespace.networkAccessType === Contract.AzureEventHubNamespaceNetworkAccessType.Public
                                ? undefined
                                : namespace.allowAnyAzureTrustedServices
                                    ? localization.info.items.allowAnyAzureTrustedServices.true()
                                    : localization.info.items.allowAnyAzureTrustedServices.false()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                </NetworkingInfoCard>
                {namespace.networkAccessType !== Contract.AzureEventHubNamespaceNetworkAccessType.Public
                    ? <AzureFirewallInfoCard
                        networkResourcePathToIdReferencesMap={namespaceModel.networkResourcePathToIdReferencesMap}
                        subnets={namespace.firewallRuleSubnets}
                        virtualNetworkPathToFirewallSubnetRulesMap={namespace.virtualNetworkPathToFirewallSubnetRulesMap}/>
                    : undefined}
                <EventHubsInfoCard eventHubsIds={namespaceModel.eventHubIds}/>
            </Info>
    });
}