﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../common";

export function useCiTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useCiDefinition.hooks.useCiTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.CiTenantModelStatus]: {
                    [Contract.CiTenantModelStatus.AuthenticationFailed]: "Failed to authenticate to this registry. Check authentication details",
                    [Contract.CiTenantModelStatus.Deleting]: "Deleting...",
                    [Contract.CiTenantModelStatus.EndpointNotExist]: "Endpoint Connector integration is not connected",
                    [Contract.CiTenantModelStatus.GetRepositoriesFailure]: "This registry type is not currently supported",
                    [Contract.CiTenantModelStatus.RegistryEmpty]: "This registry is not supported as no repositories were found",
                    [Contract.CiTenantModelStatus.RegistryNotFound]: "Failed to connect to this registry. Check the URL",
                    [Contract.CiTenantModelStatus.TooManyRepositories]: "The number of repositories in this registry exceeds the maximum",
                    [Contract.CiTenantModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.CiTenantModelStatus) => localization[Contract.TypeNames.CiTenantModelStatus][status]();
}