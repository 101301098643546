import React from "react";
import { entityModelStore } from "../../../../../../../common";
import { Profile, ProfileActions } from "../../../../Entities/components";
import { useDefinition } from "../../../../Entities/components/Profile/hooks";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useEntityDefinition(item: SideViewItem): SideViewDefinition {
    const entityModel = entityModelStore.useGet(item.id);
    const definition = useDefinition(entityModel);

    return {
        getActionsElement:
            () =>
                <ProfileActions
                    definition={definition}
                    entityModel={entityModel}
                    key={`${item.id}-actions`}/>,
        getViewElement:
            () =>
                <Profile
                    definition={definition}
                    entityModel={entityModel}
                    key={`${item.id}-profile`}/>
    };
}