﻿import { map, Optional, StringHelper } from "@infrastructure";
import _ from "lodash";
import { Contract, EntityTypeNameTranslatorOptions } from "../../../common";

export class AzureAuthorizationRoleAssignmentResourceHelper {
    public static getDisplayName(entityTypeNameTranslator: (typeName: string, options?: EntityTypeNameTranslatorOptions) => string, roleAssignmentResourceModel: Contract.AzureAuthorizationRoleAssignmentResourceModel) {
        return roleAssignmentResourceModel.unknown || _.isNil(roleAssignmentResourceModel.scopeEntityTypeName)
            ? `${roleAssignmentResourceModel.entity.displayName}`
            : `${roleAssignmentResourceModel.entity.displayName} (${entityTypeNameTranslator(roleAssignmentResourceModel.scopeEntityTypeName)})`;
    }

    public static getPrincipalSortValue =
        (principal: Optional<Contract.AadDirectoryPrincipal>, principalReferenceType: Contract.AadDirectoryPrincipalType) =>
            map(
                principalReferenceType,
                {
                    [Contract.AadDirectoryPrincipalType.Everyone]: () => 0,
                    [Contract.AadDirectoryPrincipalType.ForeignGroup]: () => 1,
                    [Contract.AadDirectoryPrincipalType.Group]: () => 2,
                    [Contract.AadDirectoryPrincipalType.User]:
                        () =>
                            _.isNil(principal)
                                ? 4
                                : 3,
                    [Contract.AadDirectoryPrincipalType.ServicePrincipal]:
                        () =>
                            _.isNil(principal)
                                ? 10
                                : map(
                                    principal.typeName,
                                    {
                                        [Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal]: () => 5,
                                        [Contract.TypeNames.AadDirectoryApplicationServicePrincipal]:
                                            () =>
                                                (principal as Contract.AadDirectoryApplicationServicePrincipal).vendor
                                                    ? 7
                                                    : 6
                                    },
                                    () => 10),
                    [Contract.AadDirectoryPrincipalType.Msi]: () => 5,
                    [Contract.AadDirectoryPrincipalType.Application]:
                        () =>
                            (principal as Contract.AadDirectoryApplicationServicePrincipal)?.vendor
                                ? 7
                                : 6,
                    [Contract.AadDirectoryPrincipalType.DirectoryObjectOrGroup]: () => 8,
                    [Contract.AadDirectoryPrincipalType.DirectoryRoleTemplate]: () => 9,
                    [Contract.AadDirectoryPrincipalType.Unknown]: () => 11
                });

    public static getTenantAssignedRoleDefinitionNames(tenantModel: Contract.TenantModel) {
        return _((tenantModel.configuration as Contract.AzureTenantConfiguration).scopeEntityPathToServicePrincipalAssignedRoleDefinitionNamesMap).
            values().
            flatMap().
            uniq().
            orderBy(roleDefinitionName => StringHelper.getSortValue(roleDefinitionName)).
            value();
    }
}