﻿import { DialogMenuItem, NumberFormatter, Optional, TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { FunctionComponentElement } from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements, useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "..";
import { Contract, InfoItem, InfoItemLocation } from "../../../../../../../../../../common";
import { GcpBigQueryDatasetTablesItem } from "../../../../../../../../../../tenants";
import { Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../useDefaultDataAnalysisResourceInfoItemElements";

export function useGcpBigQueryDatasetDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const bigQueryDatasetModel = scopeResourceModel as Contract.GcpBigQueryDatasetModel;

    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const commonLoggingLogSinkDestinationResourceInfoItemElements = useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const datasetModel = scopeResourceModel as Contract.GcpBigQueryDatasetModel;
    const dataset = datasetModel.entity as Contract.GcpBigQueryDataset;
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            bigQueryDatasetModel,
            dataAnalysis => dataAnalysis.managedDatabaseScanEnabled);
    const menuItems = [getDataAnalysisResourcePriorityScanRequestDialogMenuItem()];

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpBigQueryDatasetDefinition",
            () => ({
                info: {
                    items: {
                        defaultTableExpirationTimeFrame: {
                            empty: "Never",
                            title: "Default table expiration"
                        },
                        kmsEncryptionKeyReferences: "Default Encryption",
                        size: "Size",
                        tableIds: "BigQuery Tables"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));


    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={datasetModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={
                    _<Optional<FunctionComponentElement<{ location?: InfoItemLocation }>>>([]).
                        concat([
                            ...defaultScopeResourceInfoItemElements,
                            commonEncryptedResourceInfoElements.encryptionExists,
                            commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                            commonLoggingLogSinkDestinationResourceInfoItemElements.loggingLogSinks,
                            <InfoItem
                                emptyValue={localization.info.items.defaultTableExpirationTimeFrame.empty()}
                                key="defaultTableExpirationTimeFrame"
                                title={localization.info.items.defaultTableExpirationTimeFrame.title()}
                                value={
                                    _.isNil(dataset.defaultTableExpirationTimeFrame)
                                        ? undefined
                                        : TimeSpanFormatter.humanizeDays(dataset.defaultTableExpirationTimeFrame)}/>,
                            <InfoItem
                                key="tablesIds"
                                title={localization.info.items.tableIds()}
                                value={
                                    <GcpBigQueryDatasetTablesItem
                                        datasetId={datasetModel.id}
                                        tableCount={datasetModel.tableCount}/>}/>,
                            <InfoItem
                                key="size"
                                location="all"
                                title={localization.info.items.size()}
                                value={NumberFormatter.storage(dataset.storageSize)}/>
                        ]).concat(defaultDataAnalysisResourceInfoItemElements).value()}
                options={options?.infoOptions}>
            </Info>,
        topbarMenuItems: _.filter(menuItems) as DialogMenuItem[]
    });
}