import { InlineItems, InlineItemsVariant, useLocalization } from "@infrastructure";
import { SxProps } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { customEntityAttributeDefinitionModelStore, useBuiltInEntityAttributeTypeNameTranslator } from "..";

type InlineEntityAttributesProps = {
    builtInAttributeTypeNames?: string[];
    customAttributeDefinitionIds?: string[];
    namePluralizer?: (count: number) => string;
    sx?: SxProps;
    variant?: InlineItemsVariant;
};

export function InlineEntityAttributes({ builtInAttributeTypeNames, customAttributeDefinitionIds, namePluralizer, sx, variant = "itemPlusItemCount" }: InlineEntityAttributesProps) {
    const builtInEntityAttributeTypeNameTranslator = useBuiltInEntityAttributeTypeNameTranslator();
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const customEntityAttributeDefinitionModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customEntityAttributeDefinitionModels,
                    customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id),
            [customEntityAttributeDefinitionModels]);
    const entityAttributeNames =
        _(builtInAttributeTypeNames).
            map(builtInAttributeTypeName => builtInEntityAttributeTypeNameTranslator(builtInAttributeTypeName)).
            concat(
                _(customAttributeDefinitionIds).
                    filter(customAttributeDefinitionId => !_.isNil(customEntityAttributeDefinitionModelMap[customAttributeDefinitionId])).
                    map(customAttributeDefinitionId => customEntityAttributeDefinitionModelMap[customAttributeDefinitionId].configuration.name).
                    value()
            ).
            value();
    const localization =
        useLocalization(
            "common.inlineEntityAttributes",
            () => ({
                attributes: [
                    "1 label",
                    "{{count | NumberFormatter.humanize}} labels"
                ]
            }));
    return (
        <InlineItems
            items={entityAttributeNames}
            namePluralizer={namePluralizer ?? localization.attributes}
            sx={sx}
            variant={variant}/>);
}