﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useGetResolutionSteps } from "../..";
import { Contract } from "../../../../../../../../../../../../../../../../common";

export function useDatabricksInstanceGroupResolutionSteps(riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel, resourceGroupModel: Contract.AwsResourceGroupModel) {
    const getResolutionSteps = useGetResolutionSteps();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskTagResourceGroupDefinition.hooks.useDatabricksInstanceGroupResolutionSteps",
            () => ({
                resolution: {
                    documentation: "documentation",
                    step1: "In Databricks, configure your workspace to enforce IMDSv2 using the following **{{documentationLink}}**"
                }
            }));

    return [
        ...getResolutionSteps(
            resourceGroupModel.entity.typeName,
            riskModel),
        localization.resolution.step1({
            documentationLink:
                <Link
                    sx={{ textDecorationLine: "underline" }}
                    urlOrGetUrl={riskModel.databricksDocumentationUrl}
                    variant="external">
                    {localization.resolution.documentation()}
                </Link>
        })
    ];
}