import { ItemWithValidation, useChangeEffect } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { SlackChannelReferenceMultiSelect } from "../..";
import { Contract } from "../../../controllers";
import { DeliveryProps } from "../Delivery";
import { Message } from "./Message";

export function Slack({ delivery, disabled, iconVisible, messageOptions, onChange, scopeId }: DeliveryProps) {
    const [slackChannelReferences, setSlackChannelReferences] =
        useState(
            _.map(
                (delivery.destinations ?? []) as Contract.SlackDeliveryChannel[],
                ({ slackChannelReference }) => slackChannelReference));
    const [message, setMessage] = useState(delivery?.message);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.SlackDelivery),
                destinations:
                    _.map(
                        slackChannelReferences,
                        slackChannelReference => ({
                            slackChannelReference,
                            typeName: Contract.TypeNames.SlackDeliveryChannel
                        })) as Contract.SlackDeliveryChannel[],
                message,
                valid: !_.isEmpty(slackChannelReferences)
            } as ItemWithValidation<Contract.SlackDelivery>),
        [slackChannelReferences, message],
        500);

    return (
        <Fragment>
            <SlackChannelReferenceMultiSelect
                disabled={disabled}
                iconVisible={iconVisible}
                scopeId={scopeId}
                selectedChannelReferences={slackChannelReferences}
                onSelectedChannelReferencesChanged={setSlackChannelReferences}/>
            <Message
                disabled={disabled}
                message={message}
                options={messageOptions}
                setMessage={setMessage}/>
        </Fragment>);
}