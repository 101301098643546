import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { ApproverUserPermissionRequestViewType } from "../../../..";
import { Contract } from "../../../../../../../../common";
import { useActiveDefinition, useHistoryDefinition } from "./hooks";

export function useDefinition(viewType: ApproverUserPermissionRequestViewType): TableDefinition {
    const useDefinition =
        useMemo(
            ()=> {
                switch(viewType) {
                    case ApproverUserPermissionRequestViewType.Active:
                        return useActiveDefinition;
                    case ApproverUserPermissionRequestViewType.History:
                        return useHistoryDefinition;
                    default:
                        throw new UnexpectedError("itemType", viewType);
                }
            },
            [viewType]);
    return useDefinition();
}

export class TableDefinition {
    constructor(
        public getColumns: () => ReactNode[],
        public permissionApproveStatus: Contract.PermissionRequestStatus[]) {
    }
}