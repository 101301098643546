import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function TwilioIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2231)">
                <path
                    d="M12 0C5.36 0 0 5.36 0 12C0 18.64 5.36 24 12 24C18.64 24 24 18.64 24 12C24 5.36 18.64 0 12 0ZM12 20.8C7.12 20.8 3.2 16.88 3.2 12C3.2 7.12 7.12 3.2 12 3.2C16.88 3.2 20.8 7.12 20.8 12C20.8 16.88 16.88 20.8 12 20.8ZM17.44 9.04C17.44 10.4 16.32 11.52 14.96 11.52C13.6 11.52 12.48 10.4 12.48 9.04C12.48 7.68 13.6 6.56 14.96 6.56C16.32 6.56 17.44 7.68 17.44 9.04ZM17.44 14.96C17.44 16.32 16.32 17.44 14.96 17.44C13.6 17.44 12.48 16.32 12.48 14.96C12.48 13.6 13.6 12.48 14.96 12.48C16.32 12.48 17.44 13.6 17.44 14.96ZM11.52 14.96C11.52 16.32 10.4 17.44 9.04 17.44C7.68 17.44 6.56 16.32 6.56 14.96C6.56 13.6 7.68 12.48 9.04 12.48C10.4 12.48 11.52 13.6 11.52 14.96ZM11.52 9.04C11.52 10.4 10.4 11.52 9.04 11.52C7.68 11.52 6.56 10.4 6.56 9.04C6.56 7.68 7.68 6.56 9.04 6.56C10.4 6.56 11.52 7.68 11.52 9.04Z"
                    fill="#F22F46"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2231">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}