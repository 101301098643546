﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../controllers";

export function useKubernetesTaintEffectTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useKubernetesTaintEffectTranslator",
            () => ({
                [Contract.TypeNames.KubernetesTaintEffect]: {
                    [Contract.KubernetesTaintEffect.NoExecute]: "NoExecute",
                    [Contract.KubernetesTaintEffect.NoSchedule]: "NoSchedule",
                    [Contract.KubernetesTaintEffect.PreferNoSchedule]: "PreferNoSchedule"
                }
            }));
    return (type: Contract.KubernetesTaintEffect) => localization[Contract.TypeNames.KubernetesTaintEffect][type]();
}