﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetCommonKubernetesClusterRiskContext } from "./contexts";

export function useKubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterAuthenticationPrincipalRoleBindingExistsRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);
    const systemAuthenticatedGroupNonDefaultRoleBindingIds = (riskModel as Contract.KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskModel).systemAuthenticatedGroupNonDefaultRoleBindingIds;
    const systemUnauthenticatedGroupNonDefaultRoleBindingIds = (riskModel as Contract.KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskModel).systemUnauthenticatedGroupNonDefaultRoleBindingIds;

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.useKubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskDefinition",
            () => ({
                contextItems: {
                    systemAnonymousUserRoleBindings: "The cluster has {{roleBindings}} associated with the system:anonymous user",
                    systemAuthenticatedGroupRoleBindings: "The cluster has {{roleBindings}} associated with the system:authenticated group",
                    systemUnauthenticatedGroupRoleBindings: "The cluster has {{roleBindings}} associated with the system:unauthenticated group"
                },
                description: "The {{cluster}} is publicly accessible",
                resolutionSection: {
                    step1: "Consider removing {{roleBindings}} from the system:anonymous user",
                    step2: "Consider removing {{roleBindings}} from the system:unauthenticated group",
                    step3: "Consider removing {{roleBindings}} from the system:authenticated group"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                    variant="typeText"/>
        }),
        () =>
            _<string>([]).
                concatIf(
                    !_.isEmpty(risk.systemAnonymousUserRoleBindingIds),
                    () =>
                        localization.resolutionSection.step1({
                            roleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.systemAnonymousUserRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesRoleBinding}
                                    variant="itemCountAndType"/>
                        })).
                concatIf(
                    !_.isEmpty(systemUnauthenticatedGroupNonDefaultRoleBindingIds),
                    () =>
                        localization.resolutionSection.step2({
                            roleBindings:
                                <InlineEntities
                                    entityIdsOrModels={systemUnauthenticatedGroupNonDefaultRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesRoleBinding}
                                    variant="itemCountAndType"/>
                        })).
                concatIf(
                    !_.isEmpty(systemAuthenticatedGroupNonDefaultRoleBindingIds),
                    () =>
                        localization.resolutionSection.step3({
                            roleBindings:
                                <InlineEntities
                                    entityIdsOrModels={systemAuthenticatedGroupNonDefaultRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesRoleBinding}
                                    variant="itemCountAndType"/>
                        })).
                value(),
        riskModel,
        () => {
            const kubernetesClusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);
            return [
                kubernetesClusterRiskContext.generalInformation,
                kubernetesClusterRiskContext.sensitive,
                kubernetesClusterRiskContext.virtualNetwork,
                _.isEmpty(risk.systemAnonymousUserRoleBindingIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.systemAnonymousUserRoleBindings({
                            roleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.systemAnonymousUserRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesRoleBinding}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(risk.systemUnauthenticatedGroupRoleBindingIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.systemUnauthenticatedGroupRoleBindings({
                            roleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.systemUnauthenticatedGroupRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesRoleBinding}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(risk.systemAuthenticatedGroupRoleBindingIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.systemAuthenticatedGroupRoleBindings({
                            roleBindings:
                                <InlineEntities
                                    entityIdsOrModels={risk.systemAuthenticatedGroupRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesRoleBinding}
                                    variant="itemCountAndType"/>
                        })),
                kubernetesClusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}