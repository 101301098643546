import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../controllers";

export function useGcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperatorTranslator() {
    const localization =
        useLocalization(
            "common.customRiskPolicy.gcpPrincipalNotAllowedResourceRoleRiskPolicy.edit.hooks.useGcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperatorTranslator",
            () => ({
                [Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator.Overlap]: "Any",
                [Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator.Subset]: "All"
            }));
    return (type: Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator) => localization[type]();
}