import _, { Dictionary } from "lodash";
import React, { Dispatch, memo, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { Action1, ActionButton, AnalyticsEventActionType, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, EmptyMessageText, Optional, TimeHelper, useGetExportFileName, useLocalization, useOperation, useTrackAnalytics } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, ElasticsearchItemPageHelper, Entity, EntityController, entityModelStore, EntityPropertyHelper, EntityTypeMetadataModelHelper, ExportReportHelper, PagedEntityFilter, ReportsIcon, StorageHelper, tenantModelStore, useEntityTypeNameTranslator, UserHelper, useSelectedScopeId, useTenantNameTranslator, useTenantTypeTranslator } from "../../../../../../common";
import { CustomerSideViewItemType, useSideViewContext } from "../../../SideView";
import { ProfileView } from "../Profile";
import { ProfileCategory } from "../Profile/hooks";
import { ReportData } from "../View";
import { useDefinition } from "./hooks";
import { TableHelper } from "./utilities";

type TableProps = {
    filtersOptions?: TableFiltersOptions;
    setReportData?: Dispatch<SetStateAction<ReportData>>;
    variant?: "exportButtonOnly" | "filtersOnly" | "view";
} & ({
    entityTypeEntitiesViewName: string;
    hideEntityPropertyColumns?: never;
    tenantTypes?: never;
    typeName?: never;
} | {
    entityTypeEntitiesViewName?: never;
    hideEntityPropertyColumns?: boolean;
    tenantTypes?: Contract.TenantType[];
    typeName: string;
});

type TableFiltersOptions = {
    initialMap?: Dictionary<any>;
    onFiltersChanged?: Action1<Dictionary<any>>;
    time?: string;
};

const TableMemo = memo(Table);
export { TableMemo as Table };

function Table({ entityTypeEntitiesViewName, filtersOptions, hideEntityPropertyColumns, setReportData, tenantTypes, typeName, variant = "view" }: TableProps) {
    const dataTableActionsRef = useRef<DataTableActions>();

    const entityTypeName = entityTypeEntitiesViewName ?? typeName;
    const entityTenantTypes =
        useMemo(
            () => tenantTypes ?? [EntityTypeMetadataModelHelper.get(entityTypeName).tenantType],
            [entityTypeEntitiesViewName, tenantTypes]);

    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants(entityTenantTypes);
    const propertyDefinitionIdentifiers = EntityPropertyHelper.getDefinitionIdentifiers(filteredActiveTenantModels);

    const [entityModelFiltersPromise, refreshEntityModelFilters] =
        useOperation(
            [useDefinition, entityTypeName],
            async () => {
                const { entityModelFilters } = await EntityController.getEntityModelFilters(new Contract.EntityControllerGetEntityModelFiltersRequest(entityTypeName));
                dataTableActionsRef.current?.refreshFilters();
                return entityModelFilters;
            });

    const { registerDataChange } = useSideViewContext();

    useEffect(
        () => {
            StorageHelper.customerEntitiesProfileSelectedTab(entityTypeName).
                removeValue();
            StorageHelper.customerEntitiesSelectedTableView.setValue(entityTypeName);
            const unregister =
                registerDataChange(
                    type => {
                        if (type === Contract.CustomerConsoleAppSideViewType.Entity) {
                            refreshEntityModelFilters();
                        }
                    });
            return () => {
                StorageHelper.customerEntitiesProfileSelectedTab(entityTypeName).
                    removeValue();
                StorageHelper.customerEntitiesSelectedTableView.removeValue();
                unregister();
            };
        },
        []);

    const definition =
        useDefinition({
            entityModelFiltersPromise,
            entityTypeEntitiesViewName: entityTypeName,
            getFiltersTime: () => filtersOptions?.time ?? dataTableActionsRef.current?.getFiltersTime()
        });

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table",
            () => ({
                actions: {
                    reportRedirect: {
                        tooltip: "Create Scheduled Report"
                    }
                },
                empty: {
                    withFilter: "No Matching {{translatedEntityTypeName}}",
                    withoutFilter: "No {{translatedEntityTypeName}}"
                },
                entityColumnTitle: "Name"
            }));

    const sortRef = useRef<DataTableSort>();
    const [filterMap, setFilterMap] = useState<Dictionary<any>>(filtersOptions?.initialMap ?? definition.options?.initialFilterMap ?? {});
    useEffect(
        () => {
            filtersOptions?.onFiltersChanged?.(filterMap);
        },
        [filterMap]);

    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityTypeName,
            {
                count: 0,
                includeServiceName: true
            });

    async function getExportData(itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<string[]>>): Promise<ExportData> {
        const relatedEntityIds =
            _(itemIdToExportColumnElementIdToExportDataMap).
                values().
                flatMap(
                    exportColumnElementIdToExportDataMap =>
                        _(exportColumnElementIdToExportDataMap).
                            values().
                            flatMap().
                            value()).
                uniq().
                filter().
                value();
        const relatedEntities = await entityModelStore.get(relatedEntityIds);
        return {
            filterMap,
            relatedEntityModelMap:
                _.keyBy(
                    relatedEntities,
                    relatedEntity => relatedEntity.id)
        };
    }

    const tenantModels = tenantModelStore.useGetAll();
    const tenantModelMap =
        useMemo(
            () =>
                _.keyBy(
                    tenantModels,
                    tenantModel => tenantModel.configuration.id),
            [tenantModels]);
    const { selectedScopeId } = useSelectedScopeId();

    const trackAnalytics = useTrackAnalytics();
    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerEntitiesColumnOrder(entityTypeName)
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerEntitiesColumnSelector(entityTypeName)
                },
                stickyColumnId: Contract.EntityModelProperty.EntityId
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter({ translatedEntityTypeName }),
                        localization.empty.withFilter({ translatedEntityTypeName }))
            }}
            exportOptions={{
                fileNamePrefix: _.replace(translatedEntityTypeName, /\s/g, ""),
                getData: getExportData,
                onExportClick:
                    async (filterMap, ExportFileNameOptions) => {
                        const exportFileName = getExportFileName(ExportFileNameOptions);
                        const reportRequestDefinition =
                            new Contract.ReportControllerInventoryReportRequestDefinition(
                                exportFileName,
                                TimeHelper.timeZoneId(),
                                Contract.TypeNames.ReportControllerInventoryReportRequestDefinition,
                                _.isNil(entityTypeEntitiesViewName),
                                entityTypeEntitiesViewName,
                                _.isEmpty(filterMap)
                                    ? undefined
                                    : JSON.stringify(filterMap));
                        await ExportReportHelper.downloadRemote(reportRequestDefinition);
                    },
                showLimitMessage: false
            }}
            fetchItems={
                async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
                    const propertyIdentifierRawValues =
                        new Set(
                            _.map(
                                propertyDefinitionIdentifiers,
                                propertyDefinitionIdentifier => propertyDefinitionIdentifier.raw));

                    sort ??= ({
                        columnId:
                            definition.options?.defaultSortColumnId ??
                            Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity,
                        direction: DataTableSortDirection.Descending
                    });

                    const request =
                        definition.getEntityModelRequest(
                            filterMap,
                            limit,
                            _.pickBy(
                                filterMap,
                                (_, key) => propertyIdentifierRawValues.has(key)),
                            new Contract.EntityControllerGetEntityModelPageRequestSort(
                                sort.direction === DataTableSortDirection.Ascending
                                    ? Contract.SortDirection.Ascending
                                    : Contract.SortDirection.Descending,
                                sort.columnId as Contract.EntityModelProperty),
                            skip);

                    async function getEntityModelSummary() {
                        const { entityModelSummary } = await EntityController.getEntityModelSummary(request);
                        return entityModelSummary;
                    }

                    const { entityModelPage } = await EntityController.getEntityModelPage(request);

                    if (!_.isNil(entityTypeEntitiesViewName)) {
                        await entityModelStore.notify(entityModelPage.items);
                    }

                    return new DataTableFetchItemsResult(
                        getEntityModelSummary,
                        entityModelPage.items,
                        !entityModelPage.hasMore);
                }}
            filtersOptions={{
                initial: {
                    state: filterMap
                },
                onChanged: filterMap => setFilterMap(filterMap),
                persist: {
                    visibilityStorageItem: StorageHelper.customerEntitiesViewFilters(entityTypeName, variant)
                }
            }}
            getItemId={(item: Contract.EntityModel) => item.id}
            highlightItem={{
                hashRouteTemplate: `${CustomerSideViewItemType.Entity}/{itemId}`
            }}
            rowOptions={{
                getUrl:
                    (item: Contract.EntityModel) =>
                        definition.options?.getRowItemUrl?.(item) ??
                        CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(
                            item,
                            {
                                category:
                                    StorageHelper.customerEntitiesProfileSelectedTab(EntityTypeMetadataModelHelper.get(item.entity.typeName).entitiesViewName).
                                        getValue() as ProfileCategory ?? ProfileCategory.Overview
                            })!
            }}
            summarySection={definition.options?.summarySection}
            variant={variant}
            virtualizationEnabled={true}
            onSortChanged={sort => sortRef.current = sort}>
            {UserHelper.hasScopePermissions(
                selectedScopeId,
                Contract.IdentityPermission.SecurityWrite) &&
                !_.isNil(entityTypeEntitiesViewName) &&
                <DataTableAction>
                    <ActionButton
                        size="medium"
                        tooltip={localization.actions.reportRedirect.tooltip()}
                        variant="outlined"
                        onClick={
                            () => {
                                trackAnalytics(
                                    AnalyticsEventActionType.ScheduleReportButtonClick,
                                    { "Type": "Entities" });
                                setReportData?.({
                                    entityTypeName,
                                    filterMap,
                                    open: true
                                });
                            }}>
                        <ReportsIcon/>
                    </ActionButton>
                </DataTableAction>}
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EntityModel) => ({
                            /* eslint-disable sort-keys-fix/sort-keys-fix */
                            Cloud: tenantTypeTranslator(EntityTypeMetadataModelHelper.get(item.entity.typeName).tenantType),
                            "Account ID": tenantModelMap[item.tenantId]?.configuration.displayReference ?? "",
                            "Account Name": tenantNameTranslator(item.tenantId)
                            /* eslint-enable sort-keys-fix/sort-keys-fix */
                        })
                }}
                id="generalInformation"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EntityModel) => ({
                            /* eslint-disable sort-keys-fix/sort-keys-fix */
                            Type: entityTypeNameTranslator(item.entity.typeName),
                            Id: item.entity.displayReference,
                            Name: item.entity.displayName
                            /* eslint-enable sort-keys-fix/sort-keys-fix */
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                emptyValueOptions={{ enabled: false }}
                                getEntityIdPage={ElasticsearchItemPageHelper.makePagedEntityTypeFilter(entityTypeName)}
                                placeholder={localization.entityColumnTitle()}/>
                    }
                }}
                id={Contract.EntityModelProperty.EntityId}
                key={Contract.EntityModelProperty.EntityId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                        <Entity
                            entityIdOrModel={item.id}
                            variant="iconText"/>}
                selectorOptions={{ disabled: true }}
                title={localization.entityColumnTitle()}/>
            {definition.additionalColumnElements}
            {!hideEntityPropertyColumns && TableHelper.getEntityPropertyColumnElements(entityTypeName, propertyDefinitionIdentifiers)}
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EntityModel) => ({
                            /* eslint-disable sort-keys-fix/sort-keys-fix */
                            URL: CustomerConsoleAppUrlHelper.getEntityProfileUrl(item, { category: ProfileCategory.Overview, view: ProfileView.Info }),
                            "Console URL": item.consoleUrl
                            /* eslint-enable sort-keys-fix/sort-keys-fix */
                        })
                }}
                id="urls"/>
        </DataTable>);
}

export type DefinitionData = {
    entityModelFiltersPromise: Promise<Contract.EntityModelFilters>;
    entityTypeEntitiesViewName: string;
    getFiltersTime: () => Optional<string>;
};

export type ExportData = {
    filterMap: Dictionary<any>;
    relatedEntityModelMap: Dictionary<Contract.EntityModel>;
};