import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, FeatureHelper, SidebarNavigationItem, SidebarNavigationItemSubItem, TenantHelper, useTenantTypeRenderer, WorkloadAnalysisIcon, WorkloadAnalysisViewIcon } from "../../../../../../common";
import { CustomerView } from "../../../../hooks";
import { WorkloadAnalysisView } from "../../../WorkloadAnalysis";

export function WorkloadAnalysisSidebarNavigationItem() {
    const localization =
        useLocalization(
            "views.customer.sidebar.workloadAnalysisSidebarNavigationItem",
            () => ({
                codeScans: "Container Image CI/CD Scans",
                containerImages: "Container Images",
                maliciousFiles: "Malicious Files",
                packages: "Software",
                title: "Workload",
                virtualMachineImages: "Virtual Machine Images",
                virtualMachines: "Virtual Machines",
                vulnerabilities: "Vulnerabilities"
            }));

    const tenantTypes =
        useMemo(
            () => [
                ...TenantHelper.WorkloadAnalysisTenantTypes,
                Contract.TenantType.Code
            ],
            []);
    const tenantTypeRenderer = useTenantTypeRenderer();

    return tenantTypeRenderer(
        TenantHelper.WorkloadAnalysisTenantTypes,
        () =>
            <SidebarNavigationItem
                icon={WorkloadAnalysisIcon}
                subItems={[
                    new SidebarNavigationItemSubItem(
                        <WorkloadAnalysisViewIcon view={WorkloadAnalysisView.VirtualMachines}/>,
                        localization.virtualMachines(),
                        WorkloadAnalysisView.VirtualMachines),
                    FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                        ? new SidebarNavigationItemSubItem(
                            <WorkloadAnalysisViewIcon view={WorkloadAnalysisView.VirtualMachineImages}/>,
                            localization.virtualMachineImages(),
                            WorkloadAnalysisView.VirtualMachineImages)
                        : null,
                    new SidebarNavigationItemSubItem(
                        <WorkloadAnalysisViewIcon view={WorkloadAnalysisView.ContainerImages}/>,
                        localization.containerImages(),
                        WorkloadAnalysisView.ContainerImages),
                    new SidebarNavigationItemSubItem(
                        <WorkloadAnalysisViewIcon view={WorkloadAnalysisView.Vulnerabilities}/>,
                        localization.vulnerabilities(),
                        WorkloadAnalysisView.Vulnerabilities),
                    new SidebarNavigationItemSubItem(
                        <WorkloadAnalysisViewIcon view={WorkloadAnalysisView.Packages}/>,
                        localization.packages(),
                        WorkloadAnalysisView.Packages),
                    new SidebarNavigationItemSubItem(
                        <WorkloadAnalysisViewIcon view={WorkloadAnalysisView.MaliciousFiles}/>,
                        localization.maliciousFiles(),
                        WorkloadAnalysisView.MaliciousFiles),
                    new SidebarNavigationItemSubItem(
                        <WorkloadAnalysisViewIcon view={WorkloadAnalysisView.CodeScans}/>,
                        localization.codeScans(),
                        WorkloadAnalysisView.CodeScans)
                ].filter(sidebarNavigationItemSubItem => sidebarNavigationItemSubItem) as SidebarNavigationItemSubItem[]}
                tenantTypes={tenantTypes}
                title={localization.title()}
                view={CustomerView.WorkloadAnalysis}/>,
        <SidebarNavigationItem
            icon={WorkloadAnalysisIcon}
            subItems={[
                new SidebarNavigationItemSubItem(
                    <WorkloadAnalysisViewIcon view={WorkloadAnalysisView.CodeScans}/>,
                    localization.codeScans(),
                    WorkloadAnalysisView.CodeScans)
            ]}
            tenantTypes={tenantTypes}
            title={localization.title()}
            view={CustomerView.WorkloadAnalysis}/>)!;
}