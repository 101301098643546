import { Link, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../../../../common";

export function CredentialItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useJenkinsPipeline.credentialItem",
            () => ({
                description: {
                    link: "Jenkins credentials",
                    title: "To protect Tenable Cloud Security's API token, it is recommended to use either {{JenkinsCredentialsLink}}, or an alternative secret store, if you are currently using one."
                },
                note: "Be aware that Jenkins credentials can be stored on multiple levels, depending on how you plan to use Tenable Cloud Security's scanning . It is recommended, but not mandatory, to only associate Tenable Cloud Security's token with pipelines linked to repositories with IaC code.",
                steps: {
                    step1: "Log into Jenkins (as a user with the Credentials > Create permission).",
                    step2: "From the home page, click **Manage Jenkins -> Manage Credentials**.",
                    step3: "Under **Stores scoped to Jenkins** on the right, click on **Jenkins (/System)**.",
                    step4: "Under **System**, click the **Global credentials (unrestricted)** link to access the default domain.",
                    step5: {
                        step1: "From the **Kind**, choose **Secret text**.",
                        step2: "From the **Scope** field, choose **Global**.",
                        step3: "Copy the **API token** created earlier in the wizard, and paste it in the **Secret** field.",
                        step4: "In the ID field, specify **tenable-api-token**.",
                        step5: "Add an optional **Description** for the token.",
                        step6: "Click **Create (/OK)** to save the credentials.",
                        title: "Click **Add Credentials** on the left (or **add some credentials** if none exist)."
                    },
                    title: "To add new global credentials to your Jenkins, follow these steps:"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: theme.spacing(58) }}>
            <Typography>
                {localization.description.title({
                    JenkinsCredentialsLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://www.jenkins.io/doc/book/using/using-credentials/"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <Typography>
                {localization.note()}
            </Typography>
            <Typography>
                {localization.steps.title()}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1()}
                {localization.steps.step2()}
                {localization.steps.step3()}
                {localization.steps.step4()}
                <Stack spacing={1}>
                    <Typography>
                        {localization.steps.step5.title()}
                    </Typography>
                    <Steps variant="plainNumbers">
                        {localization.steps.step5.step1()}
                        {localization.steps.step5.step2()}
                        {localization.steps.step5.step3()}
                        {localization.steps.step5.step4()}
                        {localization.steps.step5.step5()}
                        {localization.steps.step5.step6()}
                    </Steps>
                </Stack>
            </Steps>
        </Stack>);
}