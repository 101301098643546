import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PdfIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M17.4024 3.19538C16.8859 3.01424 16.2001 2.99997 14.318 2.99997C11.0833 2.99997 9.72476 3.01426 8.67029 3.3845C6.90692 4.00369 5.54034 5.30287 4.89869 6.92233C4.71848 7.37688 4.61182 7.92698 4.55646 8.76629C4.50051 9.6145 4.5 10.6873 4.5 12.182V14.727C4.5 16.2862 4.50162 17.3681 4.59914 18.1966C4.69389 19.0015 4.86794 19.4443 5.13059 19.7744C5.31575 20.0073 5.53359 20.2124 5.77716 20.3832L5.77918 20.3846C6.14633 20.6435 6.64341 20.8142 7.52067 20.9056C8.41377 20.9987 9.57552 21 11.227 21H15.5C16.0523 21 16.5 21.4477 16.5 22C16.5 22.5523 16.0523 23 15.5 23H11.1707C9.58846 23 8.32229 23 7.31333 22.8948C6.27705 22.7868 5.39244 22.5587 4.62783 22.02C4.22751 21.7391 3.86967 21.4021 3.5653 21.0194C2.98003 20.2836 2.7301 19.4264 2.61286 18.4304C2.49996 17.4713 2.49998 16.2713 2.5 14.7893L2.5 12.1462C2.5 10.695 2.49999 9.55639 2.56079 8.63465C2.62268 7.69654 2.75058 6.91401 3.03932 6.18562C3.90569 3.99917 5.72713 2.29824 8.0077 1.49745C9.42625 0.999373 11.1574 0.999584 14.0749 0.999952C14.155 0.999962 14.236 0.999972 14.318 0.999972C14.3886 0.999972 14.4582 0.999948 14.5268 0.999924C16.1229 0.999368 17.1836 0.998998 18.0652 1.3084C19.4781 1.80507 20.6167 2.86247 21.1602 4.23251C21.3493 4.70774 21.4273 5.20512 21.4642 5.76254C21.5 6.3036 21.5 6.9663 21.5 7.78388V9.99997C21.5 10.5523 21.0523 11 20.5 11C19.9477 11 19.5 10.5523 19.5 9.99997V7.81797C19.5 6.95824 19.4995 6.36185 19.4686 5.89466C19.4382 5.43643 19.3817 5.17239 19.3019 4.9717L19.3014 4.97056C18.9829 4.16716 18.2989 3.51067 17.4024 3.19538Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M3.5 13C2.94772 13 2.5 12.5523 2.5 12C2.5 10.8508 2.95651 9.7487 3.76911 8.93611C4.5817 8.12351 5.68382 7.667 6.833 7.667C7.02509 7.667 7.21986 7.67533 7.39682 7.68348C7.41955 7.68453 7.44198 7.68557 7.46415 7.6866C7.62146 7.69393 7.76577 7.70064 7.90959 7.70358C8.24344 7.71042 8.48767 7.69332 8.67217 7.64408C8.78521 7.61351 8.88826 7.55386 8.97106 7.47106C9.0539 7.38823 9.11356 7.28512 9.14411 7.17204C9.19333 6.98754 9.21042 6.74336 9.20359 6.40959C9.20064 6.26576 9.19393 6.12146 9.18661 5.96415C9.18557 5.94198 9.18453 5.91955 9.18348 5.89682C9.17533 5.71986 9.167 5.52509 9.167 5.333C9.167 4.76398 9.27908 4.20054 9.49683 3.67483C9.71459 3.14913 10.0338 2.67146 10.4361 2.26911C10.8385 1.86675 11.3161 1.54758 11.8418 1.32983C12.3675 1.11208 12.931 1 13.5 1C14.0523 1 14.5 1.44772 14.5 2C14.5 2.55229 14.0523 3 13.5 3C13.1936 3 12.8903 3.06035 12.6072 3.17759C12.3241 3.29483 12.067 3.46668 11.8503 3.68332C11.6337 3.89996 11.4618 4.15715 11.3446 4.4402C11.2273 4.72325 11.167 5.02663 11.167 5.333C11.167 5.47391 11.1732 5.62702 11.1814 5.80477C11.1823 5.82597 11.1833 5.84757 11.1844 5.86952C11.1916 6.02441 11.1996 6.19675 11.2032 6.36867C11.2111 6.75525 11.1997 7.22688 11.0761 7.68931L11.0757 7.6907C10.9542 8.1425 10.7161 8.55445 10.3853 8.88527C10.0545 9.2161 9.64251 9.45419 9.1907 9.57569L9.18931 9.57606C8.72688 9.69971 8.25525 9.71108 7.86867 9.70317C7.69676 9.69965 7.52443 9.6916 7.36955 9.68437C7.34759 9.68335 7.32598 9.68234 7.30477 9.68136C7.12702 9.67317 6.97391 9.667 6.833 9.667C6.21425 9.667 5.62084 9.9128 5.18332 10.3503C4.7458 10.7878 4.5 11.3813 4.5 12C4.5 12.5523 4.05229 13 3.5 13Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M16.9997 18.325V13.4061C16.9997 12.7566 17.5263 12.23 18.1759 12.23H20.7997C21.2581 12.23 21.6297 12.6016 21.6297 13.06C21.6297 13.5184 21.2581 13.89 20.7997 13.89H18.7317V14.948H20.5657C21.0241 14.948 21.3957 15.3196 21.3957 15.778C21.3957 16.2364 21.0241 16.608 20.5657 16.608H18.7497V18.325C18.7497 18.8082 18.358 19.2 17.8747 19.2C17.3915 19.2 16.9997 18.8082 16.9997 18.325Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M12.9 19.2H14.033C14.5145 19.2 14.9432 19.108 15.3129 18.9166L15.3141 18.916C15.6871 18.7197 15.9791 18.4455 16.1849 18.0941C16.3976 17.7413 16.501 17.336 16.501 16.885V14.536C16.501 14.0799 16.398 13.674 16.1845 13.3261C15.9785 12.9747 15.686 12.7033 15.3123 12.513C14.9428 12.3218 14.5142 12.23 14.033 12.23H12.9C12.2372 12.23 11.7 12.7672 11.7 13.43V18C11.7 18.6627 12.2372 19.2 12.9 19.2ZM14.033 17.54H13.45V13.89H14.033C14.2694 13.89 14.4391 13.9556 14.5629 14.0705C14.6858 14.1847 14.751 14.3335 14.751 14.536V16.885C14.751 17.0951 14.6848 17.2462 14.5629 17.3594C14.4391 17.4743 14.2694 17.54 14.033 17.54Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11.0123 15.7208L11.0132 15.7193C11.2261 15.3667 11.33 14.9641 11.33 14.518C11.33 14.067 11.2267 13.664 11.0131 13.3164C10.8062 12.9699 10.51 12.702 10.1311 12.5124C9.75594 12.3217 9.32188 12.23 8.835 12.23H6.65905C6.51267 12.23 6.394 12.3486 6.394 12.495V18.325C6.394 18.8082 6.78575 19.2 7.269 19.2C7.75225 19.2 8.144 18.8082 8.144 18.325V16.806H8.835C9.32216 16.806 9.75643 16.7142 10.1317 16.5232C10.5049 16.3334 10.8001 16.0657 11.0123 15.7208ZM8.835 13.845C9.084 13.845 9.25774 13.9139 9.38017 14.0319L9.38549 14.0369C9.51145 14.1498 9.58 14.3026 9.58 14.518C9.58 14.7332 9.51155 14.8906 9.38291 15.0104C9.26073 15.1239 9.08601 15.191 8.835 15.191H8.144V13.845H8.835Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}