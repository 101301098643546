import { Optional, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, RadioGroup, RadioGroupItem, RiskStatusIcon, useTheme } from "../../../../../../../../../../../../../../../../../common";
import { SyncOptionType } from "../../../AddOrEditProject";
import { MapTable } from "../../MapTable";
import { riskStatuses } from "../SyncIssueStatusesItem";

type StatusesMapTableProps = {
    disabled: boolean;
    issueTypeDeleted: boolean;
    onStatusChange: (findingStatus: string, jiraStatus: Contract.JiraStatus) => void;
    onSyncOptionTypeChange: (syncOption: SyncOptionType) => void;
    riskStatusToJiraStatusErrorMap?: _.Dictionary<Optional<Contract.JiraStatus>>;
    riskStatusToJiraStatusMap: _.Dictionary<Optional<Contract.JiraStatus>>;
    selectedSyncOption: SyncOptionType;
    statusesMap: Contract.JiraStatus[];
};

export function StatusesMapTable({ disabled, issueTypeDeleted, onStatusChange, onSyncOptionTypeChange, riskStatusToJiraStatusErrorMap, riskStatusToJiraStatusMap, selectedSyncOption, statusesMap }: StatusesMapTableProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEditProject.syncIssueStatusesItem.statusesMapTable",
            () => ({
                findingStatusTitle: "Finding Status",
                issueTypeDeletedNote: "Issue type was deleted from your Jira project, which might affect your automations and findings. Please review your Jira settings.",
                issueTypeTitle: "Issue Type",
                jiraIssueStatusTitle: "Jira Issue Status",
                jiraStatusErrorMessage: "Jira status doesn't exist",
                options: {
                    doNotSync: "Do not sync",
                    syncWithCustomMapping: "Sync using custom mapping"
                }
            }));

    const radioGroupItems =
        useMemo(
            (): RadioGroupItem<SyncOptionType>[] => [
                {
                    label: localization.options.doNotSync(),
                    value: SyncOptionType.None
                },
                {
                    children:
                        <MapTable
                            getErrorMessage={
                                riskStatus =>
                                    issueTypeDeleted || _.isEmpty(riskStatusToJiraStatusErrorMap?.[riskStatus])
                                        ? undefined
                                        : localization.jiraStatusErrorMessage()}
                            itemSelectorProps={{
                                disabled: disabled || issueTypeDeleted,
                                items: statusesMap
                            }}
                            itemSelectorRenderer={(item: Contract.JiraStatus) => item.name}
                            keyRenderer={riskStatus => <RiskStatusTitle riskStatus={riskStatus as Contract.RiskStatus}/>}
                            keys={riskStatuses}
                            keysToSelectedItems={riskStatusToJiraStatusMap}
                            keyTitle={localization.findingStatusTitle()}
                            valueTitle={localization.jiraIssueStatusTitle()}
                            onItemSelected={(riskStatus, jiraStatus) => onStatusChange(riskStatus, jiraStatus)}/>,
                    label: localization.options.syncWithCustomMapping(),
                    value: SyncOptionType.Custom
                }
            ],
            [statusesMap, riskStatusToJiraStatusMap, disabled, selectedSyncOption]);

    const theme = useTheme();
    return (
        <Box
            sx={{
                borderLeft: theme.border.primary,
                height: "100%",
                minHeight: theme.spacing(40),
                padding: theme.spacing(2),
                width: "95%"
            }}>
            <RadioGroup
                disabled={disabled || issueTypeDeleted}
                items={radioGroupItems}
                selectedValue={selectedSyncOption}
                sx={{ marginBottom: 0 }}
                onChange={value => onSyncOptionTypeChange(value)}/>
            {issueTypeDeleted &&
                <Box
                    sx={{
                        backgroundColor: theme.palette.input.filledBackground,
                        borderRadius: 2,
                        color: theme.palette.error.main,
                        display: "flex",
                        marginBottom: theme.spacing(2),
                        padding: theme.spacing(1.5)
                    }}>
                    <Typography>
                        {localization.issueTypeDeletedNote()}
                    </Typography>
                </Box>}
        </Box>);
}

type RiskStatusTitleProps = {
    riskStatus: Contract.RiskStatus;
};

function RiskStatusTitle({ riskStatus }: RiskStatusTitleProps) {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            flexDirection="row">
            <RiskStatusIcon
                status={riskStatus}
                style={{
                    fontSize: "16px",
                    marginRight: theme.spacing(1)
                }}/>
            <Typography sx={{ fontSize: "12px" }}>
                {riskStatus}
            </Typography>
        </Box>);
}