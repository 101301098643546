import { InlineItems, useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useCloudProviderTenantPermissionTypesTranslator } from "../../../../../../../../../common";

export function useAwsOrganizationAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const cloudProviderTenantPermissionTypesTranslator = useCloudProviderTenantPermissionTypesTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAwsOrganizationAuditEventDefinition",
            () => ({
                enabled: {
                    false: "Off",
                    title: "Onboard new accounts",
                    true: "On"
                },
                folderEnabled: {
                    false: "Off",
                    title: "Folder structure sync",
                    true: "On"
                },
                masterTenantName: "Management account name",
                masterTenantRawId: "Management account ID",
                name: "Organization name",
                organizationRawId: "Organization ID",
                permissionTypes: "Permissions",
                propertyChanges: "Updated properties",
                regionSystemNames: {
                    all: "all regions",
                    some: [
                        "1 region",
                        "{{count | NumberFormatter.humanize}} regions"
                    ],
                    title: "Regions"
                },
                roleName: "Role name",
                trailName: "Trail name"
            }));

    const organizationAuditEvent = context.auditEventModel.auditEvent as Contract.AwsOrganizationAuditEvent;
    const auditEventData =
        organizationAuditEvent.typeName === Contract.TypeNames.AwsOrganizationCreationAuditEvent ||
        organizationAuditEvent.typeName === Contract.TypeNames.AwsOrganizationUpdateAuditEvent
            ? (organizationAuditEvent as Contract.AwsOrganizationCreationAuditEvent | Contract.AwsOrganizationUpdateAuditEvent).data
            : undefined;

    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.name(),
                    organizationAuditEvent.name),
                new AuditEventDefinitionDetailItem(
                    localization.organizationRawId(),
                    organizationAuditEvent.organizationRawId)).
            concatIf(
                !_.isNil(organizationAuditEvent.masterTenantName),
                new AuditEventDefinitionDetailItem(
                    localization.masterTenantName(),
                    organizationAuditEvent.masterTenantName!)).
            concatIf(
                !_.isNil(organizationAuditEvent.masterTenantRawId),
                new AuditEventDefinitionDetailItem(
                    localization.masterTenantRawId(),
                    organizationAuditEvent.masterTenantRawId!)).
            concatIf(
                !_.isNil(auditEventData),
                () => {
                    const data = auditEventData!;
                    return [
                        new AuditEventDefinitionDetailItem(
                            localization.regionSystemNames.title(),
                            _.isNil(data.regionSystemNames)
                                ? localization.regionSystemNames.all()
                                : localization.regionSystemNames.some(data.regionSystemNames!.length),
                            _.isNil(data.regionSystemNames)
                                ? undefined
                                : <InlineItems
                                    items={data.regionSystemNames!}
                                    namePluralizer={localization.regionSystemNames.some}
                                    variant="itemCountAndType"/>),
                        new AuditEventDefinitionDetailItem(
                            localization.permissionTypes(),
                            _.join(
                                cloudProviderTenantPermissionTypesTranslator.multiple(
                                    data.permissionTypes,
                                    "shortName"),
                                ",")),
                        new AuditEventDefinitionDetailItem(
                            localization.enabled.title(),
                            data.enabled
                                ? localization.enabled.true()
                                : localization.enabled.false()),
                        new AuditEventDefinitionDetailItem(
                            localization.folderEnabled.title(),
                            data.folderEnabled
                                ? localization.folderEnabled.true()
                                : localization.folderEnabled.false()),
                        new AuditEventDefinitionDetailItem(
                            localization.roleName(),
                            data.roleName)
                    ];
                }).
            concatIf(
                !_.isNil(auditEventData?.trailName),
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.trailName(),
                        auditEventData!.trailName!)).
            concatIf(
                organizationAuditEvent.typeName === Contract.TypeNames.AwsOrganizationUpdateAuditEvent,
                () => {
                    const organizationUpdateAuditEvent = organizationAuditEvent as Contract.AwsOrganizationUpdateAuditEvent;
                    const properties =
                        _.filter([
                            organizationUpdateAuditEvent.enabledChanged
                                ? localization.enabled.title()
                                : undefined,
                            organizationUpdateAuditEvent.folderEnabledChanged
                                ? localization.folderEnabled.title()
                                : undefined,
                            organizationUpdateAuditEvent.nameChanged
                                ? localization.name()
                                : undefined,
                            organizationUpdateAuditEvent.permissionTypesChanged
                                ? localization.permissionTypes()
                                : undefined,
                            organizationUpdateAuditEvent.regionSystemNamesChanged
                                ? localization.regionSystemNames.title()
                                : undefined,
                            organizationUpdateAuditEvent.roleNameChanged
                                ? localization.roleName()
                                : undefined,
                            organizationUpdateAuditEvent.trailNameChanged
                                ? localization.trailName()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}