import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpComputeBackendServiceRiskContext } from "../contexts";

export function useGcpComputeBackendServiceLoggingNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeBackendServiceLoggingNotEnabledRisk;
    const backendServiceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeBackendServiceModel;

    const getGcpComputeBackendServiceRiskContext = useGetGcpComputeBackendServiceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeBackendServiceLoggingNotEnabledRiskDefinition",
            () => ({
                description: "{{backendService}} logging is not enabled",
                sections: {
                    resolution: {
                        step1: "Enabling the logging on the backend service cannot be done directly, but only through the load balancer that uses it",
                        step2: "Next to **In use by**, select one of the load balancers",
                        step3: "Click **EDIT** then **Backend configuration**",
                        step4: "Select **Edit** on the corresponding backend service",
                        step5: "Ensure that **Enable Logging** is selected. Also ensure that **Sample Rate** is set to an appropriate level for your needs",
                        step6: "Click **UPDATE**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            backendService:
                <Entity
                    entityIdOrModel={backendServiceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const backendServiceRiskContext = getGcpComputeBackendServiceRiskContext(backendServiceModel);
            return [
                backendServiceRiskContext.generalInformation,
                backendServiceRiskContext.sensitive,
                backendServiceRiskContext.loadBalancers,
                backendServiceRiskContext.sensitiveLoadBalancers,
                backendServiceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}