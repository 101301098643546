import { Action1, AnalyticsEventActionType, ColumnHeader, DataTableFilterExternalOptions, DataTableSort, DataTableSortDirection, useTrackAnalytics } from "@infrastructure";
import { Grid } from "@mui/material";
import _ from "lodash";
import React, { memo, useCallback } from "react";
import { RowHeaderColumn } from ".";
import { GroupedTableColumn } from "../GroupedTable";

const RowHeaderMemo = memo(RowHeader);
export { RowHeaderMemo as RowHeader };

export type GroupedTableSortOptions = {
    onClick: Action1<DataTableSort | undefined>;
    sort: DataTableSort | undefined;
};

type RowHeaderProps = {
    columns: GroupedTableColumn[];
    filterExternalOptions?: DataTableFilterExternalOptions;
    sortOptions: GroupedTableSortOptions;
};

function RowHeader({ columns, filterExternalOptions, sortOptions }: RowHeaderProps) {
    const trackAnalytics = useTrackAnalytics();
    const onFilterClick =
        useCallback(
            (column: GroupedTableColumn) => () => {
                const { filterExternalId: filterId, title } = column;

                if (!filterExternalOptions?.actionsRef.current || !filterId) {
                    return;
                }

                filterExternalOptions.actionsRef.current?.add(filterId);
                trackAnalytics(
                    AnalyticsEventActionType.FilterValueOpen,
                    {
                        "Column Name": title,
                        Source: "Column"
                    });
            },
            [filterExternalOptions]);
    const createColumnFilterExternalOptions =
        useCallback(
            (column: GroupedTableColumn) => (
                filterExternalOptions && !_.isNil(column.filterExternalId)
                    ? {
                        active: _.includes(filterExternalOptions.active, column.filterExternalId),
                        onClick: onFilterClick(column),
                        visible: _.includes(filterExternalOptions.visible, column.filterExternalId)
                    }
                    : undefined),
            [filterExternalOptions, onFilterClick]);

    const createSortOptions =
        useCallback(
            (column: GroupedTableColumn) => (
                !_.isNil(column.sortOptions)
                    ? {
                        direction:
                            sortOptions.sort?.columnId === column.id
                                ? sortOptions.sort.direction
                                : undefined,
                        enabled: true,
                        onClick:
                            (direction: DataTableSortDirection) =>
                                sortOptions.onClick({
                                    columnId: column.id,
                                    direction
                                }),
                        type: column.sortOptions.type
                    }
                    : undefined),
            [sortOptions]);

    return (
        <Grid
            alignItems="center"
            container={true}>
            {_.map(
                columns,
                column =>
                    <Grid
                        item={true}
                        key={`${column.id}-header`}
                        xs={column.width}>
                        {(filterExternalOptions && column.filterExternalId) ||
                        (sortOptions && column.sortOptions)
                            ? <RowHeaderColumn
                                filterExternalOptions={createColumnFilterExternalOptions(column)}
                                sortOptions={createSortOptions(column)}
                                title={column.title}/>
                            : <ColumnHeader
                                containerSx={{ padding: 0 }}
                                title={column.title}/>}
                    </Grid>
            )}
        </Grid>);
}