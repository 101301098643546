import { SelectionView, usePagedValuesFilterContext } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

type EmptyValueOptions = {
    emptyValue?: boolean;
    title?: string;
};

type SelectionViewProps = {
    emptyValueOptions: EmptyValueOptions;
    label: string;
    loaded: boolean;
    renderValue?: (inline: boolean, value: any) => ReactNode;
    selectedEntities?: string[];
    totalCount: number;
    type?: "include" | "exclude";
};

export function PagedSelectionView({ emptyValueOptions, label, loaded, renderValue, selectedEntities, totalCount, type = "include" }: SelectionViewProps) {
    const context = usePagedValuesFilterContext();
    const [selectedValues, setSelectedValues] = useState<unknown[] | undefined>(selectedEntities);

    useEffect(
        () => {
            if (!selectedEntities) {
                setSelectedValues(selectedEntities);
            } else {
                context.
                    getValuesData(selectedEntities).
                    then(
                        valuesData => {
                            const newValueData =
                                emptyValueOptions.emptyValue
                                    ? [undefined, ..._.values(valuesData)]
                                    : _.values(valuesData);
                            setSelectedValues(newValueData);
                        });
            }
        },
        [context, emptyValueOptions, selectedEntities]);

    const empty =
        useMemo(
            () =>
                _.isNil(selectedValues) ||
                selectedValues.length === 0 &&
                type === "include",
            [type, selectedValues]);

    return (
        <SelectionView
            empty={empty}
            emptyValueTitle={emptyValueOptions.title}
            label={label}
            loaded={loaded}
            renderValue={renderValue}
            selectedValues={selectedValues}
            totalCount={
                totalCount +
                (_.isBoolean(emptyValueOptions.emptyValue)
                    ? 1
                    : 0)}
            type={type}/>);
}