import moment from "moment";

export class TimeSpanFormatter {
    public static day(time: moment.DurationInputArg1) {
        return moment.
            duration(time).
            format("d [days]");
    }

    public static dayHour(time: moment.DurationInputArg1) {
        return moment.
            duration(time).
            format("d [days], h [hours]", { trim: "both" });
    }

    public static duration(time: moment.DurationInputArg1) {
        return moment.duration(time, "d");
    }

    public static durationAsDays(timeSpan: moment.DurationInputArg1) {
        return moment.
            duration(timeSpan, "d").
            asDays();
    }

    public static dayHourMinuteSecond(time: moment.DurationInputArg1) {
        return moment.
            duration(time).
            format("d [days], h [hours], m [minutes], s [seconds]", { trim: "both" });
    }

    public static humanize(timeSpan: moment.DurationInputArg1) {
        return moment.
            duration(timeSpan).
            humanize();
    }

    public static humanizeDays(timeSpan: moment.DurationInputArg1) {
        return moment.
            duration(timeSpan).
            humanize({ d: Number.MAX_SAFE_INTEGER });
    }

    public static humanizeSeconds(timeSpan: moment.DurationInputArg1) {
        return moment.
            duration(timeSpan, "s").
            humanize({ s: Number.MAX_SAFE_INTEGER, ss: 1 });
    }
}