import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpIamWorkforcePoolProviderTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpIamWorkforcePoolProviderTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpIamWorkforcePoolProviderType]: {
                    [Contract.GcpIamWorkforcePoolProviderType.Oidc]: "OIDC",
                    [Contract.GcpIamWorkforcePoolProviderType.Saml]: "SAML"
                }
            }));
    return (type: Contract.GcpIamWorkforcePoolProviderType) =>
        localization[Contract.TypeNames.GcpIamWorkforcePoolProviderType][type]();
}