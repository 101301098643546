import { AutocompleteItems, DropdownIcon, Link, StringHelper, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { Contract, CustomerConsoleAppUrlHelper, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function GciTenantItem() {
    const gciTenantModels = tenantModelStore.useGetGciTenants();
    const { gciTenantModel, organizationConfiguration } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const orderedNonOrganizationGciTenantModels =
        useMemo(
            () =>
                _(gciTenantModels).
                    filter(
                        gciTenantModel =>
                            gciTenantModel.status === Contract.GciTenantModelStatus.Valid &&
                            _.isNil(gciTenantModel.configuration.gcpOrganizationId)).
                    orderBy(gciTenantModel => StringHelper.getSortValue(gciTenantModel.configuration.name)).
                    value(),
            [gciTenantModels]);

    const [selectedGciTenantModel, setSelectedGciTenantModel] = useState<Contract.GciTenantModel | undefined>(gciTenantModel);

    const { executing, setLoaded, setValid } = useOrderedWizardContext();
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.gciTenantItem",
            () => ({
                fields: {
                    gciTenant: {
                        empty: "No Google Workspaces",
                        error: {
                            required: "You must choose a Google Workspace"
                        },
                        subtitle: {
                            clickHere: "click here",
                            text: "Select the Google Workspace linked to the organization you want to add. If the Workspace doesn’t appear in the dropdown, {{clickHere}} to add it before you proceed to add your organization"
                        },
                        title: "Google Workspace"
                    }
                }
            }));

    const [gciTenantModelValidationController, gciTenantModelValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(selectedGciTenantModel)) {
                    return localization.fields.gciTenant.error.required();
                }

                return undefined;
            },
            [selectedGciTenantModel]);

    useEffect(
        () => {
            if (selectedGciTenantModel !== gciTenantModel) {
                setAddOrEditContext(
                    context => ({
                        ...context,
                        gciTenantModel: selectedGciTenantModel,
                        name: selectedGciTenantModel?.configuration.name
                    }));
            }

            setValid(gciTenantModelValidationController.isValid());
        },
        [selectedGciTenantModel]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Stack spacing={3}>
                <Typography>
                    {localization.fields.gciTenant.subtitle.text({
                        clickHere:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsGciRelativeUrl(true)}>
                                {localization.fields.gciTenant.subtitle.clickHere()}
                            </Link>
                    })}
                </Typography>
                <Box>
                    <AutocompleteItems
                        disableClearable={true}
                        disabled={executing || !_.isNil(organizationConfiguration)}
                        fullWidth={true}
                        getOptionLabel={gciTenantModel => gciTenantModel.configuration.name}
                        noOptionsText={localization.fields.gciTenant.empty()}
                        options={orderedNonOrganizationGciTenantModels}
                        popupIcon={
                            <DropdownIcon
                                sx={{
                                    color:
                                        executing
                                            ? theme.palette.text.disabled
                                            : theme.palette.text.hint
                                }}/>}
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    label={localization.fields.gciTenant.title()}
                                    variant="outlined"/>)}
                        value={selectedGciTenantModel}
                        onChange={(_event, gciTenantModel) => setSelectedGciTenantModel(gciTenantModel)}>
                        {gciTenantModel =>
                            <Stack>
                                <Typography>
                                    {gciTenantModel.configuration.name}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {gciTenantModel.configuration.rawId}
                                </Typography>
                            </Stack>}
                    </AutocompleteItems>
                    {!_.isNil(gciTenantModelValidationMessage) &&
                        <FormHelperText error={true}>{gciTenantModelValidationMessage}</FormHelperText>}
                </Box>
            </Stack>
        </Stack>);
}