import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "../contexts/useGetOciResourceRiskContext";

export function useOciKmsVaultKeyNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciKmsVaultKeyNotRotatedRisk;
    const keyModel = entityModelStore.useGet(risk.entityId) as Contract.OciKmsVaultKeyModel;

    const getOciResourceRiskContext = useGetOciResourceRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciKmsVaultKeyNotRotatedRiskDefinition",
            () => ({
                contextItems: {
                    keyRotation: "The vault master encryption key latest version was issued more than {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}} ago"
                },
                description: "{{key}} has a version that has not been rotated for more than {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                sections: {
                    resolution: {
                        step1: "Cryptographic operations involving objects that were encrypted with a previous version of this key will continue to use the older key version",
                        step2: "Click **Rotate Key** and then **Rotate Key**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            key:
                <Entity
                    entityIdOrModel={keyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            keyRotationTimeFrame: risk.keyRotationTimeFrame
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.Kms,
                keyModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const ociResourceRiskContext = getOciResourceRiskContext(keyModel);
            return [
                ociResourceRiskContext.generalInformation,
                new RiskDefinitionContextItem(
                    localization.contextItems.keyRotation(
                        {
                            keyRotationTimeFrame: risk.keyRotationTimeFrame
                        })),
                ociResourceRiskContext.sensitive,
                ociResourceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}