import { CheckButton, Optional, SearchTextField, useExecuteOperation, useLocalization, usePolling } from "@infrastructure";
import { Stack } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AdministrationController, Contract, useTheme } from "../../../../../../common";
import { Item } from "./components";

export function childStageDatasExists(orchestrationStageData: Contract.AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData): orchestrationStageData is Contract.AdministrationControllerGetOrchestrationStageDatasResponseStagesOrchestrationStageData {
    return orchestrationStageData.type === Contract.AdministrationControllerOrchestrationStageType.Stages ||
        orchestrationStageData.type === Contract.AdministrationControllerOrchestrationStageType.Root;
}

function filterNotApplicableStageDatas(orchestratorOrchestrationStageDatas: Contract.AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData[]): Contract.AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData[] {
    return _.reduce(
        orchestratorOrchestrationStageDatas,
        (filteredOrchestratorOrchestrationStageData, orchestrationStageData) => {
            if (orchestrationStageData.status === Contract.AdministrationControllerOrchestrationStageStatus.NotApplicable) {
                return filteredOrchestratorOrchestrationStageData;
            }

            if (childStageDatasExists(orchestrationStageData)) {
                const root = _.clone(orchestrationStageData);
                root.childStageDatas = filterNotApplicableStageDatas(root.childStageDatas);
                return [...filteredOrchestratorOrchestrationStageData, root];
            }

            return [...filteredOrchestratorOrchestrationStageData, orchestrationStageData];
        },
        [] as Contract.AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData[]);
}

function getExpandedStageId(
    orchestratorOrchestrationStageDatas: Contract.AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData[],
    predicate: (orchestrationStageData: Contract.AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData) => boolean): string[] {
    return _.reduce(
        orchestratorOrchestrationStageDatas,
        (stageIds, orchestrationStageData) => {
            if (!childStageDatasExists(orchestrationStageData)) {
                return predicate(orchestrationStageData)
                    ? [...stageIds, orchestrationStageData.id]
                    : stageIds;
            }

            const expandedChildStageIds =
                getExpandedStageId(
                    orchestrationStageData.childStageDatas,
                    predicate);

            return _.isEmpty(expandedChildStageIds)
                ? stageIds
                : [...stageIds, orchestrationStageData.id, ...expandedChildStageIds];
        },
        [] as string[]);
}

export function Orchestration() {
    const [{ rootStageDatas }, executeGetOrchestrationStageDatas] =
        useExecuteOperation(
            Orchestration,
            AdministrationController.getOrchestrationStageDatas);

    const [autoExpandEnabled, setAutoExpandEnabled] = useState(true);
    const [expandedStageIds, setExpandedStageIds] = useState<string[]>([]);
    const [filterNotApplicable, setFilterNotApplicable] = useState(true);
    const [pollingEnabled, setPollingEnabled] = useState(true);
    const [searchText, setSearchText] = useState<Optional<string>>(undefined);

    usePolling(
        executeGetOrchestrationStageDatas,
        {
            enabled: pollingEnabled,
            interval: 10000
        });

    const filteredRootStageDatas =
        useMemo(
            () =>
                filterNotApplicable
                    ? filterNotApplicableStageDatas(rootStageDatas)
                    : rootStageDatas,
            [rootStageDatas, filterNotApplicable]);

    const runningStageIds =
        useMemo(
            () =>
                autoExpandEnabled
                    ? getExpandedStageId(
                        filteredRootStageDatas,
                        orchestrationStageData => orchestrationStageData.status === Contract.AdministrationControllerOrchestrationStageStatus.Running)
                    : expandedStageIds,
            [autoExpandEnabled, filteredRootStageDatas]);
    const searchTextStageIds =
        useMemo(
            () =>
                !_.isNil(searchText)
                    ? getExpandedStageId(
                        filteredRootStageDatas,
                        orchestrationStageData =>
                            _.includes(
                                orchestrationStageData.name.toLowerCase(),
                                searchText?.toLowerCase()))
                    : [],
            [searchText, filteredRootStageDatas]);
    useEffect(
        () =>
            setExpandedStageIds(
                _.union(
                    runningStageIds,
                    searchTextStageIds)),
        [runningStageIds, searchTextStageIds]);

    const onTreeItemClick =
        useCallback(
            (stageId: string) => {
                setExpandedStageIds(
                    expandedStageIds =>
                        _.includes(expandedStageIds, stageId)
                            ? _.without(expandedStageIds, stageId)
                            : _.concat(expandedStageIds, stageId));
            },
            []);

    const localization =
        useLocalization(
            "views.customer.administration.orchestration.orchestration",
            () => ({
                collapse: "Collapse All",
                expand: "Expand All",
                expandEnabled: "Auto Expand",
                filterNotApplicable: "Filter Not Applicable",
                pollingEnabled: "Live Refresh",
                pollingInterval: "Refresh Interval (Seconds)",
                search: "Search"
            }));
    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{
                height: "100%",
                overflow: "hidden"
            }}>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                    borderBottom: theme.border.primary,
                    padding: theme.spacing(1, 2)
                }}>
                <CheckButton
                    checked={false}
                    title={localization.expand()}
                    onCheckedChanged={
                        () =>
                            setExpandedStageIds(
                                getExpandedStageId(
                                    filteredRootStageDatas,
                                    _ => true))}/>
                <CheckButton
                    checked={false}
                    title={localization.collapse()}
                    onCheckedChanged={() => setExpandedStageIds([])}/>
                <CheckButton
                    checked={autoExpandEnabled}
                    title={localization.expandEnabled()}
                    onCheckedChanged={() => setAutoExpandEnabled(autoExpandEnabled => !autoExpandEnabled)}/>
                <CheckButton
                    checked={pollingEnabled}
                    title={localization.pollingEnabled()}
                    onCheckedChanged={() => setPollingEnabled(pollingEnabled => !pollingEnabled)}/>
                <CheckButton
                    checked={filterNotApplicable}
                    title={localization.filterNotApplicable()}
                    onCheckedChanged={() => setFilterNotApplicable(filterNotApplicable => !filterNotApplicable)}/>
                <SearchTextField
                    fullWidth={false}
                    placeholder={localization.search()}
                    searchText={searchText}
                    variant="underlined"
                    onSearchTextChanged={setSearchText}/>
            </Stack>
            <SimpleTreeView
                disabledItemsFocusable={true}
                disableSelection={true}
                expandedItems={expandedStageIds}
                sx={{
                    flex: 1,
                    overflow: "scroll"
                }}>
                {_.map(
                    filteredRootStageDatas,
                    (filteredRootStageData, index) =>
                        <Item
                            expandedStageIds={expandedStageIds}
                            key={index}
                            level={0}
                            orchestrationStageData={filteredRootStageData}
                            searchText={searchText}
                            onClick={onTreeItemClick}/>
                )}
            </SimpleTreeView>
        </Stack>);
}