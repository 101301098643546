import { map } from "@infrastructure";
import React from "react";
import { Contract } from "../../../..";
import { Automatic, BuiltIn, Manual } from "./components";

export type EntityAttributeProps = {
    entityAttribute: Contract.EntityAttribute;
    entityCount: number;
    entityTypeName: string;
};

export function EntityAttribute({ entityAttribute, entityCount, entityTypeName }: EntityAttributeProps) {
    const Component =
        map(
            entityAttribute.typeName,
            {
                [Contract.TypeNames.AutomaticCustomEntityAttribute]: () => Automatic,
                [Contract.TypeNames.ManualCustomEntityAttribute]: () => Manual
            },
            () => BuiltIn);
    return (
        <Component
            entityAttribute={entityAttribute}
            entityCount={entityCount}
            entityTypeName={entityTypeName}/>);
}