import { AddIcon, CheckButton, EmptyMessage, useLocalization } from "@infrastructure";
import { Box, Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { JiraAddOrEditType, useSetJiraContext } from "../..";
import { scopeNodeModelStore, scopeSystemEntityModelStore, StorageHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../common";
import { useIntegrationsContext, useSetIntegrationsContext } from "../../../../../../../../Integrations";
import { Instance } from "./components";

export function List() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { childScopeEnabled } = useIntegrationsContext();
    const setIntegrationsContext = useSetIntegrationsContext();
    const setJiraContext = useSetJiraContext();
    const instanceModels = scopeSystemEntityModelStore.useGetJira();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);

    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const filteredInstanceModels =
        useMemo(
            () =>
                _.filter(
                    instanceModels,
                    instanceModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            concatIf(
                                childScopeEnabled,
                                childScopeIds).
                            includes(instanceModel.configuration.scopeId)),
            [childScopeEnabled, childScopeIds, instanceModels, parentScopeIds]);
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.list",
            () => ({
                actions: {
                    add: "Add Jira Instance",
                    childScopeEnabled: "Flat View"
                },
                empty: "No Jira instances",
                jiraInstances: [
                    "1 Jira instance",
                    "{{count | NumberFormatter.humanize}} Jira instances"
                ]
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="stretch"
            spacing={0.5}
            sx={{
                height: "100%",
                overflow: "hidden auto",
                padding: theme.spacing(2)
            }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{ padding: theme.spacing(1) }}>
                <Typography
                    sx={{
                        color: theme.palette.text.secondary,
                        flex: 1
                    }}>
                    {localization.jiraInstances(filteredInstanceModels.length)}
                </Typography>
                <CheckButton
                    checked={childScopeEnabled}
                    title={localization.actions.childScopeEnabled()}
                    onCheckedChanged={
                        checked => {
                            StorageHelper.customerConfigurationIntegrationsFlatView.setValue(checked);
                            setIntegrationsContext(_ => ({ childScopeEnabled: checked }));
                        }}/>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={
                        () =>
                            setJiraContext(
                                jiraContext => ({
                                    ...jiraContext,
                                    addOrEditType: JiraAddOrEditType.AddOrEditInstance,
                                    instance: undefined
                                }))}>
                    {localization.actions.add()}
                </Button>
            </Stack>
            <Box>
                {_.isEmpty(filteredInstanceModels)
                    ? <EmptyMessage
                        message={localization.empty()}
                        verticalCenter={true}/>
                    : _.map(
                        filteredInstanceModels,
                        filteredInstanceModel =>
                            <Instance
                                instanceModel={filteredInstanceModel}
                                key={filteredInstanceModel.configuration.id}/>)}
            </Box>
        </Stack>);
}