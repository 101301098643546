import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciKmsVaultStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciKmsVaultStatusTranslator",
            () => ({
                [Contract.TypeNames.OciKmsVaultStatus]: {
                    [Contract.OciKmsVaultStatus.Active]: "Active",
                    [Contract.OciKmsVaultStatus.BackupInProgress]: "Backup In Progress",
                    [Contract.OciKmsVaultStatus.CancellingDeletion]: "Cancelling Deletion",
                    [Contract.OciKmsVaultStatus.Creating]: "Creating",
                    [Contract.OciKmsVaultStatus.Deleted]: "Deleted",
                    [Contract.OciKmsVaultStatus.Deleting]: "Deleting",
                    [Contract.OciKmsVaultStatus.PendingDeletion]: "Pending Deletion",
                    [Contract.OciKmsVaultStatus.Restoring]: "Restoring",
                    [Contract.OciKmsVaultStatus.SchedulingDeletion]: "Scheduling Deletion",
                    [Contract.OciKmsVaultStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.OciKmsVaultStatus) =>
        localization[Contract.TypeNames.OciKmsVaultStatus][status]();
}