import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../..";

export function useAzureDeleteUserManagedIdentityChangeDefinition(change: Contract.AzureChange, entityLinkDisabled?: boolean) {
    const deleteManagedIdentityChange = change as Contract.AzureDeleteManagedIdentityChange;
    const manageIdentityModel = entityModelStore.useGet(deleteManagedIdentityChange.resourceId) as Contract.AzureManagedIdentityManagedIdentityModel;
    const servicePrincipalModel = entityModelStore.useGet((manageIdentityModel.entity as Contract.AzureManagedIdentityManagedIdentity).servicePrincipalId) as Contract.AadDirectoryServicePrincipalModel;
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAzureDefinition.hooks.useAzureDeleteUserManagedIdentityChangeDefinition",
            () => ({
                title: {
                    withoutWarning: "Delete {{servicePrincipal}}",
                    withWarning: "Delete {{servicePrincipal}} (may prevent linked {{originatorResourceTypeName}} from restarting)"
                }
            }));

    return {
        title:
            (_.isEmpty(manageIdentityModel.originatorResourceIds)
                ? localization.title.withoutWarning
                : localization.title.withWarning)({
                originatorResourceTypeName:
                    entityTypeNameTranslator(
                        Contract.TypeNames.AzureResource,
                        {
                            count: manageIdentityModel.originatorResourceIds.length,
                            includeServiceName: false,
                            variant: "text"
                        }),
                servicePrincipal:
                    <Entity
                        entityIdOrModel={servicePrincipalModel}
                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}