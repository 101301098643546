import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useAwsIamPrincipalDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsIamGroupDefinition(definitionData: DefinitionData) {
    const principalDefinition = useAwsIamPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsIamGroupDefinition",
            () => ({
                columns: {
                    users: "Users"
                }
            }));

    return new EntityTableDefinition(
        [
            principalDefinition.columns.tenantColumn,
            principalDefinition.columns.creationTimeColumn,
            principalDefinition.columns.creatorOriginatorEntityCsvColumn,
            principalDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsIamGroupModel>(
                        Contract.TypeNames.AwsIamUser,
                        item => (item.entity as Contract.AwsIamGroup).userIds,
                        localization.columns.users())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.AwsIamUser,
                                        true)}
                                placeholder={localization.columns.users()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamGroupUsers}
                key={Contract.EntityModelProperty.AwsIamGroupUsers}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsIamGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AwsIamGroup).userIds}
                            entityTypeName={Contract.TypeNames.AwsIamUser}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.users()}/>,
            principalDefinition.columns.policies,
            principalDefinition.columns.attributesColumn,
            principalDefinition.columns.openRiskedEntityRisksCsvColumn,
            principalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            principalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsIamGroupRequest(
                new Contract.EntityControllerGetEntityModelPageAwsIamGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamPrincipalPolicies]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamGroupUsers])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(undefined)));
}