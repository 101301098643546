import _ from "lodash";
import React from "react";
import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import { useOciContext, useSetOciContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { FinishItem, OrganizationDetailsItem, OrganizationMemberSelectionItem } from "./components";

export class AddOrEditContext {
    public enabled: boolean;
    public folderEnabled: boolean;
    public homeRegionSystemName: string;
    public memberSelection?: Contract.OrganizationMemberSelection;
    public ocid: string;
    public updatedOrganizationModel?: Contract.OciOrganizationModel;
    public updatedOrganizationTenantNames?: string[];
    public userOcid: string;
    public userPrivateKeyPem: Optional<string>;
    public userPublicKeyFingerprint: string;

    constructor(public existingOrganizationModel: Optional<Contract.OciOrganizationModel>) {
        this.enabled = existingOrganizationModel?.configuration.enabled ?? true;
        this.folderEnabled = existingOrganizationModel?.configuration.folderEnabled ?? true;
        this.homeRegionSystemName = existingOrganizationModel?.configuration.homeRegionSystemName ?? "";
        this.memberSelection = existingOrganizationModel?.configuration.memberSelection;
        this.ocid = existingOrganizationModel?.configuration.ocid ?? "";
        this.userOcid = existingOrganizationModel?.configuration.user?.ocid ?? "";
        this.userPrivateKeyPem =
            _.isNil(existingOrganizationModel)
                ? ""
                : undefined;
        this.userPublicKeyFingerprint = existingOrganizationModel?.configuration.user?.publicKeyFingerprint ?? "";
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useOciContext();
    const setOciContext = useSetOciContext();

    const [context, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.oci.addOrEdit",
            () => ({
                steps: {
                    configureScopes: "Configure Scopes",
                    organizationDetailsItem: "Tenancy Details",
                    userItem: "Permissions"
                },
                title: {
                    add: "Add OCI Tenancy",
                    edit: "Edit OCI Tenancy - {{organizationName}}"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(context.existingOrganizationModel)
                            ? localization.title.add()
                            : localization.title.edit({ organizationName: context.existingOrganizationModel.configuration.name })
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    onClose={
                        () =>
                            setOciContext(
                                ociContext => ({
                                    ...ociContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.organizationDetailsItem()}>
                        <OrganizationDetailsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.configureScopes()}>
                        <OrganizationMemberSelectionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}