import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NOpsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2016)">
                <path
                    d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
                    fill="#0071E9"/>
                <path
                    d="M19.0872 14.7386C18.5869 14.7386 18.1744 14.3377 18.1744 13.8322V10.2767C18.1744 9.77996 18.5782 9.37037 19.0872 9.37037C19.5875 9.37037 20 9.77124 20 10.2767V13.841C20 14.3377 19.5962 14.7386 19.0872 14.7386Z"
                    fill="white"/>
                <path
                    d="M16.4016 16.7778C15.9013 16.7778 15.4888 16.3769 15.4888 15.8714V7.9499C15.4888 7.45316 15.8925 7.04358 16.4016 7.04358C16.9018 7.04358 17.3143 7.44445 17.3143 7.9499V15.8714C17.3055 16.3682 16.9018 16.7778 16.4016 16.7778Z"
                    fill="white"/>
                <path
                    d="M13.7071 16.4553C13.2068 16.4553 12.7943 16.0545 12.7943 15.549V8.42048C12.7943 7.92375 13.1981 7.51416 13.7071 7.51416C14.2074 7.51416 14.6199 7.91503 14.6199 8.42048V15.549C14.6199 16.0458 14.2074 16.4553 13.7071 16.4553Z"
                    fill="white"/>
                <path
                    d="M11.9254 9.00436V15.2266C11.9254 16.0806 11.2233 16.7778 10.3631 16.7778H7.95831C7.93199 16.7778 7.90566 16.7778 7.87932 16.7778C5.7378 16.7342 4 14.9913 4 12.8388C4 10.695 5.7378 8.94336 7.8881 8.9085V9.00436C7.8881 8.9695 7.8881 8.94336 7.8881 8.9085C7.91443 8.9085 7.93199 8.9085 7.95831 8.9085H7.8881C7.94076 7.84531 8.82722 7 9.90676 7C10.9863 7 11.8727 7.84531 11.9254 8.9085C11.9254 8.93464 11.9254 8.9695 11.9254 9.00436Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2016">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}