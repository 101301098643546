import { DataTableColumnRenderProps } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../common";
import { useRiskContext } from "../../../../../../../../../useRiskContextProvider";
import { Result } from "../../../../../../components";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.AwsCloudWatchAlarmNotExistRiskRequirement>) {
    const { riskModel } = useRiskContext();
    const risk = riskModel.risk as Contract.AwsCloudWatchAlarmNotExistRisk;
    return <Result success={risk.requirementToSuccessMap[item]}/>;
}