import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureMachineLearningWorkspaceRiskContext } from "../contexts";

export function useAzureMachineLearningWorkspaceInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureMachineLearningWorkspaceInboundExternalWideRangeRisk;
    const workspaceModel = entityModelStore.useGet(risk.entityId) as Contract.AzureMachineLearningWorkspaceModel;

    const getAzureMachineLearningWorkspaceRiskContext = useGetAzureMachineLearningWorkspaceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureMachineLearningWorkspaceInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{workspace}} allows inbound network access from a wide range of public IP addresses",
                sections: {
                    resolution: {
                        step1: "In the **Public access** section, select either **Enabled from selected IP addresses** or **Disabled**",
                        step2: "If you select **Enabled from selected IP addresses**, restrict access to the specific IP ranges by adding address ranges in the **Firewall** section",
                        step3: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            workspace:
                <Entity
                    entityIdOrModel={workspaceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.NetworkSettings}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const workspaceRiskContext = getAzureMachineLearningWorkspaceRiskContext(workspaceModel);
            return [
                workspaceRiskContext.generalInformation,
                workspaceRiskContext.sensitive,
                workspaceRiskContext.allNetworkAccess,
                workspaceRiskContext.highBusinessImpact,
                workspaceRiskContext.wideRangeInboundSubnets,
                workspaceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}