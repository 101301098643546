import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";

export function useGcpSpannerInstanceDatabaseDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const databaseModel = scopeResourceModel as Contract.GcpSpannerInstanceDatabaseModel;
    const database = databaseModel.entity as Contract.GcpSpannerInstanceDatabase;
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpSpannerInstanceDatabaseDefinition",
            () => ({
                info: {
                    items: {
                        instance: "Spanner Instance"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    <EntitiesInfoItem
                        entityIdsOrModels={database.instanceId}
                        entityTypeName={Contract.TypeNames.GcpSpannerInstance}
                        key={Contract.EntityModelProperty.GcpSpannerInstanceDatabaseInstance}
                        title={localization.info.items.instance()}/>

                ]}
                options={options?.infoOptions}>
            </Info>
    });
}