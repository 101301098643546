import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOneLoginDirectoryUserStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOneLoginDirectoryUserStatusTranslator",
            () => ({
                [Contract.TypeNames.OneLoginDirectoryUserStatus]: {
                    [Contract.OneLoginDirectoryUserStatus.Active]: "Active",
                    [Contract.OneLoginDirectoryUserStatus.AwaitingPasswordReset]: "Awaiting password reset",
                    [Contract.OneLoginDirectoryUserStatus.Locked]: "Locked",
                    [Contract.OneLoginDirectoryUserStatus.PasswordExpired]: "Password expired",
                    [Contract.OneLoginDirectoryUserStatus.PasswordPending]: "Password pending",
                    [Contract.OneLoginDirectoryUserStatus.SecurityQuestionsRequired]: "Security questions required",
                    [Contract.OneLoginDirectoryUserStatus.Suspended]: "Suspended",
                    [Contract.OneLoginDirectoryUserStatus.Unactivated]: "Unactivated"
                }
            }));
    return (status: Contract.OneLoginDirectoryUserStatus) =>
        localization[Contract.TypeNames.OneLoginDirectoryUserStatus][status]();
}