import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciNetworkingInternetGatewayStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciNetworkingInternetGatewayDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const internetGatewayStatusTranslator = useOciNetworkingInternetGatewayStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciNetworkingInternetGatewayDefinition",
            () => ({
                columns: {
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingInternetGatewayModel) => ({
                            [localization.columns.status()]: internetGatewayStatusTranslator((item.entity as Contract.OciNetworkingInternetGateway).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciNetworkingInternetGatewayStatus}
                                enumTypeTranslator={internetGatewayStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingInternetGatewayStatus}
                itemProperty={(item: Contract.OciNetworkingInternetGatewayModel) => internetGatewayStatusTranslator((item.entity as Contract.OciNetworkingInternetGateway).status)}
                key={Contract.EntityModelProperty.OciNetworkingInternetGatewayStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingInternetGatewayModel) => ({
                            [localization.columns.enabled.title()]:
                                (item.entity as Contract.OciNetworkingInternetGateway).enabled
                                    ? localization.columns.enabled.true()
                                    : localization.columns.enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter placeholder={localization.columns.enabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingInternetGatewayEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciNetworkingInternetGateway).enabled
                            ? localization.columns.enabled.true()
                            : localization.columns.enabled.false()}
                key={Contract.EntityModelProperty.OciNetworkingInternetGatewayEnabled}
                title={localization.columns.enabled.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingInternetGatewayModel>(
                        Contract.TypeNames.OciNetworkingVcn,
                        item => [item.vcnIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingInternetGatewayVcn)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingInternetGatewayVcn}
                key={Contract.EntityModelProperty.OciNetworkingInternetGatewayVcn}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingInternetGatewayModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vcnIdReference}
                            entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciNetworkingInternetGatewayRequest(
                new Contract.EntityControllerGetEntityModelPageOciNetworkingInternetGatewayRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingInternetGatewayEnabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingInternetGatewayStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingInternetGatewayVcn])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}