import { AnalyticsEventActionType, makeContextProvider, useLocalization, useTrackAnalytics, Wizard, WizardItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, JiraProjectReference, RiskType } from "../../../..";
import { IssueItem, MultipleRisksItem } from "./components";

export type UpsertJiraIssueProps = {
    instanceModels: Contract.ScopeSystemEntityModel[];
    onClose: (successRiskIds?: string[]) => Promise<void>;
    projectReference?: JiraProjectReference;
    riskIdToIssueIdsMap: Dictionary<string[]>;
    riskType: RiskType;
};

export class UpsertJiraIssueContext {
    constructor(
        public multipleRisksSelection?: boolean) {
    }
}

export const [useUpsertJiraIssueContext, useSetUpsertJiraIssueContext, useUpsertJiraIssueContextProvider] = makeContextProvider<UpsertJiraIssueContext>();

export function UpsertJiraIssue({ instanceModels, onClose, projectReference, riskIdToIssueIdsMap, riskType }: UpsertJiraIssueProps) {
    const [{ multipleRisksSelection }, , ContextProvider] = useUpsertJiraIssueContextProvider(() => new UpsertJiraIssueContext());
    const create =
        _.some(
            riskIdToIssueIdsMap,
            issueIds => _.isEmpty(issueIds));
    const multipleRiskEnable = _.size(riskIdToIssueIdsMap) > 1 && create;

    const trackAnalytics = useTrackAnalytics();
    const localization =
        useLocalization(
            "common.upsertJiraIssueDialog.upsertJiraIssue",
            () => ({
                multipleRisks: "Create a unified Jira issue",
                multipleRisksSelect: "Create Jira issue/s for {{count | NumberFormatter.humanize}} findings",
                notMultipleRisks: {
                    create: [
                        "Create a Jira Issue",
                        "Create {{count | NumberFormatter.humanize}} separate Jira issues"
                    ],
                    update: [
                        "Update Jira Issue",
                        "Update {{count | NumberFormatter.humanize}} Jira Issues"
                    ]
                }
            }));

    return (
        <ContextProvider>
            <Wizard
                titleOptions={{ text: "" }}
                onFinished={
                    () => { 
                        trackAnalytics(
                            AnalyticsEventActionType.UpsertTicketing,
                            {
                                "Create": create,
                                "Type": Contract.TicketingServiceType.Jira
                            });
                        onClose();
                    }}>
                {multipleRiskEnable && (
                    <WizardItem title={localization.multipleRisksSelect({ count: _.size(riskIdToIssueIdsMap) })}>
                        <MultipleRisksItem/>
                    </WizardItem>)}
                <WizardItem
                    deferredLoading={true}
                    disablePadding={true}
                    title={
                        multipleRiskEnable && multipleRisksSelection
                            ? localization.multipleRisks()
                            : create
                                ? localization.notMultipleRisks.create(_.size(riskIdToIssueIdsMap))
                                : localization.notMultipleRisks.update(
                                    _(riskIdToIssueIdsMap).
                                        flatMap().
                                        size())
                    }>
                    <IssueItem
                        instanceModels={instanceModels}
                        projectReference={projectReference}
                        riskIdToIssueIdsMap={riskIdToIssueIdsMap}
                        riskType={riskType}
                        onClose={onClose}/>
                </WizardItem>
            </Wizard>
        </ContextProvider>);
}