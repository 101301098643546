import { useGetAwsResourceRiskContext, useResourceTagNotExistsRiskDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../../../common";

export function useAwsResourceTagNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const getAwsResourceRiskContext = useGetAwsResourceRiskContext();

    return useResourceTagNotExistsRiskDefinition({
        getContextItems:
            (resourceModel: Contract.EntityModel) => {
                const resourceRiskContext = getAwsResourceRiskContext(resourceModel as Contract.AwsResourceModel);

                return [
                    resourceRiskContext.generalInformation,
                    resourceRiskContext.sensitive,
                    resourceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
                ];
            },
        riskModel
    });
}