﻿import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetGcpContainerClusterNodePoolsRiskContext() {
    return useMemo(
        () => useGcpContainerClusterNodePoolsRiskContext,
        []);
}

function useGcpContainerClusterNodePoolsRiskContext(nodePoolIdsOrModels: string[] | Contract.GcpContainerClusterNodePoolModel[]) {
    const nodePoolModels =
        entityModelStore.useGet(
            _.isString(nodePoolIdsOrModels[0])
                ? nodePoolIdsOrModels as string[]
                : undefined) ??
        nodePoolIdsOrModels as Contract.EntityModel[];
    const entityRiskContext =
        useGetEntitiesRiskContext()(
            nodePoolModels,
            Contract.TypeNames.GcpContainerClusterNodePool);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpContainerClusterNodePoolsRiskContext",
            () => ({
                [Contract.GcpServiceAccountOriginatorAuthorizationScopeType.All]: {
                    section: {
                        all: "All node pools have the https://www.googleapis.com/auth/cloud-platform access scope attached, allowing them to access all Cloud APIs",
                        many: "{{resources}} have the https://www.googleapis.com/auth/cloud-platform access scope attached, allowing them to access all Cloud APIs",
                        single: "{{resources}} has the https://www.googleapis.com/auth/cloud-platform access scope attached, allowing it to access all Cloud APIs"
                    }
                },
                [Contract.GcpServiceAccountOriginatorAuthorizationScopeType.Custom]: {
                    authorizationScopes: [
                        "1 access scope",
                        "{{count | NumberFormatter.humanize}} access scopes"
                    ],
                    section: {
                        all: "All node pools have custom access scopes attached, limiting their access only to specific Cloud APIs",
                        many: "{{resources}} have custom access scopes attached, limiting their access only to specific Cloud APIs",
                        single: "{{resources}} has {{authorizationScopes}} attached, limiting it to access only specific Cloud APIs"
                    }
                },
                [Contract.GcpServiceAccountOriginatorAuthorizationScopeType.DefaultContainerScopes]: {
                    section: {
                        all: "All node pools have {{defaultAuthorizationScopes}} attached, limiting them to only read from storage buckets, write logs, write traces and write metrics",
                        many: "{{resources}} have {{defaultAuthorizationScopes}} attached, limiting them to only read from storage buckets, write logs, write traces and write metrics",
                        single: "{{resources}} has {{defaultAuthorizationScopes}} attached, limiting it to only read from storage buckets, write logs, write traces and write metrics"
                    },
                    title: "default access scopes"
                },
                [Contract.GcpServiceAccountOriginatorAuthorizationScopeType.None]: {
                    section: {
                        all: "All node pools have the no access scopes attached, limiting their access to all Cloud API",
                        many: "{{resources}} have the no access scopes attached, limiting their access to all Cloud API",
                        single: "The {{translatedNodePoolTypeName}} has the no access scopes attached, limiting {{resources}} access to all Cloud API"
                    }
                }
            }));

    const inlineAuthorizationScopeRiskContext =
        _(Contract.GcpServiceAccountOriginatorAuthorizationScopeType).
            values().
            map(
                authorizationScopeType => {
                    const authorizationScopeTypeNodePools =
                        _(nodePoolModels).
                            map(nodePoolModel => nodePoolModel.entity as Contract.GcpContainerClusterNodePool).
                            filter(nodePool => nodePool.serviceAccountData.authorizationScopeType === authorizationScopeType).
                            value();
                    if (_.size(authorizationScopeTypeNodePools) === 0) {
                        return undefined;
                    }

                    const authorizationScopesTranslatorVariant =
                        _.size(authorizationScopeTypeNodePools) === _.size(nodePoolModels)
                            ? "all"
                            : _.size(authorizationScopeTypeNodePools) > 1
                                ? "many"
                                : "single";
                    return new RiskDefinitionContextItem(
                        localization[authorizationScopeType as GcpServiceAccountOriginatorAuthorizationScopeType].section[authorizationScopesTranslatorVariant]({
                            authorizationScopes:
                                <InlineItems
                                    items={authorizationScopeTypeNodePools[0].serviceAccountData.authorizationScopes}
                                    namePluralizer={localization[Contract.GcpServiceAccountOriginatorAuthorizationScopeType.Custom].authorizationScopes}
                                    variant="itemCountAndType"/>,
                            defaultAuthorizationScopes:
                                <InlineItems
                                    items={authorizationScopeTypeNodePools[0].serviceAccountData.authorizationScopes}
                                    title={localization[Contract.GcpServiceAccountOriginatorAuthorizationScopeType.DefaultContainerScopes].title()}
                                    variant="title"/>,
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={
                                        _.map(
                                            authorizationScopeTypeNodePools,
                                            resource => resource.id)}
                                    entityTypeName={authorizationScopeTypeNodePools[0].typeName}
                                    variant="itemCountAndType"/>,
                            translatedResourceTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpContainerClusterNodePool,
                                    {
                                        includeServiceName: false,
                                        variant: "text"
                                    })
                        }));
                }).
            filter().
            value();

    return {
        ...entityRiskContext,
        inlineAuthorizationScopeRiskContext
    };
}

type GcpServiceAccountOriginatorAuthorizationScopeType = Exclude<Contract.GcpServiceAccountOriginatorAuthorizationScopeType, Contract.GcpServiceAccountOriginatorAuthorizationScopeType.DefaultComputeScopes>;