import { setHashRoute } from "@infrastructure";
import React from "react";
import { ComplianceHelper, Contract, CustomerConsoleAppUrlHelper, ProfileActions } from "../../../../../../../common";
import { ActionsCell, Profile } from "../../../../Compliance/components";
import { useItem } from "../../../../Compliance/hooks";
import { SideViewItem, useSideViewContext } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useComplianceDefinition(item: SideViewItem): SideViewDefinition {
    const { triggerDataChange } = useSideViewContext();
    const { identifier, scopeId } = ComplianceHelper.getSideViewItemData(item.id);
    const complianceItem = useItem(identifier, scopeId);

    return {
        getActionsElement:
            () =>
                <ProfileActions
                    getLink={
                        () =>
                            CustomerConsoleAppUrlHelper.getComplianceProfileUrl(
                                identifier,
                                scopeId)}
                    key={`${item.id}-actions`}
                    menu={
                        <ActionsCell
                            item={complianceItem}
                            scopeId={scopeId}
                            onDataChanged={() => triggerDataChange(Contract.CustomerConsoleAppSideViewType.Compliance)}
                            onDelete={() => setHashRoute("")}/>}/>,
        getViewElement:
            () =>
                <Profile
                    item={complianceItem}
                    key={`${item.id}-profile`}
                    scopeId={scopeId}/>
    };
}