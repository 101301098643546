import _ from "lodash";
import path from "path";
import { AppError, formatFilterQueryParameters, formatQueryParameters, map, Optional, PagedValuesFilterSelection, TimeRangeFilterRange, TimeRangeFilterSelection, TimeRangeFilterSelectionRelative, TimeRangeFilterType, UnexpectedError, ValuesFilterSelection } from "@infrastructure";
import { ComplianceHelper, EntityTypeMetadataModelHelper, RiskPoliciesType, RiskPoliciesView, ScopeHelper, TypeHelper, UserHelper } from ".";
import { ApiController, Contract, RiskType, RiskTypeGroups } from "..";
import { CustomerView } from "../../views";
import { AccessView, CodeView, ComplianceView, CustomerSideViewItemType, EditScopeQueryParameters, KubernetesView, ReportsView, RiskPoliciesFilterId, ScopesView, ScopesViewType, WorkloadAnalysisView } from "../../views/Customer/components";
import { CodeScanRiskHelper } from "../../views/Customer/components/Code/components/Scans/components";
import { ProfileCategory as CodeScanProfileCategory } from "../../views/Customer/components/Code/components/Scans/components/Scan";
import { EntitiesView, WorkloadAnalysisView as ConfigurationWorkloadAnalysisView } from "../../views/Customer/components/Configuration/components";
import { CiIntegrationQueryParameters, CodeOrganizationQueryParameters, CodeServerQueryParameters } from "../../views/Customer/components/Configuration/components/Integrations/hooks/useItems/hooks";
import { CloudProviderTenantOrganizationAwsQueryParameters, CloudProviderTenantOrganizationAzureQueryParameters, CloudProviderTenantOrganizationGcpQueryParameters, CloudProviderTenantOrganizationOciQueryParameters } from "../../views/Customer/components/Configuration/components/Integrations/hooks/useItems/hooks/useCloudProviderTenantOrganizationItems/components";
import { EndpointConnectorQueryParameters } from "../../views/Customer/components/Configuration/components/Integrations/hooks/useItems/hooks/useEndpointConnectorItems/components";
import { IdentityProviderTenantAadTenantQueryParameters, IdentityProviderTenantGciTenantQueryParameters } from "../../views/Customer/components/Configuration/components/Integrations/hooks/useItems/hooks/useIdentityProviderTenantItems/components";
import { getIntegrationItemViewName, IntegrationItemCategory } from "../../views/Customer/components/Configuration/components/Integrations/utilities";
import { ConfigurationView } from "../../views/Customer/components/Configuration/utilities";
import { ProfileView } from "../../views/Customer/components/Entities/components";
import { ProfileCategory as EntityProfileCategory } from "../../views/Customer/components/Entities/components/Profile/hooks";
import { ProfileCategory as EventProfileCategory } from "../../views/Customer/components/Events/components/Profile";
import { ProfileCategory as KubernetesClusterAdmissionControllerEventProfileCategory } from "../../views/Customer/components/Kubernetes/components/KubernetesClusterAdmissionControllerEvents/components/Profile";
import { ReportsHistoryQueryParameters } from "../../views/Customer/components/Reports/components";
import { RiskPolicyHelper } from "../../views/Customer/components/RiskPolicies/utilities";
import { RisksItemsFilterId } from "../../views/Customer/components/Risks";
import { RiskDefinitionSectionCategory } from "../../views/Customer/components/Risks/hooks/useDefinition/utilities";
import { RiskHelper, RiskView } from "../../views/Customer/components/Risks/utilities";
import { TableColumnId } from "../../views/Customer/components/Scopes/components";
import { TenantTableColumnId } from "../../views/Customer/components/Scopes/hooks/useDefinition/hooks";
import { ProfileCategory as WorkloadAnalysisCodeScanProfileCategory } from "../../views/Customer/components/WorkloadAnalysis/components/Code/components/Scans/components/Profile";
import { UrlHelper } from "./urlHelper";

export class CustomerConsoleAppUrlHelper {
    private static readonly urlPath = "/customer";
    private static url: URL;

    public static initialize(url: URL) {
        CustomerConsoleAppUrlHelper.url = url;
        if (CustomerConsoleAppUrlHelper.url.pathname !== CustomerConsoleAppUrlHelper.urlPath) {
            throw new AppError("Console app url path mismatch");
        }
    }

    public static getAccessPermissionsRelativeUrl() {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath([
            CustomerView.Access,
            AccessView.Permissions
        ]);
    }

    public static getAdministrationRelativeUrl() {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Administration]);
    }

    public static getAuditEventProfileHashUrl(eventId: string) {
        return `#${CustomerSideViewItemType.AuditEvent}/${encodeURIComponent(eventId)}`;
    }

    public static getAuditEventProfileUrl(eventId: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getAuditEventsRelativeUrl(),
                CustomerConsoleAppUrlHelper.getAuditEventProfileHashUrl(eventId)));
    }

    public static getAuditEventsRelativeUrl() {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.AuditEvents]);
    }

    public static getCloudRiskPoliciesProfileHashUrl(riskPolicyConfigurationTypeNameOrId: string, scopeId: string) {
        return this.getRiskPoliciesProfileHashUrl(RiskPoliciesType.Cloud, riskPolicyConfigurationTypeNameOrId, scopeId);
    }

    public static getCodeRiskPoliciesRelativeUrl(
        scopeId?: string,
        filters?: CustomerConsoleUrlHelperGetRiskPoliciesFilters,
        view?: RiskPoliciesView) {
        const urlPath =
            CustomerConsoleAppUrlHelper.getBaseUrlPath([
                CustomerView.Code,
                CustomerView.RiskPolicies
            ]);
        return CustomerConsoleAppUrlHelper.getRiskPoliciesBaseRelativeUrl(
            urlPath,
            scopeId,
            filters,
            view);
    }

    public static getCodeRiskProfileHashUrl(
        riskId: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        return CustomerConsoleAppUrlHelper.getCommonRiskProfileHashUrl(RiskType.Code, riskId, categoryView);
    }

    public static getCodeRiskProfileRelativeUrl(
        riskId: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        return (
            CustomerConsoleAppUrlHelper.getCommonRiskProfileRelativeUrl(
                `${window.location.pathname}${window.location.search}${CustomerConsoleAppUrlHelper.getCodeRiskProfileHashUrl(riskId)}`,
                categoryView));
    }

    public static getCodeRiskProfileUrl(
        riskId: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        return (
            CustomerConsoleAppUrlHelper.getCommonRiskProfileUrl(
                CustomerConsoleAppUrlHelper.getCodeRisksRelativeUrl(),
                CustomerConsoleAppUrlHelper.getCodeRiskProfileHashUrl(riskId, categoryView)));
    }

    public static getCodeRisksRelativeUrl(filters?: CustomerConsoleAppUrlHelperGetCodeRisksRelativeUrlFilters) {
        const urlPath =
            CustomerConsoleAppUrlHelper.getBaseUrlPath([
                CustomerView.Code,
                CodeView.Risks
            ]);

        if (_.isNil(filters)) {
            return urlPath;
        }

        const queryParameters =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]]:
                        _.isNil(filters?.policyConfigurationTypeNameOrIds)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                filters.policyConfigurationTypeNameOrIds),
                    [RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory]]:
                        _.isNil(filters?.riskPolicyCategory)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                filters.riskPolicyCategory),
                    [RisksItemsFilterId[Contract.RiskFilterId.TenantId]]:
                        _.isNil(filters?.tenantIds)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                filters.tenantIds),
                    [RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity]]:
                        _.isNil(filters?.severity)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                filters!.severity)
                });

        return _.isEmpty(queryParameters)
            ? urlPath
            : `${urlPath}?${queryParameters}`;
    }

    public static getCodeRisksUrl(
        filters?: CustomerConsoleAppUrlHelperGetCodeRisksRelativeUrlFilters) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            CustomerConsoleAppUrlHelper.getCodeRisksRelativeUrl(filters));
    }

    public static getCodeScanProfileHashUrl(codeScanId: string, category?: CodeScanProfileCategory, filters?: CustomerConsoleAppUrlHelperGetCodeScanProfileRelativeUrlFilters) {
        let urlPath = `#${CustomerSideViewItemType.CodeScan}/${encodeURIComponent(codeScanId)}`;

        if (_.isNil(category)) {
            return urlPath;
        }

        urlPath = path.join(urlPath, category);

        if (_.isNil(filters)) {
            return urlPath;
        }

        const queryParameters =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [Contract.CodeControllerGetIacScanPageRequestProperty.Severity]:
                        _.isNil(filters!.severity)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                _.concat(filters!.severity))
                });

        return _.isEmpty(queryParameters)
            ? urlPath
            : `${urlPath}?${queryParameters}`;
    }

    public static getCodeScanProfileUrl(
        codeScanId: string,
        category?: CodeScanProfileCategory,
        filters?: CustomerConsoleAppUrlHelperGetCodeScanProfileRelativeUrlFilters) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getCodeScansRelativeUrl(),
                CustomerConsoleAppUrlHelper.getCodeScanProfileHashUrl(codeScanId, category, filters)));
    }

    public static getCodeScansRelativeUrl(filters: Optional<CustomerConsoleAppUrlHelperGetCodeScansRelativeUrlFilters> = undefined) {
        const url = CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Code, "scans"]);

        if (_.isEmpty(filters)) {
            return url;
        }

        const queryParameters =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [Contract.CodeControllerGetIacScanPageRequestProperty.CommitBranchName]:
                        _.isNil(filters!.branch)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                _.concat(filters!.branch),
                                "include"),
                    [Contract.CodeControllerGetIacScanPageRequestProperty.Severity]:
                        _.isNil(filters!.severity)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                _.concat(filters!.severity))
                });

        return `${url}?${queryParameters}`;
    }

    public static getCodeScanRiskHashUrl(scanId: string, riskScanId: string) {
        return `#${CustomerSideViewItemType.CodeScanRisk}/${encodeURIComponent(CodeScanRiskHelper.getSideViewItemId(scanId, riskScanId))}`;
    }

    public static getCodeScanRiskProfileUrl(scanId: string, riskScanId: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getCodeScansRelativeUrl(),
                CustomerConsoleAppUrlHelper.getCodeScanRiskHashUrl(scanId, riskScanId)));
    }

    public static getCodeTenantsRelativeUrl(filters: Optional<CustomerConsoleAppUrlHelperGetCodeTenantsRelativeUrlFilters> = undefined) {
        const url = CustomerConsoleAppUrlHelper.getScopesRelativeUrl(ScopesView.Code);

        if (_.isEmpty(filters)) {
            return url;
        }

        const queryParameters = _([
            formatFilterQueryParameters(
                "filterMap",
                {
                    [TenantTableColumnId.Provider]:
                        _.isNil(filters!.provider)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                _.concat(filters!.provider)),
                    [TenantTableColumnId.Status]:
                        _.isNil(filters!.status)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                _.concat(filters!.status))
                }),
            formatFilterQueryParameters(
                "toggleFilterMap",
                {
                    [TableColumnId.ChildScopeTenantsEnabled]:
                        _.isNil(filters!.flatView)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                _.concat(filters.flatView))
                })]).
            filter(queryParameter => !_.isNil(queryParameter)).
            value().
            join("&");

        return `${url}?${queryParameters}`;
    }

    public static getComplianceProfileHashUrl(complianceId: string, scopeId: string) {
        return `#${CustomerSideViewItemType.Compliance}/${encodeURIComponent(ComplianceHelper.getSideViewItemId(complianceId, scopeId))}`;
    };

    public static getComplianceProfileUrl(complianceId: string, scopeId: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getComplianceRelativeUrl(scopeId),
                CustomerConsoleAppUrlHelper.getComplianceProfileHashUrl(
                    complianceId,
                    scopeId)));
    };

    public static getComplianceRelativeUrl(scopeId?: string, view?: ComplianceView, id?: string) {
        const url = CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Compliance], scopeId);

        return path.join(
            url,
            view ?? "",
            id ?? "");
    }

    public static getConfigurationCustomEntityAttributeDefinitionsRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
            ConfigurationView.Entities,
            EntitiesView.CustomEntityAttributeDefinitions);
    }

    public static getConfigurationIntegrationsCiIntegrationRelativeUrl(
        integrationType: Contract.CiTenantType,
        addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(
                    integrationType,
                    IntegrationItemCategory.CiIntegration));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<CiIntegrationQueryParameters>({
            addOrEditOpen: String(addOrEditOpen)
        });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsCloudProviderTenantOrganizationsAwsRelativeUrl(addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(Contract.TenantType.Aws));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<CloudProviderTenantOrganizationAwsQueryParameters>({ addOrEditOpen: String(addOrEditOpen) });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsCloudProviderTenantOrganizationsAzureRelativeUrl(addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(Contract.TenantType.Azure));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<CloudProviderTenantOrganizationAzureQueryParameters>({ addOrEditOpen: String(addOrEditOpen) });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsCloudProviderTenantOrganizationsGcpRelativeUrl(addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(Contract.TenantType.Gcp));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<CloudProviderTenantOrganizationGcpQueryParameters>({ addOrEditOpen: String(addOrEditOpen) });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsCloudProviderTenantOrganizationsOciRelativeUrl(addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(Contract.TenantType.Oci));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<CloudProviderTenantOrganizationOciQueryParameters>({ addOrEditOpen: String(addOrEditOpen) });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsCodeOrganizationsRelativeUrl(
        organizationType: Contract.CodeTenantType,
        addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(
                    organizationType,
                    IntegrationItemCategory.CodeOrganizations));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<CodeOrganizationQueryParameters>({
            addOrEditOpen: String(addOrEditOpen)
        });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsCodeServersRelativeUrl(
        serverType: Contract.IntegrationType,
        addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(serverType));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<CodeServerQueryParameters>({ addOrEditOpen: String(addOrEditOpen) });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsEndpointConnectorRelativeUrl(addOrViewOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(Contract.IntegrationType.EndpointConnector));
        if (_.isNil(addOrViewOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<EndpointConnectorQueryParameters>({ addOrViewOpen: String(addOrViewOpen) });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsJiraRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
            ConfigurationView.Integrations,
            getIntegrationItemViewName(Contract.IntegrationType.Jira));
    }

    public static getConfigurationIntegrationsIdentityProviderTenantsAadRelativeUrl(addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(Contract.TenantType.Aad));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<IdentityProviderTenantAadTenantQueryParameters>({ addOrEditOpen: String(addOrEditOpen) });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsIdentityProviderTenantsGciRelativeUrl(addOrEditOpen?: boolean) {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
                ConfigurationView.Integrations,
                getIntegrationItemViewName(Contract.TenantType.Gci));
        if (_.isNil(addOrEditOpen)) {
            return url;
        }

        const queryParameters = formatQueryParameters<IdentityProviderTenantGciTenantQueryParameters>({ addOrEditOpen: String(addOrEditOpen) });
        return `${url}?${queryParameters}`;
    }

    public static getConfigurationIntegrationsKubernetesRelativeUrl(scopeId: string) {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(scopeId),
            ConfigurationView.Integrations,
            getIntegrationItemViewName(Contract.IntegrationType.Kubernetes));
    }

    public static getConfigurationIntegrationsRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
            ConfigurationView.Integrations);
    }

    public static getConfigurationIntegrationsServiceNowRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
            ConfigurationView.Integrations,
            getIntegrationItemViewName(Contract.IntegrationType.ServiceNow));
    }

    public static GetConfigurationIntegrationsSlackRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
            ConfigurationView.Integrations,
            getIntegrationItemViewName(Contract.IntegrationType.Slack));
    }

    public static GetConfigurationIntegrationsTeamsRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
            ConfigurationView.Integrations,
            getIntegrationItemViewName(Contract.IntegrationType.Teams));
    }

    public static getConfigurationRelativeUrl(scopeId?: string) {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Configuration], scopeId);
    }

    public static getConfigurationRelativeScopedUrl(scopeId?: string) {
        return path.join(..._<string>([CustomerConsoleAppUrlHelper.getConfigurationRelativeUrl(scopeId)]).value());
    }

    public static getConfigurationWorkloadAnalysisMaliciousFilesRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
            ConfigurationView.WorkloadAnalysis,
            ConfigurationWorkloadAnalysisView.MaliciousFiles);
    }

    public static getConfigurationWorkloadAnalysisVulnerabilitiesRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getConfigurationRelativeScopedUrl(),
            ConfigurationView.WorkloadAnalysis,
            ConfigurationWorkloadAnalysisView.Vulnerabilities);
    }

    public static getCustomersRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.urlPath,
            CustomerView.Customers);
    }

    public static getDataAnalysisResourceRelativeUrl(filters?: CustomerConsoleAppUrlHelperGetDataAnalysisResourceRelativeUrlFilters) {
        const url = CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.DataAnalysis]);

        if (_.isEmpty(filters)) {
            return url;
        }

        const queryParameters =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [Contract.EntityModelProperty.DataAnalysisResourceDataCategories]:
                        _.isNil(filters.dataCategories)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                filters.dataCategories,
                                "include"),
                    [Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]:
                        _.isNil(filters.dataSensitivities)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                filters.dataSensitivities,
                                "include"),
                    [Contract.EntityModelProperty.DataAnalysisResourceScanStatus]:
                        _.isNil(filters.dataResourceScanStatuses)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                filters.dataResourceScanStatuses,
                                "include")
                });

        return `${url}?${queryParameters}`;
    }

    public static getDocumentationProfileHashUrl(itemId: string) {
        return `#${CustomerSideViewItemType.Documentation}/${encodeURIComponent(itemId)}`;
    }

    public static getDocsAddAGoogleWorkspaceDomainWideDelegationRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "add-a-google-workspace");
        return `${url}#domainwide-delegation`;
    }

    public static getDocsAdministerJitAccessMultiLevelApprovalWorkflowRelativeUrl() {
        const urlPath =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "administer-jit-access");
        return `${urlPath}#multilevel-approval-workflow`;
    }

    public static getDocsAdmissionControllerEnableDisableAndConfigurePoliciesRelativeUrl() {
        const url = CustomerConsoleAppUrlHelper.getDocsRelativeUrl();
        return `${url}#enable-disable-and-configure-admission-controller-policies`;
    }

    public static getDocsAdmissionControllerRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "admission-controller");
    }

    public static getDocsArchitectureAndDataHandlingApplicationIpAddressesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "architecture-and-data-handling");
        return `${url}#tenable-ip-addresses-to-allow`;
    }

    public static getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "architecture-and-data-handling");
        return `${url}#how-often-is-data-synced-and-updated`;
    }

    public static getDocsCloudOnboardingAccountManagementOptionsRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "cloud-onboarding");
        return `${url}#account-management-options`;
    }

    public static getDocsCloudOnboardingRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "cloud-onboarding");
    }

    public static getDocsConfigureDataProtectionRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "configure-data-protection");
    }

    public static getDocsConnectYourCICDPipelineAddAzurePipelinesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "connect-your-cicd-pipeline");
        return `${url}#add-azure-pipelines`;
    }


    public static getDocsConnectYourCICDPipelineAddDifferentPipelineAdditionalVariablesForNonGitRepositoriesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "connect-your-cicd-pipeline");
        return `${url}#additional-variables-for-nongit-repositories`;
    }

    public static getDocsConnectYourCICDPipelineAddDifferentPipelineRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "connect-your-cicd-pipeline");
        return `${url}#add-a-different-pipeline`;
    }

    public static getDocsConnectYourCICDPipelineAddGitlabPipelineRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "connect-your-cicd-pipeline");
        return `${url}#add-a-gitlab-pipeline`;
    }

    public static getDocsConnectYourCICDPipelineAddJenkinsPipelineAdditionalVariableForNonGitRepositoriesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "connect-your-cicd-pipeline");
        return `${url}#additional-variable-for-nongit-repositories`;
    }

    public static getDocsConnectYourCICDPipelineAddJenkinsPipelineRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "connect-your-cicd-pipeline");
        return `${url}#add-a-jenkins-pipeline`;
    }

    public static getDocsConnectYourCICDPipelineAddTerraformCloudRunTaskRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "connect-your-cicd-pipeline");
        return `${url}#add-a-terraform-cloud-run-task`;
    }

    public static getDocsContainerImageCICDScanningAddAzurePipelinesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "container-image-cicd-scanning");
        return `${url}#add-azure-pipelines`;
    }

    public static getDocsContainerImageCICDScanningAddDifferentPipelineAdditionalVariablesForNonGitRepositoriesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "container-image-cicd-scanning");
        return `${url}#additional-variables-for-nongit-repositories`;
    }

    public static getDocsContainerImageCICDScanningAddDifferentPipelineRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "container-image-cicd-scanning");
        return `${url}#add-a-different-pipeline`;
    }

    public static getDocsContainerImageCICDScanningAddGitlabPipelineRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "container-image-cicd-scanning");
        return `${url}#add-a-gitlab-pipeline`;
    }

    public static getDocsContainerImageCICDScanningAddJenkinsPipelineAdditionalVariableForNonGitRepositoriesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "container-image-cicd-scanning");
        return `${url}#additional-variable-for-nongit-repositories`;
    }

    public static getDocsContainerImageCICDScanningAddJenkinsPipelineRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "container-image-cicd-scanning");
        return `${url}#add-a-jenkins-pipeline`;
    }

    public static getDocsContainerImageScanningCICDScanningRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "container-image-scanning");
        return `${url}#cicd-scanning`;
    }

    public static getDocsCreateAnAccountHierarchyWithFoldersRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "create-an-account-hierarchy-with-folders#automatically-update-folder-structure");
    }

    public static getDocsDataProtectionRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "data-protection");
    }

    public static getDocsEnableConsoleLoginViaSsoRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "enable-console-login-via-sso");
    }

    public static getDocsGcpFaqsRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "gcp-faqs");
    }

    public static getDocsGenerateAwsResourceNonexcessivePolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "generate-least-privilege-policies");
        return `${url}#aws-resource-granularity`;
    }

    public static getDocsGrantTenableJitPermissionsRelativeUrl(tenantType?: Contract.TenantType) {
        const baseUrl =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "grant-tenable-jit-permissions");
        return _.isNil(tenantType)
            ? baseUrl
            : map(
                tenantType,
                {
                    [Contract.TenantType.Aad]: () => `${baseUrl}#onboard-microsoft-entra-id-for-jit`,
                    [Contract.TenantType.Aws]: () => `${baseUrl}#onboard-aws-identity-center-for-jit`,
                    [Contract.TenantType.Azure]: () => `${baseUrl}#onboard-azure-for-jit`,
                    [Contract.TenantType.Gci]: () => `${baseUrl}#onboard-google-workspace-for-jit`,
                    [Contract.TenantType.Gcp]: () => `${baseUrl}#onboard-gcp-for-jit`,
                    [Contract.TenantType.Okta]: () => `${baseUrl}#onboard-okta-for-jit`,
                    [Contract.TenantType.OneLogin]: () => `${baseUrl}#onboard-onelogin-for-jit`,
                    [Contract.TenantType.PingIdentity]: () => `${baseUrl}#onboard-ping-identity-for-jit`
                });
    }

    public static getDocsHowTenableScansYourDataScannedFileTypesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "how-tenable-scans-your-data");
        return `${url}#scanned-file-types`;
    }

    public static getDocsHowTenableScansYourOnPremisesKubernetesWorkloadsRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "how-tenable-scans-your-on-premises-kubernetes-workloads");
    }

    public static getDocsIacSecurityRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "iac-security");
    }

    public static getDocsJitAccessRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "jit-access");
    }

    public static getDocsKubernetesRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "kubernetes");
    }

    public static getDocsLatestRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "latest");
    }

    public static getDocsLicensingLicenseTypesRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "licensing");
        return `${url}#license-types`;
    }

    public static getDocsOnboardAnAzureAksClusterAddACustomTenableRoleRelativeUrl() {
        const url = CustomerConsoleAppUrlHelper.getDocsOnboardAzureAksClusterRelativeUrl();
        return `${url}#add-a-custom-tenable-role`;
    }

    public static getDocsOnboardAwsCloudtrailLogsRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "onboard-aws");
        return `${url}#cloudtrail-logs`;
    }

    public static getDocsOnboardAwsDataProtectionPolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "configure-data-protection");
        return `${url}#aws-grant-data-protection-permissions`;
    }

    public static getDocsOnboardAwsEksClusterGrantPermissionsRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "onboard-an-amazon-eks-cluster");
        return `${url}#grant-the-tenable-role-cluster-permissions`;
    }

    public static getDocsOnboardAwsJitPolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "onboard-aws");
        return `${url}#jit-policy`;
    }

    public static getDocsOnboardAwsTenableManagementPolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "onboard-aws");
        return `${url}#tenable-management-policy`;
    }

    public static getDocsOnboardAwsTenableMonitoringPolicyReadonlyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "onboard-aws");
        return `${url}#tenable-monitoring-policy-readonly`;
    }

    public static getDocsOnboardAwsTenableReadonlyPolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "onboard-aws");
        return `${url}#tenable-readonly-policy`;
    }

    public static getDocsOnboardAwsWorkloadProtectionPolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "onboard-aws");
        return `${url}#workload-protection-policy`;
    }

    public static getDocsOnboardAzureAksClusterRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "onboard-an-azure-aks-cluster");
    }

    public static getDocsOnboardAzureDataProtectionPolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "configure-data-protection");
        return `${url}#azure-grant-data-protection-permissions`;
    }

    public static getDocsOnboardAzureRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "onboard-azure");
    }

    public static getDocsOnboardAzureWorkloadProtectionPolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "connect-your-cloud-workloads");
        return `${url}#azure-grant-virtual-machine-scanning-permissions`;
    }

    public static getDocsOnboardClusterViaTenableConnectorRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "onboard-cluster-via-tenable-connector");
    }

    public static getDocsOnboardClusterViaTenableConnectorAddKubernetesClusterRelativeUrl() {
        return `${CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl()}#add-a-kubernetes-cluster-connector`;
    }

    public static getDocsOnboardClusterViaTenableConnectorAddYourClusterRelativeUrl() {
        return `${CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl()}#add-your-cluster`;
    }

    public static getDocsOnboardClusterViaTenableConnectorClusterAccountLocationRelativeUrl() {
        const url = CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl();
        return `${url}#cluster-account-location`;
    }

    public static getDocsOnboardClusterViaTenableConnectorDeleteClusterRelativeUrl() {
        const url = CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl();
        return `${url}#delete-a-cluster`;
    }

    public static getDocsOnboardGcpDataProtectionPolicyRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "configure-data-protection");
        return `${url}#gcp-grant-data-protection-permissions`;
    }

    public static getDocsOnboardGcpRelativeUrl(): string {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "onboard-gcp");
    }

    public static getDocsOnboardOracleCloudRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "onboard-oracle-cloud");
    }

    public static getDocsRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.urlPath,
            CustomerView.Docs);
    }

    public static getDocsRiskPolicyExclusionRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "drill-down-into-findings");
        return `${url}#ignore-a-finding`;
    }

    public static getDocsSettingsConfiguration(typeName: Contract.ScopeSettingConfigurationDerivedTypeNames) {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            CustomerConsoleAppUrlHelper.getDocsSettingsConfigurationPath(typeName));
    }

    public static getDocsTicketingIntegration() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "ticketing-integrations");
    }

    public static getDocsTicketingIntegrationAddJiraProject() {
        return `${(CustomerConsoleAppUrlHelper.getDocsTicketingIntegration())}#add-a-jira-project`;
    }

    public static getDocsTicketingIntegrationAllowJiraIssueTransitions() {
        return `${(CustomerConsoleAppUrlHelper.getDocsTicketingIntegration())}#allow-jira-issue-transitions`;
    }

    public static getDocsTraceIssuesBackToCodeRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "trace-issues-back-to-code");
    }

    public static getDocsViewAndManageFindingsFindingStatusRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "view-and-manage-findings");
        return `${url}#finding-status`;
    }

    public static getDocsViewAndManagePoliciesRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
            "view-and-manage-policies");
    }

    public static getDocsViewAndManageClustersResourceSyncStatusRelativeUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "view-and-manage-clusters");
        return `${url}#resource-sync-status`;
    }

    public static getDocsVulnerabilityThreatAndImpactFactorsUrl() {
        const url =
            path.join(
                CustomerConsoleAppUrlHelper.getDocsRelativeUrl(),
                "assess-and-remediate-workload-vulnerabilities");
        return `${url}#threat-factors`;
    }

    public static getEntitiesRelativeUrl(
        entityTypeName?: string,
        filters?: Partial<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection>) {
        const baseUrlPath = CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Entities]);
        if (_.isNil(entityTypeName)) {
            return baseUrlPath;
        }

        const entityTypeMetadataModel = EntityTypeMetadataModelHelper.get(entityTypeName);
        const urlPath =
            path.join(
                baseUrlPath,
                entityTypeMetadataModel.tenantType,
                entityTypeMetadataModel.entitiesViewName);
        if (_.isEmpty(filters)) {
            return urlPath;
        } else {
            const filterMapQueryParameter =
                formatFilterQueryParameters(
                    "filterMap",
                    {
                        [Contract.EntityModelProperty.AadDirectoryServicePrincipalVendor]: filters!.aadDirectoryServicePrincipalVendor,
                        [Contract.EntityModelProperty.AadDirectoryUserType]: filters!.aadDirectoryUserTypes,
                        [Contract.EntityModelProperty.AadDirectoryUserAuthenticationType]: filters!.aadDirectoryUserAuthenticationTypes,
                        [Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilitySeverities]: filters!.awsEcrRepositoryContainerImageVulnerabilitySeverities,
                        [Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]: filters!.awsEncryptedResourceEncryptionExists,
                        [Contract.EntityModelProperty.AwsIamRoleVendor]: filters!.awsIamRoleVendor,
                        [Contract.EntityModelProperty.AwsIamUserMfaEnabled]: filters!.awsIamUserMfaEnabled,
                        [Contract.EntityModelProperty.AwsIamUserPasswordEnabled]: filters!.awsIamUserPasswordEnabled,
                        [Contract.EntityModelProperty.AwsResourceAccessLevel]: filters!.awsResourceAccessLevels,
                        [Contract.EntityModelProperty.AwsEcsServiceLaunchType]: filters!.awsEcsServiceLaunchTypes,
                        [Contract.EntityModelProperty.AzureStorageStorageAccountSharedKeyAccess]: filters!.azureStorageStorageAccountSharedKeyAccess,
                        [Contract.EntityModelProperty.EntityAttributes]: filters!.builtInAttributeTypeNames,
                        [Contract.EntityModelProperty.GcpAppEngineApplicationServiceContainerImageVulnerabilitySeverities]: filters!.gcpAppEngineApplicationServiceContainerImageVulnerabilitySeverities,
                        [Contract.EntityModelProperty.GcpCloudRunServiceContainerImageVulnerabilitySeverities]: filters!.gcpCloudRunServiceContainerImageVulnerabilitySeverities,
                        [Contract.EntityModelProperty.GcpContainerClusterMode]: filters!.gcpContainerClusterModes,
                        [Contract.EntityModelProperty.GcpScopeResourceAccessLevel]: filters!.gcpScopeResourceAccessLevels,
                        [Contract.EntityModelProperty.NetworkInboundAccessType]: filters!.networkInboundAccessTypes,
                        [Contract.EntityModelProperty.NetworkInboundExternalAccessScope]: filters!.networkInboundExternalAccessScopes,
                        [Contract.EntityModelProperty.VirtualMachineVulnerabilitySeverities]: filters!.vulnerabilitySeverities,
                        [Contract.EntityModelProperty.VirtualMachineScanTime]: filters!.scanTime
                    });

            return _.isNil(filterMapQueryParameter)
                ? urlPath
                : `${urlPath}?${filterMapQueryParameter}`;
        }
    }

    public static getEntityProfileHashUrl(
        entityId: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<EntityProfileCategory, ProfileView>) {
        const urlPath = `#${CustomerSideViewItemType.Entity}/${encodeURIComponent(entityId)}`;
        return _.isNil(categoryView?.category)
            ? urlPath
            : _.isNil(categoryView!.view)
                ? path.join(urlPath, categoryView!.category)
                : path.join(urlPath, categoryView!.category, categoryView!.view);
    }

    public static getEntityProfileRelativeUrl(
        entityModel: Contract.EntityModel,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<EntityProfileCategory, ProfileView>,
        withEntitiesRelativeUrl = false): Optional<string> {
        let entityTypeName = entityModel.entity.typeName;
        let entityId = entityModel.entity.id;

        if (entityModel.unknown) {
            return undefined;
        }

        if (TypeHelper.extendOrImplement(entityTypeName, Contract.TypeNames.AadDirectoryDirectoryRoleAssignmentResource)) {
            entityTypeName = Contract.TypeNames.AadDirectoryDirectoryRoleDefinition;
            entityId = _.as<Contract.AadDirectoryDirectoryRoleAssignmentResource>(entityModel.entity).roleDefinitionId;
        } else if (TypeHelper.extendOrImplement(entityTypeName, Contract.TypeNames.AwsElbLoadBalancerListener)) {
            entityTypeName =
                map(
                    entityTypeName,
                    {
                        [Contract.TypeNames.AwsElbApplicationLoadBalancerListener]: () => Contract.TypeNames.AwsElbApplicationLoadBalancer,
                        [Contract.TypeNames.AwsElbClassicLoadBalancerListener]: () => Contract.TypeNames.AwsElbClassicLoadBalancer,
                        [Contract.TypeNames.AwsElbNetworkLoadBalancerListener]: () => Contract.TypeNames.AwsElbNetworkLoadBalancer
                    });
            entityId = _.as<Contract.AwsElbLoadBalancerListener>(entityModel.entity).loadBalancerReference.idReference;
        }

        if (!EntityTypeMetadataModelHelper.get(entityTypeName).entitiesView) {
            return undefined;
        }

        let urlPath: string;
        if (withEntitiesRelativeUrl) {
            urlPath = `${CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(entityTypeName)}${CustomerConsoleAppUrlHelper.getEntityProfileHashUrl(entityId)}`;
        } else {
            urlPath = CustomerConsoleAppUrlHelper.getEntityProfileHashUrl(entityId);
        }

        return _.isNil(categoryView?.category)
            ? urlPath
            : _.isNil(categoryView!.view)
                ? path.join(urlPath, categoryView!.category)
                : path.join(urlPath, categoryView!.category, categoryView!.view);
    }

    public static getEntityProfileUrl(
        entityModel: Contract.EntityModel,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<EntityProfileCategory, ProfileView>): Optional<string> {
        const entityProfileRelativeUrl = CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel, categoryView, true);
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            entityProfileRelativeUrl);
    }

    public static getEventProfileHashUrl(
        eventId: string,
        category?: EventProfileCategory) {
        const urlPath = `#${CustomerSideViewItemType.Event}/${encodeURIComponent(eventId)}`;
        return _.isNil(category)
            ? urlPath
            : path.join(urlPath, category);
    }

    public static getEventProfileUrl(eventId: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getEventsRelativeUrl(),
                CustomerConsoleAppUrlHelper.getEventProfileHashUrl(eventId, EventProfileCategory.Overview)));
    }

    public static getEventsRelativeUrl(filters?: CustomerConsoleAppUrlHelperGetEventsRelativeUrlFilters) {
        const urlPath = CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Events]);
        if (_.isEmpty(filters)) {
            return urlPath;
        }

        const queryParameters =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [Contract.EventModelProperty.Identity]:
                        _.isNil(filters!.identity)
                            ? undefined
                            : filters!.identity,
                    [Contract.EventModelProperty.OriginatorEntity]:
                        _.isNil(filters!.originatorEntity)
                            ? undefined
                            : filters!.originatorEntity,
                    [Contract.EventModelProperty.Tenant]:
                        _.isNil(filters!.tenantId)
                            ? undefined
                            : filters!.tenantId,
                    [Contract.EventModelProperty.Time]:
                        _.isNil(filters!.time)
                            ? undefined
                            : filters!.time
                });
        return _.isEmpty(queryParameters)
            ? urlPath
            : `${urlPath}?${queryParameters}`;
    }

    public static getGraphqlRelativeUrl() {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Graphql]);
    }

    public static getKubernetesAdmissionControllerEventRelativeUrl(
        riskPolicyConfigurationTypeName?: string,
        violationRiskPolicyConfigurationTypeName?: string) {
        const urlPath =
            path.join(
                CustomerConsoleAppUrlHelper.getKubernetesRelativeUrl(),
                KubernetesView.KubernetesClusterAdmissionControllerEvents);
        const queryParameters =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [Contract.KubernetesControllerClusterAdmissionControllerEventProperty.RiskPolicyConfigurationTypeName]:
                        _.isNil(riskPolicyConfigurationTypeName)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                [riskPolicyConfigurationTypeName]),
                    [Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ViolationRiskPolicyConfigurationTypeName]:
                        _.isNil(violationRiskPolicyConfigurationTypeName)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                [violationRiskPolicyConfigurationTypeName])
                });
        return _.isEmpty(queryParameters)
            ? urlPath
            : `${urlPath}?${queryParameters}`;
    }

    public static getKubernetesAdmissionControllerRiskPoliciesRelativeUrl(
        scopeId?: string,
        view?: RiskPoliciesView) {
        const urlPath =
            path.join(
                CustomerConsoleAppUrlHelper.getKubernetesRelativeUrl(),
                KubernetesView.KubernetesAdmissionControllerRiskPolicies);
        return CustomerConsoleAppUrlHelper.getRiskPoliciesBaseRelativeUrl(
            urlPath,
            scopeId,
            undefined,
            view);
    }

    public static getKubernetesClusterAdmissionControllerEventProfileHashUrl(
        eventId: string,
        category?: KubernetesClusterAdmissionControllerEventProfileCategory) {
        const urlPath = `#${CustomerSideViewItemType.KubernetesClusterAdmissionControllerEvent}/${encodeURIComponent(eventId)}`;
        return _.isNil(category)
            ? urlPath
            : path.join(urlPath, category);
    }

    public static getKubernetesClusterAdmissionControllerEventProfileUrl(eventId: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getKubernetesRelativeUrl(),
                CustomerConsoleAppUrlHelper.getKubernetesClusterAdmissionControllerEventProfileHashUrl(
                    eventId,
                    KubernetesClusterAdmissionControllerEventProfileCategory.Overview)));
    }

    public static getKubernetesRelativeUrl() {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Kubernetes]);
    }

    public static getMaliciousFileHashUrl(contentSha256String: string): string {
        return `#${CustomerSideViewItemType.MaliciousFile}/${encodeURIComponent(contentSha256String)}`;
    }

    public static getPrincipalExcessivePermissionsRelativeUrl(
        principalRiskCategory?: Contract.PrincipalRiskCategory,
        filters?: CustomerConsoleAppUrlHelperGetPrincipalAccessRelativeUrlFilters) {
        const urlPath =
            CustomerConsoleAppUrlHelper.getBaseUrlPath(
                _<string>([]).
                    concat(
                        CustomerView.Access,
                        AccessView.PrincipalExcessivePermissions).
                    concatIf(
                        !_.isNil(principalRiskCategory),
                        () => principalRiskCategory!).
                    value());
        if (_.isEmpty(filters)) {
            return urlPath;
        } else {
            const queryParameters =
                formatFilterQueryParameters(
                    "filterMap",
                    {
                        [Contract.AccessControllerGetPrincipalModelPageRequestProperty.Severity]:
                            _.isNil(filters!.severities)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    filters!.severities)
                    });
            return _.isEmpty(queryParameters)
                ? urlPath
                : `${urlPath}?${queryParameters}`;
        }
    }

    public static getPrincipalPermissionsRelativeUrl(filters?: CustomerConsoleAppUrlHelperGetPrincipalAccessRelativeUrlFilters) {
        const urlPath =
            CustomerConsoleAppUrlHelper.getBaseUrlPath([
                CustomerView.Access,
                AccessView.PrincipalPermissions
            ]);
        if (_.isEmpty(filters)) {
            return urlPath;
        } else {
            const queryParameters =
                formatFilterQueryParameters(
                    "filterMap",
                    {
                        [Contract.AccessControllerGetPrincipalModelPageRequestProperty.Severity]:
                            _.isNil(filters!.severities)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    filters!.severities),
                        [Contract.AccessControllerGetPrincipalModelPageRequestProperty.Vendors]:
                            _.isNil(filters!.vendors)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    filters!.vendors)
                    });
            return `${urlPath}?${queryParameters}`;
        }
    }

    public static getRelativeUrl(consoleAppRelativeUrl: string) {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath([consoleAppRelativeUrl]);
    }

    public static getReportHistoryRelativeUrl(
        scopeId: string,
        downloadReportId?: string) {
        const urlPath =
            path.join(
                CustomerConsoleAppUrlHelper.getReportsRelativeUrl(),
                scopeId,
                ReportsView.History);
        if (_.isNil(downloadReportId)) {
            return urlPath;
        } else {
            const queryParameters = formatQueryParameters<ReportsHistoryQueryParameters>({ downloadReportId });
            return _.isEmpty(queryParameters)
                ? urlPath
                : `${urlPath}?${queryParameters}`;
        }
    }

    public static getReportsRelativeUrl() {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Reports]);
    }

    public static getRiskProfileHashUrl(
        riskId: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        return CustomerConsoleAppUrlHelper.getCommonRiskProfileHashUrl(RiskType.Cloud, riskId, categoryView);
    }

    public static getRiskProfileRelativeUrl(
        riskId: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        return (
            CustomerConsoleAppUrlHelper.getCommonRiskProfileRelativeUrl(
                `${window.location.pathname}${window.location.search}${CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(riskId)}`,
                categoryView));
    }

    public static getRiskProfileUrl(
        riskId: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        return (
            CustomerConsoleAppUrlHelper.getCommonRiskProfileUrl(
                CustomerConsoleAppUrlHelper.getRisksRelativeUrl(),
                CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(riskId, categoryView)));
    }

    public static getRisksRelativeUrl(
        view?: Contract.RisksView,
        filters?: CustomerConsoleAppUrlHelperGetRisksRelativeUrlFilters,
        groupingType?: RiskTypeGroups) {
        const baseUrl = CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Risks]);
        if (_.isNil(view)) {
            return baseUrl;
        }

        const urlPath =
            path.join(
                baseUrl,
                view,
                groupingType ?? RiskTypeGroups.Risk);
        if (_.isEmpty(filters)) {
            return urlPath;
        } else {
            const queryParameters =
                formatFilterQueryParameters(
                    "filterMap",
                    {
                        [RisksItemsFilterId[Contract.RiskFilterId.ComplianceIdOrSectionType]]:
                            _.isNil(filters!.complianceIds)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    filters!.complianceIds),
                        [RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityId]]:
                            _.isNil(filters!.riskedEntityIds)
                                ? undefined
                                : new PagedValuesFilterSelection(
                                    false,
                                    "include",
                                    filters!.riskedEntityIds),
                        [RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]]:
                            _.isNil(filters!.policyConfigurationTypeNameOrIds)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    filters!.policyConfigurationTypeNameOrIds),
                        [RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity]]:
                            _.isNil(filters!.severity)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    _.concat(filters!.severity)),
                        [RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory]]:
                            _.isNil(filters!.riskPolicyCategory)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    [filters!.riskPolicyCategory]),
                        [RisksItemsFilterId[Contract.RiskFilterId.StatusUpdateTime]]:
                            !_.isNil(filters.statusUpdateTimeRange)
                                ? new TimeRangeFilterSelection(
                                    filters.statusUpdateTimeRange.endTime,
                                    filters.statusUpdateTimeRange.startTime,
                                    undefined,
                                    TimeRangeFilterType.DateBetween)
                                : !_.isNil(filters.statusUpdateTimeLast)
                                    ? new TimeRangeFilterSelection(
                                        undefined,
                                        undefined,
                                        filters.statusUpdateTimeLast,
                                        TimeRangeFilterType.RelativeInTheLast)
                                    : undefined,
                        [RisksItemsFilterId[Contract.RiskFilterId.TenantId]]:
                            _.isNil(filters!.tenantIds)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    filters!.tenantIds),
                        [RisksItemsFilterId[Contract.RiskFilterId.TicketingServiceType]]:
                            _.isNil(filters!.ticketingServiceTypes)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    filters!.ticketingServiceTypes)
                    });

            return _.isEmpty(queryParameters)
                ? urlPath
                : `${urlPath}?${queryParameters}`;
        }
    }

    public static getRisksUrl(
        view: Contract.RisksView,
        filters?: CustomerConsoleAppUrlHelperGetRisksRelativeUrlFilters) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            CustomerConsoleAppUrlHelper.getRisksRelativeUrl(view, filters));
    }

    public static getRiskPoliciesProfileHashUrl(type: RiskPoliciesType, riskPolicyConfigurationTypeNameOrId: string, scopeId: string) {
        return `#${CustomerSideViewItemType.RiskPolicies}/${encodeURIComponent(RiskPolicyHelper.getSideViewItemId(riskPolicyConfigurationTypeNameOrId, scopeId, type))}`;
    }

    public static getRiskPoliciesProfileUrl(riskPolicyConfigurationTypeNameOrId: string, scopeId: string, view: RiskPoliciesView) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getRiskPoliciesRelativeUrl(scopeId, undefined, view),
                CustomerConsoleAppUrlHelper.getCloudRiskPoliciesProfileHashUrl(riskPolicyConfigurationTypeNameOrId, scopeId)));
    }

    public static getRiskPoliciesRelativeUrl(
        scopeId?: string,
        filters?: CustomerConsoleUrlHelperGetRiskPoliciesFilters,
        view?: RiskPoliciesView) {
        const urlPath = CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.RiskPolicies]);
        return CustomerConsoleAppUrlHelper.getRiskPoliciesBaseRelativeUrl(
            urlPath,
            scopeId,
            filters,
            view);
    }

    public static getRiskPoliciesBaseRelativeUrl(
        parentUrl: string,
        scopeId?: string,
        filters?: CustomerConsoleUrlHelperGetRiskPoliciesFilters,
        view?: RiskPoliciesView) {
        if (_.isNil(scopeId)) {
            return parentUrl;
        }

        let urlPath =
            path.join(
                parentUrl,
                scopeId);

        if (view) {
            urlPath =
                path.join(
                    urlPath,
                    view);
        }

        if (_.isEmpty(filters)) {
            return urlPath;
        } else {
            const queryParameters =
                formatFilterQueryParameters(
                    "filterMap",
                    {
                        [RiskPoliciesFilterId.Id]:
                            _.isNil(filters!.id)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    [filters!.id]),
                        [RiskPoliciesFilterId.Severity]:
                            _.isNil(filters!.severity)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    [filters!.severity]),
                        [RiskPoliciesFilterId.TenantType]:
                            _.isNil(filters!.tenantType)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    [filters!.tenantType])
                    });
            return _.isEmpty(queryParameters)
                ? urlPath
                : `${urlPath}?${queryParameters}`;
        }
    }

    public static getRootRelativeUrl() {
        return CustomerConsoleAppUrlHelper.getBaseUrlPath();
    }

    public static getSignInErrorRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.urlPath,
            CustomerView.SignInError);
    }

    public static getSignInRelativeUrl(redirectUrl?: string) {
        if (UserHelper.tenable) {
            return UrlHelper.tenableSignInUrl;
        }

        const urlPath =
            path.join(
                CustomerConsoleAppUrlHelper.urlPath,
                CustomerView.SignIn);
        return _.isNil(redirectUrl) || redirectUrl === "/"
            ? urlPath
            : `${urlPath}?${UrlHelper.formatRedirectUrlQueryParameters(redirectUrl)}`;
    }

    public static getSystemKubernetesClustersRelativeUrl() {
        return path.join(
            CustomerConsoleAppUrlHelper.getKubernetesRelativeUrl(),
            KubernetesView.SystemKubernetesClusters,
            ScopeHelper.customerId);
    }

    public static getScopesRelativeUrl(
        view?: ScopesViewType,
        options?: CustomerConsoleAppUrlHelperGetTenantsRelativeUrlOptions) {
        let baseUrl = CustomerConsoleAppUrlHelper.getBaseUrlPath([CustomerView.Scopes]);

        if (!_.isNil(view)) {
            baseUrl =
                path.join(
                    baseUrl,
                    view);
        }

        if (_.isNil(options?.addOrEdit)) {
            return baseUrl;
        }

        const queryParameters =
            formatQueryParameters<EditScopeQueryParameters>({
                addOrEdit: options!.addOrEdit,
                addOrEditStepIndex: options!.addOrEditStepIndex
            });
        return `${baseUrl}?${queryParameters}`;
    }

    public static getScopeHashUrl(scopeModelId: string) {
        return `#${CustomerSideViewItemType.Scope}/${encodeURIComponent(scopeModelId)}`;
    }

    public static getScopeProfileUrl(view: ScopesViewType, scopeModelId: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getScopesRelativeUrl(view),
                CustomerConsoleAppUrlHelper.getScopeHashUrl(scopeModelId)));
    }

    public static getTemplatePath(view?: string) {
        return path.join(
            ..._<string>([CustomerConsoleAppUrlHelper.urlPath]).
                concat("{scopeId}").
                concatIf(
                    !_.isNil(view),
                    view!).
                value());
    }

    public static getVulnerabilityHashUrl(vulnerabilityRawId: string) {
        return `#${CustomerSideViewItemType.Vulnerability}/${encodeURIComponent(vulnerabilityRawId)}`;
    }

    public static getWorkloadAnalysisCodeScanProfileHashUrl(
        scanId: string,
        category?: WorkloadAnalysisCodeScanProfileCategory) {
        const urlPath = `#${CustomerSideViewItemType.WorkloadAnalysisCodeScan}/${encodeURIComponent(scanId)}`;
        return _.isNil(category)
            ? urlPath
            : path.join(urlPath, category);
    }

    public static getWorkloadAnalysisCodeScanProfileUrl(scanId: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getWorkloadAnalysisViewBaseRelativeUrl(WorkloadAnalysisView.CodeScans),
                CustomerConsoleAppUrlHelper.getWorkloadAnalysisCodeScanProfileHashUrl(scanId, WorkloadAnalysisCodeScanProfileCategory.Overview)));
    }

    public static getWorkloadAnalysisMaliciousFileProfileUrl(contentSha256String: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getWorkloadAnalysisViewBaseRelativeUrl(WorkloadAnalysisView.MaliciousFiles),
                CustomerConsoleAppUrlHelper.getMaliciousFileHashUrl(contentSha256String)));
    }

    public static getWorkloadAnalysisViewBaseRelativeUrl =
        (view: WorkloadAnalysisView) =>
            CustomerConsoleAppUrlHelper.getBaseUrlPath([
                CustomerView[Contract.CustomerConsoleAppView.WorkloadAnalysis],
                view
            ]);

    public static getWorkloadAnalysisVirtualMachinesRelativeUrl(
        filterVirtualMachines = false,
        severity: Optional<Contract.Severity> = undefined) {
        const baseUrl = CustomerConsoleAppUrlHelper.getWorkloadAnalysisViewBaseRelativeUrl(WorkloadAnalysisView.VirtualMachines);

        const queryParameters =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]:
                        _.isNil(severity)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                [severity]),
                    [Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName]:
                        filterVirtualMachines
                            ? new ValuesFilterSelection(
                                false,
                                [
                                    Contract.TypeNames.AwsEc2Instance,
                                    Contract.TypeNames.AzureComputeVirtualMachine,
                                    Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine,
                                    Contract.TypeNames.GcpComputeInstance])
                            : undefined
                });
        return `${baseUrl}?${queryParameters}`;
    }

    public static getWorkloadAnalysisVulnerabilitiesRelativeUrl(
        severity: Optional<Contract.Severity> = undefined,
        vulnerabilityRawId: Optional<string> = undefined) {
        const baseUrl = CustomerConsoleAppUrlHelper.getWorkloadAnalysisViewBaseRelativeUrl(WorkloadAnalysisView.Vulnerabilities);
        const queryParameters =
            formatFilterQueryParameters(
                "filterMap",
                {
                    [Contract.WorkloadControllerRequestProperty.VulnerabilityRawId]:
                        _.isNil(vulnerabilityRawId)
                            ? undefined
                            : new PagedValuesFilterSelection(
                                false,
                                "include",
                                [vulnerabilityRawId]),
                    [Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]:
                        _.isNil(severity)
                            ? undefined
                            : new ValuesFilterSelection(
                                false,
                                [severity])
                });
        return `${baseUrl}?${queryParameters}`;
    }

    public static getWorkloadAnalysisVulnerabilityProfileUrl(vulnerabilityRawId: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                CustomerConsoleAppUrlHelper.getWorkloadAnalysisViewBaseRelativeUrl(WorkloadAnalysisView.Vulnerabilities),
                CustomerConsoleAppUrlHelper.getVulnerabilityHashUrl(vulnerabilityRawId)));
    }

    public static isRelativeUrl(consoleAppRelativeUrl: string): boolean {
        return UrlHelper.isRelativeUrl(CustomerConsoleAppUrlHelper.url, consoleAppRelativeUrl) &&
            (
                consoleAppRelativeUrl === "/" ||
                _(CustomerView).
                    values().
                    includes(UrlHelper.getPathRootSegment(consoleAppRelativeUrl)));
    }

    private static getBaseUrlPath(paths: string[] = [], scopeId?: string): string {
        return path.join(
            ..._<string>([CustomerConsoleAppUrlHelper.urlPath]).
                concat(encodeURIComponent(scopeId ?? ApiController.scopeId)).
                concat(paths).
                value());
    }

    private static getCommonRiskProfileHashUrl(
        riskType: RiskType,
        riskId: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        const urlPath = `#${CustomerSideViewItemType.Risk}/${encodeURIComponent(RiskHelper.getSideViewItemId(riskType, riskId))}`;
        return _.isNil(categoryView?.category)
            ? urlPath
            : _.isNil(categoryView!.view)
                ? path.join(urlPath, categoryView!.category)
                : path.join(urlPath, categoryView!.category, categoryView!.view);
    }

    private static getCommonRiskProfileRelativeUrl(
        parentUrl: string,
        categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) {
        return _.isNil(categoryView?.category)
            ? parentUrl
            : _.isNil(categoryView!.view)
                ? path.join(parentUrl, categoryView!.category)
                : path.join(parentUrl, categoryView!.category, categoryView!.view);
    }

    private static getCommonRiskProfileUrl(
        baseUrl: string,
        hashUrl: string) {
        return UrlHelper.getUrl(
            CustomerConsoleAppUrlHelper.url,
            path.join(
                baseUrl,
                hashUrl));
    }

    private static getDocsSettingsConfigurationPath(typeName: Contract.ScopeSettingConfigurationDerivedTypeNames) {
        switch (typeName) {
            case Contract.ScopeSettingConfigurationDerivedTypeNames.AadSeverePermissionDirectoryRoleDefinitionsConfiguration:
                return "define-privileged-azure-ad-roles";
            case Contract.ScopeSettingConfigurationDerivedTypeNames.ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration:
            case Contract.ScopeSettingConfigurationDerivedTypeNames.UserExcessivePermissionEvaluationTimeFrameConfiguration:
                return "excessive-permissions";
            default:
                throw new UnexpectedError("getDocsSettingsConfigurationPath");
        }
    }
}

export type CustomerConsoleAppUrlHelperCategoryView<TCategory, TView> = {
    category: TCategory;
    view?: TView;
};

export type CustomerConsoleAppUrlHelperGetCodeScanProfileRelativeUrlFilters = {
    severity?: Contract.Severity | Contract.Severity[];
};

export type CustomerConsoleAppUrlHelperGetCodeScansRelativeUrlFilters = {
    branch?: string | string[];
    severity?: Contract.Severity | Contract.Severity[];
};

export type CustomerConsoleAppUrlHelperGetCodeTenantsRelativeUrlFilters = {
    flatView?: boolean;
    provider?: Contract.CodeTenantType | Contract.CodeTenantType[];
    status?: Contract.CodeTenantModelStatus | Contract.CodeTenantModelStatus[];
};

type CustomerConsoleAppUrlHelperGetDataAnalysisResourceRelativeUrlFilters = {
    dataCategories?: Contract.DataCategory[];
    dataResourceScanStatuses?: Contract.DataAnalysisResourceModelDataAnalysisStatus[];
    dataSensitivities?: Contract.DataSensitivity[];
};

export type CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters = {
    aadDirectoryServicePrincipalVendor?: boolean;
    aadDirectoryUserAuthenticationTypes?: Contract.AadDirectoryUserAuthenticationType[];
    aadDirectoryUserTypes?: Contract.AadDirectoryUserType[];
    awsEcrRepositoryContainerImageVulnerabilitySeverities?: Contract.Severity[];
    awsEcsServiceLaunchTypes?: Contract.AwsEcsServiceLaunchType[];
    awsEncryptedResourceEncryptionExists?: boolean[];
    awsIamRoleVendor?: boolean;
    awsIamUserMfaEnabled?: boolean[];
    awsIamUserPasswordEnabled?: boolean[];
    awsResourceAccessLevels?: Contract.AwsResourceAccessLevel[];
    azureStorageStorageAccountSharedKeyAccess?: boolean[];
    builtInAttributeTypeNames?: string[];
    gcpAppEngineApplicationServiceContainerImageVulnerabilitySeverities?: Contract.Severity[];
    gcpCloudRunServiceContainerImageVulnerabilitySeverities?: Contract.Severity[];
    gcpContainerClusterModes?: Contract.GcpContainerClusterMode[];
    gcpScopeResourceAccessLevels?: Contract.GcpScopeResourceAccessLevel[];
    networkInboundAccessTypes?: Contract.NetworkInboundAccessType[];
    networkInboundExternalAccessScopes?: Contract.NetworkAccessScope[];
    scanTime: string;
    vulnerabilitySeverities?: Contract.Severity[];
};
export type CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection = {
    [K in ValuesFilterKeys]: Optional<ValuesFilterSelection<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters[K]>>;
} & {
    [K in ValueFilterKeys]: Optional<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters[K]>;
} & {
    [K in TimeRangeFilterKeys]: Optional<TimeRangeFilterSelection>
};

type CustomerConsoleAppUrlHelperGetEventsRelativeUrlFilters = {
    identity?: PagedValuesFilterSelection<string>;
    originatorEntity?: PagedValuesFilterSelection<string>;
    tenantId?: ValuesFilterSelection<string>;
    time?: TimeRangeFilterSelection;
};

type CustomerConsoleAppUrlHelperGetPrincipalAccessRelativeUrlFilters = {
    severities?: Contract.Severity[];
    vendors?: Contract.Vendor[];
};

export type CustomerConsoleAppUrlHelperGetRisksRelativeUrlFilters = {
    complianceIds?: string[];
    policyConfigurationTypeNameOrIds?: string[];
    riskedEntityIds?: string[];
    riskPolicyCategory?: Contract.RiskPolicyCategory;
    severity?: Contract.Severity | Contract.Severity[];
    statusUpdateTimeLast?: TimeRangeFilterSelectionRelative;
    statusUpdateTimeRange?: TimeRangeFilterRange;
    tenantIds?: string[];
    ticketingServiceTypes?: Contract.TicketingServiceType[];
};

export type CustomerConsoleAppUrlHelperGetCodeRisksRelativeUrlFilters = {
    policyConfigurationTypeNameOrIds?: string[];
    riskPolicyCategory?: Contract.RiskPolicyCategory[];
    severity?: Contract.Severity[];
    tenantIds?: string[];
};

type CustomerConsoleAppUrlHelperGetTenantsRelativeUrlOptions = {
    addOrEdit?: string;
    addOrEditStepIndex?: number;
};

type CustomerConsoleUrlHelperGetRiskPoliciesFilters = {
    id?: string;
    severity?: Contract.Severity;
    tenantType?: Contract.TenantType;
};

export type TimeRangeFilterKeys = keyof Pick<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters, "scanTime">;
export type ValueFilterKeys = keyof Pick<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters, "aadDirectoryServicePrincipalVendor" | "awsIamRoleVendor">;
export type ValuesFilterKeys = Exclude<keyof CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters, TimeRangeFilterKeys | ValueFilterKeys>;