import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, GeographyHelper, scopeSystemEntityModelStore, TenantController, tenantModelStore } from "../../../../../../../../../../common";
import { AwsOrganizationConfiguration } from "../../../../../../../../../../common/controllers/types.generated";
import { TenantModelHelper } from "../../../../../../utilities";
import { AddOrEditComponentProps } from "../../../../useAddOrEditDefinition";
import { FinishItem, PermissionsItem, RoleItem, TenantDetailsItem, TrailItem } from "./components";

class AddOrEditContext {
    public tenantName?: string;
    public tenantOrganizationEnabled: boolean;
    public tenantPartitionType: Contract.AwsPartitionType;
    public tenantPermissionTypes: Contract.CloudProviderTenantPermissionType[];
    public tenantRawId?: string;
    public tenantRegionSystemNames?: string[];
    public tenantRoleArn?: string;
    public tenantTerraformStateBucketEnabled: boolean;
    public tenantTrailBucket?: Contract.AwsTenantConfigurationTrailBucket;
    public tenantTrailBucketEnabled: boolean;
    public tenantTrails?: Contract.AwsTenantConfigurationTrail[];
    public tenantValidTrails?: Contract.AwsCloudTrailTrail[];
    public updatedTenantModel?: Contract.AwsTenantModel;
    public validErrorValidateAwsTenantTrailBucketResponses?: Contract.TenantControllerValidateAwsTenantTrailBucketResponse[];

    constructor(
        public onClose: () => void,
        public parentFolderId: string,
        public tenantOnboardingInfo: Contract.TenantControllerGetAwsTenantOnboardingInfoResponse,
        public organizationConfiguration ?: Contract.AwsOrganizationConfiguration,
        public tenantModel ?: Contract.AwsTenantModel) {
        this.tenantName = tenantModel?.configuration.name;
        this.tenantOrganizationEnabled = !!organizationConfiguration?.enabled;
        this.tenantPartitionType =
            tenantModel?.configuration.partitionType ??
            GeographyHelper.deploymentAwsPartitionType;
        this.tenantPermissionTypes = TenantModelHelper.getTenantModelPermissions(tenantModel).configurationPermissionTypes;
        this.tenantRawId = tenantModel?.configuration.rawId;
        this.tenantRegionSystemNames = tenantModel?.configuration.regionSystemNames;
        this.tenantRoleArn = tenantModel?.configuration.roleArn;
        this.tenantTerraformStateBucketEnabled = !_.isEmpty(tenantModel?.state.codeAnalysis.terraformStateBucketNameToEncryptionKeyArnsMap);
        this.tenantTrails = tenantModel?.configuration.trails;
        this.tenantTrailBucket = tenantModel?.configuration.trailBucket;
        this.tenantTrailBucketEnabled =
            !_.isNil(tenantModel?.configuration.trailBucket) ||
            !_.isEmpty(tenantModel?.state.eventAnalysis.trailBucketNameToEncryptionKeyArnsMap);
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit({ onClose, parentFolderId, scopeNodeModel, startItemIndex }: AddOrEditComponentProps) {
    const tenantModel = tenantModelStore.useGet(scopeNodeModel?.configuration.id) as Optional<Contract.AwsTenantModel>;
    const tenantConfiguration = tenantModel?.configuration;
    const organizationConfigurationModel = scopeSystemEntityModelStore.useGet(tenantConfiguration?.organizationId);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit",
            () => ({
                steps: {
                    permissions: "Permissions",
                    role: "IAM Role",
                    tenantDetails: "Account Details",
                    trail: "Select CloudTrail"
                },
                title: {
                    add: "Add Account",
                    edit: "Edit Account - {{tenantName}}"
                }
            }));

    const [getTenantOnboardingInfoResponse] =
        useExecuteOperation(
            [AddOrEdit, tenantConfiguration?.id],
            async () =>
                await TenantController.getAwsTenantOnboardingInfo(
                    new Contract.TenantControllerGetAwsTenantOnboardingInfoRequest(parentFolderId)));

    const [addOrEditContext, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    onClose,
                    parentFolderId,
                    getTenantOnboardingInfoResponse,
                    organizationConfigurationModel?.configuration as Optional<AwsOrganizationConfiguration>,
                    tenantModel));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(tenantConfiguration)
                            ? localization.title.add()
                            : localization.title.edit({ tenantName: addOrEditContext.tenantName })
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={startItemIndex}
                    onClose={onClose}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenantDetails()}>
                        <TenantDetailsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.permissions()}>
                        <PermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.role()}>
                        <RoleItem/>
                    </OrderedWizardItem>
                    {_.includes(addOrEditContext.tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.Read) &&
                        <OrderedWizardItem
                            deferredLoading={true}
                            title={localization.steps.trail()}>
                            <TrailItem/>
                        </OrderedWizardItem>}
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}