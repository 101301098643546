import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ExpirationIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M12 6C12.5523 6 13 6.44772 13 7V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V7C11 6.44772 11.4477 6 12 6Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M16.0563 15.9769C15.6651 16.3667 15.032 16.3657 14.6421 15.9745L11.8185 13.1412C11.4287 12.75 11.4298 12.1168 11.821 11.727C12.2122 11.3371 12.8453 11.3382 13.2352 11.7294L16.0588 14.5627C16.4486 14.9539 16.4475 15.587 16.0563 15.9769Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}