import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem, LicensingHelper } from "../../../../../../../../../common";

export function useCommonVirtualMachineResourceInfoItemElements(resourceModel: Contract.EntityModel) {
    const virtualMachineResourceModel = _.as<Contract.IVirtualMachineModel>(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useCommonVirtualMachineResourceInfoItemElements",
            () => ({
                info: {
                    items: {
                        scanSource: {
                            title: "Scan Source",
                            type: {
                                kubernetesClusterSensor: "Agent",
                                workloadAnalysis: "Agentless"
                            }
                        }
                    }
                }
            }));
    return {
        ...LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
            ? {
                scanSourceInfoElement:
                    <InfoItem
                        key="scanSource"
                        location="all"
                        title={localization.info.items.scanSource.title()}
                        value={
                            virtualMachineResourceModel.kubernetesClusterSensor
                                ? localization.info.items.scanSource.type.kubernetesClusterSensor()
                                : localization.info.items.scanSource.type.workloadAnalysis()}/>
            }
            : undefined
    };
}