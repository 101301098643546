import { map } from "@infrastructure";
import { useTheme as useMuiTheme } from "@mui/material";
import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { Contract, TypeHelper, VulnerabilityEventType } from "..";

const identityIdToColorMap = {} as Dictionary<string>;

export function useTheme() {
    const muiTheme = useMuiTheme();
    const theme =
        useMemo(
            () => ({
                ...muiTheme,
                palette: {
                    ...muiTheme.palette,
                    avatar: {
                        customer: [
                            muiTheme.palette.avatar.customer.background[0],
                            muiTheme.palette.avatar.customer.background[1],
                            muiTheme.palette.avatar.customer.background[2],
                            muiTheme.palette.avatar.customer.background[3],
                            muiTheme.palette.avatar.customer.background[4],
                            muiTheme.palette.avatar.customer.background[5],
                            muiTheme.palette.avatar.customer.background[6],
                            muiTheme.palette.avatar.customer.background[7],
                            muiTheme.palette.avatar.customer.background[8],
                            muiTheme.palette.avatar.customer.background[9]
                        ],
                        identity: {
                            background(identityId: string) {
                                if (!_.has(identityIdToColorMap, identityId)) {
                                    identityIdToColorMap[identityId] = theme.palette.avatar.customer[_.size(identityIdToColorMap) % theme.palette.avatar.customer.length];
                                }

                                return identityIdToColorMap[identityId];
                            },
                            color: muiTheme.palette.avatar.identity.text,
                            defaultBackground: muiTheme.palette.avatar.identity.defaultBackground
                        }
                    },
                    data: {
                        category(category: Contract.DataCategory) {
                            return map(
                                category,
                                {
                                    [Contract.DataCategory.Pci]: () => muiTheme.palette.data.category.pci,
                                    [Contract.DataCategory.Phi]: () => muiTheme.palette.data.category.phi,
                                    [Contract.DataCategory.Pii]: () => muiTheme.palette.data.category.pii,
                                    [Contract.DataCategory.Secrets]: () => muiTheme.palette.data.category.secrets
                                });
                        },
                        sensitivity(sensitivity?: Contract.DataSensitivity) {
                            return _.isNil(sensitivity)
                                ? muiTheme.palette.text.secondary
                                : map(
                                    sensitivity,
                                    {
                                        [Contract.DataSensitivity.Confidential]: () => muiTheme.palette.data.sensitivity.confidential,
                                        [Contract.DataSensitivity.NonSensitive]: () => muiTheme.palette.data.sensitivity.public,
                                        [Contract.DataSensitivity.Private]: () => muiTheme.palette.data.sensitivity.private,
                                        [Contract.DataSensitivity.Proprietary]: () => muiTheme.palette.data.sensitivity.proprietary,
                                        [Contract.DataSensitivity.Restricted]: () => muiTheme.palette.data.sensitivity.restricted
                                    });
                        }
                    },
                    entityAttribute: {
                        builtIn:
                            (typeName: string) => {
                                if (TypeHelper.extendOrImplement(typeName, Contract.TypeNames.DataResourceAttribute) ||
                                    TypeHelper.extendOrImplement(typeName, Contract.TypeNames.DataResourcePermissionActionPrincipalAttribute)) {
                                    return muiTheme.palette.attribute.background[17];
                                }

                                return map(
                                    typeName,
                                    {
                                        [Contract.TypeNames.AadDirectoryUserExternalAttribute]: () => muiTheme.palette.attribute.background[0],
                                        [Contract.TypeNames.AadDirectoryUserGuestAttribute]: () => muiTheme.palette.attribute.background[1],
                                        [Contract.TypeNames.AdministratorPrincipalAttribute]: () => muiTheme.palette.attribute.background[2],
                                        [Contract.TypeNames.AwsAccessKeyEnabledUserAttribute]: () => muiTheme.palette.attribute.background[3],
                                        [Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute]: () => muiTheme.palette.attribute.background[4],
                                        [Contract.TypeNames.BehaviorRiskIdentityAttribute]: () => muiTheme.palette.attribute.background[5],
                                        [Contract.TypeNames.CodeResourceAttribute]: () => muiTheme.palette.attribute.background[5],
                                        [Contract.TypeNames.CredentialsDisabledUserAttribute]: () => muiTheme.palette.attribute.background[6],
                                        [Contract.TypeNames.DisabledIdentityAttribute]: () => muiTheme.palette.attribute.background[6],
                                        [Contract.TypeNames.GcpUnusedTenantEntityAttribute]: () => muiTheme.palette.attribute.background[7],
                                        [Contract.TypeNames.GcpUserManagedKeyExistsServiceAccountAttribute]: () => muiTheme.palette.attribute.background[3],
                                        [Contract.TypeNames.InactiveIdentityAttribute]: () => muiTheme.palette.attribute.background[8],
                                        [Contract.TypeNames.MfaDisabledUserAttribute]: () => muiTheme.palette.attribute.background[9],
                                        [Contract.TypeNames.PermissionManagementEntityAttribute]: () => muiTheme.palette.attribute.background[12],
                                        [Contract.TypeNames.PublicEntityAttribute]: () => muiTheme.palette.attribute.background[10],
                                        [Contract.TypeNames.RelatedPublicComputeAttribute]: () => muiTheme.palette.attribute.background[10],
                                        [Contract.TypeNames.RelatedWorkloadResourceVulnerabilityAttribute]: () => muiTheme.palette.attribute.background[2],
                                        [Contract.TypeNames.SensitiveResourceAttribute]: () => muiTheme.palette.attribute.background[7],
                                        [Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute]: () => muiTheme.palette.attribute.background[7],
                                        [Contract.TypeNames.SevereDirectoryPermissionAttribute]: () => muiTheme.palette.attribute.background[11],
                                        [Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute]: () => muiTheme.palette.attribute.background[11],
                                        [Contract.TypeNames.SeverePermissionActionPrincipalAttribute]: () => muiTheme.palette.attribute.background[8],
                                        [Contract.TypeNames.VendorServiceIdentityAttribute]: () => muiTheme.palette.attribute.background[1],
                                        [Contract.TypeNames.WorkloadResourceMaliciousFileAttribute]: () => muiTheme.palette.attribute.background[17],
                                        [Contract.TypeNames.WorkloadResourceOperatingSystemEndOfLifeAttribute]: () => muiTheme.palette.attribute.background[12],
                                        [Contract.TypeNames.WorkloadResourceOperatingSystemUnpatchedAttribute]: () => muiTheme.palette.attribute.background[13],
                                        [Contract.TypeNames.WorkloadResourceVulnerabilityAttribute]: () => muiTheme.palette.attribute.background[14]
                                    });
                            },
                        custom: [
                            muiTheme.palette.attribute.background[1],
                            muiTheme.palette.attribute.background[10],
                            muiTheme.palette.attribute.background[15],
                            muiTheme.palette.attribute.background[8],
                            muiTheme.palette.attribute.background[3],
                            muiTheme.palette.attribute.background[4],
                            muiTheme.palette.attribute.background[6],
                            muiTheme.palette.attribute.background[16]
                        ]
                    },
                    excessivePermission(excessivePermissionType: Contract.AccessGraphPermissionPathActionsExcessiveness = Contract.AccessGraphPermissionPathActionsExcessiveness.None) {
                        return map(
                            excessivePermissionType,
                            {
                                [Contract.AccessGraphPermissionPathActionsExcessiveness.All]: () => muiTheme.palette.error.main,
                                [Contract.AccessGraphPermissionPathActionsExcessiveness.None]: () => muiTheme.palette.text.primary,
                                [Contract.AccessGraphPermissionPathActionsExcessiveness.Some]: () => muiTheme.palette.warning.light
                            });
                    },
                    opacity(color: string, opacity: number) {
                        return color + Math.
                            round(Math.min(Math.max(opacity, 0), 1) * 255).
                            toString(16).
                            toUpperCase();
                    },
                    risk: {
                        access(principalType: Contract.PrincipalType) {
                            return map(
                                principalType,
                                {
                                    [Contract.PrincipalType.ServiceIdentity]: () => muiTheme.palette.risk.access[0],
                                    [Contract.PrincipalType.User]: () => muiTheme.palette.risk.access[1],
                                    [Contract.PrincipalType.Group]: () => muiTheme.palette.risk.access[2],
                                    [Contract.PrincipalType.AwsPermissionSet]: () => muiTheme.palette.risk.access[3]
                                });
                        },
                        behavior: [
                            muiTheme.palette.risk.behavior[0],
                            muiTheme.palette.risk.behavior[1],
                            muiTheme.palette.risk.behavior[2],
                            muiTheme.palette.risk.behavior[3],
                            muiTheme.palette.risk.behavior[4],
                            muiTheme.palette.risk.behavior[5],
                            muiTheme.palette.risk.behavior[6],
                            muiTheme.palette.risk.behavior[7],
                            muiTheme.palette.risk.behavior[8]
                        ],
                        category: [
                            muiTheme.palette.risk.category[0],
                            muiTheme.palette.risk.category[1],
                            muiTheme.palette.risk.category[2],
                            muiTheme.palette.risk.category[3],
                            muiTheme.palette.risk.category[4],
                            muiTheme.palette.risk.category[5],
                            muiTheme.palette.risk.category[6],
                            muiTheme.palette.risk.category[7],
                            muiTheme.palette.risk.category[8],
                            muiTheme.palette.risk.category[9],
                            muiTheme.palette.risk.category[10],
                            muiTheme.palette.risk.category[11]
                        ],
                        defaultCategory: muiTheme.palette.risk.category.default
                    },
                    securedColor(securedPercentage: number) {
                        if(securedPercentage < 0.2) {
                            return muiTheme.palette.severity.critical;
                        } else if(securedPercentage < 0.5){
                            return muiTheme.palette.severity.high;
                        } else if(securedPercentage < 0.8){
                            return muiTheme.palette.severity.medium;
                        }

                        return muiTheme.palette.success.main;
                    },
                    severity(severity?: Contract.Severity) {
                        return _.isNil(severity)
                            ? muiTheme.palette.severity.low
                            : map(
                                severity,
                                {
                                    [Contract.Severity.Critical]: () => muiTheme.palette.severity.critical,
                                    [Contract.Severity.High]: () => muiTheme.palette.severity.high,
                                    [Contract.Severity.Information]: () => muiTheme.palette.severity.low,
                                    [Contract.Severity.Low]: () => muiTheme.palette.severity.low,
                                    [Contract.Severity.Medium]: () => muiTheme.palette.severity.medium
                                });
                    },
                    udm(index: (0 | 1 | 2 | 3 | 4)) {
                        return muiTheme.palette.udm.nestingLevel[index];
                    },
                    workload: {
                        epss: muiTheme.palette.workload.epss,
                        vulnerabilityEventType(type: VulnerabilityEventType) {
                            return map(
                                type,
                                {
                                    [VulnerabilityEventType.FirstProofOfConcept]: () => muiTheme.palette.workload.events[0],
                                    [VulnerabilityEventType.FunctionalExploit]: () => muiTheme.palette.workload.events[1],
                                    [VulnerabilityEventType.EmergingThreat]: () => muiTheme.palette.workload.events[2],
                                    [VulnerabilityEventType.ExploitedInTheWild]: () => muiTheme.palette.workload.events[3],
                                    [VulnerabilityEventType.CISAKnownExploit]: () => muiTheme.palette.workload.events[4],
                                    [VulnerabilityEventType.CISADueDate]: () => muiTheme.palette.workload.events[5],
                                    [VulnerabilityEventType.Ransomware]: () => muiTheme.palette.workload.events[6],
                                    [VulnerabilityEventType.Malware]: () => muiTheme.palette.workload.events[7],
                                    [VulnerabilityEventType.Mention]: () => muiTheme.palette.workload.events[8]
                                });
                        }
                    }
                }
            }),
            [muiTheme]);

    return theme;
}