import _ from "lodash";
import { useMemo } from "react";
import { Contract, entityModelStore, GetItemValueDefinition, useTableDefinition } from "../../../../../../../../../../../common";
import { RiskDetailsSectionItem } from "../../../../../utilities";

export function useRiskItemTableDefinition<TValuesMap extends { [columnId: string]: GetItemValueDefinition<RiskDetailsSectionItem> }>(
    riskItems: Contract.RiskItem[],
    columnIdToGetItemValueMap: TValuesMap,
    defaultSortColumnId: string) {
    const entityModels =
        entityModelStore.useGet(
            _.map(
                riskItems,
                riskItem => riskItem.entityId));
    const items =
        useMemo(
            () => {
                const entityModelMap =
                    _.keyBy(
                        entityModels,
                        entityModel => entityModel.entity.id);
                return _.map(
                    riskItems,
                    riskItem =>
                        new RiskDetailsSectionItem(
                            entityModelMap[riskItem.entityId],
                            riskItem));
            },
            [riskItems]);

    return useTableDefinition(
        items,
        columnIdToGetItemValueMap,
        defaultSortColumnId);
}