import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useVendorNameTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useVendorNameTranslator",
            () => ({
                [Contract.Vendor.AddEvent]: "AddEvent",
                [Contract.Vendor.Adobe]: "Adobe",
                [Contract.Vendor.Akamai]: "Akamai",
                [Contract.Vendor.Akira]: "Akira",
                [Contract.Vendor.Alignable]: "Alignable",
                [Contract.Vendor.Allantis]: "Allantis",
                [Contract.Vendor.Amazon]: "Amazon",
                [Contract.Vendor.Amplitude]: "Amplitude",
                [Contract.Vendor.Anadot]: "Anadot",
                [Contract.Vendor.Anecdotes]: "Anecdotes",
                [Contract.Vendor.Anodot]: "Anodot",
                [Contract.Vendor.Appfluence]: "Appfluence",
                [Contract.Vendor.Apple]: "Apple",
                [Contract.Vendor.ApprovedContact]: "Approved Contact",
                [Contract.Vendor.AquaMail]: "Aqua Mail",
                [Contract.Vendor.AquaSecurity]: "Aqua Security",
                [Contract.Vendor.Asana]: "Asana",
                [Contract.Vendor.Atlassian]: "Atlassian",
                [Contract.Vendor.Autodesk]: "Autodesk",
                [Contract.Vendor.AvePoint]: "AvePoint",
                [Contract.Vendor.Betterview]: "Betterview",
                [Contract.Vendor.BeyondTrust]: "BeyondTrust",
                [Contract.Vendor.BlueMail]: "BlueMail",
                [Contract.Vendor.Boomerang]: "Boomerang",
                [Contract.Vendor.Brightcove]: "Brightcove",
                [Contract.Vendor.BrotherIndustries]: "Brother Industries",
                [Contract.Vendor.Calendly]: "Calendly",
                [Contract.Vendor.Canon]: "Canon",
                [Contract.Vendor.Canva]: "Canva",
                [Contract.Vendor.CheckPoint]: "Check Point",
                [Contract.Vendor.Cisco]: "Cisco",
                [Contract.Vendor.Citrix]: "Citrix",
                [Contract.Vendor.CloudChekr]: "CloudChekr",
                [Contract.Vendor.CloudSaver]: "CloudSaver",
                [Contract.Vendor.CloudSponge]: "CloudSponge",
                [Contract.Vendor.CloudWize]: "CloudWize",
                [Contract.Vendor.CodeSpark]: "Code Spark",
                [Contract.Vendor.CodeTwo]: "CodeTwo",
                [Contract.Vendor.Continuity]: "Continuity",
                [Contract.Vendor.CoreStack]: "CoreStack",
                [Contract.Vendor.Cronofy]: "Cronofy",
                [Contract.Vendor.CrowdStrike]: "CrowdStrike",
                [Contract.Vendor.CyberArk]: "CyberArk",
                [Contract.Vendor.Cyderes]: "Cyderes",
                [Contract.Vendor.Databricks]: "Databricks",
                [Contract.Vendor.Datadog]: "Datadog",
                [Contract.Vendor.Decisions]: "Decisions",
                [Contract.Vendor.Deepwatch]: "Deepwatch",
                [Contract.Vendor.Devek]: "Devek",
                [Contract.Vendor.Dialpad]: "Dialpad",
                [Contract.Vendor.Docusign]: "Docusign",
                [Contract.Vendor.DoiT]: "DoiT",
                [Contract.Vendor.Doodle]: "Doodle",
                [Contract.Vendor.Dropbox]: "Dropbox",
                [Contract.Vendor.Druva]: "Druva",
                [Contract.Vendor.Dynatrace]: "Dynatrace",
                [Contract.Vendor.Edison]: "Edison",
                [Contract.Vendor.EdX]: "EdX",
                [Contract.Vendor.ElasticNV]: "Elastic N.V.",
                [Contract.Vendor.Eleven41Software]: "Eleven41 Software",
                [Contract.Vendor.Epiphani]: "Epiphani",
                [Contract.Vendor.Epsagon]: "Epsagon",
                [Contract.Vendor.Expel]: "Expel",
                [Contract.Vendor.F5]: "F5",
                [Contract.Vendor.Finout]: "Finout",
                [Contract.Vendor.FireEye]: "FireEye",
                [Contract.Vendor.Fireflies]: "Fireflies",
                [Contract.Vendor.Fivetran]: "Fivetran",
                [Contract.Vendor.Flexibits]: "Flexibits",
                [Contract.Vendor.Freshworks]: "Freshworks",
                [Contract.Vendor.Garmin]: "Garmin",
                [Contract.Vendor.GenDigital]: "Gen Digital",
                [Contract.Vendor.GingerLabs]: "Ginger Labs",
                [Contract.Vendor.Globus]: "Globus",
                [Contract.Vendor.Gong]: "Gong",
                [Contract.Vendor.GoodNotes]: "GoodNotes",
                [Contract.Vendor.Google]: "Google",
                [Contract.Vendor.Grafana]: "Grafana",
                [Contract.Vendor.HP]: "HP",
                [Contract.Vendor.HubSpot]: "HubSpot",
                [Contract.Vendor.Hunters]: "Hunters",
                [Contract.Vendor.IBM]: "IBM",
                [Contract.Vendor.Imply]: "Imply",
                [Contract.Vendor.Infinipoint]: "Infinipoint",
                [Contract.Vendor.Instaclustr]: "Instaclustr",
                [Contract.Vendor.IntsigInformation]: "INTSIG Information",
                [Contract.Vendor.JGraph]: "JGraph",
                [Contract.Vendor.Jotform]: "Jotform",
                [Contract.Vendor.JupiterOne]: "JupiterOne",
                [Contract.Vendor.Kahoot]: "Kahoot!",
                [Contract.Vendor.Kentik]: "Kentik",
                [Contract.Vendor.Kochava]: "Kochava",
                [Contract.Vendor.Lacework]: "Lacework",
                [Contract.Vendor.Lansweeper]: "Lansweeper",
                [Contract.Vendor.LinkedIn]: "LinkedIn",
                [Contract.Vendor.LogzIo]: "Logz.io",
                [Contract.Vendor.Lucid]: "Lucid",
                [Contract.Vendor.Lucidchart]: "Lucidchart",
                [Contract.Vendor.Lumen]: "Lumen",
                [Contract.Vendor.Meister]: "Meister",
                [Contract.Vendor.MicrochipTechnology]: "Microchip Technology",
                [Contract.Vendor.Microsoft]: "Microsoft",
                [Contract.Vendor.Mindjet]: "Mindjet",
                [Contract.Vendor.Miro]: "Miro",
                [Contract.Vendor.Monday]: "Monday",
                [Contract.Vendor.MongoDB]: "MongoDB",
                [Contract.Vendor.MozillaFoundation]: "Mozilla Foundation",
                [Contract.Vendor.MSP360]: "MSP360",
                [Contract.Vendor.Mural]: "Mural",
                [Contract.Vendor.NetApp]: "NetApp",
                [Contract.Vendor.NewRelic]: "New Relic",
                [Contract.Vendor.NineFolders]: "9Folders",
                [Contract.Vendor.NOps]: "nOps",
                [Contract.Vendor.Notion]: "Notion",
                [Contract.Vendor.OneCalendar]: "OneCalendar",
                [Contract.Vendor.OneIdentity]: "One Identity",
                [Contract.Vendor.OneSync]: "OneSync",
                [Contract.Vendor.OpenAI]: "OpenAI",
                [Contract.Vendor.Orca]: "Orca",
                [Contract.Vendor.Otter]: "Otter",
                [Contract.Vendor.OxSecurity]: "Ox Security",
                [Contract.Vendor.Padlet]: "Padlet",
                [Contract.Vendor.PaloAlto]: "Palo Alto",
                [Contract.Vendor.Pearson]: "Pearson",
                [Contract.Vendor.Polly]: "Polly",
                [Contract.Vendor.Prezi]: "Prezi",
                [Contract.Vendor.Qualys]: "Qualys",
                [Contract.Vendor.Qubole]: "Qubole",
                [Contract.Vendor.Quest]: "Quest",
                [Contract.Vendor.Quizizz]: "Quizizz",
                [Contract.Vendor.Rackspace]: "Rackspace",
                [Contract.Vendor.Radware]: "Radware",
                [Contract.Vendor.Rapid7]: "Rapid7",
                [Contract.Vendor.Rclone]: "Rclone",
                [Contract.Vendor.Redis]: "Redis",
                [Contract.Vendor.RedLine13]: "RedLine13",
                [Contract.Vendor.ReMarkable]: "reMarkable",
                [Contract.Vendor.RenaissanceLearning]: "Renaissance Learning",
                [Contract.Vendor.RiverMeadow]: "RiverMeadow",
                [Contract.Vendor.Rocketbook]: "Rocketbook",
                [Contract.Vendor.Rubrik]: "Rubrik",
                [Contract.Vendor.Salesforce]: "Salesforce",
                [Contract.Vendor.Samsung]: "Samsung",
                [Contract.Vendor.Schoology]: "Schoology",
                [Contract.Vendor.SendGrid]: "SendGrid",
                [Contract.Vendor.Sentra]: "Sentra",
                [Contract.Vendor.Shopify]: "Shopify",
                [Contract.Vendor.Skillable]: "Skillable",
                [Contract.Vendor.Smartsheet]: "Smartsheet",
                [Contract.Vendor.Snowflake]: "Snowflake",
                [Contract.Vendor.Snyk]: "Snyk",
                [Contract.Vendor.Solvo]: "Solvo",
                [Contract.Vendor.Sonarcloud]: "Sonarcloud",
                [Contract.Vendor.Sonrai]: "Sonrai",
                [Contract.Vendor.Sophos]: "Sophos",
                [Contract.Vendor.Splunk]: "Splunk",
                [Contract.Vendor.SumoLogic]: "Sumo Logic",
                [Contract.Vendor.SurveyMonkey]: "SurveyMonkey",
                [Contract.Vendor.Sysdig]: "Sysdig",
                [Contract.Vendor.Tableau]: "Tableau",
                [Contract.Vendor.Talend]: "Talend",
                [Contract.Vendor.TasksInABox]: "Tasks in a Box",
                [Contract.Vendor.Tenable]: "Tenable",
                [Contract.Vendor.Teraproc]: "Teraproc",
                [Contract.Vendor.ThomaBravo]: "Thoma Bravo",
                [Contract.Vendor.TrendCloudOne]: "Trend Cloud One",
                [Contract.Vendor.TrendMicro]: "Trend Micro",
                [Contract.Vendor.Tufin]: "Tufin",
                [Contract.Vendor.Turbot]: "Turbot",
                [Contract.Vendor.Twilio]: "Twilio",
                [Contract.Vendor.Typeform]: "Typeform",
                [Contract.Vendor.VaronisSystems]: "Varonis Systems",
                [Contract.Vendor.Virtru]: "Virtru",
                [Contract.Vendor.Vista]: "Vista",
                [Contract.Vendor.Vmware]: "Vmware",
                [Contract.Vendor.WillisTowersWatson]: "Willis Towers Watson",
                [Contract.Vendor.Wistia]: "Wistia",
                [Contract.Vendor.Witivio]: "Witivio",
                [Contract.Vendor.Wiz]: "Wiz",
                [Contract.Vendor.Workato]: "Workato",
                [Contract.Vendor.Workleap]: "Workleap",
                [Contract.Vendor.Wrike]: "Wrike",
                [Contract.Vendor.XMCyber]: "XM Cyber",
                [Contract.Vendor.Yahoo]: "Yahoo",
                [Contract.Vendor.Zapier]: "Zapier",
                [Contract.Vendor.Zendesk]: "Zendesk",
                [Contract.Vendor.Zesty]: "Zesty",
                [Contract.Vendor.Zoho]: "Zoho",
                [Contract.Vendor.Zoom]: "Zoom",
                [Contract.Vendor.Zoominfo]: "Zoominfo"
            }));

    return (vendor: Contract.Vendor) => localization.translate(vendor);
}