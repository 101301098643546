import { createBrowserHistory, createHashHistory } from "history";

export const browserHashHistory = createHashHistory(window);
export const browserHistory = createBrowserHistory(window);

browserHashHistory.listen(
    update => {
        const hashRouteHash = `#${update.location.pathname}${update.location.search}`;
        if (browserHistory.location.hash !== hashRouteHash &&
            update.action !== "POP") {
            browserHistory.replace({
                hash:
                    update.location.pathname.length > 1
                        ? hashRouteHash
                        : "",
                pathname: `${browserHistory.location.pathname}${browserHistory.location.search}`
            });
        }
    });

browserHistory.listen(
    update => {
        const hashRouteHash = `#${browserHashHistory.location.pathname}${browserHashHistory.location.search}`;
        if (update.location.hash !== hashRouteHash.replace("#/", "")) {
            browserHashHistory.replace({
                pathname: update.location.hash.substring(1)
            });
        }
    });