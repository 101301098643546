import _, { Dictionary } from "lodash";
import { formatQueryParameters } from "@infrastructure";
import { ApplicationHelper, AuthenticationController, ConfigurationController, Contract, GeographyHelper, TenantController } from "..";
import { GeographySelectorGitHubOnboardingState, GeographySelectorView } from "../../views";
import { UrlHelper } from "./urlHelper";

export class ConsoleApiUrlHelper {
    private static aadPartitionTypeToAuthorityUrlMap: Dictionary<string>;
    private static url: URL;

    public static initialize(
        aadPartitionTypeToAuthorityUrlMap: Dictionary<string>,
        url: URL) {
        ConsoleApiUrlHelper.aadPartitionTypeToAuthorityUrlMap = aadPartitionTypeToAuthorityUrlMap;
        ConsoleApiUrlHelper.url = url;
    }

    public static getAadConsentUrl(
        applicationRawId: string,
        partitionType: Contract.AadPartitionType,
        tenantRawId?: string) {
        const configurationIdentityProviderTenantsAadUrl =
            UrlHelper.getUrl(
                ConsoleApiUrlHelper.url,
                TenantController.processAadTenantCallbackUrl(undefined, undefined, undefined));
        return `${ConsoleApiUrlHelper.aadPartitionTypeToAuthorityUrlMap[partitionType]}${tenantRawId ?? "common"}/adminconsent?client_id=${applicationRawId}&redirect_uri=${configurationIdentityProviderTenantsAadUrl}&state=${JSON.stringify({ PartitionType: partitionType })}`;
    }

    public static getAzureDevOpsConsentUrl(applicationRawId: string) {
        const processAzureDevOpsOrganizationConsentCallbackUrl =
            UrlHelper.getUrl(
                ConsoleApiUrlHelper.url,
                ConfigurationController.processAzureDevOpsOrganizationConsentCallbackUrl(undefined, undefined));
        return `${ConsoleApiUrlHelper.aadPartitionTypeToAuthorityUrlMap[GeographyHelper.deploymentAadPartitionType]}common/adminconsent?client_id=${applicationRawId}&redirect_uri=${processAzureDevOpsOrganizationConsentCallbackUrl}`;
    }

    public static getGitHubOAuthAuthorizationUrl(
        applicationClientId: string,
        serverEndpoint: string,
        serverId?: string) {
        const globalDeploymentConsoleGitHubOnboardingUrl =
            UrlHelper.getUrl(
                new URL(ApplicationHelper.globalDeploymentConsoleUrl),
                GeographySelectorView.GitHubOnboarding);
        const geographySelectorGitHubOnboardingState: GeographySelectorGitHubOnboardingState = {
            deploymentName: ApplicationHelper.deploymentName,
            serverId
        };
        return `https://${serverEndpoint}/login/oauth/authorize?client_id=${applicationClientId}&redirect_uri=${globalDeploymentConsoleGitHubOnboardingUrl}&state=${JSON.stringify(geographySelectorGitHubOnboardingState)}`;
    }

    public static getGitHubAppInstallationUrl(applicationName: string) {
        return `https://github.com/apps/${ConsoleApiUrlHelper.normalizeGitHubApplicationName(applicationName)}`;
    }

    public static getGitHubServerAppInstallationUrl(
        applicationName: string,
        serverEndpoint: string) {
        return `https://${serverEndpoint}/github-apps/${ConsoleApiUrlHelper.normalizeGitHubApplicationName(applicationName)}`;
    }

    public static getGitHubServerAppRegistrationUrl(
        applicationName: string,
        organizationName: string,
        serverEndpoint: string) {
        const permissions = {
            "checks": "write",
            "contents": "write",
            "metadata": "write",
            "pull_requests": "write",
            "statuses": "write"
        };

        const appConfiguration = {
            "callback_urls":
                UrlHelper.getUrl(
                    new URL(ApplicationHelper.globalDeploymentConsoleUrl),
                    GeographySelectorView.GitHubOnboarding),
            "description": "Tenable Cloud Security GitHub App for GitHub Enterprise",
            "name": applicationName,
            "public": true,
            "url": "https://www.tenable.com/products/tenable-cloud-security",
            "webhook_active": false,
            ...permissions
        };

        return `https://${serverEndpoint}/organizations/${organizationName}/settings/apps/new?${formatQueryParameters(appConfiguration)}`;
    }

    public static getPermissionManagementAadConsentUrl(
        applicationRawId: string,
        partitionType: Contract.AadPartitionType,
        tenantRawId?: string) {
        const redirectUrl =
            UrlHelper.getUrl(
                ConsoleApiUrlHelper.url,
                ConfigurationController.processPermissionManagementAadTenantCallbackUrl(undefined, undefined));
        return `${ConsoleApiUrlHelper.aadPartitionTypeToAuthorityUrlMap[partitionType]}${tenantRawId ?? "common"}/adminconsent?client_id=${applicationRawId}&redirect_uri=${redirectUrl}&state=${JSON.stringify({ PartitionType: partitionType })}`;
    }

    public static getSignInAadUserRelativeUrl(
        consoleAppType: Contract.ConsoleAppType,
        userMail?: string): string {
        return ConsoleApiUrlHelper.getSignInRelativeUrl(
            consoleAppType,
            AuthenticationController.signInAadUserUrl(undefined, userMail));
    }

    public static getSignInGciUserRelativeUrl(
        consoleAppType: Contract.ConsoleAppType,
        userMail?: string): string {
        return ConsoleApiUrlHelper.getSignInRelativeUrl(
            consoleAppType,
            AuthenticationController.signInGciUserUrl(undefined, userMail));
    }

    public static getSignInSamlUserRelativeUrl(
        consoleAppType: Contract.ConsoleAppType,
        issuerId: string): string {
        return ConsoleApiUrlHelper.getSignInRelativeUrl(
            consoleAppType,
            AuthenticationController.signInSamlUserUrl(issuerId));
    }

    public static getTeamsConsentUrl(applicationRawId: string, scopeId: string) {
        const configurationIntegrationsTeamsUrl =
            UrlHelper.getUrl(
                ConsoleApiUrlHelper.url,
                ConfigurationController.processTeamsOrganizationCallbackUrl(undefined, undefined, undefined));
        return `${ConsoleApiUrlHelper.aadPartitionTypeToAuthorityUrlMap[GeographyHelper.deploymentAadPartitionType]}common/adminconsent?client_id=${applicationRawId}&redirect_uri=${configurationIntegrationsTeamsUrl}&state=${scopeId}`;
    }

    private static getSignInRelativeUrl(
        consoleAppType: Contract.ConsoleAppType,
        signInRelativeUrl: string) {
        const { redirectUrl } = UrlHelper.getRedirectUrlQueryParameters();
        const [path, ...queryParameters] =
            _(signInRelativeUrl).
                split("?").
                concat(formatQueryParameters<ConsoleApiUrlHelperConsoleAppTypeQueryParameters>({ consoleAppType })).
                concatIf(
                    !_.isNil(redirectUrl),
                    () => UrlHelper.formatRedirectUrlQueryParameters(redirectUrl!)).
                value();
        return `${path}?${_.join(queryParameters, "&")}`;
    }

    private static normalizeGitHubApplicationName(name: string) {
        return name.
            replace(/ /g, "-").
            toLowerCase();
    }
}

type ConsoleApiUrlHelperConsoleAppTypeQueryParameters = {
    consoleAppType: Contract.ConsoleAppType;
};