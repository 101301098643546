import { DownloadIcon, ItemSelector, TextViewer, useBlobUrl, useLocalization } from "@infrastructure";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

type DocumentsViewerProps = {
    documents: DocumentsViewerDocument[];
};

export function DocumentsViewer({ documents }: DocumentsViewerProps) {
    const [selectedDocument, setSelectedDocument] = useState(documents[0]);
    const localization =
        useLocalization(
            "common.documentsViewer",
            () => ({
                info: "Download {{documentFileName}} file"
            }));
    const selectedDocumentFileUrl =
        useBlobUrl(
            selectedDocument.document,
            "application/json");
    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{ height: "100%" }}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                {documents.length === 1
                    ? <Typography
                        noWrap={true}
                        sx={{ flex: 1 }}
                        variant="h4">
                        {documents[0].name}
                    </Typography>
                    : <ItemSelector
                        dense={true}
                        fieldSx={{ border: "none" }}
                        fullWidth={true}
                        items={documents}
                        selectedItem={selectedDocument}
                        sorted={false}
                        onSelectedItemChanged={setSelectedDocument}>
                        {document =>
                            <Typography
                                noWrap={true}
                                variant="h4">
                                {document.name}
                            </Typography>}
                    </ItemSelector>}
                <Box
                    sx={{
                        alignItems: "center",
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        display: "flex",
                        height: theme.spacing(4),
                        justifyContent: "center",
                        width: theme.spacing(4)
                    }}>
                    <a
                        download={selectedDocument.name}
                        href={selectedDocumentFileUrl}
                        title={localization.info({ documentFileName: selectedDocument.name })}>
                        <DownloadIcon
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: "24px"
                            }}/>
                    </a>
                </Box>
            </Stack>
            <Box sx={{ flex: 1 }}>
                <TextViewer
                    copyToClipboard={true}
                    format="json"
                    height="100%"
                    text={selectedDocument.document}/>
            </Box>
        </Stack>);
}

type DocumentsViewerDocument = {
    document: string;
    name: string;
};