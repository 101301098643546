import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoCard, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { OciNetworkingNetworkSecurityGroupRulesTable } from "../../../../../../../../../../tenants";
import { useOciNetworkingNetworkSecurityGroupStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";

export function useOciNetworkingNetworkSecurityGroupDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const networkSecurityGroupModel = resourceModel as Contract.OciNetworkingNetworkSecurityGroupModel;
    const networkSecurityGroup = networkSecurityGroupModel.entity as Contract.OciNetworkingNetworkSecurityGroup;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const networkSecurityGroupStatusTranslator = useOciNetworkingNetworkSecurityGroupStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingNetworkSecurityGroupDefinition",
            () => ({
                info: {
                    cards: {
                        inboundRules: "Inbound Rules",
                        outboundRules: "Outbound Rules",
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={networkSecurityGroupStatusTranslator(networkSecurityGroup.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkSecurityGroupModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={networkSecurityGroupModel.associatedResourceTypeNameToIdReferencesMap}
                    title={localization.info.cards.resources.title()}/>
                <InfoCard title={localization.info.cards.inboundRules()}>
                    <OciNetworkingNetworkSecurityGroupRulesTable
                        inbound={true}
                        networkSecurityGroupOcidToEntityIdReferenceMap={networkSecurityGroupModel.networkSecurityGroupOcidToEntityIdReferenceMap}
                        rules={networkSecurityGroup.inboundRules}/>
                </InfoCard>
                <InfoCard title={localization.info.cards.outboundRules()}>
                    <OciNetworkingNetworkSecurityGroupRulesTable
                        inbound={false}
                        networkSecurityGroupOcidToEntityIdReferenceMap={networkSecurityGroupModel.networkSecurityGroupOcidToEntityIdReferenceMap}
                        rules={networkSecurityGroup.outboundRules}/>
                </InfoCard>
            </Info>
    });
}