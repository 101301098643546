import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Link, Message, Optional, optionalTableCell, PasswordText, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, SecretExclusionActionCell, useTheme } from "../../../common";

type AwsCloudFormationStackParameterTableProps = {
    getHighlightColor?: (parameter: Contract.AwsCloudFormationStackParameter, opacity?: number) => Optional<string>;
    getParameterSecretValues?: (parameter: Contract.AwsCloudFormationStackParameter) => Optional<string[]>;
    parameterDefaultValuesMissing: boolean;
    parameters: Contract.AwsCloudFormationStackParameter[];
    risk?: Contract.Risk;
};

export function AwsCloudFormationStackParameterTable({ getHighlightColor, getParameterSecretValues, parameterDefaultValuesMissing, parameters, risk }: AwsCloudFormationStackParameterTableProps) {
    const localization =
        useLocalization(
            "tenants.aws.awsCloudFormationStackParameterTable",
            () => ({
                columns: {
                    defaultValue: "Default Value",
                    key: "Key",
                    resolvedValue: "Resolved value",
                    value: "Value"
                },
                empty: "No parameters",
                parameterDefaultValuesMissing: {
                    learnMoreLink: "Learn more",
                    message: "Since Tenable Cloud Security is missing the {{permission}} permission, the default values for parameters won’t be displayed or assessed for findings. Update the Monitoring policy with this permission. {{learnMoreLink}}.",
                    permission: "cloudformation:Get*",
                    title: "Missing default values for parameters"
                },
                title: "Parameters Table"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{ overflow: "hidden" }}>
            {parameterDefaultValuesMissing &&
                !_.isEmpty(parameters) &&
                <Stack
                    direction="row"
                    spacing={1}>
                    <Message
                        level="info"
                        title={
                            localization.parameterDefaultValuesMissing.message({
                                learnMoreLink:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsTenableMonitoringPolicyReadonlyRelativeUrl()}
                                        variant="external">
                                        {localization.parameterDefaultValuesMissing.learnMoreLink()}
                                    </Link>,
                                permission:
                                    <Typography
                                        sx={{
                                            display: "inline-flex",
                                            fontWeight: 600
                                        }}>
                                        {localization.parameterDefaultValuesMissing.permission()}
                                    </Typography>
                            })}
                        variant="minimal"/>
                    <Typography sx={{ color: theme.palette.warning.main }}>
                        {localization.parameterDefaultValuesMissing.title()}
                    </Typography>
                </Stack>}
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => parameters}
                getItemId={(item: Contract.AwsCloudFormationStackParameter) => item.key}
                rowOptions={{
                    getHighlightColor: (parameter: Contract.AwsCloudFormationStackParameter) => getHighlightColor?.(parameter),
                    getSx:
                        (parameter: Contract.AwsCloudFormationStackParameter) => ({
                            backgroundColor: getHighlightColor?.(parameter, 0.1)
                        })
                }}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={StackParameterColumnId.Key}
                    itemProperty={(item: Contract.AwsCloudFormationStackParameter) => item.key}
                    key={StackParameterColumnId.Key}
                    title={localization.columns.key()}/>
                <DataTableColumn
                    id={StackParameterColumnId.Value}
                    key={StackParameterColumnId.Value}
                    render={
                        optionalTableCell<Contract.AwsCloudFormationStackParameter>(
                            item =>
                                !_.isNil(getParameterSecretValues) &&
                                _.includes(getParameterSecretValues(item), item.value)
                                    ? <PasswordText password={item.value!}/>
                                    : item.value)}
                    title={localization.columns.value()}/>
                {<DataTableColumn
                    id={StackParameterColumnId.DefaultValue}
                    key={StackParameterColumnId.DefaultValue}
                    render={
                        optionalTableCell<Contract.AwsCloudFormationStackParameter>(
                            item =>
                                !_.isNil(getParameterSecretValues) &&
                                _.includes(getParameterSecretValues(item), item.defaultValue)
                                    ? <PasswordText password={item.defaultValue!}/>
                                    : item.defaultValue)}
                    title={localization.columns.defaultValue()}/>}
                <DataTableColumn
                    id={StackParameterColumnId.ResolvedValue}
                    key={StackParameterColumnId.ResolvedValue}
                    render={optionalTableCell<Contract.AwsCloudFormationStackParameter>(item => item.resolvedValue)}
                    title={localization.columns.resolvedValue()}/>
                {!_.isNil(getParameterSecretValues) &&
                    !_.isNil(risk) &&
                    <DataTableColumn
                        id={StackParameterColumnId.Actions}
                        key={StackParameterColumnId.Actions}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AwsCloudFormationStackParameter>) =>
                                <SecretExclusionActionCell
                                    displayNameToValueSecretDataMap={{
                                        [localization.columns.value()]: {
                                            secret: _.includes(getParameterSecretValues(item), item.value),
                                            value: item.value
                                        },
                                        [localization.columns.defaultValue()]: {
                                            secret: _.includes(getParameterSecretValues(item), item.defaultValue),
                                            value: item.defaultValue
                                        }
                                    }}
                                    risk={risk}
                                    secretKey={item.key}/>}/>}
            </DataTable>
        </Stack>);
}

enum StackParameterColumnId {
    Actions = "actions",
    DefaultValue = "defaultValue",
    Key = "key",
    ResolvedValue = "resolvedValue",
    Value = "value"
}