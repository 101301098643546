import { InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, InlineVulnerability, SeveritySquare, useSeverityTranslator } from "../../../../../../../../../../../common";

type VulnerabilitiesInlineItemsProps = {
    severity: Contract.Severity;
    severityVulnerabilityRawIds: string[];
};

export function VulnerabilitiesInlineItems({ severity, severityVulnerabilityRawIds }: VulnerabilitiesInlineItemsProps) {
    const severityTranslator = useSeverityTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.vulnerabilitiesInlineItems",
            () => ({
                description: [
                    "1 {{severitySquare}}**{{severity}}** severity vulnerability",
                    "{{count | NumberFormatter.humanize}} {{severitySquare}}**{{severity}}** severity vulnerabilities"
                ],
                vulnerabilities: "vulnerabilities"
            }));

    return _.isEmpty(severityVulnerabilityRawIds)
        ? <Typography>
            {localization.vulnerabilities()}
        </Typography>
        : <InlineItems
            items={
                _.map(
                    severityVulnerabilityRawIds,
                    vulnerabilityRawId => <InlineVulnerability rawId={vulnerabilityRawId}/>)}
            namePluralizer={
                count =>
                    localization.description(
                        count,
                        {
                            severity: severityTranslator(severity, "text"),
                            severitySquare: <SeveritySquare severity={severity}/>
                        })}
            variant="itemCountAndType"/>;
}