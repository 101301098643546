import { UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { Contract, RiskPoliciesType, RiskPolicyItem, RiskPolicyTypeMetadataHelper } from "../../../../../common";
import { TenantType } from "../../../../../common/controllers/types.generated";

export class RiskPolicyHelper {
    public static getSideViewItemData(itemId: string) {
        const itemIdParts = decodeURIComponent(itemId).split("/");
        if (_.size(itemIdParts) < 3) {
            throw new UnexpectedError("RiskPolicyHelper.getSideViewData", itemId);
        }

        return {
            riskPoliciesType: itemIdParts[0],
            riskPolicyConfigurationTypeNameOrId: itemIdParts[2],
            scopeId: RiskPolicyHelper.decodeScopeId(itemIdParts[1])
        };
    }

    public static getSideViewItemId(riskPolicyConfigurationTypeNameOrId: string, scopeId: string, riskPoliciesType: RiskPoliciesType) {
        return `${riskPoliciesType}/${RiskPolicyHelper.encodeScopeId(scopeId)}/${riskPolicyConfigurationTypeNameOrId}`;
    }

    public static isEntityExclusionsExists(item: RiskPolicyItem, tenantModels: Contract.TenantModel[]) {
        return !_.isEmpty(RiskPolicyTypeMetadataHelper.getExclusionEntityTypeNames(item.riskPolicyConfigurationTypeName)) &&
            _.some(
                tenantModels,
                tenantModel => tenantModel.tenantType !== Contract.TenantType.Code);
    }

    public static getCodeTenantsType(itemTenantTypes: TenantType[], removeTenantTypes: TenantType[]) {
        const codeTenantTypes = _.differenceBy(itemTenantTypes, removeTenantTypes);
        return _.isEmpty(codeTenantTypes)
            ? [TenantType.Code]
            : codeTenantTypes;
    }

    private static decodeScopeId(scopeId: string) {
        return _.replace(scopeId, /\~/g, "/");
    }

    private static encodeScopeId(scopeId: string) {
        return _.replace(scopeId, /\//g, "~");
    }
}