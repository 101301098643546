import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, Link, Optional, TextValuesFilter, TimeCell, TimeRangeFilter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { Contract, ItemTable, TimeRangeHelper, useTheme, vulnerabilityModelStore } from "../../../../../../../../common";
import { useVulnerabilityEventTypeTranslator } from "../hooks";
import { getEvents, VulnerabilityEventData } from "./utilities";

type TimelineProps = {
    rawId: string;
};

const TimelineMemo = memo(Timeline);
export { TimelineMemo as Timeline };

function Timeline({ rawId }: TimelineProps) {
    const vulnerabilityModel = vulnerabilityModelStore.useGet(rawId) as Optional<Contract.VulnerabilityModel>;

    const vulnerabilityEventTypeTranslator = useVulnerabilityEventTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.timeline",
            () => ({
                empty: "No Events",
                eventType: "Event",
                time: "Time"
            }));

    const events =
        useMemo(
            () => getEvents(vulnerabilityModel?.vulnerability, true),
            [vulnerabilityModel]);

    const theme = useTheme();
    return (
        <ItemTable
            columnIdToDefaultSortDirectionMap={
                {
                    [VulnerabilityEventsTableColumnId.Time]: "desc"
                }}
            columnIdToGetItemValueMap={{
                [VulnerabilityEventsTableColumnId.Time]: item => item.time,
                [VulnerabilityEventsTableColumnId.EventName]: item => vulnerabilityEventTypeTranslator(item.eventType)
            }}
            defaultSortColumnIdOrIds={VulnerabilityEventsTableColumnId.Time}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getItemId={item => String(item?.eventType)}
            items={events}
            showEmptyTable={true}
            sx={{ padding: theme.spacing(0, 2, 0) }}
            variant="view">
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TimeRangeFilter
                                    placeholder={localization.time()}
                                    timeRange={TimeRangeHelper.getTimesFilterRange(columnIdToItemValuesMap[VulnerabilityEventsTableColumnId.Time])}/>
                        }
                    }}
                    id={VulnerabilityEventsTableColumnId.Time}
                    key={VulnerabilityEventsTableColumnId.Time}
                    render={({ item }: DataTableColumnRenderProps<VulnerabilityEventData>) => <TimeCell time={item.time}/>}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.time()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <TextValuesFilter
                                    placeholder={localization.eventType()}
                                    values={columnIdToItemValuesMap[VulnerabilityEventsTableColumnId.EventName]}/>
                        }
                    }}
                    id={VulnerabilityEventsTableColumnId.EventName}
                    key={VulnerabilityEventsTableColumnId.EventName}
                    render={
                        ({ item }: DataTableColumnRenderProps<VulnerabilityEventData>) =>
                            <Link
                                urlOrGetUrl={item.url}
                                variant="external">
                                <Typography>
                                    {vulnerabilityEventTypeTranslator(item.eventType)}
                                </Typography>
                            </Link>}
                    title={localization.eventType()}/>
            ]}
        </ItemTable>);
}

enum VulnerabilityEventsTableColumnId {
    EventName = "eventName",
    Time = "time"
}