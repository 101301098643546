import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsAcmCertificateKeyTypeTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsAcmCertificateRiskContext() {
    return useMemo(
        () => useAwsAcmCertificateRiskContext,
        []);
}

function useAwsAcmCertificateRiskContext(certificateModel: Contract.AwsAcmCertificateModel) {
    const certificate = certificateModel.entity as Contract.AwsAcmCertificate;
    const resourceRiskContext = useGetAwsResourceRiskContext()(certificateModel);

    const acmCertificateKeyTypeTranslator = useAwsAcmCertificateKeyTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsAcmCertificateRiskContext",
            () => ({
                keyType: "The {{translatedCertificateTypeName}} key algorithm is **{{keyType}}**"
            }));

    return {
        ...resourceRiskContext,
        keyType:
            new RiskDefinitionContextItem(
                localization.keyType({
                    keyType: acmCertificateKeyTypeTranslator(certificate.keyType),
                    translatedCertificateTypeName:
                        entityTypeNameTranslator(
                            certificate.typeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                }))
    };
}