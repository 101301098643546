import { StringHelper, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { displayedItemCount } from "..";
import { Contract } from "../../../../../common";

export function useGroupMembershipRequestActivationFailureDataTranslator() {
    const localization =
        useLocalization(
            "views.user.hooks.usePermissionRequestActivationFailureDataTranslator.hooks.useGroupMembershipRequestActivationFailureDataTranslator",
            () => ({
                group: "{{groupName}} ({{groupErrorMessage}})",
                more: "and {{count}} more.",
                title: [
                    "Failed group:",
                    "Failed groups:"
                ]
            }));

    return (groupMembershipRequestModelActivationFailureData: Contract.GroupMembershipRequestModelActivationFailureData, entityModelMap: Dictionary<Contract.EntityModel>) => {
        const failedGroupCount = _.size(groupMembershipRequestModelActivationFailureData.groupIdToErrorMessageMap);
        return _<string>([]).
            concat(localization.title(failedGroupCount)).
            concat(
                _(groupMembershipRequestModelActivationFailureData.groupIdToErrorMessageMap).
                    toPairs().
                    orderBy(([groupId]) => StringHelper.getSortValue(entityModelMap[groupId].entity.displayName)).
                    take(displayedItemCount).
                    map(
                        ([groupIdReference, groupErrorMessage]) =>
                            localization.group(
                                {
                                    groupErrorMessage,
                                    groupName: entityModelMap[groupIdReference].entity.displayName
                                })).
                    value()).
            concatIf(
                failedGroupCount - displayedItemCount > 0,
                () => localization.more({ count: failedGroupCount - displayedItemCount })).
            join("\n");
    };
}