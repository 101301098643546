import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useObjectTypeCategoryTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useObjectTypeCategoryTranslator",
            () => ({
                [Contract.TypeNames.ObjectTypeCategory]: {
                    [Contract.ObjectTypeCategory.Entity]: "Resource",
                    [Contract.ObjectTypeCategory.EntityAi]: "AI",
                    [Contract.ObjectTypeCategory.EntityCompute]: "Compute",
                    [Contract.ObjectTypeCategory.EntityContainers]: "Containers",
                    [Contract.ObjectTypeCategory.EntityData]: "Data",
                    [Contract.ObjectTypeCategory.EntityIam]: "IAM",
                    [Contract.ObjectTypeCategory.EntityIot]: "IoT",
                    [Contract.ObjectTypeCategory.EntityKubernetes]: "Kubernetes",
                    [Contract.ObjectTypeCategory.EntityManagement]: "Management",
                    [Contract.ObjectTypeCategory.EntityNetwork]: "Network",
                    [Contract.ObjectTypeCategory.EntitySecurity]: "Security",
                    [Contract.ObjectTypeCategory.Risk]: "Findings"
                }
            }));

    return (entityTypeMetadataCategory: Contract.ObjectTypeCategory) =>
        localization[Contract.TypeNames.ObjectTypeCategory][entityTypeMetadataCategory]();
}