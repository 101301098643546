﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEc2SnapshotRiskContext() {
    return useMemo(
        () => useAwsEc2SnapshotRiskContext,
        []);
}

function useAwsEc2SnapshotRiskContext(snapshotModel: Contract.AwsEc2SnapshotModel) {
    const snapshot = snapshotModel.entity as Contract.AwsEc2Snapshot;
    const resourceRiskContext = useGetAwsResourceRiskContext()(snapshotModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2SnapshotRiskContext",
            () => ({
                snapshotAssociation: {
                    empty: "The {{translatedSnapshotTypeName}} is not associated with any instance",
                    images: "The {{translatedSnapshotTypeName}} is associated with {{images}}",
                    imagesAndInstances: "The {{translatedSnapshotTypeName}} is associated with {{instances}} and {{images}}",
                    instances: "The {{translatedSnapshotTypeName}} is associated with {{instances}}"
                },
                sourceVolume: "The {{translatedSnapshotTypeName}}’s source volume is {{volume}}"
            }));
    const translatedSnapshotTypeName =
        entityTypeNameTranslator(
            snapshot.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        snapshotAssociation:
            new RiskDefinitionContextItem(
                _.isEmpty(snapshotModel.sourceVolumeInstanceIds) && _.isEmpty(snapshotModel.imageIds)
                    ? localization.snapshotAssociation.empty({ translatedSnapshotTypeName })
                    : !_.isEmpty(snapshotModel.sourceVolumeInstanceIds) && !_.isEmpty(snapshotModel.imageIds)
                        ? localization.snapshotAssociation.imagesAndInstances({
                            images:
                                <InlineEntities
                                    entityIdsOrModels={snapshotModel.imageIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Image}
                                    variant="itemCountAndType"/>,
                            instances:
                                <InlineEntities
                                    entityIdsOrModels={snapshotModel.sourceVolumeInstanceIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                    variant="itemCountAndType"/>,
                            translatedSnapshotTypeName
                        })
                        : !_.isEmpty(snapshotModel.sourceVolumeInstanceIds)
                            ? localization.snapshotAssociation.instances({
                                instances:
                                    <InlineEntities
                                        entityIdsOrModels={snapshotModel.sourceVolumeInstanceIds}
                                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                        variant="itemCountAndType"/>,
                                translatedSnapshotTypeName
                            })
                            : localization.snapshotAssociation.images({
                                images:
                                    <InlineEntities
                                        entityIdsOrModels={snapshotModel.imageIds}
                                        entityTypeName={Contract.TypeNames.AwsEc2Image}
                                        variant="itemCountAndType"/>,
                                translatedSnapshotTypeName
                            })),
        sourceVolumeId:
            _.isNil(snapshot.sourceVolumeId)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sourceVolume({
                        translatedSnapshotTypeName,
                        volume:
                            <Entity
                                entityIdOrModel={snapshot.sourceVolumeId!}
                                variant="text"/>
                    }))
    };
}