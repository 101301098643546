import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciFssMountTargetStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciFssMountTargetDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const mountTargetStatusTranslator = useOciFssMountTargetStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciFssMountTargetDefinition",
            () => ({
                columns: {
                    privateIpAddress: "IP Address",
                    status: "State"
                }
            }));

    function GetStatusTranslation(entityModel: Contract.EntityModel) {
        const status = (entityModel.entity as Contract.OciFssMountTarget).status;
        return _.isNil(status)
            ? undefined
            : mountTargetStatusTranslator(status);
    }

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciFssMountTargetModel) => ({
                                [localization.columns.status()]: GetStatusTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.OciFssMountTargetStatus}
                                    enumTypeTranslator={mountTargetStatusTranslator}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetStatus}
                    itemProperty={GetStatusTranslation}
                    key={Contract.EntityModelProperty.OciFssMountTargetStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.OciFssMountTargetModel>(
                            Contract.TypeNames.OciFssFileSystem,
                            item => item.fileSystemIdReferences,
                            entityTypeNameTranslator(Contract.TypeNames.OciFssFileSystem))}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciFssMountTargetFileSystems)}
                                    placeholder={
                                        entityTypeNameTranslator(
                                            Contract.TypeNames.OciFssFileSystem,
                                            { count: 0 })}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetFileSystems}
                    key={Contract.EntityModelProperty.OciFssMountTargetFileSystems}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.OciFssMountTargetModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.fileSystemIdReferences}
                                entityTypeName={Contract.TypeNames.OciFssFileSystem}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciFssFileSystem,
                            { count: 0 })}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.OciFssMountTargetModel>(
                            Contract.TypeNames.OciFssMountTargetExport,
                            item => item.exportIds,
                            entityTypeNameTranslator(Contract.TypeNames.OciFssMountTargetExport))}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciFssMountTargetExports)}
                                    placeholder={
                                        entityTypeNameTranslator(
                                            Contract.TypeNames.OciFssMountTargetExport,
                                            { count: 0 })}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetExports}
                    key={Contract.EntityModelProperty.OciFssMountTargetExports}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.OciFssMountTargetModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.exportIds}
                                entityTypeName={Contract.TypeNames.OciFssMountTargetExport}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciFssMountTargetExport,
                            { count: 0 })}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.OciFssMountTargetModel>(
                            Contract.TypeNames.OciNetworkingVcn,
                            item => [item.vcnIdReference],
                            entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn))}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciFssMountTargetVcn)}
                                    placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetVcn}
                    key={Contract.EntityModelProperty.OciFssMountTargetVcn}
                    render={
                        optionalTableCell<Contract.OciFssMountTargetModel>(
                            item =>
                                _.isNil(item.vcnIdReference)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={item.vcnIdReference}
                                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>)}
                    title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciFssMountTargetModel) => ({
                                [localization.columns.privateIpAddress()]: (item.entity as Contract.OciFssMountTarget).privateIpAddress
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciFssMountTargetPrivateIpAddress)}
                                    placeholder={localization.columns.privateIpAddress()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetPrivateIpAddress}
                    itemProperty={(item: Contract.OciFssMountTargetModel) => (item.entity as Contract.OciFssMountTarget).privateIpAddress}
                    key={Contract.EntityModelProperty.OciFssMountTargetPrivateIpAddress}
                    selectorOptions={{ default: false }}
                    title={localization.columns.privateIpAddress()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.OciFssMountTargetModel>(
                            Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                            item => item.networkSecurityGroupIdReferences,
                            entityTypeNameTranslator(Contract.TypeNames.OciNetworkingNetworkSecurityGroup))}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciFssMountTargetNetworkSecurityGroups)}
                                    placeholder={
                                        entityTypeNameTranslator(
                                            Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                                            { count: 0 })}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciFssMountTargetNetworkSecurityGroups}
                    key={Contract.EntityModelProperty.OciFssMountTargetNetworkSecurityGroups}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.OciFssMountTargetModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.networkSecurityGroupIdReferences}
                                entityTypeName={Contract.TypeNames.OciNetworkingNetworkSecurityGroup}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                            { count: 0 })}/>,
                commonResourceDefinition.columns.creatorCsvColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciFssMountTargetRequest(
                new Contract.EntityControllerGetEntityModelPageOciFssMountTargetRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetExports]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetFileSystems]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetNetworkSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetPrivateIpAddress]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciFssMountTargetVcn])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}