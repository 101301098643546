import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, ProfileTopbarTimeInfoItem, ProfileTopbarTimeInfoItemType } from "../../../../../../../../../common";
import { ProfileTopbarStatusInfoItem } from "./components";

export function useCommonInfo(riskModel: Contract.RiskModel) {
    const riskStatusToProfileTopbarTimeInfoItemTypeMap: Dictionary<ProfileTopbarTimeInfoItemType> = {
        [Contract.RiskStatus.Closed]: "closed",
        [Contract.RiskStatus.Ignored]: "ignored",
        [Contract.RiskStatus.Open]: "opened"
    };

    return useMemo(
        () => ({
            ignoreExpirationDate:
                riskModel.risk.status === Contract.RiskStatus.Ignored &&
                !_.isNil(riskModel.risk.ignoreExpirationDate)
                    ? <ProfileTopbarTimeInfoItem
                        key="ignoreExpirationDate"
                        time={riskModel.risk.ignoreExpirationDate}
                        type={"ignoreExpirationDate"}/>
                    : undefined,
            openStatusUpdateTime:
                riskModel.risk.status !== Contract.RiskStatus.Open
                    ? <ProfileTopbarTimeInfoItem
                        key="open"
                        time={riskModel.risk.openStatusUpdateTime}
                        type="opened"/>
                    : undefined,
            status:
                <ProfileTopbarStatusInfoItem
                    key="status"
                    risk={riskModel.risk}/>,
            statusUpdateTime:
                <ProfileTopbarTimeInfoItem
                    key="statusUpdateTime"
                    time={riskModel.risk.statusUpdateTime}
                    type={riskStatusToProfileTopbarTimeInfoItemTypeMap[riskModel.risk.status]}/>
        }),
        [riskModel]);
}