import { ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, scopeSystemEntityModelStore } from "../../../common";

type PermissionEligibilityFilterProps = {
    emptyValue: boolean;
    permissionEligibilityIds: string[];
    placeholder: string;
};

export function PermissionEligibilityFilter({ emptyValue, permissionEligibilityIds, placeholder }: PermissionEligibilityFilterProps) {
    const permissionEligibilityModels = scopeSystemEntityModelStore.useGet(permissionEligibilityIds) as Contract.PermissionEligibilityModel[];
    return (
        <ValuesFilter
            emptyValueOptions={{ enabled: emptyValue }}
            placeholder={placeholder}>
            {_.map(
                permissionEligibilityModels,
                permissionEligibilityModel =>
                    <ValuesFilterItem
                        key={permissionEligibilityModel.configuration.id}
                        title={(permissionEligibilityModel.configuration as Contract.PermissionEligibilityConfiguration).name}
                        value={permissionEligibilityModel.configuration.id}/>)}
        </ValuesFilter>);
}