import { Box, ListItemButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../..";
import { ObjectSelectorItem } from "../../../ObjectSelector";

type ItemProps = {
    item: ObjectSelectorItem;
    onHovered?: () => void;
    onSelected: () => void;
    selected: boolean;
};

export function Item({ item, onHovered, onSelected, selected }: ItemProps) {
    const theme = useTheme();
    return (
        <ListItemButton
            disableGutters={true}
            sx={{
                alignItems: "center",
                background:
                    selected
                        ? `${theme.palette.action.selected} !important`
                        : undefined,
                color: theme.palette.text.primary,
                height: "30px",
                padding: theme.spacing(0, 2)
            }}
            onClick={() => onSelected()}
            onMouseEnter={() => onHovered?.()}>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={1}
                sx={{
                    fontSize: "16px",
                    height: "28px",
                    width: "100%"
                }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={0.75}
                    sx={{ overflow: "hidden" }}>
                    {!_.isNil(item.options?.icon) &&
                        <Box sx={{ fontSize: "16px" }}>
                            {item.options?.icon}
                        </Box>}
                    {_.isString(item.title)
                        ? <Typography
                            noWrap={true}
                            sx={{ lineHeight: 1 }}>
                            {item.title}
                        </Typography>
                        : item.title}
                </Stack>
            </Stack>
        </ListItemButton>);
}