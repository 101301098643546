import { Box, CircularProgress, Collapse, Skeleton, Stack, SxProps, Table, TableBody, Typography, useTheme } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import _, { Dictionary } from "lodash";
import React, { Children, memo, MutableRefObject, ReactNode, Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { Action0, Action1, AppError, CsvExportButton, CsvExportButtonProps, EmptyMessageSize, EmptyMessageText, EventHandlerRegister, ExportFileNameOptions, Filter, Filters, FiltersActions, getChildElements, InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, InfiniteScrollItemsLoader, makeContextProvider, MouseClickAreaScope, Optional, SearchTextField, SelectionActions, SelectionActionsAction, selectionActionsHeight, SelectionActionsItemPermissions, Shadows, StorageItem, ToggleFilters, useActions, useChangeEffect, useDeepDependency, useEvent, useLayoutChangeEffect, useLocalization, useUncaptureValue, useUniqueKey } from "@infrastructure";
import { DataTableAction, DataTableColumn, DataTableColumnProps, DataTableHeader, DataTableRow, DataTableRowPlaceholder, DataTableSummaryToggle } from ".";
import { DataTableColumnMultiSelect } from "./components/DataTableColumnMultiSelect";

const DataTableMemo = memo(DataTable);
export { DataTableMemo as DataTable };

export const DataTableClassName = "DataTable";

export function getColumnFilters(columnProps: DataTableColumnProps) {
    return _.isArray(columnProps.filterOptions!.itemOrItems)
        ? columnProps.filterOptions!.itemOrItems
        : _.isNil(columnProps.filterOptions!.itemOrItems)
            ? []
            : [columnProps.filterOptions!.itemOrItems!];
}

export type DataTableProps<TSummary extends DataTableSummary> = {
    actionsRef?: Ref<Optional<DataTableActions>>;
    cellMaxWidth?: "large" | "medium" | "none" | "small";
    children: ReactNode;
    columnOptions?: DataTableColumnOptions;
    emptyMessageOptions?: DataTableEmptyMessageOptions;
    exportOptions?: DataTableExportOptions;
    externalLoading?: boolean;
    fetchItems?: DataTableFetchItems<TSummary>;
    fetchItemsInterval?: number;
    filtersOptions?: DataTableFiltersOptions;
    getItemId: (item: any) => string;
    highlightItem?: DataTableHighlightedItemOptions;
    manualScroll?: boolean;
    onItemCountChanged?: (itemCount?: number) => void;
    onLoadedItemsChanged?: (loadedItems: any[]) => void;
    onSortChanged?: Action1<Optional<DataTableSort>>;
    pageSize?: number;
    rowOptions?: DataTableRowOptions;
    searchOptions?: DataTableSearchOptions;
    selectionOptions?: DataTableSelectionOptions;
    sortOptions?: DataTableSortOptions;
    summarySection?: (props: DataTableSummaryProps<TSummary>) => JSX.Element;
    sx?: SxProps;
    variant?: DataTableVariant;
    virtualizationEnabled?: boolean;
};

export type DataTableVariant = "card" | "view" | "filtersOnly" | "exportButtonOnly";

export interface TableReloadOptions {
    preventItemCountLoading?: boolean;
    refreshColumns?: boolean;
    refreshFilters?: boolean;
}

export interface TableResetOptions {
    clearFilterAndSort?: boolean;
    refreshColumns?: boolean;
    refreshFilters?: boolean;
}

export type DataTableActions = {
    addFilter: (columnId: string) => void;
    fetchItems: Action0;
    getFilters: (columnId: string) => any[];
    getFiltersTime: () => Optional<string>;
    getItemId: (item: any) => string;
    getLoadedItems: () => any[];
    getSelectedItemIds: () => string[];
    getSelectorEnabledVisibleColumnElements: () => React.FunctionComponentElement<DataTableColumnProps>[];
    getSelectorVisibleColumnIds: () => string[];
    refreshColumns: () => void;
    refreshFilters: () => void;
    registerItemsLoaded: EventHandlerRegister<(loaded: boolean) => void>;
    reload: (options?: TableReloadOptions) => Promise<void>;
    reset: (options?: TableResetOptions) => void;
    setFilter: (columnId: string, updateFilter: (existingFilter: any) => any) => void;
    setSelectedItemIds: (selectedItemIds: string[]) => void;
    setSelectedVisibleColumnIds: (selectedItemIds: string[]) => void;
};

export type DataTableColumnOptions = {
    orderOptions?: DataTableColumnOptionsOrderOptions;
    resizable?: boolean;
    selectorOptions?: DataTableColumnOptionsSelectorOptions;
    stickyColumnId?: string;
};

type DataTableColumnOptionsOrderOptions = {
    disabled?: boolean;
    enabled?: boolean;
    persistenceStorageItem?: StorageItem;
};

type DataTableColumnOptionsSelectorOptions = {
    enabled?: boolean;
    enabledExternal?: boolean;
    onChanged?: Action1<string[]>;
    persistenceStorageItem?: StorageItem;
};

type DataTableExportOptions = {
    fileNamePrefix: string;
    getData?: (itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<any>>, items: any[]) => any;
    onExportClick?: (filterMap: Dictionary<any>, fileNameOptions: ExportFileNameOptions) => Promise<void> | void;
    showLimitMessage?: boolean;
};

type DataTableEmptyMessageOptions = {
    emptyMessageText?: EmptyMessageText;
    size?: EmptyMessageSize;
    sx?: SxProps;
};

export type DataTableFilterExternalOptions = {
    actionsRef: MutableRefObject<Optional<FiltersActions>>;
    active: string[];
    visible: string[];
};

export type DataTableFiltersOptions = {
    external?: DataTableFilterExternalOptions;
    initial?: {
        state?: Dictionary<any>;
    };
    itemCount?: boolean;
    onChanged?: Action1<Dictionary<any>>;
    persist?: DataTableFilterPersistOptions;
    sx?: SxProps;
    waitForReset?: boolean;
};

interface DataTableFilterPersistOptions {
    filterMap?: boolean;
    visibilityStorageItem?: StorageItem;
}

export type DataTableHighlightedItemOptions = {
    getItemId?: (item: any) => string;
    hashRouteTemplate?: string;
};

export interface DataTableRowOptions {
    getHighlightColor?: (item: any, opacity?: number) => Optional<string>;
    getSx?: (item?: any) => Optional<SxProps>;
    getUrl?: (item: any) => Optional<string>;
    onBeforeClick?: (event: any, item: any) => boolean;
}

export interface DataTableSort {
    columnId: string;
    direction: DataTableSortDirection;
}

export interface DataTableSortOptions {
    enabled?: boolean;
    reload?: boolean;
}

export enum DataTableSortType {
    Alphabetic = "Alphabetic",
    Date = "Date",
    Numeric = "Numeric"
}

export enum DataTableSortDirection {
    Ascending = 1,
    Descending = 2
}

export type DataTableFetchItems<TSummary extends DataTableSummary> = (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => Promise<DataTableFetchItemsResult<TSummary>> | DataTableFetchItemsResult<TSummary> | any[];

export class DataTableFetchItemsResult<TSummary extends DataTableSummary> {
    constructor(
        public summaryOrGetSummary: TSummary | (() => Promise<TSummary>),
        public items: any[],
        public lastItems: boolean,
        public options?: DataTableFetchItemsResultOptions) {
    }
}

interface DataTableFetchItemsResultOptions {
    itemIdToPermissionsMap?: Dictionary<string[]>;
    onAppendData?: Action0;
}

export class DataTableSearchOptions {
    constructor(
        public filterId: string,
        public placeholder: string,
        public sx?: SxProps) {
    }
}

export interface DataTableSelectionOptions {
    disabled?: boolean;
    getItemIdToPermissionsMap?: (filterMap: Dictionary<any>) => Promise<Dictionary<string[]>>;
    onChanged?: (selectedItemIds: string[], selectionPermissions?: SelectionActionsItemPermissions) => void;
    permissionsTranslator?: (permissions: string[]) => string;
    persistence?: boolean;
    showActions?: boolean;
    visible?: boolean;
}

export type DataTableSummary = {
    count: number;
};

export type DataTableSummaryProps<TSummary extends DataTableSummary> = {
    data: TSummary;
    filterActionsRef: MutableRefObject<Optional<FiltersActions>>;
    filterMap: Dictionary<any>;
    loading: boolean;
};

class DataTableActionsContext {
    constructor(public actions: DataTableActions) {
    }
}

export const [useDataTableActionsContext, , useDataTableActionsContextProvider] = makeContextProvider<DataTableActionsContext>();

export class DataTableSelectionContext {
    constructor(
        public firstPageLoading: boolean,
        public itemCount: number,
        public itemIdToSelectionEnabledMap: Optional<Dictionary<boolean>>,
        public loadSelectionData: Optional<() => Promise<void>>,
        public selectedItemIds: string[]) {
    }
}

export const [useDataTableSelectionContext, , useDataTableSelectionContextProvider] = makeContextProvider<DataTableSelectionContext>();

class DataTableColumnContext {
    constructor(public stickyColumnWidth: number) {
    }
}

export const [, useSetDataTableColumnContext, useDataTableColumnContextProvider] = makeContextProvider<DataTableColumnContext>();

function DataTable<TSummary extends DataTableSummary>({ actionsRef, cellMaxWidth = "large", children, columnOptions, emptyMessageOptions, exportOptions, externalLoading, fetchItems, fetchItemsInterval, filtersOptions, getItemId, highlightItem, manualScroll = false, onItemCountChanged, onLoadedItemsChanged, onSortChanged, pageSize = 30, rowOptions, searchOptions, selectionOptions, sortOptions = { enabled: true }, summarySection: SummarySection, sx, variant = "view", virtualizationEnabled = false }: DataTableProps<TSummary>) {
    const internalFiltersActionsRef = useRef<FiltersActions | undefined>();
    const [internalVisibleFilters, setInternalVisibleFilters] = useState<string[]>([]);
    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>();
    const itemIdToPermissionsMapRef = useRef<Dictionary<string[]>>();
    const loadedItemCountRef = useRef(0);
    const pagedItemsRef = useRef(true);
    const resetDataRef = useRef({});
    const tableRef = useRef<HTMLTableElement>(null);
    const [items, setItems] = useState<any[]>([]);
    const [summaryData, setSummaryData] = useState<TSummary>({ count: 0 } as TSummary);
    const [summaryVisible, setSummaryVisible] = useState<boolean>(true);
    const [itemCount, setItemCount] = useState<number>();
    const [itemCountLoading, setItemCountLoading] = useState<boolean>(false);
    const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
    const [columnsKey, updateColumnsKey] = useUniqueKey();
    const { actionElements, columnElements, selectionActionsActionElements } =
        useMemo(
            () => {
                const childrenArray = Children.toArray(children);
                const columnElements = getChildElements(childrenArray, DataTableColumn);
                const selectionActionsActionElements = getChildElements(childrenArray, SelectionActionsAction);
                const actionElements = getChildElements(childrenArray, DataTableAction);

                return { actionElements, columnElements, selectionActionsActionElements };
            },
            [children]);
    const columnElementsDeepDependency =
        useDeepDependency(
            _.map(
                columnElements,
                columnElement => columnElement.props.id));
    const selectionActionsActionElementsDeepDependency =
        useDeepDependency(
            _.map(
                selectionActionsActionElements,
                selectionActionsActionElement => selectionActionsActionElement.props.id));

    const [filtersKey, updateFiltersKey] = useUniqueKey();
    const [filterColumnElements, toggleFilterColumnElements] =
        useMemo(
            () =>
                _(columnElements).
                    filter(columnElement => !_.isEmpty(columnElement.props.filterOptions)).
                    partition(filterColumn => !filterColumn.props.filterOptions?.toggleItem).
                    value(),
            [columnElementsDeepDependency, filtersKey]);

    const [filtersInitialFilterMap, toggleFiltersInitialFilterMap] =
        useMemo(
            () => {
                const filtersInitialFilterMap =
                    _.pick(
                        filtersOptions?.initial?.state ?? {},
                        _.flatMap(
                            filterColumnElements,
                            filterColumnElement =>
                                _.map(
                                    getColumnFilters(filterColumnElement.props),
                                    filterItem => filterItem.id || filterColumnElement.props.id)
                        ));

                const toggleFiltersInitialFilterMap =
                    _.pick(
                        filtersOptions?.initial?.state ?? {},
                        _.map(
                            toggleFilterColumnElements,
                            filterColumnElement => filterColumnElement.props.id));

                return [filtersInitialFilterMap, toggleFiltersInitialFilterMap];
            },
            [filterColumnElements, filtersOptions?.initial?.state, toggleFilterColumnElements]);

    const [filterMap, setFilterMap] = useState<Dictionary<any>>(filtersInitialFilterMap);
    const [toggleFilterMap, setToggleFilterMap] = useState<Dictionary<any>>(toggleFiltersInitialFilterMap);
    const [sort, setSort] = useState<DataTableSort>();
    const [searchText, setSearchText] = useState<string>();
    const uncaptureFetchItems = useUncaptureValue(fetchItems);

    const fetchItemsPromiseOrResult =
        useCallback(
            (limit: number, skip = 0) => {
                const fetchFilterMap = {
                    ...filterMap,
                    ...toggleFilterMap
                };
                if (!_.isNil(searchOptions)) {
                    fetchFilterMap[searchOptions.filterId] = searchText;
                }
                return uncaptureFetchItems(
                    fetchItems =>
                        _.isNil(fetchItems)
                            ? []
                            : fetchItems(
                                fetchFilterMap,
                                sort,
                                skip,
                                limit));
            },
            [filterMap, searchOptions, searchText, sort, toggleFilterMap, uncaptureFetchItems]);
    const getSummaryIdRef = useRef<string>();
    const fetchData =
        useCallback(
            async (limit?: number, skip?: number, preventItemCountLoading?: boolean) => {
                skip ??= loadedItemCountRef.current;
                const itemsPromiseOrResult =
                    fetchItemsPromiseOrResult(
                        limit ?? pageSize,
                        skip);

                if (itemsPromiseOrResult instanceof Promise ||
                    itemsPromiseOrResult instanceof DataTableFetchItemsResult) {
                    setItemCountLoading(!preventItemCountLoading && skip === 0);
                    let getSummaryRequestId: Optional<string> = undefined;
                    if (skip === 0) {
                        getSummaryRequestId = uuid();
                        getSummaryIdRef.current = getSummaryRequestId;
                    }
                    const fetchItemsResult = await itemsPromiseOrResult;
                    if (_.isEmpty(fetchItemsResult.items)) {
                        setItemsLoaded(true);
                        triggerItemsLoaded(true);
                    }
                    return new InfiniteScrollFetchDataResult(
                        !_.isEmpty(fetchItemsResult.items),
                        fetchItemsResult.lastItems,
                        () => {
                            setItems(
                                items =>
                                    skip === 0
                                        ? fetchItemsResult.items
                                        : _.concat(items, fetchItemsResult.items));
                            if (_.isFunction(fetchItemsResult.summaryOrGetSummary)) {
                                if (skip === 0) {
                                    fetchItemsResult.
                                        summaryOrGetSummary().
                                        then(
                                            summary => {
                                                if (getSummaryIdRef.current === getSummaryRequestId &&
                                                    !_.isNil(getSummaryRequestId)) {
                                                    setItemCount(summary.count);
                                                    setSummaryData(summary);
                                                    setItemCountLoading(false);
                                                }
                                            });
                                }
                            } else {
                                setItemCount(fetchItemsResult.summaryOrGetSummary.count);
                                setItemCountLoading(false);
                            }

                            fetchItemsResult.options?.onAppendData?.();

                            itemIdToPermissionsMapRef.current =
                                skip === 0
                                    ? fetchItemsResult.options?.itemIdToPermissionsMap
                                    : _.merge(
                                        itemIdToPermissionsMapRef.current,
                                        fetchItemsResult.options?.itemIdToPermissionsMap);
                            loadedItemCountRef.current =
                                skip === 0
                                    ? fetchItemsResult.items.length
                                    : loadedItemCountRef.current + fetchItemsResult.items.length;
                        });
                } else {
                    if (_.isEmpty(itemsPromiseOrResult)) {
                        setItemsLoaded(true);
                        triggerItemsLoaded(false);
                    }
                    pagedItemsRef.current = false;
                    return new InfiniteScrollFetchDataResult(
                        !_.isEmpty(itemsPromiseOrResult),
                        true,
                        () => {
                            setItems(() => itemsPromiseOrResult);
                            setItemCount(itemsPromiseOrResult.length);
                            loadedItemCountRef.current = itemsPromiseOrResult.length;
                        });
                }
            },
            [fetchItemsPromiseOrResult, pageSize]);

    const rowOptionsRef = useRef(rowOptions);
    const filtersActionsRef =
        useMemo(
            () => filtersOptions?.external?.actionsRef ?? internalFiltersActionsRef,
            [filtersOptions?.external?.actionsRef]);
    const visibleFilters =
        useMemo(
            () => filtersOptions?.external?.visible ?? internalVisibleFilters,
            [filtersOptions?.external?.visible, internalVisibleFilters]);
    const activeFilters =
        useMemo(
            () => filtersOptions?.external?.active ?? _.keys(filterMap),
            [filterMap, filtersOptions?.external?.active]);
    const [columnIds, visibleColumnElementMap] =
        useMemo(
            () => {
                const columnIds =
                    _.map(
                        columnElements,
                        columnElement => columnElement.props.id);

                const visibleColumnElementMap =
                    _(columnElements).
                        filter(
                            dataTableColumnElement =>
                                !_.isNil(dataTableColumnElement.props.itemProperty) ||
                                !_.isNil(dataTableColumnElement.props.render)).
                        reduce(
                            (agg, column) => {
                                (agg as Record<string, React.FunctionComponentElement<DataTableColumnProps>>)[column.props.id] = column;
                                return agg;
                            },
                            {} as Record<string, React.FunctionComponentElement<DataTableColumnProps>>);

                return [columnIds, visibleColumnElementMap];
            },
            [columnElementsDeepDependency, columnsKey]);

    function getPersistenceOrderedColumnIds() {
        if (columnOptions?.orderOptions?.enabled) {
            const persistenceOrderedColumnIdsJson = columnOptions?.orderOptions?.persistenceStorageItem?.getValue();
            if (!_.isNil(persistenceOrderedColumnIdsJson)) {
                return JSON.parse(persistenceOrderedColumnIdsJson);
            }
        }

        return undefined;
    }

    const [orderedColumnIds, setOrderedColumnIds] =
        useState<string[]>(
            () => {
                const persistenceOrderedColumnIds = getPersistenceOrderedColumnIds();
                let orderedColumnIds: Optional<string[]> = undefined;
                if (!_.isNil(persistenceOrderedColumnIds)) {
                    orderedColumnIds = _.intersection(persistenceOrderedColumnIds, columnIds);
                }

                orderedColumnIds ??= columnIds;
                if (columnOptions?.stickyColumnId) {
                    return _.sortBy(
                        orderedColumnIds,
                        orderedColumnId => orderedColumnId !== columnOptions.stickyColumnId);
                }

                return orderedColumnIds;
            });
    useEffect(
        () =>
            setOrderedColumnIds(
                orderedColumnIds => {
                    if (columnOptions?.orderOptions?.disabled) {
                        return columnIds;
                    }

                    if (!_(columnIds).
                        xor(orderedColumnIds).
                        isEmpty()) {
                        const persistenceOrderedColumnIds = getPersistenceOrderedColumnIds();
                        return _.orderBy(
                            columnIds,
                            columnId => {
                                if (columnOptions?.stickyColumnId === columnId) {
                                    return 0;
                                }

                                const orderedColumnIndex = _.indexOf(persistenceOrderedColumnIds ?? orderedColumnIds, columnId);
                                return orderedColumnIndex >= 0
                                    ? orderedColumnIndex
                                    : _.indexOf(columnIds, columnId) - 1;
                            });
                    }

                    return orderedColumnIds;
                }),
        [columnElementsDeepDependency]);
    const visibleOrderedColumnElements =
        useMemo(
            () =>
                _(orderedColumnIds).
                    map(columnId => visibleColumnElementMap[columnId]).
                    filter(column => !!column).
                    value(),
            [orderedColumnIds, visibleColumnElementMap]);

    const selectorEnabledVisibleColumnElements =
        useMemo(
            () =>
                _.filter(
                    visibleOrderedColumnElements,
                    visibleOrderedColumnElement => !visibleOrderedColumnElement.props.selectorOptions?.disabled),
            [visibleOrderedColumnElements]);

    const getSelectedVisibleColumnIds =
        useCallback(
            (includeDisabledColumns = true) => {
                const selectedVisibleColumnIds =
                    _(visibleOrderedColumnElements).
                        filter(
                            visibleOrderedColumnElement =>
                                !visibleOrderedColumnElement.props.selectorOptions?.disabled &&
                                visibleOrderedColumnElement.props.selectorOptions?.default !== false).
                        map(visibleColumnElement => visibleColumnElement.props.id).
                        value();

                if (_.isNil(columnOptions?.selectorOptions?.persistenceStorageItem)) {
                    return selectedVisibleColumnIds;
                }

                const columnIdToVisibleMapJson = columnOptions!.selectorOptions!.persistenceStorageItem.getValue();
                if (_.isNil(columnIdToVisibleMapJson) || _.isEmpty(JSON.parse(columnIdToVisibleMapJson))) {
                    return selectedVisibleColumnIds;
                }

                const columnIdToVisibleMap = JSON.parse(columnIdToVisibleMapJson) as Dictionary<boolean>;
                return _(visibleOrderedColumnElements).
                    filter(
                        visibleOrderedColumnElement => {
                            if (visibleOrderedColumnElement.props.selectorOptions?.disabled) {
                                return includeDisabledColumns;
                            }

                            return columnIdToVisibleMap[visibleOrderedColumnElement.props.id] ?? visibleOrderedColumnElement.props.selectorOptions?.default ?? true;
                        }).
                    map(visibleColumnElement => visibleColumnElement.props.id).
                    value();
            },
            [columnOptions?.selectorOptions, visibleOrderedColumnElements]);

    const [selectedVisibleColumnIds, setSelectedVisibleColumnIds] = useState<string[]>(getSelectedVisibleColumnIds(false));
    useChangeEffect(
        () => setSelectedVisibleColumnIds(getSelectedVisibleColumnIds(false)),
        [visibleOrderedColumnElements]);

    const [registerItemsLoaded, triggerItemsLoaded] = useEvent<(loaded: boolean) => void>();
    const actions =
        useActions<DataTableActions>(
            actionsRef,
            {
                addFilter(columnId) {
                    filtersActionsRef.current?.add(columnId);
                },
                fetchItems() {
                    infiniteScrollActionsRef.current?.fetchData();
                },
                getFilters(columnId) {
                    return filterMap?.[columnId];
                },
                getFiltersTime() {
                    return filtersActionsRef.current?.getTime();
                },
                getItemId(item) {
                    return getItemId(item);
                },
                getLoadedItems() {
                    return items;
                },
                getSelectedItemIds() {
                    return selectedItemIds;
                },
                getSelectorEnabledVisibleColumnElements() {
                    return selectorEnabledVisibleColumnElements;
                },
                getSelectorVisibleColumnIds() {
                    return getSelectedVisibleColumnIds(false);
                },
                refreshColumns() {
                    updateColumnsKey();
                },
                refreshFilters() {
                    updateFiltersKey();
                },
                registerItemsLoaded,
                async reload({ preventItemCountLoading, refreshColumns, refreshFilters } = {}) {
                    const fetchDataResult = await fetchData(Math.max(items.length, pageSize), 0, preventItemCountLoading);
                    infiniteScrollActionsRef.current!.update(fetchDataResult);

                    if (!selectionOptions?.persistence &&
                        !_.isEmpty(selectedItemIds)) {
                        setSelectedItemIds([]);
                    }

                    setItemsLoaded(true);
                    triggerItemsLoaded(true);

                    rowOptionsRef.current = rowOptions;

                    if (refreshFilters) {
                        this.refreshFilters();
                    }

                    if (refreshColumns) {
                        this.refreshColumns();
                    }
                },
                reset({ clearFilterAndSort, refreshColumns, refreshFilters } = {}) {
                    if (pagedItemsRef.current) {
                        setItems([]);
                        setItemCount(undefined);
                        infiniteScrollActionsRef.current?.reset();

                        itemIdToPermissionsMapRef.current = undefined;
                        loadedItemCountRef.current = 0;
                    } else {
                        const itemsPromiseOrResult = fetchItemsPromiseOrResult(pageSize);

                        if (itemsPromiseOrResult instanceof Promise ||
                            itemsPromiseOrResult instanceof DataTableFetchItemsResult) {
                            throw new AppError("Cannot use async results on sync data table");
                        } else {
                            setItems(itemsPromiseOrResult);
                            setItemCount(itemsPromiseOrResult.length);
                            infiniteScrollActionsRef.current?.setHasData(!_.isEmpty(itemsPromiseOrResult));
                        }
                    }

                    if (!selectionOptions?.persistence &&
                        !_.isEmpty(selectedItemIds)) {
                        setSelectedItemIds([]);
                    }

                    setItemsLoaded(false);
                    triggerItemsLoaded(false);
                    rowOptionsRef.current = rowOptions;

                    if (clearFilterAndSort) {
                        setFilterMap({});
                        setToggleFilterMap({});
                        setSort(undefined);
                        setSearchText(undefined);
                        setHasSearchText(false);

                        resetDataRef.current = {
                            filterMap: {},
                            sort: undefined,
                            toggleFilterMap: {}
                        };
                    }

                    if (refreshFilters) {
                        this.refreshFilters();
                    }

                    if (refreshColumns) {
                        this.refreshColumns();
                    }
                },
                setFilter(columnId, updateFilter) {
                    filtersActionsRef.current?.set(columnId, updateFilter);
                },
                setSelectedItemIds(itemIds) {
                    setSelectedItemIds(itemIds);
                },
                setSelectedVisibleColumnIds(selectedIds) {
                    selectedVisibleColumnsIds(selectedIds);
                    columnOptions?.selectorOptions?.onChanged?.(selectedIds);
                }
            });

    const selectedVisibleColumnsIds =
        useCallback(
            (selectedIds: string[]) => {
                if (!_.isEqual(selectedIds, selectedVisibleColumnIds)) {
                    actions.reset();
                    setSelectedVisibleColumnIds(selectedIds);
                }
            },
            [actions, selectedVisibleColumnIds, setSelectedVisibleColumnIds]);

    const [, , ActionsContextProvider] = useDataTableActionsContextProvider(() => new DataTableActionsContext(actions));
    const [refreshSelection, setRefreshSelection] = useState({});
    const [itemIdToSelectionEnabledMap, itemSelectionEnabled] =
        useMemo(
            () => {
                if (selectionOptions?.visible === false) {
                    return [{}, false];
                }

                const itemIdToSelectionEnabledMap: Dictionary<boolean> =
                    _.isNil(itemIdToPermissionsMapRef.current)
                        ? _(items).
                            keyBy(item => getItemId(item)).
                            mapValues(() => true).
                            value()
                        : _.mapValues(
                            itemIdToPermissionsMapRef.current,
                            () => true);
                const itemSelectionEnabled =
                    !_.isNil(selectionOptions?.onChanged) ||
                    !_.isEmpty(selectionActionsActionElements);

                if (!_.isEmpty(selectionActionsActionElements) &&
                    _.every(
                        selectionActionsActionElements,
                        selectionActionsActionElement => !_.isNil(selectionActionsActionElement.props.permissions))) {
                    const selectionActionPermissions =
                        _(selectionActionsActionElements).
                            flatMap(selectionActionsActionElement => selectionActionsActionElement.props.permissions!).
                            uniq().
                            value();

                    _.each(
                        itemIdToPermissionsMapRef.current,
                        (itemPermissions, itemId) => {
                            itemIdToSelectionEnabledMap[itemId] =
                                !_(itemPermissions).
                                    intersection(selectionActionPermissions).
                                    isEmpty();
                        });
                }

                return [itemIdToSelectionEnabledMap, itemSelectionEnabled];
            },
            [getItemId, items, refreshSelection, selectionActionsActionElementsDeepDependency, selectionOptions]);

    const selectAllItemsEnabled = itemSelectionEnabled && !_.isNil(itemCount);

    const selectedItems =
        useMemo(
            () =>
                _.filter(
                    items,
                    item => _.includes(selectedItemIds, getItemId(item))),
            [getItemId, items, selectedItemIds]);

    const uncaptureLoadSelectionData =
        useUncaptureValue(
            async () => {
                if (_.size(itemIdToPermissionsMapRef.current) !== itemCount) {
                    try {
                        itemIdToPermissionsMapRef.current = await selectionOptions!.getItemIdToPermissionsMap!(filterMap);
                    } finally {
                        setRefreshSelection({});
                    }
                }
            });

    const [, , SelectionContextProvider] =
        useDataTableSelectionContextProvider(
            () =>
                new DataTableSelectionContext(
                    _.isNil(itemCount),
                    itemCount ?? 0,
                    itemIdToSelectionEnabledMap,
                    pagedItemsRef.current
                        ? () => uncaptureLoadSelectionData(loadSelectionData => loadSelectionData())
                        : undefined,
                    selectedItemIds),
            [itemCount, itemIdToSelectionEnabledMap, selectedItemIds]);

    useLayoutChangeEffect(
        () => {
            onLoadedItemsChanged?.(items);
        },
        [items]);

    useLayoutChangeEffect(
        () => {
            onItemCountChanged?.(itemCount);
        },
        [itemCount]);

    const selectionActionsItemPermissions =
        useMemo(
            () => {
                if (_.isEmpty(selectedItemIds)) {
                    return undefined;
                }

                const allPermissions =
                    _(selectedItemIds).
                        flatMap(selectedItemId => itemIdToPermissionsMapRef.current?.[selectedItemId] ?? []).
                        uniq().
                        value();
                const commonPermissions =
                    _(selectedItemIds).
                        map(selectedItemId => itemIdToPermissionsMapRef.current?.[selectedItemId] ?? []).
                        reduce(
                            (aggregatedCommonPermissions, selectedItemPermissions) =>
                                _.intersection(
                                    aggregatedCommonPermissions,
                                    selectedItemPermissions))!;
                return new SelectionActionsItemPermissions(
                    allPermissions,
                    commonPermissions);
            },
            [selectedItemIds]);

    useChangeEffect(
        () => {
            selectionOptions?.onChanged?.(
                selectedItemIds,
                selectionActionsItemPermissions);
        },
        [selectedItemIds]);

    const toolbarElementRef = useRef<HTMLDivElement | null>(null);
    const localization =
        useLocalization(
            "infrastructure.dataTable.dataTable",
            () => ({
                filters: "Filters",
                itemCount: [
                    "1 result",
                    "{{count | NumberFormatter.humanize}} items"
                ]
            }));

    const theme = useTheme();
    const [filterIds, filterElements, toggleFilterElements] =
        useMemo(
            () => {
                if (filtersOptions?.external) {
                    const externalFilterIds =
                        _(filterColumnElements).
                            filter(element => !!element.props.filterOptions?.externalId).
                            map(filterColumnElement => filterColumnElement.props.filterOptions!.externalId!).
                            value();
                    return [externalFilterIds];
                }

                const filters =
                    _.filter(
                        filterColumnElements,
                        filterColumnElement => !filterColumnElement.props.filterOptions?.externalId);
                const filterIds =
                    _.map(
                        filters,
                        filterColumnElement => filterColumnElement.props.id);
                const filterElements =
                    _.flatMap(
                        filters,
                        filterColumnElement =>
                            _.map(
                                getColumnFilters(filterColumnElement.props),
                                filterItem =>
                                    <Filter
                                        default={filterItem.default}
                                        id={filterItem.id || filterColumnElement.props.id}
                                        key={filterItem.id || filterColumnElement.props.id}
                                        title={filterItem.title || filterColumnElement.props.title}>
                                        {filterItem.element}
                                    </Filter>));
                const toggleFilterElements =
                    _(toggleFilterColumnElements).
                        map(filterColumnElement =>
                            <Filter
                                id={filterColumnElement.props.id}
                                key={filterColumnElement.props.id}>
                                {filterColumnElement.props.filterOptions?.toggleItem}
                            </Filter>).
                        value();
                return [filterIds, filterElements, toggleFilterElements];
            },
            [filtersOptions?.external, filterColumnElements, toggleFilterColumnElements]);

    const [filtersInitialized, setFiltersInitialized] = useState(_.isEmpty(filterElements));
    const [toggleFiltersInitialized, setToggleFiltersInitialized] = useState(_.isEmpty(toggleFilterElements));
    useEffect(
        () => {
            if (!filtersOptions?.waitForReset &&
                filtersInitialized &&
                toggleFiltersInitialized) {
                actions.reset();
            }
        },
        [actions, filtersInitialized, filtersOptions?.waitForReset, toggleFiltersInitialized]);

    useEffect(
        () => {
            if (fetchItemsInterval) {
                const interval =
                    setInterval(
                        async () => {
                            try {
                                await actions.reload({ preventItemCountLoading: true });
                                // eslint-disable-next-line no-empty
                            } catch {
                            }
                        },
                        fetchItemsInterval);
                return () => clearInterval(interval);
            }
        },
        [fetchItemsInterval]);

    useChangeEffect(
        () => {
            onSortChanged?.(sort);
        },
        [sort]);

    useChangeEffect(
        () => {
            filtersOptions?.onChanged?.({
                ...filterMap,
                ...toggleFilterMap
            });
        },
        [filterMap, toggleFilterMap]);

    useChangeEffect(
        () => {
            const resetData = {
                filterMap,
                sort,
                toggleFilterMap
            };
            if (_.isEqualByProperties(resetDataRef.current, resetData)) {
                return;
            }

            resetDataRef.current = resetData;

            if (filtersInitialized && toggleFiltersInitialized) {
                actions.reset();
            }
        },
        [filterMap, toggleFilterMap]);

    useChangeEffect(
        () => {
            if (!sortOptions.enabled) {
                return;
            }

            if (sortOptions.reload) {
                actions.reload();
            } else {
                actions.reset();
            }
        },
        [sort]);

    const [hasSearchText, setHasSearchText] = useState(false);
    useChangeEffect(
        () => {
            setHasSearchText(!_.isEmpty(searchText));
            actions.reset();
        },
        [searchText],
        500);

    useEffect(
        () => {
            if (_.isNil(columnOptions?.selectorOptions?.persistenceStorageItem)) {
                return;
            }

            const visibleOrderedColumnElementsIds =
                _(visibleOrderedColumnElements).
                    map(visibleOrderedColumnElement => visibleOrderedColumnElement.props.id).
                    value();
            const visibleColumnElementIdToNonSelectorOptionsDefaultMap =
                _(visibleOrderedColumnElements).
                    filter(
                        visibleOrderedColumnElement => {
                            if (visibleOrderedColumnElement.props.selectorOptions?.disabled) {
                                return false;
                            }

                            const selectorOptionsDefault = visibleOrderedColumnElement.props.selectorOptions?.default ?? true;
                            return (
                                selectorOptionsDefault && !_.includes(selectedVisibleColumnIds, visibleOrderedColumnElement.props.id) ||
                                !selectorOptionsDefault && _.includes(selectedVisibleColumnIds, visibleOrderedColumnElement.props.id));
                        }).
                    keyBy(visibleColumnElement => visibleColumnElement.props.id).
                    mapValues(visibleColumnElement => !(visibleColumnElement.props.selectorOptions?.default ?? true)).
                    value();
            const visibleColumnElementIdToNonSelectorOptionsDefaultMapJson = columnOptions!.selectorOptions!.persistenceStorageItem.getValue();
            const notVisibleColumnElementIdToNonSelectorOptionsDefaultMap =
                _.isNil(visibleColumnElementIdToNonSelectorOptionsDefaultMapJson)
                    ? {}
                    : _.pickBy(
                        JSON.parse(visibleColumnElementIdToNonSelectorOptionsDefaultMapJson) as Dictionary<boolean>,
                        (_visible, columnId) => !_.includes(visibleOrderedColumnElementsIds, columnId));

            columnOptions!.selectorOptions!.persistenceStorageItem.setValue(
                JSON.stringify(
                    _.merge(
                        notVisibleColumnElementIdToNonSelectorOptionsDefaultMap,
                        visibleColumnElementIdToNonSelectorOptionsDefaultMap)));
        },
        [columnOptions?.selectorOptions, selectedVisibleColumnIds, visibleOrderedColumnElements]);

    const [highlightedItemExists, rowElements, rowElementToItemIdMap] =
        useMemo(
            () => {
                const highlightedItemExists =
                    _.some(
                        items,
                        item => !_.isNil(rowOptionsRef.current?.getHighlightColor?.(item)));

                const rowElementToItemIdMap = new Map<JSX.Element, string>();
                const rowElements =
                    _.map(
                        items,
                        item => {
                            const itemId = getItemId(item);
                            const rowElement =
                                <DataTableRow
                                    cellMaxWidth={cellMaxWidth}
                                    columns={visibleOrderedColumnElements}
                                    highlightedItemExists={highlightedItemExists}
                                    highlightItemHashRouteTemplate={highlightItem?.hashRouteTemplate}
                                    item={item}
                                    itemId={
                                        _.isNil(highlightItem?.getItemId)
                                            ? itemId
                                            : highlightItem!.getItemId(item)}
                                    itemSelectionEnabled={itemSelectionEnabled}
                                    key={itemId}
                                    options={rowOptionsRef.current}
                                    selectedVisibleColumnIds={selectedVisibleColumnIds}
                                    selectionEnabled={
                                        itemIdToSelectionEnabledMap[itemId] &&
                                        !selectionOptions?.disabled}
                                    stickyColumnId={columnOptions?.stickyColumnId}/>;

                            rowElementToItemIdMap.set(rowElement, itemId);
                            return rowElement;
                        });

                return [highlightedItemExists, rowElements, rowElementToItemIdMap];
            },
            [cellMaxWidth, columnOptions, getItemId, visibleOrderedColumnElements, highlightItem, items, itemSelectionEnabled, selectedVisibleColumnIds, itemIdToSelectionEnabledMap, selectionOptions]);

    const renderRowPlaceholderElements =
        useCallback(
            (rowElements: JSX.Element[]) =>
                _.map(
                    rowElements,
                    rowElement => {
                        const itemId = rowElementToItemIdMap.get(rowElement);
                        return (
                            <DataTableRowPlaceholder
                                columns={visibleOrderedColumnElements}
                                itemId={itemId!}
                                itemSelectionEnabled={itemSelectionEnabled}
                                key={itemId}
                                selectedVisibleColumnIds={selectedVisibleColumnIds}
                                stickyColumnId={columnOptions?.stickyColumnId}/>);
                    }),
            [columnOptions, visibleOrderedColumnElements, itemSelectionEnabled, selectedVisibleColumnIds, rowElementToItemIdMap]);

    const exportColumnElements =
        useMemo(
            () => {
                const visibleColumnElementIds =
                    _.map(
                        visibleOrderedColumnElements,
                        visibleColumnElement => visibleColumnElement.props.id);

                if (variant === "exportButtonOnly") {
                    return _.filter(
                        columnElements,
                        columnElement => !_.isNil(columnElement.props.exportOptions?.getItem));
                }

                return _.filter(
                    columnElements,
                    columnElement =>
                        !_.isNil(columnElement.props.exportOptions?.getItem) && (
                            !_.includes(visibleColumnElementIds, columnElement.props.id) ||
                            columnElement.props.selectorOptions?.disabled || (
                                _.isEmpty(selectedVisibleColumnIds) ||
                                _.includes(selectedVisibleColumnIds, columnElement.props.id))));
            },
            [columnElementsDeepDependency, columnsKey, selectedVisibleColumnIds, variant, visibleOrderedColumnElements]);

    const getExportItemPage =
        useCallback(
            async (limit: number, skip: number) => {
                let items;
                const itemsPromiseOrResult = fetchItemsPromiseOrResult(limit, skip);
                if (itemsPromiseOrResult instanceof Promise ||
                    itemsPromiseOrResult instanceof DataTableFetchItemsResult) {
                    const fetchItemsResult = await itemsPromiseOrResult;
                    items = fetchItemsResult.items;
                    fetchItemsResult.options?.onAppendData?.();
                } else {
                    items = itemsPromiseOrResult;
                }

                const itemIdToExportColumnElementIdToExportDataMap =
                    _(items).
                        keyBy(item => getItemId(item)).
                        mapValues(
                            item =>
                                _(exportColumnElements).
                                    keyBy(exportColumnElement => exportColumnElement.props.id).
                                    mapValues(exportColumnElement => exportColumnElement.props.exportOptions?.getData?.(item)).
                                    pickBy(exportColumnElementExportData => !_.isNil(exportColumnElementExportData)).
                                    value()).
                        value();

                const exportData = await exportOptions?.getData?.(itemIdToExportColumnElementIdToExportDataMap, items);
                return _.map(
                    items,
                    item =>
                        _.assign(
                            {},
                            ...(_.map(
                                exportColumnElements,
                                exportColumnElement => exportColumnElement.props.exportOptions?.getItem!(item, exportData)))));
            },
            [exportColumnElements, exportOptions, fetchItemsPromiseOrResult, getItemId]);


    const itemSelection = {
        enabled: itemSelectionEnabled,
        selectAllEnabled: selectAllItemsEnabled
    };

    const onCsvExportButtonClick: CsvExportButtonProps<TSummary>["onClick"] | undefined =
        !_.isNil(exportOptions?.onExportClick)
            ? async fileNameOptions => await exportOptions!.onExportClick!(filterMap, fileNameOptions)
            : undefined;

    const csvExportButtonComponent =
        useMemo(
            () => {
                if (_.isNil(exportOptions)) {
                    return undefined;
                }

                return (
                    <CsvExportButton
                        disabled={_.isEmpty(exportColumnElements)}
                        fileNameOptions={{
                            filtered: !_.isEmpty(filterMap),
                            prefix: exportOptions.fileNamePrefix
                        }}
                        getItemPage={getExportItemPage}
                        itemCount={
                            filtersOptions?.itemCount === false
                                ? undefined
                                : itemCount}
                        itemPageSize={
                            variant === "exportButtonOnly"
                                ? 10000
                                : undefined}
                        showLimitMessage={exportOptions.showLimitMessage}
                        onClick={onCsvExportButtonClick}/>);
            },
            [exportColumnElements, exportOptions, filterMap, filtersOptions?.itemCount, getExportItemPage, itemCount, variant]);

    const [scrollElementHeight, setScrollElementHeight] = useState(40);
    const [scrollElementWidth, setScrollElementWidth] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [tableHeight, setTableHeight] = useState(40);
    const [tableWidth, setTableWidth] = useState(0);
    useResizeObserver(
        tableRef,
        () => {
            if (_.isNil(tableRef.current)) {
                return;
            }

            const { height, width } = tableRef.current!.getBoundingClientRect();
            setTableHeight(height);
            setTableWidth(width);
        });

    const scrollElement = infiniteScrollActionsRef.current?.getScrollElement();
    useResizeObserver(
        scrollElement ?? null,
        () => {
            const { height, width } = scrollElement!.getBoundingClientRect();
            setScrollElementWidth(width);
            setScrollElementHeight(height);
        });
    const visibleTableHeight =
        Math.min(scrollElementHeight, tableHeight) -
        (!_.isNil(scrollElement) &&
        scrollElement!.scrollWidth - scrollElement!.clientWidth > 0 &&
        scrollElement!.scrollHeight - scrollElement!.clientHeight > 0
            ? 8
            : 0);
    const [{ stickyColumnWidth }, , ColumnContextProvider] =
        useDataTableColumnContextProvider(
            () => new DataTableColumnContext(0),
            []);

    const [itemsLoaded, setItemsLoaded] = useState<boolean>(false);
    const toolbarVisible =
        !_.isEmpty(actionElements) ||
        !_.isEmpty(filterElements) ||
        columnOptions?.selectorOptions?.enabled === true ||
        !_.isEmpty(toggleFilterElements) ||
        !_.isNil(searchOptions);

    if (variant === "exportButtonOnly") {
        return csvExportButtonComponent!;
    }

    if (variant === "filtersOnly") {
        return (
            <Stack spacing={1}>
                <Typography variant="h4">{localization.filters()}</Typography>
                <Filters
                    actionsRef={internalFiltersActionsRef}
                    initialFilterMap={filterMap}
                    key={filtersKey}
                    visibilityStorageItem={filtersOptions?.persist?.visibilityStorageItem}
                    onFilterChanged={setFilterMap}
                    onInitialized={() => setFiltersInitialized(true)}>
                    {filterElements}
                </Filters>
            </Stack>);
    }
    return (
        <ActionsContextProvider>
            <SelectionContextProvider>
                <ColumnContextProvider>
                    <Stack
                        sx={{
                            height: "100%",
                            position: "relative"
                        }}>
                        {toolbarVisible && (
                            <Box
                                ref={toolbarElementRef}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    border:
                                        variant === "card"
                                            ? theme.border.primary
                                            : undefined,
                                    borderBottom: "none",
                                    borderRadius:
                                        variant === "card"
                                            ? theme.spacing(0.75, 0.75, 0, 0)
                                            : undefined,
                                    left: 0,
                                    padding: theme.spacing(2.5),
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 3,
                                    ...filtersOptions?.sx
                                }}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{
                                        alignItems: "flex-start",
                                        position: "relative"
                                    }}>
                                    {_.isNil(filtersOptions?.external) &&
                                        <Box
                                            sx={{
                                                alignSelf: "center",
                                                flex: 1,
                                                height: "100%",
                                                minWidth: 0
                                            }}>
                                            <Filters
                                                actionsRef={internalFiltersActionsRef}
                                                filterQueryParameterName={
                                                    filtersOptions?.persist?.filterMap === false
                                                        ? undefined
                                                        : "filterMap"}
                                                initialFilterMap={filtersInitialFilterMap}
                                                key={filtersKey}
                                                visibilityStorageItem={filtersOptions?.persist?.visibilityStorageItem}
                                                onFilterChanged={setFilterMap}
                                                onInitialized={() => setFiltersInitialized(true)}
                                                onVisibleFiltersChanged={setInternalVisibleFilters}>
                                                {filterElements}
                                            </Filters>
                                        </Box>}
                                    <Stack
                                        alignItems="flex-start"
                                        direction="row"
                                        spacing={1}
                                        sx={{ minHeight: theme.spacing(3.5) }}>
                                        <Typography
                                            color={theme.palette.text.secondary}
                                            sx={{
                                                alignSelf: "center",
                                                paddingRight: theme.spacing(1)
                                            }}>
                                            {itemCountLoading
                                                ? <Skeleton
                                                    animation="wave"
                                                    sx={{ width: theme.spacing(6) }}/>
                                                : !_.isNil(itemCount) && localization.itemCount(itemCount)}
                                        </Typography>
                                        {!_.isEmpty(toggleFilterElements) && (
                                            <ToggleFilters
                                                filterQueryParameterName={
                                                    filtersOptions?.persist?.filterMap === false
                                                        ? undefined
                                                        : "toggleFilterMap"}
                                                initialFilterMap={toggleFiltersInitialFilterMap}
                                                key={`toggle-${filtersKey}`}
                                                onFilterChanged={setToggleFilterMap}
                                                onInitialized={() => setToggleFiltersInitialized(true)}>
                                                {toggleFilterElements}
                                            </ToggleFilters>)}
                                        {!_.isNil(searchOptions) && (
                                            <Box sx={{ margin: theme.spacing(0, 2) }}>
                                                <SearchTextField
                                                    placeholder={searchOptions.placeholder}
                                                    searchText={searchText}
                                                    variant="underlined"
                                                    onSearchTextChanged={searchText => setSearchText(searchText)}/>
                                            </Box>)}
                                        {_.map(
                                            actionElements,
                                            (actionElement, actionElementIndex) =>
                                                <Box key={actionElementIndex}>
                                                    {actionElement.props.children}
                                                </Box>)}
                                        {!_.isNil(SummarySection) &&
                                            <DataTableSummaryToggle
                                                visible={summaryVisible}
                                                onClick={() => setSummaryVisible(!summaryVisible)}/>}
                                        {_.some(
                                            columnElements,
                                            columnElement => !_.isNil(columnElement.props.exportOptions?.getItem)) &&
                                            <Box>
                                                {csvExportButtonComponent}
                                            </Box>}
                                        {columnOptions?.selectorOptions?.enabled === true &&
                                            <Box>
                                                <DataTableColumnMultiSelect
                                                    columns={selectorEnabledVisibleColumnElements}
                                                    selectedIds={selectedVisibleColumnIds}
                                                    onSelectedIdsChanged={actions.setSelectedVisibleColumnIds}/>
                                            </Box>}
                                    </Stack>
                                </Stack>
                                {!_.isNil(SummarySection) &&
                                    !_.isNil(summaryData) &&
                                    <Collapse in={summaryVisible}>
                                        <Box
                                            sx={{
                                                backgroundColor: theme.palette.background.paper,
                                                border: theme.border.primary,
                                                borderRadius: theme.spacing(0.75),
                                                marginTop: theme.spacing(2),
                                                padding: theme.spacing(2, 3)
                                            }}>
                                            <SummarySection
                                                data={summaryData}
                                                filterActionsRef={filtersActionsRef}
                                                filterMap={filterMap}
                                                loading={itemCountLoading}/>
                                        </Box>
                                    </Collapse>}
                            </Box>)}
                        {itemsLoaded && scrollLeft > 15 &&
                            <Box
                                sx={{
                                    boxShadow: theme.shadows[Shadows.TableLeftEdge],
                                    height: visibleTableHeight,
                                    left:
                                        itemSelectionEnabled
                                            ? stickyColumnWidth + 58
                                            : stickyColumnWidth,
                                    minWidth: theme.spacing(2),
                                    position: "absolute",
                                    top:
                                        toolbarVisible
                                            ? toolbarElementRef.current?.clientHeight
                                            : 0,
                                    zIndex: 99
                                }}/>}
                        {itemsLoaded && tableWidth - scrollLeft - scrollElementWidth > 15 &&
                            <Box
                                sx={{
                                    boxShadow: theme.shadows[Shadows.TableRightEdge],
                                    height: visibleTableHeight,
                                    minWidth: theme.spacing(2),
                                    position: "absolute",
                                    right:
                                        scrollElementHeight < tableHeight
                                            ? 8
                                            : 0,
                                    top:
                                        toolbarVisible
                                            ? toolbarElementRef.current?.clientHeight
                                            : 0,
                                    zIndex: 99
                                }}/>}
                        <InfiniteScroll
                            actionsRef={infiniteScrollActionsRef}
                            className={DataTableClassName}
                            emptyTextOptions={{
                                size: emptyMessageOptions?.size,
                                sx: emptyMessageOptions?.sx,
                                text:
                                    emptyMessageOptions?.emptyMessageText?.getText(
                                        hasSearchText ||
                                        !_.isEmpty(filterMap) ||
                                        !_.isEmpty(toggleFilterMap))
                            }}
                            externalLoading={externalLoading}
                            fetchData={fetchData}
                            manual={manualScroll}
                            marginBottom={
                                !_.isEmpty(selectionActionsActionElements) &&
                                selectionOptions?.showActions !== false &&
                                !_.isEmpty(selectedItemIds)
                                    ? theme.px(selectionActionsHeight)
                                    : undefined}
                            sx={{
                                flex: 1,
                                height: "unset",
                                ...(variant === "card" && {
                                    background: theme.palette.background.paper,
                                    border: theme.border.primary,
                                    borderRadius:
                                        toolbarVisible
                                            ? theme.spacing(0, 0, 0.75, 0.75)
                                            : theme.spacing(0.75),
                                    flex: "unset"
                                }),
                                ...sx
                            }}
                            waitForReset={true}
                            onScroll={event => setScrollLeft((event.target as HTMLElement).scrollLeft)}>
                            {(_.isNil(itemCount) ||
                                    itemCount > 0 ||
                                    variant === "view") &&
                                <MouseClickAreaScope disabled={!_.isNil(rowOptions?.getUrl)}>
                                    <Table
                                        ref={tableRef}
                                        size="medium"
                                        stickyHeader={true}
                                        sx={{
                                            ...!toolbarVisible && !highlightedItemExists && {
                                                ".MuiTableCell-head:first-of-type": {
                                                    borderTopLeftRadius: theme.spacing(1)
                                                },
                                                ".MuiTableCell-head:last-of-type": {
                                                    borderTopRightRadius: theme.spacing(1)
                                                }
                                            },
                                            ".col-drag &": {
                                                cursor: "grabbing"
                                            },
                                            tableLayout:
                                                columnOptions?.resizable === true && itemsLoaded
                                                    ? "fixed"
                                                    : "auto"
                                        }}>
                                        <DataTableHeader
                                            columnOptions={columnOptions}
                                            filters={{
                                                actionsRef: filtersActionsRef,
                                                active: activeFilters,
                                                enabled: filterIds,
                                                visible: visibleFilters
                                            }}
                                            highlightedItemExists={highlightedItemExists}
                                            itemSelection={itemSelection}
                                            orderedColumnIds={orderedColumnIds}
                                            selectedVisibleColumnIds={selectedVisibleColumnIds}
                                            setOrderedColumnIds={setOrderedColumnIds}
                                            sortOptions={{
                                                active: sort,
                                                enabled: sortOptions.enabled!,
                                                onClick: setSort
                                            }}
                                            tableHeight={visibleTableHeight}
                                            visibleColumnElementsMap={visibleColumnElementMap}
                                            visibleOrderedColumnElements={visibleOrderedColumnElements}
                                            onSelectedVisibleColumnIdsChange={actions.setSelectedVisibleColumnIds}/>
                                        {!externalLoading &&
                                            <TableBody>
                                                <InfiniteScrollItemsLoader
                                                    itemChunkSize={pageSize}
                                                    items={rowElements}
                                                    renderItemPlaceholderElements={renderRowPlaceholderElements}
                                                    virtualizationEnabled={virtualizationEnabled}
                                                    onItemsLoaded={
                                                        () => {
                                                            if (columnOptions?.resizable === true && !itemsLoaded) {
                                                                triggerItemsLoaded(true);
                                                            }

                                                            setItemsLoaded(true);
                                                        }}/>
                                            </TableBody>}
                                    </Table>
                                    {externalLoading &&
                                        <Stack alignItems="center">
                                            <CircularProgress
                                                size="18px"
                                                sx={{ margin: theme.spacing(2) }}
                                                variant="indeterminate"/>
                                        </Stack>}
                                </MouseClickAreaScope>}
                        </InfiniteScroll>
                        {selectionOptions?.showActions !== false && (
                            <SelectionActions
                                itemIds={selectedItemIds}
                                itemPermissions={selectionActionsItemPermissions}
                                loadedItems={selectedItems}
                                permissionsTranslator={selectionOptions?.permissionsTranslator}
                                onClear={() => setSelectedItemIds([])}>
                                {selectionActionsActionElements}
                            </SelectionActions>)}
                    </Stack>
                </ColumnContextProvider>
            </SelectionContextProvider>
        </ActionsContextProvider>);
}