import { Link, map, Message, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantController, useTheme } from "../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../index";
import { InlineMissingItems } from "../InlineMissingItems";
import { CloudShell, Manual } from "./components";

export function OrganizationRolesItem() {
    const { gciTenantModel, parentFolderId, tenantModel } = useAddOrEditContext();
    const { setLoaded, useNextEffect } = useOrderedWizardContext();

    function isOrganizationValid(validateGcpOrganizationResponse: Contract.TenantControllerValidateGcpOrganizationResponse) {
        return (
            validateGcpOrganizationResponse.result === Contract.GcpTenantManagerValidateOrganizationResult.ServiceAccountOptionalRoleMismatch ||
            validateGcpOrganizationResponse.result === Contract.GcpTenantManagerValidateOrganizationResult.ServiceAccountTenantBillingNotEnabled ||
            validateGcpOrganizationResponse.result === Contract.GcpTenantManagerValidateOrganizationResult.ServiceAccountTenantServiceApiHostNamesMismatch ||
            validateGcpOrganizationResponse.result === Contract.GcpTenantManagerValidateOrganizationResult.ServiceAccountWorkloadAnalysisPermissionsMismatch ||
            validateGcpOrganizationResponse.result === Contract.GcpTenantManagerValidateOrganizationResult.Valid);
    }

    const [organizationValid] =
        useExecuteOperation(
            OrganizationRolesItem,
            async () => {
                const validateGcpOrganizationResponse =
                    await TenantController.validateGcpOrganization(
                        new Contract.TenantControllerValidateGcpOrganizationRequest(
                            gciTenantModel!.configuration.id,
                            parentFolderId));
                return isOrganizationValid(validateGcpOrganizationResponse);
            });
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.organizationRolesItem",
            () => ({
                actions: {
                    next: {
                        error: {
                            gciTenantIssue: "Google Workspace (or Cloud Identity) is not connected. Please validate connectivity under Settings > Integrations",
                            general: "Failed to validate organization",
                            serviceAccountMissingRoleNames: {
                                roleNames: [
                                    "1 mandatory role",
                                    "{{count | NumberFormatter.humanize}} mandatory roles"
                                ],
                                text: "Service account is missing {{roleNames}} at the organization level"
                            },
                            serviceAccountUnauthorizedErrorMessage: "Service account is not authorized. Error: {{serviceAccountUnauthorizedErrorMessage}}"
                        }
                    }
                },
                configured: "Tenable identified that the required roles were already assigned at the organization level. You can continue with the next step.",
                onboardingType: {
                    [OnboardingType.CloudShell]: "gcloud CLI (Cloud Shell)",
                    [OnboardingType.Manual]: "Manual (Google Cloud Console)"
                },
                title: {
                    documentationLink: "documentation",
                    text: "Project onboarding requires granting a basic set of permissions on the organization level. These permissions are required to provide critical security information for GCP resources. For more information, see the {{documentationLink}}."
                }
            }));

    const onboardingTypes =
        useMemo(
            () =>
                _<OnboardingType>([]).
                    concatIf(
                        _.isNil(tenantModel),
                        OnboardingType.CloudShell).
                    concat(OnboardingType.Manual).
                    value(),
            []);

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(() => _.first(onboardingTypes)!);
    useNextEffect(
        async () => {
            if (organizationValid) {
                return undefined;
            }

            try {
                const validateGcpTenantOrganizationResponse =
                    await TenantController.validateGcpOrganization(
                        new Contract.TenantControllerValidateGcpOrganizationRequest(
                            gciTenantModel!.configuration.id,
                            parentFolderId));

                if (isOrganizationValid(validateGcpTenantOrganizationResponse)) {
                    return undefined;
                }

                if (validateGcpTenantOrganizationResponse.result === Contract.GcpTenantManagerValidateOrganizationResult.GciTenantIssue) {
                    return localization.actions.next.error.gciTenantIssue();
                }

                if (validateGcpTenantOrganizationResponse.result === Contract.GcpTenantManagerValidateOrganizationResult.ServiceAccountUnauthorized) {
                    return localization.actions.next.error.serviceAccountUnauthorizedErrorMessage({ serviceAccountUnauthorizedErrorMessage: validateGcpTenantOrganizationResponse.serviceAccountUnauthorizedErrorMessage });
                }

                return localization.actions.next.error.serviceAccountMissingRoleNames.text({
                    roleNames:
                        <InlineMissingItems
                            items={validateGcpTenantOrganizationResponse.serviceAccountMissingRoleNames!}
                            namePluralizer={localization.actions.next.error.serviceAccountMissingRoleNames.roleNames}/>
                });
            } catch {
                return localization.actions.next.error.general();
            }
        },
        [selectedOnboardingType]);

    const theme = useTheme();
    return organizationValid
        ? <Message
            level="success"
            title={localization.configured()}/>
        : <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={selectedOnboardingType}
                variant="standard"
                onChange={(_, value) => setSelectedOnboardingType(value)}>
                {_.map(
                    onboardingTypes,
                    onboardingType =>
                        <Tab
                            key={onboardingType}
                            label={localization.onboardingType[onboardingType]()}
                            sx={{ padding: theme.spacing(1, 2) }}
                            value={onboardingType}/>)}
            </Tabs>
            <Stack
                key={selectedOnboardingType}
                sx={{ overflow: "hidden auto" }}>
                <Stack
                    spacing={3}
                    sx={{ maxWidth: theme.spacing(80) }}>
                    <Typography>
                        {localization.title.text({
                            documentationLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardGcpRelativeUrl()}
                                    variant="external">
                                    {localization.title.documentationLink()}
                                </Link>
                        })}
                    </Typography>
                    {map(
                        selectedOnboardingType,
                        {
                            [OnboardingType.CloudShell]: () => <CloudShell/>,
                            [OnboardingType.Manual]: () => <Manual/>
                        })}
                </Stack>
            </Stack>
        </Stack>;
}

enum OnboardingType {
    CloudShell = "cloudShell",
    Manual = "manual"
}