import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsSageMakerNotebookInstanceRiskContext } from "../contexts";

export function useAwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRisk;
    const notebookInstanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSageMakerNotebookInstanceModel;

    const getAwsSageMakerNotebookInstanceRiskContext = useGetAwsSageMakerNotebookInstanceRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskDefinition",
            () => ({
                description: "{{notebookInstance}} direct internet access is enabled",
                sections: {
                    resolution: {
                        step1: "Disabling direct internet access on an existing instance is not available and you need to re-create the instance",
                        step2:"Click **Create notebook instance**",
                        step3:"Expand the **Network** section and select a VPC, subnet, and security group",
                        step4:"Under **Direct internet access**, select **Disable - Access the internet through a VPC**",
                        step5:"Click **Create notebook instance**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            notebookInstance:
                <Entity
                    entityIdOrModel={notebookInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.SageMaker,
                AwsConsoleUrlBuilder.getSageMakerNotebookInstanceUrl(notebookInstanceModel.entity as Contract.AwsSageMakerNotebookInstance)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const notebookInstanceRiskContext = getAwsSageMakerNotebookInstanceRiskContext(notebookInstanceModel);
            return [
                notebookInstanceRiskContext.generalInformation,
                notebookInstanceRiskContext.sensitive,
                notebookInstanceRiskContext.status,
                notebookInstanceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}