﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureContainerServiceManagedClusterStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureContainerServiceManagedClusterStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureContainerServiceManagedClusterStatus]: {
                    [Contract.AzureContainerServiceManagedClusterStatus.Running]: "Running",
                    [Contract.AzureContainerServiceManagedClusterStatus.Stopped]: "Stopped"
                }
            }));
    return (status: Contract.AzureContainerServiceManagedClusterStatus) =>
        localization[Contract.TypeNames.AzureContainerServiceManagedClusterStatus][status]();
}