﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsCloudFrontDistributionStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsCloudFrontDistributionStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsCloudFrontDistributionStatus]: {
                    [Contract.AwsCloudFrontDistributionStatus.Disabled]: "Disabled",
                    [Contract.AwsCloudFrontDistributionStatus.Enabled]: "Enabled"
                }
            }));
    return (status: Contract.AwsCloudFrontDistributionStatus) =>
        localization[Contract.TypeNames.AwsCloudFrontDistributionStatus][status]();
}