import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useDataCategoryTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useDataCategoryTranslator",
            () => ({
                [Contract.TypeNames.DataCategory]: {
                    [Contract.DataCategory.Phi]: {
                        initials: "PHI",
                        title: "Protected Health Information"
                    },
                    [Contract.DataCategory.Pii]: {
                        initials: "PII",
                        title: "Personal Identifiable Information"
                    },
                    [Contract.DataCategory.Pci]: {
                        initials: "PCI",
                        title: "Payment Card Industry"
                    },
                    [Contract.DataCategory.Secrets]: {
                        initials: "Secrets",
                        title: "Secrets"
                    }
                }
            }));

    return (category: Contract.DataCategory) => ({
        initials: localization[Contract.TypeNames.DataCategory][category].initials(),
        title: localization[Contract.TypeNames.DataCategory][category].title()
    });
}