import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useVirtualMachineImageManagementTypeTranslator(){
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useVirtualMachineImageManagementTypeTranslator",
            () => ({
                [Contract.VirtualMachineImageManagementType.Cloud]: "Cloud managed",
                [Contract.VirtualMachineImageManagementType.Customer]: "Customer managed"
            }));
    return (managementType: Contract.VirtualMachineImageManagementType)=> localization[managementType]();
}