﻿import _ from "lodash";
import React, { useState } from "react";
import { useUpdateConfiguration } from "../../../../hooks";
import { ActionsCell, ExclusionTagsTableActionsCellProps } from "../../../ExclusionTags";

export function VirtualMachineActionsCell({ exclusionTagToDelete, onDelete, useScopeResourceTags }: ExclusionTagsTableActionsCellProps) {
    const [deleteVirtualMachineExclusionTagExecuting, setDeleteVirtualMachineExclusionTagExecuting] = useState(false);
    const [deleteVirtualMachineExclusionTagError, setDeleteVirtualMachineExclusionTagError] = useState(false);
    const updateConfiguration = useUpdateConfiguration();

    const scopeResourceTags = useScopeResourceTags();
    async function deleteVirtualMachineExclusionTag() {
        setDeleteVirtualMachineExclusionTagExecuting(true);
        setDeleteVirtualMachineExclusionTagError(false);

        try {
            const updateWorkloadAnalysisResponse =
                await updateConfiguration({
                    virtualMachineExclusionTags: _.filter(
                        scopeResourceTags,
                        existingVirtualMachineExclusionTag =>
                            !_.isEqual(existingVirtualMachineExclusionTag.key, exclusionTagToDelete.key) ||
                            !_.isEqual(existingVirtualMachineExclusionTag.value, exclusionTagToDelete.value))
                });

            onDelete(updateWorkloadAnalysisResponse.virtualMachineExclusionTags);
        } catch {
            setDeleteVirtualMachineExclusionTagError(true);
        }

        setDeleteVirtualMachineExclusionTagExecuting(false);
    }

    return (
        <ActionsCell
            deleteExclusionTag={deleteVirtualMachineExclusionTag}
            deleteExclusionTagError={deleteVirtualMachineExclusionTagError}
            deleteExclusionTagExecuting={deleteVirtualMachineExclusionTagExecuting}
            exclusionTag={exclusionTagToDelete}/>);
}