﻿import { IconText, NoneIcon, Optional } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, RegionLocationIcon, useRegionLocationTranslator } from "..";

type RegionLocationProps = {
    regionLocation: Optional<Contract.RegionLocation>;
};

export function RegionLocation({ regionLocation }: RegionLocationProps) {
    const regionLocationTranslator = useRegionLocationTranslator();

    return (
        _.isNil(regionLocation)
            ? <NoneIcon/>
            : <IconText
                containerSx={{ paddingRight: 0 }}
                icon={<RegionLocationIcon regionLocation={regionLocation}/>}
                iconSx={{ fontSize: "18px" }}
                spacing={0.75}
                text={
                    <Typography noWrap={true}>
                        {regionLocationTranslator(regionLocation)}
                    </Typography>}
                textSx={{ lineHeight: 1 }}/>);
}