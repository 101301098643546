import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciKmsVaultKeyProtectionModeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciKmsVaultKeyProtectionModeTranslator",
            () => ({
                [Contract.TypeNames.OciKmsVaultKeyProtectionMode]: {
                    [Contract.OciKmsVaultKeyProtectionMode.External]: "External",
                    [Contract.OciKmsVaultKeyProtectionMode.Hsm]: "HSM",
                    [Contract.OciKmsVaultKeyProtectionMode.Software]: "Software"
                }
            }));
    return (protectionMode: Contract.OciKmsVaultKeyProtectionMode) =>
        localization[Contract.TypeNames.OciKmsVaultKeyProtectionMode][protectionMode]();
}