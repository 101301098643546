﻿import { InlineItems } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";

type InlineMissingItemsProps = {
    items: string[];
    namePluralizer?: (count: number) => string;
};

export function InlineMissingItems({ items, namePluralizer }: InlineMissingItemsProps) {
    return (
        <InlineItems
            items={items}
            namePluralizer={namePluralizer}
            sx={{ color: "unset" }}
            variant="itemCountAndType">
            {text =>
                <Typography noWrap={true}>
                    {text}
                </Typography>}
        </InlineItems>);
}