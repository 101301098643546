import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function OrcaIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2227)">
                <path
                    d="M12 0.306396C10.5968 0.306396 9.23115 0.522396 7.97595 0.956796C9.93275 2.4752 11.52 4.4632 12.4432 6.8128C15.1752 7.0296 17.2064 9.0544 17.2064 12.0184C17.2064 14.9824 15.2128 17.0064 12.5168 17.224C11.6048 19.5453 10.0722 21.5712 8.08635 23.08C9.30475 23.4776 10.6344 23.6944 12 23.6944C18.6464 23.6944 24 18.5976 24 11.9824C24 5.4032 18.6464 0.306396 12 0.306396Z"
                    fill={theme.palette.text.primary}/>
                <path
                    d="M2.40006 4.93037C1.98803 5.44738 1.62915 6.00459 1.32886 6.59357C2.99046 7.85837 4.06166 9.81037 4.06166 12.052C4.06166 14.2928 3.02806 16.2448 1.36566 17.4736C1.69846 18.052 2.06806 18.6304 2.47366 19.1368C3.67512 18.3866 4.66517 17.3422 5.35004 16.1023C6.03492 14.8625 6.39193 13.4684 6.38726 12.052C6.35046 9.01517 4.80006 6.41197 2.40006 4.93037ZM9.41526 16.6096C8.53784 18.6833 7.05865 20.4461 5.16886 21.6704C5.79686 22.068 6.46086 22.4288 7.16246 22.7184C9.13646 21.3256 10.6836 19.4106 11.6305 17.188C10.8547 17.1858 10.0923 16.9868 9.41446 16.6096H9.41526ZM7.05286 1.31677C6.35126 1.60637 5.68646 1.96797 5.09606 2.40157C6.97722 3.62949 8.46437 5.37385 9.37926 7.42557C10.0605 7.05314 10.8187 6.84315 11.5945 6.81197C10.5977 4.57037 9.04646 2.69117 7.05286 1.31677Z"
                    fill="#0080FF"/>
                <path
                    d="M4.3928 2.90881C3.876 3.34241 3.36 3.77601 2.916 4.28241C5.0576 5.62001 6.5344 7.78881 7.0144 10.3544C7.3104 9.34321 7.9008 8.47521 8.676 7.89681C7.7896 5.80081 6.3128 4.06481 4.3928 2.90881ZM7.0528 13.7184C6.8374 14.958 6.3647 16.1388 5.66515 17.1846C4.9656 18.2304 4.05472 19.1181 2.9912 19.7904C3.4344 20.2968 3.9512 20.7664 4.5048 21.1648C6.40974 19.9899 7.89566 18.2442 8.7512 16.176C7.93222 15.5639 7.33579 14.7009 7.0528 13.7184ZM0.96 7.35281C0.332 8.76321 0 10.3528 0 12.0168C0 13.7152 0.3696 15.3056 0.9968 16.752C2.3632 15.6672 3.2128 13.968 3.2128 12.052C3.2128 10.136 2.3632 8.43761 0.96 7.35281Z"
                    fill="#0080FF"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2227">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}