import _ from "lodash";
import React, { useMemo } from "react";
import { useReportsContext, useSetReportsContext } from "../..";
import { Contract, LicensingHelper, Report, reportDefinitionConfigurationStore, ReportDeliveryType, useScopeNavigationViewContext } from "../../../../../../common";
import { useItems } from "./hooks/useItems/useItems";

export function AddOrEdit() {
    const { reportDeliveryType, reportScheduleConfiguration, triggerReportChange } = useReportsContext();
    const setReportsContext = useSetReportsContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const selectedScopeId = scopeNodeModel.configuration.id;

    async function onGenerate(type?: ReportDeliveryType) {
        if (type === ReportDeliveryType.Generate) {
            await triggerReportChange();
        }

        setReportsContext(
            reportsContext => ({
                ...reportsContext,
                addOrEditOpen: false,
                reportDeliveryType: undefined,
                reportScheduleConfiguration: undefined
            }));
    }

    const reportDefinitionConfigurations = reportDefinitionConfigurationStore.useGetAll();
    const [reportDefinitionConfiguration] =
        useMemo(
            () => {
                const reportDefinitionConfiguration =
                    _.isNil(reportScheduleConfiguration)
                        ? undefined
                        : _.find(
                            reportDefinitionConfigurations,
                            reportDefinitionConfiguration => reportDefinitionConfiguration.id === reportScheduleConfiguration?.definitionId);
                if (_.isNil(reportDefinitionConfiguration)) {
                    return [undefined, false];
                } else if (LicensingHelper.isActiveLicenseType(reportDefinitionConfiguration.licenseType)) {
                    return [reportDefinitionConfiguration, false];
                } else {
                    return [undefined, true];
                }
            },
            [reportDefinitionConfigurations]);

    const items =
        useItems({
            reportDefinitionConfiguration,
            selectedScopeId
        });
    const reportParams =
        useMemo(
            () => ({
                reportName:
                    _.isNil(reportDefinitionConfiguration)
                        ? undefined
                        : (reportDefinitionConfiguration as Contract.InventoryReportDefinitionConfiguration | Contract.EventsReportDefinitionConfiguration | Contract.RisksReportDefinitionConfiguration | Contract.WorkloadResourcePackageVulnerabilitiesReportDefinitionConfiguration).name,
                reportScheduleConfiguration,
                reportType: reportDefinitionConfiguration?.type,
                scheduleReportType:
                    !_.isNil(reportDeliveryType)
                        ? reportDeliveryType
                        : _.isNil(reportScheduleConfiguration)
                            ? ReportDeliveryType.Generate
                            : ReportDeliveryType.Schedule
            }),
            [reportDefinitionConfiguration, reportDeliveryType, reportScheduleConfiguration]);

    return (
        <Report
            items={items}
            options={reportParams}
            rootScopeId={selectedScopeId}
            scopeSelectorDisabled={true}
            onGenerate={type => onGenerate(type)}/>);
}