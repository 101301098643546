import { useLocalization, WizardFinishItem } from "@infrastructure";
import React from "react";

export function FinishItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEdit.finishItem",
            () => ({
                title: "Jira configured successfully"
            }));
    return <WizardFinishItem title={localization.title()}/>;
}