import { useMemo } from "react";
import { cloudRiskPolicyItemsOperationStore, Contract, RiskController, riskPolicyItemConfigurationOperationStore, riskPolicyModelStore } from "../../../../..";
import { RiskPolicyConfigurationControllerDefinition } from "../../../useRiskPolicyConfigurationControllerDefinition";

export function useCloudRiskPolicyBuiltInConfigurationControllerDefinition(riskPolicyConfigurationTypeNameOrId: string, scopeId: string): RiskPolicyConfigurationControllerDefinition {
    return useMemo(
        () => ({
            async updateRiskPolicyConfiguration({ entityTypeNameToExclusionsMap, riskPoliciesIds, riskPolicyConfiguration, riskPolicyIdToSystemUpdateTimeMap }) {
                await RiskController.updateRiskPolicyConfiguration(
                    new Contract.RiskControllerUpdateCloudRiskPolicyConfigurationRequest(
                        riskPolicyConfiguration,
                        entityTypeNameToExclusionsMap!,
                        riskPolicyIdToSystemUpdateTimeMap));

                await riskPolicyModelStore.notify(riskPoliciesIds);

                await Promise.all([
                    cloudRiskPolicyItemsOperationStore.notifyAll(),
                    riskPolicyItemConfigurationOperationStore.notifyAll()
                ]);
            }
        }),
        [riskPolicyConfigurationTypeNameOrId, scopeId]);
}