import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useAwsPartitionTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useAwsPartitionTypeTranslator",
            () => ({
                [Contract.AwsPartitionType.China]: "China",
                [Contract.AwsPartitionType.EuIsoE]: "EU Isolated E",
                [Contract.AwsPartitionType.Public]: "Public",
                [Contract.AwsPartitionType.UsGov]: "US Government",
                [Contract.AwsPartitionType.UsIsoA]: "US Isolated A",
                [Contract.AwsPartitionType.UsIsoB]: "US Isolated B",
                [Contract.AwsPartitionType.UsIsoF]: "US Isolated F"
            }));
    return (partitionType: Contract.AwsPartitionType) =>
        localization[partitionType]();
}