﻿import { CheckboxField, DeleteIcon, useLocalization } from "@infrastructure";
import { Box, FormControl, FormHelperText, Grid2, IconButton, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, InlineScopes, useTheme } from "../../../../../../../../common";

type SecretPatternProps = {
    duplicate: boolean;
    exclusionSecretPattern: Contract.SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern;
    last: boolean;
    onDelete?: () => void;
    onExclusionSecretPatternChanged: (exclusionSecretPattern: Contract.SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern) => void;
    readOnly: boolean;
    scopeIds?: string[];
    valid: boolean;
};

export function SecretPattern({ duplicate, exclusionSecretPattern, last, onDelete, onExclusionSecretPatternChanged, readOnly, scopeIds, valid }: SecretPatternProps) {
    const inherited = !_.isEmpty(scopeIds);
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.secretExclusion.secretPattern",
            () => ({
                caseInsensitive: "Ignore case",
                duplicate: "Duplicate value is not allowed",
                inherited: "Inherited from",
                key: "Key Pattern",
                message: "Comment",
                value: "Value Pattern"
            }));

    const theme = useTheme();
    return (
        <Grid2
            alignItems="start"
            container={true}
            spacing={1}
            width="100%">
            <Grid2 size={{ xs: 4 }}>
                <Stack
                    direction="row"
                    flexDirection="row"
                    justifyContent="space-evenly"
                    spacing={1}>
                    <FormControl
                        variant="standard">
                        <TextField
                            disabled={inherited || readOnly}
                            label={localization.key()}
                            value={exclusionSecretPattern.keyPattern}
                            variant="outlined"
                            onChange={
                                event =>
                                    onExclusionSecretPatternChanged({
                                        ...exclusionSecretPattern,
                                        keyPattern: event.target.value.trim()
                                    })}/>
                        {!readOnly &&
                            valid &&
                            duplicate &&
                            !inherited &&
                            <FormHelperText error={true}>
                                {localization.duplicate()}
                            </FormHelperText>}
                    </FormControl>
                    <FormControl
                        variant="standard">
                        <TextField
                            disabled={inherited || readOnly}
                            label={localization.value()}
                            value={exclusionSecretPattern.valuePattern}
                            variant="outlined"
                            onChange={
                                event =>
                                    onExclusionSecretPatternChanged({
                                        ...exclusionSecretPattern,
                                        valuePattern: event.target.value.trim()
                                    })}/>
                        {!readOnly &&
                            valid &&
                            duplicate &&
                            !inherited &&
                            <FormHelperText error={true}>
                                {localization.duplicate()}
                            </FormHelperText>}
                    </FormControl>
                </Stack>
            </Grid2>
            {inherited &&
                <Grid2 sx={{ padding: theme.spacing(2.5, 1, 0) }}>
                    <Stack
                        direction="row"
                        spacing={1}>
                        <Typography>
                            {localization.inherited()}
                        </Typography>
                        <Box>
                            <InlineScopes
                                scopeIds={scopeIds!}
                                scopeVariant="iconText"
                                variant="itemCountAndType"/>
                        </Box>
                    </Stack>
                </Grid2>}
            <Grid2 size={{ xs: 5 }}>
                {!inherited &&
                    <TextField
                        fullWidth={true}
                        label={localization.message()}
                        maxRows={1}
                        multiline={true}
                        slotProps={{ input: { readOnly } }}
                        value={exclusionSecretPattern.message ?? ""}
                        variant="outlined"
                        onChange={
                            event =>
                                onExclusionSecretPatternChanged({
                                    ...exclusionSecretPattern,
                                    message:
                                        _.isEmpty(event.target.value)
                                            ? undefined
                                            : event.target.value
                                })}/>}
            </Grid2>
            <Grid2 sx={{ padding: theme.spacing(2, 1, 0) }}>
                {!inherited &&
                    !readOnly &&
                    <CheckboxField
                        checked={!exclusionSecretPattern.caseSensitive}
                        title={localization.caseInsensitive()}
                        onChange={
                            () =>
                                onExclusionSecretPatternChanged({
                                    ...exclusionSecretPattern,
                                    caseSensitive: !exclusionSecretPattern.caseSensitive
                                })}>
                        {localization.caseInsensitive()}
                    </CheckboxField>}
            </Grid2>
            <Grid2 sx={{ padding: theme.spacing(2, 1, 0) }}>
                {!last &&
                    !inherited &&
                    !readOnly &&
                    <IconButton
                        size="medium"
                        sx={{ alignSelf: "center" }}
                        onClick={onDelete}>
                        <DeleteIcon/>
                    </IconButton>}
            </Grid2>
        </Grid2>);
}