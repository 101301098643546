import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpDefaultResourceInfoItemElements } from "..";
import { Contract, entityModelStore, InfoItem } from "../../../../../../../../../../../common";
import { useGcpArtifactRegistryTypeTranslator } from "../../../../../../../hooks";
import { Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";

export function useGcpArtifactContainerImageRepositoryDefinition(entityModel: Contract.GcpEntityModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(entityModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(entityModel as Contract.GcpResourceModel);
    const repository = entityModel.entity as Contract.GcpArtifactContainerImageRepository;
    const registryModel = entityModelStore.useGet(repository.registryId) as Contract.GcpArtifactRegistryModel;

    const artifactRegistryTypeTranslator = useGcpArtifactRegistryTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpArtifactContainerImageRepositoryDefinition",
            () => ({
                info: {
                    items: {
                        accessLevel: "Access",
                        rawUrl: "URI",
                        registry: "Registry",
                        registryType: "Format"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={registryModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawUrl"
                        location="all"
                        title={localization.info.items.rawUrl()}
                        value={repository.rawUrl}/>,
                    <InfoItem
                        key="registryType"
                        location="all"
                        title={localization.info.items.registryType()}
                        value={artifactRegistryTypeTranslator(Contract.GcpArtifactRegistryType.ContainerImage)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}