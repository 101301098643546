﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition, useGcpCommonServiceAccountOriginatorScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpSqlInstanceDatabaseVersionTranslator, useGcpSqlInstanceTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useCommonDataAnalysisDefinition } from "../useCommonDataAnalysisDefinition";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";

export function useGcpSqlInstanceDefinition(definitionData: DefinitionData) {
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const commonServiceAccountOriginatorScopeResourceDefinition = useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const sqlInstanceDatabaseVersionTranslator = useGcpSqlInstanceDatabaseVersionTranslator();
    const sqlInstanceTypeTranslator = useGcpSqlInstanceTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpSqlInstanceDefinition",
            () => ({
                columns: {
                    databaseIds: "Databases",
                    databaseVersion: "Database Version",
                    privateIpAddresses: "Private IP Addresses",
                    publicIpAddresses: "Public IP Addresses",
                    size: {
                        empty: "Unknown",
                        title: "Size"
                    },
                    type: "Instance Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonServiceAccountOriginatorScopeResourceDefinition.columns.serviceAccountColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpSqlInstanceModel) => ({
                            [localization.columns.type()]: sqlInstanceTypeTranslator((item.entity as Contract.GcpSqlInstance).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpSqlInstanceType}
                                enumTypeTranslator={sqlInstanceTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpSqlInstanceType}
                itemProperty={(item: Contract.EntityModel) => sqlInstanceTypeTranslator((item.entity as Contract.GcpSqlInstance).type)}
                key={Contract.EntityModelProperty.GcpSqlInstanceType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpSqlInstanceModel) => ({
                            [localization.columns.databaseVersion()]: sqlInstanceDatabaseVersionTranslator((item.entity as Contract.GcpSqlInstance).databaseVersion)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpSqlInstanceDatabaseVersion}
                                enumTypeTranslator={sqlInstanceDatabaseVersionTranslator}
                                placeholder={localization.columns.databaseVersion()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpSqlInstanceDatabaseVersion}
                itemProperty={(item: Contract.EntityModel) => sqlInstanceDatabaseVersionTranslator((item.entity as Contract.GcpSqlInstance).databaseVersion)}
                key={Contract.EntityModelProperty.GcpSqlInstanceDatabaseVersion}
                title={localization.columns.databaseVersion()}/>,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpSqlInstanceModel) => ({
                            [localization.columns.size.title()]:
                                _.isNil((item.entity as Contract.GcpSqlInstance).size)
                                    ? localization.columns.size.empty()
                                    : NumberFormatter.storage((item.entity as Contract.GcpSqlInstance).size!)
                        })
                }}
                id={Contract.EntityModelProperty.GcpSqlInstanceSize}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        _.isNil((item.entity as Contract.GcpSqlInstance).size)
                            ? localization.columns.size.empty()
                            : NumberFormatter.storage((item.entity as Contract.GcpStorageBucket).size!)}
                key={Contract.EntityModelProperty.GcpSqlInstanceSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpSqlInstanceModel) => ({
                            [localization.columns.publicIpAddresses()]: _.join((item.entity as Contract.GcpSqlInstance).publicIpAddresses, "\n"),
                            [localization.columns.privateIpAddresses()]: _.join((item.entity as Contract.GcpSqlInstance).privateIpAddresses, "\n")
                        })
                }}
                id="ipAddresses"
                key="ipAddresses"/>,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpSqlInstanceModel>(
                        Contract.TypeNames.GcpSqlInstanceDatabase,
                        item => item.databaseIds,
                        localization.columns.databaseIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpSqlInstanceDatabases)}
                                placeholder={localization.columns.databaseIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpSqlInstanceDatabases}
                key={Contract.EntityModelProperty.GcpSqlInstanceDatabases}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpSqlInstanceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.databaseIds}
                            entityTypeName={Contract.TypeNames.GcpSqlInstanceDatabase}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.databaseIds()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn,
            commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(false),
            commonDataAnalysisResourceDefinition.columns.scanTimeColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpSqlInstanceRequest(
                new Contract.EntityControllerGetEntityModelPageGcpSqlInstanceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpSqlInstanceDatabases]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpSqlInstanceDatabaseVersion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpSqlInstanceType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount]))));
}