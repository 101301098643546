import { ApiError, AutocompleteItems, FormLayout, GuidParser, InfoIcon, ItemSelector, Link, Message, Tooltip, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Group as GroupIcon } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { usePrincipalsContext, useSetPrincipalsContext } from "../../..";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, LicensingHelper, ScopeHelper, ScopePath, useIdentityRoleTranslator, UserHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../common";

export type AadGroupProps = {
    roleAssignment?: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment;
};

export function AadGroup({ roleAssignment }: AadGroupProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { executeGetPrincipals, groups, principalRoleAssignments } = usePrincipalsContext();
    const setPrincipalsContext = useSetPrincipalsContext();

    const [identifier, setIdentifier] = useState(roleAssignment?.principalIdentifier ?? "");
    const groupIdentifierToGroupMap =
        _.keyBy(
            groups,
            group => group.identifier);
    const [name, setName] =
        useState(
            !_.isNil(roleAssignment)
                ? groupIdentifierToGroupMap[roleAssignment.principalIdentifier].name ?? ""
                : "");
    const [role, setRole] = useState(roleAssignment?.role);

    const [saveGroupRoleAssignmentExecuting, setSaveGroupRoleAssignmentExecuting] = useState(false);
    const [saveGroupRoleAssignmentError, setSaveGroupRoleAssignmentError] = useState<Contract.ConfigurationControllerInsertGroupRoleAssignmentError | true>();
    const groupNameToGroupMap =
        _(groups).
            filter(group => !_.isNil(group.name)).
            keyBy(group => group.name!).
            value();

    async function saveGroupRoleAssignment() {
        setSaveGroupRoleAssignmentExecuting(true);
        setSaveGroupRoleAssignmentError(undefined);

        try {
            if (_.isNil(roleAssignment)) {
                await ConfigurationController.insertGroupRoleAssignment(
                    new Contract.ConfigurationControllerInsertGroupRoleAssignmentRequest(
                        identifier.trim(),
                        name.trim(),
                        role!,
                        scopeNodeModel.configuration.id));
            } else {
                await ConfigurationController.updateGroupRoleAssignment(
                    new Contract.ConfigurationControllerUpdateGroupRoleAssignmentRequest(
                        roleAssignment.role,
                        _.find(
                            groups,
                            group => group.identifier === identifier)!.
                            id,
                        name.trim(),
                        role!,
                        roleAssignment.scopeId));
            }

            await executeGetPrincipals();
            setPrincipalsContext(
                principalsContext => ({
                    ...principalsContext,
                    dialogContentElement: undefined
                }));
        } catch (error) {
            setSaveGroupRoleAssignmentError(
                error instanceof ApiError && error.statusCode === 400
                    ? error.error as Contract.ConfigurationControllerInsertGroupRoleAssignmentError
                    : true);
        }

        setSaveGroupRoleAssignmentExecuting(false);
    }

    const identityRoleTranslator = useIdentityRoleTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.principals.addOrEditPrincipal.aadGroup",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add group assignment",
                            edit: "Failed to save",
                            [Contract.TypeNames.ConfigurationControllerInsertGroupRoleAssignmentError]: {
                                [Contract.ConfigurationControllerInsertGroupRoleAssignmentError.GroupMaxCount]: "Group limit was reached"
                            }
                        },
                        title: {
                            add: "Add",
                            edit: "Save"
                        }
                    }
                },
                fields: {
                    groupRoleAssignment: {
                        error: {
                            exists: "Group role assignment exists in this scope"
                        }
                    },
                    identifier: {
                        error: {
                            format: "ID must be a valid GUID",
                            required: "ID cannot be empty"
                        },
                        title: "Group ID"
                    },
                    name: {
                        error: {
                            required: "Name cannot be empty"
                        },
                        title: "Display name"
                    },
                    role: "Role"
                },
                helpText: {
                    identifier: "The Microsoft Entra ID Group Object ID (GUID) of the group you want to add. You must first configure Microsoft Entra ID to send group claims to Tenable Cloud Security.",
                    name: "The group name, as it will appear in the Tenable Cloud Security Console.",
                    title: {
                        link: "Read documentation",
                        tenableText: "Grant all users associated with a Tenable One group identical permissions in Tenable Cloud Security.",
                        text: "Grant all users associated with an IdP group identical permissions in Tenable Cloud Security. You must first configure the IdP to send group claims to Tenable Cloud Security. {{link}}."
                    }
                },
                title: {
                    add: "Add a Group Role Assignment",
                    edit: "Edit a Group Role Assignment"
                }
            }));

    const [identifierValidationController, identifierValidationMessage] =
        useInputValidation(
            () => {
                const validationIdentifier = identifier.trim();
                if (_.isEmpty(validationIdentifier)) {
                    return localization.fields.identifier.error.required();
                }
                if (!GuidParser.validate(validationIdentifier)) {
                    return localization.fields.identifier.error.format();
                }

                return undefined;
            },
            [identifier]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }

                return undefined;
            },
            [name]);

    const groupRoleAssignmentExists =
        !_.isEmpty(identifier) &&
        role != roleAssignment?.role &&
        _.some(
            principalRoleAssignments,
            principalRoleAssignment =>
                principalRoleAssignment.principalIdentifier === identifier &&
                principalRoleAssignment.role === role &&
                principalRoleAssignment.scopeId === scopeNodeModel.configuration.id);

    const errorMessage =
        _.isNil(saveGroupRoleAssignmentError) &&
        !groupRoleAssignmentExists
            ? undefined
            : groupRoleAssignmentExists
                ? localization.fields.groupRoleAssignment.error.exists()
                : saveGroupRoleAssignmentError === true
                    ? _.isNil(roleAssignment)
                        ? localization.actions.save.error.add()
                        : localization.actions.save.error.edit()
                    : localization.actions.save.error[Contract.TypeNames.ConfigurationControllerInsertGroupRoleAssignmentError][saveGroupRoleAssignmentError!]();

    const [valid, setValid] = useState(!_.isNil(roleAssignment) && role != roleAssignment.role);
    useChangeEffect(
        () => {
            setValid(
                (
                    (!_.isNil(roleAssignment) &&
                        role != roleAssignment.role) || (
                        identifierValidationController.isValid() &&
                        nameValidationController.isValid())) &&
                role != roleAssignment?.role &&
                !groupRoleAssignmentExists);

            setSaveGroupRoleAssignmentError(undefined);
        },
        [groupRoleAssignmentExists, identifier, name, role]);

    useChangeEffect(
        () => {
            if (!_.isNil(groupIdentifierToGroupMap[identifier]?.name)) {
                setName(groupIdentifierToGroupMap[identifier].name!);
            }
        },
        [identifier]);

    useChangeEffect(
        () => {
            if (!_.isNil(groupNameToGroupMap[name])) {
                setIdentifier(groupNameToGroupMap[name].identifier);
            }
        },
        [name]);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {saveGroupRoleAssignmentExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        <Button
                            disabled={!valid || saveGroupRoleAssignmentExecuting}
                            onClick={() => saveGroupRoleAssignment()}>
                            {_.isNil(roleAssignment)
                                ? localization.actions.save.title.add()
                                : localization.actions.save.title.edit()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{
                infoElement:
                    UserHelper.tenable
                        ? localization.helpText.title.tenableText()
                        : _.isNil(roleAssignment)
                            ? localization.helpText.title.text({
                                link:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsEnableConsoleLoginViaSsoRelativeUrl()}
                                        variant="external">
                                        {localization.helpText.title.link()}
                                    </Link>
                            })
                            : undefined,
                text:
                    _.isNil(roleAssignment)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <Stack spacing={5}>
                <Stack spacing={2}>
                    <ScopePath
                        scopeId={roleAssignment?.scopeId ?? scopeNodeModel.configuration.id}
                        variant="customer"/>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <AutocompleteItems
                            autoSelect={true}
                            disableClearable={true}
                            disabled={saveGroupRoleAssignmentExecuting || !_.isNil(roleAssignment)}
                            freeSolo={true}
                            fullWidth={true}
                            options={
                                _.map(
                                    groups,
                                    group => group.identifier)}
                            renderInput={
                                params => (
                                    <TextField
                                        {...params}
                                        label={localization.fields.identifier.title()}
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            titleOrGetTitle={
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "initial",
                                                                        padding: theme.spacing(1),
                                                                        whiteSpace: "pre-wrap"
                                                                    }}>
                                                                    {localization.helpText.identifier()}
                                                                </Typography>}>
                                                            <InfoIcon
                                                                sx={{
                                                                    color: theme.palette.text.secondary,
                                                                    fontSize: "24px"
                                                                }}/>
                                                        </Tooltip>
                                                    </InputAdornment>
                                            }
                                        }}
                                        variant="outlined"/>)}
                            value={identifier}
                            onChange={(_, identifier) => setIdentifier(identifier)}>
                            {identifier =>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <GroupIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "18px"
                                        }}/>
                                    <Typography>
                                        {_.isNil(groupIdentifierToGroupMap[identifier]?.name)
                                            ? identifier
                                            : `${identifier} (${groupIdentifierToGroupMap[identifier].name})`}
                                    </Typography>
                                </Stack>}
                        </AutocompleteItems>
                        {!_.isNil(identifierValidationMessage) && (
                            <FormHelperText error={true}>{identifierValidationMessage}</FormHelperText>)}
                    </FormControl>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <AutocompleteItems
                            autoSelect={true}
                            disableClearable={true}
                            disabled={saveGroupRoleAssignmentExecuting || !_.isNil(roleAssignment)}
                            freeSolo={true}
                            fullWidth={true}
                            options={_.keys(groupNameToGroupMap)}
                            renderInput={
                                params => (
                                    <TextField
                                        {...params}
                                        label={localization.fields.name.title()}
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            titleOrGetTitle={
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "initial",
                                                                        padding: theme.spacing(1),
                                                                        whiteSpace: "pre-wrap"
                                                                    }}>
                                                                    {localization.helpText.name()}
                                                                </Typography>}>
                                                            <InfoIcon
                                                                sx={{
                                                                    color: theme.palette.text.secondary,
                                                                    fontSize: "24px"
                                                                }}/>
                                                        </Tooltip>
                                                    </InputAdornment>
                                            }
                                        }}
                                        variant="outlined"/>)}
                            value={name}
                            onChange={(_event, name) => setName(name)}>
                            {name =>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <GroupIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "18px"
                                        }}/>
                                    <Typography>
                                        {_.isNil(groupNameToGroupMap[name])
                                            ? name
                                            : `${name} (${groupNameToGroupMap[name].identifier})`}
                                    </Typography>
                                </Stack>}
                        </AutocompleteItems>
                        {!_.isNil(nameValidationMessage) && (
                            <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
                    </FormControl>
                    <ItemSelector
                        disabled={saveGroupRoleAssignmentExecuting}
                        fullWidth={true}
                        items={
                            _(
                                [
                                    Contract.IdentityRole.Administrator,
                                    Contract.IdentityRole.Collaborator,
                                    Contract.IdentityRole.Viewer
                                ]).
                                concatIf(
                                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement) &&
                                    ScopeHelper.isPermissionManagementTenantsScope(scopeNodeModel),
                                    Contract.IdentityRole.PermissionManagementAdministrator).
                                value()}
                        placeholder={localization.fields.role()}
                        selectedItem={role}
                        sorted={false}
                        onSelectedItemChanged={setRole}>
                        {identityRoleTranslator}
                    </ItemSelector>
                </Stack>
                {!_.isNil(errorMessage) && (
                    <Message
                        level="error"
                        title={errorMessage}/>)}
            </Stack>
        </FormLayout>);
}