import { DialogMenuItem, TimeFormatter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, EntityController, InfoItem, tenantModelStore, usePrincipalModelAccess, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { Vendor } from "../../../../../../../../../../common/components/Vendor";
import { AwsTenantHelper, GenerateAwsResourceNonexcessivePolicyDialog, useAwsIamRoleAssumeRolePolicyDocumentStatementGranteeTranslator } from "../../../../../../../../../../tenants";
import { Info, PrincipalSecurityStatsStat } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { AwsIamPolicyDocument, AwsPrincipalPoliciesInfoCard } from "../../components";

export function useAwsIamRoleDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const role = resourceModel.entity as Contract.AwsIamRole;
    const roleModel = resourceModel as Contract.AwsIamRoleModel;
    const roleModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(roleModel.id);
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();
    const [entityExcessivePermissionCustomEvaluationData] =
        useExecuteOperation(
            [useAwsIamRoleDefinition, roleModel.id],
            async () =>
                await EntityController.getEntityExcessivePermissionCustomEvaluationData(
                    new Contract.EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest(roleModel.id)));

    const iamRoleAssumeRolePolicyDocumentStatementGranteeTranslator = useAwsIamRoleAssumeRolePolicyDocumentStatementGranteeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsIamRoleDefinition",
            () => ({
                info: {
                    items: {
                        accessLevel: "Trust Access Level",
                        activityTime: {
                            empty: "Never",
                            title: "Activity Time"
                        },
                        assumeRolePolicyDocumentStatementGrantees: "Trusted Entities",
                        vendors: "Vendors"
                    }
                },
                tabs: {
                    assumeRolePolicyDocument: "Trust Relationship Policy"
                },
                topbar: {
                    menu: {
                        generate: "Generate Least Privilege Policy"
                    }
                }
            }));


    const commonAccessResourceInfoElements =
        useAwsCommonAccessResourceInfoElements(
            resourceModel,
            { accessLevel: { title: localization.info.items.accessLevel() } });

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument
                    policyDocument={role.assumeRolePolicyDocument}
                    variant="assumeRolePolicy"/>,
                localization.tabs.assumeRolePolicyDocument(),
                "assumeRolePolicyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    <VerticalTopItemsInfoItem
                        items={iamRoleAssumeRolePolicyDocumentStatementGranteeTranslator(resourceModel.entity as Contract.AwsIamRole)}
                        key="trustedEntities"
                        title={localization.info.items.assumeRolePolicyDocumentStatementGrantees()}/>,
                    AwsTenantHelper.isAccessAdvisorEnabled(resourceModel.entity, tenantModelMap)
                        ? <InfoItem
                            key="activityTime"
                            location="all"
                            title={localization.info.items.activityTime.title()}
                            value={
                                _.isNil(role.activityTime)
                                    ? localization.info.items.activityTime.empty()
                                    : TimeFormatter.profileFormatDateTime(role.activityTime)}/>
                        : undefined,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                role.vendors,
                                vendor => Vendor({ vendorType: vendor }))}
                        key="vendors"
                        title={localization.info.items.vendors()}/>
                ]}
                options={options?.infoOptions}>
                <AwsPrincipalPoliciesInfoCard
                    inlinePolicyIds={role.inlinePolicyIds}
                    managedPolicyIds={role.managedPolicyIds}
                    permissionBoundaryPolicyId={role.permissionBoundaryPolicyId}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                AwsTenantHelper.isAccessAdvisorEnabled(resourceModel.entity, tenantModelMap) &&
                !_.isNil(roleModelAccess?.permissions)
                    ? <PrincipalSecurityStatsStat
                        analyzingReason={roleModel.analyzingReason}
                        securityStats={roleModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.AwsIamRole}/>
                    : undefined
            ]),
        topbarMenuItems:
            entityExcessivePermissionCustomEvaluationData.nonexcessivePermitterCustomGenerationEnabled
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateAwsResourceNonexcessivePolicyDialog
                                entityExcessivePermissionCustomEvaluationData={entityExcessivePermissionCustomEvaluationData}
                                entityModel={roleModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}