import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useAwsFirehoseDeliveryStreamStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsFirehoseDeliveryStreamRiskContext() {
    return useMemo(
        () => useAwsFirehoseDeliveryStreamRiskContext,
        []);
}

function useAwsFirehoseDeliveryStreamRiskContext(deliveryStreamModel: Contract.AwsFirehoseDeliveryStreamModel) {
    const firehoseDeliveryStream = deliveryStreamModel.entity as Contract.AwsFirehoseDeliveryStream;
    const firehoseDeliveryStreamStatusTranslator = useAwsFirehoseDeliveryStreamStatusTranslator();
    const resourceRiskContext = useGetAwsResourceRiskContext()(deliveryStreamModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsFirehoseDeliveryStreamRiskContext",
            () => ({
                status: "The Data Firehose stream status is **{{status}}**"
            }));

    return {
        ...resourceRiskContext,
        status: new RiskDefinitionContextItem(
            localization.status({
                status: firehoseDeliveryStreamStatusTranslator(firehoseDeliveryStream.status)
            }))
    };
}