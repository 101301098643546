import { SxProps, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useEffect, useRef } from "react";
import { Action1, ContextMenuItem, DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DeferredFilter, EmptyMessageText, Optional, optionalTableCell, PagedValuesFilter, PagedValuesFilterSelection, PagedValuesFilterValuePage, StorageItem, StringHelper, TimeFormatter, TimeHelper, TimeRangeFilter, Tooltip, useGetExportFileName, useGetOperation, useLocalization, useSetRoute, ValuesFilterSelection } from "@infrastructure";
import { TimeCell } from ".";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, Entity, EntityContextMenuContextProvider, EntityModelHelper, entityModelStore, EventController, ExportReportHelper, ItemSelectionHelper, PagedEntityFilter, PagedEntityFilterEntityIdPage, ScopeTenantFilter, StorageHelper, Tenant, TenantCell, tenantModelStore, TimeRangeHelper, useEntityTypeNameTranslator, useTenantNameTranslator, useTenantTypeTranslator } from "../../../../../common";
import { ProfileView } from "../../Entities/components";
import { ProfileCategory as EntitiesProfileCategory } from "../../Entities/components/Profile/hooks";
import { CustomerSideViewItemType } from "../../SideView";
import { ProfileCategory } from "./Profile";

type TableProps = {
    dataTableVariant?: "card" | "exportButtonOnly" | "filtersOnly";
    enableColumnSelector?: boolean;
    entityId?: string;
    filtersOptions?: TableFiltersOptions;
    riskId?: string;
};

type TableFiltersOptions = {
    initialMap?: Dictionary<any>;
    onFiltersChanged?: Action1<Dictionary<any>>;
    sx?: SxProps;
    time?: string;
    visibilityStorageItem?: StorageItem;
};

export function Table({ dataTableVariant, enableColumnSelector = false, entityId, filtersOptions, riskId }: TableProps) {
    const profileView =
        !_.isNil(entityId) ||
        !_.isNil(riskId);

    useEffect(
        () => {
            StorageHelper.customerEventsProfileSelectedTab.removeValue();
            return () => StorageHelper.customerEventsProfileSelectedTab.removeValue();
        },
        []);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.events.table",
            () => ({
                actions: {
                    csvExport: {
                        fileNameEntityPrefix: "ActivityLog_{{entityDisplayName}}",
                        fileNamePrefix: "ActivityLog"
                    },
                    filter: {
                        exclude: "Filter out **{{value}}**",
                        include: "Filter only **{{value}}**"
                    },
                    profile: "Go to profile page",
                    search: {
                        placeholder: "Search IP address",
                        title: "Search"
                    }
                },
                columns: {
                    errorCode: "Result",
                    identityIdReference: "Identity",
                    ipAddress: "IP Address",
                    name: "Action",
                    originatorEntityIdReference: "Originator",
                    resourceIdReferences: "Resources",
                    serviceIdReference: "Service",
                    tenantId: "Account",
                    time: "Time"
                },
                empty: {
                    withFilter: "No Matching Activity Logs",
                    withoutFilter: "No Activity Logs"
                },
                emptyValue: "(Blanks)"
            }));

    const getEventFiltersResponsePromise =
        useGetOperation<Contract.EventControllerGetEventFiltersResponse>(
            [Table, entityId ?? riskId],
            () =>
                EventController.getEventFilters(
                    _.isNil(entityId)
                        ? _.isNil(riskId)
                            ? new Contract.EventControllerGetTenantEventFiltersRequest()
                            : new Contract.EventControllerGetRiskEventFiltersRequest(riskId)
                        : new Contract.EventControllerGetEntityEventFiltersRequest(entityId)));

    async function getEventModelPageRequest(filterMap: Dictionary<any>, limit: number, skip: number) {
        const nextPageSearchCursor =
            skip === 0
                ? undefined
                : fetchEventModelNextPageSearchCursorRef.current;

        const filters =
            new Contract.EventControllerGetEventModelPageRequestFilters(
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EventModelProperty.ErrorCode]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EventModelProperty.Identity]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EventModelProperty.IpAddress]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EventModelProperty.Name]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EventModelProperty.OriginatorEntity]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EventModelProperty.Resources]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EventModelProperty.Service]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EventModelProperty.Tenant]),
                TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(filtersOptions?.time ?? dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.EventModelProperty.Time]));
        return _.isNil(entityId)
            ? _.isNil(riskId)
                ? new Contract.EventControllerGetTenantEventModelPageRequest(
                    filters,
                    limit,
                    nextPageSearchCursor)
                : new Contract.EventControllerGetRiskEventModelPageRequest(
                    filters,
                    limit,
                    nextPageSearchCursor,
                    riskId)
            : new Contract.EventControllerGetEntityEventModelPageRequest(
                filters,
                limit,
                nextPageSearchCursor,
                entityId);
    }

    async function getExportData(itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<ColumnExportData>>): Promise<ExportData> {
        const columnExportDatas =
            _(itemIdToExportColumnElementIdToExportDataMap).
                values().
                flatMap(exportColumnElementIdToExportDataMap => _.values(exportColumnElementIdToExportDataMap)).
                value();

        const relatedEntityModels =
            await entityModelStore.get(
                _(columnExportDatas).
                    flatMap(data => data.entityIds!).
                    filter().
                    value());
        const relatedEntityModelMap =
            _.keyBy(
                relatedEntityModels,
                relatedEntityModel => relatedEntityModel.id);

        const relatedTenantModels =
            await tenantModelStore.get(
                _(columnExportDatas).
                    map(data => data.tenantId!).
                    filter().
                    value());
        const relatedTenantIdToTypeMap =
            _(relatedTenantModels).
                keyBy(tenantModel => tenantModel.configuration.id).
                mapValues(tenantModel => tenantModel.tenantType).
                value();

        return {
            relatedEntityModelMap,
            relatedTenantIdToTypeMap
        };
    }

    const fetchEventModelNextPageSearchCursorRef = useRef<Contract.ElasticsearchIndexSearchCursor>();
    const fetchEventModels =
        useCallback(
            async (filterMap: Dictionary<any>, _sort: Optional<DataTableSort>, skip: number, limit: number) => {
                const eventModelPageRequest =
                    await getEventModelPageRequest(
                        filterMap,
                        limit,
                        skip);

                const getEventModelCount =
                    async () => {
                        const { eventModelCount } = await EventController.getEventModelCount(eventModelPageRequest);
                        return { count: eventModelCount };
                    };

                const { eventModelPage } = await EventController.getEventModelPage(eventModelPageRequest);

                return new DataTableFetchItemsResult(
                    getEventModelCount,
                    eventModelPage.items,
                    _.isNil(eventModelPage.itemNextPageSearchCursor),
                    {
                        onAppendData:
                            () => {
                                fetchEventModelNextPageSearchCursorRef.current = eventModelPage.itemNextPageSearchCursor;
                            }
                    });
            },
            []);

    const getEventFilterItemPage =
        async (property: Contract.EventModelProperty, searchText: Optional<string>, skip: number, limit: number, data: EventsFilterItemPageData) => {
            const { eventFilterItemPage } =
                await EventController.getEventFilterItemPage(
                    new Contract.EventControllerGetEventFilterItemPageRequest(
                        limit,
                        property,
                        searchText,
                        skip)) as Contract.EventControllerGetEventFilterItemPageResponse<any>;
            const eventFilterItemCount = eventFilterItemPage.count ?? data.count;

            return new PagedValuesFilterValuePage(
                eventFilterItemCount,
                eventFilterItemPage.emptyValue,
                eventFilterItemPage.items,
                () => ({ count: eventFilterItemCount }));
        };

    const dataTableActionsRef = useRef<DataTableActions>();
    const setRoute = useSetRoute();

    function getEntityContextMenuItems(columnId: string, emptyValue = false) {
        return async (entityModel: Contract.EntityModel) => {
            if (emptyValue && _.isNil(entityModel)) {
                return [
                    new ContextMenuItem(
                        () =>
                            dataTableActionsRef.current!.setFilter(
                                columnId,
                                () =>
                                    new PagedValuesFilterSelection(
                                        true,
                                        "include",
                                        [])),
                        localization.actions.filter.include({ value: localization.emptyValue() })),
                    new ContextMenuItem(
                        () =>
                            dataTableActionsRef.current!.setFilter(
                                columnId,
                                filter =>
                                    _.isNil(filter) ||
                                    filter.type === "exclude"
                                        ? new PagedValuesFilterSelection(
                                            true,
                                            "exclude",
                                            filter?.values ?? [])
                                        : new PagedValuesFilterSelection(
                                            false,
                                            "include",
                                            filter.values)),
                        localization.actions.filter.exclude({ value: localization.emptyValue() }))];
            }

            const entityProfileUrl = CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel);
            return _.filter([
                new ContextMenuItem(
                    () =>
                        dataTableActionsRef.current!.setFilter(
                            columnId,
                            () => new PagedValuesFilterSelection(
                                false,
                                "include",
                                [entityModel.id])),
                    localization.actions.filter.include({
                        value: <Entity
                            entityIdOrModel={entityModel}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="text"/>
                    })),
                new ContextMenuItem(
                    () =>
                        dataTableActionsRef.current!.setFilter(
                            columnId,
                            filter => {
                                if (_.isNil(filter) || filter.type === "exclude") {
                                    return new PagedValuesFilterSelection(
                                        filter?.emptyValue ?? false,
                                        "exclude",
                                        _.union(filter?.values, [entityModel.id]));
                                } else {
                                    const values = _.difference(filter?.values, [entityModel.id]);
                                    return _.isEmpty(values) && !filter.emptyValue
                                        ? undefined
                                        : new PagedValuesFilterSelection(
                                            filter.emptyValue,
                                            "include",
                                            values);
                                }
                            }),
                    localization.actions.filter.exclude({
                        value: <Entity
                            entityIdOrModel={entityModel}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="text"/>
                    })),
                _.isNil(entityProfileUrl)
                    ? undefined!
                    : new ContextMenuItem(
                        () => setRoute(entityProfileUrl),
                        localization.actions.profile())
            ]);
        };
    }

    async function getTenantContextMenuItems(tenantId: string) {
        const getEventFiltersResponse = await getEventFiltersResponsePromise();
        return [
            new ContextMenuItem(
                () =>
                    dataTableActionsRef.current!.setFilter(
                        Contract.EventModelProperty.Tenant,
                        () =>
                            new ValuesFilterSelection(
                                false,
                                _.concat(tenantId))),
                localization.actions.filter.include({
                    value: <Tenant
                        sx={{ fontWeight: 600 }}
                        tenantId={tenantId}
                        variant="text"/>
                })),
            new ContextMenuItem(
                () =>
                    dataTableActionsRef.current!.setFilter(
                        Contract.EventModelProperty.Tenant,
                        filter => {
                            const filterValues =
                                _.difference(
                                    _.isNil(filter)
                                        ? getEventFiltersResponse.tenantIdItems.items
                                        : filter.values as string[],
                                    _.concat(tenantId));
                            return _.isEmpty(filterValues)
                                ? undefined
                                : new ValuesFilterSelection(
                                    false,
                                    filterValues);
                        }),
                localization.actions.filter.exclude({
                    value:
                        <Tenant
                            sx={{ fontWeight: 600 }}
                            tenantId={tenantId}
                            variant="text"/>
                }))
        ];
    }

    function getValuePagedFilterContextMenuItems(columnId: string, value: any | any[], emptyValue = false) {
        if (emptyValue && _.isNil(value)) {
            return [
                new ContextMenuItem(
                    () =>
                        dataTableActionsRef.current!.setFilter(
                            columnId,
                            () => new PagedValuesFilterSelection(
                                true,
                                "include",
                                [])),
                    localization.actions.filter.include({ value: localization.emptyValue() })),
                new ContextMenuItem(
                    () =>
                        dataTableActionsRef.current!.setFilter(
                            columnId,
                            filter =>
                                _.isNil(filter) ||
                                filter.type === "exclude"
                                    ? new PagedValuesFilterSelection(
                                        true,
                                        "exclude",
                                        filter?.values ?? [])
                                    : new PagedValuesFilterSelection(
                                        false,
                                        "include",
                                        filter.values)),
                    localization.actions.filter.exclude({ value: localization.emptyValue() }))
            ];
        }

        const values = _.concat(value);
        return [
            new ContextMenuItem(
                () =>
                    dataTableActionsRef.current!.setFilter(
                        columnId,
                        () => new PagedValuesFilterSelection(
                            false,
                            "include",
                            values)),
                localization.actions.filter.include({ value })),
            new ContextMenuItem(
                () =>
                    dataTableActionsRef.current!.setFilter(
                        columnId,
                        filter =>
                            _.isNil(filter) || filter.type === "exclude"
                                ? new PagedValuesFilterSelection(
                                    filter?.emptyValue ?? false,
                                    "exclude",
                                    _.union(filter?.values, values))
                                : new PagedValuesFilterSelection(
                                    filter.emptyValue,
                                    "include",
                                    _.difference(filter?.values, values))),
                localization.actions.filter.exclude({ value }))
        ];
    }

    function createEntityPagedFilterColumnElement(
        property: Contract.EventModelProperty,
        defaultFilter: boolean,
        emptyValue: boolean,
        getEventEntityIdReferences: (eventModel: Contract.EventModel) => string[],
        getEntitySearchableIdReferenceItems: () => Promise<Optional<Contract.FilterItems<Contract.EntitySearchableIdReference>>>,
        getExportData: (item: Contract.EventModel) => ColumnExportData,
        getExportItem: (item: Contract.EventModel, exportData: ExportData) => Dictionary<Optional<string>>,
        title: string,
        showEntityTenant = true,
        selectorDisabled = false) {
        return (
            <DataTableColumn
                exportOptions={{
                    getData: getExportData,
                    getItem: getExportItem
                }}
                filterOptions={{
                    itemOrItems: {
                        default: defaultFilter,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    async (searchText, skip, limit, data) => {
                                        if (profileView) {
                                            const entitySearchableIdReferenceItems = await getEntitySearchableIdReferenceItems();
                                            const idReferences =
                                                _(entitySearchableIdReferenceItems!.items).
                                                    filter(entitySearchableIdReference => StringHelper.search(entitySearchableIdReference.displayName, searchText)).
                                                    orderBy(entitySearchableIdReference => StringHelper.getSortValue(entitySearchableIdReference.displayName)).
                                                    map(entitySearchableIdReference => entitySearchableIdReference.idReference).
                                                    value();

                                            return new PagedEntityFilterEntityIdPage(
                                                _.size(idReferences),
                                                entitySearchableIdReferenceItems?.emptyValue,
                                                idReferences);
                                        }

                                        const { applyData, count, emptyValue, values } =
                                            await getEventFilterItemPage(
                                                property,
                                                searchText,
                                                skip,
                                                limit,
                                                data);
                                        return new PagedEntityFilterEntityIdPage(
                                            count,
                                            emptyValue,
                                            values,
                                            applyData);
                                    }}
                                placeholder={title}/>
                    }
                }}
                id={property}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.EventModel>) => {
                        const eventEntityIdReferences = getEventEntityIdReferences(item);
                        const eventEntityModels = entityModelStore.useGet(eventEntityIdReferences);
                        return (
                            <EntityContextMenuContextProvider
                                getContextMenuItems={
                                    getEntityContextMenuItems(
                                        property,
                                        emptyValue)}>
                                <EntitiesCell
                                    entityIdsOrModels={eventEntityModels}
                                    entityLinkOptions={{
                                        categoryView: {
                                            category: EntitiesProfileCategory.Events,
                                            view: ProfileView.Events
                                        }
                                    }}
                                    entityTypeName={EntityModelHelper.getCommonTypeName(eventEntityModels) ?? Contract.TypeNames.Entity}
                                    entityVariant={
                                        showEntityTenant
                                            ? "iconTextTenant"
                                            : "iconText"}/>
                            </EntityContextMenuContextProvider>);
                    }}
                selectorOptions={{ disabled: selectorDisabled }}
                title={title}/>);
    }

    const entityModel = entityModelStore.useGet(entityId);
    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerEventsColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: enableColumnSelector,
                    persistenceStorageItem: StorageHelper.customerEventsColumnSelector
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{
                fileNamePrefix:
                    _.isNil(entityModel)
                        ? localization.actions.csvExport.fileNamePrefix()
                        : localization.actions.csvExport.fileNameEntityPrefix({ entityDisplayName: entityModel.entity.displayName }),
                getData: getExportData,
                onExportClick:
                    async (filterMap, fileNameOptions) => {
                        const exportFileName = getExportFileName(fileNameOptions);
                        const reportRequestDefinition =
                            new Contract.ReportControllerEventsReportRequestDefinition(
                                exportFileName,
                                TimeHelper.timeZoneId(),
                                Contract.TypeNames.ReportControllerEventsReportRequestDefinition,
                                entityId,
                                _.isEmpty(filterMap)
                                    ? undefined
                                    : JSON.stringify(filterMap),
                                riskId);
                        await ExportReportHelper.downloadRemote(reportRequestDefinition);
                    }
            }}
            fetchItems={fetchEventModels}
            filtersOptions={{
                initial: {
                    state: filtersOptions?.initialMap
                },
                onChanged: filtersOptions?.onFiltersChanged,
                persist: { visibilityStorageItem: filtersOptions?.visibilityStorageItem },
                sx: filtersOptions?.sx
            }}
            getItemId={(item: Contract.EventModel) => item.event.id}
            highlightItem={{
                hashRouteTemplate: `${CustomerSideViewItemType.Event}/{itemId}`
            }}
            rowOptions={{
                getUrl:
                    (item: Contract.EventModel) =>
                        CustomerConsoleAppUrlHelper.getEventProfileHashUrl(
                            item.event.id,
                            StorageHelper.customerEventsProfileSelectedTab.getValue() as ProfileCategory)!
            }}
            sortOptions={{ enabled: false }}
            variant={dataTableVariant}
            virtualizationEnabled={true}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EventModel) => ({
                            [localization.columns.time()]: TimeFormatter.iso8601String(item.event.time)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={getEventFiltersResponsePromise}
                                title={localization.columns.time()}>
                                {getEventFiltersResponse =>
                                    <TimeRangeFilter
                                        emptyValue={getEventFiltersResponse.timeRange.emptyValue}
                                        placeholder={localization.columns.time()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(getEventFiltersResponse.timeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EventModelProperty.Time}
                itemProperty={(item: Contract.EventModel) => TimeFormatter.shortDateTime(item.event.time)}
                render={TimeCell}
                selectorOptions={{ disabled: true }}
                title={localization.columns.time()}/>
            {createEntityPagedFilterColumnElement(
                Contract.EventModelProperty.Identity,
                true,
                false,
                eventModel => _.filter([eventModel.identityIdReference] as string[]),
                async () => {
                    const { identitySearchableIdReferenceItems } = await getEventFiltersResponsePromise();
                    return identitySearchableIdReferenceItems!;
                },
                item => (
                    _.isNil(item.identityIdReference)
                        ? {}
                        : { entityIds: [item.identityIdReference!] }),
                (item, exportData) => {
                    const identityModel =
                        _.isNil(item.identityIdReference)
                            ? undefined
                            : exportData.relatedEntityModelMap[item.identityIdReference];

                    return {
                        /* eslint-disable sort-keys-fix/sort-keys-fix */
                        "Identity Name": identityModel?.entity.displayName,
                        "Identity Type":
                            _.isNil(identityModel) || identityModel.unknown
                                ? ""
                                : entityTypeNameTranslator(identityModel.entity.typeName),
                        "Identity Account":
                            _.isNil(identityModel)
                                ? ""
                                : tenantNameTranslator(identityModel.tenantId)
                        /* eslint-enable sort-keys-fix/sort-keys-fix */
                    };
                },
                localization.columns.identityIdReference(),
                true,
                true)}
            {createEntityPagedFilterColumnElement(
                Contract.EventModelProperty.OriginatorEntity,
                true,
                true,
                eventModel => _.filter([eventModel.originatorEntityIdReference]) as string[],
                async () => {
                    const { originatorEntitySearchableIdReferenceItems } = await getEventFiltersResponsePromise();
                    return originatorEntitySearchableIdReferenceItems!;
                },
                item => (
                    _.isNil(item.originatorEntityIdReference)
                        ? {}
                        : { entityIds: [item.originatorEntityIdReference!] }),
                (item, exportData) =>
                    ({
                        [localization.columns.originatorEntityIdReference()]:
                            _.isNil(item.originatorEntityIdReference)
                                ? undefined
                                : exportData.relatedEntityModelMap[item.originatorEntityIdReference].entity.displayReference
                    }),
                localization.columns.originatorEntityIdReference(),
                true,
                true)}
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EventModel) => ({
                            [localization.columns.tenantId()]: tenantNameTranslator(item.event.tenantId)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={getEventFiltersResponsePromise}
                                title={localization.columns.tenantId()}>
                                {getEventFiltersResponse =>
                                    <ScopeTenantFilter
                                        placeholder={localization.columns.tenantId()}
                                        tenantIds={getEventFiltersResponse.tenantIdItems!.items}/>}
                            </DeferredFilter>
                    }
                }}
                getContextMenuItems={item => getTenantContextMenuItems(item.event.tenantId)}
                id={Contract.EventModelProperty.Tenant}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.EventModel>) =>
                        <TenantCell tenantId={item.event.tenantId}/>}
                title={localization.columns.tenantId()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EventModel) => ({
                            [localization.columns.name()]: item.event.displayName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    async (searchText, skip, limit, data) => {
                                        if (profileView) {
                                            const getEventFiltersResponse = await getEventFiltersResponsePromise();
                                            const names =
                                                _(getEventFiltersResponse.nameItems!.items).
                                                    filter(name => StringHelper.search(name, searchText)).
                                                    orderBy(name => StringHelper.getSortValue(name)).
                                                    value();

                                            return new PagedValuesFilterValuePage(
                                                _.size(names),
                                                getEventFiltersResponse.nameItems!.emptyValue,
                                                names);
                                        }

                                        return getEventFilterItemPage(
                                            Contract.EventModelProperty.Name,
                                            searchText,
                                            skip,
                                            limit,
                                            data);
                                    }}
                                placeholder={localization.columns.name()}/>
                    }
                }}
                getContextMenuItems={
                    item =>
                        getValuePagedFilterContextMenuItems(
                            Contract.EventModelProperty.Name,
                            item.event.name,
                            true)}
                id={Contract.EventModelProperty.Name}
                render={optionalTableCell<Contract.EventModel>(item => item.event.displayName)}
                title={localization.columns.name()}/>
            {createEntityPagedFilterColumnElement(
                Contract.EventModelProperty.Service,
                false,
                false,
                eventModel => _.filter([eventModel.serviceIdReference]) as string[],
                async () => {
                    const { serviceSearchableIdReferenceItems } = await getEventFiltersResponsePromise();
                    return serviceSearchableIdReferenceItems!;
                },
                item => (
                    _.isNil(item.serviceIdReference)
                        ? {}
                        : { entityIds: [item.serviceIdReference!] }),
                (item, exportData) =>
                    ({
                        [localization.columns.serviceIdReference()]:
                            _.isNil(item.serviceIdReference)
                                ? undefined
                                : exportData.relatedEntityModelMap[item.serviceIdReference].entity.displayReference
                    }),
                localization.columns.serviceIdReference(),
                false)}
            {createEntityPagedFilterColumnElement(
                Contract.EventModelProperty.Resources,
                !profileView,
                true,
                eventModel => eventModel.resourceIdReferences,
                async () => {
                    const { resourceSearchableIdReferenceItems } = await getEventFiltersResponsePromise();
                    return resourceSearchableIdReferenceItems!;
                },
                item => ({ entityIds: item.resourceIdReferences }),
                (item, exportData) =>
                    ({
                        [localization.columns.resourceIdReferences()]:
                            (item.resourceIdReferences).
                                map(resourceIdReference => exportData.relatedEntityModelMap[resourceIdReference]?.entity.displayReference).
                                sort().
                                join("\n")
                    }),
                localization.columns.resourceIdReferences())}
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EventModel) => ({
                            [localization.columns.errorCode()]: item.event.errorCode
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    async (searchText, skip, limit, data) => {
                                        if (profileView) {
                                            const getEventFiltersResponse = await getEventFiltersResponsePromise();
                                            const errorCodes =
                                                _(getEventFiltersResponse.errorCodeItems!.items).
                                                    filter(errorCode => StringHelper.search(errorCode, searchText)).
                                                    orderBy(errorCode => StringHelper.getSortValue(errorCode)).
                                                    value();

                                            return new PagedValuesFilterValuePage(
                                                _.size(errorCodes),
                                                getEventFiltersResponse.errorCodeItems!.emptyValue,
                                                errorCodes!);
                                        }

                                        return getEventFilterItemPage(
                                            Contract.EventModelProperty.ErrorCode,
                                            searchText,
                                            skip,
                                            limit,
                                            data);
                                    }}
                                placeholder={localization.columns.errorCode()}/>
                    }
                }}
                getContextMenuItems={
                    item =>
                        getValuePagedFilterContextMenuItems(
                            Contract.EventModelProperty.ErrorCode,
                            item.event.errorCode)}
                id={Contract.EventModelProperty.ErrorCode}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.EventModel>) =>
                        <Tooltip titleOrGetTitle={item.event.errorMessage}>
                            <Typography noWrap={true}>
                                {item.event.errorCode}
                            </Typography>
                        </Tooltip>}
                title={localization.columns.errorCode()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EventModel) => ({
                            [localization.columns.ipAddress()]: item.event.ipAddress
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    async (searchText, skip, limit, data) => {
                                        if (profileView) {
                                            const getEventFiltersResponse = await getEventFiltersResponsePromise();
                                            const ipAddresses =
                                                _(getEventFiltersResponse.ipAddressItems!.items).
                                                    filter(ipAddress => StringHelper.search(ipAddress, searchText)).
                                                    orderBy(ipAddress => StringHelper.getSortValue(ipAddress)).
                                                    value();

                                            return new PagedValuesFilterValuePage(
                                                _.size(ipAddresses),
                                                getEventFiltersResponse.ipAddressItems!.emptyValue,
                                                ipAddresses!);
                                        }

                                        return getEventFilterItemPage(
                                            Contract.EventModelProperty.IpAddress,
                                            searchText,
                                            skip,
                                            limit,
                                            data);
                                    }}
                                placeholder={localization.columns.ipAddress()}/>
                    }
                }}
                getContextMenuItems={
                    item =>
                        getValuePagedFilterContextMenuItems(
                            Contract.EventModelProperty.IpAddress,
                            item.event.ipAddress,
                            true)}
                id={Contract.EventModelProperty.IpAddress}
                render={optionalTableCell<Contract.EventModel>(item => item.event.ipAddress)}
                title={localization.columns.ipAddress()}/>
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.EventModel) => ({ tenantId: item.event.tenantId }),
                    getItem:
                        (item: Contract.EventModel, exportData: ExportData) => ({
                            Cloud: tenantTypeTranslator(exportData.relatedTenantIdToTypeMap[item.event.tenantId])
                        })
                }}
                id="tenantType"/>
        </DataTable>);
}

type ColumnExportData = {
    entityIds?: string[];
    tenantId?: string;
};

type EventsFilterItemPageData = {
    count: number;
    itemNextPageSearchCursor?: Contract.ElasticsearchIndexSearchCursor;
};

type ExportData = {
    relatedEntityModelMap: Dictionary<Contract.EntityModel>;
    relatedTenantIdToTypeMap: Dictionary<Contract.TenantType>;
};