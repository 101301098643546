﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useSystemKubernetesClusterSensorStatusTooltipTranslator() {
    const localization =
        useLocalization(
            "tenants.kubernetes.hooks.useSystemKubernetesClusterSensorStatusTooltipTranslator",
            () => ({
                [Contract.TypeNames.SystemKubernetesClusterModelHelmComponentStatus]: {
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled]: "Tenable Cloud Security Sensor is not installed.",
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.NotRunning]: "Tenable Cloud Security Sensor wasn't active in a while.",
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.Running]: "Tenable Cloud Security Sensor is running."
                }
            }));
    return (status: Contract.SystemKubernetesClusterModelHelmComponentStatus) =>
        localization[Contract.TypeNames.SystemKubernetesClusterModelHelmComponentStatus][status]();
}