﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useOciTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.oci.hooks.useOciTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.OciTenantModelStatus]: {
                    [Contract.OciTenantModelStatus.AccessDenied]: "Access Denied",
                    [Contract.OciTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.OciTenantModelStatus.OrganizationIssue]: "Tenancy is not connected",
                    [Contract.OciTenantModelStatus.ParentTenantIssue]: "Parent compartment is not connected",
                    [Contract.OciTenantModelStatus.TenantDeleted]: "Compartment is deleted",
                    [Contract.OciTenantModelStatus.TenantDeletePending]: "Compartment is pending deletion",
                    [Contract.OciTenantModelStatus.TenantNotActive]: "Compartment is not active",
                    [Contract.OciTenantModelStatus.TenantNotExist]: "Compartment is permanently deleted",
                    [Contract.OciTenantModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.OciTenantModelStatus) => localization[Contract.TypeNames.OciTenantModelStatus][status]();
}