import { DataTableColumn, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React from "react";
import { Contract, LicensingHelper } from "../../../../../../../../../common";

export function useCommonVirtualMachineResourceDefinition() {
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonVirtualMachineResourceDefinition",
            () => ({
                columns: {
                    kubernetesClusterSensor: {
                        false: "Agentless",
                        title: "Scan Source",
                        true: "Agent"
                    }
                }
            }));

    return {
        columns: {
            ...LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise)
                ? {
                    scanSource:
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (item: Contract.IVirtualMachineModel) => ({
                                        [localization.columns.kubernetesClusterSensor.title()]:
                                            item.kubernetesClusterSensor
                                                ? localization.columns.kubernetesClusterSensor.true()
                                                : localization.columns.kubernetesClusterSensor.false()
                                    })
                            }}
                            filterOptions={
                                {
                                    itemOrItems: {
                                        element:
                                            <ValuesFilter
                                                placeholder={localization.columns.kubernetesClusterSensor.title()}
                                                sorted={false}>
                                                <ValuesFilterItem
                                                    title={localization.columns.kubernetesClusterSensor.true()}
                                                    value={true}/>
                                                <ValuesFilterItem
                                                    title={localization.columns.kubernetesClusterSensor.false()}
                                                    value={false}/>
                                            </ValuesFilter>
                                    }
                                }
                            }
                            id={Contract.EntityModelProperty.VirtualMachineKubernetesClusterSensor}
                            itemProperty={(virtualMachineModel: Contract.IVirtualMachineModel) =>
                                virtualMachineModel.kubernetesClusterSensor
                                    ? localization.columns.kubernetesClusterSensor.true()
                                    : localization.columns.kubernetesClusterSensor.false()}
                            key={Contract.EntityModelProperty.VirtualMachineKubernetesClusterSensor}
                            selectorOptions={{ default: false }}
                            title={localization.columns.kubernetesClusterSensor.title()}/>
                }
                : undefined
        }
    };
}