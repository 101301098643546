import { DistributionBar, DistributionBarItem, NoneIcon } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

type DisabledBarProps = {
    dense: boolean;
};

export function DisabledBar({ dense }: DisabledBarProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            <Box
                sx={
                    dense
                        ? { width: theme.spacing(15) }
                        : { flex: 1 }
                }>
                <DistributionBar
                    height="narrow"
                    items={
                        [
                            new DistributionBarItem(
                                theme.palette.text.disabled,
                                1,
                                undefined)
                        ]}
                    totalValue={1}/>
            </Box>
            <Stack
                sx={{
                    alignItems: "flex-end",
                    width:
                        theme.spacing(
                            dense
                                ? 5
                                : 6)
                }}>
                <NoneIcon sx={{ fontSize: "18px" }}/>
            </Stack>
        </Stack>);
}