import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../..";
import { Contract, Entity, InlineEntities, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../../common";

export type ContextSectionProps = {
    risk: Contract.AwsIamRolePublicAccessExistsRisk;
    roleModel: Contract.AwsIamRoleModel;
};

export function ContextSection({ risk, roleModel }: ContextSectionProps) {
    const role = roleModel.entity as Contract.AwsIamRole;
    const rolePermissionActionSeverity = risk.rolePermissionActionSeverity;

    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(roleModel, risk.id);
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRolePublicAccessExistsRiskDefinition.contextSection",
            () => ({
                attachedPolicies: {
                    attached: {
                        rolePermissionActionSeverityPart: " granting the role {{rolePermissionActionSeverity}} severity permissions",
                        text: "The role has {{policies}} attached{{rolePermissionActionSeverityPart}}"
                    },
                    notAttached: "The role has no policies attached"
                },
                generalInformation: {
                    activityTimePart: {
                        ever: "last active at {{date | TimeFormatter.shortDate}}",
                        never: "never active"
                    },
                    creatorPart: " by {{creatorEntity}}",
                    text: "Role {{role}} was created on {{creationTime | TimeFormatter.shortDate}}{{creatorPart}} and it was {{activityTimePart}}"
                }
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                localization.generalInformation.text({
                    activityTimePart:
                        _.isNil(role.activityTime)
                            ? localization.generalInformation.activityTimePart.never()
                            : localization.generalInformation.activityTimePart.ever({ activityTime: role.activityTime }),
                    creationTime: roleModel.creationTime,
                    creatorPart:
                        _.isNil(roleModel.creatorEntityIdReference)
                            ? ""
                            : localization.generalInformation.creatorPart({
                                creatorEntity:
                                    <Entity
                                        entityIdOrModel={roleModel.creatorEntityIdReference!}
                                        variant="text"/>
                            }),
                    role:
                        <Entity
                            entityIdOrModel={roleModel}
                            variant="text"/>
                }),
                role.inlinePolicyIds.length + role.managedPolicyIds.length == 0
                    ? localization.attachedPolicies.notAttached()
                    : localization.attachedPolicies.attached.text({
                        policies:
                            <InlineEntities
                                entityIdsOrModels={role.inlinePolicyIds.concat(role.managedPolicyIds)}
                                entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                                variant="itemCountAndType"/>,
                        rolePermissionActionSeverityPart:
                            _.isNil(rolePermissionActionSeverity)
                                ? ""
                                : localization.attachedPolicies.attached.rolePermissionActionSeverityPart({
                                    rolePermissionActionSeverity: severityTranslator(rolePermissionActionSeverity, "text")
                                })
                    }),
                openRiskedEntityRisksStep,
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!,
                    {
                        severity:
                            _.isNil(rolePermissionActionSeverity)
                                ? undefined
                                : severityTranslator(rolePermissionActionSeverity, "text")
                    })
            ])}
        </Steps>);
}