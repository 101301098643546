import { map, Optional } from "@infrastructure";
import { Contract } from "../controllers";

export class AuthenticationHelper {
    private static _authenticationUserAadClientId?: string;
    private static _authenticationCustomerAadClientId?: string;

    public static initialize(
        authenticationUserAadClientId: Optional<string>,
        authenticationCustomerAadClientId: Optional<string>){
        AuthenticationHelper._authenticationUserAadClientId = authenticationUserAadClientId;
        AuthenticationHelper._authenticationCustomerAadClientId = authenticationCustomerAadClientId;
    }

    public static getAuthenticationClientId(consoleAppType: Contract.ConsoleAppType.Customer | Contract.ConsoleAppType.User): Optional<string> {
        return map(
            consoleAppType,
            {
                [Contract.ConsoleAppType.Customer]: () => AuthenticationHelper._authenticationCustomerAadClientId,
                [Contract.ConsoleAppType.User]: () => AuthenticationHelper._authenticationUserAadClientId
            });
    }
}