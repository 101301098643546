import { ActionMenuItem, ContentMenuItem, MenuItem, Optional, useLocalization } from "@infrastructure";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Contract, useTheme } from "../../common";

export function useEntityExcessivePermissionCustomEvaluationMenuItems(date: Optional<string>, dateInfos: Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateInfo[], maxTime: Optional<string>, onDateChanged: (date?: string) => void, popoverPlacement?: "bottomLeft" | "topRight") {
    const localization =
        useLocalization(
            "common.hooks.useEntityExcessivePermissionCustomEvaluationMenuItems",
            () => ({
                menu: {
                    dateInfo: {
                        custom: "Custom start time...",
                        [Contract.TypeNames.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType]: {
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.AwsEc2InstanceLaunchDate]: "Last {{date | TimeFormatter.duration}} (since launch time)",
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.ConfigurationDate]: "Last {{date | TimeFormatter.duration}}",
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.CreationDate]: "Last {{date | TimeFormatter.duration}} (since creation)",
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.EventStartDate]: "Last {{date | TimeFormatter.duration}} (max activity time)"
                        }
                    }
                }
            }));

    return useMemo(
        () =>
            _(dateInfos).
                map(
                    dateInfo =>
                        new ActionMenuItem(
                            () => onDateChanged(dateInfo.date),
                            <Typography
                                sx={{
                                    fontWeight:
                                        dateInfo.date === date ||
                                        dateInfo.type === Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.ConfigurationDate &&
                                        _.isNil(date)
                                            ? 600
                                            : undefined
                                }}>
                                {localization.menu.dateInfo[Contract.TypeNames.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType][dateInfo.type]({ date: dateInfo.date })}
                            </Typography>)).
                as<MenuItem>().
                concat(
                    new ContentMenuItem(
                        onClose =>
                            <DatePicker
                                date={date}
                                dateInfos={dateInfos}
                                maxTime={maxTime}
                                onDateChanged={
                                    date => {
                                        onDateChanged(date);
                                        onClose();
                                    }}/>,
                        <Typography
                            sx={{
                                fontWeight:
                                    !_.isNil(date) &&
                                    !_(dateInfos).
                                        map(dateInfo => dateInfo.date).
                                        includes(date)
                                        ? 600
                                        : undefined
                            }}>
                            {localization.menu.dateInfo.custom()}
                        </Typography>,
                        { placement: popoverPlacement })).
                value(),
        [dateInfos]);
}

type DatePickerProps = {
    date?: string;
    dateInfos: Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateInfo[];
    maxTime?: string;
    onDateChanged: (date?: string) => void;
};

function DatePicker({ date, dateInfos, maxTime, onDateChanged }: DatePickerProps) {
    const localization =
        useLocalization(
            "common.hooks.useEntityExcessivePermissionCustomEvaluationMenuItems.datePicker",
            () => ({
                actions: {
                    select: "Select"
                }
            }));

    const [customDate, setCustomDate] =
        useState(
            _.isNil(date)
                ? null
                : moment(date));
    const theme = useTheme();
    return (
        <Stack>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                dateLibInstance={moment}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    maxDate={moment(maxTime)}
                    minDate={
                        moment(
                            _.minBy(
                                dateInfos,
                                dateInfo => dateInfo.date)!.date)}
                    value={customDate}
                    onChange={date => setCustomDate(date!)}/>
            </LocalizationProvider>
            <Divider flexItem={true}/>
            <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ padding: theme.spacing(1, 1) }}>
                <Button
                    disabled={_.isNil(customDate)}
                    onClick={
                        () => {
                            onDateChanged(
                                customDate?.
                                    utc(false).
                                    startOf("day").
                                    toISOString());
                        }}>
                    {localization.actions.select()}
                </Button>
            </Stack>
        </Stack>);
}