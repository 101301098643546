import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsNeptuneClusterRiskContext } from "../contexts";

export function useAwsRdsNeptuneClusterDeleteProtectionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsNeptuneClusterDeleteProtectionDisabledRisk;
    const neptuneClusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsNeptuneClusterModel;
    const neptuneCluster = neptuneClusterModel.entity as Contract.AwsRdsNeptuneCluster;

    const getAwsRdsNeptuneClusterRiskContext = useGetAwsRdsNeptuneClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsNeptuneClusterDeleteProtectionDisabledRiskDefinition",
            () => ({
                description: "{{neptuneCluster}} delete protection is not enabled",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Additional configuration** section, click **Show more** and select the **Turn on deletion protection** checkbox",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            neptuneCluster:
                <Entity
                    entityIdOrModel={neptuneClusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsNeptuneClusterUrl(neptuneCluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const neptuneClusterContext = getAwsRdsNeptuneClusterRiskContext(neptuneClusterModel);
            return [
                neptuneClusterContext.generalInformation,
                neptuneClusterContext.size,
                neptuneClusterContext.instances,
                neptuneClusterContext.sensitive,
                neptuneClusterContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}