import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsDocDbClusterSnapshotRiskContext } from "../../../contexts";

export function useAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskSnapshotDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsDocDbClusterSnapshotEncryptionDisabledRisk;
    const snapshotModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDocDbClusterSnapshotModel;
    const snapshot = snapshotModel.entity as Contract.AwsRdsDocDbClusterSnapshot;

    const getAwsRdsDocDbClusterSnapshotRiskContext = useGetAwsRdsDocDbClusterSnapshotRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskDefinition.hooks.useAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskSnapshotDefinition",
            () => ({
                contextItems: {
                    internalAccessLevel: "The DocumentDB cluster snapshot is not associated with a DocumentDB cluster"
                },
                description: "{{snapshot}} is not configured with KMS encryption",
                sections: {
                    resolution: {
                        step1: "Before encrypting the DocumentDB cluster snapshot, verify that identities using it are granted permissions to use the KMS key that will be used for encryption",
                        step2: "Click the **Actions** button on the top and **Copy snapshot**",
                        step3: "Check the **Enable encryption** checkbox and choose a **KMS Key**",
                        step4: "Click **Copy snapshot**",
                        step5: "Select the old, unencrypted DocumentDB cluster snapshots and click **Actions** on top and then **Delete**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                snapshot:
                    <Entity
                        entityIdOrModel={snapshotModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.DocDb,
                AwsConsoleUrlBuilder.getRdsDocDbClusterSnapshotUrl(snapshot)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const snapshotContextItems = getAwsRdsDocDbClusterSnapshotRiskContext(snapshotModel);
            return [
                snapshotContextItems.generalInformation,
                snapshotContextItems.type,
                snapshotContextItems.storageSize,
                snapshotContextItems.sensitive,
                snapshotContextItems.accessLevel,
                snapshotModel.accessLevel == Contract.AwsResourceAccessLevel.Internal
                    ? new RiskDefinitionContextItem(localization.contextItems.internalAccessLevel())
                    : undefined,
                snapshotContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}