﻿import { DataTableSort, UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, GroupedTableColumn, RiskType, RiskTypeGroups } from "../../../../../../../../../../common";
import { useCloudDefinition, useCodeDefinition } from "./hooks";

export function useDefinition(groupingType: RiskTypeGroups, riskType: RiskType): GroupedDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskType) {
                    case RiskType.Cloud:
                        return useCloudDefinition;
                    case RiskType.Code:
                        return useCodeDefinition;
                    default:
                        throw new UnexpectedError("groupingType", groupingType);
                }
            },
            [riskType]);

    return useDefinition(groupingType);
}

export interface GroupedDefinition {
    columns: GroupedTableColumn[];
    defaultSort?: DataTableSort;
    groupRequestType: Contract.RiskControllerGetRiskGroupsRequestType;
    riskGroupToId: (riskGroup: Contract.RiskControllerGetRiskGroupsResponseRiskGroup) => string;
}