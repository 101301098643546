import { AppError } from "@infrastructure";
import _ from "lodash";
import path from "path";
import { UserHelper } from ".";
import { UserView } from "../../views";
import { UrlHelper } from "./urlHelper";

export class UserConsoleAppUrlHelper {
    private static readonly urlPath = "/user";

    private static url: URL;

    public static initialize(url: URL) {
        UserConsoleAppUrlHelper.url = url;
        if (UserConsoleAppUrlHelper.url.pathname !== UserConsoleAppUrlHelper.urlPath) {
            throw new AppError("Console app url path mismatch");
        }
    }

    public static getApproverUserPermissionRequestsRelativeUrl() {
        return path.join(
            UserConsoleAppUrlHelper.urlPath,
            UserView.ApproverUserPermissionRequests);
    }

    public static getAuditEventsRelativeUrl() {
        return path.join(
            UserConsoleAppUrlHelper.urlPath,
            UserView.AuditEvents);
    }

    public static getCustomersRelativeUrl(): string {
        return path.join(
            UserConsoleAppUrlHelper.urlPath,
            UserView.Customers);
    }

    public static getGranteeUserPermissionRequestsRelativeUrl() {
        return path.join(
            UserConsoleAppUrlHelper.urlPath,
            UserView.GranteeUserPermissionRequests);
    }

    public static getPermissionEligibilitiesRelativeUrl() {
        return path.join(
            UserConsoleAppUrlHelper.urlPath,
            UserView.PermissionEligibilities);
    }


    public static getRelativeUrl(consoleAppRelativeUrl: string): string {
        return path.join(
            UserConsoleAppUrlHelper.urlPath,
            consoleAppRelativeUrl);
    }

    public static getRootRelativeUrl(): string {
        return UserConsoleAppUrlHelper.urlPath;
    }

    public static getSignInErrorRelativeUrl(): string {
        return path.join(
            UserConsoleAppUrlHelper.urlPath,
            UserView.SignInError);
    }

    public static getSignInRelativeUrl(redirectUrl?: string): string {
        if (UserHelper.tenable) {
            return UrlHelper.tenableSignInUrl;
        }

        const urlPath =
            path.join(
                UserConsoleAppUrlHelper.urlPath,
                UserView.SignIn);
        return _.isNil(redirectUrl) || redirectUrl === "/"
            ? urlPath
            : `${urlPath}?${UrlHelper.formatRedirectUrlQueryParameters(redirectUrl)}`;
    }

    public static isRelativeUrl(consoleAppRelativeUrl: string): boolean {
        return UrlHelper.isRelativeUrl(UserConsoleAppUrlHelper.url, consoleAppRelativeUrl) && (
            consoleAppRelativeUrl === "/" ||
            _(UserView).
                values().
                includes(UrlHelper.getPathRootSegment(consoleAppRelativeUrl)));
    }
}