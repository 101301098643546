import { ApiError, CheckboxField, Message, useExecuteOperation, useLocalization, ValuesField } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { AdministrationController, ApplicationHelper, Contract, useTheme } from "../../../../../../common";
import { ConsoleAppType } from "../../../../../../common/controllers/contract";
import { IrisWarning } from "../IrisWarning";
import { CognitoIdentityProvider, SamlIdentityProvider } from "./components";

const emptySamlIdentityProvider =
    new Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider(
        "",
        undefined,
        undefined,
        false,
        Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Other);

export function Authentication() {
    const [{ authentication: initialAuthentication, authorizationDomainNames: initialAuthorizationDomainNames }, executeGetAuthenticationConfiguration] =
        useExecuteOperation(
            Authentication,
            AdministrationController.getAuthenticationConfiguration);

    function validateDomainName(domainName: string): boolean {
        if (domainName.length === 0 || domainName.length > 255) {
            return false;
        }

        const validLabelRegex = /^[a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?$/;
        return domainName.
            split(".").
            every(label => validLabelRegex.test(label));
    }

    const [authentication, setAuthentication] = useState(initialAuthentication);
    const [authorizationDomainNames, setAuthorizationDomainNames] = useState(initialAuthorizationDomainNames);

    const [cognitoConfigurationOpen, setCognitoConfigurationOpen] = useState(false);

    const [customerSamlEnabled, setCustomerSamlEnabled] = useState(_.has(initialAuthentication.consoleAppTypeToSamlIdentityProviderMap, ConsoleAppType.Customer));
    const [customerSamlConfigurationOpen, setCustomerSamlConfigurationOpen] = useState(false);

    const [userSamlEnabled, setUserSamlEnabled] = useState(_.has(initialAuthentication.consoleAppTypeToSamlIdentityProviderMap, ConsoleAppType.User));
    const [userSamlConfigurationOpen, setUserSamlConfigurationOpen] = useState(false);

    const [updateAuthenticationConfigurationError, setUpdateAuthenticationConfigurationError] = useState<Contract.AdministrationControllerUpdateAuthenticationConfigurationError | true>();
    const [updateAuthenticationConfigurationExecuting, setUpdateAuthenticationConfigurationExecuting] = useState(false);
    const [updateAuthenticationConfigurationSuccess, setUpdateAuthenticationConfigurationSuccess] = useState(false);

    const samlConfigurations =
        useMemo(
            () =>
                ({
                    added:
                        (_.isNil(initialAuthentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.Customer]) && !_.isNil(authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.Customer])) ||
                        (_.isNil(initialAuthentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.User]) && !_.isNil(authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.User])),
                    removed:
                        (!customerSamlEnabled && !_.isNil(authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.Customer])) ||
                        (!userSamlEnabled && !_.isNil(authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.User]))
                }),
            [authentication, customerSamlEnabled, updateAuthenticationConfigurationSuccess, userSamlEnabled]);

    async function update() {
        setUpdateAuthenticationConfigurationError(undefined);
        setUpdateAuthenticationConfigurationExecuting(true);
        setUpdateAuthenticationConfigurationSuccess(false);

        const newAuthentication =
            {
                ...authentication,
                consoleAppTypeToSamlIdentityProviderMap:
                    _.omit(
                        authentication.consoleAppTypeToSamlIdentityProviderMap,
                        [
                            userSamlEnabled
                                ? ""
                                : ConsoleAppType.User,
                            customerSamlEnabled
                                ? ""
                                : ConsoleAppType.Customer
                        ])
            };
        try {
            await AdministrationController.updateAuthenticationConfiguration(
                new Contract.AdministrationControllerUpdateAuthenticationConfigurationRequest(
                    newAuthentication,
                    authorizationDomainNames));
            await executeGetAuthenticationConfiguration();
            setUpdateAuthenticationConfigurationSuccess(true);
            setAuthentication(newAuthentication);

        } catch (error) {
            setUpdateAuthenticationConfigurationError(
                error instanceof ApiError && error.statusCode === 400
                    ? error.error as Contract.AdministrationControllerUpdateAuthenticationConfigurationError
                    : true);
        }
        setUpdateAuthenticationConfigurationExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.administration.authentication",
            () => ({
                actions: {
                    update: {
                        error: {
                            common: "Failed to update SSO configuration",
                            [Contract.TypeNames.AdministrationControllerUpdateAuthenticationConfigurationError]: {
                                [Contract.AdministrationControllerUpdateAuthenticationConfigurationError.AuthenticationDomainNameExists]: "Authentication domain name already exists"
                            }
                        },
                        success: "Success",
                        text: "Update"
                    }
                },
                authenticationDomainNames: {
                    actions: {
                        delete: {
                            confirm: "Are you sure you want to delete the authentication domain {{authenticationDomainName}}?"
                        }
                    },
                    fields: {
                        error: {
                            exists: "Authentication domain name already exists",
                            required: "Authentication domain name cannot be empty",
                            valid: "Authentication domain name is not valid"
                        },
                        title: "Enter authentication domain name"
                    },
                    title: "Authentication Domains"
                },
                authorizationDomainNames: {
                    actions: {
                        delete: {
                            confirm: "Are you sure you want to delete the authorization domain {{authorizationDomainName}}?"
                        }
                    },
                    fields: {
                        error: {
                            exists: "Authorization domain name already exists",
                            required: "Authorization domain name cannot be empty",
                            valid: "Authorization domain name is not valid"
                        },
                        title: "Enter authorization domain name"
                    },
                    title: "Authorization Domains"
                },
                identityProviders: {
                    aad: "Microsoft Entra ID",
                    cognito: {
                        edit: "Edit",
                        title: "User/Password"
                    },
                    customer: "Console",
                    gci: "Google",
                    saml: {
                        customer: "Console SAML configuration",
                        edit: "Edit",
                        title: "SAML",
                        user: "JIT SAML configuration"
                    },
                    title: "Identity providers",
                    user: "JIT"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{ padding: theme.spacing(3, 2) }}>
            <IrisWarning/>
            <Stack
                spacing={2}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(2)
                }}>
                <Typography variant="h4">
                    {localization.identityProviders.title()}
                </Typography>
                <Stack
                    direction="row"
                    spacing={8}
                    sx={{ padding: theme.spacing(2) }}>
                    <Stack spacing={1}>
                        <Typography
                            variant="h4">
                            {localization.identityProviders.customer()}
                        </Typography>
                        <CheckboxField
                            checked={authentication.aadIdentityProviderEnabled}
                            sx={{ width: "100%" }}
                            onChange={() =>
                                setAuthentication({
                                    ...authentication,
                                    aadIdentityProviderEnabled: !authentication.aadIdentityProviderEnabled
                                })}>
                            <Typography
                                sx={{ fontWeight: 500 }}
                                variant="h4">
                                {localization.identityProviders.aad()}
                            </Typography>
                        </CheckboxField>
                        <CheckboxField
                            checked={authentication.gciIdentityProviderEnabled}
                            sx={{ width: "100%" }}
                            onChange={() =>
                                setAuthentication({
                                    ...authentication,
                                    gciIdentityProviderEnabled: !authentication.gciIdentityProviderEnabled
                                })}>
                            <Typography
                                sx={{ fontWeight: 500 }}
                                variant="h4">
                                {localization.identityProviders.gci()}
                            </Typography>
                        </CheckboxField>
                        {ApplicationHelper.cognitoEnabled &&
                            <Stack
                                direction="row"
                                spacing={4}>
                                <CheckboxField
                                    checked={authentication.cognitoIdentityProviderEnabled}
                                    sx={{ width: "100%" }}
                                    onChange={() =>
                                        setAuthentication({
                                            ...authentication,
                                            cognitoIdentityProviderEnabled: !authentication.cognitoIdentityProviderEnabled
                                        })}>
                                    <Typography
                                        sx={{ fontWeight: 500 }}
                                        variant="h4">
                                        {localization.identityProviders.cognito.title()}
                                    </Typography>
                                </CheckboxField>
                                <Button
                                    disabled={!authentication.cognitoIdentityProviderEnabled}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => setCognitoConfigurationOpen(true)}>
                                    {localization.identityProviders.cognito.edit()}
                                </Button>
                            </Stack>}
                        {cognitoConfigurationOpen &&
                            <CognitoIdentityProvider onClose={() => setCognitoConfigurationOpen(false)}/>}
                        <Stack
                            direction="row"
                            spacing={4}>
                            <CheckboxField
                                checked={customerSamlEnabled}
                                sx={{ width: "100%" }}
                                onChange={(_, checked) => {
                                    setCustomerSamlEnabled(!customerSamlEnabled);
                                    if (checked) {
                                        setCustomerSamlConfigurationOpen(true);
                                    }
                                }}>
                                <Typography
                                    sx={{ fontWeight: 500 }}
                                    variant="h4">
                                    {localization.identityProviders.saml.title()}
                                </Typography>
                            </CheckboxField>
                            <Button
                                disabled={!customerSamlEnabled || _.isNil(authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.Customer])}
                                size="small"
                                variant="outlined"
                                onClick={() => setCustomerSamlConfigurationOpen(true)}>
                                {localization.identityProviders.saml.edit()}
                            </Button>
                            {customerSamlConfigurationOpen &&
                                <SamlIdentityProvider
                                    configuration={authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.Customer] ?? emptySamlIdentityProvider}
                                    title={localization.identityProviders.saml.customer()}
                                    onClose={
                                        samlIdentityProvider => {
                                            setCustomerSamlConfigurationOpen(false);

                                            if (_.isNil(samlIdentityProvider) &&
                                                _.isNil(authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.Customer])) {
                                                setCustomerSamlEnabled(false);
                                            } else if (!_.isNil(samlIdentityProvider)) {
                                                setAuthentication({
                                                    ...authentication,
                                                    consoleAppTypeToSamlIdentityProviderMap: {
                                                        ...authentication.consoleAppTypeToSamlIdentityProviderMap,
                                                        [ConsoleAppType.Customer]: samlIdentityProvider
                                                    }
                                                });
                                            }
                                        }}/>}
                        </Stack>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography
                            variant="h4">
                            {localization.identityProviders.user()}
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={4}>
                            <CheckboxField
                                checked={userSamlEnabled}
                                sx={{ width: "100%" }}
                                onChange={(_, checked) => {
                                    setUserSamlEnabled(!userSamlEnabled);
                                    if (checked) {
                                        setUserSamlConfigurationOpen(true);
                                    }
                                }}>
                                <Typography
                                    sx={{ fontWeight: 500 }}
                                    variant="h4">
                                    {localization.identityProviders.saml.title()}
                                </Typography>
                            </CheckboxField>
                            <Button
                                disabled={!userSamlEnabled || _.isNil(authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.User])}
                                size="small"
                                variant="outlined"
                                onClick={() => setUserSamlConfigurationOpen(true)}>
                                {localization.identityProviders.saml.edit()}
                            </Button>
                            {userSamlConfigurationOpen &&
                                <SamlIdentityProvider
                                    configuration={authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.User] ?? emptySamlIdentityProvider}
                                    title={localization.identityProviders.saml.user()}
                                    onClose={
                                        samlIdentityProvider => {
                                            setUserSamlConfigurationOpen(false);

                                            if (_.isNil(samlIdentityProvider) &&
                                                _.isNil(authentication.consoleAppTypeToSamlIdentityProviderMap[ConsoleAppType.User])) {
                                                setUserSamlEnabled(false);
                                            } else if (!_.isNil(samlIdentityProvider)) {
                                                setAuthentication({
                                                    ...authentication,
                                                    consoleAppTypeToSamlIdentityProviderMap: {
                                                        ...authentication.consoleAppTypeToSamlIdentityProviderMap,
                                                        [ConsoleAppType.User]: samlIdentityProvider
                                                    }
                                                });
                                            }
                                        }}/>}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                spacing={8}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(2)
                }}>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.authenticationDomainNames.title()}
                    </Typography>
                    <Stack
                        alignItems="start"
                        spacing={3}>
                        <Box sx={{ width: theme.spacing(50) }}>
                            <ValuesField
                                disableConfirm={true}
                                placeholder={localization.authenticationDomainNames.fields.title()}
                                values={authentication.domainNames}
                                onValidate={
                                    authenticationDomainName => {
                                        if (_.includes(authentication.domainNames, authenticationDomainName)) {
                                            return localization.authenticationDomainNames.fields.error.exists();
                                        }
                                        if (_.isEmpty(authenticationDomainName)) {
                                            return localization.authenticationDomainNames.fields.error.required();
                                        }
                                        if (!validateDomainName(authenticationDomainName)) {
                                            return localization.authenticationDomainNames.fields.error.valid();
                                        }
                                        return undefined;
                                    }}
                                onValuesChanged={
                                    async domainNames => {
                                        setAuthentication({
                                            ...authentication,
                                            domainNames
                                        });
                                    }}/>
                        </Box>
                    </Stack>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.authorizationDomainNames.title()}
                    </Typography>
                    <Stack
                        alignItems="start"
                        spacing={3}>
                        <Box sx={{ width: theme.spacing(50) }}>
                            <ValuesField
                                disableConfirm={true}
                                placeholder={localization.authorizationDomainNames.fields.title()}
                                values={authorizationDomainNames}
                                onValidate={
                                    authorizationDomainName => {
                                        if (_.includes(authorizationDomainNames, authorizationDomainName)) {
                                            return localization.authorizationDomainNames.fields.error.exists();
                                        }
                                        if (_.isEmpty(authorizationDomainName)) {
                                            return localization.authorizationDomainNames.fields.error.required();
                                        }
                                        if (!validateDomainName(authorizationDomainName)) {
                                            return localization.authorizationDomainNames.fields.error.valid();
                                        }
                                        return undefined;
                                    }}
                                onValuesChanged={
                                    async authorizationDomainNames => {
                                        setAuthorizationDomainNames(authorizationDomainNames);
                                    }}/>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}>
                {updateAuthenticationConfigurationSuccess && (
                    <Message
                        level="success"
                        title={localization.actions.update.success()}/>
                )}
                {updateAuthenticationConfigurationExecuting && (
                    <CircularProgress
                        size={theme.spacing(2)}
                        variant="indeterminate"/>)}
                {updateAuthenticationConfigurationError && (
                    <Message
                        level="error"
                        title={
                            updateAuthenticationConfigurationError === true
                                ? localization.actions.update.error.common()
                                : localization.actions.update.error[Contract.TypeNames.AdministrationControllerUpdateAuthenticationConfigurationError][updateAuthenticationConfigurationError]()
                        }/>)}
                <Button
                    disabled={
                        _.isEqual(authentication, initialAuthentication) &&
                        _.isEqual(authorizationDomainNames, initialAuthorizationDomainNames) &&
                        !samlConfigurations.added &&
                        !samlConfigurations.removed}
                    onClick={update}>
                    {localization.actions.update.text()}
                </Button>
            </Stack>
        </Stack>);
}