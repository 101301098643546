import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlineKubernetesContainerNames, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetKubernetesResourceRiskContext } from "../contexts";

export function useKubernetesNamespaceWorkloadResourceContainerCapabilityRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesNamespaceWorkloadResourceContainerCapabilityRisk;
    const namespaceModel = entityModelStore.useGet(risk.entityId);
    const namespace = _.as<Contract.IKubernetesNamespace>(namespaceModel.entity);

    const getKubernetesResourceRiskContext = useGetKubernetesResourceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.resources.workloadResources.useKubernetesNamespaceWorkloadResourceContainerCapabilityRiskDefinition",
            () => ({
                contextItems: {
                    podSecurityPolicy: {
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.None]: "The {{translatedEntityTypeName}} doesn't have a Pod Security Standard policy configured, meaning that the provisioning of containers with capabilities assigned is not limited",
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.Privileged]: "The {{translatedEntityTypeName}} has a Privileged Pod Security Standard policy configured, meaning that the provisioning of containers with capabilities assigned is not limited"
                    },
                    workloadResources: {
                        [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType]: {
                            [Contract.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType.DefaultAndSensitiveCapabilities]: "The {{translatedEntityTypeName}} contains {{workloadResources}} running {{containerNames}} with {{defaultCapabilities}} and {{sensitiveCapabilities}}",
                            [Contract.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType.DefaultCapabilities]: "The {{translatedEntityTypeName}} contains {{workloadResources}} running {{containerNames}} with {{defaultCapabilities}}",
                            [Contract.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType.SensitiveCapabilities]: "The {{translatedEntityTypeName}} contains {{workloadResources}} running {{containerNames}} with {{sensitiveCapabilities}}"
                        }
                    }
                },
                defaultCapabilities: [
                    "1 default capability",
                    "{{count | NumberFormatter.humanize}} default capabilities"
                ],
                description: {
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType]: {
                        [Contract.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType.DefaultAndSensitiveCapabilities]: "{{kubernetesNamespace}} contains {{workloadResources}} running {{containerNames}} with {{defaultCapabilities}} and {{sensitiveCapabilities}}",
                        [Contract.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType.DefaultCapabilities]: "{{kubernetesNamespace}} contains {{workloadResources}} running {{containerNames}} with {{defaultCapabilities}}",
                        [Contract.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType.SensitiveCapabilities]: "{{kubernetesNamespace}} contains {{workloadResources}} running {{containerNames}} with {{sensitiveCapabilities}}"
                    }
                },
                resolutionSection: {
                    step1: "If the namespace contains applications which do not require any Linux capabilities to operate, add a **Restricted** Pod Security Standard policy to the {{kubernetesNamespace}}\nIf the namespace contains applications which only require default Linux capabilities to operate, add a **Baseline** Pod Security Standard policy to the {{kubernetesNamespace}}"
                },
                sensitiveCapabilities: [
                    "1 added capability",
                    "{{count | NumberFormatter.humanize}} added capabilities"
                ]
            }));

    const capabilitiesType = (riskModel as Contract.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModel).capabilitiesType;
    const getLocalizationParameters =
        () => ({
            containerNames: <InlineKubernetesContainerNames containerNames={risk.containerNames}/>,
            defaultCapabilities:
                <InlineItems
                    items={risk.defaultCapabilities}
                    namePluralizer={localization.defaultCapabilities}
                    variant="itemCountAndType"/>,
            kubernetesNamespace:
                <Entity
                    entityIdOrModel={namespaceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            sensitiveCapabilities:
                <InlineItems
                    items={risk.sensitiveCapabilities}
                    namePluralizer={localization.sensitiveCapabilities}
                    variant="itemCountAndType"/>,
            translatedEntityTypeName:
                entityTypeNameTranslator(
                    namespace.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    }),
            workloadResources:
                <InlineEntities
                    entityIdsOrModels={risk.workloadResourceIds}
                    entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                    variant="itemAndTypeOrItemCountAndType"/>
        });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description[Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType][capabilitiesType](getLocalizationParameters()),
        () => [
            localization.resolutionSection.step1({
                kubernetesNamespace:
                    <Entity
                        entityIdOrModel={namespaceModel}
                        variant="typeText"/>
            })
        ],
        riskModel,
        () => {
            const namespaceRiskContext = getKubernetesResourceRiskContext(namespaceModel);
            return [
                namespaceRiskContext.generalInformation,
                namespaceRiskContext.sensitive,
                namespaceRiskContext.relatedResources,
                new RiskDefinitionContextItem(localization.contextItems.workloadResources[Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType][capabilitiesType](getLocalizationParameters())),
                new RiskDefinitionContextItem(
                    localization.contextItems.podSecurityPolicy[risk.podSecurityStandard as Exclude<Contract.KubernetesNamespaceDataPodSecurityStandard, Contract.KubernetesNamespaceDataPodSecurityStandard.Baseline | Contract.KubernetesNamespaceDataPodSecurityStandard.Restricted>]({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                namespace.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                namespaceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}