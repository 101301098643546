﻿import { TextViewer } from "@infrastructure";
import { Stack, Tab, Tabs, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Tooltip } from "./Tooltip";

type TextSnippetProps = {
    height: string;
    items: TextSnippetItem[];
    wordWrap?: boolean;
};

export class TextSnippetItem {
    constructor(
        public text: string,
        public title: string,
        public description?: string) {
    }
}

export function TextSnippet({ height, items, wordWrap = true }: TextSnippetProps) {
    const [itemIndex, setItemIndex] = useState(0);
    const theme = useTheme();
    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.paper,
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                marginTop: theme.spacing(2)
            }}>
            {items.length > 1 &&
                <Tabs
                    indicatorColor="primary"
                    value={itemIndex}
                    variant="standard"
                    onChange={(_, value) => setItemIndex(value)}>
                    {_.map(
                        items,
                        (item, itemIndex) =>
                            <Tooltip
                                key={itemIndex}
                                titleOrGetTitle={item.description}>
                                <Tab
                                    label={item.title}
                                    value={itemIndex}/>
                            </Tooltip>)}
                </Tabs>}
            <TextViewer
                copyToClipboard={true}
                format="shell"
                height={height}
                options={{
                    folding: false,
                    glyphMargin: false,
                    lineDecorationsWidth: 10,
                    lineNumbers: "off",
                    lineNumbersMinChars: 0,
                    readOnly: true,
                    wordWrap:
                        wordWrap
                            ? "bounded"
                            : undefined
                }}
                sx={{ border: "none" }}
                text={items[itemIndex].text}/>
        </Stack>);
}