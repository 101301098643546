import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoCard, InfoItem, useEntityTypeNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { OciNetworkingNetworkLoadBalancerBackendsTable, OciNetworkingNetworkLoadBalancerListenersTable } from "../../../../../../../../../../tenants";
import { useOciNetworkingNetworkLoadBalancerStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements, useOciDefaultResourceInfoItemElements } from "../.";

export function useOciNetworkingNetworkLoadBalancerDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const networkLoadBalancerModel = resourceModel as Contract.OciNetworkingNetworkLoadBalancerModel;
    const networkLoadBalancer = networkLoadBalancerModel.entity as Contract.OciNetworkingNetworkLoadBalancer;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const networkLoadBalancerStatusTranslator = useOciNetworkingNetworkLoadBalancerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingNetworkLoadBalancerDefinition",
            () => ({
                info: {
                    items: {
                        cards: {
                            backends: "Backends",
                            listeners: "Listeners"
                        },
                        external: {
                            false: "Private",
                            title: "Visibility Type",
                            true: "Public"
                        },
                        ipAddresses: {
                            private: "Private IP",
                            public: "Public IP"
                        },
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={networkLoadBalancerStatusTranslator(networkLoadBalancer.status)}/>,
                    <InfoItem
                        key="external"
                        title={localization.info.items.external.title()}
                        value={
                            networkLoadBalancer.external
                                ? localization.info.items.external.true()
                                : localization.info.items.external.false()}/>,
                    <VerticalTopItemsInfoItem
                        items={networkLoadBalancer.publicIpAddresses}
                        key="publicIpAddress"
                        title={localization.info.items.ipAddresses.public()}/>,
                    <VerticalTopItemsInfoItem
                        items={networkLoadBalancer.privateIpAddresses}
                        key="privateIpAddress"
                        title={localization.info.items.ipAddresses.private()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkLoadBalancerModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkLoadBalancerModel.subnetIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingSubnet}
                        key="subnets"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingSubnet)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkLoadBalancerModel.securityGroupIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingNetworkSecurityGroup}
                        key="securityGroups"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                                { count: 0 })}/>
                ]}
                options={options?.infoOptions}>
                <InfoCard title={localization.info.items.cards.backends()}>
                    <OciNetworkingNetworkLoadBalancerBackendsTable
                        backendIpAddressToInstanceIdMap={networkLoadBalancerModel.backendIpAddressToInstanceIdMap}
                        backendSetNameToBackendsMap={networkLoadBalancer.backendSetNameToBackendsMap}/>
                </InfoCard>
                <InfoCard title={localization.info.items.cards.listeners()}>
                    <OciNetworkingNetworkLoadBalancerListenersTable
                        backendIpAddressToInstanceIdMap={networkLoadBalancerModel.backendIpAddressToInstanceIdMap}
                        backendSetNameToBackendsMap={networkLoadBalancer.backendSetNameToBackendsMap}
                        listeners={networkLoadBalancer.listeners}/>
                </InfoCard>
            </Info>
    });
}