import { Action0, ActionButton, CollapseIcon, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../common";
import { useItemContext } from "../../../../Profile";
import { ExpandIcon } from "./icons";

type TableHeaderProps = {
    onCollapseClick: Action0;
};

export function TableHeader({ onCollapseClick }: TableHeaderProps) {
    const { expandedSectionIds } = useItemContext();
    const localization =
        useLocalization(
            "views.customer.compliance.profile.item.tableHeader",
            () => ({
                action: {
                    collapseAll: "Collapse",
                    expandAll: "Expand"
                },
                columns: {
                    name: "Name",
                    score: "Score"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                borderBottom: theme.border.primary,
                justifyContent: "space-between",
                padding: theme.spacing(1, 1, 1, 2)
            }}>
            <Typography sx={{ fontWeight: 700 }}>
                {localization.columns.name()}
            </Typography>
            <Stack
                alignItems="center"
                direction="row">
                <Typography
                    sx={{
                        fontWeight: 700,
                        marginRight: theme.spacing(13)
                    }}>
                    {localization.columns.score()}
                </Typography>
                <ActionButton
                    size="small"
                    sx={{ fontSize: "18px" }}
                    tooltip={
                        _.size(expandedSectionIds) === 0
                            ? localization.action.expandAll()
                            : localization.action.collapseAll()}
                    onClick={() => onCollapseClick()}>
                    {_.size(expandedSectionIds) === 0
                        ? <ExpandIcon/>
                        : <CollapseIcon/>}
                </ActionButton>
            </Stack>
        </Stack>);
}