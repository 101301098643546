import { InlineItems, Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlinePermissionActions } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useResourceSecretExistsRiskContext } from "../../../../useResourceSecretExistsRiskContext";
import { EntityExternalConsoleLink } from "../../../components";
import { useGetGcpCloudRunServiceRiskContext } from "../../contexts";
import { RevisionsSection } from "./components";

export function useGcpCloudRunServiceEnvironmentVariableSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const cloudRunServiceEnvironmentVariableSecretExistsRiskModel = riskModel as Contract.GcpCloudRunServiceEnvironmentVariableSecretExistsRiskModel;
    const risk = riskModel.risk as Contract.GcpCloudRunServiceEnvironmentVariableSecretExistsRisk;
    const serviceModel = entityModelStore.useGet(cloudRunServiceEnvironmentVariableSecretExistsRiskModel.risk.entityId) as Contract.GcpCloudRunServiceModel;

    const getGcpCloudRunServiceRiskContext = useGetGcpCloudRunServiceRiskContext();
    const secretContextItems = useResourceSecretExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpCloudRunServiceEnvironmentVariableSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    nonPublicReadPermissionActions: "{{principalIds}} were granted {{nonPublicReadPermissionActions}}, allowing them to read your secrets",
                    nonPublicReadPermissionActionsExternalPrincipals: "{{principalIds}} including {{partialPrincipalIds}} were granted {{nonPublicReadPermissionActions}}, allowing them to read your secrets",
                    publicReadPermissionActions: "All users / All authenticated users were granted {{publicReadPermissionActions}}, allowing them to read your secrets"
                },
                description: {
                    environmentVariables: [
                        "1 environment variable",
                        "{{count | NumberFormatter.humanize}} environment variables"
                    ],
                    revisions: [
                        "1 revision",
                        "{{count | NumberFormatter.humanize}} revisions"
                    ],
                    text: "{{service}} is exposing secrets using {{environmentVariableNames}} in {{revisions}}"
                },
                sections: {
                    resolution: {
                        step1: {
                            link: "Secrets Manager",
                            text: "For each revision exposing secrets, migrate your secrets to {{secretManagerConsoleLink}} and deploy a new secured revision."
                        },
                        step2: "Delete the unsecured revision"
                    },
                    revisions: "Revisions"
                }
            }));
    const partialPrincipalIds =
        _(risk.principalIdToExternalMap).
            pickBy(external => external).
            keys().
            value();

    const createPrincipalIdsProps =
        () => ({
            partialPrincipalIds:
                <InlineEntities
                    entityIdsOrModels={partialPrincipalIds}
                    entityTypeName={Contract.TypeNames.IGciPartialPrincipal}
                    variant="itemCountAndType"/>,
            principalIds:
                <InlineEntities
                    entityIdsOrModels={_.keys(risk.principalIdToExternalMap)}
                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                    variant="itemCountAndType"/>
        });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            environmentVariableNames:
                <InlineItems
                    items={cloudRunServiceEnvironmentVariableSecretExistsRiskModel.secretEnvironmentVariableNames}
                    namePluralizer={localization.description.environmentVariables}
                    variant="itemCountAndType"/>,
            revisions:
                <InlineEntities
                    entityIdsOrModels={_.keys(cloudRunServiceEnvironmentVariableSecretExistsRiskModel.risk.revisionIdToSecretEnvironmentVariableNamesMap)}
                    entityTypeName={Contract.TypeNames.GcpCloudRunServiceRevision}
                    namePluralizer={localization.description.revisions}
                    variant="itemCountAndType"/>,
            service:
                <Entity
                    entityIdOrModel={serviceModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={cloudRunServiceEnvironmentVariableSecretExistsRiskModel.risk.entityId}
                key={cloudRunServiceEnvironmentVariableSecretExistsRiskModel.risk.entityId}
                page={Contract.GcpConsolePage.Revisions}/>,
            localization.sections.resolution.step1.text({
                secretManagerConsoleLink:
                    <Link
                        urlOrGetUrl={cloudRunServiceEnvironmentVariableSecretExistsRiskModel.secretManagerConsoleUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2()
        ],
        cloudRunServiceEnvironmentVariableSecretExistsRiskModel,
        () => {
            const serviceContextItems = getGcpCloudRunServiceRiskContext(serviceModel);
            return [
                serviceContextItems.generalInformation,
                serviceContextItems.sensitive,
                secretContextItems.getSecretExistsContextItem(
                    risk.entityId,
                    Contract.TypeNames.GcpCloudRunService,
                    cloudRunServiceEnvironmentVariableSecretExistsRiskModel.secretEnvironmentVariableNames),
                secretContextItems.getExcludedSecretsContextItem(
                    risk.excludedSecretEnvironmentVariableNames,
                    risk.typeName,
                    risk.tenantId),
                _.isEmpty(risk.publicReadPermissionActions)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.publicReadPermissionActions({
                            publicReadPermissionActions:
                                <InlinePermissionActions
                                    permissionActions={risk.publicReadPermissionActions}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(risk.nonPublicReadPermissionActions)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        _.isEmpty(partialPrincipalIds)
                            ? localization.contextItems.nonPublicReadPermissionActions({
                                ...createPrincipalIdsProps(),
                                nonPublicReadPermissionActions:
                                    <InlinePermissionActions
                                        permissionActions={risk.nonPublicReadPermissionActions}
                                        variant="itemCountAndType"/>
                            })
                            : localization.contextItems.nonPublicReadPermissionActionsExternalPrincipals({
                                ...createPrincipalIdsProps(),
                                nonPublicReadPermissionActions:
                                    <InlinePermissionActions
                                        permissionActions={risk.nonPublicReadPermissionActions}
                                        variant="itemCountAndType"/>
                            })),
                serviceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <RevisionsSection risk={cloudRunServiceEnvironmentVariableSecretExistsRiskModel.risk}/>,
                    localization.sections.revisions())
            ]
        });
}