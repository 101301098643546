import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonExcessivePermissionResourceRiskDefinition } from ".";
import { Contract, entityModelStore } from "../../../../../../../../../../../../common";
import { RiskDefinitionSection, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { PolicySection, S3BucketAccessControlListSection, S3PublicAccessPolicySection } from "../../components";
import { useGetAwsS3BucketRiskContext } from "../contexts";

export function useAwsExcessivePermissionBucketRiskDefinition(riskModel: Contract.RiskModel) {
    const excessivePermissionBucketRiskModel = riskModel as Contract.AwsExcessivePermissionBucketRiskModel;
    const bucketModel = entityModelStore.useGet(excessivePermissionBucketRiskModel.risk.entityId) as Contract.AwsS3BucketModel;
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;
    const tenantEntityPublicAccessPolicy = (entityModelStore.useGet(bucket.tenantId).entity as Contract.AwsTenantEntity).s3!.publicAccessPolicy;

    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionBucketRiskDefinition",
            () => ({
                sections: {
                    accessControlList: "ACL",
                    policyDocument: "Policy",
                    publicAccessPolicy: "Block Public Access"
                }
            }));
    return useAwsCommonExcessivePermissionResourceRiskDefinition(
        () => {
            const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.storageSize,
                bucketRiskContext.encryption,
                bucketRiskContext.sensitive
            ];
        },
        riskModel,
        [
            new RiskDefinitionSectionGroup(
                [
                    new RiskDefinitionSection(
                        <PolicySection policyDocument={bucket.policyDocument}/>,
                        localization.sections.policyDocument(),
                        {
                            expandable: true
                        }),
                    new RiskDefinitionSection(
                        <S3BucketAccessControlListSection accessControlList={bucket.accessControlList}/>,
                        localization.sections.accessControlList()),
                    new RiskDefinitionSection(
                        <S3PublicAccessPolicySection
                            bucketPublicAccessPolicy={bucket.publicAccessPolicy}
                            tenantPublicAccessPolicy={tenantEntityPublicAccessPolicy}/>,
                        localization.sections.publicAccessPolicy())
                ])
        ]);
}