import { DataTable, DataTableColumn, EmptyMessageText, optionalTableCell, TimeFormatter, TimeSpanFormatter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, EntitiesCell, InfoCard } from "../../../../../../../../../../../../common";

type LogsInfoCardProps = {
    logBucketNameToIdReferenceMap: Dictionary<string>;
    logResourceOcidToIdReferenceMap: Dictionary<string>;
    logs: Contract.OciLoggingLog[];
};

export function LogsInfoCard({ logBucketNameToIdReferenceMap, logResourceOcidToIdReferenceMap, logs }: LogsInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciLoggingLogGroupDefinition.logsInfoCard",
            () => ({
                columns: {
                    category: "Log Category",
                    creationTime: "Creation Time",
                    logType: {
                        title: "Log Type",
                        [Contract.TypeNames.OciLoggingLogType]: {
                            [Contract.OciLoggingLogType.Custom]: "Custom",
                            [Contract.OciLoggingLogType.Service]: "Service"
                        }
                    },
                    name: "Name",
                    retentionDuration: "Retention Period",
                    sourceResource: "Resource",
                    sourceService: "Service",
                    status: {
                        title: "Status",
                        [Contract.TypeNames.OciLoggingLogStatus]: {
                            [Contract.OciLoggingLogStatus.Active]: "Active",
                            [Contract.OciLoggingLogStatus.Creating]: "Creating",
                            [Contract.OciLoggingLogStatus.Deleting]: "Deleting",
                            [Contract.OciLoggingLogStatus.Failed]: "Failed",
                            [Contract.OciLoggingLogStatus.Inactive]: "Inactive",
                            [Contract.OciLoggingLogStatus.Updating]: "Updating"
                        }
                    },
                    updateTime: "Update Time"
                },
                empty: "No Logs",
                title: "Logs"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => logs}
                getItemId={(log: Contract.OciLoggingLog) => _.indexOf(logs, log).
                    toString()}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={LogColumnId.Name}
                    itemProperty={(log: Contract.OciLoggingLog) => log.name}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={LogColumnId.CreationTime}
                    itemProperty={(log: Contract.OciLoggingLog) => TimeFormatter.profileFormatDateTime(log.creationTime)}
                    title={localization.columns.creationTime()}/>
                <DataTableColumn
                    id={LogColumnId.LogType}
                    itemProperty={
                        (log: Contract.OciLoggingLog) =>
                            localization.columns.logType[Contract.TypeNames.OciLoggingLogType][log.type]()}
                    title={localization.columns.logType.title()}/>
                <DataTableColumn
                    id={LogColumnId.Status}
                    itemProperty={
                        (log: Contract.OciLoggingLog) =>
                            localization.columns.status[Contract.TypeNames.OciLoggingLogStatus][log.status]()}
                    title={localization.columns.status.title()}/>
                <DataTableColumn
                    id={LogColumnId.SourceService}
                    render={
                        optionalTableCell<Contract.OciLoggingLog>(
                            log =>
                                _.isNil(log.sourceServiceConfiguration?.serviceName)
                                    ? undefined
                                    : log.sourceServiceConfiguration!.serviceName)}
                    title={localization.columns.sourceService()}/>
                <DataTableColumn
                    id={LogColumnId.SourceResource}
                    render={
                        optionalTableCell<Contract.OciLoggingLog>(
                            log =>
                                _.isNil(log.sourceServiceConfiguration)
                                    ? undefined
                                    : _.isNil(log.sourceServiceConfiguration.bucketName)
                                        ? <EntitiesCell
                                            entityIdsOrModels={logResourceOcidToIdReferenceMap[log.sourceServiceConfiguration.resourceOcid!]}
                                            entityTypeName={Contract.TypeNames.OciResource}
                                            entityVariant="iconText"/>
                                        : <EntitiesCell
                                            entityIdsOrModels={logBucketNameToIdReferenceMap[log.sourceServiceConfiguration.bucketName]}
                                            entityTypeName={Contract.TypeNames.OciObjectStorageBucket}
                                            entityVariant="iconText"/>)}
                    title={localization.columns.sourceResource()}/>
                <DataTableColumn
                    id={LogColumnId.Category}
                    render={
                        optionalTableCell<Contract.OciLoggingLog>(
                            log =>
                                _.isNil(log.sourceServiceConfiguration?.category)
                                    ? undefined
                                    : log.sourceServiceConfiguration!.category)}
                    title={localization.columns.category()}/>
                <DataTableColumn
                    id={LogColumnId.RetentionDuration}
                    itemProperty={(log: Contract.OciLoggingLog) => TimeSpanFormatter.humanizeDays(log.retentionDuration)}
                    title={localization.columns.retentionDuration()}/>
                <DataTableColumn
                    id={LogColumnId.UpdateTime}
                    itemProperty={(log: Contract.OciLoggingLog) => TimeFormatter.profileFormatDateTime(log.lastModified)}
                    title={localization.columns.updateTime()}/>
            </DataTable>
        </InfoCard>);
}

enum LogColumnId {
    Category = "category",
    CreationTime = "creationTime",
    LogType = "logType",
    Name = "name",
    RetentionDuration = "retentionDuration",
    SourceResource = "sourceResource",
    SourceService = "sourceService",
    Status = "status",
    UpdateTime = "updateTime"
}