import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableSort, DataTableSortDirection, EmptyMessageText, map, Optional, optionalTableCell, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { GciTenantsContext, useSetGciTenantsContext } from "../..";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { useGciTenantModelStatusTranslator } from "../../hooks";
import { ActionsCell, StatusCell } from "./components";

export function Table() {
    const tenantModels = tenantModelStore.useGetPermittedGciTenants();
    const setGciTenantsContext = useSetGciTenantsContext();

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [tenantModels]);

    const tenantModelStatusTranslator = useGciTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.gciTenants.table",
            () => ({
                actions: {
                    add: "Add Google Workspace"
                },
                columns: {
                    name: "Name",
                    primaryDomainName: "Primary Domain Name",
                    serviceAccountMail: "Service Account Mail",
                    status: "Status",
                    userMail: "User Mail"
                },
                empty: "No Google workspaces"
            }));

    function getTenantModels(_filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _.orderBy(
            tenantModels,
            tenantModel =>
                map<string, number | string | undefined>(
                    sort?.columnId ?? TableColumnId.Name,
                    {
                        [TableColumnId.Name]: () => StringHelper.getSortValue(tenantModel.configuration.name),
                        [TableColumnId.PrimaryDomainName]: () => StringHelper.getSortValue((tenantModel.configuration as Contract.GciTenantConfiguration).primaryDomainName),
                        [TableColumnId.ServiceAccountMail]: () => StringHelper.getSortValue((tenantModel.configuration as Contract.GciTenantConfiguration).serviceAccountMail),
                        [TableColumnId.Status]: () => StringHelper.getSortValue(tenantModelStatusTranslator(tenantModel.status)),
                        [TableColumnId.UserMail]: () => StringHelper.getSortValue((tenantModel.configuration as Contract.GciTenantConfiguration).userMail)
                    }),
            sort?.direction === DataTableSortDirection.Descending
                ? "desc"
                : "asc");
    }

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={getTenantModels}
            getItemId={(item: Contract.GciTenantModel) => item.configuration.id}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setGciTenantsContext(new GciTenantsContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.GciTenantModel) => item.configuration.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.ServiceAccountMail}
                itemProperty={(item: Contract.GciTenantModel) => (item.configuration as Contract.GciTenantConfiguration).serviceAccountMail}
                title={localization.columns.serviceAccountMail()}/>
            <DataTableColumn
                id={TableColumnId.UserMail}
                render={optionalTableCell<Contract.GciTenantModel>(item => (item.configuration as Contract.GciTenantConfiguration).userMail)}
                title={localization.columns.userMail()}/>
            <DataTableColumn
                id={TableColumnId.PrimaryDomainName}
                itemProperty={(item: Contract.GciTenantModel) => (item.configuration as Contract.GciTenantConfiguration).primaryDomainName}
                title={localization.columns.primaryDomainName()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={StatusCell}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    PrimaryDomainName = "primaryDomainName",
    ServiceAccountMail = "serviceAccountMail",
    Status = "status",
    UserMail = "userMail"
}