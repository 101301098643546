import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useBuiltInEntityAttributeTypeNameTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useBuiltInEntityAttributeTypeNameTranslator",
            () => ({
                [Contract.TypeNames.EntityAttribute]: {
                    [Contract.TypeNames.AadDirectoryUserExternalAttribute]: "Microsoft Entra ID External",
                    [Contract.TypeNames.AadDirectoryUserGuestAttribute]: "Guest",
                    [Contract.TypeNames.AdministratorPrincipalAttribute]: "Admin",
                    [Contract.TypeNames.ApplicationPciDataResourceAttribute]: "PCI",
                    [Contract.TypeNames.ApplicationPciDataResourcePermissionActionPrincipalAttribute]: "PCI",
                    [Contract.TypeNames.ApplicationPhiDataResourceAttribute]: "PHI",
                    [Contract.TypeNames.ApplicationPhiDataResourcePermissionActionPrincipalAttribute]: "PHI",
                    [Contract.TypeNames.ApplicationPiiDataResourceAttribute]: "PII",
                    [Contract.TypeNames.ApplicationPiiDataResourcePermissionActionPrincipalAttribute]: "PII",
                    [Contract.TypeNames.ApplicationSecretsDataResourceAttribute]: "Secrets",
                    [Contract.TypeNames.ApplicationSecretsDataResourcePermissionActionPrincipalAttribute]: "Secrets",
                    [Contract.TypeNames.AwsAccessKeyEnabledUserAttribute]: "Access Keys",
                    [Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute]: "Trusted Service",
                    [Contract.TypeNames.BehaviorRiskIdentityAttribute]: "Anomaly",
                    [Contract.TypeNames.CodeResourceAttribute]: "IaC",
                    [Contract.TypeNames.CredentialsDisabledUserAttribute]: "No Credentials",
                    [Contract.TypeNames.DisabledIdentityAttribute]: "Disabled",
                    [Contract.TypeNames.GcpUnusedTenantEntityAttribute]: "Unused",
                    [Contract.TypeNames.GcpUserManagedKeyExistsServiceAccountAttribute]: "Access Keys",
                    [Contract.TypeNames.InactiveIdentityAttribute]: "Inactive",
                    [Contract.TypeNames.MfaDisabledUserAttribute]: "No MFA",
                    [Contract.TypeNames.PermissionManagementEntityAttribute]: "JIT",
                    [Contract.TypeNames.PublicEntityAttribute]: "Public",
                    [Contract.TypeNames.RelatedPublicComputeAttribute]: "Public Compute",
                    [Contract.TypeNames.RelatedWorkloadResourceVulnerabilityAttribute]: "Vulnerable Compute",
                    [Contract.TypeNames.SensitiveResourceAttribute]: "Sensitive",
                    [Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute]: "Sensitive",
                    [Contract.TypeNames.SevereDirectoryPermissionAttribute]: "Microsoft Entra ID Privileged",
                    [Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute]: "Excessive",
                    [Contract.TypeNames.SeverePermissionActionPrincipalAttribute]: "Privileged",
                    [Contract.TypeNames.VendorServiceIdentityAttribute]: "3rd Party",
                    [Contract.TypeNames.WorkloadResourceMaliciousFileAttribute]: "Malware",
                    [Contract.TypeNames.WorkloadResourceOperatingSystemEndOfLifeAttribute]: "OS End-of-Life",
                    [Contract.TypeNames.WorkloadResourceOperatingSystemUnpatchedAttribute]: "OS Unpatched",
                    [Contract.TypeNames.WorkloadResourceVulnerabilityAttribute]: "Vulnerable"
                }
            }));
    return (entityAttributeTypeName: string): string => localization[Contract.TypeNames.EntityAttribute].translate(entityAttributeTypeName);
}