import _ from "lodash";
import React from "react";
import { AccessController, Contract, EntitiesCell } from "../../../../../../../common";
import { usePermissionsContext } from "../Permissions";

type InlineOriginatorEntitiesProps = {
    entityId: string;
    getFilters: () => Contract.PermissionManagerRequestFilters;
    permissionsModel: Contract.PermissionsModel;
};

export function InlineOriginatorEntities({ entityId, getFilters, permissionsModel }: InlineOriginatorEntitiesProps) {
    const { entityType } = usePermissionsContext();
    return (
        <EntitiesCell
            deferredEntities={{
                count: permissionsModel.originatorEntityCount,
                firstItem: _.first(permissionsModel.originatorEntityTopIds),
                getItems:
                    async () => {
                        const { originatorEntityIds } =
                            await AccessController.getPermissionsModelOriginatorEntityIds(
                                new Contract.AccessControllerGetPermissionsModelOriginatorEntityIdsRequest(
                                    entityId,
                                    entityType,
                                    getFilters()));
                        return originatorEntityIds;
                    }
            }}
            entityTypeName={Contract.TypeNames.Entity}
            entityVariant="iconTextTypeTenant"/>);
}