import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { PermissionRequestCloseReasonAndStatusFilter } from "../../../../../../..";
import { Contract } from "../../../../../../../../../../common";
import { TableColumnId } from "../../../../Table";
import { TableDefinition } from "../../useDefinition";
import { useCommonColumns } from "../useCommonColumns";
import { ApprovedActionsCell, PendingActionsCell, StatusCell } from "./components";

export function useActiveDefinition(): TableDefinition {
    const commonColumns = useCommonColumns();
    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests.table.hooks.useDefinition.hooks.useActiveDefinition",
            () => ({
                columns: {
                    status: "Status"
                }
            }));

    return useMemo(
        () =>
            new TableDefinition(
                () =>
                    [
                        commonColumns.systemCreationTime,
                        commonColumns.granteeUserIdReference,
                        commonColumns.permissionEligibility,
                        commonColumns.permissionAssignmentRequestTenantIds,
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <PermissionRequestCloseReasonAndStatusFilter
                                            values={[
                                                Contract.PermissionRequestCloseReasonAndStatus.Active,
                                                Contract.PermissionRequestCloseReasonAndStatus.PendingActivation,
                                                Contract.PermissionRequestCloseReasonAndStatus.PendingApproval
                                            ]}/>
                                }
                            }}
                            id={TableColumnId.Status}
                            key={TableColumnId.Status}
                            render={StatusCell}
                            sortOptions={{ enabled: false }}
                            title={localization.columns.status()}/>,
                        commonColumns.startTime,
                        commonColumns.expirationTimeFrame,
                        commonColumns.reason,
                        <DataTableColumn
                            id={TableColumnId.Actions}
                            key={TableColumnId.Actions}
                            render={
                                optionalTableCell<Contract.PermissionRequestModel>(
                                    (item: Contract.PermissionRequestModel) =>
                                        item.permissionRequest.status === Contract.PermissionRequestStatus.PendingApproval
                                            ? <PendingActionsCell item={item}/>
                                            : <ApprovedActionsCell item={item}/>)}
                            selectorOptions={{ disabled: true }}/>
                    ],
                [Contract.PermissionRequestStatus.Active, Contract.PermissionRequestStatus.PendingActivation, Contract.PermissionRequestStatus.PendingApproval]),
        [commonColumns, localization]);
}