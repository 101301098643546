﻿import { useLocalization } from "@infrastructure";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";
import { useCommonKubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPolicyConfigurationDefinition } from "./useCommonKubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPolicyConfigurationDefinition";

export function useKubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfigurationDefinition(): RiskPolicyConfigurationDefinitionData {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useKubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfigurationDefinition",
            () => ({
                description: "Allowed repositories:"
            }));

    return useCommonKubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPolicyConfigurationDefinition(localization.description());
}