import { useLocalization, useSetRoute, WelcomeView } from "@infrastructure";
import { Button, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantIcon, useTheme } from "../../../../../../../common";

export function Welcome() {
    const localization =
        useLocalization(
            "views.customer.scopes.ciTenants.welcome",
            () => ({
                action: {
                    [Contract.CiTenantType.General]: "Add Container Registry"
                },
                title: "Connect your Container Registries"
            }));

    const setRoute = useSetRoute();
    const theme = useTheme();
    return (
        <WelcomeView title={localization.title()}>
            <Stack
                spacing={4}
                sx={{ width: "100%" }}>
                {_.map(
                    [
                        Contract.CiTenantType.General
                    ],
                    ciTenantType =>
                        <Button
                            startIcon={
                                <TenantIcon
                                    data={{ ciTenantType }}
                                    tenantType={Contract.TenantType.Ci}/>}
                            sx={{ padding: theme.spacing(1, 3) }}
                            variant="outlined"
                            onClick={() => setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCiIntegrationRelativeUrl(ciTenantType, true))}>
                            {localization.action.translate(ciTenantType)}
                        </Button>)}
            </Stack>
        </WelcomeView>);
}