import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization, useOperation } from "@infrastructure";
import _ from "lodash";
import React, { useCallback } from "react";
import { Contract, Entity, InlineResourceTags, PagedEntityFilter, Region, RegionDeferredFilter, WorkloadController } from "../../../../../common";
import { WorkloadAnalysisScanStatusCell } from "../components";
import { useGetWorkloadResourceScanValueFilterItemPage } from "./useGetValueFilterItemPage";
import { useGetWorkloadResourceScanEntityFilterItemPage } from "./useGetWorkloadResourceScanEntityFilterItemPage";
import { useVirtualMachineImageAccessLevelTranslator } from "./useVirtualMachineImageAccessLevelTranslator";
import { useVirtualMachineImageManagementTypeTranslator } from "./useVirtualMachineImageManagementTypeTranslator";
import { useWorkloadAnalysisScanStatusTranslator } from "./useWorkloadAnalysisScanStatusTranslator";
import { useAttributesDataTableColumn, useOperatingSystemDataTableColumn, useOperatingSystemTypeDataTableColumn, usePackageDisplayNameDataTableColumn, useResourceTagsDataTableColumn, useRiskSeverityDataTableColumn, useScanTimeDataTableColumn, useTenantIdDataTableColumn, useTimeRangeDataTableColumn, useVirtualMachinesDataTableColumn, useVulnerabilitiesDataTableColumn } from "./WorkloadScanDataTableColumns";

export function useCommonVirtualMachineImageDefinition() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useCommonVirtualMachineImageDefinition",
            () => ({
                columns: {
                    access: "Access",
                    attributes: "Labels",
                    creationDate: "Date Added",
                    management: "Management",
                    operatingSystem: "Operating System",
                    operatingSystemType: "Operating System Type",
                    packages: "Software",
                    regionId: "Region",
                    risks: "Findings",
                    risksSeverity: "Findings Severity",
                    scanStatus: "Scan Status",
                    scanTime: "Scan Time",
                    tags: "Tags",
                    tenantId: "Account",
                    virtualMachineIds: "Virtual Machines",
                    virtualMachineImageId: "ID",
                    virtualMachineImageName: "Name",
                    vulnerabilities: "Vulnerabilities",
                    vulnerabilitySeverityChart: "Vulnerability Severity"
                },
                empty: {
                    packages: {
                        withFilter: "No Matching Software",
                        withoutFilter: "No Software"
                    }
                }
            }));
    const virtualMachineImageManagementTypeTranslator = useVirtualMachineImageManagementTypeTranslator();
    const virtualMachineImageAccessLevelTranslator = useVirtualMachineImageAccessLevelTranslator();
    const workloadAnalysisScanStatusTranslator = useWorkloadAnalysisScanStatusTranslator();
    const [filtersPromise] =
        useOperation(
            useCommonVirtualMachineImageDefinition,
            useCallback(
                async () => {
                    const { filters } = await WorkloadController.getVirtualMachineImagesScanFilters(new Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest(undefined));
                    return filters;
                },
                []));
    return {
        access: <DataTableColumn
            filterOptions={{
                itemOrItems: {
                    element: <EnumValuesFilter
                        emptyValueOptions={{ enabled: true }}
                        enumType={Contract.VirtualMachineImageAccessLevel}
                        enumTypeTranslator={virtualMachineImageAccessLevelTranslator}
                        placeholder={localization.columns.access()}/>
                }
            }}
            id={Contract.WorkloadControllerRequestProperty.VirtualMachineImageAccessLevel}
            key={Contract.WorkloadControllerRequestProperty.VirtualMachineImageAccessLevel}
            render={optionalTableCell<Contract.WorkloadResourceVirtualMachineImageModel>(model => model.accessLevel && virtualMachineImageAccessLevelTranslator(model.accessLevel))}
            title={localization.columns.access()}/>,
        attributes: useAttributesDataTableColumn(
            filtersPromise,
            localization.columns),
        creationTime: useTimeRangeDataTableColumn(
            filtersPromise,
            (model: Contract.WorkloadResourceVirtualMachineImageModel) => model.creationTime,
            Contract.WorkloadControllerRequestProperty.CreationTime,
            filters => filters.creationTimeRange,
            localization.columns.creationDate),
        management: <DataTableColumn
            filterOptions={{
                itemOrItems: {
                    element: <EnumValuesFilter
                        emptyValueOptions={{ enabled: true }}
                        enumType={Contract.VirtualMachineImageManagementType}
                        enumTypeTranslator={virtualMachineImageManagementTypeTranslator}
                        placeholder={localization.columns.management()}/>
                }
            }}
            id={Contract.WorkloadControllerRequestProperty.VirtualMachineImageManagementType}
            key={Contract.WorkloadControllerRequestProperty.VirtualMachineImageManagementType}
            render={optionalTableCell<Contract.WorkloadResourceVirtualMachineImageModel>(model => model.managementType && virtualMachineImageManagementTypeTranslator(model.managementType))}
            title={localization.columns.management()}/>,
        operatingSystemDisplayName: useOperatingSystemDataTableColumn(
            localization.columns,
            Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage),
        operatingSystemType: useOperatingSystemTypeDataTableColumn(localization.columns),
        packageDisplayName: usePackageDisplayNameDataTableColumn(
            localization,
            Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage),
        regionId: <DataTableColumn
            filterOptions={{
                itemOrItems: {
                    element:
                        <RegionDeferredFilter
                            getRegionIds={filters => filters.regionIds}
                            promiseOrGetPromise={filtersPromise}
                            title={localization.columns.regionId}/>
                }
            }}
            id={Contract.WorkloadControllerRequestProperty.RegionId}
            key={Contract.WorkloadControllerRequestProperty.RegionId}
            render={optionalTableCell<Contract.WorkloadResourceVirtualMachineImageModel>(
                workloadResourceVirtualMachineImageModel =>
                    <Region
                        regionId={workloadResourceVirtualMachineImageModel.region}
                        variant="iconText"/>)}
            title={localization.columns.regionId()}/>,
        resourceTags: useResourceTagsDataTableColumn(
            localization.columns,
            Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage,
            optionalTableCell((item: Contract.WorkloadResourceVirtualMachineImageModel) =>
                !_.isEmpty(item.tags) &&
                <InlineResourceTags tags={item.tags}/>)),
        risksSeverity: useRiskSeverityDataTableColumn(localization.columns),
        scanTime: useScanTimeDataTableColumn(filtersPromise, localization.columns),
        tenantId: useTenantIdDataTableColumn(filtersPromise, localization.columns),
        virtualMachineImageId: <DataTableColumn
            filterOptions={{
                itemOrItems: {
                    element:
                        <PagedValuesFilter
                            getValuePage={
                                useGetWorkloadResourceScanValueFilterItemPage(
                                    Contract.WorkloadControllerRequestProperty.VirtualMachineImageRawId,
                                    Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage)}
                            placeholder={localization.columns.virtualMachineImageId()}/>
                }
            }}
            id={Contract.WorkloadControllerRequestProperty.VirtualMachineImageRawId}
            itemProperty={(workloadResourceVirtualMachineImageModel: Contract.WorkloadResourceVirtualMachineImageModel) => workloadResourceVirtualMachineImageModel.rawId}
            key={Contract.WorkloadControllerRequestProperty.VirtualMachineImageRawId}
            selectorOptions={{ disabled: true }}
            title={localization.columns.virtualMachineImageId()}/>,
        virtualMachineImageName: <DataTableColumn
            filterOptions={{
                itemOrItems: {
                    element:
                        <PagedEntityFilter
                            getEntityIdPage={
                                useGetWorkloadResourceScanEntityFilterItemPage(
                                    Contract.WorkloadControllerRequestProperty.VirtualMachineImageId,
                                    Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage)}
                            placeholder={localization.columns.virtualMachineImageName()}/>
                }
            }}
            id={Contract.WorkloadControllerRequestProperty.WorkloadResourceId}
            key={Contract.WorkloadControllerRequestProperty.WorkloadResourceId}
            render={
                ({ item: workloadResourceModel }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                    <Entity
                        entityIdOrModel={workloadResourceModel.id}
                        variant="iconText"/>}
            selectorOptions={{ disabled: true }}
            title={localization.columns.virtualMachineImageName()}/>,
        virtualMachines: useVirtualMachinesDataTableColumn(
            Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage,
            localization.columns),
        vulnerabilities: useVulnerabilitiesDataTableColumn(
            localization.columns,
            Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage),
        workloadAnalysisStatus:
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element: <EnumValuesFilter
                            enumType={Contract.WorkloadAnalysisStatus}
                            enumTypeTranslator={workloadAnalysisScanStatusTranslator}
                            placeholder={localization.columns.scanStatus()}/>
                    }
                }}
                id={Contract.WorkloadControllerRequestProperty.WorkloadAnalysisStatus}
                key={Contract.WorkloadControllerRequestProperty.WorkloadAnalysisStatus}
                render={({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceVirtualMachineImageModel>) =>
                    <WorkloadAnalysisScanStatusCell
                        resourceType={Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage}
                        workloadAnalysisError={item.workloadAnalysisError}
                        workloadAnalysisStatus={item.workloadAnalysisStatus}/>}
                title={localization.columns.scanStatus()}/>
    };
}