import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureKeyVaultSecretsRiskContext } from "../../..";
import { useGetAzureKeyVaultVaultRiskContext } from "..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureKeyVaultVaultSecretExpirationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultSecretExpirationDisabledRisk;
    const vaultModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultModel;

    const getAzureKeyVaultVaultRiskContext = useGetAzureKeyVaultVaultRiskContext();
    const getAzureKeyVaultSecretsRiskContext = useGetAzureKeyVaultSecretsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultSecretExpirationDisabledRiskDefinition",
            () => ({
                description: "{{vault}} has {{expirationDisabledSecretIds}} with no expiration date set",
                sections: {
                    resolution: {
                        step1: "Set an expiration date for all versions of the secret",
                        step2: {
                            step1: "Click on the secret and then select the version with the **Enabled** status",
                            step2: "Select the **Set expiration date** checkbox",
                            step3: "Enter an **Expiration date**",
                            step4: "Click **Apply**",
                            title: "Repeat the following steps for all **{{expirationDisabledSecretIds}}** and their versions"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            expirationDisabledSecretIds:
                <InlineEntities
                    entityIdsOrModels={risk.expirationDisabledSecretIds}
                    entityTypeName={Contract.TypeNames.AzureKeyVaultVaultSecret}
                    variant={"itemCountAndType"}/>,
            vault:
                <Entity
                    entityIdOrModel={vaultModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                <EntityExternalConsoleLink
                    entityId={risk.entityId}
                    key={risk.entityId}
                    page={Contract.AzureConsoleEntityPage.Secrets}/>,
                localization.sections.resolution.step1(),
                new Step(
                    localization.sections.resolution.step2.title({
                        expirationDisabledSecretIds:
                            <InlineEntities
                                entityIdsOrModels={risk.expirationDisabledSecretIds}
                                entityTypeName={Contract.TypeNames.AzureKeyVaultVaultSecret}
                                variant={"itemCountAndType"}/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step2.step1()}
                                {localization.sections.resolution.step2.step2()}
                                {localization.sections.resolution.step2.step3()}
                                {localization.sections.resolution.step2.step4()}
                            </Steps>
                    })
            ],
        riskModel,
        () => {
            const vaultRiskContext = getAzureKeyVaultVaultRiskContext(vaultModel);
            const secretsRiskContext = getAzureKeyVaultSecretsRiskContext(risk.expirationDisabledSecretIds);
            return [
                vaultRiskContext.generalInformation,
                vaultRiskContext.sensitive,
                vaultRiskContext.secrets,
                secretsRiskContext.sensitive,
                vaultRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}