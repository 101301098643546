import React from "react";
import { Contract, SourceToTargetMap } from "../../../common";
import { AwsLoadBalancerProtocolsHelper } from "../../aws";

type AwsElbLoadBalancerListenerProtocolsProps = {
    protocol: Contract.AwsElbProtocol;
    targetProtocol: Contract.AwsElbProtocol;
};

export function AwsElbLoadBalancerListenerProtocols({ protocol, targetProtocol }: AwsElbLoadBalancerListenerProtocolsProps) {
    return (
        <SourceToTargetMap
            source={protocol}
            target={targetProtocol}>
            {AwsLoadBalancerProtocolsHelper.getProtocolDisplayName}
        </SourceToTargetMap>);
}