import _ from "lodash";
import React, { useState } from "react";
import { Loading } from "@infrastructure";
import { Contract, ReportItem } from "../../../../../../../../../common";
import { Table as EventsTable } from "../../../../../../Events/components";

export function useGetEventsReportItem(filterMap: any) {
    const [reportFilterMap, setReportFilterMap] =
        useState(
            !_.isNil(filterMap)
                ? JSON.parse(filterMap)
                : {});

    return (): ReportItem => ({
        configuration:
            <Loading>
                <EventsTable
                    dataTableVariant="filtersOnly"
                    filtersOptions={{
                        initialMap: reportFilterMap,
                        onFiltersChanged: setReportFilterMap
                    }}/>
            </Loading>,
        generate:
            name =>
                new Contract.ReportControllerEventsReportRequestDefinition(
                    name,
                    undefined,
                    Contract.TypeNames.ReportControllerEventsReportRequestDefinition,
                    undefined,
                    _.isEmpty(reportFilterMap)
                        ? undefined
                        : JSON.stringify(reportFilterMap),
                    undefined)
    });
}