import { Dialog, Message, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Button, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { useRiskContext } from "../../../..";
import { Contract, RiskController, RiskResolutionAutomation, UserHelper, useTheme } from "../../../../../../../../../common";

type ResolveProps = {
    riskNotResolvedMessage?: string;
};

export function Resolve({ riskNotResolvedMessage }: ResolveProps) {
    const { reloadRiskModel, riskModel } = useRiskContext();
    const [{ changeModels }, executeGetRisksChangeModels] =
        useExecuteOperation(
            [Resolve, riskModel.id],
            async () =>
                await RiskController.getRiskChangeModels(
                    new Contract.RiskControllerGetRiskChangeModelsRequest(
                        [riskModel.id])));

    const [resolvableChangesResolved, riskClosed] =
        useMemo(
            () => {
                const resolvableChangesResolved =
                    _.some(
                        changeModels,
                        changeModel => changeModel.resolvable) &&
                    _(changeModels).
                        filter(changeModel => changeModel.resolvable).
                        every(resolvableChangeModel => resolvableChangeModel.resolved);
                const riskClosed =
                    riskModel.risk.resolutionReason === Contract.RiskResolutionReason.ManualResolution &&
                    riskModel.risk.status === Contract.RiskStatus.Closed;
                return [resolvableChangesResolved, riskClosed];
            },
            [changeModels]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.resolve",
            () => ({
                actions: {
                    resolve: {
                        resolvableChangesResolved: "Remediated successfully",
                        title: "Remediate"
                    }
                }
            }));

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    return (
        <Fragment>
            {open &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={() => setOpen(false)}>
                    <RiskResolutionAutomation
                        changeModels={changeModels}
                        riskModelMap={{ [riskModel.id]: riskModel }}
                        onFinished={
                            async (canceled: boolean) => {
                                if (!canceled) {
                                    await reloadRiskModel();
                                    await executeGetRisksChangeModels();
                                }

                                setOpen(false);
                            }}/>
                </Dialog>}
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}>
                {resolvableChangesResolved &&
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <Box sx={{ flex: 1 }}>
                            <Message
                                level="success"
                                title={localization.actions.resolve.resolvableChangesResolved()}/>
                        </Box>
                        {!riskClosed && riskNotResolvedMessage &&
                            <Message
                                level="warning"
                                title={riskNotResolvedMessage}
                                variant="minimal"/>}
                    </Stack>}
                {UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite) &&
                    <Button
                        disabled={riskModel.risk.status === Contract.RiskStatus.Closed}
                        sx={{
                            minWidth: "unset",
                            padding: theme.spacing(1, 2)
                        }}
                        onClick={() => setOpen(true)}>
                        {localization.actions.resolve.title()}
                    </Button>}
            </Stack>
        </Fragment>);
}