﻿import _ from "lodash";
import { RiskDefinitionContextItem } from "../../../utilities";
import { useResourceSecretExistsSteps } from "./useResourceSecretExistsSteps";

export function useResourceSecretExistsRiskContext() {
    const resourceSecretExistsSteps = useResourceSecretExistsSteps();
    return {
        getExcludedSecretsContextItem:
            (excludedSecrets: string[], riskTypeName: string, scopeId: string) => {
                const excludedSecretsStep =
                    resourceSecretExistsSteps.getExcludedSecretsStep(
                        excludedSecrets!,
                        riskTypeName,
                        scopeId);
                return !_.isNil(excludedSecretsStep)
                    ? new RiskDefinitionContextItem(excludedSecretsStep)
                    : undefined;
            },
        getSecretExistsContextItem:
            (entityIds: string | string[], entityTypeName: string, secrets: string[]) =>
                new RiskDefinitionContextItem(resourceSecretExistsSteps.getSecretExistsStep(entityIds, entityTypeName, secrets))
    };
}