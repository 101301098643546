﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureContainerServiceManagedClusterAgentPoolStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureContainerServiceManagedClusterAgentPoolStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureContainerServiceManagedClusterAgentPoolStatus]: {
                    [Contract.AzureContainerServiceManagedClusterAgentPoolStatus.Running]: "Running",
                    [Contract.AzureContainerServiceManagedClusterAgentPoolStatus.Stopped]: "Stopped"
                }
            }));
    return (status: Contract.AzureContainerServiceManagedClusterAgentPoolStatus) =>
        localization[Contract.TypeNames.AzureContainerServiceManagedClusterAgentPoolStatus][status]();
}