import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { GcpIamServiceAccountKeysTable } from "../../../../../../../../../../../../tenants";

type UserManagedKeysProps = {
    serviceAccount: Contract.GcpIamServiceAccount;
};

export function UserManagedKeys({ serviceAccount }: UserManagedKeysProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamServiceAccountDefinition.userManagedKeys",
            () => ({
                title: "User Managed Keys"
            }));
    return (
        <GcpIamServiceAccountKeysTable
            csvExportFilePrefixes={[
                entityTypeNameTranslator(
                    serviceAccount.typeName,
                    { includeServiceName: false }),
                serviceAccount.displayName,
                localization.title()]}
            serviceAccountKeyIds={serviceAccount.userManagedKeyIds}/>);
}