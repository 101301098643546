import { map, PrivateIcon, PublicIcon, Sx } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../common";
import { CrossTenantIcon } from "./icons";

type AwsResourceAccessLevelIconProps = SvgIconProps & {
    accessLevel: Contract.AwsResourceAccessLevel;
};

export function AwsResourceAccessLevelIcon({ accessLevel, ...props }: AwsResourceAccessLevelIconProps) {
    const theme = useTheme();
    const { sx, ...otherProps } = props;
    return map(
        accessLevel,
        {
            [Contract.AwsResourceAccessLevel.CrossTenant]: () =>
                <CrossTenantIcon
                    sx={Sx.combine(sx, { color: theme.palette.warning.main })}
                    {...otherProps}/>,
            [Contract.AwsResourceAccessLevel.Internal]: () =>
                <PrivateIcon
                    sx={Sx.combine(sx, { color: theme.palette.text.secondary })}
                    {...otherProps}/>,
            [Contract.AwsResourceAccessLevel.Public]: () =>
                <PublicIcon
                    sx={Sx.combine(sx, { color: theme.palette.error.main })}
                    {...otherProps}/>
        });
}