import { AnalyticsEventActionType, makeContextProvider, useLocalization, useTrackAnalytics, Wizard, WizardItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, RiskType } from "../../../..";
import { MultipleRisksItem, ServiceNowIncidentItem } from "./components";

export type UpsertServiceNowIncidentProps = {
    instanceId?: string;
    instanceModels: Contract.ScopeSystemEntityModel[];
    onClose: (successRiskIds?: string[]) => Promise<void>;
    riskIdToIncidentIdsMap: Dictionary<string[]>;
    riskType: RiskType;
};

export class UpsertServiceNowIncidentContext {
    constructor(
        public multipleRisksSelection?: boolean) {
    }
}

export const [useUpsertServiceNowIncidentContext, useSetUpsertServiceNowIncidentContext, useUpsertServiceNowIncidentContextProvider] = makeContextProvider<UpsertServiceNowIncidentContext>();

export function UpsertServiceNowIncident({ instanceId, instanceModels, onClose, riskIdToIncidentIdsMap, riskType }: UpsertServiceNowIncidentProps) {
    const [{ multipleRisksSelection }, , ContextProvider] = useUpsertServiceNowIncidentContextProvider(() => new UpsertServiceNowIncidentContext());
    const create =
        _.some(
            riskIdToIncidentIdsMap,
            incidentIds => _.isEmpty(incidentIds));
    const multipleRiskEnable = _.size(riskIdToIncidentIdsMap) > 1 && create;

    const trackAnalytics = useTrackAnalytics();
    const localization =
        useLocalization(
            "common.upsertServiceNowIncidentDialog.upsertServiceNowIncident",
            () => ({
                multipleRisks: "Create a unified ServiceNow incident",
                multipleRisksSelect: "Create ServiceNow incident/s for {{count | NumberFormatter.humanize}} findings",
                notMultipleRisks: {
                    create: [
                        "Create a ServiceNow Incident",
                        "Create {{count | NumberFormatter.humanize}} separate ServiceNow incidents"
                    ],
                    update: [
                        "Update ServiceNow Incident",
                        "Update {{count | NumberFormatter.humanize}} ServiceNow Incidents"
                    ]
                }
            }));

    return (
        <ContextProvider>
            <Wizard
                titleOptions={{ text: "" }}
                onFinished={() => { 
                    trackAnalytics(
                        AnalyticsEventActionType.UpsertTicketing,
                        {
                            "Create": create,
                            "Type": Contract.TicketingServiceType.ServiceNow
                        });
                    onClose();
                }}>
                {multipleRiskEnable && (
                    <WizardItem title={localization.multipleRisksSelect({ count: _.size(riskIdToIncidentIdsMap) })}>
                        <MultipleRisksItem/>
                    </WizardItem>)}
                <WizardItem
                    deferredLoading={true}
                    disablePadding={true}
                    title={
                        multipleRiskEnable && multipleRisksSelection
                            ? localization.multipleRisks()
                            : create
                                ? localization.notMultipleRisks.create(_.size(riskIdToIncidentIdsMap))
                                : localization.notMultipleRisks.update(
                                    _(riskIdToIncidentIdsMap).
                                        flatMap().
                                        size())
                    }>
                    <ServiceNowIncidentItem
                        instanceId={instanceId}
                        instanceModels={instanceModels}
                        riskIdToIncidentIdsMap={riskIdToIncidentIdsMap}
                        riskType={riskType}
                        onClose={onClose}/>
                </WizardItem>
            </Wizard>
        </ContextProvider>);
}