﻿import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import _ from "lodash";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { EntitySelector, EntitySelectorSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    createRequest: EditCreateRequest;
    entityTypeName: string;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.ResourceCodeResourceNotExistsRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

type EditCreateRequest = (
    allEntities: boolean,
    description: Optional<string>,
    name: string,
    entityBuiltInAttributeTypeNames: Optional<string[]>,
    entityCustomAttributeDefinitionIds: Optional<string[]>,
    entityIds: Optional<string[]>,
    entityTags: Optional<Contract.ResourceTag[]>,
    entityTypeNames: Optional<string[]>,
    scopeId: string,
    severity: Contract.Severity) => Contract.RiskControllerInsertResourceCodeResourceNotExistsRiskPolicyRequest;

export function Edit({ actionsRef, createRequest, entityTypeName, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const { scopeId } = useCustomRiskPolicyContext();
    const [resourcesValid, setResourcesValid] = useState(false);
    const { tenantTypes } = useCustomRiskPolicyContext();
    const [view, setView] = useState<EditView | undefined>(EditView.Resources);

    const [resourceSelectorSelection, setResourceSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.allResources,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.resourceIds,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceTags,
                undefined,
                riskPolicyConfiguration?.resourceTypeNames));

    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityTags, entityTypeNames } = resourceSelectorSelection!.getInlineSelectionData();

                    return createRequest(
                        allEntities ?? false,
                        description,
                        name,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityTags,
                        entityTypeNames,
                        scopeId,
                        severity);
                }
        });

    useEffect(
        () => {
            const resourcesValid = resourceSelectorSelection?.valid() ?? false;
            setResourcesValid(resourcesValid);

            onValidChange(resourcesValid);
        },
        [resourceSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.resourceCodeResourceNotExistsRiskPolicy.edit",
            () => ({
                prompt: "Select the required resources"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        resource:
                            <InlineEntitySelection
                                entityTypeName={entityTypeName}
                                selection={resourceSelectorSelection}/>
                    })}
                </Typography>
                <Box>
                    <Accordion
                        expanded={view === EditView.Resources}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Resources
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        entityTypeName,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {resourcesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={
                                    _<string>([]).
                                        concat([
                                            Contract.TypeNames.AdministratorPrincipalAttribute,
                                            Contract.TypeNames.BehaviorRiskIdentityAttribute,
                                            Contract.TypeNames.CredentialsDisabledUserAttribute,
                                            Contract.TypeNames.DisabledIdentityAttribute,
                                            Contract.TypeNames.InactiveIdentityAttribute,
                                            Contract.TypeNames.MfaDisabledUserAttribute,
                                            Contract.TypeNames.PublicEntityAttribute,
                                            Contract.TypeNames.RelatedPublicComputeAttribute,
                                            Contract.TypeNames.RelatedWorkloadResourceVulnerabilityAttribute,
                                            Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute,
                                            Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                            Contract.TypeNames.VendorServiceIdentityAttribute,
                                            Contract.TypeNames.WorkloadResourceMaliciousFileAttribute,
                                            Contract.TypeNames.WorkloadResourceOperatingSystemEndOfLifeAttribute,
                                            Contract.TypeNames.WorkloadResourceOperatingSystemUnpatchedAttribute,
                                            Contract.TypeNames.WorkloadResourceVulnerabilityAttribute
                                        ]).
                                        concatIf(
                                            _.includes(tenantTypes, Contract.TenantType.Aws),
                                            [
                                                Contract.TypeNames.AwsAccessKeyEnabledUserAttribute,
                                                Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute
                                            ]).
                                        value()}
                                entityTypeName={entityTypeName}
                                excludeCommonCloudProviderTenants={true}
                                properties={["all", "typeNames", "tags", "attributes"]}
                                scopeId={scopeId}
                                selection={resourceSelectorSelection}
                                onSelectionChanged={setResourceSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}

enum EditView {
    Resources = "resources"
}