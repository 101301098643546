import { DataTableColumn, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ItemSelectionHelper, RiskType } from "../../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../../useCommonCloudRiskPolicyTableDefinition";

export function useAwsIamGroupInactiveRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useAccessDefinition.hooks.aws.useAwsIamGroupInactiveRiskDefinition",
            () => ({
                columns: {
                    groupUserExists: {
                        false: "Yes",
                        title: "Empty Group",
                        true: "No"
                    }
                }
            }));
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();

    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsIamGroupInactiveRiskModel) => ({
                                [localization.columns.groupUserExists.title()]:
                                    item.risk.groupUserExists
                                        ? localization.columns.groupUserExists.true()
                                        : localization.columns.groupUserExists.false()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.groupUserExists.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.groupUserExists.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.groupUserExists.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={TableColumnId.AwsIamGroupInactiveRiskGroupUserExists}
                    itemProperty={
                        (item: Contract.AwsIamGroupInactiveRiskModel) =>
                            item.risk.groupUserExists
                                ? localization.columns.groupUserExists.true()
                                : localization.columns.groupUserExists.false()}
                    key={TableColumnId.AwsIamGroupInactiveRiskGroupUserExists}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.groupUserExists.title()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerAwsIamGroupInactiveRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[TableColumnId.AwsIamGroupInactiveRiskGroupUserExists])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}