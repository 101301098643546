import { ActionButton, CloseIcon } from "@infrastructure";
import { Stack } from "@mui/material";
import React from "react";
import { StorageHelper, useTheme } from "../../../../../common";
import { FullWidthIcon, PartialWidthIcon } from "../icons";
import { BackButton } from "./BackButton";

type SideViewActionsProps = {
    backButton: boolean;
    fullScreen: boolean;
    resetSideView: () => void;
    setFullScreen: (fullScreen: boolean) => void;
    setPointerOutside: (pointerOutside: boolean) => void;
};

export function SideViewActions({ backButton, fullScreen, resetSideView, setFullScreen, setPointerOutside }: SideViewActionsProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1.5}>
            <ActionButton
                size="small"
                sx={{
                    fontSize: "18px",
                    height: theme.spacing(2.25),
                    width: theme.spacing(2.25)
                }}
                variant="icon"
                onClick={resetSideView}>
                <CloseIcon sx={{ fontSize: "18px" }}/>
            </ActionButton>
            <ActionButton
                size="small"
                sx={{
                    fontSize: "18px",
                    height: theme.spacing(2.25),
                    width: theme.spacing(2.25)
                }}
                variant="icon"
                onClick={
                    () => {
                        StorageHelper.customerSideViewFullWidth.setValue(`${!fullScreen}`);
                        setFullScreen(!fullScreen);
                        setPointerOutside(fullScreen);
                    }}>
                {fullScreen
                    ? <PartialWidthIcon sx={{ fontSize: "18px" }}/>
                    : <FullWidthIcon sx={{ fontSize: "18px" }}/>}
            </ActionButton>
            {backButton && <BackButton/>}
        </Stack>);
}