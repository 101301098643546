import { AnalyticsEventActionType, defined, Dropdown, EmptyMessageText, EmptyValueIcon, getChildElements, Optional, SearchList, SearchListSelectors, SelectionView, SelectionViewType, useDeepDependency, useLocalization, useSetFiltersContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Children, Fragment, ReactNode, useCallback, useMemo } from "react";
import { FilterField, useFilterConnectorContext, useSetFilterConnectorContext } from ".";
import { ValuesFilterListItem } from "./ValuesFilterListItem";

export type EmptyValueOptions = {
    enabled?: boolean;
    title?: string;
    variant?: "iconText" | "text";
};
export type ValuesFilterProps = {
    children: ReactNode;
    emptyValueOptions?: EmptyValueOptions;
    groupItemTitle?: boolean;
    infoMessage?: string;
    onValuePageLoading?: (itemPage: any[]) => Promise<void>;
    placeholder: string;
    sorted?: boolean;
    spacing?: number;
};

const maximumValuesToShow = 5;
const sortSelectedThreshold = 30;

export function ValuesFilter({ children, emptyValueOptions, groupItemTitle = false, infoMessage, onValuePageLoading, placeholder, sorted = true, spacing = 0 }: ValuesFilterProps) {
    emptyValueOptions = {
        enabled: emptyValueOptions?.enabled ?? false,
        title: emptyValueOptions?.title,
        variant: emptyValueOptions?.variant ?? "text"
    };

    const itemElements =
        useMemo(
            () => getChildElements(Children.toArray(children), ValuesFilterItem),
            [children]);
    const itemElementsDeepDependency =
        useDeepDependency(
            _.map(
                itemElements,
                itemElement => itemElement.key));
    const items =
        useMemo(
            () => {
                let items =
                    _.map(
                        itemElements,
                        itemElement => ({
                            filterValues: [itemElement.props.value],
                            render: itemElement.props.children,
                            title: itemElement.props.title ?? itemElement.props.value,
                            value: itemElement.props.value
                        } as ValuesFilterValueItem));
                if (groupItemTitle) {
                    items =
                        _(items).
                            groupBy(item => item.title).
                            map(
                                (titleItems, title) => ({
                                    filterValues:
                                        _.flatMap(
                                            titleItems,
                                            titleItem => titleItem.filterValues),
                                    render: titleItems[0].render,
                                    title,
                                    value: title
                                } as ValuesFilterValueItem)).
                            value();
                }
                return items;
            },
            [itemElementsDeepDependency, groupItemTitle]);

    const emptyValueItem =
        useMemo(
            () => {
                if (emptyValueOptions?.enabled) {
                    return {
                        filterValues: [undefined],
                        render:
                            () =>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    {emptyValueOptions!.variant == "iconText" &&
                                        <EmptyValueIcon sx={{ fontSize: "24px" }}/>}
                                    <Typography>
                                        {emptyValueOptions?.title ?? localization.emptyValueTitle()}
                                    </Typography>
                                </Stack>,
                        title: undefined,
                        value: undefined
                    } as ValuesFilterValueItem;
                }
                return undefined;
            },
            [emptyValueOptions?.enabled]);

    const [allItemsFilterValueToValueMap, allItems, allItemsValues, allItemsValueToItemMap] =
        useMemo(
            () => {
                const allItems =
                    _<ValuesFilterValueItem>([]).
                        concatIf(
                            !_.isNil(emptyValueItem),
                            emptyValueItem!).
                        concat(items).
                        value();
                const allItemsFilterValueToValueMap =
                    _(allItems).
                        flatMap(
                            item =>
                                _.map(
                                    item.filterValues,
                                    filterValue => ({
                                        filterValue,
                                        value: item.value
                                    }))).
                        keyBy(({ filterValue }) => filterValue).
                        mapValues(({ value }) => value).
                        value();
                const allItemsValues =
                    _.map(
                        allItems,
                        item => item.value);
                const allItemsValueToItemMap =
                    _.keyBy(
                        allItems,
                        item => item.value) as Dictionary<ValuesFilterValueItem>;
                return [allItemsFilterValueToValueMap, allItems, allItemsValues, allItemsValueToItemMap];
            },
            [emptyValueItem, items]);
    const { filter, open } = useFilterConnectorContext() as { filter?: ValuesFilterSelection<any>; open?: boolean };
    const [selectedValues, selectionViewValues, selectionViewType] =
        useMemo(
            () => {
                if (_.isNil(filter)) {
                    return [];
                }

                const filterValues =
                    _(filter?.values ?? []).
                        map(filterValue => allItemsFilterValueToValueMap[filterValue]).
                        filter(filterValue => !_.isNil(filterValue)).
                        concatIf(
                            filter?.emptyValue,
                            undefined).
                        value();
                const selectedValues =
                    _(allItemsValues).
                        intersection(filterValues).
                        value();
                let selectionViewType: SelectionViewType,
                    selectionViewValues: Optional<string>[];

                if (allItemsValues.length <= maximumValuesToShow ||
                    selectedValues.length <= allItemsValues.length / 2) {
                    selectionViewType = "include";
                    selectionViewValues =
                        _(selectedValues).
                            map(value => allItemsValueToItemMap[value].title).
                            value();
                } else {
                    selectionViewType = "exclude";
                    selectionViewValues =
                        _(allItemsValues).
                            difference(filterValues).
                            map(value => allItemsValueToItemMap[value].title).
                            value();
                }

                return [selectedValues, selectionViewValues, selectionViewType];
            },
            [filter, allItemsFilterValueToValueMap, allItemsValueToItemMap, allItemsValues]);

    const setFilterConnectorContext = useSetFilterConnectorContext();
    const setFiltersContext = useSetFiltersContext();
    const setFilterValues =
        useCallback(
            (values?: string[]) => {
                if (_.isEmpty(values)) {
                    setFilterConnectorContext(
                        context => ({
                            ...context,
                            filter: undefined
                        }));
                } else {
                    setFilterConnectorContext(
                        context => ({
                            ...context,
                            filter:
                                new ValuesFilterSelection(
                                    _.includes(values, undefined),
                                    _(values).
                                        filter(value => !_.isNil(value)).
                                        flatMap(value => allItemsValueToItemMap[value].filterValues).
                                        value())
                        }));
                }
            },
            [allItemsValueToItemMap, setFilterConnectorContext]);

    const itemChecked =
        useCallback(
            (item: ValuesFilterValueItem) => _.includes(selectedValues, item.value),
            [selectedValues]);
    const onCheckboxClick =
        useCallback(
            (item: ValuesFilterValueItem) =>
                () => {
                    const checked = itemChecked(item);
                    const newSelectedValues =
                        !checked
                            ? _.concat(selectedValues ?? [], item.value)
                            : _.without(selectedValues, item.value);

                    setFilterValues(newSelectedValues);
                },
            [selectedValues, setFilterValues]);

    const onOnlyClick =
        useCallback(
            (item: ValuesFilterValueItem) => () => {
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        open: false
                    }));
                setFilterValues([item.value]);
            },
            [setFilterValues, setFilterConnectorContext]);

    const localization =
        useLocalization(
            "infrastructure.filters.valuesFilter",
            () => ({
                empty: "No results",
                emptyValueTitle: "(Blanks)",
                emptyValueView: "Blanks"
            }));

    return (
        <Dropdown
            analyticsOptions={{
                onClose: {
                    actionType: AnalyticsEventActionType.FilterValueClose,
                    propertyNameToValueMap: {
                        "Filter Name": placeholder,
                        "Filter Selected Value Count": selectedValues?.length ?? 0,
                        "Filter Type": "ValuesFilter"
                    }
                },
                onOpen: {
                    actionType: AnalyticsEventActionType.FilterValueOpen,
                    propertyNameToValueMap: {
                        "Filter Name": placeholder,
                        "Filter Type": "ValuesFilter"
                    }
                }
            }}
            infoMessage={infoMessage}
            open={open}
            popoverElement={
                <SearchList
                    dense={true}
                    dependencies={[selectedValues]}
                    emptyMessageText={new EmptyMessageText(localization.empty())}
                    headerElement={
                        <SearchListSelectors
                            analyticsOptions={{
                                toggleMatches: {
                                    actionType: AnalyticsEventActionType.FilterValueMatchesSet,
                                    propertyNameToValueMap: {
                                        "Filter Name": placeholder
                                    }
                                },
                                toggleValues: {
                                    actionType: AnalyticsEventActionType.FilterValueAllSet,
                                    propertyNameToValueMap: {
                                        "Filter Name": placeholder
                                    }
                                }
                            }}
                            getItemValue={(item: ValuesFilterValueItem) => item.value}
                            items={allItemsValues}
                            selectedItems={selectedValues}
                            onSelectedItemsChanged={selectedValues => setFilterValues(selectedValues)}/>}
                    itemOptions={{
                        getDependencies: () => [selectedValues],
                        getSearchValue: (item: ValuesFilterValueItem) => item.title ?? "",
                        getSortValue:
                            (item: ValuesFilterValueItem) =>
                                !_.isEmpty(selectedValues) && _.size(allItems) > sortSelectedThreshold
                                    ? [!itemChecked(item), item.title ?? ""]
                                    : item.title ?? "",
                        render:
                            (item: ValuesFilterValueItem) =>
                                <ValuesFilterListItem
                                    checked={itemChecked(item)}
                                    onCheckboxClick={onCheckboxClick(item)}
                                    onOnlyClick={onOnlyClick(item)}>
                                    {item.render?.(false) ?? item.title}
                                </ValuesFilterListItem>,
                        spacing
                    }}
                    items={allItems}
                    listOptions={{ sx: { padding: 0 } }}
                    sorted={sorted}
                    variant="dropdown"
                    onItemPageLoading={
                        _.isNil(onValuePageLoading)
                            ? undefined
                            : (items: ValuesFilterValueItem[]) =>
                                onValuePageLoading(
                                    _.flatMap(
                                        items,
                                        item => item.filterValues))}/>}
            popoverElementContainerSx={{ padding: 0 }}
            onClose={
                () => {
                    setFiltersContext(
                        context => ({
                            ...context,
                            activeFilter: undefined
                        }));
                    setFilterConnectorContext(
                        context => ({
                            ...context,
                            open: false
                        }));
                }}
            onOpen={
                () => {
                    setFilterConnectorContext(
                        context => ({
                            ...context,
                            open: true
                        }));
                }}>
            <FilterField
                emptyValue={_.isNil(selectedValues) || selectedValues.length === 0}
                focused={open}
                placeholder={placeholder}
                selection={
                    <SelectionView
                        empty={_.isNil(selectedValues) || selectedValues.length === 0}
                        emptyValueTitle={emptyValueOptions?.title}
                        label={placeholder}
                        selectedValues={selectionViewValues}
                        totalCount={allItemsValues.length}
                        type={selectionViewType}/>}/>
        </Dropdown>);
}

export type ValuesFilterItemProps = {
    children?: (text: boolean) => ReactNode;
    title?: string;
    value: any;
};

export function ValuesFilterItem(props: ValuesFilterItemProps) {
    defined(props);
    return <Fragment/>;
}

type ValuesFilterValueItem = {
    filterValues: any[];
    render?: (text: boolean) => ReactNode;
    title?: string;
    value: any;
};

export class ValuesFilterSelection<TValue> {
    constructor(
        public emptyValue: boolean,
        public values: TValue[],
        public type?: SelectionViewType) {
    }
}