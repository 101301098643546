﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeLoadBalancerLoadBalancingSchemeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeLoadBalancerLoadBalancingSchemeTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeLoadBalancerLoadBalancingScheme]: {
                    [Contract.GcpComputeLoadBalancerLoadBalancingScheme.External]: "External",
                    [Contract.GcpComputeLoadBalancerLoadBalancingScheme.ExternalManaged]: "External",
                    [Contract.GcpComputeLoadBalancerLoadBalancingScheme.Internal]: "Internal",
                    [Contract.GcpComputeLoadBalancerLoadBalancingScheme.InternalManaged]: "Internal",
                    [Contract.GcpComputeLoadBalancerLoadBalancingScheme.InternalSelfManaged]: "Internal"
                }
            }));
    return (loadBalancingScheme: Contract.GcpComputeLoadBalancerLoadBalancingScheme) =>
        localization[Contract.TypeNames.GcpComputeLoadBalancerLoadBalancingScheme][loadBalancingScheme]();
}