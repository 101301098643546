import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, Menu, Message, RevealIcon, useExecuteOperation, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { useSetEndpointConnectorsContext } from "../../..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setEndpointConnectorContext = useSetEndpointConnectorsContext();
    const [{ endpointConnectorIdToIntegrationNameMap }] =
        useExecuteOperation(
            ActionsCell,
            ConfigurationController.getEndpointConnectorIdToIntegrationNameMap);

    const [deleteEndpointConnectorExecuting, setDeleteEndpointConnectorExecuting] = useState(false);
    const [deleteEndpointConnectorError, setDeleteEndpointConnectorError] = useState(false);

    async function deleteEndpointConnector() {
        setDeleteEndpointConnectorExecuting(true);
        setDeleteEndpointConnectorError(false);

        if (!_.isNil(endpointConnectorIdToIntegrationNameMap[item.configuration.id])) {
            setDeleteEndpointConnectorError(true);
            setDeleteEndpointConnectorExecuting(false);
            return;
        }

        try {
            await ConfigurationController.deleteEndpointConnector(new Contract.ConfigurationControllerDeleteEndpointConnectorRequest(item.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteEndpointConnectorError(true);
        }

        setDeleteEndpointConnectorExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useEndpointConnectorItems.endpointConnectors.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: {
                            inUse: "Endpoint connector is in use by {{integrationName}}",
                            title: "Failed to delete"
                        },
                        prompt: "Are you sure you want to delete {{name}}?",
                        title: "Delete"
                    },
                    view: "View"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteEndpointConnectorExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteEndpointConnectorError && (
                <Message
                    level="error"
                    title={
                        !_.isNil(endpointConnectorIdToIntegrationNameMap[item.configuration.id])
                            ? localization.actions.delete.error.inUse({ integrationName: endpointConnectorIdToIntegrationNameMap[item.configuration.id] })
                            : localization.actions.delete.error.title()}
                    variant="minimal"/>)}
            {item.configuration.scopeId === scopeNodeModel.configuration.id &&
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setEndpointConnectorContext(
                                        EndpointConnectorContext => ({
                                            ...EndpointConnectorContext,
                                            addOrViewOpen: item
                                        })),
                                localization.actions.view(),
                                {
                                    disabled: deleteEndpointConnectorExecuting,
                                    icon: <RevealIcon/>
                                }),
                            new ActionMenuItem(
                                deleteEndpointConnector,
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ name: (item.configuration as Contract.EndpointConnectorConfiguration).name })
                                    },
                                    disabled: deleteEndpointConnectorExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>}
        </Stack>);
}