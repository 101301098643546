import { AnalyticsEventActionType, DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, EmptyMessageText, Optional, TimeCell, TimeFormatter, TimeRangeFilter, useExecuteOperation, useLocalization, useTrackAnalyticsEvent, ValuesFilter, ValuesFilterItem, ValuesFilterSelection } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef, useState } from "react";
import { AuditEventController, Contract, CustomerConsoleAppUrlHelper, ItemSelectionHelper, Scope, ScopeFilter, scopeNodeModelStore, StorageHelper, TimeRangeHelper, useLayoutOptions } from "../../../../common";
import { AuditEventControllerGetAuditEventFiltersRequest } from "../../../../common/controllers/types.generated";
import { CustomerSideViewItemType } from "../SideView";
import { useAuditEventTypeNameTranslator } from "./hooks";

export function AuditEvents() {
    const dataTableActionsRef = useRef<DataTableActions>();
    const auditEventTypeNameTranslator = useAuditEventTypeNameTranslator();
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const localization =
        useLocalization(
            "views.customer.auditEvents",
            () => ({
                actions: {
                    csvExport: {
                        fileNamePrefix: "AuditLog"
                    }
                },
                columns: {
                    errorMessage: {
                        failure: "Failure",
                        success: "Success",
                        title: "Result"
                    },
                    identityReference: "User",
                    scopeId: "Scope",
                    systemCreationTime: "Time",
                    typeName: "Action"
                },
                empty: {
                    withFilter: "No Matching Audit Events",
                    withoutFilter: "No Audit Events"
                },
                title: "Audit"
            }));
    useLayoutOptions({ view: { title: localization.title() } });

    const [{ filters, systemIdentityId }] =
        useExecuteOperation(
            AuditEvents,
            () => AuditEventController.getAuditEventFilters(new AuditEventControllerGetAuditEventFiltersRequest()));

    const fetchAuditEventModelNextPageSearchCursorRef = useRef<Contract.ElasticsearchIndexSearchCursor>();
    const [auditEventModelCount, setAuditEventModelCount] = useState(0);

    const fetchAuditEventModels =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { auditEventModelPage } =
                await AuditEventController.getAuditEventModelPage(
                    new Contract.AuditEventControllerGetAuditEventModelPageRequest(
                        limit,
                        skip === 0
                            ? undefined
                            : fetchAuditEventModelNextPageSearchCursorRef.current,
                        new Contract.AuditEventControllerGetAuditEventModelPageRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.IdentityReference]),
                            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[TableColumnId.ErrorMessage]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.ScopeId]),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[TableColumnId.SystemCreationTime]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.TypeName])),
                        new Contract.AuditEventControllerGetAuditEventModelPageRequestSort(
                            _.isNil(sort) || sort.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.SystemCreationTime)));
            return new DataTableFetchItemsResult(
                { count: auditEventModelPage.count ?? auditEventModelCount },
                auditEventModelPage.items,
                _.isNil(auditEventModelPage.itemNextPageSearchCursor),
                {
                    onAppendData:
                        () => {
                            if (!_.isNil(auditEventModelPage.count)) {
                                setAuditEventModelCount(auditEventModelPage.count);
                            }
                            fetchAuditEventModelNextPageSearchCursorRef.current = auditEventModelPage.itemNextPageSearchCursor;
                        }
                });
        };

    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const sortRef = useRef<DataTableSort>();
    const [filterMap, setFilterMap] =
        useState<Dictionary<any>>(
            () => {
                const identityIds =
                    _.map(
                        filters.identitySearchableReferenceItems.items,
                        identityReference => identityReference.id);
                return {
                    [TableColumnId.IdentityReference]:
                        _.includes(identityIds, systemIdentityId)
                            ? new ValuesFilterSelection(
                                false,
                                _.without(identityIds, systemIdentityId))
                            : undefined
                };
            });

    function getErrorMessage(item: Contract.AuditEventModel) {
        return _.isNil(item.auditEvent.errorMessage)
            ? localization.columns.errorMessage.success()
            : localization.columns.errorMessage.failure();
    }

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerAuditEventsColumnOrder
                },
                resizable: true
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{ fileNamePrefix: localization.actions.csvExport.fileNamePrefix() }}
            fetchItems={fetchAuditEventModels}
            filtersOptions={{
                initial: {
                    state: filterMap
                },
                onChanged: filterMap => setFilterMap(filterMap),
                persist: {
                    visibilityStorageItem: StorageHelper.customerAuditEventsFilters
                }
            }}
            getItemId={(item: Contract.AuditEventModel) => item.auditEvent.id}
            highlightItem={{
                hashRouteTemplate: `${CustomerSideViewItemType.AuditEvent}/{itemId}`
            }}
            rowOptions={{
                getUrl: (item: Contract.AuditEventModel) => CustomerConsoleAppUrlHelper.getAuditEventProfileHashUrl(item.auditEvent.id)!
            }}
            virtualizationEnabled={true}
            onSortChanged={sort => sortRef.current = sort}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AuditEventModel) => ({
                            [localization.columns.systemCreationTime()]: TimeFormatter.sortableDateTime(item.auditEvent.systemCreationTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <TimeRangeFilter
                                emptyValue={filters.systemCreationTimeRange.emptyValue}
                                placeholder={localization.columns.systemCreationTime()}
                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.systemCreationTimeRange.timeRange)}/>
                    }
                }}
                id={TableColumnId.SystemCreationTime}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AuditEventModel>) =>
                        <TimeCell
                            short={false}
                            time={item.auditEvent.systemCreationTime}/>}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.systemCreationTime()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AuditEventModel) => ({
                            [localization.columns.typeName()]: auditEventTypeNameTranslator(item.auditEvent.typeName)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: filters.typeNameItems.emptyValue }}
                                groupItemTitle={true}
                                placeholder={localization.columns.typeName()}>
                                {_.map(
                                    filters.typeNameItems.items,
                                    typeName =>
                                        <ValuesFilterItem
                                            key={typeName}
                                            title={auditEventTypeNameTranslator(typeName)}
                                            value={typeName}/>)}
                            </ValuesFilter>
                    }
                }}
                id={TableColumnId.TypeName}
                itemProperty={(item: Contract.AuditEventModel) => auditEventTypeNameTranslator(item.auditEvent.typeName)}
                key={TableColumnId.TypeName}
                sortOptions={{ enabled: false }}
                title={localization.columns.typeName()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AuditEventModel) => ({
                            [localization.columns.scopeId()]: scopeNodeMap[item.auditEvent.scopeId].path
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ScopeFilter
                                placeholder={localization.columns.scopeId()}
                                scopeIds={filters.scopeIdItems.items}/>
                    }
                }}
                id={TableColumnId.ScopeId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AuditEventModel>) =>
                        <Typography noWrap={true}>
                            <Scope
                                scopeId={item.auditEvent.scopeId}
                                scopeNameTranslatorOptions={{ path: true }}
                                variant="text"/>
                        </Typography>}
                sortOptions={{ enabled: false }}
                title={localization.columns.scopeId()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AuditEventModel) => ({
                            [localization.columns.identityReference()]: item.auditEvent.identityReference.displayName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: filters.identitySearchableReferenceItems.emptyValue }}
                                placeholder={localization.columns.identityReference()}>
                                {_.map(
                                    filters.identitySearchableReferenceItems.items,
                                    identityReference =>
                                        <ValuesFilterItem
                                            key={identityReference.id}
                                            title={identityReference.displayName}
                                            value={identityReference.id}/>)}
                            </ValuesFilter>
                    }
                }}
                id={TableColumnId.IdentityReference}
                itemProperty={(item: Contract.AuditEventModel) => item.auditEvent.identityReference.displayName}
                sortOptions={{ enabled: false }}
                title={localization.columns.identityReference()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AuditEventModel) => ({
                            [localization.columns.errorMessage.title()]: getErrorMessage(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter placeholder={localization.columns.errorMessage.title()}>
                                <ValuesFilterItem
                                    key="false"
                                    title={localization.columns.errorMessage.failure()}
                                    value={false}/>
                                <ValuesFilterItem
                                    key="true"
                                    title={localization.columns.errorMessage.success()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={TableColumnId.ErrorMessage}
                itemProperty={getErrorMessage}
                key={TableColumnId.ErrorMessage}
                sortOptions={{ enabled: false }}
                title={localization.columns.errorMessage.title()}/>
        </DataTable>);
}

enum TableColumnId {
    Details = "details",
    ErrorMessage = "errorMessage",
    IdentityReference = "identityReference",
    ScopeId = "scopeId",
    SystemCreationTime = "systemCreationTime",
    TypeName = "typeName"
}